import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  report: null,
  loading: true,
  error: null
};

const spainSlice = createSlice({
  name: 'spain',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpainReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSpainReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
      })
      .addCase(fetchSpainReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export const fetchSpainReport = createAsyncThunk(
    'spain/fetchSpainReport',
    async (_, { rejectWithValue }) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dashboard/spain-who-summary`);
        if (!response.ok) {
          throw new Error('Failed to fetch Spain report');
        }
        const data = await response.json();
        return data;
      } catch (err) {
        return rejectWithValue(err.toString());
      }
    }
  );

export default spainSlice.reducer;
