import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../UI/Loader/Loader";
import { fetchSpainReport } from "../../redux/slices/spain.slice";
import { renderGoToSourceButton } from "./Italy";

const Spain = () => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.spain);

  useEffect(() => {
    if (!report) {
      dispatch(fetchSpainReport({}));
    }
  }, [dispatch, report]);

  const renderReportWeekYear = () => {
    const week = report?.data?.week;
    return (
      <div className="alert alert-info mb-4">
        <i className="fa fa-info-circle me-2"></i>
        Report for Year {report?.data?.year}, Week {report?.data?.week}
      </div>
    );
  };

  const renderSummary = () => {
    if (!report?.data?.summary) return null;

    return (
      <div className="card mb-4">
        <div className="card-body">
          {renderReportWeekYear()}
          {report.data.summary.map((section, index) => (
            <div key={index}>
              {index !== 0 && (
                <h5 className="fw-bold mb-3">{section.heading}</h5>
              )}
              {section.paragraphs.map((paragraph, idx) => {
                return (
                  <p key={idx} className="mb-3">
                    {paragraph}
                  </p>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  function formatText(input) {
    return input
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const renderGraphs = () => {
    if (!report?.data?.graphs) return null;

    return (
      <div className="card mb-4 d-flex flex-col align-items-center">
        <div className="card-body">
          {Object.entries(report.data.graphs).map(([key, value]) => (
            <div key={key} className="mb-4">
              <div className="d-flex align-items-center mb-3 justify-content-center">
                <h5 className="fw-bold mb-3">{formatText(key)}</h5>
              </div>
              <div className="card p-2">
                <img
                  src={`data:image/png;base64,${value}`}
                  alt={key}
                  className="img-fluid"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="alert alert-danger">
        <strong>Error loading report:</strong> {error}
      </div>
    );
  if (!report)
    return (
      <div className="alert alert-info">
        <strong>No report available</strong>
      </div>
    );
  const sourceUrl =
    "https://cne.isciii.es/en/servicios/enfermedades-transmisibles/enfermedades-a-z/gripe-covid-19-y-otros-virus-respiratorios#";

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="country-name mb-4">Spain</h2>
        {/* <IconButton
                Icon={LuRefreshCcw}
                onClick={handleRefresh}
                className="btn btn-outline-primary"
              /> */}
        {renderGoToSourceButton(sourceUrl)}
      </div>
      {renderSummary()}
      {renderGraphs()}

      <div className="mt-4 p-4">
        <p className="d-flex align-items-center text-muted">
          <i className="fa fa-link me-2"></i>
          <span>
            Source: <a href={sourceUrl}>{"https://cne.isciii.es/en"}</a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Spain;
