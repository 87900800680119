import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { format } from "date-fns";

export const fetchGlobalNews = createAsyncThunk(
  "globalNews/fetchGlobalNews",
  async (selectedDate = null, { rejectWithValue }) => {
    try {
      const dateToFetch = selectedDate
        ? format(new Date(selectedDate), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd");

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/global-news`,
        {
          params: { date: dateToFetch },
        }
      );

      return {
        news: response.data.data.data,
        whoOutbreaks: response.data.data.who_outbreaks,
        timestamp: response.data.timestamp,
        selectedDate: dateToFetch,
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch news");
    }
  }
);

export const fetchHistoricalNews = createAsyncThunk(
  "globalNews/fetchHistoricalNews",
  async (
    { country, page = 1, limit = 10, startDate, endDate },
    { rejectWithValue }
  ) => {
    try {
      const params = {
        page,
        limit,
        ...(country && { country }),
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/historical-news`,
        { params }
      );

      return {
        data: response.data.data,
        pagination: response.data.pagination,
        timestamp: response.data.timestamp,
      };
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch historical news"
      );
    }
  }
);

export const fetchHistoricalWHOOutbreaks = createAsyncThunk(
  "globalNews/fetchHistoricalWHOOutbreaks",
  async ({ page = 1, limit = 10, startDate, endDate }, { rejectWithValue }) => {
    try {
      const params = {
        page,
        limit,
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/historical-who-outbreaks`,
        { params }
      );

      return {
        data: response.data.data,
        pagination: response.data.pagination,
        timestamp: response.data.timestamp,
      };
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch historical WHO outbreaks"
      );
    }
  }
);

const globalNewsSlice = createSlice({
  name: "globalNews",
  initialState: {
    news: {},
    whoOutbreaks: [],
    historicalNews: [],
    historicalWHOOutbreaks: [],
    loading: false,
    historicalLoading: false,
    error: null,
    lastUpdated: null,
    selectedDate: null,
    pagination: {
      total: 0,
      page: 1,
      limit: 10,
      total_pages: 0,
    },
    whoPagination: {
      total: 0,
      page: 1,
      limit: 10,
      total_pages: 0,
    },
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    setWHOPage: (state, action) => {
      state.whoPagination.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Existing reducers
      .addCase(fetchGlobalNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGlobalNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload.news;
        state.whoOutbreaks = action.payload.whoOutbreaks;
        state.lastUpdated = action.payload.timestamp;
        state.selectedDate = action.payload.selectedDate;
      })
      .addCase(fetchGlobalNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch news";
      })
      // Historical news reducers
      .addCase(fetchHistoricalNews.pending, (state) => {
        state.historicalLoading = true;
        state.error = null;
      })
      .addCase(fetchHistoricalNews.fulfilled, (state, action) => {
        state.historicalLoading = false;
        state.historicalNews = action.payload.data;
        state.pagination = action.payload.pagination;
        state.lastUpdated = action.payload.timestamp;
      })
      .addCase(fetchHistoricalNews.rejected, (state, action) => {
        state.historicalLoading = false;
        state.error = action.payload || "Failed to fetch historical news";
      })
      // WHO outbreaks reducers
      .addCase(fetchHistoricalWHOOutbreaks.pending, (state) => {
        state.historicalLoading = true;
        state.error = null;
      })
      .addCase(fetchHistoricalWHOOutbreaks.fulfilled, (state, action) => {
        state.historicalLoading = false;
        state.historicalWHOOutbreaks = action.payload.data;
        state.whoPagination = action.payload.pagination;
        state.lastUpdated = action.payload.timestamp;
      })
      .addCase(fetchHistoricalWHOOutbreaks.rejected, (state, action) => {
        state.historicalLoading = false;
        state.error =
          action.payload || "Failed to fetch historical WHO outbreaks";
      });
  },
});

export const { clearError, setPage, setWHOPage } = globalNewsSlice.actions;
export default globalNewsSlice.reducer;
