import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import html2canvas from "html2canvas";
import { AgCharts } from "ag-charts-react";
import "ag-charts-enterprise";
import { data } from "./data";
import { topology } from "../AgMapChart/topology.";

export const AgMapChartWrapper = forwardRef((props, ref) => {
  const {
    data: customData = data,
    topology: customTopology = topology,
    title = "Access to Clean Fuels",
    colorKey = "value",
    colorName = "% of population",
    colorRange = {
      min: 0,
      max: 100,
      colors: ["#e6f3ff", "#0066cc"]
    },
    legendPosition = "right",
    legendLength = 200,
    legendThickness = 2,
    labelFormatter = (p) => p.value + "%",
    labelFontSize = 10
  } = props;

  const agMapChartRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getAgMapChartImage: async () => {
      if (!agMapChartRef.current) return null;
      const AgchartNode = agMapChartRef.current;
      const canvas = await html2canvas(AgchartNode, {
        useCORS: true,
        logging: true,
      });
      return canvas.toDataURL("image/jpeg", 0.9);
    },
  }));

  const [options, setOptions] = useState({
    data: customData,
    topology: customTopology,
    series: [
      {
        type: "map-shape-background",
      },
      {
        type: "map-shape",
        title: title,
        idKey: "name",
        colorKey: colorKey,
        colorName: colorName,
        fills: colorRange.colors,
        colorRange: {
          min: colorRange.min,
          max: colorRange.max,
        },
      },
    ],
    gradientLegend: {
      enabled: true,
      position: legendPosition,
      gradient: {
        preferredLength: legendLength,
        thickness: legendThickness,
      },
      scale: {
        label: {
          fontSize: labelFontSize,
          formatter: labelFormatter,
        },
      },
    },
    legend: {
      enabled: true,
    },
  });

  useEffect(() => {
    setOptions(prevOptions => ({
      ...prevOptions,
      data: customData,
      topology: customTopology,
      series: [
        {
          type: "map-shape-background",
        },
        {
          type: "map-shape",
          title: title,
          idKey: "name",
          colorKey: colorKey,
          colorName: colorName,
          fills: colorRange.colors,
          colorRange: {
            min: colorRange.min,
            max: colorRange.max,
          },
        },
      ],
      gradientLegend: {
        enabled: true,
        position: legendPosition,
        gradient: {
          preferredLength: legendLength,
          thickness: legendThickness,
        },
        scale: {
          label: {
            fontSize: labelFontSize,
            formatter: labelFormatter,
          },
        },
      },
    }));
  }, [
    customData,
    customTopology,
    title,
    colorKey,
    colorName,
    colorRange,
    legendPosition,
    legendLength,
    legendThickness,
    labelFormatter,
    labelFontSize,
  ]);

  return (
    <div ref={agMapChartRef}>
      <AgCharts options={options} />
    </div>
  );
});