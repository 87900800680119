export const Specimens_data = [
    { name: "Afghanistan", value: 750.11 },
    { name: "Albania", value: 1396.37 },
    { name: "Algeria", value: 2822.24},
    { name: "Argentina", value: 3720.48 },
    { name: "Australia", value: 7807.98} ,
    {name: "Azerbaijan", value: 903.04},
    {name: "Bahrain", value: 3014.03},
    {name: "Bangladesh", value: 2168.77},
    {name: "Belarus", value: 1157.95},
    {name: "Belgium", value: 4507.41},
    {name: "Bhutan", value: 3628.47},
    {name: "Brazil", value: 5727.29},
    {name: "Bulgaria", value: 2336.54},
    {name: "Burkina Faso", value: 2150.84},
    {name: "Cambodia", value: 3673.91},
    {name: "Cameroon", value: 3643.42},
    {name: "Central African Republic", value: 2287.23},
    {name: "Chad", value: 94.76},
    {name: "Chile", value: 2324.82},
    {name: "China", value: 3747.92},
    {name: "China, Hong Kong SAR", value: 2163.5},
    {name: "Colombia", value: 3451.71},
    {name: "Costa Rica", value: 3467.78},
    {name: "Croatia", value: 3917.31},
    {name: "Cuba", value: 2909.58},
    {name: "Czechia", value: 3561.72},
    {name: "Côte d’Ivoire", value: 2441.47},
    {name: "Democratic Republic of the Congo", value: 1036.96},
    {name: "Estonia", value: 1091.78},
    {name: "Ethiopia", value: 2314.7},
    {name: "Finland", value: 4176.83},
    {name: "Gabon", value: 473.25},
    {name: "Georgia", value: 2055},
    {name: "Germany", value: 2621.53},
    {name: "Ghana", value: 2192.17},
    {name: "Greece", value: 2045.89},
    {name: "Guinea", value: 2122.61},
    {name: "India", value: 1960.77},
    {name: "Indonesia", value: 4508.99},
    {name: "Italy", value: 1638.26},
    {name: "Japan", value: 8098.4},
    {name: "Jordan", value: 860.74},
    {name: "Kazakhstan", value: 869.39},
    {name: "Kenya", value: 2053.75},
    {name: "Latvia", value: 1251.92},
    {name: "Lebanon", value: 2301.9},
    {name: "Madagascar", value: 3785.38},
    {name: "Maldives", value: 2353.67},
    {name: "Mali", value: 1844.22},
    {name: "Mauritania", value: 2020.62},
    {name: "Mauritius", value: 1274.76},
    {name: "Mongolia", value: 1434.22},
    {name: "Montenegro", value: 2602.72},
    {name: "Morocco", value: 1345.01},
    {name: "Mozambique", value: 1392.03},
    {name: "Myanmar", value: 1645.53},
    {name: "Namibia", value: 2242.06},
    {name: "Nepal", value: 4060.16},
    {name: "New Caledonia", value: 1835.19},
    {name: "Niger", value: 1645.35},
    {name: "Nigeria", value: 1367.92},
    {name: "North Macedonia", value: 1417.12},
    {name: "Norway", value: 1055.49},
    {name: "Oman", value: 2354.86},
    {name: "Pakistan", value: 913.41},
    {name: "Papua New Guinea", value: 881.02},
    {name: "Philippines", value: 2057.54},
    {name: "Portugal", value: 1353.62},
    {name: "Republic of Korea", value: 1401.91},
    {name: "Republic of Moldova", value: 2242},
    {name: "Russian Federation", value: 1107.66},
    {name: "Rwanda", value: 815.41},
    {name: "Senegal", value: 4443.87},
    {name: "Serbia", value: 3286.72},
    {name: "Seychelles", value: 868.7},
    {name: "Sierra Leone", value: 326.61},
    {name: "Singapore", value: 3218.31},
    {name: "Slovenia", value: 1155.85},
    {name: "South Africa", value: 1615.12},
    {name: "South Sudan", value: 1257.91},
    {name: "Sri Lanka", value: 3229.21},
    {name: "Sweden", value: 12871.76},
    {name: "Tajikistan", value: 893.57},
    {name: "Thailand", value: 3375.34},
    {name: "Togo", value: 2992.34},
    {name: "Tunisia", value: 1484.75},
    {name: "Turkmenistan", value: 63.02},
    {name: "Uzbekistan", value: 1125.9},
    {name: "Viet Nam", value: 3801.66},
    {name: "Zambia", value: 2242.32},
    {name: "Zimbabwe", value: 412.45},
    
]; 

export const ILI_data = [
    { name: "Afghanistan", value: 17364.94 },
    { name: "Albania", value: 2502.8 },
    { name: "Argentina", value: 294801.9 },
    { name: "Armenia", value: 301657.2 },
    { name: "Australia", value: 2638.89 },
    { name: "Austria", value: 262539.25 },
    { name: "Azerbaijan", value: 2498156.39 },
    { name: "Bahrain", value: 129512.54 },
    { name: "Bangladesh", value: 274.95 },
    { name: "Barbados", value: 6340.12 },
    { name: "Belarus", value: 2877.74 },
    { name: "Belgium", value: 68345.79 },
    { name: "Bhutan", value: 17317.31 },
    { name: "Bolivia (Plurinational State of)", value: 1450.67 },
    { name: "Bosnia and Herzegovina", value: 1251.11 },
    { name: "Brazil", value: 51410.76 },
    { name: "Brunei Darussalam", value: 81960.65 },
    { name: "Bulgaria", value: 3242.25 },
    { name: "Cambodia", value: 10588.27 },
    { name: "Cameroon", value: 5388.46 },
    { name: "Canada", value: 2159.92 },
    { name: "Central African Republic", value: 6853.3 },
    { name: "Chile", value: 194.4 },
    { name: "Colombia", value: 4884.58 },
    { name: "Costa Rica", value: 375551.67 },
    { name: "Croatia", value: 7015.38 },
    { name: "Cyprus", value: 383537.92 },
    { name: "Czechia", value: 14789.19 },
    { name: "Côte d’Ivoire", value: 73913.54 },
    { name: "Democratic Republic of the Congo", value: 18155.02 },
    { name: "Denmark", value: 22840.23 },
    { name: "Dominican Republic", value: 4889.7 },
    { name: "El Salvador", value: 37458.14 },
    { name: "Estonia", value: 29252.16 },
    { name: "Ethiopia", value: 6594.46 },
    { name: "Finland", value: 1631.76 },
    { name: "France", value: 14523.17 },
    { name: "Gabon", value: 35000 },
    { name: "Georgia", value: 38362.7 },
    { name: "Germany", value: 2228.42 },
    { name: "Ghana", value: 1288.33 },
    { name: "Greece", value: 91840.68 },
    { name: "Guatemala", value: 1912.08 },
    { name: "Guinea", value: 42479.18 },
    { name: "Honduras", value: 85505.77 },
    { name: "Hungary", value: 25930.3 },
    { name: "Iceland", value: 1216.51 },
    { name: "Indonesia", value: 711.55 },
    { name: "Ireland", value: 3151.58 },
    { name: "Israel", value: 3782.36 },
    { name: "Italy", value: 61852.67 },
    { name: "Kazakhstan", value: 10295.19 },
    { name: "Kosovo (in accordance with UN Security Council resolution 1244 (1999))", value: 15304.13 },
    { name: "Kyrgyzstan", value: 1932.78 },
    { name: "Latvia", value: 511102.27 },
    { name: "Lithuania", value: 4315.26 },
    { name: "Luxembourg", value: 525659.31 },
    { name: "Madagascar", value: 9913.12 },
    { name: "Mali", value: 457.71 },
    { name: "Malta", value: 727510.46 },
    { name: "Mauritania", value: 4223.58 },
    { name: "Mauritius", value: 21.4 },
    { name: "Mongolia", value: 5284.26 },
    { name: "Montenegro", value: 2353.33 },
    { name: "Morocco", value: 1759.46 },
    { name: "Mozambique", value: 7919.15 },
    { name: "Netherlands (Kingdom of the)", value: 5746.63 },
    { name: "Niger", value: 25616.84 },
    { name: "Nigeria", value: 3297.98 },
    { name: "North Macedonia", value: 3651.49 },
    { name: "Norway", value: 6848.52 },
    { name: "Panama", value: 290.63 },
    { name: "Paraguay", value: 16931.98 },
    { name: "Poland", value: 51531.02 },
    { name: "Portugal", value: 2225.09 },
    { name: "Republic of Moldova", value: 1117.36 },
    { name: "Romania", value: 600.48 },
    { name: "Russian Federation", value: 284.55 },
    { name: "Senegal", value: 6191.04 },
    { name: "Serbia", value: 14596.51 },
    { name: "Slovakia", value: 16994.59 },
    { name: "Slovenia", value: 3927.25 },
    { name: "South Sudan", value: 2206.52 },
    { name: "Spain", value: 3834.55 },
    { name: "Sri Lanka", value: 3522.89 },
    { name: "Suriname", value: 228.29 },
    { name: "Switzerland", value: 11892.12 },
    { name: "Tajikistan", value: 13522.83 },
    { name: "Thailand", value: 14982.05 },
    { name: "Timor-Leste", value: 33226.63 },
    { name: "Togo", value: 17344.7 },
    { name: "Turkmenistan", value: 537090.81 },
    { name: "Türkiye", value: 114908.34 },
    { name: "Uganda", value: 1785.48 },
    { name: "Ukraine", value: 45387.27 },
    { name: "United Kingdom, England", value: 446.72 },
    { name: "United Kingdom, Northern Ireland", value: 520.86 },
    { name: "United Kingdom, Scotland", value: 584.95 },
    { name: "United Kingdom, Wales", value: 513.27 },
    { name: "United States of America", value: 5246.92 },
    { name: "Uzbekistan", value: 5947.25 },
    { name: "Viet Nam", value: 3939.16 },
    { name: "Zambia", value: 86752.52 }
     
];

export const Sari_data = [
    { name: "Afghanistan", value: 2265.85 },
{ name: "Albania", value: 555.45 },
{ name: "Argentina", value: 4939.2 },
{ name: "Armenia", value: 1315.26 },
{ name: "Aruba", value: 278.32 },
{ name: "Azerbaijan", value: 246.58 },
{ name: "Bangladesh", value: 223.23 },
{ name: "Barbados", value: 1522 },
{ name: "Belarus", value: 198.1 },
{ name: "Belgium", value: 5.35 },
{ name: "Bhutan", value: 2804.14 },
{ name: "Bolivia (Plurinational State of)", value: 5096.08 },
{ name: "Bosnia and Herzegovina", value: 1472.03 },
{ name: "Brazil", value: 8245.34 },
{ name: "Cambodia", value: 1082.34 },
{ name: "Cameroon", value: 1519.04 },
{ name: "Cayman Islands", value: 225.15 },
{ name: "Central African Republic", value: 857.28 },
{ name: "Chile", value: 2139.61 },
{ name: "Costa Rica", value: 17075.27 },
{ name: "Cuba", value: 4514.65 },
{ name: "Côte d’Ivoire", value: 148470.85 },
{ name: "Democratic Republic of the Congo", value: 1794.12 },
{ name: "Dominican Republic", value: 1115.21 },
{ name: "Ecuador", value: 713.53 },
{ name: "El Salvador", value: 662.68 },
{ name: "Ethiopia", value: 1265.07 },
{ name: "Gabon", value: 300 },
{ name: "Georgia", value: 386.67 },
{ name: "Ghana", value: 759.45 },
{ name: "Guatemala", value: 11501.31 },
{ name: "Guinea", value: 907.69 },
{ name: "Honduras", value: 1642.09 },
{ name: "Indonesia", value: 203.76 },
{ name: "Ireland", value: 0.04 },
{ name: "Jamaica", value: 170.87 },
{ name: "Kazakhstan", value: 895.14 },
{ name: "Kosovo (in accordance with UN Security Council resolution 1244 (1999))", value: 0.13 },
{ name: "Kyrgyzstan", value: 109.36 },
{ name: "Maldives", value: 203.13 },
{ name: "Mali", value: 496.11 },
{ name: "Malta", value: 0.6 },
{ name: "Mauritania", value: 396.46 },
{ name: "Mauritius", value: 15.89 },
{ name: "Mexico", value: 474.62 },
{ name: "Mongolia", value: 1461.41 },
{ name: "Montenegro", value: 0.1 },
{ name: "Mozambique", value: 4192.33 },
{ name: "Niger", value: 3302.31 },
{ name: "Nigeria", value: 4251.6 },
{ name: "North Macedonia", value: 0.91 },
{ name: "Panama", value: 658.39 },
{ name: "Paraguay", value: 2614.02 },
{ name: "Peru", value: 26378.42 },
{ name: "Qatar", value: 11.29 },
{ name: "Republic of Moldova", value: 1.93 },
{ name: "Romania", value: 0.03 },
{ name: "Russian Federation", value: 61.61 },
{ name: "Saint Lucia", value: 183.97 },
{ name: "Saint Vincent and the Grenadines", value: 43.47 },
{ name: "Senegal", value: 138.26 },
{ name: "Serbia", value: 109.95 },
{ name: "South Sudan", value: 1187.02 },
{ name: "Spain", value: 6.46 },
{ name: "Sri Lanka", value: 485.21 },
{ name: "Suriname", value: 1034.82 },
{ name: "Tajikistan", value: 694.79 },
{ name: "Thailand", value: 655.76 },
{ name: "Timor-Leste", value: 3764.76 },
{ name: "Togo", value: 2401.63 },
{ name: "Trinidad and Tobago", value: 102.79 },
{ name: "Türkiye", value: 2000 },
{ name: "Uganda", value: 60.64 },
{ name: "Ukraine", value: 5.22 },
{ name: "Uruguay", value: 673.76 },
{ name: "Uzbekistan", value: 17.95 },
{ name: "Zambia", value: 7479.12 }
]