import { jsPDF } from "jspdf";

/**
 * Adds Indonesia section to the PDF document with proper margin control
 * @param {Object} doc - PDF document instance
 * @param {Object} indonesiaReport - Indonesia report data
 * @param {Object} indonesiaRef - Reference to Indonesia component
 * @returns {Promise<void>}
 */
export const addIndonesiaSection = async (
  doc,
  indonesiaReport,
  indonesiaRef
) => {
  const margin = 20;
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  const contentWidth = pageWidth - margin * 2;

  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!indonesiaReport) {
      console.warn("Indonesia report data is missing");
      return;
    }

    doc.addPage();

    // Title
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("Indonesia", margin, 30);

    // Report period
    const { current_report } = indonesiaReport.data;
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Report for Year ${current_report.iso_year || "N/A"}, Week ${
        current_report.iso_week || "N/A"
      }`,
      margin,
      45
    );

    let currentY = 65;

    // Surveillance Summary section
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text("Surveillance Summary", margin, currentY);
    currentY += 10;

    // Summary content with text wrapping
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);

    // First paragraph with proper wrapping
    const specimenText = `A total of ${
      current_report.flu_data.specimens.processed || 0
    } specimens have been processed through laboratory surveillance.`;
    const lines = doc.splitTextToSize(specimenText, contentWidth);
    doc.text(lines, margin, currentY);
    currentY += lines.length * 8 + 10;

    // Second paragraph
    const processingText = `The total number of specimens received stands at ${
      current_report.flu_data.specimens.received || 0
    }, with a processing rate of ${(
      (current_report.flu_data.specimens.processed /
        current_report.flu_data.specimens.received) *
      100
    ).toFixed(1)}%.`;
    const lines2 = doc.splitTextToSize(processingText, contentWidth);
    doc.text(lines2, margin, currentY);
    currentY += lines2.length * 8 + 10;

    // Subtype Analysis section
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text("Subtype Analysis", margin, currentY);
    currentY += 10;

    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);

    const subtypes = [
      {
        type: "AH1N1 2009:",
        value: `${
          current_report.flu_data.influenza_a.h1n1_2009 || 0
        } cases reported`,
      },
      {
        type: "AH3:",
        value: `${current_report.flu_data.influenza_a.h3 || 0} cases reported`,
      },
      { type: "AH5:", value: "No cases reported during this period" },
      {
        type: "Victoria lineage:",
        value: `${
          current_report.flu_data.influenza_b.victoria || 2
        } cases reported`,
      },
      {
        type: "Yamagata lineage:",
        value: "No cases reported during this period",
      },
    ];

    let yPosition = currentY;
    const lineHeight = 12;

    subtypes.forEach((subtype) => {
      doc.setFont(undefined, "bold");
      doc.text(subtype.type, margin, yPosition);

      doc.setFont(undefined, "normal");
      const valueLines = doc.splitTextToSize(subtype.value, contentWidth - 80);
      doc.text(valueLines, margin + 80, yPosition);

      yPosition += lineHeight * Math.max(valueLines.length, 1);
    });

    currentY = yPosition + 10;

    // Source citation
    doc.setFontSize(9);
    doc.setTextColor(128, 128, 128);
    doc.text("Source: WHO FluNet", margin, pageHeight - 15);

    // Add charts if available
    if (indonesiaRef?.current) {
      try {
        const chartImages = await indonesiaRef.current.getChartImages();
        if (chartImages) {
          doc.addPage();
          doc.setFontSize(16);
          doc.setTextColor(41, 128, 185);
          doc.text("Surveillance Data Visualization", margin, 30);

          const imageWidth = pageWidth - margin * 2;
          const imageHeight = imageWidth * 0.5;

          let yPosition = 50;

          for (const [name, imageData] of Object.entries(chartImages)) {
            if (imageData) {
              if (yPosition + imageHeight > pageHeight - margin) {
                doc.addPage();
                yPosition = margin;
              }

              doc.addImage(
                imageData,
                "JPEG",
                margin,
                yPosition,
                imageWidth,
                imageHeight,
                undefined,
                "MEDIUM"
              );

              yPosition += imageHeight + 20;
            }
          }
        }
      } catch (error) {
        console.error("Error adding charts to PDF:", error);
      }
    }

    // Always start the Comments section on a new page
    doc.addPage();
    currentY = margin;

    // Comments Section Label
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text("Comments", margin, currentY);
    currentY += 10;

    // Calculate Comment Box Height (1/3rd of total page height)
    const commentBoxHeight = (pageHeight - margin * 2) / 3;

    // Draw Comment Box with Gray Border
    doc.setLineWidth(0.1);
    doc.setDrawColor(150, 150, 150); // Light gray border
    doc.rect(margin, currentY, contentWidth, commentBoxHeight);
  } catch (error) {
    console.error("Error generating Indonesia PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating Indonesia report section. Please try again later.",
      margin,
      40
    );
  }
};
