import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../UI/Loader/Loader";
import { fetchIndonesiaReport } from "../../redux/slices/indonesia.slice";
import FluNetCharts from "../fluNetCharts/FluNetCharts";
import { renderGoToSourceButton } from "./Italy";

const Indonesia = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.indonesia);
  const fluNetChartsRef = useRef();

  useEffect(() => {
    if (!report) {
      dispatch(fetchIndonesiaReport());
    }
  }, [dispatch, report]);

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      if (fluNetChartsRef.current) {
        try {
          // Add a small delay to ensure charts are rendered
          await new Promise((resolve) => setTimeout(resolve, 1500));
          const images = await fluNetChartsRef.current.getChartImages();
          if (!images) {
            console.warn("No chart images were captured");
          }
          return images;
        } catch (error) {
          console.error("Error getting chart images:", error);
          return null;
        }
      }
      return null;
    },
  }));

  const renderReportWeekYear = () => {
    if (!report?.data?.current_report) return null;
    const { iso_week, iso_year } = report.data.current_report;

    return (
      <div className="alert alert-info mb-4">
        <i className="fa fa-info-circle me-2"></i>
        Report for Year {iso_year}, Week {iso_week}
      </div>
    );
  };

  const sourceUrl =
    "https://www.who.int/teams/global-influenza-programme/surveillance-and-monitoring/influenza-surveillance-outputs";

  // const renderSummary = () => {
  //   if (!report?.data?.current_report) return null;
  //   const { flu_data } = report.data.current_report;

  //   return (
  //     <div className="card mb-4">
  //       <div className="card-body">
  //         {renderReportWeekYear()}
  //         <p className="mb-3">
  //           <i className="fa fa-exclamation-triangle text-warning me-2"></i>
  //           In Indonesia, a total of{" "}
  //           {flu_data.specimens?.processed?.toLocaleString()} specimens have
  //           been processed through laboratory surveillance.
  //         </p>
  //         <h6 className="fw-bold mb-3">Subtype Analysis</h6>
  //         <ul className="list-unstyled">
  //           <li className="mb-2">
  //             <i className="fa fa-circle me-2"></i>
  //             AH1N12009 has been reported with{" "}
  //             {flu_data.influenza_a?.h1n1_2009?.toLocaleString()} cases in
  //             Indonesia.
  //           </li>
  //           <li className="mb-2">
  //             <i className="fa fa-circle me-2"></i>
  //             AH3 has been reported with{" "}
  //             {(flu_data.influenza_a.h3 || 0)?.toLocaleString()} cases in
  //             Indonesia.
  //           </li>
  //           <li className="mb-2">
  //             <i className="fa fa-circle me-2"></i>
  //             AH5 has{" "}
  //             {flu_data.influenza_a.h5
  //               ? "been reported with " + flu_data.influenza_a.h5 + " cases"
  //               : "not been reported"}{" "}
  //             in Indonesia during this period.
  //           </li>
  //           <li className="mb-2">
  //             <i className="fa fa-circle me-2"></i>
  //             Victoria lineage has been reported with{" "}
  //             {(
  //               flu_data.influenza_b.victoria.nodel || 0
  //             )?.toLocaleString()}{" "}
  //             cases in Indonesia.
  //           </li>
  //           <li className="mb-2">
  //             <i className="fa fa-circle me-2"></i>
  //             Yamagata lineage has{" "}
  //             {flu_data.influenza_b.yamagata
  //               ? "been reported with " +
  //                 flu_data.influenza_b.yamagata +
  //                 " cases"
  //               : "not been reported"}{" "}
  //             in Indonesia during this period.
  //           </li>
  //         </ul>
  //         <div className="mt-4">
  //           <p className="mb-2">
  //             <i className="fa fa-chart-line text-info me-2"></i>
  //             The total number of specimens received stands at{" "}
  //             {flu_data.specimens.received?.toLocaleString()}, with a processing
  //             rate of{" "}
  //             {(
  //               (flu_data.specimens.processed / flu_data.specimens.received) *
  //               100
  //             ).toFixed(1)}
  //             %.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="alert alert-danger">
        <strong>Error loading report:</strong> {error}
      </div>
    );
  if (!report)
    return (
      <div className="alert alert-info">
        <strong>No report available</strong>
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="country-name mb-4">Indonesia</h2>
        {/* <IconButton
                      Icon={LuRefreshCcw}
                      onClick={handleRefresh}
                      className="btn btn-outline-primary"
                    /> */}
        {renderGoToSourceButton(sourceUrl)}
      </div>
      {/* {renderSummary()} */}
      {report?.data?.graph_data && (
        <FluNetCharts
          ref={fluNetChartsRef}
          data={report?.data?.graph_data}
          country_name={"Indonesia"}
        />
      )}

      <div className="mt-4 p-4">
        <p className="d-flex align-items-center text-muted">
          <i className="fa fa-link me-2"></i>
          <span>
            Source: <a href={sourceUrl}>{"https://www.who.int"}</a>
          </span>
        </p>
      </div>
    </div>
  );
});

export default Indonesia;
