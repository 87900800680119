import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchItalyReport } from "../../redux/slices/italy.slice";
import { Loader } from "../UI/Loader/Loader";
import { ErrorBoundary } from "./Whoo";
import { AgMapChartWrapper } from "../AgMapChart/AgMapChartWrapper";

import { italy_topology } from "./../AgMapChart/italy_topology";

const Italy = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.italy);

  const influenzaAgMapChartRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      const influenzaAgMapChartImage = influenzaAgMapChartRef.current
        ? await influenzaAgMapChartRef.current.getAgMapChartImage()
        : null;

      return {
        influenzaAgMapChart: influenzaAgMapChartImage,
      };
    },
  }));

  const renderSubtypeAnalysis = () => {
    if (!report.subtype_analysis || !Array.isArray(report.subtype_analysis)) {
      return (
        <p className="warning-message">Subtype analysis data is unavailable.</p>
      );
    }

    return (
      <div className="report-section">
        <h5>Subtype Analysis</h5>
        <ul>
          {report.subtype_analysis.map((subtype, index) => (
            <li key={index}>
              <i className="fa fa-check-circle icon" aria-label="Subtype"></i>{" "}
              {subtype}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderItalyReport = () => {
    if (!report) return <div>No report data available</div>;

    return (
      <div>
        <h6 className="mt-4 mb-3 report-header">{report.title}</h6>

        <div className="report-item">
          <i className="fa fa-vial icon"></i>
          <span>{report.specimen_summary}</span>
        </div>

        {renderSubtypeAnalysis()}

        <div className="report-item">
          <i className="fa fa-hospital icon"></i>
          <span>{report.sari_surveillance}</span>
        </div>

        <div className="report-item">
          <i className="fa fa-stethoscope icon"></i>
          <span>{report.ili_surveillance}</span>
        </div>

        <div className="report-item">
          <i className="fa fa-heartbeat icon"></i>
          <span>{report.total_burden}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!report) {
      dispatch(fetchItalyReport());
    }
  }, [dispatch, report]);

  if (loading) return <Loader />;
  if (error) return <p>Error: {error}</p>;
  if (!report) return <p>No report available for Italy.</p>;

  return (
    <>
      <h2 className="country-name">Italy</h2>
      <ErrorBoundary>
        <div className="card-body d-flex flex-row justify-content-between">
          <div className="content" style={{ width: "55%" }}>
            {renderItalyReport()}
          </div>
          {/* <div
            className="charts-container"
            style={{ width: "45%", display: "flex", flexDirection: "column" }}
          >
            <AgMapChartWrapper
              topology={italy_topology}
              ref={influenzaAgMapChartRef}
            />
          </div> */}
        </div>
      </ErrorBoundary>
    </>
  );
});

export default Italy;
