import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchItalyReport = createAsyncThunk(
  'italy/fetchItalyReport',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dashboard/italy-who-summary`);
      if (!response.ok) {
        throw new Error('Failed to fetch Italy report');
      }
      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.toString());
    }
  }
);

const initialState = {
  report: null,
  loading: true,
  error: null
};

const italySlice = createSlice({
  name: 'italy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItalyReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchItalyReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
      })
      .addCase(fetchItalyReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export default italySlice.reducer;
