import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { ErrorBoundary } from "./Whoo";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../UI/Loader/Loader";
import { fetchFranceReport } from "../../redux/slices/france.slice";
import { AgMapChartWrapper } from "../AgMapChart/AgMapChartWrapper";
import EChartsWrapper from "../EChartsWrapper";
import { france_topology } from "../AgMapChart/france_topology";
import { Table } from "../Table/Table";

const France = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.france);
  const [sortedWeeklyData, setSortedWeeklyData] = useState(
    [...(report?.data?.weekly_data_available || [])].sort(
      (a, b) => a.week - b.week
    )
  );

  const influenzaAgMapChartRef = useRef(null);

  useEffect(() => {
    if (!report || report.detail === "list index out of range") {
      dispatch(fetchFranceReport());
    }
    setSortedWeeklyData(
      [...(report?.data?.weekly_data_available || [])].sort(
        (a, b) => a.week - b.week
      )
    );
  }, [dispatch, report]);

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      try {
        const influenzaAgMapChartImage = influenzaAgMapChartRef.current
        ? await influenzaAgMapChartRef.current.getChartImage()
        : null;
        
        return {
          influenzaAgMapChart: influenzaAgMapChartImage,
        };
      } catch (error) {
        console.error("Error getting chart images:", error);
        return null;
      }
    },
  }));

  if (loading) return <Loader />;
  if (!report) return <p className="info-message">No report available.</p>;

  const formatWeekIdToDateRange = (weekId) => {
    const year = parseInt(weekId.slice(0, 4), 10);
    const week = parseInt(weekId.slice(4), 10);

    const startDate = new Date(year, 0, 1 + (week - 1) * 7);
    startDate.setDate(startDate.getDate() + (1 - startDate.getDay()));

    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return `Observed situation in France for the week ${week} of the year ${year}, from ${startDate.toLocaleDateString(
      "en-US",
      options
    )} to ${endDate.toLocaleDateString("en-US", options)} :`;
  };

  const renderRequestedWeekReport = () => {
    const requestedReport = report.data.requested_week_report;

    return (
      <div>
        <h6 className="mt-4 mb-3 report-header">
          {formatWeekIdToDateRange(requestedReport?.week_id)}
        </h6>
        <p>{requestedReport?.summary_text}</p>
      </div>
    );
  };

  const tableColumns = [
    { label: "Region", accessor: "region" },
    { label: "Incidence", accessor: "incidence" },
    { label: "Incidence rate", accessor: "incidence_rate" },
  ];

  const tableData = report?.data?.requested_week_report?.table_data;

  const customStyles = {
    headerBgColor: "#4CAF50",
    headerTextColor: "#FFFFFF",
    rowBgColor: "#FAFAFA",
    rowTextColor: "#333333",
    alternateRowColor: "#E8F5E9",
  };

  return (
    <>
      <h2 className="country-name">France</h2>
      <ErrorBoundary>
        <div className="card-body d-flex">
          <div className="content" style={{ width: "55%" }}>
            {renderRequestedWeekReport()}
          </div>
          <div
            className="charts-container"
            style={{ width: "45%", display: "flex", flexDirection: "column" }}
          >
            <EChartsWrapper
              ref={influenzaAgMapChartRef}
              color="#3187ae"
              title={"Acute Respiratory Infection (ARI)"}
              data={sortedWeeklyData?.map((data) => {
                return {
                  value: data.inc,
                  date_value: `${String(data.week).slice(0, 4)}, week ${String(
                    data.week
                  ).slice(4)}`,
                };
              })}
            />
          </div>
        </div>
        <div className="content" style={{ width: "90%", margin: "auto" }}>
          <Table
            columns={tableColumns}
            data={tableData}
            customStyles={customStyles}
          />
        </div>
      </ErrorBoundary>
    </>
  );
});

France.displayName = "France";
export default France;
