// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf_page_container {
  padding: 2rem 0 6rem 0;
}

.get_back_btn {
  position: fixed;
  width: 100%;
  padding: 10px 1rem;
  top: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #fff;
  z-index: 999;
}

.get_back_btn > button {
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #3187ae;
  font-size: x-large;
  background-color: #fff;
}

.pdf_download_btn {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #fff;
  z-index: 999;
  padding: 10px 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf_download_btn > button {
  border: none;
  width: 150px;
  height: 55px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  background-color: #fff;
  color: #3187ae;
  &:hover {
    background-color: #3187ae;
    color: #fff;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/pages/styles/PDF.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP;0CACwC;EACxC,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,QAAQ;EACR,WAAW;EACX;0CACwC;EACxC,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB;uCACqC;EACrC,sBAAsB;EACtB,cAAc;EACd;IACE,yBAAyB;IACzB,WAAW;EACb;AACF","sourcesContent":[".pdf_page_container {\n  padding: 2rem 0 6rem 0;\n}\n\n.get_back_btn {\n  position: fixed;\n  width: 100%;\n  padding: 10px 1rem;\n  top: 0;\n  left: 0;\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  background-color: #fff;\n  z-index: 999;\n}\n\n.get_back_btn > button {\n  border: none;\n  width: 45px;\n  height: 45px;\n  border-radius: 50%;\n  color: #3187ae;\n  font-size: x-large;\n  background-color: #fff;\n}\n\n.pdf_download_btn {\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  width: 100%;\n  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,\n    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n  background-color: #fff;\n  z-index: 999;\n  padding: 10px 1rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.pdf_download_btn > button {\n  border: none;\n  width: 150px;\n  height: 55px;\n  border-radius: 5px;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,\n    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n  background-color: #fff;\n  color: #3187ae;\n  &:hover {\n    background-color: #3187ae;\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
