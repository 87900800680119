// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body {
    padding: 20px;
  }
  
  .content {
    /* Additional styles if needed */
  }
  
  .charts-container {
    /* Additional styles if needed */
  }
  
  .chart-item h6 {
    margin-bottom: 10px;
  }
  
  .report-header {
    font-weight: bold;
  }
  
  .report-section {
    font-weight: bold;
  }
  
  .report-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .report-item .icon {
    color: #b0bc04;
    margin-right: 8px;
  }
  
  .loading,
  .error {
    padding: 20px;
    font-size: 1.2em;
    text-align: center;
  }
  
  .error {
    color: red;
  }
  
  @media (max-width: 768px) {
    .card-body {
      flex-direction: column;
    }
  
    .content,
    .charts-container {
      width: 100%;
    }
  
    .charts-container {
      margin-top: 20px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/component/pages/styles/Whoo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;;IAEE,aAAa;IACb,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE;MACE,sBAAsB;IACxB;;IAEA;;MAEE,WAAW;IACb;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".card-body {\n    padding: 20px;\n  }\n  \n  .content {\n    /* Additional styles if needed */\n  }\n  \n  .charts-container {\n    /* Additional styles if needed */\n  }\n  \n  .chart-item h6 {\n    margin-bottom: 10px;\n  }\n  \n  .report-header {\n    font-weight: bold;\n  }\n  \n  .report-section {\n    font-weight: bold;\n  }\n  \n  .report-item {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n  }\n  \n  .report-item .icon {\n    color: #b0bc04;\n    margin-right: 8px;\n  }\n  \n  .loading,\n  .error {\n    padding: 20px;\n    font-size: 1.2em;\n    text-align: center;\n  }\n  \n  .error {\n    color: red;\n  }\n  \n  @media (max-width: 768px) {\n    .card-body {\n      flex-direction: column;\n    }\n  \n    .content,\n    .charts-container {\n      width: 100%;\n    }\n  \n    .charts-container {\n      margin-top: 20px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
