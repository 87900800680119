import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import ReactECharts from 'echarts-for-react';
import PropTypes from 'prop-types';

const EChartsWrapper = forwardRef(({ title, data, color }, ref) => {
  const chartRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getChartImage: () => {
      if (chartRef.current) {
        return chartRef.current.getEchartsInstance().getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff',
        });
      }
      return null;
    },
  }));

  const option = {
    title: {
      text: title,
      left: 'center',
      textStyle: {
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: function (params) {
        const week = params[0].axisValue;
        const inc = params[0].data;
        return `Week: ${week}<br/>Incidence: ${inc}`;
      },
    },
    legend: {
      data: ['Incidence'],
      top: 30,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.map(item => item.date_value),
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
      name: 'Incidence',
    },
    series: [
      {
        name: 'Incidence',
        type: 'line',
        data: data.map(item => item.value),
        smooth: true,
        lineStyle: {
          color: color,
        },
        itemStyle: {
          color: color,
        },
        areaStyle: {
          color: `${color}80`,
        },
      },
    ],
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100,
      },
      {
        type: 'inside',
      },
    ],
  };

  return (
    <div className="chart-item mb-4">
      <ReactECharts ref={chartRef} option={option} style={{ height: '300px', width: '100%' }}/>
    </div>
  );
});

EChartsWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      date_value: PropTypes.string.isRequired,
    })
  ).isRequired,
  color: PropTypes.string.isRequired,
};

export default EChartsWrapper;
