import {
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import html2canvas from "html2canvas";

const FluNetCharts = forwardRef(({ data, country_name }, ref) => {
  const [chartsReady, setChartsReady] = useState(false);
  const containerRefs = {
    specimensChart: useRef(null),
    influenzaAChart: useRef(null),
    influenzaBChart: useRef(null),
  };

  const {
    specimens_time_series = [],
    influenza_a_time_series = [],
    influenza_b_time_series = [],
  } = data || {};

  const formatSpecimensData = specimens_time_series.map((item) => ({
    name: `${item.year}-${item.week}`,
    inf_all: item.inf_all,
    processed: item.processed,
    received: item.received,
  }));

  const formatInfluenzaAData = influenza_a_time_series.map((item) => ({
    name: `${item.year}-${item.week}`,
    h1n1_2009: item.h1n1_2009,
    h3: item.h3,
    h5: item.h5,
    not_subtyped: item.not_subtyped,
  }));

  const formatInfluenzaBData = influenza_b_time_series.map((item) => ({
    name: `${item.year}-${item.week}`,
    victoria_nodel: item.victoria_nodel,
    yamagata: item.yamagata,
    not_determined: item.not_determined,
  }));

  useEffect(() => {
    const timer = setTimeout(() => {
      setChartsReady(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [data]);

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      if (!chartsReady) {
        console.warn("Charts are not ready yet");
        return null;
      }

      const images = {};
      try {
        for (const [key, containerRef] of Object.entries(containerRefs)) {
          if (containerRef.current) {
            const canvas = await html2canvas(containerRef.current, {
              logging: false,
              useCORS: true,
              allowTaint: true,
              scale: 2,
              windowWidth: 1024,
              windowHeight: 768,
            });
            images[key] = canvas.toDataURL("image/png");
          }
        }
        return images;
      } catch (error) {
        console.error("Error capturing charts:", error);
        return null;
      }
    },
  }));

  return (
    <div className="mt-6 space-y-8 mb-3">
      <div
        ref={containerRefs.specimensChart}
        className="bg-white border rounded shadow-md p-4 mb-2"
        style={{ height: "500px" }}
      >
        <h5 className="text-lg font-semibold mb-4">
          Flu Trends in {country_name} from 2020 to date
        </h5>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={formatSpecimensData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="inf_all"
              name="Inf all"
              stroke="#fc7f03"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      {/* 
      <div
        ref={containerRefs.influenzaAChart}
        className="border rounded bg-white shadow-md p-4 mb-2"
        style={{ height: "500px" }}
      >
        <h5 className="text-lg font-semibold mb-4">Influenza A Subtypes</h5>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={formatInfluenzaAData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="h1n1_2009"
              name="H1N1 2009"
              stroke="#8884d8"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="h3"
              name="H3"
              stroke="#82ca9d"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="h5"
              name="H5"
              stroke="#e74c3c"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="not_subtyped"
              name="Not Subtyped"
              stroke="#ff7300"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div> */}

      {/* <div
        ref={containerRefs.influenzaBChart}
        className="border rounded bg-white p-4"
        style={{ height: "500px" }}
      >
        <h5 className="text-lg font-semibold mb-4">Influenza B Lineages</h5>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={formatInfluenzaBData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="victoria_nodel"
              name="Victoria Nodel"
              stroke="#8884d8"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="yamagata"
              name="Yamagata"
              stroke="#8e44ad"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="not_determined"
              name="Not determined"
              stroke="#82ca9d"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div> */}
    </div>
  );
});

export default FluNetCharts;
