const fetchImageAsBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
};

const addChartsToDocument = async (doc, charts) => {
  if (!charts?.length) return;

  doc.addPage();
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.text("Surveillance Charts", 20, 30);

  const pageWidth = doc.internal.pageSize.width;
  const margin = 20;
  const maxImageWidth = (pageWidth - 3 * margin) / 2;
  let currentY = 50;

  for (let i = 0; i < charts.length; i++) {
    const chart = charts[i];
    let imageData = chart.image;

    if (typeof imageData === "string" && imageData.startsWith("http")) {
      imageData = await fetchImageAsBase64(imageData);
    }
    if (!imageData) continue;

    // Create temporary image to get dimensions
    const img = new Image();
    img.src = imageData;
    await new Promise((resolve) => {
      img.onload = resolve;
    });

    const { width, height } = calculateImageDimensions(
      img.width,
      img.height,
      maxImageWidth
    );

    // Check if we need a new page
    if (currentY + height + 30 > doc.internal.pageSize.height - margin) {
      doc.addPage();
      currentY = 30;
    }

    const xPosition = i % 2 === 0 ? margin : margin * 2 + maxImageWidth;

    try {
      doc.addImage(imageData, "JPEG", xPosition, currentY, width, height);

      // Add title below image
      doc.setFontSize(10);
      const titleLines = doc.splitTextToSize(chart.title, width);
      doc.text(titleLines, xPosition, currentY + height + 10);

      // Move to next row if we've placed two images
      if (i % 2 === 1) {
        currentY += height + 30 + titleLines.length * 5;
      }
    } catch (error) {
      console.error("Error adding chart to PDF:", error);
    }
  }
};

const addCommentingSection = (doc) => {
  doc.addPage();
  const margin = 20;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  doc.setFontSize(16);
  doc.setTextColor(41, 128, 185);
  doc.text("Comments:", margin, 30);

  // Calculate Comment Box Height (1/3rd of total page height)
  const commentBoxHeight = (pageHeight - margin * 2) / 3;
  const boxWidth = pageWidth - margin * 2;
  const boxY = 50;

  doc.setDrawColor(150, 150, 150); // Light gray border
  doc.setLineWidth(0.1); // Thin border
  doc.rect(margin, boxY, boxWidth, commentBoxHeight);
};

const calculateImageDimensions = (originalWidth, originalHeight, maxWidth) => {
  const aspectRatio = originalWidth / originalHeight;
  const width = Math.min(maxWidth, originalWidth);
  const height = width / aspectRatio;
  return { width, height };
};

const renderContentSection = (doc, content, startY) => {
  if (!content) return startY;
  const margin = 20;
  const pageWidth = doc.internal.pageSize.width;
  const textWidth = pageWidth - margin * 2;
  let currentY = startY;

  content.forEach((text) => {
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "normal");
    const lines = doc.splitTextToSize(text, textWidth);
    const lineHeight = doc.getLineHeight();
    const contentHeight = lines.length * lineHeight;

    if (currentY + contentHeight > doc.internal.pageSize.height - margin) {
      doc.addPage();
      currentY = margin;
    }
    doc.text(lines, margin, currentY);
    currentY += contentHeight - contentHeight / 2;
  });
  return currentY;
};

const addImages = async (doc, images, startY) => {
  if (!images?.length) return startY;

  const margin = 20;
  const pageWidth = doc.internal.pageSize.width;
  const maxImageWidth = pageWidth - margin * 2;
  let currentY = startY;

  for (const image of images) {
    try {
      const imageData = `data:image/png;base64,${image.base64_data}`;
      const img = new Image();
      img.src = imageData;
      await new Promise((resolve) => {
        img.onload = resolve;
      });

      const { width, height } = calculateImageDimensions(
        img.width,
        img.height,
        maxImageWidth
      );

      // Add new page if image won't fit
      if (currentY + height + margin > doc.internal.pageSize.height - margin) {
        doc.addPage();
        currentY = margin;
      }

      doc.addImage(imageData, "PNG", margin, currentY, width, height);
      currentY += height + margin;

      if (image.caption) {
        doc.setFontSize(10);
        const captionLines = doc.splitTextToSize(image.caption, maxImageWidth);
        doc.text(captionLines, margin, currentY);
        currentY += captionLines.length * doc.getLineHeight() + 10;
      }
    } catch (error) {
      console.error("Error adding image:", error);
    }
  }
  return currentY;
};

export const addRussiaSection = async (doc, russiaReport, russiaRef) => {
  const margin = 20;
  const pageWidth = doc.internal.pageSize.width;

  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!russiaReport) {
      console.warn("Russia report data is missing");
      return;
    }

    doc.addPage();

    // Header section with consistent styling
    doc.setFontSize(18);
    doc.setTextColor(41, 128, 185);
    doc.setFont(undefined, "bold");
    doc.text("Russia", margin, 40);

    // Metadata with proper spacing
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.setFont(undefined, "normal");
    const metadata = [
      `Report for Week ${russiaReport.week} of ${russiaReport.year}`,
      `Last updated: ${new Date().toLocaleDateString()}`,
    ].join("\n");
    doc.text(metadata, margin, 55);

    let currentY = 75;

    if (russiaReport.content_sections) {
      currentY = renderContentSection(
        doc,
        russiaReport.content_sections[1].content,
        currentY
      );
      for (const section of russiaReport.content_sections.slice(1)) {
        if (section.images) {
          currentY = await addImages(doc, section.images, currentY);
        }
      }
    }

    // Add charts if available
    const graphs = await russiaRef.current?.getChartImages();
    if (graphs?.length > 0) {
      await addChartsToDocument(doc, graphs);
    }

    // Add comments section
    addCommentingSection(doc);

    doc.setFontSize(9);
    doc.setTextColor(128, 128, 128);
    doc.text(
      "Source: Research Institute of Influenza, Saint Petersburg, Russia",
      margin,
      doc.internal.pageSize.height - margin
    );
  } catch (error) {
    console.error("Error generating Russia PDF section:", error);
    doc.setFontSize(11);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating Russia report section. Please try again later.",
      margin,
      40
    );
  }
};
