import { useEffect } from 'react';

const useBeforeUnload = () => {
  useEffect(() => {
    let isRefreshing = false;

    const clearLocalStorage = () => {
      if (!isRefreshing) {
        localStorage.removeItem('user');
      }
    };

    const handlePageHide = (event) => {
      if (!event.persisted) {
        // The page is being unloaded (closed or navigated away)
        clearLocalStorage();
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // The tab is being hidden (switched away or minimized)
        clearLocalStorage();
      }
    };

    const handleBeforeUnload = () => {
      isRefreshing = true;
      // Use setTimeout to reset the flag after the current event loop
      setTimeout(() => {
        isRefreshing = false;
      }, 0);
    };

    window.addEventListener('pagehide', handlePageHide);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('pagehide', handlePageHide);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

export default useBeforeUnload;