import { format } from "date-fns";
import { jsPDF } from "jspdf";

const generateGlobalNewsPDF = (news, whoOutbreaks, lastUpdated) => {
  const pdf = new jsPDF({
    orientation: "p",
    unit: "mm",
    format: "a4",
  });

  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const margin = 20;
  const contentWidth = pageWidth - margin * 2;
  const lineHeight = 7;
  let currentY = margin;

  const addWrappedText = (text, x, y, maxWidth, lineHeight = 7) => {
    if (!text) return y;
    const splitText = pdf.splitTextToSize(text, maxWidth);
    splitText.forEach((line) => {
      if (y + lineHeight > pageHeight - margin) {
        pdf.addPage();
        y = margin;
      }
      pdf.text(line, x, y);
      y += lineHeight;
    });
    return y;
  };

  const checkSpaceAndAddPage = (requiredSpace) => {
    if (currentY + requiredSpace > pageHeight - margin) {
      pdf.addPage();
      currentY = margin;
    }
  };

  // Title and Date
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(24);
  pdf.text("Ai Flu Global Health Monitor", pageWidth / 2, currentY, {
    align: "center",
  });

  pdf.setFontSize(12);
  pdf.setFont("helvetica", "normal");
  currentY += 10;
  pdf.text(
    format(new Date(lastUpdated), "EEEE, MMMM dd, yyyy"),
    pageWidth / 2,
    currentY,
    {
      align: "center",
    }
  );

  currentY += 15;

  // WHO Outbreaks Section
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(18);
  pdf.setTextColor(220, 38, 38);
  pdf.text("WHO Disease Outbreak Alerts", margin, currentY);

  currentY += 2;
  pdf.setLineWidth(0.5);
  pdf.line(margin, currentY, pageWidth - margin, currentY);
  pdf.setTextColor(0);
  currentY += 8;

  whoOutbreaks.forEach((outbreak) => {
    checkSpaceAndAddPage(40);

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(14);
    currentY = addWrappedText(outbreak.title, margin, currentY, contentWidth);

    pdf.setFont("helvetica", "italic");
    pdf.setFontSize(10);
    currentY += 5;
    pdf.text(outbreak.date, margin, currentY);
    currentY += 6;

    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    currentY = addWrappedText(
      outbreak.situation_summary,
      margin,
      currentY,
      contentWidth
    );

    currentY += 10;
  });

  checkSpaceAndAddPage(40);
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(18);
  pdf.text("Country Updates", margin, currentY);
  currentY += 2;
  pdf.setLineWidth(0.5);
  pdf.line(margin, currentY, pageWidth - margin, currentY);
  currentY += 8;

  // Country News Section
  Object.entries(news).forEach(([country, articles]) => {
    checkSpaceAndAddPage(30);

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);
    pdf.setTextColor(49, 135, 174);
    currentY += 10;
    pdf.text(country, margin, currentY);

    currentY += 2;
    pdf.setLineWidth(0.5);
    pdf.line(margin, currentY, pageWidth - margin, currentY);

    pdf.setTextColor(0);
    currentY += 8;

    articles.forEach((article) => {
      checkSpaceAndAddPage(50);

      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(14);
      currentY = addWrappedText(
        article.title.replace(/[\[\]]/g, ""),
        margin,
        currentY,
        contentWidth
      );

      pdf.setFont("helvetica", "italic");
      pdf.setFontSize(10);
      currentY += 5;
      const dateText = format(new Date(article.timestamp), "MMMM dd, yyyy");
      pdf.text(dateText, margin, currentY);

      if (article.source) {
        pdf.text(`Source: ${article.source}`, pageWidth - margin, currentY, {
          align: "right",
        });
      }
      currentY += 6;

      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(11);
      currentY = addWrappedText(
        article.summary.replace(/[\[\]]/g, ""),
        margin,
        currentY,
        contentWidth
      );

      if (article.topics && article.topics.length > 0) {
        currentY += 5;
        pdf.setFont("helvetica", "italic");
        pdf.setFontSize(9);
        pdf.text(`Topics: ${article.topics.join(", ")}`, margin, currentY);
      }

      currentY += 10;
    });

    currentY += 5;
  });

  // Add page numbers and footer
  const totalPages = pdf.internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i++) {
    pdf.setPage(i);
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.text(
      `© ${new Date().getFullYear()} AI-Flu Monitor`,
      pageWidth / 2,
      pageHeight - 10,
      { align: "center" }
    );
    pdf.text(
      `Page ${i} of ${totalPages}`,
      pageWidth - margin,
      pageHeight - 10,
      { align: "right" }
    );
  }

  return pdf;
};

export const handleDownloadPdf = (news, whoOutbreaks, lastUpdated) => {
  try {
    const pdf = generateGlobalNewsPDF(news, whoOutbreaks, lastUpdated);
    pdf.save("Ai Flu Global Health News.pdf");
  } catch (error) {
    console.error("PDF generation error:", error);
    alert("An error occurred while generating the PDF. Please try again.");
  }
};
