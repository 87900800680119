export const spainTopology = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "5603",
      properties: {
        name: "A Coruña",
        density: 0,
        path: "/world/Spain/A Coruña",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-7.680295, 43.787137],
              [-7.66231, 43.774811],
              [-7.70106, 43.736949],
              [-7.702594, 43.686355],
              [-7.719299, 43.687384],
              [-7.691004, 43.654053],
              [-7.735484, 43.622138],
              [-7.717925, 43.583406],
              [-7.74625, 43.580755],
              [-7.760311, 43.548029],
              [-7.744426, 43.511896],
              [-7.821658, 43.401678],
              [-7.881939, 43.407532],
              [-7.898186, 43.391134],
              [-7.886953, 43.337176],
              [-7.947113, 43.286022],
              [-7.921855, 43.26946],
              [-7.961389, 43.189835],
              [-7.916828, 43.088271],
              [-7.942985, 43.067624],
              [-7.905313, 42.985396],
              [-7.916304, 42.932301],
              [-7.943322, 42.917351],
              [-7.93551, 42.903456],
              [-7.995434, 42.850362],
              [-8.045931, 42.831486],
              [-8.075085, 42.853313],
              [-8.116846, 42.841186],
              [-8.183273, 42.860138],
              [-8.2208, 42.844294],
              [-8.207272, 42.82516],
              [-8.254569, 42.836704],
              [-8.280583, 42.810873],
              [-8.315098, 42.845849],
              [-8.325286, 42.831186],
              [-8.339679, 42.840642],
              [-8.331633, 42.794429],
              [-8.354719, 42.778819],
              [-8.392079, 42.789359],
              [-8.414658, 42.766603],
              [-8.407329, 42.754023],
              [-8.470355, 42.743683],
              [-8.556538, 42.760605],
              [-8.55497, 42.746512],
              [-8.59993, 42.743118],
              [-8.606636, 42.715152],
              [-8.650944, 42.729153],
              [-8.718301, 42.706003],
              [-8.746957, 42.650813],
              [-8.769996, 42.653081],
              [-8.794888, 42.636589],
              [-8.825941, 42.646099],
              [-8.822374, 42.646164],
              [-8.812258, 42.66723],
              [-8.844604, 42.68704],
              [-8.839999, 42.661026],
              [-8.852042, 42.665216],
              [-8.861103, 42.646432],
              [-8.837742, 42.63229],
              [-8.858931, 42.626605],
              [-8.857394, 42.606375],
              [-8.887678, 42.610525],
              [-8.873495, 42.632383],
              [-8.898921, 42.644556],
              [-8.937292, 42.607091],
              [-8.919883, 42.587003],
              [-8.988298, 42.565837],
              [-8.995907, 42.529566],
              [-9.042837, 42.51737],
              [-9.029734, 42.54802],
              [-9.039259, 42.565556],
              [-9.091423, 42.576485],
              [-9.091003, 42.578046],
              [-9.08949, 42.577389],
              [-9.081864, 42.578003],
              [-9.079257, 42.580122],
              [-9.079206, 42.598083],
              [-9.064513, 42.60034],
              [-9.04053, 42.637358],
              [-9.033804, 42.695198],
              [-8.990929, 42.74053],
              [-8.954214, 42.748501],
              [-8.924577, 42.788292],
              [-8.903925, 42.788444],
              [-8.904158, 42.813826],
              [-8.879419, 42.816094],
              [-8.875721, 42.828914],
              [-8.887761, 42.815745],
              [-8.899052, 42.828998],
              [-8.908848, 42.825151],
              [-8.908957, 42.796348],
              [-8.931338, 42.807313],
              [-8.963117, 42.77857],
              [-8.974836, 42.791352],
              [-8.978638, 42.789999],
              [-8.981594, 42.778062],
              [-9.017839, 42.780972],
              [-9.020175, 42.800714],
              [-9.06388, 42.786906],
              [-9.047886, 42.769679],
              [-9.086338, 42.73774],
              [-9.115675, 42.75413],
              [-9.134566, 42.790697],
              [-9.140589, 42.796098],
              [-9.15105, 42.798046],
              [-9.112177, 42.81299],
              [-9.108191, 42.8355],
              [-9.138238, 42.849856],
              [-9.145617, 42.867201],
              [-9.150222, 42.872155],
              [-9.125483, 42.905613],
              [-9.160775, 42.911877],
              [-9.17278, 42.944299],
              [-9.190993, 42.951899],
              [-9.189053, 42.915314],
              [-9.233321, 42.940916],
              [-9.23328, 42.92684],
              [-9.262681, 42.919297],
              [-9.273273, 42.880063],
              [-9.275535, 42.910345],
              [-9.297924, 42.923081],
              [-9.257235, 43.002445],
              [-9.301388, 43.049422],
              [-9.261916, 43.048581],
              [-9.249545, 43.071458],
              [-9.257758, 43.090685],
              [-9.221015, 43.088429],
              [-9.220198, 43.113993],
              [-9.198407, 43.103982],
              [-9.19038, 43.090079],
              [-9.167132, 43.105463],
              [-9.16448, 43.152667],
              [-9.192733, 43.122087],
              [-9.214076, 43.161846],
              [-9.199286, 43.154361],
              [-9.180214, 43.168314],
              [-9.174547, 43.191558],
              [-9.161358, 43.18327],
              [-9.150129, 43.195372],
              [-9.106971, 43.185306],
              [-9.099971, 43.1874],
              [-9.098276, 43.189885],
              [-9.09849, 43.193132],
              [-9.090671, 43.180624],
              [-9.059675, 43.184968],
              [-9.012862, 43.233464],
              [-8.99599, 43.216268],
              [-8.980904, 43.229348],
              [-8.939755, 43.226873],
              [-8.952759, 43.263471],
              [-8.976855, 43.261994],
              [-8.991289, 43.274739],
              [-8.984814, 43.282308],
              [-8.956794, 43.293741],
              [-8.927722, 43.281979],
              [-8.906396, 43.294185],
              [-8.911459, 43.303042],
              [-8.906565, 43.306077],
              [-8.911805, 43.320919],
              [-8.875531, 43.312497],
              [-8.848326, 43.319558],
              [-8.841195, 43.345283],
              [-8.785, 43.303859],
              [-8.747723, 43.294303],
              [-8.725914, 43.298929],
              [-8.711589, 43.289971],
              [-8.607177, 43.32234],
              [-8.604011, 43.317823],
              [-8.56811, 43.311778],
              [-8.535033, 43.315963],
              [-8.505873, 43.330626],
              [-8.493298, 43.360391],
              [-8.456739, 43.359815],
              [-8.439757, 43.381502],
              [-8.40886, 43.369262],
              [-8.40077, 43.389973],
              [-8.374294, 43.365054],
              [-8.404019, 43.363685],
              [-8.378441, 43.357269],
              [-8.38704, 43.33948],
              [-8.356071, 43.343541],
              [-8.337794, 43.37695],
              [-8.355293, 43.390353],
              [-8.306863, 43.405671],
              [-8.208817, 43.327838],
              [-8.223046, 43.386892],
              [-8.173697, 43.419895],
              [-8.233175, 43.429289],
              [-8.25937, 43.417856],
              [-8.279704, 43.436623],
              [-8.316563, 43.44501],
              [-8.254919, 43.46618],
              [-8.201467, 43.46007],
              [-8.169598, 43.480524],
              [-8.170032, 43.498456],
              [-8.152321, 43.504092],
              [-8.151374, 43.517495],
              [-8.200478, 43.477175],
              [-8.248593, 43.476185],
              [-8.247325, 43.493331],
              [-8.266188, 43.467713],
              [-8.341093, 43.458367],
              [-8.319695, 43.498042],
              [-8.332271, 43.513038],
              [-8.296325, 43.535024],
              [-8.322068, 43.565234],
              [-8.260084, 43.557904],
              [-8.192462, 43.603083],
              [-8.18843, 43.621127],
              [-8.155512, 43.615284],
              [-8.093293, 43.666623],
              [-8.086818, 43.661475],
              [-8.077287, 43.64421],
              [-8.053869, 43.648941],
              [-8.07589, 43.66027],
              [-8.075791, 43.682879],
              [-8.066627, 43.682621],
              [-8.068399, 43.69583],
              [-8.063441, 43.697509],
              [-8.064142, 43.704058],
              [-8.032984, 43.714593],
              [-8.025565, 43.709141],
              [-8.000395, 43.705107],
              [-7.946571, 43.744059],
              [-7.922735, 43.743227],
              [-7.92207, 43.747531],
              [-7.904462, 43.76986],
              [-7.86835, 43.77355],
              [-7.86821, 43.771674],
              [-7.863359, 43.766092],
              [-7.859274, 43.763521],
              [-7.859221, 43.759958],
              [-7.872034, 43.735447],
              [-7.849267, 43.710309],
              [-7.876024, 43.723285],
              [-7.869354, 43.707167],
              [-7.892, 43.684754],
              [-7.87186, 43.671986],
              [-7.904477, 43.671353],
              [-7.846972, 43.668203],
              [-7.868242, 43.697897],
              [-7.860483, 43.706735],
              [-7.834037, 43.711055],
              [-7.838847, 43.696204],
              [-7.814926, 43.695145],
              [-7.829565, 43.710074],
              [-7.81257, 43.726583],
              [-7.725448, 43.746754],
              [-7.680295, 43.787137],
            ],
            [
              [-8.35039, 43.348912],
              [-8.351184, 43.348406],
              [-8.349938, 43.347708],
              [-8.349347, 43.348699],
              [-8.35039, 43.348912],
            ],
            [
              [-7.976544, 43.719627],
              [-7.976358, 43.720536],
              [-7.977948, 43.7207],
              [-7.977618, 43.720013],
              [-7.976544, 43.719627],
            ],
            [
              [-8.109059, 43.648426],
              [-8.109557, 43.649034],
              [-8.110275, 43.64829],
              [-8.109198, 43.648014],
              [-8.109059, 43.648426],
            ],
            [
              [-9.214233, 43.109332],
              [-9.21351, 43.109713],
              [-9.213536, 43.110099],
              [-9.214256, 43.110294],
              [-9.214233, 43.109332],
            ],
            [
              [-8.474006, 43.35694],
              [-8.47339, 43.357918],
              [-8.473603, 43.358026],
              [-8.474687, 43.356911],
              [-8.474006, 43.35694],
            ],
            [
              [-7.975897, 43.720061],
              [-7.976139, 43.719393],
              [-7.975449, 43.719153],
              [-7.975249, 43.719882],
              [-7.975897, 43.720061],
            ],
            [
              [-9.036098, 43.206158],
              [-9.036489, 43.206758],
              [-9.037336, 43.205741],
              [-9.0365, 43.205875],
              [-9.036098, 43.206158],
            ],
            [
              [-9.045996, 42.570231],
              [-9.046321, 42.571069],
              [-9.046578, 42.571082],
              [-9.046594, 42.570496],
              [-9.045996, 42.570231],
            ],
            [
              [-9.259909, 42.994026],
              [-9.260086, 42.994925],
              [-9.260682, 42.995234],
              [-9.260416, 42.994196],
              [-9.259909, 42.994026],
            ],
            [
              [-7.870837, 43.772057],
              [-7.870599, 43.772184],
              [-7.870174, 43.772301],
              [-7.871456, 43.772328],
              [-7.870837, 43.772057],
            ],
            [
              [-8.094314, 43.660087],
              [-8.093948, 43.660445],
              [-8.093929, 43.660518],
              [-8.094727, 43.66042],
              [-8.094314, 43.660087],
            ],
            [
              [-9.091111, 42.742203],
              [-9.091093, 42.743014],
              [-9.091385, 42.743124],
              [-9.091541, 42.742603],
              [-9.091111, 42.742203],
            ],
            [
              [-8.28328, 43.386844],
              [-8.283883, 43.386825],
              [-8.284044, 43.386683],
              [-8.283051, 43.386486],
              [-8.28328, 43.386844],
            ],
            [
              [-9.117454, 42.842214],
              [-9.118151, 42.842338],
              [-9.117632, 42.841664],
              [-9.117359, 42.841751],
              [-9.117454, 42.842214],
            ],
            [
              [-9.274937, 42.963837],
              [-9.275325, 42.963672],
              [-9.274972, 42.963214],
              [-9.274707, 42.963859],
              [-9.274937, 42.963837],
            ],
            [
              [-8.962132, 43.289492],
              [-8.962054, 43.29045],
              [-8.962095, 43.290493],
              [-8.962421, 43.290179],
              [-8.962132, 43.289492],
            ],
            [
              [-8.277614, 43.558479],
              [-8.277275, 43.55899],
              [-8.277348, 43.559122],
              [-8.277841, 43.558676],
              [-8.277614, 43.558479],
            ],
            [
              [-7.988778, 43.712107],
              [-7.989268, 43.712481],
              [-7.989445, 43.712366],
              [-7.98913, 43.711866],
              [-7.988778, 43.712107],
            ],
            [
              [-8.301365, 43.561671],
              [-8.302111, 43.561708],
              [-8.30223, 43.561514],
              [-8.302091, 43.56134],
              [-8.301365, 43.561671],
            ],
            [
              [-8.094042, 43.664042],
              [-8.09369, 43.664274],
              [-8.094394, 43.664387],
              [-8.094321, 43.664111],
              [-8.094042, 43.664042],
            ],
            [
              [-7.8794, 43.765839],
              [-7.879405, 43.766199],
              [-7.879755, 43.766229],
              [-7.879846, 43.765973],
              [-7.8794, 43.765839],
            ],
            [
              [-9.214837, 43.110325],
              [-9.214917, 43.110753],
              [-9.215191, 43.110909],
              [-9.215167, 43.110424],
              [-9.214837, 43.110325],
            ],
            [
              [-8.23594, 43.572807],
              [-8.236424, 43.572821],
              [-8.236493, 43.57262],
              [-8.236162, 43.572374],
              [-8.23594, 43.572807],
            ],
            [
              [-9.020745, 43.21842],
              [-9.020612, 43.218697],
              [-9.02082, 43.218929],
              [-9.021079, 43.21869],
              [-9.020745, 43.21842],
            ],
            [
              [-9.02069, 43.222978],
              [-9.020663, 43.223204],
              [-9.02118, 43.22333],
              [-9.020858, 43.222933],
              [-9.02069, 43.222978],
            ],
            [
              [-8.956218, 42.581224],
              [-8.956152, 42.581561],
              [-8.956546, 42.581468],
              [-8.956576, 42.581278],
              [-8.956218, 42.581224],
            ],
            [
              [-7.978845, 43.719383],
              [-7.978999, 43.719745],
              [-7.979353, 43.719523],
              [-7.979165, 43.71936],
              [-7.978845, 43.719383],
            ],
            [
              [-8.985611, 43.270617],
              [-8.98529, 43.270733],
              [-8.985206, 43.271008],
              [-8.985566, 43.270917],
              [-8.985611, 43.270617],
            ],
            [
              [-9.290423, 42.936574],
              [-9.29039, 42.936728],
              [-9.290748, 42.936997],
              [-9.290774, 42.936653],
              [-9.290423, 42.936574],
            ],
            [
              [-8.981999, 43.267206],
              [-8.982191, 43.267538],
              [-8.982448, 43.267264],
              [-8.982366, 43.267061],
              [-8.981999, 43.267206],
            ],
            [
              [-8.269542, 43.429306],
              [-8.269747, 43.429107],
              [-8.269408, 43.428907],
              [-8.269305, 43.429137],
              [-8.269542, 43.429306],
            ],
            [
              [-9.117078, 42.84299],
              [-9.117035, 42.84329],
              [-9.117405, 42.843324],
              [-9.117368, 42.843074],
              [-9.117078, 42.84299],
            ],
            [
              [-9.277287, 42.962006],
              [-9.277149, 42.962455],
              [-9.277412, 42.962504],
              [-9.277492, 42.962211],
              [-9.277287, 42.962006],
            ],
            [
              [-8.237508, 43.572618],
              [-8.237396, 43.572894],
              [-8.237886, 43.572691],
              [-8.237854, 43.572602],
              [-8.237508, 43.572618],
            ],
            [
              [-8.23694, 43.572203],
              [-8.236894, 43.57253],
              [-8.237086, 43.572602],
              [-8.237182, 43.572282],
              [-8.23694, 43.572203],
            ],
            [
              [-7.988835, 43.712644],
              [-7.989067, 43.712895],
              [-7.989317, 43.712767],
              [-7.989192, 43.712611],
              [-7.988835, 43.712644],
            ],
            [
              [-7.97361, 43.719431],
              [-7.973535, 43.719876],
              [-7.97359, 43.719936],
              [-7.973856, 43.7197],
              [-7.97361, 43.719431],
            ],
            [
              [-9.021887, 43.21827],
              [-9.021907, 43.218602],
              [-9.02226, 43.21843],
              [-9.022126, 43.21832],
              [-9.021887, 43.21827],
            ],
            [
              [-7.688829, 43.751389],
              [-7.689306, 43.751612],
              [-7.689533, 43.751495],
              [-7.689374, 43.751375],
              [-7.688829, 43.751389],
            ],
            [
              [-8.301148, 43.562023],
              [-8.301204, 43.562381],
              [-8.301488, 43.562368],
              [-8.3013, 43.562061],
              [-8.301148, 43.562023],
            ],
            [
              [-9.021619, 43.21805],
              [-9.021289, 43.218319],
              [-9.021294, 43.218373],
              [-9.021815, 43.218165],
              [-9.021619, 43.21805],
            ],
            [
              [-8.230414, 43.576194],
              [-8.230427, 43.576482],
              [-8.230693, 43.57655],
              [-8.230669, 43.576272],
              [-8.230414, 43.576194],
            ],
            [
              [-8.985594, 43.270195],
              [-8.985413, 43.270358],
              [-8.985479, 43.270525],
              [-8.985857, 43.270235],
              [-8.985594, 43.270195],
            ],
            [
              [-7.934809, 43.743281],
              [-7.934733, 43.743563],
              [-7.935039, 43.743514],
              [-7.934995, 43.743273],
              [-7.934809, 43.743281],
            ],
            [
              [-8.218235, 43.584269],
              [-8.21821, 43.583983],
              [-8.217893, 43.584033],
              [-8.21789, 43.584141],
              [-8.218235, 43.584269],
            ],
            [
              [-7.989722, 43.712417],
              [-7.989528, 43.712623],
              [-7.989628, 43.712781],
              [-7.989909, 43.71257],
              [-7.989722, 43.712417],
            ],
            [
              [-8.075215, 43.673272],
              [-8.075085, 43.673494],
              [-8.075435, 43.673524],
              [-8.075447, 43.67337],
              [-8.075215, 43.673272],
            ],
            [
              [-8.065865, 43.691234],
              [-8.066206, 43.691453],
              [-8.066362, 43.691392],
              [-8.066337, 43.69124],
              [-8.065865, 43.691234],
            ],
            [
              [-7.934994, 43.742967],
              [-7.934963, 43.743184],
              [-7.935339, 43.743078],
              [-7.935255, 43.742965],
              [-7.934994, 43.742967],
            ],
            [
              [-7.94065, 43.74319],
              [-7.940661, 43.743469],
              [-7.940885, 43.743477],
              [-7.940917, 43.74326],
              [-7.94065, 43.74319],
            ],
            [
              [-8.242398, 43.568477],
              [-8.242559, 43.568614],
              [-8.242936, 43.568534],
              [-8.242464, 43.568375],
              [-8.242398, 43.568477],
            ],
            [
              [-9.117836, 42.844345],
              [-9.117995, 42.844588],
              [-9.118216, 42.844468],
              [-9.118097, 42.844385],
              [-9.117836, 42.844345],
            ],
            [
              [-8.303624, 43.56189],
              [-8.303656, 43.562114],
              [-8.304035, 43.562051],
              [-8.303743, 43.561831],
              [-8.303624, 43.56189],
            ],
            [
              [-9.141877, 42.882615],
              [-9.141699, 42.882795],
              [-9.141728, 42.882965],
              [-9.14202, 42.882823],
              [-9.141877, 42.882615],
            ],
            [
              [-8.087148, 43.659363],
              [-8.086931, 43.659588],
              [-8.086953, 43.659696],
              [-8.087296, 43.6595],
              [-8.087148, 43.659363],
            ],
            [
              [-7.975954, 43.718807],
              [-7.975795, 43.718985],
              [-7.976146, 43.719024],
              [-7.97613, 43.718835],
              [-7.975954, 43.718807],
            ],
            [
              [-8.302702, 43.561672],
              [-8.302713, 43.561942],
              [-8.303073, 43.561808],
              [-8.302939, 43.561697],
              [-8.302702, 43.561672],
            ],
            [
              [-7.941118, 43.74299],
              [-7.941352, 43.743268],
              [-7.941495, 43.743189],
              [-7.941433, 43.743048],
              [-7.941118, 43.74299],
            ],
            [
              [-8.943148, 42.580591],
              [-8.943067, 42.580758],
              [-8.94334, 42.580815],
              [-8.943393, 42.580605],
              [-8.943148, 42.580591],
            ],
            [
              [-7.919253, 43.749525],
              [-7.919207, 43.749717],
              [-7.919495, 43.749749],
              [-7.919464, 43.749525],
              [-7.919253, 43.749525],
            ],
            [
              [-9.077822, 42.590262],
              [-9.077801, 42.590425],
              [-9.078095, 42.590436],
              [-9.07809, 42.590266],
              [-9.077822, 42.590262],
            ],
            [
              [-9.155439, 43.18593],
              [-9.155758, 43.186165],
              [-9.155849, 43.186088],
              [-9.155749, 43.185949],
              [-9.155439, 43.18593],
            ],
            [
              [-8.280364, 43.558793],
              [-8.280252, 43.559059],
              [-8.280343, 43.559109],
              [-8.280525, 43.55893],
              [-8.280364, 43.558793],
            ],
            [
              [-7.978866, 43.718896],
              [-7.978915, 43.719038],
              [-7.979195, 43.719116],
              [-7.979104, 43.718921],
              [-7.978866, 43.718896],
            ],
            [
              [-7.910114, 43.761361],
              [-7.910115, 43.761523],
              [-7.910358, 43.761612],
              [-7.910287, 43.761354],
              [-7.910114, 43.761361],
            ],
            [
              [-9.071981, 42.598953],
              [-9.071944, 42.599072],
              [-9.072267, 42.599136],
              [-9.072237, 42.598958],
              [-9.071981, 42.598953],
            ],
            [
              [-7.876756, 43.76724],
              [-7.87676, 43.767438],
              [-7.877048, 43.767317],
              [-7.876966, 43.767222],
              [-7.876756, 43.76724],
            ],
            [
              [-9.117994, 42.840754],
              [-9.117911, 42.840902],
              [-9.118249, 42.840857],
              [-9.11818, 42.840771],
              [-9.117994, 42.840754],
            ],
            [
              [-9.01861, 43.223349],
              [-9.018523, 43.22347],
              [-9.018647, 43.223725],
              [-9.018758, 43.223476],
              [-9.01861, 43.223349],
            ],
            [
              [-9.262494, 42.988591],
              [-9.262536, 42.988769],
              [-9.262811, 42.988817],
              [-9.262735, 42.988677],
              [-9.262494, 42.988591],
            ],
            [
              [-8.082155, 43.651798],
              [-8.082056, 43.651938],
              [-8.082275, 43.652045],
              [-8.082338, 43.651907],
              [-8.082155, 43.651798],
            ],
            [
              [-9.077845, 42.587128],
              [-9.077915, 42.58734],
              [-9.078142, 42.587292],
              [-9.078029, 42.587136],
              [-9.077845, 42.587128],
            ],
            [
              [-9.153142, 43.187422],
              [-9.153128, 43.187531],
              [-9.153525, 43.187807],
              [-9.153427, 43.187569],
              [-9.153142, 43.187422],
            ],
            [
              [-8.228724, 43.576128],
              [-8.228665, 43.576301],
              [-8.228702, 43.576435],
              [-8.228909, 43.576254],
              [-8.228724, 43.576128],
            ],
            [
              [-8.942972, 42.581294],
              [-8.942915, 42.581468],
              [-8.943144, 42.581447],
              [-8.943153, 42.581284],
              [-8.942972, 42.581294],
            ],
            [
              [-7.990087, 43.712176],
              [-7.989923, 43.712291],
              [-7.989918, 43.712381],
              [-7.99022, 43.712287],
              [-7.990087, 43.712176],
            ],
            [
              [-9.278161, 42.958079],
              [-9.278321, 42.958088],
              [-9.278578, 42.957957],
              [-9.278293, 42.957927],
              [-9.278161, 42.958079],
            ],
            [
              [-8.987092, 43.277156],
              [-8.987009, 43.277313],
              [-8.987206, 43.277438],
              [-8.987218, 43.277185],
              [-8.987092, 43.277156],
            ],
            [
              [-9.015689, 43.230056],
              [-9.015796, 43.230266],
              [-9.015952, 43.230222],
              [-9.015836, 43.230039],
              [-9.015689, 43.230056],
            ],
            [
              [-9.020347, 43.22025],
              [-9.020457, 43.220244],
              [-9.020506, 43.220116],
              [-9.020236, 43.220121],
              [-9.020347, 43.22025],
            ],
            [
              [-8.95476, 42.579986],
              [-8.954684, 42.580089],
              [-8.955049, 42.580088],
              [-8.954992, 42.580001],
              [-8.95476, 42.579986],
            ],
            [
              [-8.968904, 43.22535],
              [-8.969141, 43.225509],
              [-8.969209, 43.225325],
              [-8.968904, 43.22535],
            ],
            [
              [-8.229229, 43.576375],
              [-8.229301, 43.576497],
              [-8.229432, 43.576293],
              [-8.229274, 43.576183],
              [-8.229229, 43.576375],
            ],
            [
              [-9.11795, 42.840306],
              [-9.117865, 42.840437],
              [-9.118043, 42.840508],
              [-9.118113, 42.840342],
              [-9.11795, 42.840306],
            ],
            [
              [-9.274525, 42.962077],
              [-9.274552, 42.96222],
              [-9.274846, 42.962222],
              [-9.274764, 42.962136],
              [-9.274525, 42.962077],
            ],
            [
              [-8.954734, 42.580483],
              [-8.954615, 42.580516],
              [-8.95454, 42.580619],
              [-8.954904, 42.580483],
              [-8.954734, 42.580483],
            ],
            [
              [-8.98441, 43.269681],
              [-8.984271, 43.269896],
              [-8.984325, 43.269947],
              [-8.984552, 43.269746],
              [-8.98441, 43.269681],
            ],
            [
              [-9.067714, 42.572526],
              [-9.067715, 42.572661],
              [-9.067942, 42.572739],
              [-9.067915, 42.572588],
              [-9.067714, 42.572526],
            ],
            [
              [-8.979641, 43.28383],
              [-8.979537, 43.283899],
              [-8.979849, 43.283937],
              [-8.979813, 43.28383],
              [-8.979641, 43.28383],
            ],
            [
              [-8.956189, 43.264408],
              [-8.956119, 43.264448],
              [-8.95611, 43.26461],
              [-8.956322, 43.264509],
              [-8.956189, 43.264408],
            ],
            [
              [-9.259302, 42.993006],
              [-9.259469, 42.993204],
              [-9.259614, 42.993178],
              [-9.259557, 42.9931],
              [-9.259302, 42.993006],
            ],
            [
              [-9.021903, 43.22304],
              [-9.021857, 43.223078],
              [-9.021912, 43.223264],
              [-9.022104, 43.223083],
              [-9.021903, 43.22304],
            ],
            [
              [-8.086652, 43.657314],
              [-8.086497, 43.657393],
              [-8.086863, 43.657323],
              [-8.086746, 43.657256],
              [-8.086652, 43.657314],
            ],
            [
              [-9.260049, 42.992272],
              [-9.259964, 42.992402],
              [-9.260019, 42.992462],
              [-9.260192, 42.992354],
              [-9.260049, 42.992272],
            ],
            [
              [-9.261745, 42.984851],
              [-9.261717, 42.9847],
              [-9.261434, 42.984806],
              [-9.26145, 42.98485],
              [-9.261745, 42.984851],
            ],
            [
              [-9.282277, 42.947383],
              [-9.282168, 42.947515],
              [-9.28239, 42.94753],
              [-9.28243, 42.947438],
              [-9.282277, 42.947383],
            ],
            [
              [-9.274286, 42.964422],
              [-9.27419, 42.964562],
              [-9.274401, 42.964587],
              [-9.274414, 42.964478],
              [-9.274286, 42.964422],
            ],
            [
              [-9.078703, 42.582681],
              [-9.078679, 42.582808],
              [-9.078877, 42.582842],
              [-9.078873, 42.582681],
              [-9.078703, 42.582681],
            ],
            [
              [-9.021653, 43.218516],
              [-9.021612, 43.218725],
              [-9.021821, 43.218723],
              [-9.021799, 43.218625],
              [-9.021653, 43.218516],
            ],
            [
              [-9.260964, 42.986569],
              [-9.261091, 42.986742],
              [-9.261238, 42.986734],
              [-9.2611, 42.986588],
              [-9.260964, 42.986569],
            ],
            [
              [-8.230243, 43.575077],
              [-8.230367, 43.575224],
              [-8.230586, 43.575322],
              [-8.230421, 43.575131],
              [-8.230243, 43.575077],
            ],
            [
              [-9.141856, 42.883021],
              [-9.141848, 42.883183],
              [-9.14196, 42.883204],
              [-9.142014, 42.883012],
              [-9.141856, 42.883021],
            ],
            [
              [-9.285369, 43.038145],
              [-9.285376, 43.038334],
              [-9.285466, 43.038374],
              [-9.285454, 43.038023],
              [-9.285369, 43.038145],
            ],
            [
              [-9.015581, 43.223346],
              [-9.015491, 43.223432],
              [-9.015514, 43.223539],
              [-9.015734, 43.223401],
              [-9.015581, 43.223346],
            ],
            [
              [-9.278213, 42.959912],
              [-9.278152, 42.960032],
              [-9.278306, 42.960096],
              [-9.278341, 42.959959],
              [-9.278213, 42.959912],
            ],
            [
              [-9.07724, 42.587295],
              [-9.077196, 42.58746],
              [-9.077353, 42.587451],
              [-9.077377, 42.587324],
              [-9.07724, 42.587295],
            ],
            [
              [-8.970334, 43.224744],
              [-8.970283, 43.224855],
              [-8.970542, 43.224733],
              [-8.970392, 43.224714],
              [-8.970334, 43.224744],
            ],
            [
              [-9.262603, 42.980763],
              [-9.262507, 42.980903],
              [-9.262762, 42.98088],
              [-9.262677, 42.980768],
              [-9.262603, 42.980763],
            ],
            [
              [-9.262159, 42.9841],
              [-9.262079, 42.984158],
              [-9.262238, 42.984275],
              [-9.262304, 42.9842],
              [-9.262159, 42.9841],
            ],
            [
              [-8.082877, 43.653143],
              [-8.082972, 43.653238],
              [-8.083115, 43.653178],
              [-8.083071, 43.653099],
              [-8.082877, 43.653143],
            ],
            [
              [-9.018954, 42.709809],
              [-9.019024, 42.71003],
              [-9.019112, 42.710052],
              [-9.019048, 42.709777],
              [-9.018954, 42.709809],
            ],
            [
              [-9.261725, 42.98442],
              [-9.26172, 42.984484],
              [-9.261913, 42.984572],
              [-9.261912, 42.984446],
              [-9.261725, 42.98442],
            ],
            [
              [-9.262508, 42.983918],
              [-9.262509, 42.984044],
              [-9.26266, 42.98409],
              [-9.262621, 42.983948],
              [-9.262508, 42.983918],
            ],
            [
              [-9.279591, 42.956839],
              [-9.279577, 42.956947],
              [-9.279769, 42.956901],
              [-9.279687, 42.956815],
              [-9.279591, 42.956839],
            ],
            [
              [-7.821331, 43.715257],
              [-7.821442, 43.715252],
              [-7.821288, 43.715034],
              [-7.821331, 43.715257],
            ],
            [
              [-8.986762, 43.223568],
              [-8.986682, 43.223626],
              [-8.986818, 43.223763],
              [-8.986894, 43.22366],
              [-8.986762, 43.223568],
            ],
            [
              [-8.238977, 43.571417],
              [-8.238972, 43.571507],
              [-8.239031, 43.571612],
              [-8.239132, 43.57149],
              [-8.238977, 43.571417],
            ],
            [
              [-9.014299, 43.229184],
              [-9.014297, 43.229292],
              [-9.014258, 43.229402],
              [-9.014429, 43.229258],
              [-9.014299, 43.229184],
            ],
            [
              [-9.261516, 42.979112],
              [-9.261522, 42.979292],
              [-9.261626, 42.979358],
              [-9.261591, 42.979126],
              [-9.261516, 42.979112],
            ],
            [
              [-9.017258, 43.223501],
              [-9.017182, 43.223604],
              [-9.01723, 43.22371],
              [-9.017333, 43.223632],
              [-9.017258, 43.223501],
            ],
            [
              [-8.238576, 43.571507],
              [-8.238534, 43.571599],
              [-8.238695, 43.571592],
              [-8.238694, 43.571439],
              [-8.238576, 43.571507],
            ],
            [
              [-9.26066, 42.991653],
              [-9.26061, 42.991764],
              [-9.260784, 42.99179],
              [-9.260787, 42.991691],
              [-9.26066, 42.991653],
            ],
            [
              [-8.228445, 43.57804],
              [-8.228542, 43.578162],
              [-8.228675, 43.578128],
              [-8.228568, 43.578034],
              [-8.228445, 43.57804],
            ],
            [
              [-9.067529, 42.573004],
              [-9.067504, 42.573123],
              [-9.067605, 42.573153],
              [-9.067649, 42.572989],
              [-9.067529, 42.573004],
            ],
            [
              [-9.015363, 43.229992],
              [-9.015288, 43.230104],
              [-9.015497, 43.230102],
              [-9.015476, 43.230013],
              [-9.015363, 43.229992],
            ],
            [
              [-8.2307, 43.574921],
              [-8.230886, 43.575065],
              [-8.230998, 43.575069],
              [-8.230823, 43.574915],
              [-8.2307, 43.574921],
            ],
            [
              [-9.078409, 42.582912],
              [-9.078329, 42.582971],
              [-9.078311, 42.583035],
              [-9.07849, 42.582998],
              [-9.078409, 42.582912],
            ],
            [
              [-9.018431, 43.223034],
              [-9.018437, 43.223097],
              [-9.018671, 43.223102],
              [-9.018498, 43.222967],
              [-9.018431, 43.223034],
            ],
            [
              [-9.277069, 42.961064],
              [-9.277178, 42.961175],
              [-9.277323, 42.961158],
              [-9.277204, 42.961066],
              [-9.277069, 42.961064],
            ],
            [
              [-9.021105, 43.218824],
              [-9.02105, 43.21889],
              [-9.021301, 43.21894],
              [-9.021163, 43.218794],
              [-9.021105, 43.218824],
            ],
            [
              [-8.098034, 43.65541],
              [-8.097995, 43.655529],
              [-8.098206, 43.655385],
              [-8.098168, 43.655377],
              [-8.098034, 43.65541],
            ],
            [
              [-8.229209, 43.57543],
              [-8.229213, 43.575475],
              [-8.229417, 43.575547],
              [-8.229408, 43.575448],
              [-8.229209, 43.57543],
            ],
            [
              [-9.261157, 42.978844],
              [-9.261091, 42.978919],
              [-9.261087, 42.979001],
              [-9.261259, 42.978883],
              [-9.261157, 42.978844],
            ],
            [
              [-8.227925, 43.576191],
              [-8.227898, 43.57631],
              [-8.228036, 43.57633],
              [-8.228025, 43.576205],
              [-8.227925, 43.576191],
            ],
            [
              [-9.259897, 42.991866],
              [-9.259846, 42.991968],
              [-9.259986, 42.992014],
              [-9.259996, 42.991879],
              [-9.259897, 42.991866],
            ],
            [
              [-8.973263, 43.225239],
              [-8.973192, 43.22527],
              [-8.973487, 43.225389],
              [-8.973503, 43.225307],
              [-8.973263, 43.225239],
            ],
            [
              [-9.262901, 42.98188],
              [-9.262836, 42.981965],
              [-9.26286, 42.982081],
              [-9.262968, 42.981931],
              [-9.262901, 42.98188],
            ],
            [
              [-8.977439, 43.262666],
              [-8.977461, 43.262772],
              [-8.977553, 43.262831],
              [-8.977578, 43.262703],
              [-8.977439, 43.262666],
            ],
            [
              [-9.066412, 42.575044],
              [-9.066472, 42.575158],
              [-9.066589, 42.575107],
              [-9.066545, 42.575028],
              [-9.066412, 42.575044],
            ],
            [
              [-8.230924, 43.574649],
              [-8.230957, 43.574738],
              [-8.231057, 43.574751],
              [-8.230981, 43.574593],
              [-8.230924, 43.574649],
            ],
            [
              [-8.229261, 43.576886],
              [-8.229259, 43.577003],
              [-8.229387, 43.57706],
              [-8.22935, 43.576918],
              [-8.229261, 43.576886],
            ],
            [
              [-9.277375, 42.961425],
              [-9.277325, 42.961536],
              [-9.27746, 42.961538],
              [-9.277461, 42.96143],
              [-9.277375, 42.961425],
            ],
            [
              [-9.274087, 42.961867],
              [-9.274072, 42.961967],
              [-9.274237, 42.961895],
              [-9.274185, 42.961871],
              [-9.274087, 42.961867],
            ],
            [
              [-9.02251, 43.218471],
              [-9.022504, 43.218534],
              [-9.022676, 43.218534],
              [-9.022605, 43.218439],
              [-9.02251, 43.218471],
            ],
            [
              [-8.956376, 42.578038],
              [-8.956348, 42.57813],
              [-8.956509, 42.578149],
              [-8.956475, 42.578051],
              [-8.956376, 42.578038],
            ],
            [
              [-8.243121, 43.568525],
              [-8.24313, 43.568633],
              [-8.243229, 43.568637],
              [-8.243234, 43.568547],
              [-8.243121, 43.568525],
            ],
            [
              [-8.231583, 43.574961],
              [-8.231599, 43.575005],
              [-8.231795, 43.575122],
              [-8.231644, 43.574949],
              [-8.231583, 43.574961],
            ],
            [
              [-8.230965, 43.576547],
              [-8.231, 43.576662],
              [-8.231074, 43.576659],
              [-8.23105, 43.576525],
              [-8.230965, 43.576547],
            ],
            [
              [-9.021034, 43.222725],
              [-9.020866, 43.222761],
              [-9.020832, 43.222799],
              [-9.020971, 43.222828],
              [-9.021034, 43.222725],
            ],
            [
              [-9.065713, 42.57374],
              [-9.065647, 42.573815],
              [-9.06583, 42.573815],
              [-9.065774, 42.573737],
              [-9.065713, 42.57374],
            ],
            [
              [-9.018862, 43.222912],
              [-9.018866, 43.222957],
              [-9.019123, 43.22306],
              [-9.019051, 43.222956],
              [-9.018862, 43.222912],
            ],
            [
              [-8.957944, 42.57838],
              [-8.958047, 42.578446],
              [-8.958101, 42.578372],
              [-8.957976, 42.578333],
              [-8.957944, 42.57838],
            ],
            [
              [-9.010998, 43.225299],
              [-9.011054, 43.225368],
              [-9.011198, 43.225333],
              [-9.011169, 43.22529],
              [-9.010998, 43.225299],
            ],
            [
              [-9.26228, 42.98998],
              [-9.262288, 42.99006],
              [-9.262411, 42.990063],
              [-9.262377, 42.989975],
              [-9.26228, 42.98998],
            ],
            [
              [-8.229688, 43.462281],
              [-8.229745, 43.462369],
              [-8.229828, 43.462329],
              [-8.229771, 43.462241],
              [-8.229688, 43.462281],
            ],
            [
              [-8.987576, 43.276698],
              [-8.987691, 43.276737],
              [-8.987743, 43.276636],
              [-8.987656, 43.276631],
              [-8.987576, 43.276698],
            ],
            [
              [-9.26019, 42.992579],
              [-9.260161, 42.992653],
              [-9.260252, 42.992702],
              [-9.260294, 42.992636],
              [-9.26019, 42.992579],
            ],
            [
              [-8.230721, 43.462216],
              [-8.230738, 43.462278],
              [-8.23086, 43.462254],
              [-8.230842, 43.462192],
              [-8.230721, 43.462216],
            ],
            [
              [-8.234841, 43.462846],
              [-8.234883, 43.462898],
              [-8.235002, 43.462847],
              [-8.234899, 43.462798],
              [-8.234841, 43.462846],
            ],
            [
              [-8.231352, 43.462223],
              [-8.231357, 43.462286],
              [-8.231479, 43.462262],
              [-8.23141, 43.462184],
              [-8.231352, 43.462223],
            ],
            [
              [-9.019737, 43.223172],
              [-9.019734, 43.223271],
              [-9.019855, 43.223247],
              [-9.019849, 43.223184],
              [-9.019737, 43.223172],
            ],
            [
              [-8.23388, 43.462593],
              [-8.233884, 43.462638],
              [-8.234017, 43.462605],
              [-8.233988, 43.462552],
              [-8.23388, 43.462593],
            ],
            [
              [-8.954227, 42.579897],
              [-8.954219, 42.579942],
              [-8.954288, 42.58002],
              [-8.954338, 42.579909],
              [-8.954227, 42.579897],
            ],
            [
              [-9.275311, 42.962457],
              [-9.275319, 42.962538],
              [-9.275419, 42.96255],
              [-9.275426, 42.962505],
              [-9.275311, 42.962457],
            ],
            [
              [-8.233297, 43.462422],
              [-8.233305, 43.462511],
              [-8.233367, 43.462517],
              [-8.233395, 43.462417],
              [-8.233297, 43.462422],
            ],
            [
              [-9.014292, 43.228374],
              [-9.014263, 43.228448],
              [-9.014411, 43.228332],
              [-9.014337, 43.228327],
              [-9.014292, 43.228374],
            ],
            [
              [-8.957785, 42.577992],
              [-8.957782, 42.578091],
              [-8.95789, 42.578077],
              [-8.957833, 42.577989],
              [-8.957785, 42.577992],
            ],
            [
              [-9.110633, 42.820742],
              [-9.110701, 42.82081],
              [-9.11077, 42.82077],
              [-9.110667, 42.820713],
              [-9.110633, 42.820742],
            ],
            [
              [-8.975088, 43.225647],
              [-8.975179, 43.225697],
              [-8.975196, 43.225624],
              [-8.975134, 43.225618],
              [-8.975088, 43.225647],
            ],
            [
              [-8.23738, 43.572417],
              [-8.237387, 43.572507],
              [-8.237469, 43.572449],
              [-8.237427, 43.572397],
              [-8.23738, 43.572417],
            ],
            [
              [-9.014869, 43.223492],
              [-9.014933, 43.223516],
              [-9.014924, 43.223417],
              [-9.014898, 43.223409],
              [-9.014869, 43.223492],
            ],
            [
              [-9.015358, 43.223583],
              [-9.015363, 43.223628],
              [-9.015442, 43.223552],
              [-9.015367, 43.223547],
              [-9.015358, 43.223583],
            ],
          ],
          [
            [
              [-9.012354, 42.482945],
              [-8.99936, 42.468803],
              [-9.010435, 42.462964],
              [-9.020368, 42.474341],
              [-9.012354, 42.482945],
            ],
            [
              [-9.002744, 42.47138],
              [-9.002682, 42.471491],
              [-9.002784, 42.47154],
              [-9.002866, 42.471382],
              [-9.002744, 42.47138],
            ],
          ],
          [
            [
              [-8.837627, 43.362332],
              [-8.844103, 43.355249],
              [-8.849707, 43.361238],
              [-8.844546, 43.361075],
              [-8.839564, 43.363042],
              [-8.837627, 43.362332],
            ],
          ],
          [
            [
              [-8.833543, 43.361797],
              [-8.830845, 43.357344],
              [-8.834135, 43.356149],
              [-8.835706, 43.359265],
              [-8.833543, 43.361797],
            ],
          ],
          [
            [
              [-9.005822, 42.500032],
              [-9.003983, 42.499877],
              [-9.003441, 42.495882],
              [-9.006004, 42.496602],
              [-9.00571, 42.497851],
              [-9.007094, 42.49947],
              [-9.005822, 42.500032],
            ],
          ],
          [
            [
              [-8.833874, 43.356001],
              [-8.831466, 43.354684],
              [-8.831476, 43.352991],
              [-8.835283, 43.353462],
              [-8.833874, 43.356001],
            ],
          ],
          [
            [
              [-8.965392, 42.776687],
              [-8.963295, 42.776355],
              [-8.963104, 42.773098],
              [-8.966494, 42.775315],
              [-8.965392, 42.776687],
            ],
          ],
          [
            [
              [-9.146299, 42.813821],
              [-9.146902, 42.810296],
              [-9.148127, 42.809951],
              [-9.147647, 42.814351],
              [-9.146299, 42.813821],
            ],
          ],
          [
            [
              [-9.04914, 42.510456],
              [-9.048172, 42.509382],
              [-9.04909, 42.507047],
              [-9.050635, 42.509189],
              [-9.04914, 42.510456],
            ],
          ],
          [
            [
              [-8.447759, 43.382093],
              [-8.445973, 43.381449],
              [-8.447947, 43.379518],
              [-8.449247, 43.381346],
              [-8.447759, 43.382093],
            ],
          ],
          [
            [
              [-8.450054, 43.37975],
              [-8.4483, 43.379321],
              [-8.448092, 43.3788],
              [-8.449721, 43.377966],
              [-8.45174, 43.378878],
              [-8.450054, 43.37975],
            ],
          ],
          [
            [
              [-8.939803, 42.551096],
              [-8.93832, 42.549066],
              [-8.93889, 42.548028],
              [-8.940807, 42.550125],
              [-8.939803, 42.551096],
            ],
          ],
          [
            [
              [-8.453136, 43.377523],
              [-8.450947, 43.377745],
              [-8.450217, 43.377447],
              [-8.452489, 43.375808],
              [-8.453136, 43.377523],
            ],
          ],
          [
            [
              [-8.339487, 43.511692],
              [-8.337068, 43.511751],
              [-8.336032, 43.510665],
              [-8.340503, 43.511851],
              [-8.339487, 43.511692],
            ],
          ],
          [
            [
              [-9.152167, 43.196306],
              [-9.153551, 43.196114],
              [-9.153303, 43.198297],
              [-9.152712, 43.197807],
              [-9.152167, 43.196306],
            ],
          ],
          [
            [
              [-8.996293, 42.488444],
              [-8.996146, 42.487542],
              [-8.998305, 42.488365],
              [-8.997188, 42.489531],
              [-8.996293, 42.488444],
            ],
          ],
          [
            [
              [-9.169909, 42.865657],
              [-9.168341, 42.865848],
              [-9.168381, 42.864423],
              [-9.170402, 42.865155],
              [-9.170292, 42.866633],
              [-9.169909, 42.865657],
            ],
          ],
          [
            [
              [-9.150624, 42.805558],
              [-9.149756, 42.80421],
              [-9.150951, 42.803434],
              [-9.151368, 42.805419],
              [-9.150624, 42.805558],
            ],
          ],
          [
            [
              [-8.941975, 43.232095],
              [-8.94353, 43.232392],
              [-8.943738, 43.234146],
              [-8.942333, 43.233373],
              [-8.941975, 43.232095],
            ],
          ],
          [
            [
              [-9.048235, 42.511413],
              [-9.046895, 42.510638],
              [-9.0473, 42.509923],
              [-9.04939, 42.51191],
              [-9.048235, 42.511413],
            ],
          ],
          [
            [
              [-8.876898, 43.321149],
              [-8.875366, 43.320094],
              [-8.874514, 43.319058],
              [-8.877283, 43.320409],
              [-8.876898, 43.321149],
            ],
          ],
          [
            [
              [-9.212645, 43.163617],
              [-9.213447, 43.162556],
              [-9.214466, 43.164355],
              [-9.213849, 43.164325],
              [-9.212645, 43.163617],
            ],
          ],
          [
            [
              [-9.008917, 42.517288],
              [-9.008246, 42.517269],
              [-9.008678, 42.515815],
              [-9.009904, 42.517182],
              [-9.008917, 42.517288],
            ],
          ],
          [
            [
              [-9.114746, 43.197716],
              [-9.114179, 43.196721],
              [-9.114812, 43.195553],
              [-9.115691, 43.197198],
              [-9.114746, 43.197716],
            ],
          ],
          [
            [
              [-9.186929, 43.169894],
              [-9.185853, 43.169836],
              [-9.185172, 43.168937],
              [-9.186877, 43.168655],
              [-9.186929, 43.169894],
            ],
          ],
          [
            [
              [-7.86871, 43.777369],
              [-7.869145, 43.776604],
              [-7.870952, 43.778201],
              [-7.869112, 43.777883],
              [-7.86871, 43.777369],
            ],
          ],
          [
            [
              [-9.185086, 42.881279],
              [-9.184436, 42.879919],
              [-9.185997, 42.879744],
              [-9.18616, 42.880518],
              [-9.185086, 42.881279],
            ],
          ],
          [
            [
              [-8.325822, 43.529391],
              [-8.324555, 43.528766],
              [-8.324594, 43.52781],
              [-8.326349, 43.528493],
              [-8.325822, 43.529391],
            ],
          ],
          [
            [
              [-8.262179, 43.418771],
              [-8.260766, 43.418575],
              [-8.264109, 43.417818],
              [-8.262989, 43.419292],
              [-8.262179, 43.418771],
            ],
          ],
          [
            [
              [-9.001663, 42.490664],
              [-9.002704, 42.490736],
              [-9.002553, 42.492337],
              [-9.001786, 42.491945],
              [-9.001663, 42.490664],
            ],
          ],
          [
            [
              [-7.869021, 43.780282],
              [-7.86761, 43.780189],
              [-7.866628, 43.779574],
              [-7.867457, 43.77907],
              [-7.869021, 43.780282],
            ],
          ],
          [
            [
              [-9.225658, 43.099302],
              [-9.226363, 43.100919],
              [-9.226043, 43.101279],
              [-9.225109, 43.099944],
              [-9.225658, 43.099302],
            ],
          ],
          [
            [
              [-9.141684, 42.805032],
              [-9.142175, 42.805176],
              [-9.142773, 42.806152],
              [-9.141344, 42.806157],
              [-9.141684, 42.805032],
            ],
          ],
          [
            [
              [-8.876843, 42.601217],
              [-8.87783, 42.601985],
              [-8.876865, 42.602863],
              [-8.876588, 42.601995],
              [-8.876843, 42.601217],
            ],
          ],
          [
            [
              [-9.135673, 42.84768],
              [-9.135129, 42.847008],
              [-9.135388, 42.846292],
              [-9.136598, 42.848252],
              [-9.135673, 42.84768],
            ],
          ],
          [
            [
              [-9.137259, 42.808978],
              [-9.137382, 42.808117],
              [-9.138952, 42.808545],
              [-9.138403, 42.809664],
              [-9.137259, 42.808978],
            ],
          ],
          [
            [
              [-7.858132, 43.766594],
              [-7.858074, 43.765723],
              [-7.859709, 43.766275],
              [-7.859252, 43.766619],
              [-7.858132, 43.766594],
            ],
          ],
          [
            [
              [-9.042729, 42.662122],
              [-9.041884, 42.661086],
              [-9.04399, 42.661551],
              [-9.04359, 42.662202],
              [-9.042729, 42.662122],
            ],
          ],
          [
            [
              [-9.168958, 42.799644],
              [-9.168793, 42.798105],
              [-9.169825, 42.798157],
              [-9.16965, 42.799589],
              [-9.168958, 42.799644],
            ],
          ],
          [
            [
              [-8.053994, 43.710567],
              [-8.052921, 43.71021],
              [-8.052853, 43.7097],
              [-8.05377, 43.709677],
              [-8.053994, 43.710567],
            ],
          ],
          [
            [
              [-8.223419, 43.385623],
              [-8.22349, 43.384882],
              [-8.224937, 43.384906],
              [-8.224133, 43.385609],
              [-8.223419, 43.385623],
            ],
          ],
          [
            [
              [-7.86723, 43.780871],
              [-7.865631, 43.780147],
              [-7.865695, 43.779713],
              [-7.867732, 43.780931],
              [-7.86723, 43.780871],
            ],
          ],
          [
            [
              [-8.341441, 43.375906],
              [-8.340879, 43.375968],
              [-8.340282, 43.375357],
              [-8.341848, 43.375194],
              [-8.341441, 43.375906],
            ],
          ],
          [
            [
              [-9.101846, 43.190405],
              [-9.100826, 43.190415],
              [-9.100552, 43.189151],
              [-9.102296, 43.190345],
              [-9.101846, 43.190405],
            ],
          ],
          [
            [
              [-8.191191, 43.613485],
              [-8.190592, 43.612711],
              [-8.191405, 43.612224],
              [-8.191699, 43.613335],
              [-8.191191, 43.613485],
            ],
          ],
          [
            [
              [-8.446807, 43.383308],
              [-8.446387, 43.38331],
              [-8.44546, 43.382058],
              [-8.447345, 43.382706],
              [-8.446807, 43.383308],
            ],
          ],
          [
            [
              [-8.210558, 43.596315],
              [-8.209581, 43.596036],
              [-8.209532, 43.595336],
              [-8.211176, 43.596008],
              [-8.210558, 43.596315],
            ],
          ],
          [
            [
              [-9.032841, 43.217024],
              [-9.032324, 43.216403],
              [-9.031484, 43.21625],
              [-9.032681, 43.216033],
              [-9.032841, 43.217024],
            ],
          ],
          [
            [
              [-9.170445, 42.801436],
              [-9.169644, 42.801372],
              [-9.170169, 42.80038],
              [-9.171114, 42.800914],
              [-9.170445, 42.801436],
            ],
          ],
          [
            [
              [-8.96932, 42.565399],
              [-8.970178, 42.565201],
              [-8.970439, 42.56526],
              [-8.969767, 42.566258],
              [-8.96932, 42.565399],
            ],
          ],
          [
            [
              [-8.84221, 42.626255],
              [-8.8433, 42.625398],
              [-8.843609, 42.625446],
              [-8.84339, 42.626357],
              [-8.84221, 42.626255],
            ],
          ],
          [
            [
              [-9.045232, 42.518476],
              [-9.045541, 42.517776],
              [-9.046491, 42.517528],
              [-9.046205, 42.518353],
              [-9.045232, 42.518476],
            ],
          ],
          [
            [
              [-8.978119, 42.783078],
              [-8.978728, 42.783307],
              [-8.978576, 42.784773],
              [-8.978085, 42.784124],
              [-8.978119, 42.783078],
            ],
          ],
          [
            [
              [-8.336264, 43.405847],
              [-8.336498, 43.406404],
              [-8.335425, 43.406976],
              [-8.335612, 43.406157],
              [-8.336264, 43.405847],
            ],
          ],
          [
            [
              [-9.186751, 42.879208],
              [-9.187145, 42.878989],
              [-9.18797, 42.879277],
              [-9.18693, 42.880134],
              [-9.186751, 42.879208],
            ],
          ],
          [
            [
              [-9.007439, 42.514827],
              [-9.007679, 42.514409],
              [-9.008431, 42.51464],
              [-9.007554, 42.515523],
              [-9.007439, 42.514827],
            ],
          ],
          [
            [
              [-9.146443, 42.817486],
              [-9.145754, 42.817442],
              [-9.146177, 42.816411],
              [-9.146794, 42.817206],
              [-9.146443, 42.817486],
            ],
          ],
          [
            [
              [-8.934655, 42.58133],
              [-8.935361, 42.582086],
              [-8.933945, 42.582573],
              [-8.934614, 42.581917],
              [-8.934655, 42.58133],
            ],
          ],
          [
            [
              [-9.143562, 42.803256],
              [-9.143803, 42.802856],
              [-9.143914, 42.804471],
              [-9.143199, 42.804032],
              [-9.143562, 42.803256],
            ],
          ],
          [
            [
              [-8.197694, 43.611595],
              [-8.1981, 43.612278],
              [-8.19707, 43.612686],
              [-8.197687, 43.611946],
              [-8.197694, 43.611595],
            ],
          ],
          [
            [
              [-9.040899, 43.212015],
              [-9.040342, 43.211487],
              [-9.040266, 43.210843],
              [-9.041501, 43.211884],
              [-9.040899, 43.212015],
            ],
          ],
          [
            [
              [-8.908765, 43.305882],
              [-8.908058, 43.305964],
              [-8.909688, 43.305744],
              [-8.909077, 43.306181],
              [-8.908765, 43.305882],
            ],
          ],
          [
            [
              [-9.09753, 43.193499],
              [-9.097596, 43.192928],
              [-9.098313, 43.193331],
              [-9.097741, 43.19401],
              [-9.09753, 43.193499],
            ],
          ],
          [
            [
              [-9.168955, 42.86628],
              [-9.169401, 42.866249],
              [-9.169899, 42.866918],
              [-9.168943, 42.86719],
              [-9.168955, 42.86628],
            ],
          ],
          [
            [
              [-9.117508, 43.19863],
              [-9.117873, 43.199448],
              [-9.117401, 43.200022],
              [-9.117233, 43.199572],
              [-9.117508, 43.19863],
            ],
          ],
          [
            [
              [-8.990147, 42.775408],
              [-8.990733, 42.775647],
              [-8.990588, 42.776303],
              [-8.989871, 42.775962],
              [-8.990147, 42.775408],
            ],
          ],
          [
            [
              [-9.186375, 42.881434],
              [-9.187013, 42.881453],
              [-9.187326, 42.882012],
              [-9.186343, 42.882093],
              [-9.186375, 42.881434],
            ],
          ],
          [
            [
              [-7.750539, 43.747611],
              [-7.751121, 43.747587],
              [-7.751379, 43.747864],
              [-7.750195, 43.748283],
              [-7.750539, 43.747611],
            ],
          ],
          [
            [
              [-8.758144, 43.301663],
              [-8.757872, 43.301397],
              [-8.758589, 43.300902],
              [-8.758952, 43.301604],
              [-8.758144, 43.301663],
            ],
          ],
          [
            [
              [-8.090447, 43.665967],
              [-8.091042, 43.665959],
              [-8.091579, 43.666583],
              [-8.090438, 43.666292],
              [-8.090447, 43.665967],
            ],
          ],
          [
            [
              [-8.076518, 43.668875],
              [-8.076863, 43.668995],
              [-8.077129, 43.669793],
              [-8.076077, 43.669525],
              [-8.076518, 43.668875],
            ],
          ],
          [
            [
              [-7.754904, 43.742208],
              [-7.755899, 43.742391],
              [-7.75565, 43.743005],
              [-7.755259, 43.742922],
              [-7.754904, 43.742208],
            ],
          ],
          [
            [
              [-8.553934, 43.314629],
              [-8.554353, 43.315032],
              [-8.553431, 43.315464],
              [-8.553231, 43.315024],
              [-8.553934, 43.314629],
            ],
          ],
          [
            [
              [-9.172856, 42.798893],
              [-9.172835, 42.798192],
              [-9.173884, 42.798415],
              [-9.17365, 42.79876],
              [-9.172856, 42.798893],
            ],
          ],
          [
            [
              [-9.006226, 42.514108],
              [-9.006992, 42.513725],
              [-9.007116, 42.513872],
              [-9.006369, 42.514838],
              [-9.006226, 42.514108],
            ],
          ],
          [
            [
              [-9.139362, 42.806759],
              [-9.139776, 42.807241],
              [-9.139062, 42.807432],
              [-9.138937, 42.806917],
              [-9.139362, 42.806759],
            ],
          ],
          [
            [
              [-9.046017, 42.511504],
              [-9.045504, 42.511486],
              [-9.045877, 42.510809],
              [-9.046622, 42.511471],
              [-9.046017, 42.511504],
            ],
          ],
          [
            [
              [-9.297741, 42.921173],
              [-9.298601, 42.921675],
              [-9.29882, 42.922257],
              [-9.298305, 42.922132],
              [-9.297741, 42.921173],
            ],
          ],
          [
            [
              [-9.128795, 42.807822],
              [-9.129304, 42.807777],
              [-9.129662, 42.80819],
              [-9.128912, 42.808509],
              [-9.128795, 42.807822],
            ],
          ],
          [
            [
              [-9.060371, 42.620013],
              [-9.061422, 42.619408],
              [-9.061824, 42.619657],
              [-9.061007, 42.620276],
              [-9.060371, 42.620013],
            ],
          ],
          [
            [
              [-8.855039, 42.626444],
              [-8.855652, 42.625963],
              [-8.855572, 42.627056],
              [-8.855474, 42.627043],
              [-8.855039, 42.626444],
            ],
          ],
          [
            [
              [-8.877738, 43.321034],
              [-8.878263, 43.321115],
              [-8.878475, 43.321518],
              [-8.877835, 43.321785],
              [-8.877738, 43.321034],
            ],
          ],
          [
            [
              [-9.16941, 42.863803],
              [-9.170214, 42.863883],
              [-9.170281, 42.864421],
              [-9.169295, 42.864154],
              [-9.16941, 42.863803],
            ],
          ],
          [
            [
              [-9.135989, 42.790387],
              [-9.13702, 42.790547],
              [-9.13724, 42.790796],
              [-9.13577, 42.790641],
              [-9.135989, 42.790387],
            ],
          ],
          [
            [
              [-8.991279, 42.777635],
              [-8.990955, 42.777184],
              [-8.992018, 42.777065],
              [-8.992026, 42.77765],
              [-8.991279, 42.777635],
            ],
          ],
          [
            [
              [-8.221567, 43.375715],
              [-8.221545, 43.375296],
              [-8.2225, 43.374991],
              [-8.222118, 43.375665],
              [-8.221567, 43.375715],
            ],
          ],
          [
            [
              [-9.081431, 42.732907],
              [-9.081497, 42.732589],
              [-9.082995, 42.732671],
              [-9.081308, 42.733022],
              [-9.081431, 42.732907],
            ],
          ],
          [
            [
              [-9.184651, 42.879016],
              [-9.183873, 42.878707],
              [-9.185698, 42.878842],
              [-9.185487, 42.878934],
              [-9.184651, 42.879016],
            ],
          ],
          [
            [
              [-9.152556, 42.801628],
              [-9.153256, 42.801906],
              [-9.153211, 42.802439],
              [-9.152477, 42.802065],
              [-9.152556, 42.801628],
            ],
          ],
          [
            [
              [-9.005308, 42.493029],
              [-9.004631, 42.492822],
              [-9.004711, 42.492511],
              [-9.005932, 42.49269],
              [-9.005308, 42.493029],
            ],
          ],
          [
            [
              [-8.992753, 42.485641],
              [-8.992562, 42.485048],
              [-8.993529, 42.485996],
              [-8.993172, 42.486204],
              [-8.992753, 42.485641],
            ],
          ],
          [
            [
              [-8.757104, 43.299276],
              [-8.757233, 43.299854],
              [-8.756616, 43.299975],
              [-8.7565, 43.299666],
              [-8.757104, 43.299276],
            ],
          ],
          [
            [
              [-9.12923, 42.771991],
              [-9.129829, 42.772247],
              [-9.128624, 42.772419],
              [-9.129242, 42.772116],
              [-9.12923, 42.771991],
            ],
          ],
          [
            [
              [-9.28908, 42.88912],
              [-9.289563, 42.889301],
              [-9.289698, 42.889779],
              [-9.288744, 42.889427],
              [-9.28908, 42.88912],
            ],
          ],
          [
            [
              [-7.751929, 43.7465],
              [-7.751866, 43.74571],
              [-7.752757, 43.745979],
              [-7.752421, 43.746443],
              [-7.751929, 43.7465],
            ],
          ],
          [
            [
              [-8.194224, 43.613157],
              [-8.194524, 43.613621],
              [-8.194042, 43.61421],
              [-8.193889, 43.613587],
              [-8.194224, 43.613157],
            ],
          ],
          [
            [
              [-8.436875, 43.381703],
              [-8.437344, 43.381699],
              [-8.437149, 43.382419],
              [-8.436714, 43.382386],
              [-8.436875, 43.381703],
            ],
          ],
          [
            [
              [-9.14621, 42.814771],
              [-9.146725, 42.814788],
              [-9.146831, 42.81561],
              [-9.146477, 42.815611],
              [-9.14621, 42.814771],
            ],
          ],
          [
            [
              [-9.170581, 42.863767],
              [-9.169951, 42.863546],
              [-9.169911, 42.863052],
              [-9.170884, 42.863516],
              [-9.170581, 42.863767],
            ],
          ],
          [
            [
              [-9.039149, 43.214107],
              [-9.038667, 43.214087],
              [-9.038598, 43.213515],
              [-9.039252, 43.21366],
              [-9.039149, 43.214107],
            ],
          ],
          [
            [
              [-7.774373, 43.738289],
              [-7.77474, 43.738841],
              [-7.77427, 43.739184],
              [-7.774103, 43.738795],
              [-7.774373, 43.738289],
            ],
          ],
          [
            [
              [-9.145457, 42.81446],
              [-9.14568, 42.81461],
              [-9.145701, 42.81568],
              [-9.145309, 42.815431],
              [-9.145457, 42.81446],
            ],
          ],
          [
            [
              [-8.841883, 43.362571],
              [-8.84258, 43.362496],
              [-8.842895, 43.36277],
              [-8.842198, 43.36293],
              [-8.841883, 43.362571],
            ],
          ],
          [
            [
              [-9.140695, 42.85291],
              [-9.141134, 42.853139],
              [-9.14045, 42.853769],
              [-9.140416, 42.853555],
              [-9.140695, 42.85291],
            ],
          ],
          [
            [
              [-9.132312, 42.844756],
              [-9.132637, 42.844945],
              [-9.132684, 42.846158],
              [-9.13228, 42.845793],
              [-9.132312, 42.844756],
            ],
          ],
          [
            [
              [-9.136626, 43.200564],
              [-9.136344, 43.200579],
              [-9.136012, 43.199967],
              [-9.137045, 43.200578],
              [-9.136626, 43.200564],
            ],
          ],
          [
            [
              [-9.19826, 43.16061],
              [-9.198777, 43.160735],
              [-9.199033, 43.161198],
              [-9.198379, 43.161171],
              [-9.19826, 43.16061],
            ],
          ],
          [
            [
              [-7.802156, 43.745198],
              [-7.802857, 43.745276],
              [-7.802926, 43.745823],
              [-7.802371, 43.745711],
              [-7.802156, 43.745198],
            ],
          ],
          [
            [
              [-8.315464, 43.549869],
              [-8.315937, 43.550036],
              [-8.315766, 43.550485],
              [-8.31518, 43.550441],
              [-8.315464, 43.549869],
            ],
          ],
          [
            [
              [-8.41382, 43.389309],
              [-8.412796, 43.389168],
              [-8.412716, 43.388965],
              [-8.414043, 43.389037],
              [-8.41382, 43.389309],
            ],
          ],
          [
            [
              [-9.06446, 42.619697],
              [-9.063784, 42.61976],
              [-9.06361, 42.619598],
              [-9.064942, 42.619509],
              [-9.06446, 42.619697],
            ],
          ],
          [
            [
              [-8.251385, 43.367045],
              [-8.25095, 43.367155],
              [-8.250677, 43.366844],
              [-8.251619, 43.366755],
              [-8.251385, 43.367045],
            ],
          ],
          [
            [
              [-8.196252, 43.604071],
              [-8.195945, 43.603815],
              [-8.19606, 43.603423],
              [-8.196678, 43.603845],
              [-8.196252, 43.604071],
            ],
          ],
          [
            [
              [-8.148189, 43.623258],
              [-8.148332, 43.622604],
              [-8.148494, 43.62247],
              [-8.148119, 43.623739],
              [-8.148189, 43.623258],
            ],
          ],
          [
            [
              [-9.150734, 42.869796],
              [-9.150803, 42.870369],
              [-9.150265, 42.870623],
              [-9.150404, 42.870048],
              [-9.150734, 42.869796],
            ],
          ],
          [
            [
              [-8.868913, 42.604208],
              [-8.869419, 42.60493],
              [-8.869044, 42.605219],
              [-8.86884, 42.604995],
              [-8.868913, 42.604208],
            ],
          ],
          [
            [
              [-9.153121, 42.796989],
              [-9.153631, 42.797205],
              [-9.153352, 42.797958],
              [-9.153034, 42.797714],
              [-9.153121, 42.796989],
            ],
          ],
          [
            [
              [-8.051623, 43.712194],
              [-8.051232, 43.712112],
              [-8.051322, 43.711712],
              [-8.052036, 43.711932],
              [-8.051623, 43.712194],
            ],
          ],
          [
            [
              [-8.347763, 43.361944],
              [-8.348157, 43.36207],
              [-8.347886, 43.362641],
              [-8.347521, 43.36228],
              [-8.347763, 43.361944],
            ],
          ],
          [
            [
              [-8.911252, 43.303574],
              [-8.911706, 43.303425],
              [-8.911942, 43.303827],
              [-8.911367, 43.304001],
              [-8.911252, 43.303574],
            ],
          ],
          [
            [
              [-8.153793, 43.61764],
              [-8.154217, 43.617674],
              [-8.153992, 43.618225],
              [-8.153373, 43.617947],
              [-8.153793, 43.61764],
            ],
          ],
          [
            [
              [-9.014093, 42.490658],
              [-9.013321, 42.4906],
              [-9.013256, 42.490432],
              [-9.014254, 42.490308],
              [-9.014093, 42.490658],
            ],
          ],
          [
            [
              [-9.183818, 42.879988],
              [-9.184421, 42.880388],
              [-9.184202, 42.88076],
              [-9.183798, 42.88052],
              [-9.183818, 42.879988],
            ],
          ],
          [
            [
              [-8.521671, 43.325082],
              [-8.521884, 43.325386],
              [-8.521081, 43.325641],
              [-8.521304, 43.325243],
              [-8.521671, 43.325082],
            ],
          ],
          [
            [
              [-9.187262, 42.882223],
              [-9.187701, 42.882334],
              [-9.187768, 42.882636],
              [-9.187053, 42.882711],
              [-9.187262, 42.882223],
            ],
          ],
          [
            [
              [-8.412507, 43.384141],
              [-8.413236, 43.384142],
              [-8.413494, 43.384409],
              [-8.412837, 43.384521],
              [-8.412507, 43.384141],
            ],
          ],
          [
            [
              [-8.839499, 43.345635],
              [-8.839913, 43.345569],
              [-8.840001, 43.34635],
              [-8.839644, 43.346363],
              [-8.839499, 43.345635],
            ],
          ],
          [
            [
              [-9.141794, 42.794323],
              [-9.141961, 42.794773],
              [-9.141355, 42.795067],
              [-9.141369, 42.794841],
              [-9.141794, 42.794323],
            ],
          ],
          [
            [
              [-8.780662, 43.304124],
              [-8.780943, 43.304227],
              [-8.780883, 43.305274],
              [-8.780628, 43.305188],
              [-8.780662, 43.304124],
            ],
          ],
          [
            [
              [-9.184361, 42.879176],
              [-9.184645, 42.879196],
              [-9.184915, 42.879695],
              [-9.184302, 42.879683],
              [-9.184361, 42.879176],
            ],
          ],
          [
            [
              [-7.748009, 43.74624],
              [-7.748506, 43.746399],
              [-7.748147, 43.746883],
              [-7.747984, 43.746871],
              [-7.748009, 43.74624],
            ],
          ],
          [
            [
              [-8.999556, 42.495069],
              [-9.000083, 42.494861],
              [-9.000576, 42.495051],
              [-9.000396, 42.495331],
              [-8.999556, 42.495069],
            ],
          ],
          [
            [
              [-9.184332, 43.184204],
              [-9.183997, 43.183808],
              [-9.184382, 43.183355],
              [-9.184593, 43.1841],
              [-9.184332, 43.184204],
            ],
          ],
          [
            [
              [-8.960733, 42.775004],
              [-8.961215, 42.774934],
              [-8.961411, 42.775193],
              [-8.96077, 42.775515],
              [-8.960733, 42.775004],
            ],
          ],
          [
            [
              [-9.16145, 42.89364],
              [-9.161828, 42.893597],
              [-9.162029, 42.894179],
              [-9.161356, 42.894071],
              [-9.16145, 42.89364],
            ],
          ],
          [
            [
              [-8.997189, 42.527455],
              [-8.997359, 42.527203],
              [-8.998042, 42.527725],
              [-8.99754, 42.527815],
              [-8.997189, 42.527455],
            ],
          ],
          [
            [
              [-9.042855, 42.534535],
              [-9.04327, 42.535053],
              [-9.04312, 42.535385],
              [-9.042742, 42.535135],
              [-9.042855, 42.534535],
            ],
          ],
          [
            [
              [-9.150941, 42.874088],
              [-9.151171, 42.873328],
              [-9.151379, 42.873326],
              [-9.151615, 42.87397],
              [-9.150941, 42.874088],
            ],
          ],
          [
            [
              [-7.868369, 43.774089],
              [-7.869294, 43.774464],
              [-7.869354, 43.77474],
              [-7.869056, 43.774753],
              [-7.868369, 43.774089],
            ],
          ],
          [
            [
              [-9.14836, 42.793852],
              [-9.148845, 42.793934],
              [-9.148866, 42.794149],
              [-9.148105, 42.794235],
              [-9.14836, 42.793852],
            ],
          ],
          [
            [
              [-8.917445, 42.586346],
              [-8.917677, 42.585965],
              [-8.918448, 42.586006],
              [-8.918147, 42.586301],
              [-8.917445, 42.586346],
            ],
          ],
          [
            [
              [-9.184399, 42.881631],
              [-9.185004, 42.881922],
              [-9.185058, 42.882468],
              [-9.184809, 42.882419],
              [-9.184399, 42.881631],
            ],
          ],
          [
            [
              [-9.038794, 42.517341],
              [-9.039239, 42.517407],
              [-9.039478, 42.517611],
              [-9.038838, 42.517914],
              [-9.038794, 42.517341],
            ],
          ],
          [
            [
              [-9.184945, 42.879144],
              [-9.185472, 42.879151],
              [-9.185491, 42.879582],
              [-9.185067, 42.879623],
              [-9.184945, 42.879144],
            ],
          ],
          [
            [
              [-9.146523, 42.862048],
              [-9.146829, 42.862292],
              [-9.14667, 42.863282],
              [-9.146512, 42.863165],
              [-9.146523, 42.862048],
            ],
          ],
          [
            [
              [-9.040728, 42.514565],
              [-9.04137, 42.514153],
              [-9.041533, 42.514198],
              [-9.041167, 42.514947],
              [-9.040728, 42.514565],
            ],
          ],
          [
            [
              [-9.148226, 42.878897],
              [-9.147414, 42.878851],
              [-9.147306, 42.878749],
              [-9.14852, 42.878656],
              [-9.148226, 42.878897],
            ],
          ],
          [
            [
              [-9.014631, 42.49739],
              [-9.015087, 42.497708],
              [-9.015171, 42.498073],
              [-9.014586, 42.498059],
              [-9.014631, 42.49739],
            ],
          ],
          [
            [
              [-8.867627, 42.606219],
              [-8.868123, 42.606301],
              [-8.867989, 42.606821],
              [-8.867596, 42.606788],
              [-8.867627, 42.606219],
            ],
          ],
          [
            [
              [-9.140198, 42.852469],
              [-9.139577, 42.852376],
              [-9.139637, 42.851869],
              [-9.140327, 42.852291],
              [-9.140198, 42.852469],
            ],
          ],
          [
            [
              [-9.18745, 42.880925],
              [-9.187329, 42.880212],
              [-9.188267, 42.880773],
              [-9.187759, 42.88108],
              [-9.18745, 42.880925],
            ],
          ],
          [
            [
              [-8.837884, 43.363734],
              [-8.838319, 43.363798],
              [-8.83819, 43.364295],
              [-8.83777, 43.364145],
              [-8.837884, 43.363734],
            ],
          ],
          [
            [
              [-9.005073, 42.493239],
              [-9.005321, 42.493289],
              [-9.005448, 42.493985],
              [-9.004808, 42.493775],
              [-9.005073, 42.493239],
            ],
          ],
          [
            [
              [-8.998734, 42.489801],
              [-8.998383, 42.489693],
              [-8.997968, 42.489679],
              [-8.999208, 42.489542],
              [-8.998734, 42.489801],
            ],
          ],
          [
            [
              [-9.136394, 43.197525],
              [-9.136605, 43.197424],
              [-9.137066, 43.197966],
              [-9.136386, 43.198057],
              [-9.136394, 43.197525],
            ],
          ],
          [
            [
              [-8.066382, 43.70133],
              [-8.066942, 43.701494],
              [-8.066773, 43.701997],
              [-8.06649, 43.701874],
              [-8.066382, 43.70133],
            ],
          ],
          [
            [
              [-8.406637, 43.388488],
              [-8.40792, 43.388616],
              [-8.408066, 43.388727],
              [-8.407769, 43.388858],
              [-8.406637, 43.388488],
            ],
          ],
          [
            [
              [-8.520193, 43.326594],
              [-8.520536, 43.327108],
              [-8.520146, 43.327298],
              [-8.520011, 43.326755],
              [-8.520193, 43.326594],
            ],
          ],
          [
            [
              [-8.047372, 43.715362],
              [-8.047255, 43.714998],
              [-8.04806, 43.715124],
              [-8.048001, 43.715316],
              [-8.047372, 43.715362],
            ],
          ],
          [
            [
              [-8.198083, 43.610506],
              [-8.198326, 43.610873],
              [-8.198009, 43.611364],
              [-8.197846, 43.610913],
              [-8.198083, 43.610506],
            ],
          ],
          [
            [
              [-8.134975, 43.635702],
              [-8.135793, 43.635845],
              [-8.135841, 43.635978],
              [-8.13532, 43.636118],
              [-8.134975, 43.635702],
            ],
          ],
          [
            [
              [-9.292364, 43.058607],
              [-9.292803, 43.058816],
              [-9.293074, 43.05918],
              [-9.29251, 43.059067],
              [-9.292364, 43.058607],
            ],
          ],
          [
            [
              [-9.05434, 42.506994],
              [-9.054635, 42.507023],
              [-9.054636, 42.507662],
              [-9.054202, 42.507325],
              [-9.05434, 42.506994],
            ],
          ],
          [
            [
              [-8.040959, 43.714223],
              [-8.041564, 43.714187],
              [-8.041591, 43.714654],
              [-8.041137, 43.714566],
              [-8.040959, 43.714223],
            ],
          ],
          [
            [
              [-8.835234, 43.355895],
              [-8.835747, 43.355851],
              [-8.83581, 43.356118],
              [-8.834926, 43.356163],
              [-8.835234, 43.355895],
            ],
          ],
          [
            [
              [-8.017282, 43.712945],
              [-8.017736, 43.713321],
              [-8.017478, 43.7138],
              [-8.017302, 43.713628],
              [-8.017282, 43.712945],
            ],
          ],
          [
            [
              [-8.560024, 43.315836],
              [-8.56043, 43.31596],
              [-8.560343, 43.316361],
              [-8.559941, 43.316281],
              [-8.560024, 43.315836],
            ],
          ],
          [
            [
              [-8.91662, 43.293637],
              [-8.917035, 43.293588],
              [-8.917198, 43.293877],
              [-8.916581, 43.29399],
              [-8.91662, 43.293637],
            ],
          ],
          [
            [
              [-8.956782, 42.573489],
              [-8.95741, 42.573304],
              [-8.957589, 42.573393],
              [-8.956997, 42.573829],
              [-8.956782, 42.573489],
            ],
          ],
          [
            [
              [-8.983438, 43.266312],
              [-8.982809, 43.2663],
              [-8.982723, 43.266052],
              [-8.983391, 43.265954],
              [-8.983438, 43.266312],
            ],
          ],
          [
            [
              [-8.021427, 43.712492],
              [-8.022123, 43.712354],
              [-8.022223, 43.712655],
              [-8.021647, 43.712744],
              [-8.021427, 43.712492],
            ],
          ],
          [
            [
              [-9.144765, 42.883719],
              [-9.144903, 42.883991],
              [-9.1441, 42.884655],
              [-9.144576, 42.884153],
              [-9.144765, 42.883719],
            ],
          ],
          [
            [
              [-9.266827, 43.053154],
              [-9.266928, 43.052708],
              [-9.267385, 43.053097],
              [-9.266987, 43.053407],
              [-9.266827, 43.053154],
            ],
          ],
          [
            [
              [-9.144602, 43.194976],
              [-9.145096, 43.195238],
              [-9.145061, 43.195627],
              [-9.144696, 43.195539],
              [-9.144602, 43.194976],
            ],
          ],
          [
            [
              [-9.139117, 42.790002],
              [-9.139614, 42.790093],
              [-9.139824, 42.790477],
              [-9.138945, 42.79012],
              [-9.139117, 42.790002],
            ],
          ],
          [
            [
              [-9.257983, 43.089277],
              [-9.258196, 43.089202],
              [-9.25849, 43.089672],
              [-9.258024, 43.08968],
              [-9.257983, 43.089277],
            ],
          ],
          [
            [
              [-9.215817, 43.15104],
              [-9.216309, 43.15104],
              [-9.216352, 43.151469],
              [-9.215875, 43.151369],
              [-9.215817, 43.15104],
            ],
          ],
          [
            [
              [-8.910067, 43.304572],
              [-8.910262, 43.304931],
              [-8.909599, 43.305082],
              [-8.90957, 43.305039],
              [-8.910067, 43.304572],
            ],
          ],
          [
            [
              [-9.079946, 42.596171],
              [-9.080658, 42.596232],
              [-9.080852, 42.596591],
              [-9.079851, 42.59632],
              [-9.079946, 42.596171],
            ],
          ],
          [
            [
              [-9.006179, 42.497412],
              [-9.006437, 42.497443],
              [-9.006673, 42.497872],
              [-9.005977, 42.497711],
              [-9.006179, 42.497412],
            ],
          ],
          [
            [
              [-9.045181, 42.511917],
              [-9.044832, 42.511953],
              [-9.044993, 42.51136],
              [-9.045392, 42.512212],
              [-9.045181, 42.511917],
            ],
          ],
          [
            [
              [-9.136882, 43.19749],
              [-9.137402, 43.197516],
              [-9.137533, 43.197959],
              [-9.136999, 43.19779],
              [-9.136882, 43.19749],
            ],
          ],
          [
            [
              [-9.179718, 43.183338],
              [-9.179983, 43.183405],
              [-9.179755, 43.184308],
              [-9.179578, 43.183418],
              [-9.179718, 43.183338],
            ],
          ],
          [
            [
              [-9.296633, 42.92578],
              [-9.296971, 42.925852],
              [-9.296964, 42.926383],
              [-9.296668, 42.926246],
              [-9.296633, 42.92578],
            ],
          ],
          [
            [
              [-8.304594, 43.540824],
              [-8.305042, 43.541001],
              [-8.305094, 43.541449],
              [-8.304696, 43.541287],
              [-8.304594, 43.540824],
            ],
          ],
          [
            [
              [-7.798103, 43.73164],
              [-7.798456, 43.731706],
              [-7.7985, 43.7321],
              [-7.79801, 43.732031],
              [-7.798103, 43.73164],
            ],
          ],
          [
            [
              [-9.054032, 42.520386],
              [-9.05461, 42.520446],
              [-9.054641, 42.520642],
              [-9.054146, 42.520812],
              [-9.054032, 42.520386],
            ],
          ],
          [
            [
              [-7.863152, 43.767065],
              [-7.863426, 43.767224],
              [-7.863129, 43.767858],
              [-7.862955, 43.76755],
              [-7.863152, 43.767065],
            ],
          ],
          [
            [
              [-9.187945, 43.182343],
              [-9.188197, 43.182527],
              [-9.187982, 43.18307],
              [-9.18774, 43.182624],
              [-9.187945, 43.182343],
            ],
          ],
          [
            [
              [-9.052418, 42.506871],
              [-9.052903, 42.506593],
              [-9.053163, 42.507146],
              [-9.052961, 42.507193],
              [-9.052418, 42.506871],
            ],
          ],
          [
            [
              [-8.304747, 43.540601],
              [-8.304793, 43.540275],
              [-8.305727, 43.540483],
              [-8.305574, 43.540571],
              [-8.304747, 43.540601],
            ],
          ],
          [
            [
              [-8.849991, 43.335569],
              [-8.850259, 43.335693],
              [-8.850372, 43.336024],
              [-8.849644, 43.335863],
              [-8.849991, 43.335569],
            ],
          ],
          [
            [
              [-8.838033, 43.362897],
              [-8.838319, 43.36282],
              [-8.838766, 43.363195],
              [-8.838422, 43.363321],
              [-8.838033, 43.362897],
            ],
          ],
          [
            [
              [-8.009053, 43.707581],
              [-8.009241, 43.707609],
              [-8.009259, 43.708121],
              [-8.008718, 43.707731],
              [-8.009053, 43.707581],
            ],
          ],
          [
            [
              [-9.171618, 42.800086],
              [-9.171884, 42.800296],
              [-9.171486, 42.80084],
              [-9.171501, 42.800137],
              [-9.171618, 42.800086],
            ],
          ],
          [
            [
              [-8.009405, 43.70942],
              [-8.00963, 43.709446],
              [-8.009665, 43.709859],
              [-8.009058, 43.709579],
              [-8.009405, 43.70942],
            ],
          ],
          [
            [
              [-9.014424, 42.498526],
              [-9.014553, 42.498222],
              [-9.01499, 42.498343],
              [-9.014802, 42.498659],
              [-9.014424, 42.498526],
            ],
          ],
          [
            [
              [-8.594967, 43.321373],
              [-8.595196, 43.320912],
              [-8.59565, 43.321151],
              [-8.595338, 43.321382],
              [-8.594967, 43.321373],
            ],
          ],
          [
            [
              [-9.181487, 43.172989],
              [-9.18182, 43.172998],
              [-9.181865, 43.173446],
              [-9.18142, 43.173425],
              [-9.181487, 43.172989],
            ],
          ],
          [
            [
              [-9.146761, 42.793875],
              [-9.14716, 42.794079],
              [-9.146813, 42.794647],
              [-9.146668, 42.794042],
              [-9.146761, 42.793875],
            ],
          ],
          [
            [
              [-8.960876, 42.570827],
              [-8.961309, 42.570769],
              [-8.961509, 42.571208],
              [-8.961071, 42.571213],
              [-8.960876, 42.570827],
            ],
          ],
          [
            [
              [-8.848793, 43.36206],
              [-8.849229, 43.362226],
              [-8.849069, 43.362613],
              [-8.848754, 43.362548],
              [-8.848793, 43.36206],
            ],
          ],
          [
            [
              [-7.854999, 43.726387],
              [-7.855259, 43.726385],
              [-7.855556, 43.726832],
              [-7.854886, 43.72668],
              [-7.854999, 43.726387],
            ],
          ],
          [
            [
              [-9.1912, 43.161318],
              [-9.191565, 43.16128],
              [-9.191766, 43.161683],
              [-9.191372, 43.161678],
              [-9.1912, 43.161318],
            ],
          ],
          [
            [
              [-8.590148, 43.320853],
              [-8.590566, 43.320842],
              [-8.590768, 43.32103],
              [-8.590327, 43.321196],
              [-8.590148, 43.320853],
            ],
          ],
          [
            [
              [-9.025166, 42.489066],
              [-9.025772, 42.488917],
              [-9.025907, 42.489054],
              [-9.025507, 42.489327],
              [-9.025166, 42.489066],
            ],
          ],
          [
            [
              [-8.182245, 43.62212],
              [-8.182707, 43.62173],
              [-8.182945, 43.621755],
              [-8.182709, 43.62218],
              [-8.182245, 43.62212],
            ],
          ],
          [
            [
              [-7.690412, 43.791925],
              [-7.69093, 43.79203],
              [-7.69097, 43.792217],
              [-7.69057, 43.792351],
              [-7.690412, 43.791925],
            ],
          ],
          [
            [
              [-9.073949, 42.565642],
              [-9.074231, 42.565663],
              [-9.074395, 42.566095],
              [-9.074019, 42.566115],
              [-9.073949, 42.565642],
            ],
          ],
          [
            [
              [-9.130704, 42.843717],
              [-9.131043, 42.843807],
              [-9.13119, 42.844177],
              [-9.130694, 42.844104],
              [-9.130704, 42.843717],
            ],
          ],
          [
            [
              [-8.966419, 43.229018],
              [-8.966838, 43.229149],
              [-8.96673, 43.229425],
              [-8.966239, 43.229316],
              [-8.966419, 43.229018],
            ],
          ],
          [
            [
              [-8.326712, 43.404629],
              [-8.32696, 43.404923],
              [-8.326481, 43.405234],
              [-8.32651, 43.404863],
              [-8.326712, 43.404629],
            ],
          ],
          [
            [
              [-9.133792, 43.196981],
              [-9.134181, 43.196933],
              [-9.134382, 43.197219],
              [-9.134036, 43.197328],
              [-9.133792, 43.196981],
            ],
          ],
          [
            [
              [-8.307906, 43.569046],
              [-8.308276, 43.569164],
              [-8.308449, 43.5693],
              [-8.307791, 43.56942],
              [-8.307906, 43.569046],
            ],
          ],
          [
            [
              [-8.834568, 43.350123],
              [-8.834692, 43.350522],
              [-8.834089, 43.350535],
              [-8.834105, 43.350444],
              [-8.834568, 43.350123],
            ],
          ],
          [
            [
              [-9.015125, 42.488363],
              [-9.015421, 42.488527],
              [-9.014948, 42.488795],
              [-9.014794, 42.488587],
              [-9.015125, 42.488363],
            ],
          ],
          [
            [
              [-8.181432, 43.622319],
              [-8.181416, 43.622707],
              [-8.180915, 43.622649],
              [-8.181023, 43.622455],
              [-8.181432, 43.622319],
            ],
          ],
          [
            [
              [-9.171509, 42.863214],
              [-9.171789, 42.863199],
              [-9.172284, 42.863379],
              [-9.171807, 42.86357],
              [-9.171509, 42.863214],
            ],
          ],
          [
            [
              [-9.16266, 42.912486],
              [-9.162831, 42.912603],
              [-9.16272, 42.913329],
              [-9.162492, 42.912882],
              [-9.16266, 42.912486],
            ],
          ],
          [
            [
              [-8.844072, 43.362057],
              [-8.844275, 43.361909],
              [-8.844581, 43.362183],
              [-8.844094, 43.362379],
              [-8.844072, 43.362057],
            ],
          ],
          [
            [
              [-8.009885, 43.709372],
              [-8.010426, 43.709609],
              [-8.010406, 43.709817],
              [-8.010017, 43.709771],
              [-8.009885, 43.709372],
            ],
          ],
          [
            [
              [-9.151429, 42.871982],
              [-9.151585, 42.872073],
              [-9.151032, 42.872787],
              [-9.150987, 42.872582],
              [-9.151429, 42.871982],
            ],
          ],
          [
            [
              [-8.282811, 43.561282],
              [-8.283109, 43.561439],
              [-8.282434, 43.561506],
              [-8.282449, 43.561388],
              [-8.282811, 43.561282],
            ],
          ],
          [
            [
              [-9.141739, 42.854672],
              [-9.141933, 42.854284],
              [-9.142275, 42.854778],
              [-9.141951, 42.854841],
              [-9.141739, 42.854672],
            ],
          ],
          [
            [
              [-9.116083, 43.196817],
              [-9.116425, 43.197032],
              [-9.116108, 43.197553],
              [-9.116, 43.196974],
              [-9.116083, 43.196817],
            ],
          ],
          [
            [
              [-7.77098, 43.741843],
              [-7.771385, 43.741628],
              [-7.771444, 43.742363],
              [-7.771371, 43.742385],
              [-7.77098, 43.741843],
            ],
          ],
          [
            [
              [-9.114427, 43.194538],
              [-9.114841, 43.194615],
              [-9.114709, 43.195018],
              [-9.11439, 43.194774],
              [-9.114427, 43.194538],
            ],
          ],
          [
            [
              [-9.145589, 42.736275],
              [-9.14605, 42.736367],
              [-9.146012, 42.736603],
              [-9.145493, 42.736541],
              [-9.145589, 42.736275],
            ],
          ],
          [
            [
              [-9.145027, 42.82046],
              [-9.145307, 42.820454],
              [-9.145352, 42.820776],
              [-9.144855, 42.820704],
              [-9.145027, 42.82046],
            ],
          ],
          [
            [
              [-9.089312, 42.578065],
              [-9.089575, 42.578015],
              [-9.089969, 42.578552],
              [-9.089464, 42.578498],
              [-9.089312, 42.578065],
            ],
          ],
          [
            [
              [-8.306963, 43.568874],
              [-8.307335, 43.568883],
              [-8.307407, 43.568997],
              [-8.306714, 43.569146],
              [-8.306963, 43.568874],
            ],
          ],
          [
            [
              [-9.130108, 42.769999],
              [-9.130519, 42.770085],
              [-9.130542, 42.770318],
              [-9.130073, 42.770271],
              [-9.130108, 42.769999],
            ],
          ],
          [
            [
              [-9.151765, 43.194266],
              [-9.151944, 43.19458],
              [-9.151606, 43.194761],
              [-9.151495, 43.194398],
              [-9.151765, 43.194266],
            ],
          ],
          [
            [
              [-9.009414, 42.498925],
              [-9.009426, 42.498798],
              [-9.009982, 42.498886],
              [-9.00974, 42.499151],
              [-9.009414, 42.498925],
            ],
          ],
          [
            [
              [-8.212426, 43.592989],
              [-8.212882, 43.593112],
              [-8.213067, 43.593383],
              [-8.212363, 43.593118],
              [-8.212426, 43.592989],
            ],
          ],
          [
            [
              [-9.044566, 42.51059],
              [-9.044751, 42.510994],
              [-9.044505, 42.511215],
              [-9.04433, 42.510792],
              [-9.044566, 42.51059],
            ],
          ],
          [
            [
              [-8.983233, 43.265224],
              [-8.983447, 43.265276],
              [-8.983577, 43.265719],
              [-8.983237, 43.265647],
              [-8.983233, 43.265224],
            ],
          ],
          [
            [
              [-8.994563, 42.487139],
              [-8.994954, 42.487038],
              [-8.995084, 42.487121],
              [-8.994693, 42.487484],
              [-8.994563, 42.487139],
            ],
          ],
          [
            [
              [-9.02513, 42.48831],
              [-9.025611, 42.48832],
              [-9.025703, 42.488611],
              [-9.02532, 42.48862],
              [-9.02513, 42.48831],
            ],
          ],
          [
            [
              [-9.146544, 42.738762],
              [-9.147048, 42.738915],
              [-9.147048, 42.739167],
              [-9.14669, 42.739123],
              [-9.146544, 42.738762],
            ],
          ],
          [
            [
              [-8.747177, 42.650289],
              [-8.747644, 42.650463],
              [-8.747702, 42.650694],
              [-8.747306, 42.650624],
              [-8.747177, 42.650289],
            ],
          ],
          [
            [
              [-8.013949, 43.710931],
              [-8.014315, 43.711005],
              [-8.01435, 43.711273],
              [-8.013918, 43.711301],
              [-8.013949, 43.710931],
            ],
          ],
          [
            [
              [-9.138042, 42.789646],
              [-9.138122, 42.789462],
              [-9.138653, 42.789766],
              [-9.13847, 42.789893],
              [-9.138042, 42.789646],
            ],
          ],
          [
            [
              [-8.065517, 43.701233],
              [-8.065918, 43.701279],
              [-8.065771, 43.701591],
              [-8.06544, 43.701498],
              [-8.065517, 43.701233],
            ],
          ],
          [
            [
              [-8.984917, 43.267566],
              [-8.985318, 43.267635],
              [-8.98454, 43.267748],
              [-8.984522, 43.267686],
              [-8.984917, 43.267566],
            ],
          ],
          [
            [
              [-9.003503, 42.735692],
              [-9.003899, 42.735753],
              [-9.004068, 42.735978],
              [-9.003465, 42.736054],
              [-9.003503, 42.735692],
            ],
          ],
          [
            [
              [-9.18745, 42.883122],
              [-9.187673, 42.883155],
              [-9.18792, 42.88342],
              [-9.187329, 42.88338],
              [-9.18745, 42.883122],
            ],
          ],
          [
            [
              [-8.32713, 43.40433],
              [-8.327373, 43.404292],
              [-8.327543, 43.404536],
              [-8.327189, 43.404723],
              [-8.32713, 43.40433],
            ],
          ],
          [
            [
              [-7.771437, 43.740869],
              [-7.771607, 43.741141],
              [-7.771209, 43.741437],
              [-7.771157, 43.741259],
              [-7.771437, 43.740869],
            ],
          ],
          [
            [
              [-9.014291, 42.486903],
              [-9.0147, 42.486855],
              [-9.014756, 42.487176],
              [-9.014543, 42.487241],
              [-9.014291, 42.486903],
            ],
          ],
          [
            [
              [-8.332828, 43.510941],
              [-8.333128, 43.510972],
              [-8.332977, 43.511357],
              [-8.332679, 43.511209],
              [-8.332828, 43.510941],
            ],
          ],
          [
            [
              [-9.145899, 42.859471],
              [-9.146114, 42.859783],
              [-9.145513, 42.859888],
              [-9.145492, 42.859808],
              [-9.145899, 42.859471],
            ],
          ],
          [
            [
              [-8.832813, 43.356253],
              [-8.832957, 43.355958],
              [-8.833407, 43.356142],
              [-8.833353, 43.356226],
              [-8.832813, 43.356253],
            ],
          ],
          [
            [
              [-9.142447, 42.856011],
              [-9.142853, 42.855791],
              [-9.143292, 42.855894],
              [-9.143197, 42.856052],
              [-9.142447, 42.856011],
            ],
          ],
          [
            [
              [-7.751615, 43.746927],
              [-7.752122, 43.747374],
              [-7.752151, 43.74758],
              [-7.751926, 43.747562],
              [-7.751615, 43.746927],
            ],
          ],
          [
            [
              [-8.334648, 43.510811],
              [-8.335032, 43.510685],
              [-8.335254, 43.510819],
              [-8.334807, 43.511074],
              [-8.334648, 43.510811],
            ],
          ],
          [
            [
              [-9.082629, 42.733302],
              [-9.08297, 42.733293],
              [-9.083099, 42.733358],
              [-9.082763, 42.733664],
              [-9.082629, 42.733302],
            ],
          ],
          [
            [
              [-7.868613, 43.779381],
              [-7.869082, 43.779496],
              [-7.869224, 43.779715],
              [-7.868592, 43.77958],
              [-7.868613, 43.779381],
            ],
          ],
          [
            [
              [-8.796048, 43.312561],
              [-8.796367, 43.31267],
              [-8.796317, 43.313051],
              [-8.796022, 43.312931],
              [-8.796048, 43.312561],
            ],
          ],
          [
            [
              [-9.257131, 43.088757],
              [-9.25741, 43.08884],
              [-9.257584, 43.089344],
              [-9.257139, 43.088954],
              [-9.257131, 43.088757],
            ],
          ],
          [
            [
              [-8.067139, 43.696921],
              [-8.067649, 43.696935],
              [-8.067726, 43.69712],
              [-8.067366, 43.697262],
              [-8.067139, 43.696921],
            ],
          ],
          [
            [
              [-9.150349, 42.796031],
              [-9.150534, 42.795922],
              [-9.150699, 42.796355],
              [-9.150384, 42.796381],
              [-9.150349, 42.796031],
            ],
          ],
          [
            [
              [-9.192269, 43.17307],
              [-9.192596, 43.173142],
              [-9.192563, 43.173549],
              [-9.19219, 43.173263],
              [-9.192269, 43.17307],
            ],
          ],
          [
            [
              [-8.554748, 43.313816],
              [-8.555018, 43.313946],
              [-8.554408, 43.314147],
              [-8.554534, 43.313907],
              [-8.554748, 43.313816],
            ],
          ],
          [
            [
              [-9.145851, 42.865253],
              [-9.145986, 42.86538],
              [-9.145719, 42.866016],
              [-9.145626, 42.865823],
              [-9.145851, 42.865253],
            ],
          ],
          [
            [
              [-9.18248, 42.878485],
              [-9.182716, 42.878635],
              [-9.18284, 42.879267],
              [-9.182696, 42.879176],
              [-9.18248, 42.878485],
            ],
          ],
          [
            [
              [-8.981481, 42.539945],
              [-8.981426, 42.539624],
              [-8.981766, 42.53975],
              [-8.981622, 42.540154],
              [-8.981481, 42.539945],
            ],
          ],
          [
            [
              [-8.214659, 43.593607],
              [-8.215219, 43.593779],
              [-8.215356, 43.593926],
              [-8.215141, 43.594017],
              [-8.214659, 43.593607],
            ],
          ],
          [
            [
              [-9.043334, 42.534456],
              [-9.043494, 42.534474],
              [-9.043714, 42.535111],
              [-9.04335, 42.534878],
              [-9.043334, 42.534456],
            ],
          ],
          [
            [
              [-8.046845, 43.714116],
              [-8.047254, 43.714107],
              [-8.047523, 43.714347],
              [-8.047206, 43.714415],
              [-8.046845, 43.714116],
            ],
          ],
          [
            [
              [-8.031813, 43.714834],
              [-8.032038, 43.714851],
              [-8.032224, 43.71514],
              [-8.031787, 43.715114],
              [-8.031813, 43.714834],
            ],
          ],
          [
            [
              [-8.567077, 43.315447],
              [-8.56732, 43.315543],
              [-8.566547, 43.315716],
              [-8.566725, 43.315509],
              [-8.567077, 43.315447],
            ],
          ],
          [
            [
              [-9.131406, 42.777311],
              [-9.13166, 42.777288],
              [-9.131785, 42.777434],
              [-9.131082, 42.77749],
              [-9.131406, 42.777311],
            ],
          ],
          [
            [
              [-8.411231, 43.387541],
              [-8.411735, 43.387517],
              [-8.411727, 43.387842],
              [-8.411606, 43.387874],
              [-8.411231, 43.387541],
            ],
          ],
          [
            [
              [-7.866419, 43.770733],
              [-7.866723, 43.770963],
              [-7.866275, 43.771108],
              [-7.866291, 43.770847],
              [-7.866419, 43.770733],
            ],
          ],
          [
            [
              [-8.210782, 43.594891],
              [-8.21127, 43.595094],
              [-8.211302, 43.595318],
              [-8.21101, 43.595232],
              [-8.210782, 43.594891],
            ],
          ],
          [
            [
              [-9.062477, 42.619235],
              [-9.062943, 42.619255],
              [-9.062966, 42.619371],
              [-9.062424, 42.619562],
              [-9.062477, 42.619235],
            ],
          ],
          [
            [
              [-7.737235, 43.748038],
              [-7.737437, 43.747769],
              [-7.737589, 43.747961],
              [-7.73731, 43.74835],
              [-7.737235, 43.748038],
            ],
          ],
          [
            [
              [-9.111324, 43.192175],
              [-9.111705, 43.192172],
              [-9.111614, 43.192735],
              [-9.111377, 43.192577],
              [-9.111324, 43.192175],
            ],
          ],
          [
            [
              [-8.091974, 43.666709],
              [-8.092273, 43.666876],
              [-8.092345, 43.667143],
              [-8.091963, 43.667025],
              [-8.091974, 43.666709],
            ],
          ],
          [
            [
              [-9.07621, 42.598719],
              [-9.07659, 42.598744],
              [-9.076618, 42.599031],
              [-9.076275, 42.599004],
              [-9.07621, 42.598719],
            ],
          ],
          [
            [
              [-8.12104, 43.643963],
              [-8.121226, 43.644261],
              [-8.121019, 43.644594],
              [-8.120933, 43.644166],
              [-8.12104, 43.643963],
            ],
          ],
          [
            [
              [-8.986305, 42.55522],
              [-8.985976, 42.555219],
              [-8.986593, 42.555043],
              [-8.986549, 42.555351],
              [-8.986305, 42.55522],
            ],
          ],
          [
            [
              [-8.607177, 43.322745],
              [-8.607405, 43.322941],
              [-8.606902, 43.323101],
              [-8.606869, 43.322886],
              [-8.607177, 43.322745],
            ],
          ],
          [
            [
              [-9.188789, 43.183269],
              [-9.189272, 43.183666],
              [-9.189167, 43.183842],
              [-9.188744, 43.18355],
              [-9.188789, 43.183269],
            ],
          ],
          [
            [
              [-8.016689, 43.712683],
              [-8.017114, 43.712862],
              [-8.017128, 43.713177],
              [-8.01691, 43.713096],
              [-8.016689, 43.712683],
            ],
          ],
          [
            [
              [-8.209727, 43.595003],
              [-8.210011, 43.594999],
              [-8.210514, 43.595372],
              [-8.210124, 43.5953],
              [-8.209727, 43.595003],
            ],
          ],
          [
            [
              [-9.091115, 42.737395],
              [-9.091456, 42.737386],
              [-9.091512, 42.737581],
              [-9.091078, 42.73764],
              [-9.091115, 42.737395],
            ],
          ],
          [
            [
              [-8.01011, 43.709101],
              [-8.01041, 43.709124],
              [-8.010596, 43.709422],
              [-8.010155, 43.709342],
              [-8.01011, 43.709101],
            ],
          ],
          [
            [
              [-9.042176, 42.525506],
              [-9.042455, 42.525248],
              [-9.042846, 42.52539],
              [-9.042628, 42.525527],
              [-9.042176, 42.525506],
            ],
          ],
          [
            [
              [-9.149185, 42.806941],
              [-9.149562, 42.807047],
              [-9.149101, 42.807333],
              [-9.149045, 42.807012],
              [-9.149185, 42.806941],
            ],
          ],
          [
            [
              [-8.201889, 43.597764],
              [-8.202326, 43.597952],
              [-8.202431, 43.598307],
              [-8.202279, 43.598269],
              [-8.201889, 43.597764],
            ],
          ],
          [
            [
              [-9.143627, 42.815477],
              [-9.143898, 42.815624],
              [-9.143528, 42.815833],
              [-9.143447, 42.81564],
              [-9.143627, 42.815477],
            ],
          ],
          [
            [
              [-9.146724, 42.737744],
              [-9.147045, 42.737655],
              [-9.147168, 42.738035],
              [-9.146782, 42.737957],
              [-9.146724, 42.737744],
            ],
          ],
          [
            [
              [-8.998845, 42.493873],
              [-8.999108, 42.493949],
              [-8.999275, 42.4943],
              [-8.998766, 42.494066],
              [-8.998845, 42.493873],
            ],
          ],
          [
            [
              [-9.048483, 42.517998],
              [-9.0486, 42.517695],
              [-9.048939, 42.517929],
              [-9.048885, 42.518004],
              [-9.048483, 42.517998],
            ],
          ],
          [
            [
              [-9.147097, 42.8656],
              [-9.147684, 42.865964],
              [-9.147723, 42.866106],
              [-9.147036, 42.86572],
              [-9.147097, 42.8656],
            ],
          ],
          [
            [
              [-7.790288, 43.734984],
              [-7.790371, 43.735565],
              [-7.790225, 43.735761],
              [-7.790165, 43.735628],
              [-7.790288, 43.734984],
            ],
          ],
          [
            [
              [-9.145988, 42.792495],
              [-9.14618, 42.792574],
              [-9.145987, 42.793098],
              [-9.145888, 42.792959],
              [-9.145988, 42.792495],
            ],
          ],
          [
            [
              [-9.019493, 42.459048],
              [-9.019801, 42.459095],
              [-9.019798, 42.459311],
              [-9.019409, 42.459314],
              [-9.019493, 42.459048],
            ],
          ],
          [
            [
              [-8.991301, 42.527989],
              [-8.991546, 42.528003],
              [-8.991676, 42.528347],
              [-8.991407, 42.528461],
              [-8.991301, 42.527989],
            ],
          ],
          [
            [
              [-7.869875, 43.776572],
              [-7.870439, 43.776782],
              [-7.8705, 43.776924],
              [-7.86994, 43.776759],
              [-7.869875, 43.776572],
            ],
          ],
          [
            [
              [-8.341245, 43.510592],
              [-8.340766, 43.510488],
              [-8.340634, 43.510396],
              [-8.341574, 43.510532],
              [-8.341245, 43.510592],
            ],
          ],
          [
            [
              [-9.000435, 42.491044],
              [-9.000511, 42.49095],
              [-9.001058, 42.490948],
              [-9.000694, 42.49121],
              [-9.000435, 42.491044],
            ],
          ],
          [
            [
              [-9.02154, 42.473883],
              [-9.021712, 42.474027],
              [-9.021501, 42.474362],
              [-9.021385, 42.474044],
              [-9.02154, 42.473883],
            ],
          ],
          [
            [
              [-8.030363, 43.713674],
              [-8.030713, 43.713847],
              [-8.030593, 43.714051],
              [-8.030295, 43.713902],
              [-8.030363, 43.713674],
            ],
          ],
          [
            [
              [-8.336471, 43.406657],
              [-8.33667, 43.40681],
              [-8.336262, 43.407081],
              [-8.336244, 43.406884],
              [-8.336471, 43.406657],
            ],
          ],
          [
            [
              [-9.147165, 42.737387],
              [-9.147416, 42.737338],
              [-9.147537, 42.737448],
              [-9.146986, 42.73755],
              [-9.147165, 42.737387],
            ],
          ],
          [
            [
              [-9.092616, 43.189387],
              [-9.092861, 43.189617],
              [-9.092853, 43.189914],
              [-9.092579, 43.189758],
              [-9.092616, 43.189387],
            ],
          ],
          [
            [
              [-8.958477, 42.57287],
              [-8.958776, 42.573196],
              [-8.958466, 42.573393],
              [-8.958333, 42.573282],
              [-8.958477, 42.57287],
            ],
          ],
          [
            [
              [-8.142416, 43.630244],
              [-8.142723, 43.629915],
              [-8.143065, 43.630143],
              [-8.142641, 43.630117],
              [-8.142416, 43.630244],
            ],
          ],
          [
            [
              [-9.14957, 42.796559],
              [-9.149925, 42.796684],
              [-9.149874, 42.796912],
              [-9.149627, 42.79688],
              [-9.14957, 42.796559],
            ],
          ],
          [
            [
              [-8.338771, 43.400806],
              [-8.338805, 43.400633],
              [-8.339415, 43.400686],
              [-8.339009, 43.400984],
              [-8.338771, 43.400806],
            ],
          ],
          [
            [
              [-9.020254, 42.473663],
              [-9.020535, 42.473549],
              [-9.020575, 42.47397],
              [-9.020436, 42.47404],
              [-9.020254, 42.473663],
            ],
          ],
          [
            [
              [-9.047152, 42.5177],
              [-9.047224, 42.517561],
              [-9.047571, 42.517749],
              [-9.047199, 42.518057],
              [-9.047152, 42.5177],
            ],
          ],
          [
            [
              [-9.048251, 42.518244],
              [-9.048562, 42.518183],
              [-9.048767, 42.518415],
              [-9.048465, 42.518566],
              [-9.048251, 42.518244],
            ],
          ],
          [
            [
              [-8.136532, 43.635209],
              [-8.13686, 43.635275],
              [-8.137049, 43.635455],
              [-8.136712, 43.635579],
              [-8.136532, 43.635209],
            ],
          ],
          [
            [
              [-9.140781, 42.792856],
              [-9.140953, 42.793117],
              [-9.140556, 42.793426],
              [-9.140545, 42.79331],
              [-9.140781, 42.792856],
            ],
          ],
          [
            [
              [-9.196359, 43.16138],
              [-9.196618, 43.161267],
              [-9.196516, 43.161713],
              [-9.196334, 43.161624],
              [-9.196359, 43.16138],
            ],
          ],
          [
            [
              [-9.004708, 42.510722],
              [-9.005074, 42.51073],
              [-9.005338, 42.51095],
              [-9.005051, 42.511001],
              [-9.004708, 42.510722],
            ],
          ],
          [
            [
              [-8.022917, 43.712346],
              [-8.023133, 43.712408],
              [-8.023024, 43.712737],
              [-8.022819, 43.712665],
              [-8.022917, 43.712346],
            ],
          ],
          [
            [
              [-7.797489, 43.732665],
              [-7.797829, 43.732713],
              [-7.797688, 43.733133],
              [-7.797452, 43.732819],
              [-7.797489, 43.732665],
            ],
          ],
          [
            [
              [-8.343839, 43.398633],
              [-8.34413, 43.398709],
              [-8.343979, 43.398959],
              [-8.343689, 43.398892],
              [-8.343839, 43.398633],
            ],
          ],
          [
            [
              [-8.835955, 43.344683],
              [-8.836035, 43.344355],
              [-8.836366, 43.34459],
              [-8.836074, 43.344767],
              [-8.835955, 43.344683],
            ],
          ],
          [
            [
              [-9.007428, 42.498895],
              [-9.007571, 42.499112],
              [-9.007173, 42.499277],
              [-9.007149, 42.499152],
              [-9.007428, 42.498895],
            ],
          ],
          [
            [
              [-8.954436, 43.257694],
              [-8.954659, 43.25771],
              [-8.954336, 43.258177],
              [-8.954296, 43.2579],
              [-8.954436, 43.257694],
            ],
          ],
          [
            [
              [-9.143436, 42.857587],
              [-9.143757, 42.857741],
              [-9.143643, 42.858071],
              [-9.143443, 42.858028],
              [-9.143436, 42.857587],
            ],
          ],
          [
            [
              [-8.210087, 43.59459],
              [-8.210403, 43.594666],
              [-8.210566, 43.594829],
              [-8.210183, 43.594838],
              [-8.210087, 43.59459],
            ],
          ],
          [
            [
              [-9.042483, 42.525661],
              [-9.043, 42.525724],
              [-9.043022, 42.525822],
              [-9.042775, 42.525916],
              [-9.042483, 42.525661],
            ],
          ],
          [
            [
              [-9.081535, 42.733469],
              [-9.081725, 42.733414],
              [-9.082034, 42.733703],
              [-9.081542, 42.733666],
              [-9.081535, 42.733469],
            ],
          ],
          [
            [
              [-9.014485, 42.489548],
              [-9.014777, 42.489803],
              [-9.014682, 42.489952],
              [-9.014411, 42.489786],
              [-9.014485, 42.489548],
            ],
          ],
          [
            [
              [-9.172303, 42.799724],
              [-9.172429, 42.799754],
              [-9.17247, 42.800418],
              [-9.172216, 42.800071],
              [-9.172303, 42.799724],
            ],
          ],
          [
            [
              [-8.064257, 43.702181],
              [-8.064661, 43.702262],
              [-8.064736, 43.702411],
              [-8.064247, 43.702361],
              [-8.064257, 43.702181],
            ],
          ],
          [
            [
              [-9.042048, 42.532867],
              [-9.042495, 42.532952],
              [-9.042517, 42.533185],
              [-9.042349, 42.533202],
              [-9.042048, 42.532867],
            ],
          ],
          [
            [
              [-9.013892, 43.235165],
              [-9.014015, 43.234798],
              [-9.0144, 43.234832],
              [-9.01419, 43.235194],
              [-9.013892, 43.235165],
            ],
          ],
          [
            [
              [-8.014449, 43.711566],
              [-8.014761, 43.711732],
              [-8.014771, 43.711858],
              [-8.014355, 43.711777],
              [-8.014449, 43.711566],
            ],
          ],
          [
            [
              [-9.001063, 42.467039],
              [-9.001548, 42.466897],
              [-9.001587, 42.466922],
              [-9.00137, 42.46732],
              [-9.001063, 42.467039],
            ],
          ],
          [
            [
              [-9.131675, 42.844871],
              [-9.131918, 42.844975],
              [-9.132068, 42.845381],
              [-9.131657, 42.845061],
              [-9.131675, 42.844871],
            ],
          ],
          [
            [
              [-9.070597, 42.571869],
              [-9.070989, 42.571884],
              [-9.071094, 42.571969],
              [-9.07067, 42.572117],
              [-9.070597, 42.571869],
            ],
          ],
          [
            [
              [-9.145304, 42.816485],
              [-9.145514, 42.816509],
              [-9.145715, 42.816679],
              [-9.145265, 42.816703],
              [-9.145304, 42.816485],
            ],
          ],
          [
            [
              [-9.024857, 42.492674],
              [-9.025149, 42.492677],
              [-9.025236, 42.492951],
              [-9.024959, 42.492975],
              [-9.024857, 42.492674],
            ],
          ],
          [
            [
              [-9.147037, 42.862911],
              [-9.147186, 42.862804],
              [-9.147553, 42.862928],
              [-9.14729, 42.863114],
              [-9.147037, 42.862911],
            ],
          ],
          [
            [
              [-9.151211, 42.870041],
              [-9.151441, 42.870136],
              [-9.151128, 42.870441],
              [-9.151051, 42.870283],
              [-9.151211, 42.870041],
            ],
          ],
          [
            [
              [-8.591725, 43.321514],
              [-8.591873, 43.321507],
              [-8.59201, 43.321662],
              [-8.591615, 43.32179],
              [-8.591725, 43.321514],
            ],
          ],
          [
            [
              [-9.019006, 42.492668],
              [-9.019124, 42.492635],
              [-9.019613, 42.492915],
              [-9.019099, 42.492879],
              [-9.019006, 42.492668],
            ],
          ],
          [
            [
              [-9.046802, 42.508798],
              [-9.047224, 42.50864],
              [-9.047324, 42.508788],
              [-9.047047, 42.508947],
              [-9.046802, 42.508798],
            ],
          ],
          [
            [
              [-8.332757, 43.512511],
              [-8.333034, 43.51257],
              [-8.333116, 43.512791],
              [-8.332804, 43.51277],
              [-8.332757, 43.512511],
            ],
          ],
          [
            [
              [-9.046897, 42.511538],
              [-9.047335, 42.511794],
              [-9.047386, 42.511944],
              [-9.047101, 42.511887],
              [-9.046897, 42.511538],
            ],
          ],
          [
            [
              [-9.140996, 42.787264],
              [-9.141215, 42.787252],
              [-9.141318, 42.787426],
              [-9.140963, 42.787545],
              [-9.140996, 42.787264],
            ],
          ],
          [
            [
              [-9.010854, 42.485932],
              [-9.011044, 42.486147],
              [-9.010869, 42.486471],
              [-9.010797, 42.486358],
              [-9.010854, 42.485932],
            ],
          ],
          [
            [
              [-9.189552, 43.184605],
              [-9.189856, 43.184813],
              [-9.189825, 43.184995],
              [-9.189555, 43.184884],
              [-9.189552, 43.184605],
            ],
          ],
          [
            [
              [-8.917817, 42.595653],
              [-8.917995, 42.595608],
              [-8.918118, 42.596132],
              [-8.917707, 42.595784],
              [-8.917817, 42.595653],
            ],
          ],
          [
            [
              [-9.168858, 42.862076],
              [-9.169179, 42.861921],
              [-9.169261, 42.862178],
              [-9.169029, 42.862287],
              [-9.168858, 42.862076],
            ],
          ],
          [
            [
              [-9.091699, 42.574193],
              [-9.091964, 42.574169],
              [-9.092174, 42.57432],
              [-9.091715, 42.574363],
              [-9.091699, 42.574193],
            ],
          ],
          [
            [
              [-8.189926, 43.617917],
              [-8.190053, 43.618245],
              [-8.18994, 43.61852],
              [-8.189825, 43.618048],
              [-8.189926, 43.617917],
            ],
          ],
          [
            [
              [-8.583922, 43.317234],
              [-8.58416, 43.317411],
              [-8.583737, 43.317504],
              [-8.58369, 43.317389],
              [-8.583922, 43.317234],
            ],
          ],
          [
            [
              [-9.161722, 42.912167],
              [-9.161891, 42.912383],
              [-9.161907, 42.912796],
              [-9.161698, 42.912421],
              [-9.161722, 42.912167],
            ],
          ],
          [
            [
              [-9.047559, 42.507821],
              [-9.04773, 42.50783],
              [-9.047826, 42.508194],
              [-9.047608, 42.508206],
              [-9.047559, 42.507821],
            ],
          ],
          [
            [
              [-9.047748, 42.505003],
              [-9.048013, 42.504971],
              [-9.04817, 42.505214],
              [-9.047792, 42.505198],
              [-9.047748, 42.505003],
            ],
          ],
          [
            [
              [-9.18381, 42.879295],
              [-9.184124, 42.879621],
              [-9.184126, 42.879764],
              [-9.1838, 42.879557],
              [-9.18381, 42.879295],
            ],
          ],
          [
            [
              [-8.047419, 43.715621],
              [-8.047706, 43.715491],
              [-8.047847, 43.715692],
              [-8.047684, 43.715816],
              [-8.047419, 43.715621],
            ],
          ],
          [
            [
              [-9.059611, 42.6117],
              [-9.059879, 42.611694],
              [-9.060041, 42.611983],
              [-9.0596, 42.611835],
              [-9.059611, 42.6117],
            ],
          ],
          [
            [
              [-9.124793, 43.197663],
              [-9.124939, 43.197646],
              [-9.125289, 43.197825],
              [-9.125018, 43.197939],
              [-9.124793, 43.197663],
            ],
          ],
          [
            [
              [-7.9048, 43.77323],
              [-7.905042, 43.773301],
              [-7.905001, 43.773555],
              [-7.904731, 43.77344],
              [-7.9048, 43.77323],
            ],
          ],
          [
            [
              [-8.849826, 43.361377],
              [-8.849917, 43.361687],
              [-8.849598, 43.361703],
              [-8.849595, 43.361667],
              [-8.849826, 43.361377],
            ],
          ],
          [
            [
              [-9.11967, 42.83989],
              [-9.11981, 42.839945],
              [-9.119915, 42.840381],
              [-9.11975, 42.840326],
              [-9.11967, 42.83989],
            ],
          ],
          [
            [
              [-8.14164, 43.632034],
              [-8.141855, 43.632088],
              [-8.141468, 43.632348],
              [-8.141465, 43.632168],
              [-8.14164, 43.632034],
            ],
          ],
          [
            [
              [-8.31116, 43.544983],
              [-8.311306, 43.545103],
              [-8.310758, 43.545335],
              [-8.310879, 43.545168],
              [-8.31116, 43.544983],
            ],
          ],
          [
            [
              [-9.090059, 42.738325],
              [-9.090344, 42.738373],
              [-9.090369, 42.738497],
              [-9.090113, 42.738628],
              [-9.090059, 42.738325],
            ],
          ],
          [
            [
              [-9.015251, 42.463998],
              [-9.015377, 42.464162],
              [-9.015363, 42.464397],
              [-9.015087, 42.464186],
              [-9.015251, 42.463998],
            ],
          ],
          [
            [
              [-9.047571, 42.512096],
              [-9.047724, 42.512043],
              [-9.047903, 42.512259],
              [-9.047586, 42.512375],
              [-9.047571, 42.512096],
            ],
          ],
          [
            [
              [-8.999143, 42.778455],
              [-8.999349, 42.778444],
              [-8.999622, 42.77861],
              [-8.999223, 42.778658],
              [-8.999143, 42.778455],
            ],
          ],
          [
            [
              [-9.119897, 42.838599],
              [-9.120251, 42.838589],
              [-9.120347, 42.838692],
              [-9.119964, 42.838785],
              [-9.119897, 42.838599],
            ],
          ],
          [
            [
              [-9.175593, 43.189737],
              [-9.175773, 43.189691],
              [-9.176053, 43.18991],
              [-9.175814, 43.189977],
              [-9.175593, 43.189737],
            ],
          ],
          [
            [
              [-9.19514, 43.162823],
              [-9.195371, 43.162793],
              [-9.195244, 43.163115],
              [-9.19503, 43.162946],
              [-9.19514, 43.162823],
            ],
          ],
          [
            [
              [-9.187499, 43.171268],
              [-9.187824, 43.171322],
              [-9.187797, 43.17154],
              [-9.18755, 43.171526],
              [-9.187499, 43.171268],
            ],
          ],
          [
            [
              [-9.143971, 42.794827],
              [-9.144106, 42.794712],
              [-9.144203, 42.795192],
              [-9.144035, 42.795102],
              [-9.143971, 42.794827],
            ],
          ],
          [
            [
              [-9.002119, 42.489839],
              [-9.002298, 42.489803],
              [-9.002769, 42.490147],
              [-9.002537, 42.490141],
              [-9.002119, 42.489839],
            ],
          ],
          [
            [
              [-9.151713, 42.805689],
              [-9.151988, 42.805755],
              [-9.152129, 42.806062],
              [-9.151939, 42.806],
              [-9.151713, 42.805689],
            ],
          ],
          [
            [
              [-8.436424, 43.382733],
              [-8.436619, 43.382705],
              [-8.436785, 43.382904],
              [-8.436589, 43.383058],
              [-8.436424, 43.382733],
            ],
          ],
          [
            [
              [-9.134972, 43.197089],
              [-9.135313, 43.197178],
              [-9.135355, 43.197347],
              [-9.134978, 43.197268],
              [-9.134972, 43.197089],
            ],
          ],
          [
            [
              [-9.081684, 42.597257],
              [-9.081955, 42.597279],
              [-9.082161, 42.59752],
              [-9.081662, 42.597403],
              [-9.081684, 42.597257],
            ],
          ],
          [
            [
              [-8.339387, 43.401209],
              [-8.339469, 43.401025],
              [-8.339957, 43.401092],
              [-8.339469, 43.401295],
              [-8.339387, 43.401209],
            ],
          ],
          [
            [
              [-9.135218, 43.199164],
              [-9.135608, 43.198891],
              [-9.135804, 43.198997],
              [-9.135513, 43.199166],
              [-9.135218, 43.199164],
            ],
          ],
          [
            [
              [-9.064077, 42.609752],
              [-9.064609, 42.609715],
              [-9.064657, 42.609829],
              [-9.064322, 42.609892],
              [-9.064077, 42.609752],
            ],
          ],
          [
            [
              [-9.150655, 42.797023],
              [-9.150928, 42.796936],
              [-9.151022, 42.797147],
              [-9.15082, 42.797203],
              [-9.150655, 42.797023],
            ],
          ],
          [
            [
              [-9.130746, 42.777679],
              [-9.13103, 42.777709],
              [-9.130642, 42.777982],
              [-9.130624, 42.777803],
              [-9.130746, 42.777679],
            ],
          ],
          [
            [
              [-8.014551, 43.712192],
              [-8.014755, 43.712255],
              [-8.014845, 43.71244],
              [-8.014475, 43.712474],
              [-8.014551, 43.712192],
            ],
          ],
          [
            [
              [-9.094757, 42.576703],
              [-9.095, 42.57669],
              [-9.095022, 42.576923],
              [-9.094784, 42.576972],
              [-9.094757, 42.576703],
            ],
          ],
          [
            [
              [-8.965175, 42.567263],
              [-8.965413, 42.56708],
              [-8.965436, 42.567448],
              [-8.965266, 42.567447],
              [-8.965175, 42.567263],
            ],
          ],
          [
            [
              [-9.017676, 42.487319],
              [-9.017906, 42.487307],
              [-9.017854, 42.487652],
              [-9.017702, 42.487588],
              [-9.017676, 42.487319],
            ],
          ],
          [
            [
              [-9.100233, 43.188169],
              [-9.100458, 43.188328],
              [-9.100256, 43.188528],
              [-9.100138, 43.188327],
              [-9.100233, 43.188169],
            ],
          ],
          [
            [
              [-8.851414, 43.325386],
              [-8.851695, 43.325354],
              [-8.851758, 43.325495],
              [-8.851514, 43.325651],
              [-8.851414, 43.325386],
            ],
          ],
          [
            [
              [-8.121361, 43.645263],
              [-8.12166, 43.645286],
              [-8.121556, 43.645515],
              [-8.121308, 43.645517],
              [-8.121361, 43.645263],
            ],
          ],
          [
            [
              [-8.952285, 43.29376],
              [-8.952371, 43.293756],
              [-8.9526, 43.293834],
              [-8.952068, 43.293934],
              [-8.952285, 43.29376],
            ],
          ],
          [
            [
              [-9.156974, 42.910732],
              [-9.157298, 42.910669],
              [-9.15754, 42.910755],
              [-9.157331, 42.910874],
              [-9.156974, 42.910732],
            ],
          ],
          [
            [
              [-9.163043, 42.912762],
              [-9.163211, 42.912978],
              [-9.163156, 42.913404],
              [-9.163053, 42.913104],
              [-9.163043, 42.912762],
            ],
          ],
          [
            [
              [-9.015073, 42.487699],
              [-9.015321, 42.487875],
              [-9.015219, 42.488079],
              [-9.014998, 42.487928],
              [-9.015073, 42.487699],
            ],
          ],
          [
            [
              [-9.131206, 42.844464],
              [-9.131391, 42.844472],
              [-9.131554, 42.84476],
              [-9.131241, 42.844687],
              [-9.131206, 42.844464],
            ],
          ],
          [
            [
              [-9.127709, 42.843122],
              [-9.127917, 42.843246],
              [-9.12782, 42.843494],
              [-9.127652, 42.843404],
              [-9.127709, 42.843122],
            ],
          ],
          [
            [
              [-8.199746, 43.603642],
              [-8.199954, 43.603902],
              [-8.199792, 43.604036],
              [-8.199631, 43.603899],
              [-8.199746, 43.603642],
            ],
          ],
          [
            [
              [-8.334082, 43.401673],
              [-8.334099, 43.401583],
              [-8.3346, 43.401523],
              [-8.334328, 43.401806],
              [-8.334082, 43.401673],
            ],
          ],
          [
            [
              [-9.041691, 42.52465],
              [-9.041997, 42.524661],
              [-9.042067, 42.524882],
              [-9.041856, 42.524965],
              [-9.041691, 42.52465],
            ],
          ],
          [
            [
              [-8.595939, 43.320542],
              [-8.596164, 43.320576],
              [-8.59621, 43.320673],
              [-8.595782, 43.320712],
              [-8.595939, 43.320542],
            ],
          ],
          [
            [
              [-8.99045, 43.273478],
              [-8.990576, 43.273381],
              [-8.991124, 43.273433],
              [-8.990815, 43.273558],
              [-8.99045, 43.273478],
            ],
          ],
          [
            [
              [-9.204601, 43.137203],
              [-9.205003, 43.137289],
              [-9.205033, 43.137342],
              [-9.204774, 43.137455],
              [-9.204601, 43.137203],
            ],
          ],
          [
            [
              [-9.062682, 42.609574],
              [-9.06289, 42.609581],
              [-9.062912, 42.609805],
              [-9.062605, 42.609776],
              [-9.062682, 42.609574],
            ],
          ],
          [
            [
              [-8.196813, 43.613868],
              [-8.197002, 43.614048],
              [-8.19682, 43.614236],
              [-8.196726, 43.61416],
              [-8.196813, 43.613868],
            ],
          ],
          [
            [
              [-9.197811, 43.161274],
              [-9.197989, 43.16121],
              [-9.19813, 43.161382],
              [-9.197846, 43.161497],
              [-9.197811, 43.161274],
            ],
          ],
          [
            [
              [-9.152008, 42.805466],
              [-9.152254, 42.805353],
              [-9.15244, 42.805505],
              [-9.1523, 42.805567],
              [-9.152008, 42.805466],
            ],
          ],
          [
            [
              [-9.151674, 42.871249],
              [-9.151838, 42.87142],
              [-9.151673, 42.871609],
              [-9.151542, 42.8714],
              [-9.151674, 42.871249],
            ],
          ],
          [
            [
              [-9.023321, 42.481395],
              [-9.023467, 42.481271],
              [-9.023769, 42.481381],
              [-9.023646, 42.481495],
              [-9.023321, 42.481395],
            ],
          ],
          [
            [
              [-8.121272, 43.644655],
              [-8.121491, 43.644753],
              [-8.1211, 43.644969],
              [-8.12109, 43.644852],
              [-8.121272, 43.644655],
            ],
          ],
          [
            [
              [-9.008784, 42.515152],
              [-9.008958, 42.515188],
              [-9.008599, 42.515504],
              [-9.008567, 42.515425],
              [-9.008784, 42.515152],
            ],
          ],
          [
            [
              [-8.998689, 42.468397],
              [-8.999032, 42.468559],
              [-8.999007, 42.468669],
              [-8.998738, 42.468656],
              [-8.998689, 42.468397],
            ],
          ],
          [
            [
              [-9.284156, 42.953185],
              [-9.284415, 42.953197],
              [-9.284511, 42.953417],
              [-9.284264, 42.953404],
              [-9.284156, 42.953185],
            ],
          ],
          [
            [
              [-9.002599, 42.497627],
              [-9.002756, 42.497745],
              [-9.00241, 42.497808],
              [-9.002389, 42.497719],
              [-9.002599, 42.497627],
            ],
          ],
          [
            [
              [-8.040035, 43.714012],
              [-8.040421, 43.714022],
              [-8.040515, 43.714108],
              [-8.040287, 43.714199],
              [-8.040035, 43.714012],
            ],
          ],
          [
            [
              [-9.148403, 42.810395],
              [-9.1486, 42.810402],
              [-9.148707, 42.810621],
              [-9.148423, 42.810592],
              [-9.148403, 42.810395],
            ],
          ],
          [
            [
              [-9.082437, 42.579754],
              [-9.082688, 42.579831],
              [-9.08271, 42.580055],
              [-9.082427, 42.579899],
              [-9.082437, 42.579754],
            ],
          ],
          [
            [
              [-7.76816, 43.739791],
              [-7.768369, 43.739773],
              [-7.768477, 43.739877],
              [-7.768231, 43.740067],
              [-7.76816, 43.739791],
            ],
          ],
          [
            [
              [-7.766971, 43.739526],
              [-7.767168, 43.739652],
              [-7.76686, 43.739836],
              [-7.766971, 43.739526],
            ],
          ],
          [
            [
              [-9.000029, 42.471343],
              [-9.000085, 42.471295],
              [-9.00048, 42.471355],
              [-9.000118, 42.471509],
              [-9.000029, 42.471343],
            ],
          ],
          [
            [
              [-9.018849, 42.470685],
              [-9.019065, 42.470656],
              [-9.018603, 42.470788],
              [-9.018649, 42.470759],
              [-9.018849, 42.470685],
            ],
          ],
          [
            [
              [-9.261518, 43.000067],
              [-9.261708, 43.000129],
              [-9.261602, 43.000414],
              [-9.261421, 43.000325],
              [-9.261518, 43.000067],
            ],
          ],
          [
            [
              [-9.015414, 42.491498],
              [-9.015571, 42.491481],
              [-9.015569, 42.491841],
              [-9.015442, 42.491784],
              [-9.015414, 42.491498],
            ],
          ],
          [
            [
              [-9.137329, 43.198753],
              [-9.137444, 43.198549],
              [-9.137663, 43.198762],
              [-9.1375, 43.198861],
              [-9.137329, 43.198753],
            ],
          ],
          [
            [
              [-9.077728, 43.185285],
              [-9.077888, 43.185285],
              [-9.078012, 43.185423],
              [-9.077694, 43.185566],
              [-9.077728, 43.185285],
            ],
          ],
          [
            [
              [-8.991474, 42.533849],
              [-8.991712, 42.534044],
              [-8.991533, 42.534215],
              [-8.991444, 42.534175],
              [-8.991474, 42.533849],
            ],
          ],
          [
            [
              [-9.146469, 42.861628],
              [-9.146569, 42.861649],
              [-9.146805, 42.861934],
              [-9.146514, 42.861958],
              [-9.146469, 42.861628],
            ],
          ],
          [
            [
              [-8.015845, 43.712675],
              [-8.015952, 43.712922],
              [-8.015724, 43.713013],
              [-8.015662, 43.712863],
              [-8.015845, 43.712675],
            ],
          ],
          [
            [
              [-8.198547, 43.610422],
              [-8.198634, 43.610427],
              [-8.198622, 43.610859],
              [-8.198417, 43.610635],
              [-8.198547, 43.610422],
            ],
          ],
          [
            [
              [-8.950984, 43.245938],
              [-8.951151, 43.246002],
              [-8.950982, 43.246298],
              [-8.950874, 43.24607],
              [-8.950984, 43.245938],
            ],
          ],
          [
            [
              [-9.022807, 42.482037],
              [-9.023062, 42.482032],
              [-9.022898, 42.482356],
              [-9.022835, 42.482332],
              [-9.022807, 42.482037],
            ],
          ],
          [
            [
              [-8.056966, 43.709319],
              [-8.057217, 43.709344],
              [-8.057246, 43.70955],
              [-8.056944, 43.7095],
              [-8.056966, 43.709319],
            ],
          ],
          [
            [
              [-8.605221, 43.322796],
              [-8.605393, 43.322923],
              [-8.605228, 43.323138],
              [-8.605129, 43.322999],
              [-8.605221, 43.322796],
            ],
          ],
          [
            [
              [-8.878209, 43.320299],
              [-8.878386, 43.320344],
              [-8.878218, 43.32064],
              [-8.878113, 43.320448],
              [-8.878209, 43.320299],
            ],
          ],
          [
            [
              [-9.042795, 42.533161],
              [-9.042981, 42.533196],
              [-9.043101, 42.533433],
              [-9.042816, 42.533376],
              [-9.042795, 42.533161],
            ],
          ],
          [
            [
              [-9.097271, 43.193864],
              [-9.097353, 43.193823],
              [-9.097244, 43.194333],
              [-9.097145, 43.194078],
              [-9.097271, 43.193864],
            ],
          ],
          [
            [
              [-8.999413, 42.489522],
              [-8.99964, 42.489483],
              [-8.999776, 42.489629],
              [-8.999442, 42.489701],
              [-8.999413, 42.489522],
            ],
          ],
          [
            [
              [-8.014858, 43.712151],
              [-8.015093, 43.712294],
              [-8.015084, 43.712483],
              [-8.014905, 43.712419],
              [-8.014858, 43.712151],
            ],
          ],
          [
            [
              [-9.14031, 42.84978],
              [-9.140499, 42.849959],
              [-9.140292, 42.850087],
              [-9.140188, 42.849904],
              [-9.14031, 42.84978],
            ],
          ],
          [
            [
              [-8.449533, 43.381783],
              [-8.449894, 43.381819],
              [-8.449982, 43.381968],
              [-8.44967, 43.381938],
              [-8.449533, 43.381783],
            ],
          ],
          [
            [
              [-7.77112, 43.739856],
              [-7.771315, 43.739803],
              [-7.771355, 43.740153],
              [-7.771142, 43.740125],
              [-7.77112, 43.739856],
            ],
          ],
          [
            [
              [-9.129101, 42.808814],
              [-9.129334, 42.808828],
              [-9.129409, 42.808968],
              [-9.129107, 42.809003],
              [-9.129101, 42.808814],
            ],
          ],
          [
            [
              [-9.086804, 42.578946],
              [-9.087062, 42.578968],
              [-9.087069, 42.579165],
              [-9.08677, 42.5791],
              [-9.086804, 42.578946],
            ],
          ],
          [
            [
              [-9.180151, 43.180326],
              [-9.180392, 43.18052],
              [-9.180312, 43.180695],
              [-9.180166, 43.180595],
              [-9.180151, 43.180326],
            ],
          ],
          [
            [
              [-9.137651, 42.848627],
              [-9.137813, 42.84878],
              [-9.137713, 42.849002],
              [-9.137566, 42.848884],
              [-9.137651, 42.848627],
            ],
          ],
          [
            [
              [-8.068659, 43.689057],
              [-8.068922, 43.689091],
              [-8.06907, 43.689228],
              [-8.068802, 43.689285],
              [-8.068659, 43.689057],
            ],
          ],
          [
            [
              [-9.003908, 42.511259],
              [-9.004111, 42.511347],
              [-9.004038, 42.511594],
              [-9.003892, 42.511593],
              [-9.003908, 42.511259],
            ],
          ],
          [
            [
              [-9.016698, 42.484985],
              [-9.01684, 42.485203],
              [-9.016732, 42.485343],
              [-9.016584, 42.485072],
              [-9.016698, 42.484985],
            ],
          ],
          [
            [
              [-9.045099, 42.533075],
              [-9.045365, 42.533061],
              [-9.045387, 42.533159],
              [-9.044928, 42.533192],
              [-9.045099, 42.533075],
            ],
          ],
          [
            [
              [-8.016252, 43.712504],
              [-8.016577, 43.712526],
              [-8.016606, 43.712722],
              [-8.016521, 43.712744],
              [-8.016252, 43.712504],
            ],
          ],
          [
            [
              [-9.151682, 42.798867],
              [-9.151735, 42.798783],
              [-9.152132, 42.798969],
              [-9.151904, 42.799008],
              [-9.151682, 42.798867],
            ],
          ],
          [
            [
              [-8.016125, 43.712609],
              [-8.016346, 43.712734],
              [-8.016245, 43.712864],
              [-8.016055, 43.712819],
              [-8.016125, 43.712609],
            ],
          ],
          [
            [
              [-9.168729, 42.797712],
              [-9.168891, 42.79774],
              [-9.168818, 42.797996],
              [-9.168651, 42.797915],
              [-9.168729, 42.797712],
            ],
          ],
          [
            [
              [-7.762625, 43.7414],
              [-7.7626, 43.741239],
              [-7.763006, 43.741339],
              [-7.762817, 43.741464],
              [-7.762625, 43.7414],
            ],
          ],
          [
            [
              [-7.773153, 43.740122],
              [-7.773374, 43.740095],
              [-7.773543, 43.740187],
              [-7.773179, 43.740292],
              [-7.773153, 43.740122],
            ],
          ],
          [
            [
              [-9.047471, 42.53457],
              [-9.047768, 42.534617],
              [-9.047717, 42.534845],
              [-9.047447, 42.534697],
              [-9.047471, 42.53457],
            ],
          ],
          [
            [
              [-8.590443, 43.318435],
              [-8.590589, 43.31841],
              [-8.590666, 43.318578],
              [-8.59038, 43.318682],
              [-8.590443, 43.318435],
            ],
          ],
          [
            [
              [-9.096007, 43.193769],
              [-9.096094, 43.194026],
              [-9.095818, 43.193977],
              [-9.095951, 43.193826],
              [-9.096007, 43.193769],
            ],
          ],
          [
            [
              [-7.861507, 43.765784],
              [-7.86168, 43.765768],
              [-7.861837, 43.76586],
              [-7.861474, 43.765984],
              [-7.861507, 43.765784],
            ],
          ],
          [
            [
              [-8.518393, 43.32822],
              [-8.518641, 43.328235],
              [-8.518637, 43.32846],
              [-8.518446, 43.328398],
              [-8.518393, 43.32822],
            ],
          ],
          [
            [
              [-9.148879, 42.811863],
              [-9.149076, 42.811871],
              [-9.149156, 42.812064],
              [-9.148859, 42.812026],
              [-9.148879, 42.811863],
            ],
          ],
          [
            [
              [-9.008672, 43.235044],
              [-9.008633, 43.234902],
              [-9.009289, 43.234814],
              [-9.008882, 43.235051],
              [-9.008672, 43.235044],
            ],
          ],
          [
            [
              [-9.00753, 42.512986],
              [-9.007696, 42.513067],
              [-9.007668, 42.513285],
              [-9.007459, 42.513134],
              [-9.00753, 42.512986],
            ],
          ],
          [
            [
              [-9.019287, 42.484219],
              [-9.019413, 42.48414],
              [-9.01961, 42.484283],
              [-9.019451, 42.484408],
              [-9.019287, 42.484219],
            ],
          ],
          [
            [
              [-7.750251, 43.747272],
              [-7.750378, 43.747303],
              [-7.750493, 43.747505],
              [-7.750132, 43.747484],
              [-7.750251, 43.747272],
            ],
          ],
          [
            [
              [-7.73504, 43.748076],
              [-7.735101, 43.747902],
              [-7.735292, 43.748119],
              [-7.735127, 43.748234],
              [-7.73504, 43.748076],
            ],
          ],
          [
            [
              [-8.842515, 43.344648],
              [-8.842633, 43.344723],
              [-8.84241, 43.344968],
              [-8.842306, 43.344785],
              [-8.842515, 43.344648],
            ],
          ],
          [
            [
              [-8.216883, 43.59211],
              [-8.21707, 43.592119],
              [-8.217239, 43.592219],
              [-8.217001, 43.592329],
              [-8.216883, 43.59211],
            ],
          ],
          [
            [
              [-8.212562, 43.418566],
              [-8.21277, 43.418547],
              [-8.212825, 43.418608],
              [-8.212468, 43.418768],
              [-8.212562, 43.418566],
            ],
          ],
          [
            [
              [-8.999957, 42.489359],
              [-9.000128, 42.489368],
              [-8.999992, 42.4896],
              [-8.999776, 42.489503],
              [-8.999957, 42.489359],
            ],
          ],
          [
            [
              [-9.119912, 42.839364],
              [-9.120108, 42.839371],
              [-9.120245, 42.839517],
              [-9.119991, 42.839548],
              [-9.119912, 42.839364],
            ],
          ],
          [
            [
              [-8.038173, 43.714301],
              [-8.0383, 43.714332],
              [-8.038256, 43.71455],
              [-8.038012, 43.714452],
              [-8.038173, 43.714301],
            ],
          ],
          [
            [
              [-8.599882, 43.319854],
              [-8.600123, 43.319923],
              [-8.599922, 43.320149],
              [-8.599827, 43.320054],
              [-8.599882, 43.319854],
            ],
          ],
          [
            [
              [-8.57367, 43.316358],
              [-8.573821, 43.316387],
              [-8.573953, 43.316488],
              [-8.573578, 43.316561],
              [-8.57367, 43.316358],
            ],
          ],
          [
            [
              [-9.011983, 42.487304],
              [-9.012118, 42.48745],
              [-9.011913, 42.487596],
              [-9.011839, 42.487455],
              [-9.011983, 42.487304],
            ],
          ],
          [
            [
              [-8.195102, 43.608697],
              [-8.195279, 43.608878],
              [-8.195199, 43.608954],
              [-8.194968, 43.608856],
              [-8.195102, 43.608697],
            ],
          ],
          [
            [
              [-9.151507, 42.795753],
              [-9.151582, 42.79565],
              [-9.15185, 42.795761],
              [-9.151654, 42.79588],
              [-9.151507, 42.795753],
            ],
          ],
          [
            [
              [-8.951037, 43.245593],
              [-8.951245, 43.245583],
              [-8.951229, 43.245799],
              [-8.951053, 43.245764],
              [-8.951037, 43.245593],
            ],
          ],
          [
            [
              [-8.919295, 42.587484],
              [-8.919624, 42.58762],
              [-8.919681, 42.587698],
              [-8.919453, 42.587736],
              [-8.919295, 42.587484],
            ],
          ],
          [
            [
              [-9.175324, 43.188545],
              [-9.175567, 43.188757],
              [-9.175365, 43.188948],
              [-9.175406, 43.188622],
              [-9.175324, 43.188545],
            ],
          ],
          [
            [
              [-8.922282, 42.596357],
              [-8.922569, 42.596424],
              [-8.92262, 42.596583],
              [-8.92227, 42.596484],
              [-8.922282, 42.596357],
            ],
          ],
          [
            [
              [-8.903554, 43.321905],
              [-8.903571, 43.321706],
              [-8.903842, 43.321827],
              [-8.903599, 43.321993],
              [-8.903554, 43.321905],
            ],
          ],
          [
            [
              [-8.834429, 43.344963],
              [-8.834597, 43.345035],
              [-8.834586, 43.34518],
              [-8.834335, 43.345139],
              [-8.834429, 43.344963],
            ],
          ],
          [
            [
              [-7.751061, 43.746986],
              [-7.75132, 43.746966],
              [-7.750907, 43.747245],
              [-7.750969, 43.74708],
              [-7.751061, 43.746986],
            ],
          ],
          [
            [
              [-8.90332, 43.322413],
              [-8.90313, 43.322368],
              [-8.903502, 43.322259],
              [-8.903479, 43.322404],
              [-8.90332, 43.322413],
            ],
          ],
          [
            [
              [-8.144112, 43.627746],
              [-8.144116, 43.627511],
              [-8.144357, 43.627573],
              [-8.144327, 43.627655],
              [-8.144112, 43.627746],
            ],
          ],
          [
            [
              [-8.971861, 42.568543],
              [-8.972072, 42.568577],
              [-8.972169, 42.568698],
              [-8.971892, 42.56873],
              [-8.971861, 42.568543],
            ],
          ],
          [
            [
              [-9.29253, 42.939923],
              [-9.292772, 42.940017],
              [-9.29256, 42.940218],
              [-9.292457, 42.940053],
              [-9.29253, 42.939923],
            ],
          ],
          [
            [
              [-9.052713, 42.507647],
              [-9.052457, 42.507643],
              [-9.052293, 42.507588],
              [-9.052477, 42.50748],
              [-9.052713, 42.507647],
            ],
          ],
          [
            [
              [-8.304981, 43.542129],
              [-8.305254, 43.542144],
              [-8.305276, 43.542251],
              [-8.304959, 43.542301],
              [-8.304981, 43.542129],
            ],
          ],
          [
            [
              [-9.049951, 42.536167],
              [-9.050206, 42.536162],
              [-9.050265, 42.536276],
              [-9.050065, 42.53634],
              [-9.049951, 42.536167],
            ],
          ],
          [
            [
              [-8.832995, 43.345066],
              [-8.833168, 43.345144],
              [-8.83285, 43.345281],
              [-8.832832, 43.345161],
              [-8.832995, 43.345066],
            ],
          ],
          [
            [
              [-7.750698, 43.74837],
              [-7.750936, 43.748387],
              [-7.750579, 43.748582],
              [-7.75056, 43.748502],
              [-7.750698, 43.74837],
            ],
          ],
          [
            [
              [-9.082613, 42.733015],
              [-9.082806, 42.732987],
              [-9.082986, 42.733076],
              [-9.082825, 42.733175],
              [-9.082613, 42.733015],
            ],
          ],
          [
            [
              [-9.022334, 42.481754],
              [-9.022566, 42.481768],
              [-9.0225, 42.48197],
              [-9.022352, 42.481942],
              [-9.022334, 42.481754],
            ],
          ],
          [
            [
              [-8.019068, 43.712479],
              [-8.019332, 43.712522],
              [-8.019275, 43.712731],
              [-8.019127, 43.712738],
              [-8.019068, 43.712479],
            ],
          ],
          [
            [
              [-9.129039, 42.843212],
              [-9.129214, 42.843248],
              [-9.129456, 42.843469],
              [-9.129129, 42.843378],
              [-9.129039, 42.843212],
            ],
          ],
          [
            [
              [-9.038821, 42.563418],
              [-9.038611, 42.563394],
              [-9.039052, 42.563415],
              [-9.038853, 42.563498],
              [-9.038821, 42.563418],
            ],
          ],
          [
            [
              [-9.096507, 43.193356],
              [-9.09669, 43.193346],
              [-9.09644, 43.193674],
              [-9.096384, 43.193605],
              [-9.096507, 43.193356],
            ],
          ],
          [
            [
              [-9.013061, 42.484475],
              [-9.012858, 42.484386],
              [-9.013257, 42.484365],
              [-9.013195, 42.484477],
              [-9.013061, 42.484475],
            ],
          ],
          [
            [
              [-9.168181, 42.798588],
              [-9.168324, 42.798553],
              [-9.16834, 42.798841],
              [-9.16816, 42.798751],
              [-9.168181, 42.798588],
            ],
          ],
          [
            [
              [-9.1438, 42.891846],
              [-9.14394, 42.891892],
              [-9.143896, 42.892066],
              [-9.143653, 42.891962],
              [-9.1438, 42.891846],
            ],
          ],
          [
            [
              [-9.144128, 42.815846],
              [-9.144285, 42.815954],
              [-9.144144, 42.816133],
              [-9.144055, 42.815976],
              [-9.144128, 42.815846],
            ],
          ],
          [
            [
              [-8.016504, 43.712844],
              [-8.016653, 43.712846],
              [-8.016438, 43.71309],
              [-8.016349, 43.712914],
              [-8.016504, 43.712844],
            ],
          ],
          [
            [
              [-8.217075, 43.591462],
              [-8.217104, 43.591658],
              [-8.216845, 43.59167],
              [-8.216897, 43.591551],
              [-8.217075, 43.591462],
            ],
          ],
          [
            [
              [-9.023317, 42.481035],
              [-9.023421, 42.480958],
              [-9.023699, 42.481097],
              [-9.023587, 42.481186],
              [-9.023317, 42.481035],
            ],
          ],
          [
            [
              [-9.022163, 42.481348],
              [-9.022326, 42.481277],
              [-9.022426, 42.481425],
              [-9.022264, 42.481514],
              [-9.022163, 42.481348],
            ],
          ],
          [
            [
              [-9.018794, 42.471255],
              [-9.018922, 42.47132],
              [-9.018834, 42.471541],
              [-9.018677, 42.471423],
              [-9.018794, 42.471255],
            ],
          ],
          [
            [
              [-7.764393, 43.740209],
              [-7.764536, 43.740131],
              [-7.764677, 43.740189],
              [-7.764486, 43.740431],
              [-7.764393, 43.740209],
            ],
          ],
          [
            [
              [-9.052038, 42.508349],
              [-9.05207, 42.508545],
              [-9.051809, 42.508613],
              [-9.051825, 42.508531],
              [-9.052038, 42.508349],
            ],
          ],
          [
            [
              [-7.73731, 43.746784],
              [-7.737525, 43.746676],
              [-7.737486, 43.746975],
              [-7.737333, 43.746918],
              [-7.73731, 43.746784],
            ],
          ],
          [
            [
              [-9.091869, 42.577928],
              [-9.092027, 42.577929],
              [-9.092059, 42.578134],
              [-9.091865, 42.578135],
              [-9.091869, 42.577928],
            ],
          ],
          [
            [
              [-9.017778, 42.780093],
              [-9.017948, 42.780075],
              [-9.018037, 42.780241],
              [-9.017896, 42.780294],
              [-9.017778, 42.780093],
            ],
          ],
          [
            [
              [-9.140275, 42.791326],
              [-9.140461, 42.791352],
              [-9.140485, 42.791477],
              [-9.140245, 42.791517],
              [-9.140275, 42.791326],
            ],
          ],
          [
            [
              [-9.148532, 42.880618],
              [-9.148674, 42.880691],
              [-9.148423, 42.880876],
              [-9.148387, 42.880752],
              [-9.148532, 42.880618],
            ],
          ],
          [
            [
              [-9.137425, 43.19909],
              [-9.137767, 43.199063],
              [-9.137849, 43.199148],
              [-9.137583, 43.199189],
              [-9.137425, 43.19909],
            ],
          ],
          [
            [
              [-8.017424, 43.712263],
              [-8.017615, 43.712318],
              [-8.017714, 43.712467],
              [-8.017386, 43.7124],
              [-8.017424, 43.712263],
            ],
          ],
          [
            [
              [-8.075941, 43.673636],
              [-8.076239, 43.673641],
              [-8.076321, 43.673736],
              [-8.076051, 43.673766],
              [-8.075941, 43.673636],
            ],
          ],
          [
            [
              [-8.327371, 43.403707],
              [-8.327631, 43.403713],
              [-8.327664, 43.40381],
              [-8.3274, 43.403894],
              [-8.327371, 43.403707],
            ],
          ],
          [
            [
              [-9.057462, 42.693026],
              [-9.057585, 42.693164],
              [-9.057299, 42.693233],
              [-9.057267, 42.693154],
              [-9.057462, 42.693026],
            ],
          ],
          [
            [
              [-9.28466, 42.952833],
              [-9.284822, 42.952869],
              [-9.284794, 42.953068],
              [-9.28463, 42.953023],
              [-9.28466, 42.952833],
            ],
          ],
          [
            [
              [-9.135072, 42.79013],
              [-9.135249, 42.790066],
              [-9.135372, 42.790204],
              [-9.135147, 42.790279],
              [-9.135072, 42.79013],
            ],
          ],
          [
            [
              [-9.283285, 42.95533],
              [-9.283408, 42.955341],
              [-9.283516, 42.955434],
              [-9.283111, 42.95543],
              [-9.283285, 42.95533],
            ],
          ],
          [
            [
              [-9.146322, 42.86114],
              [-9.14649, 42.861221],
              [-9.146375, 42.861426],
              [-9.146266, 42.861306],
              [-9.146322, 42.86114],
            ],
          ],
          [
            [
              [-9.187495, 43.184095],
              [-9.187634, 43.184133],
              [-9.187862, 43.184435],
              [-9.187712, 43.184417],
              [-9.187495, 43.184095],
            ],
          ],
          [
            [
              [-8.99928, 42.778988],
              [-8.999395, 42.77891],
              [-8.999614, 42.779033],
              [-8.999452, 42.779123],
              [-8.99928, 42.778988],
            ],
          ],
          [
            [
              [-9.020697, 42.475368],
              [-9.02106, 42.475349],
              [-9.021127, 42.475408],
              [-9.020855, 42.475485],
              [-9.020697, 42.475368],
            ],
          ],
          [
            [
              [-8.966531, 43.22966],
              [-8.966696, 43.229706],
              [-8.966393, 43.229884],
              [-8.966327, 43.229716],
              [-8.966531, 43.22966],
            ],
          ],
          [
            [
              [-8.998118, 42.48743],
              [-8.998279, 42.487466],
              [-8.998274, 42.487665],
              [-8.998078, 42.487648],
              [-8.998118, 42.48743],
            ],
          ],
          [
            [
              [-9.198392, 43.161782],
              [-9.198531, 43.161703],
              [-9.198663, 43.161912],
              [-9.198535, 43.161982],
              [-9.198392, 43.161782],
            ],
          ],
          [
            [
              [-9.147402, 42.883523],
              [-9.147495, 42.88359],
              [-9.147198, 42.883813],
              [-9.147211, 42.883695],
              [-9.147402, 42.883523],
            ],
          ],
          [
            [
              [-7.870542, 43.781252],
              [-7.870717, 43.781263],
              [-7.870774, 43.781359],
              [-7.870533, 43.781451],
              [-7.870542, 43.781252],
            ],
          ],
          [
            [
              [-9.142903, 42.857769],
              [-9.143114, 42.857794],
              [-9.143115, 42.857929],
              [-9.142844, 42.857907],
              [-9.142903, 42.857769],
            ],
          ],
          [
            [
              [-9.006145, 42.494398],
              [-9.006275, 42.494355],
              [-9.006448, 42.494517],
              [-9.006248, 42.494582],
              [-9.006145, 42.494398],
            ],
          ],
          [
            [
              [-8.985655, 42.555299],
              [-8.985802, 42.555309],
              [-8.985402, 42.555456],
              [-8.985432, 42.555383],
              [-8.985655, 42.555299],
            ],
          ],
          [
            [
              [-9.052762, 42.524865],
              [-9.052907, 42.524848],
              [-9.053029, 42.524985],
              [-9.052793, 42.525061],
              [-9.052762, 42.524865],
            ],
          ],
          [
            [
              [-9.093314, 43.189602],
              [-9.093264, 43.189838],
              [-9.093064, 43.189804],
              [-9.093102, 43.189694],
              [-9.093314, 43.189602],
            ],
          ],
          [
            [
              [-9.013252, 42.498228],
              [-9.013485, 42.498252],
              [-9.01348, 42.498324],
              [-9.013261, 42.498453],
              [-9.013252, 42.498228],
            ],
          ],
          [
            [
              [-7.763128, 43.741478],
              [-7.76325, 43.741292],
              [-7.763341, 43.741658],
              [-7.763134, 43.741549],
              [-7.763128, 43.741478],
            ],
          ],
          [
            [
              [-9.00169, 42.467114],
              [-9.00192, 42.467102],
              [-9.001951, 42.467173],
              [-9.001757, 42.4673],
              [-9.00169, 42.467114],
            ],
          ],
          [
            [
              [-9.092081, 42.576855],
              [-9.092272, 42.576818],
              [-9.092363, 42.577002],
              [-9.092178, 42.576985],
              [-9.092081, 42.576855],
            ],
          ],
          [
            [
              [-8.998624, 42.487637],
              [-8.998719, 42.487614],
              [-8.998787, 42.487818],
              [-8.998558, 42.487839],
              [-8.998624, 42.487637],
            ],
          ],
          [
            [
              [-9.142808, 42.788408],
              [-9.142992, 42.788416],
              [-9.143076, 42.78852],
              [-9.142849, 42.788577],
              [-9.142808, 42.788408],
            ],
          ],
          [
            [
              [-8.803987, 42.636892],
              [-8.804116, 42.63684],
              [-8.804229, 42.637005],
              [-8.803941, 42.637056],
              [-8.803987, 42.636892],
            ],
          ],
          [
            [
              [-9.147899, 42.88274],
              [-9.148039, 42.882787],
              [-9.147922, 42.88309],
              [-9.147854, 42.882896],
              [-9.147899, 42.88274],
            ],
          ],
          [
            [
              [-8.930888, 42.801476],
              [-8.931143, 42.801454],
              [-8.931172, 42.801632],
              [-8.931013, 42.801631],
              [-8.930888, 42.801476],
            ],
          ],
          [
            [
              [-7.923437, 43.74452],
              [-7.923589, 43.744558],
              [-7.923575, 43.744685],
              [-7.923339, 43.744686],
              [-7.923437, 43.74452],
            ],
          ],
          [
            [
              [-8.219673, 43.585428],
              [-8.219824, 43.585457],
              [-8.219707, 43.58567],
              [-8.219592, 43.585639],
              [-8.219673, 43.585428],
            ],
          ],
          [
            [
              [-9.08046, 42.592201],
              [-9.080703, 42.592197],
              [-9.080749, 42.592293],
              [-9.080635, 42.592372],
              [-9.08046, 42.592201],
            ],
          ],
          [
            [
              [-9.142298, 42.78795],
              [-9.142478, 42.787913],
              [-9.142597, 42.788006],
              [-9.142323, 42.788074],
              [-9.142298, 42.78795],
            ],
          ],
          [
            [
              [-9.129495, 42.842378],
              [-9.129693, 42.842403],
              [-9.129716, 42.84251],
              [-9.129552, 42.842582],
              [-9.129495, 42.842378],
            ],
          ],
          [
            [
              [-9.008236, 42.497295],
              [-9.008343, 42.497523],
              [-9.00815, 42.497542],
              [-9.008106, 42.497464],
              [-9.008236, 42.497295],
            ],
          ],
          [
            [
              [-9.147784, 42.737228],
              [-9.147941, 42.73721],
              [-9.148136, 42.737335],
              [-9.147937, 42.737418],
              [-9.147784, 42.737228],
            ],
          ],
          [
            [
              [-9.282126, 43.026136],
              [-9.282314, 43.026171],
              [-9.282409, 43.026381],
              [-9.282103, 43.026263],
              [-9.282126, 43.026136],
            ],
          ],
          [
            [
              [-8.015436, 43.712234],
              [-8.015187, 43.712227],
              [-8.015144, 43.712157],
              [-8.015512, 43.712113],
              [-8.015436, 43.712234],
            ],
          ],
          [
            [
              [-9.148088, 42.86695],
              [-9.148257, 42.867049],
              [-9.148151, 42.867217],
              [-9.148046, 42.867142],
              [-9.148088, 42.86695],
            ],
          ],
          [
            [
              [-9.092479, 42.573701],
              [-9.092651, 42.573719],
              [-9.092679, 42.573879],
              [-9.092521, 42.573888],
              [-9.092479, 42.573701],
            ],
          ],
          [
            [
              [-9.146859, 42.877458],
              [-9.147005, 42.877576],
              [-9.146895, 42.877699],
              [-9.146766, 42.877634],
              [-9.146859, 42.877458],
            ],
          ],
          [
            [
              [-9.092818, 42.558479],
              [-9.092972, 42.558435],
              [-9.093004, 42.558631],
              [-9.092769, 42.558599],
              [-9.092818, 42.558479],
            ],
          ],
          [
            [
              [-9.022898, 42.481762],
              [-9.023026, 42.481761],
              [-9.023181, 42.481871],
              [-9.02293, 42.48192],
              [-9.022898, 42.481762],
            ],
          ],
          [
            [
              [-9.145389, 42.815859],
              [-9.145551, 42.815769],
              [-9.145597, 42.815992],
              [-9.145467, 42.816035],
              [-9.145389, 42.815859],
            ],
          ],
          [
            [
              [-9.148555, 42.81008],
              [-9.148748, 42.810052],
              [-9.148818, 42.810138],
              [-9.148495, 42.81021],
              [-9.148555, 42.81008],
            ],
          ],
          [
            [
              [-9.080139, 42.566185],
              [-9.080332, 42.566166],
              [-9.080337, 42.566337],
              [-9.080131, 42.566348],
              [-9.080139, 42.566185],
            ],
          ],
          [
            [
              [-9.142882, 42.855466],
              [-9.143022, 42.855521],
              [-9.14307, 42.855627],
              [-9.1428, 42.855623],
              [-9.142882, 42.855466],
            ],
          ],
          [
            [
              [-8.195261, 43.609104],
              [-8.19544, 43.609159],
              [-8.195346, 43.60937],
              [-8.195278, 43.609292],
              [-8.195261, 43.609104],
            ],
          ],
          [
            [
              [-8.778591, 43.302563],
              [-8.778738, 43.302556],
              [-8.778776, 43.302698],
              [-8.77857, 43.302736],
              [-8.778591, 43.302563],
            ],
          ],
          [
            [
              [-9.048183, 42.566794],
              [-9.048411, 42.566754],
              [-9.048423, 42.56688],
              [-9.048197, 42.566937],
              [-9.048183, 42.566794],
            ],
          ],
          [
            [
              [-8.835471, 43.352462],
              [-8.835658, 43.35248],
              [-8.835692, 43.352577],
              [-8.835383, 43.352575],
              [-8.835471, 43.352462],
            ],
          ],
          [
            [
              [-9.020459, 42.479584],
              [-9.020606, 42.479603],
              [-9.020654, 42.479718],
              [-9.020376, 42.479732],
              [-9.020459, 42.479584],
            ],
          ],
          [
            [
              [-9.152012, 42.805006],
              [-9.152197, 42.805023],
              [-9.152166, 42.805205],
              [-9.152018, 42.805195],
              [-9.152012, 42.805006],
            ],
          ],
          [
            [
              [-7.850377, 43.725729],
              [-7.85057, 43.725811],
              [-7.850359, 43.725964],
              [-7.850253, 43.725887],
              [-7.850377, 43.725729],
            ],
          ],
          [
            [
              [-9.180484, 43.177779],
              [-9.18074, 43.177882],
              [-9.180751, 43.177989],
              [-9.180616, 43.177996],
              [-9.180484, 43.177779],
            ],
          ],
          [
            [
              [-8.21676, 43.591836],
              [-8.21692, 43.59182],
              [-8.217092, 43.591947],
              [-8.217, 43.592032],
              [-8.21676, 43.591836],
            ],
          ],
          [
            [
              [-8.957424, 42.574347],
              [-8.957567, 42.574439],
              [-8.957337, 42.574577],
              [-8.957333, 42.574415],
              [-8.957424, 42.574347],
            ],
          ],
          [
            [
              [-9.018706, 42.484753],
              [-9.018875, 42.484735],
              [-9.01888, 42.484915],
              [-9.01875, 42.484958],
              [-9.018706, 42.484753],
            ],
          ],
          [
            [
              [-8.603602, 43.320742],
              [-8.603724, 43.320862],
              [-8.603508, 43.320936],
              [-8.603463, 43.320848],
              [-8.603602, 43.320742],
            ],
          ],
          [
            [
              [-8.092586, 43.666763],
              [-8.092694, 43.666722],
              [-8.092869, 43.666885],
              [-8.092635, 43.666905],
              [-8.092586, 43.666763],
            ],
          ],
          [
            [
              [-9.018963, 42.459607],
              [-9.019188, 42.459676],
              [-9.019127, 42.459797],
              [-9.018915, 42.459745],
              [-9.018963, 42.459607],
            ],
          ],
          [
            [
              [-7.750883, 43.746778],
              [-7.750826, 43.746996],
              [-7.750592, 43.747024],
              [-7.750609, 43.746933],
              [-7.750883, 43.746778],
            ],
          ],
          [
            [
              [-9.081541, 42.579433],
              [-9.081824, 42.579463],
              [-9.081847, 42.57957],
              [-9.0815, 42.579516],
              [-9.081541, 42.579433],
            ],
          ],
          [
            [
              [-7.855536, 43.727499],
              [-7.855698, 43.72751],
              [-7.855788, 43.727704],
              [-7.855664, 43.727709],
              [-7.855536, 43.727499],
            ],
          ],
          [
            [
              [-9.047848, 42.509426],
              [-9.047975, 42.509609],
              [-9.047838, 42.509697],
              [-9.047761, 42.50953],
              [-9.047848, 42.509426],
            ],
          ],
          [
            [
              [-8.092668, 43.667435],
              [-8.092868, 43.667462],
              [-8.09296, 43.667665],
              [-8.092678, 43.66756],
              [-8.092668, 43.667435],
            ],
          ],
          [
            [
              [-8.998957, 42.526345],
              [-8.999066, 42.526339],
              [-8.999075, 42.526564],
              [-8.998848, 42.526477],
              [-8.998957, 42.526345],
            ],
          ],
          [
            [
              [-9.087753, 43.188242],
              [-9.087915, 43.188261],
              [-9.087882, 43.188425],
              [-9.087682, 43.18839],
              [-9.087753, 43.188242],
            ],
          ],
          [
            [
              [-8.192057, 43.606135],
              [-8.192077, 43.606368],
              [-8.191947, 43.606446],
              [-8.191917, 43.606384],
              [-8.192057, 43.606135],
            ],
          ],
          [
            [
              [-9.146958, 42.863222],
              [-9.147003, 42.863426],
              [-9.14685, 42.863488],
              [-9.146819, 42.863418],
              [-9.146958, 42.863222],
            ],
          ],
          [
            [
              [-8.833779, 43.352828],
              [-8.833916, 43.352839],
              [-8.834013, 43.352951],
              [-8.833784, 43.353008],
              [-8.833779, 43.352828],
            ],
          ],
          [
            [
              [-9.050902, 42.507923],
              [-9.051073, 42.507932],
              [-9.051095, 42.50803],
              [-9.05087, 42.508096],
              [-9.050902, 42.507923],
            ],
          ],
          [
            [
              [-9.084487, 43.186328],
              [-9.084695, 43.186317],
              [-9.084571, 43.186558],
              [-9.084443, 43.186376],
              [-9.084487, 43.186328],
            ],
          ],
          [
            [
              [-9.143013, 42.793429],
              [-9.143181, 42.793393],
              [-9.143153, 42.793602],
              [-9.142989, 42.793556],
              [-9.143013, 42.793429],
            ],
          ],
          [
            [
              [-8.015808, 43.712388],
              [-8.01597, 43.71239],
              [-8.015959, 43.712553],
              [-8.015735, 43.712545],
              [-8.015808, 43.712388],
            ],
          ],
          [
            [
              [-8.196299, 43.603331],
              [-8.196475, 43.60335],
              [-8.196597, 43.603479],
              [-8.196323, 43.603456],
              [-8.196299, 43.603331],
            ],
          ],
          [
            [
              [-8.030058, 43.713444],
              [-8.030212, 43.7135],
              [-8.0301, 43.713649],
              [-8.029968, 43.713547],
              [-8.030058, 43.713444],
            ],
          ],
          [
            [
              [-8.311705, 43.544994],
              [-8.311823, 43.545061],
              [-8.311509, 43.545156],
              [-8.311504, 43.545102],
              [-8.311705, 43.544994],
            ],
          ],
          [
            [
              [-9.090136, 42.572782],
              [-9.090296, 42.5728],
              [-9.090332, 42.572924],
              [-9.090129, 42.572962],
              [-9.090136, 42.572782],
            ],
          ],
          [
            [
              [-9.086881, 42.737801],
              [-9.087092, 42.737835],
              [-9.087079, 42.737953],
              [-9.086893, 42.737927],
              [-9.086881, 42.737801],
            ],
          ],
          [
            [
              [-9.057354, 42.692798],
              [-9.057456, 42.692712],
              [-9.057609, 42.692785],
              [-9.057365, 42.692906],
              [-9.057354, 42.692798],
            ],
          ],
          [
            [
              [-8.997123, 42.493927],
              [-8.99723, 42.493904],
              [-8.997417, 42.493948],
              [-8.997172, 42.49406],
              [-8.997123, 42.493927],
            ],
          ],
          [
            [
              [-8.008665, 43.7074],
              [-8.008886, 43.707372],
              [-8.008894, 43.707462],
              [-8.008701, 43.707533],
              [-8.008665, 43.7074],
            ],
          ],
          [
            [
              [-8.971938, 42.566928],
              [-8.972044, 42.567012],
              [-8.972015, 42.567221],
              [-8.971872, 42.567003],
              [-8.971938, 42.566928],
            ],
          ],
          [
            [
              [-9.044151, 42.517741],
              [-9.044311, 42.517769],
              [-9.044124, 42.517968],
              [-9.044068, 42.51789],
              [-9.044151, 42.517741],
            ],
          ],
          [
            [
              [-9.089212, 43.190037],
              [-9.089363, 43.190074],
              [-9.089439, 43.190214],
              [-9.089147, 43.19013],
              [-9.089212, 43.190037],
            ],
          ],
          [
            [
              [-9.014526, 42.489213],
              [-9.014709, 42.489222],
              [-9.014779, 42.489317],
              [-9.014578, 42.489373],
              [-9.014526, 42.489213],
            ],
          ],
          [
            [
              [-9.098622, 43.192243],
              [-9.098775, 43.192415],
              [-9.098573, 43.192489],
              [-9.098566, 43.192417],
              [-9.098622, 43.192243],
            ],
          ],
          [
            [
              [-8.966811, 43.22862],
              [-8.966966, 43.228692],
              [-8.96682, 43.228835],
              [-8.966715, 43.228769],
              [-8.966811, 43.22862],
            ],
          ],
          [
            [
              [-9.02424, 42.709062],
              [-9.024361, 42.709055],
              [-9.02449, 42.709129],
              [-9.024268, 42.709222],
              [-9.02424, 42.709062],
            ],
          ],
          [
            [
              [-9.018617, 42.492922],
              [-9.018804, 42.49285],
              [-9.018764, 42.493068],
              [-9.018628, 42.493039],
              [-9.018617, 42.492922],
            ],
          ],
          [
            [
              [-9.079935, 42.592067],
              [-9.080161, 42.59201],
              [-9.080182, 42.592099],
              [-9.08002, 42.592188],
              [-9.079935, 42.592067],
            ],
          ],
          [
            [
              [-8.60468, 43.322003],
              [-8.604816, 43.322015],
              [-8.604758, 43.322189],
              [-8.604604, 43.322124],
              [-8.60468, 43.322003],
            ],
          ],
          [
            [
              [-9.043971, 42.511225],
              [-9.044123, 42.511289],
              [-9.044129, 42.511477],
              [-9.043984, 42.511359],
              [-9.043971, 42.511225],
            ],
          ],
          [
            [
              [-8.9702, 42.566199],
              [-8.970306, 42.566158],
              [-8.970406, 42.566315],
              [-8.970191, 42.566362],
              [-8.9702, 42.566199],
            ],
          ],
          [
            [
              [-9.082041, 42.578812],
              [-9.082215, 42.578848],
              [-9.082253, 42.57899],
              [-9.082054, 42.578947],
              [-9.082041, 42.578812],
            ],
          ],
          [
            [
              [-9.146483, 42.736551],
              [-9.146669, 42.736577],
              [-9.146589, 42.736752],
              [-9.146476, 42.736722],
              [-9.146483, 42.736551],
            ],
          ],
          [
            [
              [-9.035496, 42.508135],
              [-9.035594, 42.508139],
              [-9.035806, 42.508326],
              [-9.035623, 42.508318],
              [-9.035496, 42.508135],
            ],
          ],
          [
            [
              [-8.6041, 43.322401],
              [-8.604199, 43.322405],
              [-8.604275, 43.322564],
              [-8.604079, 43.322573],
              [-8.6041, 43.322401],
            ],
          ],
          [
            [
              [-9.035179, 42.519089],
              [-9.03526, 42.519049],
              [-9.035435, 42.51922],
              [-9.035279, 42.519246],
              [-9.035179, 42.519089],
            ],
          ],
          [
            [
              [-7.73642, 43.747154],
              [-7.736422, 43.746704],
              [-7.736526, 43.747078],
              [-7.73642, 43.747154],
            ],
          ],
          [
            [
              [-9.138666, 42.849239],
              [-9.138752, 42.849243],
              [-9.138875, 42.849371],
              [-9.138723, 42.849443],
              [-9.138666, 42.849239],
            ],
          ],
          [
            [
              [-9.005234, 42.510496],
              [-9.005377, 42.510462],
              [-9.00549, 42.510627],
              [-9.005333, 42.510644],
              [-9.005234, 42.510496],
            ],
          ],
          [
            [
              [-9.000016, 42.467527],
              [-9.00007, 42.467461],
              [-9.000321, 42.467538],
              [-9.000171, 42.467626],
              [-9.000016, 42.467527],
            ],
          ],
          [
            [
              [-9.080342, 42.566517],
              [-9.080549, 42.566515],
              [-9.080569, 42.566595],
              [-9.080367, 42.56665],
              [-9.080342, 42.566517],
            ],
          ],
          [
            [
              [-7.817658, 43.723154],
              [-7.817585, 43.723022],
              [-7.817862, 43.723227],
              [-7.817662, 43.723199],
              [-7.817658, 43.723154],
            ],
          ],
          [
            [
              [-8.092927, 43.667423],
              [-8.0931, 43.667415],
              [-8.093192, 43.667618],
              [-8.092989, 43.667564],
              [-8.092927, 43.667423],
            ],
          ],
          [
            [
              [-8.850426, 43.325365],
              [-8.8506, 43.325374],
              [-8.850572, 43.325601],
              [-8.850483, 43.325569],
              [-8.850426, 43.325365],
            ],
          ],
          [
            [
              [-9.022452, 42.481199],
              [-9.022666, 42.481182],
              [-9.022728, 42.481321],
              [-9.022562, 42.481347],
              [-9.022452, 42.481199],
            ],
          ],
          [
            [
              [-9.141867, 42.884361],
              [-9.142001, 42.884471],
              [-9.141928, 42.884601],
              [-9.141825, 42.884553],
              [-9.141867, 42.884361],
            ],
          ],
          [
            [
              [-9.187549, 43.18366],
              [-9.187802, 43.183728],
              [-9.187779, 43.183864],
              [-9.187665, 43.183834],
              [-9.187549, 43.18366],
            ],
          ],
          [
            [
              [-9.020632, 42.479368],
              [-9.020772, 42.479423],
              [-9.020746, 42.479542],
              [-9.020518, 42.479446],
              [-9.020632, 42.479368],
            ],
          ],
          [
            [
              [-9.14615, 42.738972],
              [-9.146264, 42.73902],
              [-9.146303, 42.739162],
              [-9.146059, 42.739166],
              [-9.14615, 42.738972],
            ],
          ],
          [
            [
              [-9.00181, 42.466712],
              [-9.00204, 42.4667],
              [-9.002047, 42.466781],
              [-9.001836, 42.466855],
              [-9.00181, 42.466712],
            ],
          ],
          [
            [
              [-9.000629, 42.467836],
              [-9.00058, 42.467578],
              [-9.000784, 42.46781],
              [-9.000629, 42.467836],
            ],
          ],
          [
            [
              [-9.016449, 42.464313],
              [-9.016658, 42.464338],
              [-9.016718, 42.46446],
              [-9.016533, 42.464434],
              [-9.016449, 42.464313],
            ],
          ],
          [
            [
              [-8.81903, 42.656947],
              [-8.819193, 42.656867],
              [-8.819234, 42.657045],
              [-8.819025, 42.657029],
              [-8.81903, 42.656947],
            ],
          ],
          [
            [
              [-7.767946, 43.739908],
              [-7.768045, 43.740066],
              [-7.7678, 43.740103],
              [-7.767946, 43.739908],
            ],
          ],
          [
            [
              [-8.306614, 43.543818],
              [-8.306751, 43.54382],
              [-8.306772, 43.543919],
              [-8.306552, 43.543956],
              [-8.306614, 43.543818],
            ],
          ],
          [
            [
              [-9.007947, 42.497832],
              [-9.008051, 42.497782],
              [-9.008148, 42.497903],
              [-9.007962, 42.497993],
              [-9.007947, 42.497832],
            ],
          ],
          [
            [
              [-9.009285, 42.483278],
              [-9.009452, 42.483242],
              [-9.009454, 42.483395],
              [-9.009301, 42.483439],
              [-9.009285, 42.483278],
            ],
          ],
          [
            [
              [-9.044442, 42.524648],
              [-9.044597, 42.524622],
              [-9.044624, 42.524774],
              [-9.044507, 42.524825],
              [-9.044442, 42.524648],
            ],
          ],
          [
            [
              [-8.189505, 43.616919],
              [-8.189621, 43.616968],
              [-8.189539, 43.61717],
              [-8.189456, 43.617075],
              [-8.189505, 43.616919],
            ],
          ],
          [
            [
              [-9.137782, 43.198603],
              [-9.137885, 43.198525],
              [-9.137969, 43.198755],
              [-9.137836, 43.198771],
              [-9.137782, 43.198603],
            ],
          ],
          [
            [
              [-8.917395, 43.293876],
              [-8.91753, 43.293869],
              [-8.91765, 43.293961],
              [-8.917318, 43.29397],
              [-8.917395, 43.293876],
            ],
          ],
          [
            [
              [-9.199032, 43.161423],
              [-9.199166, 43.161416],
              [-9.199247, 43.161484],
              [-9.198898, 43.16144],
              [-9.199032, 43.161423],
            ],
          ],
          [
            [
              [-8.920049, 43.295817],
              [-8.920169, 43.295658],
              [-8.920325, 43.29574],
              [-8.920101, 43.295842],
              [-8.920049, 43.295817],
            ],
          ],
          [
            [
              [-9.197092, 43.160854],
              [-9.197204, 43.160866],
              [-9.197295, 43.161032],
              [-9.197111, 43.161033],
              [-9.197092, 43.160854],
            ],
          ],
          [
            [
              [-9.045796, 42.511731],
              [-9.045878, 42.5117],
              [-9.045996, 42.511793],
              [-9.045837, 42.511909],
              [-9.045796, 42.511731],
            ],
          ],
          [
            [
              [-9.014307, 42.490098],
              [-9.014411, 42.490038],
              [-9.014506, 42.490142],
              [-9.014357, 42.490239],
              [-9.014307, 42.490098],
            ],
          ],
          [
            [
              [-9.048538, 42.507373],
              [-9.048681, 42.507348],
              [-9.048557, 42.50757],
              [-9.04849, 42.507511],
              [-9.048538, 42.507373],
            ],
          ],
          [
            [
              [-9.141657, 42.789118],
              [-9.141778, 42.789112],
              [-9.141836, 42.789325],
              [-9.141656, 42.789236],
              [-9.141657, 42.789118],
            ],
          ],
          [
            [
              [-8.09236, 43.666872],
              [-8.092482, 43.666849],
              [-8.092506, 43.667136],
              [-8.092402, 43.667077],
              [-8.09236, 43.666872],
            ],
          ],
          [
            [
              [-9.025052, 42.707876],
              [-9.025173, 42.707986],
              [-9.025065, 42.708127],
              [-9.024992, 42.708014],
              [-9.025052, 42.707876],
            ],
          ],
          [
            [
              [-8.913839, 43.306474],
              [-8.914051, 43.306499],
              [-8.914104, 43.306541],
              [-8.914026, 43.306626],
              [-8.913839, 43.306474],
            ],
          ],
          [
            [
              [-9.037007, 42.507029],
              [-9.037091, 42.507142],
              [-9.036981, 42.507256],
              [-9.036898, 42.507152],
              [-9.037007, 42.507029],
            ],
          ],
          [
            [
              [-9.145039, 42.811359],
              [-9.145193, 42.811432],
              [-9.145155, 42.811542],
              [-9.144988, 42.811461],
              [-9.145039, 42.811359],
            ],
          ],
          [
            [
              [-9.001655, 42.490197],
              [-9.001771, 42.490145],
              [-9.001844, 42.490268],
              [-9.001643, 42.490332],
              [-9.001655, 42.490197],
            ],
          ],
          [
            [
              [-8.196879, 43.608463],
              [-8.197002, 43.608457],
              [-8.197142, 43.608496],
              [-8.196927, 43.608596],
              [-8.196879, 43.608463],
            ],
          ],
          [
            [
              [-9.111306, 43.193463],
              [-9.111432, 43.193492],
              [-9.111428, 43.1937],
              [-9.111227, 43.193539],
              [-9.111306, 43.193463],
            ],
          ],
          [
            [
              [-9.015107, 43.231518],
              [-9.015113, 43.231329],
              [-9.015427, 43.231636],
              [-9.015253, 43.231618],
              [-9.015107, 43.231518],
            ],
          ],
          [
            [
              [-9.010683, 43.233714],
              [-9.010795, 43.233726],
              [-9.010882, 43.233856],
              [-9.010738, 43.2339],
              [-9.010683, 43.233714],
            ],
          ],
          [
            [
              [-9.083123, 43.186338],
              [-9.08299, 43.186246],
              [-9.083218, 43.18618],
              [-9.083245, 43.186332],
              [-9.083123, 43.186338],
            ],
          ],
          [
            [
              [-9.008754, 42.499905],
              [-9.008849, 42.499882],
              [-9.00897, 42.50007],
              [-9.008796, 42.500058],
              [-9.008754, 42.499905],
            ],
          ],
          [
            [
              [-7.870193, 43.774362],
              [-7.870316, 43.774357],
              [-7.87035, 43.774464],
              [-7.87018, 43.774516],
              [-7.870193, 43.774362],
            ],
          ],
          [
            [
              [-9.048099, 42.511897],
              [-9.048328, 42.511867],
              [-9.048395, 42.511936],
              [-9.048245, 42.512016],
              [-9.048099, 42.511897],
            ],
          ],
          [
            [
              [-9.183619, 42.87702],
              [-9.183812, 42.877117],
              [-9.183747, 42.877202],
              [-9.183619, 42.877146],
              [-9.183619, 42.87702],
            ],
          ],
          [
            [
              [-9.093025, 42.575553],
              [-9.093174, 42.575581],
              [-9.093175, 42.575716],
              [-9.093029, 42.575715],
              [-9.093025, 42.575553],
            ],
          ],
          [
            [
              [-9.017832, 42.492478],
              [-9.017987, 42.492451],
              [-9.018004, 42.492622],
              [-9.017842, 42.492585],
              [-9.017832, 42.492478],
            ],
          ],
          [
            [
              [-9.009463, 42.505512],
              [-9.009622, 42.505512],
              [-9.00954, 42.505679],
              [-9.009404, 42.505659],
              [-9.009463, 42.505512],
            ],
          ],
          [
            [
              [-9.168792, 42.800301],
              [-9.168628, 42.800256],
              [-9.168702, 42.800135],
              [-9.168863, 42.800163],
              [-9.168792, 42.800301],
            ],
          ],
          [
            [
              [-9.030218, 43.216389],
              [-9.03039, 43.216389],
              [-9.03031, 43.216573],
              [-9.030195, 43.216534],
              [-9.030218, 43.216389],
            ],
          ],
          [
            [
              [-9.09179, 42.577122],
              [-9.091944, 42.577078],
              [-9.091969, 42.577212],
              [-9.091878, 42.57728],
              [-9.09179, 42.577122],
            ],
          ],
          [
            [
              [-8.849415, 43.328568],
              [-8.849546, 43.328651],
              [-8.849363, 43.328795],
              [-8.849354, 43.328697],
              [-8.849415, 43.328568],
            ],
          ],
          [
            [
              [-9.14876, 42.797143],
              [-9.148865, 42.797084],
              [-9.148965, 42.797231],
              [-9.148784, 42.797259],
              [-9.14876, 42.797143],
            ],
          ],
          [
            [
              [-9.0356, 42.508832],
              [-9.035762, 42.508877],
              [-9.035785, 42.508984],
              [-9.035576, 42.508959],
              [-9.0356, 42.508832],
            ],
          ],
          [
            [
              [-9.037198, 42.506614],
              [-9.037353, 42.506705],
              [-9.037342, 42.506841],
              [-9.037185, 42.506723],
              [-9.037198, 42.506614],
            ],
          ],
          [
            [
              [-7.816976, 43.723777],
              [-7.816689, 43.723762],
              [-7.816695, 43.723681],
              [-7.816853, 43.723647],
              [-7.816976, 43.723777],
            ],
          ],
          [
            [
              [-9.162338, 42.912323],
              [-9.162447, 42.912317],
              [-9.162434, 42.912552],
              [-9.162343, 42.912503],
              [-9.162338, 42.912323],
            ],
          ],
          [
            [
              [-7.768976, 43.740684],
              [-7.76913, 43.74075],
              [-7.768905, 43.740885],
              [-7.768976, 43.740684],
            ],
          ],
          [
            [
              [-9.017387, 42.491772],
              [-9.017505, 42.491739],
              [-9.017536, 42.491935],
              [-9.01741, 42.491888],
              [-9.017387, 42.491772],
            ],
          ],
          [
            [
              [-8.965508, 42.567687],
              [-8.965595, 42.567709],
              [-8.965566, 42.567918],
              [-8.965444, 42.567789],
              [-8.965508, 42.567687],
            ],
          ],
          [
            [
              [-9.001595, 42.466498],
              [-9.001718, 42.466519],
              [-9.001569, 42.466734],
              [-9.001522, 42.466628],
              [-9.001595, 42.466498],
            ],
          ],
          [
            [
              [-9.044243, 42.531149],
              [-9.044366, 42.53116],
              [-9.044428, 42.531301],
              [-9.044271, 42.531309],
              [-9.044243, 42.531149],
            ],
          ],
          [
            [
              [-9.143072, 42.79303],
              [-9.143233, 42.793057],
              [-9.143225, 42.793103],
              [-9.143014, 42.793186],
              [-9.143072, 42.79303],
            ],
          ],
          [
            [
              [-9.151749, 42.792283],
              [-9.151894, 42.792275],
              [-9.151977, 42.79237],
              [-9.151849, 42.792431],
              [-9.151749, 42.792283],
            ],
          ],
          [
            [
              [-7.753368, 43.745081],
              [-7.753397, 43.744971],
              [-7.753616, 43.74507],
              [-7.753348, 43.745136],
              [-7.753368, 43.745081],
            ],
          ],
          [
            [
              [-8.833137, 43.345371],
              [-8.833299, 43.34539],
              [-8.83332, 43.345488],
              [-8.83311, 43.345481],
              [-8.833137, 43.345371],
            ],
          ],
          [
            [
              [-8.220059, 43.58659],
              [-8.22012, 43.586722],
              [-8.219966, 43.586801],
              [-8.219936, 43.586748],
              [-8.220059, 43.58659],
            ],
          ],
          [
            [
              [-9.044762, 42.524811],
              [-9.044925, 42.524866],
              [-9.044754, 42.524983],
              [-9.044675, 42.524924],
              [-9.044762, 42.524811],
            ],
          ],
          [
            [
              [-9.024941, 42.481679],
              [-9.025107, 42.481634],
              [-9.025175, 42.481712],
              [-9.024951, 42.481786],
              [-9.024941, 42.481679],
            ],
          ],
          [
            [
              [-8.983858, 43.282988],
              [-8.983986, 43.283035],
              [-8.983723, 43.283112],
              [-8.983737, 43.283012],
              [-8.983858, 43.282988],
            ],
          ],
          [
            [
              [-9.180307, 43.176402],
              [-9.180416, 43.176378],
              [-9.180586, 43.176486],
              [-9.180532, 43.176552],
              [-9.180307, 43.176402],
            ],
          ],
          [
            [
              [-9.041501, 42.514875],
              [-9.041593, 42.514825],
              [-9.041722, 42.514899],
              [-9.041647, 42.515002],
              [-9.041501, 42.514875],
            ],
          ],
          [
            [
              [-9.009045, 42.498755],
              [-9.009227, 42.498755],
              [-9.009248, 42.498844],
              [-9.009067, 42.498862],
              [-9.009045, 42.498755],
            ],
          ],
          [
            [
              [-9.151978, 42.804549],
              [-9.15203, 42.804456],
              [-9.152201, 42.804573],
              [-9.152071, 42.804616],
              [-9.151978, 42.804549],
            ],
          ],
          [
            [
              [-9.150507, 42.807086],
              [-9.150679, 42.807094],
              [-9.150676, 42.807185],
              [-9.150571, 42.807235],
              [-9.150507, 42.807086],
            ],
          ],
          [
            [
              [-8.959021, 42.569511],
              [-8.959121, 42.569542],
              [-8.958929, 42.569696],
              [-8.95891, 42.569625],
              [-8.959021, 42.569511],
            ],
          ],
          [
            [
              [-9.14499, 42.814674],
              [-9.145209, 42.814672],
              [-9.145231, 42.814769],
              [-9.145098, 42.814777],
              [-9.14499, 42.814674],
            ],
          ],
          [
            [
              [-9.184759, 42.877282],
              [-9.184882, 42.877293],
              [-9.184952, 42.877379],
              [-9.184787, 42.877442],
              [-9.184759, 42.877282],
            ],
          ],
          [
            [
              [-9.007596, 42.498103],
              [-9.007728, 42.498087],
              [-9.007756, 42.498256],
              [-9.007648, 42.498271],
              [-9.007596, 42.498103],
            ],
          ],
          [
            [
              [-8.965717, 42.567073],
              [-8.965795, 42.566997],
              [-8.965886, 42.567055],
              [-8.965751, 42.567179],
              [-8.965717, 42.567073],
            ],
          ],
          [
            [
              [-9.090049, 43.19091],
              [-9.089894, 43.190829],
              [-9.089992, 43.190706],
              [-9.090156, 43.190869],
              [-9.090049, 43.19091],
            ],
          ],
          [
            [
              [-9.150975, 42.801345],
              [-9.151094, 42.801437],
              [-9.151059, 42.801574],
              [-9.150981, 42.801524],
              [-9.150975, 42.801345],
            ],
          ],
          [
            [
              [-9.022653, 42.481538],
              [-9.022823, 42.481564],
              [-9.022456, 42.481572],
              [-9.022501, 42.481546],
              [-9.022653, 42.481538],
            ],
          ],
          [
            [
              [-8.925574, 43.293703],
              [-8.925729, 43.293776],
              [-8.925478, 43.293852],
              [-8.925463, 43.293826],
              [-8.925574, 43.293703],
            ],
          ],
          [
            [
              [-8.196032, 43.608412],
              [-8.19611, 43.608453],
              [-8.196074, 43.608617],
              [-8.195935, 43.608587],
              [-8.196032, 43.608412],
            ],
          ],
          [
            [
              [-9.077348, 42.600018],
              [-9.077515, 42.599982],
              [-9.07756, 42.60007],
              [-9.07747, 42.600137],
              [-9.077348, 42.600018],
            ],
          ],
          [
            [
              [-9.051004, 42.508215],
              [-9.051059, 42.508158],
              [-9.051193, 42.508286],
              [-9.051038, 42.508321],
              [-9.051004, 42.508215],
            ],
          ],
          [
            [
              [-9.145779, 42.820897],
              [-9.145875, 42.820874],
              [-9.145914, 42.821025],
              [-9.145782, 42.821041],
              [-9.145779, 42.820897],
            ],
          ],
          [
            [
              [-8.999403, 42.470907],
              [-8.999584, 42.470889],
              [-8.999594, 42.470987],
              [-8.999402, 42.471015],
              [-8.999403, 42.470907],
            ],
          ],
          [
            [
              [-8.213474, 43.592365],
              [-8.213624, 43.592376],
              [-8.213609, 43.592494],
              [-8.213433, 43.592466],
              [-8.213474, 43.592365],
            ],
          ],
          [
            [
              [-9.08902, 43.187248],
              [-9.089189, 43.187221],
              [-9.089239, 43.187353],
              [-9.089051, 43.187318],
              [-9.08902, 43.187248],
            ],
          ],
          [
            [
              [-9.16824, 42.799188],
              [-9.168406, 42.799134],
              [-9.168442, 42.799249],
              [-9.168299, 42.799284],
              [-9.16824, 42.799188],
            ],
          ],
          [
            [
              [-8.983156, 42.532908],
              [-8.983253, 42.532903],
              [-8.983361, 42.533014],
              [-8.983178, 42.533015],
              [-8.983156, 42.532908],
            ],
          ],
          [
            [
              [-8.013581, 43.710839],
              [-8.013707, 43.710851],
              [-8.013726, 43.710941],
              [-8.013581, 43.710983],
              [-8.013581, 43.710839],
            ],
          ],
          [
            [
              [-9.008036, 42.499898],
              [-9.008136, 42.499929],
              [-9.008151, 42.500088],
              [-9.008028, 42.500067],
              [-9.008036, 42.499898],
            ],
          ],
          [
            [
              [-9.007984, 42.505437],
              [-9.008141, 42.505428],
              [-9.008206, 42.50547],
              [-9.008021, 42.50557],
              [-9.007984, 42.505437],
            ],
          ],
          [
            [
              [-8.918575, 42.591833],
              [-8.918718, 42.591798],
              [-8.918745, 42.591824],
              [-8.918475, 42.591937],
              [-8.918575, 42.591833],
            ],
          ],
          [
            [
              [-9.019808, 42.493292],
              [-9.019887, 42.49336],
              [-9.019714, 42.493459],
              [-9.019689, 42.493325],
              [-9.019808, 42.493292],
            ],
          ],
          [
            [
              [-9.081394, 42.597525],
              [-9.081564, 42.597525],
              [-9.081551, 42.597634],
              [-9.081395, 42.59766],
              [-9.081394, 42.597525],
            ],
          ],
          [
            [
              [-8.842481, 42.627448],
              [-8.842534, 42.627373],
              [-8.842624, 42.627413],
              [-8.842481, 42.627583],
              [-8.842481, 42.627448],
            ],
          ],
          [
            [
              [-8.928804, 43.293463],
              [-8.928954, 43.293482],
              [-8.928952, 43.29359],
              [-8.928791, 43.293581],
              [-8.928804, 43.293463],
            ],
          ],
          [
            [
              [-7.739709, 43.746657],
              [-7.739638, 43.74639],
              [-7.73982, 43.746491],
              [-7.739709, 43.746657],
            ],
          ],
          [
            [
              [-7.796075, 43.734534],
              [-7.796246, 43.734508],
              [-7.796233, 43.734653],
              [-7.796094, 43.734623],
              [-7.796075, 43.734534],
            ],
          ],
          [
            [
              [-9.005801, 42.476755],
              [-9.005902, 42.476795],
              [-9.005868, 42.47695],
              [-9.005766, 42.476901],
              [-9.005801, 42.476755],
            ],
          ],
          [
            [
              [-9.063084, 42.609823],
              [-9.063193, 42.609808],
              [-9.063284, 42.609875],
              [-9.063083, 42.609931],
              [-9.063084, 42.609823],
            ],
          ],
          [
            [
              [-9.028958, 42.702475],
              [-9.029141, 42.702466],
              [-9.029209, 42.702543],
              [-9.028991, 42.702564],
              [-9.028958, 42.702475],
            ],
          ],
          [
            [
              [-9.149644, 42.796438],
              [-9.149724, 42.796389],
              [-9.14989, 42.796452],
              [-9.149663, 42.796509],
              [-9.149644, 42.796438],
            ],
          ],
          [
            [
              [-9.284381, 42.952983],
              [-9.284492, 42.952995],
              [-9.284554, 42.953118],
              [-9.284364, 42.953056],
              [-9.284381, 42.952983],
            ],
          ],
          [
            [
              [-9.272483, 43.009087],
              [-9.272578, 43.009181],
              [-9.272451, 43.009251],
              [-9.272358, 43.009184],
              [-9.272483, 43.009087],
            ],
          ],
          [
            [
              [-9.145934, 42.794163],
              [-9.146116, 42.794144],
              [-9.146168, 42.794177],
              [-9.145894, 42.794255],
              [-9.145934, 42.794163],
            ],
          ],
          [
            [
              [-9.088257, 43.189467],
              [-9.088313, 43.18941],
              [-9.088439, 43.189565],
              [-9.088325, 43.189652],
              [-9.088257, 43.189467],
            ],
          ],
          [
            [
              [-8.964046, 42.568321],
              [-8.964166, 42.568297],
              [-8.964182, 42.568467],
              [-8.964069, 42.568437],
              [-8.964046, 42.568321],
            ],
          ],
          [
            [
              [-9.010211, 42.514168],
              [-9.010304, 42.514253],
              [-9.010267, 42.514372],
              [-9.010178, 42.514332],
              [-9.010211, 42.514168],
            ],
          ],
          [
            [
              [-8.220227, 43.58624],
              [-8.220312, 43.586227],
              [-8.220387, 43.586377],
              [-8.220224, 43.586357],
              [-8.220227, 43.58624],
            ],
          ],
          [
            [
              [-9.141279, 42.78913],
              [-9.141362, 42.789107],
              [-9.141413, 42.789249],
              [-9.141238, 42.789213],
              [-9.141279, 42.78913],
            ],
          ],
          [
            [
              [-9.005962, 42.477044],
              [-9.006131, 42.477026],
              [-9.006131, 42.477152],
              [-9.006062, 42.477192],
              [-9.005962, 42.477044],
            ],
          ],
          [
            [
              [-8.990448, 43.273199],
              [-8.990533, 43.273194],
              [-8.990599, 43.273236],
              [-8.990304, 43.273242],
              [-8.990448, 43.273199],
            ],
          ],
          [
            [
              [-8.84946, 43.328907],
              [-8.84962, 43.328908],
              [-8.849605, 43.328999],
              [-8.849463, 43.329069],
              [-8.84946, 43.328907],
            ],
          ],
          [
            [
              [-9.046717, 42.511692],
              [-9.046804, 42.511714],
              [-9.04689, 42.511844],
              [-9.046688, 42.511774],
              [-9.046717, 42.511692],
            ],
          ],
          [
            [
              [-9.008922, 42.50545],
              [-9.009041, 42.505426],
              [-9.009098, 42.505513],
              [-9.009007, 42.505572],
              [-9.008922, 42.50545],
            ],
          ],
          [
            [
              [-7.773151, 43.73978],
              [-7.773142, 43.739673],
              [-7.773337, 43.739782],
              [-7.773284, 43.739892],
              [-7.773151, 43.73978],
            ],
          ],
          [
            [
              [-9.140739, 42.793417],
              [-9.140845, 42.793501],
              [-9.140776, 42.793667],
              [-9.140749, 42.793641],
              [-9.140739, 42.793417],
            ],
          ],
          [
            [
              [-9.04119, 42.56035],
              [-9.041315, 42.560379],
              [-9.041319, 42.56055],
              [-9.041229, 42.560501],
              [-9.04119, 42.56035],
            ],
          ],
          [
            [
              [-8.915211, 43.306637],
              [-8.915344, 43.306612],
              [-8.915413, 43.306689],
              [-8.915334, 43.306765],
              [-8.915211, 43.306637],
            ],
          ],
          [
            [
              [-7.774115, 43.739731],
              [-7.774176, 43.739557],
              [-7.774268, 43.739626],
              [-7.774266, 43.739761],
              [-7.774115, 43.739731],
            ],
          ],
          [
            [
              [-8.962673, 42.568609],
              [-8.962809, 42.568629],
              [-8.962772, 42.568748],
              [-8.962593, 42.568667],
              [-8.962673, 42.568609],
            ],
          ],
          [
            [
              [-8.842317, 43.345279],
              [-8.842447, 43.345218],
              [-8.842511, 43.345242],
              [-8.842352, 43.345385],
              [-8.842317, 43.345279],
            ],
          ],
          [
            [
              [-9.004844, 42.512011],
              [-9.004904, 42.511999],
              [-9.00508, 42.512061],
              [-9.004879, 42.512117],
              [-9.004844, 42.512011],
            ],
          ],
          [
            [
              [-7.789813, 43.735571],
              [-7.789892, 43.735631],
              [-7.789755, 43.73578],
              [-7.789749, 43.7357],
              [-7.789813, 43.735571],
            ],
          ],
          [
            [
              [-9.002509, 42.511134],
              [-9.002624, 42.511191],
              [-9.002413, 42.511275],
              [-9.002398, 42.511239],
              [-9.002509, 42.511134],
            ],
          ],
          [
            [
              [-7.748517, 43.746858],
              [-7.748653, 43.746852],
              [-7.748674, 43.746951],
              [-7.748503, 43.746994],
              [-7.748517, 43.746858],
            ],
          ],
          [
            [
              [-7.737577, 43.747493],
              [-7.737677, 43.747345],
              [-7.737686, 43.747615],
              [-7.737577, 43.747493],
            ],
          ],
          [
            [
              [-8.132225, 43.637905],
              [-8.132346, 43.637873],
              [-8.132302, 43.638082],
              [-8.132224, 43.638031],
              [-8.132225, 43.637905],
            ],
          ],
          [
            [
              [-9.147149, 42.816475],
              [-9.147182, 42.816438],
              [-9.147369, 42.816472],
              [-9.147316, 42.816547],
              [-9.147149, 42.816475],
            ],
          ],
          [
            [
              [-8.153559, 43.618379],
              [-8.153598, 43.61826],
              [-8.153815, 43.618332],
              [-8.153732, 43.618371],
              [-8.153559, 43.618379],
            ],
          ],
          [
            [
              [-9.077115, 42.60012],
              [-9.077258, 42.600095],
              [-9.077289, 42.600165],
              [-9.077123, 42.60021],
              [-9.077115, 42.60012],
            ],
          ],
          [
            [
              [-9.045944, 42.512255],
              [-9.046036, 42.512205],
              [-9.046102, 42.512255],
              [-9.045953, 42.512353],
              [-9.045944, 42.512255],
            ],
          ],
          [
            [
              [-8.092332, 43.666549],
              [-8.092408, 43.666564],
              [-8.092425, 43.666761],
              [-8.09232, 43.666694],
              [-8.092332, 43.666549],
            ],
          ],
          [
            [
              [-9.152115, 42.792777],
              [-9.152175, 42.792764],
              [-9.152284, 42.792876],
              [-9.152113, 42.792876],
              [-9.152115, 42.792777],
            ],
          ],
          [
            [
              [-8.849135, 43.329887],
              [-8.849246, 43.32989],
              [-8.84909, 43.330061],
              [-8.849044, 43.329964],
              [-8.849135, 43.329887],
            ],
          ],
          [
            [
              [-9.01565, 43.232903],
              [-9.015722, 43.232881],
              [-9.01586, 43.233027],
              [-9.015739, 43.233051],
              [-9.01565, 43.232903],
            ],
          ],
          [
            [
              [-9.012297, 43.23398],
              [-9.012371, 43.233976],
              [-9.01254, 43.234075],
              [-9.012331, 43.234077],
              [-9.012297, 43.23398],
            ],
          ],
          [
            [
              [-9.142766, 42.804235],
              [-9.142895, 42.804174],
              [-9.142952, 42.804261],
              [-9.142886, 42.804328],
              [-9.142766, 42.804235],
            ],
          ],
          [
            [
              [-7.773264, 43.738866],
              [-7.773381, 43.738771],
              [-7.77345, 43.738868],
              [-7.773347, 43.738971],
              [-7.773264, 43.738866],
            ],
          ],
          [
            [
              [-8.25376, 43.563453],
              [-8.253909, 43.563464],
              [-8.253913, 43.563509],
              [-8.253693, 43.563546],
              [-8.25376, 43.563453],
            ],
          ],
          [
            [
              [-9.041248, 42.560698],
              [-9.041366, 42.560655],
              [-9.041439, 42.560777],
              [-9.041306, 42.560793],
              [-9.041248, 42.560698],
            ],
          ],
          [
            [
              [-8.982817, 42.536229],
              [-8.982938, 42.536223],
              [-8.983043, 42.536308],
              [-8.982912, 42.536332],
              [-8.982817, 42.536229],
            ],
          ],
          [
            [
              [-8.966311, 43.229546],
              [-8.966444, 43.229521],
              [-8.96656, 43.229578],
              [-8.966455, 43.229637],
              [-8.966311, 43.229546],
            ],
          ],
          [
            [
              [-9.038923, 42.518045],
              [-9.039018, 42.518013],
              [-9.03915, 42.518123],
              [-9.039041, 42.518129],
              [-9.038923, 42.518045],
            ],
          ],
          [
            [
              [-7.750561, 43.747106],
              [-7.750821, 43.747095],
              [-7.750754, 43.747188],
              [-7.750561, 43.747106],
            ],
          ],
          [
            [
              [-8.75491, 42.647532],
              [-8.754981, 42.64751],
              [-8.755084, 42.647568],
              [-8.754992, 42.647636],
              [-8.75491, 42.647532],
            ],
          ],
          [
            [
              [-9.167846, 42.798786],
              [-9.167963, 42.798735],
              [-9.168001, 42.798868],
              [-9.167863, 42.798839],
              [-9.167846, 42.798786],
            ],
          ],
          [
            [
              [-9.01157, 43.233847],
              [-9.01168, 43.233841],
              [-9.011715, 43.233947],
              [-9.011611, 43.234016],
              [-9.01157, 43.233847],
            ],
          ],
          [
            [
              [-9.146021, 42.736936],
              [-9.14611, 42.73685],
              [-9.146213, 42.736898],
              [-9.146137, 42.736992],
              [-9.146021, 42.736936],
            ],
          ],
          [
            [
              [-9.081751, 42.734177],
              [-9.081616, 42.734175],
              [-9.081599, 42.734122],
              [-9.081763, 42.734051],
              [-9.081751, 42.734177],
            ],
          ],
          [
            [
              [-8.190078, 43.618541],
              [-8.190195, 43.618598],
              [-8.190118, 43.61871],
              [-8.190051, 43.618659],
              [-8.190078, 43.618541],
            ],
          ],
          [
            [
              [-8.984852, 42.537653],
              [-8.984884, 42.537606],
              [-8.985048, 42.53767],
              [-8.984935, 42.537757],
              [-8.984852, 42.537653],
            ],
          ],
          [
            [
              [-9.114146, 43.193689],
              [-9.114231, 43.193684],
              [-9.114243, 43.193801],
              [-9.114097, 43.193818],
              [-9.114146, 43.193689],
            ],
          ],
          [
            [
              [-8.986927, 42.531234],
              [-8.987005, 42.531158],
              [-8.987055, 42.531173],
              [-8.987009, 42.531329],
              [-8.986927, 42.531234],
            ],
          ],
          [
            [
              [-9.046958, 42.51104],
              [-9.046985, 42.510939],
              [-9.047138, 42.511012],
              [-9.047059, 42.511079],
              [-9.046958, 42.51104],
            ],
          ],
          [
            [
              [-9.046646, 42.531958],
              [-9.046779, 42.531951],
              [-9.046811, 42.53203],
              [-9.046631, 42.532049],
              [-9.046646, 42.531958],
            ],
          ],
          [
            [
              [-9.0476, 42.518684],
              [-9.047672, 42.518671],
              [-9.047721, 42.518795],
              [-9.047575, 42.518802],
              [-9.0476, 42.518684],
            ],
          ],
          [
            [
              [-8.855725, 43.321879],
              [-8.855778, 43.321786],
              [-8.855865, 43.3218],
              [-8.855794, 43.321957],
              [-8.855725, 43.321879],
            ],
          ],
          [
            [
              [-9.093551, 43.189508],
              [-9.093686, 43.18951],
              [-9.093706, 43.189581],
              [-9.093499, 43.189601],
              [-9.093551, 43.189508],
            ],
          ],
          [
            [
              [-7.796151, 43.734864],
              [-7.796273, 43.73484],
              [-7.796261, 43.735003],
              [-7.796173, 43.734989],
              [-7.796151, 43.734864],
            ],
          ],
          [
            [
              [-9.03959, 42.517389],
              [-9.039711, 42.517382],
              [-9.039776, 42.517424],
              [-9.039573, 42.517462],
              [-9.03959, 42.517389],
            ],
          ],
          [
            [
              [-8.194075, 43.61302],
              [-8.194166, 43.613061],
              [-8.193964, 43.61316],
              [-8.193994, 43.613087],
              [-8.194075, 43.61302],
            ],
          ],
          [
            [
              [-9.102409, 43.190618],
              [-9.102479, 43.190578],
              [-9.102544, 43.190611],
              [-9.102434, 43.190743],
              [-9.102409, 43.190618],
            ],
          ],
          [
            [
              [-9.146451, 42.791741],
              [-9.146563, 42.791753],
              [-9.146548, 42.791853],
              [-9.146413, 42.791851],
              [-9.146451, 42.791741],
            ],
          ],
          [
            [
              [-9.063708, 42.609718],
              [-9.063854, 42.609719],
              [-9.063876, 42.609817],
              [-9.063741, 42.609806],
              [-9.063708, 42.609718],
            ],
          ],
          [
            [
              [-9.282913, 43.024112],
              [-9.28302, 43.024079],
              [-9.283086, 43.024121],
              [-9.283008, 43.024206],
              [-9.282913, 43.024112],
            ],
          ],
          [
            [
              [-8.603617, 43.321984],
              [-8.603752, 43.321968],
              [-8.603769, 43.322021],
              [-8.6036, 43.322066],
              [-8.603617, 43.321984],
            ],
          ],
          [
            [
              [-9.279612, 43.013078],
              [-9.279706, 43.013037],
              [-9.279817, 43.013157],
              [-9.27977, 43.013178],
              [-9.279612, 43.013078],
            ],
          ],
          [
            [
              [-9.150814, 42.792469],
              [-9.150898, 42.792455],
              [-9.151001, 42.792513],
              [-9.150872, 42.792565],
              [-9.150814, 42.792469],
            ],
          ],
          [
            [
              [-9.280072, 43.015654],
              [-9.28009, 43.01559],
              [-9.280218, 43.015637],
              [-9.28013, 43.015741],
              [-9.280072, 43.015654],
            ],
          ],
          [
            [
              [-9.008468, 42.505159],
              [-9.008574, 42.505117],
              [-9.008639, 42.505159],
              [-9.008477, 42.505249],
              [-9.008468, 42.505159],
            ],
          ],
          [
            [
              [-9.172383, 42.799171],
              [-9.172503, 42.799156],
              [-9.17249, 42.799264],
              [-9.172355, 42.799263],
              [-9.172383, 42.799171],
            ],
          ],
          [
            [
              [-9.282881, 43.022125],
              [-9.282873, 43.022044],
              [-9.283043, 43.022143],
              [-9.282922, 43.022168],
              [-9.282881, 43.022125],
            ],
          ],
          [
            [
              [-9.004466, 42.512256],
              [-9.004573, 42.512223],
              [-9.004673, 42.512254],
              [-9.004583, 42.512331],
              [-9.004466, 42.512256],
            ],
          ],
          [
            [
              [-9.151953, 42.799375],
              [-9.152063, 42.799378],
              [-9.152061, 42.799477],
              [-9.151913, 42.799467],
              [-9.151953, 42.799375],
            ],
          ],
          [
            [
              [-9.039322, 42.506934],
              [-9.039401, 42.506876],
              [-9.039477, 42.506908],
              [-9.0394, 42.506993],
              [-9.039322, 42.506934],
            ],
          ],
          [
            [
              [-9.041508, 42.561116],
              [-9.041569, 42.561113],
              [-9.041594, 42.561246],
              [-9.041456, 42.561209],
              [-9.041508, 42.561116],
            ],
          ],
          [
            [
              [-9.011635, 42.515065],
              [-9.011692, 42.515026],
              [-9.011769, 42.515067],
              [-9.011671, 42.51518],
              [-9.011635, 42.515065],
            ],
          ],
          [
            [
              [-9.041175, 42.561197],
              [-9.041255, 42.561147],
              [-9.041321, 42.561198],
              [-9.041147, 42.561288],
              [-9.041175, 42.561197],
            ],
          ],
          [
            [
              [-8.983298, 42.532486],
              [-8.983379, 42.532446],
              [-8.983485, 42.532531],
              [-8.983378, 42.532563],
              [-8.983298, 42.532486],
            ],
          ],
          [
            [
              [-9.089112, 43.190371],
              [-9.089239, 43.190431],
              [-9.089217, 43.190577],
              [-9.089175, 43.190534],
              [-9.089112, 43.190371],
            ],
          ],
          [
            [
              [-8.962606, 42.568802],
              [-8.96273, 42.568822],
              [-8.962772, 42.568874],
              [-8.962667, 42.568924],
              [-8.962606, 42.568802],
            ],
          ],
          [
            [
              [-9.146181, 42.738178],
              [-9.146242, 42.738175],
              [-9.14631, 42.738252],
              [-9.146193, 42.738304],
              [-9.146181, 42.738178],
            ],
          ],
          [
            [
              [-9.008898, 43.234222],
              [-9.008971, 43.234209],
              [-9.008812, 43.234344],
              [-9.008783, 43.2343],
              [-9.008898, 43.234222],
            ],
          ],
          [
            [
              [-7.768575, 43.740332],
              [-7.768672, 43.740301],
              [-7.768762, 43.740342],
              [-7.768682, 43.740426],
              [-7.768575, 43.740332],
            ],
          ],
          [
            [
              [-9.037255, 42.511481],
              [-9.037376, 42.511475],
              [-9.03744, 42.511507],
              [-9.037185, 42.511512],
              [-9.037255, 42.511481],
            ],
          ],
          [
            [
              [-8.195989, 43.603183],
              [-8.196079, 43.603215],
              [-8.195938, 43.603302],
              [-8.195933, 43.603249],
              [-8.195989, 43.603183],
            ],
          ],
          [
            [
              [-9.147171, 42.815709],
              [-9.147243, 42.815696],
              [-9.147322, 42.815755],
              [-9.147206, 42.815815],
              [-9.147171, 42.815709],
            ],
          ],
          [
            [
              [-9.008249, 43.23413],
              [-9.008308, 43.2341],
              [-9.0084, 43.234158],
              [-9.00822, 43.234204],
              [-9.008249, 43.23413],
            ],
          ],
          [
            [
              [-9.045109, 42.509283],
              [-9.045194, 42.509279],
              [-9.045226, 42.509358],
              [-9.045097, 42.50941],
              [-9.045109, 42.509283],
            ],
          ],
          [
            [
              [-9.039614, 42.515875],
              [-9.039733, 42.515851],
              [-9.03976, 42.515876],
              [-9.039611, 42.515974],
              [-9.039614, 42.515875],
            ],
          ],
          [
            [
              [-9.040944, 42.560822],
              [-9.041049, 42.56078],
              [-9.041112, 42.560804],
              [-9.040965, 42.56092],
              [-9.040944, 42.560822],
            ],
          ],
          [
            [
              [-9.007386, 42.498204],
              [-9.007515, 42.498152],
              [-9.007526, 42.498268],
              [-9.007466, 42.498271],
              [-9.007386, 42.498204],
            ],
          ],
          [
            [
              [-8.96602, 43.229471],
              [-8.966155, 43.229464],
              [-8.966172, 43.229508],
              [-8.966092, 43.229575],
              [-8.96602, 43.229471],
            ],
          ],
          [
            [
              [-8.604092, 43.32151],
              [-8.604207, 43.321559],
              [-8.604175, 43.321614],
              [-8.604076, 43.32161],
              [-8.604092, 43.32151],
            ],
          ],
          [
            [
              [-9.152476, 42.802551],
              [-9.152567, 42.802474],
              [-9.152624, 42.802561],
              [-9.152556, 42.80261],
              [-9.152476, 42.802551],
            ],
          ],
          [
            [
              [-7.797586, 43.733255],
              [-7.79766, 43.733243],
              [-7.797622, 43.733388],
              [-7.797533, 43.733365],
              [-7.797586, 43.733255],
            ],
          ],
          [
            [
              [-8.984301, 42.533424],
              [-8.984385, 42.533411],
              [-8.984465, 42.533488],
              [-8.984333, 42.533503],
              [-8.984301, 42.533424],
            ],
          ],
          [
            [
              [-8.202375, 43.597661],
              [-8.202527, 43.597699],
              [-8.202558, 43.59777],
              [-8.20251, 43.597781],
              [-8.202375, 43.597661],
            ],
          ],
          [
            [
              [-8.958402, 43.228735],
              [-8.958474, 43.228723],
              [-8.958519, 43.228801],
              [-8.9584, 43.228843],
              [-8.958402, 43.228735],
            ],
          ],
          [
            [
              [-8.790372, 43.310428],
              [-8.790471, 43.310432],
              [-8.790443, 43.310532],
              [-8.790369, 43.310527],
              [-8.790372, 43.310428],
            ],
          ],
          [
            [
              [-9.15123, 42.802186],
              [-9.151337, 42.802153],
              [-9.151359, 42.802251],
              [-9.151262, 42.802265],
              [-9.15123, 42.802186],
            ],
          ],
          [
            [
              [-8.310082, 43.54525],
              [-8.310088, 43.545177],
              [-8.310213, 43.545199],
              [-8.310197, 43.545289],
              [-8.310082, 43.54525],
            ],
          ],
          [
            [
              [-9.119801, 43.196221],
              [-9.119898, 43.196207],
              [-9.119932, 43.196304],
              [-9.119872, 43.196316],
              [-9.119801, 43.196221],
            ],
          ],
          [
            [
              [-7.816823, 43.72327],
              [-7.816917, 43.723203],
              [-7.81697, 43.723237],
              [-7.816865, 43.723332],
              [-7.816823, 43.72327],
            ],
          ],
          [
            [
              [-9.044899, 42.510257],
              [-9.044975, 42.510289],
              [-9.044947, 42.510372],
              [-9.044859, 42.51035],
              [-9.044899, 42.510257],
            ],
          ],
          [
            [
              [-9.117712, 43.200051],
              [-9.117773, 43.200047],
              [-9.117826, 43.20009],
              [-9.117607, 43.20011],
              [-9.117712, 43.200051],
            ],
          ],
          [
            [
              [-9.038417, 42.563395],
              [-9.038487, 42.563364],
              [-9.038552, 42.563415],
              [-9.038411, 42.563467],
              [-9.038417, 42.563395],
            ],
          ],
          [
            [
              [-7.752349, 43.746627],
              [-7.75242, 43.746588],
              [-7.752467, 43.746712],
              [-7.752394, 43.746733],
              [-7.752349, 43.746627],
            ],
          ],
          [
            [
              [-7.752419, 43.74783],
              [-7.752463, 43.747918],
              [-7.752371, 43.748003],
              [-7.752342, 43.747959],
              [-7.752419, 43.74783],
            ],
          ],
          [
            [
              [-9.028144, 42.519956],
              [-9.02829, 42.519957],
              [-9.028306, 42.519992],
              [-9.028174, 42.520017],
              [-9.028144, 42.519956],
            ],
          ],
          [
            [
              [-8.953662, 43.256448],
              [-8.953707, 43.256409],
              [-8.953848, 43.256465],
              [-8.953827, 43.256502],
              [-8.953662, 43.256448],
            ],
          ],
          [
            [
              [-9.142653, 42.789065],
              [-9.142732, 42.788998],
              [-9.14279, 42.789084],
              [-9.142708, 42.789125],
              [-9.142653, 42.789065],
            ],
          ],
          [
            [
              [-7.752883, 43.746163],
              [-7.752882, 43.745992],
              [-7.752969, 43.746151],
              [-7.752883, 43.746163],
            ],
          ],
          [
            [
              [-9.044184, 42.534564],
              [-9.04428, 42.534549],
              [-9.044313, 42.534638],
              [-9.044205, 42.534652],
              [-9.044184, 42.534564],
            ],
          ],
          [
            [
              [-9.041346, 42.560827],
              [-9.041443, 42.560822],
              [-9.041439, 42.560904],
              [-9.04134, 42.560891],
              [-9.041346, 42.560827],
            ],
          ],
          [
            [
              [-9.098969, 43.191775],
              [-9.099077, 43.191742],
              [-9.099094, 43.191795],
              [-9.098976, 43.191846],
              [-9.098969, 43.191775],
            ],
          ],
          [
            [
              [-9.152206, 42.802286],
              [-9.152236, 42.802222],
              [-9.152343, 42.802315],
              [-9.152221, 42.802322],
              [-9.152206, 42.802286],
            ],
          ],
          [
            [
              [-9.041905, 42.561194],
              [-9.041839, 42.561134],
              [-9.04192, 42.561094],
              [-9.041975, 42.561163],
              [-9.041905, 42.561194],
            ],
          ],
          [
            [
              [-9.275916, 43.009438],
              [-9.275977, 43.009435],
              [-9.276045, 43.009494],
              [-9.27595, 43.009526],
              [-9.275916, 43.009438],
            ],
          ],
          [
            [
              [-9.080955, 43.184708],
              [-9.081015, 43.184695],
              [-9.081116, 43.184843],
              [-9.081014, 43.184803],
              [-9.080955, 43.184708],
            ],
          ],
          [
            [
              [-8.948208, 42.559822],
              [-8.948235, 42.55973],
              [-8.948323, 42.559753],
              [-8.948317, 42.559816],
              [-8.948208, 42.559822],
            ],
          ],
          [
            [
              [-9.088976, 43.190509],
              [-9.089071, 43.190476],
              [-9.089082, 43.190584],
              [-9.088993, 43.190553],
              [-9.088976, 43.190509],
            ],
          ],
          [
            [
              [-9.076514, 43.186187],
              [-9.076598, 43.186164],
              [-9.076674, 43.186187],
              [-9.076484, 43.18626],
              [-9.076514, 43.186187],
            ],
          ],
          [
            [
              [-9.144828, 42.81489],
              [-9.144887, 42.814869],
              [-9.144935, 42.814983],
              [-9.144846, 42.814952],
              [-9.144828, 42.81489],
            ],
          ],
          [
            [
              [-9.18816, 43.185076],
              [-9.188233, 43.185063],
              [-9.188271, 43.185196],
              [-9.188141, 43.185131],
              [-9.18816, 43.185076],
            ],
          ],
          [
            [
              [-8.842462, 43.34538],
              [-8.842506, 43.345449],
              [-8.842368, 43.34543],
              [-8.842402, 43.345392],
              [-8.842462, 43.34538],
            ],
          ],
          [
            [
              [-7.773434, 43.738661],
              [-7.773532, 43.738639],
              [-7.773575, 43.738718],
              [-7.773478, 43.738749],
              [-7.773434, 43.738661],
            ],
          ],
          [
            [
              [-9.078956, 43.184148],
              [-9.079016, 43.184146],
              [-9.079017, 43.184271],
              [-9.078965, 43.184238],
              [-9.078956, 43.184148],
            ],
          ],
          [
            [
              [-9.145504, 42.794015],
              [-9.145613, 42.794009],
              [-9.145641, 42.794044],
              [-9.145548, 42.794094],
              [-9.145504, 42.794015],
            ],
          ],
          [
            [
              [-8.948794, 42.559845],
              [-8.948737, 42.559749],
              [-8.948872, 42.559769],
              [-8.948794, 42.559845],
            ],
          ],
          [
            [
              [-9.04333, 42.512024],
              [-9.043424, 42.511992],
              [-9.043441, 42.512036],
              [-9.043337, 42.512095],
              [-9.04333, 42.512024],
            ],
          ],
          [
            [
              [-9.153497, 42.801875],
              [-9.153542, 42.801836],
              [-9.153623, 42.801913],
              [-9.153529, 42.801945],
              [-9.153497, 42.801875],
            ],
          ],
          [
            [
              [-8.189287, 43.619999],
              [-8.189359, 43.619969],
              [-8.189393, 43.620075],
              [-8.189356, 43.620077],
              [-8.189287, 43.619999],
            ],
          ],
          [
            [
              [-9.025867, 42.708094],
              [-9.025952, 42.708089],
              [-9.025961, 42.708179],
              [-9.025849, 42.708158],
              [-9.025867, 42.708094],
            ],
          ],
          [
            [
              [-8.967038, 42.567517],
              [-8.967084, 42.567487],
              [-8.967172, 42.567519],
              [-8.967153, 42.567574],
              [-8.967038, 42.567517],
            ],
          ],
          [
            [
              [-9.117944, 43.196852],
              [-9.118039, 43.196811],
              [-9.118113, 43.196816],
              [-9.118094, 43.196871],
              [-9.117944, 43.196852],
            ],
          ],
          [
            [
              [-9.035563, 42.518907],
              [-9.035598, 42.518887],
              [-9.035712, 42.518935],
              [-9.035631, 42.518984],
              [-9.035563, 42.518907],
            ],
          ],
          [
            [
              [-9.281355, 43.024954],
              [-9.281399, 43.024907],
              [-9.281525, 43.024936],
              [-9.281395, 43.024988],
              [-9.281355, 43.024954],
            ],
          ],
          [
            [
              [-7.765379, 43.739952],
              [-7.765436, 43.739887],
              [-7.7655, 43.739911],
              [-7.765446, 43.740012],
              [-7.765379, 43.739952],
            ],
          ],
          [
            [
              [-9.040567, 42.514024],
              [-9.040689, 42.514027],
              [-9.040704, 42.514062],
              [-9.040623, 42.514102],
              [-9.040567, 42.514024],
            ],
          ],
          [
            [
              [-7.77157, 43.741449],
              [-7.771656, 43.741436],
              [-7.771633, 43.741617],
              [-7.77157, 43.741449],
            ],
          ],
          [
            [
              [-9.07556, 43.186742],
              [-9.075616, 43.186685],
              [-9.075673, 43.186763],
              [-9.075629, 43.18681],
              [-9.07556, 43.186742],
            ],
          ],
          [
            [
              [-8.818986, 42.656869],
              [-8.818936, 42.656862],
              [-8.818857, 42.656794],
              [-8.819015, 42.656795],
              [-8.818986, 42.656869],
            ],
          ],
          [
            [
              [-9.044704, 42.532133],
              [-9.04475, 42.532112],
              [-9.044807, 42.532199],
              [-9.044723, 42.532213],
              [-9.044704, 42.532133],
            ],
          ],
          [
            [
              [-9.032213, 42.517608],
              [-9.032334, 42.517601],
              [-9.032361, 42.517627],
              [-9.032256, 42.517677],
              [-9.032213, 42.517608],
            ],
          ],
          [
            [
              [-9.004116, 42.511905],
              [-9.004177, 42.511902],
              [-9.00421, 42.51199],
              [-9.004111, 42.511977],
              [-9.004116, 42.511905],
            ],
          ],
          [
            [
              [-7.772719, 43.739816],
              [-7.772775, 43.739742],
              [-7.772827, 43.739776],
              [-7.77276, 43.739869],
              [-7.772719, 43.739816],
            ],
          ],
          [
            [
              [-8.84933, 43.328842],
              [-8.84939, 43.328821],
              [-8.849443, 43.328854],
              [-8.849376, 43.32893],
              [-8.84933, 43.328842],
            ],
          ],
          [
            [
              [-9.096087, 43.193216],
              [-9.096148, 43.193213],
              [-9.096085, 43.193324],
              [-9.096055, 43.193272],
              [-9.096087, 43.193216],
            ],
          ],
          [
            [
              [-8.917378, 42.620665],
              [-8.917302, 42.620641],
              [-8.917321, 42.620577],
              [-8.917381, 42.620574],
              [-8.917378, 42.620665],
            ],
          ],
          [
            [
              [-9.083785, 43.1857],
              [-9.083833, 43.185688],
              [-9.083867, 43.185785],
              [-9.083753, 43.185746],
              [-9.083785, 43.1857],
            ],
          ],
          [
            [
              [-8.989558, 42.522373],
              [-8.989642, 42.52236],
              [-8.989669, 42.522395],
              [-8.989614, 42.522452],
              [-8.989558, 42.522373],
            ],
          ],
          [
            [
              [-7.773512, 43.739018],
              [-7.773572, 43.738989],
              [-7.773603, 43.739068],
              [-7.773518, 43.73909],
              [-7.773512, 43.739018],
            ],
          ],
          [
            [
              [-9.082373, 43.184992],
              [-9.082434, 43.18498],
              [-9.082445, 43.185096],
              [-9.082408, 43.185089],
              [-9.082373, 43.184992],
            ],
          ],
          [
            [
              [-8.971774, 42.567125],
              [-8.971811, 42.567123],
              [-8.971868, 42.56721],
              [-8.971795, 42.567214],
              [-8.971774, 42.567125],
            ],
          ],
          [
            [
              [-7.771624, 43.740096],
              [-7.771779, 43.740027],
              [-7.771773, 43.740099],
              [-7.771624, 43.740096],
            ],
          ],
          [
            [
              [-8.971998, 42.568185],
              [-8.972018, 42.568139],
              [-8.97212, 42.568187],
              [-8.972074, 42.568217],
              [-8.971998, 42.568185],
            ],
          ],
          [
            [
              [-9.090447, 43.190205],
              [-9.090506, 43.190184],
              [-9.090575, 43.190261],
              [-9.090539, 43.190272],
              [-9.090447, 43.190205],
            ],
          ],
          [
            [
              [-7.753035, 43.745887],
              [-7.753088, 43.745768],
              [-7.75311, 43.745893],
              [-7.753035, 43.745887],
            ],
          ],
          [
            [
              [-9.003628, 43.230089],
              [-9.003676, 43.230068],
              [-9.003751, 43.230082],
              [-9.003742, 43.230119],
              [-9.003628, 43.230089],
            ],
          ],
          [
            [
              [-9.102631, 43.19075],
              [-9.102705, 43.190746],
              [-9.1027, 43.190819],
              [-9.102638, 43.190813],
              [-9.102631, 43.19075],
            ],
          ],
          [
            [
              [-8.917195, 42.620665],
              [-8.917228, 42.620627],
              [-8.917271, 42.620697],
              [-8.917223, 42.6207],
              [-8.917195, 42.620665],
            ],
          ],
          [
            [
              [-9.083528, 43.186083],
              [-9.083589, 43.186079],
              [-9.083618, 43.186123],
              [-9.083546, 43.186145],
              [-9.083528, 43.186083],
            ],
          ],
          [
            [
              [-9.101936, 43.190806],
              [-9.102007, 43.190784],
              [-9.101979, 43.190875],
              [-9.10194, 43.19085],
              [-9.101936, 43.190806],
            ],
          ],
          [
            [
              [-7.764352, 43.740778],
              [-7.764376, 43.740768],
              [-7.764365, 43.740949],
              [-7.764353, 43.740949],
              [-7.764352, 43.740778],
            ],
          ],
          [
            [
              [-9.002825, 43.228682],
              [-9.002899, 43.228687],
              [-9.002916, 43.228731],
              [-9.002815, 43.228709],
              [-9.002825, 43.228682],
            ],
          ],
          [
            [
              [-9.007934, 42.498202],
              [-9.007982, 42.498199],
              [-9.007902, 42.498249],
              [-9.007934, 42.498202],
            ],
          ],
          [
            [
              [-9.082304, 43.185527],
              [-9.082364, 43.185515],
              [-9.082373, 43.185604],
              [-9.082333, 43.18557],
              [-9.082304, 43.185527],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5616",
      properties: {
        name: "Alacant/Alicante",
        density: 0,
        path: "/world/Spain/Alacant/Alicante",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.159684, 38.883508],
              [-0.148077, 38.858431],
              [-0.070616, 38.885721],
              [-0.034678, 38.856893],
              [-0.024796, 38.872552],
              [-0.037515, 38.886932],
              [0.015272, 38.863793],
              [0.1258, 38.848596],
              [0.109504, 38.845527],
              [0.197524, 38.80405],
              [0.182058, 38.793852],
              [0.190243, 38.773467],
              [0.224766, 38.764284],
              [0.221653, 38.758253],
              [0.223996, 38.748964],
              [0.233921, 38.736933],
              [0.182412, 38.720781],
              [0.148607, 38.679257],
              [0.098461, 38.672841],
              [0.073594, 38.644696],
              [0.083863, 38.631029],
              [0.051371, 38.641305],
              [0.030551, 38.625919],
              [-0.015885, 38.626663],
              [-0.06174, 38.582048],
              [-0.049584, 38.562192],
              [-0.097558, 38.523471],
              [-0.151979, 38.535044],
              [-0.375013, 38.443044],
              [-0.404572, 38.401455],
              [-0.40384, 38.352477],
              [-0.444264, 38.36325],
              [-0.510627, 38.325899],
              [-0.513711, 38.197459],
              [-0.595266, 38.187877],
              [-0.630846, 38.148935],
              [-0.64764, 37.999989],
              [-0.665976, 37.976223],
              [-0.696742, 37.969468],
              [-0.719825, 37.911381],
              [-0.749058, 37.899733],
              [-0.762393, 37.846951],
              [-0.831909, 37.865127],
              [-0.921188, 37.944238],
              [-1.022599, 38.07569],
              [-1.036762, 38.13772],
              [-0.988546, 38.199531],
              [-0.967677, 38.27564],
              [-0.989153, 38.320877],
              [-1.084384, 38.346216],
              [-1.088062, 38.367768],
              [-1.077206, 38.43147],
              [-1.094162, 38.437929],
              [-1.013645, 38.49673],
              [-1.026062, 38.522819],
              [-1.001569, 38.572675],
              [-1.026933, 38.655517],
              [-0.962466, 38.656925],
              [-0.915793, 38.695965],
              [-0.961429, 38.774792],
              [-0.928938, 38.783834],
              [-0.904989, 38.76708],
              [-0.861693, 38.77089],
              [-0.834622, 38.732431],
              [-0.734931, 38.759445],
              [-0.707189, 38.733819],
              [-0.644026, 38.728138],
              [-0.642469, 38.70414],
              [-0.618051, 38.686653],
              [-0.507115, 38.742975],
              [-0.529627, 38.767095],
              [-0.565642, 38.755427],
              [-0.59129, 38.796818],
              [-0.461877, 38.807645],
              [-0.40339, 38.839714],
              [-0.381436, 38.834899],
              [-0.310809, 38.881511],
              [-0.23054, 38.85349],
              [-0.159684, 38.883508],
            ],
            [
              [-0.721108, 37.92103],
              [-0.72107, 37.921497],
              [-0.721342, 37.921539],
              [-0.721554, 37.920957],
              [-0.721108, 37.92103],
            ],
            [
              [0.086456, 38.659564],
              [0.086537, 38.659859],
              [0.086076, 38.659547],
              [0.086268, 38.659488],
              [0.086456, 38.659564],
            ],
            [
              [-0.720814, 37.92024],
              [-0.720743, 37.920492],
              [-0.721101, 37.92027],
              [-0.721011, 37.920052],
              [-0.720814, 37.92024],
            ],
            [
              [0.2319, 38.736502],
              [0.231759, 38.736687],
              [0.231645, 38.736708],
              [0.23162, 38.736663],
              [0.2319, 38.736502],
            ],
            [
              [0.155892, 38.691754],
              [0.155944, 38.691896],
              [0.155888, 38.691916],
              [0.155768, 38.691811],
              [0.155892, 38.691754],
            ],
          ],
          [
            [
              [-0.4802, 38.166964],
              [-0.472034, 38.166473],
              [-0.464887, 38.163228],
              [-0.484339, 38.166422],
              [-0.4802, 38.166964],
            ],
            [
              [-0.476419, 38.166081],
              [-0.476469, 38.166262],
              [-0.476769, 38.166179],
              [-0.476614, 38.166031],
              [-0.476419, 38.166081],
            ],
          ],
          [
            [
              [0.230722, 38.756921],
              [0.23046, 38.753608],
              [0.228202, 38.753856],
              [0.228885, 38.756845],
              [0.230722, 38.756921],
            ],
          ],
          [
            [
              [-0.129633, 38.503342],
              [-0.12903, 38.501201],
              [-0.133016, 38.502155],
              [-0.13122, 38.504075],
              [-0.129633, 38.503342],
            ],
          ],
          [
            [
              [0.218626, 38.72623],
              [0.218131, 38.726216],
              [0.215068, 38.727409],
              [0.216277, 38.7277],
              [0.218626, 38.72623],
            ],
          ],
          [
            [
              [-0.485836, 38.166707],
              [-0.485117, 38.1667],
              [-0.484581, 38.166364],
              [-0.486594, 38.166885],
              [-0.485836, 38.166707],
            ],
          ],
          [
            [
              [-0.071788, 38.537316],
              [-0.072585, 38.536336],
              [-0.073445, 38.536619],
              [-0.072954, 38.537138],
              [-0.071788, 38.537316],
            ],
          ],
          [
            [
              [-0.473527, 38.161252],
              [-0.47456, 38.161067],
              [-0.474744, 38.161684],
              [-0.474388, 38.161757],
              [-0.473527, 38.161252],
            ],
          ],
          [
            [
              [-0.019344, 38.616634],
              [-0.019966, 38.616587],
              [-0.019685, 38.617282],
              [-0.019184, 38.617161],
              [-0.019344, 38.616634],
            ],
          ],
          [
            [
              [-0.459277, 38.161467],
              [-0.459611, 38.161357],
              [-0.459858, 38.161813],
              [-0.459192, 38.161943],
              [-0.459277, 38.161467],
            ],
          ],
          [
            [
              [-0.538875, 38.185627],
              [-0.538458, 38.186474],
              [-0.538253, 38.18647],
              [-0.538296, 38.186201],
              [-0.538875, 38.185627],
            ],
          ],
          [
            [
              [-0.484459, 38.164578],
              [-0.484758, 38.164512],
              [-0.484965, 38.164805],
              [-0.484574, 38.164896],
              [-0.484459, 38.164578],
            ],
          ],
          [
            [
              [0.234171, 38.742466],
              [0.233826, 38.742476],
              [0.233866, 38.742853],
              [0.234175, 38.742809],
              [0.234171, 38.742466],
            ],
          ],
          [
            [
              [0.224468, 38.76123],
              [0.224126, 38.761311],
              [0.224121, 38.761455],
              [0.224495, 38.761571],
              [0.224468, 38.76123],
            ],
          ],
          [
            [
              [-0.464225, 38.162908],
              [-0.464446, 38.162804],
              [-0.464664, 38.163097],
              [-0.464411, 38.163146],
              [-0.464225, 38.162908],
            ],
          ],
          [
            [
              [-0.454652, 38.16188],
              [-0.454947, 38.161905],
              [-0.454938, 38.162166],
              [-0.454632, 38.162114],
              [-0.454652, 38.16188],
            ],
          ],
          [
            [
              [0.231503, 38.75217],
              [0.231203, 38.752265],
              [0.231693, 38.752486],
              [0.231688, 38.752369],
              [0.231503, 38.75217],
            ],
          ],
          [
            [
              [-0.753428, 37.871657],
              [-0.753547, 37.871791],
              [-0.753469, 37.872141],
              [-0.753303, 37.872123],
              [-0.753428, 37.871657],
            ],
          ],
          [
            [
              [-0.456676, 38.161744],
              [-0.45696, 38.161786],
              [-0.456897, 38.161956],
              [-0.456656, 38.161987],
              [-0.456676, 38.161744],
            ],
          ],
          [
            [
              [-0.014024, 38.623904],
              [-0.014218, 38.623945],
              [-0.014212, 38.624071],
              [-0.013878, 38.624107],
              [-0.014024, 38.623904],
            ],
          ],
          [
            [
              [-0.455923, 38.161746],
              [-0.456041, 38.161631],
              [-0.456241, 38.161798],
              [-0.456158, 38.161886],
              [-0.455923, 38.161746],
            ],
          ],
          [
            [
              [0.197752, 38.800194],
              [0.197713, 38.800005],
              [0.19747, 38.800026],
              [0.19751, 38.800225],
              [0.197752, 38.800194],
            ],
          ],
          [
            [
              [-0.761119, 37.849235],
              [-0.761188, 37.849193],
              [-0.761303, 37.849244],
              [-0.761152, 37.849363],
              [-0.761119, 37.849235],
            ],
          ],
          [
            [
              [-0.761041, 37.84793],
              [-0.761151, 37.847952],
              [-0.761136, 37.848059],
              [-0.761061, 37.848087],
              [-0.761041, 37.84793],
            ],
          ],
          [
            [
              [-0.760976, 37.848862],
              [-0.761021, 37.848794],
              [-0.761123, 37.848889],
              [-0.761047, 37.848946],
              [-0.760976, 37.848862],
            ],
          ],
          [
            [
              [-0.761131, 37.848606],
              [-0.76122, 37.848529],
              [-0.761259, 37.848667],
              [-0.761197, 37.84869],
              [-0.761131, 37.848606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5612",
      properties: {
        name: "Albacete",
        density: 0,
        path: "/world/Spain/Albacete",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.483657, 39.41824],
              [-1.488138, 39.40063],
              [-1.466675, 39.402295],
              [-1.476736, 39.393057],
              [-1.45218, 39.397589],
              [-1.468193, 39.383331],
              [-1.448789, 39.37819],
              [-1.455104, 39.360849],
              [-1.432294, 39.377582],
              [-1.426033, 39.362757],
              [-1.414679, 39.380298],
              [-1.336645, 39.333996],
              [-1.312744, 39.343166],
              [-1.165003, 39.310512],
              [-1.180826, 39.211822],
              [-1.25572, 39.10617],
              [-1.26675, 39.05102],
              [-1.226538, 39.024673],
              [-1.146654, 38.929353],
              [-0.95941, 38.944582],
              [-0.956524, 38.920123],
              [-0.924537, 38.891752],
              [-0.935697, 38.855937],
              [-0.924879, 38.823362],
              [-0.928938, 38.783834],
              [-0.961429, 38.774792],
              [-0.915793, 38.695965],
              [-0.962466, 38.656925],
              [-1.034104, 38.656566],
              [-1.115601, 38.711387],
              [-1.119672, 38.737719],
              [-1.183635, 38.755085],
              [-1.249757, 38.738614],
              [-1.344069, 38.677102],
              [-1.366666, 38.704002],
              [-1.383771, 38.699176],
              [-1.447698, 38.649518],
              [-1.452001, 38.58146],
              [-1.487695, 38.563309],
              [-1.497208, 38.538074],
              [-1.47987, 38.479835],
              [-1.478547, 38.376383],
              [-1.558198, 38.325203],
              [-1.589539, 38.309992],
              [-1.670246, 38.311649],
              [-1.692731, 38.337821],
              [-1.689116, 38.366996],
              [-1.769065, 38.379408],
              [-1.771143, 38.364858],
              [-1.795754, 38.370268],
              [-1.784303, 38.356154],
              [-1.907536, 38.297962],
              [-1.98263, 38.281371],
              [-2.022639, 38.300736],
              [-2.063772, 38.297592],
              [-2.113728, 38.238203],
              [-2.212939, 38.203022],
              [-2.26165, 38.112959],
              [-2.329902, 38.065402],
              [-2.341632, 38.026001],
              [-2.422116, 38.033362],
              [-2.547491, 38.079832],
              [-2.446277, 38.185194],
              [-2.454339, 38.215488],
              [-2.436603, 38.27213],
              [-2.485206, 38.300256],
              [-2.482357, 38.396195],
              [-2.571883, 38.414128],
              [-2.566624, 38.490466],
              [-2.592699, 38.510819],
              [-2.679692, 38.496921],
              [-2.7641, 38.534981],
              [-2.74679, 38.546814],
              [-2.765499, 38.573853],
              [-2.750097, 38.615565],
              [-2.763691, 38.627886],
              [-2.728246, 38.616075],
              [-2.668855, 38.649221],
              [-2.638071, 38.735346],
              [-2.696283, 38.782932],
              [-2.732242, 38.781849],
              [-2.762307, 38.880769],
              [-2.882888, 38.938491],
              [-2.832264, 39.023243],
              [-2.809932, 39.02383],
              [-2.80501, 39.078944],
              [-2.842657, 39.134491],
              [-2.723193, 39.264792],
              [-2.74337, 39.31702],
              [-2.634971, 39.326759],
              [-2.619258, 39.340143],
              [-2.548777, 39.323455],
              [-2.511046, 39.342153],
              [-2.374077, 39.232784],
              [-2.34989, 39.278542],
              [-2.321191, 39.283739],
              [-2.288677, 39.226661],
              [-2.168681, 39.281074],
              [-2.050963, 39.266677],
              [-2.095625, 39.359425],
              [-2.020331, 39.351824],
              [-2.028431, 39.336808],
              [-1.989799, 39.29368],
              [-1.887077, 39.30479],
              [-1.769853, 39.280508],
              [-1.739281, 39.32116],
              [-1.555679, 39.401848],
              [-1.483657, 39.41824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5610",
      properties: { name: "Almería", density: 0, path: "/world/Spain/Almería" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.181908, 37.898818],
              [-2.172012, 37.888689],
              [-2.123204, 37.900552],
              [-2.100385, 37.877198],
              [-2.021816, 37.868223],
              [-1.994702, 37.880477],
              [-1.974502, 37.868469],
              [-1.995645, 37.840235],
              [-2.008836, 37.774951],
              [-1.995832, 37.728047],
              [-2.012651, 37.700601],
              [-2.011814, 37.672248],
              [-1.976807, 37.618728],
              [-1.845261, 37.454827],
              [-1.808946, 37.433474],
              [-1.811756, 37.451556],
              [-1.737075, 37.442633],
              [-1.630124, 37.375231],
              [-1.677081, 37.352845],
              [-1.689015, 37.339242],
              [-1.708299, 37.304113],
              [-1.758812, 37.263083],
              [-1.773482, 37.245849],
              [-1.774776, 37.243186],
              [-1.775175, 37.239485],
              [-1.797564, 37.229611],
              [-1.849476, 37.068349],
              [-1.900761, 36.987757],
              [-1.903742, 36.93737],
              [-1.940529, 36.936523],
              [-1.967302, 36.898863],
              [-1.992083, 36.89373],
              [-1.999607, 36.840024],
              [-2.060022, 36.809723],
              [-2.060413, 36.77768],
              [-2.106196, 36.762653],
              [-2.12428, 36.732003],
              [-2.184198, 36.719249],
              [-2.29123, 36.820069],
              [-2.361433, 36.841598],
              [-2.426547, 36.81074],
              [-2.475125, 36.837026],
              [-2.464122, 36.825853],
              [-2.481953, 36.835577],
              [-2.561925, 36.816131],
              [-2.619649, 36.726958],
              [-2.70033, 36.682999],
              [-2.771542, 36.681427],
              [-2.815603, 36.706294],
              [-2.849482, 36.695471],
              [-2.925371, 36.751699],
              [-2.977681, 36.738327],
              [-3.128683, 36.750871],
              [-3.140187, 36.787791],
              [-3.029007, 36.844441],
              [-3.071934, 36.919287],
              [-3.031316, 36.924586],
              [-3.029337, 36.947527],
              [-2.990498, 36.958185],
              [-2.976215, 36.978122],
              [-3.005711, 37.023341],
              [-3.029577, 37.113254],
              [-3.000256, 37.094977],
              [-2.948964, 37.096552],
              [-2.929146, 37.156566],
              [-2.880986, 37.187103],
              [-2.874963, 37.231455],
              [-2.845385, 37.279221],
              [-2.779004, 37.262656],
              [-2.759033, 37.226714],
              [-2.733023, 37.237321],
              [-2.660115, 37.211994],
              [-2.665651, 37.285329],
              [-2.654601, 37.340607],
              [-2.637292, 37.343331],
              [-2.641641, 37.387792],
              [-2.514154, 37.479528],
              [-2.457522, 37.490827],
              [-2.456323, 37.509426],
              [-2.358511, 37.522293],
              [-2.355729, 37.546993],
              [-2.374298, 37.55735],
              [-2.367896, 37.591576],
              [-2.39127, 37.632485],
              [-2.359565, 37.621266],
              [-2.308937, 37.633428],
              [-2.323339, 37.693874],
              [-2.276785, 37.776219],
              [-2.301775, 37.808284],
              [-2.289387, 37.83148],
              [-2.29495, 37.876316],
              [-2.207673, 37.91657],
              [-2.181908, 37.898818],
            ],
            [
              [-1.819722, 37.177762],
              [-1.819609, 37.178527],
              [-1.819538, 37.178734],
              [-1.819863, 37.178133],
              [-1.819722, 37.177762],
            ],
            [
              [-1.820263, 37.174856],
              [-1.820126, 37.175702],
              [-1.820136, 37.175801],
              [-1.820347, 37.17528],
              [-1.820263, 37.174856],
            ],
            [
              [-2.567032, 36.811128],
              [-2.566671, 36.811452],
              [-2.566479, 36.811676],
              [-2.567268, 36.811012],
              [-2.567032, 36.811128],
            ],
            [
              [-1.718278, 37.296515],
              [-1.718309, 37.296705],
              [-1.718615, 37.296582],
              [-1.718526, 37.2965],
              [-1.718278, 37.296515],
            ],
            [
              [-2.174759, 36.722366],
              [-2.174957, 36.722665],
              [-2.175092, 36.72263],
              [-2.174939, 36.722322],
              [-2.174759, 36.722366],
            ],
            [
              [-1.71914, 37.296263],
              [-1.719048, 37.296343],
              [-1.719058, 37.296415],
              [-1.719297, 37.296319],
              [-1.71914, 37.296263],
            ],
            [
              [-1.720572, 37.295638],
              [-1.720604, 37.295729],
              [-1.720808, 37.295704],
              [-1.720674, 37.295604],
              [-1.720572, 37.295638],
            ],
            [
              [-1.709052, 37.303625],
              [-1.709174, 37.303708],
              [-1.709243, 37.303664],
              [-1.709177, 37.303573],
              [-1.709052, 37.303625],
            ],
            [
              [-1.719397, 37.296383],
              [-1.719532, 37.296411],
              [-1.719545, 37.296312],
              [-1.719443, 37.296311],
              [-1.719397, 37.296383],
            ],
            [
              [-1.717794, 37.297096],
              [-1.717793, 37.297159],
              [-1.717906, 37.29716],
              [-1.717919, 37.297088],
              [-1.717794, 37.297096],
            ],
            [
              [-1.719288, 37.296147],
              [-1.719343, 37.296229],
              [-1.719401, 37.296158],
              [-1.719334, 37.29613],
              [-1.719288, 37.296147],
            ],
          ],
          [
            [
              [-3.034541, 35.938103],
              [-3.037492, 35.938938],
              [-3.031083, 35.941429],
              [-3.033687, 35.93985],
              [-3.034541, 35.938103],
            ],
          ],
          [
            [
              [-1.885714, 36.993259],
              [-1.885268, 36.992994],
              [-1.885046, 36.992063],
              [-1.886298, 36.992498],
              [-1.885714, 36.993259],
            ],
          ],
          [
            [
              [-1.651364, 37.347048],
              [-1.651797, 37.346827],
              [-1.653012, 37.347049],
              [-1.652163, 37.347805],
              [-1.651364, 37.347048],
            ],
          ],
          [
            [
              [-1.647838, 37.359824],
              [-1.64831, 37.359325],
              [-1.649077, 37.359334],
              [-1.648957, 37.359738],
              [-1.647838, 37.359824],
            ],
          ],
          [
            [
              [-2.048917, 36.812087],
              [-2.049098, 36.811971],
              [-2.049597, 36.812426],
              [-2.049044, 36.812674],
              [-2.048917, 36.812087],
            ],
          ],
          [
            [
              [-2.193768, 36.721793],
              [-2.194034, 36.722084],
              [-2.193886, 36.722344],
              [-2.193574, 36.722225],
              [-2.193768, 36.721793],
            ],
          ],
          [
            [
              [-2.19075, 36.720267],
              [-2.191027, 36.72054],
              [-2.190687, 36.72088],
              [-2.190622, 36.720721],
              [-2.19075, 36.720267],
            ],
          ],
          [
            [
              [-2.002333, 36.836459],
              [-2.002738, 36.836408],
              [-2.00288, 36.836671],
              [-2.002465, 36.836712],
              [-2.002333, 36.836459],
            ],
          ],
          [
            [
              [-1.69978, 37.317215],
              [-1.70013, 37.317228],
              [-1.700195, 37.317355],
              [-1.699832, 37.317459],
              [-1.69978, 37.317215],
            ],
          ],
          [
            [
              [-1.697703, 37.321843],
              [-1.697825, 37.321917],
              [-1.69758, 37.32241],
              [-1.697553, 37.322013],
              [-1.697703, 37.321843],
            ],
          ],
          [
            [
              [-1.712615, 37.299779],
              [-1.712784, 37.299808],
              [-1.712599, 37.300086],
              [-1.712454, 37.299967],
              [-1.712615, 37.299779],
            ],
          ],
          [
            [
              [-3.029985, 35.942052],
              [-3.030274, 35.94198],
              [-3.030362, 35.942016],
              [-3.030174, 35.942251],
              [-3.029985, 35.942052],
            ],
          ],
          [
            [
              [-1.723777, 37.290211],
              [-1.724015, 37.290177],
              [-1.724069, 37.290286],
              [-1.723841, 37.290473],
              [-1.723777, 37.290211],
            ],
          ],
          [
            [
              [-1.773201, 37.245107],
              [-1.77337, 37.245073],
              [-1.773502, 37.245291],
              [-1.773276, 37.245351],
              [-1.773201, 37.245107],
            ],
          ],
          [
            [
              [-2.191043, 36.720035],
              [-2.1912, 36.72],
              [-2.191289, 36.720127],
              [-2.191008, 36.720215],
              [-2.191043, 36.720035],
            ],
          ],
          [
            [
              [-1.725169, 37.288622],
              [-1.725294, 37.288569],
              [-1.72545, 37.288697],
              [-1.725312, 37.288839],
              [-1.725169, 37.288622],
            ],
          ],
          [
            [
              [-1.698884, 37.320081],
              [-1.699122, 37.320029],
              [-1.699166, 37.32012],
              [-1.699017, 37.320235],
              [-1.698884, 37.320081],
            ],
          ],
          [
            [
              [-1.772879, 37.244797],
              [-1.77307, 37.244808],
              [-1.772751, 37.24503],
              [-1.772752, 37.244958],
              [-1.772879, 37.244797],
            ],
          ],
          [
            [
              [-1.773853, 37.243798],
              [-1.773696, 37.24376],
              [-1.773755, 37.243581],
              [-1.773957, 37.243646],
              [-1.773853, 37.243798],
            ],
          ],
          [
            [
              [-1.773269, 37.244342],
              [-1.773519, 37.244254],
              [-1.773574, 37.244327],
              [-1.773313, 37.24445],
              [-1.773269, 37.244342],
            ],
          ],
          [
            [
              [-1.723215, 37.290764],
              [-1.723372, 37.29082],
              [-1.723166, 37.290998],
              [-1.723146, 37.290853],
              [-1.723215, 37.290764],
            ],
          ],
          [
            [
              [-1.698281, 37.321669],
              [-1.698395, 37.321635],
              [-1.698414, 37.321833],
              [-1.698222, 37.321813],
              [-1.698281, 37.321669],
            ],
          ],
          [
            [
              [-1.769322, 37.248564],
              [-1.769435, 37.248529],
              [-1.769524, 37.248638],
              [-1.769331, 37.248718],
              [-1.769322, 37.248564],
            ],
          ],
          [
            [
              [-1.698746, 37.32025],
              [-1.69887, 37.320243],
              [-1.698935, 37.320424],
              [-1.698766, 37.320413],
              [-1.698746, 37.32025],
            ],
          ],
          [
            [
              [-1.773278, 37.244522],
              [-1.773391, 37.244496],
              [-1.773503, 37.244534],
              [-1.773241, 37.244684],
              [-1.773278, 37.244522],
            ],
          ],
          [
            [
              [-1.699507, 37.317356],
              [-1.699733, 37.317332],
              [-1.699718, 37.317512],
              [-1.699651, 37.31752],
              [-1.699507, 37.317356],
            ],
          ],
          [
            [
              [-1.698419, 37.320229],
              [-1.698656, 37.320213],
              [-1.698655, 37.320276],
              [-1.698394, 37.320346],
              [-1.698419, 37.320229],
            ],
          ],
          [
            [
              [-1.765922, 37.251729],
              [-1.765696, 37.251717],
              [-1.765722, 37.251546],
              [-1.765957, 37.25163],
              [-1.765922, 37.251729],
            ],
          ],
          [
            [
              [-1.697152, 37.323036],
              [-1.697064, 37.322864],
              [-1.697189, 37.322829],
              [-1.697288, 37.323001],
              [-1.697152, 37.323036],
            ],
          ],
          [
            [
              [-1.763798, 37.254041],
              [-1.763991, 37.253953],
              [-1.764036, 37.253971],
              [-1.763626, 37.254219],
              [-1.763798, 37.254041],
            ],
          ],
          [
            [
              [-1.698795, 37.319386],
              [-1.698875, 37.319296],
              [-1.699054, 37.319397],
              [-1.698895, 37.319423],
              [-1.698795, 37.319386],
            ],
          ],
          [
            [
              [-1.704188, 37.310891],
              [-1.704311, 37.310956],
              [-1.704118, 37.311062],
              [-1.70413, 37.31099],
              [-1.704188, 37.310891],
            ],
          ],
          [
            [
              [-1.744596, 37.272299],
              [-1.744665, 37.272182],
              [-1.744721, 37.272201],
              [-1.744548, 37.272424],
              [-1.744596, 37.272299],
            ],
          ],
          [
            [
              [-1.768973, 37.249255],
              [-1.769075, 37.249202],
              [-1.769131, 37.24922],
              [-1.768925, 37.249407],
              [-1.768973, 37.249255],
            ],
          ],
          [
            [
              [-1.703946, 37.311195],
              [-1.704003, 37.311169],
              [-1.704113, 37.311314],
              [-1.703966, 37.311321],
              [-1.703946, 37.311195],
            ],
          ],
          [
            [
              [-1.763801, 37.253816],
              [-1.763937, 37.253808],
              [-1.763675, 37.253959],
              [-1.76371, 37.253878],
              [-1.763801, 37.253816],
            ],
          ],
          [
            [
              [-1.750606, 37.266242],
              [-1.750719, 37.266234],
              [-1.750774, 37.266316],
              [-1.750716, 37.266378],
              [-1.750606, 37.266242],
            ],
          ],
          [
            [
              [-1.741361, 37.275536],
              [-1.741485, 37.275528],
              [-1.741516, 37.275682],
              [-1.741404, 37.275681],
              [-1.741361, 37.275536],
            ],
          ],
          [
            [
              [-1.739216, 37.278334],
              [-1.739283, 37.278389],
              [-1.739, 37.278449],
              [-1.739125, 37.278406],
              [-1.739216, 37.278334],
            ],
          ],
          [
            [
              [-1.773966, 37.243799],
              [-1.774056, 37.243827],
              [-1.773919, 37.243934],
              [-1.773875, 37.243861],
              [-1.773966, 37.243799],
            ],
          ],
          [
            [
              [-1.722432, 37.291702],
              [-1.722512, 37.29163],
              [-1.722625, 37.291641],
              [-1.722612, 37.291722],
              [-1.722432, 37.291702],
            ],
          ],
          [
            [
              [-1.723645, 37.290056],
              [-1.72378, 37.290049],
              [-1.723756, 37.290139],
              [-1.723609, 37.290164],
              [-1.723645, 37.290056],
            ],
          ],
          [
            [
              [-1.77295, 37.245258],
              [-1.772929, 37.245195],
              [-1.773142, 37.24526],
              [-1.773051, 37.245313],
              [-1.77295, 37.245258],
            ],
          ],
          [
            [
              [-1.725056, 37.289332],
              [-1.725146, 37.289297],
              [-1.725144, 37.28946],
              [-1.725088, 37.289432],
              [-1.725056, 37.289332],
            ],
          ],
          [
            [
              [-1.765277, 37.251848],
              [-1.765357, 37.251804],
              [-1.7654, 37.251894],
              [-1.765175, 37.251892],
              [-1.765277, 37.251848],
            ],
          ],
          [
            [
              [-1.769494, 37.247683],
              [-1.769507, 37.247602],
              [-1.769619, 37.247612],
              [-1.769629, 37.247711],
              [-1.769494, 37.247683],
            ],
          ],
          [
            [
              [-1.723376, 37.290594],
              [-1.723411, 37.290522],
              [-1.723513, 37.290524],
              [-1.7235, 37.290614],
              [-1.723397, 37.290667],
              [-1.723376, 37.290594],
            ],
          ],
          [
            [
              [-1.732145, 37.283009],
              [-1.732225, 37.282947],
              [-1.732281, 37.282965],
              [-1.732256, 37.283073],
              [-1.732145, 37.283009],
            ],
          ],
          [
            [
              [-1.764487, 37.253255],
              [-1.764578, 37.253202],
              [-1.76468, 37.253212],
              [-1.764497, 37.253318],
              [-1.764487, 37.253255],
            ],
          ],
          [
            [
              [-1.763108, 37.254854],
              [-1.763188, 37.254792],
              [-1.763301, 37.254793],
              [-1.76314, 37.254935],
              [-1.763108, 37.254854],
            ],
          ],
          [
            [
              [-1.697869, 37.322025],
              [-1.697971, 37.321963],
              [-1.698016, 37.321991],
              [-1.697844, 37.322133],
              [-1.697869, 37.322025],
            ],
          ],
          [
            [
              [-1.728485, 37.286683],
              [-1.72852, 37.286612],
              [-1.728584, 37.286793],
              [-1.728528, 37.286801],
              [-1.728485, 37.286683],
            ],
          ],
          [
            [
              [-1.731977, 37.282908],
              [-1.732091, 37.282873],
              [-1.732203, 37.282892],
              [-1.731976, 37.282962],
              [-1.731977, 37.282908],
            ],
          ],
          [
            [
              [-1.72393, 37.289843],
              [-1.724043, 37.289817],
              [-1.724042, 37.289916],
              [-1.723985, 37.289961],
              [-1.72393, 37.289843],
            ],
          ],
          [
            [
              [-1.699515, 37.319493],
              [-1.699639, 37.319503],
              [-1.699626, 37.319602],
              [-1.699592, 37.319611],
              [-1.699515, 37.319493],
            ],
          ],
          [
            [
              [-1.696621, 37.325031],
              [-1.696667, 37.324932],
              [-1.696745, 37.324987],
              [-1.696721, 37.325086],
              [-1.696621, 37.325031],
            ],
          ],
          [
            [
              [-1.761371, 37.256972],
              [-1.761472, 37.256991],
              [-1.761346, 37.257089],
              [-1.761302, 37.257043],
              [-1.761371, 37.256972],
            ],
          ],
          [
            [
              [-1.699668, 37.317809],
              [-1.699781, 37.317792],
              [-1.699746, 37.317891],
              [-1.699668, 37.317809],
            ],
          ],
          [
            [
              [-1.763031, 37.254727],
              [-1.763099, 37.254701],
              [-1.763132, 37.254737],
              [-1.762962, 37.254798],
              [-1.763031, 37.254727],
            ],
          ],
          [
            [
              [-1.765692, 37.251961],
              [-1.765727, 37.251889],
              [-1.765885, 37.251927],
              [-1.765782, 37.25198],
              [-1.765692, 37.251961],
            ],
          ],
          [
            [
              [-1.745591, 37.271471],
              [-1.745693, 37.271427],
              [-1.745715, 37.271454],
              [-1.745511, 37.271533],
              [-1.745591, 37.271471],
            ],
          ],
          [
            [
              [-1.698579, 37.320762],
              [-1.69867, 37.320736],
              [-1.698691, 37.320809],
              [-1.698589, 37.320844],
              [-1.698579, 37.320762],
            ],
          ],
          [
            [
              [-1.730453, 37.283676],
              [-1.730521, 37.283632],
              [-1.730588, 37.283668],
              [-1.730542, 37.283722],
              [-1.730453, 37.283676],
            ],
          ],
          [
            [
              [-1.764968, 37.252818],
              [-1.76507, 37.252756],
              [-1.765149, 37.252793],
              [-1.765069, 37.252846],
              [-1.764968, 37.252818],
            ],
          ],
          [
            [
              [-1.698537, 37.319933],
              [-1.69865, 37.319916],
              [-1.698581, 37.320014],
              [-1.698537, 37.319933],
            ],
          ],
          [
            [
              [-1.713267, 37.299913],
              [-1.713347, 37.299869],
              [-1.713391, 37.299932],
              [-1.7133, 37.299976],
              [-1.713267, 37.299913],
            ],
          ],
          [
            [
              [-1.699593, 37.318881],
              [-1.699729, 37.318873],
              [-1.699728, 37.318918],
              [-1.699615, 37.318944],
              [-1.699593, 37.318881],
            ],
          ],
          [
            [
              [-1.769662, 37.248469],
              [-1.769696, 37.248424],
              [-1.769763, 37.248497],
              [-1.769694, 37.248559],
              [-1.769662, 37.248469],
            ],
          ],
          [
            [
              [-1.77324, 37.245441],
              [-1.77332, 37.245397],
              [-1.773387, 37.245452],
              [-1.773341, 37.245496],
              [-1.77324, 37.245441],
            ],
          ],
          [
            [
              [-1.707541, 37.304213],
              [-1.707643, 37.304205],
              [-1.707653, 37.304277],
              [-1.707585, 37.304294],
              [-1.707541, 37.304213],
            ],
          ],
          [
            [
              [-1.703138, 37.312259],
              [-1.703206, 37.31225],
              [-1.703227, 37.312314],
              [-1.703159, 37.31234],
              [-1.703138, 37.312259],
            ],
          ],
          [
            [
              [-1.723183, 37.290655],
              [-1.723218, 37.290593],
              [-1.723319, 37.290621],
              [-1.72325, 37.290683],
              [-1.723183, 37.290655],
            ],
          ],
          [
            [
              [-1.697415, 37.322841],
              [-1.697394, 37.322741],
              [-1.697461, 37.322751],
              [-1.697415, 37.322841],
            ],
          ],
          [
            [
              [-1.704328, 37.310649],
              [-1.704407, 37.310641],
              [-1.704405, 37.310731],
              [-1.704349, 37.310731],
              [-1.704328, 37.310649],
            ],
          ],
          [
            [
              [-1.701282, 37.315177],
              [-1.701407, 37.315142],
              [-1.701429, 37.315178],
              [-1.701372, 37.315205],
              [-1.701282, 37.315177],
            ],
          ],
          [
            [
              [-1.765124, 37.25227],
              [-1.765225, 37.252244],
              [-1.765247, 37.252289],
              [-1.765168, 37.252325],
              [-1.765124, 37.25227],
            ],
          ],
          [
            [
              [-1.707319, 37.304688],
              [-1.707353, 37.304643],
              [-1.707443, 37.304653],
              [-1.707397, 37.304716],
              [-1.707319, 37.304688],
            ],
          ],
          [
            [
              [-1.741514, 37.275844],
              [-1.741616, 37.2758],
              [-1.741661, 37.275828],
              [-1.741615, 37.275872],
              [-1.741514, 37.275844],
            ],
          ],
          [
            [
              [-1.739956, 37.277964],
              [-1.739923, 37.2779],
              [-1.740013, 37.277901],
              [-1.740012, 37.277964],
              [-1.739956, 37.277964],
            ],
          ],
          [
            [
              [-1.698985, 37.320767],
              [-1.698975, 37.320695],
              [-1.699054, 37.320695],
              [-1.69903, 37.320767],
              [-1.698985, 37.320767],
            ],
          ],
          [
            [
              [-1.703272, 37.312314],
              [-1.703351, 37.312306],
              [-1.703362, 37.312351],
              [-1.703294, 37.312369],
              [-1.703272, 37.312314],
            ],
          ],
          [
            [
              [-1.7743, 37.243388],
              [-1.774357, 37.243308],
              [-1.774367, 37.243389],
              [-1.7743, 37.243388],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5614",
      properties: {
        name: "Araba/Álava",
        density: 0,
        path: "/world/Spain/Araba/Álava",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.026895, 43.216568],
              [-3.006779, 43.19659],
              [-2.980044, 43.201856],
              [-2.946394, 43.170869],
              [-2.954166, 43.159706],
              [-2.935813, 43.136213],
              [-2.975483, 43.09947],
              [-2.894164, 43.046421],
              [-2.633624, 43.01991],
              [-2.633697, 43.044818],
              [-2.661674, 43.07427],
              [-2.546646, 43.088179],
              [-2.524851, 43.045327],
              [-2.549582, 43.019789],
              [-2.596533, 43.009249],
              [-2.602684, 42.982228],
              [-2.581024, 42.964257],
              [-2.542505, 42.95016],
              [-2.535304, 42.973445],
              [-2.354798, 42.950027],
              [-2.241151, 42.886032],
              [-2.235411, 42.835304],
              [-2.281011, 42.785134],
              [-2.262167, 42.748461],
              [-2.31685, 42.739345],
              [-2.324008, 42.723772],
              [-2.316864, 42.682502],
              [-2.293578, 42.677939],
              [-2.288481, 42.655069],
              [-2.381949, 42.634296],
              [-2.37626, 42.65071],
              [-2.415797, 42.66268],
              [-2.483072, 42.612158],
              [-2.500083, 42.616476],
              [-2.490631, 42.585355],
              [-2.462205, 42.587386],
              [-2.453242, 42.567108],
              [-2.421121, 42.607124],
              [-2.396754, 42.5991],
              [-2.392202, 42.516952],
              [-2.423121, 42.510709],
              [-2.420582, 42.489235],
              [-2.514721, 42.490115],
              [-2.510593, 42.520042],
              [-2.537353, 42.495243],
              [-2.53048, 42.483571],
              [-2.568259, 42.494148],
              [-2.596993, 42.472936],
              [-2.605426, 42.503523],
              [-2.647749, 42.483262],
              [-2.675781, 42.525727],
              [-2.705687, 42.517162],
              [-2.680542, 42.594518],
              [-2.740772, 42.622552],
              [-2.76372, 42.622836],
              [-2.777953, 42.581339],
              [-2.824993, 42.554944],
              [-2.840847, 42.581192],
              [-2.819905, 42.591473],
              [-2.818771, 42.612716],
              [-2.8418, 42.608599],
              [-2.842893, 42.629603],
              [-2.895719, 42.654637],
              [-2.902502, 42.693603],
              [-2.931358, 42.708402],
              [-2.980631, 42.703819],
              [-3.016953, 42.741084],
              [-3.04201, 42.73952],
              [-3.049654, 42.765606],
              [-3.147913, 42.756697],
              [-3.123255, 42.789326],
              [-3.159229, 42.804591],
              [-3.11315, 42.861064],
              [-3.11494, 42.889249],
              [-3.162823, 42.856737],
              [-3.190686, 42.869463],
              [-3.222176, 42.838636],
              [-3.205533, 42.827401],
              [-3.282609, 42.875514],
              [-3.277239, 42.90158],
              [-3.248545, 42.912145],
              [-3.224729, 42.949692],
              [-3.144732, 42.934682],
              [-3.149273, 42.917063],
              [-3.092102, 42.898779],
              [-3.018906, 42.908512],
              [-2.981471, 42.938805],
              [-3.044777, 42.973927],
              [-2.9557, 42.998852],
              [-2.985723, 42.992133],
              [-2.986012, 43.012097],
              [-3.032905, 43.020333],
              [-3.01855, 43.037662],
              [-3.028633, 43.046584],
              [-3.04974, 43.047225],
              [-3.08899, 43.001637],
              [-3.177802, 43.021984],
              [-3.14186, 43.029814],
              [-3.140312, 43.06715],
              [-3.158187, 43.074016],
              [-3.133941, 43.097234],
              [-3.181711, 43.120312],
              [-3.161848, 43.154262],
              [-3.13829, 43.163181],
              [-3.11134, 43.143498],
              [-3.0269, 43.161369],
              [-3.016504, 43.202829],
              [-3.040069, 43.209883],
              [-3.026895, 43.216568],
            ],
            [
              [-2.835474, 42.79627],
              [-2.868464, 42.739921],
              [-2.784503, 42.698942],
              [-2.7679, 42.666011],
              [-2.659175, 42.672456],
              [-2.634851, 42.647795],
              [-2.517397, 42.646332],
              [-2.522024, 42.684032],
              [-2.577768, 42.668213],
              [-2.608979, 42.695962],
              [-2.572629, 42.725172],
              [-2.558302, 42.762733],
              [-2.589366, 42.750323],
              [-2.602347, 42.770631],
              [-2.643708, 42.764716],
              [-2.731553, 42.794513],
              [-2.826812, 42.78445],
              [-2.835474, 42.79627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5602",
      properties: {
        name: "Asturias",
        density: 0,
        path: "/world/Spain/Asturias",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.840833, 43.660619],
              [-5.830022, 43.640116],
              [-5.783099, 43.631301],
              [-5.790972, 43.614201],
              [-5.755584, 43.583366],
              [-5.711967, 43.568617],
              [-5.676112, 43.570976],
              [-5.701257, 43.566152],
              [-5.679164, 43.541237],
              [-5.617565, 43.559016],
              [-5.493779, 43.544021],
              [-5.413247, 43.554871],
              [-5.388224, 43.529744],
              [-5.295404, 43.535157],
              [-5.216229, 43.474898],
              [-5.123517, 43.484608],
              [-5.037041, 43.460682],
              [-4.982897, 43.454925],
              [-4.926067, 43.462389],
              [-4.69931, 43.40479],
              [-4.680871, 43.409231],
              [-4.567442, 43.392023],
              [-4.518727, 43.395814],
              [-4.510836, 43.379909],
              [-4.534445, 43.368339],
              [-4.539457, 43.34396],
              [-4.522349, 43.335778],
              [-4.521713, 43.280625],
              [-4.547773, 43.268687],
              [-4.555242, 43.288098],
              [-4.606552, 43.300554],
              [-4.631883, 43.267653],
              [-4.730945, 43.257336],
              [-4.719682, 43.235823],
              [-4.745806, 43.184119],
              [-4.841023, 43.18078],
              [-4.871239, 43.228131],
              [-4.911425, 43.237363],
              [-4.963916, 43.21244],
              [-4.996858, 43.173097],
              [-5.075859, 43.181532],
              [-5.101735, 43.101846],
              [-5.147803, 43.115119],
              [-5.216597, 43.084488],
              [-5.282121, 43.077428],
              [-5.369136, 43.094577],
              [-5.390451, 43.049337],
              [-5.478523, 43.054909],
              [-5.493752, 43.021351],
              [-5.570142, 43.022731],
              [-5.693349, 43.056481],
              [-5.728376, 43.042513],
              [-5.763858, 42.97013],
              [-5.804006, 42.962615],
              [-5.914618, 42.992698],
              [-5.956745, 43.018593],
              [-5.975035, 43.065418],
              [-6.020214, 43.054878],
              [-6.081878, 43.075716],
              [-6.095964, 43.031278],
              [-6.136667, 43.020993],
              [-6.178401, 43.047798],
              [-6.21556, 43.0472],
              [-6.226808, 43.008913],
              [-6.285191, 43.036369],
              [-6.326493, 43.03133],
              [-6.359088, 43.059577],
              [-6.397047, 43.038322],
              [-6.394352, 43.016612],
              [-6.411107, 43.016932],
              [-6.407577, 42.999489],
              [-6.424081, 42.987875],
              [-6.478136, 42.991205],
              [-6.473011, 42.967752],
              [-6.433638, 42.96416],
              [-6.433102, 42.939757],
              [-6.541234, 42.912508],
              [-6.638783, 42.925462],
              [-6.72266, 42.917485],
              [-6.772849, 42.882543],
              [-6.770671, 42.898624],
              [-6.835796, 42.916347],
              [-6.836091, 42.957764],
              [-6.871074, 42.988584],
              [-6.910321, 42.990138],
              [-6.957104, 43.036778],
              [-6.961956, 42.990718],
              [-6.997172, 43.019483],
              [-6.981913, 43.041373],
              [-6.96464, 43.034671],
              [-6.968586, 43.057215],
              [-6.938689, 43.086512],
              [-6.915595, 43.073422],
              [-6.827604, 43.123187],
              [-6.821554, 43.143243],
              [-6.838912, 43.166143],
              [-6.871712, 43.179865],
              [-6.868246, 43.190022],
              [-6.885405, 43.156801],
              [-6.957362, 43.135477],
              [-6.980773, 43.166546],
              [-6.964901, 43.187545],
              [-6.971019, 43.20425],
              [-7.001445, 43.207276],
              [-7.009583, 43.222882],
              [-7.020053, 43.21324],
              [-7.061591, 43.244048],
              [-7.071508, 43.271218],
              [-7.059785, 43.305335],
              [-7.100933, 43.30381],
              [-7.131208, 43.331348],
              [-7.127533, 43.372599],
              [-7.182489, 43.392459],
              [-7.16855, 43.403593],
              [-7.172988, 43.430413],
              [-7.101657, 43.42748],
              [-7.089155, 43.455118],
              [-7.04827, 43.477076],
              [-7.047179, 43.508986],
              [-7.023223, 43.559576],
              [-7.017324, 43.555939],
              [-7.005972, 43.555015],
              [-7.003185, 43.557426],
              [-7.000181, 43.55412],
              [-6.97406, 43.556752],
              [-6.925379, 43.574985],
              [-6.910249, 43.566447],
              [-6.876261, 43.562412],
              [-6.828313, 43.566381],
              [-6.811092, 43.55446],
              [-6.784956, 43.555996],
              [-6.76695, 43.568792],
              [-6.722053, 43.555976],
              [-6.709504, 43.56411],
              [-6.684755, 43.558663],
              [-6.626309, 43.572905],
              [-6.598485, 43.554032],
              [-6.533299, 43.545228],
              [-6.474497, 43.550255],
              [-6.470226, 43.571156],
              [-6.445488, 43.562749],
              [-6.445026, 43.56116],
              [-6.444449, 43.561033],
              [-6.444613, 43.560209],
              [-6.443893, 43.559538],
              [-6.442663, 43.559016],
              [-6.439066, 43.558909],
              [-6.438834, 43.556143],
              [-6.429753, 43.55276],
              [-6.355771, 43.55115],
              [-6.303642, 43.563366],
              [-6.295748, 43.566637],
              [-6.298816, 43.569303],
              [-6.29706, 43.571164],
              [-6.296365, 43.569545],
              [-6.285429, 43.569968],
              [-6.267739, 43.578984],
              [-6.255946, 43.57779],
              [-6.244508, 43.595178],
              [-6.203065, 43.572911],
              [-6.188208, 43.579539],
              [-6.186366, 43.563897],
              [-6.154469, 43.570734],
              [-6.154245, 43.568346],
              [-6.151146, 43.569701],
              [-6.147428, 43.567498],
              [-6.147365, 43.563682],
              [-6.112989, 43.555432],
              [-6.092512, 43.55832],
              [-6.078188, 43.564658],
              [-6.079081, 43.567849],
              [-6.077367, 43.570407],
              [-6.077935, 43.564296],
              [-6.06603, 43.565895],
              [-6.017272, 43.588262],
              [-5.997097, 43.574117],
              [-5.956332, 43.579762],
              [-5.914993, 43.608025],
              [-5.914785, 43.623714],
              [-5.887246, 43.623216],
              [-5.866673, 43.631324],
              [-5.840833, 43.660619],
            ],
            [
              [-5.590431, 43.54901],
              [-5.591257, 43.549811],
              [-5.591668, 43.549873],
              [-5.591254, 43.548883],
              [-5.590431, 43.54901],
            ],
            [
              [-6.932443, 43.571086],
              [-6.932104, 43.571575],
              [-6.933095, 43.571586],
              [-6.932652, 43.571061],
              [-6.932443, 43.571086],
            ],
            [
              [-6.755339, 43.564078],
              [-6.755077, 43.564645],
              [-6.755263, 43.564855],
              [-6.755852, 43.564368],
              [-6.755339, 43.564078],
            ],
            [
              [-5.597769, 43.549248],
              [-5.598151, 43.549483],
              [-5.5985, 43.54925],
              [-5.597608, 43.548946],
              [-5.597769, 43.549248],
            ],
            [
              [-4.893578, 43.449254],
              [-4.89332, 43.449295],
              [-4.893121, 43.449658],
              [-4.89389, 43.449321],
              [-4.893578, 43.449254],
            ],
            [
              [-5.607032, 43.556623],
              [-5.607188, 43.556791],
              [-5.607733, 43.556517],
              [-5.607122, 43.556405],
              [-5.607032, 43.556623],
            ],
            [
              [-6.251103, 43.583997],
              [-6.250935, 43.584083],
              [-6.250863, 43.584355],
              [-6.251416, 43.584276],
              [-6.251103, 43.583997],
            ],
            [
              [-4.614774, 43.396839],
              [-4.615298, 43.397048],
              [-4.615479, 43.396892],
              [-4.615337, 43.39666],
              [-4.614774, 43.396839],
            ],
            [
              [-6.451768, 43.563883],
              [-6.452049, 43.564036],
              [-6.452229, 43.563716],
              [-6.451965, 43.563642],
              [-6.451768, 43.563883],
            ],
            [
              [-4.628621, 43.399803],
              [-4.628913, 43.399628],
              [-4.628186, 43.399675],
              [-4.62825, 43.399773],
              [-4.628621, 43.399803],
            ],
            [
              [-6.44806, 43.562473],
              [-6.447675, 43.562881],
              [-6.447771, 43.563049],
              [-6.448193, 43.562631],
              [-6.44806, 43.562473],
            ],
            [
              [-4.628802, 43.39918],
              [-4.628397, 43.399257],
              [-4.628338, 43.399375],
              [-4.629199, 43.399246],
              [-4.628802, 43.39918],
            ],
            [
              [-4.629278, 43.398966],
              [-4.629387, 43.399333],
              [-4.629645, 43.399303],
              [-4.629676, 43.399086],
              [-4.629278, 43.398966],
            ],
            [
              [-5.605532, 43.550431],
              [-5.605294, 43.550653],
              [-5.605329, 43.550886],
              [-5.605688, 43.550599],
              [-5.605532, 43.550431],
            ],
            [
              [-4.625327, 43.398995],
              [-4.625396, 43.399264],
              [-4.625789, 43.399159],
              [-4.625761, 43.399052],
              [-4.625327, 43.398995],
            ],
            [
              [-6.459676, 43.566614],
              [-6.459456, 43.566882],
              [-6.460051, 43.566684],
              [-6.459872, 43.566581],
              [-6.459676, 43.566614],
            ],
            [
              [-6.452764, 43.562889],
              [-6.452705, 43.563152],
              [-6.452897, 43.563263],
              [-6.45297, 43.562811],
              [-6.452764, 43.562889],
            ],
            [
              [-6.447425, 43.561106],
              [-6.447254, 43.561363],
              [-6.447327, 43.561559],
              [-6.447604, 43.561208],
              [-6.447425, 43.561106],
            ],
            [
              [-7.023715, 43.55692],
              [-7.023905, 43.557175],
              [-7.02418, 43.55703],
              [-7.024111, 43.556915],
              [-7.023715, 43.55692],
            ],
            [
              [-6.251346, 43.582981],
              [-6.251086, 43.583223],
              [-6.251102, 43.583285],
              [-6.251541, 43.583147],
              [-6.251346, 43.582981],
            ],
            [
              [-6.932631, 43.57036],
              [-6.932531, 43.570525],
              [-6.932984, 43.570636],
              [-6.932974, 43.570492],
              [-6.932631, 43.57036],
            ],
            [
              [-4.896261, 43.450867],
              [-4.896107, 43.451067],
              [-4.896136, 43.451202],
              [-4.896488, 43.450998],
              [-4.896261, 43.450867],
            ],
            [
              [-6.460061, 43.56708],
              [-6.459803, 43.567331],
              [-6.459797, 43.567439],
              [-6.460136, 43.567303],
              [-6.460061, 43.56708],
            ],
            [
              [-6.454697, 43.565081],
              [-6.454556, 43.565428],
              [-6.454586, 43.565517],
              [-6.454895, 43.565292],
              [-6.454697, 43.565081],
            ],
            [
              [-6.756254, 43.564462],
              [-6.756115, 43.56462],
              [-6.756386, 43.564791],
              [-6.756442, 43.564501],
              [-6.756254, 43.564462],
            ],
            [
              [-7.025777, 43.547565],
              [-7.025517, 43.547565],
              [-7.025384, 43.547606],
              [-7.026005, 43.547638],
              [-7.025777, 43.547565],
            ],
            [
              [-4.86033, 43.444731],
              [-4.860266, 43.445074],
              [-4.860331, 43.445154],
              [-4.860523, 43.44498],
              [-4.86033, 43.444731],
            ],
            [
              [-6.454023, 43.563472],
              [-6.45416, 43.563711],
              [-6.45435, 43.56357],
              [-6.454193, 43.563413],
              [-6.454023, 43.563472],
            ],
            [
              [-5.602308, 43.55001],
              [-5.60233, 43.550225],
              [-5.602609, 43.550111],
              [-5.602592, 43.549985],
              [-5.602308, 43.55001],
            ],
            [
              [-6.446397, 43.562631],
              [-6.446335, 43.562831],
              [-6.446538, 43.562924],
              [-6.446626, 43.562723],
              [-6.446397, 43.562631],
            ],
            [
              [-6.454314, 43.564013],
              [-6.454127, 43.564207],
              [-6.454085, 43.564335],
              [-6.454444, 43.564117],
              [-6.454314, 43.564013],
            ],
            [
              [-6.762632, 43.566467],
              [-6.762404, 43.56679],
              [-6.762434, 43.566861],
              [-6.762672, 43.566709],
              [-6.762632, 43.566467],
            ],
            [
              [-5.615697, 43.557339],
              [-5.61567, 43.557556],
              [-5.615868, 43.55756],
              [-5.615936, 43.557415],
              [-5.615697, 43.557339],
            ],
            [
              [-6.01256, 43.582966],
              [-6.012443, 43.583086],
              [-6.012562, 43.583254],
              [-6.012736, 43.583006],
              [-6.01256, 43.582966],
            ],
            [
              [-4.913397, 43.454561],
              [-4.913441, 43.454794],
              [-4.91365, 43.454746],
              [-4.913606, 43.45454],
              [-4.913397, 43.454561],
            ],
            [
              [-6.45399, 43.565067],
              [-6.453999, 43.565211],
              [-6.454349, 43.565056],
              [-6.454207, 43.564952],
              [-6.45399, 43.565067],
            ],
            [
              [-6.149017, 43.565438],
              [-6.149006, 43.565717],
              [-6.149143, 43.565731],
              [-6.149179, 43.56546],
              [-6.149017, 43.565438],
            ],
            [
              [-6.008909, 43.581018],
              [-6.009053, 43.581186],
              [-6.009245, 43.581055],
              [-6.009191, 43.580966],
              [-6.008909, 43.581018],
            ],
            [
              [-6.451233, 43.563178],
              [-6.451219, 43.563377],
              [-6.451448, 43.563271],
              [-6.451418, 43.563173],
              [-6.451233, 43.563178],
            ],
            [
              [-6.455808, 43.564814],
              [-6.45569, 43.564907],
              [-6.456076, 43.564941],
              [-6.456058, 43.564842],
              [-6.455808, 43.564814],
            ],
            [
              [-6.756583, 43.564767],
              [-6.756532, 43.564948],
              [-6.756549, 43.565029],
              [-6.756715, 43.564897],
              [-6.756583, 43.564767],
            ],
            [
              [-6.455017, 43.565054],
              [-6.455088, 43.565214],
              [-6.455294, 43.565135],
              [-6.455227, 43.565038],
              [-6.455017, 43.565054],
            ],
            [
              [-6.935712, 43.570704],
              [-6.93562, 43.570815],
              [-6.935965, 43.570785],
              [-6.935935, 43.570696],
              [-6.935712, 43.570704],
            ],
            [
              [-6.448571, 43.562962],
              [-6.448675, 43.563058],
              [-6.44874, 43.562885],
              [-6.448614, 43.562852],
              [-6.448571, 43.562962],
            ],
            [
              [-6.016558, 43.58539],
              [-6.016488, 43.585473],
              [-6.016431, 43.585565],
              [-6.01665, 43.585496],
              [-6.016558, 43.58539],
            ],
            [
              [-6.456014, 43.564519],
              [-6.456109, 43.564652],
              [-6.456206, 43.564622],
              [-6.456161, 43.564488],
              [-6.456014, 43.564519],
            ],
            [
              [-6.450786, 43.563588],
              [-6.450925, 43.563647],
              [-6.451044, 43.563553],
              [-6.450916, 43.563485],
              [-6.450786, 43.563588],
            ],
            [
              [-6.451984, 43.562453],
              [-6.452013, 43.562543],
              [-6.452235, 43.562518],
              [-6.452118, 43.562413],
              [-6.451984, 43.562453],
            ],
            [
              [-6.455451, 43.564626],
              [-6.45547, 43.564743],
              [-6.455623, 43.564612],
              [-6.455557, 43.564533],
              [-6.455451, 43.564626],
            ],
            [
              [-6.44738, 43.562268],
              [-6.447456, 43.562302],
              [-6.447582, 43.562127],
              [-6.447469, 43.562095],
              [-6.44738, 43.562268],
            ],
            [
              [-6.451241, 43.562674],
              [-6.451175, 43.562811],
              [-6.451336, 43.562815],
              [-6.451378, 43.562697],
              [-6.451241, 43.562674],
            ],
            [
              [-6.451631, 43.56214],
              [-6.451588, 43.562249],
              [-6.451772, 43.562217],
              [-6.451754, 43.562118],
              [-6.451631, 43.56214],
            ],
            [
              [-6.453436, 43.563607],
              [-6.453467, 43.563705],
              [-6.453591, 43.563719],
              [-6.45361, 43.563611],
              [-6.453436, 43.563607],
            ],
            [
              [-6.453415, 43.563878],
              [-6.453493, 43.563956],
              [-6.453591, 43.563926],
              [-6.453522, 43.563802],
              [-6.453415, 43.563878],
            ],
            [
              [-6.455737, 43.564654],
              [-6.45568, 43.564728],
              [-6.45577, 43.564797],
              [-6.455838, 43.564687],
              [-6.455737, 43.564654],
            ],
            [
              [-6.451582, 43.562358],
              [-6.45166, 43.562427],
              [-6.45177, 43.562397],
              [-6.451704, 43.562318],
              [-6.451582, 43.562358],
            ],
            [
              [-6.456224, 43.565584],
              [-6.456302, 43.565654],
              [-6.4564, 43.565633],
              [-6.456345, 43.565545],
              [-6.456224, 43.565584],
            ],
            [
              [-6.456048, 43.56532],
              [-6.456139, 43.565398],
              [-6.456209, 43.565324],
              [-6.456183, 43.565298],
              [-6.456048, 43.56532],
            ],
            [
              [-6.455844, 43.564579],
              [-6.455888, 43.564685],
              [-6.455972, 43.564638],
              [-6.45597, 43.564602],
              [-6.455844, 43.564579],
            ],
            [
              [-6.456187, 43.565162],
              [-6.456266, 43.565232],
              [-6.456336, 43.565158],
              [-6.456309, 43.565132],
              [-6.456187, 43.565162],
            ],
            [
              [-6.119754, 43.556976],
              [-6.119879, 43.557],
              [-6.1197, 43.556887],
              [-6.119754, 43.556976],
            ],
            [
              [-6.452349, 43.563424],
              [-6.452402, 43.563494],
              [-6.452486, 43.563447],
              [-6.452395, 43.563368],
              [-6.452349, 43.563424],
            ],
            [
              [-6.455752, 43.565563],
              [-6.455817, 43.565615],
              [-6.455849, 43.565524],
              [-6.455762, 43.565517],
              [-6.455752, 43.565563],
            ],
            [
              [-6.456036, 43.565113],
              [-6.4561, 43.565147],
              [-6.456146, 43.565083],
              [-6.456132, 43.565056],
              [-6.456036, 43.565113],
            ],
          ],
          [
            [
              [-6.030876, 43.592025],
              [-6.031712, 43.589194],
              [-6.034767, 43.588861],
              [-6.033535, 43.591442],
              [-6.030876, 43.592025],
            ],
          ],
          [
            [
              [-4.788229, 43.435391],
              [-4.789331, 43.432951],
              [-4.791349, 43.433919],
              [-4.791233, 43.435425],
              [-4.788229, 43.435391],
            ],
          ],
          [
            [
              [-4.574323, 43.398844],
              [-4.57405, 43.39783],
              [-4.576473, 43.397968],
              [-4.575368, 43.400117],
              [-4.574323, 43.398844],
            ],
          ],
          [
            [
              [-5.863134, 43.663799],
              [-5.863596, 43.661788],
              [-5.865976, 43.663322],
              [-5.863603, 43.664543],
              [-5.863134, 43.663799],
            ],
          ],
          [
            [
              [-4.810627, 43.438577],
              [-4.80851, 43.438449],
              [-4.808364, 43.437685],
              [-4.811168, 43.437245],
              [-4.810627, 43.438577],
            ],
          ],
          [
            [
              [-6.985221, 43.561161],
              [-6.986054, 43.559719],
              [-6.987761, 43.559506],
              [-6.987101, 43.561141],
              [-6.985221, 43.561161],
            ],
          ],
          [
            [
              [-6.753338, 43.56635],
              [-6.752234, 43.567512],
              [-6.751361, 43.567216],
              [-6.752319, 43.564691],
              [-6.753338, 43.56635],
            ],
          ],
          [
            [
              [-6.987302, 43.558937],
              [-6.987448, 43.55795],
              [-6.989481, 43.558366],
              [-6.988957, 43.559248],
              [-6.987302, 43.558937],
            ],
          ],
          [
            [
              [-6.658755, 43.568854],
              [-6.659636, 43.567664],
              [-6.661338, 43.568771],
              [-6.659309, 43.569412],
              [-6.658755, 43.568854],
            ],
          ],
          [
            [
              [-6.29306, 43.572071],
              [-6.29227, 43.571896],
              [-6.295028, 43.571817],
              [-6.292592, 43.572805],
              [-6.29306, 43.572071],
            ],
          ],
          [
            [
              [-6.191918, 43.580606],
              [-6.193492, 43.580868],
              [-6.193041, 43.581943],
              [-6.191748, 43.582088],
              [-6.191918, 43.580606],
            ],
          ],
          [
            [
              [-4.730258, 43.418643],
              [-4.730476, 43.417172],
              [-4.731609, 43.417497],
              [-4.7316, 43.418478],
              [-4.730258, 43.418643],
            ],
          ],
          [
            [
              [-6.41653, 43.560603],
              [-6.417639, 43.558976],
              [-6.418706, 43.559889],
              [-6.417309, 43.560804],
              [-6.41653, 43.560603],
            ],
          ],
          [
            [
              [-6.874157, 43.567426],
              [-6.873049, 43.56696],
              [-6.874341, 43.565863],
              [-6.874922, 43.566626],
              [-6.874157, 43.567426],
            ],
          ],
          [
            [
              [-4.823201, 43.437144],
              [-4.823819, 43.438386],
              [-4.822495, 43.43874],
              [-4.821949, 43.438271],
              [-4.823201, 43.437144],
            ],
          ],
          [
            [
              [-4.824906, 43.438395],
              [-4.826872, 43.43759],
              [-4.827334, 43.438176],
              [-4.826652, 43.438908],
              [-4.824906, 43.438395],
            ],
          ],
          [
            [
              [-6.096068, 43.559106],
              [-6.096947, 43.559352],
              [-6.095985, 43.560873],
              [-6.09523, 43.560389],
              [-6.096068, 43.559106],
            ],
          ],
          [
            [
              [-6.243892, 43.596302],
              [-6.242731, 43.596197],
              [-6.243098, 43.595198],
              [-6.244623, 43.595481],
              [-6.243892, 43.596302],
            ],
          ],
          [
            [
              [-5.765445, 43.605565],
              [-5.76678, 43.605497],
              [-5.767739, 43.607239],
              [-5.766653, 43.606248],
              [-5.765445, 43.605565],
            ],
          ],
          [
            [
              [-4.775351, 43.432746],
              [-4.776364, 43.432766],
              [-4.776631, 43.433005],
              [-4.775235, 43.433855],
              [-4.775351, 43.432746],
            ],
          ],
          [
            [
              [-4.708375, 43.415766],
              [-4.708674, 43.414978],
              [-4.710217, 43.414946],
              [-4.709454, 43.415489],
              [-4.708375, 43.415766],
            ],
          ],
          [
            [
              [-4.796314, 43.433445],
              [-4.797781, 43.434143],
              [-4.798076, 43.434534],
              [-4.79634, 43.434318],
              [-4.796314, 43.433445],
            ],
          ],
          [
            [
              [-6.226939, 43.591467],
              [-6.226302, 43.590877],
              [-6.226741, 43.589931],
              [-6.227376, 43.591123],
              [-6.226939, 43.591467],
            ],
          ],
          [
            [
              [-6.949116, 43.569844],
              [-6.950108, 43.569675],
              [-6.950596, 43.570693],
              [-6.949863, 43.570665],
              [-6.949116, 43.569844],
            ],
          ],
          [
            [
              [-6.870899, 43.569148],
              [-6.870195, 43.569172],
              [-6.870732, 43.568083],
              [-6.871353, 43.568314],
              [-6.870899, 43.569148],
            ],
          ],
          [
            [
              [-6.068664, 43.567544],
              [-6.07413, 43.568846],
              [-6.074362, 43.569038],
              [-6.069601, 43.567942],
              [-6.068664, 43.567544],
            ],
          ],
          [
            [
              [-6.231634, 43.591433],
              [-6.232509, 43.59165],
              [-6.23205, 43.592735],
              [-6.231359, 43.592394],
              [-6.231634, 43.591433],
            ],
          ],
          [
            [
              [-5.85547, 43.656761],
              [-5.856296, 43.657712],
              [-5.855791, 43.65831],
              [-5.855382, 43.657519],
              [-5.85547, 43.656761],
            ],
          ],
          [
            [
              [-6.765457, 43.569876],
              [-6.764866, 43.569932],
              [-6.764542, 43.569294],
              [-6.765702, 43.569229],
              [-6.765457, 43.569876],
            ],
          ],
          [
            [
              [-6.890753, 43.570562],
              [-6.891211, 43.570177],
              [-6.889874, 43.571717],
              [-6.889933, 43.571094],
              [-6.890753, 43.570562],
            ],
          ],
          [
            [
              [-6.7664, 43.57368],
              [-6.767977, 43.57434],
              [-6.768223, 43.57471],
              [-6.766204, 43.573903],
              [-6.7664, 43.57368],
            ],
          ],
          [
            [
              [-6.461415, 43.570388],
              [-6.461798, 43.569728],
              [-6.462431, 43.570177],
              [-6.461726, 43.570631],
              [-6.461415, 43.570388],
            ],
          ],
          [
            [
              [-6.485799, 43.552612],
              [-6.485632, 43.55187],
              [-6.486575, 43.551904],
              [-6.48648, 43.552402],
              [-6.485799, 43.552612],
            ],
          ],
          [
            [
              [-4.737369, 43.416743],
              [-4.738033, 43.417066],
              [-4.738375, 43.417826],
              [-4.737712, 43.417953],
              [-4.737369, 43.416743],
            ],
          ],
          [
            [
              [-6.320588, 43.564405],
              [-6.320678, 43.563574],
              [-6.321433, 43.563786],
              [-6.320812, 43.564875],
              [-6.320588, 43.564405],
            ],
          ],
          [
            [
              [-6.380027, 43.558538],
              [-6.379885, 43.557767],
              [-6.38068, 43.557609],
              [-6.380774, 43.558614],
              [-6.380027, 43.558538],
            ],
          ],
          [
            [
              [-7.019118, 43.559648],
              [-7.018433, 43.559024],
              [-7.020134, 43.559108],
              [-7.019599, 43.559622],
              [-7.019118, 43.559648],
            ],
          ],
          [
            [
              [-6.873103, 43.565481],
              [-6.873891, 43.565599],
              [-6.87308, 43.566473],
              [-6.872838, 43.565787],
              [-6.873103, 43.565481],
            ],
          ],
          [
            [
              [-4.858981, 43.445878],
              [-4.859938, 43.44607],
              [-4.860119, 43.446328],
              [-4.859385, 43.446574],
              [-4.858981, 43.445878],
            ],
          ],
          [
            [
              [-6.307631, 43.568598],
              [-6.308399, 43.569278],
              [-6.308461, 43.569745],
              [-6.307654, 43.569246],
              [-6.307631, 43.568598],
            ],
          ],
          [
            [
              [-6.947072, 43.573795],
              [-6.947689, 43.574143],
              [-6.946856, 43.574856],
              [-6.946755, 43.574067],
              [-6.947072, 43.573795],
            ],
          ],
          [
            [
              [-6.95748, 43.565197],
              [-6.957508, 43.564683],
              [-6.958483, 43.56464],
              [-6.958094, 43.565131],
              [-6.95748, 43.565197],
            ],
          ],
          [
            [
              [-6.87524, 43.56675],
              [-6.875754, 43.567048],
              [-6.874521, 43.5679],
              [-6.87449, 43.567613],
              [-6.87524, 43.56675],
            ],
          ],
          [
            [
              [-6.032226, 43.592773],
              [-6.032607, 43.591709],
              [-6.033226, 43.591702],
              [-6.032664, 43.592608],
              [-6.032226, 43.592773],
            ],
          ],
          [
            [
              [-4.72926, 43.416542],
              [-4.729152, 43.417093],
              [-4.728211, 43.417035],
              [-4.72857, 43.416624],
              [-4.72926, 43.416542],
            ],
          ],
          [
            [
              [-6.399154, 43.564677],
              [-6.399353, 43.565131],
              [-6.398581, 43.565496],
              [-6.398528, 43.564993],
              [-6.399154, 43.564677],
            ],
          ],
          [
            [
              [-6.271919, 43.583681],
              [-6.271394, 43.583813],
              [-6.271131, 43.583767],
              [-6.272611, 43.583418],
              [-6.271919, 43.583681],
            ],
          ],
          [
            [
              [-6.400416, 43.565324],
              [-6.400947, 43.565515],
              [-6.401009, 43.565964],
              [-6.400069, 43.565767],
              [-6.400416, 43.565324],
            ],
          ],
          [
            [
              [-6.28406, 43.572808],
              [-6.284537, 43.572929],
              [-6.283904, 43.573578],
              [-6.283623, 43.573189],
              [-6.28406, 43.572808],
            ],
          ],
          [
            [
              [-6.280256, 43.575267],
              [-6.280777, 43.574829],
              [-6.281278, 43.574941],
              [-6.280537, 43.575646],
              [-6.280256, 43.575267],
            ],
          ],
          [
            [
              [-6.890555, 43.569984],
              [-6.890933, 43.569322],
              [-6.891531, 43.569383],
              [-6.891404, 43.56991],
              [-6.890555, 43.569984],
            ],
          ],
          [
            [
              [-6.378969, 43.559109],
              [-6.379613, 43.559117],
              [-6.379336, 43.559692],
              [-6.378916, 43.559714],
              [-6.378969, 43.559109],
            ],
          ],
          [
            [
              [-5.743475, 43.581002],
              [-5.743863, 43.58138],
              [-5.742905, 43.581556],
              [-5.742927, 43.581222],
              [-5.743475, 43.581002],
            ],
          ],
          [
            [
              [-6.513162, 43.552484],
              [-6.513849, 43.55195],
              [-6.514143, 43.552112],
              [-6.512824, 43.552846],
              [-6.513162, 43.552484],
            ],
          ],
          [
            [
              [-6.267015, 43.579122],
              [-6.267639, 43.579203],
              [-6.267898, 43.579871],
              [-6.267347, 43.579761],
              [-6.267015, 43.579122],
            ],
          ],
          [
            [
              [-6.794408, 43.560022],
              [-6.794765, 43.56019],
              [-6.794936, 43.560743],
              [-6.794203, 43.560515],
              [-6.794408, 43.560022],
            ],
          ],
          [
            [
              [-6.948971, 43.576143],
              [-6.949452, 43.575532],
              [-6.94993, 43.57565],
              [-6.949639, 43.576128],
              [-6.948971, 43.576143],
            ],
          ],
          [
            [
              [-4.949386, 43.462165],
              [-4.948986, 43.462028],
              [-4.949691, 43.461259],
              [-4.949853, 43.461698],
              [-4.949386, 43.462165],
            ],
          ],
          [
            [
              [-6.339419, 43.559903],
              [-6.339462, 43.560442],
              [-6.338814, 43.560371],
              [-6.338732, 43.560229],
              [-6.339419, 43.559903],
            ],
          ],
          [
            [
              [-4.803918, 43.436117],
              [-4.803135, 43.435958],
              [-4.803172, 43.435516],
              [-4.803982, 43.435756],
              [-4.803918, 43.436117],
            ],
          ],
          [
            [
              [-6.419112, 43.558752],
              [-6.418791, 43.559418],
              [-6.418172, 43.559203],
              [-6.418181, 43.55914],
              [-6.419112, 43.558752],
            ],
          ],
          [
            [
              [-6.409703, 43.558097],
              [-6.41067, 43.558113],
              [-6.410986, 43.558238],
              [-6.409359, 43.558368],
              [-6.409703, 43.558097],
            ],
          ],
          [
            [
              [-6.328195, 43.562248],
              [-6.328667, 43.562486],
              [-6.328172, 43.562941],
              [-6.327898, 43.562688],
              [-6.328195, 43.562248],
            ],
          ],
          [
            [
              [-4.728902, 43.417421],
              [-4.729639, 43.417292],
              [-4.730025, 43.417395],
              [-4.729382, 43.417792],
              [-4.728902, 43.417421],
            ],
          ],
          [
            [
              [-6.27059, 43.584287],
              [-6.271241, 43.584196],
              [-6.271363, 43.584607],
              [-6.270519, 43.584811],
              [-6.27059, 43.584287],
            ],
          ],
          [
            [
              [-4.818928, 43.439301],
              [-4.819472, 43.43931],
              [-4.819537, 43.439831],
              [-4.818984, 43.439948],
              [-4.818928, 43.439301],
            ],
          ],
          [
            [
              [-6.876251, 43.563799],
              [-6.876872, 43.564012],
              [-6.876983, 43.564197],
              [-6.876405, 43.56446],
              [-6.876251, 43.563799],
            ],
          ],
          [
            [
              [-6.805885, 43.559442],
              [-6.806463, 43.559774],
              [-6.805955, 43.560349],
              [-6.8058, 43.560255],
              [-6.805885, 43.559442],
            ],
          ],
          [
            [
              [-6.168619, 43.569955],
              [-6.168727, 43.569403],
              [-6.169326, 43.569495],
              [-6.1693, 43.569955],
              [-6.168619, 43.569955],
            ],
          ],
          [
            [
              [-6.359763, 43.553706],
              [-6.360282, 43.553691],
              [-6.3605, 43.55382],
              [-6.359798, 43.554327],
              [-6.359763, 43.553706],
            ],
          ],
          [
            [
              [-6.79228, 43.559131],
              [-6.791781, 43.559085],
              [-6.792675, 43.558722],
              [-6.792628, 43.559156],
              [-6.79228, 43.559131],
            ],
          ],
          [
            [
              [-6.233508, 43.592037],
              [-6.233937, 43.591155],
              [-6.234284, 43.591173],
              [-6.234026, 43.591943],
              [-6.233508, 43.592037],
            ],
          ],
          [
            [
              [-6.816558, 43.563965],
              [-6.816811, 43.564245],
              [-6.81592, 43.564644],
              [-6.81599, 43.56438],
              [-6.816558, 43.563965],
            ],
          ],
          [
            [
              [-6.325371, 43.562897],
              [-6.32588, 43.563143],
              [-6.324938, 43.563351],
              [-6.325163, 43.562948],
              [-6.325371, 43.562897],
            ],
          ],
          [
            [
              [-4.62324, 43.402212],
              [-4.62336, 43.402093],
              [-4.624638, 43.4023],
              [-4.624001, 43.402507],
              [-4.62324, 43.402212],
            ],
          ],
          [
            [
              [-6.302413, 43.565796],
              [-6.30274, 43.565895],
              [-6.301686, 43.566303],
              [-6.301818, 43.566002],
              [-6.302413, 43.565796],
            ],
          ],
          [
            [
              [-6.475486, 43.564216],
              [-6.475991, 43.564165],
              [-6.476269, 43.564481],
              [-6.475747, 43.564668],
              [-6.475486, 43.564216],
            ],
          ],
          [
            [
              [-6.400686, 43.558239],
              [-6.401012, 43.558329],
              [-6.399922, 43.558973],
              [-6.399961, 43.558792],
              [-6.400686, 43.558239],
            ],
          ],
          [
            [
              [-6.400022, 43.564922],
              [-6.400034, 43.565354],
              [-6.399359, 43.565689],
              [-6.399546, 43.56526],
              [-6.400022, 43.564922],
            ],
          ],
          [
            [
              [-6.331954, 43.561931],
              [-6.332587, 43.561732],
              [-6.332856, 43.561896],
              [-6.332159, 43.562276],
              [-6.331954, 43.561931],
            ],
          ],
          [
            [
              [-6.472515, 43.571122],
              [-6.472019, 43.571102],
              [-6.471838, 43.570972],
              [-6.472906, 43.571021],
              [-6.472515, 43.571122],
            ],
          ],
          [
            [
              [-4.84794, 43.446129],
              [-4.847903, 43.445734],
              [-4.848846, 43.445827],
              [-4.848669, 43.446109],
              [-4.84794, 43.446129],
            ],
          ],
          [
            [
              [-6.295, 43.572448],
              [-6.295404, 43.572805],
              [-6.294756, 43.572959],
              [-6.294665, 43.572655],
              [-6.295, 43.572448],
            ],
          ],
          [
            [
              [-5.784064, 43.611407],
              [-5.784347, 43.611625],
              [-5.783396, 43.611954],
              [-5.783664, 43.611597],
              [-5.784064, 43.611407],
            ],
          ],
          [
            [
              [-6.508009, 43.550725],
              [-6.507709, 43.55132],
              [-6.506988, 43.551486],
              [-6.507709, 43.550896],
              [-6.508009, 43.550725],
            ],
          ],
          [
            [
              [-6.491631, 43.549651],
              [-6.491867, 43.550085],
              [-6.491634, 43.550336],
              [-6.491191, 43.549962],
              [-6.491631, 43.549651],
            ],
          ],
          [
            [
              [-5.837859, 43.664366],
              [-5.838262, 43.663969],
              [-5.838452, 43.664324],
              [-5.837912, 43.664689],
              [-5.837859, 43.664366],
            ],
          ],
          [
            [
              [-6.913287, 43.572042],
              [-6.91335, 43.571689],
              [-6.913775, 43.571935],
              [-6.913269, 43.572349],
              [-6.913287, 43.572042],
            ],
          ],
          [
            [
              [-6.6258, 43.575163],
              [-6.62568, 43.5746],
              [-6.626454, 43.575124],
              [-6.62599, 43.575229],
              [-6.6258, 43.575163],
            ],
          ],
          [
            [
              [-6.93853, 43.571759],
              [-6.938918, 43.572015],
              [-6.938168, 43.572284],
              [-6.938066, 43.572054],
              [-6.93853, 43.571759],
            ],
          ],
          [
            [
              [-6.307591, 43.567411],
              [-6.308001, 43.567669],
              [-6.307896, 43.568005],
              [-6.307417, 43.567857],
              [-6.307591, 43.567411],
            ],
          ],
          [
            [
              [-6.358894, 43.552985],
              [-6.359192, 43.552571],
              [-6.359836, 43.552786],
              [-6.359476, 43.553211],
              [-6.358894, 43.552985],
            ],
          ],
          [
            [
              [-6.851692, 43.565432],
              [-6.85194, 43.565631],
              [-6.850982, 43.566338],
              [-6.851443, 43.565602],
              [-6.851692, 43.565432],
            ],
          ],
          [
            [
              [-6.499334, 43.551838],
              [-6.499785, 43.5515],
              [-6.500389, 43.551463],
              [-6.499812, 43.551976],
              [-6.499334, 43.551838],
            ],
          ],
          [
            [
              [-4.775661, 43.434056],
              [-4.776149, 43.434255],
              [-4.776106, 43.434481],
              [-4.775372, 43.43433],
              [-4.775661, 43.434056],
            ],
          ],
          [
            [
              [-6.424851, 43.554942],
              [-6.425062, 43.554954],
              [-6.424971, 43.555749],
              [-6.424478, 43.555341],
              [-6.424851, 43.554942],
            ],
          ],
          [
            [
              [-6.935619, 43.571553],
              [-6.936067, 43.571781],
              [-6.935632, 43.572138],
              [-6.935314, 43.571816],
              [-6.935619, 43.571553],
            ],
          ],
          [
            [
              [-5.22211, 43.480186],
              [-5.222359, 43.480226],
              [-5.22261, 43.480662],
              [-5.221834, 43.480731],
              [-5.22211, 43.480186],
            ],
          ],
          [
            [
              [-6.357842, 43.555437],
              [-6.357943, 43.555921],
              [-6.357427, 43.55599],
              [-6.357461, 43.55571],
              [-6.357842, 43.555437],
            ],
          ],
          [
            [
              [-6.782172, 43.565704],
              [-6.782465, 43.565631],
              [-6.783122, 43.565844],
              [-6.78258, 43.566087],
              [-6.782172, 43.565704],
            ],
          ],
          [
            [
              [-6.191178, 43.581149],
              [-6.191397, 43.581773],
              [-6.191148, 43.581978],
              [-6.190887, 43.581499],
              [-6.191178, 43.581149],
            ],
          ],
          [
            [
              [-6.497767, 43.554074],
              [-6.498197, 43.554222],
              [-6.498188, 43.554493],
              [-6.497676, 43.554635],
              [-6.497767, 43.554074],
            ],
          ],
          [
            [
              [-6.412079, 43.558305],
              [-6.412509, 43.55867],
              [-6.412194, 43.559013],
              [-6.411967, 43.558938],
              [-6.412079, 43.558305],
            ],
          ],
          [
            [
              [-6.472833, 43.570618],
              [-6.47326, 43.570506],
              [-6.473755, 43.570932],
              [-6.472944, 43.570821],
              [-6.472833, 43.570618],
            ],
          ],
          [
            [
              [-4.825151, 43.440381],
              [-4.825373, 43.440369],
              [-4.825654, 43.440652],
              [-4.824842, 43.440764],
              [-4.825151, 43.440381],
            ],
          ],
          [
            [
              [-6.316853, 43.563802],
              [-6.31722, 43.563953],
              [-6.317437, 43.564289],
              [-6.316943, 43.564313],
              [-6.316853, 43.563802],
            ],
          ],
          [
            [
              [-6.792514, 43.561012],
              [-6.792217, 43.560626],
              [-6.792809, 43.560399],
              [-6.79297, 43.56079],
              [-6.792514, 43.561012],
            ],
          ],
          [
            [
              [-6.78327, 43.565632],
              [-6.783981, 43.565716],
              [-6.784262, 43.56586],
              [-6.783595, 43.566071],
              [-6.78327, 43.565632],
            ],
          ],
          [
            [
              [-6.88582, 43.567804],
              [-6.886152, 43.567955],
              [-6.885224, 43.568356],
              [-6.885504, 43.567887],
              [-6.88582, 43.567804],
            ],
          ],
          [
            [
              [-4.830444, 43.440108],
              [-4.83068, 43.440149],
              [-4.830953, 43.440568],
              [-4.830246, 43.440507],
              [-4.830444, 43.440108],
            ],
          ],
          [
            [
              [-6.482607, 43.554159],
              [-6.483103, 43.554387],
              [-6.482647, 43.554842],
              [-6.482406, 43.55475],
              [-6.482607, 43.554159],
            ],
          ],
          [
            [
              [-6.87668, 43.563343],
              [-6.877087, 43.563509],
              [-6.876992, 43.563963],
              [-6.876523, 43.563781],
              [-6.87668, 43.563343],
            ],
          ],
          [
            [
              [-6.987965, 43.562038],
              [-6.988441, 43.562111],
              [-6.988671, 43.562598],
              [-6.987941, 43.562228],
              [-6.987965, 43.562038],
            ],
          ],
          [
            [
              [-6.340979, 43.560119],
              [-6.341454, 43.559979],
              [-6.341632, 43.560289],
              [-6.34121, 43.56049],
              [-6.340979, 43.560119],
            ],
          ],
          [
            [
              [-6.13817, 43.565196],
              [-6.13758, 43.56505],
              [-6.13755, 43.564952],
              [-6.138124, 43.564783],
              [-6.13817, 43.565196],
            ],
          ],
          [
            [
              [-5.779492, 43.613013],
              [-5.779939, 43.613047],
              [-5.780785, 43.613657],
              [-5.780651, 43.613705],
              [-5.779492, 43.613013],
            ],
          ],
          [
            [
              [-6.475427, 43.55652],
              [-6.475067, 43.556513],
              [-6.475906, 43.556029],
              [-6.475903, 43.556407],
              [-6.475427, 43.55652],
            ],
          ],
          [
            [
              [-6.787454, 43.566411],
              [-6.787744, 43.566492],
              [-6.787871, 43.566731],
              [-6.787271, 43.566841],
              [-6.787454, 43.566411],
            ],
          ],
          [
            [
              [-6.771564, 43.567928],
              [-6.772278, 43.568066],
              [-6.772426, 43.568251],
              [-6.77182, 43.568262],
              [-6.771564, 43.567928],
            ],
          ],
          [
            [
              [-6.2919, 43.573951],
              [-6.29225, 43.574238],
              [-6.291678, 43.574434],
              [-6.29162, 43.574274],
              [-6.2919, 43.573951],
            ],
          ],
          [
            [
              [-6.751321, 43.567569],
              [-6.751722, 43.567844],
              [-6.751636, 43.56845],
              [-6.751468, 43.568338],
              [-6.751321, 43.567569],
            ],
          ],
          [
            [
              [-6.704744, 43.563418],
              [-6.705227, 43.563609],
              [-6.704489, 43.563894],
              [-6.704467, 43.563733],
              [-6.704744, 43.563418],
            ],
          ],
          [
            [
              [-6.444644, 43.563359],
              [-6.444853, 43.563344],
              [-6.44376, 43.564142],
              [-6.444166, 43.563671],
              [-6.444644, 43.563359],
            ],
          ],
          [
            [
              [-5.864985, 43.66413],
              [-5.865313, 43.664248],
              [-5.864758, 43.664829],
              [-5.864692, 43.66475],
              [-5.864985, 43.66413],
            ],
          ],
          [
            [
              [-5.861341, 43.666013],
              [-5.861509, 43.666414],
              [-5.860991, 43.666481],
              [-5.861052, 43.666183],
              [-5.861341, 43.666013],
            ],
          ],
          [
            [
              [-6.852584, 43.567382],
              [-6.852921, 43.567425],
              [-6.853095, 43.567626],
              [-6.852552, 43.567852],
              [-6.852584, 43.567382],
            ],
          ],
          [
            [
              [-6.384524, 43.557261],
              [-6.384884, 43.557502],
              [-6.384679, 43.557824],
              [-6.384398, 43.557679],
              [-6.384524, 43.557261],
            ],
          ],
          [
            [
              [-6.509101, 43.549701],
              [-6.509315, 43.549776],
              [-6.508324, 43.550391],
              [-6.508707, 43.549965],
              [-6.509101, 43.549701],
            ],
          ],
          [
            [
              [-6.149852, 43.570529],
              [-6.150174, 43.570304],
              [-6.150269, 43.570922],
              [-6.149859, 43.570898],
              [-6.149852, 43.570529],
            ],
          ],
          [
            [
              [-6.856292, 43.568635],
              [-6.856799, 43.568627],
              [-6.856891, 43.568713],
              [-6.856368, 43.569055],
              [-6.856292, 43.568635],
            ],
          ],
          [
            [
              [-6.658849, 43.567743],
              [-6.659144, 43.567302],
              [-6.659564, 43.567495],
              [-6.659463, 43.567669],
              [-6.658849, 43.567743],
            ],
          ],
          [
            [
              [-6.382133, 43.557458],
              [-6.38239, 43.55763],
              [-6.381835, 43.55789],
              [-6.381703, 43.557741],
              [-6.382133, 43.557458],
            ],
          ],
          [
            [
              [-4.55592, 43.394864],
              [-4.556365, 43.394876],
              [-4.556744, 43.395204],
              [-4.556201, 43.395229],
              [-4.55592, 43.394864],
            ],
          ],
          [
            [
              [-6.773816, 43.568304],
              [-6.774088, 43.568493],
              [-6.774226, 43.568911],
              [-6.773649, 43.568597],
              [-6.773816, 43.568304],
            ],
          ],
          [
            [
              [-6.399049, 43.559522],
              [-6.399209, 43.559724],
              [-6.398413, 43.56009],
              [-6.39857, 43.559806],
              [-6.399049, 43.559522],
            ],
          ],
          [
            [
              [-6.48283, 43.553936],
              [-6.483204, 43.553564],
              [-6.48361, 43.553516],
              [-6.483261, 43.553905],
              [-6.48283, 43.553936],
            ],
          ],
          [
            [
              [-5.889865, 43.628732],
              [-5.889981, 43.629089],
              [-5.889449, 43.629112],
              [-5.889455, 43.628967],
              [-5.889865, 43.628732],
            ],
          ],
          [
            [
              [-4.77671, 43.432761],
              [-4.777042, 43.432702],
              [-4.777528, 43.432838],
              [-4.776991, 43.433081],
              [-4.77671, 43.432761],
            ],
          ],
          [
            [
              [-6.324013, 43.563189],
              [-6.324255, 43.563317],
              [-6.324215, 43.563948],
              [-6.323914, 43.563651],
              [-6.324013, 43.563189],
            ],
          ],
          [
            [
              [-6.746935, 43.56692],
              [-6.747099, 43.566969],
              [-6.747004, 43.567629],
              [-6.746701, 43.567342],
              [-6.746935, 43.56692],
            ],
          ],
          [
            [
              [-6.871282, 43.566624],
              [-6.871497, 43.567075],
              [-6.871122, 43.567223],
              [-6.871029, 43.567127],
              [-6.871282, 43.566624],
            ],
          ],
          [
            [
              [-6.958054, 43.567536],
              [-6.958244, 43.567601],
              [-6.958395, 43.568208],
              [-6.957982, 43.567953],
              [-6.958054, 43.567536],
            ],
          ],
          [
            [
              [-6.086149, 43.563183],
              [-6.08638, 43.563339],
              [-6.086035, 43.563861],
              [-6.085848, 43.563596],
              [-6.086149, 43.563183],
            ],
          ],
          [
            [
              [-6.19075, 43.582178],
              [-6.190884, 43.582148],
              [-6.191467, 43.582158],
              [-6.191274, 43.582497],
              [-6.19075, 43.582178],
            ],
          ],
          [
            [
              [-6.764521, 43.570951],
              [-6.764839, 43.571085],
              [-6.764209, 43.571511],
              [-6.764383, 43.571109],
              [-6.764521, 43.570951],
            ],
          ],
          [
            [
              [-6.780359, 43.569167],
              [-6.781021, 43.569263],
              [-6.781181, 43.569446],
              [-6.780524, 43.569432],
              [-6.780359, 43.569167],
            ],
          ],
          [
            [
              [-6.287635, 43.572498],
              [-6.288004, 43.572451],
              [-6.288058, 43.572774],
              [-6.287668, 43.572875],
              [-6.287635, 43.572498],
            ],
          ],
          [
            [
              [-6.208626, 43.585546],
              [-6.208624, 43.584874],
              [-6.208974, 43.58519],
              [-6.208626, 43.585546],
            ],
          ],
          [
            [
              [-6.783037, 43.570402],
              [-6.78335, 43.570662],
              [-6.783252, 43.570872],
              [-6.782838, 43.570796],
              [-6.783037, 43.570402],
            ],
          ],
          [
            [
              [-6.292474, 43.574951],
              [-6.292653, 43.574829],
              [-6.292859, 43.575192],
              [-6.292396, 43.575341],
              [-6.292474, 43.574951],
            ],
          ],
          [
            [
              [-6.663199, 43.567199],
              [-6.663553, 43.567322],
              [-6.663666, 43.567553],
              [-6.663203, 43.567676],
              [-6.663199, 43.567199],
            ],
          ],
          [
            [
              [-6.32731, 43.562805],
              [-6.327633, 43.563056],
              [-6.327108, 43.56318],
              [-6.32706, 43.562992],
              [-6.32731, 43.562805],
            ],
          ],
          [
            [
              [-6.748788, 43.566049],
              [-6.748791, 43.566697],
              [-6.748586, 43.566785],
              [-6.748554, 43.566471],
              [-6.748788, 43.566049],
            ],
          ],
          [
            [
              [-6.30488, 43.564482],
              [-6.305215, 43.564499],
              [-6.305293, 43.564803],
              [-6.30485, 43.564843],
              [-6.30488, 43.564482],
            ],
          ],
          [
            [
              [-6.344743, 43.558334],
              [-6.345092, 43.558369],
              [-6.345228, 43.55859],
              [-6.344622, 43.558616],
              [-6.344743, 43.558334],
            ],
          ],
          [
            [
              [-6.80682, 43.559744],
              [-6.806764, 43.55944],
              [-6.807461, 43.559497],
              [-6.807396, 43.559644],
              [-6.80682, 43.559744],
            ],
          ],
          [
            [
              [-4.575603, 43.395369],
              [-4.5758, 43.395807],
              [-4.575492, 43.395838],
              [-4.575361, 43.395525],
              [-4.575603, 43.395369],
            ],
          ],
          [
            [
              [-4.76277, 43.425863],
              [-4.763435, 43.425772],
              [-4.763909, 43.425936],
              [-4.763578, 43.426013],
              [-4.76277, 43.425863],
            ],
          ],
          [
            [
              [-6.475657, 43.57127],
              [-6.476292, 43.571341],
              [-6.476499, 43.571496],
              [-6.476222, 43.571631],
              [-6.475657, 43.57127],
            ],
          ],
          [
            [
              [-6.942879, 43.573877],
              [-6.943218, 43.574136],
              [-6.942911, 43.574362],
              [-6.942718, 43.574063],
              [-6.942879, 43.573877],
            ],
          ],
          [
            [
              [-6.807875, 43.558601],
              [-6.808481, 43.558401],
              [-6.80866, 43.558485],
              [-6.807819, 43.5589],
              [-6.807875, 43.558601],
            ],
          ],
          [
            [
              [-6.398799, 43.565849],
              [-6.399222, 43.565882],
              [-6.399234, 43.566098],
              [-6.398824, 43.566299],
              [-6.398799, 43.565849],
            ],
          ],
          [
            [
              [-6.321642, 43.564887],
              [-6.322065, 43.564929],
              [-6.322212, 43.565123],
              [-6.321709, 43.5652],
              [-6.321642, 43.564887],
            ],
          ],
          [
            [
              [-6.266734, 43.581237],
              [-6.266917, 43.58143],
              [-6.266838, 43.581783],
              [-6.266483, 43.581649],
              [-6.266734, 43.581237],
            ],
          ],
          [
            [
              [-5.87979, 43.630868],
              [-5.880052, 43.630645],
              [-5.880397, 43.630601],
              [-5.880341, 43.63098],
              [-5.87979, 43.630868],
            ],
          ],
          [
            [
              [-4.846128, 43.44588],
              [-4.84662, 43.445809],
              [-4.846836, 43.445985],
              [-4.846532, 43.446143],
              [-4.846128, 43.44588],
            ],
          ],
          [
            [
              [-6.440343, 43.561337],
              [-6.440293, 43.561096],
              [-6.440874, 43.560871],
              [-6.44071, 43.561245],
              [-6.440343, 43.561337],
            ],
          ],
          [
            [
              [-6.432343, 43.554753],
              [-6.432111, 43.555246],
              [-6.431769, 43.555329],
              [-6.43202, 43.554952],
              [-6.432343, 43.554753],
            ],
          ],
          [
            [
              [-6.872583, 43.566246],
              [-6.872827, 43.566193],
              [-6.872823, 43.566715],
              [-6.872471, 43.566439],
              [-6.872583, 43.566246],
            ],
          ],
          [
            [
              [-6.391969, 43.559327],
              [-6.392181, 43.559357],
              [-6.391517, 43.559872],
              [-6.391504, 43.559656],
              [-6.391969, 43.559327],
            ],
          ],
          [
            [
              [-6.807485, 43.557723],
              [-6.807821, 43.557748],
              [-6.807836, 43.55799],
              [-6.807316, 43.557981],
              [-6.807485, 43.557723],
            ],
          ],
          [
            [
              [-6.866722, 43.57083],
              [-6.86683, 43.571159],
              [-6.866423, 43.571371],
              [-6.866424, 43.5712],
              [-6.866722, 43.57083],
            ],
          ],
          [
            [
              [-4.562645, 43.393719],
              [-4.563039, 43.393677],
              [-4.563266, 43.393881],
              [-4.562738, 43.393969],
              [-4.562645, 43.393719],
            ],
          ],
          [
            [
              [-6.891796, 43.572336],
              [-6.892004, 43.572491],
              [-6.891447, 43.572879],
              [-6.89154, 43.572588],
              [-6.891796, 43.572336],
            ],
          ],
          [
            [
              [-6.942262, 43.573728],
              [-6.942609, 43.573347],
              [-6.942931, 43.573723],
              [-6.942363, 43.573751],
              [-6.942262, 43.573728],
            ],
          ],
          [
            [
              [-6.398496, 43.560258],
              [-6.398901, 43.560399],
              [-6.398803, 43.560645],
              [-6.398496, 43.560699],
              [-6.398496, 43.560258],
            ],
          ],
          [
            [
              [-5.78651, 43.616056],
              [-5.786766, 43.616239],
              [-5.786103, 43.616372],
              [-5.786085, 43.616256],
              [-5.78651, 43.616056],
            ],
          ],
          [
            [
              [-4.782708, 43.433361],
              [-4.782443, 43.433158],
              [-4.783509, 43.433294],
              [-4.783362, 43.433342],
              [-4.782708, 43.433361],
            ],
          ],
          [
            [
              [-6.300754, 43.567375],
              [-6.300977, 43.567611],
              [-6.300503, 43.567778],
              [-6.300432, 43.567609],
              [-6.300754, 43.567375],
            ],
          ],
          [
            [
              [-6.775874, 43.568344],
              [-6.776363, 43.568444],
              [-6.776603, 43.568707],
              [-6.776088, 43.568598],
              [-6.775874, 43.568344],
            ],
          ],
          [
            [
              [-6.037377, 43.579923],
              [-6.037643, 43.57979],
              [-6.037636, 43.580393],
              [-6.037509, 43.580324],
              [-6.037377, 43.579923],
            ],
          ],
          [
            [
              [-4.565234, 43.393575],
              [-4.565604, 43.393552],
              [-4.565837, 43.393963],
              [-4.565564, 43.393931],
              [-4.565234, 43.393575],
            ],
          ],
          [
            [
              [-6.626714, 43.573882],
              [-6.62695, 43.573902],
              [-6.627209, 43.574281],
              [-6.626862, 43.574292],
              [-6.626714, 43.573882],
            ],
          ],
          [
            [
              [-6.40803, 43.557624],
              [-6.408569, 43.557743],
              [-6.408687, 43.557857],
              [-6.40822, 43.557925],
              [-6.40803, 43.557624],
            ],
          ],
          [
            [
              [-6.948422, 43.577395],
              [-6.948574, 43.577633],
              [-6.948144, 43.577882],
              [-6.948111, 43.577757],
              [-6.948422, 43.577395],
            ],
          ],
          [
            [
              [-6.803961, 43.559749],
              [-6.804221, 43.559758],
              [-6.804367, 43.559916],
              [-6.803895, 43.560075],
              [-6.803961, 43.559749],
            ],
          ],
          [
            [
              [-6.390286, 43.559566],
              [-6.390585, 43.559386],
              [-6.390739, 43.559471],
              [-6.390255, 43.559891],
              [-6.390286, 43.559566],
            ],
          ],
          [
            [
              [-4.641982, 43.404349],
              [-4.64218, 43.404346],
              [-4.642632, 43.404636],
              [-4.642188, 43.404661],
              [-4.641982, 43.404349],
            ],
          ],
          [
            [
              [-6.800447, 43.558339],
              [-6.800766, 43.558482],
              [-6.800789, 43.559048],
              [-6.800689, 43.559034],
              [-6.800447, 43.558339],
            ],
          ],
          [
            [
              [-4.845094, 43.446418],
              [-4.845325, 43.446271],
              [-4.845589, 43.446446],
              [-4.845312, 43.446676],
              [-4.845094, 43.446418],
            ],
          ],
          [
            [
              [-6.160501, 43.569586],
              [-6.160919, 43.569521],
              [-6.161033, 43.569797],
              [-6.160638, 43.569825],
              [-6.160501, 43.569586],
            ],
          ],
          [
            [
              [-6.796067, 43.558643],
              [-6.796365, 43.558661],
              [-6.795562, 43.558885],
              [-6.795606, 43.558794],
              [-6.796067, 43.558643],
            ],
          ],
          [
            [
              [-6.39221, 43.559878],
              [-6.392488, 43.559762],
              [-6.392186, 43.560329],
              [-6.392103, 43.560178],
              [-6.39221, 43.559878],
            ],
          ],
          [
            [
              [-6.794655, 43.558645],
              [-6.794942, 43.558672],
              [-6.79512, 43.558945],
              [-6.794651, 43.55897],
              [-6.794655, 43.558645],
            ],
          ],
          [
            [
              [-6.777303, 43.568224],
              [-6.777566, 43.56827],
              [-6.777462, 43.568777],
              [-6.777246, 43.568496],
              [-6.777303, 43.568224],
            ],
          ],
          [
            [
              [-6.407651, 43.559463],
              [-6.407879, 43.559339],
              [-6.408154, 43.55961],
              [-6.407826, 43.559719],
              [-6.407651, 43.559463],
            ],
          ],
          [
            [
              [-6.295205, 43.570524],
              [-6.295547, 43.570451],
              [-6.295589, 43.57054],
              [-6.295211, 43.570866],
              [-6.295205, 43.570524],
            ],
          ],
          [
            [
              [-6.833588, 43.567554],
              [-6.833879, 43.567643],
              [-6.833591, 43.567986],
              [-6.833494, 43.567827],
              [-6.833588, 43.567554],
            ],
          ],
          [
            [
              [-6.781403, 43.566864],
              [-6.781704, 43.567115],
              [-6.781644, 43.567351],
              [-6.781389, 43.567225],
              [-6.781403, 43.566864],
            ],
          ],
          [
            [
              [-6.367283, 43.552251],
              [-6.367459, 43.552516],
              [-6.367163, 43.552759],
              [-6.367122, 43.552463],
              [-6.367283, 43.552251],
            ],
          ],
          [
            [
              [-4.568305, 43.393443],
              [-4.568599, 43.393331],
              [-4.568937, 43.393497],
              [-4.568372, 43.393613],
              [-4.568305, 43.393443],
            ],
          ],
          [
            [
              [-6.166422, 43.570557],
              [-6.166682, 43.570801],
              [-6.166321, 43.570992],
              [-6.166232, 43.570706],
              [-6.166422, 43.570557],
            ],
          ],
          [
            [
              [-4.55989, 43.393675],
              [-4.559987, 43.393602],
              [-4.560401, 43.393857],
              [-4.559971, 43.393926],
              [-4.55989, 43.393675],
            ],
          ],
          [
            [
              [-7.021612, 43.560839],
              [-7.021796, 43.560823],
              [-7.022087, 43.561092],
              [-7.021655, 43.561125],
              [-7.021612, 43.560839],
            ],
          ],
          [
            [
              [-6.395486, 43.560438],
              [-6.395771, 43.560456],
              [-6.395761, 43.560718],
              [-6.395404, 43.560755],
              [-6.395486, 43.560438],
            ],
          ],
          [
            [
              [-6.433161, 43.556745],
              [-6.433394, 43.55691],
              [-6.433365, 43.55728],
              [-6.433146, 43.557133],
              [-6.433161, 43.556745],
            ],
          ],
          [
            [
              [-4.575692, 43.39688],
              [-4.575904, 43.396949],
              [-4.575081, 43.397123],
              [-4.575152, 43.397023],
              [-4.575692, 43.39688],
            ],
          ],
          [
            [
              [-6.396828, 43.560965],
              [-6.397003, 43.560789],
              [-6.397286, 43.56097],
              [-6.396777, 43.561174],
              [-6.396828, 43.560965],
            ],
          ],
          [
            [
              [-6.2954, 43.571383],
              [-6.295684, 43.571365],
              [-6.295882, 43.571585],
              [-6.29549, 43.571668],
              [-6.2954, 43.571383],
            ],
          ],
          [
            [
              [-7.022141, 43.560235],
              [-7.022436, 43.560207],
              [-7.022547, 43.560392],
              [-7.022173, 43.560531],
              [-7.022141, 43.560235],
            ],
          ],
          [
            [
              [-6.475971, 43.558656],
              [-6.476251, 43.558575],
              [-6.47643, 43.558678],
              [-6.476172, 43.558938],
              [-6.475971, 43.558656],
            ],
          ],
          [
            [
              [-6.02429, 43.587176],
              [-6.024814, 43.587],
              [-6.02497, 43.58714],
              [-6.02451, 43.587368],
              [-6.02429, 43.587176],
            ],
          ],
          [
            [
              [-4.571939, 43.394519],
              [-4.572075, 43.394517],
              [-4.572536, 43.394691],
              [-4.572156, 43.394813],
              [-4.571939, 43.394519],
            ],
          ],
          [
            [
              [-6.141953, 43.565794],
              [-6.142186, 43.565752],
              [-6.141983, 43.56637],
              [-6.141895, 43.566111],
              [-6.141953, 43.565794],
            ],
          ],
          [
            [
              [-6.80922, 43.557944],
              [-6.809588, 43.557887],
              [-6.809633, 43.558011],
              [-6.809285, 43.558194],
              [-6.80922, 43.557944],
            ],
          ],
          [
            [
              [-6.002758, 43.579632],
              [-6.003066, 43.57984],
              [-6.002868, 43.580097],
              [-6.002743, 43.580073],
              [-6.002758, 43.579632],
            ],
          ],
          [
            [
              [-6.886241, 43.567997],
              [-6.886425, 43.568153],
              [-6.886009, 43.568428],
              [-6.88599, 43.56832],
              [-6.886241, 43.567997],
            ],
          ],
          [
            [
              [-6.399398, 43.560222],
              [-6.39953, 43.560138],
              [-6.399826, 43.560336],
              [-6.399462, 43.560473],
              [-6.399398, 43.560222],
            ],
          ],
          [
            [
              [-6.410833, 43.559692],
              [-6.411071, 43.559739],
              [-6.410941, 43.560076],
              [-6.410696, 43.559904],
              [-6.410833, 43.559692],
            ],
          ],
          [
            [
              [-6.781409, 43.565765],
              [-6.781693, 43.565756],
              [-6.781803, 43.565932],
              [-6.781577, 43.566084],
              [-6.781409, 43.565765],
            ],
          ],
          [
            [
              [-6.411118, 43.559036],
              [-6.411397, 43.559153],
              [-6.411139, 43.559404],
              [-6.410982, 43.559265],
              [-6.411118, 43.559036],
            ],
          ],
          [
            [
              [-6.036944, 43.582878],
              [-6.037158, 43.582945],
              [-6.037052, 43.583317],
              [-6.036794, 43.583099],
              [-6.036944, 43.582878],
            ],
          ],
          [
            [
              [-6.035296, 43.583607],
              [-6.03566, 43.583714],
              [-6.035733, 43.583928],
              [-6.035256, 43.583797],
              [-6.035296, 43.583607],
            ],
          ],
          [
            [
              [-5.993088, 43.576932],
              [-5.993078, 43.576743],
              [-5.993522, 43.576939],
              [-5.993285, 43.57717],
              [-5.993088, 43.576932],
            ],
          ],
          [
            [
              [-6.301264, 43.567198],
              [-6.301511, 43.567182],
              [-6.301611, 43.567431],
              [-6.301229, 43.567469],
              [-6.301264, 43.567198],
            ],
          ],
          [
            [
              [-6.386214, 43.557823],
              [-6.386498, 43.557806],
              [-6.386536, 43.558039],
              [-6.386208, 43.558147],
              [-6.386214, 43.557823],
            ],
          ],
          [
            [
              [-6.380774, 43.557957],
              [-6.381114, 43.557839],
              [-6.381176, 43.558053],
              [-6.38096, 43.558186],
              [-6.380774, 43.557957],
            ],
          ],
          [
            [
              [-6.408977, 43.55883],
              [-6.409306, 43.558748],
              [-6.409381, 43.558971],
              [-6.409152, 43.559085],
              [-6.408977, 43.55883],
            ],
          ],
          [
            [
              [-6.870468, 43.567642],
              [-6.870585, 43.567728],
              [-6.870082, 43.568186],
              [-6.870015, 43.568107],
              [-6.870468, 43.567642],
            ],
          ],
          [
            [
              [-6.407013, 43.55715],
              [-6.407261, 43.55717],
              [-6.40739, 43.557256],
              [-6.406723, 43.557285],
              [-6.407013, 43.55715],
            ],
          ],
          [
            [
              [-6.499367, 43.553061],
              [-6.499633, 43.553161],
              [-6.499704, 43.553321],
              [-6.499401, 43.553429],
              [-6.499367, 43.553061],
            ],
          ],
          [
            [
              [-6.750971, 43.567715],
              [-6.75109, 43.568035],
              [-6.750828, 43.568197],
              [-6.750794, 43.568054],
              [-6.750971, 43.567715],
            ],
          ],
          [
            [
              [-7.008604, 43.55801],
              [-7.008797, 43.55812],
              [-7.008823, 43.558336],
              [-7.008383, 43.558225],
              [-7.008604, 43.55801],
            ],
          ],
          [
            [
              [-6.758662, 43.56694],
              [-6.758606, 43.566636],
              [-6.758806, 43.566476],
              [-6.758881, 43.566681],
              [-6.758662, 43.56694],
            ],
          ],
          [
            [
              [-6.808586, 43.558686],
              [-6.808638, 43.558927],
              [-6.80827, 43.558975],
              [-6.808261, 43.55884],
              [-6.808586, 43.558686],
            ],
          ],
          [
            [
              [-5.76678, 43.599014],
              [-5.767052, 43.599008],
              [-5.767328, 43.599361],
              [-5.767103, 43.599313],
              [-5.76678, 43.599014],
            ],
          ],
          [
            [
              [-6.948684, 43.576477],
              [-6.948895, 43.576676],
              [-6.948466, 43.576745],
              [-6.948611, 43.576506],
              [-6.948684, 43.576477],
            ],
          ],
          [
            [
              [-6.745064, 43.56691],
              [-6.745229, 43.566967],
              [-6.744958, 43.567381],
              [-6.74486, 43.567205],
              [-6.745064, 43.56691],
            ],
          ],
          [
            [
              [-6.877625, 43.564175],
              [-6.878082, 43.564349],
              [-6.878225, 43.564461],
              [-6.877803, 43.564439],
              [-6.877625, 43.564175],
            ],
          ],
          [
            [
              [-5.012644, 43.46042],
              [-5.012951, 43.460361],
              [-5.013128, 43.460484],
              [-5.012837, 43.460642],
              [-5.012644, 43.46042],
            ],
          ],
          [
            [
              [-6.801207, 43.556915],
              [-6.801595, 43.556974],
              [-6.801664, 43.557089],
              [-6.801186, 43.557168],
              [-6.801207, 43.556915],
            ],
          ],
          [
            [
              [-6.432878, 43.555241],
              [-6.433007, 43.555328],
              [-6.432594, 43.5557],
              [-6.432584, 43.55552],
              [-6.432878, 43.555241],
            ],
          ],
          [
            [
              [-6.816082, 43.559043],
              [-6.816347, 43.559116],
              [-6.815877, 43.559329],
              [-6.81588, 43.559167],
              [-6.816082, 43.559043],
            ],
          ],
          [
            [
              [-5.839307, 43.662187],
              [-5.839572, 43.662298],
              [-5.839086, 43.662508],
              [-5.839118, 43.662399],
              [-5.839307, 43.662187],
            ],
          ],
          [
            [
              [-6.443345, 43.56339],
              [-6.443521, 43.563222],
              [-6.443754, 43.563395],
              [-6.443529, 43.563573],
              [-6.443345, 43.56339],
            ],
          ],
          [
            [
              [-6.394375, 43.560291],
              [-6.394821, 43.560287],
              [-6.394794, 43.560467],
              [-6.394386, 43.560489],
              [-6.394375, 43.560291],
            ],
          ],
          [
            [
              [-6.770847, 43.567753],
              [-6.771107, 43.567754],
              [-6.77119, 43.567886],
              [-6.770812, 43.567989],
              [-6.770847, 43.567753],
            ],
          ],
          [
            [
              [-6.364009, 43.552807],
              [-6.364206, 43.553017],
              [-6.364124, 43.553326],
              [-6.363976, 43.553105],
              [-6.364009, 43.552807],
            ],
          ],
          [
            [
              [-6.789818, 43.558113],
              [-6.790042, 43.55833],
              [-6.789638, 43.558398],
              [-6.789654, 43.558262],
              [-6.789818, 43.558113],
            ],
          ],
          [
            [
              [-6.295863, 43.570559],
              [-6.296056, 43.570463],
              [-6.296223, 43.57081],
              [-6.295988, 43.570808],
              [-6.295863, 43.570559],
            ],
          ],
          [
            [
              [-6.308206, 43.566898],
              [-6.308464, 43.566845],
              [-6.308632, 43.566976],
              [-6.308317, 43.567111],
              [-6.308206, 43.566898],
            ],
          ],
          [
            [
              [-6.783668, 43.562107],
              [-6.784054, 43.562148],
              [-6.784123, 43.562254],
              [-6.783476, 43.562204],
              [-6.783668, 43.562107],
            ],
          ],
          [
            [
              [-6.310649, 43.567637],
              [-6.31086, 43.56764],
              [-6.310932, 43.567827],
              [-6.310576, 43.567883],
              [-6.310649, 43.567637],
            ],
          ],
          [
            [
              [-7.009995, 43.556503],
              [-7.010374, 43.556616],
              [-7.010419, 43.55674],
              [-7.009937, 43.556739],
              [-7.009995, 43.556503],
            ],
          ],
          [
            [
              [-6.783649, 43.562783],
              [-6.784021, 43.562807],
              [-6.784164, 43.562901],
              [-6.783697, 43.562971],
              [-6.783649, 43.562783],
            ],
          ],
          [
            [
              [-6.80882, 43.5573],
              [-6.80906, 43.557373],
              [-6.808489, 43.557536],
              [-6.80847, 43.557438],
              [-6.80882, 43.5573],
            ],
          ],
          [
            [
              [-6.308703, 43.56737],
              [-6.308933, 43.567507],
              [-6.308658, 43.567686],
              [-6.308528, 43.567564],
              [-6.308703, 43.56737],
            ],
          ],
          [
            [
              [-4.570638, 43.394347],
              [-4.570921, 43.394317],
              [-4.570987, 43.394496],
              [-4.570594, 43.394573],
              [-4.570638, 43.394347],
            ],
          ],
          [
            [
              [-6.339121, 43.55966],
              [-6.33927, 43.559664],
              [-6.338488, 43.560074],
              [-6.338795, 43.559804],
              [-6.339121, 43.55966],
            ],
          ],
          [
            [
              [-6.291211, 43.572233],
              [-6.291554, 43.572376],
              [-6.291477, 43.572567],
              [-6.291185, 43.572423],
              [-6.291211, 43.572233],
            ],
          ],
          [
            [
              [-6.445671, 43.562923],
              [-6.44602, 43.562967],
              [-6.446052, 43.563083],
              [-6.445558, 43.563116],
              [-6.445671, 43.562923],
            ],
          ],
          [
            [
              [-6.171205, 43.569677],
              [-6.171219, 43.569478],
              [-6.171471, 43.569552],
              [-6.171318, 43.569935],
              [-6.171205, 43.569677],
            ],
          ],
          [
            [
              [-6.139632, 43.565939],
              [-6.139802, 43.566105],
              [-6.13958, 43.566355],
              [-6.139422, 43.566188],
              [-6.139632, 43.565939],
            ],
          ],
          [
            [
              [-6.379389, 43.558339],
              [-6.379678, 43.558421],
              [-6.37966, 43.55853],
              [-6.37929, 43.558567],
              [-6.379389, 43.558339],
            ],
          ],
          [
            [
              [-6.022344, 43.586651],
              [-6.0226, 43.586572],
              [-6.022521, 43.586962],
              [-6.022395, 43.586929],
              [-6.022344, 43.586651],
            ],
          ],
          [
            [
              [-6.10689, 43.563224],
              [-6.107118, 43.563074],
              [-6.106981, 43.563546],
              [-6.106879, 43.563494],
              [-6.10689, 43.563224],
            ],
          ],
          [
            [
              [-6.194471, 43.576906],
              [-6.194801, 43.57705],
              [-6.19475, 43.577259],
              [-6.194521, 43.577139],
              [-6.194471, 43.576906],
            ],
          ],
          [
            [
              [-6.784149, 43.564639],
              [-6.784401, 43.564712],
              [-6.784477, 43.564943],
              [-6.784192, 43.564935],
              [-6.784149, 43.564639],
            ],
          ],
          [
            [
              [-6.301503, 43.565687],
              [-6.301703, 43.565717],
              [-6.301762, 43.565896],
              [-6.301469, 43.565958],
              [-6.301503, 43.565687],
            ],
          ],
          [
            [
              [-6.433433, 43.556296],
              [-6.433559, 43.556328],
              [-6.433441, 43.556656],
              [-6.433231, 43.556446],
              [-6.433433, 43.556296],
            ],
          ],
          [
            [
              [-6.322185, 43.565979],
              [-6.322571, 43.566013],
              [-6.322606, 43.56621],
              [-6.322358, 43.56619],
              [-6.322185, 43.565979],
            ],
          ],
          [
            [
              [-6.145492, 43.564814],
              [-6.145716, 43.564826],
              [-6.145524, 43.565183],
              [-6.145398, 43.565141],
              [-6.145492, 43.564814],
            ],
          ],
          [
            [
              [-6.789783, 43.558537],
              [-6.789927, 43.558469],
              [-6.790169, 43.558578],
              [-6.789797, 43.558753],
              [-6.789783, 43.558537],
            ],
          ],
          [
            [
              [-6.749295, 43.565654],
              [-6.749531, 43.565665],
              [-6.749612, 43.56577],
              [-6.749212, 43.5659],
              [-6.749295, 43.565654],
            ],
          ],
          [
            [
              [-6.942861, 43.574544],
              [-6.942958, 43.574712],
              [-6.94269, 43.574964],
              [-6.942623, 43.574886],
              [-6.942861, 43.574544],
            ],
          ],
          [
            [
              [-6.382089, 43.558224],
              [-6.38235, 43.558244],
              [-6.382337, 43.558451],
              [-6.382079, 43.558486],
              [-6.382089, 43.558224],
            ],
          ],
          [
            [
              [-6.44517, 43.56238],
              [-6.444858, 43.562336],
              [-6.444779, 43.562257],
              [-6.445365, 43.562338],
              [-6.44517, 43.56238],
            ],
          ],
          [
            [
              [-6.41889, 43.560073],
              [-6.419079, 43.56013],
              [-6.419316, 43.560384],
              [-6.419082, 43.560409],
              [-6.41889, 43.560073],
            ],
          ],
          [
            [
              [-6.796582, 43.559166],
              [-6.796819, 43.559186],
              [-6.79687, 43.559409],
              [-6.796663, 43.559461],
              [-6.796582, 43.559166],
            ],
          ],
          [
            [
              [-6.430336, 43.553211],
              [-6.430564, 43.553294],
              [-6.430195, 43.553566],
              [-6.430141, 43.553487],
              [-6.430336, 43.553211],
            ],
          ],
          [
            [
              [-4.836213, 43.442473],
              [-4.836573, 43.442531],
              [-4.836613, 43.44262],
              [-4.836181, 43.442645],
              [-4.836213, 43.442473],
            ],
          ],
          [
            [
              [-6.30907, 43.567746],
              [-6.309316, 43.567721],
              [-6.309396, 43.567818],
              [-6.309032, 43.567954],
              [-6.30907, 43.567746],
            ],
          ],
          [
            [
              [-6.389718, 43.560024],
              [-6.389743, 43.559816],
              [-6.390061, 43.559969],
              [-6.389921, 43.560126],
              [-6.389718, 43.560024],
            ],
          ],
          [
            [
              [-6.397789, 43.560234],
              [-6.397999, 43.560219],
              [-6.398127, 43.560296],
              [-6.397641, 43.560464],
              [-6.397789, 43.560234],
            ],
          ],
          [
            [
              [-6.124908, 43.561364],
              [-6.125154, 43.561321],
              [-6.125385, 43.561477],
              [-6.125206, 43.561608],
              [-6.124908, 43.561364],
            ],
          ],
          [
            [
              [-7.020251, 43.558816],
              [-7.020516, 43.558888],
              [-7.01995, 43.558944],
              [-7.019995, 43.558879],
              [-7.020251, 43.558816],
            ],
          ],
          [
            [
              [-7.013435, 43.556481],
              [-7.013854, 43.556637],
              [-7.013949, 43.55676],
              [-7.01373, 43.556822],
              [-7.013435, 43.556481],
            ],
          ],
          [
            [
              [-6.052979, 43.575311],
              [-6.053153, 43.575247],
              [-6.05355, 43.575582],
              [-6.053238, 43.575581],
              [-6.052979, 43.575311],
            ],
          ],
          [
            [
              [-5.878438, 43.630065],
              [-5.878647, 43.630024],
              [-5.878721, 43.630283],
              [-5.878487, 43.630316],
              [-5.878438, 43.630065],
            ],
          ],
          [
            [
              [-6.399831, 43.56373],
              [-6.4001, 43.563668],
              [-6.400246, 43.563853],
              [-6.400028, 43.56394],
              [-6.399831, 43.56373],
            ],
          ],
          [
            [
              [-6.440476, 43.5591],
              [-6.440637, 43.559095],
              [-6.440129, 43.559534],
              [-6.440197, 43.559415],
              [-6.440476, 43.5591],
            ],
          ],
          [
            [
              [-6.948296, 43.576994],
              [-6.948441, 43.577115],
              [-6.948122, 43.57736],
              [-6.948068, 43.577281],
              [-6.948296, 43.576994],
            ],
          ],
          [
            [
              [-6.036773, 43.584414],
              [-6.036732, 43.584091],
              [-6.036967, 43.584075],
              [-6.036995, 43.584381],
              [-6.036773, 43.584414],
            ],
          ],
          [
            [
              [-6.394132, 43.559929],
              [-6.394298, 43.559798],
              [-6.394509, 43.560017],
              [-6.394291, 43.560113],
              [-6.394132, 43.559929],
            ],
          ],
          [
            [
              [-6.037048, 43.581003],
              [-6.037169, 43.580955],
              [-6.03758, 43.581241],
              [-6.037319, 43.581239],
              [-6.037048, 43.581003],
            ],
          ],
          [
            [
              [-4.743996, 43.417831],
              [-4.744231, 43.417845],
              [-4.744287, 43.418078],
              [-4.744065, 43.418082],
              [-4.743996, 43.417831],
            ],
          ],
          [
            [
              [-6.807366, 43.558186],
              [-6.807551, 43.55818],
              [-6.80778, 43.558271],
              [-6.807329, 43.558385],
              [-6.807366, 43.558186],
            ],
          ],
          [
            [
              [-6.020939, 43.587526],
              [-6.020945, 43.587652],
              [-6.020448, 43.587881],
              [-6.020565, 43.587725],
              [-6.020939, 43.587526],
            ],
          ],
          [
            [
              [-6.771222, 43.568795],
              [-6.771553, 43.568937],
              [-6.771611, 43.56907],
              [-6.77131, 43.569017],
              [-6.771222, 43.568795],
            ],
          ],
          [
            [
              [-4.561885, 43.393468],
              [-4.562194, 43.393491],
              [-4.5622, 43.393698],
              [-4.561902, 43.393648],
              [-4.561885, 43.393468],
            ],
          ],
          [
            [
              [-6.908881, 43.568259],
              [-6.909161, 43.568195],
              [-6.909203, 43.568266],
              [-6.908934, 43.5685],
              [-6.908881, 43.568259],
            ],
          ],
          [
            [
              [-6.884917, 43.567619],
              [-6.88504, 43.567615],
              [-6.885261, 43.567778],
              [-6.884905, 43.567817],
              [-6.884917, 43.567619],
            ],
          ],
          [
            [
              [-4.575972, 43.396246],
              [-4.576305, 43.396241],
              [-4.576332, 43.396331],
              [-4.575828, 43.396401],
              [-4.575972, 43.396246],
            ],
          ],
          [
            [
              [-6.855746, 43.5694],
              [-6.855891, 43.569341],
              [-6.855999, 43.569482],
              [-6.855763, 43.569661],
              [-6.855746, 43.5694],
            ],
          ],
          [
            [
              [-6.268458, 43.582196],
              [-6.268915, 43.582174],
              [-6.268931, 43.582245],
              [-6.268628, 43.582371],
              [-6.268458, 43.582196],
            ],
          ],
          [
            [
              [-6.956396, 43.564343],
              [-6.956632, 43.564353],
              [-6.956693, 43.564531],
              [-6.956268, 43.564474],
              [-6.956396, 43.564343],
            ],
          ],
          [
            [
              [-6.420125, 43.554346],
              [-6.420377, 43.554419],
              [-6.420373, 43.554563],
              [-6.420139, 43.554579],
              [-6.420125, 43.554346],
            ],
          ],
          [
            [
              [-6.872534, 43.565879],
              [-6.872464, 43.566133],
              [-6.872263, 43.566086],
              [-6.872292, 43.565959],
              [-6.872534, 43.565879],
            ],
          ],
          [
            [
              [-6.818034, 43.561071],
              [-6.818231, 43.561046],
              [-6.818311, 43.561143],
              [-6.818001, 43.561333],
              [-6.818034, 43.561071],
            ],
          ],
          [
            [
              [-6.339809, 43.5609],
              [-6.340018, 43.560885],
              [-6.340167, 43.561106],
              [-6.339907, 43.561113],
              [-6.339809, 43.5609],
            ],
          ],
          [
            [
              [-6.396114, 43.561482],
              [-6.396356, 43.561393],
              [-6.396513, 43.561542],
              [-6.396197, 43.561641],
              [-6.396114, 43.561482],
            ],
          ],
          [
            [
              [-6.818973, 43.564353],
              [-6.819134, 43.564356],
              [-6.81932, 43.564566],
              [-6.819049, 43.564575],
              [-6.818973, 43.564353],
            ],
          ],
          [
            [
              [-6.19065, 43.58264],
              [-6.190864, 43.582688],
              [-6.190716, 43.582945],
              [-6.190591, 43.58293],
              [-6.19065, 43.58264],
            ],
          ],
          [
            [
              [-5.880708, 43.631133],
              [-5.880933, 43.631172],
              [-5.880508, 43.631354],
              [-5.880504, 43.631264],
              [-5.880708, 43.631133],
            ],
          ],
          [
            [
              [-5.890134, 43.628914],
              [-5.890305, 43.628604],
              [-5.890419, 43.628664],
              [-5.890263, 43.629019],
              [-5.890134, 43.628914],
            ],
          ],
          [
            [
              [-6.127177, 43.560221],
              [-6.127362, 43.560216],
              [-6.127176, 43.56069],
              [-6.127122, 43.560592],
              [-6.127177, 43.560221],
            ],
          ],
          [
            [
              [-6.371647, 43.558794],
              [-6.371829, 43.558951],
              [-6.371616, 43.559119],
              [-6.371543, 43.558932],
              [-6.371647, 43.558794],
            ],
          ],
          [
            [
              [-6.153374, 43.568982],
              [-6.153611, 43.569002],
              [-6.153464, 43.569276],
              [-6.153362, 43.569225],
              [-6.153374, 43.568982],
            ],
          ],
          [
            [
              [-5.915383, 43.623267],
              [-5.915511, 43.623354],
              [-5.915201, 43.623605],
              [-5.915193, 43.623452],
              [-5.915383, 43.623267],
            ],
          ],
          [
            [
              [-4.778037, 43.432929],
              [-4.778136, 43.432928],
              [-4.778424, 43.433058],
              [-4.77793, 43.433048],
              [-4.778037, 43.432929],
            ],
          ],
          [
            [
              [-6.605414, 43.56559],
              [-6.605738, 43.565616],
              [-6.606072, 43.565821],
              [-6.605949, 43.565843],
              [-6.605414, 43.56559],
            ],
          ],
          [
            [
              [-6.162288, 43.569654],
              [-6.162463, 43.569685],
              [-6.162194, 43.56998],
              [-6.162208, 43.569773],
              [-6.162288, 43.569654],
            ],
          ],
          [
            [
              [-5.837372, 43.664549],
              [-5.837584, 43.66458],
              [-5.837286, 43.66484],
              [-5.837207, 43.664743],
              [-5.837372, 43.664549],
            ],
          ],
          [
            [
              [-6.28515, 43.572812],
              [-6.285411, 43.572823],
              [-6.285478, 43.572911],
              [-6.284995, 43.572916],
              [-6.28515, 43.572812],
            ],
          ],
          [
            [
              [-6.397773, 43.560613],
              [-6.398097, 43.560639],
              [-6.398128, 43.560746],
              [-6.397708, 43.560768],
              [-6.397773, 43.560613],
            ],
          ],
          [
            [
              [-6.947739, 43.574538],
              [-6.947925, 43.574531],
              [-6.947535, 43.574815],
              [-6.947492, 43.574726],
              [-6.947739, 43.574538],
            ],
          ],
          [
            [
              [-4.739156, 43.416599],
              [-4.739367, 43.416659],
              [-4.739287, 43.416885],
              [-4.739089, 43.416852],
              [-4.739156, 43.416599],
            ],
          ],
          [
            [
              [-6.860751, 43.568268],
              [-6.860953, 43.568324],
              [-6.860453, 43.568449],
              [-6.860447, 43.568359],
              [-6.860751, 43.568268],
            ],
          ],
          [
            [
              [-6.846935, 43.565403],
              [-6.847086, 43.565434],
              [-6.846881, 43.565711],
              [-6.846808, 43.565551],
              [-6.846935, 43.565403],
            ],
          ],
          [
            [
              [-6.381676, 43.557255],
              [-6.381848, 43.557241],
              [-6.381883, 43.557411],
              [-6.381639, 43.557481],
              [-6.381676, 43.557255],
            ],
          ],
          [
            [
              [-6.784078, 43.563507],
              [-6.784332, 43.563607],
              [-6.784505, 43.563808],
              [-6.784332, 43.563814],
              [-6.784078, 43.563507],
            ],
          ],
          [
            [
              [-6.831796, 43.566435],
              [-6.832044, 43.566444],
              [-6.832077, 43.566569],
              [-6.831795, 43.566624],
              [-6.831796, 43.566435],
            ],
          ],
          [
            [
              [-6.036538, 43.579981],
              [-6.036792, 43.579857],
              [-6.036917, 43.579881],
              [-6.036547, 43.58017],
              [-6.036538, 43.579981],
            ],
          ],
          [
            [
              [-6.322071, 43.564587],
              [-6.322331, 43.564579],
              [-6.322342, 43.564777],
              [-6.322121, 43.564819],
              [-6.322071, 43.564587],
            ],
          ],
          [
            [
              [-6.349967, 43.552968],
              [-6.350086, 43.552883],
              [-6.350289, 43.553183],
              [-6.35005, 43.553118],
              [-6.349967, 43.552968],
            ],
          ],
          [
            [
              [-6.41832, 43.56072],
              [-6.418544, 43.56074],
              [-6.418653, 43.560917],
              [-6.418392, 43.560907],
              [-6.41832, 43.56072],
            ],
          ],
          [
            [
              [-5.868595, 43.633428],
              [-5.868811, 43.633269],
              [-5.868924, 43.633311],
              [-5.86863, 43.633634],
              [-5.868595, 43.633428],
            ],
          ],
          [
            [
              [-6.747306, 43.56671],
              [-6.747494, 43.566758],
              [-6.747635, 43.567024],
              [-6.747473, 43.567011],
              [-6.747306, 43.56671],
            ],
          ],
          [
            [
              [-6.166266, 43.572785],
              [-6.166488, 43.57277],
              [-6.166524, 43.572967],
              [-6.166306, 43.573063],
              [-6.166266, 43.572785],
            ],
          ],
          [
            [
              [-6.320721, 43.565697],
              [-6.320912, 43.565791],
              [-6.32092, 43.565944],
              [-6.320686, 43.565959],
              [-6.320721, 43.565697],
            ],
          ],
          [
            [
              [-6.819494, 43.564182],
              [-6.819731, 43.56421],
              [-6.819919, 43.564438],
              [-6.819539, 43.564298],
              [-6.819494, 43.564182],
            ],
          ],
          [
            [
              [-6.372129, 43.558564],
              [-6.372353, 43.558584],
              [-6.372291, 43.558802],
              [-6.372103, 43.558763],
              [-6.372129, 43.558564],
            ],
          ],
          [
            [
              [-5.859387, 43.659607],
              [-5.859636, 43.659628],
              [-5.859658, 43.659816],
              [-5.859409, 43.659795],
              [-5.859387, 43.659607],
            ],
          ],
          [
            [
              [-6.027983, 43.586718],
              [-6.028116, 43.586894],
              [-6.02768, 43.586852],
              [-6.027838, 43.586785],
              [-6.027983, 43.586718],
            ],
          ],
          [
            [
              [-6.358058, 43.556205],
              [-6.358257, 43.556227],
              [-6.358146, 43.556455],
              [-6.357957, 43.556397],
              [-6.358058, 43.556205],
            ],
          ],
          [
            [
              [-6.106196, 43.562478],
              [-6.106448, 43.562579],
              [-6.106425, 43.56284],
              [-6.106241, 43.562638],
              [-6.106196, 43.562478],
            ],
          ],
          [
            [
              [-6.171695, 43.56933],
              [-6.171879, 43.569298],
              [-6.171931, 43.569584],
              [-6.171807, 43.569588],
              [-6.171695, 43.56933],
            ],
          ],
          [
            [
              [-6.020135, 43.588051],
              [-6.020381, 43.588018],
              [-6.019986, 43.588289],
              [-6.019968, 43.588191],
              [-6.020135, 43.588051],
            ],
          ],
          [
            [
              [-5.968289, 43.581118],
              [-5.968511, 43.581094],
              [-5.968103, 43.581375],
              [-5.968294, 43.581217],
              [-5.968289, 43.581118],
            ],
          ],
          [
            [
              [-6.627479, 43.572814],
              [-6.627746, 43.572724],
              [-6.627849, 43.572784],
              [-6.627575, 43.572964],
              [-6.627479, 43.572814],
            ],
          ],
          [
            [
              [-6.856209, 43.569088],
              [-6.856302, 43.569175],
              [-6.856031, 43.5694],
              [-6.855958, 43.569222],
              [-6.856209, 43.569088],
            ],
          ],
          [
            [
              [-6.188774, 43.579938],
              [-6.188927, 43.580032],
              [-6.188768, 43.580307],
              [-6.188655, 43.580274],
              [-6.188774, 43.579938],
            ],
          ],
          [
            [
              [-5.834309, 43.650337],
              [-5.83448, 43.650278],
              [-5.834374, 43.650659],
              [-5.834246, 43.650572],
              [-5.834309, 43.650337],
            ],
          ],
          [
            [
              [-6.988479, 43.557446],
              [-6.988689, 43.557439],
              [-6.988432, 43.557673],
              [-6.988335, 43.557514],
              [-6.988479, 43.557446],
            ],
          ],
          [
            [
              [-6.331603, 43.562301],
              [-6.331875, 43.562284],
              [-6.331901, 43.56232],
              [-6.331615, 43.562508],
              [-6.331603, 43.562301],
            ],
          ],
          [
            [
              [-6.411014, 43.558508],
              [-6.411199, 43.558493],
              [-6.411254, 43.55859],
              [-6.410886, 43.558664],
              [-6.411014, 43.558508],
            ],
          ],
          [
            [
              [-6.870167, 43.569515],
              [-6.870402, 43.569507],
              [-6.870413, 43.569678],
              [-6.870218, 43.56973],
              [-6.870167, 43.569515],
            ],
          ],
          [
            [
              [-6.419699, 43.558833],
              [-6.419897, 43.558845],
              [-6.41994, 43.558952],
              [-6.419749, 43.559075],
              [-6.419699, 43.558833],
            ],
          ],
          [
            [
              [-6.359533, 43.553569],
              [-6.359649, 43.553665],
              [-6.359377, 43.553889],
              [-6.35936, 43.553799],
              [-6.359533, 43.553569],
            ],
          ],
          [
            [
              [-6.408074, 43.558856],
              [-6.408309, 43.55884],
              [-6.40832, 43.559047],
              [-6.408086, 43.559063],
              [-6.408074, 43.558856],
            ],
          ],
          [
            [
              [-6.153772, 43.570907],
              [-6.153997, 43.570936],
              [-6.153923, 43.571191],
              [-6.153797, 43.57114],
              [-6.153772, 43.570907],
            ],
          ],
          [
            [
              [-6.854154, 43.567924],
              [-6.854302, 43.567919],
              [-6.853922, 43.568166],
              [-6.853977, 43.568056],
              [-6.854154, 43.567924],
            ],
          ],
          [
            [
              [-6.439886, 43.559172],
              [-6.440019, 43.559114],
              [-6.440164, 43.559281],
              [-6.439947, 43.559368],
              [-6.439886, 43.559172],
            ],
          ],
          [
            [
              [-6.626443, 43.57309],
              [-6.626632, 43.573138],
              [-6.626707, 43.57336],
              [-6.626534, 43.573366],
              [-6.626443, 43.57309],
            ],
          ],
          [
            [
              [-6.173125, 43.568039],
              [-6.173283, 43.567989],
              [-6.173408, 43.568004],
              [-6.173013, 43.568258],
              [-6.173125, 43.568039],
            ],
          ],
          [
            [
              [-6.418858, 43.556454],
              [-6.419108, 43.556492],
              [-6.419117, 43.556645],
              [-6.418843, 43.556626],
              [-6.418858, 43.556454],
            ],
          ],
          [
            [
              [-6.372113, 43.558051],
              [-6.372268, 43.558164],
              [-6.371891, 43.558283],
              [-6.371934, 43.558164],
              [-6.372113, 43.558051],
            ],
          ],
          [
            [
              [-6.290879, 43.57317],
              [-6.291084, 43.573299],
              [-6.290843, 43.573423],
              [-6.290759, 43.573254],
              [-6.290879, 43.57317],
            ],
          ],
          [
            [
              [-6.302934, 43.565124],
              [-6.303148, 43.56519],
              [-6.302746, 43.565318],
              [-6.302766, 43.565228],
              [-6.302934, 43.565124],
            ],
          ],
          [
            [
              [-6.024854, 43.587548],
              [-6.025014, 43.587535],
              [-6.025146, 43.587684],
              [-6.024952, 43.58778],
              [-6.024854, 43.587548],
            ],
          ],
          [
            [
              [-6.027779, 43.587579],
              [-6.028175, 43.587577],
              [-6.028289, 43.587637],
              [-6.027922, 43.58771],
              [-6.027779, 43.587579],
            ],
          ],
          [
            [
              [-6.2913, 43.571132],
              [-6.291505, 43.571252],
              [-6.291354, 43.571436],
              [-6.291276, 43.571376],
              [-6.2913, 43.571132],
            ],
          ],
          [
            [
              [-6.089358, 43.563952],
              [-6.089527, 43.563866],
              [-6.089615, 43.564134],
              [-6.089493, 43.564173],
              [-6.089358, 43.563952],
            ],
          ],
          [
            [
              [-6.275842, 43.576303],
              [-6.275949, 43.576237],
              [-6.276144, 43.576402],
              [-6.275925, 43.576472],
              [-6.275842, 43.576303],
            ],
          ],
          [
            [
              [-6.03477, 43.583503],
              [-6.034906, 43.583491],
              [-6.035043, 43.583757],
              [-6.034922, 43.583815],
              [-6.03477, 43.583503],
            ],
          ],
          [
            [
              [-6.398008, 43.561047],
              [-6.398266, 43.561012],
              [-6.398252, 43.561202],
              [-6.397981, 43.561219],
              [-6.398008, 43.561047],
            ],
          ],
          [
            [
              [-6.338018, 43.561871],
              [-6.338346, 43.561987],
              [-6.338361, 43.562041],
              [-6.338129, 43.562084],
              [-6.338018, 43.561871],
            ],
          ],
          [
            [
              [-5.770673, 43.608518],
              [-5.771008, 43.608546],
              [-5.770968, 43.608745],
              [-5.770676, 43.608599],
              [-5.770673, 43.608518],
            ],
          ],
          [
            [
              [-6.028442, 43.586733],
              [-6.028712, 43.586672],
              [-6.029022, 43.58669],
              [-6.02894, 43.586782],
              [-6.028442, 43.586733],
            ],
          ],
          [
            [
              [-6.409996, 43.560006],
              [-6.410258, 43.560025],
              [-6.410289, 43.56015],
              [-6.409992, 43.56015],
              [-6.409996, 43.560006],
            ],
          ],
          [
            [
              [-6.951081, 43.570911],
              [-6.951365, 43.570901],
              [-6.951384, 43.570999],
              [-6.951131, 43.571107],
              [-6.951081, 43.570911],
            ],
          ],
          [
            [
              [-5.862263, 43.649145],
              [-5.862424, 43.649132],
              [-5.861976, 43.649368],
              [-5.862009, 43.649268],
              [-5.862263, 43.649145],
            ],
          ],
          [
            [
              [-6.424286, 43.55567],
              [-6.424435, 43.555675],
              [-6.424495, 43.555862],
              [-6.424262, 43.555905],
              [-6.424286, 43.55567],
            ],
          ],
          [
            [
              [-6.08987, 43.563794],
              [-6.090118, 43.563796],
              [-6.090128, 43.564003],
              [-6.08998, 43.564016],
              [-6.08987, 43.563794],
            ],
          ],
          [
            [
              [-6.277335, 43.575072],
              [-6.277498, 43.575103],
              [-6.27754, 43.575201],
              [-6.277168, 43.575185],
              [-6.277335, 43.575072],
            ],
          ],
          [
            [
              [-6.784266, 43.563942],
              [-6.784454, 43.563972],
              [-6.784541, 43.564185],
              [-6.784381, 43.56419],
              [-6.784266, 43.563942],
            ],
          ],
          [
            [
              [-5.879193, 43.63055],
              [-5.879423, 43.630445],
              [-5.879489, 43.630534],
              [-5.879049, 43.630653],
              [-5.879193, 43.63055],
            ],
          ],
          [
            [
              [-6.037877, 43.581737],
              [-6.038087, 43.581723],
              [-6.038206, 43.581881],
              [-6.03796, 43.581906],
              [-6.037877, 43.581737],
            ],
          ],
          [
            [
              [-6.811082, 43.556252],
              [-6.811006, 43.556228],
              [-6.810978, 43.555977],
              [-6.811223, 43.556131],
              [-6.811082, 43.556252],
            ],
          ],
          [
            [
              [-6.78441, 43.563271],
              [-6.784635, 43.563309],
              [-6.784452, 43.563549],
              [-6.784375, 43.563308],
              [-6.78441, 43.563271],
            ],
          ],
          [
            [
              [-6.194459, 43.578545],
              [-6.19463, 43.578505],
              [-6.194721, 43.578583],
              [-6.19442, 43.578745],
              [-6.194459, 43.578545],
            ],
          ],
          [
            [
              [-6.86801, 43.56967],
              [-6.868019, 43.569813],
              [-6.867684, 43.569996],
              [-6.86781, 43.569829],
              [-6.86801, 43.56967],
            ],
          ],
          [
            [
              [-6.367569, 43.558716],
              [-6.36777, 43.558765],
              [-6.36762, 43.558958],
              [-6.367542, 43.558897],
              [-6.367569, 43.558716],
            ],
          ],
          [
            [
              [-6.275725, 43.577801],
              [-6.275886, 43.577805],
              [-6.275945, 43.577975],
              [-6.275673, 43.577983],
              [-6.275725, 43.577801],
            ],
          ],
          [
            [
              [-6.388644, 43.558984],
              [-6.388854, 43.558969],
              [-6.388897, 43.559076],
              [-6.388643, 43.559183],
              [-6.388644, 43.558984],
            ],
          ],
          [
            [
              [-6.440366, 43.559563],
              [-6.440506, 43.559415],
              [-6.44062, 43.559456],
              [-6.440424, 43.559714],
              [-6.440366, 43.559563],
            ],
          ],
          [
            [
              [-6.627982, 43.572528],
              [-6.628179, 43.572503],
              [-6.628222, 43.572592],
              [-6.62788, 43.572684],
              [-6.627982, 43.572528],
            ],
          ],
          [
            [
              [-5.834462, 43.649892],
              [-5.834719, 43.649822],
              [-5.834725, 43.649957],
              [-5.834606, 43.650059],
              [-5.834462, 43.649892],
            ],
          ],
          [
            [
              [-5.012927, 43.459623],
              [-5.013001, 43.459613],
              [-5.013176, 43.4597],
              [-5.012921, 43.459839],
              [-5.012927, 43.459623],
            ],
          ],
          [
            [
              [-6.087454, 43.564255],
              [-6.087406, 43.564031],
              [-6.087555, 43.564045],
              [-6.087642, 43.564295],
              [-6.087454, 43.564255],
            ],
          ],
          [
            [
              [-6.816936, 43.560774],
              [-6.817046, 43.560744],
              [-6.81716, 43.560794],
              [-6.816785, 43.560932],
              [-6.816936, 43.560774],
            ],
          ],
          [
            [
              [-6.81249, 43.557376],
              [-6.812692, 43.55745],
              [-6.812403, 43.557577],
              [-6.812371, 43.557452],
              [-6.81249, 43.557376],
            ],
          ],
          [
            [
              [-6.433363, 43.555722],
              [-6.433524, 43.555717],
              [-6.433657, 43.555884],
              [-6.433359, 43.555857],
              [-6.433363, 43.555722],
            ],
          ],
          [
            [
              [-5.896924, 43.626545],
              [-5.89706, 43.626542],
              [-5.897091, 43.62691],
              [-5.896927, 43.626869],
              [-5.896924, 43.626545],
            ],
          ],
          [
            [
              [-5.867004, 43.662774],
              [-5.86714, 43.662762],
              [-5.866972, 43.663135],
              [-5.866901, 43.662957],
              [-5.867004, 43.662774],
            ],
          ],
          [
            [
              [-5.863323, 43.649532],
              [-5.863597, 43.649561],
              [-5.863615, 43.649669],
              [-5.863368, 43.649702],
              [-5.863323, 43.649532],
            ],
          ],
          [
            [
              [-6.149428, 43.571423],
              [-6.149663, 43.571425],
              [-6.149685, 43.571614],
              [-6.149537, 43.571618],
              [-6.149428, 43.571423],
            ],
          ],
          [
            [
              [-6.149808, 43.572069],
              [-6.149947, 43.57212],
              [-6.149738, 43.572377],
              [-6.149707, 43.57227],
              [-6.149808, 43.572069],
            ],
          ],
          [
            [
              [-6.462398, 43.569602],
              [-6.462516, 43.569724],
              [-6.462185, 43.569789],
              [-6.462182, 43.569735],
              [-6.462398, 43.569602],
            ],
          ],
          [
            [
              [-6.361583, 43.553491],
              [-6.361755, 43.553468],
              [-6.361823, 43.553574],
              [-6.361594, 43.553679],
              [-6.361583, 43.553491],
            ],
          ],
          [
            [
              [-6.41331, 43.559051],
              [-6.413471, 43.559046],
              [-6.413373, 43.559283],
              [-6.413247, 43.559242],
              [-6.41331, 43.559051],
            ],
          ],
          [
            [
              [-6.018473, 43.588266],
              [-6.018609, 43.588245],
              [-6.0187, 43.588332],
              [-6.018509, 43.58849],
              [-6.018473, 43.588266],
            ],
          ],
          [
            [
              [-6.036129, 43.580226],
              [-6.036251, 43.580196],
              [-6.036341, 43.580256],
              [-6.036052, 43.580408],
              [-6.036129, 43.580226],
            ],
          ],
          [
            [
              [-6.167527, 43.569202],
              [-6.167674, 43.569162],
              [-6.167799, 43.569195],
              [-6.167524, 43.569383],
              [-6.167527, 43.569202],
            ],
          ],
          [
            [
              [-5.915316, 43.608566],
              [-5.915451, 43.608526],
              [-5.915156, 43.608831],
              [-5.915163, 43.608723],
              [-5.915316, 43.608566],
            ],
          ],
          [
            [
              [-5.871947, 43.633533],
              [-5.872156, 43.633491],
              [-5.872165, 43.633689],
              [-5.872081, 43.633745],
              [-5.871947, 43.633533],
            ],
          ],
          [
            [
              [-6.089661, 43.563349],
              [-6.08977, 43.563292],
              [-6.089937, 43.563414],
              [-6.089708, 43.563537],
              [-6.089661, 43.563349],
            ],
          ],
          [
            [
              [-6.369888, 43.559215],
              [-6.37006, 43.559192],
              [-6.370072, 43.559417],
              [-6.369923, 43.559403],
              [-6.369888, 43.559215],
            ],
          ],
          [
            [
              [-6.16682, 43.572247],
              [-6.166967, 43.572207],
              [-6.167105, 43.572248],
              [-6.166965, 43.572414],
              [-6.16682, 43.572247],
            ],
          ],
          [
            [
              [-6.192479, 43.582319],
              [-6.192627, 43.582306],
              [-6.192418, 43.582573],
              [-6.192316, 43.582522],
              [-6.192479, 43.582319],
            ],
          ],
          [
            [
              [-5.904536, 43.625038],
              [-5.904736, 43.625069],
              [-5.904486, 43.625273],
              [-5.904492, 43.625147],
              [-5.904536, 43.625038],
            ],
          ],
          [
            [
              [-6.432473, 43.555091],
              [-6.432578, 43.554971],
              [-6.432748, 43.555128],
              [-6.432615, 43.555195],
              [-6.432473, 43.555091],
            ],
          ],
          [
            [
              [-5.862735, 43.66298],
              [-5.862858, 43.662968],
              [-5.862966, 43.663155],
              [-5.862769, 43.663169],
              [-5.862735, 43.66298],
            ],
          ],
          [
            [
              [-5.77561, 43.609182],
              [-5.775896, 43.609202],
              [-5.775901, 43.60931],
              [-5.775742, 43.609349],
              [-5.77561, 43.609182],
            ],
          ],
          [
            [
              [-6.812467, 43.556818],
              [-6.812686, 43.556766],
              [-6.812295, 43.557031],
              [-6.812315, 43.556958],
              [-6.812467, 43.556818],
            ],
          ],
          [
            [
              [-6.371852, 43.55958],
              [-6.371962, 43.559559],
              [-6.372077, 43.559619],
              [-6.3719, 43.559777],
              [-6.371852, 43.55958],
            ],
          ],
          [
            [
              [-6.623447, 43.571447],
              [-6.623556, 43.571408],
              [-6.62367, 43.571449],
              [-6.623356, 43.571585],
              [-6.623447, 43.571447],
            ],
          ],
          [
            [
              [-6.87443, 43.564554],
              [-6.874566, 43.564549],
              [-6.874647, 43.564655],
              [-6.87438, 43.564736],
              [-6.87443, 43.564554],
            ],
          ],
          [
            [
              [-6.787825, 43.557522],
              [-6.787959, 43.557679],
              [-6.787741, 43.557759],
              [-6.787825, 43.557522],
            ],
          ],
          [
            [
              [-6.180863, 43.565906],
              [-6.180986, 43.565884],
              [-6.180745, 43.566242],
              [-6.18077, 43.566016],
              [-6.180863, 43.565906],
            ],
          ],
          [
            [
              [-6.399571, 43.559119],
              [-6.399819, 43.559112],
              [-6.399849, 43.559219],
              [-6.399642, 43.559279],
              [-6.399571, 43.559119],
            ],
          ],
          [
            [
              [-6.162044, 43.57066],
              [-6.162112, 43.570541],
              [-6.162236, 43.570556],
              [-6.162127, 43.570829],
              [-6.162044, 43.57066],
            ],
          ],
          [
            [
              [-6.872443, 43.565612],
              [-6.872603, 43.565606],
              [-6.872676, 43.565766],
              [-6.872478, 43.565773],
              [-6.872443, 43.565612],
            ],
          ],
          [
            [
              [-6.371901, 43.55758],
              [-6.372137, 43.557591],
              [-6.37218, 43.557689],
              [-6.371947, 43.557732],
              [-6.371901, 43.55758],
            ],
          ],
          [
            [
              [-6.167443, 43.569484],
              [-6.167716, 43.569494],
              [-6.167794, 43.569564],
              [-6.167537, 43.569616],
              [-6.167443, 43.569484],
            ],
          ],
          [
            [
              [-6.441782, 43.560727],
              [-6.442028, 43.560692],
              [-6.442046, 43.5608],
              [-6.441839, 43.560851],
              [-6.441782, 43.560727],
            ],
          ],
          [
            [
              [-6.409748, 43.55977],
              [-6.409956, 43.559728],
              [-6.409973, 43.559817],
              [-6.409646, 43.559944],
              [-6.409748, 43.55977],
            ],
          ],
          [
            [
              [-6.865127, 43.570668],
              [-6.865323, 43.570634],
              [-6.865347, 43.570822],
              [-6.865225, 43.570844],
              [-6.865127, 43.570668],
            ],
          ],
          [
            [
              [-5.862477, 43.643719],
              [-5.862585, 43.643662],
              [-5.862735, 43.643668],
              [-5.862522, 43.643889],
              [-5.862477, 43.643719],
            ],
          ],
          [
            [
              [-6.395822, 43.561805],
              [-6.396078, 43.561726],
              [-6.396108, 43.561824],
              [-6.395952, 43.56191],
              [-6.395822, 43.561805],
            ],
          ],
          [
            [
              [-6.445109, 43.56348],
              [-6.445271, 43.563502],
              [-6.444896, 43.563658],
              [-6.444918, 43.563603],
              [-6.445109, 43.56348],
            ],
          ],
          [
            [
              [-6.871801, 43.566219],
              [-6.87204, 43.566094],
              [-6.872093, 43.566146],
              [-6.871883, 43.566342],
              [-6.871801, 43.566219],
            ],
          ],
          [
            [
              [-6.877512, 43.564719],
              [-6.877628, 43.564607],
              [-6.87782, 43.564709],
              [-6.877666, 43.564804],
              [-6.877512, 43.564719],
            ],
          ],
          [
            [
              [-6.277488, 43.574689],
              [-6.277735, 43.574682],
              [-6.277777, 43.57478],
              [-6.277657, 43.574837],
              [-6.277488, 43.574689],
            ],
          ],
          [
            [
              [-5.866312, 43.643812],
              [-5.866445, 43.643737],
              [-5.866491, 43.643916],
              [-5.866257, 43.643949],
              [-5.866312, 43.643812],
            ],
          ],
          [
            [
              [-6.370499, 43.559737],
              [-6.370658, 43.559715],
              [-6.370714, 43.55983],
              [-6.37052, 43.559899],
              [-6.370499, 43.559737],
            ],
          ],
          [
            [
              [-6.080066, 43.566508],
              [-6.080134, 43.566398],
              [-6.080271, 43.566412],
              [-6.08016, 43.566667],
              [-6.080066, 43.566508],
            ],
          ],
          [
            [
              [-6.01961, 43.588443],
              [-6.019728, 43.588341],
              [-6.019855, 43.588392],
              [-6.019702, 43.588567],
              [-6.01961, 43.588443],
            ],
          ],
          [
            [
              [-6.10497, 43.562241],
              [-6.105091, 43.562192],
              [-6.105082, 43.562499],
              [-6.104958, 43.562493],
              [-6.10497, 43.562241],
            ],
          ],
          [
            [
              [-6.853428, 43.567615],
              [-6.85354, 43.567611],
              [-6.853669, 43.567706],
              [-6.853452, 43.567785],
              [-6.853428, 43.567615],
            ],
          ],
          [
            [
              [-6.418802, 43.559616],
              [-6.418986, 43.559593],
              [-6.419077, 43.559671],
              [-6.41886, 43.559759],
              [-6.418802, 43.559616],
            ],
          ],
          [
            [
              [-5.889814, 43.627671],
              [-5.889991, 43.627738],
              [-5.889699, 43.627863],
              [-5.889744, 43.627763],
              [-5.889814, 43.627671],
            ],
          ],
          [
            [
              [-6.852337, 43.56614],
              [-6.852485, 43.566135],
              [-6.852494, 43.566278],
              [-6.852312, 43.566329],
              [-6.852337, 43.56614],
            ],
          ],
          [
            [
              [-6.854616, 43.570888],
              [-6.8548, 43.570873],
              [-6.854725, 43.571055],
              [-6.854577, 43.571051],
              [-6.854616, 43.570888],
            ],
          ],
          [
            [
              [-5.867057, 43.662323],
              [-5.867167, 43.662284],
              [-5.86725, 43.662471],
              [-5.867154, 43.662545],
              [-5.867057, 43.662323],
            ],
          ],
          [
            [
              [-6.174908, 43.56897],
              [-6.175073, 43.569056],
              [-6.174883, 43.569214],
              [-6.174852, 43.569089],
              [-6.174908, 43.56897],
            ],
          ],
          [
            [
              [-6.443827, 43.561412],
              [-6.443984, 43.561353],
              [-6.444063, 43.561423],
              [-6.443896, 43.561545],
              [-6.443827, 43.561412],
            ],
          ],
          [
            [
              [-6.875584, 43.564785],
              [-6.875724, 43.564843],
              [-6.875587, 43.565019],
              [-6.87552, 43.56494],
              [-6.875584, 43.564785],
            ],
          ],
          [
            [
              [-6.432128, 43.554472],
              [-6.432313, 43.554457],
              [-6.432355, 43.554537],
              [-6.432112, 43.554625],
              [-6.432128, 43.554472],
            ],
          ],
          [
            [
              [-6.437431, 43.559543],
              [-6.437499, 43.559433],
              [-6.43768, 43.559563],
              [-6.437485, 43.559623],
              [-6.437431, 43.559543],
            ],
          ],
          [
            [
              [-6.16712, 43.5697],
              [-6.167268, 43.569687],
              [-6.167533, 43.569787],
              [-6.167162, 43.569798],
              [-6.16712, 43.5697],
            ],
          ],
          [
            [
              [-6.296095, 43.570957],
              [-6.296278, 43.570907],
              [-6.296346, 43.571022],
              [-6.29614, 43.571091],
              [-6.296095, 43.570957],
            ],
          ],
          [
            [
              [-6.37935, 43.557945],
              [-6.379459, 43.557897],
              [-6.379535, 43.55794],
              [-6.379335, 43.558126],
              [-6.37935, 43.557945],
            ],
          ],
          [
            [
              [-6.437502, 43.559046],
              [-6.437638, 43.559042],
              [-6.437622, 43.559204],
              [-6.43741, 43.559175],
              [-6.437502, 43.559046],
            ],
          ],
          [
            [
              [-6.380123, 43.559694],
              [-6.380181, 43.55962],
              [-6.380371, 43.559696],
              [-6.380229, 43.559817],
              [-6.380123, 43.559694],
            ],
          ],
          [
            [
              [-6.416875, 43.558819],
              [-6.417093, 43.558731],
              [-6.417135, 43.55882],
              [-6.416944, 43.558943],
              [-6.416875, 43.558819],
            ],
          ],
          [
            [
              [-6.847771, 43.564925],
              [-6.847888, 43.565011],
              [-6.847689, 43.56518],
              [-6.8477, 43.564972],
              [-6.847771, 43.564925],
            ],
          ],
          [
            [
              [-5.860371, 43.65499],
              [-5.860507, 43.654987],
              [-5.860561, 43.655067],
              [-5.860304, 43.655145],
              [-5.860371, 43.65499],
            ],
          ],
          [
            [
              [-6.439917, 43.560369],
              [-6.440056, 43.560418],
              [-6.439881, 43.560604],
              [-6.439799, 43.560471],
              [-6.439917, 43.560369],
            ],
          ],
          [
            [
              [-5.834571, 43.649574],
              [-5.834654, 43.649509],
              [-5.834845, 43.649603],
              [-5.834726, 43.649705],
              [-5.834571, 43.649574],
            ],
          ],
          [
            [
              [-6.107492, 43.562649],
              [-6.107703, 43.562653],
              [-6.107663, 43.562852],
              [-6.107561, 43.562783],
              [-6.107492, 43.562649],
            ],
          ],
          [
            [
              [-6.182874, 43.564814],
              [-6.182996, 43.564784],
              [-6.182825, 43.565068],
              [-6.182773, 43.565024],
              [-6.182874, 43.564814],
            ],
          ],
          [
            [
              [-6.444541, 43.56113],
              [-6.444645, 43.561207],
              [-6.444431, 43.561367],
              [-6.444403, 43.561305],
              [-6.444541, 43.56113],
            ],
          ],
          [
            [
              [-5.792925, 43.633358],
              [-5.793072, 43.633318],
              [-5.793081, 43.633525],
              [-5.792918, 43.633484],
              [-5.792925, 43.633358],
            ],
          ],
          [
            [
              [-6.020819, 43.587862],
              [-6.021021, 43.587938],
              [-6.020976, 43.588029],
              [-6.020749, 43.587945],
              [-6.020819, 43.587862],
            ],
          ],
          [
            [
              [-6.023201, 43.587934],
              [-6.023398, 43.58792],
              [-6.02342, 43.588108],
              [-6.023294, 43.588067],
              [-6.023201, 43.587934],
            ],
          ],
          [
            [
              [-6.762935, 43.568348],
              [-6.763021, 43.568345],
              [-6.763004, 43.56867],
              [-6.762954, 43.568662],
              [-6.762935, 43.568348],
            ],
          ],
          [
            [
              [-6.291454, 43.573495],
              [-6.291603, 43.573518],
              [-6.291562, 43.573672],
              [-6.291387, 43.573632],
              [-6.291454, 43.573495],
            ],
          ],
          [
            [
              [-6.092249, 43.561172],
              [-6.092358, 43.561133],
              [-6.092455, 43.56132],
              [-6.092359, 43.561376],
              [-6.092249, 43.561172],
            ],
          ],
          [
            [
              [-6.17084, 43.56957],
              [-6.171027, 43.569583],
              [-6.170876, 43.569776],
              [-6.17081, 43.569697],
              [-6.17084, 43.56957],
            ],
          ],
          [
            [
              [-6.179418, 43.567621],
              [-6.179472, 43.567475],
              [-6.179597, 43.567489],
              [-6.179475, 43.567754],
              [-6.179418, 43.567621],
            ],
          ],
          [
            [
              [-6.369297, 43.558837],
              [-6.369444, 43.558814],
              [-6.369499, 43.558903],
              [-6.369317, 43.558971],
              [-6.369297, 43.558837],
            ],
          ],
          [
            [
              [-6.947638, 43.575072],
              [-6.947678, 43.574918],
              [-6.94789, 43.574947],
              [-6.947788, 43.575085],
              [-6.947638, 43.575072],
            ],
          ],
          [
            [
              [-6.370404, 43.560046],
              [-6.370553, 43.560051],
              [-6.370608, 43.560139],
              [-6.370439, 43.560216],
              [-6.370404, 43.560046],
            ],
          ],
          [
            [
              [-6.139768, 43.565692],
              [-6.139876, 43.565617],
              [-6.140013, 43.565631],
              [-6.139886, 43.565815],
              [-6.139768, 43.565692],
            ],
          ],
          [
            [
              [-6.871552, 43.5662],
              [-6.871676, 43.566394],
              [-6.871567, 43.566443],
              [-6.8715, 43.566355],
              [-6.871552, 43.5662],
            ],
          ],
          [
            [
              [-5.863611, 43.649336],
              [-5.863694, 43.649253],
              [-5.863832, 43.649276],
              [-5.863704, 43.64946],
              [-5.863611, 43.649336],
            ],
          ],
          [
            [
              [-6.434549, 43.557199],
              [-6.434702, 43.557275],
              [-6.434647, 43.557403],
              [-6.434495, 43.557336],
              [-6.434549, 43.557199],
            ],
          ],
          [
            [
              [-5.863517, 43.661439],
              [-5.863563, 43.661357],
              [-5.863803, 43.66145],
              [-5.863733, 43.661533],
              [-5.863517, 43.661439],
            ],
          ],
          [
            [
              [-6.167087, 43.570007],
              [-6.167238, 43.570066],
              [-6.16706, 43.570206],
              [-6.16703, 43.570117],
              [-6.167087, 43.570007],
            ],
          ],
          [
            [
              [-5.864438, 43.665414],
              [-5.864484, 43.665331],
              [-5.864735, 43.665397],
              [-5.864677, 43.66548],
              [-5.864438, 43.665414],
            ],
          ],
          [
            [
              [-6.091685, 43.561998],
              [-6.091805, 43.561923],
              [-6.091864, 43.562119],
              [-6.091756, 43.562176],
              [-6.091685, 43.561998],
            ],
          ],
          [
            [
              [-6.105561, 43.563143],
              [-6.105618, 43.563051],
              [-6.105857, 43.563126],
              [-6.105775, 43.5632],
              [-6.105561, 43.563143],
            ],
          ],
          [
            [
              [-6.034479, 43.584853],
              [-6.034524, 43.584762],
              [-6.034669, 43.584938],
              [-6.034598, 43.585003],
              [-6.034479, 43.584853],
            ],
          ],
          [
            [
              [-6.166849, 43.571373],
              [-6.166867, 43.571247],
              [-6.167035, 43.571386],
              [-6.166978, 43.571487],
              [-6.166849, 43.571373],
            ],
          ],
          [
            [
              [-6.032546, 43.584589],
              [-6.032629, 43.584524],
              [-6.032834, 43.584653],
              [-6.032726, 43.584728],
              [-6.032546, 43.584589],
            ],
          ],
          [
            [
              [-6.440766, 43.559632],
              [-6.440929, 43.559663],
              [-6.440935, 43.559771],
              [-6.440726, 43.559795],
              [-6.440766, 43.559632],
            ],
          ],
          [
            [
              [-6.443665, 43.559869],
              [-6.443812, 43.559846],
              [-6.443867, 43.559943],
              [-6.443648, 43.560013],
              [-6.443665, 43.559869],
            ],
          ],
          [
            [
              [-6.867007, 43.570433],
              [-6.867141, 43.570401],
              [-6.867222, 43.570516],
              [-6.867053, 43.570575],
              [-6.867007, 43.570433],
            ],
          ],
          [
            [
              [-6.175563, 43.568484],
              [-6.175702, 43.568543],
              [-6.175549, 43.568692],
              [-6.175507, 43.568603],
              [-6.175563, 43.568484],
            ],
          ],
          [
            [
              [-5.859327, 43.656772],
              [-5.859461, 43.656724],
              [-5.859587, 43.656757],
              [-5.859246, 43.6569],
              [-5.859327, 43.656772],
            ],
          ],
          [
            [
              [-6.089442, 43.56367],
              [-6.089513, 43.563587],
              [-6.089727, 43.563672],
              [-6.089484, 43.56375],
              [-6.089442, 43.56367],
            ],
          ],
          [
            [
              [-6.177293, 43.568652],
              [-6.17744, 43.568621],
              [-6.177589, 43.568635],
              [-6.1772, 43.568763],
              [-6.177293, 43.568652],
            ],
          ],
          [
            [
              [-6.44424, 43.560842],
              [-6.444389, 43.560855],
              [-6.444126, 43.561007],
              [-6.444123, 43.560962],
              [-6.44424, 43.560842],
            ],
          ],
          [
            [
              [-5.865111, 43.665469],
              [-5.865219, 43.665403],
              [-5.865398, 43.665507],
              [-5.865302, 43.66559],
              [-5.865111, 43.665469],
            ],
          ],
          [
            [
              [-6.439081, 43.559178],
              [-6.439078, 43.559124],
              [-6.439429, 43.559186],
              [-6.439271, 43.559245],
              [-6.439081, 43.559178],
            ],
          ],
          [
            [
              [-6.445037, 43.562654],
              [-6.445146, 43.562615],
              [-6.444911, 43.562838],
              [-6.444931, 43.562747],
              [-6.445037, 43.562654],
            ],
          ],
          [
            [
              [-6.106767, 43.558653],
              [-6.106889, 43.558632],
              [-6.106937, 43.558838],
              [-6.106813, 43.558841],
              [-6.106767, 43.558653],
            ],
          ],
          [
            [
              [-6.023608, 43.587158],
              [-6.023742, 43.587118],
              [-6.023866, 43.587124],
              [-6.023675, 43.587264],
              [-6.023608, 43.587158],
            ],
          ],
          [
            [
              [-6.371146, 43.559349],
              [-6.371294, 43.559345],
              [-6.371348, 43.559424],
              [-6.371229, 43.559509],
              [-6.371146, 43.559349],
            ],
          ],
          [
            [
              [-6.3587, 43.553738],
              [-6.358786, 43.553717],
              [-6.358925, 43.553776],
              [-6.358695, 43.553864],
              [-6.3587, 43.553738],
            ],
          ],
          [
            [
              [-6.370172, 43.559657],
              [-6.370267, 43.559582],
              [-6.370441, 43.559586],
              [-6.370325, 43.559725],
              [-6.370172, 43.559657],
            ],
          ],
          [
            [
              [-6.431158, 43.553726],
              [-6.431342, 43.553712],
              [-6.431398, 43.553809],
              [-6.431164, 43.553843],
              [-6.431158, 43.553726],
            ],
          ],
          [
            [
              [-5.701305, 43.574137],
              [-5.701298, 43.574264],
              [-5.701138, 43.574294],
              [-5.701096, 43.574178],
              [-5.701305, 43.574137],
            ],
          ],
          [
            [
              [-6.11136, 43.55842],
              [-6.111495, 43.558399],
              [-6.11153, 43.558596],
              [-6.111434, 43.558652],
              [-6.11136, 43.55842],
            ],
          ],
          [
            [
              [-5.854653, 43.658114],
              [-5.854698, 43.658004],
              [-5.854835, 43.65801],
              [-5.854794, 43.6582],
              [-5.854653, 43.658114],
            ],
          ],
          [
            [
              [-6.01899, 43.587946],
              [-6.019059, 43.587836],
              [-6.019196, 43.58786],
              [-6.019094, 43.588034],
              [-6.01899, 43.587946],
            ],
          ],
          [
            [
              [-6.175815, 43.569035],
              [-6.17586, 43.568944],
              [-6.175985, 43.568977],
              [-6.175933, 43.569158],
              [-6.175815, 43.569035],
            ],
          ],
          [
            [
              [-5.860574, 43.660576],
              [-5.860749, 43.660599],
              [-5.860766, 43.660698],
              [-5.860517, 43.660668],
              [-5.860574, 43.660576],
            ],
          ],
          [
            [
              [-6.440293, 43.561546],
              [-6.440491, 43.56154],
              [-6.440498, 43.561657],
              [-6.440287, 43.561645],
              [-6.440293, 43.561546],
            ],
          ],
          [
            [
              [-5.858405, 43.655886],
              [-5.858539, 43.655847],
              [-5.858617, 43.655926],
              [-5.858386, 43.656021],
              [-5.858405, 43.655886],
            ],
          ],
          [
            [
              [-6.443961, 43.563326],
              [-6.444001, 43.563163],
              [-6.444143, 43.563266],
              [-6.444113, 43.563384],
              [-6.443961, 43.563326],
            ],
          ],
          [
            [
              [-5.833482, 43.654576],
              [-5.833478, 43.654428],
              [-5.833687, 43.654455],
              [-5.833676, 43.654584],
              [-5.833482, 43.654576],
            ],
          ],
          [
            [
              [-6.099634, 43.560558],
              [-6.099653, 43.560449],
              [-6.099802, 43.560454],
              [-6.099737, 43.560645],
              [-6.099634, 43.560558],
            ],
          ],
          [
            [
              [-5.900245, 43.628838],
              [-5.900402, 43.628753],
              [-5.900401, 43.628987],
              [-5.900311, 43.628917],
              [-5.900245, 43.628838],
            ],
          ],
          [
            [
              [-6.291022, 43.573751],
              [-6.29122, 43.573754],
              [-6.291178, 43.57389],
              [-6.291002, 43.57385],
              [-6.291022, 43.573751],
            ],
          ],
          [
            [
              [-5.881034, 43.630945],
              [-5.881142, 43.63087],
              [-5.881266, 43.630885],
              [-5.8811, 43.631042],
              [-5.881034, 43.630945],
            ],
          ],
          [
            [
              [-6.369638, 43.559394],
              [-6.369787, 43.559407],
              [-6.369686, 43.559599],
              [-6.36966, 43.559573],
              [-6.369638, 43.559394],
            ],
          ],
          [
            [
              [-5.858076, 43.656551],
              [-5.858138, 43.656541],
              [-5.858264, 43.656583],
              [-5.858059, 43.656696],
              [-5.858076, 43.656551],
            ],
          ],
          [
            [
              [-6.191129, 43.57998],
              [-6.191265, 43.579976],
              [-6.191189, 43.580185],
              [-6.191087, 43.580125],
              [-6.191129, 43.57998],
            ],
          ],
          [
            [
              [-5.865062, 43.663921],
              [-5.865094, 43.663822],
              [-5.865258, 43.66388],
              [-5.865128, 43.664001],
              [-5.865062, 43.663921],
            ],
          ],
          [
            [
              [-5.858955, 43.655197],
              [-5.858988, 43.655115],
              [-5.859125, 43.65513],
              [-5.859047, 43.655312],
              [-5.858955, 43.655197],
            ],
          ],
          [
            [
              [-6.17524, 43.569168],
              [-6.175349, 43.56912],
              [-6.175176, 43.569368],
              [-6.175147, 43.569279],
              [-6.17524, 43.569168],
            ],
          ],
          [
            [
              [-6.434303, 43.557008],
              [-6.434479, 43.557048],
              [-6.434424, 43.557185],
              [-6.434272, 43.557117],
              [-6.434303, 43.557008],
            ],
          ],
          [
            [
              [-6.853263, 43.570646],
              [-6.853335, 43.570607],
              [-6.85328, 43.570915],
              [-6.853175, 43.570829],
              [-6.853263, 43.570646],
            ],
          ],
          [
            [
              [-6.440256, 43.560241],
              [-6.440406, 43.560255],
              [-6.440255, 43.56043],
              [-6.440189, 43.56036],
              [-6.440256, 43.560241],
            ],
          ],
          [
            [
              [-6.437403, 43.560147],
              [-6.437499, 43.560081],
              [-6.437665, 43.560175],
              [-6.437581, 43.560232],
              [-6.437403, 43.560147],
            ],
          ],
          [
            [
              [-6.417369, 43.558795],
              [-6.41749, 43.558746],
              [-6.417531, 43.558808],
              [-6.41734, 43.558949],
              [-6.417369, 43.558795],
            ],
          ],
          [
            [
              [-6.419999, 43.558671],
              [-6.420117, 43.558577],
              [-6.420246, 43.558655],
              [-6.420077, 43.558741],
              [-6.419999, 43.558671],
            ],
          ],
          [
            [
              [-6.119, 43.558203],
              [-6.119133, 43.558127],
              [-6.119178, 43.558288],
              [-6.119042, 43.558301],
              [-6.119, 43.558203],
            ],
          ],
          [
            [
              [-6.177811, 43.568602],
              [-6.177905, 43.568518],
              [-6.17803, 43.568541],
              [-6.17789, 43.568707],
              [-6.177811, 43.568602],
            ],
          ],
          [
            [
              [-6.419025, 43.561365],
              [-6.419133, 43.561317],
              [-6.419199, 43.561378],
              [-6.41902, 43.561491],
              [-6.419025, 43.561365],
            ],
          ],
          [
            [
              [-6.369759, 43.559786],
              [-6.36987, 43.559783],
              [-6.369878, 43.559927],
              [-6.369716, 43.559914],
              [-6.369759, 43.559786],
            ],
          ],
          [
            [
              [-5.700797, 43.573591],
              [-5.70087, 43.573553],
              [-5.70096, 43.573632],
              [-5.700728, 43.573701],
              [-5.700797, 43.573591],
            ],
          ],
          [
            [
              [-6.438141, 43.560053],
              [-6.438224, 43.559987],
              [-6.438354, 43.560101],
              [-6.438258, 43.560157],
              [-6.438141, 43.560053],
            ],
          ],
          [
            [
              [-5.866808, 43.663599],
              [-5.866902, 43.663506],
              [-5.867004, 43.663567],
              [-5.866887, 43.663696],
              [-5.866808, 43.663599],
            ],
          ],
          [
            [
              [-6.874722, 43.564283],
              [-6.874821, 43.56428],
              [-6.874876, 43.564368],
              [-6.874656, 43.564411],
              [-6.874722, 43.564283],
            ],
          ],
          [
            [
              [-6.411449, 43.558765],
              [-6.411573, 43.55877],
              [-6.411655, 43.558894],
              [-6.41157, 43.558923],
              [-6.411449, 43.558765],
            ],
          ],
          [
            [
              [-6.030367, 43.589797],
              [-6.030503, 43.589775],
              [-6.030544, 43.589864],
              [-6.030361, 43.589923],
              [-6.030367, 43.589797],
            ],
          ],
          [
            [
              [-5.896562, 43.627005],
              [-5.896682, 43.626912],
              [-5.896725, 43.627037],
              [-5.896628, 43.627093],
              [-5.896562, 43.627005],
            ],
          ],
          [
            [
              [-5.880581, 43.630803],
              [-5.880625, 43.630703],
              [-5.880751, 43.630727],
              [-5.880648, 43.63091],
              [-5.880581, 43.630803],
            ],
          ],
          [
            [
              [-6.138995, 43.565353],
              [-6.139142, 43.565322],
              [-6.138851, 43.565438],
              [-6.138946, 43.565364],
              [-6.138995, 43.565353],
            ],
          ],
          [
            [
              [-6.438763, 43.559449],
              [-6.438866, 43.559527],
              [-6.438649, 43.559624],
              [-6.43868, 43.559515],
              [-6.438763, 43.559449],
            ],
          ],
          [
            [
              [-5.697087, 43.573985],
              [-5.696924, 43.573944],
              [-5.696922, 43.573899],
              [-5.697196, 43.573919],
              [-5.697087, 43.573985],
            ],
          ],
          [
            [
              [-6.190794, 43.579962],
              [-6.190917, 43.579959],
              [-6.190828, 43.580141],
              [-6.190738, 43.580081],
              [-6.190794, 43.579962],
            ],
          ],
          [
            [
              [-6.412665, 43.558809],
              [-6.412752, 43.558807],
              [-6.41283, 43.558876],
              [-6.412711, 43.558961],
              [-6.412665, 43.558809],
            ],
          ],
          [
            [
              [-5.863149, 43.653426],
              [-5.863245, 43.653361],
              [-5.863335, 43.653431],
              [-5.86313, 43.653553],
              [-5.863149, 43.653426],
            ],
          ],
          [
            [
              [-6.370835, 43.559115],
              [-6.371006, 43.559074],
              [-6.371012, 43.559182],
              [-6.370889, 43.559195],
              [-6.370835, 43.559115],
            ],
          ],
          [
            [
              [-6.872098, 43.566407],
              [-6.872246, 43.566411],
              [-6.872274, 43.566455],
              [-6.872193, 43.566548],
              [-6.872098, 43.566407],
            ],
          ],
          [
            [
              [-6.874737, 43.564508],
              [-6.874862, 43.564521],
              [-6.874797, 43.564677],
              [-6.87472, 43.564625],
              [-6.874737, 43.564508],
            ],
          ],
          [
            [
              [-6.139069, 43.565576],
              [-6.139178, 43.565528],
              [-6.139304, 43.56557],
              [-6.139123, 43.565665],
              [-6.139069, 43.565576],
            ],
          ],
          [
            [
              [-6.080599, 43.566286],
              [-6.080808, 43.566262],
              [-6.080801, 43.566362],
              [-6.080691, 43.566383],
              [-6.080599, 43.566286],
            ],
          ],
          [
            [
              [-5.86139, 43.665481],
              [-5.86141, 43.665381],
              [-5.861556, 43.665576],
              [-5.861419, 43.66557],
              [-5.86139, 43.665481],
            ],
          ],
          [
            [
              [-6.024628, 43.586492],
              [-6.024723, 43.586426],
              [-6.024872, 43.586431],
              [-6.024681, 43.586571],
              [-6.024628, 43.586492],
            ],
          ],
          [
            [
              [-6.105012, 43.562807],
              [-6.105056, 43.562716],
              [-6.105182, 43.562757],
              [-6.105079, 43.562922],
              [-6.105012, 43.562807],
            ],
          ],
          [
            [
              [-6.444163, 43.560151],
              [-6.444267, 43.560012],
              [-6.444333, 43.560091],
              [-6.444278, 43.56021],
              [-6.444163, 43.560151],
            ],
          ],
          [
            [
              [-6.033655, 43.588422],
              [-6.033776, 43.588356],
              [-6.033877, 43.588407],
              [-6.033647, 43.588512],
              [-6.033655, 43.588422],
            ],
          ],
          [
            [
              [-6.127982, 43.560938],
              [-6.128117, 43.560925],
              [-6.12809, 43.561115],
              [-6.127988, 43.561063],
              [-6.127982, 43.560938],
            ],
          ],
          [
            [
              [-6.019395, 43.587629],
              [-6.019503, 43.587555],
              [-6.019618, 43.587615],
              [-6.019399, 43.587701],
              [-6.019395, 43.587629],
            ],
          ],
          [
            [
              [-6.397708, 43.560993],
              [-6.397894, 43.560996],
              [-6.397933, 43.561031],
              [-6.397777, 43.561117],
              [-6.397708, 43.560993],
            ],
          ],
          [
            [
              [-6.08084, 43.566406],
              [-6.080976, 43.566384],
              [-6.081078, 43.566444],
              [-6.080833, 43.566505],
              [-6.08084, 43.566406],
            ],
          ],
          [
            [
              [-6.083056, 43.56712],
              [-6.083062, 43.566985],
              [-6.083188, 43.567036],
              [-6.083206, 43.567134],
              [-6.083056, 43.56712],
            ],
          ],
          [
            [
              [-6.415605, 43.561765],
              [-6.41568, 43.561762],
              [-6.415734, 43.561842],
              [-6.415601, 43.561909],
              [-6.415605, 43.561765],
            ],
          ],
          [
            [
              [-6.437954, 43.559824],
              [-6.437922, 43.55969],
              [-6.438059, 43.559704],
              [-6.438043, 43.559858],
              [-6.437954, 43.559824],
            ],
          ],
          [
            [
              [-6.438195, 43.559475],
              [-6.438318, 43.559471],
              [-6.438361, 43.559578],
              [-6.438239, 43.5596],
              [-6.438195, 43.559475],
            ],
          ],
          [
            [
              [-5.897294, 43.626752],
              [-5.897465, 43.626712],
              [-5.897186, 43.626827],
              [-5.897294, 43.626752],
            ],
          ],
          [
            [
              [-6.111767, 43.558148],
              [-6.11189, 43.558136],
              [-6.111862, 43.558317],
              [-6.11176, 43.558247],
              [-6.111767, 43.558148],
            ],
          ],
          [
            [
              [-6.39132, 43.559895],
              [-6.391457, 43.559918],
              [-6.391499, 43.560007],
              [-6.39134, 43.560039],
              [-6.39132, 43.559895],
            ],
          ],
          [
            [
              [-6.418323, 43.558811],
              [-6.418383, 43.558782],
              [-6.418485, 43.558833],
              [-6.418342, 43.558919],
              [-6.418323, 43.558811],
            ],
          ],
          [
            [
              [-6.381586, 43.55787],
              [-6.381724, 43.557893],
              [-6.381719, 43.558028],
              [-6.38158, 43.557978],
              [-6.381586, 43.55787],
            ],
          ],
          [
            [
              [-6.442631, 43.560206],
              [-6.442689, 43.560123],
              [-6.44283, 43.560218],
              [-6.442709, 43.560266],
              [-6.442631, 43.560206],
            ],
          ],
          [
            [
              [-6.439224, 43.561029],
              [-6.43927, 43.560973],
              [-6.439396, 43.560997],
              [-6.439352, 43.561097],
              [-6.439224, 43.561029],
            ],
          ],
          [
            [
              [-5.013105, 43.460151],
              [-5.013164, 43.460078],
              [-5.013316, 43.460165],
              [-5.013255, 43.460211],
              [-5.013105, 43.460151],
            ],
          ],
          [
            [
              [-6.367661, 43.55658],
              [-6.367785, 43.556594],
              [-6.367731, 43.556722],
              [-6.367616, 43.556662],
              [-6.367661, 43.55658],
            ],
          ],
          [
            [
              [-5.865468, 43.66437],
              [-5.865602, 43.664322],
              [-5.865607, 43.66443],
              [-5.865511, 43.664495],
              [-5.865468, 43.66437],
            ],
          ],
          [
            [
              [-6.874937, 43.564726],
              [-6.875074, 43.564748],
              [-6.875104, 43.564828],
              [-6.874982, 43.56485],
              [-6.874937, 43.564726],
            ],
          ],
          [
            [
              [-6.444762, 43.562609],
              [-6.444844, 43.562525],
              [-6.444937, 43.562639],
              [-6.44484, 43.562678],
              [-6.444762, 43.562609],
            ],
          ],
          [
            [
              [-6.100123, 43.560689],
              [-6.100245, 43.560649],
              [-6.100168, 43.560841],
              [-6.100079, 43.56078],
              [-6.100123, 43.560689],
            ],
          ],
          [
            [
              [-6.135495, 43.564945],
              [-6.135617, 43.564915],
              [-6.135361, 43.565003],
              [-6.13547, 43.564955],
              [-6.135495, 43.564945],
            ],
          ],
          [
            [
              [-6.106636, 43.558513],
              [-6.106734, 43.558492],
              [-6.10687, 43.558498],
              [-6.10669, 43.558602],
              [-6.106636, 43.558513],
            ],
          ],
          [
            [
              [-6.118708, 43.558058],
              [-6.118844, 43.558054],
              [-6.118676, 43.558167],
              [-6.118708, 43.558058],
            ],
          ],
          [
            [
              [-6.138709, 43.565568],
              [-6.138882, 43.565563],
              [-6.138863, 43.565663],
              [-6.138727, 43.565667],
              [-6.138709, 43.565568],
            ],
          ],
          [
            [
              [-6.100747, 43.56078],
              [-6.100829, 43.560688],
              [-6.100907, 43.560757],
              [-6.100863, 43.560867],
              [-6.100747, 43.56078],
            ],
          ],
          [
            [
              [-6.123235, 43.560365],
              [-6.123341, 43.560272],
              [-6.123396, 43.560361],
              [-6.123338, 43.560452],
              [-6.123235, 43.560365],
            ],
          ],
          [
            [
              [-5.861902, 43.6491],
              [-5.862023, 43.649052],
              [-5.862112, 43.649103],
              [-5.861881, 43.64919],
              [-5.861902, 43.6491],
            ],
          ],
          [
            [
              [-6.127945, 43.561425],
              [-6.128054, 43.561386],
              [-6.128027, 43.561576],
              [-6.127938, 43.561524],
              [-6.127945, 43.561425],
            ],
          ],
          [
            [
              [-6.442251, 43.560055],
              [-6.442282, 43.559937],
              [-6.442422, 43.560014],
              [-6.442364, 43.560079],
              [-6.442251, 43.560055],
            ],
          ],
          [
            [
              [-6.438733, 43.558937],
              [-6.43882, 43.558934],
              [-6.438877, 43.559058],
              [-6.438764, 43.559044],
              [-6.438733, 43.558937],
            ],
          ],
          [
            [
              [-6.358905, 43.553858],
              [-6.358965, 43.55382],
              [-6.359079, 43.553861],
              [-6.358985, 43.553954],
              [-6.358905, 43.553858],
            ],
          ],
          [
            [
              [-6.11487, 43.557586],
              [-6.114993, 43.557556],
              [-6.115073, 43.557671],
              [-6.114949, 43.557674],
              [-6.11487, 43.557586],
            ],
          ],
          [
            [
              [-6.438669, 43.55911],
              [-6.438832, 43.559141],
              [-6.438884, 43.559184],
              [-6.438761, 43.559206],
              [-6.438669, 43.55911],
            ],
          ],
          [
            [
              [-6.10738, 43.562157],
              [-6.107388, 43.562067],
              [-6.107513, 43.562091],
              [-6.107518, 43.562189],
              [-6.10738, 43.562157],
            ],
          ],
          [
            [
              [-5.862723, 43.662729],
              [-5.862743, 43.66262],
              [-5.862856, 43.662662],
              [-5.862849, 43.66277],
              [-5.862723, 43.662729],
            ],
          ],
          [
            [
              [-6.433428, 43.557296],
              [-6.433564, 43.557292],
              [-6.433544, 43.557382],
              [-6.433434, 43.557395],
              [-6.433428, 43.557296],
            ],
          ],
          [
            [
              [-5.861738, 43.665481],
              [-5.861873, 43.66546],
              [-5.861916, 43.665576],
              [-5.861791, 43.66557],
              [-5.861738, 43.665481],
            ],
          ],
          [
            [
              [-6.082866, 43.566792],
              [-6.082923, 43.566701],
              [-6.083026, 43.566761],
              [-6.08298, 43.566843],
              [-6.082866, 43.566792],
            ],
          ],
          [
            [
              [-6.429806, 43.553911],
              [-6.429901, 43.553827],
              [-6.429967, 43.553897],
              [-6.429847, 43.553973],
              [-6.429806, 43.553911],
            ],
          ],
          [
            [
              [-6.126325, 43.560749],
              [-6.126332, 43.56065],
              [-6.126445, 43.560674],
              [-6.126451, 43.560781],
              [-6.126325, 43.560749],
            ],
          ],
          [
            [
              [-6.438651, 43.559236],
              [-6.438765, 43.559269],
              [-6.438767, 43.559314],
              [-6.438581, 43.559301],
              [-6.438651, 43.559236],
            ],
          ],
          [
            [
              [-6.128019, 43.560693],
              [-6.1281, 43.560601],
              [-6.12811, 43.56079],
              [-6.128008, 43.560721],
              [-6.128019, 43.560693],
            ],
          ],
          [
            [
              [-6.443876, 43.562932],
              [-6.44391, 43.562877],
              [-6.444023, 43.562901],
              [-6.443929, 43.562994],
              [-6.443876, 43.562932],
            ],
          ],
          [
            [
              [-6.417208, 43.5588],
              [-6.417308, 43.558806],
              [-6.41719, 43.558917],
              [-6.41716, 43.558828],
              [-6.417208, 43.5588],
            ],
          ],
          [
            [
              [-6.438595, 43.557815],
              [-6.438652, 43.557724],
              [-6.438743, 43.557793],
              [-6.438673, 43.557867],
              [-6.438595, 43.557815],
            ],
          ],
          [
            [
              [-6.114873, 43.557388],
              [-6.114981, 43.557331],
              [-6.115047, 43.557411],
              [-6.114926, 43.557459],
              [-6.114873, 43.557388],
            ],
          ],
          [
            [
              [-6.438543, 43.557556],
              [-6.438626, 43.557481],
              [-6.438704, 43.557551],
              [-6.438633, 43.557607],
              [-6.438543, 43.557556],
            ],
          ],
          [
            [
              [-6.033321, 43.588665],
              [-6.033428, 43.588581],
              [-6.033481, 43.588661],
              [-6.033386, 43.588726],
              [-6.033321, 43.588665],
            ],
          ],
          [
            [
              [-6.137649, 43.565895],
              [-6.137607, 43.565797],
              [-6.137775, 43.565945],
              [-6.137649, 43.565895],
            ],
          ],
          [
            [
              [-6.359194, 43.553939],
              [-6.359295, 43.553963],
              [-6.359163, 43.554048],
              [-6.359146, 43.553959],
              [-6.359194, 43.553939],
            ],
          ],
          [
            [
              [-6.439003, 43.559325],
              [-6.439064, 43.559305],
              [-6.439129, 43.559357],
              [-6.439059, 43.559431],
              [-6.439003, 43.559325],
            ],
          ],
          [
            [
              [-6.135334, 43.565193],
              [-6.13538, 43.565119],
              [-6.135517, 43.565134],
              [-6.135447, 43.565217],
              [-6.135334, 43.565193],
            ],
          ],
          [
            [
              [-5.970204, 43.580771],
              [-5.970302, 43.580741],
              [-5.970404, 43.580802],
              [-5.970282, 43.580841],
              [-5.970204, 43.580771],
            ],
          ],
          [
            [
              [-6.438415, 43.559432],
              [-6.438523, 43.559375],
              [-6.438663, 43.559443],
              [-6.438614, 43.559453],
              [-6.438415, 43.559432],
            ],
          ],
          [
            [
              [-6.106408, 43.562283],
              [-6.106529, 43.562225],
              [-6.106547, 43.562324],
              [-6.106411, 43.562337],
              [-6.106408, 43.562283],
            ],
          ],
          [
            [
              [-6.442885, 43.559217],
              [-6.442983, 43.559214],
              [-6.442964, 43.559304],
              [-6.442877, 43.559307],
              [-6.442885, 43.559217],
            ],
          ],
          [
            [
              [-6.111514, 43.558047],
              [-6.111546, 43.557938],
              [-6.111623, 43.557999],
              [-6.111603, 43.55809],
              [-6.111514, 43.558047],
            ],
          ],
          [
            [
              [-6.444334, 43.560317],
              [-6.44442, 43.560314],
              [-6.444437, 43.560385],
              [-6.444364, 43.560406],
              [-6.444334, 43.560317],
            ],
          ],
          [
            [
              [-6.1191, 43.557975],
              [-6.119174, 43.557964],
              [-6.119276, 43.558024],
              [-6.119154, 43.558064],
              [-6.1191, 43.557975],
            ],
          ],
          [
            [
              [-6.110348, 43.557764],
              [-6.110458, 43.557734],
              [-6.110463, 43.557833],
              [-6.110339, 43.557818],
              [-6.110348, 43.557764],
            ],
          ],
          [
            [
              [-6.440222, 43.560512],
              [-6.440295, 43.560483],
              [-6.440357, 43.56049],
              [-6.4403, 43.560573],
              [-6.440222, 43.560512],
            ],
          ],
          [
            [
              [-6.440136, 43.560731],
              [-6.440204, 43.56063],
              [-6.440246, 43.560719],
              [-6.440136, 43.560731],
            ],
          ],
          [
            [
              [-6.438832, 43.559366],
              [-6.438867, 43.55932],
              [-6.438932, 43.559372],
              [-6.438872, 43.559419],
              [-6.438832, 43.559366],
            ],
          ],
          [
            [
              [-6.443013, 43.559303],
              [-6.443063, 43.559301],
              [-6.443103, 43.559354],
              [-6.443055, 43.559392],
              [-6.443013, 43.559303],
            ],
          ],
          [
            [
              [-6.130585, 43.560533],
              [-6.130556, 43.560444],
              [-6.130694, 43.560485],
              [-6.130585, 43.560533],
            ],
          ],
          [
            [
              [-6.128378, 43.561179],
              [-6.128377, 43.56117],
              [-6.128503, 43.561193],
              [-6.128432, 43.561267],
              [-6.128378, 43.561179],
            ],
          ],
          [
            [
              [-6.44017, 43.560892],
              [-6.440244, 43.560899],
              [-6.440247, 43.560944],
              [-6.440173, 43.560955],
              [-6.44017, 43.560892],
            ],
          ],
          [
            [
              [-6.114831, 43.557786],
              [-6.114915, 43.557729],
              [-6.114944, 43.557818],
              [-6.114831, 43.557786],
            ],
          ],
          [
            [
              [-6.440203, 43.560828],
              [-6.440251, 43.560809],
              [-6.440292, 43.560862],
              [-6.440205, 43.560864],
              [-6.440203, 43.560828],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5613",
      properties: { name: "Ávila", density: 0, path: "/world/Spain/Ávila" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.013857, 41.16425],
              [-4.863677, 41.094015],
              [-4.814966, 41.103449],
              [-4.813524, 41.129377],
              [-4.786188, 41.143559],
              [-4.722102, 41.149318],
              [-4.71272, 41.062102],
              [-4.638289, 41.031306],
              [-4.606748, 40.970803],
              [-4.566352, 40.956199],
              [-4.559565, 40.885849],
              [-4.531567, 40.858642],
              [-4.556708, 40.836334],
              [-4.546388, 40.825547],
              [-4.493773, 40.833518],
              [-4.473838, 40.774654],
              [-4.431127, 40.742384],
              [-4.419623, 40.633428],
              [-4.256039, 40.651461],
              [-4.273987, 40.679943],
              [-4.252903, 40.689533],
              [-4.160234, 40.689823],
              [-4.173521, 40.673976],
              [-4.161183, 40.623465],
              [-4.245439, 40.602447],
              [-4.262141, 40.60518],
              [-4.271102, 40.629023],
              [-4.290709, 40.627823],
              [-4.278572, 40.603172],
              [-4.289262, 40.564194],
              [-4.328218, 40.533757],
              [-4.315679, 40.464766],
              [-4.341252, 40.428678],
              [-4.321089, 40.410309],
              [-4.42173, 40.406229],
              [-4.443729, 40.3857],
              [-4.438636, 40.352754],
              [-4.460596, 40.341381],
              [-4.455762, 40.319351],
              [-4.502898, 40.314893],
              [-4.526788, 40.344873],
              [-4.546225, 40.341825],
              [-4.529379, 40.293125],
              [-4.569479, 40.257298],
              [-4.565676, 40.218188],
              [-4.654235, 40.198067],
              [-4.687258, 40.212002],
              [-4.698141, 40.282192],
              [-4.76159, 40.260902],
              [-4.803469, 40.275265],
              [-4.818245, 40.254774],
              [-4.807675, 40.235319],
              [-4.924675, 40.169984],
              [-4.925454, 40.136036],
              [-4.956293, 40.133348],
              [-4.954546, 40.121126],
              [-5.013344, 40.111056],
              [-5.006672, 40.129936],
              [-5.027046, 40.158382],
              [-5.068128, 40.150287],
              [-5.141411, 40.091751],
              [-5.199792, 40.08245],
              [-5.215311, 40.107847],
              [-5.335977, 40.115818],
              [-5.367995, 40.164109],
              [-5.36967, 40.218312],
              [-5.344409, 40.264758],
              [-5.429097, 40.252607],
              [-5.47091, 40.207853],
              [-5.530964, 40.194719],
              [-5.61004, 40.215481],
              [-5.622, 40.245881],
              [-5.649199, 40.24972],
              [-5.692662, 40.292106],
              [-5.737135, 40.293755],
              [-5.673031, 40.348535],
              [-5.686359, 40.355369],
              [-5.67844, 40.387016],
              [-5.691189, 40.421994],
              [-5.634173, 40.460446],
              [-5.560924, 40.477099],
              [-5.574602, 40.444251],
              [-5.525927, 40.422364],
              [-5.51147, 40.426292],
              [-5.509831, 40.463664],
              [-5.465547, 40.465915],
              [-5.428508, 40.564468],
              [-5.524692, 40.540733],
              [-5.516222, 40.585379],
              [-5.50086, 40.597351],
              [-5.499658, 40.581587],
              [-5.44765, 40.579593],
              [-5.382954, 40.62076],
              [-5.365892, 40.648774],
              [-5.385457, 40.671449],
              [-5.214437, 40.757827],
              [-5.218992, 40.772972],
              [-5.161507, 40.814003],
              [-5.163809, 40.868384],
              [-5.115045, 40.902806],
              [-5.127601, 40.954116],
              [-5.104841, 40.960254],
              [-5.089841, 40.996629],
              [-5.106605, 41.029499],
              [-5.146543, 41.043642],
              [-5.148586, 41.092154],
              [-5.129166, 41.105378],
              [-5.124043, 41.135601],
              [-5.100129, 41.1471],
              [-5.059862, 41.138622],
              [-5.013857, 41.16425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5615",
      properties: { name: "Badajoz", density: 0, path: "/world/Spain/Badajoz" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.677889, 39.434849],
              [-4.671698, 39.413549],
              [-4.704211, 39.364451],
              [-4.70666, 39.327726],
              [-4.761012, 39.320789],
              [-4.708229, 39.209868],
              [-4.64897, 39.164905],
              [-4.810273, 39.199416],
              [-4.853171, 39.152438],
              [-4.874404, 39.100434],
              [-4.826935, 39.064119],
              [-4.832793, 39.04616],
              [-4.879987, 39.032425],
              [-4.962504, 39.058727],
              [-4.918221, 38.957045],
              [-4.832415, 38.938907],
              [-4.847319, 38.882403],
              [-4.91494, 38.88744],
              [-4.943484, 38.854378],
              [-4.989994, 38.739758],
              [-5.09919, 38.707653],
              [-5.184505, 38.719695],
              [-5.167322, 38.680469],
              [-5.185764, 38.67417],
              [-5.184233, 38.659728],
              [-5.208485, 38.667697],
              [-5.214745, 38.648567],
              [-5.223775, 38.656712],
              [-5.292237, 38.610808],
              [-5.308514, 38.577619],
              [-5.371748, 38.583572],
              [-5.39272, 38.553483],
              [-5.383169, 38.540478],
              [-5.406932, 38.543695],
              [-5.418617, 38.51273],
              [-5.479488, 38.484678],
              [-5.484455, 38.46339],
              [-5.519215, 38.463467],
              [-5.568135, 38.433393],
              [-5.584493, 38.400281],
              [-5.559248, 38.366885],
              [-5.575648, 38.329106],
              [-5.525958, 38.264124],
              [-5.520427, 38.207567],
              [-5.538707, 38.200605],
              [-5.539673, 38.160685],
              [-5.57586, 38.149643],
              [-5.584789, 38.131706],
              [-5.633432, 38.138122],
              [-5.693631, 38.08374],
              [-5.7349, 38.086255],
              [-5.738816, 38.132819],
              [-5.695258, 38.149712],
              [-5.705786, 38.169382],
              [-5.684062, 38.157978],
              [-5.688612, 38.181877],
              [-5.728162, 38.197417],
              [-5.874684, 38.15834],
              [-5.880665, 38.132281],
              [-5.926378, 38.105013],
              [-5.908982, 38.062846],
              [-5.934224, 38.046875],
              [-5.953688, 37.995346],
              [-6.115307, 37.981767],
              [-6.18024, 37.941026],
              [-6.201517, 37.962062],
              [-6.303625, 37.978337],
              [-6.352706, 38.007398],
              [-6.367246, 38.048919],
              [-6.413902, 38.058563],
              [-6.452439, 38.055959],
              [-6.443609, 38.046957],
              [-6.47656, 38.007834],
              [-6.545693, 38.028119],
              [-6.582329, 38.020845],
              [-6.580526, 38.052772],
              [-6.622104, 38.097008],
              [-6.732818, 38.089954],
              [-6.770087, 38.099456],
              [-6.770055, 38.112113],
              [-6.80834, 38.111238],
              [-6.795092, 38.178541],
              [-6.855336, 38.179027],
              [-6.937815, 38.20693],
              [-6.93647, 38.220523],
              [-6.971846, 38.20236],
              [-7.01121, 38.204551],
              [-7.028572, 38.182636],
              [-7.066391, 38.186572],
              [-7.085606, 38.169395],
              [-7.142872, 38.240476],
              [-7.150557, 38.275744],
              [-7.169611, 38.279273],
              [-7.280525, 38.420346],
              [-7.335938, 38.43494],
              [-7.295576, 38.477767],
              [-7.32262, 38.47823],
              [-7.303038, 38.544913],
              [-7.244132, 38.62363],
              [-7.269694, 38.638792],
              [-7.255303, 38.725894],
              [-7.128549, 38.813847],
              [-7.089168, 38.818873],
              [-7.068319, 38.853818],
              [-7.039338, 38.864778],
              [-7.033137, 38.879178],
              [-7.052036, 38.907164],
              [-6.951315, 39.023998],
              [-6.995871, 39.09926],
              [-7.039096, 39.118384],
              [-7.105797, 39.099316],
              [-7.143806, 39.109254],
              [-7.134586, 39.171095],
              [-7.241353, 39.208544],
              [-7.247776, 39.253952],
              [-7.231396, 39.278361],
              [-7.203858, 39.265698],
              [-7.155199, 39.314451],
              [-7.155416, 39.329664],
              [-7.168511, 39.320152],
              [-7.177832, 39.331219],
              [-7.154297, 39.367511],
              [-7.073875, 39.381221],
              [-7.062969, 39.372272],
              [-7.079124, 39.351415],
              [-7.098588, 39.359124],
              [-7.102099, 39.346373],
              [-7.077166, 39.310181],
              [-7.044128, 39.326925],
              [-7.032613, 39.367638],
              [-7.009267, 39.388485],
              [-7.067025, 39.414297],
              [-7.049385, 39.436673],
              [-6.883225, 39.38276],
              [-6.80207, 39.389497],
              [-6.797006, 39.344511],
              [-6.746746, 39.321751],
              [-6.761175, 39.276611],
              [-6.800859, 39.239419],
              [-6.767502, 39.188372],
              [-6.681001, 39.177152],
              [-6.659735, 39.19907],
              [-6.633366, 39.196843],
              [-6.580096, 39.179795],
              [-6.574253, 39.158357],
              [-6.539945, 39.155262],
              [-6.540357, 39.181695],
              [-6.379096, 39.16277],
              [-6.324115, 39.186431],
              [-6.311598, 39.111607],
              [-6.230855, 39.089796],
              [-6.203144, 39.056501],
              [-6.157449, 39.05547],
              [-6.142098, 39.03158],
              [-6.018604, 39.129119],
              [-6.009308, 39.052525],
              [-5.942289, 39.108514],
              [-5.926658, 39.104638],
              [-5.937643, 39.083785],
              [-5.924695, 39.083183],
              [-5.83345, 39.110329],
              [-5.810367, 39.144581],
              [-5.755177, 39.132296],
              [-5.687045, 39.082058],
              [-5.645936, 39.102323],
              [-5.604401, 39.100394],
              [-5.583623, 39.118109],
              [-5.600875, 39.134347],
              [-5.576561, 39.150906],
              [-5.613244, 39.188216],
              [-5.551013, 39.215876],
              [-5.528145, 39.191473],
              [-5.514578, 39.209713],
              [-5.459841, 39.158366],
              [-5.412671, 39.156956],
              [-5.384022, 39.174186],
              [-5.349053, 39.233557],
              [-5.340011, 39.333311],
              [-5.24762, 39.301824],
              [-5.123546, 39.336498],
              [-5.113585, 39.387466],
              [-5.08987, 39.390363],
              [-5.053835, 39.362426],
              [-4.984642, 39.371609],
              [-4.974033, 39.394684],
              [-4.936473, 39.396099],
              [-4.870935, 39.368313],
              [-4.774614, 39.397194],
              [-4.690277, 39.451789],
              [-4.677889, 39.434849],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5618",
      properties: {
        name: "Barcelona",
        density: 0,
        path: "/world/Spain/Barcelona",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.918206, 42.317783],
              [2.069897, 42.291596],
              [2.016731, 42.232982],
              [2.030185, 42.215871],
              [2.053296, 42.219534],
              [2.060513, 42.177891],
              [2.004833, 42.1621],
              [1.995545, 42.172301],
              [2.0073, 42.19407],
              [1.96521, 42.199092],
              [1.965892, 42.178787],
              [1.99149, 42.182589],
              [2.003984, 42.149515],
              [2.060383, 42.135358],
              [2.092321, 42.144303],
              [2.125922, 42.113111],
              [2.144697, 42.131409],
              [2.201067, 42.124287],
              [2.215121, 42.142094],
              [2.270022, 42.145213],
              [2.299549, 42.134198],
              [2.294921, 42.102679],
              [2.366025, 42.12135],
              [2.400177, 42.102755],
              [2.394499, 42.086764],
              [2.417527, 42.064975],
              [2.458042, 42.07928],
              [2.49035, 42.067324],
              [2.482218, 42.04925],
              [2.50616, 42.032274],
              [2.498901, 42.00152],
              [2.46878, 41.993835],
              [2.488023, 41.984668],
              [2.474314, 41.969345],
              [2.429103, 41.968933],
              [2.481179, 41.916019],
              [2.442697, 41.88749],
              [2.407118, 41.895721],
              [2.382607, 41.871516],
              [2.369665, 41.885501],
              [2.32798, 41.883047],
              [2.325989, 41.855663],
              [2.351551, 41.809665],
              [2.472175, 41.789597],
              [2.469147, 41.777787],
              [2.515973, 41.761903],
              [2.547308, 41.719069],
              [2.657596, 41.747692],
              [2.776861, 41.735489],
              [2.75787, 41.67913],
              [2.777784, 41.649999],
              [2.555437, 41.579365],
              [2.369044, 41.490446],
              [2.266465, 41.459137],
              [2.19831, 41.386891],
              [2.171807, 41.334988],
              [2.186643, 41.366946],
              [2.184748, 41.380824],
              [2.115852, 41.290482],
              [1.647354, 41.192745],
              [1.640979, 41.235366],
              [1.624011, 41.222941],
              [1.583106, 41.236078],
              [1.570156, 41.260734],
              [1.619705, 41.2537],
              [1.636791, 41.295952],
              [1.588222, 41.293562],
              [1.595505, 41.308848],
              [1.558659, 41.343146],
              [1.57796, 41.362457],
              [1.536815, 41.385359],
              [1.509289, 41.394439],
              [1.47304, 41.380758],
              [1.490165, 41.401483],
              [1.475096, 41.429452],
              [1.495201, 41.435609],
              [1.468484, 41.443963],
              [1.484562, 41.474922],
              [1.396347, 41.512893],
              [1.408723, 41.534928],
              [1.448583, 41.537072],
              [1.431028, 41.555661],
              [1.454483, 41.569186],
              [1.419681, 41.582606],
              [1.392848, 41.575092],
              [1.40664, 41.600477],
              [1.359622, 41.613538],
              [1.391674, 41.634087],
              [1.448319, 41.6361],
              [1.41504, 41.674582],
              [1.423393, 41.684119],
              [1.381923, 41.705755],
              [1.424849, 41.732775],
              [1.403024, 41.745302],
              [1.417005, 41.794628],
              [1.471083, 41.800932],
              [1.46112, 41.776686],
              [1.482588, 41.771131],
              [1.482448, 41.791007],
              [1.504835, 41.787888],
              [1.530724, 41.752563],
              [1.572583, 41.782189],
              [1.593595, 41.777928],
              [1.58881, 41.804725],
              [1.611457, 41.817772],
              [1.630307, 41.85878],
              [1.619122, 41.881533],
              [1.568929, 41.880016],
              [1.653005, 41.928799],
              [1.650066, 41.979662],
              [1.675291, 41.982534],
              [1.669455, 41.969906],
              [1.682746, 41.967383],
              [1.719227, 41.984468],
              [1.697741, 42.014685],
              [1.697437, 41.985184],
              [1.647856, 42.000154],
              [1.655855, 42.020915],
              [1.665966, 42.003505],
              [1.699285, 42.028901],
              [1.692477, 42.046134],
              [1.670234, 42.044423],
              [1.691296, 42.087103],
              [1.722051, 42.091087],
              [1.698555, 42.135721],
              [1.739068, 42.176529],
              [1.738041, 42.189419],
              [1.680348, 42.206238],
              [1.68039, 42.224361],
              [1.704563, 42.238818],
              [1.684603, 42.256438],
              [1.69014, 42.277105],
              [1.670307, 42.281697],
              [1.918206, 42.317783],
            ],
            [
              [2.021793, 42.127677],
              [2.00253, 42.125567],
              [2.007542, 42.107252],
              [2.029785, 42.121239],
              [2.021793, 42.127677],
            ],
            [
              [1.711086, 41.890393],
              [1.71875, 41.882398],
              [1.724909, 41.893029],
              [1.709388, 41.897108],
              [1.711086, 41.890393],
            ],
            [
              [1.628974, 41.310655],
              [1.637479, 41.309682],
              [1.6425, 41.312955],
              [1.633235, 41.313491],
              [1.628974, 41.310655],
            ],
          ],
          [
            [
              [1.656713, 42.114444],
              [1.683146, 42.114429],
              [1.691872, 42.110281],
              [1.644874, 42.11262],
              [1.656713, 42.114444],
            ],
          ],
          [
            [
              [2.639822, 41.604823],
              [2.639618, 41.604824],
              [2.639611, 41.604878],
              [2.639841, 41.604903],
              [2.639822, 41.604823],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5649",
      properties: {
        name: "Bizkaia/Vizcaya",
        density: 0,
        path: "/world/Spain/Bizkaia/Vizcaya",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.751495, 43.456648],
              [-2.738707, 43.429687],
              [-2.695678, 43.406763],
              [-2.646309, 43.415941],
              [-2.61863, 43.391864],
              [-2.509732, 43.377366],
              [-2.502606, 43.364224],
              [-2.459739, 43.335567],
              [-2.412604, 43.321269],
              [-2.439005, 43.29491],
              [-2.418061, 43.267602],
              [-2.436581, 43.243707],
              [-2.497945, 43.219923],
              [-2.484113, 43.170903],
              [-2.509717, 43.161443],
              [-2.501144, 43.096535],
              [-2.661674, 43.07427],
              [-2.633697, 43.044818],
              [-2.633624, 43.01991],
              [-2.894164, 43.046421],
              [-2.975483, 43.09947],
              [-2.935813, 43.136213],
              [-2.954166, 43.159706],
              [-2.946394, 43.170869],
              [-2.980044, 43.201856],
              [-3.006779, 43.19659],
              [-3.027249, 43.216969],
              [-3.040069, 43.209883],
              [-3.016504, 43.202829],
              [-3.0269, 43.161369],
              [-3.074709, 43.146355],
              [-3.11134, 43.143498],
              [-3.157973, 43.178031],
              [-3.224093, 43.170132],
              [-3.253417, 43.198581],
              [-3.417646, 43.133427],
              [-3.449059, 43.23591],
              [-3.340126, 43.27803],
              [-3.332405, 43.301878],
              [-3.279447, 43.290052],
              [-3.225228, 43.308937],
              [-3.208705, 43.283638],
              [-3.151522, 43.306852],
              [-3.153287, 43.353315],
              [-3.114772, 43.350476],
              [-3.109356, 43.364673],
              [-3.084135, 43.377931],
              [-3.104456, 43.364668],
              [-3.081224, 43.351856],
              [-3.075502, 43.354795],
              [-3.072291, 43.362712],
              [-3.059541, 43.361828],
              [-3.047852, 43.359348],
              [-3.074897, 43.354156],
              [-3.031839, 43.34663],
              [-3.043778, 43.339134],
              [-3.020716, 43.325544],
              [-3.010246, 43.337242],
              [-3.027815, 43.341949],
              [-3.012592, 43.347741],
              [-3.039605, 43.373308],
              [-2.970472, 43.41338],
              [-2.946881, 43.412138],
              [-2.946909, 43.436027],
              [-2.806978, 43.430489],
              [-2.751495, 43.456648],
            ],
            [
              [-3.274784, 43.264545],
              [-3.305841, 43.253481],
              [-3.285045, 43.197696],
              [-3.26519, 43.205767],
              [-3.269774, 43.23205],
              [-3.251061, 43.248679],
              [-3.249418, 43.259659],
              [-3.274784, 43.264545],
            ],
          ],
          [
            [
              [-3.048958, 43.046086],
              [-3.01855, 43.037662],
              [-3.032905, 43.020333],
              [-2.986012, 43.012097],
              [-2.985723, 42.992133],
              [-2.9557, 42.998852],
              [-3.004503, 42.982688],
              [-3.038478, 42.981916],
              [-3.060257, 43.00351],
              [-3.08899, 43.001637],
              [-3.048958, 43.046086],
            ],
          ],
          [
            [
              [-2.684215, 43.426083],
              [-2.681412, 43.425742],
              [-2.681439, 43.422923],
              [-2.687259, 43.427523],
              [-2.684215, 43.426083],
            ],
          ],
          [
            [
              [-2.493674, 43.368255],
              [-2.494244, 43.36652],
              [-2.496587, 43.365315],
              [-2.496957, 43.366856],
              [-2.493674, 43.368255],
            ],
          ],
          [
            [
              [-2.774347, 43.450527],
              [-2.776606, 43.451333],
              [-2.775871, 43.452997],
              [-2.774725, 43.451906],
              [-2.774347, 43.450527],
            ],
          ],
          [
            [
              [-2.934844, 43.441108],
              [-2.934846, 43.439253],
              [-2.935031, 43.438821],
              [-2.935697, 43.440055],
              [-2.934844, 43.441108],
            ],
          ],
          [
            [
              [-3.078549, 43.354073],
              [-3.07929, 43.354324],
              [-3.07934, 43.354675],
              [-3.078056, 43.354631],
              [-3.078549, 43.354073],
            ],
          ],
          [
            [
              [-2.815322, 43.433241],
              [-2.815569, 43.43334],
              [-2.815406, 43.434006],
              [-2.815135, 43.433682],
              [-2.815322, 43.433241],
            ],
          ],
          [
            [
              [-2.571617, 43.391094],
              [-2.571851, 43.391068],
              [-2.571724, 43.391581],
              [-2.571466, 43.391481],
              [-2.571617, 43.391094],
            ],
          ],
          [
            [
              [-2.418484, 43.32671],
              [-2.418645, 43.326702],
              [-2.419714, 43.327095],
              [-2.418397, 43.326809],
              [-2.418484, 43.32671],
            ],
          ],
          [
            [
              [-2.789981, 43.443633],
              [-2.790439, 43.44358],
              [-2.7905, 43.443688],
              [-2.790203, 43.443858],
              [-2.789981, 43.443633],
            ],
          ],
          [
            [
              [-2.41924, 43.327687],
              [-2.41929, 43.32757],
              [-2.41966, 43.327536],
              [-2.419472, 43.327886],
              [-2.41924, 43.327687],
            ],
          ],
          [
            [
              [-2.844644, 43.4326],
              [-2.844768, 43.432538],
              [-2.845026, 43.433042],
              [-2.844717, 43.432889],
              [-2.844644, 43.4326],
            ],
          ],
          [
            [
              [-2.422035, 43.329493],
              [-2.422294, 43.329485],
              [-2.422439, 43.329765],
              [-2.422168, 43.329719],
              [-2.422035, 43.329493],
            ],
          ],
          [
            [
              [-2.445186, 43.333714],
              [-2.445101, 43.333605],
              [-2.444855, 43.333505],
              [-2.445226, 43.333471],
              [-2.445186, 43.333714],
            ],
          ],
          [
            [
              [-2.753113, 43.456948],
              [-2.753323, 43.457003],
              [-2.753507, 43.45721],
              [-2.753198, 43.457183],
              [-2.753113, 43.456948],
            ],
          ],
          [
            [
              [-2.439577, 43.33338],
              [-2.439922, 43.333373],
              [-2.44002, 43.333454],
              [-2.439636, 43.333624],
              [-2.439577, 43.33338],
            ],
          ],
          [
            [
              [-2.789873, 43.442606],
              [-2.790108, 43.442561],
              [-2.790157, 43.442724],
              [-2.789996, 43.442795],
              [-2.789873, 43.442606],
            ],
          ],
          [
            [
              [-2.451559, 43.335654],
              [-2.451621, 43.3356],
              [-2.451769, 43.335646],
              [-2.451557, 43.335843],
              [-2.451559, 43.335654],
            ],
          ],
          [
            [
              [-2.449332, 43.334976],
              [-2.449456, 43.334878],
              [-2.449617, 43.334888],
              [-2.449442, 43.335076],
              [-2.449332, 43.334976],
            ],
          ],
          [
            [
              [-2.449924, 43.335051],
              [-2.45006, 43.33498],
              [-2.450146, 43.33507],
              [-2.449923, 43.335177],
              [-2.449924, 43.335051],
            ],
          ],
          [
            [
              [-2.439604, 43.333038],
              [-2.439593, 43.332975],
              [-2.439778, 43.332976],
              [-2.439764, 43.333093],
              [-2.439604, 43.333038],
            ],
          ],
          [
            [
              [-2.41902, 43.327478],
              [-2.41907, 43.327371],
              [-2.419193, 43.327434],
              [-2.41918, 43.327488],
              [-2.41902, 43.327478],
            ],
          ],
          [
            [
              [-2.439615, 43.333182],
              [-2.439789, 43.333147],
              [-2.439738, 43.333255],
              [-2.439615, 43.333182],
            ],
          ],
          [
            [
              [-2.452358, 43.335937],
              [-2.452383, 43.335919],
              [-2.452481, 43.336009],
              [-2.452358, 43.335937],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5619",
      properties: { name: "Burgos", density: 0, path: "/world/Spain/Burgos" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.249184, 43.195997],
              [-3.224093, 43.170132],
              [-3.158626, 43.17813],
              [-3.141174, 43.16541],
              [-3.181786, 43.120879],
              [-3.133941, 43.097234],
              [-3.158187, 43.074016],
              [-3.140312, 43.06715],
              [-3.141957, 43.029409],
              [-3.177802, 43.021984],
              [-3.060257, 43.00351],
              [-3.041475, 42.969921],
              [-2.981189, 42.939435],
              [-3.018906, 42.908512],
              [-3.092102, 42.898779],
              [-3.149273, 42.917063],
              [-3.144732, 42.934682],
              [-3.224729, 42.949692],
              [-3.248545, 42.912145],
              [-3.277239, 42.90158],
              [-3.282609, 42.875514],
              [-3.205533, 42.827401],
              [-3.222176, 42.838636],
              [-3.190686, 42.869463],
              [-3.162823, 42.856737],
              [-3.11494, 42.889249],
              [-3.11315, 42.861064],
              [-3.159229, 42.804591],
              [-3.123255, 42.789326],
              [-3.147913, 42.756697],
              [-3.049654, 42.765606],
              [-3.04201, 42.73952],
              [-3.016953, 42.741084],
              [-2.980631, 42.703819],
              [-2.931358, 42.708402],
              [-2.902502, 42.693603],
              [-2.89606, 42.655033],
              [-2.858093, 42.638205],
              [-2.916008, 42.619521],
              [-2.946451, 42.639317],
              [-3.084152, 42.640687],
              [-3.05983, 42.589188],
              [-3.134271, 42.542053],
              [-3.072572, 42.528923],
              [-3.094695, 42.488074],
              [-3.046004, 42.445154],
              [-3.063299, 42.42491],
              [-3.056881, 42.370273],
              [-3.082652, 42.397384],
              [-3.078179, 42.41575],
              [-3.101347, 42.416777],
              [-3.10527, 42.386369],
              [-3.084811, 42.385386],
              [-3.063462, 42.356697],
              [-3.109668, 42.351448],
              [-3.088589, 42.245725],
              [-3.129063, 42.197648],
              [-3.034373, 42.085963],
              [-2.931176, 42.086775],
              [-2.913624, 42.022852],
              [-2.950525, 41.993899],
              [-2.970792, 41.929273],
              [-3.008072, 41.922224],
              [-3.016771, 41.886906],
              [-3.097165, 41.881058],
              [-3.136198, 41.811942],
              [-3.171351, 41.793015],
              [-3.194496, 41.828926],
              [-3.234389, 41.840642],
              [-3.243342, 41.863997],
              [-3.283758, 41.88381],
              [-3.275454, 41.768671],
              [-3.354385, 41.737254],
              [-3.358397, 41.693421],
              [-3.393397, 41.690613],
              [-3.459156, 41.589632],
              [-3.529154, 41.612627],
              [-3.550438, 41.596588],
              [-3.541567, 41.577607],
              [-3.566514, 41.579957],
              [-3.572622, 41.565399],
              [-3.616836, 41.577158],
              [-3.661048, 41.566078],
              [-3.728305, 41.517638],
              [-3.723719, 41.479205],
              [-3.739483, 41.450784],
              [-3.769752, 41.460152],
              [-3.766189, 41.537929],
              [-3.803788, 41.524576],
              [-3.787641, 41.485865],
              [-3.823878, 41.481134],
              [-3.894554, 41.552153],
              [-3.981631, 41.583662],
              [-4.005927, 41.619362],
              [-4.035283, 41.620623],
              [-4.026038, 41.692381],
              [-4.076701, 41.733565],
              [-4.055787, 41.740685],
              [-4.037998, 41.789852],
              [-4.090067, 41.830864],
              [-4.079319, 41.848448],
              [-4.088825, 41.87065],
              [-4.037575, 41.872772],
              [-3.9807, 41.900617],
              [-3.98134, 41.930703],
              [-3.929142, 41.959365],
              [-3.891981, 41.957939],
              [-3.889748, 41.990913],
              [-3.9102, 41.996967],
              [-4.032977, 41.954082],
              [-4.014775, 41.998585],
              [-3.971074, 42.033215],
              [-3.981677, 42.051048],
              [-4.039248, 42.053352],
              [-4.079887, 42.036783],
              [-4.104106, 42.061627],
              [-4.103036, 42.087044],
              [-4.077486, 42.082676],
              [-4.056207, 42.100914],
              [-4.103779, 42.109689],
              [-4.120216, 42.128128],
              [-4.205013, 42.133609],
              [-4.188644, 42.17638],
              [-4.231439, 42.20845],
              [-4.228764, 42.250375],
              [-4.244106, 42.259404],
              [-4.264823, 42.352514],
              [-4.28052, 42.363785],
              [-4.272729, 42.385719],
              [-4.321588, 42.379899],
              [-4.330956, 42.390948],
              [-4.316228, 42.409984],
              [-4.335344, 42.430611],
              [-4.317094, 42.458928],
              [-4.242594, 42.456238],
              [-4.254393, 42.467916],
              [-4.247042, 42.494716],
              [-4.286204, 42.511971],
              [-4.272148, 42.519981],
              [-4.277691, 42.544685],
              [-4.303331, 42.555357],
              [-4.305346, 42.570806],
              [-4.270812, 42.581388],
              [-4.285435, 42.581981],
              [-4.293898, 42.605256],
              [-4.263873, 42.617956],
              [-4.293171, 42.690897],
              [-4.266693, 42.705296],
              [-4.257042, 42.688112],
              [-4.144969, 42.752516],
              [-4.109554, 42.733879],
              [-4.085259, 42.764084],
              [-3.990663, 42.770984],
              [-3.965694, 42.758599],
              [-3.910953, 42.768386],
              [-3.894446, 42.804538],
              [-3.863375, 42.786458],
              [-3.822913, 42.799066],
              [-3.826742, 42.870857],
              [-3.863736, 42.88981],
              [-3.880189, 42.851386],
              [-3.914832, 42.860084],
              [-3.897473, 42.880285],
              [-3.924349, 42.889221],
              [-3.892934, 42.887363],
              [-3.910584, 42.910926],
              [-3.907819, 42.914523],
              [-3.868605, 42.899076],
              [-3.833546, 42.917986],
              [-3.839539, 42.93978],
              [-3.887383, 42.948767],
              [-3.891277, 42.925485],
              [-3.933543, 42.899602],
              [-3.929987, 42.914849],
              [-3.974617, 42.912353],
              [-3.987171, 42.94054],
              [-3.945465, 43.005773],
              [-3.909261, 43.016097],
              [-3.890746, 43.041826],
              [-3.850786, 43.039248],
              [-3.831769, 43.063826],
              [-3.846961, 43.084399],
              [-3.764569, 43.081034],
              [-3.754882, 43.100548],
              [-3.704677, 43.116275],
              [-3.651753, 43.180792],
              [-3.612449, 43.169518],
              [-3.603544, 43.149637],
              [-3.434564, 43.131653],
              [-3.249184, 43.195997],
            ],
            [
              [-4.089605, 42.160566],
              [-4.111844, 42.158579],
              [-4.110037, 42.143186],
              [-4.062137, 42.13158],
              [-4.065637, 42.163502],
              [-4.089605, 42.160566],
            ],
            [
              [-4.034554, 42.106578],
              [-4.027599, 42.087853],
              [-4.031936, 42.078636],
              [-4.024374, 42.08355],
              [-4.034554, 42.106578],
            ],
          ],
          [
            [
              [-2.827707, 42.787973],
              [-2.731553, 42.794513],
              [-2.643708, 42.764716],
              [-2.602347, 42.770631],
              [-2.589366, 42.750323],
              [-2.558302, 42.762733],
              [-2.572629, 42.725172],
              [-2.608979, 42.695962],
              [-2.577768, 42.668213],
              [-2.522024, 42.684032],
              [-2.517397, 42.646332],
              [-2.634851, 42.647795],
              [-2.659175, 42.672456],
              [-2.7679, 42.666011],
              [-2.784503, 42.698942],
              [-2.868464, 42.739921],
              [-2.835474, 42.79627],
              [-2.827707, 42.787973],
            ],
          ],
          [
            [
              [-2.934887, 42.594407],
              [-2.945214, 42.603886],
              [-2.9345, 42.614075],
              [-2.922629, 42.600829],
              [-2.934887, 42.594407],
            ],
          ],
          [
            [
              [-4.247809, 42.736042],
              [-4.251332, 42.726547],
              [-4.26315, 42.730479],
              [-4.260633, 42.738891],
              [-4.247809, 42.736042],
            ],
          ],
          [
            [
              [-2.991941, 42.604557],
              [-2.994525, 42.615491],
              [-2.985222, 42.61422],
              [-2.982857, 42.610797],
              [-2.991941, 42.604557],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5620",
      properties: { name: "Cáceres", density: 0, path: "/world/Spain/Cáceres" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.230476, 40.486602],
              [-6.157053, 40.456605],
              [-6.147017, 40.436118],
              [-6.116127, 40.441585],
              [-6.129484, 40.420788],
              [-6.065127, 40.396934],
              [-6.085166, 40.390492],
              [-6.072156, 40.387001],
              [-6.082378, 40.363212],
              [-6.105716, 40.356795],
              [-6.016017, 40.340292],
              [-6.005939, 40.305805],
              [-5.937782, 40.281067],
              [-5.917174, 40.277697],
              [-5.884245, 40.326604],
              [-5.799337, 40.353751],
              [-5.781992, 40.312456],
              [-5.802735, 40.297633],
              [-5.793144, 40.286705],
              [-5.692662, 40.292106],
              [-5.649199, 40.24972],
              [-5.622, 40.245881],
              [-5.61452, 40.218298],
              [-5.548115, 40.197243],
              [-5.47091, 40.207853],
              [-5.429097, 40.252607],
              [-5.343209, 40.264052],
              [-5.36967, 40.218312],
              [-5.367995, 40.164109],
              [-5.335977, 40.115818],
              [-5.369011, 40.100085],
              [-5.365144, 39.982919],
              [-5.40611, 39.877729],
              [-5.323613, 39.891727],
              [-5.284049, 39.863875],
              [-5.312588, 39.824275],
              [-5.308079, 39.760025],
              [-5.254823, 39.752342],
              [-5.206465, 39.797559],
              [-5.1669, 39.799882],
              [-5.158459, 39.759661],
              [-5.173964, 39.751255],
              [-5.137794, 39.714999],
              [-5.161765, 39.642437],
              [-5.206625, 39.597232],
              [-4.952513, 39.394993],
              [-4.974638, 39.394331],
              [-4.984182, 39.371743],
              [-5.053835, 39.362426],
              [-5.08987, 39.390363],
              [-5.113585, 39.387466],
              [-5.123546, 39.336498],
              [-5.24762, 39.301824],
              [-5.340011, 39.333311],
              [-5.349053, 39.233557],
              [-5.384022, 39.174186],
              [-5.412671, 39.156956],
              [-5.459841, 39.158366],
              [-5.514578, 39.209713],
              [-5.528145, 39.191473],
              [-5.551013, 39.215876],
              [-5.613244, 39.188216],
              [-5.576561, 39.150906],
              [-5.600875, 39.134347],
              [-5.583623, 39.118109],
              [-5.604401, 39.100394],
              [-5.645936, 39.102323],
              [-5.687045, 39.082058],
              [-5.755177, 39.132296],
              [-5.810367, 39.144581],
              [-5.83345, 39.110329],
              [-5.924695, 39.083183],
              [-5.937643, 39.083785],
              [-5.926658, 39.104638],
              [-5.942289, 39.108514],
              [-6.009308, 39.052525],
              [-6.018604, 39.129119],
              [-6.142098, 39.03158],
              [-6.157449, 39.05547],
              [-6.203144, 39.056501],
              [-6.230855, 39.089796],
              [-6.311598, 39.111607],
              [-6.324115, 39.186431],
              [-6.379096, 39.16277],
              [-6.540357, 39.181695],
              [-6.539945, 39.155262],
              [-6.574253, 39.158357],
              [-6.580096, 39.179795],
              [-6.633366, 39.196843],
              [-6.659735, 39.19907],
              [-6.681001, 39.177152],
              [-6.767502, 39.188372],
              [-6.800859, 39.239419],
              [-6.761175, 39.276611],
              [-6.746746, 39.321751],
              [-6.797006, 39.344511],
              [-6.804405, 39.390393],
              [-6.883225, 39.38276],
              [-7.049385, 39.436673],
              [-7.067025, 39.414297],
              [-7.009267, 39.388485],
              [-7.045914, 39.324927],
              [-7.079567, 39.311394],
              [-7.103043, 39.354562],
              [-7.079124, 39.351415],
              [-7.062969, 39.372272],
              [-7.125736, 39.381174],
              [-7.161228, 39.361337],
              [-7.177806, 39.330788],
              [-7.152021, 39.321481],
              [-7.203858, 39.265698],
              [-7.308313, 39.336829],
              [-7.323483, 39.381934],
              [-7.296499, 39.4589],
              [-7.381271, 39.491807],
              [-7.391121, 39.529879],
              [-7.429745, 39.534507],
              [-7.460881, 39.572631],
              [-7.495892, 39.586444],
              [-7.544833, 39.663754],
              [-7.332841, 39.641171],
              [-7.25014, 39.667197],
              [-7.149302, 39.652901],
              [-7.015341, 39.670792],
              [-6.976716, 39.772703],
              [-6.986908, 39.810098],
              [-6.903531, 39.871307],
              [-6.90894, 39.919155],
              [-6.885769, 39.940442],
              [-6.885634, 39.980407],
              [-6.864086, 40.012452],
              [-6.941638, 40.112872],
              [-6.993438, 40.112725],
              [-7.0119, 40.126873],
              [-7.015645, 40.178057],
              [-7.028873, 40.185314],
              [-7.012164, 40.226483],
              [-6.964559, 40.237582],
              [-6.950545, 40.258252],
              [-6.865089, 40.270634],
              [-6.834522, 40.24758],
              [-6.755603, 40.238923],
              [-6.718575, 40.269201],
              [-6.690137, 40.242662],
              [-6.671753, 40.263232],
              [-6.587022, 40.270721],
              [-6.556175, 40.290777],
              [-6.56041, 40.329049],
              [-6.536284, 40.347244],
              [-6.438315, 40.372773],
              [-6.418826, 40.39929],
              [-6.371396, 40.401874],
              [-6.344643, 40.442776],
              [-6.275326, 40.458516],
              [-6.230476, 40.486602],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5621",
      properties: { name: "Cádiz", density: 0, path: "/world/Spain/Cádiz" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.431317, 37.050394],
              [-5.420108, 37.013214],
              [-5.460287, 36.975303],
              [-5.43895, 36.935288],
              [-5.412849, 36.940661],
              [-5.392391, 36.973284],
              [-5.302105, 37.037363],
              [-5.300224, 37.013742],
              [-5.234533, 36.94105],
              [-5.142383, 37.003862],
              [-5.115146, 36.988957],
              [-5.095581, 36.968295],
              [-5.110115, 36.959332],
              [-5.086769, 36.905091],
              [-5.105079, 36.897936],
              [-5.133292, 36.839102],
              [-5.170405, 36.82355],
              [-5.222013, 36.842722],
              [-5.269776, 36.884215],
              [-5.301583, 36.869643],
              [-5.340714, 36.819418],
              [-5.319819, 36.786732],
              [-5.290935, 36.77517],
              [-5.324626, 36.724009],
              [-5.324029, 36.673341],
              [-5.380718, 36.631781],
              [-5.455914, 36.619583],
              [-5.460816, 36.588784],
              [-5.523325, 36.531123],
              [-5.61048, 36.544234],
              [-5.60829, 36.512289],
              [-5.52425, 36.497712],
              [-5.492395, 36.536469],
              [-5.429324, 36.533156],
              [-5.347157, 36.418647],
              [-5.320085, 36.325071],
              [-5.27783, 36.337123],
              [-5.252352, 36.311224],
              [-5.317048, 36.229189],
              [-5.338646, 36.15346],
              [-5.365033, 36.155014],
              [-5.352154, 36.156214],
              [-5.391416, 36.180926],
              [-5.440648, 36.162059],
              [-5.447271, 36.139102],
              [-5.443116, 36.128732],
              [-5.435909, 36.140713],
              [-5.443598, 36.127442],
              [-5.440448, 36.124009],
              [-5.43001, 36.140752],
              [-5.442566, 36.119603],
              [-5.431081, 36.105587],
              [-5.443538, 36.089641],
              [-5.429537, 36.070962],
              [-5.611082, 36.000131],
              [-5.652501, 36.053156],
              [-5.68929, 36.06736],
              [-5.723817, 36.060055],
              [-5.776339, 36.087763],
              [-5.797244, 36.078273],
              [-5.9149, 36.184767],
              [-6.034468, 36.181171],
              [-6.102765, 36.284108],
              [-6.151094, 36.306525],
              [-6.182209, 36.368571],
              [-6.218753, 36.392553],
              [-6.288834, 36.523423],
              [-6.309601, 36.5286],
              [-6.305054, 36.536744],
              [-6.2905, 36.537705],
              [-6.28729, 36.54284],
              [-6.279357, 36.543004],
              [-6.292294, 36.532871],
              [-6.276838, 36.540739],
              [-6.268631, 36.531999],
              [-6.281934, 36.527475],
              [-6.256429, 36.507822],
              [-6.257597, 36.473114],
              [-6.229836, 36.462604],
              [-6.173244, 36.521409],
              [-6.20053, 36.528727],
              [-6.207867, 36.509579],
              [-6.245627, 36.508963],
              [-6.257973, 36.52919],
              [-6.229118, 36.525073],
              [-6.232817, 36.584915],
              [-6.249382, 36.572281],
              [-6.238218, 36.58448],
              [-6.265933, 36.579831],
              [-6.299819, 36.61786],
              [-6.3201, 36.611971],
              [-6.311242, 36.61784],
              [-6.330145, 36.624368],
              [-6.326167, 36.610378],
              [-6.337361, 36.625284],
              [-6.353716, 36.61421],
              [-6.388263, 36.62899],
              [-6.442779, 36.737748],
              [-6.342357, 36.794415],
              [-6.353215, 36.855037],
              [-6.331009, 36.898803],
              [-6.278289, 36.910848],
              [-6.253793, 36.893907],
              [-6.156153, 36.885649],
              [-6.104447, 36.857256],
              [-5.891055, 36.842192],
              [-5.87652, 36.894711],
              [-5.845508, 36.915212],
              [-5.713768, 36.920386],
              [-5.667567, 36.97599],
              [-5.618088, 36.940161],
              [-5.587108, 36.947013],
              [-5.583404, 36.915092],
              [-5.533822, 36.89345],
              [-5.546116, 36.930182],
              [-5.469732, 36.983948],
              [-5.510986, 37.033603],
              [-5.431317, 37.050394],
            ],
          ],
          [
            [
              [-6.469162, 36.738043],
              [-6.468314, 36.737074],
              [-6.468499, 36.732253],
              [-6.472051, 36.737246],
              [-6.469162, 36.738043],
            ],
          ],
          [
            [
              [-6.220778, 36.381135],
              [-6.219786, 36.378954],
              [-6.221266, 36.380257],
              [-6.225345, 36.396178],
              [-6.220778, 36.381135],
            ],
          ],
          [
            [
              [-6.315427, 36.528643],
              [-6.312785, 36.528212],
              [-6.312322, 36.527828],
              [-6.317795, 36.527874],
              [-6.315427, 36.528643],
            ],
          ],
          [
            [
              [-6.219208, 36.386016],
              [-6.219125, 36.384541],
              [-6.218927, 36.383798],
              [-6.220328, 36.386977],
              [-6.219208, 36.386016],
            ],
          ],
          [
            [
              [-6.220889, 36.387332],
              [-6.221298, 36.387249],
              [-6.221491, 36.390046],
              [-6.220704, 36.387977],
              [-6.220889, 36.387332],
            ],
          ],
          [
            [
              [-6.215817, 36.38244],
              [-6.216343, 36.38248],
              [-6.215445, 36.383964],
              [-6.214801, 36.383207],
              [-6.215817, 36.38244],
            ],
          ],
          [
            [
              [-5.293093, 36.245497],
              [-5.295034, 36.245271],
              [-5.29542, 36.245525],
              [-5.294276, 36.245988],
              [-5.293093, 36.245497],
            ],
          ],
          [
            [
              [-5.434645, 36.065234],
              [-5.43428, 36.06489],
              [-5.43521, 36.064826],
              [-5.435113, 36.065278],
              [-5.434645, 36.065234],
            ],
          ],
          [
            [
              [-6.457325, 36.735559],
              [-6.458096, 36.735407],
              [-6.458107, 36.735644],
              [-6.457232, 36.735864],
              [-6.457325, 36.735559],
            ],
          ],
          [
            [
              [-6.007924, 36.18142],
              [-6.008475, 36.181377],
              [-6.009246, 36.181493],
              [-6.008906, 36.181601],
              [-6.007924, 36.18142],
            ],
          ],
          [
            [
              [-6.00578, 36.181265],
              [-6.006204, 36.18129],
              [-6.006665, 36.181432],
              [-6.005928, 36.18145],
              [-6.00578, 36.181265],
            ],
          ],
          [
            [
              [-5.423617, 36.077031],
              [-5.423962, 36.077069],
              [-5.424082, 36.077355],
              [-5.423973, 36.077411],
              [-5.423617, 36.077031],
            ],
          ],
          [
            [
              [-5.423346, 36.076171],
              [-5.423659, 36.076228],
              [-5.423902, 36.076538],
              [-5.423768, 36.076514],
              [-5.423346, 36.076171],
            ],
          ],
          [
            [
              [-5.436041, 36.065485],
              [-5.436196, 36.065464],
              [-5.436213, 36.065653],
              [-5.436033, 36.065602],
              [-5.436041, 36.065485],
            ],
          ],
          [
            [
              [-5.436311, 36.065597],
              [-5.436363, 36.065496],
              [-5.436427, 36.065774],
              [-5.436315, 36.065732],
              [-5.436311, 36.065597],
            ],
          ],
          [
            [
              [-5.436467, 36.065278],
              [-5.436464, 36.06517],
              [-5.436598, 36.065203],
              [-5.436537, 36.065358],
              [-5.436467, 36.065278],
            ],
          ],
          [
            [
              [-5.437219, 36.06446],
              [-5.437295, 36.064387],
              [-5.437407, 36.064411],
              [-5.437331, 36.064494],
              [-5.437219, 36.06446],
            ],
          ],
          [
            [
              [-5.437334, 36.064233],
              [-5.437399, 36.064159],
              [-5.437532, 36.064166],
              [-5.437468, 36.064239],
              [-5.437334, 36.064233],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5608",
      properties: {
        name: "Cantabria",
        density: 0,
        path: "/world/Spain/Cantabria",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.590244, 43.513559],
              [-3.546344, 43.507707],
              [-3.497832, 43.472243],
              [-3.430884, 43.461995],
              [-3.429784, 43.44101],
              [-3.464968, 43.444601],
              [-3.491682, 43.424797],
              [-3.463569, 43.43128],
              [-3.46472, 43.403938],
              [-3.450051, 43.436061],
              [-3.425241, 43.413248],
              [-3.32379, 43.419161],
              [-3.320764, 43.40279],
              [-3.221292, 43.393903],
              [-3.225887, 43.387609],
              [-3.211891, 43.37484],
              [-3.153287, 43.353315],
              [-3.151522, 43.306852],
              [-3.209506, 43.283538],
              [-3.225228, 43.308937],
              [-3.279447, 43.290052],
              [-3.332405, 43.301878],
              [-3.340126, 43.27803],
              [-3.449059, 43.23591],
              [-3.417646, 43.133427],
              [-3.603544, 43.149637],
              [-3.612449, 43.169518],
              [-3.651017, 43.18104],
              [-3.704677, 43.116275],
              [-3.754882, 43.100548],
              [-3.764569, 43.081034],
              [-3.846961, 43.084399],
              [-3.831769, 43.063826],
              [-3.849866, 43.040218],
              [-3.890746, 43.041826],
              [-3.909261, 43.016097],
              [-3.952997, 43.000101],
              [-3.990978, 42.931088],
              [-3.973716, 42.91192],
              [-3.929642, 42.914699],
              [-3.933543, 42.899602],
              [-3.867095, 42.954829],
              [-3.830572, 42.929201],
              [-3.866807, 42.899234],
              [-3.907819, 42.914523],
              [-3.892934, 42.887363],
              [-3.924349, 42.889221],
              [-3.897473, 42.880285],
              [-3.914832, 42.860084],
              [-3.879821, 42.851326],
              [-3.863736, 42.88981],
              [-3.834788, 42.877363],
              [-3.816032, 42.810561],
              [-3.862043, 42.786522],
              [-3.894446, 42.804538],
              [-3.910953, 42.768386],
              [-3.977295, 42.75805],
              [-3.999652, 42.768979],
              [-4.003555, 42.831407],
              [-4.054688, 42.763027],
              [-4.08134, 42.761464],
              [-4.10517, 42.793027],
              [-4.149915, 42.789309],
              [-4.185429, 42.817297],
              [-4.158091, 42.837659],
              [-4.140447, 42.829002],
              [-4.12612, 42.856637],
              [-4.149901, 42.871134],
              [-4.214234, 42.847173],
              [-4.240249, 42.956533],
              [-4.348485, 42.972581],
              [-4.398114, 43.034868],
              [-4.447551, 43.05854],
              [-4.462752, 43.060535],
              [-4.475713, 43.038702],
              [-4.520932, 43.047368],
              [-4.558449, 43.019515],
              [-4.606084, 43.035767],
              [-4.638131, 43.016673],
              [-4.722822, 43.017239],
              [-4.743257, 43.028159],
              [-4.743985, 43.05645],
              [-4.766114, 43.055633],
              [-4.766497, 43.074547],
              [-4.816903, 43.092658],
              [-4.851426, 43.125945],
              [-4.834567, 43.157463],
              [-4.848068, 43.178136],
              [-4.734156, 43.189879],
              [-4.719602, 43.236454],
              [-4.730945, 43.257336],
              [-4.631883, 43.267653],
              [-4.606552, 43.300554],
              [-4.555242, 43.288098],
              [-4.547773, 43.268687],
              [-4.523513, 43.27817],
              [-4.522349, 43.335778],
              [-4.538809, 43.355926],
              [-4.511499, 43.393659],
              [-4.389356, 43.389921],
              [-4.340665, 43.405872],
              [-4.32802, 43.391252],
              [-4.280254, 43.387997],
              [-4.175226, 43.401054],
              [-4.081232, 43.438322],
              [-3.978686, 43.440875],
              [-3.957098, 43.455817],
              [-3.945545, 43.471148],
              [-3.894935, 43.470826],
              [-3.785204, 43.491766],
              [-3.763286, 43.467196],
              [-3.814332, 43.449475],
              [-3.825338, 43.451674],
              [-3.815154, 43.437006],
              [-3.830686, 43.435157],
              [-3.803722, 43.426615],
              [-3.822708, 43.413576],
              [-3.816016, 43.40181],
              [-3.779137, 43.420967],
              [-3.791279, 43.432212],
              [-3.776114, 43.449406],
              [-3.76526, 43.446282],
              [-3.746131, 43.451532],
              [-3.777179, 43.458215],
              [-3.734618, 43.459278],
              [-3.590244, 43.513559],
            ],
            [
              [-4.1106, 42.823846],
              [-4.104376, 42.797196],
              [-4.089064, 42.799891],
              [-4.088522, 42.818492],
              [-4.1106, 42.823846],
            ],
            [
              [-4.174616, 42.823082],
              [-4.174558, 42.811618],
              [-4.161095, 42.813115],
              [-4.163076, 42.820948],
              [-4.174616, 42.823082],
            ],
            [
              [-3.770827, 43.465164],
              [-3.770231, 43.465925],
              [-3.772014, 43.465201],
              [-3.771602, 43.464871],
              [-3.770827, 43.465164],
            ],
            [
              [-3.942538, 43.470975],
              [-3.943288, 43.470744],
              [-3.941852, 43.470576],
              [-3.942031, 43.470988],
              [-3.942538, 43.470975],
            ],
            [
              [-3.530836, 43.492715],
              [-3.530591, 43.493022],
              [-3.531051, 43.493236],
              [-3.531173, 43.493092],
              [-3.530836, 43.492715],
            ],
            [
              [-3.334688, 43.415195],
              [-3.334764, 43.415374],
              [-3.335281, 43.415202],
              [-3.33501, 43.415176],
              [-3.334688, 43.415195],
            ],
            [
              [-3.508996, 43.478119],
              [-3.509171, 43.478388],
              [-3.509393, 43.478234],
              [-3.509094, 43.478001],
              [-3.508996, 43.478119],
            ],
            [
              [-3.623765, 43.498237],
              [-3.62373, 43.498463],
              [-3.623855, 43.498597],
              [-3.624, 43.498254],
              [-3.623765, 43.498237],
            ],
            [
              [-3.508345, 43.478671],
              [-3.508544, 43.478787],
              [-3.508692, 43.478687],
              [-3.508418, 43.478508],
              [-3.508345, 43.478671],
            ],
            [
              [-3.630414, 43.497706],
              [-3.630083, 43.498032],
              [-3.630059, 43.498095],
              [-3.630196, 43.498148],
              [-3.630414, 43.497706],
            ],
            [
              [-3.77697, 43.472759],
              [-3.777008, 43.47284],
              [-3.777551, 43.4728],
              [-3.77718, 43.472731],
              [-3.77697, 43.472759],
            ],
            [
              [-3.509714, 43.478187],
              [-3.509764, 43.47834],
              [-3.509937, 43.478313],
              [-3.509848, 43.478025],
              [-3.509714, 43.478187],
            ],
            [
              [-3.573892, 43.507483],
              [-3.573831, 43.507573],
              [-3.573746, 43.507691],
              [-3.574005, 43.507635],
              [-3.573892, 43.507483],
            ],
            [
              [-3.63093, 43.497388],
              [-3.630684, 43.497524],
              [-3.6306, 43.497723],
              [-3.631017, 43.497369],
              [-3.63093, 43.497388],
            ],
            [
              [-3.631008, 43.497703],
              [-3.630886, 43.49791],
              [-3.630912, 43.498018],
              [-3.631143, 43.497675],
              [-3.631008, 43.497703],
            ],
            [
              [-3.628298, 43.497645],
              [-3.628459, 43.49768],
              [-3.628606, 43.497509],
              [-3.628298, 43.497645],
            ],
          ],
          [
            [
              [-3.260215, 43.263579],
              [-3.249418, 43.259659],
              [-3.269774, 43.23205],
              [-3.26519, 43.205767],
              [-3.285809, 43.197902],
              [-3.304598, 43.258689],
              [-3.260215, 43.263579],
            ],
          ],
          [
            [
              [-3.729112, 43.476314],
              [-3.727457, 43.471345],
              [-3.730471, 43.470074],
              [-3.731778, 43.472866],
              [-3.729112, 43.476314],
            ],
          ],
          [
            [
              [-4.025137, 43.453291],
              [-4.021996, 43.453184],
              [-4.020964, 43.452815],
              [-4.026444, 43.453072],
              [-4.025137, 43.453291],
            ],
          ],
          [
            [
              [-4.500262, 43.396157],
              [-4.500806, 43.396176],
              [-4.501436, 43.397186],
              [-4.49939, 43.397339],
              [-4.500262, 43.396157],
            ],
          ],
          [
            [
              [-3.527654, 43.496557],
              [-3.527568, 43.495197],
              [-3.529246, 43.496162],
              [-3.528288, 43.497022],
              [-3.527654, 43.496557],
            ],
          ],
          [
            [
              [-3.755477, 43.47355],
              [-3.754607, 43.473178],
              [-3.755424, 43.472272],
              [-3.756854, 43.472911],
              [-3.755477, 43.47355],
            ],
          ],
          [
            [
              [-4.482985, 43.396067],
              [-4.482037, 43.39571],
              [-4.484417, 43.395544],
              [-4.482707, 43.396331],
              [-4.482985, 43.396067],
            ],
          ],
          [
            [
              [-3.313292, 43.405044],
              [-3.313699, 43.404989],
              [-3.313864, 43.405889],
              [-3.312979, 43.406584],
              [-3.313292, 43.405044],
            ],
          ],
          [
            [
              [-3.933399, 43.473283],
              [-3.932868, 43.47327],
              [-3.932834, 43.472712],
              [-3.934139, 43.473124],
              [-3.933399, 43.473283],
            ],
          ],
          [
            [
              [-4.021468, 43.451181],
              [-4.020827, 43.450574],
              [-4.022654, 43.451188],
              [-4.022332, 43.451146],
              [-4.021468, 43.451181],
            ],
          ],
          [
            [
              [-3.916213, 43.474881],
              [-3.91606, 43.475405],
              [-3.914582, 43.475795],
              [-3.914861, 43.475423],
              [-3.916213, 43.474881],
            ],
          ],
          [
            [
              [-3.255286, 43.402891],
              [-3.256003, 43.402871],
              [-3.257437, 43.403336],
              [-3.256845, 43.403383],
              [-3.255286, 43.402891],
            ],
          ],
          [
            [
              [-3.919607, 43.47371],
              [-3.920163, 43.473661],
              [-3.918305, 43.474225],
              [-3.918918, 43.473932],
              [-3.919607, 43.47371],
            ],
          ],
          [
            [
              [-3.794196, 43.418099],
              [-3.795245, 43.41802],
              [-3.795421, 43.41828],
              [-3.794596, 43.418457],
              [-3.794196, 43.418099],
            ],
          ],
          [
            [
              [-3.931634, 43.473478],
              [-3.932033, 43.472871],
              [-3.932743, 43.47319],
              [-3.932637, 43.473551],
              [-3.931634, 43.473478],
            ],
          ],
          [
            [
              [-3.917516, 43.474429],
              [-3.917216, 43.474981],
              [-3.916608, 43.474851],
              [-3.916876, 43.474633],
              [-3.917516, 43.474429],
            ],
          ],
          [
            [
              [-4.013194, 43.452353],
              [-4.013902, 43.452545],
              [-4.013905, 43.452716],
              [-4.013093, 43.452957],
              [-4.013194, 43.452353],
            ],
          ],
          [
            [
              [-3.925996, 43.474271],
              [-3.925608, 43.47395],
              [-3.926521, 43.473825],
              [-3.926575, 43.474122],
              [-3.925996, 43.474271],
            ],
          ],
          [
            [
              [-3.791423, 43.429987],
              [-3.791891, 43.429876],
              [-3.79239, 43.430277],
              [-3.792057, 43.430316],
              [-3.791423, 43.429987],
            ],
          ],
          [
            [
              [-3.218377, 43.386542],
              [-3.219093, 43.386667],
              [-3.217661, 43.386742],
              [-3.217772, 43.38667],
              [-3.218377, 43.386542],
            ],
          ],
          [
            [
              [-3.939216, 43.472038],
              [-3.939675, 43.472142],
              [-3.939471, 43.472549],
              [-3.939224, 43.47256],
              [-3.939216, 43.472038],
            ],
          ],
          [
            [
              [-3.404707, 43.417552],
              [-3.405151, 43.417326],
              [-3.405767, 43.417361],
              [-3.405563, 43.417599],
              [-3.404707, 43.417552],
            ],
          ],
          [
            [
              [-3.917575, 43.476644],
              [-3.917729, 43.47703],
              [-3.917122, 43.476972],
              [-3.917354, 43.476781],
              [-3.917575, 43.476644],
            ],
          ],
          [
            [
              [-4.015536, 43.452719],
              [-4.016143, 43.452786],
              [-4.016173, 43.453119],
              [-4.01594, 43.453184],
              [-4.015536, 43.452719],
            ],
          ],
          [
            [
              [-3.521538, 43.495954],
              [-3.521239, 43.495695],
              [-3.521705, 43.495233],
              [-3.521946, 43.495853],
              [-3.521538, 43.495954],
            ],
          ],
          [
            [
              [-3.524833, 43.496525],
              [-3.525043, 43.496443],
              [-3.52522, 43.496856],
              [-3.524837, 43.49693],
              [-3.524833, 43.496525],
            ],
          ],
          [
            [
              [-3.315003, 43.406417],
              [-3.315237, 43.4062],
              [-3.315522, 43.40637],
              [-3.315351, 43.406731],
              [-3.315003, 43.406417],
            ],
          ],
          [
            [
              [-3.946189, 43.470423],
              [-3.946598, 43.4705],
              [-3.946432, 43.470934],
              [-3.946095, 43.47073],
              [-3.946189, 43.470423],
            ],
          ],
          [
            [
              [-4.476041, 43.39572],
              [-4.476339, 43.395728],
              [-4.47633, 43.396044],
              [-4.475772, 43.395822],
              [-4.476041, 43.39572],
            ],
          ],
          [
            [
              [-3.408653, 43.416964],
              [-3.40885, 43.416909],
              [-3.409162, 43.417448],
              [-3.408642, 43.417189],
              [-3.408653, 43.416964],
            ],
          ],
          [
            [
              [-3.913906, 43.476079],
              [-3.914191, 43.476086],
              [-3.914194, 43.476311],
              [-3.913837, 43.476413],
              [-3.913906, 43.476079],
            ],
          ],
          [
            [
              [-3.499411, 43.476342],
              [-3.499507, 43.476008],
              [-3.499892, 43.47616],
              [-3.499843, 43.476304],
              [-3.499411, 43.476342],
            ],
          ],
          [
            [
              [-4.021363, 43.452316],
              [-4.021697, 43.452286],
              [-4.022142, 43.452345],
              [-4.021031, 43.452382],
              [-4.021363, 43.452316],
            ],
          ],
          [
            [
              [-3.653732, 43.494379],
              [-3.653791, 43.494163],
              [-3.654025, 43.494053],
              [-3.653969, 43.494594],
              [-3.653732, 43.494379],
            ],
          ],
          [
            [
              [-3.938404, 43.471468],
              [-3.938724, 43.471385],
              [-3.938839, 43.471618],
              [-3.938617, 43.471683],
              [-3.938404, 43.471468],
            ],
          ],
          [
            [
              [-3.410068, 43.418057],
              [-3.410502, 43.418218],
              [-3.410343, 43.418515],
              [-3.41017, 43.418507],
              [-3.410068, 43.418057],
            ],
          ],
          [
            [
              [-4.015702, 43.452303],
              [-4.015737, 43.452141],
              [-4.016195, 43.4522],
              [-4.016136, 43.452345],
              [-4.015702, 43.452303],
            ],
          ],
          [
            [
              [-3.829787, 43.487778],
              [-3.829949, 43.487867],
              [-3.829617, 43.488068],
              [-3.829504, 43.487897],
              [-3.829787, 43.487778],
            ],
          ],
          [
            [
              [-3.796697, 43.433597],
              [-3.796819, 43.433497],
              [-3.797068, 43.433622],
              [-3.796699, 43.433768],
              [-3.796697, 43.433597],
            ],
          ],
          [
            [
              [-3.409163, 43.417583],
              [-3.409336, 43.417556],
              [-3.40982, 43.417851],
              [-3.409734, 43.417914],
              [-3.409163, 43.417583],
            ],
          ],
          [
            [
              [-3.813069, 43.49449],
              [-3.813316, 43.494479],
              [-3.812899, 43.494752],
              [-3.812874, 43.494671],
              [-3.813069, 43.49449],
            ],
          ],
          [
            [
              [-3.674726, 43.484758],
              [-3.674922, 43.484577],
              [-3.675221, 43.484801],
              [-3.674838, 43.484839],
              [-3.674726, 43.484758],
            ],
          ],
          [
            [
              [-3.293024, 43.407312],
              [-3.293308, 43.407357],
              [-3.293383, 43.407518],
              [-3.293049, 43.407474],
              [-3.293024, 43.407312],
            ],
          ],
          [
            [
              [-3.952261, 43.462646],
              [-3.952471, 43.462645],
              [-3.952437, 43.462852],
              [-3.952053, 43.462792],
              [-3.952261, 43.462646],
            ],
          ],
          [
            [
              [-3.246872, 43.398893],
              [-3.247181, 43.398901],
              [-3.247219, 43.399036],
              [-3.247046, 43.399109],
              [-3.246872, 43.398893],
            ],
          ],
          [
            [
              [-4.389074, 43.394904],
              [-4.389491, 43.394755],
              [-4.389164, 43.395029],
              [-4.389074, 43.394904],
            ],
          ],
          [
            [
              [-3.947677, 43.469942],
              [-3.947925, 43.469985],
              [-3.947744, 43.470248],
              [-3.94758, 43.470051],
              [-3.947677, 43.469942],
            ],
          ],
          [
            [
              [-3.419688, 43.414043],
              [-3.419848, 43.413988],
              [-3.419913, 43.414393],
              [-3.419802, 43.414402],
              [-3.419688, 43.414043],
            ],
          ],
          [
            [
              [-3.779586, 43.485789],
              [-3.779785, 43.485878],
              [-3.779316, 43.486007],
              [-3.77934, 43.485944],
              [-3.779586, 43.485789],
            ],
          ],
          [
            [
              [-3.924449, 43.474148],
              [-3.924646, 43.474156],
              [-3.9246, 43.474372],
              [-3.924364, 43.474284],
              [-3.924449, 43.474148],
            ],
          ],
          [
            [
              [-3.655081, 43.493309],
              [-3.655377, 43.493253],
              [-3.655416, 43.493487],
              [-3.655317, 43.49347],
              [-3.655081, 43.493309],
            ],
          ],
          [
            [
              [-3.502855, 43.477218],
              [-3.503164, 43.477181],
              [-3.503399, 43.477207],
              [-3.503017, 43.477307],
              [-3.502855, 43.477218],
            ],
          ],
          [
            [
              [-4.389763, 43.394787],
              [-4.389848, 43.394732],
              [-4.390035, 43.394784],
              [-4.389742, 43.39495],
              [-4.389763, 43.394787],
            ],
          ],
          [
            [
              [-3.500759, 43.474877],
              [-3.500932, 43.474921],
              [-3.500612, 43.475076],
              [-3.500574, 43.475004],
              [-3.500759, 43.474877],
            ],
          ],
          [
            [
              [-3.524635, 43.497948],
              [-3.524758, 43.497867],
              [-3.524785, 43.498137],
              [-3.524562, 43.498057],
              [-3.524635, 43.497948],
            ],
          ],
          [
            [
              [-3.31461, 43.406814],
              [-3.314758, 43.406769],
              [-3.314907, 43.406957],
              [-3.314685, 43.406958],
              [-3.31461, 43.406814],
            ],
          ],
          [
            [
              [-3.672295, 43.486375],
              [-3.672454, 43.486239],
              [-3.672628, 43.486301],
              [-3.672432, 43.486438],
              [-3.672295, 43.486375],
            ],
          ],
          [
            [
              [-3.655054, 43.494282],
              [-3.655139, 43.494173],
              [-3.655167, 43.494443],
              [-3.655068, 43.494417],
              [-3.655054, 43.494282],
            ],
          ],
          [
            [
              [-3.753611, 43.47359],
              [-3.753771, 43.473553],
              [-3.75386, 43.473714],
              [-3.753736, 43.473715],
              [-3.753611, 43.47359],
            ],
          ],
          [
            [
              [-3.523577, 43.495765],
              [-3.523626, 43.495612],
              [-3.523737, 43.49562],
              [-3.523787, 43.495728],
              [-3.523577, 43.495765],
            ],
          ],
          [
            [
              [-3.672675, 43.486067],
              [-3.672797, 43.485949],
              [-3.672897, 43.486039],
              [-3.672849, 43.486138],
              [-3.672675, 43.486067],
            ],
          ],
          [
            [
              [-3.247417, 43.399162],
              [-3.247577, 43.399135],
              [-3.247664, 43.399162],
              [-3.247615, 43.39927],
              [-3.247417, 43.399162],
            ],
          ],
          [
            [
              [-3.768308, 43.464425],
              [-3.768504, 43.464306],
              [-3.768432, 43.46446],
              [-3.768308, 43.464425],
            ],
          ],
          [
            [
              [-4.463727, 43.393348],
              [-4.463936, 43.393301],
              [-4.463772, 43.393496],
              [-4.463727, 43.393348],
            ],
          ],
          [
            [
              [-3.675814, 43.483672],
              [-3.675901, 43.483662],
              [-3.675976, 43.483743],
              [-3.675803, 43.483735],
              [-3.675814, 43.483672],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5622",
      properties: {
        name: "Castelló/Castellón",
        density: 0,
        path: "/world/Spain/Castelló/Castellón",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.164957, 40.788631],
              [-0.144533, 40.785443],
              [-0.126841, 40.753317],
              [-0.064699, 40.727407],
              [0.015738, 40.72843],
              [0.026438, 40.694951],
              [0.042175, 40.691006],
              [0.112501, 40.727137],
              [0.143917, 40.718252],
              [0.226115, 40.733164],
              [0.236523, 40.701683],
              [0.262431, 40.705852],
              [0.29184, 40.688553],
              [0.267831, 40.659001],
              [0.27883, 40.630058],
              [0.401962, 40.602381],
              [0.443944, 40.578223],
              [0.437964, 40.54706],
              [0.513165, 40.516011],
              [0.417827, 40.400591],
              [0.408446, 40.357348],
              [0.278363, 40.244149],
              [0.264005, 40.208554],
              [0.190507, 40.170433],
              [0.147554, 40.082915],
              [0.049673, 40.035439],
              [-0.005156, 39.91517],
              [-0.06699, 39.857356],
              [-0.088872, 39.85398],
              [-0.188272, 39.721908],
              [-0.264177, 39.745497],
              [-0.276528, 39.770542],
              [-0.328259, 39.801482],
              [-0.377919, 39.799877],
              [-0.396095, 39.758614],
              [-0.456176, 39.714698],
              [-0.530586, 39.795977],
              [-0.577049, 39.773202],
              [-0.588224, 39.741688],
              [-0.652347, 39.754084],
              [-0.656151, 39.835961],
              [-0.691111, 39.852463],
              [-0.71255, 39.816845],
              [-0.730456, 39.814828],
              [-0.773562, 39.869796],
              [-0.832895, 39.911],
              [-0.846297, 39.947698],
              [-0.837944, 39.97655],
              [-0.777646, 39.998407],
              [-0.753621, 40.046608],
              [-0.68294, 40.044225],
              [-0.627222, 40.076202],
              [-0.613589, 40.070013],
              [-0.628533, 40.102578],
              [-0.57864, 40.137335],
              [-0.544187, 40.251659],
              [-0.494515, 40.228732],
              [-0.428072, 40.243803],
              [-0.383626, 40.264848],
              [-0.400408, 40.294853],
              [-0.388637, 40.306937],
              [-0.366273, 40.305052],
              [-0.340617, 40.339991],
              [-0.282026, 40.36529],
              [-0.285244, 40.386317],
              [-0.347355, 40.44394],
              [-0.27264, 40.473762],
              [-0.277638, 40.501532],
              [-0.301837, 40.515404],
              [-0.293286, 40.611041],
              [-0.370483, 40.613592],
              [-0.381126, 40.66423],
              [-0.328818, 40.680279],
              [-0.306998, 40.659497],
              [-0.235991, 40.691108],
              [-0.224034, 40.754158],
              [-0.197176, 40.784456],
              [-0.164957, 40.788631],
            ],
          ],
          [
            [
              [0.687624, 39.900631],
              [0.684474, 39.89684],
              [0.686174, 39.893444],
              [0.683726, 39.897287],
              [0.687624, 39.900631],
            ],
          ],
          [
            [
              [0.671245, 39.875977],
              [0.67244, 39.875372],
              [0.671807, 39.874248],
              [0.670528, 39.874802],
              [0.671245, 39.875977],
            ],
          ],
          [
            [
              [0.669508, 39.890524],
              [0.668192, 39.891277],
              [0.668066, 39.891984],
              [0.669013, 39.891972],
              [0.669508, 39.890524],
            ],
          ],
          [
            [
              [0.690537, 39.895467],
              [0.690527, 39.8959],
              [0.690962, 39.896372],
              [0.691076, 39.895666],
              [0.690537, 39.895467],
            ],
          ],
          [
            [
              [0.671979, 39.852294],
              [0.672448, 39.85209],
              [0.672206, 39.851494],
              [0.671844, 39.851938],
              [0.671979, 39.852294],
            ],
          ],
          [
            [
              [0.689535, 39.894625],
              [0.689083, 39.894703],
              [0.689153, 39.895124],
              [0.689539, 39.89513],
              [0.689535, 39.894625],
            ],
          ],
          [
            [
              [0.672978, 39.87449],
              [0.672559, 39.874315],
              [0.672392, 39.874473],
              [0.672851, 39.874972],
              [0.672978, 39.87449],
            ],
          ],
          [
            [
              [0.6708, 39.891942],
              [0.671108, 39.891788],
              [0.670664, 39.891361],
              [0.670278, 39.891797],
              [0.6708, 39.891942],
            ],
          ],
          [
            [
              [0.667323, 39.88945],
              [0.667855, 39.889559],
              [0.667943, 39.889241],
              [0.667598, 39.889117],
              [0.667323, 39.88945],
            ],
          ],
          [
            [
              [0.679145, 39.851238],
              [0.679232, 39.851343],
              [0.679688, 39.85113],
              [0.67946, 39.850795],
              [0.679145, 39.851238],
            ],
          ],
          [
            [
              [0.670601, 39.890192],
              [0.670265, 39.890032],
              [0.670106, 39.890334],
              [0.670322, 39.890435],
              [0.670601, 39.890192],
            ],
          ],
          [
            [
              [0.679483, 39.850137],
              [0.679132, 39.850121],
              [0.679036, 39.850295],
              [0.679373, 39.850474],
              [0.679483, 39.850137],
            ],
          ],
          [
            [
              [0.670286, 39.878818],
              [0.670083, 39.878734],
              [0.669907, 39.878938],
              [0.670137, 39.879093],
              [0.670286, 39.878818],
            ],
          ],
          [
            [
              [0.674122, 39.850839],
              [0.673899, 39.850828],
              [0.673906, 39.851179],
              [0.674071, 39.851201],
              [0.674122, 39.850839],
            ],
          ],
          [
            [
              [0.673354, 39.850899],
              [0.673437, 39.85114],
              [0.673682, 39.851132],
              [0.673477, 39.850796],
              [0.673354, 39.850899],
            ],
          ],
          [
            [
              [0.671108, 39.891788],
              [0.67127, 39.891981],
              [0.671426, 39.891832],
              [0.671334, 39.891655],
              [0.671108, 39.891788],
            ],
          ],
          [
            [
              [0.690185, 39.895397],
              [0.690537, 39.895467],
              [0.690325, 39.895177],
              [0.690185, 39.895397],
            ],
          ],
          [
            [
              [0.673717, 39.851293],
              [0.673877, 39.851504],
              [0.674022, 39.851373],
              [0.673717, 39.851293],
            ],
          ],
          [
            [
              [0.674372, 39.851137],
              [0.674582, 39.851139],
              [0.674488, 39.850899],
              [0.674372, 39.851137],
            ],
          ],
          [
            [
              [0.672587, 39.851851],
              [0.6728, 39.851898],
              [0.67274, 39.851657],
              [0.672587, 39.851851],
            ],
          ],
          [
            [
              [0.674194, 39.851305],
              [0.674329, 39.851436],
              [0.674437, 39.851261],
              [0.674194, 39.851305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5637",
      properties: { name: "Ceuta", density: 0, path: "/world/Spain/Ceuta" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.344965, 35.87106],
              [-5.372914, 35.883529],
              [-5.378861, 35.916206],
              [-5.316963, 35.889401],
              [-5.278731, 35.900136],
              [-5.344965, 35.87106],
            ],
          ],
          [
            [
              [-5.419935, 35.91163],
              [-5.420259, 35.914849],
              [-5.417806, 35.916519],
              [-5.416358, 35.912584],
              [-5.419935, 35.91163],
            ],
          ],
          [
            [
              [-4.295426, 35.168158],
              [-4.300362, 35.170054],
              [-4.300297, 35.170893],
              [-4.295745, 35.169661],
              [-4.295426, 35.168158],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5623",
      properties: {
        name: "Ciudad Real",
        density: 0,
        path: "/world/Spain/Ciudad Real",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.138967, 39.566451],
              [-4.041778, 39.575354],
              [-4.03008, 39.521974],
              [-4.060076, 39.521443],
              [-4.068131, 39.457054],
              [-4.118073, 39.456882],
              [-4.148074, 39.362765],
              [-4.120539, 39.362619],
              [-4.104449, 39.341112],
              [-4.073373, 39.365334],
              [-3.972202, 39.352176],
              [-3.966856, 39.298553],
              [-3.857356, 39.258502],
              [-3.791212, 39.292895],
              [-3.722522, 39.269607],
              [-3.680186, 39.298281],
              [-3.53259, 39.304157],
              [-3.47688, 39.343144],
              [-3.470483, 39.37214],
              [-3.410278, 39.404589],
              [-3.309134, 39.399152],
              [-3.31589, 39.434528],
              [-3.294197, 39.470844],
              [-3.185165, 39.47415],
              [-3.184516, 39.487964],
              [-3.157311, 39.496114],
              [-3.069597, 39.450273],
              [-3.022587, 39.456996],
              [-2.999549, 39.441644],
              [-2.956792, 39.447214],
              [-2.927702, 39.470762],
              [-2.931812, 39.452825],
              [-2.869921, 39.390481],
              [-2.896559, 39.366774],
              [-2.882377, 39.350883],
              [-2.834091, 39.353473],
              [-2.793153, 39.39774],
              [-2.776032, 39.394761],
              [-2.722186, 39.267187],
              [-2.842657, 39.134491],
              [-2.80501, 39.078944],
              [-2.80881, 39.028686],
              [-2.832264, 39.023243],
              [-2.882888, 38.938491],
              [-2.835805, 38.905975],
              [-2.792464, 38.905554],
              [-2.76155, 38.879813],
              [-2.752679, 38.814731],
              [-2.732242, 38.781849],
              [-2.696283, 38.782932],
              [-2.640989, 38.743096],
              [-2.668855, 38.649221],
              [-2.728246, 38.616075],
              [-2.763691, 38.627886],
              [-2.750097, 38.615565],
              [-2.765499, 38.573853],
              [-2.74679, 38.546814],
              [-2.779955, 38.509706],
              [-2.88149, 38.452645],
              [-2.905788, 38.469907],
              [-2.963586, 38.471454],
              [-2.993509, 38.450119],
              [-3.001713, 38.408321],
              [-3.064966, 38.478248],
              [-3.138288, 38.438577],
              [-3.279424, 38.460244],
              [-3.326492, 38.482121],
              [-3.375012, 38.475235],
              [-3.376684, 38.439596],
              [-3.451698, 38.400318],
              [-3.528443, 38.408641],
              [-3.541888, 38.445385],
              [-3.583076, 38.451469],
              [-3.598236, 38.398474],
              [-3.785612, 38.425269],
              [-3.830056, 38.412796],
              [-3.830561, 38.392903],
              [-3.854284, 38.375141],
              [-3.91512, 38.368814],
              [-4.156557, 38.381325],
              [-4.257784, 38.401577],
              [-4.2768, 38.390206],
              [-4.26887, 38.347171],
              [-4.28733, 38.342761],
              [-4.452988, 38.400871],
              [-4.512691, 38.463854],
              [-4.566297, 38.490941],
              [-4.593691, 38.486382],
              [-4.622779, 38.523794],
              [-4.70395, 38.553171],
              [-4.707366, 38.571946],
              [-4.860171, 38.613979],
              [-4.87697, 38.686094],
              [-5.0046, 38.695095],
              [-5.008025, 38.715229],
              [-5.046948, 38.729087],
              [-4.989994, 38.739758],
              [-4.943484, 38.854378],
              [-4.91494, 38.88744],
              [-4.847319, 38.882403],
              [-4.83113, 38.935611],
              [-4.918221, 38.957045],
              [-4.962504, 39.058727],
              [-4.879987, 39.032425],
              [-4.832793, 39.04616],
              [-4.826935, 39.064119],
              [-4.874404, 39.100434],
              [-4.853171, 39.152438],
              [-4.810273, 39.199416],
              [-4.647893, 39.166866],
              [-4.708229, 39.209868],
              [-4.761012, 39.320789],
              [-4.70666, 39.327726],
              [-4.704211, 39.364451],
              [-4.669498, 39.424537],
              [-4.687696, 39.450295],
              [-4.618132, 39.454401],
              [-4.618426, 39.484652],
              [-4.567921, 39.497282],
              [-4.554627, 39.510777],
              [-4.561444, 39.533138],
              [-4.542684, 39.544002],
              [-4.531089, 39.531604],
              [-4.524951, 39.561625],
              [-4.482188, 39.538797],
              [-4.51122, 39.522125],
              [-4.461586, 39.478547],
              [-4.441906, 39.523131],
              [-4.37721, 39.495983],
              [-4.242092, 39.477898],
              [-4.193234, 39.495384],
              [-4.158731, 39.576761],
              [-4.138967, 39.566451],
            ],
          ],
          [
            [
              [-4.76776, 39.515615],
              [-4.742852, 39.487548],
              [-4.740511, 39.456165],
              [-4.766984, 39.43353],
              [-4.755371, 39.415713],
              [-4.773145, 39.397883],
              [-4.870935, 39.368313],
              [-4.940742, 39.395118],
              [-4.908212, 39.49369],
              [-4.863123, 39.556147],
              [-4.76776, 39.515615],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5625",
      properties: { name: "Córdoba", density: 0, path: "/world/Spain/Córdoba" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.035409, 38.726189],
              [-5.004469, 38.71183],
              [-5.0046, 38.695095],
              [-4.87697, 38.686094],
              [-4.860171, 38.613979],
              [-4.707366, 38.571946],
              [-4.70395, 38.553171],
              [-4.622779, 38.523794],
              [-4.593691, 38.486382],
              [-4.566297, 38.490941],
              [-4.512691, 38.463854],
              [-4.452988, 38.400871],
              [-4.28733, 38.342761],
              [-4.237442, 38.341541],
              [-4.238538, 38.315107],
              [-4.210218, 38.301452],
              [-4.216686, 38.246953],
              [-4.196001, 38.237847],
              [-4.201175, 38.214084],
              [-4.167861, 38.180787],
              [-4.161197, 38.145003],
              [-4.21795, 38.110768],
              [-4.214085, 38.089477],
              [-4.251945, 38.063522],
              [-4.240086, 38.047029],
              [-4.268941, 38.039764],
              [-4.259958, 38.022232],
              [-4.282252, 38.022812],
              [-4.258052, 37.939322],
              [-4.286737, 37.796543],
              [-4.206578, 37.778735],
              [-4.23861, 37.720091],
              [-4.183509, 37.707039],
              [-4.164368, 37.681506],
              [-4.228103, 37.639536],
              [-4.220137, 37.62024],
              [-4.173837, 37.610083],
              [-4.160707, 37.575107],
              [-4.120976, 37.5543],
              [-4.098058, 37.511531],
              [-4.059995, 37.505813],
              [-4.087653, 37.486075],
              [-4.048141, 37.467973],
              [-4.000821, 37.402091],
              [-4.085943, 37.403746],
              [-4.15784, 37.342252],
              [-4.204036, 37.352913],
              [-4.236419, 37.335851],
              [-4.236509, 37.297219],
              [-4.263253, 37.260378],
              [-4.25531, 37.229565],
              [-4.295565, 37.199387],
              [-4.327617, 37.184331],
              [-4.322629, 37.204523],
              [-4.371637, 37.215205],
              [-4.382575, 37.231545],
              [-4.379696, 37.27523],
              [-4.417965, 37.282403],
              [-4.485181, 37.252483],
              [-4.477553, 37.244728],
              [-4.498077, 37.223334],
              [-4.602299, 37.209237],
              [-4.613768, 37.255084],
              [-4.64533, 37.246855],
              [-4.662116, 37.283744],
              [-4.681092, 37.290046],
              [-4.658472, 37.30429],
              [-4.68407, 37.315357],
              [-4.668439, 37.319805],
              [-4.671912, 37.334987],
              [-4.685656, 37.330268],
              [-4.718667, 37.358499],
              [-4.729224, 37.335327],
              [-4.782224, 37.328502],
              [-4.822126, 37.358195],
              [-4.814688, 37.377532],
              [-4.84981, 37.410659],
              [-4.840635, 37.436019],
              [-4.88474, 37.437983],
              [-4.875342, 37.450453],
              [-4.904089, 37.467405],
              [-4.904468, 37.489489],
              [-4.915201, 37.48416],
              [-4.90602, 37.499837],
              [-4.948674, 37.521483],
              [-4.932025, 37.545909],
              [-4.931707, 37.617326],
              [-4.974282, 37.600157],
              [-4.953766, 37.652443],
              [-4.999821, 37.70659],
              [-5.047227, 37.718294],
              [-5.072582, 37.707695],
              [-5.091506, 37.679948],
              [-5.076435, 37.668904],
              [-5.076804, 37.664662],
              [-5.070015, 37.661834],
              [-5.069878, 37.65935],
              [-5.097878, 37.670291],
              [-5.108612, 37.700667],
              [-5.12971, 37.698747],
              [-5.10792, 37.662578],
              [-5.150054, 37.660917],
              [-5.176768, 37.680577],
              [-5.27452, 37.625284],
              [-5.323304, 37.61425],
              [-5.35186, 37.584288],
              [-5.414016, 37.645275],
              [-5.393656, 37.65535],
              [-5.412897, 37.664997],
              [-5.40369, 37.693021],
              [-5.386806, 37.697311],
              [-5.376343, 37.677562],
              [-5.344837, 37.705344],
              [-5.327099, 37.69162],
              [-5.307604, 37.699239],
              [-5.309754, 37.773586],
              [-5.349916, 37.794266],
              [-5.344511, 37.839872],
              [-5.414709, 37.869461],
              [-5.415611, 37.943674],
              [-5.474295, 37.975353],
              [-5.493751, 37.996946],
              [-5.495125, 38.041844],
              [-5.538781, 38.093811],
              [-5.581684, 38.115474],
              [-5.585641, 38.140355],
              [-5.539673, 38.160685],
              [-5.538707, 38.200605],
              [-5.520377, 38.208442],
              [-5.525958, 38.264124],
              [-5.575648, 38.329106],
              [-5.559248, 38.366885],
              [-5.584277, 38.400971],
              [-5.552793, 38.445621],
              [-5.484455, 38.46339],
              [-5.479488, 38.484678],
              [-5.410003, 38.520224],
              [-5.406932, 38.543695],
              [-5.383664, 38.539846],
              [-5.39272, 38.553483],
              [-5.371748, 38.583572],
              [-5.308514, 38.577619],
              [-5.292237, 38.610808],
              [-5.223775, 38.656712],
              [-5.214745, 38.648567],
              [-5.208485, 38.667697],
              [-5.184233, 38.659728],
              [-5.185764, 38.67417],
              [-5.167322, 38.680469],
              [-5.184505, 38.719695],
              [-5.09919, 38.707653],
              [-5.035409, 38.726189],
            ],
          ],
          [
            [
              [-5.08114, 37.646553],
              [-5.046795, 37.631942],
              [-5.031191, 37.641521],
              [-5.017785, 37.592464],
              [-5.04791, 37.60814],
              [-5.040405, 37.626195],
              [-5.08114, 37.646553],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5626",
      properties: { name: "Cuenca", density: 0, path: "/world/Spain/Cuenca" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.152111, 40.657693],
              [-2.091202, 40.646365],
              [-2.035798, 40.601566],
              [-1.985527, 40.585434],
              [-1.965905, 40.59198],
              [-1.964457, 40.611525],
              [-1.923422, 40.59188],
              [-1.909467, 40.541652],
              [-1.882003, 40.518823],
              [-1.853285, 40.423679],
              [-1.836725, 40.427657],
              [-1.811888, 40.400107],
              [-1.777769, 40.391878],
              [-1.697677, 40.321292],
              [-1.72946, 40.302908],
              [-1.719274, 40.277102],
              [-1.690346, 40.30966],
              [-1.540483, 40.190804],
              [-1.510902, 40.203715],
              [-1.474416, 40.185042],
              [-1.439208, 40.197014],
              [-1.456213, 40.130954],
              [-1.407597, 40.101404],
              [-1.420286, 40.093514],
              [-1.37686, 40.016499],
              [-1.249456, 39.995103],
              [-1.165188, 40.010096],
              [-1.142395, 39.971841],
              [-1.208184, 39.943806],
              [-1.200044, 39.858706],
              [-1.215004, 39.808774],
              [-1.275409, 39.738641],
              [-1.261995, 39.698907],
              [-1.307852, 39.670817],
              [-1.368535, 39.689615],
              [-1.417932, 39.654936],
              [-1.461796, 39.576906],
              [-1.520665, 39.549965],
              [-1.502602, 39.545616],
              [-1.499153, 39.50045],
              [-1.512454, 39.496269],
              [-1.513391, 39.458268],
              [-1.528916, 39.4546],
              [-1.52085, 39.431522],
              [-1.49665, 39.42932],
              [-1.505175, 39.417996],
              [-1.739281, 39.32116],
              [-1.769853, 39.280508],
              [-1.887077, 39.30479],
              [-1.989799, 39.29368],
              [-2.028431, 39.336808],
              [-2.020331, 39.351824],
              [-2.095625, 39.359425],
              [-2.050963, 39.266677],
              [-2.168681, 39.281074],
              [-2.288677, 39.226661],
              [-2.321191, 39.283739],
              [-2.34989, 39.278542],
              [-2.374077, 39.232784],
              [-2.511046, 39.342153],
              [-2.548777, 39.323455],
              [-2.619258, 39.340143],
              [-2.634971, 39.326759],
              [-2.742252, 39.318153],
              [-2.776032, 39.394761],
              [-2.793153, 39.39774],
              [-2.83057, 39.355117],
              [-2.882377, 39.350883],
              [-2.896559, 39.366774],
              [-2.870163, 39.391112],
              [-2.932287, 39.453852],
              [-2.925514, 39.601798],
              [-2.908233, 39.642332],
              [-2.997748, 39.718866],
              [-3.076292, 39.855707],
              [-3.127227, 39.874683],
              [-3.097059, 39.933467],
              [-3.096597, 39.98694],
              [-3.142566, 39.984965],
              [-3.165497, 40.028199],
              [-3.161579, 40.064928],
              [-3.11819, 40.061429],
              [-3.060707, 40.090564],
              [-3.056194, 40.113743],
              [-3.097374, 40.154352],
              [-3.063767, 40.168251],
              [-3.015966, 40.152708],
              [-3.021071, 40.187493],
              [-3.005071, 40.209118],
              [-2.911484, 40.157083],
              [-2.873738, 40.173574],
              [-2.863125, 40.200112],
              [-2.821531, 40.205182],
              [-2.802631, 40.264026],
              [-2.816966, 40.364024],
              [-2.779515, 40.44533],
              [-2.76007, 40.441993],
              [-2.760023, 40.412335],
              [-2.7229, 40.397117],
              [-2.717431, 40.419825],
              [-2.685129, 40.398002],
              [-2.70345, 40.425375],
              [-2.673158, 40.448609],
              [-2.663845, 40.436051],
              [-2.586236, 40.450259],
              [-2.515465, 40.424281],
              [-2.504534, 40.448649],
              [-2.544453, 40.474633],
              [-2.528726, 40.498381],
              [-2.546266, 40.518956],
              [-2.527235, 40.544275],
              [-2.49597, 40.50881],
              [-2.426745, 40.540748],
              [-2.411821, 40.525628],
              [-2.380004, 40.543587],
              [-2.417282, 40.583447],
              [-2.399514, 40.613509],
              [-2.359031, 40.596841],
              [-2.343439, 40.569384],
              [-2.29766, 40.572845],
              [-2.286042, 40.592223],
              [-2.294245, 40.624651],
              [-2.274601, 40.644754],
              [-2.213815, 40.619176],
              [-2.169249, 40.630233],
              [-2.152111, 40.657693],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5629",
      properties: {
        name: "Gipuzkoa/Guipúzcoa",
        density: 0,
        path: "/world/Spain/Gipuzkoa/Guipúzcoa",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.790863, 43.395366],
              [-1.796946, 43.382491],
              [-1.779009, 43.36341],
              [-1.789238, 43.352983],
              [-1.737382, 43.330155],
              [-1.729369, 43.293823],
              [-1.783243, 43.28682],
              [-1.794975, 43.246929],
              [-1.860575, 43.212089],
              [-1.867694, 43.230502],
              [-1.880927, 43.228777],
              [-1.881908, 43.206788],
              [-1.914152, 43.225171],
              [-1.920688, 43.15063],
              [-1.901952, 43.135854],
              [-1.947525, 43.097527],
              [-2.022824, 43.065193],
              [-2.020441, 43.046731],
              [-2.041395, 43.0297],
              [-2.028306, 42.998838],
              [-2.038338, 42.980994],
              [-2.095235, 42.972553],
              [-2.103904, 42.949038],
              [-2.129501, 42.939375],
              [-2.18096, 42.935126],
              [-2.210258, 42.949912],
              [-2.235566, 42.926608],
              [-2.239923, 42.895245],
              [-2.289543, 42.902309],
              [-2.302321, 42.925954],
              [-2.354798, 42.950027],
              [-2.535304, 42.973445],
              [-2.544306, 42.950275],
              [-2.602684, 42.982228],
              [-2.596533, 43.009249],
              [-2.549582, 43.019789],
              [-2.524851, 43.045327],
              [-2.547262, 43.084679],
              [-2.501144, 43.096535],
              [-2.509717, 43.161443],
              [-2.484113, 43.170903],
              [-2.497945, 43.219923],
              [-2.436581, 43.243707],
              [-2.418061, 43.267602],
              [-2.439005, 43.29491],
              [-2.411725, 43.324181],
              [-2.352928, 43.298704],
              [-2.202446, 43.311725],
              [-2.189571, 43.292365],
              [-2.130502, 43.287455],
              [-2.030468, 43.320998],
              [-1.99094, 43.315874],
              [-1.962396, 43.338146],
              [-1.923781, 43.332035],
              [-1.86497, 43.372381],
              [-1.790863, 43.395366],
            ],
            [
              [-2.268762, 43.300554],
              [-2.268994, 43.300718],
              [-2.269155, 43.300719],
              [-2.269034, 43.30052],
              [-2.268762, 43.300554],
            ],
            [
              [-1.798969, 43.391957],
              [-1.798869, 43.392028],
              [-1.798781, 43.392073],
              [-1.799154, 43.391986],
              [-1.798969, 43.391957],
            ],
            [
              [-1.80958, 43.388548],
              [-1.809553, 43.388664],
              [-1.809752, 43.388568],
              [-1.809679, 43.38854],
              [-1.80958, 43.388548],
            ],
          ],
          [
            [
              [-1.998009, 43.322437],
              [-1.998426, 43.321081],
              [-2.001046, 43.320753],
              [-2.000767, 43.321966],
              [-1.998009, 43.322437],
            ],
          ],
          [
            [
              [-2.149701, 43.296216],
              [-2.15029, 43.296419],
              [-2.150905, 43.297369],
              [-2.149843, 43.296667],
              [-2.149701, 43.296216],
            ],
          ],
          [
            [
              [-2.408424, 43.323714],
              [-2.408693, 43.323887],
              [-2.408813, 43.324256],
              [-2.408383, 43.324101],
              [-2.408424, 43.323714],
            ],
          ],
          [
            [
              [-2.400766, 43.322233],
              [-2.400902, 43.322225],
              [-2.400887, 43.322477],
              [-2.400727, 43.322485],
              [-2.400766, 43.322233],
            ],
          ],
          [
            [
              [-2.357739, 43.304242],
              [-2.357687, 43.304512],
              [-2.357527, 43.30452],
              [-2.357516, 43.304403],
              [-2.357739, 43.304242],
            ],
          ],
          [
            [
              [-2.409341, 43.323206],
              [-2.409378, 43.323188],
              [-2.409547, 43.323567],
              [-2.409387, 43.323557],
              [-2.409341, 43.323206],
            ],
          ],
          [
            [
              [-2.407406, 43.323061],
              [-2.407493, 43.323025],
              [-2.407427, 43.323511],
              [-2.407341, 43.32342],
              [-2.407406, 43.323061],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5604",
      properties: { name: "Girona", density: 0, path: "/world/Spain/Girona" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.74871, 42.494818],
              [1.823554, 42.486553],
              [1.891953, 42.447721],
              [1.936683, 42.454414],
              [1.965429, 42.380925],
              [2.015187, 42.347378],
              [2.084593, 42.361742],
              [2.148252, 42.420429],
              [2.206673, 42.417377],
              [2.256929, 42.438736],
              [2.432719, 42.39375],
              [2.433401, 42.377621],
              [2.480908, 42.340955],
              [2.53089, 42.332929],
              [2.571007, 42.358059],
              [2.671646, 42.340748],
              [2.651376, 42.386304],
              [2.670932, 42.387171],
              [2.672087, 42.403461],
              [2.733545, 42.424824],
              [2.799311, 42.418479],
              [2.839618, 42.459256],
              [2.918422, 42.456199],
              [2.947093, 42.482119],
              [2.967415, 42.465423],
              [3.033237, 42.474874],
              [3.085185, 42.42585],
              [3.17462, 42.435344],
              [3.160554, 42.427984],
              [3.17098, 42.416385],
              [3.15587, 42.406885],
              [3.165024, 42.400657],
              [3.153431, 42.391426],
              [3.163357, 42.391727],
              [3.167137, 42.388913],
              [3.155097, 42.378697],
              [3.162947, 42.36046],
              [3.194635, 42.33581],
              [3.229268, 42.351561],
              [3.238223, 42.348983],
              [3.249758, 42.329774],
              [3.267695, 42.340439],
              [3.322264, 42.319402],
              [3.286913, 42.303665],
              [3.286273, 42.293259],
              [3.299292, 42.284513],
              [3.290556, 42.282006],
              [3.276202, 42.287089],
              [3.2769, 42.279867],
              [3.287864, 42.265727],
              [3.25371, 42.252497],
              [3.264373, 42.237871],
              [3.229516, 42.251533],
              [3.218135, 42.232323],
              [3.162617, 42.264225],
              [3.111736, 42.205902],
              [3.12387, 42.128472],
              [3.176814, 42.111536],
              [3.169537, 42.105332],
              [3.214698, 42.061192],
              [3.199, 42.049245],
              [3.195709, 42.01377],
              [3.233607, 41.950546],
              [3.218151, 41.942665],
              [3.214199, 41.936381],
              [3.220556, 41.934811],
              [3.220382, 41.922273],
              [3.207427, 41.917332],
              [3.203013, 41.891638],
              [3.183329, 41.887789],
              [3.178298, 41.878997],
              [3.176902, 41.866426],
              [3.131937, 41.841958],
              [3.10216, 41.844914],
              [3.056324, 41.787567],
              [3.046203, 41.788786],
              [3.048706, 41.782677],
              [3.043679, 41.781655],
              [3.043633, 41.778489],
              [3.02968, 41.779477],
              [3.030164, 41.77081],
              [3.011126, 41.772181],
              [2.977548, 41.757664],
              [2.934871, 41.720358],
              [2.934766, 41.715188],
              [2.902569, 41.709966],
              [2.887693, 41.701271],
              [2.853645, 41.699614],
              [2.819472, 41.688861],
              [2.789119, 41.669983],
              [2.777784, 41.649999],
              [2.75787, 41.67913],
              [2.776861, 41.735489],
              [2.657596, 41.747692],
              [2.547308, 41.719069],
              [2.515973, 41.761903],
              [2.469147, 41.777787],
              [2.472175, 41.789597],
              [2.352935, 41.807944],
              [2.325989, 41.855663],
              [2.32798, 41.883047],
              [2.369665, 41.885501],
              [2.382607, 41.871516],
              [2.407118, 41.895721],
              [2.442697, 41.88749],
              [2.481179, 41.916019],
              [2.429103, 41.968933],
              [2.474314, 41.969345],
              [2.488023, 41.984668],
              [2.46878, 41.993835],
              [2.498901, 42.00152],
              [2.50616, 42.032274],
              [2.482218, 42.04925],
              [2.49035, 42.067324],
              [2.458042, 42.07928],
              [2.417527, 42.064975],
              [2.394499, 42.086764],
              [2.400177, 42.102755],
              [2.366025, 42.12135],
              [2.294921, 42.102679],
              [2.299549, 42.134198],
              [2.270022, 42.145213],
              [2.215121, 42.142094],
              [2.201067, 42.124287],
              [2.144697, 42.131409],
              [2.125922, 42.113111],
              [2.092321, 42.144303],
              [2.060383, 42.135358],
              [2.003984, 42.149515],
              [1.99149, 42.182589],
              [1.965892, 42.178787],
              [1.96521, 42.199092],
              [2.0073, 42.19407],
              [1.995545, 42.172301],
              [2.004833, 42.1621],
              [2.060513, 42.177891],
              [2.053296, 42.219534],
              [2.030185, 42.215871],
              [2.016731, 42.232982],
              [2.069897, 42.291596],
              [1.90312, 42.323301],
              [1.842333, 42.306341],
              [1.834703, 42.355145],
              [1.855058, 42.369648],
              [1.829989, 42.381406],
              [1.806038, 42.37009],
              [1.801545, 42.424921],
              [1.731921, 42.440383],
              [1.724271, 42.486961],
              [1.74871, 42.494818],
            ],
            [
              [3.286605, 42.333108],
              [3.285789, 42.33344],
              [3.286085, 42.332155],
              [3.286857, 42.33223],
              [3.286605, 42.333108],
            ],
            [
              [3.242269, 42.340121],
              [3.241772, 42.339761],
              [3.243353, 42.339306],
              [3.243088, 42.339815],
              [3.242269, 42.340121],
            ],
            [
              [3.217833, 41.924941],
              [3.21724, 41.924793],
              [3.217001, 41.924563],
              [3.218334, 41.924877],
              [3.217833, 41.924941],
            ],
            [
              [3.080968, 41.824191],
              [3.080876, 41.823863],
              [3.081449, 41.823941],
              [3.081389, 41.82407],
              [3.080968, 41.824191],
            ],
            [
              [3.047064, 41.783376],
              [3.046738, 41.783492],
              [3.046735, 41.783204],
              [3.047324, 41.783326],
              [3.047064, 41.783376],
            ],
            [
              [3.166557, 42.113836],
              [3.166275, 42.114041],
              [3.166308, 42.113517],
              [3.166509, 42.11347],
              [3.166557, 42.113836],
            ],
            [
              [3.153255, 41.861093],
              [3.153012, 41.861061],
              [3.153761, 41.860967],
              [3.153566, 41.861068],
              [3.153255, 41.861093],
            ],
            [
              [3.297956, 42.328342],
              [3.298456, 42.328125],
              [3.298695, 42.328337],
              [3.298362, 42.328274],
              [3.297956, 42.328342],
            ],
            [
              [3.210652, 42.062807],
              [3.210416, 42.062991],
              [3.210181, 42.062932],
              [3.210413, 42.062712],
              [3.210652, 42.062807],
            ],
            [
              [2.886889, 41.701537],
              [2.886575, 41.701518],
              [2.886229, 41.701418],
              [2.886606, 41.701336],
              [2.886889, 41.701537],
            ],
            [
              [3.218288, 41.924286],
              [3.218258, 41.92435],
              [3.217274, 41.924143],
              [3.217637, 41.924042],
              [3.218288, 41.924286],
            ],
            [
              [3.270844, 42.338682],
              [3.270676, 42.338457],
              [3.271261, 42.338605],
              [3.271193, 42.338654],
              [3.270844, 42.338682],
            ],
            [
              [3.047398, 41.78424],
              [3.047161, 41.784271],
              [3.046966, 41.784119],
              [3.047568, 41.784132],
              [3.047398, 41.78424],
            ],
            [
              [3.298979, 42.327412],
              [3.298902, 42.327614],
              [3.29851, 42.327573],
              [3.298813, 42.327331],
              [3.298979, 42.327412],
            ],
            [
              [3.059622, 41.792055],
              [3.059279, 41.791983],
              [3.059331, 41.791647],
              [3.059471, 41.791721],
              [3.059622, 41.792055],
            ],
            [
              [3.297536, 42.284772],
              [3.297306, 42.284775],
              [3.297547, 42.284519],
              [3.297867, 42.284447],
              [3.297716, 42.28451],
              [3.297536, 42.284772],
            ],
            [
              [3.285108, 42.332335],
              [3.28515, 42.332387],
              [3.284742, 42.332553],
              [3.284853, 42.332331],
              [3.285108, 42.332335],
            ],
            [
              [3.298649, 42.327997],
              [3.298495, 42.327907],
              [3.298881, 42.327886],
              [3.298823, 42.328042],
              [3.298649, 42.327997],
            ],
            [
              [3.209589, 42.065647],
              [3.209416, 42.065485],
              [3.209649, 42.065274],
              [3.20973, 42.06536],
              [3.209589, 42.065647],
            ],
            [
              [3.047608, 41.783149],
              [3.047714, 41.783251],
              [3.047138, 41.783138],
              [3.047183, 41.783108],
              [3.047608, 41.783149],
            ],
            [
              [3.219642, 41.925445],
              [3.219144, 41.9254],
              [3.218908, 41.925332],
              [3.219178, 41.925255],
              [3.219642, 41.925445],
            ],
            [
              [3.140954, 41.84836],
              [3.140755, 41.84829],
              [3.140945, 41.848136],
              [3.141088, 41.848245],
              [3.140954, 41.84836],
            ],
            [
              [3.140808, 41.848467],
              [3.140674, 41.8487],
              [3.140478, 41.848539],
              [3.140735, 41.848462],
              [3.140808, 41.848467],
            ],
            [
              [3.084654, 41.826103],
              [3.084591, 41.826145],
              [3.084338, 41.825975],
              [3.084953, 41.825961],
              [3.084654, 41.826103],
            ],
            [
              [3.261125, 42.33502],
              [3.261051, 42.335249],
              [3.260905, 42.335239],
              [3.260962, 42.334966],
              [3.261125, 42.33502],
            ],
            [
              [3.060063, 41.79277],
              [3.060307, 41.793063],
              [3.060252, 41.79312],
              [3.060104, 41.793082],
              [3.060063, 41.79277],
            ],
            [
              [3.154382, 42.391338],
              [3.154314, 42.391396],
              [3.154053, 42.391212],
              [3.154144, 42.391144],
              [3.154382, 42.391338],
            ],
            [
              [3.094504, 41.835841],
              [3.09459, 41.835892],
              [3.094652, 41.835978],
              [3.094389, 41.83599],
              [3.094504, 41.835841],
            ],
            [
              [3.217837, 41.921763],
              [3.217619, 41.921748],
              [3.218129, 41.92154],
              [3.218094, 41.921677],
              [3.217837, 41.921763],
            ],
            [
              [3.141825, 41.848656],
              [3.141827, 41.8488],
              [3.141595, 41.848767],
              [3.141635, 41.848684],
              [3.141825, 41.848656],
            ],
            [
              [3.193059, 41.892223],
              [3.192971, 41.892066],
              [3.193281, 41.892157],
              [3.193249, 41.892195],
              [3.193059, 41.892223],
            ],
            [
              [3.162142, 42.115308],
              [3.162303, 42.11547],
              [3.161824, 42.115262],
              [3.162074, 42.11523],
              [3.162142, 42.115308],
            ],
            [
              [3.29714, 42.284082],
              [3.296982, 42.284325],
              [3.296798, 42.284425],
              [3.297032, 42.284097],
              [3.29714, 42.284082],
            ],
            [
              [3.165745, 41.862122],
              [3.165676, 41.862405],
              [3.165614, 41.86239],
              [3.165555, 41.86215],
              [3.165745, 41.862122],
            ],
            [
              [3.045641, 41.786781],
              [3.045452, 41.786809],
              [3.045421, 41.786613],
              [3.045634, 41.786701],
              [3.045641, 41.786781],
            ],
            [
              [3.079063, 41.822258],
              [3.079205, 41.822359],
              [3.079188, 41.822432],
              [3.079029, 41.822404],
              [3.079063, 41.822258],
            ],
            [
              [3.218871, 41.926189],
              [3.21877, 41.926267],
              [3.218775, 41.926069],
              [3.219002, 41.926173],
              [3.218871, 41.926189],
            ],
            [
              [3.193441, 41.89231],
              [3.193392, 41.89243],
              [3.193191, 41.892342],
              [3.193245, 41.892285],
              [3.193441, 41.89231],
            ],
            [
              [3.080116, 41.823841],
              [3.08009, 41.82395],
              [3.079803, 41.823965],
              [3.079959, 41.823831],
              [3.080116, 41.823841],
            ],
            [
              [3.046117, 41.787233],
              [3.046225, 41.787354],
              [3.046138, 41.787457],
              [3.046062, 41.78729],
              [3.046117, 41.787233],
            ],
            [
              [3.241814, 42.337977],
              [3.241522, 42.337974],
              [3.241468, 42.337914],
              [3.241989, 42.337913],
              [3.241814, 42.337977],
            ],
            [
              [3.301254, 42.326999],
              [3.301038, 42.327019],
              [3.300925, 42.326981],
              [3.301047, 42.326866],
              [3.301254, 42.326999],
            ],
            [
              [3.197839, 41.892072],
              [3.19791, 41.892185],
              [3.197776, 41.892291],
              [3.197729, 41.892186],
              [3.197839, 41.892072],
            ],
            [
              [3.047375, 41.784502],
              [3.047559, 41.784538],
              [3.047563, 41.784583],
              [3.0474, 41.784645],
              [3.047375, 41.784502],
            ],
            [
              [3.218506, 41.97398],
              [3.218442, 41.974064],
              [3.218255, 41.974119],
              [3.2183, 41.973964],
              [3.218506, 41.97398],
            ],
            [
              [3.151906, 41.861085],
              [3.151944, 41.861227],
              [3.15176, 41.861192],
              [3.151774, 41.861092],
              [3.151906, 41.861085],
            ],
            [
              [3.078227, 41.822447],
              [3.078378, 41.82252],
              [3.078329, 41.822639],
              [3.078172, 41.822504],
              [3.078227, 41.822447],
            ],
            [
              [3.297843, 42.284692],
              [3.297574, 42.284914],
              [3.297467, 42.284928],
              [3.297679, 42.284746],
              [3.297843, 42.284692],
            ],
            [
              [3.279889, 42.333153],
              [3.279742, 42.333143],
              [3.280093, 42.333016],
              [3.280096, 42.333043],
              [3.279889, 42.333153],
            ],
            [
              [3.084659, 41.827467],
              [3.084767, 41.827559],
              [3.084681, 41.827677],
              [3.084585, 41.827607],
              [3.084659, 41.827467],
            ],
            [
              [3.079396, 41.823609],
              [3.079173, 41.82354],
              [3.079153, 41.82346],
              [3.079399, 41.82351],
              [3.079396, 41.823609],
            ],
            [
              [3.1435, 41.854804],
              [3.143535, 41.854919],
              [3.143341, 41.854902],
              [3.143369, 41.85482],
              [3.1435, 41.854804],
            ],
            [
              [3.312921, 42.316462],
              [3.312791, 42.316496],
              [3.313055, 42.316346],
              [3.313027, 42.316429],
              [3.312921, 42.316462],
            ],
            [
              [3.154847, 42.391007],
              [3.154925, 42.391057],
              [3.154685, 42.391088],
              [3.154773, 42.390993],
              [3.154847, 42.391007],
            ],
            [
              [3.045768, 41.786343],
              [3.045623, 41.786332],
              [3.04549, 41.786195],
              [3.045764, 41.786298],
              [3.045768, 41.786343],
            ],
            [
              [3.193339, 41.890272],
              [3.193253, 41.89025],
              [3.193457, 41.890122],
              [3.193433, 41.89024],
              [3.193339, 41.890272],
            ],
            [
              [3.216705, 41.921158],
              [3.216817, 41.921188],
              [3.216881, 41.921347],
              [3.216634, 41.921162],
              [3.216705, 41.921158],
            ],
            [
              [3.16189, 42.398549],
              [3.161821, 42.398471],
              [3.162043, 42.398504],
              [3.162036, 42.39855],
              [3.16189, 42.398549],
            ],
            [
              [3.029681, 41.777821],
              [3.029744, 41.777853],
              [3.029424, 41.777906],
              [3.029444, 41.77786],
              [3.029681, 41.777821],
            ],
            [
              [3.175733, 42.111604],
              [3.17567, 42.111697],
              [3.17551, 42.11167],
              [3.175587, 42.111594],
              [3.175733, 42.111604],
            ],
            [
              [3.271577, 42.338848],
              [3.27141, 42.338876],
              [3.271332, 42.338826],
              [3.271604, 42.338757],
              [3.271577, 42.338848],
            ],
            [
              [3.086007, 41.825615],
              [3.086055, 41.82565],
              [3.085984, 41.825815],
              [3.085917, 41.825704],
              [3.086007, 41.825615],
            ],
            [
              [3.046841, 41.783559],
              [3.046903, 41.783582],
              [3.046945, 41.783643],
              [3.046803, 41.783669],
              [3.046841, 41.783559],
            ],
            [
              [3.046098, 41.787027],
              [3.046058, 41.787119],
              [3.045919, 41.787046],
              [3.045976, 41.787016],
              [3.046098, 41.787027],
            ],
            [
              [3.14315, 41.855174],
              [3.142923, 41.855321],
              [3.14285, 41.855316],
              [3.143076, 41.85516],
              [3.14315, 41.855174],
            ],
            [
              [3.299344, 42.327185],
              [3.299242, 42.327254],
              [3.299053, 42.327309],
              [3.299198, 42.327184],
              [3.299344, 42.327185],
            ],
            [
              [3.167469, 41.863145],
              [3.1675, 41.863216],
              [3.167422, 41.863283],
              [3.167385, 41.863141],
              [3.167469, 41.863145],
            ],
            [
              [3.239802, 42.340328],
              [3.239729, 42.34044],
              [3.239622, 42.340347],
              [3.239753, 42.340321],
              [3.239802, 42.340328],
            ],
            [
              [2.885782, 41.702044],
              [2.885781, 41.702162],
              [2.885667, 41.702095],
              [2.88571, 41.702039],
              [2.885782, 41.702044],
            ],
            [
              [3.167077, 41.863193],
              [3.167062, 41.863293],
              [3.167005, 41.863323],
              [3.166978, 41.863172],
              [3.167077, 41.863193],
            ],
            [
              [3.167199, 42.41451],
              [3.167192, 42.414555],
              [3.166958, 42.414649],
              [3.167113, 42.414497],
              [3.167199, 42.41451],
            ],
            [
              [3.216249, 41.930203],
              [3.216229, 41.93024],
              [3.215864, 41.930206],
              [3.2161, 41.930157],
              [3.216249, 41.930203],
            ],
            [
              [3.165466, 42.41319],
              [3.165397, 42.41323],
              [3.165324, 42.413108],
              [3.165484, 42.413126],
              [3.165466, 42.41319],
            ],
            [
              [3.199476, 41.89156],
              [3.199388, 41.891655],
              [3.199274, 41.891715],
              [3.199334, 41.891595],
              [3.199476, 41.89156],
            ],
            [
              [3.161419, 42.398079],
              [3.161413, 42.398142],
              [3.161211, 42.398063],
              [3.161204, 42.397992],
              [3.161257, 42.398043],
              [3.161419, 42.398079],
            ],
            [
              [3.169502, 41.862874],
              [3.169432, 41.863021],
              [3.169372, 41.863025],
              [3.169442, 41.862868],
              [3.169502, 41.862874],
            ],
            [
              [3.090304, 41.832293],
              [3.090205, 41.832291],
              [3.090209, 41.832226],
              [3.090301, 41.832195],
              [3.090304, 41.832293],
            ],
            [
              [3.239583, 42.340439],
              [3.239639, 42.340517],
              [3.239506, 42.340515],
              [3.239499, 42.340452],
              [3.239583, 42.340439],
            ],
            [
              [3.169804, 41.862758],
              [3.169694, 41.862863],
              [3.169631, 41.862831],
              [3.169799, 41.862704],
              [3.169804, 41.862758],
            ],
            [
              [3.166213, 42.413996],
              [3.166098, 42.414056],
              [3.166019, 42.413998],
              [3.166136, 42.413946],
              [3.166213, 42.413996],
            ],
            [
              [3.229995, 41.95455],
              [3.229919, 41.95451],
              [3.230045, 41.954449],
              [3.23004, 41.954521],
              [3.229995, 41.95455],
            ],
            [
              [3.167335, 41.863125],
              [3.167344, 41.863215],
              [3.167324, 41.863261],
              [3.167244, 41.863175],
              [3.167335, 41.863125],
            ],
            [
              [3.094052, 41.835963],
              [3.093946, 41.835957],
              [3.09391, 41.835929],
              [3.094024, 41.835888],
              [3.094052, 41.835963],
            ],
            [
              [3.165505, 41.862378],
              [3.16542, 41.862374],
              [3.1654, 41.862285],
              [3.165459, 41.862272],
              [3.165505, 41.862378],
            ],
            [
              [3.191741, 41.889693],
              [3.191796, 41.889762],
              [3.191754, 41.889827],
              [3.191704, 41.889686],
              [3.191741, 41.889693],
            ],
            [
              [3.169388, 41.862808],
              [3.169285, 41.862867],
              [3.169235, 41.862852],
              [3.169347, 41.862765],
              [3.169388, 41.862808],
            ],
            [
              [3.09475, 41.835836],
              [3.094766, 41.835932],
              [3.094738, 41.835952],
              [3.094652, 41.835891],
              [3.09475, 41.835836],
            ],
            [
              [3.227529, 42.249688],
              [3.227383, 42.249687],
              [3.227355, 42.249643],
              [3.227425, 42.249621],
              [3.227529, 42.249688],
            ],
            [
              [3.156998, 42.390774],
              [3.156989, 42.390811],
              [3.156862, 42.390881],
              [3.156879, 42.390799],
              [3.156998, 42.390774],
            ],
            [
              [3.152848, 41.861367],
              [3.152823, 41.861477],
              [3.152777, 41.861506],
              [3.152776, 41.861371],
              [3.152848, 41.861367],
            ],
            [
              [3.082535, 41.825697],
              [3.082522, 41.825773],
              [3.082414, 41.825721],
              [3.082444, 41.825701],
              [3.082535, 41.825697],
            ],
            [
              [3.215993, 41.935735],
              [3.215982, 41.935753],
              [3.21587, 41.935714],
              [3.216036, 41.935687],
              [3.215993, 41.935735],
            ],
            [
              [3.219352, 41.934949],
              [3.219383, 41.935019],
              [3.219349, 41.935048],
              [3.219279, 41.934944],
              [3.219352, 41.934949],
            ],
            [
              [3.228776, 41.948496],
              [3.228714, 41.948598],
              [3.22865, 41.948557],
              [3.228739, 41.948489],
              [3.228776, 41.948496],
            ],
            [
              [3.240227, 42.339468],
              [3.240137, 42.339544],
              [3.240075, 42.33953],
              [3.240153, 42.339463],
              [3.240227, 42.339468],
            ],
            [
              [3.165572, 41.861951],
              [3.165552, 41.861988],
              [3.165426, 41.861932],
              [3.165483, 41.861902],
              [3.165572, 41.861951],
            ],
            [
              [3.193558, 41.890287],
              [3.193574, 41.890332],
              [3.193654, 41.890408],
              [3.193526, 41.890334],
              [3.193558, 41.890287],
            ],
            [
              [3.138666, 41.848474],
              [3.138601, 41.84855],
              [3.138558, 41.84848],
              [3.138593, 41.848469],
              [3.138666, 41.848474],
            ],
            [
              [3.156428, 42.117713],
              [3.156421, 42.117767],
              [3.156376, 42.117797],
              [3.156354, 42.117699],
              [3.156428, 42.117713],
            ],
            [
              [3.122428, 42.136443],
              [3.122336, 42.136493],
              [3.122306, 42.136441],
              [3.122425, 42.136417],
              [3.122428, 42.136443],
            ],
            [
              [3.193416, 41.892059],
              [3.193442, 41.892076],
              [3.193437, 41.892148],
              [3.193344, 41.892054],
              [3.193416, 41.892059],
            ],
            [
              [3.228763, 41.948362],
              [3.228805, 41.948422],
              [3.228785, 41.94846],
              [3.228708, 41.94841],
              [3.228763, 41.948362],
            ],
            [
              [3.16153, 42.398343],
              [3.161561, 42.398413],
              [3.161513, 42.398425],
              [3.161467, 42.398329],
              [3.16153, 42.398343],
            ],
            [
              [3.238441, 42.344219],
              [3.238378, 42.344195],
              [3.238482, 42.344136],
              [3.238476, 42.344199],
              [3.238441, 42.344219],
            ],
            [
              [3.091553, 41.831965],
              [3.091573, 41.832038],
              [3.091518, 41.83204],
              [3.091492, 41.83198],
              [3.091553, 41.831965],
            ],
            [
              [3.217026, 41.930133],
              [3.216932, 41.930157],
              [3.216865, 41.930088],
              [3.216876, 41.930079],
              [3.217026, 41.930133],
            ],
            [
              [3.193354, 41.889929],
              [3.193405, 41.889954],
              [3.193363, 41.890019],
              [3.193317, 41.889922],
              [3.193354, 41.889929],
            ],
            [
              [3.091885, 41.832262],
              [3.091922, 41.832275],
              [3.091917, 41.832327],
              [3.091819, 41.832286],
              [3.091885, 41.832262],
            ],
            [
              [3.166102, 42.413849],
              [3.166083, 42.413904],
              [3.165997, 42.4139],
              [3.166029, 42.413853],
              [3.166102, 42.413849],
            ],
            [
              [3.193112, 41.892274],
              [3.193143, 41.892344],
              [3.19312, 41.892355],
              [3.193053, 41.892286],
              [3.193112, 41.892274],
            ],
            [
              [3.290652, 42.306745],
              [3.290567, 42.306741],
              [3.29062, 42.306666],
              [3.290684, 42.306698],
              [3.290652, 42.306745],
            ],
            [
              [3.094113, 41.836911],
              [3.094059, 41.836951],
              [3.094024, 41.836917],
              [3.094047, 41.836866],
              [3.094113, 41.836911],
            ],
            [
              [3.162522, 42.115476],
              [3.162489, 42.115514],
              [3.162402, 42.115492],
              [3.162424, 42.115463],
              [3.162522, 42.115476],
            ],
            [
              [3.16781, 41.863181],
              [3.167757, 41.863256],
              [3.167736, 41.863158],
              [3.16781, 41.863181],
            ],
            [
              [3.298019, 42.284394],
              [3.298001, 42.284449],
              [3.29794, 42.284452],
              [3.297948, 42.284407],
              [3.298019, 42.284394],
            ],
            [
              [3.166731, 41.86323],
              [3.166675, 41.863269],
              [3.166612, 41.863245],
              [3.166632, 41.863199],
              [3.166731, 41.86323],
            ],
            [
              [3.163954, 41.861651],
              [3.16388, 41.861637],
              [3.163957, 41.861561],
              [3.163954, 41.861651],
            ],
            [
              [3.163918, 41.861527],
              [3.163847, 41.86154],
              [3.163886, 41.861448],
              [3.163918, 41.861527],
            ],
            [
              [3.311139, 42.320818],
              [3.311132, 42.320872],
              [3.311088, 42.32092],
              [3.311082, 42.320857],
              [3.311139, 42.320818],
            ],
            [
              [3.094574, 41.835781],
              [3.094507, 41.835774],
              [3.094485, 41.835761],
              [3.094571, 41.835737],
              [3.094574, 41.835781],
            ],
            [
              [3.298975, 42.327736],
              [3.298917, 42.327767],
              [3.29887, 42.327778],
              [3.298889, 42.327723],
              [3.298975, 42.327736],
            ],
            [
              [3.094279, 41.836553],
              [3.094293, 41.836597],
              [3.094249, 41.836635],
              [3.094239, 41.836577],
              [3.094279, 41.836553],
            ],
            [
              [3.216204, 41.935426],
              [3.216185, 41.935481],
              [3.216148, 41.935474],
              [3.216144, 41.935429],
              [3.216204, 41.935426],
            ],
            [
              [3.182263, 41.886362],
              [3.182301, 41.886378],
              [3.182304, 41.886405],
              [3.182232, 41.886409],
              [3.182263, 41.886362],
            ],
            [
              [3.085733, 41.828313],
              [3.08572, 41.82836],
              [3.085665, 41.82834],
              [3.085675, 41.828313],
              [3.085733, 41.828313],
            ],
            [
              [3.230021, 41.955188],
              [3.230002, 41.955234],
              [3.229958, 41.955156],
              [3.23002, 41.95517],
              [3.230021, 41.955188],
            ],
            [
              [3.094085, 41.836555],
              [3.094107, 41.836589],
              [3.094097, 41.836636],
              [3.094053, 41.836592],
              [3.094085, 41.836555],
            ],
            [
              [3.113662, 41.848198],
              [3.113682, 41.848248],
              [3.113634, 41.848253],
              [3.113617, 41.848218],
              [3.113662, 41.848198],
            ],
            [
              [3.279759, 42.333187],
              [3.279798, 42.333212],
              [3.279664, 42.33321],
              [3.27971, 42.333181],
              [3.279759, 42.333187],
            ],
            [
              [3.230174, 41.954406],
              [3.230213, 41.954431],
              [3.230214, 41.954449],
              [3.230155, 41.954461],
              [3.230174, 41.954406],
            ],
            [
              [3.084826, 41.827577],
              [3.08484, 41.827619],
              [3.084791, 41.827621],
              [3.084784, 41.827586],
              [3.084826, 41.827577],
            ],
            [
              [3.230163, 41.955522],
              [3.230131, 41.955569],
              [3.230093, 41.955553],
              [3.230115, 41.955525],
              [3.230163, 41.955522],
            ],
            [
              [3.216051, 41.930024],
              [3.216041, 41.930052],
              [3.215981, 41.930046],
              [3.215989, 41.93001],
              [3.216051, 41.930024],
            ],
            [
              [3.193066, 41.891799],
              [3.193044, 41.891828],
              [3.192983, 41.891813],
              [3.193053, 41.891791],
              [3.193066, 41.891799],
            ],
            [
              [3.182204, 41.886248],
              [3.182137, 41.886297],
              [3.182131, 41.886243],
              [3.182204, 41.886248],
            ],
            [
              [3.161401, 42.398269],
              [3.16135, 42.398245],
              [3.161358, 42.398208],
              [3.161396, 42.398215],
              [3.161401, 42.398269],
            ],
            [
              [3.29769, 42.328473],
              [3.297716, 42.32849],
              [3.297641, 42.328467],
              [3.297651, 42.328448],
              [3.29769, 42.328473],
            ],
            [
              [3.165805, 41.862371],
              [3.165748, 41.862401],
              [3.165766, 41.862337],
              [3.165805, 41.862371],
            ],
            [
              [3.259179, 42.335208],
              [3.259205, 42.335233],
              [3.259207, 42.335251],
              [3.259153, 42.335191],
              [3.259179, 42.335208],
            ],
            [
              [3.162499, 42.11599],
              [3.162453, 42.116011],
              [3.162429, 42.116012],
              [3.162437, 42.115976],
              [3.162499, 42.11599],
            ],
            [
              [3.161772, 41.860626],
              [3.161721, 41.860602],
              [3.161741, 41.860556],
              [3.161772, 41.860626],
            ],
            [
              [3.300182, 42.326833],
              [3.300167, 42.326806],
              [3.300217, 42.326813],
              [3.300218, 42.326831],
              [3.300182, 42.326833],
            ],
            [
              [3.161504, 42.398209],
              [3.161483, 42.398238],
              [3.161466, 42.398194],
              [3.16149, 42.398192],
              [3.161504, 42.398209],
            ],
            [
              [3.161687, 42.398344],
              [3.161689, 42.398362],
              [3.161652, 42.398355],
              [3.161698, 42.398325],
              [3.161687, 42.398344],
            ],
            [
              [3.147371, 41.857755],
              [3.147325, 41.857776],
              [3.147299, 41.85775],
              [3.147311, 41.857749],
              [3.147371, 41.857755],
            ],
            [
              [3.285601, 42.332164],
              [3.285591, 42.332182],
              [3.285566, 42.332175],
              [3.285588, 42.332155],
              [3.285601, 42.332164],
            ],
            [
              [3.160023, 42.116511],
              [3.159963, 42.116514],
              [3.159973, 42.116496],
              [3.160023, 42.116511],
            ],
          ],
          [
            [
              [1.997127, 42.486369],
              [1.986659, 42.471458],
              [2.012768, 42.448347],
              [1.956747, 42.457687],
              [1.978844, 42.494775],
              [1.997127, 42.486369],
            ],
          ],
          [
            [
              [2.030427, 42.127198],
              [2.017031, 42.109672],
              [2.007542, 42.107252],
              [2.00253, 42.125567],
              [2.030427, 42.127198],
            ],
          ],
          [
            [
              [3.22512, 42.049221],
              [3.22334, 42.044862],
              [3.219233, 42.047192],
              [3.220048, 42.049227],
              [3.22512, 42.049221],
            ],
            [
              [3.219618, 42.04718],
              [3.219646, 42.047215],
              [3.219545, 42.047175],
              [3.219581, 42.047173],
              [3.219618, 42.04718],
            ],
          ],
          [
            [
              [3.295328, 42.294336],
              [3.29733, 42.293253],
              [3.296557, 42.289893],
              [3.293099, 42.292892],
              [3.295328, 42.294336],
            ],
            [
              [3.297106, 42.292833],
              [3.297111, 42.292887],
              [3.296956, 42.292914],
              [3.297021, 42.292829],
              [3.297106, 42.292833],
            ],
          ],
          [
            [
              [3.319286, 42.324886],
              [3.320246, 42.323096],
              [3.324503, 42.321285],
              [3.321372, 42.321512],
              [3.32151, 42.322153],
              [3.318452, 42.323834],
              [3.319286, 42.324886],
            ],
          ],
          [
            [
              [3.22709, 42.044802],
              [3.22715, 42.043935],
              [3.225403, 42.043364],
              [3.225045, 42.042915],
              [3.225254, 42.04258],
              [3.223767, 42.043669],
              [3.22709, 42.044802],
            ],
          ],
          [
            [
              [3.292519, 42.282483],
              [3.293659, 42.28152],
              [3.293373, 42.27987],
              [3.291747, 42.281184],
              [3.292519, 42.282483],
            ],
          ],
          [
            [
              [3.305326, 42.328169],
              [3.306675, 42.32759],
              [3.304052, 42.327195],
              [3.304198, 42.327682],
              [3.305326, 42.328169],
            ],
          ],
          [
            [
              [3.165897, 42.401879],
              [3.166556, 42.401168],
              [3.165272, 42.400707],
              [3.165182, 42.401648],
              [3.165897, 42.401879],
            ],
          ],
          [
            [
              [3.295349, 42.295514],
              [3.296821, 42.294469],
              [3.296688, 42.293865],
              [3.295315, 42.294804],
              [3.295349, 42.295514],
            ],
          ],
          [
            [
              [3.20485, 42.074383],
              [3.205729, 42.074227],
              [3.206103, 42.073361],
              [3.205034, 42.073419],
              [3.20485, 42.074383],
            ],
          ],
          [
            [
              [3.33191, 42.320163],
              [3.332554, 42.31993],
              [3.331928, 42.319019],
              [3.331367, 42.319824],
              [3.33191, 42.320163],
            ],
          ],
          [
            [
              [3.308314, 42.292413],
              [3.308961, 42.292089],
              [3.309307, 42.291305],
              [3.308532, 42.291321],
              [3.308314, 42.292413],
            ],
          ],
          [
            [
              [3.056624, 41.787308],
              [3.056215, 41.786672],
              [3.054936, 41.787136],
              [3.055497, 41.787349],
              [3.056624, 41.787308],
            ],
          ],
          [
            [
              [3.228505, 42.041989],
              [3.22833, 42.04144],
              [3.227891, 42.041761],
              [3.228007, 42.041962],
              [3.228505, 42.041989],
            ],
          ],
          [
            [
              [2.940163, 41.719769],
              [2.93964, 41.720093],
              [2.939894, 41.720494],
              [2.940355, 41.720155],
              [2.940163, 41.719769],
            ],
          ],
          [
            [
              [3.309211, 42.292283],
              [3.309409, 42.292209],
              [3.309803, 42.291539],
              [3.308828, 42.292574],
              [3.309211, 42.292283],
            ],
          ],
          [
            [
              [3.217994, 41.938209],
              [3.218429, 41.937726],
              [3.21787, 41.937559],
              [3.217671, 41.937867],
              [3.217994, 41.938209],
            ],
          ],
          [
            [
              [3.197764, 42.084461],
              [3.197423, 42.084309],
              [3.197308, 42.084864],
              [3.197684, 42.084997],
              [3.197764, 42.084461],
            ],
          ],
          [
            [
              [3.264248, 42.237473],
              [3.264351, 42.237287],
              [3.263344, 42.237271],
              [3.263989, 42.237541],
              [3.264248, 42.237473],
            ],
          ],
          [
            [
              [3.227299, 42.042252],
              [3.227699, 42.042149],
              [3.227634, 42.041856],
              [3.22694, 42.041921],
              [3.227299, 42.042252],
            ],
          ],
          [
            [
              [3.179141, 41.868033],
              [3.179146, 41.867718],
              [3.178534, 41.86749],
              [3.178833, 41.868212],
              [3.179141, 41.868033],
            ],
          ],
          [
            [
              [3.178959, 41.867521],
              [3.179301, 41.867322],
              [3.178523, 41.867121],
              [3.178534, 41.867364],
              [3.178959, 41.867521],
            ],
          ],
          [
            [
              [3.032564, 41.772421],
              [3.032602, 41.772185],
              [3.032062, 41.771691],
              [3.032574, 41.772655],
              [3.032564, 41.772421],
            ],
          ],
          [
            [
              [3.185332, 41.86282],
              [3.185998, 41.862739],
              [3.186444, 41.862489],
              [3.18525, 41.862599],
              [3.185332, 41.86282],
            ],
          ],
          [
            [
              [2.911982, 41.707689],
              [2.912337, 41.707626],
              [2.912425, 41.707405],
              [2.91153, 41.707739],
              [2.911982, 41.707689],
            ],
          ],
          [
            [
              [2.827173, 41.689946],
              [2.826938, 41.689877],
              [2.826627, 41.689892],
              [2.826952, 41.69029],
              [2.827173, 41.689946],
            ],
          ],
          [
            [
              [3.187395, 41.862735],
              [3.187532, 41.862413],
              [3.187157, 41.862271],
              [3.187038, 41.862781],
              [3.187395, 41.862735],
            ],
          ],
          [
            [
              [3.324223, 42.322354],
              [3.323795, 42.321846],
              [3.323483, 42.321998],
              [3.323968, 42.322359],
              [3.324223, 42.322354],
            ],
          ],
          [
            [
              [2.896375, 41.703858],
              [2.896091, 41.703521],
              [2.895726, 41.703594],
              [2.895878, 41.703811],
              [2.896375, 41.703858],
            ],
          ],
          [
            [
              [2.945999, 41.725839],
              [2.946239, 41.725836],
              [2.946309, 41.725553],
              [2.945824, 41.725632],
              [2.945999, 41.725839],
            ],
          ],
          [
            [
              [3.291701, 42.303168],
              [3.291693, 42.30297],
              [3.292157, 42.302756],
              [3.291528, 42.302889],
              [3.291701, 42.303168],
            ],
          ],
          [
            [
              [3.291574, 42.279699],
              [3.291693, 42.279432],
              [3.291189, 42.27936],
              [3.291146, 42.27966],
              [3.291574, 42.279699],
            ],
          ],
          [
            [
              [3.131574, 42.12886],
              [3.131801, 42.12883],
              [3.131958, 42.128578],
              [3.131625, 42.128515],
              [3.131341, 42.128954],
              [3.131574, 42.12886],
            ],
          ],
          [
            [
              [3.283677, 42.273923],
              [3.283769, 42.273756],
              [3.283375, 42.273571],
              [3.283268, 42.273829],
              [3.283677, 42.273923],
            ],
          ],
          [
            [
              [3.234206, 41.962341],
              [3.234063, 41.962106],
              [3.233579, 41.962213],
              [3.23349, 41.96229],
              [3.234206, 41.962341],
            ],
          ],
          [
            [
              [2.96523, 41.745038],
              [2.965049, 41.745291],
              [2.965496, 41.745321],
              [2.965493, 41.745151],
              [2.96523, 41.745038],
            ],
          ],
          [
            [
              [2.80366, 41.673325],
              [2.803801, 41.673165],
              [2.803112, 41.67311],
              [2.803196, 41.67324],
              [2.80366, 41.673325],
            ],
          ],
          [
            [
              [3.165558, 42.361831],
              [3.1654, 42.361831],
              [3.164714, 42.362507],
              [3.165592, 42.361938],
              [3.165558, 42.361831],
            ],
          ],
          [
            [
              [3.094535, 41.834947],
              [3.094622, 41.834759],
              [3.094133, 41.834738],
              [3.094285, 41.834979],
              [3.094535, 41.834947],
            ],
          ],
          [
            [
              [3.322143, 42.318796],
              [3.322523, 42.318595],
              [3.322163, 42.318516],
              [3.321706, 42.318676],
              [3.322143, 42.318796],
            ],
          ],
          [
            [
              [2.792395, 41.670358],
              [2.792139, 41.67019],
              [2.792384, 41.670772],
              [2.792463, 41.670453],
              [2.792395, 41.670358],
            ],
          ],
          [
            [
              [3.192627, 42.240963],
              [3.192836, 42.240871],
              [3.192461, 42.24063],
              [3.192388, 42.240877],
              [3.192627, 42.240963],
            ],
          ],
          [
            [
              [3.287131, 42.281087],
              [3.286961, 42.281078],
              [3.286655, 42.281293],
              [3.28692, 42.281405],
              [3.287131, 42.281087],
            ],
          ],
          [
            [
              [3.310761, 42.311856],
              [3.310105, 42.31209],
              [3.309805, 42.312358],
              [3.310646, 42.312042],
              [3.310761, 42.311856],
            ],
          ],
          [
            [
              [3.300791, 42.30543],
              [3.301067, 42.305397],
              [3.301179, 42.305184],
              [3.300819, 42.305104],
              [3.300791, 42.30543],
            ],
          ],
          [
            [
              [3.291179, 42.298435],
              [3.290862, 42.29829],
              [3.290669, 42.298544],
              [3.290742, 42.298549],
              [3.291179, 42.298435],
            ],
          ],
          [
            [
              [3.260463, 42.33665],
              [3.260602, 42.336588],
              [3.260861, 42.336628],
              [3.260602, 42.336345],
              [3.260463, 42.33665],
            ],
          ],
          [
            [
              [3.277596, 42.337528],
              [3.277807, 42.337336],
              [3.27726, 42.337447],
              [3.277306, 42.337535],
              [3.277596, 42.337528],
            ],
          ],
          [
            [
              [3.046094, 41.779592],
              [3.045496, 41.779498],
              [3.045442, 41.779564],
              [3.046259, 41.779683],
              [3.046094, 41.779592],
            ],
          ],
          [
            [
              [3.291919, 42.279626],
              [3.292081, 42.279554],
              [3.292088, 42.279383],
              [3.29168, 42.279541],
              [3.291919, 42.279626],
            ],
          ],
          [
            [
              [3.165188, 42.400108],
              [3.165239, 42.400006],
              [3.164637, 42.399931],
              [3.164839, 42.400136],
              [3.165188, 42.400108],
            ],
          ],
          [
            [
              [3.309799, 42.290531],
              [3.309832, 42.290259],
              [3.30933, 42.290449],
              [3.309729, 42.290562],
              [3.309799, 42.290531],
            ],
          ],
          [
            [
              [3.123918, 42.136346],
              [3.123752, 42.136381],
              [3.123585, 42.13666],
              [3.123847, 42.136736],
              [3.123918, 42.136346],
            ],
          ],
          [
            [
              [3.316222, 42.313822],
              [3.316343, 42.313824],
              [3.316695, 42.313706],
              [3.315995, 42.313745],
              [3.316222, 42.313822],
            ],
          ],
          [
            [
              [3.234578, 42.350407],
              [3.234797, 42.350287],
              [3.234316, 42.350214],
              [3.234383, 42.350399],
              [3.234578, 42.350407],
            ],
          ],
          [
            [
              [3.221952, 42.051653],
              [3.221773, 42.051671],
              [3.221711, 42.052026],
              [3.221994, 42.05183],
              [3.221952, 42.051653],
            ],
          ],
          [
            [
              [3.123795, 42.132581],
              [3.123784, 42.132338],
              [3.123545, 42.132495],
              [3.123704, 42.132765],
              [3.123795, 42.132581],
            ],
          ],
          [
            [
              [3.028374, 41.770571],
              [3.027998, 41.770663],
              [3.027945, 41.770747],
              [3.028289, 41.770819],
              [3.028374, 41.770571],
            ],
          ],
          [
            [
              [2.945829, 41.726073],
              [2.945399, 41.725969],
              [2.945307, 41.726019],
              [2.945811, 41.726272],
              [2.945829, 41.726073],
            ],
          ],
          [
            [
              [3.129688, 41.840719],
              [3.129472, 41.840848],
              [3.129496, 41.841099],
              [3.129695, 41.840917],
              [3.129688, 41.840719],
            ],
          ],
          [
            [
              [3.165749, 42.41187],
              [3.165952, 42.411589],
              [3.165756, 42.411446],
              [3.165613, 42.411598],
              [3.165749, 42.41187],
            ],
          ],
          [
            [
              [3.044247, 41.77888],
              [3.04411, 41.778707],
              [3.043786, 41.778841],
              [3.044218, 41.778953],
              [3.044247, 41.77888],
            ],
          ],
          [
            [
              [3.209081, 41.905604],
              [3.208927, 41.90563],
              [3.20879, 41.905826],
              [3.209154, 41.905852],
              [3.209081, 41.905604],
            ],
          ],
          [
            [
              [2.870256, 41.699466],
              [2.869946, 41.69923],
              [2.869801, 41.699354],
              [2.87006, 41.699557],
              [2.870256, 41.699466],
            ],
          ],
          [
            [
              [3.22725, 41.944601],
              [3.227403, 41.944439],
              [3.226811, 41.944552],
              [3.227096, 41.944627],
              [3.22725, 41.944601],
            ],
          ],
          [
            [
              [3.167799, 42.368831],
              [3.167844, 42.368675],
              [3.167309, 42.368794],
              [3.167659, 42.368892],
              [3.167799, 42.368831],
            ],
          ],
          [
            [
              [3.31654, 42.313489],
              [3.316603, 42.313396],
              [3.316813, 42.313312],
              [3.316246, 42.313461],
              [3.31654, 42.313489],
            ],
          ],
          [
            [
              [2.982424, 41.759039],
              [2.982617, 41.758911],
              [2.982027, 41.759158],
              [2.982228, 41.759121],
              [2.982424, 41.759039],
            ],
          ],
          [
            [
              [3.045171, 41.779875],
              [3.045129, 41.779814],
              [3.04476, 41.779861],
              [3.04522, 41.780017],
              [3.045171, 41.779875],
            ],
          ],
          [
            [
              [3.048786, 41.78225],
              [3.048905, 41.782234],
              [3.049043, 41.782047],
              [3.048597, 41.782287],
              [3.048786, 41.78225],
            ],
          ],
          [
            [
              [3.196685, 42.084673],
              [3.196849, 42.084619],
              [3.196395, 42.084545],
              [3.196603, 42.084695],
              [3.196685, 42.084673],
            ],
          ],
          [
            [
              [3.309161, 42.312223],
              [3.309353, 42.312086],
              [3.309352, 42.311951],
              [3.308999, 42.312187],
              [3.309161, 42.312223],
            ],
          ],
          [
            [
              [3.209951, 41.903027],
              [3.209928, 41.902911],
              [3.209385, 41.902905],
              [3.209857, 41.90305],
              [3.209951, 41.903027],
            ],
          ],
          [
            [
              [3.171017, 42.424169],
              [3.170838, 42.424206],
              [3.170733, 42.424491],
              [3.171157, 42.424234],
              [3.171017, 42.424169],
            ],
          ],
          [
            [
              [3.193709, 42.240382],
              [3.193388, 42.240328],
              [3.193318, 42.24035],
              [3.193579, 42.240542],
              [3.193709, 42.240382],
            ],
          ],
          [
            [
              [3.040979, 41.778269],
              [3.041179, 41.778223],
              [3.040441, 41.778181],
              [3.040752, 41.77829],
              [3.040979, 41.778269],
            ],
          ],
          [
            [
              [3.092882, 41.832044],
              [3.092864, 41.831887],
              [3.092732, 41.83221],
              [3.092868, 41.832192],
              [3.092882, 41.832044],
            ],
          ],
          [
            [
              [3.197809, 42.242536],
              [3.197961, 42.242483],
              [3.198068, 42.242333],
              [3.19774, 42.242324],
              [3.197809, 42.242536],
            ],
          ],
          [
            [
              [3.167446, 42.389463],
              [3.167288, 42.389337],
              [3.167223, 42.389664],
              [3.167362, 42.389729],
              [3.167446, 42.389463],
            ],
          ],
          [
            [
              [3.198236, 42.241955],
              [3.197872, 42.242065],
              [3.197764, 42.242206],
              [3.198129, 42.242096],
              [3.198236, 42.241955],
            ],
          ],
          [
            [
              [2.842189, 41.693295],
              [2.842059, 41.693319],
              [2.842463, 41.693542],
              [2.842426, 41.69339],
              [2.842189, 41.693295],
            ],
          ],
          [
            [
              [3.226786, 42.041335],
              [3.226918, 42.041328],
              [3.227018, 42.041232],
              [3.226699, 42.041187],
              [3.226786, 42.041335],
            ],
          ],
          [
            [
              [3.180069, 41.877128],
              [3.179845, 41.876933],
              [3.179613, 41.877027],
              [3.179808, 41.877043],
              [3.180069, 41.877128],
            ],
          ],
          [
            [
              [2.813938, 41.681841],
              [2.81382, 41.681991],
              [2.814123, 41.68203],
              [2.814131, 41.681985],
              [2.813938, 41.681841],
            ],
          ],
          [
            [
              [3.176028, 42.354875],
              [3.175798, 42.354878],
              [3.175907, 42.355124],
              [3.176039, 42.355108],
              [3.176028, 42.354875],
            ],
          ],
          [
            [
              [2.838715, 41.692472],
              [2.838573, 41.692489],
              [2.838967, 41.692865],
              [2.838908, 41.692607],
              [2.838715, 41.692472],
            ],
          ],
          [
            [
              [3.229907, 41.970299],
              [3.230161, 41.970177],
              [3.229629, 41.97017],
              [3.229814, 41.970205],
              [3.229907, 41.970299],
            ],
          ],
          [
            [
              [2.975469, 41.753677],
              [2.975333, 41.753639],
              [2.975264, 41.753796],
              [2.975541, 41.753808],
              [2.975469, 41.753677],
            ],
          ],
          [
            [
              [2.816743, 41.681789],
              [2.816453, 41.681768],
              [2.816355, 41.681881],
              [2.816551, 41.681925],
              [2.816743, 41.681789],
            ],
          ],
          [
            [
              [3.208685, 41.906858],
              [3.208549, 41.906821],
              [3.208385, 41.906992],
              [3.208661, 41.906977],
              [3.208685, 41.906858],
            ],
          ],
          [
            [
              [2.807248, 41.676636],
              [2.807079, 41.676626],
              [2.80708, 41.67677],
              [2.807323, 41.676803],
              [2.807248, 41.676636],
            ],
          ],
          [
            [
              [3.190089, 41.887559],
              [3.18989, 41.887498],
              [3.190092, 41.887838],
              [3.190135, 41.887664],
              [3.190089, 41.887559],
            ],
          ],
          [
            [
              [3.169597, 42.419376],
              [3.16961, 42.419259],
              [3.169845, 42.419048],
              [3.169468, 42.419293],
              [3.169597, 42.419376],
            ],
          ],
          [
            [
              [3.190608, 41.88745],
              [3.19043, 41.887477],
              [3.190656, 41.88769],
              [3.190687, 41.887517],
              [3.190608, 41.88745],
            ],
          ],
          [
            [
              [3.22583, 42.04217],
              [3.22565, 42.042189],
              [3.225609, 42.04238],
              [3.225764, 42.042363],
              [3.22583, 42.04217],
            ],
          ],
          [
            [
              [3.309323, 42.290746],
              [3.309088, 42.290696],
              [3.308982, 42.290846],
              [3.309189, 42.290862],
              [3.309323, 42.290746],
            ],
          ],
          [
            [
              [3.226406, 41.946204],
              [3.22645, 41.946039],
              [3.226179, 41.946099],
              [3.226196, 41.946152],
              [3.226406, 41.946204],
            ],
          ],
          [
            [
              [3.294836, 42.280924],
              [3.295053, 42.280795],
              [3.294568, 42.280912],
              [3.294626, 42.281007],
              [3.294836, 42.280924],
            ],
          ],
          [
            [
              [3.164921, 42.363135],
              [3.164901, 42.363055],
              [3.164665, 42.363005],
              [3.16471, 42.363209],
              [3.164921, 42.363135],
            ],
          ],
          [
            [
              [3.206571, 41.89961],
              [3.2063, 41.899678],
              [3.206155, 41.899794],
              [3.206326, 41.899821],
              [3.206571, 41.89961],
            ],
          ],
          [
            [
              [2.967251, 41.746994],
              [2.967178, 41.746863],
              [2.966846, 41.746907],
              [2.967146, 41.747036],
              [2.967251, 41.746994],
            ],
          ],
          [
            [
              [3.218169, 42.350257],
              [3.218313, 42.350123],
              [3.217996, 42.350104],
              [3.218133, 42.350268],
              [3.218169, 42.350257],
            ],
          ],
          [
            [
              [2.803766, 41.673968],
              [2.803579, 41.674031],
              [2.803685, 41.674269],
              [2.803779, 41.674255],
              [2.803766, 41.673968],
            ],
          ],
          [
            [
              [3.218249, 41.938969],
              [3.218107, 41.938869],
              [3.217897, 41.938944],
              [3.218102, 41.939067],
              [3.218249, 41.938969],
            ],
          ],
          [
            [
              [3.291059, 42.279278],
              [3.290974, 42.279273],
              [3.290871, 42.279576],
              [3.291051, 42.279431],
              [3.291059, 42.279278],
            ],
          ],
          [
            [
              [3.280008, 42.277482],
              [3.279869, 42.277544],
              [3.279713, 42.277688],
              [3.279964, 42.277647],
              [3.280008, 42.277482],
            ],
          ],
          [
            [
              [3.171232, 42.432646],
              [3.171353, 42.432523],
              [3.171393, 42.43243],
              [3.171133, 42.432508],
              [3.171232, 42.432646],
            ],
          ],
          [
            [
              [2.966426, 41.746524],
              [2.966299, 41.746585],
              [2.966629, 41.746775],
              [2.966451, 41.746667],
              [2.966426, 41.746524],
            ],
          ],
          [
            [
              [3.183221, 41.881323],
              [3.18306, 41.881152],
              [3.182945, 41.881212],
              [3.183069, 41.881368],
              [3.183221, 41.881323],
            ],
          ],
          [
            [
              [2.981019, 41.757762],
              [2.98079, 41.757756],
              [2.980675, 41.757816],
              [2.980934, 41.757883],
              [2.981019, 41.757762],
            ],
          ],
          [
            [
              [3.20495, 42.347519],
              [3.204965, 42.34742],
              [3.204816, 42.347383],
              [3.204565, 42.347423],
              [3.20495, 42.347519],
            ],
          ],
          [
            [
              [3.179497, 41.877816],
              [3.179407, 41.877758],
              [3.179232, 41.877812],
              [3.179411, 41.877929],
              [3.179497, 41.877816],
            ],
          ],
          [
            [
              [3.165014, 42.362842],
              [3.164735, 42.362722],
              [3.164644, 42.36279],
              [3.164873, 42.362895],
              [3.165014, 42.362842],
            ],
          ],
          [
            [
              [2.822902, 41.68947],
              [2.822768, 41.689441],
              [2.822689, 41.689499],
              [2.822914, 41.689595],
              [2.822902, 41.68947],
            ],
          ],
          [
            [
              [3.324521, 42.322787],
              [3.324683, 42.322715],
              [3.324537, 42.322588],
              [3.324414, 42.322685],
              [3.324521, 42.322787],
            ],
          ],
          [
            [
              [3.197859, 42.24194],
              [3.197849, 42.241832],
              [3.197497, 42.24195],
              [3.197562, 42.241992],
              [3.197859, 42.24194],
            ],
          ],
          [
            [
              [3.124986, 42.13051],
              [3.1249, 42.13037],
              [3.124848, 42.130715],
              [3.124964, 42.130664],
              [3.124986, 42.13051],
            ],
          ],
          [
            [
              [3.19209, 42.338793],
              [3.192197, 42.338778],
              [3.192277, 42.33872],
              [3.191914, 42.33873],
              [3.19209, 42.338793],
            ],
          ],
          [
            [
              [3.232177, 41.957132],
              [3.232128, 41.957],
              [3.231904, 41.957057],
              [3.232038, 41.957194],
              [3.232177, 41.957132],
            ],
          ],
          [
            [
              [3.192389, 42.241129],
              [3.192402, 42.241011],
              [3.19213, 42.24108],
              [3.192171, 42.241132],
              [3.192389, 42.241129],
            ],
          ],
          [
            [
              [2.827459, 41.690453],
              [2.827534, 41.69035],
              [2.827181, 41.690431],
              [2.827276, 41.690553],
              [2.827459, 41.690453],
            ],
          ],
          [
            [
              [3.208469, 41.908229],
              [3.208649, 41.90822],
              [3.208916, 41.908115],
              [3.208567, 41.908116],
              [3.208469, 41.908229],
            ],
          ],
          [
            [
              [3.175288, 42.434965],
              [3.175032, 42.435078],
              [3.175038, 42.435141],
              [3.175229, 42.435113],
              [3.175288, 42.434965],
            ],
          ],
          [
            [
              [3.217813, 41.938696],
              [3.217636, 41.938616],
              [3.217563, 41.938737],
              [3.217667, 41.938812],
              [3.217813, 41.938696],
            ],
          ],
          [
            [
              [2.980759, 41.75845],
              [2.980887, 41.758282],
              [2.980679, 41.758239],
              [2.980695, 41.758409],
              [2.980759, 41.75845],
            ],
          ],
          [
            [
              [2.808414, 41.676919],
              [2.80822, 41.676893],
              [2.808158, 41.677004],
              [2.808257, 41.677035],
              [2.808414, 41.676919],
            ],
          ],
          [
            [
              [3.294225, 42.280633],
              [3.293999, 42.280556],
              [3.293955, 42.280594],
              [3.294023, 42.280789],
              [3.294225, 42.280633],
            ],
          ],
          [
            [
              [2.826088, 41.690037],
              [2.826048, 41.690129],
              [2.826284, 41.690207],
              [2.826263, 41.690109],
              [2.826088, 41.690037],
            ],
          ],
          [
            [
              [3.286066, 42.281587],
              [3.286194, 42.281535],
              [3.286143, 42.281393],
              [3.285951, 42.28153],
              [3.286066, 42.281587],
            ],
          ],
          [
            [
              [2.943188, 41.725193],
              [2.943062, 41.725262],
              [2.943287, 41.72535],
              [2.943251, 41.725226],
              [2.943188, 41.725193],
            ],
          ],
          [
            [
              [3.226649, 41.946344],
              [3.2268, 41.946281],
              [3.226781, 41.946219],
              [3.226476, 41.946299],
              [3.226649, 41.946344],
            ],
          ],
          [
            [
              [3.170034, 42.418011],
              [3.169792, 42.418024],
              [3.169527, 42.418165],
              [3.169648, 42.418158],
              [3.170034, 42.418011],
            ],
          ],
          [
            [
              [2.959206, 41.740743],
              [2.959108, 41.740856],
              [2.959248, 41.740939],
              [2.959283, 41.740802],
              [2.959206, 41.740743],
            ],
          ],
          [
            [
              [3.187653, 41.86192],
              [3.187568, 41.861916],
              [3.187414, 41.862068],
              [3.187641, 41.862047],
              [3.187653, 41.86192],
            ],
          ],
          [
            [
              [3.220797, 41.974341],
              [3.220535, 41.974247],
              [3.22047, 41.974197],
              [3.220724, 41.974453],
              [3.220797, 41.974341],
            ],
          ],
          [
            [
              [3.301014, 42.304986],
              [3.300903, 42.304974],
              [3.300836, 42.305031],
              [3.301087, 42.305108],
              [3.301014, 42.304986],
            ],
          ],
          [
            [
              [2.865025, 41.699005],
              [2.86504, 41.698905],
              [2.864821, 41.69888],
              [2.864904, 41.699002],
              [2.865025, 41.699005],
            ],
          ],
          [
            [
              [3.183463, 41.881337],
              [3.183362, 41.881289],
              [3.183473, 41.881562],
              [3.183534, 41.88145],
              [3.183463, 41.881337],
            ],
          ],
          [
            [
              [3.182933, 42.35388],
              [3.182723, 42.353846],
              [3.182514, 42.353696],
              [3.18259, 42.353854],
              [3.182933, 42.35388],
            ],
          ],
          [
            [
              [3.190261, 42.341295],
              [3.190147, 42.341364],
              [3.190149, 42.341508],
              [3.190283, 42.341519],
              [3.190261, 42.341295],
            ],
          ],
          [
            [
              [3.212223, 42.054054],
              [3.212319, 42.054049],
              [3.212329, 42.053895],
              [3.212049, 42.054001],
              [3.212223, 42.054054],
            ],
          ],
          [
            [
              [3.179441, 41.877486],
              [3.179305, 41.877448],
              [3.179133, 41.877539],
              [3.179364, 41.877562],
              [3.179441, 41.877486],
            ],
          ],
          [
            [
              [2.845582, 41.695345],
              [2.845401, 41.695336],
              [2.845377, 41.695472],
              [2.845554, 41.695436],
              [2.845582, 41.695345],
            ],
          ],
          [
            [
              [3.309671, 42.290826],
              [3.309663, 42.290745],
              [3.309373, 42.290761],
              [3.30944, 42.290821],
              [3.309671, 42.290826],
            ],
          ],
          [
            [
              [3.289653, 42.304028],
              [3.289493, 42.304126],
              [3.289524, 42.304197],
              [3.289677, 42.304143],
              [3.289653, 42.304028],
            ],
          ],
          [
            [
              [3.230994, 41.96834],
              [3.23112, 41.96827],
              [3.230854, 41.968267],
              [3.230883, 41.968319],
              [3.230994, 41.96834],
            ],
          ],
          [
            [
              [3.045788, 41.779915],
              [3.045628, 41.779878],
              [3.045583, 41.779907],
              [3.045762, 41.780024],
              [3.045788, 41.779915],
            ],
          ],
          [
            [
              [3.225436, 41.972927],
              [3.225377, 41.972814],
              [3.225287, 41.972882],
              [3.225382, 41.972993],
              [3.225436, 41.972927],
            ],
          ],
          [
            [
              [2.822139, 41.68886],
              [2.822029, 41.688848],
              [2.821917, 41.688935],
              [2.82209, 41.688989],
              [2.822139, 41.68886],
            ],
          ],
          [
            [
              [3.169397, 42.420305],
              [3.169229, 42.420197],
              [3.169054, 42.420279],
              [3.169105, 42.420303],
              [3.169397, 42.420305],
            ],
          ],
          [
            [
              [2.809671, 41.676603],
              [2.809514, 41.676602],
              [2.809499, 41.676702],
              [2.809644, 41.676712],
              [2.809671, 41.676603],
            ],
          ],
          [
            [
              [3.213186, 42.058709],
              [3.21315, 42.058585],
              [3.212962, 42.05864],
              [3.213127, 42.058722],
              [3.213186, 42.058709],
            ],
          ],
          [
            [
              [3.186291, 42.344598],
              [3.185943, 42.344518],
              [3.185872, 42.34453],
              [3.185881, 42.34462],
              [3.186291, 42.344598],
            ],
          ],
          [
            [
              [3.209723, 41.903525],
              [3.209652, 41.903421],
              [3.209451, 41.903459],
              [3.209529, 41.903518],
              [3.209723, 41.903525],
            ],
          ],
          [
            [
              [3.17946, 42.102968],
              [3.179306, 42.102995],
              [3.179249, 42.103151],
              [3.179411, 42.103079],
              [3.17946, 42.102968],
            ],
          ],
          [
            [
              [2.972095, 41.752818],
              [2.972263, 41.752809],
              [2.972292, 41.752735],
              [2.97204, 41.75274],
              [2.972095, 41.752818],
            ],
          ],
          [
            [
              [2.803408, 41.673995],
              [2.803318, 41.674062],
              [2.803486, 41.674198],
              [2.803477, 41.674099],
              [2.803408, 41.673995],
            ],
          ],
          [
            [
              [2.828639, 41.690492],
              [2.828569, 41.690514],
              [2.828534, 41.69066],
              [2.828723, 41.690614],
              [2.828639, 41.690492],
            ],
          ],
          [
            [
              [3.20973, 41.905217],
              [3.20986, 41.905183],
              [3.209631, 41.905187],
              [3.20945, 41.905305],
              [3.20973, 41.905217],
            ],
          ],
          [
            [
              [2.98089, 41.757931],
              [2.980786, 41.757972],
              [2.980929, 41.758091],
              [2.980982, 41.758007],
              [2.98089, 41.757931],
            ],
          ],
          [
            [
              [3.186565, 41.862744],
              [3.186429, 41.862715],
              [3.186456, 41.862867],
              [3.186598, 41.862841],
              [3.186565, 41.862744],
            ],
          ],
          [
            [
              [3.156181, 42.378108],
              [3.15629, 42.378111],
              [3.156253, 42.377978],
              [3.156096, 42.378113],
              [3.156181, 42.378108],
            ],
          ],
          [
            [
              [3.183209, 41.881072],
              [3.183133, 41.88104],
              [3.183401, 41.881188],
              [3.183318, 41.881084],
              [3.183209, 41.881072],
            ],
          ],
          [
            [
              [2.945665, 41.725856],
              [2.945592, 41.725851],
              [2.945526, 41.725918],
              [2.945653, 41.725992],
              [2.945665, 41.725856],
            ],
          ],
          [
            [
              [3.164533, 42.362769],
              [3.164448, 42.362765],
              [3.164293, 42.362917],
              [3.164541, 42.36285],
              [3.164533, 42.362769],
            ],
          ],
          [
            [
              [3.320872, 42.32208],
              [3.321017, 42.322081],
              [3.32082, 42.32193],
              [3.320773, 42.322067],
              [3.320872, 42.32208],
            ],
          ],
          [
            [
              [3.002026, 41.765529],
              [3.001856, 41.765511],
              [3.001742, 41.765445],
              [3.001824, 41.765557],
              [3.002026, 41.765529],
            ],
          ],
          [
            [
              [3.003911, 41.767293],
              [3.003861, 41.767278],
              [3.003752, 41.767401],
              [3.003933, 41.7674],
              [3.003911, 41.767293],
            ],
          ],
          [
            [
              [3.002072, 41.76486],
              [3.002051, 41.764762],
              [3.00186, 41.764781],
              [3.001879, 41.764852],
              [3.002072, 41.76486],
            ],
          ],
          [
            [
              [3.23173, 41.95712],
              [3.231816, 41.957143],
              [3.231835, 41.957088],
              [3.231641, 41.95708],
              [3.23173, 41.95712],
            ],
          ],
          [
            [
              [3.19045, 41.887809],
              [3.190542, 41.887759],
              [3.19039, 41.887686],
              [3.190362, 41.887769],
              [3.19045, 41.887809],
            ],
          ],
          [
            [
              [3.172033, 42.356459],
              [3.172127, 42.356427],
              [3.17211, 42.356374],
              [3.17194, 42.3565],
              [3.172033, 42.356459],
            ],
          ],
          [
            [
              [3.292471, 42.3002],
              [3.292629, 42.300074],
              [3.292615, 42.300057],
              [3.292367, 42.300125],
              [3.292471, 42.3002],
            ],
          ],
          [
            [
              [3.229755, 41.962944],
              [3.229705, 41.962928],
              [3.229484, 41.963013],
              [3.229749, 41.963007],
              [3.229755, 41.962944],
            ],
          ],
          [
            [
              [3.28164, 42.274161],
              [3.281771, 42.274136],
              [3.281735, 42.274021],
              [3.281652, 42.274034],
              [3.28164, 42.274161],
            ],
          ],
          [
            [
              [3.226329, 41.945542],
              [3.226304, 41.945408],
              [3.226145, 41.945507],
              [3.226227, 41.945484],
              [3.226329, 41.945542],
            ],
          ],
          [
            [
              [3.208019, 41.908191],
              [3.208167, 41.908102],
              [3.207765, 41.908177],
              [3.207779, 41.908195],
              [3.208019, 41.908191],
            ],
          ],
          [
            [
              [3.216248, 41.937854],
              [3.216475, 41.937833],
              [3.216506, 41.937786],
              [3.216262, 41.937754],
              [3.216248, 41.937854],
            ],
          ],
          [
            [
              [2.952892, 41.731494],
              [2.952818, 41.731471],
              [2.952741, 41.731556],
              [2.952844, 41.731622],
              [2.952892, 41.731494],
            ],
          ],
          [
            [
              [3.183203, 42.352929],
              [3.183334, 42.352895],
              [3.183059, 42.352937],
              [3.183132, 42.352942],
              [3.183203, 42.352929],
            ],
          ],
          [
            [
              [3.308897, 42.290482],
              [3.308786, 42.290461],
              [3.30881, 42.290585],
              [3.308905, 42.290562],
              [3.308897, 42.290482],
            ],
          ],
          [
            [
              [3.167216, 42.36636],
              [3.167151, 42.366318],
              [3.167005, 42.366308],
              [3.167089, 42.36643],
              [3.167216, 42.36636],
            ],
          ],
          [
            [
              [3.292023, 42.299594],
              [3.292224, 42.299547],
              [3.292362, 42.299468],
              [3.292099, 42.299509],
              [3.292023, 42.299594],
            ],
          ],
          [
            [
              [3.093173, 41.832565],
              [3.093108, 41.832487],
              [3.093114, 41.832705],
              [3.093161, 41.832654],
              [3.093173, 41.832565],
            ],
          ],
          [
            [
              [3.178528, 41.871289],
              [3.178396, 41.871296],
              [3.178494, 41.871434],
              [3.178605, 41.871338],
              [3.178528, 41.871289],
            ],
          ],
          [
            [
              [3.002781, 41.766785],
              [3.002556, 41.766689],
              [3.002511, 41.766728],
              [3.002736, 41.766815],
              [3.002781, 41.766785],
            ],
          ],
          [
            [
              [3.22494, 41.944771],
              [3.224812, 41.944823],
              [3.224842, 41.944885],
              [3.225008, 41.944849],
              [3.22494, 41.944771],
            ],
          ],
          [
            [
              [3.167792, 42.388914],
              [3.167646, 42.388903],
              [3.167616, 42.388968],
              [3.167752, 42.388997],
              [3.167792, 42.388914],
            ],
          ],
          [
            [
              [3.204239, 41.896199],
              [3.20424, 41.896082],
              [3.204077, 41.896144],
              [3.204106, 41.896188],
              [3.204239, 41.896199],
            ],
          ],
          [
            [
              [3.184999, 42.10077],
              [3.184934, 42.100719],
              [3.184797, 42.100799],
              [3.184922, 42.100846],
              [3.184999, 42.10077],
            ],
          ],
          [
            [
              [2.939922, 41.720538],
              [2.939826, 41.720542],
              [2.939796, 41.720607],
              [2.939921, 41.720655],
              [2.939922, 41.720538],
            ],
          ],
          [
            [
              [2.82663, 41.690189],
              [2.826538, 41.69023],
              [2.826726, 41.69032],
              [2.826707, 41.690239],
              [2.82663, 41.690189],
            ],
          ],
          [
            [
              [3.231553, 41.968751],
              [3.231422, 41.96865],
              [3.231272, 41.968595],
              [3.231594, 41.968802],
              [3.231553, 41.968751],
            ],
          ],
          [
            [
              [3.22217, 41.944184],
              [3.222209, 41.944092],
              [3.222066, 41.944109],
              [3.22205, 41.944191],
              [3.22217, 41.944184],
            ],
          ],
          [
            [
              [3.298128, 42.330186],
              [3.298039, 42.330137],
              [3.29796, 42.330196],
              [3.298126, 42.330285],
              [3.298128, 42.330186],
            ],
          ],
          [
            [
              [3.292393, 42.299898],
              [3.292424, 42.299842],
              [3.292127, 42.299904],
              [3.292167, 42.299947],
              [3.292393, 42.299898],
            ],
          ],
          [
            [
              [2.860559, 41.698765],
              [2.860473, 41.698752],
              [2.8605, 41.698912],
              [2.860568, 41.698864],
              [2.860559, 41.698765],
            ],
          ],
          [
            [
              [3.211176, 42.346056],
              [3.211272, 42.346042],
              [3.211382, 42.345928],
              [3.211104, 42.34606],
              [3.211176, 42.346056],
            ],
          ],
          [
            [
              [3.211647, 42.054463],
              [3.211563, 42.054468],
              [3.211574, 42.054575],
              [3.211657, 42.054562],
              [3.211647, 42.054463],
            ],
          ],
          [
            [
              [3.164482, 42.362988],
              [3.164411, 42.36301],
              [3.164461, 42.363151],
              [3.16451, 42.363148],
              [3.164482, 42.362988],
            ],
          ],
          [
            [
              [3.178399, 41.872196],
              [3.178279, 41.872193],
              [3.17826, 41.872248],
              [3.178409, 41.872294],
              [3.178399, 41.872196],
            ],
          ],
          [
            [
              [3.298019, 42.28839],
              [3.297913, 42.288423],
              [3.297866, 42.288552],
              [3.297938, 42.288548],
              [3.298019, 42.28839],
            ],
          ],
          [
            [
              [3.217892, 42.350407],
              [3.217808, 42.350412],
              [3.217753, 42.350469],
              [3.217914, 42.350505],
              [3.217892, 42.350407],
            ],
          ],
          [
            [
              [3.206037, 41.900071],
              [3.206153, 41.900028],
              [3.206232, 41.89997],
              [3.206098, 41.89995],
              [3.206037, 41.900071],
            ],
          ],
          [
            [
              [3.186998, 42.099941],
              [3.187004, 42.099887],
              [3.18707, 42.09982],
              [3.18694, 42.099845],
              [3.186998, 42.099941],
            ],
          ],
          [
            [
              [3.123629, 42.136739],
              [3.123537, 42.136789],
              [3.123712, 42.136843],
              [3.123743, 42.136787],
              [3.123629, 42.136739],
            ],
          ],
          [
            [
              [3.22583, 41.94475],
              [3.225885, 41.944693],
              [3.225729, 41.944701],
              [3.225788, 41.944815],
              [3.22583, 41.94475],
            ],
          ],
          [
            [
              [3.1885, 42.34978],
              [3.188495, 42.349726],
              [3.18834, 42.349753],
              [3.188372, 42.349832],
              [3.1885, 42.34978],
            ],
          ],
          [
            [
              [3.234768, 41.957729],
              [3.234722, 41.957632],
              [3.234585, 41.957712],
              [3.234613, 41.957746],
              [3.234768, 41.957729],
            ],
          ],
          [
            [
              [3.166791, 42.366608],
              [3.16667, 42.366605],
              [3.166639, 42.366661],
              [3.166843, 42.366641],
              [3.166791, 42.366608],
            ],
          ],
          [
            [
              [3.166629, 42.425145],
              [3.166557, 42.425148],
              [3.166597, 42.425308],
              [3.166644, 42.425288],
              [3.166629, 42.425145],
            ],
          ],
          [
            [
              [3.166332, 42.389992],
              [3.166274, 42.390022],
              [3.1663, 42.390164],
              [3.166355, 42.390107],
              [3.166332, 42.389992],
            ],
          ],
          [
            [
              [3.178326, 42.353562],
              [3.178309, 42.353509],
              [3.178143, 42.353545],
              [3.178332, 42.353616],
              [3.178326, 42.353562],
            ],
          ],
          [
            [
              [3.223115, 41.944115],
              [3.223051, 41.944073],
              [3.222988, 41.944167],
              [3.223063, 41.944189],
              [3.223115, 41.944115],
            ],
          ],
          [
            [
              [2.939973, 41.720697],
              [2.939863, 41.720676],
              [2.939851, 41.720685],
              [2.939969, 41.720787],
              [2.939973, 41.720697],
            ],
          ],
          [
            [
              [3.222237, 42.045192],
              [3.222139, 42.045171],
              [3.22215, 42.045287],
              [3.222197, 42.045276],
              [3.222237, 42.045192],
            ],
          ],
          [
            [
              [3.186624, 41.887485],
              [3.186576, 41.887488],
              [3.186503, 41.8876],
              [3.186588, 41.887604],
              [3.186624, 41.887485],
            ],
          ],
          [
            [
              [3.169435, 42.42304],
              [3.169464, 42.422957],
              [3.169314, 42.423037],
              [3.169376, 42.423052],
              [3.169435, 42.42304],
            ],
          ],
          [
            [
              [3.177739, 41.869225],
              [3.177616, 41.869205],
              [3.177626, 41.869303],
              [3.177698, 41.869299],
              [3.177739, 41.869225],
            ],
          ],
          [
            [
              [3.301097, 42.304486],
              [3.301035, 42.304471],
              [3.300945, 42.304539],
              [3.301056, 42.30456],
              [3.301097, 42.304486],
            ],
          ],
          [
            [
              [2.861383, 41.69911],
              [2.86139, 41.699056],
              [2.861226, 41.6991],
              [2.861326, 41.699149],
              [2.861383, 41.69911],
            ],
          ],
          [
            [
              [2.845076, 41.694669],
              [2.844992, 41.694673],
              [2.845075, 41.694795],
              [2.845133, 41.694765],
              [2.845076, 41.694669],
            ],
          ],
          [
            [
              [2.948317, 41.728545],
              [2.948392, 41.728451],
              [2.948365, 41.728417],
              [2.948264, 41.728494],
              [2.948317, 41.728545],
            ],
          ],
          [
            [
              [3.166423, 42.369778],
              [3.166318, 42.369829],
              [3.166348, 42.369881],
              [3.166443, 42.369858],
              [3.166423, 42.369778],
            ],
          ],
          [
            [
              [3.22384, 42.044538],
              [3.223799, 42.044621],
              [3.223899, 42.044652],
              [3.223907, 42.044606],
              [3.22384, 42.044538],
            ],
          ],
          [
            [
              [3.179248, 41.87188],
              [3.179138, 41.871868],
              [3.17916, 41.871966],
              [3.179265, 41.871933],
              [3.179248, 41.87188],
            ],
          ],
          [
            [
              [3.189963, 41.887755],
              [3.189894, 41.887785],
              [3.18995, 41.887863],
              [3.190028, 41.887805],
              [3.189963, 41.887755],
            ],
          ],
          [
            [
              [3.182434, 41.885641],
              [3.182419, 41.885732],
              [3.182493, 41.885746],
              [3.182498, 41.885674],
              [3.182434, 41.885641],
            ],
          ],
          [
            [
              [3.1367, 42.12548],
              [3.136661, 42.125455],
              [3.136552, 42.125578],
              [3.136728, 42.125523],
              [3.1367, 42.12548],
            ],
          ],
          [
            [
              [3.161877, 42.410287],
              [3.161834, 42.410227],
              [3.161727, 42.410251],
              [3.161744, 42.410295],
              [3.161877, 42.410287],
            ],
          ],
          [
            [
              [3.169644, 42.423307],
              [3.16974, 42.423293],
              [3.169746, 42.42323],
              [3.169648, 42.423226],
              [3.169644, 42.423307],
            ],
          ],
          [
            [
              [3.231575, 41.968848],
              [3.23146, 41.968792],
              [3.231376, 41.968796],
              [3.231542, 41.968886],
              [3.231575, 41.968848],
            ],
          ],
          [
            [
              [3.179456, 41.879385],
              [3.179391, 41.879334],
              [3.179263, 41.879386],
              [3.179412, 41.879432],
              [3.179456, 41.879385],
            ],
          ],
          [
            [
              [3.22907, 42.243582],
              [3.228947, 42.243562],
              [3.228927, 42.243608],
              [3.229086, 42.243626],
              [3.22907, 42.243582],
            ],
          ],
          [
            [
              [3.229502, 41.962705],
              [3.22944, 41.962691],
              [3.229704, 41.962794],
              [3.229626, 41.962735],
              [3.229502, 41.962705],
            ],
          ],
          [
            [
              [3.165348, 42.39036],
              [3.165309, 42.390335],
              [3.165241, 42.390384],
              [3.165356, 42.39044],
              [3.165348, 42.39036],
            ],
          ],
          [
            [
              [3.177959, 41.869015],
              [3.17788, 41.869064],
              [3.177923, 41.869143],
              [3.177968, 41.869105],
              [3.177959, 41.869015],
            ],
          ],
          [
            [
              [3.178567, 41.868703],
              [3.178487, 41.868743],
              [3.178566, 41.868811],
              [3.178623, 41.868781],
              [3.178567, 41.868703],
            ],
          ],
          [
            [
              [2.97628, 41.75593],
              [2.976239, 41.755878],
              [2.976094, 41.755868],
              [2.976183, 41.755926],
              [2.97628, 41.75593],
            ],
          ],
          [
            [
              [3.225524, 42.042745],
              [3.225474, 42.04273],
              [3.225397, 42.042806],
              [3.225447, 42.042821],
              [3.225524, 42.042745],
            ],
          ],
          [
            [
              [2.862151, 41.698837],
              [2.862052, 41.698806],
              [2.862045, 41.69886],
              [2.862121, 41.69891],
              [2.862151, 41.698837],
            ],
          ],
          [
            [
              [3.169539, 42.423718],
              [3.169641, 42.42365],
              [3.169638, 42.423614],
              [3.169488, 42.423694],
              [3.169539, 42.423718],
            ],
          ],
          [
            [
              [3.226554, 41.94562],
              [3.226482, 41.945624],
              [3.226425, 41.945654],
              [3.226514, 41.945703],
              [3.226554, 41.94562],
            ],
          ],
          [
            [
              [3.225454, 42.042893],
              [3.225403, 42.042869],
              [3.225351, 42.042953],
              [3.225459, 42.042947],
              [3.225454, 42.042893],
            ],
          ],
          [
            [
              [3.204508, 41.895743],
              [3.204451, 41.895773],
              [3.204496, 41.89587],
              [3.204527, 41.895814],
              [3.204508, 41.895743],
            ],
          ],
          [
            [
              [3.303891, 42.327645],
              [3.303821, 42.327676],
              [3.303879, 42.327772],
              [3.303914, 42.327761],
              [3.303891, 42.327645],
            ],
          ],
          [
            [
              [3.318383, 42.315422],
              [3.318285, 42.315409],
              [3.318241, 42.315457],
              [3.318435, 42.315455],
              [3.318383, 42.315422],
            ],
          ],
          [
            [
              [3.297119, 42.293814],
              [3.29711, 42.293724],
              [3.297009, 42.293802],
              [3.297022, 42.29381],
              [3.297119, 42.293814],
            ],
          ],
          [
            [
              [3.204766, 41.896413],
              [3.20472, 41.896434],
              [3.20478, 41.896556],
              [3.204805, 41.896438],
              [3.204766, 41.896413],
            ],
          ],
          [
            [
              [3.172421, 41.863796],
              [3.17233, 41.863729],
              [3.172272, 41.86375],
              [3.172364, 41.863826],
              [3.172421, 41.863796],
            ],
          ],
          [
            [
              [3.231744, 41.968362],
              [3.231707, 41.968355],
              [3.231628, 41.968404],
              [3.231728, 41.968444],
              [3.231744, 41.968362],
            ],
          ],
          [
            [
              [3.225538, 41.944721],
              [3.225513, 41.944713],
              [3.225372, 41.944748],
              [3.225519, 41.944776],
              [3.225538, 41.944721],
            ],
          ],
          [
            [
              [3.167574, 42.36914],
              [3.167501, 42.369135],
              [3.167606, 42.369219],
              [3.167627, 42.369182],
              [3.167574, 42.36914],
            ],
          ],
          [
            [
              [3.225453, 42.043253],
              [3.225381, 42.043266],
              [3.225449, 42.043343],
              [3.225484, 42.043323],
              [3.225453, 42.043253],
            ],
          ],
          [
            [
              [3.231321, 41.956395],
              [3.23133, 41.956359],
              [3.231199, 41.956384],
              [3.23129, 41.956451],
              [3.231321, 41.956395],
            ],
          ],
          [
            [
              [3.159478, 42.373357],
              [3.159428, 42.373342],
              [3.15935, 42.373409],
              [3.159471, 42.373412],
              [3.159478, 42.373357],
            ],
          ],
          [
            [
              [3.170762, 42.104752],
              [3.170695, 42.104684],
              [3.170641, 42.10475],
              [3.170728, 42.104781],
              [3.170762, 42.104752],
            ],
          ],
          [
            [
              [3.13631, 42.127076],
              [3.136295, 42.127041],
              [3.136148, 42.127147],
              [3.136208, 42.127144],
              [3.13631, 42.127076],
            ],
          ],
          [
            [
              [3.226898, 41.945439],
              [3.226764, 41.945428],
              [3.226723, 41.945493],
              [3.226759, 41.945491],
              [3.226898, 41.945439],
            ],
          ],
          [
            [
              [3.178744, 41.871277],
              [3.178682, 41.871262],
              [3.178703, 41.871351],
              [3.178795, 41.87131],
              [3.178744, 41.871277],
            ],
          ],
          [
            [
              [3.163634, 42.364492],
              [3.163488, 42.364491],
              [3.163519, 42.364561],
              [3.163634, 42.364492],
            ],
          ],
          [
            [
              [3.224918, 41.945169],
              [3.225002, 41.945164],
              [3.225011, 41.945128],
              [3.224867, 41.945135],
              [3.224918, 41.945169],
            ],
          ],
          [
            [
              [3.179251, 41.878631],
              [3.179258, 41.878585],
              [3.179078, 41.878586],
              [3.17913, 41.878628],
              [3.179251, 41.878631],
            ],
          ],
          [
            [
              [3.166591, 42.369886],
              [3.166518, 42.369881],
              [3.166504, 42.36999],
              [3.166596, 42.369931],
              [3.166591, 42.369886],
            ],
          ],
          [
            [
              [3.165989, 42.361898],
              [3.165881, 42.361913],
              [3.165886, 42.361958],
              [3.165993, 42.361934],
              [3.165989, 42.361898],
            ],
          ],
          [
            [
              [3.217662, 41.93802],
              [3.217648, 41.938003],
              [3.217539, 41.937991],
              [3.217584, 41.938087],
              [3.217662, 41.93802],
            ],
          ],
          [
            [
              [3.182971, 41.883344],
              [3.182936, 41.883355],
              [3.183044, 41.883475],
              [3.183045, 41.883358],
              [3.182971, 41.883344],
            ],
          ],
          [
            [
              [3.22462, 41.944834],
              [3.224559, 41.944819],
              [3.224553, 41.944882],
              [3.224616, 41.944915],
              [3.22462, 41.944834],
            ],
          ],
          [
            [
              [3.22871, 41.969779],
              [3.228674, 41.969772],
              [3.228624, 41.969882],
              [3.228698, 41.969896],
              [3.22871, 41.969779],
            ],
          ],
          [
            [
              [3.219118, 41.942811],
              [3.219187, 41.942771],
              [3.219022, 41.942816],
              [3.219059, 41.942823],
              [3.219118, 41.942811],
            ],
          ],
          [
            [
              [3.168943, 42.419889],
              [3.168881, 42.419874],
              [3.168873, 42.419919],
              [3.168997, 42.41994],
              [3.168943, 42.419889],
            ],
          ],
          [
            [
              [3.230206, 42.244537],
              [3.230122, 42.244542],
              [3.230124, 42.244568],
              [3.230257, 42.244561],
              [3.230206, 42.244537],
            ],
          ],
          [
            [
              [3.184483, 42.099807],
              [3.184439, 42.099855],
              [3.184514, 42.099887],
              [3.184535, 42.09985],
              [3.184483, 42.099807],
            ],
          ],
          [
            [
              [3.231671, 41.956889],
              [3.231612, 41.956902],
              [3.231633, 41.956991],
              [3.231688, 41.956943],
              [3.231671, 41.956889],
            ],
          ],
          [
            [
              [3.18064, 41.876935],
              [3.180591, 41.876929],
              [3.180564, 41.87702],
              [3.180637, 41.877025],
              [3.18064, 41.876935],
            ],
          ],
          [
            [
              [3.231695, 41.969337],
              [3.231634, 41.969331],
              [3.231761, 41.969396],
              [3.231757, 41.969361],
              [3.231695, 41.969337],
            ],
          ],
          [
            [
              [3.226563, 41.971129],
              [3.226521, 41.971068],
              [3.226438, 41.971091],
              [3.226466, 41.971125],
              [3.226563, 41.971129],
            ],
          ],
          [
            [
              [3.231563, 41.968606],
              [3.231558, 41.968552],
              [3.231451, 41.968576],
              [3.231466, 41.968602],
              [3.231563, 41.968606],
            ],
          ],
          [
            [
              [3.221158, 41.94333],
              [3.22114, 41.943268],
              [3.221034, 41.943292],
              [3.221048, 41.943318],
              [3.221158, 41.94333],
            ],
          ],
          [
            [
              [3.204658, 41.897157],
              [3.204665, 41.897112],
              [3.204568, 41.897108],
              [3.204561, 41.897153],
              [3.204658, 41.897157],
            ],
          ],
          [
            [
              [3.230833, 41.968169],
              [3.230789, 41.96809],
              [3.23073, 41.968102],
              [3.230745, 41.968138],
              [3.230833, 41.968169],
            ],
          ],
          [
            [
              [3.156093, 42.378203],
              [3.156031, 42.378197],
              [3.155987, 42.378235],
              [3.156122, 42.378255],
              [3.156093, 42.378203],
            ],
          ],
          [
            [
              [3.184554, 42.100047],
              [3.184485, 42.100077],
              [3.184538, 42.100128],
              [3.184571, 42.1001],
              [3.184554, 42.100047],
            ],
          ],
          [
            [
              [3.231316, 41.969538],
              [3.231243, 41.969524],
              [3.231207, 41.969535],
              [3.231262, 41.969604],
              [3.231316, 41.969538],
            ],
          ],
          [
            [
              [3.230755, 41.956282],
              [3.230695, 41.956286],
              [3.230787, 41.956362],
              [3.230809, 41.956333],
              [3.230755, 41.956282],
            ],
          ],
          [
            [
              [3.23137, 41.969346],
              [3.231331, 41.969321],
              [3.231284, 41.969332],
              [3.231387, 41.969399],
              [3.23137, 41.969346],
            ],
          ],
          [
            [
              [3.226637, 41.945606],
              [3.226588, 41.9456],
              [3.226582, 41.945663],
              [3.226689, 41.945648],
              [3.226637, 41.945606],
            ],
          ],
          [
            [
              [3.19081, 41.88779],
              [3.190706, 41.88784],
              [3.190721, 41.887867],
              [3.190802, 41.887835],
              [3.19081, 41.88779],
            ],
          ],
          [
            [
              [3.311675, 42.325433],
              [3.3116, 42.325419],
              [3.31174, 42.325483],
              [3.311713, 42.325458],
              [3.311675, 42.325433],
            ],
          ],
          [
            [
              [3.224738, 41.945169],
              [3.224666, 41.945173],
              [3.224671, 41.945227],
              [3.224731, 41.945224],
              [3.224738, 41.945169],
            ],
          ],
          [
            [
              [3.165485, 42.3627],
              [3.165399, 42.362686],
              [3.165365, 42.362715],
              [3.16544, 42.362738],
              [3.165485, 42.3627],
            ],
          ],
          [
            [
              [3.324028, 42.322589],
              [3.324077, 42.322596],
              [3.324122, 42.322557],
              [3.323977, 42.322565],
              [3.324028, 42.322589],
            ],
          ],
          [
            [
              [3.320744, 42.323824],
              [3.320695, 42.323818],
              [3.320663, 42.323865],
              [3.320783, 42.323858],
              [3.320744, 42.323824],
            ],
          ],
          [
            [
              [3.21749, 41.937985],
              [3.217439, 41.93796],
              [3.217433, 41.938015],
              [3.217496, 41.938047],
              [3.21749, 41.937985],
            ],
          ],
          [
            [
              [3.212903, 42.058653],
              [3.212818, 42.058648],
              [3.212748, 42.05867],
              [3.212835, 42.058701],
              [3.212903, 42.058653],
            ],
          ],
          [
            [
              [3.231588, 41.95666],
              [3.231539, 41.956654],
              [3.231596, 41.956741],
              [3.231616, 41.956694],
              [3.231588, 41.95666],
            ],
          ],
          [
            [
              [3.208527, 41.91279],
              [3.208501, 41.912773],
              [3.208412, 41.912841],
              [3.208461, 41.912857],
              [3.208527, 41.91279],
            ],
          ],
          [
            [
              [3.159155, 42.118151],
              [3.159178, 42.118141],
              [3.159239, 42.118146],
              [3.159149, 42.118088],
              [3.159155, 42.118151],
            ],
          ],
          [
            [
              [3.224994, 41.945074],
              [3.225, 41.94502],
              [3.224906, 41.945043],
              [3.224921, 41.945069],
              [3.224994, 41.945074],
            ],
          ],
          [
            [
              [3.211499, 42.054552],
              [3.211453, 42.054573],
              [3.211494, 42.054625],
              [3.211551, 42.054586],
              [3.211499, 42.054552],
            ],
          ],
          [
            [
              [3.207348, 41.908101],
              [3.207299, 41.908095],
              [3.20728, 41.90815],
              [3.207387, 41.908135],
              [3.207348, 41.908101],
            ],
          ],
          [
            [
              [3.260998, 42.340437],
              [3.2609, 42.340433],
              [3.260857, 42.34049],
              [3.260952, 42.340467],
              [3.260998, 42.340437],
            ],
          ],
          [
            [
              [3.321655, 42.318409],
              [3.321581, 42.318395],
              [3.321562, 42.31845],
              [3.321658, 42.318436],
              [3.321655, 42.318409],
            ],
          ],
          [
            [
              [3.164852, 42.365524],
              [3.164825, 42.365499],
              [3.164754, 42.365512],
              [3.164757, 42.365547],
              [3.164852, 42.365524],
            ],
          ],
          [
            [
              [3.230726, 41.968193],
              [3.230713, 41.968184],
              [3.230604, 41.968172],
              [3.230682, 41.968231],
              [3.230726, 41.968193],
            ],
          ],
          [
            [
              [3.197706, 42.242848],
              [3.197657, 42.242842],
              [3.197638, 42.242888],
              [3.1977, 42.242902],
              [3.197706, 42.242848],
            ],
          ],
          [
            [
              [3.183048, 41.882899],
              [3.183082, 41.882879],
              [3.182904, 41.882907],
              [3.182979, 41.88293],
              [3.183048, 41.882899],
            ],
          ],
          [
            [
              [3.296726, 42.330471],
              [3.296654, 42.330484],
              [3.296684, 42.330536],
              [3.296731, 42.330524],
              [3.296726, 42.330471],
            ],
          ],
          [
            [
              [3.21123, 42.05526],
              [3.211226, 42.055215],
              [3.21114, 42.055202],
              [3.211169, 42.055255],
              [3.21123, 42.05526],
            ],
          ],
          [
            [
              [3.226416, 41.970984],
              [3.226354, 41.970969],
              [3.226335, 41.971015],
              [3.22636, 41.971032],
              [3.226416, 41.970984],
            ],
          ],
          [
            [
              [3.232011, 41.956916],
              [3.231997, 41.956899],
              [3.231941, 41.956938],
              [3.23204, 41.956968],
              [3.232011, 41.956916],
            ],
          ],
          [
            [
              [3.208779, 41.906709],
              [3.208835, 41.906661],
              [3.208696, 41.906723],
              [3.208779, 41.906709],
            ],
          ],
          [
            [
              [3.183354, 41.881451],
              [3.183312, 41.88139],
              [3.183254, 41.881421],
              [3.183295, 41.881463],
              [3.183354, 41.881451],
            ],
          ],
          [
            [
              [3.260925, 42.340558],
              [3.260924, 42.340549],
              [3.260803, 42.340556],
              [3.260832, 42.340599],
              [3.260925, 42.340558],
            ],
          ],
          [
            [
              [3.231642, 41.969412],
              [3.231594, 41.969414],
              [3.231585, 41.969451],
              [3.23168, 41.969437],
              [3.231642, 41.969412],
            ],
          ],
          [
            [
              [3.231801, 41.957233],
              [3.231741, 41.957237],
              [3.231747, 41.957299],
              [3.231804, 41.95726],
              [3.231801, 41.957233],
            ],
          ],
          [
            [
              [3.178821, 41.871822],
              [3.178779, 41.871761],
              [3.178721, 41.871791],
              [3.178774, 41.871833],
              [3.178821, 41.871822],
            ],
          ],
          [
            [
              [3.183606, 41.887288],
              [3.183568, 41.887272],
              [3.183614, 41.887369],
              [3.183633, 41.887323],
              [3.183606, 41.887288],
            ],
          ],
          [
            [
              [3.231216, 41.969372],
              [3.231166, 41.969357],
              [3.231247, 41.969442],
              [3.231216, 41.969372],
            ],
          ],
          [
            [
              [3.178621, 41.8715],
              [3.17857, 41.871466],
              [3.178525, 41.871505],
              [3.178576, 41.871538],
              [3.178621, 41.8715],
            ],
          ],
          [
            [
              [3.178476, 41.868627],
              [3.178447, 41.868584],
              [3.178389, 41.868605],
              [3.178393, 41.868649],
              [3.178476, 41.868627],
            ],
          ],
          [
            [
              [3.287693, 42.282343],
              [3.287644, 42.282337],
              [3.287663, 42.282408],
              [3.287696, 42.282379],
              [3.287693, 42.282343],
            ],
          ],
          [
            [
              [3.211324, 42.055228],
              [3.211298, 42.055212],
              [3.211268, 42.055276],
              [3.21134, 42.055272],
              [3.211324, 42.055228],
            ],
          ],
          [
            [
              [3.222499, 41.94422],
              [3.222438, 41.944214],
              [3.222427, 41.944224],
              [3.222492, 41.944275],
              [3.222499, 41.94422],
            ],
          ],
          [
            [
              [3.210437, 42.053197],
              [3.210509, 42.053193],
              [3.210518, 42.053166],
              [3.210443, 42.053143],
              [3.210437, 42.053197],
            ],
          ],
          [
            [
              [3.22685, 41.946801],
              [3.226896, 41.946771],
              [3.226809, 41.946749],
              [3.226826, 41.946802],
              [3.22685, 41.946801],
            ],
          ],
          [
            [
              [3.232118, 41.956901],
              [3.232057, 41.956895],
              [3.232049, 41.956932],
              [3.232111, 41.956947],
              [3.232118, 41.956901],
            ],
          ],
          [
            [
              [3.218282, 41.942397],
              [3.218175, 41.942412],
              [3.218178, 41.942439],
              [3.218261, 41.942425],
              [3.218282, 41.942397],
            ],
          ],
          [
            [
              [3.179476, 41.872002],
              [3.179397, 41.871926],
              [3.179352, 41.871964],
              [3.179476, 41.872002],
            ],
          ],
          [
            [
              [3.279315, 42.337127],
              [3.279276, 42.337102],
              [3.279208, 42.337151],
              [3.279317, 42.337145],
              [3.279315, 42.337127],
            ],
          ],
          [
            [
              [3.211567, 42.054378],
              [3.211494, 42.054373],
              [3.211511, 42.054426],
              [3.211536, 42.054433],
              [3.211567, 42.054378],
            ],
          ],
          [
            [
              [3.219128, 42.048467],
              [3.219137, 42.04843],
              [3.219062, 42.048408],
              [3.219066, 42.048452],
              [3.219128, 42.048467],
            ],
          ],
          [
            [
              [3.23192, 41.956975],
              [3.231919, 41.956957],
              [3.231856, 41.95705],
              [3.231914, 41.957029],
              [3.23192, 41.956975],
            ],
          ],
          [
            [
              [3.183736, 41.887632],
              [3.183687, 41.887626],
              [3.183665, 41.887654],
              [3.183705, 41.887688],
              [3.183736, 41.887632],
            ],
          ],
          [
            [
              [3.17225, 41.863517],
              [3.172186, 41.863485],
              [3.172126, 41.863488],
              [3.172167, 41.863531],
              [3.17225, 41.863517],
            ],
          ],
          [
            [
              [3.210694, 42.053237],
              [3.210657, 42.05323],
              [3.210623, 42.05325],
              [3.210698, 42.053282],
              [3.210694, 42.053237],
            ],
          ],
          [
            [
              [3.228623, 41.969999],
              [3.228538, 41.969986],
              [3.22853, 41.970031],
              [3.228625, 41.970017],
              [3.228623, 41.969999],
            ],
          ],
          [
            [
              [3.261881, 42.339687],
              [3.261864, 42.339633],
              [3.261821, 42.33969],
              [3.261835, 42.339707],
              [3.261881, 42.339687],
            ],
          ],
          [
            [
              [3.311421, 42.325204],
              [3.311395, 42.325187],
              [3.311351, 42.325235],
              [3.311425, 42.32524],
              [3.311421, 42.325204],
            ],
          ],
          [
            [
              [3.217895, 41.939052],
              [3.217856, 41.939018],
              [3.217807, 41.939011],
              [3.217849, 41.939072],
              [3.217895, 41.939052],
            ],
          ],
          [
            [
              [3.182322, 41.884973],
              [3.182249, 41.884967],
              [3.18223, 41.885022],
              [3.1823, 41.885001],
              [3.182322, 41.884973],
            ],
          ],
          [
            [
              [3.250816, 42.331876],
              [3.250796, 42.331922],
              [3.250846, 42.331929],
              [3.250856, 42.33191],
              [3.250816, 42.331876],
            ],
          ],
          [
            [
              [3.183133, 41.88203],
              [3.183059, 41.882016],
              [3.183024, 41.882027],
              [3.183099, 41.882059],
              [3.183133, 41.88203],
            ],
          ],
          [
            [
              [3.178996, 41.878491],
              [3.178968, 41.878448],
              [3.178891, 41.878524],
              [3.178996, 41.878491],
            ],
          ],
          [
            [
              [3.211143, 42.05351],
              [3.211119, 42.053502],
              [3.211083, 42.053513],
              [3.211124, 42.053556],
              [3.211143, 42.05351],
            ],
          ],
          [
            [
              [3.231327, 41.956701],
              [3.231301, 41.956685],
              [3.231282, 41.956731],
              [3.231342, 41.956736],
              [3.231327, 41.956701],
            ],
          ],
          [
            [
              [3.231738, 41.956715],
              [3.231701, 41.956708],
              [3.231665, 41.95671],
              [3.231719, 41.956761],
              [3.231738, 41.956715],
            ],
          ],
          [
            [
              [3.219417, 41.94266],
              [3.21938, 41.942653],
              [3.219374, 41.942716],
              [3.219419, 41.942686],
              [3.219417, 41.94266],
            ],
          ],
          [
            [
              [3.293672, 42.30639],
              [3.293647, 42.306373],
              [3.293603, 42.30642],
              [3.293641, 42.306436],
              [3.293672, 42.30639],
            ],
          ],
          [
            [
              [3.166495, 42.370143],
              [3.166479, 42.370099],
              [3.166423, 42.370147],
              [3.166448, 42.370164],
              [3.166495, 42.370143],
            ],
          ],
          [
            [
              [3.226853, 41.970645],
              [3.226817, 41.970647],
              [3.226762, 41.970695],
              [3.226833, 41.970682],
              [3.226853, 41.970645],
            ],
          ],
          [
            [
              [3.209972, 42.053141],
              [3.209968, 42.053097],
              [3.209908, 42.0531],
              [3.209911, 42.053136],
              [3.209972, 42.053141],
            ],
          ],
          [
            [
              [3.227031, 41.970734],
              [3.227006, 41.970727],
              [3.227027, 41.970815],
              [3.227062, 41.970805],
              [3.227031, 41.970734],
            ],
          ],
          [
            [
              [3.227022, 41.947079],
              [3.227044, 41.947051],
              [3.227041, 41.947024],
              [3.226983, 41.947045],
              [3.227022, 41.947079],
            ],
          ],
          [
            [
              [3.231369, 41.969463],
              [3.231331, 41.969438],
              [3.231299, 41.969485],
              [3.23136, 41.96949],
              [3.231369, 41.969463],
            ],
          ],
          [
            [
              [3.178762, 41.871465],
              [3.178736, 41.871439],
              [3.178704, 41.871486],
              [3.178754, 41.87151],
              [3.178762, 41.871465],
            ],
          ],
          [
            [
              [3.31302, 42.324576],
              [3.313014, 42.324522],
              [3.312967, 42.324533],
              [3.31297, 42.32456],
              [3.31302, 42.324576],
            ],
          ],
          [
            [
              [3.221655, 42.045413],
              [3.221608, 42.045425],
              [3.221624, 42.045469],
              [3.221647, 42.045458],
              [3.221655, 42.045413],
            ],
          ],
          [
            [
              [3.23118, 41.969626],
              [3.231163, 41.969573],
              [3.231128, 41.969584],
              [3.231131, 41.96962],
              [3.23118, 41.969626],
            ],
          ],
          [
            [
              [3.260866, 42.340336],
              [3.260817, 42.34033],
              [3.260795, 42.340349],
              [3.260857, 42.340364],
              [3.260866, 42.340336],
            ],
          ],
          [
            [
              [3.232592, 41.958541],
              [3.232577, 41.958505],
              [3.232513, 41.958473],
              [3.232543, 41.958534],
              [3.232592, 41.958541],
            ],
          ],
          [
            [
              [3.183035, 41.883629],
              [3.183009, 41.883612],
              [3.182974, 41.883623],
              [3.183052, 41.883682],
              [3.183035, 41.883629],
            ],
          ],
          [
            [
              [3.2166, 41.918346],
              [3.216563, 41.918339],
              [3.216543, 41.918377],
              [3.216592, 41.918383],
              [3.2166, 41.918346],
            ],
          ],
          [
            [
              [3.181325, 41.880895],
              [3.181261, 41.880862],
              [3.181226, 41.880873],
              [3.181277, 41.880906],
              [3.181325, 41.880895],
            ],
          ],
          [
            [
              [3.293026, 42.30187],
              [3.292979, 42.301882],
              [3.29297, 42.30191],
              [3.292995, 42.301917],
              [3.293026, 42.30187],
            ],
          ],
          [
            [
              [3.210872, 42.053326],
              [3.210808, 42.053294],
              [3.210799, 42.053321],
              [3.210861, 42.053336],
              [3.210872, 42.053326],
            ],
          ],
          [
            [
              [3.219543, 42.046905],
              [3.219518, 42.046898],
              [3.219483, 42.046908],
              [3.219522, 42.046942],
              [3.219543, 42.046905],
            ],
          ],
          [
            [
              [3.182889, 41.883367],
              [3.182875, 41.883349],
              [3.182816, 41.883362],
              [3.182843, 41.883387],
              [3.182889, 41.883367],
            ],
          ],
          [
            [
              [3.212982, 42.05872],
              [3.213031, 42.058727],
              [3.213042, 42.058717],
              [3.212978, 42.058685],
              [3.212982, 42.05872],
            ],
          ],
          [
            [
              [3.215067, 41.936631],
              [3.21504, 41.936596],
              [3.214994, 41.936617],
              [3.215031, 41.936633],
              [3.215067, 41.936631],
            ],
          ],
          [
            [
              [3.183108, 41.882401],
              [3.183095, 41.882383],
              [3.183002, 41.882424],
              [3.183074, 41.88242],
              [3.183108, 41.882401],
            ],
          ],
          [
            [
              [3.093131, 41.832153],
              [3.093077, 41.832143],
              [3.093067, 41.832149],
              [3.093106, 41.832184],
              [3.093131, 41.832153],
            ],
          ],
          [
            [
              [3.211321, 42.054958],
              [3.211285, 42.05496],
              [3.211301, 42.054995],
              [3.211337, 42.054993],
              [3.211321, 42.054958],
            ],
          ],
          [
            [
              [3.292955, 42.301154],
              [3.292918, 42.301147],
              [3.293006, 42.301179],
              [3.292993, 42.30117],
              [3.292955, 42.301154],
            ],
          ],
          [
            [
              [3.180702, 41.880559],
              [3.1807, 41.880542],
              [3.180604, 41.880547],
              [3.180667, 41.88057],
              [3.180702, 41.880559],
            ],
          ],
          [
            [
              [3.225083, 41.945124],
              [3.225045, 41.945108],
              [3.225037, 41.945153],
              [3.225085, 41.945142],
              [3.225083, 41.945124],
            ],
          ],
          [
            [
              [3.186463, 41.862317],
              [3.186423, 41.862275],
              [3.186404, 41.86233],
              [3.186463, 41.862317],
            ],
          ],
          [
            [
              [3.220469, 42.046378],
              [3.220467, 42.046351],
              [3.220409, 42.046381],
              [3.220435, 42.046398],
              [3.220469, 42.046378],
            ],
          ],
          [
            [
              [3.183214, 41.881999],
              [3.183152, 41.881984],
              [3.183265, 41.882023],
              [3.183214, 41.881999],
            ],
          ],
          [
            [
              [3.178678, 41.871713],
              [3.17866, 41.871659],
              [3.178615, 41.871689],
              [3.178678, 41.871713],
            ],
          ],
          [
            [
              [3.186228, 41.862375],
              [3.186177, 41.862351],
              [3.186144, 41.86238],
              [3.186228, 41.862375],
            ],
          ],
          [
            [
              [3.178387, 41.871197],
              [3.178338, 41.871191],
              [3.178354, 41.871235],
              [3.178387, 41.871197],
            ],
          ],
          [
            [
              [3.18183, 41.881138],
              [3.18184, 41.881119],
              [3.181768, 41.881123],
              [3.18183, 41.881138],
            ],
          ],
          [
            [
              [3.182984, 41.883478],
              [3.182935, 41.883472],
              [3.182999, 41.883505],
              [3.182984, 41.883478],
            ],
          ],
          [
            [
              [3.228976, 41.969782],
              [3.228974, 41.969764],
              [3.228919, 41.969812],
              [3.228976, 41.969782],
            ],
          ],
          [
            [
              [3.208951, 41.906745],
              [3.20889, 41.906739],
              [3.208928, 41.906755],
              [3.208951, 41.906745],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5627",
      properties: { name: "Granada", density: 0, path: "/world/Spain/Granada" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.549753, 38.082888],
              [-2.423412, 38.033702],
              [-2.341632, 38.026001],
              [-2.298474, 38.002119],
              [-2.247161, 37.934432],
              [-2.207673, 37.91657],
              [-2.29495, 37.876316],
              [-2.289387, 37.83148],
              [-2.301775, 37.808284],
              [-2.276785, 37.776219],
              [-2.323339, 37.693874],
              [-2.310419, 37.630011],
              [-2.359565, 37.621266],
              [-2.39127, 37.632485],
              [-2.367896, 37.591576],
              [-2.374298, 37.55735],
              [-2.355729, 37.546993],
              [-2.358511, 37.522293],
              [-2.456323, 37.509426],
              [-2.457522, 37.490827],
              [-2.514154, 37.479528],
              [-2.641641, 37.387792],
              [-2.637292, 37.343331],
              [-2.654601, 37.340607],
              [-2.665651, 37.285329],
              [-2.660115, 37.211994],
              [-2.733023, 37.237321],
              [-2.759033, 37.226714],
              [-2.779004, 37.262656],
              [-2.845385, 37.279221],
              [-2.874963, 37.231455],
              [-2.880986, 37.187103],
              [-2.929146, 37.156566],
              [-2.948964, 37.096552],
              [-3.000256, 37.094977],
              [-3.029577, 37.113254],
              [-3.005711, 37.023341],
              [-2.976215, 36.978122],
              [-2.990498, 36.958185],
              [-3.029337, 36.947527],
              [-3.031316, 36.924586],
              [-3.071934, 36.919287],
              [-3.029007, 36.844441],
              [-3.140187, 36.787791],
              [-3.128683, 36.750871],
              [-3.250004, 36.754345],
              [-3.337329, 36.738722],
              [-3.419913, 36.69389],
              [-3.482666, 36.697275],
              [-3.52587, 36.724126],
              [-3.5537, 36.714948],
              [-3.60227, 36.744677],
              [-3.657722, 36.746555],
              [-3.734344, 36.719637],
              [-3.748808, 36.737604],
              [-3.77744, 36.737917],
              [-3.768144, 36.801955],
              [-3.826696, 36.855325],
              [-3.86761, 36.850679],
              [-3.929525, 36.88288],
              [-4.045693, 36.902864],
              [-4.099893, 36.952709],
              [-4.16664, 36.957075],
              [-4.231335, 37.016941],
              [-4.27251, 37.014902],
              [-4.287067, 37.11952],
              [-4.327617, 37.184331],
              [-4.25531, 37.229565],
              [-4.263253, 37.260378],
              [-4.236509, 37.297219],
              [-4.236419, 37.335851],
              [-4.204036, 37.352913],
              [-4.15784, 37.342252],
              [-4.085943, 37.403746],
              [-4.000821, 37.402091],
              [-3.950888, 37.378347],
              [-3.842246, 37.385751],
              [-3.785575, 37.490898],
              [-3.776241, 37.476484],
              [-3.627068, 37.52854],
              [-3.536435, 37.580815],
              [-3.536157, 37.597338],
              [-3.506544, 37.617226],
              [-3.399303, 37.56405],
              [-3.296231, 37.588257],
              [-3.262485, 37.639208],
              [-3.215743, 37.644187],
              [-3.172053, 37.64287],
              [-3.088196, 37.59554],
              [-3.050965, 37.618357],
              [-2.978177, 37.602752],
              [-2.977919, 37.633984],
              [-2.884036, 37.681318],
              [-2.901525, 37.689293],
              [-2.903782, 37.751169],
              [-2.880292, 37.759944],
              [-2.873154, 37.791491],
              [-2.784609, 37.917641],
              [-2.702673, 37.971522],
              [-2.669825, 37.961429],
              [-2.605702, 37.997716],
              [-2.595855, 38.053186],
              [-2.606628, 38.061442],
              [-2.549753, 38.082888],
            ],
          ],
          [
            [
              [-3.389571, 36.708137],
              [-3.389852, 36.708398],
              [-3.389674, 36.708678],
              [-3.389449, 36.708363],
              [-3.389571, 36.708137],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5628",
      properties: {
        name: "Guadalajara",
        density: 0,
        path: "/world/Spain/Guadalajara",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.901019, 41.325565],
              [-2.868988, 41.317056],
              [-2.866888, 41.274465],
              [-2.822245, 41.256615],
              [-2.775511, 41.250084],
              [-2.760946, 41.274024],
              [-2.716772, 41.275671],
              [-2.709546, 41.251981],
              [-2.646015, 41.24021],
              [-2.629198, 41.215215],
              [-2.589486, 41.224538],
              [-2.593741, 41.200205],
              [-2.622066, 41.187988],
              [-2.577493, 41.178663],
              [-2.595314, 41.150253],
              [-2.575577, 41.139472],
              [-2.53651, 41.162525],
              [-2.508851, 41.118857],
              [-2.482261, 41.116272],
              [-2.469466, 41.07811],
              [-2.41891, 41.057706],
              [-2.355329, 41.086454],
              [-2.323407, 41.056508],
              [-2.263544, 41.067535],
              [-2.232147, 41.097011],
              [-2.175298, 41.083755],
              [-2.131855, 41.118098],
              [-2.056434, 41.069691],
              [-2.048179, 41.078378],
              [-2.065622, 41.095789],
              [-2.047617, 41.152652],
              [-1.959017, 41.172486],
              [-1.932447, 41.13859],
              [-1.803291, 41.089895],
              [-1.617449, 40.943733],
              [-1.607904, 40.92762],
              [-1.621392, 40.8804],
              [-1.54468, 40.816194],
              [-1.539908, 40.760733],
              [-1.562017, 40.739752],
              [-1.535638, 40.687019],
              [-1.564856, 40.614742],
              [-1.545515, 40.595195],
              [-1.598749, 40.562054],
              [-1.678101, 40.590952],
              [-1.701624, 40.541096],
              [-1.692512, 40.486824],
              [-1.7486, 40.462897],
              [-1.806359, 40.39823],
              [-1.836725, 40.427657],
              [-1.853285, 40.423679],
              [-1.91649, 40.582825],
              [-1.961785, 40.611546],
              [-1.965905, 40.59198],
              [-1.985527, 40.585434],
              [-2.110118, 40.651386],
              [-2.156937, 40.658692],
              [-2.169249, 40.630233],
              [-2.213815, 40.619176],
              [-2.274601, 40.644754],
              [-2.294784, 40.622907],
              [-2.292773, 40.578094],
              [-2.333514, 40.567192],
              [-2.399039, 40.613713],
              [-2.417282, 40.583447],
              [-2.383453, 40.540867],
              [-2.411821, 40.525628],
              [-2.426745, 40.540748],
              [-2.49597, 40.50881],
              [-2.527235, 40.544275],
              [-2.538714, 40.534025],
              [-2.546266, 40.518956],
              [-2.528726, 40.498381],
              [-2.544453, 40.474633],
              [-2.504534, 40.448649],
              [-2.515465, 40.424281],
              [-2.586236, 40.450259],
              [-2.663845, 40.436051],
              [-2.673158, 40.448609],
              [-2.70345, 40.425375],
              [-2.685129, 40.398002],
              [-2.717431, 40.419825],
              [-2.7229, 40.397117],
              [-2.760023, 40.412335],
              [-2.76007, 40.441993],
              [-2.780946, 40.44417],
              [-2.816966, 40.364024],
              [-2.802631, 40.264026],
              [-2.823757, 40.203168],
              [-2.863125, 40.200112],
              [-2.873738, 40.173574],
              [-2.911484, 40.157083],
              [-3.005071, 40.209118],
              [-3.021071, 40.187493],
              [-3.015966, 40.152708],
              [-3.063767, 40.168251],
              [-3.067845, 40.157914],
              [-3.08823, 40.200318],
              [-3.079704, 40.227773],
              [-3.109443, 40.285193],
              [-3.153718, 40.263714],
              [-3.173397, 40.23417],
              [-3.194449, 40.248037],
              [-3.182857, 40.308267],
              [-3.141212, 40.350542],
              [-3.15343, 40.367703],
              [-3.130558, 40.405185],
              [-3.155515, 40.439425],
              [-3.198597, 40.442252],
              [-3.199955, 40.514449],
              [-3.249915, 40.544316],
              [-3.297126, 40.537521],
              [-3.284069, 40.568977],
              [-3.328275, 40.594836],
              [-3.321644, 40.612192],
              [-3.338516, 40.625849],
              [-3.323675, 40.629686],
              [-3.313857, 40.649743],
              [-3.316497, 40.655177],
              [-3.345598, 40.637743],
              [-3.393354, 40.692458],
              [-3.404735, 40.669637],
              [-3.468553, 40.689937],
              [-3.434945, 40.727986],
              [-3.450416, 40.734887],
              [-3.444343, 40.76158],
              [-3.45901, 40.781324],
              [-3.511572, 40.788496],
              [-3.477387, 40.823561],
              [-3.47257, 40.864217],
              [-3.440487, 40.87535],
              [-3.460577, 40.906659],
              [-3.444062, 40.906849],
              [-3.418296, 40.987564],
              [-3.39445, 41.000278],
              [-3.438935, 41.04267],
              [-3.425805, 41.082899],
              [-3.488751, 41.094294],
              [-3.509262, 41.136395],
              [-3.541098, 41.149667],
              [-3.539722, 41.165041],
              [-3.466915, 41.183264],
              [-3.444154, 41.21534],
              [-3.402486, 41.213619],
              [-3.3995, 41.254543],
              [-3.411523, 41.260707],
              [-3.414585, 41.265579],
              [-3.415641, 41.272114],
              [-3.415147, 41.273197],
              [-3.409779, 41.260623],
              [-3.33635, 41.253031],
              [-3.290168, 41.25793],
              [-3.275759, 41.275034],
              [-3.274265, 41.260265],
              [-3.254264, 41.258383],
              [-3.231245, 41.294867],
              [-3.199755, 41.304429],
              [-3.061901, 41.273427],
              [-2.950131, 41.292637],
              [-2.941191, 41.313936],
              [-2.901019, 41.325565],
            ],
          ],
          [
            [
              [-3.374049, 40.640249],
              [-3.363606, 40.638421],
              [-3.371729, 40.625769],
              [-3.382135, 40.630471],
              [-3.374049, 40.640249],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5630",
      properties: { name: "Huelva", density: 0, path: "/world/Spain/Huelva" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.926904, 38.204441],
              [-6.848429, 38.177236],
              [-6.795092, 38.178541],
              [-6.815815, 38.120138],
              [-6.770055, 38.112113],
              [-6.758217, 38.092711],
              [-6.622104, 38.097008],
              [-6.580526, 38.052772],
              [-6.583581, 38.021285],
              [-6.545693, 38.028119],
              [-6.47656, 38.007834],
              [-6.444316, 38.059603],
              [-6.367246, 38.048919],
              [-6.347044, 38.00282],
              [-6.239239, 37.95893],
              [-6.201517, 37.962062],
              [-6.169969, 37.909815],
              [-6.145213, 37.920498],
              [-6.125032, 37.904792],
              [-6.164294, 37.86201],
              [-6.14216, 37.855674],
              [-6.12166, 37.816595],
              [-6.134016, 37.787832],
              [-6.202397, 37.805534],
              [-6.222234, 37.739655],
              [-6.252424, 37.74278],
              [-6.252318, 37.763145],
              [-6.311022, 37.764869],
              [-6.449994, 37.718898],
              [-6.475123, 37.725141],
              [-6.501342, 37.667959],
              [-6.536089, 37.650976],
              [-6.528622, 37.615795],
              [-6.443271, 37.623354],
              [-6.395257, 37.611187],
              [-6.392401, 37.554051],
              [-6.366478, 37.535757],
              [-6.356184, 37.496173],
              [-6.306054, 37.433015],
              [-6.312632, 37.418722],
              [-6.356259, 37.412166],
              [-6.36208, 37.375881],
              [-6.346038, 37.365732],
              [-6.384571, 37.337489],
              [-6.333, 37.299104],
              [-6.359846, 37.238096],
              [-6.345245, 37.218283],
              [-6.350561, 37.180562],
              [-6.336117, 37.169617],
              [-6.359674, 37.138426],
              [-6.34236, 37.122977],
              [-6.38639, 37.07629],
              [-6.353943, 36.988492],
              [-6.378893, 36.934706],
              [-6.331009, 36.898803],
              [-6.352903, 36.85738],
              [-6.345502, 36.798722],
              [-6.397195, 36.807276],
              [-6.449265, 36.907787],
              [-6.521884, 36.977163],
              [-6.91668, 37.176301],
              [-6.915853, 37.163347],
              [-6.849366, 37.127402],
              [-6.839426, 37.119024],
              [-6.83214, 37.107998],
              [-6.924125, 37.164848],
              [-7.059652, 37.210794],
              [-7.270891, 37.201779],
              [-7.376354, 37.171235],
              [-7.407473, 37.183409],
              [-7.43378, 37.249689],
              [-7.421941, 37.274173],
              [-7.442143, 37.310847],
              [-7.44023, 37.390297],
              [-7.462801, 37.409792],
              [-7.445567, 37.41926],
              [-7.463774, 37.486906],
              [-7.511494, 37.524438],
              [-7.522846, 37.5534],
              [-7.506748, 37.557511],
              [-7.503855, 37.604463],
              [-7.489044, 37.603642],
              [-7.444146, 37.664732],
              [-7.452504, 37.678124],
              [-7.421107, 37.759122],
              [-7.324127, 37.815766],
              [-7.283459, 37.869079],
              [-7.248505, 37.954481],
              [-7.263192, 37.979852],
              [-7.202218, 38.003477],
              [-7.132847, 37.999948],
              [-7.120701, 38.005212],
              [-7.12813, 38.029865],
              [-7.09878, 38.043845],
              [-7.059303, 38.017437],
              [-7.002398, 38.02266],
              [-6.987363, 38.103896],
              [-6.952111, 38.152783],
              [-6.968876, 38.166188],
              [-6.926904, 38.204441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5631",
      properties: { name: "Huesca", density: 0, path: "/world/Spain/Huesca" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.747266, 42.922374],
              [-0.724485, 42.92023],
              [-0.736349, 42.913019],
              [-0.717581, 42.885839],
              [-0.678752, 42.884536],
              [-0.602023, 42.83215],
              [-0.60047, 42.802429],
              [-0.569138, 42.806856],
              [-0.567586, 42.780628],
              [-0.529647, 42.791558],
              [-0.505268, 42.827426],
              [-0.441704, 42.795371],
              [-0.394747, 42.798967],
              [-0.313264, 42.849373],
              [-0.188663, 42.786941],
              [-0.159987, 42.798029],
              [-0.106406, 42.720616],
              [-0.06846, 42.718074],
              [-0.05638, 42.692308],
              [0.000265, 42.685317],
              [0.01581, 42.701828],
              [0.058428, 42.697563],
              [0.077985, 42.714831],
              [0.116951, 42.711155],
              [0.175796, 42.737044],
              [0.260005, 42.716015],
              [0.263695, 42.693237],
              [0.295266, 42.673195],
              [0.360231, 42.724349],
              [0.42209, 42.690458],
              [0.587362, 42.694492],
              [0.597092, 42.705522],
              [0.660068, 42.690854],
              [0.695895, 42.658925],
              [0.707781, 42.621092],
              [0.767525, 42.611236],
              [0.741057, 42.544834],
              [0.749547, 42.534881],
              [0.721162, 42.524539],
              [0.73976, 42.50515],
              [0.690486, 42.487622],
              [0.746097, 42.384776],
              [0.723272, 42.36563],
              [0.771307, 42.350643],
              [0.74036, 42.336873],
              [0.764623, 42.322481],
              [0.702619, 42.19004],
              [0.698726, 42.1723],
              [0.713966, 42.163424],
              [0.695835, 42.151998],
              [0.699551, 42.106964],
              [0.656747, 42.040371],
              [0.663886, 42.014534],
              [0.641928, 41.98207],
              [0.638539, 41.994604],
              [0.61795, 41.988925],
              [0.604356, 41.960655],
              [0.586928, 41.966776],
              [0.56188, 41.938219],
              [0.607487, 41.919315],
              [0.59466, 41.917404],
              [0.592783, 41.884415],
              [0.606677, 41.871138],
              [0.580053, 41.85091],
              [0.554913, 41.85279],
              [0.543875, 41.820885],
              [0.515461, 41.822354],
              [0.483232, 41.801463],
              [0.468601, 41.764976],
              [0.398676, 41.757811],
              [0.395436, 41.731981],
              [0.384742, 41.740312],
              [0.328211, 41.681072],
              [0.326241, 41.662199],
              [0.357982, 41.63172],
              [0.349613, 41.599464],
              [0.428494, 41.602376],
              [0.446923, 41.542236],
              [0.396712, 41.490303],
              [0.342024, 41.484],
              [0.355329, 41.451759],
              [0.335845, 41.407457],
              [0.24059, 41.428353],
              [0.219094, 41.40357],
              [0.182643, 41.394409],
              [0.163652, 41.406497],
              [0.093362, 41.34997],
              [0.009693, 41.360431],
              [-0.0234, 41.396993],
              [-0.029285, 41.377818],
              [-0.007931, 41.347806],
              [-0.04944, 41.36046],
              [-0.072706, 41.377157],
              [-0.104972, 41.48548],
              [-0.156663, 41.535295],
              [-0.167355, 41.581111],
              [-0.156446, 41.589974],
              [-0.205736, 41.602261],
              [-0.244785, 41.573115],
              [-0.312589, 41.624102],
              [-0.359523, 41.635224],
              [-0.374504, 41.688118],
              [-0.444919, 41.703684],
              [-0.489424, 41.731666],
              [-0.51869, 41.728817],
              [-0.500693, 41.761317],
              [-0.516748, 41.836776],
              [-0.555209, 41.871329],
              [-0.603313, 41.882827],
              [-0.595465, 41.899594],
              [-0.630582, 41.912906],
              [-0.657826, 41.957495],
              [-0.778209, 41.957397],
              [-0.884933, 42.003579],
              [-0.850738, 42.026152],
              [-0.857005, 42.05099],
              [-0.812937, 42.092503],
              [-0.793546, 42.054398],
              [-0.763142, 42.067479],
              [-0.770161, 42.08723],
              [-0.754917, 42.090598],
              [-0.760862, 42.129853],
              [-0.747391, 42.155922],
              [-0.7598, 42.170232],
              [-0.759379, 42.263648],
              [-0.728935, 42.297738],
              [-0.744589, 42.33868],
              [-0.736429, 42.369876],
              [-0.777937, 42.391257],
              [-0.77181, 42.346714],
              [-0.806532, 42.270548],
              [-0.876959, 42.281646],
              [-0.890548, 42.240029],
              [-0.918909, 42.257316],
              [-0.855662, 42.33992],
              [-0.844179, 42.384642],
              [-0.815314, 42.395226],
              [-0.904413, 42.434759],
              [-0.903759, 42.453244],
              [-0.877719, 42.461643],
              [-0.89702, 42.479083],
              [-0.892994, 42.509852],
              [-0.933425, 42.505879],
              [-0.895048, 42.549063],
              [-0.934168, 42.572648],
              [-0.904713, 42.599048],
              [-0.927643, 42.656242],
              [-0.893094, 42.662347],
              [-0.879954, 42.688138],
              [-0.922463, 42.700804],
              [-0.904351, 42.712189],
              [-0.900535, 42.760466],
              [-0.87331, 42.758982],
              [-0.847533, 42.78592],
              [-0.860007, 42.798562],
              [-0.856833, 42.846885],
              [-0.818533, 42.869367],
              [-0.819095, 42.900669],
              [-0.747266, 42.922374],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5601",
      properties: {
        name: "Illes Balears",
        density: 0,
        path: "/world/Spain/Illes Balears",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [3.213645, 39.957514],
              [3.154396, 39.923218],
              [3.138386, 39.928085],
              [3.131327, 39.926868],
              [3.110792, 39.899265],
              [3.080042, 39.902273],
              [3.083787, 39.875484],
              [3.105639, 39.857654],
              [3.190364, 39.892422],
              [3.20446, 39.888812],
              [3.188253, 39.881623],
              [3.197213, 39.861858],
              [3.178545, 39.84058],
              [3.120768, 39.832087],
              [3.135069, 39.782669],
              [3.252588, 39.729367],
              [3.328908, 39.7598],
              [3.34812, 39.789369],
              [3.432189, 39.745099],
              [3.461318, 39.748104],
              [3.452687, 39.722487],
              [3.478685, 39.715805],
              [3.456705, 39.705505],
              [3.452354, 39.682232],
              [3.455938, 39.656305],
              [3.441007, 39.65648],
              [3.436287, 39.63427],
              [3.396889, 39.628311],
              [3.385017, 39.592797],
              [3.404074, 39.579442],
              [3.331861, 39.54044],
              [3.340317, 39.534883],
              [3.291845, 39.494288],
              [3.274859, 39.415928],
              [3.26711, 39.42784],
              [3.260961, 39.424034],
              [3.244405, 39.37744],
              [3.222134, 39.372871],
              [3.232232, 39.361642],
              [3.188004, 39.351936],
              [3.171055, 39.326534],
              [3.146396, 39.329974],
              [3.068761, 39.266718],
              [3.050121, 39.265601],
              [3.013546, 39.31044],
              [2.986055, 39.31591],
              [2.990796, 39.33677],
              [2.957816, 39.362479],
              [2.913509, 39.352417],
              [2.881941, 39.366158],
              [2.787342, 39.363059],
              [2.742029, 39.406237],
              [2.747314, 39.44147],
              [2.721996, 39.475724],
              [2.748414, 39.510664],
              [2.669532, 39.562458],
              [2.632714, 39.568431],
              [2.619906, 39.549557],
              [2.639444, 39.55262],
              [2.632465, 39.5452],
              [2.601066, 39.551115],
              [2.595727, 39.546699],
              [2.59706, 39.543514],
              [2.590316, 39.536552],
              [2.590314, 39.531065],
              [2.588287, 39.533463],
              [2.576091, 39.533626],
              [2.575443, 39.53111],
              [2.564664, 39.528025],
              [2.561592, 39.531915],
              [2.541043, 39.524834],
              [2.521742, 39.456601],
              [2.476083, 39.466261],
              [2.479147, 39.491937],
              [2.456183, 39.504771],
              [2.479606, 39.51761],
              [2.456745, 39.534292],
              [2.41944, 39.522894],
              [2.413662, 39.539012],
              [2.386928, 39.52088],
              [2.388525, 39.533746],
              [2.363015, 39.532799],
              [2.390511, 39.546209],
              [2.346497, 39.564604],
              [2.356013, 39.605564],
              [2.462224, 39.652011],
              [2.527873, 39.701554],
              [2.585511, 39.71628],
              [2.619828, 39.755952],
              [2.663196, 39.772403],
              [2.66943, 39.792225],
              [2.694452, 39.791329],
              [2.699459, 39.810849],
              [2.775596, 39.839854],
              [2.778374, 39.85559],
              [2.845008, 39.861744],
              [2.950365, 39.920076],
              [2.982663, 39.908246],
              [3.028138, 39.935084],
              [3.061012, 39.932639],
              [3.060299, 39.919836],
              [3.120499, 39.931385],
              [3.165672, 39.959917],
              [3.213645, 39.957514],
            ],
            [
              [3.18794, 39.754295],
              [3.188337, 39.754618],
              [3.188175, 39.755024],
              [3.187625, 39.754583],
              [3.18794, 39.754295],
            ],
            [
              [3.162003, 39.873743],
              [3.161908, 39.873248],
              [3.162329, 39.873247],
              [3.162202, 39.873761],
              [3.162003, 39.873743],
            ],
            [
              [2.422212, 39.536541],
              [2.422443, 39.536776],
              [2.422498, 39.537128],
              [2.422068, 39.537099],
              [2.422212, 39.536541],
            ],
            [
              [3.004081, 39.313018],
              [3.003524, 39.312982],
              [3.003327, 39.312594],
              [3.003652, 39.312531],
              [3.004081, 39.313018],
            ],
            [
              [3.375877, 39.564976],
              [3.375761, 39.565202],
              [3.375365, 39.565068],
              [3.375702, 39.564886],
              [3.375877, 39.564976],
            ],
            [
              [3.186067, 39.868986],
              [3.186102, 39.869076],
              [3.186091, 39.869346],
              [3.185751, 39.869076],
              [3.186067, 39.868986],
            ],
            [
              [2.603965, 39.735293],
              [2.604046, 39.735356],
              [2.603916, 39.735716],
              [2.603708, 39.735355],
              [2.603965, 39.735293],
            ],
            [
              [2.916974, 39.356889],
              [2.916731, 39.357006],
              [2.916533, 39.356816],
              [2.916975, 39.356736],
              [2.916974, 39.356889],
            ],
            [
              [3.188677, 39.755086],
              [3.188794, 39.755203],
              [3.188362, 39.755177],
              [3.18842, 39.755096],
              [3.188677, 39.755086],
            ],
            [
              [3.177212, 39.881109],
              [3.177329, 39.88119],
              [3.177177, 39.881316],
              [3.177025, 39.881127],
              [3.177212, 39.881109],
            ],
            [
              [3.17599, 39.951883],
              [3.175873, 39.952027],
              [3.175627, 39.951902],
              [3.175744, 39.951838],
              [3.17599, 39.951883],
            ],
            [
              [2.651584, 39.766737],
              [2.651688, 39.766936],
              [2.651547, 39.767043],
              [2.651466, 39.766971],
              [2.651584, 39.766737],
            ],
            [
              [2.440324, 39.533034],
              [2.440428, 39.533134],
              [2.440357, 39.533232],
              [2.44016, 39.533123],
              [2.440324, 39.533034],
            ],
            [
              [2.933169, 39.902787],
              [2.93318, 39.902886],
              [2.932993, 39.902859],
              [2.933087, 39.902688],
              [2.933169, 39.902787],
            ],
            [
              [2.654073, 39.768637],
              [2.654224, 39.768664],
              [2.654247, 39.768736],
              [2.654119, 39.768781],
              [2.654073, 39.768637],
            ],
            [
              [2.963381, 39.912405],
              [2.963451, 39.912531],
              [2.963275, 39.912567],
              [2.96324, 39.912522],
              [2.963381, 39.912405],
            ],
            [
              [2.598114, 39.729939],
              [2.597927, 39.730037],
              [2.597857, 39.729956],
              [2.598044, 39.729867],
              [2.598114, 39.729939],
            ],
            [
              [2.965979, 39.910459],
              [2.965944, 39.910594],
              [2.965815, 39.910612],
              [2.965815, 39.910495],
              [2.965979, 39.910459],
            ],
            [
              [2.965324, 39.910675],
              [2.965207, 39.910801],
              [2.965101, 39.910756],
              [2.965183, 39.910666],
              [2.965324, 39.910675],
            ],
            [
              [2.415237, 39.538551],
              [2.415236, 39.53865],
              [2.415073, 39.538595],
              [2.415156, 39.538488],
              [2.415237, 39.538551],
            ],
            [
              [2.615316, 39.74444],
              [2.615292, 39.74453],
              [2.615094, 39.744484],
              [2.615223, 39.744377],
              [2.615316, 39.74444],
            ],
            [
              [2.598369, 39.730273],
              [2.598403, 39.730363],
              [2.598333, 39.730435],
              [2.598228, 39.730336],
              [2.598369, 39.730273],
            ],
            [
              [3.17571, 39.952442],
              [3.175617, 39.952532],
              [3.175547, 39.952469],
              [3.175628, 39.952379],
              [3.17571, 39.952442],
            ],
            [
              [2.652559, 39.767875],
              [2.652616, 39.767966],
              [2.652418, 39.767938],
              [2.652453, 39.767893],
              [2.652559, 39.767875],
            ],
            [
              [2.631275, 39.758908],
              [2.631286, 39.758998],
              [2.63117, 39.759025],
              [2.631147, 39.758899],
              [2.631275, 39.758908],
            ],
            [
              [2.616058, 39.745172],
              [2.616139, 39.745299],
              [2.616093, 39.745335],
              [2.615999, 39.745325],
              [2.616058, 39.745172],
            ],
            [
              [2.552152, 39.700659],
              [2.552233, 39.70075],
              [2.552209, 39.700786],
              [2.55207, 39.700749],
              [2.552152, 39.700659],
            ],
            [
              [2.553444, 39.701061],
              [2.553385, 39.701142],
              [2.55328, 39.701141],
              [2.553304, 39.701042],
              [2.553444, 39.701061],
            ],
            [
              [3.174948, 39.95174],
              [3.174889, 39.951831],
              [3.174784, 39.951795],
              [3.174866, 39.951704],
              [3.174948, 39.95174],
            ],
            [
              [2.554151, 39.701838],
              [2.55415, 39.70191],
              [2.554045, 39.701937],
              [2.554022, 39.701865],
              [2.554151, 39.701838],
            ],
            [
              [2.543826, 39.700366],
              [2.543813, 39.700465],
              [2.54372, 39.700464],
              [2.543756, 39.700356],
              [2.543826, 39.700366],
            ],
            [
              [2.596819, 39.727853],
              [2.596889, 39.72788],
              [2.596807, 39.727979],
              [2.596761, 39.72788],
              [2.596819, 39.727853],
            ],
            [
              [3.175265, 39.952172],
              [3.175253, 39.952262],
              [3.175136, 39.952245],
              [3.17523, 39.952145],
              [3.175265, 39.952172],
            ],
            [
              [2.553746, 39.701251],
              [2.553711, 39.701305],
              [2.553582, 39.701295],
              [2.55363, 39.701224],
              [2.553746, 39.701251],
            ],
            [
              [2.652233, 39.767613],
              [2.652268, 39.767685],
              [2.652128, 39.767658],
              [2.652128, 39.767622],
              [2.652233, 39.767613],
            ],
            [
              [2.557531, 39.70213],
              [2.557438, 39.702211],
              [2.557391, 39.702139],
              [2.557415, 39.702112],
              [2.557531, 39.70213],
            ],
            [
              [2.651779, 39.767287],
              [2.651802, 39.767369],
              [2.651709, 39.767377],
              [2.651674, 39.767323],
              [2.651779, 39.767287],
            ],
            [
              [2.592864, 39.723848],
              [2.592922, 39.723902],
              [2.59291, 39.723956],
              [2.592805, 39.723911],
              [2.592864, 39.723848],
            ],
            [
              [2.607217, 39.73807],
              [2.607181, 39.738169],
              [2.607123, 39.738187],
              [2.607111, 39.738097],
              [2.607217, 39.73807],
            ],
            [
              [2.537655, 39.700413],
              [2.537643, 39.700467],
              [2.537515, 39.700458],
              [2.537527, 39.700422],
              [2.537655, 39.700413],
            ],
            [
              [3.177734, 39.951628],
              [3.17771, 39.951682],
              [3.177546, 39.951655],
              [3.177617, 39.951619],
              [3.177734, 39.951628],
            ],
            [
              [2.59703, 39.727773],
              [2.596948, 39.72779],
              [2.596925, 39.727709],
              [2.596984, 39.727673],
              [2.59703, 39.727773],
            ],
            [
              [2.578302, 39.714227],
              [2.578349, 39.714263],
              [2.578337, 39.714317],
              [2.578232, 39.71429],
              [2.578302, 39.714227],
            ],
            [
              [2.650396, 39.766013],
              [2.650419, 39.766094],
              [2.650349, 39.766103],
              [2.650314, 39.766031],
              [2.650396, 39.766013],
            ],
            [
              [2.606623, 39.737861],
              [2.606517, 39.737896],
              [2.606471, 39.737851],
              [2.606565, 39.737788],
              [2.606623, 39.737861],
            ],
            [
              [3.17656, 39.954909],
              [3.176443, 39.954901],
              [3.176431, 39.954837],
              [3.176501, 39.95481],
              [3.17656, 39.954909],
            ],
            [
              [2.6427, 39.761962],
              [2.64277, 39.762016],
              [2.642699, 39.762061],
              [2.642653, 39.761971],
              [2.6427, 39.761962],
            ],
            [
              [2.645335, 39.762628],
              [2.645311, 39.7627],
              [2.645218, 39.762645],
              [2.645265, 39.76261],
              [2.645335, 39.762628],
            ],
            [
              [2.619843, 39.755339],
              [2.619784, 39.75533],
              [2.619761, 39.755267],
              [2.619855, 39.755258],
              [2.619843, 39.755339],
            ],
            [
              [2.55851, 39.702368],
              [2.558509, 39.702441],
              [2.558439, 39.702449],
              [2.558428, 39.702395],
              [2.55851, 39.702368],
            ],
            [
              [2.524427, 39.698765],
              [2.524427, 39.698846],
              [2.52438, 39.698855],
              [2.524334, 39.698792],
              [2.524427, 39.698765],
            ],
            [
              [2.590985, 39.721976],
              [2.590961, 39.722039],
              [2.590891, 39.72203],
              [2.590938, 39.721949],
              [2.590985, 39.721976],
            ],
            [
              [2.649919, 39.765669],
              [2.649977, 39.765714],
              [2.649861, 39.765732],
              [2.649884, 39.765678],
              [2.649919, 39.765669],
            ],
            [
              [2.545988, 39.699608],
              [2.545988, 39.699689],
              [2.545906, 39.69968],
              [2.545907, 39.699635],
              [2.545988, 39.699608],
            ],
            [
              [3.200177, 39.956222],
              [3.200178, 39.956285],
              [3.200143, 39.956322],
              [3.20013, 39.956168],
              [3.200177, 39.956222],
            ],
            [
              [3.177064, 39.955323],
              [3.177053, 39.955368],
              [3.176982, 39.955368],
              [3.177041, 39.955278],
              [3.177064, 39.955323],
            ],
          ],
          [
            [
              [4.094163, 40.087967],
              [4.092853, 40.0596],
              [4.103361, 40.044482],
              [4.133528, 40.063246],
              [4.126597, 40.02615],
              [4.140811, 40.035921],
              [4.136546, 40.067081],
              [4.155486, 40.067912],
              [4.17272, 40.060189],
              [4.1608, 40.033479],
              [4.171084, 40.036493],
              [4.182234, 40.021794],
              [4.195306, 40.032209],
              [4.194108, 40.009843],
              [4.205282, 39.993033],
              [4.207555, 40.013019],
              [4.220458, 39.996694],
              [4.269853, 39.996541],
              [4.25485, 39.992858],
              [4.266174, 39.981915],
              [4.254767, 39.963939],
              [4.265467, 39.949912],
              [4.280661, 39.949908],
              [4.274343, 39.93367],
              [4.309713, 39.880355],
              [4.327785, 39.876961],
              [4.310347, 39.870041],
              [4.310279, 39.878178],
              [4.296119, 39.89186],
              [4.256495, 39.897107],
              [4.30743, 39.865425],
              [4.287539, 39.815185],
              [4.269178, 39.80908],
              [4.218797, 39.829126],
              [4.211018, 39.829342],
              [4.208193, 39.824831],
              [4.058611, 39.905955],
              [3.959509, 39.938351],
              [3.900183, 39.922465],
              [3.820976, 39.925603],
              [3.835955, 40.002447],
              [3.799009, 39.99778],
              [3.790786, 40.016126],
              [3.822352, 40.051151],
              [3.898611, 40.059828],
              [3.921268, 40.045343],
              [3.933945, 40.062918],
              [3.978731, 40.051097],
              [4.016172, 40.055595],
              [4.032115, 40.065563],
              [4.041056, 40.055942],
              [4.053726, 40.051872],
              [4.089391, 40.068975],
              [4.08149, 40.089113],
              [4.094163, 40.087967],
            ],
            [
              [4.168776, 40.057175],
              [4.168162, 40.057595],
              [4.167628, 40.057249],
              [4.168008, 40.056822],
              [4.168776, 40.057175],
            ],
            [
              [4.030035, 39.915574],
              [4.030375, 39.916381],
              [4.030088, 39.916735],
              [4.029724, 39.915874],
              [4.030035, 39.915574],
            ],
            [
              [4.169055, 40.02891],
              [4.169339, 40.029042],
              [4.169318, 40.029204],
              [4.16886, 40.02911],
              [4.169055, 40.02891],
            ],
            [
              [3.949057, 40.057696],
              [3.949223, 40.057785],
              [3.949283, 40.057892],
              [3.948813, 40.05786],
              [3.949057, 40.057696],
            ],
            [
              [3.94686, 40.057363],
              [3.947003, 40.05756],
              [3.946911, 40.05766],
              [3.946722, 40.057562],
              [3.94686, 40.057363],
            ],
            [
              [4.169478, 40.030338],
              [4.169515, 40.030437],
              [4.169246, 40.030493],
              [4.169326, 40.03033],
              [4.169478, 40.030338],
            ],
            [
              [3.894989, 40.059009],
              [3.895107, 40.059045],
              [3.894825, 40.05902],
              [3.894871, 40.058992],
              [3.894989, 40.059009],
            ],
            [
              [4.194557, 40.024848],
              [4.194559, 40.024965],
              [4.194405, 40.024876],
              [4.194427, 40.024822],
              [4.194557, 40.024848],
            ],
            [
              [4.165046, 40.052248],
              [4.165036, 40.052356],
              [4.164895, 40.052322],
              [4.164975, 40.052231],
              [4.165046, 40.052248],
            ],
          ],
          [
            [
              [1.532527, 39.118387],
              [1.557143, 39.093312],
              [1.604238, 39.090894],
              [1.598463, 39.082418],
              [1.609911, 39.079302],
              [1.587976, 39.063324],
              [1.621538, 39.027702],
              [1.585215, 39.015724],
              [1.577885, 39.001065],
              [1.581341, 38.989339],
              [1.527381, 38.982445],
              [1.528849, 38.94497],
              [1.510187, 38.946797],
              [1.476825, 38.913018],
              [1.455067, 38.917048],
              [1.457178, 38.903722],
              [1.450558, 38.911699],
              [1.439539, 38.91667],
              [1.438626, 38.90253],
              [1.421282, 38.903603],
              [1.403164, 38.874847],
              [1.406329, 38.83172],
              [1.384464, 38.842395],
              [1.371199, 38.830416],
              [1.343264, 38.871058],
              [1.320421, 38.855783],
              [1.31476, 38.868082],
              [1.297393, 38.862208],
              [1.295872, 38.877161],
              [1.267713, 38.878035],
              [1.255296, 38.859739],
              [1.248051, 38.855801],
              [1.224169, 38.870812],
              [1.213314, 38.901994],
              [1.236481, 38.938793],
              [1.214939, 38.958163],
              [1.229176, 38.974544],
              [1.306531, 38.972412],
              [1.284463, 38.993859],
              [1.298936, 39.009532],
              [1.293926, 39.031543],
              [1.358838, 39.074065],
              [1.366083, 39.064166],
              [1.375164, 39.062265],
              [1.426283, 39.092537],
              [1.440526, 39.080511],
              [1.462697, 39.103548],
              [1.488428, 39.110543],
              [1.492376, 39.101656],
              [1.494941, 39.101788],
              [1.498358, 39.098778],
              [1.532527, 39.118387],
            ],
            [
              [1.299647, 38.870125],
              [1.299659, 38.871558],
              [1.299056, 38.87172],
              [1.299127, 38.870667],
              [1.299647, 38.870125],
            ],
            [
              [1.21879, 38.889589],
              [1.219217, 38.890037],
              [1.2186, 38.890253],
              [1.218309, 38.889906],
              [1.21879, 38.889589],
            ],
            [
              [1.231663, 38.973437],
              [1.232018, 38.973551],
              [1.232042, 38.973966],
              [1.231439, 38.973587],
              [1.231663, 38.973437],
            ],
            [
              [1.403294, 39.077241],
              [1.403834, 39.077374],
              [1.403868, 39.07742],
              [1.40332, 39.07761],
              [1.403294, 39.077241],
            ],
            [
              [1.24366, 38.860529],
              [1.243699, 38.860818],
              [1.24389, 38.86146],
              [1.243551, 38.861185],
              [1.24366, 38.860529],
            ],
            [
              [1.298627, 38.870371],
              [1.298171, 38.870671],
              [1.298069, 38.870579],
              [1.298215, 38.870275],
              [1.298627, 38.870371],
            ],
            [
              [1.604978, 39.079766],
              [1.605184, 39.079859],
              [1.605402, 39.079942],
              [1.604687, 39.079862],
              [1.604978, 39.079766],
            ],
            [
              [1.369394, 38.834292],
              [1.369445, 38.834545],
              [1.369069, 38.834378],
              [1.369175, 38.834289],
              [1.369394, 38.834292],
            ],
            [
              [1.478515, 39.105765],
              [1.478733, 39.10583],
              [1.478778, 39.105921],
              [1.478294, 39.105852],
              [1.478515, 39.105765],
            ],
            [
              [1.497077, 39.099212],
              [1.497106, 39.099455],
              [1.497034, 39.099599],
              [1.4969, 39.099345],
              [1.497077, 39.099212],
            ],
            [
              [1.406444, 39.076482],
              [1.406624, 39.076683],
              [1.4066, 39.076718],
              [1.406359, 39.076652],
              [1.406444, 39.076482],
            ],
            [
              [1.475829, 38.915402],
              [1.475872, 38.91552],
              [1.475697, 38.915625],
              [1.47569, 38.915427],
              [1.475829, 38.915402],
            ],
            [
              [1.599588, 39.023079],
              [1.59954, 39.023178],
              [1.599319, 39.023256],
              [1.599438, 39.023068],
              [1.599588, 39.023079],
            ],
            [
              [1.321281, 38.859877],
              [1.321277, 38.860058],
              [1.321217, 38.860147],
              [1.321164, 38.859939],
              [1.321281, 38.859877],
            ],
            [
              [1.455738, 38.907929],
              [1.455644, 38.908],
              [1.455471, 38.907971],
              [1.455589, 38.907855],
              [1.455738, 38.907929],
            ],
            [
              [1.298391, 38.870143],
              [1.298503, 38.870243],
              [1.298229, 38.870158],
              [1.29838, 38.870088],
              [1.298391, 38.870143],
            ],
            [
              [1.224996, 38.870924],
              [1.225016, 38.871032],
              [1.22498, 38.871113],
              [1.224857, 38.870967],
              [1.224996, 38.870924],
            ],
            [
              [1.261968, 38.872048],
              [1.261964, 38.872237],
              [1.261883, 38.872227],
              [1.261886, 38.872092],
              [1.261968, 38.872048],
            ],
            [
              [1.407162, 39.076951],
              [1.407264, 39.077043],
              [1.407262, 39.077115],
              [1.407114, 39.077023],
              [1.407162, 39.076951],
            ],
            [
              [1.279721, 38.87815],
              [1.279661, 38.878248],
              [1.279559, 38.878201],
              [1.279665, 38.878113],
              [1.279721, 38.87815],
            ],
            [
              [1.26261, 38.872202],
              [1.262642, 38.872301],
              [1.262583, 38.872345],
              [1.262493, 38.872254],
              [1.26261, 38.872202],
            ],
            [
              [1.298361, 38.869944],
              [1.298312, 38.870051],
              [1.298209, 38.870032],
              [1.29821, 38.869987],
              [1.298361, 38.869944],
            ],
            [
              [1.240191, 38.868928],
              [1.240166, 38.869027],
              [1.240063, 38.868998],
              [1.240064, 38.868953],
              [1.240191, 38.868928],
            ],
            [
              [1.262241, 38.872187],
              [1.262159, 38.872258],
              [1.262092, 38.872185],
              [1.262185, 38.87215],
              [1.262241, 38.872187],
            ],
            [
              [1.236161, 38.871544],
              [1.236147, 38.871643],
              [1.236044, 38.871623],
              [1.236033, 38.871596],
              [1.236161, 38.871544],
            ],
            [
              [1.262415, 38.872145],
              [1.262437, 38.872217],
              [1.26231, 38.872197],
              [1.262369, 38.872144],
              [1.262415, 38.872145],
            ],
            [
              [1.259847, 38.868772],
              [1.259833, 38.868844],
              [1.259752, 38.868852],
              [1.259755, 38.868762],
              [1.259847, 38.868772],
            ],
            [
              [1.222467, 38.879896],
              [1.222534, 38.880005],
              [1.222499, 38.880031],
              [1.222441, 38.880022],
              [1.222467, 38.879896],
            ],
            [
              [1.222506, 38.879716],
              [1.222527, 38.879798],
              [1.222435, 38.879787],
              [1.222472, 38.879698],
              [1.222506, 38.879716],
            ],
            [
              [1.262867, 38.872061],
              [1.262842, 38.872142],
              [1.262773, 38.872114],
              [1.262798, 38.87206],
              [1.262867, 38.872061],
            ],
            [
              [1.258335, 38.867416],
              [1.258288, 38.867479],
              [1.258208, 38.867432],
              [1.258255, 38.867379],
              [1.258335, 38.867416],
            ],
            [
              [1.219655, 38.89236],
              [1.219664, 38.89245],
              [1.219583, 38.89244],
              [1.219596, 38.892386],
              [1.219655, 38.89236],
            ],
            [
              [1.262848, 38.872367],
              [1.262882, 38.872395],
              [1.262892, 38.872458],
              [1.262823, 38.872457],
              [1.262848, 38.872367],
            ],
            [
              [1.260309, 38.869653],
              [1.260273, 38.869716],
              [1.260193, 38.869679],
              [1.260206, 38.869625],
              [1.260309, 38.869653],
            ],
            [
              [1.235589, 38.87231],
              [1.235599, 38.872391],
              [1.235518, 38.87239],
              [1.235519, 38.872345],
              [1.235589, 38.87231],
            ],
            [
              [1.262515, 38.872317],
              [1.262525, 38.872381],
              [1.262433, 38.872388],
              [1.262446, 38.872325],
              [1.262515, 38.872317],
            ],
            [
              [1.22586, 38.875136],
              [1.225893, 38.875181],
              [1.225823, 38.875207],
              [1.225801, 38.875171],
              [1.22586, 38.875136],
            ],
            [
              [1.225141, 38.871106],
              [1.225163, 38.871161],
              [1.225083, 38.871141],
              [1.225107, 38.871106],
              [1.225141, 38.871106],
            ],
            [
              [1.321178, 38.859335],
              [1.321087, 38.859307],
              [1.321076, 38.859271],
              [1.321111, 38.859253],
              [1.321178, 38.859335],
            ],
          ],
          [
            [
              [1.435889, 38.761761],
              [1.496472, 38.688241],
              [1.530291, 38.672812],
              [1.569056, 38.691622],
              [1.58472, 38.662796],
              [1.524134, 38.653009],
              [1.447185, 38.684993],
              [1.385532, 38.641337],
              [1.38027, 38.719309],
              [1.396575, 38.735483],
              [1.41925, 38.722509],
              [1.433267, 38.771647],
              [1.435889, 38.761761],
            ],
            [
              [1.430463, 38.75465],
              [1.429987, 38.753796],
              [1.430896, 38.753259],
              [1.431174, 38.753695],
              [1.430463, 38.75465],
            ],
            [
              [1.431098, 38.750874],
              [1.431554, 38.751069],
              [1.431512, 38.751411],
              [1.430651, 38.751318],
              [1.431098, 38.750874],
            ],
            [
              [1.456707, 38.726552],
              [1.45603, 38.726489],
              [1.455955, 38.726235],
              [1.457278, 38.726217],
              [1.456707, 38.726552],
            ],
            [
              [1.453155, 38.73019],
              [1.45317, 38.730578],
              [1.452611, 38.730877],
              [1.452693, 38.730283],
              [1.453155, 38.73019],
            ],
            [
              [1.460056, 38.724343],
              [1.45962, 38.724851],
              [1.459368, 38.724812],
              [1.459502, 38.724444],
              [1.460056, 38.724343],
            ],
            [
              [1.430446, 38.755434],
              [1.430382, 38.755694],
              [1.429845, 38.755552],
              [1.430009, 38.755392],
              [1.430446, 38.755434],
            ],
            [
              [1.431484, 38.750068],
              [1.431283, 38.750299],
              [1.430986, 38.750214],
              [1.431068, 38.750116],
              [1.431484, 38.750068],
            ],
            [
              [1.456991, 38.728358],
              [1.456704, 38.728291],
              [1.456683, 38.728209],
              [1.457028, 38.728223],
              [1.456991, 38.728358],
            ],
            [
              [1.458934, 38.726266],
              [1.458782, 38.726381],
              [1.458506, 38.726368],
              [1.4586, 38.726288],
              [1.458934, 38.726266],
            ],
            [
              [1.456852, 38.728401],
              [1.456951, 38.728582],
              [1.456744, 38.728607],
              [1.456713, 38.728444],
              [1.456852, 38.728401],
            ],
            [
              [1.452501, 38.731182],
              [1.452461, 38.731407],
              [1.452312, 38.731396],
              [1.45228, 38.731269],
              [1.452501, 38.731182],
            ],
            [
              [1.436402, 38.678604],
              [1.436526, 38.678696],
              [1.436103, 38.678618],
              [1.436219, 38.678566],
              [1.436402, 38.678604],
            ],
            [
              [1.450258, 38.731702],
              [1.450256, 38.731774],
              [1.450061, 38.731762],
              [1.450097, 38.731673],
              [1.450258, 38.731702],
            ],
            [
              [1.459442, 38.725101],
              [1.459428, 38.725191],
              [1.459245, 38.725161],
              [1.459327, 38.725081],
              [1.459442, 38.725101],
            ],
            [
              [1.385005, 38.713355],
              [1.384946, 38.713435],
              [1.38482, 38.713407],
              [1.384948, 38.713327],
              [1.385005, 38.713355],
            ],
            [
              [1.459713, 38.725888],
              [1.45963, 38.725986],
              [1.459561, 38.725959],
              [1.459586, 38.725878],
              [1.459713, 38.725888],
            ],
            [
              [1.392043, 38.726725],
              [1.392007, 38.726815],
              [1.391915, 38.726795],
              [1.391928, 38.726742],
              [1.392043, 38.726725],
            ],
          ],
          [
            [
              [2.951196, 39.16548],
              [2.976595, 39.158026],
              [2.956648, 39.147567],
              [2.957933, 39.126914],
              [2.919499, 39.128948],
              [2.916892, 39.161621],
              [2.931679, 39.143329],
              [2.931087, 39.156494],
              [2.949669, 39.148412],
              [2.951196, 39.16548],
            ],
            [
              [2.92315, 39.132943],
              [2.922791, 39.132898],
              [2.922595, 39.132573],
              [2.923474, 39.132538],
              [2.92315, 39.132943],
            ],
            [
              [2.937732, 39.128392],
              [2.937582, 39.128536],
              [2.937466, 39.128356],
              [2.937628, 39.128347],
              [2.937732, 39.128392],
            ],
            [
              [2.956679, 39.134835],
              [2.956737, 39.134853],
              [2.956633, 39.13488],
              [2.956621, 39.134826],
              [2.956679, 39.134835],
            ],
            [
              [2.917662, 39.156089],
              [2.917604, 39.156089],
              [2.917604, 39.156053],
              [2.917662, 39.156044],
              [2.917662, 39.156089],
            ],
          ],
          [
            [
              [2.33858, 39.597699],
              [2.31866, 39.576734],
              [2.30319, 39.572822],
              [2.309709, 39.583466],
              [2.33858, 39.597699],
            ],
          ],
          [
            [
              [2.966221, 39.190464],
              [2.972962, 39.186654],
              [2.96162, 39.175441],
              [2.955017, 39.18235],
              [2.966221, 39.190464],
            ],
          ],
          [
            [
              [1.423417, 38.796574],
              [1.431624, 38.784141],
              [1.433333, 38.774937],
              [1.432215, 38.773967],
              [1.426489, 38.782063],
              [1.419366, 38.779299],
              [1.423417, 38.796574],
            ],
          ],
          [
            [
              [1.214774, 38.989588],
              [1.218922, 38.978398],
              [1.214078, 38.975927],
              [1.206234, 38.98943],
              [1.214774, 38.989588],
            ],
          ],
          [
            [
              [1.200232, 38.870671],
              [1.203, 38.864658],
              [1.188427, 38.862604],
              [1.195571, 38.869923],
              [1.200232, 38.870671],
            ],
          ],
          [
            [
              [4.278059, 39.966676],
              [4.284031, 39.959808],
              [4.272956, 39.956713],
              [4.273555, 39.964347],
              [4.278059, 39.966676],
            ],
          ],
          [
            [
              [1.641432, 39.041523],
              [1.646432, 39.040464],
              [1.650436, 39.032221],
              [1.639828, 39.034756],
              [1.641432, 39.041523],
            ],
          ],
          [
            [
              [1.47785, 38.800252],
              [1.482408, 38.799239],
              [1.480299, 38.787966],
              [1.474099, 38.797401],
              [1.47777, 38.805054],
              [1.47785, 38.800252],
            ],
          ],
          [
            [
              [4.290021, 39.802319],
              [4.296114, 39.800269],
              [4.285432, 39.799288],
              [4.281971, 39.800966],
              [4.290021, 39.802319],
            ],
          ],
          [
            [
              [4.306107, 39.878297],
              [4.30574, 39.87558],
              [4.307249, 39.871329],
              [4.301209, 39.881496],
              [4.306107, 39.878297],
            ],
          ],
          [
            [
              [1.198606, 38.959696],
              [1.198723, 38.956418],
              [1.189767, 38.95782],
              [1.192961, 38.959753],
              [1.198606, 38.959696],
            ],
          ],
          [
            [
              [1.21747, 38.970473],
              [1.220136, 38.96818],
              [1.21576, 38.966852],
              [1.215096, 38.9726],
              [1.21747, 38.970473],
            ],
          ],
          [
            [
              [1.211383, 38.870004],
              [1.215396, 38.869029],
              [1.213861, 38.865879],
              [1.206632, 38.868264],
              [1.211383, 38.870004],
            ],
          ],
          [
            [
              [3.147969, 39.921613],
              [3.150036, 39.919989],
              [3.144086, 39.922618],
              [3.145526, 39.923031],
              [3.147969, 39.921613],
            ],
          ],
          [
            [
              [2.976638, 39.169732],
              [2.978606, 39.167876],
              [2.977287, 39.166389],
              [2.974508, 39.167785],
              [2.976638, 39.169732],
            ],
          ],
          [
            [
              [2.454391, 39.500501],
              [2.451979, 39.499489],
              [2.447604, 39.495378],
              [2.450999, 39.499908],
              [2.454391, 39.500501],
            ],
          ],
          [
            [
              [4.076689, 40.091869],
              [4.076203, 40.090811],
              [4.072921, 40.092472],
              [4.075488, 40.094574],
              [4.076689, 40.091869],
            ],
          ],
          [
            [
              [4.212277, 40.018285],
              [4.21203, 40.016287],
              [4.208149, 40.014112],
              [4.208701, 40.016845],
              [4.212277, 40.018285],
            ],
          ],
          [
            [
              [1.410488, 38.818233],
              [1.412308, 38.814635],
              [1.411462, 38.813804],
              [1.40918, 38.817494],
              [1.410488, 38.818233],
            ],
          ],
          [
            [
              [3.012037, 39.305304],
              [3.011967, 39.303511],
              [3.008175, 39.304665],
              [3.01061, 39.305782],
              [3.012037, 39.305304],
            ],
          ],
          [
            [
              [1.418791, 38.793845],
              [1.420086, 38.792555],
              [1.419854, 38.791615],
              [1.416834, 38.794863],
              [1.418791, 38.793845],
            ],
          ],
          [
            [
              [2.973594, 39.198071],
              [2.974, 39.195846],
              [2.970955, 39.195854],
              [2.971081, 39.197755],
              [2.973594, 39.198071],
            ],
          ],
          [
            [
              [2.5433, 39.505209],
              [2.546472, 39.503951],
              [2.547073, 39.502773],
              [2.54519, 39.502585],
              [2.5433, 39.505209],
            ],
          ],
          [
            [
              [4.209491, 40.017791],
              [4.208389, 40.019127],
              [4.208734, 40.02207],
              [4.210646, 40.020194],
              [4.209922, 40.019625],
              [4.209491, 40.017791],
            ],
          ],
          [
            [
              [2.588838, 39.530807],
              [2.588711, 39.528653],
              [2.58584, 39.528093],
              [2.587616, 39.530794],
              [2.588838, 39.530807],
            ],
          ],
          [
            [
              [1.584675, 38.984839],
              [1.584867, 38.982165],
              [1.583805, 38.98217],
              [1.583007, 38.985152],
              [1.584675, 38.984839],
            ],
          ],
          [
            [
              [4.287254, 39.88567],
              [4.286102, 39.88598],
              [4.286564, 39.888146],
              [4.288796, 39.886788],
              [4.287254, 39.88567],
            ],
          ],
          [
            [
              [4.032068, 40.068671],
              [4.03325, 40.068499],
              [4.031026, 40.066446],
              [4.031119, 40.0695],
              [4.032068, 40.068671],
            ],
          ],
          [
            [
              [2.710502, 39.815815],
              [2.708898, 39.816703],
              [2.709682, 39.819146],
              [2.710859, 39.81705],
              [2.710502, 39.815815],
            ],
          ],
          [
            [
              [1.165186, 38.973033],
              [1.166377, 38.972935],
              [1.167086, 38.971846],
              [1.164933, 38.97165],
              [1.165186, 38.973033],
            ],
          ],
          [
            [
              [3.131361, 39.944257],
              [3.129944, 39.94425],
              [3.132207, 39.946049],
              [3.132146, 39.944941],
              [3.131361, 39.944257],
            ],
          ],
          [
            [
              [2.97489, 39.200982],
              [2.973895, 39.199621],
              [2.971323, 39.200468],
              [2.972087, 39.201008],
              [2.97489, 39.200982],
            ],
          ],
          [
            [
              [4.137216, 40.045281],
              [4.136015, 40.046383],
              [4.136782, 40.048087],
              [4.137551, 40.046413],
              [4.137216, 40.045281],
            ],
          ],
          [
            [
              [2.958651, 39.126257],
              [2.959935, 39.125933],
              [2.960445, 39.125185],
              [2.958397, 39.124409],
              [2.958651, 39.126257],
            ],
          ],
          [
            [
              [3.002318, 39.310098],
              [3.001529, 39.309432],
              [2.999789, 39.310243],
              [3.000868, 39.311216],
              [3.002318, 39.310098],
            ],
          ],
          [
            [
              [2.348797, 39.576348],
              [2.347166, 39.576384],
              [2.347305, 39.577682],
              [2.348529, 39.577626],
              [2.348797, 39.576348],
            ],
          ],
          [
            [
              [3.16999, 39.836043],
              [3.171427, 39.835924],
              [3.171659, 39.835266],
              [3.169425, 39.834702],
              [3.16999, 39.836043],
            ],
          ],
          [
            [
              [1.160986, 38.980085],
              [1.160299, 38.979002],
              [1.158655, 38.979634],
              [1.161237, 38.981116],
              [1.160986, 38.980085],
            ],
          ],
          [
            [
              [4.22285, 39.823731],
              [4.221309, 39.821838],
              [4.219803, 39.821908],
              [4.221455, 39.824134],
              [4.22285, 39.823731],
            ],
          ],
          [
            [
              [1.432014, 39.091128],
              [1.431531, 39.091554],
              [1.432537, 39.093081],
              [1.432747, 39.091976],
              [1.432014, 39.091128],
            ],
          ],
          [
            [
              [2.97966, 39.206597],
              [2.97827, 39.20657],
              [2.977992, 39.207245],
              [2.97871, 39.207777],
              [2.97966, 39.206597],
            ],
          ],
          [
            [
              [1.423049, 38.799165],
              [1.423737, 38.798264],
              [1.421229, 38.798689],
              [1.422337, 38.799615],
              [1.423049, 38.799165],
            ],
          ],
          [
            [
              [3.010366, 39.298555],
              [3.00974, 39.298087],
              [3.009821, 39.300015],
              [3.010064, 39.29988],
              [3.010366, 39.298555],
            ],
          ],
          [
            [
              [1.404576, 38.816332],
              [1.404095, 38.815668],
              [1.402388, 38.815807],
              [1.404471, 38.816916],
              [1.404576, 38.816332],
            ],
          ],
          [
            [
              [1.16553, 38.968659],
              [1.166516, 38.968459],
              [1.164937, 38.967452],
              [1.16474, 38.968395],
              [1.16553, 38.968659],
            ],
          ],
          [
            [
              [1.417598, 38.7744],
              [1.417243, 38.773765],
              [1.416052, 38.773505],
              [1.417171, 38.774944],
              [1.417598, 38.7744],
            ],
          ],
          [
            [
              [1.481974, 38.787691],
              [1.4833, 38.787582],
              [1.484325, 38.787036],
              [1.481352, 38.787682],
              [1.481974, 38.787691],
            ],
          ],
          [
            [
              [1.315274, 39.049568],
              [1.316022, 39.048749],
              [1.314808, 39.049732],
              [1.316416, 39.050647],
              [1.315274, 39.049568],
            ],
          ],
          [
            [
              [4.043439, 40.058452],
              [4.043568, 40.057649],
              [4.041505, 40.057677],
              [4.043336, 40.058588],
              [4.043439, 40.058452],
            ],
          ],
          [
            [
              [2.455803, 39.502805],
              [2.45631, 39.501889],
              [2.454641, 39.502701],
              [2.454826, 39.502828],
              [2.455803, 39.502805],
            ],
          ],
          [
            [
              [4.299241, 39.88132],
              [4.298321, 39.881501],
              [4.297951, 39.882947],
              [4.298371, 39.882924],
              [4.299241, 39.88132],
            ],
          ],
          [
            [
              [2.938974, 39.124635],
              [2.939551, 39.125969],
              [2.940338, 39.125429],
              [2.940072, 39.124969],
              [2.938974, 39.124635],
            ],
          ],
          [
            [
              [4.29031, 39.91143],
              [4.289385, 39.911981],
              [4.289588, 39.912834],
              [4.290717, 39.912552],
              [4.29031, 39.91143],
            ],
          ],
          [
            [
              [2.573019, 39.529804],
              [2.573938, 39.529852],
              [2.574221, 39.529286],
              [2.573386, 39.528769],
              [2.573019, 39.529804],
            ],
          ],
          [
            [
              [1.189526, 38.95687],
              [1.189351, 38.956462],
              [1.188191, 38.956696],
              [1.189343, 38.957714],
              [1.189526, 38.95687],
            ],
          ],
          [
            [
              [1.424253, 38.89702],
              [1.424261, 38.896633],
              [1.422966, 38.895777],
              [1.42303, 38.897031],
              [1.424253, 38.89702],
            ],
          ],
          [
            [
              [1.587055, 39.001041],
              [1.586366, 39.001412],
              [1.586879, 39.002346],
              [1.587575, 39.00157],
              [1.587055, 39.001041],
            ],
          ],
          [
            [
              [1.578818, 38.982749],
              [1.57898, 38.982147],
              [1.577485, 38.981823],
              [1.578079, 38.98274],
              [1.578818, 38.982749],
            ],
          ],
          [
            [
              [4.027457, 40.065361],
              [4.028408, 40.065415],
              [4.027219, 40.064381],
              [4.026812, 40.065358],
              [4.027457, 40.065361],
            ],
          ],
          [
            [
              [1.593574, 39.0981],
              [1.592304, 39.097986],
              [1.592121, 39.099038],
              [1.593592, 39.098317],
              [1.593574, 39.0981],
            ],
          ],
          [
            [
              [2.592599, 39.534245],
              [2.593162, 39.533454],
              [2.591355, 39.534078],
              [2.591853, 39.534503],
              [2.592599, 39.534245],
            ],
          ],
          [
            [
              [1.293767, 39.010763],
              [1.292995, 39.010716],
              [1.293002, 39.011852],
              [1.293279, 39.011874],
              [1.293767, 39.010763],
            ],
          ],
          [
            [
              [1.438712, 38.887573],
              [1.437987, 38.88805],
              [1.438872, 38.888693],
              [1.43908, 38.888155],
              [1.438712, 38.887573],
            ],
          ],
          [
            [
              [2.983767, 39.32601],
              [2.981946, 39.325109],
              [2.981528, 39.325334],
              [2.984185, 39.326407],
              [2.983767, 39.32601],
            ],
          ],
          [
            [
              [2.475251, 39.465537],
              [2.47463, 39.464705],
              [2.474021, 39.465225],
              [2.474714, 39.465895],
              [2.475251, 39.465537],
            ],
          ],
          [
            [
              [4.081308, 40.090232],
              [4.082382, 40.089906],
              [4.082842, 40.090064],
              [4.082521, 40.089815],
              [4.081242, 40.089782],
              [4.080681, 40.090634],
              [4.081308, 40.090232],
            ],
          ],
          [
            [
              [4.204478, 40.0146],
              [4.20448, 40.014042],
              [4.205317, 40.013664],
              [4.203835, 40.014021],
              [4.204478, 40.0146],
            ],
          ],
          [
            [
              [4.280083, 39.950265],
              [4.27952, 39.950821],
              [4.280452, 39.951216],
              [4.280602, 39.951089],
              [4.280083, 39.950265],
            ],
          ],
          [
            [
              [4.132217, 40.064078],
              [4.131825, 40.063785],
              [4.130874, 40.064416],
              [4.131664, 40.064687],
              [4.132217, 40.064078],
            ],
          ],
          [
            [
              [1.610592, 39.080581],
              [1.610826, 39.081602],
              [1.611344, 39.081716],
              [1.611381, 39.080978],
              [1.610592, 39.080581],
            ],
          ],
          [
            [
              [1.404462, 38.814213],
              [1.405173, 38.813835],
              [1.402816, 38.814145],
              [1.403798, 38.814546],
              [1.404462, 38.814213],
            ],
          ],
          [
            [
              [2.942755, 39.125961],
              [2.94345, 39.125836],
              [2.944098, 39.125394],
              [2.942351, 39.125628],
              [2.942755, 39.125961],
            ],
          ],
          [
            [
              [2.471096, 39.46168],
              [2.471751, 39.462629],
              [2.472435, 39.462821],
              [2.471941, 39.462116],
              [2.471096, 39.46168],
            ],
          ],
          [
            [
              [1.425992, 38.776902],
              [1.426812, 38.776769],
              [1.427297, 38.776162],
              [1.425567, 38.776833],
              [1.425992, 38.776902],
            ],
          ],
          [
            [
              [2.977113, 39.202614],
              [2.977391, 39.202541],
              [2.975932, 39.201613],
              [2.976082, 39.202622],
              [2.977113, 39.202614],
            ],
          ],
          [
            [
              [1.494321, 39.102654],
              [1.493739, 39.102818],
              [1.494868, 39.103562],
              [1.4948, 39.102967],
              [1.494321, 39.102654],
            ],
          ],
          [
            [
              [1.434831, 38.88371],
              [1.434059, 38.883681],
              [1.434018, 38.883951],
              [1.434631, 38.88441],
              [1.434831, 38.88371],
            ],
          ],
          [
            [
              [4.199566, 40.006399],
              [4.198913, 40.006577],
              [4.198686, 40.006993],
              [4.199669, 40.006929],
              [4.199566, 40.006399],
            ],
          ],
          [
            [
              [1.195452, 38.871002],
              [1.195602, 38.871464],
              [1.19689, 38.872007],
              [1.19625, 38.871366],
              [1.195452, 38.871002],
            ],
          ],
          [
            [
              [2.961386, 39.138639],
              [2.962208, 39.13854],
              [2.96274, 39.138225],
              [2.960819, 39.138549],
              [2.961386, 39.138639],
            ],
          ],
          [
            [
              [4.13767, 40.043691],
              [4.137061, 40.044418],
              [4.137362, 40.044883],
              [4.13772, 40.044573],
              [4.13767, 40.043691],
            ],
          ],
          [
            [
              [4.044207, 40.055742],
              [4.044686, 40.055648],
              [4.044002, 40.055366],
              [4.043838, 40.056115],
              [4.044207, 40.055742],
            ],
          ],
          [
            [
              [3.406227, 39.770169],
              [3.406846, 39.770103],
              [3.406915, 39.769932],
              [3.405734, 39.769702],
              [3.406227, 39.770169],
            ],
          ],
          [
            [
              [2.939018, 39.126185],
              [2.939412, 39.125951],
              [2.937758, 39.125986],
              [2.938058, 39.126184],
              [2.939018, 39.126185],
            ],
          ],
          [
            [
              [4.035033, 40.065447],
              [4.033787, 40.065259],
              [4.033615, 40.065477],
              [4.034803, 40.065719],
              [4.035033, 40.065447],
            ],
          ],
          [
            [
              [2.982783, 39.317612],
              [2.982505, 39.317738],
              [2.983015, 39.318432],
              [2.983282, 39.317747],
              [2.982783, 39.317612],
            ],
          ],
          [
            [
              [3.036751, 39.279537],
              [3.036206, 39.278987],
              [3.035603, 39.279231],
              [3.035754, 39.279519],
              [3.036751, 39.279537],
            ],
          ],
          [
            [
              [2.935575, 39.122173],
              [2.936859, 39.12202],
              [2.936986, 39.121786],
              [2.935563, 39.12184],
              [2.935575, 39.122173],
            ],
          ],
          [
            [
              [1.405937, 38.87147],
              [1.405622, 38.87161],
              [1.406582, 38.872001],
              [1.406546, 38.871559],
              [1.405937, 38.87147],
            ],
          ],
          [
            [
              [2.965837, 39.194699],
              [2.965373, 39.194708],
              [2.964678, 39.195501],
              [2.965512, 39.19524],
              [2.965837, 39.194699],
            ],
          ],
          [
            [
              [1.606638, 39.047015],
              [1.607057, 39.046884],
              [1.606068, 39.04662],
              [1.606234, 39.047037],
              [1.606638, 39.047015],
            ],
          ],
          [
            [
              [4.214365, 39.825631],
              [4.213946, 39.825726],
              [4.214366, 39.826379],
              [4.214615, 39.825899],
              [4.214365, 39.825631],
            ],
          ],
          [
            [
              [1.202201, 38.958526],
              [1.20118, 38.958303],
              [1.200961, 38.95875],
              [1.201607, 38.958751],
              [1.202201, 38.958526],
            ],
          ],
          [
            [
              [1.440607, 38.903196],
              [1.441103, 38.903184],
              [1.441558, 38.902929],
              [1.441046, 38.902634],
              [1.440607, 38.903196],
            ],
          ],
          [
            [
              [2.341785, 39.585211],
              [2.34134, 39.585524],
              [2.341917, 39.586014],
              [2.341876, 39.585491],
              [2.341785, 39.585211],
            ],
          ],
          [
            [
              [1.218755, 38.965258],
              [1.21743, 38.964688],
              [1.217194, 38.964901],
              [1.217866, 38.965262],
              [1.218755, 38.965258],
            ],
          ],
          [
            [
              [1.61029, 39.080649],
              [1.609827, 39.080094],
              [1.609133, 39.080068],
              [1.609666, 39.080606],
              [1.61029, 39.080649],
            ],
          ],
          [
            [
              [2.979359, 39.205254],
              [2.979394, 39.204741],
              [2.978792, 39.204867],
              [2.978745, 39.205164],
              [2.979359, 39.205254],
            ],
          ],
          [
            [
              [2.963834, 39.147867],
              [2.963498, 39.148282],
              [2.963498, 39.148714],
              [2.964112, 39.148309],
              [2.963834, 39.147867],
            ],
          ],
          [
            [
              [4.22413, 39.823475],
              [4.223954, 39.823423],
              [4.223429, 39.824131],
              [4.224197, 39.823916],
              [4.22413, 39.823475],
            ],
          ],
          [
            [
              [1.405442, 38.830149],
              [1.405267, 38.830786],
              [1.406058, 38.830455],
              [1.405566, 38.830295],
              [1.405442, 38.830149],
            ],
          ],
          [
            [
              [2.540757, 39.477969],
              [2.540456, 39.477896],
              [2.541157, 39.47907],
              [2.541034, 39.478286],
              [2.540757, 39.477969],
            ],
          ],
          [
            [
              [4.213235, 40.014833],
              [4.212842, 40.014459],
              [4.212186, 40.014502],
              [4.213181, 40.015068],
              [4.213235, 40.014833],
            ],
          ],
          [
            [
              [1.407821, 38.826793],
              [1.407481, 38.826572],
              [1.407073, 38.826774],
              [1.407525, 38.827168],
              [1.407821, 38.826793],
            ],
          ],
          [
            [
              [2.988766, 39.33367],
              [2.988917, 39.334004],
              [2.989857, 39.333941],
              [2.989776, 39.333841],
              [2.988766, 39.33367],
            ],
          ],
          [
            [
              [1.215872, 38.987686],
              [1.215497, 38.987923],
              [1.215624, 38.988358],
              [1.216143, 38.987933],
              [1.215872, 38.987686],
            ],
          ],
          [
            [
              [4.311351, 39.888391],
              [4.311129, 39.888385],
              [4.310699, 39.888552],
              [4.311315, 39.88895],
              [4.311351, 39.888391],
            ],
          ],
          [
            [
              [2.987343, 39.312035],
              [2.987053, 39.311972],
              [2.986496, 39.311972],
              [2.987262, 39.312378],
              [2.987343, 39.312035],
            ],
          ],
          [
            [
              [3.915535, 40.050542],
              [3.915125, 40.050581],
              [3.914816, 40.051142],
              [3.915329, 40.050967],
              [3.915535, 40.050542],
            ],
          ],
          [
            [
              [2.706867, 39.534453],
              [2.706599, 39.534615],
              [2.707132, 39.53513],
              [2.707251, 39.534598],
              [2.706867, 39.534453],
            ],
          ],
          [
            [
              [4.308837, 39.891501],
              [4.308347, 39.891534],
              [4.308639, 39.892134],
              [4.30893, 39.892077],
              [4.308837, 39.891501],
            ],
          ],
          [
            [
              [4.214476, 39.826648],
              [4.214477, 39.827342],
              [4.215058, 39.827147],
              [4.215103, 39.827083],
              [4.214476, 39.826648],
            ],
          ],
          [
            [
              [4.007097, 40.059512],
              [4.007279, 40.059132],
              [4.006644, 40.058994],
              [4.006862, 40.059496],
              [4.007097, 40.059512],
            ],
          ],
          [
            [
              [3.260493, 39.404428],
              [3.260226, 39.404536],
              [3.260948, 39.405003],
              [3.260691, 39.404499],
              [3.260493, 39.404428],
            ],
          ],
          [
            [
              [1.435431, 38.900469],
              [1.434956, 38.900552],
              [1.434782, 38.901154],
              [1.43492, 38.901146],
              [1.435431, 38.900469],
            ],
          ],
          [
            [
              [4.100905, 40.05283],
              [4.10129, 40.052718],
              [4.100348, 40.052493],
              [4.100634, 40.052796],
              [4.100905, 40.05283],
            ],
          ],
          [
            [
              [1.373498, 39.06335],
              [1.373362, 39.063736],
              [1.373788, 39.063832],
              [1.373839, 39.063616],
              [1.373498, 39.06335],
            ],
          ],
          [
            [
              [1.42682, 38.873708],
              [1.426269, 38.87362],
              [1.426139, 38.873762],
              [1.427001, 38.873864],
              [1.42682, 38.873708],
            ],
          ],
          [
            [
              [1.165958, 38.969063],
              [1.165258, 38.969349],
              [1.165323, 38.969494],
              [1.165617, 38.969733],
              [1.165958, 38.969063],
            ],
          ],
          [
            [
              [3.974057, 40.054615],
              [3.974347, 40.054423],
              [3.973758, 40.054176],
              [3.973717, 40.054653],
              [3.974057, 40.054615],
            ],
          ],
          [
            [
              [3.184146, 39.93741],
              [3.183923, 39.93741],
              [3.183713, 39.93759],
              [3.184147, 39.937797],
              [3.184146, 39.93741],
            ],
          ],
          [
            [
              [4.287712, 39.926396],
              [4.287507, 39.92666],
              [4.288046, 39.92669],
              [4.28803, 39.926501],
              [4.287712, 39.926396],
            ],
          ],
          [
            [
              [1.573206, 38.986798],
              [1.572777, 38.986883],
              [1.572808, 38.987091],
              [1.573224, 38.987051],
              [1.573206, 38.986798],
            ],
          ],
          [
            [
              [1.584758, 38.999815],
              [1.584502, 38.99992],
              [1.584768, 39.000491],
              [1.584873, 39.000438],
              [1.584758, 38.999815],
            ],
          ],
          [
            [
              [2.94033, 39.120743],
              [2.93981, 39.120878],
              [2.93974, 39.121067],
              [2.940515, 39.12086],
              [2.94033, 39.120743],
            ],
          ],
          [
            [
              [1.161485, 38.982219],
              [1.16109, 38.982348],
              [1.161497, 38.98267],
              [1.161558, 38.982554],
              [1.161485, 38.982219],
            ],
          ],
          [
            [
              [4.281808, 39.929174],
              [4.281469, 39.929204],
              [4.282175, 39.929386],
              [4.282151, 39.929341],
              [4.281808, 39.929174],
            ],
          ],
          [
            [
              [2.475714, 39.465791],
              [2.475456, 39.466105],
              [2.475746, 39.466224],
              [2.475921, 39.466135],
              [2.475714, 39.465791],
            ],
          ],
          [
            [
              [4.311798, 39.889143],
              [4.311391, 39.889274],
              [4.312245, 39.889282],
              [4.312185, 39.88922],
              [4.311798, 39.889143],
            ],
          ],
          [
            [
              [3.928994, 40.060958],
              [3.929191, 40.060839],
              [3.928651, 40.060781],
              [3.928667, 40.061078],
              [3.928994, 40.060958],
            ],
          ],
          [
            [
              [4.103045, 40.04744],
              [4.102904, 40.047441],
              [4.102573, 40.047949],
              [4.1029, 40.04791],
              [4.103045, 40.04744],
            ],
          ],
          [
            [
              [3.008743, 39.303232],
              [3.008221, 39.303205],
              [3.008186, 39.303295],
              [3.008743, 39.303466],
              [3.008743, 39.303232],
            ],
          ],
          [
            [
              [1.424072, 38.797169],
              [1.423643, 38.797289],
              [1.423467, 38.797449],
              [1.4238, 38.797517],
              [1.424072, 38.797169],
            ],
          ],
          [
            [
              [2.964296, 39.149354],
              [2.964111, 39.149174],
              [2.963718, 39.149309],
              [2.964065, 39.149625],
              [2.964296, 39.149354],
            ],
          ],
          [
            [
              [4.019816, 40.060131],
              [4.019943, 40.060004],
              [4.019369, 40.060063],
              [4.019643, 40.060313],
              [4.019816, 40.060131],
            ],
          ],
          [
            [
              [3.119106, 39.863785],
              [3.118791, 39.864227],
              [3.118814, 39.864344],
              [3.11906, 39.86429],
              [3.119106, 39.863785],
            ],
          ],
          [
            [
              [4.27336, 39.963304],
              [4.273184, 39.963306],
              [4.273109, 39.963685],
              [4.273481, 39.963537],
              [4.27336, 39.963304],
            ],
          ],
          [
            [
              [1.501987, 38.932933],
              [1.501765, 38.933083],
              [1.50206, 38.933285],
              [1.502178, 38.933178],
              [1.501987, 38.932933],
            ],
          ],
          [
            [
              [4.29442, 39.915943],
              [4.294044, 39.915875],
              [4.293742, 39.915996],
              [4.294538, 39.916023],
              [4.29442, 39.915943],
            ],
          ],
          [
            [
              [1.231878, 38.976829],
              [1.231686, 38.977141],
              [1.231845, 38.977234],
              [1.232012, 38.977011],
              [1.231878, 38.976829],
            ],
          ],
          [
            [
              [4.24164, 39.812775],
              [4.241488, 39.812758],
              [4.241834, 39.813187],
              [4.24191, 39.81288],
              [4.24164, 39.812775],
            ],
          ],
          [
            [
              [2.435724, 39.645596],
              [2.435686, 39.645956],
              [2.435976, 39.646111],
              [2.43592, 39.645804],
              [2.435724, 39.645596],
            ],
          ],
          [
            [
              [4.027197, 40.063678],
              [4.027443, 40.06364],
              [4.026833, 40.06361],
              [4.027117, 40.063832],
              [4.027197, 40.063678],
            ],
          ],
          [
            [
              [1.610279, 39.040498],
              [1.610026, 39.040396],
              [1.610087, 39.040848],
              [1.610228, 39.04075],
              [1.610279, 39.040498],
            ],
          ],
          [
            [
              [2.335661, 39.589961],
              [2.335522, 39.589942],
              [2.335971, 39.59044],
              [2.335869, 39.590133],
              [2.335661, 39.589961],
            ],
          ],
          [
            [
              [1.45149, 38.890068],
              [1.451383, 38.890202],
              [1.451911, 38.890335],
              [1.451788, 38.890144],
              [1.45149, 38.890068],
            ],
          ],
          [
            [
              [2.458513, 39.531318],
              [2.458325, 39.531542],
              [2.458615, 39.531652],
              [2.458652, 39.531427],
              [2.458513, 39.531318],
            ],
          ],
          [
            [
              [1.219337, 38.964141],
              [1.219103, 38.964245],
              [1.219623, 38.964244],
              [1.219624, 38.964199],
              [1.219337, 38.964141],
            ],
          ],
          [
            [
              [1.168742, 38.984658],
              [1.168374, 38.984598],
              [1.16837, 38.984733],
              [1.168515, 38.984943],
              [1.168742, 38.984658],
            ],
          ],
          [
            [
              [1.435265, 38.885446],
              [1.435509, 38.885377],
              [1.435203, 38.885147],
              [1.434989, 38.885406],
              [1.435265, 38.885446],
            ],
          ],
          [
            [
              [2.451159, 39.649481],
              [2.450972, 39.649588],
              [2.451262, 39.649788],
              [2.451403, 39.649618],
              [2.451159, 39.649481],
            ],
          ],
          [
            [
              [3.475497, 39.708746],
              [3.475611, 39.708313],
              [3.475309, 39.708512],
              [3.475497, 39.708746],
            ],
          ],
          [
            [
              [2.455966, 39.504248],
              [2.455826, 39.504418],
              [2.456266, 39.504564],
              [2.456186, 39.504429],
              [2.455966, 39.504248],
            ],
          ],
          [
            [
              [2.575098, 39.530415],
              [2.574947, 39.53046],
              [2.575026, 39.530784],
              [2.575201, 39.530713],
              [2.575098, 39.530415],
            ],
          ],
          [
            [
              [3.459249, 39.721407],
              [3.45904, 39.721516],
              [3.459344, 39.721677],
              [3.459401, 39.721487],
              [3.459249, 39.721407],
            ],
          ],
          [
            [
              [4.29541, 39.902697],
              [4.295142, 39.902754],
              [4.295206, 39.903015],
              [4.295346, 39.903004],
              [4.29541, 39.902697],
            ],
          ],
          [
            [
              [2.334657, 39.588964],
              [2.334529, 39.589044],
              [2.335201, 39.589309],
              [2.334761, 39.589072],
              [2.334657, 39.588964],
            ],
          ],
          [
            [
              [2.983001, 39.327155],
              [2.982908, 39.327335],
              [2.983384, 39.327542],
              [2.983245, 39.327425],
              [2.983001, 39.327155],
            ],
          ],
          [
            [
              [1.590535, 39.100857],
              [1.59027, 39.100845],
              [1.590196, 39.101042],
              [1.59038, 39.101107],
              [1.590535, 39.100857],
            ],
          ],
          [
            [
              [1.218596, 38.966508],
              [1.218411, 38.966532],
              [1.218405, 38.966775],
              [1.218696, 38.966699],
              [1.218596, 38.966508],
            ],
          ],
          [
            [
              [4.296759, 39.904718],
              [4.296463, 39.904532],
              [4.296252, 39.904535],
              [4.296337, 39.904696],
              [4.296759, 39.904718],
            ],
          ],
          [
            [
              [1.447054, 39.090726],
              [1.446856, 39.090777],
              [1.446724, 39.090992],
              [1.447026, 39.090942],
              [1.447054, 39.090726],
            ],
          ],
          [
            [
              [3.019273, 39.30187],
              [3.019134, 39.30187],
              [3.018821, 39.302068],
              [3.019041, 39.302122],
              [3.019273, 39.30187],
            ],
          ],
          [
            [
              [1.371031, 38.828683],
              [1.370903, 38.828735],
              [1.371286, 38.829119],
              [1.371244, 38.82892],
              [1.371031, 38.828683],
            ],
          ],
          [
            [
              [2.364188, 39.553547],
              [2.363942, 39.553681],
              [2.36394, 39.553951],
              [2.364186, 39.553781],
              [2.364188, 39.553547],
            ],
          ],
          [
            [
              [1.484929, 38.786242],
              [1.484721, 38.786275],
              [1.48454, 38.786643],
              [1.484948, 38.786405],
              [1.484929, 38.786242],
            ],
          ],
          [
            [
              [3.004986, 39.311081],
              [3.004777, 39.311108],
              [3.005322, 39.311279],
              [3.00531, 39.311234],
              [3.004986, 39.311081],
            ],
          ],
          [
            [
              [1.314121, 39.048983],
              [1.313886, 39.049151],
              [1.313974, 39.049333],
              [1.314186, 39.049182],
              [1.314121, 39.048983],
            ],
          ],
          [
            [
              [1.583391, 39.002952],
              [1.583183, 39.002932],
              [1.582855, 39.003189],
              [1.582992, 39.003236],
              [1.583391, 39.002952],
            ],
          ],
          [
            [
              [4.291793, 39.9119],
              [4.291676, 39.911865],
              [4.291466, 39.911904],
              [4.291785, 39.912089],
              [4.291793, 39.9119],
            ],
          ],
          [
            [
              [2.363593, 39.553706],
              [2.363802, 39.553725],
              [2.363873, 39.553608],
              [2.363501, 39.553516],
              [2.363593, 39.553706],
            ],
          ],
          [
            [
              [1.407813, 38.826144],
              [1.407897, 38.826488],
              [1.408164, 38.826392],
              [1.408154, 38.826347],
              [1.407813, 38.826144],
            ],
          ],
          [
            [
              [1.590546, 39.100307],
              [1.590269, 39.100286],
              [1.590128, 39.100402],
              [1.590265, 39.100511],
              [1.590546, 39.100307],
            ],
          ],
          [
            [
              [4.258501, 39.998999],
              [4.258219, 39.998984],
              [4.258022, 39.999076],
              [4.258129, 39.999165],
              [4.258501, 39.998999],
            ],
          ],
          [
            [
              [1.291782, 39.028467],
              [1.291639, 39.028681],
              [1.291681, 39.028835],
              [1.291869, 39.028693],
              [1.291782, 39.028467],
            ],
          ],
          [
            [
              [4.281343, 39.929323],
              [4.281014, 39.92929],
              [4.280935, 39.929417],
              [4.281322, 39.929467],
              [4.281343, 39.929323],
            ],
          ],
          [
            [
              [3.461181, 39.720697],
              [3.4611, 39.72067],
              [3.460962, 39.721031],
              [3.461126, 39.721057],
              [3.461181, 39.720697],
            ],
          ],
          [
            [
              [2.472678, 39.463038],
              [2.472549, 39.463182],
              [2.472827, 39.463354],
              [2.472885, 39.46331],
              [2.472678, 39.463038],
            ],
          ],
          [
            [
              [4.138896, 40.038471],
              [4.138708, 40.038437],
              [4.138758, 40.038653],
              [4.139016, 40.038623],
              [4.138896, 40.038471],
            ],
          ],
          [
            [
              [1.583924, 39.003445],
              [1.583776, 39.003308],
              [1.583634, 39.003496],
              [1.583794, 39.003597],
              [1.583924, 39.003445],
            ],
          ],
          [
            [
              [2.706031, 39.534181],
              [2.70596, 39.534298],
              [2.706286, 39.534335],
              [2.706286, 39.534236],
              [2.706031, 39.534181],
            ],
          ],
          [
            [
              [4.241661, 39.813306],
              [4.241355, 39.813201],
              [4.241275, 39.813256],
              [4.241429, 39.813426],
              [4.241661, 39.813306],
            ],
          ],
          [
            [
              [3.928646, 40.060384],
              [3.928423, 40.060377],
              [3.928354, 40.060495],
              [3.928554, 40.060556],
              [3.928646, 40.060384],
            ],
          ],
          [
            [
              [3.985991, 40.052928],
              [3.985827, 40.052902],
              [3.985559, 40.05304],
              [3.985993, 40.053045],
              [3.985991, 40.052928],
            ],
          ],
          [
            [
              [4.081741, 40.089453],
              [4.081342, 40.089439],
              [4.081237, 40.089476],
              [4.081486, 40.089608],
              [4.081741, 40.089453],
            ],
          ],
          [
            [
              [1.201492, 38.870519],
              [1.201309, 38.870489],
              [1.201271, 38.870624],
              [1.201488, 38.870681],
              [1.201492, 38.870519],
            ],
          ],
          [
            [
              [1.436151, 38.885025],
              [1.435818, 38.884966],
              [1.43577, 38.88502],
              [1.436066, 38.885222],
              [1.436151, 38.885025],
            ],
          ],
          [
            [
              [4.307069, 39.898801],
              [4.30692, 39.898928],
              [4.307145, 39.899106],
              [4.307117, 39.898845],
              [4.307069, 39.898801],
            ],
          ],
          [
            [
              [4.250553, 40.001904],
              [4.250165, 40.001827],
              [4.250036, 40.001838],
              [4.250202, 40.001944],
              [4.250553, 40.001904],
            ],
          ],
          [
            [
              [2.586172, 39.53296],
              [2.586486, 39.532943],
              [2.586486, 39.532898],
              [2.586126, 39.532825],
              [2.586172, 39.53296],
            ],
          ],
          [
            [
              [4.099513, 40.084421],
              [4.099302, 40.08445],
              [4.099281, 40.084549],
              [4.099608, 40.084519],
              [4.099513, 40.084421],
            ],
          ],
          [
            [
              [1.427923, 38.775441],
              [1.427854, 38.77544],
              [1.427836, 38.775764],
              [1.427976, 38.775667],
              [1.427923, 38.775441],
            ],
          ],
          [
            [
              [4.095124, 40.059389],
              [4.095031, 40.059408],
              [4.095189, 40.05974],
              [4.095234, 40.05965],
              [4.095124, 40.059389],
            ],
          ],
          [
            [
              [4.1012, 40.049313],
              [4.101105, 40.049242],
              [4.100884, 40.04938],
              [4.10112, 40.04944],
              [4.1012, 40.049313],
            ],
          ],
          [
            [
              [1.454191, 38.888329],
              [1.454052, 38.888336],
              [1.453934, 38.888479],
              [1.454094, 38.888553],
              [1.454191, 38.888329],
            ],
          ],
          [
            [
              [1.401847, 38.825054],
              [1.401733, 38.825016],
              [1.40159, 38.825221],
              [1.401786, 38.825197],
              [1.401847, 38.825054],
            ],
          ],
          [
            [
              [1.646503, 39.031914],
              [1.646318, 39.031876],
              [1.646236, 39.031956],
              [1.646477, 39.032067],
              [1.646503, 39.031914],
            ],
          ],
          [
            [
              [4.108217, 40.051211],
              [4.108043, 40.05132],
              [4.108268, 40.051471],
              [4.108254, 40.051291],
              [4.108217, 40.051211],
            ],
          ],
          [
            [
              [4.293435, 39.916477],
              [4.293386, 39.916333],
              [4.2932, 39.916425],
              [4.293355, 39.916559],
              [4.293435, 39.916477],
            ],
          ],
          [
            [
              [4.210938, 40.014785],
              [4.210761, 40.01476],
              [4.21068, 40.014824],
              [4.2108, 40.014931],
              [4.210938, 40.014785],
            ],
          ],
          [
            [
              [4.214841, 39.827437],
              [4.214736, 39.827466],
              [4.214904, 39.827716],
              [4.214984, 39.827634],
              [4.214841, 39.827437],
            ],
          ],
          [
            [
              [1.221394, 38.920138],
              [1.221298, 38.920299],
              [1.221481, 38.920356],
              [1.221451, 38.920166],
              [1.221394, 38.920138],
            ],
          ],
          [
            [
              [4.078435, 40.070943],
              [4.078294, 40.070917],
              [4.078202, 40.071071],
              [4.078437, 40.071078],
              [4.078435, 40.070943],
            ],
          ],
          [
            [
              [4.294214, 39.916225],
              [4.294074, 39.916217],
              [4.293994, 39.916317],
              [4.294159, 39.916396],
              [4.294214, 39.916225],
            ],
          ],
          [
            [
              [3.96152, 40.061026],
              [3.961345, 40.061045],
              [3.961358, 40.061135],
              [3.961592, 40.061151],
              [3.96152, 40.061026],
            ],
          ],
          [
            [
              [1.203252, 38.866572],
              [1.20319, 38.866716],
              [1.20335, 38.866772],
              [1.203366, 38.866619],
              [1.203252, 38.866572],
            ],
          ],
          [
            [
              [1.20411, 38.867279],
              [1.203973, 38.867214],
              [1.203878, 38.867321],
              [1.204049, 38.867387],
              [1.20411, 38.867279],
            ],
          ],
          [
            [
              [1.428616, 38.775369],
              [1.428489, 38.775358],
              [1.428416, 38.775538],
              [1.428566, 38.775531],
              [1.428616, 38.775369],
            ],
          ],
          [
            [
              [3.828934, 39.993452],
              [3.8287, 39.993436],
              [3.828572, 39.9935],
              [3.828924, 39.993533],
              [3.828934, 39.993452],
            ],
          ],
          [
            [
              [3.006934, 39.31081],
              [3.006737, 39.310693],
              [3.006644, 39.310819],
              [3.006749, 39.310864],
              [3.006934, 39.31081],
            ],
          ],
          [
            [
              [3.980076, 40.054113],
              [3.979877, 40.054133],
              [3.979926, 40.054268],
              [3.980089, 40.05423],
              [3.980076, 40.054113],
            ],
          ],
          [
            [
              [4.20412, 40.014892],
              [4.204026, 40.014875],
              [4.20389, 40.015129],
              [4.204122, 40.014982],
              [4.20412, 40.014892],
            ],
          ],
          [
            [
              [1.492365, 38.928078],
              [1.492247, 38.928167],
              [1.492524, 38.928179],
              [1.492536, 38.928144],
              [1.492365, 38.928078],
            ],
          ],
          [
            [
              [4.287344, 39.921734],
              [4.287193, 39.921789],
              [4.287336, 39.921914],
              [4.287428, 39.92185],
              [4.287344, 39.921734],
            ],
          ],
          [
            [
              [1.202436, 38.870119],
              [1.202287, 38.870108],
              [1.202227, 38.870197],
              [1.202456, 38.870245],
              [1.202436, 38.870119],
            ],
          ],
          [
            [
              [4.294102, 39.908982],
              [4.293914, 39.908984],
              [4.293928, 39.909101],
              [4.294081, 39.909127],
              [4.294102, 39.908982],
            ],
          ],
          [
            [
              [2.513024, 39.691555],
              [2.512931, 39.691546],
              [2.512883, 39.691735],
              [2.512988, 39.691762],
              [2.513024, 39.691555],
            ],
          ],
          [
            [
              [2.975226, 39.201144],
              [2.975087, 39.201189],
              [2.97533, 39.201298],
              [2.975353, 39.201252],
              [2.975226, 39.201144],
            ],
          ],
          [
            [
              [4.296025, 39.908015],
              [4.295967, 39.907997],
              [4.295828, 39.90808],
              [4.296028, 39.908177],
              [4.296025, 39.908015],
            ],
          ],
          [
            [
              [1.489568, 38.923969],
              [1.489418, 38.923958],
              [1.489381, 38.924075],
              [1.489589, 38.924078],
              [1.489568, 38.923969],
            ],
          ],
          [
            [
              [1.480683, 38.787755],
              [1.480556, 38.787789],
              [1.480656, 38.787935],
              [1.480785, 38.787846],
              [1.480683, 38.787755],
            ],
          ],
          [
            [
              [4.08205, 40.089675],
              [4.08226, 40.089601],
              [4.082271, 40.089556],
              [4.082036, 40.089558],
              [4.08205, 40.089675],
            ],
          ],
          [
            [
              [4.308274, 39.897562],
              [4.308194, 39.897671],
              [4.308453, 39.897722],
              [4.308274, 39.897562],
            ],
          ],
          [
            [
              [4.255847, 39.999937],
              [4.255659, 39.99993],
              [4.255543, 39.999977],
              [4.255779, 40.000046],
              [4.255847, 39.999937],
            ],
          ],
          [
            [
              [4.252331, 40.00048],
              [4.252073, 40.000483],
              [4.252074, 40.000519],
              [4.252437, 40.000542],
              [4.252331, 40.00048],
            ],
          ],
          [
            [
              [4.094934, 40.073896],
              [4.094746, 40.073871],
              [4.094713, 40.073952],
              [4.094878, 40.074014],
              [4.094934, 40.073896],
            ],
          ],
          [
            [
              [1.502374, 38.933199],
              [1.502291, 38.933306],
              [1.502486, 38.933345],
              [1.502499, 38.933264],
              [1.502374, 38.933199],
            ],
          ],
          [
            [
              [1.291704, 38.875514],
              [1.291542, 38.87553],
              [1.291562, 38.875656],
              [1.291724, 38.875641],
              [1.291704, 38.875514],
            ],
          ],
          [
            [
              [3.152676, 39.923229],
              [3.152571, 39.92322],
              [3.152782, 39.923508],
              [3.152735, 39.92331],
              [3.152676, 39.923229],
            ],
          ],
          [
            [
              [3.457649, 39.703637],
              [3.457475, 39.703673],
              [3.457522, 39.703772],
              [3.457639, 39.703772],
              [3.457649, 39.703637],
            ],
          ],
          [
            [
              [1.422559, 38.663523],
              [1.422421, 38.66353],
              [1.422374, 38.663593],
              [1.422498, 38.663684],
              [1.422559, 38.663523],
            ],
          ],
          [
            [
              [2.578128, 39.531606],
              [2.578058, 39.531669],
              [2.578267, 39.531742],
              [2.578233, 39.531607],
              [2.578128, 39.531606],
            ],
          ],
          [
            [
              [1.188146, 38.956209],
              [1.188017, 38.956288],
              [1.18813, 38.956389],
              [1.188236, 38.9563],
              [1.188146, 38.956209],
            ],
          ],
          [
            [
              [2.436337, 39.646158],
              [2.43622, 39.646148],
              [2.436277, 39.646338],
              [2.436371, 39.646302],
              [2.436337, 39.646158],
            ],
          ],
          [
            [
              [1.479141, 38.710707],
              [1.479003, 38.710741],
              [1.47908, 38.710895],
              [1.479185, 38.710825],
              [1.479141, 38.710707],
            ],
          ],
          [
            [
              [1.438268, 38.888883],
              [1.438129, 38.888917],
              [1.438381, 38.888992],
              [1.438383, 38.888911],
              [1.438268, 38.888883],
            ],
          ],
          [
            [
              [1.203318, 38.869421],
              [1.20332, 38.869358],
              [1.203052, 38.869444],
              [1.203143, 38.869499],
              [1.203318, 38.869421],
            ],
          ],
          [
            [
              [2.512459, 39.690679],
              [2.512389, 39.690633],
              [2.512282, 39.690858],
              [2.512341, 39.690885],
              [2.512459, 39.690679],
            ],
          ],
          [
            [
              [4.285357, 39.923089],
              [4.285205, 39.923073],
              [4.285171, 39.923163],
              [4.285313, 39.923216],
              [4.285357, 39.923089],
            ],
          ],
          [
            [
              [3.142878, 39.949379],
              [3.142784, 39.94937],
              [3.14275, 39.949541],
              [3.142878, 39.949541],
              [3.142878, 39.949379],
            ],
          ],
          [
            [
              [4.295532, 39.802861],
              [4.295415, 39.802835],
              [4.295276, 39.802918],
              [4.295534, 39.802942],
              [4.295532, 39.802861],
            ],
          ],
          [
            [
              [3.013823, 39.303322],
              [3.013707, 39.30334],
              [3.013776, 39.303466],
              [3.013927, 39.303403],
              [3.013823, 39.303322],
            ],
          ],
          [
            [
              [4.28683, 39.920487],
              [4.286689, 39.920471],
              [4.286597, 39.920553],
              [4.286726, 39.920605],
              [4.28683, 39.920487],
            ],
          ],
          [
            [
              [2.961933, 39.175198],
              [2.961817, 39.175207],
              [2.961725, 39.175324],
              [2.961921, 39.175297],
              [2.961933, 39.175198],
            ],
          ],
          [
            [
              [4.212488, 40.014985],
              [4.212324, 40.015005],
              [4.212349, 40.015095],
              [4.212525, 40.015084],
              [4.212488, 40.014985],
            ],
          ],
          [
            [
              [4.307694, 39.895343],
              [4.307541, 39.895318],
              [4.307544, 39.895453],
              [4.307719, 39.895424],
              [4.307694, 39.895343],
            ],
          ],
          [
            [
              [3.456766, 39.704091],
              [3.456626, 39.704109],
              [3.456545, 39.704182],
              [3.456732, 39.704217],
              [3.456766, 39.704091],
            ],
          ],
          [
            [
              [2.526895, 39.701244],
              [2.526847, 39.701343],
              [2.527046, 39.701371],
              [2.527011, 39.701326],
              [2.526895, 39.701244],
            ],
          ],
          [
            [
              [1.423391, 38.797745],
              [1.423287, 38.797762],
              [1.42324, 38.797843],
              [1.4234, 38.797881],
              [1.423391, 38.797745],
            ],
          ],
          [
            [
              [2.987932, 39.329002],
              [2.987874, 39.328939],
              [2.987642, 39.329011],
              [2.987839, 39.329084],
              [2.987932, 39.329002],
            ],
          ],
          [
            [
              [2.527847, 39.701897],
              [2.527753, 39.701923],
              [2.528056, 39.702051],
              [2.528033, 39.701997],
              [2.527847, 39.701897],
            ],
          ],
          [
            [
              [4.196029, 40.030004],
              [4.195901, 40.030014],
              [4.195832, 40.030105],
              [4.19595, 40.030131],
              [4.196029, 40.030004],
            ],
          ],
          [
            [
              [1.41975, 38.805689],
              [1.419741, 38.80559],
              [1.419566, 38.805687],
              [1.419634, 38.805742],
              [1.41975, 38.805689],
            ],
          ],
          [
            [
              [4.251307, 40.0015],
              [4.251224, 40.001465],
              [4.251143, 40.001502],
              [4.251285, 40.001617],
              [4.251307, 40.0015],
            ],
          ],
          [
            [
              [1.370733, 38.829616],
              [1.370699, 38.829598],
              [1.370671, 38.829823],
              [1.370811, 38.829743],
              [1.370733, 38.829616],
            ],
          ],
          [
            [
              [4.2897, 39.918176],
              [4.289513, 39.918196],
              [4.289502, 39.918241],
              [4.289748, 39.918247],
              [4.2897, 39.918176],
            ],
          ],
          [
            [
              [1.215221, 38.868198],
              [1.21507, 38.868249],
              [1.215068, 38.868295],
              [1.215253, 38.868297],
              [1.215221, 38.868198],
            ],
          ],
          [
            [
              [1.438559, 38.888761],
              [1.438444, 38.888759],
              [1.438476, 38.888886],
              [1.438625, 38.888888],
              [1.438559, 38.888761],
            ],
          ],
          [
            [
              [1.157863, 38.97854],
              [1.157814, 38.978674],
              [1.157964, 38.978659],
              [1.157977, 38.978596],
              [1.157863, 38.97854],
            ],
          ],
          [
            [
              [4.275608, 39.935575],
              [4.275467, 39.935568],
              [4.275409, 39.935605],
              [4.275505, 39.935703],
              [4.275608, 39.935575],
            ],
          ],
          [
            [
              [1.43229, 38.771607],
              [1.432222, 38.771525],
              [1.432037, 38.771567],
              [1.432117, 38.771622],
              [1.43229, 38.771607],
            ],
          ],
          [
            [
              [1.30447, 38.862662],
              [1.304413, 38.862616],
              [1.304318, 38.862741],
              [1.304433, 38.862761],
              [1.30447, 38.862662],
            ],
          ],
          [
            [
              [1.408101, 38.81067],
              [1.408011, 38.810588],
              [1.407939, 38.810731],
              [1.408007, 38.810759],
              [1.408101, 38.81067],
            ],
          ],
          [
            [
              [1.50892, 38.939572],
              [1.50884, 38.939544],
              [1.508768, 38.939651],
              [1.508871, 38.93968],
              [1.50892, 38.939572],
            ],
          ],
          [
            [
              [2.412478, 39.537249],
              [2.412373, 39.537275],
              [2.412616, 39.537412],
              [2.412535, 39.537339],
              [2.412478, 39.537249],
            ],
          ],
          [
            [
              [2.365047, 39.534549],
              [2.364942, 39.534638],
              [2.364988, 39.534711],
              [2.365105, 39.534612],
              [2.365047, 39.534549],
            ],
          ],
          [
            [
              [1.465881, 38.913542],
              [1.465777, 38.913522],
              [1.465729, 38.913594],
              [1.465832, 38.913649],
              [1.465881, 38.913542],
            ],
          ],
          [
            [
              [4.170808, 40.036856],
              [4.170678, 40.036785],
              [4.170573, 40.036823],
              [4.170657, 40.036885],
              [4.170808, 40.036856],
            ],
          ],
          [
            [
              [1.285818, 38.876284],
              [1.285762, 38.876229],
              [1.285656, 38.876309],
              [1.285804, 38.876383],
              [1.285818, 38.876284],
            ],
          ],
          [
            [
              [1.478865, 38.711785],
              [1.478739, 38.711765],
              [1.47868, 38.711836],
              [1.478783, 38.711883],
              [1.478865, 38.711785],
            ],
          ],
          [
            [
              [2.584522, 39.53263],
              [2.584382, 39.532593],
              [2.584474, 39.532729],
              [2.584522, 39.53263],
            ],
          ],
          [
            [
              [1.427442, 38.775858],
              [1.427421, 38.775785],
              [1.42727, 38.775847],
              [1.427361, 38.775911],
              [1.427442, 38.775858],
            ],
          ],
          [
            [
              [1.429754, 38.774889],
              [1.429662, 38.774887],
              [1.429626, 38.774986],
              [1.42974, 38.775006],
              [1.429754, 38.774889],
            ],
          ],
          [
            [
              [2.848278, 39.868614],
              [2.848184, 39.868605],
              [2.848125, 39.868749],
              [2.848231, 39.868731],
              [2.848278, 39.868614],
            ],
          ],
          [
            [
              [1.191678, 38.959372],
              [1.191585, 38.959407],
              [1.191605, 38.959497],
              [1.191709, 38.959499],
              [1.191678, 38.959372],
            ],
          ],
          [
            [
              [4.291194, 39.911754],
              [4.291064, 39.911719],
              [4.291018, 39.911764],
              [4.29116, 39.911835],
              [4.291194, 39.911754],
            ],
          ],
          [
            [
              [1.477959, 38.806985],
              [1.477867, 38.806984],
              [1.477842, 38.807083],
              [1.478004, 38.807076],
              [1.477959, 38.806985],
            ],
          ],
          [
            [
              [1.198014, 38.870871],
              [1.197922, 38.870869],
              [1.197886, 38.870914],
              [1.198129, 38.8709],
              [1.198014, 38.870871],
            ],
          ],
          [
            [
              [2.424266, 39.638665],
              [2.424161, 39.638656],
              [2.424137, 39.638746],
              [2.424277, 39.638755],
              [2.424266, 39.638665],
            ],
          ],
          [
            [
              [2.526955, 39.700992],
              [2.526815, 39.700964],
              [2.526757, 39.700991],
              [2.526861, 39.701073],
              [2.526955, 39.700992],
            ],
          ],
          [
            [
              [1.481481, 38.788144],
              [1.481423, 38.788143],
              [1.481375, 38.788259],
              [1.481489, 38.78827],
              [1.481481, 38.788144],
            ],
          ],
          [
            [
              [1.432088, 38.771874],
              [1.431962, 38.771855],
              [1.431858, 38.77188],
              [1.431914, 38.771944],
              [1.432088, 38.771874],
            ],
          ],
          [
            [
              [4.024938, 40.062401],
              [4.024844, 40.062375],
              [4.024763, 40.062439],
              [4.024846, 40.062483],
              [4.024938, 40.062401],
            ],
          ],
          [
            [
              [2.413426, 39.538019],
              [2.413309, 39.538028],
              [2.413216, 39.53809],
              [2.413355, 39.5381],
              [2.413426, 39.538019],
            ],
          ],
          [
            [
              [4.210255, 40.01927],
              [4.21015, 40.019298],
              [4.210292, 40.019387],
              [4.210315, 40.019323],
              [4.210255, 40.01927],
            ],
          ],
          [
            [
              [2.337291, 39.598584],
              [2.337186, 39.598565],
              [2.337139, 39.598637],
              [2.337255, 39.598665],
              [2.337291, 39.598584],
            ],
          ],
          [
            [
              [1.309148, 38.864163],
              [1.309068, 38.864143],
              [1.309041, 38.864296],
              [1.309087, 38.864297],
              [1.309148, 38.864163],
            ],
          ],
          [
            [
              [1.297503, 38.861939],
              [1.297376, 38.861964],
              [1.297374, 38.862018],
              [1.297477, 38.862047],
              [1.297503, 38.861939],
            ],
          ],
          [
            [
              [1.15738, 38.978469],
              [1.157277, 38.978468],
              [1.157241, 38.978521],
              [1.157297, 38.978585],
              [1.15738, 38.978469],
            ],
          ],
          [
            [
              [1.221257, 38.919172],
              [1.221166, 38.919126],
              [1.221106, 38.919206],
              [1.221209, 38.919243],
              [1.221257, 38.919172],
            ],
          ],
          [
            [
              [2.57786, 39.531606],
              [2.577768, 39.531542],
              [2.577732, 39.53165],
              [2.577802, 39.53165],
              [2.57786, 39.531606],
            ],
          ],
          [
            [
              [2.584883, 39.53246],
              [2.584814, 39.532406],
              [2.584709, 39.532495],
              [2.584836, 39.532514],
              [2.584883, 39.53246],
            ],
          ],
          [
            [
              [4.188031, 40.03578],
              [4.187961, 40.035763],
              [4.187915, 40.035835],
              [4.188056, 40.035852],
              [4.188031, 40.03578],
            ],
          ],
          [
            [
              [2.599358, 39.732808],
              [2.599276, 39.73288],
              [2.599404, 39.732917],
              [2.599416, 39.732863],
              [2.599358, 39.732808],
            ],
          ],
          [
            [
              [1.199343, 38.870738],
              [1.199251, 38.870746],
              [1.19941, 38.870838],
              [1.1994, 38.870757],
              [1.199343, 38.870738],
            ],
          ],
          [
            [
              [4.187725, 40.035711],
              [4.187642, 40.03564],
              [4.187595, 40.03564],
              [4.187597, 40.035739],
              [4.187725, 40.035711],
            ],
          ],
          [
            [
              [1.207046, 38.868757],
              [1.206989, 38.868756],
              [1.206952, 38.868846],
              [1.207055, 38.868866],
              [1.207046, 38.868757],
            ],
          ],
          [
            [
              [2.411282, 39.536999],
              [2.411188, 39.536999],
              [2.411223, 39.537098],
              [2.411281, 39.537099],
              [2.411282, 39.536999],
            ],
          ],
          [
            [
              [2.957262, 39.146243],
              [2.957204, 39.146216],
              [2.957077, 39.14627],
              [2.957181, 39.146297],
              [2.957262, 39.146243],
            ],
          ],
          [
            [
              [1.43532, 38.885032],
              [1.435322, 38.884969],
              [1.435241, 38.884977],
              [1.435239, 38.885067],
              [1.43532, 38.885032],
            ],
          ],
          [
            [
              [4.201192, 40.022292],
              [4.201121, 40.022293],
              [4.201182, 40.022419],
              [4.201229, 40.022409],
              [4.201192, 40.022292],
            ],
          ],
          [
            [
              [1.427733, 38.776249],
              [1.4277, 38.776186],
              [1.427595, 38.776238],
              [1.427663, 38.776293],
              [1.427733, 38.776249],
            ],
          ],
          [
            [
              [2.970581, 39.17429],
              [2.970477, 39.174272],
              [2.970466, 39.174308],
              [2.970547, 39.17438],
              [2.970581, 39.17429],
            ],
          ],
          [
            [
              [1.203135, 38.869832],
              [1.20309, 38.869805],
              [1.203007, 38.869876],
              [1.203099, 38.869904],
              [1.203135, 38.869832],
            ],
          ],
          [
            [
              [1.210715, 38.870003],
              [1.210577, 38.869992],
              [1.21053, 38.870018],
              [1.210598, 38.870055],
              [1.210715, 38.870003],
            ],
          ],
          [
            [
              [2.963684, 39.147831],
              [2.963638, 39.147795],
              [2.96358, 39.147849],
              [2.963626, 39.147903],
              [2.963684, 39.147831],
            ],
          ],
          [
            [
              [1.199833, 38.959093],
              [1.199787, 38.959074],
              [1.199703, 38.959208],
              [1.199784, 38.959182],
              [1.199833, 38.959093],
            ],
          ],
          [
            [
              [1.21453, 38.897183],
              [1.214437, 38.897227],
              [1.214459, 38.897272],
              [1.214551, 38.897246],
              [1.21453, 38.897183],
            ],
          ],
          [
            [
              [1.284911, 38.876136],
              [1.284877, 38.876117],
              [1.284829, 38.876206],
              [1.284932, 38.876217],
              [1.284911, 38.876136],
            ],
          ],
          [
            [
              [4.308176, 39.899184],
              [4.308128, 39.899104],
              [4.308035, 39.899132],
              [4.308047, 39.899159],
              [4.308176, 39.899184],
            ],
          ],
          [
            [
              [1.427888, 38.77599],
              [1.427865, 38.77599],
              [1.427761, 38.776033],
              [1.427875, 38.776062],
              [1.427888, 38.77599],
            ],
          ],
          [
            [
              [2.944446, 39.124638],
              [2.944399, 39.124728],
              [2.944469, 39.124737],
              [2.944469, 39.124683],
              [2.944446, 39.124638],
            ],
          ],
          [
            [
              [1.215329, 38.868479],
              [1.215283, 38.868478],
              [1.215235, 38.868531],
              [1.215327, 38.868551],
              [1.215329, 38.868479],
            ],
          ],
          [
            [
              [4.183088, 40.033227],
              [4.183018, 40.033237],
              [4.183089, 40.033317],
              [4.183088, 40.033227],
            ],
          ],
          [
            [
              [4.182494, 40.032746],
              [4.182481, 40.03271],
              [4.182412, 40.032747],
              [4.182483, 40.032792],
              [4.182494, 40.032746],
            ],
          ],
          [
            [
              [2.958964, 39.164798],
              [2.958906, 39.164789],
              [2.958906, 39.164861],
              [2.958964, 39.164843],
              [2.958964, 39.164798],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5624",
      properties: { name: "Jaén", density: 0, path: "/world/Spain/Jaén" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.766826, 38.532968],
              [-2.702508, 38.501091],
              [-2.599741, 38.512916],
              [-2.57021, 38.494796],
              [-2.571883, 38.414128],
              [-2.482357, 38.396195],
              [-2.485206, 38.300256],
              [-2.436603, 38.27213],
              [-2.454339, 38.215488],
              [-2.446277, 38.185194],
              [-2.53967, 38.09261],
              [-2.606318, 38.061856],
              [-2.595855, 38.053186],
              [-2.605118, 37.998147],
              [-2.669825, 37.961429],
              [-2.702673, 37.971522],
              [-2.784609, 37.917641],
              [-2.873154, 37.791491],
              [-2.880292, 37.759944],
              [-2.903782, 37.751169],
              [-2.901525, 37.689293],
              [-2.884036, 37.681318],
              [-2.977919, 37.633984],
              [-2.978177, 37.602752],
              [-3.050965, 37.618357],
              [-3.088196, 37.59554],
              [-3.172053, 37.64287],
              [-3.215743, 37.644187],
              [-3.262485, 37.639208],
              [-3.296231, 37.588257],
              [-3.399303, 37.56405],
              [-3.506544, 37.617226],
              [-3.536157, 37.597338],
              [-3.536435, 37.580815],
              [-3.627068, 37.52854],
              [-3.776241, 37.476484],
              [-3.785575, 37.490898],
              [-3.841223, 37.386236],
              [-3.950888, 37.378347],
              [-3.983685, 37.388525],
              [-4.048141, 37.467973],
              [-4.087653, 37.486075],
              [-4.059995, 37.505813],
              [-4.098058, 37.511531],
              [-4.120976, 37.5543],
              [-4.160707, 37.575107],
              [-4.173837, 37.610083],
              [-4.220137, 37.62024],
              [-4.228103, 37.639536],
              [-4.164368, 37.681506],
              [-4.183509, 37.707039],
              [-4.23861, 37.720091],
              [-4.207928, 37.780055],
              [-4.286737, 37.796543],
              [-4.258052, 37.939322],
              [-4.282476, 38.021971],
              [-4.259958, 38.022232],
              [-4.268753, 38.040054],
              [-4.240086, 38.047029],
              [-4.251945, 38.063522],
              [-4.214085, 38.089477],
              [-4.21795, 38.110768],
              [-4.161183, 38.145544],
              [-4.167861, 38.180787],
              [-4.201175, 38.214084],
              [-4.196001, 38.237847],
              [-4.216686, 38.246953],
              [-4.210218, 38.301452],
              [-4.238538, 38.315107],
              [-4.237442, 38.341541],
              [-4.269269, 38.347734],
              [-4.27866, 38.377488],
              [-4.268234, 38.398013],
              [-3.91512, 38.368814],
              [-3.854284, 38.375141],
              [-3.830561, 38.392903],
              [-3.830056, 38.412796],
              [-3.785612, 38.425269],
              [-3.598236, 38.398474],
              [-3.583076, 38.451469],
              [-3.567357, 38.452033],
              [-3.535164, 38.439414],
              [-3.528443, 38.408641],
              [-3.471015, 38.397999],
              [-3.424824, 38.406799],
              [-3.376684, 38.439596],
              [-3.375012, 38.475235],
              [-3.326492, 38.482121],
              [-3.279424, 38.460244],
              [-3.138288, 38.438577],
              [-3.064966, 38.478248],
              [-3.001713, 38.408321],
              [-2.993509, 38.450119],
              [-2.963586, 38.471454],
              [-2.905788, 38.469907],
              [-2.88149, 38.452645],
              [-2.779955, 38.509706],
              [-2.766826, 38.532968],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5634",
      properties: {
        name: "La Rioja",
        density: 0,
        path: "/world/Spain/La Rioja",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.997229, 42.642139],
              [-2.946451, 42.639317],
              [-2.916008, 42.619521],
              [-2.852975, 42.636182],
              [-2.8418, 42.608599],
              [-2.818771, 42.612716],
              [-2.819905, 42.591473],
              [-2.840847, 42.581192],
              [-2.822972, 42.554742],
              [-2.777953, 42.581339],
              [-2.76372, 42.622836],
              [-2.740772, 42.622552],
              [-2.680542, 42.594518],
              [-2.705687, 42.517162],
              [-2.675781, 42.525727],
              [-2.647749, 42.483262],
              [-2.605426, 42.503523],
              [-2.596993, 42.472936],
              [-2.568259, 42.494148],
              [-2.53048, 42.483571],
              [-2.537353, 42.495243],
              [-2.509741, 42.520057],
              [-2.514721, 42.490115],
              [-2.415616, 42.49057],
              [-2.345838, 42.451369],
              [-2.329988, 42.468802],
              [-2.298719, 42.457412],
              [-2.288012, 42.46736],
              [-2.274484, 42.445472],
              [-2.175037, 42.407766],
              [-2.111902, 42.419956],
              [-2.115514, 42.399082],
              [-2.07864, 42.368126],
              [-2.089961, 42.346971],
              [-2.069982, 42.342524],
              [-2.016015, 42.369298],
              [-1.971058, 42.353467],
              [-1.965749, 42.334408],
              [-1.918513, 42.326965],
              [-1.913818, 42.300109],
              [-1.870658, 42.288966],
              [-1.872276, 42.252669],
              [-1.84979, 42.259254],
              [-1.802326, 42.223429],
              [-1.725345, 42.214538],
              [-1.70424, 42.192029],
              [-1.680124, 42.191574],
              [-1.700897, 42.173124],
              [-1.679659, 42.151689],
              [-1.719895, 42.13344],
              [-1.739742, 42.149736],
              [-1.779005, 42.137705],
              [-1.832417, 42.148231],
              [-1.909239, 42.059123],
              [-1.901577, 42.02968],
              [-1.847185, 42.007999],
              [-1.844045, 41.987334],
              [-1.865188, 41.977602],
              [-1.856541, 41.966402],
              [-1.970298, 41.919142],
              [-2.028314, 41.953386],
              [-2.036392, 41.939404],
              [-2.114356, 41.961144],
              [-2.124072, 41.995309],
              [-2.107632, 42.002774],
              [-2.161913, 42.066734],
              [-2.12724, 42.080462],
              [-2.133377, 42.101142],
              [-2.236484, 42.102506],
              [-2.269589, 42.088555],
              [-2.28216, 42.131703],
              [-2.318205, 42.145181],
              [-2.438462, 42.137268],
              [-2.484369, 42.106349],
              [-2.514676, 42.114542],
              [-2.51488, 42.068861],
              [-2.549067, 42.05151],
              [-2.579909, 41.996264],
              [-2.746961, 42.009275],
              [-2.757377, 42.033102],
              [-2.705947, 42.103556],
              [-2.734666, 42.124954],
              [-2.791608, 42.108752],
              [-2.797986, 42.046844],
              [-2.889512, 42.010402],
              [-2.913624, 42.022852],
              [-2.931176, 42.086775],
              [-3.034373, 42.085963],
              [-3.129453, 42.198962],
              [-3.088589, 42.245725],
              [-3.109668, 42.351448],
              [-3.063462, 42.356697],
              [-3.084811, 42.385386],
              [-3.10527, 42.386369],
              [-3.101347, 42.416777],
              [-3.078179, 42.41575],
              [-3.082652, 42.397384],
              [-3.056881, 42.370273],
              [-3.063299, 42.42491],
              [-3.046004, 42.445154],
              [-3.094695, 42.488074],
              [-3.072572, 42.528923],
              [-3.134271, 42.542053],
              [-3.05983, 42.589188],
              [-3.084152, 42.640687],
              [-2.997229, 42.642139],
            ],
            [
              [-2.945214, 42.603886],
              [-2.939177, 42.594292],
              [-2.922629, 42.600829],
              [-2.9345, 42.614075],
              [-2.945214, 42.603886],
            ],
            [
              [-2.998976, 42.601766],
              [-2.986248, 42.607051],
              [-2.985222, 42.61422],
              [-2.994525, 42.615491],
              [-2.998976, 42.601766],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5605",
      properties: {
        name: "Las Palmas",
        density: 0,
        path: "/world/Spain/Las Palmas",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-13.890506, 28.756853],
              [-13.836324, 28.712746],
              [-13.824298, 28.552822],
              [-13.867233, 28.480298],
              [-13.849884, 28.401132],
              [-13.917353, 28.25195],
              [-14.012607, 28.207694],
              [-14.218269, 28.165583],
              [-14.33159, 28.044622],
              [-14.477795, 28.07747],
              [-14.507192, 28.064071],
              [-14.491428, 28.110908],
              [-14.450234, 28.100089],
              [-14.372972, 28.117134],
              [-14.222542, 28.215121],
              [-14.203695, 28.328393],
              [-14.162317, 28.368689],
              [-14.147239, 28.438396],
              [-14.100506, 28.475284],
              [-14.033462, 28.592999],
              [-14.038761, 28.615067],
              [-14.008923, 28.677541],
              [-14.021036, 28.70728],
              [-13.976713, 28.73524],
              [-13.890506, 28.756853],
            ],
            [
              [-14.032065, 28.625241],
              [-14.032302, 28.625485],
              [-14.032513, 28.625139],
              [-14.032183, 28.62509],
              [-14.032065, 28.625241],
            ],
            [
              [-14.474877, 28.104689],
              [-14.474616, 28.104875],
              [-14.474628, 28.105053],
              [-14.47498, 28.104894],
              [-14.474877, 28.104689],
            ],
            [
              [-14.476613, 28.104082],
              [-14.476784, 28.10422],
              [-14.476972, 28.104126],
              [-14.476725, 28.103789],
              [-14.476613, 28.104082],
            ],
            [
              [-14.211879, 28.267828],
              [-14.211715, 28.267717],
              [-14.211672, 28.267979],
              [-14.2118, 28.267999],
              [-14.211879, 28.267828],
            ],
            [
              [-14.209488, 28.259372],
              [-14.209314, 28.2595],
              [-14.209298, 28.259657],
              [-14.209467, 28.259557],
              [-14.209488, 28.259372],
            ],
          ],
          [
            [
              [-15.418887, 28.180395],
              [-15.401154, 28.166474],
              [-15.407332, 28.132754],
              [-15.406981, 28.158374],
              [-15.417573, 28.128232],
              [-15.417328, 28.146161],
              [-15.427756, 28.146687],
              [-15.411104, 28.100844],
              [-15.419754, 28.059711],
              [-15.373237, 28.009475],
              [-15.375075, 27.997702],
              [-15.370019, 27.996725],
              [-15.370692, 27.993816],
              [-15.367989, 27.99308],
              [-15.367076, 27.991512],
              [-15.367384, 27.989953],
              [-15.384327, 27.967336],
              [-15.358485, 27.931332],
              [-15.384392, 27.92702],
              [-15.385868, 27.857402],
              [-15.416329, 27.844164],
              [-15.424368, 27.80821],
              [-15.522055, 27.779744],
              [-15.563872, 27.760357],
              [-15.570757, 27.737219],
              [-15.598903, 27.734582],
              [-15.659009, 27.756678],
              [-15.674565, 27.749232],
              [-15.787641, 27.834706],
              [-15.833803, 27.910492],
              [-15.83451, 27.971069],
              [-15.822932, 28.01349],
              [-15.786358, 28.021092],
              [-15.710928, 28.083018],
              [-15.696335, 28.151597],
              [-15.709342, 28.1656],
              [-15.666559, 28.158271],
              [-15.637507, 28.171947],
              [-15.59982, 28.144379],
              [-15.526899, 28.155258],
              [-15.467414, 28.12605],
              [-15.453721, 28.127956],
              [-15.4375, 28.137887],
              [-15.431538, 28.151322],
              [-15.441492, 28.166553],
              [-15.418887, 28.180395],
            ],
            [
              [-15.794088, 28.018681],
              [-15.793853, 28.019046],
              [-15.79399, 28.019306],
              [-15.794315, 28.018734],
              [-15.794088, 28.018681],
            ],
          ],
          [
            [
              [-13.467605, 29.229402],
              [-13.423878, 29.208709],
              [-13.419483, 29.184399],
              [-13.4291, 29.154221],
              [-13.464455, 29.128035],
              [-13.447497, 29.089074],
              [-13.486096, 28.992764],
              [-13.640735, 28.920322],
              [-13.736425, 28.906739],
              [-13.78706, 28.838525],
              [-13.801821, 28.859012],
              [-13.876372, 28.858651],
              [-13.881661, 28.886172],
              [-13.829058, 28.930245],
              [-13.834412, 28.98938],
              [-13.815436, 29.033407],
              [-13.755867, 29.077828],
              [-13.706968, 29.080013],
              [-13.651536, 29.11434],
              [-13.657742, 29.123541],
              [-13.633177, 29.115852],
              [-13.601833, 29.136106],
              [-13.547632, 29.118527],
              [-13.524615, 29.14113],
              [-13.496855, 29.218234],
              [-13.467605, 29.229402],
            ],
            [
              [-13.759469, 29.072392],
              [-13.759745, 29.072477],
              [-13.759144, 29.07207],
              [-13.759285, 29.072283],
              [-13.759469, 29.072392],
            ],
            [
              [-13.759629, 29.071917],
              [-13.759854, 29.072137],
              [-13.759941, 29.072049],
              [-13.759898, 29.071961],
              [-13.759629, 29.071917],
            ],
          ],
          [
            [
              [-13.507977, 29.29077],
              [-13.471607, 29.265734],
              [-13.504755, 29.22499],
              [-13.543961, 29.218505],
              [-13.507977, 29.29077],
            ],
          ],
          [
            [
              [-13.504872, 29.415619],
              [-13.504991, 29.414342],
              [-13.50267, 29.412398],
              [-13.497693, 29.413007],
              [-13.488843, 29.405183],
              [-13.491782, 29.396848],
              [-13.528701, 29.385084],
              [-13.523958, 29.410787],
              [-13.518851, 29.410235],
              [-13.504872, 29.415619],
            ],
            [
              [-13.523411, 29.408709],
              [-13.523271, 29.408631],
              [-13.523309, 29.408731],
              [-13.523411, 29.408709],
            ],
            [
              [-13.491776, 29.406453],
              [-13.491775, 29.4065],
              [-13.491838, 29.406406],
              [-13.491795, 29.4064],
              [-13.491776, 29.406453],
            ],
            [
              [-13.49159, 29.406704],
              [-13.49158, 29.406638],
              [-13.491538, 29.406642],
              [-13.49154, 29.406689],
              [-13.49159, 29.406704],
            ],
            [
              [-13.491359, 29.39848],
              [-13.491323, 29.398414],
              [-13.491274, 29.39843],
              [-13.491359, 29.39848],
            ],
            [
              [-13.523551, 29.408817],
              [-13.523538, 29.408765],
              [-13.523517, 29.40876],
              [-13.523513, 29.408786],
              [-13.523551, 29.408817],
            ],
            [
              [-13.49122, 29.406031],
              [-13.491203, 29.406065],
              [-13.49126, 29.406031],
              [-13.49122, 29.406031],
            ],
            [
              [-13.523621, 29.408907],
              [-13.523621, 29.408866],
              [-13.523592, 29.40887],
              [-13.523621, 29.408907],
            ],
          ],
          [
            [
              [-13.812827, 28.765188],
              [-13.815701, 28.736589],
              [-13.8308, 28.739642],
              [-13.835743, 28.752532],
              [-13.812827, 28.765188],
            ],
          ],
          [
            [
              [-13.534034, 29.303695],
              [-13.531326, 29.290433],
              [-13.539074, 29.289082],
              [-13.540274, 29.302583],
              [-13.534034, 29.303695],
            ],
          ],
          [
            [
              [-13.336191, 29.27732],
              [-13.333806, 29.276377],
              [-13.338025, 29.27369],
              [-13.338519, 29.274807],
              [-13.336191, 29.27732],
            ],
          ],
          [
            [
              [-13.440538, 29.222633],
              [-13.439732, 29.222119],
              [-13.439474, 29.21952],
              [-13.441212, 29.221492],
              [-13.440538, 29.222633],
            ],
          ],
          [
            [
              [-13.527221, 29.310191],
              [-13.527645, 29.31099],
              [-13.526745, 29.311879],
              [-13.526307, 29.311186],
              [-13.527221, 29.310191],
            ],
          ],
          [
            [
              [-13.547586, 28.953833],
              [-13.546087, 28.953606],
              [-13.548143, 28.952834],
              [-13.550272, 28.952402],
              [-13.547586, 28.953833],
            ],
          ],
          [
            [
              [-13.548275, 28.956865],
              [-13.547358, 28.95623],
              [-13.548805, 28.955615],
              [-13.54915, 28.956358],
              [-13.548275, 28.956865],
            ],
          ],
          [
            [
              [-13.470295, 29.238849],
              [-13.470909, 29.23901],
              [-13.470531, 29.240544],
              [-13.469939, 29.239698],
              [-13.470295, 29.238849],
            ],
          ],
          [
            [
              [-13.487244, 29.403379],
              [-13.487014, 29.403031],
              [-13.486243, 29.403422],
              [-13.48699, 29.402451],
              [-13.487244, 29.403379],
            ],
          ],
          [
            [
              [-13.51587, 29.413213],
              [-13.515383, 29.412912],
              [-13.514735, 29.412909],
              [-13.515739, 29.412613],
              [-13.516557, 29.413354],
              [-13.51587, 29.413213],
            ],
          ],
          [
            [
              [-15.438952, 28.140178],
              [-15.438234, 28.141537],
              [-15.437436, 28.14212],
              [-15.437866, 28.141268],
              [-15.438952, 28.140178],
            ],
          ],
          [
            [
              [-15.437136, 28.142433],
              [-15.437039, 28.143042],
              [-15.43567, 28.144748],
              [-15.436253, 28.143667],
              [-15.437136, 28.142433],
            ],
          ],
          [
            [
              [-15.381994, 27.861779],
              [-15.381287, 27.861799],
              [-15.381287, 27.861574],
              [-15.381664, 27.86119],
              [-15.381994, 27.861779],
            ],
          ],
          [
            [
              [-13.518444, 29.411862],
              [-13.518148, 29.411768],
              [-13.519115, 29.41134],
              [-13.518646, 29.411902],
              [-13.518444, 29.411862],
            ],
          ],
          [
            [
              [-13.522755, 29.155724],
              [-13.522285, 29.155732],
              [-13.522609, 29.155242],
              [-13.523419, 29.155534],
              [-13.522755, 29.155724],
            ],
          ],
          [
            [
              [-13.829255, 28.756354],
              [-13.829629, 28.756597],
              [-13.829913, 28.757134],
              [-13.829367, 28.756828],
              [-13.829255, 28.756354],
            ],
          ],
          [
            [
              [-13.523173, 29.157981],
              [-13.523766, 29.158075],
              [-13.523887, 29.158362],
              [-13.522965, 29.15804],
              [-13.523173, 29.157981],
            ],
          ],
          [
            [
              [-13.511763, 29.415708],
              [-13.511348, 29.415654],
              [-13.511333, 29.414966],
              [-13.511785, 29.415512],
              [-13.511763, 29.415708],
            ],
          ],
          [
            [
              [-15.496179, 28.146526],
              [-15.496382, 28.146338],
              [-15.496323, 28.147014],
              [-15.496129, 28.146713],
              [-15.496179, 28.146526],
            ],
          ],
          [
            [
              [-14.070275, 28.539993],
              [-14.070328, 28.539979],
              [-14.069624, 28.540986],
              [-14.069875, 28.540279],
              [-14.070275, 28.539993],
            ],
          ],
          [
            [
              [-13.516302, 29.412998],
              [-13.516084, 29.4129],
              [-13.515974, 29.412683],
              [-13.516602, 29.412806],
              [-13.516302, 29.412998],
            ],
          ],
          [
            [
              [-14.025759, 28.642476],
              [-14.025995, 28.642232],
              [-14.026282, 28.642361],
              [-14.025609, 28.642735],
              [-14.025759, 28.642476],
            ],
          ],
          [
            [
              [-14.070422, 28.537056],
              [-14.070254, 28.53714],
              [-14.069864, 28.537029],
              [-14.070235, 28.536832],
              [-14.070422, 28.537056],
            ],
          ],
          [
            [
              [-13.518843, 29.412434],
              [-13.518659, 29.412349],
              [-13.518814, 29.411942],
              [-13.519126, 29.412197],
              [-13.518843, 29.412434],
            ],
          ],
          [
            [
              [-13.525751, 29.406543],
              [-13.52578, 29.406189],
              [-13.525723, 29.4061],
              [-13.526082, 29.406366],
              [-13.525751, 29.406543],
            ],
          ],
          [
            [
              [-13.526606, 29.405294],
              [-13.526943, 29.405351],
              [-13.526704, 29.405653],
              [-13.526514, 29.405496],
              [-13.526606, 29.405294],
            ],
          ],
          [
            [
              [-13.503622, 29.416351],
              [-13.50334, 29.416272],
              [-13.503464, 29.415947],
              [-13.503664, 29.416037],
              [-13.503622, 29.416351],
            ],
          ],
          [
            [
              [-14.066002, 28.547033],
              [-14.06612, 28.547401],
              [-14.065787, 28.547401],
              [-14.065874, 28.547192],
              [-14.066002, 28.547033],
            ],
          ],
          [
            [
              [-15.443094, 28.136081],
              [-15.443229, 28.136065],
              [-15.442506, 28.136546],
              [-15.4427, 28.136248],
              [-15.443094, 28.136081],
            ],
          ],
          [
            [
              [-13.547387, 28.957617],
              [-13.547605, 28.957672],
              [-13.547475, 28.957998],
              [-13.547351, 28.957943],
              [-13.547387, 28.957617],
            ],
          ],
          [
            [
              [-14.101983, 28.474442],
              [-14.102049, 28.474688],
              [-14.101865, 28.474982],
              [-14.101874, 28.474758],
              [-14.101983, 28.474442],
            ],
          ],
          [
            [
              [-13.522223, 29.411862],
              [-13.522083, 29.411744],
              [-13.521906, 29.411694],
              [-13.522358, 29.411718],
              [-13.522223, 29.411862],
            ],
          ],
          [
            [
              [-14.033552, 28.63146],
              [-14.033877, 28.631449],
              [-14.033924, 28.631598],
              [-14.033561, 28.631722],
              [-14.033552, 28.63146],
            ],
          ],
          [
            [
              [-13.544282, 28.953234],
              [-13.544397, 28.953198],
              [-13.544537, 28.953451],
              [-13.544309, 28.953477],
              [-13.544282, 28.953234],
            ],
          ],
          [
            [
              [-14.15833, 28.411812],
              [-14.158576, 28.411854],
              [-14.158216, 28.412075],
              [-14.158246, 28.411901],
              [-14.15833, 28.411812],
            ],
          ],
          [
            [
              [-13.504723, 29.415244],
              [-13.504844, 29.415292],
              [-13.504518, 29.415791],
              [-13.504485, 29.415745],
              [-13.504723, 29.415244],
            ],
          ],
          [
            [
              [-13.519191, 29.411964],
              [-13.519287, 29.411856],
              [-13.519469, 29.412106],
              [-13.519222, 29.412078],
              [-13.519191, 29.411964],
            ],
          ],
          [
            [
              [-14.15894, 28.408255],
              [-14.159042, 28.408406],
              [-14.158857, 28.408672],
              [-14.1588, 28.408606],
              [-14.15894, 28.408255],
            ],
          ],
          [
            [
              [-14.033151, 28.631705],
              [-14.032924, 28.631698],
              [-14.03292, 28.631404],
              [-14.033089, 28.631453],
              [-14.033151, 28.631705],
            ],
          ],
          [
            [
              [-13.524194, 29.410616],
              [-13.524024, 29.410496],
              [-13.524436, 29.410595],
              [-13.524078, 29.41082],
              [-13.524194, 29.410616],
            ],
          ],
          [
            [
              [-14.026903, 28.641064],
              [-14.027214, 28.641115],
              [-14.027233, 28.641159],
              [-14.026898, 28.641214],
              [-14.026903, 28.641064],
            ],
          ],
          [
            [
              [-14.1588, 28.411334],
              [-14.158753, 28.411049],
              [-14.158886, 28.410862],
              [-14.158902, 28.4112],
              [-14.1588, 28.411334],
            ],
          ],
          [
            [
              [-13.525027, 29.409232],
              [-13.524884, 29.409233],
              [-13.524796, 29.409027],
              [-13.525074, 29.409105],
              [-13.525027, 29.409232],
            ],
          ],
          [
            [
              [-13.531728, 29.40075],
              [-13.53157, 29.400796],
              [-13.531448, 29.400632],
              [-13.531597, 29.40056],
              [-13.531728, 29.40075],
            ],
          ],
          [
            [
              [-15.668859, 28.159242],
              [-15.669048, 28.159244],
              [-15.669087, 28.159426],
              [-15.668864, 28.159405],
              [-15.668859, 28.159242],
            ],
          ],
          [
            [
              [-14.161388, 28.392573],
              [-14.161486, 28.392562],
              [-14.161322, 28.392884],
              [-14.161266, 28.392666],
              [-14.161388, 28.392573],
            ],
          ],
          [
            [
              [-15.440455, 28.138204],
              [-15.440604, 28.138252],
              [-15.440317, 28.138454],
              [-15.440306, 28.138407],
              [-15.440455, 28.138204],
            ],
          ],
          [
            [
              [-13.525574, 29.40694],
              [-13.525612, 29.406899],
              [-13.525851, 29.406928],
              [-13.525758, 29.407089],
              [-13.525574, 29.40694],
            ],
          ],
          [
            [
              [-13.524631, 29.410399],
              [-13.524492, 29.41035],
              [-13.524483, 29.410258],
              [-13.524738, 29.410288],
              [-13.524631, 29.410399],
            ],
          ],
          [
            [
              [-13.606335, 29.133811],
              [-13.606533, 29.133857],
              [-13.606509, 29.133993],
              [-13.606327, 29.133937],
              [-13.606335, 29.133811],
            ],
          ],
          [
            [
              [-13.522331, 29.41153],
              [-13.522269, 29.411619],
              [-13.521921, 29.411551],
              [-13.522336, 29.411353],
              [-13.522331, 29.41153],
            ],
          ],
          [
            [
              [-14.069362, 28.541419],
              [-14.069474, 28.541498],
              [-14.069336, 28.541724],
              [-14.069315, 28.541526],
              [-14.069362, 28.541419],
            ],
          ],
          [
            [
              [-15.441506, 28.137338],
              [-15.441616, 28.13739],
              [-15.441392, 28.137608],
              [-15.441421, 28.137415],
              [-15.441506, 28.137338],
            ],
          ],
          [
            [
              [-14.087147, 28.506163],
              [-14.087356, 28.506122],
              [-14.087155, 28.506387],
              [-14.087116, 28.506298],
              [-14.087147, 28.506163],
            ],
          ],
          [
            [
              [-15.441999, 28.136947],
              [-15.442136, 28.137001],
              [-15.441848, 28.137165],
              [-15.441855, 28.137075],
              [-15.441999, 28.136947],
            ],
          ],
          [
            [
              [-13.518473, 29.411334],
              [-13.518305, 29.41126],
              [-13.51833, 29.41117],
              [-13.518561, 29.411278],
              [-13.518473, 29.411334],
            ],
          ],
          [
            [
              [-13.522441, 29.411448],
              [-13.522459, 29.411315],
              [-13.522429, 29.411628],
              [-13.522415, 29.411596],
              [-13.522441, 29.411448],
            ],
          ],
          [
            [
              [-14.089869, 28.49838],
              [-14.089813, 28.498262],
              [-14.089836, 28.498204],
              [-14.089979, 28.498362],
              [-14.089869, 28.49838],
            ],
          ],
          [
            [
              [-14.06883, 28.541299],
              [-14.068928, 28.541403],
              [-14.068858, 28.541565],
              [-14.068799, 28.541505],
              [-14.06883, 28.541299],
            ],
          ],
          [
            [
              [-13.511104, 29.415545],
              [-13.511211, 29.415644],
              [-13.511155, 29.415722],
              [-13.511035, 29.415689],
              [-13.511104, 29.415545],
            ],
          ],
          [
            [
              [-13.794157, 29.050534],
              [-13.794294, 29.050548],
              [-13.794285, 29.050688],
              [-13.794195, 29.050643],
              [-13.794157, 29.050534],
            ],
          ],
          [
            [
              [-13.504099, 29.416334],
              [-13.50387, 29.41634],
              [-13.503858, 29.416159],
              [-13.504082, 29.416284],
              [-13.504099, 29.416334],
            ],
          ],
          [
            [
              [-13.524189, 29.410462],
              [-13.524143, 29.410286],
              [-13.524337, 29.410356],
              [-13.524246, 29.410477],
              [-13.524189, 29.410462],
            ],
          ],
          [
            [
              [-13.515001, 29.412596],
              [-13.515262, 29.412551],
              [-13.515316, 29.412563],
              [-13.515078, 29.412671],
              [-13.515001, 29.412596],
            ],
          ],
          [
            [
              [-13.524535, 29.146522],
              [-13.524264, 29.146542],
              [-13.524256, 29.1465],
              [-13.524499, 29.146446],
              [-13.524535, 29.146522],
            ],
          ],
          [
            [
              [-15.443627, 28.135346],
              [-15.443775, 28.135418],
              [-15.443817, 28.135554],
              [-15.443629, 28.135421],
              [-15.443627, 28.135346],
            ],
          ],
          [
            [
              [-15.496637, 28.145384],
              [-15.496461, 28.145423],
              [-15.496365, 28.145404],
              [-15.496622, 28.145268],
              [-15.496637, 28.145384],
            ],
          ],
          [
            [
              [-13.522565, 29.41155],
              [-13.522554, 29.411398],
              [-13.522629, 29.411575],
              [-13.522455, 29.411765],
              [-13.522565, 29.41155],
            ],
          ],
          [
            [
              [-14.089703, 28.498334],
              [-14.08978, 28.498315],
              [-14.08963, 28.498497],
              [-14.089596, 28.498376],
              [-14.089703, 28.498334],
            ],
          ],
          [
            [
              [-13.526589, 29.406751],
              [-13.526507, 29.406694],
              [-13.526615, 29.406688],
              [-13.526756, 29.406932],
              [-13.526589, 29.406751],
            ],
          ],
          [
            [
              [-14.08694, 28.506263],
              [-14.087032, 28.506363],
              [-14.086947, 28.506446],
              [-14.086911, 28.506308],
              [-14.08694, 28.506263],
            ],
          ],
          [
            [
              [-15.439705, 28.139077],
              [-15.43981, 28.139166],
              [-15.439614, 28.139206],
              [-15.43967, 28.139122],
              [-15.439705, 28.139077],
            ],
          ],
          [
            [
              [-15.444323, 28.13546],
              [-15.444434, 28.135501],
              [-15.444387, 28.135624],
              [-15.444318, 28.135546],
              [-15.444323, 28.13546],
            ],
          ],
          [
            [
              [-15.444844, 28.13513],
              [-15.444726, 28.135191],
              [-15.444648, 28.135194],
              [-15.444825, 28.135065],
              [-15.444844, 28.13513],
            ],
          ],
          [
            [
              [-15.496963, 28.146727],
              [-15.497097, 28.146778],
              [-15.497098, 28.146866],
              [-15.496961, 28.146803],
              [-15.496963, 28.146727],
            ],
          ],
          [
            [
              [-15.496763, 28.146732],
              [-15.496652, 28.146763],
              [-15.496685, 28.146602],
              [-15.496756, 28.146676],
              [-15.496763, 28.146732],
            ],
          ],
          [
            [
              [-15.442267, 28.136825],
              [-15.44232, 28.136869],
              [-15.442177, 28.136971],
              [-15.442206, 28.136831],
              [-15.442267, 28.136825],
            ],
          ],
          [
            [
              [-13.486906, 29.403393],
              [-13.486972, 29.403377],
              [-13.486993, 29.4035],
              [-13.48689, 29.403484],
              [-13.486906, 29.403393],
            ],
          ],
          [
            [
              [-13.521632, 29.411977],
              [-13.521701, 29.411843],
              [-13.521747, 29.411875],
              [-13.521673, 29.412007],
              [-13.521632, 29.411977],
            ],
          ],
          [
            [
              [-13.526459, 29.405721],
              [-13.526397, 29.405743],
              [-13.526301, 29.405696],
              [-13.526487, 29.405679],
              [-13.526459, 29.405721],
            ],
          ],
          [
            [
              [-14.033339, 28.631803],
              [-14.033271, 28.631744],
              [-14.033282, 28.631611],
              [-14.033325, 28.631629],
              [-14.033339, 28.631803],
            ],
          ],
          [
            [
              [-13.527458, 29.404566],
              [-13.527413, 29.4046],
              [-13.52725, 29.404498],
              [-13.527366, 29.404525],
              [-13.527458, 29.404566],
            ],
          ],
          [
            [
              [-14.158783, 28.407693],
              [-14.158686, 28.407686],
              [-14.158688, 28.407592],
              [-14.158776, 28.407618],
              [-14.158783, 28.407693],
            ],
          ],
          [
            [
              [-13.511758, 29.385057],
              [-13.511712, 29.384961],
              [-13.511768, 29.384945],
              [-13.511809, 29.384978],
              [-13.511758, 29.385057],
            ],
          ],
          [
            [
              [-13.502292, 29.390166],
              [-13.502196, 29.390215],
              [-13.502169, 29.390171],
              [-13.502194, 29.390127],
              [-13.502292, 29.390166],
            ],
          ],
          [
            [
              [-13.524716, 29.409126],
              [-13.524669, 29.40915],
              [-13.524599, 29.409087],
              [-13.524672, 29.409046],
              [-13.524716, 29.409126],
            ],
          ],
          [
            [
              [-13.492515, 29.39618],
              [-13.492462, 29.396084],
              [-13.492506, 29.396052],
              [-13.492547, 29.396088],
              [-13.492515, 29.39618],
            ],
          ],
          [
            [
              [-13.499326, 29.391784],
              [-13.499261, 29.391776],
              [-13.499349, 29.391671],
              [-13.49937, 29.391719],
              [-13.499326, 29.391784],
            ],
          ],
          [
            [
              [-13.492002, 29.396414],
              [-13.492089, 29.396476],
              [-13.49209, 29.396522],
              [-13.492002, 29.396464],
              [-13.492002, 29.396414],
            ],
          ],
          [
            [
              [-13.501478, 29.414185],
              [-13.501541, 29.41418],
              [-13.501518, 29.414269],
              [-13.501468, 29.414247],
              [-13.501478, 29.414185],
            ],
          ],
          [
            [
              [-13.486998, 29.40328],
              [-13.486924, 29.403262],
              [-13.48692, 29.403216],
              [-13.48697, 29.403195],
              [-13.486998, 29.40328],
            ],
          ],
          [
            [
              [-13.502289, 29.390219],
              [-13.502326, 29.390181],
              [-13.502374, 29.390238],
              [-13.502243, 29.390239],
              [-13.502289, 29.390219],
            ],
          ],
          [
            [
              [-13.501725, 29.390877],
              [-13.50162, 29.390818],
              [-13.501649, 29.390781],
              [-13.501677, 29.390792],
              [-13.501725, 29.390877],
            ],
          ],
          [
            [
              [-13.515759, 29.412336],
              [-13.515799, 29.412329],
              [-13.515835, 29.412424],
              [-13.515784, 29.412429],
              [-13.515759, 29.412336],
            ],
          ],
          [
            [
              [-15.496045, 28.146764],
              [-15.49594, 28.146768],
              [-15.495939, 28.14672],
              [-15.496045, 28.146714],
              [-15.496045, 28.146764],
            ],
          ],
          [
            [
              [-13.501235, 29.39081],
              [-13.501133, 29.390828],
              [-13.501105, 29.39078],
              [-13.501212, 29.390782],
              [-13.501235, 29.39081],
            ],
          ],
          [
            [
              [-13.510584, 29.384785],
              [-13.510648, 29.384719],
              [-13.51054, 29.384872],
              [-13.510553, 29.384828],
              [-13.510584, 29.384785],
            ],
          ],
          [
            [
              [-13.496492, 29.392421],
              [-13.496405, 29.392406],
              [-13.496378, 29.392308],
              [-13.496426, 29.392347],
              [-13.496492, 29.392421],
            ],
          ],
          [
            [
              [-13.500991, 29.390849],
              [-13.500958, 29.390846],
              [-13.500945, 29.390774],
              [-13.500995, 29.390766],
              [-13.500991, 29.390849],
            ],
          ],
          [
            [
              [-13.501837, 29.390533],
              [-13.501739, 29.390495],
              [-13.501831, 29.390471],
              [-13.501837, 29.390533],
            ],
          ],
          [
            [
              [-13.496592, 29.392396],
              [-13.496559, 29.392312],
              [-13.496609, 29.392299],
              [-13.49661, 29.392351],
              [-13.496592, 29.392396],
            ],
          ],
          [
            [
              [-13.526735, 29.407009],
              [-13.5267, 29.407042],
              [-13.526632, 29.407003],
              [-13.526639, 29.406972],
              [-13.526735, 29.407009],
            ],
          ],
          [
            [
              [-13.502403, 29.390067],
              [-13.502335, 29.390124],
              [-13.502305, 29.390085],
              [-13.502335, 29.390049],
              [-13.502403, 29.390067],
            ],
          ],
          [
            [
              [-13.497699, 29.392618],
              [-13.497774, 29.392618],
              [-13.497787, 29.392662],
              [-13.497689, 29.392642],
              [-13.497699, 29.392618],
            ],
          ],
          [
            [
              [-13.502112, 29.390232],
              [-13.502065, 29.390302],
              [-13.502026, 29.390271],
              [-13.502049, 29.390231],
              [-13.502112, 29.390232],
            ],
          ],
          [
            [
              [-13.497383, 29.392847],
              [-13.497332, 29.39286],
              [-13.497306, 29.392829],
              [-13.497383, 29.392797],
              [-13.497383, 29.392847],
            ],
          ],
          [
            [
              [-13.498926, 29.391883],
              [-13.498904, 29.391924],
              [-13.498859, 29.391916],
              [-13.498915, 29.391837],
              [-13.498926, 29.391883],
            ],
          ],
          [
            [
              [-13.500509, 29.391154],
              [-13.500484, 29.391196],
              [-13.500438, 29.391193],
              [-13.500474, 29.391121],
              [-13.500509, 29.391154],
            ],
          ],
          [
            [
              [-13.508924, 29.385141],
              [-13.508931, 29.385126],
              [-13.509001, 29.385142],
              [-13.50897, 29.385186],
              [-13.508924, 29.385141],
            ],
          ],
          [
            [
              [-13.492229, 29.396467],
              [-13.492139, 29.396457],
              [-13.492132, 29.39641],
              [-13.492189, 29.396405],
              [-13.492229, 29.396467],
            ],
          ],
          [
            [
              [-13.511911, 29.38519],
              [-13.511951, 29.38519],
              [-13.51193, 29.385272],
              [-13.511893, 29.385237],
              [-13.511911, 29.38519],
            ],
          ],
          [
            [
              [-13.508802, 29.385029],
              [-13.508759, 29.38504],
              [-13.508746, 29.384974],
              [-13.508802, 29.384984],
              [-13.508802, 29.385029],
            ],
          ],
          [
            [
              [-13.502274, 29.390404],
              [-13.502232, 29.390429],
              [-13.502211, 29.390351],
              [-13.502274, 29.390404],
            ],
          ],
          [
            [
              [-13.496444, 29.392494],
              [-13.496451, 29.392479],
              [-13.496489, 29.392555],
              [-13.496436, 29.392557],
              [-13.496444, 29.392494],
            ],
          ],
          [
            [
              [-13.497934, 29.392512],
              [-13.497928, 29.392558],
              [-13.497846, 29.39254],
              [-13.497934, 29.392512],
            ],
          ],
          [
            [
              [-13.499965, 29.391544],
              [-13.499949, 29.391587],
              [-13.499904, 29.391586],
              [-13.499903, 29.391546],
              [-13.499965, 29.391544],
            ],
          ],
          [
            [
              [-13.524909, 29.408949],
              [-13.524812, 29.408979],
              [-13.524807, 29.408947],
              [-13.524909, 29.408949],
            ],
          ],
          [
            [
              [-13.501924, 29.390542],
              [-13.501953, 29.390531],
              [-13.502, 29.390536],
              [-13.501955, 29.390569],
              [-13.501924, 29.390542],
            ],
          ],
          [
            [
              [-13.492405, 29.39603],
              [-13.492426, 29.396025],
              [-13.492406, 29.396116],
              [-13.492386, 29.396071],
              [-13.492405, 29.39603],
            ],
          ],
          [
            [
              [-13.526298, 29.406354],
              [-13.526246, 29.406354],
              [-13.526241, 29.406291],
              [-13.526298, 29.406354],
            ],
          ],
          [
            [
              [-13.501939, 29.390655],
              [-13.501972, 29.390647],
              [-13.501986, 29.390704],
              [-13.501938, 29.390681],
              [-13.501939, 29.390655],
            ],
          ],
          [
            [
              [-13.498272, 29.391962],
              [-13.498228, 29.391969],
              [-13.498266, 29.391912],
              [-13.498272, 29.391962],
            ],
          ],
          [
            [
              [-13.50198, 29.390765],
              [-13.501908, 29.39078],
              [-13.501905, 29.390748],
              [-13.50198, 29.390765],
            ],
          ],
          [
            [
              [-13.516612, 29.412321],
              [-13.516586, 29.412356],
              [-13.51655, 29.412324],
              [-13.516612, 29.412321],
            ],
          ],
          [
            [
              [-13.498034, 29.391999],
              [-13.497978, 29.391933],
              [-13.498028, 29.391951],
              [-13.498034, 29.391999],
            ],
          ],
          [
            [
              [-13.492519, 29.396242],
              [-13.492538, 29.396235],
              [-13.492469, 29.396309],
              [-13.492489, 29.396263],
              [-13.492519, 29.396242],
            ],
          ],
          [
            [
              [-13.509689, 29.385207],
              [-13.509654, 29.385225],
              [-13.509666, 29.385165],
              [-13.509689, 29.385207],
            ],
          ],
          [
            [
              [-13.497952, 29.392392],
              [-13.497946, 29.392445],
              [-13.497933, 29.392462],
              [-13.49792, 29.392445],
              [-13.497952, 29.392392],
            ],
          ],
          [
            [
              [-13.518402, 29.411081],
              [-13.518466, 29.411079],
              [-13.518387, 29.41111],
              [-13.518402, 29.411081],
            ],
          ],
          [
            [
              [-13.502157, 29.390271],
              [-13.50216, 29.390254],
              [-13.502203, 29.3903],
              [-13.502152, 29.390283],
              [-13.502157, 29.390271],
            ],
          ],
          [
            [
              [-13.498355, 29.391774],
              [-13.498291, 29.391758],
              [-13.498336, 29.391753],
              [-13.498355, 29.391774],
            ],
          ],
          [
            [
              [-13.497603, 29.392672],
              [-13.497583, 29.392635],
              [-13.497595, 29.392612],
              [-13.497603, 29.392672],
            ],
          ],
          [
            [
              [-13.499276, 29.391792],
              [-13.499276, 29.391792],
              [-13.499275, 29.391792],
              [-13.499276, 29.391792],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5632",
      properties: { name: "León", density: 0, path: "/world/Spain/León" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.893577, 43.238479],
              [-4.839201, 43.18976],
              [-4.851778, 43.17307],
              [-4.834567, 43.157463],
              [-4.851426, 43.125945],
              [-4.832806, 43.102661],
              [-4.734216, 43.047071],
              [-4.738077, 43.019008],
              [-4.794815, 42.986651],
              [-4.809234, 42.930341],
              [-4.855501, 42.891707],
              [-4.865585, 42.860548],
              [-4.898371, 42.863134],
              [-4.895878, 42.785172],
              [-4.93556, 42.756457],
              [-4.915892, 42.72056],
              [-4.871669, 42.70799],
              [-4.900295, 42.67012],
              [-4.894656, 42.623864],
              [-4.915952, 42.554754],
              [-4.887987, 42.537028],
              [-4.899999, 42.450682],
              [-4.923985, 42.436143],
              [-4.904985, 42.424555],
              [-4.914315, 42.401192],
              [-4.903396, 42.385651],
              [-4.972553, 42.374385],
              [-4.984205, 42.337874],
              [-4.941159, 42.341736],
              [-4.953448, 42.297156],
              [-5.03835, 42.297127],
              [-5.053271, 42.257929],
              [-5.093754, 42.309295],
              [-5.112002, 42.311842],
              [-5.18272, 42.26421],
              [-5.236499, 42.273349],
              [-5.254001, 42.239775],
              [-5.225994, 42.226724],
              [-5.241483, 42.214517],
              [-5.265644, 42.210899],
              [-5.286925, 42.229928],
              [-5.307462, 42.199266],
              [-5.361234, 42.225152],
              [-5.388102, 42.221443],
              [-5.371524, 42.130064],
              [-5.390117, 42.08981],
              [-5.376294, 42.041466],
              [-5.438676, 42.031502],
              [-5.480923, 42.052425],
              [-5.527004, 42.1043],
              [-5.543363, 42.099381],
              [-5.560698, 42.055968],
              [-5.577722, 42.065448],
              [-5.586607, 42.050911],
              [-5.588502, 42.077326],
              [-5.635043, 42.081994],
              [-5.620137, 42.106659],
              [-5.667556, 42.142065],
              [-5.687177, 42.137924],
              [-5.676768, 42.120336],
              [-5.742948, 42.113342],
              [-5.755193, 42.136625],
              [-5.776707, 42.135809],
              [-5.844851, 42.110716],
              [-5.897426, 42.156101],
              [-5.911601, 42.140585],
              [-5.948121, 42.141921],
              [-5.953468, 42.123683],
              [-6.049305, 42.159861],
              [-6.160213, 42.165772],
              [-6.212395, 42.148077],
              [-6.293732, 42.178033],
              [-6.312585, 42.206585],
              [-6.427895, 42.201942],
              [-6.497561, 42.182025],
              [-6.526001, 42.203359],
              [-6.719699, 42.213351],
              [-6.734845, 42.235603],
              [-6.784318, 42.253618],
              [-6.741146, 42.298663],
              [-6.733953, 42.359111],
              [-6.843513, 42.405846],
              [-6.814273, 42.427354],
              [-6.822768, 42.452913],
              [-6.808315, 42.468612],
              [-6.823246, 42.491282],
              [-6.860064, 42.488758],
              [-6.932196, 42.520544],
              [-6.960089, 42.517763],
              [-6.978542, 42.493253],
              [-7.077054, 42.50819],
              [-7.059853, 42.529321],
              [-7.069671, 42.553949],
              [-7.033562, 42.58409],
              [-7.05017, 42.597311],
              [-7.047005, 42.6327],
              [-7.004357, 42.642872],
              [-7.045192, 42.694765],
              [-7.010888, 42.723661],
              [-6.972884, 42.725005],
              [-6.959483, 42.75503],
              [-6.935603, 42.740863],
              [-6.86743, 42.787174],
              [-6.840321, 42.823365],
              [-6.871279, 42.873841],
              [-6.833742, 42.882057],
              [-6.835411, 42.873627],
              [-6.814892, 42.87082],
              [-6.847994, 42.914325],
              [-6.776929, 42.901433],
              [-6.771515, 42.882551],
              [-6.723192, 42.917378],
              [-6.689905, 42.922464],
              [-6.48977, 42.920249],
              [-6.433102, 42.939757],
              [-6.433638, 42.96416],
              [-6.473011, 42.967752],
              [-6.478136, 42.991205],
              [-6.424081, 42.987875],
              [-6.407577, 42.999489],
              [-6.411107, 43.016932],
              [-6.394352, 43.016612],
              [-6.397047, 43.038322],
              [-6.359088, 43.059577],
              [-6.326493, 43.03133],
              [-6.285191, 43.036369],
              [-6.226808, 43.008913],
              [-6.21556, 43.0472],
              [-6.178401, 43.047798],
              [-6.136667, 43.020993],
              [-6.096901, 43.03064],
              [-6.081878, 43.075716],
              [-6.020214, 43.054878],
              [-5.975035, 43.065418],
              [-5.956745, 43.018593],
              [-5.914618, 42.992698],
              [-5.804006, 42.962615],
              [-5.763858, 42.97013],
              [-5.728376, 43.042513],
              [-5.693349, 43.056481],
              [-5.570142, 43.022731],
              [-5.493752, 43.021351],
              [-5.478523, 43.054909],
              [-5.390451, 43.049337],
              [-5.369136, 43.094577],
              [-5.282121, 43.077428],
              [-5.216597, 43.084488],
              [-5.147803, 43.115119],
              [-5.101735, 43.101846],
              [-5.075859, 43.181532],
              [-4.996858, 43.173097],
              [-4.963916, 43.21244],
              [-4.893577, 43.238479],
            ],
            [
              [-5.341366, 42.26876],
              [-5.339613, 42.23189],
              [-5.303324, 42.251997],
              [-5.306439, 42.271585],
              [-5.341366, 42.26876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5633",
      properties: { name: "Lleida", density: 0, path: "/world/Spain/Lleida" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.716037, 42.858321],
              [0.855523, 42.826837],
              [0.926413, 42.789312],
              [0.960161, 42.805903],
              [0.983896, 42.786703],
              [1.078887, 42.788336],
              [1.166144, 42.708916],
              [1.229105, 42.727756],
              [1.252718, 42.714465],
              [1.357325, 42.719722],
              [1.351079, 42.702718],
              [1.389244, 42.687025],
              [1.38744, 42.668691],
              [1.413659, 42.655599],
              [1.443022, 42.602761],
              [1.426482, 42.582802],
              [1.444947, 42.568013],
              [1.413606, 42.536624],
              [1.450545, 42.539892],
              [1.468366, 42.510016],
              [1.419193, 42.484195],
              [1.441192, 42.475357],
              [1.444393, 42.441424],
              [1.551675, 42.432514],
              [1.561074, 42.458646],
              [1.579195, 42.449719],
              [1.59737, 42.466323],
              [1.657021, 42.468216],
              [1.669978, 42.504189],
              [1.70361, 42.489802],
              [1.725622, 42.504373],
              [1.731921, 42.440383],
              [1.801545, 42.424921],
              [1.806038, 42.37009],
              [1.829989, 42.381406],
              [1.855058, 42.369648],
              [1.834703, 42.355145],
              [1.842481, 42.306308],
              [1.670307, 42.281697],
              [1.69014, 42.277105],
              [1.684603, 42.256438],
              [1.704563, 42.238818],
              [1.68039, 42.224361],
              [1.680348, 42.206238],
              [1.738041, 42.189419],
              [1.739068, 42.176529],
              [1.698555, 42.135721],
              [1.722051, 42.091087],
              [1.691296, 42.087103],
              [1.670234, 42.044423],
              [1.692477, 42.046134],
              [1.699285, 42.028901],
              [1.665966, 42.003505],
              [1.655855, 42.020915],
              [1.647856, 42.000154],
              [1.697437, 41.985184],
              [1.697741, 42.014685],
              [1.719227, 41.984468],
              [1.682746, 41.967383],
              [1.669455, 41.969906],
              [1.675291, 41.982534],
              [1.650066, 41.979662],
              [1.653005, 41.928799],
              [1.568929, 41.880016],
              [1.619122, 41.881533],
              [1.630307, 41.85878],
              [1.611457, 41.817772],
              [1.58881, 41.804725],
              [1.593595, 41.777928],
              [1.572583, 41.782189],
              [1.530724, 41.752563],
              [1.504835, 41.787888],
              [1.482448, 41.791007],
              [1.482588, 41.771131],
              [1.46112, 41.776686],
              [1.471083, 41.800932],
              [1.417005, 41.794628],
              [1.403024, 41.745302],
              [1.424849, 41.732775],
              [1.381923, 41.705755],
              [1.423393, 41.684119],
              [1.41504, 41.674582],
              [1.448319, 41.6361],
              [1.391674, 41.634087],
              [1.359622, 41.613538],
              [1.40664, 41.600477],
              [1.392323, 41.578318],
              [1.403447, 41.558163],
              [1.219863, 41.569583],
              [1.210764, 41.548604],
              [1.164653, 41.534456],
              [1.180946, 41.488113],
              [1.112176, 41.467013],
              [1.039751, 41.50111],
              [1.048504, 41.487543],
              [1.037455, 41.479992],
              [1.074934, 41.458397],
              [0.945229, 41.349757],
              [0.863267, 41.338113],
              [0.845003, 41.362306],
              [0.819496, 41.328828],
              [0.762477, 41.334247],
              [0.751522, 41.317837],
              [0.62819, 41.290861],
              [0.597064, 41.293449],
              [0.580777, 41.320058],
              [0.554427, 41.326666],
              [0.525808, 41.290455],
              [0.503274, 41.28973],
              [0.461263, 41.334903],
              [0.434443, 41.320209],
              [0.43315, 41.276041],
              [0.385664, 41.278844],
              [0.348436, 41.328871],
              [0.372909, 41.353479],
              [0.320036, 41.394183],
              [0.355329, 41.451759],
              [0.342024, 41.484],
              [0.396712, 41.490303],
              [0.446923, 41.542236],
              [0.428494, 41.602376],
              [0.349613, 41.599464],
              [0.357982, 41.63172],
              [0.327017, 41.67817],
              [0.384742, 41.740312],
              [0.395436, 41.731981],
              [0.398676, 41.757811],
              [0.468601, 41.764976],
              [0.483232, 41.801463],
              [0.515461, 41.822354],
              [0.543875, 41.820885],
              [0.554913, 41.85279],
              [0.580053, 41.85091],
              [0.60716, 41.871798],
              [0.592783, 41.884415],
              [0.59466, 41.917404],
              [0.607487, 41.919315],
              [0.56188, 41.938219],
              [0.586928, 41.966776],
              [0.604356, 41.960655],
              [0.61795, 41.988925],
              [0.638539, 41.994604],
              [0.641928, 41.98207],
              [0.662873, 42.012234],
              [0.65171, 42.026251],
              [0.699551, 42.106964],
              [0.695835, 42.151998],
              [0.713966, 42.163424],
              [0.698726, 42.1723],
              [0.702619, 42.19004],
              [0.764623, 42.322481],
              [0.74036, 42.336873],
              [0.771307, 42.350643],
              [0.723272, 42.36563],
              [0.746097, 42.384776],
              [0.690486, 42.487622],
              [0.73976, 42.50515],
              [0.721162, 42.524539],
              [0.749547, 42.534881],
              [0.741057, 42.544834],
              [0.767525, 42.611236],
              [0.698954, 42.628569],
              [0.695895, 42.658925],
              [0.659875, 42.688638],
              [0.676163, 42.690869],
              [0.680164, 42.723252],
              [0.642581, 42.753746],
              [0.667006, 42.775322],
              [0.645041, 42.782966],
              [0.670071, 42.801938],
              [0.65975, 42.838978],
              [0.716037, 42.858321],
            ],
            [
              [1.655261, 42.115088],
              [1.644874, 42.11262],
              [1.691872, 42.110281],
              [1.683146, 42.114429],
              [1.655261, 42.115088],
            ],
          ],
          [
            [
              [1.714279, 41.892351],
              [1.724909, 41.893029],
              [1.71875, 41.882398],
              [1.705976, 41.889388],
              [1.714279, 41.892351],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5638",
      properties: { name: "Lugo", density: 0, path: "/world/Spain/Lugo" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-7.636218, 43.752801],
              [-7.62959, 43.722128],
              [-7.606092, 43.717768],
              [-7.614371, 43.68127],
              [-7.596679, 43.67426],
              [-7.599745, 43.665167],
              [-7.596669, 43.66265],
              [-7.59432, 43.679423],
              [-7.600666, 43.681787],
              [-7.576276, 43.692995],
              [-7.584502, 43.711841],
              [-7.560427, 43.71024],
              [-7.557071, 43.729955],
              [-7.524341, 43.736803],
              [-7.514528, 43.718926],
              [-7.468237, 43.724206],
              [-7.459113, 43.716524],
              [-7.478408, 43.70879],
              [-7.459863, 43.712749],
              [-7.448941, 43.692649],
              [-7.436426, 43.701104],
              [-7.357097, 43.671212],
              [-7.294399, 43.592338],
              [-7.245526, 43.575171],
              [-7.271097, 43.562576],
              [-7.255804, 43.546906],
              [-7.236101, 43.569148],
              [-7.037183, 43.552276],
              [-7.04827, 43.477076],
              [-7.089155, 43.455118],
              [-7.101657, 43.42748],
              [-7.172988, 43.430413],
              [-7.16855, 43.403593],
              [-7.182489, 43.392459],
              [-7.127533, 43.372599],
              [-7.131208, 43.331348],
              [-7.100933, 43.30381],
              [-7.059785, 43.305335],
              [-7.071508, 43.271218],
              [-7.061591, 43.244048],
              [-7.020053, 43.21324],
              [-7.009583, 43.222882],
              [-7.001445, 43.207276],
              [-6.971019, 43.20425],
              [-6.964901, 43.187545],
              [-6.980773, 43.166546],
              [-6.957362, 43.135477],
              [-6.885405, 43.156801],
              [-6.868246, 43.190022],
              [-6.821554, 43.143243],
              [-6.871348, 43.091209],
              [-6.915595, 43.073422],
              [-6.938689, 43.086512],
              [-6.968586, 43.057215],
              [-6.96464, 43.034671],
              [-6.981913, 43.041373],
              [-6.997172, 43.019483],
              [-6.961956, 42.990718],
              [-6.957104, 43.036778],
              [-6.910321, 42.990138],
              [-6.864303, 42.983483],
              [-6.836091, 42.957764],
              [-6.841875, 42.928199],
              [-6.824189, 42.915061],
              [-6.847994, 42.914325],
              [-6.814892, 42.87082],
              [-6.833742, 42.882057],
              [-6.871279, 42.873841],
              [-6.840321, 42.823365],
              [-6.86743, 42.787174],
              [-6.935603, 42.740863],
              [-6.959483, 42.75503],
              [-6.972884, 42.725005],
              [-7.010888, 42.723661],
              [-7.045192, 42.694765],
              [-7.004357, 42.642872],
              [-7.047005, 42.6327],
              [-7.05017, 42.597311],
              [-7.033562, 42.58409],
              [-7.069671, 42.553949],
              [-7.059853, 42.529321],
              [-7.076188, 42.49675],
              [-7.099742, 42.458785],
              [-7.149269, 42.436156],
              [-7.202672, 42.3257],
              [-7.230933, 42.359144],
              [-7.220066, 42.387188],
              [-7.30449, 42.428873],
              [-7.369901, 42.42136],
              [-7.399246, 42.43556],
              [-7.507581, 42.386783],
              [-7.541965, 42.384441],
              [-7.576121, 42.404868],
              [-7.616651, 42.384224],
              [-7.631413, 42.407055],
              [-7.697873, 42.429545],
              [-7.713175, 42.452378],
              [-7.788899, 42.465996],
              [-7.74831, 42.479987],
              [-7.765027, 42.493958],
              [-7.802712, 42.480272],
              [-7.875414, 42.510948],
              [-7.913179, 42.508386],
              [-7.901601, 42.521361],
              [-7.924006, 42.525044],
              [-7.888733, 42.538127],
              [-7.930749, 42.5786],
              [-7.892552, 42.629632],
              [-7.889713, 42.683709],
              [-7.861428, 42.706199],
              [-7.866392, 42.720636],
              [-7.93537, 42.739743],
              [-7.978411, 42.778704],
              [-7.980484, 42.811339],
              [-7.956408, 42.839746],
              [-7.999732, 42.848895],
              [-7.93551, 42.903456],
              [-7.943322, 42.917351],
              [-7.908171, 42.95126],
              [-7.905313, 42.985396],
              [-7.942985, 43.067624],
              [-7.916828, 43.088271],
              [-7.961389, 43.189835],
              [-7.921855, 43.26946],
              [-7.947113, 43.286022],
              [-7.886953, 43.337176],
              [-7.88514, 43.375368],
              [-7.898906, 43.389798],
              [-7.881939, 43.407532],
              [-7.823066, 43.400745],
              [-7.807274, 43.437342],
              [-7.78029, 43.448586],
              [-7.758777, 43.508472],
              [-7.744426, 43.511896],
              [-7.760311, 43.548029],
              [-7.748757, 43.577671],
              [-7.717925, 43.583406],
              [-7.735484, 43.622138],
              [-7.691004, 43.654053],
              [-7.719266, 43.686647],
              [-7.698595, 43.695657],
              [-7.699802, 43.735152],
              [-7.636218, 43.752801],
            ],
            [
              [-7.139092, 43.557115],
              [-7.138552, 43.55754],
              [-7.138581, 43.558142],
              [-7.139031, 43.558018],
              [-7.139092, 43.557115],
            ],
            [
              [-7.609012, 43.676185],
              [-7.609032, 43.676627],
              [-7.60977, 43.676313],
              [-7.609754, 43.676015],
              [-7.609012, 43.676185],
            ],
            [
              [-7.435609, 43.69442],
              [-7.435386, 43.694591],
              [-7.436065, 43.694537],
              [-7.435994, 43.694423],
              [-7.435609, 43.69442],
            ],
            [
              [-7.179106, 43.562166],
              [-7.178877, 43.562255],
              [-7.178792, 43.562465],
              [-7.179064, 43.562455],
              [-7.179106, 43.562166],
            ],
            [
              [-7.380039, 43.677292],
              [-7.380062, 43.677436],
              [-7.380474, 43.677636],
              [-7.380394, 43.677396],
              [-7.380039, 43.677292],
            ],
            [
              [-7.163198, 43.555536],
              [-7.163137, 43.555736],
              [-7.163429, 43.555653],
              [-7.163385, 43.555556],
              [-7.163198, 43.555536],
            ],
            [
              [-7.145228, 43.556325],
              [-7.145214, 43.556479],
              [-7.145542, 43.556395],
              [-7.145425, 43.556309],
              [-7.145228, 43.556325],
            ],
            [
              [-7.119351, 43.556083],
              [-7.119374, 43.556235],
              [-7.119546, 43.55622],
              [-7.119438, 43.55608],
              [-7.119351, 43.556083],
            ],
          ],
          [
            [
              [-7.629538, 43.761175],
              [-7.627986, 43.758609],
              [-7.633081, 43.756557],
              [-7.634502, 43.758875],
              [-7.629538, 43.761175],
            ],
          ],
          [
            [
              [-7.488723, 43.731882],
              [-7.490427, 43.7296],
              [-7.494171, 43.731352],
              [-7.491659, 43.732793],
              [-7.488723, 43.731882],
            ],
          ],
          [
            [
              [-7.437914, 43.718386],
              [-7.440748, 43.716592],
              [-7.442469, 43.717029],
              [-7.441134, 43.717945],
              [-7.437914, 43.718386],
            ],
          ],
          [
            [
              [-7.4415, 43.701258],
              [-7.443338, 43.700097],
              [-7.444424, 43.701189],
              [-7.442541, 43.70174],
              [-7.4415, 43.701258],
            ],
          ],
          [
            [
              [-7.048407, 43.555363],
              [-7.049664, 43.5562],
              [-7.050048, 43.557501],
              [-7.04836, 43.556886],
              [-7.048407, 43.555363],
            ],
          ],
          [
            [
              [-7.439359, 43.715259],
              [-7.437851, 43.715174],
              [-7.436981, 43.714992],
              [-7.441657, 43.714954],
              [-7.439359, 43.715259],
            ],
          ],
          [
            [
              [-7.042699, 43.557204],
              [-7.041052, 43.556812],
              [-7.040696, 43.555933],
              [-7.04166, 43.555737],
              [-7.042699, 43.557204],
            ],
          ],
          [
            [
              [-7.604184, 43.711984],
              [-7.603716, 43.713327],
              [-7.603043, 43.713606],
              [-7.603455, 43.712356],
              [-7.604184, 43.711984],
            ],
          ],
          [
            [
              [-7.517117, 43.730239],
              [-7.517577, 43.731076],
              [-7.516776, 43.731477],
              [-7.516516, 43.730335],
              [-7.517117, 43.730239],
            ],
          ],
          [
            [
              [-7.583707, 43.698189],
              [-7.584341, 43.698199],
              [-7.584897, 43.698816],
              [-7.58348, 43.698783],
              [-7.583707, 43.698189],
            ],
          ],
          [
            [
              [-7.491051, 43.728153],
              [-7.491376, 43.728176],
              [-7.491986, 43.728864],
              [-7.490393, 43.72898],
              [-7.491051, 43.728153],
            ],
          ],
          [
            [
              [-7.604259, 43.698081],
              [-7.604735, 43.697324],
              [-7.60527, 43.6975],
              [-7.604848, 43.698157],
              [-7.604259, 43.698081],
            ],
          ],
          [
            [
              [-7.436626, 43.718751],
              [-7.437046, 43.718726],
              [-7.436482, 43.719495],
              [-7.436287, 43.719205],
              [-7.436626, 43.718751],
            ],
          ],
          [
            [
              [-7.546906, 43.733268],
              [-7.547237, 43.733372],
              [-7.547138, 43.733862],
              [-7.546779, 43.733715],
              [-7.546906, 43.733268],
            ],
          ],
          [
            [
              [-7.063344, 43.558973],
              [-7.063776, 43.559138],
              [-7.063623, 43.55963],
              [-7.063354, 43.559486],
              [-7.063344, 43.558973],
            ],
          ],
          [
            [
              [-7.117775, 43.562199],
              [-7.117798, 43.561631],
              [-7.11837, 43.561484],
              [-7.118401, 43.561564],
              [-7.117775, 43.562199],
            ],
          ],
          [
            [
              [-7.513075, 43.7247],
              [-7.513416, 43.724939],
              [-7.512692, 43.725219],
              [-7.513085, 43.724997],
              [-7.513075, 43.7247],
            ],
          ],
          [
            [
              [-7.060508, 43.559515],
              [-7.060573, 43.559369],
              [-7.06104, 43.559505],
              [-7.060754, 43.559678],
              [-7.060508, 43.559515],
            ],
          ],
          [
            [
              [-7.54762, 43.734653],
              [-7.547969, 43.734675],
              [-7.548008, 43.734863],
              [-7.54771, 43.734866],
              [-7.54762, 43.734653],
            ],
          ],
          [
            [
              [-7.345567, 43.663536],
              [-7.345742, 43.663421],
              [-7.346052, 43.663478],
              [-7.346002, 43.663628],
              [-7.345567, 43.663536],
            ],
          ],
          [
            [
              [-7.054812, 43.557836],
              [-7.054973, 43.55765],
              [-7.055217, 43.557966],
              [-7.055023, 43.558027],
              [-7.054812, 43.557836],
            ],
          ],
          [
            [
              [-7.318077, 43.625772],
              [-7.31838, 43.625632],
              [-7.318495, 43.625715],
              [-7.318396, 43.625863],
              [-7.318077, 43.625772],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5639",
      properties: { name: "Madrid", density: 0, path: "/world/Spain/Madrid" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.536705, 41.160576],
              [-3.488751, 41.094294],
              [-3.425805, 41.082899],
              [-3.438935, 41.04267],
              [-3.39445, 41.000278],
              [-3.418296, 40.987564],
              [-3.444062, 40.906849],
              [-3.460577, 40.906659],
              [-3.440487, 40.87535],
              [-3.47257, 40.864217],
              [-3.477387, 40.823561],
              [-3.511572, 40.788496],
              [-3.45901, 40.781324],
              [-3.444343, 40.76158],
              [-3.450416, 40.734887],
              [-3.434945, 40.727986],
              [-3.468553, 40.689937],
              [-3.404735, 40.669637],
              [-3.393354, 40.692458],
              [-3.345598, 40.637743],
              [-3.316497, 40.655177],
              [-3.338516, 40.625849],
              [-3.321644, 40.612192],
              [-3.328275, 40.594836],
              [-3.284069, 40.568977],
              [-3.297126, 40.537521],
              [-3.249915, 40.544316],
              [-3.199955, 40.514449],
              [-3.198597, 40.442252],
              [-3.155515, 40.439425],
              [-3.130558, 40.405185],
              [-3.15343, 40.367703],
              [-3.141212, 40.350542],
              [-3.182857, 40.308267],
              [-3.194449, 40.248037],
              [-3.173397, 40.23417],
              [-3.153718, 40.263714],
              [-3.112674, 40.285478],
              [-3.101528, 40.278092],
              [-3.067845, 40.157914],
              [-3.089276, 40.16297],
              [-3.096755, 40.142389],
              [-3.056194, 40.113743],
              [-3.060707, 40.090564],
              [-3.11819, 40.061429],
              [-3.161579, 40.064928],
              [-3.178137, 40.092207],
              [-3.200956, 40.060488],
              [-3.275756, 40.047276],
              [-3.332241, 40.080482],
              [-3.37529, 40.075393],
              [-3.375783, 40.051255],
              [-3.401072, 40.034181],
              [-3.506863, 40.048674],
              [-3.519689, 40.021072],
              [-3.589995, 40.013172],
              [-3.637944, 39.988352],
              [-3.634499, 39.965892],
              [-3.74387, 39.940802],
              [-3.804398, 39.88472],
              [-3.868326, 39.904859],
              [-3.87519, 39.928736],
              [-3.840044, 39.941956],
              [-3.832042, 39.93156],
              [-3.809548, 39.953965],
              [-3.783347, 39.944796],
              [-3.763602, 39.967518],
              [-3.770486, 39.950552],
              [-3.75437, 39.950443],
              [-3.758817, 39.968885],
              [-3.727936, 39.964545],
              [-3.746207, 39.981089],
              [-3.720088, 39.984441],
              [-3.699891, 40.019543],
              [-3.636088, 40.041626],
              [-3.607628, 40.108967],
              [-3.655732, 40.124912],
              [-3.667313, 40.143291],
              [-3.683724, 40.13227],
              [-3.722216, 40.147713],
              [-3.744375, 40.132224],
              [-3.770453, 40.139399],
              [-3.804603, 40.176841],
              [-3.834186, 40.161353],
              [-3.872016, 40.190153],
              [-3.931143, 40.203977],
              [-3.950892, 40.190335],
              [-3.952866, 40.212797],
              [-3.991002, 40.209478],
              [-4.026159, 40.235611],
              [-4.024028, 40.248968],
              [-4.072894, 40.265451],
              [-4.104272, 40.241833],
              [-4.145769, 40.245177],
              [-4.143992, 40.264621],
              [-4.190871, 40.297355],
              [-4.20323, 40.268818],
              [-4.247999, 40.273481],
              [-4.269961, 40.235685],
              [-4.306111, 40.221778],
              [-4.307989, 40.233882],
              [-4.348488, 40.236747],
              [-4.356386, 40.308621],
              [-4.38011, 40.318341],
              [-4.383482, 40.289049],
              [-4.435246, 40.259127],
              [-4.437426, 40.236698],
              [-4.509789, 40.21919],
              [-4.527911, 40.200093],
              [-4.574993, 40.206642],
              [-4.559329, 40.231269],
              [-4.568788, 40.258712],
              [-4.529379, 40.293125],
              [-4.546225, 40.341825],
              [-4.526788, 40.344873],
              [-4.502898, 40.314893],
              [-4.455762, 40.319351],
              [-4.460596, 40.341381],
              [-4.438636, 40.352754],
              [-4.443729, 40.3857],
              [-4.42173, 40.406229],
              [-4.321089, 40.410309],
              [-4.341252, 40.428678],
              [-4.315679, 40.464766],
              [-4.328218, 40.533757],
              [-4.289262, 40.564194],
              [-4.278572, 40.603172],
              [-4.290709, 40.627823],
              [-4.271102, 40.629023],
              [-4.262141, 40.60518],
              [-4.245439, 40.602447],
              [-4.161183, 40.623465],
              [-4.172853, 40.630362],
              [-4.171567, 40.680626],
              [-4.096991, 40.747739],
              [-4.0767, 40.788881],
              [-3.979676, 40.784555],
              [-3.947105, 40.862974],
              [-3.952276, 40.893632],
              [-3.907197, 40.955303],
              [-3.781366, 41.000312],
              [-3.740455, 41.05364],
              [-3.676065, 41.086696],
              [-3.613703, 41.149053],
              [-3.536705, 41.160576],
            ],
            [
              [-3.372259, 40.635612],
              [-3.380257, 40.632854],
              [-3.382135, 40.630471],
              [-3.363665, 40.631602],
              [-3.372259, 40.635612],
            ],
          ],
          [
            [
              [-4.255731, 40.65317],
              [-4.31951, 40.647644],
              [-4.324122, 40.658029],
              [-4.284817, 40.681221],
              [-4.255731, 40.65317],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5609",
      properties: { name: "Málaga", density: 0, path: "/world/Spain/Málaga" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.406576, 37.27412],
              [-4.378944, 37.274202],
              [-4.385015, 37.238015],
              [-4.372013, 37.21539],
              [-4.322455, 37.204227],
              [-4.332209, 37.191157],
              [-4.287067, 37.11952],
              [-4.27251, 37.014902],
              [-4.231335, 37.016941],
              [-4.16664, 36.957075],
              [-4.099893, 36.952709],
              [-4.045693, 36.902864],
              [-3.929525, 36.88288],
              [-3.86761, 36.850679],
              [-3.826696, 36.855325],
              [-3.768144, 36.801955],
              [-3.77744, 36.737917],
              [-3.844715, 36.75355],
              [-3.957264, 36.726087],
              [-4.067346, 36.748742],
              [-4.108468, 36.725335],
              [-4.18078, 36.71468],
              [-4.393754, 36.722338],
              [-4.415296, 36.706986],
              [-4.413253, 36.7177],
              [-4.418516, 36.717106],
              [-4.417447, 36.708907],
              [-4.511655, 36.593144],
              [-4.602807, 36.562956],
              [-4.638134, 36.507076],
              [-4.740536, 36.482632],
              [-4.860926, 36.507631],
              [-4.934971, 36.49981],
              [-5.0049, 36.459744],
              [-5.080979, 36.450565],
              [-5.176942, 36.41427],
              [-5.220041, 36.375407],
              [-5.248008, 36.310423],
              [-5.27783, 36.337123],
              [-5.320085, 36.325071],
              [-5.347157, 36.418647],
              [-5.42962, 36.533339],
              [-5.492395, 36.536469],
              [-5.52425, 36.497712],
              [-5.60829, 36.512289],
              [-5.604171, 36.548924],
              [-5.523325, 36.531123],
              [-5.460816, 36.588784],
              [-5.455914, 36.619583],
              [-5.380718, 36.631781],
              [-5.324029, 36.673341],
              [-5.324626, 36.724009],
              [-5.290935, 36.77517],
              [-5.319819, 36.786732],
              [-5.340714, 36.819418],
              [-5.301583, 36.869643],
              [-5.269776, 36.884215],
              [-5.172264, 36.823507],
              [-5.133292, 36.839102],
              [-5.105079, 36.897936],
              [-5.086769, 36.905091],
              [-5.110115, 36.959332],
              [-5.095581, 36.968295],
              [-5.142314, 37.005828],
              [-5.120749, 37.028468],
              [-5.053089, 37.030604],
              [-4.908368, 37.11465],
              [-4.903311, 37.138786],
              [-4.942665, 37.157588],
              [-4.939603, 37.175699],
              [-4.905113, 37.181865],
              [-4.897618, 37.156029],
              [-4.8755, 37.158193],
              [-4.853864, 37.198585],
              [-4.825906, 37.200705],
              [-4.793706, 37.153556],
              [-4.708606, 37.197696],
              [-4.732308, 37.226148],
              [-4.717945, 37.239894],
              [-4.735745, 37.244835],
              [-4.719691, 37.259696],
              [-4.614192, 37.255421],
              [-4.602299, 37.209237],
              [-4.576208, 37.209162],
              [-4.498077, 37.223334],
              [-4.477553, 37.244728],
              [-4.485723, 37.251981],
              [-4.464144, 37.252078],
              [-4.445001, 37.277587],
              [-4.406576, 37.27412],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5652",
      properties: { name: "Melilla", density: 0, path: "/world/Spain/Melilla" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.950577, 35.265458],
              [-2.970316, 35.288651],
              [-2.952625, 35.320288],
              [-2.923292, 35.290191],
              [-2.950577, 35.265458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5611",
      properties: { name: "Murcia", density: 0, path: "/world/Spain/Murcia" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.149852, 38.746067],
              [-1.119672, 38.737719],
              [-1.115601, 38.711387],
              [-1.026069, 38.65453],
              [-1.001569, 38.572675],
              [-1.026062, 38.522819],
              [-1.013645, 38.49673],
              [-1.094162, 38.437929],
              [-1.077206, 38.43147],
              [-1.084384, 38.346216],
              [-0.989153, 38.320877],
              [-0.967677, 38.27564],
              [-0.988546, 38.199531],
              [-1.036762, 38.13772],
              [-1.022599, 38.07569],
              [-0.90018, 37.923129],
              [-0.830725, 37.86442],
              [-0.762393, 37.846951],
              [-0.750861, 37.82181],
              [-0.75221, 37.788205],
              [-0.775764, 37.819111],
              [-0.796275, 37.810504],
              [-0.798016, 37.78479],
              [-0.844831, 37.748042],
              [-0.857033, 37.713363],
              [-0.802771, 37.658204],
              [-0.727675, 37.634792],
              [-0.71979, 37.648824],
              [-0.740274, 37.659518],
              [-0.729917, 37.662005],
              [-0.745737, 37.705987],
              [-0.745623, 37.748248],
              [-0.741646, 37.74318],
              [-0.733543, 37.746539],
              [-0.745476, 37.748967],
              [-0.757946, 37.77987],
              [-0.746475, 37.780751],
              [-0.745064, 37.758799],
              [-0.727828, 37.750087],
              [-0.721418, 37.740033],
              [-0.727193, 37.746741],
              [-0.733055, 37.746917],
              [-0.738242, 37.734608],
              [-0.731456, 37.678489],
              [-0.709719, 37.641311],
              [-0.688294, 37.632756],
              [-0.702217, 37.630262],
              [-0.731849, 37.601503],
              [-0.820005, 37.575021],
              [-0.876348, 37.577364],
              [-0.919248, 37.55394],
              [-0.960414, 37.558669],
              [-0.967404, 37.569289],
              [-0.949765, 37.567623],
              [-0.992002, 37.601532],
              [-0.98413, 37.584301],
              [-1.017991, 37.572754],
              [-1.031173, 37.579121],
              [-1.074017, 37.584154],
              [-1.10866, 37.569367],
              [-1.12714, 37.552124],
              [-1.111057, 37.534077],
              [-1.166474, 37.542071],
              [-1.173932, 37.562969],
              [-1.213477, 37.575345],
              [-1.24623, 37.576088],
              [-1.255754, 37.557583],
              [-1.326179, 37.56273],
              [-1.401591, 37.507221],
              [-1.45968, 37.48657],
              [-1.48622, 37.422921],
              [-1.51901, 37.431782],
              [-1.62676, 37.373903],
              [-1.737075, 37.442633],
              [-1.811756, 37.451556],
              [-1.808946, 37.433474],
              [-1.845261, 37.454827],
              [-2.011814, 37.672248],
              [-1.995832, 37.728047],
              [-2.008836, 37.774951],
              [-1.995277, 37.841385],
              [-1.974502, 37.868469],
              [-1.994702, 37.880477],
              [-2.021816, 37.868223],
              [-2.100385, 37.877198],
              [-2.123204, 37.900552],
              [-2.172012, 37.888689],
              [-2.247161, 37.934432],
              [-2.298474, 38.002119],
              [-2.341632, 38.026001],
              [-2.329902, 38.065402],
              [-2.26165, 38.112959],
              [-2.212939, 38.203022],
              [-2.113728, 38.238203],
              [-2.048992, 38.305783],
              [-1.98263, 38.281371],
              [-1.907536, 38.297962],
              [-1.784303, 38.356154],
              [-1.795754, 38.370268],
              [-1.771143, 38.364858],
              [-1.771346, 38.378549],
              [-1.745118, 38.381235],
              [-1.687557, 38.365825],
              [-1.692731, 38.337821],
              [-1.670246, 38.311649],
              [-1.589539, 38.309992],
              [-1.558198, 38.325203],
              [-1.478547, 38.376383],
              [-1.47987, 38.479835],
              [-1.498733, 38.532056],
              [-1.487695, 38.563309],
              [-1.452001, 38.58146],
              [-1.447698, 38.649518],
              [-1.421665, 38.676995],
              [-1.366666, 38.704002],
              [-1.344069, 38.677102],
              [-1.249594, 38.738692],
              [-1.183635, 38.755085],
              [-1.149852, 38.746067],
            ],
            [
              [-1.468402, 37.470442],
              [-1.467799, 37.471155],
              [-1.467879, 37.471652],
              [-1.468561, 37.470949],
              [-1.468402, 37.470442],
            ],
            [
              [-1.118587, 37.561829],
              [-1.118881, 37.561825],
              [-1.118962, 37.561763],
              [-1.118795, 37.561661],
              [-1.118587, 37.561829],
            ],
            [
              [-1.623125, 37.378133],
              [-1.623078, 37.378259],
              [-1.62329, 37.378352],
              [-1.623316, 37.378163],
              [-1.623125, 37.378133],
            ],
            [
              [-1.011689, 37.576379],
              [-1.011619, 37.576486],
              [-1.011844, 37.576526],
              [-1.011814, 37.57639],
              [-1.011689, 37.576379],
            ],
            [
              [-1.468228, 37.472873],
              [-1.468248, 37.472964],
              [-1.468496, 37.473003],
              [-1.468487, 37.472895],
              [-1.468228, 37.472873],
            ],
            [
              [-1.502079, 37.429532],
              [-1.502022, 37.42954],
              [-1.501985, 37.42972],
              [-1.502158, 37.429533],
              [-1.502079, 37.429532],
            ],
            [
              [-0.999342, 37.584173],
              [-0.999249, 37.584252],
              [-0.999293, 37.584334],
              [-0.999466, 37.584184],
              [-0.999342, 37.584173],
            ],
            [
              [-1.01468, 37.574212],
              [-1.014621, 37.574283],
              [-1.014675, 37.574401],
              [-1.01478, 37.574295],
              [-1.01468, 37.574212],
            ],
          ],
          [
            [
              [-0.770502, 37.702439],
              [-0.764605, 37.693712],
              [-0.768486, 37.690235],
              [-0.776148, 37.695679],
              [-0.770502, 37.702439],
            ],
          ],
          [
            [
              [-0.796357, 37.701042],
              [-0.79755, 37.696009],
              [-0.804373, 37.701525],
              [-0.799964, 37.703723],
              [-0.796357, 37.701042],
            ],
          ],
          [
            [
              [-0.706123, 37.730445],
              [-0.704773, 37.728229],
              [-0.708537, 37.725374],
              [-0.709271, 37.730281],
              [-0.706123, 37.730445],
            ],
          ],
          [
            [
              [-0.757603, 37.788605],
              [-0.756043, 37.78762],
              [-0.753621, 37.783591],
              [-0.756721, 37.783596],
              [-0.757603, 37.788605],
            ],
          ],
          [
            [
              [-1.266289, 37.557287],
              [-1.266586, 37.554759],
              [-1.270007, 37.555143],
              [-1.26909, 37.557103],
              [-1.266289, 37.557287],
            ],
          ],
          [
            [
              [-1.545751, 37.409591],
              [-1.543675, 37.408853],
              [-1.548409, 37.407667],
              [-1.54731, 37.409601],
              [-1.545751, 37.409591],
            ],
          ],
          [
            [
              [-0.96759, 37.559946],
              [-0.96773, 37.558552],
              [-0.97175, 37.559342],
              [-0.969558, 37.560016],
              [-0.96759, 37.559946],
            ],
          ],
          [
            [
              [-0.759186, 37.788806],
              [-0.759871, 37.786071],
              [-0.757365, 37.784816],
              [-0.760511, 37.784795],
              [-0.759186, 37.788806],
            ],
          ],
          [
            [
              [-0.761734, 37.790549],
              [-0.759126, 37.790436],
              [-0.759017, 37.790281],
              [-0.761472, 37.788706],
              [-0.761734, 37.790549],
            ],
          ],
          [
            [
              [-0.759139, 37.784354],
              [-0.757647, 37.784127],
              [-0.758702, 37.783021],
              [-0.75957, 37.782488],
              [-0.759139, 37.784354],
            ],
          ],
          [
            [
              [-0.75743, 37.782303],
              [-0.756887, 37.780364],
              [-0.759075, 37.780847],
              [-0.759498, 37.782261],
              [-0.75743, 37.782303],
            ],
          ],
          [
            [
              [-0.738977, 37.669388],
              [-0.74092, 37.668506],
              [-0.741346, 37.669416],
              [-0.740326, 37.670504],
              [-0.738977, 37.669388],
            ],
          ],
          [
            [
              [-0.74861, 37.665022],
              [-0.7497, 37.664952],
              [-0.750063, 37.665689],
              [-0.747648, 37.665688],
              [-0.74861, 37.665022],
            ],
          ],
          [
            [
              [-1.041997, 37.570956],
              [-1.040897, 37.57101],
              [-1.040642, 37.570375],
              [-1.042019, 37.570136],
              [-1.041997, 37.570956],
            ],
          ],
          [
            [
              [-1.20775, 37.571445],
              [-1.207561, 37.57083],
              [-1.208737, 37.570902],
              [-1.207943, 37.57235],
              [-1.20775, 37.571445],
            ],
          ],
          [
            [
              [-0.648078, 37.656526],
              [-0.648208, 37.655969],
              [-0.649554, 37.655005],
              [-0.649067, 37.65605],
              [-0.648078, 37.656526],
            ],
          ],
          [
            [
              [-0.745326, 37.782324],
              [-0.745398, 37.781794],
              [-0.746127, 37.781735],
              [-0.745892, 37.782722],
              [-0.745326, 37.782324],
            ],
          ],
          [
            [
              [-0.747828, 37.788527],
              [-0.747848, 37.787887],
              [-0.747996, 37.78935],
              [-0.747674, 37.788731],
              [-0.747828, 37.788527],
            ],
          ],
          [
            [
              [-0.75847, 37.787324],
              [-0.757864, 37.786727],
              [-0.758134, 37.786416],
              [-0.759187, 37.787284],
              [-0.75847, 37.787324],
            ],
          ],
          [
            [
              [-0.745404, 37.783857],
              [-0.745661, 37.783249],
              [-0.746181, 37.784061],
              [-0.745719, 37.784323],
              [-0.745404, 37.783857],
            ],
          ],
          [
            [
              [-0.757442, 37.785664],
              [-0.757129, 37.785118],
              [-0.758492, 37.785856],
              [-0.758326, 37.786078],
              [-0.757442, 37.785664],
            ],
          ],
          [
            [
              [-1.327861, 37.556373],
              [-1.32839, 37.556543],
              [-1.327956, 37.557186],
              [-1.327737, 37.556858],
              [-1.327861, 37.556373],
            ],
          ],
          [
            [
              [-0.745048, 37.781751],
              [-0.744686, 37.781311],
              [-0.745661, 37.781384],
              [-0.745586, 37.781608],
              [-0.745048, 37.781751],
            ],
          ],
          [
            [
              [-0.708671, 37.618988],
              [-0.709317, 37.619334],
              [-0.70916, 37.619646],
              [-0.708414, 37.619226],
              [-0.708671, 37.618988],
            ],
          ],
          [
            [
              [-0.758559, 37.786632],
              [-0.759208, 37.786581],
              [-0.759313, 37.786871],
              [-0.759148, 37.787058],
              [-0.758559, 37.786632],
            ],
          ],
          [
            [
              [-0.71854, 37.664498],
              [-0.71891, 37.664613],
              [-0.71824, 37.665024],
              [-0.718332, 37.66462],
              [-0.71854, 37.664498],
            ],
          ],
          [
            [
              [-0.744377, 37.77953],
              [-0.744522, 37.779623],
              [-0.744315, 37.780448],
              [-0.74406, 37.779876],
              [-0.744377, 37.77953],
            ],
          ],
          [
            [
              [-0.698058, 37.73146],
              [-0.698282, 37.731924],
              [-0.697608, 37.731721],
              [-0.697609, 37.731685],
              [-0.698058, 37.73146],
            ],
          ],
          [
            [
              [-0.758612, 37.779718],
              [-0.758317, 37.779478],
              [-0.758066, 37.779434],
              [-0.758782, 37.779389],
              [-0.758612, 37.779718],
            ],
          ],
          [
            [
              [-0.757931, 37.792757],
              [-0.758317, 37.792386],
              [-0.758552, 37.792868],
              [-0.758426, 37.79291],
              [-0.757931, 37.792757],
            ],
          ],
          [
            [
              [-0.746826, 37.78602],
              [-0.747132, 37.786008],
              [-0.747213, 37.786334],
              [-0.746598, 37.786403],
              [-0.746826, 37.78602],
            ],
          ],
          [
            [
              [-0.702937, 37.624535],
              [-0.702632, 37.62452],
              [-0.702534, 37.624392],
              [-0.703295, 37.624326],
              [-0.702937, 37.624535],
            ],
          ],
          [
            [
              [-0.744169, 37.78077],
              [-0.744592, 37.78067],
              [-0.744775, 37.781007],
              [-0.744295, 37.781124],
              [-0.744169, 37.78077],
            ],
          ],
          [
            [
              [-0.694211, 37.629375],
              [-0.69452, 37.629661],
              [-0.694093, 37.629887],
              [-0.694029, 37.629759],
              [-0.694211, 37.629375],
            ],
          ],
          [
            [
              [-1.041826, 37.5697],
              [-1.042049, 37.569839],
              [-1.041396, 37.570144],
              [-1.041422, 37.570009],
              [-1.041826, 37.5697],
            ],
          ],
          [
            [
              [-0.69331, 37.629583],
              [-0.693471, 37.629856],
              [-0.693114, 37.630048],
              [-0.693045, 37.629722],
              [-0.69331, 37.629583],
            ],
          ],
          [
            [
              [-0.762346, 37.793219],
              [-0.762479, 37.793321],
              [-0.762344, 37.79403],
              [-0.762187, 37.793604],
              [-0.762346, 37.793219],
            ],
          ],
          [
            [
              [-1.529202, 37.422103],
              [-1.529293, 37.42205],
              [-1.529738, 37.422407],
              [-1.529207, 37.422419],
              [-1.529202, 37.422103],
            ],
          ],
          [
            [
              [-0.760502, 37.845756],
              [-0.760742, 37.845545],
              [-0.760842, 37.846094],
              [-0.760603, 37.84607],
              [-0.760502, 37.845756],
            ],
          ],
          [
            [
              [-0.761286, 37.791856],
              [-0.761552, 37.791681],
              [-0.761968, 37.792194],
              [-0.761888, 37.792228],
              [-0.761286, 37.791856],
            ],
          ],
          [
            [
              [-0.705482, 37.623945],
              [-0.705605, 37.623632],
              [-0.705868, 37.623916],
              [-0.705571, 37.624019],
              [-0.705482, 37.623945],
            ],
          ],
          [
            [
              [-0.653571, 37.651985],
              [-0.653469, 37.652335],
              [-0.653167, 37.652211],
              [-0.653294, 37.652115],
              [-0.653571, 37.651985],
            ],
          ],
          [
            [
              [-0.687555, 37.632814],
              [-0.687909, 37.632748],
              [-0.687917, 37.632848],
              [-0.687627, 37.633067],
              [-0.687555, 37.632814],
            ],
          ],
          [
            [
              [-0.687951, 37.635759],
              [-0.688268, 37.635765],
              [-0.688241, 37.635918],
              [-0.687854, 37.635982],
              [-0.687951, 37.635759],
            ],
          ],
          [
            [
              [-0.747474, 37.787484],
              [-0.747605, 37.787288],
              [-0.747922, 37.787303],
              [-0.747815, 37.787463],
              [-0.747474, 37.787484],
            ],
          ],
          [
            [
              [-0.708377, 37.618216],
              [-0.708639, 37.618185],
              [-0.708505, 37.618498],
              [-0.708384, 37.618369],
              [-0.708377, 37.618216],
            ],
          ],
          [
            [
              [-0.758791, 37.786465],
              [-0.759137, 37.7863],
              [-0.759348, 37.786467],
              [-0.758937, 37.786522],
              [-0.758791, 37.786465],
            ],
          ],
          [
            [
              [-0.755407, 37.722023],
              [-0.755676, 37.722019],
              [-0.755678, 37.722204],
              [-0.75531, 37.722192],
              [-0.755407, 37.722023],
            ],
          ],
          [
            [
              [-0.759333, 37.792216],
              [-0.759391, 37.79255],
              [-0.759264, 37.792629],
              [-0.759178, 37.792474],
              [-0.759333, 37.792216],
            ],
          ],
          [
            [
              [-0.75683, 37.791618],
              [-0.757001, 37.791603],
              [-0.757259, 37.791717],
              [-0.756939, 37.791774],
              [-0.75683, 37.791618],
            ],
          ],
          [
            [
              [-0.744011, 37.778496],
              [-0.74425, 37.778473],
              [-0.744234, 37.778644],
              [-0.743938, 37.778675],
              [-0.744011, 37.778496],
            ],
          ],
          [
            [
              [-0.702758, 37.625568],
              [-0.702925, 37.62567],
              [-0.702635, 37.625899],
              [-0.702581, 37.625817],
              [-0.702758, 37.625568],
            ],
          ],
          [
            [
              [-0.75781, 37.792277],
              [-0.757992, 37.792262],
              [-0.75818, 37.792419],
              [-0.757918, 37.792441],
              [-0.75781, 37.792277],
            ],
          ],
          [
            [
              [-0.76224, 37.792604],
              [-0.762232, 37.792469],
              [-0.762442, 37.79305],
              [-0.762353, 37.792994],
              [-0.76224, 37.792604],
            ],
          ],
          [
            [
              [-0.688838, 37.636416],
              [-0.688918, 37.636382],
              [-0.689098, 37.636421],
              [-0.688958, 37.636554],
              [-0.688838, 37.636416],
            ],
          ],
          [
            [
              [-1.094359, 37.569813],
              [-1.094473, 37.569805],
              [-1.094481, 37.569941],
              [-1.094233, 37.569892],
              [-1.094359, 37.569813],
            ],
          ],
          [
            [
              [-0.689091, 37.631897],
              [-0.689308, 37.631839],
              [-0.689419, 37.631922],
              [-0.689281, 37.631991],
              [-0.689091, 37.631897],
            ],
          ],
          [
            [
              [-0.971959, 37.575009],
              [-0.972062, 37.574983],
              [-0.972114, 37.575128],
              [-0.971888, 37.575143],
              [-0.971959, 37.575009],
            ],
          ],
          [
            [
              [-0.687998, 37.633174],
              [-0.688141, 37.633294],
              [-0.688082, 37.633383],
              [-0.687968, 37.63338],
              [-0.687998, 37.633174],
            ],
          ],
          [
            [
              [-1.022492, 37.572938],
              [-1.022614, 37.573048],
              [-1.022375, 37.573107],
              [-1.022365, 37.573035],
              [-1.022492, 37.572938],
            ],
          ],
          [
            [
              [-0.701822, 37.623982],
              [-0.701723, 37.623881],
              [-0.702062, 37.623914],
              [-0.702015, 37.62395],
              [-0.701822, 37.623982],
            ],
          ],
          [
            [
              [-0.701898, 37.624064],
              [-0.702055, 37.624113],
              [-0.701971, 37.624264],
              [-0.70185, 37.624145],
              [-0.701898, 37.624064],
            ],
          ],
          [
            [
              [-0.689656, 37.631224],
              [-0.68978, 37.631235],
              [-0.68948, 37.631391],
              [-0.689482, 37.631355],
              [-0.689656, 37.631224],
            ],
          ],
          [
            [
              [-0.68932, 37.631451],
              [-0.689445, 37.631436],
              [-0.689488, 37.631518],
              [-0.689226, 37.631558],
              [-0.68932, 37.631451],
            ],
          ],
          [
            [
              [-0.690919, 37.631762],
              [-0.690979, 37.631664],
              [-0.691135, 37.631739],
              [-0.690996, 37.631836],
              [-0.690919, 37.631762],
            ],
          ],
          [
            [
              [-1.494811, 37.422247],
              [-1.494869, 37.422157],
              [-1.495059, 37.42225],
              [-1.494889, 37.422311],
              [-1.494811, 37.422247],
            ],
          ],
          [
            [
              [-0.688533, 37.633797],
              [-0.688591, 37.633771],
              [-0.68868, 37.633809],
              [-0.688462, 37.633922],
              [-0.688533, 37.633797],
            ],
          ],
          [
            [
              [-1.022601, 37.573111],
              [-1.022703, 37.573094],
              [-1.022781, 37.573159],
              [-1.022643, 37.573247],
              [-1.022601, 37.573111],
            ],
          ],
          [
            [
              [-0.977285, 37.580733],
              [-0.977398, 37.580735],
              [-0.977429, 37.580834],
              [-0.977247, 37.580849],
              [-0.977285, 37.580733],
            ],
          ],
          [
            [
              [-0.977961, 37.581249],
              [-0.978063, 37.581269],
              [-0.97806, 37.581359],
              [-0.977947, 37.581357],
              [-0.977961, 37.581249],
            ],
          ],
          [
            [
              [-0.75858, 37.786308],
              [-0.758684, 37.786256],
              [-0.758762, 37.786302],
              [-0.758646, 37.78639],
              [-0.75858, 37.786308],
            ],
          ],
          [
            [
              [-0.74633, 37.785524],
              [-0.746478, 37.785509],
              [-0.746476, 37.785581],
              [-0.746338, 37.785614],
              [-0.74633, 37.785524],
            ],
          ],
          [
            [
              [-0.686903, 37.633386],
              [-0.687014, 37.633461],
              [-0.686876, 37.633539],
              [-0.686865, 37.63353],
              [-0.686903, 37.633386],
            ],
          ],
          [
            [
              [-0.760768, 37.84704],
              [-0.760864, 37.846998],
              [-0.760971, 37.847137],
              [-0.760887, 37.847157],
              [-0.760768, 37.84704],
            ],
          ],
          [
            [
              [-0.687286, 37.633457],
              [-0.687388, 37.63345],
              [-0.687236, 37.633609],
              [-0.687238, 37.633546],
              [-0.687286, 37.633457],
            ],
          ],
          [
            [
              [-0.688758, 37.632026],
              [-0.688824, 37.63209],
              [-0.688675, 37.632151],
              [-0.688712, 37.632052],
              [-0.688758, 37.632026],
            ],
          ],
          [
            [
              [-0.689495, 37.632004],
              [-0.68961, 37.631971],
              [-0.689653, 37.632026],
              [-0.689493, 37.632077],
              [-0.689495, 37.632004],
            ],
          ],
          [
            [
              [-0.689696, 37.632117],
              [-0.689809, 37.632128],
              [-0.689795, 37.632218],
              [-0.689749, 37.632244],
              [-0.689696, 37.632117],
            ],
          ],
          [
            [
              [-1.021935, 37.573478],
              [-1.022049, 37.573453],
              [-1.02208, 37.573535],
              [-1.021978, 37.573569],
              [-1.021935, 37.573478],
            ],
          ],
          [
            [
              [-0.757566, 37.792074],
              [-0.757657, 37.792058],
              [-0.757677, 37.792157],
              [-0.757563, 37.792164],
              [-0.757566, 37.792074],
            ],
          ],
          [
            [
              [-0.688523, 37.636707],
              [-0.688547, 37.636663],
              [-0.688671, 37.636665],
              [-0.6886, 37.636772],
              [-0.688523, 37.636707],
            ],
          ],
          [
            [
              [-0.967158, 37.559605],
              [-0.967215, 37.559588],
              [-0.967303, 37.559689],
              [-0.967178, 37.559696],
              [-0.967158, 37.559605],
            ],
          ],
          [
            [
              [-0.976446, 37.580745],
              [-0.976503, 37.580737],
              [-0.976535, 37.580828],
              [-0.976421, 37.580844],
              [-0.976446, 37.580745],
            ],
          ],
          [
            [
              [-0.759241, 37.786257],
              [-0.759366, 37.786242],
              [-0.759399, 37.786278],
              [-0.759307, 37.786322],
              [-0.759241, 37.786257],
            ],
          ],
          [
            [
              [-0.688593, 37.632248],
              [-0.688662, 37.632204],
              [-0.688693, 37.632295],
              [-0.688636, 37.63233],
              [-0.688593, 37.632248],
            ],
          ],
          [
            [
              [-0.977728, 37.581074],
              [-0.977752, 37.581029],
              [-0.977865, 37.58104],
              [-0.977864, 37.581085],
              [-0.977728, 37.581074],
            ],
          ],
          [
            [
              [-0.690821, 37.631291],
              [-0.690845, 37.631247],
              [-0.69092, 37.631366],
              [-0.690886, 37.631365],
              [-0.690821, 37.631291],
            ],
          ],
          [
            [
              [-0.688275, 37.632269],
              [-0.6884, 37.632244],
              [-0.68833, 37.632324],
              [-0.688275, 37.632269],
            ],
          ],
          [
            [
              [-1.022462, 37.573234],
              [-1.022486, 37.573172],
              [-1.022565, 37.573209],
              [-1.022519, 37.573235],
              [-1.022462, 37.573234],
            ],
          ],
          [
            [
              [-0.757891, 37.787313],
              [-0.757847, 37.787285],
              [-0.757949, 37.787287],
              [-0.757891, 37.787313],
            ],
          ],
          [
            [
              [-0.756719, 37.791544],
              [-0.756788, 37.791509],
              [-0.756775, 37.791554],
              [-0.756719, 37.791544],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5636",
      properties: { name: "Navarra", density: 0, path: "/world/Spain/Navarra" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.652305, 43.310241],
              [-1.624464, 43.305768],
              [-1.630021, 43.285035],
              [-1.60871, 43.251993],
              [-1.575656, 43.249939],
              [-1.55636, 43.279376],
              [-1.564076, 43.288783],
              [-1.535528, 43.294932],
              [-1.437833, 43.266861],
              [-1.413653, 43.273668],
              [-1.384323, 43.253673],
              [-1.381409, 43.18968],
              [-1.402231, 43.177898],
              [-1.414084, 43.128303],
              [-1.473407, 43.086635],
              [-1.441208, 43.046354],
              [-1.354169, 43.028124],
              [-1.332571, 43.10762],
              [-1.270036, 43.118262],
              [-1.308971, 43.071173],
              [-1.262745, 43.044023],
              [-1.212593, 43.051687],
              [-1.145597, 43.025821],
              [-1.141858, 43.007372],
              [-1.114678, 43.023929],
              [-1.081273, 43.000636],
              [-1.01212, 42.992575],
              [-0.945101, 42.953445],
              [-0.908514, 42.964479],
              [-0.809582, 42.951372],
              [-0.755934, 42.968054],
              [-0.72395, 42.922363],
              [-0.783213, 42.921725],
              [-0.819095, 42.900669],
              [-0.818533, 42.869367],
              [-0.856833, 42.846885],
              [-0.860007, 42.798562],
              [-0.847533, 42.78592],
              [-0.865552, 42.762854],
              [-0.900535, 42.760466],
              [-0.900499, 42.743086],
              [-0.922985, 42.744001],
              [-0.948676, 42.711452],
              [-1.027981, 42.70006],
              [-1.036808, 42.651638],
              [-1.051926, 42.642594],
              [-1.152046, 42.647884],
              [-1.157116, 42.610891],
              [-1.179775, 42.610624],
              [-1.158035, 42.599154],
              [-1.20192, 42.578239],
              [-1.202791, 42.54966],
              [-1.270712, 42.548941],
              [-1.293476, 42.510955],
              [-1.27278, 42.481899],
              [-1.341508, 42.423938],
              [-1.359905, 42.378039],
              [-1.329083, 42.355385],
              [-1.399082, 42.2913],
              [-1.391371, 42.272117],
              [-1.41753, 42.212368],
              [-1.391906, 42.181418],
              [-1.398881, 42.126219],
              [-1.36676, 42.106961],
              [-1.352277, 42.07307],
              [-1.310593, 42.072313],
              [-1.304822, 42.042949],
              [-1.418245, 41.913583],
              [-1.477602, 41.924905],
              [-1.559256, 41.913944],
              [-1.654146, 41.965351],
              [-1.715221, 41.956963],
              [-1.766896, 41.995789],
              [-1.833703, 41.995065],
              [-1.901577, 42.02968],
              [-1.902301, 42.073863],
              [-1.832417, 42.148231],
              [-1.779005, 42.137705],
              [-1.739742, 42.149736],
              [-1.719895, 42.13344],
              [-1.679659, 42.151689],
              [-1.700897, 42.173124],
              [-1.679528, 42.191144],
              [-1.70424, 42.192029],
              [-1.725345, 42.214538],
              [-1.802326, 42.223429],
              [-1.84979, 42.259254],
              [-1.872276, 42.252669],
              [-1.870658, 42.288966],
              [-1.913818, 42.300109],
              [-1.918513, 42.326965],
              [-1.964521, 42.33382],
              [-1.99552, 42.363608],
              [-2.089961, 42.346971],
              [-2.07864, 42.368126],
              [-2.115514, 42.399082],
              [-2.111902, 42.419956],
              [-2.175037, 42.407766],
              [-2.274484, 42.445472],
              [-2.288012, 42.46736],
              [-2.298719, 42.457412],
              [-2.329988, 42.468802],
              [-2.345036, 42.451301],
              [-2.352245, 42.465626],
              [-2.400172, 42.471785],
              [-2.423751, 42.509632],
              [-2.392202, 42.516952],
              [-2.396754, 42.5991],
              [-2.421121, 42.607124],
              [-2.453242, 42.567108],
              [-2.462205, 42.587386],
              [-2.490631, 42.585355],
              [-2.499314, 42.610475],
              [-2.415797, 42.66268],
              [-2.37626, 42.65071],
              [-2.381949, 42.634296],
              [-2.288481, 42.655069],
              [-2.293578, 42.677939],
              [-2.316864, 42.682502],
              [-2.324008, 42.723772],
              [-2.31685, 42.739345],
              [-2.262167, 42.748461],
              [-2.281011, 42.785134],
              [-2.233707, 42.840506],
              [-2.25307, 42.892774],
              [-2.237758, 42.895996],
              [-2.235566, 42.926608],
              [-2.210258, 42.949912],
              [-2.18096, 42.935126],
              [-2.117632, 42.942246],
              [-2.095235, 42.972553],
              [-2.038338, 42.980994],
              [-2.028306, 42.998838],
              [-2.041395, 43.0297],
              [-2.020441, 43.046731],
              [-2.022824, 43.065193],
              [-1.947525, 43.097527],
              [-1.901952, 43.135854],
              [-1.920688, 43.15063],
              [-1.914152, 43.225171],
              [-1.881908, 43.206788],
              [-1.880927, 43.228777],
              [-1.867694, 43.230502],
              [-1.860575, 43.212089],
              [-1.794975, 43.246929],
              [-1.783243, 43.28682],
              [-1.652305, 43.310241],
            ],
          ],
          [
            [
              [-1.096228, 42.485665],
              [-1.054361, 42.448804],
              [-1.05645, 42.434323],
              [-1.129364, 42.446722],
              [-1.096228, 42.485665],
            ],
          ],
          [
            [
              [-1.148586, 42.441036],
              [-1.158358, 42.410017],
              [-1.187799, 42.40303],
              [-1.182826, 42.424591],
              [-1.148586, 42.441036],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5617",
      properties: { name: "Ourense", density: 0, path: "/world/Spain/Ourense" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-7.920184, 42.566658],
              [-7.888519, 42.541467],
              [-7.924006, 42.525044],
              [-7.901601, 42.521361],
              [-7.913179, 42.508386],
              [-7.875414, 42.510948],
              [-7.801307, 42.480025],
              [-7.765027, 42.493958],
              [-7.74831, 42.479987],
              [-7.788352, 42.473483],
              [-7.788899, 42.465996],
              [-7.713175, 42.452378],
              [-7.697873, 42.429545],
              [-7.631413, 42.407055],
              [-7.619068, 42.384892],
              [-7.576121, 42.404868],
              [-7.541965, 42.384441],
              [-7.507581, 42.386783],
              [-7.399246, 42.43556],
              [-7.369901, 42.42136],
              [-7.306936, 42.429447],
              [-7.220066, 42.387188],
              [-7.230933, 42.359144],
              [-7.202672, 42.3257],
              [-7.149269, 42.436156],
              [-7.099742, 42.458785],
              [-7.077054, 42.50819],
              [-6.978542, 42.493253],
              [-6.960089, 42.517763],
              [-6.925054, 42.519439],
              [-6.860064, 42.488758],
              [-6.822782, 42.490856],
              [-6.808315, 42.468612],
              [-6.822768, 42.452913],
              [-6.814273, 42.427354],
              [-6.843513, 42.405846],
              [-6.733953, 42.359111],
              [-6.734291, 42.318688],
              [-6.766997, 42.257458],
              [-6.803175, 42.238667],
              [-6.78714, 42.215724],
              [-6.8436, 42.228304],
              [-6.878245, 42.219519],
              [-6.901078, 42.189623],
              [-6.942794, 42.173337],
              [-6.944552, 42.14168],
              [-6.989816, 42.121312],
              [-7.00269, 42.08417],
              [-7.033585, 42.074235],
              [-7.011832, 42.052416],
              [-6.974792, 42.055157],
              [-6.961098, 42.027906],
              [-7.006233, 41.950511],
              [-7.073515, 41.95031],
              [-7.087559, 41.973914],
              [-7.140567, 41.99083],
              [-7.178181, 41.97965],
              [-7.18992, 41.941755],
              [-7.173502, 41.919537],
              [-7.19729, 41.880058],
              [-7.23266, 41.867224],
              [-7.315809, 41.842838],
              [-7.367275, 41.851684],
              [-7.427291, 41.807478],
              [-7.425644, 41.83106],
              [-7.4528, 41.865141],
              [-7.502141, 41.869633],
              [-7.521085, 41.841921],
              [-7.613587, 41.829276],
              [-7.586268, 41.882027],
              [-7.653848, 41.881373],
              [-7.701158, 41.908055],
              [-7.844977, 41.881534],
              [-7.845609, 41.863942],
              [-7.876485, 41.849469],
              [-7.891927, 41.859929],
              [-7.884385, 41.924428],
              [-7.905781, 41.927367],
              [-7.920975, 41.879024],
              [-7.980862, 41.872366],
              [-8.013264, 41.828817],
              [-8.093866, 41.808948],
              [-8.16511, 41.818272],
              [-8.170605, 41.879047],
              [-8.199723, 41.874019],
              [-8.217023, 41.913629],
              [-8.163476, 41.982705],
              [-8.085462, 42.01698],
              [-8.084291, 42.047372],
              [-8.118517, 42.082111],
              [-8.187301, 42.074629],
              [-8.199042, 42.15441],
              [-8.161167, 42.179154],
              [-8.192264, 42.209122],
              [-8.164606, 42.221438],
              [-8.161844, 42.244115],
              [-8.22946, 42.232931],
              [-8.264581, 42.252863],
              [-8.261955, 42.31538],
              [-8.324701, 42.345047],
              [-8.314704, 42.380983],
              [-8.34202, 42.465702],
              [-8.366344, 42.487348],
              [-8.302728, 42.49021],
              [-8.17386, 42.571974],
              [-8.146835, 42.548671],
              [-8.090496, 42.557758],
              [-8.018629, 42.54222],
              [-7.992487, 42.572498],
              [-7.928003, 42.578529],
              [-7.920184, 42.566658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5640",
      properties: {
        name: "Palencia",
        density: 0,
        path: "/world/Spain/Palencia",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.456763, 43.057045],
              [-4.398114, 43.034868],
              [-4.348485, 42.972581],
              [-4.240249, 42.956533],
              [-4.236703, 42.917112],
              [-4.222565, 42.910978],
              [-4.224679, 42.85677],
              [-4.199694, 42.844571],
              [-4.181202, 42.858694],
              [-4.14426, 42.868615],
              [-4.125502, 42.854293],
              [-4.140447, 42.829002],
              [-4.159999, 42.836937],
              [-4.182411, 42.822597],
              [-4.18385, 42.812072],
              [-4.149915, 42.789309],
              [-4.098486, 42.791182],
              [-4.081895, 42.765259],
              [-4.109554, 42.733879],
              [-4.144969, 42.752516],
              [-4.257042, 42.688112],
              [-4.266693, 42.705296],
              [-4.293171, 42.690897],
              [-4.263873, 42.617956],
              [-4.293898, 42.605256],
              [-4.285435, 42.581981],
              [-4.270812, 42.581388],
              [-4.305346, 42.570806],
              [-4.303331, 42.555357],
              [-4.277691, 42.544685],
              [-4.272148, 42.519981],
              [-4.286204, 42.511971],
              [-4.247042, 42.494716],
              [-4.254393, 42.467916],
              [-4.242594, 42.456238],
              [-4.317094, 42.458928],
              [-4.33502, 42.419907],
              [-4.316228, 42.409984],
              [-4.330074, 42.40443],
              [-4.321588, 42.379899],
              [-4.272729, 42.385719],
              [-4.28052, 42.363785],
              [-4.264823, 42.352514],
              [-4.244106, 42.259404],
              [-4.228764, 42.250375],
              [-4.231439, 42.20845],
              [-4.188644, 42.17638],
              [-4.205013, 42.133609],
              [-4.120216, 42.128128],
              [-4.103779, 42.109689],
              [-4.056207, 42.100914],
              [-4.077486, 42.082676],
              [-4.103036, 42.087044],
              [-4.104106, 42.061627],
              [-4.079887, 42.036783],
              [-4.039248, 42.053352],
              [-3.981405, 42.050636],
              [-3.971074, 42.033215],
              [-4.014775, 41.998585],
              [-4.032977, 41.954082],
              [-3.891469, 41.996646],
              [-3.891981, 41.957939],
              [-3.929142, 41.959365],
              [-3.98134, 41.930703],
              [-3.9807, 41.900617],
              [-4.037575, 41.872772],
              [-4.088825, 41.87065],
              [-4.079319, 41.848448],
              [-4.090067, 41.830864],
              [-4.037998, 41.789852],
              [-4.040877, 41.768354],
              [-4.100272, 41.774709],
              [-4.123195, 41.807308],
              [-4.213947, 41.799406],
              [-4.236025, 41.813969],
              [-4.31764, 41.763444],
              [-4.389297, 41.787774],
              [-4.454238, 41.759166],
              [-4.501184, 41.771406],
              [-4.487341, 41.820096],
              [-4.461148, 41.82036],
              [-4.478314, 41.828589],
              [-4.473298, 41.843028],
              [-4.512124, 41.823304],
              [-4.549155, 41.831015],
              [-4.566083, 41.812574],
              [-4.631897, 41.861727],
              [-4.637305, 41.888111],
              [-4.669678, 41.900506],
              [-4.728247, 41.868828],
              [-4.758421, 41.825787],
              [-4.789248, 41.818024],
              [-4.869085, 41.931728],
              [-4.927936, 41.915029],
              [-4.949587, 41.933828],
              [-4.983683, 41.910026],
              [-5.00485, 41.937947],
              [-4.965539, 42.007057],
              [-4.936006, 42.017916],
              [-4.947625, 42.054312],
              [-4.904177, 42.078394],
              [-4.928707, 42.118314],
              [-4.927498, 42.150215],
              [-4.994307, 42.144903],
              [-5.026966, 42.153912],
              [-5.031895, 42.173629],
              [-5.012394, 42.18976],
              [-5.024667, 42.219894],
              [-5.009161, 42.231027],
              [-5.018808, 42.253859],
              [-5.002141, 42.289129],
              [-4.953448, 42.297156],
              [-4.942433, 42.327503],
              [-4.942497, 42.346549],
              [-4.984205, 42.337874],
              [-4.972553, 42.374385],
              [-4.903396, 42.385651],
              [-4.914315, 42.401192],
              [-4.904985, 42.424555],
              [-4.923985, 42.436143],
              [-4.899999, 42.450682],
              [-4.887987, 42.537028],
              [-4.915952, 42.554754],
              [-4.894656, 42.623864],
              [-4.900295, 42.67012],
              [-4.871669, 42.70799],
              [-4.915892, 42.72056],
              [-4.93636, 42.744467],
              [-4.895878, 42.785172],
              [-4.90286, 42.809974],
              [-4.888557, 42.841134],
              [-4.900319, 42.859994],
              [-4.865585, 42.860548],
              [-4.855501, 42.891707],
              [-4.809234, 42.930341],
              [-4.794815, 42.986651],
              [-4.756987, 43.015504],
              [-4.699508, 43.026656],
              [-4.638131, 43.016673],
              [-4.606084, 43.035767],
              [-4.558449, 43.019515],
              [-4.520932, 43.047368],
              [-4.475713, 43.038702],
              [-4.456763, 43.057045],
            ],
            [
              [-4.256698, 42.727974],
              [-4.251332, 42.726547],
              [-4.245059, 42.735973],
              [-4.260633, 42.738891],
              [-4.256698, 42.727974],
            ],
          ],
          [
            [
              [-4.00087, 42.8098],
              [-4.003094, 42.763356],
              [-4.045912, 42.766611],
              [-4.050217, 42.779801],
              [-4.014183, 42.806377],
              [-4.011038, 42.830378],
              [-4.00087, 42.8098],
            ],
          ],
          [
            [
              [-4.071988, 42.165343],
              [-4.062137, 42.13158],
              [-4.110037, 42.143186],
              [-4.111844, 42.158579],
              [-4.071988, 42.165343],
            ],
          ],
          [
            [
              [-4.098008, 42.82367],
              [-4.087709, 42.805595],
              [-4.101042, 42.793968],
              [-4.113077, 42.81669],
              [-4.098008, 42.82367],
            ],
          ],
          [
            [
              [-4.168587, 42.811535],
              [-4.174558, 42.811618],
              [-4.182344, 42.820346],
              [-4.163076, 42.820948],
              [-4.168587, 42.811535],
            ],
          ],
          [
            [
              [-4.030565, 42.079117],
              [-4.037694, 42.102587],
              [-4.033436, 42.106975],
              [-4.024374, 42.08355],
              [-4.030565, 42.079117],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5606",
      properties: {
        name: "Pontevedra",
        density: 0,
        path: "/world/Spain/Pontevedra",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-8.181012, 42.858278],
              [-8.116367, 42.841162],
              [-8.075085, 42.853313],
              [-8.046824, 42.831482],
              [-8.013591, 42.850205],
              [-7.968601, 42.849606],
              [-7.956408, 42.839746],
              [-7.980484, 42.811339],
              [-7.978411, 42.778704],
              [-7.93537, 42.739743],
              [-7.866392, 42.720636],
              [-7.861428, 42.706199],
              [-7.889713, 42.683709],
              [-7.892552, 42.629632],
              [-7.930061, 42.578828],
              [-7.992487, 42.572498],
              [-8.018629, 42.54222],
              [-8.090496, 42.557758],
              [-8.146835, 42.548671],
              [-8.17386, 42.571974],
              [-8.302728, 42.49021],
              [-8.366344, 42.487348],
              [-8.34202, 42.465702],
              [-8.314704, 42.380983],
              [-8.324701, 42.345047],
              [-8.261955, 42.31538],
              [-8.264581, 42.252863],
              [-8.22946, 42.232931],
              [-8.162682, 42.244887],
              [-8.164054, 42.222093],
              [-8.192264, 42.209122],
              [-8.161269, 42.178628],
              [-8.208085, 42.140485],
              [-8.250771, 42.137687],
              [-8.259222, 42.121022],
              [-8.322425, 42.101948],
              [-8.331899, 42.084204],
              [-8.523552, 42.07845],
              [-8.556736, 42.051402],
              [-8.637038, 42.047069],
              [-8.663502, 42.002209],
              [-8.744121, 41.96526],
              [-8.755365, 41.934817],
              [-8.871577, 41.866326],
              [-8.87576, 41.874102],
              [-8.881935, 41.881265],
              [-8.874162, 41.899047],
              [-8.888097, 41.936345],
              [-8.87683, 42.00244],
              [-8.88978, 42.02616],
              [-8.88396, 42.038288],
              [-8.885952, 42.05041],
              [-8.896751, 42.066814],
              [-8.891587, 42.082248],
              [-8.900677, 42.112287],
              [-8.851643, 42.128282],
              [-8.826037, 42.115079],
              [-8.820134, 42.141377],
              [-8.850199, 42.156514],
              [-8.82251, 42.157081],
              [-8.81595, 42.187464],
              [-8.797041, 42.197876],
              [-8.779419, 42.203621],
              [-8.777824, 42.219941],
              [-8.775227, 42.222134],
              [-8.712736, 42.241054],
              [-8.661195, 42.284884],
              [-8.610865, 42.287513],
              [-8.623732, 42.309868],
              [-8.611868, 42.345208],
              [-8.636779, 42.347494],
              [-8.636246, 42.326509],
              [-8.662269, 42.311135],
              [-8.651813, 42.297753],
              [-8.689669, 42.280004],
              [-8.731481, 42.286692],
              [-8.792562, 42.246345],
              [-8.813845, 42.259443],
              [-8.855193, 42.26141],
              [-8.873022, 42.249981],
              [-8.873954, 42.254137],
              [-8.868558, 42.256053],
              [-8.862535, 42.268946],
              [-8.855847, 42.309798],
              [-8.831602, 42.27809],
              [-8.816868, 42.278688],
              [-8.818436, 42.313518],
              [-8.839162, 42.339125],
              [-8.791129, 42.32704],
              [-8.755015, 42.339918],
              [-8.73686, 42.376605],
              [-8.696751, 42.393528],
              [-8.697988, 42.402613],
              [-8.686658, 42.402772],
              [-8.684958, 42.407475],
              [-8.656357, 42.423298],
              [-8.653602, 42.425766],
              [-8.651929, 42.428369],
              [-8.68116, 42.421068],
              [-8.695183, 42.437681],
              [-8.70359, 42.426963],
              [-8.741388, 42.413646],
              [-8.748743, 42.40323],
              [-8.757262, 42.402351],
              [-8.76167, 42.394396],
              [-8.770386, 42.394362],
              [-8.774713, 42.387662],
              [-8.813316, 42.40117],
              [-8.838902, 42.384274],
              [-8.880938, 42.414848],
              [-8.871187, 42.435262],
              [-8.882574, 42.454813],
              [-8.933298, 42.452991],
              [-8.937474, 42.479203],
              [-8.890834, 42.486542],
              [-8.889838, 42.480994],
              [-8.891114, 42.494809],
              [-8.884301, 42.496862],
              [-8.881921, 42.49937],
              [-8.87162, 42.501205],
              [-8.855513, 42.472749],
              [-8.872745, 42.461611],
              [-8.869431, 42.448054],
              [-8.855926, 42.450763],
              [-8.848103, 42.460328],
              [-8.819644, 42.45177],
              [-8.830887, 42.484334],
              [-8.80532, 42.500797],
              [-8.826294, 42.515328],
              [-8.816415, 42.520179],
              [-8.81347, 42.528944],
              [-8.828922, 42.518453],
              [-8.825504, 42.538836],
              [-8.836354, 42.558671],
              [-8.818596, 42.559272],
              [-8.834868, 42.564788],
              [-8.795175, 42.580069],
              [-8.785293, 42.599591],
              [-8.772215, 42.59324],
              [-8.779003, 42.614151],
              [-8.728266, 42.667668],
              [-8.718301, 42.706003],
              [-8.650944, 42.729153],
              [-8.606636, 42.715152],
              [-8.59993, 42.743118],
              [-8.556279, 42.745989],
              [-8.553449, 42.761304],
              [-8.431147, 42.746176],
              [-8.407003, 42.754209],
              [-8.414658, 42.766603],
              [-8.392079, 42.789359],
              [-8.354719, 42.778819],
              [-8.331633, 42.794429],
              [-8.339679, 42.840642],
              [-8.325286, 42.831186],
              [-8.315098, 42.845849],
              [-8.279473, 42.810897],
              [-8.254569, 42.836704],
              [-8.207272, 42.82516],
              [-8.2208, 42.844294],
              [-8.181012, 42.858278],
            ],
            [
              [-8.82467, 42.555856],
              [-8.824411, 42.554654],
              [-8.822702, 42.554336],
              [-8.82349, 42.555871],
              [-8.82467, 42.555856],
            ],
            [
              [-8.823031, 42.559046],
              [-8.823641, 42.558402],
              [-8.823797, 42.557332],
              [-8.822591, 42.557853],
              [-8.823031, 42.559046],
            ],
            [
              [-8.818149, 42.511746],
              [-8.817576, 42.512649],
              [-8.817764, 42.512972],
              [-8.818537, 42.512384],
              [-8.818149, 42.511746],
            ],
            [
              [-8.859803, 42.402918],
              [-8.85942, 42.403243],
              [-8.860195, 42.403996],
              [-8.860473, 42.403585],
              [-8.859803, 42.402918],
            ],
            [
              [-8.903296, 42.459028],
              [-8.903223, 42.459545],
              [-8.903981, 42.459722],
              [-8.904002, 42.459172],
              [-8.903296, 42.459028],
            ],
            [
              [-8.907176, 42.460853],
              [-8.905936, 42.460467],
              [-8.905701, 42.460551],
              [-8.906232, 42.461028],
              [-8.907176, 42.460853],
            ],
            [
              [-8.614507, 42.345962],
              [-8.614191, 42.346094],
              [-8.613888, 42.34637],
              [-8.61497, 42.346254],
              [-8.614507, 42.345962],
            ],
            [
              [-8.926321, 42.479062],
              [-8.926109, 42.479397],
              [-8.927135, 42.47983],
              [-8.926714, 42.479105],
              [-8.926321, 42.479062],
            ],
            [
              [-8.688523, 42.43326],
              [-8.688303, 42.433514],
              [-8.688303, 42.433784],
              [-8.688914, 42.433411],
              [-8.688523, 42.43326],
            ],
            [
              [-8.77745, 42.213739],
              [-8.777107, 42.213964],
              [-8.777272, 42.214307],
              [-8.777716, 42.214131],
              [-8.77745, 42.213739],
            ],
            [
              [-8.888192, 42.487029],
              [-8.888498, 42.487176],
              [-8.888646, 42.486934],
              [-8.887993, 42.486716],
              [-8.888192, 42.487029],
            ],
            [
              [-8.913558, 42.476033],
              [-8.913732, 42.476465],
              [-8.914106, 42.476563],
              [-8.913765, 42.47604],
              [-8.913558, 42.476033],
            ],
            [
              [-8.874404, 42.115521],
              [-8.873802, 42.115813],
              [-8.873799, 42.115912],
              [-8.874363, 42.11573],
              [-8.874404, 42.115521],
            ],
            [
              [-8.867868, 42.11871],
              [-8.868186, 42.119009],
              [-8.868424, 42.118969],
              [-8.868297, 42.118778],
              [-8.867868, 42.11871],
            ],
            [
              [-8.87479, 42.115123],
              [-8.874754, 42.11526],
              [-8.875267, 42.11544],
              [-8.875129, 42.115132],
              [-8.87479, 42.115123],
            ],
            [
              [-8.874995, 42.11459],
              [-8.875139, 42.114844],
              [-8.875465, 42.11471],
              [-8.875452, 42.114567],
              [-8.874995, 42.11459],
            ],
            [
              [-8.912001, 42.475645],
              [-8.911792, 42.475881],
              [-8.912333, 42.475817],
              [-8.912156, 42.47561],
              [-8.912001, 42.475645],
            ],
            [
              [-8.688749, 42.432663],
              [-8.688524, 42.432332],
              [-8.688154, 42.432405],
              [-8.688225, 42.432653],
              [-8.688749, 42.432663],
            ],
            [
              [-8.856081, 42.122528],
              [-8.855839, 42.122657],
              [-8.855882, 42.122862],
              [-8.856325, 42.122677],
              [-8.856081, 42.122528],
            ],
            [
              [-8.914308, 42.478569],
              [-8.914066, 42.478716],
              [-8.914638, 42.478587],
              [-8.914462, 42.478525],
              [-8.914308, 42.478569],
            ],
            [
              [-8.902752, 42.458165],
              [-8.902625, 42.458361],
              [-8.902678, 42.458538],
              [-8.902985, 42.458306],
              [-8.902752, 42.458165],
            ],
            [
              [-8.877053, 42.112666],
              [-8.876965, 42.112887],
              [-8.877351, 42.112885],
              [-8.877307, 42.112671],
              [-8.877053, 42.112666],
            ],
            [
              [-8.806187, 42.191345],
              [-8.806071, 42.191531],
              [-8.806203, 42.191785],
              [-8.806389, 42.191434],
              [-8.806187, 42.191345],
            ],
            [
              [-8.885913, 42.113165],
              [-8.885976, 42.113584],
              [-8.886271, 42.113506],
              [-8.886008, 42.113151],
              [-8.885913, 42.113165],
            ],
            [
              [-8.806948, 42.190802],
              [-8.806965, 42.191116],
              [-8.807173, 42.191142],
              [-8.807145, 42.190837],
              [-8.806948, 42.190802],
            ],
            [
              [-8.915528, 42.476552],
              [-8.915585, 42.476774],
              [-8.915827, 42.476762],
              [-8.915778, 42.476494],
              [-8.915528, 42.476552],
            ],
            [
              [-8.889012, 42.487725],
              [-8.888811, 42.48779],
              [-8.888771, 42.488008],
              [-8.889134, 42.487863],
              [-8.889012, 42.487725],
            ],
            [
              [-8.924474, 42.479851],
              [-8.924372, 42.480054],
              [-8.924677, 42.480066],
              [-8.924658, 42.479868],
              [-8.924474, 42.479851],
            ],
            [
              [-8.875627, 42.115809],
              [-8.87541, 42.115937],
              [-8.875757, 42.116036],
              [-8.875769, 42.115775],
              [-8.875627, 42.115809],
            ],
            [
              [-8.89983, 42.481082],
              [-8.899927, 42.481329],
              [-8.900098, 42.481338],
              [-8.900126, 42.48112],
              [-8.89983, 42.481082],
            ],
            [
              [-8.875715, 42.115444],
              [-8.875748, 42.115677],
              [-8.87593, 42.115685],
              [-8.875954, 42.115414],
              [-8.875715, 42.115444],
            ],
            [
              [-8.933539, 42.479137],
              [-8.933437, 42.479341],
              [-8.933788, 42.479322],
              [-8.933736, 42.479163],
              [-8.933539, 42.479137],
            ],
            [
              [-8.889422, 42.113587],
              [-8.889439, 42.113901],
              [-8.889615, 42.113973],
              [-8.889634, 42.113657],
              [-8.889422, 42.113587],
            ],
            [
              [-8.926153, 42.480502],
              [-8.925826, 42.480519],
              [-8.925787, 42.48062],
              [-8.926294, 42.480576],
              [-8.926153, 42.480502],
            ],
            [
              [-8.900838, 42.479769],
              [-8.900835, 42.480004],
              [-8.900959, 42.480024],
              [-8.901095, 42.479792],
              [-8.900838, 42.479769],
            ],
            [
              [-8.826974, 42.325308],
              [-8.82681, 42.32537],
              [-8.826801, 42.325532],
              [-8.827111, 42.325472],
              [-8.826974, 42.325308],
            ],
            [
              [-8.917926, 42.454454],
              [-8.917987, 42.454586],
              [-8.918248, 42.454645],
              [-8.918088, 42.454365],
              [-8.917926, 42.454454],
            ],
            [
              [-8.915384, 42.476703],
              [-8.915257, 42.476521],
              [-8.91504, 42.476667],
              [-8.915063, 42.476783],
              [-8.915384, 42.476703],
            ],
            [
              [-8.918716, 42.457924],
              [-8.918506, 42.458151],
              [-8.918503, 42.45825],
              [-8.918771, 42.458119],
              [-8.918716, 42.457924],
            ],
            [
              [-8.915054, 42.460085],
              [-8.915194, 42.460276],
              [-8.91539, 42.460167],
              [-8.915281, 42.460038],
              [-8.915054, 42.460085],
            ],
            [
              [-8.828224, 42.327539],
              [-8.82819, 42.327694],
              [-8.82855, 42.327649],
              [-8.828456, 42.327555],
              [-8.828224, 42.327539],
            ],
            [
              [-8.862455, 42.12176],
              [-8.862418, 42.121879],
              [-8.862765, 42.121969],
              [-8.862729, 42.121845],
              [-8.862455, 42.12176],
            ],
            [
              [-8.861032, 42.403719],
              [-8.860822, 42.403811],
              [-8.860834, 42.403945],
              [-8.861161, 42.403802],
              [-8.861032, 42.403719],
            ],
            [
              [-8.919753, 42.455908],
              [-8.919942, 42.455844],
              [-8.919899, 42.455649],
              [-8.919685, 42.455705],
              [-8.919753, 42.455908],
            ],
            [
              [-8.914298, 42.4764],
              [-8.914409, 42.476547],
              [-8.914735, 42.47653],
              [-8.914417, 42.476376],
              [-8.914298, 42.4764],
            ],
            [
              [-8.816411, 42.172866],
              [-8.816331, 42.17305],
              [-8.816714, 42.172878],
              [-8.816626, 42.172837],
              [-8.816411, 42.172866],
            ],
            [
              [-8.861362, 42.404521],
              [-8.8615, 42.404442],
              [-8.861462, 42.404291],
              [-8.861224, 42.404348],
              [-8.861362, 42.404521],
            ],
            [
              [-8.901678, 42.458374],
              [-8.901684, 42.458184],
              [-8.901435, 42.458242],
              [-8.901457, 42.458349],
              [-8.901678, 42.458374],
            ],
            [
              [-8.91613, 42.458994],
              [-8.916053, 42.459205],
              [-8.916284, 42.459202],
              [-8.916296, 42.459076],
              [-8.91613, 42.458994],
            ],
            [
              [-8.891866, 42.456288],
              [-8.891892, 42.45644],
              [-8.892132, 42.4564],
              [-8.892046, 42.456261],
              [-8.891866, 42.456288],
            ],
            [
              [-8.903142, 42.45882],
              [-8.903224, 42.458654],
              [-8.903093, 42.458553],
              [-8.902991, 42.458765],
              [-8.903142, 42.45882],
            ],
            [
              [-8.91602, 42.477382],
              [-8.916096, 42.47754],
              [-8.91632, 42.477474],
              [-8.916184, 42.477319],
              [-8.91602, 42.477382],
            ],
            [
              [-8.792962, 42.39646],
              [-8.793046, 42.396708],
              [-8.793189, 42.396674],
              [-8.793131, 42.396443],
              [-8.792962, 42.39646],
            ],
            [
              [-8.826869, 42.324953],
              [-8.826872, 42.325124],
              [-8.82711, 42.325067],
              [-8.827122, 42.32494],
              [-8.826869, 42.324953],
            ],
            [
              [-8.934052, 42.479417],
              [-8.934005, 42.479563],
              [-8.934238, 42.479578],
              [-8.934238, 42.479452],
              [-8.934052, 42.479417],
            ],
            [
              [-8.914817, 42.460539],
              [-8.914744, 42.46066],
              [-8.915038, 42.460689],
              [-8.91499, 42.460566],
              [-8.914817, 42.460539],
            ],
            [
              [-8.818034, 42.169201],
              [-8.81804, 42.169399],
              [-8.81815, 42.169411],
              [-8.818229, 42.169218],
              [-8.818034, 42.169201],
            ],
            [
              [-8.919718, 42.454506],
              [-8.919757, 42.454666],
              [-8.919956, 42.454583],
              [-8.919873, 42.45448],
              [-8.919718, 42.454506],
            ],
            [
              [-8.916178, 42.477635],
              [-8.916128, 42.477754],
              [-8.916456, 42.477746],
              [-8.916384, 42.477633],
              [-8.916178, 42.477635],
            ],
            [
              [-8.928821, 42.4785],
              [-8.9289, 42.478307],
              [-8.928671, 42.478328],
              [-8.928677, 42.478391],
              [-8.928821, 42.4785],
            ],
            [
              [-8.902903, 42.457959],
              [-8.902871, 42.458006],
              [-8.903285, 42.458138],
              [-8.903212, 42.458015],
              [-8.902903, 42.457959],
            ],
            [
              [-8.893064, 42.456883],
              [-8.893163, 42.457022],
              [-8.893405, 42.45701],
              [-8.893309, 42.456898],
              [-8.893064, 42.456883],
            ],
            [
              [-8.816291, 42.172611],
              [-8.816338, 42.172735],
              [-8.816573, 42.172651],
              [-8.816344, 42.172537],
              [-8.816291, 42.172611],
            ],
            [
              [-8.918729, 42.454593],
              [-8.918693, 42.45473],
              [-8.918948, 42.454726],
              [-8.91895, 42.454617],
              [-8.918729, 42.454593],
            ],
            [
              [-8.910871, 42.476172],
              [-8.910878, 42.475992],
              [-8.910657, 42.475967],
              [-8.910735, 42.476143],
              [-8.910871, 42.476172],
            ],
            [
              [-8.815966, 42.171584],
              [-8.816049, 42.171435],
              [-8.815781, 42.171413],
              [-8.815818, 42.171555],
              [-8.815966, 42.171584],
            ],
            [
              [-8.922896, 42.478466],
              [-8.922925, 42.478644],
              [-8.923093, 42.478617],
              [-8.923004, 42.478451],
              [-8.922896, 42.478466],
            ],
            [
              [-8.916985, 42.458257],
              [-8.917014, 42.458444],
              [-8.917202, 42.458372],
              [-8.917105, 42.458242],
              [-8.916985, 42.458257],
            ],
            [
              [-8.916095, 42.476766],
              [-8.916109, 42.476909],
              [-8.916352, 42.476779],
              [-8.916223, 42.476705],
              [-8.916095, 42.476766],
            ],
            [
              [-8.826265, 42.325506],
              [-8.826137, 42.325566],
              [-8.826172, 42.325682],
              [-8.826385, 42.325626],
              [-8.826265, 42.325506],
            ],
            [
              [-8.926197, 42.480446],
              [-8.92635, 42.480393],
              [-8.92607, 42.480263],
              [-8.926032, 42.480373],
              [-8.926197, 42.480446],
            ],
            [
              [-8.90205, 42.458328],
              [-8.902076, 42.45847],
              [-8.902274, 42.458379],
              [-8.902208, 42.458319],
              [-8.90205, 42.458328],
            ],
            [
              [-8.8785, 42.111322],
              [-8.878575, 42.111481],
              [-8.878751, 42.111418],
              [-8.87872, 42.111347],
              [-8.8785, 42.111322],
            ],
            [
              [-8.807462, 42.190992],
              [-8.807585, 42.190878],
              [-8.807489, 42.190757],
              [-8.807362, 42.190826],
              [-8.807462, 42.190992],
            ],
            [
              [-8.915552, 42.478477],
              [-8.915638, 42.478356],
              [-8.915442, 42.478213],
              [-8.915429, 42.478331],
              [-8.915552, 42.478477],
            ],
            [
              [-8.818452, 42.168477],
              [-8.818523, 42.16833],
              [-8.818286, 42.168252],
              [-8.818293, 42.168333],
              [-8.818452, 42.168477],
            ],
            [
              [-8.914791, 42.477247],
              [-8.914778, 42.477104],
              [-8.914573, 42.477124],
              [-8.914596, 42.477239],
              [-8.914791, 42.477247],
            ],
            [
              [-8.932662, 42.479507],
              [-8.932676, 42.47965],
              [-8.932936, 42.479574],
              [-8.93293, 42.479511],
              [-8.932662, 42.479507],
            ],
            [
              [-8.764472, 42.225188],
              [-8.764614, 42.22528],
              [-8.764793, 42.225253],
              [-8.764648, 42.225125],
              [-8.764472, 42.225188],
            ],
            [
              [-8.776947, 42.214458],
              [-8.777044, 42.214597],
              [-8.777211, 42.214562],
              [-8.777028, 42.214418],
              [-8.776947, 42.214458],
            ],
            [
              [-8.913744, 42.476716],
              [-8.913744, 42.476851],
              [-8.913944, 42.476778],
              [-8.913873, 42.476674],
              [-8.913744, 42.476716],
            ],
            [
              [-8.861121, 42.40402],
              [-8.861121, 42.404155],
              [-8.861308, 42.404074],
              [-8.861252, 42.403996],
              [-8.861121, 42.40402],
            ],
            [
              [-8.903779, 42.458733],
              [-8.9038, 42.458831],
              [-8.903974, 42.458876],
              [-8.903986, 42.458741],
              [-8.903779, 42.458733],
            ],
            [
              [-8.893403, 42.45674],
              [-8.893414, 42.456847],
              [-8.893653, 42.456808],
              [-8.893658, 42.456727],
              [-8.893403, 42.45674],
            ],
            [
              [-8.892396, 42.456495],
              [-8.892256, 42.456556],
              [-8.892314, 42.456661],
              [-8.892471, 42.456644],
              [-8.892396, 42.456495],
            ],
            [
              [-8.914349, 42.476946],
              [-8.914275, 42.477058],
              [-8.914519, 42.476946],
              [-8.914404, 42.47688],
              [-8.914349, 42.476946],
            ],
            [
              [-8.879268, 42.111256],
              [-8.879154, 42.111334],
              [-8.879456, 42.111336],
              [-8.879437, 42.111256],
              [-8.879268, 42.111256],
            ],
            [
              [-8.827302, 42.324913],
              [-8.827447, 42.324905],
              [-8.827485, 42.324795],
              [-8.827289, 42.324778],
              [-8.827302, 42.324913],
            ],
            [
              [-8.915786, 42.477484],
              [-8.915881, 42.477452],
              [-8.915832, 42.477319],
              [-8.915655, 42.477383],
              [-8.915786, 42.477484],
            ],
            [
              [-8.776815, 42.214609],
              [-8.776842, 42.214769],
              [-8.777006, 42.214707],
              [-8.776988, 42.214645],
              [-8.776815, 42.214609],
            ],
            [
              [-8.891733, 42.455908],
              [-8.891705, 42.455999],
              [-8.891905, 42.456061],
              [-8.891894, 42.455944],
              [-8.891733, 42.455908],
            ],
            [
              [-8.894377, 42.457013],
              [-8.89433, 42.457151],
              [-8.894486, 42.457134],
              [-8.894536, 42.457023],
              [-8.894377, 42.457013],
            ],
            [
              [-8.928288, 42.478519],
              [-8.928274, 42.478367],
              [-8.928128, 42.478365],
              [-8.928127, 42.478491],
              [-8.928288, 42.478519],
            ],
            [
              [-8.918418, 42.454906],
              [-8.918412, 42.454843],
              [-8.918178, 42.454802],
              [-8.918214, 42.454935],
              [-8.918418, 42.454906],
            ],
            [
              [-8.885572, 42.113146],
              [-8.885479, 42.113178],
              [-8.88544, 42.113279],
              [-8.885677, 42.113231],
              [-8.885572, 42.113146],
            ],
            [
              [-8.894222, 42.456913],
              [-8.894313, 42.456846],
              [-8.894305, 42.456765],
              [-8.894147, 42.456764],
              [-8.894222, 42.456913],
            ],
            [
              [-8.904338, 42.458992],
              [-8.904339, 42.459136],
              [-8.904509, 42.459128],
              [-8.904487, 42.45903],
              [-8.904338, 42.458992],
            ],
            [
              [-8.928506, 42.478382],
              [-8.928486, 42.478554],
              [-8.92864, 42.47851],
              [-8.928606, 42.478412],
              [-8.928506, 42.478382],
            ],
            [
              [-8.876296, 42.113632],
              [-8.876286, 42.113525],
              [-8.876115, 42.113506],
              [-8.876165, 42.113657],
              [-8.876296, 42.113632],
            ],
            [
              [-8.918425, 42.454591],
              [-8.918508, 42.454568],
              [-8.918402, 42.454349],
              [-8.918375, 42.454449],
              [-8.918425, 42.454591],
            ],
            [
              [-8.904647, 42.458535],
              [-8.904655, 42.458616],
              [-8.904845, 42.45857],
              [-8.904803, 42.458509],
              [-8.904647, 42.458535],
            ],
            [
              [-8.892502, 42.456723],
              [-8.892513, 42.45684],
              [-8.89269, 42.456776],
              [-8.892673, 42.456723],
              [-8.892502, 42.456723],
            ],
            [
              [-8.918815, 42.454985],
              [-8.918766, 42.454852],
              [-8.918634, 42.454877],
              [-8.918683, 42.455009],
              [-8.918815, 42.454985],
            ],
            [
              [-8.916863, 42.458254],
              [-8.916887, 42.458127],
              [-8.916744, 42.458152],
              [-8.916779, 42.458268],
              [-8.916863, 42.458254],
            ],
            [
              [-8.894106, 42.457225],
              [-8.893966, 42.457287],
              [-8.893982, 42.457331],
              [-8.894206, 42.457256],
              [-8.894106, 42.457225],
            ],
            [
              [-8.878657, 42.498809],
              [-8.878659, 42.498953],
              [-8.878788, 42.49891],
              [-8.878779, 42.498812],
              [-8.878657, 42.498809],
            ],
            [
              [-8.827236, 42.332325],
              [-8.827268, 42.332413],
              [-8.82739, 42.332416],
              [-8.827381, 42.332317],
              [-8.827236, 42.332325],
            ],
            [
              [-8.827155, 42.3325],
              [-8.827224, 42.332586],
              [-8.827286, 42.332466],
              [-8.827174, 42.332436],
              [-8.827155, 42.3325],
            ],
            [
              [-8.782177, 42.395134],
              [-8.782176, 42.395251],
              [-8.782263, 42.395274],
              [-8.782276, 42.395156],
              [-8.782177, 42.395134],
            ],
            [
              [-8.905133, 42.459428],
              [-8.905215, 42.459523],
              [-8.905298, 42.459501],
              [-8.905254, 42.459422],
              [-8.905133, 42.459428],
            ],
            [
              [-8.893966, 42.457035],
              [-8.893901, 42.457119],
              [-8.894078, 42.457065],
              [-8.894063, 42.45703],
              [-8.893966, 42.457035],
            ],
            [
              [-8.879173, 42.11154],
              [-8.87907, 42.111473],
              [-8.879017, 42.111548],
              [-8.879118, 42.111597],
              [-8.879173, 42.11154],
            ],
            [
              [-8.860558, 42.403716],
              [-8.860567, 42.403815],
              [-8.860681, 42.403737],
              [-8.860654, 42.403702],
              [-8.860558, 42.403716],
            ],
            [
              [-8.827047, 42.325178],
              [-8.827006, 42.325261],
              [-8.82716, 42.325217],
              [-8.827132, 42.325174],
              [-8.827047, 42.325178],
            ],
          ],
          [
            [
              [-8.876723, 42.574866],
              [-8.855878, 42.56599],
              [-8.871754, 42.524135],
              [-8.875341, 42.529793],
              [-8.881804, 42.530189],
              [-8.870013, 42.560907],
              [-8.874479, 42.563324],
              [-8.880742, 42.557681],
              [-8.891915, 42.568466],
              [-8.876723, 42.574866],
            ],
            [
              [-8.874418, 42.534998],
              [-8.874183, 42.535604],
              [-8.874245, 42.536258],
              [-8.875425, 42.534964],
              [-8.874418, 42.534998],
            ],
            [
              [-8.87718, 42.535891],
              [-8.876841, 42.536809],
              [-8.877208, 42.537483],
              [-8.877528, 42.536612],
              [-8.87718, 42.535891],
            ],
            [
              [-8.871549, 42.534903],
              [-8.871108, 42.535259],
              [-8.871729, 42.53565],
              [-8.87197, 42.535367],
              [-8.871549, 42.534903],
            ],
            [
              [-8.873896, 42.547691],
              [-8.873863, 42.548503],
              [-8.874319, 42.548434],
              [-8.874006, 42.547694],
              [-8.873896, 42.547691],
            ],
            [
              [-8.874582, 42.54761],
              [-8.874641, 42.548102],
              [-8.874853, 42.548155],
              [-8.875024, 42.547777],
              [-8.874582, 42.54761],
            ],
            [
              [-8.865247, 42.543742],
              [-8.864888, 42.543815],
              [-8.864813, 42.543927],
              [-8.865384, 42.544041],
              [-8.865247, 42.543742],
            ],
          ],
          [
            [
              [-8.921711, 42.399699],
              [-8.936552, 42.3568],
              [-8.944219, 42.355087],
              [-8.945072, 42.380401],
              [-8.932115, 42.398241],
              [-8.921711, 42.399699],
            ],
            [
              [-8.934202, 42.389995],
              [-8.934168, 42.390536],
              [-8.934661, 42.390745],
              [-8.934677, 42.390141],
              [-8.934202, 42.389995],
            ],
            [
              [-8.930928, 42.373331],
              [-8.93085, 42.373533],
              [-8.931349, 42.373678],
              [-8.931301, 42.373429],
              [-8.930928, 42.373331],
            ],
            [
              [-8.939925, 42.369371],
              [-8.939813, 42.369719],
              [-8.939971, 42.369855],
              [-8.940228, 42.36949],
              [-8.939925, 42.369371],
            ],
            [
              [-8.930066, 42.376806],
              [-8.930259, 42.376661],
              [-8.929961, 42.376469],
              [-8.929836, 42.376683],
              [-8.930066, 42.376806],
            ],
            [
              [-8.932693, 42.367983],
              [-8.932766, 42.36824],
              [-8.933011, 42.368011],
              [-8.93289, 42.367882],
              [-8.932693, 42.367983],
            ],
            [
              [-8.943834, 42.374371],
              [-8.943763, 42.374654],
              [-8.943795, 42.374733],
              [-8.944009, 42.374551],
              [-8.943834, 42.374371],
            ],
            [
              [-8.934125, 42.38944],
              [-8.934094, 42.389622],
              [-8.934358, 42.38959],
              [-8.934346, 42.389465],
              [-8.934125, 42.38944],
            ],
            [
              [-8.929892, 42.377922],
              [-8.92985, 42.378122],
              [-8.930014, 42.378186],
              [-8.930069, 42.377994],
              [-8.929892, 42.377922],
            ],
            [
              [-8.930263, 42.377354],
              [-8.930115, 42.377451],
              [-8.930222, 42.377563],
              [-8.930405, 42.377436],
              [-8.930263, 42.377354],
            ],
          ],
          [
            [
              [-8.913968, 42.251462],
              [-8.895472, 42.227401],
              [-8.913917, 42.211343],
              [-8.903507, 42.22434],
              [-8.917597, 42.248429],
              [-8.913968, 42.251462],
            ],
            [
              [-8.9117, 42.243288],
              [-8.911437, 42.24359],
              [-8.911988, 42.243642],
              [-8.91199, 42.243534],
              [-8.9117, 42.243288],
            ],
            [
              [-8.908951, 42.239416],
              [-8.909133, 42.239676],
              [-8.909406, 42.239617],
              [-8.909397, 42.239527],
              [-8.908951, 42.239416],
            ],
            [
              [-8.906713, 42.237506],
              [-8.906721, 42.237721],
              [-8.906866, 42.237723],
              [-8.906916, 42.237603],
              [-8.906713, 42.237506],
            ],
            [
              [-8.910151, 42.242792],
              [-8.910202, 42.242943],
              [-8.910355, 42.242899],
              [-8.910268, 42.24275],
              [-8.910151, 42.242792],
            ],
          ],
          [
            [
              [-8.907402, 42.205388],
              [-8.892017, 42.196334],
              [-8.889655, 42.191126],
              [-8.91268, 42.195546],
              [-8.907402, 42.205388],
            ],
            [
              [-8.911229, 42.196467],
              [-8.911248, 42.196664],
              [-8.911572, 42.196503],
              [-8.91146, 42.196473],
              [-8.911229, 42.196467],
            ],
            [
              [-8.911777, 42.196628],
              [-8.911816, 42.196788],
              [-8.911992, 42.196599],
              [-8.911842, 42.196543],
              [-8.911777, 42.196628],
            ],
            [
              [-8.910144, 42.197027],
              [-8.91016, 42.197206],
              [-8.910326, 42.197162],
              [-8.910325, 42.197027],
              [-8.910144, 42.197027],
            ],
            [
              [-8.910852, 42.196703],
              [-8.910868, 42.196882],
              [-8.911, 42.196866],
              [-8.911008, 42.196694],
              [-8.910852, 42.196703],
            ],
          ],
          [
            [
              [-8.84195, 42.500827],
              [-8.844116, 42.485476],
              [-8.852396, 42.482973],
              [-8.852902, 42.489293],
              [-8.84195, 42.500827],
            ],
          ],
          [
            [
              [-8.778817, 42.620687],
              [-8.786296, 42.614069],
              [-8.790441, 42.616569],
              [-8.786998, 42.620965],
              [-8.778817, 42.620687],
            ],
          ],
          [
            [
              [-8.706255, 42.410041],
              [-8.708175, 42.407641],
              [-8.712418, 42.412623],
              [-8.708051, 42.414696],
              [-8.706255, 42.410041],
            ],
          ],
          [
            [
              [-8.93556, 42.348993],
              [-8.933139, 42.348795],
              [-8.932723, 42.345161],
              [-8.938057, 42.342922],
              [-8.93556, 42.348993],
            ],
          ],
          [
            [
              [-8.897419, 42.543589],
              [-8.900764, 42.537376],
              [-8.902925, 42.537957],
              [-8.904154, 42.541179],
              [-8.897419, 42.543589],
            ],
          ],
          [
            [
              [-8.833936, 42.491335],
              [-8.833828, 42.489396],
              [-8.836964, 42.489893],
              [-8.833716, 42.497197],
              [-8.833936, 42.491335],
            ],
          ],
          [
            [
              [-8.855624, 42.148585],
              [-8.853717, 42.146801],
              [-8.853337, 42.145047],
              [-8.85725, 42.146161],
              [-8.855624, 42.148585],
            ],
          ],
          [
            [
              [-8.799751, 42.201682],
              [-8.80173, 42.199367],
              [-8.804842, 42.200298],
              [-8.80026, 42.203006],
              [-8.799751, 42.201682],
            ],
          ],
          [
            [
              [-8.915523, 42.547594],
              [-8.913354, 42.545645],
              [-8.914059, 42.543574],
              [-8.914603, 42.544563],
              [-8.917052, 42.54549],
              [-8.915523, 42.547594],
            ],
          ],
          [
            [
              [-8.864023, 42.147713],
              [-8.862756, 42.145402],
              [-8.866055, 42.144972],
              [-8.86652, 42.146991],
              [-8.864023, 42.147713],
            ],
          ],
          [
            [
              [-8.856931, 42.463729],
              [-8.855801, 42.461788],
              [-8.860594, 42.465971],
              [-8.858508, 42.465376],
              [-8.856931, 42.463729],
            ],
          ],
          [
            [
              [-8.906959, 42.54522],
              [-8.905994, 42.543767],
              [-8.908191, 42.54666],
              [-8.907137, 42.546462],
              [-8.906959, 42.54522],
            ],
          ],
          [
            [
              [-8.837661, 42.551943],
              [-8.837802, 42.550199],
              [-8.83941, 42.550603],
              [-8.83889, 42.552331],
              [-8.837661, 42.551943],
            ],
          ],
          [
            [
              [-8.907104, 42.534266],
              [-8.907272, 42.533087],
              [-8.909085, 42.532435],
              [-8.909492, 42.533783],
              [-8.907104, 42.534266],
            ],
          ],
          [
            [
              [-8.85092, 42.457951],
              [-8.854419, 42.455459],
              [-8.854946, 42.455765],
              [-8.855114, 42.457169],
              [-8.85092, 42.457951],
            ],
          ],
          [
            [
              [-8.835754, 42.482789],
              [-8.836787, 42.480567],
              [-8.838754, 42.482618],
              [-8.838059, 42.483122],
              [-8.835754, 42.482789],
            ],
          ],
          [
            [
              [-8.628046, 42.307928],
              [-8.627906, 42.306908],
              [-8.628985, 42.306081],
              [-8.629268, 42.3083],
              [-8.628046, 42.307928],
            ],
          ],
          [
            [
              [-8.818496, 42.498091],
              [-8.818721, 42.497242],
              [-8.820412, 42.49658],
              [-8.819484, 42.498508],
              [-8.818496, 42.498091],
            ],
          ],
          [
            [
              [-8.816007, 42.497353],
              [-8.814809, 42.497549],
              [-8.814152, 42.496899],
              [-8.815908, 42.4959],
              [-8.816007, 42.497353],
            ],
          ],
          [
            [
              [-8.796236, 42.60982],
              [-8.797854, 42.609666],
              [-8.797155, 42.611583],
              [-8.795725, 42.610224],
              [-8.796236, 42.60982],
            ],
          ],
          [
            [
              [-8.948423, 42.366291],
              [-8.949311, 42.366704],
              [-8.949304, 42.36765],
              [-8.947762, 42.367523],
              [-8.948423, 42.366291],
            ],
          ],
          [
            [
              [-8.733013, 42.665971],
              [-8.733265, 42.666193],
              [-8.730679, 42.668834],
              [-8.731023, 42.667007],
              [-8.733013, 42.665971],
            ],
          ],
          [
            [
              [-8.905917, 42.542186],
              [-8.905431, 42.541167],
              [-8.905777, 42.539934],
              [-8.906927, 42.541531],
              [-8.905917, 42.542186],
            ],
          ],
          [
            [
              [-8.942857, 42.464313],
              [-8.942073, 42.463859],
              [-8.942882, 42.462646],
              [-8.944196, 42.463433],
              [-8.942857, 42.464313],
            ],
          ],
          [
            [
              [-8.943989, 42.477127],
              [-8.945425, 42.476755],
              [-8.944619, 42.478507],
              [-8.943835, 42.477936],
              [-8.943989, 42.477127],
            ],
          ],
          [
            [
              [-8.831213, 42.541696],
              [-8.831591, 42.541181],
              [-8.832828, 42.541919],
              [-8.831509, 42.542527],
              [-8.831213, 42.541696],
            ],
          ],
          [
            [
              [-8.888556, 42.042931],
              [-8.889057, 42.042203],
              [-8.891348, 42.042301],
              [-8.891286, 42.042538],
              [-8.888556, 42.042931],
            ],
          ],
          [
            [
              [-8.912013, 42.543824],
              [-8.912835, 42.544151],
              [-8.912618, 42.545584],
              [-8.911802, 42.545329],
              [-8.912013, 42.543824],
            ],
          ],
          [
            [
              [-8.75342, 42.350603],
              [-8.753085, 42.34999],
              [-8.754421, 42.34895],
              [-8.75431, 42.350522],
              [-8.75342, 42.350603],
            ],
          ],
          [
            [
              [-8.79316, 42.612785],
              [-8.792456, 42.612416],
              [-8.792187, 42.611493],
              [-8.793881, 42.612542],
              [-8.79316, 42.612785],
            ],
          ],
          [
            [
              [-8.877991, 42.147419],
              [-8.876001, 42.147089],
              [-8.875853, 42.146665],
              [-8.877267, 42.146133],
              [-8.877991, 42.147419],
            ],
          ],
          [
            [
              [-8.850804, 42.464385],
              [-8.849472, 42.464957],
              [-8.850762, 42.463676],
              [-8.851607, 42.465559],
              [-8.851279, 42.466089],
              [-8.850804, 42.464385],
            ],
          ],
          [
            [
              [-8.829054, 42.537089],
              [-8.830069, 42.53701],
              [-8.829494, 42.538543],
              [-8.828857, 42.537585],
              [-8.829054, 42.537089],
            ],
          ],
          [
            [
              [-8.916025, 42.217175],
              [-8.915516, 42.216265],
              [-8.917339, 42.216981],
              [-8.916532, 42.217293],
              [-8.916025, 42.217175],
            ],
          ],
          [
            [
              [-8.87361, 42.554619],
              [-8.874643, 42.554467],
              [-8.874649, 42.555565],
              [-8.874315, 42.555636],
              [-8.87361, 42.554619],
            ],
          ],
          [
            [
              [-8.898463, 42.113823],
              [-8.899445, 42.113727],
              [-8.899459, 42.114267],
              [-8.898177, 42.114774],
              [-8.898463, 42.113823],
            ],
          ],
          [
            [
              [-8.84995, 42.46721],
              [-8.848675, 42.466717],
              [-8.847655, 42.465428],
              [-8.851893, 42.46693],
              [-8.84995, 42.46721],
            ],
          ],
          [
            [
              [-8.89835, 42.215028],
              [-8.897459, 42.214174],
              [-8.898218, 42.213621],
              [-8.899, 42.215246],
              [-8.89835, 42.215028],
            ],
          ],
          [
            [
              [-8.827271, 42.533633],
              [-8.827998, 42.534514],
              [-8.827214, 42.535238],
              [-8.827054, 42.534958],
              [-8.827271, 42.533633],
            ],
          ],
          [
            [
              [-8.887297, 42.496518],
              [-8.887614, 42.496907],
              [-8.886542, 42.498187],
              [-8.886648, 42.497119],
              [-8.887297, 42.496518],
            ],
          ],
          [
            [
              [-8.629051, 42.309265],
              [-8.63008, 42.309106],
              [-8.630139, 42.309904],
              [-8.629317, 42.309936],
              [-8.629051, 42.309265],
            ],
          ],
          [
            [
              [-8.827433, 42.530475],
              [-8.827978, 42.531647],
              [-8.827273, 42.532078],
              [-8.827242, 42.531397],
              [-8.827433, 42.530475],
            ],
          ],
          [
            [
              [-8.736135, 42.664374],
              [-8.736183, 42.664102],
              [-8.738076, 42.66334],
              [-8.737153, 42.664305],
              [-8.736135, 42.664374],
            ],
          ],
          [
            [
              [-8.87953, 42.430152],
              [-8.879062, 42.429699],
              [-8.880477, 42.4295],
              [-8.880386, 42.430081],
              [-8.87953, 42.430152],
            ],
          ],
          [
            [
              [-8.82938, 42.123685],
              [-8.828442, 42.123481],
              [-8.828216, 42.122862],
              [-8.829399, 42.122973],
              [-8.82938, 42.123685],
            ],
          ],
          [
            [
              [-8.871995, 42.559833],
              [-8.872765, 42.560648],
              [-8.872349, 42.561012],
              [-8.871797, 42.560842],
              [-8.871995, 42.559833],
            ],
          ],
          [
            [
              [-8.903128, 42.117444],
              [-8.902216, 42.117122],
              [-8.901665, 42.116269],
              [-8.903433, 42.117077],
              [-8.903128, 42.117444],
            ],
          ],
          [
            [
              [-8.837313, 42.493251],
              [-8.837801, 42.493136],
              [-8.837691, 42.494429],
              [-8.837002, 42.493825],
              [-8.837313, 42.493251],
            ],
          ],
          [
            [
              [-8.89734, 42.087713],
              [-8.898103, 42.087997],
              [-8.897574, 42.088799],
              [-8.89715, 42.088659],
              [-8.89734, 42.087713],
            ],
          ],
          [
            [
              [-8.877514, 42.527151],
              [-8.878155, 42.527623],
              [-8.877761, 42.528219],
              [-8.877125, 42.52754],
              [-8.877514, 42.527151],
            ],
          ],
          [
            [
              [-8.937204, 42.342363],
              [-8.937841, 42.342429],
              [-8.936169, 42.343227],
              [-8.936665, 42.342571],
              [-8.937204, 42.342363],
            ],
          ],
          [
            [
              [-8.831249, 42.469949],
              [-8.832078, 42.469969],
              [-8.832201, 42.470377],
              [-8.830691, 42.470481],
              [-8.831249, 42.469949],
            ],
          ],
          [
            [
              [-8.920822, 42.400186],
              [-8.921319, 42.400439],
              [-8.921164, 42.401113],
              [-8.920493, 42.400824],
              [-8.920822, 42.400186],
            ],
          ],
          [
            [
              [-8.777661, 42.223325],
              [-8.778293, 42.223482],
              [-8.777978, 42.224146],
              [-8.777217, 42.223896],
              [-8.777661, 42.223325],
            ],
          ],
          [
            [
              [-8.624897, 42.340284],
              [-8.625481, 42.34057],
              [-8.625122, 42.341443],
              [-8.624734, 42.341039],
              [-8.624897, 42.340284],
            ],
          ],
          [
            [
              [-8.827501, 42.323138],
              [-8.828029, 42.322805],
              [-8.828581, 42.322876],
              [-8.827755, 42.323909],
              [-8.827501, 42.323138],
            ],
          ],
          [
            [
              [-8.945501, 42.379415],
              [-8.945859, 42.380243],
              [-8.944905, 42.38094],
              [-8.945401, 42.38041],
              [-8.945501, 42.379415],
            ],
          ],
          [
            [
              [-8.875435, 41.868871],
              [-8.875923, 41.869071],
              [-8.875195, 41.870188],
              [-8.87491, 41.869969],
              [-8.875435, 41.868871],
            ],
          ],
          [
            [
              [-8.903826, 42.116112],
              [-8.904335, 42.116635],
              [-8.903706, 42.117675],
              [-8.903584, 42.116881],
              [-8.903826, 42.116112],
            ],
          ],
          [
            [
              [-8.824925, 42.495801],
              [-8.824867, 42.494912],
              [-8.825451, 42.494793],
              [-8.825648, 42.495332],
              [-8.824925, 42.495801],
            ],
          ],
          [
            [
              [-8.751723, 42.352084],
              [-8.750912, 42.352107],
              [-8.751106, 42.351314],
              [-8.751674, 42.35142],
              [-8.751723, 42.352084],
            ],
          ],
          [
            [
              [-8.828064, 42.250698],
              [-8.827682, 42.250105],
              [-8.828415, 42.24978],
              [-8.828517, 42.250486],
              [-8.828064, 42.250698],
            ],
          ],
          [
            [
              [-8.622973, 42.339262],
              [-8.62356, 42.33944],
              [-8.623419, 42.340311],
              [-8.623138, 42.340154],
              [-8.622973, 42.339262],
            ],
          ],
          [
            [
              [-8.895314, 42.4902],
              [-8.89585, 42.49046],
              [-8.895033, 42.491213],
              [-8.895028, 42.490773],
              [-8.895314, 42.4902],
            ],
          ],
          [
            [
              [-8.887443, 42.544301],
              [-8.887934, 42.5446],
              [-8.887339, 42.545396],
              [-8.887219, 42.544898],
              [-8.887443, 42.544301],
            ],
          ],
          [
            [
              [-8.865829, 42.518444],
              [-8.866117, 42.519186],
              [-8.864938, 42.519462],
              [-8.86574, 42.5188],
              [-8.865829, 42.518444],
            ],
          ],
          [
            [
              [-8.909775, 42.178196],
              [-8.910223, 42.178587],
              [-8.909875, 42.179127],
              [-8.909279, 42.178843],
              [-8.909775, 42.178196],
            ],
          ],
          [
            [
              [-8.87526, 42.147839],
              [-8.875076, 42.147938],
              [-8.874435, 42.147548],
              [-8.875657, 42.147296],
              [-8.87526, 42.147839],
            ],
          ],
          [
            [
              [-8.833094, 42.544768],
              [-8.833174, 42.543927],
              [-8.833634, 42.543903],
              [-8.833807, 42.544579],
              [-8.833094, 42.544768],
            ],
          ],
          [
            [
              [-8.894103, 42.058975],
              [-8.894856, 42.059413],
              [-8.895053, 42.059709],
              [-8.893804, 42.059269],
              [-8.894103, 42.058975],
            ],
          ],
          [
            [
              [-8.889963, 42.026961],
              [-8.889783, 42.026592],
              [-8.890821, 42.02698],
              [-8.890487, 42.027276],
              [-8.889963, 42.026961],
            ],
          ],
          [
            [
              [-8.900601, 42.11536],
              [-8.900268, 42.114774],
              [-8.901006, 42.114781],
              [-8.900973, 42.11598],
              [-8.900601, 42.11536],
            ],
          ],
          [
            [
              [-8.868413, 42.145877],
              [-8.868889, 42.14605],
              [-8.8681, 42.146676],
              [-8.867937, 42.146216],
              [-8.868413, 42.145877],
            ],
          ],
          [
            [
              [-8.836262, 42.549872],
              [-8.836615, 42.549998],
              [-8.836552, 42.550632],
              [-8.835984, 42.550283],
              [-8.836262, 42.549872],
            ],
          ],
          [
            [
              [-8.905437, 42.118342],
              [-8.904775, 42.118115],
              [-8.904407, 42.11754],
              [-8.904903, 42.117542],
              [-8.905437, 42.118342],
            ],
          ],
          [
            [
              [-8.839764, 42.546966],
              [-8.840381, 42.547177],
              [-8.840427, 42.547418],
              [-8.839475, 42.547386],
              [-8.839764, 42.546966],
            ],
          ],
          [
            [
              [-8.884798, 41.882405],
              [-8.885446, 41.882633],
              [-8.885242, 41.883301],
              [-8.884792, 41.882991],
              [-8.884798, 41.882405],
            ],
          ],
          [
            [
              [-8.851817, 42.471192],
              [-8.852563, 42.471361],
              [-8.852151, 42.47203],
              [-8.851736, 42.471881],
              [-8.851817, 42.471192],
            ],
          ],
          [
            [
              [-8.905323, 42.117511],
              [-8.90484, 42.117131],
              [-8.904846, 42.116932],
              [-8.905697, 42.116979],
              [-8.905323, 42.117511],
            ],
          ],
          [
            [
              [-8.870241, 42.521242],
              [-8.870332, 42.521823],
              [-8.869597, 42.521509],
              [-8.869659, 42.521398],
              [-8.870241, 42.521242],
            ],
          ],
          [
            [
              [-8.844502, 42.482963],
              [-8.844786, 42.483012],
              [-8.844672, 42.483738],
              [-8.844093, 42.483272],
              [-8.844502, 42.482963],
            ],
          ],
          [
            [
              [-8.647571, 42.316975],
              [-8.647957, 42.317487],
              [-8.647172, 42.317796],
              [-8.647056, 42.317721],
              [-8.647571, 42.316975],
            ],
          ],
          [
            [
              [-8.914874, 42.543694],
              [-8.915184, 42.543759],
              [-8.915441, 42.54416],
              [-8.914755, 42.544366],
              [-8.914874, 42.543694],
            ],
          ],
          [
            [
              [-8.778038, 42.221622],
              [-8.777282, 42.221562],
              [-8.777506, 42.220974],
              [-8.777933, 42.221007],
              [-8.778038, 42.221622],
            ],
          ],
          [
            [
              [-8.790845, 42.610823],
              [-8.791589, 42.611091],
              [-8.791636, 42.611332],
              [-8.79097, 42.611384],
              [-8.790845, 42.610823],
            ],
          ],
          [
            [
              [-8.885748, 42.035559],
              [-8.886094, 42.035775],
              [-8.885473, 42.036239],
              [-8.885295, 42.036014],
              [-8.885748, 42.035559],
            ],
          ],
          [
            [
              [-8.766193, 42.226397],
              [-8.7665, 42.226445],
              [-8.766221, 42.227233],
              [-8.765962, 42.226778],
              [-8.766193, 42.226397],
            ],
          ],
          [
            [
              [-8.929523, 42.449316],
              [-8.929815, 42.44958],
              [-8.929239, 42.449916],
              [-8.929059, 42.44943],
              [-8.929523, 42.449316],
            ],
          ],
          [
            [
              [-8.877756, 42.436761],
              [-8.878185, 42.437063],
              [-8.877592, 42.437345],
              [-8.877424, 42.436985],
              [-8.877756, 42.436761],
            ],
          ],
          [
            [
              [-8.892718, 42.490145],
              [-8.892075, 42.49016],
              [-8.89196, 42.489968],
              [-8.892717, 42.489749],
              [-8.892718, 42.490145],
            ],
          ],
          [
            [
              [-8.899272, 42.114727],
              [-8.89987, 42.115038],
              [-8.899765, 42.11534],
              [-8.899347, 42.115272],
              [-8.899272, 42.114727],
            ],
          ],
          [
            [
              [-8.802612, 42.614169],
              [-8.802963, 42.614268],
              [-8.803036, 42.614786],
              [-8.802444, 42.614582],
              [-8.802612, 42.614169],
            ],
          ],
          [
            [
              [-8.875918, 42.500723],
              [-8.876224, 42.500878],
              [-8.87581, 42.501647],
              [-8.875605, 42.501405],
              [-8.875918, 42.500723],
            ],
          ],
          [
            [
              [-8.899511, 42.095072],
              [-8.899301, 42.094768],
              [-8.899876, 42.09445],
              [-8.900038, 42.094892],
              [-8.899511, 42.095072],
            ],
          ],
          [
            [
              [-8.808338, 42.583154],
              [-8.808638, 42.583499],
              [-8.808133, 42.58383],
              [-8.807941, 42.583471],
              [-8.808338, 42.583154],
            ],
          ],
          [
            [
              [-8.878101, 41.874945],
              [-8.877516, 41.874867],
              [-8.877136, 41.874274],
              [-8.877552, 41.874208],
              [-8.878101, 41.874945],
            ],
          ],
          [
            [
              [-8.912853, 42.203467],
              [-8.912665, 42.203144],
              [-8.912938, 42.20205],
              [-8.91319, 42.202415],
              [-8.912853, 42.203467],
            ],
          ],
          [
            [
              [-8.923176, 42.451356],
              [-8.923603, 42.452018],
              [-8.923569, 42.452173],
              [-8.92301, 42.45204],
              [-8.923176, 42.451356],
            ],
          ],
          [
            [
              [-8.87983, 41.876323],
              [-8.879211, 41.876139],
              [-8.880038, 41.875701],
              [-8.880133, 41.875822],
              [-8.87983, 41.876323],
            ],
          ],
          [
            [
              [-8.626202, 42.304391],
              [-8.62662, 42.30446],
              [-8.626412, 42.304974],
              [-8.626125, 42.304889],
              [-8.626202, 42.304391],
            ],
          ],
          [
            [
              [-8.863508, 42.246041],
              [-8.863798, 42.246296],
              [-8.863108, 42.246817],
              [-8.863347, 42.24613],
              [-8.863508, 42.246041],
            ],
          ],
          [
            [
              [-8.935227, 42.480004],
              [-8.935873, 42.480276],
              [-8.935641, 42.480783],
              [-8.935519, 42.480772],
              [-8.935227, 42.480004],
            ],
          ],
          [
            [
              [-8.842521, 42.258836],
              [-8.842299, 42.258919],
              [-8.842095, 42.258416],
              [-8.84254, 42.25825],
              [-8.842521, 42.258836],
            ],
          ],
          [
            [
              [-8.760213, 42.258161],
              [-8.760522, 42.258488],
              [-8.760311, 42.258831],
              [-8.759933, 42.258544],
              [-8.760213, 42.258161],
            ],
          ],
          [
            [
              [-8.813029, 42.258215],
              [-8.813443, 42.258761],
              [-8.813174, 42.259],
              [-8.812944, 42.25848],
              [-8.813029, 42.258215],
            ],
          ],
          [
            [
              [-8.900609, 42.104279],
              [-8.901044, 42.104409],
              [-8.901225, 42.104796],
              [-8.900801, 42.104782],
              [-8.900609, 42.104279],
            ],
          ],
          [
            [
              [-8.738498, 42.375398],
              [-8.738768, 42.37542],
              [-8.738612, 42.375977],
              [-8.738239, 42.375744],
              [-8.738498, 42.375398],
            ],
          ],
          [
            [
              [-8.816425, 42.494784],
              [-8.816969, 42.494883],
              [-8.816959, 42.495162],
              [-8.81649, 42.495222],
              [-8.816425, 42.494784],
            ],
          ],
          [
            [
              [-8.891051, 42.025807],
              [-8.891321, 42.026117],
              [-8.890765, 42.026371],
              [-8.890761, 42.0262],
              [-8.891051, 42.025807],
            ],
          ],
          [
            [
              [-8.933925, 42.452166],
              [-8.934312, 42.452398],
              [-8.933602, 42.452606],
              [-8.933603, 42.452354],
              [-8.933925, 42.452166],
            ],
          ],
          [
            [
              [-8.839744, 42.465338],
              [-8.840189, 42.465549],
              [-8.839804, 42.465848],
              [-8.83957, 42.465689],
              [-8.839744, 42.465338],
            ],
          ],
          [
            [
              [-8.945445, 42.354834],
              [-8.945898, 42.355009],
              [-8.945896, 42.355243],
              [-8.94537, 42.355324],
              [-8.945445, 42.354834],
            ],
          ],
          [
            [
              [-8.881164, 42.423218],
              [-8.881794, 42.423464],
              [-8.881824, 42.423517],
              [-8.881233, 42.423826],
              [-8.881164, 42.423218],
            ],
          ],
          [
            [
              [-8.877407, 41.87499],
              [-8.87779, 41.875096],
              [-8.877828, 41.875634],
              [-8.87741, 41.875421],
              [-8.877407, 41.87499],
            ],
          ],
          [
            [
              [-8.891371, 42.057],
              [-8.89195, 42.056997],
              [-8.892257, 42.057179],
              [-8.891831, 42.057408],
              [-8.891371, 42.057],
            ],
          ],
          [
            [
              [-8.930524, 42.45065],
              [-8.930832, 42.450949],
              [-8.930577, 42.451341],
              [-8.930323, 42.451093],
              [-8.930524, 42.45065],
            ],
          ],
          [
            [
              [-8.88961, 42.564822],
              [-8.8895, 42.564684],
              [-8.889508, 42.564251],
              [-8.89001, 42.564666],
              [-8.88961, 42.564822],
            ],
          ],
          [
            [
              [-8.877803, 42.554403],
              [-8.877984, 42.554772],
              [-8.877867, 42.555084],
              [-8.877427, 42.554674],
              [-8.877803, 42.554403],
            ],
          ],
          [
            [
              [-8.817357, 42.189076],
              [-8.817569, 42.189011],
              [-8.817744, 42.189596],
              [-8.817108, 42.189259],
              [-8.817357, 42.189076],
            ],
          ],
          [
            [
              [-8.833193, 42.470065],
              [-8.833585, 42.470216],
              [-8.833203, 42.47056],
              [-8.832933, 42.470403],
              [-8.833193, 42.470065],
            ],
          ],
          [
            [
              [-8.941966, 42.461578],
              [-8.9422, 42.461737],
              [-8.942113, 42.462236],
              [-8.941775, 42.461876],
              [-8.941966, 42.461578],
            ],
          ],
          [
            [
              [-8.817609, 42.493131],
              [-8.817926, 42.493529],
              [-8.817707, 42.493792],
              [-8.817503, 42.493424],
              [-8.817609, 42.493131],
            ],
          ],
          [
            [
              [-8.891402, 42.491797],
              [-8.89179, 42.491912],
              [-8.891733, 42.492212],
              [-8.891261, 42.492101],
              [-8.891402, 42.491797],
            ],
          ],
          [
            [
              [-8.918849, 42.250282],
              [-8.919138, 42.250384],
              [-8.919216, 42.250704],
              [-8.918641, 42.250518],
              [-8.918849, 42.250282],
            ],
          ],
          [
            [
              [-8.938, 42.341674],
              [-8.938325, 42.341774],
              [-8.938034, 42.342158],
              [-8.937823, 42.34198],
              [-8.938, 42.341674],
            ],
          ],
          [
            [
              [-8.826796, 42.581232],
              [-8.826167, 42.581021],
              [-8.826296, 42.580709],
              [-8.826851, 42.58104],
              [-8.826796, 42.581232],
            ],
          ],
          [
            [
              [-8.925872, 42.447984],
              [-8.925963, 42.448565],
              [-8.925704, 42.44865],
              [-8.925621, 42.448285],
              [-8.925872, 42.447984],
            ],
          ],
          [
            [
              [-8.902848, 42.53599],
              [-8.903158, 42.536307],
              [-8.902621, 42.536551],
              [-8.902835, 42.536233],
              [-8.902848, 42.53599],
            ],
          ],
          [
            [
              [-8.873088, 42.437919],
              [-8.873469, 42.437836],
              [-8.873687, 42.438212],
              [-8.873427, 42.438298],
              [-8.873088, 42.437919],
            ],
          ],
          [
            [
              [-8.872763, 42.555319],
              [-8.872998, 42.555352],
              [-8.873158, 42.555758],
              [-8.872796, 42.555795],
              [-8.872763, 42.555319],
            ],
          ],
          [
            [
              [-8.77999, 42.219264],
              [-8.780396, 42.219073],
              [-8.780553, 42.219191],
              [-8.780302, 42.219501],
              [-8.77999, 42.219264],
            ],
          ],
          [
            [
              [-8.873949, 42.147915],
              [-8.873692, 42.148018],
              [-8.87345, 42.147887],
              [-8.873918, 42.147457],
              [-8.873949, 42.147915],
            ],
          ],
          [
            [
              [-8.833558, 42.286263],
              [-8.833977, 42.286197],
              [-8.834345, 42.286376],
              [-8.833883, 42.28649],
              [-8.833558, 42.286263],
            ],
          ],
          [
            [
              [-8.901295, 42.111003],
              [-8.901554, 42.111053],
              [-8.901798, 42.111338],
              [-8.901291, 42.111346],
              [-8.901295, 42.111003],
            ],
          ],
          [
            [
              [-8.827208, 42.385834],
              [-8.827532, 42.386051],
              [-8.827514, 42.386376],
              [-8.82721, 42.386374],
              [-8.827208, 42.385834],
            ],
          ],
          [
            [
              [-8.901795, 42.111959],
              [-8.902387, 42.112081],
              [-8.902466, 42.112284],
              [-8.901717, 42.112152],
              [-8.901795, 42.111959],
            ],
          ],
          [
            [
              [-8.94483, 42.354749],
              [-8.945003, 42.354785],
              [-8.944814, 42.355605],
              [-8.944668, 42.355091],
              [-8.94483, 42.354749],
            ],
          ],
          [
            [
              [-8.889283, 41.970986],
              [-8.88941, 41.970791],
              [-8.889946, 41.970844],
              [-8.889498, 41.971218],
              [-8.889283, 41.970986],
            ],
          ],
          [
            [
              [-8.89179, 42.043547],
              [-8.89218, 42.04359],
              [-8.892289, 42.043855],
              [-8.891744, 42.043838],
              [-8.89179, 42.043547],
            ],
          ],
          [
            [
              [-8.882328, 42.557456],
              [-8.882775, 42.557676],
              [-8.882842, 42.558005],
              [-8.88227, 42.557621],
              [-8.882328, 42.557456],
            ],
          ],
          [
            [
              [-8.864166, 42.306932],
              [-8.863865, 42.306956],
              [-8.863742, 42.3068],
              [-8.86455, 42.306759],
              [-8.864166, 42.306932],
            ],
          ],
          [
            [
              [-8.889877, 42.045716],
              [-8.890391, 42.045915],
              [-8.890335, 42.046098],
              [-8.889763, 42.045911],
              [-8.889877, 42.045716],
            ],
          ],
          [
            [
              [-8.884662, 42.009869],
              [-8.885057, 42.009966],
              [-8.884904, 42.010271],
              [-8.884555, 42.010145],
              [-8.884662, 42.009869],
            ],
          ],
          [
            [
              [-8.88823, 42.544261],
              [-8.888567, 42.544469],
              [-8.888425, 42.544773],
              [-8.888165, 42.544597],
              [-8.88823, 42.544261],
            ],
          ],
          [
            [
              [-8.946261, 42.379619],
              [-8.946599, 42.379601],
              [-8.946623, 42.380113],
              [-8.946437, 42.380069],
              [-8.946261, 42.379619],
            ],
          ],
          [
            [
              [-8.767884, 42.337217],
              [-8.767338, 42.337083],
              [-8.767531, 42.336677],
              [-8.767962, 42.337015],
              [-8.767884, 42.337217],
            ],
          ],
          [
            [
              [-8.825038, 42.461345],
              [-8.825235, 42.461236],
              [-8.825557, 42.461435],
              [-8.825053, 42.461641],
              [-8.825038, 42.461345],
            ],
          ],
          [
            [
              [-8.827882, 42.3241],
              [-8.827944, 42.324511],
              [-8.827475, 42.324292],
              [-8.827482, 42.324238],
              [-8.827882, 42.3241],
            ],
          ],
          [
            [
              [-8.922223, 42.450658],
              [-8.922673, 42.450788],
              [-8.922622, 42.451412],
              [-8.922468, 42.450942],
              [-8.922223, 42.450658],
            ],
          ],
          [
            [
              [-8.708626, 42.27463],
              [-8.708912, 42.274841],
              [-8.708711, 42.275301],
              [-8.708623, 42.275125],
              [-8.708626, 42.27463],
            ],
          ],
          [
            [
              [-8.892082, 42.490493],
              [-8.892606, 42.490501],
              [-8.892604, 42.490736],
              [-8.892342, 42.490803],
              [-8.892082, 42.490493],
            ],
          ],
          [
            [
              [-8.942753, 42.465264],
              [-8.942807, 42.465702],
              [-8.942429, 42.465686],
              [-8.942417, 42.465569],
              [-8.942753, 42.465264],
            ],
          ],
          [
            [
              [-8.807583, 42.50069],
              [-8.807714, 42.5008],
              [-8.806779, 42.501334],
              [-8.806808, 42.50126],
              [-8.807583, 42.50069],
            ],
          ],
          [
            [
              [-8.798261, 42.338084],
              [-8.798099, 42.338173],
              [-8.797981, 42.33781],
              [-8.798439, 42.337777],
              [-8.798261, 42.338084],
            ],
          ],
          [
            [
              [-8.943299, 42.465505],
              [-8.94362, 42.465561],
              [-8.943465, 42.465974],
              [-8.943236, 42.465734],
              [-8.943299, 42.465505],
            ],
          ],
          [
            [
              [-8.868507, 42.520143],
              [-8.868729, 42.520303],
              [-8.868576, 42.520617],
              [-8.868303, 42.520298],
              [-8.868507, 42.520143],
            ],
          ],
          [
            [
              [-8.901928, 42.104165],
              [-8.902258, 42.104202],
              [-8.902484, 42.104425],
              [-8.902103, 42.104489],
              [-8.901928, 42.104165],
            ],
          ],
          [
            [
              [-8.764306, 42.257334],
              [-8.7644, 42.257698],
              [-8.763945, 42.257883],
              [-8.763858, 42.257734],
              [-8.764306, 42.257334],
            ],
          ],
          [
            [
              [-8.763353, 42.257904],
              [-8.763512, 42.258058],
              [-8.763251, 42.258512],
              [-8.763102, 42.258205],
              [-8.763353, 42.257904],
            ],
          ],
          [
            [
              [-8.887724, 42.545349],
              [-8.888157, 42.545417],
              [-8.888202, 42.54564],
              [-8.887888, 42.545665],
              [-8.887724, 42.545349],
            ],
          ],
          [
            [
              [-8.76521, 42.225709],
              [-8.765482, 42.225767],
              [-8.765297, 42.226137],
              [-8.764999, 42.226053],
              [-8.76521, 42.225709],
            ],
          ],
          [
            [
              [-8.88535, 42.006998],
              [-8.885327, 42.006621],
              [-8.88582, 42.006992],
              [-8.885753, 42.00705],
              [-8.88535, 42.006998],
            ],
          ],
          [
            [
              [-8.940433, 42.476691],
              [-8.940303, 42.476859],
              [-8.939827, 42.476704],
              [-8.940008, 42.476443],
              [-8.940433, 42.476691],
            ],
          ],
          [
            [
              [-8.821262, 42.280193],
              [-8.821503, 42.280307],
              [-8.821132, 42.280749],
              [-8.820995, 42.28045],
              [-8.821262, 42.280193],
            ],
          ],
          [
            [
              [-8.726797, 42.678877],
              [-8.726584, 42.678942],
              [-8.72629, 42.678668],
              [-8.726697, 42.678585],
              [-8.726797, 42.678877],
            ],
          ],
          [
            [
              [-8.913256, 42.204158],
              [-8.913738, 42.204394],
              [-8.913785, 42.204634],
              [-8.913542, 42.20462],
              [-8.913256, 42.204158],
            ],
          ],
          [
            [
              [-8.906558, 42.542648],
              [-8.906858, 42.542867],
              [-8.906653, 42.543147],
              [-8.906509, 42.543038],
              [-8.906558, 42.542648],
            ],
          ],
          [
            [
              [-8.891024, 42.566765],
              [-8.891481, 42.566832],
              [-8.891517, 42.566956],
              [-8.891188, 42.56709],
              [-8.891024, 42.566765],
            ],
          ],
          [
            [
              [-8.918639, 42.248294],
              [-8.918162, 42.248373],
              [-8.918111, 42.248096],
              [-8.918289, 42.248051],
              [-8.918639, 42.248294],
            ],
          ],
          [
            [
              [-8.898478, 42.115542],
              [-8.898539, 42.115161],
              [-8.898839, 42.115136],
              [-8.898845, 42.115334],
              [-8.898478, 42.115542],
            ],
          ],
          [
            [
              [-8.822837, 42.302618],
              [-8.822633, 42.302502],
              [-8.822584, 42.302234],
              [-8.823029, 42.302329],
              [-8.822837, 42.302618],
            ],
          ],
          [
            [
              [-8.903627, 42.11813],
              [-8.904071, 42.118215],
              [-8.904079, 42.118565],
              [-8.903845, 42.118514],
              [-8.903627, 42.11813],
            ],
          ],
          [
            [
              [-8.819908, 42.523062],
              [-8.820051, 42.523289],
              [-8.819698, 42.52355],
              [-8.819656, 42.523363],
              [-8.819908, 42.523062],
            ],
          ],
          [
            [
              [-8.849128, 42.494852],
              [-8.849363, 42.495155],
              [-8.849013, 42.495308],
              [-8.849003, 42.495209],
              [-8.849128, 42.494852],
            ],
          ],
          [
            [
              [-8.91221, 42.19647],
              [-8.912474, 42.196448],
              [-8.912479, 42.196762],
              [-8.9121, 42.196719],
              [-8.91221, 42.19647],
            ],
          ],
          [
            [
              [-8.867616, 42.145756],
              [-8.867839, 42.14569],
              [-8.868064, 42.145769],
              [-8.867667, 42.14605],
              [-8.867616, 42.145756],
            ],
          ],
          [
            [
              [-8.940286, 42.467579],
              [-8.940549, 42.467656],
              [-8.940599, 42.467932],
              [-8.940219, 42.467898],
              [-8.940286, 42.467579],
            ],
          ],
          [
            [
              [-8.899869, 42.099834],
              [-8.900122, 42.099821],
              [-8.900179, 42.100052],
              [-8.899836, 42.100124],
              [-8.899869, 42.099834],
            ],
          ],
          [
            [
              [-8.812864, 42.495992],
              [-8.812787, 42.496482],
              [-8.812491, 42.496569],
              [-8.812487, 42.496524],
              [-8.812864, 42.495992],
            ],
          ],
          [
            [
              [-8.921445, 42.452976],
              [-8.921818, 42.452938],
              [-8.921835, 42.453109],
              [-8.921447, 42.453255],
              [-8.921445, 42.452976],
            ],
          ],
          [
            [
              [-8.921187, 42.399807],
              [-8.921382, 42.400076],
              [-8.920899, 42.399975],
              [-8.920918, 42.39992],
              [-8.921187, 42.399807],
            ],
          ],
          [
            [
              [-8.906416, 42.177866],
              [-8.906625, 42.1779],
              [-8.906577, 42.178164],
              [-8.906198, 42.17812],
              [-8.906416, 42.177866],
            ],
          ],
          [
            [
              [-8.939408, 42.459605],
              [-8.939708, 42.459688],
              [-8.939592, 42.460135],
              [-8.939407, 42.459983],
              [-8.939408, 42.459605],
            ],
          ],
          [
            [
              [-8.911684, 42.206381],
              [-8.91188, 42.206398],
              [-8.911954, 42.2068],
              [-8.911622, 42.206619],
              [-8.911684, 42.206381],
            ],
          ],
          [
            [
              [-8.85809, 42.567236],
              [-8.858265, 42.567542],
              [-8.857984, 42.567664],
              [-8.857949, 42.567297],
              [-8.85809, 42.567236],
            ],
          ],
          [
            [
              [-8.904721, 42.110143],
              [-8.904515, 42.110135],
              [-8.904387, 42.109935],
              [-8.904804, 42.109859],
              [-8.904721, 42.110143],
            ],
          ],
          [
            [
              [-8.86131, 42.305827],
              [-8.861587, 42.30593],
              [-8.8616, 42.306199],
              [-8.861299, 42.306098],
              [-8.86131, 42.305827],
            ],
          ],
          [
            [
              [-8.875933, 42.558856],
              [-8.875741, 42.558623],
              [-8.875968, 42.558449],
              [-8.876158, 42.558656],
              [-8.875933, 42.558856],
            ],
          ],
          [
            [
              [-8.826116, 42.342113],
              [-8.825986, 42.341886],
              [-8.8268, 42.341898],
              [-8.826206, 42.342027],
              [-8.826116, 42.342113],
            ],
          ],
          [
            [
              [-8.897421, 42.094955],
              [-8.897658, 42.094907],
              [-8.897224, 42.095307],
              [-8.897208, 42.095137],
              [-8.897421, 42.094955],
            ],
          ],
          [
            [
              [-8.868409, 42.499273],
              [-8.868874, 42.499294],
              [-8.86893, 42.499372],
              [-8.868627, 42.499523],
              [-8.868409, 42.499273],
            ],
          ],
          [
            [
              [-8.856589, 42.309436],
              [-8.856837, 42.309621],
              [-8.856342, 42.309646],
              [-8.856319, 42.309531],
              [-8.856589, 42.309436],
            ],
          ],
          [
            [
              [-8.91298, 42.193964],
              [-8.913086, 42.194184],
              [-8.912758, 42.194308],
              [-8.912735, 42.194058],
              [-8.91298, 42.193964],
            ],
          ],
          [
            [
              [-8.94555, 42.381474],
              [-8.945902, 42.381465],
              [-8.945956, 42.381525],
              [-8.945317, 42.381702],
              [-8.94555, 42.381474],
            ],
          ],
          [
            [
              [-8.771827, 42.224556],
              [-8.771565, 42.224344],
              [-8.772192, 42.224573],
              [-8.772059, 42.224715],
              [-8.771827, 42.224556],
            ],
          ],
          [
            [
              [-8.93622, 42.480222],
              [-8.936485, 42.480317],
              [-8.936453, 42.480624],
              [-8.936241, 42.480572],
              [-8.93622, 42.480222],
            ],
          ],
          [
            [
              [-8.903296, 42.115482],
              [-8.903483, 42.115545],
              [-8.903359, 42.115893],
              [-8.903163, 42.115741],
              [-8.903296, 42.115482],
            ],
          ],
          [
            [
              [-8.925888, 42.449568],
              [-8.926123, 42.449619],
              [-8.926061, 42.449982],
              [-8.925868, 42.449875],
              [-8.925888, 42.449568],
            ],
          ],
          [
            [
              [-8.939311, 42.45564],
              [-8.939559, 42.455816],
              [-8.939173, 42.455971],
              [-8.93913, 42.455901],
              [-8.939311, 42.45564],
            ],
          ],
          [
            [
              [-8.901564, 42.112718],
              [-8.901878, 42.11272],
              [-8.901944, 42.112905],
              [-8.901448, 42.112904],
              [-8.901564, 42.112718],
            ],
          ],
          [
            [
              [-8.947086, 42.381538],
              [-8.947236, 42.381584],
              [-8.947052, 42.382071],
              [-8.946969, 42.381715],
              [-8.947086, 42.381538],
            ],
          ],
          [
            [
              [-8.761667, 42.256161],
              [-8.76208, 42.256177],
              [-8.762088, 42.256392],
              [-8.761857, 42.256386],
              [-8.761667, 42.256161],
            ],
          ],
          [
            [
              [-8.901606, 42.114597],
              [-8.901886, 42.114493],
              [-8.902101, 42.11459],
              [-8.901934, 42.114751],
              [-8.901606, 42.114597],
            ],
          ],
          [
            [
              [-8.80909, 42.193034],
              [-8.809235, 42.193035],
              [-8.80956, 42.193271],
              [-8.809153, 42.193328],
              [-8.80909, 42.193034],
            ],
          ],
          [
            [
              [-8.935758, 42.451621],
              [-8.936134, 42.451619],
              [-8.936183, 42.451743],
              [-8.935617, 42.451799],
              [-8.935758, 42.451621],
            ],
          ],
          [
            [
              [-8.774855, 42.222837],
              [-8.774556, 42.222879],
              [-8.77465, 42.222586],
              [-8.774969, 42.222633],
              [-8.774855, 42.222837],
            ],
          ],
          [
            [
              [-8.882161, 41.880056],
              [-8.88179, 41.880084],
              [-8.881782, 41.87986],
              [-8.882163, 41.879813],
              [-8.882161, 41.880056],
            ],
          ],
          [
            [
              [-8.823641, 42.297274],
              [-8.823872, 42.297281],
              [-8.823902, 42.297603],
              [-8.823646, 42.297589],
              [-8.823641, 42.297274],
            ],
          ],
          [
            [
              [-8.798717, 42.335927],
              [-8.798938, 42.335952],
              [-8.799075, 42.336125],
              [-8.798603, 42.33614],
              [-8.798717, 42.335927],
            ],
          ],
          [
            [
              [-8.891111, 42.495548],
              [-8.891373, 42.495624],
              [-8.890947, 42.495871],
              [-8.890874, 42.495749],
              [-8.891111, 42.495548],
            ],
          ],
          [
            [
              [-8.816896, 42.187488],
              [-8.817127, 42.187494],
              [-8.817058, 42.187795],
              [-8.816795, 42.1877],
              [-8.816896, 42.187488],
            ],
          ],
          [
            [
              [-8.942922, 42.475751],
              [-8.943142, 42.475892],
              [-8.942868, 42.476078],
              [-8.942815, 42.475901],
              [-8.942922, 42.475751],
            ],
          ],
          [
            [
              [-8.862207, 42.30543],
              [-8.862392, 42.305456],
              [-8.862429, 42.305725],
              [-8.86211, 42.305687],
              [-8.862207, 42.30543],
            ],
          ],
          [
            [
              [-8.884847, 42.004036],
              [-8.884359, 42.003854],
              [-8.884317, 42.003793],
              [-8.884655, 42.003784],
              [-8.884847, 42.004036],
            ],
          ],
          [
            [
              [-8.880484, 41.998355],
              [-8.880582, 41.998377],
              [-8.880703, 41.998767],
              [-8.880357, 41.998676],
              [-8.880484, 41.998355],
            ],
          ],
          [
            [
              [-8.910016, 42.179345],
              [-8.910235, 42.179361],
              [-8.91054, 42.179516],
              [-8.910119, 42.179538],
              [-8.910016, 42.179345],
            ],
          ],
          [
            [
              [-8.84136, 42.498283],
              [-8.841605, 42.498433],
              [-8.841276, 42.498566],
              [-8.841203, 42.498426],
              [-8.84136, 42.498283],
            ],
          ],
          [
            [
              [-8.752299, 42.348166],
              [-8.752137, 42.347994],
              [-8.752267, 42.347817],
              [-8.752459, 42.347933],
              [-8.752299, 42.348166],
            ],
          ],
          [
            [
              [-8.841857, 42.158147],
              [-8.842069, 42.158217],
              [-8.842022, 42.158499],
              [-8.841836, 42.158445],
              [-8.841857, 42.158147],
            ],
          ],
          [
            [
              [-8.898807, 42.102193],
              [-8.898965, 42.102329],
              [-8.898908, 42.102629],
              [-8.898739, 42.102494],
              [-8.898807, 42.102193],
            ],
          ],
          [
            [
              [-8.925627, 42.450148],
              [-8.925889, 42.450099],
              [-8.92595, 42.450231],
              [-8.92566, 42.450372],
              [-8.925627, 42.450148],
            ],
          ],
          [
            [
              [-8.886924, 42.048776],
              [-8.887123, 42.048703],
              [-8.887404, 42.048868],
              [-8.886998, 42.048916],
              [-8.886924, 42.048776],
            ],
          ],
          [
            [
              [-8.896058, 42.492925],
              [-8.89624, 42.493185],
              [-8.896071, 42.493329],
              [-8.895953, 42.492975],
              [-8.896058, 42.492925],
            ],
          ],
          [
            [
              [-8.889092, 41.980007],
              [-8.88931, 41.980014],
              [-8.889411, 41.980198],
              [-8.889064, 41.980225],
              [-8.889092, 41.980007],
            ],
          ],
          [
            [
              [-8.893451, 42.491988],
              [-8.893733, 42.49201],
              [-8.89378, 42.492115],
              [-8.893412, 42.492215],
              [-8.893451, 42.491988],
            ],
          ],
          [
            [
              [-8.839434, 42.338003],
              [-8.839715, 42.338025],
              [-8.839676, 42.338252],
              [-8.839365, 42.338169],
              [-8.839434, 42.338003],
            ],
          ],
          [
            [
              [-8.874505, 42.437864],
              [-8.8747, 42.437872],
              [-8.874827, 42.438064],
              [-8.874568, 42.438149],
              [-8.874505, 42.437864],
            ],
          ],
          [
            [
              [-8.933749, 42.480963],
              [-8.933955, 42.480952],
              [-8.93352, 42.481245],
              [-8.933587, 42.481052],
              [-8.933749, 42.480963],
            ],
          ],
          [
            [
              [-8.875292, 41.865079],
              [-8.875723, 41.865192],
              [-8.875712, 41.865328],
              [-8.875396, 41.865299],
              [-8.875292, 41.865079],
            ],
          ],
          [
            [
              [-8.9167, 42.450485],
              [-8.916926, 42.450429],
              [-8.917142, 42.45066],
              [-8.916903, 42.4507],
              [-8.9167, 42.450485],
            ],
          ],
          [
            [
              [-8.943041, 42.466122],
              [-8.943283, 42.466109],
              [-8.943365, 42.46633],
              [-8.943163, 42.466377],
              [-8.943041, 42.466122],
            ],
          ],
          [
            [
              [-8.824149, 42.313785],
              [-8.824321, 42.313812],
              [-8.824291, 42.314138],
              [-8.824082, 42.314113],
              [-8.824149, 42.313785],
            ],
          ],
          [
            [
              [-8.895966, 42.093347],
              [-8.896164, 42.093391],
              [-8.896239, 42.093549],
              [-8.895784, 42.093473],
              [-8.895966, 42.093347],
            ],
          ],
          [
            [
              [-8.901319, 42.114243],
              [-8.901513, 42.114125],
              [-8.901694, 42.114242],
              [-8.901344, 42.114386],
              [-8.901319, 42.114243],
            ],
          ],
          [
            [
              [-8.77891, 42.218581],
              [-8.7791, 42.21867],
              [-8.778736, 42.218923],
              [-8.778714, 42.218816],
              [-8.77891, 42.218581],
            ],
          ],
          [
            [
              [-8.920597, 42.401278],
              [-8.92075, 42.40136],
              [-8.920664, 42.401607],
              [-8.920459, 42.401492],
              [-8.920597, 42.401278],
            ],
          ],
          [
            [
              [-8.887811, 42.021716],
              [-8.888093, 42.021764],
              [-8.88806, 42.021928],
              [-8.887766, 42.021889],
              [-8.887811, 42.021716],
            ],
          ],
          [
            [
              [-8.888679, 41.965752],
              [-8.888934, 41.965765],
              [-8.888987, 41.965952],
              [-8.888661, 41.96595],
              [-8.888679, 41.965752],
            ],
          ],
          [
            [
              [-8.938272, 42.456477],
              [-8.938377, 42.456679],
              [-8.938142, 42.456772],
              [-8.93812, 42.456539],
              [-8.938272, 42.456477],
            ],
          ],
          [
            [
              [-8.890249, 42.565914],
              [-8.890604, 42.565932],
              [-8.890672, 42.566009],
              [-8.890379, 42.566123],
              [-8.890249, 42.565914],
            ],
          ],
          [
            [
              [-8.808399, 42.339729],
              [-8.808157, 42.339741],
              [-8.808105, 42.339573],
              [-8.808464, 42.339519],
              [-8.808399, 42.339729],
            ],
          ],
          [
            [
              [-8.864078, 42.499009],
              [-8.864309, 42.499006],
              [-8.864168, 42.49932],
              [-8.864011, 42.499202],
              [-8.864078, 42.499009],
            ],
          ],
          [
            [
              [-8.866454, 42.146148],
              [-8.866609, 42.146122],
              [-8.86689, 42.146279],
              [-8.86658, 42.146331],
              [-8.866454, 42.146148],
            ],
          ],
          [
            [
              [-8.740046, 42.269852],
              [-8.740231, 42.269879],
              [-8.740331, 42.270054],
              [-8.740085, 42.270138],
              [-8.740046, 42.269852],
            ],
          ],
          [
            [
              [-8.765667, 42.257895],
              [-8.765882, 42.258001],
              [-8.765794, 42.258231],
              [-8.76564, 42.25814],
              [-8.765667, 42.257895],
            ],
          ],
          [
            [
              [-8.92534, 42.447121],
              [-8.925655, 42.447104],
              [-8.925602, 42.447323],
              [-8.925381, 42.447299],
              [-8.92534, 42.447121],
            ],
          ],
          [
            [
              [-8.942929, 42.477071],
              [-8.942689, 42.477181],
              [-8.94255, 42.476964],
              [-8.942726, 42.476877],
              [-8.942929, 42.477071],
            ],
          ],
          [
            [
              [-8.900213, 42.109898],
              [-8.900541, 42.109908],
              [-8.900611, 42.110013],
              [-8.900277, 42.110066],
              [-8.900213, 42.109898],
            ],
          ],
          [
            [
              [-8.888278, 42.498403],
              [-8.888533, 42.498399],
              [-8.888199, 42.498731],
              [-8.88816, 42.498706],
              [-8.888278, 42.498403],
            ],
          ],
          [
            [
              [-8.824053, 42.314321],
              [-8.824214, 42.314358],
              [-8.82429, 42.314651],
              [-8.82407, 42.314635],
              [-8.824053, 42.314321],
            ],
          ],
          [
            [
              [-8.930741, 42.452304],
              [-8.930929, 42.452484],
              [-8.930595, 42.452555],
              [-8.930614, 42.452374],
              [-8.930741, 42.452304],
            ],
          ],
          [
            [
              [-8.865775, 42.264297],
              [-8.866019, 42.264321],
              [-8.86602, 42.264456],
              [-8.865785, 42.26454],
              [-8.865775, 42.264297],
            ],
          ],
          [
            [
              [-8.907037, 42.179067],
              [-8.907253, 42.179047],
              [-8.907408, 42.179147],
              [-8.907042, 42.179256],
              [-8.907037, 42.179067],
            ],
          ],
          [
            [
              [-8.890921, 42.404603],
              [-8.890725, 42.404577],
              [-8.890648, 42.40441],
              [-8.890938, 42.404395],
              [-8.890921, 42.404603],
            ],
          ],
          [
            [
              [-8.885343, 41.881909],
              [-8.885048, 41.881843],
              [-8.884968, 41.881631],
              [-8.885344, 41.881792],
              [-8.885343, 41.881909],
            ],
          ],
          [
            [
              [-8.900757, 42.110023],
              [-8.901074, 42.110052],
              [-8.901139, 42.110237],
              [-8.900944, 42.11022],
              [-8.900757, 42.110023],
            ],
          ],
          [
            [
              [-8.824602, 42.299935],
              [-8.824814, 42.299996],
              [-8.824824, 42.30023],
              [-8.824625, 42.300186],
              [-8.824602, 42.299935],
            ],
          ],
          [
            [
              [-8.923645, 42.45143],
              [-8.923527, 42.451338],
              [-8.923455, 42.451215],
              [-8.92381, 42.451242],
              [-8.923645, 42.45143],
            ],
          ],
          [
            [
              [-8.838804, 42.340889],
              [-8.838507, 42.340823],
              [-8.838497, 42.340716],
              [-8.839026, 42.340788],
              [-8.838804, 42.340889],
            ],
          ],
          [
            [
              [-8.833767, 42.550666],
              [-8.833578, 42.550595],
              [-8.833812, 42.550367],
              [-8.833907, 42.550596],
              [-8.833767, 42.550666],
            ],
          ],
          [
            [
              [-8.936217, 42.451336],
              [-8.936473, 42.45135],
              [-8.93654, 42.451409],
              [-8.936223, 42.451525],
              [-8.936217, 42.451336],
            ],
          ],
          [
            [
              [-8.859093, 42.297119],
              [-8.859338, 42.297142],
              [-8.859321, 42.29735],
              [-8.859059, 42.297274],
              [-8.859093, 42.297119],
            ],
          ],
          [
            [
              [-8.917681, 42.450191],
              [-8.917869, 42.450254],
              [-8.917611, 42.450474],
              [-8.917555, 42.450396],
              [-8.917681, 42.450191],
            ],
          ],
          [
            [
              [-8.774006, 42.388165],
              [-8.774144, 42.388078],
              [-8.774301, 42.388196],
              [-8.774112, 42.388394],
              [-8.774006, 42.388165],
            ],
          ],
          [
            [
              [-8.8585, 42.306907],
              [-8.858265, 42.306982],
              [-8.85805, 42.306894],
              [-8.858212, 42.306805],
              [-8.8585, 42.306907],
            ],
          ],
          [
            [
              [-8.826974, 42.340629],
              [-8.827235, 42.340571],
              [-8.827278, 42.340766],
              [-8.827076, 42.340813],
              [-8.826974, 42.340629],
            ],
          ],
          [
            [
              [-8.878323, 42.500554],
              [-8.87852, 42.500589],
              [-8.878307, 42.500906],
              [-8.878221, 42.500767],
              [-8.878323, 42.500554],
            ],
          ],
          [
            [
              [-8.941846, 42.460315],
              [-8.942057, 42.460367],
              [-8.941907, 42.460573],
              [-8.94178, 42.460516],
              [-8.941846, 42.460315],
            ],
          ],
          [
            [
              [-8.901533, 42.109794],
              [-8.901696, 42.109849],
              [-8.901798, 42.110041],
              [-8.901516, 42.110002],
              [-8.901533, 42.109794],
            ],
          ],
          [
            [
              [-8.860584, 42.293586],
              [-8.860734, 42.293767],
              [-8.860498, 42.293833],
              [-8.860438, 42.29371],
              [-8.860584, 42.293586],
            ],
          ],
          [
            [
              [-8.877976, 42.145953],
              [-8.878212, 42.146013],
              [-8.878205, 42.146202],
              [-8.877983, 42.146159],
              [-8.877976, 42.145953],
            ],
          ],
          [
            [
              [-8.897854, 42.238251],
              [-8.898058, 42.238223],
              [-8.898123, 42.238408],
              [-8.897924, 42.238482],
              [-8.897854, 42.238251],
            ],
          ],
          [
            [
              [-8.836854, 42.257046],
              [-8.837118, 42.257014],
              [-8.837125, 42.257095],
              [-8.836802, 42.257274],
              [-8.836854, 42.257046],
            ],
          ],
          [
            [
              [-8.765092, 42.225211],
              [-8.765185, 42.225431],
              [-8.764948, 42.225497],
              [-8.764902, 42.225382],
              [-8.765092, 42.225211],
            ],
          ],
          [
            [
              [-8.941258, 42.460787],
              [-8.941476, 42.460775],
              [-8.941596, 42.460886],
              [-8.941212, 42.460942],
              [-8.941258, 42.460787],
            ],
          ],
          [
            [
              [-8.802258, 42.609713],
              [-8.802491, 42.609728],
              [-8.802527, 42.609852],
              [-8.802237, 42.609876],
              [-8.802258, 42.609713],
            ],
          ],
          [
            [
              [-8.810771, 42.196702],
              [-8.810859, 42.196869],
              [-8.810567, 42.196992],
              [-8.810622, 42.196791],
              [-8.810771, 42.196702],
            ],
          ],
          [
            [
              [-8.82623, 42.341369],
              [-8.826638, 42.341456],
              [-8.826646, 42.341546],
              [-8.826346, 42.341579],
              [-8.82623, 42.341369],
            ],
          ],
          [
            [
              [-8.936717, 42.341317],
              [-8.936892, 42.341371],
              [-8.936839, 42.34159],
              [-8.936662, 42.341509],
              [-8.936717, 42.341317],
            ],
          ],
          [
            [
              [-8.768404, 42.336273],
              [-8.768565, 42.33631],
              [-8.768529, 42.336572],
              [-8.76839, 42.336516],
              [-8.768404, 42.336273],
            ],
          ],
          [
            [
              [-8.838882, 42.340282],
              [-8.839064, 42.340282],
              [-8.839131, 42.340486],
              [-8.838861, 42.340454],
              [-8.838882, 42.340282],
            ],
          ],
          [
            [
              [-8.929005, 42.451044],
              [-8.929155, 42.451217],
              [-8.928882, 42.451285],
              [-8.928838, 42.451197],
              [-8.929005, 42.451044],
            ],
          ],
          [
            [
              [-8.926334, 42.448122],
              [-8.926613, 42.448108],
              [-8.926645, 42.448196],
              [-8.926401, 42.448308],
              [-8.926334, 42.448122],
            ],
          ],
          [
            [
              [-8.887666, 41.985725],
              [-8.887848, 41.985733],
              [-8.887964, 41.985943],
              [-8.887665, 41.985842],
              [-8.887666, 41.985725],
            ],
          ],
          [
            [
              [-8.919023, 42.451013],
              [-8.919213, 42.450967],
              [-8.919009, 42.451248],
              [-8.91891, 42.451109],
              [-8.919023, 42.451013],
            ],
          ],
          [
            [
              [-8.810048, 42.191338],
              [-8.81022, 42.191239],
              [-8.810194, 42.191609],
              [-8.81008, 42.191561],
              [-8.810048, 42.191338],
            ],
          ],
          [
            [
              [-8.942671, 42.46056],
              [-8.942873, 42.460505],
              [-8.942911, 42.460647],
              [-8.942603, 42.460735],
              [-8.942671, 42.46056],
            ],
          ],
          [
            [
              [-8.919545, 42.45203],
              [-8.919802, 42.452053],
              [-8.919847, 42.452141],
              [-8.919599, 42.452216],
              [-8.919545, 42.45203],
            ],
          ],
          [
            [
              [-8.876028, 41.872432],
              [-8.876198, 41.87245],
              [-8.876225, 41.872611],
              [-8.876035, 41.872647],
              [-8.876028, 41.872432],
            ],
          ],
          [
            [
              [-8.864694, 42.499086],
              [-8.864523, 42.49895],
              [-8.864871, 42.498905],
              [-8.864849, 42.49906],
              [-8.864694, 42.499086],
            ],
          ],
          [
            [
              [-8.8589, 42.307679],
              [-8.859093, 42.307669],
              [-8.859164, 42.307782],
              [-8.858792, 42.307828],
              [-8.8589, 42.307679],
            ],
          ],
          [
            [
              [-8.936845, 42.452194],
              [-8.937099, 42.452181],
              [-8.937106, 42.452253],
              [-8.936814, 42.452376],
              [-8.936845, 42.452194],
            ],
          ],
          [
            [
              [-8.760049, 42.257818],
              [-8.760267, 42.257951],
              [-8.760151, 42.258137],
              [-8.760041, 42.257999],
              [-8.760049, 42.257818],
            ],
          ],
          [
            [
              [-8.792174, 42.241054],
              [-8.792352, 42.241018],
              [-8.792418, 42.241203],
              [-8.792191, 42.241242],
              [-8.792174, 42.241054],
            ],
          ],
          [
            [
              [-8.902159, 42.536034],
              [-8.90238, 42.536059],
              [-8.902403, 42.536175],
              [-8.902137, 42.536197],
              [-8.902159, 42.536034],
            ],
          ],
          [
            [
              [-8.939996, 42.460033],
              [-8.940273, 42.46001],
              [-8.940282, 42.460108],
              [-8.939901, 42.460191],
              [-8.939996, 42.460033],
            ],
          ],
          [
            [
              [-8.902174, 42.114982],
              [-8.902294, 42.114976],
              [-8.902386, 42.115052],
              [-8.902094, 42.115166],
              [-8.902174, 42.114982],
            ],
          ],
          [
            [
              [-8.874012, 42.437403],
              [-8.8741, 42.4373],
              [-8.874271, 42.437444],
              [-8.874076, 42.437562],
              [-8.874012, 42.437403],
            ],
          ],
          [
            [
              [-8.840258, 42.338772],
              [-8.840466, 42.338788],
              [-8.840463, 42.338887],
              [-8.840111, 42.338887],
              [-8.840258, 42.338772],
            ],
          ],
          [
            [
              [-8.920736, 42.451257],
              [-8.920952, 42.451228],
              [-8.921141, 42.451299],
              [-8.92087, 42.451394],
              [-8.920736, 42.451257],
            ],
          ],
          [
            [
              [-8.834387, 42.570709],
              [-8.834492, 42.570667],
              [-8.83463, 42.570705],
              [-8.834229, 42.570843],
              [-8.834387, 42.570709],
            ],
          ],
          [
            [
              [-8.887402, 42.047671],
              [-8.887532, 42.047647],
              [-8.887599, 42.04785],
              [-8.887397, 42.047888],
              [-8.887402, 42.047671],
            ],
          ],
          [
            [
              [-8.887739, 42.047015],
              [-8.887934, 42.047032],
              [-8.88801, 42.047199],
              [-8.887767, 42.047184],
              [-8.887739, 42.047015],
            ],
          ],
          [
            [
              [-8.937672, 42.456688],
              [-8.937903, 42.456685],
              [-8.937911, 42.456892],
              [-8.937714, 42.456875],
              [-8.937672, 42.456688],
            ],
          ],
          [
            [
              [-8.831671, 42.251054],
              [-8.831819, 42.251082],
              [-8.831762, 42.251247],
              [-8.831486, 42.251153],
              [-8.831671, 42.251054],
            ],
          ],
          [
            [
              [-8.939596, 42.46838],
              [-8.939473, 42.468369],
              [-8.939379, 42.468275],
              [-8.939651, 42.468189],
              [-8.939596, 42.46838],
            ],
          ],
          [
            [
              [-8.938782, 42.455055],
              [-8.939002, 42.455071],
              [-8.938938, 42.45529],
              [-8.938776, 42.455254],
              [-8.938782, 42.455055],
            ],
          ],
          [
            [
              [-8.865106, 42.499722],
              [-8.865312, 42.499711],
              [-8.86544, 42.499776],
              [-8.86523, 42.499877],
              [-8.865106, 42.499722],
            ],
          ],
          [
            [
              [-8.850283, 42.154277],
              [-8.850196, 42.153994],
              [-8.850437, 42.153981],
              [-8.850378, 42.154264],
              [-8.850283, 42.154277],
            ],
          ],
          [
            [
              [-8.825777, 42.141837],
              [-8.826056, 42.141984],
              [-8.826049, 42.142039],
              [-8.825834, 42.142068],
              [-8.825777, 42.141837],
            ],
          ],
          [
            [
              [-8.746284, 42.263948],
              [-8.746391, 42.263934],
              [-8.746557, 42.264025],
              [-8.746276, 42.264129],
              [-8.746284, 42.263948],
            ],
          ],
          [
            [
              [-8.911036, 42.200618],
              [-8.911263, 42.200579],
              [-8.911304, 42.200757],
              [-8.911063, 42.200769],
              [-8.911036, 42.200618],
            ],
          ],
          [
            [
              [-8.859389, 42.294565],
              [-8.859613, 42.294626],
              [-8.859564, 42.294745],
              [-8.859412, 42.294807],
              [-8.859389, 42.294565],
            ],
          ],
          [
            [
              [-8.910664, 42.179033],
              [-8.910588, 42.179118],
              [-8.910265, 42.179035],
              [-8.91039, 42.178948],
              [-8.910664, 42.179033],
            ],
          ],
          [
            [
              [-8.898393, 42.102421],
              [-8.898539, 42.102432],
              [-8.89863, 42.102625],
              [-8.898346, 42.102559],
              [-8.898393, 42.102421],
            ],
          ],
          [
            [
              [-8.859264, 42.147273],
              [-8.859074, 42.147301],
              [-8.859003, 42.147188],
              [-8.859224, 42.147095],
              [-8.859264, 42.147273],
            ],
          ],
          [
            [
              [-8.935345, 42.453812],
              [-8.935206, 42.453756],
              [-8.935232, 42.453511],
              [-8.935402, 42.453638],
              [-8.935345, 42.453812],
            ],
          ],
          [
            [
              [-8.874566, 42.147784],
              [-8.874773, 42.147801],
              [-8.874659, 42.148005],
              [-8.874512, 42.147985],
              [-8.874566, 42.147784],
            ],
          ],
          [
            [
              [-8.928165, 42.447685],
              [-8.928348, 42.447694],
              [-8.928087, 42.447887],
              [-8.928046, 42.447718],
              [-8.928165, 42.447685],
            ],
          ],
          [
            [
              [-8.900753, 42.108808],
              [-8.900919, 42.108781],
              [-8.901038, 42.108892],
              [-8.900854, 42.108992],
              [-8.900753, 42.108808],
            ],
          ],
          [
            [
              [-8.812955, 42.496455],
              [-8.813161, 42.496445],
              [-8.813174, 42.496579],
              [-8.812947, 42.496627],
              [-8.812955, 42.496455],
            ],
          ],
          [
            [
              [-8.834771, 42.287048],
              [-8.834873, 42.287232],
              [-8.834672, 42.287287],
              [-8.834667, 42.287107],
              [-8.834771, 42.287048],
            ],
          ],
          [
            [
              [-8.875418, 42.14693],
              [-8.875661, 42.146945],
              [-8.875743, 42.147049],
              [-8.875504, 42.147079],
              [-8.875418, 42.14693],
            ],
          ],
          [
            [
              [-8.823891, 42.579607],
              [-8.824088, 42.579633],
              [-8.824136, 42.579757],
              [-8.823713, 42.579661],
              [-8.823891, 42.579607],
            ],
          ],
          [
            [
              [-8.797544, 42.336752],
              [-8.797724, 42.336733],
              [-8.797752, 42.336903],
              [-8.797537, 42.336941],
              [-8.797544, 42.336752],
            ],
          ],
          [
            [
              [-8.774167, 42.222727],
              [-8.774286, 42.222703],
              [-8.774357, 42.222952],
              [-8.774174, 42.222943],
              [-8.774167, 42.222727],
            ],
          ],
          [
            [
              [-8.858376, 42.298794],
              [-8.858524, 42.298822],
              [-8.858574, 42.298964],
              [-8.858309, 42.298986],
              [-8.858376, 42.298794],
            ],
          ],
          [
            [
              [-8.892374, 42.496428],
              [-8.892184, 42.496482],
              [-8.892059, 42.496318],
              [-8.892226, 42.496282],
              [-8.892374, 42.496428],
            ],
          ],
          [
            [
              [-8.882839, 41.880093],
              [-8.883068, 41.880091],
              [-8.883125, 41.880196],
              [-8.882889, 41.880244],
              [-8.882839, 41.880093],
            ],
          ],
          [
            [
              [-8.898185, 42.100317],
              [-8.898344, 42.100336],
              [-8.898334, 42.100489],
              [-8.898037, 42.100405],
              [-8.898185, 42.100317],
            ],
          ],
          [
            [
              [-8.862787, 42.306318],
              [-8.862963, 42.306381],
              [-8.862932, 42.306572],
              [-8.862756, 42.3065],
              [-8.862787, 42.306318],
            ],
          ],
          [
            [
              [-8.917421, 42.449881],
              [-8.917582, 42.449908],
              [-8.917714, 42.450028],
              [-8.91755, 42.45009],
              [-8.917421, 42.449881],
            ],
          ],
          [
            [
              [-8.911932, 42.194792],
              [-8.9118, 42.194799],
              [-8.91174, 42.194685],
              [-8.91201, 42.19459],
              [-8.911932, 42.194792],
            ],
          ],
          [
            [
              [-8.945167, 42.385122],
              [-8.945361, 42.38512],
              [-8.945294, 42.385304],
              [-8.945123, 42.385295],
              [-8.945167, 42.385122],
            ],
          ],
          [
            [
              [-8.892667, 42.494765],
              [-8.892808, 42.494839],
              [-8.892565, 42.494969],
              [-8.892537, 42.494808],
              [-8.892667, 42.494765],
            ],
          ],
          [
            [
              [-8.863594, 42.306124],
              [-8.863813, 42.306131],
              [-8.863811, 42.306239],
              [-8.863573, 42.306287],
              [-8.863594, 42.306124],
            ],
          ],
          [
            [
              [-8.938982, 42.456908],
              [-8.939105, 42.456929],
              [-8.939112, 42.457126],
              [-8.938857, 42.457005],
              [-8.938982, 42.456908],
            ],
          ],
          [
            [
              [-8.947396, 42.379938],
              [-8.947555, 42.379947],
              [-8.94748, 42.380185],
              [-8.947364, 42.38011],
              [-8.947396, 42.379938],
            ],
          ],
          [
            [
              [-8.939867, 42.474821],
              [-8.94006, 42.474811],
              [-8.940086, 42.474953],
              [-8.939843, 42.474957],
              [-8.939867, 42.474821],
            ],
          ],
          [
            [
              [-8.828496, 42.305588],
              [-8.828669, 42.305615],
              [-8.828701, 42.305703],
              [-8.828424, 42.305726],
              [-8.828496, 42.305588],
            ],
          ],
          [
            [
              [-8.858888, 42.29739],
              [-8.859024, 42.297419],
              [-8.858972, 42.297638],
              [-8.858861, 42.297617],
              [-8.858888, 42.29739],
            ],
          ],
          [
            [
              [-8.861797, 42.283819],
              [-8.861982, 42.283855],
              [-8.86196, 42.284009],
              [-8.86179, 42.284009],
              [-8.861797, 42.283819],
            ],
          ],
          [
            [
              [-8.885207, 41.881349],
              [-8.885401, 41.881366],
              [-8.885411, 41.881474],
              [-8.885136, 41.881497],
              [-8.885207, 41.881349],
            ],
          ],
          [
            [
              [-8.888672, 41.982504],
              [-8.888854, 41.982522],
              [-8.888949, 41.982634],
              [-8.888747, 41.982671],
              [-8.888672, 41.982504],
            ],
          ],
          [
            [
              [-8.896312, 42.491102],
              [-8.89619, 42.491109],
              [-8.896129, 42.490968],
              [-8.896356, 42.490929],
              [-8.896312, 42.491102],
            ],
          ],
          [
            [
              [-8.864999, 42.49871],
              [-8.865216, 42.49869],
              [-8.865271, 42.498759],
              [-8.865087, 42.498867],
              [-8.864999, 42.49871],
            ],
          ],
          [
            [
              [-8.921542, 42.401643],
              [-8.92171, 42.401751],
              [-8.921624, 42.401863],
              [-8.921461, 42.401809],
              [-8.921542, 42.401643],
            ],
          ],
          [
            [
              [-8.912584, 42.394743],
              [-8.912714, 42.394826],
              [-8.912482, 42.394946],
              [-8.912458, 42.394822],
              [-8.912584, 42.394743],
            ],
          ],
          [
            [
              [-8.9201, 42.451866],
              [-8.920244, 42.451976],
              [-8.920008, 42.452051],
              [-8.91996, 42.451928],
              [-8.9201, 42.451866],
            ],
          ],
          [
            [
              [-8.894385, 42.494541],
              [-8.894615, 42.49453],
              [-8.894603, 42.494656],
              [-8.894315, 42.494698],
              [-8.894385, 42.494541],
            ],
          ],
          [
            [
              [-8.89754, 42.087135],
              [-8.897401, 42.08707],
              [-8.897435, 42.086916],
              [-8.897606, 42.086934],
              [-8.89754, 42.087135],
            ],
          ],
          [
            [
              [-8.935498, 42.45465],
              [-8.935274, 42.454472],
              [-8.935318, 42.454416],
              [-8.935544, 42.454494],
              [-8.935498, 42.45465],
            ],
          ],
          [
            [
              [-8.868564, 42.147372],
              [-8.868436, 42.147298],
              [-8.868397, 42.147138],
              [-8.868651, 42.147269],
              [-8.868564, 42.147372],
            ],
          ],
          [
            [
              [-8.928609, 42.4475],
              [-8.928456, 42.447427],
              [-8.928385, 42.447314],
              [-8.928644, 42.447354],
              [-8.928609, 42.4475],
            ],
          ],
          [
            [
              [-8.939694, 42.476972],
              [-8.939916, 42.476879],
              [-8.939983, 42.476939],
              [-8.939803, 42.477101],
              [-8.939694, 42.476972],
            ],
          ],
          [
            [
              [-8.817867, 42.189617],
              [-8.818047, 42.189608],
              [-8.818226, 42.189707],
              [-8.817951, 42.189748],
              [-8.817867, 42.189617],
            ],
          ],
          [
            [
              [-8.826238, 42.341072],
              [-8.826387, 42.341109],
              [-8.826425, 42.341251],
              [-8.82618, 42.341228],
              [-8.826238, 42.341072],
            ],
          ],
          [
            [
              [-8.917425, 42.450439],
              [-8.917229, 42.450422],
              [-8.917247, 42.450232],
              [-8.917384, 42.450261],
              [-8.917425, 42.450439],
            ],
          ],
          [
            [
              [-8.832885, 42.569759],
              [-8.832632, 42.56979],
              [-8.832618, 42.569764],
              [-8.832987, 42.569673],
              [-8.832885, 42.569759],
            ],
          ],
          [
            [
              [-8.939915, 42.459569],
              [-8.940098, 42.459578],
              [-8.940068, 42.459768],
              [-8.939969, 42.459755],
              [-8.939915, 42.459569],
            ],
          ],
          [
            [
              [-8.803865, 42.199286],
              [-8.804048, 42.199294],
              [-8.803945, 42.199497],
              [-8.803786, 42.199344],
              [-8.803865, 42.199286],
            ],
          ],
          [
            [
              [-8.841996, 42.158734],
              [-8.842139, 42.158718],
              [-8.842208, 42.158939],
              [-8.842114, 42.158971],
              [-8.841996, 42.158734],
            ],
          ],
          [
            [
              [-8.873604, 42.148761],
              [-8.873494, 42.148622],
              [-8.873523, 42.14854],
              [-8.873783, 42.148734],
              [-8.873604, 42.148761],
            ],
          ],
          [
            [
              [-8.897745, 42.237987],
              [-8.897978, 42.23802],
              [-8.897983, 42.238074],
              [-8.897621, 42.238083],
              [-8.897745, 42.237987],
            ],
          ],
          [
            [
              [-8.877327, 41.873598],
              [-8.877133, 41.873581],
              [-8.877073, 41.873458],
              [-8.877353, 41.873489],
              [-8.877327, 41.873598],
            ],
          ],
          [
            [
              [-8.863658, 42.307336],
              [-8.863879, 42.307361],
              [-8.863914, 42.307485],
              [-8.863721, 42.307486],
              [-8.863658, 42.307336],
            ],
          ],
          [
            [
              [-8.898865, 42.114892],
              [-8.899043, 42.114864],
              [-8.899125, 42.114959],
              [-8.898863, 42.115009],
              [-8.898865, 42.114892],
            ],
          ],
          [
            [
              [-8.802756, 42.341834],
              [-8.802924, 42.341808],
              [-8.802872, 42.342035],
              [-8.802745, 42.34197],
              [-8.802756, 42.341834],
            ],
          ],
          [
            [
              [-8.901846, 42.115485],
              [-8.902025, 42.115467],
              [-8.902076, 42.115617],
              [-8.901808, 42.115604],
              [-8.901846, 42.115485],
            ],
          ],
          [
            [
              [-8.903963, 42.24385],
              [-8.904004, 42.244037],
              [-8.903836, 42.244055],
              [-8.903801, 42.243939],
              [-8.903963, 42.24385],
            ],
          ],
          [
            [
              [-8.826661, 42.303971],
              [-8.826754, 42.303921],
              [-8.826849, 42.304033],
              [-8.82656, 42.304057],
              [-8.826661, 42.303971],
            ],
          ],
          [
            [
              [-8.887094, 41.943761],
              [-8.887249, 41.943744],
              [-8.88738, 41.943854],
              [-8.887078, 41.943851],
              [-8.887094, 41.943761],
            ],
          ],
          [
            [
              [-8.887772, 41.934913],
              [-8.887972, 41.934993],
              [-8.88784, 41.935125],
              [-8.887716, 41.935087],
              [-8.887772, 41.934913],
            ],
          ],
          [
            [
              [-8.810714, 42.191601],
              [-8.810683, 42.191405],
              [-8.810715, 42.191358],
              [-8.810889, 42.191664],
              [-8.810714, 42.191601],
            ],
          ],
          [
            [
              [-8.896681, 42.489859],
              [-8.896548, 42.489866],
              [-8.896459, 42.4897],
              [-8.896677, 42.489688],
              [-8.896681, 42.489859],
            ],
          ],
          [
            [
              [-8.905581, 42.12054],
              [-8.905758, 42.120495],
              [-8.90574, 42.120694],
              [-8.905555, 42.12065],
              [-8.905581, 42.12054],
            ],
          ],
          [
            [
              [-8.937334, 42.456193],
              [-8.937473, 42.456123],
              [-8.93755, 42.45629],
              [-8.937332, 42.456301],
              [-8.937334, 42.456193],
            ],
          ],
          [
            [
              [-8.896263, 42.097319],
              [-8.896423, 42.097356],
              [-8.896426, 42.097509],
              [-8.896254, 42.097482],
              [-8.896263, 42.097319],
            ],
          ],
          [
            [
              [-8.94534, 42.383996],
              [-8.945521, 42.383987],
              [-8.945545, 42.384112],
              [-8.945369, 42.384175],
              [-8.94534, 42.383996],
            ],
          ],
          [
            [
              [-8.93928, 42.455182],
              [-8.939452, 42.4552],
              [-8.939424, 42.455292],
              [-8.939234, 42.455338],
              [-8.93928, 42.455182],
            ],
          ],
          [
            [
              [-8.813605, 42.578637],
              [-8.813775, 42.578628],
              [-8.813845, 42.578724],
              [-8.813593, 42.578764],
              [-8.813605, 42.578637],
            ],
          ],
          [
            [
              [-8.858677, 42.298634],
              [-8.858836, 42.298653],
              [-8.858824, 42.29878],
              [-8.858592, 42.298765],
              [-8.858677, 42.298634],
            ],
          ],
          [
            [
              [-8.877577, 42.55743],
              [-8.877712, 42.557441],
              [-8.877737, 42.557584],
              [-8.877543, 42.557594],
              [-8.877577, 42.55743],
            ],
          ],
          [
            [
              [-8.867297, 42.261509],
              [-8.867451, 42.261601],
              [-8.867429, 42.261755],
              [-8.867306, 42.261734],
              [-8.867297, 42.261509],
            ],
          ],
          [
            [
              [-8.776774, 42.220912],
              [-8.77693, 42.220895],
              [-8.776937, 42.221102],
              [-8.776769, 42.220993],
              [-8.776774, 42.220912],
            ],
          ],
          [
            [
              [-8.933348, 42.480579],
              [-8.933464, 42.480527],
              [-8.933598, 42.480656],
              [-8.933334, 42.480687],
              [-8.933348, 42.480579],
            ],
          ],
          [
            [
              [-8.849733, 42.157753],
              [-8.849853, 42.157738],
              [-8.84989, 42.15788],
              [-8.849674, 42.1579],
              [-8.849733, 42.157753],
            ],
          ],
          [
            [
              [-8.935923, 42.455033],
              [-8.936077, 42.454989],
              [-8.93616, 42.455093],
              [-8.935937, 42.455176],
              [-8.935923, 42.455033],
            ],
          ],
          [
            [
              [-8.886904, 41.947065],
              [-8.887024, 41.94705],
              [-8.887067, 41.947255],
              [-8.886855, 41.947184],
              [-8.886904, 41.947065],
            ],
          ],
          [
            [
              [-8.936772, 42.48104],
              [-8.937022, 42.480982],
              [-8.937076, 42.481042],
              [-8.936939, 42.48113],
              [-8.936772, 42.48104],
            ],
          ],
          [
            [
              [-8.902329, 42.239983],
              [-8.902364, 42.240098],
              [-8.902136, 42.240128],
              [-8.902152, 42.240037],
              [-8.902329, 42.239983],
            ],
          ],
          [
            [
              [-8.941571, 42.465748],
              [-8.941716, 42.465867],
              [-8.941591, 42.465954],
              [-8.941511, 42.465886],
              [-8.941571, 42.465748],
            ],
          ],
          [
            [
              [-8.860816, 42.294258],
              [-8.861011, 42.294266],
              [-8.860997, 42.294384],
              [-8.860779, 42.294386],
              [-8.860816, 42.294258],
            ],
          ],
          [
            [
              [-8.742928, 42.27022],
              [-8.742823, 42.270001],
              [-8.742906, 42.269978],
              [-8.743024, 42.270071],
              [-8.742928, 42.27022],
            ],
          ],
          [
            [
              [-8.873551, 42.147278],
              [-8.873715, 42.147351],
              [-8.873605, 42.147474],
              [-8.87349, 42.147398],
              [-8.873551, 42.147278],
            ],
          ],
          [
            [
              [-8.849837, 42.157568],
              [-8.849743, 42.1576],
              [-8.849594, 42.157427],
              [-8.849824, 42.157425],
              [-8.849837, 42.157568],
            ],
          ],
          [
            [
              [-8.740257, 42.270435],
              [-8.740461, 42.270407],
              [-8.740519, 42.270512],
              [-8.740315, 42.270541],
              [-8.740257, 42.270435],
            ],
          ],
          [
            [
              [-8.802938, 42.610209],
              [-8.802878, 42.60996],
              [-8.802926, 42.609949],
              [-8.803085, 42.61022],
              [-8.802938, 42.610209],
            ],
          ],
          [
            [
              [-8.828824, 42.323017],
              [-8.829043, 42.323024],
              [-8.829038, 42.323096],
              [-8.828862, 42.323159],
              [-8.828824, 42.323017],
            ],
          ],
          [
            [
              [-8.945684, 42.382889],
              [-8.945559, 42.382851],
              [-8.945558, 42.382707],
              [-8.945742, 42.382733],
              [-8.945684, 42.382889],
            ],
          ],
          [
            [
              [-8.763336, 42.255069],
              [-8.76315, 42.255024],
              [-8.763142, 42.254935],
              [-8.763372, 42.254932],
              [-8.763336, 42.255069],
            ],
          ],
          [
            [
              [-8.934831, 42.481861],
              [-8.934977, 42.481853],
              [-8.934971, 42.482051],
              [-8.934835, 42.482032],
              [-8.934831, 42.481861],
            ],
          ],
          [
            [
              [-8.937777, 42.477531],
              [-8.937884, 42.477507],
              [-8.937944, 42.47763],
              [-8.937746, 42.477712],
              [-8.937777, 42.477531],
            ],
          ],
          [
            [
              [-8.913272, 42.203941],
              [-8.91345, 42.203896],
              [-8.913485, 42.204011],
              [-8.913332, 42.204064],
              [-8.913272, 42.203941],
            ],
          ],
          [
            [
              [-8.90319, 42.112652],
              [-8.903303, 42.112565],
              [-8.903363, 42.112697],
              [-8.903182, 42.112824],
              [-8.90319, 42.112652],
            ],
          ],
          [
            [
              [-8.80033, 42.338681],
              [-8.800536, 42.338679],
              [-8.800546, 42.338787],
              [-8.800355, 42.338814],
              [-8.80033, 42.338681],
            ],
          ],
          [
            [
              [-8.938482, 42.478295],
              [-8.938348, 42.478293],
              [-8.938341, 42.478096],
              [-8.938521, 42.478194],
              [-8.938482, 42.478295],
            ],
          ],
          [
            [
              [-8.807536, 42.195615],
              [-8.807673, 42.195662],
              [-8.807616, 42.195836],
              [-8.807531, 42.195832],
              [-8.807536, 42.195615],
            ],
          ],
          [
            [
              [-8.939559, 42.476448],
              [-8.9397, 42.476396],
              [-8.939693, 42.476576],
              [-8.939526, 42.476612],
              [-8.939559, 42.476448],
            ],
          ],
          [
            [
              [-8.7777, 42.226141],
              [-8.777808, 42.226252],
              [-8.777605, 42.226289],
              [-8.777594, 42.226173],
              [-8.7777, 42.226141],
            ],
          ],
          [
            [
              [-8.748368, 42.267804],
              [-8.748159, 42.26777],
              [-8.748151, 42.26768],
              [-8.748416, 42.267658],
              [-8.748368, 42.267804],
            ],
          ],
          [
            [
              [-8.778483, 42.228342],
              [-8.778601, 42.228436],
              [-8.778463, 42.228515],
              [-8.778369, 42.228411],
              [-8.778483, 42.228342],
            ],
          ],
          [
            [
              [-8.829301, 42.323191],
              [-8.829136, 42.323109],
              [-8.829118, 42.323038],
              [-8.829427, 42.323103],
              [-8.829301, 42.323191],
            ],
          ],
          [
            [
              [-8.862645, 42.282777],
              [-8.862781, 42.282797],
              [-8.862791, 42.282913],
              [-8.862623, 42.282931],
              [-8.862645, 42.282777],
            ],
          ],
          [
            [
              [-8.946028, 42.382799],
              [-8.946195, 42.382773],
              [-8.946209, 42.382916],
              [-8.946065, 42.382932],
              [-8.946028, 42.382799],
            ],
          ],
          [
            [
              [-8.778414, 42.217129],
              [-8.778574, 42.217157],
              [-8.778525, 42.217286],
              [-8.77839, 42.217266],
              [-8.778414, 42.217129],
            ],
          ],
          [
            [
              [-8.910019, 42.177824],
              [-8.910238, 42.177839],
              [-8.910284, 42.177945],
              [-8.910002, 42.177897],
              [-8.910019, 42.177824],
            ],
          ],
          [
            [
              [-8.631652, 42.31055],
              [-8.631452, 42.310479],
              [-8.631454, 42.31037],
              [-8.631692, 42.310458],
              [-8.631652, 42.31055],
            ],
          ],
          [
            [
              [-8.834506, 42.341514],
              [-8.834664, 42.341515],
              [-8.834686, 42.341622],
              [-8.834455, 42.341616],
              [-8.834506, 42.341514],
            ],
          ],
          [
            [
              [-8.89164, 42.495871],
              [-8.891769, 42.495946],
              [-8.891507, 42.496013],
              [-8.89149, 42.49596],
              [-8.89164, 42.495871],
            ],
          ],
          [
            [
              [-8.936636, 42.480759],
              [-8.936817, 42.48074],
              [-8.936869, 42.480783],
              [-8.936612, 42.480886],
              [-8.936636, 42.480759],
            ],
          ],
          [
            [
              [-8.93879, 42.455658],
              [-8.938618, 42.45564],
              [-8.938748, 42.455471],
              [-8.93883, 42.455566],
              [-8.93879, 42.455658],
            ],
          ],
          [
            [
              [-8.896784, 42.493797],
              [-8.896633, 42.493867],
              [-8.896564, 42.493781],
              [-8.896639, 42.493669],
              [-8.896784, 42.493797],
            ],
          ],
          [
            [
              [-8.938118, 42.478305],
              [-8.938223, 42.478255],
              [-8.938252, 42.478433],
              [-8.938091, 42.478406],
              [-8.938118, 42.478305],
            ],
          ],
          [
            [
              [-8.939298, 42.476389],
              [-8.939386, 42.476295],
              [-8.939462, 42.476318],
              [-8.939394, 42.476502],
              [-8.939298, 42.476389],
            ],
          ],
          [
            [
              [-8.833283, 42.28643],
              [-8.833198, 42.2863],
              [-8.833417, 42.286307],
              [-8.833425, 42.286396],
              [-8.833283, 42.28643],
            ],
          ],
          [
            [
              [-8.933124, 42.480014],
              [-8.933209, 42.48001],
              [-8.933432, 42.480061],
              [-8.933209, 42.480145],
              [-8.933124, 42.480014],
            ],
          ],
          [
            [
              [-8.804244, 42.19959],
              [-8.804184, 42.199458],
              [-8.804387, 42.19943],
              [-8.804364, 42.199575],
              [-8.804244, 42.19959],
            ],
          ],
          [
            [
              [-8.866982, 42.26086],
              [-8.867097, 42.260926],
              [-8.867068, 42.261008],
              [-8.866883, 42.260973],
              [-8.866982, 42.26086],
            ],
          ],
          [
            [
              [-8.898701, 42.085194],
              [-8.898635, 42.085008],
              [-8.898804, 42.085009],
              [-8.898783, 42.085172],
              [-8.898701, 42.085194],
            ],
          ],
          [
            [
              [-8.835594, 42.341341],
              [-8.835743, 42.34137],
              [-8.835717, 42.341488],
              [-8.835548, 42.341497],
              [-8.835594, 42.341341],
            ],
          ],
          [
            [
              [-8.917516, 42.449597],
              [-8.917597, 42.449557],
              [-8.917759, 42.449602],
              [-8.917648, 42.449707],
              [-8.917516, 42.449597],
            ],
          ],
          [
            [
              [-8.939085, 42.47632],
              [-8.939151, 42.476253],
              [-8.939237, 42.476258],
              [-8.939076, 42.476482],
              [-8.939085, 42.47632],
            ],
          ],
          [
            [
              [-8.921592, 42.450934],
              [-8.921471, 42.45094],
              [-8.921386, 42.450809],
              [-8.921579, 42.45079],
              [-8.921592, 42.450934],
            ],
          ],
          [
            [
              [-8.867069, 42.261278],
              [-8.86694, 42.261195],
              [-8.867205, 42.261172],
              [-8.867188, 42.261254],
              [-8.867069, 42.261278],
            ],
          ],
          [
            [
              [-8.754665, 42.350549],
              [-8.754761, 42.350536],
              [-8.754842, 42.350631],
              [-8.754601, 42.350652],
              [-8.754665, 42.350549],
            ],
          ],
          [
            [
              [-8.938548, 42.478733],
              [-8.93848, 42.478655],
              [-8.938471, 42.478566],
              [-8.938682, 42.478609],
              [-8.938548, 42.478733],
            ],
          ],
          [
            [
              [-8.860463, 42.29072],
              [-8.860555, 42.290805],
              [-8.860326, 42.290817],
              [-8.860319, 42.290737],
              [-8.860463, 42.29072],
            ],
          ],
          [
            [
              [-8.80733, 42.195617],
              [-8.807439, 42.19562],
              [-8.807397, 42.19582],
              [-8.807307, 42.195762],
              [-8.80733, 42.195617],
            ],
          ],
          [
            [
              [-8.93903, 42.455492],
              [-8.939187, 42.455484],
              [-8.939046, 42.455663],
              [-8.938996, 42.455647],
              [-8.93903, 42.455492],
            ],
          ],
          [
            [
              [-8.8477, 42.148585],
              [-8.847827, 42.148651],
              [-8.847629, 42.148724],
              [-8.847586, 42.148654],
              [-8.8477, 42.148585],
            ],
          ],
          [
            [
              [-8.825971, 42.342246],
              [-8.826024, 42.342163],
              [-8.826193, 42.34228],
              [-8.826003, 42.342326],
              [-8.825971, 42.342246],
            ],
          ],
          [
            [
              [-8.910522, 42.178284],
              [-8.9106, 42.178217],
              [-8.910602, 42.178496],
              [-8.910489, 42.178448],
              [-8.910522, 42.178284],
            ],
          ],
          [
            [
              [-8.799964, 42.338528],
              [-8.800091, 42.338585],
              [-8.799969, 42.338717],
              [-8.799902, 42.338648],
              [-8.799964, 42.338528],
            ],
          ],
          [
            [
              [-8.895509, 42.083621],
              [-8.895698, 42.083566],
              [-8.895741, 42.083645],
              [-8.895627, 42.083714],
              [-8.895509, 42.083621],
            ],
          ],
          [
            [
              [-8.779054, 42.218961],
              [-8.779119, 42.218876],
              [-8.779236, 42.218969],
              [-8.779087, 42.219058],
              [-8.779054, 42.218961],
            ],
          ],
          [
            [
              [-8.896849, 42.095696],
              [-8.897007, 42.095705],
              [-8.897019, 42.095831],
              [-8.896899, 42.095846],
              [-8.896849, 42.095696],
            ],
          ],
          [
            [
              [-8.704252, 42.259104],
              [-8.704372, 42.259089],
              [-8.704452, 42.259166],
              [-8.704263, 42.259229],
              [-8.704252, 42.259104],
            ],
          ],
          [
            [
              [-8.896664, 42.490193],
              [-8.896625, 42.490303],
              [-8.896399, 42.490225],
              [-8.896501, 42.490147],
              [-8.896664, 42.490193],
            ],
          ],
          [
            [
              [-8.868778, 42.148252],
              [-8.868935, 42.148244],
              [-8.868918, 42.148326],
              [-8.868691, 42.148356],
              [-8.868778, 42.148252],
            ],
          ],
          [
            [
              [-8.738401, 42.37606],
              [-8.738613, 42.376121],
              [-8.738617, 42.376166],
              [-8.738448, 42.376175],
              [-8.738401, 42.37606],
            ],
          ],
          [
            [
              [-8.938154, 42.478942],
              [-8.937915, 42.478982],
              [-8.937908, 42.47891],
              [-8.938148, 42.47888],
              [-8.938154, 42.478942],
            ],
          ],
          [
            [
              [-8.807498, 42.339046],
              [-8.807681, 42.339055],
              [-8.807656, 42.339173],
              [-8.807498, 42.339172],
              [-8.807498, 42.339046],
            ],
          ],
          [
            [
              [-8.853031, 42.14527],
              [-8.852921, 42.145257],
              [-8.852863, 42.145152],
              [-8.853018, 42.145127],
              [-8.853031, 42.14527],
            ],
          ],
          [
            [
              [-8.858251, 42.307748],
              [-8.858385, 42.30775],
              [-8.858384, 42.307876],
              [-8.858203, 42.307877],
              [-8.858251, 42.307748],
            ],
          ],
          [
            [
              [-8.939931, 42.467679],
              [-8.940064, 42.467672],
              [-8.940018, 42.467827],
              [-8.939894, 42.467807],
              [-8.939931, 42.467679],
            ],
          ],
          [
            [
              [-8.864842, 42.499366],
              [-8.864836, 42.499438],
              [-8.864579, 42.499425],
              [-8.864596, 42.499343],
              [-8.864842, 42.499366],
            ],
          ],
          [
            [
              [-8.878632, 42.500484],
              [-8.878541, 42.500417],
              [-8.878551, 42.500264],
              [-8.878683, 42.500374],
              [-8.878632, 42.500484],
            ],
          ],
          [
            [
              [-8.798249, 42.33848],
              [-8.798386, 42.338518],
              [-8.798373, 42.338645],
              [-8.79824, 42.338643],
              [-8.798249, 42.33848],
            ],
          ],
          [
            [
              [-8.850141, 42.157057],
              [-8.850079, 42.157051],
              [-8.850008, 42.156938],
              [-8.850189, 42.156929],
              [-8.850141, 42.157057],
            ],
          ],
          [
            [
              [-8.860896, 42.285081],
              [-8.861001, 42.285039],
              [-8.861028, 42.2852],
              [-8.860907, 42.285206],
              [-8.860896, 42.285081],
            ],
          ],
          [
            [
              [-8.900876, 42.094417],
              [-8.900676, 42.094481],
              [-8.900667, 42.094382],
              [-8.900893, 42.094344],
              [-8.900876, 42.094417],
            ],
          ],
          [
            [
              [-8.824086, 42.579345],
              [-8.824179, 42.57943],
              [-8.824157, 42.579585],
              [-8.82408, 42.579543],
              [-8.824086, 42.579345],
            ],
          ],
          [
            [
              [-8.937542, 42.478128],
              [-8.937461, 42.478177],
              [-8.937354, 42.478075],
              [-8.937507, 42.478022],
              [-8.937542, 42.478128],
            ],
          ],
          [
            [
              [-8.850125, 42.153484],
              [-8.850257, 42.153478],
              [-8.850339, 42.153572],
              [-8.850197, 42.153616],
              [-8.850125, 42.153484],
            ],
          ],
          [
            [
              [-8.835862, 42.34122],
              [-8.835986, 42.341249],
              [-8.835972, 42.341358],
              [-8.835839, 42.341365],
              [-8.835862, 42.34122],
            ],
          ],
          [
            [
              [-8.842713, 42.158121],
              [-8.842604, 42.158118],
              [-8.842553, 42.157958],
              [-8.842651, 42.157971],
              [-8.842713, 42.158121],
            ],
          ],
          [
            [
              [-8.840261, 42.338537],
              [-8.840129, 42.338562],
              [-8.840064, 42.338511],
              [-8.840276, 42.338447],
              [-8.840261, 42.338537],
            ],
          ],
          [
            [
              [-8.754831, 42.350109],
              [-8.754964, 42.350102],
              [-8.754884, 42.350286],
              [-8.75483, 42.350235],
              [-8.754831, 42.350109],
            ],
          ],
          [
            [
              [-8.794483, 42.246023],
              [-8.794595, 42.246053],
              [-8.794569, 42.246162],
              [-8.794417, 42.246089],
              [-8.794483, 42.246023],
            ],
          ],
          [
            [
              [-8.858365, 42.299974],
              [-8.858217, 42.299954],
              [-8.858212, 42.299892],
              [-8.858364, 42.299839],
              [-8.858365, 42.299974],
            ],
          ],
          [
            [
              [-8.772932, 42.223528],
              [-8.773054, 42.22354],
              [-8.773018, 42.223676],
              [-8.772905, 42.223628],
              [-8.772932, 42.223528],
            ],
          ],
          [
            [
              [-8.860424, 42.290956],
              [-8.860594, 42.290957],
              [-8.860627, 42.291054],
              [-8.860445, 42.291054],
              [-8.860424, 42.290956],
            ],
          ],
          [
            [
              [-8.821952, 42.342082],
              [-8.822109, 42.342074],
              [-8.822136, 42.342109],
              [-8.821999, 42.342197],
              [-8.821952, 42.342082],
            ],
          ],
          [
            [
              [-8.937808, 42.477862],
              [-8.937928, 42.477838],
              [-8.937949, 42.477936],
              [-8.937771, 42.477981],
              [-8.937808, 42.477862],
            ],
          ],
          [
            [
              [-8.902927, 42.116059],
              [-8.903033, 42.116027],
              [-8.903058, 42.11617],
              [-8.902964, 42.116201],
              [-8.902927, 42.116059],
            ],
          ],
          [
            [
              [-8.859299, 42.296721],
              [-8.859096, 42.29675],
              [-8.859074, 42.296652],
              [-8.859232, 42.296653],
              [-8.859299, 42.296721],
            ],
          ],
          [
            [
              [-8.764068, 42.258174],
              [-8.763956, 42.258134],
              [-8.763947, 42.258045],
              [-8.764157, 42.258088],
              [-8.764068, 42.258174],
            ],
          ],
          [
            [
              [-8.810334, 42.191944],
              [-8.810411, 42.191994],
              [-8.810207, 42.192149],
              [-8.810189, 42.192087],
              [-8.810334, 42.191944],
            ],
          ],
          [
            [
              [-8.800682, 42.338816],
              [-8.800806, 42.338846],
              [-8.800755, 42.338947],
              [-8.800596, 42.338928],
              [-8.800682, 42.338816],
            ],
          ],
          [
            [
              [-8.860304, 42.291097],
              [-8.860453, 42.291144],
              [-8.860459, 42.291206],
              [-8.860291, 42.291224],
              [-8.860304, 42.291097],
            ],
          ],
          [
            [
              [-8.858264, 42.300195],
              [-8.858384, 42.30018],
              [-8.858396, 42.300305],
              [-8.858236, 42.300286],
              [-8.858264, 42.300195],
            ],
          ],
          [
            [
              [-8.810194, 42.191871],
              [-8.81011, 42.191884],
              [-8.810065, 42.191787],
              [-8.810217, 42.191734],
              [-8.810194, 42.191871],
            ],
          ],
          [
            [
              [-8.810571, 42.191896],
              [-8.810673, 42.191819],
              [-8.810675, 42.191972],
              [-8.810567, 42.191987],
              [-8.810571, 42.191896],
            ],
          ],
          [
            [
              [-8.824241, 42.579571],
              [-8.824354, 42.57961],
              [-8.824329, 42.579729],
              [-8.824228, 42.579689],
              [-8.824241, 42.579571],
            ],
          ],
          [
            [
              [-8.772823, 42.224055],
              [-8.772942, 42.22404],
              [-8.772986, 42.224119],
              [-8.772882, 42.224178],
              [-8.772823, 42.224055],
            ],
          ],
          [
            [
              [-8.895022, 42.08488],
              [-8.894944, 42.084821],
              [-8.895004, 42.084682],
              [-8.895068, 42.08485],
              [-8.895022, 42.08488],
            ],
          ],
          [
            [
              [-8.838441, 42.292901],
              [-8.838366, 42.293012],
              [-8.838242, 42.292983],
              [-8.838332, 42.292906],
              [-8.838441, 42.292901],
            ],
          ],
          [
            [
              [-8.776094, 42.387754],
              [-8.776227, 42.387747],
              [-8.776131, 42.387896],
              [-8.776041, 42.387838],
              [-8.776094, 42.387754],
            ],
          ],
          [
            [
              [-8.945487, 42.384394],
              [-8.945619, 42.384378],
              [-8.945426, 42.384523],
              [-8.945387, 42.384489],
              [-8.945487, 42.384394],
            ],
          ],
          [
            [
              [-8.934253, 42.479991],
              [-8.934449, 42.480008],
              [-8.934455, 42.480071],
              [-8.934247, 42.480064],
              [-8.934253, 42.479991],
            ],
          ],
          [
            [
              [-8.826344, 42.303825],
              [-8.826415, 42.30393],
              [-8.826287, 42.30399],
              [-8.826281, 42.303927],
              [-8.826344, 42.303825],
            ],
          ],
          [
            [
              [-8.866404, 42.499295],
              [-8.866529, 42.499333],
              [-8.866524, 42.499406],
              [-8.866405, 42.49943],
              [-8.866404, 42.499295],
            ],
          ],
          [
            [
              [-8.810508, 42.196473],
              [-8.810535, 42.196642],
              [-8.810444, 42.196701],
              [-8.810439, 42.196521],
              [-8.810508, 42.196473],
            ],
          ],
          [
            [
              [-8.910123, 42.249608],
              [-8.910196, 42.249613],
              [-8.910129, 42.249797],
              [-8.910088, 42.249754],
              [-8.910123, 42.249608],
            ],
          ],
          [
            [
              [-8.773001, 42.223884],
              [-8.772904, 42.223889],
              [-8.772824, 42.223803],
              [-8.77307, 42.223845],
              [-8.773001, 42.223884],
            ],
          ],
          [
            [
              [-8.813033, 42.338539],
              [-8.812917, 42.338464],
              [-8.812996, 42.338397],
              [-8.813085, 42.338447],
              [-8.813033, 42.338539],
            ],
          ],
          [
            [
              [-8.808607, 42.339881],
              [-8.808509, 42.339868],
              [-8.808464, 42.33978],
              [-8.808634, 42.33978],
              [-8.808607, 42.339881],
            ],
          ],
          [
            [
              [-8.858009, 42.300055],
              [-8.858145, 42.300084],
              [-8.858093, 42.300186],
              [-8.857968, 42.300138],
              [-8.858009, 42.300055],
            ],
          ],
          [
            [
              [-8.94389, 42.466096],
              [-8.94401, 42.46608],
              [-8.944089, 42.466148],
              [-8.943849, 42.466179],
              [-8.94389, 42.466096],
            ],
          ],
          [
            [
              [-8.860146, 42.291232],
              [-8.860208, 42.291237],
              [-8.860196, 42.291373],
              [-8.860059, 42.291335],
              [-8.860146, 42.291232],
            ],
          ],
          [
            [
              [-8.833517, 42.342492],
              [-8.833608, 42.342424],
              [-8.833634, 42.34245],
              [-8.833587, 42.342587],
              [-8.833517, 42.342492],
            ],
          ],
          [
            [
              [-8.937912, 42.478442],
              [-8.938, 42.478473],
              [-8.937903, 42.478604],
              [-8.937884, 42.478533],
              [-8.937912, 42.478442],
            ],
          ],
          [
            [
              [-8.819149, 42.305092],
              [-8.819089, 42.305095],
              [-8.819042, 42.30498],
              [-8.819213, 42.304999],
              [-8.819149, 42.305092],
            ],
          ],
          [
            [
              [-8.826563, 42.581217],
              [-8.826609, 42.581188],
              [-8.826692, 42.581292],
              [-8.826521, 42.581291],
              [-8.826563, 42.581217],
            ],
          ],
          [
            [
              [-8.90901, 42.218564],
              [-8.909136, 42.218621],
              [-8.909107, 42.218694],
              [-8.90898, 42.218638],
              [-8.90901, 42.218564],
            ],
          ],
          [
            [
              [-8.83657, 42.291421],
              [-8.836706, 42.29145],
              [-8.836709, 42.291486],
              [-8.836504, 42.291496],
              [-8.83657, 42.291421],
            ],
          ],
          [
            [
              [-8.772782, 42.388848],
              [-8.772867, 42.388853],
              [-8.772831, 42.388981],
              [-8.77273, 42.388941],
              [-8.772782, 42.388848],
            ],
          ],
          [
            [
              [-8.836547, 42.291701],
              [-8.836645, 42.291705],
              [-8.836458, 42.291787],
              [-8.836478, 42.291732],
              [-8.836547, 42.291701],
            ],
          ],
          [
            [
              [-8.82295, 42.301081],
              [-8.82305, 42.301112],
              [-8.822974, 42.301206],
              [-8.822911, 42.301182],
              [-8.82295, 42.301081],
            ],
          ],
          [
            [
              [-8.797521, 42.336636],
              [-8.797478, 42.336701],
              [-8.797377, 42.336652],
              [-8.797405, 42.336561],
              [-8.797521, 42.336636],
            ],
          ],
          [
            [
              [-8.881451, 41.998377],
              [-8.881356, 41.998391],
              [-8.881288, 41.998304],
              [-8.88137, 41.998282],
              [-8.881451, 41.998377],
            ],
          ],
          [
            [
              [-8.939929, 42.467922],
              [-8.94001, 42.467873],
              [-8.940075, 42.467923],
              [-8.939961, 42.468001],
              [-8.939929, 42.467922],
            ],
          ],
          [
            [
              [-8.74821, 42.268064],
              [-8.748331, 42.268058],
              [-8.748351, 42.268147],
              [-8.74823, 42.268153],
              [-8.74821, 42.268064],
            ],
          ],
          [
            [
              [-8.860094, 42.290802],
              [-8.860185, 42.290869],
              [-8.860142, 42.290926],
              [-8.860054, 42.290894],
              [-8.860094, 42.290802],
            ],
          ],
          [
            [
              [-8.911102, 42.179568],
              [-8.911184, 42.179546],
              [-8.911276, 42.179622],
              [-8.911133, 42.179648],
              [-8.911102, 42.179568],
            ],
          ],
          [
            [
              [-8.803563, 42.609718],
              [-8.803686, 42.60973],
              [-8.803702, 42.609774],
              [-8.80356, 42.609817],
              [-8.803563, 42.609718],
            ],
          ],
          [
            [
              [-8.859776, 42.147706],
              [-8.859634, 42.14774],
              [-8.859604, 42.14767],
              [-8.859722, 42.147637],
              [-8.859776, 42.147706],
            ],
          ],
          [
            [
              [-8.860129, 42.295868],
              [-8.860249, 42.295853],
              [-8.860292, 42.295923],
              [-8.860185, 42.295947],
              [-8.860129, 42.295868],
            ],
          ],
          [
            [
              [-8.911015, 42.178375],
              [-8.910925, 42.178452],
              [-8.910862, 42.178428],
              [-8.910913, 42.178327],
              [-8.911015, 42.178375],
            ],
          ],
          [
            [
              [-8.829701, 42.322522],
              [-8.829823, 42.322534],
              [-8.829825, 42.322561],
              [-8.829673, 42.322613],
              [-8.829701, 42.322522],
            ],
          ],
          [
            [
              [-8.829308, 42.340807],
              [-8.829344, 42.340805],
              [-8.829447, 42.340863],
              [-8.829241, 42.340873],
              [-8.829308, 42.340807],
            ],
          ],
          [
            [
              [-8.810549, 42.191654],
              [-8.810511, 42.191638],
              [-8.810483, 42.19146],
              [-8.810575, 42.191545],
              [-8.810549, 42.191654],
            ],
          ],
          [
            [
              [-8.839113, 42.158827],
              [-8.839003, 42.158815],
              [-8.838985, 42.158753],
              [-8.839079, 42.158721],
              [-8.839113, 42.158827],
            ],
          ],
          [
            [
              [-8.911468, 42.179081],
              [-8.911376, 42.179131],
              [-8.911299, 42.17909],
              [-8.911413, 42.179012],
              [-8.911468, 42.179081],
            ],
          ],
          [
            [
              [-8.939725, 42.467564],
              [-8.939795, 42.467533],
              [-8.939853, 42.467629],
              [-8.93972, 42.467636],
              [-8.939725, 42.467564],
            ],
          ],
          [
            [
              [-8.803249, 42.610013],
              [-8.803137, 42.609992],
              [-8.803153, 42.609901],
              [-8.803215, 42.609907],
              [-8.803249, 42.610013],
            ],
          ],
          [
            [
              [-8.772512, 42.388952],
              [-8.772598, 42.388957],
              [-8.772536, 42.389077],
              [-8.772485, 42.389053],
              [-8.772512, 42.388952],
            ],
          ],
          [
            [
              [-8.825655, 42.342371],
              [-8.825776, 42.342364],
              [-8.825803, 42.342399],
              [-8.825637, 42.342435],
              [-8.825655, 42.342371],
            ],
          ],
          [
            [
              [-8.858897, 42.293699],
              [-8.858818, 42.293631],
              [-8.858909, 42.293572],
              [-8.85894, 42.293643],
              [-8.858897, 42.293699],
            ],
          ],
          [
            [
              [-8.836889, 42.291458],
              [-8.836944, 42.291537],
              [-8.836836, 42.291542],
              [-8.836818, 42.29148],
              [-8.836889, 42.291458],
            ],
          ],
          [
            [
              [-8.838616, 42.29309],
              [-8.838482, 42.293087],
              [-8.838466, 42.293043],
              [-8.838586, 42.293028],
              [-8.838616, 42.29309],
            ],
          ],
          [
            [
              [-8.823672, 42.578952],
              [-8.823761, 42.578993],
              [-8.823682, 42.57906],
              [-8.823644, 42.579044],
              [-8.823672, 42.578952],
            ],
          ],
          [
            [
              [-8.819219, 42.3048],
              [-8.819164, 42.304731],
              [-8.819271, 42.304708],
              [-8.819296, 42.304715],
              [-8.819219, 42.3048],
            ],
          ],
          [
            [
              [-8.810313, 42.191459],
              [-8.810386, 42.191465],
              [-8.810359, 42.191565],
              [-8.810308, 42.191532],
              [-8.810313, 42.191459],
            ],
          ],
          [
            [
              [-8.836822, 42.29166],
              [-8.836871, 42.291657],
              [-8.83689, 42.291737],
              [-8.836806, 42.291742],
              [-8.836822, 42.29166],
            ],
          ],
          [
            [
              [-8.772042, 42.388985],
              [-8.77215, 42.388979],
              [-8.772156, 42.389042],
              [-8.772032, 42.389012],
              [-8.772042, 42.388985],
            ],
          ],
          [
            [
              [-8.772342, 42.388952],
              [-8.772403, 42.388949],
              [-8.772388, 42.389048],
              [-8.77232, 42.388971],
              [-8.772342, 42.388952],
            ],
          ],
          [
            [
              [-8.830347, 42.344688],
              [-8.830303, 42.344735],
              [-8.830254, 42.344729],
              [-8.830284, 42.344664],
              [-8.830347, 42.344688],
            ],
          ],
          [
            [
              [-8.877304, 41.873609],
              [-8.877316, 41.873608],
              [-8.877281, 41.873619],
              [-8.877304, 41.873609],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5641",
      properties: {
        name: "Salamanca",
        density: 0,
        path: "/world/Spain/Salamanca",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.475919, 41.287337],
              [-6.317682, 41.270894],
              [-6.134499, 41.190387],
              [-6.10931, 41.212613],
              [-6.06201, 41.221051],
              [-6.008835, 41.200498],
              [-6.027501, 41.179123],
              [-6.021826, 41.155262],
              [-5.979218, 41.174574],
              [-5.953245, 41.1401],
              [-5.913134, 41.178337],
              [-5.92133, 41.218153],
              [-5.897674, 41.238592],
              [-5.767308, 41.215701],
              [-5.656957, 41.245548],
              [-5.628325, 41.214457],
              [-5.58793, 41.20244],
              [-5.565067, 41.217038],
              [-5.485255, 41.178854],
              [-5.48434, 41.145503],
              [-5.46983, 41.157948],
              [-5.476878, 41.178323],
              [-5.407594, 41.185593],
              [-5.400191, 41.145433],
              [-5.356987, 41.140822],
              [-5.32895, 41.117173],
              [-5.297858, 41.125877],
              [-5.288467, 41.195616],
              [-5.252968, 41.199775],
              [-5.229602, 41.190529],
              [-5.21822, 41.159585],
              [-5.179658, 41.167293],
              [-5.124929, 41.132045],
              [-5.152554, 41.061959],
              [-5.140792, 41.037246],
              [-5.106605, 41.029499],
              [-5.089841, 40.996629],
              [-5.104841, 40.960254],
              [-5.127601, 40.954116],
              [-5.115045, 40.902806],
              [-5.163809, 40.868384],
              [-5.157451, 40.821285],
              [-5.218992, 40.772972],
              [-5.214437, 40.757827],
              [-5.377699, 40.677833],
              [-5.385807, 40.663344],
              [-5.365892, 40.648774],
              [-5.382954, 40.62076],
              [-5.44765, 40.579593],
              [-5.499658, 40.581587],
              [-5.50086, 40.597351],
              [-5.516222, 40.585379],
              [-5.524692, 40.540733],
              [-5.428508, 40.564468],
              [-5.467128, 40.464007],
              [-5.509831, 40.463664],
              [-5.51147, 40.426292],
              [-5.525927, 40.422364],
              [-5.574602, 40.444251],
              [-5.560924, 40.477099],
              [-5.634173, 40.460446],
              [-5.691408, 40.421574],
              [-5.67844, 40.387016],
              [-5.686359, 40.355369],
              [-5.673031, 40.348535],
              [-5.757909, 40.282452],
              [-5.802243, 40.296546],
              [-5.781992, 40.312456],
              [-5.799337, 40.353751],
              [-5.885048, 40.326134],
              [-5.917174, 40.277697],
              [-5.992145, 40.299011],
              [-6.016428, 40.316521],
              [-6.016017, 40.340292],
              [-6.105716, 40.356795],
              [-6.082378, 40.363212],
              [-6.072156, 40.387001],
              [-6.085166, 40.390492],
              [-6.064959, 40.396605],
              [-6.129484, 40.420788],
              [-6.116127, 40.441585],
              [-6.147017, 40.436118],
              [-6.199096, 40.481837],
              [-6.23464, 40.486611],
              [-6.275326, 40.458516],
              [-6.344643, 40.442776],
              [-6.371396, 40.401874],
              [-6.418826, 40.39929],
              [-6.438315, 40.372773],
              [-6.536284, 40.347244],
              [-6.56041, 40.329049],
              [-6.556175, 40.290777],
              [-6.587022, 40.270721],
              [-6.671753, 40.263232],
              [-6.690137, 40.242662],
              [-6.718225, 40.269239],
              [-6.755603, 40.238923],
              [-6.834522, 40.24758],
              [-6.865089, 40.270634],
              [-6.862937, 40.297435],
              [-6.794182, 40.328793],
              [-6.780997, 40.364672],
              [-6.834799, 40.408152],
              [-6.849694, 40.452576],
              [-6.795028, 40.511915],
              [-6.80083, 40.550628],
              [-6.84482, 40.565876],
              [-6.793894, 40.66168],
              [-6.831198, 40.752637],
              [-6.816975, 40.801171],
              [-6.826117, 40.843239],
              [-6.800304, 40.848273],
              [-6.808754, 40.882044],
              [-6.84848, 40.900832],
              [-6.860172, 40.9489],
              [-6.921567, 41.003334],
              [-6.930088, 41.030068],
              [-6.80836, 41.036476],
              [-6.753989, 41.102779],
              [-6.767418, 41.13521],
              [-6.701317, 41.180394],
              [-6.691946, 41.208324],
              [-6.647766, 41.247566],
              [-6.599115, 41.244237],
              [-6.591391, 41.255456],
              [-6.571258, 41.239027],
              [-6.521825, 41.275998],
              [-6.497037, 41.265605],
              [-6.478688, 41.274961],
              [-6.489999, 41.288739],
              [-6.475919, 41.287337],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5607",
      properties: {
        name: "Santa Cruz De Tenerife",
        density: 0,
        path: "/world/Spain/Santa Cruz De Tenerife",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-16.15754, 28.58816],
              [-16.126692, 28.570725],
              [-16.126705, 28.563619],
              [-16.11947, 28.553604],
              [-16.125276, 28.549746],
              [-16.127674, 28.54641],
              [-16.125701, 28.532101],
              [-16.237163, 28.485392],
              [-16.246273, 28.471115],
              [-16.235665, 28.479048],
              [-16.247079, 28.455677],
              [-16.24279, 28.468053],
              [-16.360361, 28.379536],
              [-16.361465, 28.30435],
              [-16.4247, 28.204459],
              [-16.425154, 28.150088],
              [-16.546958, 28.024809],
              [-16.59524, 28.029099],
              [-16.644334, 28.003524],
              [-16.702342, 28.004068],
              [-16.711621, 28.046841],
              [-16.737753, 28.055016],
              [-16.737617, 28.086727],
              [-16.777037, 28.117131],
              [-16.807001, 28.174492],
              [-16.837586, 28.207593],
              [-16.841197, 28.26437],
              [-16.896642, 28.33215],
              [-16.925966, 28.341921],
              [-16.918154, 28.360739],
              [-16.832023, 28.393275],
              [-16.808291, 28.376165],
              [-16.751847, 28.370678],
              [-16.674352, 28.40202],
              [-16.603967, 28.394229],
              [-16.554852, 28.418877],
              [-16.533307, 28.416685],
              [-16.514284, 28.418995],
              [-16.455995, 28.46008],
              [-16.42871, 28.489357],
              [-16.410969, 28.527148],
              [-16.334221, 28.55768],
              [-16.329072, 28.576794],
              [-16.223647, 28.565411],
              [-16.193046, 28.572019],
              [-16.15754, 28.58816],
            ],
            [
              [-16.82936, 28.200139],
              [-16.829854, 28.200072],
              [-16.830003, 28.199735],
              [-16.829198, 28.199814],
              [-16.82936, 28.200139],
            ],
            [
              [-16.648368, 28.39658],
              [-16.648247, 28.396997],
              [-16.64876, 28.397217],
              [-16.648854, 28.396929],
              [-16.648368, 28.39658],
            ],
            [
              [-16.376065, 28.291082],
              [-16.375382, 28.291956],
              [-16.375426, 28.292121],
              [-16.376175, 28.291166],
              [-16.376065, 28.291082],
            ],
            [
              [-16.469099, 28.108141],
              [-16.469118, 28.108904],
              [-16.469223, 28.109029],
              [-16.469506, 28.1085],
              [-16.469099, 28.108141],
            ],
            [
              [-16.377079, 28.289598],
              [-16.37665, 28.290169],
              [-16.376559, 28.290502],
              [-16.377286, 28.289484],
              [-16.377079, 28.289598],
            ],
            [
              [-16.573651, 28.404404],
              [-16.573834, 28.404672],
              [-16.574084, 28.40445],
              [-16.573888, 28.404251],
              [-16.573651, 28.404404],
            ],
            [
              [-16.713475, 28.384802],
              [-16.71343, 28.385136],
              [-16.71364, 28.385256],
              [-16.713653, 28.384823],
              [-16.713475, 28.384802],
            ],
            [
              [-16.649013, 28.396425],
              [-16.648897, 28.396538],
              [-16.649186, 28.396843],
              [-16.649317, 28.396635],
              [-16.649013, 28.396425],
            ],
            [
              [-16.378055, 28.288232],
              [-16.377672, 28.288715],
              [-16.377704, 28.288833],
              [-16.378163, 28.288305],
              [-16.378055, 28.288232],
            ],
            [
              [-16.292761, 28.572341],
              [-16.292794, 28.572607],
              [-16.293011, 28.572661],
              [-16.293072, 28.572375],
              [-16.292761, 28.572341],
            ],
            [
              [-16.730731, 28.377884],
              [-16.73057, 28.377954],
              [-16.730554, 28.378265],
              [-16.730786, 28.378101],
              [-16.730731, 28.377884],
            ],
            [
              [-16.655684, 28.005097],
              [-16.655816, 28.005213],
              [-16.656123, 28.004942],
              [-16.655951, 28.004909],
              [-16.655684, 28.005097],
            ],
            [
              [-16.855728, 28.381147],
              [-16.855797, 28.381409],
              [-16.856065, 28.381481],
              [-16.855834, 28.381141],
              [-16.855728, 28.381147],
            ],
            [
              [-16.574643, 28.40429],
              [-16.574604, 28.404568],
              [-16.574724, 28.404574],
              [-16.574875, 28.404304],
              [-16.574643, 28.40429],
            ],
            [
              [-16.63424, 28.396552],
              [-16.634474, 28.396567],
              [-16.634135, 28.396275],
              [-16.634128, 28.396359],
              [-16.63424, 28.396552],
            ],
            [
              [-16.591757, 28.398669],
              [-16.591752, 28.39877],
              [-16.59187, 28.399138],
              [-16.591925, 28.398699],
              [-16.591757, 28.398669],
            ],
            [
              [-16.884859, 28.369157],
              [-16.885096, 28.369088],
              [-16.884605, 28.36907],
              [-16.884637, 28.369167],
              [-16.884859, 28.369157],
            ],
            [
              [-16.880423, 28.36886],
              [-16.880355, 28.369078],
              [-16.880464, 28.369249],
              [-16.880532, 28.368863],
              [-16.880423, 28.36886],
            ],
            [
              [-16.857766, 28.380203],
              [-16.857758, 28.380468],
              [-16.857814, 28.380557],
              [-16.857982, 28.380412],
              [-16.857766, 28.380203],
            ],
            [
              [-16.677879, 28.398599],
              [-16.677836, 28.398307],
              [-16.677708, 28.398267],
              [-16.677689, 28.39847],
              [-16.677879, 28.398599],
            ],
            [
              [-16.47294, 28.447539],
              [-16.472977, 28.447714],
              [-16.473191, 28.447678],
              [-16.473162, 28.447523],
              [-16.47294, 28.447539],
            ],
            [
              [-16.12816, 28.538176],
              [-16.128038, 28.538351],
              [-16.128067, 28.538416],
              [-16.128361, 28.538096],
              [-16.12816, 28.538176],
            ],
            [
              [-16.623341, 28.017788],
              [-16.623305, 28.017948],
              [-16.623562, 28.018083],
              [-16.623432, 28.017819],
              [-16.623341, 28.017788],
            ],
            [
              [-16.586709, 28.029137],
              [-16.586688, 28.029276],
              [-16.586748, 28.029373],
              [-16.586692, 28.028964],
              [-16.586709, 28.029137],
            ],
            [
              [-16.445882, 28.134281],
              [-16.445865, 28.134541],
              [-16.445954, 28.134208],
              [-16.445866, 28.134096],
              [-16.445882, 28.134281],
            ],
            [
              [-16.468833, 28.10918],
              [-16.468815, 28.109225],
              [-16.469137, 28.109192],
              [-16.468938, 28.109009],
              [-16.468833, 28.10918],
            ],
            [
              [-16.632228, 28.396968],
              [-16.632272, 28.397137],
              [-16.632428, 28.397091],
              [-16.632287, 28.396885],
              [-16.632228, 28.396968],
            ],
            [
              [-16.623692, 28.01805],
              [-16.623695, 28.018144],
              [-16.623849, 28.018251],
              [-16.62386, 28.018058],
              [-16.623692, 28.01805],
            ],
            [
              [-16.884036, 28.369905],
              [-16.884014, 28.369756],
              [-16.883861, 28.369794],
              [-16.883917, 28.369991],
              [-16.884036, 28.369905],
            ],
            [
              [-16.462479, 28.113435],
              [-16.462654, 28.1134],
              [-16.462425, 28.113205],
              [-16.462386, 28.113331],
              [-16.462479, 28.113435],
            ],
            [
              [-16.638202, 28.395577],
              [-16.638302, 28.395766],
              [-16.638389, 28.395704],
              [-16.638373, 28.395599],
              [-16.638202, 28.395577],
            ],
            [
              [-16.622297, 28.018143],
              [-16.622354, 28.018294],
              [-16.622524, 28.018284],
              [-16.622404, 28.018161],
              [-16.622297, 28.018143],
            ],
            [
              [-16.442522, 28.137015],
              [-16.442608, 28.136953],
              [-16.442391, 28.1369],
              [-16.442417, 28.137116],
              [-16.442522, 28.137015],
            ],
            [
              [-16.431948, 28.160111],
              [-16.43208, 28.160026],
              [-16.43202, 28.159966],
              [-16.431859, 28.159981],
              [-16.431948, 28.160111],
            ],
            [
              [-16.429505, 28.164207],
              [-16.429628, 28.164149],
              [-16.429252, 28.164172],
              [-16.429291, 28.164221],
              [-16.429505, 28.164207],
            ],
            [
              [-16.293899, 28.571558],
              [-16.29396, 28.5717],
              [-16.294086, 28.571664],
              [-16.294029, 28.571564],
              [-16.293899, 28.571558],
            ],
            [
              [-16.428101, 28.153168],
              [-16.428001, 28.153228],
              [-16.428056, 28.153309],
              [-16.42823, 28.153151],
              [-16.428101, 28.153168],
            ],
            [
              [-16.472128, 28.105964],
              [-16.472058, 28.106042],
              [-16.472113, 28.106117],
              [-16.472251, 28.105911],
              [-16.472128, 28.105964],
            ],
            [
              [-16.472025, 28.106121],
              [-16.472017, 28.105921],
              [-16.471917, 28.105919],
              [-16.471936, 28.106074],
              [-16.472025, 28.106121],
            ],
            [
              [-16.540463, 28.03492],
              [-16.540545, 28.035017],
              [-16.540621, 28.034867],
              [-16.540463, 28.03492],
            ],
            [
              [-16.128092, 28.537735],
              [-16.12799, 28.537776],
              [-16.128239, 28.537751],
              [-16.128116, 28.53768],
              [-16.128092, 28.537735],
            ],
            [
              [-16.629582, 28.013793],
              [-16.629576, 28.013662],
              [-16.629453, 28.013737],
              [-16.629531, 28.013799],
              [-16.629582, 28.013793],
            ],
            [
              [-16.541314, 28.035371],
              [-16.541278, 28.035336],
              [-16.541086, 28.035447],
              [-16.541253, 28.03547],
              [-16.541314, 28.035371],
            ],
            [
              [-16.540345, 28.035119],
              [-16.540283, 28.035193],
              [-16.540397, 28.035239],
              [-16.540449, 28.035106],
              [-16.540345, 28.035119],
            ],
            [
              [-16.580759, 28.02885],
              [-16.580745, 28.028938],
              [-16.580936, 28.028877],
              [-16.580848, 28.028837],
              [-16.580759, 28.02885],
            ],
            [
              [-16.461594, 28.119908],
              [-16.461652, 28.120011],
              [-16.461729, 28.119944],
              [-16.461701, 28.119903],
              [-16.461594, 28.119908],
            ],
            [
              [-16.128962, 28.537121],
              [-16.128903, 28.537207],
              [-16.128939, 28.53724],
              [-16.12904, 28.53718],
              [-16.128962, 28.537121],
            ],
            [
              [-16.661191, 28.008511],
              [-16.661147, 28.008482],
              [-16.661004, 28.008565],
              [-16.661132, 28.008585],
              [-16.661191, 28.008511],
            ],
            [
              [-16.586665, 28.028679],
              [-16.586649, 28.028729],
              [-16.586736, 28.028783],
              [-16.586764, 28.028671],
              [-16.586665, 28.028679],
            ],
            [
              [-16.128303, 28.537505],
              [-16.128254, 28.537537],
              [-16.128199, 28.53756],
              [-16.12841, 28.537504],
              [-16.128303, 28.537505],
            ],
            [
              [-16.127692, 28.536045],
              [-16.127566, 28.535999],
              [-16.127459, 28.535996],
              [-16.127675, 28.536079],
              [-16.127692, 28.536045],
            ],
            [
              [-16.882747, 28.371171],
              [-16.88261, 28.371146],
              [-16.882595, 28.371191],
              [-16.882631, 28.37123],
              [-16.882747, 28.371171],
            ],
            [
              [-16.577919, 28.029052],
              [-16.577891, 28.02898],
              [-16.577796, 28.029005],
              [-16.577815, 28.029047],
              [-16.577919, 28.029052],
            ],
            [
              [-16.119993, 28.554033],
              [-16.120035, 28.554115],
              [-16.120094, 28.554048],
              [-16.120039, 28.554011],
              [-16.119993, 28.554033],
            ],
            [
              [-16.1271, 28.54061],
              [-16.127085, 28.540654],
              [-16.127173, 28.540687],
              [-16.127137, 28.540576],
              [-16.1271, 28.54061],
            ],
            [
              [-16.127742, 28.535708],
              [-16.12773, 28.535759],
              [-16.12786, 28.535726],
              [-16.127795, 28.535703],
              [-16.127742, 28.535708],
            ],
            [
              [-16.651648, 28.007476],
              [-16.65154, 28.007462],
              [-16.651591, 28.007532],
              [-16.651648, 28.007476],
            ],
            [
              [-16.461011, 28.121148],
              [-16.460936, 28.121099],
              [-16.460884, 28.121113],
              [-16.460915, 28.121156],
              [-16.461011, 28.121148],
            ],
            [
              [-16.663847, 28.007915],
              [-16.663801, 28.007879],
              [-16.663781, 28.007972],
              [-16.663844, 28.00796],
              [-16.663847, 28.007915],
            ],
            [
              [-16.882763, 28.371056],
              [-16.882696, 28.370982],
              [-16.882671, 28.371038],
              [-16.882763, 28.371056],
            ],
            [
              [-16.625635, 28.018278],
              [-16.625599, 28.018318],
              [-16.625685, 28.018328],
              [-16.625686, 28.018271],
              [-16.625635, 28.018278],
            ],
          ],
          [
            [
              [-17.91069, 28.857631],
              [-17.85808, 28.832655],
              [-17.814221, 28.838894],
              [-17.809191, 28.845242],
              [-17.803233, 28.841577],
              [-17.788754, 28.843907],
              [-17.777551, 28.840159],
              [-17.724637, 28.743086],
              [-17.77006, 28.677059],
              [-17.749932, 28.624305],
              [-17.760163, 28.569899],
              [-17.840558, 28.452803],
              [-17.868713, 28.473378],
              [-17.880032, 28.540134],
              [-17.925901, 28.59621],
              [-18.007201, 28.757705],
              [-18.005743, 28.784438],
              [-17.967754, 28.827326],
              [-17.946212, 28.84258],
              [-17.943046, 28.840521],
              [-17.939141, 28.840876],
              [-17.926783, 28.846214],
              [-17.920164, 28.85729],
              [-17.91069, 28.857631],
            ],
            [
              [-17.962008, 28.82995],
              [-17.962571, 28.830303],
              [-17.960896, 28.828644],
              [-17.961094, 28.829829],
              [-17.962008, 28.82995],
            ],
            [
              [-17.958744, 28.831966],
              [-17.958292, 28.830682],
              [-17.958093, 28.830506],
              [-17.958119, 28.831431],
              [-17.958744, 28.831966],
            ],
            [
              [-17.732357, 28.752668],
              [-17.731968, 28.753098],
              [-17.731983, 28.753273],
              [-17.732609, 28.752785],
              [-17.732357, 28.752668],
            ],
            [
              [-18.005523, 28.76008],
              [-18.005086, 28.760303],
              [-18.005694, 28.760544],
              [-18.005778, 28.760162],
              [-18.005523, 28.76008],
            ],
            [
              [-17.924979, 28.848579],
              [-17.925315, 28.848509],
              [-17.925324, 28.8482],
              [-17.924879, 28.848202],
              [-17.924979, 28.848579],
            ],
            [
              [-17.778245, 28.839695],
              [-17.778074, 28.840121],
              [-17.778152, 28.840337],
              [-17.77843, 28.839921],
              [-17.778245, 28.839695],
            ],
            [
              [-17.766875, 28.816029],
              [-17.766637, 28.8161],
              [-17.766715, 28.816305],
              [-17.767064, 28.816038],
              [-17.766875, 28.816029],
            ],
            [
              [-17.817949, 28.489395],
              [-17.817902, 28.489669],
              [-17.818354, 28.489455],
              [-17.818162, 28.489382],
              [-17.817949, 28.489395],
            ],
            [
              [-17.86986, 28.482291],
              [-17.870009, 28.482134],
              [-17.869669, 28.481937],
              [-17.869637, 28.482091],
              [-17.86986, 28.482291],
            ],
            [
              [-17.964924, 28.67797],
              [-17.964646, 28.677628],
              [-17.964434, 28.677677],
              [-17.964839, 28.67804],
              [-17.964924, 28.67797],
            ],
            [
              [-17.822415, 28.481297],
              [-17.821945, 28.481132],
              [-17.821814, 28.48131],
              [-17.822, 28.481399],
              [-17.822415, 28.481297],
            ],
            [
              [-17.878876, 28.838823],
              [-17.878982, 28.838701],
              [-17.878886, 28.838315],
              [-17.878858, 28.838353],
              [-17.878876, 28.838823],
            ],
            [
              [-17.729251, 28.744053],
              [-17.729109, 28.744185],
              [-17.729137, 28.744272],
              [-17.72935, 28.744181],
              [-17.729251, 28.744053],
            ],
            [
              [-17.897385, 28.844107],
              [-17.897261, 28.844285],
              [-17.897294, 28.844356],
              [-17.897426, 28.844275],
              [-17.897385, 28.844107],
            ],
            [
              [-17.793134, 28.526141],
              [-17.793015, 28.526001],
              [-17.792896, 28.526082],
              [-17.79299, 28.526185],
              [-17.793134, 28.526141],
            ],
            [
              [-17.805217, 28.508691],
              [-17.805057, 28.508809],
              [-17.805297, 28.508802],
              [-17.805217, 28.508691],
            ],
            [
              [-17.868115, 28.477197],
              [-17.868089, 28.477115],
              [-17.867906, 28.477164],
              [-17.867982, 28.477241],
              [-17.868115, 28.477197],
            ],
            [
              [-17.962845, 28.673367],
              [-17.962732, 28.673293],
              [-17.962729, 28.673491],
              [-17.962813, 28.673475],
              [-17.962845, 28.673367],
            ],
            [
              [-17.994302, 28.741297],
              [-17.994245, 28.741223],
              [-17.994175, 28.741219],
              [-17.994197, 28.741364],
              [-17.994302, 28.741297],
            ],
            [
              [-17.88338, 28.839745],
              [-17.883333, 28.839862],
              [-17.8834, 28.839917],
              [-17.88344, 28.839804],
              [-17.88338, 28.839745],
            ],
            [
              [-17.875103, 28.83723],
              [-17.87506, 28.837317],
              [-17.875065, 28.837372],
              [-17.875194, 28.837239],
              [-17.875103, 28.83723],
            ],
            [
              [-17.776836, 28.836662],
              [-17.776887, 28.836658],
              [-17.776961, 28.836594],
              [-17.776751, 28.836606],
              [-17.776836, 28.836662],
            ],
            [
              [-17.814265, 28.497209],
              [-17.814012, 28.497203],
              [-17.813993, 28.497279],
              [-17.814042, 28.497293],
              [-17.814265, 28.497209],
            ],
            [
              [-17.801809, 28.512795],
              [-17.801734, 28.512928],
              [-17.802017, 28.512776],
              [-17.801809, 28.512795],
            ],
            [
              [-17.807194, 28.843721],
              [-17.807261, 28.843829],
              [-17.807353, 28.843727],
              [-17.807239, 28.843692],
              [-17.807194, 28.843721],
            ],
            [
              [-17.956472, 28.657915],
              [-17.956522, 28.657996],
              [-17.956637, 28.657912],
              [-17.956472, 28.657915],
            ],
            [
              [-17.822911, 28.47915],
              [-17.822878, 28.479096],
              [-17.822661, 28.47908],
              [-17.822694, 28.479126],
              [-17.822911, 28.47915],
            ],
            [
              [-17.821681, 28.482371],
              [-17.821464, 28.482396],
              [-17.821479, 28.48248],
              [-17.821681, 28.482371],
            ],
            [
              [-17.964505, 28.677539],
              [-17.964415, 28.677527],
              [-17.964307, 28.677571],
              [-17.964417, 28.677619],
              [-17.964505, 28.677539],
            ],
            [
              [-18.004946, 28.765838],
              [-18.004822, 28.765774],
              [-18.004927, 28.765914],
              [-18.004946, 28.765838],
            ],
            [
              [-17.876749, 28.838134],
              [-17.876754, 28.838225],
              [-17.876805, 28.838212],
              [-17.876841, 28.838122],
              [-17.876749, 28.838134],
            ],
            [
              [-17.9631, 28.675121],
              [-17.963015, 28.675024],
              [-17.962961, 28.675105],
              [-17.9631, 28.675121],
            ],
            [
              [-17.822616, 28.479806],
              [-17.822588, 28.47973],
              [-17.822475, 28.479763],
              [-17.822553, 28.479819],
              [-17.822616, 28.479806],
            ],
            [
              [-17.868462, 28.473666],
              [-17.868272, 28.473793],
              [-17.868363, 28.473791],
              [-17.868462, 28.473666],
            ],
            [
              [-17.814191, 28.49703],
              [-17.814029, 28.496998],
              [-17.813983, 28.497064],
              [-17.814191, 28.49703],
            ],
            [
              [-17.877486, 28.838477],
              [-17.877422, 28.838446],
              [-17.877374, 28.838472],
              [-17.877444, 28.838543],
              [-17.877486, 28.838477],
            ],
            [
              [-17.793151, 28.524946],
              [-17.793134, 28.524883],
              [-17.793074, 28.524865],
              [-17.793085, 28.524985],
              [-17.793151, 28.524946],
            ],
            [
              [-17.743625, 28.766067],
              [-17.743537, 28.766055],
              [-17.743583, 28.766144],
              [-17.743625, 28.766067],
            ],
            [
              [-17.964005, 28.676084],
              [-17.963982, 28.676138],
              [-17.964103, 28.676043],
              [-17.964005, 28.676084],
            ],
            [
              [-17.744566, 28.767847],
              [-17.744472, 28.767819],
              [-17.744426, 28.767861],
              [-17.744472, 28.767884],
              [-17.744566, 28.767847],
            ],
            [
              [-17.744999, 28.768359],
              [-17.744946, 28.768307],
              [-17.744906, 28.768364],
              [-17.744952, 28.768384],
              [-17.744999, 28.768359],
            ],
            [
              [-18.005483, 28.76592],
              [-18.005445, 28.765858],
              [-18.005385, 28.765908],
              [-18.005483, 28.76592],
            ],
            [
              [-17.995394, 28.743804],
              [-17.99537, 28.74382],
              [-17.995463, 28.743852],
              [-17.995422, 28.743797],
              [-17.995394, 28.743804],
            ],
            [
              [-17.87573, 28.837592],
              [-17.875714, 28.837494],
              [-17.875707, 28.837595],
              [-17.87573, 28.837592],
            ],
          ],
          [
            [
              [-17.260732, 28.217972],
              [-17.245074, 28.200821],
              [-17.206336, 28.196786],
              [-17.196077, 28.200052],
              [-17.183942, 28.180282],
              [-17.118995, 28.153546],
              [-17.103508, 28.128435],
              [-17.098461, 28.095604],
              [-17.1679, 28.035852],
              [-17.269668, 28.025003],
              [-17.348896, 28.098199],
              [-17.341242, 28.131177],
              [-17.346006, 28.152024],
              [-17.34308, 28.152452],
              [-17.33456, 28.162913],
              [-17.320366, 28.203353],
              [-17.293363, 28.207077],
              [-17.281898, 28.211313],
              [-17.276708, 28.217892],
              [-17.274569, 28.217021],
              [-17.275545, 28.215932],
              [-17.273691, 28.21446],
              [-17.260732, 28.217972],
            ],
            [
              [-17.156153, 28.048355],
              [-17.156319, 28.048733],
              [-17.156797, 28.04875],
              [-17.15667, 28.048546],
              [-17.156153, 28.048355],
            ],
            [
              [-17.319757, 28.072157],
              [-17.319574, 28.072339],
              [-17.319739, 28.072479],
              [-17.319857, 28.072246],
              [-17.319757, 28.072157],
            ],
            [
              [-17.348082, 28.1006],
              [-17.34811, 28.100504],
              [-17.34781, 28.100425],
              [-17.347824, 28.100621],
              [-17.348082, 28.1006],
            ],
            [
              [-17.260366, 28.215345],
              [-17.260436, 28.215367],
              [-17.260212, 28.214935],
              [-17.260123, 28.214994],
              [-17.260366, 28.215345],
            ],
            [
              [-17.344911, 28.150749],
              [-17.345129, 28.150748],
              [-17.345211, 28.150684],
              [-17.344762, 28.150698],
              [-17.344911, 28.150749],
            ],
            [
              [-17.259674, 28.214668],
              [-17.259832, 28.214535],
              [-17.259466, 28.214544],
              [-17.259567, 28.214664],
              [-17.259674, 28.214668],
            ],
            [
              [-17.319572, 28.071491],
              [-17.31953, 28.071633],
              [-17.319617, 28.071683],
              [-17.319699, 28.071555],
              [-17.319572, 28.071491],
            ],
            [
              [-17.344988, 28.09478],
              [-17.34496, 28.094863],
              [-17.345026, 28.094993],
              [-17.345084, 28.0948],
              [-17.344988, 28.09478],
            ],
            [
              [-17.25774, 28.212636],
              [-17.257694, 28.212729],
              [-17.257833, 28.212766],
              [-17.25784, 28.21264],
              [-17.25774, 28.212636],
            ],
            [
              [-17.322324, 28.194613],
              [-17.32233, 28.194725],
              [-17.32246, 28.194705],
              [-17.322445, 28.194625],
              [-17.322324, 28.194613],
            ],
            [
              [-17.195453, 28.198156],
              [-17.195279, 28.198084],
              [-17.195245, 28.198171],
              [-17.195354, 28.198206],
              [-17.195453, 28.198156],
            ],
            [
              [-17.252764, 28.204921],
              [-17.252728, 28.205016],
              [-17.252881, 28.204981],
              [-17.252824, 28.204917],
              [-17.252764, 28.204921],
            ],
            [
              [-17.196215, 28.197868],
              [-17.196249, 28.19778],
              [-17.196092, 28.197761],
              [-17.196166, 28.197846],
              [-17.196215, 28.197868],
            ],
            [
              [-17.258599, 28.21343],
              [-17.258534, 28.213497],
              [-17.258678, 28.213494],
              [-17.258694, 28.21345],
              [-17.258599, 28.21343],
            ],
            [
              [-17.253506, 28.209861],
              [-17.253492, 28.209905],
              [-17.253659, 28.209866],
              [-17.253576, 28.209799],
              [-17.253506, 28.209861],
            ],
            [
              [-17.253008, 28.205088],
              [-17.252891, 28.205025],
              [-17.252869, 28.205086],
              [-17.252905, 28.205119],
              [-17.253008, 28.205088],
            ],
            [
              [-17.253843, 28.207464],
              [-17.25386, 28.207389],
              [-17.253739, 28.207433],
              [-17.253741, 28.207483],
              [-17.253843, 28.207464],
            ],
            [
              [-17.260031, 28.214451],
              [-17.260022, 28.21436],
              [-17.259929, 28.214394],
              [-17.259977, 28.214452],
              [-17.260031, 28.214451],
            ],
            [
              [-17.253725, 28.207286],
              [-17.253724, 28.207369],
              [-17.253841, 28.207295],
              [-17.253777, 28.207277],
              [-17.253725, 28.207286],
            ],
            [
              [-17.257188, 28.211917],
              [-17.25713, 28.211852],
              [-17.257089, 28.211897],
              [-17.257094, 28.211943],
              [-17.257188, 28.211917],
            ],
            [
              [-17.257761, 28.212294],
              [-17.257654, 28.212367],
              [-17.257753, 28.212379],
              [-17.257761, 28.212294],
            ],
            [
              [-17.252918, 28.206179],
              [-17.252852, 28.20613],
              [-17.252838, 28.206219],
              [-17.252918, 28.206179],
            ],
            [
              [-17.253475, 28.209756],
              [-17.253391, 28.20974],
              [-17.253393, 28.20979],
              [-17.253475, 28.209756],
            ],
            [
              [-17.258091, 28.212191],
              [-17.258069, 28.21224],
              [-17.258165, 28.212187],
              [-17.258091, 28.212191],
            ],
            [
              [-17.329428, 28.080595],
              [-17.329318, 28.080537],
              [-17.329405, 28.080637],
              [-17.329428, 28.080595],
            ],
            [
              [-17.344954, 28.150395],
              [-17.344951, 28.150349],
              [-17.344905, 28.150329],
              [-17.344927, 28.15042],
              [-17.344954, 28.150395],
            ],
            [
              [-17.260538, 28.216955],
              [-17.260479, 28.216963],
              [-17.260526, 28.217017],
              [-17.260538, 28.216955],
            ],
            [
              [-17.260871, 28.216777],
              [-17.26082, 28.216779],
              [-17.260827, 28.216826],
              [-17.260871, 28.216777],
            ],
            [
              [-17.259951, 28.214519],
              [-17.259925, 28.214552],
              [-17.259947, 28.214599],
              [-17.259951, 28.214519],
            ],
            [
              [-17.252981, 28.206028],
              [-17.252939, 28.206043],
              [-17.252978, 28.206073],
              [-17.252981, 28.206028],
            ],
          ],
          [
            [
              [-17.925162, 27.849511],
              [-17.901798, 27.842011],
              [-17.883512, 27.813291],
              [-17.883523, 27.80315],
              [-17.92697, 27.732327],
              [-17.957033, 27.719993],
              [-17.97786, 27.639545],
              [-18.009888, 27.649774],
              [-18.050443, 27.692553],
              [-18.160286, 27.715061],
              [-18.151644, 27.760571],
              [-18.140832, 27.762654],
              [-18.140576, 27.766015],
              [-18.136578, 27.766817],
              [-18.132446, 27.770265],
              [-18.132315, 27.77128],
              [-18.082478, 27.753203],
              [-18.036755, 27.762027],
              [-17.989131, 27.799756],
              [-17.993454, 27.822787],
              [-17.925162, 27.849511],
            ],
            [
              [-18.028633, 27.672197],
              [-18.029078, 27.672168],
              [-18.029139, 27.672069],
              [-18.028372, 27.672002],
              [-18.028633, 27.672197],
            ],
            [
              [-18.072916, 27.753132],
              [-18.072775, 27.753456],
              [-18.073173, 27.75378],
              [-18.073165, 27.753407],
              [-18.072916, 27.753132],
            ],
            [
              [-17.899535, 27.836859],
              [-17.899225, 27.837032],
              [-17.899282, 27.837226],
              [-17.8995, 27.837183],
              [-17.899535, 27.836859],
            ],
            [
              [-17.908868, 27.842946],
              [-17.908612, 27.843304],
              [-17.90868, 27.843436],
              [-17.908952, 27.843036],
              [-17.908868, 27.842946],
            ],
            [
              [-17.914896, 27.844216],
              [-17.914568, 27.84464],
              [-17.914678, 27.844681],
              [-17.915002, 27.844363],
              [-17.914896, 27.844216],
            ],
            [
              [-17.915121, 27.844507],
              [-17.914988, 27.844765],
              [-17.915009, 27.844981],
              [-17.915204, 27.844727],
              [-17.915121, 27.844507],
            ],
            [
              [-17.993376, 27.795018],
              [-17.993439, 27.795233],
              [-17.993693, 27.795253],
              [-17.993489, 27.795037],
              [-17.993376, 27.795018],
            ],
            [
              [-17.970941, 27.82989],
              [-17.97096, 27.830155],
              [-17.97104, 27.830214],
              [-17.971174, 27.83002],
              [-17.970941, 27.82989],
            ],
            [
              [-18.023379, 27.668426],
              [-18.023121, 27.668548],
              [-18.023058, 27.668684],
              [-18.023209, 27.668699],
              [-18.023379, 27.668426],
            ],
            [
              [-17.916291, 27.845955],
              [-17.91615, 27.846136],
              [-17.916148, 27.846217],
              [-17.916324, 27.846192],
              [-17.916291, 27.845955],
            ],
            [
              [-18.09732, 27.755993],
              [-18.09724, 27.756205],
              [-18.097395, 27.756256],
              [-18.097445, 27.756177],
              [-18.09732, 27.755993],
            ],
            [
              [-17.971123, 27.669132],
              [-17.971208, 27.669445],
              [-17.971311, 27.669398],
              [-17.971234, 27.669156],
              [-17.971123, 27.669132],
            ],
            [
              [-18.065249, 27.754704],
              [-18.065141, 27.754837],
              [-18.065309, 27.754935],
              [-18.065353, 27.754697],
              [-18.065249, 27.754704],
            ],
            [
              [-18.066464, 27.754747],
              [-18.066341, 27.754871],
              [-18.066439, 27.754965],
              [-18.066591, 27.754884],
              [-18.066464, 27.754747],
            ],
            [
              [-17.970778, 27.669],
              [-17.970756, 27.669098],
              [-17.970974, 27.66925],
              [-17.97092, 27.669026],
              [-17.970778, 27.669],
            ],
            [
              [-18.061411, 27.755979],
              [-18.061337, 27.756177],
              [-18.061376, 27.756261],
              [-18.061512, 27.755971],
              [-18.061411, 27.755979],
            ],
            [
              [-18.074996, 27.753255],
              [-18.074993, 27.753503],
              [-18.075091, 27.75351],
              [-18.07511, 27.753337],
              [-18.074996, 27.753255],
            ],
            [
              [-18.095692, 27.756078],
              [-18.095619, 27.7562],
              [-18.095687, 27.7563],
              [-18.095798, 27.756134],
              [-18.095692, 27.756078],
            ],
            [
              [-17.887538, 27.818803],
              [-17.887459, 27.818866],
              [-17.887598, 27.818972],
              [-17.887677, 27.818851],
              [-17.887538, 27.818803],
            ],
            [
              [-17.973283, 27.827349],
              [-17.973232, 27.827428],
              [-17.973332, 27.827498],
              [-17.973393, 27.827371],
              [-17.973283, 27.827349],
            ],
            [
              [-18.023963, 27.668445],
              [-18.023941, 27.668498],
              [-18.024076, 27.668573],
              [-18.024108, 27.668472],
              [-18.023963, 27.668445],
            ],
            [
              [-18.157423, 27.72907],
              [-18.157463, 27.729174],
              [-18.157567, 27.729086],
              [-18.157545, 27.729064],
              [-18.157423, 27.72907],
            ],
            [
              [-17.974494, 27.827895],
              [-17.974522, 27.827979],
              [-17.974632, 27.827972],
              [-17.974612, 27.827885],
              [-17.974494, 27.827895],
            ],
            [
              [-17.973199, 27.827574],
              [-17.973157, 27.827656],
              [-17.973274, 27.827681],
              [-17.973295, 27.827589],
              [-17.973199, 27.827574],
            ],
            [
              [-18.152434, 27.738651],
              [-18.152431, 27.738559],
              [-18.152331, 27.738583],
              [-18.152315, 27.738628],
              [-18.152434, 27.738651],
            ],
            [
              [-18.152434, 27.738654],
              [-18.152439, 27.738652],
              [-18.152434, 27.738651],
              [-18.152434, 27.738654],
            ],
          ],
          [
            [
              [-16.158783, 28.594456],
              [-16.158322, 28.592993],
              [-16.158892, 28.591003],
              [-16.161317, 28.592454],
              [-16.158783, 28.594456],
            ],
          ],
          [
            [
              [-16.155806, 28.603645],
              [-16.157184, 28.601927],
              [-16.157722, 28.601964],
              [-16.155447, 28.605261],
              [-16.155806, 28.603645],
            ],
          ],
          [
            [
              [-16.763139, 28.380756],
              [-16.76176, 28.380052],
              [-16.762115, 28.3782],
              [-16.76351, 28.379396],
              [-16.763139, 28.380756],
            ],
          ],
          [
            [
              [-17.995815, 27.824774],
              [-17.995202, 27.823429],
              [-17.996421, 27.822816],
              [-17.997183, 27.824447],
              [-17.995815, 27.824774],
            ],
          ],
          [
            [
              [-17.270672, 28.218592],
              [-17.26948, 28.218345],
              [-17.2719, 28.218115],
              [-17.271722, 28.218503],
              [-17.270672, 28.218592],
            ],
          ],
          [
            [
              [-17.299889, 28.209447],
              [-17.300615, 28.208975],
              [-17.300678, 28.210102],
              [-17.299627, 28.209605],
              [-17.299889, 28.209447],
            ],
          ],
          [
            [
              [-17.968908, 28.829426],
              [-17.96794, 28.828932],
              [-17.968065, 28.82843],
              [-17.96914, 28.828812],
              [-17.968908, 28.829426],
            ],
          ],
          [
            [
              [-16.13257, 28.581264],
              [-16.132691, 28.580392],
              [-16.133604, 28.580769],
              [-16.133002, 28.581413],
              [-16.13257, 28.581264],
            ],
          ],
          [
            [
              [-16.486587, 28.439335],
              [-16.487288, 28.439745],
              [-16.487336, 28.440465],
              [-16.486576, 28.439783],
              [-16.486587, 28.439335],
            ],
          ],
          [
            [
              [-17.941533, 27.846863],
              [-17.941011, 27.846807],
              [-17.940933, 27.846124],
              [-17.941591, 27.846277],
              [-17.941533, 27.846863],
            ],
          ],
          [
            [
              [-17.337739, 28.083538],
              [-17.337713, 28.083248],
              [-17.338271, 28.084434],
              [-17.337964, 28.084306],
              [-17.337739, 28.083538],
            ],
          ],
          [
            [
              [-18.000962, 27.822208],
              [-18.001405, 27.822543],
              [-18.000914, 27.82287],
              [-18.000579, 27.822437],
              [-18.000962, 27.822208],
            ],
          ],
          [
            [
              [-16.422765, 28.510015],
              [-16.422349, 28.509796],
              [-16.422552, 28.509402],
              [-16.423201, 28.509854],
              [-16.422765, 28.510015],
            ],
          ],
          [
            [
              [-17.937507, 27.847584],
              [-17.93748, 27.846952],
              [-17.937895, 27.846853],
              [-17.93787, 27.847631],
              [-17.937507, 27.847584],
            ],
          ],
          [
            [
              [-16.413276, 28.217479],
              [-16.413758, 28.217645],
              [-16.413587, 28.218004],
              [-16.412945, 28.217786],
              [-16.413276, 28.217479],
            ],
          ],
          [
            [
              [-18.142111, 27.762633],
              [-18.142556, 27.762856],
              [-18.142899, 27.763583],
              [-18.141963, 27.762778],
              [-18.142111, 27.762633],
            ],
          ],
          [
            [
              [-16.423802, 28.507319],
              [-16.424186, 28.507426],
              [-16.42373, 28.508096],
              [-16.423389, 28.507676],
              [-16.423802, 28.507319],
            ],
          ],
          [
            [
              [-17.994786, 27.823394],
              [-17.994958, 27.823448],
              [-17.995072, 27.824233],
              [-17.994489, 27.823724],
              [-17.994786, 27.823394],
            ],
          ],
          [
            [
              [-17.853196, 28.833913],
              [-17.853558, 28.833864],
              [-17.853827, 28.834364],
              [-17.853122, 28.834216],
              [-17.853196, 28.833913],
            ],
          ],
          [
            [
              [-17.895, 27.83321],
              [-17.895389, 27.833461],
              [-17.894689, 27.833821],
              [-17.894668, 27.833744],
              [-17.895, 27.83321],
            ],
          ],
          [
            [
              [-16.829016, 28.197115],
              [-16.829264, 28.19722],
              [-16.829314, 28.197794],
              [-16.828825, 28.197424],
              [-16.829016, 28.197115],
            ],
          ],
          [
            [
              [-16.781059, 28.123287],
              [-16.78055, 28.123327],
              [-16.780483, 28.123182],
              [-16.78118, 28.122833],
              [-16.781059, 28.123287],
            ],
          ],
          [
            [
              [-17.956517, 27.841671],
              [-17.956719, 27.841779],
              [-17.956799, 27.842626],
              [-17.956526, 27.84246],
              [-17.956517, 27.841671],
            ],
          ],
          [
            [
              [-16.425448, 28.503952],
              [-16.425672, 28.504111],
              [-16.4253, 28.504508],
              [-16.424973, 28.504222],
              [-16.425448, 28.503952],
            ],
          ],
          [
            [
              [-17.920932, 28.85712],
              [-17.921277, 28.857212],
              [-17.921043, 28.857669],
              [-17.920699, 28.857401],
              [-17.920932, 28.85712],
            ],
          ],
          [
            [
              [-17.880101, 28.525069],
              [-17.879871, 28.524926],
              [-17.879486, 28.524523],
              [-17.880034, 28.524504],
              [-17.880101, 28.525069],
            ],
          ],
          [
            [
              [-16.276245, 28.573309],
              [-16.276396, 28.572854],
              [-16.276828, 28.573296],
              [-16.276613, 28.573361],
              [-16.276245, 28.573309],
            ],
          ],
          [
            [
              [-17.943602, 27.844416],
              [-17.944004, 27.844468],
              [-17.944224, 27.844862],
              [-17.943716, 27.844722],
              [-17.943602, 27.844416],
            ],
          ],
          [
            [
              [-16.445006, 28.479347],
              [-16.445266, 28.479341],
              [-16.445838, 28.479987],
              [-16.445301, 28.479772],
              [-16.445006, 28.479347],
            ],
          ],
          [
            [
              [-16.42918, 28.50047],
              [-16.428945, 28.500202],
              [-16.429426, 28.500034],
              [-16.429508, 28.500361],
              [-16.42918, 28.50047],
            ],
          ],
          [
            [
              [-17.943363, 27.844659],
              [-17.943625, 27.844759],
              [-17.943783, 27.845369],
              [-17.943446, 27.845185],
              [-17.943363, 27.844659],
            ],
          ],
          [
            [
              [-17.329677, 28.189055],
              [-17.329001, 28.188724],
              [-17.328968, 28.188576],
              [-17.329843, 28.188599],
              [-17.329677, 28.189055],
            ],
          ],
          [
            [
              [-16.522265, 28.419115],
              [-16.522048, 28.418918],
              [-16.522047, 28.418708],
              [-16.522638, 28.41928],
              [-16.522265, 28.419115],
            ],
          ],
          [
            [
              [-16.781767, 28.375619],
              [-16.782022, 28.375467],
              [-16.782153, 28.375727],
              [-16.781692, 28.375963],
              [-16.781767, 28.375619],
            ],
          ],
          [
            [
              [-17.949132, 27.840897],
              [-17.949434, 27.840932],
              [-17.949232, 27.841415],
              [-17.948986, 27.841166],
              [-17.949132, 27.840897],
            ],
          ],
          [
            [
              [-17.211406, 28.202767],
              [-17.211273, 28.202465],
              [-17.212012, 28.20266],
              [-17.211606, 28.202613],
              [-17.211406, 28.202767],
            ],
          ],
          [
            [
              [-17.928623, 28.846426],
              [-17.928842, 28.846505],
              [-17.929044, 28.846997],
              [-17.928656, 28.846801],
              [-17.928623, 28.846426],
            ],
          ],
          [
            [
              [-18.008408, 27.787584],
              [-18.00818, 27.787519],
              [-18.008382, 27.787007],
              [-18.008747, 27.787414],
              [-18.008408, 27.787584],
            ],
          ],
          [
            [
              [-17.271792, 28.217543],
              [-17.271665, 28.217235],
              [-17.272424, 28.217232],
              [-17.272151, 28.217494],
              [-17.271792, 28.217543],
            ],
          ],
          [
            [
              [-16.458894, 28.460294],
              [-16.459277, 28.460473],
              [-16.459128, 28.460713],
              [-16.458844, 28.460441],
              [-16.458894, 28.460294],
            ],
          ],
          [
            [
              [-16.743706, 28.376428],
              [-16.743986, 28.376328],
              [-16.743902, 28.376968],
              [-16.74378, 28.376883],
              [-16.743706, 28.376428],
            ],
          ],
          [
            [
              [-16.847444, 28.388359],
              [-16.847744, 28.38855],
              [-16.847731, 28.388986],
              [-16.847434, 28.388498],
              [-16.847444, 28.388359],
            ],
          ],
          [
            [
              [-16.518884, 28.420246],
              [-16.518688, 28.419919],
              [-16.519249, 28.419739],
              [-16.51926, 28.41981],
              [-16.518884, 28.420246],
            ],
          ],
          [
            [
              [-16.131929, 28.581731],
              [-16.131737, 28.5819],
              [-16.131626, 28.581481],
              [-16.132091, 28.581583],
              [-16.131929, 28.581731],
            ],
          ],
          [
            [
              [-17.755793, 28.647708],
              [-17.756188, 28.64792],
              [-17.755587, 28.648224],
              [-17.755879, 28.647922],
              [-17.755793, 28.647708],
            ],
          ],
          [
            [
              [-17.948554, 27.840983],
              [-17.948826, 27.840948],
              [-17.948707, 27.841411],
              [-17.94854, 27.84131],
              [-17.948554, 27.840983],
            ],
          ],
          [
            [
              [-17.336777, 28.083279],
              [-17.336722, 28.083037],
              [-17.337287, 28.083084],
              [-17.337086, 28.083282],
              [-17.336777, 28.083279],
            ],
          ],
          [
            [
              [-18.139439, 27.76697],
              [-18.139287, 27.766802],
              [-18.139101, 27.766528],
              [-18.139782, 27.767163],
              [-18.139439, 27.76697],
            ],
          ],
          [
            [
              [-16.616241, 28.395871],
              [-16.616408, 28.395934],
              [-16.616408, 28.396301],
              [-16.616097, 28.396251],
              [-16.616241, 28.395871],
            ],
          ],
          [
            [
              [-16.595901, 28.400594],
              [-16.596299, 28.400807],
              [-16.596229, 28.400925],
              [-16.595903, 28.400927],
              [-16.595901, 28.400594],
            ],
          ],
          [
            [
              [-17.75813, 28.652558],
              [-17.758334, 28.652597],
              [-17.758385, 28.653014],
              [-17.75805, 28.652903],
              [-17.75813, 28.652558],
            ],
          ],
          [
            [
              [-18.004864, 27.647506],
              [-18.005057, 27.647495],
              [-18.004479, 27.647837],
              [-18.004517, 27.647685],
              [-18.004864, 27.647506],
            ],
          ],
          [
            [
              [-16.59508, 28.400491],
              [-16.594978, 28.400227],
              [-16.595481, 28.400698],
              [-16.59544, 28.400763],
              [-16.59508, 28.400491],
            ],
          ],
          [
            [
              [-17.934551, 28.84524],
              [-17.93441, 28.844791],
              [-17.934429, 28.844769],
              [-17.934826, 28.845152],
              [-17.934551, 28.84524],
            ],
          ],
          [
            [
              [-17.944965, 28.842155],
              [-17.945246, 28.842468],
              [-17.944738, 28.84258],
              [-17.944771, 28.842357],
              [-17.944965, 28.842155],
            ],
          ],
          [
            [
              [-17.7592, 28.65352],
              [-17.759636, 28.65362],
              [-17.759716, 28.65374],
              [-17.759341, 28.653818],
              [-17.7592, 28.65352],
            ],
          ],
          [
            [
              [-17.340384, 28.088113],
              [-17.340375, 28.087744],
              [-17.340671, 28.088381],
              [-17.340617, 28.088422],
              [-17.340384, 28.088113],
            ],
          ],
          [
            [
              [-16.827949, 28.195455],
              [-16.827641, 28.195565],
              [-16.827564, 28.195282],
              [-16.827984, 28.195264],
              [-16.827949, 28.195455],
            ],
          ],
          [
            [
              [-17.266147, 28.21779],
              [-17.265963, 28.217547],
              [-17.266457, 28.217728],
              [-17.266014, 28.217876],
              [-17.266147, 28.21779],
            ],
          ],
          [
            [
              [-17.207224, 28.198518],
              [-17.206875, 28.198516],
              [-17.206931, 28.198292],
              [-17.207248, 28.198259],
              [-17.207224, 28.198518],
            ],
          ],
          [
            [
              [-17.284853, 28.21094],
              [-17.285105, 28.211093],
              [-17.284969, 28.211385],
              [-17.284736, 28.211195],
              [-17.284853, 28.21094],
            ],
          ],
          [
            [
              [-18.048648, 27.761323],
              [-18.048534, 27.76121],
              [-18.048418, 27.760907],
              [-18.048698, 27.76101],
              [-18.048648, 27.761323],
            ],
          ],
          [
            [
              [-16.526916, 28.418427],
              [-16.527135, 28.418418],
              [-16.52735, 28.418781],
              [-16.526953, 28.418654],
              [-16.526916, 28.418427],
            ],
          ],
          [
            [
              [-18.049291, 27.761291],
              [-18.049391, 27.761214],
              [-18.049612, 27.761556],
              [-18.049372, 27.761583],
              [-18.049291, 27.761291],
            ],
          ],
          [
            [
              [-16.825783, 28.393781],
              [-16.825975, 28.39397],
              [-16.825725, 28.39419],
              [-16.825655, 28.393906],
              [-16.825783, 28.393781],
            ],
          ],
          [
            [
              [-17.319171, 28.069025],
              [-17.318816, 28.068935],
              [-17.318776, 28.068679],
              [-17.319296, 28.068833],
              [-17.319171, 28.069025],
            ],
          ],
          [
            [
              [-17.320179, 28.057791],
              [-17.319887, 28.057812],
              [-17.319849, 28.057774],
              [-17.320171, 28.05751],
              [-17.320179, 28.057791],
            ],
          ],
          [
            [
              [-16.418582, 28.519639],
              [-16.418943, 28.519724],
              [-16.418981, 28.519868],
              [-16.418693, 28.519877],
              [-16.418582, 28.519639],
            ],
          ],
          [
            [
              [-17.299923, 28.210243],
              [-17.299607, 28.210219],
              [-17.299357, 28.209981],
              [-17.299905, 28.210141],
              [-17.299923, 28.210243],
            ],
          ],
          [
            [
              [-17.347964, 28.112349],
              [-17.348209, 28.112516],
              [-17.348091, 28.112691],
              [-17.347884, 28.112585],
              [-17.347964, 28.112349],
            ],
          ],
          [
            [
              [-18.138278, 27.766942],
              [-18.138512, 27.767094],
              [-18.138555, 27.767337],
              [-18.138273, 27.767173],
              [-18.138278, 27.766942],
            ],
          ],
          [
            [
              [-16.138351, 28.584202],
              [-16.13848, 28.584266],
              [-16.138389, 28.584689],
              [-16.138195, 28.584491],
              [-16.138351, 28.584202],
            ],
          ],
          [
            [
              [-17.963913, 27.839352],
              [-17.96417, 27.839338],
              [-17.964072, 27.839666],
              [-17.963859, 27.839469],
              [-17.963913, 27.839352],
            ],
          ],
          [
            [
              [-16.206692, 28.572717],
              [-16.206545, 28.572748],
              [-16.206465, 28.57247],
              [-16.206789, 28.572509],
              [-16.206692, 28.572717],
            ],
          ],
          [
            [
              [-17.934671, 28.843459],
              [-17.934761, 28.843584],
              [-17.934613, 28.843853],
              [-17.93442, 28.843748],
              [-17.934671, 28.843459],
            ],
          ],
          [
            [
              [-17.927764, 27.849545],
              [-17.927829, 27.849892],
              [-17.927613, 27.850001],
              [-17.927528, 27.849879],
              [-17.927764, 27.849545],
            ],
          ],
          [
            [
              [-16.120491, 28.559919],
              [-16.12037, 28.559946],
              [-16.120108, 28.559873],
              [-16.120689, 28.559832],
              [-16.120491, 28.559919],
            ],
          ],
          [
            [
              [-17.992943, 27.825074],
              [-17.992826, 27.824816],
              [-17.992869, 27.824749],
              [-17.993135, 27.824987],
              [-17.992943, 27.825074],
            ],
          ],
          [
            [
              [-17.336597, 28.174767],
              [-17.336417, 28.17477],
              [-17.336167, 28.174437],
              [-17.336538, 28.17457],
              [-17.336597, 28.174767],
            ],
          ],
          [
            [
              [-17.878676, 28.516652],
              [-17.87835, 28.516801],
              [-17.87816, 28.516544],
              [-17.878563, 28.516535],
              [-17.878676, 28.516652],
            ],
          ],
          [
            [
              [-16.275711, 28.572399],
              [-16.275953, 28.572371],
              [-16.275946, 28.572647],
              [-16.275691, 28.572632],
              [-16.275711, 28.572399],
            ],
          ],
          [
            [
              [-17.339739, 28.085723],
              [-17.339752, 28.085895],
              [-17.339862, 28.086097],
              [-17.339566, 28.085602],
              [-17.339739, 28.085723],
            ],
          ],
          [
            [
              [-18.048855, 27.760273],
              [-18.048975, 27.760264],
              [-18.049116, 27.760712],
              [-18.048837, 27.760551],
              [-18.048855, 27.760273],
            ],
          ],
          [
            [
              [-16.143564, 28.521315],
              [-16.143712, 28.521467],
              [-16.143421, 28.521616],
              [-16.143382, 28.52148],
              [-16.143564, 28.521315],
            ],
          ],
          [
            [
              [-16.188517, 28.575581],
              [-16.188657, 28.575725],
              [-16.188339, 28.575884],
              [-16.188393, 28.575662],
              [-16.188517, 28.575581],
            ],
          ],
          [
            [
              [-17.953044, 27.842193],
              [-17.95275, 27.842155],
              [-17.952743, 27.841919],
              [-17.952945, 27.841898],
              [-17.953044, 27.842193],
            ],
          ],
          [
            [
              [-16.42125, 28.51781],
              [-16.421543, 28.517818],
              [-16.421528, 28.51799],
              [-16.421346, 28.518057],
              [-16.42125, 28.51781],
            ],
          ],
          [
            [
              [-17.825763, 28.473222],
              [-17.825255, 28.473052],
              [-17.825254, 28.472956],
              [-17.825654, 28.473047],
              [-17.825763, 28.473222],
            ],
          ],
          [
            [
              [-16.160543, 28.593766],
              [-16.16078, 28.593705],
              [-16.160253, 28.59406],
              [-16.160301, 28.593968],
              [-16.160543, 28.593766],
            ],
          ],
          [
            [
              [-16.361797, 28.549118],
              [-16.361909, 28.548955],
              [-16.362085, 28.54915],
              [-16.361997, 28.549319],
              [-16.361797, 28.549118],
            ],
          ],
          [
            [
              [-16.427405, 28.494417],
              [-16.427272, 28.494294],
              [-16.427404, 28.494125],
              [-16.427608, 28.494281],
              [-16.427405, 28.494417],
            ],
          ],
          [
            [
              [-18.158241, 27.730079],
              [-18.158294, 27.72987],
              [-18.158579, 27.72997],
              [-18.158397, 27.730107],
              [-18.158241, 27.730079],
            ],
          ],
          [
            [
              [-16.695148, 28.396338],
              [-16.695348, 28.396322],
              [-16.695391, 28.396487],
              [-16.695186, 28.396568],
              [-16.695148, 28.396338],
            ],
          ],
          [
            [
              [-17.241511, 28.202664],
              [-17.241715, 28.202936],
              [-17.241599, 28.203135],
              [-17.241509, 28.2031],
              [-17.241511, 28.202664],
            ],
          ],
          [
            [
              [-16.693705, 28.395981],
              [-16.693774, 28.39598],
              [-16.693942, 28.396333],
              [-16.693752, 28.396332],
              [-16.693705, 28.395981],
            ],
          ],
          [
            [
              [-16.444746, 28.479624],
              [-16.444444, 28.479462],
              [-16.444538, 28.479336],
              [-16.444794, 28.479449],
              [-16.444746, 28.479624],
            ],
          ],
          [
            [
              [-17.942875, 27.845746],
              [-17.943028, 27.845791],
              [-17.943166, 27.846133],
              [-17.94296, 27.846026],
              [-17.942875, 27.845746],
            ],
          ],
          [
            [
              [-16.361288, 28.548862],
              [-16.361509, 28.54874],
              [-16.36161, 28.548819],
              [-16.361487, 28.549014],
              [-16.361288, 28.548862],
            ],
          ],
          [
            [
              [-18.158063, 27.729401],
              [-18.158281, 27.729382],
              [-18.158292, 27.729698],
              [-18.157997, 27.729473],
              [-18.158063, 27.729401],
            ],
          ],
          [
            [
              [-17.826006, 28.472296],
              [-17.825694, 28.472183],
              [-17.825473, 28.472084],
              [-17.8258, 28.472066],
              [-17.826006, 28.472296],
            ],
          ],
          [
            [
              [-17.329785, 28.184474],
              [-17.329326, 28.184502],
              [-17.329265, 28.184447],
              [-17.329649, 28.18424],
              [-17.329785, 28.184474],
            ],
          ],
          [
            [
              [-17.931069, 27.848103],
              [-17.930976, 27.8479],
              [-17.931003, 27.847636],
              [-17.931208, 27.848106],
              [-17.931069, 27.848103],
            ],
          ],
          [
            [
              [-18.007468, 28.774744],
              [-18.007337, 28.774751],
              [-18.007355, 28.774511],
              [-18.0077, 28.774646],
              [-18.007468, 28.774744],
            ],
          ],
          [
            [
              [-16.426845, 28.499974],
              [-16.427123, 28.500015],
              [-16.427106, 28.500168],
              [-16.426852, 28.500141],
              [-16.426845, 28.499974],
            ],
          ],
          [
            [
              [-16.202316, 28.573834],
              [-16.202225, 28.573705],
              [-16.202021, 28.573624],
              [-16.202279, 28.573579],
              [-16.202316, 28.573834],
            ],
          ],
          [
            [
              [-18.054848, 27.758979],
              [-18.054596, 27.758944],
              [-18.054544, 27.758728],
              [-18.054732, 27.758747],
              [-18.054848, 27.758979],
            ],
          ],
          [
            [
              [-16.611691, 28.019991],
              [-16.612093, 28.019818],
              [-16.612311, 28.019954],
              [-16.611605, 28.020067],
              [-16.611691, 28.019991],
            ],
          ],
          [
            [
              [-18.007769, 28.771582],
              [-18.007528, 28.771536],
              [-18.007294, 28.771353],
              [-18.007837, 28.771479],
              [-18.007769, 28.771582],
            ],
          ],
          [
            [
              [-16.13429, 28.57839],
              [-16.134411, 28.578402],
              [-16.13452, 28.578733],
              [-16.134225, 28.578591],
              [-16.13429, 28.57839],
            ],
          ],
          [
            [
              [-18.121446, 27.769705],
              [-18.121656, 27.769795],
              [-18.121288, 27.769883],
              [-18.121262, 27.769838],
              [-18.121446, 27.769705],
            ],
          ],
          [
            [
              [-17.757056, 28.650451],
              [-17.756974, 28.650627],
              [-17.75678, 28.650327],
              [-17.757028, 28.650397],
              [-17.757056, 28.650451],
            ],
          ],
          [
            [
              [-16.444408, 28.480321],
              [-16.44456, 28.480388],
              [-16.444704, 28.480663],
              [-16.444322, 28.480399],
              [-16.444408, 28.480321],
            ],
          ],
          [
            [
              [-16.206129, 28.572708],
              [-16.205982, 28.572573],
              [-16.206278, 28.572487],
              [-16.206175, 28.572688],
              [-16.206129, 28.572708],
            ],
          ],
          [
            [
              [-16.471747, 28.450614],
              [-16.471823, 28.4505],
              [-16.472112, 28.450641],
              [-16.471818, 28.450722],
              [-16.471747, 28.450614],
            ],
          ],
          [
            [
              [-18.038933, 27.763028],
              [-18.038692, 27.762894],
              [-18.038796, 27.762627],
              [-18.03886, 27.762641],
              [-18.038933, 27.763028],
            ],
          ],
          [
            [
              [-17.878653, 28.516284],
              [-17.878357, 28.516333],
              [-17.878065, 28.51626],
              [-17.878153, 28.516153],
              [-17.878653, 28.516284],
            ],
          ],
          [
            [
              [-16.12598, 28.547594],
              [-16.125851, 28.547479],
              [-16.125832, 28.547407],
              [-16.126208, 28.547572],
              [-16.12598, 28.547594],
            ],
          ],
          [
            [
              [-17.336802, 28.161697],
              [-17.337046, 28.161697],
              [-17.336509, 28.161764],
              [-17.336661, 28.16172],
              [-17.336802, 28.161697],
            ],
          ],
          [
            [
              [-16.187466, 28.576312],
              [-16.187582, 28.576446],
              [-16.187416, 28.576615],
              [-16.187373, 28.576433],
              [-16.187466, 28.576312],
            ],
          ],
          [
            [
              [-17.291957, 28.2094],
              [-17.292044, 28.2093],
              [-17.292006, 28.209882],
              [-17.291924, 28.209832],
              [-17.291957, 28.2094],
            ],
          ],
          [
            [
              [-16.187253, 28.576794],
              [-16.18754, 28.576796],
              [-16.187589, 28.576916],
              [-16.187458, 28.577008],
              [-16.187253, 28.576794],
            ],
          ],
          [
            [
              [-17.949611, 27.841732],
              [-17.949741, 27.841729],
              [-17.949722, 27.842012],
              [-17.94957, 27.841993],
              [-17.949611, 27.841732],
            ],
          ],
          [
            [
              [-17.346519, 28.119129],
              [-17.346584, 28.119118],
              [-17.346821, 28.119162],
              [-17.346504, 28.119318],
              [-17.346519, 28.119129],
            ],
          ],
          [
            [
              [-16.594484, 28.400064],
              [-16.594373, 28.399965],
              [-16.594413, 28.399834],
              [-16.59465, 28.399965],
              [-16.594484, 28.400064],
            ],
          ],
          [
            [
              [-16.204058, 28.575721],
              [-16.204174, 28.575749],
              [-16.204398, 28.575939],
              [-16.204177, 28.57595],
              [-16.204058, 28.575721],
            ],
          ],
          [
            [
              [-17.952766, 27.84167],
              [-17.952788, 27.84148],
              [-17.952978, 27.841537],
              [-17.952833, 27.841763],
              [-17.952766, 27.84167],
            ],
          ],
          [
            [
              [-17.348417, 28.110572],
              [-17.348345, 28.11069],
              [-17.348038, 28.110617],
              [-17.348169, 28.110456],
              [-17.348417, 28.110572],
            ],
          ],
          [
            [
              [-16.429539, 28.491918],
              [-16.429307, 28.4918],
              [-16.429345, 28.491678],
              [-16.429567, 28.491699],
              [-16.429539, 28.491918],
            ],
          ],
          [
            [
              [-17.75603, 28.648839],
              [-17.755924, 28.648778],
              [-17.75586, 28.648674],
              [-17.75617, 28.648724],
              [-17.75603, 28.648839],
            ],
          ],
          [
            [
              [-18.007401, 28.770305],
              [-18.007284, 28.770207],
              [-18.007296, 28.770068],
              [-18.007647, 28.770375],
              [-18.007401, 28.770305],
            ],
          ],
          [
            [
              [-16.849356, 28.387683],
              [-16.849473, 28.387676],
              [-16.849614, 28.387821],
              [-16.849258, 28.387862],
              [-16.849356, 28.387683],
            ],
          ],
          [
            [
              [-16.12483, 28.54903],
              [-16.124507, 28.549024],
              [-16.12437, 28.548951],
              [-16.124752, 28.548802],
              [-16.12483, 28.54903],
            ],
          ],
          [
            [
              [-17.967673, 27.832916],
              [-17.967788, 27.832938],
              [-17.967914, 27.833149],
              [-17.967711, 27.833138],
              [-17.967673, 27.832916],
            ],
          ],
          [
            [
              [-17.930729, 27.84845],
              [-17.930902, 27.848529],
              [-17.930923, 27.84873],
              [-17.930768, 27.848723],
              [-17.930729, 27.84845],
            ],
          ],
          [
            [
              [-17.292749, 28.208628],
              [-17.292919, 28.208715],
              [-17.292711, 28.208878],
              [-17.292632, 28.208752],
              [-17.292749, 28.208628],
            ],
          ],
          [
            [
              [-16.747461, 28.373457],
              [-16.747365, 28.373296],
              [-16.747695, 28.37327],
              [-16.747715, 28.373321],
              [-16.747461, 28.373457],
            ],
          ],
          [
            [
              [-16.694149, 28.396035],
              [-16.694327, 28.396086],
              [-16.694339, 28.39628],
              [-16.694174, 28.39623],
              [-16.694149, 28.396035],
            ],
          ],
          [
            [
              [-16.200922, 28.57315],
              [-16.201094, 28.573159],
              [-16.201252, 28.573406],
              [-16.200912, 28.573207],
              [-16.200922, 28.57315],
            ],
          ],
          [
            [
              [-16.184408, 28.577626],
              [-16.184593, 28.577783],
              [-16.184426, 28.577885],
              [-16.184321, 28.577761],
              [-16.184408, 28.577626],
            ],
          ],
          [
            [
              [-17.862107, 28.466064],
              [-17.862253, 28.466052],
              [-17.861981, 28.466373],
              [-17.861961, 28.466253],
              [-17.862107, 28.466064],
            ],
          ],
          [
            [
              [-16.495232, 28.441247],
              [-16.495383, 28.441288],
              [-16.495272, 28.441502],
              [-16.495163, 28.441356],
              [-16.495232, 28.441247],
            ],
          ],
          [
            [
              [-18.007952, 28.775965],
              [-18.008137, 28.775904],
              [-18.008189, 28.77606],
              [-18.007908, 28.77612],
              [-18.007952, 28.775965],
            ],
          ],
          [
            [
              [-18.15985, 27.714732],
              [-18.159728, 27.714745],
              [-18.159662, 27.714608],
              [-18.160043, 27.714616],
              [-18.15985, 27.714732],
            ],
          ],
          [
            [
              [-18.133755, 27.771037],
              [-18.133463, 27.771106],
              [-18.133373, 27.770989],
              [-18.133454, 27.770932],
              [-18.133755, 27.771037],
            ],
          ],
          [
            [
              [-17.870448, 28.479479],
              [-17.870231, 28.479544],
              [-17.870123, 28.479342],
              [-17.870433, 28.479394],
              [-17.870448, 28.479479],
            ],
          ],
          [
            [
              [-17.931879, 28.84459],
              [-17.931618, 28.844445],
              [-17.931645, 28.844309],
              [-17.931814, 28.844417],
              [-17.931879, 28.84459],
            ],
          ],
          [
            [
              [-18.160997, 27.718773],
              [-18.161124, 27.718764],
              [-18.161222, 27.718889],
              [-18.161001, 27.718935],
              [-18.160997, 27.718773],
            ],
          ],
          [
            [
              [-16.37142, 28.547982],
              [-16.371556, 28.547992],
              [-16.371458, 28.548223],
              [-16.371346, 28.548091],
              [-16.37142, 28.547982],
            ],
          ],
          [
            [
              [-16.427098, 28.499163],
              [-16.427288, 28.499211],
              [-16.42727, 28.49934],
              [-16.426978, 28.499264],
              [-16.427098, 28.499163],
            ],
          ],
          [
            [
              [-16.456334, 28.462101],
              [-16.456589, 28.46217],
              [-16.456666, 28.462309],
              [-16.456549, 28.462303],
              [-16.456334, 28.462101],
            ],
          ],
          [
            [
              [-16.11944, 28.551273],
              [-16.11954, 28.551243],
              [-16.11965, 28.551411],
              [-16.119364, 28.551457],
              [-16.11944, 28.551273],
            ],
          ],
          [
            [
              [-16.424166, 28.506885],
              [-16.423966, 28.506856],
              [-16.424083, 28.506653],
              [-16.424228, 28.506842],
              [-16.424166, 28.506885],
            ],
          ],
          [
            [
              [-17.853596, 28.833497],
              [-17.853772, 28.833516],
              [-17.853565, 28.833717],
              [-17.853518, 28.833646],
              [-17.853596, 28.833497],
            ],
          ],
          [
            [
              [-16.119218, 28.555459],
              [-16.119108, 28.555551],
              [-16.11879, 28.55544],
              [-16.119019, 28.555377],
              [-16.119218, 28.555459],
            ],
          ],
          [
            [
              [-16.125071, 28.53276],
              [-16.125398, 28.532836],
              [-16.12547, 28.532935],
              [-16.125207, 28.532909],
              [-16.125071, 28.53276],
            ],
          ],
          [
            [
              [-17.336301, 28.17108],
              [-17.336119, 28.171031],
              [-17.33653, 28.171073],
              [-17.33642, 28.17111],
              [-17.336301, 28.17108],
            ],
          ],
          [
            [
              [-17.927176, 27.849479],
              [-17.927267, 27.849476],
              [-17.927259, 27.849725],
              [-17.927113, 27.849672],
              [-17.927176, 27.849479],
            ],
          ],
          [
            [
              [-16.172239, 28.585455],
              [-16.172351, 28.585567],
              [-16.17211, 28.58573],
              [-16.172076, 28.58563],
              [-16.172239, 28.585455],
            ],
          ],
          [
            [
              [-16.364482, 28.547909],
              [-16.364361, 28.547749],
              [-16.364471, 28.547652],
              [-16.36459, 28.547767],
              [-16.364482, 28.547909],
            ],
          ],
          [
            [
              [-18.046635, 27.76111],
              [-18.046535, 27.760968],
              [-18.046546, 27.760844],
              [-18.046764, 27.761118],
              [-18.046635, 27.76111],
            ],
          ],
          [
            [
              [-17.347501, 28.113403],
              [-17.347622, 28.113401],
              [-17.347761, 28.113574],
              [-17.347643, 28.113647],
              [-17.347501, 28.113403],
            ],
          ],
          [
            [
              [-16.120217, 28.551018],
              [-16.120327, 28.550994],
              [-16.120541, 28.55113],
              [-16.12031, 28.551162],
              [-16.120217, 28.551018],
            ],
          ],
          [
            [
              [-16.135274, 28.582793],
              [-16.135426, 28.582769],
              [-16.135486, 28.582882],
              [-16.13533, 28.582972],
              [-16.135274, 28.582793],
            ],
          ],
          [
            [
              [-18.13489, 27.769738],
              [-18.135095, 27.769933],
              [-18.135072, 27.769976],
              [-18.134847, 27.769906],
              [-18.13489, 27.769738],
            ],
          ],
          [
            [
              [-16.595015, 28.400943],
              [-16.595115, 28.401102],
              [-16.594947, 28.401165],
              [-16.594915, 28.401069],
              [-16.595015, 28.400943],
            ],
          ],
          [
            [
              [-17.347844, 28.113593],
              [-17.348001, 28.113609],
              [-17.348053, 28.113704],
              [-17.347891, 28.11379],
              [-17.347844, 28.113593],
            ],
          ],
          [
            [
              [-17.842753, 28.83716],
              [-17.842709, 28.83686],
              [-17.842823, 28.836776],
              [-17.842799, 28.837183],
              [-17.842753, 28.83716],
            ],
          ],
          [
            [
              [-17.319669, 28.068006],
              [-17.319706, 28.068173],
              [-17.319539, 28.06815],
              [-17.319552, 28.068035],
              [-17.319669, 28.068006],
            ],
          ],
          [
            [
              [-16.172159, 28.585232],
              [-16.172157, 28.585388],
              [-16.171954, 28.585442],
              [-16.171973, 28.585301],
              [-16.172159, 28.585232],
            ],
          ],
          [
            [
              [-16.72456, 28.382322],
              [-16.724649, 28.382447],
              [-16.72461, 28.382597],
              [-16.724486, 28.382491],
              [-16.72456, 28.382322],
            ],
          ],
          [
            [
              [-17.994919, 27.822933],
              [-17.995107, 27.822998],
              [-17.995121, 27.823141],
              [-17.994937, 27.823076],
              [-17.994919, 27.822933],
            ],
          ],
          [
            [
              [-16.119791, 28.559094],
              [-16.119993, 28.559011],
              [-16.119624, 28.559256],
              [-16.119598, 28.559214],
              [-16.119791, 28.559094],
            ],
          ],
          [
            [
              [-16.748737, 28.373511],
              [-16.748642, 28.373526],
              [-16.748494, 28.373296],
              [-16.748647, 28.373303],
              [-16.748737, 28.373511],
            ],
          ],
          [
            [
              [-16.801651, 28.377249],
              [-16.801797, 28.377255],
              [-16.801608, 28.377459],
              [-16.801569, 28.377428],
              [-16.801651, 28.377249],
            ],
          ],
          [
            [
              [-17.330098, 28.186857],
              [-17.329935, 28.186963],
              [-17.329832, 28.186842],
              [-17.329906, 28.186753],
              [-17.330098, 28.186857],
            ],
          ],
          [
            [
              [-17.31948, 28.070682],
              [-17.319596, 28.070718],
              [-17.319547, 28.070929],
              [-17.319428, 28.070817],
              [-17.31948, 28.070682],
            ],
          ],
          [
            [
              [-17.964547, 28.668878],
              [-17.964288, 28.668912],
              [-17.964254, 28.668852],
              [-17.964357, 28.668696],
              [-17.964547, 28.668878],
            ],
          ],
          [
            [
              [-16.166222, 28.587803],
              [-16.166372, 28.587757],
              [-16.166492, 28.587819],
              [-16.166174, 28.587912],
              [-16.166222, 28.587803],
            ],
          ],
          [
            [
              [-17.826175, 28.471887],
              [-17.825734, 28.471759],
              [-17.825749, 28.471705],
              [-17.825998, 28.471694],
              [-17.826175, 28.471887],
            ],
          ],
          [
            [
              [-16.280437, 28.573538],
              [-16.280522, 28.573583],
              [-16.280281, 28.573802],
              [-16.280353, 28.573612],
              [-16.280437, 28.573538],
            ],
          ],
          [
            [
              [-17.34904, 28.107154],
              [-17.348859, 28.107209],
              [-17.348831, 28.10702],
              [-17.348992, 28.107028],
              [-17.34904, 28.107154],
            ],
          ],
          [
            [
              [-18.046121, 27.761255],
              [-18.046272, 27.761359],
              [-18.046133, 27.761504],
              [-18.046069, 27.761365],
              [-18.046121, 27.761255],
            ],
          ],
          [
            [
              [-18.134711, 27.769322],
              [-18.134857, 27.769328],
              [-18.134944, 27.769409],
              [-18.134747, 27.769458],
              [-18.134711, 27.769322],
            ],
          ],
          [
            [
              [-17.297919, 28.207493],
              [-17.29806, 28.207469],
              [-17.298123, 28.207553],
              [-17.297949, 28.207651],
              [-17.297919, 28.207493],
            ],
          ],
          [
            [
              [-17.274454, 28.215521],
              [-17.274512, 28.215493],
              [-17.274695, 28.215464],
              [-17.274535, 28.215675],
              [-17.274454, 28.215521],
            ],
          ],
          [
            [
              [-16.189509, 28.576017],
              [-16.189563, 28.576006],
              [-16.189739, 28.576034],
              [-16.18949, 28.57619],
              [-16.189509, 28.576017],
            ],
          ],
          [
            [
              [-18.050163, 27.760464],
              [-18.050051, 27.760475],
              [-18.049991, 27.760287],
              [-18.050179, 27.760313],
              [-18.050163, 27.760464],
            ],
          ],
          [
            [
              [-17.928448, 27.849798],
              [-17.92836, 27.849685],
              [-17.928373, 27.849599],
              [-17.928523, 27.84975],
              [-17.928424, 27.849979],
              [-17.928448, 27.849798],
            ],
          ],
          [
            [
              [-17.92178, 28.857027],
              [-17.921655, 28.856829],
              [-17.921765, 28.856792],
              [-17.921856, 28.856924],
              [-17.92178, 28.857027],
            ],
          ],
          [
            [
              [-16.783792, 28.12604],
              [-16.78391, 28.126027],
              [-16.783975, 28.126101],
              [-16.783768, 28.126199],
              [-16.783792, 28.12604],
            ],
          ],
          [
            [
              [-16.13853, 28.583516],
              [-16.138646, 28.583563],
              [-16.138485, 28.583812],
              [-16.138481, 28.583764],
              [-16.13853, 28.583516],
            ],
          ],
          [
            [
              [-16.818805, 28.385008],
              [-16.818938, 28.385037],
              [-16.818967, 28.38512],
              [-16.81876, 28.385137],
              [-16.818805, 28.385008],
            ],
          ],
          [
            [
              [-17.920821, 28.85702],
              [-17.920862, 28.857101],
              [-17.920622, 28.857269],
              [-17.920592, 28.857232],
              [-17.920821, 28.85702],
            ],
          ],
          [
            [
              [-16.119774, 28.550742],
              [-16.119932, 28.55078],
              [-16.119911, 28.55088],
              [-16.119699, 28.550861],
              [-16.119774, 28.550742],
            ],
          ],
          [
            [
              [-17.850042, 28.835789],
              [-17.849987, 28.835586],
              [-17.850027, 28.83545],
              [-17.850107, 28.835657],
              [-17.850042, 28.835789],
            ],
          ],
          [
            [
              [-16.358611, 28.549263],
              [-16.358681, 28.549185],
              [-16.358786, 28.549186],
              [-16.35873, 28.549348],
              [-16.358611, 28.549263],
            ],
          ],
          [
            [
              [-16.162142, 28.587135],
              [-16.162256, 28.587059],
              [-16.162271, 28.587251],
              [-16.162097, 28.587236],
              [-16.162142, 28.587135],
            ],
          ],
          [
            [
              [-17.967031, 27.678766],
              [-17.966934, 27.678808],
              [-17.966811, 27.678515],
              [-17.966872, 27.678529],
              [-17.967031, 27.678766],
            ],
          ],
          [
            [
              [-16.139013, 28.583969],
              [-16.139152, 28.584068],
              [-16.138926, 28.584125],
              [-16.138897, 28.584025],
              [-16.139013, 28.583969],
            ],
          ],
          [
            [
              [-17.874543, 28.491131],
              [-17.874699, 28.491144],
              [-17.874795, 28.49133],
              [-17.874523, 28.491189],
              [-17.874543, 28.491131],
            ],
          ],
          [
            [
              [-17.876679, 28.510532],
              [-17.876552, 28.510586],
              [-17.876346, 28.510501],
              [-17.876476, 28.510441],
              [-17.876679, 28.510532],
            ],
          ],
          [
            [
              [-17.877494, 28.518226],
              [-17.877449, 28.518354],
              [-17.877265, 28.518289],
              [-17.877329, 28.51821],
              [-17.877494, 28.518226],
            ],
          ],
          [
            [
              [-16.204518, 28.572503],
              [-16.204585, 28.572701],
              [-16.204422, 28.572723],
              [-16.204409, 28.572674],
              [-16.204518, 28.572503],
            ],
          ],
          [
            [
              [-17.290171, 28.210571],
              [-17.290059, 28.210564],
              [-17.289877, 28.210448],
              [-17.2901, 28.210437],
              [-17.290171, 28.210571],
            ],
          ],
          [
            [
              [-18.04564, 27.761626],
              [-18.045794, 27.761684],
              [-18.045778, 27.761817],
              [-18.045587, 27.76172],
              [-18.04564, 27.761626],
            ],
          ],
          [
            [
              [-16.282038, 28.57523],
              [-16.28212, 28.575135],
              [-16.282148, 28.57541],
              [-16.282049, 28.575365],
              [-16.282038, 28.57523],
            ],
          ],
          [
            [
              [-16.444914, 28.47919],
              [-16.444845, 28.479253],
              [-16.444533, 28.479167],
              [-16.444649, 28.479117],
              [-16.444914, 28.47919],
            ],
          ],
          [
            [
              [-16.38488, 28.546131],
              [-16.385066, 28.54617],
              [-16.385121, 28.546273],
              [-16.384829, 28.54617],
              [-16.38488, 28.546131],
            ],
          ],
          [
            [
              [-17.297786, 28.207518],
              [-17.297771, 28.207669],
              [-17.297523, 28.207518],
              [-17.297627, 28.2075],
              [-17.297786, 28.207518],
            ],
          ],
          [
            [
              [-17.923831, 28.853863],
              [-17.923946, 28.853813],
              [-17.923951, 28.854027],
              [-17.923851, 28.854],
              [-17.923831, 28.853863],
            ],
          ],
          [
            [
              [-16.131215, 28.582735],
              [-16.131045, 28.582706],
              [-16.131086, 28.582534],
              [-16.131223, 28.582647],
              [-16.131215, 28.582735],
            ],
          ],
          [
            [
              [-16.191604, 28.574873],
              [-16.191704, 28.575],
              [-16.191626, 28.575113],
              [-16.191552, 28.575052],
              [-16.191604, 28.574873],
            ],
          ],
          [
            [
              [-17.348381, 28.108834],
              [-17.348616, 28.10885],
              [-17.348628, 28.108902],
              [-17.348428, 28.108937],
              [-17.348381, 28.108834],
            ],
          ],
          [
            [
              [-16.26967, 28.576408],
              [-16.269541, 28.576389],
              [-16.269532, 28.576227],
              [-16.26967, 28.576272],
              [-16.26967, 28.576408],
            ],
          ],
          [
            [
              [-16.47143, 28.450433],
              [-16.471631, 28.450456],
              [-16.47167, 28.4505],
              [-16.47148, 28.450553],
              [-16.47143, 28.450433],
            ],
          ],
          [
            [
              [-17.336612, 28.164872],
              [-17.336714, 28.16484],
              [-17.336866, 28.164894],
              [-17.336751, 28.165001],
              [-17.336612, 28.164872],
            ],
          ],
          [
            [
              [-16.426777, 28.498024],
              [-16.426942, 28.498075],
              [-16.426933, 28.49817],
              [-16.426764, 28.498145],
              [-16.426777, 28.498024],
            ],
          ],
          [
            [
              [-18.006618, 28.752882],
              [-18.006731, 28.752913],
              [-18.006682, 28.753088],
              [-18.006555, 28.753054],
              [-18.006618, 28.752882],
            ],
          ],
          [
            [
              [-17.944221, 27.843658],
              [-17.944381, 27.843711],
              [-17.944326, 27.843805],
              [-17.944161, 27.843754],
              [-17.944221, 27.843658],
            ],
          ],
          [
            [
              [-16.135051, 28.582457],
              [-16.135204, 28.582478],
              [-16.135194, 28.582593],
              [-16.135028, 28.582558],
              [-16.135051, 28.582457],
            ],
          ],
          [
            [
              [-17.756085, 28.648143],
              [-17.756207, 28.648179],
              [-17.756125, 28.648335],
              [-17.75603, 28.648262],
              [-17.756085, 28.648143],
            ],
          ],
          [
            [
              [-18.12757, 27.770749],
              [-18.127674, 27.770867],
              [-18.127541, 27.77095],
              [-18.127512, 27.770833],
              [-18.12757, 27.770749],
            ],
          ],
          [
            [
              [-17.330114, 28.186181],
              [-17.330056, 28.186155],
              [-17.329812, 28.186123],
              [-17.329867, 28.186007],
              [-17.330114, 28.186181],
            ],
          ],
          [
            [
              [-17.9642, 28.670456],
              [-17.963928, 28.670409],
              [-17.963897, 28.670308],
              [-17.964139, 28.670377],
              [-17.9642, 28.670456],
            ],
          ],
          [
            [
              [-17.930266, 27.848752],
              [-17.930171, 27.848809],
              [-17.930237, 27.848592],
              [-17.930359, 27.848697],
              [-17.930266, 27.848752],
            ],
          ],
          [
            [
              [-18.121928, 27.770009],
              [-18.122009, 27.76995],
              [-18.122082, 27.770054],
              [-18.121911, 27.770135],
              [-18.121928, 27.770009],
            ],
          ],
          [
            [
              [-17.291188, 28.209967],
              [-17.291146, 28.210054],
              [-17.290958, 28.210006],
              [-17.290993, 28.209916],
              [-17.291188, 28.209967],
            ],
          ],
          [
            [
              [-18.05801, 27.758573],
              [-18.057891, 27.758421],
              [-18.057871, 27.758368],
              [-18.058011, 27.758387],
              [-18.05801, 27.758573],
            ],
          ],
          [
            [
              [-16.118898, 28.556401],
              [-16.118935, 28.55637],
              [-16.11915, 28.556401],
              [-16.119, 28.556496],
              [-16.118898, 28.556401],
            ],
          ],
          [
            [
              [-17.935163, 27.849076],
              [-17.935243, 27.84917],
              [-17.935122, 27.849293],
              [-17.935068, 27.849275],
              [-17.935163, 27.849076],
            ],
          ],
          [
            [
              [-16.122873, 28.560302],
              [-16.122928, 28.5603],
              [-16.123122, 28.560327],
              [-16.122765, 28.560396],
              [-16.122873, 28.560302],
            ],
          ],
          [
            [
              [-17.921387, 28.856636],
              [-17.92123, 28.856595],
              [-17.921565, 28.856614],
              [-17.921497, 28.856654],
              [-17.921387, 28.856636],
            ],
          ],
          [
            [
              [-17.938457, 27.846336],
              [-17.938575, 27.846322],
              [-17.938624, 27.846421],
              [-17.938463, 27.846476],
              [-17.938457, 27.846336],
            ],
          ],
          [
            [
              [-17.880783, 28.53372],
              [-17.880602, 28.533765],
              [-17.880514, 28.533669],
              [-17.880747, 28.533657],
              [-17.880783, 28.53372],
            ],
          ],
          [
            [
              [-16.172076, 28.587447],
              [-16.171931, 28.587431],
              [-16.172237, 28.587364],
              [-16.172248, 28.587395],
              [-16.172076, 28.587447],
            ],
          ],
          [
            [
              [-17.859278, 28.464516],
              [-17.859281, 28.46436],
              [-17.859447, 28.464464],
              [-17.85931, 28.464617],
              [-17.859278, 28.464516],
            ],
          ],
          [
            [
              [-16.140275, 28.584926],
              [-16.14037, 28.58504],
              [-16.140223, 28.585105],
              [-16.140202, 28.585045],
              [-16.140275, 28.584926],
            ],
          ],
          [
            [
              [-17.265784, 28.217715],
              [-17.265635, 28.217686],
              [-17.265564, 28.217615],
              [-17.265755, 28.21761],
              [-17.265784, 28.217715],
            ],
          ],
          [
            [
              [-16.165827, 28.587619],
              [-16.165945, 28.587708],
              [-16.165797, 28.587785],
              [-16.165744, 28.587696],
              [-16.165827, 28.587619],
            ],
          ],
          [
            [
              [-16.199677, 28.572668],
              [-16.199635, 28.572523],
              [-16.1997, 28.572463],
              [-16.199799, 28.572637],
              [-16.199677, 28.572668],
            ],
          ],
          [
            [
              [-18.157783, 27.729333],
              [-18.157786, 27.729179],
              [-18.15794, 27.729253],
              [-18.157837, 27.729364],
              [-18.157783, 27.729333],
            ],
          ],
          [
            [
              [-18.007808, 28.769375],
              [-18.00793, 28.769414],
              [-18.007837, 28.769509],
              [-18.007703, 28.769418],
              [-18.007808, 28.769375],
            ],
          ],
          [
            [
              [-17.330996, 28.183562],
              [-17.330874, 28.183529],
              [-17.330788, 28.183428],
              [-17.330969, 28.183433],
              [-17.330996, 28.183562],
            ],
          ],
          [
            [
              [-17.319715, 28.057968],
              [-17.31979, 28.058065],
              [-17.319552, 28.058051],
              [-17.319553, 28.058002],
              [-17.319715, 28.057968],
            ],
          ],
          [
            [
              [-16.267111, 28.574278],
              [-16.267217, 28.57418],
              [-16.26706, 28.574443],
              [-16.267039, 28.574383],
              [-16.267111, 28.574278],
            ],
          ],
          [
            [
              [-18.126334, 27.770349],
              [-18.126345, 27.770477],
              [-18.126218, 27.770542],
              [-18.126207, 27.770449],
              [-18.126334, 27.770349],
            ],
          ],
          [
            [
              [-16.122147, 28.550405],
              [-16.122242, 28.550423],
              [-16.122285, 28.550542],
              [-16.122119, 28.550526],
              [-16.122147, 28.550405],
            ],
          ],
          [
            [
              [-17.862633, 28.465753],
              [-17.862424, 28.465729],
              [-17.862384, 28.465624],
              [-17.862489, 28.465591],
              [-17.862633, 28.465753],
            ],
          ],
          [
            [
              [-17.849507, 28.456809],
              [-17.849594, 28.456804],
              [-17.849617, 28.456957],
              [-17.849479, 28.456985],
              [-17.849507, 28.456809],
            ],
          ],
          [
            [
              [-17.876568, 28.5041],
              [-17.876525, 28.504135],
              [-17.876253, 28.504058],
              [-17.876366, 28.504004],
              [-17.876568, 28.5041],
            ],
          ],
          [
            [
              [-17.877473, 28.517412],
              [-17.877323, 28.517541],
              [-17.877226, 28.517424],
              [-17.877375, 28.517368],
              [-17.877473, 28.517412],
            ],
          ],
          [
            [
              [-16.429412, 28.143957],
              [-16.429521, 28.144049],
              [-16.429376, 28.144118],
              [-16.429368, 28.143981],
              [-16.429412, 28.143957],
            ],
          ],
          [
            [
              [-17.319421, 28.069993],
              [-17.319298, 28.069866],
              [-17.31935, 28.069796],
              [-17.31943, 28.069852],
              [-17.319421, 28.069993],
            ],
          ],
          [
            [
              [-16.137911, 28.583679],
              [-16.138023, 28.583662],
              [-16.138014, 28.583848],
              [-16.137849, 28.583749],
              [-16.137911, 28.583679],
            ],
          ],
          [
            [
              [-16.375171, 28.547601],
              [-16.37527, 28.547696],
              [-16.375122, 28.547768],
              [-16.375117, 28.54765],
              [-16.375171, 28.547601],
            ],
          ],
          [
            [
              [-16.202467, 28.572994],
              [-16.202425, 28.573185],
              [-16.202326, 28.573152],
              [-16.202336, 28.573104],
              [-16.202467, 28.572994],
            ],
          ],
          [
            [
              [-18.048401, 27.760657],
              [-18.04844, 27.760618],
              [-18.048565, 27.760791],
              [-18.048404, 27.760808],
              [-18.048401, 27.760657],
            ],
          ],
          [
            [
              [-17.850808, 28.457034],
              [-17.850743, 28.456964],
              [-17.850895, 28.457],
              [-17.850912, 28.457152],
              [-17.850808, 28.457034],
            ],
          ],
          [
            [
              [-17.292571, 28.209258],
              [-17.292463, 28.209222],
              [-17.292507, 28.209103],
              [-17.292632, 28.209171],
              [-17.292571, 28.209258],
            ],
          ],
          [
            [
              [-16.426729, 28.49896],
              [-16.426891, 28.498932],
              [-16.4269, 28.498984],
              [-16.42673, 28.499065],
              [-16.426729, 28.49896],
            ],
          ],
          [
            [
              [-17.869018, 28.474206],
              [-17.869225, 28.474137],
              [-17.868931, 28.474347],
              [-17.869018, 28.474206],
            ],
          ],
          [
            [
              [-16.118723, 28.556866],
              [-16.118683, 28.556991],
              [-16.118566, 28.556972],
              [-16.118633, 28.556814],
              [-16.118723, 28.556866],
            ],
          ],
          [
            [
              [-17.326074, 28.188591],
              [-17.325933, 28.188535],
              [-17.325964, 28.188438],
              [-17.326106, 28.188508],
              [-17.326074, 28.188591],
            ],
          ],
          [
            [
              [-18.15846, 27.729575],
              [-18.158547, 27.729543],
              [-18.158618, 27.729644],
              [-18.158469, 27.729692],
              [-18.15846, 27.729575],
            ],
          ],
          [
            [
              [-17.267311, 28.217382],
              [-17.267411, 28.217438],
              [-17.267166, 28.217457],
              [-17.267311, 28.217382],
            ],
          ],
          [
            [
              [-16.198252, 28.572624],
              [-16.198344, 28.572682],
              [-16.198167, 28.57278],
              [-16.198138, 28.572738],
              [-16.198252, 28.572624],
            ],
          ],
          [
            [
              [-16.125733, 28.565665],
              [-16.125833, 28.565644],
              [-16.125903, 28.565735],
              [-16.125739, 28.565788],
              [-16.125733, 28.565665],
            ],
          ],
          [
            [
              [-17.33611, 28.171457],
              [-17.335977, 28.171513],
              [-17.335906, 28.171494],
              [-17.335958, 28.171356],
              [-17.33611, 28.171457],
            ],
          ],
          [
            [
              [-17.755894, 28.649168],
              [-17.756014, 28.649189],
              [-17.75598, 28.649302],
              [-17.755862, 28.649291],
              [-17.755894, 28.649168],
            ],
          ],
          [
            [
              [-18.132829, 27.770749],
              [-18.132842, 27.770658],
              [-18.132973, 27.770801],
              [-18.13285, 27.770849],
              [-18.132829, 27.770749],
            ],
          ],
          [
            [
              [-16.286905, 28.575252],
              [-16.286768, 28.575185],
              [-16.286761, 28.575074],
              [-16.286851, 28.575067],
              [-16.286905, 28.575252],
            ],
          ],
          [
            [
              [-17.319267, 28.05803],
              [-17.319431, 28.058045],
              [-17.319384, 28.058151],
              [-17.319278, 28.058143],
              [-17.319267, 28.05803],
            ],
          ],
          [
            [
              [-16.135089, 28.581967],
              [-16.135192, 28.581971],
              [-16.135233, 28.582032],
              [-16.135068, 28.58208],
              [-16.135089, 28.581967],
            ],
          ],
          [
            [
              [-16.125755, 28.566349],
              [-16.125865, 28.566345],
              [-16.125922, 28.566396],
              [-16.125709, 28.566435],
              [-16.125755, 28.566349],
            ],
          ],
          [
            [
              [-16.205585, 28.57262],
              [-16.205684, 28.572614],
              [-16.205662, 28.572765],
              [-16.205557, 28.572743],
              [-16.205585, 28.57262],
            ],
          ],
          [
            [
              [-18.049246, 27.7604],
              [-18.049135, 27.760463],
              [-18.049065, 27.760366],
              [-18.049114, 27.760302],
              [-18.049246, 27.7604],
            ],
          ],
          [
            [
              [-17.87145, 28.481359],
              [-17.87129, 28.48136],
              [-17.87137, 28.481212],
              [-17.87147, 28.481315],
              [-17.87145, 28.481359],
            ],
          ],
          [
            [
              [-17.336239, 28.16231],
              [-17.336121, 28.162286],
              [-17.336007, 28.162246],
              [-17.336304, 28.162251],
              [-17.336239, 28.16231],
            ],
          ],
          [
            [
              [-16.126029, 28.564729],
              [-16.12583, 28.564698],
              [-16.125811, 28.564624],
              [-16.126065, 28.564675],
              [-16.126029, 28.564729],
            ],
          ],
          [
            [
              [-17.336155, 28.164888],
              [-17.336052, 28.164862],
              [-17.336007, 28.164767],
              [-17.336125, 28.164746],
              [-17.336155, 28.164888],
            ],
          ],
          [
            [
              [-17.215925, 28.200325],
              [-17.216067, 28.200299],
              [-17.215992, 28.200475],
              [-17.215902, 28.200367],
              [-17.215925, 28.200325],
            ],
          ],
          [
            [
              [-16.121635, 28.559977],
              [-16.121746, 28.559872],
              [-16.121845, 28.559951],
              [-16.121593, 28.560011],
              [-16.121635, 28.559977],
            ],
          ],
          [
            [
              [-16.121715, 28.560137],
              [-16.12182, 28.560224],
              [-16.121735, 28.560281],
              [-16.121666, 28.560217],
              [-16.121715, 28.560137],
            ],
          ],
          [
            [
              [-16.123697, 28.56261],
              [-16.123811, 28.562612],
              [-16.123826, 28.562674],
              [-16.123686, 28.562721],
              [-16.123697, 28.56261],
            ],
          ],
          [
            [
              [-17.27205, 28.216552],
              [-17.271951, 28.216582],
              [-17.271862, 28.216548],
              [-17.271919, 28.216429],
              [-17.27205, 28.216552],
            ],
          ],
          [
            [
              [-16.196855, 28.573653],
              [-16.196948, 28.573662],
              [-16.196985, 28.573787],
              [-16.196836, 28.573744],
              [-16.196855, 28.573653],
            ],
          ],
          [
            [
              [-17.236587, 28.203755],
              [-17.236538, 28.203905],
              [-17.23643, 28.203881],
              [-17.236538, 28.203779],
              [-17.236587, 28.203755],
            ],
          ],
          [
            [
              [-16.15797, 28.593552],
              [-16.158038, 28.593541],
              [-16.157856, 28.593779],
              [-16.157835, 28.593707],
              [-16.15797, 28.593552],
            ],
          ],
          [
            [
              [-17.831596, 28.463996],
              [-17.831375, 28.464068],
              [-17.831325, 28.464009],
              [-17.831586, 28.463939],
              [-17.831596, 28.463996],
            ],
          ],
          [
            [
              [-17.340169, 28.087466],
              [-17.340064, 28.087362],
              [-17.340165, 28.087284],
              [-17.340206, 28.08738],
              [-17.340169, 28.087466],
            ],
          ],
          [
            [
              [-16.119276, 28.556514],
              [-16.119383, 28.556494],
              [-16.119434, 28.556566],
              [-16.119286, 28.556599],
              [-16.119276, 28.556514],
            ],
          ],
          [
            [
              [-17.298499, 28.209495],
              [-17.298388, 28.209452],
              [-17.298414, 28.209369],
              [-17.298546, 28.209372],
              [-17.298499, 28.209495],
            ],
          ],
          [
            [
              [-17.333427, 28.177995],
              [-17.333266, 28.177996],
              [-17.333229, 28.177875],
              [-17.33328, 28.177854],
              [-17.333427, 28.177995],
            ],
          ],
          [
            [
              [-17.34602, 28.145969],
              [-17.346119, 28.145938],
              [-17.346166, 28.146024],
              [-17.34611, 28.146096],
              [-17.34602, 28.145969],
            ],
          ],
          [
            [
              [-16.771627, 28.375507],
              [-16.771529, 28.375513],
              [-16.771451, 28.375439],
              [-16.771546, 28.375388],
              [-16.771627, 28.375507],
            ],
          ],
          [
            [
              [-16.265783, 28.574629],
              [-16.265922, 28.574632],
              [-16.265868, 28.574743],
              [-16.265756, 28.574701],
              [-16.265783, 28.574629],
            ],
          ],
          [
            [
              [-17.338628, 28.158613],
              [-17.338719, 28.158597],
              [-17.338742, 28.158693],
              [-17.338581, 28.158688],
              [-17.338628, 28.158613],
            ],
          ],
          [
            [
              [-18.134451, 27.77028],
              [-18.134506, 27.770253],
              [-18.134593, 27.770345],
              [-18.134473, 27.770385],
              [-18.134451, 27.77028],
            ],
          ],
          [
            [
              [-16.125717, 28.543646],
              [-16.125593, 28.543672],
              [-16.125442, 28.543608],
              [-16.125559, 28.543591],
              [-16.125717, 28.543646],
            ],
          ],
          [
            [
              [-16.741137, 28.380017],
              [-16.7411, 28.380136],
              [-16.741004, 28.38009],
              [-16.74103, 28.380049],
              [-16.741137, 28.380017],
            ],
          ],
          [
            [
              [-17.347987, 28.113352],
              [-17.348052, 28.113318],
              [-17.348135, 28.113399],
              [-17.347982, 28.113448],
              [-17.347987, 28.113352],
            ],
          ],
          [
            [
              [-17.967298, 27.678719],
              [-17.96741, 27.678718],
              [-17.967445, 27.678811],
              [-17.967246, 27.67878],
              [-17.967298, 27.678719],
            ],
          ],
          [
            [
              [-17.197449, 28.199862],
              [-17.197275, 28.199883],
              [-17.197239, 28.199799],
              [-17.197505, 28.19979],
              [-17.197449, 28.199862],
            ],
          ],
          [
            [
              [-16.205853, 28.572784],
              [-16.205758, 28.572741],
              [-16.205758, 28.572598],
              [-16.205812, 28.572611],
              [-16.205853, 28.572784],
            ],
          ],
          [
            [
              [-17.273937, 28.215455],
              [-17.273965, 28.215327],
              [-17.274064, 28.215358],
              [-17.273987, 28.215508],
              [-17.273937, 28.215455],
            ],
          ],
          [
            [
              [-16.122907, 28.560787],
              [-16.12301, 28.560783],
              [-16.12305, 28.560863],
              [-16.122909, 28.560867],
              [-16.122907, 28.560787],
            ],
          ],
          [
            [
              [-17.8305, 28.466125],
              [-17.830487, 28.46619],
              [-17.830338, 28.466104],
              [-17.830566, 28.466059],
              [-17.8305, 28.466125],
            ],
          ],
          [
            [
              [-17.346239, 28.146737],
              [-17.346324, 28.146692],
              [-17.346382, 28.146722],
              [-17.346281, 28.14683],
              [-17.346239, 28.146737],
            ],
          ],
          [
            [
              [-16.120482, 28.558865],
              [-16.120389, 28.558896],
              [-16.120279, 28.558791],
              [-16.120382, 28.558789],
              [-16.120482, 28.558865],
            ],
          ],
          [
            [
              [-16.124559, 28.562096],
              [-16.124513, 28.562122],
              [-16.124408, 28.562028],
              [-16.124555, 28.562021],
              [-16.124559, 28.562096],
            ],
          ],
          [
            [
              [-17.878428, 28.517461],
              [-17.878342, 28.517536],
              [-17.878364, 28.517325],
              [-17.878428, 28.517461],
            ],
          ],
          [
            [
              [-16.126111, 28.569182],
              [-16.126032, 28.569134],
              [-16.126066, 28.569043],
              [-16.126165, 28.569091],
              [-16.126111, 28.569182],
            ],
          ],
          [
            [
              [-16.136863, 28.584914],
              [-16.136748, 28.584941],
              [-16.136704, 28.584867],
              [-16.136802, 28.584834],
              [-16.136863, 28.584914],
            ],
          ],
          [
            [
              [-16.165716, 28.587875],
              [-16.165773, 28.587858],
              [-16.165869, 28.587899],
              [-16.165728, 28.587995],
              [-16.165716, 28.587875],
            ],
          ],
          [
            [
              [-17.875344, 28.493293],
              [-17.875558, 28.493219],
              [-17.875575, 28.493265],
              [-17.875319, 28.493336],
              [-17.875344, 28.493293],
            ],
          ],
          [
            [
              [-18.138595, 27.766777],
              [-18.138642, 27.766755],
              [-18.138749, 27.766814],
              [-18.138598, 27.766879],
              [-18.138595, 27.766777],
            ],
          ],
          [
            [
              [-17.339437, 28.158299],
              [-17.339352, 28.158349],
              [-17.339287, 28.158267],
              [-17.339367, 28.158214],
              [-17.339437, 28.158299],
            ],
          ],
          [
            [
              [-16.143438, 28.585551],
              [-16.143505, 28.585636],
              [-16.143397, 28.585681],
              [-16.143373, 28.585631],
              [-16.143438, 28.585551],
            ],
          ],
          [
            [
              [-17.273829, 28.215157],
              [-17.273708, 28.215081],
              [-17.273771, 28.215004],
              [-17.273857, 28.21511],
              [-17.273829, 28.215157],
            ],
          ],
          [
            [
              [-18.006064, 28.783331],
              [-18.006024, 28.783376],
              [-18.005898, 28.783277],
              [-18.005941, 28.783215],
              [-18.006064, 28.783331],
            ],
          ],
          [
            [
              [-16.161438, 28.60413],
              [-16.161518, 28.604109],
              [-16.161576, 28.604184],
              [-16.161498, 28.604228],
              [-16.161438, 28.60413],
            ],
          ],
          [
            [
              [-16.202657, 28.57404],
              [-16.20251, 28.574034],
              [-16.202553, 28.573893],
              [-16.202594, 28.573922],
              [-16.202657, 28.57404],
            ],
          ],
          [
            [
              [-16.119853, 28.554416],
              [-16.119905, 28.554432],
              [-16.119781, 28.55456],
              [-16.119778, 28.554473],
              [-16.119853, 28.554416],
            ],
          ],
          [
            [
              [-17.876987, 28.516636],
              [-17.876895, 28.516662],
              [-17.876795, 28.516615],
              [-17.876891, 28.516559],
              [-17.876987, 28.516636],
            ],
          ],
          [
            [
              [-16.134591, 28.577864],
              [-16.134684, 28.577883],
              [-16.134575, 28.577991],
              [-16.134542, 28.577952],
              [-16.134591, 28.577864],
            ],
          ],
          [
            [
              [-16.173425, 28.583683],
              [-16.173476, 28.583673],
              [-16.173577, 28.583763],
              [-16.173481, 28.583788],
              [-16.173425, 28.583683],
            ],
          ],
          [
            [
              [-16.125486, 28.567696],
              [-16.125327, 28.567659],
              [-16.125249, 28.567603],
              [-16.125361, 28.567598],
              [-16.125486, 28.567696],
            ],
          ],
          [
            [
              [-17.876773, 28.501951],
              [-17.87669, 28.502004],
              [-17.876481, 28.501956],
              [-17.876667, 28.501924],
              [-17.876773, 28.501951],
            ],
          ],
          [
            [
              [-17.292364, 28.209753],
              [-17.292263, 28.209773],
              [-17.292312, 28.209623],
              [-17.292374, 28.209699],
              [-17.292364, 28.209753],
            ],
          ],
          [
            [
              [-16.380734, 28.546794],
              [-16.380722, 28.546675],
              [-16.380815, 28.546695],
              [-16.380753, 28.546905],
              [-16.380734, 28.546794],
            ],
          ],
          [
            [
              [-16.121954, 28.559709],
              [-16.121853, 28.559632],
              [-16.121678, 28.559633],
              [-16.121873, 28.559588],
              [-16.121954, 28.559709],
            ],
          ],
          [
            [
              [-16.126203, 28.566261],
              [-16.126306, 28.566272],
              [-16.126319, 28.566363],
              [-16.126214, 28.56635],
              [-16.126203, 28.566261],
            ],
          ],
          [
            [
              [-16.696419, 28.001172],
              [-16.696533, 28.001167],
              [-16.696566, 28.001213],
              [-16.696497, 28.00128],
              [-16.696419, 28.001172],
            ],
          ],
          [
            [
              [-16.238344, 28.569985],
              [-16.238438, 28.569998],
              [-16.238459, 28.570123],
              [-16.238363, 28.570082],
              [-16.238344, 28.569985],
            ],
          ],
          [
            [
              [-17.199521, 28.199745],
              [-17.199675, 28.199742],
              [-17.199394, 28.199867],
              [-17.199431, 28.19978],
              [-17.199521, 28.199745],
            ],
          ],
          [
            [
              [-17.286425, 28.211532],
              [-17.286478, 28.211657],
              [-17.286381, 28.211671],
              [-17.286425, 28.211532],
            ],
          ],
          [
            [
              [-17.287444, 28.03845],
              [-17.287186, 28.038532],
              [-17.287157, 28.038494],
              [-17.287419, 28.03843],
              [-17.287444, 28.03845],
            ],
          ],
          [
            [
              [-17.816993, 28.840086],
              [-17.81693, 28.84],
              [-17.816922, 28.839917],
              [-17.817041, 28.840102],
              [-17.816993, 28.840086],
            ],
          ],
          [
            [
              [-16.269354, 28.576192],
              [-16.269416, 28.576151],
              [-16.269368, 28.576319],
              [-16.269306, 28.576293],
              [-16.269354, 28.576192],
            ],
          ],
          [
            [
              [-17.283459, 28.2124],
              [-17.283533, 28.212472],
              [-17.283312, 28.212469],
              [-17.283409, 28.212415],
              [-17.283459, 28.2124],
            ],
          ],
          [
            [
              [-17.329431, 28.187004],
              [-17.329344, 28.186943],
              [-17.329355, 28.186833],
              [-17.329454, 28.186922],
              [-17.329431, 28.187004],
            ],
          ],
          [
            [
              [-16.201018, 28.574755],
              [-16.200944, 28.574755],
              [-16.200927, 28.574657],
              [-16.201034, 28.574664],
              [-16.201018, 28.574755],
            ],
          ],
          [
            [
              [-18.006785, 28.768587],
              [-18.006878, 28.768544],
              [-18.006894, 28.768638],
              [-18.006829, 28.768656],
              [-18.006785, 28.768587],
            ],
          ],
          [
            [
              [-17.331891, 28.182514],
              [-17.331793, 28.182493],
              [-17.331846, 28.182388],
              [-17.331913, 28.182468],
              [-17.331891, 28.182514],
            ],
          ],
          [
            [
              [-16.67577, 27.999772],
              [-16.675836, 27.999711],
              [-16.675902, 27.999719],
              [-16.675846, 27.999827],
              [-16.67577, 27.999772],
            ],
          ],
          [
            [
              [-17.290992, 28.209792],
              [-17.290926, 28.209858],
              [-17.290883, 28.209736],
              [-17.290992, 28.209744],
              [-17.290992, 28.209792],
            ],
          ],
          [
            [
              [-17.345961, 28.121044],
              [-17.346029, 28.121087],
              [-17.345977, 28.121178],
              [-17.345911, 28.121117],
              [-17.345961, 28.121044],
            ],
          ],
          [
            [
              [-17.964065, 28.669747],
              [-17.964125, 28.669733],
              [-17.96421, 28.669752],
              [-17.964145, 28.669852],
              [-17.964065, 28.669747],
            ],
          ],
          [
            [
              [-17.332754, 28.178293],
              [-17.332709, 28.178321],
              [-17.332618, 28.178282],
              [-17.332676, 28.178214],
              [-17.332754, 28.178293],
            ],
          ],
          [
            [
              [-16.241274, 28.570796],
              [-16.241339, 28.570783],
              [-16.241446, 28.570791],
              [-16.241278, 28.570861],
              [-16.241274, 28.570796],
            ],
          ],
          [
            [
              [-16.14792, 28.585754],
              [-16.147799, 28.585784],
              [-16.147767, 28.585741],
              [-16.147848, 28.585701],
              [-16.14792, 28.585754],
            ],
          ],
          [
            [
              [-17.342289, 28.155931],
              [-17.342465, 28.155935],
              [-17.342477, 28.155979],
              [-17.342193, 28.155965],
              [-17.342289, 28.155931],
            ],
          ],
          [
            [
              [-17.346444, 28.15176],
              [-17.34639, 28.151849],
              [-17.34627, 28.151903],
              [-17.346404, 28.151722],
              [-17.346444, 28.15176],
            ],
          ],
          [
            [
              [-17.335493, 28.175574],
              [-17.335382, 28.175564],
              [-17.335364, 28.175504],
              [-17.335415, 28.175458],
              [-17.335493, 28.175574],
            ],
          ],
          [
            [
              [-16.161155, 28.593237],
              [-16.161256, 28.593244],
              [-16.161124, 28.593342],
              [-16.161084, 28.593312],
              [-16.161155, 28.593237],
            ],
          ],
          [
            [
              [-17.847992, 28.836822],
              [-17.847892, 28.836783],
              [-17.847865, 28.836701],
              [-17.847999, 28.836771],
              [-17.847992, 28.836822],
            ],
          ],
          [
            [
              [-16.125309, 28.567375],
              [-16.125408, 28.567389],
              [-16.125405, 28.567446],
              [-16.125288, 28.567466],
              [-16.125309, 28.567375],
            ],
          ],
          [
            [
              [-16.186797, 28.575756],
              [-16.18686, 28.575745],
              [-16.186981, 28.575764],
              [-16.186782, 28.575803],
              [-16.186797, 28.575756],
            ],
          ],
          [
            [
              [-17.336268, 28.171279],
              [-17.336136, 28.171294],
              [-17.336054, 28.171247],
              [-17.33607, 28.171193],
              [-17.336268, 28.171279],
            ],
          ],
          [
            [
              [-17.329788, 28.080352],
              [-17.329692, 28.080399],
              [-17.329632, 28.080279],
              [-17.329788, 28.080352],
            ],
          ],
          [
            [
              [-16.12277, 28.561139],
              [-16.122852, 28.561197],
              [-16.122693, 28.561204],
              [-16.12272, 28.561165],
              [-16.12277, 28.561139],
            ],
          ],
          [
            [
              [-16.191213, 28.57604],
              [-16.191109, 28.576072],
              [-16.191088, 28.575981],
              [-16.191181, 28.575972],
              [-16.191213, 28.57604],
            ],
          ],
          [
            [
              [-17.238321, 28.203818],
              [-17.238372, 28.203808],
              [-17.238406, 28.203879],
              [-17.238262, 28.203897],
              [-17.238321, 28.203818],
            ],
          ],
          [
            [
              [-17.963509, 28.66734],
              [-17.963389, 28.667316],
              [-17.963369, 28.66724],
              [-17.963459, 28.667225],
              [-17.963509, 28.66734],
            ],
          ],
          [
            [
              [-17.342477, 28.153689],
              [-17.342407, 28.153671],
              [-17.342377, 28.153527],
              [-17.342454, 28.153559],
              [-17.342477, 28.153689],
            ],
          ],
          [
            [
              [-17.329405, 28.18728],
              [-17.329319, 28.187322],
              [-17.329297, 28.187227],
              [-17.329342, 28.187203],
              [-17.329405, 28.18728],
            ],
          ],
          [
            [
              [-16.133883, 28.580963],
              [-16.134006, 28.58096],
              [-16.134058, 28.581004],
              [-16.133944, 28.581031],
              [-16.133883, 28.580963],
            ],
          ],
          [
            [
              [-17.876971, 28.506746],
              [-17.87693, 28.506786],
              [-17.876857, 28.506712],
              [-17.876952, 28.506671],
              [-17.876971, 28.506746],
            ],
          ],
          [
            [
              [-17.289405, 28.210839],
              [-17.289313, 28.210857],
              [-17.28931, 28.210733],
              [-17.289363, 28.210745],
              [-17.289405, 28.210839],
            ],
          ],
          [
            [
              [-17.876735, 28.516811],
              [-17.876602, 28.516793],
              [-17.876508, 28.516715],
              [-17.876652, 28.516731],
              [-17.876735, 28.516811],
            ],
          ],
          [
            [
              [-17.964484, 28.668055],
              [-17.964381, 28.668085],
              [-17.964359, 28.66798],
              [-17.964474, 28.667989],
              [-17.964484, 28.668055],
            ],
          ],
          [
            [
              [-16.243934, 28.572247],
              [-16.244006, 28.572245],
              [-16.244045, 28.572322],
              [-16.243936, 28.572335],
              [-16.243934, 28.572247],
            ],
          ],
          [
            [
              [-17.273674, 28.214606],
              [-17.273775, 28.214633],
              [-17.273751, 28.21469],
              [-17.273645, 28.214688],
              [-17.273674, 28.214606],
            ],
          ],
          [
            [
              [-16.175609, 28.582039],
              [-16.175684, 28.582057],
              [-16.175502, 28.582069],
              [-16.175553, 28.582041],
              [-16.175609, 28.582039],
            ],
          ],
          [
            [
              [-16.191788, 28.575617],
              [-16.191821, 28.575705],
              [-16.191726, 28.575739],
              [-16.191721, 28.575691],
              [-16.191788, 28.575617],
            ],
          ],
          [
            [
              [-17.859437, 28.464192],
              [-17.859358, 28.464253],
              [-17.859323, 28.464169],
              [-17.859369, 28.464131],
              [-17.859437, 28.464192],
            ],
          ],
          [
            [
              [-18.007863, 28.774646],
              [-18.00786, 28.774726],
              [-18.007739, 28.774575],
              [-18.007813, 28.7746],
              [-18.007863, 28.774646],
            ],
          ],
          [
            [
              [-16.121412, 28.560155],
              [-16.12151, 28.560177],
              [-16.121463, 28.560243],
              [-16.121411, 28.560249],
              [-16.121412, 28.560155],
            ],
          ],
          [
            [
              [-16.426676, 28.496514],
              [-16.4266, 28.496591],
              [-16.42653, 28.496549],
              [-16.426631, 28.496488],
              [-16.426676, 28.496514],
            ],
          ],
          [
            [
              [-17.333102, 28.177086],
              [-17.333199, 28.17705],
              [-17.333147, 28.177204],
              [-17.333111, 28.177157],
              [-17.333102, 28.177086],
            ],
          ],
          [
            [
              [-16.126798, 28.542837],
              [-16.126874, 28.542786],
              [-16.126897, 28.542792],
              [-16.126803, 28.542909],
              [-16.126798, 28.542837],
            ],
          ],
          [
            [
              [-17.336481, 28.166148],
              [-17.336382, 28.166176],
              [-17.336377, 28.166078],
              [-17.336476, 28.166094],
              [-17.336481, 28.166148],
            ],
          ],
          [
            [
              [-16.182531, 28.578329],
              [-16.182517, 28.578283],
              [-16.182635, 28.578416],
              [-16.182531, 28.57838],
              [-16.182531, 28.578329],
            ],
          ],
          [
            [
              [-16.205324, 28.57275],
              [-16.205244, 28.572744],
              [-16.205265, 28.572653],
              [-16.205319, 28.572662],
              [-16.205324, 28.57275],
            ],
          ],
          [
            [
              [-17.842723, 28.836193],
              [-17.842775, 28.836172],
              [-17.842793, 28.836321],
              [-17.842747, 28.836295],
              [-17.842723, 28.836193],
            ],
          ],
          [
            [
              [-17.342687, 28.154137],
              [-17.342657, 28.154175],
              [-17.342587, 28.154165],
              [-17.342634, 28.154054],
              [-17.342687, 28.154137],
            ],
          ],
          [
            [
              [-17.238588, 28.203854],
              [-17.238524, 28.203919],
              [-17.238485, 28.203824],
              [-17.238578, 28.203803],
              [-17.238588, 28.203854],
            ],
          ],
          [
            [
              [-17.75736, 28.65082],
              [-17.757265, 28.650853],
              [-17.757306, 28.650724],
              [-17.757349, 28.650767],
              [-17.75736, 28.65082],
            ],
          ],
          [
            [
              [-17.242084, 28.202971],
              [-17.242007, 28.203024],
              [-17.241954, 28.202922],
              [-17.242084, 28.202971],
            ],
          ],
          [
            [
              [-16.148517, 28.586703],
              [-16.14857, 28.586683],
              [-16.148622, 28.586738],
              [-16.148487, 28.586747],
              [-16.148517, 28.586703],
            ],
          ],
          [
            [
              [-17.335902, 28.171579],
              [-17.3359, 28.171675],
              [-17.335816, 28.171631],
              [-17.335851, 28.171595],
              [-17.335902, 28.171579],
            ],
          ],
          [
            [
              [-17.329547, 28.185011],
              [-17.329452, 28.185047],
              [-17.329403, 28.185024],
              [-17.329417, 28.18496],
              [-17.329547, 28.185011],
            ],
          ],
          [
            [
              [-17.231642, 28.201384],
              [-17.23154, 28.201399],
              [-17.231534, 28.201322],
              [-17.231638, 28.201336],
              [-17.231642, 28.201384],
            ],
          ],
          [
            [
              [-18.127543, 27.771206],
              [-18.127594, 27.771185],
              [-18.127619, 27.771276],
              [-18.127548, 27.771283],
              [-18.127543, 27.771206],
            ],
          ],
          [
            [
              [-17.337454, 28.16022],
              [-17.337372, 28.160279],
              [-17.337327, 28.160252],
              [-17.337349, 28.160185],
              [-17.337454, 28.16022],
            ],
          ],
          [
            [
              [-16.151609, 28.586906],
              [-16.151718, 28.586912],
              [-16.151714, 28.586968],
              [-16.151606, 28.586956],
              [-16.151609, 28.586906],
            ],
          ],
          [
            [
              [-16.11976, 28.551987],
              [-16.119656, 28.552009],
              [-16.119644, 28.55196],
              [-16.119744, 28.551933],
              [-16.11976, 28.551987],
            ],
          ],
          [
            [
              [-17.313826, 28.055795],
              [-17.313729, 28.055807],
              [-17.313786, 28.055692],
              [-17.313826, 28.055795],
            ],
          ],
          [
            [
              [-17.336996, 28.167858],
              [-17.336951, 28.167912],
              [-17.336905, 28.167796],
              [-17.336974, 28.167812],
              [-17.336996, 28.167858],
            ],
          ],
          [
            [
              [-16.15007, 28.587561],
              [-16.149978, 28.587594],
              [-16.149947, 28.587553],
              [-16.149982, 28.587509],
              [-16.15007, 28.587561],
            ],
          ],
          [
            [
              [-17.757227, 28.651331],
              [-17.757178, 28.651352],
              [-17.757114, 28.651311],
              [-17.757225, 28.651244],
              [-17.757227, 28.651331],
            ],
          ],
          [
            [
              [-17.346543, 28.148409],
              [-17.346537, 28.148455],
              [-17.346307, 28.148438],
              [-17.346543, 28.148409],
            ],
          ],
          [
            [
              [-16.189609, 28.576334],
              [-16.18967, 28.576349],
              [-16.189642, 28.576431],
              [-16.189587, 28.576419],
              [-16.189609, 28.576334],
            ],
          ],
          [
            [
              [-17.236668, 28.203908],
              [-17.236744, 28.203939],
              [-17.236636, 28.203999],
              [-17.236668, 28.203908],
            ],
          ],
          [
            [
              [-16.12379, 28.562222],
              [-16.123847, 28.562215],
              [-16.123895, 28.562294],
              [-16.123777, 28.56227],
              [-16.12379, 28.562222],
            ],
          ],
          [
            [
              [-16.138224, 28.583376],
              [-16.138089, 28.583418],
              [-16.138061, 28.583378],
              [-16.138123, 28.583363],
              [-16.138224, 28.583376],
            ],
          ],
          [
            [
              [-17.346771, 28.115263],
              [-17.346677, 28.115305],
              [-17.346658, 28.115262],
              [-17.346686, 28.115213],
              [-17.346771, 28.115263],
            ],
          ],
          [
            [
              [-17.336293, 28.16663],
              [-17.336252, 28.16667],
              [-17.336175, 28.166616],
              [-17.336215, 28.166574],
              [-17.336293, 28.16663],
            ],
          ],
          [
            [
              [-16.120023, 28.557099],
              [-16.120013, 28.557114],
              [-16.119857, 28.557076],
              [-16.11988, 28.557035],
              [-16.120023, 28.557099],
            ],
          ],
          [
            [
              [-17.335898, 28.162699],
              [-17.335827, 28.162702],
              [-17.335689, 28.162672],
              [-17.335793, 28.162637],
              [-17.335898, 28.162699],
            ],
          ],
          [
            [
              [-17.197594, 28.199909],
              [-17.197551, 28.199994],
              [-17.1975, 28.199931],
              [-17.197569, 28.199869],
              [-17.197594, 28.199909],
            ],
          ],
          [
            [
              [-17.87674, 28.506377],
              [-17.876697, 28.506411],
              [-17.876552, 28.506337],
              [-17.876613, 28.506328],
              [-17.87674, 28.506377],
            ],
          ],
          [
            [
              [-17.334516, 28.175602],
              [-17.334462, 28.17567],
              [-17.334416, 28.175579],
              [-17.334516, 28.175602],
            ],
          ],
          [
            [
              [-16.190525, 28.575456],
              [-16.190423, 28.575452],
              [-16.190397, 28.575409],
              [-16.190505, 28.575402],
              [-16.190525, 28.575456],
            ],
          ],
          [
            [
              [-17.239694, 28.203054],
              [-17.239662, 28.203149],
              [-17.239611, 28.203013],
              [-17.239694, 28.203054],
            ],
          ],
          [
            [
              [-17.340306, 28.156706],
              [-17.340248, 28.156789],
              [-17.340155, 28.156764],
              [-17.340306, 28.156706],
            ],
          ],
          [
            [
              [-17.33621, 28.166701],
              [-17.336263, 28.166701],
              [-17.336273, 28.166786],
              [-17.336177, 28.166736],
              [-17.33621, 28.166701],
            ],
          ],
          [
            [
              [-17.878922, 28.517376],
              [-17.878869, 28.517435],
              [-17.878788, 28.51739],
              [-17.878867, 28.517348],
              [-17.878922, 28.517376],
            ],
          ],
          [
            [
              [-16.245696, 28.571573],
              [-16.245552, 28.571593],
              [-16.245515, 28.571561],
              [-16.245623, 28.571546],
              [-16.245696, 28.571573],
            ],
          ],
          [
            [
              [-17.231723, 28.201424],
              [-17.231813, 28.201415],
              [-17.231808, 28.201462],
              [-17.231719, 28.20148],
              [-17.231723, 28.201424],
            ],
          ],
          [
            [
              [-16.123683, 28.562226],
              [-16.123569, 28.562214],
              [-16.123532, 28.562182],
              [-16.123575, 28.562153],
              [-16.123683, 28.562226],
            ],
          ],
          [
            [
              [-18.007573, 28.768474],
              [-18.007642, 28.768492],
              [-18.007627, 28.768541],
              [-18.007526, 28.768502],
              [-18.007573, 28.768474],
            ],
          ],
          [
            [
              [-16.158043, 28.590492],
              [-16.158022, 28.590533],
              [-16.157941, 28.590493],
              [-16.157959, 28.590445],
              [-16.158043, 28.590492],
            ],
          ],
          [
            [
              [-16.120345, 28.551334],
              [-16.1203, 28.551369],
              [-16.120227, 28.551315],
              [-16.120333, 28.551297],
              [-16.120345, 28.551334],
            ],
          ],
          [
            [
              [-17.238157, 28.204378],
              [-17.238063, 28.204397],
              [-17.23809, 28.204299],
              [-17.238157, 28.204378],
            ],
          ],
          [
            [
              [-16.238428, 28.570355],
              [-16.238387, 28.570434],
              [-16.238387, 28.570296],
              [-16.238428, 28.570355],
            ],
          ],
          [
            [
              [-16.181293, 28.57947],
              [-16.181278, 28.579508],
              [-16.181182, 28.579485],
              [-16.181203, 28.579439],
              [-16.181293, 28.57947],
            ],
          ],
          [
            [
              [-17.845857, 28.455857],
              [-17.845789, 28.455861],
              [-17.845761, 28.455797],
              [-17.845815, 28.455774],
              [-17.845857, 28.455857],
            ],
          ],
          [
            [
              [-16.249559, 28.572073],
              [-16.249489, 28.572117],
              [-16.249448, 28.572087],
              [-16.249482, 28.572046],
              [-16.249559, 28.572073],
            ],
          ],
          [
            [
              [-17.193953, 28.197828],
              [-17.19393, 28.197854],
              [-17.193853, 28.197805],
              [-17.1939, 28.197759],
              [-17.193953, 28.197828],
            ],
          ],
          [
            [
              [-17.264301, 28.217631],
              [-17.264264, 28.217673],
              [-17.264206, 28.217614],
              [-17.264294, 28.217584],
              [-17.264301, 28.217631],
            ],
          ],
          [
            [
              [-17.231082, 28.201288],
              [-17.231081, 28.201335],
              [-17.230993, 28.201336],
              [-17.23104, 28.201256],
              [-17.231082, 28.201288],
            ],
          ],
          [
            [
              [-16.189518, 28.575873],
              [-16.189582, 28.575896],
              [-16.189493, 28.575952],
              [-16.189475, 28.575903],
              [-16.189518, 28.575873],
            ],
          ],
          [
            [
              [-16.125293, 28.566355],
              [-16.125344, 28.566346],
              [-16.125366, 28.566388],
              [-16.125252, 28.566395],
              [-16.125293, 28.566355],
            ],
          ],
          [
            [
              [-18.008016, 28.770649],
              [-18.008004, 28.770722],
              [-18.007931, 28.770609],
              [-18.008016, 28.770649],
            ],
          ],
          [
            [
              [-17.247849, 28.204097],
              [-17.247937, 28.204088],
              [-17.247881, 28.204163],
              [-17.247849, 28.204097],
            ],
          ],
          [
            [
              [-16.209894, 28.569457],
              [-16.209862, 28.569515],
              [-16.209811, 28.56945],
              [-16.209861, 28.569422],
              [-16.209894, 28.569457],
            ],
          ],
          [
            [
              [-16.240706, 28.571129],
              [-16.240677, 28.571182],
              [-16.240622, 28.571128],
              [-16.240664, 28.571098],
              [-16.240706, 28.571129],
            ],
          ],
          [
            [
              [-16.125656, 28.564123],
              [-16.125572, 28.56415],
              [-16.125544, 28.564112],
              [-16.125586, 28.564085],
              [-16.125656, 28.564123],
            ],
          ],
          [
            [
              [-16.124933, 28.533892],
              [-16.124923, 28.533909],
              [-16.124816, 28.533922],
              [-16.12482, 28.533872],
              [-16.124933, 28.533892],
            ],
          ],
          [
            [
              [-17.253285, 28.209985],
              [-17.253199, 28.210043],
              [-17.25317, 28.209998],
              [-17.253214, 28.209972],
              [-17.253285, 28.209985],
            ],
          ],
          [
            [
              [-16.154058, 28.587004],
              [-16.154015, 28.587057],
              [-16.153966, 28.586991],
              [-16.154018, 28.586971],
              [-16.154058, 28.587004],
            ],
          ],
          [
            [
              [-17.334306, 28.175779],
              [-17.334242, 28.175848],
              [-17.334208, 28.175798],
              [-17.334306, 28.175779],
            ],
          ],
          [
            [
              [-16.242343, 28.571899],
              [-16.24241, 28.571908],
              [-16.242403, 28.571951],
              [-16.242312, 28.571938],
              [-16.242343, 28.571899],
            ],
          ],
          [
            [
              [-18.007914, 28.770221],
              [-18.007837, 28.770254],
              [-18.007754, 28.770261],
              [-18.007794, 28.770218],
              [-18.007914, 28.770221],
            ],
          ],
          [
            [
              [-17.964599, 28.668731],
              [-17.964547, 28.66874],
              [-17.964486, 28.668701],
              [-17.96456, 28.66868],
              [-17.964599, 28.668731],
            ],
          ],
          [
            [
              [-18.007423, 28.775102],
              [-18.007393, 28.775142],
              [-18.007322, 28.775149],
              [-18.007368, 28.775082],
              [-18.007423, 28.775102],
            ],
          ],
          [
            [
              [-17.341938, 28.154911],
              [-17.341999, 28.154911],
              [-17.34177, 28.154933],
              [-17.341938, 28.154911],
            ],
          ],
          [
            [
              [-17.204981, 28.198453],
              [-17.205035, 28.19844],
              [-17.205112, 28.198442],
              [-17.20498, 28.198498],
              [-17.204981, 28.198453],
            ],
          ],
          [
            [
              [-17.281246, 28.212285],
              [-17.281195, 28.212302],
              [-17.281206, 28.212208],
              [-17.281246, 28.212285],
            ],
          ],
          [
            [
              [-17.964159, 28.667566],
              [-17.964087, 28.667592],
              [-17.964052, 28.667511],
              [-17.964159, 28.667566],
            ],
          ],
          [
            [
              [-16.123258, 28.560655],
              [-16.123248, 28.560697],
              [-16.123167, 28.560646],
              [-16.123214, 28.560623],
              [-16.123258, 28.560655],
            ],
          ],
          [
            [
              [-17.335911, 28.162055],
              [-17.33585, 28.162069],
              [-17.335847, 28.161982],
              [-17.335889, 28.162009],
              [-17.335911, 28.162055],
            ],
          ],
          [
            [
              [-16.249154, 28.572064],
              [-16.249062, 28.572094],
              [-16.249048, 28.572048],
              [-16.249131, 28.572044],
              [-16.249154, 28.572064],
            ],
          ],
          [
            [
              [-16.188824, 28.576677],
              [-16.188877, 28.576659],
              [-16.188913, 28.576688],
              [-16.188824, 28.576723],
              [-16.188824, 28.576677],
            ],
          ],
          [
            [
              [-17.24502, 28.202773],
              [-17.24507, 28.202758],
              [-17.245115, 28.202778],
              [-17.245017, 28.202822],
              [-17.24502, 28.202773],
            ],
          ],
          [
            [
              [-16.152986, 28.587236],
              [-16.15298, 28.587252],
              [-16.152901, 28.587227],
              [-16.152931, 28.587187],
              [-16.152986, 28.587236],
            ],
          ],
          [
            [
              [-16.236369, 28.569968],
              [-16.236343, 28.57001],
              [-16.236309, 28.569974],
              [-16.236357, 28.569909],
              [-16.236369, 28.569968],
            ],
          ],
          [
            [
              [-17.231482, 28.201206],
              [-17.231436, 28.201233],
              [-17.231393, 28.201212],
              [-17.231459, 28.201164],
              [-17.231482, 28.201206],
            ],
          ],
          [
            [
              [-17.825312, 28.472749],
              [-17.825402, 28.47274],
              [-17.825329, 28.472797],
              [-17.825312, 28.472749],
            ],
          ],
          [
            [
              [-16.210174, 28.570166],
              [-16.210165, 28.570188],
              [-16.210103, 28.570134],
              [-16.210146, 28.570105],
              [-16.210174, 28.570166],
            ],
          ],
          [
            [
              [-16.15265, 28.587276],
              [-16.15255, 28.587261],
              [-16.15259, 28.587227],
              [-16.15265, 28.587276],
            ],
          ],
          [
            [
              [-16.204, 28.572703],
              [-16.203907, 28.572723],
              [-16.203937, 28.572676],
              [-16.204, 28.572703],
            ],
          ],
          [
            [
              [-16.154328, 28.5871],
              [-16.154271, 28.587107],
              [-16.154249, 28.587084],
              [-16.154329, 28.587054],
              [-16.154328, 28.5871],
            ],
          ],
          [
            [
              [-16.241684, 28.571663],
              [-16.241682, 28.5717],
              [-16.241607, 28.571673],
              [-16.241644, 28.57164],
              [-16.241684, 28.571663],
            ],
          ],
          [
            [
              [-17.343114, 28.152943],
              [-17.343056, 28.152953],
              [-17.342986, 28.152958],
              [-17.343072, 28.152914],
              [-17.343114, 28.152943],
            ],
          ],
          [
            [
              [-16.23759, 28.569985],
              [-16.237548, 28.570014],
              [-16.237527, 28.569944],
              [-16.23759, 28.569985],
            ],
          ],
          [
            [
              [-16.146063, 28.58634],
              [-16.145976, 28.586293],
              [-16.146063, 28.586295],
              [-16.146063, 28.58634],
            ],
          ],
          [
            [
              [-17.238216, 28.203845],
              [-17.238152, 28.203893],
              [-17.238123, 28.203844],
              [-17.238216, 28.203845],
            ],
          ],
          [
            [
              [-17.272074, 28.216339],
              [-17.272028, 28.216364],
              [-17.272007, 28.216276],
              [-17.272074, 28.216339],
            ],
          ],
          [
            [
              [-16.235972, 28.569527],
              [-16.235943, 28.569534],
              [-16.235936, 28.569444],
              [-16.235972, 28.56948],
              [-16.235972, 28.569527],
            ],
          ],
          [
            [
              [-16.158544, 28.591523],
              [-16.158462, 28.591496],
              [-16.158502, 28.591468],
              [-16.158544, 28.591523],
            ],
          ],
          [
            [
              [-17.336435, 28.166716],
              [-17.336392, 28.166746],
              [-17.336366, 28.166675],
              [-17.336435, 28.166716],
            ],
          ],
          [
            [
              [-16.151879, 28.587075],
              [-16.151825, 28.587084],
              [-16.151822, 28.587025],
              [-16.151879, 28.587075],
            ],
          ],
          [
            [
              [-16.231048, 28.566712],
              [-16.231097, 28.566709],
              [-16.231123, 28.566714],
              [-16.231032, 28.566758],
              [-16.231048, 28.566712],
            ],
          ],
          [
            [
              [-17.964549, 28.668986],
              [-17.964483, 28.669002],
              [-17.964485, 28.668945],
              [-17.964549, 28.668986],
            ],
          ],
          [
            [
              [-17.232208, 28.200965],
              [-17.23216, 28.201015],
              [-17.232172, 28.200927],
              [-17.232208, 28.200965],
            ],
          ],
          [
            [
              [-16.150295, 28.587537],
              [-16.150318, 28.587537],
              [-16.150337, 28.587582],
              [-16.150264, 28.58756],
              [-16.150295, 28.587537],
            ],
          ],
          [
            [
              [-16.151089, 28.587397],
              [-16.151071, 28.587414],
              [-16.151034, 28.58741],
              [-16.151085, 28.587352],
              [-16.151089, 28.587397],
            ],
          ],
          [
            [
              [-17.223311, 28.201878],
              [-17.22327, 28.201951],
              [-17.223253, 28.201906],
              [-17.223311, 28.201878],
            ],
          ],
          [
            [
              [-17.231295, 28.201284],
              [-17.231245, 28.201294],
              [-17.231235, 28.201233],
              [-17.231295, 28.201284],
            ],
          ],
          [
            [
              [-17.232449, 28.200906],
              [-17.232411, 28.200936],
              [-17.232386, 28.200883],
              [-17.232449, 28.200906],
            ],
          ],
          [
            [
              [-17.336774, 28.168653],
              [-17.336724, 28.168668],
              [-17.336753, 28.168611],
              [-17.336774, 28.168653],
            ],
          ],
          [
            [
              [-17.232638, 28.200739],
              [-17.232586, 28.200743],
              [-17.232634, 28.200693],
              [-17.232638, 28.200739],
            ],
          ],
          [
            [
              [-16.152741, 28.586919],
              [-16.152669, 28.586908],
              [-16.152714, 28.586886],
              [-16.152741, 28.586919],
            ],
          ],
          [
            [
              [-16.12309, 28.560685],
              [-16.123026, 28.560676],
              [-16.123072, 28.560652],
              [-16.12309, 28.560685],
            ],
          ],
          [
            [
              [-16.237318, 28.569952],
              [-16.237332, 28.569931],
              [-16.237347, 28.570009],
              [-16.237317, 28.569968],
              [-16.237318, 28.569952],
            ],
          ],
          [
            [
              [-16.173639, 28.583878],
              [-16.173588, 28.583861],
              [-16.173584, 28.583858],
              [-16.173594, 28.583843],
              [-16.173639, 28.583878],
            ],
          ],
          [
            [
              [-16.237818, 28.570109],
              [-16.237793, 28.570079],
              [-16.237797, 28.570045],
              [-16.237818, 28.570109],
            ],
          ],
          [
            [
              [-16.231362, 28.567139],
              [-16.23132, 28.567093],
              [-16.231357, 28.567112],
              [-16.231362, 28.567139],
            ],
          ],
          [
            [
              [-16.236559, 28.569883],
              [-16.236546, 28.569904],
              [-16.236545, 28.569839],
              [-16.236559, 28.569883],
            ],
          ],
          [
            [
              [-16.236751, 28.569927],
              [-16.236699, 28.569952],
              [-16.236737, 28.569918],
              [-16.236751, 28.569927],
            ],
          ],
          [
            [
              [-16.230714, 28.567138],
              [-16.230707, 28.567154],
              [-16.230682, 28.567126],
              [-16.230714, 28.567138],
            ],
          ],
          [
            [
              [-16.237147, 28.569929],
              [-16.237135, 28.569949],
              [-16.237139, 28.569893],
              [-16.237147, 28.569929],
            ],
          ],
          [
            [
              [-16.235893, 28.569897],
              [-16.235882, 28.569914],
              [-16.235875, 28.569872],
              [-16.235893, 28.569897],
            ],
          ],
          [
            [
              [-16.235783, 28.568529],
              [-16.235742, 28.568498],
              [-16.235775, 28.568509],
              [-16.235783, 28.568529],
            ],
          ],
          [
            [
              [-16.233179, 28.567047],
              [-16.233176, 28.567065],
              [-16.233168, 28.567036],
              [-16.233179, 28.567047],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5642",
      properties: { name: "Segovia", density: 0, path: "/world/Spain/Segovia" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.963827, 41.586082],
              [-3.867947, 41.532477],
              [-3.823878, 41.481134],
              [-3.787641, 41.485865],
              [-3.803788, 41.524576],
              [-3.766189, 41.537929],
              [-3.769752, 41.460152],
              [-3.739483, 41.450784],
              [-3.723719, 41.479205],
              [-3.728305, 41.517638],
              [-3.671723, 41.560433],
              [-3.616836, 41.577158],
              [-3.572622, 41.565399],
              [-3.546423, 41.580088],
              [-3.53833, 41.552016],
              [-3.50194, 41.529183],
              [-3.479678, 41.52091],
              [-3.458061, 41.536849],
              [-3.423675, 41.516282],
              [-3.413305, 41.47612],
              [-3.330375, 41.460721],
              [-3.350532, 41.39487],
              [-3.207351, 41.304236],
              [-3.238935, 41.287943],
              [-3.254264, 41.258383],
              [-3.274265, 41.260265],
              [-3.275759, 41.275034],
              [-3.290168, 41.25793],
              [-3.33635, 41.253031],
              [-3.409779, 41.260623],
              [-3.415147, 41.273197],
              [-3.411523, 41.260707],
              [-3.3995, 41.254543],
              [-3.402486, 41.213619],
              [-3.444154, 41.21534],
              [-3.466915, 41.183264],
              [-3.516222, 41.1623],
              [-3.613703, 41.149053],
              [-3.676065, 41.086696],
              [-3.740455, 41.05364],
              [-3.781366, 41.000312],
              [-3.890455, 40.969029],
              [-3.937949, 40.92718],
              [-3.979676, 40.784555],
              [-4.070092, 40.793915],
              [-4.147113, 40.693966],
              [-4.252903, 40.689533],
              [-4.316194, 40.66675],
              [-4.332631, 40.637131],
              [-4.419623, 40.633428],
              [-4.431127, 40.742384],
              [-4.473838, 40.774654],
              [-4.493773, 40.833518],
              [-4.546388, 40.825547],
              [-4.556708, 40.836334],
              [-4.531567, 40.858642],
              [-4.559565, 40.885849],
              [-4.566352, 40.956199],
              [-4.606748, 40.970803],
              [-4.638289, 41.031306],
              [-4.71272, 41.062102],
              [-4.721337, 41.156968],
              [-4.680561, 41.185737],
              [-4.627752, 41.170795],
              [-4.650863, 41.221421],
              [-4.573882, 41.275201],
              [-4.601978, 41.302654],
              [-4.596849, 41.320587],
              [-4.55954, 41.302166],
              [-4.528628, 41.321099],
              [-4.467786, 41.295887],
              [-4.470551, 41.31846],
              [-4.504473, 41.347196],
              [-4.484001, 41.366801],
              [-4.514958, 41.409659],
              [-4.468159, 41.413076],
              [-4.410616, 41.441647],
              [-4.364915, 41.436002],
              [-4.362393, 41.449931],
              [-4.270132, 41.461274],
              [-4.2611, 41.450591],
              [-4.158318, 41.4821],
              [-4.142485, 41.469936],
              [-4.027399, 41.517797],
              [-3.999904, 41.516103],
              [-3.980667, 41.583373],
              [-3.963827, 41.586082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5643",
      properties: { name: "Sevilla", density: 0, path: "/world/Spain/Sevilla" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.725076, 38.195777],
              [-5.688612, 38.181877],
              [-5.684062, 38.157978],
              [-5.705786, 38.169382],
              [-5.695258, 38.149712],
              [-5.738816, 38.132819],
              [-5.7349, 38.086255],
              [-5.693631, 38.08374],
              [-5.632501, 38.138557],
              [-5.584789, 38.131706],
              [-5.495125, 38.041844],
              [-5.493153, 37.99576],
              [-5.415611, 37.943674],
              [-5.414709, 37.869461],
              [-5.339317, 37.833009],
              [-5.350044, 37.794714],
              [-5.310035, 37.77423],
              [-5.307065, 37.70016],
              [-5.327099, 37.69162],
              [-5.344837, 37.705344],
              [-5.376343, 37.677562],
              [-5.389077, 37.697778],
              [-5.414201, 37.674459],
              [-5.393599, 37.656018],
              [-5.414016, 37.645275],
              [-5.359933, 37.605448],
              [-5.357958, 37.584843],
              [-5.176768, 37.680577],
              [-5.150054, 37.660917],
              [-5.10792, 37.662578],
              [-5.12971, 37.698747],
              [-5.108612, 37.700667],
              [-5.097878, 37.670291],
              [-5.069878, 37.65935],
              [-5.091506, 37.679948],
              [-5.072582, 37.707695],
              [-5.047227, 37.718294],
              [-4.999821, 37.70659],
              [-4.953766, 37.652443],
              [-4.974282, 37.600157],
              [-4.931707, 37.617326],
              [-4.932025, 37.545909],
              [-4.948935, 37.521947],
              [-4.90602, 37.499837],
              [-4.915201, 37.48416],
              [-4.904468, 37.489489],
              [-4.904089, 37.467405],
              [-4.875342, 37.450453],
              [-4.88474, 37.437983],
              [-4.840635, 37.436019],
              [-4.84981, 37.410659],
              [-4.814688, 37.377532],
              [-4.822126, 37.358195],
              [-4.782224, 37.328502],
              [-4.729224, 37.335327],
              [-4.718667, 37.358499],
              [-4.685656, 37.330268],
              [-4.671179, 37.334493],
              [-4.668439, 37.319805],
              [-4.68407, 37.315357],
              [-4.660614, 37.294445],
              [-4.67525, 37.301783],
              [-4.681092, 37.290046],
              [-4.662116, 37.283744],
              [-4.65335, 37.255828],
              [-4.730035, 37.253462],
              [-4.735745, 37.244835],
              [-4.717945, 37.239894],
              [-4.727209, 37.211108],
              [-4.708606, 37.197696],
              [-4.792632, 37.153851],
              [-4.825906, 37.200705],
              [-4.853864, 37.198585],
              [-4.8755, 37.158193],
              [-4.897618, 37.156029],
              [-4.905113, 37.181865],
              [-4.939603, 37.175699],
              [-4.942665, 37.157588],
              [-4.903311, 37.138786],
              [-4.908368, 37.11465],
              [-5.053089, 37.030604],
              [-5.12284, 37.027691],
              [-5.162637, 36.981423],
              [-5.2259, 36.941843],
              [-5.300224, 37.013742],
              [-5.302105, 37.037363],
              [-5.392391, 36.973284],
              [-5.412849, 36.940661],
              [-5.43895, 36.935288],
              [-5.460287, 36.975303],
              [-5.420108, 37.013214],
              [-5.433688, 37.052445],
              [-5.510986, 37.033603],
              [-5.469732, 36.983948],
              [-5.546116, 36.930182],
              [-5.533822, 36.89345],
              [-5.583404, 36.915092],
              [-5.587108, 36.947013],
              [-5.618088, 36.940161],
              [-5.667567, 36.97599],
              [-5.713768, 36.920386],
              [-5.845508, 36.915212],
              [-5.87652, 36.894711],
              [-5.891055, 36.842192],
              [-6.104447, 36.857256],
              [-6.156153, 36.885649],
              [-6.253793, 36.893907],
              [-6.278289, 36.910848],
              [-6.331009, 36.898803],
              [-6.378893, 36.934706],
              [-6.353943, 36.988492],
              [-6.386387, 37.074434],
              [-6.34236, 37.122977],
              [-6.359674, 37.138426],
              [-6.336117, 37.169617],
              [-6.350561, 37.180562],
              [-6.345245, 37.218283],
              [-6.359846, 37.238096],
              [-6.333, 37.299104],
              [-6.384571, 37.337489],
              [-6.346038, 37.365732],
              [-6.36208, 37.375881],
              [-6.356259, 37.412166],
              [-6.312632, 37.418722],
              [-6.306054, 37.433015],
              [-6.356184, 37.496173],
              [-6.366478, 37.535757],
              [-6.392401, 37.554051],
              [-6.395257, 37.611187],
              [-6.443271, 37.623354],
              [-6.528622, 37.615795],
              [-6.536089, 37.650976],
              [-6.501342, 37.667959],
              [-6.475123, 37.725141],
              [-6.449994, 37.718898],
              [-6.311022, 37.764869],
              [-6.252318, 37.763145],
              [-6.252424, 37.74278],
              [-6.222234, 37.739655],
              [-6.202397, 37.805534],
              [-6.134016, 37.787832],
              [-6.12166, 37.816595],
              [-6.14216, 37.855674],
              [-6.164294, 37.86201],
              [-6.124902, 37.904417],
              [-6.145213, 37.920498],
              [-6.174994, 37.915094],
              [-6.184066, 37.928994],
              [-6.167815, 37.955822],
              [-6.051709, 37.996842],
              [-5.953688, 37.995346],
              [-5.934224, 38.046875],
              [-5.908982, 38.062846],
              [-5.926378, 38.105013],
              [-5.880665, 38.132281],
              [-5.874684, 38.15834],
              [-5.725076, 38.195777],
            ],
            [
              [-5.025996, 37.599569],
              [-5.031191, 37.641521],
              [-5.046795, 37.631942],
              [-5.084322, 37.648083],
              [-5.040405, 37.626195],
              [-5.04791, 37.60814],
              [-5.031007, 37.610604],
              [-5.026597, 37.595422],
              [-5.017785, 37.592464],
              [-5.025996, 37.599569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5635",
      properties: { name: "Soria", density: 0, path: "/world/Spain/Soria" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.344003, 42.145502],
              [-2.28216, 42.131703],
              [-2.269589, 42.088555],
              [-2.208883, 42.104661],
              [-2.127324, 42.097169],
              [-2.12724, 42.080462],
              [-2.161913, 42.066734],
              [-2.107632, 42.002774],
              [-2.124072, 41.995309],
              [-2.109961, 41.957868],
              [-2.036392, 41.939404],
              [-2.028314, 41.953386],
              [-1.970298, 41.919142],
              [-1.855916, 41.966297],
              [-1.857281, 41.909515],
              [-1.817601, 41.85338],
              [-1.819054, 41.828753],
              [-1.852714, 41.788878],
              [-1.805853, 41.755084],
              [-1.809213, 41.736664],
              [-1.775372, 41.724789],
              [-1.812074, 41.686454],
              [-1.806224, 41.664263],
              [-1.858991, 41.66153],
              [-1.9231, 41.598335],
              [-1.993238, 41.60051],
              [-1.993686, 41.577087],
              [-1.967335, 41.546067],
              [-1.981112, 41.486435],
              [-1.953276, 41.462451],
              [-1.953686, 41.424263],
              [-1.937295, 41.407854],
              [-2.02927, 41.384158],
              [-2.043575, 41.397149],
              [-2.035623, 41.426889],
              [-2.10152, 41.445789],
              [-2.116767, 41.424947],
              [-2.116033, 41.382047],
              [-2.163137, 41.35535],
              [-2.173671, 41.288085],
              [-2.146242, 41.184395],
              [-2.079896, 41.170984],
              [-2.051693, 41.146849],
              [-2.065622, 41.095789],
              [-2.048179, 41.078378],
              [-2.056434, 41.069691],
              [-2.131855, 41.118098],
              [-2.175298, 41.083755],
              [-2.232147, 41.097011],
              [-2.263544, 41.067535],
              [-2.323407, 41.056508],
              [-2.355329, 41.086454],
              [-2.41891, 41.057706],
              [-2.469466, 41.07811],
              [-2.482261, 41.116272],
              [-2.508851, 41.118857],
              [-2.53651, 41.162525],
              [-2.575577, 41.139472],
              [-2.595314, 41.150253],
              [-2.577493, 41.178663],
              [-2.622066, 41.187988],
              [-2.593741, 41.200205],
              [-2.589486, 41.224538],
              [-2.629198, 41.215215],
              [-2.646015, 41.24021],
              [-2.709546, 41.251981],
              [-2.716772, 41.275671],
              [-2.760946, 41.274024],
              [-2.775511, 41.250084],
              [-2.822245, 41.256615],
              [-2.866888, 41.274465],
              [-2.866846, 41.313072],
              [-2.888425, 41.325238],
              [-2.941191, 41.313936],
              [-2.950131, 41.292637],
              [-3.061901, 41.273427],
              [-3.207351, 41.304236],
              [-3.350532, 41.39487],
              [-3.330375, 41.460721],
              [-3.413305, 41.47612],
              [-3.421406, 41.513867],
              [-3.451518, 41.533857],
              [-3.479678, 41.52091],
              [-3.53833, 41.552016],
              [-3.541441, 41.608167],
              [-3.459682, 41.58927],
              [-3.393397, 41.690613],
              [-3.358397, 41.693421],
              [-3.354385, 41.737254],
              [-3.275454, 41.768671],
              [-3.283758, 41.88381],
              [-3.243342, 41.863997],
              [-3.234389, 41.840642],
              [-3.194496, 41.828926],
              [-3.171351, 41.793015],
              [-3.136198, 41.811942],
              [-3.097165, 41.881058],
              [-3.016771, 41.886906],
              [-2.999437, 41.931817],
              [-2.970792, 41.929273],
              [-2.965802, 41.966038],
              [-2.93217, 42.017974],
              [-2.883826, 42.009487],
              [-2.797986, 42.046844],
              [-2.803344, 42.080484],
              [-2.774851, 42.123536],
              [-2.734666, 42.124954],
              [-2.705947, 42.103556],
              [-2.757584, 42.032616],
              [-2.746167, 42.008678],
              [-2.579909, 41.996264],
              [-2.549067, 42.05151],
              [-2.51488, 42.068861],
              [-2.514676, 42.114542],
              [-2.484369, 42.106349],
              [-2.440499, 42.136783],
              [-2.344003, 42.145502],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5645",
      properties: {
        name: "Tarragona",
        density: 0,
        path: "/world/Spain/Tarragona",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.438562, 41.575683],
              [1.454483, 41.569186],
              [1.431028, 41.555661],
              [1.448583, 41.537072],
              [1.408723, 41.534928],
              [1.396347, 41.512893],
              [1.484562, 41.474922],
              [1.468484, 41.443963],
              [1.495201, 41.435609],
              [1.475096, 41.429452],
              [1.490165, 41.401483],
              [1.482457, 41.398606],
              [1.47304, 41.380758],
              [1.509289, 41.394439],
              [1.536815, 41.385359],
              [1.57796, 41.362457],
              [1.558659, 41.343146],
              [1.595505, 41.308848],
              [1.588222, 41.293562],
              [1.636791, 41.295952],
              [1.619705, 41.2537],
              [1.570156, 41.260734],
              [1.583106, 41.236078],
              [1.624011, 41.222941],
              [1.640979, 41.235366],
              [1.644745, 41.193445],
              [1.51333, 41.17799],
              [1.394896, 41.131624],
              [1.297926, 41.127772],
              [1.220765, 41.083572],
              [1.249113, 41.108642],
              [1.215042, 41.104232],
              [1.170341, 41.054633],
              [1.123358, 41.074707],
              [1.049143, 41.064116],
              [0.966646, 41.02835],
              [0.862229, 40.947079],
              [0.741336, 40.823325],
              [0.699722, 40.804524],
              [0.730349, 40.769486],
              [0.780552, 40.768281],
              [0.730246, 40.789211],
              [0.765872, 40.792285],
              [0.828572, 40.734275],
              [0.871989, 40.734932],
              [0.880597, 40.718468],
              [0.863565, 40.689872],
              [0.75829, 40.641462],
              [0.702068, 40.580439],
              [0.661356, 40.557333],
              [0.617641, 40.553552],
              [0.594986, 40.578091],
              [0.648944, 40.572938],
              [0.657041, 40.578256],
              [0.660325, 40.591471],
              [0.708682, 40.59089],
              [0.745234, 40.636115],
              [0.601331, 40.622103],
              [0.604591, 40.609105],
              [0.590445, 40.612842],
              [0.537918, 40.567229],
              [0.514702, 40.523047],
              [0.437964, 40.54706],
              [0.443944, 40.578223],
              [0.401962, 40.602381],
              [0.282267, 40.627592],
              [0.267672, 40.658159],
              [0.29184, 40.688553],
              [0.262431, 40.705852],
              [0.236523, 40.701683],
              [0.226115, 40.733164],
              [0.197291, 40.724169],
              [0.164486, 40.740115],
              [0.159181, 40.750608],
              [0.175357, 40.741131],
              [0.175222, 40.754941],
              [0.229967, 40.767433],
              [0.23357, 40.786993],
              [0.278194, 40.820901],
              [0.238908, 40.884704],
              [0.293501, 40.970561],
              [0.258635, 41.01463],
              [0.279658, 41.020167],
              [0.217744, 41.043655],
              [0.230447, 41.055296],
              [0.220077, 41.082911],
              [0.201202, 41.084681],
              [0.201225, 41.126775],
              [0.247879, 41.130142],
              [0.25299, 41.150153],
              [0.303318, 41.16438],
              [0.322404, 41.225241],
              [0.381311, 41.23643],
              [0.385664, 41.278844],
              [0.43315, 41.276041],
              [0.44174, 41.32561],
              [0.469146, 41.331037],
              [0.503274, 41.28973],
              [0.525808, 41.290455],
              [0.554427, 41.326666],
              [0.580777, 41.320058],
              [0.597064, 41.293449],
              [0.62819, 41.290861],
              [0.751522, 41.317837],
              [0.762477, 41.334247],
              [0.819496, 41.328828],
              [0.845003, 41.362306],
              [0.863267, 41.338113],
              [0.945229, 41.349757],
              [1.074934, 41.458397],
              [1.037455, 41.479992],
              [1.048504, 41.487543],
              [1.039751, 41.50111],
              [1.112176, 41.467013],
              [1.180946, 41.488113],
              [1.164653, 41.534456],
              [1.210764, 41.548604],
              [1.219863, 41.569583],
              [1.391906, 41.556435],
              [1.403447, 41.558163],
              [1.395469, 41.577495],
              [1.438562, 41.575683],
            ],
            [
              [0.737639, 40.637919],
              [0.736285, 40.638864],
              [0.734714, 40.63932],
              [0.735671, 40.63783],
              [0.737639, 40.637919],
            ],
            [
              [0.707627, 40.635232],
              [0.707528, 40.635569],
              [0.707279, 40.635342],
              [0.70732, 40.635233],
              [0.707627, 40.635232],
            ],
          ],
          [
            [
              [1.633235, 41.313491],
              [1.640908, 41.31256],
              [1.6425, 41.312955],
              [1.637479, 41.309682],
              [1.628974, 41.310655],
              [1.633235, 41.313491],
            ],
          ],
          [
            [
              [0.651508, 40.58533],
              [0.654291, 40.584368],
              [0.65573, 40.581296],
              [0.648974, 40.584347],
              [0.651508, 40.58533],
            ],
          ],
          [
            [
              [0.635467, 40.579893],
              [0.634162, 40.578907],
              [0.634883, 40.577389],
              [0.633496, 40.57991],
              [0.635467, 40.579893],
            ],
          ],
          [
            [
              [0.648413, 40.584446],
              [0.647983, 40.58328],
              [0.645868, 40.583698],
              [0.64611, 40.584672],
              [0.648413, 40.584446],
            ],
          ],
          [
            [
              [0.646268, 40.576715],
              [0.647376, 40.575599],
              [0.643932, 40.576554],
              [0.6445, 40.576789],
              [0.646268, 40.576715],
            ],
          ],
          [
            [
              [0.648894, 40.578523],
              [0.648312, 40.578911],
              [0.648126, 40.580267],
              [0.64933, 40.580031],
              [0.648894, 40.578523],
            ],
          ],
          [
            [
              [0.649112, 40.575148],
              [0.648328, 40.575074],
              [0.647628, 40.576095],
              [0.648574, 40.576345],
              [0.649112, 40.575148],
            ],
          ],
          [
            [
              [0.650241, 40.576157],
              [0.649054, 40.576266],
              [0.64898, 40.577061],
              [0.649687, 40.577264],
              [0.650241, 40.576157],
            ],
          ],
          [
            [
              [0.642506, 40.57596],
              [0.642895, 40.575074],
              [0.641265, 40.576585],
              [0.641629, 40.577204],
              [0.642506, 40.57596],
            ],
          ],
          [
            [
              [0.645833, 40.582186],
              [0.645018, 40.58241],
              [0.644954, 40.583187],
              [0.646386, 40.582592],
              [0.645833, 40.582186],
            ],
          ],
          [
            [
              [0.643515, 40.57691],
              [0.643814, 40.576126],
              [0.641871, 40.578619],
              [0.642587, 40.578101],
              [0.643515, 40.57691],
            ],
          ],
          [
            [
              [0.646965, 40.578242],
              [0.645506, 40.578567],
              [0.645381, 40.579301],
              [0.646608, 40.578847],
              [0.646965, 40.578242],
            ],
          ],
          [
            [
              [0.634879, 40.582541],
              [0.635776, 40.582315],
              [0.635962, 40.581831],
              [0.63506, 40.581743],
              [0.634879, 40.582541],
            ],
          ],
          [
            [
              [0.615692, 40.576561],
              [0.614935, 40.57654],
              [0.614651, 40.576972],
              [0.615839, 40.577304],
              [0.615692, 40.576561],
            ],
          ],
          [
            [
              [0.644845, 40.575319],
              [0.645564, 40.575278],
              [0.646476, 40.574024],
              [0.644626, 40.574975],
              [0.644845, 40.575319],
            ],
          ],
          [
            [
              [0.63911, 40.581498],
              [0.638116, 40.581682],
              [0.638571, 40.582461],
              [0.639191, 40.582126],
              [0.63911, 40.581498],
            ],
          ],
          [
            [
              [0.643466, 40.582765],
              [0.642782, 40.583219],
              [0.64272, 40.583599],
              [0.643725, 40.583604],
              [0.643466, 40.582765],
            ],
          ],
          [
            [
              [0.648835, 40.5744],
              [0.647805, 40.574136],
              [0.647166, 40.574768],
              [0.648192, 40.574952],
              [0.648835, 40.5744],
            ],
          ],
          [
            [
              [0.642341, 40.581612],
              [0.641407, 40.582479],
              [0.641716, 40.582938],
              [0.642349, 40.582638],
              [0.642341, 40.581612],
            ],
          ],
          [
            [
              [0.633382, 40.581084],
              [0.633043, 40.58159],
              [0.633906, 40.581806],
              [0.63391, 40.58123],
              [0.633382, 40.581084],
            ],
          ],
          [
            [
              [0.642964, 40.579395],
              [0.643749, 40.579055],
              [0.644208, 40.578149],
              [0.643624, 40.578492],
              [0.642964, 40.579395],
            ],
          ],
          [
            [
              [0.643253, 40.579917],
              [0.642678, 40.579773],
              [0.642392, 40.580809],
              [0.642524, 40.580634],
              [0.643253, 40.579917],
            ],
          ],
          [
            [
              [0.638526, 40.579436],
              [0.638027, 40.579398],
              [0.637971, 40.580093],
              [0.638524, 40.579841],
              [0.638526, 40.579436],
            ],
          ],
          [
            [
              [0.859875, 40.735627],
              [0.85981, 40.735332],
              [0.858833, 40.735428],
              [0.859289, 40.735737],
              [0.859875, 40.735627],
            ],
          ],
          [
            [
              [0.645108, 40.584271],
              [0.645088, 40.583903],
              [0.644103, 40.584042],
              [0.644371, 40.584196],
              [0.645108, 40.584271],
            ],
          ],
          [
            [
              [0.646682, 40.574558],
              [0.646313, 40.574498],
              [0.646134, 40.575341],
              [0.646357, 40.5751],
              [0.646682, 40.574558],
            ],
          ],
          [
            [
              [0.650005, 40.583108],
              [0.64951, 40.582907],
              [0.649266, 40.583194],
              [0.649336, 40.583399],
              [0.650005, 40.583108],
            ],
          ],
          [
            [
              [0.637016, 40.581447],
              [0.636695, 40.581628],
              [0.636603, 40.582108],
              [0.636926, 40.581963],
              [0.637016, 40.581447],
            ],
          ],
          [
            [
              [0.641789, 40.581458],
              [0.641966, 40.581246],
              [0.641046, 40.581914],
              [0.641355, 40.581733],
              [0.641789, 40.581458],
            ],
          ],
          [
            [
              [0.644979, 40.580394],
              [0.644698, 40.580655],
              [0.644597, 40.581181],
              [0.644826, 40.581038],
              [0.644979, 40.580394],
            ],
          ],
          [
            [
              [1.3606, 41.128977],
              [1.360442, 41.12911],
              [1.36064, 41.129399],
              [1.360874, 41.129156],
              [1.3606, 41.128977],
            ],
          ],
          [
            [
              [0.640069, 40.577181],
              [0.640233, 40.576951],
              [0.639723, 40.577336],
              [0.639818, 40.57736],
              [0.640069, 40.577181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5646",
      properties: { name: "Teruel", density: 0, path: "/world/Spain/Teruel" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.502556, 41.354389],
              [-0.479932, 41.345934],
              [-0.470164, 41.318249],
              [-0.420759, 41.311738],
              [-0.428752, 41.294066],
              [-0.405859, 41.272359],
              [-0.337208, 41.241384],
              [-0.317468, 41.257506],
              [-0.224188, 41.189484],
              [-0.180954, 41.188145],
              [-0.01588, 41.122721],
              [0.036919, 41.046569],
              [0.078635, 41.080342],
              [0.145855, 41.063043],
              [0.220349, 41.07143],
              [0.230426, 41.054621],
              [0.217744, 41.043655],
              [0.279658, 41.020167],
              [0.258635, 41.01463],
              [0.293501, 40.970561],
              [0.238908, 40.884704],
              [0.278194, 40.820901],
              [0.23357, 40.786993],
              [0.229967, 40.767433],
              [0.175222, 40.754941],
              [0.175357, 40.741131],
              [0.159181, 40.750608],
              [0.163809, 40.723607],
              [0.112501, 40.727137],
              [0.042175, 40.691006],
              [0.026438, 40.694951],
              [0.015738, 40.72843],
              [-0.064699, 40.727407],
              [-0.126841, 40.753317],
              [-0.144533, 40.785443],
              [-0.164957, 40.788631],
              [-0.197176, 40.784456],
              [-0.224034, 40.754158],
              [-0.235991, 40.691108],
              [-0.306998, 40.659497],
              [-0.328818, 40.680279],
              [-0.377957, 40.667536],
              [-0.370483, 40.613592],
              [-0.293286, 40.611041],
              [-0.301837, 40.515404],
              [-0.277638, 40.501532],
              [-0.27264, 40.473762],
              [-0.347355, 40.44394],
              [-0.28539, 40.386492],
              [-0.281762, 40.365698],
              [-0.340617, 40.339991],
              [-0.366273, 40.305052],
              [-0.388637, 40.306937],
              [-0.400408, 40.294853],
              [-0.383626, 40.264848],
              [-0.428072, 40.243803],
              [-0.494515, 40.228732],
              [-0.544187, 40.251659],
              [-0.57864, 40.137335],
              [-0.628533, 40.102578],
              [-0.613589, 40.070013],
              [-0.627222, 40.076202],
              [-0.68294, 40.044225],
              [-0.753621, 40.046608],
              [-0.777646, 39.998407],
              [-0.837795, 39.976808],
              [-0.840423, 39.92495],
              [-0.797695, 39.881067],
              [-0.863555, 39.84741],
              [-0.912564, 39.872355],
              [-0.902659, 39.93185],
              [-0.920454, 39.964404],
              [-0.931886, 39.956077],
              [-0.989381, 39.981827],
              [-1.105015, 39.974975],
              [-1.121748, 39.960851],
              [-1.142395, 39.971841],
              [-1.165188, 40.010096],
              [-1.084228, 40.036208],
              [-1.072129, 40.060484],
              [-1.148034, 40.114497],
              [-1.244592, 40.115803],
              [-1.244296, 40.145969],
              [-1.284342, 40.171497],
              [-1.293083, 40.206923],
              [-1.297785, 40.211533],
              [-1.32299, 40.184856],
              [-1.319722, 40.146395],
              [-1.357099, 40.128887],
              [-1.433319, 40.139475],
              [-1.448856, 40.145342],
              [-1.439208, 40.197014],
              [-1.474416, 40.185042],
              [-1.510902, 40.203715],
              [-1.540483, 40.190804],
              [-1.690346, 40.30966],
              [-1.72122, 40.2787],
              [-1.72946, 40.302908],
              [-1.697677, 40.321292],
              [-1.777769, 40.391878],
              [-1.806359, 40.39823],
              [-1.800643, 40.414216],
              [-1.785434, 40.413183],
              [-1.735042, 40.472028],
              [-1.692512, 40.486824],
              [-1.701624, 40.541096],
              [-1.678101, 40.590952],
              [-1.598749, 40.562054],
              [-1.545515, 40.595195],
              [-1.564856, 40.614742],
              [-1.535638, 40.687019],
              [-1.562017, 40.739752],
              [-1.539908, 40.760733],
              [-1.542334, 40.811219],
              [-1.621392, 40.8804],
              [-1.607904, 40.92762],
              [-1.617449, 40.943733],
              [-1.56163, 40.938618],
              [-1.505343, 40.964124],
              [-1.472993, 40.948801],
              [-1.446599, 40.98456],
              [-1.476401, 41.014936],
              [-1.417523, 41.029753],
              [-1.405221, 41.066307],
              [-1.355799, 41.088528],
              [-1.354168, 41.057527],
              [-1.287431, 41.048862],
              [-1.253303, 41.077327],
              [-1.271371, 41.10328],
              [-1.254404, 41.122939],
              [-1.209983, 41.138804],
              [-1.173991, 41.123692],
              [-1.121604, 41.134928],
              [-1.105675, 41.167589],
              [-1.039309, 41.134718],
              [-1.000861, 41.134011],
              [-0.972419, 41.153456],
              [-0.998597, 41.095231],
              [-0.952918, 41.075441],
              [-0.912547, 41.093009],
              [-0.883264, 41.148761],
              [-0.859887, 41.108012],
              [-0.828532, 41.096419],
              [-0.792815, 41.105603],
              [-0.761414, 41.169658],
              [-0.693969, 41.112294],
              [-0.609902, 41.191678],
              [-0.669215, 41.236848],
              [-0.625457, 41.248175],
              [-0.629719, 41.311761],
              [-0.599809, 41.318886],
              [-0.548492, 41.267744],
              [-0.531578, 41.227807],
              [-0.509694, 41.238278],
              [-0.508156, 41.255817],
              [-0.527151, 41.288218],
              [-0.577305, 41.313946],
              [-0.581787, 41.348122],
              [-0.502556, 41.354389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5644",
      properties: { name: "Toledo", density: 0, path: "/world/Spain/Toledo" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.37514, 40.314258],
              [-4.356386, 40.308621],
              [-4.348488, 40.236747],
              [-4.307989, 40.233882],
              [-4.306111, 40.221778],
              [-4.269961, 40.235685],
              [-4.247999, 40.273481],
              [-4.20323, 40.268818],
              [-4.190871, 40.297355],
              [-4.143992, 40.264621],
              [-4.145769, 40.245177],
              [-4.104272, 40.241833],
              [-4.072894, 40.265451],
              [-4.024028, 40.248968],
              [-4.026159, 40.235611],
              [-3.991002, 40.209478],
              [-3.952866, 40.212797],
              [-3.950892, 40.190335],
              [-3.931143, 40.203977],
              [-3.872016, 40.190153],
              [-3.834186, 40.161353],
              [-3.804603, 40.176841],
              [-3.770453, 40.139399],
              [-3.744375, 40.132224],
              [-3.722216, 40.147713],
              [-3.683724, 40.13227],
              [-3.667313, 40.143291],
              [-3.655732, 40.124912],
              [-3.607628, 40.108967],
              [-3.636088, 40.041626],
              [-3.699891, 40.019543],
              [-3.720088, 39.984441],
              [-3.746207, 39.981089],
              [-3.727936, 39.964545],
              [-3.758817, 39.968885],
              [-3.75437, 39.950443],
              [-3.770486, 39.950552],
              [-3.757973, 39.958321],
              [-3.763602, 39.967518],
              [-3.783347, 39.944796],
              [-3.809548, 39.953965],
              [-3.832042, 39.93156],
              [-3.840044, 39.941956],
              [-3.87519, 39.928736],
              [-3.868938, 39.905244],
              [-3.814894, 39.885742],
              [-3.74387, 39.940802],
              [-3.634499, 39.965892],
              [-3.637944, 39.988352],
              [-3.589995, 40.013172],
              [-3.519689, 40.021072],
              [-3.506863, 40.048674],
              [-3.401072, 40.034181],
              [-3.375783, 40.051255],
              [-3.377708, 40.074767],
              [-3.334418, 40.081052],
              [-3.275165, 40.047314],
              [-3.200956, 40.060488],
              [-3.166446, 40.09111],
              [-3.170235, 40.048139],
              [-3.142566, 39.984965],
              [-3.096597, 39.98694],
              [-3.097059, 39.933467],
              [-3.127227, 39.874683],
              [-3.076292, 39.855707],
              [-2.997748, 39.718866],
              [-2.908233, 39.642332],
              [-2.925514, 39.601798],
              [-2.931283, 39.470873],
              [-2.956792, 39.447214],
              [-2.999549, 39.441644],
              [-3.022587, 39.456996],
              [-3.069597, 39.450273],
              [-3.143423, 39.495528],
              [-3.184516, 39.487964],
              [-3.185165, 39.47415],
              [-3.294197, 39.470844],
              [-3.31589, 39.434528],
              [-3.309134, 39.399152],
              [-3.410278, 39.404589],
              [-3.470483, 39.37214],
              [-3.47688, 39.343144],
              [-3.53259, 39.304157],
              [-3.680186, 39.298281],
              [-3.722522, 39.269607],
              [-3.791212, 39.292895],
              [-3.854283, 39.258417],
              [-3.966856, 39.298553],
              [-3.972202, 39.352176],
              [-4.073373, 39.365334],
              [-4.104449, 39.341112],
              [-4.120539, 39.362619],
              [-4.148074, 39.362765],
              [-4.118073, 39.456882],
              [-4.068131, 39.457054],
              [-4.060076, 39.521443],
              [-4.03008, 39.521974],
              [-4.041778, 39.575354],
              [-4.124345, 39.563557],
              [-4.158731, 39.576761],
              [-4.193234, 39.495384],
              [-4.242092, 39.477898],
              [-4.37721, 39.495983],
              [-4.441906, 39.523131],
              [-4.461586, 39.478547],
              [-4.51122, 39.522125],
              [-4.482188, 39.538797],
              [-4.524951, 39.561625],
              [-4.531089, 39.531604],
              [-4.542684, 39.544002],
              [-4.561444, 39.533138],
              [-4.554627, 39.510777],
              [-4.567921, 39.497282],
              [-4.618426, 39.484652],
              [-4.618132, 39.454401],
              [-4.690277, 39.451789],
              [-4.755371, 39.415713],
              [-4.768101, 39.429918],
              [-4.740511, 39.456165],
              [-4.75171, 39.50183],
              [-4.863123, 39.556147],
              [-4.902996, 39.503696],
              [-4.949674, 39.393112],
              [-5.206625, 39.597232],
              [-5.161765, 39.642437],
              [-5.137794, 39.714999],
              [-5.173964, 39.751255],
              [-5.158459, 39.759661],
              [-5.1669, 39.799882],
              [-5.206465, 39.797559],
              [-5.254823, 39.752342],
              [-5.308079, 39.760025],
              [-5.312588, 39.824275],
              [-5.284049, 39.863875],
              [-5.323613, 39.891727],
              [-5.40611, 39.877729],
              [-5.365144, 39.982919],
              [-5.373858, 40.059322],
              [-5.359445, 40.108406],
              [-5.216372, 40.108007],
              [-5.203518, 40.083434],
              [-5.171616, 40.08482],
              [-5.087772, 40.124323],
              [-5.066985, 40.150919],
              [-5.018542, 40.159053],
              [-5.013344, 40.111056],
              [-4.954546, 40.121126],
              [-4.956293, 40.133348],
              [-4.925454, 40.136036],
              [-4.924675, 40.169984],
              [-4.807675, 40.235319],
              [-4.818245, 40.254774],
              [-4.803469, 40.275265],
              [-4.76159, 40.260902],
              [-4.698141, 40.282192],
              [-4.687258, 40.212002],
              [-4.654235, 40.198067],
              [-4.579076, 40.217196],
              [-4.527911, 40.200093],
              [-4.509789, 40.21919],
              [-4.437426, 40.236698],
              [-4.435246, 40.259127],
              [-4.383482, 40.289049],
              [-4.37514, 40.314258],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5647",
      properties: {
        name: "València/Valencia",
        density: 0,
        path: "/world/Spain/València/Valencia",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.987142, 39.980725],
              [-0.918505, 39.962675],
              [-0.902659, 39.93185],
              [-0.912788, 39.873053],
              [-0.888574, 39.852111],
              [-0.863555, 39.84741],
              [-0.793766, 39.881434],
              [-0.730456, 39.814828],
              [-0.71255, 39.816845],
              [-0.691111, 39.852463],
              [-0.656151, 39.835961],
              [-0.652347, 39.754084],
              [-0.635858, 39.747867],
              [-0.588224, 39.741688],
              [-0.577049, 39.773202],
              [-0.530586, 39.795977],
              [-0.456176, 39.714698],
              [-0.396095, 39.758614],
              [-0.377919, 39.799877],
              [-0.328259, 39.801482],
              [-0.276528, 39.770542],
              [-0.264177, 39.745497],
              [-0.188502, 39.721938],
              [-0.208146, 39.643295],
              [-0.215735, 39.655116],
              [-0.254326, 39.620822],
              [-0.315965, 39.523438],
              [-0.321261, 39.463399],
              [-0.301967, 39.449312],
              [-0.302133, 39.434182],
              [-0.329757, 39.461586],
              [-0.316616, 39.443337],
              [-0.328295, 39.444425],
              [-0.334426, 39.433659],
              [-0.310096, 39.428025],
              [-0.335721, 39.424689],
              [-0.278519, 39.278478],
              [-0.216281, 39.186341],
              [-0.238317, 39.176713],
              [-0.239482, 39.146921],
              [-0.203461, 39.065999],
              [-0.144286, 38.995276],
              [-0.156294, 38.99811],
              [-0.161136, 38.992367],
              [-0.146779, 38.994427],
              [-0.027396, 38.8616],
              [-0.042151, 38.856452],
              [-0.070616, 38.885721],
              [-0.148077, 38.858431],
              [-0.160832, 38.887391],
              [-0.23054, 38.85349],
              [-0.310809, 38.881511],
              [-0.381436, 38.834899],
              [-0.40339, 38.839714],
              [-0.461877, 38.807645],
              [-0.595217, 38.795097],
              [-0.565642, 38.755427],
              [-0.529627, 38.767095],
              [-0.507115, 38.742975],
              [-0.6287, 38.686554],
              [-0.64344, 38.727766],
              [-0.707189, 38.733819],
              [-0.734931, 38.759445],
              [-0.834622, 38.732431],
              [-0.861693, 38.77089],
              [-0.913225, 38.769606],
              [-0.933848, 38.793976],
              [-0.924537, 38.891752],
              [-0.956524, 38.920123],
              [-0.95941, 38.944582],
              [-1.146654, 38.929353],
              [-1.226538, 39.024673],
              [-1.263903, 39.045094],
              [-1.25572, 39.10617],
              [-1.180826, 39.211822],
              [-1.161889, 39.305418],
              [-1.207909, 39.326237],
              [-1.223328, 39.316112],
              [-1.312744, 39.343166],
              [-1.336645, 39.333996],
              [-1.414679, 39.380298],
              [-1.426033, 39.362757],
              [-1.432294, 39.377582],
              [-1.455104, 39.360849],
              [-1.448789, 39.37819],
              [-1.468193, 39.383331],
              [-1.452881, 39.383741],
              [-1.452737, 39.398669],
              [-1.473305, 39.391147],
              [-1.470567, 39.407509],
              [-1.488813, 39.401648],
              [-1.483252, 39.42246],
              [-1.498994, 39.415357],
              [-1.49665, 39.42932],
              [-1.52172, 39.432677],
              [-1.528867, 39.455266],
              [-1.513391, 39.458268],
              [-1.512454, 39.496269],
              [-1.499153, 39.50045],
              [-1.502602, 39.545616],
              [-1.520665, 39.549965],
              [-1.462082, 39.576621],
              [-1.417932, 39.654936],
              [-1.368535, 39.689615],
              [-1.312827, 39.670484],
              [-1.267904, 39.691148],
              [-1.275409, 39.738641],
              [-1.215004, 39.808774],
              [-1.200044, 39.858706],
              [-1.203978, 39.949344],
              [-1.142395, 39.971841],
              [-1.121748, 39.960851],
              [-1.105015, 39.974975],
              [-0.987142, 39.980725],
            ],
          ],
          [
            [
              [-1.293083, 40.206923],
              [-1.284342, 40.171497],
              [-1.244296, 40.145969],
              [-1.244592, 40.115803],
              [-1.148034, 40.114497],
              [-1.071772, 40.051109],
              [-1.134081, 40.015752],
              [-1.24683, 39.995181],
              [-1.37686, 40.016499],
              [-1.420286, 40.093514],
              [-1.407597, 40.101404],
              [-1.457235, 40.135057],
              [-1.448856, 40.145342],
              [-1.354597, 40.129473],
              [-1.316589, 40.149656],
              [-1.32299, 40.184856],
              [-1.293083, 40.206923],
            ],
          ],
          [
            [
              [-0.230151, 39.159838],
              [-0.230976, 39.159783],
              [-0.231051, 39.159916],
              [-0.230477, 39.160074],
              [-0.230151, 39.159838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5648",
      properties: {
        name: "Valladolid",
        density: 0,
        path: "/world/Spain/Valladolid",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.112002, 42.311842],
              [-5.093754, 42.309295],
              [-5.054809, 42.257524],
              [-5.03835, 42.297127],
              [-5.002141, 42.289129],
              [-5.018808, 42.253859],
              [-5.012394, 42.18976],
              [-5.031895, 42.173629],
              [-5.026966, 42.153912],
              [-4.927498, 42.150215],
              [-4.928707, 42.118314],
              [-4.904177, 42.078394],
              [-4.947625, 42.054312],
              [-4.936006, 42.017916],
              [-4.965539, 42.007057],
              [-5.005104, 41.947228],
              [-4.985321, 41.910745],
              [-4.949587, 41.933828],
              [-4.927936, 41.915029],
              [-4.869085, 41.931728],
              [-4.789248, 41.818024],
              [-4.758421, 41.825787],
              [-4.728247, 41.868828],
              [-4.664769, 41.89947],
              [-4.566083, 41.812574],
              [-4.549155, 41.831015],
              [-4.512124, 41.823304],
              [-4.473298, 41.843028],
              [-4.478314, 41.828589],
              [-4.461148, 41.82036],
              [-4.487341, 41.820096],
              [-4.501184, 41.771406],
              [-4.45007, 41.758363],
              [-4.389297, 41.787774],
              [-4.31764, 41.763444],
              [-4.236025, 41.813969],
              [-4.213947, 41.799406],
              [-4.123195, 41.807308],
              [-4.100272, 41.774709],
              [-4.040877, 41.768354],
              [-4.07636, 41.726084],
              [-4.026038, 41.692381],
              [-4.035283, 41.620623],
              [-4.005927, 41.619362],
              [-3.984408, 41.597491],
              [-3.995787, 41.521147],
              [-4.142485, 41.469936],
              [-4.158318, 41.4821],
              [-4.2611, 41.450591],
              [-4.270132, 41.461274],
              [-4.362393, 41.449931],
              [-4.364915, 41.436002],
              [-4.410616, 41.441647],
              [-4.468159, 41.413076],
              [-4.514958, 41.409659],
              [-4.484001, 41.366801],
              [-4.504473, 41.347196],
              [-4.470551, 41.31846],
              [-4.471463, 41.291562],
              [-4.528628, 41.321099],
              [-4.55954, 41.302166],
              [-4.596849, 41.320587],
              [-4.601978, 41.302654],
              [-4.573882, 41.275201],
              [-4.650863, 41.221421],
              [-4.627752, 41.170795],
              [-4.680561, 41.185737],
              [-4.722102, 41.149318],
              [-4.805844, 41.135164],
              [-4.827958, 41.099154],
              [-4.863677, 41.094015],
              [-4.989735, 41.160805],
              [-5.124929, 41.132045],
              [-5.179658, 41.167293],
              [-5.21822, 41.159585],
              [-5.229602, 41.190529],
              [-5.252968, 41.199775],
              [-5.332508, 41.178267],
              [-5.340297, 41.194105],
              [-5.293652, 41.226253],
              [-5.302207, 41.259272],
              [-5.29174, 41.26919],
              [-5.334624, 41.370644],
              [-5.307537, 41.414053],
              [-5.339238, 41.425599],
              [-5.324791, 41.455343],
              [-5.344736, 41.479256],
              [-5.228873, 41.528431],
              [-5.293073, 41.574372],
              [-5.270539, 41.601137],
              [-5.328894, 41.615919],
              [-5.327354, 41.680527],
              [-5.358136, 41.699695],
              [-5.360161, 41.727132],
              [-5.375196, 41.719788],
              [-5.390692, 41.752573],
              [-5.370177, 41.777846],
              [-5.278611, 41.814796],
              [-5.330429, 41.831796],
              [-5.306474, 41.857838],
              [-5.311063, 41.89159],
              [-5.293733, 41.919938],
              [-5.335215, 41.937473],
              [-5.254401, 41.98094],
              [-5.280649, 41.9788],
              [-5.325519, 42.020398],
              [-5.376395, 42.039645],
              [-5.390117, 42.08981],
              [-5.371524, 42.130064],
              [-5.388988, 42.21794],
              [-5.361234, 42.225152],
              [-5.307462, 42.199266],
              [-5.286925, 42.229928],
              [-5.265644, 42.210899],
              [-5.241483, 42.214517],
              [-5.225994, 42.226724],
              [-5.254001, 42.239775],
              [-5.236499, 42.273349],
              [-5.18272, 42.26421],
              [-5.112002, 42.311842],
            ],
          ],
          [
            [
              [-5.51673, 42.093449],
              [-5.480923, 42.052425],
              [-5.421588, 42.030675],
              [-5.447437, 42.013655],
              [-5.431455, 42.004323],
              [-5.438279, 41.96739],
              [-5.485272, 41.985122],
              [-5.471416, 42.010097],
              [-5.51673, 42.093449],
            ],
          ],
          [
            [
              [-5.322061, 42.26773],
              [-5.301758, 42.269238],
              [-5.303324, 42.251997],
              [-5.341317, 42.232747],
              [-5.345342, 42.267769],
              [-5.322061, 42.26773],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5650",
      properties: { name: "Zamora", density: 0, path: "/world/Spain/Zamora" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.761138, 42.239866],
              [-6.703792, 42.209373],
              [-6.638648, 42.217296],
              [-6.613266, 42.203215],
              [-6.526001, 42.203359],
              [-6.497561, 42.182025],
              [-6.427895, 42.201942],
              [-6.312585, 42.206585],
              [-6.293732, 42.178033],
              [-6.212395, 42.148077],
              [-6.160213, 42.165772],
              [-6.049305, 42.159861],
              [-5.953468, 42.123683],
              [-5.948121, 42.141921],
              [-5.911601, 42.140585],
              [-5.897426, 42.156101],
              [-5.844851, 42.110716],
              [-5.776707, 42.135809],
              [-5.755193, 42.136625],
              [-5.742948, 42.113342],
              [-5.676768, 42.120336],
              [-5.687177, 42.137924],
              [-5.667556, 42.142065],
              [-5.620137, 42.106659],
              [-5.635043, 42.081994],
              [-5.588502, 42.077326],
              [-5.586607, 42.050911],
              [-5.577722, 42.065448],
              [-5.560698, 42.055968],
              [-5.543363, 42.099381],
              [-5.526088, 42.10377],
              [-5.471416, 42.010097],
              [-5.48565, 41.985826],
              [-5.463124, 41.97546],
              [-5.438279, 41.96739],
              [-5.431455, 42.004323],
              [-5.447437, 42.013655],
              [-5.384412, 42.042108],
              [-5.325519, 42.020398],
              [-5.280649, 41.9788],
              [-5.260505, 41.984611],
              [-5.254679, 41.981646],
              [-5.335215, 41.937473],
              [-5.293733, 41.919938],
              [-5.311063, 41.89159],
              [-5.306474, 41.857838],
              [-5.330429, 41.831796],
              [-5.278611, 41.814796],
              [-5.391384, 41.760511],
              [-5.375196, 41.719788],
              [-5.361691, 41.728847],
              [-5.358136, 41.699695],
              [-5.327354, 41.680527],
              [-5.328894, 41.615919],
              [-5.270539, 41.601137],
              [-5.293073, 41.574372],
              [-5.228873, 41.528431],
              [-5.344736, 41.479256],
              [-5.324791, 41.455343],
              [-5.339238, 41.425599],
              [-5.307537, 41.414053],
              [-5.334624, 41.370644],
              [-5.29174, 41.26919],
              [-5.302207, 41.259272],
              [-5.293652, 41.226253],
              [-5.340297, 41.194105],
              [-5.330268, 41.177619],
              [-5.288744, 41.181992],
              [-5.297858, 41.125877],
              [-5.32895, 41.117173],
              [-5.356987, 41.140822],
              [-5.386963, 41.135854],
              [-5.418103, 41.187661],
              [-5.476878, 41.178323],
              [-5.46983, 41.157948],
              [-5.48434, 41.145503],
              [-5.485255, 41.178854],
              [-5.565067, 41.217038],
              [-5.58793, 41.20244],
              [-5.628325, 41.214457],
              [-5.662791, 41.245809],
              [-5.767308, 41.215701],
              [-5.897674, 41.238592],
              [-5.92133, 41.218153],
              [-5.913134, 41.178337],
              [-5.953245, 41.1401],
              [-5.979218, 41.174574],
              [-6.021826, 41.155262],
              [-6.027501, 41.179123],
              [-6.008835, 41.200498],
              [-6.06201, 41.221051],
              [-6.10931, 41.212613],
              [-6.134499, 41.190387],
              [-6.317682, 41.270894],
              [-6.479068, 41.288386],
              [-6.437568, 41.30571],
              [-6.416153, 41.347964],
              [-6.379573, 41.357232],
              [-6.389928, 41.385367],
              [-6.318303, 41.386116],
              [-6.332814, 41.411497],
              [-6.298078, 41.431178],
              [-6.305819, 41.449525],
              [-6.285109, 41.478388],
              [-6.262379, 41.486474],
              [-6.255909, 41.513891],
              [-6.18935, 41.575024],
              [-6.302172, 41.662738],
              [-6.444493, 41.684833],
              [-6.498515, 41.657129],
              [-6.548245, 41.685617],
              [-6.549065, 41.725404],
              [-6.568989, 41.743498],
              [-6.515768, 41.870726],
              [-6.571185, 41.883621],
              [-6.54585, 41.935933],
              [-6.573802, 41.960666],
              [-6.588473, 41.967754],
              [-6.621213, 41.939953],
              [-6.699273, 41.933328],
              [-6.750622, 41.943393],
              [-6.768704, 41.983897],
              [-6.785607, 41.988896],
              [-6.811484, 41.992523],
              [-6.82115, 41.946313],
              [-6.844417, 41.942303],
              [-6.94169, 41.944294],
              [-6.957857, 41.969291],
              [-6.98454, 41.973634],
              [-6.991108, 41.988344],
              [-6.961098, 42.027906],
              [-6.974792, 42.055157],
              [-7.011832, 42.052416],
              [-7.033585, 42.074235],
              [-7.00269, 42.08417],
              [-6.989816, 42.121312],
              [-6.94901, 42.135737],
              [-6.942794, 42.173337],
              [-6.901078, 42.189623],
              [-6.886892, 42.214354],
              [-6.8436, 42.228304],
              [-6.796708, 42.20905],
              [-6.791491, 42.252156],
              [-6.761138, 42.239866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5651",
      properties: {
        name: "Zaragoza",
        density: 0,
        path: "/world/Spain/Zaragoza",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.909105, 42.744036],
              [-0.896865, 42.735662],
              [-0.922463, 42.700804],
              [-0.879954, 42.688138],
              [-0.893094, 42.662347],
              [-0.927643, 42.656242],
              [-0.904713, 42.599048],
              [-0.934168, 42.572648],
              [-0.895048, 42.549063],
              [-0.933425, 42.505879],
              [-0.892994, 42.509852],
              [-0.89702, 42.479083],
              [-0.877719, 42.461643],
              [-0.903759, 42.453244],
              [-0.904413, 42.434759],
              [-0.815314, 42.395226],
              [-0.844179, 42.384642],
              [-0.855662, 42.33992],
              [-0.918909, 42.257316],
              [-0.890548, 42.240029],
              [-0.876959, 42.281646],
              [-0.806532, 42.270548],
              [-0.77181, 42.346714],
              [-0.777937, 42.391257],
              [-0.736429, 42.369876],
              [-0.744589, 42.33868],
              [-0.728865, 42.296322],
              [-0.759379, 42.263648],
              [-0.7598, 42.170232],
              [-0.747391, 42.155922],
              [-0.760862, 42.129853],
              [-0.754917, 42.090598],
              [-0.770161, 42.08723],
              [-0.763142, 42.067479],
              [-0.793546, 42.054398],
              [-0.812937, 42.092503],
              [-0.857005, 42.05099],
              [-0.850738, 42.026152],
              [-0.884933, 42.003579],
              [-0.778209, 41.957397],
              [-0.657826, 41.957495],
              [-0.630582, 41.912906],
              [-0.595465, 41.899594],
              [-0.603313, 41.882827],
              [-0.555209, 41.871329],
              [-0.516748, 41.836776],
              [-0.500693, 41.761317],
              [-0.51869, 41.728817],
              [-0.489424, 41.731666],
              [-0.444919, 41.703684],
              [-0.374504, 41.688118],
              [-0.359523, 41.635224],
              [-0.312589, 41.624102],
              [-0.244785, 41.573115],
              [-0.205736, 41.602261],
              [-0.156446, 41.589974],
              [-0.167355, 41.581111],
              [-0.156663, 41.535295],
              [-0.104972, 41.48548],
              [-0.072706, 41.377157],
              [-0.04944, 41.36046],
              [-0.007931, 41.347806],
              [-0.029285, 41.377818],
              [-0.0234, 41.396993],
              [0.009693, 41.360431],
              [0.093362, 41.34997],
              [0.163652, 41.406497],
              [0.182643, 41.394409],
              [0.219094, 41.40357],
              [0.24059, 41.428353],
              [0.333709, 41.409423],
              [0.320036, 41.394183],
              [0.373968, 41.351539],
              [0.348436, 41.328871],
              [0.385664, 41.278844],
              [0.381311, 41.23643],
              [0.322404, 41.225241],
              [0.291988, 41.156356],
              [0.25299, 41.150153],
              [0.247879, 41.130142],
              [0.201225, 41.126775],
              [0.201202, 41.084681],
              [0.220349, 41.07143],
              [0.145855, 41.063043],
              [0.078635, 41.080342],
              [0.036919, 41.046569],
              [-0.01588, 41.122721],
              [-0.180954, 41.188145],
              [-0.224188, 41.189484],
              [-0.317468, 41.257506],
              [-0.337208, 41.241384],
              [-0.405859, 41.272359],
              [-0.428752, 41.294066],
              [-0.420759, 41.311738],
              [-0.470164, 41.318249],
              [-0.479932, 41.345934],
              [-0.502556, 41.354389],
              [-0.581787, 41.348122],
              [-0.577305, 41.313946],
              [-0.527151, 41.288218],
              [-0.508156, 41.255817],
              [-0.509694, 41.238278],
              [-0.531578, 41.227807],
              [-0.548492, 41.267744],
              [-0.599809, 41.318886],
              [-0.629719, 41.311761],
              [-0.625457, 41.248175],
              [-0.669215, 41.236848],
              [-0.609902, 41.191678],
              [-0.693969, 41.112294],
              [-0.761414, 41.169658],
              [-0.792815, 41.105603],
              [-0.828532, 41.096419],
              [-0.859887, 41.108012],
              [-0.883264, 41.148761],
              [-0.912547, 41.093009],
              [-0.952918, 41.075441],
              [-0.998597, 41.095231],
              [-0.972419, 41.153456],
              [-1.000861, 41.134011],
              [-1.039309, 41.134718],
              [-1.105675, 41.167589],
              [-1.121604, 41.134928],
              [-1.173991, 41.123692],
              [-1.209983, 41.138804],
              [-1.254404, 41.122939],
              [-1.271371, 41.10328],
              [-1.253303, 41.077327],
              [-1.287431, 41.048862],
              [-1.354168, 41.057527],
              [-1.355799, 41.088528],
              [-1.405221, 41.066307],
              [-1.417523, 41.029753],
              [-1.476401, 41.014936],
              [-1.446599, 40.98456],
              [-1.472993, 40.948801],
              [-1.505343, 40.964124],
              [-1.56163, 40.938618],
              [-1.631041, 40.950002],
              [-1.803291, 41.089895],
              [-1.932447, 41.13859],
              [-1.959017, 41.172486],
              [-2.051693, 41.146849],
              [-2.079896, 41.170984],
              [-2.146242, 41.184395],
              [-2.170487, 41.318832],
              [-2.157113, 41.333967],
              [-2.163137, 41.35535],
              [-2.116033, 41.382047],
              [-2.11177, 41.434186],
              [-2.10152, 41.445789],
              [-2.047099, 41.434335],
              [-2.035623, 41.426889],
              [-2.042355, 41.388996],
              [-2.02927, 41.384158],
              [-1.937295, 41.407854],
              [-1.953686, 41.424263],
              [-1.953276, 41.462451],
              [-1.981112, 41.486435],
              [-1.967335, 41.546067],
              [-1.993686, 41.577087],
              [-1.993238, 41.60051],
              [-1.9231, 41.598335],
              [-1.858991, 41.66153],
              [-1.806224, 41.664263],
              [-1.812074, 41.686454],
              [-1.775372, 41.724789],
              [-1.809213, 41.736664],
              [-1.805853, 41.755084],
              [-1.852918, 41.789564],
              [-1.819054, 41.828753],
              [-1.817601, 41.85338],
              [-1.857281, 41.909515],
              [-1.865188, 41.977602],
              [-1.844045, 41.987334],
              [-1.847185, 42.007999],
              [-1.833703, 41.995065],
              [-1.766896, 41.995789],
              [-1.715221, 41.956963],
              [-1.654146, 41.965351],
              [-1.559256, 41.913944],
              [-1.410756, 41.918955],
              [-1.33952, 42.018014],
              [-1.304822, 42.042949],
              [-1.310593, 42.072313],
              [-1.352277, 42.07307],
              [-1.36676, 42.106961],
              [-1.398881, 42.126219],
              [-1.391906, 42.181418],
              [-1.41753, 42.212368],
              [-1.391371, 42.272117],
              [-1.399082, 42.2913],
              [-1.329083, 42.355385],
              [-1.359905, 42.378039],
              [-1.341508, 42.423938],
              [-1.27278, 42.481899],
              [-1.288137, 42.52279],
              [-1.264769, 42.556803],
              [-1.226166, 42.542244],
              [-1.202791, 42.54966],
              [-1.20192, 42.578239],
              [-1.158035, 42.599154],
              [-1.179775, 42.610624],
              [-1.157116, 42.610891],
              [-1.152046, 42.647884],
              [-1.051926, 42.642594],
              [-1.036808, 42.651638],
              [-1.027981, 42.70006],
              [-0.969211, 42.703705],
              [-0.909105, 42.744036],
            ],
            [
              [-1.125577, 42.455863],
              [-1.108017, 42.441319],
              [-1.051014, 42.441462],
              [-1.091664, 42.485733],
              [-1.125577, 42.455863],
            ],
            [
              [-1.148985, 42.432848],
              [-1.182826, 42.424591],
              [-1.187799, 42.40303],
              [-1.15877, 42.409663],
              [-1.148985, 42.432848],
            ],
          ],
        ],
      },
    },
  ],
  style: {
    stroke: "#000000",
    fill: "#3498DB",
    "stroke-width": 1,
    "fill-opacity": 0.8,
  },
};
