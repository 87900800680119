import React, { useState, useEffect } from "react";
import CommonLoginRegisterComp from "./CommonLoginRegisterComp";
import { msalInstance, loginRequest } from "../msalConfig";
import { useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";

const Login = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          const silentRequest = {
            ...loginRequest,
            account: accounts[0]
          };
          
          try {
            const response = await msalInstance.acquireTokenSilent(silentRequest);
            await handleTokenResponse(response);
          } catch (error) {
            console.warn("Silent token acquisition failed, using interactive method");
            if (error.name !== "InteractionRequiredAuthError") {
              throw error;
            }
          }
        }
      } catch (error) {
        console.error("Authentication initialization error:", error);
        setError("Failed to initialize authentication. Please try again.");
      }
    };

    initializeAuth();
  }, [navigate]);

  const handleTokenResponse = async (response) => {
    try {
      const { accessToken, account } = response;
      
      const backendResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/microsoft`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify({ 
          id_token: accessToken,
          email: account.username,
          name: account.name
        }),
      });

      if (!backendResponse.ok) {
        const errorData = await backendResponse.json();
        throw new Error(errorData.detail || "Failed to authenticate with the server");
      }

      const userData = await backendResponse.json();
      
      localStorage.setItem("user", JSON.stringify({
        email: userData.email,
        full_name: userData.full_name,
        status: "logged_in",
        id: userData.user_id,
        token: userData.access_token,
      }));

      navigate("/who");
    } catch (error) {
      console.error("Token handling error:", error);
      setError("Authentication failed. Please try again.");
      setLoading(false);
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      setLoading(true);
      setError("");

      const response = await msalInstance.loginPopup(loginRequest);
      await handleTokenResponse(response);
    } catch (error) {
      console.error("Microsoft login error:", error);
      setError("Microsoft login failed. Please try again.");
      setLoading(false);
    }
  };

  const handleMicrosoftLogout = async () => {
    try {
      const logoutRequest = {
        account: msalInstance.getActiveAccount(),
        postLogoutRedirectUri: window.location.origin,
      };

      await msalInstance.logoutPopup(logoutRequest);
      
      localStorage.removeItem("user");
      
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/microsoft-logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
      setError("Logout failed. Please try again.");
    }
  };

  return (
    <CommonLoginRegisterComp
      title1="Sign In"
      title2="to your account"
      errorMessage={error}
      buttonText={loading ? "Signing in..." : "Sign in with Microsoft"}
      handleMicrosoftLogin={handleMicrosoftLogin}
      handleMicrosoftLogout={handleMicrosoftLogout}
    />
  );
};

export default Login;