import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  report: null,
  loading: true,
  error: null,
};

export const fetchBrazilReport = createAsyncThunk(
  "brazil/fetchBrazilReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/dashboard/brazil-who-summary`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch report");
      }
      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const brazilSlice = createSlice({
  name: "brazil",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrazilReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBrazilReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
      })
      .addCase(fetchBrazilReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default brazilSlice.reducer;
