import React, { useState, useEffect } from 'react';

function Profile() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.email) {
        try {
          // Fetch all users
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/alluser`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const allUsers = await response.json();

          // Find the user with the email from localStorage
          const currentUser = allUsers.find(u => u.email === user.email);

          if (currentUser) {
            setUserData(currentUser);
          } else {
            setError('User not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to load user data.');
        } finally {
          setLoading(false);
        }
      } else {
        setError('No user email found in localStorage.');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!userData) {
    return <div>No user data available.</div>;
  }

  return (
    <div className="profile-section d-flex align-items-center justify-content-center m-auto h-100">
      <div className="row g-0 w-100 position-relative">
        <div className="col-md-4" style={{ backgroundColor: '#367693' }}>
          <div className='text-white d-flex align-items-center justify-content-center flex-column h-100'>
            <div className='user-img mb-2'>
              <img 
                src="assets/images/profile_image.png" 
                className="w-100 h-100 rounded-circle" 
                alt="User-Profile-Image" 
                style={{objectFit: 'cover', width: '100px', height: '100px'}} 
              />
            </div>
            <h6 className="font-weight-bold">{userData.full_name}</h6>
            <p>{userData.role}</p>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card-body p-lg-5 p-md-4 border position-relative">
            <h6 className="border-bottom pb-2 font-weight-bold">Information</h6>
            <div className="row">
              <div className="col-6">
                <p className="mb-1 font-weight-bold"><i className="fa fa-user me-2" aria-hidden="true"></i>Full Name</p>
                <h6 className="text-muted">{userData.full_name}</h6>
              </div>
              <div className="col-6">
                <p className="mb-1 font-weight-bold"><i className="fa fa-envelope me-2" aria-hidden="true"></i>Email</p>
                <h6 className="text-muted">{userData.email}</h6>
              </div>
            </div>
            <h6 className="border-bottom mt-4 pb-2 font-weight-bold">Other Information</h6>
            <div className="row">
              <div className="col-sm">
                <p className="mb-1 font-weight-bold"><i className="fa fa-phone me-2" aria-hidden="true"></i>Phone</p>
                <h6 className="text-muted">{userData.phone || 'N/A'}</h6>
              </div>
            </div>
            {/* <ul className="list-inline mt-4 mb-2">
              <li className="list-inline-item"><a href="#" data-toggle="tooltip" data-placement="bottom" title="facebook"><i className="mdi mdi-facebook"></i></a></li>
              <li className="list-inline-item"><a href="#" data-toggle="tooltip" data-placement="bottom" title="twitter"><i className="mdi mdi-twitter"></i></a></li>
              <li className="list-inline-item"><a href="#" data-toggle="tooltip" data-placement="bottom" title="instagram"><i className="mdi mdi-instagram"></i></a></li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;