import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

import { generateComprehensivePDF } from "../../utils/cfunctions";

import China from "./China";
import Italy from "./Italy";
import Russia from "./Russia";
import Spain from "./Spain";
import Whoo from "./Whoo";

import "./styles/PDF.css";
import { useNavigate } from "react-router-dom";
import Indonesia from "./Indonesia";
import Brazil from "./Brazil";
import France from "./France";

import { IoMdArrowRoundBack } from "react-icons/io";
import { FaFileDownload } from "react-icons/fa";

const PDF = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const whoRef = useRef(null);
  const russiaRef = useRef(null);
  const spainRef = useRef(null);
  const italyRef = useRef(null);
  const chinaRef = useRef(null);
  const indonesiaRef = useRef(null);
  const brazilRef = useRef(null);
  const franceRef = useRef(null);

  const reportRussia = useSelector((state) => state.russia.report);
  const reportSpain = useSelector((state) => state.spain.report);
  const reportItaly = useSelector((state) => state.italy.report);
  const reportChina = useSelector((state) => state.china.report);
  const reportWHO = useSelector((state) => state.who.report);
  const reportIndonesia = useSelector((state) => state.indonesia.report);
  const reportBrazil = useSelector((state) => state.brazil.report);
  const reportFrance = useSelector((state) => state.france.report);

  return (
    <div className="pdf_page_container">
      <div className="get_back_btn">
        <button disabled={loading} onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack />
        </button>
      </div>
      <div className="container" style={{ padding: "1.5rem" }}>
        <Whoo ref={whoRef} />
        <Russia ref={russiaRef} />
        <Italy ref={italyRef} />
        <Spain ref={spainRef} />
        <China ref={chinaRef} />
        <Indonesia ref={indonesiaRef} />
        <Brazil ref={brazilRef} />
        <France ref={franceRef} />
      </div>
      <div className="pdf_download_btn">
        <button
          disabled={loading}
          onClick={() =>
            generateComprehensivePDF(
              whoRef,
              russiaRef,
              italyRef,
              spainRef,
              chinaRef,
              indonesiaRef,
              brazilRef,
              franceRef,
              reportWHO,
              reportRussia,
              reportItaly,
              reportSpain,
              reportChina,
              reportIndonesia,
              reportBrazil,
              reportFrance,
              setLoading
            )
          }
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Downloading...
            </>
          ) : (
            <>
              Download <FaFileDownload />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default PDF;
