import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { ErrorBoundary } from "./Whoo";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../UI/Loader/Loader";
import { fetchChinaReport } from "../../redux/slices/china.slice";
import { AgMapChartWrapper } from "../AgMapChart/AgMapChartWrapper";
import { chinaTopology } from "../AgMapChart/china_topology";

const China = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.china);

  const influenzaAgMapChartRef = useRef(null);

  useEffect(() => {
    if (!report) {
      dispatch(fetchChinaReport());
    }
  }, [dispatch, report]);

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      try {
        const influenzaAgMapChartImage = influenzaAgMapChartRef.current
          ? await influenzaAgMapChartRef.current.getAgMapChartImage()
          : null;

        return {
          influenzaAgMapChart: influenzaAgMapChartImage,
        };
      } catch (error) {
        console.error("Error getting chart images:", error);
        return null;
      }
    },
  }));

  if (loading) return <Loader />;
  // if (error) return <p className="error-message">Error: {error}</p>;
  if (!report) return <p className="info-message">No report available.</p>;

  const renderSubtypeAnalysis = () => {
    if (!report.subtype_analysis || !Array.isArray(report.subtype_analysis)) {
      return <p className="warning-message">Subtype analysis data is unavailable.</p>;
    }

    return (
      <div className="report-section">
        <h5>Subtype Analysis</h5>
        <ul>
          {report.subtype_analysis.map((subtype, index) => (
            <li key={index}>
              <i className="fa fa-check-circle icon"></i> {subtype}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderReport = () => {
    return (
      <div>
        {report.title && <h6 className="mt-4 mb-3 report-header">{report.title}</h6>}

        {report.specimen_summary && (
          <div className="report-item">
            <i className="fa fa-flask icon"></i>
            <span>{report.specimen_summary}</span>
          </div>
        )}

        {renderSubtypeAnalysis()}

        {report.sari_surveillance && (
          <div className="report-item">
            <i className="fa fa-heartbeat icon"></i>
            <span>{report.sari_surveillance}</span>
          </div>
        )}

        {report.ili_surveillance && (
          <div className="report-item">
            <i className="fa fa-stethoscope icon"></i>
            <span>{report.ili_surveillance}</span>
          </div>
        )}

        {report.total_burden && (
          <div className="report-item">
            <i className="fa fa-chart-line icon"></i>
            <span>{report.total_burden}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <h2 className="country-name">China</h2>
      <div className="card-body d-flex">
        <ErrorBoundary>
          <div className="content" style={{ width: "55%" }}>
            {renderReport()}
          </div>
          {/* <div
            className="charts-container"
            style={{ width: "45%", display: "flex", flexDirection: "column" }}
          >
            <AgMapChartWrapper topology={chinaTopology} ref={influenzaAgMapChartRef} />
          </div> */}
        </ErrorBoundary>
      </div>
    </>
  );
});

China.displayName = "China";
export default China;
