import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AllRoutes } from "./routes/AllRoutes";

import Footer from "./component/Footer";
import Header_menu from "./component/Header_menu";
import MainHeader from "./component/MainHeader";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showManeHeader, setShowManeHeader] = useState(false);
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const isLoggedIn = user?.status === "logged_in";

  useEffect(() => {
    const path = location.pathname;
    if (path === "/chatwithdb" || path === "/profile") {
      setShowManeHeader(true);
      setShowHeaderMenu(false);
      setShowButtons(false);
      setBackButton(true);
      setShowFooter(true);
    } else if (
      path === "/who" ||
      path === "/spain" ||
      path === "/italy" ||
      path === "/russia" ||
      path === "/china" ||
      path === "/indonesia" ||
      path === "/brazil" ||
      path === "/global-news" ||
      path === "/france"
    ) {
      setShowManeHeader(true);
      setShowHeaderMenu(true);
      setShowButtons(true);
      setBackButton(false);
      setShowFooter(true);
    } else if (
      path === "/register" ||
      path === "/login" ||
      path === "/resertpassword" ||
      path === "/global-news"
    ) {
      setShowManeHeader(false);
      setShowHeaderMenu(false);
      setShowButtons(false);
      setBackButton(false);
      setShowFooter(false);
    } else {
      setShowManeHeader(false);
      setShowHeaderMenu(false);
      setShowButtons(false);
      setBackButton(false);
      setShowFooter(false);
    }
  }, [location]);

  return (
    <>
      {showManeHeader && <MainHeader />}
      <div className={isLoggedIn ? "pt-5" : ""}>
        <div
          className={isLoggedIn ? "container container-custom" : ""}
          id="invoice"
        >
          {backButton && (
            <Link to="/who" className="btn p-0 mb-4 d-flex align-items-center">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <span className="ms-2 fw-medium">Back</span>
            </Link>
          )}
          <div className="card border-0">
            {showHeaderMenu && <Header_menu />}
            <AllRoutes isLoggedIn={isLoggedIn} />
          </div>
          {showButtons && (
            <div className="d-flex justify-content-end gap-2 function-button-tab">
              <button
                onClick={() => navigate("/pdf")}
                className="btn btn-outline-success"
              >
                Create Global Report
              </button>

              <Link to="/chatwithdb" className="btn btn-outline-primary">
                Chat with the Database
              </Link>
            </div>
          )}
        </div>
      </div>
      {showFooter && <Footer />}
    </>
  );
}

export default App;
