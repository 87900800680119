import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  report: null,
  loading: true,
  error: null,
};

const russiaSlice = createSlice({
  name: "russia",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRussiaReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRussiaReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
      })
      .addCase(fetchRussiaReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const fetchRussiaReport = createAsyncThunk(
  "russia/fetchRussiaReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/russia-influenza-summary`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch report");
      }
      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export default russiaSlice.reducer;
