import React from "react";

const CommonLoginRegisterComp = ({
  title1,
  title2,
  errorMessage,
  handleMicrosoftLogin,
}) => {
  return (
    <div className="d-flex align-items-stretch flex-wrap authentication-section">
      <div
        className="flex-1 text-white d-flex justify-content-between flex-column p-5"
        style={{ backgroundColor: "#3187ae" }}
      >
        <h4 className="mb-5">
          <span style={{ color: "#b0bc04" }}>AI-FLU</span>
          <span style={{ color: "White" }}> MONITOR</span>
        </h4>
        <div className="d-flex align-items-center text-center flex-1">
          <div>
            <img
              src="assets/images/signlogin/sign.png"
              alt="Dashboard"
              className="img-fluid authentication-image"
            />
          </div>
        </div>
      </div>
      <div className="flex-1 d-flex flex-column justify-content-center align-items-center p-5">
        <div className="authentication-form-content w-100">
          <h2 className="mb-4">
            <span style={{ color: "#b0bc04" }}>{title1}</span>
            {title2}
          </h2>

          {errorMessage && (
            <div className="form-group row">
              <div className="col-md offset-md">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
            </div>
          )}

          <button
            onClick={handleMicrosoftLogin}
            className="btn btn-outline-primary w-100 mt-3"
          >
            Login with Microsoft
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommonLoginRegisterComp;