//Source: https://datacatalog.worldbank.org/search/dataset/0037712andor
export const data = [
    { name: "Albania", value: 83.7 },
    { name: "Algeria", value: 99.7 },
    { name: "Andorra", value: 100 },
    { name: "Angola", value: 50 },
    { name: "Antigua and Barbuda", value: 100 },
    { name: "Argentina", value: 99.9 },
    { name: "Armenia", value: 98.4 },
    { name: "Australia", value: 100 },
    { name: "Austria", value: 100 },
    { name: "Azerbaijan", value: 98.3 },
    { name: "Bahamas", value: 100 },
    { name: "Bahrain", value: 100 },
    { name: "Bangladesh", value: 26.5 },
    { name: "Barbados", value: 100 },
    { name: "Belarus", value: 99.6 },
    { name: "Belgium", value: 100 },
    { name: "Belize", value: 83 },
    { name: "Benin", value: 4.6 },
    { name: "Bhutan", value: 87 },
    { name: "Bolivia", value: 88.3 },
    { name: "Bosnia and Herzegovina", value: 41.65 },
    { name: "Botswana", value: 65.7 },
    { name: "Brazil", value: 96.5 },
    { name: "Brunei", value: 100 },
    { name: "Burkina Faso", value: 11.7 },
    { name: "Burundi", value: 0.2 },
    { name: "Cape Verde", value: 81.8 },
    { name: "Cambodia", value: 44.5 },
    { name: "Cameroon", value: 22.8 },
    { name: "Canada", value: 100 },
    { name: "Central African Republic", value: 0.900000000000001 },
    { name: "Chad", value: 8 },
    { name: "Chile", value: 100 },
    { name: "China", value: 83.2 },
    { name: "Colombia", value: 93.3 },
    { name: "Comoros", value: 11.3 },
    { name: "Democratic Republic of The Congo", value: 4.3 },
    { name: "Congo", value: 35.6 },
    { name: "Costa Rica", value: 95.9 },
    { name: "Ivory Coast", value: 31.7 },
    { name: "Croatia", value: 100 },
    { name: "Cuba", value: 94.3 },
    { name: "Cyprus", value: 100 },
    { name: "Czechia", value: 100 },
    { name: "Denmark", value: 100 },
    { name: "Djibouti", value: 9.6 },
    { name: "Dominica", value: 89.4 },
    { name: "Dominican Republic", value: 91.5 },
    { name: "Ecuador", value: 94.7 },
    { name: "Egypt", value: 99.9 },
    { name: "El Salvador", value: 92.7 },
    { name: "Equatorial Guinea", value: 24.1 },
    { name: "Eritrea", value: 11.5 },
    { name: "Estonia", value: 100 },
    { name: "Eswatini", value: 58.1 },
    { name: "Ethiopia", value: 7.5 },
    { name: "Fiji", value: 51.4 },
    { name: "Finland", value: 100 },
    { name: "France", value: 100 },
    { name: "Gabon", value: 89.7 },
    { name: "Gambia", value: 1.7 },
    { name: "Georgia", value: 90.6 },
    { name: "Germany", value: 100 },
    { name: "Ghana", value: 30.3 },
    { name: "Greece", value: 100 },
    { name: "Grenada", value: 88.3 },
    { name: "Guatemala", value: 48.1 },
    { name: "Guinea", value: 1 },
    { name: "Guinea-Bissau", value: 1 },
    { name: "Guyana", value: 82 },
    { name: "Haiti", value: 4.3 },
    { name: "Honduras", value: 49.5 },
    { name: "Hungary", value: 100 },
    { name: "Iceland", value: 100 },
    { name: "India", value: 71.1 },
    { name: "Indonesia", value: 86.9 },
    { name: "Iran", value: 96.4 },
    { name: "Iraq", value: 99.3 },
    { name: "Ireland", value: 100 },
    { name: "Israel", value: 100 },
    { name: "Italy", value: 100 },
    { name: "Jamaica", value: 82.5 },
    { name: "Japan", value: 100 },
    { name: "Jordan", value: 99.9 },
    { name: "Kazakhstan", value: 93.9 },
    { name: "Kenya", value: 23.9 },
    { name: "Kiribati", value: 12.4 },
    { name: "North Korea", value: 12.5 },
    { name: "South Korea", value: 100 },
    { name: "Kuwait", value: 100 },
    { name: "Kyrgyzstan", value: 78.2 },
    { name: "Laos", value: 9.3 },
    { name: "Latvia", value: 100 },
    { name: "Lesotho", value: 41.1 },
    { name: "Liberia", value: 0.4 },
    { name: "Lithuania", value: 100 },
    { name: "Luxembourg", value: 100 },
    { name: "Madagascar", value: 1.4 },
    { name: "Malawi", value: 1.6 },
    { name: "Malaysia", value: 93.8 },
    { name: "Maldives", value: 99.5 },
    { name: "Mali", value: 0.900000000000001 },
    { name: "Malta", value: 100 },
    { name: "Marshall Islands", value: 66.7 },
    { name: "Mauritania", value: 48.3 },
    { name: "Mauritius", value: 98.9 },
    { name: "Mexico", value: 84.6 },
    { name: "Micronesia", value: 13.3 },
    { name: "Moldova", value: 97.6 },
    { name: "Monaco", value: 100 },
    { name: "Mongolia", value: 53 },
    { name: "Montenegro", value: 62 },
    { name: "Morocco", value: 98.2 },
    { name: "Mozambique", value: 5.4 },
    { name: "Namibia", value: 47.3 },
    { name: "Nauru", value: 100 },
    { name: "Nepal", value: 35.2 },
    { name: "Netherlands", value: 100 },
    { name: "New Zealand", value: 100 },
    { name: "Nicaragua", value: 57.4 },
    { name: "Niger", value: 3 },
    { name: "Nigeria", value: 16.8 },
    { name: "North Macedonia", value: 79.2 },
    { name: "Norway", value: 100 },
    { name: "Oman", value: 100 },
    { name: "Pakistan", value: 50.7 },
    { name: "Palau", value: 43 },
    { name: "Panama", value: 100 },
    { name: "Papua New Guinea", value: 9.7 },
    { name: "Paraguay", value: 69.8 },
    { name: "Peru", value: 85.5 },
    { name: "Philippines", value: 48 },
    { name: "Poland", value: 100 },
    { name: "Portugal", value: 100 },
    { name: "Qatar", value: 100 },
    { name: "Romania", value: 100 },
    { name: "Russian Federation", value: 72.9 },
    { name: "Rwanda", value: 5.4 },
    { name: "Samoa", value: 37.2 },
    { name: "San Marino", value: 100 },
    { name: "Saudi Arabia", value: 100 },
    { name: "Senegal", value: 29.4 },
    { name: "Serbia", value: 80.6 },
    { name: "Seychelles", value: 100 },
    { name: "Sierra Leone", value: 0.800000000000001 },
    { name: "Singapore", value: 100 },
    { name: "Slovakia", value: 100 },
    { name: "Slovenia", value: 100 },
    { name: "Solomon Islands", value: 8.9 },
    { name: "Somalia", value: 3.8 },
    { name: "South Africa", value: 88.4 },
    { name: "South Sudan", value: 0 },
    { name: "Spain", value: 100 },
    { name: "Sri Lanka", value: 32.6 },
    { name: "Sudan", value: 62.8 },
    { name: "Suriname", value: 94.8 },
    { name: "Sweden", value: 100 },
    { name: "Switzerland", value: 100 },
    { name: "Syria", value: 96.3 },
    { name: "Tajikistan", value: 85.5 },
    { name: "Thailand", value: 85.1 },
    { name: "Timor-Leste", value: 15.2 },
    { name: "Togo", value: 11.4 },
    { name: "Tonga", value: 86.8 },
    { name: "Trinidad and Tobago", value: 100 },
    { name: "Tunisia", value: 99.9 },
    { name: "Türkiye", value: 95.4 },
    { name: "Turkmenistan", value: 99.9 },
    { name: "Tuvalu", value: 74.6 },
    { name: "Uganda", value: 0.700000000000001 },
    { name: "Ukraine", value: 95.2 },
    { name: "United Arab Emirates", value: 100 },
    { name: "United Kingdom", value: 100 },
    { name: "United States", value: 100 },
    { name: "Uruguay", value: 100 },
    { name: "Uzbekistan", value: 82.8 },
    { name: "Vanuatu", value: 6.9 },
    { name: "Venezuela", value: 95.5 },
    { name: "Vietnam", value: 96.1 },
    { name: "Yemen", value: 61.3 },
    { name: "Zambia", value: 10.2 },
    { name: "Zimbabwe", value: 30.3 },
  ];
  