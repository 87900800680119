import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

function Error() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    navigate("/who", { replace: true });
  } else {
    navigate("/", { replace: true });
  }

  return (
    <div className="page-wrap d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block">404</span>
            <div className="mb-4 lead">
              The page you are looking for was not found.
            </div>
            <a href="/" className="btn btn-link">
              Back to Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error;
