import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactMarkdown from "react-markdown";
import { IoSend } from "react-icons/io5";
import { TiAttachment } from "react-icons/ti";
import { Spinner } from "react-bootstrap";

function Chatwithdb() {
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const welcomeMessageAdded = useRef(false);
  // const navigate = useNavigate(); // Using useNavigate for navigation

  // useEffect(() => {
  //   // Check if user is logged in
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   const isLoggedIn = user && user.status === 'logged_in';
  //   if (!isLoggedIn) {
  //     alert('You must be logged in to access for chat.');
  //     navigate('/login',{ replace: false }); // Redirect to login page
  //   }
  // }, [navigate]);

  const appendMessage = (sender, message, isMarkdown = false) => {
    const currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender, message, time: currentTime, isMarkdown },
    ]);
  };

  useEffect(() => {
    // Add welcome message only once when the component mounts
    if (!welcomeMessageAdded.current) {
      appendMessage("Bot", "Hi, how can I assist you today?");
      welcomeMessageAdded.current = true;
    }
  }, []);

  useEffect(() => {
    const handleFormSubmit = (e) => {
      e.preventDefault();
      if (userInput.trim() !== "") {
        setLoading(true);
        appendMessage("You", userInput);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/chatbot/chatbot`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_query: userInput }),
        })
          .then((response) => response.json())
          .then((data) => {
            appendMessage("Bot", data.response, true);
          })
          .catch(() => {
            appendMessage(
              "Bot",
              "Sorry, there was an error processing your request."
            );
          })
          .finally(() => {
            setLoading(false);
          });
        setUserInput("");
      }
    };

    const form = document.getElementById("chat-form");
    form.addEventListener("submit", handleFormSubmit);

    return () => {
      form.removeEventListener("submit", handleFormSubmit);
    };
  }, [userInput]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="bg-light chat-container">
      <div className="card-body" style={{ backgroundColor: "#e5ebf2" }}>
        <div
          className="chat-messages p-4 h-100"
          id="chat-container"
          style={{ overflowY: "auto" }}
          ref={chatContainerRef}
        >
          {messages.map((msg, index) => (
            <div className="d-flex mb-4" key={index}>
              {msg.sender === "You" && (
                <div className="flex-shrink-0">
                  <i
                    className="fa fa-user-circle-o fa-2x"
                    aria-hidden="true"
                    style={{
                      color: "#64748b",
                      backgroundColor: "#d9d9d9",
                      borderRadius: "50%",
                    }}
                  ></i>
                </div>
              )}
              <div
                className={`flex-grow-1 ${msg.sender === "You" ? "ms-3" : ""}`}
              >
                <div
                  className={`bg-${
                    msg.sender === "You" ? "primary" : "secondary"
                  } text-white p-2 rounded-3 d-inline-block chat-message-card`}
                >
                  {msg.isMarkdown ? (
                    <ReactMarkdown>{msg.message}</ReactMarkdown>
                  ) : (
                    msg.message
                  )}
                </div>
                <div className="text-muted small mt-1">{msg.time}</div>
              </div>
            </div>
          ))}
        </div>
        <form id="chat-form">
          <div className="chat-input">
            <div className="input-group">
              <button className="chat-form-attachment-btn" type="button" disabled={loading}>
                <TiAttachment />
              </button>
              <input
                type="text"
                id="user_input"
                className="form-control shadow-none"
                placeholder="Write your message..."
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
              />
              <button className="chat-form-send-btn" type="submit" disabled={loading}>
                {loading ? <Spinner animation="grow" size="sm" /> : <IoSend />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Chatwithdb;
