import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGlobalNews,
  fetchHistoricalNews,
  fetchHistoricalWHOOutbreaks,
} from "../../redux/slices/globalNews.slice";
import {
  Spinner,
  Alert,
  Button,
  Container,
  Pagination,
  Form,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { format } from "date-fns";
import { LuRefreshCcw, LuArrowDownToLine } from "react-icons/lu";
import { handleDownloadPdf } from "../../utils/pdfGenerator";

import "./styles/globalNews.css";
import { Loader } from "../UI/Loader/Loader";

const GlobalNews = () => {
  const dispatch = useDispatch();
  const {
    news,
    whoOutbreaks,
    loading,
    error,
    lastUpdated,
    pagination,
    historicalNews,
    historicalWHOOutbreaks,
  } = useSelector((state) => state.globalNews);

  // State for historical view
  const [selectedCountry, setSelectedCountry] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [historicalData, setHistoricalData] = useState([]);
  const [isHistoricalView, setIsHistoricalView] = useState(false);
  const [historicalLoading, setHistoricalLoading] = useState(false);
  const [expandedOutbreak, setExpandedOutbreak] = useState(false);
  const printRef = useRef();

  // Fetch current news
  useEffect(() => {
    if (!isHistoricalView) {
      dispatch(fetchGlobalNews());
    }
  }, [dispatch, isHistoricalView]);

  useEffect(() => {
    if (isHistoricalView) {
      const params = {
        page: currentPage,
        limit: pageSize,
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
      };

      if (selectedCountry === "who-outbreaks") {
        dispatch(fetchHistoricalWHOOutbreaks(params));
      } else {
        dispatch(
          fetchHistoricalNews({
            ...params,
            ...(selectedCountry !== "all" && { country: selectedCountry }),
          })
        );
      }
    }
  }, [
    currentPage,
    selectedCountry,
    startDate,
    endDate,
    isHistoricalView,
    dispatch,
    pageSize,
  ]);

  const handleHistoricalSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    const params = {
      page: 1,
      limit: pageSize,
      ...(selectedCountry !== "all" && { country: selectedCountry }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
    };
    dispatch(fetchHistoricalNews(params));
  };

  const renderPagination = () => {
    const paginationData =
      selectedCountry === "who-outbreaks"
        ? pagination.who_pagination
        : pagination;

    if (!paginationData || paginationData.total_pages <= 1) return null;

    return (
      <Pagination className="justify-content-center mt-4">
        <Pagination.First
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => setCurrentPage((curr) => Math.max(1, curr - 1))}
          disabled={currentPage === 1}
        />

        {[...Array(paginationData.total_pages)].map((_, idx) => (
          <Pagination.Item
            key={idx + 1}
            active={currentPage === idx + 1}
            onClick={() => setCurrentPage(idx + 1)}
          >
            {idx + 1}
          </Pagination.Item>
        ))}

        <Pagination.Next
          onClick={() =>
            setCurrentPage((curr) =>
              Math.min(paginationData.total_pages, curr + 1)
            )
          }
          disabled={currentPage === paginationData.total_pages}
        />
        <Pagination.Last
          onClick={() => setCurrentPage(paginationData.total_pages)}
          disabled={currentPage === paginationData.total_pages}
        />
      </Pagination>
    );
  };

  const renderNews = () => {
    if (selectedCountry === "who-outbreaks") {
      return renderWHOOutbreaks();
    }

    if (!news || typeof news !== "object") {
      return (
        <div className="text-center text-gray-600 mt-8">
          No news data available
        </div>
      );
    }

    const countries = Object.keys(news);

    if (countries.length === 0) {
      return (
        <div className="text-center text-gray-600 mt-8">
          No news found for any country
        </div>
      );
    }

    return countries?.map((country) => {
      if (selectedCountry !== "all" && selectedCountry !== country) return null;

      const countryArticles = news[country];
      if (!countryArticles || countryArticles.length === 0) return null;

      return (
        <section key={country} className="newspaper-section mb-12">
          <div className="flex items-center justify-between mb-6">
            <h2
              className="text-3xl font-serif mb-6 pb-2 border-b-2 border-black"
              style={{
                color: "#3187ae",
              }}
            >
              {country}
            </h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {countryArticles?.map((article, idx) => (
              <article
                key={idx}
                className={`newspaper-section mb-8 pb-8 border-b border-gray-300`}
              >
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">
                    {article.title.replace(/[\[\]]/g, "")}
                  </h3>
                  <p className="mb-4">
                    <span className="badge text-bg-secondary">
                      {format(new Date(article.timestamp), "MMMM dd, yyyy")}
                    </span>
                  </p>
                  <p></p>
                  <div className="article-content">
                    <p className="text-gray-800 leading-relaxed">
                      {article.summary.replace(/[\[\]]/g, "")}
                    </p>
                    <div className="mt-4 flex flex-wrap items-center justify-between border-bottom pb-2">
                      {article.source && (
                        <p className="text-sm italic text-gray-600">
                          Source:
                          <button
                            type="button"
                            onClick={() => handleReadMore(article?.url)}
                            className="btn btn-link"
                          >
                            {article?.source}
                          </button>
                        </p>
                      )}
                      {article.topics && (
                        <div className="d-flex align-items-center gap-2 mt-2">
                          {article.topics.map((topic, i) => (
                            <span key={i} className="badge text-bg-primary">
                              {topic}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </section>
      );
    });
  };
  const renderWHOOutbreaks = () => {
    const outbreaksToRender = isHistoricalView
      ? historicalWHOOutbreaks
      : whoOutbreaks;

    if (!outbreaksToRender || outbreaksToRender.length === 0) {
      return (
        <div className="text-center text-gray-600 mt-8">
          No WHO outbreak data available
        </div>
      );
    }

    return (
      <section className="mb-12">
        <div className="grid grid-cols-1 gap-4">
          {outbreaksToRender.map((outbreak, idx) => (
            <article
              key={idx}
              className="bg-white border-gray-200 hover:shadow-md transition-shadow duration-200"
            >
              <div className="p-3 border-bottom">
                <div className="flex justify-between items-start">
                  <h3 className="text-xl font-bold mb-2 flex-grow">
                    {outbreak?.title}
                  </h3>
                  <span className="badge text-bg-secondary">
                    {format(new Date(outbreak?.date), "MMMM dd, yyyy")}
                  </span>
                </div>
                <div
                  className={`mt-4 ${
                    expandedOutbreak === idx ? "" : "line-clamp-3"
                  }`}
                >
                  <p className="text-gray-800">{outbreak?.situation_summary}</p>
                </div>
                {outbreak?.url && (
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      type="button"
                      onClick={() => handleReadMore(outbreak?.url)}
                      className="btn btn-link"
                    >
                      Read more
                    </button>
                  </div>
                )}
              </div>
            </article>
          ))}
        </div>
      </section>
    );
  };
  const renderHistoricalWHOOutbreaks = () => {
    if (!historicalWHOOutbreaks || historicalWHOOutbreaks.length === 0) {
      return (
        <div className="text-center text-gray-600 mt-8">
          No historical WHO outbreak data available
        </div>
      );
    }

    return historicalWHOOutbreaks.map((dayData) => (
      <Card key={dayData.day_id} className="mb-4">
        <Card.Header>
          <h3 className="mb-0">
            {format(new Date(dayData.day_id), "EEEE, MMMM dd, yyyy")}
          </h3>
        </Card.Header>
        <Card.Body>
          <div className="grid grid-cols-1 gap-4">
            {dayData.who_outbreaks.map((outbreak, idx) => (
              <article
                key={idx}
                className="bg-white border rounded p-4 hover:shadow-md transition-shadow duration-200"
              >
                <div className="flex justify-between items-start mb-3">
                  <h4 className="text-xl font-bold mb-0">{outbreak.title}</h4>
                  <span className="badge text-bg-secondary">
                    {format(new Date(outbreak.date), "MMMM dd, yyyy")}
                  </span>
                </div>
                {outbreak.situation_summary && (
                  <p className="text-gray-800 mb-3">
                    {outbreak.situation_summary}
                  </p>
                )}
                {outbreak.url && (
                  <div className="text-end">
                    <Button
                      variant="link"
                      onClick={() => window.open(outbreak.url, "_blank")}
                      className="p-0"
                    >
                      Read more
                    </Button>
                  </div>
                )}
              </article>
            ))}
          </div>
        </Card.Body>
      </Card>
    ));
  };
  const handleReadMore = (url) => {
    if (!url) return;
    window.open(url, "_blank");
  };
  const renderHistoricalNews = () => {
    if (!historicalNews || historicalNews.length === 0) {
      return (
        <div className="text-center text-gray-600 mt-8">
          No historical news data available
        </div>
      );
    }

    return historicalNews.map((dayData) => (
      <Card key={dayData.day_id} className="mb-4">
        <Card.Header>
          <h3 className="mb-0">
            {format(new Date(dayData.day_id), "EEEE, MMMM dd, yyyy")}
          </h3>
        </Card.Header>
        <Card.Body>
          {Object.entries(dayData.data).map(([country, articles]) => (
            <div key={country} className="mb-4">
              <h4 className="border-bottom pb-2">{country}</h4>
              <Row>
                {articles.map((article, idx) => (
                  <Col key={idx} md={6} className="mb-3">
                    <Card>
                      <Card.Body>
                        <h5>{article.title}</h5>
                        <p className="text-muted small">
                          {format(new Date(article.timestamp), "MMM dd, yyyy")}
                        </p>
                        <p>{article.summary}</p>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            {article.topics?.map((topic, i) => (
                              <span key={i} className="badge bg-primary me-1">
                                {topic}
                              </span>
                            ))}
                          </div>
                          {article.url && (
                            <Button
                              variant="link"
                              onClick={() => window.open(article.url, "_blank")}
                            >
                              Read more
                            </Button>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Card.Body>
      </Card>
    ));
  };

  const renderContent = () => {
    if (loading || historicalLoading) {
      return <Loader />;
    }

    if (isHistoricalView) {
      if (selectedCountry === "who-outbreaks") {
        return renderHistoricalWHOOutbreaks();
      }
      return renderHistoricalNews();
    }

    return renderNews();
  };

  return (
    <div className="min-h-screen bg-light">
      <Container className="py-4">
        <Card className="mb-4">
          <Card.Header>
            <h2 className="text-center display-4 my-3">
              Global Health Monitor
            </h2>
          </Card.Header>
          <Card.Body>
            <Form
              onSubmit={handleHistoricalSearch}
              className="d-flex flex-column flex-md-row align-items-center justify-content-between"
            >
              <Row className="align-items-center w-100 p-3">
                <Col xs={12} md={3} className="mb-3 mb-md-0">
                  <Form.Group>
                    <Form.Label>View Mode</Form.Label>
                    <Form.Select
                      value={isHistoricalView ? "historical" : "current"}
                      onChange={(e) => {
                        setIsHistoricalView(e.target.value === "historical");
                        setCurrentPage(1);
                      }}
                    >
                      <option value="current">Current News</option>
                      <option value="historical">Historical View</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col xs={12} md={3} className="mb-3 mb-md-0">
                  <Form.Group>
                    <Form.Label>Source</Form.Label>
                    <Form.Select
                      value={selectedCountry}
                      onChange={(e) => {
                        setSelectedCountry(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="all">All Countries</option>
                      <option value="who-outbreaks">WHO Outbreak News</option>
                      {news &&
                        Object.keys(news).map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                {/* Uncomment the date fields for historical view */}
                {/* {isHistoricalView && (
                  <>
                    <Col xs={12} md={2} className="mb-3 mb-md-0">
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={2} className="mb-3 mb-md-0">
                      <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={2}>
                      <Button type="submit" variant="primary" className="w-100">
                        Search
                      </Button>
                    </Col>
                  </>
                )} */}
              </Row>

              {!isHistoricalView && (
                <Row>
                  <Col xs={12} md={2}>
                    <div className="d-flex gap-2">
                      <Button
                        variant="outline-secondary"
                        onClick={() => dispatch(fetchGlobalNews())}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <LuRefreshCcw />
                        )}
                      </Button>
                      <Button
                        variant="outline-primary"
                        onClick={() =>
                          handleDownloadPdf(
                            isHistoricalView ? historicalData : news,
                            whoOutbreaks,
                            lastUpdated
                          )
                        }
                      >
                        <LuArrowDownToLine />
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </Card.Body>
        </Card>

        {error && (
          <Alert variant="danger" className="mb-4">
            {error.detail || error}
          </Alert>
        )}

        <div ref={printRef}>
          {renderContent()}
          {isHistoricalView && renderPagination()}
        </div>

        <footer className="text-center text-muted mt-4 pt-4 border-top">
          <p>© {new Date().getFullYear()} AI-Flu Monitor</p>
        </footer>
      </Container>
    </div>
  );
};

export default GlobalNews;
