// src/components/pages/Whoo.jsx
import React, {
  useEffect,
  useMemo,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import "./styles/Whoo.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, refreshData } from "../../redux/slices/who.slice";
import { getCurrentWeekNumber } from "../../utils/cfunctions";
import EChartsWrapper from "../EChartsWrapper";

import { LuRefreshCcw } from "react-icons/lu";
import { IconButton } from "../UI/IconButton";
import { Loader } from "../UI/Loader/Loader";
import { MapChartWrapper } from "../MapGraph/MapChartWrapper";
import { AgMapChartWrapper } from "../AgMapChart/AgMapChartWrapperWHO";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error("Error Boundary Caught an error:", error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error">
          Something went wrong: {this.state.error.message}
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

const Whoo = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const influenzaAgMapChartRef = useRef(null);
  const iliAgMapChartRef = useRef(null);
  const sariAgMapChartRef = useRef(null);

  const {
    influenzaData,
    iliData,
    sariData,
    report,
    loading,
    error,
    dataLoaded,
    lastFetched,
  } = useSelector((state) => state.who);

  const memoizedInfluenzaData = useMemo(() => influenzaData, [influenzaData]);
  const memoizedIliData = useMemo(() => iliData, [iliData]);
  const memoizedSariData = useMemo(() => sariData, [sariData]);

  useEffect(() => {
    const FRESHNESS_THRESHOLD = 24 * 60 * 60 * 1000;
    const now = Date.now();

    if (
      !dataLoaded ||
      !lastFetched ||
      now - lastFetched > FRESHNESS_THRESHOLD
    ) {
      dispatch(fetchData());
    }
  }, [dispatch, dataLoaded, lastFetched]);

  const handleRefresh = () => {
    dispatch(refreshData());
    dispatch(fetchData());
  };

  const renderWhoReport = () => {
    if (!report) return <div>No report data available</div>;

    return (
      <div>
        <h6 className="mt-4 mb-3 report-header">{report.title}</h6>

        <div className="report-item">
          <i className="fa fa-globe icon"></i>
          <span>{report.global_coverage}</span>
        </div>

        <div className="report-item">
          <i className="fa fa-vials icon"></i>
          <span>{report.specimen_summary}</span>
        </div>

        <h6 className="mt-4 mb-3 report-header">Subtype Analysis</h6>
        {report?.subtype_analysis?.map((summary, index) => (
          <div className="report-item" key={index}>
            <i className="fa fa-check-circle icon"></i>
            <span>{summary}</span>
          </div>
        ))}

        <div className="report-item">
          <i className="fa fa-heartbeat icon"></i>
          <span>{report.sari_surveillance}</span>
        </div>

        <div className="report-item">
          <i className="fa fa-virus icon"></i>
          <span>{report.ili_surveillance}</span>
        </div>

        <h6 className="mt-4 mb-3 report-header">Total Burden</h6>
        <div className="report-item">
          <i className="fa fa-syringe icon"></i>
          <span>{report.total_burden}</span>
        </div>
      </div>
    );
  };

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      const influenzaAgMapChartImage = influenzaAgMapChartRef.current
        ? await influenzaAgMapChartRef.current.getAgMapChartImage()
        : null;
      const iliMapChartImage = iliAgMapChartRef.current
        ? await iliAgMapChartRef.current.getAgMapChartImage()
        : null;
      const sariMapChartImage = sariAgMapChartRef.current
        ? await sariAgMapChartRef.current.getAgMapChartImage()
        : null;

      return {
        influenzaAgMapChart: influenzaAgMapChartImage,
        iliAgMapChart: iliMapChartImage,
        sariAgMapChart: sariMapChartImage,
      };
    },
  }));

  if (loading && !dataLoaded) return <Loader />;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <ErrorBoundary>
      <div className="d-flex justify-content-between">
        <h2 className="country-name">WHO</h2>
        <IconButton Icon={LuRefreshCcw} onClick={handleRefresh} />
      </div>
      <div className="card-body d-flex flex-row justify-content-between">
        <div className="content" style={{ width: "55%" }}>
          <h5>
            Report Year {new Date().getFullYear()}, Week {getCurrentWeekNumber()}
          </h5>
          {renderWhoReport()}
        </div>

        <div
          className="charts-container"
          style={{ width: "45%", display: "flex", flexDirection: "column" }}
        >
          <AgMapChartWrapper
            ref={influenzaAgMapChartRef}
            dataType="Specimens"
            title="Influenza Specimens"
            colorName="Number of specimens"
            color="#8884d8"
            data={memoizedInfluenzaData}
          />
          <AgMapChartWrapper
            ref={iliAgMapChartRef}
            dataType="ILI"
            title="Influenza-like Illness (ILI)"
            colorName="ILI percentage"
            unit="%"
            color="#82ca9d"
            data={memoizedIliData}
          />
          <AgMapChartWrapper
            ref={sariAgMapChartRef}
            dataType="SARI"
            title="Severe Acute Respiratory Infection (SARI)"
            colorName="SARI percentage"
            unit="%"
            color="#ffc658"
            data={memoizedSariData}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
});

export default Whoo;
