import React from "react";
import PropTypes from "prop-types";

export const Table = ({ columns, data, customStyles }) => {
  const {
    headerBgColor,
    headerTextColor,
    rowBgColor,
    rowTextColor,
    alternateRowColor,
  } = customStyles;

  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead
          style={{ backgroundColor: headerBgColor, color: headerTextColor }}
        >
          <tr>
            {columns.map((col) => (
              <th key={col.accessor} scope="col">
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor:
                    index % 2 === 0
                      ? rowBgColor
                      : alternateRowColor || rowBgColor,
                  color: rowTextColor,
                }}
              >
                {columns.map((col) => (
                  <td key={col.accessor}>{row[col.accessor]}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  customStyles: PropTypes.shape({
    headerBgColor: PropTypes.string,
    headerTextColor: PropTypes.string,
    rowBgColor: PropTypes.string,
    rowTextColor: PropTypes.string,
    alternateRowColor: PropTypes.string,
  }),
};

Table.defaultProps = {
  customStyles: {
    headerBgColor: "#343a40",
    headerTextColor: "#ffffff",
    rowBgColor: "#ffffff",
    rowTextColor: "#000000",
    alternateRowColor: "#f2f2f2",
  },
};
