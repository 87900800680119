import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";

import whoReducer from "./slices/who.slice";
import russiaReducer from "./slices/russia.slice";
import italyReducer from "./slices/italy.slice";
import spainReducer from "./slices/spain.slice";
import chinaReducer from "./slices/china.slice";
import brazilReducer from "./slices/brazil.slice";
import indonesiaReducer from "./slices/indonesia.slice"
import franceReducer from "./slices/france.slice";
import globalNewsReducer from "./slices/globalNews.slice";

const whoPersistConfig = {
  key: 'who',
  storage: storage,
  whitelist: ['report'],
};

const russiaPersistConfig = {
  key: 'russia',
  storage: storage,
};

const italyPersistConfig = {
  key: 'italy',
  storage: storage,
};

const spainPersistConfig = {
  key: 'spain',
  storage: storage,
};

const chinaPersistConfig = {
  key: 'china',
  storage: storage,
};

const globalNewsPersistConfig = {
  key: 'globalNews',
  storage: storage,
};

const indonesiaPersistConfig = {
  key: 'indonesia',
  storage: storage,
};

const brazilPersistConfig = {
  key: 'brazil',
  storage: storage,
};

const persistedWhoReducer = persistReducer(whoPersistConfig, whoReducer);
// const persistedRussiaReducer = persistReducer(russiaPersistConfig, russiaReducer);
// const persistedItalyReducer = persistReducer(italyPersistConfig, italyReducer);
// const persistedSpainReducer = persistReducer(spainPersistConfig, spainReducer);
// const persistedChinaReducer = persistReducer(chinaPersistConfig, chinaReducer);
// const persistedGlobalNewsReducer = persistReducer(globalNewsPersistConfig, globalNewsReducer);
// const persistedIndonesiaReducer = persistReducer(indonesiaPersistConfig, indonesiaReducer)
// const persistedBrazilReducer = persistReducer(brazilPersistConfig, brazilReducer);

export const store = configureStore({
  reducer: {
    who: persistedWhoReducer,
    russia: russiaReducer,
    italy: italyReducer,
    spain: spainReducer,
    china: chinaReducer,
    globalNews: globalNewsReducer,
    indonesia: indonesiaReducer,
    brazil: brazilReducer,
    france:  franceReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
