import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGlobalNews } from "../../redux/slices/globalNews.slice";
import { Spinner, Alert, Button, Container } from "react-bootstrap";
import { format } from "date-fns";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { LuRefreshCcw, LuArrowDownToLine } from "react-icons/lu";
import "./styles/globalNews.css";
import { handleDownloadPdf } from "../../utils/pdfGenerator";

const GlobalNews = () => {
  const dispatch = useDispatch();
  const { news, loading, error, lastUpdated } = useSelector(
    (state) => state.globalNews
  );
  const [selectedCountry, setSelectedCountry] = useState("all");
  const printRef = useRef();

  useEffect(() => {
    dispatch(fetchGlobalNews());
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(fetchGlobalNews());
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "EEEE, MMMM dd, yyyy");
  };

  const renderNews = () => {
    const countries = Object.keys(news);
    return countries.map((country) => {
      if (selectedCountry !== "all" && selectedCountry !== country) return null;

      const countryArticles = news[country];
      if (!countryArticles || countryArticles.length === 0) return [];

      return (
        <section
          key={country}
          className="newspaper-section mb-8 pb-8 border-b border-gray-300"
        >
          <h2
            className="text-3xl font-serif mb-6 pb-2 border-b-2 border-black"
            style={{
              color: "#3187ae",
            }}
          >
            {country}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {countryArticles?.map((article, idx) => (
              <article
                key={idx}
                className={`newspaper-article ${
                  idx === 0 ? "md:col-span-2 featured-article" : ""
                }`}
              >
                <h3 className="text-xl font-bold mb-2">
                  {article.title.replace(/[\[\]]/g, "")}
                </h3>
                <p className="text-sm text-gray-600 mb-3">
                  {format(new Date(article.timestamp), "MMMM dd, yyyy")}
                </p>
                <div className="article-content">
                  <p className="text-gray-800 leading-relaxed">
                    {article.summary.replace(/[\[\]]/g, "")}
                  </p>
                  {article.source && (
                    <p className="text-sm italic text-gray-600 mt-2">
                      Source: {article.source}
                    </p>
                  )}
                </div>
              </article>
            ))}
          </div>
        </section>
      );
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Container className="max-w-7xl mx-auto py-8 px-4">
        <header className="text-center border-b-4 border-black py-6 mb-8 bg-white">
          <h1 className="text-6xl font-serif mt-4 mb-1">Global News</h1>
          <div className="flex flex-col md:flex-row justify-between items-center text-gray-600 mb-4">
            <p className="mb-4 md:mb-0">
              {formatDate(lastUpdated || new Date())}
            </p>
            <div className="functions-tab-container">
              <select
                className="form-select border border-gray-300 rounded px-3 py-2"
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                <option value="all">All Countries</option>
                {Object.keys(news).map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              <div className="functions-tab-btn-container">
                <Button
                  variant="outline-secondary"
                  onClick={handleRefresh}
                  disabled={loading}
                  className="px-4 py-2"
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </>
                  ) : (
                    <LuRefreshCcw />
                  )}
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => handleDownloadPdf(news, lastUpdated)}
                  className="px-4 py-2"
                >
                  <LuArrowDownToLine />
                </Button>
              </div>
            </div>
          </div>
        </header>

        {error && (
          <Alert variant="danger" className="mb-6">
            {error.detail || error}
          </Alert>
        )}

        {loading && Object.keys(news).length === 0 ? (
          <div className="flex justify-center items-center py-12">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div
            ref={printRef}
            className="newspaper-content bg-white p-8 shadow-sm"
          >
            {renderNews()}
          </div>
        )}

        <footer className="mt-12 pt-6 border-t border-gray-300 text-center text-gray-600">
          <p className="mt-5">© {new Date().getFullYear()} AI-Flu Monitor</p>
        </footer>
      </Container>
    </div>
  );
};

export default GlobalNews;
