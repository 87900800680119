export const russiaTopology = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "5491",
      properties: { name: "Адыгея", density: 0, path: "/world/Russia/Адыгея" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.684018, 44.951415],
            [38.685907, 45.039156],
            [38.689718, 45.059975],
            [38.705554, 45.065505],
            [38.71556, 45.059579],
            [38.719475, 45.042958],
            [38.735531, 45.045282],
            [38.748013, 45.059552],
            [38.76967, 45.055299],
            [38.756468, 45.033123],
            [38.76862, 45.028578],
            [38.785869, 45.004562],
            [38.809261, 44.995944],
            [38.813559, 45.009571],
            [38.844391, 45.008189],
            [38.860385, 45.001621],
            [38.866076, 45.011896],
            [38.853548, 45.024905],
            [38.897701, 45.053791],
            [38.909283, 45.044105],
            [38.899536, 45.029409],
            [38.907159, 45.022266],
            [38.940675, 45.036152],
            [38.956397, 45.033566],
            [38.947138, 45.016549],
            [38.953488, 44.998205],
            [38.971504, 44.994103],
            [38.973448, 45.007383],
            [39.014225, 45.003979],
            [39.020384, 44.969078],
            [39.041477, 44.984871],
            [39.056397, 44.975775],
            [39.075968, 44.978716],
            [39.077697, 44.988021],
            [39.09886, 44.98808],
            [39.118864, 44.998363],
            [39.182284, 44.99069],
            [39.200952, 45.000575],
            [39.22314, 44.990298],
            [39.22267, 45.005813],
            [39.232709, 45.010014],
            [39.255826, 45.001998],
            [39.264582, 45.019998],
            [39.289095, 45.01783],
            [39.30187, 45.030151],
            [39.327, 45.033851],
            [39.32607, 45.044812],
            [39.344829, 45.042471],
            [39.376052, 45.056554],
            [39.381427, 45.075331],
            [39.399087, 45.076703],
            [39.407627, 45.094022],
            [39.423992, 45.089677],
            [39.441277, 45.110832],
            [39.46026, 45.102214],
            [39.467576, 45.107913],
            [39.455043, 45.118575],
            [39.467706, 45.129933],
            [39.498148, 45.140322],
            [39.513307, 45.161176],
            [39.537054, 45.160553],
            [39.544279, 45.187122],
            [39.595049, 45.210617],
            [39.628562, 45.216601],
            [39.670456, 45.203953],
            [39.68812, 45.192771],
            [39.680763, 45.187849],
            [39.687916, 45.154833],
            [39.729454, 45.150733],
            [39.740598, 45.136095],
            [39.780904, 45.133245],
            [39.779327, 45.124284],
            [39.800659, 45.130631],
            [39.815132, 45.114732],
            [39.851806, 45.118028],
            [39.874448, 45.10507],
            [39.900147, 45.096677],
            [39.944209, 45.094825],
            [39.948805, 45.086183],
            [39.969446, 45.080813],
            [39.976494, 45.091372],
            [39.994495, 45.095307],
            [40.024601, 45.092963],
            [40.043405, 45.103154],
            [40.060558, 45.092623],
            [40.078832, 45.109703],
            [40.134662, 45.119682],
            [40.164303, 45.120849],
            [40.203311, 45.11266],
            [40.227033, 45.117757],
            [40.25041, 45.097954],
            [40.280774, 45.103904],
            [40.294664, 45.086084],
            [40.285322, 45.077567],
            [40.289352, 45.065285],
            [40.331991, 45.05674],
            [40.337629, 45.046973],
            [40.359502, 45.052339],
            [40.388946, 45.033997],
            [40.450416, 44.980051],
            [40.515648, 44.943356],
            [40.527814, 44.933474],
            [40.539629, 44.906381],
            [40.532771, 44.901293],
            [40.571916, 44.866573],
            [40.588461, 44.862637],
            [40.57948, 44.855306],
            [40.6005, 44.808169],
            [40.619746, 44.798124],
            [40.633412, 44.775185],
            [40.626408, 44.761383],
            [40.639156, 44.746731],
            [40.653018, 44.693586],
            [40.670093, 44.698465],
            [40.696373, 44.648391],
            [40.709595, 44.63205],
            [40.725288, 44.626536],
            [40.738487, 44.59697],
            [40.72795, 44.581633],
            [40.724394, 44.557565],
            [40.735945, 44.529605],
            [40.758547, 44.491544],
            [40.775638, 44.484713],
            [40.775739, 44.465024],
            [40.619524, 44.465084],
            [40.619703, 44.4832],
            [40.66125, 44.482709],
            [40.657901, 44.529674],
            [40.6681, 44.538816],
            [40.66681, 44.552516],
            [40.643364, 44.584312],
            [40.644146, 44.636225],
            [40.620283, 44.636503],
            [40.618122, 44.653855],
            [40.567395, 44.654544],
            [40.567472, 44.738403],
            [40.565331, 44.742213],
            [40.521998, 44.741267],
            [40.527497, 44.762615],
            [40.429997, 44.762156],
            [40.429997, 44.735435],
            [40.449403, 44.735062],
            [40.449068, 44.713357],
            [40.40477, 44.717321],
            [40.366447, 44.71686],
            [40.373192, 44.703962],
            [40.366165, 44.689047],
            [40.404299, 44.673173],
            [40.398895, 44.664045],
            [40.375225, 44.667216],
            [40.342875, 44.645264],
            [40.34029, 44.627029],
            [40.354494, 44.597283],
            [40.342871, 44.580927],
            [40.371823, 44.57608],
            [40.396912, 44.532541],
            [40.415038, 44.534995],
            [40.418013, 44.514518],
            [40.397827, 44.487675],
            [40.383468, 44.479314],
            [40.409343, 44.446875],
            [40.414686, 44.422446],
            [40.434918, 44.421516],
            [40.462765, 44.348229],
            [40.482902, 44.315372],
            [40.454634, 44.305363],
            [40.442341, 44.285424],
            [40.42431, 44.28116],
            [40.359829, 44.246745],
            [40.396194, 44.191404],
            [40.405666, 44.163961],
            [40.405546, 44.132848],
            [40.429459, 44.114159],
            [40.42054, 44.07535],
            [40.413294, 44.063314],
            [40.429388, 44.052424],
            [40.438979, 44.029864],
            [40.469243, 43.982712],
            [40.47699, 43.958813],
            [40.452506, 43.956359],
            [40.450345, 43.94835],
            [40.412941, 43.9419],
            [40.408318, 43.934826],
            [40.402758, 43.894816],
            [40.420424, 43.882593],
            [40.403054, 43.86134],
            [40.393716, 43.827757],
            [40.380233, 43.820099],
            [40.382041, 43.801973],
            [40.365667, 43.773269],
            [40.348936, 43.772217],
            [40.304056, 43.781756],
            [40.276256, 43.760788],
            [40.249407, 43.762116],
            [40.233102, 43.775383],
            [40.216865, 43.779116],
            [40.205936, 43.800014],
            [40.208118, 43.818782],
            [40.189373, 43.844313],
            [40.196892, 43.859228],
            [40.183671, 43.87378],
            [40.150901, 43.857695],
            [40.104578, 43.866871],
            [40.093347, 43.845227],
            [40.069913, 43.865782],
            [40.042425, 43.880997],
            [40.013335, 43.884374],
            [39.992789, 43.905503],
            [39.953051, 43.933772],
            [39.940146, 43.958062],
            [39.922985, 43.97254],
            [39.90892, 43.973883],
            [39.902747, 43.953737],
            [39.912676, 43.939498],
            [39.851457, 43.92423],
            [39.811369, 43.932682],
            [39.777775, 43.961767],
            [39.816449, 43.981552],
            [39.797638, 43.996435],
            [39.796783, 44.017459],
            [39.81428, 44.050867],
            [39.828494, 44.065343],
            [39.829143, 44.084684],
            [39.794054, 44.127228],
            [39.778432, 44.128841],
            [39.761805, 44.148818],
            [39.764601, 44.163456],
            [39.776328, 44.16977],
            [39.768596, 44.180348],
            [39.822049, 44.18042],
            [39.865027, 44.184307],
            [39.876101, 44.170364],
            [39.878037, 44.148147],
            [39.89664, 44.123925],
            [39.920879, 44.117122],
            [39.950265, 44.09946],
            [39.963151, 44.102303],
            [39.965197, 44.091156],
            [39.993302, 44.076403],
            [40.017852, 44.087841],
            [40.016127, 44.106438],
            [40.056938, 44.137556],
            [40.074463, 44.122049],
            [40.092788, 44.128114],
            [40.087987, 44.138516],
            [40.069046, 44.145227],
            [40.07646, 44.154208],
            [40.072489, 44.169729],
            [40.087558, 44.178697],
            [40.079227, 44.197433],
            [40.077379, 44.221195],
            [40.067827, 44.224876],
            [40.089498, 44.250888],
            [40.062091, 44.268424],
            [40.007011, 44.266541],
            [39.989763, 44.26131],
            [39.973115, 44.279173],
            [39.966303, 44.306452],
            [39.975012, 44.339454],
            [39.957679, 44.348085],
            [39.922257, 44.337314],
            [39.907281, 44.351023],
            [39.878466, 44.346915],
            [39.869771, 44.338186],
            [39.845903, 44.353173],
            [39.879092, 44.396542],
            [39.863059, 44.407026],
            [39.870626, 44.431669],
            [39.879406, 44.432766],
            [39.884823, 44.555065],
            [39.872395, 44.555554],
            [39.874164, 44.591394],
            [39.929128, 44.590294],
            [39.932877, 44.602892],
            [39.914878, 44.608468],
            [39.920384, 44.626108],
            [39.904418, 44.641771],
            [39.915962, 44.651074],
            [39.896248, 44.661805],
            [39.927501, 44.689666],
            [39.908078, 44.700518],
            [39.910078, 44.713004],
            [39.942818, 44.736303],
            [39.960817, 44.723619],
            [39.969721, 44.730053],
            [39.987544, 44.717298],
            [40.029016, 44.746998],
            [40.008347, 44.759888],
            [39.981416, 44.800436],
            [39.957911, 44.793757],
            [39.939072, 44.82703],
            [39.955455, 44.822145],
            [39.98539, 44.830456],
            [39.975785, 44.847369],
            [39.957836, 44.842396],
            [39.933944, 44.883853],
            [39.963479, 44.892425],
            [39.942136, 44.929611],
            [39.924237, 44.927186],
            [39.903734, 45.001955],
            [39.890982, 45.000365],
            [39.884915, 45.023241],
            [39.84916, 45.014785],
            [39.7641, 44.990373],
            [39.773753, 44.97371],
            [39.692425, 44.951403],
            [39.681712, 44.963087],
            [39.696232, 44.968418],
            [39.673288, 44.978613],
            [39.678693, 44.992584],
            [39.64455, 44.983232],
            [39.651649, 44.975647],
            [39.620013, 44.960311],
            [39.598588, 44.982751],
            [39.644205, 45.004864],
            [39.652424, 45.033683],
            [39.622129, 45.049037],
            [39.607981, 45.042397],
            [39.566093, 45.047076],
            [39.571574, 45.035861],
            [39.545366, 45.034607],
            [39.551892, 45.047334],
            [39.529773, 45.043242],
            [39.515807, 45.050434],
            [39.509062, 45.036597],
            [39.490775, 45.044625],
            [39.487434, 45.032259],
            [39.471249, 45.035691],
            [39.475028, 44.987667],
            [39.484008, 44.989912],
            [39.49451, 44.975211],
            [39.51295, 44.988166],
            [39.52224, 44.981395],
            [39.505031, 44.969518],
            [39.536988, 44.955771],
            [39.570017, 44.933944],
            [39.572991, 44.925901],
            [39.64016, 44.895798],
            [39.641949, 44.865606],
            [39.621835, 44.861275],
            [39.607594, 44.846157],
            [39.557787, 44.836637],
            [39.5689, 44.827708],
            [39.556268, 44.806862],
            [39.48299, 44.820153],
            [39.48181, 44.804534],
            [39.456121, 44.817524],
            [39.392659, 44.813253],
            [39.353677, 44.816],
            [39.304931, 44.825465],
            [39.23807, 44.822892],
            [39.152772, 44.835918],
            [39.140975, 44.835262],
            [39.090759, 44.817163],
            [39.027097, 44.842041],
            [38.989736, 44.844265],
            [38.989715, 44.856941],
            [38.961229, 44.862491],
            [38.932581, 44.876001],
            [38.854172, 44.894369],
            [38.860054, 44.908136],
            [38.844455, 44.925521],
            [38.81593, 44.944065],
            [38.789187, 44.942949],
            [38.781602, 44.967617],
            [38.754197, 44.949442],
            [38.684018, 44.951415],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5523",
      properties: { name: "Алтай", density: 0, path: "/world/Russia/Алтай" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.1327, 52.617593],
            [87.134162, 52.606606],
            [87.11888, 52.598563],
            [87.174017, 52.588911],
            [87.17257, 52.579661],
            [87.199877, 52.572985],
            [87.218457, 52.58875],
            [87.237922, 52.591405],
            [87.273836, 52.587785],
            [87.297967, 52.592209],
            [87.313732, 52.58111],
            [87.349606, 52.570251],
            [87.374339, 52.572503],
            [87.366296, 52.549017],
            [87.378281, 52.540893],
            [87.371324, 52.499792],
            [87.428271, 52.49947],
            [87.449506, 52.484107],
            [87.492699, 52.474858],
            [87.542327, 52.482901],
            [87.552582, 52.472204],
            [87.58733, 52.462391],
            [87.611621, 52.47148],
            [87.623351, 52.464791],
            [87.610656, 52.456439],
            [87.617976, 52.438341],
            [87.653327, 52.422416],
            [87.668851, 52.426437],
            [87.673154, 52.442042],
            [87.704925, 52.465367],
            [87.732916, 52.462713],
            [87.736938, 52.475502],
            [87.75407, 52.484511],
            [87.801124, 52.479685],
            [87.814155, 52.497943],
            [87.84665, 52.492152],
            [87.864908, 52.518856],
            [87.892618, 52.525854],
            [87.891813, 52.538161],
            [87.943332, 52.533656],
            [87.9708, 52.518696],
            [88.000199, 52.519339],
            [88.047615, 52.498668],
            [88.057106, 52.488936],
            [88.123465, 52.462634],
            [88.127929, 52.446869],
            [88.143614, 52.428852],
            [88.158897, 52.430381],
            [88.186767, 52.410272],
            [88.20945, 52.409468],
            [88.228794, 52.400701],
            [88.25208, 52.399897],
            [88.267362, 52.414938],
            [88.281921, 52.41542],
            [88.28184, 52.427164],
            [88.305247, 52.432875],
            [88.321213, 52.464002],
            [88.369192, 52.441723],
            [88.410897, 52.445342],
            [88.448339, 52.425797],
            [88.442629, 52.40601],
            [88.407721, 52.377537],
            [88.42799, 52.369654],
            [88.422521, 52.358715],
            [88.463944, 52.341503],
            [88.441906, 52.335631],
            [88.428875, 52.316488],
            [88.403297, 52.311662],
            [88.391474, 52.293725],
            [88.402493, 52.280695],
            [88.380696, 52.273858],
            [88.396381, 52.238387],
            [88.382747, 52.222863],
            [88.384195, 52.200905],
            [88.375832, 52.177457],
            [88.363573, 52.169065],
            [88.374518, 52.154623],
            [88.360185, 52.146284],
            [88.361228, 52.132515],
            [88.378253, 52.12461],
            [88.366874, 52.115663],
            [88.348632, 52.118443],
            [88.306414, 52.109908],
            [88.286261, 52.111732],
            [88.289041, 52.101547],
            [88.252557, 52.08198],
            [88.25247, 52.0458],
            [88.195224, 52.058331],
            [88.151791, 52.095988],
            [88.126426, 52.068234],
            [88.084642, 52.05225],
            [88.066661, 52.049688],
            [88.057279, 52.03631],
            [88.057627, 52.016808],
            [88.068398, 52.017243],
            [88.087596, 52.000021],
            [88.034433, 51.975481],
            [88.028613, 51.964275],
            [88.000034, 51.968097],
            [87.993432, 51.956262],
            [87.97684, 51.952266],
            [87.989262, 51.940148],
            [88.001771, 51.941712],
            [88.003769, 51.92095],
            [87.989523, 51.902078],
            [87.969804, 51.890264],
            [87.945307, 51.885704],
            [87.934536, 51.848568],
            [87.89145, 51.812562],
            [87.884761, 51.780985],
            [87.932712, 51.784547],
            [87.948782, 51.768346],
            [87.963289, 51.767304],
            [87.988046, 51.75182],
            [88.03617, 51.751733],
            [88.084121, 51.758335],
            [88.110094, 51.740266],
            [88.10219, 51.722589],
            [88.106794, 51.70856],
            [88.097933, 51.696399],
            [88.077867, 51.691165],
            [88.076042, 51.674834],
            [88.054326, 51.666929],
            [88.028874, 51.667971],
            [88.004116, 51.641954],
            [88.000642, 51.58864],
            [87.959032, 51.576348],
            [87.946871, 51.550679],
            [87.900397, 51.551547],
            [87.934449, 51.526312],
            [87.951736, 51.485355],
            [87.941398, 51.478926],
            [87.983963, 51.466309],
            [88.022706, 51.467004],
            [88.066053, 51.462878],
            [88.097325, 51.447676],
            [88.119042, 51.430628],
            [88.141106, 51.428543],
            [88.125209, 51.3951],
            [88.143278, 51.386152],
            [88.133288, 51.374165],
            [88.147274, 51.371211],
            [88.156395, 51.348495],
            [88.167166, 51.345802],
            [88.187319, 51.359006],
            [88.258637, 51.353447],
            [88.302419, 51.340417],
            [88.315101, 51.344413],
            [88.320139, 51.328342],
            [88.348545, 51.322348],
            [88.373389, 51.310773],
            [88.401447, 51.308059],
            [88.424641, 51.286255],
            [88.445489, 51.285126],
            [88.469377, 51.306495],
            [88.510355, 51.300079],
            [88.554768, 51.325345],
            [88.572836, 51.319959],
            [88.59325, 51.325171],
            [88.595487, 51.336055],
            [88.621656, 51.353403],
            [88.619744, 51.37145],
            [88.636336, 51.386999],
            [88.65397, 51.382069],
            [88.674644, 51.391885],
            [88.687762, 51.385631],
            [88.702876, 51.393362],
            [88.692365, 51.422245],
            [88.693842, 51.440444],
            [88.680552, 51.44444],
            [88.694016, 51.468502],
            [88.685329, 51.492282],
            [88.672125, 51.499579],
            [88.658053, 51.52805],
            [88.67864, 51.537432],
            [88.705309, 51.534739],
            [88.732151, 51.545532],
            [88.750393, 51.545358],
            [88.763075, 51.561429],
            [88.772891, 51.55031],
            [88.791531, 51.553761],
            [88.793794, 51.539712],
            [88.824541, 51.516782],
            [88.846101, 51.516319],
            [88.863067, 51.485813],
            [88.863883, 51.467884],
            [88.891295, 51.472329],
            [88.927007, 51.458437],
            [88.932045, 51.447158],
            [88.970868, 51.429117],
            [89.008876, 51.394259],
            [89.003097, 51.368402],
            [88.986501, 51.364623],
            [88.987538, 51.345064],
            [88.99754, 51.335377],
            [88.991243, 51.30839],
            [88.981463, 51.303982],
            [88.977981, 51.28157],
            [88.967016, 51.265048],
            [88.974277, 51.259751],
            [88.960349, 51.243526],
            [88.9765, 51.239932],
            [88.960719, 51.2283],
            [88.967313, 51.202925],
            [88.988207, 51.19861],
            [89.01562, 51.183977],
            [89.033771, 51.185015],
            [89.049849, 51.159806],
            [89.038365, 51.15036],
            [89.037699, 51.133986],
            [89.048664, 51.119576],
            [89.028882, 51.112445],
            [89.033402, 51.099072],
            [89.054147, 51.082309],
            [89.055555, 51.046395],
            [89.11653, 51.02704],
            [89.123199, 51.007425],
            [89.16906, 50.996071],
            [89.193732, 50.981809],
            [89.209587, 50.952655],
            [89.238778, 50.933429],
            [89.250188, 50.915315],
            [89.268043, 50.915982],
            [89.267747, 50.880456],
            [89.282417, 50.876381],
            [89.297605, 50.885198],
            [89.321907, 50.879642],
            [89.346949, 50.881253],
            [89.348579, 50.859675],
            [89.379178, 50.860694],
            [89.398663, 50.850525],
            [89.375918, 50.826409],
            [89.392218, 50.808221],
            [89.414148, 50.801479],
            [89.430892, 50.808887],
            [89.466529, 50.780475],
            [89.471419, 50.755451],
            [89.485793, 50.737652],
            [89.525727, 50.725798],
            [89.54825, 50.687642],
            [89.570625, 50.674825],
            [89.566921, 50.661896],
            [89.578923, 50.648542],
            [89.633897, 50.646597],
            [89.646048, 50.634447],
            [89.631971, 50.617147],
            [89.648271, 50.599774],
            [89.644048, 50.581307],
            [89.664497, 50.57012],
            [89.695762, 50.575602],
            [89.700208, 50.569675],
            [89.735548, 50.566416],
            [89.748588, 50.552061],
            [89.773334, 50.546134],
            [89.786818, 50.550431],
            [89.797857, 50.537762],
            [89.848757, 50.526519],
            [89.842163, 50.499884],
            [89.861649, 50.482529],
            [89.864909, 50.469675],
            [89.847942, 50.441558],
            [89.829272, 50.435168],
            [89.8189, 50.411201],
            [89.786671, 50.419795],
            [89.723103, 50.403106],
            [89.707322, 50.384121],
            [89.664721, 50.38501],
            [89.655163, 50.369729],
            [89.62901, 50.378045],
            [89.613229, 50.374267],
            [89.58989, 50.428129],
            [89.569294, 50.431555],
            [89.545363, 50.445354],
            [89.50291, 50.459801],
            [89.509059, 50.448392],
            [89.490463, 50.442131],
            [89.48135, 50.399919],
            [89.501577, 50.385732],
            [89.496835, 50.363912],
            [89.409559, 50.336444],
            [89.381479, 50.338889],
            [89.379479, 50.314921],
            [89.406595, 50.314032],
            [89.393333, 50.304771],
            [89.390296, 50.28636],
            [89.343768, 50.264244],
            [89.331247, 50.240869],
            [89.333026, 50.21668],
            [89.308428, 50.207937],
            [89.351252, 50.193231],
            [89.367255, 50.181951],
            [89.393409, 50.185489],
            [89.403485, 50.177932],
            [89.416969, 50.192212],
            [89.440603, 50.203696],
            [89.496244, 50.199066],
            [89.525583, 50.183729],
            [89.532474, 50.147871],
            [89.510025, 50.128571],
            [89.511507, 50.119309],
            [89.58241, 50.106974],
            [89.585893, 50.082043],
            [89.532919, 50.064373],
            [89.536031, 50.053908],
            [89.524695, 50.03935],
            [89.543959, 50.01503],
            [89.562777, 50.019401],
            [89.590116, 50.01716],
            [89.605823, 49.978005],
            [89.62479, 49.970855],
            [89.610565, 49.965447],
            [89.606268, 49.94422],
            [89.614714, 49.939182],
            [89.5915, 49.9111],
            [89.6314, 49.8986],
            [89.6366, 49.8922],
            [89.6146, 49.8712],
            [89.6245, 49.8476],
            [89.6422, 49.8427],
            [89.6469, 49.8255],
            [89.6325, 49.8158],
            [89.6303, 49.8024],
            [89.6642, 49.7871],
            [89.6862, 49.7842],
            [89.706, 49.7908],
            [89.7162, 49.7742],
            [89.7143, 49.7412],
            [89.7192, 49.7283],
            [89.6608, 49.6999],
            [89.5808, 49.6803],
            [89.5517, 49.6828],
            [89.5182, 49.6747],
            [89.5107, 49.6666],
            [89.4854, 49.66],
            [89.4578, 49.6605],
            [89.4312, 49.6261],
            [89.4017, 49.6177],
            [89.3846, 49.6062],
            [89.391, 49.5953],
            [89.3431, 49.5828],
            [89.345, 49.5918],
            [89.2989, 49.6114],
            [89.2709, 49.6329],
            [89.241, 49.6452],
            [89.1976, 49.6334],
            [89.1873, 49.6202],
            [89.1992, 49.6074],
            [89.1955, 49.5875],
            [89.2327, 49.5537],
            [89.2009, 49.5228],
            [89.1621, 49.5174],
            [89.1476, 49.5021],
            [89.1224, 49.4991],
            [89.0749, 49.4845],
            [89.035, 49.4861],
            [89.0136, 49.4696],
            [88.9708, 49.4657],
            [88.96, 49.4776],
            [88.9595, 49.5],
            [88.9326, 49.5168],
            [88.9295, 49.5429],
            [88.8982, 49.556],
            [88.8789, 49.5554],
            [88.873, 49.5447],
            [88.8813, 49.5103],
            [88.8587, 49.4963],
            [88.8801, 49.4781],
            [88.8429, 49.4416],
            [88.8143, 49.4584],
            [88.7496, 49.4603],
            [88.7328, 49.4515],
            [88.674, 49.4659],
            [88.6323, 49.4954],
            [88.5909, 49.5012],
            [88.5449, 49.4856],
            [88.5173, 49.4841],
            [88.4949, 49.4719],
            [88.445, 49.4726],
            [88.4112, 49.4936],
            [88.3668, 49.4852],
            [88.3312, 49.4935],
            [88.3045, 49.4724],
            [88.2743, 49.48],
            [88.2217, 49.4769],
            [88.1756, 49.4541],
            [88.1713, 49.4469],
            [88.1871, 49.417],
            [88.1275, 49.398],
            [88.1216, 49.3819],
            [88.1611, 49.3625],
            [88.1677, 49.3507],
            [88.1591, 49.3355],
            [88.1691, 49.2968],
            [88.1606, 49.2834],
            [88.1302, 49.2755],
            [88.123, 49.2622],
            [88.0995, 49.2515],
            [88.0722, 49.2581],
            [88.0556, 49.2529],
            [88.0511, 49.2349],
            [88.0179, 49.2209],
            [87.9953, 49.1887],
            [87.9854, 49.1816],
            [87.8824, 49.1699],
            [87.8549, 49.1746],
            [87.8082, 49.1668],
            [87.7754, 49.1742],
            [87.7547, 49.1644],
            [87.7206, 49.1756],
            [87.6987, 49.1749],
            [87.671, 49.1489],
            [87.5995, 49.1455],
            [87.5638, 49.1331],
            [87.5392, 49.1398],
            [87.4938, 49.1372],
            [87.5072, 49.11],
            [87.4976, 49.084],
            [87.4757, 49.0859],
            [87.4343, 49.0708],
            [87.4193, 49.07],
            [87.3839, 49.0898],
            [87.3512, 49.0991],
            [87.3074, 49.1035],
            [87.2826, 49.1117],
            [87.2992, 49.135],
            [87.2941, 49.1558],
            [87.3049, 49.1766],
            [87.2913, 49.1953],
            [87.3176, 49.2301],
            [87.3096, 49.235],
            [87.2682, 49.234],
            [87.208, 49.2496],
            [87.1517, 49.2468],
            [87.1227, 49.2521],
            [87.0637, 49.2532],
            [87.0404, 49.2478],
            [87.0212, 49.2686],
            [87.0102, 49.2939],
            [86.9646, 49.3162],
            [86.9522, 49.3377],
            [86.9275, 49.353],
            [86.9192, 49.3674],
            [86.9439, 49.3853],
            [86.937, 49.4233],
            [86.915, 49.4214],
            [86.9026, 49.4313],
            [86.8668, 49.442],
            [86.8392, 49.4563],
            [86.8294, 49.4851],
            [86.8501, 49.5004],
            [86.85, 49.5095],
            [86.8155, 49.5346],
            [86.8223, 49.5454],
            [86.7405, 49.5739],
            [86.6922, 49.5731],
            [86.6644, 49.563],
            [86.6188, 49.5745],
            [86.6005, 49.614],
            [86.6169, 49.6321],
            [86.65, 49.643],
            [86.6584, 49.6571],
            [86.7094, 49.6881],
            [86.7275, 49.6808],
            [86.7676, 49.6936],
            [86.7772, 49.7008],
            [86.7632, 49.7302],
            [86.7604, 49.7501],
            [86.781, 49.7881],
            [86.7657, 49.7943],
            [86.7255, 49.7932],
            [86.699, 49.8094],
            [86.6557, 49.8136],
            [86.5961, 49.8066],
            [86.6117, 49.7705],
            [86.6021, 49.7479],
            [86.5787, 49.7326],
            [86.5788, 49.7254],
            [86.5319, 49.7054],
            [86.5113, 49.6911],
            [86.5004, 49.6721],
            [86.4633, 49.6486],
            [86.4259, 49.6313],
            [86.3972, 49.6057],
            [86.3517, 49.5949],
            [86.3171, 49.6008],
            [86.2951, 49.5963],
            [86.2716, 49.5827],
            [86.2665, 49.5522],
            [86.2393, 49.5231],
            [86.226, 49.4925],
            [86.1988, 49.4689],
            [86.1807, 49.4805],
            [86.1582, 49.51],
            [86.1192, 49.527],
            [86.0541, 49.5291],
            [86.0322, 49.5206],
            [86.0228, 49.5071],
            [85.9901, 49.4915],
            [85.9736, 49.4887],
            [85.9511, 49.5057],
            [85.931, 49.5343],
            [85.9501, 49.5569],
            [85.9458, 49.5631],
            [85.8834, 49.5662],
            [85.8341, 49.5476],
            [85.8175, 49.5502],
            [85.8046, 49.5661],
            [85.7851, 49.575],
            [85.7602, 49.5784],
            [85.6898, 49.5661],
            [85.6777, 49.5559],
            [85.6553, 49.5673],
            [85.6284, 49.5886],
            [85.6155, 49.608],
            [85.593, 49.6196],
            [85.5574, 49.6013],
            [85.4897, 49.6012],
            [85.4128, 49.6289],
            [85.3833, 49.6349],
            [85.3197, 49.5951],
            [85.2617, 49.5935],
            [85.2565, 49.6241],
            [85.2121, 49.6279],
            [85.2141, 49.6438],
            [85.2041, 49.6512],
            [85.2114, 49.6782],
            [85.2096, 49.7267],
            [85.1949, 49.7508],
            [85.1453, 49.7798],
            [85.1346, 49.8047],
            [85.1031, 49.8286],
            [85.1055, 49.8392],
            [85.0908, 49.8596],
            [85.0593, 49.8844],
            [85.0196, 49.8963],
            [84.9885, 49.9101],
            [84.9784, 49.9522],
            [84.9889, 49.9658],
            [84.9839, 49.9854],
            [85.0388, 50.0062],
            [85.0298, 50.0556],
            [84.9891, 50.063],
            [84.9663, 50.0725],
            [84.9458, 50.0965],
            [84.9194, 50.0933],
            [84.8868, 50.1044],
            [84.8564, 50.0922],
            [84.8145, 50.1219],
            [84.8204, 50.1463],
            [84.8008, 50.1477],
            [84.758, 50.137],
            [84.7492, 50.1404],
            [84.691, 50.1878],
            [84.6212, 50.2126],
            [84.5851, 50.2082],
            [84.5645, 50.1996],
            [84.5277, 50.2087],
            [84.5025, 50.2306],
            [84.481, 50.2402],
            [84.447, 50.2466],
            [84.4055, 50.2113],
            [84.3744, 50.2171],
            [84.3635, 50.2087],
            [84.3275, 50.226],
            [84.3196, 50.2418],
            [84.2763, 50.2652],
            [84.2509, 50.2924],
            [84.2651, 50.3143],
            [84.2668, 50.3576],
            [84.246, 50.3767],
            [84.2416, 50.4046],
            [84.2093, 50.4514],
            [84.2039, 50.4685],
            [84.2351, 50.4916],
            [84.2433, 50.5207],
            [84.2293, 50.5392],
            [84.1694, 50.5503],
            [84.167, 50.5675],
            [84.1553, 50.5745],
            [84.1365, 50.6053],
            [84.073109, 50.634915],
            [84.127971, 50.659636],
            [84.198804, 50.666634],
            [84.249037, 50.667052],
            [84.288892, 50.687469],
            [84.323826, 50.690759],
            [84.341136, 50.686111],
            [84.354764, 50.693788],
            [84.40424, 50.707338],
            [84.413339, 50.688514],
            [84.445793, 50.680212],
            [84.473729, 50.687078],
            [84.479838, 50.696895],
            [84.503101, 50.707234],
            [84.514354, 50.721437],
            [84.50391, 50.731097],
            [84.511298, 50.765456],
            [84.529888, 50.767023],
            [84.541845, 50.781174],
            [84.557563, 50.77992],
            [84.5708, 50.79428],
            [84.572262, 50.814645],
            [84.589258, 50.821747],
            [84.539808, 50.847646],
            [84.521689, 50.84326],
            [84.47075, 50.859656],
            [84.438297, 50.859238],
            [84.438271, 50.908374],
            [84.478948, 50.935527],
            [84.490696, 50.948059],
            [84.417122, 50.968946],
            [84.34091, 50.980694],
            [84.305924, 51.003878],
            [84.280599, 50.995158],
            [84.247963, 50.999074],
            [84.222168, 50.987377],
            [84.145852, 51.014269],
            [84.127497, 51.014216],
            [84.101388, 51.042257],
            [84.061625, 51.032231],
            [84.02079, 51.044188],
            [84.038283, 51.057608],
            [84.02079, 51.055937],
            [83.978526, 51.069498],
            [83.941707, 51.069879],
            [83.956379, 51.11019],
            [83.932803, 51.136925],
            [83.948102, 51.144497],
            [84.004053, 51.142356],
            [84.008805, 51.150815],
            [84.042041, 51.15374],
            [84.057211, 51.147683],
            [84.096635, 51.143349],
            [84.073607, 51.112854],
            [84.077367, 51.098651],
            [84.089168, 51.098285],
            [84.120656, 51.071237],
            [84.133971, 51.102933],
            [84.146973, 51.104134],
            [84.145511, 51.129929],
            [84.159557, 51.159484],
            [84.155849, 51.184966],
            [84.179634, 51.192381],
            [84.185508, 51.219325],
            [84.197805, 51.222876],
            [84.196186, 51.250238],
            [84.234305, 51.255877],
            [84.256471, 51.282613],
            [84.286914, 51.282665],
            [84.331794, 51.290341],
            [84.386597, 51.265799],
            [84.414168, 51.2717],
            [84.418867, 51.280055],
            [84.451242, 51.286164],
            [84.460145, 51.301046],
            [84.457012, 51.316085],
            [84.53257, 51.336084],
            [84.57719, 51.343604],
            [84.594526, 51.336189],
            [84.633715, 51.34705],
            [84.641286, 51.374725],
            [84.68306, 51.391487],
            [84.691153, 51.410547],
            [84.716156, 51.426799],
            [84.768905, 51.417387],
            [84.788095, 51.42616],
            [84.813812, 51.427831],
            [84.851356, 51.442713],
            [84.871669, 51.431069],
            [84.897987, 51.437753],
            [84.923129, 51.465532],
            [84.92057, 51.482868],
            [84.968218, 51.506001],
            [84.995946, 51.514356],
            [85.061087, 51.512058],
            [85.112573, 51.528716],
            [85.141032, 51.502137],
            [85.166854, 51.486525],
            [85.193015, 51.485428],
            [85.215259, 51.495506],
            [85.244031, 51.499162],
            [85.260818, 51.528716],
            [85.29912, 51.530649],
            [85.295438, 51.546366],
            [85.325933, 51.5613],
            [85.444258, 51.563389],
            [85.454049, 51.572266],
            [85.446007, 51.601769],
            [85.478173, 51.622395],
            [85.473159, 51.663385],
            [85.490182, 51.674508],
            [85.474099, 51.696491],
            [85.502949, 51.701243],
            [85.556263, 51.69174],
            [85.582319, 51.697222],
            [85.606209, 51.731999],
            [85.626286, 51.747246],
            [85.66853, 51.728449],
            [85.676337, 51.717379],
            [85.702837, 51.732469],
            [85.744504, 51.785992],
            [85.743749, 51.806775],
            [85.776959, 51.819516],
            [85.771946, 51.827244],
            [85.735185, 51.830899],
            [85.721399, 51.841342],
            [85.749909, 51.867242],
            [85.853561, 51.891053],
            [85.865884, 51.906092],
            [85.836955, 51.921652],
            [85.819514, 51.94609],
            [85.81753, 51.971154],
            [85.860243, 51.996636],
            [85.889903, 52.026922],
            [85.862436, 52.023998],
            [85.830375, 52.027444],
            [85.800245, 52.053135],
            [85.828547, 52.051046],
            [85.853611, 52.06337],
            [85.874394, 52.058044],
            [85.91277, 52.059819],
            [85.918843, 52.105662],
            [85.945461, 52.100549],
            [85.980499, 52.100549],
            [86.012143, 52.084362],
            [86.027599, 52.08739],
            [86.084934, 52.077365],
            [86.104516, 52.086451],
            [86.143888, 52.084258],
            [86.130729, 52.068279],
            [86.154175, 52.051361],
            [86.156995, 52.032667],
            [86.177726, 52.018256],
            [86.227959, 52.011468],
            [86.232449, 52.040291],
            [86.267853, 52.035696],
            [86.293857, 52.038203],
            [86.347589, 52.035175],
            [86.376726, 52.039457],
            [86.395733, 52.033191],
            [86.416986, 52.065356],
            [86.452556, 52.074227],
            [86.442259, 52.094494],
            [86.454269, 52.1017],
            [86.441736, 52.11183],
            [86.464294, 52.125406],
            [86.491708, 52.128748],
            [86.523039, 52.115694],
            [86.530245, 52.118723],
            [86.566902, 52.10828],
            [86.648831, 52.105669],
            [86.656141, 52.118619],
            [86.684339, 52.126034],
            [86.685905, 52.137939],
            [86.726358, 52.138991],
            [86.741621, 52.156216],
            [86.740159, 52.171358],
            [86.801044, 52.158722],
            [86.805639, 52.187755],
            [86.72418, 52.199242],
            [86.730028, 52.213132],
            [86.757494, 52.229842],
            [86.765849, 52.241643],
            [86.771801, 52.279448],
            [86.781098, 52.294582],
            [86.693579, 52.294799],
            [86.683762, 52.348061],
            [86.667052, 52.342734],
            [86.642823, 52.377093],
            [86.642405, 52.402575],
            [86.62298, 52.406021],
            [86.622979, 52.423775],
            [86.657025, 52.425446],
            [86.679427, 52.43683],
            [86.677263, 52.464196],
            [86.646113, 52.49556],
            [86.662873, 52.504294],
            [86.627469, 52.527269],
            [86.633422, 52.529149],
            [86.672489, 52.512711],
            [86.708615, 52.518602],
            [86.733158, 52.510874],
            [86.753313, 52.524032],
            [86.763914, 52.515364],
            [86.796288, 52.515051],
            [86.788456, 52.52445],
            [86.783756, 52.554423],
            [86.793364, 52.560793],
            [86.780831, 52.57061],
            [86.77279, 52.596092],
            [86.781353, 52.604238],
            [86.821952, 52.609199],
            [86.809315, 52.648361],
            [86.842735, 52.626744],
            [86.879835, 52.638016],
            [86.904965, 52.629951],
            [86.935055, 52.637605],
            [86.937875, 52.655672],
            [86.979753, 52.652853],
            [86.989466, 52.658492],
            [87.012755, 52.642514],
            [87.052753, 52.660268],
            [87.068836, 52.657448],
            [87.073797, 52.63677],
            [87.099071, 52.619226],
            [87.1327, 52.617593],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5477",
      properties: {
        name: "Алтайский край",
        density: 0,
        path: "/world/Russia/Алтайский край",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.116875, 54.437789],
            [85.159972, 54.452634],
            [85.425309, 54.187304],
            [85.53601, 54.226006],
            [85.563206, 54.198811],
            [85.624222, 54.214501],
            [85.690469, 54.144769],
            [85.749045, 54.164294],
            [85.871253, 54.037556],
            [85.910827, 54.042612],
            [85.915185, 54.026574],
            [85.999257, 54.014197],
            [86.048114, 54.010187],
            [86.064371, 53.993016],
            [86.124254, 53.982905],
            [86.177819, 53.833068],
            [86.184139, 53.811626],
            [86.295755, 53.829757],
            [86.350672, 53.683668],
            [86.352851, 53.668937],
            [86.393733, 53.597287],
            [86.426551, 53.608009],
            [86.471747, 53.575932],
            [86.497461, 53.571923],
            [86.538865, 53.527643],
            [86.594608, 53.509339],
            [86.645514, 53.49792],
            [86.700254, 53.49487],
            [86.719736, 53.481098],
            [86.755256, 53.477001],
            [86.787289, 53.467849],
            [86.785982, 53.461224],
            [86.812873, 53.451549],
            [86.813396, 53.461834],
            [86.833749, 53.477611],
            [86.930329, 53.503674],
            [86.929022, 53.473515],
            [86.914334, 53.464973],
            [86.889492, 53.464711],
            [86.86818, 53.451288],
            [86.863386, 53.439172],
            [86.873192, 53.421739],
            [86.904921, 53.412325],
            [86.858418, 53.377633],
            [86.884917, 53.365604],
            [86.843818, 53.346689],
            [86.8487, 53.319581],
            [86.822288, 53.322806],
            [86.809388, 53.295087],
            [86.771864, 53.278613],
            [86.75321, 53.263707],
            [86.767418, 53.258042],
            [86.753123, 53.247408],
            [86.741094, 53.253073],
            [86.691453, 53.237471],
            [86.676722, 53.254729],
            [86.655758, 53.260656],
            [86.630132, 53.248627],
            [86.637367, 53.228318],
            [86.64421, 53.180464],
            [86.712461, 53.193016],
            [86.711502, 53.158325],
            [86.781846, 53.141415],
            [86.786901, 53.137057],
            [86.839288, 53.150655],
            [86.840509, 53.116922],
            [86.857245, 53.093823],
            [86.874548, 53.083015],
            [86.899216, 53.084584],
            [86.943671, 53.062793],
            [87.001462, 53.062706],
            [87.054982, 53.041002],
            [87.063437, 53.025748],
            [87.031927, 53.000644],
            [87.03184, 52.986523],
            [87.019462, 52.97432],
            [87.017415, 52.925943],
            [87.007914, 52.899184],
            [86.956878, 52.873034],
            [86.936917, 52.878089],
            [86.912424, 52.846971],
            [86.920182, 52.829015],
            [86.948337, 52.830933],
            [86.945286, 52.816376],
            [86.95592, 52.80365],
            [86.946332, 52.789268],
            [86.971349, 52.774973],
            [86.974836, 52.756058],
            [87.008003, 52.753618],
            [87.022865, 52.729996],
            [87.069717, 52.723808],
            [87.053243, 52.704893],
            [87.066492, 52.695915],
            [87.094298, 52.690075],
            [87.103668, 52.712476],
            [87.114041, 52.717532],
            [87.163682, 52.71021],
            [87.16865, 52.696089],
            [87.143259, 52.65623],
            [87.12908, 52.621811],
            [87.1327, 52.617593],
            [87.099071, 52.619226],
            [87.073797, 52.63677],
            [87.068836, 52.657448],
            [87.052753, 52.660268],
            [87.012755, 52.642514],
            [86.989466, 52.658492],
            [86.979753, 52.652853],
            [86.937875, 52.655672],
            [86.935055, 52.637605],
            [86.904965, 52.629951],
            [86.879835, 52.638016],
            [86.842735, 52.626744],
            [86.809315, 52.648361],
            [86.821952, 52.609199],
            [86.781353, 52.604238],
            [86.77279, 52.596092],
            [86.780831, 52.57061],
            [86.793364, 52.560793],
            [86.783756, 52.554423],
            [86.788456, 52.52445],
            [86.796288, 52.515051],
            [86.763914, 52.515364],
            [86.753313, 52.524032],
            [86.733158, 52.510874],
            [86.708615, 52.518602],
            [86.672489, 52.512711],
            [86.633422, 52.529149],
            [86.627469, 52.527269],
            [86.662873, 52.504294],
            [86.646113, 52.49556],
            [86.677263, 52.464196],
            [86.679427, 52.43683],
            [86.657025, 52.425446],
            [86.622979, 52.423775],
            [86.62298, 52.406021],
            [86.642405, 52.402575],
            [86.642823, 52.377093],
            [86.667052, 52.342734],
            [86.683762, 52.348061],
            [86.693579, 52.294799],
            [86.781098, 52.294582],
            [86.771801, 52.279448],
            [86.765849, 52.241643],
            [86.757494, 52.229842],
            [86.730028, 52.213132],
            [86.72418, 52.199242],
            [86.805639, 52.187755],
            [86.801044, 52.158722],
            [86.740159, 52.171358],
            [86.741621, 52.156216],
            [86.726358, 52.138991],
            [86.685905, 52.137939],
            [86.684339, 52.126034],
            [86.656141, 52.118619],
            [86.648831, 52.105669],
            [86.566902, 52.10828],
            [86.530245, 52.118723],
            [86.523039, 52.115694],
            [86.491708, 52.128748],
            [86.464294, 52.125406],
            [86.441736, 52.11183],
            [86.454269, 52.1017],
            [86.442259, 52.094494],
            [86.452556, 52.074227],
            [86.416986, 52.065356],
            [86.395733, 52.033191],
            [86.376726, 52.039457],
            [86.347589, 52.035175],
            [86.293857, 52.038203],
            [86.267853, 52.035696],
            [86.232449, 52.040291],
            [86.227959, 52.011468],
            [86.177726, 52.018256],
            [86.156995, 52.032667],
            [86.154175, 52.051361],
            [86.130729, 52.068279],
            [86.143888, 52.084258],
            [86.104516, 52.086451],
            [86.084934, 52.077365],
            [86.027599, 52.08739],
            [86.012143, 52.084362],
            [85.980499, 52.100549],
            [85.945461, 52.100549],
            [85.918843, 52.105662],
            [85.91277, 52.059819],
            [85.874394, 52.058044],
            [85.853611, 52.06337],
            [85.828547, 52.051046],
            [85.800245, 52.053135],
            [85.830375, 52.027444],
            [85.862436, 52.023998],
            [85.889903, 52.026922],
            [85.860243, 51.996636],
            [85.81753, 51.971154],
            [85.819514, 51.94609],
            [85.836955, 51.921652],
            [85.865884, 51.906092],
            [85.853561, 51.891053],
            [85.749909, 51.867242],
            [85.721399, 51.841342],
            [85.735185, 51.830899],
            [85.771946, 51.827244],
            [85.776959, 51.819516],
            [85.743749, 51.806775],
            [85.744504, 51.785992],
            [85.702837, 51.732469],
            [85.676337, 51.717379],
            [85.66853, 51.728449],
            [85.626286, 51.747246],
            [85.606209, 51.731999],
            [85.582319, 51.697222],
            [85.556263, 51.69174],
            [85.502949, 51.701243],
            [85.474099, 51.696491],
            [85.490182, 51.674508],
            [85.473159, 51.663385],
            [85.478173, 51.622395],
            [85.446007, 51.601769],
            [85.454049, 51.572266],
            [85.444258, 51.563389],
            [85.325933, 51.5613],
            [85.295438, 51.546366],
            [85.29912, 51.530649],
            [85.260818, 51.528716],
            [85.244031, 51.499162],
            [85.215259, 51.495506],
            [85.193015, 51.485428],
            [85.166854, 51.486525],
            [85.141032, 51.502137],
            [85.112573, 51.528716],
            [85.061087, 51.512058],
            [84.995946, 51.514356],
            [84.968218, 51.506001],
            [84.92057, 51.482868],
            [84.923129, 51.465532],
            [84.897987, 51.437753],
            [84.871669, 51.431069],
            [84.851356, 51.442713],
            [84.813812, 51.427831],
            [84.788095, 51.42616],
            [84.768905, 51.417387],
            [84.716156, 51.426799],
            [84.691153, 51.410547],
            [84.68306, 51.391487],
            [84.641286, 51.374725],
            [84.633715, 51.34705],
            [84.594526, 51.336189],
            [84.57719, 51.343604],
            [84.53257, 51.336084],
            [84.457012, 51.316085],
            [84.460145, 51.301046],
            [84.451242, 51.286164],
            [84.418867, 51.280055],
            [84.414168, 51.2717],
            [84.386597, 51.265799],
            [84.331794, 51.290341],
            [84.286914, 51.282665],
            [84.256471, 51.282613],
            [84.234305, 51.255877],
            [84.196186, 51.250238],
            [84.197805, 51.222876],
            [84.185508, 51.219325],
            [84.179634, 51.192381],
            [84.155849, 51.184966],
            [84.159557, 51.159484],
            [84.145511, 51.129929],
            [84.146973, 51.104134],
            [84.133971, 51.102933],
            [84.120656, 51.071237],
            [84.089168, 51.098285],
            [84.077367, 51.098651],
            [84.073607, 51.112854],
            [84.096635, 51.143349],
            [84.057211, 51.147683],
            [84.042041, 51.15374],
            [84.008805, 51.150815],
            [84.004053, 51.142356],
            [83.948102, 51.144497],
            [83.932803, 51.136925],
            [83.956379, 51.11019],
            [83.941707, 51.069879],
            [83.978526, 51.069498],
            [84.02079, 51.055937],
            [84.038283, 51.057608],
            [84.02079, 51.044188],
            [84.061625, 51.032231],
            [84.101388, 51.042257],
            [84.127497, 51.014216],
            [84.145852, 51.014269],
            [84.222168, 50.987377],
            [84.247963, 50.999074],
            [84.280599, 50.995158],
            [84.305924, 51.003878],
            [84.34091, 50.980694],
            [84.417122, 50.968946],
            [84.490696, 50.948059],
            [84.478948, 50.935527],
            [84.438271, 50.908374],
            [84.438297, 50.859238],
            [84.47075, 50.859656],
            [84.521689, 50.84326],
            [84.539808, 50.847646],
            [84.589258, 50.821747],
            [84.572262, 50.814645],
            [84.5708, 50.79428],
            [84.557563, 50.77992],
            [84.541845, 50.781174],
            [84.529888, 50.767023],
            [84.511298, 50.765456],
            [84.50391, 50.731097],
            [84.514354, 50.721437],
            [84.503101, 50.707234],
            [84.479838, 50.696895],
            [84.473729, 50.687078],
            [84.445793, 50.680212],
            [84.413339, 50.688514],
            [84.40424, 50.707338],
            [84.354764, 50.693788],
            [84.341136, 50.686111],
            [84.323826, 50.690759],
            [84.288892, 50.687469],
            [84.249037, 50.667052],
            [84.198804, 50.666634],
            [84.127971, 50.659636],
            [84.073109, 50.634915],
            [84.055122, 50.662095],
            [83.985413, 50.712688],
            [83.953576, 50.723465],
            [83.939578, 50.741255],
            [83.943626, 50.760098],
            [83.9717, 50.7902],
            [83.9318, 50.8101],
            [83.8777, 50.8313],
            [83.8741, 50.8421],
            [83.8436, 50.8537],
            [83.8264, 50.8749],
            [83.793, 50.8855],
            [83.7062, 50.8893],
            [83.6787, 50.9052],
            [83.6485, 50.9482],
            [83.573, 50.9479],
            [83.5366, 50.9588],
            [83.5199, 50.9402],
            [83.4838, 50.957],
            [83.4864, 50.9778],
            [83.4479, 50.9777],
            [83.4423, 51.0068],
            [83.4128, 51.0137],
            [83.3927, 51.0124],
            [83.3677, 50.9966],
            [83.3029, 51.0123],
            [83.1982, 50.9963],
            [83.1746, 51.011],
            [83.1219, 51.0103],
            [83.1098, 50.9637],
            [83.0733, 50.9445],
            [83.03, 50.9293],
            [82.9951, 50.9126],
            [82.9825, 50.8842],
            [82.884, 50.9082],
            [82.8736, 50.8958],
            [82.8288, 50.9145],
            [82.789, 50.9151],
            [82.7738, 50.9261],
            [82.7268, 50.9249],
            [82.7305, 50.9097],
            [82.7171, 50.8937],
            [82.7327, 50.8528],
            [82.7294, 50.8423],
            [82.7062, 50.8272],
            [82.7014, 50.8121],
            [82.6839, 50.8007],
            [82.6178, 50.7739],
            [82.5699, 50.7429],
            [82.5532, 50.7503],
            [82.4932, 50.7358],
            [82.4737, 50.7478],
            [82.4302, 50.7618],
            [82.4132, 50.7602],
            [82.3598, 50.7779],
            [82.3066, 50.7489],
            [82.2787, 50.7672],
            [82.2501, 50.7549],
            [82.2338, 50.7308],
            [82.2056, 50.7348],
            [82.1687, 50.7315],
            [82.0898, 50.7502],
            [82.0728, 50.7495],
            [82.0604, 50.764],
            [82.0377, 50.7633],
            [82.0182, 50.7796],
            [81.9673, 50.7847],
            [81.8952, 50.7977],
            [81.8183, 50.7785],
            [81.7985, 50.7822],
            [81.7904, 50.8092],
            [81.7693, 50.8182],
            [81.7344, 50.7657],
            [81.6704, 50.7508],
            [81.5442, 50.7459],
            [81.4877, 50.7534],
            [81.4565, 50.7535],
            [81.4626, 50.8029],
            [81.4855, 50.8207],
            [81.4687, 50.846],
            [81.4503, 50.8514],
            [81.4264, 50.8741],
            [81.4421, 50.8857],
            [81.434, 50.9235],
            [81.4141, 50.9262],
            [81.4173, 50.9719],
            [81.3334, 50.9732],
            [81.2906, 50.9716],
            [81.2792, 50.9644],
            [81.2479, 50.9609],
            [81.2037, 50.9448],
            [81.1838, 50.9522],
            [81.151, 50.9458],
            [81.0657, 50.9449],
            [81.0642, 50.9521],
            [81.1015, 51.0583],
            [81.1286, 51.0736],
            [81.1601, 51.1095],
            [81.1789, 51.1598],
            [81.174206, 51.184603],
            [81.1429, 51.21163],
            [81.112507, 51.210046],
            [81.0889, 51.1797],
            [81.0503, 51.1815],
            [81.0314, 51.1948],
            [80.9241, 51.2209],
            [80.9384, 51.2606],
            [80.8983, 51.2651],
            [80.6787, 51.3177],
            [80.6644, 51.3105],
            [80.6332, 51.2672],
            [80.6366, 51.2052],
            [80.4691, 51.2055],
            [80.4506, 51.2036],
            [80.4365, 51.1812],
            [80.433, 51.1208],
            [80.4558, 51.1202],
            [80.4617, 51.1103],
            [80.4846, 51.1004],
            [80.4832, 50.9726],
            [80.4648, 50.9628],
            [80.4221, 50.9606],
            [80.3798, 50.928],
            [80.3287, 50.9268],
            [80.3203, 50.916],
            [80.2478, 50.9147],
            [80.2381, 50.8984],
            [80.1926, 50.8963],
            [80.1933, 50.8584],
            [80.1834, 50.8566],
            [80.1882, 50.8226],
            [80.09, 50.8253],
            [80.0882, 50.8488],
            [80.0682, 50.8505],
            [80.0585, 50.8394],
            [80.0773, 50.7533],
            [80.069, 50.7426],
            [79.950015, 50.901251],
            [79.5309, 51.4558],
            [79.3406, 51.708],
            [79.223675, 51.873959],
            [79.179853, 51.931089],
            [79.08039, 52.036056],
            [78.981895, 52.143071],
            [78.8147, 52.3174],
            [78.62516, 52.533463],
            [78.5788, 52.5798],
            [78.5424, 52.6249],
            [78.4317, 52.7484],
            [78.4361, 52.7503],
            [78.2821, 52.9158],
            [78.1963, 52.9995],
            [78.1917, 53.0012],
            [78.1216, 53.0695],
            [78.1159, 53.0667],
            [78, 53.1941],
            [77.9608, 53.2318],
            [77.907228, 53.290941],
            [77.888953, 53.367194],
            [77.941677, 53.356559],
            [77.9559, 53.383931],
            [77.967922, 53.388269],
            [77.937435, 53.3984],
            [77.937867, 53.414489],
            [77.916811, 53.419996],
            [77.922449, 53.448716],
            [78.069061, 53.439325],
            [78.072408, 53.47323],
            [78.013775, 53.480356],
            [77.978843, 53.491694],
            [77.962778, 53.487273],
            [77.931764, 53.503355],
            [77.912165, 53.534344],
            [77.915788, 53.571123],
            [77.958488, 53.581531],
            [77.992825, 53.576564],
            [78.107891, 53.576234],
            [78.103719, 53.585743],
            [78.157169, 53.586068],
            [78.156898, 53.6217],
            [78.202142, 53.622996],
            [78.23162, 53.617058],
            [78.235887, 53.53575],
            [78.260237, 53.508432],
            [78.252246, 53.499146],
            [78.263908, 53.488672],
            [78.286692, 53.495475],
            [78.333933, 53.478955],
            [78.362871, 53.474204],
            [78.367676, 53.485649],
            [78.42172, 53.48619],
            [78.422961, 53.513292],
            [78.494119, 53.508542],
            [78.506375, 53.514157],
            [78.502811, 53.52679],
            [78.524839, 53.53003],
            [78.525811, 53.510485],
            [78.578829, 53.496233],
            [78.579701, 53.51212],
            [78.645884, 53.512538],
            [78.645073, 53.57333],
            [78.65112, 53.582292],
            [78.615323, 53.636928],
            [78.689492, 53.652117],
            [78.707801, 53.631578],
            [78.797754, 53.658201],
            [78.941852, 53.675047],
            [79.040491, 53.649997],
            [79.089513, 53.70215],
            [79.123581, 53.674832],
            [79.257392, 53.677587],
            [79.299748, 53.709278],
            [79.386509, 53.71565],
            [79.421441, 53.679585],
            [79.638802, 53.742106],
            [79.641069, 53.773851],
            [79.62066, 53.791992],
            [79.758333, 53.835076],
            [79.776474, 53.811969],
            [79.893523, 53.844579],
            [79.873438, 53.859912],
            [79.849034, 53.890361],
            [79.878621, 53.897488],
            [79.877019, 53.91411],
            [80.023096, 53.960332],
            [80.02072, 53.964003],
            [80.090906, 53.990135],
            [80.069094, 54.010866],
            [80.050953, 54.044987],
            [80.193917, 54.078245],
            [80.219425, 54.030943],
            [80.296281, 54.051683],
            [80.354914, 54.081918],
            [80.465052, 54.115392],
            [80.478657, 54.100275],
            [80.518178, 54.103298],
            [80.570223, 54.126622],
            [80.530918, 54.168949],
            [80.620216, 54.19616],
            [80.637493, 54.181691],
            [80.702496, 54.222723],
            [80.798921, 54.256197],
            [80.817062, 54.231578],
            [80.863601, 54.244752],
            [80.851723, 54.261812],
            [80.813605, 54.343228],
            [80.951709, 54.375622],
            [80.973198, 54.33675],
            [80.97795, 54.308676],
            [81.015419, 54.254471],
            [81.088846, 54.194219],
            [81.120593, 54.136991],
            [81.126424, 54.114532],
            [81.149316, 54.10503],
            [81.208489, 54.112589],
            [81.215616, 54.100495],
            [81.238939, 54.086026],
            [81.253624, 54.089698],
            [81.276516, 54.080844],
            [81.322515, 54.090994],
            [81.329318, 54.079116],
            [81.343787, 54.081924],
            [81.364736, 54.059896],
            [81.393242, 54.066375],
            [81.475738, 54.077605],
            [81.480921, 54.057737],
            [81.51418, 54.010443],
            [81.542902, 54.023185],
            [81.592356, 54.024697],
            [81.590629, 54.015195],
            [81.570545, 54.014547],
            [81.564283, 53.969196],
            [81.542904, 53.933131],
            [81.540528, 53.904625],
            [81.570115, 53.91132],
            [81.622376, 53.957103],
            [81.663408, 53.979562],
            [81.696665, 53.979347],
            [81.750331, 53.946522],
            [81.774087, 53.957967],
            [81.831192, 53.964117],
            [81.845785, 53.923415],
            [81.828509, 53.9178],
            [81.835744, 53.869857],
            [81.773656, 53.8569],
            [81.7209, 53.841738],
            [81.746662, 53.813061],
            [81.741696, 53.798159],
            [81.715781, 53.779803],
            [81.716213, 53.771597],
            [81.746015, 53.756264],
            [81.767827, 53.756912],
            [81.805728, 53.729702],
            [81.78802, 53.723007],
            [81.81955, 53.700332],
            [81.819644, 53.685664],
            [81.85108, 53.712425],
            [81.958843, 53.717177],
            [81.961435, 53.708755],
            [82.057537, 53.681761],
            [82.061856, 53.662757],
            [82.113686, 53.657358],
            [82.137226, 53.647856],
            [82.152343, 53.659518],
            [82.194454, 53.677011],
            [82.185816, 53.663837],
            [82.184953, 53.63857],
            [82.149536, 53.617838],
            [82.1504, 53.605745],
            [82.195103, 53.608336],
            [82.200395, 53.591924],
            [82.218319, 53.585445],
            [82.25784, 53.586309],
            [82.291529, 53.57292],
            [82.291961, 53.559099],
            [82.322196, 53.562554],
            [82.33321, 53.528649],
            [82.409552, 53.509646],
            [82.431579, 53.513533],
            [82.451448, 53.502519],
            [82.48125, 53.496473],
            [82.505437, 53.498416],
            [82.530488, 53.50943],
            [82.570873, 53.499281],
            [82.583722, 53.512562],
            [82.502413, 53.547223],
            [82.633391, 53.621512],
            [82.666972, 53.642676],
            [82.620109, 53.670102],
            [82.759724, 53.749143],
            [82.71492, 53.776833],
            [83.102283, 53.998919],
            [83.084296, 54.00599],
            [83.099963, 54.01218],
            [83.198057, 54.065828],
            [83.220035, 54.049973],
            [83.274997, 54.051269],
            [83.289034, 54.069409],
            [83.290761, 54.085822],
            [83.301232, 54.084927],
            [83.306101, 54.064866],
            [83.349197, 53.934281],
            [83.451631, 53.94698],
            [83.442258, 53.973742],
            [83.488263, 53.978316],
            [83.475946, 54.063209],
            [83.490511, 54.077354],
            [83.533313, 54.077922],
            [83.532383, 54.093201],
            [83.55414, 54.109229],
            [83.663542, 54.11118],
            [83.729611, 54.101301],
            [83.786043, 54.112562],
            [83.829092, 54.12782],
            [83.83726, 54.118718],
            [83.883979, 54.121393],
            [83.884539, 54.105261],
            [83.857328, 54.087985],
            [83.861432, 54.078915],
            [83.883243, 54.08237],
            [83.913477, 54.099215],
            [83.955589, 54.106126],
            [83.997701, 54.122538],
            [84.051042, 54.134632],
            [84.117126, 54.154285],
            [84.146928, 54.166594],
            [84.186016, 54.171993],
            [84.189687, 54.179336],
            [84.221649, 54.191214],
            [84.252747, 54.179552],
            [84.276503, 54.178689],
            [84.268512, 54.163788],
            [84.27931, 54.156229],
            [84.300042, 54.16206],
            [84.353168, 54.158389],
            [84.370877, 54.172642],
            [84.398087, 54.177609],
            [84.430481, 54.161629],
            [84.423031, 54.146728],
            [84.437716, 54.140249],
            [84.458232, 54.14608],
            [84.550122, 54.162061],
            [84.577548, 54.207844],
            [84.577224, 54.23743],
            [84.596876, 54.254923],
            [84.633589, 54.26896],
            [84.670733, 54.291852],
            [84.692329, 54.28926],
            [84.711117, 54.308049],
            [84.766618, 54.336555],
            [84.811969, 54.351456],
            [84.807651, 54.334612],
            [84.825955, 54.326567],
            [84.843176, 54.339579],
            [84.991215, 54.393785],
            [85.116875, 54.437789],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5538",
      properties: {
        name: "Амурская область",
        density: 0,
        path: "/world/Russia/Амурская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [119.85614, 56.896079],
            [119.907848, 56.908417],
            [120.002964, 56.916908],
            [120.035444, 56.909939],
            [120.051691, 56.926548],
            [120.081591, 56.926528],
            [120.121285, 56.944807],
            [120.125415, 56.952552],
            [120.175704, 56.961689],
            [120.24095, 56.991053],
            [120.243318, 56.999494],
            [120.267611, 57.005771],
            [120.274705, 57.020748],
            [120.291281, 57.026973],
            [120.329354, 57.021089],
            [120.391607, 57.030655],
            [120.425089, 57.028316],
            [120.4529, 57.035401],
            [120.470286, 57.032363],
            [120.498733, 57.041442],
            [120.51802, 57.037749],
            [120.543639, 57.044459],
            [120.564077, 57.035732],
            [120.60775, 57.027165],
            [120.646918, 57.035128],
            [120.666643, 57.044899],
            [120.696528, 57.03272],
            [120.717652, 57.034532],
            [120.738392, 57.026004],
            [120.777134, 57.029479],
            [120.797514, 57.023984],
            [120.806927, 57.042433],
            [120.842924, 57.046566],
            [120.879772, 57.05667],
            [120.897639, 57.048025],
            [120.9055, 57.060508],
            [120.927393, 57.057979],
            [120.965271, 57.042628],
            [121.009368, 57.043304],
            [121.049646, 57.004354],
            [121.095533, 56.981504],
            [121.13937, 57.001474],
            [121.18389, 56.99315],
            [121.233985, 56.995686],
            [121.271793, 56.98607],
            [121.300256, 56.990032],
            [121.345001, 56.984021],
            [121.40559, 56.985529],
            [121.459334, 56.970512],
            [121.486135, 56.953604],
            [121.518671, 56.920594],
            [121.506818, 56.901732],
            [121.511433, 56.870348],
            [121.534915, 56.849884],
            [121.585622, 56.848448],
            [121.609072, 56.821492],
            [121.616745, 56.804461],
            [121.634175, 56.789855],
            [121.60523, 56.761543],
            [121.61031, 56.738243],
            [121.64543, 56.741957],
            [121.683531, 56.7596],
            [121.7234, 56.758113],
            [121.750533, 56.749057],
            [121.761611, 56.754279],
            [121.835604, 56.740431],
            [121.858054, 56.745453],
            [121.885619, 56.73863],
            [121.91149, 56.748961],
            [121.957703, 56.744126],
            [121.989547, 56.762514],
            [122.048465, 56.753445],
            [122.111409, 56.754088],
            [122.134951, 56.746468],
            [122.163943, 56.74619],
            [122.203664, 56.724403],
            [122.179695, 56.70327],
            [122.233819, 56.69262],
            [122.24674, 56.678377],
            [122.278935, 56.660204],
            [122.295484, 56.640747],
            [122.378095, 56.634413],
            [122.386535, 56.625956],
            [122.435298, 56.616501],
            [122.46076, 56.599617],
            [122.46697, 56.571713],
            [122.506772, 56.564319],
            [122.550283, 56.525678],
            [122.544551, 56.510562],
            [122.558339, 56.507878],
            [122.571597, 56.485402],
            [122.610659, 56.488739],
            [122.640219, 56.475338],
            [122.653487, 56.492313],
            [122.689181, 56.502034],
            [122.715024, 56.494906],
            [122.761357, 56.490427],
            [122.790152, 56.508723],
            [122.799947, 56.526449],
            [122.78839, 56.5357],
            [122.800418, 56.559491],
            [122.787082, 56.577118],
            [122.794468, 56.585153],
            [122.834532, 56.5845],
            [122.884679, 56.596382],
            [122.903993, 56.583978],
            [122.894241, 56.566618],
            [122.90191, 56.556641],
            [122.96832, 56.538372],
            [122.99672, 56.525293],
            [123.020298, 56.521465],
            [123.025769, 56.504112],
            [123.058842, 56.487461],
            [123.100708, 56.478222],
            [123.108703, 56.457535],
            [123.166458, 56.460264],
            [123.17423, 56.446347],
            [123.194051, 56.440672],
            [123.232583, 56.443518],
            [123.284629, 56.430454],
            [123.33599, 56.428959],
            [123.348247, 56.420605],
            [123.372997, 56.418631],
            [123.383251, 56.425375],
            [123.434332, 56.419026],
            [123.491953, 56.422317],
            [123.551251, 56.42215],
            [123.572502, 56.409158],
            [123.603114, 56.413054],
            [123.661978, 56.413467],
            [123.683353, 56.403783],
            [123.709061, 56.40869],
            [123.753002, 56.424536],
            [123.808748, 56.418599],
            [123.829691, 56.410319],
            [123.920806, 56.387865],
            [123.935276, 56.379376],
            [123.946256, 56.359978],
            [123.980676, 56.353708],
            [123.969214, 56.336503],
            [123.992505, 56.318727],
            [123.999553, 56.293768],
            [124.016243, 56.289428],
            [124.032761, 56.256009],
            [124.063521, 56.248675],
            [124.080354, 56.232304],
            [124.121072, 56.230813],
            [124.143874, 56.201065],
            [124.176827, 56.197843],
            [124.177105, 56.18952],
            [124.200088, 56.174559],
            [124.193543, 56.166242],
            [124.212179, 56.155897],
            [124.204821, 56.131779],
            [124.224998, 56.110303],
            [124.203328, 56.101006],
            [124.214495, 56.084136],
            [124.301896, 56.078549],
            [124.315882, 56.071031],
            [124.348449, 56.072462],
            [124.371259, 56.05646],
            [124.394278, 56.052636],
            [124.396501, 56.020098],
            [124.388335, 55.993621],
            [124.42344, 55.953278],
            [124.414149, 55.930917],
            [124.41842, 55.920287],
            [124.450518, 55.91813],
            [124.485281, 55.891622],
            [124.505887, 55.884648],
            [124.531373, 55.892655],
            [124.549615, 55.888563],
            [124.559345, 55.89908],
            [124.639793, 55.937683],
            [124.660585, 55.92611],
            [124.702015, 55.924475],
            [124.724984, 55.907689],
            [124.761685, 55.869899],
            [124.779326, 55.869585],
            [124.784233, 55.882057],
            [124.834786, 55.882086],
            [124.839558, 55.864479],
            [124.869766, 55.861765],
            [124.91107, 55.84187],
            [124.941639, 55.84472],
            [124.964315, 55.837642],
            [124.977974, 55.842417],
            [124.977072, 55.860028],
            [125.010704, 55.869518],
            [125.052581, 55.859672],
            [125.084625, 55.879702],
            [125.139843, 55.88571],
            [125.159567, 55.896125],
            [125.200173, 55.897606],
            [125.207932, 55.905671],
            [125.221944, 55.895312],
            [125.253349, 55.907761],
            [125.291324, 55.896656],
            [125.295861, 55.885692],
            [125.329996, 55.874587],
            [125.391106, 55.876089],
            [125.420122, 55.869934],
            [125.448967, 55.851111],
            [125.444881, 55.816352],
            [125.466168, 55.798982],
            [125.488188, 55.79836],
            [125.509813, 55.785128],
            [125.502518, 55.773386],
            [125.531076, 55.746937],
            [125.565884, 55.739259],
            [125.581877, 55.758009],
            [125.601067, 55.761171],
            [125.616844, 55.779446],
            [125.651055, 55.784539],
            [125.666438, 55.793482],
            [125.683073, 55.786758],
            [125.697082, 55.800126],
            [125.735754, 55.797666],
            [125.764081, 55.804167],
            [125.781357, 55.799199],
            [125.803634, 55.806601],
            [125.838889, 55.803592],
            [125.87371, 55.794755],
            [125.931157, 55.774072],
            [125.993836, 55.767387],
            [125.995309, 55.746717],
            [125.98538, 55.72026],
            [126.004957, 55.72002],
            [126.04385, 55.731073],
            [126.091726, 55.711501],
            [126.067587, 55.707231],
            [126.080725, 55.690846],
            [126.087762, 55.667953],
            [126.131362, 55.667145],
            [126.141116, 55.651194],
            [126.133551, 55.638127],
            [126.115326, 55.650901],
            [126.06703, 55.64522],
            [126.065303, 55.632538],
            [126.080632, 55.617584],
            [126.070296, 55.611719],
            [126.077209, 55.59537],
            [126.096092, 55.58978],
            [126.182955, 55.59136],
            [126.201788, 55.602109],
            [126.235033, 55.607837],
            [126.261561, 55.595834],
            [126.2966, 55.609109],
            [126.317651, 55.584693],
            [126.336377, 55.583887],
            [126.361743, 55.596872],
            [126.385915, 55.5946],
            [126.387446, 55.573386],
            [126.414418, 55.560028],
            [126.492168, 55.569373],
            [126.503095, 55.583304],
            [126.529871, 55.579799],
            [126.533219, 55.593679],
            [126.51104, 55.612279],
            [126.537325, 55.625262],
            [126.543767, 55.640718],
            [126.562135, 55.641494],
            [126.582764, 55.654335],
            [126.608274, 55.653765],
            [126.635537, 55.660751],
            [126.692811, 55.666712],
            [126.699115, 55.655458],
            [126.682276, 55.633006],
            [126.72505, 55.599593],
            [126.758137, 55.619282],
            [126.787303, 55.627425],
            [126.813424, 55.617097],
            [126.857229, 55.611502],
            [126.86906, 55.620647],
            [126.926225, 55.629892],
            [126.923687, 55.661263],
            [126.906903, 55.676552],
            [126.934872, 55.690885],
            [126.950983, 55.689832],
            [126.966224, 55.699928],
            [127.061432, 55.69414],
            [127.089252, 55.702066],
            [127.134852, 55.69284],
            [127.161185, 55.696863],
            [127.186956, 55.717696],
            [127.231959, 55.700578],
            [127.260636, 55.680598],
            [127.274393, 55.688341],
            [127.321454, 55.687805],
            [127.327396, 55.659531],
            [127.315427, 55.649321],
            [127.338702, 55.645834],
            [127.348407, 55.635027],
            [127.34013, 55.61952],
            [127.378081, 55.608614],
            [127.420998, 55.591162],
            [127.423289, 55.57853],
            [127.458775, 55.57803],
            [127.495818, 55.588953],
            [127.513818, 55.604875],
            [127.541243, 55.607166],
            [127.565807, 55.595864],
            [127.589912, 55.594064],
            [127.599909, 55.602203],
            [127.573759, 55.631325],
            [127.569845, 55.643584],
            [127.542464, 55.66484],
            [127.637135, 55.679242],
            [127.641861, 55.69463],
            [127.667205, 55.701387],
            [127.677468, 55.715407],
            [127.706955, 55.728025],
            [127.733709, 55.731276],
            [127.764406, 55.723207],
            [127.789476, 55.723041],
            [127.784796, 55.714376],
            [127.803144, 55.692801],
            [127.787496, 55.674756],
            [127.80183, 55.666932],
            [127.85497, 55.662208],
            [127.879384, 55.668912],
            [127.924375, 55.662004],
            [128.044954, 55.677478],
            [128.063898, 55.668232],
            [128.11394, 55.687564],
            [128.15987, 55.685034],
            [128.19113, 55.689404],
            [128.200153, 55.700994],
            [128.225972, 55.699317],
            [128.237514, 55.690296],
            [128.285067, 55.6907],
            [128.290426, 55.696913],
            [128.329241, 55.673356],
            [128.381239, 55.630716],
            [128.422718, 55.624975],
            [128.45012, 55.603465],
            [128.475602, 55.597812],
            [128.491531, 55.583673],
            [128.48832, 55.5562],
            [128.512443, 55.544781],
            [128.54085, 55.554281],
            [128.55547, 55.549877],
            [128.581716, 55.556448],
            [128.634208, 55.54984],
            [128.682784, 55.571789],
            [128.70163, 55.562326],
            [128.703782, 55.541469],
            [128.687501, 55.532573],
            [128.703037, 55.515137],
            [128.718942, 55.517492],
            [128.76119, 55.511876],
            [128.769486, 55.490851],
            [128.785939, 55.487671],
            [128.821253, 55.502528],
            [128.830079, 55.520015],
            [128.852259, 55.499776],
            [128.873512, 55.50075],
            [128.90301, 55.513615],
            [128.94607, 55.505558],
            [128.95687, 55.494389],
            [129.011492, 55.497503],
            [129.022033, 55.503364],
            [129.07264, 55.513753],
            [129.100958, 55.539514],
            [129.095209, 55.561352],
            [129.09908, 55.631036],
            [129.071706, 55.637336],
            [129.06835, 55.651575],
            [129.043729, 55.67958],
            [129.05084, 55.69489],
            [129.097336, 55.695221],
            [129.105932, 55.701614],
            [129.151389, 55.705813],
            [129.174343, 55.698186],
            [129.234483, 55.712442],
            [129.262471, 55.730629],
            [129.285711, 55.719631],
            [129.341944, 55.727383],
            [129.351993, 55.713298],
            [129.375248, 55.718552],
            [129.383014, 55.732466],
            [129.421298, 55.723966],
            [129.455177, 55.729946],
            [129.467555, 55.754544],
            [129.488847, 55.760802],
            [129.525931, 55.743516],
            [129.584685, 55.762091],
            [129.617794, 55.762642],
            [129.640571, 55.769539],
            [129.659195, 55.786023],
            [129.685133, 55.767456],
            [129.711057, 55.761009],
            [129.72958, 55.770945],
            [129.788367, 55.773613],
            [129.80005, 55.757133],
            [129.840021, 55.743493],
            [129.870931, 55.740484],
            [129.871608, 55.751951],
            [129.903307, 55.77829],
            [129.964304, 55.754349],
            [129.972114, 55.737823],
            [129.998493, 55.72293],
            [130.00109, 55.71328],
            [130.04866, 55.719237],
            [130.067504, 55.711407],
            [130.091433, 55.731346],
            [130.121201, 55.726204],
            [130.126605, 55.712721],
            [130.231634, 55.724611],
            [130.239298, 55.730478],
            [130.287782, 55.740975],
            [130.29919, 55.732237],
            [130.353182, 55.73094],
            [130.35538, 55.745717],
            [130.38085, 55.747751],
            [130.438768, 55.743758],
            [130.467143, 55.730523],
            [130.483496, 55.733953],
            [130.504554, 55.727425],
            [130.524125, 55.737705],
            [130.549048, 55.739176],
            [130.624824, 55.76245],
            [130.652523, 55.75679],
            [130.680542, 55.744046],
            [130.711726, 55.753483],
            [130.736202, 55.753972],
            [130.809451, 55.735556],
            [130.809393, 55.727473],
            [130.83825, 55.7296],
            [130.85008, 55.712481],
            [130.89182, 55.706792],
            [130.902987, 55.715595],
            [130.952768, 55.712176],
            [130.971775, 55.69907],
            [131.014843, 55.687076],
            [131.05933, 55.656553],
            [131.070853, 55.663672],
            [131.09436, 55.654831],
            [131.147458, 55.649038],
            [131.187228, 55.664624],
            [131.21572, 55.694136],
            [131.255811, 55.686688],
            [131.267424, 55.674583],
            [131.298941, 55.673818],
            [131.33524, 55.651016],
            [131.363908, 55.657304],
            [131.36989, 55.665869],
            [131.386553, 55.657876],
            [131.424769, 55.661943],
            [131.455822, 55.674108],
            [131.44345, 55.686602],
            [131.493274, 55.697194],
            [131.525618, 55.686211],
            [131.524138, 55.676312],
            [131.564349, 55.655679],
            [131.579067, 55.655608],
            [131.594161, 55.670749],
            [131.59433, 55.68442],
            [131.61599, 55.701441],
            [131.636182, 55.708917],
            [131.666167, 55.700777],
            [131.744197, 55.691922],
            [131.773743, 55.694409],
            [131.798981, 55.676354],
            [131.804212, 55.653878],
            [131.827437, 55.647814],
            [131.867794, 55.664775],
            [131.897156, 55.669199],
            [131.940055, 55.659021],
            [131.932155, 55.675741],
            [131.941487, 55.704714],
            [131.974634, 55.714083],
            [132.038567, 55.695426],
            [132.044689, 55.701042],
            [132.083294, 55.701862],
            [132.10528, 55.689652],
            [132.156486, 55.679722],
            [132.155122, 55.688915],
            [132.174118, 55.705076],
            [132.197944, 55.710523],
            [132.220982, 55.707601],
            [132.234955, 55.728821],
            [132.271881, 55.73813],
            [132.306221, 55.732869],
            [132.320573, 55.741759],
            [132.386614, 55.738646],
            [132.415113, 55.746301],
            [132.441057, 55.742781],
            [132.470163, 55.721503],
            [132.49409, 55.719277],
            [132.501438, 55.710682],
            [132.541717, 55.7169],
            [132.558152, 55.709942],
            [132.559816, 55.690028],
            [132.570898, 55.669543],
            [132.591499, 55.666979],
            [132.645141, 55.632425],
            [132.661506, 55.626406],
            [132.658108, 55.604619],
            [132.686741, 55.582823],
            [132.647594, 55.564962],
            [132.639372, 55.556642],
            [132.66118, 55.521493],
            [132.690269, 55.510805],
            [132.708463, 55.511444],
            [132.745636, 55.469149],
            [132.763257, 55.456295],
            [132.799444, 55.454878],
            [132.817532, 55.438432],
            [132.844827, 55.435078],
            [132.888819, 55.441954],
            [132.902121, 55.427253],
            [132.935755, 55.429922],
            [132.965341, 55.42034],
            [132.972059, 55.401858],
            [132.930493, 55.392793],
            [132.917857, 55.384454],
            [132.88873, 55.396391],
            [132.84476, 55.379521],
            [132.832366, 55.382111],
            [132.786114, 55.362795],
            [132.77362, 55.347835],
            [132.739113, 55.346101],
            [132.69543, 55.360101],
            [132.67767, 55.355463],
            [132.692648, 55.30112],
            [132.650688, 55.280196],
            [132.631969, 55.263506],
            [132.611489, 55.235266],
            [132.546045, 55.24724],
            [132.512173, 55.245663],
            [132.481387, 55.234289],
            [132.45272, 55.24581],
            [132.425783, 55.243655],
            [132.372203, 55.247707],
            [132.336473, 55.230074],
            [132.33884, 55.221029],
            [132.36501, 55.218049],
            [132.352911, 55.206547],
            [132.377434, 55.20016],
            [132.39203, 55.185897],
            [132.36702, 55.183974],
            [132.363678, 55.193641],
            [132.35024, 55.17724],
            [132.382877, 55.174851],
            [132.401197, 55.16423],
            [132.375761, 55.159525],
            [132.381305, 55.147714],
            [132.411029, 55.149204],
            [132.39962, 55.14084],
            [132.43708, 55.120041],
            [132.437862, 55.111038],
            [132.415469, 55.102747],
            [132.435135, 55.091369],
            [132.415874, 55.081978],
            [132.360338, 55.077458],
            [132.294485, 55.062012],
            [132.193126, 55.060493],
            [132.171233, 55.065347],
            [132.127398, 55.066661],
            [132.086281, 55.061585],
            [132.06845, 55.046574],
            [132.044232, 55.041354],
            [132.01722, 55.026447],
            [131.972319, 55.019822],
            [131.979317, 55.013703],
            [132.039048, 54.998904],
            [132.073837, 55.004689],
            [132.088864, 55.000978],
            [132.061569, 54.987634],
            [132.061187, 54.970397],
            [132.028325, 54.970566],
            [132.011689, 54.959621],
            [132.01127, 54.933345],
            [131.974684, 54.917954],
            [131.929887, 54.893947],
            [131.886745, 54.892304],
            [131.875817, 54.876507],
            [131.809849, 54.884415],
            [131.810106, 54.861455],
            [131.771662, 54.843548],
            [131.754974, 54.840598],
            [131.757913, 54.827805],
            [131.736533, 54.813245],
            [131.701373, 54.798631],
            [131.675642, 54.800213],
            [131.671538, 54.775446],
            [131.634512, 54.772392],
            [131.629764, 54.789561],
            [131.618409, 54.793791],
            [131.559365, 54.77305],
            [131.552355, 54.743406],
            [131.539983, 54.741169],
            [131.494919, 54.748459],
            [131.467086, 54.745992],
            [131.449395, 54.734368],
            [131.41561, 54.731071],
            [131.39489, 54.73728],
            [131.36025, 54.724156],
            [131.349703, 54.70509],
            [131.321799, 54.699002],
            [131.28665, 54.658811],
            [131.253537, 54.650819],
            [131.240757, 54.655639],
            [131.209921, 54.643727],
            [131.169472, 54.635017],
            [131.177642, 54.61157],
            [131.170883, 54.606114],
            [131.20158, 54.570333],
            [131.231634, 54.558683],
            [131.21793, 54.544248],
            [131.208436, 54.521452],
            [131.21143, 54.5066],
            [131.196313, 54.511348],
            [131.188497, 54.49117],
            [131.16702, 54.470686],
            [131.165617, 54.453097],
            [131.17511, 54.439123],
            [131.156753, 54.428685],
            [131.136027, 54.390733],
            [131.13416, 54.367465],
            [131.106822, 54.354418],
            [131.11795, 54.333683],
            [131.083896, 54.329824],
            [131.052681, 54.299559],
            [131.037037, 54.291559],
            [130.973721, 54.304445],
            [130.972921, 54.325982],
            [130.939163, 54.339254],
            [130.89305, 54.323398],
            [130.871, 54.321661],
            [130.843275, 54.306688],
            [130.84387, 54.294906],
            [130.814952, 54.284583],
            [130.785615, 54.290015],
            [130.777795, 54.299585],
            [130.735983, 54.298827],
            [130.716215, 54.285494],
            [130.723532, 54.268444],
            [130.668607, 54.256612],
            [130.672055, 54.236408],
            [130.659953, 54.215587],
            [130.628232, 54.198362],
            [130.634532, 54.18585],
            [130.59956, 54.180671],
            [130.570601, 54.168091],
            [130.553757, 54.151245],
            [130.527269, 54.13886],
            [130.511178, 54.122416],
            [130.503197, 54.08369],
            [130.50262, 54.003311],
            [130.496274, 53.987317],
            [130.455883, 53.966052],
            [130.430772, 53.965997],
            [130.405295, 53.949055],
            [130.405986, 53.93662],
            [130.386742, 53.927568],
            [130.391022, 53.919968],
            [130.427177, 53.905036],
            [130.441599, 53.888155],
            [130.474359, 53.889541],
            [130.517043, 53.909166],
            [130.548238, 53.905854],
            [130.550986, 53.893035],
            [130.587779, 53.878462],
            [130.618758, 53.88328],
            [130.627878, 53.895227],
            [130.65933, 53.896283],
            [130.673976, 53.902561],
            [130.711175, 53.888895],
            [130.719581, 53.858272],
            [130.766391, 53.844867],
            [130.817254, 53.856118],
            [130.851848, 53.849167],
            [130.84646, 53.82719],
            [130.848504, 53.798378],
            [130.89644, 53.783358],
            [130.906202, 53.774997],
            [130.931853, 53.787074],
            [130.93774, 53.797147],
            [130.96423, 53.807301],
            [130.985051, 53.80785],
            [130.99277, 53.818009],
            [131.031688, 53.824599],
            [131.044671, 53.816708],
            [131.084135, 53.810082],
            [131.107558, 53.822338],
            [131.162727, 53.800721],
            [131.203124, 53.808668],
            [131.231088, 53.79037],
            [131.25227, 53.786405],
            [131.289005, 53.795343],
            [131.312503, 53.793405],
            [131.316504, 53.780364],
            [131.345268, 53.783016],
            [131.374364, 53.770133],
            [131.387734, 53.758092],
            [131.414561, 53.7569],
            [131.444139, 53.739173],
            [131.435092, 53.712833],
            [131.442634, 53.704592],
            [131.418264, 53.68338],
            [131.44147, 53.639661],
            [131.472705, 53.627898],
            [131.468975, 53.615427],
            [131.49141, 53.602354],
            [131.467661, 53.59119],
            [131.455222, 53.576637],
            [131.480471, 53.567552],
            [131.466018, 53.538948],
            [131.476201, 53.528686],
            [131.45127, 53.51011],
            [131.485045, 53.481995],
            [131.477329, 53.474483],
            [131.491511, 53.447722],
            [131.504989, 53.448214],
            [131.507735, 53.410271],
            [131.495549, 53.399345],
            [131.504116, 53.382573],
            [131.52249, 53.375287],
            [131.511095, 53.354116],
            [131.533441, 53.33366],
            [131.513563, 53.296897],
            [131.506918, 53.268192],
            [131.487196, 53.266821],
            [131.446764, 53.23804],
            [131.441549, 53.221315],
            [131.49127, 53.219156],
            [131.503296, 53.207901],
            [131.525645, 53.220692],
            [131.58572, 53.221008],
            [131.690212, 53.239095],
            [131.722268, 53.23337],
            [131.774508, 53.24406],
            [131.777038, 53.252507],
            [131.80643, 53.257528],
            [131.817631, 53.237873],
            [131.838361, 53.24101],
            [131.854688, 53.231578],
            [131.834435, 53.20262],
            [131.842509, 53.194397],
            [131.82651, 53.17402],
            [131.828641, 53.162787],
            [131.87864, 53.157186],
            [131.896795, 53.135808],
            [131.962098, 53.160551],
            [131.978849, 53.139492],
            [132.013113, 53.139232],
            [132.038072, 53.15787],
            [132.048158, 53.177301],
            [132.041637, 53.195669],
            [132.0611, 53.200524],
            [132.062511, 53.220296],
            [132.095639, 53.230265],
            [132.105603, 53.240534],
            [132.14055, 53.22897],
            [132.141151, 53.220924],
            [132.16666, 53.2281],
            [132.248983, 53.22612],
            [132.279676, 53.255438],
            [132.29973, 53.250482],
            [132.300443, 53.23916],
            [132.33194, 53.238699],
            [132.344917, 53.247437],
            [132.36829, 53.229348],
            [132.392853, 53.232815],
            [132.399571, 53.217513],
            [132.412575, 53.226481],
            [132.43982, 53.220104],
            [132.475178, 53.218542],
            [132.491155, 53.227295],
            [132.506664, 53.215431],
            [132.56206, 53.229671],
            [132.602166, 53.227928],
            [132.60807, 53.238042],
            [132.662759, 53.245534],
            [132.66594, 53.236333],
            [132.711906, 53.23009],
            [132.74952, 53.231647],
            [132.75054, 53.241075],
            [132.770547, 53.249724],
            [132.793237, 53.25055],
            [132.808388, 53.237989],
            [132.861201, 53.235276],
            [132.871653, 53.247788],
            [132.898012, 53.252937],
            [132.900522, 53.268502],
            [132.87905, 53.285625],
            [132.896266, 53.300235],
            [132.953823, 53.297565],
            [132.967227, 53.283613],
            [133.002908, 53.296506],
            [133.022199, 53.293595],
            [133.027988, 53.306549],
            [133.058669, 53.301164],
            [133.08556, 53.308158],
            [133.100051, 53.299746],
            [133.139159, 53.307675],
            [133.144167, 53.332671],
            [133.121995, 53.338795],
            [133.143899, 53.357552],
            [133.162718, 53.360824],
            [133.153467, 53.377153],
            [133.159513, 53.405444],
            [133.193738, 53.424357],
            [133.198365, 53.43187],
            [133.246626, 53.440217],
            [133.26115, 53.457848],
            [133.27748, 53.455657],
            [133.296106, 53.465341],
            [133.36111, 53.472979],
            [133.373872, 53.487448],
            [133.39362, 53.48934],
            [133.42068, 53.518214],
            [133.445296, 53.513967],
            [133.467075, 53.500835],
            [133.491894, 53.507104],
            [133.51444, 53.521964],
            [133.531686, 53.522619],
            [133.536722, 53.534211],
            [133.557104, 53.547241],
            [133.59901, 53.551287],
            [133.628511, 53.535923],
            [133.644344, 53.51906],
            [133.668403, 53.509015],
            [133.683571, 53.484555],
            [133.69985, 53.481118],
            [133.706805, 53.467333],
            [133.761248, 53.479634],
            [133.77668, 53.491754],
            [133.794385, 53.491222],
            [133.796591, 53.471016],
            [133.817318, 53.462919],
            [133.842905, 53.475631],
            [133.868107, 53.474426],
            [133.892631, 53.459554],
            [133.906421, 53.458927],
            [133.94648, 53.438939],
            [133.989876, 53.447725],
            [134.000946, 53.454522],
            [134.018992, 53.447169],
            [134.04631, 53.447146],
            [134.047457, 53.4568],
            [134.06947, 53.46158],
            [134.096616, 53.455898],
            [134.112319, 53.48856],
            [134.110475, 53.498104],
            [134.137971, 53.496803],
            [134.150833, 53.485176],
            [134.160244, 53.491972],
            [134.195285, 53.482235],
            [134.214065, 53.505107],
            [134.205977, 53.521355],
            [134.233432, 53.519617],
            [134.259308, 53.542171],
            [134.28513, 53.548054],
            [134.302176, 53.534141],
            [134.324308, 53.544049],
            [134.319684, 53.55259],
            [134.360207, 53.55917],
            [134.408431, 53.581282],
            [134.430421, 53.557005],
            [134.445506, 53.55846],
            [134.455941, 53.579655],
            [134.445909, 53.587972],
            [134.456307, 53.608036],
            [134.514668, 53.611175],
            [134.531237, 53.621482],
            [134.565446, 53.619653],
            [134.570762, 53.609105],
            [134.595256, 53.596765],
            [134.623293, 53.590088],
            [134.625657, 53.562766],
            [134.648819, 53.559468],
            [134.66539, 53.544332],
            [134.704406, 53.541578],
            [134.72309, 53.528952],
            [134.729792, 53.491356],
            [134.74069, 53.48708],
            [134.80415, 53.490435],
            [134.822358, 53.479781],
            [134.801164, 53.456937],
            [134.836357, 53.453133],
            [134.850818, 53.441526],
            [134.890271, 53.435472],
            [134.889364, 53.423904],
            [134.911818, 53.412608],
            [134.915095, 53.401786],
            [134.87697, 53.384798],
            [134.85908, 53.391516],
            [134.862144, 53.401488],
            [134.838494, 53.396875],
            [134.794439, 53.398195],
            [134.78237, 53.382705],
            [134.79414, 53.359445],
            [134.783393, 53.338948],
            [134.799868, 53.330286],
            [134.847573, 53.324912],
            [134.878661, 53.308016],
            [134.85899, 53.292194],
            [134.86819, 53.273816],
            [134.920913, 53.26072],
            [134.908935, 53.252384],
            [134.907069, 53.230265],
            [134.8741, 53.200518],
            [134.850161, 53.208905],
            [134.8249, 53.193831],
            [134.847603, 53.173481],
            [134.813961, 53.157805],
            [134.809652, 53.148667],
            [134.86279, 53.099726],
            [134.833215, 53.080819],
            [134.833555, 53.070203],
            [134.77177, 53.070249],
            [134.762687, 53.053363],
            [134.748694, 53.049219],
            [134.731573, 53.031946],
            [134.73499, 53.011596],
            [134.7112, 53.007921],
            [134.694515, 52.97697],
            [134.661177, 52.959693],
            [134.659969, 52.946711],
            [134.611179, 52.917118],
            [134.5946, 52.900874],
            [134.605953, 52.894264],
            [134.640802, 52.890465],
            [134.631408, 52.868942],
            [134.593882, 52.857205],
            [134.60967, 52.827164],
            [134.601656, 52.807717],
            [134.611919, 52.801217],
            [134.637034, 52.802464],
            [134.637874, 52.788491],
            [134.664882, 52.783211],
            [134.663443, 52.761607],
            [134.638526, 52.747317],
            [134.653166, 52.716489],
            [134.687954, 52.711069],
            [134.710397, 52.71999],
            [134.74054, 52.711325],
            [134.733308, 52.6981],
            [134.74872, 52.692349],
            [134.752147, 52.679678],
            [134.742256, 52.653649],
            [134.751015, 52.643576],
            [134.741746, 52.625926],
            [134.701996, 52.621243],
            [134.697576, 52.629375],
            [134.67279, 52.627651],
            [134.654692, 52.593627],
            [134.656032, 52.58356],
            [134.62184, 52.573821],
            [134.621069, 52.551054],
            [134.597839, 52.53894],
            [134.613257, 52.530467],
            [134.59916, 52.501069],
            [134.635746, 52.49879],
            [134.633543, 52.487702],
            [134.643588, 52.45882],
            [134.622947, 52.449089],
            [134.623599, 52.438936],
            [134.6024, 52.436194],
            [134.603193, 52.427106],
            [134.522152, 52.424738],
            [134.504718, 52.43252],
            [134.500889, 52.448655],
            [134.485739, 52.460792],
            [134.43832, 52.46668],
            [134.399058, 52.466102],
            [134.385555, 52.472851],
            [134.34411, 52.471997],
            [134.336086, 52.458847],
            [134.30573, 52.457656],
            [134.284073, 52.472083],
            [134.26529, 52.47072],
            [134.228935, 52.495376],
            [134.167189, 52.513573],
            [134.144583, 52.495511],
            [134.125336, 52.494384],
            [134.09752, 52.4816],
            [134.080272, 52.501095],
            [134.053796, 52.498184],
            [134.040591, 52.515714],
            [134.009128, 52.529018],
            [133.99851, 52.540358],
            [133.952294, 52.529627],
            [133.941008, 52.5126],
            [133.901827, 52.519947],
            [133.869705, 52.514491],
            [133.85485, 52.543435],
            [133.819143, 52.566742],
            [133.786517, 52.555093],
            [133.765215, 52.568453],
            [133.736235, 52.56694],
            [133.708484, 52.558646],
            [133.671725, 52.584865],
            [133.645455, 52.581317],
            [133.588637, 52.614426],
            [133.512813, 52.619666],
            [133.501103, 52.641776],
            [133.471832, 52.64606],
            [133.457519, 52.640519],
            [133.425492, 52.652516],
            [133.411119, 52.64758],
            [133.379752, 52.667598],
            [133.363152, 52.66289],
            [133.353601, 52.67244],
            [133.31719, 52.681072],
            [133.28809, 52.676429],
            [133.278986, 52.684361],
            [133.26003, 52.681092],
            [133.23657, 52.639062],
            [133.229284, 52.616671],
            [133.251439, 52.583722],
            [133.245318, 52.557869],
            [133.254747, 52.550917],
            [133.284918, 52.547014],
            [133.300754, 52.529183],
            [133.29596, 52.509441],
            [133.277035, 52.500626],
            [133.287217, 52.490071],
            [133.23898, 52.467639],
            [133.233804, 52.447453],
            [133.260191, 52.433429],
            [133.26228, 52.424155],
            [133.290196, 52.423524],
            [133.313884, 52.400007],
            [133.306984, 52.361111],
            [133.326259, 52.34619],
            [133.337664, 52.32188],
            [133.367865, 52.307077],
            [133.400865, 52.300137],
            [133.402246, 52.282767],
            [133.431421, 52.258165],
            [133.42045, 52.233701],
            [133.391638, 52.225614],
            [133.377463, 52.233223],
            [133.34746, 52.21285],
            [133.326695, 52.208556],
            [133.32591, 52.193529],
            [133.286498, 52.184496],
            [133.232201, 52.176521],
            [133.207603, 52.189721],
            [133.17135, 52.162446],
            [133.147698, 52.168221],
            [133.14464, 52.17904],
            [133.0927, 52.153126],
            [133.05783, 52.175041],
            [133.01188, 52.15918],
            [132.992416, 52.169779],
            [132.95915, 52.165725],
            [132.950453, 52.149475],
            [132.913665, 52.152429],
            [132.90419, 52.15911],
            [132.863415, 52.167536],
            [132.854423, 52.18104],
            [132.81813, 52.183412],
            [132.808303, 52.177125],
            [132.818207, 52.162822],
            [132.772167, 52.134435],
            [132.759327, 52.122053],
            [132.736646, 52.116705],
            [132.702485, 52.12784],
            [132.682486, 52.113949],
            [132.645882, 52.114255],
            [132.621792, 52.094888],
            [132.639298, 52.084471],
            [132.62138, 52.079073],
            [132.572847, 52.080795],
            [132.56446, 52.058023],
            [132.540104, 52.050249],
            [132.535806, 52.033719],
            [132.521422, 52.01642],
            [132.528301, 52.01193],
            [132.525685, 51.98984],
            [132.53395, 51.984595],
            [132.525827, 51.962229],
            [132.503228, 51.954039],
            [132.482025, 51.976147],
            [132.443897, 51.983993],
            [132.412036, 51.985695],
            [132.38938, 51.971535],
            [132.417808, 51.947628],
            [132.42751, 51.914503],
            [132.419028, 51.905468],
            [132.442925, 51.895965],
            [132.4506, 51.885684],
            [132.419441, 51.861546],
            [132.385661, 51.846817],
            [132.342698, 51.847809],
            [132.316459, 51.830644],
            [132.29565, 51.807431],
            [132.264218, 51.800098],
            [132.245708, 51.83494],
            [132.218875, 51.839784],
            [132.163514, 51.811837],
            [132.130038, 51.810771],
            [132.116661, 51.789411],
            [132.117926, 51.778549],
            [132.09759, 51.770163],
            [132.08751, 51.78679],
            [132.065705, 51.800509],
            [132.03374, 51.786049],
            [132.001154, 51.779271],
            [131.977779, 51.76601],
            [131.97801, 51.754939],
            [131.95629, 51.745314],
            [131.92357, 51.743218],
            [131.85849, 51.758266],
            [131.836909, 51.736658],
            [131.809449, 51.729585],
            [131.779877, 51.700424],
            [131.751697, 51.696745],
            [131.73953, 51.68876],
            [131.698378, 51.693581],
            [131.680291, 51.682335],
            [131.6221, 51.683887],
            [131.604323, 51.66596],
            [131.544393, 51.6737],
            [131.54037, 51.680509],
            [131.500998, 51.679689],
            [131.46287, 51.688939],
            [131.400911, 51.691948],
            [131.433176, 51.677839],
            [131.440254, 51.668888],
            [131.421571, 51.661185],
            [131.41532, 51.649714],
            [131.391433, 51.650143],
            [131.398703, 51.641131],
            [131.386529, 51.625973],
            [131.39014, 51.594668],
            [131.409658, 51.570057],
            [131.430423, 51.531616],
            [131.423209, 51.526887],
            [131.433077, 51.506278],
            [131.447753, 51.502641],
            [131.467645, 51.486221],
            [131.46825, 51.442722],
            [131.47947, 51.410896],
            [131.495169, 51.391704],
            [131.490443, 51.346711],
            [131.434723, 51.338543],
            [131.408862, 51.345265],
            [131.394161, 51.365734],
            [131.38112, 51.363501],
            [131.36059, 51.389378],
            [131.321644, 51.384386],
            [131.253109, 51.363298],
            [131.255845, 51.353251],
            [131.243608, 51.336116],
            [131.228657, 51.332281],
            [131.220332, 51.319173],
            [131.224404, 51.287567],
            [131.199184, 51.285902],
            [131.176885, 51.257705],
            [131.161662, 51.250492],
            [131.110102, 51.267198],
            [131.080034, 51.260716],
            [131.042859, 51.239123],
            [131.045429, 51.220161],
            [131.036567, 51.20824],
            [131.026889, 51.17331],
            [131.000293, 51.171032],
            [131.001368, 51.149954],
            [130.97155, 51.142668],
            [130.958451, 51.11255],
            [130.96767, 51.098296],
            [130.95761, 51.092635],
            [130.959951, 51.069812],
            [130.945529, 51.050124],
            [130.948992, 51.04269],
            [130.927046, 51.027766],
            [130.883413, 51.031154],
            [130.836887, 51.018808],
            [130.806835, 51.026951],
            [130.795499, 51.024091],
            [130.78464, 51.004151],
            [130.765432, 50.992791],
            [130.767952, 50.972289],
            [130.756983, 50.956274],
            [130.80491, 50.946084],
            [130.80632, 50.934105],
            [130.831865, 50.92282],
            [130.842658, 50.891062],
            [130.882629, 50.893339],
            [130.86778, 50.879538],
            [130.845384, 50.879121],
            [130.831922, 50.86916],
            [130.808334, 50.874228],
            [130.787387, 50.864914],
            [130.775663, 50.828507],
            [130.740586, 50.807203],
            [130.74043, 50.795426],
            [130.717272, 50.791011],
            [130.716806, 50.773171],
            [130.684318, 50.754132],
            [130.672734, 50.742222],
            [130.682264, 50.728224],
            [130.703071, 50.728219],
            [130.704787, 50.698033],
            [130.688779, 50.687855],
            [130.6682, 50.691126],
            [130.646734, 50.679891],
            [130.644752, 50.662002],
            [130.723473, 50.605954],
            [130.743995, 50.609813],
            [130.742225, 50.627686],
            [130.763969, 50.656391],
            [130.791448, 50.660374],
            [130.816808, 50.650581],
            [130.852487, 50.653648],
            [130.869414, 50.665157],
            [130.907408, 50.669539],
            [130.956589, 50.652363],
            [130.984524, 50.637969],
            [130.999334, 50.616596],
            [131.000921, 50.596698],
            [131.02039, 50.59303],
            [131.026452, 50.577456],
            [130.9843, 50.55266],
            [130.971882, 50.528062],
            [130.93973, 50.50659],
            [130.933201, 50.495098],
            [130.893439, 50.489683],
            [130.87875, 50.479153],
            [130.883912, 50.470562],
            [130.875941, 50.450522],
            [130.89911, 50.438069],
            [130.913263, 50.440217],
            [130.931014, 50.418565],
            [130.926832, 50.405011],
            [130.942123, 50.362337],
            [130.949018, 50.376262],
            [130.963698, 50.375777],
            [131.005998, 50.398362],
            [131.022008, 50.415873],
            [131.023652, 50.434764],
            [131.047069, 50.433462],
            [131.072722, 50.419383],
            [131.106012, 50.422533],
            [131.13055, 50.431843],
            [131.141332, 50.396245],
            [131.169975, 50.371261],
            [131.171355, 50.356659],
            [131.195364, 50.359004],
            [131.24035, 50.348448],
            [131.286303, 50.362487],
            [131.30484, 50.37646],
            [131.321137, 50.363463],
            [131.310048, 50.322809],
            [131.331605, 50.303755],
            [131.323935, 50.289332],
            [131.344135, 50.275182],
            [131.36101, 50.276625],
            [131.384124, 50.265384],
            [131.393811, 50.27161],
            [131.421513, 50.257708],
            [131.427671, 50.240087],
            [131.41283, 50.21624],
            [131.443446, 50.200249],
            [131.458305, 50.177325],
            [131.418819, 50.175179],
            [131.38882, 50.158784],
            [131.395645, 50.132344],
            [131.375446, 50.100153],
            [131.382361, 50.088528],
            [131.402469, 50.081925],
            [131.39736, 50.062931],
            [131.42796, 50.063009],
            [131.421724, 50.036483],
            [131.427719, 50.019816],
            [131.424054, 49.991028],
            [131.387403, 49.975901],
            [131.44806, 49.938578],
            [131.479136, 49.938632],
            [131.491197, 49.922248],
            [131.520539, 49.903465],
            [131.50248, 49.869642],
            [131.503541, 49.833144],
            [131.485209, 49.807536],
            [131.465802, 49.803785],
            [131.48612, 49.789427],
            [131.478651, 49.779948],
            [131.503085, 49.774424],
            [131.520415, 49.757803],
            [131.491368, 49.72511],
            [131.470943, 49.724179],
            [131.465893, 49.734997],
            [131.446738, 49.735642],
            [131.416832, 49.721335],
            [131.369935, 49.719508],
            [131.352414, 49.698801],
            [131.363408, 49.670134],
            [131.37786, 49.660619],
            [131.44572, 49.670272],
            [131.44605, 49.656699],
            [131.462144, 49.648681],
            [131.45865, 49.639294],
            [131.493883, 49.62777],
            [131.51035, 49.614858],
            [131.512089, 49.597028],
            [131.524086, 49.57231],
            [131.505567, 49.548375],
            [131.508782, 49.521885],
            [131.518879, 49.512025],
            [131.504095, 49.474858],
            [131.504, 49.458674],
            [131.527884, 49.427865],
            [131.488001, 49.387909],
            [131.490818, 49.380529],
            [131.470993, 49.345072],
            [131.458464, 49.333081],
            [131.454765, 49.313641],
            [131.436865, 49.301345],
            [131.443382, 49.295138],
            [131.419462, 49.255472],
            [131.378577, 49.254987],
            [131.351491, 49.262742],
            [131.3013, 49.235369],
            [131.290033, 49.255051],
            [131.267252, 49.249907],
            [131.243962, 49.251493],
            [131.204721, 49.229696],
            [131.17148, 49.230029],
            [131.15839, 49.242413],
            [131.132601, 49.24081],
            [131.109396, 49.231596],
            [131.114774, 49.223464],
            [131.092862, 49.195122],
            [131.07885, 49.187937],
            [131.068732, 49.167351],
            [131.075801, 49.151931],
            [131.063567, 49.143522],
            [131.060019, 49.114844],
            [131.049271, 49.1048],
            [131.04704, 49.079133],
            [131.032511, 49.073915],
            [131.025405, 49.037265],
            [131.017562, 49.035795],
            [131.022827, 49.000475],
            [131.048417, 48.990374],
            [131.04529, 48.974788],
            [131.032218, 48.969009],
            [130.998949, 48.968817],
            [130.98817, 48.975773],
            [130.933909, 48.979791],
            [130.897397, 48.992001],
            [130.86608, 48.993643],
            [130.831775, 48.987826],
            [130.805197, 48.967953],
            [130.754242, 48.953089],
            [130.747513, 48.946705],
            [130.712334, 48.94039],
            [130.698337, 48.927208],
            [130.657789, 48.91905],
            [130.627705, 48.893534],
            [130.631293, 48.881824],
            [130.580108, 48.868262],
            [130.537365, 48.852226],
            [130.49823, 48.856743],
            [130.484321, 48.867246],
            [130.473163, 48.890501],
            [130.451534, 48.902238],
            [130.42115, 48.902689],
            [130.340297, 48.885648],
            [130.293791, 48.867651],
            [130.241783, 48.859045],
            [130.224189, 48.863933],
            [130.190304, 48.898795],
            [130.11732, 48.942129],
            [130.050528, 48.970801],
            [130.037821, 48.980901],
            [130.019575, 49.007944],
            [129.997745, 49.018696],
            [129.927694, 49.031403],
            [129.91368, 49.041178],
            [129.909122, 49.056817],
            [129.926065, 49.07213],
            [129.922155, 49.092983],
            [129.899022, 49.104712],
            [129.858295, 49.106993],
            [129.848846, 49.113835],
            [129.849172, 49.135013],
            [129.859924, 49.149675],
            [129.852756, 49.169224],
            [129.84233, 49.175741],
            [129.783682, 49.175089],
            [129.76446, 49.185189],
            [129.749471, 49.203109],
            [129.750123, 49.251331],
            [129.744258, 49.26306],
            [129.710373, 49.289777],
            [129.690498, 49.29499],
            [129.637716, 49.287496],
            [129.599921, 49.274464],
            [129.577113, 49.276744],
            [129.559193, 49.288148],
            [129.548116, 49.307697],
            [129.543554, 49.375142],
            [129.536643, 49.398036],
            [129.500546, 49.423363],
            [129.430169, 49.440305],
            [129.407753, 49.439263],
            [129.379863, 49.428054],
            [129.368655, 49.416846],
            [129.36553, 49.387914],
            [129.377778, 49.37436],
            [129.36709, 49.355332],
            [129.35197, 49.350379],
            [129.308797, 49.357032],
            [129.260982, 49.390537],
            [129.225906, 49.398215],
            [129.174427, 49.387221],
            [129.14302, 49.357206],
            [129.126088, 49.349179],
            [129.072689, 49.353716],
            [129.050352, 49.373261],
            [129.039707, 49.406592],
            [129.005853, 49.443762],
            [128.983516, 49.455628],
            [128.928721, 49.459816],
            [128.879161, 49.487563],
            [128.855253, 49.487214],
            [128.793129, 49.470461],
            [128.756831, 49.474475],
            [128.738857, 49.495067],
            [128.747059, 49.511819],
            [128.802029, 49.548989],
            [128.79784, 49.572722],
            [128.752992, 49.592442],
            [128.728212, 49.587555],
            [128.717218, 49.56103],
            [128.688599, 49.568185],
            [128.658235, 49.568709],
            [128.619912, 49.589126],
            [128.589766, 49.590058],
            [128.552785, 49.600663],
            [128.520971, 49.599032],
            [128.501121, 49.591418],
            [128.460333, 49.587883],
            [128.389091, 49.588971],
            [128.366794, 49.582445],
            [128.357277, 49.550359],
            [128.335795, 49.540841],
            [128.307695, 49.548294],
            [128.295501, 49.559601],
            [128.249828, 49.563592],
            [128.234308, 49.560044],
            [128.20127, 49.536765],
            [128.169346, 49.534769],
            [128.129659, 49.546963],
            [128.104162, 49.542973],
            [128.087312, 49.548959],
            [128.034322, 49.55428],
            [128.010599, 49.574899],
            [127.958904, 49.595093],
            [127.923774, 49.577558],
            [127.884838, 49.569493],
            [127.842663, 49.573191],
            [127.798156, 49.592117],
            [127.788381, 49.614578],
            [127.756353, 49.636416],
            [127.70727, 49.65139],
            [127.687097, 49.665741],
            [127.67441, 49.68841],
            [127.669627, 49.71004],
            [127.672746, 49.746644],
            [127.665051, 49.769105],
            [127.648205, 49.775136],
            [127.587892, 49.781584],
            [127.556487, 49.789071],
            [127.525291, 49.817772],
            [127.523211, 49.856663],
            [127.541513, 49.915729],
            [127.541097, 49.932367],
            [127.531114, 49.946301],
            [127.498878, 49.970634],
            [127.489311, 49.989144],
            [127.488063, 50.009526],
            [127.501373, 50.058193],
            [127.521963, 50.085437],
            [127.571878, 50.127449],
            [127.603074, 50.179651],
            [127.599746, 50.199617],
            [127.606194, 50.223118],
            [127.60139, 50.236611],
            [127.565856, 50.241076],
            [127.53585, 50.24968],
            [127.498673, 50.253652],
            [127.455429, 50.267],
            [127.402366, 50.279245],
            [127.38273, 50.28079],
            [127.367396, 50.290608],
            [127.325145, 50.333852],
            [127.326358, 50.356688],
            [127.361218, 50.394637],
            [127.361117, 50.432322],
            [127.353907, 50.441068],
            [127.311507, 50.448283],
            [127.292049, 50.455532],
            [127.28543, 50.467557],
            [127.303853, 50.490834],
            [127.310693, 50.522605],
            [127.353811, 50.540617],
            [127.366857, 50.57268],
            [127.365312, 50.582054],
            [127.340593, 50.614088],
            [127.317419, 50.629335],
            [127.287378, 50.663731],
            [127.280855, 50.706038],
            [127.300939, 50.734186],
            [127.291154, 50.753412],
            [127.250471, 50.762208],
            [127.232961, 50.77263],
            [127.196397, 50.828828],
            [127.134942, 50.911159],
            [127.106103, 50.935613],
            [127.046194, 50.959298],
            [127.028684, 50.972379],
            [127.008085, 50.999718],
            [126.965169, 51.034813],
            [126.943197, 51.048089],
            [126.920881, 51.052082],
            [126.914358, 51.089725],
            [126.910924, 51.138649],
            [126.894102, 51.202043],
            [126.931524, 51.25493],
            [126.947145, 51.265457],
            [126.972722, 51.296487],
            [126.972551, 51.326853],
            [126.945257, 51.332216],
            [126.880712, 51.319666],
            [126.867494, 51.307971],
            [126.876592, 51.294233],
            [126.903028, 51.280277],
            [126.915559, 51.259979],
            [126.899766, 51.243648],
            [126.860628, 51.244293],
            [126.843461, 51.251492],
            [126.811361, 51.287792],
            [126.807584, 51.311941],
            [126.813592, 51.328248],
            [126.841573, 51.343047],
            [126.8929, 51.337793],
            [126.917791, 51.348623],
            [126.923627, 51.361486],
            [126.914529, 51.388917],
            [126.89599, 51.40691],
            [126.832132, 51.411407],
            [126.800889, 51.419436],
            [126.775655, 51.442552],
            [126.791963, 51.459561],
            [126.806211, 51.491956],
            [126.838483, 51.521872],
            [126.83299, 51.533086],
            [126.757631, 51.558813],
            [126.708536, 51.567349],
            [126.68519, 51.577805],
            [126.66906, 51.598758],
            [126.688296, 51.615959],
            [126.721255, 51.624592],
            [126.7331, 51.637698],
            [126.731212, 51.657297],
            [126.712329, 51.681997],
            [126.727435, 51.706683],
            [126.714904, 51.72689],
            [126.670443, 51.727315],
            [126.654136, 51.741774],
            [126.656882, 51.758352],
            [126.617228, 51.7745],
            [126.597879, 51.804311],
            [126.574648, 51.823066],
            [126.556745, 51.859724],
            [126.537351, 51.885086],
            [126.509005, 51.904054],
            [126.491315, 51.92963],
            [126.464674, 51.93645],
            [126.453592, 51.961599],
            [126.458707, 51.986108],
            [126.437607, 52.004437],
            [126.43782, 52.025537],
            [126.474691, 52.040669],
            [126.509218, 52.034488],
            [126.511989, 52.053244],
            [126.535219, 52.076475],
            [126.536285, 52.098853],
            [126.556106, 52.120166],
            [126.544597, 52.13679],
            [126.489397, 52.159807],
            [126.449329, 52.163004],
            [126.411605, 52.173447],
            [126.401375, 52.183038],
            [126.350864, 52.187514],
            [126.299927, 52.206269],
            [126.296326, 52.224601],
            [126.310922, 52.240045],
            [126.345774, 52.262438],
            [126.396422, 52.280255],
            [126.426739, 52.276988],
            [126.424689, 52.297103],
            [126.388943, 52.302997],
            [126.339616, 52.301459],
            [126.31066, 52.328877],
            [126.315016, 52.342843],
            [126.34, 52.355015],
            [126.346406, 52.383202],
            [126.318091, 52.424329],
            [126.286573, 52.443548],
            [126.262101, 52.473657],
            [126.223664, 52.47276],
            [126.189583, 52.465329],
            [126.180743, 52.477629],
            [126.201243, 52.530031],
            [126.154734, 52.562062],
            [126.067354, 52.603317],
            [126.053516, 52.601396],
            [126.045445, 52.580512],
            [126.026482, 52.573977],
            [126.011492, 52.57859],
            [125.972286, 52.608699],
            [125.962805, 52.622536],
            [125.961268, 52.650467],
            [125.982921, 52.673273],
            [126.055485, 52.6766],
            [126.065079, 52.694964],
            [126.036799, 52.734682],
            [126.060641, 52.750964],
            [126.100618, 52.754889],
            [126.109776, 52.76114],
            [126.09553, 52.775387],
            [126.048138, 52.797338],
            [126.027786, 52.798501],
            [126.004527, 52.788906],
            [125.986064, 52.760849],
            [125.967166, 52.756634],
            [125.938818, 52.763321],
            [125.918176, 52.782655],
            [125.919629, 52.799373],
            [125.908726, 52.818708],
            [125.862498, 52.848654],
            [125.844036, 52.867698],
            [125.845925, 52.889649],
            [125.829062, 52.895464],
            [125.758714, 52.89454],
            [125.743923, 52.879589],
            [125.706464, 52.876695],
            [125.672863, 52.859975],
            [125.655017, 52.869139],
            [125.653088, 52.905151],
            [125.665789, 52.922514],
            [125.712252, 52.932643],
            [125.727203, 52.944057],
            [125.723184, 52.969459],
            [125.741512, 52.979266],
            [125.733634, 52.991485],
            [125.694406, 52.998719],
            [125.67222, 53.007401],
            [125.636207, 53.035053],
            [125.628651, 53.064957],
            [125.604696, 53.081677],
            [125.561449, 53.072995],
            [125.518363, 53.048558],
            [125.496337, 53.060777],
            [125.497623, 53.08859],
            [125.480812, 53.099923],
            [125.444157, 53.106193],
            [125.383385, 53.128218],
            [125.302036, 53.145581],
            [125.249946, 53.175967],
            [125.216828, 53.183845],
            [125.204127, 53.192848],
            [125.157561, 53.203446],
            [125.089961, 53.201491],
            [125.082244, 53.203754],
            [124.975221, 53.195437],
            [124.958696, 53.188182],
            [124.880232, 53.165207],
            [124.87768, 53.154996],
            [124.899311, 53.121273],
            [124.893265, 53.100985],
            [124.882113, 53.096014],
            [124.84839, 53.105419],
            [124.834686, 53.137261],
            [124.823669, 53.14277],
            [124.75058, 53.139545],
            [124.711483, 53.1511],
            [124.706377, 53.163326],
            [124.716991, 53.186569],
            [124.673326, 53.201483],
            [124.636647, 53.206857],
            [124.594459, 53.20632],
            [124.550928, 53.201349],
            [124.491678, 53.203633],
            [124.48281, 53.216262],
            [124.450162, 53.217471],
            [124.43404, 53.212231],
            [124.403138, 53.247432],
            [124.369549, 53.252403],
            [124.359876, 53.273363],
            [124.337841, 53.293382],
            [124.320107, 53.328851],
            [124.279075, 53.345336],
            [124.232104, 53.377488],
            [124.164792, 53.366874],
            [124.131875, 53.346989],
            [124.109707, 53.346183],
            [124.070744, 53.368083],
            [124.074833, 53.379996],
            [124.061118, 53.398431],
            [124.007866, 53.397238],
            [123.978039, 53.429483],
            [123.87687, 53.480941],
            [123.854835, 53.487122],
            [123.796257, 53.485644],
            [123.745336, 53.496392],
            [123.708657, 53.490615],
            [123.685279, 53.498004],
            [123.664186, 53.522323],
            [123.621595, 53.545029],
            [123.592171, 53.54879],
            [123.579005, 53.544222],
            [123.570943, 53.515202],
            [123.552805, 53.497333],
            [123.529159, 53.496795],
            [123.526741, 53.511305],
            [123.549984, 53.527965],
            [123.551327, 53.53643],
            [123.533727, 53.552149],
            [123.511003, 53.555642],
            [123.483606, 53.541137],
            [123.479411, 53.527188],
            [123.501961, 53.508728],
            [123.499234, 53.498555],
            [123.481089, 53.495409],
            [123.46284, 53.508833],
            [123.450254, 53.5339],
            [123.375479, 53.535874],
            [123.304435, 53.559072],
            [123.273263, 53.562153],
            [123.223061, 53.548923],
            [123.17902, 53.518294],
            [123.171408, 53.507783],
            [123.137336, 53.497452],
            [123.084416, 53.506152],
            [123.059949, 53.506877],
            [122.932722, 53.480598],
            [122.893031, 53.460843],
            [122.849535, 53.455768],
            [122.747862, 53.462293],
            [122.698652, 53.457356],
            [122.654344, 53.458124],
            [122.596168, 53.463199],
            [122.536089, 53.45204],
            [122.480359, 53.456675],
            [122.454034, 53.442124],
            [122.429487, 53.44243],
            [122.366543, 53.474617],
            [122.359656, 53.490203],
            [122.341714, 53.501621],
            [122.309454, 53.497815],
            [122.298761, 53.484404],
            [122.243665, 53.463018],
            [122.213896, 53.46135],
            [122.162861, 53.471613],
            [122.14961, 53.464932],
            [122.112072, 53.431152],
            [122.086212, 53.446342],
            [122.093885, 53.467087],
            [122.071742, 53.489639],
            [122.039841, 53.509265],
            [122.028903, 53.523953],
            [122.011815, 53.528958],
            [121.974671, 53.55462],
            [121.98972, 53.578812],
            [121.948005, 53.616322],
            [121.966042, 53.633489],
            [121.956789, 53.64423],
            [121.96676, 53.697527],
            [121.96083, 53.726344],
            [121.933805, 53.72526],
            [121.944266, 53.749351],
            [121.9249, 53.77003],
            [121.934481, 53.784872],
            [121.924218, 53.79333],
            [121.926814, 53.80858],
            [121.946601, 53.826274],
            [121.93025, 53.847186],
            [121.909338, 53.853402],
            [121.878671, 53.896021],
            [121.860513, 53.907116],
            [121.863906, 53.922393],
            [121.844121, 53.93611],
            [121.848812, 53.952169],
            [121.815522, 53.965355],
            [121.789058, 53.955524],
            [121.786209, 53.938616],
            [121.774529, 53.935273],
            [121.742516, 53.971301],
            [121.72063, 53.979485],
            [121.711543, 53.997339],
            [121.674279, 54.008821],
            [121.653701, 54.026285],
            [121.667441, 54.046938],
            [121.642505, 54.055463],
            [121.645685, 54.070337],
            [121.626528, 54.096369],
            [121.646564, 54.114342],
            [121.654548, 54.134028],
            [121.672511, 54.138747],
            [121.724243, 54.139997],
            [121.753657, 54.153417],
            [121.756446, 54.165329],
            [121.779499, 54.175246],
            [121.738257, 54.186282],
            [121.734528, 54.212199],
            [121.715283, 54.216169],
            [121.731153, 54.261609],
            [121.748118, 54.279152],
            [121.730633, 54.283649],
            [121.703795, 54.317612],
            [121.714677, 54.325518],
            [121.711361, 54.339042],
            [121.669777, 54.347743],
            [121.655447, 54.358832],
            [121.664952, 54.377032],
            [121.702016, 54.413143],
            [121.73156, 54.417164],
            [121.743598, 54.43389],
            [121.759862, 54.439438],
            [121.802959, 54.439514],
            [121.814429, 54.447627],
            [121.830249, 54.432829],
            [121.890032, 54.417087],
            [121.881064, 54.388049],
            [121.912289, 54.377137],
            [121.940807, 54.37859],
            [121.955482, 54.386974],
            [121.985463, 54.39032],
            [122.025062, 54.407186],
            [122.036786, 54.405446],
            [122.088899, 54.415101],
            [122.107077, 54.40701],
            [122.128962, 54.413104],
            [122.133362, 54.440893],
            [122.14582, 54.458755],
            [122.131326, 54.483302],
            [122.12279, 54.480451],
            [122.030425, 54.522315],
            [122.023807, 54.544197],
            [122.003935, 54.560795],
            [121.949905, 54.571296],
            [121.929866, 54.569081],
            [121.93917, 54.629767],
            [121.927587, 54.651011],
            [121.889118, 54.654598],
            [121.892318, 54.673073],
            [121.915204, 54.681429],
            [121.937792, 54.713422],
            [121.939016, 54.740334],
            [121.903501, 54.752016],
            [121.90229, 54.791286],
            [121.912039, 54.802854],
            [121.895753, 54.812144],
            [121.879915, 54.803022],
            [121.810872, 54.786443],
            [121.794049, 54.772232],
            [121.723541, 54.765244],
            [121.723734, 54.751906],
            [121.710866, 54.735923],
            [121.682467, 54.727801],
            [121.664314, 54.729098],
            [121.668327, 54.741619],
            [121.640328, 54.764774],
            [121.652327, 54.773358],
            [121.67122, 54.819287],
            [121.667207, 54.834498],
            [121.702186, 54.848023],
            [121.74898, 54.871431],
            [121.755664, 54.879976],
            [121.791475, 54.89642],
            [121.786027, 54.902705],
            [121.832993, 54.933456],
            [121.846259, 54.938045],
            [121.852999, 54.963903],
            [121.908112, 54.969661],
            [121.959649, 55.000532],
            [121.947071, 55.027828],
            [121.949548, 55.042845],
            [121.938924, 55.052503],
            [121.950968, 55.07695],
            [121.95025, 55.091264],
            [121.913876, 55.119523],
            [121.922884, 55.133621],
            [121.970804, 55.156371],
            [121.964915, 55.163337],
            [121.980011, 55.176826],
            [121.996327, 55.172585],
            [122.03258, 55.196149],
            [122.018066, 55.20895],
            [121.991717, 55.203843],
            [121.958106, 55.218073],
            [121.943818, 55.232939],
            [121.957454, 55.247085],
            [121.953389, 55.269629],
            [121.940285, 55.279089],
            [121.954981, 55.295752],
            [121.96983, 55.296873],
            [121.985257, 55.312551],
            [121.986133, 55.337833],
            [121.960947, 55.374333],
            [121.968201, 55.39713],
            [121.979945, 55.401532],
            [121.984841, 55.420437],
            [121.999728, 55.433874],
            [121.981481, 55.446881],
            [121.988787, 55.454142],
            [121.993862, 55.490895],
            [121.991114, 55.507256],
            [121.946427, 55.515501],
            [121.946245, 55.538243],
            [121.936156, 55.551507],
            [121.913328, 55.563734],
            [121.903089, 55.581821],
            [121.906474, 55.600492],
            [121.899496, 55.619519],
            [121.863159, 55.618231],
            [121.848865, 55.610644],
            [121.812593, 55.623503],
            [121.790061, 55.618138],
            [121.780001, 55.632219],
            [121.75497, 55.627064],
            [121.748276, 55.614351],
            [121.696744, 55.614257],
            [121.662841, 55.592643],
            [121.668459, 55.584216],
            [121.644509, 55.563824],
            [121.644725, 55.553224],
            [121.620438, 55.540398],
            [121.607495, 55.525772],
            [121.581252, 55.527413],
            [121.562323, 55.511638],
            [121.548644, 55.5203],
            [121.514825, 55.514413],
            [121.484099, 55.522684],
            [121.470029, 55.511478],
            [121.474527, 55.491083],
            [121.447807, 55.498544],
            [121.419257, 55.494462],
            [121.378048, 55.524445],
            [121.342017, 55.522041],
            [121.336304, 55.530157],
            [121.347945, 55.559552],
            [121.345853, 55.575751],
            [121.36111, 55.607218],
            [121.332857, 55.634995],
            [121.317121, 55.65923],
            [121.325972, 55.672025],
            [121.313119, 55.731141],
            [121.298557, 55.734866],
            [121.315196, 55.779498],
            [121.343985, 55.793416],
            [121.3371, 55.821683],
            [121.289732, 55.84066],
            [121.286201, 55.860417],
            [121.246779, 55.88011],
            [121.269105, 55.940675],
            [121.25802, 55.943022],
            [121.249934, 55.98816],
            [121.236787, 55.990219],
            [121.212657, 56.027873],
            [121.21542, 56.036643],
            [121.193811, 56.041188],
            [121.157265, 56.032433],
            [121.119983, 56.012626],
            [121.063653, 56.016223],
            [121.045408, 56.009329],
            [121.003895, 56.027817],
            [120.993658, 56.041873],
            [120.964935, 56.03019],
            [120.948967, 56.016367],
            [120.914073, 56.032531],
            [120.88448, 56.029839],
            [120.877713, 56.018513],
            [120.855829, 56.021638],
            [120.785378, 55.987659],
            [120.778611, 55.977042],
            [120.690841, 55.964359],
            [120.657889, 55.966889],
            [120.63825, 55.952007],
            [120.633166, 55.930567],
            [120.601328, 55.933406],
            [120.591404, 55.940655],
            [120.568958, 55.938572],
            [120.561842, 55.928013],
            [120.512161, 55.933902],
            [120.477635, 55.930509],
            [120.467959, 55.914665],
            [120.477545, 55.886741],
            [120.419341, 55.880425],
            [120.404212, 55.873615],
            [120.340293, 55.882653],
            [120.35556, 55.903652],
            [120.349865, 55.918489],
            [120.306369, 55.931697],
            [120.250885, 55.93599],
            [120.224446, 55.931387],
            [120.227646, 55.922583],
            [120.201619, 55.900194],
            [120.163201, 55.883196],
            [120.159902, 55.875223],
            [120.120565, 55.865797],
            [120.127393, 55.879918],
            [120.113819, 55.897977],
            [120.127948, 55.897785],
            [120.118331, 55.915027],
            [120.095665, 55.917917],
            [120.083189, 55.935283],
            [120.12401, 55.957488],
            [120.134792, 55.992258],
            [120.11747, 56.003437],
            [120.12743, 56.024235],
            [120.092212, 56.056521],
            [120.082593, 56.071806],
            [120.032566, 56.108902],
            [120.027939, 56.120432],
            [120.041536, 56.138948],
            [120.039768, 56.157008],
            [120.070227, 56.163509],
            [120.115634, 56.161004],
            [120.152404, 56.17797],
            [120.173616, 56.206326],
            [120.225578, 56.2051],
            [120.234672, 56.214257],
            [120.26018, 56.203002],
            [120.303761, 56.202379],
            [120.299372, 56.22331],
            [120.327207, 56.225478],
            [120.378755, 56.23746],
            [120.425607, 56.228796],
            [120.467277, 56.242145],
            [120.478799, 56.237301],
            [120.508655, 56.248471],
            [120.506501, 56.263459],
            [120.539248, 56.266273],
            [120.535543, 56.287492],
            [120.516146, 56.29982],
            [120.500674, 56.292052],
            [120.466301, 56.295101],
            [120.409628, 56.314398],
            [120.397519, 56.314196],
            [120.365069, 56.332091],
            [120.365324, 56.342182],
            [120.393342, 56.359852],
            [120.373551, 56.369849],
            [120.342302, 56.371529],
            [120.323642, 56.366441],
            [120.281693, 56.393189],
            [120.258686, 56.402479],
            [120.235379, 56.403327],
            [120.240954, 56.428514],
            [120.262475, 56.430447],
            [120.254406, 56.447785],
            [120.279717, 56.458654],
            [120.254354, 56.47728],
            [120.230034, 56.480651],
            [120.223919, 56.461782],
            [120.159418, 56.472718],
            [120.1371, 56.487377],
            [120.119705, 56.481037],
            [120.089185, 56.500828],
            [120.04819, 56.497739],
            [120.026334, 56.489236],
            [119.949473, 56.492727],
            [119.938404, 56.512195],
            [119.914051, 56.525552],
            [119.923613, 56.54911],
            [119.833165, 56.56601],
            [119.836613, 56.577526],
            [119.796982, 56.596617],
            [119.807481, 56.634966],
            [119.829434, 56.654386],
            [119.860553, 56.660511],
            [119.88538, 56.67795],
            [119.865197, 56.698037],
            [119.780835, 56.695609],
            [119.772849, 56.711899],
            [119.776596, 56.733397],
            [119.762595, 56.738643],
            [119.725466, 56.729354],
            [119.677939, 56.739303],
            [119.68163, 56.747071],
            [119.655391, 56.757026],
            [119.655777, 56.776279],
            [119.696485, 56.800315],
            [119.706492, 56.829407],
            [119.768748, 56.841299],
            [119.812775, 56.854107],
            [119.837968, 56.877258],
            [119.832444, 56.890344],
            [119.85614, 56.896079],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5518",
      properties: {
        name: "Архангельская область",
        density: 0,
        path: "/world/Russia/Архангельская область",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [37.90677, 63.99368],
              [37.89757, 63.99117],
              [37.86085, 64.01164],
              [37.90677, 63.99368],
            ],
          ],
          [
            [
              [36.78719, 64.04337],
              [36.79209, 64.03429],
              [36.76013, 64.01386],
              [36.76929, 64.03847],
              [36.76403, 64.06013],
              [36.78719, 64.04337],
            ],
          ],
          [
            [
              [35.63596, 64.96554],
              [35.69733, 64.98416],
              [35.69343, 64.96938],
              [35.63867, 64.95321],
              [35.63596, 64.96554],
            ],
          ],
          [
            [
              [42.67422, 66.68276],
              [42.66041, 66.66158],
              [42.59456, 66.67542],
              [42.49166, 66.70989],
              [42.44038, 66.73695],
              [42.42968, 66.74957],
              [42.44888, 66.76728],
              [42.48434, 66.77539],
              [42.52459, 66.77462],
              [42.61254, 66.75653],
              [42.65046, 66.7392],
              [42.67422, 66.68276],
            ],
          ],
          [
            [
              [44.249558, 66.436164],
              [44.520753, 66.361621],
              [44.589523, 66.297577],
              [44.854938, 66.230571],
              [44.989002, 66.225047],
              [45.179847, 66.194308],
              [45.267541, 66.178248],
              [45.515382, 66.115301],
              [45.70329, 66.083267],
              [45.72583, 66.069019],
              [45.848494, 66.085428],
              [46.134673, 66.058836],
              [46.139164, 66.0521],
              [46.307209, 66.037941],
              [46.347105, 66.038287],
              [46.426725, 66.017737],
              [46.558159, 65.978016],
              [46.686639, 65.953591],
              [46.770449, 65.935343],
              [47.144758, 65.821305],
              [47.269648, 65.831014],
              [47.471346, 65.843935],
              [47.738354, 65.820969],
              [47.797418, 65.837895],
              [47.843529, 65.855339],
              [47.749226, 65.906458],
              [47.824563, 65.981587],
              [47.849174, 65.982969],
              [47.911042, 66.032277],
              [48.029132, 66.013281],
              [48.063755, 66.067943],
              [48.072645, 66.113106],
              [48.084647, 66.129254],
              [48.30187, 66.127185],
              [48.405668, 66.122351],
              [48.721637, 66.118556],
              [48.999789, 66.113264],
              [48.998237, 66.023014],
              [49.018291, 65.810241],
              [49.036279, 65.511115],
              [49.0466, 65.435568],
              [49.03605, 65.43562],
              [49.048729, 65.331847],
              [49.055647, 65.22477],
              [49.120065, 65.23107],
              [49.636629, 65.289107],
              [49.762888, 65.176504],
              [49.918852, 65.066148],
              [49.995711, 65.013474],
              [50.245634, 64.82851],
              [50.368085, 64.815904],
              [50.498827, 64.791727],
              [50.443223, 64.690694],
              [50.393837, 64.579471],
              [50.360683, 64.510388],
              [49.912503, 64.557875],
              [49.905423, 64.547513],
              [49.552407, 64.575831],
              [49.483163, 64.425058],
              [48.961064, 64.477553],
              [48.885516, 64.342669],
              [48.88379, 64.326694],
              [48.52447, 64.355617],
              [48.464726, 64.204326],
              [48.196509, 64.2266],
              [48.165764, 64.276167],
              [48.156606, 64.331605],
              [48.16144, 64.345249],
              [47.960064, 64.344037],
              [47.804454, 64.345934],
              [47.344534, 64.35439],
              [47.234175, 64.348171],
              [46.96268, 64.337804],
              [46.795155, 64.32623],
              [46.630221, 64.316901],
              [46.399144, 64.260423],
              [46.392926, 64.266813],
              [46.350094, 64.263531],
              [46.321081, 64.247987],
              [46.036113, 64.268017],
              [45.943714, 64.271642],
              [45.852006, 64.278031],
              [45.714043, 64.257442],
              [45.523005, 64.20877],
              [45.39935, 64.174918],
              [45.552376, 64.080624],
              [45.594518, 64.056964],
              [45.840631, 63.989094],
              [45.943394, 63.956627],
              [46.151162, 63.94385],
              [46.199538, 63.925025],
              [46.33458, 63.904476],
              [46.408327, 63.889107],
              [46.499691, 63.864584],
              [46.809881, 63.756648],
              [46.881386, 63.703283],
              [46.999526, 63.599661],
              [46.991237, 63.588608],
              [47.017316, 63.577728],
              [47.011967, 63.517972],
              [46.939262, 63.453551],
              [46.945829, 63.403812],
              [46.942724, 63.34941],
              [47.032018, 63.292419],
              [47.051019, 63.253906],
              [47.16017, 63.245445],
              [47.230499, 63.222194],
              [47.39005, 63.156332],
              [47.570357, 63.144764],
              [47.559824, 63.113331],
              [47.54238, 63.115058],
              [47.530814, 63.049948],
              [47.470369, 63.010397],
              [47.409062, 62.964802],
              [47.442739, 62.964975],
              [47.422879, 62.955131],
              [47.288516, 62.911607],
              [47.060895, 62.826287],
              [47.085075, 62.809017],
              [47.230505, 62.65013],
              [47.245359, 62.638732],
              [47.241225, 62.488132],
              [47.231909, 62.337187],
              [47.402889, 62.334427],
              [47.389083, 62.181409],
              [47.642617, 62.175714],
              [47.659359, 62.328386],
              [47.883361, 62.321654],
              [48.151639, 62.315083],
              [48.280069, 62.310952],
              [48.322901, 62.307153],
              [48.320653, 62.337549],
              [48.298019, 62.476922],
              [48.255704, 62.506109],
              [48.287304, 62.583654],
              [48.352231, 62.733736],
              [48.681413, 62.702136],
              [48.741332, 62.8517],
              [49.324394, 62.799206],
              [49.640796, 62.76916],
              [49.575351, 62.619423],
              [49.566888, 62.620459],
              [49.537706, 62.539805],
              [49.452922, 62.321332],
              [49.374007, 62.132736],
              [49.28843, 62.139298],
              [49.280056, 62.117364],
              [49.300004, 62.106915],
              [49.284115, 62.098107],
              [49.227262, 62.1022],
              [49.179636, 61.97324],
              [49.111427, 61.82143],
              [49.049952, 61.679811],
              [49.043737, 61.649069],
              [49.215236, 61.632147],
              [49.502104, 61.605036],
              [49.441751, 61.456347],
              [49.392279, 61.342522],
              [49.347037, 61.2237],
              [49.321827, 61.152372],
              [49.236595, 61.15997],
              [49.126753, 61.17223],
              [49.092817, 61.160572],
              [48.957936, 61.104959],
              [48.92158, 61.136045],
              [48.830564, 61.114024],
              [48.686184, 61.076027],
              [48.474967, 61.022139],
              [48.448547, 60.95772],
              [48.426618, 60.880865],
              [48.419677, 60.837206],
              [48.388444, 60.839043],
              [48.02043, 60.87544],
              [47.96653, 60.879171],
              [47.961305, 60.895506],
              [47.965449, 60.932324],
              [47.959312, 60.944431],
              [47.955992, 60.988545],
              [47.94111, 61.002131],
              [47.913783, 61.000154],
              [47.897198, 61.016821],
              [47.836208, 61.007971],
              [47.794373, 61.025194],
              [47.742733, 61.025553],
              [47.699332, 61.034983],
              [47.655019, 61.033604],
              [47.645452, 61.0507],
              [47.603077, 61.060586],
              [47.486065, 61.046667],
              [47.482583, 61.035472],
              [47.463344, 61.042686],
              [47.19318, 61.067393],
              [47.182237, 61.028336],
              [47.19381, 61.022445],
              [47.193951, 60.996978],
              [47.146822, 60.982131],
              [47.122825, 60.960441],
              [47.102304, 60.961749],
              [47.108785, 60.939032],
              [47.072164, 60.844481],
              [46.924817, 60.856179],
              [46.933693, 60.870991],
              [46.89963, 60.881966],
              [46.820444, 60.881563],
              [46.793613, 60.922724],
              [46.823677, 60.963126],
              [46.67947, 60.970896],
              [46.542857, 60.982266],
              [46.552399, 61.023029],
              [46.52476, 61.043915],
              [46.484347, 61.040503],
              [46.474058, 61.008627],
              [46.412762, 61.012089],
              [46.365158, 60.995728],
              [46.312821, 60.995849],
              [46.299524, 60.968742],
              [46.225388, 60.975721],
              [45.90686, 60.999126],
              [45.759413, 61.011219],
              [45.788077, 61.104998],
              [45.809008, 61.161534],
              [45.533179, 61.180497],
              [45.338405, 61.195118],
              [45.326418, 61.158704],
              [45.36165, 61.15525],
              [45.349736, 61.118291],
              [45.314676, 61.120363],
              [45.251136, 60.89404],
              [44.92503, 60.910785],
              [44.691211, 60.926362],
              [44.454296, 60.943154],
              [44.159802, 60.965034],
              [44.180954, 61.061493],
              [44.09451, 61.052989],
              [44.024737, 61.053765],
              [43.978624, 61.057391],
              [43.940974, 61.054973],
              [43.93847, 61.044179],
              [43.898834, 61.045473],
              [43.896762, 61.036061],
              [43.868351, 61.045559],
              [43.782949, 61.037182],
              [43.75325, 60.940294],
              [43.767757, 60.934767],
              [43.74047, 60.928549],
              [43.735118, 60.90817],
              [43.766378, 60.896254],
              [43.734947, 60.881055],
              [43.705759, 60.893144],
              [43.681752, 60.895044],
              [43.657748, 60.870864],
              [43.597646, 60.866201],
              [43.544626, 60.85791],
              [43.523734, 60.766376],
              [43.250684, 60.79245],
              [43.20483, 60.806093],
              [43.178666, 60.795557],
              [43.153104, 60.811964],
              [43.135143, 60.809201],
              [43.118737, 60.789339],
              [43.100776, 60.78623],
              [43.077678, 60.744464],
              [42.994978, 60.729891],
              [42.984028, 60.760754],
              [42.950003, 60.783896],
              [42.911922, 60.782082],
              [42.919692, 60.813687],
              [42.883336, 60.821977],
              [42.887567, 60.832253],
              [42.828845, 60.854531],
              [42.802602, 60.85716],
              [42.80009, 60.845636],
              [42.658296, 60.887947],
              [42.609163, 60.864199],
              [42.585158, 60.837516],
              [42.570133, 60.838206],
              [42.566334, 60.819468],
              [42.540171, 60.80021],
              [42.536545, 60.787344],
              [42.556579, 60.781213],
              [42.545611, 60.799606],
              [42.563918, 60.79952],
              [42.570482, 60.773183],
              [42.523075, 60.766964],
              [42.519447, 60.786653],
              [42.534299, 60.79857],
              [42.508652, 60.797188],
              [42.497643, 60.784191],
              [42.46077, 60.786825],
              [42.429209, 60.768388],
              [42.375325, 60.767783],
              [42.355463, 60.773741],
              [42.224724, 60.790318],
              [42.208144, 60.784446],
              [42.173689, 60.786259],
              [42.153136, 60.801025],
              [42.166002, 60.811301],
              [42.128811, 60.812703],
              [42.126623, 60.844201],
              [42.111598, 60.835738],
              [42.063499, 60.839105],
              [41.983882, 60.840054],
              [41.974728, 60.845925],
              [41.926889, 60.84368],
              [41.93155, 60.86829],
              [41.956678, 60.870018],
              [41.94381, 60.905249],
              [41.952876, 60.914835],
              [41.929302, 60.916043],
              [41.910305, 60.898168],
              [41.885175, 60.909998],
              [41.824642, 60.916387],
              [41.793383, 60.902311],
              [41.796233, 60.893158],
              [41.768515, 60.873382],
              [41.725166, 60.870187],
              [41.728535, 60.858356],
              [41.696843, 60.857579],
              [41.651594, 60.8663],
              [41.651161, 60.884434],
              [41.567656, 60.898853],
              [41.509109, 60.895225],
              [41.426039, 60.870268],
              [41.377683, 60.85032],
              [41.383383, 60.838922],
              [41.424661, 60.818025],
              [41.361197, 60.732621],
              [41.342462, 60.687545],
              [41.35982, 60.673987],
              [41.369925, 60.641519],
              [41.349546, 60.640742],
              [41.309994, 60.668287],
              [41.270359, 60.665696],
              [41.261206, 60.655247],
              [41.198859, 60.653347],
              [41.197219, 60.643848],
              [41.140831, 60.635816],
              [41.093509, 60.640306],
              [41.076152, 60.6473],
              [41.085131, 60.666039],
              [40.895154, 60.684256],
              [40.903529, 60.703858],
              [40.844895, 60.709038],
              [40.822357, 60.707052],
              [40.785138, 60.715341],
              [40.793254, 60.734339],
              [40.754481, 60.738224],
              [40.771145, 60.775442],
              [40.72106, 60.780623],
              [40.650253, 60.739345],
              [40.656039, 60.728983],
              [40.574868, 60.719656],
              [40.47815, 60.759981],
              [40.453539, 60.777337],
              [40.428928, 60.783295],
              [40.407167, 60.777336],
              [40.407773, 60.759634],
              [40.372628, 60.749789],
              [40.353891, 60.726042],
              [40.338002, 60.720429],
              [40.333945, 60.6991],
              [40.310803, 60.694782],
              [40.28248, 60.67967],
              [40.251393, 60.679324],
              [40.19319, 60.694521],
              [40.185938, 60.671378],
              [40.048809, 60.674312],
              [40.023767, 60.670167],
              [40.01047, 60.646765],
              [39.885604, 60.65393],
              [39.879644, 60.667487],
              [39.837848, 60.693911],
              [39.816648, 60.696587],
              [39.81315, 60.712433],
              [39.758875, 60.741187],
              [39.731718, 60.728061],
              [39.687938, 60.718519],
              [39.657584, 60.723181],
              [39.645104, 60.750209],
              [39.589063, 60.72141],
              [39.572829, 60.723612],
              [39.52214, 60.715796],
              [39.488809, 60.699863],
              [39.43965, 60.698963],
              [39.418694, 60.69161],
              [39.38433, 60.697441],
              [39.375122, 60.687016],
              [39.334071, 60.692129],
              [39.321713, 60.709877],
              [39.276301, 60.720585],
              [39.258726, 60.71038],
              [39.234775, 60.718864],
              [39.19359, 60.722709],
              [39.188722, 60.711376],
              [39.159233, 60.695326],
              [39.18025, 60.679521],
              [39.186348, 60.643816],
              [39.161949, 60.655133],
              [39.143225, 60.679941],
              [39.015268, 60.7204],
              [38.975319, 60.756728],
              [38.927, 60.76725],
              [38.906964, 60.759514],
              [38.89625, 60.7715],
              [38.876024, 60.767166],
              [38.8125, 60.73325],
              [38.807283, 60.708491],
              [38.619959, 60.716362],
              [38.616201, 60.745476],
              [38.589033, 60.761667],
              [38.598313, 60.774146],
              [38.578045, 60.779987],
              [38.586728, 60.797676],
              [38.404946, 60.806727],
              [38.412024, 60.820265],
              [38.400537, 60.846947],
              [38.40943, 60.876998],
              [38.388273, 60.884511],
              [38.392299, 60.892972],
              [38.367515, 60.901002],
              [38.367169, 60.914128],
              [38.325893, 60.904801],
              [38.308276, 60.9162],
              [38.252837, 60.914644],
              [38.21795, 60.931051],
              [38.181332, 60.985107],
              [38.217256, 60.975781],
              [38.220363, 60.990807],
              [38.189275, 61.003932],
              [38.18841, 61.025865],
              [38.206025, 61.038128],
              [38.276315, 61.058335],
              [38.269752, 61.068007],
              [38.211378, 61.063861],
              [38.176661, 61.09892],
              [38.19894, 61.109455],
              [38.177177, 61.131388],
              [38.123649, 61.160507],
              [38.061167, 61.140756],
              [38.007959, 61.141705],
              [38.018173, 61.224792],
              [37.953559, 61.229406],
              [37.952744, 61.266925],
              [37.872908, 61.290487],
              [37.88074, 61.366033],
              [37.84313, 61.375653],
              [37.823888, 61.400439],
              [37.849785, 61.432417],
              [37.807225, 61.452017],
              [37.836488, 61.501609],
              [37.81674, 61.529811],
              [37.785939, 61.537388],
              [37.763686, 61.564233],
              [37.775057, 61.574018],
              [37.759843, 61.615429],
              [37.784646, 61.631154],
              [37.775588, 61.643666],
              [37.800406, 61.68142],
              [37.841031, 61.684955],
              [37.869322, 61.703208],
              [37.884509, 61.722298],
              [37.876698, 61.745274],
              [37.85945, 61.755579],
              [37.876712, 61.771531],
              [37.868969, 61.781578],
              [37.895455, 61.804343],
              [37.901366, 61.831015],
              [37.895128, 61.840494],
              [37.911951, 61.859653],
              [37.921814, 61.890951],
              [37.934746, 61.909282],
              [37.924367, 61.925729],
              [37.943999, 61.972526],
              [37.888506, 61.98217],
              [37.836783, 61.98254],
              [37.81185, 62.007389],
              [37.823979, 62.03764],
              [37.802248, 62.036106],
              [37.798371, 62.058315],
              [37.776886, 62.081197],
              [37.784443, 62.093181],
              [37.772494, 62.118749],
              [37.755097, 62.120218],
              [37.773086, 62.138087],
              [37.737213, 62.166381],
              [37.758347, 62.173495],
              [37.716298, 62.189902],
              [37.701812, 62.212684],
              [37.636642, 62.209914],
              [37.588205, 62.219968],
              [37.555035, 62.21356],
              [37.525947, 62.21592],
              [37.473523, 62.198026],
              [37.452374, 62.198557],
              [37.452738, 62.215169],
              [37.480277, 62.235266],
              [37.455512, 62.241794],
              [37.476446, 62.26954],
              [37.514496, 62.28328],
              [37.533014, 62.297447],
              [37.545591, 62.324572],
              [37.596416, 62.357571],
              [37.58465, 62.370779],
              [37.594132, 62.379606],
              [37.584912, 62.401603],
              [37.610498, 62.430184],
              [37.600394, 62.438478],
              [37.617792, 62.46693],
              [37.635381, 62.477396],
              [37.610519, 62.492409],
              [37.577011, 62.499195],
              [37.564136, 62.562297],
              [37.540224, 62.590972],
              [37.548008, 62.622088],
              [37.521747, 62.637806],
              [37.513465, 62.651627],
              [37.477835, 62.652333],
              [37.368941, 62.677886],
              [37.323919, 62.677799],
              [37.275063, 62.671998],
              [37.251577, 62.677459],
              [37.180938, 62.652793],
              [37.070641, 62.648683],
              [36.989723, 62.677207],
              [36.821712, 62.69601],
              [36.550694, 62.785014],
              [36.528467, 62.793114],
              [36.487951, 62.838889],
              [36.258139, 63.01153],
              [36.246958, 63.051403],
              [36.274818, 63.082101],
              [36.313263, 63.113118],
              [36.307068, 63.15076],
              [36.324087, 63.161687],
              [36.355601, 63.162905],
              [36.405032, 63.195385],
              [36.391114, 63.225946],
              [36.319439, 63.224076],
              [36.245765, 63.211511],
              [36.261894, 63.280401],
              [36.192072, 63.425609],
              [36.129176, 63.448328],
              [36.086364, 63.453163],
              [36.029888, 63.465428],
              [35.892773, 63.576226],
              [35.93535, 63.605399],
              [36.290921, 63.621152],
              [36.297807, 63.618278],
              [36.455216, 63.626485],
              [36.493082, 63.66895],
              [36.515384, 63.683595],
              [36.590395, 63.748201],
              [36.581887, 63.79105],
              [36.558627, 63.794795],
              [36.548059, 63.840643],
              [36.562558, 63.867346],
              [36.548577, 63.929546],
              [36.553041, 63.952169],
              [36.59777, 63.95652],
              [36.61543, 63.94443],
              [36.65457, 63.93205],
              [36.64105, 63.91985],
              [36.68544, 63.90566],
              [36.76701, 63.90254],
              [36.76732, 63.91789],
              [36.82078, 63.91316],
              [36.81483, 63.9036],
              [36.85481, 63.88985],
              [36.88785, 63.88922],
              [36.92903, 63.89694],
              [36.9369, 63.90954],
              [36.95487, 63.907],
              [36.96201, 63.89169],
              [36.99753, 63.88392],
              [37.06187, 63.88278],
              [37.13442, 63.88609],
              [37.20516, 63.87714],
              [37.24931, 63.83101],
              [37.25101, 63.81533],
              [37.2814, 63.80546],
              [37.34518, 63.79433],
              [37.43799, 63.78382],
              [37.52626, 63.79248],
              [37.61432, 63.81698],
              [37.6377, 63.8375],
              [37.63996, 63.88458],
              [37.66723, 63.89472],
              [37.73248, 63.90221],
              [37.78221, 63.88673],
              [37.85755, 63.88858],
              [37.88542, 63.89494],
              [37.92303, 63.91366],
              [37.97582, 63.92569],
              [37.99982, 63.92307],
              [38.07734, 63.90089],
              [38.08797, 63.9082],
              [38.05075, 63.92451],
              [38.01393, 63.93361],
              [38.003979, 63.95086],
              [38.0489, 63.97726],
              [38.07468, 64.00353],
              [38.03899, 64.03067],
              [38.05619, 64.04007],
              [38.06, 64.0707],
              [38.04091, 64.08008],
              [38.00921, 64.1122],
              [38.00088, 64.14248],
              [37.93537, 64.16036],
              [37.89096, 64.21158],
              [37.90343, 64.23372],
              [37.94978, 64.25574],
              [37.96152, 64.27021],
              [38.00253, 64.28009],
              [37.99694, 64.2981],
              [37.9545, 64.30826],
              [37.92863, 64.30234],
              [37.90833, 64.31764],
              [37.91575, 64.3261],
              [37.95165, 64.33181],
              [37.91612, 64.34801],
              [37.82601, 64.35344],
              [37.78824, 64.36751],
              [37.78345, 64.37739],
              [37.79951, 64.42352],
              [37.7911, 64.43815],
              [37.75671, 64.4417],
              [37.68973, 64.4386],
              [37.65172, 64.42264],
              [37.65055, 64.40525],
              [37.62222, 64.38129],
              [37.61764, 64.35581],
              [37.56647, 64.36818],
              [37.55366, 64.38011],
              [37.44953, 64.38036],
              [37.37481, 64.35712],
              [37.34287, 64.34206],
              [37.19784, 64.37139],
              [37.10935, 64.40067],
              [37.08879, 64.42558],
              [37.04374, 64.44963],
              [37.02058, 64.4696],
              [36.91837, 64.52679],
              [36.87888, 64.55652],
              [36.8685, 64.57574],
              [36.81705, 64.61448],
              [36.7827, 64.62919],
              [36.76223, 64.66889],
              [36.69572, 64.70937],
              [36.63511, 64.71945],
              [36.59301, 64.72035],
              [36.54001, 64.71356],
              [36.55115, 64.73224],
              [36.52133, 64.76316],
              [36.52353, 64.78923],
              [36.50047, 64.81973],
              [36.53071, 64.83028],
              [36.46345, 64.859],
              [36.45282, 64.88639],
              [36.43723, 64.90339],
              [36.43724, 64.92234],
              [36.45945, 64.93078],
              [36.52961, 64.93195],
              [36.54953, 64.9092],
              [36.57691, 64.89741],
              [36.64198, 64.9016],
              [36.66203, 64.91826],
              [36.65772, 64.92861],
              [36.73749, 64.9466],
              [36.77566, 64.94743],
              [36.80811, 64.9548],
              [36.82994, 64.97012],
              [36.81195, 64.98172],
              [36.81005, 65.00238],
              [36.84075, 65.01593],
              [36.85177, 65.04818],
              [36.85301, 65.08195],
              [36.86346, 65.10165],
              [36.81828, 65.1233],
              [36.84887, 65.15402],
              [36.86668, 65.14677],
              [36.91251, 65.14801],
              [36.95483, 65.1624],
              [37.00029, 65.169],
              [37.09688, 65.16332],
              [37.14161, 65.14941],
              [37.17513, 65.14964],
              [37.30871, 65.1137],
              [37.417825, 65.098375],
              [37.52001, 65.06173],
              [37.58925, 65.04437],
              [37.65699, 65.03625],
              [37.69442, 65.02149],
              [37.71092, 64.99996],
              [37.69397, 64.96897],
              [37.74057, 64.95032],
              [37.74833, 64.93957],
              [37.81686, 64.91404],
              [37.88652, 64.89203],
              [37.98569, 64.87374],
              [38.05301, 64.85339],
              [38.13898, 64.84287],
              [38.1846, 64.84205],
              [38.35783, 64.84667],
              [38.37, 64.84123],
              [38.3383, 64.81057],
              [38.35047, 64.78551],
              [38.37098, 64.77682],
              [38.36829, 64.763189],
              [38.33258, 64.77411],
              [38.31944, 64.79433],
              [38.27485, 64.80002],
              [38.20706, 64.7714],
              [38.17917, 64.78571],
              [38.14413, 64.78795],
              [38.12318, 64.77894],
              [38.05479, 64.77155],
              [38.0149, 64.75893],
              [38.00339, 64.74868],
              [38.01308, 64.72656],
              [38.04691, 64.71823],
              [38.11598, 64.67766],
              [38.15707, 64.7111],
              [38.25204, 64.71852],
              [38.2991, 64.7353],
              [38.35033, 64.74245],
              [38.41952, 64.74325],
              [38.4489, 64.73409],
              [38.48523, 64.7475],
              [38.44458, 64.76617],
              [38.42712, 64.76605],
              [38.42219, 64.78188],
              [38.39758, 64.7956],
              [38.40106, 64.80394],
              [38.44782, 64.80608],
              [38.46934, 64.79348],
              [38.56401, 64.77136],
              [38.63076, 64.76575],
              [38.74646, 64.74745],
              [38.830043, 64.744028],
              [38.89784, 64.73648],
              [38.99128, 64.71104],
              [39.021339, 64.706287],
              [39.07835, 64.678818],
              [39.14132, 64.656444],
              [39.231112, 64.648054],
              [39.283044, 64.645845],
              [39.32747, 64.633709],
              [39.364471, 64.608194],
              [39.458762, 64.579453],
              [39.532273, 64.549245],
              [39.580674, 64.545651],
              [39.665674, 64.552862],
              [39.745062, 64.546105],
              [39.73476, 64.562404],
              [39.766309, 64.575068],
              [39.809369, 64.6124],
              [39.820193, 64.629925],
              [39.800428, 64.633713],
              [39.807861, 64.655769],
              [39.833622, 64.659537],
              [39.877739, 64.638983],
              [39.913888, 64.627928],
              [39.970763, 64.620907],
              [39.989313, 64.63692],
              [40.04542, 64.627536],
              [40.066452, 64.630164],
              [40.112536, 64.623112],
              [40.10192, 64.638908],
              [40.029488, 64.66558],
              [39.977444, 64.66228],
              [39.941083, 64.664756],
              [39.934612, 64.673763],
              [39.891403, 64.682273],
              [39.886265, 64.698375],
              [39.860825, 64.693519],
              [39.84788, 64.681273],
              [39.837069, 64.692434],
              [39.883863, 64.707907],
              [39.93029, 64.706431],
              [39.962784, 64.712157],
              [39.962934, 64.721006],
              [39.930335, 64.733351],
              [39.958521, 64.760721],
              [40.005399, 64.750774],
              [40.027197, 64.739973],
              [39.983651, 64.725684],
              [40.022645, 64.704443],
              [40.024167, 64.691292],
              [40.054335, 64.686794],
              [40.088798, 64.694866],
              [40.121776, 64.691344],
              [40.161817, 64.708553],
              [40.210814, 64.695795],
              [40.253824, 64.70154],
              [40.22084, 64.723801],
              [40.12707, 64.738426],
              [40.105817, 64.749772],
              [40.176153, 64.752466],
              [40.148319, 64.763731],
              [40.069958, 64.765371],
              [40.030742, 64.759831],
              [40.034109, 64.767634],
              [40.088101, 64.774562],
              [40.147775, 64.786782],
              [40.204043, 64.774518],
              [40.207169, 64.756934],
              [40.270159, 64.767418],
              [40.313176, 64.768657],
              [40.37365, 64.759156],
              [40.384212, 64.72899],
              [40.414182, 64.704296],
              [40.427865, 64.715483],
              [40.412483, 64.735507],
              [40.425784, 64.752575],
              [40.455502, 64.75426],
              [40.444398, 64.764937],
              [40.459962, 64.774127],
              [40.423365, 64.784532],
              [40.353236, 64.816689],
              [40.359886, 64.825226],
              [40.393511, 64.825634],
              [40.412232, 64.84287],
              [40.412061, 64.857984],
              [40.386141, 64.886138],
              [40.399146, 64.905249],
              [40.396087, 64.921298],
              [40.376873, 64.929415],
              [40.330834, 64.919776],
              [40.284641, 64.923188],
              [40.279164, 64.899436],
              [40.311724, 64.877146],
              [40.277186, 64.872976],
              [40.294586, 64.859689],
              [40.285533, 64.851201],
              [40.245313, 64.86914],
              [40.238384, 64.891216],
              [40.218081, 64.898509],
              [40.213799, 64.947837],
              [40.197888, 64.969111],
              [40.177493, 64.978191],
              [40.17846, 65.0123],
              [40.14133, 65.05957],
              [40.09556, 65.08079],
              [40.03921, 65.11635],
              [40.03336, 65.13796],
              [40.01074, 65.1678],
              [39.95197, 65.18828],
              [39.93773, 65.21206],
              [39.91448, 65.22265],
              [39.87507, 65.25812],
              [39.83886, 65.28277],
              [39.79188, 65.30045],
              [39.72242, 65.33851],
              [39.71853, 65.36771],
              [39.70715, 65.38697],
              [39.70125, 65.42165],
              [39.70637, 65.47279],
              [39.71335, 65.49149],
              [39.74988, 65.54765],
              [39.7788, 65.57771],
              [39.8351, 65.60292],
              [39.8784, 65.62794],
              [39.96565, 65.64708],
              [40.02142, 65.6553],
              [40.17186, 65.69246],
              [40.23856, 65.72786],
              [40.28473, 65.7475],
              [40.3558, 65.76426],
              [40.41757, 65.7853],
              [40.48602, 65.8128],
              [40.54339, 65.85113],
              [40.57562, 65.88785],
              [40.59653, 65.89684],
              [40.64138, 65.93524],
              [40.74353, 65.98515],
              [40.77376, 65.99108],
              [40.92088, 66.00008],
              [40.98839, 66.00672],
              [41.11174, 66.02705],
              [41.14593, 66.02995],
              [41.33046, 66.06007],
              [41.43402, 66.09015],
              [41.5219, 66.12108],
              [41.57116, 66.15393],
              [41.63557, 66.17838],
              [41.76491, 66.24129],
              [41.81736, 66.28077],
              [41.85893, 66.32205],
              [41.89994, 66.33017],
              [41.99226, 66.37818],
              [42.05137, 66.44507],
              [42.10734, 66.48655],
              [42.18385, 66.50689],
              [42.26452, 66.51382],
              [42.31198, 66.50786],
              [42.47548, 66.46351],
              [42.59853, 66.44166],
              [42.63389, 66.41943],
              [42.62243, 66.41222],
              [42.57122, 66.40363],
              [42.57179, 66.3879],
              [42.60764, 66.39332],
              [42.71854, 66.39472],
              [42.75993, 66.38867],
              [42.87445, 66.39232],
              [42.96881, 66.40113],
              [43.18481, 66.41479],
              [43.25651, 66.41215],
              [43.2892, 66.40088],
              [43.32803, 66.37368],
              [43.358947, 66.335391],
              [43.40431, 66.32704],
              [43.53901, 66.30828],
              [43.6002, 66.29401],
              [43.62421, 66.27326],
              [43.65332, 66.26302],
              [43.67982, 66.23564],
              [43.65172, 66.22171],
              [43.57191, 66.19901],
              [43.54539, 66.19787],
              [43.49671, 66.18078],
              [43.46326, 66.17431],
              [43.46016, 66.15254],
              [43.43259, 66.14881],
              [43.40192, 66.13418],
              [43.39495, 66.11965],
              [43.33204, 66.09265],
              [43.37222, 66.06571],
              [43.39325, 66.07667],
              [43.40059, 66.10028],
              [43.4564, 66.13128],
              [43.53555, 66.14905],
              [43.61646, 66.1599],
              [43.71782, 66.18635],
              [43.73791, 66.18319],
              [43.76847, 66.19213],
              [43.84058, 66.19268],
              [43.88423, 66.17868],
              [43.90244, 66.15248],
              [43.92681, 66.14395],
              [43.92992, 66.12249],
              [43.96736, 66.09436],
              [43.99864, 66.08421],
              [44.05951, 66.07398],
              [44.07739, 66.05849],
              [44.0433, 66.03604],
              [44.02609, 66.01726],
              [44.03324, 65.98611],
              [44.05361, 65.96169],
              [44.09418, 65.9494],
              [44.11824, 65.93292],
              [44.12867, 65.88414],
              [44.13736, 65.87677],
              [44.16844, 65.8818],
              [44.14987, 65.90472],
              [44.14511, 65.94433],
              [44.10871, 65.96381],
              [44.08557, 65.98848],
              [44.10261, 66.01261],
              [44.15597, 66.03312],
              [44.16012, 66.06919],
              [44.14662, 66.08676],
              [44.14596, 66.10645],
              [44.12023, 66.12114],
              [44.11035, 66.151],
              [44.09758, 66.15476],
              [44.07411, 66.19195],
              [44.07337, 66.22953],
              [44.11969, 66.30934],
              [44.15808, 66.34969],
              [44.19157, 66.36725],
              [44.22951, 66.41833],
              [44.249558, 66.436164],
            ],
          ],
          [
            [
              [36.8154, 65.19431],
              [36.788, 65.19244],
              [36.78947, 65.20265],
              [36.8367, 65.20875],
              [36.8154, 65.19431],
            ],
          ],
          [
            [
              [36.01521, 65.02362],
              [36.00205, 65.0116],
              [35.96497, 65.01004],
              [35.96354, 65.01903],
              [35.93643, 65.0147],
              [35.876567, 65.016471],
              [35.86208, 65.00987],
              [35.87869, 64.98997],
              [35.8515, 64.97872],
              [35.77378, 64.96944],
              [35.77083, 64.95849],
              [35.74815, 64.9552],
              [35.72474, 64.97137],
              [35.72593, 64.98593],
              [35.70937, 64.99557],
              [35.709599, 65.011557],
              [35.677108, 65.024987],
              [35.677606, 65.034357],
              [35.6004, 65.03794],
              [35.56649, 65.05043],
              [35.52091, 65.08089],
              [35.50494, 65.0983],
              [35.50378, 65.11495],
              [35.51703, 65.13622],
              [35.5373, 65.14484],
              [35.59961, 65.154],
              [35.61968, 65.14777],
              [35.65945, 65.15321],
              [35.66119, 65.16751],
              [35.76214, 65.1726],
              [35.82265, 65.15202],
              [35.84108, 65.13094],
              [35.82823, 65.12735],
              [35.85157, 65.10414],
              [35.82647, 65.09641],
              [35.84826, 65.07322],
              [35.8562, 65.04543],
              [35.89165, 65.0427],
              [35.92041, 65.05085],
              [35.97385, 65.05721],
              [36.02371, 65.03983],
              [36.01521, 65.02362],
            ],
          ],
          [
            [
              [36.11383, 65.13705],
              [36.07744, 65.12487],
              [36.03164, 65.12823],
              [35.93306, 65.14376],
              [35.94199, 65.17493],
              [35.93497, 65.18032],
              [35.99037, 65.19356],
              [36.01798, 65.18456],
              [36.03604, 65.16679],
              [36.06838, 65.15692],
              [36.10108, 65.16072],
              [36.17903, 65.15602],
              [36.26709, 65.1645],
              [36.27397, 65.15615],
              [36.20933, 65.14002],
              [36.11383, 65.13705],
            ],
          ],
          [
            [
              [58.677882, 74.421352],
              [58.66573, 74.427961],
              [58.717983, 74.43165],
              [58.705059, 74.417567],
              [58.677882, 74.421352],
            ],
          ],
          [
            [
              [59.101205, 74.414543],
              [59.140454, 74.391673],
              [59.100454, 74.394358],
              [59.070923, 74.371633],
              [59.064859, 74.346642],
              [59.108735, 74.341881],
              [59.082401, 74.328585],
              [58.963237, 74.336325],
              [59.001469, 74.377386],
              [59.055125, 74.40373],
              [59.101205, 74.414543],
            ],
          ],
          [
            [
              [53.0935, 73.05051],
              [53.10491, 73.06225],
              [53.14532, 73.06813],
              [53.0935, 73.05051],
            ],
          ],
          [
            [
              [54.13681, 73.43254],
              [54.17503, 73.43447],
              [54.16238, 73.42434],
              [54.10308, 73.4253],
              [54.06603, 73.41673],
              [54.00851, 73.41783],
              [54.05788, 73.43797],
              [54.11938, 73.44548],
              [54.13681, 73.43254],
            ],
          ],
          [
            [
              [54.5751, 73.65271],
              [54.62343, 73.65121],
              [54.61357, 73.64394],
              [54.5751, 73.65271],
            ],
          ],
          [
            [
              [56.26445, 70.55557],
              [56.29296, 70.56309],
              [56.30252, 70.5529],
              [56.26445, 70.55557],
            ],
          ],
          [
            [
              [55.54993, 70.60726],
              [55.58637, 70.6032],
              [55.58157, 70.59498],
              [55.54993, 70.60726],
            ],
          ],
          [
            [
              [55.6497, 70.57633],
              [55.6893, 70.58147],
              [55.7066, 70.57521],
              [55.68596, 70.56202],
              [55.6497, 70.57633],
            ],
          ],
          [
            [
              [55.61036, 70.63457],
              [55.57714, 70.63597],
              [55.53588, 70.65252],
              [55.61036, 70.63457],
            ],
          ],
          [
            [
              [53.62409, 71.093606],
              [53.573711, 71.082904],
              [53.557103, 71.101456],
              [53.517113, 71.097152],
              [53.461235, 71.11958],
              [53.473034, 71.133689],
              [53.541271, 71.116962],
              [53.601918, 71.118719],
              [53.5497, 71.14161],
              [53.547765, 71.160839],
              [53.567969, 71.16683],
              [53.65604, 71.1171],
              [53.62409, 71.093606],
            ],
          ],
          [
            [
              [53.4375, 71.052509],
              [53.40428, 71.06107],
              [53.43133, 71.0662],
              [53.4375, 71.052509],
            ],
          ],
          [
            [
              [52.5736, 71.39049],
              [52.638398, 71.369759],
              [52.658854, 71.377953],
              [52.702654, 71.359345],
              [52.727351, 71.34035],
              [52.769866, 71.328838],
              [52.778087, 71.34299],
              [52.737408, 71.356452],
              [52.709439, 71.376982],
              [52.734375, 71.383502],
              [52.716819, 71.395567],
              [52.770694, 71.382342],
              [52.81773, 71.3853],
              [52.898468, 71.366939],
              [52.944713, 71.3457],
              [53.003436, 71.311448],
              [53.027675, 71.291054],
              [53.078431, 71.274036],
              [53.083574, 71.252601],
              [53.040762, 71.240079],
              [53.089335, 71.226169],
              [53.116648, 71.242249],
              [53.164426, 71.238777],
              [53.153764, 71.251112],
              [53.200517, 71.251887],
              [53.22996, 71.240613],
              [53.190702, 71.220403],
              [53.210376, 71.199395],
              [53.235091, 71.189616],
              [53.207387, 71.180062],
              [53.241738, 71.140316],
              [53.200651, 71.134158],
              [53.193691, 71.122657],
              [53.14971, 71.10539],
              [53.179069, 71.078104],
              [53.1327, 71.06152],
              [53.07528, 71.05434],
              [53.05119, 71.0278],
              [53.08496, 70.98858],
              [53.1161, 70.97003],
              [53.18245, 70.95145],
              [53.14383, 70.95256],
              [53.05862, 70.93964],
              [53.038072, 70.944011],
              [53.02816, 70.96253],
              [53.04493, 70.97672],
              [53.0218, 70.99415],
              [52.98394, 70.99487],
              [52.9737, 71.03152],
              [52.92241, 71.04922],
              [52.81542, 71.06695],
              [52.82517, 71.07306],
              [52.8191, 71.10339],
              [52.79727, 71.11122],
              [52.79588, 71.12873],
              [52.75836, 71.14119],
              [52.72281, 71.14449],
              [52.71925, 71.16797],
              [52.69549, 71.2002],
              [52.66929, 71.21446],
              [52.59107, 71.23227],
              [52.48375, 71.22741],
              [52.4674, 71.21788],
              [52.41087, 71.22496],
              [52.36129, 71.22271],
              [52.33128, 71.24503],
              [52.2709, 71.25427],
              [52.23791, 71.2783],
              [52.24137, 71.31297],
              [52.284726, 71.324927],
              [52.32136, 71.34239],
              [52.4094, 71.342801],
              [52.43708, 71.35727],
              [52.50949, 71.36618],
              [52.53265, 71.35956],
              [52.58615, 71.36722],
              [52.59286, 71.37993],
              [52.5736, 71.39049],
            ],
          ],
          [
            [
              [53.430457, 71.208742],
              [53.434971, 71.201509],
              [53.387042, 71.197758],
              [53.367621, 71.244141],
              [53.399336, 71.237953],
              [53.430457, 71.208742],
            ],
          ],
          [
            [
              [53.237658, 71.29142],
              [53.246858, 71.274308],
              [53.191369, 71.284315],
              [53.160712, 71.301224],
              [53.208876, 71.303461],
              [53.237658, 71.29142],
            ],
          ],
          [
            [
              [53.01131, 71.3795],
              [53.03322, 71.37817],
              [53.04691, 71.35439],
              [53.01131, 71.3795],
            ],
          ],
          [
            [
              [52.64288, 71.40337],
              [52.683976, 71.391627],
              [52.666453, 71.38895],
              [52.64288, 71.40337],
            ],
          ],
          [
            [
              [52.5736, 71.39049],
              [52.5213, 71.40847],
              [52.50353, 71.42265],
              [52.54224, 71.42203],
              [52.5736, 71.39049],
            ],
          ],
          [
            [
              [56.39595, 70.50621],
              [56.42885, 70.50657],
              [56.46548, 70.48138],
              [56.43686, 70.47976],
              [56.39595, 70.50621],
            ],
          ],
          [
            [
              [56.31094, 70.50821],
              [56.3592, 70.50711],
              [56.33545, 70.49603],
              [56.31094, 70.50821],
            ],
          ],
          [
            [
              [56.601562, 70.495684],
              [56.59707, 70.51341],
              [56.64742, 70.52348],
              [56.68626, 70.53786],
              [56.73758, 70.53973],
              [56.75684, 70.53222],
              [56.6949, 70.5237],
              [56.67136, 70.51126],
              [56.68791, 70.5049],
              [56.654, 70.49692],
              [56.601562, 70.495684],
            ],
          ],
          [
            [
              [58.700981, 79.780853],
              [58.648385, 79.775224],
              [58.632324, 79.785279],
              [58.700981, 79.780853],
            ],
          ],
          [
            [
              [49.21875, 80.02947],
              [49.130386, 80.027221],
              [49.179787, 80.039025],
              [49.21875, 80.02947],
            ],
          ],
          [
            [
              [59.964301, 79.92791],
              [60.016286, 79.929925],
              [60.073087, 79.912323],
              [59.955887, 79.886323],
              [59.902892, 79.886475],
              [59.854084, 79.899124],
              [59.900886, 79.912622],
              [59.916385, 79.929619],
              [59.964301, 79.92791],
            ],
          ],
          [
            [
              [58.736583, 79.958824],
              [58.816184, 79.936217],
              [58.929687, 79.92572],
              [58.992484, 79.914718],
              [58.978288, 79.906921],
              [58.813903, 79.892273],
              [58.710327, 79.906311],
              [58.627784, 79.93702],
              [58.570484, 79.943618],
              [58.447988, 79.94532],
              [58.312393, 79.94156],
              [58.296487, 79.945617],
              [58.399902, 79.963027],
              [58.564269, 79.973878],
              [58.633687, 79.985221],
              [58.688384, 79.985718],
              [58.674035, 79.972907],
              [58.736583, 79.958824],
            ],
          ],
          [
            [
              [36.785959, 80.15625],
              [36.815284, 80.144822],
              [36.736786, 80.132118],
              [36.589985, 80.152717],
              [36.618686, 80.168824],
              [36.735287, 80.166519],
              [36.785959, 80.15625],
            ],
          ],
          [
            [
              [56.676484, 80.472619],
              [56.551986, 80.469018],
              [56.455086, 80.473136],
              [56.470521, 80.48494],
              [56.608784, 80.498321],
              [56.710628, 80.480835],
              [56.676484, 80.472619],
            ],
          ],
          [
            [
              [54.84375, 80.531173],
              [54.897705, 80.531678],
              [55.099983, 80.498321],
              [55.11861, 80.47725],
              [55.049087, 80.448264],
              [55.000118, 80.441139],
              [54.851384, 80.452911],
              [54.780986, 80.475121],
              [54.638535, 80.498672],
              [54.617084, 80.518821],
              [54.665684, 80.527024],
              [54.735474, 80.52333],
              [54.84375, 80.531173],
            ],
          ],
          [
            [
              [55.306885, 80.429871],
              [55.32502, 80.405341],
              [55.296814, 80.378144],
              [55.150085, 80.369529],
              [55.121685, 80.384017],
              [55.006288, 80.396621],
              [54.984684, 80.409218],
              [55.104083, 80.415818],
              [55.215287, 80.415718],
              [55.260986, 80.434418],
              [55.306885, 80.429871],
            ],
          ],
          [
            [
              [54.84375, 80.431961],
              [54.830208, 80.410057],
              [54.779313, 80.391869],
              [54.586986, 80.376122],
              [54.447384, 80.384118],
              [54.483276, 80.433472],
              [54.595902, 80.45749],
              [54.612984, 80.480217],
              [54.702388, 80.477485],
              [54.802991, 80.449547],
              [54.84375, 80.431961],
            ],
          ],
          [
            [
              [45, 80.613824],
              [45.257075, 80.620332],
              [45.317504, 80.632325],
              [45.526485, 80.64762],
              [45.7677, 80.652718],
              [45.858092, 80.65812],
              [45.945085, 80.656219],
              [46.155884, 80.665719],
              [46.244884, 80.678917],
              [46.358689, 80.686149],
              [46.487186, 80.709617],
              [46.501283, 80.71952],
              [46.56451, 80.718125],
              [46.632686, 80.726524],
              [46.74652, 80.730286],
              [46.904906, 80.741272],
              [47.022769, 80.772674],
              [47.103893, 80.775641],
              [47.153774, 80.786285],
              [47.237671, 80.788085],
              [47.151386, 80.814719],
              [47.190284, 80.83042],
              [47.350388, 80.835074],
              [47.439186, 80.85202],
              [47.593488, 80.843521],
              [47.793384, 80.822516],
              [47.945678, 80.797302],
              [48.122986, 80.80912],
              [48.181582, 80.805619],
              [48.218083, 80.814628],
              [48.337525, 80.81427],
              [48.49688, 80.798027],
              [48.596592, 80.775352],
              [48.598286, 80.745819],
              [48.460384, 80.740021],
              [48.461385, 80.730118],
              [48.586231, 80.716568],
              [48.643071, 80.69313],
              [48.613891, 80.67688],
              [48.724383, 80.667825],
              [48.786187, 80.653122],
              [48.718506, 80.623108],
              [48.63849, 80.617127],
              [48.572285, 80.625923],
              [48.492584, 80.623024],
              [48.465287, 80.642822],
              [48.353272, 80.649322],
              [48.255128, 80.64331],
              [48.139281, 80.648071],
              [48.012085, 80.673522],
              [48.038375, 80.687874],
              [48.009539, 80.702645],
              [48.044495, 80.716491],
              [47.982785, 80.722321],
              [47.914917, 80.713332],
              [47.872985, 80.731422],
              [47.870118, 80.757324],
              [47.774686, 80.769118],
              [47.604489, 80.762933],
              [47.557384, 80.751617],
              [47.629993, 80.727485],
              [47.482585, 80.73812],
              [47.449184, 80.73262],
              [47.465858, 80.710267],
              [47.532981, 80.705704],
              [47.559485, 80.679817],
              [47.525695, 80.673278],
              [47.340271, 80.686889],
              [47.263305, 80.686707],
              [47.287685, 80.673119],
              [47.265819, 80.645867],
              [47.31549, 80.64032],
              [47.323784, 80.625123],
              [47.40509, 80.614502],
              [47.403321, 80.585511],
              [47.271485, 80.587517],
              [47.149783, 80.60302],
              [47.085323, 80.602532],
              [47.002086, 80.580819],
              [46.913487, 80.56762],
              [46.873684, 80.554718],
              [46.890584, 80.538117],
              [46.838282, 80.528519],
              [46.794922, 80.53473],
              [46.698886, 80.533722],
              [46.602191, 80.546066],
              [46.529027, 80.527633],
              [46.584022, 80.494239],
              [46.476085, 80.499923],
              [46.367985, 80.496718],
              [46.354386, 80.482018],
              [46.376281, 80.457886],
              [46.275486, 80.442521],
              [46.16571, 80.442505],
              [46.032982, 80.4515],
              [46.018126, 80.471924],
              [46.092896, 80.513917],
              [46.083187, 80.549339],
              [46.040886, 80.555222],
              [45.92212, 80.551087],
              [45.859143, 80.536635],
              [45.698986, 80.544418],
              [45.641087, 80.513124],
              [45.536187, 80.509621],
              [45.417187, 80.548423],
              [45.272484, 80.563622],
              [45.173486, 80.564422],
              [45.022083, 80.573318],
              [44.963584, 80.588684],
              [44.888915, 80.597718],
              [45, 80.613824],
            ],
          ],
          [
            [
              [53.4375, 80.548776],
              [53.535484, 80.524018],
              [53.56073, 80.507323],
              [53.457702, 80.469116],
              [53.316277, 80.485405],
              [53.216186, 80.516517],
              [53.242127, 80.52472],
              [53.258186, 80.566619],
              [53.321332, 80.581056],
              [53.308384, 80.587822],
              [53.151582, 80.607491],
              [53.082784, 80.610497],
              [53.08429, 80.620423],
              [53.187535, 80.626846],
              [53.156587, 80.642218],
              [53.28949, 80.63849],
              [53.386191, 80.623482],
              [53.431485, 80.600221],
              [53.4375, 80.548776],
            ],
          ],
          [
            [
              [54.369082, 80.493819],
              [54.482987, 80.490913],
              [54.537987, 80.466424],
              [54.412286, 80.452622],
              [54.379386, 80.418617],
              [54.269103, 80.414307],
              [54.097717, 80.441285],
              [53.89032, 80.447877],
              [53.875786, 80.474319],
              [53.8927, 80.496276],
              [53.966863, 80.519027],
              [53.860992, 80.544532],
              [53.83429, 80.569519],
              [53.909962, 80.596877],
              [54.044586, 80.596314],
              [54.271583, 80.582122],
              [54.27069, 80.565674],
              [54.126785, 80.548218],
              [54.147286, 80.537323],
              [54.247486, 80.537116],
              [54.378486, 80.526618],
              [54.415084, 80.513322],
              [54.338075, 80.498109],
              [54.369082, 80.493819],
            ],
          ],
          [
            [
              [54.410187, 80.554536],
              [54.315484, 80.564523],
              [54.339978, 80.580682],
              [54.44149, 80.565423],
              [54.410187, 80.554536],
            ],
          ],
          [
            [
              [58.782777, 80.562073],
              [58.736084, 80.559723],
              [58.494874, 80.581482],
              [58.563485, 80.586922],
              [58.62452, 80.60443],
              [58.803284, 80.61548],
              [58.875485, 80.624519],
              [58.77959, 80.595918],
              [58.782777, 80.562073],
            ],
          ],
          [
            [
              [57.65625, 80.536478],
              [57.593182, 80.522302],
              [57.317486, 80.560525],
              [57.283696, 80.588622],
              [57.348884, 80.590819],
              [57.367486, 80.600936],
              [57.474685, 80.611619],
              [57.784301, 80.621093],
              [57.809882, 80.630417],
              [57.969909, 80.617927],
              [58.079185, 80.625136],
              [58.083884, 80.612724],
              [58.036651, 80.586769],
              [57.97168, 80.569885],
              [57.907593, 80.532264],
              [57.765076, 80.528077],
              [57.713185, 80.539521],
              [57.65625, 80.536478],
            ],
          ],
          [
            [
              [56.25, 80.752978],
              [56.395507, 80.76007],
              [56.492676, 80.76007],
              [56.674683, 80.734681],
              [56.766906, 80.73169],
              [56.898583, 80.715103],
              [56.948686, 80.688018],
              [57.014202, 80.673005],
              [56.953187, 80.652816],
              [56.946472, 80.632874],
              [56.892284, 80.621818],
              [56.726283, 80.606324],
              [56.614487, 80.612122],
              [56.511283, 80.605119],
              [56.472107, 80.595093],
              [56.370884, 80.595122],
              [56.325486, 80.625221],
              [56.160278, 80.613526],
              [56.059873, 80.599526],
              [55.924084, 80.601622],
              [55.783325, 80.614304],
              [55.631981, 80.616897],
              [55.569485, 80.62732],
              [55.633736, 80.652251],
              [55.738271, 80.676353],
              [55.738186, 80.688218],
              [55.646118, 80.689118],
              [55.450286, 80.679924],
              [55.439766, 80.693864],
              [55.540984, 80.70752],
              [55.747925, 80.714478],
              [55.817487, 80.736319],
              [55.987187, 80.75032],
              [56.172984, 80.749222],
              [56.25, 80.752978],
            ],
          ],
          [
            [
              [60.221577, 80.15625],
              [60.234887, 80.13932],
              [60.174786, 80.13453],
              [60.026184, 80.146019],
              [60.011718, 80.160279],
              [59.944183, 80.178123],
              [60.083787, 80.185922],
              [60.160286, 80.177421],
              [60.221577, 80.15625],
            ],
          ],
          [
            [
              [59.0625, 80.108626],
              [59.106872, 80.136474],
              [59.170184, 80.140321],
              [59.243085, 80.134621],
              [59.25531, 80.123717],
              [59.179779, 80.124068],
              [59.0625, 80.108626],
            ],
          ],
          [
            [
              [54.257389, 80.302659],
              [54.420185, 80.284622],
              [54.44658, 80.265664],
              [54.393127, 80.248901],
              [54.426086, 80.226502],
              [54.399285, 80.205123],
              [54.230483, 80.184921],
              [54.22059, 80.169899],
              [54.168685, 80.168723],
              [54.148587, 80.21112],
              [54.176696, 80.237487],
              [54.102387, 80.255821],
              [54.124084, 80.292519],
              [54.175686, 80.293419],
              [54.215083, 80.308318],
              [54.257389, 80.302659],
            ],
          ],
          [
            [
              [50.625, 79.92363],
              [50.450286, 79.927224],
              [50.377087, 79.93972],
              [50.381183, 79.955321],
              [50.303284, 79.942321],
              [50.131385, 79.940133],
              [50.055786, 79.95182],
              [50.091309, 79.966126],
              [50.266686, 79.991019],
              [50.376784, 79.985618],
              [50.414112, 79.965844],
              [50.583484, 79.98822],
              [50.637328, 80.020324],
              [50.709119, 80.045051],
              [50.822086, 80.063325],
              [50.908336, 80.066826],
              [50.968186, 80.083518],
              [50.950288, 80.099221],
              [51.013288, 80.103021],
              [51.139458, 80.083137],
              [51.128587, 80.075425],
              [51.205986, 80.056825],
              [51.298385, 80.05249],
              [51.254085, 80.009918],
              [51.208983, 80.003921],
              [51.203185, 79.983222],
              [51.386109, 79.947875],
              [51.375084, 79.935417],
              [51.431924, 79.919051],
              [51.389527, 79.910889],
              [51.283873, 79.906119],
              [51.105529, 79.906128],
              [50.752885, 79.926087],
              [50.625, 79.92363],
            ],
          ],
          [
            [
              [49.377937, 80.081688],
              [49.418884, 80.088919],
              [49.508386, 80.085517],
              [49.692166, 80.052018],
              [49.659287, 80.024322],
              [49.418483, 80.009622],
              [49.293983, 80.048424],
              [49.379685, 80.065818],
              [49.377937, 80.081688],
            ],
          ],
          [
            [
              [59.0625, 80.050667],
              [59.296487, 80.078116],
              [59.464286, 80.086921],
              [59.662186, 80.081223],
              [59.801484, 80.067917],
              [59.864486, 80.051621],
              [59.928284, 80.005309],
              [60.016785, 79.998923],
              [60.009483, 79.992316],
              [59.854305, 79.976342],
              [59.796306, 79.954154],
              [59.717282, 79.937332],
              [59.522484, 79.937118],
              [59.47931, 79.917481],
              [59.360886, 79.911819],
              [59.305583, 79.937218],
              [59.186986, 79.949418],
              [59.149884, 79.96222],
              [59.023925, 79.976683],
              [58.930786, 79.995017],
              [58.800105, 79.997009],
              [58.792714, 80.008224],
              [59.0625, 80.050667],
            ],
          ],
          [
            [
              [52.812927, 80.107467],
              [52.900708, 80.105605],
              [52.835286, 80.094818],
              [52.769104, 80.103089],
              [52.812927, 80.107467],
            ],
          ],
          [
            [
              [50.351294, 80.15625],
              [50.303086, 80.145317],
              [50.3349, 80.129882],
              [50.128769, 80.128082],
              [50.090698, 80.1015],
              [50.147822, 80.060706],
              [50.047486, 80.044021],
              [49.885685, 80.054817],
              [49.865722, 80.086303],
              [49.719684, 80.108123],
              [49.658585, 80.13182],
              [49.586284, 80.13832],
              [49.551988, 80.159119],
              [49.631085, 80.181022],
              [49.720265, 80.188538],
              [49.87268, 80.214112],
              [50.078685, 80.227523],
              [50.309185, 80.214019],
              [50.387184, 80.19162],
              [50.394283, 80.162324],
              [50.351294, 80.15625],
            ],
          ],
          [
            [
              [57.086917, 80.15625],
              [57.101073, 80.129089],
              [57.082788, 80.113122],
              [57.119484, 80.100723],
              [57.106689, 80.08368],
              [57.02173, 80.057312],
              [56.888683, 80.060524],
              [56.771287, 80.055519],
              [56.762788, 80.045821],
              [56.666984, 80.056022],
              [56.569702, 80.055298],
              [56.510383, 80.063819],
              [56.434884, 80.058625],
              [56.262513, 80.059692],
              [56.227296, 80.055733],
              [56.099384, 80.061622],
              [55.9693, 80.078126],
              [55.825058, 80.089913],
              [55.884537, 80.103034],
              [56.008471, 80.140136],
              [56.092102, 80.156922],
              [56.153085, 80.177619],
              [56.128967, 80.202835],
              [56.052586, 80.227119],
              [56.069985, 80.242722],
              [56.039176, 80.268616],
              [55.940143, 80.296654],
              [56.060985, 80.318618],
              [56.241985, 80.331322],
              [56.397885, 80.327622],
              [56.50708, 80.328903],
              [56.595702, 80.307495],
              [56.700394, 80.314071],
              [56.689983, 80.341423],
              [56.738526, 80.351075],
              [57.004271, 80.350282],
              [57.026917, 80.333313],
              [57.170983, 80.3163],
              [57.20259, 80.303147],
              [57.165254, 80.289825],
              [57.156388, 80.263611],
              [57.119874, 80.243285],
              [57.127602, 80.211539],
              [57.21162, 80.195237],
              [57.091485, 80.174522],
              [57.086917, 80.15625],
            ],
          ],
          [
            [
              [61.182385, 80.338669],
              [61.138484, 80.332222],
              [61.094185, 80.339622],
              [61.182385, 80.338669],
            ],
          ],
          [
            [
              [51.589467, 80.315705],
              [51.532985, 80.323121],
              [51.588585, 80.335023],
              [51.589467, 80.315705],
            ],
          ],
          [
            [
              [55.428985, 80.315438],
              [55.519282, 80.314324],
              [55.556384, 80.305825],
              [55.524474, 80.288086],
              [55.541486, 80.277121],
              [55.500484, 80.258216],
              [55.356689, 80.244874],
              [55.273384, 80.22322],
              [55.205584, 80.222518],
              [55.145684, 80.211624],
              [55.082183, 80.217422],
              [54.958184, 80.244721],
              [55.008384, 80.254884],
              [55.11129, 80.24768],
              [55.119884, 80.267417],
              [55.252799, 80.275841],
              [55.1391, 80.296082],
              [55.256897, 80.31733],
              [55.374085, 80.332276],
              [55.42151, 80.329284],
              [55.428985, 80.315438],
            ],
          ],
          [
            [
              [58.903705, 80.277137],
              [58.845184, 80.275528],
              [58.779492, 80.28469],
              [58.815986, 80.297921],
              [58.906791, 80.289689],
              [58.903705, 80.277137],
            ],
          ],
          [
            [
              [53.924785, 80.331139],
              [53.980285, 80.338517],
              [54.087525, 80.330873],
              [54.010488, 80.312316],
              [54.046885, 80.297317],
              [53.939513, 80.292907],
              [53.925686, 80.309517],
              [53.879184, 80.31932],
              [53.924785, 80.331139],
            ],
          ],
          [
            [
              [52.390683, 80.359665],
              [52.444275, 80.345277],
              [52.534791, 80.333145],
              [52.615085, 80.33242],
              [52.656296, 80.320961],
              [52.546986, 80.315682],
              [52.426185, 80.316421],
              [52.319786, 80.325523],
              [52.255184, 80.344322],
              [52.285686, 80.356522],
              [52.390683, 80.359665],
            ],
          ],
          [
            [
              [53.545295, 80.15625],
              [53.525342, 80.137467],
              [53.392516, 80.13282],
              [53.288585, 80.150085],
              [53.161286, 80.146919],
              [53.130584, 80.153077],
              [53.013286, 80.146324],
              [52.900986, 80.152421],
              [52.767383, 80.15052],
              [52.691784, 80.154319],
              [52.630585, 80.166695],
              [52.395287, 80.188019],
              [52.279687, 80.213417],
              [52.219577, 80.244293],
              [52.234086, 80.251923],
              [52.33551, 80.262818],
              [52.492485, 80.260224],
              [52.642701, 80.261475],
              [52.657105, 80.2807],
              [52.722977, 80.293686],
              [52.842586, 80.292617],
              [52.885684, 80.303521],
              [52.865794, 80.314292],
              [52.901406, 80.327057],
              [52.767882, 80.336121],
              [52.750385, 80.34372],
              [52.834725, 80.364433],
              [53.035277, 80.387504],
              [53.092529, 80.376282],
              [53.167296, 80.372925],
              [53.281285, 80.389923],
              [53.372498, 80.38507],
              [53.430115, 80.363282],
              [53.378185, 80.346321],
              [53.453492, 80.322082],
              [53.617127, 80.294678],
              [53.748902, 80.267524],
              [53.89878, 80.244897],
              [53.947511, 80.230103],
              [53.910541, 80.211448],
              [53.778786, 80.184623],
              [53.740886, 80.201424],
              [53.680077, 80.209283],
              [53.554241, 80.18695],
              [53.545295, 80.15625],
            ],
          ],
          [
            [
              [57.989112, 80.15625],
              [57.981483, 80.149818],
              [58.085678, 80.112228],
              [58.048783, 80.085091],
              [57.856873, 80.093323],
              [57.651184, 80.117523],
              [57.588585, 80.133018],
              [57.34967, 80.153077],
              [57.3508, 80.175805],
              [57.294987, 80.205123],
              [57.276484, 80.24282],
              [57.291183, 80.293618],
              [57.253905, 80.315124],
              [57.293984, 80.346421],
              [57.221086, 80.368424],
              [57.210091, 80.398545],
              [57.024784, 80.439423],
              [57.009586, 80.450317],
              [57.320285, 80.458023],
              [57.391724, 80.468079],
              [57.555093, 80.45929],
              [57.631173, 80.471459],
              [57.916682, 80.471322],
              [58.079185, 80.475823],
              [58.158692, 80.463685],
              [58.268987, 80.467422],
              [58.914791, 80.418893],
              [59.006531, 80.406677],
              [59.075684, 80.384323],
              [59.163586, 80.375313],
              [59.227486, 80.34922],
              [59.286983, 80.33802],
              [59.316712, 80.320922],
              [59.254487, 80.309218],
              [59.179688, 80.314269],
              [59.058185, 80.311119],
              [58.87218, 80.312088],
              [58.719986, 80.326219],
              [58.648314, 80.325318],
              [58.625886, 80.302819],
              [58.477486, 80.305617],
              [58.359131, 80.28131],
              [58.203311, 80.267829],
              [58.144295, 80.253457],
              [58.101283, 80.231019],
              [58.129784, 80.222618],
              [58.286483, 80.224922],
              [58.360902, 80.213073],
              [58.419482, 80.212722],
              [58.491088, 80.19812],
              [58.439072, 80.184944],
              [58.419986, 80.165122],
              [58.454087, 80.148621],
              [58.431186, 80.142418],
              [58.020691, 80.161683],
              [57.989112, 80.15625],
            ],
          ],
          [
            [
              [60.293987, 80.353532],
              [60.311685, 80.341523],
              [60.208686, 80.347923],
              [60.088487, 80.341523],
              [60.258487, 80.362502],
              [60.293987, 80.353532],
            ],
          ],
          [
            [
              [54.84375, 80.367432],
              [54.820495, 80.35791],
              [54.66333, 80.347289],
              [54.597484, 80.358322],
              [54.711486, 80.37912],
              [54.771913, 80.380936],
              [54.927785, 80.39753],
              [54.84375, 80.367432],
            ],
          ],
          [
            [
              [56.25, 80.389518],
              [56.293384, 80.358222],
              [56.176685, 80.346817],
              [55.965384, 80.370224],
              [55.778584, 80.380218],
              [55.799507, 80.399247],
              [55.900185, 80.419221],
              [55.973988, 80.409491],
              [56.032986, 80.411223],
              [56.187584, 80.389123],
              [56.25, 80.389518],
            ],
          ],
          [
            [
              [58.273686, 81.210418],
              [58.305683, 81.201621],
              [58.210483, 81.193108],
              [58.273686, 81.210418],
            ],
          ],
          [
            [
              [58.794186, 81.441124],
              [58.709094, 81.444916],
              [58.771912, 81.455872],
              [58.794186, 81.441124],
            ],
          ],
          [
            [
              [59.0625, 81.727959],
              [58.760186, 81.724022],
              [58.560303, 81.724671],
              [58.434684, 81.707336],
              [58.361786, 81.70662],
              [58.220703, 81.688109],
              [58.149289, 81.687667],
              [57.976284, 81.713822],
              [57.960118, 81.73668],
              [57.996101, 81.755433],
              [58.064484, 81.768219],
              [58.035286, 81.78392],
              [57.939271, 81.796081],
              [57.912484, 81.810822],
              [58.215787, 81.833518],
              [58.357185, 81.829017],
              [58.536785, 81.837418],
              [58.849669, 81.856872],
              [59.096787, 81.857324],
              [59.283788, 81.846931],
              [59.37563, 81.824433],
              [59.361192, 81.795684],
              [59.302673, 81.783692],
              [59.343387, 81.758696],
              [59.237316, 81.734451],
              [59.0625, 81.727959],
            ],
          ],
          [
            [
              [62.236445, 81.5625],
              [62.317078, 81.54309],
              [62.231091, 81.526245],
              [62.104084, 81.516021],
              [61.956783, 81.528718],
              [61.912483, 81.538917],
              [61.875122, 81.568482],
              [61.80951, 81.573997],
              [61.910084, 81.588219],
              [62.032585, 81.582123],
              [62.236445, 81.5625],
            ],
          ],
          [
            [
              [58.565917, 81.578674],
              [58.341082, 81.581619],
              [58.298889, 81.588684],
              [58.33112, 81.608025],
              [58.399394, 81.61406],
              [58.356685, 81.622621],
              [58.431785, 81.629722],
              [58.609184, 81.631425],
              [58.679283, 81.637718],
              [58.734459, 81.607239],
              [58.694889, 81.594414],
              [58.565917, 81.578674],
            ],
          ],
          [
            [
              [61.902597, 81.623375],
              [61.972386, 81.633622],
              [62.019286, 81.624512],
              [61.952886, 81.611922],
              [61.902597, 81.623375],
            ],
          ],
          [
            [
              [58.936283, 81.631425],
              [58.871285, 81.624925],
              [58.828282, 81.636719],
              [58.936283, 81.631425],
            ],
          ],
          [
            [
              [63.28125, 81.710105],
              [63.513184, 81.703819],
              [63.650985, 81.691521],
              [63.756183, 81.675125],
              [63.834987, 81.642134],
              [63.791836, 81.612962],
              [63.602886, 81.580224],
              [63.460384, 81.569122],
              [63.174687, 81.57872],
              [62.988484, 81.577118],
              [62.772183, 81.590888],
              [62.693908, 81.599494],
              [62.628686, 81.617219],
              [62.629486, 81.636719],
              [62.593585, 81.646622],
              [62.452686, 81.645524],
              [62.344981, 81.6501],
              [62.206485, 81.667205],
              [62.326484, 81.694924],
              [62.507873, 81.702324],
              [62.666583, 81.698021],
              [62.745482, 81.707031],
              [62.840687, 81.708922],
              [62.835098, 81.697449],
              [62.751784, 81.685821],
              [63.106785, 81.704918],
              [63.28125, 81.710105],
            ],
          ],
          [
            [
              [58.278411, 81.526924],
              [58.406311, 81.517272],
              [58.280086, 81.503135],
              [58.229127, 81.519469],
              [58.278411, 81.526924],
            ],
          ],
          [
            [
              [60.46875, 81.295231],
              [60.539181, 81.287492],
              [60.602753, 81.259461],
              [60.431702, 81.238098],
              [60.119079, 81.267723],
              [59.98993, 81.269471],
              [59.842087, 81.285919],
              [59.845519, 81.291321],
              [60.037484, 81.308624],
              [60.265785, 81.305618],
              [60.46875, 81.295231],
            ],
          ],
          [
            [
              [54.515321, 81.271729],
              [54.461595, 81.267745],
              [54.392701, 81.296326],
              [54.302085, 81.314522],
              [54.308785, 81.337624],
              [54.451687, 81.302621],
              [54.454987, 81.283424],
              [54.515321, 81.271729],
            ],
          ],
          [
            [
              [54.84375, 81.377584],
              [54.926285, 81.396523],
              [54.934383, 81.375672],
              [54.84375, 81.377584],
            ],
          ],
          [
            [
              [59.0625, 81.294647],
              [58.902709, 81.295478],
              [58.814587, 81.305306],
              [58.640686, 81.315124],
              [58.528885, 81.345421],
              [58.577285, 81.373816],
              [58.703501, 81.390648],
              [58.785286, 81.40992],
              [58.946984, 81.426223],
              [59.104675, 81.419686],
              [59.215785, 81.425323],
              [59.162483, 81.399322],
              [59.258911, 81.383728],
              [59.276984, 81.359718],
              [59.357727, 81.334289],
              [59.350986, 81.32402],
              [59.260684, 81.307824],
              [59.0625, 81.294647],
            ],
          ],
          [
            [
              [57.673949, 81.5625],
              [57.840881, 81.552307],
              [57.819687, 81.523323],
              [57.900284, 81.517119],
              [57.911898, 81.501288],
              [58.055484, 81.487222],
              [58.187683, 81.490296],
              [58.359183, 81.479523],
              [58.551586, 81.440116],
              [58.583484, 81.421822],
              [58.553386, 81.406021],
              [58.302285, 81.377921],
              [58.134094, 81.371888],
              [57.840272, 81.378119],
              [57.740382, 81.393927],
              [57.607483, 81.399475],
              [57.524475, 81.425293],
              [57.401684, 81.43882],
              [57.36958, 81.432686],
              [57.271083, 81.438523],
              [57.189884, 81.433022],
              [57.125786, 81.443123],
              [56.905587, 81.445222],
              [56.834385, 81.465317],
              [56.845085, 81.485223],
              [56.954383, 81.489822],
              [56.926086, 81.518218],
              [56.945923, 81.530701],
              [57.035188, 81.541221],
              [57.127983, 81.540282],
              [57.274586, 81.530418],
              [57.374184, 81.542716],
              [57.428284, 81.539024],
              [57.495769, 81.558677],
              [57.673949, 81.5625],
            ],
          ],
          [
            [
              [63.164786, 80.943244],
              [63.191284, 80.935912],
              [63.383178, 80.95629],
              [63.681984, 80.956817],
              [63.967785, 80.95462],
              [64.068085, 80.959618],
              [64.133781, 80.97512],
              [64.188294, 81.006714],
              [64.159752, 81.043731],
              [64.122384, 81.066521],
              [64.210326, 81.12207],
              [64.189513, 81.135925],
              [64.354286, 81.169022],
              [64.575485, 81.190116],
              [64.791282, 81.18422],
              [64.894486, 81.170021],
              [65.005387, 81.17108],
              [65.234688, 81.123818],
              [65.324432, 81.084694],
              [65.284287, 81.082618],
              [65.327584, 81.052016],
              [65.381446, 81.038277],
              [65.362944, 81.02626],
              [65.402282, 81.013923],
              [65.341887, 80.998618],
              [65.193687, 80.973618],
              [65.282584, 80.972907],
              [65.310882, 80.938821],
              [65.384888, 80.91272],
              [65.350181, 80.891121],
              [65.219482, 80.845123],
              [65.161284, 80.833823],
              [64.97914, 80.779664],
              [64.900382, 80.764419],
              [64.765686, 80.754883],
              [64.680915, 80.738831],
              [64.627784, 80.737489],
              [64.551682, 80.721817],
              [64.415688, 80.705223],
              [64.237487, 80.697693],
              [64.138182, 80.701622],
              [64.020508, 80.689269],
              [63.800483, 80.67672],
              [63.711986, 80.676323],
              [63.718487, 80.689918],
              [63.598686, 80.682418],
              [63.558471, 80.674072],
              [63.376083, 80.661117],
              [63.16413, 80.637824],
              [63.037444, 80.640557],
              [63.03888, 80.662475],
              [62.94928, 80.683471],
              [62.838986, 80.722221],
              [62.774986, 80.73732],
              [62.629085, 80.757424],
              [62.520584, 80.78022],
              [62.523677, 80.798326],
              [62.585084, 80.804504],
              [62.653182, 80.828141],
              [62.812083, 80.86032],
              [62.920085, 80.873122],
              [63.090286, 80.90206],
              [63.160385, 80.929923],
              [63.040542, 80.949264],
              [63.064976, 80.954086],
              [63.164786, 80.943244],
            ],
          ],
          [
            [
              [60.46875, 80.432389],
              [60.352782, 80.450821],
              [60.190307, 80.443931],
              [60.203998, 80.431686],
              [60.155186, 80.421874],
              [60.033874, 80.415536],
              [59.751526, 80.391739],
              [59.731095, 80.383263],
              [59.665386, 80.386818],
              [59.654724, 80.404115],
              [59.566426, 80.422378],
              [59.523986, 80.452095],
              [59.433087, 80.461624],
              [59.338086, 80.485024],
              [59.368897, 80.497521],
              [59.455985, 80.509117],
              [59.469726, 80.520508],
              [59.384899, 80.544273],
              [59.37272, 80.565849],
              [59.404992, 80.595612],
              [59.365723, 80.609328],
              [59.454684, 80.615922],
              [59.413514, 80.633911],
              [59.47467, 80.67633],
              [59.471497, 80.690673],
              [59.547901, 80.718941],
              [59.673096, 80.740479],
              [59.615295, 80.757873],
              [59.599671, 80.778686],
              [59.681882, 80.788345],
              [59.778686, 80.807679],
              [59.887875, 80.809532],
              [60.030685, 80.825317],
              [60.116782, 80.827271],
              [60.27832, 80.815307],
              [60.295464, 80.807946],
              [60.197869, 80.797341],
              [59.999986, 80.793122],
              [59.999986, 80.785118],
              [60.287483, 80.795121],
              [60.413514, 80.774224],
              [60.599685, 80.78672],
              [60.664383, 80.794487],
              [60.724185, 80.785423],
              [60.75989, 80.796828],
              [60.921388, 80.80233],
              [61.249886, 80.826318],
              [61.356484, 80.815818],
              [61.502704, 80.839058],
              [61.57611, 80.86213],
              [61.871587, 80.86422],
              [62.043586, 80.85968],
              [62.136386, 80.834021],
              [62.162129, 80.790077],
              [62.218284, 80.766121],
              [62.212632, 80.704186],
              [62.178408, 80.66005],
              [62.095092, 80.649131],
              [62.038201, 80.625932],
              [62.030297, 80.603692],
              [62.08178, 80.594505],
              [62.011487, 80.578017],
              [61.877884, 80.562524],
              [61.660279, 80.555023],
              [61.660397, 80.538803],
              [61.592185, 80.505019],
              [61.646896, 80.468459],
              [61.534126, 80.455635],
              [61.421872, 80.449128],
              [61.322987, 80.451721],
              [61.426221, 80.44162],
              [61.344726, 80.410888],
              [61.295909, 80.384681],
              [61.185485, 80.376893],
              [61.157287, 80.36609],
              [61.087708, 80.362489],
              [60.800721, 80.377686],
              [60.597787, 80.394622],
              [60.46875, 80.432389],
            ],
          ],
          [
            [
              [54.84375, 80.841482],
              [54.925686, 80.846222],
              [54.977906, 80.873122],
              [55.089683, 80.875121],
              [55.194886, 80.871016],
              [55.298484, 80.854424],
              [55.315085, 80.836822],
              [55.450683, 80.824707],
              [55.481285, 80.826819],
              [55.659182, 80.79882],
              [55.809086, 80.795021],
              [55.966481, 80.76596],
              [55.812504, 80.754828],
              [55.717102, 80.754525],
              [55.554078, 80.742927],
              [55.481285, 80.742019],
              [55.373901, 80.731339],
              [55.149882, 80.73722],
              [55.105286, 80.734024],
              [55.013116, 80.711953],
              [54.883912, 80.710877],
              [54.800903, 80.726075],
              [54.727485, 80.731018],
              [54.686183, 80.742523],
              [54.727401, 80.769004],
              [54.687984, 80.778221],
              [54.480712, 80.78247],
              [54.313476, 80.771728],
              [54.199989, 80.777542],
              [54.050983, 80.800117],
              [54.037903, 80.82129],
              [54.148086, 80.846322],
              [54.220519, 80.84729],
              [54.281495, 80.856872],
              [54.379701, 80.861877],
              [54.474666, 80.848838],
              [54.607585, 80.865318],
              [54.716186, 80.86853],
              [54.822605, 80.862884],
              [54.784286, 80.843323],
              [54.84375, 80.841482],
            ],
          ],
          [
            [
              [57.65625, 80.803551],
              [57.681583, 80.791123],
              [57.804077, 80.772888],
              [57.812984, 80.756118],
              [57.666492, 80.726745],
              [57.601795, 80.703468],
              [57.49231, 80.689087],
              [57.180485, 80.668419],
              [57.056083, 80.674919],
              [56.98352, 80.694274],
              [56.942871, 80.727294],
              [56.867286, 80.738617],
              [56.756787, 80.744622],
              [56.714905, 80.753112],
              [56.728088, 80.76593],
              [56.669186, 80.781494],
              [56.378113, 80.794686],
              [56.209717, 80.810731],
              [56.056518, 80.803529],
              [56.00369, 80.814881],
              [55.914489, 80.819886],
              [55.773185, 80.84552],
              [55.772964, 80.855666],
              [55.847378, 80.86409],
              [55.794784, 80.87671],
              [55.631286, 80.8797],
              [55.525188, 80.900344],
              [55.441283, 80.908875],
              [55.391085, 80.922317],
              [55.380387, 80.944824],
              [55.280971, 80.948655],
              [55.242919, 80.930907],
              [55.034786, 80.933022],
              [54.931687, 80.940917],
              [54.834717, 80.95691],
              [54.901488, 80.970275],
              [54.831086, 80.988023],
              [54.771484, 80.991624],
              [54.615582, 80.97969],
              [54.492183, 80.99282],
              [54.446185, 81.018021],
              [54.46185, 81.027673],
              [54.67739, 81.061509],
              [54.63849, 81.067687],
              [54.634232, 81.091484],
              [54.750687, 81.106421],
              [54.894485, 81.089722],
              [54.966685, 81.074021],
              [55.051086, 81.080872],
              [55.137185, 81.058724],
              [55.243584, 81.057219],
              [55.298097, 81.037719],
              [55.397984, 81.025528],
              [55.499085, 80.999619],
              [55.551331, 81.004699],
              [55.680729, 81.004029],
              [56.025086, 81.023522],
              [56.253586, 81.008125],
              [56.332275, 80.985474],
              [56.397285, 80.982621],
              [56.654907, 80.957519],
              [56.72528, 80.917296],
              [56.835285, 80.901322],
              [56.893184, 80.875823],
              [57.118714, 80.850281],
              [57.307285, 80.843323],
              [57.585884, 80.820121],
              [57.65625, 80.803551],
            ],
          ],
          [
            [
              [58.883904, 80.869056],
              [58.964485, 80.843719],
              [59.023048, 80.808882],
              [58.989383, 80.790818],
              [58.970985, 80.76162],
              [58.916687, 80.754883],
              [58.734085, 80.753723],
              [58.569687, 80.723923],
              [58.50769, 80.72052],
              [58.331483, 80.726685],
              [58.288696, 80.738708],
              [58.192688, 80.750671],
              [58.094981, 80.746901],
              [57.946384, 80.756622],
              [57.881486, 80.767318],
              [57.845184, 80.782921],
              [57.891384, 80.795921],
              [58.025718, 80.79988],
              [58.165897, 80.847434],
              [58.331683, 80.86332],
              [58.444886, 80.862527],
              [58.752074, 80.876281],
              [58.883904, 80.869056],
            ],
          ],
          [
            [
              [47.8125, 80.060094],
              [47.721685, 80.049522],
              [47.654384, 80.075019],
              [47.665169, 80.089644],
              [47.714982, 80.099533],
              [47.790528, 80.127074],
              [47.85255, 80.16378],
              [47.982484, 80.169327],
              [48.11853, 80.184326],
              [48.146386, 80.196023],
              [47.848687, 80.195123],
              [47.827687, 80.20372],
              [48.000202, 80.232551],
              [47.960385, 80.243821],
              [47.856685, 80.24582],
              [47.735084, 80.230522],
              [47.628883, 80.234322],
              [47.615985, 80.223616],
              [47.520683, 80.232017],
              [47.500187, 80.220268],
              [47.38507, 80.222924],
              [47.376685, 80.206719],
              [47.461487, 80.192322],
              [47.450702, 80.18287],
              [47.384785, 80.18032],
              [47.320671, 80.187011],
              [47.245906, 80.177339],
              [47.190507, 80.16066],
              [47.115382, 80.160819],
              [47.011109, 80.172302],
              [46.943298, 80.187073],
              [46.944287, 80.199319],
              [47.006077, 80.221221],
              [46.892273, 80.243529],
              [46.879266, 80.254485],
              [46.767486, 80.276123],
              [46.732727, 80.295288],
              [46.754887, 80.315017],
              [46.94091, 80.334152],
              [47.037479, 80.363227],
              [47.1839, 80.375931],
              [47.269787, 80.37362],
              [47.325867, 80.353662],
              [47.256385, 80.322121],
              [47.319884, 80.308715],
              [47.449086, 80.321724],
              [47.537385, 80.322419],
              [47.566284, 80.300622],
              [47.631905, 80.297447],
              [47.755311, 80.308471],
              [47.779385, 80.297317],
              [47.873475, 80.301087],
              [48.032586, 80.299683],
              [48.054885, 80.305617],
              [47.897082, 80.321022],
              [47.884285, 80.33162],
              [48.115184, 80.332618],
              [48.056287, 80.345124],
              [47.798888, 80.350526],
              [47.702697, 80.361329],
              [47.605538, 80.394226],
              [47.770985, 80.405922],
              [47.962487, 80.398223],
              [48.091483, 80.411324],
              [48.285085, 80.393417],
              [48.356232, 80.400641],
              [48.273684, 80.419731],
              [48.186387, 80.432419],
              [48.236806, 80.444244],
              [48.201985, 80.451819],
              [47.977486, 80.455222],
              [47.786686, 80.462723],
              [47.507083, 80.445426],
              [47.416684, 80.451218],
              [47.612124, 80.483237],
              [47.774488, 80.498817],
              [47.95172, 80.529739],
              [48.074096, 80.539489],
              [48.170471, 80.526488],
              [48.372925, 80.549072],
              [48.502284, 80.54332],
              [48.489986, 80.535819],
              [48.572568, 80.529228],
              [48.548983, 80.506423],
              [48.678527, 80.506286],
              [48.746277, 80.483887],
              [48.875121, 80.488525],
              [48.8573, 80.523072],
              [48.997784, 80.523118],
              [49.154121, 80.513427],
              [49.22879, 80.531617],
              [49.187985, 80.548218],
              [49.253585, 80.561318],
              [49.236184, 80.58612],
              [49.429484, 80.59842],
              [49.303273, 80.620254],
              [49.280686, 80.631919],
              [49.429981, 80.646294],
              [49.532184, 80.639618],
              [49.670487, 80.660622],
              [49.685185, 80.672921],
              [49.637512, 80.676513],
              [49.676037, 80.688827],
              [49.607884, 80.68882],
              [49.642773, 80.704247],
              [49.609085, 80.71782],
              [49.329097, 80.699135],
              [49.220089, 80.696526],
              [49.206386, 80.707322],
              [49.249093, 80.727836],
              [49.344994, 80.748139],
              [49.301185, 80.751617],
              [49.2453, 80.738282],
              [49.151585, 80.740622],
              [49.117885, 80.718818],
              [49.059585, 80.718109],
              [48.98428, 80.740287],
              [49.150684, 80.755822],
              [49.176085, 80.772095],
              [49.123886, 80.779122],
              [49.016906, 80.781127],
              [49.253109, 80.800942],
              [49.350986, 80.81652],
              [49.410706, 80.814881],
              [49.495185, 80.824417],
              [49.513885, 80.83442],
              [49.646485, 80.849023],
              [49.701488, 80.871636],
              [49.747987, 80.878822],
              [49.805115, 80.907471],
              [50.08271, 80.933838],
              [50.16528, 80.944329],
              [50.242787, 80.945022],
              [50.309502, 80.931817],
              [50.437576, 80.926705],
              [50.384889, 80.914917],
              [50.173096, 80.907471],
              [49.935685, 80.885224],
              [49.995685, 80.882722],
              [49.974587, 80.872222],
              [50.076786, 80.867623],
              [50.098511, 80.850525],
              [50.191283, 80.855103],
              [50.230484, 80.875023],
              [50.435185, 80.883622],
              [50.582786, 80.895829],
              [50.701919, 80.915649],
              [50.832085, 80.946419],
              [50.889485, 80.943618],
              [50.925983, 80.898819],
              [50.974189, 80.884133],
              [51.002785, 80.85762],
              [50.823303, 80.835083],
              [50.729485, 80.836221],
              [50.657264, 80.829155],
              [50.708394, 80.818099],
              [50.666886, 80.812821],
              [50.462958, 80.810676],
              [50.441487, 80.800117],
              [50.562386, 80.801879],
              [50.464984, 80.778121],
              [50.408086, 80.776024],
              [50.388485, 80.76252],
              [50.232488, 80.75502],
              [50.314514, 80.732484],
              [50.421184, 80.721817],
              [50.470183, 80.724021],
              [50.665683, 80.76632],
              [50.743686, 80.748123],
              [50.825086, 80.778823],
              [50.957184, 80.782821],
              [51.006286, 80.763122],
              [51.118713, 80.762511],
              [51.118713, 80.783874],
              [51.226074, 80.796691],
              [51.292115, 80.796509],
              [51.492127, 80.758484],
              [51.498803, 80.74134],
              [51.595584, 80.740303],
              [51.735682, 80.718223],
              [51.801796, 80.713088],
              [51.709885, 80.685921],
              [51.637684, 80.678124],
              [51.446715, 80.64032],
              [51.363383, 80.62812],
              [51.165527, 80.618531],
              [51.131985, 80.584319],
              [51.057323, 80.558686],
              [50.986524, 80.545829],
              [50.839478, 80.531311],
              [50.644585, 80.524818],
              [50.539886, 80.528123],
              [50.366273, 80.525818],
              [50.214084, 80.51902],
              [50.099117, 80.508538],
              [50.009274, 80.494339],
              [49.840889, 80.483025],
              [49.724304, 80.48452],
              [49.666077, 80.478271],
              [49.702983, 80.453223],
              [49.791985, 80.43982],
              [49.788685, 80.39772],
              [49.80228, 80.383682],
              [49.749787, 80.36802],
              [49.69873, 80.367676],
              [49.572785, 80.346817],
              [49.363786, 80.350624],
              [49.134085, 80.371124],
              [48.961915, 80.36792],
              [48.909183, 80.380218],
              [48.813286, 80.377725],
              [48.772084, 80.368218],
              [48.811387, 80.335725],
              [48.765499, 80.325127],
              [48.617786, 80.327422],
              [48.519104, 80.308899],
              [48.526306, 80.280273],
              [48.563923, 80.271674],
              [48.607482, 80.28312],
              [48.721309, 80.275733],
              [48.774784, 80.282623],
              [48.891686, 80.280923],
              [48.960885, 80.26612],
              [48.934486, 80.247322],
              [48.997887, 80.236222],
              [48.928284, 80.229522],
              [48.850076, 80.21231],
              [48.968322, 80.19629],
              [49.066712, 80.192689],
              [49.113883, 80.169624],
              [49.031495, 80.158508],
              [48.904369, 80.159883],
              [48.928284, 80.142021],
              [48.827881, 80.1427],
              [48.637485, 80.161423],
              [48.590983, 80.184219],
              [48.50171, 80.190674],
              [48.456715, 80.183953],
              [48.440385, 80.167319],
              [48.520079, 80.122924],
              [48.41069, 80.087151],
              [48.34261, 80.073777],
              [48.273926, 80.115478],
              [48.133483, 80.120116],
              [47.981335, 80.099426],
              [48.027547, 80.084243],
              [48.005485, 80.068223],
              [47.8125, 80.060094],
            ],
          ],
          [
            [
              [58.062985, 80.940926],
              [58.008983, 80.942916],
              [57.960186, 80.971818],
              [58.102885, 80.969621],
              [58.153603, 80.951087],
              [58.062985, 80.940926],
            ],
          ],
          [
            [
              [50.625, 81.057101],
              [50.517701, 81.049071],
              [50.448586, 81.023819],
              [50.385682, 81.044495],
              [50.479309, 81.06549],
              [50.489442, 81.080781],
              [50.434986, 81.092423],
              [50.452025, 81.118195],
              [50.372985, 81.136016],
              [50.367684, 81.149123],
              [50.461486, 81.152526],
              [50.53254, 81.167931],
              [50.475293, 81.171402],
              [50.532287, 81.18042],
              [50.643311, 81.179686],
              [50.748085, 81.187522],
              [50.798782, 81.173293],
              [50.932889, 81.165282],
              [51.013584, 81.142418],
              [51.017089, 81.122314],
              [50.906136, 81.096443],
              [50.782288, 81.089904],
              [50.765076, 81.07251],
              [50.825484, 81.064323],
              [50.787785, 81.058113],
              [50.625, 81.057101],
            ],
          ],
          [
            [
              [54.337478, 81.076852],
              [54.247085, 81.077424],
              [54.248486, 81.101722],
              [54.298286, 81.098319],
              [54.337478, 81.076852],
            ],
          ],
          [
            [
              [58.329372, 81.074044],
              [58.385487, 81.072923],
              [58.441784, 81.053222],
              [58.684186, 81.025017],
              [58.760022, 81.004195],
              [58.641117, 80.963059],
              [58.643077, 80.943504],
              [58.60608, 80.933471],
              [58.410705, 80.927673],
              [58.334781, 80.933501],
              [58.072286, 80.993522],
              [58.002503, 81.014098],
              [57.920486, 81.021622],
              [57.861785, 81.039916],
              [57.875687, 81.04782],
              [58.005885, 81.058022],
              [58.006084, 81.047118],
              [58.10764, 81.057648],
              [58.084785, 81.075019],
              [58.111687, 81.08342],
              [58.191888, 81.083717],
              [58.329372, 81.074044],
            ],
          ],
          [
            [
              [60.46875, 80.903924],
              [60.417886, 80.904221],
              [60.308186, 80.92002],
              [60.290386, 80.935418],
              [60.214985, 80.945419],
              [60.082283, 80.953949],
              [60.001187, 80.973518],
              [60.212531, 80.996063],
              [60.288697, 81.020332],
              [60.483784, 81.047219],
              [60.587086, 81.048919],
              [60.707486, 81.071824],
              [60.965881, 81.093688],
              [61.144586, 81.112924],
              [61.332886, 81.107019],
              [61.380492, 81.109719],
              [61.542479, 81.104126],
              [61.643283, 81.093223],
              [61.637084, 81.084617],
              [61.525878, 81.072082],
              [61.525985, 81.056718],
              [61.587524, 81.034118],
              [61.580623, 81.017731],
              [61.442074, 80.987136],
              [61.351928, 80.957275],
              [61.266784, 80.942321],
              [61.206482, 80.921082],
              [61.100384, 80.904319],
              [61.000672, 80.897279],
              [60.777893, 80.888718],
              [60.671784, 80.890419],
              [60.552491, 80.90168],
              [60.46875, 80.903924],
            ],
          ],
          [
            [
              [57.65625, 80.997809],
              [57.737285, 80.987022],
              [57.865723, 80.950622],
              [57.921783, 80.951622],
              [58.032898, 80.929526],
              [58.13818, 80.934684],
              [58.300083, 80.915817],
              [58.340984, 80.904823],
              [58.274719, 80.890503],
              [58.088502, 80.872924],
              [58.023679, 80.857934],
              [57.956683, 80.855422],
              [57.973075, 80.836075],
              [57.848693, 80.81549],
              [57.742584, 80.816718],
              [57.526786, 80.831223],
              [57.327884, 80.857918],
              [57.305584, 80.867217],
              [57.33403, 80.896041],
              [57.238384, 80.918618],
              [57.035188, 80.937524],
              [56.928784, 80.962822],
              [56.943298, 80.980529],
              [56.855685, 81.006623],
              [56.778076, 81.021911],
              [56.598084, 81.031219],
              [56.166286, 81.080619],
              [56.100086, 81.096024],
              [56.174484, 81.110923],
              [56.266884, 81.092017],
              [56.505086, 81.081123],
              [56.625121, 81.088722],
              [56.770984, 81.085221],
              [56.863587, 81.075822],
              [56.945385, 81.052224],
              [57.010785, 81.043618],
              [57.146581, 81.042487],
              [57.282684, 81.046722],
              [57.370483, 81.033218],
              [57.487485, 81.031417],
              [57.618184, 81.023422],
              [57.596154, 81.006051],
              [57.65625, 80.997809],
            ],
          ],
          [
            [
              [58.641185, 81.044128],
              [58.581185, 81.045929],
              [58.530586, 81.063621],
              [58.518388, 81.086417],
              [58.556787, 81.098121],
              [58.700684, 81.101524],
              [58.845929, 81.090775],
              [58.821246, 81.070976],
              [58.758082, 81.05342],
              [58.641185, 81.044128],
            ],
          ],
          [
            [
              [58.448302, 81.14508],
              [58.539306, 81.139282],
              [58.626672, 81.126054],
              [58.519279, 81.105943],
              [58.368892, 81.096726],
              [58.433087, 81.110321],
              [58.301697, 81.10772],
              [58.177299, 81.113892],
              [58.132984, 81.125518],
              [58.270324, 81.137124],
              [58.448302, 81.14508],
            ],
          ],
          [
            [
              [57.65625, 81.099499],
              [57.503284, 81.097122],
              [57.308983, 81.101081],
              [57.21753, 81.110107],
              [57.056885, 81.134277],
              [56.924483, 81.143822],
              [56.998284, 81.164817],
              [57.143486, 81.178123],
              [57.233887, 81.179686],
              [57.359684, 81.201019],
              [57.526585, 81.209023],
              [57.609484, 81.207123],
              [57.684785, 81.21682],
              [57.829385, 81.217721],
              [57.912785, 81.223724],
              [58.046876, 81.210083],
              [58.085785, 81.183914],
              [57.984924, 81.188523],
              [57.872086, 81.162041],
              [57.855084, 81.13272],
              [57.785083, 81.106551],
              [57.683483, 81.107322],
              [57.65625, 81.099499],
            ],
          ],
          [
            [
              [59.0625, 81.209974],
              [59.242385, 81.213418],
              [59.426186, 81.206817],
              [59.527885, 81.20762],
              [59.650086, 81.201903],
              [59.781284, 81.187424],
              [59.812084, 81.168122],
              [59.736508, 81.158928],
              [59.644283, 81.162324],
              [59.442985, 81.180489],
              [59.320185, 81.186889],
              [59.127883, 81.183823],
              [59.054183, 81.189017],
              [59.004256, 81.202553],
              [59.0625, 81.209974],
            ],
          ],
          [
            [
              [56.151132, 81.198852],
              [55.953483, 81.18422],
              [55.844784, 81.183625],
              [55.717984, 81.193817],
              [55.637683, 81.194321],
              [55.563484, 81.208718],
              [55.623585, 81.230117],
              [55.614502, 81.255675],
              [55.484592, 81.256133],
              [55.460084, 81.264892],
              [55.562287, 81.295922],
              [55.487541, 81.312973],
              [55.591483, 81.320617],
              [55.693684, 81.334517],
              [55.683104, 81.314513],
              [55.803712, 81.304503],
              [55.923786, 81.307824],
              [56.152729, 81.282838],
              [56.269191, 81.285286],
              [56.317486, 81.268219],
              [56.579383, 81.252519],
              [56.636086, 81.264222],
              [56.530587, 81.281021],
              [56.522284, 81.290924],
              [56.411785, 81.309625],
              [56.496703, 81.332703],
              [56.482994, 81.348349],
              [56.421387, 81.365021],
              [56.36715, 81.396248],
              [56.402183, 81.400879],
              [56.575931, 81.403632],
              [56.641725, 81.409729],
              [56.802586, 81.397424],
              [56.887511, 81.379532],
              [57.012387, 81.387619],
              [57.213886, 81.362122],
              [57.300586, 81.346223],
              [57.400089, 81.352493],
              [57.464443, 81.323165],
              [57.527885, 81.306823],
              [57.643886, 81.316521],
              [57.649903, 81.332924],
              [57.732708, 81.313239],
              [57.827388, 81.307685],
              [57.951722, 81.292114],
              [57.807286, 81.286621],
              [57.674321, 81.275025],
              [57.557383, 81.277924],
              [57.390934, 81.262825],
              [57.260086, 81.259019],
              [56.991684, 81.225625],
              [56.652585, 81.166221],
              [56.519684, 81.156419],
              [56.420883, 81.155617],
              [56.350184, 81.164619],
              [56.417225, 81.197038],
              [56.274383, 81.211221],
              [56.151132, 81.198852],
            ],
          ],
          [
            [
              [55.614933, 74.107176],
              [55.617142, 74.092115],
              [55.67894, 74.099909],
              [55.729255, 74.10021],
              [55.780411, 74.08926],
              [55.78613, 74.074984],
              [55.842025, 74.061109],
              [55.886838, 74.072202],
              [55.839529, 74.083197],
              [55.845623, 74.102392],
              [55.78345, 74.110995],
              [55.793525, 74.126126],
              [55.731791, 74.127564],
              [55.609416, 74.139081],
              [55.616053, 74.170935],
              [55.555818, 74.184982],
              [55.491492, 74.191447],
              [55.431813, 74.207508],
              [55.351958, 74.212927],
              [55.32545, 74.219625],
              [55.23625, 74.227996],
              [55.119071, 74.225487],
              [55.119865, 74.259102],
              [55.157405, 74.260187],
              [55.167926, 74.275353],
              [55.243478, 74.282616],
              [55.282013, 74.292632],
              [55.271668, 74.270317],
              [55.247138, 74.261945],
              [55.273765, 74.255128],
              [55.442472, 74.272363],
              [55.560838, 74.265761],
              [55.622047, 74.276886],
              [55.650235, 74.249721],
              [55.685749, 74.246871],
              [55.754279, 74.273077],
              [55.730278, 74.296701],
              [55.652584, 74.311825],
              [55.631064, 74.303898],
              [55.596146, 74.309172],
              [55.577311, 74.324638],
              [55.523353, 74.335781],
              [55.346825, 74.341426],
              [55.29887, 74.34745],
              [55.3553, 74.378799],
              [55.376813, 74.409878],
              [55.427498, 74.406667],
              [55.447469, 74.416263],
              [55.471663, 74.401866],
              [55.520929, 74.403223],
              [55.630812, 74.428971],
              [55.732928, 74.42498],
              [55.877836, 74.433682],
              [55.896308, 74.428026],
              [55.948729, 74.440928],
              [56.029398, 74.43474],
              [56.163551, 74.447208],
              [56.116801, 74.465932],
              [56.074217, 74.460393],
              [56.02429, 74.466311],
              [56.018926, 74.475995],
              [56.071551, 74.481587],
              [56.049412, 74.492734],
              [56.049321, 74.510234],
              [55.868437, 74.512581],
              [55.811748, 74.516118],
              [55.786809, 74.504486],
              [55.692201, 74.492579],
              [55.724314, 74.516824],
              [55.716098, 74.527851],
              [55.648206, 74.53818],
              [55.570654, 74.537697],
              [55.602847, 74.552848],
              [55.621841, 74.582478],
              [55.602443, 74.611741],
              [55.706372, 74.635361],
              [55.824244, 74.670246],
              [55.858188, 74.655092],
              [55.91468, 74.661504],
              [55.889222, 74.64605],
              [55.905912, 74.634891],
              [55.978527, 74.62974],
              [56.03121, 74.634701],
              [56.050657, 74.647814],
              [56.117798, 74.654474],
              [56.11298, 74.662886],
              [56.206223, 74.661319],
              [56.257719, 74.674651],
              [56.295678, 74.668387],
              [56.347629, 74.673142],
              [56.40742, 74.701408],
              [56.414747, 74.686611],
              [56.50801, 74.672274],
              [56.602423, 74.64385],
              [56.66289, 74.656722],
              [56.649085, 74.669984],
              [56.591569, 74.67193],
              [56.570637, 74.695648],
              [56.539674, 74.712446],
              [56.486895, 74.727276],
              [56.429044, 74.718835],
              [56.426704, 74.729814],
              [56.501033, 74.755024],
              [56.484651, 74.765972],
              [56.443788, 74.767408],
              [56.388029, 74.751936],
              [56.308273, 74.777124],
              [56.252363, 74.766855],
              [56.178236, 74.771963],
              [56.159127, 74.765761],
              [56.046857, 74.758289],
              [56.023148, 74.749119],
              [55.999718, 74.758724],
              [55.92289, 74.753006],
              [55.8935, 74.770364],
              [55.798149, 74.784057],
              [55.829939, 74.788311],
              [55.852084, 74.804776],
              [55.934449, 74.81131],
              [55.989935, 74.827404],
              [56.049894, 74.836094],
              [56.125859, 74.856581],
              [56.255046, 74.873518],
              [56.282992, 74.872203],
              [56.346414, 74.887977],
              [56.419323, 74.876424],
              [56.448895, 74.897955],
              [56.425026, 74.907348],
              [56.346922, 74.910714],
              [56.304855, 74.897433],
              [56.307182, 74.911107],
              [56.348773, 74.925025],
              [56.338678, 74.931509],
              [56.256465, 74.925509],
              [56.353913, 74.966422],
              [56.389716, 74.969586],
              [56.360923, 74.987261],
              [56.300226, 74.999729],
              [56.215391, 74.999581],
              [56.139158, 74.98679],
              [56.058526, 74.983535],
              [55.997134, 74.957727],
              [55.935788, 74.962686],
              [55.858628, 74.954649],
              [55.833512, 74.961549],
              [55.874099, 74.985256],
              [55.871933, 75.001341],
              [55.93091, 75.047098],
              [55.901063, 75.056155],
              [55.802771, 75.065755],
              [55.747737, 75.054093],
              [55.789953, 75.108737],
              [55.855904, 75.150206],
              [55.945752, 75.176435],
              [56.018344, 75.180587],
              [56.00664, 75.161121],
              [56.054885, 75.154937],
              [56.127814, 75.169274],
              [56.090055, 75.141794],
              [56.143144, 75.127436],
              [56.258997, 75.159002],
              [56.292945, 75.157831],
              [56.326774, 75.13673],
              [56.314619, 75.130308],
              [56.381094, 75.098309],
              [56.407133, 75.054185],
              [56.447282, 75.052753],
              [56.483475, 75.065609],
              [56.60445, 75.080522],
              [56.553, 75.093598],
              [56.592161, 75.11385],
              [56.611451, 75.136873],
              [56.658118, 75.142055],
              [56.662841, 75.153557],
              [56.734223, 75.17434],
              [56.74787, 75.193769],
              [56.809788, 75.195871],
              [56.870608, 75.211495],
              [56.903617, 75.211382],
              [56.964601, 75.22481],
              [57.01067, 75.246524],
              [56.939462, 75.252519],
              [56.881354, 75.244196],
              [56.831945, 75.270219],
              [56.720871, 75.276243],
              [56.713785, 75.305609],
              [56.81705, 75.339174],
              [56.912766, 75.359408],
              [57.033017, 75.379218],
              [57.091629, 75.370517],
              [57.172502, 75.3706],
              [57.254189, 75.359031],
              [57.310945, 75.368158],
              [57.393189, 75.363181],
              [57.379704, 75.34941],
              [57.451489, 75.336256],
              [57.514282, 75.314414],
              [57.592647, 75.309502],
              [57.750872, 75.312496],
              [57.812981, 75.306412],
              [57.874732, 75.31232],
              [57.898182, 75.350805],
              [57.80608, 75.346669],
              [57.717337, 75.348364],
              [57.702724, 75.359648],
              [57.730485, 75.42138],
              [57.695088, 75.434515],
              [57.624524, 75.446591],
              [57.593692, 75.437858],
              [57.595898, 75.454967],
              [57.560939, 75.484939],
              [57.612661, 75.503968],
              [57.752568, 75.527192],
              [57.86752, 75.536123],
              [57.894605, 75.544452],
              [58.000019, 75.56038],
              [58.088912, 75.557689],
              [58.151119, 75.567614],
              [58.180432, 75.562617],
              [58.261576, 75.577272],
              [58.289068, 75.57508],
              [58.354542, 75.595921],
              [58.306644, 75.623015],
              [58.222187, 75.621547],
              [58.14225, 75.611853],
              [58.076653, 75.614203],
              [57.983286, 75.631978],
              [58.013024, 75.645393],
              [58.057483, 75.647754],
              [58.091521, 75.662162],
              [58.157947, 75.654568],
              [58.204316, 75.666985],
              [58.294859, 75.664339],
              [58.393584, 75.693803],
              [58.541021, 75.710017],
              [58.609658, 75.713142],
              [58.690796, 75.725614],
              [58.780435, 75.721643],
              [58.818129, 75.774767],
              [58.757178, 75.786463],
              [58.733728, 75.799154],
              [58.797221, 75.815808],
              [58.88374, 75.847857],
              [58.952681, 75.855487],
              [59.0338, 75.852518],
              [59.080495, 75.842919],
              [59.151865, 75.838204],
              [59.194277, 75.849324],
              [59.199754, 75.861286],
              [59.10866, 75.878455],
              [59.040936, 75.880824],
              [59.126501, 75.900022],
              [59.215584, 75.913281],
              [59.213933, 75.902562],
              [59.284993, 75.90088],
              [59.309302, 75.908573],
              [59.457265, 75.914658],
              [59.555637, 75.921695],
              [59.728645, 75.89501],
              [59.810863, 75.913246],
              [59.785213, 75.916354],
              [59.859702, 75.926482],
              [59.910308, 75.922045],
              [60.042603, 75.931418],
              [59.991143, 75.936788],
              [59.994194, 75.948898],
              [60.038352, 75.961008],
              [60.099085, 75.964077],
              [60.221035, 75.962621],
              [60.227191, 75.954866],
              [60.324846, 75.964804],
              [60.361973, 75.992688],
              [60.336172, 75.999821],
              [60.189937, 76.016997],
              [60.131266, 76.014815],
              [60.10781, 76.023683],
              [60.054074, 76.019886],
              [60.0411, 76.033474],
              [60.107873, 76.053093],
              [60.208823, 76.063299],
              [60.267311, 76.086825],
              [60.223509, 76.090601],
              [60.16251, 76.079375],
              [60.095833, 76.080611],
              [60.1195, 76.096422],
              [60.05927, 76.098331],
              [59.931724, 76.083523],
              [59.931047, 76.095678],
              [60.019902, 76.125799],
              [60.025492, 76.136982],
              [60.084188, 76.154155],
              [60.123455, 76.148161],
              [60.258396, 76.154469],
              [60.39088, 76.167772],
              [60.478007, 76.169755],
              [60.486921, 76.153896],
              [60.364389, 76.137439],
              [60.27762, 76.119939],
              [60.268192, 76.09065],
              [60.294049, 76.08749],
              [60.513667, 76.115396],
              [60.579729, 76.115337],
              [60.715601, 76.101036],
              [60.76941, 76.103158],
              [60.645541, 76.060508],
              [60.57454, 76.044158],
              [60.545306, 76.028977],
              [60.474079, 76.006209],
              [60.473766, 75.996236],
              [60.54213, 75.990646],
              [60.6015, 75.99566],
              [60.671719, 75.989238],
              [60.705737, 75.998191],
              [60.743895, 75.994537],
              [60.818026, 75.998604],
              [60.795094, 76.013214],
              [60.829774, 76.034916],
              [60.907546, 76.026851],
              [61.011179, 76.032493],
              [61.106202, 76.046807],
              [61.081223, 76.081784],
              [61.114455, 76.099147],
              [61.194543, 76.107007],
              [61.149584, 76.13542],
              [61.04595, 76.131517],
              [60.954386, 76.132367],
              [60.90658, 76.144777],
              [60.959633, 76.181554],
              [61.007103, 76.190257],
              [60.959129, 76.210228],
              [60.991056, 76.228144],
              [61.060349, 76.25031],
              [61.056303, 76.256776],
              [61.154584, 76.272171],
              [61.134913, 76.282228],
              [61.186101, 76.287386],
              [61.174461, 76.277342],
              [61.27105, 76.282738],
              [61.334215, 76.291502],
              [61.466216, 76.295565],
              [61.423821, 76.284543],
              [61.473013, 76.279692],
              [61.563646, 76.279577],
              [61.614411, 76.286195],
              [61.621824, 76.295534],
              [61.691334, 76.296536],
              [61.735583, 76.285193],
              [61.739614, 76.273202],
              [61.790356, 76.273252],
              [61.858699, 76.286655],
              [61.869319, 76.258262],
              [62.04153, 76.2491],
              [62.13902, 76.25311],
              [62.199, 76.24539],
              [62.33076, 76.25682],
              [62.41825, 76.24555],
              [62.36161, 76.1965],
              [62.35114, 76.17772],
              [62.4158, 76.17509],
              [62.47206, 76.16041],
              [62.53238, 76.15995],
              [62.59348, 76.18133],
              [62.54815, 76.20043],
              [62.59044, 76.21977],
              [62.59997, 76.19305],
              [62.64997, 76.19306],
              [62.68805, 76.21959],
              [62.66255, 76.22832],
              [62.72656, 76.23226],
              [62.69841, 76.24207],
              [62.76992, 76.26083],
              [62.77815, 76.25204],
              [62.83717, 76.24557],
              [62.78753, 76.2261],
              [62.81312, 76.21942],
              [62.89366, 76.22002],
              [62.89939, 76.19449],
              [62.97437, 76.20019],
              [63.03841, 76.19122],
              [63.17352, 76.21268],
              [63.21888, 76.23837],
              [63.30914, 76.25766],
              [63.383827, 76.251562],
              [63.493701, 76.265233],
              [63.53579, 76.262914],
              [63.58345, 76.27945],
              [63.56737, 76.29577],
              [63.65721, 76.30695],
              [63.72009, 76.32958],
              [63.82134, 76.32555],
              [63.98072, 76.33138],
              [64.05816, 76.29347],
              [64.07036, 76.27424],
              [64.21471, 76.28142],
              [64.27684, 76.28927],
              [64.27266, 76.30482],
              [64.24187, 76.30628],
              [64.2842, 76.33143],
              [64.37334, 76.3436],
              [64.50095, 76.33467],
              [64.50032, 76.35335],
              [64.540697, 76.37933],
              [64.66446, 76.35933],
              [64.74151, 76.3558],
              [64.80149, 76.33684],
              [64.84936, 76.3507],
              [64.81724, 76.36911],
              [64.73351, 76.37832],
              [64.70466, 76.39076],
              [64.797585, 76.421821],
              [64.829469, 76.425899],
              [64.87896, 76.45564],
              [64.97139, 76.47583],
              [65.0957, 76.47727],
              [65.13125, 76.46759],
              [65.38696, 76.48879],
              [65.37175, 76.50495],
              [65.43673, 76.50792],
              [65.45087, 76.53805],
              [65.48824, 76.55085],
              [65.56488, 76.55652],
              [65.6301, 76.54178],
              [65.80471, 76.52794],
              [65.88859, 76.51134],
              [65.99314, 76.49931],
              [66.09204, 76.49766],
              [66.07644, 76.51435],
              [65.99571, 76.5602],
              [66.02461, 76.56641],
              [66.01012, 76.59745],
              [65.94047, 76.61299],
              [65.88939, 76.63359],
              [65.82099, 76.64707],
              [65.89661, 76.68918],
              [65.95323, 76.7139],
              [66.02929, 76.73729],
              [66.09585, 76.73345],
              [66.1401, 76.71912],
              [66.20929, 76.71273],
              [66.28097, 76.69356],
              [66.330904, 76.697908],
              [66.39152, 76.71337],
              [66.34596, 76.73591],
              [66.23518, 76.75468],
              [66.23112, 76.76359],
              [66.28886, 76.77622],
              [66.402051, 76.774741],
              [66.44415, 76.79085],
              [66.45848, 76.81859],
              [66.51858, 76.82685],
              [66.61711, 76.815],
              [66.73006, 76.82931],
              [66.74557, 76.83711],
              [66.70256, 76.85564],
              [66.72913, 76.87454],
              [66.88145, 76.88592],
              [66.97492, 76.88577],
              [66.996227, 76.892837],
              [66.94167, 76.91158],
              [67.02973, 76.92632],
              [67.04817, 76.93923],
              [67.111, 76.94251],
              [67.15879, 76.93212],
              [67.26221, 76.92734],
              [67.30426, 76.93413],
              [67.3551, 76.96154],
              [67.45509, 76.97773],
              [67.56261, 76.98959],
              [67.6568, 76.98932],
              [67.75667, 77.0008],
              [67.83568, 76.99299],
              [67.982888, 76.996819],
              [68.122218, 76.990256],
              [68.117897, 76.979675],
              [68.162181, 76.961534],
              [68.281259, 76.957512],
              [68.328243, 76.965675],
              [68.370906, 76.955928],
              [68.531298, 76.953856],
              [68.497815, 76.930063],
              [68.56937, 76.912404],
              [68.627155, 76.915583],
              [68.643356, 76.901883],
              [68.70492, 76.891232],
              [68.728682, 76.879223],
              [68.822919, 76.869964],
              [68.758654, 76.851673],
              [68.755954, 76.836555],
              [68.80036, 76.81507],
              [68.88339, 76.79073],
              [69.00518, 76.77978],
              [68.963077, 76.760133],
              [68.953673, 76.736461],
              [68.98738, 76.71388],
              [69.06398, 76.70136],
              [69.007937, 76.680749],
              [68.958376, 76.674418],
              [68.85818, 76.63927],
              [68.82048, 76.60757],
              [68.819361, 76.584859],
              [68.796069, 76.575548],
              [68.8195, 76.55726],
              [68.87122, 76.54554],
              [68.9524, 76.55145],
              [68.95684, 76.53663],
              [68.8255, 76.51665],
              [68.77335, 76.49235],
              [68.71348, 76.48079],
              [68.69766, 76.45474],
              [68.71213, 76.44018],
              [68.67683, 76.43631],
              [68.61728, 76.40507],
              [68.56611, 76.40881],
              [68.49674, 76.40409],
              [68.43535, 76.39144],
              [68.42495, 76.38043],
              [68.45881, 76.37412],
              [68.4551, 76.35828],
              [68.3821, 76.34432],
              [68.3618, 76.3154],
              [68.22729, 76.30838],
              [68.20309, 76.29758],
              [68.23019, 76.27493],
              [68.32236, 76.26063],
              [68.31005, 76.24886],
              [68.20227, 76.25517],
              [68.14753, 76.2541],
              [68.06995, 76.24105],
              [68.03869, 76.24284],
              [67.87103, 76.21813],
              [67.8105, 76.19868],
              [67.74341, 76.19477],
              [67.69653, 76.181],
              [67.63912, 76.17761],
              [67.57139, 76.16037],
              [67.5118, 76.15848],
              [67.40069, 76.14041],
              [67.32902, 76.12434],
              [67.26892, 76.10196],
              [67.14165, 76.08315],
              [66.82065, 76.04319],
              [66.67704, 76.03394],
              [66.62707, 76.02247],
              [66.49945, 76.0143],
              [66.44377, 75.98867],
              [66.37659, 75.98667],
              [66.32479, 75.99838],
              [66.26787, 75.99775],
              [66.235829, 75.98763],
              [65.964587, 75.973156],
              [65.95027, 75.96637],
              [65.9676, 75.94433],
              [65.94795, 75.93769],
              [65.80479, 75.91437],
              [65.732521, 75.925169],
              [65.706019, 75.915823],
              [65.571564, 75.901794],
              [65.50948, 75.87575],
              [65.41545, 75.86618],
              [65.3565, 75.84663],
              [65.24611, 75.83265],
              [65.1935, 75.8221],
              [65.1665, 75.79787],
              [65.06385, 75.80722],
              [64.88757, 75.79224],
              [64.86914, 75.77644],
              [64.7303, 75.77042],
              [64.61763, 75.74008],
              [64.43817, 75.72906],
              [64.4105, 75.73775],
              [64.378319, 75.727908],
              [64.18535, 75.7177],
              [64.09649, 75.69488],
              [64.00685, 75.65996],
              [63.8944, 75.65911],
              [63.84175, 75.64413],
              [63.84089, 75.62899],
              [63.79639, 75.61187],
              [63.7439, 75.6202],
              [63.81904, 75.64261],
              [63.81477, 75.65175],
              [63.75947, 75.65746],
              [63.71415, 75.66979],
              [63.73671, 75.68618],
              [63.70735, 75.70425],
              [63.66434, 75.70265],
              [63.68699, 75.68893],
              [63.63166, 75.67025],
              [63.63251, 75.65104],
              [63.57509, 75.64692],
              [63.53725, 75.63194],
              [63.52428, 75.61139],
              [63.46168, 75.59685],
              [63.38128, 75.5888],
              [63.37185, 75.58114],
              [63.19201, 75.55935],
              [63.05808, 75.56243],
              [62.936115, 75.551283],
              [62.918085, 75.538121],
              [62.876253, 75.537182],
              [62.844863, 75.522191],
              [62.759254, 75.506909],
              [62.736555, 75.495738],
              [62.698161, 75.499636],
              [62.51739, 75.47552],
              [62.50029, 75.46785],
              [62.35238, 75.44059],
              [62.33909, 75.42582],
              [62.2827, 75.41624],
              [62.23085, 75.41809],
              [62.2476, 75.43284],
              [62.05655, 75.43072],
              [61.964206, 75.437973],
              [61.912941, 75.412667],
              [61.951696, 75.39918],
              [61.950326, 75.387421],
              [61.890781, 75.374579],
              [61.812474, 75.348789],
              [61.757565, 75.3576],
              [61.724231, 75.335475],
              [61.672089, 75.323973],
              [61.661998, 75.298046],
              [61.678293, 75.282348],
              [61.619457, 75.274559],
              [61.643165, 75.259998],
              [61.555901, 75.250968],
              [61.541145, 75.232047],
              [61.466472, 75.207119],
              [61.382884, 75.211021],
              [61.381445, 75.230254],
              [61.402295, 75.25009],
              [61.410493, 75.283226],
              [61.374317, 75.302603],
              [61.291172, 75.304662],
              [61.3359, 75.293591],
              [61.344749, 75.280622],
              [61.26676, 75.266703],
              [61.228708, 75.251268],
              [61.213149, 75.233373],
              [61.176512, 75.220722],
              [61.138299, 75.220383],
              [61.109455, 75.178912],
              [61.073123, 75.168418],
              [60.973045, 75.164146],
              [60.936412, 75.150055],
              [60.891051, 75.152232],
              [60.825434, 75.145395],
              [60.736175, 75.11922],
              [60.784047, 75.119462],
              [60.81613, 75.098459],
              [60.75257, 75.090803],
              [60.772359, 75.08037],
              [60.812034, 75.0801],
              [60.810587, 75.068351],
              [60.752136, 75.01634],
              [60.6669, 75.013627],
              [60.588406, 75.03534],
              [60.589363, 75.056306],
              [60.613092, 75.064378],
              [60.615149, 75.083536],
              [60.594945, 75.088459],
              [60.630983, 75.100143],
              [60.494959, 75.101001],
              [60.528817, 75.086157],
              [60.51063, 75.061106],
              [60.474168, 75.062172],
              [60.484578, 75.046919],
              [60.401657, 75.043942],
              [60.407257, 75.033498],
              [60.45073, 75.022103],
              [60.448386, 75.007299],
              [60.411428, 74.998899],
              [60.209546, 74.973555],
              [60.147406, 74.968665],
              [60.064871, 74.979889],
              [59.973752, 74.998355],
              [59.934864, 74.986071],
              [59.951185, 74.978572],
              [60.138295, 74.943341],
              [60.243791, 74.939563],
              [60.337894, 74.942426],
              [60.448008, 74.937461],
              [60.524548, 74.945117],
              [60.571314, 74.942983],
              [60.629451, 74.911871],
              [60.602007, 74.880578],
              [60.562273, 74.852803],
              [60.52467, 74.842389],
              [60.447668, 74.843574],
              [60.351178, 74.836067],
              [60.215668, 74.842506],
              [60.171095, 74.849273],
              [60.155315, 74.841437],
              [60.204076, 74.819522],
              [60.199088, 74.795293],
              [60.292554, 74.790114],
              [60.307763, 74.776846],
              [60.296276, 74.752456],
              [60.245561, 74.732984],
              [60.199148, 74.72444],
              [60.098321, 74.715108],
              [59.976687, 74.707799],
              [59.908796, 74.707536],
              [59.839371, 74.717501],
              [59.720217, 74.726516],
              [59.656271, 74.718458],
              [59.628698, 74.747673],
              [59.574313, 74.75955],
              [59.542722, 74.756585],
              [59.594287, 74.733499],
              [59.579063, 74.717694],
              [59.669759, 74.703725],
              [59.737207, 74.701636],
              [59.755693, 74.674562],
              [59.828838, 74.667259],
              [59.854468, 74.643408],
              [59.866315, 74.613082],
              [59.850868, 74.596914],
              [59.783178, 74.589584],
              [59.761663, 74.571057],
              [59.714421, 74.564477],
              [59.586278, 74.573542],
              [59.515282, 74.59225],
              [59.51446, 74.599162],
              [59.45165, 74.60983],
              [59.445425, 74.619357],
              [59.365228, 74.649872],
              [59.336847, 74.653748],
              [59.309093, 74.675124],
              [59.221789, 74.679839],
              [59.161147, 74.695895],
              [59.155295, 74.720233],
              [59.133931, 74.723504],
              [59.091484, 74.710611],
              [59.14586, 74.658468],
              [59.218491, 74.64626],
              [59.206869, 74.628737],
              [59.144595, 74.617329],
              [59.17148, 74.592277],
              [59.109975, 74.513375],
              [59.125025, 74.500708],
              [59.157384, 74.499629],
              [59.152429, 74.494821],
              [59.123883, 74.499313],
              [59.070125, 74.479245],
              [59.063503, 74.464994],
              [59.085141, 74.451756],
              [59.151759, 74.443962],
              [59.115503, 74.439966],
              [59.182333, 74.423726],
              [59.232409, 74.441631],
              [59.238049, 74.427515],
              [59.362114, 74.429805],
              [59.354012, 74.407139],
              [59.315173, 74.408061],
              [59.309266, 74.386905],
              [59.263765, 74.387377],
              [59.268673, 74.398574],
              [59.210908, 74.423953],
              [59.1092, 74.424691],
              [59.101205, 74.414543],
              [59.046167, 74.430266],
              [58.908633, 74.434178],
              [58.872017, 74.44663],
              [58.823288, 74.445375],
              [58.808746, 74.459933],
              [58.778321, 74.46069],
              [58.768604, 74.476229],
              [58.692939, 74.491641],
              [58.662141, 74.489445],
              [58.632807, 74.501417],
              [58.601908, 74.49832],
              [58.529053, 74.515826],
              [58.503022, 74.513704],
              [58.440623, 74.528317],
              [58.336833, 74.539293],
              [58.261845, 74.560042],
              [58.204901, 74.542855],
              [58.217192, 74.528225],
              [58.287017, 74.515696],
              [58.329989, 74.514805],
              [58.376661, 74.50149],
              [58.439879, 74.503006],
              [58.497441, 74.495088],
              [58.506057, 74.475099],
              [58.591181, 74.448441],
              [58.578309, 74.424257],
              [58.625211, 74.417785],
              [58.670638, 74.402114],
              [58.63613, 74.385123],
              [58.605018, 74.382413],
              [58.615479, 74.368538],
              [58.666002, 74.343885],
              [58.815272, 74.341428],
              [58.82558, 74.328054],
              [58.771219, 74.319553],
              [58.763512, 74.33546],
              [58.728198, 74.341362],
              [58.678645, 74.333567],
              [58.697457, 74.319113],
              [58.643622, 74.291667],
              [58.675585, 74.286059],
              [58.68717, 74.253829],
              [58.739716, 74.246716],
              [58.653277, 74.242494],
              [58.636782, 74.234092],
              [58.723194, 74.224103],
              [58.72949, 74.21268],
              [58.667256, 74.21917],
              [58.607803, 74.216293],
              [58.640082, 74.201231],
              [58.631504, 74.190563],
              [58.508217, 74.195537],
              [58.492964, 74.183417],
              [58.531016, 74.178769],
              [58.539259, 74.160175],
              [58.506216, 74.143678],
              [58.480228, 74.155994],
              [58.418824, 74.159852],
              [58.39741, 74.182859],
              [58.358747, 74.17991],
              [58.356318, 74.162038],
              [58.286993, 74.161053],
              [58.266845, 74.154656],
              [58.331883, 74.137915],
              [58.440333, 74.128026],
              [58.499153, 74.127884],
              [58.537482, 74.118418],
              [58.520155, 74.105007],
              [58.331117, 74.122844],
              [58.307014, 74.110401],
              [58.327466, 74.101911],
              [58.322032, 74.062748],
              [58.34391, 74.045855],
              [58.308417, 74.043517],
              [58.316702, 74.065697],
              [58.259244, 74.080602],
              [58.291385, 74.110308],
              [58.256654, 74.119582],
              [58.190762, 74.12468],
              [58.151508, 74.110839],
              [58.193053, 74.079443],
              [58.179668, 74.055243],
              [58.146733, 74.049543],
              [58.217888, 74.025393],
              [58.249413, 74.000684],
              [58.233126, 73.994877],
              [58.16403, 73.996454],
              [58.14536, 73.972434],
              [58.092776, 73.977213],
              [58.075748, 73.988771],
              [57.994871, 73.989487],
              [57.93151, 73.998711],
              [57.913555, 74.009008],
              [57.87008, 74.01072],
              [57.828309, 74.028001],
              [57.777843, 74.040068],
              [57.70937, 74.045796],
              [57.715144, 74.057696],
              [57.665787, 74.093452],
              [57.647247, 74.119311],
              [57.603226, 74.14013],
              [57.541941, 74.179633],
              [57.488897, 74.190999],
              [57.401997, 74.20165],
              [57.370688, 74.185866],
              [57.457092, 74.159901],
              [57.499629, 74.128641],
              [57.541413, 74.079491],
              [57.489967, 74.071183],
              [57.404589, 74.079917],
              [57.377368, 74.070131],
              [57.459695, 74.053367],
              [57.51091, 74.056573],
              [57.521411, 74.065234],
              [57.561427, 74.038821],
              [57.636292, 74.005914],
              [57.680271, 73.993772],
              [57.722867, 73.972685],
              [57.781176, 73.954087],
              [57.821365, 73.926346],
              [57.862847, 73.908299],
              [57.839885, 73.891528],
              [57.803215, 73.894479],
              [57.761318, 73.884156],
              [57.786404, 73.870015],
              [57.844247, 73.868773],
              [57.832254, 73.831263],
              [57.859605, 73.81046],
              [57.884821, 73.767946],
              [57.800775, 73.748861],
              [57.796699, 73.739935],
              [57.724801, 73.722307],
              [57.655194, 73.712825],
              [57.63209, 73.739976],
              [57.600294, 73.743389],
              [57.60553, 73.762024],
              [57.583292, 73.778947],
              [57.540826, 73.780081],
              [57.54475, 73.802297],
              [57.527611, 73.81026],
              [57.395844, 73.833919],
              [57.326172, 73.835187],
              [57.25474, 73.842053],
              [57.153831, 73.843938],
              [57.062101, 73.852414],
              [56.962941, 73.855485],
              [56.94417, 73.83926],
              [57.025342, 73.828919],
              [57.148133, 73.822355],
              [57.177333, 73.810582],
              [57.245026, 73.805533],
              [57.304113, 73.807629],
              [57.417498, 73.765029],
              [57.473316, 73.75265],
              [57.509104, 73.699173],
              [57.538835, 73.690159],
              [57.55591, 73.670443],
              [57.620709, 73.629897],
              [57.583714, 73.595002],
              [57.53848, 73.5941],
              [57.504636, 73.580898],
              [57.380775, 73.576304],
              [57.345723, 73.562569],
              [57.357604, 73.548242],
              [57.208006, 73.553553],
              [57.121507, 73.564605],
              [56.952677, 73.590693],
              [56.963501, 73.600914],
              [56.854708, 73.631779],
              [56.830887, 73.658074],
              [56.793135, 73.66545],
              [56.796165, 73.677715],
              [56.762532, 73.678957],
              [56.739734, 73.654917],
              [56.753191, 73.634495],
              [56.831389, 73.608003],
              [56.888415, 73.604209],
              [56.88846, 73.597311],
              [56.754239, 73.593621],
              [56.761615, 73.583411],
              [56.843412, 73.57991],
              [56.899382, 73.585309],
              [56.922946, 73.569736],
              [56.99517, 73.547524],
              [57.123943, 73.527561],
              [57.168735, 73.504282],
              [57.224308, 73.490689],
              [57.24124, 73.449284],
              [57.222747, 73.440107],
              [57.181552, 73.441424],
              [57.150991, 73.430208],
              [57.146712, 73.401342],
              [57.112253, 73.393955],
              [57.099205, 73.378122],
              [56.965868, 73.324493],
              [56.917853, 73.317326],
              [56.905496, 73.299948],
              [56.855538, 73.323679],
              [56.816281, 73.333612],
              [56.792056, 73.349045],
              [56.751738, 73.333289],
              [56.750663, 73.315405],
              [56.806761, 73.309333],
              [56.744893, 73.28474],
              [56.735471, 73.262373],
              [56.715229, 73.253278],
              [56.730601, 73.233626],
              [56.668623, 73.234558],
              [56.639268, 73.241407],
              [56.543471, 73.24735],
              [56.411043, 73.260047],
              [56.347758, 73.269055],
              [56.242517, 73.261927],
              [56.122105, 73.258515],
              [56.049497, 73.289111],
              [56.02626, 73.3074],
              [56.057839, 73.339484],
              [56.040342, 73.373642],
              [56.003969, 73.385225],
              [55.975118, 73.382275],
              [55.973417, 73.343684],
              [55.986852, 73.312322],
              [55.965687, 73.294588],
              [55.915872, 73.295612],
              [55.864576, 73.318973],
              [55.777138, 73.33496],
              [55.693125, 73.338921],
              [55.634516, 73.336629],
              [55.592814, 73.32383],
              [55.631451, 73.319233],
              [55.709586, 73.321392],
              [55.804092, 73.318763],
              [55.837638, 73.296339],
              [55.811707, 73.285844],
              [55.845472, 73.285523],
              [55.898798, 73.270864],
              [55.959061, 73.271942],
              [55.980704, 73.266045],
              [55.964076, 73.247566],
              [56.014511, 73.248705],
              [56.156878, 73.236302],
              [56.25, 73.236998],
              [56.395428, 73.224999],
              [56.469505, 73.203997],
              [56.526095, 73.17041],
              [56.559921, 73.158093],
              [56.567935, 73.137033],
              [56.548862, 73.108888],
              [56.368295, 73.095895],
              [56.315154, 73.084994],
              [56.271343, 73.08331],
              [56.17835, 73.064052],
              [56.116761, 73.068523],
              [55.972277, 73.065169],
              [55.908561, 73.060624],
              [55.887104, 73.053482],
              [55.945165, 73.049572],
              [56.034251, 73.054227],
              [56.109194, 73.044984],
              [56.164975, 73.050457],
              [56.219449, 73.04671],
              [56.279729, 73.050252],
              [56.406555, 73.029707],
              [56.431343, 73.020708],
              [56.333482, 72.999998],
              [56.31725, 72.98638],
              [56.1873, 72.99118],
              [56.1574, 72.98962],
              [56.01884, 72.96983],
              [55.97271, 72.96833],
              [55.89937, 72.95619],
              [55.88506, 72.9413],
              [55.81382, 72.95854],
              [55.77197, 72.9464],
              [55.68612, 72.95314],
              [55.64366, 72.95018],
              [55.61673, 72.93812],
              [55.71161, 72.93823],
              [55.76239, 72.93395],
              [55.81333, 72.94],
              [55.89273, 72.9232],
              [56.022161, 72.92706],
              [56.077992, 72.935466],
              [56.101156, 72.952488],
              [56.203018, 72.9542],
              [56.265982, 72.94575],
              [56.217215, 72.904224],
              [56.232953, 72.881637],
              [56.234155, 72.853496],
              [56.221414, 72.842117],
              [56.121454, 72.792176],
              [56.121607, 72.778407],
              [56.018626, 72.76956],
              [55.99904, 72.78168],
              [55.95156, 72.78221],
              [55.91588, 72.77307],
              [55.89296, 72.78138],
              [55.81068, 72.77299],
              [55.76067, 72.76068],
              [55.69241, 72.76319],
              [55.6483, 72.77644],
              [55.59478, 72.77697],
              [55.57557, 72.79064],
              [55.53548, 72.79521],
              [55.55499, 72.77823],
              [55.479, 72.76925],
              [55.47883, 72.76106],
              [55.58621, 72.76554],
              [55.64548, 72.74563],
              [55.71434, 72.73995],
              [55.84165, 72.738109],
              [55.843688, 72.721282],
              [55.864557, 72.715073],
              [55.899718, 72.686807],
              [55.921909, 72.650749],
              [55.89631, 72.64392],
              [55.77486, 72.65472],
              [55.74175, 72.65042],
              [55.67358, 72.6574],
              [55.659321, 72.642321],
              [55.69105, 72.632832],
              [55.696115, 72.596824],
              [55.660939, 72.595393],
              [55.620077, 72.58024],
              [55.639237, 72.573746],
              [55.64429, 72.541959],
              [55.612012, 72.54376],
              [55.614984, 72.525741],
              [55.516844, 72.529929],
              [55.52778, 72.545827],
              [55.492862, 72.559077],
              [55.425742, 72.561236],
              [55.42057, 72.55334],
              [55.46272, 72.54856],
              [55.490318, 72.536683],
              [55.484541, 72.52237],
              [55.552381, 72.51524],
              [55.562004, 72.495835],
              [55.54757, 72.47636],
              [55.566495, 72.469308],
              [55.545057, 72.457494],
              [55.497977, 72.458916],
              [55.412837, 72.454123],
              [55.356462, 72.433826],
              [55.404598, 72.425753],
              [55.479547, 72.435418],
              [55.47933, 72.41141],
              [55.500661, 72.381759],
              [55.46972, 72.333011],
              [55.428578, 72.318264],
              [55.429225, 72.308938],
              [55.37672, 72.299706],
              [55.373597, 72.291846],
              [55.419854, 72.270999],
              [55.470097, 72.266226],
              [55.529495, 72.234098],
              [55.525839, 72.21074],
              [55.552507, 72.200175],
              [55.575378, 72.173115],
              [55.548353, 72.155745],
              [55.53125, 72.157623],
              [55.46216, 72.135491],
              [55.441873, 72.123928],
              [55.432412, 72.100209],
              [55.412745, 72.091343],
              [55.451388, 72.064487],
              [55.432569, 72.030549],
              [55.40364, 72.027179],
              [55.413086, 72.012417],
              [55.389323, 72.010685],
              [55.384895, 71.986419],
              [55.412978, 71.961537],
              [55.40388, 71.953751],
              [55.43302, 71.93456],
              [55.386396, 71.933541],
              [55.3241, 71.94092],
              [55.30503, 71.93309],
              [55.38084, 71.91506],
              [55.449321, 71.918959],
              [55.524604, 71.881876],
              [55.508699, 71.87048],
              [55.553631, 71.845482],
              [55.525244, 71.831558],
              [55.533062, 71.787065],
              [55.575615, 71.765243],
              [55.569992, 71.744433],
              [55.58994, 71.734708],
              [55.583548, 71.700895],
              [55.634496, 71.683076],
              [55.623174, 71.673404],
              [55.642198, 71.649535],
              [55.665458, 71.648322],
              [55.657024, 71.624725],
              [55.690065, 71.612715],
              [55.697536, 71.595154],
              [55.73919, 71.575637],
              [55.738782, 71.553739],
              [55.829258, 71.508319],
              [55.833727, 71.485192],
              [55.874587, 71.466793],
              [55.862548, 71.454555],
              [55.898847, 71.429388],
              [55.93086, 71.423985],
              [55.967707, 71.396509],
              [55.997801, 71.364424],
              [56.02784, 71.35106],
              [56.04234, 71.33222],
              [56.03186, 71.31862],
              [56.04738, 71.29493],
              [56.089498, 71.281278],
              [56.110154, 71.254925],
              [56.143727, 71.23987],
              [56.199461, 71.200863],
              [56.212915, 71.184815],
              [56.30384, 71.15105],
              [56.335456, 71.133946],
              [56.375919, 71.133382],
              [56.446613, 71.105388],
              [56.473556, 71.08685],
              [56.521992, 71.067036],
              [56.574933, 71.051753],
              [56.606247, 71.025606],
              [56.679779, 70.996695],
              [56.757952, 70.977819],
              [56.808596, 70.949941],
              [56.899024, 70.927177],
              [56.894879, 70.922171],
              [56.947459, 70.890999],
              [57.04565, 70.85219],
              [57.137021, 70.836727],
              [57.180984, 70.835607],
              [57.305066, 70.800042],
              [57.374573, 70.794906],
              [57.419142, 70.8002],
              [57.461966, 70.784541],
              [57.465672, 70.774054],
              [57.53351, 70.75483],
              [57.61269, 70.7091],
              [57.54941, 70.71287],
              [57.51572, 70.69585],
              [57.53473, 70.6843],
              [57.52282, 70.67181],
              [57.46496, 70.65535],
              [57.48598, 70.64789],
              [57.4792, 70.62184],
              [57.44814, 70.60965],
              [57.46544, 70.59287],
              [57.4257, 70.60344],
              [57.40856, 70.5921],
              [57.34793, 70.59683],
              [57.279507, 70.613617],
              [57.192254, 70.623556],
              [57.151695, 70.61112],
              [57.199129, 70.584614],
              [57.253339, 70.56845],
              [57.30512, 70.56138],
              [57.31483, 70.54476],
              [57.39184, 70.52238],
              [57.47889, 70.51169],
              [57.42062, 70.50724],
              [57.38258, 70.51882],
              [57.30695, 70.53046],
              [57.3194, 70.54258],
              [57.27188, 70.54303],
              [57.24873, 70.5308],
              [57.27236, 70.52183],
              [57.24319, 70.51676],
              [57.22612, 70.53058],
              [57.17985, 70.54009],
              [57.17534, 70.55031],
              [57.10327, 70.55216],
              [57.095904, 70.557078],
              [57.0074, 70.56138],
              [57.00663, 70.55283],
              [57.123774, 70.532509],
              [57.16444, 70.52963],
              [57.21093, 70.50607],
              [57.1901, 70.48871],
              [57.10304, 70.50794],
              [57.067642, 70.511742],
              [57.05252, 70.50286],
              [57.13345, 70.47752],
              [57.12268, 70.46501],
              [57.07231, 70.47551],
              [57.03792, 70.47041],
              [57.02343, 70.49086],
              [56.95001, 70.49776],
              [56.86328, 70.53962],
              [56.907628, 70.550541],
              [56.8831, 70.56237],
              [56.8432, 70.56094],
              [56.8379, 70.55161],
              [56.80486, 70.55771],
              [56.82087, 70.56948],
              [56.80995, 70.58812],
              [56.84679, 70.59352],
              [56.79046, 70.61467],
              [56.74586, 70.61309],
              [56.664559, 70.64479],
              [56.608912, 70.652285],
              [56.601562, 70.640082],
              [56.642574, 70.631507],
              [56.74886, 70.58882],
              [56.76915, 70.59446],
              [56.74318, 70.58178],
              [56.7085, 70.59003],
              [56.7064, 70.57623],
              [56.73489, 70.57014],
              [56.659292, 70.574664],
              [56.63686, 70.55668],
              [56.64849, 70.55031],
              [56.59802, 70.54306],
              [56.53336, 70.52093],
              [56.4776, 70.51541],
              [56.42569, 70.53723],
              [56.39188, 70.54019],
              [56.33737, 70.56606],
              [56.35558, 70.57277],
              [56.29448, 70.58533],
              [56.24772, 70.60328],
              [56.24691, 70.61478],
              [56.1675, 70.62798],
              [56.1502, 70.63659],
              [56.043099, 70.645999],
              [56.053282, 70.62988],
              [56.102279, 70.631262],
              [56.17756, 70.60784],
              [56.18069, 70.58766],
              [56.15256, 70.58038],
              [56.112962, 70.598544],
              [56.07156, 70.60477],
              [55.995204, 70.630118],
              [55.934318, 70.642942],
              [55.900326, 70.659208],
              [55.848315, 70.665323],
              [55.836002, 70.659863],
              [55.909813, 70.627374],
              [55.969419, 70.616377],
              [56.03881, 70.59796],
              [56.00156, 70.57399],
              [56.01698, 70.55695],
              [55.93132, 70.58076],
              [55.90352, 70.581675],
              [55.88831, 70.56669],
              [55.82705, 70.59059],
              [55.82847, 70.60593],
              [55.803672, 70.607996],
              [55.74705, 70.59159],
              [55.70263, 70.60125],
              [55.66228, 70.61834],
              [55.6376, 70.61904],
              [55.61036, 70.63457],
              [55.635989, 70.642994],
              [55.56709, 70.669],
              [55.545616, 70.681599],
              [55.514646, 70.682636],
              [55.458333, 70.702622],
              [55.471193, 70.716655],
              [55.430754, 70.732601],
              [55.4117, 70.71181],
              [55.35866, 70.71735],
              [55.34336, 70.70598],
              [55.363822, 70.690394],
              [55.40692, 70.68429],
              [55.43584, 70.66997],
              [55.49613, 70.62727],
              [55.42596, 70.61876],
              [55.43121, 70.64028],
              [55.34868, 70.66],
              [55.3081, 70.68404],
              [55.27129, 70.68102],
              [55.272, 70.65929],
              [55.23512, 70.66201],
              [55.22999, 70.6502],
              [55.25727, 70.62899],
              [55.31895, 70.60042],
              [55.28822, 70.59093],
              [55.27198, 70.57417],
              [55.30032, 70.55679],
              [55.24561, 70.57264],
              [55.19872, 70.56886],
              [55.20378, 70.54641],
              [55.16861, 70.54516],
              [54.99395, 70.60481],
              [54.98471, 70.62031],
              [54.91319, 70.62692],
              [54.88493, 70.63938],
              [54.82234, 70.64082],
              [54.81694, 70.64804],
              [54.743983, 70.671433],
              [54.735154, 70.686424],
              [54.69676, 70.70768],
              [54.69354, 70.72259],
              [54.72588, 70.73055],
              [54.696321, 70.743564],
              [54.63903, 70.74479],
              [54.580294, 70.765887],
              [54.56209, 70.74659],
              [54.520071, 70.764852],
              [54.50006, 70.75932],
              [54.512231, 70.743744],
              [54.475864, 70.733621],
              [54.507758, 70.712692],
              [54.551956, 70.710257],
              [54.573541, 70.694364],
              [54.65166, 70.68333],
              [54.660808, 70.663932],
              [54.73094, 70.6585],
              [54.77319, 70.64624],
              [54.78071, 70.63393],
              [54.67428, 70.64807],
              [54.65021, 70.64291],
              [54.52856, 70.67302],
              [54.56086, 70.69314],
              [54.46042, 70.70507],
              [54.42523, 70.72018],
              [54.33585, 70.74039],
              [54.341854, 70.749529],
              [54.28546, 70.76344],
              [54.24981, 70.75473],
              [54.294663, 70.729981],
              [54.26288, 70.73011],
              [54.23312, 70.7156],
              [54.19655, 70.72809],
              [54.09522, 70.74058],
              [54.0863, 70.73533],
              [54.1238, 70.72236],
              [54.10714, 70.7198],
              [54.0031, 70.73207],
              [53.94535, 70.7755],
              [53.90709, 70.77691],
              [53.89956, 70.76041],
              [53.83443, 70.77977],
              [53.7973, 70.77401],
              [53.73367, 70.78799],
              [53.69204, 70.7907],
              [53.68944, 70.80639],
              [53.64804, 70.80743],
              [53.55785, 70.82074],
              [53.55212, 70.80438],
              [53.48591, 70.80057],
              [53.451809, 70.826393],
              [53.40082, 70.8368],
              [53.35884, 70.86183],
              [53.40458, 70.85478],
              [53.46997, 70.86859],
              [53.51507, 70.85614],
              [53.54709, 70.85585],
              [53.57632, 70.87623],
              [53.54177, 70.88646],
              [53.47766, 70.91762],
              [53.55972, 70.89102],
              [53.56238, 70.88467],
              [53.634831, 70.875162],
              [53.652586, 70.856427],
              [53.711056, 70.816973],
              [53.753438, 70.811978],
              [53.699346, 70.85345],
              [53.683097, 70.877607],
              [53.727306, 70.892807],
              [53.781894, 70.931572],
              [53.723485, 70.976091],
              [53.563127, 71.034898],
              [53.530824, 71.056394],
              [53.578153, 71.058776],
              [53.611223, 71.053757],
              [53.661356, 71.068005],
              [53.69828, 71.07214],
              [53.702858, 71.083865],
              [53.79491, 71.056751],
              [53.822568, 71.054261],
              [53.932881, 71.02194],
              [53.969758, 71.032351],
              [53.91744, 71.054163],
              [53.912136, 71.071194],
              [53.876412, 71.078851],
              [53.856942, 71.098557],
              [53.816081, 71.113755],
              [53.884779, 71.098576],
              [53.919395, 71.118649],
              [54.02626, 71.114689],
              [54.101498, 71.094396],
              [54.12331, 71.098473],
              [54.011921, 71.136556],
              [54.06396, 71.14605],
              [54.07998, 71.17648],
              [53.991992, 71.175325],
              [53.993211, 71.164344],
              [53.95139, 71.15269],
              [53.902679, 71.163447],
              [53.849199, 71.184925],
              [53.832891, 71.178201],
              [53.848592, 71.150959],
              [53.83809, 71.13391],
              [53.724157, 71.155576],
              [53.74888, 71.16642],
              [53.717242, 71.17688],
              [53.68086, 71.1752],
              [53.65063, 71.19437],
              [53.599493, 71.183307],
              [53.552448, 71.196494],
              [53.518476, 71.225398],
              [53.463672, 71.256709],
              [53.484167, 71.259814],
              [53.42787, 71.28009],
              [53.384113, 71.272004],
              [53.32352, 71.28754],
              [53.296652, 71.284253],
              [53.22438, 71.32244],
              [53.22164, 71.33237],
              [53.15849, 71.3526],
              [53.21686, 71.35685],
              [53.21371, 71.36881],
              [53.16102, 71.36801],
              [53.1807, 71.36764],
              [53.15445, 71.39296],
              [53.16392, 71.39775],
              [53.13555, 71.42124],
              [53.06691, 71.42905],
              [53.02758, 71.42695],
              [52.98849, 71.4507],
              [52.9564, 71.45796],
              [52.93661, 71.44883],
              [52.95856, 71.42856],
              [53.02909, 71.40214],
              [53.07647, 71.40185],
              [53.12126, 71.3846],
              [53.10529, 71.37045],
              [53.0311, 71.40014],
              [52.95834, 71.38759],
              [52.96898, 71.37117],
              [52.91159, 71.3812],
              [52.91929, 71.39404],
              [52.9055, 71.41743],
              [52.8759, 71.4319],
              [52.93562, 71.43831],
              [52.87773, 71.45524],
              [52.79908, 71.45249],
              [52.86031, 71.4185],
              [52.85692, 71.40997],
              [52.79998, 71.42997],
              [52.76049, 71.43421],
              [52.7389, 71.46446],
              [52.69965, 71.47254],
              [52.66935, 71.44624],
              [52.63623, 71.45393],
              [52.61433, 71.46879],
              [52.65237, 71.47325],
              [52.65645, 71.49614],
              [52.63211, 71.49327],
              [52.59731, 71.50783],
              [52.66038, 71.51441],
              [52.64904, 71.5039],
              [52.66987, 71.4903],
              [52.69743, 71.5004],
              [52.69462, 71.52006],
              [52.71867, 71.52265],
              [52.69767, 71.53882],
              [52.67481, 71.53446],
              [52.63962, 71.54698],
              [52.58295, 71.60151],
              [52.541409, 71.600062],
              [52.52132, 71.57906],
              [52.53585, 71.5689],
              [52.49812, 71.55213],
              [52.49185, 71.53072],
              [52.43282, 71.54443],
              [52.41284, 71.54344],
              [52.40299, 71.52219],
              [52.40803, 71.49951],
              [52.45382, 71.48678],
              [52.45567, 71.46198],
              [52.43734, 71.4764],
              [52.39569, 71.48039],
              [52.33261, 71.51433],
              [52.31113, 71.53276],
              [52.32076, 71.53966],
              [52.27191, 71.56061],
              [52.19641, 71.53092],
              [52.21745, 71.5066],
              [52.30197, 71.46156],
              [52.21937, 71.47308],
              [52.15454, 71.4713],
              [52.11383, 71.45398],
              [52.00468, 71.46498],
              [51.94997, 71.46468],
              [51.91902, 71.4568],
              [51.887932, 71.463567],
              [51.80115, 71.50232],
              [51.76227, 71.51458],
              [51.69472, 71.52501],
              [51.65177, 71.52534],
              [51.62047, 71.536],
              [51.60715, 71.56024],
              [51.58227, 71.57081],
              [51.59675, 71.60405],
              [51.50523, 71.68889],
              [51.52607, 71.70282],
              [51.49386, 71.71113],
              [51.46849, 71.74477],
              [51.47755, 71.76273],
              [51.44268, 71.78096],
              [51.43539, 71.84025],
              [51.46613, 71.86704],
              [51.50689, 71.93719],
              [51.50689, 71.96105],
              [51.52714, 71.98085],
              [51.56531, 72.06142],
              [51.63706, 72.08085],
              [51.64763, 72.09037],
              [51.71989, 72.10285],
              [51.73828, 72.11437],
              [51.80901, 72.12985],
              [51.82477, 72.15103],
              [51.86189, 72.15278],
              [51.90259, 72.13257],
              [51.91969, 72.10841],
              [52.00628, 72.10116],
              [52.05591, 72.09319],
              [52.110518, 72.093948],
              [52.14064, 72.10992],
              [52.18579, 72.11258],
              [52.20456, 72.13021],
              [52.2353, 72.11943],
              [52.222, 72.10476],
              [52.24811, 72.09233],
              [52.27686, 72.09226],
              [52.31054, 72.06617],
              [52.38884, 72.06393],
              [52.39864, 72.07135],
              [52.32767, 72.08067],
              [52.32671, 72.09269],
              [52.37007, 72.09577],
              [52.40697, 72.1115],
              [52.37669, 72.1259],
              [52.40778, 72.13842],
              [52.37037, 72.14112],
              [52.36711, 72.15744],
              [52.38992, 72.1631],
              [52.38375, 72.17935],
              [52.41143, 72.16566],
              [52.43863, 72.16552],
              [52.4738, 72.19247],
              [52.43176, 72.19994],
              [52.45053, 72.21882],
              [52.47514, 72.20402],
              [52.49144, 72.21951],
              [52.53142, 72.22401],
              [52.47298, 72.24675],
              [52.45775, 72.27433],
              [52.55284, 72.2601],
              [52.56593, 72.25116],
              [52.62516, 72.25455],
              [52.59977, 72.28856],
              [52.64834, 72.28246],
              [52.71845, 72.29732],
              [52.67923, 72.31362],
              [52.69354, 72.32264],
              [52.66075, 72.33652],
              [52.60067, 72.34774],
              [52.58016, 72.33832],
              [52.60035, 72.3211],
              [52.57567, 72.32209],
              [52.52794, 72.33791],
              [52.54025, 72.35663],
              [52.58424, 72.36325],
              [52.62617, 72.35753],
              [52.68532, 72.36804],
              [52.61234, 72.37284],
              [52.63032, 72.39655],
              [52.69363, 72.40075],
              [52.72034, 72.38801],
              [52.74019, 72.39894],
              [52.71108, 72.40932],
              [52.70764, 72.42743],
              [52.73932, 72.42248],
              [52.77589, 72.44374],
              [52.73128, 72.45303],
              [52.70793, 72.44408],
              [52.68717, 72.45823],
              [52.7255, 72.47749],
              [52.7784, 72.49117],
              [52.82362, 72.49059],
              [52.86968, 72.49744],
              [52.87504, 72.51496],
              [52.92746, 72.52602],
              [52.9563, 72.53992],
              [52.94429, 72.5535],
              [52.90168, 72.55135],
              [52.88166, 72.53071],
              [52.85907, 72.53255],
              [52.92014, 72.56402],
              [52.9867, 72.56613],
              [52.96318, 72.57769],
              [52.91997, 72.57605],
              [52.85414, 72.56138],
              [52.83581, 72.55204],
              [52.75901, 72.53749],
              [52.72868, 72.53953],
              [52.74047, 72.56898],
              [52.78039, 72.57725],
              [52.8004, 72.60865],
              [52.69196, 72.61455],
              [52.8011, 72.64401],
              [52.73801, 72.65497],
              [52.64471, 72.6609],
              [52.55141, 72.65963],
              [52.50295, 72.69518],
              [52.46489, 72.70944],
              [52.42465, 72.7052],
              [52.40503, 72.71781],
              [52.42628, 72.73572],
              [52.46006, 72.7352],
              [52.4895, 72.74662],
              [52.48877, 72.76479],
              [52.51748, 72.79821],
              [52.54273, 72.80108],
              [52.58403, 72.8368],
              [52.61426, 72.8513],
              [52.74737, 72.87167],
              [52.79023, 72.8814],
              [52.87356, 72.88938],
              [52.95466, 72.90548],
              [53.03956, 72.89371],
              [53.1079, 72.89541],
              [53.25233, 72.87061],
              [53.31852, 72.86555],
              [53.42171, 72.86612],
              [53.49793, 72.85823],
              [53.55432, 72.87608],
              [53.42702, 72.87849],
              [53.40508, 72.88809],
              [53.34587, 72.89279],
              [53.27503, 72.88651],
              [53.24076, 72.89638],
              [53.37181, 72.90618],
              [53.39835, 72.91253],
              [53.3696, 72.92112],
              [53.30461, 72.91124],
              [53.20617, 72.9116],
              [53.13818, 72.92786],
              [53.1164, 72.92783],
              [53.08982, 72.94549],
              [53.12048, 72.98831],
              [53.14964, 72.99885],
              [53.21424, 72.99954],
              [53.23568, 73.00966],
              [53.28144, 72.99617],
              [53.40117, 72.99242],
              [53.42422, 73.00793],
              [53.30087, 73.01597],
              [53.31949, 73.0419],
              [53.27628, 73.04509],
              [53.28146, 73.0599],
              [53.22157, 73.05471],
              [53.19246, 73.05941],
              [53.20584, 73.08666],
              [53.17488, 73.1035],
              [53.207741, 73.109452],
              [53.18756, 73.12395],
              [53.19871, 73.13542],
              [53.2791, 73.17169],
              [53.33252, 73.18647],
              [53.42436, 73.22979],
              [53.51224, 73.23794],
              [53.53461, 73.22808],
              [53.6107, 73.2287],
              [53.68518, 73.24923],
              [53.70203, 73.26547],
              [53.76953, 73.27959],
              [53.83138, 73.28157],
              [53.90485, 73.29304],
              [53.94126, 73.29332],
              [53.97188, 73.27599],
              [54.01422, 73.27776],
              [54.07123, 73.26118],
              [54.13219, 73.26817],
              [54.23245, 73.25751],
              [54.26883, 73.2631],
              [54.29877, 73.28152],
              [54.35478, 73.29286],
              [54.3816, 73.31519],
              [54.42769, 73.3224],
              [54.47303, 73.34112],
              [54.54076, 73.35984],
              [54.65702, 73.37753],
              [54.55675, 73.39159],
              [54.50111, 73.37776],
              [54.45392, 73.35572],
              [54.42552, 73.35474],
              [54.37407, 73.32955],
              [54.28366, 73.31534],
              [54.2134, 73.32042],
              [54.19608, 73.33924],
              [54.09104, 73.34355],
              [54.07506, 73.36404],
              [54.13543, 73.38739],
              [54.16411, 73.39238],
              [54.24694, 73.38889],
              [54.27195, 73.40021],
              [54.30771, 73.39466],
              [54.31451, 73.41406],
              [54.35073, 73.42296],
              [54.38877, 73.44364],
              [54.48568, 73.46144],
              [54.44283, 73.47179],
              [54.34311, 73.45457],
              [54.30683, 73.47405],
              [54.24529, 73.48783],
              [54.3047, 73.50164],
              [54.38839, 73.55716],
              [54.55195, 73.58725],
              [54.6466, 73.6089],
              [54.69686, 73.61214],
              [54.72078, 73.63617],
              [54.8179, 73.64947],
              [54.87039, 73.64347],
              [54.93123, 73.65035],
              [55.029815, 73.676463],
              [55.086477, 73.68171],
              [55.109993, 73.690867],
              [55.208588, 73.696066],
              [55.249121, 73.691292],
              [55.26437, 73.702151],
              [55.203474, 73.709823],
              [55.030736, 73.699603],
              [54.99785, 73.70057],
              [54.90597, 73.68563],
              [54.88483, 73.66932],
              [54.8242, 73.671],
              [54.83326, 73.70279],
              [54.77953, 73.68919],
              [54.70717, 73.68281],
              [54.59841, 73.69286],
              [54.51809, 73.68222],
              [54.44739, 73.65963],
              [54.39041, 73.62597],
              [54.3548, 73.61606],
              [54.28443, 73.61415],
              [54.2722, 73.60018],
              [54.22288, 73.60286],
              [54.15529, 73.61705],
              [54.09289, 73.60585],
              [53.9995, 73.62168],
              [53.9988, 73.64597],
              [53.94746, 73.64868],
              [53.89892, 73.6837],
              [53.84365, 73.69858],
              [53.79341, 73.70409],
              [53.68255, 73.73015],
              [53.67823, 73.75873],
              [53.70906, 73.79016],
              [53.76242, 73.7654],
              [53.84322, 73.75881],
              [53.94446, 73.76502],
              [54.0392, 73.78758],
              [54.05422, 73.8062],
              [54.0892, 73.81845],
              [54.05577, 73.82336],
              [54.08844, 73.8463],
              [54.11476, 73.84821],
              [54.26219, 73.90128],
              [54.39871, 73.92394],
              [54.49021, 73.92912],
              [54.51887, 73.93495],
              [54.59928, 73.93446],
              [54.66406, 73.94642],
              [54.67255, 73.95827],
              [54.73618, 73.95281],
              [54.79033, 73.95568],
              [54.80678, 73.97093],
              [54.74398, 73.98357],
              [54.66349, 73.9831],
              [54.57796, 73.99788],
              [54.614679, 74.025895],
              [54.682789, 74.043252],
              [54.729577, 74.067777],
              [54.861073, 74.108477],
              [54.97037, 74.137385],
              [55.008987, 74.142598],
              [55.027892, 74.156316],
              [55.070503, 74.144174],
              [55.179798, 74.161726],
              [55.299879, 74.132157],
              [55.442422, 74.116613],
              [55.550189, 74.122086],
              [55.614933, 74.107176],
            ],
          ],
          [
            [
              [59.152429, 74.494821],
              [59.157384, 74.499629],
              [59.205936, 74.511933],
              [59.182677, 74.534145],
              [59.22767, 74.557727],
              [59.271697, 74.570319],
              [59.265018, 74.543803],
              [59.240804, 74.53868],
              [59.228507, 74.509152],
              [59.189219, 74.505197],
              [59.231553, 74.483176],
              [59.318948, 74.486499],
              [59.253339, 74.47516],
              [59.189788, 74.478097],
              [59.152429, 74.494821],
            ],
          ],
          [
            [
              [58.735307, 75.77755],
              [58.713644, 75.761352],
              [58.584915, 75.752144],
              [58.664775, 75.778944],
              [58.735307, 75.77755],
            ],
          ],
          [
            [
              [59.258708, 75.927196],
              [59.222163, 75.928683],
              [59.11027, 75.918054],
              [58.923016, 75.891756],
              [58.805945, 75.885758],
              [58.796248, 75.900396],
              [58.86769, 75.909566],
              [58.953038, 75.912215],
              [59.029773, 75.92053],
              [59.04642, 75.93419],
              [59.170973, 75.95151],
              [59.272999, 75.957734],
              [59.29271, 75.942725],
              [59.258708, 75.927196],
            ],
          ],
          [
            [
              [59.514151, 76.029749],
              [59.54121, 76.027168],
              [59.687311, 76.044268],
              [59.724988, 76.041806],
              [59.623364, 76.019269],
              [59.520301, 76.016649],
              [59.514151, 76.029749],
            ],
          ],
          [
            [
              [62.95028, 76.22861],
              [63.01304, 76.23809],
              [62.97292, 76.22107],
              [62.95028, 76.22861],
            ],
          ],
          [
            [
              [59.514151, 76.029749],
              [59.454149, 76.019368],
              [59.380867, 76.017537],
              [59.439596, 76.045587],
              [59.495091, 76.047839],
              [59.514151, 76.029749],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5490",
      properties: {
        name: "Астраханская область",
        density: 0,
        path: "/world/Russia/Астраханская область",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [47.525402, 45.516259],
              [47.508319, 45.539921],
              [47.49693, 45.576476],
              [47.507761, 45.584519],
              [47.504336, 45.600128],
              [47.493027, 45.607853],
              [47.504505, 45.620333],
              [47.536615, 45.618315],
              [47.552315, 45.602351],
              [47.544852, 45.574284],
              [47.52646, 45.543531],
              [47.536804, 45.522733],
              [47.525402, 45.516259],
            ],
          ],
          [
            [
              [46.442228, 45.445634],
              [46.450469, 45.47927],
              [46.64226, 45.503998],
              [46.657629, 45.548772],
              [46.730693, 45.555679],
              [46.772793, 45.553006],
              [46.775467, 45.52984],
              [46.887071, 45.527964],
              [46.857051, 45.453028],
              [46.832125, 45.453183],
              [46.796407, 45.475933],
              [46.751856, 45.479719],
              [46.660304, 45.477268],
              [46.641147, 45.480164],
              [46.623328, 45.43539],
              [46.578109, 45.425811],
              [46.57744, 45.440067],
              [46.499031, 45.440957],
              [46.442228, 45.445634],
            ],
          ],
          [
            [
              [44.959474, 48.270909],
              [44.981943, 48.29281],
              [44.991387, 48.359386],
              [45.025531, 48.357298],
              [45.014083, 48.425031],
              [45.05606, 48.427318],
              [45.082572, 48.449721],
              [45.06461, 48.461715],
              [45.085938, 48.471348],
              [45.124409, 48.480935],
              [45.177353, 48.471787],
              [45.185557, 48.493682],
              [45.174021, 48.511584],
              [45.212676, 48.514505],
              [45.212593, 48.490388],
              [45.200538, 48.485466],
              [45.204895, 48.473888],
              [45.219277, 48.482343],
              [45.244288, 48.483348],
              [45.270262, 48.470836],
              [45.28686, 48.455721],
              [45.307137, 48.446939],
              [45.332175, 48.44538],
              [45.333911, 48.433358],
              [45.347248, 48.438311],
              [45.348248, 48.456768],
              [45.382485, 48.47359],
              [45.394851, 48.484675],
              [45.424031, 48.495571],
              [45.369902, 48.502478],
              [45.337435, 48.495684],
              [45.353543, 48.47443],
              [45.329391, 48.471901],
              [45.318747, 48.491245],
              [45.326687, 48.504723],
              [45.301327, 48.504921],
              [45.298722, 48.512741],
              [45.268754, 48.515856],
              [45.266611, 48.522063],
              [45.228597, 48.51517],
              [45.224142, 48.533315],
              [45.201349, 48.536606],
              [45.224644, 48.557904],
              [45.247374, 48.553742],
              [45.245269, 48.5661],
              [45.264261, 48.565497],
              [45.300224, 48.572976],
              [45.305906, 48.558102],
              [45.326594, 48.549082],
              [45.385383, 48.561751],
              [45.392379, 48.569456],
              [45.422035, 48.570207],
              [45.433419, 48.58948],
              [45.447246, 48.582962],
              [45.478498, 48.586681],
              [45.495775, 48.572062],
              [45.511033, 48.576535],
              [45.526002, 48.593082],
              [45.544962, 48.593798],
              [45.560635, 48.583249],
              [45.58457, 48.605118],
              [45.613916, 48.612015],
              [45.685833, 48.643576],
              [45.715611, 48.649088],
              [45.916585, 48.770478],
              [45.954699, 48.840775],
              [45.987327, 48.880416],
              [46.019104, 48.899223],
              [46.060027, 48.905198],
              [46.329392, 48.862662],
              [46.319338, 48.851504],
              [46.43614, 48.789339],
              [46.44537, 48.795032],
              [46.627097, 48.679148],
              [46.4923, 48.4372],
              [46.4936, 48.4336],
              [46.6314, 48.391],
              [46.68, 48.3894],
              [46.7237, 48.3637],
              [46.7449, 48.355],
              [46.8496, 48.3374],
              [46.8884, 48.3286],
              [46.9067, 48.3133],
              [46.9227, 48.3085],
              [46.9499, 48.3151],
              [47.006, 48.3017],
              [47.1246, 48.2697],
              [47.1118, 48.2527],
              [47.1012, 48.2231],
              [47.1241, 48.1597],
              [47.1025, 48.1237],
              [47.0963, 48.1037],
              [47.2123, 48.0844],
              [47.1977, 48.0576],
              [47.1605, 48.065],
              [47.1364, 48.0358],
              [47.1038, 48.0237],
              [47.0521, 47.9903],
              [47.0965, 47.9294],
              [47.1132, 47.9111],
              [47.1763, 47.8526],
              [47.1298, 47.827],
              [47.1527, 47.7978],
              [47.1952, 47.7611],
              [47.2696, 47.7253],
              [47.3561, 47.6915],
              [47.3863, 47.6819],
              [47.4164, 47.7844],
              [47.4145, 47.8317],
              [47.5436, 47.7937],
              [47.6145, 47.7696],
              [47.6712, 47.7563],
              [47.7337, 47.7539],
              [47.8603, 47.7517],
              [47.9751, 47.7524],
              [48.0275, 47.7652],
              [48.1166, 47.7504],
              [48.2104, 47.6932],
              [48.3004, 47.5742],
              [48.3791, 47.4887],
              [48.4462, 47.4191],
              [48.5168, 47.4124],
              [48.5327, 47.3823],
              [48.6017, 47.2631],
              [48.701, 47.1061],
              [48.7206, 47.0786],
              [48.7989, 47.0008],
              [48.8799, 46.9133],
              [48.9849, 46.81],
              [49.018, 46.7629],
              [48.9185, 46.6954],
              [48.7502, 46.6867],
              [48.7068, 46.7235],
              [48.7006, 46.7478],
              [48.6563, 46.774],
              [48.5949, 46.77],
              [48.5651, 46.7622],
              [48.514, 46.7365],
              [48.5056, 46.7196],
              [48.5087, 46.68],
              [48.4842, 46.6732],
              [48.4861, 46.6595],
              [48.5153, 46.6511],
              [48.5468, 46.6474],
              [48.5562, 46.6407],
              [48.5506, 46.6213],
              [48.572, 46.6103],
              [48.6217, 46.6066],
              [48.6065, 46.5965],
              [48.5755, 46.5903],
              [48.5487, 46.5763],
              [48.5625, 46.5596],
              [48.642, 46.5604],
              [48.6423, 46.5533],
              [48.6698, 46.551],
              [48.7074, 46.5554],
              [48.722, 46.5494],
              [48.748379, 46.550006],
              [48.762798, 46.54127],
              [48.787066, 46.541891],
              [48.787645, 46.526284],
              [48.817043, 46.492128],
              [48.837191, 46.47839],
              [48.856757, 46.479277],
              [48.8951, 46.4556],
              [48.9308, 46.4427],
              [48.9922, 46.4347],
              [49.028, 46.4174],
              [49.0508, 46.3982],
              [49.1015, 46.3989],
              [49.1346, 46.386],
              [49.1542, 46.3854],
              [49.317983, 46.275273],
              [49.296798, 46.261711],
              [49.275671, 46.237426],
              [49.336408, 46.199731],
              [49.338236, 46.162651],
              [49.325705, 46.136539],
              [49.313585, 46.14008],
              [49.295916, 46.157421],
              [49.263155, 46.168639],
              [49.247631, 46.154911],
              [49.267573, 46.12452],
              [49.320579, 46.065043],
              [49.30413, 46.079245],
              [49.29824, 46.071891],
              [49.277037, 46.080879],
              [49.265368, 46.101288],
              [49.249826, 46.102457],
              [49.239964, 46.090247],
              [49.232729, 46.055958],
              [49.235718, 46.041371],
              [49.252796, 46.021487],
              [49.234836, 46.010724],
              [49.230768, 45.997942],
              [49.216078, 45.990972],
              [49.184425, 46.00388],
              [49.170866, 46.016343],
              [49.151975, 46.018867],
              [49.126502, 46.043347],
              [49.123176, 46.057319],
              [49.09978, 46.060614],
              [49.099319, 46.0734],
              [49.063068, 46.118371],
              [49.038812, 46.108184],
              [49.02823, 46.117954],
              [49.016532, 46.087692],
              [49.029646, 46.075222],
              [49.019213, 46.043003],
              [49.027553, 46.008741],
              [49.02054, 46.006533],
              [49.019815, 45.964197],
              [49.031608, 45.925628],
              [48.995564, 45.954462],
              [48.972098, 45.981666],
              [48.965545, 45.97697],
              [48.93009, 45.978582],
              [48.926467, 45.968587],
              [48.932957, 45.92175],
              [48.907097, 45.909931],
              [48.891535, 45.920761],
              [48.885896, 45.911895],
              [48.868753, 45.914876],
              [48.863227, 45.899574],
              [48.866046, 45.87987],
              [48.859956, 45.84329],
              [48.845013, 45.831013],
              [48.841911, 45.815884],
              [48.832505, 45.833105],
              [48.833333, 45.880631],
              [48.813249, 45.914426],
              [48.799037, 45.913147],
              [48.810303, 45.895161],
              [48.786446, 45.883629],
              [48.772031, 45.901503],
              [48.745523, 45.919256],
              [48.73873, 45.913838],
              [48.74983, 45.870249],
              [48.768055, 45.847403],
              [48.772529, 45.812192],
              [48.760434, 45.808381],
              [48.772363, 45.789438],
              [48.783298, 45.786319],
              [48.775345, 45.774071],
              [48.760931, 45.775457],
              [48.755132, 45.761473],
              [48.740718, 45.75396],
              [48.750493, 45.73546],
              [48.729783, 45.724358],
              [48.753088, 45.700346],
              [48.746185, 45.680504],
              [48.747014, 45.643914],
              [48.729629, 45.617089],
              [48.681239, 45.602548],
              [48.656884, 45.612052],
              [48.636008, 45.644609],
              [48.639688, 45.615129],
              [48.618115, 45.620396],
              [48.588624, 45.610198],
              [48.571559, 45.623872],
              [48.572884, 45.670317],
              [48.561784, 45.689648],
              [48.542565, 45.677147],
              [48.525169, 45.682935],
              [48.528151, 45.652717],
              [48.486556, 45.653817],
              [48.331374, 45.664136],
              [48.26894, 45.698057],
              [48.196156, 45.684867],
              [48.087666, 45.634476],
              [48.038227, 45.664476],
              [47.964154, 45.67287],
              [47.9348, 45.698773],
              [47.891283, 45.696838],
              [47.875342, 45.682159],
              [47.873102, 45.664006],
              [47.861904, 45.659624],
              [47.855714, 45.688465],
              [47.845332, 45.657746],
              [47.834582, 45.647414],
              [47.8397, 45.631473],
              [47.859956, 45.634954],
              [47.909051, 45.618627],
              [47.895146, 45.608541],
              [47.908536, 45.55676],
              [47.903214, 45.526702],
              [47.908021, 45.513472],
              [47.903214, 45.489529],
              [47.909394, 45.476651],
              [47.882443, 45.464974],
              [47.854462, 45.478817],
              [47.844334, 45.495305],
              [47.841931, 45.524778],
              [47.834893, 45.544017],
              [47.806054, 45.583797],
              [47.794553, 45.562529],
              [47.76022, 45.557961],
              [47.768803, 45.517682],
              [47.762624, 45.487724],
              [47.769061, 45.460068],
              [47.754904, 45.481554],
              [47.731725, 45.49338],
              [47.726403, 45.515998],
              [47.744256, 45.525018],
              [47.716447, 45.522974],
              [47.71473, 45.535721],
              [47.728972, 45.553813],
              [47.703959, 45.552546],
              [47.697564, 45.613104],
              [47.678853, 45.62523],
              [47.665978, 45.648516],
              [47.65173, 45.643715],
              [47.652548, 45.609244],
              [47.637023, 45.591249],
              [47.602443, 45.581712],
              [47.609751, 45.598028],
              [47.587484, 45.611576],
              [47.589568, 45.626994],
              [47.562947, 45.638065],
              [47.55738, 45.649986],
              [47.536153, 45.655581],
              [47.525888, 45.670415],
              [47.518467, 45.657173],
              [47.491221, 45.643815],
              [47.440408, 45.649534],
              [47.42655, 45.657673],
              [47.401033, 45.655033],
              [47.387835, 45.667791],
              [47.294348, 45.670429],
              [47.288849, 45.646672],
              [47.270811, 45.649092],
              [47.243535, 45.640293],
              [47.21186, 45.618515],
              [47.155109, 45.610376],
              [47.115734, 45.610815],
              [47.016968, 45.617193],
              [46.970299, 45.621809],
              [46.947678, 45.596515],
              [46.911606, 45.591167],
              [46.853971, 45.571657],
              [46.813717, 45.567917],
              [46.770383, 45.581115],
              [46.752564, 45.61301],
              [46.685254, 45.61301],
              [46.677555, 45.617629],
              [46.695152, 45.646005],
              [46.549751, 45.67284],
              [46.501358, 45.680098],
              [46.49082, 45.690055],
              [46.544944, 45.760209],
              [46.550585, 45.799342],
              [46.400368, 45.819715],
              [46.287833, 45.919122],
              [46.295463, 45.965225],
              [46.460982, 45.972329],
              [46.462825, 46.042914],
              [46.449872, 46.17173],
              [46.502882, 46.280836],
              [46.414014, 46.304592],
              [46.409834, 46.31735],
              [46.50222, 46.374543],
              [46.558972, 46.351447],
              [46.660156, 46.439436],
              [46.663456, 46.440756],
              [46.857248, 46.456596],
              [46.966353, 46.445819],
              [46.949854, 46.523028],
              [46.865823, 46.619374],
              [46.957767, 46.718362],
              [46.964859, 46.833021],
              [46.978936, 46.856998],
              [46.961775, 46.973802],
              [46.910962, 46.98491],
              [46.812525, 46.999756],
              [46.779308, 47.053539],
              [46.740043, 47.073335],
              [46.651172, 47.215875],
              [46.537442, 47.402958],
              [46.670632, 47.481379],
              [46.849366, 47.558483],
              [46.788752, 47.586746],
              [46.761806, 47.607203],
              [46.703184, 47.606652],
              [46.686796, 47.600822],
              [46.586382, 47.541869],
              [46.539199, 47.507883],
              [46.488827, 47.474887],
              [46.399411, 47.413074],
              [46.267429, 47.420222],
              [46.159093, 47.476423],
              [46.087272, 47.515247],
              [46.01468, 47.560119],
              [45.921851, 47.644367],
              [45.899852, 47.716297],
              [45.84068, 47.720255],
              [45.784475, 47.813522],
              [45.739711, 47.830349],
              [45.714082, 47.930545],
              [45.756974, 47.999506],
              [45.709239, 48.031621],
              [45.675186, 48.027125],
              [45.595708, 48.01152],
              [45.56428, 48.000053],
              [45.538544, 48.000163],
              [45.48597, 48.034698],
              [45.379503, 48.099367],
              [45.262919, 48.105305],
              [45.237731, 48.163267],
              [45.144133, 48.187352],
              [45.16327, 48.220678],
              [44.959474, 48.270909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5548",
      properties: {
        name: "Башкортостан",
        density: 0,
        path: "/world/Russia/Башкортостан",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.697341, 55.925082],
            [53.705725, 55.933043],
            [53.708927, 55.969034],
            [53.791142, 55.972595],
            [53.797727, 55.96779],
            [53.825844, 55.978912],
            [53.80173, 55.988299],
            [53.807139, 56.002246],
            [53.841463, 56.017905],
            [53.84536, 56.044852],
            [53.868356, 56.042278],
            [53.895782, 56.029451],
            [53.953994, 56.0145],
            [53.974165, 56.005389],
            [54.014881, 56.009474],
            [54.035782, 56.022081],
            [54.081744, 56.082758],
            [54.087819, 56.108145],
            [54.118574, 56.131969],
            [54.147795, 56.144855],
            [54.172247, 56.166315],
            [54.195177, 56.198227],
            [54.249867, 56.202387],
            [54.246823, 56.21398],
            [54.270565, 56.21469],
            [54.29654, 56.227602],
            [54.334995, 56.235009],
            [54.337126, 56.24267],
            [54.392322, 56.258322],
            [54.392625, 56.291526],
            [54.416149, 56.311239],
            [54.362895, 56.311971],
            [54.358961, 56.35295],
            [54.374587, 56.364619],
            [54.377579, 56.378702],
            [54.410856, 56.376841],
            [54.418623, 56.362086],
            [54.444573, 56.34606],
            [54.4575, 56.3571],
            [54.4466, 56.3748],
            [54.4627, 56.4003],
            [54.4864, 56.4107],
            [54.4979, 56.4325],
            [54.4937, 56.4467],
            [54.5025, 56.4604],
            [54.5248, 56.468],
            [54.5392, 56.4953],
            [54.5398, 56.5114],
            [54.577733, 56.534643],
            [54.60915, 56.531315],
            [54.603274, 56.513835],
            [54.61472, 56.504647],
            [54.630839, 56.509492],
            [54.646429, 56.500862],
            [54.682184, 56.501397],
            [54.679644, 56.4862],
            [54.707192, 56.464125],
            [54.733821, 56.430409],
            [54.787349, 56.436567],
            [54.788421, 56.426413],
            [54.863153, 56.402275],
            [54.867232, 56.393269],
            [54.899297, 56.390083],
            [54.929619, 56.380248],
            [54.937518, 56.370654],
            [54.943592, 56.337213],
            [54.978383, 56.334995],
            [54.989742, 56.325124],
            [55.021814, 56.324292],
            [55.021135, 56.340348],
            [55.039763, 56.344946],
            [55.116014, 56.3494],
            [55.136998, 56.341632],
            [55.150002, 56.307456],
            [55.196512, 56.307451],
            [55.201268, 56.324517],
            [55.187945, 56.358086],
            [55.206916, 56.37202],
            [55.238503, 56.344644],
            [55.280699, 56.320966],
            [55.331195, 56.316454],
            [55.368311, 56.323734],
            [55.38524, 56.317094],
            [55.42206, 56.331012],
            [55.459557, 56.331608],
            [55.506358, 56.359249],
            [55.522033, 56.380746],
            [55.627859, 56.400694],
            [55.633282, 56.395796],
            [55.691955, 56.408486],
            [55.707318, 56.435715],
            [55.739046, 56.430493],
            [55.774309, 56.445925],
            [55.789442, 56.435629],
            [55.83681, 56.42065],
            [55.874755, 56.432733],
            [55.867046, 56.442979],
            [55.879236, 56.456682],
            [55.908537, 56.456142],
            [55.919867, 56.449547],
            [55.935976, 56.413169],
            [55.945286, 56.408605],
            [55.941528, 56.388746],
            [55.952536, 56.373755],
            [56.017745, 56.339763],
            [56.042079, 56.339931],
            [56.051117, 56.314239],
            [56.081113, 56.290316],
            [56.124858, 56.30038],
            [56.122626, 56.310245],
            [56.162298, 56.317164],
            [56.218417, 56.316403],
            [56.235373, 56.320566],
            [56.267411, 56.305743],
            [56.285953, 56.304422],
            [56.283691, 56.314425],
            [56.303726, 56.333371],
            [56.321917, 56.341382],
            [56.324682, 56.365207],
            [56.377829, 56.365215],
            [56.362071, 56.388603],
            [56.40723, 56.40379],
            [56.460827, 56.365593],
            [56.483962, 56.388626],
            [56.507644, 56.376753],
            [56.532631, 56.397031],
            [56.572244, 56.389021],
            [56.532621, 56.361487],
            [56.535034, 56.350188],
            [56.506254, 56.344041],
            [56.538582, 56.313044],
            [56.578681, 56.2905],
            [56.597125, 56.274223],
            [56.594748, 56.262981],
            [56.6209, 56.264502],
            [56.651969, 56.25574],
            [56.652679, 56.232966],
            [56.725129, 56.230841],
            [56.758236, 56.219029],
            [56.746621, 56.209651],
            [56.768379, 56.193539],
            [56.783004, 56.164346],
            [56.85044, 56.166111],
            [56.86701, 56.147413],
            [56.883149, 56.110432],
            [56.910454, 56.110593],
            [56.921333, 56.103883],
            [56.970055, 56.125026],
            [57.007201, 56.136611],
            [57.045964, 56.126737],
            [57.044666, 56.150958],
            [57.064534, 56.159073],
            [57.114189, 56.159438],
            [57.144839, 56.165789],
            [57.167776, 56.162307],
            [57.185657, 56.171752],
            [57.203793, 56.169341],
            [57.204048, 56.188198],
            [57.23492, 56.19177],
            [57.227381, 56.199451],
            [57.200482, 56.19639],
            [57.216173, 56.208547],
            [57.195286, 56.250602],
            [57.219294, 56.249383],
            [57.248166, 56.271258],
            [57.298041, 56.272278],
            [57.285038, 56.304739],
            [57.306645, 56.310392],
            [57.297953, 56.32104],
            [57.324945, 56.314887],
            [57.353176, 56.320814],
            [57.348693, 56.331209],
            [57.390734, 56.331497],
            [57.406237, 56.34266],
            [57.432504, 56.313092],
            [57.412841, 56.293926],
            [57.4065, 56.27247],
            [57.420497, 56.249445],
            [57.452536, 56.240399],
            [57.468344, 56.213471],
            [57.50354, 56.203585],
            [57.50828, 56.19462],
            [57.49019, 56.173165],
            [57.493474, 56.164205],
            [57.473738, 56.13444],
            [57.480225, 56.122618],
            [57.51834, 56.105684],
            [57.521365, 56.096742],
            [57.598601, 56.085196],
            [57.61113, 56.095296],
            [57.645798, 56.093041],
            [57.657071, 56.113933],
            [57.686242, 56.136622],
            [57.721896, 56.12704],
            [57.751631, 56.133382],
            [57.774601, 56.144656],
            [57.8018, 56.141133],
            [57.861552, 56.148356],
            [57.893824, 56.141311],
            [57.908903, 56.131023],
            [57.894107, 56.121581],
            [57.912709, 56.106608],
            [57.955409, 56.112387],
            [57.996703, 56.109851],
            [58.071391, 56.116052],
            [58.103662, 56.139587],
            [58.129592, 56.133809],
            [58.164541, 56.140715],
            [58.185399, 56.126059],
            [58.242853, 56.118187],
            [58.25713, 56.10478],
            [58.306031, 56.10464],
            [58.316178, 56.11676],
            [58.349088, 56.121563],
            [58.359301, 56.114083],
            [58.345632, 56.084453],
            [58.384528, 56.081776],
            [58.410598, 56.101083],
            [58.42145, 56.12165],
            [58.469786, 56.121835],
            [58.482469, 56.132545],
            [58.49346, 56.157101],
            [58.511075, 56.170666],
            [58.55448, 56.166297],
            [58.559694, 56.172639],
            [58.582806, 56.16517],
            [58.597604, 56.137514],
            [58.628467, 56.135542],
            [58.643547, 56.117327],
            [58.713868, 56.116201],
            [58.71401, 56.094745],
            [58.732754, 56.080653],
            [58.750933, 56.086396],
            [58.832106, 56.08386],
            [58.832811, 56.068922],
            [58.880303, 56.073573],
            [58.900497, 56.053224],
            [58.96063, 56.065224],
            [58.984164, 56.080198],
            [59.038559, 56.12776],
            [59.04205, 56.168357],
            [59.061669, 56.165487],
            [59.097005, 56.149044],
            [59.152774, 56.145639],
            [59.162992, 56.130314],
            [59.189173, 56.138615],
            [59.239408, 56.129463],
            [59.267293, 56.129038],
            [59.265164, 56.141809],
            [59.281128, 56.155698],
            [59.330086, 56.156337],
            [59.335833, 56.149686],
            [59.319231, 56.125633],
            [59.334771, 56.102165],
            [59.314337, 56.085509],
            [59.33541, 56.085935],
            [59.315827, 56.069917],
            [59.308165, 56.04629],
            [59.286454, 56.034742],
            [59.192583, 56.025162],
            [59.221959, 55.98871],
            [59.216213, 55.951833],
            [59.24644, 55.9488],
            [59.246653, 55.924321],
            [59.233031, 55.902982],
            [59.203019, 55.885474],
            [59.206638, 55.864774],
            [59.221148, 55.85022],
            [59.192378, 55.828321],
            [59.193902, 55.820163],
            [59.133416, 55.820019],
            [59.135546, 55.789102],
            [59.187909, 55.793359],
            [59.201532, 55.787825],
            [59.27135, 55.788039],
            [59.303705, 55.775694],
            [59.320522, 55.761379],
            [59.27561, 55.709122],
            [59.298611, 55.701453],
            [59.288809, 55.677991],
            [59.316268, 55.67714],
            [59.335809, 55.63157],
            [59.290939, 55.621957],
            [59.276465, 55.606152],
            [59.250497, 55.607003],
            [59.249646, 55.58146],
            [59.275828, 55.579757],
            [59.335427, 55.609558],
            [59.382895, 55.620681],
            [59.390982, 55.644734],
            [59.464419, 55.643457],
            [59.471444, 55.627067],
            [59.461653, 55.602322],
            [59.468494, 55.594828],
            [59.564465, 55.594022],
            [59.601502, 55.60573],
            [59.65493, 55.591895],
            [59.642798, 55.557571],
            [59.587243, 55.54432],
            [59.578942, 55.516595],
            [59.517, 55.509357],
            [59.483794, 55.499565],
            [59.464074, 55.473619],
            [59.431006, 55.477002],
            [59.433693, 55.493994],
            [59.380345, 55.498074],
            [59.372895, 55.478065],
            [59.361614, 55.484025],
            [59.281366, 55.463963],
            [59.200693, 55.465452],
            [59.183238, 55.468431],
            [59.183026, 55.441984],
            [59.170255, 55.423837],
            [59.150033, 55.425753],
            [59.144287, 55.412556],
            [59.184305, 55.38696],
            [59.176856, 55.363545],
            [59.160041, 55.342419],
            [59.162596, 55.333266],
            [59.124706, 55.342632],
            [59.101292, 55.33284],
            [59.104485, 55.32241],
            [59.086392, 55.325815],
            [59.074686, 55.291918],
            [59.05638, 55.297452],
            [59.034243, 55.277177],
            [59.014873, 55.283349],
            [59.006998, 55.265256],
            [58.966394, 55.262515],
            [58.965275, 55.315969],
            [58.930579, 55.317033],
            [58.911422, 55.326399],
            [58.846925, 55.326824],
            [58.837773, 55.293352],
            [58.865658, 55.290372],
            [58.933335, 55.294488],
            [58.931858, 55.272014],
            [58.83075, 55.27776],
            [58.725384, 55.289678],
            [58.70942, 55.279674],
            [58.693031, 55.23194],
            [58.773067, 55.212571],
            [58.755401, 55.16372],
            [58.726648, 55.152116],
            [58.723899, 55.138602],
            [58.693034, 55.150735],
            [58.692395, 55.171808],
            [58.679197, 55.173298],
            [58.657486, 55.161164],
            [58.644906, 55.172941],
            [58.652377, 55.192295],
            [58.615316, 55.194679],
            [58.608447, 55.181493],
            [58.582985, 55.177767],
            [58.57545, 55.162091],
            [58.597163, 55.161379],
            [58.587514, 55.146702],
            [58.624068, 55.140464],
            [58.641309, 55.147062],
            [58.733479, 55.12019],
            [58.731442, 55.10189],
            [58.761151, 55.094807],
            [58.771552, 55.077121],
            [58.798403, 55.055589],
            [58.823733, 55.053886],
            [58.806493, 55.028715],
            [58.730947, 54.996176],
            [58.727088, 54.967864],
            [58.694956, 54.967996],
            [58.652717, 54.958664],
            [58.621954, 54.964708],
            [58.59768, 54.956288],
            [58.566836, 54.970946],
            [58.529352, 54.972677],
            [58.493543, 54.98441],
            [58.525519, 54.994601],
            [58.548933, 55.023604],
            [58.531053, 55.027435],
            [58.501678, 55.020623],
            [58.475284, 55.001678],
            [58.453279, 54.99672],
            [58.422069, 55.017908],
            [58.439522, 55.048134],
            [58.42973, 55.06628],
            [58.379285, 55.065779],
            [58.400355, 55.07953],
            [58.373321, 55.09592],
            [58.346926, 55.103849],
            [58.370979, 55.119813],
            [58.362464, 55.140886],
            [58.305843, 55.162437],
            [58.271146, 55.171377],
            [58.245816, 55.170525],
            [58.231129, 55.151368],
            [58.210057, 55.142374],
            [58.190899, 55.149824],
            [58.167698, 55.131518],
            [58.128532, 55.127048],
            [58.158333, 55.10805],
            [58.137047, 55.097407],
            [58.153651, 55.083997],
            [58.163868, 55.094694],
            [58.195584, 55.096184],
            [58.193243, 55.070535],
            [58.15131, 55.059412],
            [58.166211, 55.050047],
            [58.153014, 55.037062],
            [58.176428, 55.040681],
            [58.179834, 55.031315],
            [58.158761, 55.031315],
            [58.128323, 55.017213],
            [58.087454, 55.012955],
            [58.130665, 54.999971],
            [58.101504, 54.98422],
            [58.125557, 54.975493],
            [58.131944, 54.952238],
            [58.117319, 54.938628],
            [58.082774, 54.946511],
            [58.080007, 54.929994],
            [58.058722, 54.919989],
            [58.005081, 54.906153],
            [57.982943, 54.927917],
            [57.963786, 54.921106],
            [57.932921, 54.948617],
            [57.952929, 54.960112],
            [57.958889, 54.975916],
            [57.983014, 54.984433],
            [57.999332, 54.977886],
            [58.027003, 54.991296],
            [58.008779, 54.99885],
            [58.032749, 55.027908],
            [58.023383, 55.038976],
            [58.029441, 55.059835],
            [58.04041, 55.068191],
            [57.991026, 55.091233],
            [58.007058, 55.102993],
            [57.98891, 55.112652],
            [58.005712, 55.142425],
            [57.987618, 55.149662],
            [58.005072, 55.163764],
            [58.041259, 55.153973],
            [58.080424, 55.170789],
            [58.117674, 55.191703],
            [58.131935, 55.194045],
            [58.136618, 55.211127],
            [58.126613, 55.217725],
            [58.078719, 55.221556],
            [58.043919, 55.238855],
            [58.023161, 55.276526],
            [58.008686, 55.282699],
            [57.969733, 55.28041],
            [57.955258, 55.291691],
            [57.882886, 55.302333],
            [57.780501, 55.298075],
            [57.721326, 55.304247],
            [57.641291, 55.301479],
            [57.578071, 55.319571],
            [57.568066, 55.319358],
            [57.530101, 55.337019],
            [57.507614, 55.327446],
            [57.457592, 55.326381],
            [57.420129, 55.316164],
            [57.393947, 55.317228],
            [57.380751, 55.294611],
            [57.339883, 55.279711],
            [57.326047, 55.259649],
            [57.30391, 55.25752],
            [57.292415, 55.26646],
            [57.246225, 55.266033],
            [57.232603, 55.246397],
            [57.263255, 55.219152],
            [57.239841, 55.214894],
            [57.22047, 55.236606],
            [57.211956, 55.23022],
            [57.212596, 55.200845],
            [57.233031, 55.179719],
            [57.259851, 55.179028],
            [57.241546, 55.1496],
            [57.210682, 55.135125],
            [57.156404, 55.127249],
            [57.131288, 55.085049],
            [57.164282, 55.058177],
            [57.168965, 55.040456],
            [57.184717, 55.031889],
            [57.180035, 55.014222],
            [57.192594, 55.006506],
            [57.187699, 54.977131],
            [57.204515, 54.982666],
            [57.214094, 54.972236],
            [57.259646, 54.960529],
            [57.259434, 54.950259],
            [57.188767, 54.887624],
            [57.156626, 54.870329],
            [57.143324, 54.851586],
            [57.162799, 54.848777],
            [57.201329, 54.80445],
            [57.244966, 54.772469],
            [57.384603, 54.735167],
            [57.395813, 54.71987],
            [57.454209, 54.71617],
            [57.516151, 54.692278],
            [57.504232, 54.675462],
            [57.484862, 54.665244],
            [57.520623, 54.65375],
            [57.592355, 54.679347],
            [57.603152, 54.67859],
            [57.627054, 54.617672],
            [57.746043, 54.58186],
            [57.738999, 54.574982],
            [57.762647, 54.563129],
            [57.747535, 54.54328],
            [57.753361, 54.501668],
            [57.826507, 54.48549],
            [57.856522, 54.456967],
            [57.896966, 54.429615],
            [57.901312, 54.420698],
            [57.983601, 54.396357],
            [58.012124, 54.396783],
            [58.118338, 54.46011],
            [58.16921, 54.502576],
            [58.163463, 54.510238],
            [58.257121, 54.514497],
            [58.38526, 54.560103],
            [58.421872, 54.558826],
            [58.436134, 54.543926],
            [58.498716, 54.513701],
            [58.587267, 54.464],
            [58.617068, 54.462723],
            [58.714982, 54.47789],
            [58.739246, 54.536906],
            [58.77458, 54.552498],
            [58.805871, 54.550583],
            [58.839076, 54.566973],
            [58.908468, 54.57187],
            [58.919749, 54.583151],
            [58.953697, 54.584371],
            [59.001486, 54.618327],
            [59.065343, 54.649671],
            [59.150062, 54.62812],
            [59.235206, 54.623013],
            [59.263303, 54.616893],
            [59.291613, 54.636689],
            [59.288845, 54.658241],
            [59.381011, 54.702623],
            [59.441463, 54.720717],
            [59.44572, 54.736947],
            [59.486588, 54.762544],
            [59.522773, 54.779786],
            [59.520005, 54.795591],
            [59.53682, 54.841409],
            [59.60089, 54.867006],
            [59.617976, 54.906111],
            [59.660276, 54.907928],
            [59.659425, 54.90282],
            [59.697314, 54.885951],
            [59.736906, 54.885739],
            [59.76394, 54.87318],
            [59.776712, 54.860409],
            [59.842486, 54.841838],
            [59.857599, 54.851842],
            [59.875479, 54.838432],
            [59.915283, 54.869936],
            [60.001809, 54.864135],
            [59.99319, 54.853281],
            [59.938274, 54.817892],
            [59.929122, 54.78889],
            [59.947215, 54.770372],
            [59.897621, 54.704917],
            [59.897196, 54.684643],
            [59.853775, 54.609024],
            [59.79226, 54.572306],
            [59.737343, 54.549316],
            [59.726913, 54.551658],
            [59.689025, 54.521804],
            [59.675402, 54.518558],
            [59.680299, 54.504296],
            [59.666889, 54.490673],
            [59.646489, 54.484027],
            [59.670295, 54.469334],
            [59.739901, 54.455659],
            [59.736071, 54.400688],
            [59.725855, 54.384883],
            [59.67179, 54.33965],
            [59.755019, 54.314268],
            [59.789291, 54.244344],
            [59.776307, 54.226464],
            [59.715218, 54.19512],
            [59.705427, 54.179954],
            [59.707982, 54.141639],
            [59.661792, 54.14249],
            [59.655832, 54.150366],
            [59.600488, 54.159731],
            [59.525348, 54.186125],
            [59.470643, 54.187614],
            [59.399122, 54.183569],
            [59.378901, 54.172926],
            [59.3491, 54.177395],
            [59.325654, 54.187793],
            [59.309296, 54.162442],
            [59.284818, 54.149883],
            [59.236501, 54.069688],
            [59.224794, 54.065218],
            [59.192228, 54.024508],
            [59.188185, 54.003595],
            [59.140931, 53.972677],
            [59.10879, 53.956286],
            [59.090483, 53.967355],
            [59.051317, 53.97076],
            [59.000232, 53.951602],
            [58.952125, 53.967353],
            [58.939566, 53.963096],
            [58.910619, 53.928772],
            [58.934033, 53.931539],
            [58.954468, 53.923664],
            [58.964898, 53.907859],
            [58.939994, 53.89679],
            [58.907744, 53.862662],
            [58.925523, 53.784827],
            [58.904026, 53.751993],
            [58.885933, 53.748587],
            [58.891255, 53.719639],
            [58.912543, 53.678185],
            [58.906865, 53.642514],
            [58.839787, 53.611418],
            [58.822129, 53.608293],
            [58.813353, 53.591603],
            [58.81378, 53.548606],
            [58.906374, 53.547702],
            [58.907014, 53.493796],
            [58.897223, 53.49353],
            [58.90021, 53.276521],
            [58.875305, 53.27668],
            [58.872275, 53.201817],
            [58.906597, 53.21144],
            [58.911068, 53.191218],
            [58.911284, 53.091654],
            [58.913414, 53.056053],
            [58.975995, 53.048551],
            [58.991746, 53.041952],
            [58.989132, 53.019652],
            [58.839127, 53.024017],
            [58.861054, 52.944409],
            [58.812309, 52.94031],
            [58.802945, 52.896994],
            [58.7608, 52.849632],
            [58.762717, 52.810626],
            [58.779534, 52.787318],
            [58.804657, 52.695086],
            [58.776557, 52.690308],
            [58.78571, 52.680091],
            [58.776771, 52.661519],
            [58.751015, 52.6579],
            [58.751016, 52.611018],
            [58.781029, 52.610593],
            [58.781235, 52.592084],
            [58.824027, 52.593299],
            [58.838928, 52.566],
            [58.838291, 52.520821],
            [58.781245, 52.520394],
            [58.770178, 52.471171],
            [58.79785, 52.468617],
            [58.796148, 52.431792],
            [58.854927, 52.425806],
            [58.837006, 52.355784],
            [58.868531, 52.351906],
            [58.900391, 52.342399],
            [58.945428, 52.339689],
            [58.944316, 52.326398],
            [58.930487, 52.31884],
            [58.943371, 52.313429],
            [58.952477, 52.28167],
            [58.925764, 52.28182],
            [58.903948, 52.273209],
            [58.890892, 52.250576],
            [58.897506, 52.244048],
            [58.812128, 52.250747],
            [58.742554, 52.25867],
            [58.699263, 52.29541],
            [58.670231, 52.297299],
            [58.661558, 52.242263],
            [58.653656, 52.217891],
            [58.661215, 52.199467],
            [58.616724, 52.136593],
            [58.651511, 52.130065],
            [58.652715, 52.078787],
            [58.713098, 52.079733],
            [58.712842, 52.037409],
            [58.655809, 52.034295],
            [58.659677, 51.935346],
            [58.655641, 51.893001],
            [58.639322, 51.885012],
            [58.614413, 51.884754],
            [58.619137, 51.875113],
            [58.615445, 51.826519],
            [58.574732, 51.827034],
            [58.574905, 51.771697],
            [58.489871, 51.770837],
            [58.489441, 51.779255],
            [58.40329, 51.778567],
            [58.403462, 51.771352],
            [58.329165, 51.770234],
            [58.319888, 51.778909],
            [58.313447, 51.752261],
            [58.258733, 51.750779],
            [58.257273, 51.733493],
            [58.188301, 51.733406],
            [58.164657, 51.718366],
            [58.144409, 51.724516],
            [58.152569, 51.735081],
            [58.142777, 51.749103],
            [58.114432, 51.761686],
            [58.118211, 51.773732],
            [58.109535, 51.791877],
            [58.069594, 51.793959],
            [57.955184, 51.793786],
            [57.955098, 51.821551],
            [57.941956, 51.821637],
            [57.912408, 51.83583],
            [57.844381, 51.837912],
            [57.84378, 51.826231],
            [57.754365, 51.825371],
            [57.725161, 51.834304],
            [57.715197, 51.860028],
            [57.655329, 51.86881],
            [57.63901, 51.853865],
            [57.658679, 51.846693],
            [57.66976, 51.830373],
            [57.645281, 51.819765],
            [57.641588, 51.808342],
            [57.613845, 51.794469],
            [57.610925, 51.785107],
            [57.640816, 51.76722],
            [57.628705, 51.750428],
            [57.589882, 51.744071],
            [57.573735, 51.725647],
            [57.529413, 51.732432],
            [57.499093, 51.721094],
            [57.481313, 51.725904],
            [57.395935, 51.727277],
            [57.407274, 51.691396],
            [57.36055, 51.653301],
            [57.331861, 51.649844],
            [57.293554, 51.62953],
            [57.305837, 51.60709],
            [57.225356, 51.590212],
            [57.22046, 51.575738],
            [57.198214, 51.569554],
            [57.160507, 51.575738],
            [57.118074, 51.632169],
            [57.095569, 51.66702],
            [57.002686, 51.666984],
            [56.997715, 51.68361],
            [56.996857, 51.720548],
            [56.96926, 51.723847],
            [56.958461, 51.745787],
            [56.943549, 51.73396],
            [56.898812, 51.735159],
            [56.864016, 51.743044],
            [56.842481, 51.764773],
            [56.811799, 51.768614],
            [56.793968, 51.765199],
            [56.795624, 51.747499],
            [56.861446, 51.704263],
            [56.886129, 51.677138],
            [56.885444, 51.651384],
            [56.895215, 51.647228],
            [56.892302, 51.606433],
            [56.817912, 51.591948],
            [56.814141, 51.587492],
            [56.774375, 51.585092],
            [56.723, 51.591497],
            [56.732379, 51.610802],
            [56.752433, 51.630557],
            [56.771116, 51.666638],
            [56.749347, 51.681465],
            [56.759459, 51.696548],
            [56.745575, 51.716646],
            [56.740946, 51.738286],
            [56.780884, 51.736572],
            [56.759972, 51.753241],
            [56.769913, 51.763697],
            [56.754143, 51.776081],
            [56.743173, 51.771281],
            [56.697064, 51.803205],
            [56.678209, 51.812461],
            [56.610845, 51.827073],
            [56.622328, 51.874981],
            [56.633298, 51.879437],
            [56.630384, 51.899192],
            [56.671693, 51.902278],
            [56.689347, 51.908963],
            [56.726029, 51.901079],
            [56.734256, 51.918219],
            [56.724314, 51.92169],
            [56.7166, 51.943459],
            [56.695517, 51.938145],
            [56.691574, 51.949286],
            [56.65455, 51.959442],
            [56.658149, 51.985538],
            [56.677174, 52.00855],
            [56.683173, 52.031904],
            [56.704598, 52.060058],
            [56.682829, 52.067085],
            [56.666716, 52.08564],
            [56.62952, 52.095966],
            [56.627635, 52.085682],
            [56.606381, 52.080711],
            [56.603125, 52.06477],
            [56.585469, 52.078825],
            [56.621293, 52.09768],
            [56.627977, 52.121506],
            [56.616321, 52.128919],
            [56.54433, 52.148501],
            [56.487422, 52.169284],
            [56.458112, 52.163113],
            [56.427945, 52.144086],
            [56.399491, 52.159855],
            [56.441142, 52.180425],
            [56.45074, 52.190923],
            [56.437713, 52.198122],
            [56.474394, 52.20018],
            [56.466166, 52.211192],
            [56.427771, 52.200736],
            [56.421258, 52.183424],
            [56.412345, 52.185138],
            [56.382864, 52.161098],
            [56.351668, 52.158869],
            [56.347727, 52.128616],
            [56.295448, 52.127416],
            [56.268194, 52.12193],
            [56.202373, 52.142327],
            [56.200487, 52.166709],
            [56.236995, 52.219032],
            [56.260993, 52.201891],
            [56.279332, 52.223189],
            [56.272476, 52.23073],
            [56.294587, 52.241401],
            [56.327326, 52.247229],
            [56.311556, 52.25717],
            [56.338466, 52.274525],
            [56.359206, 52.280525],
            [56.399315, 52.281768],
            [56.453994, 52.297024],
            [56.452965, 52.317978],
            [56.433938, 52.331348],
            [56.44525, 52.342832],
            [56.43925, 52.374628],
            [56.432565, 52.374285],
            [56.440278, 52.405353],
            [56.45759, 52.409809],
            [56.464102, 52.42905],
            [56.486728, 52.438135],
            [56.486556, 52.451204],
            [56.465644, 52.470273],
            [56.443189, 52.47233],
            [56.443362, 52.445547],
            [56.430849, 52.445204],
            [56.432392, 52.428407],
            [56.381999, 52.420007],
            [56.3508, 52.496325],
            [56.384224, 52.508024],
            [56.374796, 52.528979],
            [56.43016, 52.543592],
            [56.40702, 52.559404],
            [56.445379, 52.581714],
            [56.423645, 52.603841],
            [56.379422, 52.598184],
            [56.322344, 52.580871],
            [56.26441, 52.545861],
            [56.219052, 52.572656],
            [56.190017, 52.599253],
            [56.18419, 52.591197],
            [56.157108, 52.591711],
            [56.166535, 52.602166],
            [56.148457, 52.632301],
            [56.12523, 52.633621],
            [56.104792, 52.618557],
            [56.07354, 52.621542],
            [56.098037, 52.636475],
            [56.126477, 52.645876],
            [56.108575, 52.648476],
            [56.068193, 52.644699],
            [56.035274, 52.647084],
            [56.067469, 52.623386],
            [56.053138, 52.590947],
            [56.027052, 52.576099],
            [56.036609, 52.570241],
            [56.006415, 52.548907],
            [55.98734, 52.561291],
            [55.966266, 52.557788],
            [55.968138, 52.546898],
            [55.944062, 52.515294],
            [55.920333, 52.509666],
            [55.928453, 52.52126],
            [55.911998, 52.535936],
            [55.92211, 52.552841],
            [55.908133, 52.559945],
            [55.885258, 52.544442],
            [55.867592, 52.545781],
            [55.872231, 52.5345],
            [55.863247, 52.496093],
            [55.866071, 52.488317],
            [55.848236, 52.470308],
            [55.871378, 52.414687],
            [55.887662, 52.407831],
            [55.88206, 52.396155],
            [55.857774, 52.38953],
            [55.823417, 52.364962],
            [55.798198, 52.376925],
            [55.773394, 52.345755],
            [55.73319, 52.351499],
            [55.699395, 52.400965],
            [55.665641, 52.40133],
            [55.674984, 52.367874],
            [55.618275, 52.371895],
            [55.614079, 52.382345],
            [55.592428, 52.383848],
            [55.56936, 52.406242],
            [55.551764, 52.386398],
            [55.544871, 52.394402],
            [55.525097, 52.38756],
            [55.496196, 52.416898],
            [55.528543, 52.43107],
            [55.542362, 52.443787],
            [55.539457, 52.457284],
            [55.499997, 52.462045],
            [55.495774, 52.472497],
            [55.501479, 52.498758],
            [55.519649, 52.496358],
            [55.525192, 52.513938],
            [55.510049, 52.520227],
            [55.474527, 52.523159],
            [55.479367, 52.541481],
            [55.544844, 52.534839],
            [55.548958, 52.544438],
            [55.510061, 52.555206],
            [55.508135, 52.567669],
            [55.530034, 52.575909],
            [55.524102, 52.604216],
            [55.469281, 52.583557],
            [55.437028, 52.58536],
            [55.463937, 52.624612],
            [55.435312, 52.649037],
            [55.403601, 52.66772],
            [55.368152, 52.675262],
            [55.363492, 52.646123],
            [55.352865, 52.638366],
            [55.302971, 52.629615],
            [55.280618, 52.630418],
            [55.263219, 52.645907],
            [55.269045, 52.700114],
            [55.216321, 52.721853],
            [55.225291, 52.740677],
            [55.266281, 52.738386],
            [55.276882, 52.718143],
            [55.301736, 52.729321],
            [55.315497, 52.693984],
            [55.367311, 52.683087],
            [55.361536, 52.695058],
            [55.373689, 52.70786],
            [55.395115, 52.699859],
            [55.427082, 52.733112],
            [55.416625, 52.746867],
            [55.367903, 52.74008],
            [55.338549, 52.767307],
            [55.387828, 52.788581],
            [55.395584, 52.78105],
            [55.420737, 52.791133],
            [55.400725, 52.807036],
            [55.400338, 52.827063],
            [55.385366, 52.821007],
            [55.34878, 52.851411],
            [55.319446, 52.847758],
            [55.27251, 52.827761],
            [55.265087, 52.842316],
            [55.245713, 52.818871],
            [55.190935, 52.838874],
            [55.179053, 52.817227],
            [55.160718, 52.81141],
            [55.1395, 52.814367],
            [55.152113, 52.837682],
            [55.136813, 52.838043],
            [55.1308, 52.852005],
            [55.155027, 52.848177],
            [55.147488, 52.860756],
            [55.131206, 52.863282],
            [55.123386, 52.882552],
            [55.133692, 52.897318],
            [55.122748, 52.914446],
            [55.070961, 52.943829],
            [55.053578, 52.958721],
            [55.038322, 52.999258],
            [55.004039, 53.028311],
            [54.996668, 53.056593],
            [54.966157, 53.068591],
            [54.975413, 53.08029],
            [54.941131, 53.10073],
            [54.956557, 53.119799],
            [54.94627, 53.17422],
            [54.927072, 53.211115],
            [54.901874, 53.226756],
            [54.871878, 53.233612],
            [54.840853, 53.252209],
            [54.827796, 53.275814],
            [54.797144, 53.255122],
            [54.792688, 53.242052],
            [54.675103, 53.225767],
            [54.660534, 53.214283],
            [54.615454, 53.217025],
            [54.612368, 53.227652],
            [54.637565, 53.236223],
            [54.626937, 53.258848],
            [54.552032, 53.263175],
            [54.565743, 53.2903],
            [54.56797, 53.32771],
            [54.547572, 53.355177],
            [54.504664, 53.363604],
            [54.463848, 53.362103],
            [54.428063, 53.37045],
            [54.401955, 53.369312],
            [54.344458, 53.388498],
            [54.316259, 53.40333],
            [54.287963, 53.402712],
            [54.280186, 53.384195],
            [54.253711, 53.361851],
            [54.238873, 53.36353],
            [54.169707, 53.385642],
            [54.151671, 53.387978],
            [54.10729, 53.421725],
            [54.074814, 53.441663],
            [54.060446, 53.457009],
            [54.041745, 53.500566],
            [54.023932, 53.498377],
            [54.003278, 53.532144],
            [53.98006, 53.543541],
            [53.913805, 53.610626],
            [53.850762, 53.684026],
            [53.824225, 53.687494],
            [53.682811, 53.743744],
            [53.644587, 53.753514],
            [53.627274, 53.790238],
            [53.629673, 53.807121],
            [53.599161, 53.839559],
            [53.596932, 53.867756],
            [53.582533, 53.877569],
            [53.598302, 53.883568],
            [53.570705, 53.914464],
            [53.57019, 53.946731],
            [53.55082, 53.968199],
            [53.530936, 53.975741],
            [53.535563, 53.998067],
            [53.514651, 54.009251],
            [53.504024, 54.023306],
            [53.511907, 54.053859],
            [53.500606, 54.056129],
            [53.4722, 54.0917],
            [53.4706, 54.1158],
            [53.4545, 54.1539],
            [53.4571, 54.1718],
            [53.4366, 54.1875],
            [53.4356, 54.2235],
            [53.4239, 54.2336],
            [53.4367, 54.2416],
            [53.4192, 54.2581],
            [53.4408, 54.2601],
            [53.419313, 54.30742],
            [53.432359, 54.327856],
            [53.399845, 54.350121],
            [53.419355, 54.360619],
            [53.381851, 54.375212],
            [53.398792, 54.382388],
            [53.404462, 54.397053],
            [53.386261, 54.41684],
            [53.417046, 54.435317],
            [53.404029, 54.449074],
            [53.424189, 54.455364],
            [53.415256, 54.470263],
            [53.434387, 54.479517],
            [53.437452, 54.496616],
            [53.426929, 54.507159],
            [53.434379, 54.550383],
            [53.422711, 54.564303],
            [53.437896, 54.571828],
            [53.459133, 54.565256],
            [53.47038, 54.571764],
            [53.466894, 54.588953],
            [53.489398, 54.592603],
            [53.490357, 54.60187],
            [53.511976, 54.617107],
            [53.531842, 54.616781],
            [53.546874, 54.631265],
            [53.571059, 54.640495],
            [53.5693, 54.6705],
            [53.6097, 54.6955],
            [53.6149, 54.7044],
            [53.6125, 54.7395],
            [53.6192, 54.7474],
            [53.6063, 54.788],
            [53.610457, 54.810367],
            [53.593066, 54.809682],
            [53.622774, 54.878513],
            [53.637366, 54.880269],
            [53.636504, 54.899498],
            [53.648184, 54.908455],
            [53.617124, 54.906188],
            [53.588445, 54.885896],
            [53.553362, 54.878503],
            [53.5535, 54.8881],
            [53.507682, 54.914218],
            [53.51806, 54.924777],
            [53.476099, 54.950344],
            [53.482486, 54.961686],
            [53.47511, 54.978258],
            [53.44267, 54.994621],
            [53.431194, 54.993997],
            [53.371849, 55.025013],
            [53.321526, 55.021277],
            [53.319335, 55.043372],
            [53.298459, 55.046311],
            [53.276383, 55.040976],
            [53.269505, 55.048659],
            [53.294045, 55.070675],
            [53.269498, 55.078052],
            [53.264145, 55.099769],
            [53.250295, 55.10447],
            [53.170297, 55.1052],
            [53.161102, 55.114022],
            [53.158294, 55.165716],
            [53.207989, 55.158837],
            [53.241394, 55.17459],
            [53.283468, 55.171541],
            [53.318673, 55.16222],
            [53.368441, 55.179223],
            [53.378185, 55.17562],
            [53.398238, 55.191883],
            [53.362239, 55.202065],
            [53.426972, 55.230037],
            [53.466611, 55.228466],
            [53.482911, 55.216589],
            [53.516353, 55.213979],
            [53.616626, 55.214323],
            [53.627864, 55.227383],
            [53.602269, 55.262642],
            [53.673076, 55.288411],
            [53.686497, 55.301295],
            [53.685995, 55.318991],
            [53.7001, 55.3308],
            [53.717, 55.3232],
            [53.74, 55.3371],
            [53.7771, 55.3497],
            [53.786, 55.3415],
            [53.8541, 55.3668],
            [53.8827, 55.3707],
            [53.8874, 55.3912],
            [53.8752, 55.3978],
            [53.900854, 55.40744],
            [53.902602, 55.42843],
            [53.927291, 55.444216],
            [53.926926, 55.455099],
            [53.978624, 55.46587],
            [53.958814, 55.482991],
            [53.978448, 55.482801],
            [53.966076, 55.529181],
            [53.982886, 55.52979],
            [54.004173, 55.550763],
            [54.023434, 55.538432],
            [54.042572, 55.546077],
            [54.031063, 55.559244],
            [54.071294, 55.572625],
            [54.07906, 55.586582],
            [54.107653, 55.583319],
            [54.141629, 55.609011],
            [54.182916, 55.622447],
            [54.175246, 55.634957],
            [54.203851, 55.642312],
            [54.198257, 55.660818],
            [54.240793, 55.676896],
            [54.265888, 55.704039],
            [54.255689, 55.720834],
            [54.234018, 55.706363],
            [54.208897, 55.702633],
            [54.171663, 55.716081],
            [54.162807, 55.739211],
            [54.127829, 55.749207],
            [54.116207, 55.763783],
            [54.129927, 55.780451],
            [54.106772, 55.774039],
            [54.0237, 55.7819],
            [54, 55.7972],
            [53.9707, 55.7848],
            [53.952013, 55.790528],
            [53.94242, 55.813033],
            [53.99175, 55.847449],
            [53.938073, 55.846524],
            [53.856133, 55.849799],
            [53.88881, 55.859657],
            [53.893719, 55.874328],
            [53.879525, 55.886982],
            [53.856336, 55.882445],
            [53.854127, 55.869071],
            [53.838584, 55.870704],
            [53.825437, 55.891485],
            [53.808252, 55.897436],
            [53.784106, 55.895104],
            [53.760793, 55.908521],
            [53.741982, 55.903634],
            [53.72827, 55.885983],
            [53.710224, 55.893027],
            [53.708493, 55.908907],
            [53.719374, 55.924025],
            [53.697341, 55.925082],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5515",
      properties: {
        name: "Белгородская область",
        density: 0,
        path: "/world/Russia/Белгородская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.350987, 50.975007],
            [35.390603, 50.960942],
            [35.412302, 50.957556],
            [35.421383, 50.948512],
            [35.436245, 50.958166],
            [35.456672, 50.954986],
            [35.479507, 50.937681],
            [35.54306, 50.944578],
            [35.542685, 50.923694],
            [35.529766, 50.920182],
            [35.570907, 50.901557],
            [35.605932, 50.915856],
            [35.60633, 50.935838],
            [35.647888, 50.928337],
            [35.6477, 50.916735],
            [35.679814, 50.907181],
            [35.699626, 50.931913],
            [35.726405, 50.928904],
            [35.736314, 50.940318],
            [35.720726, 50.950291],
            [35.734212, 50.975939],
            [35.77946, 50.966846],
            [35.788022, 50.943016],
            [35.803262, 50.936682],
            [35.83418, 50.940257],
            [35.836123, 50.958131],
            [35.862745, 50.958006],
            [35.882844, 50.990868],
            [35.874942, 50.997892],
            [35.909873, 51.020532],
            [35.933017, 51.022692],
            [35.949132, 51.037779],
            [35.950696, 51.054648],
            [36.006041, 51.052517],
            [35.996461, 51.071242],
            [35.9223, 51.096882],
            [35.937131, 51.098994],
            [35.936273, 51.117209],
            [35.922334, 51.130149],
            [35.954324, 51.138352],
            [36.005473, 51.129403],
            [35.98436, 51.148072],
            [36.011712, 51.158189],
            [36.064989, 51.158692],
            [36.077218, 51.145397],
            [36.131187, 51.119247],
            [36.140156, 51.110468],
            [36.185502, 51.124598],
            [36.223316, 51.118059],
            [36.228394, 51.143019],
            [36.249216, 51.131982],
            [36.265023, 51.107274],
            [36.305765, 51.094514],
            [36.353138, 51.091598],
            [36.362982, 51.104113],
            [36.380606, 51.103451],
            [36.404843, 51.11844],
            [36.430242, 51.117688],
            [36.430807, 51.106149],
            [36.448054, 51.095801],
            [36.486282, 51.062953],
            [36.503822, 51.078414],
            [36.494006, 51.094044],
            [36.508069, 51.116121],
            [36.520857, 51.119749],
            [36.520353, 51.133985],
            [36.531642, 51.133797],
            [36.564615, 51.110914],
            [36.588211, 51.10959],
            [36.60157, 51.100184],
            [36.603797, 51.080053],
            [36.587022, 51.066319],
            [36.619507, 51.077795],
            [36.642341, 51.077848],
            [36.648479, 51.0899],
            [36.665726, 51.086137],
            [36.743079, 51.131167],
            [36.752338, 51.149488],
            [36.767707, 51.14152],
            [36.790768, 51.160269],
            [36.810617, 51.166164],
            [36.85537, 51.171292],
            [36.868078, 51.177882],
            [36.895737, 51.172472],
            [36.921022, 51.178664],
            [36.949428, 51.170439],
            [36.960578, 51.184797],
            [36.987165, 51.183217],
            [36.989985, 51.190595],
            [37.023605, 51.208853],
            [37.03519, 51.235027],
            [37.054698, 51.242429],
            [37.084393, 51.243621],
            [37.07925, 51.252087],
            [37.109697, 51.254471],
            [37.112458, 51.244249],
            [37.096716, 51.243182],
            [37.097971, 51.230326],
            [37.139551, 51.230766],
            [37.136131, 51.260993],
            [37.121755, 51.283952],
            [37.133592, 51.298546],
            [37.152124, 51.304191],
            [37.159994, 51.319629],
            [37.145238, 51.355232],
            [37.186816, 51.347633],
            [37.19773, 51.337981],
            [37.237701, 51.327501],
            [37.273066, 51.337562],
            [37.300755, 51.331542],
            [37.319004, 51.33957],
            [37.323108, 51.358639],
            [37.383742, 51.384521],
            [37.418872, 51.38002],
            [37.47205, 51.368026],
            [37.50125, 51.382605],
            [37.521695, 51.378466],
            [37.557248, 51.39153],
            [37.585636, 51.388727],
            [37.583906, 51.371443],
            [37.604163, 51.367555],
            [37.685815, 51.361285],
            [37.703198, 51.35774],
            [37.761905, 51.391977],
            [37.782623, 51.390209],
            [37.831218, 51.422749],
            [37.847478, 51.429731],
            [37.933749, 51.422302],
            [37.948711, 51.426777],
            [37.98618, 51.420358],
            [38.005829, 51.423132],
            [38.011934, 51.407675],
            [38.035173, 51.381351],
            [38.035954, 51.36706],
            [38.059847, 51.365101],
            [38.074862, 51.356055],
            [38.074869, 51.37177],
            [38.157822, 51.333011],
            [38.206662, 51.33799],
            [38.217127, 51.330741],
            [38.229369, 51.299783],
            [38.246245, 51.308657],
            [38.288584, 51.296352],
            [38.310197, 51.291902],
            [38.319747, 51.271492],
            [38.335277, 51.256143],
            [38.381546, 51.272989],
            [38.399248, 51.287113],
            [38.434292, 51.28578],
            [38.433534, 51.267265],
            [38.44631, 51.266862],
            [38.449592, 51.247711],
            [38.471738, 51.223644],
            [38.456376, 51.216301],
            [38.4757, 51.21106],
            [38.519389, 51.211023],
            [38.525427, 51.187125],
            [38.506288, 51.176994],
            [38.52222, 51.163495],
            [38.489211, 51.169521],
            [38.483829, 51.162307],
            [38.411662, 51.165983],
            [38.396234, 51.162881],
            [38.3962, 51.151986],
            [38.380353, 51.150207],
            [38.381193, 51.12959],
            [38.40725, 51.130024],
            [38.405908, 51.099242],
            [38.390817, 51.095263],
            [38.387686, 51.077056],
            [38.436559, 51.064721],
            [38.454173, 51.069511],
            [38.457899, 51.08834],
            [38.465442, 51.074446],
            [38.484068, 51.077172],
            [38.518256, 51.07103],
            [38.502001, 51.049361],
            [38.531019, 51.047907],
            [38.547524, 51.025813],
            [38.58801, 51.012156],
            [38.617944, 51.018485],
            [38.633258, 50.998237],
            [38.655072, 51.011954],
            [38.674221, 51.00548],
            [38.676016, 51.014192],
            [38.699355, 51.022127],
            [38.687174, 51.057953],
            [38.712542, 51.05047],
            [38.733582, 51.068277],
            [38.760884, 51.074385],
            [38.803495, 51.076693],
            [38.807222, 51.048325],
            [38.829598, 51.049688],
            [38.831186, 51.041992],
            [38.856455, 51.036004],
            [38.864588, 51.005923],
            [38.804784, 50.983853],
            [38.846102, 50.958492],
            [38.849455, 50.93784],
            [38.891204, 50.937082],
            [38.876593, 50.930671],
            [38.868774, 50.915701],
            [38.878355, 50.909739],
            [38.837514, 50.893409],
            [38.792354, 50.915693],
            [38.774359, 50.917929],
            [38.767346, 50.9062],
            [38.726079, 50.899699],
            [38.746102, 50.8985],
            [38.740958, 50.879436],
            [38.74862, 50.865639],
            [38.720936, 50.877304],
            [38.725201, 50.857509],
            [38.741368, 50.851543],
            [38.732049, 50.839493],
            [38.708949, 50.829055],
            [38.706516, 50.800234],
            [38.72995, 50.788673],
            [38.764058, 50.785319],
            [38.801499, 50.796417],
            [38.848768, 50.783758],
            [38.838776, 50.773982],
            [38.799805, 50.779188],
            [38.78783, 50.7664],
            [38.803684, 50.762344],
            [38.792211, 50.743781],
            [38.76859, 50.719567],
            [38.805983, 50.715272],
            [38.816357, 50.723913],
            [38.828426, 50.712965],
            [38.854885, 50.715698],
            [38.880177, 50.707802],
            [38.86467, 50.699983],
            [38.890136, 50.69107],
            [38.896723, 50.704248],
            [38.957737, 50.697817],
            [38.988247, 50.697477],
            [38.9803, 50.686629],
            [38.962109, 50.682523],
            [38.964852, 50.653082],
            [39.022606, 50.655211],
            [39.026511, 50.645965],
            [39.050269, 50.651571],
            [39.056083, 50.642618],
            [39.11927, 50.624593],
            [39.091549, 50.611086],
            [39.055398, 50.625862],
            [39.046488, 50.617566],
            [39.083569, 50.605573],
            [39.075408, 50.59274],
            [39.094009, 50.579953],
            [39.081008, 50.53075],
            [39.092792, 50.515985],
            [39.124935, 50.534216],
            [39.127046, 50.507278],
            [39.047548, 50.492195],
            [39.052111, 50.473092],
            [39.034936, 50.47522],
            [39.014783, 50.455454],
            [38.981448, 50.438618],
            [39.008814, 50.413255],
            [39.02882, 50.413468],
            [39.039465, 50.396979],
            [39.036686, 50.379302],
            [39.064292, 50.353226],
            [39.089718, 50.352052],
            [39.101595, 50.339723],
            [39.070721, 50.321265],
            [39.044122, 50.316353],
            [39.034382, 50.287401],
            [39.047222, 50.276746],
            [39.084227, 50.275384],
            [39.117791, 50.268059],
            [39.101737, 50.254404],
            [39.119534, 50.226416],
            [39.137774, 50.224666],
            [39.139482, 50.18861],
            [39.128717, 50.185827],
            [39.116441, 50.156293],
            [39.140534, 50.152613],
            [39.159814, 50.140906],
            [39.152992, 50.121253],
            [39.169416, 50.116622],
            [39.191748, 50.090902],
            [39.210941, 50.090698],
            [39.237548, 50.101622],
            [39.241521, 50.082841],
            [39.208959, 50.078259],
            [39.227257, 50.065493],
            [39.217667, 50.048129],
            [39.253077, 50.033694],
            [39.256052, 50.041068],
            [39.280925, 50.035237],
            [39.252916, 49.985873],
            [39.221344, 49.978544],
            [39.205664, 49.969317],
            [39.209425, 49.948011],
            [39.187575, 49.94283],
            [39.206576, 49.937253],
            [39.211429, 49.926593],
            [39.191898, 49.924794],
            [39.177494, 49.897864],
            [39.179732, 49.888659],
            [39.13038, 49.86111],
            [39.10858, 49.8369],
            [39.06772, 49.81544],
            [39.02687, 49.82214],
            [38.94911, 49.79587],
            [38.93091, 49.7969],
            [38.90962, 49.81922],
            [38.91718, 49.85973],
            [38.89486, 49.87072],
            [38.84783, 49.86437],
            [38.82414, 49.87844],
            [38.78088, 49.88239],
            [38.76835, 49.89595],
            [38.74363, 49.89784],
            [38.72354, 49.92737],
            [38.69333, 49.93698],
            [38.68578, 49.96153],
            [38.71805, 49.9835],
            [38.68492, 50.00805],
            [38.67788, 49.99878],
            [38.67977, 49.97406],
            [38.66741, 49.97234],
            [38.6475, 49.95586],
            [38.61145, 49.9756],
            [38.58261, 49.97749],
            [38.52733, 49.95861],
            [38.48871, 49.96324],
            [38.47858, 49.98316],
            [38.46502, 49.98299],
            [38.46931, 49.99534],
            [38.43412, 50.00135],
            [38.41627, 49.98161],
            [38.39704, 49.9914],
            [38.35029, 50.00663],
            [38.351234, 50.035752],
            [38.333022, 50.059495],
            [38.329592, 50.085404],
            [38.301755, 50.087091],
            [38.27579, 50.073476],
            [38.252525, 50.072759],
            [38.17823, 50.079996],
            [38.18727, 50.040768],
            [38.18623, 50.02282],
            [38.221848, 49.979138],
            [38.195575, 49.969337],
            [38.197682, 49.962111],
            [38.1622, 49.951251],
            [38.171016, 49.941901],
            [38.13217, 49.92462],
            [38.124554, 49.942388],
            [38.055923, 49.923187],
            [38.032974, 49.900151],
            [38.022374, 49.909474],
            [38.02093, 49.925751],
            [38.04372, 49.932944],
            [38.004709, 49.967425],
            [37.964579, 49.978925],
            [37.92652, 50.0338],
            [37.88807, 50.05199],
            [37.86386, 50.05422],
            [37.8249, 50.06847],
            [37.79692, 50.08409],
            [37.75486, 50.07843],
            [37.70164, 50.13439],
            [37.66851, 50.15276],
            [37.63899, 50.17937],
            [37.64345, 50.19087],
            [37.61495, 50.21644],
            [37.62731, 50.24082],
            [37.62646, 50.29335],
            [37.63555, 50.31137],
            [37.60877, 50.31652],
            [37.57702, 50.30657],
            [37.55573, 50.32974],
            [37.52174, 50.33626],
            [37.50509, 50.3524],
            [37.48466, 50.35652],
            [37.46612, 50.38038],
            [37.46492, 50.43102],
            [37.484931, 50.431144],
            [37.49102, 50.45746],
            [37.431696, 50.443361],
            [37.420546, 50.434431],
            [37.396856, 50.431341],
            [37.332996, 50.437011],
            [37.309306, 50.425851],
            [37.292486, 50.401301],
            [37.256035, 50.392538],
            [37.181416, 50.363371],
            [37.147569, 50.356476],
            [37.137919, 50.361154],
            [37.102585, 50.35732],
            [37.102286, 50.351521],
            [37.044529, 50.344956],
            [37.037534, 50.348753],
            [36.93687, 50.350336],
            [36.87183, 50.33523],
            [36.83287, 50.31309],
            [36.76781, 50.30159],
            [36.76403, 50.29163],
            [36.71957, 50.27738],
            [36.70069, 50.26399],
            [36.69296, 50.26914],
            [36.67992, 50.23807],
            [36.66429, 50.22194],
            [36.64867, 50.21765],
            [36.59683, 50.22932],
            [36.56044, 50.25061],
            [36.58052, 50.26228],
            [36.57417, 50.27447],
            [36.593802, 50.275364],
            [36.58499, 50.2858],
            [36.55632, 50.28133],
            [36.5237, 50.28648],
            [36.47341, 50.31309],
            [36.43993, 50.3112],
            [36.43341, 50.33077],
            [36.4068, 50.31034],
            [36.37384, 50.2997],
            [36.36406, 50.28751],
            [36.29574, 50.29163],
            [36.28561, 50.29867],
            [36.27342, 50.32322],
            [36.28372, 50.33558],
            [36.25574, 50.35549],
            [36.23342, 50.36373],
            [36.23085, 50.3718],
            [36.19789, 50.40012],
            [36.17077, 50.40888],
            [36.16064, 50.43154],
            [36.11446, 50.43892],
            [36.06794, 50.45076],
            [35.9749, 50.44063],
            [35.9325, 50.43085],
            [35.92134, 50.43686],
            [35.88976, 50.43857],
            [35.87242, 50.42244],
            [35.8556, 50.42913],
            [35.83534, 50.42467],
            [35.83174, 50.43377],
            [35.79431, 50.41386],
            [35.80702, 50.4051],
            [35.79277, 50.38982],
            [35.73818, 50.35446],
            [35.67346, 50.35498],
            [35.62677, 50.35171],
            [35.6012, 50.36699],
            [35.61201, 50.37454],
            [35.58403, 50.39223],
            [35.58678, 50.4487],
            [35.56995, 50.46432],
            [35.56463, 50.45128],
            [35.53768, 50.45935],
            [35.503, 50.48012],
            [35.47588, 50.48904],
            [35.45786, 50.51308],
            [35.43623, 50.530284],
            [35.43898, 50.54449],
            [35.42782, 50.56217],
            [35.391723, 50.57963],
            [35.425498, 50.602509],
            [35.391486, 50.641734],
            [35.400493, 50.656372],
            [35.424753, 50.653647],
            [35.462811, 50.666182],
            [35.484918, 50.654958],
            [35.49649, 50.678678],
            [35.471548, 50.68106],
            [35.460073, 50.689009],
            [35.460767, 50.706182],
            [35.473745, 50.725368],
            [35.464059, 50.734668],
            [35.488713, 50.776804],
            [35.474479, 50.784847],
            [35.450043, 50.782717],
            [35.410347, 50.805038],
            [35.419954, 50.829164],
            [35.419191, 50.843905],
            [35.406341, 50.862435],
            [35.387121, 50.867027],
            [35.402196, 50.879769],
            [35.379973, 50.895675],
            [35.403127, 50.903078],
            [35.393005, 50.924714],
            [35.36741, 50.925919],
            [35.372585, 50.932614],
            [35.334472, 50.934469],
            [35.32612, 50.94681],
            [35.350987, 50.975007],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5513",
      properties: {
        name: "Брянская область",
        density: 0,
        path: "/world/Russia/Брянская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.762935, 53.424048],
            [32.769289, 53.413006],
            [32.79848, 53.425536],
            [32.788549, 53.438106],
            [32.804324, 53.438501],
            [32.857514, 53.427463],
            [32.880551, 53.434176],
            [32.900947, 53.465133],
            [32.87167, 53.473052],
            [32.891104, 53.499283],
            [32.862973, 53.494233],
            [32.862313, 53.511242],
            [32.879157, 53.527706],
            [32.895864, 53.525265],
            [32.911742, 53.544085],
            [32.894224, 53.558003],
            [32.91822, 53.58584],
            [32.951453, 53.58819],
            [32.951575, 53.606479],
            [32.975812, 53.619917],
            [32.99404, 53.619188],
            [33.044006, 53.654223],
            [33.035083, 53.662154],
            [33.069399, 53.661908],
            [33.067492, 53.689285],
            [33.0942, 53.694211],
            [33.140932, 53.725766],
            [33.184011, 53.726155],
            [33.235166, 53.738353],
            [33.224771, 53.743836],
            [33.221863, 53.767047],
            [33.211167, 53.780062],
            [33.218366, 53.792899],
            [33.197146, 53.796706],
            [33.19828, 53.807679],
            [33.223439, 53.804994],
            [33.233138, 53.795954],
            [33.265723, 53.801929],
            [33.298769, 53.816648],
            [33.303076, 53.826629],
            [33.28815, 53.832353],
            [33.289447, 53.844058],
            [33.263226, 53.843395],
            [33.257628, 53.855857],
            [33.271448, 53.864216],
            [33.245515, 53.870104],
            [33.251289, 53.891812],
            [33.286896, 53.896653],
            [33.303602, 53.911971],
            [33.276488, 53.926855],
            [33.280324, 53.938608],
            [33.317999, 53.948447],
            [33.340607, 53.944605],
            [33.344637, 53.92781],
            [33.408709, 53.946289],
            [33.430065, 53.973646],
            [33.449879, 53.978516],
            [33.431079, 53.989021],
            [33.432129, 54.000274],
            [33.493218, 54.007648],
            [33.504592, 54.016586],
            [33.52158, 53.998749],
            [33.527061, 54.010022],
            [33.570846, 54.02072],
            [33.588783, 54.034797],
            [33.598313, 54.022278],
            [33.619613, 54.029877],
            [33.627461, 54.021406],
            [33.655052, 54.027075],
            [33.715717, 54.023977],
            [33.73926, 54.012533],
            [33.751095, 53.998145],
            [33.798929, 53.990937],
            [33.817427, 53.992681],
            [33.813628, 53.975491],
            [33.797497, 53.965572],
            [33.798495, 53.949689],
            [33.826958, 53.948849],
            [33.83655, 53.937825],
            [33.801672, 53.937575],
            [33.80622, 53.89639],
            [33.838235, 53.881022],
            [33.889681, 53.879279],
            [33.888249, 53.872054],
            [33.911543, 53.867228],
            [33.931473, 53.874328],
            [33.964359, 53.873831],
            [33.963362, 53.880059],
            [33.99413, 53.884046],
            [34.011979, 53.865659],
            [34.002403, 53.859622],
            [34.045703, 53.86212],
            [34.047784, 53.869406],
            [34.074014, 53.86795],
            [34.114814, 53.886894],
            [34.132425, 53.88697],
            [34.18062, 53.900824],
            [34.201182, 53.912152],
            [34.22499, 53.886009],
            [34.231483, 53.863876],
            [34.227645, 53.839432],
            [34.270841, 53.822407],
            [34.291796, 53.825985],
            [34.307983, 53.80131],
            [34.355956, 53.767889],
            [34.349012, 53.750961],
            [34.369201, 53.732143],
            [34.407544, 53.734498],
            [34.425024, 53.708337],
            [34.417432, 53.700391],
            [34.441641, 53.682635],
            [34.470559, 53.683979],
            [34.47485, 53.671996],
            [34.496489, 53.673982],
            [34.488385, 53.654692],
            [34.500615, 53.648078],
            [34.472724, 53.635961],
            [34.497294, 53.615777],
            [34.51192, 53.584867],
            [34.497578, 53.58304],
            [34.501547, 53.543076],
            [34.517695, 53.543982],
            [34.519712, 53.500843],
            [34.541039, 53.496995],
            [34.544654, 53.481917],
            [34.568537, 53.470416],
            [34.565359, 53.457194],
            [34.571712, 53.428653],
            [34.606004, 53.426675],
            [34.605119, 53.41378],
            [34.638643, 53.398411],
            [34.63795, 53.383027],
            [34.700278, 53.37839],
            [34.733558, 53.383212],
            [34.781648, 53.380471],
            [34.796145, 53.38555],
            [34.83255, 53.377487],
            [34.860578, 53.389485],
            [34.8599, 53.362124],
            [34.899124, 53.37333],
            [34.944937, 53.376376],
            [34.982773, 53.373609],
            [35.023286, 53.362393],
            [35.019671, 53.345183],
            [35.030479, 53.334486],
            [35.066726, 53.333814],
            [35.084134, 53.32481],
            [35.074702, 53.309023],
            [35.082703, 53.29898],
            [35.141099, 53.279123],
            [35.130986, 53.256716],
            [35.10801, 53.25686],
            [35.10567, 53.227345],
            [35.135351, 53.197978],
            [35.11377, 53.184664],
            [35.129952, 53.180396],
            [35.121884, 53.15423],
            [35.127382, 53.139148],
            [35.162496, 53.141999],
            [35.174634, 53.118336],
            [35.216698, 53.127218],
            [35.240672, 53.123099],
            [35.269924, 53.108502],
            [35.276879, 53.081587],
            [35.325127, 53.079388],
            [35.270764, 53.047156],
            [35.292656, 53.041674],
            [35.28358, 53.019737],
            [35.255159, 53.018224],
            [35.256169, 53.006411],
            [35.240067, 52.994564],
            [35.199766, 53.009654],
            [35.176485, 53.010769],
            [35.152291, 52.987177],
            [35.144872, 52.97133],
            [35.155583, 52.957383],
            [35.130944, 52.965379],
            [35.090962, 52.949818],
            [35.052545, 52.966675],
            [35.034823, 52.96192],
            [35.038173, 52.951114],
            [35.014615, 52.954247],
            [35.009968, 52.965053],
            [34.983277, 52.966242],
            [34.995489, 52.948952],
            [34.960477, 52.948843],
            [34.965862, 52.913547],
            [34.954546, 52.926523],
            [34.902364, 52.929095],
            [34.912606, 52.919558],
            [34.875969, 52.916812],
            [34.852791, 52.898069],
            [34.868078, 52.900174],
            [34.879286, 52.886976],
            [34.850649, 52.884684],
            [34.861878, 52.868225],
            [34.825726, 52.865741],
            [34.831292, 52.846506],
            [34.861173, 52.808901],
            [34.880949, 52.800364],
            [34.906993, 52.778644],
            [34.925741, 52.800581],
            [34.960244, 52.780115],
            [34.976353, 52.77991],
            [34.974416, 52.767466],
            [34.98904, 52.756071],
            [35.006847, 52.765363],
            [35.041037, 52.756281],
            [35.037595, 52.733825],
            [35.016686, 52.719762],
            [35.015803, 52.709161],
            [35.036945, 52.695448],
            [35.09081, 52.715167],
            [35.109214, 52.70427],
            [35.079504, 52.68339],
            [35.083719, 52.677103],
            [35.039507, 52.659344],
            [35.003767, 52.660101],
            [34.99923, 52.643351],
            [35.007767, 52.623035],
            [35.02522, 52.610608],
            [35.009011, 52.592454],
            [35.002204, 52.566843],
            [35.006582, 52.540908],
            [34.991555, 52.50716],
            [34.922607, 52.512414],
            [34.913542, 52.506759],
            [34.903061, 52.474124],
            [34.888797, 52.472827],
            [34.854596, 52.451538],
            [34.869402, 52.429277],
            [34.848439, 52.398047],
            [34.869728, 52.368114],
            [34.900958, 52.378488],
            [34.924193, 52.369988],
            [34.911457, 52.347189],
            [34.921535, 52.339164],
            [34.950586, 52.347376],
            [34.960167, 52.342959],
            [34.936093, 52.323052],
            [34.94107, 52.313659],
            [34.917164, 52.301115],
            [34.918178, 52.291636],
            [34.887945, 52.278386],
            [34.887075, 52.266131],
            [34.859579, 52.261154],
            [34.881322, 52.244793],
            [34.903032, 52.245042],
            [34.884059, 52.229428],
            [34.869013, 52.202119],
            [34.807327, 52.186254],
            [34.761107, 52.188804],
            [34.772055, 52.200748],
            [34.757, 52.212007],
            [34.729317, 52.215304],
            [34.701666, 52.207839],
            [34.701853, 52.196579],
            [34.683128, 52.196703],
            [34.694376, 52.15911],
            [34.726352, 52.15202],
            [34.728076, 52.135428],
            [34.71914, 52.125356],
            [34.744338, 52.115486],
            [34.742774, 52.087892],
            [34.696157, 52.061123],
            [34.658035, 52.067867],
            [34.628166, 52.05351],
            [34.609376, 52.036475],
            [34.576697, 52.038692],
            [34.585187, 52.05188],
            [34.545471, 52.071284],
            [34.527333, 52.068489],
            [34.506587, 52.054927],
            [34.492933, 52.036825],
            [34.458378, 52.016917],
            [34.43281, 52.014802],
            [34.403542, 51.993775],
            [34.40323, 51.972957],
            [34.378722, 51.957818],
            [34.36413, 51.941633],
            [34.340515, 51.945419],
            [34.334116, 51.933741],
            [34.370357, 51.926092],
            [34.388149, 51.914708],
            [34.419782, 51.906808],
            [34.452758, 51.921475],
            [34.445474, 51.896855],
            [34.382268, 51.877913],
            [34.388351, 51.872482],
            [34.385548, 51.843075],
            [34.368048, 51.845519],
            [34.361294, 51.858883],
            [34.330786, 51.870134],
            [34.302856, 51.88717],
            [34.274677, 51.887492],
            [34.26293, 51.878404],
            [34.250087, 51.916183],
            [34.232564, 51.912919],
            [34.225812, 51.928197],
            [34.203434, 51.940982],
            [34.18959, 51.968942],
            [34.14241, 51.968086],
            [34.128859, 51.984272],
            [34.139722, 51.999722],
            [34.12755, 52.005919],
            [34.096917, 52.004262],
            [34.091626, 52.030476],
            [34.101178, 52.040843],
            [34.082898, 52.057717],
            [34.080837, 52.070686],
            [34.062654, 52.07306],
            [34.099677, 52.093313],
            [34.087168, 52.108849],
            [34.109597, 52.121374],
            [34.116327, 52.141215],
            [34.089156, 52.154834],
            [34.075388, 52.15469],
            [34.054674, 52.171419],
            [34.065318, 52.183274],
            [34.05262, 52.200979],
            [34.016368, 52.201669],
            [34.000566, 52.208805],
            [34.000818, 52.227026],
            [33.963953, 52.234283],
            [33.960173, 52.247655],
            [33.936844, 52.24979],
            [33.944391, 52.282943],
            [33.924361, 52.294935],
            [33.92209, 52.304551],
            [33.875571, 52.305571],
            [33.839296, 52.320039],
            [33.854318, 52.332028],
            [33.837733, 52.349775],
            [33.836472, 52.361389],
            [33.788115, 52.366295],
            [33.724449, 52.353479],
            [33.7171, 52.362473],
            [33.677819, 52.354607],
            [33.679399, 52.347329],
            [33.655378, 52.338995],
            [33.605347, 52.333483],
            [33.563831, 52.313307],
            [33.560506, 52.301927],
            [33.504908, 52.306075],
            [33.480768, 52.316059],
            [33.48987, 52.329483],
            [33.51343, 52.334539],
            [33.518636, 52.354675],
            [33.472191, 52.358706],
            [33.45452, 52.36497],
            [33.438957, 52.352765],
            [33.446633, 52.341885],
            [33.412799, 52.349803],
            [33.406996, 52.366872],
            [33.380563, 52.371641],
            [33.35121, 52.353093],
            [33.288863, 52.357681],
            [33.207924, 52.375614],
            [33.169904, 52.372834],
            [33.169563, 52.340846],
            [33.136956, 52.345905],
            [33.111089, 52.325415],
            [33.066987, 52.302594],
            [33.058927, 52.318267],
            [33.018423, 52.293243],
            [32.997026, 52.288066],
            [32.995741, 52.27186],
            [32.961558, 52.273206],
            [32.9103, 52.250018],
            [32.887559, 52.245098],
            [32.879133, 52.258832],
            [32.884404, 52.273371],
            [32.833022, 52.278295],
            [32.817052, 52.258938],
            [32.799242, 52.265394],
            [32.762846, 52.255492],
            [32.756589, 52.266798],
            [32.700589, 52.247901],
            [32.685125, 52.266307],
            [32.637769, 52.286625],
            [32.606953, 52.305039],
            [32.577215, 52.300189],
            [32.550034, 52.326866],
            [32.501711, 52.327295],
            [32.489679, 52.314381],
            [32.459889, 52.326826],
            [32.433806, 52.322026],
            [32.401073, 52.338035],
            [32.376832, 52.337308],
            [32.35516, 52.327346],
            [32.351885, 52.316218],
            [32.363603, 52.300643],
            [32.370301, 52.274954],
            [32.394855, 52.278191],
            [32.382944, 52.269747],
            [32.401708, 52.248171],
            [32.360878, 52.238358],
            [32.346698, 52.244134],
            [32.324542, 52.223413],
            [32.330555, 52.202801],
            [32.346867, 52.182471],
            [32.328471, 52.164922],
            [32.331329, 52.15574],
            [32.365222, 52.155322],
            [32.360139, 52.139895],
            [32.320269, 52.136199],
            [32.31648, 52.12104],
            [32.293491, 52.118272],
            [32.290663, 52.103039],
            [32.252748, 52.088922],
            [32.224624, 52.08719],
            [32.191545, 52.070805],
            [32.147289, 52.069238],
            [32.147279, 52.057838],
            [32.125715, 52.045755],
            [32.096473, 52.043507],
            [32.095886, 52.033117],
            [32.065939, 52.034699],
            [32.035918, 52.045072],
            [31.962721, 52.053321],
            [31.951322, 52.046317],
            [31.917152, 52.053382],
            [31.958203, 52.067945],
            [31.954161, 52.08159],
            [31.901566, 52.10261],
            [31.855163, 52.110816],
            [31.809871, 52.098639],
            [31.781789, 52.111907],
            [31.7928, 52.12601],
            [31.78422, 52.13528],
            [31.78104, 52.18557],
            [31.73736, 52.19278],
            [31.70835, 52.18635],
            [31.68732, 52.19424],
            [31.70929, 52.21484],
            [31.69659, 52.25673],
            [31.71247, 52.26368],
            [31.68354, 52.27767],
            [31.66028, 52.2763],
            [31.62964, 52.29784],
            [31.62681, 52.29011],
            [31.58535, 52.31972],
            [31.58183, 52.32822],
            [31.62518, 52.3344],
            [31.62071, 52.34101],
            [31.63256, 52.39818],
            [31.621639, 52.418336],
            [31.594092, 52.414901],
            [31.602493, 52.423285],
            [31.596662, 52.439861],
            [31.609878, 52.440812],
            [31.61084, 52.45345],
            [31.5796, 52.45929],
            [31.60732, 52.46684],
            [31.62612, 52.4925],
            [31.60509, 52.50443],
            [31.5796, 52.49534],
            [31.55763, 52.51765],
            [31.59016, 52.52083],
            [31.61024, 52.54915],
            [31.62363, 52.55327],
            [31.63127, 52.54194],
            [31.65217, 52.545071],
            [31.668427, 52.559863],
            [31.630421, 52.562756],
            [31.617056, 52.576296],
            [31.593368, 52.582801],
            [31.579719, 52.594406],
            [31.564194, 52.594245],
            [31.569742, 52.635099],
            [31.560184, 52.647306],
            [31.51523, 52.67704],
            [31.50312, 52.6985],
            [31.56432, 52.70888],
            [31.575067, 52.727587],
            [31.60063, 52.74605],
            [31.58793, 52.7621],
            [31.59496, 52.77],
            [31.58698, 52.79377],
            [31.56492, 52.79772],
            [31.56175, 52.80681],
            [31.53694, 52.81583],
            [31.51943, 52.83153],
            [31.53626, 52.83943],
            [31.53823, 52.85643],
            [31.48081, 52.86054],
            [31.459299, 52.875739],
            [31.440771, 52.880887],
            [31.40785, 52.91041],
            [31.39798, 52.90947],
            [31.37189, 52.93358],
            [31.37824, 52.95299],
            [31.36202, 52.9656],
            [31.355342, 52.984078],
            [31.3276, 52.9911],
            [31.30271, 53.00586],
            [31.28966, 53.00586],
            [31.2434, 53.03435],
            [31.26349, 53.04276],
            [31.306879, 53.03655],
            [31.330609, 53.041807],
            [31.327519, 53.066425],
            [31.33478, 53.091232],
            [31.3912, 53.096492],
            [31.395007, 53.115755],
            [31.367251, 53.125097],
            [31.36356, 53.14396],
            [31.39446, 53.15615],
            [31.39858, 53.17091],
            [31.41841, 53.18764],
            [31.40184, 53.21185],
            [31.50029, 53.20447],
            [31.53849, 53.19881],
            [31.54334, 53.190819],
            [31.58664, 53.19597],
            [31.62835, 53.23108],
            [31.644211, 53.220267],
            [31.706898, 53.206159],
            [31.730116, 53.19366],
            [31.745463, 53.199553],
            [31.773923, 53.180365],
            [31.786399, 53.179727],
            [31.801241, 53.137167],
            [31.830955, 53.110379],
            [31.869678, 53.107081],
            [31.88021, 53.114909],
            [31.922545, 53.104985],
            [31.913481, 53.088873],
            [31.934674, 53.083064],
            [31.992581, 53.085337],
            [31.992586, 53.093286],
            [32.019064, 53.100115],
            [32.018036, 53.090851],
            [32.094753, 53.076191],
            [32.173158, 53.088756],
            [32.176917, 53.104673],
            [32.202711, 53.107881],
            [32.235101, 53.098968],
            [32.235654, 53.118695],
            [32.254731, 53.131443],
            [32.302219, 53.127664],
            [32.332621, 53.144337],
            [32.344125, 53.158826],
            [32.338895, 53.171792],
            [32.357738, 53.185125],
            [32.386043, 53.190907],
            [32.414345, 53.186095],
            [32.446819, 53.207991],
            [32.455046, 53.220837],
            [32.49257, 53.253659],
            [32.51009, 53.29468],
            [32.48846, 53.30352],
            [32.52254, 53.30669],
            [32.52193, 53.29656],
            [32.55386, 53.30146],
            [32.61558, 53.32051],
            [32.61017, 53.33373],
            [32.66244, 53.33253],
            [32.6857, 53.3418],
            [32.70192, 53.33107],
            [32.7335, 53.33253],
            [32.73926, 53.34806],
            [32.72295, 53.37407],
            [32.75548, 53.39707],
            [32.73986, 53.4084],
            [32.73926, 53.42256],
            [32.762935, 53.424048],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5466",
      properties: {
        name: "Бурятия",
        density: 0,
        path: "/world/Russia/Бурятия",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [99.230567, 52.963186],
            [99.240532, 52.986425],
            [99.300652, 52.997709],
            [99.318427, 52.995095],
            [99.331845, 53.002849],
            [99.342475, 53.023978],
            [99.369922, 53.034086],
            [99.366785, 53.060181],
            [99.379245, 53.066368],
            [99.355545, 53.080526],
            [99.372187, 53.101895],
            [99.390659, 53.101024],
            [99.400243, 53.111305],
            [99.439539, 53.110347],
            [99.451912, 53.117753],
            [99.485981, 53.112525],
            [99.496029, 53.116483],
            [99.530766, 53.105663],
            [99.575116, 53.118668],
            [99.601517, 53.135593],
            [99.643253, 53.144219],
            [99.677757, 53.142476],
            [99.716792, 53.149556],
            [99.738836, 53.169029],
            [99.755129, 53.171295],
            [99.795123, 53.20007],
            [99.841302, 53.19678],
            [99.871363, 53.228474],
            [99.864131, 53.244593],
            [99.846704, 53.257707],
            [99.851758, 53.286982],
            [99.876242, 53.294955],
            [99.872321, 53.31212],
            [99.902381, 53.302971],
            [99.938977, 53.309767],
            [99.962241, 53.308809],
            [99.977837, 53.326257],
            [100.026021, 53.32495],
            [100.037871, 53.310922],
            [100.090411, 53.304125],
            [100.115592, 53.313013],
            [100.123869, 53.342594],
            [100.145217, 53.351481],
            [100.167087, 53.350523],
            [100.189392, 53.367731],
            [100.230867, 53.374984],
            [100.265894, 53.372066],
            [100.286283, 53.362655],
            [100.297087, 53.348627],
            [100.290204, 53.323033],
            [100.330807, 53.29502],
            [100.331765, 53.288921],
            [100.394674, 53.264547],
            [100.421104, 53.249162],
            [100.426042, 53.236447],
            [100.40208, 53.233746],
            [100.397114, 53.226166],
            [100.414627, 53.218498],
            [100.384393, 53.204427],
            [100.402342, 53.203729],
            [100.407308, 53.194145],
            [100.382824, 53.192381],
            [100.384654, 53.185323],
            [100.344661, 53.17038],
            [100.335512, 53.161319],
            [100.357295, 53.159293],
            [100.372369, 53.150144],
            [100.359299, 53.137685],
            [100.38448, 53.115248],
            [100.377597, 53.104531],
            [100.399728, 53.090895],
            [100.421162, 53.086887],
            [100.431183, 53.075713],
            [100.488167, 53.058004],
            [100.500626, 53.035045],
            [100.539923, 53.036178],
            [100.544541, 53.017314],
            [100.559876, 53.012347],
            [100.572684, 52.986905],
            [100.592899, 52.982592],
            [100.599521, 52.95031],
            [100.655546, 52.920512],
            [100.721418, 52.908923],
            [100.754963, 52.888971],
            [100.766552, 52.888099],
            [100.81369, 52.864509],
            [100.832859, 52.843576],
            [100.865359, 52.81748],
            [100.872242, 52.803452],
            [100.91058, 52.783194],
            [100.934018, 52.786157],
            [100.952403, 52.77777],
            [100.988911, 52.774721],
            [101.005118, 52.7796],
            [101.022283, 52.766095],
            [101.053911, 52.764134],
            [101.07369, 52.748015],
            [101.081433, 52.760074],
            [101.093302, 52.736543],
            [101.127042, 52.714552],
            [101.171102, 52.70421],
            [101.214722, 52.698883],
            [101.218241, 52.684127],
            [101.24107, 52.67158],
            [101.268985, 52.666022],
            [101.278001, 52.6513],
            [101.293926, 52.643924],
            [101.312258, 52.646084],
            [101.340958, 52.639267],
            [101.416426, 52.632261],
            [101.458035, 52.595493],
            [101.457069, 52.5704],
            [101.468221, 52.559073],
            [101.49109, 52.556573],
            [101.530259, 52.530995],
            [101.547812, 52.531492],
            [101.56973, 52.505183],
            [101.595695, 52.491002],
            [101.609354, 52.491152],
            [101.632461, 52.479218],
            [101.677511, 52.475645],
            [101.685373, 52.45168],
            [101.674608, 52.44848],
            [101.691714, 52.416919],
            [101.68666, 52.404721],
            [101.72316, 52.409037],
            [101.755929, 52.381936],
            [101.808731, 52.367015],
            [101.828102, 52.354835],
            [101.860748, 52.3485],
            [101.886452, 52.332969],
            [101.926164, 52.317848],
            [101.963041, 52.315434],
            [101.921392, 52.302343],
            [101.899609, 52.284786],
            [101.918952, 52.255815],
            [101.913637, 52.24109],
            [101.926968, 52.220832],
            [101.949884, 52.219089],
            [101.961237, 52.226663],
            [102.008523, 52.21201],
            [102.061197, 52.215307],
            [102.086332, 52.226583],
            [102.083195, 52.257383],
            [102.111861, 52.261413],
            [102.119354, 52.274396],
            [102.146104, 52.27396],
            [102.153423, 52.282259],
            [102.187458, 52.280304],
            [102.19359, 52.264005],
            [102.256761, 52.262611],
            [102.268785, 52.241395],
            [102.301198, 52.229981],
            [102.32481, 52.233989],
            [102.332826, 52.242702],
            [102.356003, 52.244924],
            [102.375434, 52.262873],
            [102.393482, 52.263629],
            [102.449757, 52.232072],
            [102.484261, 52.236603],
            [102.500032, 52.229197],
            [102.515452, 52.234572],
            [102.56782, 52.219569],
            [102.559804, 52.202055],
            [102.571015, 52.185842],
            [102.565729, 52.177789],
            [102.581848, 52.146357],
            [102.610817, 52.12562],
            [102.617318, 52.110608],
            [102.670918, 52.086828],
            [102.67046, 52.060686],
            [102.698168, 52.050797],
            [102.719167, 52.028491],
            [102.746572, 52.021346],
            [102.764301, 51.994902],
            [102.811178, 51.976365],
            [102.828691, 51.980025],
            [102.858141, 51.968611],
            [102.855963, 51.948026],
            [102.877049, 51.934652],
            [102.926365, 51.912237],
            [102.987357, 51.899407],
            [103.059328, 51.90225],
            [103.082974, 51.879254],
            [103.111033, 51.867562],
            [103.077015, 51.856441],
            [103.126855, 51.842217],
            [103.128684, 51.835573],
            [103.178001, 51.833918],
            [103.226932, 51.80512],
            [103.21172, 51.7902],
            [103.223483, 51.771445],
            [103.197518, 51.759116],
            [103.200132, 51.739054],
            [103.179308, 51.722303],
            [103.198389, 51.687408],
            [103.235434, 51.666353],
            [103.206231, 51.653819],
            [103.187324, 51.636785],
            [103.181311, 51.619315],
            [103.208322, 51.60215],
            [103.237511, 51.570173],
            [103.215118, 51.553445],
            [103.234374, 51.551528],
            [103.242565, 51.535278],
            [103.241083, 51.506176],
            [103.269924, 51.4962],
            [103.30103, 51.495503],
            [103.326646, 51.475833],
            [103.338496, 51.446557],
            [103.364374, 51.433988],
            [103.391472, 51.456141],
            [103.388684, 51.470082],
            [103.421271, 51.464419],
            [103.433208, 51.455096],
            [103.462397, 51.468862],
            [103.492719, 51.463025],
            [103.508577, 51.472304],
            [103.538632, 51.470984],
            [103.547786, 51.479884],
            [103.56739, 51.479013],
            [103.586472, 51.490601],
            [103.800815, 51.434838],
            [103.804649, 51.32416],
            [103.810084, 51.130227],
            [103.841069, 51.134063],
            [103.843771, 51.143212],
            [103.893174, 51.156456],
            [103.919139, 51.151402],
            [103.983355, 51.193225],
            [103.985185, 51.213787],
            [104.016726, 51.221825],
            [104.038335, 51.220758],
            [104.066653, 51.204987],
            [104.121894, 51.224809],
            [104.127645, 51.237705],
            [104.149776, 51.242453],
            [104.135225, 51.261796],
            [104.152826, 51.263234],
            [104.196914, 51.284058],
            [104.231331, 51.282315],
            [104.259823, 51.290419],
            [104.267665, 51.280224],
            [104.317678, 51.283187],
            [104.326565, 51.287456],
            [104.343643, 51.277414],
            [104.360285, 51.277501],
            [104.399494, 51.289569],
            [104.419099, 51.289482],
            [104.454997, 51.303967],
            [104.460225, 51.314772],
            [104.487061, 51.321742],
            [104.561907, 51.286236],
            [104.589789, 51.286868],
            [104.602946, 51.299001],
            [104.637189, 51.304403],
            [104.651827, 51.325358],
            [104.672738, 51.337817],
            [104.658972, 51.359382],
            [104.659494, 51.38086],
            [104.667946, 51.389137],
            [104.665245, 51.405605],
            [104.635272, 51.410702],
            [104.638515, 51.456042],
            [104.627661, 51.482858],
            [104.587705, 51.622183],
            [104.601498, 51.631356],
            [104.753439, 51.652049],
            [104.951034, 51.68234],
            [105.004265, 51.688857],
            [105.245418, 51.733833],
            [105.308535, 51.748854],
            [105.479826, 51.804316],
            [105.602595, 51.851716],
            [105.773819, 51.956291],
            [105.823638, 51.999338],
            [105.991702, 52.184285],
            [106.172533, 52.361055],
            [106.999934, 52.716939],
            [107.248888, 52.84479],
            [108.013798, 53.235626],
            [108.048128, 53.276272],
            [108.075161, 53.332176],
            [108.297403, 53.673511],
            [108.373418, 53.764052],
            [108.451478, 53.848489],
            [108.635572, 54.00124],
            [109.001352, 54.335516],
            [109.068144, 54.426243],
            [108.887624, 54.473349],
            [108.669556, 54.536483],
            [108.631518, 54.545774],
            [108.576761, 54.5384],
            [108.546178, 54.543454],
            [108.548966, 54.550598],
            [108.581292, 54.567349],
            [108.605253, 54.567698],
            [108.634007, 54.5863],
            [108.649952, 54.612831],
            [108.650736, 54.629255],
            [108.636795, 54.639537],
            [108.660266, 54.654665],
            [108.673565, 54.646856],
            [108.686667, 54.660491],
            [108.701884, 54.656122],
            [108.721662, 54.728127],
            [108.707285, 54.735097],
            [108.723492, 54.774872],
            [108.726168, 54.794738],
            [108.708505, 54.815954],
            [108.72544, 54.835136],
            [108.727239, 54.859454],
            [108.711991, 54.875573],
            [108.718257, 54.900716],
            [108.734384, 54.91818],
            [108.760949, 54.932696],
            [108.738915, 54.942586],
            [108.731751, 54.957844],
            [108.695175, 54.979171],
            [108.686059, 55.02013],
            [108.697524, 55.062726],
            [108.714728, 55.073957],
            [108.71952, 55.087478],
            [108.745618, 55.105546],
            [108.733892, 55.129097],
            [108.768549, 55.14366],
            [108.763247, 55.154804],
            [108.796959, 55.166257],
            [108.774028, 55.191625],
            [108.794136, 55.206911],
            [108.784639, 55.23297],
            [108.753909, 55.243146],
            [108.735347, 55.242729],
            [108.708738, 55.25283],
            [108.716864, 55.271407],
            [108.70882, 55.287437],
            [108.68991, 55.287996],
            [108.652959, 55.29976],
            [108.671484, 55.31776],
            [108.661154, 55.334531],
            [108.672217, 55.359455],
            [108.667679, 55.396397],
            [108.652729, 55.423135],
            [108.60667, 55.431677],
            [108.606511, 55.439832],
            [108.640832, 55.458832],
            [108.639338, 55.489723],
            [108.658491, 55.499326],
            [108.691056, 55.494649],
            [108.704029, 55.499434],
            [108.69633, 55.53357],
            [108.672371, 55.560899],
            [108.672456, 55.57288],
            [108.69359, 55.580373],
            [108.682294, 55.605975],
            [108.73064, 55.614078],
            [108.775659, 55.61104],
            [108.792171, 55.639142],
            [108.783703, 55.646569],
            [108.794488, 55.690311],
            [108.81032, 55.699317],
            [108.809292, 55.716904],
            [108.796457, 55.722869],
            [108.781577, 55.751346],
            [108.785618, 55.772824],
            [108.813839, 55.795054],
            [108.817434, 55.817163],
            [108.82932, 55.823783],
            [108.823035, 55.848072],
            [108.846626, 55.864507],
            [108.879256, 55.877951],
            [108.904161, 55.87729],
            [108.92138, 55.891848],
            [108.927142, 55.911513],
            [108.941301, 55.909564],
            [108.969228, 55.938905],
            [108.989373, 55.939633],
            [109.004855, 55.931156],
            [108.997666, 55.915937],
            [109.01403, 55.923829],
            [109.037526, 55.918366],
            [109.04403, 55.93306],
            [109.061915, 55.928343],
            [109.080875, 55.911808],
            [109.13571, 55.917464],
            [109.135652, 55.905203],
            [109.164692, 55.915634],
            [109.187989, 55.90775],
            [109.246675, 55.923688],
            [109.235084, 55.932534],
            [109.240446, 55.947048],
            [109.267174, 55.961668],
            [109.250858, 55.972379],
            [109.239913, 55.994201],
            [109.240161, 56.018805],
            [109.199294, 55.987561],
            [109.170199, 55.985901],
            [109.119339, 56.004307],
            [109.101781, 56.004509],
            [109.087133, 56.014996],
            [109.06629, 56.011961],
            [109.034617, 55.99671],
            [109.02256, 56.007414],
            [108.986295, 56.006484],
            [108.977452, 56.020885],
            [108.953405, 56.02863],
            [108.943129, 56.040529],
            [108.954557, 56.045716],
            [108.953405, 56.066754],
            [108.924577, 56.076644],
            [108.92508, 56.085773],
            [108.8947, 56.086457],
            [108.889121, 56.107563],
            [108.922169, 56.123171],
            [108.906168, 56.136925],
            [108.908735, 56.149248],
            [108.886229, 56.151772],
            [108.861688, 56.175638],
            [108.868771, 56.186991],
            [108.834883, 56.191008],
            [108.831099, 56.210413],
            [108.80515, 56.234776],
            [108.784907, 56.233283],
            [108.772584, 56.248772],
            [108.746569, 56.233796],
            [108.702222, 56.252763],
            [108.651599, 56.241405],
            [108.64926, 56.257179],
            [108.61132, 56.270767],
            [108.581759, 56.301804],
            [108.585182, 56.314359],
            [108.5593, 56.32099],
            [108.549329, 56.332141],
            [108.561429, 56.348897],
            [108.580164, 56.343589],
            [108.602257, 56.36835],
            [108.585778, 56.375994],
            [108.608517, 56.407937],
            [108.654456, 56.420787],
            [108.651496, 56.438151],
            [108.663149, 56.452436],
            [108.69842, 56.46596],
            [108.703854, 56.478731],
            [108.740097, 56.504686],
            [108.732986, 56.532067],
            [108.670854, 56.566617],
            [108.702714, 56.579446],
            [108.770375, 56.572232],
            [108.817796, 56.588045],
            [108.840858, 56.578728],
            [108.848535, 56.585592],
            [108.883355, 56.591609],
            [108.903861, 56.58077],
            [108.956613, 56.608739],
            [108.948207, 56.624659],
            [108.981358, 56.631163],
            [108.999595, 56.645393],
            [109.015054, 56.645634],
            [109.011429, 56.623043],
            [109.060805, 56.610665],
            [109.073964, 56.628061],
            [109.080827, 56.660443],
            [109.115844, 56.66335],
            [109.142796, 56.679836],
            [109.158031, 56.670032],
            [109.187712, 56.663681],
            [109.214446, 56.66621],
            [109.232027, 56.650954],
            [109.288164, 56.672817],
            [109.318025, 56.672617],
            [109.323313, 56.689447],
            [109.347894, 56.699751],
            [109.358185, 56.713904],
            [109.378493, 56.717058],
            [109.423648, 56.738455],
            [109.447291, 56.73207],
            [109.469971, 56.735587],
            [109.48512, 56.725874],
            [109.505875, 56.736536],
            [109.550381, 56.741103],
            [109.553482, 56.730619],
            [109.595085, 56.711188],
            [109.604865, 56.69792],
            [109.6314, 56.702687],
            [109.6879, 56.69595],
            [109.711869, 56.705186],
            [109.745201, 56.701143],
            [109.768487, 56.707834],
            [109.776613, 56.729901],
            [109.802394, 56.739103],
            [109.808622, 56.754101],
            [109.834489, 56.761521],
            [109.853111, 56.77911],
            [109.880948, 56.779936],
            [109.890831, 56.793695],
            [109.861913, 56.810855],
            [109.863884, 56.828809],
            [109.846152, 56.846413],
            [109.877993, 56.867274],
            [109.872337, 56.877618],
            [109.886764, 56.898718],
            [109.92423, 56.927158],
            [109.950859, 56.917943],
            [109.971553, 56.946423],
            [109.968998, 56.967713],
            [109.993805, 56.963562],
            [110.013516, 56.975784],
            [110.043852, 56.978364],
            [110.055587, 56.972079],
            [110.085546, 56.988024],
            [110.106397, 56.970138],
            [110.169447, 56.955016],
            [110.18939, 56.934206],
            [110.216932, 56.930371],
            [110.236033, 56.934661],
            [110.242671, 56.946636],
            [110.270846, 56.952484],
            [110.369264, 56.9432],
            [110.394013, 56.928001],
            [110.388608, 56.911903],
            [110.440285, 56.898698],
            [110.469624, 56.897763],
            [110.491932, 56.905135],
            [110.508202, 56.895323],
            [110.490755, 56.86955],
            [110.500766, 56.85542],
            [110.576459, 56.835035],
            [110.629337, 56.836687],
            [110.665553, 56.858984],
            [110.694514, 56.869872],
            [110.699774, 56.883071],
            [110.713102, 56.880428],
            [110.756987, 56.887228],
            [110.801305, 56.867668],
            [110.837142, 56.84103],
            [110.874582, 56.849203],
            [110.882536, 56.843344],
            [110.947572, 56.859378],
            [110.982002, 56.874279],
            [111.018625, 56.85465],
            [111.071031, 56.834915],
            [111.092756, 56.815703],
            [111.11212, 56.822805],
            [111.160855, 56.822461],
            [111.184109, 56.841977],
            [111.202212, 56.84152],
            [111.210609, 56.867019],
            [111.24157, 56.892394],
            [111.342361, 56.896154],
            [111.368447, 56.931778],
            [111.34703, 56.946904],
            [111.333906, 56.969545],
            [111.374676, 56.989405],
            [111.385642, 57.004823],
            [111.390749, 57.030826],
            [111.447987, 57.060124],
            [111.447438, 57.074909],
            [111.478508, 57.079254],
            [111.47192, 57.090547],
            [111.499414, 57.117463],
            [111.532251, 57.131254],
            [111.549524, 57.119153],
            [111.609594, 57.102289],
            [111.608781, 57.086181],
            [111.592829, 57.059881],
            [111.594298, 57.040849],
            [111.615676, 57.01114],
            [111.680897, 57.000444],
            [111.698284, 56.980829],
            [111.722762, 56.978161],
            [111.750761, 56.962716],
            [111.768867, 56.946074],
            [111.767659, 56.931941],
            [111.789741, 56.909677],
            [111.831171, 56.912777],
            [111.848783, 56.92271],
            [111.847661, 56.935642],
            [111.876303, 56.951835],
            [111.908483, 56.944405],
            [111.922917, 56.952775],
            [111.95293, 56.948712],
            [111.98859, 56.954822],
            [112.014092, 56.943629],
            [112.046051, 56.943847],
            [112.065315, 56.957098],
            [112.083403, 56.956536],
            [112.118266, 56.938481],
            [112.125262, 56.921857],
            [112.18843, 56.881028],
            [112.217937, 56.857332],
            [112.257554, 56.867247],
            [112.324892, 56.866112],
            [112.3356, 56.871389],
            [112.402457, 56.857649],
            [112.432787, 56.859863],
            [112.454851, 56.872797],
            [112.452449, 56.888207],
            [112.472535, 56.907153],
            [112.49465, 56.91736],
            [112.518875, 56.918295],
            [112.550479, 56.946112],
            [112.573801, 56.958113],
            [112.587965, 56.951256],
            [112.621983, 56.955174],
            [112.645189, 56.96595],
            [112.652924, 56.960479],
            [112.680348, 56.965787],
            [112.694624, 56.95248],
            [112.738001, 56.948522],
            [112.727145, 56.916807],
            [112.710964, 56.910664],
            [112.691069, 56.887113],
            [112.691931, 56.872422],
            [112.67696, 56.870134],
            [112.679139, 56.856946],
            [112.705253, 56.843597],
            [112.702323, 56.822762],
            [112.719275, 56.813951],
            [112.711874, 56.80166],
            [112.734937, 56.790077],
            [112.720988, 56.773132],
            [112.734447, 56.75958],
            [112.798462, 56.747849],
            [112.806652, 56.729944],
            [112.873837, 56.723066],
            [112.866245, 56.685793],
            [112.964696, 56.681637],
            [113.000616, 56.69078],
            [113.035383, 56.682856],
            [113.07874, 56.682779],
            [113.089151, 56.660452],
            [113.112047, 56.661575],
            [113.138009, 56.644092],
            [113.184474, 56.64484],
            [113.190961, 56.653011],
            [113.221356, 56.658071],
            [113.236498, 56.652671],
            [113.302852, 56.645723],
            [113.322493, 56.630184],
            [113.379891, 56.631197],
            [113.384432, 56.653114],
            [113.42781, 56.645104],
            [113.465105, 56.671585],
            [113.496386, 56.684807],
            [113.498507, 56.672471],
            [113.557077, 56.671414],
            [113.590771, 56.665335],
            [113.619407, 56.642511],
            [113.64602, 56.642675],
            [113.690756, 56.624205],
            [113.7439, 56.614654],
            [113.756391, 56.625674],
            [113.772717, 56.613653],
            [113.788065, 56.613572],
            [113.801534, 56.589041],
            [113.78733, 56.575877],
            [113.81533, 56.565755],
            [113.823249, 56.542938],
            [113.811738, 56.539346],
            [113.83231, 56.526754],
            [113.85827, 56.520305],
            [113.89272, 56.532387],
            [113.910843, 56.532142],
            [113.925864, 56.543693],
            [113.962926, 56.547774],
            [114.001131, 56.562754],
            [113.968396, 56.577224],
            [114.021051, 56.600428],
            [114.075664, 56.606652],
            [114.09689, 56.623428],
            [114.099584, 56.640408],
            [114.120891, 56.672428],
            [114.145708, 56.678551],
            [114.13779, 56.689245],
            [114.157627, 56.697082],
            [114.186689, 56.691775],
            [114.232894, 56.675591],
            [114.27665, 56.69102],
            [114.319835, 56.679816],
            [114.346367, 56.681203],
            [114.40596, 56.66804],
            [114.423021, 56.643713],
            [114.453879, 56.649264],
            [114.504411, 56.670447],
            [114.490534, 56.68955],
            [114.49633, 56.703305],
            [114.573067, 56.688407],
            [114.580577, 56.679345],
            [114.61168, 56.673304],
            [114.635191, 56.684815],
            [114.638456, 56.695794],
            [114.704499, 56.696284],
            [114.748582, 56.700529],
            [114.767439, 56.686957],
            [114.777154, 56.707161],
            [114.793318, 56.708549],
            [114.786379, 56.726202],
            [114.816911, 56.731121],
            [114.839769, 56.761427],
            [114.831034, 56.781101],
            [114.843198, 56.800366],
            [114.829728, 56.816326],
            [114.854872, 56.840122],
            [114.879199, 56.854183],
            [114.885404, 56.870061],
            [114.91667, 56.868591],
            [114.930875, 56.877347],
            [114.927201, 56.903388],
            [114.914467, 56.911919],
            [114.933733, 56.926123],
            [114.952182, 56.923674],
            [114.972591, 56.943551],
            [114.986061, 56.941143],
            [115.005653, 56.971041],
            [115.033001, 56.975796],
            [115.071614, 56.972939],
            [115.082717, 56.98249],
            [115.06688, 57.010205],
            [115.020185, 57.019552],
            [114.988184, 57.033512],
            [115.049737, 57.059206],
            [115.05684, 57.069328],
            [115.039207, 57.076798],
            [115.069984, 57.112084],
            [115.101495, 57.107757],
            [115.144843, 57.110369],
            [115.16117, 57.117063],
            [115.200681, 57.105798],
            [115.216845, 57.118777],
            [115.256601, 57.119593],
            [115.267213, 57.10294],
            [115.282561, 57.105389],
            [115.329419, 57.096246],
            [115.358563, 57.09796],
            [115.399789, 57.107838],
            [115.432524, 57.102695],
            [115.449259, 57.11045],
            [115.485587, 57.11249],
            [115.539372, 57.108054],
            [115.599873, 57.136008],
            [115.637298, 57.133552],
            [115.653909, 57.12056],
            [115.710855, 57.162121],
            [115.74104, 57.16406],
            [115.766249, 57.186359],
            [115.756101, 57.205071],
            [115.780276, 57.214702],
            [115.811495, 57.212634],
            [115.845042, 57.24828],
            [115.888543, 57.239942],
            [115.914527, 57.228679],
            [115.963198, 57.248942],
            [115.993707, 57.239473],
            [115.996034, 57.227758],
            [116.02777, 57.223039],
            [116.047873, 57.237905],
            [116.067458, 57.242494],
            [116.107856, 57.241266],
            [116.113673, 57.235174],
            [116.152067, 57.230633],
            [116.15504, 57.219774],
            [116.173139, 57.218078],
            [116.176952, 57.188652],
            [116.214699, 57.161117],
            [116.231828, 57.156512],
            [116.250508, 57.138381],
            [116.251319, 57.121489],
            [116.213794, 57.107905],
            [116.184061, 57.103898],
            [116.146054, 57.11075],
            [116.099516, 57.095463],
            [116.078961, 57.068801],
            [116.045866, 57.046631],
            [115.993898, 57.042236],
            [115.971792, 57.031604],
            [115.935983, 57.03225],
            [115.90276, 57.014799],
            [115.873544, 57.012569],
            [115.752414, 56.978926],
            [115.729827, 56.962428],
            [115.699313, 56.930708],
            [115.643037, 56.919236],
            [115.616496, 56.908207],
            [115.615342, 56.891498],
            [115.587648, 56.879848],
            [115.593684, 56.85495],
            [115.584186, 56.84492],
            [115.586138, 56.826036],
            [115.606287, 56.799385],
            [115.614453, 56.771381],
            [115.58667, 56.754117],
            [115.593327, 56.728065],
            [115.586226, 56.714107],
            [115.555691, 56.693825],
            [115.50767, 56.680223],
            [115.43302, 56.666531],
            [115.424587, 56.653639],
            [115.432576, 56.642788],
            [115.415513, 56.620234],
            [115.420681, 56.5955],
            [115.480951, 56.583938],
            [115.500035, 56.577015],
            [115.549742, 56.5716],
            [115.568471, 56.577636],
            [115.597763, 56.575062],
            [115.642855, 56.577458],
            [115.67836, 56.572132],
            [115.686437, 56.549808],
            [115.673921, 56.53432],
            [115.672856, 56.515901],
            [115.69336, 56.486321],
            [115.733125, 56.473872],
            [115.795792, 56.441252],
            [115.804756, 56.414846],
            [115.790998, 56.402929],
            [115.767121, 56.403639],
            [115.727089, 56.42765],
            [115.698063, 56.423833],
            [115.676227, 56.409143],
            [115.671789, 56.391546],
            [115.686967, 56.371508],
            [115.69389, 56.336314],
            [115.675161, 56.328814],
            [115.67019, 56.303672],
            [115.675782, 56.280727],
            [115.697174, 56.268012],
            [115.705517, 56.251413],
            [115.727175, 56.233594],
            [115.755756, 56.197934],
            [115.764721, 56.17723],
            [115.817712, 56.166379],
            [115.832181, 56.148693],
            [115.81567, 56.133404],
            [115.758685, 56.13347],
            [115.753803, 56.124861],
            [115.763921, 56.099874],
            [115.783981, 56.088224],
            [115.78407, 56.075331],
            [115.750074, 56.060397],
            [115.754245, 56.045818],
            [115.781407, 56.027489],
            [115.779897, 55.986148],
            [115.860138, 55.956989],
            [115.904963, 55.926943],
            [115.896087, 55.899205],
            [115.87505, 55.870557],
            [115.83697, 55.856333],
            [115.830401, 55.797462],
            [115.840431, 55.760049],
            [115.864485, 55.743051],
            [115.861733, 55.732821],
            [115.839542, 55.72055],
            [115.828624, 55.676457],
            [115.897415, 55.664008],
            [115.908067, 55.650583],
            [115.897503, 55.627727],
            [115.875046, 55.603273],
            [115.887473, 55.58896],
            [115.910729, 55.586208],
            [115.915965, 55.573937],
            [115.897769, 55.564883],
            [115.876821, 55.540319],
            [115.873891, 55.507943],
            [115.897324, 55.476232],
            [115.93061, 55.469287],
            [115.961588, 55.444722],
            [115.95351, 55.43336],
            [115.902383, 55.409705],
            [115.92626, 55.392463],
            [115.952711, 55.365435],
            [115.974102, 55.355582],
            [116.01733, 55.364547],
            [116.036769, 55.378327],
            [116.082837, 55.377972],
            [116.08124, 55.364192],
            [116.106803, 55.343843],
            [116.182696, 55.359642],
            [116.233468, 55.352541],
            [116.267819, 55.364635],
            [116.292052, 55.361173],
            [116.350902, 55.383119],
            [116.377619, 55.379369],
            [116.396082, 55.3576],
            [116.395371, 55.340891],
            [116.422977, 55.334122],
            [116.428302, 55.318656],
            [116.450937, 55.3262],
            [116.472506, 55.316436],
            [116.44348, 55.299461],
            [116.460256, 55.277315],
            [116.450581, 55.25204],
            [116.472505, 55.229117],
            [116.525763, 55.210099],
            [116.536059, 55.196852],
            [116.591092, 55.177634],
            [116.62278, 55.144371],
            [116.671422, 55.138335],
            [116.687487, 55.119406],
            [116.718732, 55.110707],
            [116.735508, 55.125886],
            [116.755746, 55.117608],
            [116.764888, 55.096727],
            [116.799683, 55.067014],
            [116.794002, 55.051991],
            [116.822584, 55.044158],
            [116.836253, 55.031198],
            [116.831016, 55.005657],
            [116.808914, 54.995072],
            [116.817346, 54.976699],
            [116.839004, 54.983977],
            [116.866343, 54.983533],
            [116.840335, 54.966003],
            [116.858354, 54.952156],
            [116.890131, 54.946897],
            [116.85933, 54.922798],
            [116.874597, 54.903603],
            [116.87504, 54.87622],
            [116.911699, 54.867854],
            [116.908592, 54.849702],
            [116.918977, 54.828643],
            [116.89155, 54.815462],
            [116.912409, 54.805787],
            [116.913562, 54.793782],
            [116.88072, 54.78961],
            [116.848854, 54.76924],
            [116.847256, 54.737241],
            [116.864032, 54.733868],
            [116.877701, 54.709947],
            [116.867049, 54.699362],
            [116.838113, 54.705398],
            [116.820893, 54.697742],
            [116.825863, 54.688245],
            [116.814945, 54.672955],
            [116.777754, 54.674642],
            [116.74571, 54.662903],
            [116.744467, 54.652762],
            [116.765681, 54.629018],
            [116.750059, 54.621807],
            [116.75716, 54.610445],
            [116.780771, 54.607405],
            [116.771717, 54.589542],
            [116.698842, 54.57017],
            [116.695647, 54.561537],
            [116.72334, 54.547136],
            [116.720588, 54.529272],
            [116.670615, 54.522238],
            [116.646826, 54.50473],
            [116.609812, 54.519198],
            [116.574839, 54.506683],
            [116.52673, 54.519021],
            [116.515102, 54.515471],
            [116.509066, 54.488021],
            [116.529747, 54.470957],
            [116.516078, 54.45995],
            [116.490958, 54.460194],
            [116.483058, 54.484205],
            [116.446311, 54.485336],
            [116.431753, 54.476993],
            [116.404592, 54.501292],
            [116.343168, 54.509192],
            [116.324084, 54.488488],
            [116.285029, 54.505464],
            [116.262128, 54.508304],
            [116.249434, 54.490175],
            [116.210911, 54.497897],
            [116.191916, 54.494613],
            [116.189165, 54.511567],
            [116.17372, 54.510857],
            [116.157831, 54.498852],
            [116.134043, 54.497343],
            [116.112917, 54.478681],
            [116.076524, 54.479302],
            [116.040842, 54.486403],
            [116.012526, 54.480634],
            [115.974269, 54.454693],
            [115.934947, 54.472357],
            [115.903436, 54.461062],
            [115.841568, 54.459287],
            [115.82204, 54.445507],
            [115.786713, 54.435366],
            [115.757421, 54.443],
            [115.706116, 54.423184],
            [115.676647, 54.430463],
            [115.638035, 54.415085],
            [115.596138, 54.410469],
            [115.594629, 54.391008],
            [115.578829, 54.381577],
            [115.501694, 54.37421],
            [115.489445, 54.367886],
            [115.499741, 54.345119],
            [115.500362, 54.31454],
            [115.475242, 54.302358],
            [115.451631, 54.300738],
            [115.403876, 54.27249],
            [115.37201, 54.266165],
            [115.341298, 54.267231],
            [115.313782, 54.23612],
            [115.263719, 54.230506],
            [115.251115, 54.223294],
            [115.249162, 54.207561],
            [115.205934, 54.193159],
            [115.18259, 54.200172],
            [115.161819, 54.189431],
            [115.172381, 54.163202],
            [115.151256, 54.136796],
            [115.143888, 54.118111],
            [115.094447, 54.106462],
            [115.091961, 54.09439],
            [115.033733, 54.083162],
            [115.012518, 54.073642],
            [115.013761, 54.044684],
            [115.022282, 54.029993],
            [114.988729, 54.029461],
            [114.984912, 53.999681],
            [114.973639, 53.993024],
            [114.939643, 53.995687],
            [114.921713, 53.978157],
            [114.901031, 53.972476],
            [114.878219, 53.975938],
            [114.864106, 53.951484],
            [114.806498, 53.951396],
            [114.753329, 53.947756],
            [114.749424, 53.928473],
            [114.721552, 53.925721],
            [114.689597, 53.92945],
            [114.657021, 53.915403],
            [114.618587, 53.918066],
            [114.577046, 53.915647],
            [114.577312, 53.882428],
            [114.562843, 53.86978],
            [114.527693, 53.852249],
            [114.51207, 53.814304],
            [114.499644, 53.809511],
            [114.46689, 53.819718],
            [114.433604, 53.805428],
            [114.388069, 53.822825],
            [114.363925, 53.814216],
            [114.35496, 53.800968],
            [114.351143, 53.77394],
            [114.308714, 53.760315],
            [114.305962, 53.755344],
            [114.270013, 53.754457],
            [114.24374, 53.744249],
            [114.218265, 53.753747],
            [114.204595, 53.739168],
            [114.180629, 53.741232],
            [114.156575, 53.759073],
            [114.11095, 53.734442],
            [114.003458, 53.727496],
            [113.947449, 53.703309],
            [113.958011, 53.691592],
            [113.977184, 53.687753],
            [113.981178, 53.673618],
            [113.967153, 53.643506],
            [113.937595, 53.644016],
            [113.937063, 53.662944],
            [113.912387, 53.644482],
            [113.891705, 53.651738],
            [113.890462, 53.63281],
            [113.872266, 53.62258],
            [113.857797, 53.631834],
            [113.824245, 53.630769],
            [113.799125, 53.615391],
            [113.774626, 53.58854],
            [113.812262, 53.584723],
            [113.859217, 53.56808],
            [113.876526, 53.548886],
            [113.882472, 53.516155],
            [113.868625, 53.494297],
            [113.835872, 53.48298],
            [113.822646, 53.447342],
            [113.847588, 53.432497],
            [113.826728, 53.41774],
            [113.841463, 53.394529],
            [113.874837, 53.369986],
            [113.918242, 53.355673],
            [113.937326, 53.358602],
            [113.948865, 53.341537],
            [113.940433, 53.334769],
            [113.9557, 53.321255],
            [113.952771, 53.304967],
            [113.925698, 53.285684],
            [113.94416, 53.2743],
            [113.948776, 53.249935],
            [113.97283, 53.235556],
            [114.007448, 53.247627],
            [114.025822, 53.237419],
            [114.052362, 53.246917],
            [114.04313, 53.227789],
            [114.04242, 53.206552],
            [114.067007, 53.198186],
            [114.092837, 53.197388],
            [114.104554, 53.183163],
            [114.158167, 53.190353],
            [114.205566, 53.17746],
            [114.204944, 53.144507],
            [114.170326, 53.115238],
            [114.150355, 53.119232],
            [114.141656, 53.107782],
            [114.143608, 53.075783],
            [114.157722, 53.072388],
            [114.17006, 53.08608],
            [114.206541, 53.06724],
            [114.233525, 53.088654],
            [114.268853, 53.089519],
            [114.265657, 53.083838],
            [114.290511, 53.059761],
            [114.329566, 53.060383],
            [114.368291, 53.05173],
            [114.354775, 53.034775],
            [114.325716, 53.041399],
            [114.307287, 53.030403],
            [114.319003, 53.016801],
            [114.300541, 53.003997],
            [114.316606, 52.978211],
            [114.306576, 52.955466],
            [114.281811, 52.9552],
            [114.253673, 52.933586],
            [114.253673, 52.912794],
            [114.235388, 52.897394],
            [114.237074, 52.880152],
            [114.223759, 52.862444],
            [114.237606, 52.833729],
            [114.260773, 52.816221],
            [114.227221, 52.812848],
            [114.215149, 52.795318],
            [114.198461, 52.786508],
            [114.181241, 52.767402],
            [114.177335, 52.752468],
            [114.14183, 52.730855],
            [114.14467, 52.715388],
            [114.114757, 52.70498],
            [114.098424, 52.681681],
            [114.08156, 52.679728],
            [114.077388, 52.66222],
            [114.024574, 52.666392],
            [113.971404, 52.637411],
            [113.960309, 52.627381],
            [113.912555, 52.640163],
            [113.881665, 52.631908],
            [113.872789, 52.612691],
            [113.849444, 52.603282],
            [113.815093, 52.614289],
            [113.782872, 52.586817],
            [113.782073, 52.576321],
            [113.737869, 52.576854],
            [113.710086, 52.552755],
            [113.640318, 52.529455],
            [113.640584, 52.50154],
            [113.617772, 52.489357],
            [113.590877, 52.448327],
            [113.542235, 52.488559],
            [113.500783, 52.477175],
            [113.488178, 52.469431],
            [113.296451, 52.462241],
            [113.289971, 52.46881],
            [113.152477, 52.452545],
            [113.141648, 52.433882],
            [113.111469, 52.434659],
            [113.113954, 52.424097],
            [113.092739, 52.417861],
            [113.093361, 52.402749],
            [113.037884, 52.388082],
            [113.008414, 52.370063],
            [112.981963, 52.376365],
            [112.938203, 52.347007],
            [112.94202, 52.335557],
            [112.922137, 52.329699],
            [112.905982, 52.333427],
            [112.881217, 52.351024],
            [112.855831, 52.338664],
            [112.82645, 52.340706],
            [112.804526, 52.326126],
            [112.735468, 52.326659],
            [112.705467, 52.335824],
            [112.680258, 52.354375],
            [112.653452, 52.350292],
            [112.653274, 52.340795],
            [112.610136, 52.357393],
            [112.584217, 52.34976],
            [112.546581, 52.346365],
            [112.541699, 52.334382],
            [112.51578, 52.321578],
            [112.521994, 52.30316],
            [112.50646, 52.296681],
            [112.511075, 52.249215],
            [112.475304, 52.238098],
            [112.457374, 52.237299],
            [112.381304, 52.202993],
            [112.377576, 52.212934],
            [112.354143, 52.221566],
            [112.333283, 52.221411],
            [112.325739, 52.234925],
            [112.305501, 52.244955],
            [112.236887, 52.237056],
            [112.219845, 52.246842],
            [112.192062, 52.251191],
            [112.198098, 52.269498],
            [112.177772, 52.302828],
            [112.159486, 52.302473],
            [112.139781, 52.290646],
            [112.145728, 52.281148],
            [112.093802, 52.287451],
            [112.069747, 52.282547],
            [112.067883, 52.272228],
            [112.045426, 52.263352],
            [112.043295, 52.238365],
            [112.010986, 52.228735],
            [112.00637, 52.209806],
            [111.937934, 52.200664],
            [111.922311, 52.184376],
            [111.932341, 52.180116],
            [111.93101, 52.163229],
            [111.909174, 52.146142],
            [111.885741, 52.14126],
            [111.893285, 52.118936],
            [111.832394, 52.109972],
            [111.828045, 52.094682],
            [111.796267, 52.086849],
            [111.803368, 52.07298],
            [111.774875, 52.069585],
            [111.753572, 52.03073],
            [111.735908, 52.020145],
            [111.732801, 51.997311],
            [111.705462, 51.990809],
            [111.71496, 51.975609],
            [111.672087, 51.977029],
            [111.637381, 51.95384],
            [111.616522, 51.957546],
            [111.601077, 51.931871],
            [111.575779, 51.922441],
            [111.514711, 51.913365],
            [111.481901, 51.893504],
            [111.462437, 51.871232],
            [111.401153, 51.868647],
            [111.350213, 51.878323],
            [111.315622, 51.870204],
            [111.293044, 51.855495],
            [111.288817, 51.839701],
            [111.211072, 51.823102],
            [111.149232, 51.832889],
            [111.114308, 51.81362],
            [111.089839, 51.814065],
            [111.059253, 51.799356],
            [111.014541, 51.79132],
            [111.029778, 51.771606],
            [111.013539, 51.73418],
            [111.000971, 51.724921],
            [110.953812, 51.7267],
            [110.933459, 51.722001],
            [110.919333, 51.703872],
            [110.903651, 51.700396],
            [110.879293, 51.680238],
            [110.84181, 51.675205],
            [110.835137, 51.649485],
            [110.861497, 51.641588],
            [110.872508, 51.614728],
            [110.846259, 51.585337],
            [110.809778, 51.589119],
            [110.778413, 51.581751],
            [110.775966, 51.563816],
            [110.759171, 51.54121],
            [110.740263, 51.5473],
            [110.713569, 51.538736],
            [110.712123, 51.514461],
            [110.687543, 51.508567],
            [110.618362, 51.513683],
            [110.598787, 51.497778],
            [110.521932, 51.499919],
            [110.511143, 51.514601],
            [110.495127, 51.518827],
            [110.473327, 51.511709],
            [110.455309, 51.518966],
            [110.426613, 51.520746],
            [110.409485, 51.545243],
            [110.389799, 51.54794],
            [110.394804, 51.586451],
            [110.365775, 51.576107],
            [110.313277, 51.591706],
            [110.293146, 51.602495],
            [110.244986, 51.595043],
            [110.216958, 51.601271],
            [110.183925, 51.594042],
            [110.14622, 51.61117],
            [110.136989, 51.600048],
            [110.109628, 51.598602],
            [110.103733, 51.604497],
            [110.071923, 51.608167],
            [110.048233, 51.623071],
            [110.033218, 51.615842],
            [109.995068, 51.611282],
            [109.975382, 51.616509],
            [109.958809, 51.607806],
            [109.949355, 51.587091],
            [109.928445, 51.5852],
            [109.913096, 51.572048],
            [109.834017, 51.552891],
            [109.819224, 51.534066],
            [109.792196, 51.516021],
            [109.748931, 51.502201],
            [109.724239, 51.503202],
            [109.693541, 51.476898],
            [109.671519, 51.467333],
            [109.641934, 51.464775],
            [109.619022, 51.448509],
            [109.597111, 51.453292],
            [109.544391, 51.448064],
            [109.538385, 51.439167],
            [109.52248, 51.444616],
            [109.485413, 51.440194],
            [109.457826, 51.426544],
            [109.422078, 51.431482],
            [109.404204, 51.404059],
            [109.383963, 51.399325],
            [109.382657, 51.384899],
            [109.41514, 51.369147],
            [109.393267, 51.353722],
            [109.350336, 51.389511],
            [109.31622, 51.397672],
            [109.310017, 51.386715],
            [109.290674, 51.385246],
            [109.282676, 51.375289],
            [109.225135, 51.365903],
            [109.208159, 51.383451],
            [109.193958, 51.388185],
            [109.174125, 51.407242],
            [109.153966, 51.409609],
            [109.145641, 51.41977],
            [109.11887, 51.432584],
            [109.116912, 51.451152],
            [109.125726, 51.461925],
            [109.109893, 51.481717],
            [109.092916, 51.482615],
            [109.063616, 51.517914],
            [109.052679, 51.508814],
            [109.058556, 51.491022],
            [109.048598, 51.475739],
            [109.026317, 51.478432],
            [108.995302, 51.45358],
            [108.951719, 51.438073],
            [108.929111, 51.440522],
            [108.920459, 51.431544],
            [108.862103, 51.449989],
            [108.853207, 51.458722],
            [108.816805, 51.436584],
            [108.785628, 51.437992],
            [108.773875, 51.4566],
            [108.761224, 51.452356],
            [108.734862, 51.470577],
            [108.728822, 51.482697],
            [108.679036, 51.504815],
            [108.654388, 51.50253],
            [108.628189, 51.492491],
            [108.635208, 51.488003],
            [108.637819, 51.441624],
            [108.622067, 51.43485],
            [108.618639, 51.408569],
            [108.562568, 51.399816],
            [108.536614, 51.415976],
            [108.503069, 51.40557],
            [108.479155, 51.387533],
            [108.474585, 51.367966],
            [108.478094, 51.343032],
            [108.471483, 51.333401],
            [108.462478, 51.295736],
            [108.448957, 51.297531],
            [108.397783, 51.278983],
            [108.375583, 51.25607],
            [108.385785, 51.229361],
            [108.425451, 51.238094],
            [108.431898, 51.229034],
            [108.459566, 51.216445],
            [108.468707, 51.206406],
            [108.557588, 51.17786],
            [108.555793, 51.161741],
            [108.567137, 51.137052],
            [108.561342, 51.120566],
            [108.498987, 51.082288],
            [108.500129, 51.070617],
            [108.453526, 51.052886],
            [108.420961, 51.057619],
            [108.396394, 51.053763],
            [108.371011, 51.062741],
            [108.341118, 51.062878],
            [108.328489, 51.040215],
            [108.324897, 51.015424],
            [108.330447, 50.99959],
            [108.305309, 50.99704],
            [108.295515, 50.950805],
            [108.268174, 50.922484],
            [108.260828, 50.899632],
            [108.277396, 50.876576],
            [108.274947, 50.838666],
            [108.264092, 50.822873],
            [108.215531, 50.828505],
            [108.175784, 50.806999],
            [108.097351, 50.784698],
            [108.088781, 50.772761],
            [108.060542, 50.774557],
            [108.081109, 50.753623],
            [108.069356, 50.73881],
            [108.181822, 50.649003],
            [108.219128, 50.663223],
            [108.239847, 50.665319],
            [108.268276, 50.655089],
            [108.278786, 50.639365],
            [108.313606, 50.63447],
            [108.338718, 50.619367],
            [108.377389, 50.612949],
            [108.406673, 50.622576],
            [108.424966, 50.602719],
            [108.481528, 50.594857],
            [108.480485, 50.583103],
            [108.511213, 50.562484],
            [108.509047, 50.553739],
            [108.535443, 50.538194],
            [108.594252, 50.539558],
            [108.620567, 50.529931],
            [108.640384, 50.540521],
            [108.683387, 50.54036],
            [108.692694, 50.524876],
            [108.690608, 50.511016],
            [108.674, 50.507085],
            [108.661404, 50.492564],
            [108.654183, 50.464604],
            [108.634045, 50.46677],
            [108.621048, 50.455137],
            [108.60067, 50.453692],
            [108.573873, 50.469418],
            [108.549242, 50.462919],
            [108.530548, 50.472065],
            [108.502067, 50.454555],
            [108.48939, 50.457363],
            [108.463396, 50.450383],
            [108.433871, 50.463441],
            [108.415338, 50.44563],
            [108.406191, 50.419114],
            [108.364873, 50.417188],
            [108.339359, 50.393902],
            [108.306064, 50.400962],
            [108.288172, 50.39679],
            [108.274934, 50.409868],
            [108.2422, 50.405535],
            [108.233295, 50.416045],
            [108.206819, 50.411713],
            [108.167185, 50.388246],
            [108.157076, 50.362673],
            [108.169592, 50.354249],
            [108.158279, 50.345584],
            [108.135333, 50.343658],
            [108.124262, 50.332386],
            [108.128273, 50.320352],
            [108.110622, 50.305489],
            [108.089762, 50.318747],
            [108.072673, 50.320773],
            [108.051493, 50.337802],
            [108.016512, 50.346547],
            [107.999183, 50.332908],
            [107.967331, 50.321736],
            [107.963239, 50.286375],
            [107.935239, 50.278653],
            [107.917588, 50.253741],
            [107.941416, 50.241707],
            [107.87322, 50.201773],
            [107.839684, 50.20779],
            [107.836716, 50.202013],
            [107.79981, 50.186168],
            [107.789701, 50.164626],
            [107.734021, 50.153214],
            [107.741803, 50.116489],
            [107.733379, 50.110552],
            [107.766915, 50.072302],
            [107.790663, 50.061952],
            [107.795316, 50.031947],
            [107.767316, 50.014998],
            [107.795637, 49.993336],
            [107.781597, 49.989284],
            [107.759212, 49.971554],
            [107.7305, 49.9825],
            [107.7116, 49.9685],
            [107.6833, 49.9621],
            [107.6496, 49.9619],
            [107.6378, 49.9702],
            [107.6013, 49.9657],
            [107.5591, 49.9848],
            [107.5279, 49.9754],
            [107.4903, 49.9754],
            [107.4782, 49.9855],
            [107.4498, 49.9737],
            [107.3969, 49.9755],
            [107.3722, 49.9852],
            [107.3342, 50.0065],
            [107.2938, 50.0064],
            [107.2875, 49.9911],
            [107.2371, 49.9888],
            [107.2087, 50.0079],
            [107.1865, 50.0103],
            [107.1621, 50.026],
            [107.1598, 50.0368],
            [107.1392, 50.0444],
            [107.1112, 50.043],
            [107.0816, 50.0679],
            [107.0767, 50.0833],
            [107.0617, 50.0924],
            [107.0558, 50.1177],
            [107.0328, 50.1353],
            [107.0168, 50.1563],
            [107.0122, 50.1789],
            [107.0157, 50.1959],
            [106.9841, 50.2099],
            [106.8454, 50.2814],
            [106.8096, 50.3018],
            [106.7442, 50.3172],
            [106.6938, 50.3215],
            [106.6172, 50.3388],
            [106.5848, 50.3374],
            [106.5499, 50.3416],
            [106.536, 50.348],
            [106.5093, 50.3483],
            [106.497103, 50.32422],
            [106.472926, 50.320427],
            [106.4369, 50.3214],
            [106.412209, 50.329488],
            [106.296, 50.305],
            [106.2581, 50.3036],
            [106.2066, 50.324],
            [106.1804, 50.3404],
            [106.0918, 50.3375],
            [106.066, 50.3766],
            [106.0665, 50.3998],
            [105.9839, 50.4187],
            [105.8812, 50.4168],
            [105.8449, 50.4341],
            [105.8211, 50.4397],
            [105.776, 50.44],
            [105.7111, 50.4278],
            [105.6914, 50.428],
            [105.6451, 50.4381],
            [105.6, 50.4328],
            [105.5201, 50.4612],
            [105.489, 50.4513],
            [105.4467, 50.4434],
            [105.4256, 50.4445],
            [105.3526, 50.4798],
            [105.2652, 50.4711],
            [105.2552, 50.4443],
            [105.1946, 50.4162],
            [105.1552, 50.4082],
            [105.1185, 50.3922],
            [105.0975, 50.396],
            [105.0201, 50.3896],
            [104.9976, 50.3914],
            [104.9807, 50.4005],
            [104.9034, 50.4113],
            [104.8851, 50.4057],
            [104.8669, 50.3762],
            [104.85, 50.3652],
            [104.7981, 50.3434],
            [104.7574, 50.3408],
            [104.7025, 50.366],
            [104.6786, 50.3657],
            [104.6605, 50.3368],
            [104.5608, 50.3068],
            [104.5217, 50.3167],
            [104.4739, 50.3156],
            [104.4305, 50.3109],
            [104.4094, 50.3144],
            [104.387, 50.3097],
            [104.3607, 50.2646],
            [104.3359, 50.2492],
            [104.2968, 50.2391],
            [104.2787, 50.2264],
            [104.2721, 50.203],
            [104.1941, 50.1755],
            [104.1428, 50.1455],
            [104.049, 50.1519],
            [104.0182, 50.1517],
            [103.9563, 50.1682],
            [103.9434, 50.1816],
            [103.9125, 50.1849],
            [103.8918, 50.1819],
            [103.8757, 50.1917],
            [103.8395, 50.1932],
            [103.8067, 50.1623],
            [103.797, 50.1586],
            [103.7744, 50.1664],
            [103.752, 50.1653],
            [103.7207, 50.1316],
            [103.6854, 50.1437],
            [103.6601, 50.1472],
            [103.6168, 50.1449],
            [103.5768, 50.1742],
            [103.5388, 50.1792],
            [103.5244, 50.1925],
            [103.458, 50.2112],
            [103.4131, 50.2142],
            [103.3783, 50.2056],
            [103.342, 50.2032],
            [103.3255, 50.195],
            [103.2864, 50.1908],
            [103.2623, 50.1995],
            [103.2618, 50.2138],
            [103.2749, 50.2383],
            [103.2521, 50.2497],
            [103.2762, 50.2806],
            [103.2701, 50.2949],
            [103.2311, 50.3222],
            [103.204, 50.3343],
            [103.183, 50.3331],
            [103.1708, 50.3221],
            [103.1445, 50.3117],
            [103.081, 50.3189],
            [103.0295, 50.3081],
            [102.9723, 50.3054],
            [102.9298, 50.31],
            [102.8951, 50.331],
            [102.8499, 50.3382],
            [102.8037, 50.3651],
            [102.7563, 50.3804],
            [102.7515, 50.3974],
            [102.7287, 50.4052],
            [102.6883, 50.3972],
            [102.6512, 50.4063],
            [102.6198, 50.4434],
            [102.6114, 50.499],
            [102.5819, 50.5209],
            [102.5431, 50.5389],
            [102.5508, 50.5535],
            [102.5482, 50.5768],
            [102.5261, 50.5934],
            [102.4907, 50.5963],
            [102.4648, 50.6045],
            [102.4528, 50.617],
            [102.4444, 50.642],
            [102.3918, 50.6462],
            [102.3897, 50.6597],
            [102.3512, 50.6651],
            [102.3109, 50.6766],
            [102.3068, 50.6992],
            [102.3411, 50.7188],
            [102.3282, 50.7463],
            [102.2833, 50.7867],
            [102.2341, 50.8035],
            [102.2178, 50.8148],
            [102.215, 50.8382],
            [102.2189, 50.8724],
            [102.2447, 50.8892],
            [102.242, 50.9107],
            [102.2195, 50.9534],
            [102.244, 50.9719],
            [102.2261, 50.986],
            [102.2081, 51.025],
            [102.199, 51.0347],
            [102.1561, 51.0588],
            [102.1484, 51.0694],
            [102.1672, 51.0879],
            [102.1421, 51.1016],
            [102.1338, 51.1158],
            [102.1366, 51.1671],
            [102.1589, 51.1767],
            [102.1417, 51.2005],
            [102.1345, 51.2246],
            [102.1075, 51.2437],
            [102.1154, 51.2556],
            [102.1166, 51.2808],
            [102.1455, 51.2779],
            [102.1697, 51.2812],
            [102.1862, 51.2915],
            [102.1553, 51.3276],
            [102.1177, 51.3591],
            [102.0466, 51.3888],
            [102.016, 51.3952],
            [102, 51.3867],
            [101.9832, 51.3952],
            [101.9673, 51.3929],
            [101.9582, 51.4085],
            [101.9129, 51.4186],
            [101.8919, 51.4046],
            [101.8366, 51.4213],
            [101.8147, 51.4156],
            [101.7961, 51.4403],
            [101.769, 51.4445],
            [101.7523, 51.4548],
            [101.7207, 51.4556],
            [101.6657, 51.4505],
            [101.6307, 51.4414],
            [101.6122, 51.4445],
            [101.628, 51.4703],
            [101.5565, 51.479],
            [101.5388, 51.4965],
            [101.5001, 51.5002],
            [101.4606, 51.4892],
            [101.437, 51.451],
            [101.4214, 51.4557],
            [101.3923, 51.4501],
            [101.3541, 51.4617],
            [101.3576, 51.4742],
            [101.2901, 51.5052],
            [101.2487, 51.542],
            [101.2256, 51.5415],
            [101.1957, 51.5196],
            [101.1658, 51.5283],
            [101.1482, 51.5214],
            [101.1269, 51.5272],
            [101.1238, 51.5533],
            [101.0921, 51.5862],
            [101.0781, 51.564],
            [101.0134, 51.5687],
            [101.017, 51.584],
            [100.9849, 51.6097],
            [100.9693, 51.6153],
            [100.8909, 51.6121],
            [100.8573, 51.6008],
            [100.8504, 51.6451],
            [100.823, 51.6457],
            [100.8104, 51.6565],
            [100.726, 51.6776],
            [100.7032, 51.6878],
            [100.678, 51.7106],
            [100.6576, 51.7055],
            [100.6497, 51.7263],
            [100.6178, 51.7259],
            [100.5898, 51.7128],
            [100.5834, 51.7353],
            [100.5691, 51.74],
            [100.5297, 51.7323],
            [100.5172, 51.747],
            [100.4692, 51.7422],
            [100.4311, 51.7256],
            [100.4138, 51.7276],
            [100.407, 51.744],
            [100.3734, 51.7335],
            [100.3345, 51.7386],
            [100.2707, 51.7364],
            [100.2609, 51.7455],
            [100.2027, 51.7382],
            [100.1554, 51.7538],
            [100.1133, 51.7506],
            [100.0842, 51.7438],
            [100.0578, 51.7304],
            [100.0245, 51.7315],
            [99.9957, 51.7391],
            [99.9655, 51.7573],
            [99.925, 51.7594],
            [99.8643, 51.7698],
            [99.8263, 51.8279],
            [99.7478, 51.9037],
            [99.7188, 51.9093],
            [99.5939, 51.9163],
            [99.5838, 51.9189],
            [99.4661, 51.9259],
            [99.4562, 51.9482],
            [99.3646, 51.9523],
            [99.3485, 51.9388],
            [99.337, 51.9524],
            [99.2963, 51.9553],
            [99.2629, 51.966],
            [99.2746, 51.9822],
            [99.2572, 51.993],
            [99.2742, 52],
            [99.2802, 52.0226],
            [99.2627, 52.0235],
            [99.2379, 52.0101],
            [99.219, 52.0254],
            [99.1695, 52.0345],
            [99.1214, 52.0238],
            [99.0661, 52.067],
            [99.025, 52.0634],
            [98.9915, 52.1022],
            [98.979918, 52.136017],
            [98.969217, 52.1434],
            [98.988249, 52.16508],
            [98.959619, 52.172265],
            [98.930988, 52.187777],
            [98.912972, 52.189111],
            [98.89501, 52.20729],
            [98.833557, 52.186498],
            [98.821147, 52.196187],
            [98.839218, 52.229117],
            [98.825393, 52.253066],
            [98.832896, 52.273913],
            [98.812003, 52.276961],
            [98.785114, 52.268687],
            [98.758824, 52.27326],
            [98.744508, 52.290051],
            [98.710054, 52.290487],
            [98.690187, 52.283084],
            [98.67745, 52.265013],
            [98.657076, 52.263283],
            [98.64871, 52.278185],
            [98.661991, 52.304557],
            [98.654698, 52.306516],
            [98.668632, 52.340671],
            [98.685505, 52.355912],
            [98.667217, 52.36707],
            [98.652085, 52.392761],
            [98.637497, 52.391563],
            [98.63053, 52.415023],
            [98.639783, 52.426235],
            [98.67021, 52.42746],
            [98.712557, 52.458622],
            [98.712938, 52.4774],
            [98.724369, 52.477618],
            [98.744617, 52.500397],
            [98.759313, 52.49694],
            [98.770199, 52.509133],
            [98.794366, 52.506194],
            [98.808518, 52.51678],
            [98.805797, 52.532783],
            [98.830236, 52.534661],
            [98.81837, 52.548486],
            [98.78495, 52.560052],
            [98.798557, 52.57053],
            [98.785657, 52.604331],
            [98.807157, 52.618837],
            [98.856308, 52.62311],
            [98.867739, 52.633125],
            [98.866106, 52.655904],
            [98.908562, 52.649699],
            [98.931259, 52.657455],
            [98.941329, 52.671743],
            [98.932185, 52.691991],
            [98.940893, 52.710742],
            [98.96174, 52.715559],
            [98.956624, 52.730609],
            [98.936049, 52.745904],
            [98.933981, 52.771922],
            [98.950745, 52.786999],
            [98.926469, 52.801695],
            [98.869807, 52.798456],
            [98.850865, 52.801178],
            [98.86948, 52.813806],
            [98.850266, 52.832802],
            [98.860934, 52.839878],
            [98.872365, 52.86478],
            [98.901703, 52.872509],
            [98.893212, 52.896349],
            [98.909813, 52.900949],
            [98.937518, 52.937471],
            [98.96272, 52.930178],
            [98.973497, 52.934314],
            [99.021124, 52.905575],
            [99.103097, 52.882524],
            [99.120079, 52.873489],
            [99.170972, 52.864426],
            [99.204011, 52.87436],
            [99.231227, 52.868699],
            [99.223225, 52.911944],
            [99.258823, 52.93377],
            [99.273846, 52.931484],
            [99.25523, 52.950453],
            [99.230567, 52.963186],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5485",
      properties: {
        name: "Владимирская область",
        density: 0,
        path: "/world/Russia/Владимирская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.318131, 56.658578],
            [38.357297, 56.673408],
            [38.398327, 56.68225],
            [38.389041, 56.702346],
            [38.422891, 56.70089],
            [38.410372, 56.693653],
            [38.428497, 56.659627],
            [38.456231, 56.647092],
            [38.492388, 56.648677],
            [38.521052, 56.627793],
            [38.5204, 56.621666],
            [38.562259, 56.613802],
            [38.531709, 56.590182],
            [38.568167, 56.56284],
            [38.55302, 56.541787],
            [38.592943, 56.539092],
            [38.607576, 56.551159],
            [38.620283, 56.576062],
            [38.648652, 56.584663],
            [38.691166, 56.581297],
            [38.73978, 56.588744],
            [38.765064, 56.572109],
            [38.766493, 56.594293],
            [38.755733, 56.609031],
            [38.769532, 56.610467],
            [38.785987, 56.595635],
            [38.809905, 56.595302],
            [38.841929, 56.605497],
            [38.854648, 56.601373],
            [38.852815, 56.584942],
            [38.868652, 56.576842],
            [38.855249, 56.567531],
            [38.884323, 56.568165],
            [38.927564, 56.548754],
            [38.972406, 56.535227],
            [38.989735, 56.540805],
            [39.03063, 56.538867],
            [39.062255, 56.559381],
            [39.078172, 56.563361],
            [39.09392, 56.554077],
            [39.106371, 56.571705],
            [39.134397, 56.578266],
            [39.148715, 56.572985],
            [39.174764, 56.579128],
            [39.187783, 56.554174],
            [39.273024, 56.536674],
            [39.289999, 56.5468],
            [39.297201, 56.567423],
            [39.294309, 56.585936],
            [39.323495, 56.578197],
            [39.332853, 56.595585],
            [39.335408, 56.627096],
            [39.312824, 56.660539],
            [39.323758, 56.682234],
            [39.366865, 56.692375],
            [39.342299, 56.705503],
            [39.353268, 56.719589],
            [39.336539, 56.739753],
            [39.347053, 56.756873],
            [39.367433, 56.765873],
            [39.402019, 56.771048],
            [39.419312, 56.769288],
            [39.42612, 56.753829],
            [39.459258, 56.744352],
            [39.491998, 56.749415],
            [39.49674, 56.762545],
            [39.51869, 56.763236],
            [39.534478, 56.782543],
            [39.579191, 56.798241],
            [39.624481, 56.796441],
            [39.636649, 56.812642],
            [39.666603, 56.803354],
            [39.674091, 56.812427],
            [39.701174, 56.798059],
            [39.72651, 56.796011],
            [39.766473, 56.774627],
            [39.802331, 56.765411],
            [39.847045, 56.763107],
            [39.861229, 56.77002],
            [39.900942, 56.770205],
            [39.896586, 56.706658],
            [39.880746, 56.693409],
            [39.869085, 56.624718],
            [39.824012, 56.610172],
            [39.850077, 56.596924],
            [39.839997, 56.58814],
            [39.883055, 56.596925],
            [39.889968, 56.572444],
            [39.916035, 56.552859],
            [39.928131, 56.554011],
            [39.931876, 56.539179],
            [39.957077, 56.533707],
            [39.948582, 56.521466],
            [39.926549, 56.517722],
            [39.932454, 56.506633],
            [39.916613, 56.495401],
            [39.935321, 56.48865],
            [39.949304, 56.466888],
            [39.977385, 56.474953],
            [39.992938, 56.467033],
            [40.046509, 56.484456],
            [40.063175, 56.477339],
            [40.102725, 56.480223],
            [40.112812, 56.463548],
            [40.13835, 56.452279],
            [40.176691, 56.459979],
            [40.190228, 56.452347],
            [40.192821, 56.435786],
            [40.22551, 56.439819],
            [40.235878, 56.458252],
            [40.259639, 56.459548],
            [40.247685, 56.473949],
            [40.267414, 56.488062],
            [40.303703, 56.48835],
            [40.314215, 56.507603],
            [40.335686, 56.504247],
            [40.387375, 56.534305],
            [40.413423, 56.523087],
            [40.427995, 56.543034],
            [40.457471, 56.535148],
            [40.490601, 56.583698],
            [40.531286, 56.58385],
            [40.561214, 56.60283],
            [40.579881, 56.597641],
            [40.578597, 56.587331],
            [40.606929, 56.579892],
            [40.612629, 56.587828],
            [40.648323, 56.591099],
            [40.652914, 56.582536],
            [40.673149, 56.590209],
            [40.767234, 56.594363],
            [40.781943, 56.590029],
            [40.798937, 56.571594],
            [40.830619, 56.573322],
            [40.822227, 56.560756],
            [40.880015, 56.524361],
            [40.885921, 56.50564],
            [40.918034, 56.50348],
            [40.951875, 56.520042],
            [40.977508, 56.521626],
            [40.985339, 56.512265],
            [41.023734, 56.520331],
            [41.032232, 56.490377],
            [41.054607, 56.483511],
            [41.065353, 56.489658],
            [41.1468, 56.489134],
            [41.146142, 56.475979],
            [41.162367, 56.479171],
            [41.1745, 56.49936],
            [41.183273, 56.488577],
            [41.202735, 56.493694],
            [41.232739, 56.485437],
            [41.265891, 56.490895],
            [41.267544, 56.482291],
            [41.289284, 56.489805],
            [41.316213, 56.487934],
            [41.323845, 56.496286],
            [41.353222, 56.492686],
            [41.370215, 56.501039],
            [41.401778, 56.493879],
            [41.491757, 56.491248],
            [41.526606, 56.506226],
            [41.54409, 56.489845],
            [41.533371, 56.47926],
            [41.544808, 56.472339],
            [41.563541, 56.483701],
            [41.585362, 56.473681],
            [41.609699, 56.477138],
            [41.677381, 56.49658],
            [41.697109, 56.513861],
            [41.708198, 56.512133],
            [41.702438, 56.496004],
            [41.740608, 56.492127],
            [41.833692, 56.499741],
            [41.849533, 56.504062],
            [41.882941, 56.528111],
            [41.92384, 56.518607],
            [41.93104, 56.509535],
            [41.915056, 56.503919],
            [41.948542, 56.481586],
            [41.951491, 56.465613],
            [41.938676, 56.450492],
            [41.942356, 56.440277],
            [41.963525, 56.431205],
            [41.980664, 56.389011],
            [41.979437, 56.373575],
            [42.010684, 56.368837],
            [42.02972, 56.380123],
            [42.046402, 56.352363],
            [42.072114, 56.36738],
            [42.117332, 56.368541],
            [42.113239, 56.378012],
            [42.138893, 56.378509],
            [42.137628, 56.397683],
            [42.189135, 56.39877],
            [42.205838, 56.408712],
            [42.20424, 56.450181],
            [42.218455, 56.440683],
            [42.243143, 56.443662],
            [42.279188, 56.434954],
            [42.321381, 56.449499],
            [42.368039, 56.444748],
            [42.405769, 56.447772],
            [42.455884, 56.429772],
            [42.473165, 56.417244],
            [42.500383, 56.419692],
            [42.554385, 56.412925],
            [42.585831, 56.399935],
            [42.614182, 56.406734],
            [42.635422, 56.405681],
            [42.64675, 56.39029],
            [42.673948, 56.388161],
            [42.725957, 56.36644],
            [42.732761, 56.350955],
            [42.773942, 56.331789],
            [42.774688, 56.303914],
            [42.794772, 56.284807],
            [42.744215, 56.265555],
            [42.741779, 56.249094],
            [42.76364, 56.246047],
            [42.784438, 56.218047],
            [42.757401, 56.210617],
            [42.763367, 56.192849],
            [42.788201, 56.207921],
            [42.80024, 56.202139],
            [42.832058, 56.208992],
            [42.823445, 56.195343],
            [42.806581, 56.197564],
            [42.833521, 56.179893],
            [42.840645, 56.189545],
            [42.89489, 56.185386],
            [42.903545, 56.209389],
            [42.923744, 56.193678],
            [42.903013, 56.189919],
            [42.92163, 56.181934],
            [42.942883, 56.190469],
            [42.943966, 56.174048],
            [42.966181, 56.164211],
            [42.96122, 56.140375],
            [42.931039, 56.143072],
            [42.926038, 56.136538],
            [42.871771, 56.138158],
            [42.848324, 56.13317],
            [42.803773, 56.114997],
            [42.775515, 56.117373],
            [42.74008, 56.10124],
            [42.716474, 56.10441],
            [42.678957, 56.083946],
            [42.688223, 56.08365],
            [42.680652, 56.048237],
            [42.711927, 56.046537],
            [42.70487, 56.011126],
            [42.719076, 56.009528],
            [42.716769, 55.968332],
            [42.688358, 55.984135],
            [42.667076, 55.976181],
            [42.653378, 55.955191],
            [42.60881, 55.932994],
            [42.590342, 55.936013],
            [42.570988, 55.926779],
            [42.576799, 55.946097],
            [42.555538, 55.95845],
            [42.531744, 55.95661],
            [42.522778, 55.933777],
            [42.502894, 55.914379],
            [42.506266, 55.88505],
            [42.492061, 55.868713],
            [42.450867, 55.840657],
            [42.410994, 55.821301],
            [42.393333, 55.788542],
            [42.350174, 55.788301],
            [42.363013, 55.778011],
            [42.295724, 55.773173],
            [42.255492, 55.756079],
            [42.248126, 55.731104],
            [42.218082, 55.725598],
            [42.199227, 55.713067],
            [42.188331, 55.67704],
            [42.17168, 55.674412],
            [42.136665, 55.681753],
            [42.124879, 55.668345],
            [42.142695, 55.639853],
            [42.134566, 55.617994],
            [42.082847, 55.621835],
            [42.064176, 55.612244],
            [42.06483, 55.587369],
            [42.103603, 55.528165],
            [42.085064, 55.504279],
            [42.094265, 55.488752],
            [42.155906, 55.466584],
            [42.166871, 55.443995],
            [42.136006, 55.431279],
            [42.086916, 55.402262],
            [42.068568, 55.419689],
            [42.030788, 55.393279],
            [42.016668, 55.377439],
            [42.016448, 55.361525],
            [42.004222, 55.327471],
            [41.979674, 55.328155],
            [41.961732, 55.348],
            [41.946544, 55.34757],
            [41.940843, 55.334855],
            [41.966972, 55.320146],
            [41.972521, 55.286605],
            [41.959747, 55.275906],
            [41.920645, 55.264434],
            [41.911839, 55.256944],
            [41.894756, 55.264397],
            [41.880087, 55.248403],
            [41.860527, 55.241109],
            [41.879125, 55.233631],
            [41.873888, 55.222977],
            [41.908336, 55.210282],
            [41.866488, 55.194006],
            [41.868451, 55.179944],
            [41.882679, 55.177431],
            [41.873446, 55.164735],
            [41.822129, 55.166599],
            [41.805172, 55.151771],
            [41.814228, 55.148575],
            [41.783953, 55.129753],
            [41.795935, 55.103672],
            [41.78246, 55.128799],
            [41.772956, 55.132378],
            [41.717817, 55.130018],
            [41.681372, 55.112134],
            [41.631239, 55.120261],
            [41.641855, 55.132145],
            [41.628133, 55.1524],
            [41.605165, 55.15645],
            [41.572258, 55.146816],
            [41.543868, 55.173107],
            [41.491972, 55.168022],
            [41.484622, 55.159405],
            [41.443436, 55.145457],
            [41.406255, 55.157349],
            [41.394764, 55.166069],
            [41.361505, 55.175076],
            [41.321256, 55.196107],
            [41.301449, 55.213465],
            [41.294505, 55.233472],
            [41.180486, 55.227904],
            [41.149382, 55.229879],
            [41.143458, 55.217535],
            [41.099888, 55.206303],
            [41.078432, 55.212305],
            [41.073351, 55.190874],
            [41.090419, 55.175732],
            [41.06434, 55.187911],
            [41.059526, 55.206549],
            [41.072116, 55.209141],
            [41.059337, 55.222439],
            [41.042615, 55.221977],
            [41.027679, 55.239628],
            [41.005841, 55.244519],
            [40.993449, 55.257039],
            [41.002091, 55.271881],
            [40.905729, 55.275297],
            [40.903138, 55.237651],
            [40.870059, 55.233454],
            [40.847472, 55.218889],
            [40.827599, 55.238884],
            [40.786003, 55.229009],
            [40.761564, 55.232588],
            [40.725997, 55.208214],
            [40.702936, 55.207654],
            [40.707159, 55.226553],
            [40.667758, 55.219874],
            [40.650602, 55.206049],
            [40.600251, 55.200483],
            [40.612955, 55.217774],
            [40.588267, 55.253939],
            [40.562593, 55.257518],
            [40.555434, 55.272083],
            [40.568267, 55.280419],
            [40.540526, 55.290245],
            [40.527195, 55.302347],
            [40.545434, 55.3238],
            [40.559167, 55.323846],
            [40.586905, 55.348487],
            [40.567896, 55.356139],
            [40.517742, 55.350894],
            [40.450293, 55.301197],
            [40.394356, 55.304296],
            [40.381273, 55.289731],
            [40.358068, 55.289607],
            [40.34344, 55.304882],
            [40.325729, 55.308121],
            [40.326954, 55.321948],
            [40.363867, 55.349775],
            [40.330047, 55.348483],
            [40.295659, 55.335212],
            [40.27841, 55.346734],
            [40.277959, 55.360578],
            [40.246238, 55.35095],
            [40.207282, 55.351532],
            [40.225471, 55.360106],
            [40.190923, 55.365007],
            [40.178833, 55.393976],
            [40.142569, 55.418107],
            [40.141305, 55.450727],
            [40.153403, 55.460587],
            [40.194029, 55.461913],
            [40.200484, 55.497414],
            [40.171194, 55.506192],
            [40.167802, 55.52662],
            [40.142822, 55.523782],
            [40.102973, 55.528707],
            [40.048871, 55.526498],
            [40.046829, 55.581884],
            [40.074838, 55.57724],
            [40.093163, 55.607689],
            [40.041447, 55.635321],
            [40.05359, 55.655344],
            [40.014423, 55.6869],
            [39.994282, 55.687658],
            [39.958382, 55.722522],
            [39.933337, 55.720279],
            [39.92594, 55.733167],
            [39.903052, 55.741896],
            [39.886481, 55.760985],
            [39.903745, 55.773692],
            [39.896797, 55.782985],
            [39.90924, 55.802929],
            [39.889102, 55.833195],
            [39.865998, 55.832431],
            [39.849886, 55.84061],
            [39.788881, 55.831511],
            [39.791101, 55.839488],
            [39.732105, 55.842353],
            [39.731196, 55.824883],
            [39.713854, 55.813868],
            [39.696764, 55.814088],
            [39.70341, 55.787435],
            [39.660624, 55.785725],
            [39.660764, 55.795394],
            [39.594754, 55.798157],
            [39.594957, 55.788482],
            [39.523467, 55.787257],
            [39.521138, 55.745227],
            [39.470275, 55.74699],
            [39.470079, 55.773557],
            [39.423642, 55.773447],
            [39.437278, 55.78078],
            [39.406172, 55.778188],
            [39.402762, 55.785803],
            [39.351905, 55.768562],
            [39.325843, 55.773018],
            [39.321072, 55.802812],
            [39.308908, 55.805798],
            [39.29476, 55.832233],
            [39.327168, 55.848],
            [39.327174, 55.864863],
            [39.280373, 55.850979],
            [39.27683, 55.841437],
            [39.240002, 55.845131],
            [39.204831, 55.842143],
            [39.190104, 55.846779],
            [39.16766, 55.840038],
            [39.150045, 55.854908],
            [39.10091, 55.846719],
            [39.103441, 55.859931],
            [39.087574, 55.863697],
            [39.05917, 55.857344],
            [39.075125, 55.868746],
            [39.049246, 55.90262],
            [39.028881, 55.908286],
            [39.063332, 55.931282],
            [39.057092, 55.942105],
            [39.042563, 55.921429],
            [39.013651, 55.92852],
            [39.016748, 55.944102],
            [39.028362, 55.935173],
            [39.049121, 55.942896],
            [39.020238, 55.950411],
            [38.9572, 55.930955],
            [38.919415, 55.933525],
            [38.900645, 55.947065],
            [38.885039, 55.9452],
            [38.838383, 55.91481],
            [38.806929, 55.940421],
            [38.793372, 55.943358],
            [38.73143, 55.979875],
            [38.692028, 55.988925],
            [38.611132, 55.976905],
            [38.576002, 55.998682],
            [38.606224, 56.014272],
            [38.589611, 56.025375],
            [38.586439, 56.053546],
            [38.545948, 56.064945],
            [38.529293, 56.090927],
            [38.533169, 56.114595],
            [38.510329, 56.133191],
            [38.522638, 56.141701],
            [38.509883, 56.16163],
            [38.521041, 56.182146],
            [38.502022, 56.182754],
            [38.509715, 56.199692],
            [38.527775, 56.213561],
            [38.503447, 56.217831],
            [38.491483, 56.204928],
            [38.459454, 56.202617],
            [38.442024, 56.223921],
            [38.414288, 56.23558],
            [38.396988, 56.283185],
            [38.417775, 56.282347],
            [38.435805, 56.292228],
            [38.43861, 56.312692],
            [38.414406, 56.321388],
            [38.422418, 56.360341],
            [38.446269, 56.3865],
            [38.431881, 56.396586],
            [38.382539, 56.400235],
            [38.401212, 56.441602],
            [38.394348, 56.459764],
            [38.365241, 56.463565],
            [38.354285, 56.452799],
            [38.343041, 56.48106],
            [38.319255, 56.48274],
            [38.298436, 56.49496],
            [38.335904, 56.505503],
            [38.335808, 56.517649],
            [38.295201, 56.511473],
            [38.27379, 56.531804],
            [38.309799, 56.555078],
            [38.358751, 56.57327],
            [38.357459, 56.596908],
            [38.330983, 56.603813],
            [38.339252, 56.631515],
            [38.317845, 56.642277],
            [38.318131, 56.658578],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5487",
      properties: {
        name: "Волгоградская область",
        density: 0,
        path: "/world/Russia/Волгоградская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.880894, 51.233025],
            [42.949355, 51.201463],
            [43.11589, 51.133724],
            [43.13836, 51.122649],
            [43.182935, 51.113982],
            [43.262745, 51.061529],
            [43.324206, 51.013014],
            [43.357075, 50.998832],
            [43.380939, 51.005699],
            [43.421123, 51.034178],
            [43.433055, 51.027537],
            [43.511849, 51.050501],
            [43.527157, 51.048362],
            [43.561489, 51.060632],
            [43.652666, 51.053429],
            [43.681932, 51.064236],
            [43.687672, 51.084948],
            [43.674501, 51.098005],
            [43.679904, 51.109824],
            [43.661218, 51.114101],
            [43.673713, 51.121531],
            [43.694087, 51.108361],
            [43.737424, 51.113652],
            [43.753295, 51.121307],
            [43.805638, 51.117818],
            [43.848525, 51.099921],
            [43.882294, 51.110953],
            [43.916286, 51.160594],
            [43.958723, 51.147087],
            [43.975157, 51.155304],
            [44.019169, 51.159132],
            [44.018499, 51.072218],
            [44.050803, 51.072233],
            [44.058569, 51.102738],
            [44.098979, 51.099924],
            [44.116988, 51.124914],
            [44.140289, 51.119061],
            [44.129821, 51.106453],
            [44.184978, 51.093284],
            [44.215256, 51.12863],
            [44.208052, 51.150017],
            [44.215368, 51.16465],
            [44.210865, 51.18176],
            [44.228199, 51.198982],
            [44.282567, 51.195606],
            [44.282793, 51.1848],
            [44.300465, 51.185813],
            [44.314986, 51.177034],
            [44.350331, 51.175571],
            [44.374645, 51.189079],
            [44.401547, 51.188516],
            [44.423273, 51.179174],
            [44.450963, 51.191444],
            [44.455127, 51.210805],
            [44.499365, 51.205177],
            [44.516588, 51.179513],
            [44.548107, 51.153061],
            [44.571332, 51.123383],
            [44.593444, 51.11936],
            [44.627127, 51.126046],
            [44.615644, 51.166682],
            [44.643897, 51.178614],
            [44.741829, 51.152275],
            [44.789676, 51.148431],
            [44.809757, 51.160282],
            [44.834476, 51.15899],
            [44.863315, 51.170724],
            [44.896617, 51.162866],
            [44.894917, 51.118058],
            [44.939155, 51.106577],
            [44.953562, 51.122449],
            [44.981703, 51.120536],
            [44.993972, 51.146763],
            [45.03292, 51.127966],
            [45.044514, 51.132693],
            [45.06849, 51.119186],
            [45.077528, 51.099738],
            [45.074757, 51.087625],
            [45.094824, 51.077452],
            [45.1483, 51.064931],
            [45.188598, 51.044445],
            [45.220229, 51.039943],
            [45.202219, 51.020469],
            [45.201207, 50.997394],
            [45.218205, 50.980397],
            [45.203797, 50.967902],
            [45.217385, 50.94954],
            [45.24736, 50.942688],
            [45.250625, 50.933458],
            [45.22541, 50.936047],
            [45.24646, 50.917924],
            [45.267397, 50.925692],
            [45.304881, 50.913985],
            [45.30173, 50.905768],
            [45.321317, 50.877965],
            [45.326157, 50.860856],
            [45.312539, 50.808626],
            [45.285525, 50.769115],
            [45.27866, 50.718574],
            [45.209948, 50.701555],
            [45.194154, 50.715512],
            [45.18518, 50.710334],
            [45.202444, 50.69696],
            [45.234198, 50.698987],
            [45.206509, 50.662741],
            [45.218553, 50.659027],
            [45.200994, 50.634601],
            [45.192553, 50.585635],
            [45.385037, 50.589915],
            [45.402934, 50.613329],
            [45.427136, 50.60635],
            [45.489946, 50.603874],
            [45.496701, 50.585302],
            [45.533826, 50.551134],
            [45.552646, 50.556486],
            [45.559737, 50.571457],
            [45.553433, 50.586653],
            [45.606113, 50.590594],
            [45.633353, 50.578887],
            [45.684345, 50.575624],
            [45.705741, 50.582496],
            [45.702129, 50.605341],
            [45.678601, 50.667025],
            [45.685917, 50.691564],
            [45.715632, 50.737716],
            [45.744223, 50.757302],
            [45.842266, 50.756178],
            [45.893032, 50.749988],
            [45.952805, 50.713068],
            [45.966426, 50.700573],
            [45.983085, 50.699448],
            [45.987475, 50.688529],
            [46.03554, 50.687179],
            [46.034078, 50.665117],
            [46.091598, 50.665005],
            [46.091149, 50.617278],
            [46.065053, 50.607239],
            [46.06719, 50.531507],
            [46.06146, 50.526166],
            [46.278413, 50.527697],
            [46.275643, 50.57293],
            [46.344307, 50.573606],
            [46.345208, 50.555483],
            [46.387419, 50.555709],
            [46.414542, 50.549634],
            [46.465426, 50.545692],
            [46.495777, 50.553459],
            [46.454607, 50.556361],
            [46.442723, 50.585304],
            [46.460578, 50.597562],
            [46.45836, 50.617155],
            [46.492468, 50.634456],
            [46.49785, 50.625068],
            [46.527074, 50.623485],
            [46.519471, 50.641973],
            [46.538285, 50.644435],
            [46.548105, 50.613879],
            [46.553923, 50.622115],
            [46.56871, 50.61463],
            [46.56808, 50.690451],
            [46.583388, 50.712288],
            [46.611867, 50.686174],
            [46.646424, 50.682572],
            [46.679968, 50.689326],
            [46.686947, 50.697544],
            [46.727447, 50.703916],
            [46.742104, 50.65871],
            [46.775424, 50.642726],
            [46.856582, 50.641039],
            [46.853431, 50.626405],
            [46.812983, 50.60653],
            [46.814034, 50.591789],
            [46.803181, 50.575686],
            [46.870767, 50.577215],
            [46.870769, 50.523747],
            [46.915051, 50.522865],
            [46.911452, 50.489874],
            [47.038641, 50.489146],
            [47.038152, 50.509679],
            [47.205309, 50.509006],
            [47.204637, 50.430211],
            [47.23683, 50.429536],
            [47.235211, 50.371022],
            [47.272091, 50.371398],
            [47.272176, 50.379065],
            [47.430817, 50.376814],
            [47.418431, 50.363892],
            [47.406683, 50.336373],
            [47.377726, 50.328494],
            [47.338877, 50.329122],
            [47.299714, 50.302702],
            [47.291538, 50.282805],
            [47.300827, 50.26312],
            [47.334775, 50.237773],
            [47.257387, 50.191595],
            [47.316939, 50.152456],
            [47.352685, 50.093983],
            [47.181973, 49.934973],
            [46.953831, 49.870716],
            [46.930452, 49.874592],
            [46.902044, 49.863013],
            [46.891396, 49.830447],
            [46.868176, 49.737656],
            [46.858629, 49.644727],
            [46.829501, 49.568843],
            [46.805603, 49.433384],
            [46.779693, 49.337954],
            [46.839857, 49.317685],
            [46.917511, 49.280428],
            [46.991765, 49.235037],
            [47.048171, 49.16774],
            [47.048208, 49.158317],
            [47.029128, 49.088384],
            [46.9697, 49.025613],
            [46.891, 48.981869],
            [46.80057, 48.954001],
            [46.779498, 48.951999],
            [46.6995, 48.8119],
            [46.627097, 48.679148],
            [46.44537, 48.795032],
            [46.43614, 48.789339],
            [46.319338, 48.851504],
            [46.329392, 48.862662],
            [46.060027, 48.905198],
            [46.019104, 48.899223],
            [45.987327, 48.880416],
            [45.954699, 48.840775],
            [45.916585, 48.770478],
            [45.715611, 48.649088],
            [45.685833, 48.643576],
            [45.613916, 48.612015],
            [45.58457, 48.605118],
            [45.560635, 48.583249],
            [45.544962, 48.593798],
            [45.526002, 48.593082],
            [45.511033, 48.576535],
            [45.495775, 48.572062],
            [45.478498, 48.586681],
            [45.447246, 48.582962],
            [45.433419, 48.58948],
            [45.422035, 48.570207],
            [45.392379, 48.569456],
            [45.385383, 48.561751],
            [45.326594, 48.549082],
            [45.305906, 48.558102],
            [45.300224, 48.572976],
            [45.264261, 48.565497],
            [45.245269, 48.5661],
            [45.247374, 48.553742],
            [45.224644, 48.557904],
            [45.201349, 48.536606],
            [45.224142, 48.533315],
            [45.228597, 48.51517],
            [45.266611, 48.522063],
            [45.268754, 48.515856],
            [45.298722, 48.512741],
            [45.301327, 48.504921],
            [45.326687, 48.504723],
            [45.318747, 48.491245],
            [45.329391, 48.471901],
            [45.353543, 48.47443],
            [45.337435, 48.495684],
            [45.369902, 48.502478],
            [45.424031, 48.495571],
            [45.394851, 48.484675],
            [45.382485, 48.47359],
            [45.348248, 48.456768],
            [45.347248, 48.438311],
            [45.333911, 48.433358],
            [45.332175, 48.44538],
            [45.307137, 48.446939],
            [45.28686, 48.455721],
            [45.270262, 48.470836],
            [45.244288, 48.483348],
            [45.219277, 48.482343],
            [45.204895, 48.473888],
            [45.200538, 48.485466],
            [45.212593, 48.490388],
            [45.212676, 48.514505],
            [45.174021, 48.511584],
            [45.185557, 48.493682],
            [45.177353, 48.471787],
            [45.124409, 48.480935],
            [45.085938, 48.471348],
            [45.06461, 48.461715],
            [45.082572, 48.449721],
            [45.05606, 48.427318],
            [45.014083, 48.425031],
            [45.025531, 48.357298],
            [44.991387, 48.359386],
            [44.981943, 48.29281],
            [44.959474, 48.270909],
            [44.907817, 48.220676],
            [44.936032, 48.195892],
            [44.915648, 48.164199],
            [44.925662, 48.120248],
            [44.873692, 48.090407],
            [44.809767, 48.050632],
            [44.663061, 48.086332],
            [44.66317, 48.056717],
            [44.644307, 48.052937],
            [44.593512, 48.054301],
            [44.541511, 48.049884],
            [44.544157, 48.188073],
            [44.50462, 48.184844],
            [44.478019, 48.201998],
            [44.435767, 48.20623],
            [44.437292, 48.229747],
            [44.382409, 48.233604],
            [44.381122, 48.250449],
            [44.326409, 48.238797],
            [44.327921, 48.213596],
            [44.325646, 48.141192],
            [44.325132, 48.041328],
            [44.370587, 48.043912],
            [44.424307, 48.042363],
            [44.422416, 47.956618],
            [44.391252, 47.919427],
            [44.390048, 47.891534],
            [44.355441, 47.853998],
            [44.168627, 47.883783],
            [44.149687, 47.874313],
            [44.155024, 47.886882],
            [44.08259, 47.899435],
            [44.010224, 47.839875],
            [44.024343, 47.824035],
            [43.972712, 47.7745],
            [43.945831, 47.753096],
            [43.87145, 47.760843],
            [43.806195, 47.745863],
            [43.781057, 47.733121],
            [43.752131, 47.733121],
            [43.714769, 47.717624],
            [43.667597, 47.548888],
            [43.711218, 47.474225],
            [43.686675, 47.450155],
            [43.662467, 47.453522],
            [43.656744, 47.443765],
            [43.647018, 47.456461],
            [43.587169, 47.485709],
            [43.436895, 47.471989],
            [43.402113, 47.470062],
            [43.314577, 47.447569],
            [43.251113, 47.447352],
            [43.194611, 47.456157],
            [43.155268, 47.466015],
            [43.150106, 47.476388],
            [43.1604, 47.499896],
            [43.157179, 47.517079],
            [43.145453, 47.528941],
            [43.172718, 47.544429],
            [43.164356, 47.553664],
            [43.049853, 47.553617],
            [43.052624, 47.543715],
            [43.027956, 47.534117],
            [43.011999, 47.517609],
            [42.986591, 47.505497],
            [42.92499, 47.507356],
            [42.925543, 47.519058],
            [42.868766, 47.63316],
            [42.843235, 47.644216],
            [42.846395, 47.671727],
            [42.803383, 47.709857],
            [42.792718, 47.712903],
            [42.75922, 47.760653],
            [42.720173, 47.87587],
            [42.62961, 47.908836],
            [42.56435, 47.961393],
            [42.547152, 47.971845],
            [42.525202, 48.018644],
            [42.446936, 48.023456],
            [42.39162, 48.00933],
            [42.383199, 48.02295],
            [42.372059, 48.022661],
            [42.364317, 48.040216],
            [42.352163, 48.04424],
            [42.338384, 48.027268],
            [42.216126, 48.032691],
            [42.172242, 48.007568],
            [42.129761, 48.024694],
            [42.120019, 48.017316],
            [42.095019, 48.027099],
            [42.09303, 48.018808],
            [42.068134, 48.021921],
            [42.052393, 48.013022],
            [42.046224, 48.027148],
            [42.020433, 48.023011],
            [42.038065, 48.034178],
            [42.017706, 48.047625],
            [42.029996, 48.055495],
            [42.030365, 48.073091],
            [42.020522, 48.091585],
            [42.03161, 48.10616],
            [42.074472, 48.120535],
            [42.080752, 48.13612],
            [42.12905, 48.140898],
            [42.088673, 48.233794],
            [42.063066, 48.234135],
            [42.062792, 48.265648],
            [42.181006, 48.276915],
            [42.1247, 48.368584],
            [42.085691, 48.415706],
            [42.15072, 48.439636],
            [42.212249, 48.466184],
            [42.220617, 48.481455],
            [42.277304, 48.506578],
            [42.317278, 48.51603],
            [42.349585, 48.512285],
            [42.362682, 48.497345],
            [42.39608, 48.518632],
            [42.411578, 48.513687],
            [42.402934, 48.504808],
            [42.430525, 48.513456],
            [42.437117, 48.503217],
            [42.489046, 48.515595],
            [42.504981, 48.524545],
            [42.527661, 48.521032],
            [42.559716, 48.541897],
            [42.57153, 48.536857],
            [42.606795, 48.546791],
            [42.62202, 48.542907],
            [42.628896, 48.55752],
            [42.645076, 48.557393],
            [42.646141, 48.577273],
            [42.66253, 48.590596],
            [42.673221, 48.584854],
            [42.704482, 48.586598],
            [42.702115, 48.613077],
            [42.708659, 48.613181],
            [42.670158, 48.660325],
            [42.672559, 48.671092],
            [42.657963, 48.700576],
            [42.665464, 48.724617],
            [42.745068, 48.774256],
            [42.747807, 48.822529],
            [42.73026, 48.844013],
            [42.68149, 48.875137],
            [42.682567, 48.935906],
            [42.637566, 48.9596],
            [42.610108, 48.962434],
            [42.554753, 48.9739],
            [42.500368, 49.010149],
            [42.473589, 49.003156],
            [42.467184, 49.009866],
            [42.41803, 49.003343],
            [42.38979, 49.013372],
            [42.350433, 49.050401],
            [42.361722, 49.061428],
            [42.387197, 49.062182],
            [42.381409, 49.109262],
            [42.364654, 49.104401],
            [42.359233, 49.128754],
            [42.339804, 49.139264],
            [42.189283, 49.122345],
            [42.069082, 49.130259],
            [42.064725, 49.126669],
            [42.021112, 49.133835],
            [42.032237, 49.147433],
            [42.081462, 49.170547],
            [42.098703, 49.195383],
            [42.047344, 49.20989],
            [42.068084, 49.267257],
            [42.085354, 49.257317],
            [42.105854, 49.272548],
            [42.075661, 49.286704],
            [42.101571, 49.317076],
            [42.071349, 49.311787],
            [42.078087, 49.340972],
            [42.029998, 49.342203],
            [42.027719, 49.358697],
            [42.045053, 49.386119],
            [42.039484, 49.405585],
            [42.059534, 49.43157],
            [42.063311, 49.471003],
            [42.079416, 49.481835],
            [42.071475, 49.488621],
            [42.081482, 49.51253],
            [42.103262, 49.516963],
            [42.118558, 49.553812],
            [42.124338, 49.592714],
            [42.130499, 49.595313],
            [42.184355, 49.675666],
            [42.167569, 49.704099],
            [42.136251, 49.732863],
            [42.064703, 49.770174],
            [41.998759, 49.825964],
            [41.984923, 49.835096],
            [41.920794, 49.850074],
            [41.87773, 49.907268],
            [41.83972, 49.918772],
            [41.813886, 49.912302],
            [41.751389, 49.918476],
            [41.722128, 49.943959],
            [41.617367, 49.959574],
            [41.576483, 49.992399],
            [41.592494, 50.02029],
            [41.610802, 50.041515],
            [41.574625, 50.061049],
            [41.492899, 50.082891],
            [41.484191, 50.10311],
            [41.456458, 50.131195],
            [41.400994, 50.202806],
            [41.382154, 50.22721],
            [41.417573, 50.242803],
            [41.522312, 50.362297],
            [41.496394, 50.419945],
            [41.420479, 50.498893],
            [41.487791, 50.563731],
            [41.539097, 50.574381],
            [41.51734, 50.608973],
            [41.446748, 50.606084],
            [41.347529, 50.710292],
            [41.275575, 50.749105],
            [41.168874, 50.776314],
            [41.329588, 50.821908],
            [41.355783, 50.827006],
            [41.3928, 50.842407],
            [41.420919, 50.831443],
            [41.424907, 50.86729],
            [41.418472, 50.889863],
            [41.42857, 50.915329],
            [41.471846, 50.949818],
            [41.509193, 50.954785],
            [41.525203, 50.946212],
            [41.540637, 50.959291],
            [41.61289, 50.983487],
            [41.652193, 50.988197],
            [41.704036, 51.002396],
            [41.750759, 51.006208],
            [41.778361, 51.019382],
            [41.765484, 51.022296],
            [41.787881, 51.033682],
            [41.796408, 51.080121],
            [41.82, 51.11841],
            [41.857815, 51.158983],
            [41.928097, 51.194125],
            [41.966635, 51.195095],
            [41.984159, 51.176964],
            [42.024227, 51.170781],
            [42.087336, 51.147966],
            [42.174743, 51.135876],
            [42.213122, 51.139226],
            [42.293994, 51.137003],
            [42.422761, 51.160901],
            [42.434685, 51.14524],
            [42.491683, 51.143283],
            [42.553001, 51.144735],
            [42.595525, 51.153082],
            [42.647899, 51.218732],
            [42.677605, 51.237477],
            [42.708392, 51.247608],
            [42.75002, 51.236361],
            [42.85534, 51.244728],
            [42.880894, 51.233025],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5545",
      properties: {
        name: "Вологодская область",
        density: 0,
        path: "/world/Russia/Вологодская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.722362, 59.084852],
            [34.752138, 59.09343],
            [34.76224, 59.089266],
            [34.783195, 59.100666],
            [34.784901, 59.114522],
            [34.803671, 59.127321],
            [34.834593, 59.127253],
            [34.842542, 59.173004],
            [34.849982, 59.171878],
            [34.84926, 59.201096],
            [34.863305, 59.215629],
            [34.891208, 59.218517],
            [34.925839, 59.213223],
            [34.912944, 59.228606],
            [34.936793, 59.222012],
            [34.975849, 59.224128],
            [34.999124, 59.246607],
            [35.037493, 59.254795],
            [35.047762, 59.249169],
            [35.100451, 59.246096],
            [35.148201, 59.256286],
            [35.178108, 59.257808],
            [35.195082, 59.277462],
            [35.188167, 59.286016],
            [35.24689, 59.30188],
            [35.35703, 59.307346],
            [35.396223, 59.317561],
            [35.390585, 59.32745],
            [35.354194, 59.322604],
            [35.299507, 59.340982],
            [35.297205, 59.35093],
            [35.312699, 59.371749],
            [35.347921, 59.365879],
            [35.392971, 59.380897],
            [35.413721, 59.398474],
            [35.442389, 59.404345],
            [35.426143, 59.40885],
            [35.440613, 59.425505],
            [35.390374, 59.429054],
            [35.4071, 59.44137],
            [35.373058, 59.472722],
            [35.31825, 59.486473],
            [35.316317, 59.520275],
            [35.417065, 59.517033],
            [35.495147, 59.50147],
            [35.512622, 59.503458],
            [35.476921, 59.516666],
            [35.421495, 59.519259],
            [35.40948, 59.530044],
            [35.441465, 59.534755],
            [35.466134, 59.5467],
            [35.493687, 59.535701],
            [35.49466, 59.651901],
            [35.605574, 59.614381],
            [35.615236, 59.712406],
            [35.584817, 59.720882],
            [35.565239, 59.710899],
            [35.442658, 59.705428],
            [35.407351, 59.719425],
            [35.397589, 59.771027],
            [35.375027, 59.8579],
            [35.4222, 59.905566],
            [35.447103, 59.916773],
            [35.446386, 59.977593],
            [35.321386, 59.980802],
            [35.322887, 59.995253],
            [35.286651, 60.000382],
            [35.282711, 60.014191],
            [35.145554, 60.020779],
            [35.140138, 60.042845],
            [35.213954, 60.074013],
            [35.207347, 60.11418],
            [35.175518, 60.128019],
            [35.155502, 60.151927],
            [35.181838, 60.201357],
            [35.174712, 60.211912],
            [35.185005, 60.245507],
            [35.162628, 60.256032],
            [35.237984, 60.291391],
            [35.252712, 60.313907],
            [35.253705, 60.332801],
            [35.274424, 60.336265],
            [35.260854, 60.35912],
            [35.278109, 60.478981],
            [35.298761, 60.601439],
            [35.272549, 60.610176],
            [35.281693, 60.637821],
            [35.261077, 60.669493],
            [35.258414, 60.689493],
            [35.20872, 60.698093],
            [35.237252, 60.714475],
            [35.237523, 60.731336],
            [35.220458, 60.733519],
            [35.184143, 60.751096],
            [35.175541, 60.770072],
            [35.200044, 60.799253],
            [35.223662, 60.800618],
            [35.241953, 60.834339],
            [35.259018, 60.83393],
            [35.261611, 60.846421],
            [35.286867, 60.844237],
            [35.328845, 60.856934],
            [35.363521, 60.860621],
            [35.393146, 60.854068],
            [35.461678, 60.856936],
            [35.491574, 60.885264],
            [35.51874, 60.893593],
            [35.526136, 60.920639],
            [35.696684, 61.003459],
            [35.697879, 61.207401],
            [35.829805, 61.243138],
            [36.459932, 61.407254],
            [36.499441, 61.416318],
            [36.516838, 61.427001],
            [36.495489, 61.44098],
            [36.576504, 61.515389],
            [36.584329, 61.53946],
            [36.695913, 61.560066],
            [36.721623, 61.550363],
            [36.873933, 61.539284],
            [36.883783, 61.56252],
            [37.029972, 61.614232],
            [37.032279, 61.599363],
            [37.057142, 61.599671],
            [37.170795, 61.557221],
            [37.29236, 61.550556],
            [37.291399, 61.528535],
            [37.30461, 61.510119],
            [37.467147, 61.511015],
            [37.52285, 61.529969],
            [37.569782, 61.524226],
            [37.705644, 61.539714],
            [37.758623, 61.531392],
            [37.81674, 61.529811],
            [37.836488, 61.501609],
            [37.807225, 61.452017],
            [37.849785, 61.432417],
            [37.823888, 61.400439],
            [37.84313, 61.375653],
            [37.88074, 61.366033],
            [37.872908, 61.290487],
            [37.952744, 61.266925],
            [37.953559, 61.229406],
            [38.018173, 61.224792],
            [38.007959, 61.141705],
            [38.061167, 61.140756],
            [38.123649, 61.160507],
            [38.177177, 61.131388],
            [38.19894, 61.109455],
            [38.176661, 61.09892],
            [38.211378, 61.063861],
            [38.269752, 61.068007],
            [38.276315, 61.058335],
            [38.206025, 61.038128],
            [38.18841, 61.025865],
            [38.189275, 61.003932],
            [38.220363, 60.990807],
            [38.217256, 60.975781],
            [38.181332, 60.985107],
            [38.21795, 60.931051],
            [38.252837, 60.914644],
            [38.308276, 60.9162],
            [38.325893, 60.904801],
            [38.367169, 60.914128],
            [38.367515, 60.901002],
            [38.392299, 60.892972],
            [38.388273, 60.884511],
            [38.40943, 60.876998],
            [38.400537, 60.846947],
            [38.412024, 60.820265],
            [38.404946, 60.806727],
            [38.586728, 60.797676],
            [38.578045, 60.779987],
            [38.598313, 60.774146],
            [38.589033, 60.761667],
            [38.616201, 60.745476],
            [38.619959, 60.716362],
            [38.807283, 60.708491],
            [38.8125, 60.73325],
            [38.876024, 60.767166],
            [38.89625, 60.7715],
            [38.906964, 60.759514],
            [38.927, 60.76725],
            [38.975319, 60.756728],
            [39.015268, 60.7204],
            [39.143225, 60.679941],
            [39.161949, 60.655133],
            [39.186348, 60.643816],
            [39.18025, 60.679521],
            [39.159233, 60.695326],
            [39.188722, 60.711376],
            [39.19359, 60.722709],
            [39.234775, 60.718864],
            [39.258726, 60.71038],
            [39.276301, 60.720585],
            [39.321713, 60.709877],
            [39.334071, 60.692129],
            [39.375122, 60.687016],
            [39.38433, 60.697441],
            [39.418694, 60.69161],
            [39.43965, 60.698963],
            [39.488809, 60.699863],
            [39.52214, 60.715796],
            [39.572829, 60.723612],
            [39.589063, 60.72141],
            [39.645104, 60.750209],
            [39.657584, 60.723181],
            [39.687938, 60.718519],
            [39.731718, 60.728061],
            [39.758875, 60.741187],
            [39.81315, 60.712433],
            [39.816648, 60.696587],
            [39.837848, 60.693911],
            [39.879644, 60.667487],
            [39.885604, 60.65393],
            [40.01047, 60.646765],
            [40.023767, 60.670167],
            [40.048809, 60.674312],
            [40.185938, 60.671378],
            [40.19319, 60.694521],
            [40.251393, 60.679324],
            [40.28248, 60.67967],
            [40.310803, 60.694782],
            [40.333945, 60.6991],
            [40.338002, 60.720429],
            [40.353891, 60.726042],
            [40.372628, 60.749789],
            [40.407773, 60.759634],
            [40.407167, 60.777336],
            [40.428928, 60.783295],
            [40.453539, 60.777337],
            [40.47815, 60.759981],
            [40.574868, 60.719656],
            [40.656039, 60.728983],
            [40.650253, 60.739345],
            [40.72106, 60.780623],
            [40.771145, 60.775442],
            [40.754481, 60.738224],
            [40.793254, 60.734339],
            [40.785138, 60.715341],
            [40.822357, 60.707052],
            [40.844895, 60.709038],
            [40.903529, 60.703858],
            [40.895154, 60.684256],
            [41.085131, 60.666039],
            [41.076152, 60.6473],
            [41.093509, 60.640306],
            [41.140831, 60.635816],
            [41.197219, 60.643848],
            [41.198859, 60.653347],
            [41.261206, 60.655247],
            [41.270359, 60.665696],
            [41.309994, 60.668287],
            [41.349546, 60.640742],
            [41.369925, 60.641519],
            [41.35982, 60.673987],
            [41.342462, 60.687545],
            [41.361197, 60.732621],
            [41.424661, 60.818025],
            [41.383383, 60.838922],
            [41.377683, 60.85032],
            [41.426039, 60.870268],
            [41.509109, 60.895225],
            [41.567656, 60.898853],
            [41.651161, 60.884434],
            [41.651594, 60.8663],
            [41.696843, 60.857579],
            [41.728535, 60.858356],
            [41.725166, 60.870187],
            [41.768515, 60.873382],
            [41.796233, 60.893158],
            [41.793383, 60.902311],
            [41.824642, 60.916387],
            [41.885175, 60.909998],
            [41.910305, 60.898168],
            [41.929302, 60.916043],
            [41.952876, 60.914835],
            [41.94381, 60.905249],
            [41.956678, 60.870018],
            [41.93155, 60.86829],
            [41.926889, 60.84368],
            [41.974728, 60.845925],
            [41.983882, 60.840054],
            [42.063499, 60.839105],
            [42.111598, 60.835738],
            [42.126623, 60.844201],
            [42.128811, 60.812703],
            [42.166002, 60.811301],
            [42.153136, 60.801025],
            [42.173689, 60.786259],
            [42.208144, 60.784446],
            [42.224724, 60.790318],
            [42.355463, 60.773741],
            [42.375325, 60.767783],
            [42.429209, 60.768388],
            [42.46077, 60.786825],
            [42.497643, 60.784191],
            [42.508652, 60.797188],
            [42.534299, 60.79857],
            [42.519447, 60.786653],
            [42.523075, 60.766964],
            [42.570482, 60.773183],
            [42.563918, 60.79952],
            [42.545611, 60.799606],
            [42.556579, 60.781213],
            [42.536545, 60.787344],
            [42.540171, 60.80021],
            [42.566334, 60.819468],
            [42.570133, 60.838206],
            [42.585158, 60.837516],
            [42.609163, 60.864199],
            [42.658296, 60.887947],
            [42.80009, 60.845636],
            [42.802602, 60.85716],
            [42.828845, 60.854531],
            [42.887567, 60.832253],
            [42.883336, 60.821977],
            [42.919692, 60.813687],
            [42.911922, 60.782082],
            [42.950003, 60.783896],
            [42.984028, 60.760754],
            [42.994978, 60.729891],
            [43.077678, 60.744464],
            [43.100776, 60.78623],
            [43.118737, 60.789339],
            [43.135143, 60.809201],
            [43.153104, 60.811964],
            [43.178666, 60.795557],
            [43.20483, 60.806093],
            [43.250684, 60.79245],
            [43.523734, 60.766376],
            [43.544626, 60.85791],
            [43.597646, 60.866201],
            [43.657748, 60.870864],
            [43.681752, 60.895044],
            [43.705759, 60.893144],
            [43.734947, 60.881055],
            [43.766378, 60.896254],
            [43.735118, 60.90817],
            [43.74047, 60.928549],
            [43.767757, 60.934767],
            [43.75325, 60.940294],
            [43.782949, 61.037182],
            [43.868351, 61.045559],
            [43.896762, 61.036061],
            [43.898834, 61.045473],
            [43.93847, 61.044179],
            [43.940974, 61.054973],
            [43.978624, 61.057391],
            [44.024737, 61.053765],
            [44.09451, 61.052989],
            [44.180954, 61.061493],
            [44.159802, 60.965034],
            [44.454296, 60.943154],
            [44.691211, 60.926362],
            [44.92503, 60.910785],
            [45.251136, 60.89404],
            [45.314676, 61.120363],
            [45.349736, 61.118291],
            [45.36165, 61.15525],
            [45.326418, 61.158704],
            [45.338405, 61.195118],
            [45.533179, 61.180497],
            [45.809008, 61.161534],
            [45.788077, 61.104998],
            [45.759413, 61.011219],
            [45.90686, 60.999126],
            [46.225388, 60.975721],
            [46.299524, 60.968742],
            [46.312821, 60.995849],
            [46.365158, 60.995728],
            [46.412762, 61.012089],
            [46.474058, 61.008627],
            [46.484347, 61.040503],
            [46.52476, 61.043915],
            [46.552399, 61.023029],
            [46.542857, 60.982266],
            [46.67947, 60.970896],
            [46.823677, 60.963126],
            [46.793613, 60.922724],
            [46.820444, 60.881563],
            [46.89963, 60.881966],
            [46.933693, 60.870991],
            [46.924817, 60.856179],
            [47.072164, 60.844481],
            [47.063475, 60.810076],
            [47.03308, 60.725683],
            [46.982951, 60.721814],
            [47.015613, 60.708681],
            [46.976484, 60.69233],
            [47.006089, 60.669941],
            [46.996993, 60.659389],
            [46.964361, 60.657176],
            [46.962752, 60.639727],
            [46.993382, 60.639225],
            [47.013141, 60.624541],
            [46.99722, 60.604888],
            [46.997222, 60.574372],
            [47.01787, 60.567158],
            [47.001618, 60.557705],
            [47.038685, 60.555632],
            [47.038023, 60.544604],
            [46.97929, 60.551955],
            [46.952612, 60.535232],
            [46.873836, 60.513505],
            [46.890007, 60.484233],
            [46.888754, 60.454133],
            [46.863183, 60.453227],
            [46.83582, 60.434644],
            [46.871727, 60.414204],
            [46.79121, 60.395297],
            [46.779351, 60.396043],
            [46.758457, 60.36478],
            [46.843537, 60.358231],
            [46.794365, 60.324729],
            [46.816341, 60.297448],
            [46.763025, 60.233679],
            [46.749426, 60.234011],
            [46.635985, 60.2627],
            [46.551983, 60.260709],
            [46.521551, 60.255153],
            [46.510605, 60.243046],
            [46.47586, 60.246362],
            [46.415822, 60.268916],
            [46.390614, 60.263111],
            [46.384976, 60.246195],
            [46.35048, 60.240307],
            [46.304208, 60.244411],
            [46.283104, 60.253822],
            [46.264862, 60.229691],
            [46.294466, 60.228282],
            [46.290486, 60.21717],
            [46.329626, 60.214766],
            [46.3453, 60.192709],
            [46.329711, 60.18906],
            [46.326561, 60.166173],
            [46.358653, 60.161281],
            [46.353181, 60.147018],
            [46.378059, 60.12861],
            [46.352685, 60.110449],
            [46.393733, 60.109206],
            [46.399289, 60.101328],
            [46.443238, 60.111694],
            [46.497885, 60.106305],
            [46.506592, 60.095028],
            [46.547889, 60.088726],
            [46.551455, 60.081512],
            [46.588522, 60.080352],
            [46.598308, 60.064513],
            [46.624512, 60.061943],
            [46.651502, 60.084665],
            [46.666843, 60.077658],
            [46.664522, 60.061902],
            [46.679697, 60.07177],
            [46.76088, 60.061987],
            [46.751676, 60.048014],
            [46.770376, 60.040178],
            [46.78555, 60.059126],
            [46.835014, 60.054234],
            [46.823569, 60.068912],
            [46.89082, 60.075795],
            [46.883355, 60.101087],
            [46.920587, 60.123228],
            [47.034772, 60.126381],
            [47.006916, 60.029526],
            [46.988095, 59.983172],
            [46.922349, 59.765497],
            [47.152877, 59.752233],
            [47.138288, 59.663505],
            [47.117822, 59.598969],
            [46.79716, 59.623604],
            [46.78983, 59.593086],
            [46.702943, 59.598144],
            [46.408911, 59.618645],
            [46.372459, 59.50016],
            [46.406308, 59.496395],
            [46.368871, 59.343196],
            [46.165765, 59.353715],
            [46.163072, 59.313612],
            [46.020292, 59.323947],
            [45.979028, 59.171648],
            [45.718589, 59.189087],
            [45.609981, 59.197834],
            [45.605968, 59.177136],
            [45.579906, 59.17945],
            [45.570329, 59.201016],
            [45.340468, 59.216042],
            [45.333896, 59.183884],
            [45.056262, 59.198298],
            [45.039644, 59.121812],
            [44.774014, 59.138523],
            [44.587632, 59.151573],
            [44.40025, 59.158691],
            [44.352629, 59.132541],
            [44.331382, 59.141296],
            [44.333456, 59.149681],
            [44.294622, 59.151471],
            [44.293631, 59.16983],
            [44.215437, 59.172803],
            [44.190221, 59.17012],
            [44.197831, 59.208372],
            [44.207774, 59.225699],
            [44.171108, 59.237329],
            [44.090678, 59.234088],
            [43.998309, 59.214834],
            [43.981004, 59.208149],
            [43.94817, 59.208092],
            [43.927293, 59.228381],
            [43.900833, 59.239426],
            [43.844462, 59.237603],
            [43.806349, 59.242564],
            [43.776377, 59.268368],
            [43.755109, 59.268807],
            [43.745345, 59.259308],
            [43.692617, 59.25915],
            [43.671753, 59.24439],
            [43.674542, 59.233587],
            [43.625249, 59.2391],
            [43.609263, 59.23455],
            [43.633666, 59.221868],
            [43.63605, 59.211152],
            [43.616033, 59.207928],
            [43.610897, 59.189801],
            [43.566936, 59.179544],
            [43.534962, 59.1785],
            [43.520612, 59.191797],
            [43.525388, 59.207576],
            [43.557065, 59.217689],
            [43.530879, 59.240261],
            [43.544296, 59.257313],
            [43.508454, 59.259547],
            [43.513902, 59.297745],
            [43.504048, 59.305888],
            [43.455835, 59.289315],
            [43.420694, 59.291302],
            [43.361899, 59.279903],
            [43.33743, 59.266158],
            [43.34376, 59.24626],
            [43.369449, 59.230832],
            [43.382571, 59.204406],
            [43.334569, 59.198145],
            [43.327444, 59.211571],
            [43.29415, 59.227636],
            [43.284226, 59.243021],
            [43.263919, 59.246937],
            [43.235867, 59.228369],
            [43.217779, 59.234271],
            [43.175206, 59.263005],
            [43.143517, 59.267955],
            [43.143818, 59.292548],
            [43.132496, 59.281689],
            [43.151491, 59.254514],
            [43.084482, 59.250143],
            [43.076591, 59.229135],
            [43.028181, 59.202897],
            [43.001329, 59.21278],
            [42.959777, 59.195358],
            [42.928633, 59.19473],
            [42.908979, 59.200636],
            [42.903206, 59.183689],
            [42.878378, 59.174397],
            [42.885856, 59.166217],
            [42.858727, 59.166718],
            [42.864552, 59.177565],
            [42.823221, 59.185086],
            [42.799691, 59.170034],
            [42.764739, 59.197224],
            [42.757316, 59.216919],
            [42.711966, 59.229724],
            [42.692399, 59.223503],
            [42.654928, 59.264309],
            [42.683234, 59.273741],
            [42.673167, 59.298031],
            [42.65278, 59.300512],
            [42.668402, 59.314497],
            [42.636381, 59.328185],
            [42.573804, 59.329918],
            [42.567289, 59.315537],
            [42.523102, 59.331103],
            [42.549887, 59.336868],
            [42.507684, 59.338405],
            [42.507236, 59.319136],
            [42.348388, 59.304629],
            [42.338712, 59.284418],
            [42.312121, 59.271917],
            [42.306128, 59.261643],
            [42.279223, 59.261537],
            [42.289094, 59.276239],
            [42.271467, 59.280815],
            [42.272523, 59.291805],
            [42.231835, 59.30069],
            [42.202767, 59.327548],
            [42.216254, 59.408483],
            [42.124956, 59.411216],
            [42.126811, 59.420086],
            [42.089084, 59.431424],
            [42.034862, 59.429549],
            [41.986462, 59.3929],
            [41.9622, 59.281707],
            [41.938463, 59.27723],
            [41.951354, 59.261145],
            [42.0292, 59.241964],
            [42.031676, 59.254315],
            [42.066766, 59.245886],
            [42.098663, 59.24982],
            [42.090339, 59.15592],
            [42.047829, 59.150266],
            [42.038264, 59.136328],
            [42.058869, 59.125959],
            [42.023517, 59.109085],
            [42.001337, 59.104732],
            [41.958691, 59.122382],
            [41.927085, 59.101708],
            [41.948905, 59.078643],
            [41.916598, 59.082914],
            [41.906841, 59.07355],
            [41.878227, 59.083329],
            [41.861284, 59.096686],
            [41.826127, 59.10498],
            [41.770671, 59.110749],
            [41.72852, 59.110905],
            [41.71442, 59.084944],
            [41.683712, 59.084404],
            [41.666098, 59.075326],
            [41.61607, 59.069265],
            [41.573567, 59.044893],
            [41.525212, 59.041272],
            [41.531057, 59.023458],
            [41.515481, 59.013879],
            [41.39821, 58.988965],
            [41.349027, 58.951944],
            [41.334083, 58.983058],
            [41.305487, 58.99124],
            [41.268229, 58.992002],
            [41.225368, 58.961497],
            [41.190382, 58.95622],
            [41.172, 58.931752],
            [41.187637, 58.921902],
            [41.177734, 58.914105],
            [41.182087, 58.895598],
            [41.226299, 58.884518],
            [41.263991, 58.898907],
            [41.255651, 58.875897],
            [41.259186, 58.838373],
            [41.243286, 58.852072],
            [41.199924, 58.848598],
            [41.222141, 58.819695],
            [41.156287, 58.803261],
            [41.168609, 58.782112],
            [41.188086, 58.78016],
            [41.230467, 58.741234],
            [41.22104, 58.713521],
            [41.189787, 58.712434],
            [41.194472, 58.701293],
            [41.168179, 58.688612],
            [41.171111, 58.664988],
            [41.153437, 58.651692],
            [41.10521, 58.654407],
            [41.065726, 58.632777],
            [41.052204, 58.606772],
            [40.989051, 58.59741],
            [40.997626, 58.584298],
            [40.958024, 58.578124],
            [40.932482, 58.588871],
            [40.930072, 58.576336],
            [40.889446, 58.577944],
            [40.890291, 58.591265],
            [40.856679, 58.576996],
            [40.834739, 58.574619],
            [40.813721, 58.590818],
            [40.782557, 58.594103],
            [40.763943, 58.587026],
            [40.72971, 58.58953],
            [40.711103, 58.584275],
            [40.69444, 58.594323],
            [40.608548, 58.585411],
            [40.569449, 58.56794],
            [40.53503, 58.567128],
            [40.512763, 58.546907],
            [40.457932, 58.547559],
            [40.392445, 58.54185],
            [40.368509, 58.552355],
            [40.343029, 58.554866],
            [40.327573, 58.536621],
            [40.302323, 58.535242],
            [40.264165, 58.55235],
            [40.229404, 58.547819],
            [40.185873, 58.555134],
            [40.162469, 58.578585],
            [40.130086, 58.578874],
            [40.088303, 58.595198],
            [40.076797, 58.608179],
            [40.09108, 58.623194],
            [40.123609, 58.620308],
            [40.135936, 58.626926],
            [40.125689, 58.649295],
            [40.104903, 58.658993],
            [40.069843, 58.650933],
            [40.048492, 58.656204],
            [40.053786, 58.676136],
            [40.035743, 58.677308],
            [40.014392, 58.721089],
            [40.020075, 58.741223],
            [39.979688, 58.741128],
            [39.954285, 58.75844],
            [39.914668, 58.758523],
            [39.887569, 58.748857],
            [39.870957, 58.752406],
            [39.853778, 58.77945],
            [39.812688, 58.809344],
            [39.793261, 58.837057],
            [39.73947, 58.834313],
            [39.71973, 58.820438],
            [39.712795, 58.837611],
            [39.604119, 58.904925],
            [39.571404, 58.875857],
            [39.500675, 58.883894],
            [39.454599, 58.882638],
            [39.457371, 58.894029],
            [39.437477, 58.89839],
            [39.392464, 58.891436],
            [39.377833, 58.883279],
            [39.363122, 58.897599],
            [39.368656, 58.909982],
            [39.332204, 58.915924],
            [39.334266, 58.926163],
            [39.290723, 58.924279],
            [39.257841, 58.91538],
            [39.267398, 58.905783],
            [39.244836, 58.898895],
            [39.234881, 58.915334],
            [39.210407, 58.926676],
            [39.175345, 58.930193],
            [39.124124, 58.95099],
            [39.10996, 58.937947],
            [39.023818, 58.928068],
            [38.989808, 58.956012],
            [38.948761, 58.943357],
            [38.944178, 58.907422],
            [38.926217, 58.896967],
            [38.957458, 58.891373],
            [39.001896, 58.85837],
            [39.036062, 58.855655],
            [39.000323, 58.837786],
            [39.005826, 58.832894],
            [38.941013, 58.805571],
            [38.920087, 58.807158],
            [38.938282, 58.795379],
            [38.915831, 58.779062],
            [38.921668, 58.770098],
            [38.923263, 58.733331],
            [38.847842, 58.734774],
            [38.842299, 58.746996],
            [38.728614, 58.745622],
            [38.719124, 58.753602],
            [38.690658, 58.747472],
            [38.701999, 58.732646],
            [38.676623, 58.729964],
            [38.662891, 58.757303],
            [38.649014, 58.750949],
            [38.635604, 58.761153],
            [38.557291, 58.772827],
            [38.565221, 58.751712],
            [38.547214, 58.748055],
            [38.539141, 58.726815],
            [38.482592, 58.699892],
            [38.412961, 58.726816],
            [38.284253, 58.728478],
            [38.094185, 58.72865],
            [38.064554, 58.730111],
            [38.018375, 58.713666],
            [37.983481, 58.707115],
            [38.022971, 58.689446],
            [38.014158, 58.682653],
            [37.95983, 58.679983],
            [37.966591, 58.66312],
            [37.927378, 58.647451],
            [37.896818, 58.611319],
            [37.86559, 58.610613],
            [37.833259, 58.630544],
            [37.76473, 58.623164],
            [37.747035, 58.628428],
            [37.712146, 58.625677],
            [37.697463, 58.571405],
            [37.720287, 58.561851],
            [37.694258, 58.523322],
            [37.640894, 58.484668],
            [37.628222, 58.482944],
            [37.596134, 58.507638],
            [37.571111, 58.517203],
            [37.539429, 58.521115],
            [37.512288, 58.553923],
            [37.476755, 58.559603],
            [37.413973, 58.583469],
            [37.392495, 58.600564],
            [37.364895, 58.646456],
            [37.340476, 58.652521],
            [37.292556, 58.645339],
            [37.285897, 58.663322],
            [37.306579, 58.671568],
            [37.304705, 58.696169],
            [37.276161, 58.68144],
            [37.27266, 58.697968],
            [37.23392, 58.714688],
            [37.234456, 58.739163],
            [37.202669, 58.747582],
            [37.188408, 58.792383],
            [37.195804, 58.814279],
            [37.18322, 58.826327],
            [37.137009, 58.834349],
            [37.121601, 58.853441],
            [37.070959, 58.863139],
            [37.032297, 58.846149],
            [37.031405, 58.836855],
            [36.997589, 58.838681],
            [36.959319, 58.828296],
            [36.883815, 58.835535],
            [36.847863, 58.836168],
            [36.800934, 58.808617],
            [36.765447, 58.800944],
            [36.779681, 58.774926],
            [36.821566, 58.760337],
            [36.829193, 58.742107],
            [36.808341, 58.735456],
            [36.810425, 58.723997],
            [36.786967, 58.723219],
            [36.763185, 58.713163],
            [36.721537, 58.715532],
            [36.685747, 58.709504],
            [36.687498, 58.694438],
            [36.66368, 58.701748],
            [36.629739, 58.650605],
            [36.638635, 58.642348],
            [36.630504, 58.619255],
            [36.587092, 58.626687],
            [36.594984, 58.614127],
            [36.579527, 58.599135],
            [36.539134, 58.603816],
            [36.506743, 58.576879],
            [36.543732, 58.569482],
            [36.537297, 58.55169],
            [36.522387, 58.543852],
            [36.520438, 58.525277],
            [36.503051, 58.515478],
            [36.455388, 58.527473],
            [36.434238, 58.51164],
            [36.394136, 58.52219],
            [36.352442, 58.490262],
            [36.359827, 58.47943],
            [36.327619, 58.484121],
            [36.287792, 58.515481],
            [36.243809, 58.521414],
            [36.231289, 58.505763],
            [36.181682, 58.51165],
            [36.176846, 58.541681],
            [36.212545, 58.559521],
            [36.228686, 58.582474],
            [36.219745, 58.607657],
            [36.199279, 58.61869],
            [36.1498, 58.613111],
            [36.127689, 58.623972],
            [36.153556, 58.632827],
            [36.116421, 58.674069],
            [36.119013, 58.699482],
            [36.103803, 58.724755],
            [36.045221, 58.714719],
            [36.033312, 58.725155],
            [36.016158, 58.723456],
            [36.010762, 58.703056],
            [35.996759, 58.714157],
            [35.995346, 58.684884],
            [35.987598, 58.684919],
            [35.986987, 58.72292],
            [35.928449, 58.729182],
            [35.912573, 58.738155],
            [35.966447, 58.735174],
            [35.97774, 58.745056],
            [35.962167, 58.751129],
            [35.925393, 58.747785],
            [35.899606, 58.755414],
            [35.858508, 58.744873],
            [35.83502, 58.75555],
            [35.783203, 58.753698],
            [35.775429, 58.772214],
            [35.750499, 58.781599],
            [35.744992, 58.82213],
            [35.790648, 58.821448],
            [35.817984, 58.806757],
            [35.827022, 58.815966],
            [35.775797, 58.829755],
            [35.75633, 58.842318],
            [35.724038, 58.838771],
            [35.711735, 58.821923],
            [35.726526, 58.818099],
            [35.57926, 58.810316],
            [35.575546, 58.829182],
            [35.557281, 58.828387],
            [35.552567, 58.847333],
            [35.491686, 58.843629],
            [35.468928, 58.84862],
            [35.461428, 58.861123],
            [35.43756, 58.847561],
            [35.409386, 58.84934],
            [35.3872, 58.841041],
            [35.360265, 58.846879],
            [35.366683, 58.827381],
            [35.317402, 58.799765],
            [35.299956, 58.799885],
            [35.295517, 58.779846],
            [35.282841, 58.767134],
            [35.27866, 58.786333],
            [35.247236, 58.78074],
            [35.253609, 58.792572],
            [35.226512, 58.789791],
            [35.224269, 58.804292],
            [35.245632, 58.815665],
            [35.209615, 58.829118],
            [35.175289, 58.816589],
            [35.128426, 58.81525],
            [35.143263, 58.84902],
            [35.131941, 58.868317],
            [35.138309, 58.885517],
            [35.162752, 58.907806],
            [35.185952, 58.905345],
            [35.199209, 58.921745],
            [35.185427, 58.924265],
            [35.17886, 58.948819],
            [35.126324, 58.950953],
            [35.071264, 58.926817],
            [35.02031, 58.924973],
            [35.010426, 58.93434],
            [35.047105, 58.93273],
            [35.050143, 58.949693],
            [35.012299, 58.951467],
            [34.998257, 58.939983],
            [34.952621, 58.954057],
            [34.964019, 58.97239],
            [34.868292, 58.990594],
            [34.854364, 59.005326],
            [34.886035, 59.021586],
            [34.880476, 59.037994],
            [34.897115, 59.049105],
            [34.864922, 59.055515],
            [34.787139, 59.059266],
            [34.719093, 59.077804],
            [34.722362, 59.084852],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5469",
      properties: {
        name: "Воронежская область",
        density: 0,
        path: "/world/Russia/Воронежская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.292611, 51.96594],
            [38.32064, 51.983608],
            [38.399619, 51.987485],
            [38.394868, 52.00148],
            [38.433678, 52.004334],
            [38.481296, 52.017863],
            [38.500885, 52.033109],
            [38.547496, 52.047847],
            [38.555547, 52.055531],
            [38.600616, 52.05403],
            [38.624604, 52.039113],
            [38.678184, 52.0642],
            [38.669927, 52.082876],
            [38.706769, 52.098242],
            [38.72398, 52.078903],
            [38.723439, 52.064442],
            [38.741764, 52.041692],
            [38.786778, 52.029204],
            [38.810615, 52.045874],
            [38.784269, 52.052355],
            [38.800134, 52.075047],
            [38.827308, 52.092962],
            [38.877206, 52.09655],
            [38.901691, 52.087083],
            [38.908748, 52.07592],
            [38.977003, 52.025529],
            [39.008675, 51.997103],
            [39.015011, 51.984812],
            [39.073572, 51.98316],
            [39.103682, 51.993397],
            [39.087042, 52.034477],
            [39.103758, 52.050553],
            [39.166478, 52.055658],
            [39.175524, 52.06476],
            [39.145478, 52.082697],
            [39.179071, 52.090032],
            [39.233245, 52.079427],
            [39.263975, 52.095548],
            [39.265585, 52.102221],
            [39.299772, 52.103184],
            [39.343374, 52.098697],
            [39.33668, 52.086107],
            [39.385301, 52.084282],
            [39.419065, 52.074318],
            [39.448771, 52.07922],
            [39.46203, 52.068769],
            [39.473172, 52.073549],
            [39.501846, 52.045357],
            [39.49482, 52.042394],
            [39.515367, 52.022596],
            [39.500289, 52.016721],
            [39.484369, 51.977717],
            [39.7599, 51.927417],
            [39.778475, 51.942858],
            [39.798471, 51.925889],
            [39.816124, 51.920291],
            [39.832696, 51.927747],
            [39.840761, 51.921077],
            [39.875222, 51.93448],
            [39.877509, 51.944511],
            [39.897672, 51.951036],
            [39.892448, 51.960777],
            [39.913043, 51.982835],
            [39.927212, 51.967388],
            [39.945121, 51.972976],
            [39.957253, 51.966567],
            [40.018867, 51.953321],
            [40.042467, 51.952219],
            [40.081112, 51.97195],
            [40.075816, 51.992008],
            [40.132234, 51.999536],
            [40.127123, 51.985584],
            [40.141441, 51.968883],
            [40.162126, 51.959674],
            [40.183102, 51.971246],
            [40.221411, 51.975988],
            [40.249022, 51.968522],
            [40.273819, 51.949144],
            [40.300997, 51.947372],
            [40.31161, 51.937502],
            [40.328304, 51.961849],
            [40.354018, 51.965474],
            [40.386047, 51.957373],
            [40.39621, 51.947504],
            [40.433532, 51.949337],
            [40.441414, 51.960047],
            [40.460954, 51.96042],
            [40.46156, 51.950945],
            [40.508554, 51.935072],
            [40.53414, 51.945692],
            [40.549422, 51.951287],
            [40.608231, 51.938629],
            [40.61489, 51.944898],
            [40.658441, 51.942379],
            [40.711674, 51.933371],
            [40.716701, 51.938909],
            [40.752964, 51.937303],
            [40.803869, 51.894734],
            [40.814335, 51.909495],
            [40.845697, 51.921982],
            [40.860278, 51.909668],
            [40.876566, 51.912412],
            [40.925488, 51.887958],
            [40.945671, 51.891089],
            [40.948615, 51.860852],
            [40.917884, 51.828317],
            [40.928766, 51.817667],
            [40.897881, 51.770844],
            [40.882178, 51.738862],
            [40.927168, 51.74022],
            [40.969403, 51.746758],
            [41.002491, 51.716182],
            [41.033768, 51.721225],
            [41.026164, 51.725908],
            [41.055265, 51.736374],
            [41.063106, 51.720509],
            [41.119121, 51.719484],
            [41.116109, 51.730632],
            [41.249755, 51.737803],
            [41.296199, 51.738301],
            [41.284019, 51.756992],
            [41.280708, 51.791969],
            [41.3029, 51.788803],
            [41.307116, 51.801948],
            [41.349144, 51.799556],
            [41.345818, 51.788681],
            [41.428666, 51.772346],
            [41.460271, 51.769938],
            [41.472619, 51.789384],
            [41.462463, 51.805777],
            [41.487444, 51.797682],
            [41.507537, 51.799297],
            [41.51318, 51.777484],
            [41.546007, 51.770497],
            [41.585224, 51.769251],
            [41.617788, 51.755218],
            [41.635909, 51.732996],
            [41.679894, 51.740783],
            [41.682758, 51.732533],
            [41.722839, 51.736204],
            [41.733194, 51.70581],
            [41.783517, 51.718146],
            [41.79207, 51.708261],
            [41.812842, 51.712249],
            [41.856193, 51.711119],
            [41.855538, 51.693661],
            [41.842268, 51.69425],
            [41.839034, 51.674523],
            [41.893655, 51.680347],
            [41.931929, 51.663495],
            [41.959807, 51.656305],
            [42.010072, 51.654292],
            [42.017594, 51.668344],
            [42.069582, 51.657448],
            [42.089973, 51.629231],
            [42.136364, 51.631887],
            [42.121703, 51.676284],
            [42.107484, 51.692872],
            [42.125145, 51.702332],
            [42.105897, 51.717496],
            [42.118229, 51.730848],
            [42.16895, 51.709637],
            [42.194129, 51.707263],
            [42.18929, 51.723581],
            [42.227836, 51.718875],
            [42.24725, 51.709222],
            [42.239473, 51.702839],
            [42.295798, 51.684837],
            [42.306212, 51.689461],
            [42.295654, 51.666029],
            [42.268707, 51.663671],
            [42.280951, 51.639731],
            [42.264695, 51.612489],
            [42.287685, 51.606183],
            [42.298351, 51.619261],
            [42.343861, 51.587718],
            [42.379097, 51.594961],
            [42.425604, 51.623934],
            [42.482818, 51.631369],
            [42.498911, 51.593099],
            [42.520795, 51.596072],
            [42.534912, 51.577671],
            [42.546505, 51.578481],
            [42.568272, 51.538178],
            [42.630981, 51.53074],
            [42.752124, 51.494893],
            [42.767992, 51.472282],
            [42.834455, 51.391738],
            [42.849233, 51.401642],
            [42.85907, 51.397253],
            [42.853608, 51.358419],
            [42.843463, 51.338709],
            [42.858106, 51.316673],
            [42.904514, 51.286697],
            [42.947337, 51.26722],
            [42.941504, 51.256251],
            [42.880894, 51.233025],
            [42.85534, 51.244728],
            [42.75002, 51.236361],
            [42.708392, 51.247608],
            [42.677605, 51.237477],
            [42.647899, 51.218732],
            [42.595525, 51.153082],
            [42.553001, 51.144735],
            [42.491683, 51.143283],
            [42.434685, 51.14524],
            [42.422761, 51.160901],
            [42.293994, 51.137003],
            [42.213122, 51.139226],
            [42.174743, 51.135876],
            [42.087336, 51.147966],
            [42.024227, 51.170781],
            [41.984159, 51.176964],
            [41.966635, 51.195095],
            [41.928097, 51.194125],
            [41.857815, 51.158983],
            [41.82, 51.11841],
            [41.796408, 51.080121],
            [41.787881, 51.033682],
            [41.765484, 51.022296],
            [41.778361, 51.019382],
            [41.750759, 51.006208],
            [41.704036, 51.002396],
            [41.652193, 50.988197],
            [41.61289, 50.983487],
            [41.540637, 50.959291],
            [41.525203, 50.946212],
            [41.509193, 50.954785],
            [41.471846, 50.949818],
            [41.42857, 50.915329],
            [41.418472, 50.889863],
            [41.424907, 50.86729],
            [41.420919, 50.831443],
            [41.3928, 50.842407],
            [41.355783, 50.827006],
            [41.329588, 50.821908],
            [41.168874, 50.776314],
            [41.275575, 50.749105],
            [41.347529, 50.710292],
            [41.446748, 50.606084],
            [41.51734, 50.608973],
            [41.539097, 50.574381],
            [41.487791, 50.563731],
            [41.420479, 50.498893],
            [41.496394, 50.419945],
            [41.522312, 50.362297],
            [41.417573, 50.242803],
            [41.382154, 50.22721],
            [41.400994, 50.202806],
            [41.372793, 50.201755],
            [41.350255, 50.214477],
            [41.338107, 50.208881],
            [41.288934, 50.16324],
            [41.34569, 50.145173],
            [41.372992, 50.127898],
            [41.38366, 50.113865],
            [41.38435, 50.08111],
            [41.338275, 50.068574],
            [41.324701, 50.047593],
            [41.306175, 50.042833],
            [41.297755, 50.021471],
            [41.192887, 50.011776],
            [41.108252, 49.961177],
            [41.082125, 49.943371],
            [41.041246, 49.866913],
            [41.047121, 49.809186],
            [41.040879, 49.79981],
            [41.012444, 49.79851],
            [41.004022, 49.782426],
            [40.961071, 49.736528],
            [40.921617, 49.700629],
            [40.795915, 49.701203],
            [40.795504, 49.685151],
            [40.779277, 49.685124],
            [40.778987, 49.667234],
            [40.720055, 49.666012],
            [40.636967, 49.672992],
            [40.615951, 49.643587],
            [40.642174, 49.644334],
            [40.639862, 49.637089],
            [40.59628, 49.635017],
            [40.575904, 49.622558],
            [40.560794, 49.604971],
            [40.549094, 49.580892],
            [40.535614, 49.577769],
            [40.50815, 49.585157],
            [40.461434, 49.579025],
            [40.374629, 49.587519],
            [40.277485, 49.628409],
            [40.212694, 49.630173],
            [40.16067, 49.643123],
            [40.135392, 49.617102],
            [40.06491, 49.59881],
            [40.05735, 49.60653],
            [39.95212, 49.59709],
            [39.93513, 49.57392],
            [39.89118, 49.5583],
            [39.83969, 49.56379],
            [39.80604, 49.55795],
            [39.75025, 49.59743],
            [39.68605, 49.61203],
            [39.6603, 49.61494],
            [39.63884, 49.63451],
            [39.61378, 49.69065],
            [39.59129, 49.71828],
            [39.61052, 49.73373],
            [39.53413, 49.74094],
            [39.47868, 49.75656],
            [39.43371, 49.75931],
            [39.37878, 49.73854],
            [39.28522, 49.75536],
            [39.27355, 49.76824],
            [39.25089, 49.77648],
            [39.22771, 49.80738],
            [39.22668, 49.83982],
            [39.18892, 49.88514],
            [39.179732, 49.888659],
            [39.177494, 49.897864],
            [39.191898, 49.924794],
            [39.211429, 49.926593],
            [39.206576, 49.937253],
            [39.187575, 49.94283],
            [39.209425, 49.948011],
            [39.205664, 49.969317],
            [39.221344, 49.978544],
            [39.252916, 49.985873],
            [39.280925, 50.035237],
            [39.256052, 50.041068],
            [39.253077, 50.033694],
            [39.217667, 50.048129],
            [39.227257, 50.065493],
            [39.208959, 50.078259],
            [39.241521, 50.082841],
            [39.237548, 50.101622],
            [39.210941, 50.090698],
            [39.191748, 50.090902],
            [39.169416, 50.116622],
            [39.152992, 50.121253],
            [39.159814, 50.140906],
            [39.140534, 50.152613],
            [39.116441, 50.156293],
            [39.128717, 50.185827],
            [39.139482, 50.18861],
            [39.137774, 50.224666],
            [39.119534, 50.226416],
            [39.101737, 50.254404],
            [39.117791, 50.268059],
            [39.084227, 50.275384],
            [39.047222, 50.276746],
            [39.034382, 50.287401],
            [39.044122, 50.316353],
            [39.070721, 50.321265],
            [39.101595, 50.339723],
            [39.089718, 50.352052],
            [39.064292, 50.353226],
            [39.036686, 50.379302],
            [39.039465, 50.396979],
            [39.02882, 50.413468],
            [39.008814, 50.413255],
            [38.981448, 50.438618],
            [39.014783, 50.455454],
            [39.034936, 50.47522],
            [39.052111, 50.473092],
            [39.047548, 50.492195],
            [39.127046, 50.507278],
            [39.124935, 50.534216],
            [39.092792, 50.515985],
            [39.081008, 50.53075],
            [39.094009, 50.579953],
            [39.075408, 50.59274],
            [39.083569, 50.605573],
            [39.046488, 50.617566],
            [39.055398, 50.625862],
            [39.091549, 50.611086],
            [39.11927, 50.624593],
            [39.056083, 50.642618],
            [39.050269, 50.651571],
            [39.026511, 50.645965],
            [39.022606, 50.655211],
            [38.964852, 50.653082],
            [38.962109, 50.682523],
            [38.9803, 50.686629],
            [38.988247, 50.697477],
            [38.957737, 50.697817],
            [38.896723, 50.704248],
            [38.890136, 50.69107],
            [38.86467, 50.699983],
            [38.880177, 50.707802],
            [38.854885, 50.715698],
            [38.828426, 50.712965],
            [38.816357, 50.723913],
            [38.805983, 50.715272],
            [38.76859, 50.719567],
            [38.792211, 50.743781],
            [38.803684, 50.762344],
            [38.78783, 50.7664],
            [38.799805, 50.779188],
            [38.838776, 50.773982],
            [38.848768, 50.783758],
            [38.801499, 50.796417],
            [38.764058, 50.785319],
            [38.72995, 50.788673],
            [38.706516, 50.800234],
            [38.708949, 50.829055],
            [38.732049, 50.839493],
            [38.741368, 50.851543],
            [38.725201, 50.857509],
            [38.720936, 50.877304],
            [38.74862, 50.865639],
            [38.740958, 50.879436],
            [38.746102, 50.8985],
            [38.726079, 50.899699],
            [38.767346, 50.9062],
            [38.774359, 50.917929],
            [38.792354, 50.915693],
            [38.837514, 50.893409],
            [38.878355, 50.909739],
            [38.868774, 50.915701],
            [38.876593, 50.930671],
            [38.891204, 50.937082],
            [38.849455, 50.93784],
            [38.846102, 50.958492],
            [38.804784, 50.983853],
            [38.864588, 51.005923],
            [38.856455, 51.036004],
            [38.831186, 51.041992],
            [38.829598, 51.049688],
            [38.807222, 51.048325],
            [38.803495, 51.076693],
            [38.760884, 51.074385],
            [38.733582, 51.068277],
            [38.712542, 51.05047],
            [38.687174, 51.057953],
            [38.699355, 51.022127],
            [38.676016, 51.014192],
            [38.674221, 51.00548],
            [38.655072, 51.011954],
            [38.633258, 50.998237],
            [38.617944, 51.018485],
            [38.58801, 51.012156],
            [38.547524, 51.025813],
            [38.531019, 51.047907],
            [38.502001, 51.049361],
            [38.518256, 51.07103],
            [38.484068, 51.077172],
            [38.465442, 51.074446],
            [38.457899, 51.08834],
            [38.454173, 51.069511],
            [38.436559, 51.064721],
            [38.387686, 51.077056],
            [38.390817, 51.095263],
            [38.405908, 51.099242],
            [38.40725, 51.130024],
            [38.381193, 51.12959],
            [38.380353, 51.150207],
            [38.3962, 51.151986],
            [38.396234, 51.162881],
            [38.411662, 51.165983],
            [38.483829, 51.162307],
            [38.489211, 51.169521],
            [38.52222, 51.163495],
            [38.506288, 51.176994],
            [38.525427, 51.187125],
            [38.519389, 51.211023],
            [38.4757, 51.21106],
            [38.456376, 51.216301],
            [38.471738, 51.223644],
            [38.449592, 51.247711],
            [38.44631, 51.266862],
            [38.433534, 51.267265],
            [38.434292, 51.28578],
            [38.399248, 51.287113],
            [38.381546, 51.272989],
            [38.335277, 51.256143],
            [38.319747, 51.271492],
            [38.310197, 51.291902],
            [38.288584, 51.296352],
            [38.33691, 51.304152],
            [38.356969, 51.30172],
            [38.36205, 51.323779],
            [38.336448, 51.323268],
            [38.328641, 51.33115],
            [38.332386, 51.357946],
            [38.375303, 51.359852],
            [38.376395, 51.387401],
            [38.318728, 51.373982],
            [38.293467, 51.394089],
            [38.306227, 51.410398],
            [38.280482, 51.434852],
            [38.318511, 51.44484],
            [38.302876, 51.464023],
            [38.309791, 51.482844],
            [38.280533, 51.493034],
            [38.297481, 51.521305],
            [38.258393, 51.548813],
            [38.228964, 51.560909],
            [38.216189, 51.584547],
            [38.1882, 51.610012],
            [38.139917, 51.628731],
            [38.135846, 51.640672],
            [38.15256, 51.654439],
            [38.157917, 51.672848],
            [38.234734, 51.658985],
            [38.224395, 51.699273],
            [38.237557, 51.701374],
            [38.288977, 51.692752],
            [38.29114, 51.701321],
            [38.332611, 51.694216],
            [38.338935, 51.688989],
            [38.38662, 51.683529],
            [38.445159, 51.691451],
            [38.478134, 51.721006],
            [38.48381, 51.732775],
            [38.518174, 51.747509],
            [38.517086, 51.753629],
            [38.463079, 51.739917],
            [38.446167, 51.756229],
            [38.399056, 51.782003],
            [38.402726, 51.800821],
            [38.432535, 51.817115],
            [38.446396, 51.844548],
            [38.422032, 51.850628],
            [38.417763, 51.871193],
            [38.442473, 51.89253],
            [38.434247, 51.905376],
            [38.416767, 51.906304],
            [38.42656, 51.917975],
            [38.448492, 51.921944],
            [38.429118, 51.94475],
            [38.376993, 51.935711],
            [38.341533, 51.938835],
            [38.340496, 51.919174],
            [38.318387, 51.919466],
            [38.316416, 51.957489],
            [38.292611, 51.96594],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5528",
      properties: {
        name: "Дагестан",
        density: 0,
        path: "/world/Russia/Дагестан",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [45.705116, 44.977363],
              [45.734712, 44.972762],
              [45.748838, 44.975364],
              [45.783409, 44.968487],
              [45.797349, 44.976852],
              [45.793632, 44.987632],
              [45.813705, 44.991721],
              [45.846047, 44.985774],
              [45.881362, 44.988005],
              [45.969277, 44.980757],
              [45.982474, 44.989121],
              [46.01054, 44.980757],
              [46.028941, 44.981315],
              [46.083029, 44.968677],
              [46.117414, 44.969793],
              [46.155332, 44.958827],
              [46.161465, 44.963102],
              [46.200312, 44.958456],
              [46.223731, 44.972768],
              [46.248823, 44.965705],
              [46.325959, 44.949721],
              [46.347334, 44.951023],
              [46.358672, 44.9408],
              [46.383206, 44.947306],
              [46.430603, 44.934481],
              [46.43432, 44.938756],
              [46.470751, 44.935783],
              [46.484505, 44.930393],
              [46.504579, 44.934296],
              [46.570562, 44.907346],
              [46.584875, 44.889875],
              [46.63506, 44.861066],
              [46.672977, 44.859579],
              [46.690077, 44.846569],
              [46.766841, 44.827611],
              [46.772789, 44.819433],
              [46.755132, 44.80289],
              [46.787288, 44.788393],
              [46.812938, 44.77055],
              [46.812566, 44.762929],
              [46.836915, 44.770179],
              [46.825019, 44.788951],
              [46.807733, 44.80382],
              [46.826134, 44.808281],
              [46.860148, 44.799174],
              [46.895027, 44.8121],
              [46.907321, 44.791731],
              [46.891185, 44.769311],
              [46.865779, 44.744079],
              [46.840202, 44.728958],
              [46.804668, 44.715053],
              [46.767417, 44.71359],
              [46.72536, 44.674906],
              [46.731883, 44.652808],
              [46.706993, 44.669291],
              [46.697894, 44.686868],
              [46.704933, 44.656471],
              [46.698066, 44.604429],
              [46.688796, 44.561393],
              [46.688968, 44.516611],
              [46.67987, 44.467996],
              [46.695491, 44.443123],
              [46.739608, 44.41689],
              [46.774627, 44.370401],
              [46.806899, 44.369542],
              [46.834194, 44.360952],
              [46.879088, 44.366255],
              [46.889922, 44.362458],
              [46.879667, 44.345963],
              [46.890499, 44.325841],
              [46.922599, 44.328788],
              [46.928092, 44.343522],
              [46.958991, 44.338365],
              [46.971351, 44.353342],
              [46.958991, 44.358252],
              [46.949035, 44.37396],
              [47.00225, 44.364634],
              [47.016326, 44.358006],
              [47.036239, 44.332717],
              [47.080528, 44.245227],
              [47.112457, 44.220874],
              [47.141639, 44.205617],
              [47.162238, 44.208078],
              [47.194167, 44.224072],
              [47.22438, 44.219889],
              [47.239486, 44.202417],
              [47.274848, 44.113747],
              [47.289955, 44.086873],
              [47.33905, 44.030621],
              [47.370292, 44.008648],
              [47.411834, 43.931803],
              [47.43106, 43.875899],
              [47.456123, 43.872434],
              [47.469512, 43.859068],
              [47.453376, 43.829602],
              [47.459556, 43.819942],
              [47.490112, 43.806316],
              [47.517234, 43.802847],
              [47.5249, 43.792708],
              [47.483108, 43.768296],
              [47.457182, 43.744962],
              [47.424639, 43.709148],
              [47.449038, 43.6994],
              [47.431446, 43.691639],
              [47.397229, 43.685249],
              [47.384151, 43.660969],
              [47.367267, 43.646078],
              [47.3693, 43.632339],
              [47.353871, 43.64657],
              [47.331822, 43.607017],
              [47.341494, 43.569839],
              [47.370263, 43.555315],
              [47.392291, 43.526428],
              [47.394413, 43.510058],
              [47.405735, 43.490239],
              [47.43893, 43.477149],
              [47.453867, 43.478591],
              [47.472375, 43.511053],
              [47.481332, 43.53955],
              [47.472022, 43.578765],
              [47.483779, 43.599262],
              [47.515433, 43.694436],
              [47.534895, 43.736161],
              [47.543009, 43.765141],
              [47.540436, 43.782493],
              [47.548133, 43.80805],
              [47.555343, 43.85882],
              [47.579719, 43.881343],
              [47.637054, 43.898415],
              [47.690269, 43.917214],
              [47.723228, 43.923643],
              [47.742454, 43.916472],
              [47.74726, 43.901879],
              [47.691814, 43.862038],
              [47.674133, 43.843346],
              [47.641002, 43.786243],
              [47.599288, 43.730574],
              [47.581779, 43.701293],
              [47.573711, 43.674108],
              [47.551566, 43.620944],
              [47.561351, 43.593226],
              [47.542468, 43.572584],
              [47.521182, 43.527173],
              [47.496978, 43.447215],
              [47.486335, 43.401334],
              [47.496842, 43.375518],
              [47.483886, 43.373448],
              [47.4819, 43.321545],
              [47.487574, 43.298892],
              [47.49741, 43.284937],
              [47.529376, 43.296198],
              [47.546778, 43.322719],
              [47.535145, 43.342739],
              [47.550022, 43.330326],
              [47.558948, 43.29985],
              [47.55191, 43.255484],
              [47.518951, 43.221093],
              [47.48891, 43.171035],
              [47.477237, 43.130709],
              [47.463238, 43.057726],
              [47.461676, 43.02988],
              [47.475507, 43.000704],
              [47.507032, 42.994299],
              [47.517417, 42.981901],
              [47.574863, 42.953697],
              [47.599293, 42.930096],
              [47.61707, 42.921673],
              [47.648898, 42.887313],
              [47.663318, 42.886056],
              [47.697479, 42.869199],
              [47.711383, 42.832199],
              [47.717391, 42.731659],
              [47.721683, 42.699624],
              [47.734901, 42.668076],
              [47.769405, 42.624389],
              [47.792579, 42.607713],
              [47.817642, 42.607334],
              [47.866737, 42.59571],
              [47.888194, 42.58067],
              [47.900897, 42.560443],
              [47.90948, 42.526548],
              [47.952567, 42.462122],
              [48.011791, 42.391418],
              [48.044063, 42.3639],
              [48.085948, 42.339416],
              [48.111354, 42.318349],
              [48.151695, 42.260824],
              [48.208686, 42.187603],
              [48.226539, 42.154143],
              [48.241302, 42.135688],
              [48.264476, 42.123339],
              [48.286277, 42.105638],
              [48.28971, 42.093666],
              [48.318035, 42.041928],
              [48.333827, 42.02446],
              [48.335201, 42.008263],
              [48.347904, 41.97675],
              [48.36095, 41.956838],
              [48.377429, 41.942156],
              [48.41365, 41.921468],
              [48.463775, 41.909588],
              [48.486778, 41.908949],
              [48.533813, 41.886206],
              [48.563339, 41.860641],
              [48.58736, 41.845057],
              [48.548733, 41.778611],
              [48.534867, 41.760393],
              [48.503186, 41.737298],
              [48.482032, 41.711713],
              [48.434985, 41.681569],
              [48.423741, 41.667348],
              [48.416754, 41.638219],
              [48.404026, 41.617625],
              [48.387563, 41.602281],
              [48.333198, 41.577746],
              [48.287871, 41.554717],
              [48.277125, 41.542699],
              [48.252123, 41.527594],
              [48.203396, 41.511708],
              [48.135176, 41.506307],
              [48.091402, 41.49242],
              [48.073981, 41.502427],
              [48.044423, 41.476781],
              [48.060237, 41.466413],
              [48.039482, 41.444066],
              [48.007771, 41.433508],
              [47.999864, 41.377105],
              [47.978944, 41.354667],
              [47.959753, 41.355965],
              [47.958188, 41.338714],
              [47.972849, 41.323128],
              [47.917994, 41.304816],
              [47.906793, 41.290646],
              [47.90984, 41.25338],
              [47.89658, 41.228854],
              [47.872365, 41.214977],
              [47.850044, 41.21287],
              [47.818828, 41.21919],
              [47.776904, 41.18678],
              [47.737946, 41.187648],
              [47.727815, 41.202398],
              [47.700223, 41.224518],
              [47.666289, 41.223651],
              [47.644957, 41.231332],
              [47.616706, 41.230093],
              [47.606493, 41.216836],
              [47.575277, 41.212127],
              [47.546696, 41.201221],
              [47.511856, 41.226005],
              [47.504773, 41.235234],
              [47.503373, 41.261491],
              [47.490853, 41.265144],
              [47.474463, 41.2579],
              [47.453378, 41.270468],
              [47.405442, 41.267187],
              [47.360306, 41.280434],
              [47.343669, 41.290955],
              [47.307346, 41.303207],
              [47.294332, 41.323499],
              [47.269788, 41.335436],
              [47.273, 41.355965],
              [47.260893, 41.393296],
              [47.240796, 41.398362],
              [47.226052, 41.415907],
              [47.239972, 41.443572],
              [47.216086, 41.447277],
              [47.204555, 41.460055],
              [47.198378, 41.481162],
              [47.167244, 41.496401],
              [47.163291, 41.505346],
              [47.173422, 41.525636],
              [47.139158, 41.54518],
              [47.123427, 41.573468],
              [47.124827, 41.580554],
              [47.102259, 41.586345],
              [47.078868, 41.571743],
              [47.066431, 41.557508],
              [47.033238, 41.55874],
              [47.019565, 41.552762],
              [47.021624, 41.570018],
              [47.002598, 41.580985],
              [46.994774, 41.601991],
              [47.004905, 41.610736],
              [46.999716, 41.635856],
              [46.969653, 41.646443],
              [46.932259, 41.676533],
              [46.926576, 41.687852],
              [46.928471, 41.710298],
              [46.911174, 41.725791],
              [46.894454, 41.726037],
              [46.867192, 41.718168],
              [46.831353, 41.758486],
              [46.802627, 41.762262],
              [46.788924, 41.788692],
              [46.772304, 41.796201],
              [46.782403, 41.814968],
              [46.776487, 41.817777],
              [46.764132, 41.849453],
              [46.754639, 41.858573],
              [46.732984, 41.854392],
              [46.719419, 41.838383],
              [46.724977, 41.825713],
              [46.667331, 41.833053],
              [46.643145, 41.827845],
              [46.634807, 41.831782],
              [46.60033, 41.805841],
              [46.585857, 41.800775],
              [46.569732, 41.818044],
              [46.550416, 41.826369],
              [46.554654, 41.851605],
              [46.546711, 41.871551],
              [46.510918, 41.884607],
              [46.492664, 41.880268],
              [46.465413, 41.88589],
              [46.41213, 41.922038],
              [46.404596, 41.921787],
              [46.399361, 41.940573],
              [46.360495, 41.932431],
              [46.338853, 41.946056],
              [46.320047, 41.92927],
              [46.282439, 41.945439],
              [46.284083, 41.950784],
              [46.262001, 41.982559],
              [46.245231, 41.982111],
              [46.256504, 42.006129],
              [46.224219, 42.015665],
              [46.192578, 41.999335],
              [46.161577, 41.990417],
              [46.150739, 41.994943],
              [46.150327, 42.007897],
              [46.133048, 42.00774],
              [46.113771, 41.996168],
              [46.109218, 41.986403],
              [46.085935, 42.005384],
              [46.082966, 42.029893],
              [46.072682, 42.040617],
              [46.058457, 42.027383],
              [46.038718, 42.020105],
              [46.023431, 42.026266],
              [46.02228, 42.040847],
              [46.006904, 42.036968],
              [45.987334, 42.045341],
              [45.954341, 42.023821],
              [45.943824, 42.035783],
              [45.927909, 42.031495],
              [45.934246, 42.043835],
              [45.928882, 42.056011],
              [45.913961, 42.062212],
              [45.920603, 42.079698],
              [45.913586, 42.095129],
              [45.896377, 42.106486],
              [45.87978, 42.104033],
              [45.857015, 42.112466],
              [45.822892, 42.106133],
              [45.803924, 42.11605],
              [45.78603, 42.102069],
              [45.769484, 42.116897],
              [45.769147, 42.126909],
              [45.738947, 42.131543],
              [45.747982, 42.146101],
              [45.725601, 42.153752],
              [45.721881, 42.162647],
              [45.67168, 42.186383],
              [45.647516, 42.186408],
              [45.62144, 42.209855],
              [45.608612, 42.212313],
              [45.608972, 42.233808],
              [45.650574, 42.290635],
              [45.669949, 42.283016],
              [45.688205, 42.285638],
              [45.701961, 42.29673],
              [45.720184, 42.289223],
              [45.73816, 42.293772],
              [45.752271, 42.308012],
              [45.756161, 42.325801],
              [45.74321, 42.332369],
              [45.741829, 42.358367],
              [45.734701, 42.378317],
              [45.77459, 42.428526],
              [45.781574, 42.446946],
              [45.774363, 42.459961],
              [45.772822, 42.489153],
              [45.80835, 42.498779],
              [45.841594, 42.515836],
              [45.86039, 42.534566],
              [45.879855, 42.536104],
              [45.906076, 42.554633],
              [45.894971, 42.583262],
              [45.911226, 42.588613],
              [45.924804, 42.603263],
              [45.936978, 42.599383],
              [45.96136, 42.579049],
              [46.005374, 42.598648],
              [46.010925, 42.612344],
              [46.035407, 42.617963],
              [46.004771, 42.640973],
              [46.013165, 42.676742],
              [46.042128, 42.682495],
              [46.072731, 42.682194],
              [46.084436, 42.694235],
              [46.073131, 42.717445],
              [46.093867, 42.712563],
              [46.108984, 42.72069],
              [46.133533, 42.722095],
              [46.170155, 42.731878],
              [46.177647, 42.742848],
              [46.160657, 42.747664],
              [46.16477, 42.76738],
              [46.147579, 42.789253],
              [46.114769, 42.803082],
              [46.120655, 42.81539],
              [46.186273, 42.873501],
              [46.184333, 42.886812],
              [46.207711, 42.889889],
              [46.219484, 42.869689],
              [46.238816, 42.859789],
              [46.25497, 42.865943],
              [46.272494, 42.884606],
              [46.300488, 42.86489],
              [46.308849, 42.886045],
              [46.306909, 42.90959],
              [46.334936, 42.894072],
              [46.367747, 42.863403],
              [46.366744, 42.841864],
              [46.391426, 42.854105],
              [46.429152, 42.859725],
              [46.446209, 42.878588],
              [46.435239, 42.88628],
              [46.466309, 42.897485],
              [46.490389, 42.912268],
              [46.524838, 42.917134],
              [46.531259, 42.923288],
              [46.491927, 42.950445],
              [46.494435, 42.975512],
              [46.478715, 42.994241],
              [46.469618, 43.015362],
              [46.504802, 43.016767],
              [46.519986, 43.029744],
              [46.496908, 43.037302],
              [46.524032, 43.041767],
              [46.517276, 43.065446],
              [46.499884, 43.073406],
              [46.505235, 43.085379],
              [46.488178, 43.088222],
              [46.434699, 43.084893],
              [46.419113, 43.106432],
              [46.419648, 43.122619],
              [46.395969, 43.129157],
              [46.385467, 43.148154],
              [46.407273, 43.159726],
              [46.435968, 43.186131],
              [46.421185, 43.19088],
              [46.423927, 43.201048],
              [46.390215, 43.202435],
              [46.408475, 43.233205],
              [46.407505, 43.255797],
              [46.421217, 43.27379],
              [46.422286, 43.298322],
              [46.413791, 43.304008],
              [46.408573, 43.323941],
              [46.390278, 43.337503],
              [46.367483, 43.341315],
              [46.351941, 43.383744],
              [46.333018, 43.405239],
              [46.325255, 43.451031],
              [46.355026, 43.470087],
              [46.386722, 43.479869],
              [46.38127, 43.49494],
              [46.40079, 43.498612],
              [46.411179, 43.509929],
              [46.413585, 43.530146],
              [46.433226, 43.536246],
              [46.424921, 43.551785],
              [46.430784, 43.574892],
              [46.41331, 43.590019],
              [46.426526, 43.601097],
              [46.426559, 43.616077],
              [46.440974, 43.618552],
              [46.453846, 43.631252],
              [46.446434, 43.642157],
              [46.455128, 43.663141],
              [46.474696, 43.674092],
              [46.478307, 43.683427],
              [46.513616, 43.70473],
              [46.537429, 43.733092],
              [46.548666, 43.75443],
              [46.590271, 43.76821],
              [46.614151, 43.765869],
              [46.655132, 43.793539],
              [46.647426, 43.814857],
              [46.649831, 43.829716],
              [46.618966, 43.829147],
              [46.616156, 43.849883],
              [46.603514, 43.850184],
              [46.58572, 43.872391],
              [46.553345, 43.86811],
              [46.535553, 43.858745],
              [46.493679, 43.859413],
              [46.491538, 43.878811],
              [46.452608, 43.872891],
              [46.445518, 43.878376],
              [46.448326, 43.919313],
              [46.461035, 43.931487],
              [46.445115, 43.952089],
              [46.432004, 43.954898],
              [46.442305, 43.968276],
              [46.427789, 43.985734],
              [46.437555, 44.002992],
              [46.320363, 44.009813],
              [46.325313, 43.998442],
              [46.343106, 43.988944],
              [46.342571, 43.971017],
              [46.358893, 43.94386],
              [46.252002, 43.944929],
              [46.250131, 43.881952],
              [46.215883, 43.859209],
              [46.094878, 43.861081],
              [46.095147, 43.840211],
              [46.079093, 43.841114],
              [46.078291, 43.817167],
              [46.044043, 43.819843],
              [46.002636, 43.880979],
              [46.034743, 43.887936],
              [46.058021, 43.885395],
              [46.071198, 43.930546],
              [45.99628, 43.940043],
              [45.886111, 43.951179],
              [45.858819, 43.97138],
              [45.795809, 43.970576],
              [45.795809, 43.952382],
              [45.718551, 43.953318],
              [45.712798, 43.96295],
              [45.522159, 44.007663],
              [45.487471, 44.010095],
              [45.491051, 44.090725],
              [45.490175, 44.128568],
              [45.504341, 44.128966],
              [45.503624, 44.175802],
              [45.398412, 44.177014],
              [45.310073, 44.184255],
              [45.288784, 44.183778],
              [45.123923, 44.193187],
              [45.103629, 44.191674],
              [45.136672, 44.253797],
              [45.216877, 44.263939],
              [45.217672, 44.270863],
              [45.37151, 44.290144],
              [45.425904, 44.394064],
              [45.429086, 44.428803],
              [45.39773, 44.436283],
              [45.386667, 44.452081],
              [45.358414, 44.464655],
              [45.22674, 44.467518],
              [45.231117, 44.506794],
              [45.264861, 44.504327],
              [45.268123, 44.517956],
              [45.239472, 44.556773],
              [45.26072, 44.589344],
              [45.313644, 44.591911],
              [45.347507, 44.602258],
              [45.384315, 44.606814],
              [45.41233, 44.594161],
              [45.497763, 44.662426],
              [45.521916, 44.679398],
              [45.655536, 44.803692],
              [45.613474, 44.873985],
              [45.705116, 44.977363],
            ],
          ],
          [
            [
              [47.8125, 43.947923],
              [47.77507, 43.927105],
              [47.753784, 43.930814],
              [47.732841, 43.945895],
              [47.740394, 43.959489],
              [47.76065, 43.970609],
              [47.737304, 43.970609],
              [47.653877, 43.946142],
              [47.635337, 43.962207],
              [47.639457, 43.985185],
              [47.652847, 43.995559],
              [47.703658, 43.999017],
              [47.751724, 43.986914],
              [47.801505, 43.991361],
              [47.841331, 43.991855],
              [47.866737, 43.98395],
              [47.8125, 43.947923],
            ],
          ],
          [
            [
              [47.517937, 44.448668],
              [47.523544, 44.438291],
              [47.543947, 44.427466],
              [47.545816, 44.460939],
              [47.539586, 44.485586],
              [47.550177, 44.481125],
              [47.564506, 44.434832],
              [47.554383, 44.41943],
              [47.522765, 44.43327],
              [47.514043, 44.442532],
              [47.48009, 44.454023],
              [47.481647, 44.47042],
              [47.49473, 44.489934],
              [47.507657, 44.494839],
              [47.529774, 44.491941],
              [47.521052, 44.473543],
              [47.517937, 44.448668],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5539",
      properties: {
        name: "Еврейская автономная область",
        density: 0,
        path: "/world/Russia/Еврейская автономная область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [130.631293, 48.881824],
            [130.627705, 48.893534],
            [130.657789, 48.91905],
            [130.698337, 48.927208],
            [130.712334, 48.94039],
            [130.747513, 48.946705],
            [130.754242, 48.953089],
            [130.805197, 48.967953],
            [130.831775, 48.987826],
            [130.86608, 48.993643],
            [130.897397, 48.992001],
            [130.933909, 48.979791],
            [130.98817, 48.975773],
            [130.998949, 48.968817],
            [131.032218, 48.969009],
            [131.04529, 48.974788],
            [131.048417, 48.990374],
            [131.022827, 49.000475],
            [131.017562, 49.035795],
            [131.025405, 49.037265],
            [131.032511, 49.073915],
            [131.04704, 49.079133],
            [131.049271, 49.1048],
            [131.060019, 49.114844],
            [131.063567, 49.143522],
            [131.075801, 49.151931],
            [131.068732, 49.167351],
            [131.07885, 49.187937],
            [131.092862, 49.195122],
            [131.114774, 49.223464],
            [131.109396, 49.231596],
            [131.132601, 49.24081],
            [131.15839, 49.242413],
            [131.17148, 49.230029],
            [131.204721, 49.229696],
            [131.243962, 49.251493],
            [131.267252, 49.249907],
            [131.290033, 49.255051],
            [131.3013, 49.235369],
            [131.351491, 49.262742],
            [131.378577, 49.254987],
            [131.419462, 49.255472],
            [131.459003, 49.242346],
            [131.47328, 49.209787],
            [131.490045, 49.210796],
            [131.491973, 49.223846],
            [131.508001, 49.231771],
            [131.514038, 49.248659],
            [131.556983, 49.247656],
            [131.573254, 49.256399],
            [131.569326, 49.266544],
            [131.6011, 49.265535],
            [131.6177, 49.276764],
            [131.618328, 49.288651],
            [131.638842, 49.292885],
            [131.665912, 49.312318],
            [131.701932, 49.303566],
            [131.735133, 49.313139],
            [131.742878, 49.308373],
            [131.776878, 49.315773],
            [131.803901, 49.337971],
            [131.833711, 49.322664],
            [131.852134, 49.30275],
            [131.872833, 49.289383],
            [131.88416, 49.272973],
            [131.902858, 49.278574],
            [131.918911, 49.271623],
            [131.93838, 49.276366],
            [131.986279, 49.256153],
            [132.00432, 49.268777],
            [132.001485, 49.284979],
            [131.979242, 49.290257],
            [131.974828, 49.309116],
            [131.999851, 49.326977],
            [132.022414, 49.329382],
            [132.022254, 49.33867],
            [131.99841, 49.358336],
            [131.993382, 49.377629],
            [132.009733, 49.381191],
            [132.023787, 49.374039],
            [132.093754, 49.389564],
            [132.062771, 49.411754],
            [132.062335, 49.428191],
            [132.074193, 49.441266],
            [132.06406, 49.457305],
            [132.082172, 49.478248],
            [132.111332, 49.494486],
            [132.167162, 49.488923],
            [132.19089, 49.474523],
            [132.222839, 49.474291],
            [132.241219, 49.489845],
            [132.25957, 49.481473],
            [132.273653, 49.463876],
            [132.270948, 49.442468],
            [132.304147, 49.421765],
            [132.353117, 49.410772],
            [132.384763, 49.41336],
            [132.404875, 49.379738],
            [132.400927, 49.347657],
            [132.375081, 49.342703],
            [132.366774, 49.334435],
            [132.371428, 49.307934],
            [132.385134, 49.29676],
            [132.3995, 49.301873],
            [132.44621, 49.282574],
            [132.46673, 49.283328],
            [132.48581, 49.264958],
            [132.50725, 49.266773],
            [132.508815, 49.27601],
            [132.529497, 49.281569],
            [132.529707, 49.293406],
            [132.59698, 49.310076],
            [132.632373, 49.286292],
            [132.65773, 49.282217],
            [132.674014, 49.291997],
            [132.672947, 49.300899],
            [132.694596, 49.306814],
            [132.713422, 49.300556],
            [132.705897, 49.319129],
            [132.732994, 49.330695],
            [132.75379, 49.32633],
            [132.771334, 49.33546],
            [132.783534, 49.329466],
            [132.780452, 49.286892],
            [132.802498, 49.271883],
            [132.809333, 49.23666],
            [132.833625, 49.242819],
            [132.869524, 49.24037],
            [132.898506, 49.256298],
            [132.91115, 49.254084],
            [132.916965, 49.233353],
            [132.956274, 49.223678],
            [132.983739, 49.229577],
            [133.003141, 49.221952],
            [133.053719, 49.214828],
            [133.05855, 49.198409],
            [133.079814, 49.172064],
            [133.101844, 49.173901],
            [133.152341, 49.155724],
            [133.164188, 49.143333],
            [133.156535, 49.120216],
            [133.218857, 49.088427],
            [133.26, 49.056674],
            [133.272472, 49.034188],
            [133.275213, 48.986787],
            [133.250817, 48.978582],
            [133.224739, 48.958946],
            [133.253603, 48.911513],
            [133.27972, 48.878996],
            [133.281351, 48.848458],
            [133.404043, 48.833351],
            [133.463447, 48.829097],
            [133.482393, 48.820146],
            [133.50623, 48.783504],
            [133.528844, 48.784348],
            [133.522162, 48.765858],
            [133.556654, 48.759103],
            [133.565438, 48.742921],
            [133.577718, 48.7455],
            [133.589277, 48.729502],
            [133.605928, 48.736556],
            [133.621995, 48.733932],
            [133.625282, 48.743646],
            [133.644678, 48.739797],
            [133.658952, 48.746097],
            [133.680013, 48.740046],
            [133.703995, 48.740502],
            [133.683841, 48.723314],
            [133.703452, 48.719495],
            [133.717763, 48.73494],
            [133.70826, 48.753292],
            [133.726693, 48.757533],
            [133.738935, 48.734169],
            [133.755086, 48.734887],
            [133.759577, 48.748359],
            [133.820529, 48.698068],
            [133.833011, 48.680125],
            [133.85713, 48.679158],
            [133.853577, 48.665552],
            [133.87184, 48.659732],
            [133.873747, 48.670191],
            [133.902691, 48.66129],
            [133.902577, 48.652437],
            [133.934109, 48.639607],
            [133.94099, 48.654206],
            [134.003048, 48.640963],
            [134.020498, 48.649311],
            [134.046514, 48.63543],
            [134.072179, 48.648589],
            [134.090931, 48.637638],
            [134.100942, 48.650471],
            [134.123085, 48.644663],
            [134.1393, 48.647749],
            [134.128004, 48.655934],
            [134.15435, 48.662256],
            [134.171668, 48.656715],
            [134.196799, 48.66338],
            [134.22614, 48.659429],
            [134.21574, 48.674429],
            [134.24324, 48.683001],
            [134.265952, 48.666304],
            [134.273305, 48.676445],
            [134.25495, 48.691814],
            [134.26195, 48.699412],
            [134.25613, 48.718611],
            [134.282982, 48.723274],
            [134.289842, 48.740582],
            [134.302859, 48.736826],
            [134.309419, 48.722234],
            [134.347005, 48.730434],
            [134.36483, 48.728769],
            [134.371803, 48.736779],
            [134.399664, 48.746428],
            [134.416051, 48.741429],
            [134.408964, 48.721579],
            [134.419568, 48.715602],
            [134.447548, 48.728866],
            [134.458388, 48.717634],
            [134.448697, 48.707913],
            [134.463124, 48.694962],
            [134.465815, 48.67584],
            [134.492386, 48.674632],
            [134.503197, 48.666612],
            [134.49272, 48.65461],
            [134.50332, 48.648804],
            [134.514478, 48.661437],
            [134.53961, 48.648855],
            [134.543621, 48.63711],
            [134.568489, 48.633703],
            [134.579396, 48.621861],
            [134.557148, 48.613116],
            [134.55478, 48.599774],
            [134.61214, 48.612643],
            [134.651434, 48.610716],
            [134.658138, 48.624854],
            [134.67592, 48.620047],
            [134.68265, 48.59929],
            [134.73236, 48.570618],
            [134.772053, 48.575596],
            [134.778207, 48.590172],
            [134.801276, 48.591748],
            [134.85976, 48.614619],
            [134.865839, 48.635209],
            [134.906315, 48.627179],
            [134.924001, 48.617156],
            [134.950617, 48.626557],
            [134.96632, 48.604424],
            [134.976974, 48.602619],
            [134.979706, 48.563532],
            [134.995785, 48.532681],
            [134.966287, 48.513675],
            [134.953331, 48.51935],
            [134.936202, 48.513926],
            [134.901014, 48.461912],
            [134.896898, 48.443262],
            [134.844333, 48.392645],
            [134.797868, 48.3772],
            [134.765455, 48.375203],
            [134.726888, 48.381352],
            [134.687222, 48.404728],
            [134.653435, 48.412015],
            [134.620539, 48.412696],
            [134.58079, 48.406018],
            [134.521006, 48.414604],
            [134.493022, 48.423826],
            [134.470763, 48.420646],
            [134.43451, 48.396796],
            [134.408753, 48.386938],
            [134.369321, 48.385984],
            [134.336885, 48.377716],
            [134.267244, 48.37867],
            [134.222406, 48.384712],
            [134.18838, 48.382486],
            [134.16167, 48.361498],
            [134.14004, 48.337012],
            [134.08885, 48.337012],
            [134.033834, 48.342736],
            [134.01444, 48.330016],
            [134.00748, 48.307595],
            [133.972443, 48.298595],
            [133.949621, 48.303095],
            [133.877618, 48.28188],
            [133.833902, 48.274487],
            [133.797901, 48.258736],
            [133.74647, 48.255522],
            [133.737148, 48.23945],
            [133.738434, 48.224342],
            [133.727826, 48.216627],
            [133.710147, 48.186412],
            [133.677682, 48.180947],
            [133.613715, 48.198627],
            [133.593464, 48.197019],
            [133.578678, 48.187376],
            [133.567418, 48.13537],
            [133.52213, 48.120167],
            [133.4662, 48.110327],
            [133.442722, 48.110327],
            [133.418722, 48.121805],
            [133.391592, 48.122327],
            [133.348287, 48.113979],
            [133.286765, 48.09153],
            [133.266086, 48.09153],
            [133.239793, 48.100393],
            [133.18189, 48.107483],
            [133.097989, 48.103051],
            [133.066379, 48.107778],
            [133.049539, 48.103642],
            [133.030041, 48.080895],
            [133.007884, 48.03924],
            [132.984546, 48.029491],
            [132.913939, 48.012651],
            [132.863126, 47.990495],
            [132.815858, 47.933773],
            [132.791043, 47.927569],
            [132.763568, 47.929046],
            [132.719254, 47.960657],
            [132.682917, 47.95977],
            [132.657215, 47.946181],
            [132.652193, 47.934364],
            [132.68233, 47.884142],
            [132.680554, 47.870847],
            [132.628854, 47.835397],
            [132.598721, 47.809104],
            [132.590154, 47.770403],
            [132.601971, 47.756518],
            [132.603743, 47.740861],
            [132.589858, 47.728453],
            [132.54879, 47.713091],
            [132.523327, 47.717965],
            [132.484893, 47.714562],
            [132.46067, 47.728975],
            [132.428041, 47.728975],
            [132.384488, 47.749425],
            [132.343682, 47.756566],
            [132.309253, 47.755546],
            [132.282984, 47.740754],
            [132.277628, 47.718821],
            [132.25289, 47.706834],
            [132.206728, 47.713465],
            [132.155466, 47.701733],
            [132.133964, 47.700141],
            [132.069334, 47.721526],
            [132.040986, 47.739293],
            [132.018428, 47.746281],
            [131.929991, 47.734103],
            [131.91023, 47.720528],
            [131.902043, 47.703959],
            [131.903357, 47.687237],
            [131.866999, 47.67218],
            [131.812279, 47.665937],
            [131.787338, 47.671728],
            [131.73112, 47.700458],
            [131.709448, 47.706334],
            [131.677865, 47.701193],
            [131.645179, 47.667038],
            [131.622042, 47.655653],
            [131.5945, 47.655286],
            [131.565486, 47.663366],
            [131.548592, 47.680627],
            [131.55263, 47.701927],
            [131.544552, 47.722861],
            [131.517434, 47.731711],
            [131.461803, 47.738808],
            [131.431562, 47.739466],
            [131.366477, 47.72303],
            [131.254715, 47.733549],
            [131.207381, 47.719086],
            [131.17517, 47.694104],
            [131.0897, 47.680298],
            [131.02659, 47.685557],
            [130.99109, 47.693446],
            [130.968737, 47.704622],
            [130.957561, 47.719086],
            [130.95164, 47.749327],
            [130.95164, 47.813754],
            [130.891161, 47.901849],
            [130.862083, 47.93588],
            [130.743241, 47.999805],
            [130.729389, 48.028492],
            [130.68836, 48.041922],
            [130.676517, 48.052824],
            [130.674457, 48.082649],
            [130.654489, 48.094736],
            [130.654489, 48.110514],
            [130.667638, 48.1305],
            [130.694461, 48.145226],
            [130.723913, 48.170471],
            [130.74863, 48.179412],
            [130.749684, 48.213072],
            [130.764936, 48.246206],
            [130.797018, 48.257251],
            [130.835304, 48.304761],
            [130.817966, 48.319945],
            [130.810928, 48.342428],
            [130.769557, 48.35338],
            [130.756521, 48.370853],
            [130.748443, 48.405486],
            [130.727069, 48.441329],
            [130.758625, 48.471307],
            [130.764922, 48.485535],
            [130.753421, 48.503395],
            [130.732822, 48.512721],
            [130.713394, 48.510752],
            [130.664501, 48.49111],
            [130.637893, 48.484852],
            [130.609259, 48.498656],
            [130.605577, 48.517064],
            [130.60837, 48.577726],
            [130.583537, 48.59471],
            [130.538867, 48.601535],
            [130.521081, 48.615598],
            [130.52315, 48.629454],
            [130.562771, 48.682286],
            [130.620621, 48.793796],
            [130.642078, 48.816294],
            [130.681732, 48.848159],
            [130.684307, 48.862277],
            [130.660761, 48.879912],
            [130.631293, 48.881824],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5522",
      properties: {
        name: "Забайкальский край",
        density: 0,
        path: "/world/Russia/Забайкальский край",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [115.729827, 56.962428],
            [115.756437, 56.961793],
            [115.794605, 56.951095],
            [115.828917, 56.946525],
            [115.856481, 56.929358],
            [115.903847, 56.914662],
            [115.922156, 56.895575],
            [115.950349, 56.89009],
            [115.978505, 56.87081],
            [116.000364, 56.870438],
            [116.017216, 56.879636],
            [116.028478, 56.861091],
            [116.06274, 56.850394],
            [116.080663, 56.826272],
            [116.098858, 56.829699],
            [116.115518, 56.819059],
            [116.162292, 56.830435],
            [116.184629, 56.840146],
            [116.194512, 56.832977],
            [116.230002, 56.829235],
            [116.26415, 56.844702],
            [116.270478, 56.858034],
            [116.291415, 56.854892],
            [116.316016, 56.87033],
            [116.350471, 56.86431],
            [116.370665, 56.835868],
            [116.399264, 56.831819],
            [116.419602, 56.815081],
            [116.466918, 56.813295],
            [116.496952, 56.806183],
            [116.516433, 56.793758],
            [116.520988, 56.773756],
            [116.569954, 56.773349],
            [116.577216, 56.765551],
            [116.615899, 56.76895],
            [116.626782, 56.760495],
            [116.663493, 56.75901],
            [116.669099, 56.772684],
            [116.697206, 56.779882],
            [116.723249, 56.755532],
            [116.736959, 56.753361],
            [116.742915, 56.738758],
            [116.771279, 56.744442],
            [116.775685, 56.752282],
            [116.799821, 56.74994],
            [116.808769, 56.757131],
            [116.888734, 56.783016],
            [116.948732, 56.791906],
            [116.970648, 56.801782],
            [117.018756, 56.789849],
            [117.025412, 56.807916],
            [116.999847, 56.809258],
            [116.994449, 56.819634],
            [117.011159, 56.826061],
            [117.09393, 56.830116],
            [117.109925, 56.818977],
            [117.120701, 56.833394],
            [117.161698, 56.840513],
            [117.152186, 56.858722],
            [117.158235, 56.878588],
            [117.17628, 56.892906],
            [117.229402, 56.914035],
            [117.277568, 56.926881],
            [117.27569, 56.939171],
            [117.299941, 56.940799],
            [117.320665, 56.965178],
            [117.337553, 56.959722],
            [117.332326, 56.948811],
            [117.350371, 56.936971],
            [117.348163, 56.891305],
            [117.378298, 56.884364],
            [117.402941, 56.845418],
            [117.416195, 56.851673],
            [117.431119, 56.83552],
            [117.451228, 56.836292],
            [117.464032, 56.84636],
            [117.461033, 56.876188],
            [117.487155, 56.891133],
            [117.527351, 56.882685],
            [117.531786, 56.871503],
            [117.560692, 56.865304],
            [117.569983, 56.870267],
            [117.576667, 56.915954],
            [117.560857, 56.924566],
            [117.547597, 56.954465],
            [117.578675, 56.957064],
            [117.629226, 56.972867],
            [117.653341, 56.996939],
            [117.653077, 57.018997],
            [117.677128, 57.027251],
            [117.680006, 57.043754],
            [117.657526, 57.051409],
            [117.675637, 57.08947],
            [117.658077, 57.105166],
            [117.642338, 57.100396],
            [117.614032, 57.118276],
            [117.620266, 57.137907],
            [117.596444, 57.146247],
            [117.576207, 57.165135],
            [117.606221, 57.181252],
            [117.626737, 57.185179],
            [117.636306, 57.196404],
            [117.702103, 57.19996],
            [117.720991, 57.209258],
            [117.754789, 57.237599],
            [117.752533, 57.253545],
            [117.772521, 57.264121],
            [117.761424, 57.285379],
            [117.779848, 57.292063],
            [117.779141, 57.318355],
            [117.768173, 57.325154],
            [117.73646, 57.324668],
            [117.724406, 57.315756],
            [117.675405, 57.317185],
            [117.624161, 57.324861],
            [117.587371, 57.354518],
            [117.566562, 57.345692],
            [117.576416, 57.302175],
            [117.552351, 57.292342],
            [117.540218, 57.271448],
            [117.48464, 57.269063],
            [117.480784, 57.257667],
            [117.449871, 57.259616],
            [117.43706, 57.282681],
            [117.417365, 57.291465],
            [117.432704, 57.30284],
            [117.451929, 57.348377],
            [117.444402, 57.36735],
            [117.426807, 57.367179],
            [117.390566, 57.344757],
            [117.372771, 57.343186],
            [117.344629, 57.364109],
            [117.336431, 57.389802],
            [117.351256, 57.397057],
            [117.33444, 57.43089],
            [117.350892, 57.436574],
            [117.333697, 57.454405],
            [117.33896, 57.467123],
            [117.32575, 57.478756],
            [117.2979, 57.472415],
            [117.280126, 57.48304],
            [117.288239, 57.494451],
            [117.270615, 57.507226],
            [117.272943, 57.525571],
            [117.254805, 57.537425],
            [117.283491, 57.560697],
            [117.342754, 57.560347],
            [117.332821, 57.586718],
            [117.311298, 57.59633],
            [117.335114, 57.634848],
            [117.290376, 57.638418],
            [117.274944, 57.631592],
            [117.24341, 57.635991],
            [117.213525, 57.653514],
            [117.175807, 57.662241],
            [117.156412, 57.648702],
            [117.139095, 57.648416],
            [117.140152, 57.665354],
            [117.157312, 57.682028],
            [117.143801, 57.692597],
            [117.176957, 57.707657],
            [117.159127, 57.74029],
            [117.181378, 57.74686],
            [117.181607, 57.769668],
            [117.157156, 57.772467],
            [117.153143, 57.794818],
            [117.132092, 57.789734],
            [117.099101, 57.79806],
            [117.094945, 57.815662],
            [117.076521, 57.826745],
            [117.095745, 57.835807],
            [117.119853, 57.860793],
            [117.170504, 57.85408],
            [117.225682, 57.85785],
            [117.262487, 57.863955],
            [117.277283, 57.878008],
            [117.313738, 57.862241],
            [117.346079, 57.864469],
            [117.367538, 57.890262],
            [117.353057, 57.902287],
            [117.409278, 57.911606],
            [117.403765, 57.915719],
            [117.431708, 57.929893],
            [117.426309, 57.944075],
            [117.382514, 57.94124],
            [117.383457, 57.96659],
            [117.39314, 57.978851],
            [117.37973, 58.018834],
            [117.400525, 58.017548],
            [117.405703, 58.045956],
            [117.396284, 58.079562],
            [117.405996, 58.091987],
            [117.432976, 58.105505],
            [117.404419, 58.123508],
            [117.423843, 58.128221],
            [117.411274, 58.14371],
            [117.422322, 58.159685],
            [117.450837, 58.175202],
            [117.467133, 58.163676],
            [117.506653, 58.152829],
            [117.562568, 58.149494],
            [117.576972, 58.14498],
            [117.589084, 58.154578],
            [117.594369, 58.184642],
            [117.54978, 58.232395],
            [117.561777, 58.250169],
            [117.527678, 58.25781],
            [117.539447, 58.270436],
            [117.528778, 58.286896],
            [117.486067, 58.28761],
            [117.464422, 58.311983],
            [117.472249, 58.326015],
            [117.513661, 58.344453],
            [117.531693, 58.345146],
            [117.566121, 58.361313],
            [117.586431, 58.361484],
            [117.62298, 58.373196],
            [117.620009, 58.393912],
            [117.643261, 58.391084],
            [117.672319, 58.406302],
            [117.669877, 58.425561],
            [117.723272, 58.413421],
            [117.795369, 58.41522],
            [117.805282, 58.427703],
            [117.830854, 58.436308],
            [117.856227, 58.426581],
            [117.863125, 58.412028],
            [117.907329, 58.413456],
            [117.92479, 58.424524],
            [117.938344, 58.414113],
            [118.001543, 58.423339],
            [118.043284, 58.409285],
            [118.05241, 58.380356],
            [118.072476, 58.367609],
            [118.088929, 58.366809],
            [118.120773, 58.400229],
            [118.197741, 58.395937],
            [118.254371, 58.397679],
            [118.27498, 58.388596],
            [118.307108, 58.355361],
            [118.33413, 58.354104],
            [118.348169, 58.320805],
            [118.402656, 58.280486],
            [118.448681, 58.277886],
            [118.476253, 58.257591],
            [118.474681, 58.229584],
            [118.515479, 58.23484],
            [118.57685, 58.232497],
            [118.585969, 58.214944],
            [118.602708, 58.215258],
            [118.680026, 58.178824],
            [118.697708, 58.188878],
            [118.714539, 58.186565],
            [118.76445, 58.206659],
            [118.787523, 58.225448],
            [118.807119, 58.230418],
            [118.860349, 58.22494],
            [118.885579, 58.231353],
            [118.944694, 58.228867],
            [118.985721, 58.233837],
            [119.008901, 58.246963],
            [119.045236, 58.254811],
            [119.079978, 58.248219],
            [119.088233, 58.237593],
            [119.116842, 58.238128],
            [119.128751, 58.23007],
            [119.133544, 58.206694],
            [119.15313, 58.191746],
            [119.162911, 58.169353],
            [119.187902, 58.16239],
            [119.191361, 58.152822],
            [119.152198, 58.139107],
            [119.159515, 58.109624],
            [119.14373, 58.099193],
            [119.148284, 58.085329],
            [119.184517, 58.044775],
            [119.163985, 58.031456],
            [119.152364, 58.005861],
            [119.137163, 57.988796],
            [119.135, 57.973142],
            [119.110081, 57.969755],
            [119.120055, 57.962517],
            [119.116039, 57.924726],
            [119.091565, 57.913008],
            [119.087889, 57.859283],
            [119.103373, 57.832283],
            [119.08378, 57.815193],
            [119.062909, 57.781243],
            [119.04583, 57.764522],
            [119.043871, 57.747557],
            [119.058217, 57.726167],
            [119.09677, 57.732012],
            [119.166955, 57.718408],
            [119.174363, 57.686365],
            [119.168664, 57.658518],
            [119.141664, 57.649476],
            [119.168982, 57.62566],
            [119.164033, 57.612644],
            [119.179843, 57.589429],
            [119.164821, 57.570613],
            [119.142199, 57.557559],
            [119.174523, 57.519333],
            [119.222136, 57.505674],
            [119.223145, 57.524324],
            [119.246429, 57.541832],
            [119.27314, 57.579503],
            [119.270363, 57.595776],
            [119.33814, 57.619588],
            [119.420717, 57.620952],
            [119.438155, 57.603646],
            [119.480369, 57.608866],
            [119.502115, 57.599544],
            [119.486432, 57.549482],
            [119.467154, 57.533297],
            [119.445125, 57.5323],
            [119.420623, 57.51872],
            [119.392819, 57.485241],
            [119.41859, 57.463229],
            [119.400462, 57.432688],
            [119.421799, 57.418431],
            [119.419936, 57.395929],
            [119.44365, 57.380061],
            [119.477319, 57.370509],
            [119.509504, 57.370754],
            [119.538448, 57.351368],
            [119.5643, 57.318446],
            [119.586803, 57.304075],
            [119.579385, 57.285978],
            [119.60138, 57.275657],
            [119.590954, 57.250667],
            [119.602753, 57.236038],
            [119.626156, 57.22598],
            [119.661951, 57.19039],
            [119.691375, 57.177932],
            [119.659859, 57.168914],
            [119.638123, 57.151064],
            [119.60854, 57.136346],
            [119.592954, 57.09797],
            [119.576359, 57.091773],
            [119.572568, 57.076454],
            [119.592735, 57.057313],
            [119.597018, 57.028036],
            [119.621578, 57.017655],
            [119.645616, 56.997165],
            [119.69011, 56.985813],
            [119.709825, 56.990381],
            [119.767692, 56.989411],
            [119.791058, 56.980033],
            [119.804702, 56.948635],
            [119.805443, 56.932447],
            [119.834795, 56.902237],
            [119.85614, 56.896079],
            [119.832444, 56.890344],
            [119.837968, 56.877258],
            [119.812775, 56.854107],
            [119.768748, 56.841299],
            [119.706492, 56.829407],
            [119.696485, 56.800315],
            [119.655777, 56.776279],
            [119.655391, 56.757026],
            [119.68163, 56.747071],
            [119.677939, 56.739303],
            [119.725466, 56.729354],
            [119.762595, 56.738643],
            [119.776596, 56.733397],
            [119.772849, 56.711899],
            [119.780835, 56.695609],
            [119.865197, 56.698037],
            [119.88538, 56.67795],
            [119.860553, 56.660511],
            [119.829434, 56.654386],
            [119.807481, 56.634966],
            [119.796982, 56.596617],
            [119.836613, 56.577526],
            [119.833165, 56.56601],
            [119.923613, 56.54911],
            [119.914051, 56.525552],
            [119.938404, 56.512195],
            [119.949473, 56.492727],
            [120.026334, 56.489236],
            [120.04819, 56.497739],
            [120.089185, 56.500828],
            [120.119705, 56.481037],
            [120.1371, 56.487377],
            [120.159418, 56.472718],
            [120.223919, 56.461782],
            [120.230034, 56.480651],
            [120.254354, 56.47728],
            [120.279717, 56.458654],
            [120.254406, 56.447785],
            [120.262475, 56.430447],
            [120.240954, 56.428514],
            [120.235379, 56.403327],
            [120.258686, 56.402479],
            [120.281693, 56.393189],
            [120.323642, 56.366441],
            [120.342302, 56.371529],
            [120.373551, 56.369849],
            [120.393342, 56.359852],
            [120.365324, 56.342182],
            [120.365069, 56.332091],
            [120.397519, 56.314196],
            [120.409628, 56.314398],
            [120.466301, 56.295101],
            [120.500674, 56.292052],
            [120.516146, 56.29982],
            [120.535543, 56.287492],
            [120.539248, 56.266273],
            [120.506501, 56.263459],
            [120.508655, 56.248471],
            [120.478799, 56.237301],
            [120.467277, 56.242145],
            [120.425607, 56.228796],
            [120.378755, 56.23746],
            [120.327207, 56.225478],
            [120.299372, 56.22331],
            [120.303761, 56.202379],
            [120.26018, 56.203002],
            [120.234672, 56.214257],
            [120.225578, 56.2051],
            [120.173616, 56.206326],
            [120.152404, 56.17797],
            [120.115634, 56.161004],
            [120.070227, 56.163509],
            [120.039768, 56.157008],
            [120.041536, 56.138948],
            [120.027939, 56.120432],
            [120.032566, 56.108902],
            [120.082593, 56.071806],
            [120.092212, 56.056521],
            [120.12743, 56.024235],
            [120.11747, 56.003437],
            [120.134792, 55.992258],
            [120.12401, 55.957488],
            [120.083189, 55.935283],
            [120.095665, 55.917917],
            [120.118331, 55.915027],
            [120.127948, 55.897785],
            [120.113819, 55.897977],
            [120.127393, 55.879918],
            [120.120565, 55.865797],
            [120.159902, 55.875223],
            [120.163201, 55.883196],
            [120.201619, 55.900194],
            [120.227646, 55.922583],
            [120.224446, 55.931387],
            [120.250885, 55.93599],
            [120.306369, 55.931697],
            [120.349865, 55.918489],
            [120.35556, 55.903652],
            [120.340293, 55.882653],
            [120.404212, 55.873615],
            [120.419341, 55.880425],
            [120.477545, 55.886741],
            [120.467959, 55.914665],
            [120.477635, 55.930509],
            [120.512161, 55.933902],
            [120.561842, 55.928013],
            [120.568958, 55.938572],
            [120.591404, 55.940655],
            [120.601328, 55.933406],
            [120.633166, 55.930567],
            [120.63825, 55.952007],
            [120.657889, 55.966889],
            [120.690841, 55.964359],
            [120.778611, 55.977042],
            [120.785378, 55.987659],
            [120.855829, 56.021638],
            [120.877713, 56.018513],
            [120.88448, 56.029839],
            [120.914073, 56.032531],
            [120.948967, 56.016367],
            [120.964935, 56.03019],
            [120.993658, 56.041873],
            [121.003895, 56.027817],
            [121.045408, 56.009329],
            [121.063653, 56.016223],
            [121.119983, 56.012626],
            [121.157265, 56.032433],
            [121.193811, 56.041188],
            [121.21542, 56.036643],
            [121.212657, 56.027873],
            [121.236787, 55.990219],
            [121.249934, 55.98816],
            [121.25802, 55.943022],
            [121.269105, 55.940675],
            [121.246779, 55.88011],
            [121.286201, 55.860417],
            [121.289732, 55.84066],
            [121.3371, 55.821683],
            [121.343985, 55.793416],
            [121.315196, 55.779498],
            [121.298557, 55.734866],
            [121.313119, 55.731141],
            [121.325972, 55.672025],
            [121.317121, 55.65923],
            [121.332857, 55.634995],
            [121.36111, 55.607218],
            [121.345853, 55.575751],
            [121.347945, 55.559552],
            [121.336304, 55.530157],
            [121.342017, 55.522041],
            [121.378048, 55.524445],
            [121.419257, 55.494462],
            [121.447807, 55.498544],
            [121.474527, 55.491083],
            [121.470029, 55.511478],
            [121.484099, 55.522684],
            [121.514825, 55.514413],
            [121.548644, 55.5203],
            [121.562323, 55.511638],
            [121.581252, 55.527413],
            [121.607495, 55.525772],
            [121.620438, 55.540398],
            [121.644725, 55.553224],
            [121.644509, 55.563824],
            [121.668459, 55.584216],
            [121.662841, 55.592643],
            [121.696744, 55.614257],
            [121.748276, 55.614351],
            [121.75497, 55.627064],
            [121.780001, 55.632219],
            [121.790061, 55.618138],
            [121.812593, 55.623503],
            [121.848865, 55.610644],
            [121.863159, 55.618231],
            [121.899496, 55.619519],
            [121.906474, 55.600492],
            [121.903089, 55.581821],
            [121.913328, 55.563734],
            [121.936156, 55.551507],
            [121.946245, 55.538243],
            [121.946427, 55.515501],
            [121.991114, 55.507256],
            [121.993862, 55.490895],
            [121.988787, 55.454142],
            [121.981481, 55.446881],
            [121.999728, 55.433874],
            [121.984841, 55.420437],
            [121.979945, 55.401532],
            [121.968201, 55.39713],
            [121.960947, 55.374333],
            [121.986133, 55.337833],
            [121.985257, 55.312551],
            [121.96983, 55.296873],
            [121.954981, 55.295752],
            [121.940285, 55.279089],
            [121.953389, 55.269629],
            [121.957454, 55.247085],
            [121.943818, 55.232939],
            [121.958106, 55.218073],
            [121.991717, 55.203843],
            [122.018066, 55.20895],
            [122.03258, 55.196149],
            [121.996327, 55.172585],
            [121.980011, 55.176826],
            [121.964915, 55.163337],
            [121.970804, 55.156371],
            [121.922884, 55.133621],
            [121.913876, 55.119523],
            [121.95025, 55.091264],
            [121.950968, 55.07695],
            [121.938924, 55.052503],
            [121.949548, 55.042845],
            [121.947071, 55.027828],
            [121.959649, 55.000532],
            [121.908112, 54.969661],
            [121.852999, 54.963903],
            [121.846259, 54.938045],
            [121.832993, 54.933456],
            [121.786027, 54.902705],
            [121.791475, 54.89642],
            [121.755664, 54.879976],
            [121.74898, 54.871431],
            [121.702186, 54.848023],
            [121.667207, 54.834498],
            [121.67122, 54.819287],
            [121.652327, 54.773358],
            [121.640328, 54.764774],
            [121.668327, 54.741619],
            [121.664314, 54.729098],
            [121.682467, 54.727801],
            [121.710866, 54.735923],
            [121.723734, 54.751906],
            [121.723541, 54.765244],
            [121.794049, 54.772232],
            [121.810872, 54.786443],
            [121.879915, 54.803022],
            [121.895753, 54.812144],
            [121.912039, 54.802854],
            [121.90229, 54.791286],
            [121.903501, 54.752016],
            [121.939016, 54.740334],
            [121.937792, 54.713422],
            [121.915204, 54.681429],
            [121.892318, 54.673073],
            [121.889118, 54.654598],
            [121.927587, 54.651011],
            [121.93917, 54.629767],
            [121.929866, 54.569081],
            [121.949905, 54.571296],
            [122.003935, 54.560795],
            [122.023807, 54.544197],
            [122.030425, 54.522315],
            [122.12279, 54.480451],
            [122.131326, 54.483302],
            [122.14582, 54.458755],
            [122.133362, 54.440893],
            [122.128962, 54.413104],
            [122.107077, 54.40701],
            [122.088899, 54.415101],
            [122.036786, 54.405446],
            [122.025062, 54.407186],
            [121.985463, 54.39032],
            [121.955482, 54.386974],
            [121.940807, 54.37859],
            [121.912289, 54.377137],
            [121.881064, 54.388049],
            [121.890032, 54.417087],
            [121.830249, 54.432829],
            [121.814429, 54.447627],
            [121.802959, 54.439514],
            [121.759862, 54.439438],
            [121.743598, 54.43389],
            [121.73156, 54.417164],
            [121.702016, 54.413143],
            [121.664952, 54.377032],
            [121.655447, 54.358832],
            [121.669777, 54.347743],
            [121.711361, 54.339042],
            [121.714677, 54.325518],
            [121.703795, 54.317612],
            [121.730633, 54.283649],
            [121.748118, 54.279152],
            [121.731153, 54.261609],
            [121.715283, 54.216169],
            [121.734528, 54.212199],
            [121.738257, 54.186282],
            [121.779499, 54.175246],
            [121.756446, 54.165329],
            [121.753657, 54.153417],
            [121.724243, 54.139997],
            [121.672511, 54.138747],
            [121.654548, 54.134028],
            [121.646564, 54.114342],
            [121.626528, 54.096369],
            [121.645685, 54.070337],
            [121.642505, 54.055463],
            [121.667441, 54.046938],
            [121.653701, 54.026285],
            [121.674279, 54.008821],
            [121.711543, 53.997339],
            [121.72063, 53.979485],
            [121.742516, 53.971301],
            [121.774529, 53.935273],
            [121.786209, 53.938616],
            [121.789058, 53.955524],
            [121.815522, 53.965355],
            [121.848812, 53.952169],
            [121.844121, 53.93611],
            [121.863906, 53.922393],
            [121.860513, 53.907116],
            [121.878671, 53.896021],
            [121.909338, 53.853402],
            [121.93025, 53.847186],
            [121.946601, 53.826274],
            [121.926814, 53.80858],
            [121.924218, 53.79333],
            [121.934481, 53.784872],
            [121.9249, 53.77003],
            [121.944266, 53.749351],
            [121.933805, 53.72526],
            [121.96083, 53.726344],
            [121.96676, 53.697527],
            [121.956789, 53.64423],
            [121.966042, 53.633489],
            [121.948005, 53.616322],
            [121.98972, 53.578812],
            [121.974671, 53.55462],
            [122.011815, 53.528958],
            [122.028903, 53.523953],
            [122.039841, 53.509265],
            [122.071742, 53.489639],
            [122.093885, 53.467087],
            [122.086212, 53.446342],
            [122.112072, 53.431152],
            [122.078925, 53.41987],
            [121.992175, 53.427907],
            [121.948509, 53.428197],
            [121.907879, 53.420823],
            [121.871009, 53.425594],
            [121.840934, 53.416051],
            [121.817511, 53.415039],
            [121.75447, 53.390603],
            [121.733794, 53.385254],
            [121.69461, 53.391905],
            [121.656149, 53.373253],
            [121.619184, 53.364651],
            [121.572112, 53.34684],
            [121.488231, 53.333609],
            [121.445522, 53.321522],
            [121.422154, 53.323331],
            [121.39969, 53.316849],
            [121.365923, 53.322725],
            [121.327097, 53.322725],
            [121.298951, 53.298333],
            [121.276002, 53.288374],
            [121.245403, 53.289817],
            [121.234434, 53.280868],
            [121.212206, 53.278126],
            [121.147538, 53.282548],
            [121.120473, 53.274327],
            [121.11179, 53.290748],
            [121.088372, 53.305393],
            [121.065096, 53.302705],
            [121.041607, 53.287494],
            [120.993923, 53.288626],
            [120.940844, 53.295105],
            [120.925453, 53.284898],
            [120.898659, 53.284738],
            [120.883507, 53.291835],
            [120.857749, 53.276524],
            [120.811976, 53.264323],
            [120.827287, 53.255152],
            [120.830317, 53.23713],
            [120.810221, 53.239363],
            [120.759238, 53.211961],
            [120.726421, 53.202028],
            [120.717852, 53.19229],
            [120.682162, 53.171305],
            [120.690829, 53.161469],
            [120.684353, 53.142383],
            [120.645109, 53.12909],
            [120.635979, 53.103448],
            [120.60362, 53.098137],
            [120.576818, 53.083192],
            [120.555204, 53.079981],
            [120.526674, 53.058738],
            [120.522598, 53.044534],
            [120.502096, 53.038606],
            [120.489498, 53.025514],
            [120.445406, 53.007235],
            [120.41811, 52.982657],
            [120.405883, 52.957585],
            [120.379823, 52.943999],
            [120.356727, 52.939923],
            [120.342523, 52.922632],
            [120.342523, 52.903859],
            [120.331901, 52.896819],
            [120.288921, 52.891755],
            [120.289785, 52.869276],
            [120.280769, 52.860507],
            [120.247298, 52.853467],
            [120.211481, 52.838893],
            [120.18258, 52.809498],
            [120.137376, 52.812215],
            [120.099088, 52.796159],
            [120.092295, 52.785661],
            [120.062406, 52.778868],
            [120.034987, 52.779362],
            [120.024703, 52.772744],
            [120.02842, 52.758647],
            [120.048552, 52.73518],
            [120.062137, 52.707144],
            [120.048552, 52.687383],
            [120.027061, 52.635386],
            [120.041512, 52.597592],
            [120.078688, 52.583142],
            [120.119939, 52.584994],
            [120.184905, 52.576966],
            [120.282313, 52.622037],
            [120.30603, 52.618159],
            [120.391202, 52.615175],
            [120.440873, 52.641428],
            [120.459668, 52.643815],
            [120.478463, 52.629346],
            [120.566618, 52.592353],
            [120.598241, 52.589967],
            [120.618676, 52.570576],
            [120.655967, 52.566101],
            [120.681623, 52.546859],
            [120.716527, 52.548499],
            [120.728013, 52.540295],
            [120.716677, 52.525976],
            [120.686695, 52.517623],
            [120.681772, 52.505689],
            [120.69997, 52.487492],
            [120.679684, 52.472575],
            [120.683861, 52.42887],
            [120.64299, 52.389491],
            [120.647912, 52.369503],
            [120.639708, 52.360703],
            [120.61808, 52.360852],
            [120.621659, 52.324605],
            [120.651343, 52.299844],
            [120.693705, 52.287165],
            [120.7125, 52.259421],
            [120.747553, 52.258078],
            [120.756056, 52.248532],
            [120.737709, 52.225412],
            [120.739349, 52.20438],
            [120.780369, 52.158288],
            [120.753967, 52.135615],
            [120.763839, 52.117589],
            [120.755485, 52.102772],
            [120.738461, 52.096467],
            [120.745145, 52.076123],
            [120.725978, 52.080663],
            [120.693696, 52.055947],
            [120.679405, 52.036107],
            [120.687525, 52.023399],
            [120.707861, 52.016734],
            [120.712133, 52.007165],
            [120.697266, 51.981018],
            [120.654543, 51.954701],
            [120.6547, 51.92746],
            [120.572234, 51.90843],
            [120.542595, 51.907227],
            [120.546313, 51.886775],
            [120.535814, 51.877915],
            [120.497636, 51.885204],
            [120.477037, 51.884522],
            [120.464796, 51.874266],
            [120.469841, 51.851396],
            [120.443809, 51.841143],
            [120.391571, 51.830352],
            [120.399074, 51.815631],
            [120.371418, 51.807985],
            [120.35584, 51.787333],
            [120.304674, 51.780973],
            [120.287523, 51.749744],
            [120.262226, 51.747457],
            [120.246576, 51.728091],
            [120.225066, 51.72559],
            [120.217777, 51.714228],
            [120.166753, 51.677997],
            [120.130451, 51.671994],
            [120.084144, 51.680141],
            [120.080571, 51.672852],
            [120.095292, 51.648912],
            [120.062491, 51.639979],
            [120.052915, 51.6219],
            [120.0353, 51.6086],
            [120.027899, 51.583937],
            [120.038263, 51.566658],
            [120.020741, 51.562447],
            [120.008198, 51.539883],
            [120.006881, 51.518743],
            [119.984391, 51.508877],
            [119.97487, 51.487276],
            [119.995371, 51.458164],
            [119.993657, 51.448629],
            [119.967966, 51.434534],
            [119.964514, 51.400188],
            [119.915326, 51.395532],
            [119.902604, 51.38909],
            [119.905127, 51.374553],
            [119.935418, 51.366695],
            [119.934343, 51.354485],
            [119.91037, 51.343249],
            [119.8839, 51.342],
            [119.874151, 51.331613],
            [119.879275, 51.307529],
            [119.869378, 51.293671],
            [119.8319, 51.293116],
            [119.815078, 51.287048],
            [119.8049, 51.272],
            [119.8097, 51.2566],
            [119.7888, 51.2428],
            [119.815213, 51.226233],
            [119.812582, 51.212731],
            [119.777514, 51.225001],
            [119.752768, 51.212413],
            [119.768075, 51.179882],
            [119.781349, 51.173815],
            [119.779823, 51.161907],
            [119.7585, 51.167],
            [119.752438, 51.160761],
            [119.776105, 51.154354],
            [119.763802, 51.123091],
            [119.744122, 51.115214],
            [119.755986, 51.08965],
            [119.73825, 51.088455],
            [119.738159, 51.077448],
            [119.7088, 51.0746],
            [119.7188, 51.0591],
            [119.691193, 51.028786],
            [119.659036, 51.011245],
            [119.625936, 51.009532],
            [119.625101, 50.996816],
            [119.583472, 50.97552],
            [119.574304, 50.949816],
            [119.5429, 50.9129],
            [119.5109, 50.8993],
            [119.5242, 50.8819],
            [119.5188, 50.8613],
            [119.5029, 50.8563],
            [119.5035, 50.8408],
            [119.4821, 50.8396],
            [119.5033, 50.8095],
            [119.5012, 50.797],
            [119.5134, 50.7868],
            [119.4976, 50.7827],
            [119.4884, 50.7695],
            [119.4965, 50.762],
            [119.4689, 50.7221],
            [119.4257, 50.6817],
            [119.378556, 50.681318],
            [119.370627, 50.675154],
            [119.386277, 50.662267],
            [119.367575, 50.651158],
            [119.351511, 50.63001],
            [119.330896, 50.641154],
            [119.326612, 50.623468],
            [119.303913, 50.613605],
            [119.28835, 50.614659],
            [119.27401, 50.603488],
            [119.29185, 50.582372],
            [119.28708, 50.571242],
            [119.26388, 50.569812],
            [119.258337, 50.558843],
            [119.273984, 50.556082],
            [119.271237, 50.542737],
            [119.257312, 50.537933],
            [119.261083, 50.522079],
            [119.250217, 50.504934],
            [119.2303, 50.496678],
            [119.254732, 50.487494],
            [119.253976, 50.466143],
            [119.232569, 50.458244],
            [119.243241, 50.449992],
            [119.219081, 50.44121],
            [119.210254, 50.414499],
            [119.197959, 50.40972],
            [119.184081, 50.42174],
            [119.158929, 50.42211],
            [119.118282, 50.389549],
            [119.126809, 50.380122],
            [119.142412, 50.390075],
            [119.147498, 50.379159],
            [119.166622, 50.378913],
            [119.150573, 50.366125],
            [119.152724, 50.358008],
            [119.187801, 50.358234],
            [119.171823, 50.348104],
            [119.188176, 50.34751],
            [119.205654, 50.361957],
            [119.22412, 50.363834],
            [119.226432, 50.347652],
            [119.252979, 50.343066],
            [119.251191, 50.351033],
            [119.273985, 50.363507],
            [119.310201, 50.349492],
            [119.316853, 50.357654],
            [119.350619, 50.357736],
            [119.364288, 50.33325],
            [119.349861, 50.326034],
            [119.371572, 50.322644],
            [119.364538, 50.308657],
            [119.339381, 50.295972],
            [119.343305, 50.283621],
            [119.317955, 50.283237],
            [119.334335, 50.27132],
            [119.340443, 50.256641],
            [119.32064, 50.239897],
            [119.310048, 50.21794],
            [119.353084, 50.194695],
            [119.328776, 50.187526],
            [119.329986, 50.171952],
            [119.3027, 50.1609],
            [119.3006, 50.1475],
            [119.3172, 50.1434],
            [119.3187, 50.1174],
            [119.2871, 50.0992],
            [119.2765, 50.0816],
            [119.2496, 50.074],
            [119.2281, 50.0617],
            [119.2105, 50.044],
            [119.1771, 50.0464],
            [119.178, 50.0356],
            [119.1627, 50.0368],
            [119.1578, 50.0207],
            [119.1256, 50.0195],
            [119.1051, 49.9967],
            [119.0858, 50.0023],
            [119.074, 49.9881],
            [119.0588, 49.9893],
            [119.0458, 49.9789],
            [119.0336, 49.9879],
            [119.0041, 49.9823],
            [118.987, 49.9731],
            [118.965, 49.981],
            [118.931304, 49.963577],
            [118.9017, 49.9669],
            [118.8803, 49.9545],
            [118.8678, 49.9556],
            [118.827, 49.9455],
            [118.8056, 49.9314],
            [118.7817, 49.9266],
            [118.757, 49.9352],
            [118.733, 49.927],
            [118.7191, 49.9292],
            [118.7062, 49.9186],
            [118.6753, 49.9117],
            [118.6575, 49.9184],
            [118.6379, 49.9159],
            [118.6134, 49.8929],
            [118.5996, 49.8939],
            [118.571, 49.87],
            [118.5545, 49.8354],
            [118.514, 49.8277],
            [118.5082, 49.8206],
            [118.4644, 49.7969],
            [118.4556, 49.7808],
            [118.4306, 49.7802],
            [118.4054, 49.7724],
            [118.3617, 49.7514],
            [118.3283, 49.7464],
            [118.2888, 49.7234],
            [118.254, 49.7157],
            [118.2382, 49.6934],
            [118.1975, 49.6732],
            [118.1684, 49.6736],
            [118.1653, 49.6591],
            [118.1362, 49.6594],
            [118.1184, 49.6704],
            [118.0968, 49.6328],
            [118.0888, 49.6473],
            [118.0595, 49.6378],
            [118.0784, 49.6141],
            [118.0577, 49.6161],
            [118.0506, 49.609],
            [118.012, 49.6138],
            [118.0003, 49.583],
            [117.9753, 49.5809],
            [117.9765, 49.5719],
            [117.935016, 49.563839],
            [117.9333, 49.5506],
            [117.9151, 49.54],
            [117.8903, 49.5438],
            [117.875, 49.5394],
            [117.881244, 49.513743],
            [117.857861, 49.510206],
            [117.847341, 49.527621],
            [117.821555, 49.538617],
            [117.793331, 49.520143],
            [117.775097, 49.525857],
            [117.763126, 49.517471],
            [117.748347, 49.532022],
            [117.628608, 49.565982],
            [117.471214, 49.626934],
            [117.276288, 49.630441],
            [117.25719, 49.632799],
            [117.1874, 49.6531],
            [117.0614, 49.6856],
            [116.9935, 49.7119],
            [116.9269, 49.7405],
            [116.7266, 49.8326],
            [116.7015, 49.8491],
            [116.6216, 49.9311],
            [116.5268, 49.9472],
            [116.3436, 49.9958],
            [116.2819, 50.0152],
            [116.2355, 50.0356],
            [116.1255, 50.024],
            [116.0727, 50.0137],
            [116.0299, 49.99],
            [115.9829, 49.9707],
            [115.9496, 49.9614],
            [115.8435, 49.926125],
            [115.80728, 49.907556],
            [115.74634, 49.885772],
            [115.682482, 49.886104],
            [115.6548, 49.8891],
            [115.6099, 49.9003],
            [115.5067, 49.9071],
            [115.4351, 49.9268],
            [115.3608, 49.9402],
            [115.2947, 49.959],
            [115.2239, 49.9884],
            [115.1292, 50.0572],
            [115.0893, 50.0763],
            [115.0677, 50.0939],
            [115.0685, 50.1091],
            [115.0075, 50.1639],
            [114.9647, 50.1821],
            [114.9109, 50.1966],
            [114.8312, 50.2237],
            [114.7791, 50.2281],
            [114.7002, 50.2428],
            [114.6535, 50.2472],
            [114.6171, 50.2456],
            [114.5142, 50.2291],
            [114.4677, 50.2335],
            [114.412, 50.2529],
            [114.3388, 50.2827],
            [114.1784, 50.243],
            [114.0789, 50.1955],
            [114.0121, 50.1669],
            [113.9784, 50.1573],
            [113.9131, 50.12],
            [113.8787, 50.1049],
            [113.8108, 50.0923],
            [113.7427, 50.0749],
            [113.7103, 50.0442],
            [113.6827, 50.0259],
            [113.6519, 49.9997],
            [113.615, 49.9869],
            [113.4888, 49.9736],
            [113.4802, 49.9693],
            [113.4692, 49.9452],
            [113.4531, 49.9294],
            [113.3341, 49.8879],
            [113.2956, 49.8697],
            [113.2235, 49.8441],
            [113.193, 49.815],
            [113.178, 49.7909],
            [113.1623, 49.7805],
            [113.1597, 49.7536],
            [113.1283, 49.7335],
            [113.1144, 49.7014],
            [113.0736, 49.6563],
            [113.0686, 49.6348],
            [113.0439, 49.6082],
            [113.0143, 49.5935],
            [112.9936, 49.5958],
            [112.9683, 49.5853],
            [112.9475, 49.5847],
            [112.9318, 49.5724],
            [112.9068, 49.5685],
            [112.836, 49.5253],
            [112.8066, 49.5151],
            [112.761, 49.5129],
            [112.7442, 49.5079],
            [112.7322, 49.5179],
            [112.7115, 49.5183],
            [112.7055, 49.5067],
            [112.6889, 49.5041],
            [112.6436, 49.5139],
            [112.6021, 49.51],
            [112.5692, 49.5168],
            [112.4865, 49.5241],
            [112.4615, 49.52],
            [112.4031, 49.5034],
            [112.3574, 49.4996],
            [112.2783, 49.4844],
            [112.1946, 49.4466],
            [112.128, 49.4311],
            [112.0448, 49.4076],
            [112.0074, 49.3944],
            [111.9412, 49.3797],
            [111.9092, 49.3764],
            [111.8169, 49.3753],
            [111.7882, 49.3844],
            [111.7056, 49.3859],
            [111.6683, 49.3798],
            [111.5451, 49.3265],
            [111.5148, 49.323],
            [111.4215, 49.3477],
            [111.3748, 49.3577],
            [111.2768, 49.3104],
            [111.2107, 49.2925],
            [111.1158, 49.2693],
            [111.0787, 49.2621],
            [110.9551, 49.2215],
            [110.9386, 49.2136],
            [110.8193, 49.182],
            [110.7083, 49.1701],
            [110.6836, 49.1709],
            [110.5902, 49.1895],
            [110.5518, 49.1938],
            [110.5244, 49.1901],
            [110.5051, 49.199],
            [110.4762, 49.2016],
            [110.4142, 49.2283],
            [110.4043, 49.2489],
            [110.3796, 49.247],
            [110.3281, 49.1991],
            [110.2654, 49.1717],
            [110.2258, 49.1581],
            [110.1998, 49.1542],
            [110.1571, 49.1656],
            [110.1352, 49.1664],
            [110.1199, 49.178],
            [110.0924, 49.1831],
            [110.0306, 49.1851],
            [109.9895, 49.1948],
            [109.9563, 49.1954],
            [109.9026, 49.2057],
            [109.8533, 49.2016],
            [109.8201, 49.2112],
            [109.7581, 49.2204],
            [109.6838, 49.226],
            [109.6057, 49.2205],
            [109.5591, 49.2208],
            [109.5037, 49.2345],
            [109.487, 49.2432],
            [109.4753, 49.2674],
            [109.4325, 49.2729],
            [109.4124, 49.2998],
            [109.3876, 49.3023],
            [109.3776, 49.3119],
            [109.3261, 49.3292],
            [109.2057, 49.3498],
            [109.1712, 49.3529],
            [109.1113, 49.3348],
            [109.0581, 49.3232],
            [109.0388, 49.3246],
            [108.9554, 49.3448],
            [108.9086, 49.345],
            [108.8953, 49.3347],
            [108.8621, 49.3377],
            [108.8488, 49.3266],
            [108.8331, 49.3398],
            [108.8137, 49.3431],
            [108.7962, 49.3346],
            [108.7742, 49.3342],
            [108.7464, 49.3416],
            [108.7178, 49.3349],
            [108.6763, 49.3386],
            [108.6525, 49.3473],
            [108.6267, 49.3404],
            [108.6284, 49.3331],
            [108.6081, 49.3273],
            [108.5789, 49.3319],
            [108.5175, 49.3227],
            [108.4701, 49.3612],
            [108.4662, 49.3567],
            [108.431, 49.3721],
            [108.4381, 49.3938],
            [108.4258, 49.4135],
            [108.39, 49.4187],
            [108.3564, 49.4279],
            [108.3502, 49.4656],
            [108.3276, 49.5001],
            [108.3036, 49.5103],
            [108.3038, 49.5319],
            [108.281, 49.5449],
            [108.2343, 49.5402],
            [108.2061, 49.5485],
            [108.1959, 49.559],
            [108.1611, 49.5618],
            [108.1243, 49.5771],
            [108.1308, 49.5835],
            [108.0513, 49.6175],
            [108.0275, 49.6223],
            [108.0349, 49.6379],
            [108.0242, 49.6535],
            [108.023, 49.6734],
            [107.988, 49.6586],
            [107.9683, 49.6548],
            [107.9471, 49.6706],
            [107.9343, 49.7114],
            [107.9677, 49.735],
            [107.9698, 49.7474],
            [107.9556, 49.7647],
            [107.9485, 49.7848],
            [107.9726, 49.7922],
            [107.9816, 49.8028],
            [107.9738, 49.8129],
            [107.9367, 49.8192],
            [107.9319, 49.8319],
            [107.9444, 49.8549],
            [107.9268, 49.8627],
            [107.9192, 49.8754],
            [107.9659, 49.9102],
            [107.9745, 49.937],
            [107.9596, 49.9436],
            [107.8934, 49.932],
            [107.8549, 49.9371],
            [107.853, 49.9553],
            [107.8392, 49.9582],
            [107.8173, 49.94],
            [107.7934, 49.9379],
            [107.759212, 49.971554],
            [107.781597, 49.989284],
            [107.795637, 49.993336],
            [107.767316, 50.014998],
            [107.795316, 50.031947],
            [107.790663, 50.061952],
            [107.766915, 50.072302],
            [107.733379, 50.110552],
            [107.741803, 50.116489],
            [107.734021, 50.153214],
            [107.789701, 50.164626],
            [107.79981, 50.186168],
            [107.836716, 50.202013],
            [107.839684, 50.20779],
            [107.87322, 50.201773],
            [107.941416, 50.241707],
            [107.917588, 50.253741],
            [107.935239, 50.278653],
            [107.963239, 50.286375],
            [107.967331, 50.321736],
            [107.999183, 50.332908],
            [108.016512, 50.346547],
            [108.051493, 50.337802],
            [108.072673, 50.320773],
            [108.089762, 50.318747],
            [108.110622, 50.305489],
            [108.128273, 50.320352],
            [108.124262, 50.332386],
            [108.135333, 50.343658],
            [108.158279, 50.345584],
            [108.169592, 50.354249],
            [108.157076, 50.362673],
            [108.167185, 50.388246],
            [108.206819, 50.411713],
            [108.233295, 50.416045],
            [108.2422, 50.405535],
            [108.274934, 50.409868],
            [108.288172, 50.39679],
            [108.306064, 50.400962],
            [108.339359, 50.393902],
            [108.364873, 50.417188],
            [108.406191, 50.419114],
            [108.415338, 50.44563],
            [108.433871, 50.463441],
            [108.463396, 50.450383],
            [108.48939, 50.457363],
            [108.502067, 50.454555],
            [108.530548, 50.472065],
            [108.549242, 50.462919],
            [108.573873, 50.469418],
            [108.60067, 50.453692],
            [108.621048, 50.455137],
            [108.634045, 50.46677],
            [108.654183, 50.464604],
            [108.661404, 50.492564],
            [108.674, 50.507085],
            [108.690608, 50.511016],
            [108.692694, 50.524876],
            [108.683387, 50.54036],
            [108.640384, 50.540521],
            [108.620567, 50.529931],
            [108.594252, 50.539558],
            [108.535443, 50.538194],
            [108.509047, 50.553739],
            [108.511213, 50.562484],
            [108.480485, 50.583103],
            [108.481528, 50.594857],
            [108.424966, 50.602719],
            [108.406673, 50.622576],
            [108.377389, 50.612949],
            [108.338718, 50.619367],
            [108.313606, 50.63447],
            [108.278786, 50.639365],
            [108.268276, 50.655089],
            [108.239847, 50.665319],
            [108.219128, 50.663223],
            [108.181822, 50.649003],
            [108.069356, 50.73881],
            [108.081109, 50.753623],
            [108.060542, 50.774557],
            [108.088781, 50.772761],
            [108.097351, 50.784698],
            [108.175784, 50.806999],
            [108.215531, 50.828505],
            [108.264092, 50.822873],
            [108.274947, 50.838666],
            [108.277396, 50.876576],
            [108.260828, 50.899632],
            [108.268174, 50.922484],
            [108.295515, 50.950805],
            [108.305309, 50.99704],
            [108.330447, 50.99959],
            [108.324897, 51.015424],
            [108.328489, 51.040215],
            [108.341118, 51.062878],
            [108.371011, 51.062741],
            [108.396394, 51.053763],
            [108.420961, 51.057619],
            [108.453526, 51.052886],
            [108.500129, 51.070617],
            [108.498987, 51.082288],
            [108.561342, 51.120566],
            [108.567137, 51.137052],
            [108.555793, 51.161741],
            [108.557588, 51.17786],
            [108.468707, 51.206406],
            [108.459566, 51.216445],
            [108.431898, 51.229034],
            [108.425451, 51.238094],
            [108.385785, 51.229361],
            [108.375583, 51.25607],
            [108.397783, 51.278983],
            [108.448957, 51.297531],
            [108.462478, 51.295736],
            [108.471483, 51.333401],
            [108.478094, 51.343032],
            [108.474585, 51.367966],
            [108.479155, 51.387533],
            [108.503069, 51.40557],
            [108.536614, 51.415976],
            [108.562568, 51.399816],
            [108.618639, 51.408569],
            [108.622067, 51.43485],
            [108.637819, 51.441624],
            [108.635208, 51.488003],
            [108.628189, 51.492491],
            [108.654388, 51.50253],
            [108.679036, 51.504815],
            [108.728822, 51.482697],
            [108.734862, 51.470577],
            [108.761224, 51.452356],
            [108.773875, 51.4566],
            [108.785628, 51.437992],
            [108.816805, 51.436584],
            [108.853207, 51.458722],
            [108.862103, 51.449989],
            [108.920459, 51.431544],
            [108.929111, 51.440522],
            [108.951719, 51.438073],
            [108.995302, 51.45358],
            [109.026317, 51.478432],
            [109.048598, 51.475739],
            [109.058556, 51.491022],
            [109.052679, 51.508814],
            [109.063616, 51.517914],
            [109.092916, 51.482615],
            [109.109893, 51.481717],
            [109.125726, 51.461925],
            [109.116912, 51.451152],
            [109.11887, 51.432584],
            [109.145641, 51.41977],
            [109.153966, 51.409609],
            [109.174125, 51.407242],
            [109.193958, 51.388185],
            [109.208159, 51.383451],
            [109.225135, 51.365903],
            [109.282676, 51.375289],
            [109.290674, 51.385246],
            [109.310017, 51.386715],
            [109.31622, 51.397672],
            [109.350336, 51.389511],
            [109.393267, 51.353722],
            [109.41514, 51.369147],
            [109.382657, 51.384899],
            [109.383963, 51.399325],
            [109.404204, 51.404059],
            [109.422078, 51.431482],
            [109.457826, 51.426544],
            [109.485413, 51.440194],
            [109.52248, 51.444616],
            [109.538385, 51.439167],
            [109.544391, 51.448064],
            [109.597111, 51.453292],
            [109.619022, 51.448509],
            [109.641934, 51.464775],
            [109.671519, 51.467333],
            [109.693541, 51.476898],
            [109.724239, 51.503202],
            [109.748931, 51.502201],
            [109.792196, 51.516021],
            [109.819224, 51.534066],
            [109.834017, 51.552891],
            [109.913096, 51.572048],
            [109.928445, 51.5852],
            [109.949355, 51.587091],
            [109.958809, 51.607806],
            [109.975382, 51.616509],
            [109.995068, 51.611282],
            [110.033218, 51.615842],
            [110.048233, 51.623071],
            [110.071923, 51.608167],
            [110.103733, 51.604497],
            [110.109628, 51.598602],
            [110.136989, 51.600048],
            [110.14622, 51.61117],
            [110.183925, 51.594042],
            [110.216958, 51.601271],
            [110.244986, 51.595043],
            [110.293146, 51.602495],
            [110.313277, 51.591706],
            [110.365775, 51.576107],
            [110.394804, 51.586451],
            [110.389799, 51.54794],
            [110.409485, 51.545243],
            [110.426613, 51.520746],
            [110.455309, 51.518966],
            [110.473327, 51.511709],
            [110.495127, 51.518827],
            [110.511143, 51.514601],
            [110.521932, 51.499919],
            [110.598787, 51.497778],
            [110.618362, 51.513683],
            [110.687543, 51.508567],
            [110.712123, 51.514461],
            [110.713569, 51.538736],
            [110.740263, 51.5473],
            [110.759171, 51.54121],
            [110.775966, 51.563816],
            [110.778413, 51.581751],
            [110.809778, 51.589119],
            [110.846259, 51.585337],
            [110.872508, 51.614728],
            [110.861497, 51.641588],
            [110.835137, 51.649485],
            [110.84181, 51.675205],
            [110.879293, 51.680238],
            [110.903651, 51.700396],
            [110.919333, 51.703872],
            [110.933459, 51.722001],
            [110.953812, 51.7267],
            [111.000971, 51.724921],
            [111.013539, 51.73418],
            [111.029778, 51.771606],
            [111.014541, 51.79132],
            [111.059253, 51.799356],
            [111.089839, 51.814065],
            [111.114308, 51.81362],
            [111.149232, 51.832889],
            [111.211072, 51.823102],
            [111.288817, 51.839701],
            [111.293044, 51.855495],
            [111.315622, 51.870204],
            [111.350213, 51.878323],
            [111.401153, 51.868647],
            [111.462437, 51.871232],
            [111.481901, 51.893504],
            [111.514711, 51.913365],
            [111.575779, 51.922441],
            [111.601077, 51.931871],
            [111.616522, 51.957546],
            [111.637381, 51.95384],
            [111.672087, 51.977029],
            [111.71496, 51.975609],
            [111.705462, 51.990809],
            [111.732801, 51.997311],
            [111.735908, 52.020145],
            [111.753572, 52.03073],
            [111.774875, 52.069585],
            [111.803368, 52.07298],
            [111.796267, 52.086849],
            [111.828045, 52.094682],
            [111.832394, 52.109972],
            [111.893285, 52.118936],
            [111.885741, 52.14126],
            [111.909174, 52.146142],
            [111.93101, 52.163229],
            [111.932341, 52.180116],
            [111.922311, 52.184376],
            [111.937934, 52.200664],
            [112.00637, 52.209806],
            [112.010986, 52.228735],
            [112.043295, 52.238365],
            [112.045426, 52.263352],
            [112.067883, 52.272228],
            [112.069747, 52.282547],
            [112.093802, 52.287451],
            [112.145728, 52.281148],
            [112.139781, 52.290646],
            [112.159486, 52.302473],
            [112.177772, 52.302828],
            [112.198098, 52.269498],
            [112.192062, 52.251191],
            [112.219845, 52.246842],
            [112.236887, 52.237056],
            [112.305501, 52.244955],
            [112.325739, 52.234925],
            [112.333283, 52.221411],
            [112.354143, 52.221566],
            [112.377576, 52.212934],
            [112.381304, 52.202993],
            [112.457374, 52.237299],
            [112.475304, 52.238098],
            [112.511075, 52.249215],
            [112.50646, 52.296681],
            [112.521994, 52.30316],
            [112.51578, 52.321578],
            [112.541699, 52.334382],
            [112.546581, 52.346365],
            [112.584217, 52.34976],
            [112.610136, 52.357393],
            [112.653274, 52.340795],
            [112.653452, 52.350292],
            [112.680258, 52.354375],
            [112.705467, 52.335824],
            [112.735468, 52.326659],
            [112.804526, 52.326126],
            [112.82645, 52.340706],
            [112.855831, 52.338664],
            [112.881217, 52.351024],
            [112.905982, 52.333427],
            [112.922137, 52.329699],
            [112.94202, 52.335557],
            [112.938203, 52.347007],
            [112.981963, 52.376365],
            [113.008414, 52.370063],
            [113.037884, 52.388082],
            [113.093361, 52.402749],
            [113.092739, 52.417861],
            [113.113954, 52.424097],
            [113.111469, 52.434659],
            [113.141648, 52.433882],
            [113.152477, 52.452545],
            [113.289971, 52.46881],
            [113.296451, 52.462241],
            [113.488178, 52.469431],
            [113.500783, 52.477175],
            [113.542235, 52.488559],
            [113.590877, 52.448327],
            [113.617772, 52.489357],
            [113.640584, 52.50154],
            [113.640318, 52.529455],
            [113.710086, 52.552755],
            [113.737869, 52.576854],
            [113.782073, 52.576321],
            [113.782872, 52.586817],
            [113.815093, 52.614289],
            [113.849444, 52.603282],
            [113.872789, 52.612691],
            [113.881665, 52.631908],
            [113.912555, 52.640163],
            [113.960309, 52.627381],
            [113.971404, 52.637411],
            [114.024574, 52.666392],
            [114.077388, 52.66222],
            [114.08156, 52.679728],
            [114.098424, 52.681681],
            [114.114757, 52.70498],
            [114.14467, 52.715388],
            [114.14183, 52.730855],
            [114.177335, 52.752468],
            [114.181241, 52.767402],
            [114.198461, 52.786508],
            [114.215149, 52.795318],
            [114.227221, 52.812848],
            [114.260773, 52.816221],
            [114.237606, 52.833729],
            [114.223759, 52.862444],
            [114.237074, 52.880152],
            [114.235388, 52.897394],
            [114.253673, 52.912794],
            [114.253673, 52.933586],
            [114.281811, 52.9552],
            [114.306576, 52.955466],
            [114.316606, 52.978211],
            [114.300541, 53.003997],
            [114.319003, 53.016801],
            [114.307287, 53.030403],
            [114.325716, 53.041399],
            [114.354775, 53.034775],
            [114.368291, 53.05173],
            [114.329566, 53.060383],
            [114.290511, 53.059761],
            [114.265657, 53.083838],
            [114.268853, 53.089519],
            [114.233525, 53.088654],
            [114.206541, 53.06724],
            [114.17006, 53.08608],
            [114.157722, 53.072388],
            [114.143608, 53.075783],
            [114.141656, 53.107782],
            [114.150355, 53.119232],
            [114.170326, 53.115238],
            [114.204944, 53.144507],
            [114.205566, 53.17746],
            [114.158167, 53.190353],
            [114.104554, 53.183163],
            [114.092837, 53.197388],
            [114.067007, 53.198186],
            [114.04242, 53.206552],
            [114.04313, 53.227789],
            [114.052362, 53.246917],
            [114.025822, 53.237419],
            [114.007448, 53.247627],
            [113.97283, 53.235556],
            [113.948776, 53.249935],
            [113.94416, 53.2743],
            [113.925698, 53.285684],
            [113.952771, 53.304967],
            [113.9557, 53.321255],
            [113.940433, 53.334769],
            [113.948865, 53.341537],
            [113.937326, 53.358602],
            [113.918242, 53.355673],
            [113.874837, 53.369986],
            [113.841463, 53.394529],
            [113.826728, 53.41774],
            [113.847588, 53.432497],
            [113.822646, 53.447342],
            [113.835872, 53.48298],
            [113.868625, 53.494297],
            [113.882472, 53.516155],
            [113.876526, 53.548886],
            [113.859217, 53.56808],
            [113.812262, 53.584723],
            [113.774626, 53.58854],
            [113.799125, 53.615391],
            [113.824245, 53.630769],
            [113.857797, 53.631834],
            [113.872266, 53.62258],
            [113.890462, 53.63281],
            [113.891705, 53.651738],
            [113.912387, 53.644482],
            [113.937063, 53.662944],
            [113.937595, 53.644016],
            [113.967153, 53.643506],
            [113.981178, 53.673618],
            [113.977184, 53.687753],
            [113.958011, 53.691592],
            [113.947449, 53.703309],
            [114.003458, 53.727496],
            [114.11095, 53.734442],
            [114.156575, 53.759073],
            [114.180629, 53.741232],
            [114.204595, 53.739168],
            [114.218265, 53.753747],
            [114.24374, 53.744249],
            [114.270013, 53.754457],
            [114.305962, 53.755344],
            [114.308714, 53.760315],
            [114.351143, 53.77394],
            [114.35496, 53.800968],
            [114.363925, 53.814216],
            [114.388069, 53.822825],
            [114.433604, 53.805428],
            [114.46689, 53.819718],
            [114.499644, 53.809511],
            [114.51207, 53.814304],
            [114.527693, 53.852249],
            [114.562843, 53.86978],
            [114.577312, 53.882428],
            [114.577046, 53.915647],
            [114.618587, 53.918066],
            [114.657021, 53.915403],
            [114.689597, 53.92945],
            [114.721552, 53.925721],
            [114.749424, 53.928473],
            [114.753329, 53.947756],
            [114.806498, 53.951396],
            [114.864106, 53.951484],
            [114.878219, 53.975938],
            [114.901031, 53.972476],
            [114.921713, 53.978157],
            [114.939643, 53.995687],
            [114.973639, 53.993024],
            [114.984912, 53.999681],
            [114.988729, 54.029461],
            [115.022282, 54.029993],
            [115.013761, 54.044684],
            [115.012518, 54.073642],
            [115.033733, 54.083162],
            [115.091961, 54.09439],
            [115.094447, 54.106462],
            [115.143888, 54.118111],
            [115.151256, 54.136796],
            [115.172381, 54.163202],
            [115.161819, 54.189431],
            [115.18259, 54.200172],
            [115.205934, 54.193159],
            [115.249162, 54.207561],
            [115.251115, 54.223294],
            [115.263719, 54.230506],
            [115.313782, 54.23612],
            [115.341298, 54.267231],
            [115.37201, 54.266165],
            [115.403876, 54.27249],
            [115.451631, 54.300738],
            [115.475242, 54.302358],
            [115.500362, 54.31454],
            [115.499741, 54.345119],
            [115.489445, 54.367886],
            [115.501694, 54.37421],
            [115.578829, 54.381577],
            [115.594629, 54.391008],
            [115.596138, 54.410469],
            [115.638035, 54.415085],
            [115.676647, 54.430463],
            [115.706116, 54.423184],
            [115.757421, 54.443],
            [115.786713, 54.435366],
            [115.82204, 54.445507],
            [115.841568, 54.459287],
            [115.903436, 54.461062],
            [115.934947, 54.472357],
            [115.974269, 54.454693],
            [116.012526, 54.480634],
            [116.040842, 54.486403],
            [116.076524, 54.479302],
            [116.112917, 54.478681],
            [116.134043, 54.497343],
            [116.157831, 54.498852],
            [116.17372, 54.510857],
            [116.189165, 54.511567],
            [116.191916, 54.494613],
            [116.210911, 54.497897],
            [116.249434, 54.490175],
            [116.262128, 54.508304],
            [116.285029, 54.505464],
            [116.324084, 54.488488],
            [116.343168, 54.509192],
            [116.404592, 54.501292],
            [116.431753, 54.476993],
            [116.446311, 54.485336],
            [116.483058, 54.484205],
            [116.490958, 54.460194],
            [116.516078, 54.45995],
            [116.529747, 54.470957],
            [116.509066, 54.488021],
            [116.515102, 54.515471],
            [116.52673, 54.519021],
            [116.574839, 54.506683],
            [116.609812, 54.519198],
            [116.646826, 54.50473],
            [116.670615, 54.522238],
            [116.720588, 54.529272],
            [116.72334, 54.547136],
            [116.695647, 54.561537],
            [116.698842, 54.57017],
            [116.771717, 54.589542],
            [116.780771, 54.607405],
            [116.75716, 54.610445],
            [116.750059, 54.621807],
            [116.765681, 54.629018],
            [116.744467, 54.652762],
            [116.74571, 54.662903],
            [116.777754, 54.674642],
            [116.814945, 54.672955],
            [116.825863, 54.688245],
            [116.820893, 54.697742],
            [116.838113, 54.705398],
            [116.867049, 54.699362],
            [116.877701, 54.709947],
            [116.864032, 54.733868],
            [116.847256, 54.737241],
            [116.848854, 54.76924],
            [116.88072, 54.78961],
            [116.913562, 54.793782],
            [116.912409, 54.805787],
            [116.89155, 54.815462],
            [116.918977, 54.828643],
            [116.908592, 54.849702],
            [116.911699, 54.867854],
            [116.87504, 54.87622],
            [116.874597, 54.903603],
            [116.85933, 54.922798],
            [116.890131, 54.946897],
            [116.858354, 54.952156],
            [116.840335, 54.966003],
            [116.866343, 54.983533],
            [116.839004, 54.983977],
            [116.817346, 54.976699],
            [116.808914, 54.995072],
            [116.831016, 55.005657],
            [116.836253, 55.031198],
            [116.822584, 55.044158],
            [116.794002, 55.051991],
            [116.799683, 55.067014],
            [116.764888, 55.096727],
            [116.755746, 55.117608],
            [116.735508, 55.125886],
            [116.718732, 55.110707],
            [116.687487, 55.119406],
            [116.671422, 55.138335],
            [116.62278, 55.144371],
            [116.591092, 55.177634],
            [116.536059, 55.196852],
            [116.525763, 55.210099],
            [116.472505, 55.229117],
            [116.450581, 55.25204],
            [116.460256, 55.277315],
            [116.44348, 55.299461],
            [116.472506, 55.316436],
            [116.450937, 55.3262],
            [116.428302, 55.318656],
            [116.422977, 55.334122],
            [116.395371, 55.340891],
            [116.396082, 55.3576],
            [116.377619, 55.379369],
            [116.350902, 55.383119],
            [116.292052, 55.361173],
            [116.267819, 55.364635],
            [116.233468, 55.352541],
            [116.182696, 55.359642],
            [116.106803, 55.343843],
            [116.08124, 55.364192],
            [116.082837, 55.377972],
            [116.036769, 55.378327],
            [116.01733, 55.364547],
            [115.974102, 55.355582],
            [115.952711, 55.365435],
            [115.92626, 55.392463],
            [115.902383, 55.409705],
            [115.95351, 55.43336],
            [115.961588, 55.444722],
            [115.93061, 55.469287],
            [115.897324, 55.476232],
            [115.873891, 55.507943],
            [115.876821, 55.540319],
            [115.897769, 55.564883],
            [115.915965, 55.573937],
            [115.910729, 55.586208],
            [115.887473, 55.58896],
            [115.875046, 55.603273],
            [115.897503, 55.627727],
            [115.908067, 55.650583],
            [115.897415, 55.664008],
            [115.828624, 55.676457],
            [115.839542, 55.72055],
            [115.861733, 55.732821],
            [115.864485, 55.743051],
            [115.840431, 55.760049],
            [115.830401, 55.797462],
            [115.83697, 55.856333],
            [115.87505, 55.870557],
            [115.896087, 55.899205],
            [115.904963, 55.926943],
            [115.860138, 55.956989],
            [115.779897, 55.986148],
            [115.781407, 56.027489],
            [115.754245, 56.045818],
            [115.750074, 56.060397],
            [115.78407, 56.075331],
            [115.783981, 56.088224],
            [115.763921, 56.099874],
            [115.753803, 56.124861],
            [115.758685, 56.13347],
            [115.81567, 56.133404],
            [115.832181, 56.148693],
            [115.817712, 56.166379],
            [115.764721, 56.17723],
            [115.755756, 56.197934],
            [115.727175, 56.233594],
            [115.705517, 56.251413],
            [115.697174, 56.268012],
            [115.675782, 56.280727],
            [115.67019, 56.303672],
            [115.675161, 56.328814],
            [115.69389, 56.336314],
            [115.686967, 56.371508],
            [115.671789, 56.391546],
            [115.676227, 56.409143],
            [115.698063, 56.423833],
            [115.727089, 56.42765],
            [115.767121, 56.403639],
            [115.790998, 56.402929],
            [115.804756, 56.414846],
            [115.795792, 56.441252],
            [115.733125, 56.473872],
            [115.69336, 56.486321],
            [115.672856, 56.515901],
            [115.673921, 56.53432],
            [115.686437, 56.549808],
            [115.67836, 56.572132],
            [115.642855, 56.577458],
            [115.597763, 56.575062],
            [115.568471, 56.577636],
            [115.549742, 56.5716],
            [115.500035, 56.577015],
            [115.480951, 56.583938],
            [115.420681, 56.5955],
            [115.415513, 56.620234],
            [115.432576, 56.642788],
            [115.424587, 56.653639],
            [115.43302, 56.666531],
            [115.50767, 56.680223],
            [115.555691, 56.693825],
            [115.586226, 56.714107],
            [115.593327, 56.728065],
            [115.58667, 56.754117],
            [115.614453, 56.771381],
            [115.606287, 56.799385],
            [115.586138, 56.826036],
            [115.584186, 56.84492],
            [115.593684, 56.85495],
            [115.587648, 56.879848],
            [115.615342, 56.891498],
            [115.616496, 56.908207],
            [115.643037, 56.919236],
            [115.699313, 56.930708],
            [115.729827, 56.962428],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5510",
      properties: {
        name: "Ивановская область",
        density: 0,
        path: "/world/Russia/Ивановская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [40.430464, 57.285829],
            [40.470622, 57.272169],
            [40.526172, 57.286304],
            [40.532688, 57.304007],
            [40.563659, 57.313549],
            [40.59661, 57.313227],
            [40.602738, 57.301225],
            [40.653451, 57.288239],
            [40.680512, 57.294165],
            [40.704242, 57.315717],
            [40.736858, 57.319211],
            [40.755435, 57.328693],
            [40.781358, 57.328699],
            [40.830224, 57.319582],
            [40.827293, 57.32933],
            [40.864246, 57.353118],
            [40.861736, 57.342267],
            [40.893784, 57.337277],
            [40.929412, 57.320323],
            [40.974993, 57.32305],
            [41.018309, 57.31818],
            [41.013476, 57.335232],
            [41.030196, 57.351131],
            [41.057355, 57.344063],
            [41.102141, 57.370997],
            [41.110593, 57.385347],
            [41.153112, 57.391702],
            [41.139434, 57.409306],
            [41.151576, 57.422957],
            [41.178571, 57.42156],
            [41.202914, 57.406044],
            [41.24748, 57.40307],
            [41.251767, 57.413],
            [41.312641, 57.414808],
            [41.331075, 57.43036],
            [41.362101, 57.438811],
            [41.379769, 57.431801],
            [41.418254, 57.438892],
            [41.406201, 57.461505],
            [41.420339, 57.462744],
            [41.422237, 57.479755],
            [41.536083, 57.46088],
            [41.567387, 57.45253],
            [41.582177, 57.474344],
            [41.608792, 57.493307],
            [41.609309, 57.516328],
            [41.632585, 57.538482],
            [41.604078, 57.54273],
            [41.593087, 57.562892],
            [41.619631, 57.56666],
            [41.611774, 57.574422],
            [41.624531, 57.596401],
            [41.588428, 57.598705],
            [41.619181, 57.623641],
            [41.59596, 57.635596],
            [41.569898, 57.634141],
            [41.587964, 57.663863],
            [41.592855, 57.687952],
            [41.579001, 57.703841],
            [41.599156, 57.736762],
            [41.626886, 57.737664],
            [41.651512, 57.72875],
            [41.725811, 57.727919],
            [41.797126, 57.717537],
            [41.803355, 57.706063],
            [41.824787, 57.698861],
            [41.895725, 57.693402],
            [41.893026, 57.676692],
            [41.906054, 57.675256],
            [41.902112, 57.657774],
            [41.934463, 57.655292],
            [41.930339, 57.634648],
            [41.965481, 57.627089],
            [41.983082, 57.632335],
            [42.018787, 57.629918],
            [42.007887, 57.60658],
            [42.023312, 57.606395],
            [42.023212, 57.588587],
            [42.103377, 57.58688],
            [42.122761, 57.577753],
            [42.124028, 57.559258],
            [42.200277, 57.566996],
            [42.22089, 57.554981],
            [42.245471, 57.570489],
            [42.252431, 57.562938],
            [42.322874, 57.573506],
            [42.350359, 57.567944],
            [42.376808, 57.57665],
            [42.374847, 57.594698],
            [42.406645, 57.599832],
            [42.403898, 57.608723],
            [42.425988, 57.618575],
            [42.431764, 57.629752],
            [42.460068, 57.636223],
            [42.465958, 57.622092],
            [42.511669, 57.630891],
            [42.530508, 57.62483],
            [42.624344, 57.634829],
            [42.668757, 57.627971],
            [42.692215, 57.633179],
            [42.715006, 57.613792],
            [42.729233, 57.621489],
            [42.719241, 57.643018],
            [42.74892, 57.690753],
            [42.783707, 57.690568],
            [42.784388, 57.681019],
            [42.831241, 57.681371],
            [42.839351, 57.663537],
            [42.859525, 57.667734],
            [42.883711, 57.654661],
            [42.864368, 57.645138],
            [42.868231, 57.6364],
            [42.851943, 57.615724],
            [42.873355, 57.600018],
            [42.858562, 57.565368],
            [42.834677, 57.54366],
            [42.806918, 57.527843],
            [42.818915, 57.516402],
            [42.847368, 57.508583],
            [42.841078, 57.490338],
            [42.866, 57.4805],
            [42.85925, 57.46625],
            [42.8405, 57.469],
            [42.827, 57.448],
            [42.84725, 57.44225],
            [42.849, 57.432],
            [42.81475, 57.4295],
            [42.86929, 57.38536],
            [42.89554, 57.37111],
            [42.92404, 57.37161],
            [42.94979, 57.37911],
            [43.01775, 57.3825],
            [43.051582, 57.394363],
            [43.08213, 57.393795],
            [43.104874, 57.403865],
            [43.11775, 57.398],
            [43.161065, 57.405714],
            [43.1845, 57.4305],
            [43.1705, 57.449],
            [43.188953, 57.469145],
            [43.183, 57.476],
            [43.201, 57.494],
            [43.210096, 57.516262],
            [43.184346, 57.542465],
            [43.19295, 57.554294],
            [43.200411, 57.535571],
            [43.228421, 57.51898],
            [43.21433, 57.486632],
            [43.227503, 57.463414],
            [43.20488, 57.460505],
            [43.224945, 57.418325],
            [43.263262, 57.410914],
            [43.304617, 57.428877],
            [43.324612, 57.418904],
            [43.310942, 57.405349],
            [43.225935, 57.336457],
            [43.203062, 57.315011],
            [43.196526, 57.276124],
            [43.184381, 57.255553],
            [43.136787, 57.227748],
            [43.101808, 57.198735],
            [43.086767, 57.166766],
            [43.086668, 57.136286],
            [43.099189, 57.096033],
            [43.127419, 57.054379],
            [43.151622, 57.02938],
            [43.214734, 56.978615],
            [43.23071, 56.952499],
            [43.250795, 56.895032],
            [43.285637, 56.858406],
            [43.300596, 56.820831],
            [43.272947, 56.813358],
            [43.270058, 56.797537],
            [43.249595, 56.8085],
            [43.236515, 56.851282],
            [43.198231, 56.860434],
            [43.181248, 56.855121],
            [43.173024, 56.870686],
            [43.152336, 56.879027],
            [43.140145, 56.85318],
            [43.11676, 56.834262],
            [43.084443, 56.824191],
            [43.046145, 56.826432],
            [43.027463, 56.820827],
            [42.983092, 56.827926],
            [42.922937, 56.809616],
            [42.905376, 56.799154],
            [42.890917, 56.809883],
            [42.870814, 56.809429],
            [42.832164, 56.773185],
            [42.832322, 56.744479],
            [42.824681, 56.717636],
            [42.802596, 56.707983],
            [42.797447, 56.691727],
            [42.827591, 56.668003],
            [42.822979, 56.654869],
            [42.859146, 56.636619],
            [42.898378, 56.645773],
            [42.927336, 56.634938],
            [42.913325, 56.620179],
            [42.924127, 56.601803],
            [42.913446, 56.592371],
            [42.92491, 56.569364],
            [42.902305, 56.567869],
            [42.853172, 56.552922],
            [42.843133, 56.530356],
            [42.821133, 56.515931],
            [42.868773, 56.509206],
            [42.902709, 56.511607],
            [42.887011, 56.489771],
            [42.897287, 56.470342],
            [42.793414, 56.467912],
            [42.651056, 56.46847],
            [42.655302, 56.449928],
            [42.679765, 56.452573],
            [42.677119, 56.440716],
            [42.644559, 56.428873],
            [42.614748, 56.430057],
            [42.614182, 56.406734],
            [42.585831, 56.399935],
            [42.554385, 56.412925],
            [42.500383, 56.419692],
            [42.473165, 56.417244],
            [42.455884, 56.429772],
            [42.405769, 56.447772],
            [42.368039, 56.444748],
            [42.321381, 56.449499],
            [42.279188, 56.434954],
            [42.243143, 56.443662],
            [42.218455, 56.440683],
            [42.20424, 56.450181],
            [42.205838, 56.408712],
            [42.189135, 56.39877],
            [42.137628, 56.397683],
            [42.138893, 56.378509],
            [42.113239, 56.378012],
            [42.117332, 56.368541],
            [42.072114, 56.36738],
            [42.046402, 56.352363],
            [42.02972, 56.380123],
            [42.010684, 56.368837],
            [41.979437, 56.373575],
            [41.980664, 56.389011],
            [41.963525, 56.431205],
            [41.942356, 56.440277],
            [41.938676, 56.450492],
            [41.951491, 56.465613],
            [41.948542, 56.481586],
            [41.915056, 56.503919],
            [41.93104, 56.509535],
            [41.92384, 56.518607],
            [41.882941, 56.528111],
            [41.849533, 56.504062],
            [41.833692, 56.499741],
            [41.740608, 56.492127],
            [41.702438, 56.496004],
            [41.708198, 56.512133],
            [41.697109, 56.513861],
            [41.677381, 56.49658],
            [41.609699, 56.477138],
            [41.585362, 56.473681],
            [41.563541, 56.483701],
            [41.544808, 56.472339],
            [41.533371, 56.47926],
            [41.54409, 56.489845],
            [41.526606, 56.506226],
            [41.491757, 56.491248],
            [41.401778, 56.493879],
            [41.370215, 56.501039],
            [41.353222, 56.492686],
            [41.323845, 56.496286],
            [41.316213, 56.487934],
            [41.289284, 56.489805],
            [41.267544, 56.482291],
            [41.265891, 56.490895],
            [41.232739, 56.485437],
            [41.202735, 56.493694],
            [41.183273, 56.488577],
            [41.1745, 56.49936],
            [41.162367, 56.479171],
            [41.146142, 56.475979],
            [41.1468, 56.489134],
            [41.065353, 56.489658],
            [41.054607, 56.483511],
            [41.032232, 56.490377],
            [41.023734, 56.520331],
            [40.985339, 56.512265],
            [40.977508, 56.521626],
            [40.951875, 56.520042],
            [40.918034, 56.50348],
            [40.885921, 56.50564],
            [40.880015, 56.524361],
            [40.822227, 56.560756],
            [40.830619, 56.573322],
            [40.798937, 56.571594],
            [40.781943, 56.590029],
            [40.767234, 56.594363],
            [40.673149, 56.590209],
            [40.652914, 56.582536],
            [40.648323, 56.591099],
            [40.612629, 56.587828],
            [40.606929, 56.579892],
            [40.578597, 56.587331],
            [40.579881, 56.597641],
            [40.561214, 56.60283],
            [40.531286, 56.58385],
            [40.490601, 56.583698],
            [40.457471, 56.535148],
            [40.427995, 56.543034],
            [40.413423, 56.523087],
            [40.387375, 56.534305],
            [40.335686, 56.504247],
            [40.314215, 56.507603],
            [40.303703, 56.48835],
            [40.267414, 56.488062],
            [40.247685, 56.473949],
            [40.259639, 56.459548],
            [40.235878, 56.458252],
            [40.22551, 56.439819],
            [40.192821, 56.435786],
            [40.190228, 56.452347],
            [40.176691, 56.459979],
            [40.13835, 56.452279],
            [40.112812, 56.463548],
            [40.102725, 56.480223],
            [40.063175, 56.477339],
            [40.046509, 56.484456],
            [39.992938, 56.467033],
            [39.977385, 56.474953],
            [39.949304, 56.466888],
            [39.935321, 56.48865],
            [39.916613, 56.495401],
            [39.932454, 56.506633],
            [39.926549, 56.517722],
            [39.948582, 56.521466],
            [39.957077, 56.533707],
            [39.931876, 56.539179],
            [39.928131, 56.554011],
            [39.916035, 56.552859],
            [39.889968, 56.572444],
            [39.883055, 56.596925],
            [39.839997, 56.58814],
            [39.850077, 56.596924],
            [39.824012, 56.610172],
            [39.869085, 56.624718],
            [39.880746, 56.693409],
            [39.896586, 56.706658],
            [39.900942, 56.770205],
            [39.861229, 56.77002],
            [39.847045, 56.763107],
            [39.802331, 56.765411],
            [39.766473, 56.774627],
            [39.72651, 56.796011],
            [39.701174, 56.798059],
            [39.674091, 56.812427],
            [39.666603, 56.803354],
            [39.636649, 56.812642],
            [39.624481, 56.796441],
            [39.579191, 56.798241],
            [39.534478, 56.782543],
            [39.51869, 56.763236],
            [39.49674, 56.762545],
            [39.491998, 56.749415],
            [39.459258, 56.744352],
            [39.42612, 56.753829],
            [39.419312, 56.769288],
            [39.402019, 56.771048],
            [39.379041, 56.777268],
            [39.378491, 56.80001],
            [39.397218, 56.803031],
            [39.377242, 56.811235],
            [39.379646, 56.833224],
            [39.396005, 56.847538],
            [39.413145, 56.851281],
            [39.400397, 56.86469],
            [39.44436, 56.869595],
            [39.458825, 56.857984],
            [39.484551, 56.874],
            [39.472727, 56.886691],
            [39.445761, 56.890415],
            [39.437352, 56.901626],
            [39.469288, 56.925113],
            [39.501841, 56.935981],
            [39.531866, 56.962599],
            [39.553421, 56.959924],
            [39.54414, 56.989185],
            [39.55533, 56.998764],
            [39.533913, 57.002793],
            [39.524104, 57.018293],
            [39.562769, 57.03501],
            [39.60581, 57.029405],
            [39.620565, 57.038587],
            [39.621221, 57.060488],
            [39.654902, 57.077999],
            [39.666814, 57.071994],
            [39.723018, 57.074697],
            [39.738382, 57.083506],
            [39.765078, 57.07658],
            [39.7572, 57.096218],
            [39.77375, 57.105298],
            [39.795387, 57.090914],
            [39.8097, 57.096119],
            [39.857583, 57.084842],
            [39.858897, 57.11754],
            [39.837557, 57.116277],
            [39.824378, 57.128063],
            [39.824462, 57.145667],
            [39.848047, 57.148263],
            [39.853334, 57.17419],
            [39.904223, 57.169569],
            [39.947938, 57.170057],
            [39.96247, 57.179829],
            [39.991697, 57.182867],
            [40.021669, 57.194743],
            [40.031508, 57.208731],
            [40.052497, 57.198999],
            [40.140513, 57.201084],
            [40.150221, 57.19083],
            [40.193111, 57.203873],
            [40.175128, 57.225073],
            [40.187722, 57.234192],
            [40.209622, 57.225632],
            [40.228748, 57.243559],
            [40.24353, 57.246199],
            [40.232559, 57.257483],
            [40.20697, 57.251954],
            [40.176423, 57.25601],
            [40.182932, 57.263417],
            [40.213795, 57.265247],
            [40.262173, 57.275349],
            [40.262508, 57.286251],
            [40.285098, 57.277301],
            [40.29796, 57.287611],
            [40.334816, 57.281308],
            [40.366431, 57.282945],
            [40.366886, 57.275179],
            [40.404191, 57.266173],
            [40.430464, 57.285829],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5546",
      properties: {
        name: "Ингушетия",
        density: 0,
        path: "/world/Russia/Ингушетия",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.847608, 43.56497],
            [44.867364, 43.558494],
            [44.874142, 43.534042],
            [44.901526, 43.519392],
            [44.909396, 43.527833],
            [44.955539, 43.519089],
            [44.975471, 43.529216],
            [45.016275, 43.522807],
            [45.023689, 43.512516],
            [45.066798, 43.508619],
            [45.078126, 43.48685],
            [45.089891, 43.482623],
            [45.059517, 43.477679],
            [45.067942, 43.451871],
            [45.100526, 43.450972],
            [45.131748, 43.418404],
            [45.158521, 43.411035],
            [45.14014, 43.400318],
            [45.122969, 43.360585],
            [45.135585, 43.346571],
            [45.124371, 43.342239],
            [45.124021, 43.324906],
            [45.104046, 43.322612],
            [45.112456, 43.295072],
            [45.114909, 43.262417],
            [45.133132, 43.227191],
            [45.131029, 43.197564],
            [45.149952, 43.17048],
            [45.149602, 43.149519],
            [45.17308, 43.150286],
            [45.189526, 43.137981],
            [45.192105, 43.116251],
            [45.180624, 43.095714],
            [45.195959, 43.097012],
            [45.179733, 43.059249],
            [45.193695, 43.036113],
            [45.183265, 43.008968],
            [45.193972, 42.977908],
            [45.214626, 42.970617],
            [45.214988, 42.94261],
            [45.246863, 42.93158],
            [45.2493, 42.909473],
            [45.242798, 42.890254],
            [45.216134, 42.876305],
            [45.206439, 42.85827],
            [45.169194, 42.82898],
            [45.199209, 42.824666],
            [45.197038, 42.804507],
            [45.183847, 42.795998],
            [45.176431, 42.780111],
            [45.149575, 42.776272],
            [45.141653, 42.764649],
            [45.117422, 42.752656],
            [45.089499, 42.727476],
            [45.086794, 42.712221],
            [45.052848, 42.69268],
            [45.038047, 42.6928],
            [45.03042, 42.704218],
            [45.011146, 42.713092],
            [44.983148, 42.736634],
            [44.979751, 42.744936],
            [44.945952, 42.750839],
            [44.887471, 42.747044],
            [44.869156, 42.724235],
            [44.863992, 42.68765],
            [44.848148, 42.672958],
            [44.836123, 42.674307],
            [44.830801, 42.647529],
            [44.823576, 42.641717],
            [44.829338, 42.618979],
            [44.803788, 42.61473],
            [44.785094, 42.632709],
            [44.766573, 42.642795],
            [44.74475, 42.705345],
            [44.751963, 42.71409],
            [44.737557, 42.728843],
            [44.710879, 42.743336],
            [44.699307, 42.743193],
            [44.678461, 42.756177],
            [44.665868, 42.774223],
            [44.666338, 42.798681],
            [44.658577, 42.811263],
            [44.664741, 42.831885],
            [44.646008, 42.843065],
            [44.645292, 42.85913],
            [44.630115, 42.883143],
            [44.633879, 42.913062],
            [44.673793, 42.890841],
            [44.701413, 42.884269],
            [44.700495, 42.858856],
            [44.73389, 42.852037],
            [44.75094, 42.843453],
            [44.777044, 42.859798],
            [44.795798, 42.860386],
            [44.830134, 42.840044],
            [44.853651, 42.835576],
            [44.856826, 42.853802],
            [44.869995, 42.860622],
            [44.862234, 42.881905],
            [44.87752, 42.883375],
            [44.882929, 42.912537],
            [44.911149, 42.921003],
            [44.907974, 42.93629],
            [44.927611, 42.931116],
            [44.953009, 42.946579],
            [44.954067, 42.977151],
            [44.943837, 42.981502],
            [44.934194, 43.001492],
            [44.933135, 43.019688],
            [44.956652, 43.031329],
            [44.959944, 43.045205],
            [44.915555, 43.07948],
            [44.903326, 43.096648],
            [44.855233, 43.107465],
            [44.834126, 43.118695],
            [44.846707, 43.119635],
            [44.831421, 43.13986],
            [44.798251, 43.146351],
            [44.796956, 43.166151],
            [44.773101, 43.1577],
            [44.697017, 43.270732],
            [44.669267, 43.278728],
            [44.667974, 43.234162],
            [44.680493, 43.233137],
            [44.679901, 43.210889],
            [44.653688, 43.219464],
            [44.620764, 43.220757],
            [44.568979, 43.234828],
            [44.573778, 43.309153],
            [44.577958, 43.416361],
            [44.533922, 43.417331],
            [44.511816, 43.40569],
            [44.486887, 43.434263],
            [44.480478, 43.451754],
            [44.480948, 43.473625],
            [44.493177, 43.472684],
            [44.488473, 43.498318],
            [44.479066, 43.513722],
            [44.502877, 43.52942],
            [44.508168, 43.543765],
            [44.501935, 43.557876],
            [44.503346, 43.587919],
            [44.63363, 43.614966],
            [44.622436, 43.548247],
            [44.653151, 43.54121],
            [44.677256, 43.550264],
            [44.707358, 43.550382],
            [44.696069, 43.594712],
            [44.797311, 43.594948],
            [44.799898, 43.56955],
            [44.789316, 43.554499],
            [44.828589, 43.551795],
            [44.847608, 43.56497],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5537",
      properties: {
        name: "Иркутская область",
        density: 0,
        path: "/world/Russia/Иркутская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [108.085664, 64.246468],
            [108.119211, 64.260729],
            [108.177868, 64.271764],
            [108.189293, 64.284289],
            [108.236827, 64.272134],
            [108.263828, 64.277356],
            [108.285489, 64.2698],
            [108.325514, 64.274124],
            [108.360864, 64.26464],
            [108.398389, 64.279697],
            [108.408728, 64.290331],
            [108.394859, 64.311035],
            [108.42834, 64.321545],
            [108.460006, 64.305409],
            [108.502827, 64.306291],
            [108.528984, 64.280703],
            [108.515734, 64.267948],
            [108.583116, 64.255198],
            [108.564356, 64.243236],
            [108.520916, 64.232464],
            [108.519782, 64.211751],
            [108.596893, 64.194121],
            [108.565096, 64.175885],
            [108.563997, 64.165214],
            [108.524723, 64.148757],
            [108.522791, 64.1346],
            [108.561741, 64.129611],
            [108.575507, 64.11608],
            [108.609358, 64.106627],
            [108.59588, 64.087527],
            [108.613007, 64.068797],
            [108.64482, 64.065031],
            [108.660205, 64.055075],
            [108.738807, 64.036193],
            [108.762471, 64.01662],
            [108.742761, 63.993893],
            [108.758917, 63.989195],
            [108.75017, 63.971412],
            [108.726077, 63.962167],
            [108.734659, 63.949464],
            [108.710601, 63.932133],
            [108.73945, 63.930677],
            [108.726626, 63.913474],
            [108.750462, 63.913036],
            [108.798344, 63.890804],
            [108.802638, 63.870719],
            [108.755276, 63.866311],
            [108.731835, 63.842014],
            [108.681176, 63.822215],
            [108.64789, 63.821812],
            [108.617179, 63.847725],
            [108.57553, 63.845315],
            [108.571351, 63.835731],
            [108.519852, 63.836164],
            [108.496466, 63.82235],
            [108.45756, 63.835292],
            [108.417107, 63.839107],
            [108.39144, 63.849517],
            [108.397535, 63.860962],
            [108.360629, 63.860993],
            [108.334732, 63.848223],
            [108.306324, 63.847093],
            [108.287333, 63.836652],
            [108.299295, 63.821584],
            [108.288525, 63.801924],
            [108.293075, 63.759155],
            [108.304045, 63.74393],
            [108.290108, 63.735989],
            [108.299315, 63.716051],
            [108.27279, 63.692947],
            [108.238237, 63.688203],
            [108.227865, 63.670016],
            [108.175527, 63.678133],
            [108.124333, 63.658146],
            [108.121532, 63.644839],
            [108.141358, 63.627116],
            [108.131411, 63.618065],
            [108.144004, 63.603945],
            [108.168998, 63.600662],
            [108.224163, 63.603415],
            [108.26406, 63.612614],
            [108.324941, 63.609137],
            [108.370605, 63.614787],
            [108.398593, 63.626607],
            [108.436178, 63.632487],
            [108.452815, 63.628641],
            [108.495058, 63.636415],
            [108.565959, 63.633721],
            [108.581849, 63.626194],
            [108.612839, 63.63243],
            [108.628232, 63.615876],
            [108.680051, 63.611704],
            [108.726957, 63.620063],
            [108.776065, 63.602552],
            [108.809476, 63.601787],
            [108.827793, 63.590065],
            [108.85561, 63.589498],
            [108.925639, 63.575649],
            [108.948255, 63.581924],
            [109.000071, 63.585065],
            [109.024534, 63.578966],
            [109.081238, 63.577065],
            [109.101878, 63.563812],
            [109.141663, 63.557673],
            [109.152264, 63.541669],
            [109.17121, 63.542724],
            [109.188828, 63.530199],
            [109.177315, 63.496168],
            [109.191372, 63.497659],
            [109.205101, 63.477932],
            [109.225679, 63.467811],
            [109.257929, 63.462989],
            [109.257791, 63.438851],
            [109.280412, 63.430266],
            [109.319332, 63.393414],
            [109.294618, 63.391306],
            [109.285972, 63.372478],
            [109.314958, 63.363457],
            [109.361415, 63.367573],
            [109.387816, 63.377348],
            [109.411673, 63.367869],
            [109.417675, 63.345739],
            [109.405326, 63.332638],
            [109.42835, 63.313726],
            [109.41587, 63.279973],
            [109.452144, 63.276225],
            [109.449538, 63.268754],
            [109.474685, 63.250419],
            [109.476166, 63.228486],
            [109.445411, 63.225706],
            [109.443339, 63.184794],
            [109.454443, 63.172254],
            [109.485233, 63.169677],
            [109.460137, 63.158812],
            [109.461507, 63.148064],
            [109.496928, 63.140758],
            [109.458555, 63.109201],
            [109.515634, 63.105548],
            [109.484731, 63.095885],
            [109.499467, 63.083403],
            [109.4881, 63.059914],
            [109.511788, 63.050358],
            [109.500157, 63.039547],
            [109.515371, 63.026988],
            [109.469752, 63.024098],
            [109.500371, 63.012955],
            [109.523396, 63.021319],
            [109.534118, 63.013659],
            [109.514489, 63.006081],
            [109.522663, 62.992972],
            [109.493619, 62.983241],
            [109.483979, 62.970701],
            [109.494455, 62.95639],
            [109.528362, 62.936629],
            [109.526804, 62.918123],
            [109.571428, 62.921499],
            [109.601607, 62.913438],
            [109.595097, 62.906037],
            [109.63932, 62.898698],
            [109.626582, 62.886566],
            [109.645487, 62.863578],
            [109.669882, 62.860569],
            [109.681311, 62.839964],
            [109.675732, 62.829442],
            [109.63627, 62.829144],
            [109.637146, 62.822334],
            [109.566645, 62.807156],
            [109.557787, 62.798641],
            [109.507987, 62.795014],
            [109.490232, 62.760373],
            [109.465187, 62.746661],
            [109.485845, 62.734106],
            [109.466491, 62.711588],
            [109.485329, 62.704266],
            [109.472373, 62.68541],
            [109.441231, 62.675398],
            [109.387625, 62.671617],
            [109.379037, 62.661495],
            [109.384026, 62.634933],
            [109.371361, 62.618261],
            [109.378151, 62.607404],
            [109.321039, 62.607872],
            [109.254805, 62.598724],
            [109.251916, 62.57675],
            [109.283197, 62.570145],
            [109.269589, 62.556171],
            [109.315182, 62.534728],
            [109.298355, 62.521629],
            [109.331384, 62.504491],
            [109.337635, 62.493533],
            [109.364208, 62.485329],
            [109.396881, 62.487216],
            [109.430854, 62.473591],
            [109.429148, 62.449974],
            [109.446225, 62.439617],
            [109.445182, 62.424841],
            [109.510465, 62.43065],
            [109.511885, 62.440061],
            [109.553325, 62.455069],
            [109.597643, 62.445939],
            [109.63906, 62.443911],
            [109.667902, 62.435392],
            [109.708819, 62.44252],
            [109.766392, 62.439608],
            [109.826772, 62.44538],
            [109.91093, 62.437021],
            [109.927115, 62.41604],
            [109.903973, 62.407934],
            [109.901879, 62.390456],
            [109.886796, 62.388369],
            [109.889907, 62.37053],
            [109.918012, 62.373167],
            [109.952515, 62.360993],
            [109.940476, 62.336908],
            [109.976218, 62.328178],
            [109.99532, 62.309658],
            [109.96541, 62.287118],
            [109.940637, 62.283957],
            [109.942542, 62.275217],
            [109.918588, 62.26006],
            [109.928832, 62.247467],
            [109.953945, 62.238388],
            [109.959688, 62.21826],
            [109.973639, 62.216203],
            [109.986581, 62.196525],
            [110.00455, 62.186047],
            [109.983661, 62.16212],
            [109.955671, 62.161926],
            [109.913799, 62.135983],
            [109.860159, 62.1096],
            [109.888904, 62.097735],
            [109.872613, 62.089061],
            [109.8721, 62.066979],
            [109.847397, 62.045839],
            [109.825956, 62.043834],
            [109.815796, 62.022757],
            [109.788918, 62.017958],
            [109.759877, 62.023455],
            [109.776145, 62.010131],
            [109.724054, 62.001212],
            [109.713407, 61.988741],
            [109.725438, 61.976609],
            [109.684185, 61.968145],
            [109.642256, 61.927687],
            [109.575309, 61.914704],
            [109.579281, 61.887328],
            [109.598866, 61.875907],
            [109.610922, 61.856408],
            [109.587859, 61.851233],
            [109.574538, 61.831156],
            [109.595105, 61.808043],
            [109.619117, 61.799681],
            [109.596726, 61.767658],
            [109.600187, 61.745473],
            [109.621593, 61.734282],
            [109.64819, 61.733501],
            [109.661904, 61.707276],
            [109.686193, 61.698058],
            [109.731829, 61.698271],
            [109.725983, 61.683838],
            [109.692799, 61.664631],
            [109.698127, 61.65606],
            [109.750185, 61.654497],
            [109.744946, 61.645636],
            [109.791498, 61.627234],
            [109.791757, 61.611161],
            [109.81912, 61.600738],
            [109.819822, 61.590004],
            [109.87049, 61.549351],
            [109.852766, 61.538084],
            [109.859293, 61.513695],
            [109.808911, 61.477883],
            [109.841329, 61.452756],
            [109.859936, 61.448522],
            [109.843192, 61.439957],
            [109.839155, 61.425168],
            [109.801233, 61.386175],
            [109.813556, 61.371162],
            [109.778996, 61.353524],
            [109.761796, 61.3317],
            [109.791678, 61.326304],
            [109.820886, 61.32866],
            [109.850701, 61.313317],
            [109.881907, 61.322966],
            [109.951507, 61.318418],
            [109.962238, 61.308802],
            [109.997213, 61.314717],
            [110.017394, 61.3002],
            [110.111251, 61.277886],
            [110.124805, 61.245059],
            [110.14079, 61.237072],
            [110.141577, 61.22151],
            [110.182385, 61.202514],
            [110.18179, 61.18617],
            [110.222093, 61.168251],
            [110.281989, 61.170505],
            [110.345216, 61.177064],
            [110.381295, 61.201345],
            [110.426312, 61.208414],
            [110.433001, 61.198562],
            [110.480688, 61.191158],
            [110.479851, 61.184227],
            [110.523942, 61.179692],
            [110.541449, 61.16427],
            [110.543107, 61.148485],
            [110.522813, 61.137411],
            [110.545392, 61.11666],
            [110.542292, 61.087795],
            [110.50083, 61.069968],
            [110.484787, 61.049727],
            [110.500269, 61.031415],
            [110.483167, 61.000681],
            [110.457916, 60.981627],
            [110.418913, 60.975645],
            [110.405495, 60.963744],
            [110.41589, 60.955051],
            [110.367768, 60.937577],
            [110.337218, 60.913736],
            [110.32481, 60.890717],
            [110.306785, 60.87417],
            [110.282638, 60.866969],
            [110.261865, 60.844633],
            [110.257592, 60.821647],
            [110.28296, 60.804044],
            [110.299794, 60.78213],
            [110.274962, 60.768111],
            [110.286167, 60.761539],
            [110.285677, 60.734238],
            [110.298469, 60.725578],
            [110.288624, 60.701981],
            [110.261457, 60.686626],
            [110.233532, 60.681354],
            [110.189047, 60.695944],
            [110.153486, 60.687679],
            [110.099332, 60.694398],
            [110.056796, 60.683279],
            [110.104821, 60.672408],
            [110.089865, 60.666565],
            [110.094435, 60.654072],
            [110.126216, 60.648095],
            [110.134587, 60.632181],
            [110.122694, 60.62139],
            [110.138172, 60.59693],
            [110.098306, 60.585129],
            [110.073888, 60.589096],
            [110.040121, 60.581271],
            [110.057321, 60.569849],
            [110.022251, 60.533268],
            [109.949669, 60.504864],
            [109.9248, 60.473833],
            [109.907127, 60.461395],
            [109.953373, 60.439926],
            [109.948778, 60.41689],
            [109.927097, 60.409603],
            [109.92554, 60.398534],
            [109.894707, 60.389439],
            [109.849731, 60.390969],
            [109.847149, 60.372024],
            [109.870997, 60.370794],
            [109.881641, 60.358443],
            [109.833218, 60.339688],
            [109.804651, 60.336441],
            [109.790777, 60.318494],
            [109.813265, 60.306672],
            [109.815246, 60.273757],
            [109.791317, 60.254336],
            [109.765509, 60.247294],
            [109.740317, 60.248555],
            [109.722853, 60.232044],
            [109.733636, 60.215595],
            [109.707514, 60.206889],
            [109.718438, 60.183118],
            [109.714598, 60.170523],
            [109.694643, 60.161238],
            [109.692776, 60.125608],
            [109.714113, 60.123064],
            [109.699399, 60.107703],
            [109.692598, 60.076711],
            [109.713836, 60.07286],
            [109.705972, 60.052794],
            [109.752078, 60.05239],
            [109.755081, 60.026996],
            [109.699774, 59.994889],
            [109.67474, 59.986677],
            [109.666365, 59.939082],
            [109.621253, 59.937818],
            [109.621352, 59.918122],
            [109.649978, 59.905096],
            [109.644325, 59.896015],
            [109.662596, 59.876033],
            [109.635339, 59.865575],
            [109.625284, 59.853674],
            [109.563324, 59.844239],
            [109.567703, 59.834807],
            [109.544459, 59.816041],
            [109.508123, 59.801529],
            [109.489046, 59.772083],
            [109.474766, 59.767633],
            [109.484454, 59.751955],
            [109.473381, 59.742339],
            [109.475642, 59.721383],
            [109.490698, 59.704568],
            [109.488587, 59.6862],
            [109.508243, 59.689748],
            [109.541292, 59.677482],
            [109.543439, 59.667295],
            [109.488318, 59.629131],
            [109.469828, 59.624351],
            [109.401759, 59.584365],
            [109.400384, 59.567578],
            [109.362492, 59.548386],
            [109.339252, 59.546245],
            [109.327877, 59.531574],
            [109.33398, 59.510732],
            [109.301102, 59.500696],
            [109.252271, 59.466906],
            [109.299961, 59.442145],
            [109.342513, 59.44246],
            [109.351645, 59.42901],
            [109.342886, 59.417168],
            [109.282946, 59.398155],
            [109.282681, 59.387755],
            [109.240394, 59.390863],
            [109.239804, 59.381485],
            [109.274728, 59.351495],
            [109.245004, 59.333282],
            [109.291664, 59.316525],
            [109.313819, 59.322394],
            [109.329543, 59.316102],
            [109.379609, 59.315437],
            [109.400094, 59.304693],
            [109.4526, 59.313018],
            [109.506285, 59.308076],
            [109.48516, 59.293458],
            [109.491523, 59.268551],
            [109.479278, 59.252317],
            [109.524361, 59.247185],
            [109.536504, 59.235458],
            [109.523843, 59.225786],
            [109.509493, 59.192734],
            [109.580476, 59.184238],
            [109.579928, 59.170634],
            [109.600108, 59.172478],
            [109.630401, 59.16426],
            [109.621544, 59.14417],
            [109.634199, 59.122181],
            [109.613595, 59.100666],
            [109.617505, 59.083333],
            [109.642656, 59.060777],
            [109.6556, 59.072746],
            [109.699021, 59.091942],
            [109.727389, 59.090552],
            [109.752613, 59.058986],
            [109.757196, 59.037041],
            [109.811944, 59.004253],
            [109.821884, 58.988769],
            [109.847911, 58.991841],
            [109.851127, 59.003205],
            [109.910673, 59.019581],
            [109.982887, 59.024971],
            [110.002585, 59.018775],
            [110.023501, 59.001598],
            [110.086257, 59.002417],
            [110.108643, 58.990821],
            [110.141044, 58.995427],
            [110.158248, 58.980934],
            [110.239422, 58.999926],
            [110.263097, 58.991048],
            [110.309698, 59.009021],
            [110.317668, 59.025479],
            [110.348347, 59.041594],
            [110.399319, 59.057215],
            [110.474673, 59.046366],
            [110.498522, 59.058927],
            [110.541325, 59.092033],
            [110.526121, 59.106793],
            [110.545461, 59.127576],
            [110.579649, 59.127],
            [110.603614, 59.140373],
            [110.587617, 59.159461],
            [110.548603, 59.183345],
            [110.574234, 59.190401],
            [110.574622, 59.204138],
            [110.641581, 59.218745],
            [110.634577, 59.246616],
            [110.673439, 59.264988],
            [110.720542, 59.280902],
            [110.809481, 59.266828],
            [110.826164, 59.273226],
            [110.859243, 59.272094],
            [110.856772, 59.261129],
            [110.949402, 59.247444],
            [110.983503, 59.23045],
            [110.983064, 59.216713],
            [111.042328, 59.222514],
            [111.071206, 59.240463],
            [111.064697, 59.254169],
            [111.093641, 59.256028],
            [111.10927, 59.243321],
            [111.102422, 59.236257],
            [111.134678, 59.213351],
            [111.172184, 59.211331],
            [111.180048, 59.203451],
            [111.217729, 59.203733],
            [111.234806, 59.229778],
            [111.250744, 59.241878],
            [111.285046, 59.244389],
            [111.267917, 59.258086],
            [111.297237, 59.277575],
            [111.3344, 59.277732],
            [111.381514, 59.288708],
            [111.39968, 59.283761],
            [111.41472, 59.290645],
            [111.451149, 59.292353],
            [111.467346, 59.282609],
            [111.494803, 59.285527],
            [111.507296, 59.255457],
            [111.560631, 59.23519],
            [111.587306, 59.239021],
            [111.616265, 59.226461],
            [111.649821, 59.223848],
            [111.718216, 59.257567],
            [111.790191, 59.29798],
            [111.844647, 59.290646],
            [111.871352, 59.301691],
            [111.898915, 59.303685],
            [111.941094, 59.278543],
            [111.975872, 59.319657],
            [111.959563, 59.338655],
            [112.000682, 59.357486],
            [112.040207, 59.383011],
            [112.078895, 59.392533],
            [112.097631, 59.416611],
            [112.093749, 59.428747],
            [112.148501, 59.464562],
            [112.178247, 59.470555],
            [112.201259, 59.491584],
            [112.199298, 59.504334],
            [112.251292, 59.5284],
            [112.273681, 59.531504],
            [112.301088, 59.524185],
            [112.281919, 59.502896],
            [112.286398, 59.482966],
            [112.336859, 59.439098],
            [112.340591, 59.425588],
            [112.275442, 59.373139],
            [112.302841, 59.364742],
            [112.305718, 59.345079],
            [112.322275, 59.334186],
            [112.280752, 59.318039],
            [112.268992, 59.305639],
            [112.279031, 59.299172],
            [112.313654, 59.315351],
            [112.368609, 59.310569],
            [112.419849, 59.335736],
            [112.429447, 59.330534],
            [112.455825, 59.341127],
            [112.504409, 59.334328],
            [112.521975, 59.348222],
            [112.566509, 59.335847],
            [112.569536, 59.316706],
            [112.590147, 59.325879],
            [112.635479, 59.323992],
            [112.651461, 59.313623],
            [112.643807, 59.281264],
            [112.628966, 59.270745],
            [112.601928, 59.274901],
            [112.56316, 59.255757],
            [112.539127, 59.254534],
            [112.525441, 59.23851],
            [112.505704, 59.238225],
            [112.50087, 59.220552],
            [112.486702, 59.214334],
            [112.465957, 59.186606],
            [112.462859, 59.158156],
            [112.448434, 59.153263],
            [112.447849, 59.136239],
            [112.464359, 59.113039],
            [112.459172, 59.094074],
            [112.468122, 59.082522],
            [112.438764, 59.070083],
            [112.435639, 59.055174],
            [112.460588, 59.030472],
            [112.434783, 59.010194],
            [112.461634, 58.995335],
            [112.446791, 58.979136],
            [112.441539, 58.918129],
            [112.479134, 58.891077],
            [112.524731, 58.894082],
            [112.543017, 58.922834],
            [112.568271, 58.943242],
            [112.589706, 58.944663],
            [112.578243, 58.962816],
            [112.599206, 58.992301],
            [112.555207, 59.01189],
            [112.51428, 59.044106],
            [112.514623, 59.060429],
            [112.531626, 59.075065],
            [112.605654, 59.107824],
            [112.619391, 59.090908],
            [112.594487, 59.076389],
            [112.614909, 59.056752],
            [112.608183, 59.048381],
            [112.627223, 59.042901],
            [112.650495, 59.051722],
            [112.706568, 59.054407],
            [112.730105, 59.047867],
            [112.751869, 59.051929],
            [112.749709, 59.091973],
            [112.805138, 59.126996],
            [112.844225, 59.127371],
            [112.851254, 59.121095],
            [112.886195, 59.13066],
            [112.891904, 59.142783],
            [112.931538, 59.156883],
            [112.962675, 59.153892],
            [112.964304, 59.141975],
            [113.02739, 59.145079],
            [113.058007, 59.167939],
            [113.078061, 59.165254],
            [113.109606, 59.150801],
            [113.161002, 59.183913],
            [113.201358, 59.187265],
            [113.239238, 59.161153],
            [113.277556, 59.158104],
            [113.332701, 59.191033],
            [113.33897, 59.205674],
            [113.32757, 59.218052],
            [113.356255, 59.227663],
            [113.374692, 59.223066],
            [113.39303, 59.241639],
            [113.429053, 59.247773],
            [113.436636, 59.270746],
            [113.456647, 59.276604],
            [113.465212, 59.265593],
            [113.485608, 59.284619],
            [113.461359, 59.308064],
            [113.500317, 59.327844],
            [113.532065, 59.349913],
            [113.513904, 59.360903],
            [113.432036, 59.37874],
            [113.431376, 59.40231],
            [113.451648, 59.409828],
            [113.463385, 59.432292],
            [113.503324, 59.45906],
            [113.523024, 59.45717],
            [113.529518, 59.47675],
            [113.561228, 59.494312],
            [113.584552, 59.496414],
            [113.614501, 59.515156],
            [113.607031, 59.545576],
            [113.626506, 59.562263],
            [113.596278, 59.577466],
            [113.5913, 59.594758],
            [113.652561, 59.614768],
            [113.684235, 59.615525],
            [113.706531, 59.630044],
            [113.736447, 59.62762],
            [113.761156, 59.617057],
            [113.795431, 59.627648],
            [113.814375, 59.643095],
            [113.856518, 59.650689],
            [113.883414, 59.678075],
            [113.874841, 59.686248],
            [113.913864, 59.694279],
            [113.925864, 59.682163],
            [113.969705, 59.690922],
            [113.99071, 59.701724],
            [113.983193, 59.709355],
            [114.006912, 59.719191],
            [114.000474, 59.730389],
            [114.04531, 59.741771],
            [114.06274, 59.755741],
            [114.096977, 59.76525],
            [114.150321, 59.745556],
            [114.156466, 59.758813],
            [114.196052, 59.781074],
            [114.216725, 59.784636],
            [114.230564, 59.819407],
            [114.227656, 59.837069],
            [114.288854, 59.854491],
            [114.30032, 59.875797],
            [114.297153, 59.890731],
            [114.334394, 59.913428],
            [114.35555, 59.914127],
            [114.426677, 59.949203],
            [114.449414, 59.945349],
            [114.497457, 59.97219],
            [114.529526, 59.983615],
            [114.573376, 59.989086],
            [114.556467, 60.008393],
            [114.559409, 60.022594],
            [114.53565, 60.020618],
            [114.49177, 60.034572],
            [114.496649, 60.069566],
            [114.546082, 60.082723],
            [114.555543, 60.101175],
            [114.547111, 60.107172],
            [114.565087, 60.130926],
            [114.599837, 60.1443],
            [114.621711, 60.144433],
            [114.656844, 60.155313],
            [114.651151, 60.179064],
            [114.719238, 60.195522],
            [114.774705, 60.202535],
            [114.821046, 60.200335],
            [114.825752, 60.185261],
            [114.84814, 60.192142],
            [114.906236, 60.184778],
            [114.911595, 60.194489],
            [114.939869, 60.2048],
            [114.986751, 60.214531],
            [115.023475, 60.236097],
            [115.032908, 60.263513],
            [115.077809, 60.283546],
            [115.073852, 60.305342],
            [115.117234, 60.326021],
            [115.107652, 60.340613],
            [115.145207, 60.365985],
            [115.117581, 60.37775],
            [115.096025, 60.413232],
            [115.112914, 60.430798],
            [115.146563, 60.44456],
            [115.211848, 60.460481],
            [115.22385, 60.470391],
            [115.212785, 60.490171],
            [115.259912, 60.499393],
            [115.300825, 60.513756],
            [115.332884, 60.507298],
            [115.340499, 60.496998],
            [115.369457, 60.491911],
            [115.417318, 60.496469],
            [115.456402, 60.515425],
            [115.496886, 60.512871],
            [115.594673, 60.535918],
            [115.639685, 60.533377],
            [115.679674, 60.539498],
            [115.696606, 60.549035],
            [115.749481, 60.546659],
            [115.767473, 60.541896],
            [115.758977, 60.521309],
            [115.805734, 60.529058],
            [115.812823, 60.520504],
            [115.872447, 60.523097],
            [115.885956, 60.508793],
            [115.867206, 60.507642],
            [115.863649, 60.496064],
            [115.904058, 60.491589],
            [115.937639, 60.45355],
            [116.005434, 60.469058],
            [116.019852, 60.459397],
            [116.042146, 60.461727],
            [116.084679, 60.456175],
            [116.088981, 60.449995],
            [116.138555, 60.433993],
            [116.167005, 60.429603],
            [116.17791, 60.41734],
            [116.173857, 60.39729],
            [116.1839, 60.394096],
            [116.291066, 60.409652],
            [116.316204, 60.398011],
            [116.324623, 60.374726],
            [116.355831, 60.371132],
            [116.369779, 60.377006],
            [116.464281, 60.386435],
            [116.540974, 60.389983],
            [116.559706, 60.382033],
            [116.594034, 60.382068],
            [116.602968, 60.36447],
            [116.623588, 60.349739],
            [116.6284, 60.319366],
            [116.608546, 60.295133],
            [116.648943, 60.283744],
            [116.688805, 60.289319],
            [116.759277, 60.27959],
            [116.774809, 60.269127],
            [116.770875, 60.249365],
            [116.791288, 60.240082],
            [116.822579, 60.236478],
            [116.829859, 60.226992],
            [116.879451, 60.205201],
            [116.921234, 60.20398],
            [116.937166, 60.19646],
            [116.925493, 60.170317],
            [116.941196, 60.146179],
            [116.972894, 60.139372],
            [117.0036, 60.118831],
            [117.012285, 60.100972],
            [117.00022, 60.091751],
            [116.988643, 60.048908],
            [117.019565, 60.049938],
            [117.103804, 60.027719],
            [117.130037, 60.02756],
            [117.178079, 60.039178],
            [117.181297, 60.049392],
            [117.21826, 60.054401],
            [117.254433, 60.051843],
            [117.264106, 60.057423],
            [117.300025, 60.048145],
            [117.34719, 60.024205],
            [117.35563, 60.011623],
            [117.338736, 60.006315],
            [117.292275, 60.00819],
            [117.246186, 60.001987],
            [117.238857, 59.993891],
            [117.154029, 59.973846],
            [117.132947, 59.980982],
            [117.105949, 59.972504],
            [117.077473, 59.972628],
            [117.056816, 59.951718],
            [117.02769, 59.947088],
            [117.008568, 59.916842],
            [117.046649, 59.905568],
            [117.077015, 59.888991],
            [117.096402, 59.869285],
            [117.147443, 59.8671],
            [117.204236, 59.87589],
            [117.223793, 59.874169],
            [117.210268, 59.846182],
            [117.229302, 59.809118],
            [117.208511, 59.786204],
            [117.232207, 59.773634],
            [117.222096, 59.767258],
            [117.228182, 59.750281],
            [117.209486, 59.729696],
            [117.175226, 59.715202],
            [117.199169, 59.696965],
            [117.205544, 59.681404],
            [117.170165, 59.658293],
            [117.145768, 59.65392],
            [117.160091, 59.634432],
            [117.112064, 59.609812],
            [117.162368, 59.589584],
            [117.193248, 59.582705],
            [117.218229, 59.561117],
            [117.211949, 59.542428],
            [117.24934, 59.513227],
            [117.297984, 59.526496],
            [117.346171, 59.512934],
            [117.382657, 59.520427],
            [117.438608, 59.525192],
            [117.460532, 59.52151],
            [117.497941, 59.492639],
            [117.569694, 59.477549],
            [117.596393, 59.48191],
            [117.583062, 59.508223],
            [117.611619, 59.523644],
            [117.646238, 59.530348],
            [117.645175, 59.540614],
            [117.717561, 59.557717],
            [117.77047, 59.565891],
            [117.783308, 59.558303],
            [117.796426, 59.495233],
            [117.808439, 59.466853],
            [117.826314, 59.445463],
            [117.873709, 59.463277],
            [117.910902, 59.450702],
            [117.930158, 59.482032],
            [117.968651, 59.497344],
            [117.956534, 59.527564],
            [117.938673, 59.54007],
            [117.954052, 59.555488],
            [117.980631, 59.557683],
            [118.03129, 59.575435],
            [118.021936, 59.598405],
            [118.033995, 59.629318],
            [118.052115, 59.642408],
            [118.070093, 59.625059],
            [118.106235, 59.62572],
            [118.121665, 59.640941],
            [118.159317, 59.630118],
            [118.239862, 59.640404],
            [118.244631, 59.650811],
            [118.275653, 59.634492],
            [118.319667, 59.633078],
            [118.346144, 59.615041],
            [118.356952, 59.573275],
            [118.361812, 59.525624],
            [118.405667, 59.510095],
            [118.437345, 59.507868],
            [118.46811, 59.496393],
            [118.496482, 59.498592],
            [118.508431, 59.490409],
            [118.539956, 59.485821],
            [118.553514, 59.471432],
            [118.58667, 59.460755],
            [118.589584, 59.450808],
            [118.614056, 59.461164],
            [118.62646, 59.448701],
            [118.6773, 59.437983],
            [118.737944, 59.435893],
            [118.761284, 59.428266],
            [118.747832, 59.392291],
            [118.763448, 59.370909],
            [118.7488, 59.345128],
            [118.817384, 59.329585],
            [118.836142, 59.317371],
            [118.792598, 59.302133],
            [118.802725, 59.291277],
            [118.801678, 59.270481],
            [118.76521, 59.274538],
            [118.755055, 59.262437],
            [118.733112, 59.255702],
            [118.734139, 59.243543],
            [118.713848, 59.236206],
            [118.698895, 59.213715],
            [118.670814, 59.186608],
            [118.689436, 59.172683],
            [118.689244, 59.150897],
            [118.711372, 59.117735],
            [118.71524, 59.080634],
            [118.696272, 59.057558],
            [118.713828, 59.049824],
            [118.75967, 59.053004],
            [118.819188, 59.050707],
            [118.840954, 59.03677],
            [118.839269, 59.018397],
            [118.872731, 58.991417],
            [118.873087, 58.967517],
            [118.861275, 58.952058],
            [118.827697, 58.946925],
            [118.799529, 58.958486],
            [118.763634, 58.950214],
            [118.748988, 58.934514],
            [118.766741, 58.895001],
            [118.816317, 58.872379],
            [118.833914, 58.870459],
            [118.8448, 58.849208],
            [118.86805, 58.835519],
            [118.87273, 58.820487],
            [118.838993, 58.774902],
            [118.853443, 58.756212],
            [118.861725, 58.731207],
            [118.831637, 58.693784],
            [118.833945, 58.676304],
            [118.81002, 58.644614],
            [118.799202, 58.638691],
            [118.765925, 58.645577],
            [118.754963, 58.629187],
            [118.776802, 58.611387],
            [118.820862, 58.600121],
            [118.850878, 58.603875],
            [118.866504, 58.596555],
            [118.863517, 58.577428],
            [118.887996, 58.569793],
            [118.920096, 58.56848],
            [118.950581, 58.579385],
            [118.962044, 58.566428],
            [118.991886, 58.56155],
            [118.992228, 58.547655],
            [119.016512, 58.52748],
            [119.038428, 58.530943],
            [119.084596, 58.518191],
            [119.120441, 58.516282],
            [119.131251, 58.487889],
            [119.105559, 58.422259],
            [119.063283, 58.363496],
            [119.08043, 58.34898],
            [119.076844, 58.339019],
            [119.1178, 58.325076],
            [119.138674, 58.292535],
            [119.123641, 58.284831],
            [119.133159, 58.273635],
            [119.116842, 58.238128],
            [119.088233, 58.237593],
            [119.079978, 58.248219],
            [119.045236, 58.254811],
            [119.008901, 58.246963],
            [118.985721, 58.233837],
            [118.944694, 58.228867],
            [118.885579, 58.231353],
            [118.860349, 58.22494],
            [118.807119, 58.230418],
            [118.787523, 58.225448],
            [118.76445, 58.206659],
            [118.714539, 58.186565],
            [118.697708, 58.188878],
            [118.680026, 58.178824],
            [118.602708, 58.215258],
            [118.585969, 58.214944],
            [118.57685, 58.232497],
            [118.515479, 58.23484],
            [118.474681, 58.229584],
            [118.476253, 58.257591],
            [118.448681, 58.277886],
            [118.402656, 58.280486],
            [118.348169, 58.320805],
            [118.33413, 58.354104],
            [118.307108, 58.355361],
            [118.27498, 58.388596],
            [118.254371, 58.397679],
            [118.197741, 58.395937],
            [118.120773, 58.400229],
            [118.088929, 58.366809],
            [118.072476, 58.367609],
            [118.05241, 58.380356],
            [118.043284, 58.409285],
            [118.001543, 58.423339],
            [117.938344, 58.414113],
            [117.92479, 58.424524],
            [117.907329, 58.413456],
            [117.863125, 58.412028],
            [117.856227, 58.426581],
            [117.830854, 58.436308],
            [117.805282, 58.427703],
            [117.795369, 58.41522],
            [117.723272, 58.413421],
            [117.669877, 58.425561],
            [117.672319, 58.406302],
            [117.643261, 58.391084],
            [117.620009, 58.393912],
            [117.62298, 58.373196],
            [117.586431, 58.361484],
            [117.566121, 58.361313],
            [117.531693, 58.345146],
            [117.513661, 58.344453],
            [117.472249, 58.326015],
            [117.464422, 58.311983],
            [117.486067, 58.28761],
            [117.528778, 58.286896],
            [117.539447, 58.270436],
            [117.527678, 58.25781],
            [117.561777, 58.250169],
            [117.54978, 58.232395],
            [117.594369, 58.184642],
            [117.589084, 58.154578],
            [117.576972, 58.14498],
            [117.562568, 58.149494],
            [117.506653, 58.152829],
            [117.467133, 58.163676],
            [117.450837, 58.175202],
            [117.422322, 58.159685],
            [117.411274, 58.14371],
            [117.423843, 58.128221],
            [117.404419, 58.123508],
            [117.432976, 58.105505],
            [117.405996, 58.091987],
            [117.396284, 58.079562],
            [117.405703, 58.045956],
            [117.400525, 58.017548],
            [117.37973, 58.018834],
            [117.39314, 57.978851],
            [117.383457, 57.96659],
            [117.382514, 57.94124],
            [117.426309, 57.944075],
            [117.431708, 57.929893],
            [117.403765, 57.915719],
            [117.409278, 57.911606],
            [117.353057, 57.902287],
            [117.367538, 57.890262],
            [117.346079, 57.864469],
            [117.313738, 57.862241],
            [117.277283, 57.878008],
            [117.262487, 57.863955],
            [117.225682, 57.85785],
            [117.170504, 57.85408],
            [117.119853, 57.860793],
            [117.095745, 57.835807],
            [117.076521, 57.826745],
            [117.094945, 57.815662],
            [117.099101, 57.79806],
            [117.132092, 57.789734],
            [117.153143, 57.794818],
            [117.157156, 57.772467],
            [117.181607, 57.769668],
            [117.181378, 57.74686],
            [117.159127, 57.74029],
            [117.176957, 57.707657],
            [117.143801, 57.692597],
            [117.157312, 57.682028],
            [117.140152, 57.665354],
            [117.139095, 57.648416],
            [117.156412, 57.648702],
            [117.175807, 57.662241],
            [117.213525, 57.653514],
            [117.24341, 57.635991],
            [117.274944, 57.631592],
            [117.290376, 57.638418],
            [117.335114, 57.634848],
            [117.311298, 57.59633],
            [117.332821, 57.586718],
            [117.342754, 57.560347],
            [117.283491, 57.560697],
            [117.254805, 57.537425],
            [117.272943, 57.525571],
            [117.270615, 57.507226],
            [117.288239, 57.494451],
            [117.280126, 57.48304],
            [117.2979, 57.472415],
            [117.32575, 57.478756],
            [117.33896, 57.467123],
            [117.333697, 57.454405],
            [117.350892, 57.436574],
            [117.33444, 57.43089],
            [117.351256, 57.397057],
            [117.336431, 57.389802],
            [117.344629, 57.364109],
            [117.372771, 57.343186],
            [117.390566, 57.344757],
            [117.426807, 57.367179],
            [117.444402, 57.36735],
            [117.451929, 57.348377],
            [117.432704, 57.30284],
            [117.417365, 57.291465],
            [117.43706, 57.282681],
            [117.449871, 57.259616],
            [117.480784, 57.257667],
            [117.48464, 57.269063],
            [117.540218, 57.271448],
            [117.552351, 57.292342],
            [117.576416, 57.302175],
            [117.566562, 57.345692],
            [117.587371, 57.354518],
            [117.624161, 57.324861],
            [117.675405, 57.317185],
            [117.724406, 57.315756],
            [117.73646, 57.324668],
            [117.768173, 57.325154],
            [117.779141, 57.318355],
            [117.779848, 57.292063],
            [117.761424, 57.285379],
            [117.772521, 57.264121],
            [117.752533, 57.253545],
            [117.754789, 57.237599],
            [117.720991, 57.209258],
            [117.702103, 57.19996],
            [117.636306, 57.196404],
            [117.626737, 57.185179],
            [117.606221, 57.181252],
            [117.576207, 57.165135],
            [117.596444, 57.146247],
            [117.620266, 57.137907],
            [117.614032, 57.118276],
            [117.642338, 57.100396],
            [117.658077, 57.105166],
            [117.675637, 57.08947],
            [117.657526, 57.051409],
            [117.680006, 57.043754],
            [117.677128, 57.027251],
            [117.653077, 57.018997],
            [117.653341, 56.996939],
            [117.629226, 56.972867],
            [117.578675, 56.957064],
            [117.547597, 56.954465],
            [117.560857, 56.924566],
            [117.576667, 56.915954],
            [117.569983, 56.870267],
            [117.560692, 56.865304],
            [117.531786, 56.871503],
            [117.527351, 56.882685],
            [117.487155, 56.891133],
            [117.461033, 56.876188],
            [117.464032, 56.84636],
            [117.451228, 56.836292],
            [117.431119, 56.83552],
            [117.416195, 56.851673],
            [117.402941, 56.845418],
            [117.378298, 56.884364],
            [117.348163, 56.891305],
            [117.350371, 56.936971],
            [117.332326, 56.948811],
            [117.337553, 56.959722],
            [117.320665, 56.965178],
            [117.299941, 56.940799],
            [117.27569, 56.939171],
            [117.277568, 56.926881],
            [117.229402, 56.914035],
            [117.17628, 56.892906],
            [117.158235, 56.878588],
            [117.152186, 56.858722],
            [117.161698, 56.840513],
            [117.120701, 56.833394],
            [117.109925, 56.818977],
            [117.09393, 56.830116],
            [117.011159, 56.826061],
            [116.994449, 56.819634],
            [116.999847, 56.809258],
            [117.025412, 56.807916],
            [117.018756, 56.789849],
            [116.970648, 56.801782],
            [116.948732, 56.791906],
            [116.888734, 56.783016],
            [116.808769, 56.757131],
            [116.799821, 56.74994],
            [116.775685, 56.752282],
            [116.771279, 56.744442],
            [116.742915, 56.738758],
            [116.736959, 56.753361],
            [116.723249, 56.755532],
            [116.697206, 56.779882],
            [116.669099, 56.772684],
            [116.663493, 56.75901],
            [116.626782, 56.760495],
            [116.615899, 56.76895],
            [116.577216, 56.765551],
            [116.569954, 56.773349],
            [116.520988, 56.773756],
            [116.516433, 56.793758],
            [116.496952, 56.806183],
            [116.466918, 56.813295],
            [116.419602, 56.815081],
            [116.399264, 56.831819],
            [116.370665, 56.835868],
            [116.350471, 56.86431],
            [116.316016, 56.87033],
            [116.291415, 56.854892],
            [116.270478, 56.858034],
            [116.26415, 56.844702],
            [116.230002, 56.829235],
            [116.194512, 56.832977],
            [116.184629, 56.840146],
            [116.162292, 56.830435],
            [116.115518, 56.819059],
            [116.098858, 56.829699],
            [116.080663, 56.826272],
            [116.06274, 56.850394],
            [116.028478, 56.861091],
            [116.017216, 56.879636],
            [116.000364, 56.870438],
            [115.978505, 56.87081],
            [115.950349, 56.89009],
            [115.922156, 56.895575],
            [115.903847, 56.914662],
            [115.856481, 56.929358],
            [115.828917, 56.946525],
            [115.794605, 56.951095],
            [115.756437, 56.961793],
            [115.729827, 56.962428],
            [115.752414, 56.978926],
            [115.873544, 57.012569],
            [115.90276, 57.014799],
            [115.935983, 57.03225],
            [115.971792, 57.031604],
            [115.993898, 57.042236],
            [116.045866, 57.046631],
            [116.078961, 57.068801],
            [116.099516, 57.095463],
            [116.146054, 57.11075],
            [116.184061, 57.103898],
            [116.213794, 57.107905],
            [116.251319, 57.121489],
            [116.250508, 57.138381],
            [116.231828, 57.156512],
            [116.214699, 57.161117],
            [116.176952, 57.188652],
            [116.173139, 57.218078],
            [116.15504, 57.219774],
            [116.152067, 57.230633],
            [116.113673, 57.235174],
            [116.107856, 57.241266],
            [116.067458, 57.242494],
            [116.047873, 57.237905],
            [116.02777, 57.223039],
            [115.996034, 57.227758],
            [115.993707, 57.239473],
            [115.963198, 57.248942],
            [115.914527, 57.228679],
            [115.888543, 57.239942],
            [115.845042, 57.24828],
            [115.811495, 57.212634],
            [115.780276, 57.214702],
            [115.756101, 57.205071],
            [115.766249, 57.186359],
            [115.74104, 57.16406],
            [115.710855, 57.162121],
            [115.653909, 57.12056],
            [115.637298, 57.133552],
            [115.599873, 57.136008],
            [115.539372, 57.108054],
            [115.485587, 57.11249],
            [115.449259, 57.11045],
            [115.432524, 57.102695],
            [115.399789, 57.107838],
            [115.358563, 57.09796],
            [115.329419, 57.096246],
            [115.282561, 57.105389],
            [115.267213, 57.10294],
            [115.256601, 57.119593],
            [115.216845, 57.118777],
            [115.200681, 57.105798],
            [115.16117, 57.117063],
            [115.144843, 57.110369],
            [115.101495, 57.107757],
            [115.069984, 57.112084],
            [115.039207, 57.076798],
            [115.05684, 57.069328],
            [115.049737, 57.059206],
            [114.988184, 57.033512],
            [115.020185, 57.019552],
            [115.06688, 57.010205],
            [115.082717, 56.98249],
            [115.071614, 56.972939],
            [115.033001, 56.975796],
            [115.005653, 56.971041],
            [114.986061, 56.941143],
            [114.972591, 56.943551],
            [114.952182, 56.923674],
            [114.933733, 56.926123],
            [114.914467, 56.911919],
            [114.927201, 56.903388],
            [114.930875, 56.877347],
            [114.91667, 56.868591],
            [114.885404, 56.870061],
            [114.879199, 56.854183],
            [114.854872, 56.840122],
            [114.829728, 56.816326],
            [114.843198, 56.800366],
            [114.831034, 56.781101],
            [114.839769, 56.761427],
            [114.816911, 56.731121],
            [114.786379, 56.726202],
            [114.793318, 56.708549],
            [114.777154, 56.707161],
            [114.767439, 56.686957],
            [114.748582, 56.700529],
            [114.704499, 56.696284],
            [114.638456, 56.695794],
            [114.635191, 56.684815],
            [114.61168, 56.673304],
            [114.580577, 56.679345],
            [114.573067, 56.688407],
            [114.49633, 56.703305],
            [114.490534, 56.68955],
            [114.504411, 56.670447],
            [114.453879, 56.649264],
            [114.423021, 56.643713],
            [114.40596, 56.66804],
            [114.346367, 56.681203],
            [114.319835, 56.679816],
            [114.27665, 56.69102],
            [114.232894, 56.675591],
            [114.186689, 56.691775],
            [114.157627, 56.697082],
            [114.13779, 56.689245],
            [114.145708, 56.678551],
            [114.120891, 56.672428],
            [114.099584, 56.640408],
            [114.09689, 56.623428],
            [114.075664, 56.606652],
            [114.021051, 56.600428],
            [113.968396, 56.577224],
            [114.001131, 56.562754],
            [113.962926, 56.547774],
            [113.925864, 56.543693],
            [113.910843, 56.532142],
            [113.89272, 56.532387],
            [113.85827, 56.520305],
            [113.83231, 56.526754],
            [113.811738, 56.539346],
            [113.823249, 56.542938],
            [113.81533, 56.565755],
            [113.78733, 56.575877],
            [113.801534, 56.589041],
            [113.788065, 56.613572],
            [113.772717, 56.613653],
            [113.756391, 56.625674],
            [113.7439, 56.614654],
            [113.690756, 56.624205],
            [113.64602, 56.642675],
            [113.619407, 56.642511],
            [113.590771, 56.665335],
            [113.557077, 56.671414],
            [113.498507, 56.672471],
            [113.496386, 56.684807],
            [113.465105, 56.671585],
            [113.42781, 56.645104],
            [113.384432, 56.653114],
            [113.379891, 56.631197],
            [113.322493, 56.630184],
            [113.302852, 56.645723],
            [113.236498, 56.652671],
            [113.221356, 56.658071],
            [113.190961, 56.653011],
            [113.184474, 56.64484],
            [113.138009, 56.644092],
            [113.112047, 56.661575],
            [113.089151, 56.660452],
            [113.07874, 56.682779],
            [113.035383, 56.682856],
            [113.000616, 56.69078],
            [112.964696, 56.681637],
            [112.866245, 56.685793],
            [112.873837, 56.723066],
            [112.806652, 56.729944],
            [112.798462, 56.747849],
            [112.734447, 56.75958],
            [112.720988, 56.773132],
            [112.734937, 56.790077],
            [112.711874, 56.80166],
            [112.719275, 56.813951],
            [112.702323, 56.822762],
            [112.705253, 56.843597],
            [112.679139, 56.856946],
            [112.67696, 56.870134],
            [112.691931, 56.872422],
            [112.691069, 56.887113],
            [112.710964, 56.910664],
            [112.727145, 56.916807],
            [112.738001, 56.948522],
            [112.694624, 56.95248],
            [112.680348, 56.965787],
            [112.652924, 56.960479],
            [112.645189, 56.96595],
            [112.621983, 56.955174],
            [112.587965, 56.951256],
            [112.573801, 56.958113],
            [112.550479, 56.946112],
            [112.518875, 56.918295],
            [112.49465, 56.91736],
            [112.472535, 56.907153],
            [112.452449, 56.888207],
            [112.454851, 56.872797],
            [112.432787, 56.859863],
            [112.402457, 56.857649],
            [112.3356, 56.871389],
            [112.324892, 56.866112],
            [112.257554, 56.867247],
            [112.217937, 56.857332],
            [112.18843, 56.881028],
            [112.125262, 56.921857],
            [112.118266, 56.938481],
            [112.083403, 56.956536],
            [112.065315, 56.957098],
            [112.046051, 56.943847],
            [112.014092, 56.943629],
            [111.98859, 56.954822],
            [111.95293, 56.948712],
            [111.922917, 56.952775],
            [111.908483, 56.944405],
            [111.876303, 56.951835],
            [111.847661, 56.935642],
            [111.848783, 56.92271],
            [111.831171, 56.912777],
            [111.789741, 56.909677],
            [111.767659, 56.931941],
            [111.768867, 56.946074],
            [111.750761, 56.962716],
            [111.722762, 56.978161],
            [111.698284, 56.980829],
            [111.680897, 57.000444],
            [111.615676, 57.01114],
            [111.594298, 57.040849],
            [111.592829, 57.059881],
            [111.608781, 57.086181],
            [111.609594, 57.102289],
            [111.549524, 57.119153],
            [111.532251, 57.131254],
            [111.499414, 57.117463],
            [111.47192, 57.090547],
            [111.478508, 57.079254],
            [111.447438, 57.074909],
            [111.447987, 57.060124],
            [111.390749, 57.030826],
            [111.385642, 57.004823],
            [111.374676, 56.989405],
            [111.333906, 56.969545],
            [111.34703, 56.946904],
            [111.368447, 56.931778],
            [111.342361, 56.896154],
            [111.24157, 56.892394],
            [111.210609, 56.867019],
            [111.202212, 56.84152],
            [111.184109, 56.841977],
            [111.160855, 56.822461],
            [111.11212, 56.822805],
            [111.092756, 56.815703],
            [111.071031, 56.834915],
            [111.018625, 56.85465],
            [110.982002, 56.874279],
            [110.947572, 56.859378],
            [110.882536, 56.843344],
            [110.874582, 56.849203],
            [110.837142, 56.84103],
            [110.801305, 56.867668],
            [110.756987, 56.887228],
            [110.713102, 56.880428],
            [110.699774, 56.883071],
            [110.694514, 56.869872],
            [110.665553, 56.858984],
            [110.629337, 56.836687],
            [110.576459, 56.835035],
            [110.500766, 56.85542],
            [110.490755, 56.86955],
            [110.508202, 56.895323],
            [110.491932, 56.905135],
            [110.469624, 56.897763],
            [110.440285, 56.898698],
            [110.388608, 56.911903],
            [110.394013, 56.928001],
            [110.369264, 56.9432],
            [110.270846, 56.952484],
            [110.242671, 56.946636],
            [110.236033, 56.934661],
            [110.216932, 56.930371],
            [110.18939, 56.934206],
            [110.169447, 56.955016],
            [110.106397, 56.970138],
            [110.085546, 56.988024],
            [110.055587, 56.972079],
            [110.043852, 56.978364],
            [110.013516, 56.975784],
            [109.993805, 56.963562],
            [109.968998, 56.967713],
            [109.971553, 56.946423],
            [109.950859, 56.917943],
            [109.92423, 56.927158],
            [109.886764, 56.898718],
            [109.872337, 56.877618],
            [109.877993, 56.867274],
            [109.846152, 56.846413],
            [109.863884, 56.828809],
            [109.861913, 56.810855],
            [109.890831, 56.793695],
            [109.880948, 56.779936],
            [109.853111, 56.77911],
            [109.834489, 56.761521],
            [109.808622, 56.754101],
            [109.802394, 56.739103],
            [109.776613, 56.729901],
            [109.768487, 56.707834],
            [109.745201, 56.701143],
            [109.711869, 56.705186],
            [109.6879, 56.69595],
            [109.6314, 56.702687],
            [109.604865, 56.69792],
            [109.595085, 56.711188],
            [109.553482, 56.730619],
            [109.550381, 56.741103],
            [109.505875, 56.736536],
            [109.48512, 56.725874],
            [109.469971, 56.735587],
            [109.447291, 56.73207],
            [109.423648, 56.738455],
            [109.378493, 56.717058],
            [109.358185, 56.713904],
            [109.347894, 56.699751],
            [109.323313, 56.689447],
            [109.318025, 56.672617],
            [109.288164, 56.672817],
            [109.232027, 56.650954],
            [109.214446, 56.66621],
            [109.187712, 56.663681],
            [109.158031, 56.670032],
            [109.142796, 56.679836],
            [109.115844, 56.66335],
            [109.080827, 56.660443],
            [109.073964, 56.628061],
            [109.060805, 56.610665],
            [109.011429, 56.623043],
            [109.015054, 56.645634],
            [108.999595, 56.645393],
            [108.981358, 56.631163],
            [108.948207, 56.624659],
            [108.956613, 56.608739],
            [108.903861, 56.58077],
            [108.883355, 56.591609],
            [108.848535, 56.585592],
            [108.840858, 56.578728],
            [108.817796, 56.588045],
            [108.770375, 56.572232],
            [108.702714, 56.579446],
            [108.670854, 56.566617],
            [108.732986, 56.532067],
            [108.740097, 56.504686],
            [108.703854, 56.478731],
            [108.69842, 56.46596],
            [108.663149, 56.452436],
            [108.651496, 56.438151],
            [108.654456, 56.420787],
            [108.608517, 56.407937],
            [108.585778, 56.375994],
            [108.602257, 56.36835],
            [108.580164, 56.343589],
            [108.561429, 56.348897],
            [108.549329, 56.332141],
            [108.5593, 56.32099],
            [108.585182, 56.314359],
            [108.581759, 56.301804],
            [108.61132, 56.270767],
            [108.64926, 56.257179],
            [108.651599, 56.241405],
            [108.702222, 56.252763],
            [108.746569, 56.233796],
            [108.772584, 56.248772],
            [108.784907, 56.233283],
            [108.80515, 56.234776],
            [108.831099, 56.210413],
            [108.834883, 56.191008],
            [108.868771, 56.186991],
            [108.861688, 56.175638],
            [108.886229, 56.151772],
            [108.908735, 56.149248],
            [108.906168, 56.136925],
            [108.922169, 56.123171],
            [108.889121, 56.107563],
            [108.8947, 56.086457],
            [108.92508, 56.085773],
            [108.924577, 56.076644],
            [108.953405, 56.066754],
            [108.954557, 56.045716],
            [108.943129, 56.040529],
            [108.953405, 56.02863],
            [108.977452, 56.020885],
            [108.986295, 56.006484],
            [109.02256, 56.007414],
            [109.034617, 55.99671],
            [109.06629, 56.011961],
            [109.087133, 56.014996],
            [109.101781, 56.004509],
            [109.119339, 56.004307],
            [109.170199, 55.985901],
            [109.199294, 55.987561],
            [109.240161, 56.018805],
            [109.239913, 55.994201],
            [109.250858, 55.972379],
            [109.267174, 55.961668],
            [109.240446, 55.947048],
            [109.235084, 55.932534],
            [109.246675, 55.923688],
            [109.187989, 55.90775],
            [109.164692, 55.915634],
            [109.135652, 55.905203],
            [109.13571, 55.917464],
            [109.080875, 55.911808],
            [109.061915, 55.928343],
            [109.04403, 55.93306],
            [109.037526, 55.918366],
            [109.01403, 55.923829],
            [108.997666, 55.915937],
            [109.004855, 55.931156],
            [108.989373, 55.939633],
            [108.969228, 55.938905],
            [108.941301, 55.909564],
            [108.927142, 55.911513],
            [108.92138, 55.891848],
            [108.904161, 55.87729],
            [108.879256, 55.877951],
            [108.846626, 55.864507],
            [108.823035, 55.848072],
            [108.82932, 55.823783],
            [108.817434, 55.817163],
            [108.813839, 55.795054],
            [108.785618, 55.772824],
            [108.781577, 55.751346],
            [108.796457, 55.722869],
            [108.809292, 55.716904],
            [108.81032, 55.699317],
            [108.794488, 55.690311],
            [108.783703, 55.646569],
            [108.792171, 55.639142],
            [108.775659, 55.61104],
            [108.73064, 55.614078],
            [108.682294, 55.605975],
            [108.69359, 55.580373],
            [108.672456, 55.57288],
            [108.672371, 55.560899],
            [108.69633, 55.53357],
            [108.704029, 55.499434],
            [108.691056, 55.494649],
            [108.658491, 55.499326],
            [108.639338, 55.489723],
            [108.640832, 55.458832],
            [108.606511, 55.439832],
            [108.60667, 55.431677],
            [108.652729, 55.423135],
            [108.667679, 55.396397],
            [108.672217, 55.359455],
            [108.661154, 55.334531],
            [108.671484, 55.31776],
            [108.652959, 55.29976],
            [108.68991, 55.287996],
            [108.70882, 55.287437],
            [108.716864, 55.271407],
            [108.708738, 55.25283],
            [108.735347, 55.242729],
            [108.753909, 55.243146],
            [108.784639, 55.23297],
            [108.794136, 55.206911],
            [108.774028, 55.191625],
            [108.796959, 55.166257],
            [108.763247, 55.154804],
            [108.768549, 55.14366],
            [108.733892, 55.129097],
            [108.745618, 55.105546],
            [108.71952, 55.087478],
            [108.714728, 55.073957],
            [108.697524, 55.062726],
            [108.686059, 55.02013],
            [108.695175, 54.979171],
            [108.731751, 54.957844],
            [108.738915, 54.942586],
            [108.760949, 54.932696],
            [108.734384, 54.91818],
            [108.718257, 54.900716],
            [108.711991, 54.875573],
            [108.727239, 54.859454],
            [108.72544, 54.835136],
            [108.708505, 54.815954],
            [108.726168, 54.794738],
            [108.723492, 54.774872],
            [108.707285, 54.735097],
            [108.721662, 54.728127],
            [108.701884, 54.656122],
            [108.686667, 54.660491],
            [108.673565, 54.646856],
            [108.660266, 54.654665],
            [108.636795, 54.639537],
            [108.650736, 54.629255],
            [108.649952, 54.612831],
            [108.634007, 54.5863],
            [108.605253, 54.567698],
            [108.581292, 54.567349],
            [108.548966, 54.550598],
            [108.546178, 54.543454],
            [108.576761, 54.5384],
            [108.631518, 54.545774],
            [108.669556, 54.536483],
            [108.887624, 54.473349],
            [109.068144, 54.426243],
            [109.001352, 54.335516],
            [108.635572, 54.00124],
            [108.451478, 53.848489],
            [108.373418, 53.764052],
            [108.297403, 53.673511],
            [108.075161, 53.332176],
            [108.048128, 53.276272],
            [108.013798, 53.235626],
            [107.248888, 52.84479],
            [106.999934, 52.716939],
            [106.172533, 52.361055],
            [105.991702, 52.184285],
            [105.823638, 51.999338],
            [105.773819, 51.956291],
            [105.602595, 51.851716],
            [105.479826, 51.804316],
            [105.308535, 51.748854],
            [105.245418, 51.733833],
            [105.004265, 51.688857],
            [104.951034, 51.68234],
            [104.753439, 51.652049],
            [104.601498, 51.631356],
            [104.587705, 51.622183],
            [104.627661, 51.482858],
            [104.638515, 51.456042],
            [104.635272, 51.410702],
            [104.665245, 51.405605],
            [104.667946, 51.389137],
            [104.659494, 51.38086],
            [104.658972, 51.359382],
            [104.672738, 51.337817],
            [104.651827, 51.325358],
            [104.637189, 51.304403],
            [104.602946, 51.299001],
            [104.589789, 51.286868],
            [104.561907, 51.286236],
            [104.487061, 51.321742],
            [104.460225, 51.314772],
            [104.454997, 51.303967],
            [104.419099, 51.289482],
            [104.399494, 51.289569],
            [104.360285, 51.277501],
            [104.343643, 51.277414],
            [104.326565, 51.287456],
            [104.317678, 51.283187],
            [104.267665, 51.280224],
            [104.259823, 51.290419],
            [104.231331, 51.282315],
            [104.196914, 51.284058],
            [104.152826, 51.263234],
            [104.135225, 51.261796],
            [104.149776, 51.242453],
            [104.127645, 51.237705],
            [104.121894, 51.224809],
            [104.066653, 51.204987],
            [104.038335, 51.220758],
            [104.016726, 51.221825],
            [103.985185, 51.213787],
            [103.983355, 51.193225],
            [103.919139, 51.151402],
            [103.893174, 51.156456],
            [103.843771, 51.143212],
            [103.841069, 51.134063],
            [103.810084, 51.130227],
            [103.804649, 51.32416],
            [103.800815, 51.434838],
            [103.586472, 51.490601],
            [103.56739, 51.479013],
            [103.547786, 51.479884],
            [103.538632, 51.470984],
            [103.508577, 51.472304],
            [103.492719, 51.463025],
            [103.462397, 51.468862],
            [103.433208, 51.455096],
            [103.421271, 51.464419],
            [103.388684, 51.470082],
            [103.391472, 51.456141],
            [103.364374, 51.433988],
            [103.338496, 51.446557],
            [103.326646, 51.475833],
            [103.30103, 51.495503],
            [103.269924, 51.4962],
            [103.241083, 51.506176],
            [103.242565, 51.535278],
            [103.234374, 51.551528],
            [103.215118, 51.553445],
            [103.237511, 51.570173],
            [103.208322, 51.60215],
            [103.181311, 51.619315],
            [103.187324, 51.636785],
            [103.206231, 51.653819],
            [103.235434, 51.666353],
            [103.198389, 51.687408],
            [103.179308, 51.722303],
            [103.200132, 51.739054],
            [103.197518, 51.759116],
            [103.223483, 51.771445],
            [103.21172, 51.7902],
            [103.226932, 51.80512],
            [103.178001, 51.833918],
            [103.128684, 51.835573],
            [103.126855, 51.842217],
            [103.077015, 51.856441],
            [103.111033, 51.867562],
            [103.082974, 51.879254],
            [103.059328, 51.90225],
            [102.987357, 51.899407],
            [102.926365, 51.912237],
            [102.877049, 51.934652],
            [102.855963, 51.948026],
            [102.858141, 51.968611],
            [102.828691, 51.980025],
            [102.811178, 51.976365],
            [102.764301, 51.994902],
            [102.746572, 52.021346],
            [102.719167, 52.028491],
            [102.698168, 52.050797],
            [102.67046, 52.060686],
            [102.670918, 52.086828],
            [102.617318, 52.110608],
            [102.610817, 52.12562],
            [102.581848, 52.146357],
            [102.565729, 52.177789],
            [102.571015, 52.185842],
            [102.559804, 52.202055],
            [102.56782, 52.219569],
            [102.515452, 52.234572],
            [102.500032, 52.229197],
            [102.484261, 52.236603],
            [102.449757, 52.232072],
            [102.393482, 52.263629],
            [102.375434, 52.262873],
            [102.356003, 52.244924],
            [102.332826, 52.242702],
            [102.32481, 52.233989],
            [102.301198, 52.229981],
            [102.268785, 52.241395],
            [102.256761, 52.262611],
            [102.19359, 52.264005],
            [102.187458, 52.280304],
            [102.153423, 52.282259],
            [102.146104, 52.27396],
            [102.119354, 52.274396],
            [102.111861, 52.261413],
            [102.083195, 52.257383],
            [102.086332, 52.226583],
            [102.061197, 52.215307],
            [102.008523, 52.21201],
            [101.961237, 52.226663],
            [101.949884, 52.219089],
            [101.926968, 52.220832],
            [101.913637, 52.24109],
            [101.918952, 52.255815],
            [101.899609, 52.284786],
            [101.921392, 52.302343],
            [101.963041, 52.315434],
            [101.926164, 52.317848],
            [101.886452, 52.332969],
            [101.860748, 52.3485],
            [101.828102, 52.354835],
            [101.808731, 52.367015],
            [101.755929, 52.381936],
            [101.72316, 52.409037],
            [101.68666, 52.404721],
            [101.691714, 52.416919],
            [101.674608, 52.44848],
            [101.685373, 52.45168],
            [101.677511, 52.475645],
            [101.632461, 52.479218],
            [101.609354, 52.491152],
            [101.595695, 52.491002],
            [101.56973, 52.505183],
            [101.547812, 52.531492],
            [101.530259, 52.530995],
            [101.49109, 52.556573],
            [101.468221, 52.559073],
            [101.457069, 52.5704],
            [101.458035, 52.595493],
            [101.416426, 52.632261],
            [101.340958, 52.639267],
            [101.312258, 52.646084],
            [101.293926, 52.643924],
            [101.278001, 52.6513],
            [101.268985, 52.666022],
            [101.24107, 52.67158],
            [101.218241, 52.684127],
            [101.214722, 52.698883],
            [101.171102, 52.70421],
            [101.127042, 52.714552],
            [101.093302, 52.736543],
            [101.081433, 52.760074],
            [101.07369, 52.748015],
            [101.053911, 52.764134],
            [101.022283, 52.766095],
            [101.005118, 52.7796],
            [100.988911, 52.774721],
            [100.952403, 52.77777],
            [100.934018, 52.786157],
            [100.91058, 52.783194],
            [100.872242, 52.803452],
            [100.865359, 52.81748],
            [100.832859, 52.843576],
            [100.81369, 52.864509],
            [100.766552, 52.888099],
            [100.754963, 52.888971],
            [100.721418, 52.908923],
            [100.655546, 52.920512],
            [100.599521, 52.95031],
            [100.592899, 52.982592],
            [100.572684, 52.986905],
            [100.559876, 53.012347],
            [100.544541, 53.017314],
            [100.539923, 53.036178],
            [100.500626, 53.035045],
            [100.488167, 53.058004],
            [100.431183, 53.075713],
            [100.421162, 53.086887],
            [100.399728, 53.090895],
            [100.377597, 53.104531],
            [100.38448, 53.115248],
            [100.359299, 53.137685],
            [100.372369, 53.150144],
            [100.357295, 53.159293],
            [100.335512, 53.161319],
            [100.344661, 53.17038],
            [100.384654, 53.185323],
            [100.382824, 53.192381],
            [100.407308, 53.194145],
            [100.402342, 53.203729],
            [100.384393, 53.204427],
            [100.414627, 53.218498],
            [100.397114, 53.226166],
            [100.40208, 53.233746],
            [100.426042, 53.236447],
            [100.421104, 53.249162],
            [100.394674, 53.264547],
            [100.331765, 53.288921],
            [100.330807, 53.29502],
            [100.290204, 53.323033],
            [100.297087, 53.348627],
            [100.286283, 53.362655],
            [100.265894, 53.372066],
            [100.230867, 53.374984],
            [100.189392, 53.367731],
            [100.167087, 53.350523],
            [100.145217, 53.351481],
            [100.123869, 53.342594],
            [100.115592, 53.313013],
            [100.090411, 53.304125],
            [100.037871, 53.310922],
            [100.026021, 53.32495],
            [99.977837, 53.326257],
            [99.962241, 53.308809],
            [99.938977, 53.309767],
            [99.902381, 53.302971],
            [99.872321, 53.31212],
            [99.876242, 53.294955],
            [99.851758, 53.286982],
            [99.846704, 53.257707],
            [99.864131, 53.244593],
            [99.871363, 53.228474],
            [99.841302, 53.19678],
            [99.795123, 53.20007],
            [99.755129, 53.171295],
            [99.738836, 53.169029],
            [99.716792, 53.149556],
            [99.677757, 53.142476],
            [99.643253, 53.144219],
            [99.601517, 53.135593],
            [99.575116, 53.118668],
            [99.530766, 53.105663],
            [99.496029, 53.116483],
            [99.485981, 53.112525],
            [99.451912, 53.117753],
            [99.439539, 53.110347],
            [99.400243, 53.111305],
            [99.390659, 53.101024],
            [99.372187, 53.101895],
            [99.355545, 53.080526],
            [99.379245, 53.066368],
            [99.366785, 53.060181],
            [99.369922, 53.034086],
            [99.342475, 53.023978],
            [99.331845, 53.002849],
            [99.318427, 52.995095],
            [99.300652, 52.997709],
            [99.240532, 52.986425],
            [99.230567, 52.963186],
            [99.203971, 52.97685],
            [99.180928, 52.976117],
            [99.165545, 52.98797],
            [99.128644, 52.984975],
            [99.114848, 52.995082],
            [99.078919, 53.003334],
            [98.999776, 53.027406],
            [98.993968, 53.045745],
            [99.002957, 53.059427],
            [99.023128, 53.065844],
            [99.027788, 53.079106],
            [99.0636, 53.092067],
            [99.054015, 53.109028],
            [99.014152, 53.102184],
            [98.961474, 53.127061],
            [98.957722, 53.137793],
            [98.927803, 53.153476],
            [98.891476, 53.14788],
            [98.88603, 53.137591],
            [98.899073, 53.120402],
            [98.879942, 53.125744],
            [98.851137, 53.121403],
            [98.823519, 53.123815],
            [98.798824, 53.134195],
            [98.768621, 53.131901],
            [98.753547, 53.147359],
            [98.724091, 53.15345],
            [98.665839, 53.153727],
            [98.667312, 53.140974],
            [98.637103, 53.101222],
            [98.583248, 53.103138],
            [98.559658, 53.099931],
            [98.506846, 53.107271],
            [98.468117, 53.095779],
            [98.380716, 53.099745],
            [98.355017, 53.086194],
            [98.329347, 53.09956],
            [98.31211, 53.094827],
            [98.296149, 53.121197],
            [98.291325, 53.148271],
            [98.318025, 53.159717],
            [98.298281, 53.173243],
            [98.31543, 53.186354],
            [98.305157, 53.204468],
            [98.319987, 53.212176],
            [98.295093, 53.227152],
            [98.273667, 53.217505],
            [98.236532, 53.208673],
            [98.229772, 53.225184],
            [98.192373, 53.246006],
            [98.150268, 53.244357],
            [98.142315, 53.23677],
            [98.092163, 53.241691],
            [98.079918, 53.25081],
            [98.057371, 53.246837],
            [98.002828, 53.249523],
            [98.002554, 53.275606],
            [97.96027, 53.300753],
            [97.989152, 53.328596],
            [97.9825, 53.348161],
            [97.959474, 53.342008],
            [97.939596, 53.349517],
            [97.902536, 53.34485],
            [97.883108, 53.351283],
            [97.866066, 53.346704],
            [97.798959, 53.373758],
            [97.787516, 53.362296],
            [97.736898, 53.368394],
            [97.729229, 53.376118],
            [97.71465, 53.368025],
            [97.684698, 53.370649],
            [97.67274, 53.363521],
            [97.6379, 53.359463],
            [97.58953, 53.376688],
            [97.606885, 53.394515],
            [97.602759, 53.425895],
            [97.587548, 53.436353],
            [97.536574, 53.451613],
            [97.516061, 53.447402],
            [97.481233, 53.456097],
            [97.456561, 53.455465],
            [97.435152, 53.470489],
            [97.440588, 53.482447],
            [97.422881, 53.499088],
            [97.426427, 53.50809],
            [97.381697, 53.524872],
            [97.389376, 53.544146],
            [97.37321, 53.55438],
            [97.347, 53.55703],
            [97.332363, 53.569261],
            [97.329068, 53.588836],
            [97.297731, 53.57986],
            [97.274457, 53.58628],
            [97.215899, 53.593799],
            [97.203084, 53.619032],
            [97.183225, 53.62365],
            [97.177222, 53.612917],
            [97.13848, 53.610564],
            [97.085388, 53.639584],
            [97.077679, 53.657607],
            [97.058447, 53.646366],
            [97.022052, 53.648817],
            [97.000997, 53.663362],
            [96.976025, 53.671487],
            [96.972082, 53.688428],
            [96.918321, 53.702283],
            [96.88758, 53.73011],
            [96.843435, 53.720378],
            [96.79882, 53.728141],
            [96.760208, 53.718908],
            [96.734819, 53.724722],
            [96.707983, 53.709238],
            [96.710717, 53.673035],
            [96.700177, 53.668117],
            [96.689007, 53.645835],
            [96.647236, 53.642692],
            [96.647152, 53.656784],
            [96.616067, 53.677162],
            [96.599065, 53.673195],
            [96.591528, 53.659603],
            [96.55448, 53.676931],
            [96.524859, 53.673051],
            [96.517754, 53.681468],
            [96.483179, 53.693692],
            [96.490946, 53.704619],
            [96.483, 53.730263],
            [96.456938, 53.741666],
            [96.456532, 53.751708],
            [96.432967, 53.751444],
            [96.39889, 53.758916],
            [96.385146, 53.799177],
            [96.3665, 53.809771],
            [96.340136, 53.83732],
            [96.303382, 53.845955],
            [96.277803, 53.865063],
            [96.242285, 53.877276],
            [96.187094, 53.907754],
            [96.14831, 53.94181],
            [96.144403, 53.959143],
            [96.127536, 53.959514],
            [96.117032, 53.983111],
            [96.07278, 53.985312],
            [96.061071, 53.995375],
            [96.082975, 54.015322],
            [96.057841, 54.028031],
            [96.09246, 54.045825],
            [96.098333, 54.061608],
            [96.073598, 54.08123],
            [95.997764, 54.080847],
            [95.981227, 54.08989],
            [95.936125, 54.103321],
            [95.938262, 54.111405],
            [95.912101, 54.116089],
            [95.906078, 54.147992],
            [95.877712, 54.174857],
            [95.86043, 54.170125],
            [95.847152, 54.18209],
            [95.857406, 54.188603],
            [95.847958, 54.20614],
            [95.826432, 54.211641],
            [95.802983, 54.227496],
            [95.788824, 54.218971],
            [95.745018, 54.215891],
            [95.74029, 54.226946],
            [95.704315, 54.237624],
            [95.684428, 54.237435],
            [95.66262, 54.254737],
            [95.667915, 54.278497],
            [95.647701, 54.299588],
            [95.664051, 54.306168],
            [95.655021, 54.317159],
            [95.662508, 54.337769],
            [95.687617, 54.355889],
            [95.694924, 54.372684],
            [95.681798, 54.391417],
            [95.708534, 54.404997],
            [95.711193, 54.413813],
            [95.743264, 54.411887],
            [95.752729, 54.422432],
            [95.817549, 54.424396],
            [95.846173, 54.422233],
            [95.871519, 54.393452],
            [95.904026, 54.389473],
            [95.939504, 54.423506],
            [95.955548, 54.446452],
            [95.978091, 54.446602],
            [95.95264, 54.466362],
            [95.977474, 54.475729],
            [95.979573, 54.508195],
            [96.019513, 54.511783],
            [95.99966, 54.526478],
            [96.019719, 54.521692],
            [96.036602, 54.532191],
            [96.025287, 54.554361],
            [96.064756, 54.574173],
            [96.08131, 54.575294],
            [96.088654, 54.563586],
            [96.114081, 54.557474],
            [96.126982, 54.545837],
            [96.175712, 54.541343],
            [96.178883, 54.535775],
            [96.210108, 54.5365],
            [96.243881, 54.549939],
            [96.274977, 54.548123],
            [96.290252, 54.557529],
            [96.338091, 54.55553],
            [96.374589, 54.541924],
            [96.394652, 54.542958],
            [96.409172, 54.533728],
            [96.433191, 54.530879],
            [96.458816, 54.520378],
            [96.486433, 54.515854],
            [96.538823, 54.529775],
            [96.541091, 54.570991],
            [96.530267, 54.582593],
            [96.561772, 54.596555],
            [96.580866, 54.626644],
            [96.575293, 54.645557],
            [96.583105, 54.676086],
            [96.566126, 54.690612],
            [96.587163, 54.720109],
            [96.610419, 54.73163],
            [96.629927, 54.760019],
            [96.626487, 54.771368],
            [96.661748, 54.791259],
            [96.670805, 54.803742],
            [96.668025, 54.832348],
            [96.691239, 54.835289],
            [96.710014, 54.869358],
            [96.700208, 54.891291],
            [96.716457, 54.899447],
            [96.703631, 54.912702],
            [96.691689, 54.946426],
            [96.67899, 54.957066],
            [96.647731, 54.965086],
            [96.608013, 55.005847],
            [96.614441, 55.012573],
            [96.593417, 55.030175],
            [96.61892, 55.041736],
            [96.641432, 55.060369],
            [96.658423, 55.058557],
            [96.685306, 55.082735],
            [96.651298, 55.104142],
            [96.677974, 55.118032],
            [96.667786, 55.153987],
            [96.689476, 55.179313],
            [96.684606, 55.225656],
            [96.657122, 55.234882],
            [96.678578, 55.25414],
            [96.708441, 55.247094],
            [96.731407, 55.253171],
            [96.719938, 55.268059],
            [96.744529, 55.273273],
            [96.773878, 55.271731],
            [96.781277, 55.288453],
            [96.821281, 55.284655],
            [96.83737, 55.295286],
            [96.875425, 55.29096],
            [96.909051, 55.30135],
            [96.927883, 55.301909],
            [96.943629, 55.321854],
            [96.920596, 55.333972],
            [96.925033, 55.344868],
            [96.899538, 55.361376],
            [96.885755, 55.386941],
            [96.890849, 55.397195],
            [96.847501, 55.431695],
            [96.837476, 55.465881],
            [96.854324, 55.469745],
            [96.854558, 55.482769],
            [96.836734, 55.4928],
            [96.82844, 55.51792],
            [96.839419, 55.531729],
            [96.81105, 55.546148],
            [96.810292, 55.569201],
            [96.862385, 55.577909],
            [96.854371, 55.599766],
            [96.831486, 55.61488],
            [96.784491, 55.630508],
            [96.770664, 55.644526],
            [96.743994, 55.656761],
            [96.751024, 55.662538],
            [96.82558, 55.688982],
            [96.820508, 55.708273],
            [96.780495, 55.713858],
            [96.754383, 55.733953],
            [96.775054, 55.746576],
            [96.771603, 55.76643],
            [96.786108, 55.78958],
            [96.817401, 55.805478],
            [96.833726, 55.806123],
            [96.833579, 55.827139],
            [96.844137, 55.839301],
            [96.869905, 55.829822],
            [96.889621, 55.830459],
            [96.91657, 55.861772],
            [96.966233, 55.85],
            [96.990024, 55.851758],
            [96.992523, 55.893471],
            [96.990726, 56.003892],
            [97.063652, 56.004181],
            [97.08366, 56.035048],
            [97.108216, 56.084349],
            [97.131942, 56.112529],
            [97.258207, 56.086972],
            [97.301181, 56.074375],
            [97.311312, 56.039563],
            [97.359519, 56.03207],
            [97.41236, 56.097372],
            [97.437077, 56.104107],
            [97.440408, 56.142725],
            [97.409098, 56.163546],
            [97.42783, 56.176981],
            [97.456248, 56.177814],
            [97.471782, 56.194531],
            [97.527279, 56.187359],
            [97.574179, 56.187314],
            [97.570024, 56.270358],
            [97.556301, 56.280184],
            [97.557019, 56.376301],
            [97.879988, 56.375359],
            [97.879933, 56.534101],
            [97.796848, 56.535762],
            [97.741605, 56.547458],
            [97.758451, 56.575144],
            [97.779681, 56.591282],
            [97.766105, 56.621161],
            [97.736512, 56.629244],
            [97.737344, 56.646309],
            [97.775369, 56.651324],
            [97.762106, 56.674789],
            [97.767308, 56.687381],
            [97.758067, 56.702384],
            [97.76868, 56.721448],
            [97.748529, 56.732598],
            [97.764327, 56.738818],
            [97.802869, 56.774852],
            [97.785914, 56.797093],
            [97.669615, 56.805594],
            [97.619008, 56.800112],
            [97.606165, 56.793016],
            [97.562729, 56.784737],
            [97.528941, 56.790406],
            [97.495791, 56.817765],
            [97.472194, 56.822412],
            [97.469086, 56.840144],
            [97.485069, 56.873977],
            [97.47862, 56.883111],
            [97.523555, 56.899874],
            [97.579817, 56.905519],
            [97.595403, 56.892561],
            [97.63156, 56.907463],
            [97.653767, 56.93938],
            [97.676532, 56.95965],
            [97.67146, 56.9738],
            [97.654746, 56.975583],
            [97.621356, 56.963274],
            [97.589992, 56.969878],
            [97.598219, 56.988393],
            [97.584583, 56.989131],
            [97.530581, 56.969848],
            [97.501003, 56.988336],
            [97.506464, 57.00421],
            [97.485493, 57.005682],
            [97.471899, 56.997196],
            [97.383541, 57.024655],
            [97.377274, 57.035926],
            [97.34397, 57.032281],
            [97.511138, 57.248205],
            [97.572395, 57.334005],
            [97.715826, 57.521905],
            [97.828832, 57.667319],
            [97.9532, 57.828574],
            [97.990255, 57.831815],
            [98.042383, 57.827704],
            [98.049663, 57.821935],
            [98.107961, 57.828657],
            [98.142523, 57.817784],
            [98.212939, 57.815685],
            [98.246612, 57.807987],
            [98.32675, 57.808378],
            [98.39162, 57.796704],
            [98.434624, 57.800587],
            [98.451181, 57.794896],
            [98.503782, 57.792259],
            [98.591332, 57.783599],
            [98.606649, 57.776458],
            [98.644834, 57.772471],
            [98.695171, 57.776939],
            [98.729742, 57.79401],
            [98.747524, 57.797018],
            [98.794011, 57.78201],
            [98.790008, 57.762694],
            [98.809075, 57.75191],
            [98.844412, 57.752969],
            [98.848295, 57.72796],
            [98.884167, 57.72871],
            [98.885981, 57.71677],
            [98.913085, 57.711384],
            [98.926157, 57.725117],
            [98.965691, 57.726323],
            [98.978912, 57.71777],
            [99.004298, 57.742816],
            [99.039856, 57.745543],
            [99.086604, 57.778079],
            [99.135858, 57.773995],
            [99.146732, 57.768042],
            [99.204509, 57.76101],
            [99.238358, 57.769328],
            [99.255329, 57.779277],
            [99.298169, 57.781619],
            [99.346934, 57.798072],
            [99.394282, 57.782604],
            [99.433516, 57.78925],
            [99.458462, 57.808769],
            [99.441665, 57.82796],
            [99.477749, 57.836266],
            [99.479816, 57.866085],
            [99.498903, 57.883776],
            [99.483612, 57.899699],
            [99.533668, 57.911382],
            [99.511289, 57.923357],
            [99.509926, 57.937443],
            [99.561197, 57.937168],
            [99.570528, 57.954951],
            [99.588723, 57.960334],
            [99.608068, 57.993752],
            [99.63747, 57.992361],
            [99.637198, 58.017257],
            [99.648951, 58.026031],
            [99.632899, 58.053343],
            [99.641134, 58.064024],
            [99.681723, 58.082698],
            [99.757661, 58.084158],
            [99.812033, 58.057505],
            [99.826649, 58.068316],
            [99.87976, 58.077524],
            [99.908967, 58.072933],
            [99.942573, 58.082863],
            [99.988983, 58.076662],
            [100.003762, 58.082883],
            [100.021414, 58.072751],
            [100.044293, 58.076586],
            [100.090969, 58.068641],
            [100.075183, 58.052204],
            [100.091916, 58.050721],
            [100.099231, 58.034453],
            [100.080133, 58.022005],
            [100.089296, 58.013702],
            [100.064688, 58.000636],
            [100.081665, 57.990456],
            [100.063989, 57.977071],
            [100.090482, 57.9679],
            [100.074821, 57.960291],
            [100.080626, 57.945216],
            [100.103555, 57.940258],
            [100.106256, 57.926413],
            [100.129432, 57.919499],
            [100.127362, 57.901624],
            [100.147703, 57.870586],
            [100.137241, 57.859669],
            [100.19112, 57.832735],
            [100.2191, 57.836656],
            [100.270222, 57.810403],
            [100.316027, 57.80529],
            [100.290919, 57.794192],
            [100.286118, 57.767915],
            [100.323147, 57.759103],
            [100.334834, 57.747222],
            [100.37883, 57.741991],
            [100.375006, 57.728841],
            [100.350244, 57.714596],
            [100.349046, 57.705432],
            [100.322777, 57.684373],
            [100.330276, 57.675691],
            [100.378372, 57.67076],
            [100.382227, 57.655401],
            [100.359618, 57.649644],
            [100.353229, 57.635808],
            [100.381322, 57.625758],
            [100.387441, 57.606141],
            [100.413886, 57.597231],
            [100.426355, 57.576471],
            [100.408114, 57.57103],
            [100.403319, 57.552781],
            [100.431446, 57.534281],
            [100.454775, 57.529141],
            [100.472043, 57.516283],
            [100.490238, 57.518328],
            [100.515623, 57.493958],
            [100.545317, 57.491458],
            [100.577303, 57.480782],
            [100.591108, 57.483639],
            [100.622697, 57.469449],
            [100.649441, 57.468151],
            [100.681985, 57.456311],
            [100.688484, 57.444219],
            [100.718125, 57.420523],
            [100.71931, 57.412899],
            [100.763049, 57.399517],
            [100.785697, 57.407934],
            [100.807796, 57.431807],
            [100.807931, 57.441255],
            [100.843258, 57.453342],
            [100.856577, 57.463785],
            [100.888553, 57.455109],
            [100.88165, 57.447367],
            [100.912935, 57.444816],
            [100.938226, 57.451977],
            [100.940785, 57.469054],
            [100.97034, 57.483276],
            [100.9657, 57.493451],
            [100.940483, 57.500796],
            [100.956205, 57.505783],
            [100.942974, 57.518587],
            [100.961421, 57.524779],
            [100.945524, 57.531206],
            [100.959822, 57.546437],
            [100.947622, 57.547755],
            [100.948439, 57.569257],
            [100.898465, 57.590878],
            [100.847663, 57.606073],
            [100.843303, 57.61618],
            [100.813714, 57.638003],
            [100.845827, 57.646274],
            [100.813734, 57.665043],
            [100.820849, 57.675465],
            [100.855252, 57.677555],
            [100.839447, 57.694989],
            [100.856363, 57.701524],
            [100.873023, 57.736424],
            [100.891027, 57.741212],
            [100.892875, 57.756723],
            [100.908093, 57.774067],
            [100.867261, 57.785651],
            [100.852898, 57.809476],
            [100.870399, 57.819516],
            [100.849936, 57.823824],
            [100.857583, 57.835427],
            [100.852007, 57.855177],
            [100.81856, 57.875391],
            [100.868133, 57.878432],
            [100.896562, 57.875421],
            [100.921903, 57.862338],
            [100.942755, 57.869296],
            [100.973949, 57.864191],
            [101.02328, 57.88148],
            [101.039069, 57.902746],
            [101.022095, 57.911897],
            [101.045166, 57.920345],
            [101.039497, 57.930733],
            [101.084098, 57.942279],
            [101.104061, 57.962422],
            [101.13863, 57.968196],
            [101.130978, 57.978067],
            [101.137838, 57.997745],
            [101.153013, 58.009561],
            [101.166866, 58.044212],
            [101.123244, 58.060815],
            [101.106576, 58.101445],
            [101.150903, 58.108668],
            [101.149409, 58.123016],
            [101.200103, 58.129044],
            [101.260576, 58.166736],
            [101.256267, 58.17329],
            [101.273018, 58.194269],
            [101.288757, 58.190482],
            [101.31162, 58.209801],
            [101.363814, 58.232678],
            [101.436368, 58.237435],
            [101.498147, 58.229704],
            [101.552672, 58.254658],
            [101.556074, 58.275274],
            [101.585095, 58.28955],
            [101.605191, 58.308043],
            [101.640047, 58.325694],
            [101.643061, 58.33952],
            [101.68143, 58.36107],
            [101.669009, 58.368819],
            [101.683491, 58.38076],
            [101.683149, 58.397113],
            [101.699847, 58.411146],
            [101.730349, 58.420367],
            [101.760063, 58.439583],
            [101.805524, 58.455298],
            [101.922738, 58.465577],
            [101.95293, 58.479271],
            [102.006388, 58.476891],
            [102.029999, 58.491434],
            [102.06236, 58.484958],
            [102.078054, 58.491776],
            [102.103653, 58.489791],
            [102.111543, 58.499409],
            [102.137653, 58.503112],
            [102.146518, 58.51205],
            [102.206713, 58.525541],
            [102.221994, 58.538475],
            [102.254439, 58.5524],
            [102.250317, 58.563146],
            [102.27234, 58.55896],
            [102.276334, 58.572473],
            [102.298686, 58.585039],
            [102.277669, 58.590393],
            [102.338043, 58.647934],
            [102.361201, 58.660025],
            [102.393447, 58.66424],
            [102.388363, 58.675779],
            [102.43506, 58.670759],
            [102.451638, 58.680927],
            [102.455193, 58.695531],
            [102.500195, 58.710782],
            [102.51853, 58.707781],
            [102.545936, 58.734049],
            [102.565799, 58.740148],
            [102.548648, 58.762945],
            [102.581494, 58.790032],
            [102.547094, 58.827091],
            [102.565563, 58.839695],
            [102.542404, 58.847957],
            [102.558039, 58.864971],
            [102.535998, 58.883203],
            [102.56121, 58.904955],
            [102.510531, 58.908539],
            [102.481107, 58.926595],
            [102.484602, 58.936686],
            [102.449866, 58.939753],
            [102.445717, 58.970297],
            [102.455978, 58.980757],
            [102.438545, 58.993114],
            [102.479614, 58.994882],
            [102.491842, 59.010768],
            [102.472862, 59.022575],
            [102.439856, 59.021138],
            [102.440065, 59.039385],
            [102.460247, 59.051927],
            [102.426267, 59.060678],
            [102.402553, 59.083479],
            [102.438707, 59.115759],
            [102.419657, 59.127618],
            [102.437541, 59.13642],
            [102.426481, 59.144976],
            [102.458288, 59.150553],
            [102.483397, 59.16528],
            [102.497896, 59.186055],
            [102.550634, 59.188665],
            [102.567676, 59.209606],
            [102.590279, 59.202955],
            [102.630931, 59.211547],
            [102.648689, 59.197666],
            [102.787413, 59.202063],
            [102.825359, 59.19379],
            [102.831598, 59.205006],
            [102.8668, 59.218304],
            [102.84969, 59.243395],
            [102.882485, 59.255104],
            [102.897103, 59.275841],
            [102.922113, 59.278857],
            [102.939373, 59.29874],
            [102.983235, 59.295683],
            [102.989457, 59.310337],
            [103.018591, 59.31841],
            [103.04648, 59.310453],
            [103.062651, 59.29584],
            [103.05614, 59.285761],
            [103.136945, 59.258482],
            [103.217944, 59.260507],
            [103.212599, 59.278377],
            [103.232277, 59.290707],
            [103.272023, 59.285596],
            [103.303907, 59.267894],
            [103.320186, 59.274344],
            [103.352725, 59.266382],
            [103.359801, 59.235042],
            [103.329025, 59.24083],
            [103.30022, 59.23707],
            [103.285089, 59.242813],
            [103.217734, 59.237867],
            [103.230686, 59.207208],
            [103.252646, 59.192354],
            [103.234371, 59.1807],
            [103.244019, 59.16865],
            [103.284595, 59.153328],
            [103.324786, 59.121472],
            [103.316974, 59.111092],
            [103.353436, 59.085537],
            [103.358925, 59.062435],
            [103.386206, 59.051429],
            [103.392745, 59.029943],
            [103.432781, 59.026453],
            [103.459375, 59.009007],
            [103.483839, 59.015831],
            [103.529448, 58.999907],
            [103.564626, 58.998519],
            [103.56322, 58.988047],
            [103.582219, 58.985107],
            [103.588289, 58.968954],
            [103.607961, 58.963285],
            [103.593081, 58.944529],
            [103.630515, 58.922413],
            [103.665201, 58.931628],
            [103.704766, 58.933812],
            [103.724801, 58.917198],
            [103.749557, 58.918747],
            [103.781722, 58.908954],
            [103.839003, 58.913105],
            [103.861841, 58.920615],
            [103.899953, 58.921479],
            [103.946214, 58.903804],
            [103.940493, 58.891875],
            [103.95495, 58.873042],
            [103.985972, 58.875309],
            [103.977297, 58.846605],
            [103.94918, 58.832537],
            [103.958693, 58.828634],
            [103.949644, 58.809609],
            [103.9504, 58.788346],
            [103.965114, 58.770761],
            [103.942868, 58.761312],
            [103.916827, 58.760324],
            [103.886533, 58.749313],
            [103.887545, 58.739103],
            [103.930402, 58.731564],
            [103.949987, 58.722397],
            [103.991519, 58.73978],
            [104.0022, 58.757994],
            [104.01755, 58.761023],
            [104.030155, 58.784704],
            [104.07048, 58.787199],
            [104.129952, 58.774841],
            [104.149258, 58.766533],
            [104.180736, 58.765371],
            [104.191929, 58.785699],
            [104.211024, 58.786293],
            [104.199008, 58.761505],
            [104.212756, 58.755292],
            [104.232845, 58.72559],
            [104.257158, 58.715693],
            [104.292227, 58.720821],
            [104.299829, 58.729001],
            [104.33663, 58.734186],
            [104.351727, 58.727193],
            [104.382345, 58.726987],
            [104.386335, 58.707996],
            [104.34669, 58.672869],
            [104.377632, 58.664646],
            [104.409082, 58.645664],
            [104.407994, 58.629144],
            [104.431421, 58.617487],
            [104.467538, 58.608789],
            [104.493782, 58.612579],
            [104.500545, 58.624432],
            [104.525186, 58.638368],
            [104.552973, 58.639477],
            [104.590876, 58.623504],
            [104.62047, 58.625139],
            [104.618101, 58.643905],
            [104.635804, 58.651085],
            [104.653198, 58.644182],
            [104.684687, 58.652818],
            [104.709414, 58.66749],
            [104.746754, 58.676301],
            [104.804707, 58.674798],
            [104.817273, 58.692817],
            [104.877179, 58.726927],
            [104.875431, 58.736609],
            [104.894032, 58.744527],
            [104.857511, 58.760175],
            [104.817329, 58.76951],
            [104.816148, 58.776518],
            [104.780006, 58.793689],
            [104.786016, 58.802848],
            [104.754618, 58.807188],
            [104.767295, 58.829013],
            [104.767418, 58.844875],
            [104.836726, 58.901237],
            [104.89112, 58.908601],
            [104.882694, 58.921484],
            [104.893806, 58.931533],
            [104.915176, 58.93244],
            [104.920861, 58.9587],
            [104.969497, 58.966859],
            [105.017485, 58.98746],
            [105.054788, 58.996004],
            [105.112795, 59.027094],
            [105.099561, 59.032748],
            [105.135744, 59.061288],
            [105.175629, 59.057082],
            [105.225394, 59.066272],
            [105.246366, 59.063144],
            [105.301725, 59.075561],
            [105.368066, 59.118101],
            [105.341176, 59.144133],
            [105.320945, 59.144363],
            [105.293526, 59.155833],
            [105.319947, 59.17371],
            [105.33618, 59.200578],
            [105.321492, 59.202635],
            [105.311275, 59.225292],
            [105.294901, 59.234835],
            [105.304037, 59.245387],
            [105.271766, 59.270229],
            [105.246886, 59.263745],
            [105.214896, 59.26885],
            [105.211775, 59.289349],
            [105.185544, 59.291112],
            [105.177321, 59.317438],
            [105.219528, 59.342678],
            [105.229248, 59.387152],
            [105.205738, 59.389033],
            [105.197901, 59.403063],
            [105.217376, 59.424238],
            [105.242476, 59.429599],
            [105.235691, 59.443036],
            [105.198106, 59.458067],
            [105.194948, 59.476901],
            [105.148868, 59.485218],
            [105.10969, 59.467616],
            [105.071873, 59.467761],
            [104.993789, 59.460772],
            [104.967757, 59.470628],
            [104.966461, 59.482864],
            [104.94601, 59.493133],
            [104.9261, 59.517061],
            [104.908795, 59.519307],
            [104.857115, 59.549555],
            [104.849694, 59.560951],
            [104.855917, 59.583707],
            [104.88176, 59.595197],
            [104.87328, 59.607069],
            [104.842341, 59.606501],
            [104.816573, 59.625118],
            [104.78094, 59.640744],
            [104.80803, 59.663298],
            [104.807681, 59.671523],
            [104.778188, 59.674387],
            [104.762143, 59.684813],
            [104.769354, 59.695339],
            [104.794583, 59.701142],
            [104.834162, 59.730778],
            [104.862224, 59.743759],
            [104.861704, 59.755578],
            [104.918291, 59.77095],
            [104.935379, 59.765316],
            [104.953264, 59.77554],
            [104.990019, 59.763491],
            [105.025709, 59.779466],
            [105.029727, 59.793898],
            [105.014411, 59.805246],
            [105.023023, 59.812788],
            [105.006752, 59.826926],
            [105.018908, 59.834884],
            [104.978901, 59.842011],
            [104.97972, 59.854009],
            [105.02328, 59.843555],
            [105.060717, 59.855065],
            [105.051769, 59.871403],
            [105.070091, 59.876918],
            [105.104916, 59.867689],
            [105.145734, 59.883033],
            [105.179557, 59.865826],
            [105.268315, 59.872921],
            [105.25794, 59.860291],
            [105.310073, 59.853467],
            [105.342908, 59.843228],
            [105.379827, 59.840989],
            [105.416967, 59.856854],
            [105.432799, 59.857575],
            [105.477711, 59.83568],
            [105.509932, 59.843756],
            [105.509548, 59.857241],
            [105.480915, 59.869644],
            [105.483521, 59.878247],
            [105.451023, 59.886618],
            [105.425828, 59.903451],
            [105.440569, 59.927161],
            [105.409822, 59.947871],
            [105.433446, 59.961634],
            [105.433935, 59.971849],
            [105.49359, 59.991477],
            [105.504517, 60.000523],
            [105.485104, 60.02086],
            [105.495969, 60.041132],
            [105.471144, 60.048826],
            [105.452837, 60.090941],
            [105.436971, 60.096103],
            [105.422475, 60.15293],
            [105.460109, 60.16896],
            [105.471038, 60.182998],
            [105.469211, 60.205336],
            [105.502186, 60.215728],
            [105.508445, 60.226811],
            [105.477055, 60.219563],
            [105.459082, 60.23198],
            [105.464623, 60.252154],
            [105.391979, 60.273176],
            [105.374781, 60.264614],
            [105.343879, 60.293087],
            [105.324678, 60.299431],
            [105.306181, 60.292288],
            [105.256112, 60.284971],
            [105.241929, 60.275851],
            [105.216103, 60.276489],
            [105.200851, 60.285541],
            [105.180137, 60.282007],
            [105.149195, 60.289945],
            [105.111023, 60.287666],
            [105.079236, 60.301824],
            [105.050983, 60.296374],
            [105.018436, 60.298164],
            [104.977571, 60.289098],
            [104.948328, 60.303205],
            [104.903699, 60.299521],
            [104.862965, 60.311182],
            [104.853421, 60.324698],
            [104.870535, 60.328832],
            [104.845133, 60.34965],
            [104.799699, 60.361351],
            [104.795135, 60.373956],
            [104.750592, 60.383028],
            [104.724367, 60.393497],
            [104.704567, 60.39267],
            [104.656759, 60.406016],
            [104.644667, 60.424673],
            [104.677473, 60.422012],
            [104.649103, 60.454612],
            [104.664893, 60.462951],
            [104.625882, 60.495127],
            [104.652389, 60.506177],
            [104.628356, 60.51049],
            [104.644574, 60.518049],
            [104.609924, 60.522461],
            [104.602468, 60.535899],
            [104.560996, 60.552497],
            [104.548605, 60.565655],
            [104.56568, 60.569371],
            [104.571955, 60.586772],
            [104.533225, 60.592001],
            [104.519795, 60.601689],
            [104.539427, 60.613585],
            [104.503669, 60.61562],
            [104.51591, 60.639247],
            [104.564516, 60.665747],
            [104.559328, 60.68408],
            [104.590342, 60.702114],
            [104.609339, 60.724322],
            [104.57905, 60.768832],
            [104.548906, 60.77978],
            [104.50987, 60.78285],
            [104.504313, 60.796608],
            [104.481644, 60.808162],
            [104.487466, 60.816618],
            [104.468419, 60.850633],
            [104.532673, 60.868628],
            [104.554574, 60.870246],
            [104.6092, 60.885441],
            [104.633952, 60.905524],
            [104.61924, 60.922408],
            [104.581359, 60.936311],
            [104.583249, 60.959834],
            [104.623214, 60.988873],
            [104.604374, 61.005381],
            [104.624586, 61.013219],
            [104.629029, 61.030928],
            [104.64374, 61.039994],
            [104.616096, 61.047579],
            [104.615484, 61.067828],
            [104.626259, 61.079141],
            [104.690556, 61.086311],
            [104.716797, 61.100324],
            [104.711036, 61.113198],
            [104.734801, 61.11871],
            [104.760943, 61.135624],
            [104.830195, 61.162214],
            [104.856982, 61.191937],
            [104.871888, 61.187557],
            [104.906066, 61.195008],
            [104.964278, 61.188266],
            [104.988389, 61.195099],
            [105.044477, 61.177693],
            [105.037118, 61.19462],
            [105.022354, 61.197355],
            [105.030005, 61.2198],
            [104.992932, 61.242547],
            [104.990335, 61.27301],
            [104.939594, 61.294197],
            [104.950299, 61.316054],
            [104.864946, 61.336101],
            [104.82082, 61.335813],
            [104.776858, 61.347387],
            [104.788802, 61.354829],
            [104.835414, 61.357744],
            [104.884096, 61.372045],
            [104.906616, 61.372716],
            [104.910016, 61.395402],
            [104.947158, 61.402013],
            [104.994846, 61.40226],
            [105.010333, 61.41283],
            [105.062238, 61.406284],
            [105.098302, 61.410716],
            [105.156108, 61.424142],
            [105.185177, 61.413951],
            [105.225944, 61.41389],
            [105.283294, 61.425124],
            [105.291461, 61.450107],
            [105.257449, 61.462705],
            [105.295796, 61.475572],
            [105.297168, 61.489227],
            [105.313656, 61.498335],
            [105.319176, 61.515602],
            [105.347963, 61.521766],
            [105.369996, 61.534297],
            [105.359109, 61.539433],
            [105.391587, 61.549735],
            [105.452583, 61.559107],
            [105.508074, 61.579874],
            [105.550164, 61.578879],
            [105.58489, 61.590493],
            [105.593054, 61.600374],
            [105.579028, 61.616942],
            [105.609098, 61.623819],
            [105.628138, 61.639944],
            [105.673428, 61.656237],
            [105.712403, 61.662096],
            [105.775692, 61.645481],
            [105.811364, 61.629894],
            [105.841793, 61.661362],
            [105.879955, 61.67094],
            [105.8773, 61.678677],
            [105.904092, 61.688776],
            [105.968187, 61.687447],
            [105.979911, 61.690511],
            [105.964797, 61.707008],
            [105.973387, 61.713598],
            [105.949343, 61.74144],
            [105.934789, 61.773607],
            [105.937476, 61.787721],
            [105.917216, 61.798131],
            [105.893402, 61.824892],
            [105.899614, 61.844031],
            [105.934138, 61.859537],
            [106.002677, 61.898663],
            [106.048681, 61.918748],
            [106.06028, 61.933564],
            [106.111178, 61.957391],
            [106.178883, 61.966568],
            [106.208155, 61.979379],
            [106.207779, 61.989002],
            [106.247703, 61.998921],
            [106.249382, 62.012443],
            [106.320701, 62.037017],
            [106.333364, 62.03452],
            [106.3957, 62.042693],
            [106.404861, 62.057566],
            [106.393384, 62.077754],
            [106.411518, 62.100137],
            [106.387597, 62.128528],
            [106.387729, 62.145273],
            [106.424001, 62.160939],
            [106.434062, 62.177641],
            [106.424549, 62.183381],
            [106.44957, 62.199037],
            [106.428211, 62.212501],
            [106.455594, 62.234367],
            [106.464277, 62.261178],
            [106.416793, 62.253832],
            [106.402422, 62.265261],
            [106.3487, 62.28343],
            [106.387967, 62.291713],
            [106.408707, 62.301599],
            [106.409253, 62.329623],
            [106.462653, 62.330141],
            [106.483176, 62.34107],
            [106.482329, 62.354975],
            [106.460964, 62.359773],
            [106.431921, 62.396715],
            [106.466151, 62.405801],
            [106.492811, 62.398748],
            [106.543113, 62.396299],
            [106.573039, 62.405644],
            [106.575563, 62.424441],
            [106.617101, 62.448789],
            [106.626944, 62.462393],
            [106.664699, 62.477032],
            [106.728355, 62.494846],
            [106.755899, 62.498125],
            [106.738739, 62.507782],
            [106.720555, 62.537761],
            [106.672482, 62.556457],
            [106.688781, 62.569326],
            [106.717311, 62.608649],
            [106.781993, 62.603921],
            [106.8018, 62.611745],
            [106.773111, 62.638657],
            [106.70764, 62.655683],
            [106.664068, 62.661433],
            [106.651025, 62.656777],
            [106.583433, 62.676953],
            [106.575942, 62.6893],
            [106.527918, 62.688562],
            [106.472495, 62.697612],
            [106.459864, 62.708133],
            [106.482795, 62.722088],
            [106.508711, 62.727007],
            [106.503004, 62.744223],
            [106.517775, 62.757413],
            [106.512518, 62.791116],
            [106.461298, 62.803738],
            [106.446644, 62.814399],
            [106.444204, 62.831941],
            [106.455144, 62.844815],
            [106.437116, 62.862638],
            [106.382422, 62.880641],
            [106.354472, 62.882846],
            [106.318757, 62.874305],
            [106.298498, 62.885849],
            [106.28207, 62.909676],
            [106.192995, 62.920967],
            [106.199095, 62.937283],
            [106.192009, 62.968388],
            [106.137897, 62.976082],
            [106.144528, 62.992879],
            [106.170084, 63.006851],
            [106.130644, 63.037512],
            [106.114888, 63.090599],
            [106.184922, 63.085993],
            [106.259582, 63.094968],
            [106.283663, 63.103242],
            [106.353852, 63.107458],
            [106.396808, 63.125686],
            [106.408901, 63.150564],
            [106.38743, 63.168292],
            [106.39432, 63.185919],
            [106.438764, 63.215343],
            [106.421129, 63.234046],
            [106.344485, 63.245063],
            [106.349248, 63.265265],
            [106.375135, 63.274695],
            [106.353728, 63.284935],
            [106.355847, 63.301955],
            [106.407089, 63.318915],
            [106.42041, 63.329293],
            [106.408223, 63.336717],
            [106.411826, 63.353603],
            [106.44762, 63.360785],
            [106.471253, 63.341899],
            [106.501261, 63.339509],
            [106.520189, 63.322564],
            [106.575891, 63.326768],
            [106.620901, 63.316763],
            [106.657853, 63.325542],
            [106.676789, 63.354783],
            [106.719795, 63.375233],
            [106.708343, 63.391819],
            [106.737036, 63.397767],
            [106.72663, 63.40971],
            [106.704571, 63.406353],
            [106.707287, 63.418612],
            [106.676891, 63.429126],
            [106.648121, 63.424473],
            [106.63955, 63.448441],
            [106.594643, 63.454353],
            [106.592363, 63.475398],
            [106.574595, 63.478266],
            [106.583515, 63.497881],
            [106.524963, 63.51627],
            [106.471391, 63.542362],
            [106.506713, 63.548407],
            [106.510083, 63.564285],
            [106.566476, 63.567658],
            [106.59685, 63.560554],
            [106.602837, 63.574679],
            [106.579205, 63.593051],
            [106.615467, 63.618833],
            [106.658126, 63.627194],
            [106.686944, 63.667414],
            [106.657689, 63.678043],
            [106.653057, 63.692644],
            [106.609913, 63.705721],
            [106.602442, 63.722726],
            [106.624825, 63.741654],
            [106.662936, 63.762951],
            [106.672709, 63.796624],
            [106.690652, 63.802471],
            [106.69859, 63.825001],
            [106.767916, 63.84792],
            [106.792636, 63.881165],
            [106.771227, 63.896273],
            [106.721747, 63.904767],
            [106.710779, 63.915051],
            [106.730351, 63.935006],
            [106.696232, 63.948829],
            [106.645377, 63.958855],
            [106.606458, 63.986782],
            [106.623829, 63.994901],
            [106.624071, 64.008376],
            [106.660701, 64.025953],
            [106.664368, 64.044317],
            [106.695178, 64.036301],
            [106.699577, 64.019565],
            [106.730095, 64.027102],
            [106.737843, 64.018875],
            [106.791712, 64.006107],
            [106.775544, 64.019963],
            [106.807366, 64.018026],
            [106.820713, 63.988111],
            [106.878569, 63.983811],
            [106.905615, 63.973958],
            [106.927203, 63.977354],
            [106.977949, 63.970495],
            [106.980515, 63.959],
            [107.013561, 63.945651],
            [107.051822, 63.938939],
            [107.030661, 63.931933],
            [107.089406, 63.927],
            [107.110581, 63.953772],
            [107.124622, 63.948341],
            [107.167008, 63.953355],
            [107.165218, 63.968644],
            [107.195923, 63.982792],
            [107.20456, 63.97399],
            [107.244995, 63.980972],
            [107.272119, 63.971997],
            [107.28307, 63.980318],
            [107.307045, 63.977563],
            [107.343939, 63.95963],
            [107.32811, 63.952877],
            [107.377132, 63.936817],
            [107.372747, 63.912123],
            [107.344739, 63.906224],
            [107.41087, 63.894269],
            [107.4521, 63.921848],
            [107.502615, 63.91938],
            [107.518391, 63.896714],
            [107.565638, 63.897369],
            [107.598287, 63.90926],
            [107.586667, 63.920754],
            [107.617468, 63.947167],
            [107.610371, 63.962323],
            [107.631215, 63.981369],
            [107.685992, 63.987078],
            [107.685321, 64.001227],
            [107.769202, 64.020085],
            [107.779813, 64.011031],
            [107.834403, 64.015322],
            [107.860372, 64.021644],
            [107.86042, 64.007564],
            [107.91115, 63.998119],
            [107.977438, 64.019468],
            [107.990233, 64.009938],
            [108.028702, 64.001936],
            [108.07628, 64.006222],
            [108.101783, 64.013997],
            [108.116279, 64.005547],
            [108.161784, 64.008068],
            [108.205426, 64.003724],
            [108.214171, 64.010304],
            [108.271083, 64.018717],
            [108.291242, 64.011606],
            [108.326631, 64.016504],
            [108.323989, 64.028449],
            [108.385109, 64.046349],
            [108.354489, 64.075879],
            [108.364787, 64.103807],
            [108.326779, 64.124587],
            [108.322968, 64.14048],
            [108.299751, 64.153486],
            [108.305068, 64.171845],
            [108.283789, 64.185398],
            [108.292582, 64.214672],
            [108.268247, 64.221161],
            [108.246822, 64.250218],
            [108.171314, 64.257581],
            [108.085664, 64.246468],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5532",
      properties: {
        name: "Кабардино-Балкарская республика",
        density: 0,
        path: "/world/Russia/Кабардино-Балкарская республика",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.678289, 43.809695],
            [42.732152, 43.819264],
            [42.763202, 43.810295],
            [42.801233, 43.807117],
            [42.824279, 43.800647],
            [42.854704, 43.801896],
            [42.854362, 43.840494],
            [42.871845, 43.84776],
            [42.865714, 43.867429],
            [42.918503, 43.865272],
            [42.970441, 43.897344],
            [43.060069, 43.899275],
            [43.101846, 43.894053],
            [43.147711, 43.895076],
            [43.140104, 43.922776],
            [43.155429, 43.938386],
            [43.144871, 43.948035],
            [43.147482, 43.958934],
            [43.189032, 43.955869],
            [43.224112, 43.958254],
            [43.275257, 43.867434],
            [43.317604, 43.799546],
            [43.349277, 43.816121],
            [43.374139, 43.838486],
            [43.400249, 43.845071],
            [43.411715, 43.858126],
            [43.47387, 43.86562],
            [43.504522, 43.860284],
            [43.545505, 43.843143],
            [43.575533, 43.849614],
            [43.655058, 43.832018],
            [43.712047, 43.830316],
            [43.731517, 43.819418],
            [43.743437, 43.821234],
            [43.770228, 43.874819],
            [43.770114, 43.926558],
            [43.784531, 43.939501],
            [43.820802, 43.958971],
            [43.857925, 43.96022],
            [43.862919, 43.987353],
            [43.920306, 44.018403],
            [43.943919, 44.008867],
            [43.945282, 43.985367],
            [43.986946, 43.985822],
            [43.9874, 43.969701],
            [44.143736, 43.970477],
            [44.14435, 43.928323],
            [44.225805, 43.926961],
            [44.226032, 43.918106],
            [44.270705, 43.917312],
            [44.269698, 43.962351],
            [44.319519, 43.96295],
            [44.319519, 43.973054],
            [44.367597, 43.973282],
            [44.369867, 44.004757],
            [44.400065, 44.008503],
            [44.419364, 44.016451],
            [44.418912, 43.945497],
            [44.344666, 43.945155],
            [44.343645, 43.90386],
            [44.324573, 43.894892],
            [44.29324, 43.89123],
            [44.291438, 43.810387],
            [44.269395, 43.812249],
            [44.267364, 43.746333],
            [44.282233, 43.710046],
            [44.302567, 43.688174],
            [44.333271, 43.676673],
            [44.358584, 43.680473],
            [44.370496, 43.691461],
            [44.401714, 43.690947],
            [44.410751, 43.683965],
            [44.429341, 43.685154],
            [44.426206, 43.659165],
            [44.440071, 43.617909],
            [44.43545, 43.605894],
            [44.474986, 43.58225],
            [44.443975, 43.541841],
            [44.440587, 43.507799],
            [44.44798, 43.497633],
            [44.434323, 43.488288],
            [44.412997, 43.452857],
            [44.442879, 43.427804],
            [44.384058, 43.402977],
            [44.381491, 43.396508],
            [44.355254, 43.3924],
            [44.355767, 43.408522],
            [44.34406, 43.417867],
            [44.333482, 43.444053],
            [44.320372, 43.449644],
            [44.282959, 43.420408],
            [44.190334, 43.356713],
            [44.17493, 43.3486],
            [44.170308, 43.401152],
            [44.156342, 43.41635],
            [44.146346, 43.437676],
            [44.105683, 43.431065],
            [44.103816, 43.416144],
            [44.076552, 43.392756],
            [44.037769, 43.352206],
            [44.087696, 43.292119],
            [44.098232, 43.26719],
            [44.082973, 43.254996],
            [44.056685, 43.252326],
            [44.028856, 43.22655],
            [44.003052, 43.212394],
            [43.979462, 43.234457],
            [43.974178, 43.246343],
            [43.984904, 43.249244],
            [44.002068, 43.272692],
            [43.940236, 43.286538],
            [43.9237, 43.262029],
            [43.919593, 43.242004],
            [43.910864, 43.253402],
            [43.861798, 43.264182],
            [43.859672, 43.298817],
            [43.829874, 43.323265],
            [43.806581, 43.300433],
            [43.763042, 43.234018],
            [43.750206, 43.221695],
            [43.744354, 43.190015],
            [43.726281, 43.16619],
            [43.75068, 43.15681],
            [43.742198, 43.133458],
            [43.703382, 43.131198],
            [43.697015, 43.123085],
            [43.652037, 43.101212],
            [43.647571, 43.069403],
            [43.632681, 43.047427],
            [43.612041, 43.041266],
            [43.609884, 43.030791],
            [43.622618, 43.01069],
            [43.610399, 42.989227],
            [43.595201, 42.978136],
            [43.593147, 42.966635],
            [43.57949, 42.961167],
            [43.572302, 42.943093],
            [43.53523, 42.944838],
            [43.510379, 42.962501],
            [43.465041, 42.961473],
            [43.442963, 42.96938],
            [43.4291, 42.955619],
            [43.426533, 42.929998],
            [43.414416, 42.922296],
            [43.398487, 42.896923],
            [43.389892, 42.899134],
            [43.356879, 42.88978],
            [43.340008, 42.889536],
            [43.317549, 42.914819],
            [43.300661, 42.907666],
            [43.291268, 42.918615],
            [43.240103, 42.92242],
            [43.232375, 42.930599],
            [43.206107, 42.930117],
            [43.18487, 42.938667],
            [43.173163, 42.962769],
            [43.142312, 42.971231],
            [43.133697, 42.98497],
            [43.115785, 42.991781],
            [43.11382, 43.000414],
            [43.088842, 42.999339],
            [43.071003, 43.013102],
            [43.050805, 43.018928],
            [43.016593, 43.071302],
            [43.04261, 43.087832],
            [42.989695, 43.118912],
            [42.95611, 43.125073],
            [42.931724, 43.122872],
            [42.922252, 43.136138],
            [42.931189, 43.149132],
            [42.895542, 43.159202],
            [42.880941, 43.155041],
            [42.87739, 43.170689],
            [42.859797, 43.178898],
            [42.811434, 43.167702],
            [42.77611, 43.188756],
            [42.684621, 43.174604],
            [42.680928, 43.159522],
            [42.664413, 43.140186],
            [42.616476, 43.163575],
            [42.600872, 43.162441],
            [42.596866, 43.171215],
            [42.54467, 43.176935],
            [42.510341, 43.199515],
            [42.493336, 43.200539],
            [42.45718, 43.213178],
            [42.444264, 43.229154],
            [42.452468, 43.23712],
            [42.440679, 43.252424],
            [42.414023, 43.256817],
            [42.415417, 43.274839],
            [42.397382, 43.292435],
            [42.409722, 43.317766],
            [42.415405, 43.344397],
            [42.436676, 43.350567],
            [42.415242, 43.365181],
            [42.403016, 43.392464],
            [42.418046, 43.405923],
            [42.421411, 43.4374],
            [42.456432, 43.443167],
            [42.451125, 43.482379],
            [42.467119, 43.506533],
            [42.474425, 43.529916],
            [42.464195, 43.545342],
            [42.469066, 43.572703],
            [42.460459, 43.584557],
            [42.456074, 43.621092],
            [42.60262, 43.656208],
            [42.620482, 43.676506],
            [42.667246, 43.700458],
            [42.675528, 43.69786],
            [42.681373, 43.716046],
            [42.679586, 43.736831],
            [42.666595, 43.782216],
            [42.6686, 43.803198],
            [42.678289, 43.809695],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5534",
      properties: {
        name: "Калининградская область",
        density: 0,
        path: "/world/Russia/Калининградская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.953488, 55.281096],
            [20.984437, 55.277644],
            [21.098334, 55.256447],
            [21.271322, 55.245223],
            [21.29386, 55.251626],
            [21.333272, 55.275729],
            [21.383565, 55.294581],
            [21.408161, 55.275793],
            [21.433592, 55.249083],
            [21.447668, 55.22383],
            [21.484404, 55.203851],
            [21.505347, 55.185822],
            [21.549235, 55.197787],
            [21.575315, 55.197034],
            [21.592207, 55.188762],
            [21.653781, 55.179283],
            [21.712714, 55.149936],
            [21.71752, 55.136201],
            [21.783438, 55.118927],
            [21.822234, 55.116178],
            [21.847983, 55.097717],
            [21.880598, 55.093591],
            [21.911154, 55.082391],
            [21.962354, 55.074228],
            [21.985312, 55.084946],
            [22.010031, 55.087108],
            [22.03784, 55.081802],
            [22.031252, 55.053061],
            [22.044879, 55.035547],
            [22.070215, 55.02599],
            [22.117148, 55.026738],
            [22.136374, 55.047396],
            [22.162123, 55.055656],
            [22.184439, 55.0531],
            [22.215612, 55.060023],
            [22.291899, 55.064898],
            [22.345457, 55.061948],
            [22.391806, 55.053296],
            [22.423735, 55.05369],
            [22.476537, 55.044671],
            [22.544241, 55.064504],
            [22.589128, 55.070525],
            [22.594732, 55.034472],
            [22.643714, 54.982383],
            [22.648857, 54.966515],
            [22.667828, 54.967984],
            [22.659491, 54.982086],
            [22.681069, 54.986137],
            [22.689082, 54.972925],
            [22.704356, 54.973794],
            [22.729375, 54.960698],
            [22.746132, 54.938098],
            [22.766713, 54.932543],
            [22.749657, 54.922907],
            [22.762439, 54.916885],
            [22.783194, 54.924872],
            [22.798548, 54.902148],
            [22.814288, 54.911632],
            [22.836036, 54.904103],
            [22.855236, 54.887883],
            [22.839617, 54.864663],
            [22.869419, 54.852627],
            [22.858564, 54.839297],
            [22.876717, 54.833404],
            [22.873647, 54.788933],
            [22.848142, 54.785081],
            [22.859162, 54.776716],
            [22.844264, 54.766893],
            [22.813003, 54.766482],
            [22.805731, 54.74747],
            [22.776739, 54.7492],
            [22.763728, 54.736297],
            [22.743629, 54.731513],
            [22.739093, 54.716027],
            [22.748961, 54.706742],
            [22.723254, 54.685878],
            [22.740577, 54.673283],
            [22.730262, 54.669641],
            [22.756186, 54.654007],
            [22.741729, 54.642638],
            [22.745416, 54.62682],
            [22.726252, 54.622592],
            [22.719999, 54.606612],
            [22.686001, 54.590819],
            [22.6869, 54.579622],
            [22.713142, 54.563696],
            [22.680587, 54.53379],
            [22.696154, 54.505455],
            [22.703643, 54.468007],
            [22.701062, 54.453663],
            [22.736342, 54.442901],
            [22.766094, 54.387239],
            [22.792047, 54.36332],
            [22.680587, 54.360287],
            [22.641544, 54.35377],
            [22.571368, 54.353872],
            [22.528228, 54.34916],
            [22.421774, 54.34845],
            [22.318666, 54.340537],
            [22.229278, 54.340503],
            [22.225682, 54.343782],
            [22.138527, 54.336464],
            [22.009961, 54.336209],
            [21.969879, 54.332681],
            [21.913578, 54.333704],
            [21.796705, 54.331786],
            [21.677085, 54.326863],
            [21.635351, 54.32741],
            [21.495703, 54.324015],
            [21.445369, 54.318778],
            [21.43698, 54.326251],
            [21.379499, 54.331043],
            [21.323899, 54.329299],
            [21.302913, 54.333537],
            [21.274325, 54.328393],
            [21.196499, 54.337906],
            [21.164219, 54.337304],
            [21.090048, 54.347392],
            [21.038111, 54.348571],
            [21.00662, 54.353611],
            [20.819355, 54.359768],
            [20.742094, 54.369209],
            [20.69277, 54.37212],
            [20.648217, 54.371226],
            [20.631092, 54.366337],
            [20.584409, 54.3779],
            [20.438368, 54.387868],
            [20.361326, 54.395832],
            [20.332429, 54.401054],
            [20.06874, 54.421806],
            [20.006814, 54.424719],
            [19.813881, 54.442071],
            [19.651013, 54.453089],
            [19.638671, 54.458405],
            [19.685861, 54.487361],
            [19.780689, 54.553317],
            [19.84456, 54.60343],
            [19.877003, 54.637248],
            [19.879799, 54.649611],
            [19.920371, 54.691732],
            [19.93908, 54.71803],
            [19.95881, 54.75995],
            [19.96524, 54.78562],
            [19.95971, 54.81093],
            [19.92669, 54.86852],
            [19.92907, 54.89621],
            [19.946906, 54.928675],
            [19.979779, 54.961212],
            [20.018558, 54.948491],
            [20.070603, 54.948346],
            [20.122967, 54.944029],
            [20.155881, 54.94673],
            [20.216083, 54.960393],
            [20.224911, 54.954876],
            [20.27117, 54.96303],
            [20.29776, 54.94423],
            [20.36994, 54.93758],
            [20.445214, 54.952621],
            [20.54931, 54.98768],
            [20.59987, 55.01065],
            [20.68016, 55.05776],
            [20.74321, 55.10529],
            [20.79191, 55.1479],
            [20.84474, 55.18665],
            [20.928059, 55.255499],
            [20.953488, 55.281096],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5505",
      properties: {
        name: "Калужская область",
        density: 0,
        path: "/world/Russia/Калужская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.334221, 55.271752],
            [35.359539, 55.25922],
            [35.356606, 55.249262],
            [35.396147, 55.234589],
            [35.446271, 55.229013],
            [35.481539, 55.239919],
            [35.508232, 55.240189],
            [35.49696, 55.250028],
            [35.535004, 55.258387],
            [35.560646, 55.250421],
            [35.576835, 55.269049],
            [35.560637, 55.275855],
            [35.57308, 55.290267],
            [35.599991, 55.289704],
            [35.663479, 55.278338],
            [35.706761, 55.290904],
            [35.718431, 55.279478],
            [35.741156, 55.295578],
            [35.793411, 55.261104],
            [35.824452, 55.264877],
            [35.814427, 55.246011],
            [35.842833, 55.232315],
            [35.860387, 55.239271],
            [35.886403, 55.23867],
            [35.888598, 55.247898],
            [35.930252, 55.232077],
            [35.958628, 55.241411],
            [35.984835, 55.224288],
            [36.022026, 55.214361],
            [36.038665, 55.227703],
            [36.048653, 55.215264],
            [36.070793, 55.218157],
            [36.103751, 55.210854],
            [36.115347, 55.184706],
            [36.13988, 55.174077],
            [36.166193, 55.190972],
            [36.192776, 55.193391],
            [36.237559, 55.188104],
            [36.249227, 55.190781],
            [36.264784, 55.171484],
            [36.313471, 55.185734],
            [36.295486, 55.190839],
            [36.312246, 55.210303],
            [36.328046, 55.215914],
            [36.342833, 55.253636],
            [36.362528, 55.249135],
            [36.383547, 55.258372],
            [36.368929, 55.277218],
            [36.389854, 55.281104],
            [36.38434, 55.293977],
            [36.417366, 55.303984],
            [36.435488, 55.290908],
            [36.462573, 55.293134],
            [36.461143, 55.301174],
            [36.502358, 55.304707],
            [36.51643, 55.296301],
            [36.535195, 55.307073],
            [36.536994, 55.324067],
            [36.590025, 55.337704],
            [36.600825, 55.35128],
            [36.617343, 55.34872],
            [36.604941, 55.333068],
            [36.625885, 55.310121],
            [36.607751, 55.311175],
            [36.612989, 55.29431],
            [36.600553, 55.2829],
            [36.621329, 55.265391],
            [36.659326, 55.27749],
            [36.696707, 55.261768],
            [36.726683, 55.260325],
            [36.763246, 55.269828],
            [36.788721, 55.256409],
            [36.799951, 55.242472],
            [36.794096, 55.226977],
            [36.811717, 55.21252],
            [36.80179, 55.206089],
            [36.813606, 55.194054],
            [36.840339, 55.207888],
            [36.872101, 55.215076],
            [36.855839, 55.227342],
            [36.872234, 55.232673],
            [36.907475, 55.232415],
            [36.93724, 55.241391],
            [36.950132, 55.238262],
            [36.947708, 55.218168],
            [36.961636, 55.208977],
            [36.982938, 55.180355],
            [37.015248, 55.183382],
            [37.01989, 55.16112],
            [37.045003, 55.142201],
            [37.059874, 55.127168],
            [37.057237, 55.109546],
            [37.070936, 55.114381],
            [37.116322, 55.098063],
            [37.111292, 55.077172],
            [37.118159, 55.063488],
            [37.083806, 55.05655],
            [37.095868, 55.032409],
            [37.122318, 55.023302],
            [37.149899, 55.000159],
            [37.134298, 54.991398],
            [37.171741, 54.968152],
            [37.160496, 54.950444],
            [37.192994, 54.923256],
            [37.16715, 54.895109],
            [37.175909, 54.878547],
            [37.165285, 54.86658],
            [37.2011, 54.853453],
            [37.223933, 54.853203],
            [37.235728, 54.845297],
            [37.261369, 54.850796],
            [37.272035, 54.839777],
            [37.26874, 54.842015],
            [37.229558, 54.805061],
            [37.243372, 54.765528],
            [37.234961, 54.752882],
            [37.196827, 54.735545],
            [37.20708, 54.722359],
            [37.231864, 54.718342],
            [37.245222, 54.707979],
            [37.210464, 54.676738],
            [37.192101, 54.634859],
            [37.162569, 54.62163],
            [37.147374, 54.606117],
            [37.142571, 54.569363],
            [37.121643, 54.551012],
            [37.077294, 54.54509],
            [37.055458, 54.561195],
            [37.059952, 54.581004],
            [37.031293, 54.584843],
            [37.027094, 54.593696],
            [37.004506, 54.591329],
            [36.990644, 54.602404],
            [36.970507, 54.604839],
            [36.942129, 54.592265],
            [36.920734, 54.589374],
            [36.894968, 54.563494],
            [36.922083, 54.550073],
            [36.914239, 54.547388],
            [36.942882, 54.528016],
            [36.941926, 54.50975],
            [36.90514, 54.510962],
            [36.875511, 54.501732],
            [36.881819, 54.485188],
            [36.854939, 54.478772],
            [36.890308, 54.460459],
            [36.875826, 54.446976],
            [36.891884, 54.427655],
            [36.907202, 54.438313],
            [36.941106, 54.446766],
            [36.942246, 54.435037],
            [36.972763, 54.429393],
            [36.957966, 54.419652],
            [36.983973, 54.388175],
            [36.959388, 54.363503],
            [36.931142, 54.342983],
            [36.969779, 54.331732],
            [36.964199, 54.31973],
            [36.974841, 54.299628],
            [36.966846, 54.291658],
            [36.926247, 54.291733],
            [36.891825, 54.286082],
            [36.884255, 54.279213],
            [36.840507, 54.286994],
            [36.798817, 54.267073],
            [36.790644, 54.276346],
            [36.730522, 54.285922],
            [36.697801, 54.294038],
            [36.689024, 54.306458],
            [36.67044, 54.30027],
            [36.680245, 54.289297],
            [36.647955, 54.277873],
            [36.64058, 54.285608],
            [36.587333, 54.278997],
            [36.550636, 54.286192],
            [36.545239, 54.277377],
            [36.500537, 54.282863],
            [36.500357, 54.290598],
            [36.471035, 54.282593],
            [36.490824, 54.256869],
            [36.483, 54.24666],
            [36.446033, 54.238699],
            [36.453499, 54.233033],
            [36.429394, 54.226107],
            [36.435511, 54.217023],
            [36.390178, 54.223858],
            [36.377139, 54.202666],
            [36.362531, 54.211163],
            [36.337222, 54.211903],
            [36.315943, 54.232107],
            [36.309218, 54.220033],
            [36.267737, 54.221463],
            [36.240872, 54.215805],
            [36.247708, 54.200875],
            [36.234487, 54.186214],
            [36.234578, 54.171553],
            [36.205167, 54.155902],
            [36.174225, 54.173306],
            [36.148502, 54.168538],
            [36.141666, 54.176813],
            [36.060266, 54.18023],
            [36.035892, 54.173484],
            [35.987861, 54.195609],
            [35.983749, 54.182827],
            [35.969872, 54.181488],
            [35.971622, 54.167903],
            [35.988941, 54.165118],
            [36.019792, 54.175642],
            [36.087341, 54.144747],
            [36.07367, 54.127703],
            [36.109827, 54.143263],
            [36.130604, 54.143983],
            [36.142837, 54.13418],
            [36.115405, 54.108635],
            [36.103622, 54.118439],
            [36.069084, 54.11565],
            [36.070163, 54.107465],
            [36.105151, 54.106206],
            [36.12614, 54.091224],
            [36.140949, 54.100316],
            [36.176657, 54.102655],
            [36.176208, 54.089973],
            [36.207958, 54.092492],
            [36.211557, 54.06245],
            [36.176839, 54.052511],
            [36.154173, 54.054264],
            [36.12818, 54.031463],
            [36.124674, 54.011091],
            [36.09868, 54.006953],
            [36.093861, 53.98658],
            [36.120219, 53.984145],
            [36.128371, 53.964773],
            [36.156774, 53.956237],
            [36.141287, 53.940373],
            [36.114505, 53.943178],
            [36.110799, 53.95387],
            [36.066021, 53.961279],
            [36.047179, 53.95588],
            [36.055859, 53.946353],
            [36.025796, 53.933385],
            [36.02008, 53.924704],
            [35.981549, 53.908719],
            [35.981867, 53.892205],
            [36.001558, 53.873151],
            [35.975517, 53.856161],
            [35.92153, 53.851237],
            [35.90491, 53.854942],
            [35.900676, 53.840863],
            [35.918672, 53.840016],
            [35.938151, 53.827419],
            [35.965145, 53.824244],
            [35.96144, 53.807307],
            [35.973197, 53.800197],
            [35.949265, 53.783711],
            [35.941222, 53.790475],
            [35.917532, 53.777077],
            [35.93345, 53.752785],
            [35.958421, 53.751863],
            [35.954414, 53.733176],
            [35.963249, 53.715868],
            [35.974575, 53.723784],
            [35.999686, 53.720999],
            [36.028752, 53.726162],
            [36.030412, 53.711979],
            [36.053512, 53.715563],
            [36.070743, 53.699502],
            [36.074004, 53.681861],
            [36.028584, 53.673355],
            [36.003268, 53.659763],
            [35.991189, 53.626273],
            [35.960637, 53.625155],
            [35.954477, 53.647133],
            [35.925304, 53.652394],
            [35.926401, 53.639526],
            [35.909112, 53.64065],
            [35.875331, 53.596266],
            [35.822518, 53.587647],
            [35.823209, 53.573658],
            [35.809128, 53.564278],
            [35.809175, 53.550662],
            [35.794394, 53.539902],
            [35.794964, 53.52476],
            [35.772057, 53.505952],
            [35.758281, 53.520329],
            [35.719842, 53.521993],
            [35.700118, 53.530125],
            [35.685123, 53.545414],
            [35.629667, 53.541586],
            [35.612642, 53.535],
            [35.623268, 53.523857],
            [35.596106, 53.51887],
            [35.581477, 53.509856],
            [35.583921, 53.496636],
            [35.55864, 53.485316],
            [35.547629, 53.470489],
            [35.555733, 53.454924],
            [35.530145, 53.42868],
            [35.501566, 53.422196],
            [35.507664, 53.432196],
            [35.528826, 53.430185],
            [35.544013, 53.45684],
            [35.528502, 53.443965],
            [35.494053, 53.446348],
            [35.430717, 53.443595],
            [35.439174, 53.435119],
            [35.465689, 53.432896],
            [35.463722, 53.418108],
            [35.426047, 53.414174],
            [35.413994, 53.397572],
            [35.384099, 53.397562],
            [35.383564, 53.337393],
            [35.378812, 53.334278],
            [35.306889, 53.333934],
            [35.266824, 53.31914],
            [35.247443, 53.319465],
            [35.255724, 53.289914],
            [35.238381, 53.280351],
            [35.179103, 53.282277],
            [35.141099, 53.279123],
            [35.082703, 53.29898],
            [35.074702, 53.309023],
            [35.084134, 53.32481],
            [35.066726, 53.333814],
            [35.030479, 53.334486],
            [35.019671, 53.345183],
            [35.023286, 53.362393],
            [34.982773, 53.373609],
            [34.944937, 53.376376],
            [34.899124, 53.37333],
            [34.8599, 53.362124],
            [34.860578, 53.389485],
            [34.83255, 53.377487],
            [34.796145, 53.38555],
            [34.781648, 53.380471],
            [34.733558, 53.383212],
            [34.700278, 53.37839],
            [34.63795, 53.383027],
            [34.638643, 53.398411],
            [34.605119, 53.41378],
            [34.606004, 53.426675],
            [34.571712, 53.428653],
            [34.565359, 53.457194],
            [34.568537, 53.470416],
            [34.544654, 53.481917],
            [34.541039, 53.496995],
            [34.519712, 53.500843],
            [34.517695, 53.543982],
            [34.501547, 53.543076],
            [34.497578, 53.58304],
            [34.51192, 53.584867],
            [34.497294, 53.615777],
            [34.472724, 53.635961],
            [34.500615, 53.648078],
            [34.488385, 53.654692],
            [34.496489, 53.673982],
            [34.47485, 53.671996],
            [34.470559, 53.683979],
            [34.441641, 53.682635],
            [34.417432, 53.700391],
            [34.425024, 53.708337],
            [34.407544, 53.734498],
            [34.369201, 53.732143],
            [34.349012, 53.750961],
            [34.355956, 53.767889],
            [34.307983, 53.80131],
            [34.291796, 53.825985],
            [34.270841, 53.822407],
            [34.227645, 53.839432],
            [34.231483, 53.863876],
            [34.22499, 53.886009],
            [34.201182, 53.912152],
            [34.18062, 53.900824],
            [34.132425, 53.88697],
            [34.114814, 53.886894],
            [34.074014, 53.86795],
            [34.047784, 53.869406],
            [34.045703, 53.86212],
            [34.002403, 53.859622],
            [34.011979, 53.865659],
            [33.99413, 53.884046],
            [33.963362, 53.880059],
            [33.964359, 53.873831],
            [33.931473, 53.874328],
            [33.911543, 53.867228],
            [33.888249, 53.872054],
            [33.889681, 53.879279],
            [33.838235, 53.881022],
            [33.80622, 53.89639],
            [33.801672, 53.937575],
            [33.83655, 53.937825],
            [33.826958, 53.948849],
            [33.798495, 53.949689],
            [33.797497, 53.965572],
            [33.813628, 53.975491],
            [33.817427, 53.992681],
            [33.798929, 53.990937],
            [33.751095, 53.998145],
            [33.73926, 54.012533],
            [33.715717, 54.023977],
            [33.655052, 54.027075],
            [33.627461, 54.021406],
            [33.619613, 54.029877],
            [33.598313, 54.022278],
            [33.588783, 54.034797],
            [33.570846, 54.02072],
            [33.527061, 54.010022],
            [33.52158, 53.998749],
            [33.504592, 54.016586],
            [33.510339, 54.023592],
            [33.473335, 54.035273],
            [33.501965, 54.03892],
            [33.48981, 54.058097],
            [33.465289, 54.049088],
            [33.451051, 54.055699],
            [33.452535, 54.082574],
            [33.484811, 54.098848],
            [33.493185, 54.088044],
            [33.541262, 54.093987],
            [33.56368, 54.106581],
            [33.527039, 54.10274],
            [33.536399, 54.120356],
            [33.572863, 54.123901],
            [33.559086, 54.147163],
            [33.568944, 54.164214],
            [33.564217, 54.175693],
            [33.629445, 54.187207],
            [33.628634, 54.207768],
            [33.611371, 54.223628],
            [33.625257, 54.236838],
            [33.604189, 54.242408],
            [33.603378, 54.258884],
            [33.630288, 54.25767],
            [33.621204, 54.268507],
            [33.65186, 54.268643],
            [33.655371, 54.276509],
            [33.687777, 54.295332],
            [33.671662, 54.305132],
            [33.689941, 54.329382],
            [33.642808, 54.340489],
            [33.614987, 54.373981],
            [33.572328, 54.393617],
            [33.625789, 54.397277],
            [33.637403, 54.410681],
            [33.627139, 54.419459],
            [33.660765, 54.448698],
            [33.702224, 54.465884],
            [33.686423, 54.475101],
            [33.670623, 54.469699],
            [33.633653, 54.476861],
            [33.659547, 54.492927],
            [33.649148, 54.507816],
            [33.693039, 54.514299],
            [33.694388, 54.533915],
            [33.709243, 54.545834],
            [33.760696, 54.564877],
            [33.771905, 54.573655],
            [33.792028, 54.555289],
            [33.819038, 54.556505],
            [33.822819, 54.565587],
            [33.855906, 54.571935],
            [33.864415, 54.559645],
            [33.89021, 54.554784],
            [33.913437, 54.576257],
            [33.950846, 54.582335],
            [33.963541, 54.574772],
            [33.987039, 54.57977],
            [34.029024, 54.555896],
            [34.062532, 54.566536],
            [34.084276, 54.54611],
            [34.119524, 54.562485],
            [34.135054, 54.564376],
            [34.152746, 54.553944],
            [34.171655, 54.518257],
            [34.181584, 54.520074],
            [34.222496, 54.50422],
            [34.242752, 54.508157],
            [34.252479, 54.521315],
            [34.301979, 54.515637],
            [34.418526, 54.513669],
            [34.444682, 54.516637],
            [34.435076, 54.545138],
            [34.466009, 54.562432],
            [34.458313, 54.577815],
            [34.48935, 54.578494],
            [34.488192, 54.597902],
            [34.520689, 54.598645],
            [34.51787, 54.660966],
            [34.481244, 54.658595],
            [34.464755, 54.682214],
            [34.448093, 54.678798],
            [34.402897, 54.680678],
            [34.406122, 54.688705],
            [34.434798, 54.688873],
            [34.441275, 54.679738],
            [34.475936, 54.691312],
            [34.499166, 54.691335],
            [34.51163, 54.677671],
            [34.534722, 54.672009],
            [34.542704, 54.681139],
            [34.575505, 54.6761],
            [34.586254, 54.683524],
            [34.620999, 54.661333],
            [34.66963, 54.679804],
            [34.684616, 54.674576],
            [34.702386, 54.682468],
            [34.706239, 54.71152],
            [34.758679, 54.705064],
            [34.789711, 54.707565],
            [34.814159, 54.69443],
            [34.83396, 54.708904],
            [34.83238, 54.721782],
            [34.867382, 54.736435],
            [34.87143, 54.753879],
            [34.893736, 54.757531],
            [34.90348, 54.778007],
            [34.928462, 54.78371],
            [34.905368, 54.787525],
            [34.888351, 54.809167],
            [34.894292, 54.823077],
            [34.876871, 54.825271],
            [34.870656, 54.866799],
            [34.88038, 54.875307],
            [34.871971, 54.903384],
            [34.906713, 54.90171],
            [34.920623, 54.910488],
            [34.906171, 54.930071],
            [34.916352, 54.934925],
            [34.944143, 54.919747],
            [34.953037, 54.895229],
            [34.990916, 54.897844],
            [34.976256, 54.914239],
            [35.000037, 54.915235],
            [35.021277, 54.933515],
            [35.006546, 54.94268],
            [35.043514, 54.945521],
            [35.030264, 54.95778],
            [35.05219, 54.960383],
            [35.051015, 54.969835],
            [35.092722, 54.975884],
            [35.124703, 54.976239],
            [35.122086, 54.991965],
            [35.105972, 54.998502],
            [35.126778, 55.004857],
            [35.100232, 55.012822],
            [35.117465, 55.016978],
            [35.114697, 55.037647],
            [35.136925, 55.043155],
            [35.149835, 55.03605],
            [35.167349, 55.052029],
            [35.187268, 55.049483],
            [35.183581, 55.059315],
            [35.208929, 55.062557],
            [35.231645, 55.092252],
            [35.25643, 55.111428],
            [35.274322, 55.111455],
            [35.288992, 55.163844],
            [35.280461, 55.179638],
            [35.303937, 55.18591],
            [35.321577, 55.201011],
            [35.345212, 55.20588],
            [35.330108, 55.213828],
            [35.342516, 55.233722],
            [35.323486, 55.242744],
            [35.308391, 55.239139],
            [35.297346, 55.253492],
            [35.320893, 55.25409],
            [35.334221, 55.271752],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5474",
      properties: {
        name: "Камчатский край",
        density: 0,
        path: "/world/Russia/Камчатский край",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [163.502542, 58.469103],
              [163.48999, 58.448475],
              [163.441053, 58.489363],
              [163.449808, 58.501502],
              [163.440414, 58.520333],
              [163.408325, 58.542724],
              [163.414424, 58.554064],
              [163.435662, 58.561108],
              [163.513315, 58.566894],
              [163.572124, 58.578698],
              [163.597282, 58.599199],
              [163.627118, 58.632545],
              [163.647299, 58.681909],
              [163.66585, 58.70463],
              [163.718179, 58.73972],
              [163.736132, 58.760632],
              [163.772393, 58.822163],
              [163.773119, 58.885397],
              [163.834508, 58.934601],
              [163.871787, 58.968208],
              [163.918724, 58.98883],
              [163.920527, 58.994604],
              [163.864978, 59.009487],
              [163.769013, 59.014755],
              [163.68815, 58.987723],
              [163.726103, 59.007428],
              [163.766974, 59.016271],
              [163.886931, 59.021196],
              [164.000718, 59.034539],
              [164.107344, 59.059121],
              [164.131413, 59.070291],
              [164.205581, 59.090782],
              [164.311029, 59.113077],
              [164.355936, 59.131535],
              [164.395105, 59.176109],
              [164.424367, 59.182495],
              [164.445493, 59.193821],
              [164.506218, 59.208935],
              [164.547866, 59.229818],
              [164.589724, 59.239594],
              [164.585664, 59.218241],
              [164.595632, 59.187364],
              [164.639297, 59.178659],
              [164.631702, 59.166096],
              [164.640477, 59.129375],
              [164.674605, 59.091841],
              [164.694782, 59.080387],
              [164.707985, 59.059709],
              [164.730031, 59.047988],
              [164.729408, 59.033187],
              [164.708549, 59.009908],
              [164.685956, 58.969849],
              [164.689353, 58.937429],
              [164.703808, 58.926994],
              [164.698971, 58.913393],
              [164.66901, 58.890027],
              [164.623291, 58.865331],
              [164.567385, 58.870787],
              [164.521555, 58.861155],
              [164.48638, 58.86729],
              [164.443455, 58.863273],
              [164.424346, 58.853591],
              [164.332749, 58.838817],
              [164.317197, 58.826193],
              [164.267501, 58.806979],
              [164.24116, 58.80478],
              [164.209329, 58.816107],
              [164.184211, 58.81403],
              [164.139152, 58.800427],
              [164.116737, 58.779927],
              [164.079454, 58.763796],
              [164.00218, 58.743767],
              [163.9443, 58.703629],
              [163.918407, 58.69791],
              [163.870174, 58.699032],
              [163.809684, 58.670073],
              [163.781025, 58.646382],
              [163.713473, 58.613726],
              [163.677038, 58.60414],
              [163.677088, 58.589805],
              [163.623687, 58.588732],
              [163.58034, 58.569573],
              [163.574925, 58.545961],
              [163.555462, 58.542927],
              [163.543538, 58.523464],
              [163.507991, 58.501936],
              [163.502542, 58.469103],
            ],
          ],
          [
            [
              [168.070494, 54.526167],
              [168.090121, 54.517723],
              [168.102843, 54.502534],
              [168.089798, 54.495949],
              [168.057645, 54.513424],
              [168.022141, 54.539557],
              [167.96809, 54.553837],
              [167.936115, 54.568968],
              [167.924025, 54.591041],
              [167.881279, 54.607441],
              [167.876286, 54.614862],
              [167.837097, 54.63539],
              [167.789515, 54.650076],
              [167.75281, 54.649635],
              [167.70092, 54.680013],
              [167.690541, 54.696899],
              [167.653255, 54.715271],
              [167.617928, 54.723342],
              [167.612651, 54.734217],
              [167.56033, 54.750578],
              [167.52824, 54.768674],
              [167.492298, 54.796704],
              [167.458359, 54.81469],
              [167.443203, 54.839207],
              [167.451525, 54.844676],
              [167.435608, 54.875227],
              [167.462618, 54.870071],
              [167.487849, 54.854185],
              [167.521539, 54.853233],
              [167.521723, 54.841956],
              [167.54309, 54.8287],
              [167.56423, 54.826206],
              [167.54304, 54.79975],
              [167.593824, 54.796369],
              [167.63264, 54.786309],
              [167.652025, 54.791267],
              [167.68031, 54.783214],
              [167.68345, 54.757011],
              [167.70512, 54.763062],
              [167.73138, 54.761577],
              [167.735856, 54.739185],
              [167.75495, 54.736703],
              [167.746036, 54.719482],
              [167.760026, 54.697385],
              [167.78951, 54.682232],
              [167.83606, 54.690247],
              [167.851753, 54.680655],
              [167.88063, 54.645123],
              [167.9413, 54.65007],
              [167.925896, 54.626357],
              [167.965002, 54.58415],
              [167.995069, 54.579053],
              [168.00849, 54.557707],
              [168.036324, 54.534718],
              [168.070494, 54.526167],
            ],
          ],
          [
            [
              [166.59527, 54.716653],
              [166.577723, 54.720469],
              [166.562829, 54.735904],
              [166.56045, 54.754633],
              [166.515553, 54.773902],
              [166.503689, 54.788522],
              [166.486405, 54.788607],
              [166.488468, 54.808562],
              [166.508102, 54.817797],
              [166.485479, 54.835878],
              [166.447285, 54.822636],
              [166.430782, 54.832693],
              [166.407961, 54.827196],
              [166.390151, 54.838288],
              [166.376477, 54.827685],
              [166.368152, 54.837385],
              [166.332383, 54.856225],
              [166.326381, 54.869482],
              [166.306817, 54.868798],
              [166.283583, 54.892354],
              [166.2863, 54.914866],
              [166.244753, 54.915682],
              [166.23329, 54.933345],
              [166.200583, 54.94046],
              [166.176039, 54.960806],
              [166.180139, 54.979208],
              [166.157014, 55.003507],
              [166.155347, 55.015305],
              [166.104463, 55.037036],
              [166.072063, 55.076256],
              [166.08656, 55.084068],
              [166.079195, 55.100782],
              [166.083546, 55.121233],
              [166.051412, 55.148407],
              [166.042341, 55.169231],
              [166.007903, 55.187576],
              [165.981402, 55.193043],
              [165.99881, 55.199945],
              [165.999308, 55.210826],
              [165.978433, 55.227131],
              [165.917194, 55.253089],
              [165.899193, 55.25015],
              [165.892669, 55.259162],
              [165.801776, 55.277337],
              [165.770627, 55.278945],
              [165.75371, 55.285838],
              [165.827355, 55.309948],
              [165.837651, 55.308469],
              [165.922451, 55.325146],
              [165.936997, 55.332462],
              [165.966107, 55.36553],
              [165.994689, 55.357745],
              [166.017655, 55.359221],
              [166.065378, 55.344985],
              [166.084397, 55.333804],
              [166.155871, 55.319575],
              [166.198032, 55.317632],
              [166.210451, 55.312515],
              [166.237983, 55.316978],
              [166.256772, 55.328823],
              [166.300743, 55.299678],
              [166.266628, 55.285693],
              [166.258714, 55.269639],
              [166.242435, 55.265452],
              [166.25578, 55.254814],
              [166.256864, 55.239436],
              [166.24132, 55.20092],
              [166.248274, 55.179847],
              [166.266018, 55.16071],
              [166.294762, 55.115842],
              [166.351367, 55.091663],
              [166.349501, 55.076059],
              [166.3725, 55.050189],
              [166.388582, 55.044371],
              [166.399068, 55.01249],
              [166.43034, 54.983183],
              [166.444742, 54.962521],
              [166.48691, 54.947156],
              [166.509125, 54.934332],
              [166.535115, 54.927603],
              [166.549294, 54.914615],
              [166.593291, 54.914046],
              [166.587041, 54.890818],
              [166.643356, 54.865991],
              [166.65128, 54.847953],
              [166.637405, 54.839687],
              [166.653473, 54.825637],
              [166.652913, 54.801054],
              [166.671133, 54.769809],
              [166.675105, 54.747981],
              [166.671911, 54.713443],
              [166.679341, 54.695153],
              [166.671837, 54.679375],
              [166.64586, 54.687415],
              [166.63897, 54.697499],
              [166.59527, 54.716653],
            ],
          ],
          [
            [
              [162.465934, 61.664844],
              [162.443698, 61.689541],
              [162.447849, 61.718583],
              [162.464925, 61.734541],
              [162.454713, 61.75018],
              [162.424074, 61.751297],
              [162.39184, 61.763266],
              [162.423597, 61.773079],
              [162.474504, 61.799887],
              [162.432536, 61.808665],
              [162.402696, 61.829251],
              [162.38642, 61.825581],
              [162.37174, 61.858055],
              [162.383709, 61.866512],
              [162.36887, 61.883428],
              [162.376211, 61.895874],
              [162.3427, 61.89779],
              [162.332169, 61.910476],
              [162.290041, 61.926275],
              [162.298341, 61.944227],
              [162.322277, 61.946142],
              [162.343821, 61.960504],
              [162.369514, 61.963934],
              [162.40079, 61.951327],
              [162.443875, 61.942071],
              [162.49143, 61.954996],
              [162.491751, 61.969836],
              [162.508666, 61.976698],
              [162.484731, 61.998002],
              [162.532604, 61.995927],
              [162.53803, 62.019703],
              [162.514096, 62.035981],
              [162.527501, 62.03598],
              [162.511066, 62.065981],
              [162.524472, 62.083215],
              [162.480589, 62.089679],
              [162.456812, 62.086966],
              [162.390268, 62.095744],
              [162.365535, 62.108591],
              [162.289735, 62.103804],
              [162.290853, 62.113858],
              [162.248885, 62.123752],
              [162.220481, 62.140588],
              [162.168011, 62.149184],
              [162.174844, 62.167318],
              [162.260402, 62.200762],
              [162.306035, 62.182111],
              [162.332028, 62.205126],
              [162.388178, 62.22318],
              [162.397505, 62.238953],
              [162.439616, 62.233198],
              [162.445519, 62.240936],
              [162.503107, 62.237686],
              [162.534257, 62.241356],
              [162.573146, 62.255393],
              [162.568583, 62.263428],
              [162.595765, 62.272306],
              [162.617191, 62.251672],
              [162.66471, 62.25683],
              [162.690402, 62.252861],
              [162.735341, 62.265161],
              [162.748636, 62.293334],
              [162.786235, 62.311785],
              [162.777507, 62.343182],
              [162.791595, 62.354293],
              [162.773343, 62.367189],
              [162.779496, 62.408258],
              [162.760946, 62.420906],
              [162.781384, 62.450964],
              [162.77087, 62.46763],
              [162.68119, 62.571277],
              [162.704812, 62.596047],
              [162.750445, 62.607156],
              [162.766021, 62.621739],
              [162.800543, 62.620449],
              [162.80025, 62.63513],
              [162.827429, 62.66117],
              [162.82019, 62.690682],
              [162.805906, 62.699908],
              [162.777435, 62.697826],
              [162.759282, 62.70596],
              [162.715037, 62.704374],
              [162.711664, 62.693759],
              [162.662757, 62.689991],
              [162.626848, 62.714047],
              [162.609587, 62.713452],
              [162.58707, 62.739939],
              [162.557707, 62.744999],
              [162.576062, 62.773122],
              [162.62854, 62.778082],
              [162.606717, 62.798964],
              [162.57269, 62.809281],
              [162.564559, 62.830213],
              [162.544025, 62.839786],
              [162.533806, 62.833636],
              [162.48292, 62.837704],
              [162.47766, 62.85055],
              [162.440857, 62.858785],
              [162.41447, 62.874012],
              [162.373302, 62.875848],
              [162.323404, 62.887158],
              [162.304656, 62.900154],
              [162.279558, 62.903328],
              [162.25655, 62.934577],
              [162.269445, 62.96652],
              [162.289286, 62.971876],
              [162.281749, 63.005307],
              [162.320836, 63.030107],
              [162.29187, 63.0434],
              [162.304371, 63.06453],
              [162.358338, 63.08298],
              [162.362308, 63.098852],
              [162.379768, 63.108078],
              [162.438891, 63.091411],
              [162.452978, 63.099347],
              [162.511309, 63.112639],
              [162.578171, 63.111745],
              [162.597219, 63.12345],
              [162.579563, 63.1546],
              [162.697812, 63.166998],
              [162.734517, 63.160847],
              [162.781144, 63.194177],
              [162.762297, 63.206677],
              [162.778566, 63.213621],
              [162.778369, 63.229295],
              [162.735715, 63.265603],
              [162.762699, 63.278895],
              [162.760518, 63.291593],
              [162.784129, 63.306274],
              [162.766997, 63.323496],
              [162.73949, 63.327901],
              [162.719849, 63.343773],
              [162.676994, 63.344965],
              [162.656031, 63.365136],
              [162.65821, 63.38458],
              [162.710527, 63.381272],
              [162.758541, 63.383453],
              [162.774415, 63.40002],
              [162.82104, 63.406169],
              [162.82481, 63.41728],
              [162.876593, 63.415096],
              [162.871833, 63.431564],
              [162.9008, 63.43573],
              [162.910722, 63.454181],
              [162.933736, 63.447633],
              [162.971038, 63.480369],
              [162.961319, 63.503979],
              [162.945844, 63.513503],
              [162.948028, 63.528581],
              [162.974218, 63.541179],
              [162.963901, 63.545346],
              [162.97402, 63.580562],
              [162.996049, 63.624408],
              [162.965298, 63.639487],
              [162.925816, 63.648019],
              [162.892683, 63.645441],
              [162.860147, 63.672424],
              [162.884552, 63.689288],
              [162.875229, 63.714187],
              [162.856778, 63.722917],
              [162.807972, 63.729267],
              [162.77769, 63.724776],
              [162.768887, 63.736013],
              [162.725636, 63.752084],
              [162.757185, 63.784027],
              [162.711949, 63.785416],
              [162.724053, 63.795534],
              [162.723658, 63.819938],
              [162.744292, 63.824898],
              [162.757271, 63.843031],
              [162.78219, 63.851582],
              [162.8435, 63.849299],
              [162.87901, 63.875488],
              [162.940992, 63.866758],
              [162.939724, 63.877074],
              [162.906868, 63.886797],
              [162.90342, 63.897907],
              [162.95421, 63.922905],
              [162.93219, 63.941158],
              [162.932787, 63.966553],
              [162.91017, 63.980244],
              [162.823483, 64.008662],
              [162.848621, 64.052074],
              [162.836575, 64.069731],
              [162.851947, 64.087386],
              [162.82349, 64.090502],
              [162.80189, 64.103381],
              [162.818715, 64.115013],
              [162.808538, 64.129553],
              [162.81789, 64.139108],
              [162.856024, 64.138282],
              [162.84759, 64.150418],
              [162.867396, 64.162682],
              [162.907382, 64.161915],
              [162.961805, 64.170473],
              [162.990678, 64.185611],
              [163.02683, 64.17654],
              [163.055575, 64.18331],
              [163.0672, 64.166575],
              [163.14347, 64.167021],
              [163.14385, 64.157184],
              [163.179747, 64.157311],
              [163.200444, 64.170214],
              [163.22881, 64.20477],
              [163.216418, 64.225082],
              [163.220891, 64.252932],
              [163.277104, 64.270049],
              [163.253215, 64.277843],
              [163.255132, 64.292853],
              [163.272763, 64.301284],
              [163.353246, 64.29694],
              [163.347243, 64.319424],
              [163.372029, 64.343313],
              [163.4101, 64.350977],
              [163.44472, 64.342928],
              [163.49569, 64.35813],
              [163.469251, 64.377293],
              [163.422366, 64.37065],
              [163.38979, 64.377422],
              [163.39554, 64.393071],
              [163.426456, 64.399905],
              [163.407294, 64.409998],
              [163.414452, 64.445768],
              [163.373188, 64.447046],
              [163.354154, 64.457841],
              [163.362332, 64.474896],
              [163.299608, 64.503513],
              [163.272142, 64.503258],
              [163.235095, 64.512329],
              [163.212101, 64.535069],
              [163.192556, 64.536858],
              [163.220918, 64.550143],
              [163.187064, 64.558575],
              [163.171991, 64.574481],
              [163.203803, 64.591471],
              [163.17736, 64.619065],
              [163.192309, 64.636822],
              [163.211855, 64.633117],
              [163.216327, 64.645765],
              [163.244945, 64.66103],
              [163.2429, 64.672145],
              [163.264824, 64.693339],
              [163.262112, 64.715055],
              [163.212468, 64.722993],
              [163.21138, 64.734079],
              [163.169334, 64.740367],
              [163.170196, 64.762891],
              [163.135555, 64.767094],
              [163.125913, 64.793097],
              [163.207603, 64.813802],
              [163.266608, 64.79718],
              [163.317217, 64.805994],
              [163.357314, 64.80002],
              [163.35488, 64.815209],
              [163.405424, 64.832808],
              [163.41337, 64.854646],
              [163.468452, 64.868587],
              [163.488276, 64.86873],
              [163.490903, 64.899435],
              [163.503285, 64.916112],
              [163.544892, 64.928587],
              [163.563752, 64.913944],
              [163.60947, 64.922849],
              [163.643064, 64.91021],
              [163.643666, 64.892442],
              [163.680697, 64.885551],
              [163.721588, 64.889874],
              [163.776489, 64.886393],
              [163.810464, 64.890064],
              [163.800962, 64.881779],
              [163.825759, 64.873931],
              [163.828923, 64.861595],
              [163.907742, 64.848028],
              [163.930515, 64.86051],
              [163.965453, 64.855842],
              [164.003172, 64.866564],
              [164.05137, 64.870961],
              [164.061393, 64.896892],
              [164.151001, 64.893137],
              [164.167119, 64.921942],
              [164.197135, 64.921309],
              [164.218241, 64.936724],
              [164.232118, 64.925386],
              [164.270007, 64.926992],
              [164.275386, 64.911113],
              [164.33411, 64.903294],
              [164.362849, 64.886237],
              [164.475409, 64.872934],
              [164.476799, 64.862202],
              [164.521065, 64.844342],
              [164.516425, 64.825019],
              [164.585967, 64.817618],
              [164.607591, 64.795528],
              [164.623763, 64.794789],
              [164.628222, 64.810713],
              [164.67068, 64.805139],
              [164.713618, 64.77616],
              [164.733979, 64.77058],
              [164.797048, 64.767849],
              [164.805576, 64.749227],
              [164.845487, 64.731896],
              [164.869243, 64.71436],
              [164.856123, 64.703272],
              [164.864242, 64.681064],
              [164.922629, 64.674772],
              [164.932037, 64.682897],
              [164.971809, 64.68263],
              [164.986672, 64.673019],
              [165.012585, 64.675938],
              [165.007484, 64.725244],
              [165.02892, 64.728929],
              [165.038547, 64.748619],
              [165.010583, 64.766615],
              [165.00608, 64.786955],
              [165.024031, 64.838332],
              [165.081143, 64.846238],
              [165.121024, 64.845924],
              [165.137212, 64.854638],
              [165.171698, 64.838828],
              [165.186025, 64.846663],
              [165.258326, 64.826718],
              [165.286615, 64.827598],
              [165.302253, 64.811641],
              [165.331882, 64.804919],
              [165.320862, 64.796582],
              [165.348309, 64.783188],
              [165.323488, 64.762923],
              [165.336542, 64.754339],
              [165.387318, 64.764213],
              [165.40296, 64.743801],
              [165.42676, 64.744423],
              [165.445495, 64.733335],
              [165.496323, 64.755802],
              [165.525146, 64.752954],
              [165.539672, 64.739783],
              [165.567374, 64.736539],
              [165.615246, 64.74965],
              [165.685052, 64.750314],
              [165.684222, 64.737327],
              [165.717353, 64.718489],
              [165.758397, 64.714798],
              [165.721248, 64.671251],
              [165.732861, 64.659654],
              [165.780178, 64.644428],
              [165.813857, 64.640283],
              [165.822032, 64.624686],
              [165.901555, 64.608768],
              [165.871587, 64.596993],
              [165.850725, 64.597382],
              [165.820972, 64.586512],
              [165.846329, 64.571211],
              [165.845867, 64.548706],
              [165.879624, 64.551828],
              [165.915267, 64.547998],
              [165.942191, 64.55485],
              [165.961869, 64.549075],
              [166.002052, 64.554937],
              [166.040728, 64.549084],
              [166.06826, 64.574812],
              [166.11977, 64.58527],
              [166.134676, 64.606856],
              [166.185739, 64.620014],
              [166.19155, 64.600692],
              [166.310734, 64.58354],
              [166.311455, 64.594397],
              [166.350502, 64.589989],
              [166.407941, 64.590268],
              [166.427855, 64.57689],
              [166.457794, 64.570296],
              [166.47368, 64.558544],
              [166.537825, 64.55392],
              [166.547253, 64.544697],
              [166.582167, 64.537298],
              [166.588154, 64.52357],
              [166.635199, 64.521465],
              [166.682196, 64.532955],
              [166.685874, 64.540472],
              [166.729994, 64.549762],
              [166.759556, 64.542366],
              [166.787804, 64.518863],
              [166.808377, 64.532234],
              [166.859232, 64.544411],
              [166.867415, 64.536707],
              [166.901218, 64.534195],
              [166.91515, 64.541273],
              [166.999067, 64.538417],
              [167.026525, 64.556488],
              [167.041733, 64.575325],
              [167.106174, 64.599957],
              [167.135402, 64.592524],
              [167.12462, 64.618162],
              [167.136568, 64.622144],
              [167.19936, 64.607669],
              [167.231553, 64.623183],
              [167.250571, 64.615764],
              [167.247613, 64.603106],
              [167.273418, 64.593443],
              [167.292798, 64.574843],
              [167.290625, 64.557627],
              [167.333357, 64.540155],
              [167.328537, 64.52055],
              [167.339302, 64.501677],
              [167.383833, 64.479166],
              [167.413633, 64.476157],
              [167.476589, 64.482259],
              [167.49116, 64.473569],
              [167.482496, 64.453544],
              [167.54647, 64.434657],
              [167.58747, 64.428691],
              [167.589071, 64.415265],
              [167.611898, 64.406083],
              [167.603069, 64.388751],
              [167.641128, 64.366123],
              [167.669343, 64.362011],
              [167.66455, 64.353802],
              [167.69068, 64.318488],
              [167.746542, 64.329898],
              [167.786889, 64.333],
              [167.809568, 64.345986],
              [167.837622, 64.324898],
              [167.836957, 64.302597],
              [167.847339, 64.294216],
              [167.882369, 64.300728],
              [167.9015, 64.289603],
              [167.93587, 64.2823],
              [167.981494, 64.28257],
              [168.038053, 64.265495],
              [168.113724, 64.282469],
              [168.14621, 64.273928],
              [168.182753, 64.27669],
              [168.199244, 64.290906],
              [168.195512, 64.307663],
              [168.246553, 64.331137],
              [168.279529, 64.330188],
              [168.302123, 64.31462],
              [168.361042, 64.298165],
              [168.415652, 64.309292],
              [168.480252, 64.317216],
              [168.5625, 64.288961],
              [168.58188, 64.274248],
              [168.577783, 64.262058],
              [168.60619, 64.250496],
              [168.608112, 64.234771],
              [168.64551, 64.227367],
              [168.634006, 64.212841],
              [168.652026, 64.193683],
              [168.68417, 64.196187],
              [168.71649, 64.184893],
              [168.800179, 64.183616],
              [168.823667, 64.137213],
              [168.808886, 64.110033],
              [168.838594, 64.09409],
              [168.839008, 64.07231],
              [168.882987, 64.06051],
              [168.887334, 64.034473],
              [168.869441, 64.013695],
              [168.882406, 64.001941],
              [168.876823, 63.984723],
              [168.922598, 63.979769],
              [168.99219, 63.990697],
              [169.00286, 63.976777],
              [169.04243, 63.979293],
              [169.068102, 63.971273],
              [169.052757, 63.961709],
              [169.090249, 63.935409],
              [169.102014, 63.940831],
              [169.19246, 63.937282],
              [169.211128, 63.94283],
              [169.310539, 63.947033],
              [169.332526, 63.935192],
              [169.387495, 63.940075],
              [169.368591, 63.907502],
              [169.400577, 63.899524],
              [169.37658, 63.880708],
              [169.386581, 63.862377],
              [169.416511, 63.865271],
              [169.415505, 63.852506],
              [169.444418, 63.847105],
              [169.493011, 63.848934],
              [169.50109, 63.836943],
              [169.539125, 63.81744],
              [169.571695, 63.807671],
              [169.583028, 63.76989],
              [169.575561, 63.758346],
              [169.592006, 63.731213],
              [169.568975, 63.721548],
              [169.581741, 63.716004],
              [169.568073, 63.698947],
              [169.585314, 63.686057],
              [169.614284, 63.683735],
              [169.60865, 63.662615],
              [169.641674, 63.654413],
              [169.602365, 63.640453],
              [169.57933, 63.643317],
              [169.55444, 63.63182],
              [169.564733, 63.624837],
              [169.527684, 63.599037],
              [169.54593, 63.583164],
              [169.527422, 63.564474],
              [169.47952, 63.565419],
              [169.429222, 63.546706],
              [169.36931, 63.530681],
              [169.331603, 63.538088],
              [169.254119, 63.513185],
              [169.232317, 63.511282],
              [169.220475, 63.49595],
              [169.15488, 63.473665],
              [169.133743, 63.440496],
              [169.113003, 63.436899],
              [169.094153, 63.414],
              [169.05527, 63.403411],
              [169.010377, 63.378201],
              [168.946179, 63.355338],
              [168.948797, 63.3399],
              [168.90946, 63.321058],
              [168.897121, 63.324772],
              [168.827449, 63.304941],
              [168.824273, 63.272325],
              [168.831871, 63.266134],
              [168.796898, 63.235857],
              [168.754997, 63.24662],
              [168.709444, 63.22675],
              [168.733173, 63.222525],
              [168.738855, 63.211508],
              [168.773463, 63.202955],
              [168.744975, 63.180026],
              [168.7101, 63.183597],
              [168.651524, 63.182653],
              [168.61987, 63.178267],
              [168.627151, 63.160955],
              [168.55773, 63.128134],
              [168.529978, 63.126887],
              [168.521346, 63.114476],
              [168.493876, 63.11007],
              [168.46637, 63.083485],
              [168.50285, 63.065197],
              [168.522746, 63.067891],
              [168.52178, 63.056359],
              [168.578645, 63.032889],
              [168.591948, 63.036016],
              [168.645973, 63.023882],
              [168.671594, 63.026717],
              [168.754875, 62.999789],
              [168.825232, 62.967347],
              [168.912004, 62.952128],
              [168.922245, 62.943581],
              [169.014616, 62.963151],
              [169.048976, 62.953577],
              [169.06428, 62.97343],
              [169.109186, 62.980079],
              [169.158696, 62.966315],
              [169.185099, 62.952982],
              [169.186518, 62.939391],
              [169.214155, 62.941882],
              [169.248048, 62.919404],
              [169.3053, 62.906858],
              [169.339824, 62.891499],
              [169.386914, 62.891975],
              [169.400384, 62.881426],
              [169.36773, 62.866284],
              [169.401349, 62.857608],
              [169.42298, 62.844696],
              [169.445654, 62.845384],
              [169.473761, 62.823923],
              [169.501229, 62.821101],
              [169.58508, 62.788218],
              [169.601438, 62.762595],
              [169.579482, 62.750573],
              [169.622239, 62.746631],
              [169.679408, 62.704598],
              [169.7279, 62.715483],
              [169.742747, 62.707964],
              [169.739229, 62.694837],
              [169.772189, 62.681158],
              [169.811904, 62.676887],
              [169.85202, 62.656463],
              [169.900712, 62.668014],
              [169.932571, 62.667126],
              [169.947973, 62.655859],
              [170.006875, 62.676483],
              [170.048049, 62.674604],
              [170.070611, 62.656407],
              [170.094389, 62.648404],
              [170.09316, 62.636479],
              [170.0626, 62.636989],
              [170.028008, 62.623279],
              [170.026816, 62.605272],
              [170.041986, 62.597444],
              [170.042353, 62.573314],
              [170.011058, 62.558569],
              [169.99005, 62.539206],
              [169.99225, 62.5228],
              [170.05726, 62.524033],
              [170.069288, 62.507878],
              [170.11876, 62.49084],
              [170.10331, 62.459071],
              [170.06948, 62.455599],
              [170.08217, 62.4359],
              [170.120286, 62.431296],
              [170.143278, 62.411865],
              [170.148339, 62.392621],
              [170.131001, 62.386457],
              [170.140918, 62.366201],
              [170.170794, 62.353965],
              [170.183503, 62.357869],
              [170.20513, 62.344565],
              [170.213749, 62.328554],
              [170.184889, 62.306044],
              [170.194294, 62.295329],
              [170.235389, 62.292237],
              [170.245718, 62.281324],
              [170.291897, 62.294027],
              [170.298668, 62.275848],
              [170.35214, 62.292368],
              [170.370708, 62.30936],
              [170.408735, 62.312558],
              [170.416784, 62.30471],
              [170.44925, 62.304989],
              [170.490754, 62.285621],
              [170.51566, 62.300487],
              [170.544238, 62.303805],
              [170.552443, 62.290251],
              [170.573444, 62.298796],
              [170.584259, 62.279596],
              [170.601768, 62.290618],
              [170.632825, 62.284062],
              [170.641231, 62.267511],
              [170.662579, 62.270172],
              [170.700483, 62.263975],
              [170.73612, 62.277845],
              [170.767489, 62.26707],
              [170.78221, 62.252599],
              [170.804925, 62.261125],
              [170.83724, 62.250558],
              [170.850672, 62.25602],
              [170.844375, 62.269792],
              [170.8817, 62.280754],
              [170.921012, 62.270597],
              [170.96152, 62.28576],
              [170.981732, 62.275592],
              [170.997912, 62.281038],
              [171.05716, 62.277264],
              [171.086826, 62.289552],
              [171.10279, 62.310481],
              [171.085581, 62.322492],
              [171.107763, 62.34708],
              [171.148135, 62.335212],
              [171.192094, 62.34629],
              [171.25856, 62.383176],
              [171.309611, 62.377775],
              [171.314958, 62.372576],
              [171.266862, 62.354581],
              [171.244232, 62.340306],
              [171.248154, 62.319866],
              [171.268018, 62.314377],
              [171.315538, 62.319549],
              [171.339915, 62.331442],
              [171.346724, 62.347617],
              [171.382744, 62.354319],
              [171.38045, 62.340593],
              [171.40592, 62.332453],
              [171.402679, 62.301781],
              [171.435475, 62.306317],
              [171.471852, 62.303962],
              [171.4849, 62.294897],
              [171.52466, 62.311059],
              [171.590686, 62.324425],
              [171.621632, 62.3217],
              [171.63248, 62.339739],
              [171.676266, 62.354163],
              [171.714933, 62.348439],
              [171.749989, 62.349353],
              [171.768937, 62.361852],
              [171.805032, 62.356125],
              [171.895871, 62.360447],
              [171.891578, 62.377368],
              [171.841757, 62.392785],
              [171.852391, 62.412222],
              [171.875575, 62.402724],
              [171.920741, 62.401883],
              [171.960248, 62.415579],
              [171.978614, 62.416243],
              [171.995413, 62.454633],
              [172.07791, 62.465021],
              [172.100192, 62.460809],
              [172.162107, 62.461725],
              [172.182644, 62.457083],
              [172.20788, 62.464736],
              [172.2591, 62.424608],
              [172.238774, 62.407197],
              [172.242769, 62.397399],
              [172.271449, 62.396831],
              [172.268631, 62.37978],
              [172.28989, 62.371698],
              [172.320421, 62.374693],
              [172.33575, 62.399657],
              [172.374137, 62.395169],
              [172.406617, 62.403206],
              [172.419743, 62.418806],
              [172.452212, 62.438965],
              [172.499434, 62.444187],
              [172.561024, 62.445272],
              [172.584804, 62.429435],
              [172.62867, 62.436529],
              [172.644272, 62.421972],
              [172.704597, 62.421706],
              [172.703012, 62.41131],
              [172.756171, 62.412584],
              [172.768112, 62.40159],
              [172.741966, 62.383547],
              [172.767013, 62.350416],
              [172.793113, 62.344965],
              [172.854532, 62.347644],
              [172.890025, 62.35845],
              [172.883218, 62.377276],
              [172.920784, 62.397648],
              [172.95373, 62.402296],
              [172.976564, 62.398321],
              [173.010951, 62.381273],
              [173.070009, 62.383073],
              [173.07239, 62.402047],
              [173.09818, 62.409669],
              [173.094508, 62.417176],
              [173.134771, 62.425077],
              [173.180178, 62.42199],
              [173.16152, 62.447456],
              [173.20337, 62.444382],
              [173.216713, 62.449442],
              [173.251238, 62.44021],
              [173.269609, 62.452354],
              [173.329736, 62.44872],
              [173.332214, 62.465132],
              [173.362203, 62.467701],
              [173.382692, 62.479825],
              [173.4301, 62.484078],
              [173.462527, 62.505291],
              [173.485693, 62.506174],
              [173.500988, 62.523735],
              [173.52692, 62.523075],
              [173.540705, 62.514027],
              [173.574556, 62.515991],
              [173.592502, 62.501869],
              [173.612841, 62.499333],
              [173.63745, 62.511339],
              [173.67219, 62.546819],
              [173.732033, 62.534029],
              [173.723504, 62.51915],
              [173.772736, 62.522911],
              [173.799952, 62.513792],
              [173.84286, 62.507995],
              [173.865629, 62.488304],
              [173.860485, 62.468816],
              [173.9079, 62.447295],
              [173.894196, 62.437186],
              [173.955109, 62.437163],
              [173.987901, 62.443877],
              [174.002261, 62.459279],
              [174.037455, 62.462522],
              [174.042267, 62.47225],
              [174.082054, 62.467087],
              [174.08989, 62.45911],
              [174.13243, 62.447693],
              [174.158895, 62.42429],
              [174.160191, 62.402512],
              [174.144737, 62.383242],
              [174.151094, 62.33786],
              [174.088572, 62.324979],
              [174.069572, 62.32856],
              [174.074323, 62.312558],
              [174.054646, 62.2879],
              [174.011992, 62.292158],
              [174.00031, 62.281329],
              [174.02701, 62.261523],
              [174.058785, 62.266418],
              [174.073904, 62.256459],
              [174.063652, 62.243942],
              [174.082716, 62.24179],
              [174.113683, 62.219027],
              [174.11431, 62.20579],
              [174.137134, 62.203946],
              [174.149516, 62.18192],
              [174.110153, 62.174747],
              [174.108163, 62.156999],
              [174.127968, 62.134357],
              [174.120364, 62.122197],
              [174.15971, 62.109879],
              [174.158877, 62.084907],
              [174.192821, 62.072767],
              [174.239136, 62.076077],
              [174.251102, 62.089446],
              [174.30487, 62.093357],
              [174.353816, 62.078618],
              [174.341654, 62.040955],
              [174.354987, 62.024397],
              [174.386407, 62.036997],
              [174.451898, 62.023903],
              [174.442449, 62.011431],
              [174.450382, 61.984105],
              [174.49087, 61.973976],
              [174.469231, 61.957837],
              [174.462568, 61.933237],
              [174.437647, 61.923686],
              [174.43822, 61.909319],
              [174.470355, 61.909201],
              [174.502612, 61.898324],
              [174.51887, 61.870161],
              [174.49364, 61.85402],
              [174.496964, 61.83798],
              [174.473132, 61.849604],
              [174.440917, 61.848414],
              [174.445181, 61.824373],
              [174.475429, 61.808114],
              [174.462307, 61.80725],
              [174.42488, 61.821272],
              [174.348194, 61.81303],
              [174.29956, 61.799921],
              [174.267157, 61.805424],
              [174.214317, 61.79745],
              [174.142186, 61.778729],
              [174.035492, 61.744674],
              [174.02004, 61.736914],
              [174.018223, 61.716648],
              [173.989888, 61.698549],
              [173.950142, 61.700179],
              [173.921113, 61.695425],
              [173.873409, 61.663663],
              [173.833931, 61.644261],
              [173.817034, 61.671492],
              [173.703342, 61.701945],
              [173.637497, 61.739068],
              [173.583935, 61.737655],
              [173.529372, 61.723955],
              [173.49299, 61.707727],
              [173.510089, 61.679257],
              [173.513927, 61.653589],
              [173.499539, 61.642077],
              [173.475053, 61.638683],
              [173.49021, 61.613066],
              [173.499342, 61.572966],
              [173.509505, 61.559212],
              [173.493702, 61.542863],
              [173.455473, 61.52743],
              [173.428672, 61.54122],
              [173.36688, 61.539746],
              [173.304711, 61.511374],
              [173.3184, 61.490153],
              [173.332952, 61.484274],
              [173.31099, 61.455212],
              [173.268893, 61.431603],
              [173.239003, 61.424675],
              [173.203441, 61.405291],
              [173.170604, 61.393789],
              [173.120394, 61.385366],
              [173.07056, 61.382295],
              [173.001725, 61.407128],
              [172.982047, 61.429426],
              [172.958295, 61.438344],
              [172.925349, 61.432799],
              [172.92974, 61.451932],
              [172.897832, 61.463641],
              [172.825953, 61.451059],
              [172.762432, 61.424513],
              [172.739892, 61.398699],
              [172.87066, 61.367276],
              [172.939986, 61.341479],
              [172.957517, 61.323568],
              [172.92969, 61.312704],
              [172.968503, 61.303446],
              [172.969942, 61.283973],
              [172.917993, 61.260659],
              [172.895083, 61.259701],
              [172.841519, 61.270677],
              [172.806276, 61.267951],
              [172.780768, 61.258691],
              [172.775798, 61.24596],
              [172.706759, 61.226629],
              [172.667392, 61.199991],
              [172.67191, 61.19021],
              [172.650527, 61.166544],
              [172.581052, 61.165198],
              [172.55912, 61.171594],
              [172.50678, 61.174234],
              [172.503982, 61.182199],
              [172.452637, 61.195299],
              [172.441078, 61.209241],
              [172.385346, 61.220698],
              [172.339288, 61.204655],
              [172.36934, 61.182091],
              [172.37594, 61.157202],
              [172.443714, 61.13935],
              [172.424866, 61.120484],
              [172.39225, 61.124663],
              [172.366946, 61.114557],
              [172.296973, 61.110768],
              [172.29717, 61.102705],
              [172.351465, 61.098847],
              [172.405359, 61.110069],
              [172.426478, 61.097564],
              [172.39951, 61.061507],
              [172.417641, 61.053641],
              [172.46291, 61.073758],
              [172.483466, 61.050119],
              [172.455503, 61.027391],
              [172.406159, 61.008977],
              [172.374399, 61.005977],
              [172.345359, 61.017274],
              [172.307978, 61.00587],
              [172.27685, 61.005144],
              [172.20115, 61.038339],
              [172.16195, 61.062992],
              [172.113574, 61.068773],
              [172.059329, 61.089169],
              [172.031789, 61.087335],
              [172.040527, 61.079847],
              [172.150595, 61.041082],
              [172.1693, 61.011749],
              [172.216631, 60.967315],
              [172.21405, 60.94789],
              [172.19626, 60.936534],
              [172.147695, 60.931582],
              [172.09226, 60.886749],
              [172.110184, 60.866131],
              [172.099689, 60.850863],
              [172.087061, 60.865168],
              [172.070154, 60.853916],
              [172.071416, 60.836761],
              [172.043153, 60.838262],
              [172.030438, 60.866765],
              [172.011657, 60.886868],
              [171.971695, 60.885115],
              [171.95732, 60.860908],
              [171.979279, 60.841302],
              [171.944278, 60.82799],
              [171.883232, 60.819064],
              [171.816832, 60.824858],
              [171.79846, 60.844545],
              [171.781209, 60.846949],
              [171.7138, 60.834401],
              [171.70285, 60.813093],
              [171.655604, 60.80847],
              [171.618798, 60.774099],
              [171.65543, 60.748849],
              [171.652581, 60.737092],
              [171.629718, 60.717242],
              [171.583189, 60.714893],
              [171.560286, 60.719341],
              [171.510528, 60.710839],
              [171.473749, 60.695621],
              [171.43323, 60.670937],
              [171.408685, 60.648241],
              [171.393932, 60.61374],
              [171.34809, 60.61118],
              [171.290131, 60.591999],
              [171.212385, 60.557984],
              [171.151518, 60.545401],
              [171.128692, 60.553996],
              [171.07618, 60.544185],
              [171.02941, 60.525524],
              [170.984768, 60.502752],
              [170.967642, 60.505947],
              [170.922538, 60.489296],
              [170.882084, 60.489395],
              [170.843782, 60.473234],
              [170.81642, 60.452984],
              [170.815991, 60.43763],
              [170.787641, 60.417884],
              [170.750876, 60.420763],
              [170.734452, 60.40397],
              [170.644383, 60.409646],
              [170.626977, 60.393477],
              [170.639005, 60.381354],
              [170.66129, 60.376167],
              [170.617951, 60.369397],
              [170.616824, 60.355319],
              [170.680153, 60.349601],
              [170.693128, 60.342722],
              [170.680636, 60.326571],
              [170.690309, 60.313287],
              [170.67807, 60.298928],
              [170.60916, 60.283645],
              [170.582359, 60.283691],
              [170.526557, 60.257786],
              [170.485664, 60.205429],
              [170.468199, 60.140872],
              [170.459958, 60.127019],
              [170.463023, 60.096322],
              [170.454248, 60.06916],
              [170.463638, 60.05845],
              [170.433538, 60.022753],
              [170.430792, 59.989216],
              [170.422447, 59.96973],
              [170.392761, 59.946052],
              [170.360164, 59.943339],
              [170.329263, 59.932693],
              [170.286066, 59.925812],
              [170.255869, 59.93102],
              [170.22885, 59.952618],
              [170.197496, 59.962156],
              [170.158666, 59.963706],
              [170.126313, 59.971173],
              [170.159625, 60.006451],
              [170.14731, 60.0184],
              [170.088129, 60.04155],
              [169.967083, 60.067532],
              [169.946143, 60.089889],
              [169.93507, 60.13126],
              [169.923048, 60.146007],
              [169.884863, 60.167189],
              [169.87002, 60.217992],
              [169.875282, 60.231944],
              [169.837154, 60.25277],
              [169.781979, 60.268655],
              [169.770399, 60.316407],
              [169.736241, 60.332466],
              [169.750901, 60.37432],
              [169.737939, 60.39544],
              [169.699503, 60.412473],
              [169.628099, 60.436838],
              [169.52094, 60.458598],
              [169.42043, 60.500827],
              [169.41249, 60.514553],
              [169.340692, 60.527757],
              [169.278461, 60.535799],
              [169.236092, 60.545868],
              [169.06254, 60.558565],
              [168.937458, 60.563347],
              [168.84637, 60.564726],
              [168.741411, 60.563376],
              [168.71798, 60.55645],
              [168.714925, 60.540783],
              [168.685037, 60.551787],
              [168.640812, 60.552294],
              [168.62719, 60.567924],
              [168.60045, 60.577814],
              [168.573055, 60.5754],
              [168.556011, 60.594417],
              [168.447068, 60.596221],
              [168.320017, 60.590149],
              [168.20507, 60.57704],
              [168.139815, 60.567594],
              [168.044241, 60.548868],
              [167.98218, 60.523698],
              [167.956523, 60.521453],
              [167.91696, 60.509008],
              [167.870141, 60.502214],
              [167.834732, 60.50694],
              [167.82629, 60.526323],
              [167.799466, 60.531498],
              [167.777345, 60.521974],
              [167.776743, 60.505856],
              [167.804082, 60.482752],
              [167.786864, 60.469542],
              [167.720041, 60.476683],
              [167.70852, 60.462964],
              [167.66996, 60.447996],
              [167.5673, 60.427739],
              [167.524994, 60.429828],
              [167.490721, 60.421898],
              [167.462087, 60.406173],
              [167.431001, 60.414825],
              [167.402876, 60.410327],
              [167.399631, 60.394385],
              [167.378174, 60.37685],
              [167.343194, 60.386558],
              [167.32011, 60.381971],
              [167.306213, 60.360965],
              [167.23029, 60.338964],
              [167.194678, 60.3393],
              [167.128729, 60.357524],
              [167.08112, 60.380803],
              [167.076066, 60.398801],
              [167.051659, 60.401483],
              [167.047079, 60.378004],
              [167.064849, 60.364674],
              [167.058461, 60.349414],
              [167.07802, 60.340151],
              [167.0879, 60.321053],
              [167.055959, 60.301456],
              [166.987247, 60.299686],
              [166.959112, 60.282058],
              [166.95337, 60.267808],
              [166.886361, 60.222703],
              [166.841615, 60.223075],
              [166.859719, 60.215656],
              [166.814165, 60.173284],
              [166.798927, 60.167093],
              [166.770653, 60.138038],
              [166.773128, 60.131606],
              [166.741983, 60.100535],
              [166.67732, 60.068864],
              [166.647945, 60.044661],
              [166.608629, 60.025437],
              [166.586155, 60.00472],
              [166.515217, 59.973558],
              [166.421551, 59.926738],
              [166.390999, 59.885088],
              [166.337611, 59.836498],
              [166.285723, 59.809629],
              [166.2604, 59.811793],
              [166.211898, 59.805466],
              [166.183921, 59.81041],
              [166.120756, 59.799364],
              [166.092565, 59.80527],
              [166.089485, 59.822728],
              [166.103206, 59.863663],
              [166.101525, 59.8839],
              [166.118606, 59.892188],
              [166.135842, 59.929252],
              [166.17948, 59.973402],
              [166.172807, 60.001359],
              [166.162949, 60.011683],
              [166.187364, 60.036803],
              [166.176066, 60.049957],
              [166.186843, 60.079293],
              [166.206881, 60.09498],
              [166.208071, 60.127473],
              [166.272973, 60.140941],
              [166.288903, 60.154607],
              [166.290189, 60.174302],
              [166.313364, 60.195061],
              [166.308577, 60.216863],
              [166.283908, 60.243721],
              [166.294423, 60.262302],
              [166.280064, 60.290337],
              [166.292437, 60.30131],
              [166.28961, 60.32326],
              [166.252128, 60.368662],
              [166.256989, 60.394208],
              [166.268015, 60.398454],
              [166.25322, 60.370187],
              [166.264071, 60.36343],
              [166.308481, 60.375669],
              [166.332212, 60.374538],
              [166.385279, 60.407012],
              [166.38631, 60.425172],
              [166.356839, 60.44467],
              [166.31664, 60.462476],
              [166.272498, 60.473525],
              [166.200862, 60.457043],
              [166.233831, 60.445098],
              [166.172203, 60.438232],
              [166.1147, 60.437829],
              [166.05431, 60.411834],
              [166.04188, 60.402125],
              [166.017169, 60.365802],
              [165.98522, 60.351711],
              [165.92172, 60.334253],
              [165.831408, 60.314887],
              [165.806502, 60.28817],
              [165.758475, 60.262094],
              [165.69435, 60.249905],
              [165.691083, 60.241477],
              [165.6369, 60.234454],
              [165.61816, 60.223156],
              [165.542187, 60.201863],
              [165.439197, 60.178424],
              [165.365743, 60.156633],
              [165.28285, 60.116703],
              [165.234334, 60.087815],
              [165.190531, 60.082774],
              [165.14289, 60.057264],
              [165.138192, 60.038737],
              [165.145535, 60.021711],
              [165.194018, 60.006422],
              [165.207789, 59.990896],
              [165.156802, 59.969416],
              [165.15883, 59.949078],
              [165.130395, 59.929406],
              [165.133103, 59.903272],
              [165.087737, 59.871],
              [165.038309, 59.846861],
              [164.992266, 59.8455],
              [164.929296, 59.832913],
              [164.832619, 59.786454],
              [164.820484, 59.808878],
              [164.799099, 59.81678],
              [164.793145, 59.834429],
              [164.80159, 59.867711],
              [164.779294, 59.929863],
              [164.733911, 59.96228],
              [164.710116, 59.998569],
              [164.691712, 59.999679],
              [164.647079, 60.016586],
              [164.6082, 60.018779],
              [164.611387, 60.030305],
              [164.568537, 60.061137],
              [164.537912, 60.073061],
              [164.488842, 60.100263],
              [164.42369, 60.103706],
              [164.37472, 60.091072],
              [164.363616, 60.055959],
              [164.355422, 60.056176],
              [164.362581, 60.083081],
              [164.34086, 60.086111],
              [164.314139, 60.072346],
              [164.303446, 60.045163],
              [164.31315, 60.020693],
              [164.338675, 59.990849],
              [164.293037, 59.982696],
              [164.25131, 59.98237],
              [164.202412, 59.989871],
              [164.12874, 59.98824],
              [163.989314, 60.015739],
              [163.907963, 60.016735],
              [163.866682, 60.038814],
              [163.829786, 60.04054],
              [163.797349, 60.017982],
              [163.79195, 60.025867],
              [163.752304, 60.032921],
              [163.701762, 60.030386],
              [163.65495, 60.011746],
              [163.639877, 59.992908],
              [163.65462, 59.982053],
              [163.693772, 59.973616],
              [163.69599, 59.956106],
              [163.729113, 59.957453],
              [163.763437, 59.951849],
              [163.690716, 59.879555],
              [163.665029, 59.888482],
              [163.587705, 59.898298],
              [163.521321, 59.890276],
              [163.42718, 59.83441],
              [163.40208, 59.806463],
              [163.376343, 59.766217],
              [163.369127, 59.732084],
              [163.39679, 59.678415],
              [163.4232, 59.644179],
              [163.413531, 59.618721],
              [163.362828, 59.59122],
              [163.31021, 59.593495],
              [163.275022, 59.590477],
              [163.248173, 59.572842],
              [163.222223, 59.567372],
              [163.205763, 59.535405],
              [163.2352, 59.536318],
              [163.246028, 59.546191],
              [163.272084, 59.549353],
              [163.224945, 59.530035],
              [163.202761, 59.50183],
              [163.21242, 59.476363],
              [163.243783, 59.473076],
              [163.317737, 59.422469],
              [163.333853, 59.401173],
              [163.345879, 59.350129],
              [163.348166, 59.303056],
              [163.342867, 59.273038],
              [163.279328, 59.218967],
              [163.260838, 59.219233],
              [163.266281, 59.230058],
              [163.305327, 59.248492],
              [163.342556, 59.274314],
              [163.327896, 59.287694],
              [163.226974, 59.284823],
              [163.160764, 59.278656],
              [163.13724, 59.269746],
              [163.107335, 59.267906],
              [163.075109, 59.249642],
              [163.070582, 59.22807],
              [163.07541, 59.199981],
              [163.08661, 59.182737],
              [163.143141, 59.161285],
              [163.189705, 59.152726],
              [163.229476, 59.140218],
              [163.250533, 59.093656],
              [163.25056, 59.083416],
              [163.223298, 59.038245],
              [163.171493, 59.031636],
              [163.164482, 59.042033],
              [163.215005, 59.044914],
              [163.239808, 59.065094],
              [163.239989, 59.080969],
              [163.16771, 59.102624],
              [163.10453, 59.111317],
              [163.071717, 59.128243],
              [163.071257, 59.136428],
              [163.033706, 59.139705],
              [163.02419, 59.149289],
              [162.995014, 59.136809],
              [162.931245, 59.120319],
              [162.903704, 59.118726],
              [162.906862, 59.107767],
              [162.993871, 59.020047],
              [163.01809, 59.004674],
              [163.05079, 58.99685],
              [163.07163, 59.005611],
              [163.053851, 59.02173],
              [163.07067, 59.018397],
              [163.07262, 58.992313],
              [163.046696, 58.964018],
              [163.014588, 58.94484],
              [162.917111, 58.926409],
              [162.861537, 58.904251],
              [162.84782, 58.886101],
              [162.81372, 58.877847],
              [162.812307, 58.853243],
              [162.796516, 58.837356],
              [162.75137, 58.81763],
              [162.655443, 58.783404],
              [162.611306, 58.772608],
              [162.550288, 58.749953],
              [162.485448, 58.71311],
              [162.415988, 58.660775],
              [162.352679, 58.604429],
              [162.309096, 58.561781],
              [162.255643, 58.562186],
              [162.20774, 58.572806],
              [162.20329, 58.567203],
              [162.226993, 58.552607],
              [162.271294, 58.539046],
              [162.27223, 58.525405],
              [162.208856, 58.454125],
              [162.174473, 58.419821],
              [162.131614, 58.368889],
              [162.095728, 58.306362],
              [162.086092, 58.278254],
              [162.047023, 58.210251],
              [162.019089, 58.172974],
              [162, 58.133438],
              [161.989369, 58.084408],
              [161.997993, 58.014279],
              [162.009401, 57.979362],
              [162.028289, 57.943433],
              [162.058008, 57.906985],
              [162.092852, 57.874746],
              [162.153277, 57.840129],
              [162.21523, 57.815252],
              [162.279426, 57.797693],
              [162.364904, 57.783236],
              [162.460914, 57.781611],
              [162.482684, 57.771929],
              [162.444239, 57.76655],
              [162.439303, 57.748266],
              [162.449702, 57.740024],
              [162.398515, 57.715933],
              [162.36706, 57.681219],
              [162.379383, 57.669437],
              [162.422008, 57.695156],
              [162.473435, 57.708743],
              [162.487652, 57.741808],
              [162.529223, 57.749953],
              [162.514558, 57.760471],
              [162.51904, 57.781079],
              [162.508379, 57.793954],
              [162.510354, 57.827113],
              [162.49395, 57.847959],
              [162.4965, 57.870034],
              [162.507588, 57.886899],
              [162.527288, 57.890613],
              [162.53334, 57.904048],
              [162.557402, 57.918086],
              [162.55341, 57.930814],
              [162.58905, 57.937769],
              [162.647236, 57.931562],
              [162.678416, 57.933287],
              [162.701919, 57.94972],
              [162.750428, 57.921139],
              [162.798722, 57.918401],
              [162.81292, 57.902513],
              [162.838606, 57.903337],
              [162.873725, 57.895095],
              [162.880963, 57.88651],
              [162.93482, 57.869473],
              [162.960739, 57.85312],
              [163.047032, 57.820677],
              [163.10829, 57.814692],
              [163.1373, 57.819731],
              [163.212689, 57.81864],
              [163.235125, 57.791557],
              [163.25666, 57.777516],
              [163.270078, 57.757472],
              [163.295375, 57.734002],
              [163.321122, 57.725897],
              [163.30327, 57.718745],
              [163.280525, 57.693858],
              [163.257777, 57.684585],
              [163.228176, 57.664202],
              [163.223373, 57.645186],
              [163.241627, 57.6183],
              [163.226664, 57.60708],
              [163.225053, 57.591182],
              [163.206342, 57.57901],
              [163.147184, 57.570603],
              [163.11817, 57.527923],
              [163.119, 57.511803],
              [163.095982, 57.483291],
              [163.05538, 57.468971],
              [163.020984, 57.463443],
              [162.990274, 57.451561],
              [162.974129, 57.451431],
              [162.952495, 57.439046],
              [162.918371, 57.437846],
              [162.869739, 57.416675],
              [162.843759, 57.390368],
              [162.82499, 57.386972],
              [162.798694, 57.366796],
              [162.781006, 57.345741],
              [162.773567, 57.31479],
              [162.769561, 57.242839],
              [162.7751, 57.196588],
              [162.78984, 57.151262],
              [162.80612, 57.119023],
              [162.830356, 57.091018],
              [162.842443, 57.069202],
              [162.852234, 57.029931],
              [162.842013, 56.987498],
              [162.810955, 56.933176],
              [162.810204, 56.906015],
              [162.794411, 56.872187],
              [162.78679, 56.842855],
              [162.777985, 56.774572],
              [162.801015, 56.747704],
              [162.831117, 56.726846],
              [162.891781, 56.700099],
              [162.930341, 56.693063],
              [162.975291, 56.696747],
              [162.979749, 56.709113],
              [163.007204, 56.718262],
              [163.012673, 56.729362],
              [163.04452, 56.726152],
              [163.076823, 56.741252],
              [163.102608, 56.728971],
              [163.125, 56.725781],
              [163.146556, 56.735267],
              [163.19422, 56.732381],
              [163.224848, 56.744857],
              [163.244493, 56.733355],
              [163.226527, 56.714151],
              [163.255216, 56.703031],
              [163.264262, 56.688561],
              [163.28711, 56.687597],
              [163.262856, 56.668566],
              [163.23781, 56.632105],
              [163.237328, 56.602648],
              [163.271884, 56.567792],
              [163.245872, 56.538489],
              [163.27565, 56.459516],
              [163.260735, 56.461997],
              [163.24989, 56.441229],
              [163.28399, 56.423266],
              [163.29323, 56.40265],
              [163.3111, 56.395467],
              [163.339057, 56.336687],
              [163.355769, 56.31258],
              [163.333284, 56.27873],
              [163.333907, 56.264397],
              [163.349264, 56.20698],
              [163.361531, 56.183326],
              [163.327925, 56.17468],
              [163.263687, 56.143318],
              [163.225142, 56.143538],
              [163.18059, 56.134951],
              [163.114991, 56.092988],
              [163.06911, 56.043872],
              [163.046815, 56.009712],
              [163.0212, 56.005462],
              [162.96572, 56.017366],
              [162.911492, 56.02235],
              [162.858745, 56.049877],
              [162.817815, 56.061454],
              [162.78321, 56.097977],
              [162.751915, 56.119952],
              [162.751214, 56.14376],
              [162.689746, 56.174131],
              [162.670829, 56.198366],
              [162.630123, 56.225127],
              [162.602756, 56.228954],
              [162.561946, 56.227132],
              [162.471382, 56.205845],
              [162.450763, 56.20491],
              [162.37456, 56.189517],
              [162.273366, 56.166028],
              [162.201428, 56.145078],
              [162.159763, 56.128243],
              [162.106031, 56.101632],
              [162.07778, 56.08124],
              [162.079388, 56.075129],
              [162.05303, 56.051445],
              [162.053744, 56.033016],
              [162.079295, 56.024913],
              [162.03842, 55.991144],
              [162.02484, 55.971602],
              [162.020821, 55.937686],
              [161.989231, 55.915409],
              [161.965498, 55.891388],
              [161.964301, 55.872714],
              [161.929027, 55.839996],
              [161.91703, 55.807488],
              [161.925879, 55.801129],
              [161.905637, 55.767534],
              [161.893047, 55.76322],
              [161.81659, 55.679684],
              [161.798504, 55.65499],
              [161.773233, 55.608425],
              [161.751809, 55.544859],
              [161.739899, 55.461349],
              [161.742848, 55.373919],
              [161.753442, 55.312705],
              [161.777606, 55.23427],
              [161.813995, 55.159134],
              [161.865908, 55.081186],
              [161.883484, 55.064758],
              [161.91517, 55.052344],
              [161.929977, 55.053398],
              [161.960555, 55.026266],
              [161.963807, 55.015938],
              [161.98977, 55.005309],
              [162.004092, 54.976258],
              [162.04347, 54.955035],
              [162.05456, 54.942714],
              [162.051161, 54.92435],
              [162.072306, 54.904086],
              [162.084347, 54.884178],
              [162.137648, 54.863721],
              [162.154342, 54.852783],
              [162.15689, 54.837085],
              [162.119942, 54.832916],
              [162.106938, 54.819669],
              [162.10484, 54.801016],
              [162.125619, 54.791514],
              [162.133125, 54.773895],
              [162.125305, 54.758722],
              [162.138235, 54.754031],
              [162.092671, 54.737522],
              [162.071606, 54.734214],
              [162.039598, 54.70299],
              [161.999755, 54.695935],
              [161.98051, 54.666535],
              [161.964197, 54.664117],
              [161.947033, 54.646856],
              [161.879446, 54.62209],
              [161.872715, 54.614307],
              [161.836385, 54.59979],
              [161.800422, 54.564499],
              [161.707776, 54.496096],
              [161.568869, 54.467165],
              [161.274682, 54.479239],
              [161.220411, 54.511645],
              [161.202066, 54.54979],
              [161.143209, 54.588342],
              [161.046897, 54.581255],
              [160.91924, 54.575053],
              [160.868032, 54.557326],
              [160.81267, 54.551057],
              [160.685534, 54.508322],
              [160.637495, 54.490409],
              [160.543529, 54.448995],
              [160.426251, 54.388316],
              [160.342841, 54.33407],
              [160.313557, 54.302372],
              [160.255165, 54.276224],
              [160.247816, 54.264773],
              [160.221405, 54.259536],
              [160.215036, 54.251275],
              [160.19141, 54.251127],
              [160.165649, 54.229605],
              [160.131449, 54.226089],
              [160.072101, 54.188079],
              [160.033057, 54.154991],
              [159.9912, 54.111027],
              [159.977346, 54.087317],
              [159.987, 54.080962],
              [159.971739, 54.061746],
              [159.96244, 54.036204],
              [159.947972, 54.021974],
              [159.899612, 53.948057],
              [159.87835, 53.907484],
              [159.856143, 53.850519],
              [159.842918, 53.796048],
              [159.840662, 53.758919],
              [159.846686, 53.703798],
              [159.86405, 53.676776],
              [159.85439, 53.668647],
              [159.884797, 53.657923],
              [159.881572, 53.668706],
              [159.903585, 53.669311],
              [159.897539, 53.658853],
              [159.90137, 53.63628],
              [159.914448, 53.623116],
              [159.923158, 53.597687],
              [159.941577, 53.593188],
              [159.955583, 53.574632],
              [159.946048, 53.55242],
              [159.956065, 53.538762],
              [159.950275, 53.510628],
              [159.956095, 53.501871],
              [159.94765, 53.47965],
              [159.936755, 53.472434],
              [159.881243, 53.487332],
              [159.84975, 53.468087],
              [159.83867, 53.452028],
              [159.83788, 53.433844],
              [159.851507, 53.41812],
              [159.84498, 53.402539],
              [159.868877, 53.386611],
              [159.899087, 53.396764],
              [159.924084, 53.383251],
              [159.916102, 53.360768],
              [159.902127, 53.358056],
              [159.896451, 53.342727],
              [159.90668, 53.330609],
              [159.928728, 53.32096],
              [159.965824, 53.334244],
              [159.974941, 53.321104],
              [159.961635, 53.300204],
              [159.94616, 53.296612],
              [159.909748, 53.263462],
              [159.904394, 53.252743],
              [159.928169, 53.245899],
              [159.945792, 53.212199],
              [159.956201, 53.210066],
              [159.952201, 53.241354],
              [159.980813, 53.259952],
              [160.016735, 53.261317],
              [159.998529, 53.231752],
              [160.004671, 53.220555],
              [160.029007, 53.220732],
              [160.04647, 53.201621],
              [160.03554, 53.1907],
              [160.033615, 53.172783],
              [160.050643, 53.162898],
              [160.05907, 53.141558],
              [160.03562, 53.123318],
              [160.047359, 53.115529],
              [160.03629, 53.097948],
              [160.02093, 53.096415],
              [159.98845, 53.109243],
              [159.965409, 53.106998],
              [159.956376, 53.122059],
              [159.90645, 53.137565],
              [159.847251, 53.160419],
              [159.802251, 53.181739],
              [159.763201, 53.192243],
              [159.744003, 53.204406],
              [159.76869, 53.236786],
              [159.735605, 53.237251],
              [159.71095, 53.218442],
              [159.6821, 53.229371],
              [159.662758, 53.248566],
              [159.616745, 53.256755],
              [159.579521, 53.252587],
              [159.530594, 53.234112],
              [159.47973, 53.22946],
              [159.4676, 53.212985],
              [159.445346, 53.207434],
              [159.42067, 53.1714],
              [159.397135, 53.162258],
              [159.393629, 53.151766],
              [159.371061, 53.154015],
              [159.342207, 53.169986],
              [159.214187, 53.146971],
              [159.193694, 53.138296],
              [159.079881, 53.105378],
              [159.019419, 53.079912],
              [158.903394, 53.023639],
              [158.824571, 52.971925],
              [158.777046, 52.929059],
              [158.756115, 52.919283],
              [158.734011, 52.89464],
              [158.70548, 52.885221],
              [158.681147, 52.911163],
              [158.688521, 52.917075],
              [158.681973, 52.942934],
              [158.65632, 52.941838],
              [158.6458, 52.957948],
              [158.652705, 52.97001],
              [158.67904, 52.967009],
              [158.652545, 52.989993],
              [158.641038, 53.020703],
              [158.612137, 53.045463],
              [158.592784, 53.046423],
              [158.583947, 53.059203],
              [158.566901, 53.062729],
              [158.518266, 53.057162],
              [158.517373, 53.042042],
              [158.474256, 53.022132],
              [158.421675, 53.014202],
              [158.411477, 52.983151],
              [158.450588, 52.978727],
              [158.47158, 52.962938],
              [158.44497, 52.953464],
              [158.41824, 52.953721],
              [158.413461, 52.941312],
              [158.420378, 52.920681],
              [158.446975, 52.895956],
              [158.482133, 52.896498],
              [158.503829, 52.888649],
              [158.531753, 52.891709],
              [158.538961, 52.910952],
              [158.484835, 52.918672],
              [158.46901, 52.929876],
              [158.496107, 52.933581],
              [158.52629, 52.926402],
              [158.553143, 52.912544],
              [158.579853, 52.911616],
              [158.58232, 52.919824],
              [158.611975, 52.921461],
              [158.63533, 52.916924],
              [158.645252, 52.895433],
              [158.624507, 52.893832],
              [158.632316, 52.872079],
              [158.601841, 52.870827],
              [158.600898, 52.858142],
              [158.644815, 52.845215],
              [158.597671, 52.818163],
              [158.574884, 52.808938],
              [158.564672, 52.777053],
              [158.525393, 52.775749],
              [158.496597, 52.781523],
              [158.480683, 52.769541],
              [158.490911, 52.761899],
              [158.486551, 52.73754],
              [158.581385, 52.708448],
              [158.602402, 52.693404],
              [158.595123, 52.682618],
              [158.566499, 52.678162],
              [158.585168, 52.665417],
              [158.570552, 52.651786],
              [158.548052, 52.647859],
              [158.550993, 52.628142],
              [158.509007, 52.617342],
              [158.455755, 52.634276],
              [158.428596, 52.650162],
              [158.4011, 52.658481],
              [158.391579, 52.646134],
              [158.436985, 52.620224],
              [158.421027, 52.617201],
              [158.41177, 52.601142],
              [158.475139, 52.562878],
              [158.494008, 52.567316],
              [158.520018, 52.55346],
              [158.515547, 52.539606],
              [158.526395, 52.527352],
              [158.516285, 52.510656],
              [158.494577, 52.501295],
              [158.51852, 52.478894],
              [158.495672, 52.458813],
              [158.46837, 52.461768],
              [158.481553, 52.44671],
              [158.433703, 52.417536],
              [158.40579, 52.405357],
              [158.435981, 52.403199],
              [158.489272, 52.431882],
              [158.507665, 52.436572],
              [158.546001, 52.418533],
              [158.543395, 52.406993],
              [158.561702, 52.398863],
              [158.57194, 52.37994],
              [158.547225, 52.380873],
              [158.544647, 52.36677],
              [158.508756, 52.357146],
              [158.483382, 52.34397],
              [158.49715, 52.329296],
              [158.544337, 52.336022],
              [158.561441, 52.319431],
              [158.548983, 52.29043],
              [158.52639, 52.273431],
              [158.484043, 52.269281],
              [158.45145, 52.270288],
              [158.460843, 52.253676],
              [158.425682, 52.22827],
              [158.396477, 52.227997],
              [158.387431, 52.218213],
              [158.388621, 52.19234],
              [158.41283, 52.190413],
              [158.413519, 52.169661],
              [158.399854, 52.163186],
              [158.385208, 52.137487],
              [158.373656, 52.129739],
              [158.356817, 52.142588],
              [158.33895, 52.139927],
              [158.303293, 52.116276],
              [158.298564, 52.099336],
              [158.325747, 52.076433],
              [158.321754, 52.065519],
              [158.300889, 52.057069],
              [158.302606, 52.040295],
              [158.283606, 52.033135],
              [158.296629, 52.023989],
              [158.292258, 52.011406],
              [158.278548, 52.011835],
              [158.278446, 51.99236],
              [158.268254, 51.981022],
              [158.277708, 51.97365],
              [158.272326, 51.960163],
              [158.243132, 51.944989],
              [158.212472, 51.919931],
              [158.202381, 51.904744],
              [158.176082, 51.893032],
              [158.157627, 51.872145],
              [158.126162, 51.850902],
              [158.123005, 51.832428],
              [158.102556, 51.820742],
              [158.10241, 51.807287],
              [158.077176, 51.808117],
              [158.062117, 51.796722],
              [158.03786, 51.790632],
              [158.013959, 51.768781],
              [157.996914, 51.727122],
              [157.96844, 51.718163],
              [157.959711, 51.702966],
              [157.960958, 51.684526],
              [157.932292, 51.676325],
              [157.924045, 51.65908],
              [157.906271, 51.644308],
              [157.9103, 51.63102],
              [157.859096, 51.620154],
              [157.83235, 51.595417],
              [157.826366, 51.580238],
              [157.802839, 51.583331],
              [157.772006, 51.56738],
              [157.771118, 51.554557],
              [157.744205, 51.546584],
              [157.712702, 51.555981],
              [157.665911, 51.539313],
              [157.621813, 51.529833],
              [157.564335, 51.502234],
              [157.53502, 51.481625],
              [157.49667, 51.44321],
              [157.474963, 51.39936],
              [157.486131, 51.383743],
              [157.469009, 51.381342],
              [157.472625, 51.358836],
              [157.44064, 51.348419],
              [157.415624, 51.349878],
              [157.404721, 51.357418],
              [157.374753, 51.348293],
              [157.343934, 51.329023],
              [157.337691, 51.309328],
              [157.313981, 51.297968],
              [157.301719, 51.275749],
              [157.280645, 51.271085],
              [157.27771, 51.240285],
              [157.263398, 51.240843],
              [157.25624, 51.224973],
              [157.241132, 51.225132],
              [157.234396, 51.20914],
              [157.201292, 51.194795],
              [157.175355, 51.195675],
              [157.15803, 51.174899],
              [157.13634, 51.16174],
              [157.090658, 51.152545],
              [157.072865, 51.139438],
              [157.055479, 51.116851],
              [157.037205, 51.115648],
              [157.012519, 51.09306],
              [156.963206, 51.066707],
              [156.938582, 51.044104],
              [156.875364, 51.021697],
              [156.807442, 50.958154],
              [156.78776, 50.94958],
              [156.781061, 50.936893],
              [156.751016, 50.924783],
              [156.719711, 50.89316],
              [156.692369, 50.884676],
              [156.669007, 50.865627],
              [156.658313, 50.885023],
              [156.701457, 50.914077],
              [156.733549, 50.946233],
              [156.732451, 50.952108],
              [156.763882, 50.986293],
              [156.767362, 51.015324],
              [156.757624, 51.019239],
              [156.764098, 51.057812],
              [156.753769, 51.086322],
              [156.735676, 51.094303],
              [156.701142, 51.084786],
              [156.702273, 51.103008],
              [156.723283, 51.155965],
              [156.722457, 51.178161],
              [156.712246, 51.201887],
              [156.68413, 51.213381],
              [156.675837, 51.232185],
              [156.649421, 51.253828],
              [156.596938, 51.257632],
              [156.552957, 51.278124],
              [156.534526, 51.3208],
              [156.536979, 51.351354],
              [156.523744, 51.372862],
              [156.52547, 51.420574],
              [156.50696, 51.444276],
              [156.506091, 51.463995],
              [156.490296, 51.49566],
              [156.485873, 51.560189],
              [156.495416, 51.604333],
              [156.499827, 51.775261],
              [156.496434, 51.850358],
              [156.487612, 51.918215],
              [156.4849, 51.979701],
              [156.476754, 52.041937],
              [156.471586, 52.107362],
              [156.451874, 52.193823],
              [156.414806, 52.315013],
              [156.383485, 52.388649],
              [156.345508, 52.466484],
              [156.325858, 52.492952],
              [156.308341, 52.52536],
              [156.278905, 52.593629],
              [156.197775, 52.733723],
              [156.14714, 52.846309],
              [156.129624, 52.912779],
              [156.120976, 52.972797],
              [156.106189, 53.095456],
              [156.090026, 53.196038],
              [156.065631, 53.318711],
              [156.04966, 53.382644],
              [156.026567, 53.452547],
              [156, 53.575105],
              [155.99242, 53.628905],
              [155.977471, 53.701291],
              [155.954273, 53.783103],
              [155.94005, 53.845725],
              [155.92927, 53.877509],
              [155.907174, 53.962101],
              [155.88636, 54.025642],
              [155.790421, 54.275697],
              [155.759917, 54.359676],
              [155.728813, 54.456215],
              [155.719285, 54.498195],
              [155.696261, 54.576347],
              [155.672603, 54.666293],
              [155.650855, 54.770026],
              [155.637409, 54.818744],
              [155.589449, 55.018849],
              [155.57609, 55.099066],
              [155.567767, 55.129474],
              [155.557861, 55.194755],
              [155.553835, 55.254277],
              [155.556384, 55.367203],
              [155.567298, 55.43845],
              [155.585461, 55.512874],
              [155.595079, 55.542388],
              [155.633595, 55.709366],
              [155.640971, 55.788368],
              [155.645959, 55.80784],
              [155.653262, 55.934468],
              [155.651971, 55.966687],
              [155.673851, 56.018986],
              [155.718914, 56.087424],
              [155.731001, 56.086645],
              [155.743995, 56.105058],
              [155.729507, 56.114089],
              [155.756101, 56.174981],
              [155.77726, 56.210372],
              [155.820039, 56.31085],
              [155.83298, 56.351799],
              [155.881384, 56.439833],
              [155.959314, 56.632953],
              [155.990423, 56.68242],
              [156.083812, 56.786395],
              [156.118425, 56.809742],
              [156.160854, 56.829159],
              [156.183051, 56.832224],
              [156.148124, 56.802996],
              [156.153319, 56.789364],
              [156.187337, 56.793768],
              [156.215223, 56.823724],
              [156.209598, 56.842811],
              [156.222922, 56.855403],
              [156.303188, 56.877015],
              [156.382436, 56.907774],
              [156.426033, 56.927378],
              [156.466052, 56.952016],
              [156.512347, 56.990495],
              [156.544361, 57.036129],
              [156.550496, 57.061527],
              [156.534704, 57.079914],
              [156.537832, 57.093038],
              [156.557525, 57.101235],
              [156.579868, 57.086942],
              [156.595205, 57.086213],
              [156.653478, 57.06478],
              [156.686325, 57.05822],
              [156.727048, 57.068698],
              [156.735566, 57.075511],
              [156.71778, 57.085688],
              [156.732503, 57.110286],
              [156.782882, 57.157286],
              [156.809513, 57.193095],
              [156.84226, 57.222089],
              [156.886245, 57.278409],
              [156.902065, 57.291223],
              [156.932731, 57.334753],
              [156.986796, 57.402467],
              [157.003346, 57.451385],
              [157.000161, 57.492307],
              [156.99196, 57.529051],
              [156.968433, 57.583987],
              [156.95012, 57.612884],
              [156.908301, 57.668023],
              [156.86508, 57.708273],
              [156.808461, 57.726767],
              [156.774398, 57.724125],
              [156.799552, 57.747355],
              [156.83631, 57.77334],
              [156.854311, 57.776975],
              [156.84368, 57.786526],
              [156.859516, 57.802415],
              [156.867606, 57.795381],
              [156.907643, 57.799633],
              [156.917927, 57.812606],
              [156.963814, 57.832684],
              [156.988357, 57.848824],
              [157.010642, 57.85404],
              [157.018698, 57.838547],
              [157.035934, 57.830621],
              [157.034115, 57.811132],
              [157.059784, 57.7977],
              [157.124433, 57.781005],
              [157.233444, 57.76708],
              [157.306803, 57.766033],
              [157.357818, 57.771569],
              [157.432823, 57.787881],
              [157.52543, 57.821125],
              [157.569519, 57.864899],
              [157.573813, 57.883893],
              [157.587954, 57.897109],
              [157.575683, 57.933839],
              [157.615738, 57.943626],
              [157.6721, 57.981735],
              [157.674003, 57.99558],
              [157.652971, 58.003552],
              [157.6795, 58.016502],
              [157.689778, 58.012512],
              [157.73949, 58.011734],
              [157.757641, 58.016905],
              [157.76762, 57.993193],
              [157.80986, 57.984379],
              [157.877547, 57.977697],
              [157.949365, 57.977159],
              [158.01287, 57.980868],
              [158.08924, 57.991038],
              [158.144361, 58.004348],
              [158.19361, 58.023102],
              [158.22267, 58.017907],
              [158.266, 58.02226],
              [158.277684, 58.017079],
              [158.28414, 57.995868],
              [158.300998, 57.984186],
              [158.315899, 57.986315],
              [158.305731, 58.001107],
              [158.307125, 58.028763],
              [158.29389, 58.039442],
              [158.261249, 58.042072],
              [158.224782, 58.031872],
              [158.348401, 58.080947],
              [158.418602, 58.132485],
              [158.583426, 58.212805],
              [158.664995, 58.261055],
              [158.727241, 58.287912],
              [158.802575, 58.311731],
              [159.022991, 58.401828],
              [159.09609, 58.437268],
              [159.147802, 58.476845],
              [159.201034, 58.540653],
              [159.225147, 58.560066],
              [159.330874, 58.603864],
              [159.427382, 58.67525],
              [159.485185, 58.697058],
              [159.530372, 58.725804],
              [159.58121, 58.76398],
              [159.62642, 58.812145],
              [159.61669, 58.819871],
              [159.634241, 58.835143],
              [159.685049, 58.832836],
              [159.706026, 58.844754],
              [159.735736, 58.87172],
              [159.746483, 58.888808],
              [159.760929, 58.938636],
              [159.753406, 58.968372],
              [159.740967, 58.977658],
              [159.71842, 58.978041],
              [159.763505, 59.032814],
              [159.829457, 59.070291],
              [159.837399, 59.100443],
              [159.856902, 59.124376],
              [159.926851, 59.161009],
              [159.969079, 59.173755],
              [160.021332, 59.203814],
              [160.042405, 59.223868],
              [160.082999, 59.244269],
              [160.10476, 59.268402],
              [160.122265, 59.26838],
              [160.164196, 59.288362],
              [160.189579, 59.313217],
              [160.196912, 59.329531],
              [160.236601, 59.332744],
              [160.264025, 59.329281],
              [160.314175, 59.343759],
              [160.396683, 59.388208],
              [160.430622, 59.419581],
              [160.458085, 59.462536],
              [160.464384, 59.491902],
              [160.489811, 59.549208],
              [160.512158, 59.558423],
              [160.55086, 59.555666],
              [160.617763, 59.562536],
              [160.67463, 59.580123],
              [160.691752, 59.589503],
              [160.749888, 59.591043],
              [160.768439, 59.598217],
              [160.792067, 59.596232],
              [160.84616, 59.606616],
              [160.881224, 59.619429],
              [160.911539, 59.637166],
              [160.953944, 59.6839],
              [160.945134, 59.702206],
              [160.9905, 59.70703],
              [161.036432, 59.733333],
              [161.041823, 59.742722],
              [161.066753, 59.743995],
              [161.12218, 59.762185],
              [161.157614, 59.784392],
              [161.191889, 59.819154],
              [161.211457, 59.822923],
              [161.265415, 59.846326],
              [161.322182, 59.905245],
              [161.328112, 59.920758],
              [161.3752, 59.94808],
              [161.398193, 59.952016],
              [161.419447, 59.977487],
              [161.43933, 59.987361],
              [161.465311, 60.013762],
              [161.467446, 60.025243],
              [161.492786, 60.042433],
              [161.52455, 60.049511],
              [161.557542, 60.045804],
              [161.637128, 60.080854],
              [161.681041, 60.091493],
              [161.73931, 60.120257],
              [161.766343, 60.124431],
              [161.831486, 60.153909],
              [161.850177, 60.173993],
              [161.88264, 60.192495],
              [161.92021, 60.207293],
              [161.94528, 60.234394],
              [161.949626, 60.282694],
              [161.937139, 60.313059],
              [161.914918, 60.31278],
              [161.88983, 60.298306],
              [161.90676, 60.319745],
              [161.924301, 60.354185],
              [161.94371, 60.420334],
              [161.985087, 60.431064],
              [162.011347, 60.426374],
              [162.039353, 60.437371],
              [162.08648, 60.4781],
              [162.099344, 60.473516],
              [162.143994, 60.47896],
              [162.167134, 60.487264],
              [162.206684, 60.528169],
              [162.231754, 60.526643],
              [162.272557, 60.535677],
              [162.380618, 60.536049],
              [162.419902, 60.548464],
              [162.438329, 60.568532],
              [162.478845, 60.582616],
              [162.514912, 60.607612],
              [162.55004, 60.611333],
              [162.575758, 60.601817],
              [162.643972, 60.594589],
              [162.705991, 60.608211],
              [162.720546, 60.630732],
              [162.74909, 60.624943],
              [162.786798, 60.644899],
              [162.824067, 60.687491],
              [162.856957, 60.703124],
              [162.897179, 60.734989],
              [162.890815, 60.752296],
              [162.942186, 60.758725],
              [162.963537, 60.768521],
              [163.009648, 60.775996],
              [163.067106, 60.75188],
              [163.12717, 60.753372],
              [163.141761, 60.76778],
              [163.116439, 60.775745],
              [163.147633, 60.779379],
              [163.199492, 60.806703],
              [163.245347, 60.792081],
              [163.311918, 60.800741],
              [163.335659, 60.797455],
              [163.389364, 60.802204],
              [163.406079, 60.813665],
              [163.442925, 60.821007],
              [163.527243, 60.845118],
              [163.560759, 60.846249],
              [163.612866, 60.866246],
              [163.655212, 60.870131],
              [163.675186, 60.856766],
              [163.695136, 60.85645],
              [163.729926, 60.872137],
              [163.74428, 60.900934],
              [163.762959, 60.920555],
              [163.735001, 60.922982],
              [163.686088, 60.936205],
              [163.65478, 60.955217],
              [163.613991, 60.96133],
              [163.567325, 60.980675],
              [163.547946, 60.978292],
              [163.53376, 61.000403],
              [163.50498, 60.990363],
              [163.48849, 61.004766],
              [163.513913, 61.030384],
              [163.553779, 61.04694],
              [163.588729, 61.044366],
              [163.612609, 61.053443],
              [163.636079, 61.078505],
              [163.621979, 61.11726],
              [163.64303, 61.119776],
              [163.660921, 61.134541],
              [163.688944, 61.135198],
              [163.739319, 61.159348],
              [163.767132, 61.179564],
              [163.784131, 61.178566],
              [163.882645, 61.21025],
              [163.90131, 61.207459],
              [163.940185, 61.226577],
              [163.95511, 61.256631],
              [163.946854, 61.2732],
              [163.9937, 61.304261],
              [164.022196, 61.337477],
              [164.028532, 61.356457],
              [163.990191, 61.405051],
              [163.957185, 61.426423],
              [163.902104, 61.44339],
              [163.867742, 61.447715],
              [163.811862, 61.441226],
              [163.791393, 61.41776],
              [163.772037, 61.414261],
              [163.754705, 61.433806],
              [163.750901, 61.468828],
              [163.778275, 61.50055],
              [163.811021, 61.526427],
              [163.839705, 61.561985],
              [163.852695, 61.587939],
              [163.861092, 61.637137],
              [163.870535, 61.651032],
              [163.93351, 61.665993],
              [163.972396, 61.662069],
              [164.014311, 61.664169],
              [164.04714, 61.6818],
              [164.054094, 61.692082],
              [164.066049, 61.759968],
              [164.073045, 61.778693],
              [164.072476, 61.815533],
              [164.046281, 61.840428],
              [164.063089, 61.848475],
              [164.085461, 61.879072],
              [164.100313, 61.917804],
              [164.080164, 61.938139],
              [164.088533, 61.967106],
              [164.072882, 61.990284],
              [164.09226, 61.997321],
              [164.11089, 62.020725],
              [164.0973, 62.065922],
              [164.076202, 62.096228],
              [164.115787, 62.114652],
              [164.126224, 62.131604],
              [164.103916, 62.165233],
              [164.127788, 62.191226],
              [164.125644, 62.227542],
              [164.156419, 62.274039],
              [164.216432, 62.288973],
              [164.23074, 62.30952],
              [164.25799, 62.319134],
              [164.300736, 62.325495],
              [164.315966, 62.33299],
              [164.35942, 62.333197],
              [164.372858, 62.348608],
              [164.41026, 62.349922],
              [164.439473, 62.362707],
              [164.448444, 62.39907],
              [164.508367, 62.409783],
              [164.527223, 62.438244],
              [164.601155, 62.441801],
              [164.642393, 62.456238],
              [164.682219, 62.451346],
              [164.734633, 62.457723],
              [164.808247, 62.435971],
              [164.86237, 62.429896],
              [164.943549, 62.407184],
              [164.989325, 62.40451],
              [165.081436, 62.411393],
              [165.115225, 62.448463],
              [165.091595, 62.486724],
              [165.033004, 62.507856],
              [164.990947, 62.512464],
              [164.902294, 62.543383],
              [164.861815, 62.550895],
              [164.800264, 62.556833],
              [164.791429, 62.55196],
              [164.719732, 62.57409],
              [164.711137, 62.602468],
              [164.681354, 62.63617],
              [164.640603, 62.651165],
              [164.637387, 62.663947],
              [164.615204, 62.68026],
              [164.564022, 62.678575],
              [164.534426, 62.684875],
              [164.48773, 62.680876],
              [164.439064, 62.693863],
              [164.37836, 62.694248],
              [164.340167, 62.683841],
              [164.299414, 62.665224],
              [164.269462, 62.661617],
              [164.16981, 62.664517],
              [164.112838, 62.653551],
              [164.053325, 62.653802],
              [164.004757, 62.64272],
              [163.953094, 62.610109],
              [163.928996, 62.585367],
              [163.897293, 62.587423],
              [163.848785, 62.607755],
              [163.78775, 62.6066],
              [163.754612, 62.602344],
              [163.671662, 62.582521],
              [163.615595, 62.583129],
              [163.59082, 62.579044],
              [163.548379, 62.561979],
              [163.47643, 62.548548],
              [163.412061, 62.550007],
              [163.328433, 62.538925],
              [163.27486, 62.526376],
              [163.250136, 62.513531],
              [163.265661, 62.470225],
              [163.25792, 62.456054],
              [163.191259, 62.434479],
              [163.19927, 62.420636],
              [163.22702, 62.41805],
              [163.229717, 62.386354],
              [163.274402, 62.371433],
              [163.302882, 62.374471],
              [163.370079, 62.339451],
              [163.344199, 62.324688],
              [163.284827, 62.323968],
              [163.25373, 62.319486],
              [163.226414, 62.306986],
              [163.222834, 62.293122],
              [163.241834, 62.287735],
              [163.220063, 62.274919],
              [163.18761, 62.275779],
              [163.148501, 62.259653],
              [163.083447, 62.220768],
              [163.100919, 62.213203],
              [163.093719, 62.201333],
              [163.09372, 62.17088],
              [163.115242, 62.146096],
              [163.11085, 62.137591],
              [163.14241, 62.117802],
              [163.133199, 62.098741],
              [163.098712, 62.095712],
              [163.11064, 62.075147],
              [163.132824, 62.069307],
              [163.159208, 62.075035],
              [163.191324, 62.067142],
              [163.184224, 62.049008],
              [163.13516, 62.031208],
              [163.142366, 62.002388],
              [163.116363, 61.991543],
              [163.09814, 61.969541],
              [163.074671, 61.971006],
              [163.08275, 61.954996],
              [163.059787, 61.931386],
              [163.039974, 61.927011],
              [163.067155, 61.917926],
              [163.059992, 61.902471],
              [163.01847, 61.856581],
              [163.010627, 61.835482],
              [162.966066, 61.808833],
              [162.98724, 61.787539],
              [163.03096, 61.771665],
              [163.090184, 61.757532],
              [163.120861, 61.759235],
              [163.134046, 61.746619],
              [163.156623, 61.748204],
              [163.212976, 61.735258],
              [163.249779, 61.733302],
              [163.286416, 61.724552],
              [163.307544, 61.713043],
              [163.33682, 61.710075],
              [163.343186, 61.701963],
              [163.321044, 61.687875],
              [163.324523, 61.658488],
              [163.24357, 61.643398],
              [163.223521, 61.645826],
              [163.200121, 61.627223],
              [163.232836, 61.614598],
              [163.226366, 61.605557],
              [163.19691, 61.613105],
              [163.177098, 61.630116],
              [163.130237, 61.622446],
              [163.0944, 61.601989],
              [163.07479, 61.580344],
              [163.108219, 61.569167],
              [163.112144, 61.548747],
              [163.095606, 61.538537],
              [163.03855, 61.516486],
              [163.016196, 61.514116],
              [162.985547, 61.53235],
              [162.98877, 61.544682],
              [162.962884, 61.559143],
              [162.959303, 61.580972],
              [162.910644, 61.577932],
              [162.895097, 61.585501],
              [162.91575, 61.597846],
              [162.894938, 61.611604],
              [162.867607, 61.610413],
              [162.858363, 61.619738],
              [162.941221, 61.647116],
              [162.930091, 61.668703],
              [162.907789, 61.683976],
              [162.854869, 61.709008],
              [162.821518, 61.71552],
              [162.724172, 61.697173],
              [162.71036, 61.687216],
              [162.64293, 61.674042],
              [162.616996, 61.661051],
              [162.64754, 61.627536],
              [162.692763, 61.619731],
              [162.741638, 61.627842],
              [162.778016, 61.60054],
              [162.753976, 61.591188],
              [162.694412, 61.604786],
              [162.673403, 61.598287],
              [162.630423, 61.626344],
              [162.59954, 61.629261],
              [162.564285, 61.646684],
              [162.465934, 61.664844],
            ],
          ],
          [
            [
              [162.781913, 60.803015],
              [162.764982, 60.79787],
              [162.780111, 60.825255],
              [162.781913, 60.803015],
            ],
          ],
          [
            [
              [163.270908, 60.852175],
              [163.288203, 60.872103],
              [163.30022, 60.863298],
              [163.270908, 60.852175],
            ],
          ],
          [
            [
              [172.459268, 61.103612],
              [172.48754, 61.114564],
              [172.488948, 61.099449],
              [172.459268, 61.103612],
            ],
          ],
          [
            [
              [162.985726, 61.455059],
              [163.016971, 61.468315],
              [163.011131, 61.450826],
              [162.985726, 61.455059],
            ],
          ],
          [
            [
              [162.527145, 61.565896],
              [162.510894, 61.574433],
              [162.580594, 61.589508],
              [162.60269, 61.574667],
              [162.646249, 61.573761],
              [162.63317, 61.562272],
              [162.59029, 61.561522],
              [162.527145, 61.565896],
            ],
          ],
          [
            [
              [163.459465, 62.340469],
              [163.486824, 62.354587],
              [163.48758, 62.339626],
              [163.459465, 62.340469],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5467",
      properties: {
        name: "Карачаево-Черкесская республика",
        density: 0,
        path: "/world/Russia/Карачаево-Черкесская республика",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.736821, 44.473134],
            [41.792645, 44.470522],
            [41.807331, 44.484452],
            [41.865302, 44.486981],
            [41.86469, 44.496795],
            [41.933232, 44.496139],
            [41.926061, 44.482305],
            [41.930363, 44.469026],
            [41.92203, 44.456144],
            [41.927834, 44.438727],
            [41.9218, 44.40993],
            [41.951627, 44.393526],
            [41.977978, 44.387714],
            [42.018255, 44.37163],
            [42.029404, 44.35275],
            [42.119405, 44.352845],
            [42.120022, 44.348989],
            [42.217269, 44.347838],
            [42.237575, 44.336656],
            [42.285639, 44.326657],
            [42.298839, 44.328137],
            [42.341392, 44.310887],
            [42.408013, 44.307995],
            [42.408795, 44.325217],
            [42.478497, 44.324628],
            [42.495734, 44.350016],
            [42.494614, 44.364247],
            [42.510115, 44.368334],
            [42.553418, 44.368318],
            [42.553608, 44.352438],
            [42.581737, 44.343145],
            [42.580704, 44.320388],
            [42.608568, 44.31185],
            [42.609648, 44.269443],
            [42.553135, 44.269106],
            [42.552023, 44.223795],
            [42.50173, 44.224918],
            [42.500786, 44.214943],
            [42.481053, 44.215861],
            [42.469826, 44.19472],
            [42.440252, 44.202141],
            [42.44137, 44.168431],
            [42.378722, 44.168116],
            [42.378687, 44.153491],
            [42.335165, 44.132512],
            [42.351602, 44.114092],
            [42.324978, 44.097371],
            [42.320219, 44.064938],
            [42.298903, 44.053928],
            [42.293279, 44.038985],
            [42.303408, 44.029137],
            [42.343488, 44.02388],
            [42.358284, 44.037804],
            [42.370382, 44.004071],
            [42.379982, 43.997747],
            [42.369612, 43.989705],
            [42.376245, 43.972467],
            [42.412466, 43.978947],
            [42.424913, 43.973513],
            [42.480224, 43.966421],
            [42.495719, 43.978314],
            [42.481334, 43.980395],
            [42.48049, 44.014419],
            [42.502637, 44.026397],
            [42.584567, 44.026377],
            [42.577584, 43.997102],
            [42.579146, 43.973354],
            [42.624916, 43.973578],
            [42.627183, 43.951902],
            [42.61901, 43.945347],
            [42.660201, 43.937436],
            [42.647174, 43.916275],
            [42.640156, 43.917482],
            [42.639193, 43.89239],
            [42.628037, 43.890869],
            [42.655145, 43.856186],
            [42.639535, 43.846094],
            [42.646519, 43.827188],
            [42.678289, 43.809695],
            [42.6686, 43.803198],
            [42.666595, 43.782216],
            [42.679586, 43.736831],
            [42.681373, 43.716046],
            [42.675528, 43.69786],
            [42.667246, 43.700458],
            [42.620482, 43.676506],
            [42.60262, 43.656208],
            [42.456074, 43.621092],
            [42.460459, 43.584557],
            [42.469066, 43.572703],
            [42.464195, 43.545342],
            [42.474425, 43.529916],
            [42.467119, 43.506533],
            [42.451125, 43.482379],
            [42.456432, 43.443167],
            [42.421411, 43.4374],
            [42.418046, 43.405923],
            [42.403016, 43.392464],
            [42.415242, 43.365181],
            [42.436676, 43.350567],
            [42.415405, 43.344397],
            [42.409722, 43.317766],
            [42.397382, 43.292435],
            [42.415417, 43.274839],
            [42.414023, 43.256817],
            [42.407136, 43.248648],
            [42.354336, 43.24318],
            [42.348307, 43.227033],
            [42.336226, 43.219685],
            [42.306224, 43.223828],
            [42.294532, 43.211216],
            [42.253082, 43.213238],
            [42.249049, 43.227019],
            [42.218457, 43.230684],
            [42.196795, 43.226768],
            [42.177971, 43.216906],
            [42.16639, 43.224183],
            [42.129574, 43.210635],
            [42.095582, 43.214035],
            [42.070727, 43.19774],
            [42.040998, 43.187409],
            [42.018162, 43.217788],
            [41.967663, 43.212332],
            [41.957372, 43.204058],
            [41.942296, 43.225699],
            [41.912431, 43.226061],
            [41.889105, 43.248081],
            [41.866791, 43.244063],
            [41.865845, 43.233763],
            [41.848378, 43.211513],
            [41.830688, 43.2147],
            [41.80388, 43.202273],
            [41.78327, 43.22224],
            [41.773152, 43.218256],
            [41.745493, 43.237071],
            [41.728498, 43.243226],
            [41.700917, 43.21926],
            [41.684854, 43.217455],
            [41.660895, 43.237486],
            [41.634419, 43.221966],
            [41.619072, 43.222305],
            [41.588846, 43.234733],
            [41.574673, 43.252708],
            [41.561691, 43.249394],
            [41.545421, 43.26887],
            [41.507875, 43.276684],
            [41.489022, 43.286257],
            [41.461629, 43.308501],
            [41.442677, 43.305902],
            [41.422595, 43.314047],
            [41.40957, 43.33544],
            [41.413135, 43.357095],
            [41.393641, 43.366229],
            [41.365941, 43.370041],
            [41.340786, 43.347482],
            [41.317975, 43.339561],
            [41.287373, 43.337717],
            [41.276751, 43.359219],
            [41.256333, 43.364034],
            [41.247853, 43.358029],
            [41.219206, 43.381026],
            [41.154319, 43.390776],
            [41.151831, 43.403668],
            [41.12771, 43.39804],
            [41.10702, 43.404106],
            [41.096373, 43.385163],
            [41.073667, 43.387552],
            [41.038776, 43.399993],
            [41.004166, 43.432048],
            [40.950818, 43.418533],
            [40.926205, 43.434105],
            [40.929268, 43.443885],
            [40.892918, 43.458422],
            [40.899237, 43.467604],
            [40.890915, 43.48961],
            [40.878765, 43.485127],
            [40.833799, 43.489448],
            [40.777367, 43.505355],
            [40.755834, 43.519983],
            [40.713602, 43.532771],
            [40.726324, 43.549258],
            [40.726145, 43.58359],
            [40.739701, 43.589462],
            [40.742397, 43.60543],
            [40.725684, 43.617414],
            [40.712418, 43.645923],
            [40.699356, 43.653443],
            [40.709309, 43.660782],
            [40.712826, 43.685883],
            [40.692923, 43.695614],
            [40.696001, 43.703801],
            [40.684137, 43.7202],
            [40.701007, 43.741355],
            [40.71113, 43.735981],
            [40.732303, 43.738651],
            [40.756861, 43.749115],
            [40.741954, 43.782686],
            [40.722806, 43.7949],
            [40.755663, 43.829717],
            [40.752922, 43.836755],
            [40.765771, 43.872229],
            [40.785981, 43.880003],
            [40.802972, 43.917742],
            [40.834261, 43.921044],
            [40.840751, 43.932735],
            [40.860826, 43.94688],
            [40.885749, 43.944623],
            [40.911465, 43.977061],
            [40.91512, 43.997726],
            [40.935459, 44.017353],
            [40.919054, 44.038141],
            [40.926887, 44.068084],
            [40.910667, 44.083673],
            [40.926203, 44.09018],
            [40.891244, 44.103888],
            [40.891276, 44.142995],
            [40.914129, 44.145297],
            [40.954853, 44.14107],
            [41.031259, 44.112563],
            [41.035626, 44.121109],
            [41.061504, 44.112629],
            [41.084788, 44.094713],
            [41.122556, 44.106524],
            [41.121454, 44.126023],
            [41.152235, 44.117715],
            [41.169946, 44.104265],
            [41.170276, 44.088794],
            [41.232298, 44.089329],
            [41.264029, 44.094959],
            [41.291116, 44.085462],
            [41.30147, 44.061719],
            [41.294487, 44.055537],
            [41.278499, 44.073784],
            [41.281858, 44.051354],
            [41.246592, 44.009949],
            [41.273621, 44.018233],
            [41.277656, 44.004234],
            [41.295147, 44.016818],
            [41.313159, 44.015349],
            [41.292763, 44.000815],
            [41.308807, 43.997952],
            [41.315039, 43.979784],
            [41.360119, 43.977994],
            [41.350163, 43.993271],
            [41.362695, 43.999758],
            [41.354443, 44.00942],
            [41.380429, 44.003677],
            [41.393281, 43.985687],
            [41.414169, 43.98285],
            [41.432291, 44.000443],
            [41.452599, 44.004105],
            [41.47975, 44.000926],
            [41.484461, 44.046941],
            [41.538583, 44.044665],
            [41.553604, 44.064285],
            [41.556685, 44.087222],
            [41.550784, 44.096158],
            [41.599999, 44.112284],
            [41.637668, 44.117428],
            [41.677916, 44.15664],
            [41.683131, 44.167666],
            [41.698912, 44.171185],
            [41.711403, 44.198071],
            [41.712361, 44.214777],
            [41.728459, 44.232715],
            [41.727642, 44.247823],
            [41.74398, 44.268377],
            [41.690496, 44.333406],
            [41.592001, 44.363556],
            [41.59778, 44.374121],
            [41.633695, 44.377892],
            [41.628202, 44.407096],
            [41.693904, 44.409881],
            [41.706222, 44.455179],
            [41.735251, 44.460957],
            [41.736821, 44.473134],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5541",
      properties: {
        name: "Кемеровская область",
        density: 0,
        path: "/world/Russia/Кемеровская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [84.45382, 56.043149],
            [84.468541, 56.052924],
            [84.522506, 56.062979],
            [84.556656, 56.081066],
            [84.591818, 56.0825],
            [84.638531, 56.099638],
            [84.676729, 56.102758],
            [84.757087, 56.127254],
            [84.832554, 56.139017],
            [84.897481, 56.162817],
            [84.926825, 56.154132],
            [84.960975, 56.135624],
            [84.986862, 56.107777],
            [85.043273, 56.125611],
            [85.035431, 56.131092],
            [85.106345, 56.172957],
            [85.11621, 56.195871],
            [85.208036, 56.197642],
            [85.20188, 56.216867],
            [85.231645, 56.225658],
            [85.290164, 56.228356],
            [85.356947, 56.213516],
            [85.386544, 56.210818],
            [85.388146, 56.202471],
            [85.413864, 56.206097],
            [85.453073, 56.194966],
            [85.452274, 56.211113],
            [85.671139, 56.211846],
            [85.674163, 56.234134],
            [85.571628, 56.266407],
            [85.562492, 56.280707],
            [85.628966, 56.3174],
            [85.655611, 56.317569],
            [85.690436, 56.324757],
            [85.692291, 56.332515],
            [85.724164, 56.338692],
            [85.77307, 56.340125],
            [85.790525, 56.333211],
            [85.81228, 56.339641],
            [85.780658, 56.387092],
            [85.797523, 56.386523],
            [85.804184, 56.411439],
            [85.882771, 56.430095],
            [85.886397, 56.426638],
            [85.979234, 56.436525],
            [85.984631, 56.453579],
            [85.976283, 56.463972],
            [85.992051, 56.466586],
            [86.002583, 56.480861],
            [86.052945, 56.477857],
            [86.089079, 56.486996],
            [86.11507, 56.499507],
            [86.105171, 56.537152],
            [86.129704, 56.54279],
            [86.195642, 56.578759],
            [86.191003, 56.603197],
            [86.207586, 56.604779],
            [86.189443, 56.615721],
            [86.216043, 56.628059],
            [86.338887, 56.636074],
            [86.334438, 56.592011],
            [86.364136, 56.594563],
            [86.382675, 56.548036],
            [86.440552, 56.549862],
            [86.488621, 56.569464],
            [86.532459, 56.577249],
            [86.591258, 56.613592],
            [86.597467, 56.593799],
            [86.6222, 56.588123],
            [86.633685, 56.618225],
            [86.67819, 56.643265],
            [86.746245, 56.603176],
            [86.78771, 56.615559],
            [86.804658, 56.626212],
            [86.821676, 56.617442],
            [86.852139, 56.589539],
            [86.838795, 56.586899],
            [86.848797, 56.555901],
            [86.909466, 56.540903],
            [86.925984, 56.551537],
            [86.963234, 56.564119],
            [86.969635, 56.578898],
            [87.000494, 56.54191],
            [87.085961, 56.535332],
            [87.118012, 56.535604],
            [87.137734, 56.548739],
            [87.199818, 56.561724],
            [87.225251, 56.574715],
            [87.193837, 56.5871],
            [87.183538, 56.576228],
            [87.158864, 56.588387],
            [87.181649, 56.595607],
            [87.179933, 56.612898],
            [87.164827, 56.606379],
            [87.149549, 56.622909],
            [87.16002, 56.63273],
            [87.15384, 56.649154],
            [87.128434, 56.651985],
            [87.172663, 56.664294],
            [87.195664, 56.638864],
            [87.242786, 56.630271],
            [87.262701, 56.63858],
            [87.349474, 56.635181],
            [87.383977, 56.645611],
            [87.403373, 56.62706],
            [87.347585, 56.61096],
            [87.36458, 56.592957],
            [87.444656, 56.612752],
            [87.596665, 56.663656],
            [87.653999, 56.600611],
            [87.633813, 56.59324],
            [87.642603, 56.561621],
            [87.654309, 56.543607],
            [87.698943, 56.560436],
            [87.701479, 56.551007],
            [87.931175, 56.631567],
            [87.998176, 56.656265],
            [88.052597, 56.669813],
            [88.057575, 56.688106],
            [88.142033, 56.709407],
            [88.303566, 56.78264],
            [88.327599, 56.791855],
            [88.362446, 56.827376],
            [88.400726, 56.821552],
            [88.424416, 56.83442],
            [88.523953, 56.768973],
            [88.597557, 56.802198],
            [88.648161, 56.819786],
            [88.624413, 56.719298],
            [88.736076, 56.714701],
            [88.718487, 56.682136],
            [88.674013, 56.680068],
            [88.663149, 56.619767],
            [88.500878, 56.62189],
            [88.52623, 56.552816],
            [88.546388, 56.529922],
            [88.605597, 56.531251],
            [88.609168, 56.46327],
            [88.582962, 56.460457],
            [88.593416, 56.430664],
            [88.696483, 56.418182],
            [88.695147, 56.36643],
            [88.860922, 56.372908],
            [88.866519, 56.358142],
            [88.894965, 56.348794],
            [88.920763, 56.333893],
            [88.912883, 56.32183],
            [88.892981, 56.31524],
            [88.900879, 56.301983],
            [89.039898, 56.300484],
            [89.036652, 56.201266],
            [89.097007, 56.201208],
            [89.126689, 56.184532],
            [89.099127, 56.1639],
            [89.104815, 56.151022],
            [89.136266, 56.14971],
            [89.151761, 56.139216],
            [89.201353, 56.138817],
            [89.192927, 56.134806],
            [89.194023, 56.081165],
            [89.247083, 56.070401],
            [89.255537, 56.076846],
            [89.280863, 56.053909],
            [89.260058, 56.048763],
            [89.258663, 56.033986],
            [89.235652, 56.013021],
            [89.248512, 56.00569],
            [89.241638, 55.992782],
            [89.256115, 55.983206],
            [89.262011, 55.965129],
            [89.280624, 55.942477],
            [89.262857, 55.93301],
            [89.285061, 55.914307],
            [89.275644, 55.892811],
            [89.305735, 55.896666],
            [89.320941, 55.887192],
            [89.361557, 55.881071],
            [89.402644, 55.866562],
            [89.387679, 55.85338],
            [89.399962, 55.841301],
            [89.36326, 55.829846],
            [89.332535, 55.811453],
            [89.33202, 55.774602],
            [89.349576, 55.770889],
            [89.294845, 55.757835],
            [89.274805, 55.759326],
            [89.265302, 55.746363],
            [89.236279, 55.734648],
            [89.165656, 55.726749],
            [89.140517, 55.694502],
            [89.105201, 55.699334],
            [89.084478, 55.693683],
            [89.050681, 55.693479],
            [89.023577, 55.672052],
            [88.96722, 55.670472],
            [88.94769, 55.658478],
            [88.913599, 55.660203],
            [88.89184, 55.629866],
            [88.872089, 55.627732],
            [88.862214, 55.616706],
            [88.825879, 55.605601],
            [88.820579, 55.590937],
            [88.766489, 55.571577],
            [88.757496, 55.558152],
            [88.729674, 55.550345],
            [88.734618, 55.543557],
            [88.711924, 55.537635],
            [88.675315, 55.517335],
            [88.653079, 55.499269],
            [88.660294, 55.481575],
            [88.650615, 55.470411],
            [88.632473, 55.472022],
            [88.611406, 55.455835],
            [88.617088, 55.451539],
            [88.601258, 55.427377],
            [88.611029, 55.41834],
            [88.573088, 55.397196],
            [88.569312, 55.375725],
            [88.542311, 55.365963],
            [88.535042, 55.342633],
            [88.490334, 55.329812],
            [88.472795, 55.315699],
            [88.442366, 55.304549],
            [88.446689, 55.289678],
            [88.432544, 55.281874],
            [88.446518, 55.262224],
            [88.488049, 55.239229],
            [88.48995, 55.212695],
            [88.51001, 55.213393],
            [88.51857, 55.202245],
            [88.501839, 55.191116],
            [88.525117, 55.179077],
            [88.518298, 55.143557],
            [88.544084, 55.135912],
            [88.560741, 55.119048],
            [88.550186, 55.051339],
            [88.539308, 55.035888],
            [88.556563, 55.016892],
            [88.550427, 55.001677],
            [88.599541, 55.001986],
            [88.606291, 54.963456],
            [88.628385, 54.957931],
            [88.65381, 54.965732],
            [88.673682, 54.949111],
            [88.665424, 54.944231],
            [88.676801, 54.929747],
            [88.662633, 54.924175],
            [88.646555, 54.896987],
            [88.687834, 54.875196],
            [88.688967, 54.882552],
            [88.732001, 54.892945],
            [88.747198, 54.879469],
            [88.738961, 54.867757],
            [88.758422, 54.848333],
            [88.747868, 54.840162],
            [88.754163, 54.790547],
            [88.732453, 54.768861],
            [88.718436, 54.765672],
            [88.659798, 54.76744],
            [88.635811, 54.756529],
            [88.648647, 54.725857],
            [88.625769, 54.700898],
            [88.595401, 54.68305],
            [88.596995, 54.671376],
            [88.6198, 54.67476],
            [88.606885, 54.660401],
            [88.608022, 54.642431],
            [88.578944, 54.60631],
            [88.588558, 54.590191],
            [88.574997, 54.575683],
            [88.592093, 54.54169],
            [88.56582, 54.517836],
            [88.574052, 54.506016],
            [88.563371, 54.485822],
            [88.568741, 54.47939],
            [88.537757, 54.467244],
            [88.542332, 54.458624],
            [88.518374, 54.45649],
            [88.530751, 54.438141],
            [88.545095, 54.434451],
            [88.524409, 54.415688],
            [88.503846, 54.416481],
            [88.465218, 54.409471],
            [88.421954, 54.393341],
            [88.380624, 54.397388],
            [88.363813, 54.382062],
            [88.367763, 54.372208],
            [88.394309, 54.353236],
            [88.435603, 54.349481],
            [88.45478, 54.356446],
            [88.498421, 54.348067],
            [88.516723, 54.335887],
            [88.558052, 54.337789],
            [88.5812, 54.323219],
            [88.571659, 54.297064],
            [88.594107, 54.286127],
            [88.621098, 54.290286],
            [88.630714, 54.281594],
            [88.651612, 54.293439],
            [88.66442, 54.316153],
            [88.663695, 54.328411],
            [88.69484, 54.336242],
            [88.696615, 54.347217],
            [88.740377, 54.359258],
            [88.75842, 54.374098],
            [88.788401, 54.389057],
            [88.80741, 54.385801],
            [88.827133, 54.403222],
            [88.83563, 54.420038],
            [88.859688, 54.417708],
            [88.867904, 54.401112],
            [88.906129, 54.403487],
            [88.927541, 54.38632],
            [88.927829, 54.370738],
            [88.944188, 54.367799],
            [88.953502, 54.349514],
            [88.938367, 54.320394],
            [88.972936, 54.317506],
            [88.987123, 54.299554],
            [88.974605, 54.288641],
            [88.999211, 54.287349],
            [89.028477, 54.314152],
            [89.029732, 54.331375],
            [89.075554, 54.330119],
            [89.079956, 54.338724],
            [89.125804, 54.345714],
            [89.15976, 54.341127],
            [89.16118, 54.310003],
            [89.202405, 54.299327],
            [89.191946, 54.290004],
            [89.209741, 54.26738],
            [89.238394, 54.265603],
            [89.21672, 54.24977],
            [89.20699, 54.229196],
            [89.211309, 54.213181],
            [89.194711, 54.206289],
            [89.201, 54.1834],
            [89.159697, 54.135923],
            [89.135651, 54.129098],
            [89.127271, 54.117711],
            [89.098869, 54.118507],
            [89.086131, 54.108739],
            [89.041004, 54.108859],
            [89.040398, 54.098582],
            [89.01232, 54.080741],
            [89.005812, 54.06533],
            [89.014226, 54.036588],
            [89.023913, 54.02596],
            [89.006933, 54.013103],
            [89.007465, 54.000501],
            [89.061692, 53.972464],
            [89.041655, 53.960798],
            [89.041741, 53.940037],
            [89.050639, 53.938406],
            [89.074112, 53.956496],
            [89.131373, 53.954363],
            [89.142063, 53.948524],
            [89.17002, 53.954979],
            [89.194799, 53.952626],
            [89.194915, 53.933714],
            [89.207976, 53.920221],
            [89.200816, 53.906073],
            [89.175002, 53.897683],
            [89.165088, 53.885376],
            [89.197677, 53.850114],
            [89.237377, 53.838319],
            [89.237828, 53.818508],
            [89.218642, 53.805365],
            [89.188202, 53.795978],
            [89.14591, 53.801516],
            [89.127828, 53.787626],
            [89.11074, 53.79328],
            [89.091021, 53.788571],
            [89.07645, 53.794474],
            [89.044724, 53.79211],
            [89.04309, 53.752827],
            [89.010713, 53.72828],
            [89.002941, 53.687142],
            [88.977776, 53.663476],
            [88.994553, 53.656878],
            [88.97477, 53.644837],
            [88.948332, 53.646638],
            [88.926461, 53.625703],
            [88.933916, 53.611549],
            [88.891366, 53.608186],
            [88.874174, 53.597039],
            [88.838829, 53.595685],
            [88.845743, 53.577739],
            [88.860797, 53.565613],
            [88.88733, 53.562521],
            [88.880931, 53.542106],
            [88.857273, 53.530762],
            [88.876085, 53.498],
            [88.859936, 53.464],
            [88.839869, 53.472338],
            [88.82119, 53.466738],
            [88.805481, 53.437065],
            [88.820263, 53.426434],
            [88.805646, 53.419817],
            [88.786875, 53.382874],
            [88.75786, 53.376288],
            [88.758809, 53.355877],
            [88.780016, 53.342421],
            [88.806406, 53.343808],
            [88.822868, 53.368653],
            [88.880464, 53.394102],
            [88.910521, 53.392139],
            [88.927784, 53.355883],
            [88.951507, 53.342117],
            [88.945876, 53.317429],
            [88.987426, 53.301163],
            [89.003842, 53.287091],
            [89.036455, 53.280057],
            [89.034636, 53.262663],
            [89.05536, 53.235665],
            [89.045678, 53.219715],
            [89.015519, 53.206254],
            [89.005738, 53.19619],
            [89.029165, 53.165939],
            [89.065468, 53.165612],
            [89.076221, 53.158765],
            [89.062157, 53.131653],
            [89.018389, 53.117654],
            [89.012572, 53.103182],
            [89.025342, 53.093153],
            [89.008181, 53.076792],
            [88.935776, 53.045581],
            [88.920496, 53.045948],
            [88.914554, 53.034328],
            [88.941928, 53.03097],
            [88.912551, 52.989647],
            [88.934819, 52.982923],
            [88.941186, 52.964502],
            [88.958572, 52.95036],
            [88.972378, 52.965951],
            [89.01922, 52.960763],
            [89.05162, 52.964847],
            [89.075433, 52.958189],
            [89.084353, 52.938883],
            [89.116679, 52.903341],
            [89.102454, 52.881767],
            [89.125252, 52.863459],
            [89.176158, 52.883115],
            [89.2194, 52.857751],
            [89.239749, 52.800688],
            [89.19543, 52.787778],
            [89.178573, 52.752761],
            [89.159296, 52.731643],
            [89.137817, 52.735184],
            [89.131145, 52.724956],
            [89.137409, 52.706822],
            [89.112102, 52.703886],
            [89.083779, 52.709502],
            [89.053054, 52.705816],
            [89.058029, 52.686697],
            [89.084592, 52.663425],
            [89.076756, 52.636382],
            [89.129269, 52.610529],
            [89.110396, 52.58785],
            [89.083305, 52.599332],
            [89.062215, 52.59536],
            [89.055466, 52.580549],
            [89.059463, 52.563767],
            [89.013374, 52.552605],
            [89.007008, 52.532849],
            [88.98807, 52.526828],
            [88.994094, 52.509122],
            [88.986759, 52.495625],
            [88.939137, 52.487435],
            [88.928, 52.471142],
            [88.889346, 52.46457],
            [88.881763, 52.44887],
            [88.857319, 52.432426],
            [88.805355, 52.420444],
            [88.801628, 52.412531],
            [88.773459, 52.406177],
            [88.762539, 52.38594],
            [88.721042, 52.36865],
            [88.699479, 52.363788],
            [88.694844, 52.349283],
            [88.676496, 52.33306],
            [88.708339, 52.325467],
            [88.716037, 52.298651],
            [88.700993, 52.270618],
            [88.707507, 52.255952],
            [88.684737, 52.240756],
            [88.666294, 52.252735],
            [88.634362, 52.24878],
            [88.629626, 52.241173],
            [88.584203, 52.21711],
            [88.563867, 52.211553],
            [88.541756, 52.216066],
            [88.523069, 52.193471],
            [88.477939, 52.180842],
            [88.475288, 52.170883],
            [88.441009, 52.163754],
            [88.410632, 52.163467],
            [88.375832, 52.177457],
            [88.384195, 52.200905],
            [88.382747, 52.222863],
            [88.396381, 52.238387],
            [88.380696, 52.273858],
            [88.402493, 52.280695],
            [88.391474, 52.293725],
            [88.403297, 52.311662],
            [88.428875, 52.316488],
            [88.441906, 52.335631],
            [88.463944, 52.341503],
            [88.422521, 52.358715],
            [88.42799, 52.369654],
            [88.407721, 52.377537],
            [88.442629, 52.40601],
            [88.448339, 52.425797],
            [88.410897, 52.445342],
            [88.369192, 52.441723],
            [88.321213, 52.464002],
            [88.305247, 52.432875],
            [88.28184, 52.427164],
            [88.281921, 52.41542],
            [88.267362, 52.414938],
            [88.25208, 52.399897],
            [88.228794, 52.400701],
            [88.20945, 52.409468],
            [88.186767, 52.410272],
            [88.158897, 52.430381],
            [88.143614, 52.428852],
            [88.127929, 52.446869],
            [88.123465, 52.462634],
            [88.057106, 52.488936],
            [88.047615, 52.498668],
            [88.000199, 52.519339],
            [87.9708, 52.518696],
            [87.943332, 52.533656],
            [87.891813, 52.538161],
            [87.892618, 52.525854],
            [87.864908, 52.518856],
            [87.84665, 52.492152],
            [87.814155, 52.497943],
            [87.801124, 52.479685],
            [87.75407, 52.484511],
            [87.736938, 52.475502],
            [87.732916, 52.462713],
            [87.704925, 52.465367],
            [87.673154, 52.442042],
            [87.668851, 52.426437],
            [87.653327, 52.422416],
            [87.617976, 52.438341],
            [87.610656, 52.456439],
            [87.623351, 52.464791],
            [87.611621, 52.47148],
            [87.58733, 52.462391],
            [87.552582, 52.472204],
            [87.542327, 52.482901],
            [87.492699, 52.474858],
            [87.449506, 52.484107],
            [87.428271, 52.49947],
            [87.371324, 52.499792],
            [87.378281, 52.540893],
            [87.366296, 52.549017],
            [87.374339, 52.572503],
            [87.349606, 52.570251],
            [87.313732, 52.58111],
            [87.297967, 52.592209],
            [87.273836, 52.587785],
            [87.237922, 52.591405],
            [87.218457, 52.58875],
            [87.199877, 52.572985],
            [87.17257, 52.579661],
            [87.174017, 52.588911],
            [87.11888, 52.598563],
            [87.134162, 52.606606],
            [87.1327, 52.617593],
            [87.12908, 52.621811],
            [87.143259, 52.65623],
            [87.16865, 52.696089],
            [87.163682, 52.71021],
            [87.114041, 52.717532],
            [87.103668, 52.712476],
            [87.094298, 52.690075],
            [87.066492, 52.695915],
            [87.053243, 52.704893],
            [87.069717, 52.723808],
            [87.022865, 52.729996],
            [87.008003, 52.753618],
            [86.974836, 52.756058],
            [86.971349, 52.774973],
            [86.946332, 52.789268],
            [86.95592, 52.80365],
            [86.945286, 52.816376],
            [86.948337, 52.830933],
            [86.920182, 52.829015],
            [86.912424, 52.846971],
            [86.936917, 52.878089],
            [86.956878, 52.873034],
            [87.007914, 52.899184],
            [87.017415, 52.925943],
            [87.019462, 52.97432],
            [87.03184, 52.986523],
            [87.031927, 53.000644],
            [87.063437, 53.025748],
            [87.054982, 53.041002],
            [87.001462, 53.062706],
            [86.943671, 53.062793],
            [86.899216, 53.084584],
            [86.874548, 53.083015],
            [86.857245, 53.093823],
            [86.840509, 53.116922],
            [86.839288, 53.150655],
            [86.786901, 53.137057],
            [86.781846, 53.141415],
            [86.711502, 53.158325],
            [86.712461, 53.193016],
            [86.64421, 53.180464],
            [86.637367, 53.228318],
            [86.630132, 53.248627],
            [86.655758, 53.260656],
            [86.676722, 53.254729],
            [86.691453, 53.237471],
            [86.741094, 53.253073],
            [86.753123, 53.247408],
            [86.767418, 53.258042],
            [86.75321, 53.263707],
            [86.771864, 53.278613],
            [86.809388, 53.295087],
            [86.822288, 53.322806],
            [86.8487, 53.319581],
            [86.843818, 53.346689],
            [86.884917, 53.365604],
            [86.858418, 53.377633],
            [86.904921, 53.412325],
            [86.873192, 53.421739],
            [86.863386, 53.439172],
            [86.86818, 53.451288],
            [86.889492, 53.464711],
            [86.914334, 53.464973],
            [86.929022, 53.473515],
            [86.930329, 53.503674],
            [86.833749, 53.477611],
            [86.813396, 53.461834],
            [86.812873, 53.451549],
            [86.785982, 53.461224],
            [86.787289, 53.467849],
            [86.755256, 53.477001],
            [86.719736, 53.481098],
            [86.700254, 53.49487],
            [86.645514, 53.49792],
            [86.594608, 53.509339],
            [86.538865, 53.527643],
            [86.497461, 53.571923],
            [86.471747, 53.575932],
            [86.426551, 53.608009],
            [86.393733, 53.597287],
            [86.352851, 53.668937],
            [86.350672, 53.683668],
            [86.295755, 53.829757],
            [86.184139, 53.811626],
            [86.177819, 53.833068],
            [86.124254, 53.982905],
            [86.064371, 53.993016],
            [86.048114, 54.010187],
            [85.999257, 54.014197],
            [85.915185, 54.026574],
            [85.910827, 54.042612],
            [85.871253, 54.037556],
            [85.749045, 54.164294],
            [85.690469, 54.144769],
            [85.624222, 54.214501],
            [85.563206, 54.198811],
            [85.53601, 54.226006],
            [85.425309, 54.187304],
            [85.159972, 54.452634],
            [85.116875, 54.437789],
            [84.931938, 54.60875],
            [85.010792, 54.637058],
            [84.986066, 54.667199],
            [85.004037, 54.680076],
            [84.978903, 54.693395],
            [84.980076, 54.704878],
            [84.945965, 54.709029],
            [84.894531, 54.72854],
            [84.902018, 54.745192],
            [84.931075, 54.76218],
            [84.963742, 54.770775],
            [84.964464, 54.780882],
            [85.018698, 54.7791],
            [85.002817, 54.799558],
            [85.088092, 54.816346],
            [85.075368, 54.830266],
            [85.050191, 54.843644],
            [85.078977, 54.855668],
            [85.03873, 54.873738],
            [85.002633, 54.907104],
            [84.97177, 54.944508],
            [84.90761, 54.945771],
            [84.910948, 54.953441],
            [84.887537, 54.952149],
            [84.887305, 54.969639],
            [84.857075, 54.989243],
            [84.841651, 54.992675],
            [84.836829, 55.00887],
            [84.8781, 55.02024],
            [84.905081, 55.055163],
            [84.91609, 55.085529],
            [84.927188, 55.163608],
            [84.917623, 55.163789],
            [84.915457, 55.183957],
            [84.900351, 55.185079],
            [84.818268, 55.227091],
            [84.828465, 55.241168],
            [84.86059, 55.254298],
            [84.864795, 55.262629],
            [84.914373, 55.27573],
            [84.909319, 55.294612],
            [84.916447, 55.321729],
            [84.899302, 55.343702],
            [84.856888, 55.373887],
            [84.818897, 55.361344],
            [84.755007, 55.37788],
            [84.691568, 55.372194],
            [84.652224, 55.380406],
            [84.621925, 55.403696],
            [84.723873, 55.443506],
            [84.755728, 55.468435],
            [84.749862, 55.483076],
            [84.723331, 55.507057],
            [84.731091, 55.523932],
            [84.784063, 55.525339],
            [84.766645, 55.577286],
            [84.750582, 55.598965],
            [84.753108, 55.616156],
            [84.740925, 55.641355],
            [84.692196, 55.641829],
            [84.68272, 55.651484],
            [84.706544, 55.651123],
            [84.709612, 55.668201],
            [84.683261, 55.681579],
            [84.630152, 55.687777],
            [84.620364, 55.696829],
            [84.603038, 55.686722],
            [84.592118, 55.705018],
            [84.564776, 55.700506],
            [84.55909, 55.72099],
            [84.539147, 55.718644],
            [84.526062, 55.760447],
            [84.578581, 55.761936],
            [84.60818, 55.766539],
            [84.60457, 55.798618],
            [84.549343, 55.802138],
            [84.557464, 55.823547],
            [84.573346, 55.826457],
            [84.555749, 55.844347],
            [84.522902, 55.855672],
            [84.524706, 55.877622],
            [84.505214, 55.883849],
            [84.468757, 55.872659],
            [84.455401, 55.894249],
            [84.49231, 55.901287],
            [84.497994, 55.917215],
            [84.539776, 55.921118],
            [84.543114, 55.932037],
            [84.488248, 55.948302],
            [84.489601, 55.958431],
            [84.537429, 55.952566],
            [84.542031, 55.964568],
            [84.529289, 55.975096],
            [84.547174, 55.982751],
            [84.537699, 55.990263],
            [84.569463, 56.004814],
            [84.548617, 56.026855],
            [84.51577, 56.028118],
            [84.483103, 56.016658],
            [84.45382, 56.043149],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5501",
      properties: {
        name: "Кировская область",
        density: 0,
        path: "/world/Russia/Кировская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [47.072164, 60.844481],
            [47.108785, 60.939032],
            [47.102304, 60.961749],
            [47.122825, 60.960441],
            [47.146822, 60.982131],
            [47.193951, 60.996978],
            [47.19381, 61.022445],
            [47.182237, 61.028336],
            [47.19318, 61.067393],
            [47.463344, 61.042686],
            [47.482583, 61.035472],
            [47.486065, 61.046667],
            [47.603077, 61.060586],
            [47.645452, 61.0507],
            [47.655019, 61.033604],
            [47.699332, 61.034983],
            [47.742733, 61.025553],
            [47.794373, 61.025194],
            [47.836208, 61.007971],
            [47.897198, 61.016821],
            [47.913783, 61.000154],
            [47.94111, 61.002131],
            [47.955992, 60.988545],
            [47.959312, 60.944431],
            [47.965449, 60.932324],
            [47.961305, 60.895506],
            [47.96653, 60.879171],
            [48.02043, 60.87544],
            [48.388444, 60.839043],
            [48.419677, 60.837206],
            [48.501792, 60.680726],
            [48.502165, 60.673113],
            [48.560666, 60.551513],
            [48.496802, 60.517251],
            [48.520811, 60.503947],
            [48.496136, 60.472218],
            [48.555107, 60.458759],
            [48.566645, 60.440491],
            [48.632908, 60.423506],
            [48.605496, 60.415556],
            [48.562553, 60.420854],
            [48.530512, 60.400429],
            [48.676327, 60.390446],
            [48.680117, 60.400813],
            [48.738352, 60.371108],
            [48.785465, 60.354924],
            [48.769603, 60.316144],
            [48.677848, 60.278498],
            [48.675702, 60.272397],
            [48.492226, 60.195156],
            [48.472056, 60.13227],
            [48.481976, 60.091638],
            [48.483582, 60.02722],
            [48.499928, 60.021452],
            [48.509332, 59.899346],
            [48.506024, 59.899666],
            [48.51212, 59.778201],
            [48.523224, 59.706844],
            [48.690021, 59.693557],
            [48.801186, 59.686542],
            [48.807418, 59.702446],
            [48.84728, 59.679033],
            [48.876736, 59.680895],
            [49.101547, 59.661699],
            [49.039664, 59.482398],
            [49.096541, 59.481431],
            [49.117862, 59.499706],
            [49.151193, 59.507078],
            [49.196698, 59.488283],
            [49.22026, 59.489612],
            [49.216094, 59.47503],
            [49.347335, 59.465417],
            [49.359354, 59.458527],
            [49.344454, 59.409331],
            [49.499892, 59.395232],
            [49.568798, 59.392349],
            [49.524257, 59.257743],
            [49.503105, 59.245564],
            [49.530668, 59.233706],
            [49.519772, 59.216079],
            [49.725497, 59.198212],
            [49.766229, 59.221211],
            [49.815425, 59.210155],
            [49.866896, 59.360181],
            [49.829041, 59.363707],
            [49.870036, 59.477682],
            [49.798092, 59.484999],
            [49.859414, 59.655265],
            [49.974509, 59.65755],
            [49.997118, 59.67134],
            [50.017308, 59.674224],
            [50.066664, 59.670379],
            [50.084931, 59.680315],
            [50.024357, 59.709158],
            [49.982241, 59.723801],
            [49.972115, 59.7505],
            [49.988744, 59.778361],
            [50.099246, 59.767881],
            [50.268901, 59.7488],
            [50.288832, 59.805536],
            [50.402529, 59.838961],
            [50.474639, 59.836078],
            [50.735837, 59.870694],
            [50.781668, 59.860439],
            [50.831023, 59.863324],
            [50.872707, 59.886182],
            [50.873173, 59.911696],
            [50.911463, 59.922936],
            [51.047665, 60.031904],
            [51.112404, 60.040558],
            [51.123986, 60.035088],
            [51.179065, 60.048892],
            [51.309187, 60.012358],
            [51.390272, 59.994091],
            [51.418478, 59.937686],
            [51.434824, 59.925507],
            [51.467194, 59.915573],
            [51.498657, 59.928639],
            [51.551321, 59.941533],
            [51.609167, 59.989607],
            [51.665251, 60.027746],
            [51.766358, 60.085865],
            [51.859139, 60.187993],
            [51.973869, 60.279334],
            [52.065457, 60.308487],
            [52.317282, 60.227188],
            [52.44445, 60.184868],
            [52.459294, 60.184619],
            [52.920523, 60.153778],
            [53.075593, 60.150712],
            [53.37263, 60.155608],
            [53.421552, 60.224519],
            [53.436561, 60.223524],
            [53.475288, 60.204535],
            [53.487396, 60.18737],
            [53.563772, 60.158347],
            [53.577372, 60.144582],
            [53.625636, 60.114233],
            [53.641061, 60.090185],
            [53.685846, 60.001456],
            [53.6303, 59.742317],
            [53.597778, 59.687428],
            [53.449701, 59.702848],
            [53.441566, 59.679458],
            [53.384659, 59.540487],
            [53.357126, 59.461552],
            [53.290739, 59.41866],
            [53.222744, 59.358456],
            [53.231039, 59.322301],
            [53.307085, 59.235149],
            [53.326657, 59.206955],
            [53.384022, 59.152672],
            [53.415141, 59.147002],
            [53.491391, 59.14837],
            [53.72213, 59.117775],
            [53.682241, 59.151151],
            [53.725112, 59.181336],
            [53.77943, 59.138797],
            [53.819608, 59.111349],
            [53.870817, 59.120355],
            [53.902916, 59.086836],
            [53.87582, 59.067936],
            [53.87563, 59.044473],
            [53.841905, 59.024331],
            [53.790244, 59.017119],
            [53.770654, 59.00398],
            [53.753649, 58.964697],
            [53.773512, 58.918177],
            [53.799013, 58.89102],
            [53.796361, 58.861913],
            [53.826422, 58.828951],
            [53.926141, 58.794746],
            [53.914947, 58.785376],
            [53.931242, 58.773891],
            [53.905413, 58.754864],
            [53.843634, 58.739605],
            [53.831196, 58.728741],
            [53.780279, 58.722024],
            [53.77008, 58.704195],
            [53.739067, 58.691258],
            [53.736911, 58.678736],
            [53.76717, 58.656445],
            [53.749021, 58.634454],
            [53.702641, 58.602127],
            [53.737746, 58.566704],
            [53.756904, 58.517944],
            [53.783764, 58.483933],
            [53.785599, 58.455538],
            [53.636346, 58.472183],
            [53.635767, 58.456179],
            [53.620923, 58.474256],
            [53.592068, 58.418863],
            [53.596213, 58.443574],
            [53.534021, 58.441583],
            [53.529045, 58.454187],
            [53.504666, 58.454353],
            [53.488413, 58.440007],
            [53.423485, 58.429475],
            [53.434929, 58.412891],
            [53.415194, 58.399954],
            [53.383518, 58.395144],
            [53.373651, 58.384282],
            [53.213692, 58.401361],
            [53.24802, 58.443735],
            [53.160667, 58.450482],
            [53.179509, 58.500687],
            [53.146429, 58.507052],
            [53.160052, 58.542172],
            [53.098981, 58.545842],
            [53.090581, 58.522298],
            [53.03419, 58.528306],
            [52.910892, 58.536977],
            [52.892691, 58.533618],
            [52.850254, 58.396989],
            [52.843074, 58.392879],
            [52.650824, 58.408919],
            [52.662835, 58.3987],
            [52.627675, 58.411304],
            [52.327908, 58.436177],
            [52.319755, 58.431612],
            [52.296977, 58.462546],
            [52.270856, 58.471916],
            [52.218863, 58.47432],
            [52.173006, 58.489411],
            [52.08884, 58.465777],
            [52.046715, 58.472991],
            [51.964705, 58.453088],
            [51.913875, 58.425225],
            [51.875401, 58.387247],
            [51.852763, 58.373647],
            [51.817521, 58.373978],
            [51.786873, 58.36115],
            [51.803654, 58.355204],
            [51.794519, 58.339565],
            [51.825249, 58.328881],
            [51.809821, 58.321335],
            [51.820168, 58.30829],
            [51.820346, 58.285681],
            [51.77836, 58.277338],
            [51.75733, 58.261811],
            [51.72312, 58.249386],
            [51.744018, 58.222437],
            [51.719557, 58.194616],
            [51.693395, 58.180975],
            [51.685395, 58.148137],
            [51.709028, 58.137358],
            [51.747671, 58.133378],
            [51.767157, 58.151704],
            [51.790292, 58.146398],
            [51.802152, 58.119448],
            [51.830761, 58.10021],
            [51.841791, 58.061735],
            [51.806302, 58.040008],
            [51.824628, 58.017371],
            [51.864597, 58.024669],
            [51.872226, 58.01306],
            [51.897684, 58.012314],
            [51.924883, 58.000705],
            [51.897187, 57.993573],
            [51.891882, 57.948546],
            [51.88243, 57.935859],
            [51.857554, 57.922591],
            [51.824634, 57.913884],
            [51.851087, 57.886603],
            [51.834053, 57.870933],
            [51.847274, 57.857165],
            [51.820076, 57.843814],
            [51.840144, 57.835937],
            [51.866679, 57.842571],
            [51.872235, 57.828392],
            [51.927628, 57.828973],
            [51.932853, 57.803018],
            [51.923483, 57.798955],
            [51.954249, 57.766864],
            [51.919919, 57.753762],
            [51.926222, 57.741821],
            [51.901262, 57.742319],
            [51.864777, 57.729299],
            [51.884223, 57.72051],
            [51.866105, 57.712964],
            [51.883104, 57.69754],
            [51.86992, 57.690243],
            [51.860468, 57.668932],
            [51.868429, 57.65583],
            [51.847699, 57.635348],
            [51.8511, 57.619178],
            [51.815527, 57.608315],
            [51.799648, 57.588206],
            [51.824069, 57.586714],
            [51.82445, 57.598026],
            [51.846748, 57.596499],
            [51.832307, 57.571415],
            [51.767267, 57.575104],
            [51.75566, 57.538203],
            [51.705575, 57.517389],
            [51.674811, 57.515896],
            [51.651094, 57.532066],
            [51.626881, 57.531568],
            [51.62481, 57.478995],
            [51.608343, 57.476234],
            [51.590067, 57.460752],
            [51.530113, 57.458263],
            [51.400422, 57.468212],
            [51.230846, 57.449552],
            [51.216748, 57.459005],
            [51.190214, 57.444079],
            [51.165254, 57.440762],
            [51.154146, 57.375419],
            [51.134083, 57.276492],
            [51.124165, 57.243277],
            [51.179236, 57.241956],
            [51.170697, 57.202567],
            [51.208393, 57.200064],
            [51.19425, 57.140956],
            [51.240272, 57.139464],
            [51.231483, 57.128436],
            [51.243175, 57.121802],
            [51.225015, 57.111685],
            [51.219129, 57.086311],
            [51.245831, 57.069146],
            [51.216643, 57.044933],
            [51.283106, 57.04087],
            [51.27896, 57.021549],
            [51.31213, 57.019393],
            [51.306492, 56.996175],
            [51.348599, 56.965055],
            [51.343605, 56.942575],
            [51.382734, 56.937734],
            [51.422802, 56.942899],
            [51.430318, 56.93549],
            [51.420099, 56.913836],
            [51.392154, 56.908362],
            [51.427397, 56.895925],
            [51.461892, 56.898744],
            [51.476817, 56.914997],
            [51.495807, 56.887882],
            [51.536232, 56.885229],
            [51.513325, 56.879499],
            [51.513512, 56.863586],
            [51.532916, 56.855875],
            [51.493861, 56.841612],
            [51.481506, 56.822042],
            [51.483415, 56.799073],
            [51.438058, 56.773035],
            [51.391373, 56.766567],
            [51.391374, 56.748987],
            [51.40514, 56.719467],
            [51.393202, 56.666895],
            [51.27396, 56.673527],
            [51.180423, 56.684637],
            [51.147675, 56.559343],
            [51.164757, 56.545826],
            [51.159119, 56.534383],
            [51.137559, 56.529242],
            [51.127693, 56.511828],
            [51.146122, 56.510477],
            [51.137893, 56.477996],
            [51.156551, 56.475758],
            [51.19735, 56.433924],
            [51.289808, 56.427292],
            [51.299262, 56.421902],
            [51.270282, 56.392382],
            [51.265141, 56.379529],
            [51.296321, 56.367671],
            [51.278161, 56.349677],
            [51.303618, 56.351336],
            [51.286024, 56.323433],
            [51.3458, 56.320586],
            [51.344584, 56.309378],
            [51.381235, 56.303906],
            [51.377919, 56.287902],
            [51.403791, 56.286327],
            [51.391023, 56.232478],
            [51.357483, 56.198388],
            [51.378545, 56.199425],
            [51.392599, 56.216507],
            [51.406074, 56.213522],
            [51.44455, 56.225712],
            [51.468763, 56.215098],
            [51.485928, 56.219162],
            [51.498864, 56.205728],
            [51.49131, 56.19885],
            [51.446672, 56.195386],
            [51.427512, 56.163769],
            [51.440862, 56.16153],
            [51.433732, 56.146024],
            [51.444008, 56.127376],
            [51.40249, 56.11608],
            [51.346405, 56.086189],
            [51.345599, 56.068375],
            [51.320319, 56.060762],
            [51.308844, 56.078253],
            [51.28663, 56.083867],
            [51.254669, 56.079697],
            [51.218075, 56.084197],
            [51.207984, 56.123117],
            [51.145957, 56.124348],
            [51.129677, 56.136729],
            [51.119748, 56.130601],
            [51.091951, 56.134043],
            [51.091675, 56.116499],
            [51.058532, 56.112816],
            [51.066751, 56.131697],
            [51.032482, 56.133667],
            [50.984957, 56.153971],
            [50.984416, 56.183285],
            [51.002717, 56.203645],
            [50.9341, 56.192794],
            [50.875488, 56.196212],
            [50.868764, 56.223568],
            [50.846866, 56.227419],
            [50.832155, 56.240606],
            [50.871451, 56.254609],
            [50.895228, 56.275833],
            [50.917326, 56.278355],
            [50.913143, 56.30763],
            [50.888398, 56.307768],
            [50.85389, 56.355091],
            [50.883545, 56.371771],
            [50.82413, 56.397554],
            [50.756164, 56.410003],
            [50.741588, 56.409196],
            [50.827078, 56.347222],
            [50.801149, 56.340792],
            [50.742046, 56.312816],
            [50.747378, 56.318536],
            [50.63102, 56.338337],
            [50.615689, 56.354768],
            [50.579791, 56.349373],
            [50.577631, 56.358683],
            [50.617509, 56.377046],
            [50.639824, 56.404603],
            [50.625159, 56.409722],
            [50.594796, 56.392785],
            [50.571828, 56.393444],
            [50.547752, 56.408043],
            [50.499089, 56.476043],
            [50.489004, 56.480112],
            [50.501001, 56.496648],
            [50.534738, 56.506487],
            [50.464257, 56.544342],
            [50.438251, 56.550561],
            [50.453373, 56.572091],
            [50.473809, 56.583356],
            [50.491241, 56.60972],
            [50.452537, 56.611329],
            [50.448577, 56.588409],
            [50.410307, 56.575783],
            [50.416298, 56.602811],
            [50.430506, 56.608573],
            [50.384279, 56.635619],
            [50.370963, 56.656227],
            [50.345692, 56.674661],
            [50.30761, 56.675063],
            [50.30213, 56.666005],
            [50.318158, 56.658599],
            [50.314647, 56.641663],
            [50.299205, 56.632284],
            [50.257675, 56.63273],
            [50.241929, 56.628118],
            [50.223631, 56.652051],
            [50.172819, 56.650296],
            [50.174968, 56.634704],
            [50.158947, 56.631894],
            [50.149412, 56.604106],
            [50.081423, 56.611177],
            [50.084263, 56.626502],
            [50.053872, 56.648226],
            [50.026035, 56.657554],
            [50.060962, 56.659213],
            [50.074723, 56.721071],
            [50.096051, 56.730823],
            [50.104437, 56.792259],
            [50.042915, 56.795976],
            [50.047314, 56.826917],
            [50.057295, 56.834382],
            [50.041011, 56.852249],
            [50.019037, 56.854488],
            [50.019966, 56.869405],
            [49.990401, 56.86009],
            [49.94867, 56.857923],
            [49.935393, 56.869882],
            [49.900419, 56.880523],
            [49.82666, 56.889054],
            [49.826288, 56.879953],
            [49.767832, 56.873558],
            [49.730864, 56.891185],
            [49.754704, 56.902717],
            [49.746406, 56.91428],
            [49.711172, 56.917964],
            [49.705227, 56.932225],
            [49.741081, 56.940975],
            [49.760903, 56.952163],
            [49.747583, 56.962115],
            [49.739595, 56.984357],
            [49.717178, 56.986044],
            [49.70275, 57.019868],
            [49.68956, 57.024149],
            [49.673584, 57.047195],
            [49.695383, 57.057719],
            [49.682859, 57.06294],
            [49.665493, 57.048346],
            [49.645419, 57.053094],
            [49.649981, 57.066049],
            [49.602114, 57.077971],
            [49.584932, 57.057204],
            [49.572903, 57.063278],
            [49.518441, 57.049549],
            [49.486103, 57.050958],
            [49.468026, 57.057757],
            [49.433117, 57.040095],
            [49.402935, 57.044821],
            [49.401111, 57.034373],
            [49.431542, 57.031969],
            [49.416784, 57.009083],
            [49.391742, 57.005848],
            [49.389503, 57.019696],
            [49.358905, 57.025417],
            [49.345389, 57.021188],
            [49.328473, 57.039513],
            [49.277082, 57.033935],
            [49.247377, 57.041502],
            [49.253637, 57.04884],
            [49.230958, 57.068989],
            [49.20749, 57.109827],
            [49.221752, 57.118077],
            [49.199488, 57.128956],
            [49.171873, 57.133336],
            [49.186561, 57.138995],
            [49.207197, 57.160407],
            [49.173664, 57.155206],
            [49.164865, 57.141863],
            [49.138697, 57.17991],
            [49.179681, 57.192907],
            [49.187127, 57.221809],
            [49.178235, 57.248667],
            [49.191201, 57.257624],
            [49.195836, 57.284214],
            [49.214115, 57.288103],
            [49.216754, 57.306004],
            [49.180447, 57.318367],
            [49.169038, 57.342734],
            [49.151218, 57.33006],
            [49.127286, 57.328052],
            [49.081704, 57.304333],
            [49.084256, 57.29501],
            [49.044898, 57.300841],
            [49.007399, 57.289029],
            [49.007425, 57.219817],
            [49.037314, 57.209689],
            [49.044261, 57.190278],
            [48.989271, 57.186122],
            [48.996867, 57.156171],
            [48.98329, 57.145409],
            [49.002717, 57.124579],
            [48.983029, 57.115086],
            [48.969276, 57.096652],
            [48.947945, 57.082648],
            [48.909666, 57.08188],
            [48.840072, 57.092782],
            [48.835677, 57.099913],
            [48.864906, 57.113968],
            [48.829539, 57.140419],
            [48.814488, 57.139714],
            [48.824609, 57.165219],
            [48.769917, 57.17197],
            [48.707934, 57.167491],
            [48.673521, 57.169025],
            [48.66295, 57.151736],
            [48.63231, 57.150491],
            [48.595908, 57.157332],
            [48.581064, 57.172133],
            [48.555442, 57.171718],
            [48.553369, 57.159985],
            [48.416842, 57.110189],
            [48.41984, 57.125526],
            [48.402382, 57.130933],
            [48.378458, 57.126566],
            [48.361658, 57.133496],
            [48.377817, 57.152907],
            [48.363622, 57.166524],
            [48.328936, 57.16609],
            [48.310245, 57.151119],
            [48.279404, 57.148135],
            [48.269972, 57.133671],
            [48.276759, 57.123576],
            [48.229698, 57.129736],
            [48.224134, 57.116737],
            [48.247104, 57.096256],
            [48.202493, 57.070882],
            [48.13753, 57.069662],
            [48.164516, 57.061926],
            [48.1612, 57.052887],
            [48.207719, 57.042523],
            [48.206062, 57.0135],
            [48.159211, 57.018143],
            [48.140472, 57.007198],
            [48.142131, 56.995837],
            [48.091715, 56.987379],
            [48.085081, 57.002056],
            [48.052327, 57.005289],
            [48.049757, 56.993514],
            [47.988478, 57.001972],
            [47.986323, 56.968637],
            [47.954068, 56.957774],
            [47.945323, 56.96459],
            [47.949257, 56.986713],
            [47.91768, 56.989462],
            [47.908305, 56.964629],
            [47.89473, 56.984772],
            [47.864771, 56.990444],
            [47.83466, 56.989946],
            [47.832835, 57.000228],
            [47.8008, 57.029503],
            [47.764379, 57.03932],
            [47.744068, 57.025949],
            [47.768903, 57.001637],
            [47.782673, 56.998604],
            [47.685069, 57.001636],
            [47.706132, 56.993012],
            [47.686149, 56.975764],
            [47.662765, 56.98331],
            [47.616455, 56.957935],
            [47.616912, 56.944253],
            [47.601406, 56.937661],
            [47.602351, 56.924553],
            [47.547064, 56.924886],
            [47.546196, 56.900591],
            [47.513353, 56.894257],
            [47.513099, 56.883134],
            [47.486695, 56.873412],
            [47.510934, 56.850756],
            [47.44572, 56.873265],
            [47.434973, 56.88289],
            [47.383633, 56.866688],
            [47.389572, 56.859538],
            [47.359704, 56.847732],
            [47.33632, 56.848988],
            [47.302553, 56.82891],
            [47.265361, 56.815391],
            [47.261413, 56.80731],
            [47.198167, 56.823764],
            [47.17835, 56.801209],
            [47.155713, 56.796026],
            [47.134692, 56.806101],
            [47.128306, 56.821234],
            [47.107907, 56.823804],
            [47.129466, 56.838315],
            [47.127059, 56.882678],
            [47.162052, 56.879444],
            [47.15633, 56.893209],
            [47.095051, 56.926632],
            [47.06412, 56.930937],
            [47.060887, 56.923225],
            [47.034352, 56.918913],
            [46.992891, 56.925878],
            [46.99173, 56.941798],
            [46.935675, 56.939393],
            [46.90939, 56.919491],
            [46.89438, 56.95183],
            [46.867349, 56.934334],
            [46.819338, 56.921149],
            [46.790564, 56.936157],
            [46.703663, 56.940716],
            [46.728373, 56.948014],
            [46.721544, 56.963392],
            [46.764653, 56.967786],
            [46.790524, 56.996727],
            [46.748523, 57.000955],
            [46.75445, 57.03284],
            [46.788681, 57.03652],
            [46.797405, 57.042915],
            [46.813988, 57.077163],
            [46.813075, 57.090514],
            [46.781813, 57.086243],
            [46.767052, 57.096526],
            [46.769746, 57.117257],
            [46.802778, 57.123439],
            [46.786578, 57.134795],
            [46.773933, 57.128327],
            [46.749178, 57.161911],
            [46.776211, 57.167923],
            [46.779901, 57.182228],
            [46.838155, 57.177129],
            [46.847541, 57.211459],
            [46.856562, 57.221494],
            [46.825837, 57.230327],
            [46.807097, 57.223859],
            [46.796316, 57.249524],
            [46.809749, 57.260097],
            [46.79818, 57.290529],
            [46.755225, 57.292104],
            [46.748592, 57.279292],
            [46.717703, 57.285511],
            [46.715255, 57.313207],
            [46.68254, 57.343308],
            [46.70298, 57.347496],
            [46.720891, 57.361179],
            [46.761026, 57.366569],
            [46.771638, 57.409316],
            [46.725948, 57.404257],
            [46.709008, 57.381615],
            [46.684735, 57.395011],
            [46.687803, 57.406496],
            [46.710647, 57.422127],
            [46.730881, 57.416737],
            [46.741702, 57.430295],
            [46.769159, 57.422097],
            [46.776903, 57.435603],
            [46.821639, 57.446632],
            [46.822551, 57.459361],
            [46.80439, 57.471634],
            [46.773542, 57.470265],
            [46.771012, 57.498832],
            [46.752933, 57.521511],
            [46.800987, 57.522134],
            [46.840045, 57.506171],
            [46.862269, 57.505301],
            [46.902445, 57.515709],
            [46.941295, 57.495144],
            [46.982674, 57.496015],
            [47.021939, 57.487807],
            [47.086286, 57.505387],
            [47.13405, 57.509617],
            [47.178579, 57.530431],
            [47.203332, 57.531095],
            [47.236707, 57.554687],
            [47.293717, 57.561322],
            [47.314851, 57.547986],
            [47.338247, 57.560286],
            [47.350354, 57.550709],
            [47.368887, 57.556016],
            [47.377968, 57.546853],
            [47.375067, 57.520732],
            [47.481365, 57.515179],
            [47.4941, 57.578697],
            [47.529177, 57.573888],
            [47.536597, 57.605565],
            [47.52221, 57.609711],
            [47.516736, 57.627954],
            [47.556497, 57.652749],
            [47.535192, 57.689273],
            [47.563998, 57.714817],
            [47.605583, 57.741104],
            [47.593931, 57.762664],
            [47.599652, 57.784929],
            [47.563994, 57.790857],
            [47.579293, 57.809888],
            [47.603838, 57.817642],
            [47.628423, 57.845463],
            [47.617145, 57.859228],
            [47.657445, 57.864702],
            [47.726188, 57.879463],
            [47.770129, 57.879952],
            [47.771781, 57.888717],
            [47.738426, 57.909956],
            [47.73261, 57.933815],
            [47.633861, 57.952999],
            [47.598669, 57.953477],
            [47.563651, 57.967771],
            [47.560058, 57.961571],
            [47.484683, 57.971676],
            [47.456502, 57.980847],
            [47.438997, 57.976656],
            [47.410783, 57.980791],
            [47.417182, 58.006856],
            [47.296928, 58.0157],
            [47.120798, 58.020341],
            [46.847192, 58.025271],
            [46.585195, 58.027796],
            [46.56123, 58.03671],
            [46.492278, 58.040772],
            [46.4849, 58.016102],
            [46.436307, 58.004617],
            [46.416737, 58.014236],
            [46.402887, 58.031152],
            [46.346664, 58.055199],
            [46.34493, 58.062218],
            [46.346911, 58.07734],
            [46.329497, 58.077339],
            [46.322943, 58.116978],
            [46.339362, 58.136797],
            [46.335236, 58.165583],
            [46.365563, 58.192026],
            [46.407192, 58.201148],
            [46.416211, 58.214995],
            [46.412496, 58.259611],
            [46.39193, 58.28613],
            [46.396088, 58.329071],
            [46.407641, 58.323979],
            [46.431678, 58.332613],
            [46.439635, 58.356283],
            [46.422498, 58.346789],
            [46.394087, 58.347008],
            [46.394919, 58.375077],
            [46.426669, 58.393577],
            [46.488283, 58.397725],
            [46.509761, 58.392874],
            [46.528958, 58.402245],
            [46.579335, 58.399094],
            [46.582152, 58.456686],
            [46.592434, 58.456354],
            [46.560507, 58.480485],
            [46.616189, 58.534844],
            [46.608807, 58.554331],
            [46.644132, 58.572824],
            [46.658063, 58.588497],
            [46.671663, 58.587253],
            [46.661132, 58.570336],
            [46.768106, 58.584601],
            [46.827398, 58.588085],
            [46.878479, 58.610641],
            [46.88785, 58.607242],
            [47.017877, 58.618604],
            [47.000707, 58.690501],
            [47.007589, 58.718198],
            [47.062652, 58.71455],
            [47.077078, 58.756594],
            [47.113068, 58.765135],
            [47.108838, 58.779399],
            [47.145574, 58.780726],
            [47.164606, 58.769241],
            [47.229661, 58.772144],
            [47.243012, 58.783008],
            [47.293182, 58.781391],
            [47.306781, 58.788067],
            [47.324111, 58.814562],
            [47.307982, 58.820657],
            [47.266768, 58.818998],
            [47.260091, 58.844331],
            [47.27763, 58.851132],
            [47.273565, 58.863819],
            [47.249268, 58.869416],
            [47.261125, 58.888821],
            [47.275554, 58.895621],
            [47.286914, 58.919338],
            [47.319171, 58.928253],
            [47.347864, 58.926719],
            [47.389079, 58.909347],
            [47.414371, 58.916976],
            [47.439788, 58.900267],
            [47.474617, 58.905741],
            [47.492903, 58.895293],
            [47.573837, 58.915113],
            [47.598467, 58.905909],
            [47.639515, 58.916855],
            [47.586938, 58.949113],
            [47.591606, 58.973854],
            [47.526777, 59.031659],
            [47.552276, 59.056821],
            [47.5187, 59.060829],
            [47.510632, 59.072741],
            [47.485652, 59.075514],
            [47.444002, 59.093717],
            [47.434148, 59.117004],
            [47.471483, 59.12731],
            [47.466109, 59.136687],
            [47.478533, 59.15915],
            [47.45415, 59.149967],
            [47.450868, 59.138149],
            [47.368364, 59.133551],
            [47.350092, 59.14516],
            [47.318348, 59.145124],
            [47.318631, 59.164915],
            [47.304273, 59.158959],
            [47.209394, 59.201214],
            [47.202301, 59.212321],
            [47.253922, 59.246384],
            [47.221029, 59.258869],
            [47.247709, 59.352628],
            [47.048947, 59.369321],
            [47.118039, 59.5941],
            [47.117822, 59.598969],
            [47.138288, 59.663505],
            [47.152877, 59.752233],
            [46.922349, 59.765497],
            [46.988095, 59.983172],
            [47.006916, 60.029526],
            [47.034772, 60.126381],
            [46.920587, 60.123228],
            [46.883355, 60.101087],
            [46.89082, 60.075795],
            [46.823569, 60.068912],
            [46.835014, 60.054234],
            [46.78555, 60.059126],
            [46.770376, 60.040178],
            [46.751676, 60.048014],
            [46.76088, 60.061987],
            [46.679697, 60.07177],
            [46.664522, 60.061902],
            [46.666843, 60.077658],
            [46.651502, 60.084665],
            [46.624512, 60.061943],
            [46.598308, 60.064513],
            [46.588522, 60.080352],
            [46.551455, 60.081512],
            [46.547889, 60.088726],
            [46.506592, 60.095028],
            [46.497885, 60.106305],
            [46.443238, 60.111694],
            [46.399289, 60.101328],
            [46.393733, 60.109206],
            [46.352685, 60.110449],
            [46.378059, 60.12861],
            [46.353181, 60.147018],
            [46.358653, 60.161281],
            [46.326561, 60.166173],
            [46.329711, 60.18906],
            [46.3453, 60.192709],
            [46.329626, 60.214766],
            [46.290486, 60.21717],
            [46.294466, 60.228282],
            [46.264862, 60.229691],
            [46.283104, 60.253822],
            [46.304208, 60.244411],
            [46.35048, 60.240307],
            [46.384976, 60.246195],
            [46.390614, 60.263111],
            [46.415822, 60.268916],
            [46.47586, 60.246362],
            [46.510605, 60.243046],
            [46.521551, 60.255153],
            [46.551983, 60.260709],
            [46.635985, 60.2627],
            [46.749426, 60.234011],
            [46.763025, 60.233679],
            [46.816341, 60.297448],
            [46.794365, 60.324729],
            [46.843537, 60.358231],
            [46.758457, 60.36478],
            [46.779351, 60.396043],
            [46.79121, 60.395297],
            [46.871727, 60.414204],
            [46.83582, 60.434644],
            [46.863183, 60.453227],
            [46.888754, 60.454133],
            [46.890007, 60.484233],
            [46.873836, 60.513505],
            [46.952612, 60.535232],
            [46.97929, 60.551955],
            [47.038023, 60.544604],
            [47.038685, 60.555632],
            [47.001618, 60.557705],
            [47.01787, 60.567158],
            [46.997222, 60.574372],
            [46.99722, 60.604888],
            [47.013141, 60.624541],
            [46.993382, 60.639225],
            [46.962752, 60.639727],
            [46.964361, 60.657176],
            [46.996993, 60.659389],
            [47.006089, 60.669941],
            [46.976484, 60.69233],
            [47.015613, 60.708681],
            [46.982951, 60.721814],
            [47.03308, 60.725683],
            [47.063475, 60.810076],
            [47.072164, 60.844481],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5502",
      properties: {
        name: "Костромская область",
        density: 0,
        path: "/world/Russia/Костромская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [40.930072, 58.576336],
            [40.932482, 58.588871],
            [40.958024, 58.578124],
            [40.997626, 58.584298],
            [40.989051, 58.59741],
            [41.052204, 58.606772],
            [41.065726, 58.632777],
            [41.10521, 58.654407],
            [41.153437, 58.651692],
            [41.171111, 58.664988],
            [41.168179, 58.688612],
            [41.194472, 58.701293],
            [41.189787, 58.712434],
            [41.22104, 58.713521],
            [41.230467, 58.741234],
            [41.188086, 58.78016],
            [41.168609, 58.782112],
            [41.156287, 58.803261],
            [41.222141, 58.819695],
            [41.199924, 58.848598],
            [41.243286, 58.852072],
            [41.259186, 58.838373],
            [41.255651, 58.875897],
            [41.263991, 58.898907],
            [41.226299, 58.884518],
            [41.182087, 58.895598],
            [41.177734, 58.914105],
            [41.187637, 58.921902],
            [41.172, 58.931752],
            [41.190382, 58.95622],
            [41.225368, 58.961497],
            [41.268229, 58.992002],
            [41.305487, 58.99124],
            [41.334083, 58.983058],
            [41.349027, 58.951944],
            [41.39821, 58.988965],
            [41.515481, 59.013879],
            [41.531057, 59.023458],
            [41.525212, 59.041272],
            [41.573567, 59.044893],
            [41.61607, 59.069265],
            [41.666098, 59.075326],
            [41.683712, 59.084404],
            [41.71442, 59.084944],
            [41.72852, 59.110905],
            [41.770671, 59.110749],
            [41.826127, 59.10498],
            [41.861284, 59.096686],
            [41.878227, 59.083329],
            [41.906841, 59.07355],
            [41.916598, 59.082914],
            [41.948905, 59.078643],
            [41.927085, 59.101708],
            [41.958691, 59.122382],
            [42.001337, 59.104732],
            [42.023517, 59.109085],
            [42.058869, 59.125959],
            [42.038264, 59.136328],
            [42.047829, 59.150266],
            [42.090339, 59.15592],
            [42.098663, 59.24982],
            [42.066766, 59.245886],
            [42.031676, 59.254315],
            [42.0292, 59.241964],
            [41.951354, 59.261145],
            [41.938463, 59.27723],
            [41.9622, 59.281707],
            [41.986462, 59.3929],
            [42.034862, 59.429549],
            [42.089084, 59.431424],
            [42.126811, 59.420086],
            [42.124956, 59.411216],
            [42.216254, 59.408483],
            [42.202767, 59.327548],
            [42.231835, 59.30069],
            [42.272523, 59.291805],
            [42.271467, 59.280815],
            [42.289094, 59.276239],
            [42.279223, 59.261537],
            [42.306128, 59.261643],
            [42.312121, 59.271917],
            [42.338712, 59.284418],
            [42.348388, 59.304629],
            [42.507236, 59.319136],
            [42.507684, 59.338405],
            [42.549887, 59.336868],
            [42.523102, 59.331103],
            [42.567289, 59.315537],
            [42.573804, 59.329918],
            [42.636381, 59.328185],
            [42.668402, 59.314497],
            [42.65278, 59.300512],
            [42.673167, 59.298031],
            [42.683234, 59.273741],
            [42.654928, 59.264309],
            [42.692399, 59.223503],
            [42.711966, 59.229724],
            [42.757316, 59.216919],
            [42.764739, 59.197224],
            [42.799691, 59.170034],
            [42.823221, 59.185086],
            [42.864552, 59.177565],
            [42.858727, 59.166718],
            [42.885856, 59.166217],
            [42.878378, 59.174397],
            [42.903206, 59.183689],
            [42.908979, 59.200636],
            [42.928633, 59.19473],
            [42.959777, 59.195358],
            [43.001329, 59.21278],
            [43.028181, 59.202897],
            [43.076591, 59.229135],
            [43.084482, 59.250143],
            [43.151491, 59.254514],
            [43.132496, 59.281689],
            [43.143818, 59.292548],
            [43.143517, 59.267955],
            [43.175206, 59.263005],
            [43.217779, 59.234271],
            [43.235867, 59.228369],
            [43.263919, 59.246937],
            [43.284226, 59.243021],
            [43.29415, 59.227636],
            [43.327444, 59.211571],
            [43.334569, 59.198145],
            [43.382571, 59.204406],
            [43.369449, 59.230832],
            [43.34376, 59.24626],
            [43.33743, 59.266158],
            [43.361899, 59.279903],
            [43.420694, 59.291302],
            [43.455835, 59.289315],
            [43.504048, 59.305888],
            [43.513902, 59.297745],
            [43.508454, 59.259547],
            [43.544296, 59.257313],
            [43.530879, 59.240261],
            [43.557065, 59.217689],
            [43.525388, 59.207576],
            [43.520612, 59.191797],
            [43.534962, 59.1785],
            [43.566936, 59.179544],
            [43.610897, 59.189801],
            [43.616033, 59.207928],
            [43.63605, 59.211152],
            [43.633666, 59.221868],
            [43.609263, 59.23455],
            [43.625249, 59.2391],
            [43.674542, 59.233587],
            [43.671753, 59.24439],
            [43.692617, 59.25915],
            [43.745345, 59.259308],
            [43.755109, 59.268807],
            [43.776377, 59.268368],
            [43.806349, 59.242564],
            [43.844462, 59.237603],
            [43.900833, 59.239426],
            [43.927293, 59.228381],
            [43.94817, 59.208092],
            [43.981004, 59.208149],
            [43.998309, 59.214834],
            [44.090678, 59.234088],
            [44.171108, 59.237329],
            [44.207774, 59.225699],
            [44.197831, 59.208372],
            [44.190221, 59.17012],
            [44.215437, 59.172803],
            [44.293631, 59.16983],
            [44.294622, 59.151471],
            [44.333456, 59.149681],
            [44.331382, 59.141296],
            [44.352629, 59.132541],
            [44.40025, 59.158691],
            [44.587632, 59.151573],
            [44.774014, 59.138523],
            [45.039644, 59.121812],
            [45.056262, 59.198298],
            [45.333896, 59.183884],
            [45.340468, 59.216042],
            [45.570329, 59.201016],
            [45.579906, 59.17945],
            [45.605968, 59.177136],
            [45.609981, 59.197834],
            [45.718589, 59.189087],
            [45.979028, 59.171648],
            [46.020292, 59.323947],
            [46.163072, 59.313612],
            [46.165765, 59.353715],
            [46.368871, 59.343196],
            [46.406308, 59.496395],
            [46.372459, 59.50016],
            [46.408911, 59.618645],
            [46.702943, 59.598144],
            [46.78983, 59.593086],
            [46.79716, 59.623604],
            [47.117822, 59.598969],
            [47.118039, 59.5941],
            [47.048947, 59.369321],
            [47.247709, 59.352628],
            [47.221029, 59.258869],
            [47.253922, 59.246384],
            [47.202301, 59.212321],
            [47.209394, 59.201214],
            [47.304273, 59.158959],
            [47.318631, 59.164915],
            [47.318348, 59.145124],
            [47.350092, 59.14516],
            [47.368364, 59.133551],
            [47.450868, 59.138149],
            [47.45415, 59.149967],
            [47.478533, 59.15915],
            [47.466109, 59.136687],
            [47.471483, 59.12731],
            [47.434148, 59.117004],
            [47.444002, 59.093717],
            [47.485652, 59.075514],
            [47.510632, 59.072741],
            [47.5187, 59.060829],
            [47.552276, 59.056821],
            [47.526777, 59.031659],
            [47.591606, 58.973854],
            [47.586938, 58.949113],
            [47.639515, 58.916855],
            [47.598467, 58.905909],
            [47.573837, 58.915113],
            [47.492903, 58.895293],
            [47.474617, 58.905741],
            [47.439788, 58.900267],
            [47.414371, 58.916976],
            [47.389079, 58.909347],
            [47.347864, 58.926719],
            [47.319171, 58.928253],
            [47.286914, 58.919338],
            [47.275554, 58.895621],
            [47.261125, 58.888821],
            [47.249268, 58.869416],
            [47.273565, 58.863819],
            [47.27763, 58.851132],
            [47.260091, 58.844331],
            [47.266768, 58.818998],
            [47.307982, 58.820657],
            [47.324111, 58.814562],
            [47.306781, 58.788067],
            [47.293182, 58.781391],
            [47.243012, 58.783008],
            [47.229661, 58.772144],
            [47.164606, 58.769241],
            [47.145574, 58.780726],
            [47.108838, 58.779399],
            [47.113068, 58.765135],
            [47.077078, 58.756594],
            [47.062652, 58.71455],
            [47.007589, 58.718198],
            [47.000707, 58.690501],
            [47.017877, 58.618604],
            [46.88785, 58.607242],
            [46.878479, 58.610641],
            [46.827398, 58.588085],
            [46.768106, 58.584601],
            [46.661132, 58.570336],
            [46.671663, 58.587253],
            [46.658063, 58.588497],
            [46.644132, 58.572824],
            [46.608807, 58.554331],
            [46.616189, 58.534844],
            [46.560507, 58.480485],
            [46.592434, 58.456354],
            [46.582152, 58.456686],
            [46.579335, 58.399094],
            [46.528958, 58.402245],
            [46.509761, 58.392874],
            [46.488283, 58.397725],
            [46.426669, 58.393577],
            [46.394919, 58.375077],
            [46.394087, 58.347008],
            [46.422498, 58.346789],
            [46.439635, 58.356283],
            [46.431678, 58.332613],
            [46.407641, 58.323979],
            [46.396088, 58.329071],
            [46.39193, 58.28613],
            [46.412496, 58.259611],
            [46.416211, 58.214995],
            [46.407192, 58.201148],
            [46.365563, 58.192026],
            [46.335236, 58.165583],
            [46.339362, 58.136797],
            [46.322943, 58.116978],
            [46.329497, 58.077339],
            [46.346911, 58.07734],
            [46.34493, 58.062218],
            [46.064194, 58.058746],
            [46.063617, 58.056869],
            [45.922555, 58.057032],
            [45.903391, 58.042639],
            [45.842632, 58.050935],
            [45.808877, 58.041255],
            [45.795864, 58.021653],
            [45.747791, 58.028677],
            [45.749503, 58.014169],
            [45.733398, 58.011973],
            [45.749834, 57.99462],
            [45.742835, 57.966831],
            [45.713798, 57.954467],
            [45.709, 57.941291],
            [45.673213, 57.924128],
            [45.612942, 57.919409],
            [45.589841, 57.940394],
            [45.557957, 57.936896],
            [45.534206, 57.947063],
            [45.50785, 57.976342],
            [45.50891, 58.028511],
            [45.293524, 58.032057],
            [45.294337, 58.051415],
            [45.221458, 58.052715],
            [45.223571, 58.088666],
            [45.009464, 58.091175],
            [45.007842, 58.076179],
            [44.971645, 58.075262],
            [44.95076, 58.085157],
            [44.869006, 58.076712],
            [44.849901, 58.061683],
            [44.855611, 58.037684],
            [44.829749, 58.018811],
            [44.813206, 58.014384],
            [44.816935, 57.98223],
            [44.826373, 57.968949],
            [44.800976, 57.959861],
            [44.842803, 57.906039],
            [44.873559, 57.894506],
            [44.880783, 57.881924],
            [44.863892, 57.854546],
            [44.855855, 57.829265],
            [44.834768, 57.819712],
            [44.83011, 57.793732],
            [44.776754, 57.757966],
            [44.761027, 57.753422],
            [44.760097, 57.719287],
            [44.766505, 57.712414],
            [44.739011, 57.697385],
            [44.710585, 57.690627],
            [44.67913, 57.698199],
            [44.633695, 57.694471],
            [44.597113, 57.700878],
            [44.56694, 57.685965],
            [44.561815, 57.675014],
            [44.500886, 57.655674],
            [44.50974, 57.64717],
            [44.493081, 57.629927],
            [44.502869, 57.612103],
            [44.472463, 57.591482],
            [44.447998, 57.582977],
            [44.451494, 57.57191],
            [44.424815, 57.56923],
            [44.365285, 57.528337],
            [44.349674, 57.527987],
            [44.31321, 57.513657],
            [44.245756, 57.51785],
            [44.226978, 57.491356],
            [44.195779, 57.482783],
            [44.178188, 57.471599],
            [44.14883, 57.473695],
            [44.122268, 57.457501],
            [44.061572, 57.445501],
            [44.031282, 57.44352],
            [44.01707, 57.432568],
            [43.9453, 57.431819],
            [43.91501, 57.42091],
            [43.879908, 57.42716],
            [43.853393, 57.417485],
            [43.82231, 57.432854],
            [43.74751, 57.45636],
            [43.714455, 57.481092],
            [43.674054, 57.488607],
            [43.659094, 57.500162],
            [43.649508, 57.531335],
            [43.630696, 57.54719],
            [43.551419, 57.554983],
            [43.536189, 57.572719],
            [43.511466, 57.567344],
            [43.528127, 57.57872],
            [43.518272, 57.605414],
            [43.496505, 57.604608],
            [43.467661, 57.59135],
            [43.451359, 57.570926],
            [43.419202, 57.549875],
            [43.361039, 57.527611],
            [43.357216, 57.50831],
            [43.339301, 57.494514],
            [43.305531, 57.479644],
            [43.304617, 57.428877],
            [43.263262, 57.410914],
            [43.224945, 57.418325],
            [43.20488, 57.460505],
            [43.227503, 57.463414],
            [43.21433, 57.486632],
            [43.228421, 57.51898],
            [43.200411, 57.535571],
            [43.19295, 57.554294],
            [43.184346, 57.542465],
            [43.210096, 57.516262],
            [43.201, 57.494],
            [43.183, 57.476],
            [43.188953, 57.469145],
            [43.1705, 57.449],
            [43.1845, 57.4305],
            [43.161065, 57.405714],
            [43.11775, 57.398],
            [43.104874, 57.403865],
            [43.08213, 57.393795],
            [43.051582, 57.394363],
            [43.01775, 57.3825],
            [42.94979, 57.37911],
            [42.92404, 57.37161],
            [42.89554, 57.37111],
            [42.86929, 57.38536],
            [42.81475, 57.4295],
            [42.849, 57.432],
            [42.84725, 57.44225],
            [42.827, 57.448],
            [42.8405, 57.469],
            [42.85925, 57.46625],
            [42.866, 57.4805],
            [42.841078, 57.490338],
            [42.847368, 57.508583],
            [42.818915, 57.516402],
            [42.806918, 57.527843],
            [42.834677, 57.54366],
            [42.858562, 57.565368],
            [42.873355, 57.600018],
            [42.851943, 57.615724],
            [42.868231, 57.6364],
            [42.864368, 57.645138],
            [42.883711, 57.654661],
            [42.859525, 57.667734],
            [42.839351, 57.663537],
            [42.831241, 57.681371],
            [42.784388, 57.681019],
            [42.783707, 57.690568],
            [42.74892, 57.690753],
            [42.719241, 57.643018],
            [42.729233, 57.621489],
            [42.715006, 57.613792],
            [42.692215, 57.633179],
            [42.668757, 57.627971],
            [42.624344, 57.634829],
            [42.530508, 57.62483],
            [42.511669, 57.630891],
            [42.465958, 57.622092],
            [42.460068, 57.636223],
            [42.431764, 57.629752],
            [42.425988, 57.618575],
            [42.403898, 57.608723],
            [42.406645, 57.599832],
            [42.374847, 57.594698],
            [42.376808, 57.57665],
            [42.350359, 57.567944],
            [42.322874, 57.573506],
            [42.252431, 57.562938],
            [42.245471, 57.570489],
            [42.22089, 57.554981],
            [42.200277, 57.566996],
            [42.124028, 57.559258],
            [42.122761, 57.577753],
            [42.103377, 57.58688],
            [42.023212, 57.588587],
            [42.023312, 57.606395],
            [42.007887, 57.60658],
            [42.018787, 57.629918],
            [41.983082, 57.632335],
            [41.965481, 57.627089],
            [41.930339, 57.634648],
            [41.934463, 57.655292],
            [41.902112, 57.657774],
            [41.906054, 57.675256],
            [41.893026, 57.676692],
            [41.895725, 57.693402],
            [41.824787, 57.698861],
            [41.803355, 57.706063],
            [41.797126, 57.717537],
            [41.725811, 57.727919],
            [41.651512, 57.72875],
            [41.626886, 57.737664],
            [41.599156, 57.736762],
            [41.579001, 57.703841],
            [41.592855, 57.687952],
            [41.587964, 57.663863],
            [41.569898, 57.634141],
            [41.59596, 57.635596],
            [41.619181, 57.623641],
            [41.588428, 57.598705],
            [41.624531, 57.596401],
            [41.611774, 57.574422],
            [41.619631, 57.56666],
            [41.593087, 57.562892],
            [41.604078, 57.54273],
            [41.632585, 57.538482],
            [41.609309, 57.516328],
            [41.608792, 57.493307],
            [41.582177, 57.474344],
            [41.567387, 57.45253],
            [41.536083, 57.46088],
            [41.422237, 57.479755],
            [41.420339, 57.462744],
            [41.406201, 57.461505],
            [41.418254, 57.438892],
            [41.379769, 57.431801],
            [41.362101, 57.438811],
            [41.331075, 57.43036],
            [41.312641, 57.414808],
            [41.251767, 57.413],
            [41.24748, 57.40307],
            [41.202914, 57.406044],
            [41.178571, 57.42156],
            [41.151576, 57.422957],
            [41.139434, 57.409306],
            [41.153112, 57.391702],
            [41.110593, 57.385347],
            [41.102141, 57.370997],
            [41.057355, 57.344063],
            [41.030196, 57.351131],
            [41.013476, 57.335232],
            [41.018309, 57.31818],
            [40.974993, 57.32305],
            [40.929412, 57.320323],
            [40.893784, 57.337277],
            [40.861736, 57.342267],
            [40.864246, 57.353118],
            [40.827293, 57.32933],
            [40.830224, 57.319582],
            [40.781358, 57.328699],
            [40.755435, 57.328693],
            [40.736858, 57.319211],
            [40.704242, 57.315717],
            [40.680512, 57.294165],
            [40.653451, 57.288239],
            [40.602738, 57.301225],
            [40.59661, 57.313227],
            [40.563659, 57.313549],
            [40.532688, 57.304007],
            [40.526172, 57.286304],
            [40.470622, 57.272169],
            [40.430464, 57.285829],
            [40.423103, 57.312432],
            [40.437478, 57.312456],
            [40.42469, 57.337995],
            [40.434653, 57.337582],
            [40.437419, 57.369118],
            [40.446901, 57.378803],
            [40.413778, 57.382872],
            [40.390103, 57.398728],
            [40.393326, 57.406933],
            [40.443977, 57.417478],
            [40.433576, 57.435372],
            [40.439927, 57.449526],
            [40.415532, 57.466428],
            [40.431873, 57.471207],
            [40.40563, 57.493452],
            [40.428242, 57.512229],
            [40.451868, 57.501794],
            [40.453246, 57.510666],
            [40.502487, 57.504063],
            [40.50458, 57.517849],
            [40.467212, 57.52039],
            [40.479221, 57.526576],
            [40.440885, 57.542821],
            [40.465, 57.565859],
            [40.490195, 57.559519],
            [40.507242, 57.568075],
            [40.498313, 57.581876],
            [40.524801, 57.59297],
            [40.548976, 57.582613],
            [40.591824, 57.59752],
            [40.615347, 57.593291],
            [40.598718, 57.572212],
            [40.631984, 57.56882],
            [40.650352, 57.585331],
            [40.673456, 57.593508],
            [40.692973, 57.618057],
            [40.707318, 57.625776],
            [40.708052, 57.652632],
            [40.718094, 57.662102],
            [40.673414, 57.697475],
            [40.655755, 57.697005],
            [40.675894, 57.729532],
            [40.647084, 57.727518],
            [40.657468, 57.759314],
            [40.624526, 57.7685],
            [40.614466, 57.782011],
            [40.635677, 57.785577],
            [40.630668, 57.79832],
            [40.651189, 57.799045],
            [40.650823, 57.819228],
            [40.622468, 57.84794],
            [40.638259, 57.864494],
            [40.614604, 57.878272],
            [40.597672, 57.897109],
            [40.626687, 57.914659],
            [40.646417, 57.935326],
            [40.63961, 57.94144],
            [40.673455, 57.958976],
            [40.680084, 57.981601],
            [40.715185, 57.991237],
            [40.739258, 57.987963],
            [40.766502, 57.996925],
            [40.777746, 57.989048],
            [40.790008, 58.003541],
            [40.809087, 58.006626],
            [40.803394, 58.019806],
            [40.76965, 58.041827],
            [40.774589, 58.066146],
            [40.820946, 58.064373],
            [40.839311, 58.068554],
            [40.837791, 58.082993],
            [40.85109, 58.085621],
            [40.850582, 58.110446],
            [40.875533, 58.115513],
            [40.893266, 58.107787],
            [40.892504, 58.138565],
            [40.882116, 58.178652],
            [40.906813, 58.199678],
            [40.921253, 58.191319],
            [40.96305, 58.195119],
            [40.960769, 58.219533],
            [40.985087, 58.231566],
            [40.98382, 58.242712],
            [41.037442, 58.283714],
            [41.0762, 58.292068],
            [41.088059, 58.285663],
            [41.138783, 58.306043],
            [41.161074, 58.332135],
            [41.190459, 58.333909],
            [41.189698, 58.350375],
            [41.150939, 58.370386],
            [41.170697, 58.382546],
            [41.150684, 58.396225],
            [41.136245, 58.394641],
            [41.082033, 58.426558],
            [41.067594, 58.426305],
            [41.048341, 58.441757],
            [41.103809, 58.463148],
            [41.078738, 58.468862],
            [41.070885, 58.486135],
            [41.041655, 58.484808],
            [41.052517, 58.506986],
            [41.023638, 58.527758],
            [41.019201, 58.541739],
            [40.968159, 58.558155],
            [40.938014, 58.562968],
            [40.930072, 58.576336],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5493",
      properties: {
        name: "Краснодарский край",
        density: 0,
        path: "/world/Russia/Краснодарский край",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [38.685837, 46.870703],
              [38.685737, 46.826137],
              [38.869561, 46.826414],
              [38.869563, 46.841224],
              [38.933296, 46.841291],
              [38.93327, 46.826404],
              [38.895873, 46.826389],
              [38.895954, 46.731792],
              [38.869483, 46.731926],
              [38.869649, 46.584606],
              [38.867766, 46.569768],
              [38.902433, 46.560099],
              [38.90208, 46.634822],
              [38.930587, 46.625247],
              [38.962894, 46.636245],
              [38.985364, 46.622987],
              [39.003552, 46.62163],
              [39.013991, 46.633196],
              [39.056754, 46.620234],
              [39.0688, 46.600413],
              [39.131449, 46.604947],
              [39.1403, 46.621152],
              [39.15199, 46.610593],
              [39.169423, 46.622915],
              [39.20242, 46.60978],
              [39.209095, 46.620574],
              [39.209323, 46.682072],
              [39.227224, 46.68196],
              [39.22789, 46.708289],
              [39.261845, 46.708199],
              [39.262562, 46.794294],
              [39.340976, 46.794535],
              [39.340945, 46.799494],
              [39.38329, 46.801202],
              [39.418574, 46.796536],
              [39.445993, 46.805679],
              [39.4461, 46.818085],
              [39.486776, 46.817798],
              [39.486642, 46.805214],
              [39.565225, 46.805597],
              [39.563772, 46.787894],
              [39.690484, 46.788808],
              [39.722643, 46.787433],
              [39.733501, 46.793417],
              [39.758186, 46.788926],
              [39.840838, 46.789107],
              [39.841119, 46.771467],
              [40.063222, 46.771941],
              [40.063257, 46.741989],
              [40.079678, 46.741853],
              [40.078948, 46.729629],
              [40.104755, 46.730213],
              [40.105537, 46.735972],
              [40.141978, 46.736044],
              [40.11629, 46.718187],
              [40.116658, 46.646098],
              [40.103439, 46.645922],
              [40.1036, 46.631452],
              [40.067814, 46.635723],
              [40.064406, 46.628049],
              [40.064291, 46.534309],
              [40.135707, 46.541362],
              [40.180034, 46.533212],
              [40.221454, 46.537824],
              [40.221043, 46.502402],
              [40.238018, 46.496791],
              [40.238122, 46.448403],
              [40.285994, 46.448445],
              [40.28613, 46.376346],
              [40.260582, 46.376221],
              [40.247545, 46.36771],
              [40.247306, 46.345153],
              [40.272239, 46.344489],
              [40.272275, 46.314493],
              [40.324277, 46.31434],
              [40.324128, 46.28296],
              [40.408093, 46.272132],
              [40.490197, 46.274116],
              [40.537268, 46.272668],
              [40.566432, 46.286279],
              [40.743525, 46.285549],
              [40.74449, 46.229063],
              [40.925714, 46.229994],
              [40.925535, 46.295277],
              [40.951891, 46.291879],
              [40.990422, 46.298363],
              [40.991235, 46.20741],
              [40.97192, 46.207312],
              [40.967431, 46.189339],
              [40.978863, 46.187751],
              [40.978788, 46.113405],
              [41.044878, 46.1225],
              [41.069513, 46.11333],
              [41.069777, 46.077571],
              [41.108224, 46.077619],
              [41.10844, 46.059741],
              [41.121842, 46.059611],
              [41.121792, 46.005679],
              [41.134752, 46.005693],
              [41.135053, 45.979184],
              [41.16065, 45.978968],
              [41.160898, 45.951688],
              [41.161177, 45.879841],
              [41.24901, 45.880051],
              [41.262804, 45.857267],
              [41.240907, 45.847836],
              [41.233466, 45.831393],
              [41.257537, 45.817223],
              [41.260045, 45.794947],
              [41.272798, 45.79481],
              [41.272136, 45.758834],
              [41.169374, 45.759651],
              [41.167237, 45.674439],
              [41.053134, 45.675002],
              [41.053256, 45.693132],
              [41.02911, 45.693199],
              [41.029051, 45.700812],
              [40.979072, 45.713609],
              [40.963457, 45.705951],
              [40.963034, 45.693733],
              [40.847968, 45.694229],
              [40.846043, 45.51692],
              [40.882291, 45.514764],
              [40.923175, 45.516862],
              [40.92249, 45.447101],
              [40.940638, 45.435855],
              [40.980407, 45.422455],
              [40.973484, 45.411534],
              [40.981617, 45.402008],
              [40.974033, 45.392539],
              [40.99532, 45.393013],
              [40.991039, 45.377283],
              [41.007196, 45.349503],
              [40.994456, 45.338962],
              [41.003106, 45.333545],
              [40.993477, 45.322803],
              [41.016011, 45.316449],
              [41.019894, 45.290748],
              [41.033672, 45.276623],
              [41.016884, 45.2634],
              [41.038357, 45.255708],
              [41.027456, 45.239273],
              [41.036139, 45.225292],
              [41.232713, 45.223985],
              [41.232885, 45.233001],
              [41.258261, 45.232849],
              [41.258531, 45.250751],
              [41.278997, 45.263847],
              [41.29749, 45.223569],
              [41.353706, 45.223072],
              [41.351444, 45.09139],
              [41.387, 45.078511],
              [41.40635, 45.064124],
              [41.420713, 45.030103],
              [41.456479, 44.988263],
              [41.654051, 44.986251],
              [41.652237, 44.905315],
              [41.63964, 44.905394],
              [41.638753, 44.864101],
              [41.629383, 44.847359],
              [41.620672, 44.848579],
              [41.605375, 44.825898],
              [41.615002, 44.799952],
              [41.584635, 44.794232],
              [41.594201, 44.768236],
              [41.58627, 44.772358],
              [41.578485, 44.757225],
              [41.563826, 44.76161],
              [41.555153, 44.751345],
              [41.559862, 44.738386],
              [41.519405, 44.730715],
              [41.523479, 44.727036],
              [41.427077, 44.708474],
              [41.444169, 44.66287],
              [41.456889, 44.650612],
              [41.476111, 44.647504],
              [41.494933, 44.595691],
              [41.531649, 44.602582],
              [41.527452, 44.61394],
              [41.551966, 44.618152],
              [41.589707, 44.515929],
              [41.638336, 44.525138],
              [41.651129, 44.490341],
              [41.699787, 44.499517],
              [41.703536, 44.489524],
              [41.736411, 44.483439],
              [41.736821, 44.473134],
              [41.735251, 44.460957],
              [41.706222, 44.455179],
              [41.693904, 44.409881],
              [41.628202, 44.407096],
              [41.633695, 44.377892],
              [41.59778, 44.374121],
              [41.592001, 44.363556],
              [41.690496, 44.333406],
              [41.74398, 44.268377],
              [41.727642, 44.247823],
              [41.728459, 44.232715],
              [41.712361, 44.214777],
              [41.711403, 44.198071],
              [41.698912, 44.171185],
              [41.683131, 44.167666],
              [41.677916, 44.15664],
              [41.637668, 44.117428],
              [41.599999, 44.112284],
              [41.550784, 44.096158],
              [41.556685, 44.087222],
              [41.553604, 44.064285],
              [41.538583, 44.044665],
              [41.484461, 44.046941],
              [41.47975, 44.000926],
              [41.452599, 44.004105],
              [41.432291, 44.000443],
              [41.414169, 43.98285],
              [41.393281, 43.985687],
              [41.380429, 44.003677],
              [41.354443, 44.00942],
              [41.362695, 43.999758],
              [41.350163, 43.993271],
              [41.360119, 43.977994],
              [41.315039, 43.979784],
              [41.308807, 43.997952],
              [41.292763, 44.000815],
              [41.313159, 44.015349],
              [41.295147, 44.016818],
              [41.277656, 44.004234],
              [41.273621, 44.018233],
              [41.246592, 44.009949],
              [41.281858, 44.051354],
              [41.278499, 44.073784],
              [41.294487, 44.055537],
              [41.30147, 44.061719],
              [41.291116, 44.085462],
              [41.264029, 44.094959],
              [41.232298, 44.089329],
              [41.170276, 44.088794],
              [41.169946, 44.104265],
              [41.152235, 44.117715],
              [41.121454, 44.126023],
              [41.122556, 44.106524],
              [41.084788, 44.094713],
              [41.061504, 44.112629],
              [41.035626, 44.121109],
              [41.031259, 44.112563],
              [40.954853, 44.14107],
              [40.914129, 44.145297],
              [40.891276, 44.142995],
              [40.891244, 44.103888],
              [40.926203, 44.09018],
              [40.910667, 44.083673],
              [40.926887, 44.068084],
              [40.919054, 44.038141],
              [40.935459, 44.017353],
              [40.91512, 43.997726],
              [40.911465, 43.977061],
              [40.885749, 43.944623],
              [40.860826, 43.94688],
              [40.840751, 43.932735],
              [40.834261, 43.921044],
              [40.802972, 43.917742],
              [40.785981, 43.880003],
              [40.765771, 43.872229],
              [40.752922, 43.836755],
              [40.755663, 43.829717],
              [40.722806, 43.7949],
              [40.741954, 43.782686],
              [40.756861, 43.749115],
              [40.732303, 43.738651],
              [40.71113, 43.735981],
              [40.701007, 43.741355],
              [40.684137, 43.7202],
              [40.696001, 43.703801],
              [40.692923, 43.695614],
              [40.712826, 43.685883],
              [40.709309, 43.660782],
              [40.699356, 43.653443],
              [40.712418, 43.645923],
              [40.725684, 43.617414],
              [40.742397, 43.60543],
              [40.739701, 43.589462],
              [40.726145, 43.58359],
              [40.726324, 43.549258],
              [40.713602, 43.532771],
              [40.688744, 43.540519],
              [40.661776, 43.563048],
              [40.634257, 43.550029],
              [40.607264, 43.546564],
              [40.595294, 43.531656],
              [40.566298, 43.519157],
              [40.523301, 43.520501],
              [40.50541, 43.524875],
              [40.477821, 43.549126],
              [40.466627, 43.546082],
              [40.433113, 43.557683],
              [40.427631, 43.553606],
              [40.388676, 43.554312],
              [40.328106, 43.566797],
              [40.314359, 43.578671],
              [40.299055, 43.5706],
              [40.24219, 43.586529],
              [40.213012, 43.57462],
              [40.171142, 43.57656],
              [40.130665, 43.565039],
              [40.109773, 43.569601],
              [40.090694, 43.555579],
              [40.095973, 43.532441],
              [40.061089, 43.502657],
              [40.043176, 43.470359],
              [40.031788, 43.439935],
              [40.012909, 43.428939],
              [40.008494, 43.385291],
              [39.991821, 43.385174],
              [39.969399, 43.396461],
              [39.948326, 43.399204],
              [39.914505, 43.424872],
              [39.902814, 43.444519],
              [39.886529, 43.488075],
              [39.863359, 43.511356],
              [39.849253, 43.512607],
              [39.825903, 43.52961],
              [39.768682, 43.551517],
              [39.751848, 43.562093],
              [39.713454, 43.577165],
              [39.700224, 43.619167],
              [39.681806, 43.637528],
              [39.606864, 43.667468],
              [39.582183, 43.702777],
              [39.502082, 43.762711],
              [39.460655, 43.78892],
              [39.444059, 43.811565],
              [39.419006, 43.832429],
              [39.402805, 43.853667],
              [39.346515, 43.895863],
              [39.333068, 43.898654],
              [39.296422, 43.941055],
              [39.262613, 43.957001],
              [39.249751, 43.971191],
              [39.228026, 43.979628],
              [39.198305, 43.998184],
              [39.150138, 44.02264],
              [39.13249, 44.043805],
              [39.089917, 44.081608],
              [39.070578, 44.093757],
              [39.037511, 44.096073],
              [39.03123, 44.102009],
              [39.029145, 44.12784],
              [39.014131, 44.148925],
              [38.997504, 44.161673],
              [38.973432, 44.169288],
              [38.961359, 44.167075],
              [38.9175, 44.185513],
              [38.88801, 44.186768],
              [38.871481, 44.19458],
              [38.839465, 44.229692],
              [38.818359, 44.246339],
              [38.8134, 44.261473],
              [38.793077, 44.279909],
              [38.747192, 44.300755],
              [38.709955, 44.301902],
              [38.653909, 44.319166],
              [38.634747, 44.321315],
              [38.568628, 44.349458],
              [38.520228, 44.355556],
              [38.473214, 44.367168],
              [38.440071, 44.368913],
              [38.411387, 44.375697],
              [38.385701, 44.36889],
              [38.360615, 44.373243],
              [38.335759, 44.388496],
              [38.283871, 44.41067],
              [38.221344, 44.411896],
              [38.203325, 44.417926],
              [38.189105, 44.436557],
              [38.152625, 44.458101],
              [38.13323, 44.485666],
              [38.132558, 44.49657],
              [38.091348, 44.528445],
              [38.048714, 44.551841],
              [38.074198, 44.556953],
              [38.076472, 44.564274],
              [38.058275, 44.581377],
              [38.031512, 44.585004],
              [38.023363, 44.578424],
              [38.029993, 44.560448],
              [37.985934, 44.567711],
              [37.930704, 44.604539],
              [37.905974, 44.630475],
              [37.930584, 44.644701],
              [37.912687, 44.666438],
              [37.880371, 44.680008],
              [37.845015, 44.706736],
              [37.840099, 44.715448],
              [37.802747, 44.72823],
              [37.785394, 44.722435],
              [37.791527, 44.69421],
              [37.812425, 44.670426],
              [37.763117, 44.654174],
              [37.726726, 44.649426],
              [37.692856, 44.656842],
              [37.661586, 44.667897],
              [37.62929, 44.669855],
              [37.614995, 44.66593],
              [37.547863, 44.676385],
              [37.472632, 44.704004],
              [37.455077, 44.70643],
              [37.43458, 44.727286],
              [37.409124, 44.739833],
              [37.397921, 44.754429],
              [37.380786, 44.756589],
              [37.38328, 44.782317],
              [37.340832, 44.830454],
              [37.337602, 44.851987],
              [37.297421, 44.888872],
              [37.297014, 44.896367],
              [37.315826, 44.901431],
              [37.313242, 44.925658],
              [37.292604, 44.949557],
              [37.260272, 44.974602],
              [37.222864, 44.995664],
              [37.156996, 45.025841],
              [37.114006, 45.042229],
              [37.042743, 45.063979],
              [36.992919, 45.075857],
              [36.918084, 45.10048],
              [36.857438, 45.114238],
              [36.801816, 45.119081],
              [36.734784, 45.109817],
              [36.720293, 45.118893],
              [36.632684, 45.14063],
              [36.615949, 45.166894],
              [36.613777, 45.182465],
              [36.597915, 45.195378],
              [36.608123, 45.214931],
              [36.620802, 45.210887],
              [36.652887, 45.21617],
              [36.685852, 45.22672],
              [36.708315, 45.218835],
              [36.741985, 45.221045],
              [36.763765, 45.239561],
              [36.77903, 45.239217],
              [36.831361, 45.247663],
              [36.865074, 45.249325],
              [36.898124, 45.259174],
              [36.909363, 45.269642],
              [36.983759, 45.282268],
              [36.993061, 45.291539],
              [36.986992, 45.324398],
              [36.929346, 45.329524],
              [36.826001, 45.311954],
              [36.800195, 45.303005],
              [36.781295, 45.334824],
              [36.766062, 45.375317],
              [36.779428, 45.378178],
              [36.798113, 45.356058],
              [36.812313, 45.355817],
              [36.840953, 45.368163],
              [36.841891, 45.381778],
              [36.81508, 45.382619],
              [36.810572, 45.390251],
              [36.786361, 45.392947],
              [36.768034, 45.411925],
              [36.758318, 45.410055],
              [36.735004, 45.378297],
              [36.65848, 45.318518],
              [36.619865, 45.30284],
              [36.657426, 45.334635],
              [36.702077, 45.355448],
              [36.718583, 45.367226],
              [36.765926, 45.423142],
              [36.785133, 45.442244],
              [36.857142, 45.450949],
              [36.884501, 45.439783],
              [36.924104, 45.433071],
              [36.976777, 45.401821],
              [37.034796, 45.377437],
              [37.100533, 45.358521],
              [37.192857, 45.337176],
              [37.220562, 45.332895],
              [37.325766, 45.329868],
              [37.366273, 45.337767],
              [37.373993, 45.334926],
              [37.39061, 45.351837],
              [37.41522, 45.352567],
              [37.442813, 45.346312],
              [37.473686, 45.354494],
              [37.520138, 45.375011],
              [37.56224, 45.40193],
              [37.579361, 45.4193],
              [37.596887, 45.453258],
              [37.59879, 45.477565],
              [37.598234, 45.577792],
              [37.601331, 45.622049],
              [37.60706, 45.649055],
              [37.616005, 45.664555],
              [37.649322, 45.686373],
              [37.677651, 45.695683],
              [37.717017, 45.703058],
              [37.757518, 45.717487],
              [37.766528, 45.732463],
              [37.78531, 45.744721],
              [37.820795, 45.747273],
              [37.838271, 45.761433],
              [37.862359, 45.802462],
              [37.876901, 45.83621],
              [37.921083, 45.961559],
              [37.945257, 46.005648],
              [37.970874, 46.033585],
              [37.988006, 46.043356],
              [38.095676, 46.059136],
              [38.118116, 46.050845],
              [38.11747, 46.032502],
              [38.104781, 46.020091],
              [38.079198, 46.008601],
              [38.08589, 45.982234],
              [38.080614, 45.967571],
              [38.104935, 45.953399],
              [38.116388, 45.961697],
              [38.124291, 45.985357],
              [38.154167, 46.00482],
              [38.14781, 46.035298],
              [38.178503, 46.076564],
              [38.180866, 46.104825],
              [38.187299, 46.117668],
              [38.239714, 46.151574],
              [38.244744, 46.159911],
              [38.253067, 46.232003],
              [38.266427, 46.255688],
              [38.283101, 46.269619],
              [38.222395, 46.323112],
              [38.144442, 46.375102],
              [38.09715, 46.397216],
              [38.072232, 46.40189],
              [38.02218, 46.401858],
              [37.988701, 46.407851],
              [37.984682, 46.394606],
              [38.01521, 46.376517],
              [38.010238, 46.367094],
              [37.984388, 46.365851],
              [37.924768, 46.401774],
              [37.886669, 46.432587],
              [37.860766, 46.465498],
              [37.814032, 46.540197],
              [37.750486, 46.665961],
              [37.729275, 46.68733],
              [37.808037, 46.649518],
              [37.898384, 46.634959],
              [37.95455, 46.633263],
              [38.006059, 46.635765],
              [38.046164, 46.642005],
              [38.101536, 46.671436],
              [38.119194, 46.68415],
              [38.143657, 46.693141],
              [38.222224, 46.699763],
              [38.248008, 46.710777],
              [38.27479, 46.73225],
              [38.29353, 46.741414],
              [38.286794, 46.724019],
              [38.288868, 46.707236],
              [38.306929, 46.680374],
              [38.336148, 46.662897],
              [38.391911, 46.655324],
              [38.429535, 46.653924],
              [38.469247, 46.644109],
              [38.508533, 46.643441],
              [38.553542, 46.663289],
              [38.574428, 46.660947],
              [38.59285, 46.66621],
              [38.60191, 46.68276],
              [38.588822, 46.715775],
              [38.577218, 46.726116],
              [38.546359, 46.733674],
              [38.507361, 46.751],
              [38.477536, 46.747043],
              [38.455055, 46.755118],
              [38.409809, 46.753978],
              [38.395578, 46.749186],
              [38.413483, 46.807851],
              [38.442314, 46.845572],
              [38.480822, 46.866358],
              [38.49864, 46.881739],
              [38.549777, 46.85652],
              [38.569965, 46.85634],
              [38.643893, 46.872649],
              [38.685837, 46.870703],
            ],
            [
              [38.684018, 44.951415],
              [38.754197, 44.949442],
              [38.781602, 44.967617],
              [38.789187, 44.942949],
              [38.81593, 44.944065],
              [38.844455, 44.925521],
              [38.860054, 44.908136],
              [38.854172, 44.894369],
              [38.932581, 44.876001],
              [38.961229, 44.862491],
              [38.989715, 44.856941],
              [38.989736, 44.844265],
              [39.027097, 44.842041],
              [39.090759, 44.817163],
              [39.140975, 44.835262],
              [39.152772, 44.835918],
              [39.23807, 44.822892],
              [39.304931, 44.825465],
              [39.353677, 44.816],
              [39.392659, 44.813253],
              [39.456121, 44.817524],
              [39.48181, 44.804534],
              [39.48299, 44.820153],
              [39.556268, 44.806862],
              [39.5689, 44.827708],
              [39.557787, 44.836637],
              [39.607594, 44.846157],
              [39.621835, 44.861275],
              [39.641949, 44.865606],
              [39.64016, 44.895798],
              [39.572991, 44.925901],
              [39.570017, 44.933944],
              [39.536988, 44.955771],
              [39.505031, 44.969518],
              [39.52224, 44.981395],
              [39.51295, 44.988166],
              [39.49451, 44.975211],
              [39.484008, 44.989912],
              [39.475028, 44.987667],
              [39.471249, 45.035691],
              [39.487434, 45.032259],
              [39.490775, 45.044625],
              [39.509062, 45.036597],
              [39.515807, 45.050434],
              [39.529773, 45.043242],
              [39.551892, 45.047334],
              [39.545366, 45.034607],
              [39.571574, 45.035861],
              [39.566093, 45.047076],
              [39.607981, 45.042397],
              [39.622129, 45.049037],
              [39.652424, 45.033683],
              [39.644205, 45.004864],
              [39.598588, 44.982751],
              [39.620013, 44.960311],
              [39.651649, 44.975647],
              [39.64455, 44.983232],
              [39.678693, 44.992584],
              [39.673288, 44.978613],
              [39.696232, 44.968418],
              [39.681712, 44.963087],
              [39.692425, 44.951403],
              [39.773753, 44.97371],
              [39.7641, 44.990373],
              [39.84916, 45.014785],
              [39.884915, 45.023241],
              [39.890982, 45.000365],
              [39.903734, 45.001955],
              [39.924237, 44.927186],
              [39.942136, 44.929611],
              [39.963479, 44.892425],
              [39.933944, 44.883853],
              [39.957836, 44.842396],
              [39.975785, 44.847369],
              [39.98539, 44.830456],
              [39.955455, 44.822145],
              [39.939072, 44.82703],
              [39.957911, 44.793757],
              [39.981416, 44.800436],
              [40.008347, 44.759888],
              [40.029016, 44.746998],
              [39.987544, 44.717298],
              [39.969721, 44.730053],
              [39.960817, 44.723619],
              [39.942818, 44.736303],
              [39.910078, 44.713004],
              [39.908078, 44.700518],
              [39.927501, 44.689666],
              [39.896248, 44.661805],
              [39.915962, 44.651074],
              [39.904418, 44.641771],
              [39.920384, 44.626108],
              [39.914878, 44.608468],
              [39.932877, 44.602892],
              [39.929128, 44.590294],
              [39.874164, 44.591394],
              [39.872395, 44.555554],
              [39.884823, 44.555065],
              [39.879406, 44.432766],
              [39.870626, 44.431669],
              [39.863059, 44.407026],
              [39.879092, 44.396542],
              [39.845903, 44.353173],
              [39.869771, 44.338186],
              [39.878466, 44.346915],
              [39.907281, 44.351023],
              [39.922257, 44.337314],
              [39.957679, 44.348085],
              [39.975012, 44.339454],
              [39.966303, 44.306452],
              [39.973115, 44.279173],
              [39.989763, 44.26131],
              [40.007011, 44.266541],
              [40.062091, 44.268424],
              [40.089498, 44.250888],
              [40.067827, 44.224876],
              [40.077379, 44.221195],
              [40.079227, 44.197433],
              [40.087558, 44.178697],
              [40.072489, 44.169729],
              [40.07646, 44.154208],
              [40.069046, 44.145227],
              [40.087987, 44.138516],
              [40.092788, 44.128114],
              [40.074463, 44.122049],
              [40.056938, 44.137556],
              [40.016127, 44.106438],
              [40.017852, 44.087841],
              [39.993302, 44.076403],
              [39.965197, 44.091156],
              [39.963151, 44.102303],
              [39.950265, 44.09946],
              [39.920879, 44.117122],
              [39.89664, 44.123925],
              [39.878037, 44.148147],
              [39.876101, 44.170364],
              [39.865027, 44.184307],
              [39.822049, 44.18042],
              [39.768596, 44.180348],
              [39.776328, 44.16977],
              [39.764601, 44.163456],
              [39.761805, 44.148818],
              [39.778432, 44.128841],
              [39.794054, 44.127228],
              [39.829143, 44.084684],
              [39.828494, 44.065343],
              [39.81428, 44.050867],
              [39.796783, 44.017459],
              [39.797638, 43.996435],
              [39.816449, 43.981552],
              [39.777775, 43.961767],
              [39.811369, 43.932682],
              [39.851457, 43.92423],
              [39.912676, 43.939498],
              [39.902747, 43.953737],
              [39.90892, 43.973883],
              [39.922985, 43.97254],
              [39.940146, 43.958062],
              [39.953051, 43.933772],
              [39.992789, 43.905503],
              [40.013335, 43.884374],
              [40.042425, 43.880997],
              [40.069913, 43.865782],
              [40.093347, 43.845227],
              [40.104578, 43.866871],
              [40.150901, 43.857695],
              [40.183671, 43.87378],
              [40.196892, 43.859228],
              [40.189373, 43.844313],
              [40.208118, 43.818782],
              [40.205936, 43.800014],
              [40.216865, 43.779116],
              [40.233102, 43.775383],
              [40.249407, 43.762116],
              [40.276256, 43.760788],
              [40.304056, 43.781756],
              [40.348936, 43.772217],
              [40.365667, 43.773269],
              [40.382041, 43.801973],
              [40.380233, 43.820099],
              [40.393716, 43.827757],
              [40.403054, 43.86134],
              [40.420424, 43.882593],
              [40.402758, 43.894816],
              [40.408318, 43.934826],
              [40.412941, 43.9419],
              [40.450345, 43.94835],
              [40.452506, 43.956359],
              [40.47699, 43.958813],
              [40.469243, 43.982712],
              [40.438979, 44.029864],
              [40.429388, 44.052424],
              [40.413294, 44.063314],
              [40.42054, 44.07535],
              [40.429459, 44.114159],
              [40.405546, 44.132848],
              [40.405666, 44.163961],
              [40.396194, 44.191404],
              [40.359829, 44.246745],
              [40.42431, 44.28116],
              [40.442341, 44.285424],
              [40.454634, 44.305363],
              [40.482902, 44.315372],
              [40.462765, 44.348229],
              [40.434918, 44.421516],
              [40.414686, 44.422446],
              [40.409343, 44.446875],
              [40.383468, 44.479314],
              [40.397827, 44.487675],
              [40.418013, 44.514518],
              [40.415038, 44.534995],
              [40.396912, 44.532541],
              [40.371823, 44.57608],
              [40.342871, 44.580927],
              [40.354494, 44.597283],
              [40.34029, 44.627029],
              [40.342875, 44.645264],
              [40.375225, 44.667216],
              [40.398895, 44.664045],
              [40.404299, 44.673173],
              [40.366165, 44.689047],
              [40.373192, 44.703962],
              [40.366447, 44.71686],
              [40.40477, 44.717321],
              [40.449068, 44.713357],
              [40.449403, 44.735062],
              [40.429997, 44.735435],
              [40.429997, 44.762156],
              [40.527497, 44.762615],
              [40.521998, 44.741267],
              [40.565331, 44.742213],
              [40.567472, 44.738403],
              [40.567395, 44.654544],
              [40.618122, 44.653855],
              [40.620283, 44.636503],
              [40.644146, 44.636225],
              [40.643364, 44.584312],
              [40.66681, 44.552516],
              [40.6681, 44.538816],
              [40.657901, 44.529674],
              [40.66125, 44.482709],
              [40.619703, 44.4832],
              [40.619524, 44.465084],
              [40.775739, 44.465024],
              [40.775638, 44.484713],
              [40.758547, 44.491544],
              [40.735945, 44.529605],
              [40.724394, 44.557565],
              [40.72795, 44.581633],
              [40.738487, 44.59697],
              [40.725288, 44.626536],
              [40.709595, 44.63205],
              [40.696373, 44.648391],
              [40.670093, 44.698465],
              [40.653018, 44.693586],
              [40.639156, 44.746731],
              [40.626408, 44.761383],
              [40.633412, 44.775185],
              [40.619746, 44.798124],
              [40.6005, 44.808169],
              [40.57948, 44.855306],
              [40.588461, 44.862637],
              [40.571916, 44.866573],
              [40.532771, 44.901293],
              [40.539629, 44.906381],
              [40.527814, 44.933474],
              [40.515648, 44.943356],
              [40.450416, 44.980051],
              [40.388946, 45.033997],
              [40.359502, 45.052339],
              [40.337629, 45.046973],
              [40.331991, 45.05674],
              [40.289352, 45.065285],
              [40.285322, 45.077567],
              [40.294664, 45.086084],
              [40.280774, 45.103904],
              [40.25041, 45.097954],
              [40.227033, 45.117757],
              [40.203311, 45.11266],
              [40.164303, 45.120849],
              [40.134662, 45.119682],
              [40.078832, 45.109703],
              [40.060558, 45.092623],
              [40.043405, 45.103154],
              [40.024601, 45.092963],
              [39.994495, 45.095307],
              [39.976494, 45.091372],
              [39.969446, 45.080813],
              [39.948805, 45.086183],
              [39.944209, 45.094825],
              [39.900147, 45.096677],
              [39.874448, 45.10507],
              [39.851806, 45.118028],
              [39.815132, 45.114732],
              [39.800659, 45.130631],
              [39.779327, 45.124284],
              [39.780904, 45.133245],
              [39.740598, 45.136095],
              [39.729454, 45.150733],
              [39.687916, 45.154833],
              [39.680763, 45.187849],
              [39.68812, 45.192771],
              [39.670456, 45.203953],
              [39.628562, 45.216601],
              [39.595049, 45.210617],
              [39.544279, 45.187122],
              [39.537054, 45.160553],
              [39.513307, 45.161176],
              [39.498148, 45.140322],
              [39.467706, 45.129933],
              [39.455043, 45.118575],
              [39.467576, 45.107913],
              [39.46026, 45.102214],
              [39.441277, 45.110832],
              [39.423992, 45.089677],
              [39.407627, 45.094022],
              [39.399087, 45.076703],
              [39.381427, 45.075331],
              [39.376052, 45.056554],
              [39.344829, 45.042471],
              [39.32607, 45.044812],
              [39.327, 45.033851],
              [39.30187, 45.030151],
              [39.289095, 45.01783],
              [39.264582, 45.019998],
              [39.255826, 45.001998],
              [39.232709, 45.010014],
              [39.22267, 45.005813],
              [39.22314, 44.990298],
              [39.200952, 45.000575],
              [39.182284, 44.99069],
              [39.118864, 44.998363],
              [39.09886, 44.98808],
              [39.077697, 44.988021],
              [39.075968, 44.978716],
              [39.056397, 44.975775],
              [39.041477, 44.984871],
              [39.020384, 44.969078],
              [39.014225, 45.003979],
              [38.973448, 45.007383],
              [38.971504, 44.994103],
              [38.953488, 44.998205],
              [38.947138, 45.016549],
              [38.956397, 45.033566],
              [38.940675, 45.036152],
              [38.907159, 45.022266],
              [38.899536, 45.029409],
              [38.909283, 45.044105],
              [38.897701, 45.053791],
              [38.853548, 45.024905],
              [38.866076, 45.011896],
              [38.860385, 45.001621],
              [38.844391, 45.008189],
              [38.813559, 45.009571],
              [38.809261, 44.995944],
              [38.785869, 45.004562],
              [38.76862, 45.028578],
              [38.756468, 45.033123],
              [38.76967, 45.055299],
              [38.748013, 45.059552],
              [38.735531, 45.045282],
              [38.719475, 45.042958],
              [38.71556, 45.059579],
              [38.705554, 45.065505],
              [38.689718, 45.059975],
              [38.685907, 45.039156],
              [38.684018, 44.951415],
            ],
          ],
          [
            [
              [38.321016, 46.758998],
              [38.337657, 46.771534],
              [38.35336, 46.769444],
              [38.343774, 46.759644],
              [38.321016, 46.758998],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5526",
      properties: {
        name: "Красноярский край",
        density: 0,
        path: "/world/Russia/Красноярский край",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [88.634506, 55.31622],
              [88.646141, 55.318868],
              [88.664144, 55.303534],
              [88.651407, 55.299072],
              [88.634506, 55.31622],
            ],
          ],
          [
            [
              [81.01902, 72.30113],
              [81.05006, 72.28771],
              [80.97451, 72.29526],
              [80.9577, 72.30227],
              [81.01902, 72.30113],
            ],
          ],
          [
            [
              [80.73622, 72.42884],
              [80.7525, 72.4447],
              [80.77877, 72.4432],
              [80.80874, 72.41027],
              [80.86625, 72.4024],
              [80.85713, 72.38067],
              [80.82576, 72.39738],
              [80.77589, 72.40155],
              [80.73622, 72.42884],
            ],
          ],
          [
            [
              [104.706867, 72.64228],
              [104.74462, 72.64398],
              [104.695471, 72.62097],
              [104.66445, 72.619782],
              [104.595676, 72.60563],
              [104.552023, 72.611538],
              [104.637682, 72.623841],
              [104.706867, 72.64228],
            ],
          ],
          [
            [
              [105.643458, 72.836527],
              [105.654942, 72.842878],
              [105.731365, 72.848877],
              [105.690223, 72.837523],
              [105.643458, 72.836527],
            ],
          ],
          [
            [
              [104.93099, 72.70276],
              [104.88128, 72.68728],
              [104.83372, 72.68085],
              [104.82525, 72.71381],
              [104.92396, 72.73994],
              [104.92824, 72.74632],
              [104.98936, 72.7517],
              [105, 72.73744],
              [105.037975, 72.734836],
              [104.97563, 72.71172],
              [104.93099, 72.70276],
            ],
          ],
          [
            [
              [106.005519, 72.95694],
              [105.977275, 72.950434],
              [106.008381, 72.984011],
              [106.021717, 72.965803],
              [106.005519, 72.95694],
            ],
          ],
          [
            [
              [78.82998, 73.20736],
              [78.84296, 73.19906],
              [78.78582, 73.18618],
              [78.82963, 73.2278],
              [78.88675, 73.23849],
              [78.98554, 73.24216],
              [78.89621, 73.2336],
              [78.85189, 73.22318],
              [78.82998, 73.20736],
            ],
          ],
          [
            [
              [78.75, 72.774583],
              [78.62464, 72.79936],
              [78.60849, 72.81655],
              [78.61928, 72.8425],
              [78.67669, 72.87692],
              [78.72692, 72.89794],
              [78.96019, 72.98425],
              [79.0119, 73.00546],
              [79.10263, 73.06358],
              [79.16983, 73.08336],
              [79.24002, 73.06633],
              [79.31795, 73.03121],
              [79.38632, 73.01106],
              [79.40325, 72.98844],
              [79.47046, 72.94858],
              [79.51031, 72.93047],
              [79.53256, 72.90891],
              [79.49682, 72.9153],
              [79.47341, 72.9095],
              [79.5214, 72.89171],
              [79.50294, 72.88416],
              [79.52092, 72.8457],
              [79.50919, 72.83591],
              [79.5396, 72.81338],
              [79.50371, 72.7983],
              [79.52891, 72.78596],
              [79.53712, 72.76507],
              [79.57405, 72.75051],
              [79.5291, 72.73154],
              [79.53835, 72.71856],
              [79.42597, 72.71567],
              [79.39628, 72.70164],
              [79.35748, 72.70324],
              [79.27885, 72.71677],
              [79.21394, 72.72106],
              [79.04273, 72.72165],
              [78.95242, 72.7366],
              [78.88829, 72.733],
              [78.85044, 72.76124],
              [78.80761, 72.77292],
              [78.75, 72.774583],
            ],
          ],
          [
            [
              [86.39514, 73.19709],
              [86.36603, 73.20041],
              [86.33608, 73.21824],
              [86.36706, 73.24077],
              [86.40639, 73.21744],
              [86.41413, 73.19826],
              [86.39514, 73.19709],
            ],
          ],
          [
            [
              [80.28859, 73.42343],
              [80.32114, 73.42686],
              [80.33138, 73.4168],
              [80.28859, 73.42343],
            ],
          ],
          [
            [
              [80.45951, 73.43902],
              [80.56568, 73.42087],
              [80.47314, 73.43267],
              [80.45951, 73.43902],
            ],
          ],
          [
            [
              [80.15625, 73.464581],
              [80.19843, 73.46629],
              [80.18937, 73.44939],
              [80.15625, 73.464581],
            ],
          ],
          [
            [
              [111.32291, 73.852293],
              [111.226024, 73.838634],
              [111.234498, 73.82194],
              [111.168105, 73.827327],
              [111.132383, 73.821506],
              [111.056944, 73.823841],
              [110.991666, 73.812938],
              [110.907194, 73.768841],
              [110.883231, 73.762895],
              [110.779815, 73.770227],
              [110.705407, 73.791348],
              [110.676628, 73.793143],
              [110.598116, 73.750976],
              [110.507758, 73.749233],
              [110.456764, 73.733367],
              [110.390487, 73.722238],
              [110.332545, 73.701139],
              [110.296092, 73.679747],
              [110.25133, 73.6824],
              [110.124516, 73.703064],
              [109.949953, 73.69004],
              [109.906115, 73.696056],
              [109.780088, 73.689199],
              [109.747484, 73.677121],
              [109.766784, 73.652091],
              [109.670218, 73.674483],
              [109.580505, 73.726137],
              [109.555573, 73.765351],
              [109.543379, 73.801216],
              [109.549666, 73.817475],
              [109.724363, 73.840581],
              [109.838412, 73.868778],
              [109.879501, 73.892445],
              [109.9285, 73.968459],
              [109.971207, 73.997718],
              [110.108569, 74.009116],
              [110.321983, 74.016133],
              [110.459952, 74.005632],
              [110.585914, 73.990349],
              [110.609058, 73.981924],
              [110.667812, 73.977178],
              [110.72673, 73.957018],
              [110.78962, 73.948534],
              [110.87513, 73.925683],
              [110.875709, 73.918226],
              [110.975616, 73.906481],
              [110.983756, 73.900865],
              [111.046332, 73.90501],
              [111.095354, 73.936221],
              [111.06553, 73.960937],
              [111.101407, 74.001249],
              [111.150139, 74.02194],
              [111.219122, 74.036649],
              [111.297687, 74.044384],
              [111.464649, 74.051004],
              [111.557579, 74.047429],
              [111.600834, 74.013483],
              [111.481412, 73.99913],
              [111.422327, 73.998076],
              [111.381739, 73.982334],
              [111.258587, 73.995967],
              [111.228047, 73.987866],
              [111.215421, 73.970516],
              [111.218011, 73.944254],
              [111.249219, 73.90432],
              [111.32291, 73.852293],
            ],
          ],
          [
            [
              [84.64319, 73.860038],
              [84.715837, 73.867192],
              [84.726896, 73.85813],
              [84.680499, 73.840503],
              [84.646749, 73.837177],
              [84.60067, 73.847294],
              [84.64319, 73.860038],
            ],
          ],
          [
            [
              [87.12603, 73.77208],
              [87.16937, 73.77879],
              [87.22366, 73.82315],
              [87.26649, 73.80856],
              [87.35828, 73.79711],
              [87.34922, 73.77824],
              [87.29899, 73.78629],
              [87.24703, 73.73981],
              [87.22347, 73.73562],
              [87.20906, 73.71537],
              [87.14626, 73.68152],
              [87.15786, 73.66591],
              [87.21374, 73.67634],
              [87.21582, 73.65985],
              [87.13929, 73.62897],
              [87.02405, 73.61856],
              [86.9745, 73.62626],
              [86.99916, 73.63544],
              [87.11264, 73.64951],
              [87.11563, 73.663905],
              [87.04502, 73.65659],
              [86.89529, 73.63259],
              [86.8633, 73.64914],
              [86.89545, 73.66672],
              [86.98074, 73.68845],
              [86.98139, 73.70049],
              [87.07986, 73.71488],
              [87.13902, 73.74013],
              [87.17605, 73.76833],
              [87.12603, 73.77208],
            ],
          ],
          [
            [
              [87.12523, 73.7911],
              [87.12603, 73.77208],
              [87.09939, 73.7708],
              [87.12523, 73.7911],
            ],
          ],
          [
            [
              [86.77373, 73.97988],
              [86.90985, 73.97592],
              [86.94632, 73.96632],
              [86.9118, 73.9633],
              [86.82313, 73.96982],
              [86.75538, 73.96728],
              [86.75514, 73.956],
              [86.8727, 73.95782],
              [86.89349, 73.9511],
              [86.96101, 73.94795],
              [86.96934, 73.93386],
              [86.73677, 73.92199],
              [86.752812, 73.916107],
              [86.681008, 73.911297],
              [86.637744, 73.916296],
              [86.57456, 73.93781],
              [86.56301, 73.95709],
              [86.60352, 73.97387],
              [86.58041, 73.981],
              [86.60314, 73.99452],
              [86.64276, 74.00156],
              [86.6899, 73.99802],
              [86.71403, 73.97519],
              [86.77373, 73.97988],
            ],
          ],
          [
            [
              [82.685383, 74.051261],
              [82.678094, 74.047038],
              [82.602832, 74.055947],
              [82.489386, 74.058264],
              [82.449362, 74.071955],
              [82.437225, 74.104593],
              [82.529279, 74.134608],
              [82.636707, 74.145523],
              [82.645505, 74.135418],
              [82.724406, 74.112352],
              [82.828008, 74.088124],
              [82.77453, 74.081112],
              [82.740514, 74.061616],
              [82.685383, 74.051261],
            ],
          ],
          [
            [
              [86.70227, 74.10569],
              [86.78042, 74.09374],
              [86.7399, 74.08764],
              [86.71627, 74.07492],
              [86.62698, 74.08291],
              [86.59313, 74.09866],
              [86.62711, 74.11035],
              [86.5799, 74.12285],
              [86.63124, 74.12768],
              [86.62905, 74.11055],
              [86.70227, 74.10569],
            ],
          ],
          [
            [
              [86.54444, 74.11504],
              [86.478376, 74.11772],
              [86.5244, 74.12222],
              [86.54444, 74.11504],
            ],
          ],
          [
            [
              [86.36508, 74.09084],
              [86.41557, 74.08804],
              [86.40942, 74.07794],
              [86.36645, 74.0808],
              [86.36508, 74.09084],
            ],
          ],
          [
            [
              [82.944706, 74.075805],
              [83.022062, 74.100745],
              [83.111723, 74.120526],
              [83.275467, 74.135812],
              [83.373374, 74.134881],
              [83.463558, 74.128189],
              [83.60793, 74.099382],
              [83.630051, 74.086647],
              [83.626648, 74.067184],
              [83.558585, 74.077601],
              [83.509631, 74.090523],
              [83.405311, 74.091491],
              [83.359107, 74.059925],
              [83.373601, 74.051739],
              [83.448113, 74.062872],
              [83.432275, 74.053165],
              [83.349813, 74.051007],
              [83.277823, 74.056365],
              [83.071801, 74.090451],
              [83.015256, 74.090702],
              [82.944706, 74.075805],
            ],
          ],
          [
            [
              [86.65188, 74.0307],
              [86.67526, 74.0229],
              [86.56879, 74.01657],
              [86.50349, 74.02863],
              [86.57316, 74.04852],
              [86.65874, 74.05053],
              [86.67419, 74.04396],
              [86.62694, 74.03404],
              [86.65188, 74.0307],
            ],
          ],
          [
            [
              [84.93, 74.29872],
              [84.97744, 74.30322],
              [85.00463, 74.29659],
              [84.93, 74.29872],
            ],
          ],
          [
            [
              [85.12107, 74.31748],
              [85.19568, 74.32042],
              [85.24877, 74.31709],
              [85.29475, 74.30493],
              [85.21833, 74.31294],
              [85.17453, 74.30828],
              [85.17042, 74.29606],
              [85.12713, 74.29317],
              [85.05318, 74.2998],
              [85.13571, 74.30082],
              [85.12107, 74.31748],
            ],
          ],
          [
            [
              [85.14566, 74.37795],
              [85.15499, 74.38277],
              [85.26365, 74.37314],
              [85.24535, 74.36863],
              [85.14839, 74.3683],
              [85.14566, 74.37795],
            ],
          ],
          [
            [
              [85.21963, 74.3503],
              [85.26692, 74.34619],
              [85.24528, 74.33969],
              [85.15574, 74.34537],
              [85.17508, 74.35409],
              [85.21963, 74.3503],
            ],
          ],
          [
            [
              [85.54331, 74.129575],
              [85.47773, 74.125935],
              [85.44183, 74.14194],
              [85.52986, 74.141765],
              [85.54518, 74.149635],
              [85.60467, 74.151205],
              [85.58828, 74.169484],
              [85.526, 74.164905],
              [85.4768, 74.180384],
              [85.37895, 74.179685],
              [85.38911, 74.189464],
              [85.287269, 74.195229],
              [85.322009, 74.198639],
              [85.43063, 74.186704],
              [85.58686, 74.187694],
              [85.63682, 74.175114],
              [85.70144, 74.171294],
              [85.764, 74.17662],
              [85.73706, 74.16158],
              [85.7631, 74.151125],
              [85.7473, 74.141985],
              [85.62148, 74.137785],
              [85.58333, 74.128735],
              [85.54331, 74.129575],
            ],
          ],
          [
            [
              [85.493311, 74.408639],
              [85.42791, 74.40703],
              [85.39366, 74.3985],
              [85.37075, 74.40868],
              [85.30389, 74.41678],
              [85.25836, 74.41037],
              [85.19404, 74.41927],
              [85.311155, 74.437373],
              [85.3871, 74.43179],
              [85.45649, 74.43437],
              [85.48953, 74.42973],
              [85.54514, 74.43744],
              [85.55937, 74.42715],
              [85.60858, 74.42255],
              [85.57682, 74.40822],
              [85.493311, 74.408639],
            ],
          ],
          [
            [
              [79.523659, 74.53125],
              [79.56046, 74.51285],
              [79.50484, 74.51439],
              [79.34578, 74.53053],
              [79.2629, 74.54854],
              [79.21356, 74.57371],
              [79.22996, 74.616],
              [79.28181, 74.63941],
              [79.34052, 74.642529],
              [79.32824, 74.61983],
              [79.405003, 74.608022],
              [79.561298, 74.596893],
              [79.62013, 74.57455],
              [79.58212, 74.58082],
              [79.54424, 74.56972],
              [79.51863, 74.543763],
              [79.523659, 74.53125],
            ],
          ],
          [
            [
              [85.032455, 74.53125],
              [84.97974, 74.53024],
              [85.00009, 74.54626],
              [85.0443, 74.54829],
              [85.032455, 74.53125],
            ],
          ],
          [
            [
              [85.68262, 74.55126],
              [85.63261, 74.55936],
              [85.68916, 74.58301],
              [85.71898, 74.56035],
              [85.68262, 74.55126],
            ],
          ],
          [
            [
              [84.88329, 74.53125],
              [84.87285, 74.52458],
              [84.81082, 74.53577],
              [84.8583, 74.56719],
              [84.92918, 74.57389],
              [84.959, 74.58923],
              [85.00124, 74.59257],
              [85.01264, 74.58059],
              [85.1009, 74.58321],
              [85.13238, 74.57111],
              [85.04975, 74.57518],
              [84.97393, 74.57207],
              [84.91237, 74.55652],
              [84.88329, 74.53125],
            ],
          ],
          [
            [
              [84.237591, 74.46146],
              [84.305168, 74.454493],
              [84.347801, 74.437629],
              [84.237591, 74.46146],
            ],
          ],
          [
            [
              [84.76374, 74.41231],
              [84.73932, 74.40965],
              [84.674209, 74.423503],
              [84.59534, 74.42113],
              [84.45359, 74.44373],
              [84.44603, 74.45727],
              [84.5036, 74.47173],
              [84.58121, 74.48123],
              [84.61881, 74.49521],
              [84.74976, 74.50276],
              [84.84961, 74.5118],
              [84.89293, 74.50093],
              [84.91987, 74.51313],
              [84.95142, 74.51283],
              [84.98284, 74.4987],
              [84.95752, 74.47971],
              [84.90874, 74.47967],
              [84.88519, 74.46933],
              [84.80487, 74.46906],
              [84.75963, 74.47572],
              [84.66264, 74.47786],
              [84.590374, 74.471879],
              [84.583657, 74.46375],
              [84.738916, 74.445917],
              [84.748063, 74.430507],
              [84.814037, 74.424925],
              [84.83244, 74.411],
              [84.76374, 74.41231],
            ],
          ],
          [
            [
              [85.46531, 74.57814],
              [85.5221, 74.56802],
              [85.56686, 74.53983],
              [85.66706, 74.53762],
              [85.65038, 74.51517],
              [85.56532, 74.50451],
              [85.47216, 74.50039],
              [85.48668, 74.48396],
              [85.47162, 74.46788],
              [85.43553, 74.45503],
              [85.36437, 74.44978],
              [85.28754, 74.4623],
              [85.27834, 74.47784],
              [85.1787, 74.48076],
              [85.15741, 74.52187],
              [85.20245, 74.5561],
              [85.28865, 74.57622],
              [85.35454, 74.56739],
              [85.39344, 74.57239],
              [85.45102, 74.56843],
              [85.46531, 74.57814],
            ],
          ],
          [
            [
              [86.17806, 74.61064],
              [86.18095, 74.60622],
              [86.16604, 74.5858],
              [86.056808, 74.593129],
              [86.04297, 74.58375],
              [86.09001, 74.57422],
              [86.11624, 74.55709],
              [86.17701, 74.54286],
              [86.22654, 74.5444],
              [86.26295, 74.5238],
              [86.22168, 74.52092],
              [86.18057, 74.48625],
              [86.12532, 74.48575],
              [86.1192, 74.51126],
              [86.10022, 74.51939],
              [85.973786, 74.530946],
              [85.951914, 74.518185],
              [86.000443, 74.514575],
              [86.055209, 74.492872],
              [86.031137, 74.480068],
              [85.9453, 74.484792],
              [85.90457, 74.47023],
              [85.85707, 74.46821],
              [85.86615, 74.45725],
              [85.72926, 74.4533],
              [85.77084, 74.46291],
              [85.76343, 74.47806],
              [85.68198, 74.47442],
              [85.66429, 74.4826],
              [85.75607, 74.5078],
              [85.772569, 74.525983],
              [85.75691, 74.55058],
              [85.79634, 74.56637],
              [85.79703, 74.57662],
              [85.94553, 74.58644],
              [85.97485, 74.59387],
              [86.01807, 74.58726],
              [86.067252, 74.606286],
              [86.17806, 74.61064],
            ],
          ],
          [
            [
              [85.724163, 74.653963],
              [85.669936, 74.658518],
              [85.699954, 74.670612],
              [85.724163, 74.653963],
            ],
          ],
          [
            [
              [85.594563, 74.83324],
              [85.63804, 74.844323],
              [85.692968, 74.848427],
              [85.635114, 74.829561],
              [85.594563, 74.83324],
            ],
          ],
          [
            [
              [85.55422, 74.71772],
              [85.5556, 74.70745],
              [85.4884, 74.7202],
              [85.45229, 74.71618],
              [85.43748, 74.72902],
              [85.36141, 74.74895],
              [85.30448, 74.74364],
              [85.28745, 74.73475],
              [85.17444, 74.7338],
              [85.15314, 74.74507],
              [85.09594, 74.75315],
              [85.18326, 74.7522],
              [85.20935, 74.76234],
              [85.28923, 74.76954],
              [85.33493, 74.76572],
              [85.40021, 74.77611],
              [85.45919, 74.79825],
              [85.51265, 74.80078],
              [85.51679, 74.78815],
              [85.57774, 74.7864],
              [85.63014, 74.792],
              [85.65918, 74.76348],
              [85.65226, 74.75337],
              [85.70808, 74.72378],
              [85.67331, 74.70503],
              [85.63491, 74.713],
              [85.64059, 74.74196],
              [85.57883, 74.74207],
              [85.55422, 74.71772],
            ],
          ],
          [
            [
              [85.238199, 74.802365],
              [85.319216, 74.806369],
              [85.296302, 74.798467],
              [85.238199, 74.802365],
            ],
          ],
          [
            [
              [81.728491, 75.198004],
              [81.840703, 75.174919],
              [81.927739, 75.149463],
              [82.028231, 75.111198],
              [82.089872, 75.132486],
              [82.145002, 75.122069],
              [82.143265, 75.076191],
              [82.155854, 75.066462],
              [82.096166, 75.077979],
              [81.958559, 75.130426],
              [81.854594, 75.164919],
              [81.728491, 75.198004],
            ],
          ],
          [
            [
              [85.11743, 74.84929],
              [85.20772, 74.86632],
              [85.22932, 74.85747],
              [85.11743, 74.84929],
            ],
          ],
          [
            [
              [86.67098, 74.74552],
              [86.57659, 74.74044],
              [86.53733, 74.74782],
              [86.55872, 74.75999],
              [86.53012, 74.77967],
              [86.46809, 74.78375],
              [86.45633, 74.79198],
              [86.49812, 74.79687],
              [86.50526, 74.80875],
              [86.45673, 74.82095],
              [86.5117, 74.82962],
              [86.56045, 74.82626],
              [86.5766, 74.83303],
              [86.54585, 74.84841],
              [86.49518, 74.84656],
              [86.4182, 74.83393],
              [86.343125, 74.830085],
              [86.37288, 74.83886],
              [86.280535, 74.864805],
              [86.27549, 74.88089],
              [86.22488, 74.88667],
              [86.27649, 74.90394],
              [86.264519, 74.923298],
              [86.305955, 74.918917],
              [86.32288, 74.93286],
              [86.37662, 74.92723],
              [86.31832, 74.91681],
              [86.37542, 74.90829],
              [86.421666, 74.914082],
              [86.41611, 74.9036],
              [86.45375, 74.89873],
              [86.46238, 74.88698],
              [86.51867, 74.89318],
              [86.57613, 74.888982],
              [86.64365, 74.89331],
              [86.65955, 74.90446],
              [86.591545, 74.907362],
              [86.51993, 74.9297],
              [86.56649, 74.93985],
              [86.51778, 74.97592],
              [86.56005, 74.97798],
              [86.71501, 74.97439],
              [86.7146, 74.96529],
              [86.64532, 74.95702],
              [86.63623, 74.94839],
              [86.677966, 74.941539],
              [86.69862, 74.94889],
              [86.82256, 74.96064],
              [86.80979, 74.96865],
              [86.89302, 74.97165],
              [86.9229, 74.97986],
              [86.97513, 74.97927],
              [87.010498, 74.991037],
              [87.064724, 74.97694],
              [87.091308, 74.961864],
              [87.075119, 74.955836],
              [87.180443, 74.94633],
              [87.168761, 74.917933],
              [87.088503, 74.882325],
              [87.066702, 74.888969],
              [86.99503, 74.8707],
              [86.94122, 74.87134],
              [86.875401, 74.852415],
              [86.810694, 74.850463],
              [86.834277, 74.839518],
              [86.899464, 74.835686],
              [86.960801, 74.847754],
              [87.001934, 74.835762],
              [86.969817, 74.813731],
              [86.87557, 74.8021],
              [86.86323, 74.82513],
              [86.77793, 74.82888],
              [86.73504, 74.824687],
              [86.63769, 74.83395],
              [86.58292, 74.82608],
              [86.68853, 74.80959],
              [86.68699, 74.8027],
              [86.7824, 74.80413],
              [86.81063, 74.79853],
              [86.81102, 74.78389],
              [86.75953, 74.76623],
              [86.71844, 74.7633],
              [86.62918, 74.77953],
              [86.63101, 74.76435],
              [86.67069, 74.76079],
              [86.67098, 74.74552],
            ],
          ],
          [
            [
              [85.656958, 74.879565],
              [85.683406, 74.874341],
              [85.628594, 74.868208],
              [85.580395, 74.87267],
              [85.513554, 74.87017],
              [85.534152, 74.880596],
              [85.589355, 74.885861],
              [85.656958, 74.879565],
            ],
          ],
          [
            [
              [86.349487, 74.947169],
              [86.319207, 74.943499],
              [86.300985, 74.962422],
              [86.379493, 74.953062],
              [86.349487, 74.947169],
            ],
          ],
          [
            [
              [88.03073, 75.63073],
              [87.9094, 75.62791],
              [87.89257, 75.63472],
              [88.03165, 75.64177],
              [88.03073, 75.63073],
            ],
          ],
          [
            [
              [92.7519, 75.80118],
              [92.78822, 75.80862],
              [92.83095, 75.80597],
              [92.7519, 75.80118],
            ],
          ],
          [
            [
              [92.8125, 75.824932],
              [92.77763, 75.81998],
              [92.65357, 75.82149],
              [92.68866, 75.83789],
              [92.79705, 75.8472],
              [92.85116, 75.86588],
              [92.88885, 75.85875],
              [92.88789, 75.84751],
              [92.99232, 75.8409],
              [92.89841, 75.83628],
              [92.89019, 75.82095],
              [92.85257, 75.83],
              [92.8125, 75.824932],
            ],
          ],
          [
            [
              [93.35953, 75.85417],
              [93.31292, 75.84861],
              [93.31656, 75.87211],
              [93.41481, 75.86915],
              [93.35953, 75.85417],
            ],
          ],
          [
            [
              [86.19663, 75.28012],
              [86.22057, 75.28865],
              [86.3469, 75.29115],
              [86.2647, 75.27824],
              [86.19663, 75.28012],
            ],
          ],
          [
            [
              [84.21436, 74.65209],
              [84.24338, 74.66165],
              [84.28541, 74.65464],
              [84.24612, 74.64217],
              [84.23972, 74.62512],
              [84.21436, 74.65209],
            ],
          ],
          [
            [
              [92.64254, 75.97256],
              [92.57402, 75.97436],
              [92.57598, 75.99684],
              [92.69143, 76.00136],
              [92.69576, 75.97123],
              [92.64254, 75.97256],
            ],
          ],
          [
            [
              [96.45504, 76.08041],
              [96.50644, 76.07764],
              [96.47927, 76.07086],
              [96.45504, 76.08041],
            ],
          ],
          [
            [
              [93.84111, 76.16715],
              [93.91372, 76.18351],
              [93.90095, 76.16772],
              [93.84111, 76.16715],
            ],
          ],
          [
            [
              [94.69326, 76.2724],
              [94.79066, 76.27657],
              [94.74829, 76.26487],
              [94.69326, 76.2724],
            ],
          ],
          [
            [
              [99.35418, 76.21581],
              [99.4147, 76.22914],
              [99.48907, 76.22437],
              [99.46806, 76.20925],
              [99.40366, 76.21915],
              [99.35418, 76.21581],
            ],
          ],
          [
            [
              [95.19244, 76.15374],
              [95.12139, 76.14976],
              [95.01208, 76.16524],
              [95.06279, 76.17964],
              [95.09945, 76.18164],
              [95.09822, 76.19494],
              [95.16591, 76.21045],
              [95.21431, 76.20466],
              [95.23599, 76.18606],
              [95.173539, 76.176478],
              [95.2653, 76.16202],
              [95.19244, 76.15374],
            ],
          ],
          [
            [
              [94.921875, 76.185734],
              [94.91269, 76.17676],
              [94.84199, 76.18803],
              [94.72645, 76.19679],
              [94.66946, 76.19003],
              [94.64131, 76.19648],
              [94.572043, 76.196187],
              [94.52257, 76.18727],
              [94.44959, 76.19525],
              [94.51439, 76.22066],
              [94.57339, 76.21135],
              [94.61915, 76.22716],
              [94.74343, 76.21711],
              [94.78945, 76.2081],
              [94.85425, 76.22014],
              [94.80829, 76.22968],
              [94.83404, 76.2461],
              [94.79027, 76.25664],
              [94.88112, 76.25265],
              [94.96587, 76.26681],
              [94.95945, 76.27862],
              [95.08796, 76.27731],
              [95.08348, 76.26892],
              [95.0075, 76.26443],
              [94.99973, 76.25147],
              [94.87669, 76.23965],
              [94.93993, 76.21156],
              [94.9009, 76.20037],
              [94.921875, 76.185734],
            ],
          ],
          [
            [
              [91.87358, 76.05247],
              [91.9317, 76.05439],
              [91.86511, 76.03915],
              [91.71324, 76.05641],
              [91.78449, 76.05359],
              [91.81467, 76.06011],
              [91.87358, 76.05247],
            ],
          ],
          [
            [
              [96.8667, 76.38905],
              [96.88594, 76.37237],
              [96.85735, 76.36718],
              [96.81337, 76.38884],
              [96.8667, 76.38905],
            ],
          ],
          [
            [
              [113.148717, 76.463528],
              [113.154291, 76.443942],
              [113.105639, 76.467916],
              [113.148717, 76.463528],
            ],
          ],
          [
            [
              [95.13024, 76.39937],
              [95.19622, 76.40442],
              [95.20877, 76.3877],
              [95.13024, 76.39937],
            ],
          ],
          [
            [
              [94.83599, 76.4086],
              [94.97056, 76.41473],
              [95.03487, 76.43057],
              [95.03206, 76.44107],
              [95.10008, 76.45125],
              [95.15118, 76.4503],
              [95.10995, 76.43736],
              [95.11623, 76.42147],
              [95.0183, 76.41397],
              [94.98824, 76.40156],
              [94.93563, 76.41373],
              [94.83599, 76.4086],
            ],
          ],
          [
            [
              [95.21562, 76.46457],
              [95.27743, 76.47527],
              [95.38246, 76.45716],
              [95.4313, 76.45567],
              [95.38691, 76.43871],
              [95.44479, 76.4333],
              [95.36714, 76.4251],
              [95.32296, 76.46668],
              [95.21562, 76.46457],
            ],
          ],
          [
            [
              [111.796267, 76.604439],
              [111.741242, 76.613107],
              [111.741622, 76.625047],
              [111.807405, 76.618918],
              [111.85794, 76.633234],
              [111.893026, 76.621357],
              [111.839452, 76.619725],
              [111.822389, 76.600137],
              [111.796267, 76.604439],
            ],
          ],
          [
            [
              [96.44612, 76.56404],
              [96.51026, 76.5652],
              [96.46354, 76.55024],
              [96.44612, 76.56404],
            ],
          ],
          [
            [
              [95.33808, 76.51074],
              [95.327745, 76.521306],
              [95.37837, 76.54104],
              [95.43324, 76.5533],
              [95.47, 76.53786],
              [95.43443, 76.51673],
              [95.36104, 76.49775],
              [95.33808, 76.51074],
            ],
          ],
          [
            [
              [96.04918, 76.54061],
              [95.99835, 76.53419],
              [96.03581, 76.56456],
              [96.07934, 76.55265],
              [96.04918, 76.54061],
            ],
          ],
          [
            [
              [95.625, 76.524242],
              [95.58074, 76.51963],
              [95.55785, 76.53213],
              [95.59387, 76.54275],
              [95.64275, 76.53714],
              [95.625, 76.524242],
            ],
          ],
          [
            [
              [96.68162, 76.30817],
              [96.64517, 76.31549],
              [96.59858, 76.31092],
              [96.53518, 76.32513],
              [96.42821, 76.32561],
              [96.36065, 76.33254],
              [96.37657, 76.34012],
              [96.45045, 76.34115],
              [96.4836, 76.34812],
              [96.54941, 76.33427],
              [96.6033, 76.3459],
              [96.67444, 76.34039],
              [96.7291, 76.34226],
              [96.73838, 76.35262],
              [96.77197, 76.32857],
              [96.73359, 76.31865],
              [96.70521, 76.29323],
              [96.65641, 76.29652],
              [96.68162, 76.30817],
            ],
          ],
          [
            [
              [96.08902, 76.33234],
              [96.10123, 76.34345],
              [96.23688, 76.34939],
              [96.24542, 76.33955],
              [96.20172, 76.32606],
              [96.12908, 76.31803],
              [96.08902, 76.33234],
            ],
          ],
          [
            [
              [97.06059, 76.23444],
              [97.07558, 76.21755],
              [97.00795, 76.21955],
              [96.85478, 76.19959],
              [96.81925, 76.17737],
              [96.7606, 76.16624],
              [96.68055, 76.17922],
              [96.70646, 76.20648],
              [96.77601, 76.24969],
              [96.77243, 76.26638],
              [96.80667, 76.26944],
              [96.86243, 76.3113],
              [96.93501, 76.31376],
              [96.84192, 76.33889],
              [96.85599, 76.35057],
              [96.93613, 76.34266],
              [96.97562, 76.32156],
              [97.06011, 76.33299],
              [97.08113, 76.32344],
              [97.05031, 76.3105],
              [97.12445, 76.29431],
              [97.10287, 76.27969],
              [97.05929, 76.27823],
              [97.07154, 76.26472],
              [96.98534, 76.261],
              [97.00505, 76.24328],
              [97.06059, 76.23444],
            ],
          ],
          [
            [
              [94.5466, 76.3477],
              [94.61546, 76.35365],
              [94.62746, 76.34514],
              [94.58747, 76.33607],
              [94.5466, 76.3477],
            ],
          ],
          [
            [
              [95.06789, 76.371],
              [95.09194, 76.37593],
              [95.11096, 76.35839],
              [95.07673, 76.35765],
              [95.06789, 76.371],
            ],
          ],
          [
            [
              [113.39915, 76.370897],
              [113.409445, 76.364365],
              [113.481504, 76.366699],
              [113.4819, 76.352786],
              [113.450621, 76.34718],
              [113.329071, 76.3526],
              [113.200789, 76.351105],
              [113.161592, 76.374722],
              [113.226921, 76.421279],
              [113.257407, 76.43085],
              [113.307295, 76.426483],
              [113.333426, 76.411608],
              [113.272849, 76.397182],
              [113.295021, 76.380037],
              [113.383709, 76.380783],
              [113.39915, 76.370897],
            ],
          ],
          [
            [
              [92.12529, 75.9042],
              [92.15706, 75.91656],
              [92.229, 75.92524],
              [92.23903, 75.91429],
              [92.18851, 75.91719],
              [92.12529, 75.9042],
            ],
          ],
          [
            [
              [93.724742, 78.185655],
              [93.789538, 78.181276],
              [93.783883, 78.159401],
              [93.817241, 78.146334],
              [93.76813, 78.139795],
              [93.667337, 78.158249],
              [93.65034, 78.17376],
              [93.669108, 78.195943],
              [93.724742, 78.185655],
            ],
          ],
          [
            [
              [106.592142, 78.320931],
              [106.687517, 78.325391],
              [106.712335, 78.313323],
              [106.785272, 78.304477],
              [106.820658, 78.270085],
              [106.812494, 78.252141],
              [106.764338, 78.246652],
              [106.725328, 78.267075],
              [106.672541, 78.259361],
              [106.558938, 78.253755],
              [106.484538, 78.235033],
              [106.603105, 78.23013],
              [106.562639, 78.216015],
              [106.29838, 78.179115],
              [106.231157, 78.18518],
              [106.152689, 78.20206],
              [106.144664, 78.238264],
              [106.182962, 78.247056],
              [106.278299, 78.244527],
              [106.365542, 78.249851],
              [106.389634, 78.238342],
              [106.473338, 78.265868],
              [106.471841, 78.293899],
              [106.564036, 78.325239],
              [106.592142, 78.320931],
            ],
          ],
          [
            [
              [106.85634, 78.26447],
              [106.832881, 78.265884],
              [106.83124, 78.29603],
              [106.782824, 78.321747],
              [106.845186, 78.30832],
              [106.865739, 78.28349],
              [106.85634, 78.26447],
            ],
          ],
          [
            [
              [106.705714, 78.347221],
              [106.645242, 78.354668],
              [106.664439, 78.362481],
              [106.705714, 78.347221],
            ],
          ],
          [
            [
              [98.821138, 78.548491],
              [98.759036, 78.55069],
              [98.746537, 78.561903],
              [98.827936, 78.562904],
              [98.821138, 78.548491],
            ],
          ],
          [
            [
              [98.753039, 78.589537],
              [98.715541, 78.607357],
              [98.762858, 78.618953],
              [98.780435, 78.64049],
              [98.822236, 78.625574],
              [98.794413, 78.602209],
              [98.753039, 78.589537],
            ],
          ],
          [
            [
              [98.805712, 78.479734],
              [98.739137, 78.484514],
              [98.811641, 78.489823],
              [98.805712, 78.479734],
            ],
          ],
          [
            [
              [104.370809, 79.043753],
              [104.337629, 79.032638],
              [104.263808, 79.024388],
              [104.14063, 79.018102],
              [104.166311, 79.032073],
              [104.284826, 79.035243],
              [104.351126, 79.051386],
              [104.370809, 79.043753],
            ],
          ],
          [
            [
              [103.368826, 79.214236],
              [103.38346, 79.19174],
              [103.43511, 79.20143],
              [103.435964, 79.166774],
              [103.305295, 79.170515],
              [103.279509, 79.200631],
              [103.326306, 79.19504],
              [103.345182, 79.216317],
              [103.368826, 79.214236],
            ],
          ],
          [
            [
              [103.438811, 79.119548],
              [103.404462, 79.117704],
              [103.412467, 79.135151],
              [103.438811, 79.119548],
            ],
          ],
          [
            [
              [103.369008, 79.131325],
              [103.332808, 79.142616],
              [103.38281, 79.144407],
              [103.369008, 79.131325],
            ],
          ],
          [
            [
              [77.34375, 79.524259],
              [77.44114, 79.514483],
              [77.577661, 79.509593],
              [77.62034, 79.486856],
              [77.60483, 79.477286],
              [77.480745, 79.466156],
              [77.07734, 79.463728],
              [76.941141, 79.465827],
              [76.843462, 79.458594],
              [76.79104, 79.46052],
              [76.711657, 79.474527],
              [76.699801, 79.515996],
              [76.646342, 79.523694],
              [76.56634, 79.51258],
              [76.501603, 79.520271],
              [76.47194, 79.508272],
              [76.394274, 79.511679],
              [76.31685, 79.526612],
              [76.133746, 79.586907],
              [76.131517, 79.625867],
              [76.187051, 79.636852],
              [76.271905, 79.634155],
              [76.410989, 79.616275],
              [76.398187, 79.606062],
              [76.318605, 79.601702],
              [76.367105, 79.590603],
              [76.424737, 79.59087],
              [76.467439, 79.605184],
              [76.53174, 79.611493],
              [76.66974, 79.606085],
              [76.753739, 79.591872],
              [76.88038, 79.584317],
              [77.082635, 79.555926],
              [77.261775, 79.537457],
              [77.34375, 79.524259],
            ],
          ],
          [
            [
              [91.210839, 79.495663],
              [91.160737, 79.493662],
              [91.030961, 79.516354],
              [91.058215, 79.520112],
              [91.210839, 79.495663],
            ],
          ],
          [
            [
              [91.40625, 79.501909],
              [91.450739, 79.493562],
              [91.394715, 79.489521],
              [91.264636, 79.510282],
              [90.995737, 79.538909],
              [90.9033, 79.538847],
              [90.841052, 79.531591],
              [90.702241, 79.536908],
              [90.644442, 79.547921],
              [90.893137, 79.549427],
              [90.934604, 79.555873],
              [91.056239, 79.540812],
              [91.315545, 79.517577],
              [91.40625, 79.501909],
            ],
          ],
          [
            [
              [100.170495, 79.560067],
              [100.120439, 79.566038],
              [100.189661, 79.571692],
              [100.170495, 79.560067],
            ],
          ],
          [
            [
              [100.218736, 79.698789],
              [100.186242, 79.673056],
              [100.281642, 79.680275],
              [100.352541, 79.675867],
              [100.393853, 79.664609],
              [100.304734, 79.653839],
              [100.180635, 79.652342],
              [100.118212, 79.636907],
              [100.051638, 79.58476],
              [100.011836, 79.581561],
              [99.999018, 79.602818],
              [100.087038, 79.656742],
              [100.073329, 79.67042],
              [100.113139, 79.693886],
              [100.077837, 79.706403],
              [100.163134, 79.710216],
              [100.218736, 79.698789],
            ],
          ],
          [
            [
              [91.64844, 79.429867],
              [91.56241, 79.445579],
              [91.48424, 79.449308],
              [91.543641, 79.456814],
              [91.473047, 79.479616],
              [91.492739, 79.483351],
              [91.605241, 79.464926],
              [91.659243, 79.435391],
              [91.847842, 79.417669],
              [91.64844, 79.429867],
            ],
          ],
          [
            [
              [97.891825, 79.839528],
              [97.820909, 79.852543],
              [97.867135, 79.853972],
              [97.891825, 79.839528],
            ],
          ],
          [
            [
              [94.348131, 79.917139],
              [94.329545, 79.929878],
              [94.386933, 79.925098],
              [94.348131, 79.917139],
            ],
          ],
          [
            [
              [94.576341, 79.977808],
              [94.515634, 79.976097],
              [94.558215, 79.987531],
              [94.576341, 79.977808],
            ],
          ],
          [
            [
              [92.8125, 79.044598],
              [92.861184, 79.031077],
              [92.729035, 79.039946],
              [92.566629, 79.063311],
              [92.468241, 79.071285],
              [92.301873, 79.097995],
              [92.374468, 79.099156],
              [92.42744, 79.0853],
              [92.499743, 79.083994],
              [92.493685, 79.076745],
              [92.63624, 79.058467],
              [92.697039, 79.057864],
              [92.8125, 79.044598],
            ],
          ],
          [
            [
              [97.935366, 78.75],
              [97.85454, 78.74611],
              [97.89554, 78.765532],
              [97.937341, 78.766733],
              [97.935366, 78.75],
            ],
          ],
          [
            [
              [98.712642, 78.774744],
              [98.676629, 78.760974],
              [98.625795, 78.76645],
              [98.565608, 78.761637],
              [98.631236, 78.78155],
              [98.712642, 78.774744],
            ],
          ],
          [
            [
              [96.831995, 78.952825],
              [96.858079, 78.961977],
              [96.882769, 78.949656],
              [96.831995, 78.952825],
            ],
          ],
          [
            [
              [99.84375, 79.226852],
              [99.879443, 79.214742],
              [99.777162, 79.228414],
              [99.803842, 79.239574],
              [99.84375, 79.226852],
            ],
          ],
          [
            [
              [92.8125, 79.422849],
              [92.933023, 79.427004],
              [93.055018, 79.415632],
              [93.087443, 79.403655],
              [93.070856, 79.386622],
              [92.881838, 79.373125],
              [92.815629, 79.37945],
              [92.758539, 79.39445],
              [92.738717, 79.411262],
              [92.8125, 79.422849],
            ],
          ],
          [
            [
              [100.088968, 79.433007],
              [100.162142, 79.43449],
              [100.15652, 79.407449],
              [100.064661, 79.389485],
              [100.007884, 79.391792],
              [100.032441, 79.427583],
              [100.064356, 79.443526],
              [100.088968, 79.433007],
            ],
          ],
          [
            [
              [92.524801, 79.435054],
              [92.567841, 79.429685],
              [92.558328, 79.417968],
              [92.430993, 79.414347],
              [92.381716, 79.404449],
              [92.396662, 79.388265],
              [92.371576, 79.377807],
              [92.305041, 79.376929],
              [92.15388, 79.395107],
              [91.872796, 79.416019],
              [92.081132, 79.414714],
              [92.161738, 79.41027],
              [92.360536, 79.433091],
              [92.463767, 79.432754],
              [92.514437, 79.441997],
              [92.68333, 79.440103],
              [92.800935, 79.434207],
              [92.76784, 79.43099],
              [92.524801, 79.435054],
            ],
          ],
          [
            [
              [92.8125, 80.475604],
              [92.709139, 80.461063],
              [92.681657, 80.466546],
              [92.726412, 80.487903],
              [92.833841, 80.495999],
              [92.8125, 80.475604],
            ],
          ],
          [
            [
              [97.494439, 80.236303],
              [97.443339, 80.244613],
              [97.494439, 80.259629],
              [97.494439, 80.236303],
            ],
          ],
          [
            [
              [78.75, 80.817655],
              [78.675441, 80.821969],
              [78.619036, 80.836887],
              [78.674073, 80.87329],
              [78.799211, 80.915865],
              [78.880137, 80.932095],
              [79.058939, 80.939901],
              [79.250635, 80.957126],
              [79.439357, 80.95491],
              [79.824519, 80.930797],
              [79.946435, 80.910067],
              [79.95324, 80.881639],
              [79.825212, 80.85599],
              [79.540842, 80.826873],
              [79.294907, 80.819028],
              [78.953294, 80.814027],
              [78.75, 80.817655],
            ],
          ],
          [
            [
              [93.343217, 81.065143],
              [93.240519, 81.064112],
              [93.200937, 81.07262],
              [93.312341, 81.073355],
              [93.343217, 81.065143],
            ],
          ],
          [
            [
              [91.40625, 81.181571],
              [91.614137, 81.167967],
              [91.661639, 81.158252],
              [91.666247, 81.126378],
              [91.735941, 81.102593],
              [91.69594, 81.088371],
              [91.604075, 81.072652],
              [91.562442, 81.058339],
              [91.433741, 81.035515],
              [91.354272, 81.027625],
              [91.09652, 81.019483],
              [90.823655, 81.022203],
              [90.725634, 81.025623],
              [90.604035, 81.039517],
              [90.407335, 81.046223],
              [90.219217, 81.058941],
              [90.113855, 81.079687],
              [90.091143, 81.095185],
              [90.11004, 81.12492],
              [90.179323, 81.159338],
              [90.25174, 81.172871],
              [90.708039, 81.189592],
              [90.972711, 81.190951],
              [91.06634, 81.200904],
              [91.168336, 81.190294],
              [91.312357, 81.188928],
              [91.40625, 81.181571],
            ],
          ],
          [
            [
              [98.05141, 80.07407],
              [97.999657, 80.081914],
              [97.991717, 80.09487],
              [98.073442, 80.09775],
              [98.086741, 80.085031],
              [98.05141, 80.07407],
            ],
          ],
          [
            [
              [91.40625, 79.689206],
              [91.351106, 79.697422],
              [91.291491, 79.718419],
              [91.305635, 79.738836],
              [91.432435, 79.731931],
              [91.723939, 79.724819],
              [91.956019, 79.725928],
              [91.859103, 79.711384],
              [91.905543, 79.7026],
              [91.99563, 79.700676],
              [92.04204, 79.690082],
              [92.056214, 79.707184],
              [92.160851, 79.741111],
              [92.276103, 79.741975],
              [92.305574, 79.727959],
              [92.380412, 79.72573],
              [92.418047, 79.737857],
              [92.415841, 79.75706],
              [92.367022, 79.776651],
              [92.198908, 79.804928],
              [92.026079, 79.810772],
              [91.943139, 79.797107],
              [91.892939, 79.806913],
              [91.73714, 79.815324],
              [91.622636, 79.812421],
              [91.302743, 79.827125],
              [91.235942, 79.847953],
              [91.235041, 79.861481],
              [91.309656, 79.881629],
              [91.348939, 79.87529],
              [91.436234, 79.886708],
              [91.45604, 79.908829],
              [91.391436, 79.92515],
              [91.268939, 79.939763],
              [91.259768, 79.952152],
              [91.366839, 79.964296],
              [91.374269, 79.991754],
              [91.283074, 79.994672],
              [91.253739, 80.026988],
              [91.211908, 80.033886],
              [91.014077, 80.049324],
              [91.031884, 80.056886],
              [91.336465, 80.054052],
              [91.570574, 80.060192],
              [91.686579, 80.053295],
              [91.932824, 80.065255],
              [91.986605, 80.045261],
              [92.059236, 80.036476],
              [92.186968, 80.034384],
              [92.28377, 80.027938],
              [92.32004, 80.032168],
              [92.531938, 80.029366],
              [92.564142, 80.013049],
              [92.783938, 80.012951],
              [93.020838, 80.015349],
              [93.174716, 79.999843],
              [93.374049, 79.9885],
              [93.603465, 79.950761],
              [93.670337, 79.931154],
              [93.829835, 79.92215],
              [93.910523, 79.909187],
              [93.907039, 79.892711],
              [93.82924, 79.863284],
              [93.75004, 79.852267],
              [93.767909, 79.835189],
              [93.74444, 79.822434],
              [93.680041, 79.81753],
              [93.516839, 79.7948],
              [93.435107, 79.801652],
              [93.411662, 79.793638],
              [93.509133, 79.784076],
              [93.416995, 79.760076],
              [93.318338, 79.751255],
              [93.249535, 79.719818],
              [93.145076, 79.700761],
              [92.917162, 79.687301],
              [92.747996, 79.672643],
              [92.502298, 79.66583],
              [92.324275, 79.668191],
              [92.219537, 79.678671],
              [92.119638, 79.676967],
              [91.930941, 79.650638],
              [91.787967, 79.653762],
              [91.775942, 79.637821],
              [91.649042, 79.658147],
              [91.40625, 79.689206],
            ],
          ],
          [
            [
              [94.21875, 79.984355],
              [94.125635, 79.967595],
              [94.001101, 79.965252],
              [93.992907, 79.970399],
              [94.064639, 80.003343],
              [94.30083, 80.037195],
              [94.370134, 80.032275],
              [94.403535, 80.016953],
              [94.334939, 80.007245],
              [94.311838, 79.984215],
              [94.21875, 79.984355],
            ],
          ],
          [
            [
              [92.8125, 80.024087],
              [92.672442, 80.019153],
              [92.618639, 80.024561],
              [92.70463, 80.03811],
              [92.833773, 80.034696],
              [92.8125, 80.024087],
            ],
          ],
          [
            [
              [99.84375, 79.087735],
              [99.944323, 79.08161],
              [99.983775, 79.060867],
              [99.968042, 79.048057],
              [100.005137, 79.03734],
              [99.948717, 79.01348],
              [100.006853, 78.980605],
              [100.012353, 78.959524],
              [100.060937, 78.93493],
              [100.045298, 78.921098],
              [99.982339, 78.898986],
              [99.749879, 78.865225],
              [99.686633, 78.842426],
              [99.606936, 78.830304],
              [99.57599, 78.801736],
              [99.430636, 78.803174],
              [99.406839, 78.81179],
              [99.312136, 78.80458],
              [99.190043, 78.788158],
              [98.948443, 78.79407],
              [98.746042, 78.791366],
              [98.667041, 78.797773],
              [98.597741, 78.787761],
              [98.573642, 78.77324],
              [98.463213, 78.758215],
              [98.404742, 78.758858],
              [98.350571, 78.788471],
              [98.295717, 78.795704],
              [98.267435, 78.788471],
              [98.1951, 78.794481],
              [98.15584, 78.813889],
              [98.113138, 78.809483],
              [98.156138, 78.796774],
              [98.072802, 78.797429],
              [98.016268, 78.813531],
              [97.941628, 78.817617],
              [97.844186, 78.810834],
              [97.807025, 78.802348],
              [97.678042, 78.795971],
              [97.591637, 78.806083],
              [97.576638, 78.816693],
              [97.468637, 78.822],
              [97.418352, 78.83711],
              [97.386385, 78.86492],
              [97.403139, 78.874658],
              [97.318636, 78.867051],
              [97.240236, 78.874658],
              [97.193536, 78.872153],
              [97.175538, 78.883671],
              [97.098642, 78.903294],
              [96.995539, 78.922211],
              [96.976214, 78.974517],
              [96.890534, 78.987685],
              [96.801438, 78.981981],
              [96.708605, 78.985586],
              [96.622736, 78.977374],
              [96.589442, 78.99349],
              [96.540735, 78.984486],
              [96.520243, 78.969468],
              [96.336335, 79.000999],
              [96.277337, 78.991391],
              [96.219048, 78.972181],
              [96.044435, 78.966865],
              [95.952296, 78.988534],
              [95.750636, 78.981575],
              [95.736856, 79.022294],
              [95.780992, 79.035889],
              [95.760995, 79.051188],
              [95.695536, 79.040044],
              [95.656435, 79.05138],
              [95.650942, 79.084896],
              [95.495462, 79.082053],
              [95.416535, 79.065373],
              [95.387773, 79.050355],
              [95.316385, 79.048598],
              [95.193538, 79.01772],
              [95.117442, 79.013617],
              [95.031642, 79.023524],
              [95.08124, 79.028138],
              [95.092836, 79.04045],
              [95.020937, 79.040044],
              [94.971536, 79.029436],
              [94.891023, 79.04045],
              [94.773767, 79.086835],
              [94.691736, 79.107619],
              [94.692041, 79.135351],
              [94.74174, 79.166889],
              [94.658716, 79.185618],
              [94.517824, 79.205003],
              [94.485139, 79.219547],
              [94.417551, 79.225222],
              [94.391047, 79.243989],
              [94.478037, 79.2663],
              [94.439714, 79.281697],
              [94.413141, 79.317657],
              [94.446138, 79.335584],
              [94.412141, 79.366312],
              [94.39174, 79.406756],
              [94.448329, 79.417799],
              [94.439837, 79.461825],
              [94.394037, 79.475643],
              [94.105241, 79.477742],
              [94.058214, 79.456036],
              [94.101137, 79.44581],
              [94.038438, 79.425683],
              [93.950137, 79.436895],
              [93.919542, 79.423581],
              [93.866443, 79.423681],
              [93.913142, 79.451813],
              [93.809327, 79.465407],
              [93.871539, 79.47714],
              [93.943842, 79.475436],
              [93.964037, 79.48435],
              [93.944635, 79.508014],
              [94.042939, 79.502666],
              [94.021937, 79.511281],
              [93.873827, 79.524557],
              [93.899545, 79.530316],
              [93.772342, 79.550427],
              [93.731935, 79.54822],
              [93.727641, 79.564443],
              [93.824443, 79.565137],
              [93.851136, 79.580461],
              [93.964129, 79.597668],
              [93.924852, 79.604513],
              [93.959101, 79.62712],
              [93.799441, 79.611096],
              [93.758936, 79.589077],
              [93.801043, 79.570844],
              [93.736101, 79.571692],
              [93.636339, 79.552122],
              [93.597101, 79.535349],
              [93.510537, 79.52219],
              [93.481326, 79.505812],
              [93.573045, 79.486511],
              [93.451036, 79.458021],
              [93.451341, 79.45111],
              [93.328143, 79.441997],
              [93.244341, 79.452415],
              [93.212602, 79.473307],
              [93.33864, 79.48435],
              [93.326037, 79.496763],
              [93.40004, 79.494762],
              [93.378047, 79.510373],
              [93.396296, 79.523808],
              [93.346337, 79.5315],
              [93.246041, 79.530599],
              [93.201883, 79.518713],
              [93.121607, 79.528315],
              [93.158044, 79.544417],
              [93.076241, 79.559937],
              [92.974938, 79.558096],
              [92.978106, 79.573586],
              [93.087739, 79.571608],
              [93.101334, 79.562235],
              [93.269542, 79.551725],
              [93.332339, 79.540008],
              [93.381037, 79.540711],
              [93.404573, 79.55996],
              [93.463343, 79.575549],
              [93.592135, 79.599418],
              [93.628107, 79.613633],
              [93.671738, 79.610591],
              [93.826075, 79.653312],
              [93.871637, 79.687378],
              [93.936107, 79.702036],
              [94.116343, 79.757861],
              [94.21074, 79.766372],
              [94.397684, 79.77501],
              [94.692041, 79.770175],
              [94.627519, 79.775759],
              [94.676737, 79.790499],
              [94.750841, 79.796908],
              [94.736102, 79.816307],
              [94.606049, 79.82846],
              [94.52514, 79.852566],
              [94.361551, 79.882377],
              [94.388078, 79.896065],
              [94.35599, 79.904932],
              [94.40616, 79.910997],
              [94.472994, 79.93266],
              [94.415735, 79.939763],
              [94.39055, 79.96723],
              [94.510966, 79.954993],
              [94.63891, 79.973455],
              [94.80974, 79.961891],
              [94.750636, 79.980658],
              [94.786493, 80.030603],
              [94.979739, 80.053196],
              [94.992518, 80.089309],
              [95.065835, 80.088232],
              [95.074075, 80.074574],
              [95.156436, 80.063781],
              [95.116412, 80.048177],
              [95.232538, 80.03798],
              [95.320643, 80.008949],
              [95.435236, 80.039677],
              [95.41974, 80.061331],
              [95.437296, 80.077102],
              [95.518107, 80.09176],
              [95.60319, 80.092066],
              [95.668635, 80.084107],
              [95.737237, 80.09244],
              [95.810434, 80.089615],
              [95.845537, 80.079929],
              [96.056742, 80.09024],
              [96.170266, 80.074574],
              [96.223643, 80.078524],
              [96.250635, 80.093045],
              [96.300035, 80.097649],
              [96.364642, 80.085428],
              [96.489573, 80.082968],
              [96.572741, 80.08834],
              [96.626139, 80.100453],
              [96.628518, 80.116423],
              [96.6868, 80.11834],
              [96.781496, 80.103851],
              [96.951036, 80.114666],
              [97.108736, 80.129685],
              [97.200907, 80.148541],
              [97.352296, 80.144372],
              [97.694942, 80.146129],
              [97.742341, 80.12688],
              [97.9023, 80.104189],
              [97.95794, 80.090035],
              [97.966744, 80.07307],
              [98.125436, 80.052592],
              [98.123041, 80.030473],
              [97.92644, 80.000034],
              [97.952035, 79.973806],
              [98.010629, 79.953213],
              [97.952737, 79.935165],
              [98.034211, 79.930795],
              [98.040552, 79.917139],
              [97.986842, 79.891109],
              [97.857768, 79.877542],
              [97.736214, 79.843804],
              [97.67644, 79.839551],
              [97.557336, 79.847663],
              [97.463235, 79.84696],
              [97.422343, 79.840056],
              [97.590272, 79.8328],
              [97.607409, 79.825176],
              [97.475436, 79.811023],
              [97.246538, 79.795601],
              [97.175105, 79.783656],
              [97.03174, 79.772879],
              [96.965905, 79.756129],
              [96.80514, 79.754256],
              [96.784541, 79.746046],
              [96.633035, 79.727523],
              [96.619736, 79.720719],
              [96.511491, 79.712103],
              [96.52608, 79.698337],
              [96.617738, 79.706602],
              [96.687639, 79.703195],
              [96.831628, 79.716135],
              [96.899186, 79.729761],
              [96.979409, 79.730457],
              [97.016962, 79.709826],
              [97.035745, 79.734603],
              [97.159684, 79.759129],
              [97.281436, 79.774009],
              [97.535242, 79.785488],
              [97.620881, 79.792669],
              [97.748843, 79.794898],
              [97.758548, 79.772144],
              [97.735537, 79.759663],
              [97.623384, 79.739577],
              [97.539637, 79.731229],
              [97.445552, 79.729654],
              [97.383523, 79.722147],
              [97.317537, 79.694488],
              [97.23034, 79.687476],
              [97.194101, 79.664769],
              [97.187036, 79.637821],
              [97.217355, 79.622783],
              [97.171335, 79.604887],
              [97.215035, 79.581461],
              [97.236742, 79.585547],
              [97.202639, 79.605987],
              [97.241243, 79.618192],
              [97.226328, 79.661599],
              [97.287462, 79.666884],
              [97.309939, 79.678671],
              [97.41234, 79.686278],
              [97.533839, 79.711407],
              [97.676638, 79.723322],
              [97.76214, 79.748047],
              [97.870936, 79.735635],
              [97.855379, 79.761827],
              [97.865656, 79.781486],
              [97.942629, 79.781235],
              [97.995142, 79.7959],
              [98.051934, 79.794204],
              [98.212335, 79.81032],
              [98.287836, 79.812718],
              [98.256967, 79.837612],
              [98.202136, 79.849664],
              [98.134539, 79.891207],
              [98.158885, 79.900708],
              [98.231637, 79.891513],
              [98.330538, 79.888809],
              [98.407823, 79.910082],
              [98.387933, 79.921844],
              [98.40941, 79.944474],
              [98.472635, 79.948378],
              [98.435237, 79.936363],
              [98.530543, 79.934759],
              [98.522936, 79.922843],
              [98.449686, 79.898173],
              [98.4293, 79.868034],
              [98.460993, 79.863138],
              [98.533442, 79.872388],
              [98.512041, 79.897318],
              [98.596139, 79.939968],
              [98.604937, 79.97421],
              [98.518838, 79.994428],
              [98.499048, 80.02201],
              [98.570535, 80.042479],
              [98.654132, 80.051736],
              [98.776239, 80.057504],
              [98.711541, 80.034276],
              [98.788943, 80.021161],
              [98.858142, 80.027867],
              [98.886836, 80.044688],
              [98.951739, 80.045085],
              [98.965341, 80.030067],
              [99.086352, 80.047597],
              [99.311341, 80.050194],
              [99.33854, 80.038477],
              [99.218042, 80.026165],
              [99.219576, 80.009971],
              [99.388772, 80.022293],
              [99.476802, 80.01014],
              [99.372636, 79.995934],
              [99.361826, 79.982109],
              [99.576639, 79.980115],
              [99.503244, 79.973653],
              [99.669138, 79.966495],
              [99.69938, 79.956047],
              [99.656496, 79.951235],
              [99.621942, 79.926855],
              [99.671036, 79.941467],
              [99.751741, 79.935058],
              [99.780542, 79.925547],
              [99.763743, 79.909432],
              [99.854539, 79.911937],
              [99.932337, 79.888907],
              [99.937342, 79.853871],
              [100.033754, 79.847877],
              [100.03444, 79.834885],
              [100.085335, 79.83645],
              [100.086329, 79.814055],
              [100.127078, 79.801818],
              [100.116548, 79.790216],
              [100.167337, 79.771978],
              [100.109271, 79.749895],
              [100.008143, 79.73714],
              [99.902239, 79.742747],
              [99.900942, 79.730425],
              [99.988839, 79.714713],
              [100.001244, 79.688462],
              [99.989541, 79.656742],
              [99.946329, 79.64155],
              [99.939936, 79.62451],
              [99.893236, 79.635423],
              [99.910799, 79.593246],
              [99.883103, 79.559319],
              [99.797907, 79.541521],
              [99.866839, 79.535105],
              [99.936738, 79.492058],
              [99.873132, 79.470694],
              [99.878137, 79.445205],
              [99.831041, 79.431686],
              [99.879381, 79.399805],
              [99.879381, 79.38181],
              [99.828912, 79.355145],
              [99.873239, 79.321362],
              [99.848741, 79.30795],
              [99.869599, 79.28793],
              [99.839136, 79.257791],
              [99.734041, 79.268301],
              [99.756212, 79.252803],
              [99.722214, 79.24026],
              [99.636476, 79.233272],
              [99.654435, 79.219752],
              [99.583338, 79.238572],
              [99.606409, 79.242177],
              [99.448237, 79.267803],
              [99.370439, 79.285019],
              [99.283342, 79.289626],
              [99.23491, 79.279972],
              [99.246942, 79.261494],
              [99.326577, 79.232731],
              [99.53644, 79.180103],
              [99.642351, 79.159031],
              [99.78892, 79.135253],
              [99.67804, 79.127142],
              [99.649638, 79.104516],
              [99.715837, 79.096008],
              [99.84375, 79.087735],
            ],
          ],
          [
            [
              [96.060838, 80.111559],
              [95.985102, 80.111084],
              [95.999438, 80.125979],
              [96.065043, 80.132487],
              [96.118639, 80.126978],
              [96.060838, 80.111559],
            ],
          ],
          [
            [
              [92.8125, 80.165195],
              [92.59884, 80.181637],
              [92.364182, 80.206056],
              [92.337496, 80.223633],
              [92.175294, 80.240207],
              [92.22304, 80.246416],
              [92.500039, 80.246614],
              [92.584436, 80.253824],
              [92.854746, 80.263481],
              [92.964883, 80.261174],
              [93.216326, 80.238366],
              [93.307024, 80.222159],
              [93.423989, 80.226886],
              [93.313768, 80.261119],
              [93.008767, 80.282278],
              [92.888017, 80.280665],
              [92.671519, 80.266458],
              [92.477578, 80.258974],
              [92.012407, 80.253749],
              [91.872773, 80.24104],
              [91.76169, 80.236143],
              [91.722742, 80.240406],
              [91.773355, 80.257277],
              [91.742938, 80.272019],
              [91.675767, 80.254329],
              [91.528216, 80.242849],
              [91.480737, 80.244209],
              [91.501238, 80.258529],
              [91.657495, 80.298797],
              [91.723435, 80.301777],
              [91.74505, 80.314564],
              [91.84414, 80.318398],
              [91.925852, 80.307139],
              [91.970218, 80.324745],
              [92.083741, 80.326005],
              [92.186159, 80.313709],
              [92.072268, 80.33601],
              [92.101412, 80.339341],
              [92.236216, 80.333843],
              [92.304941, 80.318696],
              [92.431139, 80.331116],
              [92.596688, 80.316962],
              [92.695635, 80.324325],
              [92.782189, 80.322384],
              [92.759439, 80.334018],
              [92.589037, 80.354335],
              [92.415841, 80.356039],
              [92.280878, 80.363318],
              [92.165537, 80.378266],
              [92.087076, 80.382925],
              [92.000742, 80.397245],
              [92.103745, 80.413178],
              [92.231142, 80.421612],
              [92.330538, 80.422718],
              [92.446215, 80.443633],
              [92.73874, 80.447947],
              [92.841739, 80.456853],
              [92.904337, 80.475077],
              [92.898042, 80.542354],
              [92.96563, 80.610907],
              [93.054995, 80.641274],
              [93.249857, 80.684461],
              [93.353639, 80.714447],
              [93.33777, 80.725256],
              [93.39354, 80.749492],
              [93.359141, 80.758199],
              [93.25274, 80.750186],
              [93.249879, 80.735911],
              [93.292857, 80.720307],
              [93.153353, 80.715218],
              [92.947741, 80.698927],
              [92.892108, 80.719581],
              [92.799037, 80.692419],
              [92.639406, 80.691326],
              [92.598573, 80.717083],
              [92.717539, 80.736972],
              [92.750466, 80.751845],
              [92.857219, 80.779349],
              [92.843439, 80.804348],
              [92.896037, 80.819167],
              [93.075038, 80.837392],
              [93.158837, 80.852102],
              [93.260942, 80.845998],
              [93.304139, 80.850199],
              [93.228884, 80.871816],
              [93.204135, 80.892087],
              [93.21316, 80.91116],
              [93.167712, 80.917392],
              [93.205072, 80.927712],
              [93.336434, 80.932095],
              [93.573496, 80.968393],
              [93.623881, 80.972539],
              [93.801339, 80.960731],
              [93.828714, 80.966727],
              [93.782541, 80.99647],
              [94.02299, 81.019116],
              [94.072907, 81.017008],
              [94.206736, 81.029306],
              [94.286189, 81.049655],
              [94.401659, 81.052486],
              [94.484141, 81.063548],
              [94.563035, 81.061241],
              [94.664942, 81.073958],
              [94.84424, 81.08517],
              [94.923127, 81.094146],
              [94.963541, 81.110103],
              [95.228326, 81.151966],
              [95.374162, 81.179776],
              [95.655642, 81.180579],
              [95.615335, 81.195198],
              [95.309443, 81.193096],
              [95.188241, 81.198712],
              [95.163141, 81.228529],
              [95.190965, 81.233029],
              [95.580537, 81.25366],
              [95.802142, 81.252262],
              [95.894937, 81.246359],
              [95.98804, 81.225031],
              [96.014017, 81.200774],
              [95.984339, 81.181884],
              [95.909159, 81.162697],
              [95.907908, 81.15027],
              [96.130641, 81.156351],
              [96.158738, 81.132824],
              [96.266824, 81.122428],
              [96.289576, 81.096008],
              [96.369852, 81.082918],
              [96.396538, 81.067649],
              [96.525141, 81.051334],
              [96.574158, 81.033995],
              [96.570535, 81.003475],
              [96.649691, 80.985882],
              [96.788835, 80.973144],
              [96.738466, 80.941505],
              [96.74624, 80.932095],
              [96.8743, 80.890499],
              [96.956538, 80.890346],
              [97.082743, 80.868823],
              [97.162934, 80.862215],
              [97.219712, 80.836719],
              [97.479242, 80.803752],
              [97.470736, 80.791234],
              [97.625047, 80.789943],
              [97.702073, 80.782153],
              [97.885575, 80.778959],
              [97.904039, 80.769213],
              [97.83414, 80.758695],
              [97.822642, 80.746086],
              [97.918687, 80.747674],
              [97.978343, 80.729365],
              [97.96454, 80.715449],
              [97.863208, 80.698842],
              [97.986101, 80.68983],
              [98.033519, 80.658239],
              [97.985942, 80.646668],
              [97.899439, 80.646568],
              [97.798243, 80.624745],
              [97.735408, 80.631178],
              [97.57464, 80.622646],
              [97.569101, 80.608652],
              [97.443712, 80.599587],
              [97.391436, 80.602925],
              [97.390435, 80.626044],
              [97.31534, 80.615833],
              [97.195642, 80.623242],
              [96.999842, 80.660188],
              [96.958215, 80.678984],
              [96.758142, 80.709238],
              [96.779338, 80.694322],
              [96.913538, 80.678405],
              [96.974855, 80.653037],
              [97.135377, 80.625174],
              [97.240496, 80.612349],
              [97.253961, 80.594355],
              [97.205019, 80.578506],
              [97.217882, 80.560318],
              [97.16855, 80.54091],
              [97.187136, 80.523534],
              [97.132036, 80.501803],
              [97.14084, 80.480882],
              [97.194635, 80.46857],
              [97.261796, 80.419686],
              [97.284358, 80.366738],
              [97.275736, 80.323301],
              [97.439036, 80.313394],
              [97.469774, 80.307201],
              [97.481241, 80.278855],
              [97.308434, 80.235799],
              [97.315135, 80.216842],
              [97.160243, 80.21788],
              [96.999659, 80.204827],
              [96.780824, 80.20889],
              [96.655467, 80.200573],
              [96.57419, 80.2055],
              [96.392801, 80.19596],
              [96.210855, 80.197876],
              [96.020135, 80.193354],
              [95.88234, 80.19486],
              [95.687021, 80.190506],
              [95.579539, 80.181845],
              [95.415911, 80.154416],
              [95.29658, 80.147519],
              [95.236246, 80.138421],
              [95.023608, 80.129714],
              [94.816218, 80.135672],
              [94.742235, 80.118769],
              [94.688538, 80.115469],
              [94.588297, 80.130385],
              [94.571856, 80.115698],
              [94.511935, 80.111054],
              [94.503336, 80.096442],
              [94.440835, 80.073314],
              [94.36274, 80.055526],
              [94.260637, 80.043787],
              [94.094637, 80.032373],
              [93.835604, 79.997903],
              [93.734843, 79.992023],
              [93.60274, 80.000295],
              [93.427834, 80.024659],
              [93.201341, 80.046689],
              [92.983638, 80.083427],
              [92.806437, 80.108962],
              [92.750338, 80.11246],
              [92.470338, 80.11136],
              [92.301356, 80.117035],
              [92.198542, 80.126581],
              [92.20849, 80.140979],
              [92.318636, 80.149702],
              [92.289942, 80.169928],
              [92.324038, 80.171326],
              [92.480464, 80.153552],
              [92.78164, 80.134191],
              [92.888338, 80.121571],
              [92.989543, 80.127079],
              [92.968438, 80.142394],
              [92.8125, 80.165195],
            ],
          ],
          [
            [
              [106.982242, 78.160455],
              [107.08349, 78.154],
              [107.320185, 78.144493],
              [107.390268, 78.16015],
              [107.441544, 78.163374],
              [107.502839, 78.177167],
              [107.585877, 78.168521],
              [107.661141, 78.167155],
              [107.693842, 78.153642],
              [107.696826, 78.135815],
              [107.759714, 78.122556],
              [107.731241, 78.078661],
              [107.688967, 78.061459],
              [107.544466, 78.026026],
              [107.52244, 78.027997],
              [107.526574, 78.061039],
              [107.580241, 78.073848],
              [107.479136, 78.077454],
              [107.462686, 78.061819],
              [107.410936, 78.055433],
              [107.350183, 78.062987],
              [107.135042, 78.07245],
              [106.937037, 78.085068],
              [106.870835, 78.083961],
              [106.749537, 78.089369],
              [106.645937, 78.102888],
              [106.517542, 78.110091],
              [106.444521, 78.122999],
              [106.558336, 78.14974],
              [106.5691, 78.136876],
              [106.726443, 78.143032],
              [106.853639, 78.166153],
              [106.892717, 78.156453],
              [106.969836, 78.152741],
              [106.982242, 78.160455],
            ],
          ],
          [
            [
              [99.84375, 77.941477],
              [99.824561, 77.955543],
              [99.761507, 77.939216],
              [99.723675, 77.961436],
              [99.654064, 77.946698],
              [99.613709, 77.924768],
              [99.560996, 77.921285],
              [99.561501, 77.948067],
              [99.506013, 77.946171],
              [99.485332, 77.960278],
              [99.518624, 77.991488],
              [99.4248, 78.013927],
              [99.387977, 78.013613],
              [99.489872, 78.068435],
              [99.535775, 78.112261],
              [99.661126, 78.174963],
              [99.730233, 78.18891],
              [99.775631, 78.20614],
              [99.887615, 78.2662],
              [100.02482, 78.297907],
              [100.040457, 78.324886],
              [100.127724, 78.33565],
              [100.144874, 78.34829],
              [100.07375, 78.365494],
              [100.103006, 78.393943],
              [100.049537, 78.405608],
              [100.168582, 78.413918],
              [100.176653, 78.428599],
              [100.25484, 78.473741],
              [100.182202, 78.488347],
              [100.276026, 78.501125],
              [100.252318, 78.541629],
              [100.298221, 78.550344],
              [100.312345, 78.566554],
              [100.421769, 78.575575],
              [100.375745, 78.586312],
              [100.408211, 78.608777],
              [100.338997, 78.614202],
              [100.386805, 78.621243],
              [100.363614, 78.655682],
              [100.393584, 78.681969],
              [100.517206, 78.740286],
              [100.625506, 78.785199],
              [100.694555, 78.802213],
              [100.787031, 78.777283],
              [100.884439, 78.763836],
              [101.063535, 78.75302],
              [101.138441, 78.743456],
              [101.204407, 78.756446],
              [101.140907, 78.766659],
              [100.931911, 78.787897],
              [100.884439, 78.813162],
              [100.880124, 78.827625],
              [100.917114, 78.831447],
              [100.820323, 78.850418],
              [100.837585, 78.871916],
              [100.907867, 78.898301],
              [100.895537, 78.904709],
              [100.944241, 78.929478],
              [100.960886, 78.958798],
              [100.995411, 78.978911],
              [101.018838, 79.008806],
              [101.076085, 79.011671],
              [101.201793, 79.007406],
              [101.32181, 78.98376],
              [101.45259, 78.96817],
              [101.486265, 78.981337],
              [101.272298, 79.024227],
              [101.085501, 79.041026],
              [101.070557, 79.053481],
              [101.099086, 79.065212],
              [101.145276, 79.103029],
              [101.302864, 79.114452],
              [101.488303, 79.10855],
              [101.541285, 79.11535],
              [101.442792, 79.116248],
              [101.33479, 79.128553],
              [101.241731, 79.131372],
              [101.305582, 79.18771],
              [101.360262, 79.220473],
              [101.520009, 79.228788],
              [101.593611, 79.218001],
              [101.577208, 79.229687],
              [101.667806, 79.226389],
              [101.700437, 79.232819],
              [101.686507, 79.258347],
              [101.615293, 79.277669],
              [101.60671, 79.315263],
              [101.67221, 79.34103],
              [101.742109, 79.340427],
              [101.759931, 79.351201],
              [101.883809, 79.356304],
              [101.918607, 79.332742],
              [101.907606, 79.320254],
              [101.94711, 79.302678],
              [101.963795, 79.275063],
              [102.010657, 79.252901],
              [102.001005, 79.234983],
              [102.192511, 79.215204],
              [102.309935, 79.21362],
              [102.248074, 79.258256],
              [102.254376, 79.281415],
              [102.21205, 79.305825],
              [102.206808, 79.349517],
              [102.237905, 79.38482],
              [102.303487, 79.413197],
              [102.454824, 79.425646],
              [102.526266, 79.404376],
              [102.632658, 79.400271],
              [102.647848, 79.392171],
              [102.778571, 79.378245],
              [102.862005, 79.376775],
              [102.911688, 79.36083],
              [102.937848, 79.336001],
              [102.983205, 79.339924],
              [102.961508, 79.324148],
              [103.051809, 79.322555],
              [103.049154, 79.3053],
              [103.112019, 79.31489],
              [103.159375, 79.309398],
              [103.155989, 79.290092],
              [103.072508, 79.268433],
              [103.024512, 79.262735],
              [103.008688, 79.245823],
              [102.864508, 79.199632],
              [102.820905, 79.168474],
              [102.820379, 79.144088],
              [102.796904, 79.123808],
              [102.833379, 79.116182],
              [102.755209, 79.088694],
              [102.716626, 79.064925],
              [102.731406, 79.052447],
              [102.723431, 79.012273],
              [102.738914, 78.988939],
              [102.688811, 78.927223],
              [102.623633, 78.922125],
              [102.532353, 78.901328],
              [102.556906, 78.89537],
              [102.50151, 78.885686],
              [102.51399, 78.868454],
              [102.493742, 78.852227],
              [102.410711, 78.822873],
              [102.388738, 78.797809],
              [102.535811, 78.801503],
              [102.654004, 78.849241],
              [102.72891, 78.843244],
              [102.703076, 78.852616],
              [102.695959, 78.885268],
              [102.774687, 78.888841],
              [102.771131, 78.907063],
              [102.896878, 78.979187],
              [102.959044, 79.032082],
              [102.954205, 79.053346],
              [102.986571, 79.045002],
              [103.078017, 79.038596],
              [103.117628, 79.04728],
              [103.339712, 79.072633],
              [103.332211, 79.094584],
              [103.464827, 79.103337],
              [103.495794, 79.113462],
              [103.516805, 79.140515],
              [103.505307, 79.157092],
              [103.554464, 79.177456],
              [103.587965, 79.179787],
              [103.595907, 79.163979],
              [103.646436, 79.17021],
              [103.625379, 79.136956],
              [103.62702, 79.102756],
              [103.68852, 79.138007],
              [103.789686, 79.144277],
              [103.78211, 79.163681],
              [103.839811, 79.176266],
              [103.860212, 79.165479],
              [103.837575, 79.136378],
              [104.006108, 79.135021],
              [104.030012, 79.128134],
              [104.031767, 79.084809],
              [103.958547, 79.065503],
              [104.04321, 79.044957],
              [104.069022, 79.024593],
              [104.03581, 79.00611],
              [104.055905, 78.996024],
              [104.172912, 79.008807],
              [104.237975, 78.99271],
              [104.274703, 78.998884],
              [104.449026, 78.981505],
              [104.460538, 78.959692],
              [104.547699, 78.941726],
              [104.574012, 78.908873],
              [104.525224, 78.888713],
              [104.541943, 78.878989],
              [104.595117, 78.881421],
              [104.669396, 78.872009],
              [104.715443, 78.878143],
              [104.763684, 78.865341],
              [104.658706, 78.861106],
              [104.553455, 78.846906],
              [104.554003, 78.838738],
              [104.703383, 78.817815],
              [104.707769, 78.791419],
              [104.735178, 78.786623],
              [104.660077, 78.777452],
              [104.657884, 78.762826],
              [104.766973, 78.774891],
              [104.817954, 78.817336],
              [104.789996, 78.834652],
              [104.844267, 78.832741],
              [104.948422, 78.853796],
              [105.020782, 78.839375],
              [105.142597, 78.825992],
              [105.286109, 78.79223],
              [105.338666, 78.741985],
              [105.368144, 78.736607],
              [105.304921, 78.725314],
              [105.318497, 78.711807],
              [105.368144, 78.709226],
              [105.394132, 78.695396],
              [105.403441, 78.66174],
              [105.389865, 78.637771],
              [105.421283, 78.633338],
              [105.418956, 78.611684],
              [105.381332, 78.595742],
              [105.453089, 78.565562],
              [105.449792, 78.527668],
              [105.422641, 78.521881],
              [105.409065, 78.490425],
              [105.206983, 78.416475],
              [105.088294, 78.362025],
              [105.02352, 78.346836],
              [104.967666, 78.325978],
              [104.824541, 78.294622],
              [104.806699, 78.309328],
              [104.708761, 78.321268],
              [104.630799, 78.307048],
              [104.534025, 78.303196],
              [104.447529, 78.314749],
              [104.364912, 78.308935],
              [104.185521, 78.288876],
              [104.109498, 78.271385],
              [104.067608, 78.27241],
              [103.876581, 78.240793],
              [103.780776, 78.24348],
              [103.760219, 78.231541],
              [103.693311, 78.23858],
              [103.660342, 78.232727],
              [103.532732, 78.235417],
              [103.363038, 78.216857],
              [103.274991, 78.198467],
              [103.240858, 78.209647],
              [103.174144, 78.216461],
              [103.05274, 78.210519],
              [103.02714, 78.199736],
              [102.937929, 78.192357],
              [102.952669, 78.169716],
              [102.874706, 78.152328],
              [102.910778, 78.133121],
              [102.847943, 78.141493],
              [102.806441, 78.135194],
              [102.771144, 78.15607],
              [102.790631, 78.164113],
              [102.75984, 78.18199],
              [102.70183, 78.196135],
              [102.548483, 78.199436],
              [102.457182, 78.189529],
              [102.319724, 78.193761],
              [102.181006, 78.18075],
              [102.143174, 78.165035],
              [102.092983, 78.165346],
              [102.046071, 78.17579],
              [101.944176, 78.183746],
              [101.746692, 78.178425],
              [101.733577, 78.173154],
              [101.578717, 78.180595],
              [101.418812, 78.177702],
              [101.267483, 78.167052],
              [101.18627, 78.152355],
              [101.103544, 78.121505],
              [100.949692, 78.098797],
              [100.901267, 78.083079],
              [100.839222, 78.076619],
              [100.776168, 78.060716],
              [100.733292, 78.066036],
              [100.673265, 78.059359],
              [100.572379, 78.03805],
              [100.47124, 78.023666],
              [100.342611, 77.998413],
              [100.245508, 77.98624],
              [100.205154, 77.971637],
              [100.009435, 77.940903],
              [99.84375, 77.941477],
            ],
          ],
          [
            [
              [110.914279, 73.698611],
              [110.947657, 73.70273],
              [111.015577, 73.691043],
              [111.010336, 73.681184],
              [111.061709, 73.672093],
              [111.101221, 73.673578],
              [111.109177, 73.661831],
              [111.192815, 73.660171],
              [111.156113, 73.646706],
              [111.192979, 73.644019],
              [111.197218, 73.623456],
              [111.227305, 73.617445],
              [111.28636, 73.620639],
              [111.282728, 73.609245],
              [111.221677, 73.598841],
              [111.165888, 73.604296],
              [111.182717, 73.592124],
              [111.113794, 73.576502],
              [111.09316, 73.579424],
              [111.052562, 73.564928],
              [111.001102, 73.556337],
              [110.946944, 73.556625],
              [110.923171, 73.565576],
              [110.879011, 73.563837],
              [110.872517, 73.549427],
              [110.814072, 73.546944],
              [110.839942, 73.518475],
              [110.802711, 73.502298],
              [110.743396, 73.491785],
              [110.679469, 73.473523],
              [110.64354, 73.454194],
              [110.650285, 73.4324],
              [110.635663, 73.427318],
              [110.531354, 73.434333],
              [110.429838, 73.415689],
              [110.376366, 73.413324],
              [110.207085, 73.396613],
              [110.143616, 73.414549],
              [110.121518, 73.402718],
              [110.159445, 73.396667],
              [110.137264, 73.383828],
              [110.203449, 73.381293],
              [110.214125, 73.36996],
              [110.244841, 73.377281],
              [110.260113, 73.360056],
              [110.321897, 73.361044],
              [110.321045, 73.34964],
              [110.363407, 73.354325],
              [110.374631, 73.336874],
              [110.456182, 73.346712],
              [110.456924, 73.334729],
              [110.543538, 73.333151],
              [110.530644, 73.317006],
              [110.562694, 73.309125],
              [110.540183, 73.299727],
              [110.623502, 73.268432],
              [110.624424, 73.254833],
              [110.583074, 73.240059],
              [110.552858, 73.238491],
              [110.448026, 73.212295],
              [110.384068, 73.206652],
              [110.34862, 73.196564],
              [110.36746, 73.182387],
              [110.342491, 73.16679],
              [110.378839, 73.162808],
              [110.373798, 73.151871],
              [110.417873, 73.156964],
              [110.468167, 73.150456],
              [110.542413, 73.14966],
              [110.555196, 73.156875],
              [110.65296, 73.165108],
              [110.731348, 73.153652],
              [110.80879, 73.163509],
              [110.85016, 73.16067],
              [110.838421, 73.132597],
              [110.815013, 73.112359],
              [110.745516, 73.102943],
              [110.798904, 73.086851],
              [110.801936, 73.068365],
              [110.85986, 73.055281],
              [110.811154, 73.020106],
              [110.730581, 73.021727],
              [110.703361, 72.993518],
              [110.666563, 72.99109],
              [110.711362, 72.967572],
              [110.677426, 72.964412],
              [110.657588, 72.952045],
              [110.693503, 72.940148],
              [110.687951, 72.960546],
              [110.855904, 72.94596],
              [110.890874, 72.95037],
              [110.891484, 72.931056],
              [110.818026, 72.929062],
              [110.754645, 72.900855],
              [110.700887, 72.902967],
              [110.645702, 72.893732],
              [110.626252, 72.879091],
              [110.648535, 72.87484],
              [110.660238, 72.857189],
              [110.628665, 72.846816],
              [110.665502, 72.847813],
              [110.681791, 72.835747],
              [110.730591, 72.829548],
              [110.721088, 72.811418],
              [110.735058, 72.800483],
              [110.656684, 72.791381],
              [110.687768, 72.766692],
              [110.662144, 72.75878],
              [110.70336, 72.728384],
              [110.699496, 72.705094],
              [110.719794, 72.697254],
              [110.718647, 72.678114],
              [110.763707, 72.656219],
              [110.76136, 72.647711],
              [110.908832, 72.632042],
              [110.909361, 72.623391],
              [110.863022, 72.60242],
              [110.91465, 72.591788],
              [110.870467, 72.584455],
              [110.912348, 72.570951],
              [110.957672, 72.580563],
              [111.044843, 72.566625],
              [111.137315, 72.563326],
              [111.178717, 72.557758],
              [111.245519, 72.533927],
              [111.243823, 72.52391],
              [111.30088, 72.512994],
              [111.291058, 72.486607],
              [111.253708, 72.483913],
              [111.239722, 72.473805],
              [111.161391, 72.460998],
              [111.130945, 72.446986],
              [111.008817, 72.441847],
              [111.017054, 72.425097],
              [111.074827, 72.412194],
              [111.0102, 72.39022],
              [111.056463, 72.377908],
              [111.069036, 72.363761],
              [111.174025, 72.359852],
              [111.257798, 72.337789],
              [111.304495, 72.339997],
              [111.291282, 72.331478],
              [111.33524, 72.316017],
              [111.360258, 72.32087],
              [111.438796, 72.317262],
              [111.454667, 72.326818],
              [111.483435, 72.322515],
              [111.517859, 72.329466],
              [111.574805, 72.324402],
              [111.58684, 72.309008],
              [111.639687, 72.298523],
              [111.638422, 72.281022],
              [111.667015, 72.257532],
              [111.582311, 72.226299],
              [111.592957, 72.211758],
              [111.623857, 72.20773],
              [111.691752, 72.220915],
              [111.715856, 72.211131],
              [111.690315, 72.193588],
              [111.698145, 72.172726],
              [111.743839, 72.158337],
              [111.788606, 72.161392],
              [111.823227, 72.142014],
              [111.865668, 72.140526],
              [111.895486, 72.128646],
              [111.967885, 72.135161],
              [112.000695, 72.1244],
              [112.000186, 72.001037],
              [112.000574, 71.66755],
              [112.000514, 71.402308],
              [112.16385, 71.377365],
              [112.193474, 71.382586],
              [112.251531, 71.362361],
              [112.33522, 71.341799],
              [112.349044, 71.324085],
              [112.383887, 71.307076],
              [112.443992, 71.302192],
              [112.469258, 71.276587],
              [112.50756, 71.275652],
              [112.593699, 71.293798],
              [112.634871, 71.289676],
              [112.658249, 71.26465],
              [112.724997, 71.252169],
              [112.707824, 71.227251],
              [112.675735, 71.201036],
              [112.651449, 71.192058],
              [112.614316, 71.191786],
              [112.520897, 71.176169],
              [112.509573, 71.159787],
              [112.537486, 71.1374],
              [112.599576, 71.109193],
              [112.569875, 71.095356],
              [112.509301, 71.101637],
              [112.468867, 71.072039],
              [112.434492, 71.068225],
              [112.355982, 71.071176],
              [112.277194, 71.044259],
              [112.277215, 71.0285],
              [112.249204, 71.001368],
              [112.219628, 71.001151],
              [112.184129, 71.013275],
              [112.164337, 71.032363],
              [112.101396, 71.060682],
              [112.030505, 71.05306],
              [111.988164, 71.027639],
              [111.980531, 71.000007],
              [111.946322, 70.978864],
              [111.920763, 70.973797],
              [111.867671, 70.977268],
              [111.801059, 70.957544],
              [111.749636, 70.971118],
              [111.726046, 70.97001],
              [111.637736, 70.938977],
              [111.572607, 70.942261],
              [111.511454, 70.930001],
              [111.478798, 70.928825],
              [111.43374, 70.892373],
              [111.432135, 70.881283],
              [111.378156, 70.863313],
              [111.325938, 70.856235],
              [111.194968, 70.861285],
              [111.129412, 70.849538],
              [111.034216, 70.840829],
              [111.026053, 70.830673],
              [110.974464, 70.816452],
              [110.887823, 70.799118],
              [110.858396, 70.785825],
              [110.813493, 70.780047],
              [110.794247, 70.792803],
              [110.736653, 70.799434],
              [110.652496, 70.782753],
              [110.61927, 70.784785],
              [110.558766, 70.768113],
              [110.517212, 70.764128],
              [110.492218, 70.739581],
              [110.516327, 70.716534],
              [110.505705, 70.711318],
              [110.333428, 70.695886],
              [110.308159, 70.68335],
              [110.308611, 70.670126],
              [110.20139, 70.658641],
              [110.185375, 70.645292],
              [110.123517, 70.636983],
              [110.145001, 70.608832],
              [110.105702, 70.592266],
              [110.132049, 70.558029],
              [110.153717, 70.543554],
              [110.092425, 70.537606],
              [110.084571, 70.520573],
              [110.124565, 70.499696],
              [110.113966, 70.479588],
              [110.133608, 70.465111],
              [110.180309, 70.458605],
              [110.205965, 70.445671],
              [110.284181, 70.438346],
              [110.317634, 70.429094],
              [110.313335, 70.408571],
              [110.211261, 70.408151],
              [110.15902, 70.411911],
              [110.062605, 70.39786],
              [110.013418, 70.413271],
              [109.998206, 70.400426],
              [109.959248, 70.391803],
              [109.851532, 70.390921],
              [109.822516, 70.38355],
              [109.755786, 70.379016],
              [109.726074, 70.370697],
              [109.643766, 70.372773],
              [109.558341, 70.352237],
              [109.598842, 70.332779],
              [109.586493, 70.324948],
              [109.610124, 70.306863],
              [109.616694, 70.28517],
              [109.575575, 70.252447],
              [109.456463, 70.218879],
              [109.396676, 70.212626],
              [109.346962, 70.21568],
              [109.304333, 70.209872],
              [109.307008, 70.175688],
              [109.294619, 70.166653],
              [109.303296, 70.144451],
              [109.36197, 70.131616],
              [109.361616, 70.096789],
              [109.347926, 70.088919],
              [109.365572, 70.073329],
              [109.396613, 70.064363],
              [109.417194, 70.0481],
              [109.451802, 70.039807],
              [109.50404, 70.043963],
              [109.545224, 70.030906],
              [109.545155, 70.014676],
              [109.525572, 69.9916],
              [109.463896, 69.98656],
              [109.418592, 69.97182],
              [109.400095, 69.95813],
              [109.406992, 69.931682],
              [109.374558, 69.906566],
              [109.413702, 69.893764],
              [109.390523, 69.87716],
              [109.378434, 69.837451],
              [109.38587, 69.824367],
              [109.340219, 69.823474],
              [109.325564, 69.789058],
              [109.295729, 69.774273],
              [109.263026, 69.772274],
              [109.201107, 69.754623],
              [109.120873, 69.769119],
              [109.054495, 69.766856],
              [108.995823, 69.775269],
              [108.944624, 69.770599],
              [108.917026, 69.802293],
              [108.932733, 69.824702],
              [108.901959, 69.842557],
              [108.800199, 69.830515],
              [108.77494, 69.832721],
              [108.68523, 69.828793],
              [108.637042, 69.838822],
              [108.613956, 69.832761],
              [108.549133, 69.831224],
              [108.514629, 69.84314],
              [108.443592, 69.841628],
              [108.391078, 69.850246],
              [108.248733, 69.854403],
              [108.226446, 69.848397],
              [108.221155, 69.82848],
              [108.176466, 69.821985],
              [108.140692, 69.808194],
              [108.117876, 69.786449],
              [108.069768, 69.79335],
              [108.022611, 69.787929],
              [108.05221, 69.76343],
              [108.006886, 69.749899],
              [108.030772, 69.725203],
              [108.01942, 69.69844],
              [107.976489, 69.688458],
              [107.952931, 69.669024],
              [107.929119, 69.668473],
              [107.844267, 69.686374],
              [107.785075, 69.676754],
              [107.733843, 69.675481],
              [107.691555, 69.666576],
              [107.606481, 69.660499],
              [107.507636, 69.639916],
              [107.442207, 69.622379],
              [107.393786, 69.614381],
              [107.341379, 69.613131],
              [107.30918, 69.605071],
              [107.171761, 69.585819],
              [107.069603, 69.556196],
              [106.956394, 69.545334],
              [106.857426, 69.523271],
              [106.744472, 69.53482],
              [106.681711, 69.525942],
              [106.630677, 69.527968],
              [106.615727, 69.558962],
              [106.59088, 69.569514],
              [106.543921, 69.570706],
              [106.487412, 69.581163],
              [106.452173, 69.572737],
              [106.440504, 69.555865],
              [106.372145, 69.526467],
              [106.348603, 69.507902],
              [106.344196, 69.478153],
              [106.292662, 69.459201],
              [106.178337, 69.44168],
              [106.14286, 69.409405],
              [106.308897, 69.293111],
              [106.513032, 69.150287],
              [106.675069, 69.03248],
              [106.715348, 69.000868],
              [106.905551, 68.859042],
              [106.906583, 68.685558],
              [106.912523, 68.604069],
              [106.916949, 68.486389],
              [106.916037, 68.399264],
              [106.92067, 68.334174],
              [106.920682, 68.254164],
              [106.910232, 68.175051],
              [106.900779, 68.045418],
              [106.88762, 68.000818],
              [106.8865, 67.918525],
              [106.870384, 67.835794],
              [106.868015, 67.747208],
              [106.874489, 67.667541],
              [106.838335, 67.502118],
              [106.825863, 67.416493],
              [106.822039, 67.334093],
              [106.760047, 67.301958],
              [106.737461, 67.295615],
              [106.712254, 67.276062],
              [106.715601, 67.258458],
              [106.678428, 67.251076],
              [106.590562, 67.262535],
              [106.560112, 67.273161],
              [106.555429, 67.290893],
              [106.511791, 67.283238],
              [106.505324, 67.275583],
              [106.433573, 67.264014],
              [106.38696, 67.229825],
              [106.376191, 67.215615],
              [106.342143, 67.200771],
              [106.276234, 67.198461],
              [106.259281, 67.184353],
              [106.220558, 67.171419],
              [106.184439, 67.173352],
              [106.113717, 67.167391],
              [106.076491, 67.168536],
              [106.062034, 67.142048],
              [106.066743, 67.11733],
              [106.015868, 67.085736],
              [105.962214, 67.045478],
              [105.872565, 67.029438],
              [105.764727, 67.029696],
              [105.669799, 67.013449],
              [105.640971, 67.025436],
              [105.579905, 67.018431],
              [105.544952, 67.023716],
              [105.509063, 67.013985],
              [105.555029, 66.999472],
              [105.543609, 66.984024],
              [105.579588, 66.966926],
              [105.626986, 66.954285],
              [105.637632, 66.944452],
              [105.698989, 66.925205],
              [105.737398, 66.924972],
              [105.785105, 66.936374],
              [105.858441, 66.943471],
              [105.845134, 66.930725],
              [105.855024, 66.910733],
              [105.909857, 66.909716],
              [105.976463, 66.900774],
              [106.018052, 66.886514],
              [106.033915, 66.89739],
              [106.080664, 66.895417],
              [106.103131, 66.874373],
              [106.09415, 66.859184],
              [105.985634, 66.801503],
              [106.025462, 66.78582],
              [106.051322, 66.789151],
              [106.085487, 66.779365],
              [106.123156, 66.797558],
              [106.186698, 66.801545],
              [106.213301, 66.797782],
              [106.186863, 66.770683],
              [106.14689, 66.764451],
              [106.146376, 66.730805],
              [106.170333, 66.707544],
              [106.220205, 66.697373],
              [106.258277, 66.676895],
              [106.222287, 66.663933],
              [106.219282, 66.63636],
              [106.250231, 66.603831],
              [106.231644, 66.590328],
              [106.237461, 66.563338],
              [106.290229, 66.546116],
              [106.322893, 66.518899],
              [106.300794, 66.497481],
              [106.330961, 66.474971],
              [106.316003, 66.464533],
              [106.262919, 66.445326],
              [106.253068, 66.435256],
              [106.195564, 66.429772],
              [106.182526, 66.414263],
              [106.148658, 66.409175],
              [106.146585, 66.389783],
              [106.12695, 66.384347],
              [106.108699, 66.363172],
              [106.119552, 66.357872],
              [106.095545, 66.336202],
              [106.121396, 66.314879],
              [106.112368, 66.297614],
              [106.13221, 66.282989],
              [106.120464, 66.277377],
              [106.137001, 66.24871],
              [106.108133, 66.235398],
              [106.120406, 66.213102],
              [106.106042, 66.19724],
              [106.157688, 66.163805],
              [106.17052, 66.165311],
              [106.205446, 66.147],
              [106.252266, 66.153987],
              [106.288516, 66.148764],
              [106.32207, 66.15679],
              [106.338582, 66.152849],
              [106.367106, 66.118887],
              [106.353288, 66.11242],
              [106.379069, 66.101865],
              [106.37404, 66.092769],
              [106.429789, 66.082528],
              [106.413411, 66.061489],
              [106.450987, 66.054196],
              [106.44727, 66.042775],
              [106.482648, 66.025938],
              [106.478285, 66.016127],
              [106.514476, 65.995053],
              [106.489102, 65.979808],
              [106.493774, 65.95507],
              [106.464948, 65.931961],
              [106.467775, 65.907716],
              [106.493821, 65.883603],
              [106.473245, 65.879053],
              [106.484043, 65.855028],
              [106.469854, 65.83249],
              [106.448051, 65.825931],
              [106.458572, 65.812298],
              [106.491483, 65.798387],
              [106.443504, 65.786145],
              [106.42806, 65.763081],
              [106.454803, 65.745556],
              [106.435674, 65.741582],
              [106.459869, 65.733042],
              [106.436468, 65.724716],
              [106.449195, 65.712487],
              [106.412898, 65.713006],
              [106.424278, 65.702393],
              [106.400972, 65.697742],
              [106.434117, 65.687521],
              [106.415123, 65.671969],
              [106.42805, 65.664566],
              [106.467432, 65.661283],
              [106.51585, 65.647869],
              [106.551804, 65.645548],
              [106.563533, 65.632606],
              [106.59399, 65.639472],
              [106.590382, 65.625145],
              [106.651519, 65.619566],
              [106.607198, 65.611315],
              [106.698461, 65.603734],
              [106.720365, 65.587637],
              [106.693273, 65.557131],
              [106.703752, 65.539858],
              [106.777177, 65.532961],
              [106.79765, 65.52049],
              [106.821717, 65.524952],
              [106.887487, 65.518033],
              [106.904479, 65.511646],
              [106.936139, 65.514155],
              [106.967889, 65.508284],
              [106.938868, 65.471908],
              [106.915869, 65.469944],
              [106.862204, 65.442414],
              [106.872231, 65.422995],
              [106.831985, 65.399978],
              [106.817327, 65.377536],
              [106.780135, 65.374773],
              [106.776138, 65.387228],
              [106.710033, 65.386566],
              [106.694962, 65.393503],
              [106.666106, 65.387591],
              [106.615225, 65.39598],
              [106.605457, 65.382964],
              [106.544958, 65.380508],
              [106.516178, 65.342986],
              [106.474336, 65.323562],
              [106.439462, 65.319506],
              [106.427562, 65.293797],
              [106.429257, 65.24653],
              [106.46739, 65.231975],
              [106.526121, 65.219637],
              [106.477599, 65.209495],
              [106.419395, 65.228993],
              [106.351408, 65.191747],
              [106.346887, 65.180628],
              [106.314717, 65.167082],
              [106.312529, 65.145672],
              [106.26613, 65.145893],
              [106.252735, 65.137042],
              [106.189947, 65.133042],
              [106.154905, 65.089094],
              [106.099257, 65.067432],
              [106.025802, 65.066351],
              [106.008667, 65.055783],
              [105.950465, 65.039901],
              [105.952936, 65.014658],
              [105.982286, 64.998276],
              [106.01496, 64.994318],
              [106.041725, 64.982915],
              [106.070925, 64.980394],
              [106.067029, 64.9565],
              [106.101321, 64.935535],
              [106.11633, 64.9061],
              [106.157388, 64.895398],
              [106.207529, 64.873037],
              [106.190975, 64.864978],
              [106.100655, 64.878377],
              [106.062025, 64.869101],
              [106.060232, 64.883835],
              [106.03309, 64.88266],
              [106.046102, 64.911952],
              [106.03182, 64.9187],
              [105.96893, 64.908378],
              [105.954415, 64.912983],
              [105.914801, 64.903497],
              [105.941564, 64.873473],
              [105.941366, 64.851868],
              [105.960806, 64.853228],
              [105.986937, 64.83151],
              [105.976429, 64.822378],
              [105.924011, 64.82586],
              [105.895064, 64.843904],
              [105.852876, 64.832933],
              [105.862818, 64.821242],
              [105.846682, 64.791062],
              [105.826075, 64.786311],
              [105.818335, 64.760534],
              [105.838079, 64.751579],
              [105.832102, 64.733509],
              [105.815905, 64.72606],
              [105.859898, 64.703434],
              [105.876841, 64.686773],
              [105.869535, 64.675123],
              [105.823024, 64.66791],
              [105.782152, 64.668013],
              [105.735249, 64.690987],
              [105.687202, 64.682967],
              [105.719323, 64.650038],
              [105.750906, 64.640663],
              [105.709529, 64.602223],
              [105.714886, 64.580925],
              [105.752845, 64.581327],
              [105.762625, 64.562309],
              [105.785637, 64.550311],
              [105.792656, 64.527169],
              [105.749891, 64.503934],
              [105.765799, 64.48447],
              [105.807422, 64.497484],
              [105.866378, 64.502231],
              [105.902473, 64.486768],
              [105.918855, 64.501263],
              [105.98557, 64.534101],
              [106.023849, 64.540924],
              [106.061565, 64.524808],
              [106.076682, 64.499172],
              [106.116999, 64.495013],
              [106.133812, 64.485387],
              [106.117332, 64.45595],
              [106.075299, 64.446469],
              [106.092496, 64.426158],
              [106.138632, 64.415954],
              [106.166881, 64.421609],
              [106.179124, 64.415694],
              [106.234628, 64.434828],
              [106.256347, 64.447051],
              [106.311919, 64.439264],
              [106.365503, 64.455635],
              [106.441341, 64.454437],
              [106.460507, 64.4606],
              [106.500714, 64.456455],
              [106.537184, 64.46433],
              [106.554146, 64.460234],
              [106.587233, 64.478226],
              [106.554992, 64.516329],
              [106.55534, 64.534551],
              [106.578978, 64.542671],
              [106.652537, 64.529516],
              [106.665837, 64.498908],
              [106.708144, 64.479424],
              [106.704785, 64.465273],
              [106.723825, 64.435543],
              [106.741122, 64.422277],
              [106.786969, 64.426061],
              [106.827834, 64.420867],
              [106.885759, 64.440323],
              [106.915167, 64.43828],
              [106.950481, 64.420774],
              [106.959579, 64.403908],
              [107.042761, 64.379854],
              [107.099751, 64.371804],
              [107.092467, 64.358963],
              [107.133377, 64.35101],
              [107.176165, 64.353232],
              [107.209175, 64.34282],
              [107.25651, 64.345425],
              [107.268497, 64.328176],
              [107.254706, 64.320253],
              [107.295266, 64.284644],
              [107.325227, 64.270333],
              [107.369929, 64.261064],
              [107.401014, 64.274573],
              [107.459087, 64.279131],
              [107.495908, 64.300898],
              [107.551413, 64.306474],
              [107.560744, 64.312666],
              [107.641299, 64.3107],
              [107.698538, 64.295698],
              [107.724676, 64.272285],
              [107.707701, 64.258048],
              [107.751537, 64.255209],
              [107.821949, 64.229155],
              [107.860089, 64.231146],
              [107.887879, 64.209748],
              [107.892208, 64.196355],
              [107.92246, 64.191118],
              [107.923244, 64.201142],
              [107.964583, 64.203991],
              [107.971941, 64.182336],
              [108.05893, 64.187904],
              [108.053478, 64.213862],
              [108.085664, 64.246468],
              [108.171314, 64.257581],
              [108.246822, 64.250218],
              [108.268247, 64.221161],
              [108.292582, 64.214672],
              [108.283789, 64.185398],
              [108.305068, 64.171845],
              [108.299751, 64.153486],
              [108.322968, 64.14048],
              [108.326779, 64.124587],
              [108.364787, 64.103807],
              [108.354489, 64.075879],
              [108.385109, 64.046349],
              [108.323989, 64.028449],
              [108.326631, 64.016504],
              [108.291242, 64.011606],
              [108.271083, 64.018717],
              [108.214171, 64.010304],
              [108.205426, 64.003724],
              [108.161784, 64.008068],
              [108.116279, 64.005547],
              [108.101783, 64.013997],
              [108.07628, 64.006222],
              [108.028702, 64.001936],
              [107.990233, 64.009938],
              [107.977438, 64.019468],
              [107.91115, 63.998119],
              [107.86042, 64.007564],
              [107.860372, 64.021644],
              [107.834403, 64.015322],
              [107.779813, 64.011031],
              [107.769202, 64.020085],
              [107.685321, 64.001227],
              [107.685992, 63.987078],
              [107.631215, 63.981369],
              [107.610371, 63.962323],
              [107.617468, 63.947167],
              [107.586667, 63.920754],
              [107.598287, 63.90926],
              [107.565638, 63.897369],
              [107.518391, 63.896714],
              [107.502615, 63.91938],
              [107.4521, 63.921848],
              [107.41087, 63.894269],
              [107.344739, 63.906224],
              [107.372747, 63.912123],
              [107.377132, 63.936817],
              [107.32811, 63.952877],
              [107.343939, 63.95963],
              [107.307045, 63.977563],
              [107.28307, 63.980318],
              [107.272119, 63.971997],
              [107.244995, 63.980972],
              [107.20456, 63.97399],
              [107.195923, 63.982792],
              [107.165218, 63.968644],
              [107.167008, 63.953355],
              [107.124622, 63.948341],
              [107.110581, 63.953772],
              [107.089406, 63.927],
              [107.030661, 63.931933],
              [107.051822, 63.938939],
              [107.013561, 63.945651],
              [106.980515, 63.959],
              [106.977949, 63.970495],
              [106.927203, 63.977354],
              [106.905615, 63.973958],
              [106.878569, 63.983811],
              [106.820713, 63.988111],
              [106.807366, 64.018026],
              [106.775544, 64.019963],
              [106.791712, 64.006107],
              [106.737843, 64.018875],
              [106.730095, 64.027102],
              [106.699577, 64.019565],
              [106.695178, 64.036301],
              [106.664368, 64.044317],
              [106.660701, 64.025953],
              [106.624071, 64.008376],
              [106.623829, 63.994901],
              [106.606458, 63.986782],
              [106.645377, 63.958855],
              [106.696232, 63.948829],
              [106.730351, 63.935006],
              [106.710779, 63.915051],
              [106.721747, 63.904767],
              [106.771227, 63.896273],
              [106.792636, 63.881165],
              [106.767916, 63.84792],
              [106.69859, 63.825001],
              [106.690652, 63.802471],
              [106.672709, 63.796624],
              [106.662936, 63.762951],
              [106.624825, 63.741654],
              [106.602442, 63.722726],
              [106.609913, 63.705721],
              [106.653057, 63.692644],
              [106.657689, 63.678043],
              [106.686944, 63.667414],
              [106.658126, 63.627194],
              [106.615467, 63.618833],
              [106.579205, 63.593051],
              [106.602837, 63.574679],
              [106.59685, 63.560554],
              [106.566476, 63.567658],
              [106.510083, 63.564285],
              [106.506713, 63.548407],
              [106.471391, 63.542362],
              [106.524963, 63.51627],
              [106.583515, 63.497881],
              [106.574595, 63.478266],
              [106.592363, 63.475398],
              [106.594643, 63.454353],
              [106.63955, 63.448441],
              [106.648121, 63.424473],
              [106.676891, 63.429126],
              [106.707287, 63.418612],
              [106.704571, 63.406353],
              [106.72663, 63.40971],
              [106.737036, 63.397767],
              [106.708343, 63.391819],
              [106.719795, 63.375233],
              [106.676789, 63.354783],
              [106.657853, 63.325542],
              [106.620901, 63.316763],
              [106.575891, 63.326768],
              [106.520189, 63.322564],
              [106.501261, 63.339509],
              [106.471253, 63.341899],
              [106.44762, 63.360785],
              [106.411826, 63.353603],
              [106.408223, 63.336717],
              [106.42041, 63.329293],
              [106.407089, 63.318915],
              [106.355847, 63.301955],
              [106.353728, 63.284935],
              [106.375135, 63.274695],
              [106.349248, 63.265265],
              [106.344485, 63.245063],
              [106.421129, 63.234046],
              [106.438764, 63.215343],
              [106.39432, 63.185919],
              [106.38743, 63.168292],
              [106.408901, 63.150564],
              [106.396808, 63.125686],
              [106.353852, 63.107458],
              [106.283663, 63.103242],
              [106.259582, 63.094968],
              [106.184922, 63.085993],
              [106.114888, 63.090599],
              [106.130644, 63.037512],
              [106.170084, 63.006851],
              [106.144528, 62.992879],
              [106.137897, 62.976082],
              [106.192009, 62.968388],
              [106.199095, 62.937283],
              [106.192995, 62.920967],
              [106.28207, 62.909676],
              [106.298498, 62.885849],
              [106.318757, 62.874305],
              [106.354472, 62.882846],
              [106.382422, 62.880641],
              [106.437116, 62.862638],
              [106.455144, 62.844815],
              [106.444204, 62.831941],
              [106.446644, 62.814399],
              [106.461298, 62.803738],
              [106.512518, 62.791116],
              [106.517775, 62.757413],
              [106.503004, 62.744223],
              [106.508711, 62.727007],
              [106.482795, 62.722088],
              [106.459864, 62.708133],
              [106.472495, 62.697612],
              [106.527918, 62.688562],
              [106.575942, 62.6893],
              [106.583433, 62.676953],
              [106.651025, 62.656777],
              [106.664068, 62.661433],
              [106.70764, 62.655683],
              [106.773111, 62.638657],
              [106.8018, 62.611745],
              [106.781993, 62.603921],
              [106.717311, 62.608649],
              [106.688781, 62.569326],
              [106.672482, 62.556457],
              [106.720555, 62.537761],
              [106.738739, 62.507782],
              [106.755899, 62.498125],
              [106.728355, 62.494846],
              [106.664699, 62.477032],
              [106.626944, 62.462393],
              [106.617101, 62.448789],
              [106.575563, 62.424441],
              [106.573039, 62.405644],
              [106.543113, 62.396299],
              [106.492811, 62.398748],
              [106.466151, 62.405801],
              [106.431921, 62.396715],
              [106.460964, 62.359773],
              [106.482329, 62.354975],
              [106.483176, 62.34107],
              [106.462653, 62.330141],
              [106.409253, 62.329623],
              [106.408707, 62.301599],
              [106.387967, 62.291713],
              [106.3487, 62.28343],
              [106.402422, 62.265261],
              [106.416793, 62.253832],
              [106.464277, 62.261178],
              [106.455594, 62.234367],
              [106.428211, 62.212501],
              [106.44957, 62.199037],
              [106.424549, 62.183381],
              [106.434062, 62.177641],
              [106.424001, 62.160939],
              [106.387729, 62.145273],
              [106.387597, 62.128528],
              [106.411518, 62.100137],
              [106.393384, 62.077754],
              [106.404861, 62.057566],
              [106.3957, 62.042693],
              [106.333364, 62.03452],
              [106.320701, 62.037017],
              [106.249382, 62.012443],
              [106.247703, 61.998921],
              [106.207779, 61.989002],
              [106.208155, 61.979379],
              [106.178883, 61.966568],
              [106.111178, 61.957391],
              [106.06028, 61.933564],
              [106.048681, 61.918748],
              [106.002677, 61.898663],
              [105.934138, 61.859537],
              [105.899614, 61.844031],
              [105.893402, 61.824892],
              [105.917216, 61.798131],
              [105.937476, 61.787721],
              [105.934789, 61.773607],
              [105.949343, 61.74144],
              [105.973387, 61.713598],
              [105.964797, 61.707008],
              [105.979911, 61.690511],
              [105.968187, 61.687447],
              [105.904092, 61.688776],
              [105.8773, 61.678677],
              [105.879955, 61.67094],
              [105.841793, 61.661362],
              [105.811364, 61.629894],
              [105.775692, 61.645481],
              [105.712403, 61.662096],
              [105.673428, 61.656237],
              [105.628138, 61.639944],
              [105.609098, 61.623819],
              [105.579028, 61.616942],
              [105.593054, 61.600374],
              [105.58489, 61.590493],
              [105.550164, 61.578879],
              [105.508074, 61.579874],
              [105.452583, 61.559107],
              [105.391587, 61.549735],
              [105.359109, 61.539433],
              [105.369996, 61.534297],
              [105.347963, 61.521766],
              [105.319176, 61.515602],
              [105.313656, 61.498335],
              [105.297168, 61.489227],
              [105.295796, 61.475572],
              [105.257449, 61.462705],
              [105.291461, 61.450107],
              [105.283294, 61.425124],
              [105.225944, 61.41389],
              [105.185177, 61.413951],
              [105.156108, 61.424142],
              [105.098302, 61.410716],
              [105.062238, 61.406284],
              [105.010333, 61.41283],
              [104.994846, 61.40226],
              [104.947158, 61.402013],
              [104.910016, 61.395402],
              [104.906616, 61.372716],
              [104.884096, 61.372045],
              [104.835414, 61.357744],
              [104.788802, 61.354829],
              [104.776858, 61.347387],
              [104.82082, 61.335813],
              [104.864946, 61.336101],
              [104.950299, 61.316054],
              [104.939594, 61.294197],
              [104.990335, 61.27301],
              [104.992932, 61.242547],
              [105.030005, 61.2198],
              [105.022354, 61.197355],
              [105.037118, 61.19462],
              [105.044477, 61.177693],
              [104.988389, 61.195099],
              [104.964278, 61.188266],
              [104.906066, 61.195008],
              [104.871888, 61.187557],
              [104.856982, 61.191937],
              [104.830195, 61.162214],
              [104.760943, 61.135624],
              [104.734801, 61.11871],
              [104.711036, 61.113198],
              [104.716797, 61.100324],
              [104.690556, 61.086311],
              [104.626259, 61.079141],
              [104.615484, 61.067828],
              [104.616096, 61.047579],
              [104.64374, 61.039994],
              [104.629029, 61.030928],
              [104.624586, 61.013219],
              [104.604374, 61.005381],
              [104.623214, 60.988873],
              [104.583249, 60.959834],
              [104.581359, 60.936311],
              [104.61924, 60.922408],
              [104.633952, 60.905524],
              [104.6092, 60.885441],
              [104.554574, 60.870246],
              [104.532673, 60.868628],
              [104.468419, 60.850633],
              [104.487466, 60.816618],
              [104.481644, 60.808162],
              [104.504313, 60.796608],
              [104.50987, 60.78285],
              [104.548906, 60.77978],
              [104.57905, 60.768832],
              [104.609339, 60.724322],
              [104.590342, 60.702114],
              [104.559328, 60.68408],
              [104.564516, 60.665747],
              [104.51591, 60.639247],
              [104.503669, 60.61562],
              [104.539427, 60.613585],
              [104.519795, 60.601689],
              [104.533225, 60.592001],
              [104.571955, 60.586772],
              [104.56568, 60.569371],
              [104.548605, 60.565655],
              [104.560996, 60.552497],
              [104.602468, 60.535899],
              [104.609924, 60.522461],
              [104.644574, 60.518049],
              [104.628356, 60.51049],
              [104.652389, 60.506177],
              [104.625882, 60.495127],
              [104.664893, 60.462951],
              [104.649103, 60.454612],
              [104.677473, 60.422012],
              [104.644667, 60.424673],
              [104.656759, 60.406016],
              [104.704567, 60.39267],
              [104.724367, 60.393497],
              [104.750592, 60.383028],
              [104.795135, 60.373956],
              [104.799699, 60.361351],
              [104.845133, 60.34965],
              [104.870535, 60.328832],
              [104.853421, 60.324698],
              [104.862965, 60.311182],
              [104.903699, 60.299521],
              [104.948328, 60.303205],
              [104.977571, 60.289098],
              [105.018436, 60.298164],
              [105.050983, 60.296374],
              [105.079236, 60.301824],
              [105.111023, 60.287666],
              [105.149195, 60.289945],
              [105.180137, 60.282007],
              [105.200851, 60.285541],
              [105.216103, 60.276489],
              [105.241929, 60.275851],
              [105.256112, 60.284971],
              [105.306181, 60.292288],
              [105.324678, 60.299431],
              [105.343879, 60.293087],
              [105.374781, 60.264614],
              [105.391979, 60.273176],
              [105.464623, 60.252154],
              [105.459082, 60.23198],
              [105.477055, 60.219563],
              [105.508445, 60.226811],
              [105.502186, 60.215728],
              [105.469211, 60.205336],
              [105.471038, 60.182998],
              [105.460109, 60.16896],
              [105.422475, 60.15293],
              [105.436971, 60.096103],
              [105.452837, 60.090941],
              [105.471144, 60.048826],
              [105.495969, 60.041132],
              [105.485104, 60.02086],
              [105.504517, 60.000523],
              [105.49359, 59.991477],
              [105.433935, 59.971849],
              [105.433446, 59.961634],
              [105.409822, 59.947871],
              [105.440569, 59.927161],
              [105.425828, 59.903451],
              [105.451023, 59.886618],
              [105.483521, 59.878247],
              [105.480915, 59.869644],
              [105.509548, 59.857241],
              [105.509932, 59.843756],
              [105.477711, 59.83568],
              [105.432799, 59.857575],
              [105.416967, 59.856854],
              [105.379827, 59.840989],
              [105.342908, 59.843228],
              [105.310073, 59.853467],
              [105.25794, 59.860291],
              [105.268315, 59.872921],
              [105.179557, 59.865826],
              [105.145734, 59.883033],
              [105.104916, 59.867689],
              [105.070091, 59.876918],
              [105.051769, 59.871403],
              [105.060717, 59.855065],
              [105.02328, 59.843555],
              [104.97972, 59.854009],
              [104.978901, 59.842011],
              [105.018908, 59.834884],
              [105.006752, 59.826926],
              [105.023023, 59.812788],
              [105.014411, 59.805246],
              [105.029727, 59.793898],
              [105.025709, 59.779466],
              [104.990019, 59.763491],
              [104.953264, 59.77554],
              [104.935379, 59.765316],
              [104.918291, 59.77095],
              [104.861704, 59.755578],
              [104.862224, 59.743759],
              [104.834162, 59.730778],
              [104.794583, 59.701142],
              [104.769354, 59.695339],
              [104.762143, 59.684813],
              [104.778188, 59.674387],
              [104.807681, 59.671523],
              [104.80803, 59.663298],
              [104.78094, 59.640744],
              [104.816573, 59.625118],
              [104.842341, 59.606501],
              [104.87328, 59.607069],
              [104.88176, 59.595197],
              [104.855917, 59.583707],
              [104.849694, 59.560951],
              [104.857115, 59.549555],
              [104.908795, 59.519307],
              [104.9261, 59.517061],
              [104.94601, 59.493133],
              [104.966461, 59.482864],
              [104.967757, 59.470628],
              [104.993789, 59.460772],
              [105.071873, 59.467761],
              [105.10969, 59.467616],
              [105.148868, 59.485218],
              [105.194948, 59.476901],
              [105.198106, 59.458067],
              [105.235691, 59.443036],
              [105.242476, 59.429599],
              [105.217376, 59.424238],
              [105.197901, 59.403063],
              [105.205738, 59.389033],
              [105.229248, 59.387152],
              [105.219528, 59.342678],
              [105.177321, 59.317438],
              [105.185544, 59.291112],
              [105.211775, 59.289349],
              [105.214896, 59.26885],
              [105.246886, 59.263745],
              [105.271766, 59.270229],
              [105.304037, 59.245387],
              [105.294901, 59.234835],
              [105.311275, 59.225292],
              [105.321492, 59.202635],
              [105.33618, 59.200578],
              [105.319947, 59.17371],
              [105.293526, 59.155833],
              [105.320945, 59.144363],
              [105.341176, 59.144133],
              [105.368066, 59.118101],
              [105.301725, 59.075561],
              [105.246366, 59.063144],
              [105.225394, 59.066272],
              [105.175629, 59.057082],
              [105.135744, 59.061288],
              [105.099561, 59.032748],
              [105.112795, 59.027094],
              [105.054788, 58.996004],
              [105.017485, 58.98746],
              [104.969497, 58.966859],
              [104.920861, 58.9587],
              [104.915176, 58.93244],
              [104.893806, 58.931533],
              [104.882694, 58.921484],
              [104.89112, 58.908601],
              [104.836726, 58.901237],
              [104.767418, 58.844875],
              [104.767295, 58.829013],
              [104.754618, 58.807188],
              [104.786016, 58.802848],
              [104.780006, 58.793689],
              [104.816148, 58.776518],
              [104.817329, 58.76951],
              [104.857511, 58.760175],
              [104.894032, 58.744527],
              [104.875431, 58.736609],
              [104.877179, 58.726927],
              [104.817273, 58.692817],
              [104.804707, 58.674798],
              [104.746754, 58.676301],
              [104.709414, 58.66749],
              [104.684687, 58.652818],
              [104.653198, 58.644182],
              [104.635804, 58.651085],
              [104.618101, 58.643905],
              [104.62047, 58.625139],
              [104.590876, 58.623504],
              [104.552973, 58.639477],
              [104.525186, 58.638368],
              [104.500545, 58.624432],
              [104.493782, 58.612579],
              [104.467538, 58.608789],
              [104.431421, 58.617487],
              [104.407994, 58.629144],
              [104.409082, 58.645664],
              [104.377632, 58.664646],
              [104.34669, 58.672869],
              [104.386335, 58.707996],
              [104.382345, 58.726987],
              [104.351727, 58.727193],
              [104.33663, 58.734186],
              [104.299829, 58.729001],
              [104.292227, 58.720821],
              [104.257158, 58.715693],
              [104.232845, 58.72559],
              [104.212756, 58.755292],
              [104.199008, 58.761505],
              [104.211024, 58.786293],
              [104.191929, 58.785699],
              [104.180736, 58.765371],
              [104.149258, 58.766533],
              [104.129952, 58.774841],
              [104.07048, 58.787199],
              [104.030155, 58.784704],
              [104.01755, 58.761023],
              [104.0022, 58.757994],
              [103.991519, 58.73978],
              [103.949987, 58.722397],
              [103.930402, 58.731564],
              [103.887545, 58.739103],
              [103.886533, 58.749313],
              [103.916827, 58.760324],
              [103.942868, 58.761312],
              [103.965114, 58.770761],
              [103.9504, 58.788346],
              [103.949644, 58.809609],
              [103.958693, 58.828634],
              [103.94918, 58.832537],
              [103.977297, 58.846605],
              [103.985972, 58.875309],
              [103.95495, 58.873042],
              [103.940493, 58.891875],
              [103.946214, 58.903804],
              [103.899953, 58.921479],
              [103.861841, 58.920615],
              [103.839003, 58.913105],
              [103.781722, 58.908954],
              [103.749557, 58.918747],
              [103.724801, 58.917198],
              [103.704766, 58.933812],
              [103.665201, 58.931628],
              [103.630515, 58.922413],
              [103.593081, 58.944529],
              [103.607961, 58.963285],
              [103.588289, 58.968954],
              [103.582219, 58.985107],
              [103.56322, 58.988047],
              [103.564626, 58.998519],
              [103.529448, 58.999907],
              [103.483839, 59.015831],
              [103.459375, 59.009007],
              [103.432781, 59.026453],
              [103.392745, 59.029943],
              [103.386206, 59.051429],
              [103.358925, 59.062435],
              [103.353436, 59.085537],
              [103.316974, 59.111092],
              [103.324786, 59.121472],
              [103.284595, 59.153328],
              [103.244019, 59.16865],
              [103.234371, 59.1807],
              [103.252646, 59.192354],
              [103.230686, 59.207208],
              [103.217734, 59.237867],
              [103.285089, 59.242813],
              [103.30022, 59.23707],
              [103.329025, 59.24083],
              [103.359801, 59.235042],
              [103.352725, 59.266382],
              [103.320186, 59.274344],
              [103.303907, 59.267894],
              [103.272023, 59.285596],
              [103.232277, 59.290707],
              [103.212599, 59.278377],
              [103.217944, 59.260507],
              [103.136945, 59.258482],
              [103.05614, 59.285761],
              [103.062651, 59.29584],
              [103.04648, 59.310453],
              [103.018591, 59.31841],
              [102.989457, 59.310337],
              [102.983235, 59.295683],
              [102.939373, 59.29874],
              [102.922113, 59.278857],
              [102.897103, 59.275841],
              [102.882485, 59.255104],
              [102.84969, 59.243395],
              [102.8668, 59.218304],
              [102.831598, 59.205006],
              [102.825359, 59.19379],
              [102.787413, 59.202063],
              [102.648689, 59.197666],
              [102.630931, 59.211547],
              [102.590279, 59.202955],
              [102.567676, 59.209606],
              [102.550634, 59.188665],
              [102.497896, 59.186055],
              [102.483397, 59.16528],
              [102.458288, 59.150553],
              [102.426481, 59.144976],
              [102.437541, 59.13642],
              [102.419657, 59.127618],
              [102.438707, 59.115759],
              [102.402553, 59.083479],
              [102.426267, 59.060678],
              [102.460247, 59.051927],
              [102.440065, 59.039385],
              [102.439856, 59.021138],
              [102.472862, 59.022575],
              [102.491842, 59.010768],
              [102.479614, 58.994882],
              [102.438545, 58.993114],
              [102.455978, 58.980757],
              [102.445717, 58.970297],
              [102.449866, 58.939753],
              [102.484602, 58.936686],
              [102.481107, 58.926595],
              [102.510531, 58.908539],
              [102.56121, 58.904955],
              [102.535998, 58.883203],
              [102.558039, 58.864971],
              [102.542404, 58.847957],
              [102.565563, 58.839695],
              [102.547094, 58.827091],
              [102.581494, 58.790032],
              [102.548648, 58.762945],
              [102.565799, 58.740148],
              [102.545936, 58.734049],
              [102.51853, 58.707781],
              [102.500195, 58.710782],
              [102.455193, 58.695531],
              [102.451638, 58.680927],
              [102.43506, 58.670759],
              [102.388363, 58.675779],
              [102.393447, 58.66424],
              [102.361201, 58.660025],
              [102.338043, 58.647934],
              [102.277669, 58.590393],
              [102.298686, 58.585039],
              [102.276334, 58.572473],
              [102.27234, 58.55896],
              [102.250317, 58.563146],
              [102.254439, 58.5524],
              [102.221994, 58.538475],
              [102.206713, 58.525541],
              [102.146518, 58.51205],
              [102.137653, 58.503112],
              [102.111543, 58.499409],
              [102.103653, 58.489791],
              [102.078054, 58.491776],
              [102.06236, 58.484958],
              [102.029999, 58.491434],
              [102.006388, 58.476891],
              [101.95293, 58.479271],
              [101.922738, 58.465577],
              [101.805524, 58.455298],
              [101.760063, 58.439583],
              [101.730349, 58.420367],
              [101.699847, 58.411146],
              [101.683149, 58.397113],
              [101.683491, 58.38076],
              [101.669009, 58.368819],
              [101.68143, 58.36107],
              [101.643061, 58.33952],
              [101.640047, 58.325694],
              [101.605191, 58.308043],
              [101.585095, 58.28955],
              [101.556074, 58.275274],
              [101.552672, 58.254658],
              [101.498147, 58.229704],
              [101.436368, 58.237435],
              [101.363814, 58.232678],
              [101.31162, 58.209801],
              [101.288757, 58.190482],
              [101.273018, 58.194269],
              [101.256267, 58.17329],
              [101.260576, 58.166736],
              [101.200103, 58.129044],
              [101.149409, 58.123016],
              [101.150903, 58.108668],
              [101.106576, 58.101445],
              [101.123244, 58.060815],
              [101.166866, 58.044212],
              [101.153013, 58.009561],
              [101.137838, 57.997745],
              [101.130978, 57.978067],
              [101.13863, 57.968196],
              [101.104061, 57.962422],
              [101.084098, 57.942279],
              [101.039497, 57.930733],
              [101.045166, 57.920345],
              [101.022095, 57.911897],
              [101.039069, 57.902746],
              [101.02328, 57.88148],
              [100.973949, 57.864191],
              [100.942755, 57.869296],
              [100.921903, 57.862338],
              [100.896562, 57.875421],
              [100.868133, 57.878432],
              [100.81856, 57.875391],
              [100.852007, 57.855177],
              [100.857583, 57.835427],
              [100.849936, 57.823824],
              [100.870399, 57.819516],
              [100.852898, 57.809476],
              [100.867261, 57.785651],
              [100.908093, 57.774067],
              [100.892875, 57.756723],
              [100.891027, 57.741212],
              [100.873023, 57.736424],
              [100.856363, 57.701524],
              [100.839447, 57.694989],
              [100.855252, 57.677555],
              [100.820849, 57.675465],
              [100.813734, 57.665043],
              [100.845827, 57.646274],
              [100.813714, 57.638003],
              [100.843303, 57.61618],
              [100.847663, 57.606073],
              [100.898465, 57.590878],
              [100.948439, 57.569257],
              [100.947622, 57.547755],
              [100.959822, 57.546437],
              [100.945524, 57.531206],
              [100.961421, 57.524779],
              [100.942974, 57.518587],
              [100.956205, 57.505783],
              [100.940483, 57.500796],
              [100.9657, 57.493451],
              [100.97034, 57.483276],
              [100.940785, 57.469054],
              [100.938226, 57.451977],
              [100.912935, 57.444816],
              [100.88165, 57.447367],
              [100.888553, 57.455109],
              [100.856577, 57.463785],
              [100.843258, 57.453342],
              [100.807931, 57.441255],
              [100.807796, 57.431807],
              [100.785697, 57.407934],
              [100.763049, 57.399517],
              [100.71931, 57.412899],
              [100.718125, 57.420523],
              [100.688484, 57.444219],
              [100.681985, 57.456311],
              [100.649441, 57.468151],
              [100.622697, 57.469449],
              [100.591108, 57.483639],
              [100.577303, 57.480782],
              [100.545317, 57.491458],
              [100.515623, 57.493958],
              [100.490238, 57.518328],
              [100.472043, 57.516283],
              [100.454775, 57.529141],
              [100.431446, 57.534281],
              [100.403319, 57.552781],
              [100.408114, 57.57103],
              [100.426355, 57.576471],
              [100.413886, 57.597231],
              [100.387441, 57.606141],
              [100.381322, 57.625758],
              [100.353229, 57.635808],
              [100.359618, 57.649644],
              [100.382227, 57.655401],
              [100.378372, 57.67076],
              [100.330276, 57.675691],
              [100.322777, 57.684373],
              [100.349046, 57.705432],
              [100.350244, 57.714596],
              [100.375006, 57.728841],
              [100.37883, 57.741991],
              [100.334834, 57.747222],
              [100.323147, 57.759103],
              [100.286118, 57.767915],
              [100.290919, 57.794192],
              [100.316027, 57.80529],
              [100.270222, 57.810403],
              [100.2191, 57.836656],
              [100.19112, 57.832735],
              [100.137241, 57.859669],
              [100.147703, 57.870586],
              [100.127362, 57.901624],
              [100.129432, 57.919499],
              [100.106256, 57.926413],
              [100.103555, 57.940258],
              [100.080626, 57.945216],
              [100.074821, 57.960291],
              [100.090482, 57.9679],
              [100.063989, 57.977071],
              [100.081665, 57.990456],
              [100.064688, 58.000636],
              [100.089296, 58.013702],
              [100.080133, 58.022005],
              [100.099231, 58.034453],
              [100.091916, 58.050721],
              [100.075183, 58.052204],
              [100.090969, 58.068641],
              [100.044293, 58.076586],
              [100.021414, 58.072751],
              [100.003762, 58.082883],
              [99.988983, 58.076662],
              [99.942573, 58.082863],
              [99.908967, 58.072933],
              [99.87976, 58.077524],
              [99.826649, 58.068316],
              [99.812033, 58.057505],
              [99.757661, 58.084158],
              [99.681723, 58.082698],
              [99.641134, 58.064024],
              [99.632899, 58.053343],
              [99.648951, 58.026031],
              [99.637198, 58.017257],
              [99.63747, 57.992361],
              [99.608068, 57.993752],
              [99.588723, 57.960334],
              [99.570528, 57.954951],
              [99.561197, 57.937168],
              [99.509926, 57.937443],
              [99.511289, 57.923357],
              [99.533668, 57.911382],
              [99.483612, 57.899699],
              [99.498903, 57.883776],
              [99.479816, 57.866085],
              [99.477749, 57.836266],
              [99.441665, 57.82796],
              [99.458462, 57.808769],
              [99.433516, 57.78925],
              [99.394282, 57.782604],
              [99.346934, 57.798072],
              [99.298169, 57.781619],
              [99.255329, 57.779277],
              [99.238358, 57.769328],
              [99.204509, 57.76101],
              [99.146732, 57.768042],
              [99.135858, 57.773995],
              [99.086604, 57.778079],
              [99.039856, 57.745543],
              [99.004298, 57.742816],
              [98.978912, 57.71777],
              [98.965691, 57.726323],
              [98.926157, 57.725117],
              [98.913085, 57.711384],
              [98.885981, 57.71677],
              [98.884167, 57.72871],
              [98.848295, 57.72796],
              [98.844412, 57.752969],
              [98.809075, 57.75191],
              [98.790008, 57.762694],
              [98.794011, 57.78201],
              [98.747524, 57.797018],
              [98.729742, 57.79401],
              [98.695171, 57.776939],
              [98.644834, 57.772471],
              [98.606649, 57.776458],
              [98.591332, 57.783599],
              [98.503782, 57.792259],
              [98.451181, 57.794896],
              [98.434624, 57.800587],
              [98.39162, 57.796704],
              [98.32675, 57.808378],
              [98.246612, 57.807987],
              [98.212939, 57.815685],
              [98.142523, 57.817784],
              [98.107961, 57.828657],
              [98.049663, 57.821935],
              [98.042383, 57.827704],
              [97.990255, 57.831815],
              [97.9532, 57.828574],
              [97.828832, 57.667319],
              [97.715826, 57.521905],
              [97.572395, 57.334005],
              [97.511138, 57.248205],
              [97.34397, 57.032281],
              [97.377274, 57.035926],
              [97.383541, 57.024655],
              [97.471899, 56.997196],
              [97.485493, 57.005682],
              [97.506464, 57.00421],
              [97.501003, 56.988336],
              [97.530581, 56.969848],
              [97.584583, 56.989131],
              [97.598219, 56.988393],
              [97.589992, 56.969878],
              [97.621356, 56.963274],
              [97.654746, 56.975583],
              [97.67146, 56.9738],
              [97.676532, 56.95965],
              [97.653767, 56.93938],
              [97.63156, 56.907463],
              [97.595403, 56.892561],
              [97.579817, 56.905519],
              [97.523555, 56.899874],
              [97.47862, 56.883111],
              [97.485069, 56.873977],
              [97.469086, 56.840144],
              [97.472194, 56.822412],
              [97.495791, 56.817765],
              [97.528941, 56.790406],
              [97.562729, 56.784737],
              [97.606165, 56.793016],
              [97.619008, 56.800112],
              [97.669615, 56.805594],
              [97.785914, 56.797093],
              [97.802869, 56.774852],
              [97.764327, 56.738818],
              [97.748529, 56.732598],
              [97.76868, 56.721448],
              [97.758067, 56.702384],
              [97.767308, 56.687381],
              [97.762106, 56.674789],
              [97.775369, 56.651324],
              [97.737344, 56.646309],
              [97.736512, 56.629244],
              [97.766105, 56.621161],
              [97.779681, 56.591282],
              [97.758451, 56.575144],
              [97.741605, 56.547458],
              [97.796848, 56.535762],
              [97.879933, 56.534101],
              [97.879988, 56.375359],
              [97.557019, 56.376301],
              [97.556301, 56.280184],
              [97.570024, 56.270358],
              [97.574179, 56.187314],
              [97.527279, 56.187359],
              [97.471782, 56.194531],
              [97.456248, 56.177814],
              [97.42783, 56.176981],
              [97.409098, 56.163546],
              [97.440408, 56.142725],
              [97.437077, 56.104107],
              [97.41236, 56.097372],
              [97.359519, 56.03207],
              [97.311312, 56.039563],
              [97.301181, 56.074375],
              [97.258207, 56.086972],
              [97.131942, 56.112529],
              [97.108216, 56.084349],
              [97.08366, 56.035048],
              [97.063652, 56.004181],
              [96.990726, 56.003892],
              [96.992523, 55.893471],
              [96.990024, 55.851758],
              [96.966233, 55.85],
              [96.91657, 55.861772],
              [96.889621, 55.830459],
              [96.869905, 55.829822],
              [96.844137, 55.839301],
              [96.833579, 55.827139],
              [96.833726, 55.806123],
              [96.817401, 55.805478],
              [96.786108, 55.78958],
              [96.771603, 55.76643],
              [96.775054, 55.746576],
              [96.754383, 55.733953],
              [96.780495, 55.713858],
              [96.820508, 55.708273],
              [96.82558, 55.688982],
              [96.751024, 55.662538],
              [96.743994, 55.656761],
              [96.770664, 55.644526],
              [96.784491, 55.630508],
              [96.831486, 55.61488],
              [96.854371, 55.599766],
              [96.862385, 55.577909],
              [96.810292, 55.569201],
              [96.81105, 55.546148],
              [96.839419, 55.531729],
              [96.82844, 55.51792],
              [96.836734, 55.4928],
              [96.854558, 55.482769],
              [96.854324, 55.469745],
              [96.837476, 55.465881],
              [96.847501, 55.431695],
              [96.890849, 55.397195],
              [96.885755, 55.386941],
              [96.899538, 55.361376],
              [96.925033, 55.344868],
              [96.920596, 55.333972],
              [96.943629, 55.321854],
              [96.927883, 55.301909],
              [96.909051, 55.30135],
              [96.875425, 55.29096],
              [96.83737, 55.295286],
              [96.821281, 55.284655],
              [96.781277, 55.288453],
              [96.773878, 55.271731],
              [96.744529, 55.273273],
              [96.719938, 55.268059],
              [96.731407, 55.253171],
              [96.708441, 55.247094],
              [96.678578, 55.25414],
              [96.657122, 55.234882],
              [96.684606, 55.225656],
              [96.689476, 55.179313],
              [96.667786, 55.153987],
              [96.677974, 55.118032],
              [96.651298, 55.104142],
              [96.685306, 55.082735],
              [96.658423, 55.058557],
              [96.641432, 55.060369],
              [96.61892, 55.041736],
              [96.593417, 55.030175],
              [96.614441, 55.012573],
              [96.608013, 55.005847],
              [96.647731, 54.965086],
              [96.67899, 54.957066],
              [96.691689, 54.946426],
              [96.703631, 54.912702],
              [96.716457, 54.899447],
              [96.700208, 54.891291],
              [96.710014, 54.869358],
              [96.691239, 54.835289],
              [96.668025, 54.832348],
              [96.670805, 54.803742],
              [96.661748, 54.791259],
              [96.626487, 54.771368],
              [96.629927, 54.760019],
              [96.610419, 54.73163],
              [96.587163, 54.720109],
              [96.566126, 54.690612],
              [96.583105, 54.676086],
              [96.575293, 54.645557],
              [96.580866, 54.626644],
              [96.561772, 54.596555],
              [96.530267, 54.582593],
              [96.541091, 54.570991],
              [96.538823, 54.529775],
              [96.486433, 54.515854],
              [96.458816, 54.520378],
              [96.433191, 54.530879],
              [96.409172, 54.533728],
              [96.394652, 54.542958],
              [96.374589, 54.541924],
              [96.338091, 54.55553],
              [96.290252, 54.557529],
              [96.274977, 54.548123],
              [96.243881, 54.549939],
              [96.210108, 54.5365],
              [96.178883, 54.535775],
              [96.175712, 54.541343],
              [96.126982, 54.545837],
              [96.114081, 54.557474],
              [96.088654, 54.563586],
              [96.08131, 54.575294],
              [96.064756, 54.574173],
              [96.025287, 54.554361],
              [96.036602, 54.532191],
              [96.019719, 54.521692],
              [95.99966, 54.526478],
              [96.019513, 54.511783],
              [95.979573, 54.508195],
              [95.977474, 54.475729],
              [95.95264, 54.466362],
              [95.978091, 54.446602],
              [95.955548, 54.446452],
              [95.939504, 54.423506],
              [95.904026, 54.389473],
              [95.871519, 54.393452],
              [95.846173, 54.422233],
              [95.817549, 54.424396],
              [95.752729, 54.422432],
              [95.743264, 54.411887],
              [95.711193, 54.413813],
              [95.708534, 54.404997],
              [95.681798, 54.391417],
              [95.694924, 54.372684],
              [95.687617, 54.355889],
              [95.662508, 54.337769],
              [95.655021, 54.317159],
              [95.664051, 54.306168],
              [95.647701, 54.299588],
              [95.667915, 54.278497],
              [95.66262, 54.254737],
              [95.684428, 54.237435],
              [95.704315, 54.237624],
              [95.74029, 54.226946],
              [95.745018, 54.215891],
              [95.788824, 54.218971],
              [95.802983, 54.227496],
              [95.826432, 54.211641],
              [95.847958, 54.20614],
              [95.857406, 54.188603],
              [95.847152, 54.18209],
              [95.86043, 54.170125],
              [95.877712, 54.174857],
              [95.906078, 54.147992],
              [95.912101, 54.116089],
              [95.938262, 54.111405],
              [95.936125, 54.103321],
              [95.981227, 54.08989],
              [95.997764, 54.080847],
              [96.073598, 54.08123],
              [96.098333, 54.061608],
              [96.09246, 54.045825],
              [96.057841, 54.028031],
              [96.082975, 54.015322],
              [96.061071, 53.995375],
              [96.07278, 53.985312],
              [96.117032, 53.983111],
              [96.127536, 53.959514],
              [96.144403, 53.959143],
              [96.14831, 53.94181],
              [96.187094, 53.907754],
              [96.242285, 53.877276],
              [96.277803, 53.865063],
              [96.303382, 53.845955],
              [96.340136, 53.83732],
              [96.3665, 53.809771],
              [96.385146, 53.799177],
              [96.39889, 53.758916],
              [96.432967, 53.751444],
              [96.456532, 53.751708],
              [96.456938, 53.741666],
              [96.483, 53.730263],
              [96.490946, 53.704619],
              [96.483179, 53.693692],
              [96.517754, 53.681468],
              [96.524859, 53.673051],
              [96.55448, 53.676931],
              [96.591528, 53.659603],
              [96.599065, 53.673195],
              [96.616067, 53.677162],
              [96.647152, 53.656784],
              [96.647236, 53.642692],
              [96.689007, 53.645835],
              [96.687892, 53.632349],
              [96.705965, 53.623475],
              [96.674148, 53.604158],
              [96.686377, 53.590738],
              [96.659105, 53.573477],
              [96.658672, 53.56417],
              [96.62772, 53.556919],
              [96.621443, 53.56655],
              [96.589409, 53.552427],
              [96.559323, 53.551778],
              [96.556185, 53.545068],
              [96.52361, 53.5468],
              [96.482269, 53.541713],
              [96.483567, 53.530999],
              [96.46084, 53.516822],
              [96.439737, 53.513467],
              [96.440278, 53.535761],
              [96.423395, 53.539441],
              [96.384543, 53.557946],
              [96.387249, 53.566712],
              [96.35121, 53.571366],
              [96.374695, 53.593768],
              [96.333353, 53.59788],
              [96.310085, 53.587816],
              [96.272532, 53.590846],
              [96.277077, 53.608161],
              [96.240931, 53.618767],
              [96.225455, 53.597772],
              [96.222208, 53.568877],
              [96.240715, 53.557946],
              [96.232057, 53.517146],
              [96.193097, 53.509679],
              [96.187253, 53.51509],
              [96.135739, 53.511844],
              [96.103488, 53.504484],
              [96.112795, 53.485816],
              [96.110523, 53.451726],
              [96.075567, 53.451077],
              [96.033252, 53.457029],
              [95.993534, 53.48008],
              [95.976218, 53.479323],
              [95.967127, 53.491281],
              [95.93163, 53.488467],
              [95.885743, 53.501454],
              [95.860419, 53.493229],
              [95.807498, 53.491281],
              [95.757066, 53.505025],
              [95.732224, 53.52522],
              [95.708041, 53.506702],
              [95.685206, 53.506377],
              [95.656852, 53.486356],
              [95.66064, 53.47521],
              [95.65209, 53.455621],
              [95.594624, 53.447342],
              [95.577308, 53.438143],
              [95.539214, 53.431866],
              [95.531313, 53.438468],
              [95.490838, 53.415687],
              [95.495275, 53.395017],
              [95.47309, 53.400698],
              [95.423848, 53.395071],
              [95.428394, 53.375374],
              [95.405126, 53.365634],
              [95.375472, 53.381435],
              [95.336404, 53.376456],
              [95.30253, 53.391283],
              [95.284132, 53.410059],
              [95.309673, 53.421747],
              [95.304586, 53.444907],
              [95.267249, 53.448045],
              [95.248418, 53.459841],
              [95.212813, 53.468174],
              [95.152966, 53.434192],
              [95.146148, 53.4185],
              [95.112707, 53.397992],
              [95.094634, 53.401563],
              [95.061517, 53.394529],
              [95.035869, 53.366121],
              [94.995177, 53.376835],
              [94.991064, 53.400319],
              [94.954593, 53.404864],
              [94.942147, 53.388631],
              [94.925697, 53.400427],
              [94.884681, 53.393176],
              [94.812821, 53.392797],
              [94.782735, 53.381866],
              [94.771588, 53.387223],
              [94.744424, 53.377808],
              [94.72029, 53.376293],
              [94.698538, 53.358923],
              [94.677218, 53.357516],
              [94.657413, 53.338577],
              [94.618345, 53.33111],
              [94.600813, 53.305732],
              [94.606332, 53.291501],
              [94.631764, 53.286198],
              [94.677002, 53.28728],
              [94.68685, 53.257573],
              [94.716503, 53.240799],
              [94.698322, 53.21515],
              [94.65341, 53.214285],
              [94.662609, 53.200919],
              [94.657089, 53.187175],
              [94.62538, 53.183712],
              [94.602437, 53.167803],
              [94.616614, 53.159578],
              [94.607848, 53.135228],
              [94.620943, 53.126354],
              [94.590208, 53.119969],
              [94.585879, 53.090911],
              [94.575706, 53.07457],
              [94.600489, 53.046595],
              [94.581226, 53.04021],
              [94.570187, 53.02073],
              [94.583282, 52.996542],
              [94.555577, 52.977278],
              [94.542374, 52.975222],
              [94.525383, 52.958123],
              [94.51283, 52.935072],
              [94.525925, 52.910019],
              [94.51402, 52.909477],
              [94.493231, 52.891667],
              [94.449952, 52.893027],
              [94.399196, 52.914888],
              [94.378525, 52.911966],
              [94.354283, 52.895679],
              [94.331448, 52.893298],
              [94.296925, 52.898276],
              [94.279934, 52.886263],
              [94.261753, 52.883774],
              [94.244221, 52.864402],
              [94.209265, 52.859532],
              [94.191083, 52.845788],
              [94.157642, 52.830745],
              [94.141842, 52.831178],
              [94.159374, 52.813159],
              [94.131886, 52.782208],
              [94.140002, 52.77539],
              [94.13957, 52.754449],
              [94.093575, 52.73854],
              [94.09087, 52.714461],
              [94.102883, 52.68589],
              [94.093142, 52.676258],
              [94.058078, 52.660891],
              [94.030265, 52.659159],
              [93.999205, 52.649311],
              [93.97702, 52.628153],
              [93.941523, 52.613327],
              [93.965223, 52.601747],
              [93.943363, 52.566196],
              [93.919445, 52.558404],
              [93.912627, 52.543253],
              [93.88752, 52.525992],
              [93.859707, 52.519877],
              [93.860248, 52.512193],
              [93.829404, 52.495689],
              [93.832435, 52.478482],
              [93.807977, 52.450886],
              [93.767515, 52.451383],
              [93.765878, 52.434219],
              [93.754082, 52.427726],
              [93.765445, 52.381136],
              [93.752567, 52.362035],
              [93.725403, 52.344395],
              [93.716096, 52.326538],
              [93.687092, 52.316365],
              [93.678543, 52.263932],
              [93.66653, 52.257547],
              [93.656899, 52.237039],
              [93.676812, 52.212364],
              [93.642072, 52.192992],
              [93.622917, 52.196239],
              [93.59348, 52.189637],
              [93.576273, 52.166857],
              [93.546404, 52.176488],
              [93.498893, 52.169832],
              [93.472163, 52.171023],
              [93.431796, 52.149487],
              [93.420216, 52.135418],
              [93.394784, 52.127193],
              [93.363725, 52.124487],
              [93.337751, 52.128059],
              [93.309181, 52.104683],
              [93.266325, 52.084229],
              [93.24717, 52.063234],
              [93.237214, 52.037694],
              [93.204206, 52.032283],
              [93.188514, 52.035421],
              [93.146524, 52.015617],
              [93.119036, 51.966484],
              [93.091872, 51.956311],
              [93.075964, 51.935858],
              [93.01168, 51.914159],
              [92.957677, 51.915782],
              [92.947937, 51.904636],
              [92.91006, 51.884723],
              [92.898372, 51.868922],
              [92.890612, 51.84355],
              [92.873698, 51.836023],
              [92.843287, 51.835049],
              [92.81753, 51.848685],
              [92.79502, 51.846629],
              [92.769047, 51.855503],
              [92.763095, 51.848577],
              [92.719373, 51.846196],
              [92.693833, 51.850903],
              [92.662665, 51.833047],
              [92.6447, 51.81216],
              [92.625653, 51.808156],
              [92.593295, 51.813458],
              [92.568837, 51.807939],
              [92.5565, 51.798199],
              [92.529552, 51.791057],
              [92.512129, 51.799065],
              [92.480961, 51.790732],
              [92.461589, 51.802961],
              [92.438321, 51.7969],
              [92.395465, 51.805882],
              [92.364189, 51.818328],
              [92.307481, 51.814648],
              [92.284321, 51.809129],
              [92.25521, 51.822656],
              [92.230102, 51.809778],
              [92.1919, 51.798415],
              [92.130538, 51.789757],
              [92.07567, 51.773091],
              [92.026645, 51.794302],
              [91.995586, 51.794843],
              [91.95695, 51.811942],
              [91.940068, 51.80902],
              [91.914744, 51.826876],
              [91.918315, 51.835155],
              [91.897103, 51.859396],
              [91.87784, 51.867513],
              [91.839313, 51.85918],
              [91.81942, 51.869267],
              [91.804399, 51.886579],
              [91.781656, 51.876141],
              [91.739224, 51.865703],
              [91.729635, 51.872492],
              [91.635097, 51.87945],
              [91.59275, 51.891585],
              [91.585282, 51.901514],
              [91.547942, 51.908897],
              [91.509499, 51.906988],
              [91.485652, 51.894428],
              [91.428539, 51.898671],
              [91.371087, 51.897652],
              [91.355302, 51.891457],
              [91.292334, 51.896167],
              [91.266281, 51.894045],
              [91.259746, 51.876564],
              [91.232675, 51.89447],
              [91.179211, 51.902404],
              [91.164869, 51.899264],
              [91.154855, 51.914794],
              [91.128547, 51.924214],
              [91.105294, 51.939871],
              [91.072364, 51.95189],
              [91.059129, 51.944793],
              [91.017631, 51.944793],
              [90.995736, 51.935373],
              [90.959499, 51.936094],
              [90.930221, 51.925359],
              [90.913079, 51.92748],
              [90.849343, 51.900384],
              [90.83509, 51.921582],
              [90.818201, 51.968044],
              [90.76007, 52.013615],
              [90.76584, 52.047985],
              [90.751753, 52.070303],
              [90.714413, 52.090288],
              [90.666295, 52.096992],
              [90.633029, 52.093725],
              [90.59153, 52.120923],
              [90.558348, 52.12907],
              [90.531362, 52.145915],
              [90.51651, 52.174938],
              [90.486733, 52.195871],
              [90.496156, 52.209786],
              [90.512743, 52.211769],
              [90.509606, 52.227962],
              [90.531812, 52.233167],
              [90.558904, 52.261357],
              [90.568068, 52.298085],
              [90.591224, 52.303822],
              [90.589912, 52.311008],
              [90.638078, 52.327075],
              [90.654951, 52.345888],
              [90.66857, 52.350257],
              [90.688385, 52.369668],
              [90.684521, 52.377253],
              [90.706567, 52.408439],
              [90.705793, 52.430385],
              [90.693913, 52.43867],
              [90.720166, 52.470235],
              [90.73812, 52.477165],
              [90.758913, 52.498283],
              [90.803863, 52.526558],
              [90.822664, 52.552573],
              [90.831763, 52.555171],
              [90.885794, 52.589605],
              [90.894977, 52.600995],
              [90.956857, 52.599768],
              [91.011261, 52.615372],
              [91.073766, 52.623062],
              [91.118591, 52.633616],
              [91.185164, 52.625229],
              [91.190706, 52.632722],
              [91.228259, 52.615591],
              [91.233304, 52.638615],
              [91.257868, 52.636738],
              [91.256921, 52.664536],
              [91.263077, 52.673303],
              [91.258797, 52.697665],
              [91.270632, 52.715598],
              [91.287223, 52.716196],
              [91.321925, 52.733034],
              [91.314188, 52.776589],
              [91.332282, 52.787034],
              [91.34264, 52.805121],
              [91.387412, 52.83389],
              [91.419238, 52.841608],
              [91.427931, 52.854537],
              [91.42009, 52.878011],
              [91.430373, 52.885749],
              [91.458933, 52.886229],
              [91.475536, 52.894465],
              [91.465897, 52.923675],
              [91.475508, 52.943589],
              [91.493693, 52.957787],
              [91.505196, 52.993749],
              [91.496401, 53.004598],
              [91.459194, 53.022867],
              [91.444315, 53.038623],
              [91.433847, 53.073997],
              [91.419335, 53.089087],
              [91.439671, 53.111194],
              [91.449972, 53.135703],
              [91.489048, 53.159076],
              [91.527398, 53.157364],
              [91.575916, 53.139195],
              [91.629837, 53.145196],
              [91.676469, 53.166895],
              [91.738462, 53.177746],
              [91.774662, 53.228352],
              [91.807835, 53.243766],
              [91.840148, 53.292922],
              [91.864175, 53.313412],
              [91.907072, 53.334663],
              [91.923697, 53.349601],
              [91.919645, 53.367065],
              [91.905242, 53.377863],
              [91.918126, 53.390967],
              [91.906587, 53.400345],
              [91.903467, 53.426054],
              [91.879319, 53.42512],
              [91.864219, 53.436738],
              [91.863571, 53.456917],
              [91.842762, 53.457089],
              [91.761159, 53.492379],
              [91.657052, 53.519714],
              [91.625096, 53.533874],
              [91.631648, 53.564612],
              [91.623919, 53.576365],
              [91.59253, 53.584128],
              [91.567396, 53.596858],
              [91.557796, 53.630012],
              [91.538996, 53.641577],
              [91.571485, 53.659669],
              [91.577792, 53.687712],
              [91.567944, 53.708647],
              [91.524552, 53.720482],
              [91.505855, 53.735917],
              [91.487686, 53.737015],
              [91.461967, 53.770166],
              [91.44478, 53.803129],
              [91.427376, 53.861709],
              [91.480156, 53.945136],
              [91.521131, 53.964043],
              [91.564056, 54.019147],
              [91.580759, 54.063137],
              [91.573678, 54.091245],
              [91.52951, 54.124141],
              [91.458375, 54.215508],
              [91.434076, 54.259431],
              [91.416507, 54.279602],
              [91.358511, 54.318032],
              [91.310988, 54.33155],
              [91.311651, 54.351286],
              [91.276786, 54.380733],
              [91.250915, 54.414755],
              [91.249282, 54.438577],
              [91.268225, 54.461612],
              [91.260237, 54.506586],
              [91.217608, 54.52807],
              [91.170756, 54.54556],
              [91.09651, 54.586661],
              [91.030075, 54.605106],
              [91.010093, 54.616662],
              [90.942466, 54.637361],
              [90.918748, 54.653634],
              [90.914141, 54.722113],
              [90.944518, 54.779888],
              [90.940488, 54.836551],
              [90.945677, 54.848132],
              [90.780531, 54.871266],
              [90.779157, 54.912112],
              [90.741339, 54.915973],
              [90.678864, 54.915962],
              [90.637298, 54.904927],
              [90.507234, 54.904443],
              [90.52982, 54.989815],
              [90.526867, 55.023657],
              [90.499215, 55.060849],
              [90.463409, 55.072154],
              [90.439813, 55.060747],
              [90.397906, 55.073421],
              [90.367261, 55.076371],
              [90.36589, 55.066338],
              [90.295343, 55.066437],
              [90.271273, 55.076059],
              [90.24387, 55.078872],
              [90.249609, 55.112082],
              [90.216071, 55.131556],
              [90.216932, 55.153584],
              [90.126284, 55.153501],
              [90.132867, 55.131174],
              [90.120482, 55.109682],
              [90.091258, 55.110759],
              [90.076843, 55.119825],
              [90.04576, 55.116027],
              [90.036775, 55.123109],
              [90.015134, 55.118353],
              [89.995627, 55.124243],
              [89.92036, 55.108077],
              [89.90575, 55.097058],
              [89.891708, 55.102379],
              [89.882704, 55.082825],
              [89.83429, 55.085191],
              [89.724446, 55.068499],
              [89.730913, 55.04482],
              [89.683324, 55.006839],
              [89.664379, 55.000658],
              [89.607299, 55.028163],
              [89.627189, 55.047129],
              [89.624329, 55.059897],
              [89.63597, 55.070827],
              [89.661038, 55.073468],
              [89.646806, 55.079748],
              [89.627795, 55.0698],
              [89.597068, 55.067954],
              [89.580251, 55.087853],
              [89.562187, 55.088492],
              [89.540107, 55.068627],
              [89.43036, 55.062463],
              [89.438259, 55.044098],
              [89.437054, 55.02391],
              [89.453832, 55.012713],
              [89.423039, 55.011289],
              [89.38204, 55.022877],
              [89.371978, 55.04258],
              [89.329931, 55.040314],
              [89.278195, 55.042068],
              [89.270908, 55.059318],
              [89.27278, 55.094979],
              [89.24112, 55.111902],
              [89.170443, 55.124705],
              [89.124025, 55.120452],
              [89.064119, 55.120055],
              [89.061335, 55.128288],
              [89.092232, 55.166117],
              [89.115942, 55.180122],
              [89.086482, 55.189799],
              [89.009895, 55.233704],
              [89.011422, 55.323647],
              [88.99724, 55.320614],
              [88.970614, 55.330049],
              [88.968233, 55.343534],
              [88.900361, 55.340356],
              [88.869843, 55.367371],
              [88.874789, 55.391738],
              [88.84465, 55.391199],
              [88.796978, 55.398554],
              [88.792774, 55.408678],
              [88.750821, 55.392331],
              [88.742338, 55.431879],
              [88.70922, 55.429377],
              [88.668924, 55.437878],
              [88.657559, 55.431521],
              [88.621307, 55.434932],
              [88.601258, 55.427377],
              [88.617088, 55.451539],
              [88.611406, 55.455835],
              [88.632473, 55.472022],
              [88.650615, 55.470411],
              [88.660294, 55.481575],
              [88.653079, 55.499269],
              [88.675315, 55.517335],
              [88.711924, 55.537635],
              [88.734618, 55.543557],
              [88.729674, 55.550345],
              [88.757496, 55.558152],
              [88.766489, 55.571577],
              [88.820579, 55.590937],
              [88.825879, 55.605601],
              [88.862214, 55.616706],
              [88.872089, 55.627732],
              [88.89184, 55.629866],
              [88.913599, 55.660203],
              [88.94769, 55.658478],
              [88.96722, 55.670472],
              [89.023577, 55.672052],
              [89.050681, 55.693479],
              [89.084478, 55.693683],
              [89.105201, 55.699334],
              [89.140517, 55.694502],
              [89.165656, 55.726749],
              [89.236279, 55.734648],
              [89.265302, 55.746363],
              [89.274805, 55.759326],
              [89.294845, 55.757835],
              [89.349576, 55.770889],
              [89.33202, 55.774602],
              [89.332535, 55.811453],
              [89.36326, 55.829846],
              [89.399962, 55.841301],
              [89.387679, 55.85338],
              [89.402644, 55.866562],
              [89.361557, 55.881071],
              [89.320941, 55.887192],
              [89.305735, 55.896666],
              [89.275644, 55.892811],
              [89.285061, 55.914307],
              [89.262857, 55.93301],
              [89.280624, 55.942477],
              [89.262011, 55.965129],
              [89.256115, 55.983206],
              [89.241638, 55.992782],
              [89.248512, 56.00569],
              [89.235652, 56.013021],
              [89.258663, 56.033986],
              [89.260058, 56.048763],
              [89.280863, 56.053909],
              [89.255537, 56.076846],
              [89.247083, 56.070401],
              [89.194023, 56.081165],
              [89.192927, 56.134806],
              [89.201353, 56.138817],
              [89.151761, 56.139216],
              [89.136266, 56.14971],
              [89.104815, 56.151022],
              [89.099127, 56.1639],
              [89.126689, 56.184532],
              [89.097007, 56.201208],
              [89.036652, 56.201266],
              [89.039898, 56.300484],
              [88.900879, 56.301983],
              [88.892981, 56.31524],
              [88.912883, 56.32183],
              [88.920763, 56.333893],
              [88.894965, 56.348794],
              [88.866519, 56.358142],
              [88.860922, 56.372908],
              [88.695147, 56.36643],
              [88.696483, 56.418182],
              [88.593416, 56.430664],
              [88.582962, 56.460457],
              [88.609168, 56.46327],
              [88.605597, 56.531251],
              [88.546388, 56.529922],
              [88.52623, 56.552816],
              [88.500878, 56.62189],
              [88.663149, 56.619767],
              [88.674013, 56.680068],
              [88.718487, 56.682136],
              [88.736076, 56.714701],
              [88.624413, 56.719298],
              [88.648161, 56.819786],
              [88.597557, 56.802198],
              [88.697349, 57.047894],
              [88.714554, 57.092294],
              [88.694879, 57.08222],
              [88.649939, 57.079165],
              [88.516982, 57.090443],
              [88.520819, 57.098836],
              [88.506549, 57.125246],
              [88.549875, 57.121331],
              [88.556312, 57.140655],
              [88.570971, 57.140888],
              [88.622505, 57.209349],
              [88.734155, 57.208035],
              [88.789771, 57.311617],
              [88.86505, 57.44362],
              [88.869036, 57.476367],
              [88.892182, 57.459946],
              [88.90098, 57.4361],
              [88.944947, 57.438103],
              [88.974953, 57.484618],
              [88.970841, 57.498411],
              [89.023645, 57.503043],
              [89.042849, 57.50095],
              [89.024766, 57.486414],
              [89.030142, 57.46398],
              [89.063639, 57.457552],
              [89.065828, 57.481676],
              [89.090307, 57.50261],
              [89.08392, 57.489343],
              [89.137288, 57.482803],
              [89.160477, 57.492393],
              [89.098764, 57.517209],
              [89.130337, 57.615341],
              [89.2115, 57.594065],
              [89.355248, 57.615657],
              [89.369804, 57.64867],
              [89.369805, 57.679281],
              [89.3549, 57.691536],
              [89.353726, 57.7863],
              [89.362799, 57.801674],
              [89.342479, 57.825677],
              [89.370576, 57.846373],
              [89.359155, 57.85666],
              [89.372526, 57.866694],
              [89.350168, 57.906931],
              [89.353245, 57.912996],
              [89.301527, 57.932874],
              [89.29641, 57.948954],
              [89.046761, 57.953132],
              [88.858954, 57.955186],
              [88.687253, 58.036199],
              [88.499757, 58.06183],
              [88.316451, 58.084336],
              [88.106659, 58.112772],
              [88.069853, 58.220064],
              [88.016988, 58.214258],
              [87.924013, 58.511345],
              [88.214673, 58.754299],
              [88.259621, 58.795813],
              [88.336587, 58.854956],
              [88.382657, 58.893119],
              [88.43196, 58.906989],
              [88.439381, 58.914215],
              [88.484038, 58.925682],
              [88.758918, 59.000665],
              [88.82866, 59.022604],
              [88.575826, 59.212216],
              [88.626467, 59.295126],
              [88.499308, 59.286529],
              [87.902226, 59.256238],
              [87.819717, 59.339384],
              [87.737545, 59.424316],
              [87.610136, 59.563763],
              [87.514225, 59.66441],
              [87.289785, 59.675226],
              [87.174086, 59.682528],
              [87.141509, 59.742885],
              [87.100398, 59.826583],
              [87.078047, 59.879074],
              [86.636499, 59.948654],
              [86.498852, 59.948596],
              [86.187707, 59.949625],
              [86.018937, 59.948568],
              [85.998473, 59.947149],
              [85.810271, 59.922616],
              [85.499551, 59.883032],
              [85.02993, 59.890304],
              [84.875863, 59.890405],
              [84.70755, 59.89207],
              [84.681663, 59.89955],
              [84.581069, 59.962095],
              [84.575203, 59.983973],
              [84.596798, 60.016354],
              [84.633451, 60.029796],
              [84.623418, 60.142768],
              [84.616018, 60.168548],
              [84.644489, 60.183854],
              [84.695195, 60.232382],
              [84.738281, 60.251798],
              [84.744393, 60.271115],
              [84.724749, 60.291218],
              [84.720161, 60.309016],
              [84.746543, 60.312453],
              [84.752084, 60.328742],
              [84.774695, 60.327504],
              [84.791487, 60.352366],
              [84.747627, 60.40053],
              [84.659268, 60.488676],
              [84.551635, 60.600516],
              [84.483434, 60.6648],
              [84.351305, 60.796932],
              [84.293921, 60.808916],
              [84.287673, 60.827181],
              [84.268217, 60.836548],
              [84.26311, 60.862518],
              [84.641651, 60.997945],
              [84.65392, 61.003498],
              [84.735082, 61.014263],
              [85.000187, 61.091738],
              [85.251538, 61.161459],
              [85.2797, 61.17077],
              [85.619246, 61.262323],
              [85.696583, 61.283815],
              [85.700535, 61.316369],
              [85.640731, 61.36709],
              [85.798192, 61.454098],
              [85.965282, 61.459195],
              [85.976673, 61.48261],
              [85.961137, 61.54512],
              [85.842513, 61.593921],
              [85.712839, 61.572615],
              [85.492853, 61.617215],
              [85.406556, 61.687147],
              [85.340882, 61.686691],
              [85.277798, 61.673722],
              [85.229997, 61.698402],
              [85.174212, 61.708649],
              [85.096852, 61.751986],
              [84.992454, 61.769161],
              [84.91898, 61.789921],
              [84.889225, 61.780066],
              [84.840892, 61.773046],
              [84.735405, 61.792696],
              [84.677915, 61.80813],
              [84.63959, 61.831949],
              [84.630174, 61.860226],
              [84.61101, 61.875949],
              [84.554217, 61.898145],
              [84.519392, 61.927839],
              [84.514918, 61.969531],
              [84.523857, 61.990612],
              [84.490848, 62.031781],
              [84.49506, 62.065138],
              [84.491986, 62.098471],
              [84.448072, 62.165132],
              [84.440859, 62.195657],
              [84.491794, 62.215966],
              [84.573038, 62.300468],
              [84.698142, 62.390519],
              [84.722828, 62.403652],
              [84.785774, 62.416548],
              [84.848321, 62.441852],
              [84.935707, 62.482305],
              [84.94153, 62.516178],
              [84.909911, 62.526669],
              [84.89644, 62.550588],
              [84.918939, 62.558609],
              [84.946259, 62.579064],
              [84.940968, 62.587774],
              [84.962338, 62.596207],
              [84.984253, 62.592255],
              [85.011468, 62.602773],
              [85.059571, 62.630429],
              [85.090824, 62.627616],
              [85.099163, 62.641226],
              [85.064501, 62.667328],
              [85.099052, 62.683386],
              [85.12292, 62.68661],
              [85.116793, 62.701625],
              [85.153164, 62.707092],
              [85.149451, 62.717994],
              [85.170114, 62.734982],
              [85.18572, 62.770076],
              [85.20172, 62.777625],
              [85.214249, 62.798508],
              [85.28791, 62.824852],
              [85.301374, 62.843559],
              [85.343377, 62.861001],
              [85.369446, 62.879135],
              [85.471088, 62.881443],
              [85.499268, 62.898239],
              [85.523438, 62.904483],
              [85.512826, 62.919396],
              [85.522644, 62.935229],
              [85.53983, 62.938304],
              [85.502784, 62.971997],
              [85.517406, 62.990056],
              [85.536314, 62.988855],
              [85.57119, 63.014049],
              [85.59704, 63.01196],
              [85.619849, 63.034473],
              [85.572212, 63.061867],
              [85.597853, 63.059846],
              [85.595826, 63.075644],
              [85.569114, 63.087632],
              [85.559121, 63.101308],
              [85.541052, 63.09826],
              [85.522945, 63.11475],
              [85.532168, 63.119987],
              [85.502887, 63.135314],
              [85.498995, 63.153013],
              [85.517061, 63.167664],
              [85.502517, 63.177791],
              [85.564761, 63.202299],
              [85.572605, 63.225243],
              [85.546841, 63.235379],
              [85.554254, 63.252271],
              [85.576221, 63.257632],
              [85.56673, 63.26897],
              [85.598154, 63.299987],
              [85.61974, 63.298574],
              [85.652741, 63.326496],
              [85.695214, 63.332953],
              [85.704665, 63.345816],
              [85.688428, 63.372495],
              [85.645105, 63.377929],
              [85.577508, 63.379641],
              [85.496847, 63.366953],
              [85.463305, 63.351474],
              [85.455583, 63.361023],
              [85.419417, 63.365302],
              [85.411977, 63.388874],
              [85.374222, 63.393427],
              [85.387895, 63.41736],
              [85.404698, 63.418745],
              [85.417437, 63.437233],
              [85.373757, 63.452969],
              [85.397096, 63.461738],
              [85.380802, 63.475976],
              [85.360194, 63.473395],
              [85.353103, 63.491005],
              [85.317586, 63.507781],
              [85.295183, 63.511072],
              [85.28704, 63.52422],
              [85.272764, 63.510287],
              [85.231655, 63.495216],
              [85.210887, 63.523121],
              [85.123356, 63.525224],
              [85.078386, 63.533944],
              [85.085848, 63.565094],
              [85.023957, 63.580608],
              [85.04993, 63.595275],
              [85.096347, 63.584295],
              [85.114893, 63.571338],
              [85.145257, 63.562733],
              [85.154238, 63.581321],
              [85.193304, 63.613213],
              [85.229085, 63.607644],
              [85.257042, 63.62082],
              [85.242732, 63.624336],
              [85.302541, 63.647649],
              [85.299763, 63.665512],
              [85.315816, 63.667152],
              [85.322846, 63.710094],
              [85.336979, 63.710665],
              [85.319814, 63.727772],
              [85.340582, 63.733815],
              [85.330488, 63.747194],
              [85.348794, 63.762973],
              [85.378336, 63.766248],
              [85.349183, 63.783469],
              [85.37013, 63.797836],
              [85.329072, 63.796407],
              [85.346891, 63.817754],
              [85.387177, 63.834426],
              [85.366722, 63.844078],
              [85.375304, 63.859079],
              [85.402454, 63.854804],
              [85.418798, 63.867247],
              [85.462111, 63.863603],
              [85.491013, 63.876445],
              [85.476851, 63.89602],
              [85.482025, 63.914251],
              [85.506213, 63.928271],
              [85.558226, 63.932411],
              [85.585829, 63.943315],
              [85.629777, 63.950658],
              [85.75205, 64.001927],
              [85.902069, 64.043453],
              [85.967046, 64.048501],
              [85.9492, 64.110808],
              [85.933575, 64.132014],
              [85.982966, 64.178761],
              [85.978354, 64.205842],
              [86.00086, 64.2242],
              [86.007285, 64.272084],
              [86.000129, 64.277536],
              [85.931565, 64.372174],
              [85.921623, 64.431646],
              [85.865029, 64.45754],
              [85.804287, 64.496899],
              [85.907316, 64.574106],
              [85.905753, 64.598818],
              [85.819372, 64.679925],
              [85.819617, 64.705883],
              [85.833713, 64.784334],
              [85.735818, 64.823632],
              [85.694898, 64.828829],
              [85.665141, 64.838452],
              [85.596899, 64.84424],
              [85.569651, 64.853289],
              [85.527884, 64.85222],
              [85.512885, 64.843637],
              [85.455371, 64.830894],
              [85.44143, 64.832739],
              [85.416623, 64.812221],
              [85.390201, 64.811935],
              [85.364297, 64.795039],
              [85.340311, 64.791581],
              [85.25597, 64.796108],
              [85.2545, 64.791215],
              [85.204421, 64.786133],
              [85.182537, 64.772662],
              [85.167076, 64.7772],
              [85.133283, 64.763997],
              [85.131131, 64.796339],
              [85.10929, 64.800335],
              [85.133607, 64.826115],
              [85.103347, 64.829987],
              [85.100654, 64.820103],
              [84.946845, 64.834707],
              [84.923987, 64.864172],
              [84.94803, 64.870846],
              [84.959678, 64.885843],
              [84.981293, 64.878786],
              [85.012963, 64.895114],
              [84.996115, 64.900383],
              [85.002434, 64.915996],
              [85.032215, 64.910079],
              [85.025955, 64.924338],
              [84.976894, 64.940666],
              [84.942834, 64.942583],
              [84.921258, 64.954149],
              [84.89691, 64.952588],
              [84.908119, 64.935235],
              [84.862233, 64.950847],
              [84.821049, 64.94228],
              [84.755948, 64.943312],
              [84.721354, 64.929382],
              [84.701519, 64.933858],
              [84.686435, 64.923],
              [84.661303, 64.931257],
              [84.659474, 64.921971],
              [84.628287, 64.921249],
              [84.618237, 64.930824],
              [84.587194, 64.90822],
              [84.549276, 64.927878],
              [84.54777, 64.916504],
              [84.518227, 64.913284],
              [84.502268, 64.925069],
              [84.490015, 64.91163],
              [84.44585, 64.917591],
              [84.430095, 64.905507],
              [84.414101, 64.926077],
              [84.401873, 64.911383],
              [84.371262, 64.901682],
              [84.35453, 64.917806],
              [84.317647, 64.910492],
              [84.321848, 64.920113],
              [84.300791, 64.932167],
              [84.27232, 64.935291],
              [84.284623, 64.94824],
              [84.318959, 64.957283],
              [84.316776, 64.974176],
              [84.354288, 64.977971],
              [84.325554, 64.995697],
              [84.358867, 64.993762],
              [84.374455, 65.011592],
              [84.362265, 65.024672],
              [84.383055, 65.03567],
              [84.393556, 65.054653],
              [84.376725, 65.061749],
              [84.350452, 65.050932],
              [84.357616, 65.078756],
              [84.335926, 65.088867],
              [84.343988, 65.073417],
              [84.300765, 65.058692],
              [84.284425, 65.086531],
              [84.288352, 65.104061],
              [84.357794, 65.172809],
              [84.38348, 65.201851],
              [84.426055, 65.221949],
              [84.504262, 65.229712],
              [84.542418, 65.244356],
              [84.554657, 65.269471],
              [84.567119, 65.317042],
              [84.549785, 65.332901],
              [84.551225, 65.349357],
              [84.514388, 65.379842],
              [84.493406, 65.390253],
              [84.485458, 65.408738],
              [84.520354, 65.428043],
              [84.527697, 65.455532],
              [84.509418, 65.477438],
              [84.473298, 65.49993],
              [84.433876, 65.514673],
              [84.438607, 65.533728],
              [84.406546, 65.549365],
              [84.333665, 65.549023],
              [84.322646, 65.558741],
              [84.251996, 65.576431],
              [84.175125, 65.584504],
              [84.175039, 65.595555],
              [84.232378, 65.603347],
              [84.282737, 65.617595],
              [84.271657, 65.637469],
              [84.292248, 65.652353],
              [84.29285, 65.687125],
              [84.2391, 65.683762],
              [84.151455, 65.694778],
              [84.117299, 65.718203],
              [84.05659, 65.741084],
              [84.018518, 65.776739],
              [83.999658, 65.787181],
              [83.966508, 65.792251],
              [83.762194, 65.789367],
              [83.563395, 65.761979],
              [83.473505, 65.814529],
              [83.515845, 65.835187],
              [83.529222, 65.855543],
              [83.502296, 65.862725],
              [83.504384, 65.881159],
              [83.551045, 65.902985],
              [83.508987, 65.916357],
              [83.502832, 65.924771],
              [83.454818, 65.925961],
              [83.395265, 65.957719],
              [83.367649, 65.95742],
              [83.328522, 65.989962],
              [83.311026, 65.986285],
              [83.294883, 66.035719],
              [83.269447, 66.049229],
              [83.327144, 66.081155],
              [83.364974, 66.081315],
              [83.351897, 66.119241],
              [83.379892, 66.122494],
              [83.513789, 66.153953],
              [83.522981, 66.167431],
              [83.545444, 66.173299],
              [83.539154, 66.187446],
              [83.484163, 66.217641],
              [83.355001, 66.315935],
              [83.307718, 66.337277],
              [83.270533, 66.366471],
              [83.238121, 66.382911],
              [83.15444, 66.400506],
              [83.119947, 66.402522],
              [83.094962, 66.425271],
              [83.076214, 66.462893],
              [83.082868, 66.576432],
              [83.09052, 66.594674],
              [83.122368, 66.603983],
              [83.162402, 66.599785],
              [83.190509, 66.603551],
              [83.202174, 66.615499],
              [83.223195, 66.613303],
              [83.253065, 66.62303],
              [83.276548, 66.645163],
              [83.323616, 66.644533],
              [83.32771, 66.658633],
              [83.277275, 66.666761],
              [83.285059, 66.67638],
              [83.26775, 66.69833],
              [83.250573, 66.698628],
              [83.22996, 66.71815],
              [83.24019, 66.72671],
              [83.20839, 66.736153],
              [83.185789, 66.754953],
              [83.201161, 66.761555],
              [83.187981, 66.782996],
              [83.144944, 66.830278],
              [83.113129, 66.843262],
              [83.071408, 66.845654],
              [83.035754, 66.861255],
              [83.046354, 66.867114],
              [83.026209, 66.882688],
              [82.730564, 67.003301],
              [82.520082, 67.078868],
              [82.488674, 67.091716],
              [82.133208, 67.2189],
              [82.117143, 67.233997],
              [82.136118, 67.246504],
              [82.197843, 67.24558],
              [82.21349, 67.25647],
              [82.196173, 67.272089],
              [82.200453, 67.284044],
              [82.227294, 67.271557],
              [82.271919, 67.27394],
              [82.278312, 67.283058],
              [82.225925, 67.285306],
              [82.2293, 67.300235],
              [82.253418, 67.294351],
              [82.27037, 67.304129],
              [82.361536, 67.31718],
              [82.366948, 67.323128],
              [82.303994, 67.333324],
              [82.288914, 67.348964],
              [82.345855, 67.354136],
              [82.285948, 67.362518],
              [82.250594, 67.405108],
              [82.231825, 67.409169],
              [82.259384, 67.422546],
              [82.290645, 67.423842],
              [82.271942, 67.433633],
              [82.317267, 67.44591],
              [82.299863, 67.456941],
              [82.344465, 67.458119],
              [82.33056, 67.47218],
              [82.358301, 67.475628],
              [82.386048, 67.465779],
              [82.376629, 67.483455],
              [82.341602, 67.490989],
              [82.396098, 67.50127],
              [82.397382, 67.521762],
              [82.351871, 67.531287],
              [82.376661, 67.552146],
              [82.323576, 67.549621],
              [82.307857, 67.534853],
              [82.286725, 67.534251],
              [82.300135, 67.553875],
              [82.260205, 67.544636],
              [82.231579, 67.558268],
              [82.240198, 67.565679],
              [82.208187, 67.570045],
              [82.183963, 67.564111],
              [82.167995, 67.574196],
              [82.167622, 67.593395],
              [82.113949, 67.596324],
              [82.115164, 67.615483],
              [82.078833, 67.617567],
              [82.055803, 67.640415],
              [82.027625, 67.644631],
              [82.042417, 67.661923],
              [82.065729, 67.653168],
              [82.079389, 67.675686],
              [82.101719, 67.675952],
              [82.079755, 67.690459],
              [82.098844, 67.719674],
              [82.061455, 67.72096],
              [82.069957, 67.732534],
              [81.963442, 67.753996],
              [81.937585, 67.775003],
              [81.90804, 67.77695],
              [81.91561, 67.786164],
              [81.872129, 67.784859],
              [81.858145, 67.816113],
              [81.81928, 67.829277],
              [81.80235, 67.848585],
              [81.773772, 67.861449],
              [81.779235, 67.883733],
              [81.724489, 67.892795],
              [81.747112, 67.932713],
              [81.767561, 67.947541],
              [81.819986, 67.929903],
              [81.860003, 67.945482],
              [81.925067, 67.955957],
              [81.973863, 67.959532],
              [82.062589, 67.942873],
              [82.203654, 67.937124],
              [82.242385, 67.938319],
              [82.335616, 67.933346],
              [82.362037, 67.939894],
              [82.396129, 67.967409],
              [82.374997, 67.992154],
              [82.364764, 68.023897],
              [82.381642, 68.05583],
              [82.367328, 68.080665],
              [82.34465, 68.089396],
              [82.337415, 68.113932],
              [82.347783, 68.123965],
              [82.478772, 68.158007],
              [82.552208, 68.167067],
              [82.531906, 68.200874],
              [82.535222, 68.232502],
              [82.557966, 68.26484],
              [82.629405, 68.275401],
              [82.649253, 68.286303],
              [82.686904, 68.333877],
              [82.714025, 68.405172],
              [82.704353, 68.412865],
              [82.691186, 68.471412],
              [82.667387, 68.503439],
              [82.62097, 68.54776],
              [82.625036, 68.582547],
              [82.648785, 68.608826],
              [82.727938, 68.627516],
              [82.824517, 68.637134],
              [82.864283, 68.656829],
              [82.872731, 68.674322],
              [82.858743, 68.710673],
              [82.836311, 68.735302],
              [82.800677, 68.759195],
              [82.68717, 68.788657],
              [82.594519, 68.807023],
              [82.566188, 68.81906],
              [82.551408, 68.8405],
              [82.546685, 68.882811],
              [82.518253, 68.938132],
              [82.493701, 68.971615],
              [82.4287, 69.012804],
              [82.345381, 69.027492],
              [82.315583, 69.053849],
              [82.370327, 69.075634],
              [82.460954, 69.077317],
              [82.461028, 69.095961],
              [82.504474, 69.112295],
              [82.453385, 69.118583],
              [82.468054, 69.14523],
              [82.442518, 69.162003],
              [82.39592, 69.173855],
              [82.323671, 69.175565],
              [82.276559, 69.160162],
              [82.244619, 69.157358],
              [82.189619, 69.165569],
              [82.138464, 69.162346],
              [82.090297, 69.1802],
              [82.001487, 69.194029],
              [81.917518, 69.188421],
              [81.844422, 69.195059],
              [81.828985, 69.216017],
              [81.797426, 69.238756],
              [81.805257, 69.253679],
              [81.896496, 69.302854],
              [81.95246, 69.323323],
              [81.961172, 69.352549],
              [81.90612, 69.373803],
              [81.89647, 69.387787],
              [81.853177, 69.404787],
              [81.821998, 69.427072],
              [81.731076, 69.445398],
              [81.708273, 69.455059],
              [81.687942, 69.448003],
              [81.640992, 69.413596],
              [81.651931, 69.400761],
              [81.653093, 69.367954],
              [81.639582, 69.344352],
              [81.61129, 69.332608],
              [81.673828, 69.321784],
              [81.67646, 69.31661],
              [81.585507, 69.269522],
              [81.528168, 69.264923],
              [81.437938, 69.248205],
              [81.385886, 69.259035],
              [81.373666, 69.270983],
              [81.335078, 69.284426],
              [81.275437, 69.266156],
              [81.244246, 69.265178],
              [81.167313, 69.23672],
              [81.133316, 69.241534],
              [81.03203, 69.213412],
              [80.949757, 69.200576],
              [80.929878, 69.203682],
              [80.919105, 69.227823],
              [80.927304, 69.238647],
              [80.872294, 69.23983],
              [80.874165, 69.249398],
              [80.835565, 69.256959],
              [80.821636, 69.270802],
              [80.795927, 69.269559],
              [80.762196, 69.30638],
              [80.71105, 69.310098],
              [80.702343, 69.318664],
              [80.593458, 69.311571],
              [80.550795, 69.318527],
              [80.542682, 69.305977],
              [80.496754, 69.32377],
              [80.443944, 69.317724],
              [80.432638, 69.331127],
              [80.35852, 69.322592],
              [80.309274, 69.334058],
              [80.313646, 69.345314],
              [80.269265, 69.347486],
              [80.236667, 69.365988],
              [80.115068, 69.3544],
              [80.139804, 69.365459],
              [80.100091, 69.367839],
              [80.086493, 69.353341],
              [80.020968, 69.365285],
              [79.964082, 69.357285],
              [79.954077, 69.341658],
              [79.917844, 69.352295],
              [79.908253, 69.363246],
              [79.877087, 69.35401],
              [79.843914, 69.358653],
              [79.869868, 69.366531],
              [79.809118, 69.390502],
              [79.766587, 69.391648],
              [79.771019, 69.410323],
              [79.79105, 69.426335],
              [79.733248, 69.428886],
              [79.734247, 69.419249],
              [79.661787, 69.428579],
              [79.695007, 69.445197],
              [79.61801, 69.459564],
              [79.647238, 69.471071],
              [79.641837, 69.481205],
              [79.585271, 69.488729],
              [79.626555, 69.505197],
              [79.600986, 69.525044],
              [79.569015, 69.532759],
              [79.587099, 69.546462],
              [79.559154, 69.561575],
              [79.544678, 69.584276],
              [79.55472, 69.593397],
              [79.511352, 69.594249],
              [79.448908, 69.610453],
              [79.481391, 69.613092],
              [79.510766, 69.629141],
              [79.495568, 69.637517],
              [79.458558, 69.633626],
              [79.469401, 69.65567],
              [79.416822, 69.661203],
              [79.400052, 69.649052],
              [79.371382, 69.661547],
              [79.324142, 69.657002],
              [79.274866, 69.677791],
              [79.270381, 69.687241],
              [79.223269, 69.682024],
              [79.180069, 69.688798],
              [79.132922, 69.685417],
              [79.095395, 69.70651],
              [79.062402, 69.693997],
              [79.044522, 69.704091],
              [79.099668, 69.723533],
              [79.144295, 69.726591],
              [79.166983, 69.737767],
              [79.146281, 69.752703],
              [79.093897, 69.747511],
              [79.087384, 69.763771],
              [79.125156, 69.779482],
              [79.11753, 69.788667],
              [79.016828, 69.798959],
              [78.995, 69.806612],
              [79.010212, 69.82133],
              [79.046211, 69.824618],
              [79.039316, 69.837227],
              [78.987285, 69.840212],
              [78.948831, 69.854013],
              [78.909773, 69.856747],
              [78.913266, 69.868182],
              [78.880529, 69.8801],
              [78.892444, 69.886699],
              [78.962518, 69.878783],
              [79.045156, 69.907096],
              [79.056374, 69.930272],
              [79.109672, 69.932747],
              [79.158702, 69.951187],
              [79.14093, 69.959356],
              [79.159844, 69.974706],
              [79.197571, 69.976687],
              [79.14276, 69.989871],
              [79.176512, 69.998754],
              [79.166111, 70.006822],
              [79.116594, 69.997191],
              [79.165125, 70.021813],
              [79.182317, 70.023356],
              [79.209804, 70.00852],
              [79.242506, 70.020846],
              [79.200147, 70.032955],
              [79.220603, 70.060161],
              [79.283963, 70.052806],
              [79.324006, 70.063819],
              [79.342589, 70.04288],
              [79.369504, 70.045558],
              [79.364617, 70.068145],
              [79.32421, 70.090289],
              [79.358067, 70.110197],
              [79.325458, 70.128691],
              [79.322573, 70.142259],
              [79.348985, 70.152833],
              [79.403641, 70.155644],
              [79.441932, 70.148842],
              [79.442857, 70.139509],
              [79.397415, 70.132122],
              [79.414425, 70.122856],
              [79.507395, 70.111808],
              [79.552797, 70.118067],
              [79.570074, 70.139317],
              [79.651349, 70.18942],
              [79.666234, 70.232815],
              [79.70702, 70.240707],
              [79.785765, 70.233662],
              [79.782522, 70.246777],
              [79.737334, 70.255122],
              [79.755067, 70.27571],
              [79.811261, 70.283682],
              [79.837473, 70.281499],
              [79.878573, 70.261865],
              [79.919093, 70.268871],
              [79.945168, 70.290666],
              [80.023538, 70.331577],
              [80.124283, 70.342495],
              [80.136716, 70.365902],
              [80.0936, 70.375866],
              [80.124854, 70.389838],
              [80.177161, 70.376417],
              [80.224153, 70.397162],
              [80.208279, 70.427463],
              [80.222912, 70.435726],
              [80.311517, 70.425037],
              [80.341113, 70.427121],
              [80.358166, 70.446542],
              [80.414205, 70.460549],
              [80.497134, 70.459621],
              [80.61768, 70.453917],
              [80.674829, 70.432125],
              [80.685447, 70.411911],
              [80.641438, 70.405301],
              [80.644504, 70.398094],
              [80.730004, 70.387803],
              [80.749746, 70.381302],
              [80.790826, 70.385454],
              [80.824533, 70.38139],
              [80.822814, 70.397179],
              [80.791129, 70.400031],
              [80.793724, 70.411725],
              [80.849402, 70.409958],
              [80.889185, 70.437963],
              [80.849976, 70.439681],
              [80.834476, 70.423041],
              [80.812044, 70.424993],
              [80.823019, 70.438821],
              [80.7449, 70.434705],
              [80.720114, 70.441577],
              [80.702053, 70.460186],
              [80.701756, 70.480456],
              [80.646883, 70.482826],
              [80.648834, 70.501354],
              [80.723535, 70.506797],
              [80.722395, 70.531962],
              [80.742221, 70.557082],
              [80.715888, 70.565926],
              [80.77313, 70.574926],
              [80.764365, 70.583266],
              [80.793954, 70.590818],
              [80.778763, 70.602112],
              [80.82179, 70.631349],
              [80.800471, 70.635958],
              [80.794794, 70.654248],
              [80.736152, 70.673077],
              [80.684978, 70.676792],
              [80.647689, 70.691794],
              [80.641232, 70.706509],
              [80.674105, 70.760624],
              [80.701131, 70.777713],
              [80.715196, 70.809468],
              [80.638733, 70.833051],
              [80.608037, 70.943661],
              [80.583982, 71.043189],
              [80.367468, 71.075853],
              [79.996963, 71.116462],
              [79.433874, 71.296142],
              [79.220234, 71.307417],
              [79.175292, 71.332855],
              [79.143139, 71.38226],
              [79.12869, 71.387636],
              [79.161258, 71.404567],
              [79.213298, 71.416812],
              [79.249602, 71.434949],
              [79.313637, 71.454336],
              [79.362777, 71.505488],
              [79.371675, 71.528345],
              [79.352386, 71.548112],
              [79.314737, 71.567241],
              [79.312296, 71.586591],
              [79.394241, 71.612286],
              [79.520297, 71.595289],
              [79.542758, 71.606218],
              [79.572985, 71.600888],
              [79.651636, 71.605087],
              [79.705195, 71.601186],
              [79.724504, 71.592669],
              [79.784924, 71.590181],
              [79.841094, 71.582324],
              [79.905025, 71.596165],
              [79.915592, 71.608444],
              [79.980134, 71.609985],
              [79.980895, 71.623868],
              [80.030842, 71.639904],
              [80.138899, 71.663788],
              [80.130702, 71.678223],
              [80.174307, 71.681381],
              [80.135581, 71.687023],
              [80.152478, 71.709932],
              [80.109841, 71.725041],
              [80.142773, 71.732426],
              [80.101663, 71.734077],
              [80.072496, 71.750427],
              [80.128742, 71.745589],
              [80.148266, 71.757419],
              [80.117063, 71.768785],
              [80.120764, 71.78695],
              [80.100051, 71.783889],
              [80.101847, 71.805901],
              [80.053254, 71.798315],
              [80.074416, 71.823327],
              [80.131336, 71.83741],
              [80.163743, 71.835537],
              [80.194109, 71.854525],
              [80.251155, 71.855703],
              [80.253045, 71.846459],
              [80.317788, 71.855119],
              [80.297444, 71.869873],
              [80.322325, 71.877079],
              [80.331172, 71.864062],
              [80.378147, 71.871454],
              [80.379051, 71.878692],
              [80.330231, 71.885112],
              [80.251176, 71.907715],
              [80.277673, 71.923354],
              [80.251741, 71.931796],
              [80.225997, 71.925883],
              [80.220522, 71.938078],
              [80.17003, 71.93004],
              [80.151362, 71.945617],
              [80.089477, 71.944032],
              [80.114021, 71.950669],
              [80.089689, 71.971078],
              [80.065868, 71.964915],
              [80.062768, 71.980913],
              [80.020164, 71.974892],
              [80.00038, 71.986653],
              [79.975134, 71.978693],
              [79.91305, 71.991997],
              [79.907604, 71.973699],
              [79.878516, 71.992395],
              [79.895474, 72.003879],
              [79.881514, 72.019102],
              [79.831558, 72.019639],
              [79.839756, 72.026746],
              [79.73776, 72.040823],
              [79.720558, 72.050556],
              [79.639712, 72.048765],
              [79.645341, 72.040144],
              [79.486142, 72.040398],
              [79.435364, 72.02926],
              [79.393911, 72.028686],
              [79.410236, 72.048989],
              [79.372573, 72.03978],
              [79.359508, 72.057111],
              [79.319376, 72.04977],
              [79.275364, 72.055556],
              [79.286178, 72.063482],
              [79.233532, 72.06242],
              [79.206873, 72.075347],
              [79.128127, 72.061557],
              [79.128228, 72.072],
              [79.092652, 72.073317],
              [79.070631, 72.059099],
              [79.059655, 72.083078],
              [79.016746, 72.091428],
              [79.053889, 72.093937],
              [79.04323, 72.103561],
              [78.997891, 72.089835],
              [78.992399, 72.109691],
              [78.953751, 72.120564],
              [78.926717, 72.108473],
              [78.88643, 72.109703],
              [78.9155, 72.125797],
              [78.882471, 72.137547],
              [78.783209, 72.138289],
              [78.774602, 72.155162],
              [78.72248, 72.151004],
              [78.769734, 72.163414],
              [78.71507, 72.163972],
              [78.685192, 72.176796],
              [78.660146, 72.16653],
              [78.625833, 72.17134],
              [78.630646, 72.195669],
              [78.580285, 72.192207],
              [78.552386, 72.200763],
              [78.614171, 72.203066],
              [78.653866, 72.223482],
              [78.603707, 72.226794],
              [78.603167, 72.233909],
              [78.667055, 72.243517],
              [78.602525, 72.258172],
              [78.613903, 72.287387],
              [78.570749, 72.295288],
              [78.520242, 72.29383],
              [78.485855, 72.301407],
              [78.424233, 72.334442],
              [78.48371, 72.353842],
              [78.499157, 72.366584],
              [78.52175, 72.37879],
              [78.81254, 72.36815],
              [78.98428, 72.36345],
              [79.24316, 72.3631],
              [79.49561, 72.34819],
              [79.56579, 72.33414],
              [79.73437, 72.28188],
              [79.9154, 72.21473],
              [79.95535, 72.20344],
              [80.1113, 72.17482],
              [80.26063, 72.15398],
              [80.36288, 72.1367],
              [80.5817, 72.10535],
              [80.82299, 72.0748],
              [80.82454, 72.04236],
              [80.85302, 72.0015],
              [80.84779, 71.98437],
              [80.95026, 71.94968],
              [80.96264, 71.93921],
              [81.01617, 71.92975],
              [81.11474, 71.89471],
              [81.17816, 71.86758],
              [81.2835, 71.83424],
              [81.27502, 71.81432],
              [81.29101, 71.79002],
              [81.38046, 71.74822],
              [81.49814, 71.72789],
              [81.56065, 71.72095],
              [81.671177, 71.694802],
              [81.719413, 71.68974],
              [81.87989, 71.68755],
              [81.97104, 71.68993],
              [82.17661, 71.70143],
              [82.31609, 71.70691],
              [82.48431, 71.72485],
              [82.56761, 71.73735],
              [82.70596, 71.76199],
              [82.88069, 71.77085],
              [82.95222, 71.76758],
              [82.97346, 71.73643],
              [83.06291, 71.72693],
              [83.18378, 71.7293],
              [83.2788, 71.70621],
              [83.44423, 71.73908],
              [83.45748, 71.75642],
              [83.45253, 71.7905],
              [83.41445, 71.81885],
              [83.33484, 71.84386],
              [83.23927, 71.85726],
              [83.201, 71.87023],
              [83.14841, 71.87709],
              [83.03663, 71.88392],
              [82.89495, 71.88842],
              [82.85945, 71.89503],
              [82.80898, 71.89167],
              [82.77547, 71.90234],
              [82.70898, 71.89463],
              [82.70855, 71.87188],
              [82.68003, 71.88338],
              [82.65733, 71.92396],
              [82.6234, 71.94571],
              [82.59199, 71.98815],
              [82.54316, 72.01691],
              [82.44343, 72.05371],
              [82.37682, 72.07208],
              [82.29139, 72.08442],
              [82.26561, 72.09583],
              [82.27247, 72.11112],
              [82.3208, 72.11259],
              [82.36091, 72.13225],
              [82.37243, 72.15287],
              [82.36925, 72.18867],
              [82.34637, 72.2126],
              [82.24903, 72.25643],
              [82.15293, 72.27413],
              [81.90775, 72.30276],
              [81.8923, 72.3133],
              [81.81307, 72.32719],
              [81.69363, 72.32751],
              [81.59725, 72.33805],
              [81.53314, 72.33325],
              [81.45935, 72.34327],
              [81.31549, 72.34892],
              [81.19935, 72.35584],
              [81.18435, 72.37131],
              [81.11776, 72.37507],
              [81.04362, 72.40536],
              [80.9811, 72.41764],
              [80.89823, 72.42388],
              [80.83745, 72.45884],
              [80.85088, 72.47495],
              [80.79131, 72.49278],
              [80.76735, 72.50783],
              [80.78954, 72.53621],
              [80.82192, 72.531],
              [80.8571, 72.54547],
              [80.81609, 72.56076],
              [80.83169, 72.5779],
              [80.88111, 72.58457],
              [80.87668, 72.60782],
              [80.81592, 72.61838],
              [80.7801, 72.61259],
              [80.71877, 72.62708],
              [80.69458, 72.69169],
              [80.70789, 72.71777],
              [80.69268, 72.72771],
              [80.7458, 72.74107],
              [80.81003, 72.77129],
              [80.84125, 72.79839],
              [80.83111, 72.82164],
              [80.83693, 72.84617],
              [80.87789, 72.87047],
              [80.86549, 72.90418],
              [80.88318, 72.92182],
              [80.88391, 72.94277],
              [80.84233, 72.96906],
              [80.82644, 72.98923],
              [80.80059, 72.99815],
              [80.81515, 73.02525],
              [80.76155, 73.0432],
              [80.70796, 73.0545],
              [80.56172, 73.06932],
              [80.51186, 73.08096],
              [80.5485, 73.08909],
              [80.60823, 73.08704],
              [80.64686, 73.10806],
              [80.6257, 73.13178],
              [80.52417, 73.14396],
              [80.51026, 73.13793],
              [80.45978, 73.14764],
              [80.39443, 73.15244],
              [80.41501, 73.16936],
              [80.38389, 73.17642],
              [80.33582, 73.16452],
              [80.34671, 73.17963],
              [80.39316, 73.18871],
              [80.47995, 73.18001],
              [80.52757, 73.18089],
              [80.52472, 73.18829],
              [80.59637, 73.18257],
              [80.58084, 73.204],
              [80.67304, 73.18819],
              [80.7048, 73.18965],
              [80.68417, 73.20725],
              [80.67843, 73.231],
              [80.63401, 73.25954],
              [80.49563, 73.27764],
              [80.47717, 73.27236],
              [80.35493, 73.29079],
              [80.38267, 73.29752],
              [80.42512, 73.32958],
              [80.43892, 73.35695],
              [80.46992, 73.36342],
              [80.50586, 73.35431],
              [80.57606, 73.36315],
              [80.53039, 73.36995],
              [80.53268, 73.38427],
              [80.62902, 73.3888],
              [80.65891, 73.41261],
              [80.64634, 73.45781],
              [80.71611, 73.4689],
              [80.69696, 73.48599],
              [80.61697, 73.48778],
              [80.62097, 73.46977],
              [80.58798, 73.4609],
              [80.53475, 73.47216],
              [80.56526, 73.49036],
              [80.52435, 73.49091],
              [80.53487, 73.50064],
              [80.44464, 73.51051],
              [80.38929, 73.49861],
              [80.46154, 73.48808],
              [80.46286, 73.47479],
              [80.32181, 73.47745],
              [80.23046, 73.49221],
              [80.24153, 73.50579],
              [80.15189, 73.51797],
              [80.20777, 73.51614],
              [80.27296, 73.52813],
              [80.27612, 73.5385],
              [80.37289, 73.52896],
              [80.38118, 73.52375],
              [80.51204, 73.51298],
              [80.56405, 73.51974],
              [80.57039, 73.53585],
              [80.52464, 73.54469],
              [80.53338, 73.55479],
              [80.60807, 73.56198],
              [80.65685, 73.55951],
              [80.67023, 73.56745],
              [80.70375, 73.56009],
              [80.74604, 73.56806],
              [80.8093, 73.56174],
              [80.84466, 73.54287],
              [80.89785, 73.53945],
              [80.91126, 73.56053],
              [80.95186, 73.56131],
              [81.00239, 73.57103],
              [81.03126, 73.56272],
              [81.09444, 73.56641],
              [81.10578, 73.57534],
              [81.07058, 73.58021],
              [81.30038, 73.5749],
              [81.31112, 73.57016],
              [81.38171, 73.57758],
              [81.41317, 73.59003],
              [81.40846, 73.60078],
              [81.62607, 73.6157],
              [81.64624, 73.62241],
              [81.7676, 73.62252],
              [81.81151, 73.63181],
              [81.8951, 73.63569],
              [81.95542, 73.62878],
              [82.11182, 73.63734],
              [82.12515, 73.63331],
              [82.27427, 73.63975],
              [82.3557, 73.64895],
              [82.50744, 73.64141],
              [82.61345, 73.6405],
              [82.71442, 73.64354],
              [82.911168, 73.641845],
              [83.038313, 73.637074],
              [83.266393, 73.637966],
              [83.526792, 73.643304],
              [83.83647, 73.66882],
              [83.85703, 73.67213],
              [84.095898, 73.690113],
              [84.177008, 73.699272],
              [84.355123, 73.705062],
              [84.452602, 73.711483],
              [84.564713, 73.725484],
              [84.702458, 73.748854],
              [84.756145, 73.754117],
              [84.881257, 73.754222],
              [85.051003, 73.71243],
              [85.133218, 73.707378],
              [85.174271, 73.698417],
              [85.27405, 73.699281],
              [85.393288, 73.704785],
              [85.519722, 73.718152],
              [85.524911, 73.729081],
              [85.489293, 73.73545],
              [85.493932, 73.754399],
              [85.56218, 73.79733],
              [85.589307, 73.796937],
              [85.639235, 73.809675],
              [85.746365, 73.823041],
              [85.892691, 73.81565],
              [86.007566, 73.833577],
              [86.106008, 73.859446],
              [86.185933, 73.863534],
              [86.232598, 73.860311],
              [86.366344, 73.867859],
              [86.422169, 73.875958],
              [86.544967, 73.882168],
              [86.582288, 73.867191],
              [86.662208, 73.873302],
              [86.793995, 73.892285],
              [86.799668, 73.882248],
              [86.843852, 73.879302],
              [86.879853, 73.887594],
              [86.918201, 73.885521],
              [86.924419, 73.87112],
              [87.083262, 73.859556],
              [87.138138, 73.847665],
              [87.138714, 73.807827],
              [87.108057, 73.797525],
              [87.12523, 73.7911],
              [87.08717, 73.79758],
              [87.05641, 73.78383],
              [87.0603, 73.75549],
              [87.02526, 73.73595],
              [86.88942, 73.72475],
              [86.77786, 73.71178],
              [86.79597, 73.70077],
              [86.82926, 73.70447],
              [86.79011, 73.67989],
              [86.93324, 73.7116],
              [86.93552, 73.69742],
              [86.88438, 73.67934],
              [86.82209, 73.6472],
              [86.84983, 73.62359],
              [86.81733, 73.60987],
              [86.63998, 73.59044],
              [86.62708, 73.59258],
              [86.45062, 73.59415],
              [86.47461, 73.6093],
              [86.55102, 73.61512],
              [86.6193, 73.61549],
              [86.61345, 73.62245],
              [86.49864, 73.62444],
              [86.47895, 73.63098],
              [86.52886, 73.64443],
              [86.68008, 73.66081],
              [86.78631, 73.66229],
              [86.78353, 73.67006],
              [86.71786, 73.67005],
              [86.70354, 73.68558],
              [86.62697, 73.67095],
              [86.5163, 73.66438],
              [86.45143, 73.64426],
              [86.39964, 73.60354],
              [86.25579, 73.58769],
              [86.19997, 73.58753],
              [86.12655, 73.56677],
              [86.07311, 73.53962],
              [85.97233, 73.52281],
              [85.91836, 73.50178],
              [85.85169, 73.48629],
              [85.83554, 73.41864],
              [85.82149, 73.3959],
              [85.78514, 73.39571],
              [85.74222, 73.40732],
              [85.70465, 73.40113],
              [85.75645, 73.3587],
              [85.79708, 73.36008],
              [85.8201, 73.3434],
              [85.88053, 73.32662],
              [85.9068, 73.29679],
              [85.98793, 73.29383],
              [86.0273, 73.27917],
              [86.21415, 73.26404],
              [86.31076, 73.25992],
              [86.34137, 73.24246],
              [86.27706, 73.22693],
              [86.27796, 73.21537],
              [86.31214, 73.19527],
              [86.44578, 73.17216],
              [86.596579, 73.15619],
              [86.616149, 73.164494],
              [86.51467, 73.17713],
              [86.4604, 73.19319],
              [86.42003, 73.24811],
              [86.39099, 73.26041],
              [86.31763, 73.27064],
              [86.25972, 73.2734],
              [86.14352, 73.2867],
              [85.97053, 73.32295],
              [85.87765, 73.35411],
              [85.84782, 73.37505],
              [85.89504, 73.40546],
              [85.973965, 73.414861],
              [85.95328, 73.42997],
              [85.89886, 73.43125],
              [85.88047, 73.46577],
              [85.91439, 73.48189],
              [85.99733, 73.5035],
              [86.11204, 73.51291],
              [86.18088, 73.52545],
              [86.20636, 73.54072],
              [86.17576, 73.54711],
              [86.1851, 73.56278],
              [86.33723, 73.57423],
              [86.41168, 73.5704],
              [86.31429, 73.57045],
              [86.33058, 73.56465],
              [86.43406, 73.56251],
              [86.45217, 73.5709],
              [86.63204, 73.58027],
              [86.69576, 73.5756],
              [86.78917, 73.57563],
              [86.84967, 73.5905],
              [86.8814, 73.59038],
              [86.88467, 73.60492],
              [86.96039, 73.61344],
              [87.02439, 73.6043],
              [87.13084, 73.60958],
              [87.20456, 73.62578],
              [87.27175, 73.65332],
              [87.29644, 73.67287],
              [87.27007, 73.68418],
              [87.28283, 73.72052],
              [87.29937, 73.73158],
              [87.36232, 73.73689],
              [87.44001, 73.76948],
              [87.45221, 73.78555],
              [87.44396, 73.80454],
              [87.33592, 73.82165],
              [87.18881, 73.87655],
              [87.16484, 73.90352],
              [87.15839, 73.93493],
              [87.09009, 73.97333],
              [87.048997, 73.969707],
              [86.963187, 73.988327],
              [86.911249, 73.99242],
              [86.814531, 74.010909],
              [86.81697, 73.99436],
              [86.89313, 73.98113],
              [86.77373, 73.97988],
              [86.78505, 73.9856],
              [86.75327, 74.00364],
              [86.766911, 74.012101],
              [86.724873, 74.021136],
              [86.713041, 74.038443],
              [86.7287, 74.05048],
              [86.832146, 74.052415],
              [86.91697, 74.04206],
              [86.916111, 74.026853],
              [86.97296, 74.04206],
              [87.06134, 74.05366],
              [87.11718, 74.05416],
              [87.17799, 74.04593],
              [87.17433, 74.06405],
              [87.12691, 74.07123],
              [86.99491, 74.07392],
              [86.90696, 74.06338],
              [86.83093, 74.07802],
              [86.82453, 74.09283],
              [86.87823, 74.10548],
              [86.86003, 74.11213],
              [86.78944, 74.11646],
              [86.72142, 74.13353],
              [86.77576, 74.15313],
              [86.79716, 74.1736],
              [86.77839, 74.20925],
              [86.72682, 74.22857],
              [86.63593, 74.25198],
              [86.5225, 74.25448],
              [86.4668, 74.25178],
              [86.4233, 74.26004],
              [86.37724, 74.28054],
              [86.24912, 74.28506],
              [86.1683, 74.28083],
              [86.07677, 74.28745],
              [85.956, 74.30688],
              [85.90412, 74.33546],
              [85.92039, 74.37456],
              [85.95148, 74.37822],
              [85.96472, 74.39637],
              [86.02977, 74.39426],
              [86.12498, 74.40014],
              [86.12732, 74.39111],
              [86.20291, 74.37908],
              [86.20843, 74.36973],
              [86.25491, 74.36068],
              [86.30973, 74.3368],
              [86.35048, 74.3494],
              [86.3275, 74.37458],
              [86.2861, 74.3889],
              [86.29352, 74.40411],
              [86.23327, 74.40785],
              [86.14877, 74.42368],
              [86.07049, 74.42652],
              [86.07078, 74.43343],
              [86.12661, 74.43189],
              [86.15709, 74.44348],
              [86.20848, 74.43702],
              [86.24172, 74.45098],
              [86.27904, 74.43958],
              [86.33427, 74.44902],
              [86.31167, 74.4629],
              [86.43219, 74.46279],
              [86.44156, 74.47897],
              [86.56378, 74.47786],
              [86.60048, 74.46513],
              [86.64638, 74.4623],
              [86.64823, 74.44866],
              [86.72176, 74.4324],
              [86.65303, 74.41775],
              [86.65305, 74.40839],
              [86.70139, 74.40753],
              [86.73224, 74.41904],
              [86.82649, 74.40785],
              [86.8515, 74.41359],
              [86.98353, 74.41186],
              [86.96601, 74.39565],
              [86.9844, 74.38873],
              [86.96105, 74.36961],
              [86.89993, 74.36395],
              [86.8743, 74.34859],
              [86.89531, 74.3411],
              [87.00194, 74.35986],
              [86.98447, 74.32791],
              [87.03314, 74.31197],
              [86.9977, 74.30111],
              [86.97945, 74.2839],
              [87, 74.27808],
              [87.05955, 74.28419],
              [87.08646, 74.29596],
              [87.06986, 74.32297],
              [87.09384, 74.33131],
              [87.02043, 74.37911],
              [87.03615, 74.39314],
              [87.13061, 74.38259],
              [87.13969, 74.39028],
              [87.05313, 74.41028],
              [87.04819, 74.42221],
              [87.10931, 74.43257],
              [87.08234, 74.4434],
              [86.97869, 74.43457],
              [86.81055, 74.42957],
              [86.76539, 74.44076],
              [86.80357, 74.45759],
              [86.70125, 74.45781],
              [86.73847, 74.48405],
              [86.65472, 74.50185],
              [86.6646, 74.50801],
              [86.5729, 74.51341],
              [86.59034, 74.52605],
              [86.66969, 74.5343],
              [86.68081, 74.54248],
              [86.76311, 74.55447],
              [86.7554, 74.56237],
              [86.59772, 74.56049],
              [86.58647, 74.5559],
              [86.50945, 74.56141],
              [86.50182, 74.58097],
              [86.37862, 74.58436],
              [86.36539, 74.59365],
              [86.39084, 74.61163],
              [86.3219, 74.61623],
              [86.27351, 74.6102],
              [86.24009, 74.61714],
              [86.18095, 74.60622],
              [86.17806, 74.61064],
              [86.17316, 74.62216],
              [86.03817, 74.61999],
              [86.02161, 74.63164],
              [85.97602, 74.6342],
              [85.96072, 74.61635],
              [85.88559, 74.62159],
              [85.87423, 74.63324],
              [85.77958, 74.64297],
              [85.724163, 74.653963],
              [85.75858, 74.66303],
              [85.83056, 74.66096],
              [85.89177, 74.69115],
              [85.83521, 74.69802],
              [85.83845, 74.71793],
              [85.93054, 74.73566],
              [85.96254, 74.73534],
              [86.00366, 74.72097],
              [86.04298, 74.71779],
              [86.16352, 74.77811],
              [86.11514, 74.77934],
              [86.03735, 74.79224],
              [85.9894, 74.80868],
              [85.97122, 74.79922],
              [85.928, 74.80158],
              [85.88526, 74.79302],
              [85.73704, 74.79362],
              [85.73692, 74.80605],
              [85.79717, 74.81131],
              [85.79121, 74.83475],
              [85.81179, 74.85533],
              [85.79823, 74.86535],
              [85.89121, 74.874],
              [85.92765, 74.86821],
              [85.91038, 74.84783],
              [86.02768, 74.8318],
              [86.12732, 74.83405],
              [86.12846, 74.80198],
              [86.19103, 74.79619],
              [86.22884, 74.81275],
              [86.32236, 74.78595],
              [86.36776, 74.78731],
              [86.40548, 74.77782],
              [86.42254, 74.75677],
              [86.34722, 74.75601],
              [86.29268, 74.76473],
              [86.26351, 74.74914],
              [86.32819, 74.73172],
              [86.33689, 74.71023],
              [86.35851, 74.701],
              [86.41249, 74.71663],
              [86.4233, 74.72816],
              [86.51353, 74.73289],
              [86.56416, 74.72472],
              [86.55313, 74.71011],
              [86.62461, 74.70885],
              [86.66445, 74.68732],
              [86.61373, 74.65961],
              [86.65717, 74.65648],
              [86.70271, 74.66711],
              [86.74405, 74.65554],
              [86.70105, 74.64253],
              [86.70342, 74.61579],
              [86.8279, 74.62823],
              [86.88405, 74.62316],
              [86.90215, 74.63011],
              [86.86886, 74.64074],
              [86.87484, 74.66061],
              [86.96186, 74.67524],
              [86.92482, 74.67995],
              [86.79311, 74.67685],
              [86.80939, 74.68541],
              [86.74558, 74.69937],
              [86.80929, 74.71389],
              [86.79887, 74.72776],
              [86.86635, 74.73114],
              [86.83157, 74.74231],
              [86.8739, 74.75634],
              [86.92021, 74.74217],
              [86.99343, 74.74498],
              [87.03135, 74.7192],
              [87.06766, 74.74661],
              [87.12147, 74.76674],
              [87.09983, 74.77234],
              [87.0313, 74.76514],
              [87.04732, 74.77686],
              [87.047, 74.82869],
              [87.09369, 74.85789],
              [87.15613, 74.85117],
              [87.17692, 74.8585],
              [87.13222, 74.86761],
              [87.20939, 74.88191],
              [87.30294, 74.91481],
              [87.3072, 74.92595],
              [87.36064, 74.92805],
              [87.37833, 74.9485],
              [87.2896, 74.9468],
              [87.25108, 74.95212],
              [87.23306, 74.9659],
              [87.17819, 74.97759],
              [87.25083, 74.99765],
              [87.35729, 74.99633],
              [87.40518, 75.00353],
              [87.46566, 74.99244],
              [87.47717, 74.97425],
              [87.39546, 74.95431],
              [87.41618, 74.94891],
              [87.4986, 74.9531],
              [87.51239, 74.94074],
              [87.56068, 74.93113],
              [87.59298, 74.93882],
              [87.56077, 74.95318],
              [87.62148, 74.96591],
              [87.62077, 74.97358],
              [87.69437, 74.96941],
              [87.73783, 74.97811],
              [87.74446, 74.99339],
              [87.70643, 75.0161],
              [87.70696, 75.03175],
              [87.57916, 75.02978],
              [87.51922, 75.03643],
              [87.43488, 75.03901],
              [87.3991, 75.02827],
              [87.28621, 75.04882],
              [87.18005, 75.05872],
              [87.12955, 75.07103],
              [86.99322, 75.08598],
              [86.94622, 75.08384],
              [86.93255, 75.07436],
              [86.97704, 75.05886],
              [86.94096, 75.06201],
              [86.90454, 75.08338],
              [86.89564, 75.1006],
              [86.98141, 75.1284],
              [87.06743, 75.14103],
              [87.14985, 75.14776],
              [87.21415, 75.14707],
              [87.4342, 75.1362],
              [87.58238, 75.12422],
              [87.90692, 75.106],
              [88.03511, 75.11021],
              [88.1057, 75.12005],
              [88.17014, 75.14012],
              [88.2173, 75.13624],
              [88.32674, 75.15039],
              [88.31982, 75.16473],
              [88.29213, 75.16211],
              [88.31087, 75.16941],
              [88.19863, 75.17005],
              [88.14837, 75.15823],
              [88.11202, 75.16862],
              [88.16809, 75.18261],
              [88.17122, 75.20574],
              [88.22744, 75.19922],
              [88.30283, 75.20178],
              [88.37057, 75.21953],
              [88.442123, 75.245395],
              [88.498978, 75.28074],
              [88.573965, 75.276942],
              [88.56296, 75.29522],
              [88.614532, 75.323156],
              [88.720442, 75.341501],
              [88.758664, 75.361526],
              [88.811573, 75.364104],
              [88.862606, 75.376651],
              [88.85612, 75.392153],
              [88.796752, 75.39471],
              [88.768622, 75.386501],
              [88.740001, 75.395493],
              [88.808527, 75.408066],
              [88.879016, 75.407571],
              [88.964064, 75.426282],
              [88.997893, 75.425839],
              [89.062991, 75.441651],
              [89.103034, 75.433624],
              [89.170493, 75.44088],
              [89.177888, 75.469056],
              [89.210355, 75.481858],
              [89.271141, 75.490899],
              [89.304087, 75.511816],
              [89.368012, 75.50058],
              [89.413745, 75.501065],
              [89.439484, 75.513342],
              [89.495453, 75.504941],
              [89.515044, 75.484001],
              [89.432288, 75.487981],
              [89.361992, 75.496942],
              [89.309046, 75.476587],
              [89.306113, 75.461561],
              [89.465367, 75.447562],
              [89.399568, 75.441497],
              [89.411591, 75.430234],
              [89.481529, 75.425116],
              [89.53204, 75.432182],
              [89.574216, 75.448503],
              [89.624616, 75.435498],
              [89.653065, 75.440824],
              [89.758583, 75.43233],
              [89.684085, 75.460376],
              [89.679892, 75.47616],
              [89.716682, 75.487032],
              [89.802081, 75.487629],
              [89.889006, 75.50138],
              [89.982221, 75.496416],
              [90.027208, 75.512615],
              [90.008432, 75.52982],
              [89.95277, 75.536059],
              [89.948576, 75.549812],
              [90.02466, 75.56248],
              [90.1206, 75.56468],
              [90.16713, 75.57867],
              [90.19237, 75.597],
              [90.24721, 75.57847],
              [90.29628, 75.59066],
              [90.42117, 75.58706],
              [90.45317, 75.58012],
              [90.5307, 75.58899],
              [90.56454, 75.60162],
              [90.63402, 75.6041],
              [90.66263, 75.59864],
              [90.69805, 75.61189],
              [90.68533, 75.63068],
              [90.75058, 75.64153],
              [90.79175, 75.63489],
              [90.88749, 75.64943],
              [90.89389, 75.66226],
              [90.95081, 75.66097],
              [90.91537, 75.6346],
              [90.94478, 75.62309],
              [90.93491, 75.61279],
              [91.01632, 75.60377],
              [91.09542, 75.62767],
              [91.14556, 75.65439],
              [91.22154, 75.6611],
              [91.21343, 75.63037],
              [91.28635, 75.62286],
              [91.36823, 75.62663],
              [91.38936, 75.64032],
              [91.43694, 75.64804],
              [91.50878, 75.6508],
              [91.55372, 75.63383],
              [91.68065, 75.63035],
              [91.66063, 75.64672],
              [91.68745, 75.6636],
              [91.63693, 75.68555],
              [91.70046, 75.69714],
              [91.69257, 75.7275],
              [91.84202, 75.72392],
              [91.79208, 75.71423],
              [91.80721, 75.70638],
              [91.99002, 75.72511],
              [92.03182, 75.7194],
              [92.02064, 75.70863],
              [92.06077, 75.70381],
              [92.09449, 75.71863],
              [92.18181, 75.73976],
              [92.31659, 75.73929],
              [92.36869, 75.73267],
              [92.46537, 75.75218],
              [92.48847, 75.77394],
              [92.54645, 75.78045],
              [92.65166, 75.77066],
              [92.70761, 75.77789],
              [92.85403, 75.78875],
              [93.01419, 75.79594],
              [93.15072, 75.81748],
              [93.19976, 75.82191],
              [93.30907, 75.81541],
              [93.41145, 75.82471],
              [93.46442, 75.83483],
              [93.46311, 75.84275],
              [93.60747, 75.84984],
              [93.73726, 75.87579],
              [93.83968, 75.87367],
              [93.92915, 75.89837],
              [93.99324, 75.9008],
              [94.03691, 75.9102],
              [94.02068, 75.91806],
              [93.9245, 75.91576],
              [93.90441, 75.90485],
              [93.82067, 75.90732],
              [93.78933, 75.89044],
              [93.6843, 75.88425],
              [93.61565, 75.89477],
              [93.62467, 75.92157],
              [93.58256, 75.94441],
              [93.55878, 75.94114],
              [93.51481, 75.90216],
              [93.56966, 75.88441],
              [93.54868, 75.86938],
              [93.46831, 75.87441],
              [93.42815, 75.89569],
              [93.36371, 75.91674],
              [93.33159, 75.93415],
              [93.28125, 75.93881],
              [93.32107, 75.95716],
              [93.31897, 75.98091],
              [93.28049, 75.98045],
              [93.27592, 75.96206],
              [93.22386, 75.96404],
              [93.20794, 75.95562],
              [93.13428, 75.95439],
              [93.11522, 75.93869],
              [93.02797, 75.92569],
              [93.1318, 75.91391],
              [93.16942, 75.90137],
              [93.09018, 75.88481],
              [92.98496, 75.89645],
              [92.90645, 75.88467],
              [92.86821, 75.8876],
              [92.94036, 75.91215],
              [92.939532, 75.922753],
              [92.89776, 75.92874],
              [92.91181, 75.95021],
              [92.83539, 75.96523],
              [92.97011, 75.98214],
              [92.99329, 75.99274],
              [92.96794, 76.0087],
              [92.98851, 76.03552],
              [92.93394, 76.05212],
              [92.98903, 76.05034],
              [93.02294, 76.06362],
              [93.09718, 76.06692],
              [93.10239, 76.05619],
              [93.06739, 76.04701],
              [93.05356, 76.03092],
              [93.12648, 76.024],
              [93.15225, 76.010632],
              [93.2131, 76.0196],
              [93.29337, 76.04301],
              [93.27096, 76.06143],
              [93.32514, 76.06697],
              [93.31916, 76.08397],
              [93.18548, 76.08999],
              [93.22294, 76.10847],
              [93.27414, 76.11672],
              [93.31025, 76.11323],
              [93.28966, 76.09512],
              [93.35658, 76.09452],
              [93.36423, 76.10243],
              [93.44648, 76.11557],
              [93.50756, 76.09783],
              [93.50509, 76.08813],
              [93.42183, 76.07347],
              [93.373993, 76.071776],
              [93.369386, 76.062027],
              [93.442925, 76.059639],
              [93.47802, 76.04093],
              [93.46697, 76.02691],
              [93.42185, 76.02086],
              [93.43321, 76.0119],
              [93.51193, 76.01277],
              [93.58002, 76.03739],
              [93.55902, 76.05532],
              [93.5984, 76.05957],
              [93.62569, 76.04137],
              [93.71689, 76.05516],
              [93.70593, 76.06924],
              [93.77796, 76.06775],
              [93.79515, 76.05262],
              [93.86847, 76.05621],
              [94.01819, 76.04592],
              [94.02517, 76.05458],
              [93.98135, 76.06446],
              [93.92112, 76.06764],
              [93.87995, 76.08784],
              [93.83412, 76.08506],
              [93.78688, 76.09467],
              [93.72, 76.09577],
              [93.69316, 76.11194],
              [93.72447, 76.12091],
              [93.83101, 76.12249],
              [93.86361, 76.116],
              [93.95804, 76.11181],
              [94.01275, 76.11824],
              [94.0861, 76.11716],
              [94.1112, 76.1385],
              [94.17012, 76.10349],
              [94.21875, 76.10218],
              [94.33934, 76.12766],
              [94.42899, 76.12945],
              [94.399204, 76.108152],
              [94.41958, 76.09949],
              [94.493029, 76.101232],
              [94.50716, 76.09322],
              [94.48164, 76.07779],
              [94.51209, 76.06968],
              [94.559582, 76.082619],
              [94.69018, 76.07747],
              [94.69447, 76.08229],
              [94.61938, 76.09889],
              [94.63044, 76.11185],
              [94.78944, 76.11534],
              [94.78608, 76.13939],
              [94.59354, 76.13382],
              [94.54713, 76.1479],
              [94.62699, 76.15594],
              [94.6673, 76.15377],
              [94.78545, 76.1597],
              [94.90762, 76.15508],
              [94.91159, 76.13717],
              [95.00975, 76.13766],
              [95.0341, 76.12903],
              [94.96527, 76.13214],
              [94.9753, 76.11239],
              [95.04696, 76.10745],
              [95.04871, 76.09747],
              [95.11785, 76.09765],
              [95.12347, 76.1068],
              [95.20268, 76.12349],
              [95.23487, 76.12166],
              [95.28607, 76.1056],
              [95.33625, 76.10666],
              [95.3436, 76.12858],
              [95.45664, 76.16275],
              [95.52371, 76.14941],
              [95.61587, 76.15139],
              [95.66921, 76.13983],
              [95.76628, 76.13979],
              [95.78686, 76.1467],
              [95.68553, 76.16956],
              [95.74106, 76.18301],
              [95.7333, 76.18988],
              [95.59431, 76.18511],
              [95.54666, 76.20567],
              [95.51486, 76.19565],
              [95.49695, 76.20939],
              [95.4294, 76.20621],
              [95.42779, 76.2185],
              [95.36222, 76.21773],
              [95.33283, 76.23449],
              [95.37426, 76.24458],
              [95.34975, 76.25862],
              [95.34985, 76.27842],
              [95.39791, 76.27605],
              [95.52522, 76.28322],
              [95.56563, 76.28025],
              [95.51304, 76.26341],
              [95.61, 76.25243],
              [95.58673, 76.23708],
              [95.65162, 76.24509],
              [95.68972, 76.28124],
              [95.72736, 76.28083],
              [95.77653, 76.30048],
              [95.89301, 76.31577],
              [95.92757, 76.3054],
              [95.89126, 76.26824],
              [95.94965, 76.26678],
              [95.99073, 76.28777],
              [96.16422, 76.28716],
              [96.1594, 76.26343],
              [96.12875, 76.23466],
              [96.16025, 76.23145],
              [96.22849, 76.24783],
              [96.25234, 76.28116],
              [96.2788, 76.29744],
              [96.37495, 76.29999],
              [96.37866, 76.30863],
              [96.47049, 76.29996],
              [96.48547, 76.28644],
              [96.70958, 76.25683],
              [96.70511, 76.23797],
              [96.65695, 76.21616],
              [96.59727, 76.20527],
              [96.63338, 76.156055],
              [96.60491, 76.140868],
              [96.55219, 76.134988],
              [96.53414, 76.152396],
              [96.56937, 76.17778],
              [96.53974, 76.18768],
              [96.57094, 76.21563],
              [96.52886, 76.22545],
              [96.49479, 76.27198],
              [96.44117, 76.27298],
              [96.40097, 76.25614],
              [96.40786, 76.22254],
              [96.49929, 76.20591],
              [96.46427, 76.2009],
              [96.43596, 76.21229],
              [96.3644, 76.21814],
              [96.31105, 76.2149],
              [96.328125, 76.19863],
              [96.40238, 76.19767],
              [96.42967, 76.17772],
              [96.47679, 76.17348],
              [96.4655, 76.13628],
              [96.40322, 76.14356],
              [96.41893, 76.12031],
              [96.46169, 76.11639],
              [96.35897, 76.10119],
              [96.37682, 76.11578],
              [96.35643, 76.13091],
              [96.30455, 76.1371],
              [96.29277, 76.15299],
              [96.24607, 76.14734],
              [96.15877, 76.15807],
              [96.10877, 76.14208],
              [96.01517, 76.15603],
              [95.9656, 76.14895],
              [95.86474, 76.15182],
              [95.83246, 76.13992],
              [95.89949, 76.13901],
              [96.03359, 76.10761],
              [96.10521, 76.10402],
              [96.13288, 76.11364],
              [96.17307, 76.09574],
              [96.27579, 76.09592],
              [96.25695, 76.08338],
              [96.16628, 76.07485],
              [96.17578, 76.08879],
              [96.12323, 76.08435],
              [96.10105, 76.07044],
              [96.04428, 76.06821],
              [96.02846, 76.05869],
              [96.06864, 76.04822],
              [96.15762, 76.04785],
              [96.09744, 76.03686],
              [96.03306, 76.01312],
              [96.05482, 76.00363],
              [95.97804, 75.9826],
              [95.93111, 75.98278],
              [95.8965, 75.95775],
              [95.84985, 75.94041],
              [95.78307, 75.92968],
              [95.75731, 75.91872],
              [95.75946, 75.90258],
              [95.70032, 75.89494],
              [95.68236, 75.87737],
              [95.61643, 75.87477],
              [95.64902, 75.8555],
              [95.71347, 75.84788],
              [95.74319, 75.82824],
              [95.77139, 75.84467],
              [95.81451, 75.85414],
              [95.8172, 75.86792],
              [95.90184, 75.90092],
              [95.97801, 75.91406],
              [95.99306, 75.93587],
              [96.07349, 75.94305],
              [96.12296, 75.96237],
              [96.17662, 75.95246],
              [96.19648, 75.93762],
              [96.23578, 75.95092],
              [96.29854, 75.94675],
              [96.37038, 75.95446],
              [96.31469, 75.96187],
              [96.35869, 75.97201],
              [96.34933, 75.9793],
              [96.28123, 75.97691],
              [96.26955, 75.98576],
              [96.35156, 75.99334],
              [96.38318, 75.98856],
              [96.42844, 75.99892],
              [96.44313, 76.01381],
              [96.44132, 76.00194],
              [96.49131, 75.9988],
              [96.53724, 76.00504],
              [96.49133, 75.97731],
              [96.5394, 75.97111],
              [96.66246, 75.98196],
              [96.70911, 75.9705],
              [96.62891, 75.92463],
              [96.58022, 75.91922],
              [96.49969, 75.87562],
              [96.65279, 75.87796],
              [96.68371, 75.89643],
              [96.74582, 75.9052],
              [96.76115, 75.89153],
              [96.8465, 75.89671],
              [96.87876, 75.91513],
              [96.92865, 75.91689],
              [96.99028, 75.94378],
              [96.97736, 75.95986],
              [97.04585, 75.96897],
              [97.06862, 75.98037],
              [97.13346, 75.98542],
              [97.18316, 75.99635],
              [97.16067, 76.00263],
              [97.04706, 76.00121],
              [96.9685, 75.99635],
              [96.86108, 75.97838],
              [96.78426, 75.97536],
              [96.72737, 75.99198],
              [96.74406, 76.00323],
              [96.89782, 76.03179],
              [96.9216, 76.04114],
              [97.03631, 76.05667],
              [97.06346, 76.06621],
              [97.15098, 76.07114],
              [97.22664, 76.08956],
              [97.2665, 76.08759],
              [97.35441, 76.10345],
              [97.36904, 76.08527],
              [97.32856, 76.07818],
              [97.29319, 76.05972],
              [97.20795, 76.0356],
              [97.20525, 76.0121],
              [97.23474, 76.00979],
              [97.36551, 76.02541],
              [97.39993, 76.02018],
              [97.32137, 76.00571],
              [97.31242, 75.99537],
              [97.27397, 75.99938],
              [97.29708, 75.9813],
              [97.22231, 75.99285],
              [97.18266, 75.9856],
              [97.1847, 75.97456],
              [97.2664, 75.96699],
              [97.27286, 75.95331],
              [97.17902, 75.92985],
              [97.20222, 75.92459],
              [97.31576, 75.92771],
              [97.32537, 75.95955],
              [97.40191, 75.98199],
              [97.4484, 75.97858],
              [97.4714, 75.98789],
              [97.53907, 75.99458],
              [97.58596, 75.98576],
              [97.63892, 75.98604],
              [97.67603, 75.97021],
              [97.72432, 75.96927],
              [97.75646, 75.98018],
              [97.85013, 75.96962],
              [97.87284, 75.98197],
              [97.84195, 75.99848],
              [97.78645, 76.0129],
              [97.77674, 76.03128],
              [97.68739, 76.02418],
              [97.60121, 76.02979],
              [97.56238, 76.03769],
              [97.64414, 76.05407],
              [97.68752, 76.06964],
              [97.85669, 76.09145],
              [97.83624, 76.07213],
              [97.90528, 76.07485],
              [97.93847, 76.0647],
              [97.89949, 76.05498],
              [97.93403, 76.04429],
              [97.9749, 76.04577],
              [98.03555, 76.064],
              [98.08066, 76.05333],
              [98.0887, 76.06982],
              [98.15425, 76.07742],
              [98.19755, 76.07061],
              [98.2704, 76.08904],
              [98.20303, 76.08843],
              [98.0798, 76.10589],
              [98.05272, 76.09427],
              [98.01922, 76.10803],
              [97.95568, 76.10638],
              [97.96502, 76.1208],
              [98.05336, 76.13488],
              [98.07584, 76.12904],
              [98.14014, 76.13539],
              [98.11541, 76.14975],
              [98.18949, 76.154589],
              [98.197865, 76.163364],
              [98.278645, 76.157777],
              [98.39233, 76.16844],
              [98.450991, 76.184009],
              [98.52666, 76.18828],
              [98.56066, 76.20906],
              [98.60949, 76.21283],
              [98.66073, 76.22782],
              [98.80344, 76.25376],
              [98.86757, 76.25609],
              [98.83207, 76.22666],
              [98.77664, 76.20588],
              [98.80203, 76.19965],
              [98.90063, 76.20655],
              [98.94958, 76.19767],
              [98.97233, 76.20688],
              [98.93913, 76.21871],
              [99.07274, 76.21636],
              [99.07908, 76.20863],
              [99.33464, 76.20476],
              [99.38093, 76.19175],
              [99.38927, 76.17818],
              [99.36048, 76.18817],
              [99.26336, 76.17654],
              [99.24656, 76.16413],
              [99.28524, 76.14979],
              [99.23898, 76.15431],
              [99.19488, 76.14183],
              [99.26159, 76.12074],
              [99.36828, 76.12004],
              [99.4082, 76.13018],
              [99.48205, 76.12772],
              [99.6075, 76.15314],
              [99.63238, 76.14219],
              [99.58013, 76.14052],
              [99.60244, 76.13092],
              [99.58844, 76.11589],
              [99.62758, 76.11149],
              [99.70569, 76.11373],
              [99.79863, 76.0978],
              [99.78806, 76.07804],
              [99.7982, 76.0536],
              [99.86252, 76.05874],
              [99.89659, 76.08674],
              [99.84567, 76.09777],
              [99.84933, 76.10641],
              [99.79626, 76.12177],
              [99.77827, 76.15336],
              [99.73203, 76.1734],
              [99.62873, 76.19136],
              [99.60795, 76.2523],
              [99.52772, 76.25636],
              [99.40712, 76.27146],
              [99.40446, 76.29747],
              [99.32231, 76.33013],
              [99.28539, 76.33736],
              [99.17935, 76.33835],
              [99.10302, 76.33279],
              [99.06457, 76.35915],
              [99.03058, 76.40104],
              [98.87223, 76.45458],
              [98.8618, 76.47126],
              [98.88212, 76.48285],
              [98.968108, 76.502324],
              [99.214512, 76.471801],
              [99.350505, 76.458176],
              [99.406119, 76.44673],
              [99.648796, 76.445569],
              [99.7047, 76.44242],
              [99.85993, 76.44824],
              [100.11459, 76.46555],
              [100.31626, 76.45845],
              [100.34815, 76.45237],
              [100.43897, 76.45381],
              [100.55925, 76.46281],
              [100.72665, 76.48087],
              [100.86385, 76.49926],
              [100.95239, 76.51875],
              [100.99419, 76.53636],
              [101.05093, 76.53416],
              [101.09327, 76.54173],
              [101.12706, 76.56605],
              [101.119208, 76.57988],
              [101.156891, 76.60212],
              [101.174175, 76.632359],
              [101.183375, 76.680136],
              [101.21947, 76.673939],
              [101.288121, 76.674428],
              [101.381542, 76.661698],
              [101.436038, 76.678995],
              [101.406313, 76.693174],
              [101.337662, 76.707827],
              [101.28529, 76.73473],
              [101.205315, 76.754366],
              [101.081461, 76.750798],
              [101.067306, 76.763282],
              [100.995117, 76.792582],
              [100.927882, 76.847287],
              [100.930713, 76.858717],
              [101.026611, 76.91163],
              [101.071906, 76.944285],
              [101.059875, 76.958347],
              [101.154004, 76.964574],
              [101.190806, 76.953555],
              [101.185852, 76.921884],
              [101.239994, 76.916277],
              [101.29449, 76.923005],
              [101.27255, 76.953395],
              [101.332708, 76.993118],
              [101.307229, 77.017705],
              [101.359037, 77.037469],
              [101.280477, 77.048768],
              [101.394314, 77.109003],
              [101.528984, 77.153001],
              [101.59747, 77.180166],
              [101.795128, 77.242669],
              [101.967735, 77.288669],
              [102.041138, 77.314516],
              [102.085203, 77.339385],
              [102.146391, 77.338985],
              [102.249133, 77.320882],
              [102.323107, 77.321683],
              [102.315345, 77.336407],
              [102.268768, 77.333404],
              [102.242969, 77.348214],
              [102.343542, 77.359061],
              [102.354273, 77.372694],
              [102.403132, 77.375988],
              [102.394684, 77.392992],
              [102.428247, 77.399867],
              [102.563295, 77.465096],
              [102.622657, 77.477676],
              [102.656448, 77.493013],
              [102.905768, 77.51966],
              [103.008967, 77.538198],
              [103.038191, 77.551444],
              [102.986021, 77.570206],
              [103.122325, 77.588499],
              [103.201665, 77.614505],
              [103.284544, 77.629138],
              [103.363997, 77.632122],
              [103.352125, 77.625176],
              [103.446191, 77.605197],
              [103.592541, 77.607794],
              [103.69026, 77.620771],
              [103.753732, 77.608063],
              [103.822911, 77.622705],
              [103.778161, 77.635422],
              [103.903392, 77.667258],
              [103.890607, 77.680223],
              [103.996088, 77.689232],
              [104.092209, 77.716852],
              [104.118237, 77.70655],
              [104.179197, 77.705821],
              [104.253971, 77.723286],
              [104.345753, 77.70232],
              [104.370754, 77.686603],
              [104.474637, 77.687528],
              [104.420755, 77.674766],
              [104.30557, 77.683121],
              [104.290273, 77.67323],
              [104.36242, 77.665258],
              [104.358311, 77.655353],
              [104.424294, 77.652864],
              [104.54336, 77.661844],
              [104.637541, 77.678566],
              [104.704437, 77.684947],
              [104.839143, 77.667599],
              [104.815398, 77.639626],
              [104.859235, 77.626423],
              [104.803297, 77.620013],
              [104.849189, 77.598604],
              [104.911747, 77.593405],
              [104.938689, 77.581578],
              [105.030243, 77.575488],
              [105.067687, 77.580792],
              [105.167232, 77.559193],
              [105.270773, 77.553388],
              [105.347031, 77.538518],
              [105.425571, 77.53906],
              [105.52877, 77.533196],
              [105.633795, 77.538617],
              [105.64521, 77.548565],
              [105.747267, 77.55137],
              [105.819415, 77.547088],
              [105.901837, 77.552158],
              [105.886311, 77.535365],
              [105.949783, 77.512331],
              [105.967135, 77.482477],
              [105.935399, 77.470942],
              [105.992592, 77.436075],
              [106.039397, 77.420272],
              [106.027525, 77.408779],
              [106.085288, 77.396082],
              [106.203327, 77.380079],
              [106.201273, 77.371696],
              [106.308124, 77.365405],
              [106.25858, 77.355363],
              [106.286434, 77.326243],
              [106.248762, 77.341712],
              [106.193053, 77.334856],
              [106.079809, 77.351164],
              [106.032091, 77.351064],
              [105.918618, 77.36071],
              [105.83711, 77.360285],
              [105.836653, 77.342887],
              [105.682426, 77.290553],
              [105.566442, 77.259976],
              [105.617014, 77.258264],
              [105.608794, 77.248491],
              [105.538815, 77.231926],
              [105.584022, 77.225415],
              [105.648521, 77.241434],
              [105.68117, 77.240829],
              [105.577629, 77.217184],
              [105.512559, 77.228545],
              [105.425571, 77.219785],
              [105.428653, 77.211373],
              [105.363355, 77.194634],
              [105.209927, 77.179089],
              [105.15125, 77.180027],
              [104.955584, 77.152083],
              [104.844394, 77.165175],
              [104.699642, 77.159899],
              [104.630234, 77.151169],
              [104.580119, 77.122298],
              [104.514136, 77.116241],
              [104.432285, 77.128149],
              [104.423609, 77.116954],
              [104.377946, 77.116038],
              [104.28525, 77.103457],
              [104.242103, 77.092448],
              [104.255885, 77.084601],
              [104.324288, 77.081905],
              [104.384069, 77.072703],
              [104.577463, 77.075781],
              [104.578352, 77.086418],
              [104.640605, 77.086816],
              [104.723312, 77.080057],
              [104.769113, 77.105881],
              [104.848707, 77.076676],
              [104.918742, 77.066429],
              [104.94053, 77.053385],
              [105.030015, 77.060276],
              [105.081386, 77.055367],
              [105.158899, 77.068479],
              [105.202964, 77.090431],
              [105.303411, 77.070961],
              [105.435276, 77.06749],
              [105.523979, 77.077454],
              [105.577025, 77.091324],
              [105.652717, 77.099706],
              [105.723904, 77.085258],
              [105.817736, 77.088501],
              [105.856145, 77.096717],
              [105.903812, 77.125852],
              [105.951854, 77.126632],
              [105.885045, 77.099985],
              [105.909567, 77.072165],
              [105.892427, 77.060236],
              [105.766316, 77.04852],
              [105.776575, 77.032078],
              [105.815109, 77.027192],
              [105.71377, 77.014266],
              [105.630447, 77.024608],
              [105.509842, 77.018314],
              [105.465106, 77.005134],
              [105.494058, 76.992882],
              [105.4958, 76.969192],
              [105.527299, 76.966949],
              [105.533599, 76.98197],
              [105.572392, 76.982717],
              [105.629028, 76.970636],
              [105.688163, 76.971311],
              [105.792217, 76.987873],
              [105.887214, 76.983991],
              [105.975964, 76.996063],
              [106.112521, 77.029],
              [106.140277, 77.044952],
              [106.193126, 77.056524],
              [106.286945, 77.046963],
              [106.40331, 77.045224],
              [106.46428, 77.033725],
              [106.502462, 77.003365],
              [106.555069, 77.003146],
              [106.587796, 77.020636],
              [106.651636, 76.99284],
              [106.721852, 77.000169],
              [106.681498, 77.019029],
              [106.77204, 77.033857],
              [106.741612, 77.042513],
              [106.777606, 77.048337],
              [106.867776, 77.047422],
              [106.94074, 77.035712],
              [106.978358, 77.010652],
              [107.075393, 77.02189],
              [107.071125, 77.007357],
              [107.010882, 76.990407],
              [107.070305, 76.987894],
              [107.124474, 77.00171],
              [107.216262, 76.998425],
              [107.246082, 77.008585],
              [107.314828, 77.009387],
              [107.245725, 76.985282],
              [107.305096, 76.978786],
              [107.358203, 76.964344],
              [107.311275, 76.959092],
              [107.317412, 76.94752],
              [107.417043, 76.923163],
              [107.508371, 76.922877],
              [107.531112, 76.914175],
              [107.512522, 76.90101],
              [107.450614, 76.90146],
              [107.317592, 76.885539],
              [107.259835, 76.88947],
              [107.241425, 76.882508],
              [107.284743, 76.874396],
              [107.37914, 76.8721],
              [107.361993, 76.857416],
              [107.265701, 76.848877],
              [107.210804, 76.835954],
              [107.244932, 76.832081],
              [107.311741, 76.840379],
              [107.23574, 76.797148],
              [107.087209, 76.79865],
              [107.059831, 76.787882],
              [107.035747, 76.739521],
              [106.959808, 76.738068],
              [106.932979, 76.713321],
              [106.849603, 76.722023],
              [106.7824, 76.718411],
              [106.792101, 76.684369],
              [106.845966, 76.676916],
              [106.84178, 76.664587],
              [106.768075, 76.644598],
              [106.794282, 76.631965],
              [106.769253, 76.624459],
              [106.787026, 76.611778],
              [106.733111, 76.60174],
              [106.721955, 76.584174],
              [106.746055, 76.575875],
              [106.688801, 76.565614],
              [106.658882, 76.544324],
              [106.621635, 76.536546],
              [106.608696, 76.566522],
              [106.57769, 76.588433],
              [106.539636, 76.597609],
              [106.414008, 76.576113],
              [106.46421, 76.551814],
              [106.454081, 76.513295],
              [106.622823, 76.487814],
              [106.729736, 76.488213],
              [106.775856, 76.499652],
              [106.794302, 76.472779],
              [106.858955, 76.458256],
              [106.875, 76.48231],
              [106.847389, 76.485937],
              [106.890952, 76.50069],
              [106.938476, 76.504035],
              [107.005996, 76.49418],
              [107.073711, 76.493504],
              [107.099204, 76.502877],
              [107.257668, 76.51467],
              [107.329369, 76.51108],
              [107.319737, 76.522159],
              [107.4349, 76.525547],
              [107.623804, 76.492595],
              [107.724905, 76.500342],
              [107.806083, 76.517222],
              [107.890549, 76.527902],
              [107.932044, 76.539826],
              [107.98789, 76.566789],
              [108.009877, 76.619206],
              [108.040017, 76.63547],
              [107.969014, 76.652162],
              [107.985769, 76.693136],
              [107.951894, 76.721145],
              [107.999541, 76.732842],
              [108.094293, 76.73541],
              [108.221455, 76.729466],
              [108.307357, 76.742307],
              [108.358378, 76.738008],
              [108.296164, 76.718587],
              [108.306836, 76.702011],
              [108.356035, 76.695603],
              [108.38545, 76.709314],
              [108.46094, 76.708476],
              [108.465886, 76.717271],
              [108.562461, 76.700275],
              [108.661245, 76.697393],
              [108.762275, 76.69952],
              [109.126379, 76.720508],
              [109.189629, 76.711814],
              [109.426762, 76.752446],
              [109.450962, 76.733623],
              [109.514738, 76.727066],
              [109.54978, 76.733556],
              [109.624289, 76.726328],
              [109.784575, 76.697119],
              [109.84144, 76.691178],
              [109.94733, 76.688633],
              [110.054545, 76.692681],
              [110.129985, 76.706683],
              [110.275929, 76.726524],
              [110.549016, 76.747156],
              [110.644084, 76.749822],
              [110.702698, 76.745572],
              [110.664855, 76.735383],
              [110.69138, 76.721454],
              [110.796397, 76.716397],
              [110.948403, 76.729453],
              [111.06327, 76.746259],
              [111.069652, 76.76536],
              [111.133245, 76.76962],
              [111.119702, 76.75467],
              [111.23905, 76.741516],
              [111.139505, 76.703622],
              [111.207832, 76.698116],
              [111.222755, 76.68209],
              [111.302165, 76.689894],
              [111.378543, 76.67926],
              [111.492686, 76.673289],
              [111.546857, 76.674726],
              [111.581398, 76.686166],
              [111.69708, 76.669785],
              [111.609866, 76.653183],
              [111.556005, 76.626963],
              [111.610149, 76.615022],
              [111.563165, 76.601601],
              [111.582422, 76.596733],
              [111.700813, 76.603131],
              [111.853659, 76.578599],
              [112.002522, 76.549445],
              [111.982854, 76.53585],
              [112.012685, 76.51548],
              [112.114017, 76.502602],
              [112.228669, 76.456883],
              [112.243485, 76.443336],
              [112.191809, 76.438912],
              [112.170904, 76.425109],
              [112.088867, 76.40405],
              [112.08199, 76.389588],
              [112.002289, 76.363815],
              [112.062593, 76.355938],
              [112.134029, 76.390932],
              [112.179297, 76.39709],
              [112.200583, 76.409594],
              [112.245683, 76.414205],
              [112.325587, 76.44506],
              [112.400641, 76.438732],
              [112.425972, 76.451104],
              [112.380523, 76.471748],
              [112.363658, 76.490512],
              [112.292856, 76.521909],
              [112.250387, 76.527124],
              [112.066312, 76.529663],
              [112.083645, 76.540748],
              [112.052721, 76.550359],
              [112.068585, 76.568172],
              [111.971925, 76.580375],
              [111.998816, 76.602514],
              [112.180664, 76.621204],
              [112.18932, 76.610285],
              [112.268614, 76.606989],
              [112.305147, 76.625359],
              [112.277396, 76.637864],
              [112.351425, 76.636884],
              [112.449321, 76.626689],
              [112.404492, 76.613852],
              [112.475317, 76.6113],
              [112.517009, 76.594317],
              [112.48776, 76.580516],
              [112.527617, 76.57341],
              [112.514877, 76.554358],
              [112.604463, 76.538411],
              [112.524223, 76.509166],
              [112.630716, 76.502347],
              [112.706938, 76.512375],
              [112.688396, 76.52453],
              [112.735835, 76.502989],
              [112.653711, 76.49418],
              [112.616609, 76.482953],
              [112.591909, 76.459248],
              [112.602773, 76.448106],
              [112.670146, 76.44682],
              [112.73057, 76.425389],
              [112.704686, 76.376239],
              [112.625705, 76.35885],
              [112.712992, 76.331437],
              [112.808121, 76.320476],
              [112.727034, 76.288257],
              [112.734475, 76.249916],
              [112.69953, 76.237621],
              [112.728354, 76.20273],
              [112.622157, 76.214798],
              [112.561352, 76.233148],
              [112.548374, 76.215142],
              [112.600054, 76.204928],
              [112.595855, 76.193216],
              [112.691135, 76.174289],
              [112.739313, 76.177666],
              [112.750485, 76.188128],
              [112.805932, 76.188947],
              [112.77415, 76.164719],
              [112.829979, 76.137445],
              [112.880492, 76.125006],
              [112.844496, 76.10762],
              [112.888317, 76.092042],
              [112.764652, 76.060988],
              [112.83573, 76.049439],
              [112.899598, 76.050979],
              [112.913793, 76.061079],
              [112.99862, 76.071188],
              [113.050617, 76.085449],
              [113.048267, 76.109489],
              [112.991921, 76.114174],
              [113.067645, 76.145182],
              [113.193536, 76.152596],
              [113.202612, 76.169046],
              [113.167165, 76.183117],
              [113.056709, 76.189861],
              [113.023695, 76.184331],
              [112.997334, 76.225681],
              [112.95268, 76.236531],
              [112.984125, 76.240408],
              [113.169847, 76.239471],
              [113.192887, 76.259325],
              [113.106931, 76.261651],
              [113.142646, 76.270782],
              [113.221435, 76.26817],
              [113.285057, 76.254697],
              [113.351316, 76.216879],
              [113.398087, 76.202907],
              [113.478185, 76.151409],
              [113.510406, 76.101541],
              [113.53624, 76.075101],
              [113.497337, 76.067435],
              [113.537158, 76.037667],
              [113.552582, 76.015456],
              [113.536068, 75.980958],
              [113.593559, 75.951061],
              [113.545242, 75.937815],
              [113.504276, 75.940294],
              [113.520496, 75.928373],
              [113.601207, 75.926796],
              [113.584136, 75.916054],
              [113.654211, 75.912772],
              [113.719592, 75.919186],
              [113.712667, 75.933887],
              [113.618059, 75.940641],
              [113.642771, 75.951625],
              [113.72988, 75.963716],
              [113.795097, 75.929861],
              [113.849311, 75.919744],
              [113.879146, 75.926778],
              [113.921685, 75.886222],
              [113.924253, 75.84456],
              [113.904754, 75.783374],
              [113.888602, 75.763306],
              [113.804553, 75.708623],
              [113.780923, 75.684976],
              [113.726336, 75.611057],
              [113.674514, 75.581518],
              [113.593256, 75.563084],
              [113.560815, 75.535892],
              [113.524438, 75.539019],
              [113.434407, 75.535045],
              [113.400685, 75.552755],
              [113.487141, 75.570027],
              [113.581424, 75.599372],
              [113.617101, 75.622064],
              [113.612863, 75.639503],
              [113.552329, 75.657691],
              [113.316965, 75.681021],
              [113.100719, 75.678061],
              [113.16284, 75.70338],
              [113.093373, 75.709749],
              [113.081271, 75.729228],
              [112.979574, 75.738066],
              [112.883474, 75.757362],
              [112.768402, 75.795382],
              [112.659182, 75.823746],
              [112.570372, 75.830684],
              [112.515026, 75.841432],
              [112.417516, 75.839961],
              [112.413208, 75.831603],
              [112.483718, 75.821693],
              [112.503645, 75.800482],
              [112.493242, 75.779447],
              [112.580847, 75.770765],
              [112.66262, 75.770989],
              [112.734422, 75.754513],
              [112.798574, 75.749335],
              [112.845861, 75.737226],
              [112.886602, 75.713215],
              [112.884286, 75.69552],
              [112.852128, 75.684223],
              [112.791363, 75.685451],
              [112.768001, 75.678748],
              [112.837124, 75.671841],
              [112.802768, 75.648509],
              [112.884765, 75.634977],
              [112.873977, 75.61499],
              [112.802707, 75.60087],
              [112.778672, 75.57514],
              [112.796345, 75.550093],
              [112.894922, 75.536764],
              [112.973752, 75.541447],
              [113.02556, 75.550378],
              [113.036596, 75.569583],
              [112.980992, 75.585125],
              [112.996705, 75.610098],
              [113.031439, 75.614846],
              [113.256021, 75.659949],
              [113.322804, 75.647559],
              [113.332954, 75.619874],
              [113.299371, 75.611001],
              [113.358577, 75.595764],
              [113.303357, 75.594104],
              [113.294793, 75.567337],
              [113.343077, 75.545007],
              [113.3783, 75.519905],
              [113.540971, 75.501793],
              [113.640006, 75.498551],
              [113.703468, 75.504848],
              [113.737995, 75.483178],
              [113.756904, 75.44402],
              [113.740857, 75.408236],
              [113.701898, 75.415112],
              [113.634588, 75.41297],
              [113.532165, 75.390689],
              [113.542379, 75.381803],
              [113.583067, 75.388561],
              [113.692454, 75.386824],
              [113.706642, 75.374425],
              [113.673268, 75.299817],
              [113.579212, 75.243639],
              [113.510531, 75.209723],
              [113.294512, 75.141323],
              [113.171325, 75.094363],
              [113.098179, 75.059601],
              [112.992795, 75.064344],
              [112.9724, 75.057843],
              [113.055328, 75.048492],
              [113.052248, 75.042675],
              [112.93298, 75.020579],
              [112.930442, 75.008047],
              [112.883807, 74.989062],
              [112.839964, 74.961734],
              [112.754605, 74.938144],
              [112.625632, 74.923724],
              [112.575598, 74.912533],
              [112.551832, 74.918474],
              [112.42916, 74.901942],
              [112.309866, 74.89399],
              [112.244199, 74.882339],
              [112.204342, 74.881426],
              [112.132822, 74.868519],
              [112.14432, 74.854012],
              [112.039426, 74.781456],
              [111.963982, 74.752954],
              [111.983238, 74.743615],
              [111.951002, 74.726106],
              [111.843684, 74.688283],
              [111.802342, 74.649194],
              [111.768416, 74.660505],
              [111.677874, 74.678131],
              [111.601846, 74.686811],
              [111.510065, 74.688214],
              [111.44788, 74.683592],
              [111.363147, 74.668811],
              [111.25582, 74.631013],
              [111.195415, 74.617807],
              [111.158784, 74.588716],
              [111.040783, 74.552746],
              [111.012765, 74.53862],
              [110.912167, 74.541064],
              [110.819223, 74.526455],
              [110.727558, 74.506904],
              [110.563411, 74.483639],
              [110.509404, 74.486784],
              [110.455535, 74.497349],
              [110.399987, 74.500376],
              [110.405834, 74.468309],
              [110.428811, 74.458514],
              [110.403428, 74.426057],
              [110.253945, 74.388132],
              [110.22786, 74.373883],
              [110.108586, 74.384629],
              [109.983233, 74.358027],
              [109.902107, 74.325768],
              [109.947772, 74.310906],
              [109.951561, 74.258669],
              [109.929219, 74.221119],
              [109.876055, 74.186951],
              [109.789299, 74.15736],
              [109.663014, 74.132436],
              [109.609967, 74.107183],
              [109.530755, 74.077859],
              [109.439056, 74.07694],
              [109.328433, 74.067911],
              [109.250183, 74.06818],
              [109.172912, 74.060002],
              [109.036055, 74.027522],
              [108.911288, 73.984534],
              [108.866125, 73.964888],
              [108.772151, 73.934491],
              [108.656051, 73.869854],
              [108.6191, 73.842583],
              [108.483216, 73.765207],
              [108.458362, 73.763739],
              [108.369232, 73.724205],
              [108.290974, 73.707212],
              [108.225354, 73.671003],
              [108.173028, 73.659375],
              [108.107245, 73.667877],
              [108.045534, 73.668736],
              [107.992941, 73.659503],
              [107.930238, 73.638392],
              [107.784824, 73.615038],
              [107.735782, 73.62827],
              [107.595805, 73.628949],
              [107.512562, 73.617937],
              [107.391429, 73.613461],
              [107.344138, 73.605554],
              [107.317794, 73.585137],
              [107.255189, 73.569966],
              [107.251616, 73.556711],
              [107.210404, 73.552162],
              [107.205321, 73.533938],
              [107.044488, 73.486743],
              [107.015564, 73.469722],
              [106.95234, 73.458223],
              [106.897593, 73.425881],
              [106.853615, 73.385081],
              [106.863346, 73.36651],
              [106.854832, 73.345186],
              [106.796738, 73.318534],
              [106.635234, 73.295202],
              [106.559161, 73.30882],
              [106.505593, 73.306403],
              [106.407574, 73.314628],
              [106.282585, 73.320418],
              [106.240073, 73.310839],
              [106.204274, 73.288563],
              [106.12769, 73.271872],
              [106.098585, 73.25075],
              [106.075188, 73.191152],
              [106.043535, 73.178794],
              [106.063255, 73.140622],
              [106.008286, 73.134126],
              [105.972747, 73.120372],
              [105.906538, 73.107132],
              [105.896849, 73.038443],
              [105.923208, 73.007153],
              [105.88999, 72.98967],
              [105.8113, 72.968024],
              [105.796456, 72.94828],
              [105.864818, 72.939443],
              [105.843754, 72.890473],
              [105.814194, 72.879989],
              [105.766381, 72.87762],
              [105.735775, 72.902901],
              [105.724082, 72.882502],
              [105.645884, 72.879315],
              [105.615848, 72.863696],
              [105.559247, 72.855172],
              [105.518824, 72.835478],
              [105.395096, 72.816212],
              [105.403006, 72.802911],
              [105.468774, 72.807879],
              [105.483884, 72.791747],
              [105.378446, 72.785201],
              [105.350099, 72.791512],
              [105.285339, 72.789697],
              [105.22822, 72.777123],
              [105.150228, 72.783583],
              [105.161967, 72.793538],
              [105.086064, 72.805209],
              [105.016089, 72.790663],
              [104.97382, 72.77597],
              [104.87387, 72.76469],
              [104.83088, 72.75444],
              [104.77754, 72.74979],
              [104.74162, 72.73276],
              [104.69405, 72.72286],
              [104.73066, 72.71805],
              [104.73573, 72.69481],
              [104.66518, 72.68065],
              [104.64879, 72.66376],
              [104.541733, 72.630494],
              [104.476314, 72.621881],
              [104.423956, 72.608825],
              [104.757937, 72.600252],
              [104.81839, 72.62796],
              [104.96797, 72.67492],
              [104.99369, 72.67033],
              [105.049425, 72.675049],
              [105.072632, 72.688838],
              [104.96452, 72.68502],
              [105.059858, 72.717968],
              [105.141174, 72.675261],
              [105.195763, 72.688245],
              [105.201824, 72.730907],
              [105.212212, 72.736517],
              [105.149433, 72.74015],
              [105.121519, 72.748344],
              [105.153029, 72.758032],
              [105.252178, 72.763808],
              [105.271849, 72.754074],
              [105.451538, 72.741616],
              [105.535997, 72.745371],
              [105.620294, 72.768125],
              [105.701618, 72.797498],
              [105.759473, 72.807765],
              [105.789742, 72.837544],
              [105.889718, 72.863831],
              [105.922185, 72.854872],
              [105.977157, 72.869946],
              [105.976692, 72.885024],
              [106.02071, 72.925947],
              [106.058745, 72.936485],
              [106.099508, 72.934225],
              [106.158783, 72.907652],
              [106.283638, 72.893541],
              [106.308695, 72.903604],
              [106.341374, 72.887803],
              [106.36847, 72.901422],
              [106.298602, 72.919895],
              [106.23691, 72.920534],
              [106.180588, 72.927899],
              [106.169601, 72.937321],
              [106.342113, 72.931752],
              [106.36743, 72.921617],
              [106.499398, 72.898583],
              [106.5204, 72.905456],
              [106.496781, 72.91892],
              [106.448263, 72.921059],
              [106.398561, 72.93035],
              [106.403521, 72.937983],
              [106.315035, 72.953649],
              [106.261255, 72.956926],
              [106.238733, 72.94646],
              [106.223179, 72.979517],
              [106.24072, 73.005476],
              [106.214584, 73.042344],
              [106.21474, 73.095064],
              [106.229186, 73.108781],
              [106.21819, 73.127499],
              [106.314084, 73.138921],
              [106.34679, 73.185984],
              [106.431984, 73.180557],
              [106.567139, 73.162198],
              [106.688208, 73.137731],
              [106.804481, 73.12959],
              [106.894384, 73.1307],
              [106.936583, 73.138104],
              [107.018607, 73.161856],
              [107.14679, 73.164269],
              [107.316737, 73.148755],
              [107.383586, 73.147737],
              [107.482975, 73.158582],
              [107.589737, 73.152923],
              [107.800937, 73.155661],
              [107.848754, 73.164393],
              [107.96293, 73.209151],
              [108.003756, 73.221483],
              [108.099173, 73.235039],
              [108.196995, 73.232018],
              [108.243051, 73.213011],
              [108.282043, 73.208558],
              [108.355484, 73.213008],
              [108.403738, 73.224245],
              [108.407339, 73.238002],
              [108.368943, 73.257051],
              [108.296535, 73.25668],
              [108.259168, 73.265585],
              [108.20085, 73.25997],
              [108.239724, 73.284948],
              [108.274121, 73.292851],
              [108.442459, 73.298444],
              [108.659796, 73.316098],
              [108.824553, 73.339752],
              [108.926617, 73.33734],
              [109.045551, 73.364886],
              [109.096413, 73.364533],
              [109.163242, 73.371018],
              [109.205248, 73.381825],
              [109.301519, 73.391001],
              [109.287197, 73.408837],
              [109.230792, 73.427143],
              [109.21757, 73.460698],
              [109.254494, 73.47173],
              [109.334347, 73.442692],
              [109.404568, 73.429445],
              [109.433777, 73.410643],
              [109.458742, 73.432394],
              [109.394511, 73.456547],
              [109.362482, 73.484107],
              [109.297161, 73.498131],
              [109.209433, 73.489539],
              [109.191347, 73.514738],
              [109.201118, 73.54322],
              [109.241651, 73.553545],
              [109.273339, 73.535876],
              [109.45026, 73.466895],
              [109.519425, 73.452991],
              [109.598923, 73.447802],
              [109.61118, 73.439974],
              [109.66395, 73.435859],
              [109.713632, 73.440125],
              [109.768412, 73.43363],
              [109.915368, 73.454187],
              [110.012311, 73.49315],
              [110.084946, 73.488838],
              [110.132351, 73.501012],
              [110.142149, 73.531789],
              [110.166715, 73.538235],
              [110.12635, 73.545248],
              [110.087794, 73.532856],
              [110.102875, 73.557354],
              [110.144377, 73.565827],
              [110.158572, 73.558942],
              [110.225172, 73.557684],
              [110.300128, 73.568628],
              [110.351211, 73.584002],
              [110.386857, 73.587709],
              [110.36675, 73.612838],
              [110.416633, 73.61823],
              [110.473811, 73.637527],
              [110.596204, 73.630728],
              [110.654744, 73.653281],
              [110.795432, 73.657038],
              [110.837242, 73.67372],
              [110.896765, 73.678228],
              [110.914279, 73.698611],
            ],
          ],
          [
            [
              [94.42559, 76.64503],
              [94.42525, 76.63123],
              [94.55065, 76.61696],
              [94.59488, 76.62716],
              [94.71961, 76.64423],
              [94.7799, 76.64023],
              [94.71002, 76.62079],
              [94.66717, 76.62235],
              [94.60968, 76.61225],
              [94.58918, 76.61622],
              [94.4742, 76.60387],
              [94.4195, 76.61313],
              [94.32564, 76.61772],
              [94.37471, 76.64479],
              [94.42559, 76.64503],
            ],
          ],
          [
            [
              [94.40698, 76.53988],
              [94.34517, 76.53612],
              [94.30868, 76.55599],
              [94.19175, 76.55018],
              [94.141002, 76.542952],
              [94.07973, 76.56371],
              [93.97274, 76.55801],
              [93.93851, 76.57142],
              [93.94797, 76.58109],
              [94.00443, 76.58573],
              [94.1343, 76.57241],
              [94.31309, 76.56759],
              [94.4353, 76.5484],
              [94.40698, 76.53988],
            ],
          ],
          [
            [
              [95.50419, 76.56871],
              [95.52106, 76.57672],
              [95.57446, 76.56801],
              [95.53895, 76.56044],
              [95.50419, 76.56871],
            ],
          ],
          [
            [
              [95.01558, 76.56335],
              [95.04682, 76.57742],
              [95.09675, 76.57292],
              [95.01558, 76.56335],
            ],
          ],
          [
            [
              [96.20633, 76.5856],
              [96.16241, 76.59817],
              [96.07505, 76.5972],
              [96.01948, 76.60852],
              [95.903212, 76.574514],
              [95.888599, 76.591835],
              [95.954796, 76.609052],
              [95.897754, 76.618101],
              [95.896346, 76.628528],
              [95.9669, 76.64247],
              [96.068879, 76.643991],
              [96.069936, 76.630319],
              [96.110428, 76.631215],
              [96.16512, 76.660803],
              [96.155569, 76.674046],
              [96.19236, 76.686258],
              [96.25741, 76.67728],
              [96.35465, 76.67116],
              [96.42332, 76.68394],
              [96.46481, 76.6725],
              [96.41927, 76.6547],
              [96.37444, 76.64814],
              [96.33003, 76.65217],
              [96.27987, 76.63709],
              [96.29778, 76.61973],
              [96.33502, 76.61387],
              [96.26968, 76.59707],
              [96.23129, 76.57697],
              [96.25965, 76.55269],
              [96.16297, 76.53397],
              [96.15502, 76.56144],
              [96.21311, 76.5699],
              [96.20633, 76.5856],
            ],
          ],
          [
            [
              [95.625, 76.62206],
              [95.59818, 76.61761],
              [95.46576, 76.62711],
              [95.48003, 76.63873],
              [95.42576, 76.65996],
              [95.4718, 76.67563],
              [95.58491, 76.67859],
              [95.55293, 76.66484],
              [95.61491, 76.65297],
              [95.64326, 76.63939],
              [95.68651, 76.64558],
              [95.69848, 76.67243],
              [95.74933, 76.66626],
              [95.85025, 76.66656],
              [95.8412, 76.64454],
              [95.77057, 76.63727],
              [95.72653, 76.62445],
              [95.625, 76.62206],
            ],
          ],
          [
            [
              [95.290695, 76.971926],
              [95.54606, 77.03557],
              [95.57721, 77.03757],
              [95.70497, 77.06057],
              [95.7838, 77.06089],
              [96.01338, 77.08648],
              [96.17558, 77.11385],
              [96.41694, 77.17325],
              [96.48947, 77.17742],
              [96.49719, 77.16324],
              [96.57745, 77.1243],
              [96.53524, 77.09341],
              [96.4629, 77.06009],
              [96.354519, 77.026656],
              [96.34274, 77.00512],
              [96.27623, 77.0029],
              [96.23386, 76.97376],
              [96.14269, 76.98152],
              [96.11531, 76.99054],
              [96.06421, 76.97095],
              [96.07536, 76.95547],
              [96.019, 76.95664],
              [95.94375, 76.94421],
              [95.88535, 76.94103],
              [95.95468, 76.92977],
              [95.93535, 76.91132],
              [95.82506, 76.90218],
              [95.81573, 76.91674],
              [95.78348, 76.91913],
              [95.7161, 76.90987],
              [95.7183, 76.94002],
              [95.792, 76.94358],
              [95.80375, 76.96442],
              [95.84902, 76.97211],
              [95.92637, 76.96806],
              [95.96154, 76.98339],
              [96.02398, 76.99341],
              [95.96121, 77.0073],
              [95.7569, 76.98703],
              [95.67507, 76.97523],
              [95.67339, 76.96694],
              [95.5175, 76.98139],
              [95.47094, 76.97507],
              [95.39264, 76.97465],
              [95.29318, 76.96328],
              [95.290695, 76.971926],
            ],
          ],
          [
            [
              [100.16243, 77.14679],
              [100.10955, 77.1526],
              [100.16887, 77.15742],
              [100.16243, 77.14679],
            ],
          ],
          [
            [
              [102.15815, 77.374391],
              [102.254727, 77.375539],
              [102.208379, 77.36001],
              [102.163173, 77.36081],
              [102.091938, 77.349189],
              [102.073216, 77.359086],
              [102.15815, 77.374391],
            ],
          ],
          [
            [
              [107.699271, 77.267646],
              [107.731241, 77.26405],
              [107.690733, 77.253214],
              [107.583047, 77.246046],
              [107.550828, 77.231514],
              [107.493874, 77.229481],
              [107.464566, 77.213711],
              [107.365443, 77.210144],
              [107.34977, 77.219261],
              [107.279913, 77.210987],
              [107.220131, 77.216636],
              [107.349434, 77.228273],
              [107.366451, 77.241136],
              [107.415038, 77.227382],
              [107.434517, 77.242174],
              [107.487918, 77.2569],
              [107.457915, 77.267662],
              [107.403954, 77.262875],
              [107.378206, 77.275851],
              [107.29984, 77.269241],
              [107.318872, 77.301175],
              [107.377594, 77.308276],
              [107.383166, 77.329368],
              [107.427609, 77.339496],
              [107.5669, 77.320796],
              [107.580376, 77.311379],
              [107.665311, 77.314552],
              [107.69589, 77.332692],
              [107.74503, 77.321978],
              [107.752843, 77.298816],
              [107.669893, 77.280875],
              [107.699271, 77.267646],
            ],
          ],
          [
            [
              [107.604215, 77.340106],
              [107.632076, 77.357843],
              [107.6708, 77.347749],
              [107.604215, 77.340106],
            ],
          ],
          [
            [
              [106.875, 77.410014],
              [106.814635, 77.413871],
              [106.770979, 77.403249],
              [106.768573, 77.377416],
              [106.69587, 77.364094],
              [106.619301, 77.375275],
              [106.531645, 77.370277],
              [106.561207, 77.387271],
              [106.615519, 77.395788],
              [106.614832, 77.418234],
              [106.706613, 77.427179],
              [106.702488, 77.438547],
              [106.76209, 77.459067],
              [106.825588, 77.463161],
              [106.835862, 77.449962],
              [106.890088, 77.440697],
              [106.93701, 77.453136],
              [106.947838, 77.438361],
              [106.875, 77.410014],
            ],
          ],
          [
            [
              [97.50747, 76.83726],
              [97.47328, 76.84093],
              [97.40594, 76.82596],
              [97.33959, 76.82222],
              [97.30934, 76.83117],
              [97.34147, 76.84881],
              [97.37963, 76.8451],
              [97.45076, 76.85604],
              [97.50747, 76.83726],
            ],
          ],
          [
            [
              [95.8138, 76.87022],
              [95.867512, 76.867422],
              [95.8604, 76.85667],
              [95.75246, 76.84557],
              [95.70297, 76.85633],
              [95.74949, 76.86938],
              [95.8138, 76.87022],
            ],
          ],
          [
            [
              [96.903701, 76.840396],
              [96.87079, 76.83395],
              [96.84711, 76.85256],
              [96.77127, 76.84882],
              [96.73905, 76.85824],
              [96.77941, 76.86968],
              [96.80415, 76.86164],
              [96.90717, 76.85985],
              [96.903701, 76.840396],
            ],
          ],
          [
            [
              [96.26689, 76.87308],
              [96.1964, 76.86552],
              [96.10358, 76.86307],
              [96.02569, 76.8668],
              [95.96205, 76.88928],
              [95.85484, 76.88333],
              [95.92433, 76.89713],
              [95.96974, 76.89341],
              [95.99427, 76.90732],
              [96.09929, 76.90762],
              [96.15804, 76.9174],
              [96.21216, 76.91699],
              [96.32995, 76.90476],
              [96.36488, 76.88666],
              [96.26689, 76.87308],
            ],
          ],
          [
            [
              [94.42559, 76.64503],
              [94.46169, 76.65172],
              [94.40921, 76.66836],
              [94.4532, 76.68613],
              [94.5566, 76.67875],
              [94.511, 76.66242],
              [94.52162, 76.65341],
              [94.42559, 76.64503],
            ],
          ],
          [
            [
              [94.83208, 76.63374],
              [94.96321, 76.63889],
              [95.03463, 76.65827],
              [95.12041, 76.65092],
              [95.10714, 76.66177],
              [95.14641, 76.6755],
              [95.15181, 76.69206],
              [95.228391, 76.666349],
              [95.24557, 76.64167],
              [95.29229, 76.63678],
              [95.35147, 76.64021],
              [95.41313, 76.63247],
              [95.32544, 76.6108],
              [95.22765, 76.60656],
              [95.09669, 76.6156],
              [94.95957, 76.61362],
              [94.87508, 76.60097],
              [94.82479, 76.61233],
              [94.83208, 76.63374],
            ],
          ],
          [
            [
              [97.47396, 76.62605],
              [97.4839, 76.61256],
              [97.54601, 76.59327],
              [97.635, 76.59191],
              [97.58266, 76.57417],
              [97.4929, 76.59293],
              [97.37106, 76.59174],
              [97.32636, 76.58303],
              [97.34321, 76.60476],
              [97.40304, 76.60527],
              [97.42748, 76.61585],
              [97.41753, 76.63293],
              [97.46248, 76.64674],
              [97.42945, 76.66268],
              [97.36336, 76.66075],
              [97.30961, 76.67319],
              [97.31325, 76.68286],
              [97.36759, 76.68424],
              [97.44419, 76.69751],
              [97.38595, 76.71004],
              [97.44886, 76.7151],
              [97.44378, 76.74028],
              [97.39624, 76.73483],
              [97.20126, 76.73554],
              [97.16085, 76.74555],
              [97.19519, 76.75549],
              [97.23455, 76.74921],
              [97.27062, 76.7573],
              [97.38291, 76.76246],
              [97.44889, 76.75915],
              [97.59207, 76.77583],
              [97.64904, 76.77224],
              [97.64942, 76.74585],
              [97.62314, 76.73275],
              [97.58866, 76.74618],
              [97.55118, 76.74762],
              [97.50593, 76.73587],
              [97.46116, 76.70848],
              [97.45481, 76.66558],
              [97.49126, 76.65161],
              [97.53323, 76.65445],
              [97.555, 76.64331],
              [97.4995, 76.64634],
              [97.46241, 76.6347],
              [97.47396, 76.62605],
            ],
          ],
          [
            [
              [94.73815, 76.69419],
              [94.799279, 76.700645],
              [94.834645, 76.695049],
              [94.79128, 76.68053],
              [94.71345, 76.68311],
              [94.73815, 76.69419],
            ],
          ],
          [
            [
              [96.0689, 76.685119],
              [96.044, 76.6656],
              [95.90194, 76.66471],
              [95.95313, 76.6861],
              [95.97919, 76.71878],
              [96.08889, 76.707],
              [96.0689, 76.685119],
            ],
          ],
          [
            [
              [95.81738, 76.70042],
              [95.84121, 76.69223],
              [95.82773, 76.6755],
              [95.78809, 76.6877],
              [95.70306, 76.6899],
              [95.67038, 76.7043],
              [95.69697, 76.72063],
              [95.83975, 76.72497],
              [95.81738, 76.70042],
            ],
          ],
          [
            [
              [94.55681, 76.69025],
              [94.48403, 76.69425],
              [94.41322, 76.72812],
              [94.43209, 76.73824],
              [94.4945, 76.71873],
              [94.556551, 76.711841],
              [94.55681, 76.69025],
            ],
          ],
          [
            [
              [98.00356, 76.71432],
              [98.05582, 76.72585],
              [98.06177, 76.70753],
              [98.00356, 76.71432],
            ],
          ],
          [
            [
              [95.47199, 76.69492],
              [95.447892, 76.684214],
              [95.411479, 76.708058],
              [95.51754, 76.70099],
              [95.47199, 76.69492],
            ],
          ],
          [
            [
              [94.80832, 76.73766],
              [94.91692, 76.73405],
              [94.82953, 76.72166],
              [94.80832, 76.73766],
            ],
          ],
          [
            [
              [110.721721, 76.769263],
              [110.74082, 76.779867],
              [110.79836, 76.78845],
              [110.837239, 76.785767],
              [110.820684, 76.775244],
              [110.721721, 76.769263],
            ],
          ],
          [
            [
              [97.50747, 76.83726],
              [97.55779, 76.84636],
              [97.59532, 76.86388],
              [97.62047, 76.85191],
              [97.60507, 76.82683],
              [97.67605, 76.81454],
              [97.73445, 76.8273],
              [97.81017, 76.82846],
              [97.85764, 76.8168],
              [97.92375, 76.82862],
              [97.92873, 76.81161],
              [97.88878, 76.79443],
              [97.93944, 76.79193],
              [97.942109, 76.782879],
              [97.88029, 76.74642],
              [97.85885, 76.77989],
              [97.82856, 76.79224],
              [97.78462, 76.79472],
              [97.70622, 76.81091],
              [97.64378, 76.80564],
              [97.61043, 76.81956],
              [97.5104, 76.82927],
              [97.50747, 76.83726],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5520",
      properties: {
        name: "Курганская область",
        density: 0,
        path: "/world/Russia/Курганская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [62.061058, 56.130821],
            [62.078054, 56.198672],
            [62.066803, 56.203021],
            [62.077829, 56.218268],
            [62.076479, 56.233558],
            [62.053632, 56.245319],
            [62.061513, 56.28285],
            [62.07111, 56.297018],
            [62.11954, 56.286588],
            [62.15402, 56.274073],
            [62.171373, 56.285933],
            [62.17877, 56.303582],
            [62.196859, 56.304905],
            [62.212465, 56.319319],
            [62.267765, 56.339687],
            [62.256548, 56.34077],
            [62.254465, 56.399082],
            [62.271055, 56.419793],
            [62.309866, 56.431601],
            [62.336378, 56.467094],
            [62.369919, 56.457488],
            [62.378312, 56.487955],
            [62.401213, 56.487838],
            [62.399577, 56.508835],
            [62.435904, 56.520722],
            [62.428448, 56.526957],
            [62.518812, 56.563641],
            [62.569212, 56.565813],
            [62.604782, 56.548317],
            [62.583358, 56.532031],
            [62.602932, 56.490658],
            [62.623809, 56.496864],
            [62.651286, 56.48652],
            [62.653279, 56.514784],
            [62.688123, 56.502965],
            [62.724762, 56.507773],
            [62.742916, 56.558361],
            [62.83173, 56.548307],
            [62.871181, 56.546933],
            [62.890995, 56.590835],
            [62.922006, 56.592433],
            [62.976832, 56.586357],
            [62.985872, 56.597938],
            [63.124405, 56.564124],
            [63.145443, 56.585314],
            [63.154567, 56.566747],
            [63.18414, 56.586739],
            [63.201517, 56.58195],
            [63.189185, 56.568746],
            [63.231373, 56.560724],
            [63.236565, 56.570337],
            [63.262299, 56.564874],
            [63.290639, 56.57034],
            [63.31507, 56.567783],
            [63.311569, 56.54951],
            [63.318129, 56.524622],
            [63.360522, 56.512345],
            [63.358256, 56.49102],
            [63.377526, 56.497103],
            [63.368715, 56.549709],
            [63.398544, 56.581199],
            [63.400279, 56.610414],
            [63.417439, 56.610044],
            [63.430653, 56.598565],
            [63.469777, 56.59504],
            [63.473944, 56.611631],
            [63.520765, 56.609643],
            [63.560809, 56.59973],
            [63.568155, 56.591747],
            [63.599114, 56.587807],
            [63.595406, 56.558355],
            [63.686506, 56.533072],
            [63.701056, 56.524505],
            [63.725886, 56.538971],
            [63.74679, 56.537487],
            [63.742126, 56.514347],
            [63.773648, 56.510216],
            [63.829419, 56.481103],
            [63.890534, 56.482327],
            [63.915512, 56.474196],
            [63.985993, 56.475053],
            [63.996761, 56.468701],
            [64.019699, 56.489201],
            [64.05516, 56.487172],
            [64.041584, 56.476578],
            [64.061912, 56.472767],
            [64.178836, 56.460685],
            [64.214483, 56.491548],
            [64.221468, 56.537953],
            [64.168653, 56.559312],
            [64.183935, 56.58758],
            [64.211091, 56.597568],
            [64.287097, 56.605852],
            [64.31121, 56.619347],
            [64.298524, 56.630755],
            [64.2981, 56.646379],
            [64.343056, 56.644276],
            [64.350427, 56.68256],
            [64.295714, 56.699422],
            [64.321689, 56.715784],
            [64.291601, 56.728016],
            [64.292639, 56.737618],
            [64.327899, 56.756542],
            [64.493862, 56.745584],
            [64.517225, 56.761477],
            [64.545599, 56.788758],
            [64.549117, 56.803542],
            [64.594202, 56.799497],
            [64.606224, 56.793384],
            [64.633952, 56.803772],
            [64.680239, 56.813917],
            [64.73172, 56.815342],
            [64.764311, 56.812473],
            [64.789286, 56.824958],
            [64.813299, 56.822364],
            [64.830508, 56.842297],
            [64.886807, 56.824077],
            [64.883325, 56.807528],
            [64.865125, 56.793932],
            [64.912255, 56.78772],
            [64.927055, 56.7724],
            [64.94638, 56.761552],
            [64.945, 56.736348],
            [64.991291, 56.717528],
            [64.978742, 56.686504],
            [65.000835, 56.68159],
            [64.991497, 56.66181],
            [64.972266, 56.643221],
            [64.979605, 56.636822],
            [65.028727, 56.641531],
            [65.034667, 56.614714],
            [65.013137, 56.621989],
            [65.004166, 56.610595],
            [64.985705, 56.609939],
            [65.003239, 56.598938],
            [65.012426, 56.576128],
            [64.998529, 56.571001],
            [64.99417, 56.547821],
            [65.015652, 56.541928],
            [64.989483, 56.518366],
            [64.996718, 56.504587],
            [65.031883, 56.466854],
            [65.093433, 56.441139],
            [65.083579, 56.434922],
            [65.105062, 56.40784],
            [65.12467, 56.410616],
            [65.136775, 56.372141],
            [65.160137, 56.364429],
            [65.213432, 56.364235],
            [65.219061, 56.344992],
            [65.207091, 56.338509],
            [65.125872, 56.345926],
            [65.094151, 56.334932],
            [65.122002, 56.307573],
            [65.180078, 56.28877],
            [65.207996, 56.288089],
            [65.234286, 56.276729],
            [65.35355, 56.285693],
            [65.454226, 56.285428],
            [65.683123, 56.269664],
            [65.673665, 56.213742],
            [65.689082, 56.214087],
            [65.688219, 56.19797],
            [65.707607, 56.189252],
            [65.73308, 56.188621],
            [65.778002, 56.157672],
            [65.79186, 56.137115],
            [65.777827, 56.131316],
            [65.84187, 56.068988],
            [65.903161, 56.040181],
            [65.996445, 56.001231],
            [66.020971, 55.993602],
            [66.071999, 56.011522],
            [66.122045, 56.036595],
            [66.161296, 56.068576],
            [66.258997, 56.062678],
            [66.292425, 56.057455],
            [66.30855, 56.049543],
            [66.35573, 56.013219],
            [66.391701, 56.000042],
            [66.422473, 55.993962],
            [66.451777, 55.959582],
            [66.473329, 55.956088],
            [66.4811, 55.966471],
            [66.518225, 55.973076],
            [66.586198, 55.998765],
            [66.635863, 56.006067],
            [66.669975, 56.040107],
            [66.723401, 56.042495],
            [66.722086, 56.026798],
            [66.763542, 56.009534],
            [66.76387, 55.975632],
            [66.924861, 55.973432],
            [66.92495, 55.992638],
            [66.989894, 55.992353],
            [66.983715, 55.971465],
            [67.099064, 55.969277],
            [67.099887, 55.958139],
            [67.184004, 55.962381],
            [67.303046, 55.9629],
            [67.278328, 55.938899],
            [67.283794, 55.914767],
            [67.299739, 55.888765],
            [67.333167, 55.869666],
            [67.318994, 55.855776],
            [67.340969, 55.82508],
            [67.40392, 55.727855],
            [67.449763, 55.735538],
            [67.462485, 55.718991],
            [67.554481, 55.71839],
            [67.565377, 55.710337],
            [67.574727, 55.68669],
            [67.591939, 55.693749],
            [67.614331, 55.721569],
            [67.633784, 55.714146],
            [67.719124, 55.714405],
            [67.731729, 55.70951],
            [67.74655, 55.719513],
            [67.79429, 55.728749],
            [67.822177, 55.729012],
            [67.848303, 55.714853],
            [67.844043, 55.6854],
            [67.853535, 55.678494],
            [67.894692, 55.673417],
            [67.920944, 55.654964],
            [67.972331, 55.664005],
            [68.011171, 55.693712],
            [68.041776, 55.696473],
            [68.09223, 55.710829],
            [68.09471, 55.71917],
            [68.138859, 55.728834],
            [68.177142, 55.72036],
            [68.174467, 55.684289],
            [68.192357, 55.676914],
            [68.179072, 55.657672],
            [68.17737, 55.618867],
            [68.188755, 55.589124],
            [68.230727, 55.575236],
            [68.292587, 55.576648],
            [68.317003, 55.5694],
            [68.313915, 55.531461],
            [68.321853, 55.507337],
            [68.372716, 55.489629],
            [68.432854, 55.476641],
            [68.447332, 55.448561],
            [68.4739, 55.433147],
            [68.486273, 55.417287],
            [68.555691, 55.430851],
            [68.599243, 55.430458],
            [68.601612, 55.410634],
            [68.682148, 55.387897],
            [68.674592, 55.360946],
            [68.714266, 55.347519],
            [68.72265, 55.325502],
            [68.702914, 55.32562],
            [68.71025, 55.307406],
            [68.696868, 55.288835],
            [68.674736, 55.277193],
            [68.635512, 55.265321],
            [68.636801, 55.242186],
            [68.659446, 55.235801],
            [68.645385, 55.217463],
            [68.649153, 55.208548],
            [68.620187, 55.198268],
            [68.600245, 55.200873],
            [68.595327, 55.219439],
            [68.557073, 55.215281],
            [68.559086, 55.209131],
            [68.445188, 55.196533],
            [68.358804, 55.206463],
            [68.353888, 55.181],
            [68.318374, 55.186143],
            [68.30459, 55.203481],
            [68.280743, 55.204403],
            [68.239986, 55.18715],
            [68.17743, 55.198887],
            [68.177497, 55.177666],
            [68.210182, 55.177704],
            [68.21918, 55.154838],
            [68.190745, 55.151776],
            [68.240705, 55.112678],
            [68.258907, 55.113593],
            [68.25673, 55.100585],
            [68.272202, 55.084814],
            [68.315549, 55.076692],
            [68.312788, 55.067545],
            [68.237737, 55.053773],
            [68.238112, 55.014705],
            [68.196712, 54.990108],
            [68.235134, 54.969052],
            [68.032564, 54.950461],
            [67.910862, 54.983692],
            [67.819464, 54.971231],
            [67.801343, 54.94096],
            [67.798753, 54.925387],
            [67.773531, 54.885956],
            [67.698369, 54.870816],
            [67.691741, 54.863316],
            [67.643474, 54.865333],
            [67.563527, 54.844391],
            [67.517238, 54.861916],
            [67.475725, 54.858464],
            [67.461567, 54.864069],
            [67.398524, 54.855399],
            [67.353541, 54.864088],
            [67.333049, 54.873306],
            [67.29026, 54.862322],
            [67.282345, 54.825914],
            [67.221315, 54.814295],
            [67.152321, 54.809274],
            [67.1069, 54.8087],
            [67.0524, 54.7783],
            [66.9976, 54.7857],
            [66.8639, 54.7759],
            [66.792, 54.7649],
            [66.7313, 54.7386],
            [66.6973, 54.7362],
            [66.6488, 54.7415],
            [66.4089, 54.7052],
            [66.3107, 54.687],
            [66.1961, 54.6618],
            [66.139343, 54.654734],
            [65.9982, 54.625504],
            [65.963486, 54.638403],
            [65.981598, 54.717621],
            [65.849986, 54.698245],
            [65.835542, 54.655958],
            [65.806243, 54.626299],
            [65.786888, 54.625107],
            [65.784728, 54.614438],
            [65.747158, 54.607688],
            [65.631101, 54.641835],
            [65.550916, 54.637718],
            [65.529091, 54.649866],
            [65.489568, 54.656337],
            [65.464316, 54.642866],
            [65.455775, 54.62423],
            [65.49445, 54.596661],
            [65.501312, 54.571642],
            [65.466157, 54.567363],
            [65.425703, 54.581887],
            [65.378511, 54.574255],
            [65.357921, 54.578213],
            [65.304787, 54.569369],
            [65.262938, 54.547394],
            [65.238008, 54.549397],
            [65.213741, 54.541467],
            [65.2279, 54.5037],
            [65.217, 54.4751],
            [65.2058, 54.4663],
            [65.2079, 54.4483],
            [65.2213, 54.44],
            [65.2379, 54.3768],
            [65.2617, 54.3621],
            [65.2233, 54.3348],
            [65.1869, 54.3445],
            [65.1201, 54.3313],
            [65.1004, 54.3363],
            [65.0711, 54.3654],
            [65.0164, 54.4014],
            [64.9728, 54.4227],
            [64.9259, 54.409],
            [64.9023, 54.3969],
            [64.8727, 54.3911],
            [64.8024, 54.3679],
            [64.7403, 54.3544],
            [64.7038, 54.3648],
            [64.6699, 54.3617],
            [64.6441, 54.3711],
            [64.622, 54.3965],
            [64.581, 54.3719],
            [64.5713, 54.3601],
            [64.5422, 54.3634],
            [64.5234, 54.3538],
            [64.5008, 54.3659],
            [64.4604, 54.3563],
            [64.4707, 54.3428],
            [64.4565, 54.3312],
            [64.4209, 54.3255],
            [64.3577, 54.3351],
            [64.3191, 54.3301],
            [64.3069, 54.334],
            [64.276185, 54.329392],
            [64.235585, 54.317248],
            [64.2282, 54.3221],
            [64.1724, 54.3056],
            [64.1218, 54.3097],
            [64.1079, 54.3071],
            [64.0698, 54.3182],
            [64.0692, 54.2965],
            [64.0443, 54.2852],
            [64.0183, 54.2881],
            [64.0264, 54.3041],
            [63.9817, 54.2974],
            [63.9766, 54.2777],
            [64.0036, 54.2516],
            [64.0184, 54.2306],
            [64.0072, 54.2093],
            [63.9763, 54.1986],
            [63.9319, 54.2044],
            [63.9119, 54.2],
            [63.903, 54.2136],
            [63.8152, 54.2672],
            [63.7645, 54.2639],
            [63.6951, 54.2444],
            [63.6459, 54.2395],
            [63.629811, 54.22994],
            [63.588273, 54.219644],
            [63.5121, 54.2051],
            [63.4983, 54.2122],
            [63.456788, 54.194801],
            [63.4415, 54.2],
            [63.4062, 54.1983],
            [63.3694, 54.1858],
            [63.3541, 54.1904],
            [63.338985, 54.231347],
            [63.352015, 54.241017],
            [63.344, 54.251514],
            [63.309289, 54.261899],
            [63.274897, 54.237351],
            [63.251585, 54.285381],
            [63.263381, 54.293759],
            [63.215469, 54.294293],
            [63.213937, 54.31519],
            [63.171626, 54.308113],
            [63.155219, 54.320983],
            [63.127624, 54.314759],
            [63.117456, 54.328276],
            [63.097533, 54.333485],
            [63.165626, 54.335988],
            [63.154131, 54.35508],
            [63.142525, 54.355651],
            [63.125166, 54.380913],
            [63.138918, 54.403277],
            [63.148314, 54.396429],
            [63.181211, 54.394922],
            [63.181712, 54.431861],
            [63.144085, 54.438556],
            [63.12989, 54.468126],
            [63.153453, 54.493809],
            [63.168383, 54.490695],
            [63.167028, 54.472849],
            [63.195799, 54.465319],
            [63.218199, 54.471431],
            [63.207921, 54.483939],
            [63.234235, 54.487245],
            [63.244031, 54.52262],
            [63.280173, 54.529435],
            [63.294737, 54.543791],
            [63.272589, 54.547238],
            [63.2851, 54.581361],
            [63.243144, 54.581245],
            [63.226293, 54.609063],
            [63.237101, 54.646927],
            [63.199821, 54.699085],
            [63.110931, 54.697375],
            [63.061435, 54.700512],
            [63.014372, 54.692893],
            [62.978433, 54.70545],
            [62.900567, 54.692772],
            [62.86964, 54.675336],
            [62.827097, 54.661022],
            [62.749789, 54.646767],
            [62.698632, 54.62956],
            [62.671724, 54.624012],
            [62.659602, 54.663626],
            [62.631824, 54.670165],
            [62.621821, 54.635613],
            [62.600672, 54.634595],
            [62.550252, 54.652031],
            [62.549045, 54.677384],
            [62.524142, 54.678356],
            [62.519936, 54.688386],
            [62.537999, 54.70167],
            [62.578253, 54.714924],
            [62.550583, 54.729143],
            [62.513741, 54.718592],
            [62.441153, 54.715725],
            [62.405792, 54.722977],
            [62.395527, 54.685839],
            [62.319144, 54.670853],
            [62.276022, 54.669316],
            [62.239155, 54.672924],
            [62.237823, 54.69127],
            [62.202633, 54.68929],
            [62.188443, 54.676673],
            [62.155938, 54.679579],
            [62.155404, 54.668387],
            [62.118048, 54.668887],
            [62.077854, 54.675609],
            [62.084809, 54.719894],
            [62.054358, 54.727045],
            [62.016963, 54.723884],
            [62.037149, 54.764265],
            [62.074371, 54.787675],
            [62.111899, 54.798037],
            [62.118788, 54.814148],
            [62.108793, 54.839655],
            [62.073992, 54.867154],
            [62.054764, 54.900122],
            [62.035037, 54.907666],
            [62.010334, 54.906065],
            [62.000864, 54.931751],
            [62.007965, 54.943448],
            [61.98787, 54.970678],
            [61.995558, 54.980732],
            [61.966185, 55.030311],
            [62.014551, 55.030216],
            [62.046347, 55.021295],
            [62.069132, 55.021576],
            [62.081408, 55.032419],
            [62.147238, 55.03827],
            [62.193825, 54.99019],
            [62.209049, 55.007085],
            [62.223947, 55.065911],
            [62.25291, 55.064172],
            [62.25832, 55.073978],
            [62.28477, 55.078212],
            [62.263906, 55.084684],
            [62.283391, 55.107393],
            [62.274191, 55.113405],
            [62.237112, 55.108568],
            [62.224007, 55.150734],
            [62.208643, 55.150343],
            [62.201699, 55.170596],
            [62.225938, 55.203206],
            [62.174374, 55.202504],
            [62.160815, 55.231132],
            [62.071679, 55.259332],
            [62.043156, 55.260574],
            [62.066351, 55.303627],
            [62.103194, 55.297734],
            [62.106083, 55.304462],
            [62.161149, 55.312684],
            [62.171268, 55.348693],
            [62.244451, 55.337179],
            [62.241682, 55.359085],
            [62.180311, 55.397237],
            [62.166861, 55.409406],
            [62.18825, 55.445441],
            [62.20855, 55.446311],
            [62.263046, 55.438024],
            [62.288312, 55.427604],
            [62.299507, 55.403819],
            [62.319534, 55.413737],
            [62.37801, 55.417973],
            [62.383235, 55.427026],
            [62.452115, 55.4276],
            [62.47628, 55.433965],
            [62.454923, 55.467246],
            [62.499635, 55.472144],
            [62.541959, 55.483939],
            [62.585075, 55.48449],
            [62.62009, 55.490569],
            [62.602374, 55.513439],
            [62.597863, 55.531209],
            [62.55157, 55.567405],
            [62.534669, 55.574883],
            [62.468967, 55.563626],
            [62.472397, 55.585984],
            [62.523405, 55.590302],
            [62.524592, 55.625249],
            [62.546249, 55.624153],
            [62.554016, 55.646069],
            [62.551791, 55.662811],
            [62.536746, 55.664477],
            [62.517429, 55.679204],
            [62.543848, 55.709547],
            [62.515934, 55.72578],
            [62.525893, 55.769909],
            [62.498942, 55.780707],
            [62.469247, 55.800751],
            [62.420078, 55.806317],
            [62.440321, 55.827572],
            [62.41601, 55.834826],
            [62.390936, 55.813193],
            [62.360737, 55.805429],
            [62.304293, 55.837975],
            [62.29181, 55.866571],
            [62.310055, 55.86677],
            [62.313568, 55.900655],
            [62.344562, 55.901515],
            [62.369971, 55.923328],
            [62.364329, 55.933387],
            [62.31766, 55.947603],
            [62.238595, 55.933735],
            [62.230334, 55.939632],
            [62.218997, 55.996339],
            [62.230419, 56.027999],
            [62.124984, 56.081645],
            [62.113867, 56.090665],
            [62.151776, 56.111757],
            [62.148541, 56.116316],
            [62.061058, 56.130821],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5521",
      properties: {
        name: "Курская область",
        density: 0,
        path: "/world/Russia/Курская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.385548, 51.843075],
            [34.388351, 51.872482],
            [34.382268, 51.877913],
            [34.445474, 51.896855],
            [34.452758, 51.921475],
            [34.419782, 51.906808],
            [34.388149, 51.914708],
            [34.370357, 51.926092],
            [34.334116, 51.933741],
            [34.340515, 51.945419],
            [34.36413, 51.941633],
            [34.378722, 51.957818],
            [34.40323, 51.972957],
            [34.403542, 51.993775],
            [34.43281, 52.014802],
            [34.458378, 52.016917],
            [34.492933, 52.036825],
            [34.506587, 52.054927],
            [34.527333, 52.068489],
            [34.545471, 52.071284],
            [34.585187, 52.05188],
            [34.576697, 52.038692],
            [34.609376, 52.036475],
            [34.628166, 52.05351],
            [34.658035, 52.067867],
            [34.696157, 52.061123],
            [34.742774, 52.087892],
            [34.744338, 52.115486],
            [34.71914, 52.125356],
            [34.728076, 52.135428],
            [34.726352, 52.15202],
            [34.694376, 52.15911],
            [34.683128, 52.196703],
            [34.701853, 52.196579],
            [34.701666, 52.207839],
            [34.729317, 52.215304],
            [34.757, 52.212007],
            [34.772055, 52.200748],
            [34.761107, 52.188804],
            [34.807327, 52.186254],
            [34.869013, 52.202119],
            [34.884059, 52.229428],
            [34.903032, 52.245042],
            [34.881322, 52.244793],
            [34.859579, 52.261154],
            [34.887075, 52.266131],
            [34.887945, 52.278386],
            [34.918178, 52.291636],
            [34.917164, 52.301115],
            [34.94107, 52.313659],
            [34.936093, 52.323052],
            [34.960167, 52.342959],
            [34.950586, 52.347376],
            [34.921535, 52.339164],
            [34.911457, 52.347189],
            [34.924193, 52.369988],
            [34.96491, 52.366341],
            [34.956209, 52.354996],
            [34.980009, 52.355081],
            [34.998265, 52.347234],
            [35.030339, 52.349622],
            [35.028377, 52.333159],
            [35.038444, 52.31951],
            [35.066082, 52.321985],
            [35.083484, 52.334013],
            [35.127415, 52.334355],
            [35.157356, 52.340753],
            [35.156929, 52.351501],
            [35.193696, 52.347066],
            [35.204784, 52.379567],
            [35.233361, 52.374876],
            [35.282666, 52.389463],
            [35.329925, 52.3701],
            [35.337773, 52.374195],
            [35.40203, 52.374298],
            [35.41719, 52.381106],
            [35.410706, 52.40337],
            [35.431434, 52.426658],
            [35.455404, 52.430497],
            [35.47656, 52.419749],
            [35.532863, 52.426863],
            [35.541322, 52.44506],
            [35.606443, 52.437365],
            [35.595295, 52.384266],
            [35.615455, 52.379483],
            [35.633735, 52.36432],
            [35.629892, 52.356974],
            [35.66483, 52.33468],
            [35.67081, 52.319048],
            [35.64826, 52.288936],
            [35.642623, 52.27104],
            [35.645443, 52.250453],
            [35.667909, 52.248233],
            [35.712413, 52.254939],
            [35.776478, 52.286674],
            [35.814661, 52.301282],
            [35.82773, 52.297438],
            [35.86429, 52.300258],
            [35.884792, 52.294535],
            [35.897008, 52.282918],
            [35.951507, 52.278092],
            [35.972776, 52.295134],
            [36.002907, 52.303839],
            [36.003516, 52.310459],
            [36.052201, 52.317914],
            [36.10286, 52.319585],
            [36.129068, 52.31145],
            [36.15498, 52.330904],
            [36.17353, 52.335098],
            [36.189105, 52.328057],
            [36.213397, 52.343317],
            [36.221832, 52.357394],
            [36.261883, 52.367118],
            [36.298401, 52.381697],
            [36.322661, 52.381902],
            [36.331258, 52.368009],
            [36.357389, 52.373204],
            [36.41636, 52.345507],
            [36.440794, 52.322658],
            [36.444622, 52.31068],
            [36.47179, 52.275699],
            [36.495959, 52.258657],
            [36.486124, 52.251965],
            [36.496133, 52.232407],
            [36.524042, 52.21992],
            [36.564741, 52.212477],
            [36.574982, 52.220652],
            [36.599111, 52.212195],
            [36.608808, 52.216409],
            [36.640841, 52.207556],
            [36.64018, 52.184415],
            [36.660552, 52.175811],
            [36.687132, 52.180756],
            [36.734773, 52.171662],
            [36.751284, 52.174745],
            [36.772347, 52.161246],
            [36.777568, 52.137335],
            [36.792174, 52.127743],
            [36.837452, 52.118655],
            [36.848525, 52.111693],
            [36.88235, 52.115543],
            [36.94007, 52.101388],
            [36.935787, 52.095247],
            [36.9796, 52.091225],
            [36.99376, 52.107455],
            [37.033989, 52.11012],
            [37.027124, 52.088931],
            [37.042894, 52.07895],
            [37.074764, 52.07417],
            [37.094563, 52.094706],
            [37.115435, 52.079329],
            [37.152633, 52.075169],
            [37.189753, 52.087521],
            [37.207258, 52.082804],
            [37.195241, 52.055513],
            [37.177713, 52.041696],
            [37.238523, 52.015973],
            [37.262062, 52.015127],
            [37.282554, 52.001749],
            [37.293561, 52.013095],
            [37.329548, 52.008185],
            [37.345214, 51.979184],
            [37.387341, 51.947022],
            [37.424947, 51.934378],
            [37.483032, 51.962386],
            [37.507934, 52.016485],
            [37.622523, 52.0026],
            [37.640728, 51.986597],
            [37.710247, 51.970256],
            [37.722778, 51.97703],
            [37.717697, 51.996505],
            [37.738679, 52.004723],
            [37.738268, 52.01703],
            [37.782756, 52.004608],
            [37.794812, 51.981341],
            [37.821724, 51.975734],
            [37.831257, 51.944337],
            [37.855297, 51.923312],
            [37.854036, 51.908454],
            [37.867912, 51.900885],
            [37.897348, 51.901306],
            [37.900431, 51.91322],
            [37.918793, 51.913361],
            [37.922719, 51.891558],
            [37.993854, 51.894579],
            [38.051393, 51.88645],
            [38.047047, 51.90257],
            [38.083911, 51.904393],
            [38.121926, 51.91446],
            [38.111944, 51.923736],
            [38.127502, 51.93523],
            [38.137314, 51.929904],
            [38.171796, 51.931586],
            [38.18343, 51.918271],
            [38.19177, 51.930185],
            [38.24195, 51.922897],
            [38.245313, 51.940699],
            [38.280144, 51.96649],
            [38.292611, 51.96594],
            [38.316416, 51.957489],
            [38.318387, 51.919466],
            [38.340496, 51.919174],
            [38.341533, 51.938835],
            [38.376993, 51.935711],
            [38.429118, 51.94475],
            [38.448492, 51.921944],
            [38.42656, 51.917975],
            [38.416767, 51.906304],
            [38.434247, 51.905376],
            [38.442473, 51.89253],
            [38.417763, 51.871193],
            [38.422032, 51.850628],
            [38.446396, 51.844548],
            [38.432535, 51.817115],
            [38.402726, 51.800821],
            [38.399056, 51.782003],
            [38.446167, 51.756229],
            [38.463079, 51.739917],
            [38.517086, 51.753629],
            [38.518174, 51.747509],
            [38.48381, 51.732775],
            [38.478134, 51.721006],
            [38.445159, 51.691451],
            [38.38662, 51.683529],
            [38.338935, 51.688989],
            [38.332611, 51.694216],
            [38.29114, 51.701321],
            [38.288977, 51.692752],
            [38.237557, 51.701374],
            [38.224395, 51.699273],
            [38.234734, 51.658985],
            [38.157917, 51.672848],
            [38.15256, 51.654439],
            [38.135846, 51.640672],
            [38.139917, 51.628731],
            [38.1882, 51.610012],
            [38.216189, 51.584547],
            [38.228964, 51.560909],
            [38.258393, 51.548813],
            [38.297481, 51.521305],
            [38.280533, 51.493034],
            [38.309791, 51.482844],
            [38.302876, 51.464023],
            [38.318511, 51.44484],
            [38.280482, 51.434852],
            [38.306227, 51.410398],
            [38.293467, 51.394089],
            [38.318728, 51.373982],
            [38.376395, 51.387401],
            [38.375303, 51.359852],
            [38.332386, 51.357946],
            [38.328641, 51.33115],
            [38.336448, 51.323268],
            [38.36205, 51.323779],
            [38.356969, 51.30172],
            [38.33691, 51.304152],
            [38.288584, 51.296352],
            [38.246245, 51.308657],
            [38.229369, 51.299783],
            [38.217127, 51.330741],
            [38.206662, 51.33799],
            [38.157822, 51.333011],
            [38.074869, 51.37177],
            [38.074862, 51.356055],
            [38.059847, 51.365101],
            [38.035954, 51.36706],
            [38.035173, 51.381351],
            [38.011934, 51.407675],
            [38.005829, 51.423132],
            [37.98618, 51.420358],
            [37.948711, 51.426777],
            [37.933749, 51.422302],
            [37.847478, 51.429731],
            [37.831218, 51.422749],
            [37.782623, 51.390209],
            [37.761905, 51.391977],
            [37.703198, 51.35774],
            [37.685815, 51.361285],
            [37.604163, 51.367555],
            [37.583906, 51.371443],
            [37.585636, 51.388727],
            [37.557248, 51.39153],
            [37.521695, 51.378466],
            [37.50125, 51.382605],
            [37.47205, 51.368026],
            [37.418872, 51.38002],
            [37.383742, 51.384521],
            [37.323108, 51.358639],
            [37.319004, 51.33957],
            [37.300755, 51.331542],
            [37.273066, 51.337562],
            [37.237701, 51.327501],
            [37.19773, 51.337981],
            [37.186816, 51.347633],
            [37.145238, 51.355232],
            [37.159994, 51.319629],
            [37.152124, 51.304191],
            [37.133592, 51.298546],
            [37.121755, 51.283952],
            [37.136131, 51.260993],
            [37.139551, 51.230766],
            [37.097971, 51.230326],
            [37.096716, 51.243182],
            [37.112458, 51.244249],
            [37.109697, 51.254471],
            [37.07925, 51.252087],
            [37.084393, 51.243621],
            [37.054698, 51.242429],
            [37.03519, 51.235027],
            [37.023605, 51.208853],
            [36.989985, 51.190595],
            [36.987165, 51.183217],
            [36.960578, 51.184797],
            [36.949428, 51.170439],
            [36.921022, 51.178664],
            [36.895737, 51.172472],
            [36.868078, 51.177882],
            [36.85537, 51.171292],
            [36.810617, 51.166164],
            [36.790768, 51.160269],
            [36.767707, 51.14152],
            [36.752338, 51.149488],
            [36.743079, 51.131167],
            [36.665726, 51.086137],
            [36.648479, 51.0899],
            [36.642341, 51.077848],
            [36.619507, 51.077795],
            [36.587022, 51.066319],
            [36.603797, 51.080053],
            [36.60157, 51.100184],
            [36.588211, 51.10959],
            [36.564615, 51.110914],
            [36.531642, 51.133797],
            [36.520353, 51.133985],
            [36.520857, 51.119749],
            [36.508069, 51.116121],
            [36.494006, 51.094044],
            [36.503822, 51.078414],
            [36.486282, 51.062953],
            [36.448054, 51.095801],
            [36.430807, 51.106149],
            [36.430242, 51.117688],
            [36.404843, 51.11844],
            [36.380606, 51.103451],
            [36.362982, 51.104113],
            [36.353138, 51.091598],
            [36.305765, 51.094514],
            [36.265023, 51.107274],
            [36.249216, 51.131982],
            [36.228394, 51.143019],
            [36.223316, 51.118059],
            [36.185502, 51.124598],
            [36.140156, 51.110468],
            [36.131187, 51.119247],
            [36.077218, 51.145397],
            [36.064989, 51.158692],
            [36.011712, 51.158189],
            [35.98436, 51.148072],
            [36.005473, 51.129403],
            [35.954324, 51.138352],
            [35.922334, 51.130149],
            [35.936273, 51.117209],
            [35.937131, 51.098994],
            [35.9223, 51.096882],
            [35.996461, 51.071242],
            [36.006041, 51.052517],
            [35.950696, 51.054648],
            [35.949132, 51.037779],
            [35.933017, 51.022692],
            [35.909873, 51.020532],
            [35.874942, 50.997892],
            [35.882844, 50.990868],
            [35.862745, 50.958006],
            [35.836123, 50.958131],
            [35.83418, 50.940257],
            [35.803262, 50.936682],
            [35.788022, 50.943016],
            [35.77946, 50.966846],
            [35.734212, 50.975939],
            [35.720726, 50.950291],
            [35.736314, 50.940318],
            [35.726405, 50.928904],
            [35.699626, 50.931913],
            [35.679814, 50.907181],
            [35.6477, 50.916735],
            [35.647888, 50.928337],
            [35.60633, 50.935838],
            [35.605932, 50.915856],
            [35.570907, 50.901557],
            [35.529766, 50.920182],
            [35.542685, 50.923694],
            [35.54306, 50.944578],
            [35.479507, 50.937681],
            [35.456672, 50.954986],
            [35.436245, 50.958166],
            [35.421383, 50.948512],
            [35.412302, 50.957556],
            [35.390603, 50.960942],
            [35.350987, 50.975007],
            [35.329333, 50.996421],
            [35.329886, 51.010892],
            [35.357518, 51.005635],
            [35.382513, 51.012867],
            [35.383937, 51.020063],
            [35.409673, 51.026222],
            [35.409781, 51.039037],
            [35.375306, 51.066957],
            [35.349601, 51.062222],
            [35.327441, 51.070647],
            [35.328747, 51.081446],
            [35.314502, 51.086973],
            [35.304276, 51.074054],
            [35.276063, 51.05909],
            [35.249645, 51.064264],
            [35.213135, 51.046436],
            [35.18981, 51.054158],
            [35.177264, 51.070115],
            [35.148198, 51.082447],
            [35.176928, 51.098445],
            [35.164685, 51.101005],
            [35.16701, 51.126432],
            [35.141357, 51.133848],
            [35.13849, 51.15376],
            [35.123326, 51.164645],
            [35.129494, 51.190943],
            [35.143355, 51.202984],
            [35.137561, 51.215194],
            [35.150481, 51.223433],
            [35.105548, 51.23262],
            [35.066324, 51.229048],
            [35.062137, 51.215567],
            [35.039775, 51.204427],
            [35.037854, 51.217757],
            [35.010508, 51.231448],
            [34.979821, 51.235006],
            [34.951258, 51.2266],
            [34.93854, 51.210011],
            [34.911165, 51.205749],
            [34.896956, 51.19313],
            [34.876191, 51.190979],
            [34.86251, 51.199175],
            [34.834648, 51.183146],
            [34.835572, 51.174424],
            [34.817314, 51.168911],
            [34.78463, 51.184672],
            [34.772513, 51.174628],
            [34.731243, 51.182685],
            [34.716867, 51.17188],
            [34.688265, 51.181241],
            [34.668698, 51.197159],
            [34.661654, 51.247478],
            [34.603836, 51.254624],
            [34.580228, 51.233474],
            [34.538213, 51.253771],
            [34.512866, 51.242684],
            [34.483371, 51.244627],
            [34.446217, 51.261811],
            [34.438851, 51.256374],
            [34.383668, 51.27401],
            [34.330917, 51.239858],
            [34.306796, 51.238178],
            [34.254165, 51.259741],
            [34.231285, 51.265143],
            [34.254305, 51.282037],
            [34.237848, 51.292152],
            [34.288784, 51.32233],
            [34.287917, 51.339048],
            [34.316805, 51.330948],
            [34.316114, 51.342622],
            [34.338989, 51.357325],
            [34.336701, 51.365575],
            [34.285412, 51.374676],
            [34.292501, 51.388041],
            [34.272098, 51.382706],
            [34.245793, 51.40217],
            [34.228087, 51.399613],
            [34.222388, 51.428758],
            [34.246199, 51.431506],
            [34.255278, 51.439698],
            [34.244733, 51.456456],
            [34.263785, 51.470662],
            [34.295988, 51.481829],
            [34.289608, 51.509603],
            [34.308407, 51.5192],
            [34.295169, 51.5328],
            [34.259867, 51.533276],
            [34.257211, 51.559571],
            [34.237452, 51.584811],
            [34.216291, 51.574344],
            [34.209562, 51.600301],
            [34.182334, 51.596765],
            [34.179736, 51.624426],
            [34.16726, 51.645658],
            [34.130228, 51.643006],
            [34.112085, 51.649019],
            [34.105377, 51.666775],
            [34.080765, 51.6657],
            [34.128387, 51.689256],
            [34.161334, 51.686345],
            [34.213067, 51.702874],
            [34.233912, 51.696848],
            [34.296708, 51.707552],
            [34.306162, 51.718843],
            [34.338581, 51.713818],
            [34.400777, 51.715211],
            [34.435815, 51.732626],
            [34.43665, 51.764149],
            [34.409704, 51.781476],
            [34.415371, 51.825296],
            [34.385548, 51.843075],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5498",
      properties: {
        name: "Ленинградская область",
        density: 0,
        path: "/world/Russia/Ленинградская область",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [26.896822, 59.816167],
              [26.920219, 59.818092],
              [26.926266, 59.805019],
              [26.902202, 59.805564],
              [26.896822, 59.816167],
            ],
          ],
          [
            [
              [27.211232, 59.840587],
              [27.185261, 59.835448],
              [27.173066, 59.847474],
              [27.194856, 59.866614],
              [27.214213, 59.861516],
              [27.22889, 59.845626],
              [27.211232, 59.840587],
            ],
          ],
          [
            [
              [27.85833, 59.99354],
              [27.82458, 59.98198],
              [27.79474, 59.99419],
              [27.81068, 60.00894],
              [27.79696, 60.02259],
              [27.83001, 60.03569],
              [27.83324, 60.02185],
              [27.85877, 60.01043],
              [27.87713, 60.01875],
              [27.89974, 60.00401],
              [27.89175, 59.98283],
              [27.88351, 59.999],
              [27.85833, 59.99354],
            ],
          ],
          [
            [
              [28.39137, 60.02176],
              [28.40272, 60.00603],
              [28.38998, 60.00079],
              [28.36776, 60.01356],
              [28.31509, 60.01307],
              [28.3052, 60.02162],
              [28.3344, 60.02312],
              [28.33385, 60.03463],
              [28.36168, 60.03974],
              [28.392, 60.03071],
              [28.39137, 60.02176],
            ],
          ],
          [
            [
              [26.985559, 60.01448],
              [27.027842, 60.035229],
              [27.029603, 60.024718],
              [27.007631, 60.009963],
              [26.985559, 60.01448],
            ],
          ],
          [
            [
              [27.99449, 60.0279],
              [28.05961, 60.01717],
              [28.00451, 60.02092],
              [27.99449, 60.0279],
            ],
          ],
          [
            [
              [29.305928, 61.329293],
              [29.335026, 61.308869],
              [29.367141, 61.277887],
              [29.424469, 61.256427],
              [29.48907, 61.255501],
              [29.5153, 61.249371],
              [29.517735, 61.240619],
              [29.551238, 61.240634],
              [29.563615, 61.224882],
              [29.558341, 61.212478],
              [29.564198, 61.183669],
              [29.582921, 61.177989],
              [29.629958, 61.18241],
              [29.650015, 61.175744],
              [29.707642, 61.177481],
              [29.720548, 61.172914],
              [29.795129, 61.17107],
              [29.812496, 61.162331],
              [29.854803, 61.172328],
              [29.902393, 61.162069],
              [29.996847, 61.147145],
              [30.384933, 61.120144],
              [30.939044, 61.026161],
              [31.517454, 60.921182],
              [32.09427, 60.817666],
              [32.419333, 60.763544],
              [32.915654, 60.679797],
              [32.923099, 60.710952],
              [33.026625, 60.720911],
              [33.073887, 60.721259],
              [33.157684, 60.739337],
              [33.154203, 60.754431],
              [33.173097, 60.786138],
              [33.264762, 60.794883],
              [33.244682, 60.806702],
              [33.211091, 60.80618],
              [33.179558, 60.820888],
              [33.204424, 60.825627],
              [33.1977, 60.833843],
              [33.2059, 60.854681],
              [33.220189, 60.843379],
              [33.26111, 60.867652],
              [33.249372, 60.873072],
              [33.330695, 60.899418],
              [33.350403, 60.897911],
              [33.385615, 60.909794],
              [33.426219, 60.913896],
              [33.466504, 60.927591],
              [33.466386, 60.951414],
              [33.421693, 60.983135],
              [33.449051, 61.003278],
              [33.542432, 61.004323],
              [33.545476, 60.984093],
              [33.587965, 60.973805],
              [33.607877, 60.958575],
              [33.691027, 60.95649],
              [33.719547, 60.939753],
              [33.785424, 60.93703],
              [33.783858, 60.946884],
              [33.812841, 60.947058],
              [33.819795, 60.971447],
              [33.838545, 60.991416],
              [33.861353, 61.002531],
              [33.863497, 61.016023],
              [33.884942, 61.045368],
              [33.9194, 61.073669],
              [33.920413, 61.089233],
              [33.881054, 61.101564],
              [33.843841, 61.09426],
              [33.835001, 61.104795],
              [33.738691, 61.130023],
              [33.725157, 61.113473],
              [33.685683, 61.112502],
              [33.660789, 61.096112],
              [33.66108, 61.07381],
              [33.63633, 61.065883],
              [33.59135, 61.063795],
              [33.544948, 61.078634],
              [33.543903, 61.094443],
              [33.523383, 61.109659],
              [33.507095, 61.109659],
              [33.471532, 61.130554],
              [33.450692, 61.153015],
              [33.483847, 61.164391],
              [33.501003, 61.183694],
              [33.51277, 61.184332],
              [33.550967, 61.21201],
              [33.608844, 61.219011],
              [33.6682, 61.214171],
              [33.667677, 61.23243],
              [33.976599, 61.233624],
              [34.186692, 61.235685],
              [34.186169, 61.244032],
              [34.236106, 61.244265],
              [34.241961, 61.237309],
              [34.304854, 61.222877],
              [34.333923, 61.220559],
              [34.358154, 61.1952],
              [34.373283, 61.194736],
              [34.421774, 61.145192],
              [34.442786, 61.145134],
              [34.467362, 61.163857],
              [34.470345, 61.18458],
              [34.490487, 61.20039],
              [34.463011, 61.208084],
              [34.478255, 61.219315],
              [34.478428, 61.235342],
              [34.513003, 61.243328],
              [34.541144, 61.260703],
              [34.545519, 61.274948],
              [34.658173, 61.274254],
              [34.731961, 61.295485],
              [34.749234, 61.296818],
              [34.73851, 61.311932],
              [34.770245, 61.312745],
              [34.794331, 61.289675],
              [34.87441, 61.287068],
              [34.905015, 61.282678],
              [34.949866, 61.264969],
              [35.050276, 61.263523],
              [35.078941, 61.248481],
              [35.084506, 61.23741],
              [35.169801, 61.236789],
              [35.19855, 61.260192],
              [35.224606, 61.247556],
              [35.297757, 61.252311],
              [35.352676, 61.284975],
              [35.374877, 61.276802],
              [35.393194, 61.282483],
              [35.358332, 61.225778],
              [35.350481, 61.177392],
              [35.402273, 61.175727],
              [35.393668, 61.141498],
              [35.416332, 61.142716],
              [35.451457, 61.160976],
              [35.462934, 61.157846],
              [35.515101, 61.172497],
              [35.524781, 61.181714],
              [35.600599, 61.178962],
              [35.697879, 61.207401],
              [35.696684, 61.003459],
              [35.526136, 60.920639],
              [35.51874, 60.893593],
              [35.491574, 60.885264],
              [35.461678, 60.856936],
              [35.393146, 60.854068],
              [35.363521, 60.860621],
              [35.328845, 60.856934],
              [35.286867, 60.844237],
              [35.261611, 60.846421],
              [35.259018, 60.83393],
              [35.241953, 60.834339],
              [35.223662, 60.800618],
              [35.200044, 60.799253],
              [35.175541, 60.770072],
              [35.184143, 60.751096],
              [35.220458, 60.733519],
              [35.237523, 60.731336],
              [35.237252, 60.714475],
              [35.20872, 60.698093],
              [35.258414, 60.689493],
              [35.261077, 60.669493],
              [35.281693, 60.637821],
              [35.272549, 60.610176],
              [35.298761, 60.601439],
              [35.278109, 60.478981],
              [35.260854, 60.35912],
              [35.274424, 60.336265],
              [35.253705, 60.332801],
              [35.252712, 60.313907],
              [35.237984, 60.291391],
              [35.162628, 60.256032],
              [35.185005, 60.245507],
              [35.174712, 60.211912],
              [35.181838, 60.201357],
              [35.155502, 60.151927],
              [35.175518, 60.128019],
              [35.207347, 60.11418],
              [35.213954, 60.074013],
              [35.140138, 60.042845],
              [35.145554, 60.020779],
              [35.282711, 60.014191],
              [35.286651, 60.000382],
              [35.322887, 59.995253],
              [35.321386, 59.980802],
              [35.446386, 59.977593],
              [35.447103, 59.916773],
              [35.4222, 59.905566],
              [35.375027, 59.8579],
              [35.397589, 59.771027],
              [35.407351, 59.719425],
              [35.442658, 59.705428],
              [35.565239, 59.710899],
              [35.584817, 59.720882],
              [35.615236, 59.712406],
              [35.605574, 59.614381],
              [35.49466, 59.651901],
              [35.493687, 59.535701],
              [35.466134, 59.5467],
              [35.441465, 59.534755],
              [35.40948, 59.530044],
              [35.421495, 59.519259],
              [35.476921, 59.516666],
              [35.512622, 59.503458],
              [35.495147, 59.50147],
              [35.417065, 59.517033],
              [35.316317, 59.520275],
              [35.31825, 59.486473],
              [35.373058, 59.472722],
              [35.4071, 59.44137],
              [35.390374, 59.429054],
              [35.440613, 59.425505],
              [35.426143, 59.40885],
              [35.442389, 59.404345],
              [35.413721, 59.398474],
              [35.392971, 59.380897],
              [35.347921, 59.365879],
              [35.312699, 59.371749],
              [35.297205, 59.35093],
              [35.299507, 59.340982],
              [35.354194, 59.322604],
              [35.390585, 59.32745],
              [35.396223, 59.317561],
              [35.35703, 59.307346],
              [35.24689, 59.30188],
              [35.188167, 59.286016],
              [35.195082, 59.277462],
              [35.178108, 59.257808],
              [35.148201, 59.256286],
              [35.100451, 59.246096],
              [35.047762, 59.249169],
              [35.037493, 59.254795],
              [34.999124, 59.246607],
              [34.975849, 59.224128],
              [34.936793, 59.222012],
              [34.912944, 59.228606],
              [34.925839, 59.213223],
              [34.891208, 59.218517],
              [34.863305, 59.215629],
              [34.84926, 59.201096],
              [34.849982, 59.171878],
              [34.842542, 59.173004],
              [34.834593, 59.127253],
              [34.803671, 59.127321],
              [34.784901, 59.114522],
              [34.783195, 59.100666],
              [34.76224, 59.089266],
              [34.752138, 59.09343],
              [34.722362, 59.084852],
              [34.725364, 59.098002],
              [34.706925, 59.097856],
              [34.692216, 59.11088],
              [34.658414, 59.121465],
              [34.627166, 59.114153],
              [34.58019, 59.121616],
              [34.556885, 59.118012],
              [34.511956, 59.144646],
              [34.479577, 59.141627],
              [34.450966, 59.160066],
              [34.423125, 59.144681],
              [34.423344, 59.156681],
              [34.397953, 59.160632],
              [34.391952, 59.182766],
              [34.38083, 59.177717],
              [34.341462, 59.204132],
              [34.319438, 59.190521],
              [34.330122, 59.18379],
              [34.28417, 59.178155],
              [34.25695, 59.188691],
              [34.248351, 59.20713],
              [34.215204, 59.205227],
              [34.21162, 59.178739],
              [34.176535, 59.173287],
              [34.158973, 59.183018],
              [34.105741, 59.170158],
              [34.117889, 59.156841],
              [34.116426, 59.141914],
              [34.076767, 59.140523],
              [34.067181, 59.150181],
              [34.032296, 59.147703],
              [34.011606, 59.162382],
              [33.972166, 59.173905],
              [33.966019, 59.186052],
              [33.946848, 59.187296],
              [33.929835, 59.199991],
              [33.93254, 59.234674],
              [33.922076, 59.236723],
              [33.907846, 59.207326],
              [33.800101, 59.200665],
              [33.791393, 59.216177],
              [33.819526, 59.234489],
              [33.79977, 59.239318],
              [33.804305, 59.258782],
              [33.78206, 59.264727],
              [33.773571, 59.28302],
              [33.733765, 59.297653],
              [33.706362, 59.298202],
              [33.690447, 59.310604],
              [33.660739, 59.308774],
              [33.663372, 59.327287],
              [33.62203, 59.327798],
              [33.630295, 59.382494],
              [33.536818, 59.387084],
              [33.523794, 59.378011],
              [33.444585, 59.379253],
              [33.348401, 59.385252],
              [33.342771, 59.315153],
              [33.262062, 59.320932],
              [33.261586, 59.337195],
              [33.23473, 59.362439],
              [33.212852, 59.363024],
              [33.155191, 59.383858],
              [33.138436, 59.371346],
              [33.112934, 59.388504],
              [33.066544, 59.379503],
              [33.056956, 59.418503],
              [33.020992, 59.426387],
              [32.966106, 59.420202],
              [32.970725, 59.392197],
              [32.937799, 59.373098],
              [32.92141, 59.348622],
              [32.846921, 59.348621],
              [32.773204, 59.301662],
              [32.76318, 59.286076],
              [32.732449, 59.271441],
              [32.744998, 59.265203],
              [32.72956, 59.257154],
              [32.736805, 59.240252],
              [32.770172, 59.218648],
              [32.765856, 59.206355],
              [32.738417, 59.195672],
              [32.764174, 59.188044],
              [32.74676, 59.175166],
              [32.728833, 59.173921],
              [32.725908, 59.148458],
              [32.679042, 59.144981],
              [32.647397, 59.117194],
              [32.615568, 59.110095],
              [32.564201, 59.115948],
              [32.575761, 59.135778],
              [32.4997, 59.112508],
              [32.447236, 59.107971],
              [32.417564, 59.130507],
              [32.389173, 59.142433],
              [32.407611, 59.151251],
              [32.43249, 59.15169],
              [32.464174, 59.137715],
              [32.475441, 59.152277],
              [32.408306, 59.160654],
              [32.373549, 59.170824],
              [32.347097, 59.172488],
              [32.280729, 59.202122],
              [32.278496, 59.211945],
              [32.301106, 59.215311],
              [32.286252, 59.224604],
              [32.315885, 59.241287],
              [32.289689, 59.243793],
              [32.279225, 59.258866],
              [32.296785, 59.278769],
              [32.276003, 59.289159],
              [32.248454, 59.290439],
              [32.218601, 59.275731],
              [32.199722, 59.289634],
              [32.22855, 59.306793],
              [32.260159, 59.333208],
              [32.236085, 59.343379],
              [32.253867, 59.325891],
              [32.202941, 59.303646],
              [32.184428, 59.310652],
              [32.211574, 59.320311],
              [32.222036, 59.338385],
              [32.187354, 59.319213],
              [32.114947, 59.371676],
              [32.11802, 59.382505],
              [32.095995, 59.38192],
              [32.032554, 59.405169],
              [32.031457, 59.38812],
              [32.001565, 59.409687],
              [31.97098, 59.40515],
              [31.989784, 59.421321],
              [31.986358, 59.433329],
              [31.963515, 59.432369],
              [31.927479, 59.41146],
              [31.906152, 59.376154],
              [31.860347, 59.360604],
              [31.804102, 59.354581],
              [31.786775, 59.339493],
              [31.808873, 59.327274],
              [31.800679, 59.313024],
              [31.740312, 59.315218],
              [31.726222, 59.321524],
              [31.760287, 59.333749],
              [31.747408, 59.342822],
              [31.710677, 59.335797],
              [31.686894, 59.360583],
              [31.648003, 59.362046],
              [31.602563, 59.376314],
              [31.552404, 59.369069],
              [31.541212, 59.315781],
              [31.558848, 59.293245],
              [31.54019, 59.293684],
              [31.537263, 59.281025],
              [31.548826, 59.259],
              [31.580584, 59.231836],
              [31.566976, 59.211201],
              [31.53361, 59.199402],
              [31.510194, 59.21184],
              [31.508878, 59.200718],
              [31.469404, 59.165211],
              [31.488575, 59.166967],
              [31.483967, 59.137644],
              [31.462931, 59.124363],
              [31.476982, 59.101899],
              [31.425946, 59.072172],
              [31.356837, 59.056421],
              [31.371545, 59.046982],
              [31.365912, 59.026348],
              [31.306643, 59.022907],
              [31.317327, 59.018005],
              [31.300095, 59.006846],
              [31.222168, 59.005089],
              [31.187226, 59.047089],
              [31.15503, 59.054625],
              [31.164102, 59.075406],
              [31.129491, 59.08929],
              [31.080905, 59.090679],
              [31.05976, 59.067941],
              [31.046515, 59.075477],
              [31.02193, 59.065891],
              [30.990029, 59.040555],
              [30.97115, 59.043847],
              [30.956004, 59.033255],
              [30.964267, 59.016809],
              [30.98666, 59.012498],
              [31.003275, 59.000292],
              [30.979056, 58.989316],
              [30.978618, 58.980755],
              [30.954106, 58.973145],
              [30.965082, 58.963907],
              [30.952352, 58.94276],
              [30.922426, 58.921503],
              [30.873475, 58.903392],
              [30.813167, 58.876562],
              [30.772318, 58.872842],
              [30.762184, 58.856524],
              [30.749598, 58.870061],
              [30.736282, 58.856816],
              [30.70555, 58.853523],
              [30.68272, 58.858718],
              [30.67888, 58.833528],
              [30.715247, 58.8257],
              [30.728272, 58.813919],
              [30.715278, 58.809309],
              [30.713969, 58.778193],
              [30.700691, 58.745174],
              [30.713789, 58.736394],
              [30.6824, 58.713746],
              [30.664179, 58.736576],
              [30.643105, 58.731892],
              [30.619838, 58.71455],
              [30.598691, 58.715739],
              [30.586033, 58.70447],
              [30.561832, 58.726569],
              [30.496031, 58.723713],
              [30.444995, 58.704468],
              [30.406441, 58.702876],
              [30.388067, 58.719065],
              [30.368969, 58.718991],
              [30.377748, 58.73926],
              [30.347053, 58.746924],
              [30.338783, 58.760534],
              [30.323053, 58.743924],
              [30.28837, 58.736533],
              [30.270039, 58.748679],
              [30.229869, 58.743629],
              [30.215526, 58.760751],
              [30.192149, 58.745769],
              [30.177806, 58.757696],
              [30.152307, 58.742787],
              [30.121606, 58.745329],
              [30.120552, 58.720981],
              [30.10299, 58.728005],
              [30.093186, 58.719883],
              [30.108992, 58.702084],
              [30.136505, 58.693231],
              [30.117042, 58.679035],
              [30.083421, 58.668022],
              [30.065055, 58.667875],
              [30.070616, 58.65401],
              [30.111447, 58.641425],
              [30.072681, 58.628347],
              [30.102779, 58.599772],
              [30.119793, 58.565802],
              [30.171235, 58.530442],
              [30.099747, 58.51449],
              [30.093382, 58.502709],
              [30.132895, 58.492008],
              [30.108895, 58.489301],
              [30.097774, 58.479715],
              [30.062213, 58.474007],
              [30.069715, 58.447409],
              [30.021129, 58.445286],
              [29.953409, 58.435919],
              [29.963506, 58.448139],
              [29.944335, 58.45831],
              [29.930579, 58.442943],
              [29.899773, 58.45326],
              [29.894085, 58.445747],
              [29.857009, 58.443009],
              [29.865539, 58.427259],
              [29.786442, 58.421187],
              [29.747405, 58.423666],
              [29.752772, 58.434235],
              [29.713254, 58.444417],
              [29.708016, 58.459262],
              [29.690701, 58.459715],
              [29.673745, 58.442748],
              [29.639475, 58.446943],
              [29.59714, 58.458222],
              [29.582799, 58.47552],
              [29.551022, 58.479784],
              [29.539741, 58.493041],
              [29.551158, 58.508758],
              [29.584326, 58.523322],
              [29.579562, 58.533553],
              [29.543091, 58.544007],
              [29.510885, 58.541393],
              [29.490595, 58.549234],
              [29.47204, 58.566876],
              [29.423153, 58.590329],
              [29.406303, 58.579255],
              [29.393838, 58.554494],
              [29.3275, 58.560201],
              [29.318833, 58.574026],
              [29.358277, 58.581868],
              [29.37341, 58.568593],
              [29.383588, 58.579839],
              [29.3677, 58.583691],
              [29.403052, 58.591945],
              [29.352412, 58.606921],
              [29.313104, 58.613729],
              [29.288567, 58.611528],
              [29.264289, 58.594676],
              [29.243034, 58.626487],
              [29.230928, 58.632608],
              [29.267949, 58.634517],
              [29.291677, 58.648687],
              [29.257286, 58.669767],
              [29.283283, 58.696145],
              [29.278399, 58.701028],
              [29.217254, 58.699634],
              [29.180972, 58.709951],
              [29.139825, 58.700389],
              [29.123764, 58.717756],
              [29.096441, 58.721332],
              [29.085917, 58.731649],
              [29.035966, 58.725303],
              [29.073657, 58.736377],
              [29.055894, 58.739799],
              [29.033775, 58.832772],
              [29.016856, 58.820546],
              [28.98422, 58.816625],
              [28.973492, 58.805139],
              [28.909698, 58.803005],
              [28.888187, 58.816606],
              [28.843307, 58.825255],
              [28.767855, 58.831306],
              [28.765792, 58.824841],
              [28.666662, 58.852317],
              [28.659233, 58.865866],
              [28.64479, 58.852316],
              [28.621988, 58.864559],
              [28.540948, 58.867859],
              [28.492734, 58.862699],
              [28.486423, 58.869423],
              [28.436471, 58.881046],
              [28.411402, 58.855132],
              [28.373537, 58.878448],
              [28.344101, 58.854048],
              [28.324706, 58.851159],
              [28.344894, 58.824954],
              [28.311311, 58.840566],
              [28.294324, 58.826191],
              [28.260928, 58.874749],
              [28.239743, 58.879839],
              [28.266292, 58.88541],
              [28.257969, 58.898616],
              [28.225608, 58.895641],
              [28.202272, 58.934793],
              [28.146438, 58.96356],
              [28.174018, 58.971332],
              [28.144219, 58.978416],
              [28.121247, 58.974839],
              [28.120902, 58.992653],
              [28.096588, 59.000958],
              [28.080545, 58.991879],
              [28.03381, 58.987063],
              [28.033998, 59.000252],
              [27.996598, 59.004239],
              [27.990717, 59.01552],
              [27.93124, 59.014556],
              [27.917999, 59.021159],
              [27.885742, 59.014556],
              [27.737764, 58.97789],
              [27.746922, 58.991553],
              [27.744829, 59.026807],
              [27.768081, 59.033119],
              [27.773144, 59.058397],
              [27.792153, 59.070041],
              [27.78808, 59.091203],
              [27.809444, 59.108778],
              [27.808705, 59.12973],
              [27.864855, 59.16089],
              [27.887661, 59.18386],
              [27.900424, 59.207658],
              [27.899999, 59.235855],
              [27.95419, 59.270369],
              [27.998281, 59.276399],
              [28.046436, 59.290109],
              [28.131204, 59.290973],
              [28.167837, 59.333793],
              [28.191905, 59.347182],
              [28.210017, 59.370368],
              [28.208569, 59.383744],
              [28.191225, 59.400645],
              [28.139254, 59.419181],
              [28.124381, 59.437162],
              [28.078423, 59.459194],
              [28.046065, 59.466505],
              [28.045343, 59.478381],
              [28.073735, 59.51259],
              [28.08999, 59.5488],
              [28.0945, 59.59457],
              [28.07709, 59.61852],
              [28.05141, 59.62948],
              [28.04775, 59.64178],
              [28.01487, 59.65767],
              [28.01647, 59.66891],
              [27.9962, 59.69338],
              [28.03758, 59.72966],
              [28.029749, 59.751902],
              [28.07225, 59.76778],
              [28.09791, 59.79981],
              [28.07541, 59.81075],
              [28.09029, 59.81523],
              [28.11333, 59.78983],
              [28.16908, 59.78691],
              [28.19369, 59.77211],
              [28.20229, 59.74336],
              [28.2267, 59.71063],
              [28.21375, 59.69565],
              [28.22104, 59.68713],
              [28.253256, 59.677494],
              [28.30199, 59.67945],
              [28.34706, 59.66747],
              [28.39727, 59.667219],
              [28.422225, 59.683188],
              [28.434993, 59.701261],
              [28.440464, 59.729883],
              [28.436945, 59.770819],
              [28.446902, 59.777559],
              [28.44184, 59.79497],
              [28.47145, 59.82715],
              [28.508117, 59.832556],
              [28.544724, 59.849234],
              [28.576331, 59.826204],
              [28.639266, 59.815925],
              [28.660037, 59.815849],
              [28.672526, 59.797838],
              [28.706544, 59.788834],
              [28.717577, 59.775794],
              [28.755664, 59.774174],
              [28.779485, 59.782598],
              [28.791205, 59.803865],
              [28.811766, 59.783025],
              [28.840371, 59.782203],
              [28.865007, 59.793744],
              [28.880203, 59.80965],
              [28.926865, 59.805639],
              [28.964706, 59.81191],
              [29.000218, 59.825246],
              [29.015739, 59.841565],
              [29.061495, 59.864999],
              [29.061837, 59.891148],
              [28.996978, 59.905511],
              [28.988939, 59.913568],
              [29.04088, 59.91835],
              [29.058603, 59.937091],
              [29.05707, 59.957528],
              [29.119817, 59.973567],
              [29.12991, 59.98683],
              [29.15552, 59.98001],
              [29.18768, 59.98358],
              [29.20645, 59.993],
              [29.25882, 59.98161],
              [29.33418, 59.97813],
              [29.42425, 59.96639],
              [29.47261, 59.95743],
              [29.55231, 59.95809],
              [29.62473, 59.93132],
              [29.647559, 59.929828],
              [29.656531, 59.915543],
              [29.72208, 59.9015],
              [29.714945, 59.889277],
              [29.7245, 59.871103],
              [29.771356, 59.87222],
              [29.798885, 59.858308],
              [29.821382, 59.868244],
              [29.872016, 59.856211],
              [29.886267, 59.842746],
              [29.892708, 59.853855],
              [29.945457, 59.844794],
              [29.944059, 59.828085],
              [30.005135, 59.83638],
              [30.044272, 59.837316],
              [30.038641, 59.821716],
              [30.053039, 59.816193],
              [30.106357, 59.81671],
              [30.12769, 59.809289],
              [30.105084, 59.802945],
              [30.077229, 59.747534],
              [30.040947, 59.719632],
              [30.080821, 59.715371],
              [30.100898, 59.699199],
              [30.133743, 59.694757],
              [30.107026, 59.724548],
              [30.117987, 59.761779],
              [30.141685, 59.76117],
              [30.162925, 59.796426],
              [30.17948, 59.790583],
              [30.211009, 59.797537],
              [30.28985, 59.790402],
              [30.250585, 59.765601],
              [30.300721, 59.747387],
              [30.255618, 59.701724],
              [30.231665, 59.689384],
              [30.218223, 59.673069],
              [30.249575, 59.671021],
              [30.23863, 59.661783],
              [30.261542, 59.648656],
              [30.242922, 59.63863],
              [30.254172, 59.633783],
              [30.306506, 59.662096],
              [30.351922, 59.658231],
              [30.366668, 59.650194],
              [30.40637, 59.64945],
              [30.437203, 59.662958],
              [30.467429, 59.639224],
              [30.505564, 59.646024],
              [30.501904, 59.670247],
              [30.47712, 59.6807],
              [30.48795, 59.688416],
              [30.520541, 59.690944],
              [30.530688, 59.707325],
              [30.564495, 59.704998],
              [30.576483, 59.73],
              [30.63112, 59.735854],
              [30.648782, 59.721816],
              [30.682984, 59.724918],
              [30.703055, 59.745284],
              [30.757012, 59.743751],
              [30.727032, 59.770433],
              [30.736193, 59.779121],
              [30.697214, 59.781414],
              [30.625462, 59.79543],
              [30.589738, 59.816848],
              [30.563241, 59.825223],
              [30.558082, 59.836605],
              [30.540985, 59.833595],
              [30.493413, 59.847083],
              [30.493672, 59.856844],
              [30.521344, 59.861529],
              [30.495419, 59.891479],
              [30.512311, 59.967529],
              [30.544485, 59.976283],
              [30.534726, 59.992412],
              [30.513354, 59.983375],
              [30.490446, 60.008026],
              [30.489542, 60.022454],
              [30.46788, 60.022636],
              [30.468412, 60.033393],
              [30.392749, 60.055272],
              [30.37004, 60.088377],
              [30.302941, 60.091104],
              [30.275745, 60.102217],
              [30.259144, 60.125758],
              [30.232764, 60.114415],
              [30.189739, 60.117365],
              [30.192502, 60.125877],
              [30.068275, 60.135832],
              [30.023036, 60.143029],
              [30.007516, 60.165109],
              [29.849118, 60.197295],
              [29.801803, 60.221276],
              [29.785676, 60.222078],
              [29.758015, 60.242701],
              [29.713353, 60.241376],
              [29.719902, 60.225924],
              [29.69096, 60.237576],
              [29.643965, 60.242192],
              [29.579326, 60.219809],
              [29.521235, 60.225715],
              [29.521152, 60.233286],
              [29.490349, 60.224964],
              [29.42981, 60.189538],
              [29.469222, 60.181487],
              [29.4389, 60.176428],
              [29.457377, 60.169779],
              [29.44526, 60.16084],
              [29.26793, 60.15938],
              [29.17434, 60.16543],
              [29.14777, 60.15985],
              [29.06938, 60.17914],
              [29.03938, 60.17615],
              [29.00997, 60.18249],
              [28.99027, 60.19661],
              [28.9926, 60.23088],
              [28.97439, 60.23192],
              [28.95199, 60.24479],
              [28.94997, 60.26274],
              [28.92931, 60.27059],
              [28.91126, 60.26151],
              [28.87993, 60.2722],
              [28.85049, 60.28925],
              [28.85057, 60.29974],
              [28.82636, 60.31415],
              [28.81361, 60.34453],
              [28.79647, 60.34297],
              [28.79747, 60.3189],
              [28.75004, 60.31979],
              [28.648, 60.35334],
              [28.61998, 60.35087],
              [28.58461, 60.37766],
              [28.58837, 60.39655],
              [28.57535, 60.40541],
              [28.56989, 60.42621],
              [28.51121, 60.46707],
              [28.48516, 60.50396],
              [28.441, 60.5436],
              [28.42365, 60.5745],
              [28.43963, 60.57126],
              [28.44406, 60.55406],
              [28.47731, 60.5537],
              [28.50453, 60.54111],
              [28.51489, 60.51258],
              [28.57276, 60.47493],
              [28.61226, 60.4664],
              [28.65194, 60.44134],
              [28.67993, 60.43177],
              [28.70286, 60.43456],
              [28.71305, 60.44789],
              [28.66599, 60.45467],
              [28.67691, 60.46214],
              [28.69356, 60.45027],
              [28.70728, 60.45478],
              [28.70059, 60.47146],
              [28.67272, 60.47282],
              [28.67344, 60.48227],
              [28.70099, 60.48628],
              [28.66168, 60.51585],
              [28.651722, 60.556078],
              [28.61524, 60.56954],
              [28.61503, 60.55546],
              [28.56535, 60.53988],
              [28.53824, 60.55721],
              [28.53261, 60.5729],
              [28.54662, 60.57358],
              [28.55134, 60.59112],
              [28.53486, 60.6034],
              [28.54717, 60.61254],
              [28.53074, 60.61759],
              [28.50934, 60.63882],
              [28.44426, 60.65182],
              [28.42093, 60.63697],
              [28.42431, 60.64858],
              [28.39871, 60.64719],
              [28.38989, 60.63602],
              [28.40399, 60.62487],
              [28.43625, 60.62401],
              [28.45023, 60.61644],
              [28.44421, 60.60397],
              [28.47153, 60.58679],
              [28.42947, 60.60041],
              [28.40269, 60.61926],
              [28.38643, 60.60406],
              [28.339, 60.6153],
              [28.32314, 60.60931],
              [28.3447, 60.59579],
              [28.31767, 60.59017],
              [28.29613, 60.59806],
              [28.29804, 60.58064],
              [28.25926, 60.56668],
              [28.24456, 60.5699],
              [28.22415, 60.55267],
              [28.23191, 60.51062],
              [28.19782, 60.52937],
              [28.15332, 60.53373],
              [28.15397, 60.51542],
              [28.17175, 60.51193],
              [28.13987, 60.50583],
              [28.1201, 60.51929],
              [28.07077, 60.51892],
              [28.08065, 60.50401],
              [28.04111, 60.50088],
              [28.04862, 60.50836],
              [28.02875, 60.52003],
              [28.00747, 60.52019],
              [28.01319, 60.50428],
              [27.99277, 60.49804],
              [27.95265, 60.50985],
              [27.93315, 60.49949],
              [27.94649, 60.49428],
              [27.93887, 60.48179],
              [27.9139, 60.47864],
              [27.8743, 60.4528],
              [27.84824, 60.46665],
              [27.80845, 60.47113],
              [27.80196, 60.48141],
              [27.77617, 60.48693],
              [27.774693, 60.533477],
              [27.814245, 60.55426],
              [27.847287, 60.561284],
              [27.838033, 60.577894],
              [27.873435, 60.60462],
              [28.015563, 60.682449],
              [28.088238, 60.720175],
              [28.135215, 60.740559],
              [28.173368, 60.778629],
              [28.220336, 60.782868],
              [28.255684, 60.810182],
              [28.31145, 60.840936],
              [28.3235, 60.858178],
              [28.335704, 60.854132],
              [28.464522, 60.922874],
              [28.483822, 60.929331],
              [28.52467, 60.957137],
              [28.572853, 60.951706],
              [28.61482, 60.955352],
              [28.649205, 60.948126],
              [28.683893, 60.978702],
              [28.710069, 61.026866],
              [28.713692, 61.044335],
              [28.807976, 61.096595],
              [28.818984, 61.121647],
              [28.883796, 61.141499],
              [28.958384, 61.151449],
              [28.98571, 61.173315],
              [29.029888, 61.191815],
              [29.164097, 61.237463],
              [29.23305, 61.268169],
              [29.265433, 61.291324],
              [29.305928, 61.329293],
            ],
          ],
          [
            [
              [27.74908, 60.468335],
              [27.760321, 60.478512],
              [27.79267, 60.46201],
              [27.79374, 60.44204],
              [27.76948, 60.44552],
              [27.74908, 60.468335],
            ],
          ],
          [
            [
              [28.41616, 60.36164],
              [28.46272, 60.37445],
              [28.50025, 60.3643],
              [28.4986, 60.37917],
              [28.47027, 60.42158],
              [28.43101, 60.44697],
              [28.43748, 60.4643],
              [28.45746, 60.45922],
              [28.49769, 60.43241],
              [28.51437, 60.41277],
              [28.50039, 60.40679],
              [28.53496, 60.37722],
              [28.52403, 60.36455],
              [28.5458, 60.35508],
              [28.57067, 60.35657],
              [28.58184, 60.34723],
              [28.61918, 60.33476],
              [28.63851, 60.33913],
              [28.62278, 60.32395],
              [28.6671, 60.30243],
              [28.71481, 60.29747],
              [28.72033, 60.28485],
              [28.69784, 60.27849],
              [28.69433, 60.26063],
              [28.61897, 60.26796],
              [28.55026, 60.29667],
              [28.53225, 60.31941],
              [28.52288, 60.30672],
              [28.47806, 60.31925],
              [28.46319, 60.33298],
              [28.4357, 60.34173],
              [28.41616, 60.36164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5516",
      properties: {
        name: "Липецкая область",
        density: 0,
        path: "/world/Russia/Липецкая область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.866469, 53.012217],
            [37.889671, 53.013689],
            [37.91186, 53.032074],
            [37.925037, 53.055766],
            [37.983985, 53.05072],
            [38.004592, 53.044903],
            [38.042653, 53.043572],
            [38.096484, 53.048689],
            [38.122278, 53.054437],
            [38.132302, 53.027872],
            [38.147231, 53.04112],
            [38.167629, 53.027732],
            [38.193108, 53.021284],
            [38.230784, 52.994509],
            [38.266145, 53.019743],
            [38.274206, 53.012033],
            [38.307781, 53.002641],
            [38.307922, 52.978249],
            [38.320013, 52.976286],
            [38.326953, 52.946357],
            [38.361859, 52.958133],
            [38.370654, 52.989955],
            [38.414743, 52.991218],
            [38.464473, 53.010354],
            [38.467696, 53.028438],
            [38.492193, 53.036499],
            [38.505372, 53.015121],
            [38.519951, 53.019677],
            [38.534846, 53.004607],
            [38.52952, 52.992201],
            [38.565863, 52.99108],
            [38.556188, 53.034327],
            [38.585733, 53.026898],
            [38.602554, 53.043019],
            [38.633851, 53.040707],
            [38.636444, 53.051641],
            [38.616309, 53.0692],
            [38.639947, 53.078276],
            [38.671049, 53.129568],
            [38.655015, 53.142342],
            [38.639594, 53.139047],
            [38.627397, 53.159514],
            [38.613098, 53.163789],
            [38.583309, 53.160284],
            [38.57756, 53.188462],
            [38.608331, 53.189934],
            [38.624066, 53.198836],
            [38.626449, 53.210962],
            [38.643444, 53.215751],
            [38.611308, 53.233041],
            [38.625536, 53.249583],
            [38.604789, 53.251055],
            [38.582534, 53.263321],
            [38.569707, 53.255891],
            [38.564519, 53.278811],
            [38.55001, 53.279652],
            [38.567743, 53.293881],
            [38.528911, 53.302222],
            [38.547905, 53.327596],
            [38.580113, 53.333204],
            [38.624095, 53.353601],
            [38.641408, 53.356055],
            [38.661069, 53.342387],
            [38.689001, 53.358859],
            [38.763755, 53.357529],
            [38.77178, 53.364328],
            [38.765961, 53.398393],
            [38.753059, 53.417301],
            [38.738273, 53.424677],
            [38.725936, 53.443112],
            [38.725655, 53.463999],
            [38.745666, 53.467224],
            [38.757371, 53.479631],
            [38.721903, 53.497293],
            [38.722323, 53.528485],
            [38.769635, 53.541593],
            [38.784634, 53.550074],
            [38.810569, 53.549374],
            [38.866923, 53.554702],
            [38.885779, 53.530029],
            [38.900358, 53.535917],
            [38.906875, 53.5758],
            [38.937824, 53.574779],
            [38.960552, 53.561922],
            [38.999304, 53.570438],
            [39.011885, 53.564808],
            [39.054738, 53.578467],
            [39.087757, 53.554487],
            [39.101349, 53.509131],
            [39.121785, 53.508227],
            [39.136001, 53.493178],
            [39.130579, 53.483516],
            [39.152511, 53.458597],
            [39.138957, 53.445945],
            [39.170063, 53.44035],
            [39.163113, 53.426378],
            [39.17771, 53.423042],
            [39.17434, 53.408271],
            [39.189494, 53.392562],
            [39.207671, 53.389399],
            [39.207253, 53.403232],
            [39.235545, 53.394821],
            [39.265399, 53.405075],
            [39.288825, 53.402225],
            [39.312215, 53.412409],
            [39.328203, 53.402817],
            [39.32723, 53.390583],
            [39.357224, 53.393885],
            [39.364939, 53.402226],
            [39.385237, 53.396874],
            [39.438864, 53.409769],
            [39.457146, 53.400803],
            [39.468789, 53.413489],
            [39.47282, 53.43323],
            [39.520852, 53.432674],
            [39.53677, 53.418147],
            [39.559951, 53.442997],
            [39.555188, 53.48738],
            [39.568019, 53.506398],
            [39.54827, 53.524812],
            [39.555012, 53.529122],
            [39.59453, 53.523596],
            [39.612394, 53.532564],
            [39.663588, 53.545806],
            [39.681487, 53.558006],
            [39.753604, 53.585429],
            [39.750893, 53.578547],
            [39.773415, 53.571805],
            [39.784642, 53.554393],
            [39.816269, 53.556409],
            [39.808553, 53.566349],
            [39.837296, 53.566454],
            [39.860687, 53.547582],
            [39.885294, 53.549667],
            [39.894192, 53.541048],
            [39.890821, 53.524539],
            [39.932111, 53.513974],
            [39.943271, 53.49666],
            [39.942678, 53.480192],
            [39.954148, 53.467193],
            [39.923286, 53.445088],
            [39.923704, 53.435357],
            [39.989114, 53.438347],
            [39.982441, 53.425695],
            [39.993147, 53.399559],
            [40.038051, 53.403314],
            [40.061825, 53.38538],
            [40.111803, 53.380863],
            [40.127373, 53.392541],
            [40.142638, 53.381264],
            [40.121879, 53.374685],
            [40.13824, 53.354215],
            [40.155853, 53.370092],
            [40.173669, 53.353893],
            [40.17937, 53.332623],
            [40.169999, 53.312082],
            [40.157788, 53.312512],
            [40.152171, 53.295393],
            [40.123552, 53.294858],
            [40.113255, 53.275599],
            [40.095531, 53.275049],
            [40.103159, 53.221962],
            [40.087045, 53.213545],
            [40.108309, 53.211004],
            [40.125995, 53.173678],
            [40.120603, 53.144519],
            [40.139899, 53.137839],
            [40.133309, 53.111383],
            [40.155331, 53.046237],
            [40.125435, 53.022167],
            [40.074883, 53.004513],
            [40.116075, 52.989535],
            [40.12303, 52.977098],
            [40.114337, 52.959979],
            [40.124769, 52.957037],
            [40.122762, 52.939562],
            [40.078155, 52.946872],
            [40.072345, 52.924672],
            [40.037574, 52.912903],
            [40.037174, 52.894046],
            [40.095751, 52.879469],
            [40.092676, 52.858606],
            [40.094343, 52.814679],
            [40.090443, 52.783203],
            [40.064398, 52.784916],
            [40.083271, 52.754594],
            [40.058042, 52.752782],
            [40.051221, 52.770874],
            [40.023805, 52.772478],
            [40.050286, 52.751214],
            [40.02394, 52.737974],
            [39.990104, 52.739311],
            [39.97932, 52.753208],
            [39.960682, 52.7468],
            [39.97205, 52.732356],
            [39.960368, 52.727158],
            [39.940488, 52.734094],
            [39.915694, 52.708851],
            [39.938758, 52.708237],
            [39.931449, 52.699224],
            [39.987347, 52.677187],
            [39.982138, 52.655944],
            [39.960238, 52.668856],
            [39.945499, 52.657239],
            [39.955811, 52.650025],
            [39.949503, 52.63353],
            [39.971531, 52.624179],
            [39.976678, 52.596802],
            [40.043619, 52.589945],
            [40.019627, 52.564738],
            [40.046364, 52.545786],
            [40.063483, 52.562392],
            [40.114642, 52.540056],
            [40.124275, 52.551432],
            [40.145986, 52.534698],
            [40.155118, 52.517492],
            [40.173294, 52.502199],
            [40.226615, 52.500961],
            [40.214266, 52.467058],
            [40.2507, 52.464872],
            [40.272276, 52.453063],
            [40.325873, 52.458656],
            [40.330291, 52.44204],
            [40.296317, 52.441296],
            [40.294096, 52.425315],
            [40.281917, 52.415982],
            [40.293363, 52.391964],
            [40.308935, 52.38608],
            [40.355599, 52.383839],
            [40.354691, 52.366161],
            [40.376023, 52.360889],
            [40.399375, 52.367148],
            [40.512791, 52.354903],
            [40.503468, 52.346002],
            [40.505614, 52.328857],
            [40.529884, 52.336903],
            [40.563586, 52.332022],
            [40.573082, 52.314101],
            [40.572754, 52.276909],
            [40.590336, 52.257397],
            [40.601703, 52.262345],
            [40.611198, 52.281068],
            [40.660279, 52.285884],
            [40.733435, 52.251247],
            [40.741357, 52.22519],
            [40.733335, 52.224173],
            [40.764775, 52.201607],
            [40.744072, 52.19361],
            [40.709958, 52.223105],
            [40.696304, 52.229732],
            [40.684615, 52.202932],
            [40.701571, 52.188767],
            [40.66195, 52.190266],
            [40.637032, 52.174902],
            [40.655454, 52.164534],
            [40.642537, 52.158497],
            [40.647262, 52.135595],
            [40.625935, 52.144561],
            [40.611258, 52.103932],
            [40.590303, 52.091979],
            [40.626478, 52.088901],
            [40.614188, 52.052097],
            [40.644101, 52.053124],
            [40.641422, 52.036222],
            [40.606981, 52.033557],
            [40.594375, 52.024003],
            [40.536676, 52.008933],
            [40.545245, 51.997171],
            [40.531723, 51.967316],
            [40.540536, 51.964565],
            [40.53414, 51.945692],
            [40.508554, 51.935072],
            [40.46156, 51.950945],
            [40.460954, 51.96042],
            [40.441414, 51.960047],
            [40.433532, 51.949337],
            [40.39621, 51.947504],
            [40.386047, 51.957373],
            [40.354018, 51.965474],
            [40.328304, 51.961849],
            [40.31161, 51.937502],
            [40.300997, 51.947372],
            [40.273819, 51.949144],
            [40.249022, 51.968522],
            [40.221411, 51.975988],
            [40.183102, 51.971246],
            [40.162126, 51.959674],
            [40.141441, 51.968883],
            [40.127123, 51.985584],
            [40.132234, 51.999536],
            [40.075816, 51.992008],
            [40.081112, 51.97195],
            [40.042467, 51.952219],
            [40.018867, 51.953321],
            [39.957253, 51.966567],
            [39.945121, 51.972976],
            [39.927212, 51.967388],
            [39.913043, 51.982835],
            [39.892448, 51.960777],
            [39.897672, 51.951036],
            [39.877509, 51.944511],
            [39.875222, 51.93448],
            [39.840761, 51.921077],
            [39.832696, 51.927747],
            [39.816124, 51.920291],
            [39.798471, 51.925889],
            [39.778475, 51.942858],
            [39.7599, 51.927417],
            [39.484369, 51.977717],
            [39.500289, 52.016721],
            [39.515367, 52.022596],
            [39.49482, 52.042394],
            [39.501846, 52.045357],
            [39.473172, 52.073549],
            [39.46203, 52.068769],
            [39.448771, 52.07922],
            [39.419065, 52.074318],
            [39.385301, 52.084282],
            [39.33668, 52.086107],
            [39.343374, 52.098697],
            [39.299772, 52.103184],
            [39.265585, 52.102221],
            [39.263975, 52.095548],
            [39.233245, 52.079427],
            [39.179071, 52.090032],
            [39.145478, 52.082697],
            [39.175524, 52.06476],
            [39.166478, 52.055658],
            [39.103758, 52.050553],
            [39.087042, 52.034477],
            [39.103682, 51.993397],
            [39.073572, 51.98316],
            [39.015011, 51.984812],
            [39.008675, 51.997103],
            [38.977003, 52.025529],
            [38.908748, 52.07592],
            [38.901691, 52.087083],
            [38.877206, 52.09655],
            [38.827308, 52.092962],
            [38.800134, 52.075047],
            [38.784269, 52.052355],
            [38.810615, 52.045874],
            [38.786778, 52.029204],
            [38.741764, 52.041692],
            [38.723439, 52.064442],
            [38.72398, 52.078903],
            [38.706769, 52.098242],
            [38.669927, 52.082876],
            [38.678184, 52.0642],
            [38.624604, 52.039113],
            [38.600616, 52.05403],
            [38.555547, 52.055531],
            [38.547496, 52.047847],
            [38.500885, 52.033109],
            [38.481296, 52.017863],
            [38.433678, 52.004334],
            [38.394868, 52.00148],
            [38.399619, 51.987485],
            [38.32064, 51.983608],
            [38.292611, 51.96594],
            [38.280144, 51.96649],
            [38.245313, 51.940699],
            [38.24195, 51.922897],
            [38.19177, 51.930185],
            [38.18343, 51.918271],
            [38.171796, 51.931586],
            [38.137314, 51.929904],
            [38.127502, 51.93523],
            [38.111944, 51.923736],
            [38.121926, 51.91446],
            [38.083911, 51.904393],
            [38.047047, 51.90257],
            [38.051393, 51.88645],
            [37.993854, 51.894579],
            [37.922719, 51.891558],
            [37.918793, 51.913361],
            [37.900431, 51.91322],
            [37.897348, 51.901306],
            [37.867912, 51.900885],
            [37.854036, 51.908454],
            [37.855297, 51.923312],
            [37.831257, 51.944337],
            [37.821724, 51.975734],
            [37.794812, 51.981341],
            [37.782756, 52.004608],
            [37.738268, 52.01703],
            [37.758678, 52.027341],
            [37.750635, 52.057355],
            [37.771341, 52.059382],
            [37.757656, 52.074111],
            [37.774726, 52.097642],
            [37.787743, 52.098895],
            [37.779424, 52.119075],
            [37.795024, 52.121745],
            [37.791854, 52.146957],
            [37.772478, 52.146956],
            [37.760546, 52.163008],
            [37.800972, 52.169246],
            [37.827819, 52.191409],
            [37.839406, 52.20895],
            [37.865115, 52.212623],
            [37.883099, 52.206671],
            [37.886771, 52.22782],
            [37.939707, 52.241245],
            [37.956677, 52.249478],
            [37.984032, 52.252771],
            [38.012591, 52.236751],
            [38.039122, 52.24511],
            [38.036842, 52.260307],
            [38.052292, 52.269805],
            [38.013349, 52.282533],
            [38.057294, 52.297414],
            [38.043235, 52.321729],
            [38.065904, 52.330215],
            [38.057165, 52.339016],
            [38.026011, 52.344145],
            [37.996249, 52.360798],
            [37.988079, 52.383278],
            [37.993588, 52.403097],
            [37.955658, 52.398538],
            [37.958899, 52.389912],
            [37.928458, 52.403076],
            [37.88253, 52.436371],
            [37.892841, 52.442419],
            [37.932924, 52.446156],
            [37.930833, 52.472308],
            [37.901641, 52.47674],
            [37.883531, 52.485731],
            [37.850554, 52.49286],
            [37.81189, 52.491234],
            [37.789985, 52.497305],
            [37.802905, 52.513903],
            [37.824751, 52.519233],
            [37.820162, 52.528973],
            [37.860276, 52.536041],
            [37.84557, 52.553715],
            [37.822703, 52.545695],
            [37.810337, 52.575063],
            [37.786606, 52.57044],
            [37.775183, 52.58957],
            [37.722169, 52.594846],
            [37.730377, 52.609947],
            [37.743054, 52.601126],
            [37.770786, 52.613067],
            [37.786233, 52.629249],
            [37.804548, 52.622015],
            [37.824277, 52.632034],
            [37.82653, 52.656935],
            [37.79405, 52.656636],
            [37.821211, 52.673045],
            [37.810331, 52.708182],
            [37.859553, 52.733537],
            [37.891259, 52.740489],
            [37.891347, 52.771149],
            [37.90025, 52.78118],
            [37.884592, 52.814333],
            [37.836651, 52.823843],
            [37.838143, 52.828461],
            [37.801613, 52.853207],
            [37.842658, 52.868786],
            [37.834043, 52.878168],
            [37.876029, 52.886328],
            [37.856372, 52.891931],
            [37.838335, 52.906478],
            [37.85006, 52.935792],
            [37.831905, 52.949628],
            [37.875195, 52.993481],
            [37.866469, 53.012217],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5473",
      properties: {
        name: "Магаданская область",
        density: 0,
        path: "/world/Russia/Магаданская область",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [150.40425, 59.57483],
              [150.37253, 59.573813],
              [150.37175, 59.583784],
              [150.421901, 59.598095],
              [150.40425, 59.57483],
            ],
          ],
          [
            [
              [150.46875, 59.001261],
              [150.500844, 59.040151],
              [150.530175, 59.060686],
              [150.6196, 59.090269],
              [150.645645, 59.087799],
              [150.655373, 59.098346],
              [150.685698, 59.1099],
              [150.68786, 59.133238],
              [150.705467, 59.145585],
              [150.729104, 59.12731],
              [150.773649, 59.116303],
              [150.782084, 59.093661],
              [150.715026, 59.069453],
              [150.685696, 59.047169],
              [150.64317, 59.022334],
              [150.604419, 59.004724],
              [150.555553, 58.997409],
              [150.46875, 59.001261],
            ],
          ],
          [
            [
              [148.98351, 59.112997],
              [148.958729, 59.122108],
              [148.957885, 59.138296],
              [148.97848, 59.137052],
              [148.985127, 59.157084],
              [148.999516, 59.160191],
              [149.018386, 59.14851],
              [149.003682, 59.136458],
              [149.0169, 59.119986],
              [148.98351, 59.112997],
            ],
          ],
          [
            [
              [155.516367, 59.205489],
              [155.538412, 59.213254],
              [155.530642, 59.195994],
              [155.516367, 59.205489],
            ],
          ],
          [
            [
              [149.068838, 59.317325],
              [149.097306, 59.307909],
              [149.083535, 59.303778],
              [149.068838, 59.317325],
            ],
          ],
          [
            [
              [155.514073, 59.323575],
              [155.548333, 59.330603],
              [155.590208, 59.331353],
              [155.594053, 59.322179],
              [155.514073, 59.323575],
            ],
          ],
          [
            [
              [149.059943, 59.161935],
              [149.030211, 59.156701],
              [149.034558, 59.187809],
              [149.04658, 59.205286],
              [149.071051, 59.197217],
              [149.109795, 59.210419],
              [149.138756, 59.210458],
              [149.129597, 59.191663],
              [149.099241, 59.172699],
              [149.099672, 59.163475],
              [149.059943, 59.161935],
            ],
          ],
          [
            [
              [158.543444, 66.335235],
              [158.601593, 66.339138],
              [158.644111, 66.336957],
              [158.688511, 66.319563],
              [158.7231, 66.315994],
              [158.7172, 66.294388],
              [158.74961, 66.288937],
              [158.780927, 66.275854],
              [158.783008, 66.265943],
              [158.90159, 66.265099],
              [158.92082, 66.253602],
              [158.945251, 66.252313],
              [158.981326, 66.239923],
              [159.039207, 66.247554],
              [159.06012, 66.231845],
              [159.115768, 66.226393],
              [159.229895, 66.236302],
              [159.215522, 66.216233],
              [159.246046, 66.192049],
              [159.239304, 66.173615],
              [159.260315, 66.1641],
              [159.247032, 66.142494],
              [159.209964, 66.138134],
              [159.217297, 66.125844],
              [159.18766, 66.114051],
              [159.187858, 66.09304],
              [159.16506, 66.069056],
              [159.132452, 66.061722],
              [159.097365, 66.045072],
              [159.10886, 66.024259],
              [159.054151, 66.019304],
              [159.02818, 65.987788],
              [159.048993, 65.978372],
              [159.024808, 65.961127],
              [159.02491, 65.947549],
              [158.973861, 65.91306],
              [158.976833, 65.901563],
              [158.93302, 65.892842],
              [158.931439, 65.882535],
              [158.960973, 65.871137],
              [158.923706, 65.862217],
              [158.924102, 65.851117],
              [158.950265, 65.831295],
              [158.900411, 65.81742],
              [158.9345, 65.763504],
              [158.909324, 65.744476],
              [158.951841, 65.72723],
              [158.998422, 65.723661],
              [159.026768, 65.726832],
              [159.068095, 65.703937],
              [159.100207, 65.704729],
              [159.142625, 65.687286],
              [159.208236, 65.680941],
              [159.227461, 65.659137],
              [159.284153, 65.665677],
              [159.41686, 65.644664],
              [159.448378, 65.648628],
              [159.450557, 65.638717],
              [159.48624, 65.637725],
              [159.51706, 65.619687],
              [159.566812, 65.620281],
              [159.587426, 65.613937],
              [159.581082, 65.597683],
              [159.645702, 65.594907],
              [159.728953, 65.575282],
              [159.752242, 65.556748],
              [159.805562, 65.53643],
              [159.811506, 65.511256],
              [159.854916, 65.51185],
              [159.91646, 65.522949],
              [159.940448, 65.506298],
              [159.999718, 65.519182],
              [160.038371, 65.520172],
              [160.04947, 65.510658],
              [160.092682, 65.502233],
              [160.12717, 65.478644],
              [160.158291, 65.477652],
              [160.194366, 65.46556],
              [160.189607, 65.448712],
              [160.240945, 65.432953],
              [160.243718, 65.417293],
              [160.204271, 65.404806],
              [160.212198, 65.382605],
              [160.19515, 65.377452],
              [160.233801, 65.351485],
              [160.284149, 65.343654],
              [160.299608, 65.325418],
              [160.32815, 65.321453],
              [160.326762, 65.2939],
              [160.38484, 65.290827],
              [160.391776, 65.274969],
              [160.41596, 65.27596],
              [160.434887, 65.250092],
              [160.456492, 65.238001],
              [160.439345, 65.224423],
              [160.461942, 65.22244],
              [160.475023, 65.210547],
              [160.512288, 65.20341],
              [160.501978, 65.178038],
              [160.53399, 65.176452],
              [160.569471, 65.162774],
              [160.599403, 65.167333],
              [160.64299, 65.152034],
              [160.736273, 65.148896],
              [160.760952, 65.155834],
              [160.868287, 65.143344],
              [160.943811, 65.164156],
              [160.952335, 65.161381],
              [161.01879, 65.178427],
              [161.037076, 65.177931],
              [161.114876, 65.158504],
              [161.144857, 65.165639],
              [161.21562, 65.147204],
              [161.283016, 65.153645],
              [161.361311, 65.13511],
              [161.381132, 65.121879],
              [161.406157, 65.118955],
              [161.418347, 65.104782],
              [161.406353, 65.09492],
              [161.4732, 65.064245],
              [161.518394, 65.050072],
              [161.547829, 65.050071],
              [161.589752, 65.036839],
              [161.619584, 65.038623],
              [161.718085, 65.003001],
              [161.744508, 64.982971],
              [161.753822, 64.958788],
              [161.74044, 64.938916],
              [161.758229, 64.922365],
              [161.754363, 64.910868],
              [161.814817, 64.877566],
              [161.82403, 64.854969],
              [161.798907, 64.84045],
              [161.848113, 64.824641],
              [161.88924, 64.827366],
              [161.911048, 64.81577],
              [161.956043, 64.812598],
              [162.020563, 64.79882],
              [162.088255, 64.795053],
              [162.141922, 64.782168],
              [162.224432, 64.785933],
              [162.250547, 64.777211],
              [162.264322, 64.763434],
              [162.307186, 64.754514],
              [162.318979, 64.743661],
              [162.422304, 64.762094],
              [162.48791, 64.753074],
              [162.534199, 64.758425],
              [162.578103, 64.74217],
              [162.650107, 64.74098],
              [162.676368, 64.708471],
              [162.675771, 64.685577],
              [162.711153, 64.684437],
              [162.75248, 64.662186],
              [162.86829, 64.657724],
              [162.922009, 64.66258],
              [162.9619, 64.656831],
              [163.001644, 64.663668],
              [163.071021, 64.662974],
              [163.108883, 64.681358],
              [163.148477, 64.683835],
              [163.230641, 64.696866],
              [163.264824, 64.693339],
              [163.2429, 64.672145],
              [163.244945, 64.66103],
              [163.216327, 64.645765],
              [163.211855, 64.633117],
              [163.192309, 64.636822],
              [163.17736, 64.619065],
              [163.203803, 64.591471],
              [163.171991, 64.574481],
              [163.187064, 64.558575],
              [163.220918, 64.550143],
              [163.192556, 64.536858],
              [163.212101, 64.535069],
              [163.235095, 64.512329],
              [163.272142, 64.503258],
              [163.299608, 64.503513],
              [163.362332, 64.474896],
              [163.354154, 64.457841],
              [163.373188, 64.447046],
              [163.414452, 64.445768],
              [163.407294, 64.409998],
              [163.426456, 64.399905],
              [163.39554, 64.393071],
              [163.38979, 64.377422],
              [163.422366, 64.37065],
              [163.469251, 64.377293],
              [163.49569, 64.35813],
              [163.44472, 64.342928],
              [163.4101, 64.350977],
              [163.372029, 64.343313],
              [163.347243, 64.319424],
              [163.353246, 64.29694],
              [163.272763, 64.301284],
              [163.255132, 64.292853],
              [163.253215, 64.277843],
              [163.277104, 64.270049],
              [163.220891, 64.252932],
              [163.216418, 64.225082],
              [163.22881, 64.20477],
              [163.200444, 64.170214],
              [163.179747, 64.157311],
              [163.14385, 64.157184],
              [163.14347, 64.167021],
              [163.0672, 64.166575],
              [163.055575, 64.18331],
              [163.02683, 64.17654],
              [162.990678, 64.185611],
              [162.961805, 64.170473],
              [162.907382, 64.161915],
              [162.867396, 64.162682],
              [162.84759, 64.150418],
              [162.856024, 64.138282],
              [162.81789, 64.139108],
              [162.808538, 64.129553],
              [162.818715, 64.115013],
              [162.80189, 64.103381],
              [162.82349, 64.090502],
              [162.851947, 64.087386],
              [162.836575, 64.069731],
              [162.848621, 64.052074],
              [162.823483, 64.008662],
              [162.91017, 63.980244],
              [162.932787, 63.966553],
              [162.93219, 63.941158],
              [162.95421, 63.922905],
              [162.90342, 63.897907],
              [162.906868, 63.886797],
              [162.939724, 63.877074],
              [162.940992, 63.866758],
              [162.87901, 63.875488],
              [162.8435, 63.849299],
              [162.78219, 63.851582],
              [162.757271, 63.843031],
              [162.744292, 63.824898],
              [162.723658, 63.819938],
              [162.724053, 63.795534],
              [162.711949, 63.785416],
              [162.757185, 63.784027],
              [162.725636, 63.752084],
              [162.768887, 63.736013],
              [162.77769, 63.724776],
              [162.807972, 63.729267],
              [162.856778, 63.722917],
              [162.875229, 63.714187],
              [162.884552, 63.689288],
              [162.860147, 63.672424],
              [162.892683, 63.645441],
              [162.925816, 63.648019],
              [162.965298, 63.639487],
              [162.996049, 63.624408],
              [162.97402, 63.580562],
              [162.963901, 63.545346],
              [162.974218, 63.541179],
              [162.948028, 63.528581],
              [162.945844, 63.513503],
              [162.961319, 63.503979],
              [162.971038, 63.480369],
              [162.933736, 63.447633],
              [162.910722, 63.454181],
              [162.9008, 63.43573],
              [162.871833, 63.431564],
              [162.876593, 63.415096],
              [162.82481, 63.41728],
              [162.82104, 63.406169],
              [162.774415, 63.40002],
              [162.758541, 63.383453],
              [162.710527, 63.381272],
              [162.65821, 63.38458],
              [162.656031, 63.365136],
              [162.676994, 63.344965],
              [162.719849, 63.343773],
              [162.73949, 63.327901],
              [162.766997, 63.323496],
              [162.784129, 63.306274],
              [162.760518, 63.291593],
              [162.762699, 63.278895],
              [162.735715, 63.265603],
              [162.778369, 63.229295],
              [162.778566, 63.213621],
              [162.762297, 63.206677],
              [162.781144, 63.194177],
              [162.734517, 63.160847],
              [162.697812, 63.166998],
              [162.579563, 63.1546],
              [162.597219, 63.12345],
              [162.578171, 63.111745],
              [162.511309, 63.112639],
              [162.452978, 63.099347],
              [162.438891, 63.091411],
              [162.379768, 63.108078],
              [162.362308, 63.098852],
              [162.358338, 63.08298],
              [162.304371, 63.06453],
              [162.29187, 63.0434],
              [162.320836, 63.030107],
              [162.281749, 63.005307],
              [162.289286, 62.971876],
              [162.269445, 62.96652],
              [162.25655, 62.934577],
              [162.279558, 62.903328],
              [162.304656, 62.900154],
              [162.323404, 62.887158],
              [162.373302, 62.875848],
              [162.41447, 62.874012],
              [162.440857, 62.858785],
              [162.47766, 62.85055],
              [162.48292, 62.837704],
              [162.533806, 62.833636],
              [162.544025, 62.839786],
              [162.564559, 62.830213],
              [162.57269, 62.809281],
              [162.606717, 62.798964],
              [162.62854, 62.778082],
              [162.576062, 62.773122],
              [162.557707, 62.744999],
              [162.58707, 62.739939],
              [162.609587, 62.713452],
              [162.626848, 62.714047],
              [162.662757, 62.689991],
              [162.711664, 62.693759],
              [162.715037, 62.704374],
              [162.759282, 62.70596],
              [162.777435, 62.697826],
              [162.805906, 62.699908],
              [162.82019, 62.690682],
              [162.827429, 62.66117],
              [162.80025, 62.63513],
              [162.800543, 62.620449],
              [162.766021, 62.621739],
              [162.750445, 62.607156],
              [162.704812, 62.596047],
              [162.68119, 62.571277],
              [162.77087, 62.46763],
              [162.781384, 62.450964],
              [162.760946, 62.420906],
              [162.779496, 62.408258],
              [162.773343, 62.367189],
              [162.791595, 62.354293],
              [162.777507, 62.343182],
              [162.786235, 62.311785],
              [162.748636, 62.293334],
              [162.735341, 62.265161],
              [162.690402, 62.252861],
              [162.66471, 62.25683],
              [162.617191, 62.251672],
              [162.595765, 62.272306],
              [162.568583, 62.263428],
              [162.573146, 62.255393],
              [162.534257, 62.241356],
              [162.503107, 62.237686],
              [162.445519, 62.240936],
              [162.439616, 62.233198],
              [162.397505, 62.238953],
              [162.388178, 62.22318],
              [162.332028, 62.205126],
              [162.306035, 62.182111],
              [162.260402, 62.200762],
              [162.174844, 62.167318],
              [162.168011, 62.149184],
              [162.220481, 62.140588],
              [162.248885, 62.123752],
              [162.290853, 62.113858],
              [162.289735, 62.103804],
              [162.365535, 62.108591],
              [162.390268, 62.095744],
              [162.456812, 62.086966],
              [162.480589, 62.089679],
              [162.524472, 62.083215],
              [162.511066, 62.065981],
              [162.527501, 62.03598],
              [162.514096, 62.035981],
              [162.53803, 62.019703],
              [162.532604, 61.995927],
              [162.484731, 61.998002],
              [162.508666, 61.976698],
              [162.491751, 61.969836],
              [162.49143, 61.954996],
              [162.443875, 61.942071],
              [162.40079, 61.951327],
              [162.369514, 61.963934],
              [162.343821, 61.960504],
              [162.322277, 61.946142],
              [162.298341, 61.944227],
              [162.290041, 61.926275],
              [162.332169, 61.910476],
              [162.3427, 61.89779],
              [162.376211, 61.895874],
              [162.36887, 61.883428],
              [162.383709, 61.866512],
              [162.37174, 61.858055],
              [162.38642, 61.825581],
              [162.402696, 61.829251],
              [162.432536, 61.808665],
              [162.474504, 61.799887],
              [162.423597, 61.773079],
              [162.39184, 61.763266],
              [162.424074, 61.751297],
              [162.454713, 61.75018],
              [162.464925, 61.734541],
              [162.447849, 61.718583],
              [162.443698, 61.689541],
              [162.465934, 61.664844],
              [162.424883, 61.672673],
              [162.386918, 61.662994],
              [162.384132, 61.638622],
              [162.35357, 61.612178],
              [162.311628, 61.612255],
              [162.300119, 61.605501],
              [162.332259, 61.597428],
              [162.328782, 61.587888],
              [162.277549, 61.571772],
              [162.254278, 61.549545],
              [162.211711, 61.557514],
              [162.226086, 61.540783],
              [162.22816, 61.517528],
              [162.174608, 61.505164],
              [162.169236, 61.493531],
              [162.133848, 61.482295],
              [162.137412, 61.472641],
              [162.09879, 61.453389],
              [162.098396, 61.437507],
              [162.055283, 61.435428],
              [162.05909, 61.417717],
              [162.02923, 61.411435],
              [162.007348, 61.383303],
              [161.98486, 61.372947],
              [161.916674, 61.377179],
              [161.916862, 61.391523],
              [161.897062, 61.410789],
              [161.902458, 61.418337],
              [161.875221, 61.426164],
              [161.89166, 61.371104],
              [161.915192, 61.359733],
              [161.908064, 61.345488],
              [161.872814, 61.330086],
              [161.847802, 61.345639],
              [161.821519, 61.373451],
              [161.799937, 61.362701],
              [161.810754, 61.345543],
              [161.791376, 61.334331],
              [161.799442, 61.312755],
              [161.78367, 61.300347],
              [161.668037, 61.281166],
              [161.652714, 61.255179],
              [161.627507, 61.259676],
              [161.594767, 61.245284],
              [161.602876, 61.214499],
              [161.578092, 61.203118],
              [161.58026, 61.188063],
              [161.56526, 61.184481],
              [161.520399, 61.196781],
              [161.478891, 61.197757],
              [161.456847, 61.167214],
              [161.41855, 61.158905],
              [161.409864, 61.151376],
              [161.456645, 61.135962],
              [161.44583, 61.11903],
              [161.401285, 61.115033],
              [161.308971, 61.057725],
              [161.238195, 61.048469],
              [161.206724, 61.048741],
              [161.217729, 61.029581],
              [161.181975, 61.012947],
              [161.17611, 60.98934],
              [161.02041, 60.939233],
              [160.985564, 60.911852],
              [160.954624, 60.900147],
              [160.910054, 60.897332],
              [160.913632, 60.873734],
              [160.905571, 60.861819],
              [160.863656, 60.845002],
              [160.86878, 60.833925],
              [160.846335, 60.812992],
              [160.830183, 60.773285],
              [160.82898, 60.752185],
              [160.811219, 60.752555],
              [160.79485, 60.740489],
              [160.795222, 60.718985],
              [160.740462, 60.730456],
              [160.713471, 60.753583],
              [160.677574, 60.757235],
              [160.65783, 60.749675],
              [160.63301, 60.71402],
              [160.60839, 60.712655],
              [160.574996, 60.724057],
              [160.570548, 60.739976],
              [160.545973, 60.739878],
              [160.518876, 60.719585],
              [160.500529, 60.718257],
              [160.495247, 60.705775],
              [160.4619, 60.695906],
              [160.404053, 60.704202],
              [160.402444, 60.715224],
              [160.43339, 60.735626],
              [160.436062, 60.74865],
              [160.38641, 60.731002],
              [160.377721, 60.717271],
              [160.382542, 60.695725],
              [160.367758, 60.667213],
              [160.338957, 60.651893],
              [160.31659, 60.653139],
              [160.28687, 60.633466],
              [160.295494, 60.621099],
              [160.26453, 60.601689],
              [160.246296, 60.601349],
              [160.212826, 60.58323],
              [160.148024, 60.574846],
              [160.137071, 60.588759],
              [160.147443, 60.624498],
              [160.159282, 60.64231],
              [160.144618, 60.654975],
              [160.164364, 60.667601],
              [160.204238, 60.659954],
              [160.22034, 60.672092],
              [160.202604, 60.700019],
              [160.231227, 60.711275],
              [160.251326, 60.73796],
              [160.242574, 60.754932],
              [160.25055, 60.771748],
              [160.238851, 60.787211],
              [160.180377, 60.819982],
              [160.207403, 60.827931],
              [160.217477, 60.84473],
              [160.233325, 60.834093],
              [160.248198, 60.839074],
              [160.243449, 60.878783],
              [160.26684, 60.886243],
              [160.268484, 60.90107],
              [160.301622, 60.902033],
              [160.350378, 60.918187],
              [160.34858, 60.956463],
              [160.368477, 60.954088],
              [160.394891, 60.980431],
              [160.424121, 60.986268],
              [160.414306, 61.002494],
              [160.396909, 61.002419],
              [160.4234, 61.017241],
              [160.428963, 61.034602],
              [160.384615, 61.03479],
              [160.372827, 61.042682],
              [160.353315, 61.030757],
              [160.331749, 61.032251],
              [160.281136, 61.019946],
              [160.209041, 61.037756],
              [160.181576, 61.036976],
              [160.16701, 61.028146],
              [160.087322, 61.011667],
              [160.044694, 60.981808],
              [160.01886, 60.980982],
              [160.012702, 60.969514],
              [159.980224, 60.957727],
              [159.98052, 60.96732],
              [159.951918, 60.961512],
              [159.949718, 60.940672],
              [159.900392, 60.944849],
              [159.889924, 60.927903],
              [159.903871, 60.919109],
              [159.884163, 60.909772],
              [159.890069, 60.895404],
              [159.861323, 60.900708],
              [159.879562, 60.914408],
              [159.865638, 60.924222],
              [159.829579, 60.932509],
              [159.78751, 60.932634],
              [159.829178, 60.947482],
              [159.840279, 60.969419],
              [159.829944, 60.976904],
              [159.863638, 60.986288],
              [159.883162, 61.006505],
              [159.927899, 61.022325],
              [159.941987, 61.043978],
              [159.965886, 61.057627],
              [159.954456, 61.07255],
              [159.96785, 61.087552],
              [159.99375, 61.096064],
              [160.001731, 61.114429],
              [159.97033, 61.142549],
              [159.984314, 61.149613],
              [159.970325, 61.176838],
              [159.916447, 61.210222],
              [159.947559, 61.22165],
              [159.939222, 61.24461],
              [159.89347, 61.260399],
              [159.858888, 61.258831],
              [159.825279, 61.233325],
              [159.805286, 61.240354],
              [159.768085, 61.239034],
              [159.798184, 61.253445],
              [159.775496, 61.259718],
              [159.820356, 61.268537],
              [159.81716, 61.288899],
              [159.906633, 61.293192],
              [159.918494, 61.280446],
              [159.960784, 61.272477],
              [159.9861, 61.283171],
              [159.933777, 61.293776],
              [159.917788, 61.3109],
              [159.881825, 61.318943],
              [159.896418, 61.326651],
              [159.90518, 61.316614],
              [159.973757, 61.339553],
              [159.975281, 61.362827],
              [159.956544, 61.377673],
              [160.003092, 61.382327],
              [160.034195, 61.37928],
              [160.063523, 61.402595],
              [160.092143, 61.412091],
              [160.107891, 61.442158],
              [160.087108, 61.451537],
              [160.029378, 61.451729],
              [160.057173, 61.467381],
              [160.111973, 61.489206],
              [160.117796, 61.502336],
              [160.20323, 61.509926],
              [160.224791, 61.520225],
              [160.224936, 61.530715],
              [160.255455, 61.536579],
              [160.279156, 61.558877],
              [160.245348, 61.571983],
              [160.245984, 61.587842],
              [160.274641, 61.605746],
              [160.26087, 61.623785],
              [160.31059, 61.642765],
              [160.322113, 61.666963],
              [160.302602, 61.683014],
              [160.258273, 61.692256],
              [160.31563, 61.710781],
              [160.355191, 61.727667],
              [160.390455, 61.756101],
              [160.410913, 61.760754],
              [160.394707, 61.784461],
              [160.397162, 61.796846],
              [160.353633, 61.802784],
              [160.366621, 61.815193],
              [160.393347, 61.820043],
              [160.401937, 61.830446],
              [160.373432, 61.870779],
              [160.393322, 61.879151],
              [160.376145, 61.901074],
              [160.353452, 61.909231],
              [160.367807, 61.920859],
              [160.35579, 61.937482],
              [160.29722, 61.939453],
              [160.276053, 61.926857],
              [160.23602, 61.916988],
              [160.219376, 61.901016],
              [160.172743, 61.880184],
              [160.133689, 61.879488],
              [160.082895, 61.853288],
              [160.0574, 61.852078],
              [160.025463, 61.836329],
              [159.997028, 61.794066],
              [159.978894, 61.784729],
              [159.969992, 61.759462],
              [159.929198, 61.731599],
              [159.89765, 61.722599],
              [159.897898, 61.712619],
              [159.875564, 61.704447],
              [159.83322, 61.713027],
              [159.79324, 61.709682],
              [159.790848, 61.700485],
              [159.748914, 61.704559],
              [159.723492, 61.700601],
              [159.70424, 61.707098],
              [159.706976, 61.685336],
              [159.69196, 61.688355],
              [159.64472, 61.683085],
              [159.57116, 61.65143],
              [159.56113, 61.662089],
              [159.524105, 61.668504],
              [159.529208, 61.68358],
              [159.513731, 61.692082],
              [159.515162, 61.713312],
              [159.531208, 61.721694],
              [159.537905, 61.750067],
              [159.52413, 61.765215],
              [159.53917, 61.791509],
              [159.498659, 61.792941],
              [159.478507, 61.783035],
              [159.47164, 61.757986],
              [159.456889, 61.782597],
              [159.410501, 61.788372],
              [159.388588, 61.807624],
              [159.351384, 61.816935],
              [159.349869, 61.826405],
              [159.398699, 61.836761],
              [159.406404, 61.866928],
              [159.393808, 61.877454],
              [159.347911, 61.892416],
              [159.155571, 61.921549],
              [159.11539, 61.916984],
              [159.086644, 61.921738],
              [159.023952, 61.916391],
              [159.029658, 61.923297],
              [158.983527, 61.914601],
              [158.999806, 61.906671],
              [158.977573, 61.895668],
              [158.90978, 61.898711],
              [158.901143, 61.885913],
              [158.878392, 61.881673],
              [158.850834, 61.864121],
              [158.844561, 61.819087],
              [158.768282, 61.82013],
              [158.742342, 61.817142],
              [158.744436, 61.825589],
              [158.703741, 61.837659],
              [158.658275, 61.837682],
              [158.646436, 61.831768],
              [158.586882, 61.833776],
              [158.549579, 61.8311],
              [158.510109, 61.79502],
              [158.475597, 61.790287],
              [158.46998, 61.802546],
              [158.434085, 61.814172],
              [158.381954, 61.824564],
              [158.288293, 61.827182],
              [158.25, 61.822117],
              [158.215303, 61.79314],
              [158.182381, 61.781179],
              [158.171325, 61.770366],
              [158.134065, 61.770136],
              [158.108855, 61.745413],
              [158.087865, 61.743484],
              [158.078528, 61.730884],
              [158.060357, 61.743042],
              [158.038644, 61.729843],
              [157.972911, 61.743822],
              [157.944356, 61.742454],
              [157.93941, 61.760839],
              [157.877506, 61.770325],
              [157.85433, 61.76911],
              [157.816019, 61.78209],
              [157.728474, 61.782331],
              [157.684821, 61.779617],
              [157.559438, 61.791792],
              [157.493099, 61.788777],
              [157.467529, 61.775828],
              [157.433359, 61.773139],
              [157.434217, 61.75626],
              [157.391689, 61.743485],
              [157.356888, 61.712876],
              [157.375559, 61.701327],
              [157.341218, 61.678396],
              [157.335809, 61.688822],
              [157.305196, 61.696892],
              [157.251509, 61.693274],
              [157.156485, 61.669251],
              [157.090309, 61.659643],
              [157.030349, 61.645997],
              [156.994473, 61.625453],
              [157.001718, 61.615123],
              [156.963196, 61.57695],
              [156.933119, 61.565695],
              [156.943522, 61.560148],
              [156.887329, 61.535622],
              [156.826482, 61.519478],
              [156.762519, 61.52095],
              [156.728855, 61.526835],
              [156.681034, 61.524955],
              [156.668721, 61.513994],
              [156.69314, 61.509802],
              [156.668002, 61.499054],
              [156.655088, 61.470321],
              [156.64729, 61.43263],
              [156.63355, 61.423321],
              [156.62282, 61.371826],
              [156.646163, 61.34469],
              [156.64976, 61.321435],
              [156.634651, 61.309909],
              [156.626785, 61.289532],
              [156.63094, 61.270292],
              [156.647786, 61.251186],
              [156.647708, 61.231552],
              [156.662202, 61.21158],
              [156.640316, 61.192002],
              [156.615754, 61.208211],
              [156.575154, 61.211713],
              [156.493382, 61.192178],
              [156.41999, 61.162127],
              [156.38085, 61.150996],
              [156.32531, 61.122398],
              [156.258016, 61.074454],
              [156.23597, 61.071185],
              [156.155961, 61.039187],
              [156.11291, 61.018164],
              [156.04789, 60.97554],
              [156.02895, 60.946883],
              [155.993492, 60.942109],
              [155.979405, 60.913742],
              [155.9293, 60.874899],
              [155.918059, 60.858244],
              [155.915786, 60.816045],
              [155.903348, 60.806072],
              [155.914479, 60.781506],
              [155.909029, 60.764725],
              [155.922498, 60.748813],
              [155.900121, 60.738545],
              [155.91413, 60.720061],
              [155.901879, 60.700099],
              [155.88945, 60.712914],
              [155.867, 60.719029],
              [155.857282, 60.733191],
              [155.807562, 60.726689],
              [155.799342, 60.704329],
              [155.77108, 60.691603],
              [155.758451, 60.678294],
              [155.708362, 60.653714],
              [155.674388, 60.659133],
              [155.65455, 60.646343],
              [155.62253, 60.643646],
              [155.608938, 60.630343],
              [155.574155, 60.625512],
              [155.556585, 60.614201],
              [155.532495, 60.585985],
              [155.516713, 60.590507],
              [155.488147, 60.581186],
              [155.475406, 60.553856],
              [155.484276, 60.534152],
              [155.447971, 60.528167],
              [155.435386, 60.51278],
              [155.413788, 60.529557],
              [155.353899, 60.527555],
              [155.313556, 60.51546],
              [155.276476, 60.490243],
              [155.241954, 60.48622],
              [155.214684, 60.466708],
              [155.201007, 60.448504],
              [155.147951, 60.414043],
              [155.11988, 60.404254],
              [155.087083, 60.401188],
              [155.039346, 60.382474],
              [154.990793, 60.37489],
              [154.951221, 60.361526],
              [154.863667, 60.312356],
              [154.82655, 60.285227],
              [154.769002, 60.231486],
              [154.669731, 60.182094],
              [154.579285, 60.096434],
              [154.512982, 60.030918],
              [154.488226, 59.969514],
              [154.482931, 59.890996],
              [154.477035, 59.884407],
              [154.407285, 59.868702],
              [154.397961, 59.838687],
              [154.373353, 59.827684],
              [154.400171, 59.848901],
              [154.41717, 59.877898],
              [154.453777, 59.888249],
              [154.426895, 59.902748],
              [154.365426, 59.894825],
              [154.33102, 59.90068],
              [154.270972, 59.889243],
              [154.241399, 59.888373],
              [154.213977, 59.8766],
              [154.22714, 59.853955],
              [154.225901, 59.827092],
              [154.21682, 59.79416],
              [154.217261, 59.764466],
              [154.230156, 59.728769],
              [154.256975, 59.687051],
              [154.297609, 59.644679],
              [154.372535, 59.578902],
              [154.313549, 59.612653],
              [154.290067, 59.619414],
              [154.29584, 59.644696],
              [154.255514, 59.668252],
              [154.229049, 59.664024],
              [154.211384, 59.651135],
              [154.199863, 59.625902],
              [154.218567, 59.612164],
              [154.209872, 59.595095],
              [154.18479, 59.571009],
              [154.155441, 59.561033],
              [154.146538, 59.53654],
              [154.110142, 59.540821],
              [154.076277, 59.522036],
              [154.073238, 59.513615],
              [154.122547, 59.474296],
              [154.126276, 59.457883],
              [154.164876, 59.447178],
              [154.29368, 59.437391],
              [154.320499, 59.461439],
              [154.347025, 59.470895],
              [154.36081, 59.500956],
              [154.347726, 59.520033],
              [154.368715, 59.522119],
              [154.386136, 59.535373],
              [154.404171, 59.532749],
              [154.41342, 59.512941],
              [154.409727, 59.496855],
              [154.37873, 59.483619],
              [154.38449, 59.47076],
              [154.42511, 59.455733],
              [154.41073, 59.436435],
              [154.421008, 59.417894],
              [154.525854, 59.415175],
              [154.532734, 59.434037],
              [154.490138, 59.448096],
              [154.490007, 59.456545],
              [154.458647, 59.475585],
              [154.449761, 59.497185],
              [154.45303, 59.520442],
              [154.428506, 59.524295],
              [154.412486, 59.550834],
              [154.439911, 59.543624],
              [154.49937, 59.52043],
              [154.532841, 59.514155],
              [154.574114, 59.513537],
              [154.620861, 59.532548],
              [154.626403, 59.542975],
              [154.646021, 59.542089],
              [154.692374, 59.51142],
              [154.763948, 59.473868],
              [154.793095, 59.463744],
              [154.845363, 59.472232],
              [154.879413, 59.464835],
              [154.918813, 59.472198],
              [154.916406, 59.482985],
              [154.943288, 59.495388],
              [154.973462, 59.494808],
              [155.012719, 59.477698],
              [155.013686, 59.455922],
              [155.02589, 59.432949],
              [155.083112, 59.395054],
              [155.11951, 59.383075],
              [155.167875, 59.378184],
              [155.210009, 59.347138],
              [155.197326, 59.329303],
              [155.21923, 59.307937],
              [155.176019, 59.305594],
              [155.1576, 59.295403],
              [155.159303, 59.274893],
              [155.189413, 59.253011],
              [155.201598, 59.236549],
              [155.1558, 59.219521],
              [155.165876, 59.197208],
              [155.187575, 59.185323],
              [155.18237, 59.174145],
              [155.103415, 59.187864],
              [155.06375, 59.192085],
              [155.040616, 59.188994],
              [155.030128, 59.172005],
              [154.989107, 59.186977],
              [154.912249, 59.195426],
              [154.901942, 59.189562],
              [154.856505, 59.188938],
              [154.833478, 59.196817],
              [154.79272, 59.180874],
              [154.786699, 59.151517],
              [154.80969, 59.129182],
              [154.760211, 59.140358],
              [154.701985, 59.141647],
              [154.674378, 59.147637],
              [154.656472, 59.158619],
              [154.62781, 59.163845],
              [154.611142, 59.180436],
              [154.58046, 59.185677],
              [154.590348, 59.194515],
              [154.53246, 59.216585],
              [154.508886, 59.214173],
              [154.485694, 59.220455],
              [154.44906, 59.216133],
              [154.394811, 59.195864],
              [154.331264, 59.191283],
              [154.341889, 59.163273],
              [154.359747, 59.148497],
              [154.347844, 59.124559],
              [154.359674, 59.11522],
              [154.320804, 59.107686],
              [154.306998, 59.091444],
              [154.272928, 59.084871],
              [154.228677, 59.09912],
              [154.162805, 59.093328],
              [154.117306, 59.070947],
              [154.054987, 59.048943],
              [153.987911, 59.065981],
              [153.944246, 59.068738],
              [153.927556, 59.076538],
              [153.894478, 59.104669],
              [153.86912, 59.144771],
              [153.842278, 59.167472],
              [153.761855, 59.182558],
              [153.715047, 59.186022],
              [153.693511, 59.18166],
              [153.672794, 59.193287],
              [153.638313, 59.190134],
              [153.59117, 59.204178],
              [153.586869, 59.215459],
              [153.498772, 59.233358],
              [153.449298, 59.231235],
              [153.381785, 59.217408],
              [153.358606, 59.202979],
              [153.364137, 59.183512],
              [153.347805, 59.171272],
              [153.35008, 59.150022],
              [153.339071, 59.145189],
              [153.354737, 59.118528],
              [153.3385, 59.087346],
              [153.290768, 59.088613],
              [153.272829, 59.084301],
              [153.240069, 59.09294],
              [153.17588, 59.088308],
              [153.15347, 59.082834],
              [153.123152, 59.085682],
              [153.0202, 59.06795],
              [153.00082, 59.057842],
              [152.99014, 59.030455],
              [152.947969, 59.009202],
              [152.93688, 58.968757],
              [152.910653, 58.952873],
              [152.903514, 58.936743],
              [152.913716, 58.916401],
              [152.896431, 58.904743],
              [152.884508, 58.919706],
              [152.811714, 58.927555],
              [152.744966, 58.916563],
              [152.735143, 58.906817],
              [152.66308, 58.940435],
              [152.604559, 58.947948],
              [152.548048, 58.951346],
              [152.54565, 58.96388],
              [152.513331, 58.989358],
              [152.46887, 59.010427],
              [152.4376, 59.010935],
              [152.408594, 59.023988],
              [152.41686, 59.034263],
              [152.38339, 59.04537],
              [152.379278, 59.053029],
              [152.35045, 59.053656],
              [152.36635, 59.040169],
              [152.37087, 59.02381],
              [152.396661, 59.010879],
              [152.332333, 59.014274],
              [152.267962, 59.0074],
              [152.180556, 58.989727],
              [152.14375, 58.974132],
              [152.13689, 58.960776],
              [152.149638, 58.94627],
              [152.144849, 58.919619],
              [152.122255, 58.903085],
              [152.052423, 58.887874],
              [151.97907, 58.876949],
              [151.935481, 58.879669],
              [151.82983, 58.875299],
              [151.769892, 58.864888],
              [151.750546, 58.853183],
              [151.680191, 58.845572],
              [151.608377, 58.85045],
              [151.552413, 58.865706],
              [151.473384, 58.864668],
              [151.427278, 58.856947],
              [151.413902, 58.846928],
              [151.34423, 58.837093],
              [151.361756, 58.858053],
              [151.31592, 58.891256],
              [151.313007, 58.910419],
              [151.28897, 58.926231],
              [151.251314, 58.93648],
              [151.254783, 58.946255],
              [151.233697, 58.975921],
              [151.242759, 58.982957],
              [151.232074, 59.00278],
              [151.18126, 59.025373],
              [151.156238, 59.047548],
              [151.138233, 59.081852],
              [151.10261, 59.105401],
              [151.183124, 59.084153],
              [151.211912, 59.085321],
              [151.255777, 59.10307],
              [151.321082, 59.105852],
              [151.381114, 59.125685],
              [151.39545, 59.149287],
              [151.43263, 59.164332],
              [151.462741, 59.16546],
              [151.511193, 59.157577],
              [151.593809, 59.148386],
              [151.618365, 59.151081],
              [151.650005, 59.163828],
              [151.704243, 59.140063],
              [151.736964, 59.130787],
              [151.791884, 59.133869],
              [151.795336, 59.141964],
              [151.84807, 59.144107],
              [151.906297, 59.130403],
              [151.935165, 59.130899],
              [151.972218, 59.140797],
              [151.990347, 59.151049],
              [152.045368, 59.14353],
              [152.08368, 59.145342],
              [152.120364, 59.152379],
              [152.159525, 59.181049],
              [152.186442, 59.181604],
              [152.197584, 59.195623],
              [152.273279, 59.202211],
              [152.302866, 59.195078],
              [152.317955, 59.201429],
              [152.324506, 59.223475],
              [152.2869, 59.242982],
              [152.264871, 59.245163],
              [152.23397, 59.263797],
              [152.209385, 59.267874],
              [152.207117, 59.279377],
              [152.17232, 59.291191],
              [152.152527, 59.282361],
              [152.135524, 59.301957],
              [152.076671, 59.275958],
              [152.02523, 59.273536],
              [152.015974, 59.26502],
              [151.952697, 59.274252],
              [151.931097, 59.293274],
              [151.898636, 59.306825],
              [151.862349, 59.299218],
              [151.848236, 59.302166],
              [151.737714, 59.299734],
              [151.730948, 59.320146],
              [151.76585, 59.338142],
              [151.770517, 59.354568],
              [151.726744, 59.378977],
              [151.715487, 59.391408],
              [151.694454, 59.396831],
              [151.701324, 59.414049],
              [151.665498, 59.45669],
              [151.63038, 59.479619],
              [151.591861, 59.498407],
              [151.566923, 59.504792],
              [151.517511, 59.506289],
              [151.496409, 59.522174],
              [151.494811, 59.541097],
              [151.448893, 59.55747],
              [151.383462, 59.561015],
              [151.351955, 59.555503],
              [151.283663, 59.559616],
              [151.19198, 59.576174],
              [151.136576, 59.58346],
              [151.058923, 59.582076],
              [151.009969, 59.573405],
              [150.938987, 59.556726],
              [150.910515, 59.563437],
              [150.892034, 59.549696],
              [150.901769, 59.532163],
              [150.943997, 59.521569],
              [150.938075, 59.504681],
              [150.915973, 59.485796],
              [150.960122, 59.479836],
              [150.93338, 59.464202],
              [150.93902, 59.454417],
              [150.910807, 59.445139],
              [150.86032, 59.450462],
              [150.81704, 59.440338],
              [150.744944, 59.436121],
              [150.741907, 59.45591],
              [150.779157, 59.470356],
              [150.75029, 59.488516],
              [150.71918, 59.489666],
              [150.662226, 59.498472],
              [150.58582, 59.485106],
              [150.55013, 59.491355],
              [150.536025, 59.477648],
              [150.51982, 59.485919],
              [150.532466, 59.498833],
              [150.632745, 59.516252],
              [150.672473, 59.526711],
              [150.68695, 59.522524],
              [150.760003, 59.530619],
              [150.782307, 59.547602],
              [150.76856, 59.563798],
              [150.687765, 59.56282],
              [150.656744, 59.551173],
              [150.602476, 59.540423],
              [150.57045, 59.544863],
              [150.553472, 59.556452],
              [150.517433, 59.559487],
              [150.50673, 59.574646],
              [150.45807, 59.577295],
              [150.467772, 59.589261],
              [150.458648, 59.616481],
              [150.430969, 59.621574],
              [150.392822, 59.618132],
              [150.361655, 59.635304],
              [150.329138, 59.643607],
              [150.195349, 59.65272],
              [150.13555, 59.659749],
              [149.990169, 59.700221],
              [149.882722, 59.723496],
              [149.793288, 59.739386],
              [149.665625, 59.754202],
              [149.559874, 59.758226],
              [149.498096, 59.753271],
              [149.386569, 59.726284],
              [149.341572, 59.720822],
              [149.21327, 59.673893],
              [149.163658, 59.65808],
              [149.106845, 59.631484],
              [149.05514, 59.620913],
              [149.053245, 59.61467],
              [149.098789, 59.611773],
              [149.094471, 59.591106],
              [149.110419, 59.587777],
              [149.112351, 59.566241],
              [149.095416, 59.561649],
              [149.097533, 59.547023],
              [149.143202, 59.527721],
              [149.17151, 59.529047],
              [149.216374, 59.52151],
              [149.230757, 59.498991],
              [149.214193, 59.476743],
              [149.22707, 59.462187],
              [149.201777, 59.459927],
              [149.161109, 59.484672],
              [149.077625, 59.48577],
              [149.039728, 59.474975],
              [148.996736, 59.454134],
              [148.965869, 59.459466],
              [148.931771, 59.452423],
              [148.88707, 59.47138],
              [148.862731, 59.495281],
              [148.87853, 59.516815],
              [148.914378, 59.527174],
              [148.918332, 59.534669],
              [148.872694, 59.547873],
              [148.851777, 59.543435],
              [148.81227, 59.511862],
              [148.77181, 59.499105],
              [148.742118, 59.470065],
              [148.759154, 59.459086],
              [148.7247, 59.463897],
              [148.71256, 59.43769],
              [148.717705, 59.380537],
              [148.725361, 59.368276],
              [148.75087, 59.357185],
              [148.797471, 59.356679],
              [148.805404, 59.345561],
              [148.825516, 59.351425],
              [148.828695, 59.363924],
              [148.850549, 59.378464],
              [148.89616, 59.391121],
              [148.907683, 59.377385],
              [148.984452, 59.37752],
              [148.994769, 59.360978],
              [148.949579, 59.342461],
              [148.9223, 59.303185],
              [148.95997, 59.283679],
              [148.955862, 59.251228],
              [148.92935, 59.233191],
              [148.904235, 59.239705],
              [148.865153, 59.27432],
              [148.833934, 59.279755],
              [148.78977, 59.272446],
              [148.778883, 59.2438],
              [148.726887, 59.236516],
              [148.719087, 59.241461],
              [148.72863, 59.262357],
              [148.709408, 59.267537],
              [148.59919, 59.250172],
              [148.578151, 59.239698],
              [148.526142, 59.247401],
              [148.489511, 59.245272],
              [148.417729, 59.252959],
              [148.412905, 59.258713],
              [148.441901, 59.270217],
              [148.439997, 59.291409],
              [148.409241, 59.310584],
              [148.423353, 59.325871],
              [148.42116, 59.342102],
              [148.396142, 59.379121],
              [148.30262, 59.404633],
              [148.202337, 59.409201],
              [148.093785, 59.403564],
              [148.035429, 59.392799],
              [147.948715, 59.39106],
              [147.934922, 59.386098],
              [147.897533, 59.387941],
              [147.832954, 59.372951],
              [147.79018, 59.347443],
              [147.799197, 59.327693],
              [147.815428, 59.324761],
              [147.845018, 59.306055],
              [147.844707, 59.292335],
              [147.78866, 59.260638],
              [147.743491, 59.266145],
              [147.716629, 59.280507],
              [147.704801, 59.296962],
              [147.633733, 59.307373],
              [147.588819, 59.297785],
              [147.541745, 59.278991],
              [147.533869, 59.262809],
              [147.558514, 59.2514],
              [147.543269, 59.235704],
              [147.480401, 59.245801],
              [147.4525, 59.260863],
              [147.421363, 59.268678],
              [147.397466, 59.292142],
              [147.364473, 59.306479],
              [147.328791, 59.310364],
              [147.302129, 59.332135],
              [147.25094, 59.335299],
              [147.17496, 59.319052],
              [147.131599, 59.332035],
              [147.126501, 59.34565],
              [147.076019, 59.358986],
              [147.060069, 59.354503],
              [147.019948, 59.370693],
              [146.94151, 59.368693],
              [146.937766, 59.38745],
              [146.97398, 59.389481],
              [147.00181, 59.401602],
              [147.108655, 59.408506],
              [147.110148, 59.43761],
              [147.093636, 59.45473],
              [147.102551, 59.470346],
              [147.148458, 59.504515],
              [147.137041, 59.512844],
              [147.142621, 59.53333],
              [147.136835, 59.548483],
              [147.152583, 59.563969],
              [147.15439, 59.600556],
              [147.143398, 59.606033],
              [147.164454, 59.621808],
              [147.168798, 59.646334],
              [147.15554, 59.647538],
              [147.13966, 59.665316],
              [147.145368, 59.679472],
              [147.12605, 59.696004],
              [147.17234, 59.716919],
              [147.198585, 59.720802],
              [147.21618, 59.73206],
              [147.201996, 59.75988],
              [147.146468, 59.796661],
              [147.156659, 59.808469],
              [147.142002, 59.827433],
              [147.166118, 59.837383],
              [147.139833, 59.844838],
              [147.118901, 59.866597],
              [147.11826, 59.886198],
              [147.04359, 59.903588],
              [147.028112, 59.914996],
              [147.061496, 59.929358],
              [147.03927, 59.947898],
              [147.001935, 59.953285],
              [146.942227, 59.951859],
              [146.9332, 59.942806],
              [146.904867, 59.940006],
              [146.88295, 59.924335],
              [146.80077, 59.905055],
              [146.77542, 59.92131],
              [146.77842, 59.9426],
              [146.758139, 59.94688],
              [146.747207, 59.962624],
              [146.722682, 59.962885],
              [146.70214, 59.971554],
              [146.705747, 59.988169],
              [146.683728, 60.004762],
              [146.69053, 60.027667],
              [146.672289, 60.038349],
              [146.690388, 60.064478],
              [146.689112, 60.087294],
              [146.664963, 60.101209],
              [146.608579, 60.115646],
              [146.579596, 60.092908],
              [146.53747, 60.097224],
              [146.533677, 60.124984],
              [146.500442, 60.12335],
              [146.499899, 60.148409],
              [146.511635, 60.160368],
              [146.484631, 60.192764],
              [146.453189, 60.202174],
              [146.432792, 60.215523],
              [146.41052, 60.217274],
              [146.408265, 60.241535],
              [146.372387, 60.250587],
              [146.340462, 60.243678],
              [146.313531, 60.244643],
              [146.265748, 60.23566],
              [146.217277, 60.217982],
              [146.21731, 60.208566],
              [146.132452, 60.200305],
              [146.090755, 60.206653],
              [146.049952, 60.195748],
              [145.978902, 60.202491],
              [145.920186, 60.197625],
              [145.915409, 60.180972],
              [145.882826, 60.171169],
              [145.857411, 60.170272],
              [145.79459, 60.211519],
              [145.770586, 60.204123],
              [145.737718, 60.226411],
              [145.698828, 60.237327],
              [145.677225, 60.252353],
              [145.636964, 60.264846],
              [145.611438, 60.284773],
              [145.617888, 60.292888],
              [145.600369, 60.310756],
              [145.561934, 60.311641],
              [145.555693, 60.322623],
              [145.57439, 60.335558],
              [145.615686, 60.337572],
              [145.659894, 60.361861],
              [145.657322, 60.38814],
              [145.637327, 60.414645],
              [145.58146, 60.411589],
              [145.53005, 60.422181],
              [145.538663, 60.444499],
              [145.525005, 60.45554],
              [145.535392, 60.462866],
              [145.528851, 60.494829],
              [145.563685, 60.504242],
              [145.566349, 60.515785],
              [145.587037, 60.515271],
              [145.626598, 60.52481],
              [145.640721, 60.517542],
              [145.65509, 60.528835],
              [145.639837, 60.545287],
              [145.645282, 60.556981],
              [145.714854, 60.578764],
              [145.713608, 60.594978],
              [145.748897, 60.599828],
              [145.792376, 60.614177],
              [145.830282, 60.601436],
              [145.847676, 60.610482],
              [145.930939, 60.619104],
              [145.910596, 60.643125],
              [145.920307, 60.655805],
              [145.982425, 60.658375],
              [145.994941, 60.675609],
              [146.063997, 60.708828],
              [146.061358, 60.725503],
              [146.085257, 60.73658],
              [146.1338, 60.73676],
              [146.151136, 60.744506],
              [146.173735, 60.732191],
              [146.199886, 60.701338],
              [146.242154, 60.698121],
              [146.28128, 60.685494],
              [146.280041, 60.667611],
              [146.297767, 60.669397],
              [146.336103, 60.652664],
              [146.34966, 60.659897],
              [146.444025, 60.671125],
              [146.431612, 60.688452],
              [146.45999, 60.704862],
              [146.454625, 60.72933],
              [146.469313, 60.736664],
              [146.446264, 60.755915],
              [146.465473, 60.769191],
              [146.455747, 60.785163],
              [146.4872, 60.777343],
              [146.501062, 60.802687],
              [146.480923, 60.809335],
              [146.50785, 60.832275],
              [146.534597, 60.842448],
              [146.49871, 60.85376],
              [146.503261, 60.867388],
              [146.52209, 60.867943],
              [146.504548, 60.904813],
              [146.479014, 60.91716],
              [146.511681, 60.932212],
              [146.499321, 60.941147],
              [146.568905, 60.954392],
              [146.652, 60.945716],
              [146.66211, 60.950901],
              [146.705414, 60.949364],
              [146.71989, 60.965353],
              [146.70866, 60.975015],
              [146.721791, 60.992906],
              [146.75447, 61.003652],
              [146.7202, 61.013136],
              [146.712682, 61.040488],
              [146.667757, 61.054407],
              [146.664852, 61.067675],
              [146.682238, 61.076655],
              [146.670831, 61.083864],
              [146.689305, 61.093608],
              [146.725255, 61.09785],
              [146.756434, 61.093282],
              [146.757117, 61.100403],
              [146.728865, 61.164504],
              [146.743798, 61.178721],
              [146.742649, 61.1938],
              [146.757482, 61.209857],
              [146.74663, 61.236461],
              [146.730453, 61.252452],
              [146.733556, 61.264154],
              [146.698001, 61.306826],
              [146.698508, 61.322757],
              [146.712803, 61.34519],
              [146.728651, 61.352298],
              [146.747149, 61.38622],
              [146.729782, 61.403394],
              [146.714902, 61.396332],
              [146.68859, 61.408637],
              [146.706629, 61.416811],
              [146.716501, 61.444985],
              [146.739752, 61.468605],
              [146.708261, 61.490057],
              [146.673275, 61.488312],
              [146.66786, 61.496357],
              [146.580909, 61.531723],
              [146.59698, 61.552083],
              [146.591986, 61.565858],
              [146.606449, 61.575197],
              [146.58641, 61.601855],
              [146.603855, 61.615183],
              [146.572679, 61.650276],
              [146.5248, 61.657271],
              [146.52068, 61.666036],
              [146.479016, 61.689127],
              [146.456955, 61.689024],
              [146.467905, 61.707189],
              [146.455265, 61.7188],
              [146.42961, 61.719534],
              [146.427952, 61.738466],
              [146.389128, 61.743088],
              [146.379613, 61.771473],
              [146.362923, 61.781153],
              [146.388651, 61.806001],
              [146.3571, 61.81383],
              [146.347697, 61.841445],
              [146.308021, 61.857929],
              [146.277914, 61.851388],
              [146.242142, 61.857972],
              [146.25249, 61.872252],
              [146.226035, 61.871423],
              [146.238214, 61.899087],
              [146.186024, 61.914923],
              [146.176039, 61.906438],
              [146.140271, 61.906977],
              [146.157411, 61.933111],
              [146.135168, 61.929078],
              [146.100616, 61.936406],
              [146.1081, 61.944711],
              [146.087183, 61.95767],
              [146.060986, 61.950385],
              [146.022548, 61.947958],
              [145.98079, 61.981906],
              [145.988451, 61.988797],
              [145.965418, 62.004494],
              [145.926066, 62.000266],
              [145.861912, 62.013644],
              [145.843618, 62.002971],
              [145.818152, 62.001761],
              [145.779807, 62.029094],
              [145.743016, 62.030019],
              [145.753758, 62.03661],
              [145.704365, 62.035487],
              [145.66786, 62.04619],
              [145.664655, 62.056279],
              [145.634243, 62.05328],
              [145.663188, 62.03401],
              [145.659291, 62.018113],
              [145.643044, 62.00764],
              [145.65273, 61.993842],
              [145.620791, 61.974948],
              [145.597797, 61.984507],
              [145.57723, 61.974745],
              [145.554616, 61.975966],
              [145.55416, 61.956611],
              [145.527861, 61.949691],
              [145.510858, 61.96523],
              [145.450543, 61.949333],
              [145.461918, 61.988265],
              [145.440354, 61.991315],
              [145.398708, 61.976244],
              [145.361146, 61.994983],
              [145.32584, 61.994086],
              [145.290614, 62.00536],
              [145.256372, 61.990301],
              [145.234294, 61.972938],
              [145.210803, 61.964145],
              [145.17445, 61.979742],
              [145.148477, 61.980585],
              [145.144669, 62.002154],
              [145.05248, 61.996843],
              [144.992525, 62.026461],
              [144.987821, 62.051919],
              [144.956246, 62.066851],
              [144.92795, 62.054622],
              [144.85569, 62.059788],
              [144.83113, 62.047998],
              [144.772073, 62.036407],
              [144.73642, 62.045483],
              [144.744278, 62.061611],
              [144.728624, 62.085814],
              [144.736525, 62.095409],
              [144.810162, 62.114145],
              [144.825926, 62.112342],
              [144.83817, 62.124844],
              [144.876847, 62.133395],
              [144.87762, 62.158284],
              [144.934388, 62.161794],
              [144.956614, 62.14802],
              [144.98684, 62.156606],
              [144.991661, 62.168061],
              [144.976977, 62.182099],
              [145.009646, 62.193405],
              [145.0171, 62.205437],
              [144.98746, 62.225914],
              [144.990204, 62.238915],
              [145.01118, 62.249071],
              [145.057278, 62.229654],
              [145.098984, 62.23461],
              [145.130434, 62.247412],
              [145.120337, 62.264956],
              [145.16828, 62.276724],
              [145.19959, 62.278872],
              [145.171262, 62.289954],
              [145.16684, 62.300434],
              [145.202017, 62.298957],
              [145.210096, 62.310129],
              [145.196303, 62.322083],
              [145.207517, 62.333696],
              [145.1976, 62.345568],
              [145.170176, 62.352578],
              [145.176425, 62.365294],
              [145.1665, 62.389675],
              [145.14923, 62.397267],
              [145.184358, 62.413875],
              [145.189874, 62.436781],
              [145.157866, 62.457601],
              [145.137654, 62.478539],
              [145.17304, 62.489912],
              [145.217012, 62.473603],
              [145.227252, 62.503263],
              [145.268837, 62.509846],
              [145.280202, 62.524484],
              [145.327619, 62.513327],
              [145.349283, 62.503078],
              [145.384511, 62.499477],
              [145.43741, 62.530871],
              [145.484044, 62.539551],
              [145.444813, 62.556269],
              [145.436306, 62.587348],
              [145.39705, 62.599234],
              [145.413601, 62.628984],
              [145.450265, 62.632283],
              [145.425613, 62.642819],
              [145.418847, 62.657247],
              [145.39044, 62.680707],
              [145.40855, 62.702381],
              [145.45657, 62.707403],
              [145.439708, 62.73048],
              [145.400193, 62.728871],
              [145.353052, 62.746696],
              [145.3657, 62.774314],
              [145.36137, 62.782774],
              [145.37628, 62.810559],
              [145.379009, 62.831527],
              [145.401412, 62.836953],
              [145.409515, 62.866673],
              [145.39294, 62.891634],
              [145.395014, 62.914168],
              [145.37191, 62.938497],
              [145.354949, 62.942947],
              [145.378116, 62.950317],
              [145.36918, 62.974442],
              [145.353298, 62.979215],
              [145.340331, 63.01119],
              [145.309704, 63.006716],
              [145.288057, 63.011657],
              [145.280643, 63.036917],
              [145.316205, 63.050642],
              [145.28934, 63.086188],
              [145.30296, 63.087796],
              [145.24951, 63.161006],
              [145.27331, 63.178784],
              [145.25786, 63.242875],
              [145.311364, 63.269032],
              [145.34363, 63.297427],
              [145.393119, 63.308217],
              [145.38456, 63.317996],
              [145.405285, 63.340815],
              [145.397199, 63.356534],
              [145.440458, 63.378698],
              [145.448674, 63.412584],
              [145.464405, 63.438259],
              [145.458739, 63.463393],
              [145.489762, 63.513848],
              [145.4683, 63.526303],
              [145.498318, 63.55866],
              [145.532194, 63.569678],
              [145.557949, 63.590799],
              [145.554987, 63.603644],
              [145.583953, 63.619099],
              [145.576826, 63.629661],
              [145.60117, 63.644897],
              [145.637149, 63.643739],
              [145.665851, 63.658704],
              [145.675889, 63.673529],
              [145.659314, 63.682692],
              [145.672851, 63.691651],
              [145.66653, 63.709403],
              [145.649345, 63.719893],
              [145.659141, 63.728856],
              [145.65335, 63.768658],
              [145.668741, 63.774762],
              [145.601472, 63.805714],
              [145.567507, 63.807865],
              [145.586, 63.828036],
              [145.6558, 63.83722],
              [145.710067, 63.848307],
              [145.75762, 63.865663],
              [145.81653, 63.8718],
              [145.83098, 63.881252],
              [145.884917, 63.877891],
              [145.930847, 63.884302],
              [145.995674, 63.903434],
              [145.998187, 63.909952],
              [146.0755, 63.920526],
              [146.112125, 63.918997],
              [146.165684, 63.927209],
              [146.181984, 63.942217],
              [146.176961, 63.953823],
              [146.230093, 63.978811],
              [146.220312, 64.004468],
              [146.230759, 64.019683],
              [146.279241, 64.030661],
              [146.29613, 64.049507],
              [146.33349, 64.0701],
              [146.338551, 64.091519],
              [146.329119, 64.101924],
              [146.364923, 64.140723],
              [146.368627, 64.185881],
              [146.433786, 64.199296],
              [146.496719, 64.189493],
              [146.542709, 64.189356],
              [146.574287, 64.17529],
              [146.612855, 64.16827],
              [146.646569, 64.169644],
              [146.685668, 64.1527],
              [146.718527, 64.155281],
              [146.767089, 64.146753],
              [146.834568, 64.151145],
              [146.884999, 64.13875],
              [146.951481, 64.134016],
              [147.012052, 64.133424],
              [147.066709, 64.113401],
              [147.10512, 64.106548],
              [147.10563, 64.099557],
              [147.21371, 64.042064],
              [147.254844, 64.026709],
              [147.294615, 64.018729],
              [147.298862, 64.036299],
              [147.318879, 64.045437],
              [147.346773, 64.045528],
              [147.358948, 64.055362],
              [147.361506, 64.076641],
              [147.38328, 64.081941],
              [147.371342, 64.101712],
              [147.342367, 64.111444],
              [147.401753, 64.129572],
              [147.460623, 64.135187],
              [147.460183, 64.139736],
              [147.511452, 64.145372],
              [147.569347, 64.146041],
              [147.585092, 64.163447],
              [147.643774, 64.178001],
              [147.65099, 64.164259],
              [147.672395, 64.160118],
              [147.700153, 64.133743],
              [147.73276, 64.115184],
              [147.71923, 64.093695],
              [147.691719, 64.08698],
              [147.651769, 64.066943],
              [147.680403, 64.052988],
              [147.653248, 64.035045],
              [147.669515, 64.031434],
              [147.670816, 64.011346],
              [147.701462, 64.015055],
              [147.75111, 64.009646],
              [147.804644, 63.995213],
              [147.777639, 63.981339],
              [147.788405, 63.953647],
              [147.819278, 63.947363],
              [147.862753, 63.95049],
              [147.880922, 63.969182],
              [147.922182, 63.960472],
              [147.905539, 63.947495],
              [147.975677, 63.936786],
              [148.01834, 63.936136],
              [148.032397, 63.964504],
              [148.071839, 63.972242],
              [148.069504, 63.996305],
              [148.1109, 64.016339],
              [148.137044, 64.012923],
              [148.176595, 64.023366],
              [148.16009, 64.052392],
              [148.176123, 64.061811],
              [148.176411, 64.07742],
              [148.159692, 64.102504],
              [148.187992, 64.106027],
              [148.1857, 64.117023],
              [148.224318, 64.131761],
              [148.229795, 64.153254],
              [148.263517, 64.152785],
              [148.278921, 64.166849],
              [148.25709, 64.181866],
              [148.26089, 64.200037],
              [148.28952, 64.218607],
              [148.314505, 64.220696],
              [148.249508, 64.259703],
              [148.212463, 64.269326],
              [148.202361, 64.280952],
              [148.1235, 64.295166],
              [148.128378, 64.306964],
              [148.11488, 64.326225],
              [148.09495, 64.330418],
              [148.069663, 64.350655],
              [148.092208, 64.362501],
              [148.073, 64.385231],
              [148.088686, 64.393328],
              [148.04783, 64.407899],
              [148.055195, 64.418476],
              [148.094725, 64.427704],
              [148.130736, 64.429482],
              [148.147623, 64.421806],
              [148.179806, 64.424865],
              [148.224911, 64.44223],
              [148.239746, 64.481226],
              [148.274567, 64.48303],
              [148.327615, 64.528079],
              [148.385405, 64.52954],
              [148.406878, 64.520425],
              [148.444865, 64.523554],
              [148.459538, 64.509653],
              [148.501199, 64.50981],
              [148.525032, 64.496054],
              [148.511888, 64.485097],
              [148.551888, 64.472444],
              [148.555713, 64.450364],
              [148.612289, 64.437041],
              [148.6635, 64.416219],
              [148.681748, 64.416161],
              [148.708723, 64.402203],
              [148.758717, 64.405719],
              [148.776467, 64.413502],
              [148.813001, 64.409058],
              [148.85167, 64.41876],
              [148.863373, 64.411548],
              [148.923544, 64.42125],
              [148.934726, 64.432145],
              [148.98424, 64.422725],
              [149.041135, 64.431475],
              [149.119454, 64.419479],
              [149.164267, 64.426423],
              [149.192364, 64.41919],
              [149.178988, 64.408618],
              [149.193644, 64.399073],
              [149.195211, 64.381817],
              [149.261488, 64.377044],
              [149.339492, 64.376755],
              [149.366622, 64.380223],
              [149.370432, 64.389826],
              [149.345641, 64.402761],
              [149.360938, 64.42002],
              [149.42266, 64.412683],
              [149.447375, 64.416017],
              [149.474748, 64.404078],
              [149.502287, 64.405748],
              [149.511681, 64.425879],
              [149.55923, 64.433716],
              [149.560621, 64.450031],
              [149.580637, 64.470464],
              [149.679967, 64.500727],
              [149.699192, 64.512746],
              [149.73678, 64.519051],
              [149.743301, 64.531435],
              [149.772787, 64.546997],
              [149.80483, 64.548111],
              [149.839375, 64.560976],
              [149.871263, 64.56237],
              [149.914301, 64.549494],
              [149.92443, 64.552736],
              [149.97822, 64.542086],
              [149.975083, 64.526813],
              [149.998889, 64.50728],
              [149.994193, 64.496075],
              [150.028071, 64.497536],
              [150.097893, 64.483663],
              [150.127874, 64.468331],
              [150.143723, 64.469472],
              [150.14022, 64.447603],
              [150.11969, 64.437519],
              [150.134722, 64.430507],
              [150.070378, 64.408742],
              [150.077697, 64.386433],
              [150.016771, 64.350929],
              [150.042052, 64.345263],
              [150.04578, 64.324949],
              [150.078898, 64.318663],
              [150.091281, 64.302514],
              [150.083688, 64.292798],
              [150.109845, 64.282031],
              [150.162749, 64.270117],
              [150.17951, 64.271926],
              [150.209798, 64.258904],
              [150.196246, 64.226865],
              [150.233632, 64.213203],
              [150.312512, 64.20838],
              [150.336531, 64.216661],
              [150.375155, 64.214925],
              [150.432291, 64.204656],
              [150.480691, 64.185068],
              [150.51863, 64.182748],
              [150.54882, 64.166332],
              [150.59338, 64.176755],
              [150.65081, 64.167535],
              [150.668293, 64.1927],
              [150.648306, 64.209899],
              [150.645018, 64.224515],
              [150.681119, 64.254371],
              [150.675331, 64.267998],
              [150.628188, 64.290402],
              [150.595208, 64.292299],
              [150.58689, 64.304575],
              [150.63959, 64.337741],
              [150.663659, 64.33979],
              [150.71832, 64.331387],
              [150.740348, 64.333272],
              [150.795892, 64.327052],
              [150.886288, 64.330046],
              [150.916772, 64.334283],
              [150.993956, 64.332985],
              [151.049068, 64.351699],
              [151.083735, 64.346474],
              [151.167177, 64.359236],
              [151.225879, 64.354606],
              [151.2517, 64.344147],
              [151.273165, 64.35669],
              [151.304334, 64.358111],
              [151.313895, 64.371303],
              [151.371697, 64.366406],
              [151.38906, 64.330327],
              [151.410216, 64.330624],
              [151.418398, 64.357932],
              [151.446157, 64.382078],
              [151.481078, 64.390834],
              [151.481479, 64.41951],
              [151.496874, 64.425812],
              [151.611105, 64.43678],
              [151.813634, 64.470063],
              [151.879244, 64.471568],
              [151.929879, 64.476437],
              [151.974573, 64.489108],
              [151.991473, 64.475287],
              [152.017576, 64.477341],
              [152.047762, 64.467923],
              [152.071538, 64.452285],
              [152.082717, 64.4222],
              [152.101848, 64.416679],
              [152.115163, 64.395449],
              [152.143024, 64.386236],
              [152.12471, 64.371347],
              [152.131617, 64.356607],
              [152.150417, 64.351137],
              [152.216195, 64.359517],
              [152.255496, 64.355151],
              [152.285359, 64.376253],
              [152.332168, 64.368045],
              [152.365649, 64.384042],
              [152.416385, 64.384074],
              [152.4448, 64.389408],
              [152.427814, 64.408159],
              [152.44504, 64.418435],
              [152.44429, 64.44176],
              [152.423284, 64.453966],
              [152.43551, 64.467784],
              [152.40307, 64.492112],
              [152.358914, 64.50825],
              [152.376811, 64.5329],
              [152.39884, 64.528184],
              [152.480712, 64.55022],
              [152.487935, 64.562294],
              [152.535019, 64.561144],
              [152.601485, 64.583835],
              [152.623773, 64.610306],
              [152.671481, 64.621045],
              [152.72706, 64.623176],
              [152.737272, 64.640229],
              [152.75415, 64.641326],
              [152.783722, 64.66464],
              [152.83076, 64.681016],
              [152.847283, 64.701393],
              [152.83183, 64.709804],
              [152.808007, 64.743132],
              [152.78814, 64.742334],
              [152.759392, 64.757366],
              [152.746164, 64.774556],
              [152.714918, 64.782868],
              [152.65378, 64.808854],
              [152.629897, 64.803574],
              [152.585915, 64.811292],
              [152.584843, 64.835743],
              [152.550923, 64.863363],
              [152.550146, 64.884787],
              [152.518397, 64.91846],
              [152.541042, 64.935388],
              [152.529664, 64.945858],
              [152.519314, 64.976629],
              [152.525736, 64.993862],
              [152.567701, 65.000459],
              [152.578109, 65.013245],
              [152.568656, 65.025088],
              [152.590011, 65.033339],
              [152.60715, 65.052021],
              [152.630224, 65.060508],
              [152.6468, 65.082805],
              [152.645634, 65.117523],
              [152.656316, 65.130427],
              [152.66147, 65.168483],
              [152.67615, 65.180385],
              [152.710476, 65.179268],
              [152.727381, 65.197046],
              [152.71279, 65.213399],
              [152.733014, 65.227087],
              [152.765397, 65.228625],
              [152.797821, 65.206831],
              [152.876473, 65.225611],
              [152.876438, 65.233208],
              [152.908782, 65.25068],
              [152.94148, 65.255444],
              [152.973499, 65.242989],
              [153.045009, 65.256635],
              [153.119256, 65.264191],
              [153.148186, 65.244046],
              [153.18347, 65.27024],
              [153.190035, 65.295752],
              [153.243637, 65.309622],
              [153.28299, 65.313529],
              [153.339097, 65.327895],
              [153.3842, 65.33384],
              [153.42793, 65.355396],
              [153.458385, 65.357266],
              [153.500533, 65.345736],
              [153.514828, 65.364502],
              [153.530787, 65.36605],
              [153.585837, 65.398429],
              [153.582315, 65.419394],
              [153.557892, 65.430287],
              [153.549962, 65.44646],
              [153.50034, 65.462715],
              [153.461894, 65.45987],
              [153.442342, 65.48533],
              [153.467599, 65.515581],
              [153.46947, 65.540324],
              [153.487275, 65.550535],
              [153.490216, 65.585007],
              [153.500336, 65.589247],
              [153.540637, 65.6373],
              [153.510087, 65.654913],
              [153.514928, 65.667004],
              [153.577082, 65.689843],
              [153.57278, 65.710275],
              [153.556768, 65.71615],
              [153.54799, 65.73725],
              [153.523676, 65.733418],
              [153.483706, 65.747972],
              [153.495287, 65.764848],
              [153.53367, 65.79825],
              [153.532184, 65.810303],
              [153.577152, 65.822128],
              [153.593077, 65.834886],
              [153.635921, 65.845812],
              [153.68031, 65.842535],
              [153.731446, 65.857908],
              [153.754727, 65.870852],
              [153.788468, 65.855133],
              [153.836342, 65.8566],
              [153.89418, 65.869331],
              [153.928861, 65.865979],
              [153.956673, 65.873049],
              [154.026468, 65.865818],
              [154.03414, 65.854609],
              [154.087433, 65.850263],
              [154.099285, 65.839377],
              [154.087705, 65.80189],
              [154.089677, 65.783536],
              [154.136718, 65.778504],
              [154.159725, 65.769547],
              [154.19775, 65.795132],
              [154.266841, 65.806328],
              [154.27328, 65.819511],
              [154.300839, 65.826402],
              [154.312849, 65.846124],
              [154.337794, 65.85493],
              [154.32548, 65.877533],
              [154.387702, 65.901437],
              [154.411806, 65.922526],
              [154.415657, 65.950151],
              [154.378689, 65.97417],
              [154.430058, 65.994357],
              [154.393085, 65.997841],
              [154.374991, 66.029176],
              [154.277612, 66.039986],
              [154.236796, 66.038241],
              [154.228944, 66.047978],
              [154.265203, 66.087036],
              [154.302583, 66.102929],
              [154.364956, 66.103399],
              [154.392951, 66.141492],
              [154.379887, 66.168081],
              [154.394493, 66.182848],
              [154.463077, 66.183165],
              [154.521153, 66.218694],
              [154.585952, 66.217985],
              [154.610278, 66.19595],
              [154.663521, 66.18126],
              [154.724973, 66.175958],
              [154.801674, 66.174906],
              [154.843025, 66.168246],
              [154.859214, 66.154873],
              [154.887025, 66.150144],
              [154.894973, 66.109322],
              [154.946317, 66.110584],
              [154.979514, 66.119423],
              [155.079598, 66.130124],
              [155.057722, 66.160338],
              [155.102205, 66.169477],
              [155.138609, 66.157341],
              [155.170693, 66.182483],
              [155.200893, 66.175111],
              [155.210873, 66.181796],
              [155.26268, 66.181404],
              [155.290528, 66.158076],
              [155.316642, 66.159646],
              [155.362241, 66.143519],
              [155.372376, 66.129588],
              [155.402757, 66.125378],
              [155.4212, 66.132869],
              [155.453615, 66.129983],
              [155.498419, 66.133956],
              [155.528176, 66.14422],
              [155.560239, 66.142208],
              [155.622894, 66.119839],
              [155.659016, 66.121953],
              [155.69157, 66.140096],
              [155.747972, 66.161196],
              [155.81293, 66.147378],
              [155.859257, 66.105488],
              [155.906952, 66.088383],
              [155.94107, 66.087261],
              [155.948471, 66.067757],
              [156.006589, 66.073601],
              [156.06531, 66.046131],
              [156.087741, 66.069622],
              [156.201065, 66.068083],
              [156.246524, 66.058638],
              [156.266742, 66.064626],
              [156.29862, 66.057828],
              [156.346148, 66.061141],
              [156.382725, 66.054639],
              [156.42702, 66.038272],
              [156.47141, 66.050884],
              [156.455227, 66.06521],
              [156.49589, 66.083248],
              [156.533871, 66.087908],
              [156.538553, 66.119412],
              [156.577662, 66.138892],
              [156.644273, 66.148031],
              [156.63143, 66.177213],
              [156.674004, 66.189043],
              [156.722754, 66.174626],
              [156.767952, 66.152493],
              [156.766567, 66.122456],
              [156.817836, 66.103545],
              [156.841225, 66.082817],
              [156.904384, 66.064054],
              [156.921275, 66.039791],
              [157.00252, 66.012557],
              [157.034383, 66.007334],
              [157.013504, 65.993437],
              [157.019134, 65.9773],
              [157.002377, 65.965314],
              [156.961833, 65.956736],
              [157.023725, 65.942584],
              [157.083926, 65.914601],
              [157.110698, 65.914599],
              [157.147028, 65.900839],
              [157.19149, 65.903256],
              [157.213991, 65.895921],
              [157.303631, 65.906688],
              [157.335158, 65.891827],
              [157.381484, 65.907634],
              [157.449326, 65.912874],
              [157.458704, 65.936626],
              [157.42048, 65.953133],
              [157.408144, 65.96865],
              [157.444713, 66.000241],
              [157.502266, 66.004453],
              [157.56808, 66.021185],
              [157.621411, 66.022685],
              [157.660763, 66.037198],
              [157.662667, 66.053972],
              [157.68936, 66.064581],
              [157.699656, 66.07985],
              [157.744936, 66.099459],
              [157.840617, 66.104395],
              [157.897153, 66.101658],
              [157.92769, 66.107823],
              [158.04098, 66.117201],
              [158.092131, 66.134323],
              [158.163165, 66.133985],
              [158.188291, 66.142603],
              [158.228532, 66.122559],
              [158.26616, 66.112828],
              [158.285921, 66.115365],
              [158.283133, 66.101296],
              [158.378094, 66.102058],
              [158.449504, 66.085382],
              [158.500697, 66.093502],
              [158.517708, 66.104918],
              [158.496465, 66.115262],
              [158.47511, 66.146916],
              [158.455504, 66.160289],
              [158.47245, 66.169927],
              [158.432257, 66.20337],
              [158.41131, 66.204641],
              [158.403346, 66.21974],
              [158.437993, 66.222454],
              [158.441348, 66.248228],
              [158.429979, 66.257794],
              [158.448307, 66.272865],
              [158.493151, 66.284674],
              [158.498243, 66.322115],
              [158.543444, 66.335235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5507",
      properties: {
        name: "Марий Эл",
        density: 0,
        path: "/world/Russia/Марий Эл",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.721544, 56.963392],
            [46.728373, 56.948014],
            [46.703663, 56.940716],
            [46.790564, 56.936157],
            [46.819338, 56.921149],
            [46.867349, 56.934334],
            [46.89438, 56.95183],
            [46.90939, 56.919491],
            [46.935675, 56.939393],
            [46.99173, 56.941798],
            [46.992891, 56.925878],
            [47.034352, 56.918913],
            [47.060887, 56.923225],
            [47.06412, 56.930937],
            [47.095051, 56.926632],
            [47.15633, 56.893209],
            [47.162052, 56.879444],
            [47.127059, 56.882678],
            [47.129466, 56.838315],
            [47.107907, 56.823804],
            [47.128306, 56.821234],
            [47.134692, 56.806101],
            [47.155713, 56.796026],
            [47.17835, 56.801209],
            [47.198167, 56.823764],
            [47.261413, 56.80731],
            [47.265361, 56.815391],
            [47.302553, 56.82891],
            [47.33632, 56.848988],
            [47.359704, 56.847732],
            [47.389572, 56.859538],
            [47.383633, 56.866688],
            [47.434973, 56.88289],
            [47.44572, 56.873265],
            [47.510934, 56.850756],
            [47.486695, 56.873412],
            [47.513099, 56.883134],
            [47.513353, 56.894257],
            [47.546196, 56.900591],
            [47.547064, 56.924886],
            [47.602351, 56.924553],
            [47.601406, 56.937661],
            [47.616912, 56.944253],
            [47.616455, 56.957935],
            [47.662765, 56.98331],
            [47.686149, 56.975764],
            [47.706132, 56.993012],
            [47.685069, 57.001636],
            [47.782673, 56.998604],
            [47.768903, 57.001637],
            [47.744068, 57.025949],
            [47.764379, 57.03932],
            [47.8008, 57.029503],
            [47.832835, 57.000228],
            [47.83466, 56.989946],
            [47.864771, 56.990444],
            [47.89473, 56.984772],
            [47.908305, 56.964629],
            [47.91768, 56.989462],
            [47.949257, 56.986713],
            [47.945323, 56.96459],
            [47.954068, 56.957774],
            [47.986323, 56.968637],
            [47.988478, 57.001972],
            [48.049757, 56.993514],
            [48.052327, 57.005289],
            [48.085081, 57.002056],
            [48.091715, 56.987379],
            [48.142131, 56.995837],
            [48.140472, 57.007198],
            [48.159211, 57.018143],
            [48.206062, 57.0135],
            [48.207719, 57.042523],
            [48.1612, 57.052887],
            [48.164516, 57.061926],
            [48.13753, 57.069662],
            [48.202493, 57.070882],
            [48.247104, 57.096256],
            [48.224134, 57.116737],
            [48.229698, 57.129736],
            [48.276759, 57.123576],
            [48.269972, 57.133671],
            [48.279404, 57.148135],
            [48.310245, 57.151119],
            [48.328936, 57.16609],
            [48.363622, 57.166524],
            [48.377817, 57.152907],
            [48.361658, 57.133496],
            [48.378458, 57.126566],
            [48.402382, 57.130933],
            [48.41984, 57.125526],
            [48.416842, 57.110189],
            [48.553369, 57.159985],
            [48.555442, 57.171718],
            [48.581064, 57.172133],
            [48.595908, 57.157332],
            [48.63231, 57.150491],
            [48.66295, 57.151736],
            [48.673521, 57.169025],
            [48.707934, 57.167491],
            [48.769917, 57.17197],
            [48.824609, 57.165219],
            [48.814488, 57.139714],
            [48.829539, 57.140419],
            [48.864906, 57.113968],
            [48.835677, 57.099913],
            [48.840072, 57.092782],
            [48.909666, 57.08188],
            [48.947945, 57.082648],
            [48.969276, 57.096652],
            [48.983029, 57.115086],
            [49.002717, 57.124579],
            [48.98329, 57.145409],
            [48.996867, 57.156171],
            [48.989271, 57.186122],
            [49.044261, 57.190278],
            [49.037314, 57.209689],
            [49.007425, 57.219817],
            [49.007399, 57.289029],
            [49.044898, 57.300841],
            [49.084256, 57.29501],
            [49.081704, 57.304333],
            [49.127286, 57.328052],
            [49.151218, 57.33006],
            [49.169038, 57.342734],
            [49.180447, 57.318367],
            [49.216754, 57.306004],
            [49.214115, 57.288103],
            [49.195836, 57.284214],
            [49.191201, 57.257624],
            [49.178235, 57.248667],
            [49.187127, 57.221809],
            [49.179681, 57.192907],
            [49.138697, 57.17991],
            [49.164865, 57.141863],
            [49.173664, 57.155206],
            [49.207197, 57.160407],
            [49.186561, 57.138995],
            [49.171873, 57.133336],
            [49.199488, 57.128956],
            [49.221752, 57.118077],
            [49.20749, 57.109827],
            [49.230958, 57.068989],
            [49.253637, 57.04884],
            [49.247377, 57.041502],
            [49.277082, 57.033935],
            [49.328473, 57.039513],
            [49.345389, 57.021188],
            [49.358905, 57.025417],
            [49.389503, 57.019696],
            [49.391742, 57.005848],
            [49.416784, 57.009083],
            [49.431542, 57.031969],
            [49.401111, 57.034373],
            [49.402935, 57.044821],
            [49.433117, 57.040095],
            [49.468026, 57.057757],
            [49.486103, 57.050958],
            [49.518441, 57.049549],
            [49.572903, 57.063278],
            [49.584932, 57.057204],
            [49.602114, 57.077971],
            [49.649981, 57.066049],
            [49.645419, 57.053094],
            [49.665493, 57.048346],
            [49.682859, 57.06294],
            [49.695383, 57.057719],
            [49.673584, 57.047195],
            [49.68956, 57.024149],
            [49.70275, 57.019868],
            [49.717178, 56.986044],
            [49.739595, 56.984357],
            [49.747583, 56.962115],
            [49.760903, 56.952163],
            [49.741081, 56.940975],
            [49.705227, 56.932225],
            [49.711172, 56.917964],
            [49.746406, 56.91428],
            [49.754704, 56.902717],
            [49.730864, 56.891185],
            [49.767832, 56.873558],
            [49.826288, 56.879953],
            [49.82666, 56.889054],
            [49.900419, 56.880523],
            [49.935393, 56.869882],
            [49.94867, 56.857923],
            [49.990401, 56.86009],
            [50.019966, 56.869405],
            [50.019037, 56.854488],
            [50.041011, 56.852249],
            [50.057295, 56.834382],
            [50.047314, 56.826917],
            [50.042915, 56.795976],
            [50.104437, 56.792259],
            [50.096051, 56.730823],
            [50.074723, 56.721071],
            [50.060962, 56.659213],
            [50.026035, 56.657554],
            [50.053872, 56.648226],
            [50.084263, 56.626502],
            [50.081423, 56.611177],
            [50.061857, 56.61314],
            [50.053581, 56.599679],
            [50.169562, 56.592931],
            [50.202985, 56.583254],
            [50.192858, 56.572799],
            [50.201345, 56.557124],
            [50.160043, 56.530607],
            [50.119262, 56.517108],
            [50.089445, 56.516607],
            [50.08303, 56.505663],
            [50.05647, 56.497223],
            [50.039934, 56.485033],
            [50.037929, 56.458014],
            [49.970484, 56.431381],
            [49.946482, 56.428925],
            [49.928538, 56.458441],
            [49.967272, 56.463642],
            [49.977481, 56.469913],
            [49.987235, 56.494623],
            [49.933392, 56.496858],
            [49.903985, 56.476443],
            [49.919405, 56.467339],
            [49.89751, 56.447866],
            [49.885751, 56.447704],
            [49.86106, 56.425313],
            [49.803606, 56.437279],
            [49.78581, 56.448073],
            [49.753835, 56.454004],
            [49.750548, 56.46291],
            [49.806064, 56.489335],
            [49.813884, 56.501165],
            [49.777231, 56.531537],
            [49.756333, 56.537227],
            [49.73262, 56.531461],
            [49.707403, 56.517412],
            [49.684931, 56.51541],
            [49.664696, 56.527425],
            [49.618808, 56.515194],
            [49.611684, 56.502179],
            [49.569368, 56.502881],
            [49.567596, 56.487324],
            [49.578909, 56.478829],
            [49.581551, 56.45921],
            [49.565345, 56.459197],
            [49.549388, 56.43168],
            [49.506447, 56.421256],
            [49.455574, 56.425532],
            [49.439946, 56.440824],
            [49.451117, 56.467959],
            [49.435871, 56.476933],
            [49.417629, 56.473483],
            [49.42199, 56.462872],
            [49.441279, 56.468774],
            [49.435759, 56.447522],
            [49.409455, 56.450295],
            [49.400395, 56.410163],
            [49.409294, 56.397521],
            [49.394666, 56.3561],
            [49.381475, 56.349293],
            [49.359371, 56.363939],
            [49.339344, 56.366506],
            [49.331366, 56.354366],
            [49.34248, 56.343216],
            [49.334157, 56.329954],
            [49.312777, 56.332456],
            [49.28468, 56.346146],
            [49.252924, 56.35005],
            [49.251641, 56.361108],
            [49.21585, 56.390075],
            [49.167041, 56.390303],
            [49.170712, 56.369392],
            [49.191993, 56.365539],
            [49.133862, 56.356452],
            [49.114449, 56.369224],
            [49.10932, 56.346635],
            [49.080153, 56.334948],
            [49.079259, 56.345993],
            [49.051564, 56.34479],
            [49.041116, 56.325022],
            [49.055362, 56.321641],
            [49.03177, 56.308431],
            [49.031806, 56.284657],
            [48.973401, 56.275231],
            [48.922421, 56.256181],
            [48.937537, 56.225379],
            [49.009691, 56.222237],
            [49.02417, 56.235341],
            [49.04553, 56.215833],
            [49.069652, 56.206555],
            [49.043896, 56.188095],
            [49.009971, 56.204535],
            [48.984553, 56.200543],
            [48.980718, 56.162363],
            [48.953969, 56.160633],
            [48.9477, 56.139046],
            [48.929196, 56.13202],
            [48.894516, 56.133043],
            [48.848632, 56.115987],
            [48.845125, 56.094245],
            [48.829949, 56.080957],
            [48.835911, 56.066487],
            [48.829928, 56.050319],
            [48.777082, 56.057728],
            [48.744356, 56.039351],
            [48.738772, 56.052527],
            [48.716533, 56.059498],
            [48.717585, 56.0711],
            [48.691852, 56.059126],
            [48.714258, 56.048323],
            [48.711319, 56.040409],
            [48.677612, 56.032797],
            [48.712245, 56.013791],
            [48.726219, 56.013638],
            [48.703817, 55.994769],
            [48.695051, 55.954844],
            [48.668108, 55.946123],
            [48.666195, 55.938391],
            [48.626829, 55.94475],
            [48.577594, 55.945224],
            [48.521926, 55.931532],
            [48.474072, 55.93618],
            [48.415802, 55.91449],
            [48.383295, 55.867481],
            [48.40565, 55.856962],
            [48.401626, 55.845173],
            [48.425891, 55.834241],
            [48.452569, 55.834095],
            [48.459321, 55.825284],
            [48.406167, 55.825699],
            [48.347642, 55.830113],
            [48.322389, 55.835341],
            [48.285568, 55.854808],
            [48.25709, 55.85859],
            [48.222827, 55.876277],
            [48.234728, 55.909427],
            [48.169206, 55.935567],
            [48.096342, 55.939571],
            [48.053736, 55.953809],
            [48.047062, 55.945689],
            [47.983319, 55.976168],
            [48.005901, 55.98885],
            [47.995555, 55.998416],
            [47.952679, 56.01956],
            [47.926942, 56.01401],
            [47.931256, 56.03468],
            [47.918461, 56.079065],
            [47.933033, 56.092303],
            [47.909226, 56.115329],
            [47.878968, 56.125786],
            [47.872628, 56.11811],
            [47.844038, 56.126453],
            [47.759383, 56.135685],
            [47.742475, 56.130456],
            [47.672726, 56.141913],
            [47.653148, 56.131233],
            [47.602978, 56.143135],
            [47.562597, 56.14091],
            [47.51899, 56.144692],
            [47.50742, 56.159486],
            [47.480167, 56.150921],
            [47.425881, 56.156816],
            [47.43233, 56.225229],
            [47.380268, 56.227231],
            [47.392278, 56.324122],
            [47.251891, 56.329126],
            [47.247665, 56.311549],
            [47.150551, 56.314996],
            [47.146882, 56.277397],
            [47.091483, 56.280066],
            [47.042649, 56.270276],
            [46.984582, 56.252477],
            [46.985472, 56.243911],
            [46.934079, 56.225889],
            [46.918395, 56.213096],
            [46.822506, 56.193294],
            [46.775227, 56.210869],
            [46.753424, 56.206976],
            [46.76911, 56.199189],
            [46.787242, 56.203416],
            [46.796975, 56.190563],
            [46.754427, 56.163369],
            [46.760435, 56.155916],
            [46.734628, 56.129107],
            [46.706778, 56.123864],
            [46.655615, 56.099032],
            [46.619727, 56.127174],
            [46.586121, 56.132553],
            [46.516558, 56.113211],
            [46.516744, 56.122578],
            [46.48709, 56.124691],
            [46.44351, 56.112973],
            [46.414245, 56.129863],
            [46.38668, 56.108821],
            [46.321702, 56.086495],
            [46.30614, 56.072167],
            [46.238717, 56.06547],
            [46.200673, 56.045446],
            [46.202034, 56.032768],
            [46.185308, 56.031569],
            [46.13582, 56.029873],
            [46.155905, 56.049051],
            [46.086554, 56.039751],
            [46.109213, 56.071766],
            [46.081232, 56.067837],
            [46.054453, 56.073778],
            [46.056341, 56.098967],
            [46.044256, 56.104603],
            [46.047044, 56.121547],
            [46.06917, 56.124006],
            [46.110839, 56.150104],
            [46.080179, 56.153401],
            [46.094822, 56.170396],
            [46.076757, 56.174244],
            [46.042232, 56.158424],
            [46.025664, 56.168044],
            [45.996376, 56.171785],
            [45.963529, 56.162499],
            [45.938073, 56.187445],
            [45.936375, 56.208115],
            [45.917684, 56.219199],
            [45.897548, 56.219832],
            [45.899462, 56.237331],
            [45.887131, 56.234528],
            [45.8665, 56.258791],
            [45.92044, 56.256409],
            [45.926267, 56.296612],
            [45.923133, 56.313684],
            [45.970041, 56.318456],
            [45.945594, 56.364507],
            [45.934263, 56.373806],
            [45.952968, 56.403843],
            [45.974452, 56.412608],
            [45.963762, 56.428534],
            [45.916196, 56.426503],
            [45.89578, 56.434306],
            [45.800968, 56.425005],
            [45.757037, 56.40865],
            [45.765588, 56.420194],
            [45.768473, 56.446275],
            [45.751156, 56.455361],
            [45.687663, 56.452901],
            [45.661902, 56.460918],
            [45.648753, 56.491061],
            [45.627695, 56.507308],
            [45.62887, 56.517783],
            [45.652172, 56.517356],
            [45.656875, 56.536062],
            [45.644155, 56.53403],
            [45.625555, 56.552308],
            [45.627051, 56.568021],
            [45.645435, 56.583093],
            [45.66978, 56.614284],
            [45.717073, 56.615134],
            [45.745056, 56.607893],
            [45.750614, 56.623178],
            [45.886151, 56.626066],
            [45.886363, 56.647338],
            [45.950749, 56.649393],
            [45.925907, 56.679297],
            [45.899815, 56.678731],
            [45.89827, 56.692968],
            [45.920138, 56.698752],
            [45.90186, 56.706662],
            [45.890717, 56.696738],
            [45.865013, 56.690408],
            [45.861989, 56.706302],
            [45.882191, 56.719382],
            [45.878556, 56.737553],
            [45.894697, 56.726543],
            [45.918534, 56.722482],
            [45.941509, 56.859195],
            [45.978172, 56.858447],
            [45.978706, 56.869991],
            [46.091476, 56.862831],
            [46.143958, 56.878652],
            [46.207344, 56.882714],
            [46.239731, 56.898962],
            [46.273081, 56.902918],
            [46.303545, 56.887419],
            [46.314766, 56.945247],
            [46.447952, 56.938835],
            [46.464093, 56.930498],
            [46.481516, 56.937874],
            [46.514011, 56.933599],
            [46.505353, 56.913076],
            [46.53881, 56.913397],
            [46.546719, 56.932744],
            [46.59311, 56.929966],
            [46.606471, 56.923338],
            [46.624214, 56.939052],
            [46.67317, 56.942033],
            [46.6814, 56.951772],
            [46.677658, 56.971333],
            [46.717635, 56.971548],
            [46.721544, 56.963392],
          ],
          [
            [49.524869, 56.485832],
            [49.546705, 56.483279],
            [49.551464, 56.495247],
            [49.534847, 56.5034],
            [49.524869, 56.485832],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5478",
      properties: { name: "Москва", density: 0, path: "/world/Russia/Москва" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [37.045003, 55.142201],
              [37.01989, 55.16112],
              [37.015248, 55.183382],
              [36.982938, 55.180355],
              [36.961636, 55.208977],
              [36.947708, 55.218168],
              [36.950132, 55.238262],
              [36.93724, 55.241391],
              [36.952517, 55.264835],
              [36.983412, 55.268138],
              [36.990038, 55.276139],
              [37.026036, 55.283021],
              [36.983812, 55.296971],
              [36.986441, 55.315589],
              [36.94473, 55.329369],
              [36.936134, 55.341257],
              [36.856056, 55.380332],
              [36.848266, 55.392082],
              [36.861874, 55.401035],
              [36.834296, 55.413987],
              [36.803101, 55.440833],
              [36.804512, 55.465508],
              [36.815208, 55.465972],
              [36.816053, 55.508359],
              [36.919474, 55.505845],
              [36.919754, 55.515446],
              [36.93967, 55.513477],
              [36.935058, 55.496155],
              [36.977651, 55.493772],
              [36.973446, 55.45546],
              [36.994017, 55.458318],
              [37.019234, 55.445129],
              [37.020071, 55.456786],
              [37.036818, 55.464061],
              [37.060613, 55.46266],
              [37.089283, 55.441232],
              [37.110774, 55.448399],
              [37.117006, 55.438057],
              [37.1418, 55.443081],
              [37.095406, 55.463119],
              [37.093361, 55.470404],
              [37.139813, 55.473409],
              [37.125616, 55.497223],
              [37.136931, 55.513136],
              [37.118631, 55.513137],
              [37.115906, 55.529998],
              [37.12547, 55.549963],
              [37.087353, 55.590355],
              [37.116462, 55.605636],
              [37.123927, 55.598922],
              [37.146117, 55.60938],
              [37.172023, 55.605184],
              [37.185474, 55.618785],
              [37.227259, 55.620061],
              [37.240713, 55.643688],
              [37.271434, 55.651479],
              [37.305682, 55.646307],
              [37.322908, 55.650889],
              [37.319522, 55.664281],
              [37.358452, 55.662576],
              [37.369999, 55.667997],
              [37.393928, 55.660883],
              [37.415457, 55.664765],
              [37.404032, 55.671043],
              [37.417481, 55.680776],
              [37.386661, 55.711147],
              [37.368948, 55.745982],
              [37.370601, 55.788192],
              [37.344497, 55.768598],
              [37.332302, 55.771934],
              [37.348733, 55.796382],
              [37.374481, 55.792363],
              [37.393919, 55.829862],
              [37.362157, 55.822037],
              [37.333004, 55.845285],
              [37.342022, 55.860207],
              [37.377591, 55.868329],
              [37.376182, 55.854507],
              [37.394423, 55.854571],
              [37.411159, 55.871002],
              [37.372702, 55.881694],
              [37.365754, 55.914104],
              [37.377823, 55.921114],
              [37.356463, 55.929954],
              [37.343707, 55.924183],
              [37.33132, 55.93387],
              [37.354099, 55.938907],
              [37.335026, 55.953589],
              [37.371972, 55.956138],
              [37.394025, 55.948647],
              [37.413904, 55.954532],
              [37.408413, 55.924135],
              [37.389973, 55.903831],
              [37.40942, 55.880688],
              [37.485935, 55.888399],
              [37.537191, 55.907591],
              [37.519479, 55.941763],
              [37.543175, 55.943897],
              [37.537429, 55.952877],
              [37.563216, 55.951335],
              [37.55595, 55.909555],
              [37.578601, 55.911434],
              [37.637352, 55.898608],
              [37.703052, 55.893412],
              [37.830081, 55.829153],
              [37.837369, 55.82249],
              [37.843401, 55.774735],
              [37.842655, 55.746734],
              [37.882541, 55.74934],
              [37.890085, 55.741604],
              [37.864238, 55.734717],
              [37.876396, 55.720437],
              [37.923585, 55.731225],
              [37.967428, 55.716249],
              [37.944604, 55.697207],
              [37.960204, 55.692774],
              [37.963378, 55.673656],
              [37.920472, 55.676143],
              [37.913291, 55.683317],
              [37.928568, 55.695127],
              [37.905442, 55.706993],
              [37.886971, 55.705215],
              [37.856728, 55.675683],
              [37.832464, 55.683038],
              [37.840962, 55.655559],
              [37.795848, 55.624226],
              [37.753831, 55.601597],
              [37.684567, 55.574055],
              [37.666359, 55.571517],
              [37.60032, 55.575374],
              [37.589454, 55.557683],
              [37.578504, 55.521679],
              [37.608096, 55.509844],
              [37.611354, 55.489635],
              [37.565485, 55.487418],
              [37.560845, 55.473387],
              [37.54475, 55.472646],
              [37.54592, 55.459718],
              [37.532764, 55.453685],
              [37.538293, 55.43757],
              [37.5026, 55.438014],
              [37.484551, 55.458003],
              [37.457993, 55.465437],
              [37.445218, 55.481899],
              [37.411257, 55.462932],
              [37.394573, 55.469007],
              [37.374121, 55.445515],
              [37.400983, 55.44449],
              [37.387338, 55.434646],
              [37.427369, 55.432866],
              [37.437352, 55.415383],
              [37.467019, 55.41344],
              [37.471193, 55.390449],
              [37.462693, 55.367786],
              [37.454663, 55.380059],
              [37.427315, 55.364443],
              [37.443347, 55.352592],
              [37.420927, 55.350844],
              [37.406942, 55.336124],
              [37.429062, 55.314933],
              [37.409951, 55.309164],
              [37.384066, 55.316204],
              [37.415868, 55.290256],
              [37.405315, 55.250576],
              [37.351865, 55.238635],
              [37.333392, 55.228672],
              [37.300847, 55.224526],
              [37.306992, 55.240122],
              [37.271561, 55.258824],
              [37.269605, 55.240722],
              [37.245516, 55.239265],
              [37.219005, 55.253109],
              [37.19394, 55.23481],
              [37.163361, 55.231579],
              [37.169879, 55.164339],
              [37.140776, 55.164378],
              [37.14208, 55.155676],
              [37.11121, 55.149187],
              [37.064393, 55.147119],
              [37.045003, 55.142201],
            ],
          ],
          [
            [
              [37.845327, 55.813562],
              [37.872252, 55.822498],
              [37.884128, 55.816661],
              [37.865743, 55.804208],
              [37.845327, 55.813562],
            ],
          ],
          [
            [
              [37.131602, 56.016446],
              [37.16173, 56.013221],
              [37.188978, 56.021224],
              [37.260562, 55.993143],
              [37.261109, 55.968067],
              [37.242027, 55.979274],
              [37.218912, 55.98036],
              [37.205811, 55.963213],
              [37.217713, 55.948026],
              [37.18251, 55.958733],
              [37.146611, 55.963719],
              [37.144393, 55.989316],
              [37.151171, 55.999549],
              [37.131602, 56.016446],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5486",
      properties: {
        name: "Московская область",
        density: 0,
        path: "/world/Russia/Московская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.148495, 55.957501],
            [35.153871, 55.977983],
            [35.175032, 55.984885],
            [35.17658, 55.996691],
            [35.196821, 56.012079],
            [35.215562, 56.010689],
            [35.239819, 56.031182],
            [35.23879, 56.046528],
            [35.266758, 56.039058],
            [35.287845, 56.062588],
            [35.304736, 56.058311],
            [35.323369, 56.077495],
            [35.317324, 56.083591],
            [35.338992, 56.094214],
            [35.308981, 56.106635],
            [35.287279, 56.103389],
            [35.28455, 56.124996],
            [35.308985, 56.130453],
            [35.291909, 56.140434],
            [35.304932, 56.154468],
            [35.320517, 56.153519],
            [35.363129, 56.178871],
            [35.348939, 56.183957],
            [35.36386, 56.196974],
            [35.338269, 56.199408],
            [35.320227, 56.209248],
            [35.315791, 56.227991],
            [35.363012, 56.237404],
            [35.392907, 56.235105],
            [35.4078, 56.247951],
            [35.442007, 56.239189],
            [35.486339, 56.247309],
            [35.50231, 56.259486],
            [35.474962, 56.276813],
            [35.491342, 56.286963],
            [35.467814, 56.307892],
            [35.480621, 56.32731],
            [35.465698, 56.330621],
            [35.480977, 56.346185],
            [35.470512, 56.361216],
            [35.482453, 56.37352],
            [35.505408, 56.367575],
            [35.520323, 56.39541],
            [35.497957, 56.399551],
            [35.482958, 56.383923],
            [35.461629, 56.37887],
            [35.438798, 56.385666],
            [35.42172, 56.38132],
            [35.419005, 56.396036],
            [35.430117, 56.42123],
            [35.456791, 56.432005],
            [35.424872, 56.443439],
            [35.434271, 56.458656],
            [35.477568, 56.45257],
            [35.473405, 56.437806],
            [35.518457, 56.430333],
            [35.599685, 56.477777],
            [35.62024, 56.477052],
            [35.630245, 56.454778],
            [35.658578, 56.460063],
            [35.664086, 56.447809],
            [35.686079, 56.445234],
            [35.681303, 56.429147],
            [35.71819, 56.411086],
            [35.72898, 56.41383],
            [35.735272, 56.389721],
            [35.769391, 56.389768],
            [35.801089, 56.406391],
            [35.839276, 56.397202],
            [35.843538, 56.380809],
            [35.862528, 56.386641],
            [35.871625, 56.37212],
            [35.953103, 56.370394],
            [35.958513, 56.378032],
            [36.012155, 56.37822],
            [36.0389, 56.365898],
            [36.049949, 56.373256],
            [36.073525, 56.363181],
            [36.062368, 56.353946],
            [36.06473, 56.335649],
            [36.088667, 56.335662],
            [36.092087, 56.367206],
            [36.107304, 56.388135],
            [36.124693, 56.386915],
            [36.126592, 56.399513],
            [36.273977, 56.417564],
            [36.305615, 56.441491],
            [36.375977, 56.451898],
            [36.392351, 56.466933],
            [36.425619, 56.473675],
            [36.54243, 56.473843],
            [36.540478, 56.496709],
            [36.58957, 56.499836],
            [36.614859, 56.517958],
            [36.649127, 56.503291],
            [36.681721, 56.498062],
            [36.65287, 56.523223],
            [36.677301, 56.532703],
            [36.740807, 56.546422],
            [36.813078, 56.579103],
            [36.803018, 56.597475],
            [36.873056, 56.57975],
            [36.970927, 56.564529],
            [36.969187, 56.547287],
            [37.037426, 56.533722],
            [37.055548, 56.526247],
            [37.071835, 56.545359],
            [37.098352, 56.540526],
            [37.122725, 56.568503],
            [37.148042, 56.587675],
            [37.126291, 56.601612],
            [37.13401, 56.618559],
            [37.115391, 56.640885],
            [37.157952, 56.651802],
            [37.159729, 56.661342],
            [37.129726, 56.668146],
            [37.158811, 56.681931],
            [37.154296, 56.690563],
            [37.17425, 56.696425],
            [37.13194, 56.714913],
            [37.123451, 56.726781],
            [37.066831, 56.755621],
            [37.105904, 56.761241],
            [37.100301, 56.769769],
            [37.167297, 56.779564],
            [37.178298, 56.769323],
            [37.205593, 56.769717],
            [37.215044, 56.783441],
            [37.24286, 56.785421],
            [37.228643, 56.758945],
            [37.249196, 56.745381],
            [37.274683, 56.759685],
            [37.280951, 56.77543],
            [37.342833, 56.763786],
            [37.350858, 56.779353],
            [37.442927, 56.796534],
            [37.455039, 56.794323],
            [37.463114, 56.776999],
            [37.483008, 56.787671],
            [37.515798, 56.821924],
            [37.552028, 56.839534],
            [37.54146, 56.852674],
            [37.54583, 56.866268],
            [37.564686, 56.858637],
            [37.574944, 56.873177],
            [37.537589, 56.881876],
            [37.524739, 56.892526],
            [37.531958, 56.909493],
            [37.548218, 56.918446],
            [37.585203, 56.914738],
            [37.591068, 56.929787],
            [37.645471, 56.935101],
            [37.649372, 56.94607],
            [37.678639, 56.940173],
            [37.698307, 56.958512],
            [37.758185, 56.949991],
            [37.747218, 56.935464],
            [37.762928, 56.925655],
            [37.785248, 56.929675],
            [37.798462, 56.908819],
            [37.825187, 56.91144],
            [37.82892, 56.90489],
            [37.857343, 56.906122],
            [37.882701, 56.893781],
            [37.887726, 56.878111],
            [37.910586, 56.878547],
            [37.91016, 56.888007],
            [37.942993, 56.88793],
            [37.962562, 56.870963],
            [37.984001, 56.865396],
            [37.977721, 56.849466],
            [37.983326, 56.825293],
            [37.994002, 56.822902],
            [37.99271, 56.767322],
            [38.009817, 56.771481],
            [38.051567, 56.767872],
            [38.050131, 56.753236],
            [38.067499, 56.761446],
            [38.103836, 56.759725],
            [38.128958, 56.770051],
            [38.164173, 56.767839],
            [38.217557, 56.771895],
            [38.241535, 56.760653],
            [38.250716, 56.765376],
            [38.244299, 56.793463],
            [38.271916, 56.773607],
            [38.304354, 56.759976],
            [38.303159, 56.73902],
            [38.319536, 56.721839],
            [38.314092, 56.703681],
            [38.301448, 56.69824],
            [38.318131, 56.658578],
            [38.317845, 56.642277],
            [38.339252, 56.631515],
            [38.330983, 56.603813],
            [38.357459, 56.596908],
            [38.358751, 56.57327],
            [38.309799, 56.555078],
            [38.27379, 56.531804],
            [38.295201, 56.511473],
            [38.335808, 56.517649],
            [38.335904, 56.505503],
            [38.298436, 56.49496],
            [38.319255, 56.48274],
            [38.343041, 56.48106],
            [38.354285, 56.452799],
            [38.365241, 56.463565],
            [38.394348, 56.459764],
            [38.401212, 56.441602],
            [38.382539, 56.400235],
            [38.431881, 56.396586],
            [38.446269, 56.3865],
            [38.422418, 56.360341],
            [38.414406, 56.321388],
            [38.43861, 56.312692],
            [38.435805, 56.292228],
            [38.417775, 56.282347],
            [38.396988, 56.283185],
            [38.414288, 56.23558],
            [38.442024, 56.223921],
            [38.459454, 56.202617],
            [38.491483, 56.204928],
            [38.503447, 56.217831],
            [38.527775, 56.213561],
            [38.509715, 56.199692],
            [38.502022, 56.182754],
            [38.521041, 56.182146],
            [38.509883, 56.16163],
            [38.522638, 56.141701],
            [38.510329, 56.133191],
            [38.533169, 56.114595],
            [38.529293, 56.090927],
            [38.545948, 56.064945],
            [38.586439, 56.053546],
            [38.589611, 56.025375],
            [38.606224, 56.014272],
            [38.576002, 55.998682],
            [38.611132, 55.976905],
            [38.692028, 55.988925],
            [38.73143, 55.979875],
            [38.793372, 55.943358],
            [38.806929, 55.940421],
            [38.838383, 55.91481],
            [38.885039, 55.9452],
            [38.900645, 55.947065],
            [38.919415, 55.933525],
            [38.9572, 55.930955],
            [39.020238, 55.950411],
            [39.049121, 55.942896],
            [39.028362, 55.935173],
            [39.016748, 55.944102],
            [39.013651, 55.92852],
            [39.042563, 55.921429],
            [39.057092, 55.942105],
            [39.063332, 55.931282],
            [39.028881, 55.908286],
            [39.049246, 55.90262],
            [39.075125, 55.868746],
            [39.05917, 55.857344],
            [39.087574, 55.863697],
            [39.103441, 55.859931],
            [39.10091, 55.846719],
            [39.150045, 55.854908],
            [39.16766, 55.840038],
            [39.190104, 55.846779],
            [39.204831, 55.842143],
            [39.240002, 55.845131],
            [39.27683, 55.841437],
            [39.280373, 55.850979],
            [39.327174, 55.864863],
            [39.327168, 55.848],
            [39.29476, 55.832233],
            [39.308908, 55.805798],
            [39.321072, 55.802812],
            [39.325843, 55.773018],
            [39.351905, 55.768562],
            [39.402762, 55.785803],
            [39.406172, 55.778188],
            [39.437278, 55.78078],
            [39.423642, 55.773447],
            [39.470079, 55.773557],
            [39.470275, 55.74699],
            [39.521138, 55.745227],
            [39.523467, 55.787257],
            [39.594957, 55.788482],
            [39.594754, 55.798157],
            [39.660764, 55.795394],
            [39.660624, 55.785725],
            [39.70341, 55.787435],
            [39.696764, 55.814088],
            [39.713854, 55.813868],
            [39.731196, 55.824883],
            [39.732105, 55.842353],
            [39.791101, 55.839488],
            [39.788881, 55.831511],
            [39.849886, 55.84061],
            [39.865998, 55.832431],
            [39.889102, 55.833195],
            [39.90924, 55.802929],
            [39.896797, 55.782985],
            [39.903745, 55.773692],
            [39.886481, 55.760985],
            [39.903052, 55.741896],
            [39.92594, 55.733167],
            [39.933337, 55.720279],
            [39.958382, 55.722522],
            [39.994282, 55.687658],
            [40.014423, 55.6869],
            [40.05359, 55.655344],
            [40.041447, 55.635321],
            [40.093163, 55.607689],
            [40.074838, 55.57724],
            [40.046829, 55.581884],
            [40.048871, 55.526498],
            [40.102973, 55.528707],
            [40.142822, 55.523782],
            [40.167802, 55.52662],
            [40.171194, 55.506192],
            [40.200484, 55.497414],
            [40.194029, 55.461913],
            [40.153403, 55.460587],
            [40.141305, 55.450727],
            [40.142569, 55.418107],
            [40.178833, 55.393976],
            [40.190923, 55.365007],
            [40.202142, 55.348554],
            [40.178899, 55.334834],
            [40.205688, 55.315762],
            [40.179502, 55.312063],
            [40.154644, 55.264389],
            [40.12684, 55.264792],
            [40.063663, 55.248067],
            [40.056419, 55.239258],
            [40.034136, 55.237863],
            [40.004695, 55.224077],
            [39.993914, 55.199897],
            [39.987484, 55.213318],
            [39.955369, 55.207433],
            [39.922507, 55.215311],
            [39.882855, 55.203867],
            [39.837854, 55.204526],
            [39.784906, 55.178792],
            [39.753887, 55.153743],
            [39.715144, 55.130186],
            [39.705639, 55.117414],
            [39.755965, 55.11947],
            [39.775137, 55.116365],
            [39.799059, 55.137608],
            [39.823104, 55.136054],
            [39.834256, 55.115608],
            [39.862565, 55.111142],
            [39.804256, 55.005216],
            [39.791136, 55.021561],
            [39.795772, 55.030511],
            [39.773741, 55.035157],
            [39.769973, 55.003971],
            [39.701278, 55.005641],
            [39.669218, 55.013973],
            [39.669568, 55.025425],
            [39.625368, 55.02508],
            [39.617584, 55.000383],
            [39.602885, 54.988013],
            [39.549097, 54.988852],
            [39.517518, 54.983877],
            [39.520583, 54.969754],
            [39.475166, 54.945866],
            [39.46555, 54.931445],
            [39.473672, 54.909501],
            [39.486499, 54.901426],
            [39.461383, 54.901958],
            [39.466207, 54.892387],
            [39.429527, 54.886132],
            [39.435377, 54.877063],
            [39.398601, 54.866246],
            [39.390504, 54.853952],
            [39.415177, 54.839913],
            [39.387404, 54.824719],
            [39.361286, 54.799827],
            [39.362821, 54.782729],
            [39.337742, 54.774516],
            [39.321955, 54.75371],
            [39.361288, 54.755646],
            [39.370879, 54.747372],
            [39.32745, 54.725509],
            [39.304687, 54.724338],
            [39.300491, 54.73527],
            [39.245611, 54.731869],
            [39.232055, 54.718611],
            [39.240709, 54.695916],
            [39.232416, 54.684127],
            [39.256321, 54.677558],
            [39.256482, 54.659497],
            [39.231811, 54.64658],
            [39.226063, 54.635703],
            [39.193928, 54.64193],
            [39.182282, 54.638054],
            [39.162813, 54.660841],
            [39.150494, 54.643067],
            [39.127352, 54.645934],
            [39.13195, 54.634659],
            [39.111228, 54.63285],
            [39.099955, 54.646041],
            [39.082056, 54.63447],
            [39.044773, 54.635404],
            [39.02361, 54.64112],
            [38.992897, 54.628108],
            [38.993967, 54.615927],
            [38.971764, 54.600977],
            [38.952496, 54.609956],
            [38.946505, 54.634241],
            [38.864631, 54.615156],
            [38.849023, 54.62562],
            [38.826756, 54.60643],
            [38.836843, 54.58675],
            [38.823007, 54.578467],
            [38.834331, 54.56961],
            [38.79701, 54.551414],
            [38.807775, 54.538247],
            [38.851213, 54.540496],
            [38.838244, 54.52585],
            [38.815089, 54.527942],
            [38.80134, 54.514148],
            [38.802991, 54.497461],
            [38.789806, 54.494802],
            [38.779489, 54.47923],
            [38.799514, 54.47006],
            [38.800949, 54.453427],
            [38.853546, 54.457659],
            [38.880352, 54.441288],
            [38.930667, 54.43928],
            [38.940648, 54.407989],
            [38.889513, 54.402096],
            [38.869913, 54.392079],
            [38.877143, 54.371187],
            [38.857918, 54.370132],
            [38.85463, 54.386906],
            [38.836482, 54.393119],
            [38.819578, 54.374625],
            [38.773931, 54.374373],
            [38.778134, 54.363574],
            [38.712983, 54.348532],
            [38.704104, 54.316263],
            [38.704244, 54.289497],
            [38.719932, 54.284554],
            [38.703614, 54.261294],
            [38.665982, 54.256714],
            [38.639191, 54.264471],
            [38.632173, 54.280508],
            [38.608143, 54.276476],
            [38.570939, 54.290465],
            [38.528507, 54.289467],
            [38.510521, 54.296849],
            [38.519661, 54.320857],
            [38.50432, 54.347055],
            [38.528351, 54.380648],
            [38.527101, 54.395264],
            [38.484496, 54.397746],
            [38.453655, 54.416074],
            [38.470833, 54.422766],
            [38.450583, 54.43995],
            [38.433834, 54.441871],
            [38.420126, 54.456837],
            [38.386291, 54.466623],
            [38.39772, 54.475176],
            [38.398851, 54.5073],
            [38.379174, 54.516165],
            [38.377215, 54.526831],
            [38.423841, 54.541588],
            [38.438378, 54.553412],
            [38.457998, 54.549169],
            [38.446135, 54.57254],
            [38.471292, 54.571361],
            [38.516588, 54.59149],
            [38.483182, 54.630129],
            [38.456644, 54.653588],
            [38.422812, 54.651189],
            [38.391978, 54.63557],
            [38.362991, 54.653668],
            [38.331786, 54.65502],
            [38.313244, 54.647208],
            [38.314649, 54.661571],
            [38.291259, 54.677533],
            [38.255999, 54.662748],
            [38.218403, 54.677807],
            [38.216253, 54.696624],
            [38.187138, 54.692211],
            [38.177162, 54.697053],
            [38.134454, 54.686644],
            [38.119556, 54.714024],
            [38.072864, 54.707359],
            [38.050275, 54.720807],
            [38.027778, 54.718491],
            [38.031018, 54.736143],
            [38.04482, 54.734262],
            [38.039657, 54.764673],
            [37.998749, 54.783971],
            [37.983619, 54.774917],
            [37.955153, 54.782063],
            [37.982201, 54.798755],
            [37.989826, 54.825024],
            [37.97445, 54.839807],
            [37.938469, 54.849673],
            [37.897804, 54.849918],
            [37.869544, 54.843173],
            [37.792103, 54.834333],
            [37.74816, 54.83237],
            [37.779168, 54.815614],
            [37.769412, 54.798489],
            [37.775636, 54.771395],
            [37.763837, 54.771646],
            [37.758426, 54.743],
            [37.727261, 54.724849],
            [37.682832, 54.717463],
            [37.629987, 54.737922],
            [37.626172, 54.743296],
            [37.563038, 54.742341],
            [37.547996, 54.737604],
            [37.556287, 54.760067],
            [37.544787, 54.780428],
            [37.526134, 54.77949],
            [37.497114, 54.793084],
            [37.503987, 54.807357],
            [37.446294, 54.841587],
            [37.391636, 54.843917],
            [37.375017, 54.840172],
            [37.359138, 54.848037],
            [37.358095, 54.834569],
            [37.334343, 54.846043],
            [37.326773, 54.839153],
            [37.303605, 54.850802],
            [37.294629, 54.826633],
            [37.270913, 54.830663],
            [37.272035, 54.839777],
            [37.261369, 54.850796],
            [37.235728, 54.845297],
            [37.223933, 54.853203],
            [37.2011, 54.853453],
            [37.165285, 54.86658],
            [37.175909, 54.878547],
            [37.16715, 54.895109],
            [37.192994, 54.923256],
            [37.160496, 54.950444],
            [37.171741, 54.968152],
            [37.134298, 54.991398],
            [37.149899, 55.000159],
            [37.122318, 55.023302],
            [37.095868, 55.032409],
            [37.083806, 55.05655],
            [37.118159, 55.063488],
            [37.111292, 55.077172],
            [37.116322, 55.098063],
            [37.070936, 55.114381],
            [37.057237, 55.109546],
            [37.059874, 55.127168],
            [37.045003, 55.142201],
            [37.064393, 55.147119],
            [37.11121, 55.149187],
            [37.14208, 55.155676],
            [37.140776, 55.164378],
            [37.169879, 55.164339],
            [37.163361, 55.231579],
            [37.19394, 55.23481],
            [37.219005, 55.253109],
            [37.245516, 55.239265],
            [37.269605, 55.240722],
            [37.271561, 55.258824],
            [37.306992, 55.240122],
            [37.300847, 55.224526],
            [37.333392, 55.228672],
            [37.351865, 55.238635],
            [37.405315, 55.250576],
            [37.415868, 55.290256],
            [37.384066, 55.316204],
            [37.409951, 55.309164],
            [37.429062, 55.314933],
            [37.406942, 55.336124],
            [37.420927, 55.350844],
            [37.443347, 55.352592],
            [37.427315, 55.364443],
            [37.454663, 55.380059],
            [37.462693, 55.367786],
            [37.471193, 55.390449],
            [37.467019, 55.41344],
            [37.437352, 55.415383],
            [37.427369, 55.432866],
            [37.387338, 55.434646],
            [37.400983, 55.44449],
            [37.374121, 55.445515],
            [37.394573, 55.469007],
            [37.411257, 55.462932],
            [37.445218, 55.481899],
            [37.457993, 55.465437],
            [37.484551, 55.458003],
            [37.5026, 55.438014],
            [37.538293, 55.43757],
            [37.532764, 55.453685],
            [37.54592, 55.459718],
            [37.54475, 55.472646],
            [37.560845, 55.473387],
            [37.565485, 55.487418],
            [37.611354, 55.489635],
            [37.608096, 55.509844],
            [37.578504, 55.521679],
            [37.589454, 55.557683],
            [37.60032, 55.575374],
            [37.666359, 55.571517],
            [37.684567, 55.574055],
            [37.753831, 55.601597],
            [37.795848, 55.624226],
            [37.840962, 55.655559],
            [37.832464, 55.683038],
            [37.856728, 55.675683],
            [37.886971, 55.705215],
            [37.905442, 55.706993],
            [37.928568, 55.695127],
            [37.913291, 55.683317],
            [37.920472, 55.676143],
            [37.963378, 55.673656],
            [37.960204, 55.692774],
            [37.944604, 55.697207],
            [37.967428, 55.716249],
            [37.923585, 55.731225],
            [37.876396, 55.720437],
            [37.864238, 55.734717],
            [37.890085, 55.741604],
            [37.882541, 55.74934],
            [37.842655, 55.746734],
            [37.843401, 55.774735],
            [37.837369, 55.82249],
            [37.830081, 55.829153],
            [37.703052, 55.893412],
            [37.637352, 55.898608],
            [37.578601, 55.911434],
            [37.55595, 55.909555],
            [37.563216, 55.951335],
            [37.537429, 55.952877],
            [37.543175, 55.943897],
            [37.519479, 55.941763],
            [37.537191, 55.907591],
            [37.485935, 55.888399],
            [37.40942, 55.880688],
            [37.389973, 55.903831],
            [37.408413, 55.924135],
            [37.413904, 55.954532],
            [37.394025, 55.948647],
            [37.371972, 55.956138],
            [37.335026, 55.953589],
            [37.354099, 55.938907],
            [37.33132, 55.93387],
            [37.343707, 55.924183],
            [37.356463, 55.929954],
            [37.377823, 55.921114],
            [37.365754, 55.914104],
            [37.372702, 55.881694],
            [37.411159, 55.871002],
            [37.394423, 55.854571],
            [37.376182, 55.854507],
            [37.377591, 55.868329],
            [37.342022, 55.860207],
            [37.333004, 55.845285],
            [37.362157, 55.822037],
            [37.393919, 55.829862],
            [37.374481, 55.792363],
            [37.348733, 55.796382],
            [37.332302, 55.771934],
            [37.344497, 55.768598],
            [37.370601, 55.788192],
            [37.368948, 55.745982],
            [37.386661, 55.711147],
            [37.417481, 55.680776],
            [37.404032, 55.671043],
            [37.415457, 55.664765],
            [37.393928, 55.660883],
            [37.369999, 55.667997],
            [37.358452, 55.662576],
            [37.319522, 55.664281],
            [37.322908, 55.650889],
            [37.305682, 55.646307],
            [37.271434, 55.651479],
            [37.240713, 55.643688],
            [37.227259, 55.620061],
            [37.185474, 55.618785],
            [37.172023, 55.605184],
            [37.146117, 55.60938],
            [37.123927, 55.598922],
            [37.116462, 55.605636],
            [37.087353, 55.590355],
            [37.12547, 55.549963],
            [37.115906, 55.529998],
            [37.118631, 55.513137],
            [37.136931, 55.513136],
            [37.125616, 55.497223],
            [37.139813, 55.473409],
            [37.093361, 55.470404],
            [37.095406, 55.463119],
            [37.1418, 55.443081],
            [37.117006, 55.438057],
            [37.110774, 55.448399],
            [37.089283, 55.441232],
            [37.060613, 55.46266],
            [37.036818, 55.464061],
            [37.020071, 55.456786],
            [37.019234, 55.445129],
            [36.994017, 55.458318],
            [36.973446, 55.45546],
            [36.977651, 55.493772],
            [36.935058, 55.496155],
            [36.93967, 55.513477],
            [36.919754, 55.515446],
            [36.919474, 55.505845],
            [36.816053, 55.508359],
            [36.815208, 55.465972],
            [36.804512, 55.465508],
            [36.803101, 55.440833],
            [36.834296, 55.413987],
            [36.861874, 55.401035],
            [36.848266, 55.392082],
            [36.856056, 55.380332],
            [36.936134, 55.341257],
            [36.94473, 55.329369],
            [36.986441, 55.315589],
            [36.983812, 55.296971],
            [37.026036, 55.283021],
            [36.990038, 55.276139],
            [36.983412, 55.268138],
            [36.952517, 55.264835],
            [36.93724, 55.241391],
            [36.907475, 55.232415],
            [36.872234, 55.232673],
            [36.855839, 55.227342],
            [36.872101, 55.215076],
            [36.840339, 55.207888],
            [36.813606, 55.194054],
            [36.80179, 55.206089],
            [36.811717, 55.21252],
            [36.794096, 55.226977],
            [36.799951, 55.242472],
            [36.788721, 55.256409],
            [36.763246, 55.269828],
            [36.726683, 55.260325],
            [36.696707, 55.261768],
            [36.659326, 55.27749],
            [36.621329, 55.265391],
            [36.600553, 55.2829],
            [36.612989, 55.29431],
            [36.607751, 55.311175],
            [36.625885, 55.310121],
            [36.604941, 55.333068],
            [36.617343, 55.34872],
            [36.600825, 55.35128],
            [36.590025, 55.337704],
            [36.536994, 55.324067],
            [36.535195, 55.307073],
            [36.51643, 55.296301],
            [36.502358, 55.304707],
            [36.461143, 55.301174],
            [36.462573, 55.293134],
            [36.435488, 55.290908],
            [36.417366, 55.303984],
            [36.38434, 55.293977],
            [36.389854, 55.281104],
            [36.368929, 55.277218],
            [36.383547, 55.258372],
            [36.362528, 55.249135],
            [36.342833, 55.253636],
            [36.328046, 55.215914],
            [36.312246, 55.210303],
            [36.295486, 55.190839],
            [36.313471, 55.185734],
            [36.264784, 55.171484],
            [36.249227, 55.190781],
            [36.237559, 55.188104],
            [36.192776, 55.193391],
            [36.166193, 55.190972],
            [36.13988, 55.174077],
            [36.115347, 55.184706],
            [36.103751, 55.210854],
            [36.070793, 55.218157],
            [36.048653, 55.215264],
            [36.038665, 55.227703],
            [36.022026, 55.214361],
            [35.984835, 55.224288],
            [35.958628, 55.241411],
            [35.930252, 55.232077],
            [35.888598, 55.247898],
            [35.886403, 55.23867],
            [35.860387, 55.239271],
            [35.842833, 55.232315],
            [35.814427, 55.246011],
            [35.824452, 55.264877],
            [35.793411, 55.261104],
            [35.741156, 55.295578],
            [35.718431, 55.279478],
            [35.706761, 55.290904],
            [35.663479, 55.278338],
            [35.599991, 55.289704],
            [35.57308, 55.290267],
            [35.560637, 55.275855],
            [35.576835, 55.269049],
            [35.560646, 55.250421],
            [35.535004, 55.258387],
            [35.49696, 55.250028],
            [35.508232, 55.240189],
            [35.481539, 55.239919],
            [35.446271, 55.229013],
            [35.396147, 55.234589],
            [35.356606, 55.249262],
            [35.359539, 55.25922],
            [35.334221, 55.271752],
            [35.334374, 55.27902],
            [35.309887, 55.291157],
            [35.293924, 55.291095],
            [35.299363, 55.304631],
            [35.258645, 55.329882],
            [35.274107, 55.331069],
            [35.290961, 55.351858],
            [35.286192, 55.389008],
            [35.300277, 55.391562],
            [35.297793, 55.412433],
            [35.329487, 55.429493],
            [35.303344, 55.434904],
            [35.309344, 55.457494],
            [35.289005, 55.472355],
            [35.324762, 55.480205],
            [35.325435, 55.501841],
            [35.31357, 55.514627],
            [35.344685, 55.527586],
            [35.338802, 55.538373],
            [35.309436, 55.544395],
            [35.294308, 55.555006],
            [35.300606, 55.581443],
            [35.341906, 55.582126],
            [35.348577, 55.572621],
            [35.372138, 55.575828],
            [35.364621, 55.587866],
            [35.386857, 55.594644],
            [35.391128, 55.60589],
            [35.38264, 55.63067],
            [35.351132, 55.643017],
            [35.337077, 55.665767],
            [35.32325, 55.665981],
            [35.33759, 55.700594],
            [35.3165, 55.708153],
            [35.30433, 55.734254],
            [35.315471, 55.744795],
            [35.342574, 55.741519],
            [35.327064, 55.758336],
            [35.330782, 55.792565],
            [35.283169, 55.79983],
            [35.288269, 55.816958],
            [35.269949, 55.836746],
            [35.251602, 55.845286],
            [35.249547, 55.860075],
            [35.223436, 55.880218],
            [35.220673, 55.908027],
            [35.199279, 55.889126],
            [35.189813, 55.907153],
            [35.204486, 55.919457],
            [35.15835, 55.946356],
            [35.148495, 55.957501],
          ],
          [
            [37.131602, 56.016446],
            [37.151171, 55.999549],
            [37.144393, 55.989316],
            [37.146611, 55.963719],
            [37.18251, 55.958733],
            [37.217713, 55.948026],
            [37.205811, 55.963213],
            [37.218912, 55.98036],
            [37.242027, 55.979274],
            [37.261109, 55.968067],
            [37.260562, 55.993143],
            [37.188978, 56.021224],
            [37.16173, 56.013221],
            [37.131602, 56.016446],
          ],
          [
            [37.845327, 55.813562],
            [37.865743, 55.804208],
            [37.884128, 55.816661],
            [37.872252, 55.822498],
            [37.845327, 55.813562],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5495",
      properties: {
        name: "Мурманская область",
        density: 0,
        path: "/world/Russia/Мурманская область",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [37.70574, 68.6977],
              [37.72519, 68.70305],
              [37.77153, 68.69227],
              [37.72188, 68.69072],
              [37.70574, 68.6977],
            ],
          ],
          [
            [
              [37.30636, 68.80774],
              [37.30736, 68.82386],
              [37.35585, 68.81468],
              [37.37617, 68.80275],
              [37.30636, 68.80774],
            ],
          ],
          [
            [
              [37.4454, 68.78443],
              [37.53135, 68.76191],
              [37.50329, 68.76292],
              [37.4454, 68.78443],
            ],
          ],
          [
            [
              [31.59187, 69.83357],
              [31.54918, 69.83476],
              [31.58683, 69.84785],
              [31.59187, 69.83357],
            ],
          ],
          [
            [
              [32.90325, 66.77942],
              [32.9311, 66.7802],
              [32.92077, 66.76984],
              [32.90325, 66.77942],
            ],
          ],
          [
            [
              [32.650804, 66.9989],
              [32.667748, 67.007217],
              [32.689873, 66.987086],
              [32.664192, 66.98653],
              [32.650804, 66.9989],
            ],
          ],
          [
            [
              [32.695312, 66.958859],
              [32.67746, 66.95501],
              [32.67393, 66.97546],
              [32.695312, 66.958859],
            ],
          ],
          [
            [
              [35.96027, 69.1597],
              [36.01829, 69.12722],
              [36.04423, 69.13828],
              [36.14638, 69.11981],
              [36.21536, 69.11085],
              [36.23263, 69.10157],
              [36.2878, 69.09669],
              [36.31376, 69.07648],
              [36.42364, 69.06255],
              [36.36269, 69.06171],
              [36.39583, 69.03546],
              [36.47431, 69.03005],
              [36.5342, 69.01142],
              [36.56434, 69.01233],
              [36.62099, 69.00091],
              [36.6301, 68.98688],
              [36.69986, 68.97757],
              [36.74015, 68.96505],
              [36.71532, 68.9551],
              [36.78057, 68.94931],
              [36.77513, 68.94252],
              [36.84833, 68.92142],
              [36.88167, 68.91683],
              [36.9127, 68.90331],
              [36.94594, 68.89845],
              [36.99445, 68.87991],
              [37.06739, 68.86332],
              [37.12011, 68.84169],
              [37.18829, 68.83478],
              [37.23736, 68.80974],
              [37.24386, 68.798058],
              [37.26735, 68.80002],
              [37.31412, 68.78578],
              [37.38529, 68.7737],
              [37.44486, 68.73577],
              [37.5201, 68.73096],
              [37.56993, 68.7227],
              [37.61335, 68.70077],
              [37.67725, 68.6862],
              [37.68287, 68.67604],
              [37.72858, 68.6658],
              [37.74019, 68.6542],
              [37.80454, 68.64597],
              [37.79992, 68.63098],
              [37.82499, 68.62668],
              [37.84013, 68.60895],
              [37.89038, 68.6012],
              [37.91557, 68.58484],
              [37.98151, 68.56906],
              [38.02378, 68.53089],
              [38.06081, 68.52285],
              [38.09003, 68.49121],
              [38.1172, 68.48167],
              [38.16556, 68.47633],
              [38.20865, 68.44679],
              [38.25058, 68.44266],
              [38.27584, 68.42545],
              [38.34165, 68.41797],
              [38.3662, 68.39077],
              [38.40001, 68.37942],
              [38.4102, 68.36677],
              [38.43958, 68.37044],
              [38.44305, 68.39649],
              [38.47458, 68.39161],
              [38.51623, 68.35689],
              [38.5515, 68.35623],
              [38.59059, 68.34005],
              [38.62183, 68.34567],
              [38.60744, 68.35769],
              [38.6158, 68.37178],
              [38.64853, 68.37477],
              [38.65615, 68.36348],
              [38.74956, 68.32616],
              [38.77981, 68.30869],
              [38.81271, 68.30985],
              [38.85797, 68.29979],
              [38.89864, 68.29887],
              [38.90098, 68.29186],
              [38.95016, 68.28112],
              [38.97552, 68.26686],
              [38.94257, 68.27061],
              [38.93217, 68.25995],
              [38.99416, 68.23244],
              [39.04277, 68.22542],
              [39.08904, 68.20447],
              [39.10595, 68.18511],
              [39.13704, 68.19551],
              [39.1706, 68.19453],
              [39.20009, 68.17596],
              [39.32947, 68.12739],
              [39.3401, 68.11851],
              [39.41793, 68.09163],
              [39.43632, 68.09836],
              [39.48583, 68.09102],
              [39.55558, 68.06797],
              [39.52038, 68.06012],
              [39.53297, 68.04883],
              [39.60092, 68.02968],
              [39.6161, 68.04191],
              [39.64507, 68.04716],
              [39.59747, 68.06193],
              [39.60395, 68.06655],
              [39.70094, 68.04336],
              [39.74674, 68.02632],
              [39.79311, 68.03469],
              [39.85216, 68.03018],
              [39.86595, 68.035],
              [39.82218, 68.08179],
              [39.80839, 68.08403],
              [39.78639, 68.12129],
              [39.74335, 68.14824],
              [39.744, 68.15746],
              [39.85482, 68.12206],
              [39.86878, 68.10657],
              [39.89634, 68.09977],
              [39.9707, 68.02956],
              [40.00933, 67.98565],
              [40.03952, 67.99144],
              [40.07227, 67.98524],
              [40.09521, 67.96081],
              [40.15293, 67.93933],
              [40.22464, 67.93208],
              [40.26241, 67.91396],
              [40.28722, 67.91416],
              [40.27476, 67.90176],
              [40.30528, 67.90059],
              [40.37697, 67.88571],
              [40.37581, 67.86928],
              [40.40624, 67.86143],
              [40.40691, 67.84428],
              [40.38625, 67.8355],
              [40.3154, 67.8504],
              [40.2934, 67.83157],
              [40.34688, 67.80158],
              [40.37415, 67.7939],
              [40.40534, 67.7748],
              [40.41782, 67.75773],
              [40.45529, 67.76672],
              [40.4586, 67.74964],
              [40.52613, 67.73588],
              [40.56475, 67.75667],
              [40.57112, 67.77836],
              [40.5559, 67.79613],
              [40.48628, 67.80242],
              [40.45522, 67.82974],
              [40.54355, 67.8396],
              [40.54136, 67.82263],
              [40.55715, 67.79668],
              [40.59995, 67.8068],
              [40.65718, 67.80142],
              [40.67732, 67.78044],
              [40.69769, 67.77637],
              [40.69714, 67.76098],
              [40.76477, 67.74968],
              [40.78342, 67.71739],
              [40.80236, 67.71339],
              [40.82914, 67.72215],
              [40.86291, 67.72199],
              [40.88697, 67.73106],
              [40.91912, 67.717],
              [40.96481, 67.70636],
              [40.98285, 67.69554],
              [40.98792, 67.67774],
              [41.01322, 67.66351],
              [41.0374, 67.63388],
              [41.01746, 67.60062],
              [41.01842, 67.57708],
              [40.96469, 67.554],
              [40.99739, 67.53892],
              [41.01418, 67.50681],
              [40.98124, 67.46835],
              [41.08094, 67.44673],
              [41.11903, 67.40745],
              [41.108, 67.30169],
              [41.08076, 67.25545],
              [41.10751, 67.24355],
              [41.13319, 67.21959],
              [41.26328, 67.19861],
              [41.27614, 67.21786],
              [41.31397, 67.21669],
              [41.35257, 67.19299],
              [41.30688, 67.15098],
              [41.30942, 67.13875],
              [41.37839, 67.1178],
              [41.39674, 67.11822],
              [41.4058, 67.10152],
              [41.37713, 67.09985],
              [41.33892, 67.0657],
              [41.35434, 67.04173],
              [41.34481, 67.02231],
              [41.37472, 67.01941],
              [41.35001, 67.00732],
              [41.34283, 66.98975],
              [41.30375, 66.97348],
              [41.25777, 66.97061],
              [41.28056, 66.95835],
              [41.28606, 66.93518],
              [41.26187, 66.92257],
              [41.24609, 66.88943],
              [41.25395, 66.88065],
              [41.24078, 66.85192],
              [41.22083, 66.82942],
              [41.15215, 66.78859],
              [41.11665, 66.7607],
              [41.07541, 66.73881],
              [41.01634, 66.71556],
              [40.99794, 66.7133],
              [40.94931, 66.68824],
              [40.9345, 66.67415],
              [40.90822, 66.66898],
              [40.85153, 66.64495],
              [40.81938, 66.62131],
              [40.76521, 66.59501],
              [40.69392, 66.57514],
              [40.68676, 66.52755],
              [40.63873, 66.51751],
              [40.635601, 66.509928],
              [40.59039, 66.502459],
              [40.56371, 66.47902],
              [40.56927, 66.46214],
              [40.55781, 66.45092],
              [40.37533, 66.3813],
              [40.32597, 66.38213],
              [40.24692, 66.36103],
              [40.2244, 66.34522],
              [40.1971, 66.34117],
              [40.11243, 66.30585],
              [40.08176, 66.28515],
              [40.04028, 66.27434],
              [39.99729, 66.25427],
              [39.92492, 66.24841],
              [39.87138, 66.2391],
              [39.78873, 66.21604],
              [39.586756, 66.191845],
              [39.47104, 66.18295],
              [39.42538, 66.16829],
              [39.375269, 66.15988],
              [39.28491, 66.14969],
              [39.20394, 66.13243],
              [39.18752, 66.12231],
              [39.10524, 66.1016],
              [39.04692, 66.10647],
              [38.97381, 66.10778],
              [38.88464, 66.10262],
              [38.8301, 66.09109],
              [38.76844, 66.07302],
              [38.68333, 66.06541],
              [38.54494, 66.06507],
              [38.53582, 66.06091],
              [38.43677, 66.06419],
              [38.39868, 66.05732],
              [38.35323, 66.06327],
              [38.33309, 66.05756],
              [38.27381, 66.05781],
              [38.16788, 66.07691],
              [38.12202, 66.08127],
              [38.09789, 66.07745],
              [38.01301, 66.08575],
              [37.98565, 66.08151],
              [37.87355, 66.08971],
              [37.84516, 66.09983],
              [37.78062, 66.10942],
              [37.7305, 66.10506],
              [37.65496, 66.11697],
              [37.60361, 66.13363],
              [37.5611, 66.13643],
              [37.53516, 66.1541],
              [37.45971, 66.17142],
              [37.43075, 66.18327],
              [37.34982, 66.20273],
              [37.28172, 66.21257],
              [37.16856, 66.23794],
              [37.06417, 66.2521],
              [36.808562, 66.279432],
              [36.73015, 66.28278],
              [36.62469, 66.28349],
              [36.51809, 66.27596],
              [36.42855, 66.29051],
              [36.34741, 66.29727],
              [36.30609, 66.30711],
              [36.16348, 66.3216],
              [36.12752, 66.32936],
              [36.0433, 66.33846],
              [36.0104, 66.335],
              [36, 66.34325],
              [35.94862, 66.35128],
              [35.80971, 66.35705],
              [35.80601, 66.36377],
              [35.708261, 66.370375],
              [35.675912, 66.377841],
              [35.608003, 66.378275],
              [35.553892, 66.383733],
              [35.457799, 66.402071],
              [35.42976, 66.41512],
              [35.37319, 66.41804],
              [35.337929, 66.432852],
              [35.34874, 66.44681],
              [35.31512, 66.46701],
              [35.26828, 66.46565],
              [35.25407, 66.47135],
              [35.20452, 66.46835],
              [35.17536, 66.48257],
              [35.17281, 66.49745],
              [35.14371, 66.50552],
              [35.11146, 66.50315],
              [35.06059, 66.53119],
              [35.0029, 66.55573],
              [34.96271, 66.56736],
              [34.90499, 66.59365],
              [34.82059, 66.60054],
              [34.75111, 66.60056],
              [34.69422, 66.58699],
              [34.70345, 66.57154],
              [34.66261, 66.55664],
              [34.61065, 66.54759],
              [34.5729, 66.54951],
              [34.50924, 66.53709],
              [34.45758, 66.5423],
              [34.44061, 66.5596],
              [34.3897, 66.58512],
              [34.37603, 66.61107],
              [34.33543, 66.60989],
              [34.32131, 66.61666],
              [34.37768, 66.62921],
              [34.44818, 66.63446],
              [34.44931, 66.64276],
              [34.38559, 66.64398],
              [34.37688, 66.65529],
              [34.229186, 66.665874],
              [34.20227, 66.66388],
              [34.19249, 66.67495],
              [34.11349, 66.66838],
              [34.08855, 66.68812],
              [34.06434, 66.68023],
              [34.05873, 66.69229],
              [33.98312, 66.68216],
              [33.92581, 66.68545],
              [33.895429, 66.697417],
              [33.88334, 66.68902],
              [33.84107, 66.69846],
              [33.78748, 66.71892],
              [33.81477, 66.7234],
              [33.81664, 66.74078],
              [33.769467, 66.753202],
              [33.760649, 66.762879],
              [33.705255, 66.786311],
              [33.682737, 66.76915],
              [33.660945, 66.785037],
              [33.6401, 66.78224],
              [33.63957, 66.7678],
              [33.594472, 66.786102],
              [33.587705, 66.777908],
              [33.6082, 66.75987],
              [33.654833, 66.742315],
              [33.64477, 66.725028],
              [33.693397, 66.729627],
              [33.700951, 66.722957],
              [33.666066, 66.71594],
              [33.683765, 66.70601],
              [33.654095, 66.692996],
              [33.6419, 66.703738],
              [33.566867, 66.724051],
              [33.521461, 66.712804],
              [33.485249, 66.729045],
              [33.445183, 66.73597],
              [33.389054, 66.753375],
              [33.319156, 66.783415],
              [33.287896, 66.791886],
              [33.273409, 66.785118],
              [33.259279, 66.801307],
              [33.165809, 66.822241],
              [33.147988, 66.839601],
              [33.113048, 66.858957],
              [33.064752, 66.87315],
              [33.034222, 66.888658],
              [32.976228, 66.898212],
              [32.86009, 66.96474],
              [32.82663, 66.98977],
              [32.79105, 67.02423],
              [32.793643, 67.055027],
              [32.904029, 67.074513],
              [32.890805, 67.081542],
              [32.842174, 67.075601],
              [32.76306, 67.07609],
              [32.71963, 67.08957],
              [32.69386, 67.10448],
              [32.610274, 67.120308],
              [32.602129, 67.110906],
              [32.54405, 67.11141],
              [32.53839, 67.100087],
              [32.469972, 67.114763],
              [32.411758, 67.131581],
              [32.400291, 67.14703],
              [32.371551, 67.156444],
              [32.352965, 67.144558],
              [32.319175, 67.141976],
              [32.314822, 67.124467],
              [32.360335, 67.119516],
              [32.382034, 67.110945],
              [32.43721, 67.106598],
              [32.453228, 67.097873],
              [32.497924, 67.095146],
              [32.491751, 67.079804],
              [32.459847, 67.075981],
              [32.468091, 67.064557],
              [32.432035, 67.074446],
              [32.379223, 67.080393],
              [32.339984, 67.09386],
              [32.312881, 67.084651],
              [32.346386, 67.076882],
              [32.376773, 67.060649],
              [32.399327, 67.059432],
              [32.369044, 67.047521],
              [32.375113, 67.039416],
              [32.414782, 67.041236],
              [32.514971, 67.015716],
              [32.56498, 67.04295],
              [32.578626, 67.060038],
              [32.55658, 67.0731],
              [32.61444, 67.06225],
              [32.598106, 67.052614],
              [32.60603, 67.03539],
              [32.57158, 67.04041],
              [32.55706, 67.02861],
              [32.58445, 67.01514],
              [32.58019, 66.99595],
              [32.62308, 66.98783],
              [32.648078, 66.971397],
              [32.63955, 66.9603],
              [32.60032, 66.970034],
              [32.61045, 66.98401],
              [32.584564, 66.983949],
              [32.567024, 66.966341],
              [32.58231, 66.94917],
              [32.549836, 66.954744],
              [32.533075, 66.949687],
              [32.541455, 66.927445],
              [32.559851, 66.910451],
              [32.527502, 66.910112],
              [32.480181, 66.889576],
              [32.532565, 66.879149],
              [32.583208, 66.852118],
              [32.631891, 66.834419],
              [32.67914, 66.82744],
              [32.701387, 66.818477],
              [32.75502, 66.83371],
              [32.757682, 66.823875],
              [32.71642, 66.80322],
              [32.708419, 66.786273],
              [32.76727, 66.77365],
              [32.85405, 66.77401],
              [32.88846, 66.75742],
              [32.91046, 66.75513],
              [32.954102, 66.730573],
              [32.88671, 66.71396],
              [32.89967, 66.70796],
              [32.947972, 66.70589],
              [32.973158, 66.699348],
              [32.98362, 66.70965],
              [33.01398, 66.70633],
              [33.007283, 66.694946],
              [33.03009, 66.6759],
              [33.05007, 66.67283],
              [33.102791, 66.683392],
              [33.15713, 66.67109],
              [33.19133, 66.67272],
              [33.20352, 66.66478],
              [33.26084, 66.65907],
              [33.26329, 66.6473],
              [33.32051, 66.63774],
              [33.30715, 66.62473],
              [33.32463, 66.60781],
              [33.29378, 66.61043],
              [33.2821, 66.60098],
              [33.31416, 66.59403],
              [33.3816, 66.58928],
              [33.45367, 66.55815],
              [33.42326, 66.54468],
              [33.45062, 66.53169],
              [33.49086, 66.52353],
              [33.53124, 66.52288],
              [33.54628, 66.50723],
              [33.52144, 66.51422],
              [33.43446, 66.5242],
              [33.39487, 66.53427],
              [33.30066, 66.54113],
              [33.18149, 66.56228],
              [33.146063, 66.554993],
              [33.055272, 66.561726],
              [33.000331, 66.573173],
              [32.865577, 66.583263],
              [32.587114, 66.582213],
              [32.557279, 66.576101],
              [32.548149, 66.479487],
              [32.360389, 66.475171],
              [32.353833, 66.466637],
              [32.342661, 66.345393],
              [32.135482, 66.347773],
              [31.936984, 66.351047],
              [31.853877, 66.36348],
              [31.83003, 66.371375],
              [31.803098, 66.452053],
              [31.791938, 66.495233],
              [31.621161, 66.502378],
              [31.606008, 66.562249],
              [31.575857, 66.654003],
              [31.497476, 66.658709],
              [31.377101, 66.657621],
              [31.093639, 66.6588],
              [31.086424, 66.667324],
              [30.639788, 66.658435],
              [30.159515, 66.652838],
              [30.169505, 66.660657],
              [29.322073, 66.662859],
              [29.144593, 66.780322],
              [29.105561, 66.790069],
              [29.119417, 66.801372],
              [29.060753, 66.852693],
              [29.031376, 66.927782],
              [29.040559, 66.961819],
              [29.07346, 66.995771],
              [29.221596, 67.089967],
              [29.450456, 67.235442],
              [29.515365, 67.280794],
              [29.505388, 67.287666],
              [29.527181, 67.311247],
              [29.579132, 67.316015],
              [29.584461, 67.330679],
              [29.611445, 67.326397],
              [29.645306, 67.340548],
              [29.616983, 67.3452],
              [29.646264, 67.354632],
              [29.650674, 67.365003],
              [29.710676, 67.389545],
              [29.714361, 67.398823],
              [29.767105, 67.427406],
              [29.856782, 67.489265],
              [29.93051, 67.522821],
              [30.017341, 67.673569],
              [29.658791, 67.802906],
              [29.326526, 68.074817],
              [28.901246, 68.15065],
              [28.646077, 68.196031],
              [28.474284, 68.473744],
              [28.43412, 68.539794],
              [28.706112, 68.732722],
              [28.800731, 68.869303],
              [28.660434, 68.886628],
              [28.466344, 68.886052],
              [28.416086, 68.915251],
              [28.49454, 68.930194],
              [28.929294, 69.051915],
              [29.01988, 69.02518],
              [29.04147, 69.01083],
              [29.14235, 69.06308],
              [29.1597, 69.06704],
              [29.24223, 69.11306],
              [29.23229, 69.122837],
              [29.291177, 69.181435],
              [29.293467, 69.206124],
              [29.312769, 69.229286],
              [29.279615, 69.253687],
              [29.286096, 69.294096],
              [29.390536, 69.322218],
              [29.411946, 69.318611],
              [29.4858, 69.322027],
              [29.524946, 69.328127],
              [29.53124, 69.320016],
              [29.57299, 69.318517],
              [29.634798, 69.34378],
              [29.695079, 69.36345],
              [29.724424, 69.389639],
              [29.7878, 69.397652],
              [29.84424, 69.421226],
              [29.86839, 69.423899],
              [29.932349, 69.412961],
              [29.937093, 69.406173],
              [30.008813, 69.415898],
              [30.04452, 69.429667],
              [30.083559, 69.456716],
              [30.115103, 69.468341],
              [30.119473, 69.513656],
              [30.177218, 69.536982],
              [30.188101, 69.565826],
              [30.160547, 69.597133],
              [30.137807, 69.643539],
              [30.091287, 69.642843],
              [30.085325, 69.658111],
              [30.151663, 69.669379],
              [30.224023, 69.653127],
              [30.30384, 69.628869],
              [30.417722, 69.589919],
              [30.507533, 69.547963],
              [30.515963, 69.540426],
              [30.664214, 69.540689],
              [30.767222, 69.530556],
              [30.863889, 69.536667],
              [30.920278, 69.553333],
              [30.945833, 69.582222],
              [30.9523, 69.637712],
              [30.923504, 69.668585],
              [30.946421, 69.674533],
              [30.908355, 69.700589],
              [30.874023, 69.714982],
              [30.891446, 69.72065],
              [30.890502, 69.737332],
              [30.827672, 69.791213],
              [30.9, 69.7904],
              [30.93474, 69.78314],
              [30.98415, 69.78435],
              [31.02679, 69.77115],
              [31.11007, 69.76529],
              [31.20871, 69.74293],
              [31.26842, 69.74378],
              [31.31196, 69.73449],
              [31.33077, 69.72036],
              [31.36536, 69.71146],
              [31.41644, 69.7081],
              [31.446, 69.68663],
              [31.40399, 69.63871],
              [31.44069, 69.63878],
              [31.48227, 69.68363],
              [31.60787, 69.67547],
              [31.62902, 69.67819],
              [31.66945, 69.66674],
              [31.69998, 69.67234],
              [31.72477, 69.66357],
              [31.78774, 69.65481],
              [31.8088, 69.6593],
              [31.75523, 69.68935],
              [31.68619, 69.7052],
              [31.68202, 69.72486],
              [31.71621, 69.7384],
              [31.71074, 69.77978],
              [31.73366, 69.8165],
              [31.77533, 69.83187],
              [31.81716, 69.82578],
              [31.94325, 69.79244],
              [31.96094, 69.79344],
              [31.97701, 69.77462],
              [32.06345, 69.76615],
              [32.07646, 69.77261],
              [32.03877, 69.81356],
              [31.99047, 69.8409],
              [31.93725, 69.85285],
              [31.98043, 69.86461],
              [31.98203, 69.8774],
              [31.96086, 69.8926],
              [31.8878, 69.90444],
              [31.89581, 69.93593],
              [31.942346, 69.953861],
              [31.98689, 69.94593],
              [31.99513, 69.92949],
              [32.056351, 69.945372],
              [32.16902, 69.9171],
              [32.20113, 69.91379],
              [32.24828, 69.88448],
              [32.33302, 69.88163],
              [32.41312, 69.8584],
              [32.43427, 69.8439],
              [32.52202, 69.83086],
              [32.48138, 69.81184],
              [32.53548, 69.7914],
              [32.57511, 69.80646],
              [32.56288, 69.78982],
              [32.60285, 69.78634],
              [32.64295, 69.77518],
              [32.68554, 69.79238],
              [32.76034, 69.78062],
              [32.84016, 69.77802],
              [32.85276, 69.77177],
              [32.94878, 69.76889],
              [32.96727, 69.75394],
              [33.03251, 69.748882],
              [33.02996, 69.73414],
              [33.05575, 69.73017],
              [33.06907, 69.74537],
              [33.10369, 69.73463],
              [33.11613, 69.71749],
              [33.09329, 69.71558],
              [33.11161, 69.69858],
              [33.10562, 69.67839],
              [33.07946, 69.6623],
              [33.09705, 69.65258],
              [33.08635, 69.64058],
              [33.01587, 69.61862],
              [32.95107, 69.5836],
              [32.89437, 69.5736],
              [32.82052, 69.56818],
              [32.75379, 69.58177],
              [32.70991, 69.57889],
              [32.64247, 69.5849],
              [32.5844, 69.59716],
              [32.525, 69.60021],
              [32.45724, 69.61394],
              [32.42524, 69.63186],
              [32.38825, 69.61678],
              [32.29194, 69.6246],
              [32.1942, 69.62929],
              [32.15436, 69.64418],
              [32.15935, 69.6759],
              [32.19581, 69.68374],
              [32.19306, 69.69804],
              [32.15979, 69.71117],
              [32.13934, 69.68884],
              [32.09192, 69.65358],
              [32.05245, 69.6328],
              [31.98119, 69.63445],
              [31.98355, 69.62334],
              [32.03845, 69.60141],
              [32.0627, 69.60001],
              [32.07223, 69.58293],
              [32.09502, 69.58075],
              [32.12389, 69.59062],
              [32.1797, 69.58667],
              [32.25996, 69.57386],
              [32.28666, 69.56325],
              [32.36782, 69.56393],
              [32.43754, 69.55484],
              [32.39345, 69.52428],
              [32.44092, 69.50961],
              [32.4957, 69.50671],
              [32.51255, 69.47162],
              [32.53626, 69.48638],
              [32.57789, 69.48163],
              [32.59723, 69.46046],
              [32.6191, 69.45899],
              [32.62387, 69.47713],
              [32.64781, 69.48319],
              [32.74188, 69.47709],
              [32.76677, 69.46563],
              [32.7915, 69.46821],
              [32.88326, 69.46472],
              [32.93569, 69.45887],
              [32.89752, 69.45118],
              [32.9081, 69.44352],
              [32.97573, 69.45492],
              [33.04923, 69.45721],
              [33.0743, 69.4466],
              [33.05135, 69.43357],
              [33.0001, 69.42105],
              [32.9874, 69.40701],
              [32.95547, 69.39957],
              [32.9294, 69.38194],
              [32.93451, 69.3689],
              [32.90849, 69.35202],
              [32.874579, 69.315948],
              [32.88881, 69.30703],
              [32.92889, 69.31651],
              [32.94832, 69.3546],
              [32.98307, 69.36508],
              [32.97219, 69.3753],
              [33.02007, 69.40701],
              [33.08327, 69.41541],
              [33.15025, 69.43888],
              [33.15379, 69.42533],
              [33.20177, 69.42733],
              [33.25536, 69.41103],
              [33.30279, 69.42769],
              [33.34203, 69.42736],
              [33.36943, 69.41453],
              [33.39792, 69.42367],
              [33.43791, 69.42459],
              [33.43624, 69.41361],
              [33.49699, 69.40296],
              [33.48119, 69.37796],
              [33.41989, 69.35794],
              [33.47431, 69.36162],
              [33.486394, 69.338025],
              [33.59508, 69.30119],
              [33.66166, 69.30452],
              [33.69042, 69.29981],
              [33.73838, 69.30805],
              [33.76464, 69.29413],
              [33.824, 69.29769],
              [33.82373, 69.30971],
              [33.88197, 69.30201],
              [33.97998, 69.31222],
              [34.02657, 69.30364],
              [34.07424, 69.30322],
              [34.16345, 69.30903],
              [34.12231, 69.32439],
              [34.02529, 69.33777],
              [33.98175, 69.33521],
              [33.96253, 69.34248],
              [33.989, 69.36712],
              [34.02587, 69.38045],
              [34.06902, 69.38761],
              [34.12633, 69.38763],
              [34.20848, 69.37775],
              [34.34863, 69.34578],
              [34.400133, 69.337502],
              [34.3956, 69.32189],
              [34.35904, 69.31566],
              [34.2974, 69.32483],
              [34.20911, 69.31141],
              [34.225, 69.29813],
              [34.31628, 69.30232],
              [34.37046, 69.29026],
              [34.40091, 69.29219],
              [34.38125, 69.27842],
              [34.39303, 69.27078],
              [34.46072, 69.26869],
              [34.47964, 69.26035],
              [34.51309, 69.26415],
              [34.57198, 69.2603],
              [34.58956, 69.25425],
              [34.64913, 69.25524],
              [34.65278, 69.26057],
              [34.71193, 69.26051],
              [34.82836, 69.24678],
              [34.76543, 69.24424],
              [34.76266, 69.23673],
              [34.85008, 69.22251],
              [34.87911, 69.22502],
              [34.93122, 69.21166],
              [34.97845, 69.21649],
              [34.99525, 69.21118],
              [35.05797, 69.21224],
              [35.07239, 69.20489],
              [35.12898, 69.20217],
              [35.131202, 69.171617],
              [35.154166, 69.167416],
              [35.17602, 69.17601],
              [35.161994, 69.184528],
              [35.215417, 69.195709],
              [35.26763, 69.19298],
              [35.27859, 69.20153],
              [35.2599, 69.21559],
              [35.2117, 69.22312],
              [35.14951, 69.24615],
              [35.1549, 69.25325],
              [35.2186, 69.24835],
              [35.25311, 69.2543],
              [35.31735, 69.24782],
              [35.37518, 69.22939],
              [35.44393, 69.23201],
              [35.46344, 69.2261],
              [35.5352, 69.22353],
              [35.56111, 69.21112],
              [35.6349, 69.20797],
              [35.73712, 69.1964],
              [35.76563, 69.17727],
              [35.80256, 69.18486],
              [35.80584, 69.20476],
              [35.84162, 69.18652],
              [35.92122, 69.17393],
              [35.93737, 69.16572],
              [35.91897, 69.15708],
              [35.96027, 69.1597],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5472",
      properties: {
        name: "Ненецкий автономный округ",
        density: 0,
        path: "/world/Russia/Ненецкий автономный округ",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [53.87913, 68.25193],
              [53.89265, 68.23875],
              [53.84977, 68.2446],
              [53.87913, 68.25193],
            ],
          ],
          [
            [
              [53.99357, 68.33912],
              [53.99703, 68.3182],
              [53.96419, 68.29202],
              [53.94378, 68.28448],
              [53.88427, 68.2977],
              [53.85969, 68.31055],
              [53.8682, 68.33168],
              [53.85562, 68.34758],
              [53.89542, 68.35549],
              [53.92607, 68.36962],
              [53.98577, 68.36661],
              [53.99357, 68.33912],
            ],
          ],
          [
            [
              [52.30443, 68.54142],
              [52.34252, 68.54505],
              [52.32026, 68.52419],
              [52.30443, 68.54142],
            ],
          ],
          [
            [
              [58.30902, 70.23156],
              [58.32677, 70.22581],
              [58.36589, 70.24085],
              [58.36612, 70.22705],
              [58.33701, 70.22062],
              [58.30902, 70.23156],
            ],
          ],
          [
            [
              [58.6553, 70.46185],
              [58.72098, 70.4528],
              [58.68191, 70.44652],
              [58.6553, 70.46185],
            ],
          ],
          [
            [
              [64.735405, 69.120174],
              [64.720055, 69.112431],
              [64.711538, 69.079576],
              [64.647438, 69.063295],
              [64.613376, 69.039797],
              [64.538948, 69.028795],
              [64.53918, 69.008155],
              [64.518135, 68.978933],
              [64.557475, 68.972606],
              [64.568502, 68.961855],
              [64.624637, 68.932736],
              [64.551408, 68.910664],
              [64.575929, 68.896119],
              [64.557261, 68.867615],
              [64.590996, 68.86092],
              [64.654625, 68.866444],
              [64.687459, 68.859042],
              [64.756903, 68.855895],
              [64.742446, 68.872436],
              [64.784693, 68.892985],
              [64.809513, 68.877657],
              [64.854588, 68.875255],
              [64.865264, 68.862601],
              [64.919129, 68.876499],
              [64.911532, 68.863604],
              [64.963436, 68.843816],
              [65.02436, 68.844186],
              [65.029112, 68.831514],
              [65.085294, 68.810969],
              [65.139807, 68.803589],
              [65.1469, 68.813516],
              [65.186805, 68.810914],
              [65.22558, 68.820371],
              [65.229804, 68.804397],
              [65.253883, 68.809826],
              [65.305789, 68.803284],
              [65.346071, 68.8123],
              [65.347424, 68.788339],
              [65.396731, 68.793298],
              [65.398303, 68.778002],
              [65.353261, 68.743744],
              [65.35088, 68.729614],
              [65.384136, 68.719959],
              [65.448928, 68.73369],
              [65.468167, 68.710267],
              [65.463755, 68.695572],
              [65.426367, 68.697146],
              [65.403742, 68.683976],
              [65.437586, 68.673458],
              [65.425481, 68.661362],
              [65.436069, 68.646778],
              [65.497763, 68.636243],
              [65.517197, 68.618311],
              [65.561211, 68.608162],
              [65.56128, 68.590539],
              [65.603516, 68.581606],
              [65.658298, 68.581045],
              [65.670532, 68.563628],
              [65.659721, 68.540906],
              [65.628645, 68.534101],
              [65.593241, 68.515511],
              [65.609221, 68.489609],
              [65.550141, 68.484215],
              [65.54192, 68.469838],
              [65.515125, 68.464719],
              [65.51067, 68.435016],
              [65.472578, 68.417891],
              [65.42283, 68.422662],
              [65.358103, 68.413975],
              [65.341268, 68.402214],
              [65.300372, 68.394449],
              [65.280922, 68.406671],
              [65.251538, 68.396908],
              [65.149065, 68.405401],
              [65.116123, 68.415317],
              [65.053222, 68.402556],
              [65.060296, 68.388335],
              [65.02801, 68.380801],
              [64.988035, 68.387488],
              [64.970429, 68.402863],
              [64.938469, 68.404861],
              [64.878586, 68.390408],
              [64.815797, 68.409971],
              [64.776438, 68.403975],
              [64.773211, 68.394443],
              [64.730162, 68.386909],
              [64.697797, 68.396441],
              [64.658593, 68.382603],
              [64.657595, 68.370919],
              [64.580261, 68.357235],
              [64.555587, 68.331329],
              [64.528912, 68.326024],
              [64.538292, 68.305576],
              [64.527994, 68.278748],
              [64.562741, 68.274597],
              [64.577655, 68.261452],
              [64.562205, 68.251075],
              [64.581889, 68.200339],
              [64.518085, 68.187424],
              [64.497789, 68.19857],
              [64.448513, 68.199108],
              [64.400008, 68.177199],
              [64.374178, 68.175738],
              [64.356268, 68.156289],
              [64.395474, 68.158058],
              [64.371567, 68.145681],
              [64.362498, 68.125771],
              [64.346815, 68.130691],
              [64.300616, 68.112395],
              [64.269404, 68.119544],
              [64.250264, 68.110165],
              [64.213902, 68.113239],
              [64.158843, 68.093329],
              [64.079356, 68.092098],
              [64.0729, 68.070305],
              [64.043688, 68.067768],
              [64.019897, 68.056198],
              [63.980326, 68.057428],
              [63.97164, 68.048741],
              [63.866319, 68.032518],
              [63.901299, 67.999617],
              [63.906913, 67.976325],
              [63.939278, 67.958952],
              [63.947447, 67.944923],
              [63.921465, 67.92951],
              [63.964978, 67.905565],
              [63.996996, 67.901222],
              [63.989348, 67.89119],
              [63.950988, 67.890113],
              [63.947799, 67.87201],
              [63.911324, 67.854521],
              [63.92128, 67.844874],
              [63.868123, 67.826539],
              [63.817694, 67.820812],
              [63.799436, 67.828345],
              [63.766651, 67.815545],
              [63.763365, 67.800209],
              [63.746971, 67.814968],
              [63.706344, 67.803668],
              [63.716569, 67.797633],
              [63.695238, 67.771535],
              [63.770155, 67.740403],
              [63.758087, 67.725028],
              [63.735025, 67.724375],
              [63.719841, 67.736712],
              [63.607106, 67.732291],
              [63.560212, 67.741726],
              [63.553371, 67.733097],
              [63.494408, 67.742821],
              [63.490986, 67.755197],
              [63.425642, 67.768995],
              [63.383593, 67.75339],
              [63.327822, 67.747297],
              [63.299457, 67.732883],
              [63.30384, 67.71355],
              [63.263673, 67.715663],
              [63.226813, 67.709744],
              [63.221356, 67.698982],
              [63.144213, 67.691409],
              [63.139929, 67.67],
              [63.119175, 67.647476],
              [63.06575, 67.62918],
              [62.943157, 67.605156],
              [62.913907, 67.596546],
              [62.928706, 67.588167],
              [62.913679, 67.568795],
              [62.919985, 67.539661],
              [62.880358, 67.52098],
              [62.834253, 67.519865],
              [62.856356, 67.500763],
              [62.851591, 67.491461],
              [62.882264, 67.485465],
              [62.916956, 67.445954],
              [62.900929, 67.436998],
              [62.909695, 67.412168],
              [62.897127, 67.402098],
              [62.873603, 67.405288],
              [62.878486, 67.389337],
              [62.845123, 67.384148],
              [62.863958, 67.369734],
              [62.753512, 67.350323],
              [62.484498, 67.27929],
              [62.150142, 67.19611],
              [61.846651, 67.126153],
              [61.828701, 67.115929],
              [61.785883, 67.111777],
              [61.777966, 67.097248],
              [61.729305, 67.09571],
              [61.742126, 67.076492],
              [61.707726, 67.056121],
              [61.716453, 67.033789],
              [61.657243, 67.013456],
              [61.638947, 67.019144],
              [61.644406, 67.003309],
              [61.597897, 67.002847],
              [61.597206, 66.992392],
              [61.566225, 67.006844],
              [61.541318, 67.00692],
              [61.508494, 66.995312],
              [61.514105, 67.007304],
              [61.47636, 67.00223],
              [61.461292, 67.009379],
              [61.454837, 66.981705],
              [61.425471, 66.983089],
              [61.381289, 66.974401],
              [61.375945, 66.98893],
              [61.345848, 67.001037],
              [61.320787, 67.000614],
              [60.109855, 67.000637],
              [60.044534, 66.965775],
              [59.924997, 66.951591],
              [59.907354, 66.964236],
              [59.921113, 66.977304],
              [59.9652, 66.97792],
              [59.9735, 67.000443],
              [58.83629, 67.000428],
              [58.224412, 67.00042],
              [57.705899, 67.000413],
              [57.068064, 67.000404],
              [56.501823, 67.000396],
              [55.887852, 67.000388],
              [55.408391, 67.000381],
              [54.92893, 67.000375],
              [54.282416, 67.000366],
              [53.874727, 67.000361],
              [53.556893, 67.019728],
              [53.291525, 67.036943],
              [52.55938, 67.077829],
              [52.14922, 67.099397],
              [52.068354, 67.064369],
              [52.048522, 67.050609],
              [52.057134, 67.029546],
              [51.931986, 67.016245],
              [51.929527, 67.006021],
              [51.887401, 66.995489],
              [51.871718, 67.003022],
              [51.830438, 67.000177],
              [51.865723, 66.994643],
              [51.843661, 66.990722],
              [51.846506, 66.977347],
              [51.82875, 66.964739],
              [51.831827, 66.945829],
              [51.785778, 66.959511],
              [51.754876, 66.948826],
              [51.789777, 66.946289],
              [51.776404, 66.919384],
              [51.749574, 66.926994],
              [51.719979, 66.917923],
              [51.693765, 66.921535],
              [51.656653, 66.937793],
              [51.616947, 66.947786],
              [51.526506, 66.939329],
              [51.505637, 66.92134],
              [51.514595, 66.897856],
              [51.545692, 66.880407],
              [51.540235, 66.863264],
              [51.501089, 66.846582],
              [51.488714, 66.834436],
              [51.49894, 66.817294],
              [51.536532, 66.798],
              [51.545125, 66.773977],
              [51.207526, 66.691604],
              [50.85815, 66.602618],
              [50.405685, 66.487392],
              [50.010487, 66.384098],
              [49.779813, 66.321395],
              [49.37584, 66.214971],
              [48.999585, 66.116202],
              [48.999789, 66.113264],
              [48.721637, 66.118556],
              [48.405668, 66.122351],
              [48.30187, 66.127185],
              [48.084647, 66.129254],
              [48.072645, 66.113106],
              [48.063755, 66.067943],
              [48.029132, 66.013281],
              [47.911042, 66.032277],
              [47.849174, 65.982969],
              [47.824563, 65.981587],
              [47.749226, 65.906458],
              [47.843529, 65.855339],
              [47.797418, 65.837895],
              [47.738354, 65.820969],
              [47.471346, 65.843935],
              [47.269648, 65.831014],
              [47.144758, 65.821305],
              [46.770449, 65.935343],
              [46.686639, 65.953591],
              [46.558159, 65.978016],
              [46.426725, 66.017737],
              [46.347105, 66.038287],
              [46.307209, 66.037941],
              [46.139164, 66.0521],
              [46.134673, 66.058836],
              [45.848494, 66.085428],
              [45.72583, 66.069019],
              [45.70329, 66.083267],
              [45.515382, 66.115301],
              [45.267541, 66.178248],
              [45.179847, 66.194308],
              [44.989002, 66.225047],
              [44.854938, 66.230571],
              [44.589523, 66.297577],
              [44.520753, 66.361621],
              [44.249558, 66.436164],
              [44.30041, 66.47415],
              [44.33903, 66.48991],
              [44.38362, 66.4993],
              [44.38002, 66.51514],
              [44.39297, 66.57553],
              [44.43767, 66.63015],
              [44.47145, 66.64663],
              [44.55714, 66.64626],
              [44.53819, 66.66078],
              [44.50919, 66.66005],
              [44.47541, 66.67247],
              [44.39646, 66.72307],
              [44.36741, 66.75927],
              [44.37325, 66.78383],
              [44.44935, 66.79971],
              [44.50178, 66.83619],
              [44.47299, 66.87649],
              [44.48818, 66.89623],
              [44.51659, 66.90833],
              [44.48617, 66.93048],
              [44.43343, 66.95613],
              [44.4011, 66.99729],
              [44.38537, 66.9989],
              [44.30879, 67.03668],
              [44.31903, 67.05717],
              [44.22876, 67.09383],
              [44.17763, 67.13091],
              [44.09121, 67.15703],
              [44.04688, 67.16329],
              [43.939, 67.1621],
              [43.90122, 67.15354],
              [43.82585, 67.17473],
              [43.777, 67.19661],
              [43.77045, 67.24105],
              [43.77555, 67.2705],
              [43.80105, 67.32259],
              [43.8078, 67.34668],
              [43.90297, 67.47673],
              [43.95585, 67.53282],
              [44.00884, 67.5761],
              [44.03587, 67.60311],
              [44.06816, 67.6598],
              [44.11721, 67.65981],
              [44.10829, 67.64698],
              [44.1475, 67.64898],
              [44.17424, 67.67175],
              [44.1617, 67.68421],
              [44.10522, 67.68353],
              [44.08505, 67.69211],
              [44.1356, 67.74454],
              [44.14363, 67.77398],
              [44.13473, 67.80487],
              [44.10973, 67.8133],
              [44.09526, 67.83763],
              [44.11314, 67.85716],
              [44.1184, 67.87917],
              [44.13754, 67.88439],
              [44.18923, 67.88056],
              [44.19507, 67.86857],
              [44.26619, 67.8707],
              [44.27584, 67.88761],
              [44.30146, 67.89284],
              [44.27952, 67.90016],
              [44.2822, 67.91218],
              [44.23283, 67.92653],
              [44.20017, 67.9274],
              [44.18344, 67.91057],
              [44.15004, 67.91385],
              [44.17408, 67.96357],
              [44.15063, 67.99975],
              [44.20394, 68.00615],
              [44.18683, 68.027836],
              [44.15914, 68.02843],
              [44.15822, 68.04427],
              [44.18577, 68.06691],
              [44.2038, 68.09921],
              [44.20465, 68.13423],
              [44.225842, 68.186834],
              [44.231054, 68.223596],
              [44.223036, 68.267871],
              [44.20118, 68.30319],
              [44.04141, 68.37342],
              [43.92142, 68.41207],
              [43.89552, 68.42365],
              [43.82914, 68.43802],
              [43.71993, 68.4766],
              [43.55286, 68.52509],
              [43.47768, 68.55358],
              [43.40531, 68.58882],
              [43.33642, 68.61207],
              [43.28678, 68.63842],
              [43.26921, 68.65691],
              [43.289993, 68.66578],
              [43.42975, 68.66972],
              [43.48881, 68.65825],
              [43.56665, 68.64844],
              [43.65025, 68.62331],
              [43.74537, 68.61408],
              [43.75289, 68.60467],
              [43.85185, 68.57265],
              [43.91667, 68.56235],
              [44.01625, 68.53842],
              [44.08428, 68.53075],
              [44.36171, 68.54072],
              [44.53825, 68.547923],
              [44.773379, 68.552595],
              [44.924098, 68.55021],
              [45.27978, 68.538782],
              [45.489453, 68.522287],
              [45.541967, 68.516122],
              [45.620939, 68.50026],
              [45.734385, 68.484682],
              [45.805339, 68.469092],
              [45.915177, 68.451284],
              [46.0182, 68.431988],
              [45.99976, 68.428303],
              [45.913172, 68.449811],
              [45.804537, 68.467915],
              [45.859857, 68.429335],
              [45.917582, 68.398955],
              [45.96288, 68.3805],
              [46.035037, 68.329932],
              [46.026618, 68.311569],
              [46.110801, 68.294971],
              [46.193781, 68.244509],
              [46.243489, 68.222508],
              [46.30843, 68.186638],
              [46.38497, 68.16794],
              [46.423746, 68.150669],
              [46.45886, 68.15247],
              [46.52459, 68.12938],
              [46.549352, 68.100529],
              [46.529309, 68.094248],
              [46.523697, 68.045891],
              [46.549753, 68.004489],
              [46.616999, 67.958463],
              [46.641051, 67.935209],
              [46.631029, 67.931218],
              [46.652676, 67.910801],
              [46.70472, 67.89007],
              [46.68464, 67.8798],
              [46.68554, 67.86419],
              [46.71195, 67.85445],
              [46.69268, 67.80836],
              [46.63503, 67.80128],
              [46.60375, 67.8085],
              [46.48476, 67.80925],
              [46.44517, 67.8142],
              [46.33328, 67.82141],
              [46.30991, 67.81495],
              [46.15673, 67.79514],
              [46.052675, 67.78523],
              [45.984527, 67.79008],
              [45.848131, 67.753874],
              [45.714041, 67.749549],
              [45.667339, 67.744159],
              [45.651906, 67.75266],
              [45.5571, 67.751977],
              [45.472717, 67.741654],
              [45.355964, 67.713432],
              [45.31267, 67.688943],
              [45.344339, 67.658639],
              [45.33366, 67.61023],
              [45.30771, 67.57398],
              [45.22745, 67.58216],
              [45.17346, 67.57136],
              [45.14694, 67.55835],
              [45.04184, 67.51802],
              [44.99042, 67.49412],
              [44.9611, 67.47277],
              [44.93385, 67.44261],
              [44.91444, 67.37867],
              [44.91894, 67.32359],
              [44.96268, 67.31479],
              [45.08701, 67.27028],
              [45.11801, 67.25697],
              [45.26229, 67.22075],
              [45.49563, 67.18057],
              [45.55918, 67.16605],
              [45.60275, 67.14493],
              [45.62547, 67.10828],
              [45.616323, 67.063431],
              [45.6715, 67.03983],
              [45.68908, 67.02492],
              [45.69759, 66.99064],
              [45.73353, 66.96899],
              [45.79683, 66.90835],
              [45.82678, 66.88889],
              [45.85711, 66.8817],
              [45.87582, 66.8683],
              [45.90643, 66.86071],
              [45.98467, 66.85053],
              [46.00707, 66.84246],
              [46.01835, 66.82475],
              [46.09339, 66.82629],
              [46.13088, 66.82283],
              [46.29685, 66.83177],
              [46.35778, 66.84176],
              [46.43355, 66.86001],
              [46.53789, 66.86543],
              [46.56618, 66.86042],
              [46.60093, 66.83501],
              [46.5553, 66.82728],
              [46.57072, 66.81958],
              [46.66005, 66.81624],
              [46.69166, 66.82281],
              [46.76478, 66.82854],
              [46.79578, 66.83548],
              [46.86443, 66.84133],
              [46.92384, 66.83678],
              [46.96053, 66.84887],
              [47.00318, 66.85383],
              [47.07053, 66.85079],
              [47.10012, 66.86242],
              [47.18465, 66.87688],
              [47.28606, 66.88977],
              [47.36504, 66.91216],
              [47.44192, 66.9184],
              [47.49575, 66.90939],
              [47.59877, 66.87933],
              [47.61528, 66.88762],
              [47.57773, 66.89886],
              [47.57755, 66.90961],
              [47.63451, 66.94526],
              [47.67611, 66.95556],
              [47.68139, 66.96801],
              [47.74305, 67.00789],
              [47.7553, 67.06116],
              [47.76305, 67.12627],
              [47.75696, 67.14492],
              [47.712, 67.16654],
              [47.70866, 67.18374],
              [47.67629, 67.18889],
              [47.71452, 67.2123],
              [47.73512, 67.21361],
              [47.784, 67.23106],
              [47.79985, 67.25382],
              [47.77241, 67.27374],
              [47.76021, 67.30286],
              [47.78302, 67.31961],
              [47.7721, 67.33413],
              [47.86721, 67.38056],
              [47.90408, 67.40498],
              [47.93054, 67.43131],
              [47.94092, 67.46367],
              [47.93277, 67.47866],
              [47.89585, 67.49059],
              [47.87405, 67.51733],
              [47.8509, 67.52772],
              [47.8636, 67.54157],
              [47.83036, 67.57131],
              [47.83432, 67.57919],
              [47.93209, 67.60559],
              [47.99761, 67.6328],
              [48.04748, 67.62624],
              [48.05376, 67.61768],
              [48.12595, 67.61272],
              [48.21025, 67.63283],
              [48.25672, 67.65316],
              [48.28794, 67.65034],
              [48.34658, 67.66552],
              [48.42045, 67.64134],
              [48.4742, 67.63662],
              [48.54156, 67.65103],
              [48.59124, 67.67247],
              [48.65199, 67.69083],
              [48.71288, 67.68817],
              [48.66643, 67.6841],
              [48.70102, 67.66847],
              [48.73705, 67.6665],
              [48.78571, 67.6719],
              [48.82202, 67.68398],
              [48.86658, 67.6805],
              [48.9015, 67.66366],
              [48.94593, 67.65964],
              [49.0041, 67.64157],
              [49.03948, 67.64787],
              [48.97128, 67.67029],
              [48.91894, 67.6754],
              [48.86465, 67.70973],
              [48.82872, 67.71685],
              [48.74404, 67.71037],
              [48.78461, 67.75078],
              [48.77639, 67.76685],
              [48.78608, 67.7912],
              [48.77764, 67.82903],
              [48.76188, 67.84271],
              [48.69689, 67.87338],
              [48.63894, 67.88491],
              [48.59174, 67.9136],
              [48.63265, 67.91424],
              [48.68221, 67.89649],
              [48.69669, 67.8785],
              [48.77037, 67.84904],
              [48.79978, 67.84222],
              [48.92448, 67.82954],
              [49.00526, 67.83015],
              [49.06103, 67.83505],
              [49.17477, 67.85292],
              [49.34555, 67.89152],
              [49.50185, 67.92957],
              [49.62621, 67.96223],
              [49.87614, 68.03445],
              [50.04144, 68.08995],
              [50.08759, 68.10809],
              [50.1453, 68.12232],
              [50.34594, 68.18808],
              [50.49098, 68.24137],
              [50.628759, 68.289206],
              [50.714472, 68.325841],
              [50.78409, 68.36174],
              [50.82074, 68.37016],
              [50.81799, 68.38533],
              [50.94745, 68.44014],
              [51.03609, 68.46643],
              [51.11735, 68.48162],
              [51.22008, 68.48981],
              [51.46633, 68.48328],
              [51.48547, 68.47929],
              [51.43405, 68.45553],
              [51.36615, 68.45915],
              [51.34492, 68.4492],
              [51.292, 68.44047],
              [51.20421, 68.44553],
              [51.14543, 68.44078],
              [51.10234, 68.42413],
              [51.06268, 68.42118],
              [51.03469, 68.42685],
              [50.98529, 68.4238],
              [50.97097, 68.40429],
              [50.93451, 68.39219],
              [50.85927, 68.39225],
              [50.84385, 68.37731],
              [50.80516, 68.36355],
              [50.80856, 68.34853],
              [50.84914, 68.33733],
              [50.90399, 68.33486],
              [51.02327, 68.34612],
              [51.08796, 68.34715],
              [51.12258, 68.36505],
              [51.19577, 68.37789],
              [51.26689, 68.37727],
              [51.29928, 68.3812],
              [51.360586, 68.404818],
              [51.392, 68.408482],
              [51.406934, 68.425153],
              [51.47194, 68.41767],
              [51.54664, 68.41987],
              [51.5898, 68.43167],
              [51.589434, 68.445578],
              [51.510298, 68.447912],
              [51.51253, 68.461654],
              [51.553042, 68.484645],
              [51.65043, 68.4876],
              [51.7781, 68.4974],
              [51.87921, 68.51065],
              [52.01622, 68.53285],
              [52.20342, 68.57368],
              [52.22624, 68.57509],
              [52.2792, 68.55994],
              [52.25995, 68.55657],
              [52.24038, 68.56794],
              [52.18947, 68.55047],
              [52.19115, 68.54257],
              [52.15561, 68.5354],
              [52.15229, 68.51185],
              [52.07841, 68.51726],
              [52.04419, 68.50754],
              [52.06193, 68.49038],
              [52.11189, 68.49184],
              [52.11708, 68.5066],
              [52.16514, 68.50082],
              [52.19211, 68.48904],
              [52.22366, 68.4884],
              [52.2659, 68.47013],
              [52.33831, 68.47754],
              [52.38239, 68.46672],
              [52.33317, 68.46869],
              [52.299165, 68.452518],
              [52.313402, 68.428336],
              [52.293442, 68.410824],
              [52.25192, 68.408008],
              [52.203798, 68.396327],
              [52.1635, 68.37494],
              [52.16715, 68.35454],
              [52.20443, 68.34563],
              [52.23333, 68.32698],
              [52.29688, 68.32136],
              [52.40647, 68.32776],
              [52.4796, 68.34692],
              [52.54618, 68.36818],
              [52.54996, 68.37725],
              [52.59486, 68.39996],
              [52.69067, 68.41527],
              [52.73993, 68.4529],
              [52.74548, 68.47148],
              [52.734375, 68.48523],
              [52.67167, 68.50737],
              [52.60184, 68.51618],
              [52.5763, 68.52524],
              [52.53835, 68.56856],
              [52.48585, 68.57535],
              [52.50345, 68.59068],
              [52.50002, 68.6043],
              [52.46541, 68.60542],
              [52.42451, 68.584864],
              [52.3812, 68.5816],
              [52.38102, 68.57225],
              [52.28465, 68.58904],
              [52.30121, 68.60206],
              [52.51184, 68.66443],
              [52.86971, 68.76039],
              [53.34563, 68.87656],
              [53.49337, 68.90704],
              [53.6663, 68.93355],
              [53.86959, 68.95966],
              [54.02288, 68.97392],
              [54.11569, 68.98063],
              [54.330129, 68.988186],
              [54.436044, 68.988986],
              [54.532174, 68.984985],
              [54.567708, 68.979321],
              [54.579209, 68.965585],
              [54.530286, 68.959423],
              [54.436902, 68.975688],
              [54.404287, 68.971315],
              [54.379739, 68.95893],
              [54.325837, 68.950916],
              [54.263009, 68.947402],
              [54.162587, 68.946477],
              [54.01307, 68.925808],
              [53.979253, 68.926302],
              [53.86635, 68.91227],
              [53.81168, 68.91688],
              [53.78563, 68.92496],
              [53.74115, 68.90838],
              [53.65027, 68.89856],
              [53.603932, 68.872443],
              [53.65188, 68.84804],
              [53.71103, 68.84344],
              [53.685601, 68.851491],
              [53.685945, 68.869815],
              [53.743966, 68.859417],
              [53.80358, 68.86478],
              [53.83687, 68.85517],
              [53.87147, 68.85607],
              [53.93011, 68.84716],
              [53.95042, 68.83857],
              [53.98652, 68.8387],
              [54.00328, 68.81418],
              [53.97127, 68.81553],
              [53.96705, 68.7946],
              [53.91543, 68.79994],
              [53.87026, 68.79438],
              [53.87648, 68.77274],
              [53.84749, 68.75557],
              [53.82837, 68.73372],
              [53.85911, 68.70819],
              [53.84411, 68.68975],
              [53.8038, 68.67159],
              [53.73299, 68.65104],
              [53.71971, 68.617],
              [53.755, 68.58876],
              [53.79071, 68.58152],
              [53.80847, 68.56241],
              [53.88042, 68.54047],
              [53.91741, 68.50507],
              [53.91772, 68.48998],
              [53.9377, 68.46604],
              [53.92509, 68.43843],
              [53.94356, 68.4073],
              [53.90719, 68.38104],
              [53.83147, 68.37169],
              [53.8613, 68.36662],
              [53.81798, 68.34174],
              [53.83269, 68.31566],
              [53.80471, 68.33509],
              [53.73894, 68.34943],
              [53.7293, 68.36588],
              [53.68771, 68.3776],
              [53.65707, 68.37366],
              [53.643393, 68.348102],
              [53.65419, 68.31781],
              [53.61979, 68.33042],
              [53.59284, 68.35316],
              [53.51416, 68.3555],
              [53.4316, 68.36464],
              [53.37735, 68.33505],
              [53.31248, 68.33773],
              [53.28132, 68.32065],
              [53.2727, 68.30356],
              [53.24905, 68.30226],
              [53.19997, 68.27298],
              [53.19732, 68.26396],
              [53.2588, 68.24283],
              [53.30415, 68.23975],
              [53.33018, 68.22804],
              [53.37157, 68.22792],
              [53.45877, 68.24363],
              [53.49488, 68.25566],
              [53.52787, 68.252],
              [53.54257, 68.24156],
              [53.59927, 68.24989],
              [53.65137, 68.23815],
              [53.70897, 68.24993],
              [53.76661, 68.23763],
              [53.80817, 68.24124],
              [53.87229, 68.22122],
              [53.9124, 68.22018],
              [53.92655, 68.21226],
              [53.96763, 68.22197],
              [54.03646, 68.21451],
              [54.05867, 68.23998],
              [54.10766, 68.2452],
              [54.11954, 68.27137],
              [54.15251, 68.29483],
              [54.23226, 68.30974],
              [54.239926, 68.32026],
              [54.3049, 68.32705],
              [54.35187, 68.33675],
              [54.33856, 68.31735],
              [54.34789, 68.30855],
              [54.3439, 68.27447],
              [54.31199, 68.25527],
              [54.26534, 68.24722],
              [54.19018, 68.24149],
              [54.17487, 68.2315],
              [54.19657, 68.20066],
              [54.14494, 68.15373],
              [54.14264, 68.13629],
              [54.117644, 68.127216],
              [54.12939, 68.11048],
              [54.05282, 68.06589],
              [53.98606, 68.01632],
              [53.92182, 67.99621],
              [53.86905, 67.97268],
              [53.82296, 67.9608],
              [53.84616, 67.95049],
              [53.89996, 67.96073],
              [53.90117, 67.9669],
              [53.9601, 67.9749],
              [53.98754, 67.98667],
              [54.02066, 68.01286],
              [54.06657, 68.02775],
              [54.09897, 68.04445],
              [54.1275, 68.07616],
              [54.18394, 68.11014],
              [54.17646, 68.12169],
              [54.21636, 68.15674],
              [54.22773, 68.15923],
              [54.24248, 68.19376],
              [54.2368, 68.21796],
              [54.28566, 68.23003],
              [54.34086, 68.23246],
              [54.39135, 68.255],
              [54.42693, 68.26374],
              [54.4688, 68.29073],
              [54.51054, 68.29443],
              [54.54233, 68.27828],
              [54.5831, 68.24537],
              [54.59844, 68.2241],
              [54.62767, 68.21122],
              [54.61831, 68.19856],
              [54.67317, 68.16991],
              [54.76542, 68.15406],
              [54.87286, 68.15061],
              [54.90452, 68.17608],
              [54.8999, 68.20031],
              [54.885094, 68.214471],
              [54.94299, 68.23383],
              [54.950863, 68.256992],
              [54.900567, 68.285653],
              [54.861599, 68.300764],
              [54.86102, 68.31464],
              [54.90015, 68.325566],
              [54.949932, 68.356801],
              [54.98412, 68.40825],
              [55.01582, 68.42678],
              [55.061743, 68.43579],
              [55.132147, 68.458173],
              [55.228792, 68.49338],
              [55.283037, 68.519859],
              [55.339857, 68.541284],
              [55.494696, 68.55861],
              [55.593573, 68.547814],
              [55.670477, 68.54976],
              [55.712191, 68.565009],
              [55.789953, 68.578117],
              [55.820509, 68.594036],
              [55.873552, 68.608003],
              [55.891062, 68.6065],
              [55.932089, 68.61902],
              [55.941187, 68.632534],
              [56.006075, 68.642414],
              [56.078001, 68.637849],
              [56.142546, 68.621586],
              [56.207262, 68.595289],
              [56.318155, 68.583821],
              [56.413599, 68.58169],
              [56.454454, 68.585952],
              [56.55354, 68.611764],
              [56.64287, 68.617984],
              [56.693682, 68.609596],
              [56.70023, 68.59726],
              [56.74371, 68.58915],
              [56.871866, 68.551482],
              [56.917013, 68.54263],
              [56.992201, 68.536601],
              [57.038034, 68.524726],
              [57.104982, 68.51907],
              [57.317842, 68.525669],
              [57.438349, 68.564221],
              [57.469248, 68.578458],
              [57.499632, 68.604586],
              [57.490705, 68.621989],
              [57.50358, 68.639503],
              [57.567953, 68.648129],
              [57.597994, 68.658127],
              [57.641424, 68.644879],
              [57.673525, 68.660126],
              [57.763819, 68.677423],
              [57.835401, 68.678484],
              [57.843813, 68.690714],
              [57.879704, 68.694972],
              [57.862538, 68.706196],
              [57.828034, 68.704512],
              [57.754906, 68.710684],
              [57.710961, 68.702081],
              [57.671307, 68.678063],
              [57.625473, 68.691416],
              [57.646416, 68.709312],
              [57.703922, 68.722647],
              [57.738941, 68.723768],
              [57.839192, 68.717476],
              [57.868331, 68.720023],
              [57.94266, 68.712546],
              [58.013385, 68.742689],
              [58.038619, 68.746734],
              [58.048575, 68.802232],
              [58.00463, 68.796024],
              [57.981799, 68.804839],
              [58.000338, 68.811541],
              [58.12599, 68.83816],
              [58.35472, 68.87624],
              [58.60514, 68.92814],
              [58.76466, 68.95929],
              [58.87205, 68.97541],
              [59.04791, 68.98272],
              [59.19894, 68.98277],
              [59.27483, 68.97947],
              [59.31609, 68.97214],
              [59.28844, 68.9596],
              [59.24541, 68.9704],
              [59.14496, 68.95161],
              [59.05752, 68.94833],
              [59.03141, 68.95057],
              [59.00685, 68.93872],
              [59.02104, 68.93062],
              [58.94613, 68.91921],
              [58.95323, 68.9112],
              [59.00023, 68.90491],
              [59.21558, 68.85775],
              [59.26183, 68.83886],
              [59.34511, 68.8165],
              [59.37568, 68.79817],
              [59.31857, 68.80612],
              [59.30916, 68.80062],
              [59.37038, 68.78161],
              [59.39959, 68.78402],
              [59.40252, 68.75879],
              [59.46543, 68.75365],
              [59.44889, 68.74112],
              [59.47085, 68.71343],
              [59.44988, 68.69557],
              [59.47685, 68.68188],
              [59.44196, 68.66818],
              [59.30908, 68.6739],
              [59.28085, 68.67952],
              [59.25404, 68.67144],
              [59.22323, 68.67262],
              [59.18976, 68.66436],
              [59.19314, 68.63082],
              [59.178478, 68.614056],
              [59.126766, 68.606454],
              [59.114339, 68.5788],
              [59.059821, 68.573676],
              [59.104718, 68.568842],
              [59.092692, 68.553898],
              [59.109128, 68.538943],
              [59.17006, 68.519281],
              [59.121555, 68.463136],
              [59.108727, 68.434864],
              [59.121555, 68.405081],
              [59.170862, 68.388108],
              [59.263062, 68.376736],
              [59.392543, 68.366837],
              [59.477574, 68.343346],
              [59.528484, 68.332544],
              [59.5818, 68.336836],
              [59.599438, 68.32914],
              [59.688665, 68.314083],
              [59.686828, 68.337428],
              [59.753725, 68.355909],
              [59.822322, 68.367891],
              [59.855995, 68.360944],
              [59.866818, 68.374835],
              [59.956212, 68.441365],
              [59.980264, 68.449907],
              [59.975454, 68.490804],
              [59.926147, 68.52884],
              [59.89087, 68.539842],
              [59.877241, 68.561537],
              [59.84246, 68.5743],
              [59.80391, 68.62714],
              [59.82934, 68.65357],
              [59.9483, 68.69836],
              [59.95577, 68.70693],
              [59.99114, 68.69249],
              [60.052421, 68.677864],
              [60.064046, 68.668242],
              [60.094512, 68.679905],
              [60.189919, 68.677135],
              [60.3655, 68.686317],
              [60.449281, 68.695349],
              [60.509412, 68.70758],
              [60.625664, 68.749754],
              [60.699424, 68.793299],
              [60.753942, 68.816923],
              [60.80365, 68.86713],
              [60.890238, 68.885911],
              [60.952773, 68.885766],
              [60.978429, 68.876378],
              [60.947161, 68.855276],
              [60.959989, 68.844862],
              [61.04337, 68.844428],
              [61.063013, 68.84978],
              [61.113121, 68.840811],
              [61.152406, 68.8411],
              [61.09388, 68.855276],
              [61.037357, 68.858168],
              [61.000076, 68.86554],
              [61.053392, 68.885477],
              [61.097487, 68.876811],
              [61.077845, 68.890387],
              [61.00649, 68.89746],
              [60.985645, 68.91059],
              [60.95558, 68.906839],
              [60.935937, 68.914484],
              [60.963597, 68.961737],
              [60.961451, 68.984165],
              [60.937098, 69.038811],
              [60.950349, 69.069026],
              [60.89686, 69.12361],
              [60.84568, 69.15087],
              [60.81399, 69.15875],
              [60.7724, 69.15942],
              [60.75146, 69.11917],
              [60.768017, 69.091551],
              [60.738663, 69.091428],
              [60.67108, 69.11272],
              [60.64425, 69.13684],
              [60.63781, 69.16283],
              [60.65185, 69.23369],
              [60.63019, 69.25949],
              [60.5942, 69.2733],
              [60.58025, 69.29381],
              [60.48687, 69.34051],
              [60.47497, 69.35663],
              [60.44964, 69.36378],
              [60.42398, 69.3941],
              [60.39245, 69.39689],
              [60.38778, 69.41106],
              [60.36032, 69.42848],
              [60.31852, 69.44055],
              [60.30116, 69.45317],
              [60.30074, 69.47645],
              [60.25507, 69.4855],
              [60.24964, 69.5002],
              [60.22064, 69.50636],
              [60.24622, 69.53301],
              [60.23243, 69.5458],
              [60.19453, 69.55162],
              [60.19255, 69.56777],
              [60.21783, 69.57755],
              [60.19799, 69.59915],
              [60.21076, 69.62066],
              [60.33067, 69.65964],
              [60.37648, 69.66553],
              [60.45321, 69.64868],
              [60.61861, 69.65265],
              [60.64318, 69.65866],
              [60.68014, 69.65546],
              [60.71187, 69.66861],
              [60.71031, 69.68912],
              [60.69093, 69.69209],
              [60.695428, 69.711506],
              [60.714998, 69.719064],
              [60.753278, 69.717576],
              [60.77824, 69.72505],
              [60.73318, 69.78131],
              [60.72426, 69.81042],
              [60.78361, 69.84372],
              [60.82487, 69.83797],
              [60.85261, 69.85567],
              [60.88394, 69.85639],
              [60.99843, 69.84513],
              [61.05809, 69.82726],
              [61.08767, 69.83438],
              [61.10738, 69.85928],
              [61.12864, 69.83138],
              [61.17772, 69.81691],
              [61.22756, 69.82136],
              [61.29696, 69.8161],
              [61.35273, 69.79885],
              [61.35089, 69.78634],
              [61.43404, 69.77313],
              [61.64488, 69.76231],
              [61.71642, 69.77104],
              [61.75133, 69.76831],
              [61.81375, 69.74983],
              [61.94653, 69.74499],
              [61.9707, 69.74713],
              [62.03964, 69.7353],
              [62.47779, 69.72782],
              [62.65019, 69.72249],
              [62.78904, 69.7136],
              [63.11251, 69.68389],
              [63.23072, 69.66637],
              [63.57488, 69.62141],
              [63.76762, 69.59246],
              [64.19828, 69.51889],
              [64.30419, 69.4964],
              [64.53956, 69.42959],
              [64.71882, 69.37442],
              [64.91855, 69.30673],
              [64.89723, 69.29961],
              [64.91211, 69.2764],
              [64.88448, 69.27152],
              [64.85612, 69.27993],
              [64.81278, 69.27786],
              [64.81744, 69.26491],
              [64.75528, 69.24828],
              [64.71056, 69.24807],
              [64.7142, 69.24061],
              [64.78542, 69.23443],
              [64.79243, 69.20386],
              [64.75163, 69.20056],
              [64.73959, 69.16824],
              [64.76346, 69.16576],
              [64.73426, 69.14583],
              [64.735405, 69.120174],
            ],
          ],
          [
            [
              [49.39593, 68.66302],
              [49.50914, 68.66977],
              [49.56583, 68.68207],
              [49.55064, 68.69389],
              [49.60852, 68.68758],
              [49.51603, 68.66907],
              [49.45929, 68.66328],
              [49.39593, 68.66302],
            ],
          ],
          [
            [
              [54.2596, 68.80047],
              [54.22702, 68.80672],
              [54.19609, 68.82208],
              [54.23435, 68.82731],
              [54.32046, 68.81744],
              [54.34394, 68.8045],
              [54.39429, 68.7971],
              [54.409, 68.78607],
              [54.37235, 68.78537],
              [54.32169, 68.79544],
              [54.2596, 68.80047],
            ],
          ],
          [
            [
              [54.902744, 68.93184],
              [54.936485, 68.93732],
              [55.009251, 68.934444],
              [54.978906, 68.929642],
              [54.902744, 68.93184],
            ],
          ],
          [
            [
              [49.97575, 68.92218],
              [50.08521, 68.94935],
              [49.9906, 68.92174],
              [49.97575, 68.92218],
            ],
          ],
          [
            [
              [59.48667, 69.02588],
              [59.45131, 69.02575],
              [59.4404, 69.03998],
              [59.39795, 69.06334],
              [59.4096, 69.07827],
              [59.45169, 69.05145],
              [59.4852, 69.04445],
              [59.50351, 69.0296],
              [59.48667, 69.02588],
            ],
          ],
          [
            [
              [50.171582, 69.047397],
              [50.197372, 69.067184],
              [50.190414, 69.082581],
              [50.213538, 69.077591],
              [50.195451, 69.053747],
              [50.171582, 69.047397],
            ],
          ],
          [
            [
              [49.069586, 68.672424],
              [49.08083, 68.66672],
              [48.86727, 68.68429],
              [48.62329, 68.69841],
              [48.4415, 68.71566],
              [48.39484, 68.72376],
              [48.30804, 68.74672],
              [48.2712, 68.76484],
              [48.2423, 68.78689],
              [48.22106, 68.82568],
              [48.20995, 68.92006],
              [48.22081, 69.03347],
              [48.23546, 69.0941],
              [48.25503, 69.13817],
              [48.331418, 69.259389],
              [48.35573, 69.28245],
              [48.41332, 69.32227],
              [48.468, 69.35164],
              [48.54709, 69.38715],
              [48.64882, 69.42707],
              [48.74279, 69.45983],
              [48.816467, 69.469981],
              [48.928391, 69.490981],
              [48.981262, 69.496814],
              [49.127346, 69.502044],
              [49.243904, 69.499519],
              [49.33455, 69.49053],
              [49.437882, 69.454082],
              [49.606461, 69.40485],
              [49.68601, 69.38393],
              [49.73241, 69.36678],
              [49.937081, 69.31093],
              [50.05344, 69.28447],
              [50.15265, 69.24861],
              [50.18696, 69.22479],
              [50.24802, 69.1938],
              [50.29365, 69.15372],
              [50.310921, 69.106853],
              [50.28916, 69.14841],
              [50.24112, 69.15448],
              [50.22516, 69.14434],
              [50.1729, 69.13507],
              [50.16483, 69.12293],
              [50.13582, 69.12125],
              [50.11932, 69.09823],
              [50.1048, 69.09636],
              [50.11447, 69.12836],
              [50.05231, 69.12778],
              [50.04144, 69.11898],
              [50.04453, 69.09354],
              [49.97917, 69.08227],
              [49.96704, 69.0652],
              [49.9302, 69.05755],
              [49.92324, 69.03776],
              [49.88441, 69.03695],
              [49.84211, 69.01394],
              [49.86004, 68.99805],
              [49.89772, 68.99723],
              [49.88257, 68.98049],
              [49.796843, 68.943603],
              [49.74051, 68.90762],
              [49.7189, 68.87978],
              [49.73879, 68.8764],
              [49.82894, 68.89719],
              [49.67375, 68.85105],
              [49.61632, 68.83084],
              [49.59006, 68.84438],
              [49.56947, 68.83057],
              [49.53912, 68.83113],
              [49.52203, 68.82211],
              [49.41879, 68.79998],
              [49.38701, 68.79671],
              [49.27347, 68.77298],
              [49.1784, 68.75601],
              [49.1104, 68.75294],
              [49.05044, 68.74158],
              [48.96361, 68.73148],
              [48.94359, 68.72593],
              [48.84928, 68.71466],
              [48.77452, 68.71007],
              [48.766771, 68.7034],
              [48.828559, 68.69705],
              [48.859858, 68.699626],
              [49.069586, 68.672424],
            ],
          ],
          [
            [
              [64.829245, 69.155683],
              [64.801416, 69.136606],
              [64.760528, 69.126732],
              [64.79424, 69.15909],
              [64.829245, 69.155683],
            ],
          ],
          [
            [
              [65.083685, 69.260027],
              [65.059762, 69.246286],
              [65.077134, 69.236169],
              [65.033576, 69.225393],
              [65.025041, 69.193918],
              [64.896678, 69.177919],
              [64.939091, 69.192279],
              [64.943861, 69.211079],
              [64.907102, 69.237869],
              [64.938761, 69.255769],
              [65.083685, 69.260027],
            ],
          ],
          [
            [
              [59.26652, 69.18584],
              [59.29605, 69.18289],
              [59.30675, 69.1651],
              [59.29295, 69.14994],
              [59.30021, 69.13373],
              [59.33029, 69.11977],
              [59.33396, 69.10719],
              [59.3055, 69.11588],
              [59.2772, 69.11255],
              [59.24032, 69.13733],
              [59.22757, 69.16],
              [59.2232, 69.19817],
              [59.18526, 69.23241],
              [59.10088, 69.25404],
              [59.08451, 69.26383],
              [59.03672, 69.26977],
              [58.99413, 69.28535],
              [58.95838, 69.28141],
              [58.88474, 69.2899],
              [58.86041, 69.30525],
              [58.81381, 69.32287],
              [58.76378, 69.33042],
              [58.74359, 69.34482],
              [58.74116, 69.36402],
              [58.79927, 69.33939],
              [58.88203, 69.33365],
              [58.92475, 69.31746],
              [59.01565, 69.30709],
              [59.05161, 69.29746],
              [59.13695, 69.28339],
              [59.19059, 69.26379],
              [59.2537, 69.2461],
              [59.2859, 69.22318],
              [59.28653, 69.20859],
              [59.25271, 69.20187],
              [59.26652, 69.18584],
            ],
          ],
          [
            [
              [61.20344, 69.86022],
              [61.26566, 69.84093],
              [61.2467, 69.83166],
              [61.2164, 69.83597],
              [61.18139, 69.86524],
              [61.20344, 69.86022],
            ],
          ],
          [
            [
              [58.56821, 69.46281],
              [58.5663, 69.45806],
              [58.48937, 69.45456],
              [58.50101, 69.46494],
              [58.48672, 69.47938],
              [58.51972, 69.47206],
              [58.55232, 69.47957],
              [58.56821, 69.46281],
            ],
          ],
          [
            [
              [59.47718, 69.83231],
              [59.44577, 69.82354],
              [59.36172, 69.82059],
              [59.41463, 69.82984],
              [59.47718, 69.83231],
            ],
          ],
          [
            [
              [59.47718, 69.83231],
              [59.4559, 69.8532],
              [59.40892, 69.85116],
              [59.4242, 69.88074],
              [59.26272, 69.88505],
              [59.24421, 69.89948],
              [59.18431, 69.91685],
              [59.13052, 69.9074],
              [59.11787, 69.8963],
              [59.16627, 69.8791],
              [59.18353, 69.85229],
              [59.12996, 69.85064],
              [59.06608, 69.85728],
              [59.05504, 69.87338],
              [59.02059, 69.87801],
              [59.00036, 69.89179],
              [58.97803, 69.92285],
              [58.85077, 69.95656],
              [58.79787, 69.96554],
              [58.76943, 69.98628],
              [58.68326, 70.02379],
              [58.63225, 70.05994],
              [58.6137, 70.06215],
              [58.60823, 70.08828],
              [58.6307, 70.09268],
              [58.63021, 70.11032],
              [58.59943, 70.10063],
              [58.55507, 70.11457],
              [58.56669, 70.12418],
              [58.6002, 70.1203],
              [58.65757, 70.12387],
              [58.62489, 70.13823],
              [58.54707, 70.15324],
              [58.54614, 70.16603],
              [58.50685, 70.16917],
              [58.42207, 70.20642],
              [58.45332, 70.22176],
              [58.40523, 70.23596],
              [58.42762, 70.24293],
              [58.42263, 70.25831],
              [58.51517, 70.25193],
              [58.57835, 70.23785],
              [58.61334, 70.24085],
              [58.68005, 70.2249],
              [58.68608, 70.21564],
              [58.75863, 70.19909],
              [58.81603, 70.20006],
              [58.836976, 70.207843],
              [58.81995, 70.23172],
              [58.74774, 70.23972],
              [58.72078, 70.26516],
              [58.69626, 70.27511],
              [58.65525, 70.27344],
              [58.60461, 70.28413],
              [58.59484, 70.2947],
              [58.49428, 70.32853],
              [58.53285, 70.3369],
              [58.63725, 70.31349],
              [58.66922, 70.31499],
              [58.66382, 70.34188],
              [58.72287, 70.33979],
              [58.77867, 70.36149],
              [58.79791, 70.38584],
              [58.76575, 70.40559],
              [58.79788, 70.42445],
              [58.88081, 70.42245],
              [58.96254, 70.43193],
              [58.96719, 70.44059],
              [59.076855, 70.462788],
              [59.098849, 70.444165],
              [59.090655, 70.431841],
              [59.153542, 70.416986],
              [59.218428, 70.388473],
              [59.259198, 70.380979],
              [59.29363, 70.36437],
              [59.38732, 70.33965],
              [59.38117, 70.32592],
              [59.43663, 70.29448],
              [59.48689, 70.27769],
              [59.52312, 70.25826],
              [59.53766, 70.23811],
              [59.57243, 70.22304],
              [59.586295, 70.204451],
              [59.643287, 70.197648],
              [59.73579, 70.16768],
              [59.79052, 70.15455],
              [59.8137, 70.1368],
              [59.80412, 70.1216],
              [59.8557, 70.1195],
              [59.89342, 70.09894],
              [59.94393, 70.08968],
              [60.0091, 70.08885],
              [60.02381, 70.09705],
              [60.0498, 70.08995],
              [60.05503, 70.06427],
              [60.10887, 70.03827],
              [60.16476, 70.02168],
              [60.1784, 70.00813],
              [60.28509, 69.96858],
              [60.33933, 69.95298],
              [60.34208, 69.93988],
              [60.36736, 69.9371],
              [60.39936, 69.94596],
              [60.41148, 69.93344],
              [60.45402, 69.92929],
              [60.48096, 69.90087],
              [60.44676, 69.88746],
              [60.47421, 69.87131],
              [60.49123, 69.8441],
              [60.5456, 69.82101],
              [60.55874, 69.79131],
              [60.5156, 69.78348],
              [60.47451, 69.74974],
              [60.50038, 69.72517],
              [60.51809, 69.72072],
              [60.49722, 69.70604],
              [60.4309, 69.71541],
              [60.39717, 69.70058],
              [60.32684, 69.7009],
              [60.30653, 69.68414],
              [60.26824, 69.67462],
              [60.197239, 69.668764],
              [60.164994, 69.692998],
              [60.12291, 69.69236],
              [60.082497, 69.700077],
              [60.049092, 69.713956],
              [60.00798, 69.7156],
              [60.04617, 69.68117],
              [59.99227, 69.7009],
              [59.96854, 69.69195],
              [60.00119, 69.66506],
              [59.98386, 69.65691],
              [59.92864, 69.67242],
              [59.92388, 69.68862],
              [59.84679, 69.67658],
              [59.81137, 69.687],
              [59.74285, 69.69231],
              [59.6416, 69.71304],
              [59.62057, 69.70389],
              [59.58484, 69.70698],
              [59.57698, 69.72116],
              [59.52092, 69.72095],
              [59.57325, 69.72684],
              [59.60911, 69.72452],
              [59.63094, 69.73699],
              [59.57468, 69.75676],
              [59.6001, 69.77428],
              [59.59535, 69.79276],
              [59.62139, 69.78803],
              [59.60954, 69.77593],
              [59.69227, 69.78108],
              [59.692284, 69.795748],
              [59.66767, 69.80294],
              [59.63864, 69.83125],
              [59.61103, 69.82975],
              [59.59409, 69.84267],
              [59.52475, 69.87051],
              [59.4815, 69.87522],
              [59.4839, 69.84526],
              [59.51657, 69.83943],
              [59.47718, 69.83231],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5519",
      properties: {
        name: "Нижегородская область",
        density: 0,
        path: "/world/Russia/Нижегородская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.795935, 55.103672],
            [41.783953, 55.129753],
            [41.814228, 55.148575],
            [41.805172, 55.151771],
            [41.822129, 55.166599],
            [41.873446, 55.164735],
            [41.882679, 55.177431],
            [41.868451, 55.179944],
            [41.866488, 55.194006],
            [41.908336, 55.210282],
            [41.873888, 55.222977],
            [41.879125, 55.233631],
            [41.860527, 55.241109],
            [41.880087, 55.248403],
            [41.894756, 55.264397],
            [41.911839, 55.256944],
            [41.920645, 55.264434],
            [41.959747, 55.275906],
            [41.972521, 55.286605],
            [41.966972, 55.320146],
            [41.940843, 55.334855],
            [41.946544, 55.34757],
            [41.961732, 55.348],
            [41.979674, 55.328155],
            [42.004222, 55.327471],
            [42.016448, 55.361525],
            [42.016668, 55.377439],
            [42.030788, 55.393279],
            [42.068568, 55.419689],
            [42.086916, 55.402262],
            [42.136006, 55.431279],
            [42.166871, 55.443995],
            [42.155906, 55.466584],
            [42.094265, 55.488752],
            [42.085064, 55.504279],
            [42.103603, 55.528165],
            [42.06483, 55.587369],
            [42.064176, 55.612244],
            [42.082847, 55.621835],
            [42.134566, 55.617994],
            [42.142695, 55.639853],
            [42.124879, 55.668345],
            [42.136665, 55.681753],
            [42.17168, 55.674412],
            [42.188331, 55.67704],
            [42.199227, 55.713067],
            [42.218082, 55.725598],
            [42.248126, 55.731104],
            [42.255492, 55.756079],
            [42.295724, 55.773173],
            [42.363013, 55.778011],
            [42.350174, 55.788301],
            [42.393333, 55.788542],
            [42.410994, 55.821301],
            [42.450867, 55.840657],
            [42.492061, 55.868713],
            [42.506266, 55.88505],
            [42.502894, 55.914379],
            [42.522778, 55.933777],
            [42.531744, 55.95661],
            [42.555538, 55.95845],
            [42.576799, 55.946097],
            [42.570988, 55.926779],
            [42.590342, 55.936013],
            [42.60881, 55.932994],
            [42.653378, 55.955191],
            [42.667076, 55.976181],
            [42.688358, 55.984135],
            [42.716769, 55.968332],
            [42.719076, 56.009528],
            [42.70487, 56.011126],
            [42.711927, 56.046537],
            [42.680652, 56.048237],
            [42.688223, 56.08365],
            [42.678957, 56.083946],
            [42.716474, 56.10441],
            [42.74008, 56.10124],
            [42.775515, 56.117373],
            [42.803773, 56.114997],
            [42.848324, 56.13317],
            [42.871771, 56.138158],
            [42.926038, 56.136538],
            [42.931039, 56.143072],
            [42.96122, 56.140375],
            [42.966181, 56.164211],
            [42.943966, 56.174048],
            [42.942883, 56.190469],
            [42.92163, 56.181934],
            [42.903013, 56.189919],
            [42.923744, 56.193678],
            [42.903545, 56.209389],
            [42.89489, 56.185386],
            [42.840645, 56.189545],
            [42.833521, 56.179893],
            [42.806581, 56.197564],
            [42.823445, 56.195343],
            [42.832058, 56.208992],
            [42.80024, 56.202139],
            [42.788201, 56.207921],
            [42.763367, 56.192849],
            [42.757401, 56.210617],
            [42.784438, 56.218047],
            [42.76364, 56.246047],
            [42.741779, 56.249094],
            [42.744215, 56.265555],
            [42.794772, 56.284807],
            [42.774688, 56.303914],
            [42.773942, 56.331789],
            [42.732761, 56.350955],
            [42.725957, 56.36644],
            [42.673948, 56.388161],
            [42.64675, 56.39029],
            [42.635422, 56.405681],
            [42.614182, 56.406734],
            [42.614748, 56.430057],
            [42.644559, 56.428873],
            [42.677119, 56.440716],
            [42.679765, 56.452573],
            [42.655302, 56.449928],
            [42.651056, 56.46847],
            [42.793414, 56.467912],
            [42.897287, 56.470342],
            [42.887011, 56.489771],
            [42.902709, 56.511607],
            [42.868773, 56.509206],
            [42.821133, 56.515931],
            [42.843133, 56.530356],
            [42.853172, 56.552922],
            [42.902305, 56.567869],
            [42.92491, 56.569364],
            [42.913446, 56.592371],
            [42.924127, 56.601803],
            [42.913325, 56.620179],
            [42.927336, 56.634938],
            [42.898378, 56.645773],
            [42.859146, 56.636619],
            [42.822979, 56.654869],
            [42.827591, 56.668003],
            [42.797447, 56.691727],
            [42.802596, 56.707983],
            [42.824681, 56.717636],
            [42.832322, 56.744479],
            [42.832164, 56.773185],
            [42.870814, 56.809429],
            [42.890917, 56.809883],
            [42.905376, 56.799154],
            [42.922937, 56.809616],
            [42.983092, 56.827926],
            [43.027463, 56.820827],
            [43.046145, 56.826432],
            [43.084443, 56.824191],
            [43.11676, 56.834262],
            [43.140145, 56.85318],
            [43.152336, 56.879027],
            [43.173024, 56.870686],
            [43.181248, 56.855121],
            [43.198231, 56.860434],
            [43.236515, 56.851282],
            [43.249595, 56.8085],
            [43.270058, 56.797537],
            [43.272947, 56.813358],
            [43.300596, 56.820831],
            [43.285637, 56.858406],
            [43.250795, 56.895032],
            [43.23071, 56.952499],
            [43.214734, 56.978615],
            [43.151622, 57.02938],
            [43.127419, 57.054379],
            [43.099189, 57.096033],
            [43.086668, 57.136286],
            [43.086767, 57.166766],
            [43.101808, 57.198735],
            [43.136787, 57.227748],
            [43.184381, 57.255553],
            [43.196526, 57.276124],
            [43.203062, 57.315011],
            [43.225935, 57.336457],
            [43.310942, 57.405349],
            [43.324612, 57.418904],
            [43.304617, 57.428877],
            [43.305531, 57.479644],
            [43.339301, 57.494514],
            [43.357216, 57.50831],
            [43.361039, 57.527611],
            [43.419202, 57.549875],
            [43.451359, 57.570926],
            [43.467661, 57.59135],
            [43.496505, 57.604608],
            [43.518272, 57.605414],
            [43.528127, 57.57872],
            [43.511466, 57.567344],
            [43.536189, 57.572719],
            [43.551419, 57.554983],
            [43.630696, 57.54719],
            [43.649508, 57.531335],
            [43.659094, 57.500162],
            [43.674054, 57.488607],
            [43.714455, 57.481092],
            [43.74751, 57.45636],
            [43.82231, 57.432854],
            [43.853393, 57.417485],
            [43.879908, 57.42716],
            [43.91501, 57.42091],
            [43.9453, 57.431819],
            [44.01707, 57.432568],
            [44.031282, 57.44352],
            [44.061572, 57.445501],
            [44.122268, 57.457501],
            [44.14883, 57.473695],
            [44.178188, 57.471599],
            [44.195779, 57.482783],
            [44.226978, 57.491356],
            [44.245756, 57.51785],
            [44.31321, 57.513657],
            [44.349674, 57.527987],
            [44.365285, 57.528337],
            [44.424815, 57.56923],
            [44.451494, 57.57191],
            [44.447998, 57.582977],
            [44.472463, 57.591482],
            [44.502869, 57.612103],
            [44.493081, 57.629927],
            [44.50974, 57.64717],
            [44.500886, 57.655674],
            [44.561815, 57.675014],
            [44.56694, 57.685965],
            [44.597113, 57.700878],
            [44.633695, 57.694471],
            [44.67913, 57.698199],
            [44.710585, 57.690627],
            [44.739011, 57.697385],
            [44.766505, 57.712414],
            [44.760097, 57.719287],
            [44.761027, 57.753422],
            [44.776754, 57.757966],
            [44.83011, 57.793732],
            [44.834768, 57.819712],
            [44.855855, 57.829265],
            [44.863892, 57.854546],
            [44.880783, 57.881924],
            [44.873559, 57.894506],
            [44.842803, 57.906039],
            [44.800976, 57.959861],
            [44.826373, 57.968949],
            [44.816935, 57.98223],
            [44.813206, 58.014384],
            [44.829749, 58.018811],
            [44.855611, 58.037684],
            [44.849901, 58.061683],
            [44.869006, 58.076712],
            [44.95076, 58.085157],
            [44.971645, 58.075262],
            [45.007842, 58.076179],
            [45.009464, 58.091175],
            [45.223571, 58.088666],
            [45.221458, 58.052715],
            [45.294337, 58.051415],
            [45.293524, 58.032057],
            [45.50891, 58.028511],
            [45.50785, 57.976342],
            [45.534206, 57.947063],
            [45.557957, 57.936896],
            [45.589841, 57.940394],
            [45.612942, 57.919409],
            [45.673213, 57.924128],
            [45.709, 57.941291],
            [45.713798, 57.954467],
            [45.742835, 57.966831],
            [45.749834, 57.99462],
            [45.733398, 58.011973],
            [45.749503, 58.014169],
            [45.747791, 58.028677],
            [45.795864, 58.021653],
            [45.808877, 58.041255],
            [45.842632, 58.050935],
            [45.903391, 58.042639],
            [45.922555, 58.057032],
            [46.063617, 58.056869],
            [46.064194, 58.058746],
            [46.34493, 58.062218],
            [46.346664, 58.055199],
            [46.402887, 58.031152],
            [46.416737, 58.014236],
            [46.436307, 58.004617],
            [46.4849, 58.016102],
            [46.492278, 58.040772],
            [46.56123, 58.03671],
            [46.585195, 58.027796],
            [46.847192, 58.025271],
            [47.120798, 58.020341],
            [47.296928, 58.0157],
            [47.417182, 58.006856],
            [47.410783, 57.980791],
            [47.438997, 57.976656],
            [47.456502, 57.980847],
            [47.484683, 57.971676],
            [47.560058, 57.961571],
            [47.563651, 57.967771],
            [47.598669, 57.953477],
            [47.633861, 57.952999],
            [47.73261, 57.933815],
            [47.738426, 57.909956],
            [47.771781, 57.888717],
            [47.770129, 57.879952],
            [47.726188, 57.879463],
            [47.657445, 57.864702],
            [47.617145, 57.859228],
            [47.628423, 57.845463],
            [47.603838, 57.817642],
            [47.579293, 57.809888],
            [47.563994, 57.790857],
            [47.599652, 57.784929],
            [47.593931, 57.762664],
            [47.605583, 57.741104],
            [47.563998, 57.714817],
            [47.535192, 57.689273],
            [47.556497, 57.652749],
            [47.516736, 57.627954],
            [47.52221, 57.609711],
            [47.536597, 57.605565],
            [47.529177, 57.573888],
            [47.4941, 57.578697],
            [47.481365, 57.515179],
            [47.375067, 57.520732],
            [47.377968, 57.546853],
            [47.368887, 57.556016],
            [47.350354, 57.550709],
            [47.338247, 57.560286],
            [47.314851, 57.547986],
            [47.293717, 57.561322],
            [47.236707, 57.554687],
            [47.203332, 57.531095],
            [47.178579, 57.530431],
            [47.13405, 57.509617],
            [47.086286, 57.505387],
            [47.021939, 57.487807],
            [46.982674, 57.496015],
            [46.941295, 57.495144],
            [46.902445, 57.515709],
            [46.862269, 57.505301],
            [46.840045, 57.506171],
            [46.800987, 57.522134],
            [46.752933, 57.521511],
            [46.771012, 57.498832],
            [46.773542, 57.470265],
            [46.80439, 57.471634],
            [46.822551, 57.459361],
            [46.821639, 57.446632],
            [46.776903, 57.435603],
            [46.769159, 57.422097],
            [46.741702, 57.430295],
            [46.730881, 57.416737],
            [46.710647, 57.422127],
            [46.687803, 57.406496],
            [46.684735, 57.395011],
            [46.709008, 57.381615],
            [46.725948, 57.404257],
            [46.771638, 57.409316],
            [46.761026, 57.366569],
            [46.720891, 57.361179],
            [46.70298, 57.347496],
            [46.68254, 57.343308],
            [46.715255, 57.313207],
            [46.717703, 57.285511],
            [46.748592, 57.279292],
            [46.755225, 57.292104],
            [46.79818, 57.290529],
            [46.809749, 57.260097],
            [46.796316, 57.249524],
            [46.807097, 57.223859],
            [46.825837, 57.230327],
            [46.856562, 57.221494],
            [46.847541, 57.211459],
            [46.838155, 57.177129],
            [46.779901, 57.182228],
            [46.776211, 57.167923],
            [46.749178, 57.161911],
            [46.773933, 57.128327],
            [46.786578, 57.134795],
            [46.802778, 57.123439],
            [46.769746, 57.117257],
            [46.767052, 57.096526],
            [46.781813, 57.086243],
            [46.813075, 57.090514],
            [46.813988, 57.077163],
            [46.797405, 57.042915],
            [46.788681, 57.03652],
            [46.75445, 57.03284],
            [46.748523, 57.000955],
            [46.790524, 56.996727],
            [46.764653, 56.967786],
            [46.721544, 56.963392],
            [46.717635, 56.971548],
            [46.677658, 56.971333],
            [46.6814, 56.951772],
            [46.67317, 56.942033],
            [46.624214, 56.939052],
            [46.606471, 56.923338],
            [46.59311, 56.929966],
            [46.546719, 56.932744],
            [46.53881, 56.913397],
            [46.505353, 56.913076],
            [46.514011, 56.933599],
            [46.481516, 56.937874],
            [46.464093, 56.930498],
            [46.447952, 56.938835],
            [46.314766, 56.945247],
            [46.303545, 56.887419],
            [46.273081, 56.902918],
            [46.239731, 56.898962],
            [46.207344, 56.882714],
            [46.143958, 56.878652],
            [46.091476, 56.862831],
            [45.978706, 56.869991],
            [45.978172, 56.858447],
            [45.941509, 56.859195],
            [45.918534, 56.722482],
            [45.894697, 56.726543],
            [45.878556, 56.737553],
            [45.882191, 56.719382],
            [45.861989, 56.706302],
            [45.865013, 56.690408],
            [45.890717, 56.696738],
            [45.90186, 56.706662],
            [45.920138, 56.698752],
            [45.89827, 56.692968],
            [45.899815, 56.678731],
            [45.925907, 56.679297],
            [45.950749, 56.649393],
            [45.886363, 56.647338],
            [45.886151, 56.626066],
            [45.750614, 56.623178],
            [45.745056, 56.607893],
            [45.717073, 56.615134],
            [45.66978, 56.614284],
            [45.645435, 56.583093],
            [45.627051, 56.568021],
            [45.625555, 56.552308],
            [45.644155, 56.53403],
            [45.656875, 56.536062],
            [45.652172, 56.517356],
            [45.62887, 56.517783],
            [45.627695, 56.507308],
            [45.648753, 56.491061],
            [45.661902, 56.460918],
            [45.687663, 56.452901],
            [45.751156, 56.455361],
            [45.768473, 56.446275],
            [45.765588, 56.420194],
            [45.757037, 56.40865],
            [45.800968, 56.425005],
            [45.89578, 56.434306],
            [45.916196, 56.426503],
            [45.963762, 56.428534],
            [45.974452, 56.412608],
            [45.952968, 56.403843],
            [45.934263, 56.373806],
            [45.945594, 56.364507],
            [45.970041, 56.318456],
            [45.923133, 56.313684],
            [45.926267, 56.296612],
            [45.92044, 56.256409],
            [45.8665, 56.258791],
            [45.887131, 56.234528],
            [45.899462, 56.237331],
            [45.897548, 56.219832],
            [45.917684, 56.219199],
            [45.936375, 56.208115],
            [45.938073, 56.187445],
            [45.963529, 56.162499],
            [45.996376, 56.171785],
            [46.025664, 56.168044],
            [46.042232, 56.158424],
            [46.076757, 56.174244],
            [46.094822, 56.170396],
            [46.080179, 56.153401],
            [46.110839, 56.150104],
            [46.06917, 56.124006],
            [46.047044, 56.121547],
            [46.044256, 56.104603],
            [46.056341, 56.098967],
            [46.054453, 56.073778],
            [46.081232, 56.067837],
            [46.109213, 56.071766],
            [46.086554, 56.039751],
            [46.155905, 56.049051],
            [46.13582, 56.029873],
            [46.185308, 56.031569],
            [46.180124, 56.010566],
            [46.080268, 55.948898],
            [46.102214, 55.926968],
            [46.134376, 55.92199],
            [46.145996, 55.913638],
            [46.125517, 55.901897],
            [46.150682, 55.900137],
            [46.168537, 55.886183],
            [46.142623, 55.889017],
            [46.12001, 55.878293],
            [46.073492, 55.867697],
            [46.111353, 55.831387],
            [46.100442, 55.798637],
            [46.067654, 55.78252],
            [46.032636, 55.785416],
            [45.992459, 55.749566],
            [45.952936, 55.75773],
            [45.933783, 55.72984],
            [45.953328, 55.712665],
            [45.914065, 55.695883],
            [45.916555, 55.668798],
            [45.93913, 55.665878],
            [45.952126, 55.653825],
            [45.960194, 55.630865],
            [46.014439, 55.633772],
            [46.041562, 55.629508],
            [46.06508, 55.614096],
            [46.062848, 55.600327],
            [46.080751, 55.595669],
            [46.098174, 55.575732],
            [46.082124, 55.57282],
            [46.11675, 55.555302],
            [46.092166, 55.549764],
            [46.10856, 55.541752],
            [46.107867, 55.530672],
            [46.133022, 55.51547],
            [46.151812, 55.526688],
            [46.19825, 55.521697],
            [46.18572, 55.542244],
            [46.206392, 55.543998],
            [46.228756, 55.532221],
            [46.214334, 55.520674],
            [46.237134, 55.521447],
            [46.250696, 55.502979],
            [46.28005, 55.499139],
            [46.291894, 55.508812],
            [46.308803, 55.500014],
            [46.32416, 55.477979],
            [46.345658, 55.484469],
            [46.394891, 55.462952],
            [46.384585, 55.451703],
            [46.38922, 55.427651],
            [46.378577, 55.417811],
            [46.332228, 55.413427],
            [46.256641, 55.399179],
            [46.245024, 55.386035],
            [46.214155, 55.371729],
            [46.236098, 55.347988],
            [46.199877, 55.337738],
            [46.229394, 55.287286],
            [46.221752, 55.271374],
            [46.182287, 55.263606],
            [46.163557, 55.24619],
            [46.173581, 55.236669],
            [46.12497, 55.223764],
            [46.105613, 55.230529],
            [46.079679, 55.220505],
            [46.074605, 55.200585],
            [46.056564, 55.189685],
            [46.044538, 55.166256],
            [46.011838, 55.163123],
            [46.013467, 55.149717],
            [46.028377, 55.149342],
            [46.043663, 55.114262],
            [46.032038, 55.094169],
            [46.077993, 55.082189],
            [46.067971, 55.064899],
            [46.040972, 55.053873],
            [46.061001, 55.047639],
            [46.034488, 55.027136],
            [45.998884, 55.025024],
            [45.97341, 55.001105],
            [45.944325, 54.997515],
            [45.927419, 55.003075],
            [45.923738, 54.993674],
            [45.898507, 54.998448],
            [45.904168, 55.004454],
            [45.829161, 55.009269],
            [45.809006, 54.997754],
            [45.807111, 55.005869],
            [45.722519, 54.991403],
            [45.698321, 55.011776],
            [45.73417, 55.04171],
            [45.717695, 55.047711],
            [45.74328, 55.057063],
            [45.752112, 55.067922],
            [45.718997, 55.08035],
            [45.73384, 55.093314],
            [45.748947, 55.090859],
            [45.75589, 55.114472],
            [45.783599, 55.116384],
            [45.777603, 55.144053],
            [45.76138, 55.155669],
            [45.736751, 55.147678],
            [45.73461, 55.165426],
            [45.749196, 55.168957],
            [45.72766, 55.184632],
            [45.703121, 55.190268],
            [45.690082, 55.18091],
            [45.640229, 55.177921],
            [45.61475, 55.162241],
            [45.593126, 55.16577],
            [45.584289, 55.142435],
            [45.592266, 55.130072],
            [45.550225, 55.13174],
            [45.569781, 55.101517],
            [45.527845, 55.098275],
            [45.509145, 55.110302],
            [45.486402, 55.112268],
            [45.483294, 55.084672],
            [45.46176, 55.081775],
            [45.462445, 55.071754],
            [45.422554, 55.038037],
            [45.421346, 55.024961],
            [45.43654, 55.008186],
            [45.419462, 55.004742],
            [45.437051, 54.982688],
            [45.44683, 54.982101],
            [45.445806, 54.957524],
            [45.415256, 54.947077],
            [45.432503, 54.933619],
            [45.422639, 54.920162],
            [45.43808, 54.910789],
            [45.425473, 54.905067],
            [45.426846, 54.885528],
            [45.391494, 54.87393],
            [45.371238, 54.882172],
            [45.298144, 54.878076],
            [45.283211, 54.868448],
            [45.259011, 54.878423],
            [45.230701, 54.877285],
            [45.222378, 54.860058],
            [45.227265, 54.84573],
            [45.226577, 54.802827],
            [45.209673, 54.802924],
            [45.216108, 54.779226],
            [45.189256, 54.751461],
            [45.158715, 54.7722],
            [45.158369, 54.786553],
            [45.133563, 54.786354],
            [45.111952, 54.800055],
            [45.077714, 54.773143],
            [45.073589, 54.763587],
            [45.034551, 54.742691],
            [45.018253, 54.705283],
            [45.034551, 54.691147],
            [45.071197, 54.671654],
            [45.08741, 54.652352],
            [45.036103, 54.629858],
            [45.052058, 54.621014],
            [45.030604, 54.605512],
            [45.045703, 54.597464],
            [45.047684, 54.58165],
            [45.03018, 54.582248],
            [45.011049, 54.570761],
            [45.016883, 54.547474],
            [45.035496, 54.54653],
            [45.070591, 54.559667],
            [45.101655, 54.543398],
            [45.08818, 54.530252],
            [45.10963, 54.523827],
            [45.116405, 54.532045],
            [45.141635, 54.52816],
            [45.126884, 54.51646],
            [45.139918, 54.489457],
            [45.107319, 54.479136],
            [45.099935, 54.468519],
            [45.038416, 54.480232],
            [45.02469, 54.49439],
            [45.014308, 54.484224],
            [44.965142, 54.487611],
            [44.95879, 54.479041],
            [44.907995, 54.463531],
            [44.850685, 54.489257],
            [44.844933, 54.479833],
            [44.804176, 54.488858],
            [44.806583, 54.501412],
            [44.769772, 54.520891],
            [44.741797, 54.505602],
            [44.700698, 54.494338],
            [44.687659, 54.484424],
            [44.651452, 54.485566],
            [44.58624, 54.471508],
            [44.575253, 54.480135],
            [44.602628, 54.487364],
            [44.602366, 54.517402],
            [44.619532, 54.522534],
            [44.622881, 54.495784],
            [44.658824, 54.514816],
            [44.626656, 54.556182],
            [44.598596, 54.570262],
            [44.580232, 54.572801],
            [44.558443, 54.532142],
            [44.536737, 54.517904],
            [44.525583, 54.525573],
            [44.461659, 54.522933],
            [44.43094, 54.533386],
            [44.404768, 54.534631],
            [44.405199, 54.547974],
            [44.381515, 54.552105],
            [44.371133, 54.583641],
            [44.35629, 54.590453],
            [44.308583, 54.590453],
            [44.271862, 54.60621],
            [44.248113, 54.597503],
            [44.235759, 54.603166],
            [44.200579, 54.596459],
            [44.194658, 54.613701],
            [44.172005, 54.635362],
            [44.120093, 54.633919],
            [44.101649, 54.645291],
            [44.06844, 54.648567],
            [44.079934, 54.676357],
            [44.07839, 54.70403],
            [44.094179, 54.718352],
            [44.09598, 54.734113],
            [44.068097, 54.743426],
            [44.031286, 54.741489],
            [44.002889, 54.72792],
            [43.973629, 54.727426],
            [43.921118, 54.739324],
            [43.915027, 54.753784],
            [43.957756, 54.806086],
            [43.861144, 54.80495],
            [43.77354, 54.819436],
            [43.78006, 54.829669],
            [43.748212, 54.821864],
            [43.690637, 54.831749],
            [43.672874, 54.841729],
            [43.618222, 54.854423],
            [43.632036, 54.862077],
            [43.614709, 54.870869],
            [43.614365, 54.883407],
            [43.590768, 54.902653],
            [43.520838, 54.899643],
            [43.465669, 54.909411],
            [43.454771, 54.934408],
            [43.422021, 54.935277],
            [43.407645, 54.929519],
            [43.388853, 54.935592],
            [43.386935, 54.956907],
            [43.341734, 54.950598],
            [43.340037, 54.940904],
            [43.362573, 54.93224],
            [43.35547, 54.905895],
            [43.300436, 54.910963],
            [43.28735, 54.929483],
            [43.265818, 54.91256],
            [43.242986, 54.919565],
            [43.223367, 54.910532],
            [43.21244, 54.892056],
            [43.169227, 54.897769],
            [43.141245, 54.855142],
            [43.131063, 54.849259],
            [43.138148, 54.829512],
            [43.11591, 54.794375],
            [43.086784, 54.775022],
            [43.046913, 54.772469],
            [43.03314, 54.794118],
            [43.020443, 54.78492],
            [42.968963, 54.797617],
            [42.952109, 54.782394],
            [42.924684, 54.780464],
            [42.901959, 54.759991],
            [42.885506, 54.767088],
            [42.853161, 54.765487],
            [42.832058, 54.770336],
            [42.818053, 54.754304],
            [42.79367, 54.754855],
            [42.809342, 54.770075],
            [42.786079, 54.777049],
            [42.772876, 54.789618],
            [42.738283, 54.787761],
            [42.741648, 54.812669],
            [42.724419, 54.818554],
            [42.672316, 54.807089],
            [42.641943, 54.781992],
            [42.61555, 54.78209],
            [42.601969, 54.79257],
            [42.579816, 54.787432],
            [42.571163, 54.796266],
            [42.543476, 54.803493],
            [42.534132, 54.817507],
            [42.501685, 54.83814],
            [42.475039, 54.819597],
            [42.448387, 54.810519],
            [42.444321, 54.798354],
            [42.433575, 54.793561],
            [42.425528, 54.805582],
            [42.442662, 54.825224],
            [42.44429, 54.855454],
            [42.425751, 54.861484],
            [42.445182, 54.88449],
            [42.458956, 54.878477],
            [42.468336, 54.895252],
            [42.494678, 54.907429],
            [42.493807, 54.91886],
            [42.462432, 54.943462],
            [42.445968, 54.967753],
            [42.485231, 54.990865],
            [42.485478, 55.009005],
            [42.444723, 55.025903],
            [42.42944, 55.013105],
            [42.404528, 55.005028],
            [42.366817, 55.00714],
            [42.321528, 54.988004],
            [42.30028, 54.992104],
            [42.254307, 54.97856],
            [42.226476, 54.958679],
            [42.113063, 54.978372],
            [42.060815, 54.978184],
            [41.999029, 54.9962],
            [41.989585, 55.016764],
            [42.002568, 55.038757],
            [41.980948, 55.055406],
            [41.997907, 55.075101],
            [41.997626, 55.099703],
            [41.961904, 55.095416],
            [41.927704, 55.077833],
            [41.87701, 55.059816],
            [41.85803, 55.058076],
            [41.836876, 55.074788],
            [41.803794, 55.070811],
            [41.775525, 55.100507],
            [41.795935, 55.103672],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5517",
      properties: {
        name: "Новгородская область",
        density: 0,
        path: "/world/Russia/Новгородская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.894085, 58.445747],
            [29.899773, 58.45326],
            [29.930579, 58.442943],
            [29.944335, 58.45831],
            [29.963506, 58.448139],
            [29.953409, 58.435919],
            [30.021129, 58.445286],
            [30.069715, 58.447409],
            [30.062213, 58.474007],
            [30.097774, 58.479715],
            [30.108895, 58.489301],
            [30.132895, 58.492008],
            [30.093382, 58.502709],
            [30.099747, 58.51449],
            [30.171235, 58.530442],
            [30.119793, 58.565802],
            [30.102779, 58.599772],
            [30.072681, 58.628347],
            [30.111447, 58.641425],
            [30.070616, 58.65401],
            [30.065055, 58.667875],
            [30.083421, 58.668022],
            [30.117042, 58.679035],
            [30.136505, 58.693231],
            [30.108992, 58.702084],
            [30.093186, 58.719883],
            [30.10299, 58.728005],
            [30.120552, 58.720981],
            [30.121606, 58.745329],
            [30.152307, 58.742787],
            [30.177806, 58.757696],
            [30.192149, 58.745769],
            [30.215526, 58.760751],
            [30.229869, 58.743629],
            [30.270039, 58.748679],
            [30.28837, 58.736533],
            [30.323053, 58.743924],
            [30.338783, 58.760534],
            [30.347053, 58.746924],
            [30.377748, 58.73926],
            [30.368969, 58.718991],
            [30.388067, 58.719065],
            [30.406441, 58.702876],
            [30.444995, 58.704468],
            [30.496031, 58.723713],
            [30.561832, 58.726569],
            [30.586033, 58.70447],
            [30.598691, 58.715739],
            [30.619838, 58.71455],
            [30.643105, 58.731892],
            [30.664179, 58.736576],
            [30.6824, 58.713746],
            [30.713789, 58.736394],
            [30.700691, 58.745174],
            [30.713969, 58.778193],
            [30.715278, 58.809309],
            [30.728272, 58.813919],
            [30.715247, 58.8257],
            [30.67888, 58.833528],
            [30.68272, 58.858718],
            [30.70555, 58.853523],
            [30.736282, 58.856816],
            [30.749598, 58.870061],
            [30.762184, 58.856524],
            [30.772318, 58.872842],
            [30.813167, 58.876562],
            [30.873475, 58.903392],
            [30.922426, 58.921503],
            [30.952352, 58.94276],
            [30.965082, 58.963907],
            [30.954106, 58.973145],
            [30.978618, 58.980755],
            [30.979056, 58.989316],
            [31.003275, 59.000292],
            [30.98666, 59.012498],
            [30.964267, 59.016809],
            [30.956004, 59.033255],
            [30.97115, 59.043847],
            [30.990029, 59.040555],
            [31.02193, 59.065891],
            [31.046515, 59.075477],
            [31.05976, 59.067941],
            [31.080905, 59.090679],
            [31.129491, 59.08929],
            [31.164102, 59.075406],
            [31.15503, 59.054625],
            [31.187226, 59.047089],
            [31.222168, 59.005089],
            [31.300095, 59.006846],
            [31.317327, 59.018005],
            [31.306643, 59.022907],
            [31.365912, 59.026348],
            [31.371545, 59.046982],
            [31.356837, 59.056421],
            [31.425946, 59.072172],
            [31.476982, 59.101899],
            [31.462931, 59.124363],
            [31.483967, 59.137644],
            [31.488575, 59.166967],
            [31.469404, 59.165211],
            [31.508878, 59.200718],
            [31.510194, 59.21184],
            [31.53361, 59.199402],
            [31.566976, 59.211201],
            [31.580584, 59.231836],
            [31.548826, 59.259],
            [31.537263, 59.281025],
            [31.54019, 59.293684],
            [31.558848, 59.293245],
            [31.541212, 59.315781],
            [31.552404, 59.369069],
            [31.602563, 59.376314],
            [31.648003, 59.362046],
            [31.686894, 59.360583],
            [31.710677, 59.335797],
            [31.747408, 59.342822],
            [31.760287, 59.333749],
            [31.726222, 59.321524],
            [31.740312, 59.315218],
            [31.800679, 59.313024],
            [31.808873, 59.327274],
            [31.786775, 59.339493],
            [31.804102, 59.354581],
            [31.860347, 59.360604],
            [31.906152, 59.376154],
            [31.927479, 59.41146],
            [31.963515, 59.432369],
            [31.986358, 59.433329],
            [31.989784, 59.421321],
            [31.97098, 59.40515],
            [32.001565, 59.409687],
            [32.031457, 59.38812],
            [32.032554, 59.405169],
            [32.095995, 59.38192],
            [32.11802, 59.382505],
            [32.114947, 59.371676],
            [32.187354, 59.319213],
            [32.222036, 59.338385],
            [32.211574, 59.320311],
            [32.184428, 59.310652],
            [32.202941, 59.303646],
            [32.253867, 59.325891],
            [32.236085, 59.343379],
            [32.260159, 59.333208],
            [32.22855, 59.306793],
            [32.199722, 59.289634],
            [32.218601, 59.275731],
            [32.248454, 59.290439],
            [32.276003, 59.289159],
            [32.296785, 59.278769],
            [32.279225, 59.258866],
            [32.289689, 59.243793],
            [32.315885, 59.241287],
            [32.286252, 59.224604],
            [32.301106, 59.215311],
            [32.278496, 59.211945],
            [32.280729, 59.202122],
            [32.347097, 59.172488],
            [32.373549, 59.170824],
            [32.408306, 59.160654],
            [32.475441, 59.152277],
            [32.464174, 59.137715],
            [32.43249, 59.15169],
            [32.407611, 59.151251],
            [32.389173, 59.142433],
            [32.417564, 59.130507],
            [32.447236, 59.107971],
            [32.4997, 59.112508],
            [32.575761, 59.135778],
            [32.564201, 59.115948],
            [32.615568, 59.110095],
            [32.647397, 59.117194],
            [32.679042, 59.144981],
            [32.725908, 59.148458],
            [32.728833, 59.173921],
            [32.74676, 59.175166],
            [32.764174, 59.188044],
            [32.738417, 59.195672],
            [32.765856, 59.206355],
            [32.770172, 59.218648],
            [32.736805, 59.240252],
            [32.72956, 59.257154],
            [32.744998, 59.265203],
            [32.732449, 59.271441],
            [32.76318, 59.286076],
            [32.773204, 59.301662],
            [32.846921, 59.348621],
            [32.92141, 59.348622],
            [32.937799, 59.373098],
            [32.970725, 59.392197],
            [32.966106, 59.420202],
            [33.020992, 59.426387],
            [33.056956, 59.418503],
            [33.066544, 59.379503],
            [33.112934, 59.388504],
            [33.138436, 59.371346],
            [33.155191, 59.383858],
            [33.212852, 59.363024],
            [33.23473, 59.362439],
            [33.261586, 59.337195],
            [33.262062, 59.320932],
            [33.342771, 59.315153],
            [33.348401, 59.385252],
            [33.444585, 59.379253],
            [33.523794, 59.378011],
            [33.536818, 59.387084],
            [33.630295, 59.382494],
            [33.62203, 59.327798],
            [33.663372, 59.327287],
            [33.660739, 59.308774],
            [33.690447, 59.310604],
            [33.706362, 59.298202],
            [33.733765, 59.297653],
            [33.773571, 59.28302],
            [33.78206, 59.264727],
            [33.804305, 59.258782],
            [33.79977, 59.239318],
            [33.819526, 59.234489],
            [33.791393, 59.216177],
            [33.800101, 59.200665],
            [33.907846, 59.207326],
            [33.922076, 59.236723],
            [33.93254, 59.234674],
            [33.929835, 59.199991],
            [33.946848, 59.187296],
            [33.966019, 59.186052],
            [33.972166, 59.173905],
            [34.011606, 59.162382],
            [34.032296, 59.147703],
            [34.067181, 59.150181],
            [34.076767, 59.140523],
            [34.116426, 59.141914],
            [34.117889, 59.156841],
            [34.105741, 59.170158],
            [34.158973, 59.183018],
            [34.176535, 59.173287],
            [34.21162, 59.178739],
            [34.215204, 59.205227],
            [34.248351, 59.20713],
            [34.25695, 59.188691],
            [34.28417, 59.178155],
            [34.330122, 59.18379],
            [34.319438, 59.190521],
            [34.341462, 59.204132],
            [34.38083, 59.177717],
            [34.391952, 59.182766],
            [34.397953, 59.160632],
            [34.423344, 59.156681],
            [34.423125, 59.144681],
            [34.450966, 59.160066],
            [34.479577, 59.141627],
            [34.511956, 59.144646],
            [34.556885, 59.118012],
            [34.58019, 59.121616],
            [34.627166, 59.114153],
            [34.658414, 59.121465],
            [34.692216, 59.11088],
            [34.706925, 59.097856],
            [34.725364, 59.098002],
            [34.722362, 59.084852],
            [34.719093, 59.077804],
            [34.787139, 59.059266],
            [34.864922, 59.055515],
            [34.897115, 59.049105],
            [34.880476, 59.037994],
            [34.886035, 59.021586],
            [34.854364, 59.005326],
            [34.868292, 58.990594],
            [34.964019, 58.97239],
            [34.952621, 58.954057],
            [34.998257, 58.939983],
            [35.012299, 58.951467],
            [35.050143, 58.949693],
            [35.047105, 58.93273],
            [35.010426, 58.93434],
            [35.02031, 58.924973],
            [35.071264, 58.926817],
            [35.126324, 58.950953],
            [35.17886, 58.948819],
            [35.185427, 58.924265],
            [35.199209, 58.921745],
            [35.185952, 58.905345],
            [35.162752, 58.907806],
            [35.138309, 58.885517],
            [35.131941, 58.868317],
            [35.143263, 58.84902],
            [35.128426, 58.81525],
            [35.175289, 58.816589],
            [35.209615, 58.829118],
            [35.245632, 58.815665],
            [35.224269, 58.804292],
            [35.226512, 58.789791],
            [35.253609, 58.792572],
            [35.247236, 58.78074],
            [35.27866, 58.786333],
            [35.282841, 58.767134],
            [35.295517, 58.779846],
            [35.299956, 58.799885],
            [35.317402, 58.799765],
            [35.366683, 58.827381],
            [35.360265, 58.846879],
            [35.3872, 58.841041],
            [35.409386, 58.84934],
            [35.43756, 58.847561],
            [35.461428, 58.861123],
            [35.468928, 58.84862],
            [35.491686, 58.843629],
            [35.552567, 58.847333],
            [35.557281, 58.828387],
            [35.575546, 58.829182],
            [35.57926, 58.810316],
            [35.726526, 58.818099],
            [35.711735, 58.821923],
            [35.724038, 58.838771],
            [35.75633, 58.842318],
            [35.775797, 58.829755],
            [35.827022, 58.815966],
            [35.817984, 58.806757],
            [35.790648, 58.821448],
            [35.744992, 58.82213],
            [35.750499, 58.781599],
            [35.775429, 58.772214],
            [35.783203, 58.753698],
            [35.83502, 58.75555],
            [35.858508, 58.744873],
            [35.899606, 58.755414],
            [35.925393, 58.747785],
            [35.962167, 58.751129],
            [35.97774, 58.745056],
            [35.966447, 58.735174],
            [35.912573, 58.738155],
            [35.928449, 58.729182],
            [35.986987, 58.72292],
            [35.987598, 58.684919],
            [35.995346, 58.684884],
            [35.996759, 58.714157],
            [36.010762, 58.703056],
            [36.016158, 58.723456],
            [36.033312, 58.725155],
            [36.045221, 58.714719],
            [36.103803, 58.724755],
            [36.119013, 58.699482],
            [36.116421, 58.674069],
            [36.153556, 58.632827],
            [36.127689, 58.623972],
            [36.1498, 58.613111],
            [36.199279, 58.61869],
            [36.219745, 58.607657],
            [36.228686, 58.582474],
            [36.212545, 58.559521],
            [36.176846, 58.541681],
            [36.181682, 58.51165],
            [36.124582, 58.497919],
            [36.102447, 58.504954],
            [36.043097, 58.473604],
            [36.015296, 58.472598],
            [36.0197, 58.459165],
            [35.958054, 58.466351],
            [35.92145, 58.451908],
            [35.918009, 58.439865],
            [35.941912, 58.438396],
            [35.939633, 58.423027],
            [35.916309, 58.42043],
            [35.904824, 58.434683],
            [35.888604, 58.422124],
            [35.835318, 58.41584],
            [35.836414, 58.426799],
            [35.768285, 58.432275],
            [35.777631, 58.45289],
            [35.754942, 58.45011],
            [35.732741, 58.434081],
            [35.688914, 58.441939],
            [35.690179, 58.448823],
            [35.656716, 58.450978],
            [35.644882, 58.46017],
            [35.622531, 58.450428],
            [35.551328, 58.456874],
            [35.536775, 58.445934],
            [35.516529, 58.447671],
            [35.497564, 58.428603],
            [35.460123, 58.428659],
            [35.470656, 58.409995],
            [35.376726, 58.406808],
            [35.331258, 58.412427],
            [35.304491, 58.408005],
            [35.304681, 58.396007],
            [35.254972, 58.390497],
            [35.247637, 58.381558],
            [35.283327, 58.36566],
            [35.276753, 58.345296],
            [35.255755, 58.317227],
            [35.264654, 58.297481],
            [35.257167, 58.274199],
            [35.231997, 58.26181],
            [35.206943, 58.25826],
            [35.195361, 58.246905],
            [35.142443, 58.23915],
            [35.11844, 58.212464],
            [35.077818, 58.213585],
            [35.050888, 58.194615],
            [35.004235, 58.207862],
            [34.974672, 58.225534],
            [34.963552, 58.218088],
            [34.954828, 58.229452],
            [34.928811, 58.231576],
            [34.898552, 58.224394],
            [34.821427, 58.231177],
            [34.793484, 58.245972],
            [34.749008, 58.223637],
            [34.689496, 58.200132],
            [34.724218, 58.202071],
            [34.716572, 58.185445],
            [34.736603, 58.172142],
            [34.711954, 58.152155],
            [34.731345, 58.142323],
            [34.721495, 58.090302],
            [34.671645, 58.07347],
            [34.657612, 58.083881],
            [34.645992, 58.078059],
            [34.615104, 58.094454],
            [34.617502, 58.10639],
            [34.59814, 58.105445],
            [34.596931, 58.118418],
            [34.560291, 58.115115],
            [34.551974, 58.104723],
            [34.527531, 58.117053],
            [34.504716, 58.114822],
            [34.484339, 58.127741],
            [34.498089, 58.139293],
            [34.465114, 58.155906],
            [34.425484, 58.149261],
            [34.370037, 58.161658],
            [34.379375, 58.171867],
            [34.357481, 58.176971],
            [34.349473, 58.164745],
            [34.27885, 58.177382],
            [34.276928, 58.187918],
            [34.223395, 58.187271],
            [34.190048, 58.200448],
            [34.204372, 58.205885],
            [34.170765, 58.212845],
            [34.149264, 58.200866],
            [34.133311, 58.214555],
            [34.110204, 58.203651],
            [34.063235, 58.191565],
            [34.047469, 58.195008],
            [34.026013, 58.18429],
            [34.018985, 58.205009],
            [33.998275, 58.213395],
            [33.968536, 58.195951],
            [33.95975, 58.180582],
            [33.928132, 58.163292],
            [33.802965, 58.137807],
            [33.780013, 58.169795],
            [33.74091, 58.16248],
            [33.705874, 58.170305],
            [33.696798, 58.152536],
            [33.665874, 58.148524],
            [33.669436, 58.140084],
            [33.637842, 58.128632],
            [33.635316, 58.107335],
            [33.617137, 58.122078],
            [33.585955, 58.120475],
            [33.575189, 58.108559],
            [33.602981, 58.100814],
            [33.551355, 58.067343],
            [33.529306, 58.061171],
            [33.53089, 58.051676],
            [33.554386, 58.042703],
            [33.544086, 58.034797],
            [33.569504, 58.023685],
            [33.62454, 58.038732],
            [33.625161, 58.005129],
            [33.64015, 57.997918],
            [33.689505, 58.002835],
            [33.692728, 57.990429],
            [33.677965, 57.981365],
            [33.69115, 57.968512],
            [33.668348, 57.961045],
            [33.684006, 57.905601],
            [33.647059, 57.873226],
            [33.605802, 57.869105],
            [33.597558, 57.874356],
            [33.547352, 57.851164],
            [33.575312, 57.843942],
            [33.567594, 57.835301],
            [33.544991, 57.836258],
            [33.564736, 57.795364],
            [33.549292, 57.794531],
            [33.551329, 57.767487],
            [33.567818, 57.756921],
            [33.566229, 57.74634],
            [33.586473, 57.739469],
            [33.593119, 57.727617],
            [33.569241, 57.713418],
            [33.535533, 57.710684],
            [33.509664, 57.728809],
            [33.507938, 57.717502],
            [33.48021, 57.714533],
            [33.492127, 57.699274],
            [33.476542, 57.679353],
            [33.45587, 57.692623],
            [33.426627, 57.700311],
            [33.306631, 57.648529],
            [33.30101, 57.629189],
            [33.32445, 57.614812],
            [33.318586, 57.583298],
            [33.294918, 57.582941],
            [33.304749, 57.565236],
            [33.293068, 57.54945],
            [33.268076, 57.553838],
            [33.253062, 57.544852],
            [33.233437, 57.545719],
            [33.232229, 57.477529],
            [33.266631, 57.463398],
            [33.264033, 57.42192],
            [33.240044, 57.411426],
            [33.213418, 57.411762],
            [33.196153, 57.423895],
            [33.160375, 57.430798],
            [33.185483, 57.444382],
            [33.184909, 57.453961],
            [33.154916, 57.455583],
            [33.127341, 57.465221],
            [33.07835, 57.466113],
            [33.073025, 57.442789],
            [33.050486, 57.429899],
            [33.018319, 57.435847],
            [33.004129, 57.44903],
            [32.962562, 57.446606],
            [32.937279, 57.465572],
            [32.915921, 57.445144],
            [32.871533, 57.451668],
            [32.859809, 57.460455],
            [32.826286, 57.455627],
            [32.78858, 57.467192],
            [32.781273, 57.449085],
            [32.76576, 57.440478],
            [32.749844, 57.443715],
            [32.706919, 57.434025],
            [32.706845, 57.406561],
            [32.681974, 57.410075],
            [32.662259, 57.390892],
            [32.670973, 57.375154],
            [32.626831, 57.34468],
            [32.631678, 57.334859],
            [32.593406, 57.328621],
            [32.582649, 57.320788],
            [32.545901, 57.34028],
            [32.547621, 57.324454],
            [32.477148, 57.285729],
            [32.436438, 57.276537],
            [32.434068, 57.269259],
            [32.391948, 57.260135],
            [32.384981, 57.230085],
            [32.327271, 57.225718],
            [32.292204, 57.240814],
            [32.276174, 57.259781],
            [32.236373, 57.255114],
            [32.231125, 57.229682],
            [32.24883, 57.225543],
            [32.250349, 57.202555],
            [32.210399, 57.186733],
            [32.191207, 57.19078],
            [32.181588, 57.178602],
            [32.155924, 57.184418],
            [32.106282, 57.185621],
            [32.083323, 57.176584],
            [32.08108, 57.159026],
            [32.020452, 57.149556],
            [32.015875, 57.128794],
            [32.001669, 57.113838],
            [31.967902, 57.114984],
            [31.969155, 57.109182],
            [31.922915, 57.095235],
            [31.928792, 57.08353],
            [31.913784, 57.07957],
            [31.882155, 57.094541],
            [31.896333, 57.10219],
            [31.898327, 57.125242],
            [31.880087, 57.130835],
            [31.873182, 57.12099],
            [31.818398, 57.104165],
            [31.779036, 57.09861],
            [31.755728, 57.114047],
            [31.743553, 57.092969],
            [31.725252, 57.092029],
            [31.711352, 57.073049],
            [31.68957, 57.071595],
            [31.66991, 57.056472],
            [31.671558, 57.043636],
            [31.647391, 57.026185],
            [31.65357, 57.013395],
            [31.679213, 57.012223],
            [31.702837, 56.99325],
            [31.646749, 57.003313],
            [31.615116, 56.984392],
            [31.613833, 56.990603],
            [31.563951, 56.977634],
            [31.531003, 56.946578],
            [31.441735, 56.954436],
            [31.434381, 56.924694],
            [31.396194, 56.909396],
            [31.368725, 56.927522],
            [31.343863, 56.928755],
            [31.334984, 56.939236],
            [31.308221, 56.937681],
            [31.298762, 56.955736],
            [31.275091, 56.948262],
            [31.241306, 56.951509],
            [31.225126, 56.936859],
            [31.20466, 56.942175],
            [31.152641, 56.9384],
            [31.100948, 56.930977],
            [31.097912, 56.938615],
            [31.069698, 56.943224],
            [31.069051, 56.956625],
            [31.054287, 56.954979],
            [31.030901, 56.98758],
            [31.004929, 56.986641],
            [31.006758, 56.977791],
            [30.978757, 56.969897],
            [30.940913, 56.972944],
            [30.906157, 56.960783],
            [30.871113, 56.941201],
            [30.837601, 56.932488],
            [30.843536, 56.957],
            [30.815769, 56.960159],
            [30.804566, 56.952307],
            [30.765501, 56.9457],
            [30.72921, 56.974329],
            [30.686505, 56.984573],
            [30.700292, 56.996159],
            [30.675652, 57.004156],
            [30.688417, 57.041401],
            [30.687549, 57.149025],
            [30.62847, 57.176504],
            [30.745663, 57.263208],
            [30.541043, 57.30409],
            [30.464248, 57.350097],
            [30.49709, 57.36671],
            [30.49393, 57.374274],
            [30.53676, 57.385973],
            [30.522653, 57.395435],
            [30.49862, 57.391413],
            [30.478129, 57.403573],
            [30.451702, 57.398402],
            [30.453331, 57.385572],
            [30.476886, 57.37255],
            [30.457832, 57.36422],
            [30.427384, 57.367475],
            [30.413403, 57.396966],
            [30.435616, 57.40477],
            [30.423359, 57.424207],
            [30.469318, 57.449581],
            [30.463667, 57.46538],
            [30.486072, 57.483142],
            [30.505413, 57.486206],
            [30.515634, 57.501436],
            [30.468931, 57.509617],
            [30.463377, 57.521873],
            [30.499187, 57.53006],
            [30.504069, 57.544614],
            [30.542752, 57.546626],
            [30.563625, 57.565202],
            [30.680627, 57.644054],
            [30.518809, 57.642471],
            [30.495828, 57.662962],
            [30.45226, 57.687999],
            [30.494388, 57.720986],
            [30.475142, 57.732284],
            [30.503292, 57.743775],
            [30.496683, 57.772213],
            [30.448329, 57.778244],
            [30.434637, 57.773648],
            [30.41721, 57.781691],
            [30.435114, 57.802182],
            [30.448232, 57.798639],
            [30.510565, 57.804912],
            [30.539483, 57.840542],
            [30.530765, 57.857],
            [30.544553, 57.86399],
            [30.518987, 57.884002],
            [30.472931, 57.885868],
            [30.466133, 57.879165],
            [30.42113, 57.879069],
            [30.394378, 57.890642],
            [30.386372, 57.90358],
            [30.342518, 57.902431],
            [30.336198, 57.915453],
            [30.294738, 57.910281],
            [30.245907, 57.890843],
            [30.198798, 57.89496],
            [30.153986, 57.907406],
            [30.168443, 57.926126],
            [30.116067, 57.940727],
            [30.089066, 57.938907],
            [30.090884, 57.949966],
            [30.071759, 57.958173],
            [29.991783, 57.942018],
            [29.979335, 57.955518],
            [29.958462, 57.955135],
            [29.942472, 57.945464],
            [29.901395, 57.941441],
            [29.910108, 57.953985],
            [29.884255, 57.959538],
            [29.889138, 57.968395],
            [29.876784, 57.997024],
            [29.903595, 57.990322],
            [29.952914, 58.009644],
            [29.947063, 58.023788],
            [29.987182, 58.030395],
            [29.993884, 58.042747],
            [29.980574, 58.049162],
            [29.937487, 58.038916],
            [29.88875, 58.04533],
            [29.889036, 58.062518],
            [29.862705, 58.069986],
            [29.833022, 58.067831],
            [29.789646, 58.091002],
            [29.7569, 58.085352],
            [29.744548, 58.094448],
            [29.711514, 58.096171],
            [29.700695, 58.081329],
            [29.673886, 58.072999],
            [29.650905, 58.082908],
            [29.628881, 58.100909],
            [29.647839, 58.108761],
            [29.646594, 58.124608],
            [29.668328, 58.134087],
            [29.657124, 58.162669],
            [29.6644, 58.176265],
            [29.689452, 58.188816],
            [29.679048, 58.210688],
            [29.637109, 58.214326],
            [29.638353, 58.224379],
            [29.658689, 58.233783],
            [29.637299, 58.235391],
            [29.617957, 58.252625],
            [29.633659, 58.256647],
            [29.682779, 58.252818],
            [29.700206, 58.256936],
            [29.766656, 58.252532],
            [29.822478, 58.258278],
            [29.815871, 58.272353],
            [29.862787, 58.289446],
            [29.845934, 58.304047],
            [29.854264, 58.310941],
            [29.892181, 58.309506],
            [29.908648, 58.348333],
            [29.933063, 58.3626],
            [29.904817, 58.363749],
            [29.89256, 58.374855],
            [29.913505, 58.39051],
            [29.894187, 58.398697],
            [29.875322, 58.422347],
            [29.91793, 58.437381],
            [29.894085, 58.445747],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5471",
      properties: {
        name: "Новосибирская область",
        density: 0,
        path: "/world/Russia/Новосибирская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [76.095015, 57.235533],
            [76.386868, 57.225836],
            [76.697895, 57.215498],
            [76.916861, 57.160251],
            [76.945459, 57.169691],
            [77.25041, 57.168751],
            [77.630444, 57.167579],
            [78.063128, 57.166244],
            [78.463438, 57.16501],
            [78.440323, 57.113345],
            [78.727446, 57.070035],
            [78.999552, 57.028944],
            [79.198214, 57.001034],
            [79.588576, 56.92316],
            [79.75726, 56.798643],
            [79.95075, 56.655303],
            [80.263517, 56.422445],
            [80.543437, 56.456212],
            [81.005222, 56.511755],
            [81.220274, 56.537593],
            [81.475127, 56.331022],
            [81.573403, 56.241888],
            [81.991904, 56.333547],
            [82.159901, 56.33486],
            [82.19514, 56.341513],
            [82.225696, 56.341989],
            [82.297622, 56.357874],
            [82.340366, 56.357303],
            [82.351524, 56.365386],
            [82.454521, 56.397035],
            [82.495033, 56.39903],
            [82.525417, 56.407769],
            [82.557689, 56.397795],
            [82.603523, 56.410713],
            [82.63116, 56.410238],
            [82.641116, 56.417645],
            [82.689596, 56.425517],
            [82.746517, 56.4053],
            [82.766773, 56.406629],
            [82.809345, 56.420209],
            [82.825137, 56.436062],
            [82.797157, 56.461108],
            [82.805911, 56.470402],
            [82.798015, 56.486802],
            [82.807971, 56.516173],
            [82.867538, 56.533216],
            [82.899124, 56.530281],
            [82.936202, 56.533405],
            [82.986327, 56.516457],
            [83.015853, 56.524032],
            [83.028213, 56.516457],
            [83.049671, 56.520813],
            [83.059284, 56.536245],
            [83.091384, 56.549778],
            [83.109831, 56.549519],
            [83.288813, 56.443041],
            [83.26076, 56.407621],
            [83.143915, 56.37556],
            [83.144675, 56.360275],
            [83.157761, 56.362743],
            [83.192988, 56.327988],
            [83.136898, 56.296951],
            [83.148702, 56.288899],
            [83.06484, 56.230477],
            [83.088379, 56.216049],
            [83.120501, 56.20929],
            [83.159234, 56.227521],
            [83.195788, 56.217952],
            [83.212234, 56.208177],
            [83.217942, 56.187673],
            [83.207977, 56.177363],
            [83.177249, 56.184911],
            [83.160426, 56.17268],
            [83.183429, 56.166086],
            [83.166959, 56.148201],
            [83.141896, 56.138733],
            [83.135373, 56.119503],
            [83.17846, 56.113378],
            [83.220981, 56.085828],
            [83.274158, 56.113939],
            [83.302317, 56.095736],
            [83.233748, 56.056269],
            [83.245368, 56.024073],
            [83.229571, 56.012005],
            [83.306484, 55.994485],
            [83.340379, 55.979045],
            [83.31371, 55.961171],
            [83.377024, 55.917896],
            [83.392628, 55.911335],
            [83.400827, 55.895182],
            [83.375685, 55.896018],
            [83.389744, 55.864454],
            [83.382134, 55.85268],
            [83.352127, 55.848539],
            [83.316456, 55.855875],
            [83.293241, 55.827598],
            [83.289214, 55.81163],
            [83.270875, 55.784504],
            [83.218095, 55.755319],
            [83.210779, 55.730319],
            [83.257878, 55.712177],
            [83.315157, 55.703312],
            [83.308655, 55.691513],
            [83.326275, 55.690585],
            [83.33679, 55.710601],
            [83.377923, 55.717162],
            [83.392158, 55.713955],
            [83.415712, 55.726359],
            [83.445489, 55.723099],
            [83.444034, 55.693661],
            [83.422492, 55.676653],
            [83.443539, 55.670225],
            [83.472582, 55.698702],
            [83.501749, 55.706691],
            [83.540484, 55.679731],
            [83.615655, 55.66466],
            [83.643134, 55.720537],
            [83.667647, 55.739603],
            [83.649676, 55.742896],
            [83.668674, 55.750947],
            [83.664797, 55.765441],
            [83.694061, 55.780928],
            [83.732489, 55.784882],
            [83.746832, 55.804119],
            [83.727803, 55.81655],
            [83.738456, 55.835183],
            [83.768354, 55.835543],
            [83.785981, 55.851842],
            [83.775403, 55.857456],
            [83.816779, 55.903748],
            [83.856092, 55.909589],
            [83.851874, 55.93256],
            [83.879665, 55.932936],
            [83.881595, 55.954169],
            [83.919492, 55.973405],
            [83.952621, 56.015014],
            [84.062283, 56.026322],
            [84.074814, 56.017847],
            [84.202577, 56.059193],
            [84.240179, 56.03059],
            [84.250683, 56.034349],
            [84.294221, 56.001946],
            [84.336326, 55.981167],
            [84.385253, 56.001404],
            [84.406505, 56.030504],
            [84.45382, 56.043149],
            [84.483103, 56.016658],
            [84.51577, 56.028118],
            [84.548617, 56.026855],
            [84.569463, 56.004814],
            [84.537699, 55.990263],
            [84.547174, 55.982751],
            [84.529289, 55.975096],
            [84.542031, 55.964568],
            [84.537429, 55.952566],
            [84.489601, 55.958431],
            [84.488248, 55.948302],
            [84.543114, 55.932037],
            [84.539776, 55.921118],
            [84.497994, 55.917215],
            [84.49231, 55.901287],
            [84.455401, 55.894249],
            [84.468757, 55.872659],
            [84.505214, 55.883849],
            [84.524706, 55.877622],
            [84.522902, 55.855672],
            [84.555749, 55.844347],
            [84.573346, 55.826457],
            [84.557464, 55.823547],
            [84.549343, 55.802138],
            [84.60457, 55.798618],
            [84.60818, 55.766539],
            [84.578581, 55.761936],
            [84.526062, 55.760447],
            [84.539147, 55.718644],
            [84.55909, 55.72099],
            [84.564776, 55.700506],
            [84.592118, 55.705018],
            [84.603038, 55.686722],
            [84.620364, 55.696829],
            [84.630152, 55.687777],
            [84.683261, 55.681579],
            [84.709612, 55.668201],
            [84.706544, 55.651123],
            [84.68272, 55.651484],
            [84.692196, 55.641829],
            [84.740925, 55.641355],
            [84.753108, 55.616156],
            [84.750582, 55.598965],
            [84.766645, 55.577286],
            [84.784063, 55.525339],
            [84.731091, 55.523932],
            [84.723331, 55.507057],
            [84.749862, 55.483076],
            [84.755728, 55.468435],
            [84.723873, 55.443506],
            [84.621925, 55.403696],
            [84.652224, 55.380406],
            [84.691568, 55.372194],
            [84.755007, 55.37788],
            [84.818897, 55.361344],
            [84.856888, 55.373887],
            [84.899302, 55.343702],
            [84.916447, 55.321729],
            [84.909319, 55.294612],
            [84.914373, 55.27573],
            [84.864795, 55.262629],
            [84.86059, 55.254298],
            [84.828465, 55.241168],
            [84.818268, 55.227091],
            [84.900351, 55.185079],
            [84.915457, 55.183957],
            [84.917623, 55.163789],
            [84.927188, 55.163608],
            [84.91609, 55.085529],
            [84.905081, 55.055163],
            [84.8781, 55.02024],
            [84.836829, 55.00887],
            [84.841651, 54.992675],
            [84.857075, 54.989243],
            [84.887305, 54.969639],
            [84.887537, 54.952149],
            [84.910948, 54.953441],
            [84.90761, 54.945771],
            [84.97177, 54.944508],
            [85.002633, 54.907104],
            [85.03873, 54.873738],
            [85.078977, 54.855668],
            [85.050191, 54.843644],
            [85.075368, 54.830266],
            [85.088092, 54.816346],
            [85.002817, 54.799558],
            [85.018698, 54.7791],
            [84.964464, 54.780882],
            [84.963742, 54.770775],
            [84.931075, 54.76218],
            [84.902018, 54.745192],
            [84.894531, 54.72854],
            [84.945965, 54.709029],
            [84.980076, 54.704878],
            [84.978903, 54.693395],
            [85.004037, 54.680076],
            [84.986066, 54.667199],
            [85.010792, 54.637058],
            [84.931938, 54.60875],
            [85.116875, 54.437789],
            [84.991215, 54.393785],
            [84.843176, 54.339579],
            [84.825955, 54.326567],
            [84.807651, 54.334612],
            [84.811969, 54.351456],
            [84.766618, 54.336555],
            [84.711117, 54.308049],
            [84.692329, 54.28926],
            [84.670733, 54.291852],
            [84.633589, 54.26896],
            [84.596876, 54.254923],
            [84.577224, 54.23743],
            [84.577548, 54.207844],
            [84.550122, 54.162061],
            [84.458232, 54.14608],
            [84.437716, 54.140249],
            [84.423031, 54.146728],
            [84.430481, 54.161629],
            [84.398087, 54.177609],
            [84.370877, 54.172642],
            [84.353168, 54.158389],
            [84.300042, 54.16206],
            [84.27931, 54.156229],
            [84.268512, 54.163788],
            [84.276503, 54.178689],
            [84.252747, 54.179552],
            [84.221649, 54.191214],
            [84.189687, 54.179336],
            [84.186016, 54.171993],
            [84.146928, 54.166594],
            [84.117126, 54.154285],
            [84.051042, 54.134632],
            [83.997701, 54.122538],
            [83.955589, 54.106126],
            [83.913477, 54.099215],
            [83.883243, 54.08237],
            [83.861432, 54.078915],
            [83.857328, 54.087985],
            [83.884539, 54.105261],
            [83.883979, 54.121393],
            [83.83726, 54.118718],
            [83.829092, 54.12782],
            [83.786043, 54.112562],
            [83.729611, 54.101301],
            [83.663542, 54.11118],
            [83.55414, 54.109229],
            [83.532383, 54.093201],
            [83.533313, 54.077922],
            [83.490511, 54.077354],
            [83.475946, 54.063209],
            [83.488263, 53.978316],
            [83.442258, 53.973742],
            [83.451631, 53.94698],
            [83.349197, 53.934281],
            [83.306101, 54.064866],
            [83.301232, 54.084927],
            [83.290761, 54.085822],
            [83.289034, 54.069409],
            [83.274997, 54.051269],
            [83.220035, 54.049973],
            [83.198057, 54.065828],
            [83.099963, 54.01218],
            [83.084296, 54.00599],
            [83.102283, 53.998919],
            [82.71492, 53.776833],
            [82.759724, 53.749143],
            [82.620109, 53.670102],
            [82.666972, 53.642676],
            [82.633391, 53.621512],
            [82.502413, 53.547223],
            [82.583722, 53.512562],
            [82.570873, 53.499281],
            [82.530488, 53.50943],
            [82.505437, 53.498416],
            [82.48125, 53.496473],
            [82.451448, 53.502519],
            [82.431579, 53.513533],
            [82.409552, 53.509646],
            [82.33321, 53.528649],
            [82.322196, 53.562554],
            [82.291961, 53.559099],
            [82.291529, 53.57292],
            [82.25784, 53.586309],
            [82.218319, 53.585445],
            [82.200395, 53.591924],
            [82.195103, 53.608336],
            [82.1504, 53.605745],
            [82.149536, 53.617838],
            [82.184953, 53.63857],
            [82.185816, 53.663837],
            [82.194454, 53.677011],
            [82.152343, 53.659518],
            [82.137226, 53.647856],
            [82.113686, 53.657358],
            [82.061856, 53.662757],
            [82.057537, 53.681761],
            [81.961435, 53.708755],
            [81.958843, 53.717177],
            [81.85108, 53.712425],
            [81.819644, 53.685664],
            [81.81955, 53.700332],
            [81.78802, 53.723007],
            [81.805728, 53.729702],
            [81.767827, 53.756912],
            [81.746015, 53.756264],
            [81.716213, 53.771597],
            [81.715781, 53.779803],
            [81.741696, 53.798159],
            [81.746662, 53.813061],
            [81.7209, 53.841738],
            [81.773656, 53.8569],
            [81.835744, 53.869857],
            [81.828509, 53.9178],
            [81.845785, 53.923415],
            [81.831192, 53.964117],
            [81.774087, 53.957967],
            [81.750331, 53.946522],
            [81.696665, 53.979347],
            [81.663408, 53.979562],
            [81.622376, 53.957103],
            [81.570115, 53.91132],
            [81.540528, 53.904625],
            [81.542904, 53.933131],
            [81.564283, 53.969196],
            [81.570545, 54.014547],
            [81.590629, 54.015195],
            [81.592356, 54.024697],
            [81.542902, 54.023185],
            [81.51418, 54.010443],
            [81.480921, 54.057737],
            [81.475738, 54.077605],
            [81.393242, 54.066375],
            [81.364736, 54.059896],
            [81.343787, 54.081924],
            [81.329318, 54.079116],
            [81.322515, 54.090994],
            [81.276516, 54.080844],
            [81.253624, 54.089698],
            [81.238939, 54.086026],
            [81.215616, 54.100495],
            [81.208489, 54.112589],
            [81.149316, 54.10503],
            [81.126424, 54.114532],
            [81.120593, 54.136991],
            [81.088846, 54.194219],
            [81.015419, 54.254471],
            [80.97795, 54.308676],
            [80.973198, 54.33675],
            [80.951709, 54.375622],
            [80.813605, 54.343228],
            [80.851723, 54.261812],
            [80.863601, 54.244752],
            [80.817062, 54.231578],
            [80.798921, 54.256197],
            [80.702496, 54.222723],
            [80.637493, 54.181691],
            [80.620216, 54.19616],
            [80.530918, 54.168949],
            [80.570223, 54.126622],
            [80.518178, 54.103298],
            [80.478657, 54.100275],
            [80.465052, 54.115392],
            [80.354914, 54.081918],
            [80.296281, 54.051683],
            [80.219425, 54.030943],
            [80.193917, 54.078245],
            [80.050953, 54.044987],
            [80.069094, 54.010866],
            [80.090906, 53.990135],
            [80.02072, 53.964003],
            [80.023096, 53.960332],
            [79.877019, 53.91411],
            [79.878621, 53.897488],
            [79.849034, 53.890361],
            [79.873438, 53.859912],
            [79.893523, 53.844579],
            [79.776474, 53.811969],
            [79.758333, 53.835076],
            [79.62066, 53.791992],
            [79.641069, 53.773851],
            [79.638802, 53.742106],
            [79.421441, 53.679585],
            [79.386509, 53.71565],
            [79.299748, 53.709278],
            [79.257392, 53.677587],
            [79.123581, 53.674832],
            [79.089513, 53.70215],
            [79.040491, 53.649997],
            [78.941852, 53.675047],
            [78.797754, 53.658201],
            [78.707801, 53.631578],
            [78.689492, 53.652117],
            [78.615323, 53.636928],
            [78.65112, 53.582292],
            [78.645073, 53.57333],
            [78.645884, 53.512538],
            [78.579701, 53.51212],
            [78.578829, 53.496233],
            [78.525811, 53.510485],
            [78.524839, 53.53003],
            [78.502811, 53.52679],
            [78.506375, 53.514157],
            [78.494119, 53.508542],
            [78.422961, 53.513292],
            [78.42172, 53.48619],
            [78.367676, 53.485649],
            [78.362871, 53.474204],
            [78.333933, 53.478955],
            [78.286692, 53.495475],
            [78.263908, 53.488672],
            [78.252246, 53.499146],
            [78.260237, 53.508432],
            [78.235887, 53.53575],
            [78.23162, 53.617058],
            [78.202142, 53.622996],
            [78.156898, 53.6217],
            [78.157169, 53.586068],
            [78.103719, 53.585743],
            [78.107891, 53.576234],
            [77.992825, 53.576564],
            [77.958488, 53.581531],
            [77.915788, 53.571123],
            [77.912165, 53.534344],
            [77.931764, 53.503355],
            [77.962778, 53.487273],
            [77.978843, 53.491694],
            [78.013775, 53.480356],
            [78.072408, 53.47323],
            [78.069061, 53.439325],
            [77.922449, 53.448716],
            [77.916811, 53.419996],
            [77.937867, 53.414489],
            [77.937435, 53.3984],
            [77.967922, 53.388269],
            [77.9559, 53.383931],
            [77.941677, 53.356559],
            [77.888953, 53.367194],
            [77.907228, 53.290941],
            [77.621983, 53.4453],
            [77.3997, 53.5652],
            [77.2776, 53.6252],
            [77.0532, 53.7362],
            [76.8189, 53.856],
            [76.6849, 53.9253],
            [76.54, 54.0029],
            [76.5076, 54.0346],
            [76.5072, 54.0652],
            [76.4342, 54.1209],
            [76.4435, 54.1667],
            [76.4543, 54.1691],
            [76.5806, 54.1479],
            [76.6163, 54.1624],
            [76.6531, 54.1243],
            [76.6984, 54.1481],
            [76.7573, 54.1651],
            [76.7598, 54.1866],
            [76.7831, 54.196],
            [76.8214, 54.229],
            [76.8321, 54.2296],
            [76.8382, 54.2627],
            [76.8341, 54.2754],
            [76.8528, 54.2806],
            [76.8768, 54.3017],
            [76.8682, 54.3494],
            [76.8611, 54.3623],
            [76.8152, 54.3682],
            [76.7871, 54.3606],
            [76.774, 54.3778],
            [76.7958, 54.3838],
            [76.8796, 54.3987],
            [76.886, 54.404],
            [76.9591, 54.4173],
            [76.9366, 54.4301],
            [76.9431, 54.4372],
            [76.9254, 54.4555],
            [76.8617, 54.4457],
            [76.8114, 54.4222],
            [76.7388, 54.4179],
            [76.7349, 54.3991],
            [76.6923, 54.3745],
            [76.6741, 54.3424],
            [76.6078, 54.3379],
            [76.6049, 54.3433],
            [76.5382, 54.3264],
            [76.5026, 54.3215],
            [76.4658, 54.3236],
            [76.2625, 54.3592],
            [76.2353, 54.3234],
            [76.196, 54.2933],
            [76.2278, 54.2787],
            [76.2166, 54.2625],
            [76.1486, 54.2452],
            [75.9972, 54.2034],
            [75.7386, 54.1352],
            [75.688494, 54.123289],
            [75.688938, 54.215193],
            [75.687219, 54.369462],
            [75.690512, 54.423607],
            [75.691058, 54.546033],
            [75.804033, 54.540152],
            [75.827073, 54.599356],
            [75.851846, 54.607591],
            [75.837709, 54.628522],
            [75.824601, 54.666402],
            [75.802915, 54.671206],
            [75.79866, 54.698176],
            [75.78466, 54.694127],
            [75.736279, 54.700783],
            [75.736623, 54.674294],
            [75.684226, 54.674746],
            [75.685977, 54.704694],
            [75.638859, 54.81005],
            [75.611194, 54.805332],
            [75.603795, 54.820987],
            [75.545463, 54.816804],
            [75.549967, 54.799541],
            [75.528951, 54.803294],
            [75.496888, 54.866021],
            [75.490561, 54.884357],
            [75.513936, 54.901084],
            [75.514151, 54.913308],
            [75.442523, 54.915559],
            [75.447134, 54.903121],
            [75.344946, 54.902906],
            [75.332079, 54.911162],
            [75.38183, 54.990724],
            [75.409602, 54.990939],
            [75.36846, 55.067782],
            [75.314383, 55.066962],
            [75.312022, 55.127759],
            [75.275565, 55.121968],
            [75.261625, 55.128938],
            [75.251974, 55.150169],
            [75.293471, 55.171185],
            [75.267736, 55.195633],
            [75.281246, 55.225013],
            [75.275133, 55.242491],
            [75.227845, 55.2827],
            [75.202003, 55.285166],
            [75.199536, 55.321087],
            [75.170477, 55.326234],
            [75.177661, 55.352461],
            [75.091515, 55.376632],
            [75.198627, 55.425701],
            [75.162293, 55.44713],
            [75.236485, 55.475592],
            [75.321648, 55.497983],
            [75.312433, 55.537969],
            [75.284099, 55.557318],
            [75.220802, 55.540616],
            [75.195778, 55.552481],
            [75.185376, 55.565992],
            [75.140769, 55.583898],
            [75.170149, 55.597945],
            [75.195347, 55.619069],
            [75.256359, 55.625503],
            [75.245422, 55.638263],
            [75.115461, 55.685977],
            [75.112887, 55.69938],
            [75.129507, 55.709889],
            [75.08801, 55.72683],
            [75.104415, 55.756425],
            [75.230193, 55.730906],
            [75.369374, 55.740665],
            [75.367335, 55.805322],
            [75.346748, 55.800068],
            [75.303856, 55.832021],
            [75.265147, 55.833414],
            [75.262427, 55.844122],
            [75.323584, 55.897643],
            [75.356932, 55.913084],
            [75.418372, 55.934959],
            [75.531388, 56.008839],
            [75.528492, 56.031678],
            [75.584572, 56.031142],
            [75.594437, 56.03897],
            [75.653412, 56.036933],
            [75.67786, 56.05559],
            [75.681291, 56.072961],
            [75.735761, 56.099768],
            [75.731579, 56.112635],
            [75.893814, 56.115639],
            [75.959545, 56.115103],
            [75.979703, 56.125397],
            [76.014015, 56.163677],
            [76.04286, 56.150596],
            [76.072133, 56.147057],
            [76.120921, 56.154885],
            [76.138614, 56.121431],
            [76.162633, 56.131939],
            [76.226433, 56.145236],
            [76.306639, 56.157246],
            [76.302885, 56.18105],
            [76.231793, 56.217185],
            [76.170564, 56.281305],
            [76.126708, 56.268224],
            [76.10226, 56.290312],
            [76.078777, 56.292992],
            [76.0984, 56.299962],
            [76.081243, 56.325375],
            [76.083815, 56.386065],
            [76.078453, 56.40247],
            [76.055935, 56.416088],
            [76.092499, 56.42531],
            [76.125137, 56.41769],
            [76.108368, 56.445576],
            [76.085207, 56.443216],
            [76.075342, 56.45544],
            [76.047141, 56.464125],
            [76.010245, 56.460179],
            [75.982405, 56.464787],
            [75.950242, 56.437992],
            [75.927533, 56.445546],
            [75.901785, 56.414096],
            [75.882021, 56.419559],
            [75.852709, 56.402028],
            [75.83133, 56.417848],
            [75.84328, 56.437203],
            [75.834929, 56.44683],
            [75.849032, 56.460447],
            [75.835948, 56.479004],
            [75.855155, 56.496727],
            [75.962049, 56.507491],
            [76.016979, 56.569103],
            [76.038233, 56.61783],
            [76.104664, 56.610673],
            [76.166647, 56.618004],
            [76.152728, 56.638789],
            [76.178616, 56.653449],
            [76.289127, 56.696968],
            [76.261009, 56.818521],
            [76.240549, 56.826138],
            [76.250431, 56.835872],
            [76.260543, 56.884586],
            [76.195032, 56.943321],
            [76.182226, 56.975889],
            [76.140255, 57.112964],
            [76.095015, 57.235533],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5529",
      properties: {
        name: "Омская область",
        density: 0,
        path: "/world/Russia/Омская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [75.108237, 58.576245],
            [75.265048, 58.50625],
            [75.376835, 58.454101],
            [75.254965, 58.417351],
            [75.056664, 58.339153],
            [75.120436, 58.293624],
            [75.242489, 58.202615],
            [75.071473, 58.116793],
            [75.189059, 58.069921],
            [75.302963, 58.026804],
            [75.384577, 58.000182],
            [75.498835, 57.960411],
            [75.565762, 57.934585],
            [75.576082, 57.878807],
            [75.550039, 57.625218],
            [75.704124, 57.624997],
            [75.780091, 57.495967],
            [75.848267, 57.3858],
            [75.965411, 57.393694],
            [76.008875, 57.342677],
            [76.095015, 57.235533],
            [76.140255, 57.112964],
            [76.182226, 56.975889],
            [76.195032, 56.943321],
            [76.260543, 56.884586],
            [76.250431, 56.835872],
            [76.240549, 56.826138],
            [76.261009, 56.818521],
            [76.289127, 56.696968],
            [76.178616, 56.653449],
            [76.152728, 56.638789],
            [76.166647, 56.618004],
            [76.104664, 56.610673],
            [76.038233, 56.61783],
            [76.016979, 56.569103],
            [75.962049, 56.507491],
            [75.855155, 56.496727],
            [75.835948, 56.479004],
            [75.849032, 56.460447],
            [75.834929, 56.44683],
            [75.84328, 56.437203],
            [75.83133, 56.417848],
            [75.852709, 56.402028],
            [75.882021, 56.419559],
            [75.901785, 56.414096],
            [75.927533, 56.445546],
            [75.950242, 56.437992],
            [75.982405, 56.464787],
            [76.010245, 56.460179],
            [76.047141, 56.464125],
            [76.075342, 56.45544],
            [76.085207, 56.443216],
            [76.108368, 56.445576],
            [76.125137, 56.41769],
            [76.092499, 56.42531],
            [76.055935, 56.416088],
            [76.078453, 56.40247],
            [76.083815, 56.386065],
            [76.081243, 56.325375],
            [76.0984, 56.299962],
            [76.078777, 56.292992],
            [76.10226, 56.290312],
            [76.126708, 56.268224],
            [76.170564, 56.281305],
            [76.231793, 56.217185],
            [76.302885, 56.18105],
            [76.306639, 56.157246],
            [76.226433, 56.145236],
            [76.162633, 56.131939],
            [76.138614, 56.121431],
            [76.120921, 56.154885],
            [76.072133, 56.147057],
            [76.04286, 56.150596],
            [76.014015, 56.163677],
            [75.979703, 56.125397],
            [75.959545, 56.115103],
            [75.893814, 56.115639],
            [75.731579, 56.112635],
            [75.735761, 56.099768],
            [75.681291, 56.072961],
            [75.67786, 56.05559],
            [75.653412, 56.036933],
            [75.594437, 56.03897],
            [75.584572, 56.031142],
            [75.528492, 56.031678],
            [75.531388, 56.008839],
            [75.418372, 55.934959],
            [75.356932, 55.913084],
            [75.323584, 55.897643],
            [75.262427, 55.844122],
            [75.265147, 55.833414],
            [75.303856, 55.832021],
            [75.346748, 55.800068],
            [75.367335, 55.805322],
            [75.369374, 55.740665],
            [75.230193, 55.730906],
            [75.104415, 55.756425],
            [75.08801, 55.72683],
            [75.129507, 55.709889],
            [75.112887, 55.69938],
            [75.115461, 55.685977],
            [75.245422, 55.638263],
            [75.256359, 55.625503],
            [75.195347, 55.619069],
            [75.170149, 55.597945],
            [75.140769, 55.583898],
            [75.185376, 55.565992],
            [75.195778, 55.552481],
            [75.220802, 55.540616],
            [75.284099, 55.557318],
            [75.312433, 55.537969],
            [75.321648, 55.497983],
            [75.236485, 55.475592],
            [75.162293, 55.44713],
            [75.198627, 55.425701],
            [75.091515, 55.376632],
            [75.177661, 55.352461],
            [75.170477, 55.326234],
            [75.199536, 55.321087],
            [75.202003, 55.285166],
            [75.227845, 55.2827],
            [75.275133, 55.242491],
            [75.281246, 55.225013],
            [75.267736, 55.195633],
            [75.293471, 55.171185],
            [75.251974, 55.150169],
            [75.261625, 55.128938],
            [75.275565, 55.121968],
            [75.312022, 55.127759],
            [75.314383, 55.066962],
            [75.36846, 55.067782],
            [75.409602, 54.990939],
            [75.38183, 54.990724],
            [75.332079, 54.911162],
            [75.344946, 54.902906],
            [75.447134, 54.903121],
            [75.442523, 54.915559],
            [75.514151, 54.913308],
            [75.513936, 54.901084],
            [75.490561, 54.884357],
            [75.496888, 54.866021],
            [75.528951, 54.803294],
            [75.549967, 54.799541],
            [75.545463, 54.816804],
            [75.603795, 54.820987],
            [75.611194, 54.805332],
            [75.638859, 54.81005],
            [75.685977, 54.704694],
            [75.684226, 54.674746],
            [75.736623, 54.674294],
            [75.736279, 54.700783],
            [75.78466, 54.694127],
            [75.79866, 54.698176],
            [75.802915, 54.671206],
            [75.824601, 54.666402],
            [75.837709, 54.628522],
            [75.851846, 54.607591],
            [75.827073, 54.599356],
            [75.804033, 54.540152],
            [75.691058, 54.546033],
            [75.690512, 54.423607],
            [75.687219, 54.369462],
            [75.688938, 54.215193],
            [75.688494, 54.123289],
            [75.6034, 54.0991],
            [75.5318, 54.1157],
            [75.3784, 54.0695],
            [75.4464, 54.0002],
            [75.4401, 53.9732],
            [75.4157, 53.9706],
            [75.4141, 53.9634],
            [75.3668, 53.9608],
            [75.2858, 53.917],
            [75.2125, 53.874],
            [75.056497, 53.797823],
            [75.0331, 53.804],
            [74.9997, 53.823],
            [74.8858, 53.8231],
            [74.8569, 53.8184],
            [74.7946, 53.8245],
            [74.7751, 53.7834],
            [74.7632, 53.7437],
            [74.6737, 53.7553],
            [74.6426, 53.6724],
            [74.6335, 53.669],
            [74.4653, 53.6888],
            [74.4579, 53.6807],
            [74.4256, 53.606],
            [74.4258, 53.5943],
            [74.4531, 53.582],
            [74.4834, 53.5757],
            [74.4821, 53.5622],
            [74.3945, 53.4603],
            [74.3824, 53.4613],
            [74.3007, 53.4885],
            [74.3062, 53.5155],
            [74.2714, 53.5243],
            [74.2889, 53.5593],
            [74.2375, 53.5672],
            [74.2416, 53.587],
            [74.1704, 53.5989],
            [74.1644, 53.5973],
            [74.1516, 53.5593],
            [74.1411, 53.5558],
            [74.0579, 53.5659],
            [74.0548, 53.5693],
            [74.0716, 53.6262],
            [74.0685, 53.6307],
            [73.9078, 53.6502],
            [73.8928, 53.6443],
            [73.841, 53.6027],
            [73.8204, 53.5827],
            [73.8084, 53.5818],
            [73.7762, 53.5966],
            [73.7321, 53.6077],
            [73.6686, 53.6082],
            [73.6476, 53.5557],
            [73.5993, 53.5562],
            [73.5867, 53.5264],
            [73.5692, 53.5098],
            [73.5339, 53.486],
            [73.44547, 53.43703],
            [73.4209, 53.4433],
            [73.3705, 53.4642],
            [73.3864, 53.4806],
            [73.3986, 53.5176],
            [73.3559, 53.5285],
            [73.3417, 53.5448],
            [73.2488, 53.5649],
            [73.2455, 53.5731],
            [73.2792, 53.6354],
            [73.2592, 53.6432],
            [73.261, 53.6719],
            [73.3273, 53.6801],
            [73.3372, 53.6965],
            [73.3575, 53.7588],
            [73.3427, 53.7881],
            [73.4033, 53.7917],
            [73.4482, 53.8077],
            [73.4301, 53.844],
            [73.4372, 53.8578],
            [73.4609, 53.876],
            [73.685, 53.8571],
            [73.697, 53.8609],
            [73.7435, 53.9827],
            [73.767879, 54.053821],
            [73.700116, 54.048838],
            [73.70366, 54.065955],
            [73.630035, 54.070924],
            [73.657973, 54.046379],
            [73.609624, 54.008405],
            [73.578146, 54.01448],
            [73.518798, 54.00325],
            [73.521991, 53.965353],
            [73.5375, 53.9462],
            [73.5104, 53.9378],
            [73.458631, 53.963691],
            [73.404506, 53.978509],
            [73.4035, 53.9452],
            [73.3, 53.9431],
            [73.2845, 53.95],
            [73.199182, 53.969316],
            [73.066968, 53.985537],
            [73.072208, 54.030043],
            [73.059681, 54.04015],
            [72.978659, 54.049487],
            [72.994581, 54.098412],
            [72.831674, 54.097984],
            [72.825666, 54.120677],
            [72.723744, 54.123116],
            [72.608793, 54.136841],
            [72.612851, 54.121959],
            [72.5602, 54.1164],
            [72.5309, 54.043],
            [72.5648, 54.0392],
            [72.5717, 54.051],
            [72.5964, 54.0461],
            [72.6744, 54.0449],
            [72.7146, 54.007],
            [72.7066, 53.9584],
            [72.6931, 53.9554],
            [72.556, 53.9797],
            [72.49327, 53.903469],
            [72.4305, 53.9141],
            [72.3752, 53.9462],
            [72.4168, 53.9874],
            [72.4331, 54.0447],
            [72.3977, 54.05],
            [72.4068, 54.0746],
            [72.3712, 54.0818],
            [72.3789, 54.1054],
            [72.4327, 54.1011],
            [72.4393, 54.1182],
            [72.5054, 54.1116],
            [72.5175, 54.1406],
            [72.4406, 54.1455],
            [72.3862, 54.1585],
            [72.3092, 54.1892],
            [72.337948, 54.245809],
            [72.2914, 54.2498],
            [72.2995, 54.2904],
            [72.2828, 54.3108],
            [72.20079, 54.353394],
            [72.0126, 54.3693],
            [72.0424, 54.3411],
            [72.042, 54.3249],
            [72.0835, 54.326],
            [72.1046, 54.3101],
            [72.1263, 54.3065],
            [72.1347, 54.2965],
            [72.1315, 54.2759],
            [72.1161, 54.2764],
            [72.1018, 54.2401],
            [72.0975, 54.2148],
            [72.1724, 54.1949],
            [72.2197, 54.1753],
            [72.1832, 54.127],
            [72.1743, 54.1223],
            [72.1008, 54.1459],
            [72.1113, 54.1702],
            [72.0738, 54.1801],
            [72.0576, 54.1932],
            [72.0617, 54.2005],
            [71.9512, 54.2413],
            [71.9348, 54.2264],
            [71.9134, 54.2269],
            [71.8237, 54.2388],
            [71.7555, 54.2522],
            [71.7265, 54.1846],
            [71.7669, 54.1468],
            [71.7227, 54.1038],
            [71.6756, 54.1083],
            [71.6749, 54.0976],
            [71.6199, 54.0972],
            [71.6118, 54.1145],
            [71.4899, 54.0971],
            [71.4904, 54.1566],
            [71.3882, 54.1639],
            [71.3876, 54.179],
            [71.2974, 54.1827],
            [71.2771, 54.1472],
            [71.1764, 54.0917],
            [71.126, 54.1266],
            [71.1353, 54.1598],
            [71.1048, 54.162],
            [71.1105, 54.1834],
            [71.0556, 54.188],
            [71.0563, 54.2024],
            [71.0732, 54.2021],
            [71.0736, 54.2723],
            [70.9999, 54.2725],
            [70.9983, 54.3338],
            [71.112, 54.3326],
            [71.1113, 54.3191],
            [71.1999, 54.3077],
            [71.2071, 54.3273],
            [71.1979, 54.3284],
            [71.1975, 54.3501],
            [71.2267, 54.3494],
            [71.2342, 54.4021],
            [71.2003, 54.4029],
            [71.2049, 54.4585],
            [71.1739, 54.4571],
            [71.1724, 54.5734],
            [71.2113, 54.6067],
            [71.2884, 54.6016],
            [71.2936, 54.6662],
            [71.2409, 54.6656],
            [71.2389, 54.6861],
            [71.211, 54.6866],
            [71.2103, 54.7019],
            [71.0984, 54.6994],
            [71.0992, 54.7136],
            [71.0355, 54.7742],
            [71.0289, 54.7986],
            [71.0126, 54.8131],
            [71.0287, 54.8246],
            [71.0222, 54.8499],
            [70.9946, 54.8584],
            [70.9601, 54.8859],
            [70.9966, 54.9321],
            [71.0092, 54.9651],
            [70.9804, 54.9817],
            [70.9963, 54.9878],
            [70.9957, 55.0067],
            [71.0072, 55.0181],
            [71.0057, 55.0505],
            [70.9916, 55.0509],
            [70.9924, 55.0669],
            [71.0245, 55.0825],
            [70.9248, 55.1256],
            [70.9298, 55.164],
            [70.8819, 55.1801],
            [70.8043, 55.2694],
            [70.8267, 55.2779],
            [70.8161, 55.288],
            [70.6996, 55.287],
            [70.6241, 55.2503],
            [70.4787, 55.2719],
            [70.460501, 55.279672],
            [70.486614, 55.297933],
            [70.498739, 55.330814],
            [70.4565, 55.392328],
            [70.439596, 55.407525],
            [70.536611, 55.478153],
            [70.56696, 55.493869],
            [70.636919, 55.49852],
            [70.673372, 55.490529],
            [70.719824, 55.559855],
            [70.756972, 55.584722],
            [70.753997, 55.609054],
            [70.710092, 55.625407],
            [70.64543, 55.624773],
            [70.563457, 55.650732],
            [70.531725, 55.694373],
            [70.54187, 55.736888],
            [70.587837, 55.764592],
            [70.589364, 55.772024],
            [70.550487, 55.791749],
            [70.561112, 55.80224],
            [70.646012, 55.811069],
            [70.647249, 55.799736],
            [70.698063, 55.800149],
            [70.748688, 55.825476],
            [70.778235, 55.824685],
            [70.828713, 55.834617],
            [70.757141, 55.855256],
            [70.6784, 55.842326],
            [70.602565, 55.837173],
            [70.585787, 55.822901],
            [70.524765, 55.841796],
            [70.47174, 55.851273],
            [70.481471, 55.869793],
            [70.480612, 55.905967],
            [70.582157, 55.905118],
            [70.579544, 55.910326],
            [70.80854, 55.902622],
            [70.867412, 55.962189],
            [70.898741, 56.067523],
            [70.913828, 56.085713],
            [70.901599, 56.084211],
            [70.902366, 56.112283],
            [70.879129, 56.146069],
            [70.861489, 56.140403],
            [70.806176, 56.186314],
            [70.823622, 56.195442],
            [70.814554, 56.218311],
            [70.800242, 56.211516],
            [70.773726, 56.212417],
            [70.732229, 56.253127],
            [70.788846, 56.271192],
            [70.851894, 56.301797],
            [70.948417, 56.304836],
            [70.928243, 56.317054],
            [70.911848, 56.356672],
            [70.927466, 56.379234],
            [70.877066, 56.387356],
            [70.871007, 56.403317],
            [70.841063, 56.409388],
            [70.840635, 56.418571],
            [70.811821, 56.442475],
            [70.7909, 56.416275],
            [70.736817, 56.424495],
            [70.800116, 56.449525],
            [70.894654, 56.461593],
            [70.926303, 56.454477],
            [70.984133, 56.462236],
            [71.012583, 56.488987],
            [71.084598, 56.524166],
            [71.113689, 56.624043],
            [71.012849, 56.645153],
            [71.020382, 56.682954],
            [71.075766, 56.691085],
            [71.120526, 56.652955],
            [71.225917, 56.644557],
            [71.263843, 56.656553],
            [71.295691, 56.638108],
            [71.369887, 56.631768],
            [71.392227, 56.647584],
            [71.381898, 56.678879],
            [71.434151, 56.682177],
            [71.449009, 56.676412],
            [71.553729, 56.67029],
            [71.576622, 56.711275],
            [71.581371, 56.743492],
            [71.565588, 56.752901],
            [71.598779, 56.757744],
            [71.630517, 56.755332],
            [71.677329, 56.765223],
            [71.638385, 56.88099],
            [71.604991, 56.932929],
            [71.516805, 56.975491],
            [71.297296, 57.085244],
            [71.22941, 57.174657],
            [71.215273, 57.171141],
            [71.18601, 57.195199],
            [71.115604, 57.202398],
            [71.105784, 57.217462],
            [71.081857, 57.234356],
            [71.070508, 57.258979],
            [71.08953, 57.257903],
            [71.09501, 57.28738],
            [71.052905, 57.293575],
            [71.065009, 57.350892],
            [71.018686, 57.356162],
            [71.01329, 57.345591],
            [70.974634, 57.351552],
            [70.981098, 57.367342],
            [71.006614, 57.376629],
            [70.983217, 57.387105],
            [70.979721, 57.379774],
            [70.948648, 57.383686],
            [70.92378, 57.354927],
            [70.845196, 57.364726],
            [70.829772, 57.378681],
            [70.81839, 57.366709],
            [70.786795, 57.364979],
            [70.763869, 57.341794],
            [70.776198, 57.326788],
            [70.758672, 57.317596],
            [70.73594, 57.322343],
            [70.733074, 57.307597],
            [70.712722, 57.299281],
            [70.734903, 57.286699],
            [70.780822, 57.275989],
            [70.753752, 57.250143],
            [70.71734, 57.232867],
            [70.733612, 57.212013],
            [70.708643, 57.21229],
            [70.701809, 57.201325],
            [70.639746, 57.20537],
            [70.581932, 57.205669],
            [70.587531, 57.223846],
            [70.629275, 57.234288],
            [70.606542, 57.280058],
            [70.569395, 57.272277],
            [70.530717, 57.275258],
            [70.520669, 57.286626],
            [70.45883, 57.282399],
            [70.372349, 57.329437],
            [70.369213, 57.372048],
            [70.418236, 57.415114],
            [70.57835, 57.458047],
            [70.587554, 57.476986],
            [70.632848, 57.501159],
            [70.685693, 57.515541],
            [70.690201, 57.553787],
            [70.681918, 57.574278],
            [70.657721, 57.584911],
            [70.627927, 57.589325],
            [70.598864, 57.577222],
            [70.557686, 57.718741],
            [70.478006, 57.735192],
            [70.47546, 57.768888],
            [70.392336, 57.782465],
            [70.429808, 57.841476],
            [70.420397, 57.84778],
            [70.425981, 57.870982],
            [70.403375, 57.878436],
            [70.426527, 57.893057],
            [70.405108, 57.90292],
            [70.382948, 57.887385],
            [70.358304, 57.900312],
            [70.379803, 57.915815],
            [70.367825, 57.920026],
            [70.376636, 57.940205],
            [70.440861, 57.943851],
            [70.430413, 57.952164],
            [70.540962, 58.10462],
            [70.669649, 58.277618],
            [70.758948, 58.403319],
            [70.852774, 58.529271],
            [70.887287, 58.531734],
            [70.901177, 58.526307],
            [70.943145, 58.528651],
            [70.939455, 58.514799],
            [70.963236, 58.514521],
            [70.961167, 58.505102],
            [71.003296, 58.502508],
            [71.053455, 58.511108],
            [71.084247, 58.496674],
            [71.079972, 58.480399],
            [71.095853, 58.477055],
            [71.122573, 58.454975],
            [71.099613, 58.450936],
            [71.111075, 58.434179],
            [71.140297, 58.442415],
            [71.165371, 58.441161],
            [71.172763, 58.427704],
            [71.194513, 58.419858],
            [71.220251, 58.4244],
            [71.23364, 58.412253],
            [71.276216, 58.408023],
            [71.293769, 58.389985],
            [71.311412, 58.383055],
            [71.291074, 58.361039],
            [71.293407, 58.351995],
            [71.237838, 58.327859],
            [71.20606, 58.111172],
            [71.233165, 58.09608],
            [71.208449, 58.078466],
            [71.214219, 58.054221],
            [71.271379, 58.053899],
            [71.310618, 58.065751],
            [71.326795, 58.077779],
            [71.338558, 58.07458],
            [71.390028, 58.077982],
            [71.402441, 58.064489],
            [71.466598, 58.064595],
            [71.490403, 58.074241],
            [71.541423, 58.0748],
            [71.990196, 58.115843],
            [72.071223, 58.095156],
            [72.089325, 58.086513],
            [72.105428, 58.052778],
            [72.167156, 58.014698],
            [72.326371, 58.008055],
            [72.746413, 58.00074],
            [72.811536, 57.996748],
            [72.835228, 58.003391],
            [72.867559, 58.001417],
            [72.92548, 58.044752],
            [72.947761, 58.069781],
            [73.128287, 58.155304],
            [73.261116, 58.149947],
            [73.47897, 58.143947],
            [73.791707, 58.139185],
            [74.219631, 58.136298],
            [74.455494, 58.242033],
            [74.563786, 58.274036],
            [74.628585, 58.288288],
            [74.642353, 58.302844],
            [74.645196, 58.321179],
            [74.663241, 58.331007],
            [74.661444, 58.345004],
            [74.688812, 58.359603],
            [74.806145, 58.410053],
            [74.913113, 58.430885],
            [74.914149, 58.459021],
            [74.950558, 58.472194],
            [74.951106, 58.493586],
            [75.108237, 58.576245],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5488",
      properties: {
        name: "Оренбургская область",
        density: 0,
        path: "/world/Russia/Оренбургская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [50.768482, 51.773511],
            [50.909958, 51.860651],
            [50.999658, 51.891218],
            [51.033474, 51.923495],
            [51.088503, 51.932962],
            [51.126882, 51.92612],
            [51.153398, 51.956344],
            [51.14376, 51.988392],
            [51.205405, 51.986511],
            [51.262259, 52.019003],
            [51.298469, 52.031791],
            [51.39142, 52.069258],
            [51.406759, 52.106553],
            [51.43624, 52.150178],
            [51.432304, 52.174414],
            [51.447017, 52.183538],
            [51.462982, 52.226136],
            [51.409321, 52.22836],
            [51.399226, 52.272212],
            [51.399877, 52.322495],
            [51.384492, 52.357747],
            [51.37108, 52.365158],
            [51.461028, 52.365624],
            [51.462051, 52.406721],
            [51.473152, 52.428663],
            [51.548065, 52.428867],
            [51.548183, 52.46205],
            [51.531445, 52.463032],
            [51.532381, 52.475262],
            [51.514243, 52.495419],
            [51.493254, 52.494044],
            [51.490212, 52.512063],
            [51.461006, 52.510296],
            [51.451672, 52.568762],
            [51.477907, 52.570427],
            [51.510147, 52.564558],
            [51.507069, 52.59464],
            [51.475359, 52.604744],
            [51.471709, 52.628534],
            [51.530492, 52.631999],
            [51.523228, 52.676763],
            [51.611405, 52.681831],
            [51.616767, 52.645848],
            [51.720098, 52.651894],
            [51.766511, 52.800333],
            [51.725738, 52.804381],
            [51.715014, 52.876347],
            [51.758924, 52.874694],
            [51.836879, 52.864887],
            [51.848625, 52.901669],
            [51.866216, 52.930061],
            [51.88712, 52.946433],
            [51.912995, 52.949556],
            [51.913636, 52.971276],
            [51.981933, 52.970703],
            [51.9899, 52.966225],
            [52.054539, 52.964347],
            [52.061883, 53.032584],
            [52.045723, 53.032983],
            [52.050012, 53.090567],
            [52.162267, 53.087759],
            [52.1652, 53.120075],
            [52.157784, 53.125495],
            [52.085232, 53.123064],
            [52.066289, 53.129354],
            [52.068654, 53.15238],
            [52.106906, 53.168183],
            [52.136642, 53.166116],
            [52.126918, 53.233201],
            [52.166878, 53.235245],
            [52.160008, 53.282933],
            [52.142478, 53.301449],
            [52.173312, 53.297383],
            [52.188006, 53.321999],
            [52.176114, 53.324583],
            [52.211862, 53.380847],
            [52.162968, 53.390435],
            [52.152981, 53.386786],
            [52.145715, 53.415515],
            [52.159431, 53.42911],
            [52.145093, 53.444658],
            [52.122757, 53.441169],
            [52.129202, 53.450931],
            [52.091388, 53.470991],
            [52.116364, 53.491766],
            [52.101297, 53.499607],
            [52.078361, 53.498788],
            [52.097669, 53.558273],
            [52.122063, 53.545953],
            [52.138162, 53.550667],
            [52.168448, 53.544269],
            [52.188837, 53.55588],
            [52.2143, 53.615726],
            [52.226679, 53.62877],
            [52.234282, 53.651838],
            [52.26426, 53.661428],
            [52.261065, 53.694617],
            [52.278743, 53.711782],
            [52.279768, 53.744116],
            [52.339587, 53.787285],
            [52.342609, 53.801028],
            [52.357549, 53.80867],
            [52.3673, 53.830625],
            [52.381041, 53.889932],
            [52.408185, 53.893012],
            [52.416508, 53.960074],
            [52.407727, 53.950095],
            [52.384232, 53.95055],
            [52.395523, 53.962469],
            [52.371914, 53.971878],
            [52.405443, 54.008546],
            [52.401508, 54.014134],
            [52.426314, 54.026623],
            [52.458306, 54.033923],
            [52.488814, 54.054396],
            [52.478891, 54.070534],
            [52.381433, 54.078402],
            [52.389188, 54.089693],
            [52.379037, 54.097905],
            [52.381203, 54.133318],
            [52.372192, 54.148658],
            [52.347672, 54.125733],
            [52.307525, 54.129953],
            [52.294238, 54.149512],
            [52.310888, 54.1766],
            [52.343222, 54.169358],
            [52.362553, 54.191199],
            [52.349779, 54.211728],
            [52.373215, 54.255467],
            [52.330786, 54.279075],
            [52.345441, 54.294073],
            [52.389865, 54.293732],
            [52.393343, 54.304966],
            [52.429383, 54.310897],
            [52.460976, 54.297895],
            [52.528209, 54.303314],
            [52.568276, 54.340128],
            [52.573179, 54.349483],
            [52.602655, 54.365607],
            [52.622826, 54.356098],
            [52.638066, 54.338272],
            [52.678207, 54.357831],
            [52.696425, 54.360757],
            [52.755502, 54.349043],
            [52.8053, 54.335002],
            [52.84457, 54.313596],
            [52.877998, 54.325628],
            [52.897121, 54.317069],
            [52.917656, 54.320039],
            [52.934188, 54.308426],
            [52.952239, 54.306951],
            [52.927788, 54.292028],
            [52.904269, 54.290593],
            [52.890558, 54.276983],
            [52.922449, 54.268675],
            [52.935902, 54.277177],
            [52.945415, 54.268671],
            [52.98129, 54.268161],
            [52.978842, 54.286455],
            [53.029466, 54.291641],
            [53.025673, 54.303075],
            [52.990646, 54.318612],
            [53.016833, 54.322738],
            [53.011303, 54.333979],
            [53.056297, 54.352738],
            [53.081858, 54.317485],
            [53.115194, 54.311969],
            [53.124611, 54.301575],
            [53.15518, 54.288275],
            [53.116426, 54.267219],
            [53.143489, 54.251756],
            [53.130439, 54.24234],
            [53.097554, 54.23443],
            [53.044416, 54.245803],
            [53.074925, 54.221105],
            [53.027078, 54.220662],
            [53.004273, 54.237965],
            [52.954906, 54.235049],
            [52.950733, 54.212322],
            [52.970985, 54.205598],
            [52.962821, 54.191088],
            [52.974662, 54.179371],
            [53.025076, 54.16898],
            [53.046302, 54.138575],
            [53.044023, 54.131547],
            [53.096854, 54.125289],
            [53.083714, 54.092348],
            [53.058766, 54.082654],
            [53.062389, 54.065377],
            [53.092525, 54.079139],
            [53.133062, 54.076905],
            [53.171209, 54.084727],
            [53.198409, 54.096781],
            [53.199975, 54.111576],
            [53.236603, 54.113463],
            [53.254303, 54.098034],
            [53.228191, 54.096755],
            [53.225771, 54.087275],
            [53.287181, 54.087859],
            [53.290863, 54.076162],
            [53.282151, 54.037567],
            [53.30719, 54.016889],
            [53.321745, 54.017714],
            [53.346783, 54.001296],
            [53.36886, 53.974244],
            [53.404099, 53.997322],
            [53.414488, 54.030531],
            [53.445952, 54.043073],
            [53.500606, 54.056129],
            [53.511907, 54.053859],
            [53.504024, 54.023306],
            [53.514651, 54.009251],
            [53.535563, 53.998067],
            [53.530936, 53.975741],
            [53.55082, 53.968199],
            [53.57019, 53.946731],
            [53.570705, 53.914464],
            [53.598302, 53.883568],
            [53.582533, 53.877569],
            [53.596932, 53.867756],
            [53.599161, 53.839559],
            [53.629673, 53.807121],
            [53.627274, 53.790238],
            [53.644587, 53.753514],
            [53.682811, 53.743744],
            [53.824225, 53.687494],
            [53.850762, 53.684026],
            [53.913805, 53.610626],
            [53.98006, 53.543541],
            [54.003278, 53.532144],
            [54.023932, 53.498377],
            [54.041745, 53.500566],
            [54.060446, 53.457009],
            [54.074814, 53.441663],
            [54.10729, 53.421725],
            [54.151671, 53.387978],
            [54.169707, 53.385642],
            [54.238873, 53.36353],
            [54.253711, 53.361851],
            [54.280186, 53.384195],
            [54.287963, 53.402712],
            [54.316259, 53.40333],
            [54.344458, 53.388498],
            [54.401955, 53.369312],
            [54.428063, 53.37045],
            [54.463848, 53.362103],
            [54.504664, 53.363604],
            [54.547572, 53.355177],
            [54.56797, 53.32771],
            [54.565743, 53.2903],
            [54.552032, 53.263175],
            [54.626937, 53.258848],
            [54.637565, 53.236223],
            [54.612368, 53.227652],
            [54.615454, 53.217025],
            [54.660534, 53.214283],
            [54.675103, 53.225767],
            [54.792688, 53.242052],
            [54.797144, 53.255122],
            [54.827796, 53.275814],
            [54.840853, 53.252209],
            [54.871878, 53.233612],
            [54.901874, 53.226756],
            [54.927072, 53.211115],
            [54.94627, 53.17422],
            [54.956557, 53.119799],
            [54.941131, 53.10073],
            [54.975413, 53.08029],
            [54.966157, 53.068591],
            [54.996668, 53.056593],
            [55.004039, 53.028311],
            [55.038322, 52.999258],
            [55.053578, 52.958721],
            [55.070961, 52.943829],
            [55.122748, 52.914446],
            [55.133692, 52.897318],
            [55.123386, 52.882552],
            [55.131206, 52.863282],
            [55.147488, 52.860756],
            [55.155027, 52.848177],
            [55.1308, 52.852005],
            [55.136813, 52.838043],
            [55.152113, 52.837682],
            [55.1395, 52.814367],
            [55.160718, 52.81141],
            [55.179053, 52.817227],
            [55.190935, 52.838874],
            [55.245713, 52.818871],
            [55.265087, 52.842316],
            [55.27251, 52.827761],
            [55.319446, 52.847758],
            [55.34878, 52.851411],
            [55.385366, 52.821007],
            [55.400338, 52.827063],
            [55.400725, 52.807036],
            [55.420737, 52.791133],
            [55.395584, 52.78105],
            [55.387828, 52.788581],
            [55.338549, 52.767307],
            [55.367903, 52.74008],
            [55.416625, 52.746867],
            [55.427082, 52.733112],
            [55.395115, 52.699859],
            [55.373689, 52.70786],
            [55.361536, 52.695058],
            [55.367311, 52.683087],
            [55.315497, 52.693984],
            [55.301736, 52.729321],
            [55.276882, 52.718143],
            [55.266281, 52.738386],
            [55.225291, 52.740677],
            [55.216321, 52.721853],
            [55.269045, 52.700114],
            [55.263219, 52.645907],
            [55.280618, 52.630418],
            [55.302971, 52.629615],
            [55.352865, 52.638366],
            [55.363492, 52.646123],
            [55.368152, 52.675262],
            [55.403601, 52.66772],
            [55.435312, 52.649037],
            [55.463937, 52.624612],
            [55.437028, 52.58536],
            [55.469281, 52.583557],
            [55.524102, 52.604216],
            [55.530034, 52.575909],
            [55.508135, 52.567669],
            [55.510061, 52.555206],
            [55.548958, 52.544438],
            [55.544844, 52.534839],
            [55.479367, 52.541481],
            [55.474527, 52.523159],
            [55.510049, 52.520227],
            [55.525192, 52.513938],
            [55.519649, 52.496358],
            [55.501479, 52.498758],
            [55.495774, 52.472497],
            [55.499997, 52.462045],
            [55.539457, 52.457284],
            [55.542362, 52.443787],
            [55.528543, 52.43107],
            [55.496196, 52.416898],
            [55.525097, 52.38756],
            [55.544871, 52.394402],
            [55.551764, 52.386398],
            [55.56936, 52.406242],
            [55.592428, 52.383848],
            [55.614079, 52.382345],
            [55.618275, 52.371895],
            [55.674984, 52.367874],
            [55.665641, 52.40133],
            [55.699395, 52.400965],
            [55.73319, 52.351499],
            [55.773394, 52.345755],
            [55.798198, 52.376925],
            [55.823417, 52.364962],
            [55.857774, 52.38953],
            [55.88206, 52.396155],
            [55.887662, 52.407831],
            [55.871378, 52.414687],
            [55.848236, 52.470308],
            [55.866071, 52.488317],
            [55.863247, 52.496093],
            [55.872231, 52.5345],
            [55.867592, 52.545781],
            [55.885258, 52.544442],
            [55.908133, 52.559945],
            [55.92211, 52.552841],
            [55.911998, 52.535936],
            [55.928453, 52.52126],
            [55.920333, 52.509666],
            [55.944062, 52.515294],
            [55.968138, 52.546898],
            [55.966266, 52.557788],
            [55.98734, 52.561291],
            [56.006415, 52.548907],
            [56.036609, 52.570241],
            [56.027052, 52.576099],
            [56.053138, 52.590947],
            [56.067469, 52.623386],
            [56.035274, 52.647084],
            [56.068193, 52.644699],
            [56.108575, 52.648476],
            [56.126477, 52.645876],
            [56.098037, 52.636475],
            [56.07354, 52.621542],
            [56.104792, 52.618557],
            [56.12523, 52.633621],
            [56.148457, 52.632301],
            [56.166535, 52.602166],
            [56.157108, 52.591711],
            [56.18419, 52.591197],
            [56.190017, 52.599253],
            [56.219052, 52.572656],
            [56.26441, 52.545861],
            [56.322344, 52.580871],
            [56.379422, 52.598184],
            [56.423645, 52.603841],
            [56.445379, 52.581714],
            [56.40702, 52.559404],
            [56.43016, 52.543592],
            [56.374796, 52.528979],
            [56.384224, 52.508024],
            [56.3508, 52.496325],
            [56.381999, 52.420007],
            [56.432392, 52.428407],
            [56.430849, 52.445204],
            [56.443362, 52.445547],
            [56.443189, 52.47233],
            [56.465644, 52.470273],
            [56.486556, 52.451204],
            [56.486728, 52.438135],
            [56.464102, 52.42905],
            [56.45759, 52.409809],
            [56.440278, 52.405353],
            [56.432565, 52.374285],
            [56.43925, 52.374628],
            [56.44525, 52.342832],
            [56.433938, 52.331348],
            [56.452965, 52.317978],
            [56.453994, 52.297024],
            [56.399315, 52.281768],
            [56.359206, 52.280525],
            [56.338466, 52.274525],
            [56.311556, 52.25717],
            [56.327326, 52.247229],
            [56.294587, 52.241401],
            [56.272476, 52.23073],
            [56.279332, 52.223189],
            [56.260993, 52.201891],
            [56.236995, 52.219032],
            [56.200487, 52.166709],
            [56.202373, 52.142327],
            [56.268194, 52.12193],
            [56.295448, 52.127416],
            [56.347727, 52.128616],
            [56.351668, 52.158869],
            [56.382864, 52.161098],
            [56.412345, 52.185138],
            [56.421258, 52.183424],
            [56.427771, 52.200736],
            [56.466166, 52.211192],
            [56.474394, 52.20018],
            [56.437713, 52.198122],
            [56.45074, 52.190923],
            [56.441142, 52.180425],
            [56.399491, 52.159855],
            [56.427945, 52.144086],
            [56.458112, 52.163113],
            [56.487422, 52.169284],
            [56.54433, 52.148501],
            [56.616321, 52.128919],
            [56.627977, 52.121506],
            [56.621293, 52.09768],
            [56.585469, 52.078825],
            [56.603125, 52.06477],
            [56.606381, 52.080711],
            [56.627635, 52.085682],
            [56.62952, 52.095966],
            [56.666716, 52.08564],
            [56.682829, 52.067085],
            [56.704598, 52.060058],
            [56.683173, 52.031904],
            [56.677174, 52.00855],
            [56.658149, 51.985538],
            [56.65455, 51.959442],
            [56.691574, 51.949286],
            [56.695517, 51.938145],
            [56.7166, 51.943459],
            [56.724314, 51.92169],
            [56.734256, 51.918219],
            [56.726029, 51.901079],
            [56.689347, 51.908963],
            [56.671693, 51.902278],
            [56.630384, 51.899192],
            [56.633298, 51.879437],
            [56.622328, 51.874981],
            [56.610845, 51.827073],
            [56.678209, 51.812461],
            [56.697064, 51.803205],
            [56.743173, 51.771281],
            [56.754143, 51.776081],
            [56.769913, 51.763697],
            [56.759972, 51.753241],
            [56.780884, 51.736572],
            [56.740946, 51.738286],
            [56.745575, 51.716646],
            [56.759459, 51.696548],
            [56.749347, 51.681465],
            [56.771116, 51.666638],
            [56.752433, 51.630557],
            [56.732379, 51.610802],
            [56.723, 51.591497],
            [56.774375, 51.585092],
            [56.814141, 51.587492],
            [56.817912, 51.591948],
            [56.892302, 51.606433],
            [56.895215, 51.647228],
            [56.885444, 51.651384],
            [56.886129, 51.677138],
            [56.861446, 51.704263],
            [56.795624, 51.747499],
            [56.793968, 51.765199],
            [56.811799, 51.768614],
            [56.842481, 51.764773],
            [56.864016, 51.743044],
            [56.898812, 51.735159],
            [56.943549, 51.73396],
            [56.958461, 51.745787],
            [56.96926, 51.723847],
            [56.996857, 51.720548],
            [56.997715, 51.68361],
            [57.002686, 51.666984],
            [57.095569, 51.66702],
            [57.118074, 51.632169],
            [57.160507, 51.575738],
            [57.198214, 51.569554],
            [57.22046, 51.575738],
            [57.225356, 51.590212],
            [57.305837, 51.60709],
            [57.293554, 51.62953],
            [57.331861, 51.649844],
            [57.36055, 51.653301],
            [57.407274, 51.691396],
            [57.395935, 51.727277],
            [57.481313, 51.725904],
            [57.499093, 51.721094],
            [57.529413, 51.732432],
            [57.573735, 51.725647],
            [57.589882, 51.744071],
            [57.628705, 51.750428],
            [57.640816, 51.76722],
            [57.610925, 51.785107],
            [57.613845, 51.794469],
            [57.641588, 51.808342],
            [57.645281, 51.819765],
            [57.66976, 51.830373],
            [57.658679, 51.846693],
            [57.63901, 51.853865],
            [57.655329, 51.86881],
            [57.715197, 51.860028],
            [57.725161, 51.834304],
            [57.754365, 51.825371],
            [57.84378, 51.826231],
            [57.844381, 51.837912],
            [57.912408, 51.83583],
            [57.941956, 51.821637],
            [57.955098, 51.821551],
            [57.955184, 51.793786],
            [58.069594, 51.793959],
            [58.109535, 51.791877],
            [58.118211, 51.773732],
            [58.114432, 51.761686],
            [58.142777, 51.749103],
            [58.152569, 51.735081],
            [58.144409, 51.724516],
            [58.164657, 51.718366],
            [58.188301, 51.733406],
            [58.257273, 51.733493],
            [58.258733, 51.750779],
            [58.313447, 51.752261],
            [58.319888, 51.778909],
            [58.329165, 51.770234],
            [58.403462, 51.771352],
            [58.40329, 51.778567],
            [58.489441, 51.779255],
            [58.489871, 51.770837],
            [58.574905, 51.771697],
            [58.574732, 51.827034],
            [58.615445, 51.826519],
            [58.619137, 51.875113],
            [58.614413, 51.884754],
            [58.639322, 51.885012],
            [58.655641, 51.893001],
            [58.659677, 51.935346],
            [58.655809, 52.034295],
            [58.712842, 52.037409],
            [58.713098, 52.079733],
            [58.652715, 52.078787],
            [58.651511, 52.130065],
            [58.616724, 52.136593],
            [58.661215, 52.199467],
            [58.653656, 52.217891],
            [58.661558, 52.242263],
            [58.670231, 52.297299],
            [58.699263, 52.29541],
            [58.742554, 52.25867],
            [58.812128, 52.250747],
            [58.897506, 52.244048],
            [58.890892, 52.250576],
            [58.903948, 52.273209],
            [58.925764, 52.28182],
            [58.952477, 52.28167],
            [58.943371, 52.313429],
            [58.930487, 52.31884],
            [58.944316, 52.326398],
            [58.945428, 52.339689],
            [58.900391, 52.342399],
            [58.868531, 52.351906],
            [58.837006, 52.355784],
            [58.854927, 52.425806],
            [58.859035, 52.4468],
            [58.898511, 52.443294],
            [58.901069, 52.447419],
            [58.953251, 52.440118],
            [58.955313, 52.433518],
            [59.001885, 52.42465],
            [58.989139, 52.408274],
            [59.007538, 52.385834],
            [59.001908, 52.361888],
            [59.029422, 52.348482],
            [59.057062, 52.346978],
            [59.076471, 52.33243],
            [59.165674, 52.276502],
            [59.187577, 52.284794],
            [59.217112, 52.304759],
            [59.230269, 52.352898],
            [59.227421, 52.42488],
            [59.250108, 52.43643],
            [59.244538, 52.463201],
            [59.249241, 52.476566],
            [59.296142, 52.479826],
            [59.312395, 52.471081],
            [59.370021, 52.472443],
            [59.391513, 52.469184],
            [59.412303, 52.484694],
            [59.414531, 52.460852],
            [59.443282, 52.457428],
            [59.489195, 52.42744],
            [59.501736, 52.398978],
            [59.516668, 52.404753],
            [59.547646, 52.442167],
            [59.658279, 52.441261],
            [59.672531, 52.438972],
            [59.669479, 52.423874],
            [59.677565, 52.408694],
            [59.734036, 52.422596],
            [59.733458, 52.434063],
            [59.824166, 52.480058],
            [59.860137, 52.464631],
            [59.918259, 52.42202],
            [60.003771, 52.41967],
            [60.021138, 52.413442],
            [60.037555, 52.422929],
            [60.076207, 52.411503],
            [60.162998, 52.400119],
            [60.160155, 52.282928],
            [60.173892, 52.260282],
            [60.144357, 52.244771],
            [60.122908, 52.221671],
            [60.082565, 52.216143],
            [60.055011, 52.182318],
            [60.056826, 52.176543],
            [60.016071, 52.175552],
            [59.968139, 52.151874],
            [59.933078, 52.084966],
            [59.905441, 52.082243],
            [59.919466, 52.064259],
            [59.907917, 52.040168],
            [59.975567, 52.033569],
            [59.999883, 51.996788],
            [60.0096, 51.9938],
            [60.0064, 51.9766],
            [59.9912, 51.9551],
            [60.0282, 51.9331],
            [60.0324, 51.9142],
            [60.0748, 51.8921],
            [60.0656, 51.8729],
            [60.1237, 51.8726],
            [60.1621, 51.8636],
            [60.152, 51.9083],
            [60.182, 51.8929],
            [60.2026, 51.8887],
            [60.2257, 51.8678],
            [60.2475, 51.8666],
            [60.3209, 51.8332],
            [60.3933, 51.8105],
            [60.4534, 51.7993],
            [60.5143, 51.8007],
            [60.5192, 51.7763],
            [60.4673, 51.7591],
            [60.4496, 51.7381],
            [60.4161, 51.7129],
            [60.3541, 51.6846],
            [60.413, 51.6481],
            [60.4425, 51.6345],
            [60.5388, 51.6167],
            [60.6542, 51.619],
            [60.8102, 51.6184],
            [60.9302, 51.6143],
            [60.9047, 51.5725],
            [60.9179, 51.5674],
            [60.9192, 51.5404],
            [60.9477, 51.5149],
            [60.9618, 51.489],
            [60.9818, 51.493],
            [61.019075, 51.468301],
            [61.085, 51.4713],
            [61.1105, 51.4466],
            [61.1332, 51.4541],
            [61.1448, 51.4507],
            [61.2034, 51.4563],
            [61.2791, 51.4375],
            [61.316901, 51.443407],
            [61.386, 51.4257],
            [61.4093, 51.4151],
            [61.4308, 51.418],
            [61.4657, 51.4059],
            [61.4972, 51.411],
            [61.518, 51.3885],
            [61.5483, 51.3306],
            [61.592737, 51.303199],
            [61.689672, 51.255852],
            [61.5718, 51.2383],
            [61.566, 51.231],
            [61.5264, 51.0838],
            [61.5071, 51.0181],
            [61.4486, 50.8061],
            [61.2742, 50.765],
            [61.261434, 50.763079],
            [61.085253, 50.72206],
            [60.9599, 50.6905],
            [60.8115, 50.6556],
            [60.749, 50.6598],
            [60.5582, 50.6579],
            [60.4407, 50.6598],
            [60.3255, 50.6671],
            [60.2753, 50.7028],
            [60.1744, 50.7786],
            [60.1609, 50.7908],
            [60.206, 50.8207],
            [60.2015, 50.8242],
            [60.0883, 50.8409],
            [60.035842, 50.845367],
            [60.0063, 50.8139],
            [59.9907, 50.8033],
            [59.9691, 50.7759],
            [59.9901, 50.6828],
            [59.9869, 50.6775],
            [59.9097, 50.6415],
            [59.8549, 50.6008],
            [59.8117, 50.5387],
            [59.7739, 50.5443],
            [59.6536, 50.5373],
            [59.6152, 50.5334],
            [59.5034, 50.5001],
            [59.5165, 50.533],
            [59.4593, 50.5451],
            [59.4704, 50.5689],
            [59.4685, 50.5862],
            [59.5669, 50.5778],
            [59.5389, 50.6081],
            [59.501, 50.6134],
            [59.4758, 50.6444],
            [59.4575, 50.6467],
            [59.4219, 50.6411],
            [59.2968, 50.657],
            [59.2772, 50.6361],
            [59.2292, 50.6675],
            [59.1914, 50.6763],
            [59.1615, 50.6723],
            [59.1182, 50.6856],
            [59.0868, 50.6782],
            [59.0436, 50.6935],
            [59.0194, 50.6912],
            [58.9899, 50.6961],
            [58.9206, 50.6972],
            [58.8741, 50.7032],
            [58.8496, 50.7273],
            [58.8476, 50.748],
            [58.8221, 50.7501],
            [58.7796, 50.7867],
            [58.8093, 50.7853],
            [58.7836, 50.8181],
            [58.6599, 50.8108],
            [58.6655, 50.8477],
            [58.6925, 50.85],
            [58.6826, 50.8897],
            [58.5953, 50.8729],
            [58.5749, 50.9406],
            [58.5995, 50.9529],
            [58.5917, 50.9755],
            [58.645, 50.9926],
            [58.6205, 51.0245],
            [58.5184, 51.0851],
            [58.3868, 51.0734],
            [58.3722, 51.1131],
            [58.351086, 51.116195],
            [58.325977, 51.173995],
            [58.321843, 51.162528],
            [58.278773, 51.144933],
            [58.243353, 51.144076],
            [58.235214, 51.134601],
            [58.203885, 51.128351],
            [58.218847, 51.11049],
            [58.206963, 51.08835],
            [58.171462, 51.085478],
            [58.172686, 51.062474],
            [58.119443, 51.070633],
            [58.103926, 51.069135],
            [58.08089, 51.086539],
            [58.0907, 51.1032],
            [58.0897, 51.1233],
            [58.0712, 51.1243],
            [58.0395, 51.1138],
            [58.0106, 51.0961],
            [57.9548, 51.0894],
            [57.9392, 51.0958],
            [57.9239, 51.1175],
            [57.8953, 51.1132],
            [57.8779, 51.1026],
            [57.851, 51.1145],
            [57.8339, 51.1155],
            [57.8172, 51.1418],
            [57.772124, 51.138578],
            [57.7529, 51.0531],
            [57.7534, 50.9929],
            [57.7497, 50.9435],
            [57.7523, 50.9273],
            [57.7306, 50.9328],
            [57.6853, 50.9169],
            [57.6812, 50.9295],
            [57.5604, 50.9364],
            [57.5289, 50.9177],
            [57.5072, 50.8809],
            [57.4745, 50.8811],
            [57.4206, 50.8975],
            [57.3328, 50.9454],
            [57.3175, 50.9886],
            [57.2962, 50.9985],
            [57.2879, 51.0363],
            [57.2408, 51.0292],
            [57.2323, 51.0501],
            [57.1812, 51.1147],
            [57.1455, 51.0986],
            [57.1497, 51.0861],
            [57.0256, 51.0644],
            [56.9913, 51.06],
            [56.9428, 51.087],
            [56.8958, 51.0609],
            [56.7953, 51.0949],
            [56.7582, 51.0876],
            [56.744, 51.0786],
            [56.704, 51.0775],
            [56.7545, 50.9816],
            [56.7147, 50.9741],
            [56.6961, 50.985],
            [56.6308, 50.9857],
            [56.6104, 51.0119],
            [56.586, 51.0307],
            [56.5602, 51.0423],
            [56.5373, 51.06],
            [56.5184, 51.0861],
            [56.4546, 51.057],
            [56.4656, 50.9646],
            [56.3887, 50.9658],
            [56.3789, 50.9523],
            [56.399, 50.9451],
            [56.3753, 50.9037],
            [56.3666, 50.9001],
            [56.2972, 50.9024],
            [56.2185, 50.9289],
            [56.1856, 50.9378],
            [56.1819, 50.9072],
            [56.157, 50.8691],
            [56.1407, 50.8321],
            [56.1316, 50.7864],
            [56.1449, 50.7567],
            [56.061, 50.6976],
            [56.0105, 50.6747],
            [55.9824, 50.6691],
            [55.9136, 50.6442],
            [55.9014, 50.6226],
            [55.866, 50.6275],
            [55.828, 50.6218],
            [55.793, 50.607],
            [55.7807, 50.59],
            [55.7526, 50.5841],
            [55.7347, 50.5668],
            [55.7394, 50.5518],
            [55.7112, 50.5504],
            [55.7124, 50.5576],
            [55.6559, 50.5615],
            [55.6155, 50.5824],
            [55.6107, 50.6015],
            [55.5612, 50.6044],
            [55.5297, 50.6157],
            [55.5257, 50.6552],
            [55.4983, 50.6719],
            [55.3989, 50.6823],
            [55.3758, 50.6541],
            [55.2745, 50.7183],
            [55.1482, 50.7963],
            [55.0594, 50.8172],
            [55.0592, 50.8202],
            [55.1266, 50.8742],
            [55.1347, 50.8842],
            [55.1229, 50.8966],
            [55.0879, 50.9176],
            [55.0648, 50.9264],
            [55.0364, 50.9241],
            [55.0019, 50.8999],
            [54.8959, 50.9179],
            [54.9067, 50.9352],
            [54.8863, 50.9411],
            [54.8983, 50.9593],
            [54.8362, 50.979],
            [54.796, 50.9851],
            [54.7771, 50.993],
            [54.7517, 51.0168],
            [54.7525, 51.0302],
            [54.732, 51.0416],
            [54.7238, 51.0343],
            [54.7048, 51.043],
            [54.6908, 51.039],
            [54.7058, 51.0233],
            [54.6889, 51.0183],
            [54.6342, 51.0299],
            [54.5999, 51.0309],
            [54.5551, 51.0147],
            [54.5405, 50.9955],
            [54.5781, 50.9837],
            [54.5779, 50.9657],
            [54.5521, 50.9634],
            [54.5685, 50.9502],
            [54.5407, 50.9369],
            [54.5399, 50.9253],
            [54.5688, 50.9177],
            [54.6082, 50.925],
            [54.664, 50.918],
            [54.6732, 50.9047],
            [54.701, 50.8891],
            [54.69, 50.8538],
            [54.6781, 50.8348],
            [54.6601, 50.8262],
            [54.6879, 50.7811],
            [54.6795, 50.7223],
            [54.7169, 50.6956],
            [54.7079, 50.686],
            [54.7194, 50.6296],
            [54.7267, 50.6235],
            [54.719, 50.6077],
            [54.6652, 50.581],
            [54.6449, 50.5652],
            [54.6399, 50.5515],
            [54.6124, 50.5374],
            [54.5888, 50.5621],
            [54.537618, 50.541417],
            [54.510581, 50.538894],
            [54.4682, 50.5687],
            [54.4145, 50.6205],
            [54.4353, 50.6553],
            [54.4577, 50.7115],
            [54.4514, 50.7228],
            [54.4595, 50.7573],
            [54.4845, 50.793],
            [54.5105, 50.8096],
            [54.5325, 50.8515],
            [54.5237, 50.8567],
            [54.4529, 50.8516],
            [54.4396, 50.8872],
            [54.4201, 50.8796],
            [54.3612, 50.8862],
            [54.3236, 50.898],
            [54.2949, 50.9019],
            [54.2799, 50.9161],
            [54.2817, 50.9331],
            [54.2696, 50.9453],
            [54.2778, 50.952],
            [54.2061, 50.9601],
            [54.1942, 50.9697],
            [54.1929, 50.9903],
            [54.17, 50.9925],
            [54.1601, 51.014],
            [54.1875, 51.0325],
            [54.1542, 51.0651],
            [54.1434, 51.0537],
            [54.1314, 51.0635],
            [54.1498, 51.0901],
            [54.1372, 51.1078],
            [54.124, 51.1136],
            [54.0824, 51.1171],
            [54.0591, 51.1246],
            [54.0366, 51.1402],
            [54.0473, 51.1522],
            [54.0223, 51.1622],
            [53.9799, 51.1624],
            [53.9637, 51.1781],
            [53.9467, 51.1805],
            [53.9065, 51.1975],
            [53.857, 51.1907],
            [53.8259, 51.1932],
            [53.8264, 51.2013],
            [53.7984, 51.211],
            [53.7636, 51.2046],
            [53.7241, 51.2136],
            [53.7204, 51.2236],
            [53.699, 51.2257],
            [53.6982, 51.2358],
            [53.6612, 51.2402],
            [53.6678, 51.2561],
            [53.6454, 51.264],
            [53.6069, 51.2936],
            [53.601, 51.3152],
            [53.6217, 51.3256],
            [53.6177, 51.3563],
            [53.6004, 51.3559],
            [53.6114, 51.3726],
            [53.6256, 51.3705],
            [53.6283, 51.392],
            [53.5749, 51.413],
            [53.5773, 51.4309],
            [53.5268, 51.4275],
            [53.4941, 51.4335],
            [53.4821, 51.451],
            [53.4686, 51.4422],
            [53.4457, 51.4445],
            [53.4243, 51.4748],
            [53.3851, 51.4952],
            [53.368, 51.4983],
            [53.3616, 51.4867],
            [53.3488, 51.4906],
            [53.3513, 51.5112],
            [53.2934, 51.5051],
            [53.2898, 51.4908],
            [53.2725, 51.4894],
            [53.2634, 51.5102],
            [53.2319, 51.5144],
            [53.1958, 51.5115],
            [53.1897, 51.5359],
            [53.1675, 51.5228],
            [53.1562, 51.5275],
            [53.141, 51.5116],
            [53.1593, 51.5012],
            [53.156, 51.4934],
            [53.1128, 51.4941],
            [53.0846, 51.5054],
            [53.0624, 51.4934],
            [53.043749, 51.493424],
            [53.012649, 51.478824],
            [52.970049, 51.488324],
            [52.981049, 51.474724],
            [52.952249, 51.476124],
            [52.961749, 51.462424],
            [52.937649, 51.470924],
            [52.941049, 51.483424],
            [52.908349, 51.493924],
            [52.912249, 51.482124],
            [52.895149, 51.487824],
            [52.876109, 51.473667],
            [52.861389, 51.473898],
            [52.873749, 51.490824],
            [52.896091, 51.495835],
            [52.888263, 51.506951],
            [52.852619, 51.492732],
            [52.846014, 51.504141],
            [52.826156, 51.500921],
            [52.787795, 51.502855],
            [52.765147, 51.519366],
            [52.758069, 51.497401],
            [52.743261, 51.484908],
            [52.719365, 51.485323],
            [52.692339, 51.477687],
            [52.675164, 51.478856],
            [52.688937, 51.465631],
            [52.674527, 51.45925],
            [52.663537, 51.470578],
            [52.664782, 51.485734],
            [52.649566, 51.490091],
            [52.623071, 51.460263],
            [52.596496, 51.45427],
            [52.585204, 51.46102],
            [52.597215, 51.468458],
            [52.585839, 51.477226],
            [52.564585, 51.463702],
            [52.564678, 51.455342],
            [52.534698, 51.469615],
            [52.553805, 51.478399],
            [52.554049, 51.490123],
            [52.5241, 51.5006],
            [52.5073, 51.5152],
            [52.5107, 51.5313],
            [52.4997, 51.5485],
            [52.5043, 51.5566],
            [52.4603, 51.6229],
            [52.442, 51.6372],
            [52.4261, 51.6393],
            [52.4065, 51.6594],
            [52.3958, 51.6848],
            [52.3896, 51.7199],
            [52.380561, 51.718651],
            [52.362828, 51.773279],
            [52.320058, 51.766165],
            [52.333567, 51.743368],
            [52.238604, 51.72351],
            [52.232231, 51.735542],
            [52.181342, 51.733519],
            [52.173864, 51.686552],
            [52.161901, 51.686596],
            [52.090178, 51.658756],
            [52.074621, 51.673989],
            [52.056515, 51.666587],
            [51.998974, 51.666909],
            [51.998532, 51.683138],
            [51.894261, 51.68328],
            [51.894046, 51.674191],
            [51.86327, 51.673711],
            [51.86383, 51.655779],
            [51.844917, 51.64981],
            [51.79557, 51.608002],
            [51.799161, 51.591679],
            [51.771901, 51.582016],
            [51.813533, 51.497057],
            [51.730608, 51.480543],
            [51.738221, 51.465026],
            [51.656046, 51.448826],
            [51.641781, 51.476686],
            [51.630671, 51.475075],
            [51.644356, 51.501623],
            [51.658248, 51.504487],
            [51.640494, 51.538787],
            [51.613251, 51.533413],
            [51.604132, 51.550436],
            [51.576846, 51.545046],
            [51.585331, 51.527975],
            [51.557835, 51.52283],
            [51.566504, 51.505577],
            [51.5145, 51.4968],
            [51.4351, 51.4773],
            [51.3847, 51.4784],
            [51.3848, 51.4911],
            [51.2927, 51.4939],
            [51.2886, 51.538],
            [51.254, 51.5247],
            [51.2426, 51.5606],
            [51.2456, 51.5678],
            [51.3855, 51.5683],
            [51.3832, 51.6438],
            [51.3081, 51.6439],
            [51.2938, 51.671],
            [51.2578, 51.6909],
            [51.2144, 51.6865],
            [51.2071, 51.6721],
            [51.1897, 51.6703],
            [51.1594, 51.6803],
            [51.1233, 51.6794],
            [51.0987, 51.6731],
            [51.038, 51.6839],
            [51.0047, 51.6777],
            [50.9715, 51.6866],
            [50.9396, 51.685],
            [50.8919, 51.6884],
            [50.87, 51.7555],
            [50.7932, 51.7719],
            [50.768482, 51.773511],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5535",
      properties: {
        name: "Орловская область",
        density: 0,
        path: "/world/Russia/Орловская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.141099, 53.279123],
            [35.179103, 53.282277],
            [35.238381, 53.280351],
            [35.255724, 53.289914],
            [35.247443, 53.319465],
            [35.266824, 53.31914],
            [35.306889, 53.333934],
            [35.378812, 53.334278],
            [35.383564, 53.337393],
            [35.384099, 53.397562],
            [35.413994, 53.397572],
            [35.426047, 53.414174],
            [35.463722, 53.418108],
            [35.465689, 53.432896],
            [35.439174, 53.435119],
            [35.430717, 53.443595],
            [35.494053, 53.446348],
            [35.528502, 53.443965],
            [35.544013, 53.45684],
            [35.528826, 53.430185],
            [35.507664, 53.432196],
            [35.501566, 53.422196],
            [35.530145, 53.42868],
            [35.555733, 53.454924],
            [35.547629, 53.470489],
            [35.55864, 53.485316],
            [35.583921, 53.496636],
            [35.581477, 53.509856],
            [35.596106, 53.51887],
            [35.623268, 53.523857],
            [35.612642, 53.535],
            [35.629667, 53.541586],
            [35.685123, 53.545414],
            [35.700118, 53.530125],
            [35.719842, 53.521993],
            [35.758281, 53.520329],
            [35.772057, 53.505952],
            [35.794964, 53.52476],
            [35.794394, 53.539902],
            [35.809175, 53.550662],
            [35.809128, 53.564278],
            [35.823209, 53.573658],
            [35.822518, 53.587647],
            [35.875331, 53.596266],
            [35.909112, 53.64065],
            [35.926401, 53.639526],
            [35.925304, 53.652394],
            [35.954477, 53.647133],
            [35.960637, 53.625155],
            [35.991189, 53.626273],
            [36.037, 53.610236],
            [36.021448, 53.597309],
            [36.030437, 53.579939],
            [36.015793, 53.573071],
            [36.030538, 53.560296],
            [36.048918, 53.561104],
            [36.060734, 53.573425],
            [36.081235, 53.569588],
            [36.13163, 53.546765],
            [36.173945, 53.539393],
            [36.207978, 53.549897],
            [36.233427, 53.562824],
            [36.228511, 53.548008],
            [36.24326, 53.544746],
            [36.264378, 53.55474],
            [36.296466, 53.549973],
            [36.29754, 53.529813],
            [36.2879, 53.517555],
            [36.323766, 53.499218],
            [36.32032, 53.473698],
            [36.371735, 53.467763],
            [36.383065, 53.449602],
            [36.425529, 53.43309],
            [36.426208, 53.421834],
            [36.452931, 53.431575],
            [36.487625, 53.407303],
            [36.494591, 53.388288],
            [36.511078, 53.389478],
            [36.531698, 53.378138],
            [36.573274, 53.377057],
            [36.5793, 53.384788],
            [36.622851, 53.399125],
            [36.635626, 53.420219],
            [36.699603, 53.395036],
            [36.695868, 53.370495],
            [36.67567, 53.363628],
            [36.731215, 53.343734],
            [36.739093, 53.325606],
            [36.760457, 53.323755],
            [36.748183, 53.305913],
            [36.773935, 53.315608],
            [36.784136, 53.295815],
            [36.813423, 53.295815],
            [36.823219, 53.288948],
            [36.910777, 53.294302],
            [36.89876, 53.272993],
            [36.914919, 53.279456],
            [36.99619, 53.290203],
            [37.012474, 53.296323],
            [37.007767, 53.267031],
            [37.029775, 53.273852],
            [37.071526, 53.279115],
            [37.094112, 53.286224],
            [37.11498, 53.279156],
            [37.127045, 53.262907],
            [37.139004, 53.267278],
            [37.158125, 53.258045],
            [37.206376, 53.25593],
            [37.261921, 53.259566],
            [37.292722, 53.278553],
            [37.365434, 53.291279],
            [37.364222, 53.301176],
            [37.439863, 53.29431],
            [37.465515, 53.287039],
            [37.453094, 53.273506],
            [37.475918, 53.264215],
            [37.535098, 53.26568],
            [37.534694, 53.270528],
            [37.590463, 53.278939],
            [37.575899, 53.248715],
            [37.579434, 53.241544],
            [37.572872, 53.188019],
            [37.582669, 53.157975],
            [37.609433, 53.12268],
            [37.609804, 53.098542],
            [37.648876, 53.096258],
            [37.652725, 53.105177],
            [37.679015, 53.107583],
            [37.730973, 53.127954],
            [37.754454, 53.113845],
            [37.760832, 53.099325],
            [37.824341, 53.086377],
            [37.819999, 53.068098],
            [37.845237, 53.015455],
            [37.866469, 53.012217],
            [37.875195, 52.993481],
            [37.831905, 52.949628],
            [37.85006, 52.935792],
            [37.838335, 52.906478],
            [37.856372, 52.891931],
            [37.876029, 52.886328],
            [37.834043, 52.878168],
            [37.842658, 52.868786],
            [37.801613, 52.853207],
            [37.838143, 52.828461],
            [37.836651, 52.823843],
            [37.884592, 52.814333],
            [37.90025, 52.78118],
            [37.891347, 52.771149],
            [37.891259, 52.740489],
            [37.859553, 52.733537],
            [37.810331, 52.708182],
            [37.821211, 52.673045],
            [37.79405, 52.656636],
            [37.82653, 52.656935],
            [37.824277, 52.632034],
            [37.804548, 52.622015],
            [37.786233, 52.629249],
            [37.770786, 52.613067],
            [37.743054, 52.601126],
            [37.730377, 52.609947],
            [37.722169, 52.594846],
            [37.775183, 52.58957],
            [37.786606, 52.57044],
            [37.810337, 52.575063],
            [37.822703, 52.545695],
            [37.84557, 52.553715],
            [37.860276, 52.536041],
            [37.820162, 52.528973],
            [37.824751, 52.519233],
            [37.802905, 52.513903],
            [37.789985, 52.497305],
            [37.81189, 52.491234],
            [37.850554, 52.49286],
            [37.883531, 52.485731],
            [37.901641, 52.47674],
            [37.930833, 52.472308],
            [37.932924, 52.446156],
            [37.892841, 52.442419],
            [37.88253, 52.436371],
            [37.928458, 52.403076],
            [37.958899, 52.389912],
            [37.955658, 52.398538],
            [37.993588, 52.403097],
            [37.988079, 52.383278],
            [37.996249, 52.360798],
            [38.026011, 52.344145],
            [38.057165, 52.339016],
            [38.065904, 52.330215],
            [38.043235, 52.321729],
            [38.057294, 52.297414],
            [38.013349, 52.282533],
            [38.052292, 52.269805],
            [38.036842, 52.260307],
            [38.039122, 52.24511],
            [38.012591, 52.236751],
            [37.984032, 52.252771],
            [37.956677, 52.249478],
            [37.939707, 52.241245],
            [37.886771, 52.22782],
            [37.883099, 52.206671],
            [37.865115, 52.212623],
            [37.839406, 52.20895],
            [37.827819, 52.191409],
            [37.800972, 52.169246],
            [37.760546, 52.163008],
            [37.772478, 52.146956],
            [37.791854, 52.146957],
            [37.795024, 52.121745],
            [37.779424, 52.119075],
            [37.787743, 52.098895],
            [37.774726, 52.097642],
            [37.757656, 52.074111],
            [37.771341, 52.059382],
            [37.750635, 52.057355],
            [37.758678, 52.027341],
            [37.738268, 52.01703],
            [37.738679, 52.004723],
            [37.717697, 51.996505],
            [37.722778, 51.97703],
            [37.710247, 51.970256],
            [37.640728, 51.986597],
            [37.622523, 52.0026],
            [37.507934, 52.016485],
            [37.483032, 51.962386],
            [37.424947, 51.934378],
            [37.387341, 51.947022],
            [37.345214, 51.979184],
            [37.329548, 52.008185],
            [37.293561, 52.013095],
            [37.282554, 52.001749],
            [37.262062, 52.015127],
            [37.238523, 52.015973],
            [37.177713, 52.041696],
            [37.195241, 52.055513],
            [37.207258, 52.082804],
            [37.189753, 52.087521],
            [37.152633, 52.075169],
            [37.115435, 52.079329],
            [37.094563, 52.094706],
            [37.074764, 52.07417],
            [37.042894, 52.07895],
            [37.027124, 52.088931],
            [37.033989, 52.11012],
            [36.99376, 52.107455],
            [36.9796, 52.091225],
            [36.935787, 52.095247],
            [36.94007, 52.101388],
            [36.88235, 52.115543],
            [36.848525, 52.111693],
            [36.837452, 52.118655],
            [36.792174, 52.127743],
            [36.777568, 52.137335],
            [36.772347, 52.161246],
            [36.751284, 52.174745],
            [36.734773, 52.171662],
            [36.687132, 52.180756],
            [36.660552, 52.175811],
            [36.64018, 52.184415],
            [36.640841, 52.207556],
            [36.608808, 52.216409],
            [36.599111, 52.212195],
            [36.574982, 52.220652],
            [36.564741, 52.212477],
            [36.524042, 52.21992],
            [36.496133, 52.232407],
            [36.486124, 52.251965],
            [36.495959, 52.258657],
            [36.47179, 52.275699],
            [36.444622, 52.31068],
            [36.440794, 52.322658],
            [36.41636, 52.345507],
            [36.357389, 52.373204],
            [36.331258, 52.368009],
            [36.322661, 52.381902],
            [36.298401, 52.381697],
            [36.261883, 52.367118],
            [36.221832, 52.357394],
            [36.213397, 52.343317],
            [36.189105, 52.328057],
            [36.17353, 52.335098],
            [36.15498, 52.330904],
            [36.129068, 52.31145],
            [36.10286, 52.319585],
            [36.052201, 52.317914],
            [36.003516, 52.310459],
            [36.002907, 52.303839],
            [35.972776, 52.295134],
            [35.951507, 52.278092],
            [35.897008, 52.282918],
            [35.884792, 52.294535],
            [35.86429, 52.300258],
            [35.82773, 52.297438],
            [35.814661, 52.301282],
            [35.776478, 52.286674],
            [35.712413, 52.254939],
            [35.667909, 52.248233],
            [35.645443, 52.250453],
            [35.642623, 52.27104],
            [35.64826, 52.288936],
            [35.67081, 52.319048],
            [35.66483, 52.33468],
            [35.629892, 52.356974],
            [35.633735, 52.36432],
            [35.615455, 52.379483],
            [35.595295, 52.384266],
            [35.606443, 52.437365],
            [35.541322, 52.44506],
            [35.532863, 52.426863],
            [35.47656, 52.419749],
            [35.455404, 52.430497],
            [35.431434, 52.426658],
            [35.410706, 52.40337],
            [35.41719, 52.381106],
            [35.40203, 52.374298],
            [35.337773, 52.374195],
            [35.329925, 52.3701],
            [35.282666, 52.389463],
            [35.233361, 52.374876],
            [35.204784, 52.379567],
            [35.193696, 52.347066],
            [35.156929, 52.351501],
            [35.157356, 52.340753],
            [35.127415, 52.334355],
            [35.083484, 52.334013],
            [35.066082, 52.321985],
            [35.038444, 52.31951],
            [35.028377, 52.333159],
            [35.030339, 52.349622],
            [34.998265, 52.347234],
            [34.980009, 52.355081],
            [34.956209, 52.354996],
            [34.96491, 52.366341],
            [34.924193, 52.369988],
            [34.900958, 52.378488],
            [34.869728, 52.368114],
            [34.848439, 52.398047],
            [34.869402, 52.429277],
            [34.854596, 52.451538],
            [34.888797, 52.472827],
            [34.903061, 52.474124],
            [34.913542, 52.506759],
            [34.922607, 52.512414],
            [34.991555, 52.50716],
            [35.006582, 52.540908],
            [35.002204, 52.566843],
            [35.009011, 52.592454],
            [35.02522, 52.610608],
            [35.007767, 52.623035],
            [34.99923, 52.643351],
            [35.003767, 52.660101],
            [35.039507, 52.659344],
            [35.083719, 52.677103],
            [35.079504, 52.68339],
            [35.109214, 52.70427],
            [35.09081, 52.715167],
            [35.036945, 52.695448],
            [35.015803, 52.709161],
            [35.016686, 52.719762],
            [35.037595, 52.733825],
            [35.041037, 52.756281],
            [35.006847, 52.765363],
            [34.98904, 52.756071],
            [34.974416, 52.767466],
            [34.976353, 52.77991],
            [34.960244, 52.780115],
            [34.925741, 52.800581],
            [34.906993, 52.778644],
            [34.880949, 52.800364],
            [34.861173, 52.808901],
            [34.831292, 52.846506],
            [34.825726, 52.865741],
            [34.861878, 52.868225],
            [34.850649, 52.884684],
            [34.879286, 52.886976],
            [34.868078, 52.900174],
            [34.852791, 52.898069],
            [34.875969, 52.916812],
            [34.912606, 52.919558],
            [34.902364, 52.929095],
            [34.954546, 52.926523],
            [34.965862, 52.913547],
            [34.960477, 52.948843],
            [34.995489, 52.948952],
            [34.983277, 52.966242],
            [35.009968, 52.965053],
            [35.014615, 52.954247],
            [35.038173, 52.951114],
            [35.034823, 52.96192],
            [35.052545, 52.966675],
            [35.090962, 52.949818],
            [35.130944, 52.965379],
            [35.155583, 52.957383],
            [35.144872, 52.97133],
            [35.152291, 52.987177],
            [35.176485, 53.010769],
            [35.199766, 53.009654],
            [35.240067, 52.994564],
            [35.256169, 53.006411],
            [35.255159, 53.018224],
            [35.28358, 53.019737],
            [35.292656, 53.041674],
            [35.270764, 53.047156],
            [35.325127, 53.079388],
            [35.276879, 53.081587],
            [35.269924, 53.108502],
            [35.240672, 53.123099],
            [35.216698, 53.127218],
            [35.174634, 53.118336],
            [35.162496, 53.141999],
            [35.127382, 53.139148],
            [35.121884, 53.15423],
            [35.129952, 53.180396],
            [35.11377, 53.184664],
            [35.135351, 53.197978],
            [35.10567, 53.227345],
            [35.10801, 53.25686],
            [35.130986, 53.256716],
            [35.141099, 53.279123],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5514",
      properties: {
        name: "Пензенская область",
        density: 0,
        path: "/world/Russia/Пензенская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.144531, 53.802341],
            [42.136515, 53.82247],
            [42.205091, 53.818646],
            [42.200068, 53.824626],
            [42.236265, 53.836824],
            [42.258911, 53.831881],
            [42.35023, 53.853669],
            [42.357451, 53.826181],
            [42.322876, 53.813167],
            [42.311894, 53.799125],
            [42.371838, 53.795215],
            [42.387662, 53.805338],
            [42.409472, 53.798938],
            [42.417906, 53.781768],
            [42.412782, 53.763974],
            [42.45578, 53.773176],
            [42.485899, 53.760749],
            [42.474226, 53.75431],
            [42.498596, 53.734776],
            [42.562126, 53.733439],
            [42.522144, 53.746636],
            [42.52257, 53.757085],
            [42.505803, 53.760496],
            [42.494995, 53.778096],
            [42.51138, 53.81058],
            [42.526479, 53.810173],
            [42.555995, 53.830742],
            [42.590057, 53.836764],
            [42.588943, 53.809468],
            [42.568867, 53.809872],
            [42.556252, 53.800501],
            [42.558398, 53.779728],
            [42.534973, 53.76502],
            [42.547844, 53.749295],
            [42.570068, 53.749389],
            [42.58886, 53.77115],
            [42.601127, 53.770893],
            [42.6291, 53.80906],
            [42.648575, 53.817569],
            [42.665651, 53.801308],
            [42.698857, 53.81519],
            [42.739018, 53.81679],
            [42.707692, 53.779967],
            [42.702032, 53.74944],
            [42.727085, 53.739494],
            [42.753254, 53.764553],
            [42.745793, 53.784582],
            [42.759604, 53.825475],
            [42.790234, 53.807437],
            [42.837598, 53.814232],
            [42.896283, 53.794468],
            [42.931977, 53.795533],
            [42.972735, 53.810986],
            [42.968533, 53.822181],
            [43.02353, 53.82983],
            [43.027393, 53.823955],
            [43.062055, 53.819145],
            [43.023358, 53.814178],
            [43.072694, 53.810782],
            [43.078358, 53.83849],
            [43.045323, 53.840055],
            [43.046951, 53.856356],
            [43.09672, 53.87381],
            [43.106242, 53.853976],
            [43.138591, 53.825069],
            [43.156781, 53.820004],
            [43.180034, 53.834538],
            [43.164758, 53.877757],
            [43.169308, 53.886253],
            [43.130696, 53.896469],
            [43.10762, 53.894194],
            [43.100064, 53.928203],
            [43.129926, 53.940028],
            [43.126751, 53.977284],
            [43.182778, 53.968601],
            [43.190184, 53.993583],
            [43.240036, 53.989494],
            [43.238752, 53.995748],
            [43.288085, 53.999832],
            [43.289889, 54.009061],
            [43.315971, 54.024188],
            [43.336877, 54.012186],
            [43.347603, 53.997465],
            [43.397371, 53.997515],
            [43.402086, 53.985256],
            [43.458459, 53.978798],
            [43.464293, 53.987579],
            [43.494243, 54.002202],
            [43.514491, 53.996455],
            [43.535252, 54.014353],
            [43.553872, 54.006339],
            [43.580126, 53.981116],
            [43.615648, 53.975968],
            [43.61522, 53.964411],
            [43.632983, 53.964967],
            [43.686692, 53.95437],
            [43.69107, 53.950276],
            [43.745811, 53.959363],
            [43.748472, 53.967391],
            [43.766749, 53.953105],
            [43.846455, 53.924113],
            [43.865764, 53.895657],
            [43.923849, 53.87381],
            [43.933371, 53.85666],
            [43.904373, 53.846232],
            [43.917324, 53.839855],
            [43.901196, 53.834489],
            [43.906434, 53.81661],
            [43.859671, 53.805976],
            [43.883263, 53.801055],
            [43.895878, 53.786458],
            [43.916213, 53.786866],
            [43.927452, 53.770688],
            [43.958426, 53.76435],
            [43.982364, 53.740151],
            [43.995839, 53.7503],
            [44.039338, 53.744668],
            [44.052551, 53.731831],
            [44.075034, 53.739089],
            [44.08816, 53.725382],
            [44.103606, 53.734776],
            [44.119049, 53.730156],
            [44.122309, 53.710914],
            [44.11407, 53.692526],
            [44.144189, 53.691762],
            [44.148737, 53.704363],
            [44.195328, 53.703245],
            [44.202533, 53.693845],
            [44.295369, 53.690848],
            [44.311932, 53.692218],
            [44.317853, 53.706852],
            [44.355774, 53.705526],
            [44.371566, 53.721574],
            [44.381859, 53.704461],
            [44.371911, 53.694049],
            [44.414121, 53.697857],
            [44.458908, 53.663962],
            [44.504989, 53.665942],
            [44.511503, 53.675553],
            [44.53682, 53.677686],
            [44.54162, 53.670367],
            [44.514087, 53.66086],
            [44.494346, 53.660402],
            [44.505153, 53.649065],
            [44.537161, 53.655571],
            [44.532531, 53.660913],
            [44.586661, 53.666202],
            [44.587957, 53.685924],
            [44.620134, 53.683381],
            [44.62459, 53.688869],
            [44.656429, 53.683278],
            [44.715204, 53.680125],
            [44.731592, 53.700704],
            [44.725927, 53.710556],
            [44.700528, 53.723098],
            [44.689718, 53.750708],
            [44.638576, 53.755325],
            [44.636261, 53.770538],
            [44.618507, 53.769421],
            [44.635493, 53.785296],
            [44.64982, 53.778651],
            [44.669206, 53.782048],
            [44.654191, 53.799584],
            [44.603824, 53.798975],
            [44.6004, 53.839449],
            [44.590701, 53.844816],
            [44.613269, 53.857618],
            [44.607604, 53.881195],
            [44.630773, 53.891714],
            [44.651278, 53.886406],
            [44.639436, 53.904151],
            [44.660284, 53.904255],
            [44.685678, 53.914915],
            [44.695811, 53.900208],
            [44.723096, 53.886406],
            [44.724896, 53.897021],
            [44.743429, 53.907334],
            [44.721466, 53.91618],
            [44.72678, 53.92315],
            [44.750813, 53.920275],
            [44.803149, 53.92669],
            [44.817476, 53.933814],
            [44.831459, 53.963251],
            [44.856686, 53.95962],
            [44.871526, 53.968503],
            [44.903621, 53.959969],
            [44.932706, 53.960478],
            [44.923954, 53.946844],
            [44.960246, 53.964313],
            [44.984281, 53.957396],
            [44.988735, 53.9684],
            [45.007186, 53.971125],
            [45.007268, 53.959113],
            [45.07085, 53.960576],
            [45.115982, 53.946189],
            [45.132713, 53.967644],
            [45.144556, 53.964111],
            [45.176467, 53.971527],
            [45.18934, 53.958155],
            [45.21225, 53.956692],
            [45.270686, 53.916688],
            [45.282606, 53.9181],
            [45.295475, 53.90253],
            [45.298482, 53.871534],
            [45.35717, 53.870064],
            [45.362234, 53.881299],
            [45.415435, 53.89788],
            [45.472745, 53.900158],
            [45.485437, 53.890096],
            [45.541814, 53.895003],
            [45.517101, 53.917897],
            [45.540873, 53.92937],
            [45.564036, 53.929465],
            [45.633792, 53.913755],
            [45.661675, 53.918154],
            [45.704491, 53.895757],
            [45.73624, 53.905925],
            [45.710335, 53.932398],
            [45.718741, 53.946692],
            [45.737698, 53.94159],
            [45.780173, 53.965421],
            [45.813633, 53.975716],
            [45.830398, 53.966588],
            [45.850307, 53.979291],
            [45.857451, 53.994651],
            [45.87269, 53.981194],
            [45.905733, 53.984423],
            [45.892731, 54.007081],
            [45.928127, 54.005476],
            [45.924477, 53.985742],
            [45.947012, 54.027264],
            [45.97641, 54.019802],
            [45.973759, 54.003427],
            [45.95926, 53.995986],
            [45.983434, 53.995544],
            [45.976949, 53.98513],
            [46.003857, 53.980768],
            [46.030821, 53.984404],
            [46.030515, 53.97682],
            [46.078067, 53.966138],
            [46.107735, 53.947141],
            [46.100432, 53.932647],
            [46.124426, 53.921518],
            [46.124903, 53.934978],
            [46.148233, 53.935542],
            [46.150167, 53.926036],
            [46.185294, 53.911999],
            [46.177834, 53.901473],
            [46.186894, 53.890782],
            [46.217747, 53.885096],
            [46.192932, 53.881457],
            [46.174146, 53.857007],
            [46.173285, 53.818819],
            [46.16317, 53.807983],
            [46.17272, 53.796735],
            [46.201384, 53.800393],
            [46.216315, 53.793677],
            [46.250099, 53.794973],
            [46.279374, 53.790302],
            [46.287422, 53.779221],
            [46.256721, 53.771453],
            [46.246873, 53.754909],
            [46.276153, 53.740858],
            [46.303347, 53.758559],
            [46.331165, 53.741447],
            [46.328083, 53.725346],
            [46.376481, 53.670359],
            [46.438391, 53.648993],
            [46.479751, 53.617991],
            [46.471303, 53.609069],
            [46.444955, 53.603505],
            [46.449633, 53.581845],
            [46.450697, 53.539561],
            [46.467044, 53.52291],
            [46.455797, 53.512958],
            [46.505246, 53.481495],
            [46.521321, 53.46439],
            [46.532285, 53.48297],
            [46.555964, 53.486761],
            [46.552762, 53.477997],
            [46.568175, 53.462485],
            [46.589637, 53.467599],
            [46.607771, 53.455752],
            [46.620054, 53.467599],
            [46.649753, 53.468775],
            [46.669933, 53.482395],
            [46.681045, 53.475366],
            [46.659541, 53.441857],
            [46.678696, 53.428115],
            [46.703585, 53.434995],
            [46.720084, 53.41511],
            [46.746153, 53.41597],
            [46.758187, 53.390948],
            [46.768237, 53.395788],
            [46.791834, 53.372678],
            [46.817088, 53.366982],
            [46.908509, 53.36347],
            [46.898875, 53.278757],
            [46.954466, 53.284601],
            [46.937642, 53.275552],
            [46.942159, 53.250974],
            [46.903859, 53.255807],
            [46.871097, 53.238552],
            [46.874847, 53.215738],
            [46.862212, 53.209943],
            [46.863107, 53.177165],
            [46.876104, 53.165773],
            [46.930099, 53.171332],
            [46.947901, 53.128992],
            [46.969899, 53.129485],
            [46.97298, 53.120431],
            [46.946652, 53.112531],
            [46.956722, 53.097728],
            [46.911449, 53.090428],
            [46.910137, 53.082676],
            [46.938684, 53.08047],
            [46.933116, 53.071272],
            [46.923738, 52.996389],
            [46.916406, 52.996374],
            [46.911345, 52.967432],
            [46.926232, 52.966235],
            [46.917765, 52.919133],
            [46.927109, 52.918984],
            [46.892765, 52.893382],
            [46.896265, 52.875997],
            [46.909558, 52.875679],
            [46.925652, 52.854482],
            [46.934715, 52.828303],
            [46.97028, 52.80898],
            [46.966177, 52.78689],
            [46.969109, 52.759405],
            [46.945772, 52.752972],
            [46.926254, 52.739942],
            [46.937198, 52.738486],
            [46.940143, 52.721964],
            [46.857917, 52.710086],
            [46.845057, 52.691247],
            [46.817569, 52.697949],
            [46.814921, 52.680551],
            [46.799415, 52.67002],
            [46.805864, 52.662709],
            [46.798117, 52.644268],
            [46.801859, 52.621141],
            [46.743722, 52.611189],
            [46.702319, 52.635175],
            [46.680429, 52.64265],
            [46.659886, 52.664758],
            [46.609288, 52.662354],
            [46.597012, 52.694029],
            [46.593834, 52.721665],
            [46.577626, 52.725591],
            [46.571619, 52.702207],
            [46.539042, 52.688495],
            [46.522894, 52.694115],
            [46.489002, 52.681361],
            [46.45509, 52.678501],
            [46.432712, 52.684914],
            [46.436128, 52.669456],
            [46.417894, 52.664397],
            [46.412709, 52.675961],
            [46.37749, 52.675505],
            [46.325899, 52.680843],
            [46.289592, 52.668342],
            [46.261472, 52.650081],
            [46.261215, 52.626334],
            [46.268342, 52.610239],
            [46.210562, 52.614092],
            [46.198966, 52.601671],
            [46.178785, 52.599827],
            [46.166902, 52.607934],
            [46.140672, 52.587105],
            [46.140302, 52.549581],
            [46.125701, 52.546721],
            [46.136282, 52.526805],
            [46.119078, 52.523341],
            [46.122478, 52.544849],
            [46.084433, 52.555158],
            [46.081744, 52.538846],
            [46.065976, 52.528585],
            [46.054819, 52.533722],
            [46.028676, 52.514732],
            [46.004107, 52.510991],
            [45.983535, 52.474385],
            [46.012701, 52.454175],
            [45.996931, 52.447368],
            [45.977432, 52.423126],
            [45.960923, 52.418503],
            [45.961039, 52.396793],
            [45.914225, 52.405492],
            [45.908432, 52.415284],
            [45.850129, 52.412961],
            [45.835325, 52.431397],
            [45.823238, 52.420537],
            [45.794148, 52.41629],
            [45.766139, 52.427567],
            [45.770688, 52.457491],
            [45.732676, 52.45693],
            [45.698533, 52.440915],
            [45.700307, 52.471891],
            [45.667805, 52.474003],
            [45.654974, 52.485144],
            [45.652083, 52.508327],
            [45.640552, 52.507726],
            [45.62145, 52.477667],
            [45.589871, 52.469449],
            [45.600943, 52.452551],
            [45.583588, 52.453142],
            [45.576436, 52.464282],
            [45.552133, 52.465001],
            [45.524166, 52.47653],
            [45.524668, 52.459737],
            [45.513653, 52.425543],
            [45.4927, 52.426717],
            [45.489449, 52.413239],
            [45.468635, 52.426798],
            [45.448791, 52.424964],
            [45.442501, 52.400184],
            [45.429318, 52.38629],
            [45.409938, 52.402415],
            [45.384038, 52.410578],
            [45.359899, 52.386716],
            [45.352976, 52.396469],
            [45.317327, 52.396867],
            [45.299771, 52.391998],
            [45.295126, 52.376046],
            [45.267626, 52.390862],
            [45.243229, 52.385016],
            [45.233534, 52.375677],
            [45.207339, 52.384094],
            [45.19833, 52.404832],
            [45.17267, 52.409204],
            [45.174283, 52.418244],
            [45.148294, 52.418508],
            [45.093912, 52.400002],
            [45.088551, 52.379492],
            [45.068893, 52.38028],
            [45.061078, 52.36153],
            [45.085446, 52.338755],
            [45.088581, 52.313289],
            [45.059969, 52.306116],
            [45.027721, 52.316085],
            [44.983033, 52.33875],
            [44.98313, 52.365641],
            [44.95333, 52.365614],
            [44.952157, 52.356257],
            [44.892952, 52.356528],
            [44.8917, 52.416733],
            [44.8751, 52.424649],
            [44.853116, 52.447721],
            [44.79549, 52.438479],
            [44.783179, 52.45117],
            [44.682972, 52.463814],
            [44.665932, 52.460764],
            [44.613324, 52.480727],
            [44.620977, 52.489249],
            [44.591168, 52.509917],
            [44.570131, 52.505648],
            [44.532591, 52.532595],
            [44.507483, 52.523551],
            [44.503006, 52.505867],
            [44.520512, 52.502196],
            [44.506254, 52.485864],
            [44.520031, 52.481625],
            [44.535785, 52.450395],
            [44.506151, 52.440331],
            [44.497208, 52.425548],
            [44.47943, 52.429486],
            [44.461003, 52.413593],
            [44.466022, 52.428167],
            [44.448574, 52.431995],
            [44.425629, 52.42633],
            [44.401212, 52.429344],
            [44.39803, 52.413792],
            [44.381569, 52.413844],
            [44.374613, 52.396503],
            [44.372671, 52.366274],
            [44.35696, 52.3482],
            [44.356178, 52.335326],
            [44.3389, 52.337068],
            [44.314552, 52.306192],
            [44.334083, 52.308371],
            [44.347376, 52.29285],
            [44.332756, 52.294122],
            [44.321377, 52.278928],
            [44.258845, 52.314328],
            [44.218318, 52.321992],
            [44.196805, 52.347351],
            [44.177899, 52.33266],
            [44.156717, 52.306612],
            [44.114037, 52.330932],
            [44.095208, 52.32651],
            [44.064811, 52.352987],
            [44.028928, 52.330323],
            [44.001124, 52.350691],
            [43.997444, 52.34338],
            [43.965897, 52.360544],
            [43.938736, 52.380685],
            [43.891909, 52.405085],
            [43.864351, 52.380937],
            [43.797414, 52.418835],
            [43.755197, 52.425884],
            [43.747019, 52.432056],
            [43.709458, 52.418702],
            [43.669829, 52.393654],
            [43.597952, 52.400061],
            [43.584709, 52.397404],
            [43.56725, 52.407008],
            [43.543858, 52.398231],
            [43.510974, 52.401945],
            [43.488266, 52.379427],
            [43.461067, 52.375678],
            [43.414062, 52.382003],
            [43.374095, 52.371165],
            [43.366971, 52.353769],
            [43.336238, 52.373748],
            [43.321052, 52.403211],
            [43.322433, 52.420056],
            [43.283355, 52.440201],
            [43.245243, 52.426943],
            [43.240315, 52.436983],
            [43.178883, 52.452091],
            [43.157808, 52.437517],
            [43.142162, 52.438962],
            [43.117153, 52.37877],
            [43.097226, 52.382538],
            [43.087174, 52.372175],
            [43.039706, 52.370635],
            [43.038936, 52.3879],
            [43.072117, 52.391566],
            [43.066872, 52.402483],
            [43.081329, 52.404693],
            [43.06728, 52.43095],
            [43.048908, 52.440327],
            [43.024048, 52.486763],
            [43.057376, 52.49191],
            [43.121771, 52.510893],
            [43.061915, 52.517591],
            [43.04917, 52.530761],
            [43.058242, 52.559391],
            [43.083488, 52.566516],
            [43.08806, 52.574568],
            [43.11228, 52.570038],
            [43.220343, 52.636794],
            [43.248087, 52.63634],
            [43.193604, 52.717261],
            [43.11827, 52.803298],
            [43.10569, 52.803579],
            [43.009742, 52.870166],
            [42.990725, 52.89326],
            [42.980534, 52.89314],
            [42.954222, 52.911944],
            [42.957555, 52.930946],
            [42.93265, 52.942773],
            [42.810405, 52.98805],
            [42.754868, 53.018262],
            [42.708381, 53.060523],
            [42.64225, 53.111768],
            [42.627608, 53.128283],
            [42.547553, 53.122359],
            [42.544187, 53.135306],
            [42.526065, 53.144476],
            [42.509028, 53.17019],
            [42.571364, 53.179832],
            [42.557809, 53.195599],
            [42.494068, 53.241357],
            [42.420968, 53.271701],
            [42.43218, 53.283097],
            [42.419006, 53.287923],
            [42.412166, 53.33802],
            [42.379592, 53.327366],
            [42.323499, 53.347962],
            [42.291964, 53.372906],
            [42.28219, 53.364336],
            [42.272662, 53.373222],
            [42.240536, 53.357158],
            [42.246815, 53.392787],
            [42.259682, 53.3982],
            [42.259801, 53.414553],
            [42.273684, 53.412219],
            [42.285082, 53.428585],
            [42.299252, 53.432534],
            [42.295901, 53.452083],
            [42.271082, 53.45824],
            [42.25675, 53.470193],
            [42.255598, 53.484075],
            [42.227419, 53.479705],
            [42.225196, 53.489027],
            [42.197589, 53.496055],
            [42.15583, 53.49358],
            [42.141635, 53.499847],
            [42.158169, 53.507449],
            [42.171746, 53.532993],
            [42.109533, 53.515339],
            [42.139582, 53.567753],
            [42.121927, 53.569092],
            [42.127316, 53.652501],
            [42.117835, 53.671193],
            [42.125589, 53.69293],
            [42.143951, 53.712079],
            [42.089521, 53.708587],
            [42.081739, 53.748005],
            [42.110404, 53.75708],
            [42.106717, 53.768021],
            [42.154048, 53.77081],
            [42.144531, 53.802341],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5509",
      properties: {
        name: "Пермский край",
        density: 0,
        path: "/world/Russia/Пермский край",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [59.348463, 61.66377],
            [59.357648, 61.634347],
            [59.406496, 61.616265],
            [59.413554, 61.5973],
            [59.402087, 61.58936],
            [59.413336, 61.55143],
            [59.402862, 61.532575],
            [59.415102, 61.521549],
            [59.446197, 61.510964],
            [59.439031, 61.486375],
            [59.401653, 61.46366],
            [59.42933, 61.441939],
            [59.44521, 61.415145],
            [59.425473, 61.39673],
            [59.369239, 61.392871],
            [59.312123, 61.354388],
            [59.320615, 61.333548],
            [59.28467, 61.316236],
            [59.257545, 61.313038],
            [59.261516, 61.275659],
            [59.252806, 61.260553],
            [59.271884, 61.228908],
            [59.284013, 61.228136],
            [59.292176, 61.161206],
            [59.315994, 61.151944],
            [59.344114, 61.104862],
            [59.340035, 61.078068],
            [59.358671, 61.06627],
            [59.3418, 61.058221],
            [59.362421, 61.043556],
            [59.374001, 60.991071],
            [59.369371, 60.968356],
            [59.395064, 60.942114],
            [59.437075, 60.946415],
            [59.475006, 60.939248],
            [59.476994, 60.88974],
            [59.469608, 60.847729],
            [59.482731, 60.831521],
            [59.450206, 60.772971],
            [59.425948, 60.750256],
            [59.444805, 60.733386],
            [59.432566, 60.721808],
            [59.417351, 60.685752],
            [59.423639, 60.635472],
            [59.409195, 60.622681],
            [59.405998, 60.601951],
            [59.394421, 60.596879],
            [59.361234, 60.543732],
            [59.355832, 60.521459],
            [59.284051, 60.499405],
            [59.243806, 60.470295],
            [59.218446, 60.44328],
            [59.225504, 60.429387],
            [59.207201, 60.406783],
            [59.21073, 60.39741],
            [59.175558, 60.349776],
            [59.149756, 60.341837],
            [59.143803, 60.326179],
            [59.179309, 60.315153],
            [59.156264, 60.290344],
            [59.160896, 60.278877],
            [59.14965, 60.257375],
            [59.155605, 60.249436],
            [59.137965, 60.205882],
            [59.103343, 60.17997],
            [59.0889, 60.148985],
            [59.089342, 60.1298],
            [59.068392, 60.119214],
            [59.07567, 60.102344],
            [59.029805, 60.015235],
            [58.984157, 59.985133],
            [58.963427, 59.977965],
            [58.980078, 59.967711],
            [58.978314, 59.955802],
            [58.988792, 59.907617],
            [58.839456, 59.931982],
            [58.800246, 59.843002],
            [58.693449, 59.85661],
            [58.602325, 59.693481],
            [58.505842, 59.71013],
            [58.479048, 59.711673],
            [58.362935, 59.46464],
            [58.416209, 59.459389],
            [58.458441, 59.463359],
            [58.459103, 59.455641],
            [58.524931, 59.435023],
            [58.54037, 59.407126],
            [58.598702, 59.352767],
            [58.624727, 59.297966],
            [58.681216, 59.26849],
            [58.789187, 59.239542],
            [58.95987, 59.234125],
            [58.989779, 59.22277],
            [58.994559, 59.208547],
            [59.106479, 59.17139],
            [59.151908, 59.162459],
            [59.196787, 59.147133],
            [59.092924, 59.000481],
            [59.20694, 58.953952],
            [59.1689, 58.910287],
            [59.080028, 58.906096],
            [59.087528, 58.843907],
            [59.081684, 58.843576],
            [59.105835, 58.77378],
            [59.145751, 58.774993],
            [59.153582, 58.717546],
            [59.228562, 58.719862],
            [59.267596, 58.712696],
            [59.286231, 58.700304],
            [59.317767, 58.694393],
            [59.3439, 58.696929],
            [59.350846, 58.706853],
            [59.398591, 58.708066],
            [59.415573, 58.662859],
            [59.39606, 58.585343],
            [59.453623, 58.478057],
            [59.38493, 58.429651],
            [59.319323, 58.426342],
            [59.287457, 58.419285],
            [59.27577, 58.399548],
            [59.277755, 58.377164],
            [59.255373, 58.35059],
            [59.264636, 58.336697],
            [59.218876, 58.324788],
            [59.201675, 58.327104],
            [59.200353, 58.310233],
            [59.18624, 58.278588],
            [59.105526, 58.288069],
            [59.137063, 58.259842],
            [58.924693, 58.28101],
            [58.908816, 58.241315],
            [58.874304, 58.245395],
            [58.861294, 58.21011],
            [58.816306, 58.214851],
            [58.805502, 58.176148],
            [58.778046, 58.179125],
            [58.762831, 58.141415],
            [58.738242, 58.144281],
            [58.688293, 58.115502],
            [58.663264, 58.105247],
            [58.676717, 58.069522],
            [58.650475, 58.050005],
            [58.625445, 58.058715],
            [58.586743, 58.041514],
            [58.597439, 58.021005],
            [58.638569, 58.004797],
            [58.642168, 57.975416],
            [58.662396, 57.94694],
            [58.649257, 57.943832],
            [58.668554, 57.933306],
            [58.686603, 57.913182],
            [58.686545, 57.899422],
            [58.702668, 57.885296],
            [58.744246, 57.892697],
            [58.728266, 57.852053],
            [58.863791, 57.838097],
            [58.83132, 57.740102],
            [58.847335, 57.725834],
            [58.805427, 57.729091],
            [58.744342, 57.690972],
            [58.679547, 57.655736],
            [58.594911, 57.597965],
            [58.537085, 57.564072],
            [58.517058, 57.562257],
            [58.510638, 57.574219],
            [58.469498, 57.576165],
            [58.460452, 57.582358],
            [58.465331, 57.620189],
            [58.449041, 57.650015],
            [58.428456, 57.660667],
            [58.452214, 57.664705],
            [58.446871, 57.673931],
            [58.387239, 57.673989],
            [58.276121, 57.677705],
            [58.250693, 57.673932],
            [58.238149, 57.682415],
            [58.15942, 57.679759],
            [58.154722, 57.695192],
            [58.094477, 57.658863],
            [58.107606, 57.651305],
            [58.109025, 57.630595],
            [58.092918, 57.619945],
            [58.119009, 57.602701],
            [58.088728, 57.588975],
            [58.064248, 57.595706],
            [58.063776, 57.611709],
            [58.030354, 57.611408],
            [58.028868, 57.566948],
            [57.985679, 57.554646],
            [57.985624, 57.535398],
            [57.968976, 57.489028],
            [58.012026, 57.484537],
            [57.940486, 57.31643],
            [57.979685, 57.312939],
            [57.940085, 57.296469],
            [57.955704, 57.29033],
            [57.98373, 57.293403],
            [58.02596, 57.274177],
            [58.010854, 57.265621],
            [58.016394, 57.25284],
            [58.047489, 57.248701],
            [58.028881, 57.237622],
            [58.017442, 57.213096],
            [57.99185, 57.22149],
            [57.98641, 57.21175],
            [57.998718, 57.19],
            [58.025352, 57.18495],
            [58.035169, 57.197225],
            [58.047652, 57.193216],
            [58.032166, 57.150674],
            [58.124395, 57.142605],
            [58.117442, 57.12396],
            [58.103573, 57.128724],
            [58.061359, 57.125685],
            [58.039704, 57.114243],
            [58.09072, 57.100279],
            [58.069369, 57.049412],
            [58.059225, 57.057241],
            [58.037282, 57.05294],
            [58.026036, 57.03629],
            [57.970683, 57.052278],
            [58.016111, 57.057461],
            [58.021624, 57.078962],
            [57.980275, 57.0783],
            [57.965941, 57.070912],
            [57.943116, 57.072786],
            [57.945763, 57.056247],
            [57.909045, 57.061429],
            [57.89449, 57.052608],
            [57.872988, 57.055695],
            [57.843328, 57.032208],
            [57.848842, 57.017874],
            [57.839691, 57.005965],
            [57.823702, 57.007068],
            [57.839912, 56.984905],
            [57.826571, 56.962411],
            [57.80959, 56.966932],
            [57.816977, 56.977517],
            [57.80562, 56.986779],
            [57.780921, 56.990197],
            [57.774857, 56.962852],
            [57.751041, 56.944878],
            [57.706604, 56.957668],
            [57.661506, 56.944216],
            [57.676062, 56.920509],
            [57.665146, 56.910806],
            [57.640337, 56.908711],
            [57.636698, 56.917311],
            [57.598657, 56.916208],
            [57.578258, 56.910474],
            [57.572525, 56.894596],
            [57.466781, 56.912899],
            [57.430833, 56.955129],
            [57.389154, 56.937376],
            [57.371511, 56.955349],
            [57.359934, 56.937376],
            [57.315829, 56.928444],
            [57.297195, 56.91036],
            [57.268085, 56.901759],
            [57.265219, 56.889741],
            [57.248349, 56.884779],
            [57.229384, 56.856],
            [57.254415, 56.840563],
            [57.271947, 56.838248],
            [57.2768, 56.817298],
            [57.270625, 56.802522],
            [57.305029, 56.775177],
            [57.323223, 56.772421],
            [57.347261, 56.748935],
            [57.306575, 56.718061],
            [57.317492, 56.705601],
            [57.335244, 56.703617],
            [57.335024, 56.687188],
            [57.367994, 56.680462],
            [57.395008, 56.683991],
            [57.438894, 56.677265],
            [57.39909, 56.640547],
            [57.346936, 56.621581],
            [57.338556, 56.609783],
            [57.371084, 56.606255],
            [57.387514, 56.598095],
            [57.418167, 56.609232],
            [57.418168, 56.580454],
            [57.408575, 56.581666],
            [57.388288, 56.531496],
            [57.370646, 56.527306],
            [57.35058, 56.467764],
            [57.357308, 56.440859],
            [57.323348, 56.394217],
            [57.312763, 56.390579],
            [57.32809, 56.374039],
            [57.371424, 56.379994],
            [57.388736, 56.372496],
            [57.410728, 56.346032],
            [57.406237, 56.34266],
            [57.390734, 56.331497],
            [57.348693, 56.331209],
            [57.353176, 56.320814],
            [57.324945, 56.314887],
            [57.297953, 56.32104],
            [57.306645, 56.310392],
            [57.285038, 56.304739],
            [57.298041, 56.272278],
            [57.248166, 56.271258],
            [57.219294, 56.249383],
            [57.195286, 56.250602],
            [57.216173, 56.208547],
            [57.200482, 56.19639],
            [57.227381, 56.199451],
            [57.23492, 56.19177],
            [57.204048, 56.188198],
            [57.203793, 56.169341],
            [57.185657, 56.171752],
            [57.167776, 56.162307],
            [57.144839, 56.165789],
            [57.114189, 56.159438],
            [57.064534, 56.159073],
            [57.044666, 56.150958],
            [57.045964, 56.126737],
            [57.007201, 56.136611],
            [56.970055, 56.125026],
            [56.921333, 56.103883],
            [56.910454, 56.110593],
            [56.883149, 56.110432],
            [56.86701, 56.147413],
            [56.85044, 56.166111],
            [56.783004, 56.164346],
            [56.768379, 56.193539],
            [56.746621, 56.209651],
            [56.758236, 56.219029],
            [56.725129, 56.230841],
            [56.652679, 56.232966],
            [56.651969, 56.25574],
            [56.6209, 56.264502],
            [56.594748, 56.262981],
            [56.597125, 56.274223],
            [56.578681, 56.2905],
            [56.538582, 56.313044],
            [56.506254, 56.344041],
            [56.535034, 56.350188],
            [56.532621, 56.361487],
            [56.572244, 56.389021],
            [56.532631, 56.397031],
            [56.507644, 56.376753],
            [56.483962, 56.388626],
            [56.460827, 56.365593],
            [56.40723, 56.40379],
            [56.362071, 56.388603],
            [56.377829, 56.365215],
            [56.324682, 56.365207],
            [56.321917, 56.341382],
            [56.303726, 56.333371],
            [56.283691, 56.314425],
            [56.285953, 56.304422],
            [56.267411, 56.305743],
            [56.235373, 56.320566],
            [56.218417, 56.316403],
            [56.162298, 56.317164],
            [56.122626, 56.310245],
            [56.124858, 56.30038],
            [56.081113, 56.290316],
            [56.051117, 56.314239],
            [56.042079, 56.339931],
            [56.017745, 56.339763],
            [55.952536, 56.373755],
            [55.941528, 56.388746],
            [55.945286, 56.408605],
            [55.935976, 56.413169],
            [55.919867, 56.449547],
            [55.908537, 56.456142],
            [55.879236, 56.456682],
            [55.867046, 56.442979],
            [55.874755, 56.432733],
            [55.83681, 56.42065],
            [55.789442, 56.435629],
            [55.774309, 56.445925],
            [55.739046, 56.430493],
            [55.707318, 56.435715],
            [55.691955, 56.408486],
            [55.633282, 56.395796],
            [55.627859, 56.400694],
            [55.522033, 56.380746],
            [55.506358, 56.359249],
            [55.459557, 56.331608],
            [55.42206, 56.331012],
            [55.38524, 56.317094],
            [55.368311, 56.323734],
            [55.331195, 56.316454],
            [55.280699, 56.320966],
            [55.238503, 56.344644],
            [55.206916, 56.37202],
            [55.187945, 56.358086],
            [55.201268, 56.324517],
            [55.196512, 56.307451],
            [55.150002, 56.307456],
            [55.136998, 56.341632],
            [55.116014, 56.3494],
            [55.039763, 56.344946],
            [55.021135, 56.340348],
            [55.021814, 56.324292],
            [54.989742, 56.325124],
            [54.978383, 56.334995],
            [54.943592, 56.337213],
            [54.937518, 56.370654],
            [54.929619, 56.380248],
            [54.899297, 56.390083],
            [54.867232, 56.393269],
            [54.863153, 56.402275],
            [54.788421, 56.426413],
            [54.787349, 56.436567],
            [54.733821, 56.430409],
            [54.707192, 56.464125],
            [54.679644, 56.4862],
            [54.682184, 56.501397],
            [54.646429, 56.500862],
            [54.630839, 56.509492],
            [54.61472, 56.504647],
            [54.603274, 56.513835],
            [54.60915, 56.531315],
            [54.577733, 56.534643],
            [54.5398, 56.5114],
            [54.5392, 56.4953],
            [54.5248, 56.468],
            [54.5025, 56.4604],
            [54.4937, 56.4467],
            [54.4979, 56.4325],
            [54.4864, 56.4107],
            [54.4627, 56.4003],
            [54.4466, 56.3748],
            [54.4575, 56.3571],
            [54.444573, 56.34606],
            [54.418623, 56.362086],
            [54.410856, 56.376841],
            [54.377579, 56.378702],
            [54.3468, 56.401529],
            [54.338456, 56.430565],
            [54.344629, 56.456246],
            [54.329599, 56.464091],
            [54.328795, 56.482895],
            [54.244086, 56.458165],
            [54.218731, 56.469356],
            [54.205898, 56.498706],
            [54.190389, 56.506259],
            [54.191302, 56.534463],
            [54.141266, 56.532146],
            [54.10796, 56.541972],
            [54.103281, 56.579168],
            [54.070281, 56.577721],
            [54.054993, 56.592409],
            [54.041618, 56.579077],
            [54.033573, 56.589073],
            [54.064054, 56.624547],
            [54.082409, 56.635401],
            [54.050387, 56.638054],
            [53.962696, 56.601512],
            [53.876681, 56.597965],
            [53.864883, 56.588813],
            [53.84102, 56.59672],
            [53.838854, 56.625688],
            [53.865246, 56.620372],
            [53.84524, 56.643374],
            [53.851004, 56.654217],
            [53.884968, 56.662533],
            [53.901705, 56.679068],
            [53.884949, 56.678908],
            [53.885947, 56.717607],
            [53.849607, 56.718192],
            [53.854054, 56.758561],
            [53.82716, 56.759525],
            [53.806785, 56.779609],
            [53.831831, 56.797308],
            [53.796359, 56.800024],
            [53.803202, 56.817448],
            [53.827534, 56.820257],
            [53.873961, 56.815064],
            [53.905032, 56.782888],
            [53.943293, 56.761489],
            [53.978163, 56.752412],
            [54.014841, 56.752071],
            [54.03253, 56.757927],
            [54.061375, 56.778247],
            [54.05943, 56.789674],
            [54.08791, 56.81075],
            [54.097687, 56.843158],
            [54.107012, 56.854994],
            [54.101643, 56.889308],
            [54.124079, 56.891253],
            [54.150754, 56.922388],
            [54.148268, 56.94274],
            [54.153452, 56.980931],
            [54.225297, 56.992335],
            [54.257175, 56.980451],
            [54.303163, 56.97305],
            [54.36676, 56.981221],
            [54.434826, 57.035921],
            [54.393782, 57.041128],
            [54.393693, 57.058227],
            [54.347069, 57.07743],
            [54.311305, 57.0841],
            [54.314321, 57.091715],
            [54.289141, 57.114539],
            [54.319761, 57.110327],
            [54.331194, 57.130563],
            [54.322196, 57.140156],
            [54.344605, 57.158853],
            [54.330755, 57.165285],
            [54.340671, 57.175154],
            [54.319205, 57.18663],
            [54.327436, 57.21328],
            [54.340702, 57.223288],
            [54.299241, 57.239152],
            [54.29802, 57.245973],
            [54.328177, 57.267346],
            [54.310318, 57.283404],
            [54.311665, 57.305716],
            [54.217127, 57.306307],
            [54.205214, 57.29933],
            [54.185449, 57.315996],
            [54.141776, 57.315905],
            [54.124469, 57.346259],
            [54.149179, 57.371825],
            [54.119877, 57.377806],
            [54.142765, 57.38683],
            [54.154916, 57.401067],
            [54.206791, 57.399698],
            [54.218424, 57.410733],
            [54.292475, 57.432529],
            [54.273253, 57.440659],
            [54.273825, 57.461266],
            [54.18859, 57.468123],
            [54.174891, 57.479743],
            [54.143876, 57.483947],
            [54.17044, 57.50163],
            [54.173095, 57.521944],
            [54.142203, 57.532375],
            [54.113674, 57.535628],
            [54.107028, 57.528856],
            [54.063941, 57.529366],
            [54.059918, 57.514508],
            [54.031841, 57.52388],
            [54.051644, 57.528055],
            [54.044726, 57.544242],
            [54.058109, 57.544873],
            [54.043913, 57.570364],
            [54.02448, 57.556596],
            [53.991837, 57.559337],
            [53.992334, 57.588211],
            [54.01381, 57.596813],
            [54.040194, 57.586715],
            [54.059279, 57.594556],
            [54.051878, 57.6058],
            [54.088364, 57.612969],
            [54.103149, 57.606745],
            [54.126741, 57.615644],
            [54.098266, 57.635629],
            [54.050386, 57.643421],
            [54.063679, 57.645237],
            [54.056055, 57.680584],
            [54.088111, 57.686243],
            [54.12001, 57.706049],
            [54.153117, 57.701943],
            [54.155006, 57.71436],
            [54.111653, 57.74115],
            [54.135365, 57.739321],
            [54.138683, 57.756679],
            [54.155539, 57.774661],
            [54.119574, 57.809495],
            [54.137142, 57.815237],
            [54.132491, 57.842951],
            [54.141852, 57.855673],
            [54.130595, 57.875985],
            [54.092953, 57.90138],
            [54.085662, 57.918409],
            [54.102384, 57.925599],
            [54.118932, 57.947192],
            [54.136468, 57.957979],
            [54.07895, 57.991935],
            [54.071602, 58.009748],
            [53.989937, 58.069713],
            [53.94708, 58.084837],
            [53.93644, 58.106267],
            [53.948822, 58.123932],
            [53.93725, 58.134961],
            [53.945871, 58.146597],
            [53.925447, 58.156092],
            [53.885234, 58.164273],
            [53.908204, 58.179682],
            [53.881299, 58.200116],
            [53.82639, 58.210146],
            [53.785463, 58.236607],
            [53.831076, 58.262961],
            [53.833044, 58.270021],
            [53.865735, 58.284107],
            [53.88668, 58.31436],
            [53.854932, 58.342762],
            [53.828815, 58.350435],
            [53.845873, 58.354893],
            [53.840281, 58.365872],
            [53.811236, 58.377084],
            [53.812801, 58.391611],
            [53.791731, 58.401598],
            [53.772575, 58.420644],
            [53.785599, 58.455538],
            [53.783764, 58.483933],
            [53.756904, 58.517944],
            [53.737746, 58.566704],
            [53.702641, 58.602127],
            [53.749021, 58.634454],
            [53.76717, 58.656445],
            [53.736911, 58.678736],
            [53.739067, 58.691258],
            [53.77008, 58.704195],
            [53.780279, 58.722024],
            [53.831196, 58.728741],
            [53.843634, 58.739605],
            [53.905413, 58.754864],
            [53.931242, 58.773891],
            [53.914947, 58.785376],
            [53.926141, 58.794746],
            [53.826422, 58.828951],
            [53.796361, 58.861913],
            [53.799013, 58.89102],
            [53.773512, 58.918177],
            [53.753649, 58.964697],
            [53.770654, 59.00398],
            [53.790244, 59.017119],
            [53.841905, 59.024331],
            [53.87563, 59.044473],
            [53.87582, 59.067936],
            [53.902916, 59.086836],
            [53.870817, 59.120355],
            [53.819608, 59.111349],
            [53.77943, 59.138797],
            [53.725112, 59.181336],
            [53.682241, 59.151151],
            [53.72213, 59.117775],
            [53.491391, 59.14837],
            [53.415141, 59.147002],
            [53.384022, 59.152672],
            [53.326657, 59.206955],
            [53.307085, 59.235149],
            [53.231039, 59.322301],
            [53.222744, 59.358456],
            [53.290739, 59.41866],
            [53.357126, 59.461552],
            [53.384659, 59.540487],
            [53.441566, 59.679458],
            [53.449701, 59.702848],
            [53.597778, 59.687428],
            [53.6303, 59.742317],
            [53.685846, 60.001456],
            [53.641061, 60.090185],
            [53.625636, 60.114233],
            [53.577372, 60.144582],
            [53.563772, 60.158347],
            [53.487396, 60.18737],
            [53.475288, 60.204535],
            [53.436561, 60.223524],
            [53.421552, 60.224519],
            [53.37263, 60.155608],
            [53.075593, 60.150712],
            [52.920523, 60.153778],
            [52.459294, 60.184619],
            [52.44445, 60.184868],
            [52.317282, 60.227188],
            [52.368736, 60.358377],
            [52.33204, 60.362488],
            [52.328689, 60.375278],
            [52.348937, 60.438315],
            [52.12267, 60.464806],
            [52.152814, 60.541242],
            [51.963015, 60.561338],
            [51.771541, 60.579912],
            [51.802294, 60.656196],
            [51.833505, 60.726086],
            [51.893792, 60.8817],
            [52.360943, 60.829175],
            [52.431433, 60.976416],
            [52.653132, 60.952514],
            [52.806464, 60.937746],
            [52.870558, 61.09062],
            [53.335883, 61.041293],
            [53.413843, 61.032158],
            [53.381415, 60.959071],
            [53.352946, 60.882025],
            [53.607078, 60.854012],
            [53.728412, 60.842451],
            [53.826351, 60.835202],
            [53.869119, 60.967148],
            [53.87597, 60.982831],
            [54.221919, 60.944922],
            [54.370378, 60.927261],
            [54.723331, 60.886916],
            [54.976851, 60.859908],
            [55.05005, 61.009161],
            [55.19996, 60.991563],
            [55.272479, 61.146023],
            [55.730554, 61.09171],
            [55.813087, 61.246778],
            [56.274501, 61.191858],
            [56.326083, 61.291241],
            [56.311972, 61.301255],
            [56.322895, 61.332511],
            [56.37827, 61.443122],
            [56.56634, 61.418999],
            [56.597365, 61.492892],
            [56.63947, 61.488492],
            [56.653124, 61.5243],
            [56.73248, 61.514439],
            [57.080551, 61.475297],
            [57.092687, 61.50185],
            [57.922196, 61.498826],
            [57.997294, 61.499984],
            [58.231781, 61.497631],
            [58.843882, 61.495045],
            [58.844108, 61.51788],
            [58.918948, 61.531536],
            [58.974044, 61.522075],
            [58.984156, 61.532422],
            [59.023314, 61.538406],
            [59.048309, 61.536394],
            [59.090423, 61.574337],
            [59.114905, 61.59132],
            [59.123438, 61.610362],
            [59.148321, 61.629707],
            [59.187468, 61.628191],
            [59.206699, 61.639798],
            [59.236325, 61.62637],
            [59.281881, 61.641696],
            [59.292805, 61.655731],
            [59.348463, 61.66377],
          ],
          [
            [53.842667, 56.617525],
            [53.853217, 56.599894],
            [53.957127, 56.602785],
            [53.992823, 56.616226],
            [54.044127, 56.642096],
            [54.076377, 56.640808],
            [54.095682, 56.654968],
            [54.022272, 56.656415],
            [54.013632, 56.674432],
            [53.987186, 56.64831],
            [53.981117, 56.633568],
            [53.962474, 56.628799],
            [53.945975, 56.646311],
            [53.923308, 56.649609],
            [53.889345, 56.645707],
            [53.882409, 56.625908],
            [53.842667, 56.617525],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5475",
      properties: {
        name: "Приморский край",
        density: 0,
        path: "/world/Russia/Приморский край",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [132.468373, 42.796184],
              [132.459961, 42.79442],
              [132.43936, 42.811927],
              [132.44228, 42.817594],
              [132.425286, 42.834087],
              [132.39936, 42.832828],
              [132.392155, 42.859132],
              [132.412755, 42.861397],
              [132.415707, 42.870279],
              [132.407948, 42.896493],
              [132.428547, 42.902655],
              [132.43816, 42.884545],
              [132.434384, 42.877375],
              [132.458416, 42.860013],
              [132.439705, 42.845918],
              [132.440564, 42.832198],
              [132.454297, 42.813312],
              [132.476441, 42.80626],
              [132.468373, 42.796184],
            ],
          ],
          [
            [
              [131.66264, 42.88699],
              [131.67765, 42.89102],
              [131.67855, 42.87186],
              [131.66072, 42.87472],
              [131.65096, 42.85467],
              [131.63909, 42.8629],
              [131.66264, 42.88699],
            ],
          ],
          [
            [
              [130.90735, 42.45884],
              [130.91124, 42.47264],
              [130.93386, 42.47534],
              [130.93498, 42.4666],
              [130.90735, 42.45884],
            ],
          ],
          [
            [
              [131.44271, 42.65939],
              [131.46734, 42.67554],
              [131.4739, 42.66565],
              [131.46628, 42.64887],
              [131.44271, 42.65939],
            ],
          ],
          [
            [
              [131.44271, 42.65939],
              [131.4259, 42.66539],
              [131.42507, 42.67978],
              [131.44271, 42.65939],
            ],
          ],
          [
            [
              [132.340657, 42.728506],
              [132.34409, 42.744266],
              [132.332589, 42.755989],
              [132.321087, 42.743762],
              [132.308728, 42.758762],
              [132.331902, 42.779177],
              [132.34907, 42.781697],
              [132.361599, 42.767458],
              [132.359711, 42.748552],
              [132.365548, 42.733424],
              [132.340657, 42.728506],
            ],
          ],
          [
            [
              [131.49949, 42.7313],
              [131.51703, 42.72603],
              [131.52148, 42.71568],
              [131.49949, 42.7313],
            ],
          ],
          [
            [
              [131.43522, 42.83252],
              [131.46067, 42.83249],
              [131.44505, 42.82538],
              [131.43522, 42.83252],
            ],
          ],
          [
            [
              [131.43977, 42.80163],
              [131.4505, 42.8132],
              [131.45465, 42.80362],
              [131.43977, 42.80163],
            ],
          ],
          [
            [
              [134.005313, 46.655228],
              [134.02085, 46.634961],
              [134.0682, 46.657629],
              [134.111206, 46.659719],
              [134.140707, 46.650474],
              [134.178086, 46.654333],
              [134.208552, 46.652323],
              [134.248664, 46.66639],
              [134.253648, 46.675955],
              [134.317554, 46.674669],
              [134.333711, 46.68536],
              [134.3612, 46.67491],
              [134.38773, 46.675392],
              [134.432745, 46.697497],
              [134.422778, 46.711886],
              [134.45204, 46.72105],
              [134.450351, 46.749586],
              [134.4731, 46.771852],
              [134.462329, 46.785116],
              [134.463052, 46.798058],
              [134.45003, 46.807945],
              [134.450593, 46.825951],
              [134.407427, 46.860999],
              [134.41305, 46.878121],
              [134.436205, 46.890742],
              [134.433874, 46.89677],
              [134.452845, 46.920243],
              [134.441672, 46.933426],
              [134.448827, 46.941303],
              [134.432345, 46.959034],
              [134.43814, 46.970805],
              [134.455419, 46.972171],
              [134.464663, 46.983264],
              [134.485884, 46.967348],
              [134.512733, 46.964373],
              [134.550996, 46.973296],
              [134.576479, 46.992909],
              [134.575916, 47.012764],
              [134.565547, 47.021044],
              [134.566672, 47.040818],
              [134.591271, 47.062844],
              [134.626801, 47.061477],
              [134.646334, 47.07064],
              [134.634036, 47.096283],
              [134.643843, 47.100624],
              [134.66213, 47.124291],
              [134.687251, 47.124417],
              [134.759196, 47.145799],
              [134.767957, 47.125462],
              [134.777604, 47.124979],
              [134.807346, 47.149818],
              [134.820128, 47.147969],
              [134.840304, 47.168467],
              [134.895207, 47.139046],
              [134.913936, 47.140734],
              [134.92173, 47.130525],
              [134.975752, 47.138],
              [134.979932, 47.129479],
              [135.009433, 47.133498],
              [135.022858, 47.156006],
              [135.016749, 47.172002],
              [135.045125, 47.180362],
              [135.070045, 47.194831],
              [135.085318, 47.2134],
              [135.10461, 47.205602],
              [135.137414, 47.204149],
              [135.191908, 47.181406],
              [135.195766, 47.16975],
              [135.216505, 47.161309],
              [135.230572, 47.164605],
              [135.239415, 47.182048],
              [135.25517, 47.162836],
              [135.29866, 47.160907],
              [135.303642, 47.151502],
              [135.2907, 47.137193],
              [135.294236, 47.122724],
              [135.317467, 47.109782],
              [135.338608, 47.104557],
              [135.360312, 47.071599],
              [135.370601, 47.063882],
              [135.3991, 47.066448],
              [135.415134, 47.059782],
              [135.430889, 47.04274],
              [135.42285, 47.031406],
              [135.392063, 47.017098],
              [135.391982, 47.000539],
              [135.402914, 46.987436],
              [135.398252, 46.972967],
              [135.360953, 46.97988],
              [135.350503, 46.969029],
              [135.35203, 46.949656],
              [135.344393, 46.929801],
              [135.361515, 46.920717],
              [135.387801, 46.928595],
              [135.427752, 46.926585],
              [135.427672, 46.943626],
              [135.442302, 46.950218],
              [135.47815, 46.945475],
              [135.521, 46.965249],
              [135.550098, 46.96766],
              [135.564005, 46.977708],
              [135.58378, 46.977145],
              [135.590853, 46.960827],
              [135.608779, 46.949573],
              [135.633457, 46.958496],
              [135.650981, 46.952869],
              [135.690852, 46.966775],
              [135.709903, 46.963238],
              [135.752588, 46.964765],
              [135.752426, 46.942177],
              [135.737394, 46.943543],
              [135.70902, 46.931164],
              [135.704517, 46.917338],
              [135.740047, 46.916695],
              [135.789322, 46.898045],
              [135.807087, 46.884219],
              [135.80435, 46.866052],
              [135.81127, 46.84885],
              [135.836668, 46.828593],
              [135.859336, 46.828673],
              [135.89776, 46.821438],
              [135.949447, 46.797242],
              [135.9689, 46.802306],
              [135.986826, 46.796438],
              [136.027099, 46.8347],
              [136.045379, 46.843406],
              [136.054106, 46.867438],
              [136.087006, 46.868224],
              [136.116545, 46.851344],
              [136.127845, 46.82717],
              [136.14701, 46.807],
              [136.199725, 46.787545],
              [136.220323, 46.789262],
              [136.234199, 46.775458],
              [136.258373, 46.787259],
              [136.311658, 46.776315],
              [136.33941, 46.774742],
              [136.360079, 46.763369],
              [136.372667, 46.780106],
              [136.3894, 46.787401],
              [136.383611, 46.81365],
              [136.419372, 46.824735],
              [136.459711, 46.826237],
              [136.466148, 46.809501],
              [136.497332, 46.81136],
              [136.504198, 46.801633],
              [136.521936, 46.809643],
              [136.553191, 46.810001],
              [136.570142, 46.786613],
              [136.60247, 46.79684],
              [136.624928, 46.79827],
              [136.63823, 46.814434],
              [136.634012, 46.825806],
              [136.677785, 46.846118],
              [136.683363, 46.839324],
              [136.70389, 46.843043],
              [136.724632, 46.858134],
              [136.73815, 46.857132],
              [136.73372, 46.885026],
              [136.762111, 46.881235],
              [136.794725, 46.901476],
              [136.822763, 46.95333],
              [136.871971, 46.949682],
              [136.88556, 46.944317],
              [136.894715, 46.956691],
              [136.892785, 46.974214],
              [136.904515, 46.988733],
              [136.939346, 46.992309],
              [136.94607, 47.007185],
              [136.978112, 47.024922],
              [136.974751, 47.04216],
              [136.949003, 47.049455],
              [136.926188, 47.068695],
              [136.926116, 47.080067],
              [136.94743, 47.100093],
              [136.93777, 47.116972],
              [136.952867, 47.125627],
              [136.948004, 47.166323],
              [136.972608, 47.182344],
              [136.981477, 47.195146],
              [136.998428, 47.199509],
              [137.003006, 47.227618],
              [137.015308, 47.248645],
              [137.036837, 47.265596],
              [137.037267, 47.284049],
              [137.081325, 47.304003],
              [137.106287, 47.302286],
              [137.110006, 47.294919],
              [137.152204, 47.289626],
              [137.18618, 47.261232],
              [137.226516, 47.262376],
              [137.223226, 47.278039],
              [137.247544, 47.287408],
              [137.257056, 47.279755],
              [137.281589, 47.279755],
              [137.284521, 47.259943],
              [137.305834, 47.250145],
              [137.30555, 47.242277],
              [137.33144, 47.229903],
              [137.335587, 47.212309],
              [137.392805, 47.201723],
              [137.41905, 47.214239],
              [137.46519, 47.209447],
              [137.469049, 47.225182],
              [137.48396, 47.234108],
              [137.50195, 47.233263],
              [137.552999, 47.263551],
              [137.59321, 47.313797],
              [137.60294, 47.31065],
              [137.628046, 47.324311],
              [137.64743, 47.327887],
              [137.652507, 47.336827],
              [137.687983, 47.339544],
              [137.705435, 47.355351],
              [137.717522, 47.356281],
              [137.746703, 47.330604],
              [137.7879, 47.353419],
              [137.82638, 47.362287],
              [137.885029, 47.390467],
              [137.898761, 47.390395],
              [137.917787, 47.406988],
              [137.905199, 47.418289],
              [137.912423, 47.426156],
              [137.906058, 47.441534],
              [137.922437, 47.455552],
              [137.937385, 47.447613],
              [137.961774, 47.448543],
              [137.964492, 47.455766],
              [138.004259, 47.463419],
              [138.019351, 47.478081],
              [138.03845, 47.508692],
              [138.077857, 47.511982],
              [138.079073, 47.533153],
              [138.055471, 47.553966],
              [138.086441, 47.566339],
              [138.067702, 47.575065],
              [138.070063, 47.586509],
              [138.051181, 47.601529],
              [138.034873, 47.587153],
              [138.010627, 47.601815],
              [138.000829, 47.596451],
              [137.972363, 47.616192],
              [138.054115, 47.671263],
              [138.049394, 47.679631],
              [138.060695, 47.691433],
              [138.05247, 47.696654],
              [138.036091, 47.687141],
              [137.992176, 47.690146],
              [137.992177, 47.722331],
              [137.958418, 47.736922],
              [137.87853, 47.751656],
              [137.8482, 47.729198],
              [137.800424, 47.72455],
              [137.792699, 47.706169],
              [137.776893, 47.707599],
              [137.776607, 47.720545],
              [137.730618, 47.740357],
              [137.719103, 47.759311],
              [137.66932, 47.768609],
              [137.654232, 47.781269],
              [137.632847, 47.784559],
              [137.595012, 47.805015],
              [137.557534, 47.787635],
              [137.527351, 47.798578],
              [137.50947, 47.788923],
              [137.48623, 47.793286],
              [137.453039, 47.784418],
              [137.413487, 47.805803],
              [137.442454, 47.823612],
              [137.444028, 47.833983],
              [137.46384, 47.83856],
              [137.482436, 47.856941],
              [137.5119, 47.874249],
              [137.53758, 47.881545],
              [137.572269, 47.87618],
              [137.594513, 47.883475],
              [137.61103, 47.897851],
              [137.60055, 47.911268],
              [137.605242, 47.924028],
              [137.560255, 47.937547],
              [137.515768, 47.976169],
              [137.494025, 47.983393],
              [137.47464, 47.979889],
              [137.427795, 47.987256],
              [137.42164, 47.993049],
              [137.418498, 48.021587],
              [137.390096, 48.040427],
              [137.372074, 48.041288],
              [137.35001, 48.05633],
              [137.322777, 48.065076],
              [137.321254, 48.097808],
              [137.308267, 48.104368],
              [137.33709, 48.109205],
              [137.351734, 48.118945],
              [137.366841, 48.152737],
              [137.395399, 48.153929],
              [137.409314, 48.16294],
              [137.451324, 48.202961],
              [137.484586, 48.197991],
              [137.491411, 48.204948],
              [137.534943, 48.205146],
              [137.560188, 48.213694],
              [137.56734, 48.238077],
              [137.591927, 48.245299],
              [137.620551, 48.2343],
              [137.647188, 48.252455],
              [137.678197, 48.24828],
              [137.704501, 48.211307],
              [137.789682, 48.232001],
              [137.797199, 48.221974],
              [137.83457, 48.209318],
              [137.860941, 48.219853],
              [137.88281, 48.210842],
              [137.895396, 48.226545],
              [137.928393, 48.228069],
              [137.935748, 48.243507],
              [137.950325, 48.249934],
              [137.946615, 48.259873],
              [137.965234, 48.270474],
              [137.997966, 48.272528],
              [138.012346, 48.309699],
              [138.0523, 48.298567],
              [138.065419, 48.311024],
              [138.07701, 48.309235],
              [138.099146, 48.327854],
              [138.123198, 48.326661],
              [138.14447, 48.333883],
              [138.17634, 48.335274],
              [138.189326, 48.347267],
              [138.18045, 48.358929],
              [138.182634, 48.374897],
              [138.171966, 48.385896],
              [138.1776, 48.402991],
              [138.189658, 48.401798],
              [138.19317, 48.419026],
              [138.211259, 48.424989],
              [138.236041, 48.443342],
              [138.270297, 48.447782],
              [138.286001, 48.458383],
              [138.310583, 48.464346],
              [138.33748, 48.44142],
              [138.358024, 48.433336],
              [138.364319, 48.442281],
              [138.396588, 48.443937],
              [138.408448, 48.43042],
              [138.459401, 48.410078],
              [138.474111, 48.414451],
              [138.469207, 48.389206],
              [138.500415, 48.380261],
              [138.495843, 48.359522],
              [138.468278, 48.343753],
              [138.462646, 48.318773],
              [138.481198, 48.312345],
              [138.48577, 48.290944],
              [138.523405, 48.270403],
              [138.538843, 48.26815],
              [138.547456, 48.245688],
              [138.52725, 48.233364],
              [138.52233, 48.21394],
              [138.537517, 48.211763],
              [138.550106, 48.196523],
              [138.582705, 48.189831],
              [138.601987, 48.180091],
              [138.635548, 48.179],
              [138.647839, 48.192017],
              [138.679246, 48.203082],
              [138.688854, 48.213816],
              [138.703497, 48.210105],
              [138.716815, 48.193607],
              [138.70946, 48.179295],
              [138.726952, 48.154513],
              [138.723572, 48.14093],
              [138.697399, 48.129733],
              [138.701507, 48.117938],
              [138.685207, 48.109391],
              [138.658703, 48.10694],
              [138.636706, 48.12218],
              [138.615701, 48.101242],
              [138.613183, 48.083352],
              [138.597479, 48.074407],
              [138.594166, 48.060095],
              [138.574884, 48.047638],
              [138.577998, 48.029218],
              [138.598008, 48.005299],
              [138.630342, 48.003509],
              [138.662478, 47.982041],
              [138.671886, 47.968391],
              [138.69627, 47.96256],
              [138.71628, 47.930888],
              [138.734037, 47.928701],
              [138.75471, 47.913859],
              [138.748746, 47.891795],
              [138.772864, 47.882783],
              [138.831636, 47.868139],
              [138.831172, 47.858399],
              [138.814806, 47.847798],
              [138.806986, 47.819174],
              [138.79751, 47.809566],
              [138.77876, 47.809699],
              [138.758219, 47.796249],
              [138.762327, 47.776305],
              [138.775313, 47.771865],
              [138.76266, 47.763384],
              [138.737744, 47.769878],
              [138.664328, 47.74417],
              [138.657901, 47.735159],
              [138.637294, 47.738869],
              [138.623446, 47.729792],
              [138.594888, 47.73019],
              [138.57713, 47.712631],
              [138.554138, 47.717137],
              [138.549764, 47.697325],
              [138.532073, 47.692687],
              [138.506695, 47.67705],
              [138.528428, 47.6689],
              [138.547908, 47.647763],
              [138.575141, 47.64836],
              [138.580375, 47.637294],
              [138.600253, 47.630337],
              [138.615028, 47.612579],
              [138.6304, 47.60423],
              [138.617016, 47.583491],
              [138.590776, 47.562355],
              [138.591969, 47.544597],
              [138.58415, 47.535056],
              [138.5976, 47.521009],
              [138.559169, 47.505173],
              [138.53684, 47.481983],
              [138.541411, 47.476284],
              [138.571228, 47.48238],
              [138.605087, 47.482247],
              [138.596274, 47.463429],
              [138.621717, 47.444744],
              [138.642722, 47.453357],
              [138.692747, 47.443683],
              [138.721902, 47.455278],
              [138.725347, 47.440569],
              [138.764175, 47.456206],
              [138.795781, 47.454085],
              [138.801082, 47.465747],
              [138.862704, 47.469589],
              [138.890797, 47.448982],
              [138.93327, 47.445338],
              [138.96971, 47.419297],
              [138.977729, 47.401937],
              [138.995884, 47.390739],
              [139.013509, 47.402268],
              [139.032543, 47.396181],
              [139.014023, 47.383362],
              [138.984393, 47.346942],
              [138.990512, 47.316644],
              [138.946829, 47.313033],
              [138.931474, 47.319217],
              [138.87894, 47.301163],
              [138.830309, 47.268781],
              [138.804262, 47.240662],
              [138.745768, 47.183082],
              [138.70176, 47.136209],
              [138.637088, 47.098084],
              [138.603591, 47.074718],
              [138.561197, 47.030055],
              [138.532973, 46.987292],
              [138.51824, 46.953049],
              [138.501091, 46.937126],
              [138.502266, 46.922604],
              [138.49325, 46.875196],
              [138.485178, 46.859811],
              [138.44357, 46.826706],
              [138.409282, 46.794064],
              [138.404617, 46.777961],
              [138.38924, 46.766353],
              [138.368027, 46.722372],
              [138.36606, 46.706158],
              [138.351735, 46.694083],
              [138.352261, 46.664112],
              [138.339202, 46.632318],
              [138.344589, 46.610522],
              [138.359316, 46.597007],
              [138.350413, 46.58812],
              [138.341911, 46.53869],
              [138.297227, 46.514287],
              [138.234113, 46.493267],
              [138.214504, 46.471833],
              [138.188734, 46.459433],
              [138.165634, 46.434088],
              [138.158052, 46.408649],
              [138.119989, 46.354159],
              [138.10708, 46.302719],
              [138.096365, 46.289802],
              [138.099297, 46.257846],
              [138.109005, 46.246613],
              [138.073826, 46.215163],
              [138.06888, 46.203989],
              [138.029467, 46.167256],
              [138.011733, 46.163894],
              [138.00015, 46.14595],
              [137.97621, 46.123055],
              [137.948343, 46.109367],
              [137.93423, 46.093407],
              [137.9289, 46.073829],
              [137.880745, 46.036544],
              [137.862694, 46.017913],
              [137.796897, 45.969056],
              [137.770205, 45.945381],
              [137.744835, 45.89888],
              [137.709226, 45.861636],
              [137.693119, 45.855907],
              [137.686734, 45.832063],
              [137.687942, 45.810815],
              [137.666165, 45.79757],
              [137.638977, 45.774332],
              [137.62436, 45.770793],
              [137.57854, 45.738823],
              [137.562939, 45.735652],
              [137.53613, 45.71609],
              [137.526412, 45.700601],
              [137.485003, 45.671972],
              [137.463495, 45.670551],
              [137.434712, 45.65911],
              [137.359169, 45.61437],
              [137.34023, 45.587943],
              [137.326433, 45.578793],
              [137.320392, 45.558626],
              [137.307661, 45.545858],
              [137.297281, 45.521882],
              [137.282037, 45.517653],
              [137.2605, 45.48983],
              [137.242858, 45.475274],
              [137.214346, 45.466335],
              [137.199777, 45.456153],
              [137.182503, 45.422822],
              [137.161223, 45.411573],
              [137.154511, 45.390567],
              [137.138798, 45.375255],
              [137.099017, 45.370127],
              [137.07082, 45.359488],
              [137.039359, 45.341782],
              [137.00346, 45.312413],
              [136.974871, 45.297631],
              [136.946283, 45.268239],
              [136.918869, 45.25317],
              [136.870046, 45.238648],
              [136.842622, 45.22677],
              [136.801636, 45.19866],
              [136.769859, 45.126478],
              [136.764804, 45.092838],
              [136.7249, 45.069635],
              [136.690236, 45.053693],
              [136.684639, 45.040937],
              [136.65779, 45.038357],
              [136.633796, 45.025603],
              [136.614818, 44.999355],
              [136.601394, 44.99504],
              [136.567834, 44.958674],
              [136.550219, 44.9484],
              [136.559614, 44.920613],
              [136.536041, 44.901357],
              [136.502989, 44.888015],
              [136.494145, 44.857819],
              [136.476768, 44.840202],
              [136.470109, 44.844233],
              [136.44218, 44.830183],
              [136.436654, 44.813134],
              [136.451758, 44.79585],
              [136.450114, 44.772749],
              [136.422196, 44.774551],
              [136.403086, 44.792883],
              [136.379945, 44.805808],
              [136.353221, 44.799028],
              [136.349002, 44.766128],
              [136.335394, 44.753245],
              [136.303157, 44.750024],
              [136.335556, 44.732419],
              [136.338634, 44.724248],
              [136.319842, 44.712506],
              [136.29668, 44.713197],
              [136.264601, 44.695696],
              [136.24014, 44.677038],
              [136.22613, 44.656765],
              [136.223005, 44.637367],
              [136.210618, 44.625974],
              [136.229501, 44.592028],
              [136.227784, 44.584285],
              [136.19288, 44.576814],
              [136.186014, 44.564721],
              [136.183774, 44.526633],
              [136.157157, 44.509781],
              [136.146601, 44.495624],
              [136.11643, 44.487031],
              [136.111493, 44.479009],
              [136.055619, 44.460914],
              [136.03566, 44.44691],
              [136.00881, 44.454199],
              [135.989304, 44.435114],
              [135.96085, 44.431755],
              [135.918629, 44.416187],
              [135.881101, 44.412533],
              [135.87526, 44.398811],
              [135.852543, 44.374787],
              [135.83063, 44.363879],
              [135.827417, 44.349113],
              [135.836825, 44.334589],
              [135.816881, 44.299088],
              [135.806045, 44.292587],
              [135.795289, 44.266461],
              [135.78025, 44.257804],
              [135.73619, 44.249204],
              [135.717751, 44.23876],
              [135.687048, 44.202636],
              [135.685534, 44.193044],
              [135.65187, 44.178542],
              [135.647685, 44.153271],
              [135.659321, 44.130101],
              [135.63053, 44.099061],
              [135.636279, 44.08413],
              [135.629932, 44.0648],
              [135.631634, 44.035611],
              [135.611627, 44.036636],
              [135.595943, 44.022318],
              [135.57819, 44.026121],
              [135.553382, 44.012226],
              [135.530232, 44.008526],
              [135.521906, 43.993967],
              [135.530135, 43.971171],
              [135.514372, 43.967152],
              [135.505688, 43.952797],
              [135.519287, 43.940691],
              [135.518792, 43.922706],
              [135.501484, 43.946033],
              [135.46192, 43.956535],
              [135.452032, 43.94265],
              [135.46884, 43.933035],
              [135.471449, 43.915507],
              [135.488626, 43.902934],
              [135.45475, 43.884938],
              [135.459202, 43.877452],
              [135.485165, 43.873709],
              [135.506182, 43.886363],
              [135.501484, 43.896877],
              [135.52497, 43.900262],
              [135.509397, 43.883512],
              [135.501237, 43.846788],
              [135.487637, 43.846253],
              [135.472802, 43.831807],
              [135.472802, 43.814324],
              [135.453021, 43.806116],
              [135.43423, 43.78702],
              [135.434723, 43.76631],
              [135.419146, 43.75881],
              [135.400848, 43.760417],
              [135.373402, 43.736303],
              [135.370929, 43.725583],
              [135.338538, 43.716827],
              [135.305652, 43.702349],
              [135.30021, 43.693947],
              [135.276794, 43.685824],
              [135.260807, 43.69268],
              [135.25211, 43.707928],
              [135.25885, 43.728122],
              [135.23825, 43.727948],
              [135.21558, 43.705963],
              [135.237311, 43.692304],
              [135.222659, 43.685087],
              [135.227543, 43.659131],
              [135.219474, 43.658056],
              [135.222659, 43.632549],
              [135.192129, 43.62017],
              [135.181889, 43.610491],
              [135.183098, 43.592467],
              [135.175473, 43.583924],
              [135.170016, 43.556551],
              [135.147662, 43.540654],
              [135.155953, 43.52247],
              [135.136118, 43.501083],
              [135.109987, 43.503747],
              [135.075565, 43.490425],
              [135.023197, 43.475348],
              [135.001683, 43.460572],
              [134.981316, 43.467625],
              [134.968762, 43.453305],
              [134.968848, 43.440821],
              [134.92868, 43.421748],
              [134.898467, 43.414517],
              [134.8389, 43.383335],
              [134.816069, 43.354134],
              [134.79753, 43.350514],
              [134.79427, 43.336033],
              [134.77847, 43.309183],
              [134.753069, 43.30531],
              [134.72629, 43.308433],
              [134.681658, 43.288194],
              [134.681658, 43.279946],
              [134.663806, 43.268573],
              [134.633593, 43.261948],
              [134.623637, 43.244069],
              [134.600291, 43.236691],
              [134.58055, 43.243944],
              [134.558062, 43.236441],
              [134.548621, 43.22631],
              [134.518408, 43.209171],
              [134.472969, 43.176912],
              [134.450138, 43.172405],
              [134.440182, 43.158132],
              [134.415978, 43.146109],
              [134.389198, 43.142477],
              [134.382504, 43.133082],
              [134.342335, 43.111908],
              [134.319161, 43.121807],
              [134.302681, 43.111281],
              [134.317444, 43.103009],
              [134.308518, 43.097369],
              [134.295471, 43.107647],
              [134.28088, 43.107647],
              [134.246205, 43.091979],
              [134.234532, 43.079944],
              [134.22045, 43.080696],
              [134.18801, 43.072169],
              [134.17702, 43.060757],
              [134.176682, 43.038177],
              [134.154366, 43.032656],
              [134.130505, 43.017221],
              [134.123638, 42.993369],
              [134.097374, 42.987216],
              [134.081753, 42.975035],
              [134.050682, 42.973402],
              [134.010342, 42.949282],
              [134.006909, 42.938852],
              [133.983048, 42.926787],
              [133.9585, 42.908559],
              [133.950089, 42.880766],
              [133.931034, 42.876866],
              [133.922966, 42.866676],
              [133.894642, 42.871331],
              [133.90271, 42.879885],
              [133.880737, 42.892714],
              [133.887947, 42.901266],
              [133.876961, 42.90969],
              [133.840054, 42.889444],
              [133.823231, 42.890576],
              [133.807781, 42.86051],
              [133.795593, 42.870324],
              [133.78701, 42.862523],
              [133.74684, 42.844402],
              [133.745297, 42.825771],
              [133.728646, 42.808269],
              [133.717316, 42.807135],
              [133.713539, 42.823379],
              [133.698433, 42.830304],
              [133.685044, 42.851324],
              [133.646076, 42.859],
              [133.62582, 42.845786],
              [133.6035, 42.841507],
              [133.602818, 42.832821],
              [133.58136, 42.825016],
              [133.56557, 42.832821],
              [133.561104, 42.819728],
              [133.522824, 42.833829],
              [133.507546, 42.823253],
              [133.517159, 42.816454],
              [133.473385, 42.791769],
              [133.440941, 42.793029],
              [133.422402, 42.774887],
              [133.3594, 42.761277],
              [133.347451, 42.774137],
              [133.293377, 42.773759],
              [133.288743, 42.753342],
              [133.23887, 42.752048],
              [133.227077, 42.746406],
              [133.239562, 42.735728],
              [133.222208, 42.734692],
              [133.206387, 42.723433],
              [133.203583, 42.708291],
              [133.18747, 42.702155],
              [133.17693, 42.707554],
              [133.149273, 42.685053],
              [133.109978, 42.688532],
              [133.091407, 42.683807],
              [133.077729, 42.691906],
              [133.05559, 42.684767],
              [133.03979, 42.672522],
              [133.024201, 42.69583],
              [133.004083, 42.707763],
              [133.02907, 42.742261],
              [133.0484, 42.746881],
              [133.074082, 42.733111],
              [133.087942, 42.73857],
              [133.064896, 42.763445],
              [133.052755, 42.758869],
              [133.020612, 42.793797],
              [132.988895, 42.80781],
              [132.985589, 42.83031],
              [132.96053, 42.838241],
              [132.907998, 42.832828],
              [132.885904, 42.805572],
              [132.892548, 42.799585],
              [132.91366, 42.805504],
              [132.911431, 42.785728],
              [132.887742, 42.774515],
              [132.891861, 42.760905],
              [132.87641, 42.753721],
              [132.873322, 42.738971],
              [132.848088, 42.733676],
              [132.833251, 42.749211],
              [132.806844, 42.758749],
              [132.808262, 42.80286],
              [132.789036, 42.822001],
              [132.7717, 42.826155],
              [132.776848, 42.836352],
              [132.763115, 42.842898],
              [132.777363, 42.850953],
              [132.7729, 42.867814],
              [132.78526, 42.891086],
              [132.770497, 42.907056],
              [132.738396, 42.909696],
              [132.724663, 42.901775],
              [132.734105, 42.883665],
              [132.718998, 42.887312],
              [132.719513, 42.875111],
              [132.705952, 42.870959],
              [132.705094, 42.858],
              [132.690503, 42.85347],
              [132.661664, 42.859384],
              [132.630593, 42.860013],
              [132.602955, 42.850575],
              [132.607762, 42.838492],
              [132.570511, 42.837737],
              [132.563817, 42.84617],
              [132.51352, 42.859761],
              [132.512146, 42.876998],
              [132.522275, 42.902404],
              [132.482964, 42.916234],
              [132.44949, 42.920885],
              [132.445027, 42.930564],
              [132.430607, 42.918245],
              [132.405716, 42.947906],
              [132.399022, 42.94577],
              [132.406231, 42.920131],
              [132.377736, 42.913971],
              [132.362114, 42.903787],
              [132.374302, 42.882533],
              [132.361771, 42.872847],
              [132.368809, 42.86253],
              [132.34975, 42.84101],
              [132.33688, 42.858881],
              [132.317826, 42.842646],
              [132.31388, 42.859887],
              [132.29602, 42.868317],
              [132.292248, 42.878633],
              [132.307354, 42.890457],
              [132.297913, 42.897248],
              [132.295167, 42.920508],
              [132.297055, 42.964867],
              [132.308213, 42.977804],
              [132.297741, 42.997393],
              [132.315766, 43.009445],
              [132.30495, 43.03605],
              [132.317139, 43.0496],
              [132.299801, 43.065529],
              [132.330529, 43.090732],
              [132.317139, 43.117678],
              [132.326237, 43.13384],
              [132.340657, 43.142859],
              [132.314564, 43.164774],
              [132.323147, 43.17116],
              [132.35593, 43.166778],
              [132.388894, 43.187558],
              [132.388722, 43.2017],
              [132.371899, 43.217839],
              [132.318341, 43.211459],
              [132.327267, 43.223594],
              [132.3077, 43.227596],
              [132.316281, 43.247981],
              [132.348725, 43.271983],
              [132.35027, 43.295976],
              [132.333104, 43.309093],
              [132.292365, 43.316384],
              [132.28141, 43.308495],
              [132.298256, 43.28773],
              [132.25809, 43.267984],
              [132.25208, 43.249481],
              [132.210194, 43.220091],
              [132.17569, 43.215838],
              [132.170197, 43.205704],
              [132.133805, 43.194567],
              [132.112347, 43.192564],
              [132.108742, 43.182051],
              [132.082436, 43.167164],
              [132.047287, 43.135343],
              [132.030834, 43.116246],
              [132.010051, 43.109717],
              [131.965106, 43.077462],
              [131.962805, 43.05762],
              [131.956086, 43.073998],
              [131.92636, 43.064821],
              [131.909352, 43.077499],
              [131.86798, 43.090015],
              [131.848773, 43.079399],
              [131.841274, 43.066879],
              [131.84873, 43.060123],
              [131.89265, 43.06258],
              [131.90237, 43.05721],
              [131.894039, 43.035503],
              [131.91597, 43.03043],
              [131.91271, 43.01333],
              [131.924673, 43.011509],
              [131.93476, 42.997638],
              [131.90908, 42.9735],
              [131.90754, 42.95931],
              [131.87956, 42.95873],
              [131.87352, 42.94528],
              [131.872115, 42.966951],
              [131.842277, 42.954724],
              [131.84921, 42.9449],
              [131.8395, 42.93471],
              [131.822023, 42.932372],
              [131.831033, 42.958336],
              [131.82461, 42.96395],
              [131.796284, 42.963795],
              [131.80322, 42.97807],
              [131.75252, 42.98149],
              [131.74814, 42.9694],
              [131.76248, 42.96187],
              [131.75374, 42.95002],
              [131.7333, 42.94615],
              [131.73284, 42.92383],
              [131.74557, 42.90377],
              [131.72298, 42.89814],
              [131.71131, 42.90253],
              [131.71771, 42.91316],
              [131.70898, 42.92106],
              [131.72635, 42.93092],
              [131.71092, 42.9495],
              [131.71909, 42.96046],
              [131.70696, 42.97834],
              [131.7272, 42.99364],
              [131.74544, 42.98717],
              [131.75667, 43.00384],
              [131.76945, 43.00032],
              [131.780126, 43.026985],
              [131.79446, 43.035549],
              [131.79329, 43.05582],
              [131.80549, 43.06245],
              [131.813476, 43.047923],
              [131.828058, 43.049303],
              [131.82401, 43.06558],
              [131.837884, 43.067663],
              [131.842182, 43.081699],
              [131.86032, 43.103768],
              [131.870342, 43.107587],
              [131.876093, 43.126654],
              [131.901349, 43.145406],
              [131.90555, 43.15668],
              [131.896165, 43.170228],
              [131.920994, 43.203549],
              [131.987816, 43.234459],
              [132.012248, 43.242187],
              [132.002762, 43.261759],
              [132.02658, 43.267135],
              [132.054491, 43.284965],
              [132.05309, 43.29782],
              [132.064096, 43.309341],
              [132.063348, 43.324586],
              [132.04402, 43.331127],
              [132.01467, 43.327401],
              [131.997628, 43.298093],
              [132.011889, 43.285353],
              [131.969491, 43.261266],
              [131.991592, 43.281295],
              [131.98856, 43.294614],
              [131.974025, 43.295988],
              [131.948094, 43.314782],
              [131.9041, 43.31955],
              [131.8706, 43.3168],
              [131.86846, 43.30878],
              [131.82347, 43.29322],
              [131.82164, 43.31456],
              [131.77748, 43.31718],
              [131.77267, 43.30036],
              [131.75983, 43.2922],
              [131.76016, 43.25677],
              [131.76808, 43.24941],
              [131.75582, 43.22661],
              [131.72523, 43.20963],
              [131.706, 43.21099],
              [131.70024, 43.19971],
              [131.76327, 43.1973],
              [131.77773, 43.17989],
              [131.75166, 43.17319],
              [131.74718, 43.16317],
              [131.72766, 43.16185],
              [131.70509, 43.19061],
              [131.68246, 43.14623],
              [131.65783, 43.12371],
              [131.65035, 43.10875],
              [131.6224, 43.09398],
              [131.60537, 43.0641],
              [131.56325, 43.04016],
              [131.55903, 43.0228],
              [131.57786, 43.01314],
              [131.57568, 43.00267],
              [131.53618, 42.99021],
              [131.54265, 43.00315],
              [131.52026, 43.01135],
              [131.49683, 43.00262],
              [131.48604, 42.9908],
              [131.48994, 42.97506],
              [131.50649, 42.96931],
              [131.50783, 42.9508],
              [131.49061, 42.93963],
              [131.46931, 42.94432],
              [131.45618, 42.93447],
              [131.45731, 42.92341],
              [131.41925, 42.93243],
              [131.40361, 42.95227],
              [131.37668, 42.94255],
              [131.38413, 42.92778],
              [131.37903, 42.91736],
              [131.40546, 42.91027],
              [131.40861, 42.89673],
              [131.39563, 42.87262],
              [131.42191, 42.87566],
              [131.43287, 42.87042],
              [131.45215, 42.88143],
              [131.47143, 42.88358],
              [131.45618, 42.86851],
              [131.42495, 42.85507],
              [131.41482, 42.84578],
              [131.3875, 42.84289],
              [131.35329, 42.81447],
              [131.35022, 42.78862],
              [131.38016, 42.7789],
              [131.35442, 42.77519],
              [131.32569, 42.79412],
              [131.28047, 42.78682],
              [131.25663, 42.76843],
              [131.26025, 42.74834],
              [131.25001, 42.74444],
              [131.24242, 42.72226],
              [131.241, 42.69739],
              [131.25195, 42.69204],
              [131.23659, 42.6713],
              [131.22248, 42.67542],
              [131.21659, 42.6663],
              [131.22571, 42.65406],
              [131.19903, 42.6171],
              [131.20967, 42.60856],
              [131.22992, 42.61112],
              [131.23373, 42.58666],
              [131.21769, 42.58492],
              [131.22281, 42.56737],
              [131.21817, 42.55411],
              [131.18316, 42.55581],
              [131.16527, 42.5754],
              [131.14866, 42.58213],
              [131.16681, 42.58474],
              [131.17553, 42.59574],
              [131.12133, 42.616],
              [131.13276, 42.63542],
              [131.11725, 42.66985],
              [131.10104, 42.66873],
              [131.08508, 42.64387],
              [131.10127, 42.61894],
              [131.06594, 42.64332],
              [131.0433, 42.63128],
              [131.01915, 42.64762],
              [131.00171, 42.6351],
              [130.99926, 42.6454],
              [130.98543, 42.64757],
              [130.96711, 42.64031],
              [130.96015, 42.62862],
              [130.94368, 42.62429],
              [130.94761, 42.61088],
              [130.93526, 42.61056],
              [130.92324, 42.59255],
              [130.87088, 42.59573],
              [130.85151, 42.61016],
              [130.84835, 42.62073],
              [130.82394, 42.62998],
              [130.79726, 42.63258],
              [130.77616, 42.60571],
              [130.77464, 42.57673],
              [130.79429, 42.57758],
              [130.84311, 42.56102],
              [130.84156, 42.54949],
              [130.86549, 42.5373],
              [130.86748, 42.51674],
              [130.84889, 42.53282],
              [130.83459, 42.53087],
              [130.84959, 42.48648],
              [130.8403, 42.47123],
              [130.81954, 42.46079],
              [130.80178, 42.47608],
              [130.77804, 42.464],
              [130.78865, 42.45306],
              [130.76719, 42.42362],
              [130.7509, 42.36689],
              [130.73529, 42.34044],
              [130.699718, 42.292987],
              [130.650598, 42.328587],
              [130.64649, 42.346523],
              [130.670657, 42.367929],
              [130.667455, 42.38063],
              [130.653431, 42.39329],
              [130.64635, 42.411296],
              [130.625841, 42.432736],
              [130.5926, 42.445877],
              [130.592231, 42.484534],
              [130.55833, 42.504765],
              [130.55327, 42.518445],
              [130.567252, 42.538261],
              [130.563457, 42.555114],
              [130.574873, 42.559367],
              [130.603343, 42.55491],
              [130.615574, 42.571],
              [130.627176, 42.597576],
              [130.616423, 42.612886],
              [130.615513, 42.629029],
              [130.605477, 42.630939],
              [130.587829, 42.66513],
              [130.561532, 42.68619],
              [130.521415, 42.701434],
              [130.4884, 42.6888],
              [130.4566, 42.6884],
              [130.452, 42.6984],
              [130.424, 42.7033],
              [130.4001, 42.7226],
              [130.4362, 42.7519],
              [130.5324, 42.792],
              [130.5425, 42.8046],
              [130.5732, 42.8104],
              [130.6005, 42.8244],
              [130.6288, 42.8312],
              [130.6709, 42.8495],
              [130.6953, 42.8473],
              [130.7119, 42.8309],
              [130.7415, 42.8385],
              [130.7487, 42.8348],
              [130.7711, 42.8462],
              [130.7816, 42.8712],
              [130.7928, 42.8792],
              [130.8286, 42.8831],
              [130.8405, 42.8721],
              [130.878, 42.8563],
              [130.9238, 42.8743],
              [130.9409, 42.874],
              [130.9699, 42.86],
              [131.0262, 42.8609],
              [131.035, 42.8679],
              [131.0157, 42.9159],
              [131.0282, 42.922],
              [131.0976, 42.9116],
              [131.1087, 42.9134],
              [131.1355, 42.9435],
              [131.1283, 42.9499],
              [131.1435, 42.9639],
              [131.112, 42.9736],
              [131.104, 43.0279],
              [131.1158, 43.0475],
              [131.1162, 43.0601],
              [131.1326, 43.0715],
              [131.1584, 43.0728],
              [131.1626, 43.0889],
              [131.1557, 43.1017],
              [131.1682, 43.1059],
              [131.2048, 43.134],
              [131.2019, 43.1557],
              [131.211, 43.1701],
              [131.1961, 43.2017],
              [131.2036, 43.2376],
              [131.2398, 43.2522],
              [131.2687, 43.3335],
              [131.2619, 43.3453],
              [131.274, 43.3703],
              [131.3084, 43.3994],
              [131.2975, 43.4373],
              [131.3035, 43.455436],
              [131.2962, 43.4688],
              [131.2721, 43.4846],
              [131.259, 43.4993],
              [131.2041, 43.5193],
              [131.1958, 43.5277],
              [131.2002, 43.5472],
              [131.1944, 43.5589],
              [131.2048, 43.5714],
              [131.2029, 43.5859],
              [131.2155, 43.593],
              [131.2302, 43.5889],
              [131.2297, 43.6089],
              [131.2113, 43.6138],
              [131.2234, 43.6405],
              [131.2293, 43.6655],
              [131.2115, 43.6856],
              [131.2133, 43.7351],
              [131.2253, 43.7566],
              [131.205, 43.7813],
              [131.2159, 43.8072],
              [131.2103, 43.8227],
              [131.2295, 43.8717],
              [131.2493, 43.9001],
              [131.2605, 43.9333],
              [131.2561, 43.9486],
              [131.2382, 43.9698],
              [131.257, 44.0081],
              [131.2529, 44.0289],
              [131.2643, 44.0359],
              [131.2945, 44.0371],
              [131.3029, 44.0586],
              [131.2591, 44.1927],
              [131.2122, 44.3438],
              [131.188, 44.4342],
              [131.1587, 44.5258],
              [131.1036, 44.7061],
              [131.0884, 44.7062],
              [131.087, 44.7379],
              [131.0614, 44.7616],
              [131.0559, 44.7852],
              [131.0417, 44.7783],
              [131.0141, 44.7815],
              [131.0125, 44.8092],
              [130.9964, 44.8187],
              [130.9696, 44.8173],
              [130.959, 44.8409],
              [130.9633, 44.856],
              [130.9788, 44.8658],
              [131.0171, 44.8741],
              [131.0512, 44.8717],
              [131.0872, 44.889],
              [131.0928, 44.9024],
              [131.0821, 44.9224],
              [131.0964, 44.9321],
              [131.1166, 44.9317],
              [131.1501, 44.9464],
              [131.1802, 44.9377],
              [131.1974, 44.9194],
              [131.2176, 44.9154],
              [131.247, 44.9238],
              [131.2814, 44.9276],
              [131.2943, 44.9354],
              [131.308, 44.9604],
              [131.3486, 44.9865],
              [131.3685, 44.9789],
              [131.4055, 44.9836],
              [131.4289, 44.9678],
              [131.4591, 44.9617],
              [131.475188, 44.963653],
              [131.4745, 44.9956],
              [131.4849, 45.0016],
              [131.535, 45.0167],
              [131.5654, 45.0423],
              [131.5925, 45.0534],
              [131.6803, 45.1127],
              [131.6884, 45.1257],
              [131.6831, 45.1485],
              [131.6582, 45.1599],
              [131.655, 45.1743],
              [131.6665, 45.202],
              [131.6813, 45.2196],
              [131.7186, 45.2268],
              [131.7486, 45.2153],
              [131.7851, 45.2072],
              [131.7777, 45.2371],
              [131.8201, 45.2974],
              [131.8784, 45.3399],
              [131.9126, 45.3363],
              [131.928508, 45.279796],
              [131.963079, 45.27659],
              [131.981524, 45.269645],
              [131.98641, 45.256402],
              [132.03227, 45.249297],
              [132.261804, 45.199346],
              [132.342677, 45.185075],
              [132.466365, 45.156531],
              [132.53297, 45.144638],
              [132.656653, 45.117284],
              [132.798181, 45.082794],
              [132.8746, 45.0519],
              [132.8878, 45.0396],
              [132.9365, 45.027],
              [132.938, 45.0189],
              [132.9543, 45.0264],
              [132.9696, 45.0222],
              [132.9742, 45.0359],
              [133.0019, 45.0418],
              [133.031799, 45.058498],
              [133.0352, 45.0699],
              [133.0674, 45.0967],
              [133.0825, 45.0997],
              [133.1022, 45.1198],
              [133.1159, 45.1265],
              [133.1266, 45.149],
              [133.1199, 45.1615],
              [133.1255, 45.1815],
              [133.1223, 45.202],
              [133.1102, 45.2242],
              [133.0879, 45.2412],
              [133.0985, 45.2673],
              [133.0915, 45.2868],
              [133.1073, 45.3087],
              [133.0982, 45.3141],
              [133.1143, 45.3306],
              [133.1112, 45.3458],
              [133.1267, 45.3793],
              [133.1381, 45.3869],
              [133.1323, 45.4021],
              [133.1341, 45.4263],
              [133.1455, 45.4282],
              [133.1879, 45.5009],
              [133.2244, 45.5195],
              [133.2411, 45.5197],
              [133.2574, 45.5308],
              [133.2727, 45.5319],
              [133.3005, 45.544],
              [133.3217, 45.5623],
              [133.3334, 45.5562],
              [133.3585, 45.5737],
              [133.3814, 45.5812],
              [133.4116, 45.571915],
              [133.415379, 45.580085],
              [133.403652, 45.596124],
              [133.404511, 45.617158],
              [133.421242, 45.610262],
              [133.42448, 45.626093],
              [133.44649, 45.625793],
              [133.466157, 45.616438],
              [133.466397, 45.626992],
              [133.440132, 45.640904],
              [133.446188, 45.653857],
              [133.482708, 45.661473],
              [133.472633, 45.691996],
              [133.458301, 45.689597],
              [133.445589, 45.70243],
              [133.420583, 45.695594],
              [133.44359, 45.727684],
              [133.477612, 45.736471],
              [133.47495, 45.747965],
              [133.458914, 45.752837],
              [133.462288, 45.777406],
              [133.486633, 45.772671],
              [133.498325, 45.783219],
              [133.488794, 45.791352],
              [133.461998, 45.796451],
              [133.456318, 45.828477],
              [133.468509, 45.837814],
              [133.484772, 45.838275],
              [133.4807, 45.863882],
              [133.5012, 45.864265],
              [133.50373, 45.886872],
              [133.520242, 45.89595],
              [133.54443, 45.895606],
              [133.573324, 45.870511],
              [133.590169, 45.873342],
              [133.587672, 45.887006],
              [133.60721, 45.902212],
              [133.598377, 45.932575],
              [133.623941, 45.947501],
              [133.643592, 45.94738],
              [133.666413, 45.939761],
              [133.674388, 45.955014],
              [133.672257, 45.983148],
              [133.693377, 46.00311],
              [133.69844, 46.015261],
              [133.734603, 46.054317],
              [133.734024, 46.077462],
              [133.704226, 46.099738],
              [133.680792, 46.137926],
              [133.687012, 46.155863],
              [133.69844, 46.162373],
              [133.741691, 46.166278],
              [133.786426, 46.190567],
              [133.794866, 46.221514],
              [133.805623, 46.229458],
              [133.82201, 46.223831],
              [133.826144, 46.203972],
              [133.837397, 46.200331],
              [133.850471, 46.209599],
              [133.855436, 46.224659],
              [133.871323, 46.230782],
              [133.854774, 46.242863],
              [133.855932, 46.250972],
              [133.897471, 46.255606],
              [133.909717, 46.263715],
              [133.908559, 46.28225],
              [133.898629, 46.301447],
              [133.91584, 46.331236],
              [133.892558, 46.323887],
              [133.861724, 46.337028],
              [133.869668, 46.361521],
              [133.905249, 46.367147],
              [133.936361, 46.379725],
              [133.939175, 46.399749],
              [133.926432, 46.419774],
              [133.890918, 46.447063],
              [133.846499, 46.444597],
              [133.840541, 46.465615],
              [133.844679, 46.481337],
              [133.86371, 46.499872],
              [133.86549, 46.5123],
              [133.881881, 46.52148],
              [133.912105, 46.596411],
              [133.951242, 46.614205],
              [133.981548, 46.633129],
              [134.005313, 46.655228],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5496",
      properties: {
        name: "Псковская область",
        density: 0,
        path: "/world/Russia/Псковская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.737764, 58.97789],
            [27.885742, 59.014556],
            [27.917999, 59.021159],
            [27.93124, 59.014556],
            [27.990717, 59.01552],
            [27.996598, 59.004239],
            [28.033998, 59.000252],
            [28.03381, 58.987063],
            [28.080545, 58.991879],
            [28.096588, 59.000958],
            [28.120902, 58.992653],
            [28.121247, 58.974839],
            [28.144219, 58.978416],
            [28.174018, 58.971332],
            [28.146438, 58.96356],
            [28.202272, 58.934793],
            [28.225608, 58.895641],
            [28.257969, 58.898616],
            [28.266292, 58.88541],
            [28.239743, 58.879839],
            [28.260928, 58.874749],
            [28.294324, 58.826191],
            [28.311311, 58.840566],
            [28.344894, 58.824954],
            [28.324706, 58.851159],
            [28.344101, 58.854048],
            [28.373537, 58.878448],
            [28.411402, 58.855132],
            [28.436471, 58.881046],
            [28.486423, 58.869423],
            [28.492734, 58.862699],
            [28.540948, 58.867859],
            [28.621988, 58.864559],
            [28.64479, 58.852316],
            [28.659233, 58.865866],
            [28.666662, 58.852317],
            [28.765792, 58.824841],
            [28.767855, 58.831306],
            [28.843307, 58.825255],
            [28.888187, 58.816606],
            [28.909698, 58.803005],
            [28.973492, 58.805139],
            [28.98422, 58.816625],
            [29.016856, 58.820546],
            [29.033775, 58.832772],
            [29.055894, 58.739799],
            [29.073657, 58.736377],
            [29.035966, 58.725303],
            [29.085917, 58.731649],
            [29.096441, 58.721332],
            [29.123764, 58.717756],
            [29.139825, 58.700389],
            [29.180972, 58.709951],
            [29.217254, 58.699634],
            [29.278399, 58.701028],
            [29.283283, 58.696145],
            [29.257286, 58.669767],
            [29.291677, 58.648687],
            [29.267949, 58.634517],
            [29.230928, 58.632608],
            [29.243034, 58.626487],
            [29.264289, 58.594676],
            [29.288567, 58.611528],
            [29.313104, 58.613729],
            [29.352412, 58.606921],
            [29.403052, 58.591945],
            [29.3677, 58.583691],
            [29.383588, 58.579839],
            [29.37341, 58.568593],
            [29.358277, 58.581868],
            [29.318833, 58.574026],
            [29.3275, 58.560201],
            [29.393838, 58.554494],
            [29.406303, 58.579255],
            [29.423153, 58.590329],
            [29.47204, 58.566876],
            [29.490595, 58.549234],
            [29.510885, 58.541393],
            [29.543091, 58.544007],
            [29.579562, 58.533553],
            [29.584326, 58.523322],
            [29.551158, 58.508758],
            [29.539741, 58.493041],
            [29.551022, 58.479784],
            [29.582799, 58.47552],
            [29.59714, 58.458222],
            [29.639475, 58.446943],
            [29.673745, 58.442748],
            [29.690701, 58.459715],
            [29.708016, 58.459262],
            [29.713254, 58.444417],
            [29.752772, 58.434235],
            [29.747405, 58.423666],
            [29.786442, 58.421187],
            [29.865539, 58.427259],
            [29.857009, 58.443009],
            [29.894085, 58.445747],
            [29.91793, 58.437381],
            [29.875322, 58.422347],
            [29.894187, 58.398697],
            [29.913505, 58.39051],
            [29.89256, 58.374855],
            [29.904817, 58.363749],
            [29.933063, 58.3626],
            [29.908648, 58.348333],
            [29.892181, 58.309506],
            [29.854264, 58.310941],
            [29.845934, 58.304047],
            [29.862787, 58.289446],
            [29.815871, 58.272353],
            [29.822478, 58.258278],
            [29.766656, 58.252532],
            [29.700206, 58.256936],
            [29.682779, 58.252818],
            [29.633659, 58.256647],
            [29.617957, 58.252625],
            [29.637299, 58.235391],
            [29.658689, 58.233783],
            [29.638353, 58.224379],
            [29.637109, 58.214326],
            [29.679048, 58.210688],
            [29.689452, 58.188816],
            [29.6644, 58.176265],
            [29.657124, 58.162669],
            [29.668328, 58.134087],
            [29.646594, 58.124608],
            [29.647839, 58.108761],
            [29.628881, 58.100909],
            [29.650905, 58.082908],
            [29.673886, 58.072999],
            [29.700695, 58.081329],
            [29.711514, 58.096171],
            [29.744548, 58.094448],
            [29.7569, 58.085352],
            [29.789646, 58.091002],
            [29.833022, 58.067831],
            [29.862705, 58.069986],
            [29.889036, 58.062518],
            [29.88875, 58.04533],
            [29.937487, 58.038916],
            [29.980574, 58.049162],
            [29.993884, 58.042747],
            [29.987182, 58.030395],
            [29.947063, 58.023788],
            [29.952914, 58.009644],
            [29.903595, 57.990322],
            [29.876784, 57.997024],
            [29.889138, 57.968395],
            [29.884255, 57.959538],
            [29.910108, 57.953985],
            [29.901395, 57.941441],
            [29.942472, 57.945464],
            [29.958462, 57.955135],
            [29.979335, 57.955518],
            [29.991783, 57.942018],
            [30.071759, 57.958173],
            [30.090884, 57.949966],
            [30.089066, 57.938907],
            [30.116067, 57.940727],
            [30.168443, 57.926126],
            [30.153986, 57.907406],
            [30.198798, 57.89496],
            [30.245907, 57.890843],
            [30.294738, 57.910281],
            [30.336198, 57.915453],
            [30.342518, 57.902431],
            [30.386372, 57.90358],
            [30.394378, 57.890642],
            [30.42113, 57.879069],
            [30.466133, 57.879165],
            [30.472931, 57.885868],
            [30.518987, 57.884002],
            [30.544553, 57.86399],
            [30.530765, 57.857],
            [30.539483, 57.840542],
            [30.510565, 57.804912],
            [30.448232, 57.798639],
            [30.435114, 57.802182],
            [30.41721, 57.781691],
            [30.434637, 57.773648],
            [30.448329, 57.778244],
            [30.496683, 57.772213],
            [30.503292, 57.743775],
            [30.475142, 57.732284],
            [30.494388, 57.720986],
            [30.45226, 57.687999],
            [30.495828, 57.662962],
            [30.518809, 57.642471],
            [30.680627, 57.644054],
            [30.563625, 57.565202],
            [30.542752, 57.546626],
            [30.504069, 57.544614],
            [30.499187, 57.53006],
            [30.463377, 57.521873],
            [30.468931, 57.509617],
            [30.515634, 57.501436],
            [30.505413, 57.486206],
            [30.486072, 57.483142],
            [30.463667, 57.46538],
            [30.469318, 57.449581],
            [30.423359, 57.424207],
            [30.435616, 57.40477],
            [30.413403, 57.396966],
            [30.427384, 57.367475],
            [30.457832, 57.36422],
            [30.476886, 57.37255],
            [30.453331, 57.385572],
            [30.451702, 57.398402],
            [30.478129, 57.403573],
            [30.49862, 57.391413],
            [30.522653, 57.395435],
            [30.53676, 57.385973],
            [30.49393, 57.374274],
            [30.49709, 57.36671],
            [30.464248, 57.350097],
            [30.541043, 57.30409],
            [30.745663, 57.263208],
            [30.62847, 57.176504],
            [30.687549, 57.149025],
            [30.688417, 57.041401],
            [30.675652, 57.004156],
            [30.700292, 56.996159],
            [30.686505, 56.984573],
            [30.72921, 56.974329],
            [30.765501, 56.9457],
            [30.804566, 56.952307],
            [30.815769, 56.960159],
            [30.843536, 56.957],
            [30.837601, 56.932488],
            [30.871113, 56.941201],
            [30.906157, 56.960783],
            [30.940913, 56.972944],
            [30.978757, 56.969897],
            [30.985984, 56.960182],
            [30.967542, 56.946301],
            [30.975962, 56.911792],
            [30.941615, 56.895983],
            [30.962436, 56.889493],
            [30.927479, 56.863016],
            [30.89615, 56.871177],
            [30.900005, 56.890327],
            [30.879827, 56.885764],
            [30.874752, 56.853954],
            [30.886512, 56.859545],
            [30.88497, 56.838853],
            [30.870126, 56.828185],
            [30.867493, 56.809484],
            [30.81785, 56.811154],
            [30.772483, 56.764499],
            [30.791055, 56.754281],
            [30.82248, 56.750426],
            [30.834819, 56.739309],
            [30.790863, 56.738922],
            [30.774251, 56.731075],
            [30.799539, 56.730633],
            [30.811943, 56.713475],
            [30.824538, 56.713925],
            [30.814386, 56.694774],
            [30.849732, 56.666435],
            [30.857316, 56.655125],
            [30.881864, 56.643172],
            [30.936938, 56.636554],
            [30.946962, 56.647993],
            [31.021925, 56.644396],
            [31.04342, 56.651786],
            [31.065591, 56.638806],
            [31.085384, 56.640155],
            [31.079151, 56.625632],
            [31.046925, 56.606674],
            [31.054444, 56.602111],
            [31.040178, 56.586045],
            [31.04246, 56.574992],
            [31.021737, 56.540033],
            [30.998732, 56.525702],
            [31.005867, 56.489972],
            [30.987103, 56.485795],
            [31.042594, 56.468188],
            [31.153513, 56.437536],
            [31.184488, 56.435737],
            [31.211736, 56.427769],
            [31.206595, 56.414145],
            [31.229698, 56.408426],
            [31.224044, 56.39114],
            [31.229027, 56.338444],
            [31.301677, 56.322058],
            [31.357352, 56.324257],
            [31.367932, 56.307279],
            [31.387661, 56.305351],
            [31.399967, 56.314541],
            [31.433705, 56.308951],
            [31.509342, 56.307795],
            [31.514484, 56.293786],
            [31.487751, 56.286974],
            [31.46057, 56.242247],
            [31.464171, 56.19315],
            [31.501187, 56.184025],
            [31.507486, 56.161019],
            [31.482938, 56.145339],
            [31.451065, 56.139812],
            [31.467647, 56.087888],
            [31.503635, 56.067195],
            [31.501066, 56.034807],
            [31.481145, 56.030051],
            [31.42331, 55.999204],
            [31.411744, 55.983909],
            [31.363869, 55.968229],
            [31.347419, 55.942652],
            [31.38662, 55.927101],
            [31.398702, 55.916433],
            [31.394205, 55.903452],
            [31.414319, 55.890728],
            [31.408023, 55.87312],
            [31.394014, 55.866437],
            [31.413166, 55.832249],
            [31.402499, 55.817726],
            [31.406805, 55.805773],
            [31.434825, 55.781354],
            [31.447358, 55.743567],
            [31.47165, 55.734699],
            [31.483134, 55.718642],
            [31.490476, 55.705396],
            [31.440717, 55.702724],
            [31.418229, 55.712288],
            [31.465358, 55.727712],
            [31.453855, 55.73874],
            [31.436192, 55.739602],
            [31.417582, 55.72099],
            [31.395136, 55.732277],
            [31.350289, 55.732966],
            [31.310137, 55.74072],
            [31.309965, 55.725986],
            [31.293336, 55.72633],
            [31.280799, 55.737704],
            [31.239269, 55.738478],
            [31.235047, 55.730293],
            [31.206873, 55.719694],
            [31.176284, 55.744595],
            [31.176026, 55.735117],
            [31.135098, 55.752004],
            [31.139234, 55.756226],
            [31.038165, 55.758896],
            [31.027568, 55.749676],
            [31.027914, 55.723052],
            [31.062574, 55.704743],
            [31.064901, 55.694619],
            [31.037975, 55.698582],
            [30.965018, 55.668984],
            [30.949725, 55.659075],
            [30.951751, 55.646108],
            [30.927856, 55.640456],
            [30.931557, 55.623159],
            [30.91604, 55.61356],
            [30.88858, 55.61906],
            [30.86223, 55.6315],
            [30.83819, 55.62807],
            [30.85158, 55.62258],
            [30.80833, 55.59846],
            [30.80515, 55.58859],
            [30.75151, 55.59734],
            [30.75863, 55.60361],
            [30.74524, 55.63854],
            [30.72962, 55.65983],
            [30.70413, 55.6588],
            [30.69374, 55.64112],
            [30.67426, 55.63511],
            [30.67383, 55.65554],
            [30.65658, 55.65914],
            [30.67108, 55.67236],
            [30.64276, 55.67219],
            [30.63924, 55.68755],
            [30.65091, 55.69502],
            [30.63126, 55.72446],
            [30.58345, 55.74814],
            [30.53161, 55.74943],
            [30.49702, 55.77819],
            [30.51581, 55.79433],
            [30.48122, 55.81063],
            [30.40938, 55.80728],
            [30.40423, 55.81535],
            [30.36553, 55.80934],
            [30.33677, 55.83415],
            [30.2918, 55.82943],
            [30.27532, 55.86222],
            [30.24073, 55.87054],
            [30.18107, 55.86204],
            [30.16811, 55.87784],
            [30.16811, 55.84977],
            [30.14262, 55.84874],
            [30.1331, 55.83046],
            [30.10906, 55.83106],
            [30.0749, 55.84762],
            [30.060787, 55.840051],
            [30.05065, 55.852911],
            [29.978615, 55.871013],
            [29.97148, 55.85612],
            [29.93912, 55.85054],
            [29.89955, 55.86101],
            [29.86342, 55.84934],
            [29.87706, 55.83981],
            [29.83852, 55.80394],
            [29.80067, 55.77999],
            [29.76179, 55.78025],
            [29.75767, 55.79853],
            [29.74128, 55.80179],
            [29.71424, 55.79021],
            [29.67939, 55.79149],
            [29.63013, 55.77476],
            [29.61133, 55.77639],
            [29.58558, 55.75896],
            [29.57992, 55.73673],
            [29.51683, 55.70214],
            [29.48241, 55.70652],
            [29.44001, 55.73287],
            [29.40285, 55.75149],
            [29.36328, 55.75527],
            [29.35847, 55.76462],
            [29.3704, 55.78025],
            [29.360427, 55.797403],
            [29.391138, 55.821943],
            [29.384231, 55.834168],
            [29.396902, 55.848572],
            [29.403618, 55.872377],
            [29.425252, 55.872558],
            [29.394538, 55.902046],
            [29.429524, 55.904575],
            [29.479157, 55.918962],
            [29.471207, 55.928767],
            [29.440055, 55.941627],
            [29.421441, 55.941231],
            [29.44626, 55.958552],
            [29.395967, 55.973467],
            [29.357555, 55.971497],
            [29.305426, 55.99142],
            [29.286161, 55.988636],
            [29.255842, 55.997019],
            [29.212319, 55.990563],
            [29.205969, 55.999963],
            [29.156347, 56.026948],
            [29.13422, 56.028401],
            [29.115199, 56.015999],
            [29.100464, 56.018664],
            [29.088155, 56.034096],
            [29.065497, 56.029106],
            [29.012078, 56.025934],
            [29.003569, 56.012388],
            [28.952577, 56.012893],
            [28.914555, 55.993622],
            [28.85771, 55.988538],
            [28.841365, 55.95508],
            [28.80981, 55.972421],
            [28.777452, 55.973484],
            [28.737046, 55.969152],
            [28.701632, 56.001758],
            [28.704613, 56.014984],
            [28.68951, 56.03491],
            [28.65664, 56.04692],
            [28.65423, 56.06151],
            [28.63544, 56.07619],
            [28.67629, 56.09207],
            [28.67303, 56.10297],
            [28.64084, 56.10512],
            [28.6199, 56.09936],
            [28.54978, 56.11447],
            [28.52454, 56.10538],
            [28.4648, 56.10615],
            [28.42009, 56.08795],
            [28.42395, 56.0955],
            [28.39262, 56.11104],
            [28.37382, 56.10838],
            [28.37159, 56.09619],
            [28.3909, 56.07696],
            [28.37966, 56.06186],
            [28.35606, 56.05817],
            [28.33872, 56.06418],
            [28.3152, 56.0598],
            [28.293816, 56.066224],
            [28.27795, 56.09001],
            [28.24079, 56.08572],
            [28.24405, 56.10992],
            [28.21375, 56.12331],
            [28.196056, 56.138704],
            [28.155489, 56.14332],
            [28.155919, 56.170678],
            [28.17953, 56.179989],
            [28.183999, 56.203248],
            [28.20388, 56.214683],
            [28.185322, 56.230656],
            [28.218247, 56.248409],
            [28.241665, 56.278862],
            [28.206253, 56.322863],
            [28.189754, 56.361671],
            [28.165597, 56.374708],
            [28.187145, 56.403866],
            [28.18259, 56.427973],
            [28.194577, 56.442573],
            [28.12959, 56.490771],
            [28.130177, 56.508411],
            [28.097587, 56.511152],
            [28.092546, 56.529461],
            [28.105636, 56.542995],
            [28.136352, 56.549116],
            [28.143571, 56.571691],
            [28.124786, 56.582678],
            [28.053618, 56.584799],
            [28.011438, 56.636691],
            [28.012734, 56.672437],
            [27.994128, 56.688875],
            [27.97636, 56.693207],
            [27.980034, 56.707286],
            [27.962465, 56.706904],
            [27.93606, 56.728326],
            [27.917415, 56.733771],
            [27.900609, 56.759879],
            [27.920011, 56.774798],
            [27.94398, 56.776315],
            [27.942994, 56.801535],
            [27.976488, 56.811915],
            [27.967822, 56.836846],
            [27.902591, 56.822423],
            [27.873961, 56.877419],
            [27.847328, 56.880125],
            [27.824167, 56.867313],
            [27.732396, 56.857217],
            [27.70359, 56.847589],
            [27.692228, 56.837219],
            [27.659445, 56.834365],
            [27.655641, 56.851269],
            [27.67057, 56.869889],
            [27.672536, 56.896066],
            [27.686303, 56.915829],
            [27.704606, 56.912291],
            [27.737645, 56.920241],
            [27.742987, 56.9571],
            [27.76852, 57.002505],
            [27.731427, 57.001283],
            [27.714762, 57.007441],
            [27.727805, 57.038177],
            [27.749215, 57.055903],
            [27.74298, 57.067729],
            [27.768726, 57.085606],
            [27.75177, 57.084078],
            [27.719101, 57.093104],
            [27.722212, 57.100357],
            [27.75239, 57.098304],
            [27.733461, 57.113309],
            [27.757204, 57.13096],
            [27.816832, 57.142388],
            [27.83331, 57.166626],
            [27.825574, 57.192115],
            [27.83795, 57.220022],
            [27.85868, 57.24482],
            [27.870156, 57.289795],
            [27.858382, 57.304263],
            [27.815613, 57.324175],
            [27.782231, 57.320408],
            [27.779423, 57.343323],
            [27.758606, 57.354178],
            [27.681932, 57.373246],
            [27.671273, 57.395023],
            [27.623944, 57.401567],
            [27.603581, 57.415636],
            [27.557111, 57.415678],
            [27.517335, 57.426961],
            [27.53665, 57.460917],
            [27.54689, 57.459247],
            [27.566494, 57.514809],
            [27.546923, 57.519272],
            [27.55635, 57.537774],
            [27.500638, 57.540215],
            [27.443733, 57.531877],
            [27.415077, 57.522641],
            [27.38044, 57.535515],
            [27.352455, 57.518662],
            [27.336307, 57.546236],
            [27.332788, 57.565064],
            [27.317455, 57.579984],
            [27.356598, 57.59779],
            [27.377722, 57.596073],
            [27.404757, 57.612573],
            [27.388801, 57.616006],
            [27.399047, 57.636672],
            [27.377588, 57.64852],
            [27.376388, 57.667844],
            [27.431497, 57.709217],
            [27.451662, 57.716683],
            [27.461864, 57.707227],
            [27.492572, 57.722882],
            [27.497026, 57.708084],
            [27.525588, 57.709414],
            [27.527713, 57.730297],
            [27.545464, 57.733337],
            [27.527858, 57.741373],
            [27.526376, 57.757257],
            [27.507832, 57.763547],
            [27.512618, 57.77492],
            [27.494324, 57.788902],
            [27.528057, 57.789762],
            [27.552423, 57.834173],
            [27.576216, 57.83176],
            [27.639904, 57.840997],
            [27.687687, 57.835562],
            [27.708115, 57.84095],
            [27.740926, 57.827721],
            [27.77632, 57.833464],
            [27.81309, 57.859975],
            [27.816309, 57.895402],
            [27.770659, 57.894604],
            [27.730138, 57.90593],
            [27.705432, 57.900129],
            [27.694911, 57.907453],
            [27.719157, 57.912105],
            [27.709919, 57.922796],
            [27.684455, 57.928139],
            [27.656366, 57.955335],
            [27.684455, 57.960016],
            [27.678626, 57.974435],
            [27.697184, 57.988741],
            [27.694367, 58.003926],
            [27.651344, 58.019678],
            [27.653932, 57.995408],
            [27.618005, 58.013537],
            [27.608029, 58.054308],
            [27.634458, 58.097153],
            [27.590273, 58.118556],
            [27.490969, 58.224633],
            [27.48833, 58.303981],
            [27.552989, 58.366271],
            [27.437224, 58.61585],
            [27.357337, 58.787222],
            [27.642773, 58.928607],
            [27.737764, 58.97789],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5492",
      properties: {
        name: "Республика Калмыкия",
        density: 0,
        path: "/world/Russia/Республика Калмыкия",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.711218, 47.474225],
            [43.667597, 47.548888],
            [43.714769, 47.717624],
            [43.752131, 47.733121],
            [43.781057, 47.733121],
            [43.806195, 47.745863],
            [43.87145, 47.760843],
            [43.945831, 47.753096],
            [43.972712, 47.7745],
            [44.024343, 47.824035],
            [44.010224, 47.839875],
            [44.08259, 47.899435],
            [44.155024, 47.886882],
            [44.149687, 47.874313],
            [44.168627, 47.883783],
            [44.355441, 47.853998],
            [44.390048, 47.891534],
            [44.391252, 47.919427],
            [44.422416, 47.956618],
            [44.424307, 48.042363],
            [44.370587, 48.043912],
            [44.325132, 48.041328],
            [44.325646, 48.141192],
            [44.327921, 48.213596],
            [44.326409, 48.238797],
            [44.381122, 48.250449],
            [44.382409, 48.233604],
            [44.437292, 48.229747],
            [44.435767, 48.20623],
            [44.478019, 48.201998],
            [44.50462, 48.184844],
            [44.544157, 48.188073],
            [44.541511, 48.049884],
            [44.593512, 48.054301],
            [44.644307, 48.052937],
            [44.66317, 48.056717],
            [44.663061, 48.086332],
            [44.809767, 48.050632],
            [44.873692, 48.090407],
            [44.925662, 48.120248],
            [44.915648, 48.164199],
            [44.936032, 48.195892],
            [44.907817, 48.220676],
            [44.959474, 48.270909],
            [45.16327, 48.220678],
            [45.144133, 48.187352],
            [45.237731, 48.163267],
            [45.262919, 48.105305],
            [45.379503, 48.099367],
            [45.48597, 48.034698],
            [45.538544, 48.000163],
            [45.56428, 48.000053],
            [45.595708, 48.01152],
            [45.675186, 48.027125],
            [45.709239, 48.031621],
            [45.756974, 47.999506],
            [45.714082, 47.930545],
            [45.739711, 47.830349],
            [45.784475, 47.813522],
            [45.84068, 47.720255],
            [45.899852, 47.716297],
            [45.921851, 47.644367],
            [46.01468, 47.560119],
            [46.087272, 47.515247],
            [46.159093, 47.476423],
            [46.267429, 47.420222],
            [46.399411, 47.413074],
            [46.488827, 47.474887],
            [46.539199, 47.507883],
            [46.586382, 47.541869],
            [46.686796, 47.600822],
            [46.703184, 47.606652],
            [46.761806, 47.607203],
            [46.788752, 47.586746],
            [46.849366, 47.558483],
            [46.670632, 47.481379],
            [46.537442, 47.402958],
            [46.651172, 47.215875],
            [46.740043, 47.073335],
            [46.779308, 47.053539],
            [46.812525, 46.999756],
            [46.910962, 46.98491],
            [46.961775, 46.973802],
            [46.978936, 46.856998],
            [46.964859, 46.833021],
            [46.957767, 46.718362],
            [46.865823, 46.619374],
            [46.949854, 46.523028],
            [46.966353, 46.445819],
            [46.857248, 46.456596],
            [46.663456, 46.440756],
            [46.660156, 46.439436],
            [46.558972, 46.351447],
            [46.50222, 46.374543],
            [46.409834, 46.31735],
            [46.414014, 46.304592],
            [46.502882, 46.280836],
            [46.449872, 46.17173],
            [46.462825, 46.042914],
            [46.460982, 45.972329],
            [46.295463, 45.965225],
            [46.287833, 45.919122],
            [46.400368, 45.819715],
            [46.550585, 45.799342],
            [46.544944, 45.760209],
            [46.49082, 45.690055],
            [46.501358, 45.680098],
            [46.549751, 45.67284],
            [46.695152, 45.646005],
            [46.677555, 45.617629],
            [46.685254, 45.61301],
            [46.752564, 45.61301],
            [46.770383, 45.581115],
            [46.813717, 45.567917],
            [46.853971, 45.571657],
            [46.911606, 45.591167],
            [46.947678, 45.596515],
            [46.970299, 45.621809],
            [47.016968, 45.617193],
            [47.115734, 45.610815],
            [47.155109, 45.610376],
            [47.21186, 45.618515],
            [47.243535, 45.640293],
            [47.270811, 45.649092],
            [47.288849, 45.646672],
            [47.294348, 45.670429],
            [47.387835, 45.667791],
            [47.401033, 45.655033],
            [47.42655, 45.657673],
            [47.440408, 45.649534],
            [47.491221, 45.643815],
            [47.518467, 45.657173],
            [47.509533, 45.641228],
            [47.536849, 45.644026],
            [47.536615, 45.618315],
            [47.504505, 45.620333],
            [47.493027, 45.607853],
            [47.504336, 45.600128],
            [47.507761, 45.584519],
            [47.49693, 45.576476],
            [47.508319, 45.539921],
            [47.525402, 45.516259],
            [47.457839, 45.475568],
            [47.423999, 45.461146],
            [47.40473, 45.431925],
            [47.367791, 45.431235],
            [47.381278, 45.410048],
            [47.410118, 45.39245],
            [47.415267, 45.366888],
            [47.402564, 45.348795],
            [47.369605, 45.321282],
            [47.359992, 45.339867],
            [47.342439, 45.341093],
            [47.341147, 45.321119],
            [47.315031, 45.298211],
            [47.31948, 45.274431],
            [47.302314, 45.257274],
            [47.296478, 45.240597],
            [47.277252, 45.240838],
            [47.256996, 45.249299],
            [47.275192, 45.234311],
            [47.275535, 45.2065],
            [47.284461, 45.191501],
            [47.282058, 45.172384],
            [47.271072, 45.163912],
            [47.262489, 45.134129],
            [47.250816, 45.153018],
            [47.241203, 45.150354],
            [47.247726, 45.126621],
            [47.22953, 45.10312],
            [47.23262, 45.085912],
            [47.243263, 45.07476],
            [47.231246, 45.066516],
            [47.216827, 45.080094],
            [47.202064, 45.072336],
            [47.196227, 45.048812],
            [47.199661, 45.021639],
            [47.179748, 45.003679],
            [47.149192, 44.945632],
            [47.118293, 44.939071],
            [47.103874, 44.956809],
            [47.094604, 44.956323],
            [47.062675, 44.985955],
            [47.040359, 44.994453],
            [47.036582, 44.982798],
            [47.065765, 44.976484],
            [47.064906, 44.958145],
            [47.099925, 44.910144],
            [47.10456, 44.874147],
            [47.091857, 44.866482],
            [47.069198, 44.839101],
            [47.060443, 44.837275],
            [47.047225, 44.8576],
            [47.048427, 44.874633],
            [47.030574, 44.883999],
            [47.012893, 44.87074],
            [47.004482, 44.908928],
            [46.987144, 44.921206],
            [46.991264, 44.949034],
            [46.978904, 44.950127],
            [46.967231, 44.916343],
            [46.959335, 44.880594],
            [46.950065, 44.871835],
            [46.942512, 44.830945],
            [46.931011, 44.817917],
            [46.916591, 44.829241],
            [46.889812, 44.8167],
            [46.895027, 44.8121],
            [46.860148, 44.799174],
            [46.826134, 44.808281],
            [46.807733, 44.80382],
            [46.825019, 44.788951],
            [46.836915, 44.770179],
            [46.812566, 44.762929],
            [46.812938, 44.77055],
            [46.787288, 44.788393],
            [46.755132, 44.80289],
            [46.772789, 44.819433],
            [46.766841, 44.827611],
            [46.690077, 44.846569],
            [46.672977, 44.859579],
            [46.63506, 44.861066],
            [46.584875, 44.889875],
            [46.570562, 44.907346],
            [46.504579, 44.934296],
            [46.484505, 44.930393],
            [46.470751, 44.935783],
            [46.43432, 44.938756],
            [46.430603, 44.934481],
            [46.383206, 44.947306],
            [46.358672, 44.9408],
            [46.347334, 44.951023],
            [46.325959, 44.949721],
            [46.248823, 44.965705],
            [46.223731, 44.972768],
            [46.200312, 44.958456],
            [46.161465, 44.963102],
            [46.155332, 44.958827],
            [46.117414, 44.969793],
            [46.083029, 44.968677],
            [46.028941, 44.981315],
            [46.01054, 44.980757],
            [45.982474, 44.989121],
            [45.969277, 44.980757],
            [45.881362, 44.988005],
            [45.846047, 44.985774],
            [45.813705, 44.991721],
            [45.793632, 44.987632],
            [45.797349, 44.976852],
            [45.783409, 44.968487],
            [45.748838, 44.975364],
            [45.734712, 44.972762],
            [45.705116, 44.977363],
            [45.715953, 44.995841],
            [45.659504, 45.049073],
            [45.586328, 45.12112],
            [45.590027, 45.125784],
            [45.463781, 45.174672],
            [45.19376, 45.283866],
            [45.136346, 45.306059],
            [45.056417, 45.33388],
            [44.895113, 45.387432],
            [44.893182, 45.403514],
            [44.837377, 45.42828],
            [44.829657, 45.437607],
            [44.793312, 45.429244],
            [44.742814, 45.434872],
            [44.723676, 45.445164],
            [44.632811, 45.46414],
            [44.598395, 45.478292],
            [44.577006, 45.505149],
            [44.534066, 45.499037],
            [44.514607, 45.510455],
            [44.497721, 45.501771],
            [44.451726, 45.507077],
            [44.365846, 45.544708],
            [44.364238, 45.551945],
            [44.319047, 45.551302],
            [44.297336, 45.569313],
            [44.278037, 45.574942],
            [44.274499, 45.589255],
            [44.234615, 45.599225],
            [44.181222, 45.635409],
            [44.13812, 45.682208],
            [44.123003, 45.705848],
            [44.121555, 45.731097],
            [44.105955, 45.738173],
            [44.088585, 45.78642],
            [44.091158, 45.796391],
            [44.059315, 45.804431],
            [44.042107, 45.819548],
            [44.035512, 45.849461],
            [44.016856, 45.87085],
            [43.982762, 45.890148],
            [43.934515, 45.897545],
            [43.91811, 45.944987],
            [43.893183, 45.944183],
            [43.847027, 45.95737],
            [43.810038, 45.956404],
            [43.772567, 45.973772],
            [43.723194, 45.982617],
            [43.691834, 45.979239],
            [43.677199, 45.98326],
            [43.638442, 45.980847],
            [43.619304, 45.990496],
            [43.603865, 45.978917],
            [43.598558, 45.986797],
            [43.570093, 45.979077],
            [43.511875, 45.989208],
            [43.46974, 45.988886],
            [43.404768, 46.017029],
            [43.359737, 46.03311],
            [43.310364, 46.08988],
            [43.281737, 46.104032],
            [43.257453, 46.110464],
            [43.218374, 46.112233],
            [43.164337, 46.128797],
            [43.069935, 46.151471],
            [43.033267, 46.163693],
            [43.001746, 46.169322],
            [42.961862, 46.1965],
            [42.931787, 46.236062],
            [42.913775, 46.236222],
            [42.880004, 46.20647],
            [42.870676, 46.207113],
            [42.84784, 46.187975],
            [42.862475, 46.176396],
            [42.751671, 46.099683],
            [42.737359, 46.09132],
            [42.667402, 46.086494],
            [42.6042, 46.039855],
            [42.554668, 46.006404],
            [42.485676, 45.996271],
            [42.319871, 45.968447],
            [42.319547, 46.04162],
            [42.329676, 46.135219],
            [42.31472, 46.13554],
            [42.170947, 46.111897],
            [42.167571, 46.069923],
            [42.180275, 46.071371],
            [42.176577, 46.039367],
            [42.163068, 46.039045],
            [42.151814, 45.941748],
            [42.08057, 45.930811],
            [42.083464, 45.96571],
            [41.924894, 45.974231],
            [41.902802, 45.967967],
            [41.804885, 45.978374],
            [41.71441, 45.982428],
            [41.701913, 46.052122],
            [41.648689, 46.138508],
            [41.640864, 46.154467],
            [41.654367, 46.167058],
            [41.637826, 46.176103],
            [41.635671, 46.186534],
            [41.653301, 46.188841],
            [41.656206, 46.179837],
            [41.688451, 46.186322],
            [41.679027, 46.199885],
            [41.698772, 46.216062],
            [41.74397, 46.193781],
            [41.738463, 46.185582],
            [41.863031, 46.143873],
            [41.886388, 46.175591],
            [41.962656, 46.176349],
            [41.962394, 46.203273],
            [41.911261, 46.203514],
            [41.911855, 46.254929],
            [41.962993, 46.254877],
            [41.962308, 46.297794],
            [42.170595, 46.297562],
            [42.175251, 46.36082],
            [42.163927, 46.382901],
            [42.100883, 46.374208],
            [42.066559, 46.483079],
            [42.051149, 46.508358],
            [42.05589, 46.522281],
            [42.044824, 46.533957],
            [42.049948, 46.545913],
            [42.062775, 46.544362],
            [42.12185, 46.555379],
            [42.200366, 46.558404],
            [42.252638, 46.543177],
            [42.279098, 46.530757],
            [42.332557, 46.527086],
            [42.37403, 46.514126],
            [42.397084, 46.511122],
            [42.416798, 46.498683],
            [42.415718, 46.482267],
            [42.44369, 46.466932],
            [42.488942, 46.464124],
            [42.551475, 46.413797],
            [42.560547, 46.399325],
            [42.598456, 46.383882],
            [42.653212, 46.372435],
            [42.692415, 46.367575],
            [42.769106, 46.368682],
            [42.79086, 46.382572],
            [42.781082, 46.402784],
            [42.93173, 46.446915],
            [43.038985, 46.372332],
            [43.126142, 46.313581],
            [43.212219, 46.252886],
            [43.333072, 46.169188],
            [43.339229, 46.167136],
            [43.424656, 46.162817],
            [43.533951, 46.160119],
            [43.593567, 46.17578],
            [43.623158, 46.177832],
            [43.687793, 46.288046],
            [43.72948, 46.335351],
            [43.745787, 46.363431],
            [43.72343, 46.394966],
            [43.733905, 46.410843],
            [43.806266, 46.383627],
            [43.815338, 46.384384],
            [43.824302, 46.35166],
            [43.847739, 46.332004],
            [43.885215, 46.315049],
            [43.846876, 46.29118],
            [43.860376, 46.271525],
            [43.894935, 46.300253],
            [43.957575, 46.319154],
            [43.975825, 46.360302],
            [43.922905, 46.388381],
            [43.93014, 46.419269],
            [43.91286, 46.419485],
            [43.913615, 46.446917],
            [43.930246, 46.494221],
            [43.930569, 46.535045],
            [43.872897, 46.551784],
            [43.808097, 46.574571],
            [43.82354, 46.598763],
            [43.880131, 46.625224],
            [43.911557, 46.677928],
            [43.903025, 46.685596],
            [43.915336, 46.71508],
            [44.033594, 46.764545],
            [44.048389, 46.800077],
            [44.086836, 46.835502],
            [44.151636, 46.82319],
            [44.256393, 46.899115],
            [44.264923, 46.979791],
            [44.285765, 47.047075],
            [44.319242, 47.140927],
            [44.319886, 47.264587],
            [44.318049, 47.305411],
            [44.319992, 47.359303],
            [44.317545, 47.413347],
            [44.310594, 47.413518],
            [44.310809, 47.478534],
            [44.230889, 47.478641],
            [44.230995, 47.550569],
            [44.186391, 47.550892],
            [44.185852, 47.536528],
            [44.13358, 47.544736],
            [44.132611, 47.413516],
            [44.109607, 47.414056],
            [43.964133, 47.40023],
            [43.925687, 47.328734],
            [43.939513, 47.25875],
            [43.793497, 47.26782],
            [43.786743, 47.354211],
            [43.711218, 47.474225],
          ],
          [
            [46.442228, 45.445634],
            [46.499031, 45.440957],
            [46.57744, 45.440067],
            [46.578109, 45.425811],
            [46.623328, 45.43539],
            [46.641147, 45.480164],
            [46.660304, 45.477268],
            [46.751856, 45.479719],
            [46.796407, 45.475933],
            [46.832125, 45.453183],
            [46.857051, 45.453028],
            [46.887071, 45.527964],
            [46.775467, 45.52984],
            [46.772793, 45.553006],
            [46.730693, 45.555679],
            [46.657629, 45.548772],
            [46.64226, 45.503998],
            [46.450469, 45.47927],
            [46.442228, 45.445634],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5536",
      properties: {
        name: "Республика Карелия",
        density: 0,
        path: "/world/Russia/Республика Карелия",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [36.66163, 64.09065],
              [36.68317, 64.11853],
              [36.69323, 64.10163],
              [36.66163, 64.09065],
            ],
          ],
          [
            [
              [36.647, 64.2001],
              [36.67682, 64.19449],
              [36.62336, 64.19006],
              [36.61175, 64.22043],
              [36.55538, 64.21875],
              [36.55051, 64.23116],
              [36.5831, 64.23984],
              [36.5846, 64.25363],
              [36.60106, 64.23991],
              [36.63942, 64.24075],
              [36.66452, 64.21695],
              [36.72016, 64.21976],
              [36.74873, 64.20059],
              [36.75542, 64.1784],
              [36.73928, 64.17843],
              [36.73609, 64.19836],
              [36.71443, 64.21428],
              [36.6631, 64.21071],
              [36.647, 64.2001],
            ],
          ],
          [
            [
              [36.45351, 64.22222],
              [36.50288, 64.21976],
              [36.49585, 64.21289],
              [36.4556, 64.20836],
              [36.45351, 64.22222],
            ],
          ],
          [
            [
              [36.41514, 64.33527],
              [36.42408, 64.34069],
              [36.45886, 64.33552],
              [36.4648, 64.32642],
              [36.49896, 64.32585],
              [36.47977, 64.31877],
              [36.41514, 64.33527],
            ],
          ],
          [
            [
              [36.50387, 64.28094],
              [36.42762, 64.28377],
              [36.39439, 64.29146],
              [36.38018, 64.31022],
              [36.40182, 64.31586],
              [36.40403, 64.30275],
              [36.44378, 64.29517],
              [36.43757, 64.30683],
              [36.46016, 64.30361],
              [36.45049, 64.28758],
              [36.48877, 64.29666],
              [36.50387, 64.28094],
            ],
          ],
          [
            [
              [36.10506, 64.38108],
              [36.12746, 64.38572],
              [36.14905, 64.37297],
              [36.10506, 64.38108],
            ],
          ],
          [
            [
              [35.43646, 64.39448],
              [35.46575, 64.40474],
              [35.46102, 64.39151],
              [35.43646, 64.39448],
            ],
          ],
          [
            [
              [35.72999, 64.37869],
              [35.71064, 64.39223],
              [35.74099, 64.39422],
              [35.76306, 64.38127],
              [35.75726, 64.37357],
              [35.72999, 64.37869],
            ],
          ],
          [
            [
              [35.52888, 64.40289],
              [35.58413, 64.41166],
              [35.5671, 64.39404],
              [35.54596, 64.38929],
              [35.52888, 64.40289],
            ],
          ],
          [
            [
              [36.12996, 64.33498],
              [36.09618, 64.33903],
              [36.09309, 64.34831],
              [36.12019, 64.34774],
              [36.12996, 64.33498],
            ],
          ],
          [
            [
              [36, 64.335937],
              [36.02128, 64.32357],
              [35.99039, 64.31077],
              [35.94977, 64.31146],
              [35.92816, 64.31894],
              [35.92256, 64.33569],
              [35.90321, 64.35092],
              [35.83599, 64.38064],
              [35.8658, 64.37725],
              [35.92471, 64.39759],
              [35.95135, 64.38681],
              [35.96466, 64.3676],
              [36.0028, 64.35429],
              [36, 64.335937],
            ],
          ],
          [
            [
              [29.305928, 61.329293],
              [29.334571, 61.355201],
              [29.502772, 61.461093],
              [29.49646, 61.491569],
              [29.591427, 61.495399],
              [29.645422, 61.5199],
              [29.64295, 61.525793],
              [29.688347, 61.551821],
              [29.740299, 61.573704],
              [29.754611, 61.603139],
              [29.804, 61.63425],
              [29.818549, 61.654928],
              [29.867902, 61.681714],
              [30.038728, 61.765001],
              [30.070922, 61.817965],
              [30.156421, 61.858226],
              [30.273228, 61.933052],
              [30.300987, 61.934996],
              [30.31302, 61.945009],
              [30.304448, 61.963221],
              [30.351857, 61.980152],
              [30.376499, 61.999088],
              [30.423175, 62.022371],
              [30.540525, 62.102845],
              [30.602068, 62.141349],
              [30.636704, 62.173217],
              [30.628358, 62.187784],
              [30.656406, 62.208588],
              [30.720412, 62.208906],
              [30.743802, 62.226223],
              [30.83449, 62.275338],
              [30.90435, 62.310261],
              [30.943272, 62.305969],
              [30.96225, 62.32725],
              [30.942442, 62.332999],
              [31.138311, 62.442084],
              [31.163415, 62.44549],
              [31.173402, 62.481886],
              [31.185833, 62.493574],
              [31.221835, 62.498295],
              [31.254319, 62.533626],
              [31.347378, 62.643784],
              [31.377337, 62.655675],
              [31.38623, 62.675207],
              [31.380135, 62.689855],
              [31.439061, 62.785375],
              [31.467492, 62.783279],
              [31.454699, 62.79627],
              [31.586855, 62.908527],
              [31.56406, 62.927285],
              [31.502025, 62.996249],
              [31.448791, 63.006126],
              [31.462523, 63.024219],
              [31.425429, 63.043149],
              [31.390525, 63.048091],
              [31.361219, 63.072034],
              [31.294229, 63.093861],
              [31.265855, 63.115467],
              [31.255958, 63.142009],
              [31.254729, 63.179136],
              [31.239037, 63.195214],
              [31.241646, 63.216642],
              [31.165326, 63.244231],
              [31.148312, 63.261519],
              [31.10288, 63.270759],
              [31.08691, 63.281591],
              [31.01852, 63.304024],
              [30.979874, 63.307818],
              [30.945056, 63.336349],
              [30.933044, 63.355921],
              [30.849989, 63.368565],
              [30.843856, 63.377128],
              [30.789711, 63.405088],
              [30.683796, 63.424371],
              [30.484325, 63.467039],
              [30.38562, 63.54578],
              [30.255672, 63.585976],
              [30.245716, 63.606968],
              [30.124351, 63.667873],
              [30.079376, 63.692457],
              [30.065128, 63.708125],
              [29.971745, 63.75705],
              [30.154965, 63.802332],
              [30.260859, 63.822138],
              [30.32251, 63.909877],
              [30.361705, 63.936558],
              [30.44776, 63.982998],
              [30.528247, 64.04911],
              [30.553653, 64.101777],
              [30.553811, 64.131983],
              [30.516935, 64.154595],
              [30.466911, 64.204338],
              [30.509293, 64.222645],
              [30.483432, 64.261639],
              [30.386828, 64.268827],
              [30.360509, 64.280485],
              [30.367528, 64.300027],
              [30.302729, 64.319616],
              [30.286868, 64.329348],
              [30.207032, 64.351058],
              [30.139254, 64.357634],
              [30.109635, 64.365268],
              [30.045383, 64.401775],
              [30.059068, 64.451039],
              [30.046683, 64.471353],
              [30.024542, 64.482838],
              [30.029227, 64.498861],
              [30.015313, 64.504059],
              [29.987039, 64.533619],
              [29.997738, 64.577179],
              [29.989621, 64.587631],
              [30.051627, 64.614975],
              [30.118088, 64.626065],
              [30.136573, 64.648883],
              [30.054709, 64.712355],
              [30.041973, 64.739699],
              [30.086482, 64.765894],
              [30.086896, 64.774192],
              [30.043385, 64.79223],
              [29.898374, 64.793405],
              [29.869989, 64.789957],
              [29.739663, 64.789755],
              [29.687673, 64.818756],
              [29.698495, 64.837028],
              [29.647035, 64.867447],
              [29.610097, 64.927446],
              [29.618854, 64.959338],
              [29.599663, 64.994514],
              [29.615123, 65.012198],
              [29.625074, 65.06191],
              [29.735422, 65.091646],
              [29.826864, 65.092036],
              [29.896753, 65.104839],
              [29.819345, 65.144459],
              [29.893525, 65.192955],
              [29.886701, 65.205263],
              [29.859988, 65.214908],
              [29.764769, 65.228628],
              [29.634791, 65.231302],
              [29.602179, 65.259977],
              [29.604971, 65.273652],
              [29.646826, 65.289023],
              [29.74717, 65.347239],
              [29.731936, 65.37371],
              [29.727942, 65.4001],
              [29.733681, 65.472201],
              [29.755185, 65.496927],
              [29.797972, 65.52542],
              [29.864331, 65.560209],
              [29.754492, 65.609293],
              [29.722582, 65.636831],
              [29.976238, 65.687447],
              [30.017092, 65.696527],
              [30.040137, 65.687125],
              [30.138463, 65.668687],
              [30.123276, 65.740178],
              [30.087578, 65.850597],
              [30.068611, 65.895381],
              [30.025043, 65.958258],
              [29.997496, 65.978578],
              [29.989474, 66.019576],
              [29.923935, 66.126249],
              [29.709818, 66.268139],
              [29.663318, 66.317707],
              [29.631093, 66.366578],
              [29.524286, 66.490698],
              [29.472675, 66.543448],
              [29.411463, 66.585478],
              [29.416594, 66.5925],
              [29.382805, 66.598461],
              [29.386821, 66.608083],
              [29.360196, 66.625603],
              [29.350718, 66.643917],
              [29.322073, 66.662859],
              [30.169505, 66.660657],
              [30.159515, 66.652838],
              [30.639788, 66.658435],
              [31.086424, 66.667324],
              [31.093639, 66.6588],
              [31.377101, 66.657621],
              [31.497476, 66.658709],
              [31.575857, 66.654003],
              [31.606008, 66.562249],
              [31.621161, 66.502378],
              [31.791938, 66.495233],
              [31.803098, 66.452053],
              [31.83003, 66.371375],
              [31.853877, 66.36348],
              [31.936984, 66.351047],
              [32.135482, 66.347773],
              [32.342661, 66.345393],
              [32.353833, 66.466637],
              [32.360389, 66.475171],
              [32.548149, 66.479487],
              [32.557279, 66.576101],
              [32.587114, 66.582213],
              [32.865577, 66.583263],
              [33.000331, 66.573173],
              [33.055272, 66.561726],
              [33.146063, 66.554993],
              [33.14352, 66.54851],
              [33.19607, 66.53848],
              [33.14218, 66.53726],
              [33.12542, 66.52168],
              [33.19141, 66.51637],
              [33.2523, 66.51705],
              [33.25857, 66.50311],
              [33.31167, 66.49816],
              [33.32865, 66.47434],
              [33.3548, 66.46815],
              [33.38395, 66.47166],
              [33.35063, 66.49015],
              [33.36616, 66.50162],
              [33.40134, 66.49821],
              [33.44712, 66.47942],
              [33.49943, 66.47082],
              [33.49075, 66.48517],
              [33.54687, 66.47552],
              [33.6089, 66.47401],
              [33.62854, 66.46627],
              [33.58877, 66.46118],
              [33.560908, 66.445312],
              [33.65483, 66.41988],
              [33.69174, 66.41537],
              [33.78934, 66.43332],
              [33.82707, 66.4344],
              [33.85845, 66.41812],
              [33.82962, 66.41496],
              [33.74418, 66.41833],
              [33.66332, 66.40271],
              [33.61419, 66.40291],
              [33.563, 66.3962],
              [33.54076, 66.40024],
              [33.4997, 66.38849],
              [33.50642, 66.37351],
              [33.53838, 66.37826],
              [33.62284, 66.36555],
              [33.63585, 66.37649],
              [33.6553, 66.36439],
              [33.60679, 66.3605],
              [33.60086, 66.35259],
              [33.65234, 66.34881],
              [33.67807, 66.35841],
              [33.68338, 66.34279],
              [33.6666, 66.33417],
              [33.62975, 66.3365],
              [33.56534, 66.33023],
              [33.45459, 66.32765],
              [33.49189, 66.31539],
              [33.46499, 66.30525],
              [33.49987, 66.30117],
              [33.52746, 66.31387],
              [33.59801, 66.31298],
              [33.60392, 66.32062],
              [33.64332, 66.31944],
              [33.70199, 66.32558],
              [33.73758, 66.32439],
              [33.75765, 66.33606],
              [33.80492, 66.3417],
              [33.78427, 66.35306],
              [33.79791, 66.36143],
              [33.87236, 66.35924],
              [33.85901, 66.34141],
              [33.8754, 66.31856],
              [33.92406, 66.3101],
              [33.839, 66.31403],
              [33.79709, 66.31553],
              [33.78521, 66.30166],
              [33.75, 66.299619],
              [33.648975, 66.303889],
              [33.66894, 66.2907],
              [33.71212, 66.27887],
              [33.77218, 66.27053],
              [33.79979, 66.28035],
              [33.91289, 66.28255],
              [33.93854, 66.27217],
              [33.99034, 66.26571],
              [33.93622, 66.26106],
              [33.91053, 66.25252],
              [33.85229, 66.25579],
              [33.813, 66.26674],
              [33.80296, 66.2604],
              [33.86251, 66.23911],
              [33.90173, 66.23684],
              [33.92867, 66.2285],
              [33.94483, 66.23672],
              [33.95323, 66.22426],
              [34.02596, 66.22099],
              [34.05039, 66.22952],
              [34.10982, 66.22655],
              [34.1208, 66.21688],
              [34.16581, 66.20389],
              [34.18598, 66.17509],
              [34.22236, 66.18197],
              [34.26881, 66.16216],
              [34.26269, 66.15352],
              [34.31843, 66.1441],
              [34.38552, 66.1118],
              [34.45997, 66.09015],
              [34.51168, 66.07993],
              [34.55791, 66.05287],
              [34.5853, 66.04799],
              [34.60744, 66.03497],
              [34.60392, 66.02401],
              [34.63742, 65.99732],
              [34.69867, 65.98335],
              [34.71659, 65.96986],
              [34.71206, 65.9584],
              [34.6816, 65.94339],
              [34.71824, 65.93838],
              [34.70769, 65.90218],
              [34.73135, 65.90984],
              [34.77717, 65.90267],
              [34.78045, 65.88677],
              [34.818, 65.89605],
              [34.83973, 65.89374],
              [34.83196, 65.8813],
              [34.85122, 65.87954],
              [34.8596, 65.85284],
              [34.88906, 65.84148],
              [34.91895, 65.81553],
              [34.93264, 65.8151],
              [34.90423, 65.79346],
              [35.00643, 65.79343],
              [34.99873, 65.78253],
              [35.0531, 65.74574],
              [35.00129, 65.72724],
              [35.02106, 65.71777],
              [34.97736, 65.71502],
              [34.97535, 65.70507],
              [35.02425, 65.68703],
              [35.00973, 65.6839],
              [34.96878, 65.69211],
              [34.92777, 65.67485],
              [34.89171, 65.68643],
              [34.85575, 65.68253],
              [34.85013, 65.67339],
              [34.82045, 65.68346],
              [34.74421, 65.6754],
              [34.72514, 65.66386],
              [34.75823, 65.6535],
              [34.73357, 65.6369],
              [34.75243, 65.63183],
              [34.75871, 65.61487],
              [34.78429, 65.60895],
              [34.76842, 65.60298],
              [34.79617, 65.57939],
              [34.74509, 65.55032],
              [34.76446, 65.5263],
              [34.73349, 65.50569],
              [34.67918, 65.50451],
              [34.67892, 65.49083],
              [34.6477, 65.48387],
              [34.68356, 65.45765],
              [34.69361, 65.42761],
              [34.67423, 65.4176],
              [34.58044, 65.4204],
              [34.5485, 65.43083],
              [34.51091, 65.42891],
              [34.49098, 65.41875],
              [34.55874, 65.40144],
              [34.60075, 65.38078],
              [34.5884, 65.37939],
              [34.55657, 65.40108],
              [34.54139, 65.3832],
              [34.48215, 65.38737],
              [34.45356, 65.38075],
              [34.44778, 65.36469],
              [34.41702, 65.36268],
              [34.39725, 65.37105],
              [34.31283, 65.37462],
              [34.30622, 65.36508],
              [34.37714, 65.36177],
              [34.42303, 65.3492],
              [34.4869, 65.34129],
              [34.50091, 65.32938],
              [34.52934, 65.33396],
              [34.46792, 65.28649],
              [34.47217, 65.27494],
              [34.4984, 65.27442],
              [34.52158, 65.26319],
              [34.53781, 65.26942],
              [34.53163, 65.24698],
              [34.55211, 65.2373],
              [34.58457, 65.24463],
              [34.58074, 65.23163],
              [34.61757, 65.22561],
              [34.60753, 65.21088],
              [34.65952, 65.19446],
              [34.73791, 65.19204],
              [34.73233, 65.1811],
              [34.68685, 65.16487],
              [34.70376, 65.15329],
              [34.67985, 65.14052],
              [34.72746, 65.11568],
              [34.71031, 65.09439],
              [34.79648, 65.07675],
              [34.8365, 65.07678],
              [34.80268, 65.04751],
              [34.78303, 65.05695],
              [34.77723, 65.0427],
              [34.80465, 65.01833],
              [34.83089, 65.02159],
              [34.80359, 64.99814],
              [34.86262, 64.99076],
              [34.86095, 64.97543],
              [34.93673, 64.99594],
              [34.92157, 64.9785],
              [34.90194, 64.97427],
              [34.88298, 64.95379],
              [34.84833, 64.94156],
              [34.83398, 64.96975],
              [34.80013, 64.96927],
              [34.8002, 64.97924],
              [34.75992, 64.97768],
              [34.71145, 64.9611],
              [34.71127, 64.94883],
              [34.7582, 64.947],
              [34.76826, 64.95302],
              [34.78774, 64.93744],
              [34.81142, 64.93076],
              [34.82984, 64.93565],
              [34.8439, 64.9209],
              [34.88079, 64.92248],
              [34.90304, 64.91325],
              [34.92514, 64.91885],
              [34.94128, 64.90866],
              [34.9014, 64.89856],
              [34.9315, 64.88167],
              [34.92197, 64.87463],
              [34.95936, 64.86749],
              [34.94947, 64.83897],
              [34.96066, 64.83575],
              [34.94119, 64.8158],
              [34.99677, 64.78794],
              [34.98343, 64.79414],
              [34.96008, 64.77001],
              [34.98954, 64.76298],
              [35.05028, 64.77365],
              [35.02174, 64.74488],
              [35.0465, 64.73967],
              [35.03223, 64.7171],
              [34.980469, 64.729492],
              [34.90463, 64.71471],
              [34.89892, 64.70148],
              [34.8739, 64.70282],
              [34.89041, 64.69084],
              [34.84345, 64.68146],
              [34.86053, 64.67356],
              [34.91297, 64.68032],
              [34.89647, 64.66646],
              [34.9207, 64.66282],
              [34.91672, 64.64881],
              [34.94221, 64.64643],
              [34.92859, 64.631],
              [34.95767, 64.62908],
              [34.99199, 64.64167],
              [35.00051, 64.66023],
              [35.02558, 64.66015],
              [34.99103, 64.63682],
              [34.97133, 64.63248],
              [34.99689, 64.61909],
              [34.91906, 64.58047],
              [34.85904, 64.56461],
              [34.82207, 64.54537],
              [34.82346, 64.53947],
              [34.86796, 64.5384],
              [34.82918, 64.51842],
              [34.84029, 64.50703],
              [34.86792, 64.5031],
              [34.86435, 64.49445],
              [34.90728, 64.49722],
              [34.9323, 64.51532],
              [34.93815, 64.50577],
              [34.98022, 64.5047],
              [34.97169, 64.51403],
              [34.98803, 64.52225],
              [35.019, 64.51518],
              [35.05597, 64.49778],
              [35.07551, 64.49699],
              [35.05931, 64.49466],
              [35.0648, 64.47693],
              [35.02644, 64.47951],
              [35.04634, 64.46798],
              [35.00918, 64.47054],
              [34.9897, 64.45955],
              [35.00201, 64.44556],
              [34.9784, 64.44256],
              [34.98847, 64.42221],
              [35.05126, 64.40702],
              [35.07252, 64.4144],
              [35.1391, 64.42598],
              [35.16503, 64.41716],
              [35.14544, 64.41616],
              [35.13218, 64.3941],
              [35.1698, 64.38753],
              [35.14278, 64.37833],
              [35.17327, 64.36407],
              [35.1526, 64.34902],
              [35.16642, 64.3446],
              [35.2024, 64.35903],
              [35.25698, 64.35487],
              [35.21541, 64.38877],
              [35.25206, 64.40305],
              [35.32849, 64.39751],
              [35.3025, 64.36952],
              [35.31317, 64.35604],
              [35.36295, 64.36497],
              [35.3611, 64.3366],
              [35.33673, 64.33443],
              [35.32185, 64.30499],
              [35.34991, 64.29533],
              [35.37644, 64.30232],
              [35.41118, 64.28843],
              [35.45901, 64.29635],
              [35.457, 64.31353],
              [35.48798, 64.31045],
              [35.51922, 64.32974],
              [35.54875, 64.33013],
              [35.5854, 64.31823],
              [35.61179, 64.32288],
              [35.62674, 64.34666],
              [35.5821, 64.37433],
              [35.58768, 64.38651],
              [35.62014, 64.39208],
              [35.6294, 64.37758],
              [35.66512, 64.38026],
              [35.65803, 64.36811],
              [35.69647, 64.36242],
              [35.74508, 64.34629],
              [35.75497, 64.32128],
              [35.7928, 64.32197],
              [35.80052, 64.32835],
              [35.83836, 64.31764],
              [35.85305, 64.31991],
              [35.83894, 64.3404],
              [35.86217, 64.34813],
              [35.88636, 64.33103],
              [35.90797, 64.30091],
              [35.89846, 64.2778],
              [35.92381, 64.26399],
              [35.90107, 64.23527],
              [35.92942, 64.23086],
              [35.96011, 64.20721],
              [35.95458, 64.19119],
              [35.99075, 64.18355],
              [36.01777, 64.18914],
              [36.04144, 64.21219],
              [36.09896, 64.19844],
              [36.10094, 64.18726],
              [36.14022, 64.17631],
              [36.164, 64.14815],
              [36.21153, 64.14327],
              [36.24349, 64.12592],
              [36.21437, 64.10711],
              [36.18455, 64.10751],
              [36.17209, 64.09319],
              [36.20692, 64.06785],
              [36.26191, 64.05305],
              [36.26217, 64.03538],
              [36.24853, 64.00599],
              [36.28819, 63.98359],
              [36.31276, 63.98431],
              [36.324, 63.99558],
              [36.37005, 63.98082],
              [36.41509, 63.97259],
              [36.46387, 63.98053],
              [36.45453, 63.96871],
              [36.48251, 63.95704],
              [36.553041, 63.952169],
              [36.548577, 63.929546],
              [36.562558, 63.867346],
              [36.548059, 63.840643],
              [36.558627, 63.794795],
              [36.581887, 63.79105],
              [36.590395, 63.748201],
              [36.515384, 63.683595],
              [36.493082, 63.66895],
              [36.455216, 63.626485],
              [36.297807, 63.618278],
              [36.290921, 63.621152],
              [35.93535, 63.605399],
              [35.892773, 63.576226],
              [36.029888, 63.465428],
              [36.086364, 63.453163],
              [36.129176, 63.448328],
              [36.192072, 63.425609],
              [36.261894, 63.280401],
              [36.245765, 63.211511],
              [36.319439, 63.224076],
              [36.391114, 63.225946],
              [36.405032, 63.195385],
              [36.355601, 63.162905],
              [36.324087, 63.161687],
              [36.307068, 63.15076],
              [36.313263, 63.113118],
              [36.274818, 63.082101],
              [36.246958, 63.051403],
              [36.258139, 63.01153],
              [36.487951, 62.838889],
              [36.528467, 62.793114],
              [36.550694, 62.785014],
              [36.821712, 62.69601],
              [36.989723, 62.677207],
              [37.070641, 62.648683],
              [37.180938, 62.652793],
              [37.251577, 62.677459],
              [37.275063, 62.671998],
              [37.323919, 62.677799],
              [37.368941, 62.677886],
              [37.477835, 62.652333],
              [37.513465, 62.651627],
              [37.521747, 62.637806],
              [37.548008, 62.622088],
              [37.540224, 62.590972],
              [37.564136, 62.562297],
              [37.577011, 62.499195],
              [37.610519, 62.492409],
              [37.635381, 62.477396],
              [37.617792, 62.46693],
              [37.600394, 62.438478],
              [37.610498, 62.430184],
              [37.584912, 62.401603],
              [37.594132, 62.379606],
              [37.58465, 62.370779],
              [37.596416, 62.357571],
              [37.545591, 62.324572],
              [37.533014, 62.297447],
              [37.514496, 62.28328],
              [37.476446, 62.26954],
              [37.455512, 62.241794],
              [37.480277, 62.235266],
              [37.452738, 62.215169],
              [37.452374, 62.198557],
              [37.473523, 62.198026],
              [37.525947, 62.21592],
              [37.555035, 62.21356],
              [37.588205, 62.219968],
              [37.636642, 62.209914],
              [37.701812, 62.212684],
              [37.716298, 62.189902],
              [37.758347, 62.173495],
              [37.737213, 62.166381],
              [37.773086, 62.138087],
              [37.755097, 62.120218],
              [37.772494, 62.118749],
              [37.784443, 62.093181],
              [37.776886, 62.081197],
              [37.798371, 62.058315],
              [37.802248, 62.036106],
              [37.823979, 62.03764],
              [37.81185, 62.007389],
              [37.836783, 61.98254],
              [37.888506, 61.98217],
              [37.943999, 61.972526],
              [37.924367, 61.925729],
              [37.934746, 61.909282],
              [37.921814, 61.890951],
              [37.911951, 61.859653],
              [37.895128, 61.840494],
              [37.901366, 61.831015],
              [37.895455, 61.804343],
              [37.868969, 61.781578],
              [37.876712, 61.771531],
              [37.85945, 61.755579],
              [37.876698, 61.745274],
              [37.884509, 61.722298],
              [37.869322, 61.703208],
              [37.841031, 61.684955],
              [37.800406, 61.68142],
              [37.775588, 61.643666],
              [37.784646, 61.631154],
              [37.759843, 61.615429],
              [37.775057, 61.574018],
              [37.763686, 61.564233],
              [37.785939, 61.537388],
              [37.81674, 61.529811],
              [37.758623, 61.531392],
              [37.705644, 61.539714],
              [37.569782, 61.524226],
              [37.52285, 61.529969],
              [37.467147, 61.511015],
              [37.30461, 61.510119],
              [37.291399, 61.528535],
              [37.29236, 61.550556],
              [37.170795, 61.557221],
              [37.057142, 61.599671],
              [37.032279, 61.599363],
              [37.029972, 61.614232],
              [36.883783, 61.56252],
              [36.873933, 61.539284],
              [36.721623, 61.550363],
              [36.695913, 61.560066],
              [36.584329, 61.53946],
              [36.576504, 61.515389],
              [36.495489, 61.44098],
              [36.516838, 61.427001],
              [36.499441, 61.416318],
              [36.459932, 61.407254],
              [35.829805, 61.243138],
              [35.697879, 61.207401],
              [35.600599, 61.178962],
              [35.524781, 61.181714],
              [35.515101, 61.172497],
              [35.462934, 61.157846],
              [35.451457, 61.160976],
              [35.416332, 61.142716],
              [35.393668, 61.141498],
              [35.402273, 61.175727],
              [35.350481, 61.177392],
              [35.358332, 61.225778],
              [35.393194, 61.282483],
              [35.374877, 61.276802],
              [35.352676, 61.284975],
              [35.297757, 61.252311],
              [35.224606, 61.247556],
              [35.19855, 61.260192],
              [35.169801, 61.236789],
              [35.084506, 61.23741],
              [35.078941, 61.248481],
              [35.050276, 61.263523],
              [34.949866, 61.264969],
              [34.905015, 61.282678],
              [34.87441, 61.287068],
              [34.794331, 61.289675],
              [34.770245, 61.312745],
              [34.73851, 61.311932],
              [34.749234, 61.296818],
              [34.731961, 61.295485],
              [34.658173, 61.274254],
              [34.545519, 61.274948],
              [34.541144, 61.260703],
              [34.513003, 61.243328],
              [34.478428, 61.235342],
              [34.478255, 61.219315],
              [34.463011, 61.208084],
              [34.490487, 61.20039],
              [34.470345, 61.18458],
              [34.467362, 61.163857],
              [34.442786, 61.145134],
              [34.421774, 61.145192],
              [34.373283, 61.194736],
              [34.358154, 61.1952],
              [34.333923, 61.220559],
              [34.304854, 61.222877],
              [34.241961, 61.237309],
              [34.236106, 61.244265],
              [34.186169, 61.244032],
              [34.186692, 61.235685],
              [33.976599, 61.233624],
              [33.667677, 61.23243],
              [33.6682, 61.214171],
              [33.608844, 61.219011],
              [33.550967, 61.21201],
              [33.51277, 61.184332],
              [33.501003, 61.183694],
              [33.483847, 61.164391],
              [33.450692, 61.153015],
              [33.471532, 61.130554],
              [33.507095, 61.109659],
              [33.523383, 61.109659],
              [33.543903, 61.094443],
              [33.544948, 61.078634],
              [33.59135, 61.063795],
              [33.63633, 61.065883],
              [33.66108, 61.07381],
              [33.660789, 61.096112],
              [33.685683, 61.112502],
              [33.725157, 61.113473],
              [33.738691, 61.130023],
              [33.835001, 61.104795],
              [33.843841, 61.09426],
              [33.881054, 61.101564],
              [33.920413, 61.089233],
              [33.9194, 61.073669],
              [33.884942, 61.045368],
              [33.863497, 61.016023],
              [33.861353, 61.002531],
              [33.838545, 60.991416],
              [33.819795, 60.971447],
              [33.812841, 60.947058],
              [33.783858, 60.946884],
              [33.785424, 60.93703],
              [33.719547, 60.939753],
              [33.691027, 60.95649],
              [33.607877, 60.958575],
              [33.587965, 60.973805],
              [33.545476, 60.984093],
              [33.542432, 61.004323],
              [33.449051, 61.003278],
              [33.421693, 60.983135],
              [33.466386, 60.951414],
              [33.466504, 60.927591],
              [33.426219, 60.913896],
              [33.385615, 60.909794],
              [33.350403, 60.897911],
              [33.330695, 60.899418],
              [33.249372, 60.873072],
              [33.26111, 60.867652],
              [33.220189, 60.843379],
              [33.2059, 60.854681],
              [33.1977, 60.833843],
              [33.204424, 60.825627],
              [33.179558, 60.820888],
              [33.211091, 60.80618],
              [33.244682, 60.806702],
              [33.264762, 60.794883],
              [33.173097, 60.786138],
              [33.154203, 60.754431],
              [33.157684, 60.739337],
              [33.073887, 60.721259],
              [33.026625, 60.720911],
              [32.923099, 60.710952],
              [32.915654, 60.679797],
              [32.419333, 60.763544],
              [32.09427, 60.817666],
              [31.517454, 60.921182],
              [30.939044, 61.026161],
              [30.384933, 61.120144],
              [29.996847, 61.147145],
              [29.902393, 61.162069],
              [29.854803, 61.172328],
              [29.812496, 61.162331],
              [29.795129, 61.17107],
              [29.720548, 61.172914],
              [29.707642, 61.177481],
              [29.650015, 61.175744],
              [29.629958, 61.18241],
              [29.582921, 61.177989],
              [29.564198, 61.183669],
              [29.558341, 61.212478],
              [29.563615, 61.224882],
              [29.551238, 61.240634],
              [29.517735, 61.240619],
              [29.5153, 61.249371],
              [29.48907, 61.255501],
              [29.424469, 61.256427],
              [29.367141, 61.277887],
              [29.335026, 61.308869],
              [29.305928, 61.329293],
            ],
          ],
          [
            [
              [35.08159, 64.44036],
              [35.11856, 64.436],
              [35.08651, 64.42725],
              [35.08159, 64.44036],
            ],
          ],
          [
            [
              [35.02344, 64.43887],
              [35.00201, 64.44556],
              [35.02612, 64.43909],
              [35.02344, 64.43887],
            ],
          ],
          [
            [
              [35.66279, 64.61229],
              [35.62586, 64.62498],
              [35.64771, 64.63496],
              [35.7098, 64.60543],
              [35.66279, 64.61229],
            ],
          ],
          [
            [
              [35.561498, 64.6875],
              [35.59983, 64.67087],
              [35.5892, 64.65954],
              [35.6139, 64.64951],
              [35.5687, 64.65759],
              [35.50415, 64.67968],
              [35.50997, 64.68752],
              [35.55344, 64.69215],
              [35.561498, 64.6875],
            ],
          ],
          [
            [
              [34.980469, 64.708868],
              [34.99982, 64.69951],
              [34.97235, 64.69988],
              [34.980469, 64.708868],
            ],
          ],
          [
            [
              [35.0465, 64.73967],
              [35.09993, 64.75684],
              [35.09766, 64.74306],
              [35.0465, 64.73967],
            ],
          ],
          [
            [
              [35.09451, 64.826],
              [35.12983, 64.82447],
              [35.10217, 64.81642],
              [35.09451, 64.826],
            ],
          ],
          [
            [
              [35.00032, 64.81962],
              [34.99206, 64.82971],
              [35.04517, 64.82105],
              [35.00032, 64.81962],
            ],
          ],
          [
            [
              [35.21121, 64.93407],
              [35.23939, 64.94285],
              [35.21643, 64.92055],
              [35.21121, 64.93407],
            ],
          ],
          [
            [
              [35.0327, 64.97012],
              [35.01994, 64.95315],
              [34.9566, 64.9288],
              [34.93532, 64.94302],
              [35.00163, 64.97463],
              [35.0327, 64.97012],
            ],
          ],
          [
            [
              [35.10098, 64.92823],
              [35.10429, 64.94354],
              [35.17289, 64.95612],
              [35.191424, 64.931391],
              [35.16277, 64.92429],
              [35.17122, 64.91316],
              [35.1333, 64.92138],
              [35.06584, 64.89087],
              [35.06434, 64.90266],
              [35.10377, 64.91387],
              [35.10098, 64.92823],
            ],
          ],
          [
            [
              [35.02168, 65.03218],
              [35.04729, 65.03464],
              [35.0271, 65.01982],
              [35.02168, 65.03218],
            ],
          ],
          [
            [
              [34.86234, 65.04755],
              [34.88946, 65.05412],
              [34.91446, 65.04792],
              [34.86234, 65.04755],
            ],
          ],
          [
            [
              [35.0327, 64.97012],
              [35.02526, 64.99145],
              [35.06676, 64.98884],
              [35.08242, 64.98043],
              [35.05084, 64.96258],
              [35.0327, 64.97012],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5530",
      properties: {
        name: "Республика Коми",
        density: 0,
        path: "/world/Russia/Республика Коми",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.999789, 66.113264],
            [48.999585, 66.116202],
            [49.37584, 66.214971],
            [49.779813, 66.321395],
            [50.010487, 66.384098],
            [50.405685, 66.487392],
            [50.85815, 66.602618],
            [51.207526, 66.691604],
            [51.545125, 66.773977],
            [51.536532, 66.798],
            [51.49894, 66.817294],
            [51.488714, 66.834436],
            [51.501089, 66.846582],
            [51.540235, 66.863264],
            [51.545692, 66.880407],
            [51.514595, 66.897856],
            [51.505637, 66.92134],
            [51.526506, 66.939329],
            [51.616947, 66.947786],
            [51.656653, 66.937793],
            [51.693765, 66.921535],
            [51.719979, 66.917923],
            [51.749574, 66.926994],
            [51.776404, 66.919384],
            [51.789777, 66.946289],
            [51.754876, 66.948826],
            [51.785778, 66.959511],
            [51.831827, 66.945829],
            [51.82875, 66.964739],
            [51.846506, 66.977347],
            [51.843661, 66.990722],
            [51.865723, 66.994643],
            [51.830438, 67.000177],
            [51.871718, 67.003022],
            [51.887401, 66.995489],
            [51.929527, 67.006021],
            [51.931986, 67.016245],
            [52.057134, 67.029546],
            [52.048522, 67.050609],
            [52.068354, 67.064369],
            [52.14922, 67.099397],
            [52.55938, 67.077829],
            [53.291525, 67.036943],
            [53.556893, 67.019728],
            [53.874727, 67.000361],
            [54.282416, 67.000366],
            [54.92893, 67.000375],
            [55.408391, 67.000381],
            [55.887852, 67.000388],
            [56.501823, 67.000396],
            [57.068064, 67.000404],
            [57.705899, 67.000413],
            [58.224412, 67.00042],
            [58.83629, 67.000428],
            [59.9735, 67.000443],
            [59.9652, 66.97792],
            [59.921113, 66.977304],
            [59.907354, 66.964236],
            [59.924997, 66.951591],
            [60.044534, 66.965775],
            [60.109855, 67.000637],
            [61.320787, 67.000614],
            [61.345848, 67.001037],
            [61.375945, 66.98893],
            [61.381289, 66.974401],
            [61.425471, 66.983089],
            [61.454837, 66.981705],
            [61.461292, 67.009379],
            [61.47636, 67.00223],
            [61.514105, 67.007304],
            [61.508494, 66.995312],
            [61.541318, 67.00692],
            [61.566225, 67.006844],
            [61.597206, 66.992392],
            [61.597897, 67.002847],
            [61.644406, 67.003309],
            [61.638947, 67.019144],
            [61.657243, 67.013456],
            [61.716453, 67.033789],
            [61.707726, 67.056121],
            [61.742126, 67.076492],
            [61.729305, 67.09571],
            [61.777966, 67.097248],
            [61.785883, 67.111777],
            [61.828701, 67.115929],
            [61.846651, 67.126153],
            [62.150142, 67.19611],
            [62.484498, 67.27929],
            [62.753512, 67.350323],
            [62.863958, 67.369734],
            [62.845123, 67.384148],
            [62.878486, 67.389337],
            [62.873603, 67.405288],
            [62.897127, 67.402098],
            [62.909695, 67.412168],
            [62.900929, 67.436998],
            [62.916956, 67.445954],
            [62.882264, 67.485465],
            [62.851591, 67.491461],
            [62.856356, 67.500763],
            [62.834253, 67.519865],
            [62.880358, 67.52098],
            [62.919985, 67.539661],
            [62.913679, 67.568795],
            [62.928706, 67.588167],
            [62.913907, 67.596546],
            [62.943157, 67.605156],
            [63.06575, 67.62918],
            [63.119175, 67.647476],
            [63.139929, 67.67],
            [63.144213, 67.691409],
            [63.221356, 67.698982],
            [63.226813, 67.709744],
            [63.263673, 67.715663],
            [63.30384, 67.71355],
            [63.299457, 67.732883],
            [63.327822, 67.747297],
            [63.383593, 67.75339],
            [63.425642, 67.768995],
            [63.490986, 67.755197],
            [63.494408, 67.742821],
            [63.553371, 67.733097],
            [63.560212, 67.741726],
            [63.607106, 67.732291],
            [63.719841, 67.736712],
            [63.735025, 67.724375],
            [63.758087, 67.725028],
            [63.770155, 67.740403],
            [63.695238, 67.771535],
            [63.716569, 67.797633],
            [63.706344, 67.803668],
            [63.746971, 67.814968],
            [63.763365, 67.800209],
            [63.766651, 67.815545],
            [63.799436, 67.828345],
            [63.817694, 67.820812],
            [63.868123, 67.826539],
            [63.92128, 67.844874],
            [63.911324, 67.854521],
            [63.947799, 67.87201],
            [63.950988, 67.890113],
            [63.989348, 67.89119],
            [63.996996, 67.901222],
            [63.964978, 67.905565],
            [63.921465, 67.92951],
            [63.947447, 67.944923],
            [63.939278, 67.958952],
            [63.906913, 67.976325],
            [63.901299, 67.999617],
            [63.866319, 68.032518],
            [63.97164, 68.048741],
            [63.980326, 68.057428],
            [64.019897, 68.056198],
            [64.043688, 68.067768],
            [64.0729, 68.070305],
            [64.079356, 68.092098],
            [64.158843, 68.093329],
            [64.213902, 68.113239],
            [64.250264, 68.110165],
            [64.269404, 68.119544],
            [64.300616, 68.112395],
            [64.346815, 68.130691],
            [64.362498, 68.125771],
            [64.371567, 68.145681],
            [64.395474, 68.158058],
            [64.356268, 68.156289],
            [64.374178, 68.175738],
            [64.400008, 68.177199],
            [64.448513, 68.199108],
            [64.497789, 68.19857],
            [64.518085, 68.187424],
            [64.581889, 68.200339],
            [64.562205, 68.251075],
            [64.577655, 68.261452],
            [64.562741, 68.274597],
            [64.527994, 68.278748],
            [64.538292, 68.305576],
            [64.528912, 68.326024],
            [64.555587, 68.331329],
            [64.580261, 68.357235],
            [64.657595, 68.370919],
            [64.658593, 68.382603],
            [64.697797, 68.396441],
            [64.730162, 68.386909],
            [64.773211, 68.394443],
            [64.776438, 68.403975],
            [64.815797, 68.409971],
            [64.878586, 68.390408],
            [64.938469, 68.404861],
            [64.970429, 68.402863],
            [64.988035, 68.387488],
            [65.02801, 68.380801],
            [65.060296, 68.388335],
            [65.053222, 68.402556],
            [65.116123, 68.415317],
            [65.149065, 68.405401],
            [65.251538, 68.396908],
            [65.280922, 68.406671],
            [65.300372, 68.394449],
            [65.341268, 68.402214],
            [65.358103, 68.413975],
            [65.42283, 68.422662],
            [65.472578, 68.417891],
            [65.44049, 68.396327],
            [65.428235, 68.378696],
            [65.436016, 68.363875],
            [65.411423, 68.361759],
            [65.405622, 68.33647],
            [65.373345, 68.313321],
            [65.364489, 68.282158],
            [65.324039, 68.268639],
            [65.274676, 68.234566],
            [65.284121, 68.227142],
            [65.280376, 68.198504],
            [65.311004, 68.190353],
            [65.310891, 68.173499],
            [65.288958, 68.153982],
            [65.322634, 68.119883],
            [65.322088, 68.110485],
            [65.352139, 68.107793],
            [65.336759, 68.08832],
            [65.348994, 68.063172],
            [65.331834, 68.05422],
            [65.319394, 68.016118],
            [65.357862, 67.950353],
            [65.382919, 67.944782],
            [65.385561, 67.928762],
            [65.432183, 67.90966],
            [65.453463, 67.918514],
            [65.4894, 67.920222],
            [65.5432, 67.932502],
            [65.690547, 67.929199],
            [65.750547, 67.91861],
            [65.766259, 67.921182],
            [65.866145, 67.956043],
            [65.920661, 67.96083],
            [65.945242, 67.950062],
            [65.987643, 67.944247],
            [66.020693, 67.932718],
            [66.07661, 67.938814],
            [66.133509, 67.914682],
            [66.119031, 67.88728],
            [66.057176, 67.879331],
            [66.047795, 67.866182],
            [66.080581, 67.849507],
            [66.074298, 67.831083],
            [66.021731, 67.809888],
            [66.065215, 67.798077],
            [66.055807, 67.783174],
            [66.110666, 67.782191],
            [66.163149, 67.772819],
            [66.185166, 67.758957],
            [66.226747, 67.753885],
            [66.25068, 67.733047],
            [66.224767, 67.718531],
            [66.220695, 67.684884],
            [66.161257, 67.649424],
            [66.117604, 67.647701],
            [66.044432, 67.674547],
            [65.996714, 67.67737],
            [65.976326, 67.672615],
            [65.960725, 67.653882],
            [65.904437, 67.666742],
            [65.845715, 67.667578],
            [65.78579, 67.642054],
            [65.813708, 67.626718],
            [65.836791, 67.601611],
            [65.806473, 67.588875],
            [65.788083, 67.568425],
            [65.822687, 67.538601],
            [65.876801, 67.542269],
            [65.893079, 67.552989],
            [65.971078, 67.560416],
            [66.048175, 67.580506],
            [66.059843, 67.56531],
            [66.060745, 67.540621],
            [66.091752, 67.514165],
            [66.093311, 67.495058],
            [66.06951, 67.464988],
            [66.040582, 67.443929],
            [66.010179, 67.409108],
            [65.992447, 67.403085],
            [65.922929, 67.404582],
            [65.85407, 67.396179],
            [65.827343, 67.387172],
            [65.795274, 67.391766],
            [65.698554, 67.380796],
            [65.69548, 67.36228],
            [65.74717, 67.34372],
            [65.746773, 67.333938],
            [65.665897, 67.32427],
            [65.668852, 67.299021],
            [65.658006, 67.294547],
            [65.619661, 67.303601],
            [65.570202, 67.279413],
            [65.556663, 67.256931],
            [65.50968, 67.249252],
            [65.458811, 67.221479],
            [65.426237, 67.209387],
            [65.405533, 67.219455],
            [65.36408, 67.215417],
            [65.318911, 67.203139],
            [65.302119, 67.185523],
            [65.263249, 67.171558],
            [65.262585, 67.163616],
            [65.223308, 67.161529],
            [65.18091, 67.142545],
            [65.214029, 67.13368],
            [65.205183, 67.110883],
            [65.244684, 67.09368],
            [65.205406, 67.067292],
            [65.127976, 67.061901],
            [65.081882, 67.039277],
            [65.111176, 67.006182],
            [65.117673, 66.960843],
            [65.140295, 66.955128],
            [65.145955, 66.935037],
            [65.139159, 66.907727],
            [65.054753, 66.884621],
            [65.05176, 66.876503],
            [64.979456, 66.860543],
            [64.939573, 66.863404],
            [64.921903, 66.849913],
            [64.877128, 66.834849],
            [64.834147, 66.828869],
            [64.814796, 66.836576],
            [64.778325, 66.835303],
            [64.768064, 66.827809],
            [64.708453, 66.831029],
            [64.714178, 66.810624],
            [64.658993, 66.800424],
            [64.630696, 66.808178],
            [64.611075, 66.797046],
            [64.553329, 66.796648],
            [64.555757, 66.769045],
            [64.506595, 66.752184],
            [64.505894, 66.742547],
            [64.544723, 66.728846],
            [64.53158, 66.71483],
            [64.496341, 66.719664],
            [64.458896, 66.71398],
            [64.443604, 66.720463],
            [64.395068, 66.72215],
            [64.337775, 66.699157],
            [64.325124, 66.667262],
            [64.301372, 66.659457],
            [64.269698, 66.659842],
            [64.261545, 66.645425],
            [64.174878, 66.651075],
            [64.159809, 66.642068],
            [64.125606, 66.647817],
            [64.038427, 66.634757],
            [63.993179, 66.639145],
            [63.931975, 66.594275],
            [63.881065, 66.577164],
            [63.851482, 66.575783],
            [63.838289, 66.546448],
            [63.802101, 66.531855],
            [63.767974, 66.533668],
            [63.7477, 66.524034],
            [63.66223, 66.515292],
            [63.633352, 66.519951],
            [63.626707, 66.510935],
            [63.585116, 66.498071],
            [63.554035, 66.474148],
            [63.510363, 66.457538],
            [63.447751, 66.468432],
            [63.418482, 66.466617],
            [63.416008, 66.451572],
            [63.382189, 66.443279],
            [63.364195, 66.426125],
            [63.331256, 66.413166],
            [63.308081, 66.391106],
            [63.302604, 66.358524],
            [63.28396, 66.350307],
            [63.292663, 66.339047],
            [63.232851, 66.332681],
            [63.243029, 66.315291],
            [63.234032, 66.30107],
            [63.262144, 66.283247],
            [63.270776, 66.265564],
            [63.310583, 66.248885],
            [63.278412, 66.219457],
            [63.250304, 66.222002],
            [63.219912, 66.237197],
            [63.202564, 66.233634],
            [63.178859, 66.24684],
            [63.138085, 66.256855],
            [63.107787, 66.247136],
            [63.089206, 66.220227],
            [63.058787, 66.206278],
            [63.028633, 66.200695],
            [63.031789, 66.185552],
            [62.986638, 66.172368],
            [62.994195, 66.158134],
            [62.957961, 66.128016],
            [62.968793, 66.114505],
            [62.933659, 66.110951],
            [62.95548, 66.100588],
            [62.925695, 66.084021],
            [62.869189, 66.064291],
            [62.851145, 66.023981],
            [62.792307, 66.000908],
            [62.831275, 65.997422],
            [62.884004, 65.979192],
            [62.904052, 65.965107],
            [62.846927, 65.953058],
            [62.822601, 65.942017],
            [62.775354, 65.902371],
            [62.833679, 65.888324],
            [62.84062, 65.878156],
            [62.799675, 65.857698],
            [62.751791, 65.866437],
            [62.748267, 65.856293],
            [62.698536, 65.858617],
            [62.687134, 65.845543],
            [62.642781, 65.855652],
            [62.618258, 65.848709],
            [62.563529, 65.851148],
            [62.554679, 65.838638],
            [62.496709, 65.847722],
            [62.468907, 65.823102],
            [62.406354, 65.809564],
            [62.353647, 65.806328],
            [62.308086, 65.784366],
            [62.270043, 65.783781],
            [62.229096, 65.757906],
            [62.17072, 65.751395],
            [62.133162, 65.75227],
            [62.066415, 65.728674],
            [62.026009, 65.725843],
            [62.000444, 65.717736],
            [61.961463, 65.7052],
            [61.955117, 65.69501],
            [61.857863, 65.686456],
            [61.878652, 65.669296],
            [61.855128, 65.660797],
            [61.831175, 65.663645],
            [61.814891, 65.652638],
            [61.830104, 65.644434],
            [61.78545, 65.624826],
            [61.750465, 65.622093],
            [61.703051, 65.602312],
            [61.742868, 65.589061],
            [61.716791, 65.577346],
            [61.69067, 65.575222],
            [61.669803, 65.558652],
            [61.626756, 65.561903],
            [61.598434, 65.539773],
            [61.571082, 65.528201],
            [61.564165, 65.514705],
            [61.524417, 65.494941],
            [61.491333, 65.455144],
            [61.47539, 65.454127],
            [61.434698, 65.411656],
            [61.417349, 65.386495],
            [61.38901, 65.370602],
            [61.347973, 65.358507],
            [61.335774, 65.345616],
            [61.298457, 65.327348],
            [61.285108, 65.31284],
            [61.295947, 65.276648],
            [61.359639, 65.28197],
            [61.351023, 65.256621],
            [61.364851, 65.2496],
            [61.323386, 65.219914],
            [61.281404, 65.211969],
            [61.297693, 65.205582],
            [61.295941, 65.183228],
            [61.27206, 65.16841],
            [61.193366, 65.176767],
            [61.166163, 65.167684],
            [61.137374, 65.174495],
            [61.093093, 65.151899],
            [61.097518, 65.138433],
            [61.081824, 65.127035],
            [61.091081, 65.106465],
            [61.076999, 65.082361],
            [61.041285, 65.071714],
            [60.996897, 65.067247],
            [60.988548, 65.04727],
            [60.955745, 65.027903],
            [60.948215, 65.014577],
            [60.84733, 65.027714],
            [60.807062, 65.024702],
            [60.794563, 65.004511],
            [60.753815, 64.980045],
            [60.765388, 64.967777],
            [60.753212, 64.960118],
            [60.753755, 64.939346],
            [60.72944, 64.925505],
            [60.644805, 64.925762],
            [60.609563, 64.920501],
            [60.592993, 64.942128],
            [60.516354, 64.962713],
            [60.48843, 64.989316],
            [60.434454, 65.00674],
            [60.428405, 65.023475],
            [60.447877, 65.036957],
            [60.417476, 65.066288],
            [60.377801, 65.072337],
            [60.35556, 65.060169],
            [60.289914, 65.076326],
            [60.203559, 65.076943],
            [60.177349, 65.052986],
            [60.130546, 65.055739],
            [60.101346, 65.038348],
            [60.033904, 65.028322],
            [60.036927, 65.006768],
            [60.056269, 64.996032],
            [60.049971, 64.979083],
            [60.031191, 64.974592],
            [60.038073, 64.96082],
            [60.010842, 64.955815],
            [60.001153, 64.939918],
            [59.950259, 64.943434],
            [59.890691, 64.930419],
            [59.879619, 64.920824],
            [59.888279, 64.902543],
            [59.867154, 64.89549],
            [59.812292, 64.910195],
            [59.726773, 64.853714],
            [59.73503, 64.846043],
            [59.707821, 64.837712],
            [59.723883, 64.827827],
            [59.706685, 64.823285],
            [59.675885, 64.801337],
            [59.626861, 64.803015],
            [59.624966, 64.78496],
            [59.648094, 64.775226],
            [59.63034, 64.763135],
            [59.638389, 64.741983],
            [59.626092, 64.724716],
            [59.660252, 64.711075],
            [59.661117, 64.702024],
            [59.72439, 64.692999],
            [59.746635, 64.679672],
            [59.745304, 64.657241],
            [59.7251, 64.652498],
            [59.655024, 64.65706],
            [59.615089, 64.64667],
            [59.631457, 64.6379],
            [59.618408, 64.617777],
            [59.570746, 64.589729],
            [59.573207, 64.576099],
            [59.552113, 64.556071],
            [59.556749, 64.544007],
            [59.536323, 64.540316],
            [59.526315, 64.522399],
            [59.494704, 64.519313],
            [59.466195, 64.505842],
            [59.464282, 64.495798],
            [59.507163, 64.490439],
            [59.561088, 64.498961],
            [59.598435, 64.497467],
            [59.590241, 64.475978],
            [59.600329, 64.465922],
            [59.629247, 64.461526],
            [59.651175, 64.433148],
            [59.616166, 64.408627],
            [59.62263, 64.387376],
            [59.653717, 64.376185],
            [59.625999, 64.340828],
            [59.600077, 64.333729],
            [59.601092, 64.311108],
            [59.587271, 64.302809],
            [59.571647, 64.274308],
            [59.574095, 64.25314],
            [59.55983, 64.247737],
            [59.585183, 64.224432],
            [59.633988, 64.214047],
            [59.620796, 64.197696],
            [59.677796, 64.164328],
            [59.679501, 64.149016],
            [59.744113, 64.136433],
            [59.768183, 64.142667],
            [59.828359, 64.134407],
            [59.815235, 64.115073],
            [59.822345, 64.095163],
            [59.836947, 64.087144],
            [59.810533, 64.075785],
            [59.811302, 64.063284],
            [59.769766, 64.042346],
            [59.75742, 64.007112],
            [59.768687, 63.999949],
            [59.756326, 63.985473],
            [59.723352, 63.974075],
            [59.72279, 63.962117],
            [59.688065, 63.94595],
            [59.634136, 63.931134],
            [59.593018, 63.924348],
            [59.561302, 63.908559],
            [59.559964, 63.884864],
            [59.58006, 63.862522],
            [59.571, 63.841078],
            [59.541601, 63.820747],
            [59.527208, 63.771404],
            [59.533813, 63.745989],
            [59.521964, 63.724038],
            [59.533165, 63.714067],
            [59.500314, 63.687782],
            [59.496804, 63.653542],
            [59.473707, 63.638534],
            [59.47992, 63.614184],
            [59.478645, 63.572225],
            [59.467974, 63.548],
            [59.436928, 63.530527],
            [59.449857, 63.516444],
            [59.442454, 63.500528],
            [59.404394, 63.479046],
            [59.405744, 63.46646],
            [59.377966, 63.422055],
            [59.353451, 63.414044],
            [59.313, 63.390018],
            [59.373036, 63.370917],
            [59.364137, 63.359209],
            [59.326557, 63.348915],
            [59.32203, 63.314058],
            [59.33497, 63.308197],
            [59.339221, 63.281587],
            [59.366262, 63.266174],
            [59.365877, 63.251827],
            [59.336674, 63.249565],
            [59.331562, 63.229375],
            [59.304599, 63.203292],
            [59.320395, 63.19704],
            [59.288405, 63.184708],
            [59.285487, 63.17032],
            [59.322027, 63.123271],
            [59.28941, 63.104993],
            [59.254634, 63.104314],
            [59.225312, 63.089303],
            [59.243106, 63.080074],
            [59.233202, 63.055274],
            [59.203794, 63.027597],
            [59.22443, 62.982778],
            [59.273633, 62.982215],
            [59.325204, 62.942026],
            [59.377201, 62.941711],
            [59.448193, 62.918013],
            [59.464346, 62.893049],
            [59.443141, 62.876208],
            [59.444318, 62.829926],
            [59.434096, 62.779641],
            [59.414437, 62.759843],
            [59.360391, 62.752291],
            [59.391679, 62.73744],
            [59.37116, 62.724874],
            [59.395962, 62.721136],
            [59.410605, 62.705249],
            [59.402618, 62.674158],
            [59.431883, 62.652543],
            [59.426075, 62.632817],
            [59.437217, 62.621091],
            [59.471717, 62.604623],
            [59.469099, 62.592541],
            [59.442619, 62.565955],
            [59.467715, 62.560321],
            [59.5085, 62.562821],
            [59.551061, 62.545566],
            [59.625152, 62.54457],
            [59.635447, 62.548454],
            [59.629845, 62.522176],
            [59.619192, 62.513674],
            [59.621651, 62.494288],
            [59.60924, 62.477312],
            [59.603141, 62.439639],
            [59.582734, 62.421282],
            [59.61999, 62.405604],
            [59.612653, 62.366442],
            [59.578084, 62.35775],
            [59.577386, 62.335849],
            [59.550246, 62.325899],
            [59.526464, 62.327885],
            [59.494088, 62.299098],
            [59.511709, 62.278955],
            [59.491126, 62.246724],
            [59.453562, 62.234336],
            [59.456211, 62.205783],
            [59.435856, 62.193728],
            [59.432765, 62.174576],
            [59.400125, 62.157112],
            [59.404955, 62.136903],
            [59.401369, 62.104504],
            [59.430881, 62.094864],
            [59.436656, 62.055547],
            [59.425375, 62.050411],
            [59.442736, 62.015486],
            [59.438397, 61.999515],
            [59.465313, 61.995063],
            [59.477331, 61.979461],
            [59.486699, 61.946069],
            [59.402123, 61.908124],
            [59.403716, 61.883808],
            [59.364515, 61.873581],
            [59.327587, 61.842219],
            [59.33486, 61.830175],
            [59.315659, 61.802337],
            [59.327818, 61.783702],
            [59.342248, 61.785179],
            [59.359748, 61.769613],
            [59.391053, 61.765182],
            [59.418551, 61.753706],
            [59.399747, 61.727572],
            [59.369296, 61.713822],
            [59.371342, 61.700983],
            [59.345208, 61.689847],
            [59.360094, 61.672462],
            [59.348463, 61.66377],
            [59.292805, 61.655731],
            [59.281881, 61.641696],
            [59.236325, 61.62637],
            [59.206699, 61.639798],
            [59.187468, 61.628191],
            [59.148321, 61.629707],
            [59.123438, 61.610362],
            [59.114905, 61.59132],
            [59.090423, 61.574337],
            [59.048309, 61.536394],
            [59.023314, 61.538406],
            [58.984156, 61.532422],
            [58.974044, 61.522075],
            [58.918948, 61.531536],
            [58.844108, 61.51788],
            [58.843882, 61.495045],
            [58.231781, 61.497631],
            [57.997294, 61.499984],
            [57.922196, 61.498826],
            [57.092687, 61.50185],
            [57.080551, 61.475297],
            [56.73248, 61.514439],
            [56.653124, 61.5243],
            [56.63947, 61.488492],
            [56.597365, 61.492892],
            [56.56634, 61.418999],
            [56.37827, 61.443122],
            [56.322895, 61.332511],
            [56.311972, 61.301255],
            [56.326083, 61.291241],
            [56.274501, 61.191858],
            [55.813087, 61.246778],
            [55.730554, 61.09171],
            [55.272479, 61.146023],
            [55.19996, 60.991563],
            [55.05005, 61.009161],
            [54.976851, 60.859908],
            [54.723331, 60.886916],
            [54.370378, 60.927261],
            [54.221919, 60.944922],
            [53.87597, 60.982831],
            [53.869119, 60.967148],
            [53.826351, 60.835202],
            [53.728412, 60.842451],
            [53.607078, 60.854012],
            [53.352946, 60.882025],
            [53.381415, 60.959071],
            [53.413843, 61.032158],
            [53.335883, 61.041293],
            [52.870558, 61.09062],
            [52.806464, 60.937746],
            [52.653132, 60.952514],
            [52.431433, 60.976416],
            [52.360943, 60.829175],
            [51.893792, 60.8817],
            [51.833505, 60.726086],
            [51.802294, 60.656196],
            [51.771541, 60.579912],
            [51.963015, 60.561338],
            [52.152814, 60.541242],
            [52.12267, 60.464806],
            [52.348937, 60.438315],
            [52.328689, 60.375278],
            [52.33204, 60.362488],
            [52.368736, 60.358377],
            [52.317282, 60.227188],
            [52.065457, 60.308487],
            [51.973869, 60.279334],
            [51.859139, 60.187993],
            [51.766358, 60.085865],
            [51.665251, 60.027746],
            [51.609167, 59.989607],
            [51.551321, 59.941533],
            [51.498657, 59.928639],
            [51.467194, 59.915573],
            [51.434824, 59.925507],
            [51.418478, 59.937686],
            [51.390272, 59.994091],
            [51.309187, 60.012358],
            [51.179065, 60.048892],
            [51.123986, 60.035088],
            [51.112404, 60.040558],
            [51.047665, 60.031904],
            [50.911463, 59.922936],
            [50.873173, 59.911696],
            [50.872707, 59.886182],
            [50.831023, 59.863324],
            [50.781668, 59.860439],
            [50.735837, 59.870694],
            [50.474639, 59.836078],
            [50.402529, 59.838961],
            [50.288832, 59.805536],
            [50.268901, 59.7488],
            [50.099246, 59.767881],
            [49.988744, 59.778361],
            [49.972115, 59.7505],
            [49.982241, 59.723801],
            [50.024357, 59.709158],
            [50.084931, 59.680315],
            [50.066664, 59.670379],
            [50.017308, 59.674224],
            [49.997118, 59.67134],
            [49.974509, 59.65755],
            [49.859414, 59.655265],
            [49.798092, 59.484999],
            [49.870036, 59.477682],
            [49.829041, 59.363707],
            [49.866896, 59.360181],
            [49.815425, 59.210155],
            [49.766229, 59.221211],
            [49.725497, 59.198212],
            [49.519772, 59.216079],
            [49.530668, 59.233706],
            [49.503105, 59.245564],
            [49.524257, 59.257743],
            [49.568798, 59.392349],
            [49.499892, 59.395232],
            [49.344454, 59.409331],
            [49.359354, 59.458527],
            [49.347335, 59.465417],
            [49.216094, 59.47503],
            [49.22026, 59.489612],
            [49.196698, 59.488283],
            [49.151193, 59.507078],
            [49.117862, 59.499706],
            [49.096541, 59.481431],
            [49.039664, 59.482398],
            [49.101547, 59.661699],
            [48.876736, 59.680895],
            [48.84728, 59.679033],
            [48.807418, 59.702446],
            [48.801186, 59.686542],
            [48.690021, 59.693557],
            [48.523224, 59.706844],
            [48.51212, 59.778201],
            [48.506024, 59.899666],
            [48.509332, 59.899346],
            [48.499928, 60.021452],
            [48.483582, 60.02722],
            [48.481976, 60.091638],
            [48.472056, 60.13227],
            [48.492226, 60.195156],
            [48.675702, 60.272397],
            [48.677848, 60.278498],
            [48.769603, 60.316144],
            [48.785465, 60.354924],
            [48.738352, 60.371108],
            [48.680117, 60.400813],
            [48.676327, 60.390446],
            [48.530512, 60.400429],
            [48.562553, 60.420854],
            [48.605496, 60.415556],
            [48.632908, 60.423506],
            [48.566645, 60.440491],
            [48.555107, 60.458759],
            [48.496136, 60.472218],
            [48.520811, 60.503947],
            [48.496802, 60.517251],
            [48.560666, 60.551513],
            [48.502165, 60.673113],
            [48.501792, 60.680726],
            [48.419677, 60.837206],
            [48.426618, 60.880865],
            [48.448547, 60.95772],
            [48.474967, 61.022139],
            [48.686184, 61.076027],
            [48.830564, 61.114024],
            [48.92158, 61.136045],
            [48.957936, 61.104959],
            [49.092817, 61.160572],
            [49.126753, 61.17223],
            [49.236595, 61.15997],
            [49.321827, 61.152372],
            [49.347037, 61.2237],
            [49.392279, 61.342522],
            [49.441751, 61.456347],
            [49.502104, 61.605036],
            [49.215236, 61.632147],
            [49.043737, 61.649069],
            [49.049952, 61.679811],
            [49.111427, 61.82143],
            [49.179636, 61.97324],
            [49.227262, 62.1022],
            [49.284115, 62.098107],
            [49.300004, 62.106915],
            [49.280056, 62.117364],
            [49.28843, 62.139298],
            [49.374007, 62.132736],
            [49.452922, 62.321332],
            [49.537706, 62.539805],
            [49.566888, 62.620459],
            [49.575351, 62.619423],
            [49.640796, 62.76916],
            [49.324394, 62.799206],
            [48.741332, 62.8517],
            [48.681413, 62.702136],
            [48.352231, 62.733736],
            [48.287304, 62.583654],
            [48.255704, 62.506109],
            [48.298019, 62.476922],
            [48.320653, 62.337549],
            [48.322901, 62.307153],
            [48.280069, 62.310952],
            [48.151639, 62.315083],
            [47.883361, 62.321654],
            [47.659359, 62.328386],
            [47.642617, 62.175714],
            [47.389083, 62.181409],
            [47.402889, 62.334427],
            [47.231909, 62.337187],
            [47.241225, 62.488132],
            [47.245359, 62.638732],
            [47.230505, 62.65013],
            [47.085075, 62.809017],
            [47.060895, 62.826287],
            [47.288516, 62.911607],
            [47.422879, 62.955131],
            [47.442739, 62.964975],
            [47.409062, 62.964802],
            [47.470369, 63.010397],
            [47.530814, 63.049948],
            [47.54238, 63.115058],
            [47.559824, 63.113331],
            [47.570357, 63.144764],
            [47.39005, 63.156332],
            [47.230499, 63.222194],
            [47.16017, 63.245445],
            [47.051019, 63.253906],
            [47.032018, 63.292419],
            [46.942724, 63.34941],
            [46.945829, 63.403812],
            [46.939262, 63.453551],
            [47.011967, 63.517972],
            [47.017316, 63.577728],
            [46.991237, 63.588608],
            [46.999526, 63.599661],
            [46.881386, 63.703283],
            [46.809881, 63.756648],
            [46.499691, 63.864584],
            [46.408327, 63.889107],
            [46.33458, 63.904476],
            [46.199538, 63.925025],
            [46.151162, 63.94385],
            [45.943394, 63.956627],
            [45.840631, 63.989094],
            [45.594518, 64.056964],
            [45.552376, 64.080624],
            [45.39935, 64.174918],
            [45.523005, 64.20877],
            [45.714043, 64.257442],
            [45.852006, 64.278031],
            [45.943714, 64.271642],
            [46.036113, 64.268017],
            [46.321081, 64.247987],
            [46.350094, 64.263531],
            [46.392926, 64.266813],
            [46.399144, 64.260423],
            [46.630221, 64.316901],
            [46.795155, 64.32623],
            [46.96268, 64.337804],
            [47.234175, 64.348171],
            [47.344534, 64.35439],
            [47.804454, 64.345934],
            [47.960064, 64.344037],
            [48.16144, 64.345249],
            [48.156606, 64.331605],
            [48.165764, 64.276167],
            [48.196509, 64.2266],
            [48.464726, 64.204326],
            [48.52447, 64.355617],
            [48.88379, 64.326694],
            [48.885516, 64.342669],
            [48.961064, 64.477553],
            [49.483163, 64.425058],
            [49.552407, 64.575831],
            [49.905423, 64.547513],
            [49.912503, 64.557875],
            [50.360683, 64.510388],
            [50.393837, 64.579471],
            [50.443223, 64.690694],
            [50.498827, 64.791727],
            [50.368085, 64.815904],
            [50.245634, 64.82851],
            [49.995711, 65.013474],
            [49.918852, 65.066148],
            [49.762888, 65.176504],
            [49.636629, 65.289107],
            [49.120065, 65.23107],
            [49.055647, 65.22477],
            [49.048729, 65.331847],
            [49.03605, 65.43562],
            [49.0466, 65.435568],
            [49.036279, 65.511115],
            [49.018291, 65.810241],
            [48.998237, 66.023014],
            [48.999789, 66.113264],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5499",
      properties: {
        name: "Республика Мордовия",
        density: 0,
        path: "/world/Russia/Республика Мордовия",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.442662, 54.825224],
            [42.425528, 54.805582],
            [42.433575, 54.793561],
            [42.454866, 54.750434],
            [42.509725, 54.750742],
            [42.503668, 54.770663],
            [42.457904, 54.801894],
            [42.444321, 54.798354],
            [42.448387, 54.810519],
            [42.475039, 54.819597],
            [42.501685, 54.83814],
            [42.534132, 54.817507],
            [42.543476, 54.803493],
            [42.571163, 54.796266],
            [42.579816, 54.787432],
            [42.601969, 54.79257],
            [42.61555, 54.78209],
            [42.641943, 54.781992],
            [42.672316, 54.807089],
            [42.724419, 54.818554],
            [42.741648, 54.812669],
            [42.738283, 54.787761],
            [42.772876, 54.789618],
            [42.786079, 54.777049],
            [42.809342, 54.770075],
            [42.79367, 54.754855],
            [42.818053, 54.754304],
            [42.832058, 54.770336],
            [42.853161, 54.765487],
            [42.885506, 54.767088],
            [42.901959, 54.759991],
            [42.924684, 54.780464],
            [42.952109, 54.782394],
            [42.968963, 54.797617],
            [43.020443, 54.78492],
            [43.03314, 54.794118],
            [43.046913, 54.772469],
            [43.086784, 54.775022],
            [43.11591, 54.794375],
            [43.138148, 54.829512],
            [43.131063, 54.849259],
            [43.141245, 54.855142],
            [43.169227, 54.897769],
            [43.21244, 54.892056],
            [43.223367, 54.910532],
            [43.242986, 54.919565],
            [43.265818, 54.91256],
            [43.28735, 54.929483],
            [43.300436, 54.910963],
            [43.35547, 54.905895],
            [43.362573, 54.93224],
            [43.340037, 54.940904],
            [43.341734, 54.950598],
            [43.386935, 54.956907],
            [43.388853, 54.935592],
            [43.407645, 54.929519],
            [43.422021, 54.935277],
            [43.454771, 54.934408],
            [43.465669, 54.909411],
            [43.520838, 54.899643],
            [43.590768, 54.902653],
            [43.614365, 54.883407],
            [43.614709, 54.870869],
            [43.632036, 54.862077],
            [43.618222, 54.854423],
            [43.672874, 54.841729],
            [43.690637, 54.831749],
            [43.748212, 54.821864],
            [43.78006, 54.829669],
            [43.77354, 54.819436],
            [43.861144, 54.80495],
            [43.957756, 54.806086],
            [43.915027, 54.753784],
            [43.921118, 54.739324],
            [43.973629, 54.727426],
            [44.002889, 54.72792],
            [44.031286, 54.741489],
            [44.068097, 54.743426],
            [44.09598, 54.734113],
            [44.094179, 54.718352],
            [44.07839, 54.70403],
            [44.079934, 54.676357],
            [44.06844, 54.648567],
            [44.101649, 54.645291],
            [44.120093, 54.633919],
            [44.172005, 54.635362],
            [44.194658, 54.613701],
            [44.200579, 54.596459],
            [44.235759, 54.603166],
            [44.248113, 54.597503],
            [44.271862, 54.60621],
            [44.308583, 54.590453],
            [44.35629, 54.590453],
            [44.371133, 54.583641],
            [44.381515, 54.552105],
            [44.405199, 54.547974],
            [44.404768, 54.534631],
            [44.43094, 54.533386],
            [44.461659, 54.522933],
            [44.525583, 54.525573],
            [44.536737, 54.517904],
            [44.558443, 54.532142],
            [44.580232, 54.572801],
            [44.598596, 54.570262],
            [44.626656, 54.556182],
            [44.658824, 54.514816],
            [44.622881, 54.495784],
            [44.619532, 54.522534],
            [44.602366, 54.517402],
            [44.602628, 54.487364],
            [44.575253, 54.480135],
            [44.58624, 54.471508],
            [44.651452, 54.485566],
            [44.687659, 54.484424],
            [44.700698, 54.494338],
            [44.741797, 54.505602],
            [44.769772, 54.520891],
            [44.806583, 54.501412],
            [44.804176, 54.488858],
            [44.844933, 54.479833],
            [44.850685, 54.489257],
            [44.907995, 54.463531],
            [44.95879, 54.479041],
            [44.965142, 54.487611],
            [45.014308, 54.484224],
            [45.02469, 54.49439],
            [45.038416, 54.480232],
            [45.099935, 54.468519],
            [45.107319, 54.479136],
            [45.139918, 54.489457],
            [45.126884, 54.51646],
            [45.141635, 54.52816],
            [45.116405, 54.532045],
            [45.10963, 54.523827],
            [45.08818, 54.530252],
            [45.101655, 54.543398],
            [45.070591, 54.559667],
            [45.035496, 54.54653],
            [45.016883, 54.547474],
            [45.011049, 54.570761],
            [45.03018, 54.582248],
            [45.047684, 54.58165],
            [45.045703, 54.597464],
            [45.030604, 54.605512],
            [45.052058, 54.621014],
            [45.036103, 54.629858],
            [45.08741, 54.652352],
            [45.071197, 54.671654],
            [45.034551, 54.691147],
            [45.018253, 54.705283],
            [45.034551, 54.742691],
            [45.073589, 54.763587],
            [45.077714, 54.773143],
            [45.111952, 54.800055],
            [45.133563, 54.786354],
            [45.158369, 54.786553],
            [45.158715, 54.7722],
            [45.189256, 54.751461],
            [45.216108, 54.779226],
            [45.209673, 54.802924],
            [45.226577, 54.802827],
            [45.227265, 54.84573],
            [45.222378, 54.860058],
            [45.230701, 54.877285],
            [45.259011, 54.878423],
            [45.283211, 54.868448],
            [45.298144, 54.878076],
            [45.371238, 54.882172],
            [45.391494, 54.87393],
            [45.426846, 54.885528],
            [45.425473, 54.905067],
            [45.43808, 54.910789],
            [45.422639, 54.920162],
            [45.432503, 54.933619],
            [45.415256, 54.947077],
            [45.445806, 54.957524],
            [45.44683, 54.982101],
            [45.437051, 54.982688],
            [45.419462, 55.004742],
            [45.43654, 55.008186],
            [45.421346, 55.024961],
            [45.422554, 55.038037],
            [45.462445, 55.071754],
            [45.46176, 55.081775],
            [45.483294, 55.084672],
            [45.486402, 55.112268],
            [45.509145, 55.110302],
            [45.527845, 55.098275],
            [45.569781, 55.101517],
            [45.550225, 55.13174],
            [45.592266, 55.130072],
            [45.584289, 55.142435],
            [45.593126, 55.16577],
            [45.61475, 55.162241],
            [45.640229, 55.177921],
            [45.690082, 55.18091],
            [45.703121, 55.190268],
            [45.72766, 55.184632],
            [45.749196, 55.168957],
            [45.73461, 55.165426],
            [45.736751, 55.147678],
            [45.76138, 55.155669],
            [45.777603, 55.144053],
            [45.783599, 55.116384],
            [45.75589, 55.114472],
            [45.748947, 55.090859],
            [45.73384, 55.093314],
            [45.718997, 55.08035],
            [45.752112, 55.067922],
            [45.74328, 55.057063],
            [45.717695, 55.047711],
            [45.73417, 55.04171],
            [45.698321, 55.011776],
            [45.722519, 54.991403],
            [45.807111, 55.005869],
            [45.809006, 54.997754],
            [45.829161, 55.009269],
            [45.904168, 55.004454],
            [45.898507, 54.998448],
            [45.923738, 54.993674],
            [45.927419, 55.003075],
            [45.944325, 54.997515],
            [45.97341, 55.001105],
            [45.998884, 55.025024],
            [46.034488, 55.027136],
            [46.061001, 55.047639],
            [46.137705, 55.022711],
            [46.143967, 55.005783],
            [46.13015, 54.996485],
            [46.154598, 54.973984],
            [46.184022, 55.019353],
            [46.1799, 55.026287],
            [46.207103, 55.032733],
            [46.202126, 55.049692],
            [46.182139, 55.068561],
            [46.226845, 55.083002],
            [46.279319, 55.034057],
            [46.293037, 55.028402],
            [46.275798, 55.00056],
            [46.287887, 54.992389],
            [46.243187, 54.977962],
            [46.27133, 54.962842],
            [46.282571, 54.938099],
            [46.29612, 54.943225],
            [46.310885, 54.928735],
            [46.32684, 54.93677],
            [46.326326, 54.920945],
            [46.339282, 54.91256],
            [46.370261, 54.90649],
            [46.373013, 54.893616],
            [46.410591, 54.874072],
            [46.390421, 54.865328],
            [46.400897, 54.86005],
            [46.382018, 54.830457],
            [46.399953, 54.806872],
            [46.426299, 54.790789],
            [46.449103, 54.785992],
            [46.405606, 54.77753],
            [46.441731, 54.761445],
            [46.408442, 54.740002],
            [46.409037, 54.731975],
            [46.454323, 54.719589],
            [46.433729, 54.707395],
            [46.440595, 54.698917],
            [46.421202, 54.688222],
            [46.525617, 54.654832],
            [46.535834, 54.631893],
            [46.500659, 54.626381],
            [46.463864, 54.605165],
            [46.409115, 54.583342],
            [46.403024, 54.574192],
            [46.41915, 54.561215],
            [46.455693, 54.579617],
            [46.477726, 54.57688],
            [46.521321, 54.587969],
            [46.536418, 54.5731],
            [46.496099, 54.560662],
            [46.509573, 54.552451],
            [46.539864, 54.559021],
            [46.528966, 54.518056],
            [46.485206, 54.504254],
            [46.485121, 54.490401],
            [46.459293, 54.48158],
            [46.492495, 54.474255],
            [46.51378, 54.485615],
            [46.557627, 54.488458],
            [46.571612, 54.502561],
            [46.584736, 54.486064],
            [46.534713, 54.477093],
            [46.544414, 54.46807],
            [46.526566, 54.463931],
            [46.506919, 54.470363],
            [46.492495, 54.462183],
            [46.506572, 54.457296],
            [46.490875, 54.443429],
            [46.500991, 54.428505],
            [46.528276, 54.41288],
            [46.534027, 54.398745],
            [46.562776, 54.404692],
            [46.575211, 54.398001],
            [46.609701, 54.406492],
            [46.654581, 54.39825],
            [46.708287, 54.39845],
            [46.729482, 54.401948],
            [46.71121, 54.378615],
            [46.723995, 54.373468],
            [46.705291, 54.35897],
            [46.697828, 54.337313],
            [46.705543, 54.326711],
            [46.684518, 54.304336],
            [46.655862, 54.300884],
            [46.663933, 54.295173],
            [46.622231, 54.290621],
            [46.616823, 54.297128],
            [46.577444, 54.28541],
            [46.540123, 54.283504],
            [46.541404, 54.273034],
            [46.511379, 54.264668],
            [46.47371, 54.268577],
            [46.460664, 54.261509],
            [46.44085, 54.270431],
            [46.420681, 54.24848],
            [46.385849, 54.233583],
            [46.404468, 54.217685],
            [46.397946, 54.210359],
            [46.369033, 54.210062],
            [46.361742, 54.200121],
            [46.3239, 54.190137],
            [46.304076, 54.197965],
            [46.281083, 54.192846],
            [46.283062, 54.183403],
            [46.264694, 54.183154],
            [46.254055, 54.172458],
            [46.228312, 54.186469],
            [46.192366, 54.179893],
            [46.186101, 54.167235],
            [46.155379, 54.154425],
            [46.1269, 54.165576],
            [46.12235, 54.176982],
            [46.103995, 54.163974],
            [46.086833, 54.165879],
            [46.083575, 54.176427],
            [46.062373, 54.170452],
            [46.038522, 54.139646],
            [46.016733, 54.129446],
            [46.003261, 54.135731],
            [46.005831, 54.148848],
            [45.988159, 54.152414],
            [45.969883, 54.138093],
            [45.967396, 54.154323],
            [45.955466, 54.147289],
            [45.96928, 54.127835],
            [45.957786, 54.095239],
            [45.944915, 54.093633],
            [45.929382, 54.076922],
            [45.889915, 54.061819],
            [45.905014, 54.047918],
            [45.89412, 54.019242],
            [45.870607, 54.000688],
            [45.845556, 53.993172],
            [45.815348, 53.992367],
            [45.79665, 53.985759],
            [45.813633, 53.975716],
            [45.780173, 53.965421],
            [45.737698, 53.94159],
            [45.718741, 53.946692],
            [45.710335, 53.932398],
            [45.73624, 53.905925],
            [45.704491, 53.895757],
            [45.661675, 53.918154],
            [45.633792, 53.913755],
            [45.564036, 53.929465],
            [45.540873, 53.92937],
            [45.517101, 53.917897],
            [45.541814, 53.895003],
            [45.485437, 53.890096],
            [45.472745, 53.900158],
            [45.415435, 53.89788],
            [45.362234, 53.881299],
            [45.35717, 53.870064],
            [45.298482, 53.871534],
            [45.295475, 53.90253],
            [45.282606, 53.9181],
            [45.270686, 53.916688],
            [45.21225, 53.956692],
            [45.18934, 53.958155],
            [45.176467, 53.971527],
            [45.144556, 53.964111],
            [45.132713, 53.967644],
            [45.115982, 53.946189],
            [45.07085, 53.960576],
            [45.007268, 53.959113],
            [45.007186, 53.971125],
            [44.988735, 53.9684],
            [44.984281, 53.957396],
            [44.960246, 53.964313],
            [44.923954, 53.946844],
            [44.932706, 53.960478],
            [44.903621, 53.959969],
            [44.871526, 53.968503],
            [44.856686, 53.95962],
            [44.831459, 53.963251],
            [44.817476, 53.933814],
            [44.803149, 53.92669],
            [44.750813, 53.920275],
            [44.72678, 53.92315],
            [44.721466, 53.91618],
            [44.743429, 53.907334],
            [44.724896, 53.897021],
            [44.723096, 53.886406],
            [44.695811, 53.900208],
            [44.685678, 53.914915],
            [44.660284, 53.904255],
            [44.639436, 53.904151],
            [44.651278, 53.886406],
            [44.630773, 53.891714],
            [44.607604, 53.881195],
            [44.613269, 53.857618],
            [44.590701, 53.844816],
            [44.6004, 53.839449],
            [44.603824, 53.798975],
            [44.654191, 53.799584],
            [44.669206, 53.782048],
            [44.64982, 53.778651],
            [44.635493, 53.785296],
            [44.618507, 53.769421],
            [44.636261, 53.770538],
            [44.638576, 53.755325],
            [44.689718, 53.750708],
            [44.700528, 53.723098],
            [44.725927, 53.710556],
            [44.731592, 53.700704],
            [44.715204, 53.680125],
            [44.656429, 53.683278],
            [44.62459, 53.688869],
            [44.620134, 53.683381],
            [44.587957, 53.685924],
            [44.586661, 53.666202],
            [44.532531, 53.660913],
            [44.537161, 53.655571],
            [44.505153, 53.649065],
            [44.494346, 53.660402],
            [44.514087, 53.66086],
            [44.54162, 53.670367],
            [44.53682, 53.677686],
            [44.511503, 53.675553],
            [44.504989, 53.665942],
            [44.458908, 53.663962],
            [44.414121, 53.697857],
            [44.371911, 53.694049],
            [44.381859, 53.704461],
            [44.371566, 53.721574],
            [44.355774, 53.705526],
            [44.317853, 53.706852],
            [44.311932, 53.692218],
            [44.295369, 53.690848],
            [44.202533, 53.693845],
            [44.195328, 53.703245],
            [44.148737, 53.704363],
            [44.144189, 53.691762],
            [44.11407, 53.692526],
            [44.122309, 53.710914],
            [44.119049, 53.730156],
            [44.103606, 53.734776],
            [44.08816, 53.725382],
            [44.075034, 53.739089],
            [44.052551, 53.731831],
            [44.039338, 53.744668],
            [43.995839, 53.7503],
            [43.982364, 53.740151],
            [43.958426, 53.76435],
            [43.927452, 53.770688],
            [43.916213, 53.786866],
            [43.895878, 53.786458],
            [43.883263, 53.801055],
            [43.859671, 53.805976],
            [43.906434, 53.81661],
            [43.901196, 53.834489],
            [43.917324, 53.839855],
            [43.904373, 53.846232],
            [43.933371, 53.85666],
            [43.923849, 53.87381],
            [43.865764, 53.895657],
            [43.846455, 53.924113],
            [43.766749, 53.953105],
            [43.748472, 53.967391],
            [43.745811, 53.959363],
            [43.69107, 53.950276],
            [43.686692, 53.95437],
            [43.632983, 53.964967],
            [43.61522, 53.964411],
            [43.615648, 53.975968],
            [43.580126, 53.981116],
            [43.553872, 54.006339],
            [43.535252, 54.014353],
            [43.514491, 53.996455],
            [43.494243, 54.002202],
            [43.464293, 53.987579],
            [43.458459, 53.978798],
            [43.402086, 53.985256],
            [43.397371, 53.997515],
            [43.347603, 53.997465],
            [43.336877, 54.012186],
            [43.315971, 54.024188],
            [43.289889, 54.009061],
            [43.288085, 53.999832],
            [43.238752, 53.995748],
            [43.240036, 53.989494],
            [43.190184, 53.993583],
            [43.182778, 53.968601],
            [43.126751, 53.977284],
            [43.129926, 53.940028],
            [43.100064, 53.928203],
            [43.10762, 53.894194],
            [43.130696, 53.896469],
            [43.169308, 53.886253],
            [43.164758, 53.877757],
            [43.180034, 53.834538],
            [43.156781, 53.820004],
            [43.138591, 53.825069],
            [43.106242, 53.853976],
            [43.09672, 53.87381],
            [43.046951, 53.856356],
            [43.045323, 53.840055],
            [43.078358, 53.83849],
            [43.072694, 53.810782],
            [43.023358, 53.814178],
            [43.062055, 53.819145],
            [43.027393, 53.823955],
            [43.02353, 53.82983],
            [42.968533, 53.822181],
            [42.972735, 53.810986],
            [42.931977, 53.795533],
            [42.896283, 53.794468],
            [42.837598, 53.814232],
            [42.790234, 53.807437],
            [42.759604, 53.825475],
            [42.745793, 53.784582],
            [42.753254, 53.764553],
            [42.727085, 53.739494],
            [42.702032, 53.74944],
            [42.707692, 53.779967],
            [42.739018, 53.81679],
            [42.698857, 53.81519],
            [42.665651, 53.801308],
            [42.648575, 53.817569],
            [42.6291, 53.80906],
            [42.601127, 53.770893],
            [42.58886, 53.77115],
            [42.570068, 53.749389],
            [42.547844, 53.749295],
            [42.534973, 53.76502],
            [42.558398, 53.779728],
            [42.556252, 53.800501],
            [42.568867, 53.809872],
            [42.588943, 53.809468],
            [42.590057, 53.836764],
            [42.555995, 53.830742],
            [42.526479, 53.810173],
            [42.51138, 53.81058],
            [42.494995, 53.778096],
            [42.505803, 53.760496],
            [42.52257, 53.757085],
            [42.522144, 53.746636],
            [42.562126, 53.733439],
            [42.498596, 53.734776],
            [42.474226, 53.75431],
            [42.485899, 53.760749],
            [42.45578, 53.773176],
            [42.412782, 53.763974],
            [42.417906, 53.781768],
            [42.409472, 53.798938],
            [42.387662, 53.805338],
            [42.371838, 53.795215],
            [42.311894, 53.799125],
            [42.322876, 53.813167],
            [42.357451, 53.826181],
            [42.35023, 53.853669],
            [42.356509, 53.887619],
            [42.383019, 53.923458],
            [42.40464, 53.938362],
            [42.444366, 53.943007],
            [42.439305, 53.958004],
            [42.444625, 53.995998],
            [42.416742, 54.010119],
            [42.363887, 54.018338],
            [42.325191, 54.051592],
            [42.292582, 54.062424],
            [42.261952, 54.087393],
            [42.288809, 54.096248],
            [42.283234, 54.104851],
            [42.325445, 54.122607],
            [42.326046, 54.106364],
            [42.303136, 54.09982],
            [42.312322, 54.082463],
            [42.328622, 54.087344],
            [42.379416, 54.090964],
            [42.376241, 54.098562],
            [42.417168, 54.103094],
            [42.433812, 54.120392],
            [42.451234, 54.122406],
            [42.449604, 54.103745],
            [42.45947, 54.08503],
            [42.49808, 54.070025],
            [42.572044, 54.083319],
            [42.550934, 54.1211],
            [42.53566, 54.117077],
            [42.520645, 54.132308],
            [42.528799, 54.148698],
            [42.545616, 54.143215],
            [42.579333, 54.165228],
            [42.607048, 54.152968],
            [42.669366, 54.140924],
            [42.675398, 54.127981],
            [42.689115, 54.129681],
            [42.667966, 54.156738],
            [42.649948, 54.166381],
            [42.632701, 54.165027],
            [42.598899, 54.184863],
            [42.583538, 54.177787],
            [42.546042, 54.192495],
            [42.505115, 54.214275],
            [42.489413, 54.214926],
            [42.433386, 54.25705],
            [42.395634, 54.254498],
            [42.391345, 54.240759],
            [42.365086, 54.218391],
            [42.392369, 54.209407],
            [42.339089, 54.190387],
            [42.353847, 54.175573],
            [42.349386, 54.161961],
            [42.316267, 54.150705],
            [42.288121, 54.153619],
            [42.222057, 54.17467],
            [42.236297, 54.182254],
            [42.202148, 54.188527],
            [42.215877, 54.203837],
            [42.207643, 54.218189],
            [42.226176, 54.224009],
            [42.196399, 54.231181],
            [42.163367, 54.258106],
            [42.207214, 54.253839],
            [42.240932, 54.23895],
            [42.255259, 54.239456],
            [42.299961, 54.226212],
            [42.321926, 54.234788],
            [42.327165, 54.24928],
            [42.386967, 54.253944],
            [42.398206, 54.263011],
            [42.404984, 54.284658],
            [42.477745, 54.299079],
            [42.493016, 54.310795],
            [42.487528, 54.328464],
            [42.49645, 54.342668],
            [42.529911, 54.370217],
            [42.543555, 54.372819],
            [42.577963, 54.365521],
            [42.628586, 54.375868],
            [42.620432, 54.401595],
            [42.584744, 54.425562],
            [42.583538, 54.444723],
            [42.567065, 54.442481],
            [42.551791, 54.428209],
            [42.530773, 54.440734],
            [42.552738, 54.457249],
            [42.565607, 54.477741],
            [42.556252, 54.486663],
            [42.562258, 54.505203],
            [42.591946, 54.501813],
            [42.605587, 54.510034],
            [42.611254, 54.489156],
            [42.632614, 54.493776],
            [42.624967, 54.522011],
            [42.606069, 54.525409],
            [42.61237, 54.538467],
            [42.599169, 54.546773],
            [42.62881, 54.554653],
            [42.607795, 54.560514],
            [42.634424, 54.569587],
            [42.684363, 54.570882],
            [42.688461, 54.580993],
            [42.648882, 54.587932],
            [42.620953, 54.611446],
            [42.631418, 54.62953],
            [42.608495, 54.627427],
            [42.597332, 54.635541],
            [42.620803, 54.663243],
            [42.643047, 54.671403],
            [42.602024, 54.699853],
            [42.588876, 54.704496],
            [42.573411, 54.683965],
            [42.541867, 54.675006],
            [42.540755, 54.684936],
            [42.500038, 54.704323],
            [42.4784, 54.690565],
            [42.439807, 54.709716],
            [42.412899, 54.707919],
            [42.428558, 54.722557],
            [42.42891, 54.739147],
            [42.395078, 54.751232],
            [42.411087, 54.765364],
            [42.388067, 54.780686],
            [42.389103, 54.789667],
            [42.421806, 54.798948],
            [42.42683, 54.814853],
            [42.393684, 54.812263],
            [42.3891, 54.830207],
            [42.407356, 54.842369],
            [42.442662, 54.825224],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5527",
      properties: {
        name: "Республика Саха (Якутия)",
        density: 0,
        path: "/world/Russia/Республика Саха (Якутия)",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [161.07324, 69.356931],
              [161.097253, 69.357938],
              [161.093223, 69.340408],
              [161.07324, 69.356931],
            ],
          ],
          [
            [
              [161.813863, 69.485954],
              [161.798071, 69.478011],
              [161.758596, 69.483239],
              [161.74263, 69.506235],
              [161.77, 69.510114],
              [161.75485, 69.494244],
              [161.813863, 69.485954],
            ],
          ],
          [
            [
              [161.159471, 69.509375],
              [161.178467, 69.515482],
              [161.21843, 69.511591],
              [161.159471, 69.509375],
            ],
          ],
          [
            [
              [161.186534, 69.547048],
              [161.238572, 69.544525],
              [161.214794, 69.528769],
              [161.186534, 69.547048],
            ],
          ],
          [
            [
              [161.060791, 69.654765],
              [161.009939, 69.653356],
              [160.973464, 69.669958],
              [160.94873, 69.660844],
              [160.984371, 69.648582],
              [160.92839, 69.657336],
              [160.951034, 69.683992],
              [161.038902, 69.6727],
              [161.084116, 69.66054],
              [161.060791, 69.654765],
            ],
          ],
          [
            [
              [162.461278, 70.628572],
              [162.423414, 70.633595],
              [162.38492, 70.620146],
              [162.341614, 70.621193],
              [162.239568, 70.646212],
              [162.27099, 70.650808],
              [162.283006, 70.640106],
              [162.332204, 70.63694],
              [162.380684, 70.652564],
              [162.451759, 70.641536],
              [162.49993, 70.642762],
              [162.461278, 70.628572],
            ],
          ],
          [
            [
              [161.71875, 69.570028],
              [161.694559, 69.58618],
              [161.66246, 69.619803],
              [161.686319, 69.633548],
              [161.753095, 69.628888],
              [161.822447, 69.644657],
              [161.889738, 69.649852],
              [161.908403, 69.638841],
              [161.929714, 69.648312],
              [161.965208, 69.643663],
              [161.931745, 69.63228],
              [161.908581, 69.636144],
              [161.87755, 69.61795],
              [161.881841, 69.589054],
              [161.914972, 69.551064],
              [161.859697, 69.534505],
              [161.762193, 69.535825],
              [161.71875, 69.570028],
            ],
          ],
          [
            [
              [161.700397, 70.670123],
              [161.707935, 70.659812],
              [161.666686, 70.659921],
              [161.678448, 70.686512],
              [161.700397, 70.670123],
            ],
          ],
          [
            [
              [161.703858, 70.729607],
              [161.683965, 70.725618],
              [161.632797, 70.750912],
              [161.558439, 70.772313],
              [161.50315, 70.770685],
              [161.50174, 70.787363],
              [161.546582, 70.817343],
              [161.571594, 70.805471],
              [161.629727, 70.802193],
              [161.657407, 70.789663],
              [161.695226, 70.790474],
              [161.658914, 70.779922],
              [161.676316, 70.748185],
              [161.706599, 70.741588],
              [161.703858, 70.729607],
            ],
          ],
          [
            [
              [160.58791, 70.795582],
              [160.521913, 70.804374],
              [160.50102, 70.814865],
              [160.505896, 70.835593],
              [160.529941, 70.837594],
              [160.563932, 70.863952],
              [160.547626, 70.88657],
              [160.43296, 70.899938],
              [160.440737, 70.912462],
              [160.528275, 70.909984],
              [160.591486, 70.892172],
              [160.622593, 70.892172],
              [160.67703, 70.877741],
              [160.665465, 70.867679],
              [160.677758, 70.845969],
              [160.746286, 70.822265],
              [160.757306, 70.803919],
              [160.724547, 70.796532],
              [160.664871, 70.802018],
              [160.58791, 70.795582],
            ],
          ],
          [
            [
              [161.506036, 70.896109],
              [161.560505, 70.893336],
              [161.571572, 70.87995],
              [161.519831, 70.888156],
              [161.46002, 70.88358],
              [161.428699, 70.889477],
              [161.447568, 70.913966],
              [161.424275, 70.924619],
              [161.487096, 70.929578],
              [161.506036, 70.896109],
            ],
          ],
          [
            [
              [151.02531, 71.410473],
              [151.092277, 71.418062],
              [151.092782, 71.412954],
              [151.02531, 71.410473],
            ],
          ],
          [
            [
              [137.932902, 71.298203],
              [137.993457, 71.290738],
              [137.93149, 71.289482],
              [137.891652, 71.301876],
              [137.935987, 71.30644],
              [137.932902, 71.298203],
            ],
          ],
          [
            [
              [137.617342, 71.317619],
              [137.66721, 71.315437],
              [137.659267, 71.305957],
              [137.613082, 71.298607],
              [137.617342, 71.317619],
            ],
          ],
          [
            [
              [137.507251, 71.436654],
              [137.530892, 71.448092],
              [137.56659, 71.437227],
              [137.557825, 71.427856],
              [137.507251, 71.436654],
            ],
          ],
          [
            [
              [150.432513, 71.537366],
              [150.46875, 71.533103],
              [150.48625, 71.539872],
              [150.48218, 71.558486],
              [150.529898, 71.558088],
              [150.573164, 71.548159],
              [150.508494, 71.530192],
              [150.474088, 71.528076],
              [150.432513, 71.537366],
            ],
          ],
          [
            [
              [139.849286, 71.466082],
              [139.819127, 71.460019],
              [139.819925, 71.473185],
              [139.849286, 71.466082],
            ],
          ],
          [
            [
              [130.023619, 71.572098],
              [130.021137, 71.563423],
              [129.979968, 71.595199],
              [129.995339, 71.595248],
              [130.023619, 71.572098],
            ],
          ],
          [
            [
              [139.037685, 71.631221],
              [139.072999, 71.626723],
              [139.107693, 71.611987],
              [139.037685, 71.631221],
            ],
          ],
          [
            [
              [136.398894, 71.631483],
              [136.444464, 71.631166],
              [136.554036, 71.638486],
              [136.479632, 71.626751],
              [136.398894, 71.631483],
            ],
          ],
          [
            [
              [138.034603, 71.490658],
              [138.008382, 71.493572],
              [137.9672, 71.478977],
              [137.944458, 71.460067],
              [137.900839, 71.438894],
              [137.86194, 71.433649],
              [137.785556, 71.410237],
              [137.74717, 71.409581],
              [137.746038, 71.418645],
              [137.70923, 71.415706],
              [137.696955, 71.428798],
              [137.636029, 71.442054],
              [137.647376, 71.450699],
              [137.610272, 71.462834],
              [137.607352, 71.474808],
              [137.559309, 71.473432],
              [137.549729, 71.464045],
              [137.494865, 71.467134],
              [137.477726, 71.455301],
              [137.470877, 71.42037],
              [137.429564, 71.42241],
              [137.362583, 71.437003],
              [137.352934, 71.44552],
              [137.292227, 71.460211],
              [137.232052, 71.467599],
              [137.212241, 71.461958],
              [137.286744, 71.436556],
              [137.18592, 71.428409],
              [137.161128, 71.43994],
              [137.125525, 71.441134],
              [137.128298, 71.451303],
              [137.078221, 71.476002],
              [137.02558, 71.491277],
              [137.023362, 71.506142],
              [137.068546, 71.505615],
              [137.04466, 71.533292],
              [137.145772, 71.54855],
              [137.203235, 71.564423],
              [137.26433, 71.574589],
              [137.376767, 71.587635],
              [137.453042, 71.591115],
              [137.63733, 71.588438],
              [137.65777, 71.582656],
              [137.687294, 71.594429],
              [137.760462, 71.592742],
              [137.774109, 71.598218],
              [137.92483, 71.587658],
              [137.983764, 71.57325],
              [138.03343, 71.532079],
              [138.019236, 71.498859],
              [138.034603, 71.490658],
            ],
          ],
          [
            [
              [129.02411, 71.664337],
              [129.068673, 71.655574],
              [129.04391, 71.647916],
              [129.02411, 71.664337],
            ],
          ],
          [
            [
              [138.34288, 71.677177],
              [138.323153, 71.684447],
              [138.367336, 71.688384],
              [138.393873, 71.678772],
              [138.34288, 71.677177],
            ],
          ],
          [
            [
              [144.987466, 71.697752],
              [145.055794, 71.712449],
              [145.016991, 71.698672],
              [144.987466, 71.697752],
            ],
          ],
          [
            [
              [150.000442, 71.770607],
              [150.000272, 71.761395],
              [149.902451, 71.750253],
              [149.961138, 71.766026],
              [150.000442, 71.770607],
            ],
          ],
          [
            [
              [132.920629, 71.894201],
              [132.93227, 71.906509],
              [133.022242, 71.903508],
              [133.007292, 71.895242],
              [132.920629, 71.894201],
            ],
          ],
          [
            [
              [146.051184, 72.078787],
              [146.03973, 72.088695],
              [146.095507, 72.091604],
              [146.081185, 72.078905],
              [146.051184, 72.078787],
            ],
          ],
          [
            [
              [129.271418, 72.018484],
              [129.27531, 72.029976],
              [129.336616, 72.033202],
              [129.329636, 72.013028],
              [129.303794, 72.000295],
              [129.271418, 72.018484],
            ],
          ],
          [
            [
              [129.535761, 72.36518],
              [129.556121, 72.369468],
              [129.57324, 72.404424],
              [129.648099, 72.371744],
              [129.606515, 72.363699],
              [129.571304, 72.36968],
              [129.535761, 72.36518],
            ],
          ],
          [
            [
              [129.536687, 72.553998],
              [129.59555, 72.569673],
              [129.593373, 72.555371],
              [129.564778, 72.539867],
              [129.536687, 72.553998],
            ],
          ],
          [
            [
              [129.39789, 72.497472],
              [129.342736, 72.499236],
              [129.286138, 72.517721],
              [129.334104, 72.537575],
              [129.356237, 72.538048],
              [129.450121, 72.515119],
              [129.474195, 72.493346],
              [129.39789, 72.497472],
            ],
          ],
          [
            [
              [129.502702, 72.694228],
              [129.34641, 72.713081],
              [129.326355, 72.720524],
              [129.386985, 72.741495],
              [129.471711, 72.755878],
              [129.472227, 72.742847],
              [129.545849, 72.729759],
              [129.529723, 72.708084],
              [129.538605, 72.689281],
              [129.502702, 72.694228],
            ],
          ],
          [
            [
              [111.119565, 74.276303],
              [111.098971, 74.283168],
              [111.084908, 74.312309],
              [111.156856, 74.319284],
              [111.213643, 74.305358],
              [111.215355, 74.269366],
              [111.119565, 74.276303],
            ],
          ],
          [
            [
              [112.808583, 74.081781],
              [112.618825, 74.091266],
              [112.490901, 74.115039],
              [112.36246, 74.126008],
              [112.322955, 74.123876],
              [112.207752, 74.135329],
              [112.127085, 74.157155],
              [112.000069, 74.206281],
              [111.954569, 74.217894],
              [111.831097, 74.234138],
              [111.677728, 74.240431],
              [111.595302, 74.263107],
              [111.511237, 74.297481],
              [111.508354, 74.324281],
              [111.553999, 74.347582],
              [111.633019, 74.363706],
              [111.746064, 74.365145],
              [111.800824, 74.354975],
              [111.836867, 74.334579],
              [111.936638, 74.334272],
              [111.959432, 74.353858],
              [112.036853, 74.360902],
              [112.055994, 74.371858],
              [112.019211, 74.37715],
              [112.025376, 74.390065],
              [112.084121, 74.418007],
              [112.029431, 74.4393],
              [112.014249, 74.462408],
              [112.009378, 74.498042],
              [112.019907, 74.539102],
              [112.009803, 74.544563],
              [112.110339, 74.548306],
              [112.312208, 74.531067],
              [112.511291, 74.517843],
              [112.75684, 74.507568],
              [113.086019, 74.506718],
              [113.259613, 74.486507],
              [113.340783, 74.470564],
              [113.405104, 74.450331],
              [113.446003, 74.426075],
              [113.467419, 74.381672],
              [113.455543, 74.369238],
              [113.404799, 74.365518],
              [113.390655, 74.34956],
              [113.403216, 74.325359],
              [113.333997, 74.291403],
              [113.313627, 74.28883],
              [113.276693, 74.265232],
              [113.229944, 74.218551],
              [113.164751, 74.190861],
              [113.154672, 74.198971],
              [113.077912, 74.200552],
              [112.987776, 74.184695],
              [112.870747, 74.094846],
              [112.834292, 74.078661],
              [112.808583, 74.081781],
            ],
          ],
          [
            [
              [115.969117, 74.316858],
              [115.973236, 74.289284],
              [116.039492, 74.277321],
              [115.986688, 74.27785],
              [115.952587, 74.295063],
              [115.965334, 74.324249],
              [116.054892, 74.368339],
              [116.110962, 74.365506],
              [116.149897, 74.350091],
              [116.073189, 74.363307],
              [115.969117, 74.316858],
            ],
          ],
          [
            [
              [112.972569, 74.639302],
              [112.941085, 74.633417],
              [112.943283, 74.661672],
              [112.916675, 74.674466],
              [112.951802, 74.682233],
              [112.986512, 74.671978],
              [112.994222, 74.6547],
              [112.972569, 74.639302],
            ],
          ],
          [
            [
              [149.259355, 74.732794],
              [149.173584, 74.727824],
              [148.979378, 74.728517],
              [148.862482, 74.738961],
              [148.736885, 74.736122],
              [148.651499, 74.74137],
              [148.592392, 74.750457],
              [148.505898, 74.753624],
              [148.447258, 74.761882],
              [148.358985, 74.763505],
              [148.261126, 74.771834],
              [148.109482, 74.795453],
              [148.081419, 74.811251],
              [148.011208, 74.82733],
              [147.943295, 74.864134],
              [147.812249, 74.903844],
              [147.776963, 74.91107],
              [147.754455, 74.929473],
              [147.686092, 74.953979],
              [147.549719, 74.970534],
              [147.405334, 74.975669],
              [147.317664, 74.981584],
              [147.121029, 75.007485],
              [146.990683, 75.049439],
              [146.82797, 75.084172],
              [146.753073, 75.104791],
              [146.62332, 75.122535],
              [146.55739, 75.137049],
              [146.44238, 75.153515],
              [146.323519, 75.165392],
              [146.249433, 75.185548],
              [146.128677, 75.225427],
              [146.121806, 75.243649],
              [146.22752, 75.25116],
              [146.256127, 75.268203],
              [146.289924, 75.269743],
              [146.298587, 75.282878],
              [146.275429, 75.305966],
              [146.291257, 75.310734],
              [146.260053, 75.328362],
              [146.229442, 75.38263],
              [146.249087, 75.405262],
              [146.360365, 75.441716],
              [146.391596, 75.478106],
              [146.416591, 75.484017],
              [146.41633, 75.505628],
              [146.391737, 75.521288],
              [146.388662, 75.547049],
              [146.436732, 75.58258],
              [146.515544, 75.586615],
              [146.630128, 75.556951],
              [146.687141, 75.545793],
              [146.749744, 75.512691],
              [146.726024, 75.484477],
              [146.65484, 75.467078],
              [146.64491, 75.436949],
              [146.670491, 75.419077],
              [146.726914, 75.400473],
              [146.736686, 75.373039],
              [146.794877, 75.350307],
              [146.923932, 75.327946],
              [147.042504, 75.318955],
              [147.116768, 75.318981],
              [147.186862, 75.326884],
              [147.31143, 75.350759],
              [147.36187, 75.354781],
              [147.35083, 75.367023],
              [147.261078, 75.36136],
              [147.208557, 75.349087],
              [147.171954, 75.361938],
              [147.28933, 75.384994],
              [147.349357, 75.406483],
              [147.340781, 75.419812],
              [147.45303, 75.427019],
              [147.513421, 75.420466],
              [147.61932, 75.440618],
              [147.820164, 75.421877],
              [147.85292, 75.411025],
              [147.9852, 75.392969],
              [148.096744, 75.391791],
              [148.193563, 75.398086],
              [148.368901, 75.402009],
              [148.565677, 75.373579],
              [148.583351, 75.348295],
              [148.51216, 75.312],
              [148.520274, 75.287803],
              [148.548324, 75.258065],
              [148.520737, 75.236316],
              [148.523829, 75.223347],
              [148.625244, 75.199554],
              [148.71711, 75.194051],
              [148.927725, 75.205701],
              [148.968444, 75.219753],
              [149.101991, 75.225922],
              [149.154801, 75.241327],
              [149.33985, 75.26127],
              [149.384097, 75.262505],
              [149.448896, 75.255246],
              [149.6163, 75.243385],
              [149.683953, 75.232592],
              [149.828311, 75.216526],
              [150.142474, 75.20426],
              [150.235531, 75.187882],
              [150.29087, 75.171181],
              [150.332356, 75.137195],
              [150.38605, 75.116211],
              [150.52916, 75.09638],
              [150.635435, 75.108599],
              [150.68036, 75.125321],
              [150.770523, 75.140112],
              [150.85034, 75.144969],
              [150.922922, 75.14028],
              [150.965158, 75.124841],
              [150.849814, 75.090214],
              [150.8094, 75.063546],
              [150.794666, 75.04232],
              [150.695384, 75.007706],
              [150.666356, 74.976168],
              [150.70107, 74.9602],
              [150.670546, 74.930967],
              [150.629724, 74.861359],
              [150.527784, 74.859751],
              [150.460466, 74.846822],
              [150.340034, 74.834025],
              [150.161577, 74.821089],
              [150.06587, 74.817611],
              [149.996311, 74.803108],
              [149.928196, 74.799373],
              [149.807524, 74.777557],
              [149.78233, 74.781641],
              [149.70774, 74.765198],
              [149.744814, 74.755239],
              [149.600337, 74.756446],
              [149.558501, 74.742831],
              [149.506306, 74.751267],
              [149.517973, 74.729676],
              [149.472642, 74.734491],
              [149.46006, 74.747345],
              [149.42495, 74.742222],
              [149.2887, 74.738283],
              [149.259355, 74.732794],
            ],
          ],
          [
            [
              [135.4996, 75.438083],
              [135.52166, 75.44615],
              [135.607319, 75.509621],
              [135.645821, 75.592084],
              [135.64138, 75.6171],
              [135.6202, 75.638629],
              [135.60854, 75.674563],
              [135.650422, 75.735012],
              [135.680955, 75.757348],
              [135.720527, 75.801863],
              [135.72088, 75.827774],
              [135.679373, 75.835566],
              [135.722156, 75.871323],
              [135.737436, 75.848367],
              [135.779583, 75.822754],
              [135.795499, 75.79419],
              [135.830006, 75.76222],
              [135.880047, 75.737459],
              [135.898638, 75.711429],
              [136.000249, 75.670676],
              [136.133692, 75.637801],
              [136.234284, 75.623771],
              [136.157064, 75.594194],
              [136.1076, 75.581535],
              [136.039401, 75.528145],
              [135.978067, 75.500897],
              [135.958282, 75.479698],
              [135.99157, 75.455144],
              [136.05557, 75.442513],
              [136.016498, 75.431526],
              [135.965522, 75.400036],
              [135.870431, 75.382304],
              [135.872672, 75.375517],
              [135.728146, 75.364551],
              [135.579418, 75.366037],
              [135.536424, 75.370141],
              [135.514717, 75.384778],
              [135.517331, 75.430076],
              [135.4996, 75.438083],
            ],
          ],
          [
            [
              [140.569761, 75.695177],
              [140.618113, 75.703432],
              [140.619519, 75.689131],
              [140.569761, 75.695177],
            ],
          ],
          [
            [
              [140.981062, 75.670276],
              [141.027489, 75.673596],
              [141.113082, 75.66744],
              [141.072736, 75.642808],
              [141.046999, 75.643964],
              [140.981062, 75.670276],
            ],
          ],
          [
            [
              [140.42839, 75.663718],
              [140.399303, 75.643723],
              [140.469957, 75.626265],
              [140.565633, 75.625437],
              [140.561122, 75.638711],
              [140.614577, 75.641485],
              [140.626034, 75.656446],
              [140.651872, 75.65342],
              [140.757382, 75.659937],
              [140.788181, 75.66865],
              [140.79865, 75.657421],
              [140.764175, 75.654069],
              [140.790602, 75.643621],
              [140.691763, 75.643003],
              [140.656612, 75.649085],
              [140.599407, 75.631499],
              [140.665336, 75.627897],
              [140.700063, 75.614661],
              [140.765366, 75.611925],
              [140.80532, 75.620645],
              [140.931827, 75.609987],
              [140.951313, 75.620337],
              [141.012989, 75.611274],
              [141.041785, 75.621232],
              [141.103471, 75.620958],
              [141.186365, 75.63138],
              [141.197458, 75.652827],
              [141.182177, 75.666887],
              [141.109577, 75.681746],
              [141.090673, 75.712915],
              [141.040544, 75.731064],
              [140.977867, 75.737196],
              [141.065577, 75.769116],
              [141.001712, 75.820464],
              [141.022623, 75.862862],
              [140.982856, 75.900154],
              [140.966017, 75.930036],
              [141.003306, 75.962476],
              [141.01651, 75.999638],
              [141.144332, 76.012494],
              [141.190759, 76.008759],
              [141.243762, 76.020589],
              [141.312444, 76.019872],
              [141.348541, 76.026592],
              [141.438395, 76.025936],
              [141.572252, 76.015442],
              [141.592936, 76.004665],
              [141.675714, 75.991423],
              [141.706886, 76.003212],
              [141.582547, 76.034702],
              [141.559967, 76.079327],
              [141.45513, 76.119645],
              [141.447488, 76.147244],
              [141.383804, 76.158206],
              [141.350557, 76.154716],
              [141.357425, 76.170123],
              [141.398087, 76.172742],
              [141.47874, 76.156891],
              [141.565326, 76.133975],
              [141.646816, 76.122025],
              [141.76025, 76.113271],
              [141.82345, 76.102339],
              [141.87996, 76.079409],
              [142.029064, 76.035147],
              [142.065788, 76.016853],
              [142.127881, 76.000224],
              [142.242036, 75.9777],
              [142.361715, 75.939505],
              [142.423029, 75.900575],
              [142.451438, 75.891209],
              [142.608908, 75.856487],
              [142.688119, 75.845598],
              [142.799696, 75.835721],
              [143.016678, 75.804743],
              [143.163615, 75.798101],
              [143.269921, 75.811619],
              [143.341477, 75.829026],
              [143.434789, 75.843951],
              [143.584593, 75.851979],
              [143.700379, 75.84496],
              [143.8732, 75.825832],
              [144.067371, 75.796331],
              [144.126678, 75.776641],
              [144.192293, 75.762734],
              [144.258775, 75.739332],
              [144.346679, 75.715353],
              [144.396455, 75.706593],
              [144.510347, 75.699279],
              [144.623753, 75.700058],
              [144.69009, 75.692134],
              [144.751298, 75.674547],
              [144.76422, 75.658477],
              [144.799428, 75.646914],
              [144.891752, 75.629299],
              [144.966315, 75.622947],
              [145.148767, 75.593976],
              [145.260909, 75.5692],
              [145.349, 75.519679],
              [145.379385, 75.513763],
              [145.400026, 75.495119],
              [145.294625, 75.505217],
              [145.16055, 75.495009],
              [145.088162, 75.483164],
              [145.085207, 75.472977],
              [144.94634, 75.450169],
              [144.85661, 75.428964],
              [144.790177, 75.395805],
              [144.779196, 75.371328],
              [144.803903, 75.349126],
              [144.837761, 75.29696],
              [144.954889, 75.269878],
              [144.907306, 75.265689],
              [144.79475, 75.245661],
              [144.778281, 75.236103],
              [144.731155, 75.165397],
              [144.72841, 75.147816],
              [144.631871, 75.126809],
              [144.59115, 75.112827],
              [144.466701, 75.081056],
              [144.388005, 75.048038],
              [144.271792, 75.046149],
              [144.185775, 75.035756],
              [144.059496, 75.039536],
              [144.022893, 75.051815],
              [143.921195, 75.051716],
              [143.878507, 75.059155],
              [143.779672, 75.066253],
              [143.700812, 75.063358],
              [143.595298, 75.066746],
              [143.486963, 75.050949],
              [143.377804, 75.047201],
              [143.28597, 75.078938],
              [143.231136, 75.088689],
              [143.177466, 75.105717],
              [143.084714, 75.110664],
              [143.070817, 75.132769],
              [143.03872, 75.138422],
              [142.963707, 75.136005],
              [142.926942, 75.144493],
              [142.945365, 75.156094],
              [142.902052, 75.180477],
              [142.883642, 75.217171],
              [142.816177, 75.226397],
              [142.772694, 75.222901],
              [142.78299, 75.242243],
              [142.737456, 75.264819],
              [142.742504, 75.299789],
              [142.692939, 75.315486],
              [142.615013, 75.321659],
              [142.588162, 75.334619],
              [142.524551, 75.343985],
              [142.557598, 75.365741],
              [142.549769, 75.392622],
              [142.609544, 75.435146],
              [142.610131, 75.449962],
              [142.569689, 75.456878],
              [142.619419, 75.474555],
              [142.665443, 75.471807],
              [142.69647, 75.50276],
              [142.789834, 75.536568],
              [142.909387, 75.555567],
              [142.958237, 75.572546],
              [143.020709, 75.583267],
              [143.082677, 75.618767],
              [143.094136, 75.637834],
              [143.084919, 75.67804],
              [143.0516, 75.683439],
              [143.028487, 75.710471],
              [142.920715, 75.725159],
              [142.770011, 75.721081],
              [142.766059, 75.731204],
              [142.63852, 75.730577],
              [142.601359, 75.722396],
              [142.458915, 75.711553],
              [142.467199, 75.732198],
              [142.41952, 75.737339],
              [142.379138, 75.710493],
              [142.480424, 75.694047],
              [142.420981, 75.667326],
              [142.33236, 75.657107],
              [142.258613, 75.662617],
              [142.179695, 75.655867],
              [142.287159, 75.647136],
              [142.297604, 75.637116],
              [142.23442, 75.620334],
              [142.161446, 75.607673],
              [142.188921, 75.591698],
              [142.140815, 75.553613],
              [142.086874, 75.543904],
              [142.109108, 75.526684],
              [142.10677, 75.509068],
              [142.148862, 75.49916],
              [142.147991, 75.487923],
              [142.183989, 75.472928],
              [142.184902, 75.433325],
              [142.161947, 75.418877],
              [142.174051, 75.392248],
              [142.241504, 75.351024],
              [142.279347, 75.321184],
              [142.27023, 75.308715],
              [142.32799, 75.29755],
              [142.41557, 75.258823],
              [142.448404, 75.219273],
              [142.51831, 75.182672],
              [142.563427, 75.174706],
              [142.58945, 75.146806],
              [142.633678, 75.136532],
              [142.656403, 75.102896],
              [142.729059, 75.086519],
              [142.76855, 75.071368],
              [142.838826, 75.067546],
              [142.98804, 75.065713],
              [143.059392, 75.059757],
              [143.169027, 75.037158],
              [143.274751, 75.027087],
              [143.39464, 74.996986],
              [143.423735, 74.994977],
              [143.539159, 75.00281],
              [143.598908, 74.991561],
              [143.673012, 74.966423],
              [143.699589, 74.949557],
              [143.681354, 74.930353],
              [143.602206, 74.91683],
              [143.54905, 74.89713],
              [143.47223, 74.888029],
              [143.224314, 74.887068],
              [143.162237, 74.877658],
              [143.031365, 74.874477],
              [143.02779, 74.88831],
              [142.958228, 74.892242],
              [142.929447, 74.869743],
              [142.86513, 74.863891],
              [142.813534, 74.886925],
              [142.740509, 74.890945],
              [142.700816, 74.864159],
              [142.69684, 74.850134],
              [142.657653, 74.849273],
              [142.625967, 74.829762],
              [142.653536, 74.825779],
              [142.695861, 74.841197],
              [142.76136, 74.84172],
              [142.665558, 74.824776],
              [142.560701, 74.801534],
              [142.49708, 74.79722],
              [142.430169, 74.801821],
              [142.359418, 74.813607],
              [142.26399, 74.836291],
              [142.0982, 74.879576],
              [141.909175, 74.932572],
              [141.973682, 74.930253],
              [142.011942, 74.943667],
              [142.024989, 74.960694],
              [142.127938, 74.968896],
              [142.230984, 74.952268],
              [142.314073, 74.911564],
              [142.361707, 74.912179],
              [142.371773, 74.92055],
              [142.346886, 74.939095],
              [142.221444, 74.968415],
              [142.23333, 75.000032],
              [142.20768, 75.0063],
              [142.0853, 74.992606],
              [141.895374, 74.99943],
              [141.860759, 74.997903],
              [141.761625, 74.96811],
              [141.654964, 74.960266],
              [141.603372, 74.949424],
              [141.62948, 74.938624],
              [141.771365, 74.946364],
              [141.554455, 74.928984],
              [140.974177, 74.893512],
              [140.686654, 74.868392],
              [140.189173, 74.804701],
              [140.06113, 74.795521],
              [139.91937, 74.791554],
              [139.844026, 74.802225],
              [139.937808, 74.79539],
              [140.082215, 74.801227],
              [140.096436, 74.807927],
              [140.040119, 74.836308],
              [140.026551, 74.850301],
              [140.031225, 74.877145],
              [139.965608, 74.900252],
              [139.912923, 74.900252],
              [139.905021, 74.915513],
              [139.833238, 74.922024],
              [139.777261, 74.944791],
              [139.714699, 74.956932],
              [139.642916, 74.9624],
              [139.6034, 74.947528],
              [139.601428, 74.936407],
              [139.555329, 74.931443],
              [139.524607, 74.910251],
              [139.51216, 74.870621],
              [139.525079, 74.85642],
              [139.60452, 74.835639],
              [139.642415, 74.839652],
              [139.69408, 74.862747],
              [139.683064, 74.846851],
              [139.595997, 74.824178],
              [139.54251, 74.764795],
              [139.512491, 74.739877],
              [139.455803, 74.706155],
              [139.369364, 74.675124],
              [139.24272, 74.642007],
              [139.164054, 74.634694],
              [139.090748, 74.634339],
              [139.034194, 74.640481],
              [138.8057, 74.693884],
              [138.656542, 74.717357],
              [138.549598, 74.727774],
              [138.435686, 74.734373],
              [138.230377, 74.75546],
              [138.176369, 74.756623],
              [138.100785, 74.79013],
              [138.058034, 74.81409],
              [137.997058, 74.836752],
              [137.982584, 74.856475],
              [137.941308, 74.876068],
              [137.907402, 74.91375],
              [137.871068, 74.938115],
              [137.801812, 74.95938],
              [137.738055, 74.970484],
              [137.663753, 74.997783],
              [137.570082, 75.013563],
              [137.531061, 75.015826],
              [137.388317, 75.052281],
              [137.35833, 75.055315],
              [137.31687, 75.079935],
              [137.246147, 75.106322],
              [137.148019, 75.166161],
              [137.066246, 75.208157],
              [137.00268, 75.234537],
              [136.95296, 75.26351],
              [136.966935, 75.267099],
              [136.966798, 75.29811],
              [136.931515, 75.34398],
              [137.062286, 75.366806],
              [137.196606, 75.375117],
              [137.21745, 75.390199],
              [137.17778, 75.432501],
              [137.246354, 75.443046],
              [137.297739, 75.470513],
              [137.27847, 75.493245],
              [137.187373, 75.526662],
              [137.106567, 75.543166],
              [137.043951, 75.561386],
              [136.990709, 75.585321],
              [137.1644, 75.623131],
              [137.225877, 75.651389],
              [137.217453, 75.681737],
              [137.149479, 75.697533],
              [137.154998, 75.725924],
              [137.213829, 75.751003],
              [137.26847, 75.765249],
              [137.353927, 75.767201],
              [137.426244, 75.758314],
              [137.432556, 75.750877],
              [137.498356, 75.752855],
              [137.563774, 75.765983],
              [137.610255, 75.792673],
              [137.571043, 75.822172],
              [137.49766, 75.850723],
              [137.441492, 75.880913],
              [137.441761, 75.909077],
              [137.514059, 75.948284],
              [137.6577, 75.990807],
              [137.772213, 75.998396],
              [137.830151, 75.992622],
              [137.97062, 76.001308],
              [138.005356, 75.991893],
              [138.102617, 75.999403],
              [138.164216, 76.03432],
              [138.159585, 76.043818],
              [138.198489, 76.067367],
              [138.140018, 76.06156],
              [138.204517, 76.100918],
              [138.268345, 76.119416],
              [138.42551, 76.137199],
              [138.595907, 76.173177],
              [138.718993, 76.191849],
              [138.84264, 76.200841],
              [139.06171, 76.195867],
              [139.030277, 76.179557],
              [139.039843, 76.156929],
              [139.10555, 76.119453],
              [139.176164, 76.088244],
              [139.241397, 76.070881],
              [139.320128, 76.060708],
              [139.426384, 76.052225],
              [139.544162, 76.015302],
              [139.549923, 76.001993],
              [139.60113, 75.984796],
              [139.73491, 75.973165],
              [139.880832, 75.964055],
              [140.001317, 75.947209],
              [139.93926, 75.917486],
              [139.862197, 75.912032],
              [139.83131, 75.893946],
              [139.835826, 75.871349],
              [139.914592, 75.867114],
              [139.94857, 75.846641],
              [139.99246, 75.832559],
              [140.069773, 75.822246],
              [140.173477, 75.795291],
              [140.308368, 75.803251],
              [140.430484, 75.795397],
              [140.47555, 75.786433],
              [140.495101, 75.762464],
              [140.422771, 75.753872],
              [140.467478, 75.737117],
              [140.432514, 75.73014],
              [140.389907, 75.696318],
              [140.37851, 75.672789],
              [140.42839, 75.663718],
            ],
          ],
          [
            [
              [141.35611, 76.075876],
              [141.40267, 76.071462],
              [141.360113, 76.066165],
              [141.35611, 76.075876],
            ],
          ],
          [
            [
              [140.92852, 76.078168],
              [140.934831, 76.09094],
              [141.088852, 76.093232],
              [141.071742, 76.070761],
              [140.962511, 76.066043],
              [140.92852, 76.078168],
            ],
          ],
          [
            [
              [110.914279, 73.698611],
              [110.843917, 73.718953],
              [110.866858, 73.733459],
              [110.883231, 73.762895],
              [110.907194, 73.768841],
              [110.991666, 73.812938],
              [111.056944, 73.823841],
              [111.132383, 73.821506],
              [111.168105, 73.827327],
              [111.234498, 73.82194],
              [111.226024, 73.838634],
              [111.32291, 73.852293],
              [111.439663, 73.810068],
              [111.551647, 73.778909],
              [111.674971, 73.75413],
              [111.896061, 73.72714],
              [112.297986, 73.701064],
              [112.481844, 73.702626],
              [112.811923, 73.71759],
              [112.89653, 73.733578],
              [112.971961, 73.765484],
              [112.997191, 73.786184],
              [112.99718, 73.807175],
              [112.895209, 73.90611],
              [112.878112, 73.909878],
              [112.829406, 73.974739],
              [112.829928, 74],
              [112.852119, 73.993099],
              [112.959218, 73.934972],
              [113.008183, 73.914128],
              [113.162151, 73.873423],
              [113.234034, 73.81046],
              [113.24418, 73.791956],
              [113.326817, 73.726858],
              [113.370251, 73.709691],
              [113.393008, 73.684489],
              [113.43135, 73.66262],
              [113.460881, 73.630363],
              [113.465289, 73.600754],
              [113.451575, 73.584148],
              [113.361606, 73.577497],
              [113.277322, 73.526889],
              [113.260952, 73.478484],
              [113.197133, 73.448159],
              [113.247861, 73.425784],
              [113.305272, 73.411195],
              [113.327494, 73.384971],
              [113.390243, 73.363162],
              [113.449178, 73.350357],
              [113.530822, 73.348278],
              [113.554356, 73.33997],
              [113.581329, 73.314317],
              [113.563459, 73.294577],
              [113.553448, 73.255775],
              [113.481776, 73.226425],
              [113.519991, 73.206089],
              [113.534935, 73.188941],
              [113.510388, 73.170361],
              [113.517917, 73.15488],
              [113.575211, 73.135114],
              [113.610169, 73.145671],
              [113.579599, 73.162053],
              [113.627725, 73.155169],
              [113.66677, 73.158618],
              [113.598918, 73.203053],
              [113.580553, 73.226545],
              [113.590865, 73.234902],
              [113.692707, 73.258555],
              [113.7186, 73.27406],
              [113.767853, 73.321648],
              [113.832417, 73.324176],
              [113.870709, 73.346242],
              [113.842732, 73.350424],
              [113.814675, 73.37174],
              [113.76423, 73.378639],
              [113.730605, 73.393208],
              [113.68802, 73.398293],
              [113.651476, 73.420353],
              [113.620498, 73.422924],
              [113.542677, 73.464126],
              [113.557954, 73.490731],
              [113.521718, 73.507277],
              [113.630089, 73.514927],
              [113.72537, 73.530119],
              [113.88429, 73.538799],
              [113.955034, 73.54674],
              [114.009719, 73.564148],
              [114.101113, 73.58435],
              [114.19999, 73.593484],
              [114.317755, 73.598336],
              [114.448482, 73.58372],
              [114.55472, 73.580631],
              [114.83637, 73.597637],
              [114.917869, 73.59705],
              [114.979102, 73.606974],
              [115.025365, 73.625431],
              [114.984354, 73.631179],
              [115.077653, 73.647637],
              [115.102123, 73.66396],
              [115.211727, 73.683696],
              [115.257745, 73.678329],
              [115.248748, 73.694867],
              [115.339717, 73.704158],
              [115.374925, 73.697786],
              [115.435381, 73.703223],
              [115.449214, 73.711118],
              [115.656969, 73.709338],
              [115.744464, 73.695946],
              [115.79771, 73.701876],
              [115.866418, 73.695099],
              [115.916413, 73.695615],
              [116.053374, 73.686433],
              [116.165257, 73.666846],
              [116.300132, 73.655071],
              [116.397121, 73.657796],
              [116.48478, 73.669779],
              [116.622841, 73.668537],
              [116.691036, 73.663537],
              [116.725681, 73.654388],
              [116.863678, 73.643026],
              [117.004203, 73.619949],
              [117.109196, 73.614684],
              [117.194186, 73.606704],
              [117.316706, 73.578965],
              [117.466377, 73.569362],
              [117.583929, 73.575074],
              [117.744442, 73.569842],
              [117.92593, 73.570274],
              [117.970628, 73.578249],
              [118.095293, 73.578405],
              [118.233521, 73.566272],
              [118.326618, 73.567752],
              [118.519308, 73.56198],
              [118.625811, 73.561953],
              [118.794205, 73.541164],
              [118.878181, 73.517941],
              [119.005276, 73.492485],
              [118.981309, 73.475843],
              [118.909731, 73.478296],
              [118.867356, 73.489156],
              [118.837082, 73.487634],
              [118.838767, 73.469984],
              [118.862288, 73.455208],
              [118.905275, 73.454566],
              [118.837513, 73.433446],
              [118.767825, 73.426634],
              [118.72137, 73.436605],
              [118.69596, 73.451379],
              [118.576416, 73.467774],
              [118.512673, 73.437322],
              [118.473721, 73.437599],
              [118.433757, 73.4205],
              [118.397081, 73.393405],
              [118.397776, 73.369124],
              [118.4709, 73.358145],
              [118.41333, 73.314334],
              [118.402287, 73.228036],
              [118.444307, 73.211903],
              [118.487268, 73.187066],
              [118.536434, 73.173929],
              [118.594348, 73.170423],
              [118.76236, 73.139538],
              [118.815834, 73.137158],
              [118.950355, 73.119571],
              [119.105636, 73.086855],
              [119.210348, 73.074636],
              [119.290387, 73.069286],
              [119.339358, 73.074614],
              [119.355721, 73.085292],
              [119.284428, 73.097474],
              [119.269689, 73.109141],
              [119.339217, 73.119356],
              [119.38499, 73.11947],
              [119.404015, 73.110684],
              [119.367502, 73.087969],
              [119.392603, 73.059684],
              [119.506316, 73.045414],
              [119.562927, 73.060952],
              [119.560661, 73.069418],
              [119.499288, 73.06979],
              [119.428868, 73.077666],
              [119.448756, 73.097373],
              [119.565919, 73.10515],
              [119.620598, 73.10037],
              [119.657549, 73.083274],
              [119.720399, 73.069538],
              [119.780552, 73.064257],
              [119.712512, 73.03576],
              [119.730537, 73.013784],
              [119.660944, 73.022115],
              [119.638071, 73.035835],
              [119.644779, 73.05122],
              [119.615182, 73.078959],
              [119.567856, 73.070607],
              [119.630177, 73.006659],
              [119.691207, 72.994103],
              [119.708739, 72.977505],
              [119.789046, 72.960953],
              [119.833717, 72.945888],
              [119.91838, 72.930884],
              [119.908757, 72.917466],
              [119.99012, 72.907208],
              [120.031805, 72.906848],
              [120.217921, 72.916271],
              [120.306608, 72.906536],
              [120.359728, 72.895432],
              [120.480041, 72.87729],
              [120.605874, 72.863964],
              [120.728579, 72.856215],
              [120.736046, 72.871976],
              [120.683694, 72.87498],
              [120.579598, 72.888483],
              [120.533138, 72.899531],
              [120.437261, 72.905139],
              [120.378405, 72.918021],
              [120.244198, 72.936064],
              [120.16798, 72.924089],
              [120.082152, 72.926172],
              [120.003348, 72.943974],
              [119.880718, 72.965269],
              [119.827497, 72.983058],
              [119.801865, 73.000289],
              [119.855203, 73.013745],
              [120.001944, 73.007171],
              [120.081171, 72.995122],
              [120.184514, 72.991365],
              [120.197231, 73.003574],
              [120.203968, 72.992596],
              [120.288923, 72.988817],
              [120.321911, 72.99225],
              [120.459127, 72.974366],
              [120.533346, 72.978597],
              [120.6324, 72.975965],
              [120.710087, 72.967402],
              [120.802614, 72.951111],
              [120.972657, 72.931357],
              [121.0538, 72.924126],
              [121.133055, 72.923942],
              [121.283699, 72.93773],
              [121.442398, 72.961608],
              [121.51583, 72.961788],
              [121.624952, 72.968053],
              [121.730452, 72.967044],
              [121.753376, 72.95165],
              [121.883664, 72.939601],
              [121.894897, 72.932924],
              [122.020095, 72.91296],
              [122.055939, 72.910277],
              [122.110335, 72.896485],
              [122.170227, 72.892791],
              [122.233419, 72.879764],
              [122.367491, 72.871262],
              [122.423088, 72.859654],
              [122.458803, 72.85985],
              [122.486539, 72.879105],
              [122.526107, 72.880339],
              [122.558432, 72.866888],
              [122.639642, 72.848742],
              [122.744702, 72.816285],
              [122.764139, 72.822659],
              [122.700279, 72.842683],
              [122.711811, 72.848772],
              [122.65717, 72.86544],
              [122.613076, 72.871796],
              [122.581231, 72.90589],
              [122.52502, 72.912941],
              [122.533862, 72.918856],
              [122.585587, 72.914107],
              [122.714027, 72.911947],
              [122.719146, 72.919627],
              [122.67539, 72.930089],
              [122.607744, 72.93158],
              [122.469747, 72.956751],
              [122.475203, 72.963015],
              [122.572202, 72.976416],
              [122.611081, 72.969323],
              [122.622289, 72.976966],
              [122.554556, 72.992543],
              [122.530943, 72.987158],
              [122.53719, 73.001351],
              [122.595914, 73.025913],
              [122.657289, 73.028328],
              [122.634872, 73.054085],
              [122.729522, 73.039656],
              [122.78205, 73.020475],
              [122.810774, 73.022354],
              [122.806906, 73.008095],
              [122.864104, 72.998122],
              [122.900394, 73.001694],
              [123.054579, 72.990379],
              [123.020944, 72.981055],
              [123, 72.963564],
              [123.050541, 72.95459],
              [122.982882, 72.95053],
              [123.004731, 72.94144],
              [123.105377, 72.933346],
              [123.13199, 72.926931],
              [123.134182, 72.903387],
              [123.175793, 72.894962],
              [123.240813, 72.898742],
              [123.316733, 72.920121],
              [123.383816, 72.96418],
              [123.437314, 72.966545],
              [123.42569, 72.989312],
              [123.405115, 72.993366],
              [123.378914, 73.015539],
              [123.388433, 73.046315],
              [123.429866, 73.061966],
              [123.440744, 73.08063],
              [123.469551, 73.081006],
              [123.49051, 73.113476],
              [123.630117, 73.10413],
              [123.61896, 73.125813],
              [123.555124, 73.151109],
              [123.471792, 73.146173],
              [123.469004, 73.164754],
              [123.515702, 73.166362],
              [123.531421, 73.175111],
              [123.579711, 73.176141],
              [123.607549, 73.188608],
              [123.706549, 73.179498],
              [123.719434, 73.193209],
              [123.623142, 73.203779],
              [123.570931, 73.198514],
              [123.581231, 73.213856],
              [123.556131, 73.233151],
              [123.513502, 73.231032],
              [123.48817, 73.241719],
              [123.532547, 73.241348],
              [123.568645, 73.263579],
              [123.529045, 73.266118],
              [123.496759, 73.257374],
              [123.500844, 73.275226],
              [123.42073, 73.275673],
              [123.410267, 73.290636],
              [123.472112, 73.291281],
              [123.428809, 73.311506],
              [123.388494, 73.313651],
              [123.401701, 73.325058],
              [123.369887, 73.338107],
              [123.389588, 73.357275],
              [123.447583, 73.363703],
              [123.442938, 73.370396],
              [123.34459, 73.380866],
              [123.265723, 73.396993],
              [123.305365, 73.405811],
              [123.328653, 73.391109],
              [123.368204, 73.393627],
              [123.380286, 73.4043],
              [123.340277, 73.410272],
              [123.320696, 73.432565],
              [123.350635, 73.452456],
              [123.382329, 73.457654],
              [123.348009, 73.471924],
              [123.294234, 73.465589],
              [123.365185, 73.486425],
              [123.381196, 73.504941],
              [123.336052, 73.509906],
              [123.314122, 73.534706],
              [123.33432, 73.546294],
              [123.396087, 73.544293],
              [123.396079, 73.564582],
              [123.373595, 73.580673],
              [123.420278, 73.569751],
              [123.486935, 73.576694],
              [123.41294, 73.610039],
              [123.465245, 73.601762],
              [123.427299, 73.649923],
              [123.461293, 73.643492],
              [123.499381, 73.650028],
              [123.553646, 73.648864],
              [123.581354, 73.663672],
              [123.617929, 73.668438],
              [123.595667, 73.693026],
              [123.700867, 73.695373],
              [123.716648, 73.668663],
              [123.663521, 73.665978],
              [123.662559, 73.633763],
              [123.746349, 73.613239],
              [123.786006, 73.650513],
              [123.765116, 73.669943],
              [123.772099, 73.68154],
              [123.824341, 73.672921],
              [123.857881, 73.686742],
              [123.85215, 73.716227],
              [123.870539, 73.70972],
              [123.959863, 73.703203],
              [123.990176, 73.721998],
              [123.969781, 73.74555],
              [123.911409, 73.738721],
              [123.893038, 73.729808],
              [123.790999, 73.721281],
              [123.798716, 73.702016],
              [123.762328, 73.703696],
              [123.767744, 73.718171],
              [123.736456, 73.721224],
              [123.700363, 73.712347],
              [123.615173, 73.739946],
              [123.663178, 73.738443],
              [123.715489, 73.720348],
              [123.711322, 73.74412],
              [123.858075, 73.768467],
              [123.906749, 73.761749],
              [123.917088, 73.77555],
              [123.962282, 73.775873],
              [123.969156, 73.785673],
              [123.927385, 73.791409],
              [123.987792, 73.805885],
              [124.001229, 73.796279],
              [124.043806, 73.795095],
              [124.064406, 73.80608],
              [124.098398, 73.799816],
              [124.099134, 73.761523],
              [124.010489, 73.734859],
              [124.022468, 73.711562],
              [124.044462, 73.709771],
              [124.12489, 73.72829],
              [124.088241, 73.692851],
              [124.095752, 73.681237],
              [124.041132, 73.684065],
              [123.987679, 73.65704],
              [124.023202, 73.649303],
              [124.048664, 73.664765],
              [124.126841, 73.668373],
              [124.159762, 73.67579],
              [124.127475, 73.688119],
              [124.175736, 73.71049],
              [124.252681, 73.728879],
              [124.182703, 73.738577],
              [124.164107, 73.752248],
              [124.204661, 73.765592],
              [124.252634, 73.754755],
              [124.272616, 73.75853],
              [124.27486, 73.778051],
              [124.326392, 73.774064],
              [124.355384, 73.796601],
              [124.485706, 73.796454],
              [124.472928, 73.782736],
              [124.412819, 73.774663],
              [124.421576, 73.762812],
              [124.514032, 73.774769],
              [124.495145, 73.758234],
              [124.577196, 73.759443],
              [124.589702, 73.743311],
              [124.647397, 73.742963],
              [124.632742, 73.72909],
              [124.643023, 73.714192],
              [124.684459, 73.716318],
              [124.687002, 73.731223],
              [124.751865, 73.742003],
              [124.733233, 73.720121],
              [124.753338, 73.70388],
              [124.706105, 73.696015],
              [124.733157, 73.690031],
              [124.806394, 73.695973],
              [124.866664, 73.725063],
              [124.918452, 73.718639],
              [124.896707, 73.703115],
              [124.855797, 73.691567],
              [124.939838, 73.694472],
              [124.951532, 73.676263],
              [124.911864, 73.663469],
              [124.965406, 73.64888],
              [124.989659, 73.659581],
              [124.998028, 73.691226],
              [125.024543, 73.698508],
              [125.045931, 73.675486],
              [125.018857, 73.626597],
              [125.087653, 73.62737],
              [125.11221, 73.640877],
              [125.081454, 73.66565],
              [125.127062, 73.669049],
              [125.141795, 73.703871],
              [125.164434, 73.704538],
              [125.147028, 73.687073],
              [125.15625, 73.664731],
              [125.186487, 73.643764],
              [125.150301, 73.643109],
              [125.15625, 73.63285],
              [125.228355, 73.635301],
              [125.265784, 73.605766],
              [125.215066, 73.587459],
              [125.203585, 73.527874],
              [125.233019, 73.525332],
              [125.260175, 73.555505],
              [125.240365, 73.561294],
              [125.291613, 73.570775],
              [125.2757, 73.544519],
              [125.328882, 73.528307],
              [125.370428, 73.525957],
              [125.347204, 73.543725],
              [125.369412, 73.583262],
              [125.403512, 73.584614],
              [125.388548, 73.572075],
              [125.441362, 73.566064],
              [125.503849, 73.545873],
              [125.520231, 73.532071],
              [125.608709, 73.536202],
              [125.679941, 73.527233],
              [125.691071, 73.511802],
              [125.649938, 73.496568],
              [125.673626, 73.481339],
              [125.705041, 73.490916],
              [125.792822, 73.490613],
              [125.79655, 73.51139],
              [125.748849, 73.511169],
              [125.719494, 73.551133],
              [125.668383, 73.549307],
              [125.671324, 73.56189],
              [125.721799, 73.576495],
              [125.772973, 73.583168],
              [125.828397, 73.625192],
              [125.830114, 73.649774],
              [125.883454, 73.651077],
              [125.897338, 73.634911],
              [125.832078, 73.615271],
              [125.783442, 73.582816],
              [125.794867, 73.544108],
              [125.768769, 73.543709],
              [125.755476, 73.521248],
              [125.875885, 73.515909],
              [125.888643, 73.510215],
              [125.857396, 73.489287],
              [125.749005, 73.486869],
              [125.7292, 73.477607],
              [125.679354, 73.479639],
              [125.683438, 73.467935],
              [125.736867, 73.461099],
              [125.80776, 73.461423],
              [125.831007, 73.471623],
              [125.876735, 73.472182],
              [125.922407, 73.487948],
              [125.946392, 73.524618],
              [125.96684, 73.511102],
              [126.037912, 73.485773],
              [126.046724, 73.49715],
              [126.107508, 73.49953],
              [126.112563, 73.512131],
              [126.183753, 73.506448],
              [126.194382, 73.526537],
              [126.253447, 73.543042],
              [126.302826, 73.550202],
              [126.339978, 73.543514],
              [126.336494, 73.528384],
              [126.376766, 73.512781],
              [126.437039, 73.5102],
              [126.429742, 73.454371],
              [126.472167, 73.443637],
              [126.447909, 73.431964],
              [126.490358, 73.420589],
              [126.472099, 73.407841],
              [126.508225, 73.38718],
              [126.540809, 73.387439],
              [126.527263, 73.410034],
              [126.5625, 73.420836],
              [126.535312, 73.441065],
              [126.594605, 73.440221],
              [126.688028, 73.458656],
              [126.6668, 73.440014],
              [126.679141, 73.428392],
              [126.670439, 73.390459],
              [126.712662, 73.389143],
              [126.73848, 73.423679],
              [126.763467, 73.438007],
              [126.910164, 73.454724],
              [126.94083, 73.438542],
              [126.848686, 73.430074],
              [126.822355, 73.412043],
              [126.861782, 73.383053],
              [126.903536, 73.388326],
              [126.893855, 73.412513],
              [126.922785, 73.424816],
              [126.955499, 73.41338],
              [127.010752, 73.488293],
              [126.951535, 73.498586],
              [126.898586, 73.501259],
              [126.954196, 73.513821],
              [127.046862, 73.522114],
              [127.131406, 73.523138],
              [127.279575, 73.518523],
              [127.356102, 73.504568],
              [127.370983, 73.490117],
              [127.442257, 73.477369],
              [127.459718, 73.468042],
              [127.523794, 73.47438],
              [127.573592, 73.496189],
              [127.448769, 73.50936],
              [127.519026, 73.518811],
              [127.552082, 73.516485],
              [127.603181, 73.524497],
              [127.662484, 73.52616],
              [127.812002, 73.513003],
              [127.911118, 73.496931],
              [127.956821, 73.484643],
              [127.941611, 73.469236],
              [128.033291, 73.466868],
              [128.09198, 73.443369],
              [128.135304, 73.437164],
              [128.167336, 73.423242],
              [128.250227, 73.41078],
              [128.259823, 73.395741],
              [128.15247, 73.398144],
              [128.151357, 73.384598],
              [128.232801, 73.370605],
              [128.339524, 73.368159],
              [128.483852, 73.345061],
              [128.481016, 73.321039],
              [128.500564, 73.306524],
              [128.472223, 73.288697],
              [128.440411, 73.283888],
              [128.440917, 73.268305],
              [128.376809, 73.275148],
              [128.329921, 73.271553],
              [128.36409, 73.259141],
              [128.447719, 73.2498],
              [128.444632, 73.235612],
              [128.533578, 73.242148],
              [128.574399, 73.250482],
              [128.756096, 73.245921],
              [128.84124, 73.233485],
              [128.881244, 73.208986],
              [128.975832, 73.199566],
              [128.999684, 73.173253],
              [128.921299, 73.147434],
              [128.857844, 73.15251],
              [128.886188, 73.129279],
              [128.916145, 73.120351],
              [128.916665, 73.103234],
              [128.96107, 73.087663],
              [129.034852, 73.116277],
              [129.094696, 73.1275],
              [129.1861, 73.119007],
              [129.227268, 73.106958],
              [129.242899, 73.093655],
              [129.348601, 73.066677],
              [129.34935, 73.060288],
              [129.409445, 73.044337],
              [129.41362, 73.035642],
              [129.460131, 73.040653],
              [129.498746, 73.02881],
              [129.421386, 72.994875],
              [129.463086, 72.986993],
              [129.484483, 72.971741],
              [129.533874, 72.961527],
              [129.556656, 72.93796],
              [129.471272, 72.934343],
              [129.46415, 72.928228],
              [129.384339, 72.937847],
              [129.301579, 72.932876],
              [129.318692, 72.91701],
              [129.366636, 72.923572],
              [129.420522, 72.922446],
              [129.397495, 72.903841],
              [129.34826, 72.917728],
              [129.27788, 72.919624],
              [129.246036, 72.942659],
              [129.181634, 72.944004],
              [129.246161, 72.951601],
              [129.250745, 72.967691],
              [129.32521, 72.98777],
              [129.380959, 72.982691],
              [129.421162, 72.985551],
              [129.366559, 73.003412],
              [129.272241, 73.004709],
              [129.23782, 72.979203],
              [129.17671, 72.973772],
              [129.122665, 72.976877],
              [129.108668, 72.970115],
              [129.145116, 72.949526],
              [129.061545, 72.957616],
              [129.014499, 72.957114],
              [128.927341, 72.966299],
              [128.859466, 72.951956],
              [128.78057, 72.923272],
              [128.82156, 72.911049],
              [128.848455, 72.915925],
              [128.869191, 72.932546],
              [128.964857, 72.948552],
              [129.023437, 72.935056],
              [129.085839, 72.93704],
              [129.168453, 72.931241],
              [129.28062, 72.900714],
              [129.262614, 72.886942],
              [129.36237, 72.885256],
              [129.411491, 72.870855],
              [129.412933, 72.858333],
              [129.476571, 72.84082],
              [129.523497, 72.817965],
              [129.466586, 72.823214],
              [129.412696, 72.811067],
              [129.419457, 72.804666],
              [129.337168, 72.7923],
              [129.338775, 72.786037],
              [129.491848, 72.783297],
              [129.422911, 72.768378],
              [129.344373, 72.763289],
              [129.305358, 72.749428],
              [129.318132, 72.741488],
              [129.211275, 72.730002],
              [129.152834, 72.735293],
              [129.115321, 72.722032],
              [129.152656, 72.714598],
              [129.226573, 72.715551],
              [129.23423, 72.709666],
              [129.302116, 72.710597],
              [129.309722, 72.703304],
              [129.18613, 72.710865],
              [129.18429, 72.698858],
              [129.276097, 72.694794],
              [129.307758, 72.688999],
              [129.385831, 72.688226],
              [129.4455, 72.672263],
              [129.515091, 72.632749],
              [129.486229, 72.625156],
              [129.467938, 72.645129],
              [129.37312, 72.655036],
              [129.33616, 72.647692],
              [129.342852, 72.639632],
              [129.388253, 72.641721],
              [129.443648, 72.632153],
              [129.43168, 72.597176],
              [129.315681, 72.60314],
              [129.214854, 72.598803],
              [129.220734, 72.590876],
              [129.196209, 72.611468],
              [129.143237, 72.611641],
              [129.130511, 72.624169],
              [129.092831, 72.612693],
              [129.161733, 72.578285],
              [129.0969, 72.575005],
              [129.062806, 72.578724],
              [129.003105, 72.574773],
              [129.014184, 72.567491],
              [129.07129, 72.567479],
              [129.051643, 72.552411],
              [129.136878, 72.56208],
              [129.294398, 72.555139],
              [129.300247, 72.54836],
              [129.244869, 72.545752],
              [129.15892, 72.549416],
              [129.13836, 72.533208],
              [129.191995, 72.536996],
              [129.186786, 72.527036],
              [129.108556, 72.528371],
              [129.077858, 72.509606],
              [129.024339, 72.513581],
              [128.979283, 72.529038],
              [128.86571, 72.533466],
              [128.85432, 72.521449],
              [128.782174, 72.511802],
              [128.748359, 72.499371],
              [128.69402, 72.498912],
              [128.688227, 72.490344],
              [128.793493, 72.487926],
              [128.807831, 72.496116],
              [128.863802, 72.50421],
              [128.908826, 72.502319],
              [128.93171, 72.514593],
              [128.969107, 72.511791],
              [128.964036, 72.497865],
              [128.891051, 72.492473],
              [128.94794, 72.485471],
              [128.937829, 72.465028],
              [128.830569, 72.470331],
              [128.821861, 72.450777],
              [128.933636, 72.453018],
              [128.965807, 72.459414],
              [129.092883, 72.472008],
              [129.133764, 72.486966],
              [129.18049, 72.492805],
              [129.265356, 72.491689],
              [129.242315, 72.482392],
              [129.20418, 72.485628],
              [129.179378, 72.466106],
              [129.243803, 72.466933],
              [129.225918, 72.460055],
              [129.36795, 72.444769],
              [129.450773, 72.422114],
              [129.430806, 72.400185],
              [129.3878, 72.40334],
              [129.309542, 72.42034],
              [129.265025, 72.411206],
              [129.290167, 72.398141],
              [129.350181, 72.401365],
              [129.359258, 72.390618],
              [129.429932, 72.381024],
              [129.48311, 72.382179],
              [129.486332, 72.360709],
              [129.52853, 72.348336],
              [129.521501, 72.338134],
              [129.539321, 72.31458],
              [129.523885, 72.30389],
              [129.489424, 72.303212],
              [129.40797, 72.282693],
              [129.391443, 72.271277],
              [129.469253, 72.270044],
              [129.460195, 72.243237],
              [129.42779, 72.253913],
              [129.390074, 72.253097],
              [129.401765, 72.232378],
              [129.463894, 72.223937],
              [129.504133, 72.227288],
              [129.564054, 72.21716],
              [129.45747, 72.201084],
              [129.428665, 72.183774],
              [129.392704, 72.180272],
              [129.40744, 72.167467],
              [129.355535, 72.156708],
              [129.281791, 72.129872],
              [129.281659, 72.121185],
              [129.329128, 72.12456],
              [129.421084, 72.144186],
              [129.467672, 72.142292],
              [129.532976, 72.154518],
              [129.55368, 72.150245],
              [129.53546, 72.125255],
              [129.390576, 72.07986],
              [129.266798, 72.071126],
              [129.217661, 72.054323],
              [129.168361, 72.055649],
              [129.1761, 72.041334],
              [129.070144, 72.05392],
              [129.051049, 72.041151],
              [128.983481, 72.03773],
              [128.955262, 72.045461],
              [128.95471, 72.063729],
              [128.91268, 72.055063],
              [128.897698, 72.067193],
              [128.919228, 72.074612],
              [128.866814, 72.095024],
              [128.837769, 72.092192],
              [128.8653, 72.074583],
              [128.848784, 72.065372],
              [128.740317, 72.05376],
              [128.64164, 72.05667],
              [128.550833, 72.075902],
              [128.5988, 72.083161],
              [128.543878, 72.088789],
              [128.545253, 72.100418],
              [128.485098, 72.112534],
              [128.453047, 72.099927],
              [128.431338, 72.103442],
              [128.46398, 72.123976],
              [128.46185, 72.140067],
              [128.48825, 72.148826],
              [128.500247, 72.167253],
              [128.465114, 72.171121],
              [128.431273, 72.159656],
              [128.394141, 72.173598],
              [128.440716, 72.17808],
              [128.40097, 72.194316],
              [128.442454, 72.203381],
              [128.38951, 72.212678],
              [128.349459, 72.210633],
              [128.294496, 72.22718],
              [128.310545, 72.245379],
              [128.253542, 72.246832],
              [128.189532, 72.255012],
              [128.138688, 72.26719],
              [128.053594, 72.299508],
              [128.005098, 72.326356],
              [127.969855, 72.332309],
              [127.92763, 72.356361],
              [127.885213, 72.35421],
              [127.805524, 72.368184],
              [127.757099, 72.367376],
              [127.725655, 72.35887],
              [127.618943, 72.376939],
              [127.566684, 72.408514],
              [127.616127, 72.413976],
              [127.655238, 72.410922],
              [127.666059, 72.418918],
              [127.627199, 72.435264],
              [127.559658, 72.42252],
              [127.521456, 72.424284],
              [127.437859, 72.442302],
              [127.445357, 72.453606],
              [127.304263, 72.440313],
              [127.244763, 72.442737],
              [127.227682, 72.435557],
              [127.119939, 72.429711],
              [127.034254, 72.431694],
              [127.099446, 72.423382],
              [127.003507, 72.426531],
              [126.905372, 72.433702],
              [126.798555, 72.434978],
              [126.759545, 72.428662],
              [126.707381, 72.436249],
              [126.686141, 72.463359],
              [126.719385, 72.499024],
              [126.791385, 72.507759],
              [126.874504, 72.523744],
              [127.006248, 72.540184],
              [126.942349, 72.525604],
              [126.954086, 72.504636],
              [127.038237, 72.512254],
              [127.123999, 72.505027],
              [127.191104, 72.505262],
              [127.197195, 72.512347],
              [127.139563, 72.510668],
              [127.052009, 72.522486],
              [127.065896, 72.539747],
              [127.180466, 72.560248],
              [127.199712, 72.58087],
              [127.140063, 72.58007],
              [127.081986, 72.564569],
              [127.020094, 72.56141],
              [126.989678, 72.566603],
              [126.938918, 72.548193],
              [126.876159, 72.538589],
              [126.786385, 72.534675],
              [126.704519, 72.519051],
              [126.627657, 72.508438],
              [126.5909, 72.478083],
              [126.600454, 72.444448],
              [126.628501, 72.414614],
              [126.665194, 72.395617],
              [126.637667, 72.389667],
              [126.719367, 72.368003],
              [126.773612, 72.374553],
              [126.738593, 72.385465],
              [126.732151, 72.397288],
              [126.843821, 72.408982],
              [126.948583, 72.41089],
              [127.006213, 72.405817],
              [127.058055, 72.407006],
              [127.141584, 72.400136],
              [127.204271, 72.406975],
              [127.274603, 72.396096],
              [127.314315, 72.395926],
              [127.412722, 72.371563],
              [127.43413, 72.377612],
              [127.368453, 72.394973],
              [127.367215, 72.408765],
              [127.44687, 72.416176],
              [127.476058, 72.410907],
              [127.55203, 72.380296],
              [127.569477, 72.367644],
              [127.503464, 72.365495],
              [127.553055, 72.356791],
              [127.640437, 72.360221],
              [127.797872, 72.324439],
              [127.829979, 72.309043],
              [127.871875, 72.270189],
              [127.901267, 72.255533],
              [127.857916, 72.251965],
              [127.787037, 72.259591],
              [127.798354, 72.240367],
              [127.838964, 72.233711],
              [127.915629, 72.239385],
              [127.974141, 72.226028],
              [128.003962, 72.200627],
              [128.092012, 72.193899],
              [128.122604, 72.156475],
              [128.20718, 72.137414],
              [128.309182, 72.125791],
              [128.330255, 72.1067],
              [128.339246, 72.076068],
              [128.383596, 72.064842],
              [128.454372, 72.05496],
              [128.542088, 72.026823],
              [128.592296, 72.039199],
              [128.63693, 72.043375],
              [128.666838, 72.038318],
              [128.764528, 72.039738],
              [128.794457, 72.02904],
              [128.881181, 72.015431],
              [128.970583, 72.009773],
              [129.012007, 71.997108],
              [128.975752, 71.991459],
              [128.848334, 72.006716],
              [128.788116, 72.004505],
              [128.789009, 71.998629],
              [128.894433, 71.986015],
              [128.948194, 71.951211],
              [128.879945, 71.926907],
              [128.838224, 71.923953],
              [128.820574, 71.931504],
              [128.787674, 71.926177],
              [128.775067, 71.902377],
              [128.723889, 71.907381],
              [128.641656, 71.927125],
              [128.569272, 71.960706],
              [128.577223, 71.991896],
              [128.548724, 71.992088],
              [128.518999, 72.007603],
              [128.506397, 71.968478],
              [128.553892, 71.914167],
              [128.586444, 71.892776],
              [128.660458, 71.880015],
              [128.703814, 71.84658],
              [128.706273, 71.821961],
              [128.732715, 71.804368],
              [128.726592, 71.775486],
              [128.75001, 71.764403],
              [128.809722, 71.750162],
              [128.960595, 71.73786],
              [128.9826, 71.732369],
              [129.089378, 71.745253],
              [129.178441, 71.767421],
              [129.247357, 71.794941],
              [129.270929, 71.814328],
              [129.270734, 71.829149],
              [129.18485, 71.880097],
              [129.161258, 71.90567],
              [129.157413, 71.937357],
              [129.137787, 71.956279],
              [129.127271, 71.98567],
              [129.103037, 72.007786],
              [129.129016, 71.999211],
              [129.170347, 71.955743],
              [129.311012, 71.888112],
              [129.367671, 71.845342],
              [129.349466, 71.813947],
              [129.387007, 71.789345],
              [129.41637, 71.786984],
              [129.465744, 71.768218],
              [129.47764, 71.754391],
              [129.535595, 71.727408],
              [129.562386, 71.724521],
              [129.604131, 71.705741],
              [129.45831, 71.73093],
              [129.409498, 71.733851],
              [129.434579, 71.744016],
              [129.405926, 71.757238],
              [129.377593, 71.747714],
              [129.381021, 71.735698],
              [129.274969, 71.72668],
              [129.194981, 71.709402],
              [129.118459, 71.702948],
              [129.03794, 71.705154],
              [128.985893, 71.687142],
              [128.937937, 71.689688],
              [128.89696, 71.677534],
              [128.870731, 71.652221],
              [128.894363, 71.632816],
              [128.922761, 71.624142],
              [128.92889, 71.609324],
              [128.909529, 71.595774],
              [128.952033, 71.579563],
              [129.00698, 71.583019],
              [129.061182, 71.596807],
              [129.14121, 71.591271],
              [129.198831, 71.611023],
              [129.246389, 71.595636],
              [129.257, 71.572881],
              [129.297573, 71.558513],
              [129.320771, 71.521935],
              [129.299984, 71.506381],
              [129.307583, 71.478261],
              [129.364475, 71.468706],
              [129.398191, 71.42852],
              [129.413858, 71.374059],
              [129.442549, 71.335153],
              [129.443008, 71.320331],
              [129.466084, 71.301877],
              [129.534243, 71.274864],
              [129.663793, 71.248387],
              [129.709368, 71.234676],
              [129.7576, 71.209445],
              [129.776008, 71.153915],
              [129.821953, 71.118452],
              [129.829324, 71.087498],
              [129.949206, 71.089993],
              [130.009263, 71.077922],
              [130.05264, 71.079088],
              [130.104196, 71.071507],
              [130.16867, 71.070092],
              [130.163071, 71.061948],
              [130.216382, 71.026849],
              [130.233261, 70.995301],
              [130.274102, 70.984035],
              [130.271205, 70.969487],
              [130.240125, 70.961007],
              [130.274089, 70.935218],
              [130.348822, 70.918843],
              [130.357179, 70.903957],
              [130.445583, 70.895747],
              [130.482537, 70.899417],
              [130.540712, 70.91479],
              [130.55794, 70.905444],
              [130.55426, 70.888349],
              [130.56747, 70.862932],
              [130.59026, 70.850723],
              [130.643571, 70.845655],
              [130.711469, 70.847594],
              [130.764294, 70.855022],
              [130.825251, 70.87846],
              [130.833464, 70.899853],
              [130.816598, 70.950125],
              [130.849452, 70.966673],
              [130.862253, 70.944559],
              [130.834797, 70.942609],
              [130.833791, 70.928737],
              [130.87994, 70.912425],
              [130.868233, 70.891836],
              [130.887314, 70.875025],
              [130.8727, 70.860602],
              [130.931118, 70.840315],
              [130.950601, 70.812755],
              [130.955826, 70.782787],
              [130.934363, 70.773555],
              [130.965214, 70.744082],
              [131.02843, 70.725754],
              [131.0743, 70.705299],
              [131.267569, 70.71861],
              [131.325519, 70.724534],
              [131.404248, 70.761367],
              [131.517427, 70.804095],
              [131.51797, 70.814572],
              [131.607215, 70.854763],
              [131.621376, 70.867606],
              [131.612291, 70.881545],
              [131.703595, 70.933176],
              [131.742797, 70.962013],
              [131.773902, 71.002219],
              [131.807287, 71.057477],
              [131.821213, 71.066731],
              [131.788198, 71.086666],
              [131.781939, 71.109268],
              [131.79511, 71.121348],
              [131.920918, 71.138609],
              [131.954156, 71.1519],
              [131.851768, 71.157837],
              [131.856232, 71.166721],
              [131.968748, 71.219328],
              [132.027342, 71.228498],
              [132.065515, 71.218668],
              [132.113559, 71.216214],
              [132.118846, 71.224441],
              [132.063421, 71.230598],
              [132.04949, 71.243104],
              [132.004194, 71.238079],
              [131.988292, 71.263913],
              [132.040581, 71.318331],
              [132.157856, 71.50393],
              [132.185905, 71.558723],
              [132.30429, 71.684638],
              [132.362181, 71.728921],
              [132.438745, 71.775416],
              [132.512021, 71.837741],
              [132.559198, 71.872009],
              [132.612601, 71.90173],
              [132.686043, 71.931826],
              [132.733726, 71.943456],
              [132.786088, 71.949352],
              [132.905911, 71.945953],
              [133.003822, 71.963671],
              [133.057747, 71.96844],
              [133.135342, 71.966316],
              [133.185469, 71.951901],
              [133.180775, 71.942016],
              [133.099804, 71.964228],
              [133.037967, 71.966883],
              [132.90641, 71.944407],
              [132.856777, 71.943864],
              [132.86978, 71.926966],
              [132.903982, 71.928841],
              [132.906478, 71.913175],
              [132.875067, 71.918669],
              [132.883475, 71.901606],
              [132.830644, 71.921088],
              [132.797448, 71.924045],
              [132.780913, 71.915666],
              [132.795785, 71.854967],
              [132.818476, 71.833274],
              [132.77383, 71.823402],
              [132.753319, 71.800766],
              [132.771359, 71.77021],
              [132.808683, 71.752581],
              [132.84862, 71.744106],
              [132.947271, 71.739417],
              [132.985095, 71.725195],
              [133.003767, 71.699717],
              [133.065863, 71.672038],
              [133.123321, 71.63317],
              [133.166667, 71.593821],
              [133.274574, 71.547385],
              [133.340557, 71.524635],
              [133.517393, 71.473126],
              [133.665009, 71.440728],
              [133.873638, 71.406899],
              [134.070729, 71.380322],
              [134.216799, 71.369836],
              [134.39284, 71.362737],
              [134.47898, 71.366897],
              [134.542507, 71.376277],
              [134.664308, 71.399284],
              [134.727081, 71.3992],
              [134.78329, 71.374618],
              [134.840905, 71.367781],
              [134.862574, 71.372852],
              [134.84715, 71.384671],
              [134.770643, 71.412454],
              [134.792036, 71.424711],
              [134.772252, 71.441004],
              [134.821056, 71.453388],
              [134.83515, 71.468481],
              [134.94593, 71.495926],
              [134.987458, 71.510337],
              [135.085408, 71.5283],
              [135.149413, 71.533362],
              [135.152589, 71.538848],
              [135.253937, 71.5599],
              [135.373647, 71.571687],
              [135.498419, 71.600083],
              [135.541877, 71.612745],
              [135.6492, 71.63553],
              [135.78982, 71.647622],
              [135.926775, 71.648531],
              [135.998892, 71.645094],
              [136.01473, 71.636962],
              [136.139677, 71.628394],
              [136.213658, 71.628213],
              [136.31451, 71.614572],
              [136.378639, 71.584496],
              [136.471928, 71.587503],
              [136.471106, 71.601189],
              [136.401686, 71.596454],
              [136.417022, 71.603484],
              [136.51324, 71.611662],
              [136.589953, 71.606278],
              [136.59863, 71.600331],
              [136.683228, 71.581263],
              [136.760097, 71.570696],
              [136.61536, 71.540015],
              [136.603955, 71.528098],
              [136.621891, 71.513273],
              [136.602327, 71.501808],
              [136.627473, 71.490607],
              [136.660216, 71.508006],
              [136.669371, 71.524605],
              [136.76237, 71.538608],
              [136.816541, 71.5311],
              [136.912026, 71.534073],
              [136.95483, 71.518858],
              [136.93422, 71.499983],
              [136.965417, 71.468644],
              [136.990284, 71.461363],
              [137.056728, 71.455204],
              [137.092094, 71.431616],
              [137.131871, 71.417585],
              [137.180156, 71.412934],
              [137.30107, 71.414312],
              [137.391144, 71.38969],
              [137.440536, 71.402143],
              [137.466811, 71.398797],
              [137.53758, 71.406503],
              [137.569548, 71.398129],
              [137.61532, 71.397674],
              [137.617961, 71.382624],
              [137.58851, 71.381583],
              [137.587494, 71.394104],
              [137.493748, 71.391748],
              [137.50777, 71.37627],
              [137.471406, 71.351795],
              [137.508303, 71.351201],
              [137.525479, 71.366872],
              [137.554651, 71.355892],
              [137.632612, 71.362927],
              [137.638599, 71.346914],
              [137.5794, 71.333567],
              [137.617342, 71.317619],
              [137.567445, 71.324394],
              [137.55186, 71.277408],
              [137.604621, 71.267237],
              [137.647434, 71.249838],
              [137.699588, 71.261251],
              [137.726046, 71.283718],
              [137.79471, 71.287715],
              [137.760616, 71.267557],
              [137.831569, 71.242543],
              [137.858933, 71.247863],
              [137.86864, 71.264143],
              [137.905134, 71.259887],
              [137.92523, 71.268658],
              [137.970491, 71.269393],
              [137.995932, 71.259313],
              [138.026666, 71.266452],
              [138.059839, 71.262881],
              [138.03605, 71.251891],
              [137.96891, 71.254018],
              [137.967634, 71.2673],
              [137.906445, 71.259141],
              [137.954578, 71.228717],
              [138.026341, 71.216565],
              [138.094607, 71.216611],
              [138.15473, 71.239785],
              [138.223863, 71.245424],
              [138.237, 71.233172],
              [138.290018, 71.238461],
              [138.30737, 71.256613],
              [138.352916, 71.272605],
              [138.31139, 71.285059],
              [138.285201, 71.267292],
              [138.199717, 71.280618],
              [138.167364, 71.273099],
              [138.039646, 71.290958],
              [138.03394, 71.299565],
              [138.123925, 71.31375],
              [138.201571, 71.317476],
              [138.200095, 71.327685],
              [138.123851, 71.330375],
              [138.122333, 71.340583],
              [138.087809, 71.341716],
              [138.094891, 71.326477],
              [137.993211, 71.333454],
              [138.040978, 71.347511],
              [138.010793, 71.354069],
              [137.978551, 71.350615],
              [137.916167, 71.358304],
              [137.939326, 71.366099],
              [137.909414, 71.373031],
              [137.883368, 71.360835],
              [137.851065, 71.377441],
              [137.863738, 71.38814],
              [137.940204, 71.385812],
              [138.003229, 71.415727],
              [138.00567, 71.443904],
              [138.043935, 71.445783],
              [138.091612, 71.473122],
              [138.130905, 71.546617],
              [138.110261, 71.54963],
              [138.10559, 71.575138],
              [138.12888, 71.597742],
              [138.188531, 71.594026],
              [138.193998, 71.584387],
              [138.29235, 71.586831],
              [138.41485, 71.602338],
              [138.471539, 71.620613],
              [138.58577, 71.620939],
              [138.623266, 71.612715],
              [138.762856, 71.631145],
              [138.759599, 71.645042],
              [138.805378, 71.659634],
              [138.78486, 71.665023],
              [138.74019, 71.651334],
              [138.649632, 71.641934],
              [138.61132, 71.65629],
              [138.542513, 71.642123],
              [138.42746, 71.664905],
              [138.422102, 71.680433],
              [138.476819, 71.678603],
              [138.556817, 71.713904],
              [138.659146, 71.703032],
              [138.696987, 71.706431],
              [138.722444, 71.724888],
              [138.757983, 71.706897],
              [138.794773, 71.705533],
              [138.84898, 71.722766],
              [138.8903, 71.724758],
              [138.93036, 71.745901],
              [138.9779, 71.758933],
              [138.92376, 71.776253],
              [138.868581, 71.78741],
              [138.817797, 71.781218],
              [138.69304, 71.792293],
              [138.635259, 71.803099],
              [138.670197, 71.811706],
              [138.70441, 71.79294],
              [138.752772, 71.791976],
              [138.738542, 71.821979],
              [138.751633, 71.832359],
              [138.692249, 71.838818],
              [138.613979, 71.822525],
              [138.60936, 71.845992],
              [138.54977, 71.850104],
              [138.483562, 71.836084],
              [138.43902, 71.832047],
              [138.355567, 71.842686],
              [138.40311, 71.872483],
              [138.466837, 71.893274],
              [138.581604, 71.876842],
              [138.605573, 71.862821],
              [138.665785, 71.860488],
              [138.71752, 71.866986],
              [138.796166, 71.868409],
              [138.868045, 71.857199],
              [138.874417, 71.851645],
              [138.966435, 71.842664],
              [138.997433, 71.813334],
              [138.932708, 71.821343],
              [138.929158, 71.831275],
              [138.799489, 71.840069],
              [138.764593, 71.823305],
              [138.850946, 71.813036],
              [138.937282, 71.794549],
              [139.00359, 71.770705],
              [139.061273, 71.765671],
              [138.948297, 71.738424],
              [138.891177, 71.694983],
              [138.782356, 71.672536],
              [138.808675, 71.66274],
              [138.845202, 71.662898],
              [138.973008, 71.698038],
              [139.00542, 71.683806],
              [139.043372, 71.646874],
              [139.037685, 71.631221],
              [138.986734, 71.645391],
              [138.943885, 71.6365],
              [138.908978, 71.651589],
              [138.878716, 71.646399],
              [138.837818, 71.656424],
              [138.801107, 71.64041],
              [138.849049, 71.636097],
              [138.874517, 71.625899],
              [138.937377, 71.62173],
              [138.956292, 71.604038],
              [138.992493, 71.606211],
              [139.017077, 71.587805],
              [139.097171, 71.587598],
              [139.105458, 71.577455],
              [139.151917, 71.577654],
              [139.134512, 71.56138],
              [139.154988, 71.542225],
              [139.17339, 71.479956],
              [139.219365, 71.430802],
              [139.180412, 71.435544],
              [139.17834, 71.42351],
              [139.237176, 71.402721],
              [139.307471, 71.404896],
              [139.40014, 71.417588],
              [139.445466, 71.432023],
              [139.459061, 71.452582],
              [139.45495, 71.473763],
              [139.476, 71.492081],
              [139.504071, 71.493326],
              [139.64866, 71.485463],
              [139.736827, 71.477891],
              [139.791154, 71.457798],
              [139.819127, 71.460019],
              [139.849286, 71.466082],
              [139.876803, 71.462669],
              [139.918099, 71.485398],
              [139.905121, 71.501424],
              [139.929303, 71.500795],
              [140.02098, 71.470878],
              [140.04573, 71.47407],
              [139.990697, 71.504197],
              [139.953086, 71.536961],
              [139.971645, 71.544217],
              [139.942354, 71.570635],
              [139.87482, 71.592483],
              [139.84688, 71.626561],
              [139.852495, 71.634262],
              [139.811192, 71.653177],
              [139.809881, 71.674125],
              [139.7889, 71.684728],
              [139.782722, 71.717656],
              [139.802963, 71.77094],
              [139.781467, 71.794062],
              [139.788958, 71.836532],
              [139.856248, 71.848186],
              [139.847443, 71.858101],
              [139.771378, 71.867344],
              [139.750884, 71.904014],
              [139.721807, 71.920436],
              [139.533554, 71.935118],
              [139.407419, 71.94942],
              [139.37388, 71.955916],
              [139.420635, 71.958857],
              [139.500149, 71.954631],
              [139.553827, 71.942897],
              [139.60435, 71.939433],
              [139.665413, 71.949574],
              [139.702505, 71.94957],
              [139.729093, 71.958563],
              [139.709429, 71.968656],
              [139.755051, 71.977386],
              [139.731792, 72.015953],
              [139.740096, 72.039902],
              [139.763778, 72.057432],
              [139.806631, 72.074309],
              [139.872917, 72.089524],
              [139.9023, 72.112179],
              [139.907175, 72.135206],
              [139.951009, 72.162277],
              [139.98566, 72.168847],
              [140.067152, 72.157113],
              [140.0588, 72.141103],
              [140.120044, 72.131549],
              [140.215957, 72.146393],
              [140.307517, 72.170246],
              [140.277036, 72.18189],
              [140.255989, 72.207108],
              [140.216328, 72.216153],
              [140.12611, 72.214495],
              [140.083909, 72.222481],
              [140.080442, 72.241629],
              [140.059265, 72.262738],
              [139.94809, 72.242756],
              [139.921408, 72.226513],
              [139.812197, 72.220518],
              [139.736402, 72.228096],
              [139.614559, 72.223827],
              [139.563858, 72.208896],
              [139.467425, 72.172387],
              [139.419417, 72.148493],
              [139.34097, 72.159091],
              [139.29531, 72.175058],
              [139.192284, 72.178994],
              [139.167228, 72.204323],
              [139.152616, 72.249941],
              [139.215678, 72.273526],
              [139.268704, 72.307226],
              [139.252782, 72.315521],
              [139.310679, 72.344139],
              [139.3171, 72.356717],
              [139.434157, 72.357677],
              [139.489527, 72.370885],
              [139.452527, 72.379545],
              [139.44984, 72.39128],
              [139.389023, 72.391563],
              [139.416954, 72.419901],
              [139.497534, 72.447617],
              [139.534073, 72.467057],
              [139.601938, 72.488501],
              [139.685899, 72.489454],
              [139.779604, 72.497077],
              [139.816098, 72.510329],
              [139.912954, 72.510625],
              [140.020196, 72.491759],
              [140.105238, 72.488581],
              [140.163303, 72.480912],
              [140.30545, 72.479414],
              [140.317538, 72.485077],
              [140.276233, 72.499495],
              [140.259515, 72.513743],
              [140.279429, 72.525825],
              [140.28552, 72.500806],
              [140.325297, 72.481782],
              [140.414746, 72.470182],
              [140.517374, 72.468413],
              [140.746401, 72.495554],
              [140.859424, 72.521069],
              [140.89689, 72.510617],
              [140.930558, 72.510817],
              [140.91849, 72.524807],
              [140.959256, 72.546537],
              [140.945191, 72.552921],
              [140.986917, 72.558421],
              [140.982644, 72.572601],
              [141.040637, 72.581029],
              [141.097741, 72.572833],
              [141.176265, 72.581722],
              [141.27858, 72.57854],
              [141.28472, 72.589645],
              [141.178982, 72.59756],
              [141.206393, 72.605454],
              [141.168934, 72.61852],
              [141.179845, 72.641249],
              [141.144946, 72.664783],
              [141.072615, 72.68475],
              [141.025432, 72.685784],
              [141.024161, 72.698814],
              [140.96705, 72.7075],
              [140.936887, 72.735341],
              [140.89209, 72.74452],
              [140.84818, 72.781785],
              [140.80866, 72.794305],
              [140.767489, 72.794236],
              [140.769196, 72.820789],
              [140.73158, 72.828683],
              [140.732796, 72.84912],
              [140.716084, 72.871867],
              [140.763781, 72.878287],
              [140.94334, 72.871888],
              [141.008353, 72.85773],
              [141.17378, 72.854335],
              [141.311335, 72.844973],
              [141.376053, 72.835154],
              [141.438837, 72.819954],
              [141.470136, 72.794079],
              [141.586588, 72.765415],
              [141.756882, 72.736871],
              [141.982036, 72.715844],
              [142.2529, 72.705145],
              [142.359353, 72.7042],
              [142.79998, 72.693043],
              [143.050031, 72.688375],
              [143.501904, 72.683358],
              [143.529531, 72.676528],
              [143.602111, 72.676249],
              [143.748664, 72.659415],
              [143.87183, 72.654833],
              [143.928489, 72.658601],
              [144.038531, 72.655251],
              [144.273598, 72.642194],
              [144.444513, 72.629197],
              [144.644929, 72.607515],
              [144.661786, 72.602335],
              [145.029387, 72.570071],
              [145.141106, 72.563784],
              [145.323473, 72.549679],
              [145.503531, 72.529051],
              [145.621298, 72.519441],
              [145.664808, 72.509718],
              [145.77684, 72.501683],
              [145.989199, 72.471873],
              [146.15462, 72.446504],
              [146.294655, 72.422821],
              [146.490069, 72.3984],
              [146.554505, 72.381855],
              [146.570223, 72.386159],
              [146.685358, 72.366979],
              [146.813309, 72.356843],
              [146.856108, 72.347881],
              [146.863063, 72.335358],
              [146.778133, 72.334014],
              [146.641194, 72.341951],
              [146.550592, 72.344134],
              [146.33818, 72.337295],
              [146.099357, 72.343861],
              [145.935065, 72.339062],
              [145.723626, 72.343973],
              [145.679263, 72.338879],
              [145.535482, 72.354526],
              [145.456197, 72.354919],
              [145.387819, 72.337495],
              [145.325126, 72.398547],
              [145.26696, 72.404929],
              [145.268935, 72.41513],
              [145.22579, 72.428088],
              [145.117472, 72.395286],
              [145.045171, 72.423912],
              [144.955039, 72.430207],
              [144.781509, 72.400696],
              [144.753699, 72.382796],
              [144.679173, 72.348619],
              [144.625363, 72.315574],
              [144.51448, 72.235947],
              [144.49418, 72.228376],
              [144.437634, 72.230651],
              [144.43833, 72.247783],
              [144.388753, 72.258621],
              [144.371679, 72.272987],
              [144.318648, 72.281562],
              [144.278334, 72.272258],
              [144.240564, 72.229646],
              [144.310291, 72.179617],
              [144.395286, 72.160255],
              [144.43605, 72.155066],
              [144.49583, 72.157848],
              [144.523136, 72.152241],
              [144.617726, 72.166514],
              [144.68392, 72.180126],
              [144.740935, 72.201571],
              [144.765588, 72.219784],
              [144.863251, 72.222113],
              [144.953664, 72.229532],
              [145.208463, 72.262142],
              [145.279626, 72.257535],
              [145.410898, 72.262066],
              [145.559985, 72.272902],
              [145.704122, 72.275006],
              [145.885984, 72.281982],
              [146.118091, 72.296339],
              [146.300847, 72.301892],
              [146.324115, 72.300752],
              [146.498354, 72.3111],
              [146.563805, 72.312727],
              [146.833294, 72.3084],
              [146.880012, 72.314582],
              [146.952666, 72.309579],
              [146.949857, 72.295805],
              [146.88152, 72.25911],
              [146.758227, 72.209652],
              [146.705255, 72.179645],
              [146.649335, 72.139453],
              [146.5601, 72.093022],
              [146.549815, 72.081004],
              [146.494281, 72.065137],
              [146.50965, 72.060856],
              [146.413277, 72.032913],
              [146.40312, 72.022297],
              [146.324332, 72.00524],
              [146.254946, 71.975465],
              [146.161111, 71.956578],
              [145.96463, 71.869443],
              [145.966764, 71.891429],
              [145.99625, 71.950899],
              [145.986319, 71.960125],
              [146.002933, 72.045046],
              [146.02651, 72.038543],
              [146.005787, 72.018701],
              [146.055423, 72.01511],
              [146.107472, 72.023363],
              [146.146632, 72.043351],
              [146.19436, 72.057979],
              [146.198434, 72.072483],
              [146.278671, 72.078018],
              [146.315986, 72.11367],
              [146.323192, 72.095269],
              [146.341043, 72.095038],
              [146.347033, 72.124361],
              [146.329259, 72.136513],
              [146.290072, 72.13386],
              [146.231164, 72.152878],
              [146.259302, 72.162123],
              [146.221463, 72.17762],
              [146.18392, 72.173618],
              [146.14624, 72.154104],
              [146.059712, 72.160628],
              [146.037785, 72.154874],
              [146.018463, 72.128458],
              [146.094508, 72.12726],
              [146.096792, 72.114384],
              [146.048874, 72.106914],
              [145.997335, 72.105932],
              [146.004235, 72.069873],
              [145.95919, 72.077175],
              [145.956388, 72.093136],
              [146.015785, 72.152363],
              [146.056947, 72.178986],
              [146.071472, 72.210395],
              [146.01519, 72.191593],
              [145.966377, 72.165924],
              [145.927603, 72.152296],
              [145.87574, 72.145778],
              [145.88365, 72.178767],
              [145.85139, 72.202374],
              [145.89014, 72.193725],
              [145.91446, 72.203239],
              [145.857778, 72.223357],
              [145.762576, 72.242727],
              [145.730877, 72.254893],
              [145.644587, 72.240937],
              [145.646993, 72.218975],
              [145.693069, 72.222108],
              [145.66228, 72.216436],
              [145.712758, 72.204086],
              [145.742415, 72.208624],
              [145.766766, 72.224945],
              [145.810885, 72.215671],
              [145.779041, 72.18032],
              [145.798036, 72.166531],
              [145.783273, 72.159589],
              [145.719851, 72.159975],
              [145.668425, 72.130208],
              [145.660997, 72.10246],
              [145.629171, 72.093481],
              [145.648788, 72.073801],
              [145.642658, 72.052683],
              [145.659044, 72.03359],
              [145.706874, 72.027443],
              [145.778736, 72.035813],
              [145.802299, 72.030621],
              [145.821682, 72.009386],
              [145.818702, 71.985117],
              [145.842916, 71.944539],
              [145.830588, 71.932035],
              [145.79125, 71.923531],
              [145.749896, 71.900464],
              [145.705475, 71.917179],
              [145.600237, 71.915224],
              [145.496991, 71.88371],
              [145.427885, 71.883895],
              [145.33785, 71.863015],
              [145.333618, 71.880269],
              [145.292443, 71.899882],
              [145.24219, 71.907136],
              [145.194734, 71.92699],
              [145.16286, 71.928318],
              [145.14048, 71.918556],
              [145.112931, 71.937578],
              [145.069278, 71.945539],
              [145.028916, 71.938317],
              [145.027327, 71.906091],
              [145.092667, 71.88091],
              [145.109174, 71.868013],
              [145.153758, 71.854001],
              [145.218369, 71.849016],
              [145.228238, 71.832667],
              [145.19178, 71.80171],
              [145.115771, 71.796948],
              [145.042058, 71.758824],
              [144.989222, 71.775672],
              [144.92764, 71.770667],
              [144.908283, 71.762102],
              [144.913851, 71.745605],
              [144.9957, 71.729071],
              [144.94432, 71.725269],
              [144.949126, 71.697522],
              [144.987466, 71.697752],
              [145.007397, 71.687992],
              [145.070876, 71.687806],
              [145.0392, 71.663386],
              [145.075688, 71.653395],
              [145.1082, 71.666526],
              [145.108726, 71.685846],
              [145.146464, 71.668518],
              [145.189056, 71.658756],
              [145.259455, 71.671671],
              [145.33136, 71.680046],
              [145.335193, 71.654092],
              [145.379388, 71.651805],
              [145.381094, 71.659193],
              [145.391301, 71.654093],
              [145.473234, 71.660134],
              [145.468142, 71.666145],
              [145.522163, 71.677684],
              [145.550219, 71.699562],
              [145.680716, 71.722808],
              [145.714915, 71.706085],
              [145.796755, 71.707129],
              [145.955057, 71.750771],
              [145.98558, 71.751775],
              [146.060169, 71.773662],
              [146.151159, 71.806878],
              [146.230481, 71.844221],
              [146.253567, 71.862053],
              [146.316038, 71.884957],
              [146.346296, 71.907199],
              [146.345025, 71.920055],
              [146.420667, 71.940261],
              [146.447004, 71.958243],
              [146.49053, 71.973942],
              [146.55337, 72.012421],
              [146.640388, 72.050188],
              [146.68163, 72.081568],
              [146.71907, 72.094839],
              [146.774254, 72.13529],
              [146.793218, 72.157051],
              [146.829419, 72.177529],
              [146.899046, 72.206493],
              [147.0522, 72.258549],
              [147.098813, 72.292275],
              [147.141165, 72.305334],
              [147.190614, 72.304299],
              [147.287858, 72.31457],
              [147.362123, 72.314368],
              [147.488377, 72.32489],
              [147.554818, 72.319224],
              [147.614279, 72.326999],
              [147.786902, 72.32267],
              [147.937327, 72.313444],
              [148.009455, 72.314103],
              [148.111698, 72.322519],
              [148.31274, 72.317339],
              [148.385355, 72.319],
              [148.583341, 72.294354],
              [148.773337, 72.261956],
              [148.953473, 72.243142],
              [148.980304, 72.234067],
              [149.030451, 72.23588],
              [149.199477, 72.213928],
              [149.316735, 72.195573],
              [149.46255, 72.168426],
              [149.55853, 72.145155],
              [149.795966, 72.070593],
              [149.889388, 72.035111],
              [149.95695, 72.002061],
              [150.029203, 71.96149],
              [150.099626, 71.901423],
              [150.113569, 71.875031],
              [150.105801, 71.8455],
              [150.049365, 71.8183],
              [149.982397, 71.811893],
              [149.82654, 71.779331],
              [149.70138, 71.746909],
              [149.6712, 71.774534],
              [149.635713, 71.790165],
              [149.588735, 71.792026],
              [149.574776, 71.835022],
              [149.548342, 71.84274],
              [149.527378, 71.871792],
              [149.46957, 71.890706],
              [149.418195, 71.884003],
              [149.400231, 71.861917],
              [149.34002, 71.83788],
              [149.298275, 71.83734],
              [149.212194, 71.796252],
              [149.18936, 71.773532],
              [149.087661, 71.757155],
              [149.050588, 71.735149],
              [148.99579, 71.686161],
              [148.953639, 71.702731],
              [148.880782, 71.700989],
              [148.871426, 71.686442],
              [148.935011, 71.683807],
              [148.973785, 71.668026],
              [149.034104, 71.669783],
              [149.072685, 71.664178],
              [149.134478, 71.696154],
              [149.235255, 71.67269],
              [149.331633, 71.687485],
              [149.36956, 71.667989],
              [149.483346, 71.646312],
              [149.550834, 71.641399],
              [149.613105, 71.642768],
              [149.744066, 71.655531],
              [149.753791, 71.648532],
              [149.900608, 71.661966],
              [149.958136, 71.67312],
              [150.014158, 71.637422],
              [150.09462, 71.631243],
              [150.144337, 71.635061],
              [150.179109, 71.608466],
              [150.186531, 71.588369],
              [150.27174, 71.606486],
              [150.27904, 71.592268],
              [150.2476, 71.577366],
              [150.17106, 71.580851],
              [150.150961, 71.56917],
              [150.201051, 71.569401],
              [150.262911, 71.560835],
              [150.28605, 71.568583],
              [150.361893, 71.563765],
              [150.407592, 71.566437],
              [150.391657, 71.552922],
              [150.347241, 71.547066],
              [150.29878, 71.55572],
              [150.265674, 71.552818],
              [150.298055, 71.540876],
              [150.295874, 71.522047],
              [150.25979, 71.53189],
              [150.13764, 71.517815],
              [150.097479, 71.500837],
              [150.11769, 71.495006],
              [150.19927, 71.515408],
              [150.239368, 71.511262],
              [150.3706, 71.512226],
              [150.608711, 71.50923],
              [150.617126, 71.499875],
              [150.725996, 71.491748],
              [150.746917, 71.481514],
              [150.703824, 71.465145],
              [150.72025, 71.452048],
              [150.6713, 71.425117],
              [150.677859, 71.397327],
              [150.637566, 71.379696],
              [150.66299, 71.367994],
              [150.699217, 71.379434],
              [150.723392, 71.411943],
              [150.763267, 71.427503],
              [150.833132, 71.441098],
              [150.777728, 71.441224],
              [150.787442, 71.468439],
              [150.8208, 71.457831],
              [150.834621, 71.442138],
              [150.901615, 71.4388],
              [150.95019, 71.427315],
              [150.978046, 71.429404],
              [150.942205, 71.404476],
              [150.912902, 71.393928],
              [150.829396, 71.37458],
              [150.760054, 71.372365],
              [150.712433, 71.355686],
              [150.686532, 71.338368],
              [150.673867, 71.317501],
              [150.718774, 71.301941],
              [150.730568, 71.327141],
              [150.757736, 71.350989],
              [150.81368, 71.350877],
              [150.998738, 71.37696],
              [151.128519, 71.391582],
              [151.204539, 71.391934],
              [151.33679, 71.380746],
              [151.384941, 71.369405],
              [151.449242, 71.345277],
              [151.372452, 71.346804],
              [151.310111, 71.356481],
              [151.298742, 71.349478],
              [151.376619, 71.335209],
              [151.479346, 71.328014],
              [151.596905, 71.302087],
              [151.668979, 71.273473],
              [151.695339, 71.271869],
              [151.733496, 71.247849],
              [151.72928, 71.239387],
              [151.774836, 71.209531],
              [151.76229, 71.194881],
              [151.869673, 71.139588],
              [151.846722, 71.13357],
              [151.860127, 71.120362],
              [151.88675, 71.124101],
              [151.923031, 71.095945],
              [151.958859, 71.089248],
              [151.989374, 71.062836],
              [151.974972, 71.05228],
              [152.030655, 71.039544],
              [152.055787, 71.023572],
              [152.07138, 70.992727],
              [152.095889, 70.988248],
              [152.025499, 70.976903],
              [152.01382, 70.967641],
              [151.92077, 70.964951],
              [151.84196, 70.973549],
              [151.693611, 70.977804],
              [151.682103, 70.97091],
              [151.765373, 70.945453],
              [151.811113, 70.939362],
              [151.991984, 70.935066],
              [152.070821, 70.921167],
              [152.11454, 70.904513],
              [152.145008, 70.885745],
              [152.266177, 70.856876],
              [152.395345, 70.8346],
              [152.512289, 70.823701],
              [152.566245, 70.80927],
              [152.587108, 70.814684],
              [152.799003, 70.818567],
              [152.916177, 70.810357],
              [153.00038, 70.818273],
              [153.119473, 70.822658],
              [153.360744, 70.846584],
              [153.405109, 70.84846],
              [153.51818, 70.860558],
              [153.595925, 70.864763],
              [153.65713, 70.863407],
              [153.686947, 70.84826],
              [153.75886, 70.861208],
              [153.802556, 70.874279],
              [153.855548, 70.879028],
              [153.908849, 70.889648],
              [153.953303, 70.88927],
              [154.08788, 70.908713],
              [154.341452, 70.941415],
              [154.442896, 70.952759],
              [154.77342, 70.981119],
              [154.82152, 70.987137],
              [155.11073, 71.010703],
              [155.206138, 71.014893],
              [155.502603, 71.035782],
              [155.555765, 71.036984],
              [155.687386, 71.05518],
              [155.860908, 71.069909],
              [155.949047, 71.07391],
              [156.11275, 71.076707],
              [156.406625, 71.066723],
              [156.525062, 71.069299],
              [156.57394, 71.060667],
              [156.566534, 71.038927],
              [156.593798, 71.038158],
              [156.614845, 71.064066],
              [156.655025, 71.074246],
              [156.728405, 71.075598],
              [157.01823, 71.07582],
              [157.145742, 71.070268],
              [157.420723, 71.051638],
              [157.485842, 71.041695],
              [157.58788, 71.039262],
              [157.81889, 71.024474],
              [158.053534, 70.999025],
              [158.121128, 70.993248],
              [158.435622, 70.958433],
              [158.563211, 70.934123],
              [158.625608, 70.928256],
              [158.707208, 70.909374],
              [158.825024, 70.890023],
              [158.97, 70.861862],
              [159.05826, 70.842215],
              [159.194624, 70.807867],
              [159.344221, 70.767307],
              [159.575228, 70.694068],
              [159.63626, 70.666051],
              [159.723958, 70.616126],
              [159.792003, 70.58349],
              [159.865328, 70.533751],
              [159.939132, 70.471476],
              [159.996289, 70.409292],
              [160.041289, 70.35412],
              [160.095588, 70.252874],
              [160.06245, 70.218934],
              [159.984071, 70.173337],
              [159.939428, 70.131043],
              [159.926375, 70.106638],
              [159.879337, 70.115495],
              [159.734987, 70.117066],
              [159.796273, 70.093647],
              [159.880871, 70.0816],
              [159.93179, 70.084703],
              [159.949729, 70.077287],
              [159.87987, 70.032111],
              [159.86056, 70.003352],
              [159.859832, 69.976237],
              [159.74964, 69.918016],
              [159.719658, 69.895359],
              [159.708406, 69.851092],
              [159.761494, 69.824923],
              [159.756689, 69.819673],
              [159.818164, 69.790835],
              [159.802197, 69.779652],
              [159.867603, 69.772035],
              [159.915466, 69.76002],
              [159.98142, 69.75033],
              [160.028089, 69.730505],
              [160.058477, 69.738197],
              [160.158519, 69.721688],
              [160.179776, 69.711028],
              [160.256156, 69.705719],
              [160.368694, 69.676927],
              [160.366272, 69.662261],
              [160.33765, 69.654734],
              [160.325183, 69.6405],
              [160.418891, 69.555239],
              [160.44539, 69.560429],
              [160.418856, 69.572793],
              [160.41282, 69.596349],
              [160.37433, 69.637758],
              [160.434303, 69.668215],
              [160.517621, 69.674741],
              [160.587098, 69.670878],
              [160.786077, 69.642237],
              [160.852309, 69.640973],
              [160.901038, 69.630843],
              [160.93873, 69.63001],
              [160.969312, 69.613567],
              [161.028974, 69.608613],
              [161.040323, 69.617966],
              [160.999553, 69.633495],
              [161.036504, 69.642068],
              [161.101393, 69.643948],
              [161.140637, 69.633052],
              [161.12835, 69.622875],
              [161.083852, 69.635191],
              [161.056192, 69.621926],
              [161.106688, 69.604912],
              [161.097982, 69.598728],
              [161.007736, 69.59445],
              [161.019871, 69.583587],
              [161.095105, 69.577848],
              [161.110226, 69.569278],
              [161.095347, 69.537839],
              [161.02892, 69.511431],
              [161.047419, 69.497192],
              [161.011799, 69.47904],
              [161.003334, 69.486734],
              [160.969046, 69.478114],
              [160.951191, 69.44332],
              [161.027826, 69.427522],
              [160.995106, 69.408479],
              [160.99561, 69.381433],
              [161.033175, 69.356423],
              [161.038705, 69.335729],
              [161.064638, 69.330049],
              [161.09442, 69.30262],
              [161.072849, 69.277717],
              [161.087736, 69.263735],
              [161.087908, 69.226202],
              [161.051344, 69.206161],
              [161.09306, 69.154972],
              [161.081899, 69.138962],
              [161.02748, 69.113211],
              [161.044089, 69.108814],
              [161.135095, 69.12684],
              [161.112527, 69.174788],
              [161.112923, 69.206884],
              [161.141794, 69.266368],
              [161.118662, 69.287826],
              [161.115843, 69.333386],
              [161.166445, 69.310627],
              [161.123591, 69.28777],
              [161.174734, 69.280057],
              [161.190291, 69.304502],
              [161.173878, 69.328304],
              [161.149733, 69.334264],
              [161.142473, 69.358923],
              [161.104215, 69.382053],
              [161.090311, 69.41123],
              [161.095461, 69.447238],
              [161.127046, 69.459589],
              [161.164812, 69.45977],
              [161.188501, 69.473317],
              [161.265577, 69.490525],
              [161.32171, 69.516551],
              [161.362222, 69.528982],
              [161.395181, 69.531744],
              [161.371815, 69.544357],
              [161.42088, 69.547478],
              [161.377802, 69.557818],
              [161.417716, 69.568705],
              [161.441913, 69.588275],
              [161.39338, 69.606742],
              [161.337896, 69.578186],
              [161.351768, 69.610258],
              [161.453397, 69.618494],
              [161.525129, 69.617113],
              [161.581949, 69.611073],
              [161.657137, 69.582168],
              [161.663316, 69.554722],
              [161.624864, 69.51547],
              [161.62933, 69.50243],
              [161.664346, 69.490585],
              [161.666406, 69.475604],
              [161.63276, 69.460251],
              [161.646494, 69.431986],
              [161.600488, 69.413463],
              [161.519464, 69.407124],
              [161.45217, 69.383625],
              [161.459039, 69.40483],
              [161.38511, 69.387682],
              [161.370916, 69.376799],
              [161.40236, 69.336294],
              [161.358688, 69.314708],
              [161.356941, 69.302933],
              [161.424397, 69.290772],
              [161.417947, 69.26543],
              [161.36173, 69.240651],
              [161.35859, 69.22341],
              [161.383935, 69.199232],
              [161.44016, 69.186222],
              [161.466249, 69.239349],
              [161.507105, 69.260574],
              [161.484445, 69.264282],
              [161.44943, 69.24525],
              [161.465563, 69.270116],
              [161.4908, 69.277346],
              [161.452688, 69.295135],
              [161.440328, 69.311759],
              [161.448568, 69.328127],
              [161.50384, 69.324005],
              [161.492513, 69.338789],
              [161.463331, 69.347994],
              [161.4932, 69.353442],
              [161.511396, 69.378124],
              [161.553625, 69.386707],
              [161.608213, 69.384773],
              [161.631216, 69.391662],
              [161.70812, 69.441994],
              [161.699194, 69.449106],
              [161.798071, 69.478011],
              [161.813863, 69.485954],
              [161.854638, 69.504182],
              [161.942499, 69.513114],
              [162.035212, 69.53777],
              [162.017795, 69.547419],
              [161.96918, 69.540403],
              [161.956748, 69.562142],
              [161.985182, 69.581811],
              [162.04468, 69.578298],
              [162.045097, 69.594405],
              [162.09413, 69.596794],
              [162.084041, 69.576473],
              [162.043729, 69.572364],
              [162.063116, 69.561137],
              [162.112579, 69.571033],
              [162.142235, 69.590195],
              [162.21255, 69.590561],
              [162.236993, 69.602716],
              [162.212302, 69.620487],
              [162.21929, 69.634411],
              [162.277117, 69.624447],
              [162.349764, 69.630549],
              [162.383665, 69.65638],
              [162.36009, 69.677513],
              [162.401114, 69.660987],
              [162.469443, 69.654961],
              [162.488798, 69.632718],
              [162.378298, 69.616001],
              [162.348009, 69.59612],
              [162.32411, 69.590994],
              [162.36618, 69.584414],
              [162.394562, 69.571793],
              [162.412578, 69.549421],
              [162.342288, 69.54897],
              [162.342853, 69.531982],
              [162.32461, 69.52943],
              [162.357457, 69.497936],
              [162.417086, 69.470847],
              [162.43208, 69.454206],
              [162.406185, 69.424936],
              [162.359094, 69.417791],
              [162.3495, 69.406387],
              [162.460298, 69.3802],
              [162.489371, 69.383634],
              [162.543629, 69.372961],
              [162.545741, 69.355219],
              [162.513718, 69.327776],
              [162.562997, 69.28877],
              [162.584566, 69.285107],
              [162.636688, 69.26048],
              [162.69896, 69.244011],
              [162.724771, 69.223296],
              [162.72572, 69.208189],
              [162.772473, 69.181351],
              [162.82646, 69.183649],
              [162.764771, 69.154624],
              [162.714982, 69.153991],
              [162.686757, 69.145103],
              [162.685317, 69.123839],
              [162.62488, 69.124027],
              [162.531907, 69.105638],
              [162.512406, 69.084911],
              [162.510844, 69.067557],
              [162.529824, 69.046234],
              [162.560909, 69.045326],
              [162.577096, 69.035302],
              [162.551378, 69.021705],
              [162.558471, 69.004701],
              [162.539667, 68.982584],
              [162.57955, 68.961211],
              [162.616054, 68.957342],
              [162.66426, 68.941105],
              [162.726529, 68.928539],
              [162.773897, 68.903257],
              [162.761457, 68.882807],
              [162.802471, 68.85326],
              [162.846724, 68.843782],
              [162.855209, 68.831229],
              [162.79937, 68.822854],
              [162.772709, 68.806893],
              [162.770738, 68.794234],
              [162.733122, 68.769073],
              [162.66112, 68.770112],
              [162.591608, 68.777632],
              [162.588524, 68.741601],
              [162.600451, 68.72875],
              [162.591219, 68.713933],
              [162.603191, 68.68753],
              [162.652767, 68.666866],
              [162.709587, 68.658241],
              [162.727942, 68.598712],
              [162.751685, 68.583955],
              [162.723938, 68.573763],
              [162.722343, 68.561084],
              [162.664538, 68.535849],
              [162.64623, 68.509754],
              [162.590326, 68.499859],
              [162.563824, 68.483344],
              [162.600113, 68.463629],
              [162.616563, 68.43732],
              [162.59491, 68.416396],
              [162.556008, 68.39305],
              [162.5452, 68.364126],
              [162.532366, 68.355276],
              [162.54795, 68.333041],
              [162.509277, 68.316693],
              [162.464571, 68.306676],
              [162.447526, 68.284103],
              [162.39375, 68.274591],
              [162.35531, 68.28684],
              [162.312949, 68.293189],
              [162.293248, 68.305501],
              [162.256169, 68.305486],
              [162.206016, 68.315894],
              [162.194525, 68.33462],
              [162.054609, 68.338086],
              [161.984553, 68.359083],
              [161.952261, 68.356581],
              [161.893656, 68.340347],
              [161.832294, 68.354943],
              [161.795702, 68.347973],
              [161.74553, 68.351468],
              [161.710646, 68.345468],
              [161.680545, 68.365356],
              [161.648387, 68.374933],
              [161.604907, 68.377016],
              [161.53387, 68.373988],
              [161.44974, 68.377936],
              [161.327292, 68.368581],
              [161.314502, 68.36157],
              [161.199905, 68.341693],
              [161.136985, 68.314666],
              [161.068762, 68.302327],
              [161.028757, 68.284424],
              [160.955187, 68.272423],
              [160.862201, 68.270017],
              [160.753468, 68.257634],
              [160.73171, 68.247989],
              [160.687007, 68.25067],
              [160.521456, 68.244884],
              [160.492392, 68.24924],
              [160.424518, 68.248848],
              [160.397983, 68.23932],
              [160.339679, 68.251563],
              [160.315998, 68.248802],
              [160.22938, 68.262074],
              [160.18506, 68.264599],
              [160.107415, 68.259679],
              [160.072613, 68.267002],
              [160.026359, 68.263318],
              [159.996778, 68.25186],
              [159.944142, 68.259624],
              [159.827464, 68.248196],
              [159.791751, 68.221162],
              [159.811305, 68.204376],
              [159.790401, 68.203685],
              [159.80139, 68.186179],
              [159.743854, 68.17864],
              [159.723907, 68.182196],
              [159.71826, 68.197332],
              [159.684328, 68.203597],
              [159.656572, 68.222842],
              [159.610554, 68.210857],
              [159.571052, 68.20796],
              [159.562946, 68.214363],
              [159.519636, 68.211605],
              [159.479905, 68.2022],
              [159.421084, 68.196032],
              [159.41762, 68.186571],
              [159.251936, 68.190598],
              [159.211853, 68.168098],
              [159.149323, 68.169485],
              [159.050112, 68.148367],
              [159.032074, 68.149087],
              [158.980483, 68.123932],
              [158.937036, 68.120201],
              [158.893296, 68.10158],
              [158.880165, 68.108243],
              [158.806252, 68.121998],
              [158.770846, 68.108175],
              [158.726653, 68.116504],
              [158.705533, 68.113723],
              [158.646984, 68.127407],
              [158.52692, 68.096885],
              [158.372391, 68.076162],
              [158.373762, 68.063619],
              [158.325145, 68.060556],
              [158.32669, 68.050031],
              [158.300103, 68.047854],
              [158.291787, 68.034494],
              [158.30916, 68.018818],
              [158.298679, 68.002909],
              [158.273534, 67.998201],
              [158.276, 67.984673],
              [158.253323, 67.966535],
              [158.235019, 67.964085],
              [158.215908, 67.934821],
              [158.150225, 67.916965],
              [158.103566, 67.914021],
              [158.09895, 67.889414],
              [158.079696, 67.875019],
              [158.081936, 67.836157],
              [158.106031, 67.821721],
              [158.148686, 67.825202],
              [158.181783, 67.819633],
              [158.212334, 67.830204],
              [158.249488, 67.824492],
              [158.285982, 67.805521],
              [158.293781, 67.785093],
              [158.318766, 67.77327],
              [158.314675, 67.758049],
              [158.36058, 67.734226],
              [158.362234, 67.723638],
              [158.316615, 67.723052],
              [158.239228, 67.708721],
              [158.18225, 67.714712],
              [158.169867, 67.694815],
              [158.15075, 67.701194],
              [158.1168, 67.697089],
              [158.115615, 67.710335],
              [158.0833, 67.718131],
              [157.991944, 67.711679],
              [157.942039, 67.72397],
              [157.810091, 67.674568],
              [157.84812, 67.659477],
              [157.809631, 67.6448],
              [157.777557, 67.622253],
              [157.773519, 67.602328],
              [157.758624, 67.594201],
              [157.78304, 67.574687],
              [157.744088, 67.557574],
              [157.74368, 67.523743],
              [157.730565, 67.518002],
              [157.762218, 67.500002],
              [157.79945, 67.489086],
              [157.788038, 67.476399],
              [157.793054, 67.449008],
              [157.907708, 67.420455],
              [157.89918, 67.399359],
              [157.86616, 67.381504],
              [157.849176, 67.364113],
              [157.807727, 67.351499],
              [157.84261, 67.333261],
              [157.811372, 67.308325],
              [157.839066, 67.286079],
              [157.879196, 67.284386],
              [157.924251, 67.259511],
              [158.002632, 67.247197],
              [158.016554, 67.233673],
              [158.051201, 67.227525],
              [158.093155, 67.227682],
              [158.110039, 67.213935],
              [158.171108, 67.19888],
              [158.160574, 67.189819],
              [158.184807, 67.185583],
              [158.199036, 67.171969],
              [158.238178, 67.167357],
              [158.242582, 67.144659],
              [158.277883, 67.150695],
              [158.311666, 67.139337],
              [158.378919, 67.101907],
              [158.43693, 67.103686],
              [158.479141, 67.08831],
              [158.540513, 67.080172],
              [158.574056, 67.064713],
              [158.618001, 67.05745],
              [158.643645, 67.062734],
              [158.663363, 67.049827],
              [158.71176, 67.038389],
              [158.746824, 67.021642],
              [158.740084, 67.002832],
              [158.781459, 66.989897],
              [158.782125, 66.979273],
              [158.811792, 66.962764],
              [158.815415, 66.94042],
              [158.783817, 66.930799],
              [158.785948, 66.916132],
              [158.809047, 66.908021],
              [158.787335, 66.89387],
              [158.841209, 66.871343],
              [158.8632, 66.869575],
              [158.860867, 66.854178],
              [158.88054, 66.844751],
              [158.847127, 66.837239],
              [158.85824, 66.817353],
              [158.893509, 66.803769],
              [158.934868, 66.768745],
              [158.960036, 66.760587],
              [158.9202, 66.751728],
              [158.88028, 66.751201],
              [158.843127, 66.741986],
              [158.841305, 66.731155],
              [158.811654, 66.71975],
              [158.77551, 66.726084],
              [158.71122, 66.689077],
              [158.723779, 66.673912],
              [158.713382, 66.659665],
              [158.72259, 66.641765],
              [158.666922, 66.615566],
              [158.669226, 66.601431],
              [158.644722, 66.592493],
              [158.63624, 66.566424],
              [158.606415, 66.558864],
              [158.57364, 66.560691],
              [158.535021, 66.548431],
              [158.544228, 66.530127],
              [158.490742, 66.502967],
              [158.504391, 66.4629],
              [158.417477, 66.44803],
              [158.397622, 66.434672],
              [158.368873, 66.430309],
              [158.370252, 66.411157],
              [158.390142, 66.404874],
              [158.40425, 66.415158],
              [158.466687, 66.386071],
              [158.465944, 66.375204],
              [158.498739, 66.369821],
              [158.507455, 66.356178],
              [158.551015, 66.342661],
              [158.543444, 66.335235],
              [158.498243, 66.322115],
              [158.493151, 66.284674],
              [158.448307, 66.272865],
              [158.429979, 66.257794],
              [158.441348, 66.248228],
              [158.437993, 66.222454],
              [158.403346, 66.21974],
              [158.41131, 66.204641],
              [158.432257, 66.20337],
              [158.47245, 66.169927],
              [158.455504, 66.160289],
              [158.47511, 66.146916],
              [158.496465, 66.115262],
              [158.517708, 66.104918],
              [158.500697, 66.093502],
              [158.449504, 66.085382],
              [158.378094, 66.102058],
              [158.283133, 66.101296],
              [158.285921, 66.115365],
              [158.26616, 66.112828],
              [158.228532, 66.122559],
              [158.188291, 66.142603],
              [158.163165, 66.133985],
              [158.092131, 66.134323],
              [158.04098, 66.117201],
              [157.92769, 66.107823],
              [157.897153, 66.101658],
              [157.840617, 66.104395],
              [157.744936, 66.099459],
              [157.699656, 66.07985],
              [157.68936, 66.064581],
              [157.662667, 66.053972],
              [157.660763, 66.037198],
              [157.621411, 66.022685],
              [157.56808, 66.021185],
              [157.502266, 66.004453],
              [157.444713, 66.000241],
              [157.408144, 65.96865],
              [157.42048, 65.953133],
              [157.458704, 65.936626],
              [157.449326, 65.912874],
              [157.381484, 65.907634],
              [157.335158, 65.891827],
              [157.303631, 65.906688],
              [157.213991, 65.895921],
              [157.19149, 65.903256],
              [157.147028, 65.900839],
              [157.110698, 65.914599],
              [157.083926, 65.914601],
              [157.023725, 65.942584],
              [156.961833, 65.956736],
              [157.002377, 65.965314],
              [157.019134, 65.9773],
              [157.013504, 65.993437],
              [157.034383, 66.007334],
              [157.00252, 66.012557],
              [156.921275, 66.039791],
              [156.904384, 66.064054],
              [156.841225, 66.082817],
              [156.817836, 66.103545],
              [156.766567, 66.122456],
              [156.767952, 66.152493],
              [156.722754, 66.174626],
              [156.674004, 66.189043],
              [156.63143, 66.177213],
              [156.644273, 66.148031],
              [156.577662, 66.138892],
              [156.538553, 66.119412],
              [156.533871, 66.087908],
              [156.49589, 66.083248],
              [156.455227, 66.06521],
              [156.47141, 66.050884],
              [156.42702, 66.038272],
              [156.382725, 66.054639],
              [156.346148, 66.061141],
              [156.29862, 66.057828],
              [156.266742, 66.064626],
              [156.246524, 66.058638],
              [156.201065, 66.068083],
              [156.087741, 66.069622],
              [156.06531, 66.046131],
              [156.006589, 66.073601],
              [155.948471, 66.067757],
              [155.94107, 66.087261],
              [155.906952, 66.088383],
              [155.859257, 66.105488],
              [155.81293, 66.147378],
              [155.747972, 66.161196],
              [155.69157, 66.140096],
              [155.659016, 66.121953],
              [155.622894, 66.119839],
              [155.560239, 66.142208],
              [155.528176, 66.14422],
              [155.498419, 66.133956],
              [155.453615, 66.129983],
              [155.4212, 66.132869],
              [155.402757, 66.125378],
              [155.372376, 66.129588],
              [155.362241, 66.143519],
              [155.316642, 66.159646],
              [155.290528, 66.158076],
              [155.26268, 66.181404],
              [155.210873, 66.181796],
              [155.200893, 66.175111],
              [155.170693, 66.182483],
              [155.138609, 66.157341],
              [155.102205, 66.169477],
              [155.057722, 66.160338],
              [155.079598, 66.130124],
              [154.979514, 66.119423],
              [154.946317, 66.110584],
              [154.894973, 66.109322],
              [154.887025, 66.150144],
              [154.859214, 66.154873],
              [154.843025, 66.168246],
              [154.801674, 66.174906],
              [154.724973, 66.175958],
              [154.663521, 66.18126],
              [154.610278, 66.19595],
              [154.585952, 66.217985],
              [154.521153, 66.218694],
              [154.463077, 66.183165],
              [154.394493, 66.182848],
              [154.379887, 66.168081],
              [154.392951, 66.141492],
              [154.364956, 66.103399],
              [154.302583, 66.102929],
              [154.265203, 66.087036],
              [154.228944, 66.047978],
              [154.236796, 66.038241],
              [154.277612, 66.039986],
              [154.374991, 66.029176],
              [154.393085, 65.997841],
              [154.430058, 65.994357],
              [154.378689, 65.97417],
              [154.415657, 65.950151],
              [154.411806, 65.922526],
              [154.387702, 65.901437],
              [154.32548, 65.877533],
              [154.337794, 65.85493],
              [154.312849, 65.846124],
              [154.300839, 65.826402],
              [154.27328, 65.819511],
              [154.266841, 65.806328],
              [154.19775, 65.795132],
              [154.159725, 65.769547],
              [154.136718, 65.778504],
              [154.089677, 65.783536],
              [154.087705, 65.80189],
              [154.099285, 65.839377],
              [154.087433, 65.850263],
              [154.03414, 65.854609],
              [154.026468, 65.865818],
              [153.956673, 65.873049],
              [153.928861, 65.865979],
              [153.89418, 65.869331],
              [153.836342, 65.8566],
              [153.788468, 65.855133],
              [153.754727, 65.870852],
              [153.731446, 65.857908],
              [153.68031, 65.842535],
              [153.635921, 65.845812],
              [153.593077, 65.834886],
              [153.577152, 65.822128],
              [153.532184, 65.810303],
              [153.53367, 65.79825],
              [153.495287, 65.764848],
              [153.483706, 65.747972],
              [153.523676, 65.733418],
              [153.54799, 65.73725],
              [153.556768, 65.71615],
              [153.57278, 65.710275],
              [153.577082, 65.689843],
              [153.514928, 65.667004],
              [153.510087, 65.654913],
              [153.540637, 65.6373],
              [153.500336, 65.589247],
              [153.490216, 65.585007],
              [153.487275, 65.550535],
              [153.46947, 65.540324],
              [153.467599, 65.515581],
              [153.442342, 65.48533],
              [153.461894, 65.45987],
              [153.50034, 65.462715],
              [153.549962, 65.44646],
              [153.557892, 65.430287],
              [153.582315, 65.419394],
              [153.585837, 65.398429],
              [153.530787, 65.36605],
              [153.514828, 65.364502],
              [153.500533, 65.345736],
              [153.458385, 65.357266],
              [153.42793, 65.355396],
              [153.3842, 65.33384],
              [153.339097, 65.327895],
              [153.28299, 65.313529],
              [153.243637, 65.309622],
              [153.190035, 65.295752],
              [153.18347, 65.27024],
              [153.148186, 65.244046],
              [153.119256, 65.264191],
              [153.045009, 65.256635],
              [152.973499, 65.242989],
              [152.94148, 65.255444],
              [152.908782, 65.25068],
              [152.876438, 65.233208],
              [152.876473, 65.225611],
              [152.797821, 65.206831],
              [152.765397, 65.228625],
              [152.733014, 65.227087],
              [152.71279, 65.213399],
              [152.727381, 65.197046],
              [152.710476, 65.179268],
              [152.67615, 65.180385],
              [152.66147, 65.168483],
              [152.656316, 65.130427],
              [152.645634, 65.117523],
              [152.6468, 65.082805],
              [152.630224, 65.060508],
              [152.60715, 65.052021],
              [152.590011, 65.033339],
              [152.568656, 65.025088],
              [152.578109, 65.013245],
              [152.567701, 65.000459],
              [152.525736, 64.993862],
              [152.519314, 64.976629],
              [152.529664, 64.945858],
              [152.541042, 64.935388],
              [152.518397, 64.91846],
              [152.550146, 64.884787],
              [152.550923, 64.863363],
              [152.584843, 64.835743],
              [152.585915, 64.811292],
              [152.629897, 64.803574],
              [152.65378, 64.808854],
              [152.714918, 64.782868],
              [152.746164, 64.774556],
              [152.759392, 64.757366],
              [152.78814, 64.742334],
              [152.808007, 64.743132],
              [152.83183, 64.709804],
              [152.847283, 64.701393],
              [152.83076, 64.681016],
              [152.783722, 64.66464],
              [152.75415, 64.641326],
              [152.737272, 64.640229],
              [152.72706, 64.623176],
              [152.671481, 64.621045],
              [152.623773, 64.610306],
              [152.601485, 64.583835],
              [152.535019, 64.561144],
              [152.487935, 64.562294],
              [152.480712, 64.55022],
              [152.39884, 64.528184],
              [152.376811, 64.5329],
              [152.358914, 64.50825],
              [152.40307, 64.492112],
              [152.43551, 64.467784],
              [152.423284, 64.453966],
              [152.44429, 64.44176],
              [152.44504, 64.418435],
              [152.427814, 64.408159],
              [152.4448, 64.389408],
              [152.416385, 64.384074],
              [152.365649, 64.384042],
              [152.332168, 64.368045],
              [152.285359, 64.376253],
              [152.255496, 64.355151],
              [152.216195, 64.359517],
              [152.150417, 64.351137],
              [152.131617, 64.356607],
              [152.12471, 64.371347],
              [152.143024, 64.386236],
              [152.115163, 64.395449],
              [152.101848, 64.416679],
              [152.082717, 64.4222],
              [152.071538, 64.452285],
              [152.047762, 64.467923],
              [152.017576, 64.477341],
              [151.991473, 64.475287],
              [151.974573, 64.489108],
              [151.929879, 64.476437],
              [151.879244, 64.471568],
              [151.813634, 64.470063],
              [151.611105, 64.43678],
              [151.496874, 64.425812],
              [151.481479, 64.41951],
              [151.481078, 64.390834],
              [151.446157, 64.382078],
              [151.418398, 64.357932],
              [151.410216, 64.330624],
              [151.38906, 64.330327],
              [151.371697, 64.366406],
              [151.313895, 64.371303],
              [151.304334, 64.358111],
              [151.273165, 64.35669],
              [151.2517, 64.344147],
              [151.225879, 64.354606],
              [151.167177, 64.359236],
              [151.083735, 64.346474],
              [151.049068, 64.351699],
              [150.993956, 64.332985],
              [150.916772, 64.334283],
              [150.886288, 64.330046],
              [150.795892, 64.327052],
              [150.740348, 64.333272],
              [150.71832, 64.331387],
              [150.663659, 64.33979],
              [150.63959, 64.337741],
              [150.58689, 64.304575],
              [150.595208, 64.292299],
              [150.628188, 64.290402],
              [150.675331, 64.267998],
              [150.681119, 64.254371],
              [150.645018, 64.224515],
              [150.648306, 64.209899],
              [150.668293, 64.1927],
              [150.65081, 64.167535],
              [150.59338, 64.176755],
              [150.54882, 64.166332],
              [150.51863, 64.182748],
              [150.480691, 64.185068],
              [150.432291, 64.204656],
              [150.375155, 64.214925],
              [150.336531, 64.216661],
              [150.312512, 64.20838],
              [150.233632, 64.213203],
              [150.196246, 64.226865],
              [150.209798, 64.258904],
              [150.17951, 64.271926],
              [150.162749, 64.270117],
              [150.109845, 64.282031],
              [150.083688, 64.292798],
              [150.091281, 64.302514],
              [150.078898, 64.318663],
              [150.04578, 64.324949],
              [150.042052, 64.345263],
              [150.016771, 64.350929],
              [150.077697, 64.386433],
              [150.070378, 64.408742],
              [150.134722, 64.430507],
              [150.11969, 64.437519],
              [150.14022, 64.447603],
              [150.143723, 64.469472],
              [150.127874, 64.468331],
              [150.097893, 64.483663],
              [150.028071, 64.497536],
              [149.994193, 64.496075],
              [149.998889, 64.50728],
              [149.975083, 64.526813],
              [149.97822, 64.542086],
              [149.92443, 64.552736],
              [149.914301, 64.549494],
              [149.871263, 64.56237],
              [149.839375, 64.560976],
              [149.80483, 64.548111],
              [149.772787, 64.546997],
              [149.743301, 64.531435],
              [149.73678, 64.519051],
              [149.699192, 64.512746],
              [149.679967, 64.500727],
              [149.580637, 64.470464],
              [149.560621, 64.450031],
              [149.55923, 64.433716],
              [149.511681, 64.425879],
              [149.502287, 64.405748],
              [149.474748, 64.404078],
              [149.447375, 64.416017],
              [149.42266, 64.412683],
              [149.360938, 64.42002],
              [149.345641, 64.402761],
              [149.370432, 64.389826],
              [149.366622, 64.380223],
              [149.339492, 64.376755],
              [149.261488, 64.377044],
              [149.195211, 64.381817],
              [149.193644, 64.399073],
              [149.178988, 64.408618],
              [149.192364, 64.41919],
              [149.164267, 64.426423],
              [149.119454, 64.419479],
              [149.041135, 64.431475],
              [148.98424, 64.422725],
              [148.934726, 64.432145],
              [148.923544, 64.42125],
              [148.863373, 64.411548],
              [148.85167, 64.41876],
              [148.813001, 64.409058],
              [148.776467, 64.413502],
              [148.758717, 64.405719],
              [148.708723, 64.402203],
              [148.681748, 64.416161],
              [148.6635, 64.416219],
              [148.612289, 64.437041],
              [148.555713, 64.450364],
              [148.551888, 64.472444],
              [148.511888, 64.485097],
              [148.525032, 64.496054],
              [148.501199, 64.50981],
              [148.459538, 64.509653],
              [148.444865, 64.523554],
              [148.406878, 64.520425],
              [148.385405, 64.52954],
              [148.327615, 64.528079],
              [148.274567, 64.48303],
              [148.239746, 64.481226],
              [148.224911, 64.44223],
              [148.179806, 64.424865],
              [148.147623, 64.421806],
              [148.130736, 64.429482],
              [148.094725, 64.427704],
              [148.055195, 64.418476],
              [148.04783, 64.407899],
              [148.088686, 64.393328],
              [148.073, 64.385231],
              [148.092208, 64.362501],
              [148.069663, 64.350655],
              [148.09495, 64.330418],
              [148.11488, 64.326225],
              [148.128378, 64.306964],
              [148.1235, 64.295166],
              [148.202361, 64.280952],
              [148.212463, 64.269326],
              [148.249508, 64.259703],
              [148.314505, 64.220696],
              [148.28952, 64.218607],
              [148.26089, 64.200037],
              [148.25709, 64.181866],
              [148.278921, 64.166849],
              [148.263517, 64.152785],
              [148.229795, 64.153254],
              [148.224318, 64.131761],
              [148.1857, 64.117023],
              [148.187992, 64.106027],
              [148.159692, 64.102504],
              [148.176411, 64.07742],
              [148.176123, 64.061811],
              [148.16009, 64.052392],
              [148.176595, 64.023366],
              [148.137044, 64.012923],
              [148.1109, 64.016339],
              [148.069504, 63.996305],
              [148.071839, 63.972242],
              [148.032397, 63.964504],
              [148.01834, 63.936136],
              [147.975677, 63.936786],
              [147.905539, 63.947495],
              [147.922182, 63.960472],
              [147.880922, 63.969182],
              [147.862753, 63.95049],
              [147.819278, 63.947363],
              [147.788405, 63.953647],
              [147.777639, 63.981339],
              [147.804644, 63.995213],
              [147.75111, 64.009646],
              [147.701462, 64.015055],
              [147.670816, 64.011346],
              [147.669515, 64.031434],
              [147.653248, 64.035045],
              [147.680403, 64.052988],
              [147.651769, 64.066943],
              [147.691719, 64.08698],
              [147.71923, 64.093695],
              [147.73276, 64.115184],
              [147.700153, 64.133743],
              [147.672395, 64.160118],
              [147.65099, 64.164259],
              [147.643774, 64.178001],
              [147.585092, 64.163447],
              [147.569347, 64.146041],
              [147.511452, 64.145372],
              [147.460183, 64.139736],
              [147.460623, 64.135187],
              [147.401753, 64.129572],
              [147.342367, 64.111444],
              [147.371342, 64.101712],
              [147.38328, 64.081941],
              [147.361506, 64.076641],
              [147.358948, 64.055362],
              [147.346773, 64.045528],
              [147.318879, 64.045437],
              [147.298862, 64.036299],
              [147.294615, 64.018729],
              [147.254844, 64.026709],
              [147.21371, 64.042064],
              [147.10563, 64.099557],
              [147.10512, 64.106548],
              [147.066709, 64.113401],
              [147.012052, 64.133424],
              [146.951481, 64.134016],
              [146.884999, 64.13875],
              [146.834568, 64.151145],
              [146.767089, 64.146753],
              [146.718527, 64.155281],
              [146.685668, 64.1527],
              [146.646569, 64.169644],
              [146.612855, 64.16827],
              [146.574287, 64.17529],
              [146.542709, 64.189356],
              [146.496719, 64.189493],
              [146.433786, 64.199296],
              [146.368627, 64.185881],
              [146.364923, 64.140723],
              [146.329119, 64.101924],
              [146.338551, 64.091519],
              [146.33349, 64.0701],
              [146.29613, 64.049507],
              [146.279241, 64.030661],
              [146.230759, 64.019683],
              [146.220312, 64.004468],
              [146.230093, 63.978811],
              [146.176961, 63.953823],
              [146.181984, 63.942217],
              [146.165684, 63.927209],
              [146.112125, 63.918997],
              [146.0755, 63.920526],
              [145.998187, 63.909952],
              [145.995674, 63.903434],
              [145.930847, 63.884302],
              [145.884917, 63.877891],
              [145.83098, 63.881252],
              [145.81653, 63.8718],
              [145.75762, 63.865663],
              [145.710067, 63.848307],
              [145.6558, 63.83722],
              [145.586, 63.828036],
              [145.567507, 63.807865],
              [145.601472, 63.805714],
              [145.668741, 63.774762],
              [145.65335, 63.768658],
              [145.659141, 63.728856],
              [145.649345, 63.719893],
              [145.66653, 63.709403],
              [145.672851, 63.691651],
              [145.659314, 63.682692],
              [145.675889, 63.673529],
              [145.665851, 63.658704],
              [145.637149, 63.643739],
              [145.60117, 63.644897],
              [145.576826, 63.629661],
              [145.583953, 63.619099],
              [145.554987, 63.603644],
              [145.557949, 63.590799],
              [145.532194, 63.569678],
              [145.498318, 63.55866],
              [145.4683, 63.526303],
              [145.489762, 63.513848],
              [145.458739, 63.463393],
              [145.464405, 63.438259],
              [145.448674, 63.412584],
              [145.440458, 63.378698],
              [145.397199, 63.356534],
              [145.405285, 63.340815],
              [145.38456, 63.317996],
              [145.393119, 63.308217],
              [145.34363, 63.297427],
              [145.311364, 63.269032],
              [145.25786, 63.242875],
              [145.27331, 63.178784],
              [145.24951, 63.161006],
              [145.30296, 63.087796],
              [145.28934, 63.086188],
              [145.316205, 63.050642],
              [145.280643, 63.036917],
              [145.288057, 63.011657],
              [145.309704, 63.006716],
              [145.340331, 63.01119],
              [145.353298, 62.979215],
              [145.36918, 62.974442],
              [145.378116, 62.950317],
              [145.354949, 62.942947],
              [145.37191, 62.938497],
              [145.395014, 62.914168],
              [145.39294, 62.891634],
              [145.409515, 62.866673],
              [145.401412, 62.836953],
              [145.379009, 62.831527],
              [145.37628, 62.810559],
              [145.36137, 62.782774],
              [145.3657, 62.774314],
              [145.353052, 62.746696],
              [145.400193, 62.728871],
              [145.439708, 62.73048],
              [145.45657, 62.707403],
              [145.40855, 62.702381],
              [145.39044, 62.680707],
              [145.418847, 62.657247],
              [145.425613, 62.642819],
              [145.450265, 62.632283],
              [145.413601, 62.628984],
              [145.39705, 62.599234],
              [145.436306, 62.587348],
              [145.444813, 62.556269],
              [145.484044, 62.539551],
              [145.43741, 62.530871],
              [145.384511, 62.499477],
              [145.349283, 62.503078],
              [145.327619, 62.513327],
              [145.280202, 62.524484],
              [145.268837, 62.509846],
              [145.227252, 62.503263],
              [145.217012, 62.473603],
              [145.17304, 62.489912],
              [145.137654, 62.478539],
              [145.157866, 62.457601],
              [145.189874, 62.436781],
              [145.184358, 62.413875],
              [145.14923, 62.397267],
              [145.1665, 62.389675],
              [145.176425, 62.365294],
              [145.170176, 62.352578],
              [145.1976, 62.345568],
              [145.207517, 62.333696],
              [145.196303, 62.322083],
              [145.210096, 62.310129],
              [145.202017, 62.298957],
              [145.16684, 62.300434],
              [145.171262, 62.289954],
              [145.19959, 62.278872],
              [145.16828, 62.276724],
              [145.120337, 62.264956],
              [145.130434, 62.247412],
              [145.098984, 62.23461],
              [145.057278, 62.229654],
              [145.01118, 62.249071],
              [144.990204, 62.238915],
              [144.98746, 62.225914],
              [145.0171, 62.205437],
              [145.009646, 62.193405],
              [144.976977, 62.182099],
              [144.991661, 62.168061],
              [144.98684, 62.156606],
              [144.956614, 62.14802],
              [144.934388, 62.161794],
              [144.87762, 62.158284],
              [144.876847, 62.133395],
              [144.83817, 62.124844],
              [144.825926, 62.112342],
              [144.810162, 62.114145],
              [144.736525, 62.095409],
              [144.728624, 62.085814],
              [144.744278, 62.061611],
              [144.73642, 62.045483],
              [144.719519, 62.044336],
              [144.697123, 62.019886],
              [144.672131, 62.019022],
              [144.655509, 61.990844],
              [144.633074, 61.991529],
              [144.61343, 61.978189],
              [144.582979, 61.973206],
              [144.586321, 61.94963],
              [144.609365, 61.935741],
              [144.587926, 61.91696],
              [144.617804, 61.907496],
              [144.584004, 61.904548],
              [144.547548, 61.892323],
              [144.52626, 61.875799],
              [144.548564, 61.870947],
              [144.537783, 61.862845],
              [144.549473, 61.848024],
              [144.515905, 61.845166],
              [144.500138, 61.832384],
              [144.48298, 61.832981],
              [144.454055, 61.820931],
              [144.411954, 61.8333],
              [144.371148, 61.806798],
              [144.372251, 61.791467],
              [144.354194, 61.784095],
              [144.336371, 61.792736],
              [144.297148, 61.776817],
              [144.24661, 61.766743],
              [144.25561, 61.746034],
              [144.223743, 61.727803],
              [144.209109, 61.737826],
              [144.18888, 61.732616],
              [144.187931, 61.747094],
              [144.13549, 61.756068],
              [144.122208, 61.735251],
              [144.060494, 61.750378],
              [144.001115, 61.745286],
              [143.98853, 61.756103],
              [143.944683, 61.754986],
              [143.949971, 61.777356],
              [143.933097, 61.785698],
              [143.959431, 61.812187],
              [143.94774, 61.823597],
              [143.951274, 61.839507],
              [143.983995, 61.859956],
              [143.97975, 61.875601],
              [143.998537, 61.891583],
              [143.983579, 61.903569],
              [143.964942, 61.902701],
              [143.941437, 61.917156],
              [143.959103, 61.92975],
              [143.944928, 61.948375],
              [143.920001, 61.949944],
              [143.93577, 61.973307],
              [143.919316, 61.982571],
              [143.922257, 61.994276],
              [143.901706, 61.997886],
              [143.86515, 61.980292],
              [143.85607, 61.962707],
              [143.823748, 61.956367],
              [143.777093, 61.954826],
              [143.768335, 61.945167],
              [143.78401, 61.920851],
              [143.753372, 61.902973],
              [143.731818, 61.90734],
              [143.698927, 61.904],
              [143.71445, 61.885679],
              [143.687047, 61.877504],
              [143.697407, 61.871227],
              [143.681857, 61.859308],
              [143.642355, 61.85787],
              [143.635303, 61.870926],
              [143.649507, 61.878154],
              [143.623527, 61.882964],
              [143.639553, 61.903285],
              [143.615074, 61.927776],
              [143.6179, 61.940864],
              [143.602775, 61.953289],
              [143.63134, 61.965832],
              [143.63625, 61.977713],
              [143.617208, 61.984889],
              [143.611058, 62.00392],
              [143.645788, 62.018704],
              [143.60635, 62.023577],
              [143.609126, 62.036011],
              [143.56373, 62.03252],
              [143.547992, 62.049755],
              [143.47987, 62.052848],
              [143.441032, 62.045505],
              [143.413333, 62.021878],
              [143.37231, 62.034006],
              [143.334967, 62.033713],
              [143.285479, 62.049911],
              [143.27977, 62.075521],
              [143.296127, 62.087315],
              [143.276342, 62.090898],
              [143.229693, 62.117471],
              [143.197541, 62.103805],
              [143.135909, 62.104004],
              [143.115075, 62.071557],
              [143.153417, 62.059373],
              [143.126801, 62.048889],
              [143.104672, 62.014728],
              [143.086812, 62.011602],
              [143.07884, 61.992666],
              [143.055557, 61.98723],
              [143.052154, 61.975846],
              [143.014314, 61.973431],
              [143.02309, 61.945692],
              [143.000232, 61.932521],
              [143.006472, 61.914842],
              [142.973968, 61.911128],
              [142.957602, 61.89841],
              [142.940479, 61.898748],
              [142.926701, 61.913874],
              [142.896399, 61.905418],
              [142.883119, 61.92451],
              [142.866266, 61.926435],
              [142.844346, 61.94751],
              [142.78729, 61.944438],
              [142.793588, 61.952197],
              [142.767657, 61.965786],
              [142.742518, 61.959688],
              [142.723366, 61.970616],
              [142.68618, 61.965485],
              [142.673781, 61.947024],
              [142.617375, 61.939848],
              [142.569167, 61.927841],
              [142.555523, 61.912663],
              [142.518457, 61.907066],
              [142.467313, 61.891966],
              [142.471657, 61.884928],
              [142.44244, 61.873455],
              [142.421049, 61.88267],
              [142.42107, 61.895734],
              [142.393293, 61.90529],
              [142.424474, 61.920605],
              [142.41709, 61.943794],
              [142.397509, 61.958573],
              [142.375686, 61.954798],
              [142.329519, 61.967712],
              [142.318117, 61.956153],
              [142.292738, 61.968122],
              [142.265991, 61.9665],
              [142.28246, 61.990797],
              [142.255451, 62.005627],
              [142.266965, 62.017924],
              [142.181468, 62.044696],
              [142.16952, 62.060032],
              [142.181286, 62.069774],
              [142.14532, 62.07551],
              [142.108654, 62.067137],
              [142.081733, 62.053158],
              [142.061563, 62.033485],
              [142.045886, 62.043576],
              [142.022489, 62.037788],
              [142.00171, 62.020176],
              [141.957493, 62.039296],
              [141.903894, 62.035321],
              [141.897412, 62.021338],
              [141.84925, 62.0154],
              [141.833325, 62.017922],
              [141.84241, 62.041912],
              [141.83174, 62.054842],
              [141.852298, 62.060914],
              [141.85493, 62.082662],
              [141.823296, 62.083877],
              [141.778878, 62.076295],
              [141.729374, 62.078307],
              [141.708222, 62.103302],
              [141.647994, 62.115758],
              [141.626925, 62.131674],
              [141.632858, 62.141878],
              [141.607506, 62.151737],
              [141.572829, 62.149282],
              [141.559283, 62.155529],
              [141.54525, 62.18069],
              [141.53986, 62.235773],
              [141.5269, 62.255515],
              [141.50437, 62.26778],
              [141.492869, 62.286735],
              [141.456588, 62.285214],
              [141.421017, 62.302541],
              [141.420903, 62.318754],
              [141.391841, 62.323614],
              [141.391133, 62.340882],
              [141.363378, 62.345377],
              [141.322277, 62.387475],
              [141.327896, 62.397646],
              [141.314379, 62.416314],
              [141.279787, 62.400903],
              [141.21822, 62.399386],
              [141.17455, 62.416705],
              [141.161606, 62.403162],
              [141.12933, 62.396668],
              [141.128164, 62.404761],
              [141.07591, 62.433207],
              [141.08102, 62.44442],
              [141.068185, 62.460944],
              [141.07842, 62.465083],
              [141.061325, 62.489834],
              [141.03792, 62.49252],
              [140.994842, 62.475898],
              [140.973947, 62.483573],
              [140.95661, 62.500818],
              [140.889114, 62.496533],
              [140.879461, 62.516447],
              [140.845978, 62.53015],
              [140.81124, 62.522479],
              [140.790861, 62.526497],
              [140.74471, 62.513493],
              [140.737663, 62.489801],
              [140.705446, 62.483149],
              [140.73082, 62.467138],
              [140.72409, 62.453594],
              [140.693738, 62.448448],
              [140.69751, 62.43857],
              [140.666065, 62.416365],
              [140.628272, 62.403972],
              [140.610694, 62.413204],
              [140.579632, 62.415275],
              [140.54565, 62.408606],
              [140.535839, 62.414131],
              [140.48202, 62.416702],
              [140.46521, 62.409171],
              [140.422095, 62.416213],
              [140.395049, 62.404049],
              [140.391467, 62.378302],
              [140.359918, 62.378813],
              [140.337705, 62.353838],
              [140.312081, 62.341879],
              [140.319696, 62.320276],
              [140.30686, 62.29327],
              [140.318535, 62.284846],
              [140.291707, 62.268197],
              [140.28359, 62.244521],
              [140.289673, 62.234111],
              [140.33532, 62.219561],
              [140.31687, 62.214022],
              [140.312693, 62.196089],
              [140.333334, 62.187437],
              [140.320568, 62.171382],
              [140.32718, 62.151843],
              [140.311191, 62.123116],
              [140.313853, 62.109324],
              [140.256402, 62.090872],
              [140.24132, 62.068294],
              [140.265695, 62.063973],
              [140.275988, 62.037808],
              [140.237099, 62.018818],
              [140.193451, 62.012437],
              [140.175297, 62.015144],
              [140.116334, 61.968794],
              [140.096414, 61.977448],
              [140.062635, 61.977386],
              [140.030227, 61.987143],
              [139.99022, 61.977711],
              [139.980514, 61.957772],
              [139.952546, 61.936529],
              [139.967351, 61.916537],
              [139.958918, 61.901133],
              [139.980066, 61.876091],
              [139.977237, 61.860247],
              [139.927104, 61.795297],
              [139.882064, 61.776085],
              [139.8521, 61.735086],
              [139.824067, 61.720311],
              [139.802106, 61.716978],
              [139.780897, 61.694221],
              [139.787808, 61.683865],
              [139.769855, 61.656403],
              [139.775756, 61.639539],
              [139.731738, 61.634646],
              [139.732754, 61.65924],
              [139.692293, 61.662242],
              [139.672261, 61.639014],
              [139.664966, 61.605594],
              [139.64859, 61.562251],
              [139.60984, 61.533169],
              [139.58998, 61.507548],
              [139.565293, 61.493745],
              [139.55376, 61.473609],
              [139.49708, 61.465204],
              [139.489168, 61.4776],
              [139.452056, 61.485956],
              [139.450048, 61.494528],
              [139.421749, 61.48629],
              [139.389311, 61.486507],
              [139.396751, 61.465213],
              [139.381519, 61.470046],
              [139.342592, 61.448575],
              [139.325365, 61.449778],
              [139.316815, 61.435597],
              [139.280331, 61.427652],
              [139.22169, 61.434719],
              [139.199463, 61.421789],
              [139.13744, 61.425831],
              [139.126827, 61.432874],
              [139.091365, 61.435135],
              [139.055351, 61.431278],
              [139.058469, 61.405616],
              [139.076006, 61.393615],
              [139.060415, 61.363339],
              [139.039404, 61.364679],
              [139.039548, 61.345647],
              [138.982414, 61.326873],
              [138.97238, 61.316048],
              [138.941172, 61.327542],
              [138.913598, 61.318768],
              [138.902048, 61.3323],
              [138.831063, 61.330637],
              [138.809022, 61.319307],
              [138.770495, 61.313665],
              [138.734927, 61.336884],
              [138.688467, 61.307003],
              [138.649138, 61.297307],
              [138.636763, 61.282242],
              [138.63744, 61.263558],
              [138.657732, 61.2491],
              [138.651092, 61.226987],
              [138.671659, 61.224647],
              [138.702808, 61.20852],
              [138.659701, 61.20452],
              [138.574067, 61.163295],
              [138.555849, 61.151232],
              [138.521881, 61.142254],
              [138.481323, 61.141343],
              [138.45702, 61.119319],
              [138.397131, 61.099381],
              [138.336459, 61.107432],
              [138.325686, 61.080891],
              [138.326384, 61.057158],
              [138.36053, 61.044959],
              [138.34714, 61.022645],
              [138.359881, 61.005269],
              [138.34398, 60.977802],
              [138.319109, 60.965698],
              [138.269092, 60.954915],
              [138.249141, 60.927106],
              [138.26168, 60.92074],
              [138.22958, 60.895601],
              [138.234533, 60.885437],
              [138.206922, 60.857035],
              [138.23419, 60.826663],
              [138.270196, 60.827573],
              [138.283249, 60.81715],
              [138.27044, 60.808488],
              [138.271806, 60.793298],
              [138.305303, 60.776131],
              [138.316269, 60.759931],
              [138.314729, 60.736787],
              [138.352726, 60.735604],
              [138.397526, 60.723003],
              [138.397671, 60.712894],
              [138.428956, 60.703656],
              [138.409833, 60.693742],
              [138.391856, 60.670421],
              [138.404109, 60.652866],
              [138.396839, 60.620654],
              [138.383449, 60.611781],
              [138.346549, 60.61164],
              [138.331997, 60.604217],
              [138.342413, 60.585708],
              [138.3283, 60.571854],
              [138.35997, 60.562798],
              [138.338562, 60.541402],
              [138.331848, 60.512503],
              [138.316538, 60.491307],
              [138.268057, 60.482459],
              [138.316753, 60.426214],
              [138.30414, 60.412142],
              [138.33563, 60.401549],
              [138.313925, 60.387825],
              [138.308435, 60.370427],
              [138.322441, 60.333853],
              [138.306788, 60.313082],
              [138.24477, 60.295472],
              [138.243668, 60.281947],
              [138.21322, 60.277813],
              [138.218639, 60.256284],
              [138.178408, 60.20899],
              [138.183612, 60.196145],
              [138.210739, 60.195193],
              [138.239475, 60.173035],
              [138.21689, 60.156824],
              [138.227166, 60.149738],
              [138.211654, 60.101969],
              [138.241172, 60.092213],
              [138.26242, 60.068805],
              [138.236042, 60.036286],
              [138.230908, 60.015369],
              [138.233283, 59.984511],
              [138.22523, 59.972463],
              [138.246993, 59.970681],
              [138.268721, 59.945713],
              [138.262393, 59.930771],
              [138.231852, 59.93093],
              [138.21251, 59.91464],
              [138.194355, 59.91419],
              [138.186291, 59.883179],
              [138.19466, 59.866927],
              [138.17898, 59.838894],
              [138.17691, 59.822122],
              [138.21974, 59.814601],
              [138.232334, 59.748429],
              [138.22002, 59.730602],
              [138.267109, 59.722185],
              [138.272096, 59.705773],
              [138.232169, 59.688217],
              [138.210209, 59.683585],
              [138.21759, 59.653979],
              [138.16908, 59.635249],
              [138.144453, 59.644283],
              [138.12539, 59.638795],
              [138.105984, 59.646032],
              [138.072804, 59.640158],
              [138.047467, 59.645564],
              [138.022098, 59.667668],
              [138.028721, 59.699374],
              [138.05174, 59.709361],
              [138.050514, 59.724308],
              [138.080477, 59.74679],
              [138.061788, 59.770348],
              [138.034008, 59.782405],
              [138.046915, 59.803682],
              [138.031175, 59.824763],
              [137.988064, 59.81245],
              [137.968194, 59.792038],
              [137.97434, 59.772345],
              [137.95199, 59.762151],
              [137.920366, 59.76161],
              [137.89877, 59.740811],
              [137.860995, 59.7562],
              [137.818526, 59.758248],
              [137.727178, 59.746032],
              [137.721515, 59.754589],
              [137.650391, 59.747367],
              [137.631978, 59.751303],
              [137.557797, 59.729889],
              [137.555677, 59.703664],
              [137.5266, 59.686103],
              [137.491184, 59.680838],
              [137.48843, 59.66298],
              [137.449811, 59.645583],
              [137.457479, 59.638071],
              [137.449793, 59.613138],
              [137.463238, 59.599753],
              [137.448996, 59.592532],
              [137.484382, 59.586042],
              [137.501406, 59.558085],
              [137.464461, 59.550823],
              [137.445322, 59.537099],
              [137.412171, 59.536585],
              [137.359772, 59.541879],
              [137.332057, 59.530313],
              [137.326398, 59.509319],
              [137.294906, 59.494901],
              [137.258171, 59.490787],
              [137.218749, 59.4794],
              [137.178614, 59.440205],
              [137.154117, 59.436571],
              [137.117116, 59.451139],
              [137.083397, 59.445562],
              [137.064595, 59.431897],
              [137.026074, 59.425489],
              [137.00541, 59.435164],
              [136.959364, 59.425584],
              [136.90915, 59.405651],
              [136.861098, 59.398562],
              [136.821174, 59.388431],
              [136.801104, 59.372371],
              [136.795411, 59.35718],
              [136.768574, 59.337881],
              [136.72633, 59.351102],
              [136.6729, 59.345857],
              [136.654064, 59.350732],
              [136.612259, 59.379357],
              [136.586397, 59.381576],
              [136.55692, 59.368504],
              [136.520344, 59.373094],
              [136.49855, 59.384844],
              [136.444841, 59.39246],
              [136.399989, 59.3838],
              [136.346825, 59.393925],
              [136.33782, 59.413681],
              [136.317351, 59.42381],
              [136.2745, 59.419008],
              [136.26194, 59.425962],
              [136.23986, 59.412094],
              [136.21046, 59.420925],
              [136.15826, 59.421636],
              [136.12014, 59.414602],
              [136.111533, 59.425725],
              [136.08988, 59.419551],
              [136.070977, 59.429925],
              [136.059743, 59.447244],
              [136.022941, 59.453181],
              [135.98686, 59.473552],
              [135.971292, 59.472243],
              [135.965809, 59.456007],
              [135.947567, 59.458813],
              [135.909163, 59.480115],
              [135.889517, 59.496764],
              [135.890556, 59.514043],
              [135.826448, 59.526107],
              [135.769162, 59.521347],
              [135.73732, 59.503295],
              [135.701028, 59.49993],
              [135.689987, 59.508543],
              [135.66232, 59.498231],
              [135.613066, 59.487839],
              [135.594593, 59.466778],
              [135.551849, 59.450927],
              [135.50804, 59.439284],
              [135.476391, 59.414049],
              [135.437829, 59.396957],
              [135.43587, 59.380391],
              [135.420443, 59.366186],
              [135.374108, 59.343398],
              [135.349139, 59.335389],
              [135.362486, 59.311354],
              [135.327001, 59.294163],
              [135.305126, 59.276336],
              [135.29629, 59.24826],
              [135.26093, 59.23396],
              [135.264922, 59.225636],
              [135.30238, 59.202744],
              [135.28806, 59.193331],
              [135.2383, 59.180245],
              [135.25053, 59.159491],
              [135.24807, 59.139061],
              [135.228102, 59.14379],
              [135.203196, 59.135634],
              [135.193251, 59.114383],
              [135.14393, 59.114982],
              [135.129173, 59.123017],
              [135.10225, 59.122982],
              [135.070293, 59.146526],
              [135.052726, 59.151643],
              [135.02601, 59.145711],
              [134.988606, 59.164912],
              [134.932373, 59.169826],
              [134.92199, 59.156692],
              [134.890082, 59.152452],
              [134.842509, 59.136602],
              [134.80816, 59.140001],
              [134.79873, 59.152045],
              [134.733111, 59.157808],
              [134.7221, 59.175071],
              [134.736352, 59.187575],
              [134.716415, 59.2047],
              [134.722557, 59.218614],
              [134.66857, 59.205716],
              [134.65486, 59.198671],
              [134.619074, 59.209323],
              [134.597844, 59.202131],
              [134.562509, 59.203086],
              [134.527989, 59.186748],
              [134.499186, 59.157457],
              [134.499409, 59.142191],
              [134.483379, 59.139514],
              [134.43971, 59.166065],
              [134.38892, 59.172668],
              [134.377232, 59.185384],
              [134.38773, 59.193869],
              [134.365691, 59.202258],
              [134.303705, 59.203486],
              [134.241695, 59.210743],
              [134.213563, 59.220021],
              [134.183703, 59.21853],
              [134.184975, 59.22834],
              [134.153547, 59.22903],
              [134.122666, 59.239994],
              [134.102972, 59.234929],
              [134.077362, 59.256017],
              [134.044984, 59.256496],
              [134.00117, 59.26333],
              [133.993286, 59.255428],
              [133.957765, 59.256217],
              [133.938569, 59.250537],
              [133.869044, 59.259041],
              [133.813366, 59.245417],
              [133.78608, 59.242967],
              [133.747541, 59.228344],
              [133.718346, 59.234119],
              [133.67724, 59.229846],
              [133.629472, 59.232917],
              [133.626456, 59.241618],
              [133.572488, 59.251018],
              [133.573378, 59.293565],
              [133.51856, 59.312262],
              [133.464108, 59.304017],
              [133.465973, 59.296137],
              [133.413022, 59.275897],
              [133.376802, 59.27349],
              [133.3689, 59.249389],
              [133.337031, 59.25356],
              [133.305743, 59.234144],
              [133.271917, 59.226689],
              [133.23506, 59.233383],
              [133.218423, 59.229948],
              [133.209436, 59.244381],
              [133.170973, 59.24141],
              [133.172857, 59.225692],
              [133.159038, 59.204642],
              [133.10398, 59.215674],
              [133.081235, 59.197575],
              [133.051638, 59.19458],
              [133.041852, 59.1794],
              [133.017638, 59.165723],
              [132.988955, 59.158992],
              [132.961538, 59.13753],
              [132.959967, 59.121165],
              [132.929453, 59.103984],
              [132.896474, 59.101137],
              [132.8647, 59.117909],
              [132.829636, 59.118754],
              [132.816881, 59.092156],
              [132.83699, 59.06807],
              [132.822336, 59.060754],
              [132.81975, 59.04324],
              [132.79922, 59.041526],
              [132.756228, 59.025339],
              [132.765002, 59.007366],
              [132.80827, 58.997135],
              [132.844908, 58.993174],
              [132.843873, 58.976578],
              [132.820145, 58.964118],
              [132.789074, 58.928292],
              [132.777245, 58.887027],
              [132.74635, 58.880192],
              [132.703116, 58.886562],
              [132.668123, 58.910123],
              [132.635521, 58.891286],
              [132.588076, 58.891657],
              [132.558718, 58.871613],
              [132.554992, 58.846344],
              [132.52548, 58.834074],
              [132.534671, 58.820878],
              [132.520134, 58.807822],
              [132.48851, 58.795264],
              [132.53667, 58.781395],
              [132.527413, 58.752215],
              [132.544862, 58.730203],
              [132.518315, 58.704971],
              [132.504695, 58.676655],
              [132.546511, 58.659689],
              [132.511525, 58.641663],
              [132.505111, 58.6309],
              [132.443672, 58.60246],
              [132.447914, 58.591133],
              [132.430068, 58.578355],
              [132.386507, 58.561251],
              [132.387727, 58.544871],
              [132.427658, 58.538308],
              [132.430917, 58.510854],
              [132.36222, 58.519684],
              [132.319044, 58.516768],
              [132.30206, 58.53674],
              [132.282621, 58.527949],
              [132.243122, 58.527345],
              [132.20998, 58.519674],
              [132.18708, 58.528982],
              [132.139929, 58.514862],
              [132.175477, 58.489555],
              [132.185, 58.469852],
              [132.186351, 58.443014],
              [132.170937, 58.430252],
              [132.196828, 58.396821],
              [132.226305, 58.386228],
              [132.212643, 58.362869],
              [132.229369, 58.342844],
              [132.22858, 58.331669],
              [132.205794, 58.306802],
              [132.210888, 58.288011],
              [132.196821, 58.274626],
              [132.198077, 58.254443],
              [132.131227, 58.279292],
              [132.111436, 58.27843],
              [132.073062, 58.245695],
              [132.047651, 58.229951],
              [132.014457, 58.20118],
              [132.034414, 58.186011],
              [132.005107, 58.178489],
              [131.985865, 58.158537],
              [131.99847, 58.150393],
              [132.029399, 58.146302],
              [132.104785, 58.122059],
              [132.1228, 58.102078],
              [132.144236, 58.102204],
              [132.137239, 58.08023],
              [132.090796, 58.069012],
              [132.066274, 58.049308],
              [132.021543, 58.057305],
              [131.984823, 58.05169],
              [131.979986, 58.079754],
              [131.886851, 58.119543],
              [131.899933, 58.129314],
              [131.876158, 58.135154],
              [131.863, 58.149277],
              [131.828724, 58.16674],
              [131.798024, 58.162822],
              [131.769774, 58.151824],
              [131.750023, 58.158754],
              [131.740003, 58.180971],
              [131.70549, 58.201191],
              [131.674061, 58.201267],
              [131.66815, 58.209021],
              [131.593139, 58.198359],
              [131.556726, 58.211532],
              [131.50224, 58.198514],
              [131.477286, 58.203714],
              [131.453301, 58.196866],
              [131.444274, 58.182403],
              [131.460153, 58.166493],
              [131.495204, 58.170494],
              [131.52336, 58.1322],
              [131.528286, 58.110512],
              [131.565695, 58.110516],
              [131.583217, 58.087085],
              [131.62013, 58.088486],
              [131.644071, 58.079844],
              [131.632881, 58.046134],
              [131.580677, 58.038557],
              [131.569036, 58.031347],
              [131.584748, 58.019402],
              [131.61859, 58.011091],
              [131.641358, 57.993719],
              [131.65645, 57.992809],
              [131.69544, 57.97183],
              [131.70772, 57.950209],
              [131.777842, 57.940176],
              [131.799213, 57.932452],
              [131.790738, 57.910954],
              [131.796466, 57.901824],
              [131.747755, 57.888456],
              [131.760145, 57.871115],
              [131.815722, 57.868479],
              [131.8415, 57.848018],
              [131.877758, 57.847845],
              [131.879332, 57.815483],
              [131.902646, 57.811239],
              [131.916428, 57.797247],
              [131.958236, 57.789994],
              [132.010458, 57.754854],
              [132.023597, 57.751953],
              [132.050971, 57.731251],
              [132.047152, 57.711306],
              [132.061857, 57.70392],
              [132.068427, 57.68172],
              [132.028038, 57.666687],
              [131.990664, 57.665197],
              [131.96994, 57.63569],
              [131.942162, 57.616709],
              [131.849116, 57.622865],
              [131.782979, 57.600091],
              [131.760622, 57.580018],
              [131.724049, 57.578063],
              [131.72291, 57.56858],
              [131.780773, 57.538616],
              [131.749743, 57.529496],
              [131.729471, 57.501621],
              [131.703289, 57.496777],
              [131.68317, 57.479958],
              [131.659488, 57.475764],
              [131.637851, 57.461394],
              [131.625617, 57.44091],
              [131.60628, 57.4282],
              [131.602788, 57.380298],
              [131.605674, 57.356235],
              [131.62714, 57.340519],
              [131.6117, 57.329838],
              [131.608751, 57.309362],
              [131.588436, 57.30685],
              [131.557366, 57.315993],
              [131.51019, 57.294236],
              [131.508821, 57.268152],
              [131.487912, 57.26412],
              [131.465957, 57.276765],
              [131.420455, 57.27952],
              [131.370607, 57.275056],
              [131.325463, 57.285161],
              [131.319267, 57.302107],
              [131.305023, 57.30609],
              [131.292271, 57.334498],
              [131.279232, 57.336195],
              [131.255328, 57.29879],
              [131.232327, 57.276633],
              [131.200708, 57.259563],
              [131.141993, 57.270024],
              [131.12295, 57.251713],
              [131.143447, 57.232004],
              [131.193769, 57.213022],
              [131.202333, 57.20329],
              [131.18971, 57.184351],
              [131.19229, 57.167836],
              [131.232651, 57.163881],
              [131.23455, 57.179639],
              [131.248833, 57.193211],
              [131.313257, 57.192029],
              [131.333482, 57.186913],
              [131.332037, 57.157313],
              [131.350222, 57.15047],
              [131.389647, 57.148995],
              [131.406821, 57.134384],
              [131.4323, 57.137788],
              [131.465111, 57.111482],
              [131.494191, 57.111407],
              [131.506517, 57.08963],
              [131.487935, 57.095532],
              [131.469062, 57.083762],
              [131.473271, 57.060153],
              [131.507657, 57.04964],
              [131.4989, 57.030247],
              [131.510433, 57.018914],
              [131.53427, 57.026356],
              [131.587089, 56.977716],
              [131.608717, 56.978186],
              [131.614759, 56.966813],
              [131.607381, 56.949785],
              [131.638126, 56.952756],
              [131.634783, 56.940357],
              [131.661892, 56.930381],
              [131.66088, 56.907639],
              [131.679719, 56.892562],
              [131.707992, 56.895986],
              [131.720687, 56.862724],
              [131.734877, 56.85935],
              [131.759408, 56.868136],
              [131.777026, 56.85771],
              [131.773785, 56.834853],
              [131.811644, 56.819534],
              [131.79451, 56.813208],
              [131.809684, 56.77245],
              [131.835872, 56.771393],
              [131.831681, 56.742746],
              [131.804928, 56.73796],
              [131.776829, 56.74895],
              [131.774384, 56.740859],
              [131.802934, 56.723221],
              [131.784409, 56.714717],
              [131.75214, 56.72274],
              [131.76587, 56.711561],
              [131.736786, 56.700277],
              [131.722274, 56.686933],
              [131.700781, 56.690527],
              [131.68148, 56.683889],
              [131.681289, 56.672586],
              [131.720395, 56.665584],
              [131.71905, 56.658784],
              [131.671966, 56.647742],
              [131.690424, 56.627643],
              [131.667007, 56.617286],
              [131.69494, 56.617854],
              [131.709222, 56.603398],
              [131.69962, 56.565521],
              [131.646368, 56.536917],
              [131.69251, 56.526286],
              [131.710913, 56.501021],
              [131.751685, 56.492245],
              [131.725008, 56.470715],
              [131.695326, 56.473014],
              [131.695851, 56.489168],
              [131.682475, 56.49617],
              [131.665751, 56.478928],
              [131.66254, 56.459318],
              [131.648413, 56.455436],
              [131.635363, 56.472114],
              [131.617775, 56.476977],
              [131.63506, 56.523971],
              [131.62874, 56.529793],
              [131.591261, 56.527464],
              [131.56642, 56.532595],
              [131.564234, 56.551999],
              [131.541156, 56.548214],
              [131.501157, 56.564587],
              [131.48688, 56.552468],
              [131.45285, 56.546743],
              [131.435655, 56.538401],
              [131.437323, 56.521797],
              [131.408278, 56.509243],
              [131.41754, 56.490896],
              [131.437601, 56.487905],
              [131.450872, 56.464378],
              [131.422284, 56.45309],
              [131.403556, 56.461469],
              [131.383884, 56.46048],
              [131.387256, 56.476993],
              [131.374186, 56.478812],
              [131.389722, 56.495145],
              [131.350384, 56.516237],
              [131.339013, 56.528154],
              [131.332727, 56.51304],
              [131.288813, 56.522084],
              [131.273297, 56.515731],
              [131.222116, 56.520844],
              [131.2156, 56.506312],
              [131.22548, 56.495728],
              [131.191349, 56.480053],
              [131.187366, 56.458697],
              [131.168599, 56.450922],
              [131.167571, 56.435544],
              [131.134938, 56.41859],
              [131.103629, 56.409936],
              [131.100653, 56.379269],
              [131.114135, 56.370057],
              [131.119877, 56.335738],
              [131.137156, 56.329351],
              [131.124973, 56.314347],
              [131.140401, 56.290778],
              [131.120136, 56.248448],
              [131.073342, 56.209382],
              [131.0431, 56.191435],
              [131.02717, 56.188264],
              [130.991287, 56.167585],
              [130.971793, 56.137579],
              [130.98006, 56.107148],
              [130.971521, 56.080218],
              [130.94703, 56.069564],
              [130.934076, 56.04176],
              [130.896693, 56.018642],
              [130.900451, 56.000446],
              [130.92738, 55.982601],
              [130.919627, 55.972647],
              [130.93553, 55.963596],
              [130.91676, 55.951553],
              [130.896825, 55.948569],
              [130.86022, 55.915099],
              [130.88634, 55.90152],
              [130.885771, 55.881929],
              [130.89742, 55.853822],
              [130.891943, 55.842574],
              [130.931467, 55.831635],
              [130.94079, 55.819272],
              [130.957815, 55.816086],
              [130.964553, 55.800008],
              [130.953358, 55.78679],
              [130.95825, 55.775232],
              [130.943203, 55.753498],
              [130.943834, 55.736267],
              [130.9625, 55.724033],
              [130.952768, 55.712176],
              [130.902987, 55.715595],
              [130.89182, 55.706792],
              [130.85008, 55.712481],
              [130.83825, 55.7296],
              [130.809393, 55.727473],
              [130.809451, 55.735556],
              [130.736202, 55.753972],
              [130.711726, 55.753483],
              [130.680542, 55.744046],
              [130.652523, 55.75679],
              [130.624824, 55.76245],
              [130.549048, 55.739176],
              [130.524125, 55.737705],
              [130.504554, 55.727425],
              [130.483496, 55.733953],
              [130.467143, 55.730523],
              [130.438768, 55.743758],
              [130.38085, 55.747751],
              [130.35538, 55.745717],
              [130.353182, 55.73094],
              [130.29919, 55.732237],
              [130.287782, 55.740975],
              [130.239298, 55.730478],
              [130.231634, 55.724611],
              [130.126605, 55.712721],
              [130.121201, 55.726204],
              [130.091433, 55.731346],
              [130.067504, 55.711407],
              [130.04866, 55.719237],
              [130.00109, 55.71328],
              [129.998493, 55.72293],
              [129.972114, 55.737823],
              [129.964304, 55.754349],
              [129.903307, 55.77829],
              [129.871608, 55.751951],
              [129.870931, 55.740484],
              [129.840021, 55.743493],
              [129.80005, 55.757133],
              [129.788367, 55.773613],
              [129.72958, 55.770945],
              [129.711057, 55.761009],
              [129.685133, 55.767456],
              [129.659195, 55.786023],
              [129.640571, 55.769539],
              [129.617794, 55.762642],
              [129.584685, 55.762091],
              [129.525931, 55.743516],
              [129.488847, 55.760802],
              [129.467555, 55.754544],
              [129.455177, 55.729946],
              [129.421298, 55.723966],
              [129.383014, 55.732466],
              [129.375248, 55.718552],
              [129.351993, 55.713298],
              [129.341944, 55.727383],
              [129.285711, 55.719631],
              [129.262471, 55.730629],
              [129.234483, 55.712442],
              [129.174343, 55.698186],
              [129.151389, 55.705813],
              [129.105932, 55.701614],
              [129.097336, 55.695221],
              [129.05084, 55.69489],
              [129.043729, 55.67958],
              [129.06835, 55.651575],
              [129.071706, 55.637336],
              [129.09908, 55.631036],
              [129.095209, 55.561352],
              [129.100958, 55.539514],
              [129.07264, 55.513753],
              [129.022033, 55.503364],
              [129.011492, 55.497503],
              [128.95687, 55.494389],
              [128.94607, 55.505558],
              [128.90301, 55.513615],
              [128.873512, 55.50075],
              [128.852259, 55.499776],
              [128.830079, 55.520015],
              [128.821253, 55.502528],
              [128.785939, 55.487671],
              [128.769486, 55.490851],
              [128.76119, 55.511876],
              [128.718942, 55.517492],
              [128.703037, 55.515137],
              [128.687501, 55.532573],
              [128.703782, 55.541469],
              [128.70163, 55.562326],
              [128.682784, 55.571789],
              [128.634208, 55.54984],
              [128.581716, 55.556448],
              [128.55547, 55.549877],
              [128.54085, 55.554281],
              [128.512443, 55.544781],
              [128.48832, 55.5562],
              [128.491531, 55.583673],
              [128.475602, 55.597812],
              [128.45012, 55.603465],
              [128.422718, 55.624975],
              [128.381239, 55.630716],
              [128.329241, 55.673356],
              [128.290426, 55.696913],
              [128.285067, 55.6907],
              [128.237514, 55.690296],
              [128.225972, 55.699317],
              [128.200153, 55.700994],
              [128.19113, 55.689404],
              [128.15987, 55.685034],
              [128.11394, 55.687564],
              [128.063898, 55.668232],
              [128.044954, 55.677478],
              [127.924375, 55.662004],
              [127.879384, 55.668912],
              [127.85497, 55.662208],
              [127.80183, 55.666932],
              [127.787496, 55.674756],
              [127.803144, 55.692801],
              [127.784796, 55.714376],
              [127.789476, 55.723041],
              [127.764406, 55.723207],
              [127.733709, 55.731276],
              [127.706955, 55.728025],
              [127.677468, 55.715407],
              [127.667205, 55.701387],
              [127.641861, 55.69463],
              [127.637135, 55.679242],
              [127.542464, 55.66484],
              [127.569845, 55.643584],
              [127.573759, 55.631325],
              [127.599909, 55.602203],
              [127.589912, 55.594064],
              [127.565807, 55.595864],
              [127.541243, 55.607166],
              [127.513818, 55.604875],
              [127.495818, 55.588953],
              [127.458775, 55.57803],
              [127.423289, 55.57853],
              [127.420998, 55.591162],
              [127.378081, 55.608614],
              [127.34013, 55.61952],
              [127.348407, 55.635027],
              [127.338702, 55.645834],
              [127.315427, 55.649321],
              [127.327396, 55.659531],
              [127.321454, 55.687805],
              [127.274393, 55.688341],
              [127.260636, 55.680598],
              [127.231959, 55.700578],
              [127.186956, 55.717696],
              [127.161185, 55.696863],
              [127.134852, 55.69284],
              [127.089252, 55.702066],
              [127.061432, 55.69414],
              [126.966224, 55.699928],
              [126.950983, 55.689832],
              [126.934872, 55.690885],
              [126.906903, 55.676552],
              [126.923687, 55.661263],
              [126.926225, 55.629892],
              [126.86906, 55.620647],
              [126.857229, 55.611502],
              [126.813424, 55.617097],
              [126.787303, 55.627425],
              [126.758137, 55.619282],
              [126.72505, 55.599593],
              [126.682276, 55.633006],
              [126.699115, 55.655458],
              [126.692811, 55.666712],
              [126.635537, 55.660751],
              [126.608274, 55.653765],
              [126.582764, 55.654335],
              [126.562135, 55.641494],
              [126.543767, 55.640718],
              [126.537325, 55.625262],
              [126.51104, 55.612279],
              [126.533219, 55.593679],
              [126.529871, 55.579799],
              [126.503095, 55.583304],
              [126.492168, 55.569373],
              [126.414418, 55.560028],
              [126.387446, 55.573386],
              [126.385915, 55.5946],
              [126.361743, 55.596872],
              [126.336377, 55.583887],
              [126.317651, 55.584693],
              [126.2966, 55.609109],
              [126.261561, 55.595834],
              [126.235033, 55.607837],
              [126.201788, 55.602109],
              [126.182955, 55.59136],
              [126.096092, 55.58978],
              [126.077209, 55.59537],
              [126.070296, 55.611719],
              [126.080632, 55.617584],
              [126.065303, 55.632538],
              [126.06703, 55.64522],
              [126.115326, 55.650901],
              [126.133551, 55.638127],
              [126.141116, 55.651194],
              [126.131362, 55.667145],
              [126.087762, 55.667953],
              [126.080725, 55.690846],
              [126.067587, 55.707231],
              [126.091726, 55.711501],
              [126.04385, 55.731073],
              [126.004957, 55.72002],
              [125.98538, 55.72026],
              [125.995309, 55.746717],
              [125.993836, 55.767387],
              [125.931157, 55.774072],
              [125.87371, 55.794755],
              [125.838889, 55.803592],
              [125.803634, 55.806601],
              [125.781357, 55.799199],
              [125.764081, 55.804167],
              [125.735754, 55.797666],
              [125.697082, 55.800126],
              [125.683073, 55.786758],
              [125.666438, 55.793482],
              [125.651055, 55.784539],
              [125.616844, 55.779446],
              [125.601067, 55.761171],
              [125.581877, 55.758009],
              [125.565884, 55.739259],
              [125.531076, 55.746937],
              [125.502518, 55.773386],
              [125.509813, 55.785128],
              [125.488188, 55.79836],
              [125.466168, 55.798982],
              [125.444881, 55.816352],
              [125.448967, 55.851111],
              [125.420122, 55.869934],
              [125.391106, 55.876089],
              [125.329996, 55.874587],
              [125.295861, 55.885692],
              [125.291324, 55.896656],
              [125.253349, 55.907761],
              [125.221944, 55.895312],
              [125.207932, 55.905671],
              [125.200173, 55.897606],
              [125.159567, 55.896125],
              [125.139843, 55.88571],
              [125.084625, 55.879702],
              [125.052581, 55.859672],
              [125.010704, 55.869518],
              [124.977072, 55.860028],
              [124.977974, 55.842417],
              [124.964315, 55.837642],
              [124.941639, 55.84472],
              [124.91107, 55.84187],
              [124.869766, 55.861765],
              [124.839558, 55.864479],
              [124.834786, 55.882086],
              [124.784233, 55.882057],
              [124.779326, 55.869585],
              [124.761685, 55.869899],
              [124.724984, 55.907689],
              [124.702015, 55.924475],
              [124.660585, 55.92611],
              [124.639793, 55.937683],
              [124.559345, 55.89908],
              [124.549615, 55.888563],
              [124.531373, 55.892655],
              [124.505887, 55.884648],
              [124.485281, 55.891622],
              [124.450518, 55.91813],
              [124.41842, 55.920287],
              [124.414149, 55.930917],
              [124.42344, 55.953278],
              [124.388335, 55.993621],
              [124.396501, 56.020098],
              [124.394278, 56.052636],
              [124.371259, 56.05646],
              [124.348449, 56.072462],
              [124.315882, 56.071031],
              [124.301896, 56.078549],
              [124.214495, 56.084136],
              [124.203328, 56.101006],
              [124.224998, 56.110303],
              [124.204821, 56.131779],
              [124.212179, 56.155897],
              [124.193543, 56.166242],
              [124.200088, 56.174559],
              [124.177105, 56.18952],
              [124.176827, 56.197843],
              [124.143874, 56.201065],
              [124.121072, 56.230813],
              [124.080354, 56.232304],
              [124.063521, 56.248675],
              [124.032761, 56.256009],
              [124.016243, 56.289428],
              [123.999553, 56.293768],
              [123.992505, 56.318727],
              [123.969214, 56.336503],
              [123.980676, 56.353708],
              [123.946256, 56.359978],
              [123.935276, 56.379376],
              [123.920806, 56.387865],
              [123.829691, 56.410319],
              [123.808748, 56.418599],
              [123.753002, 56.424536],
              [123.709061, 56.40869],
              [123.683353, 56.403783],
              [123.661978, 56.413467],
              [123.603114, 56.413054],
              [123.572502, 56.409158],
              [123.551251, 56.42215],
              [123.491953, 56.422317],
              [123.434332, 56.419026],
              [123.383251, 56.425375],
              [123.372997, 56.418631],
              [123.348247, 56.420605],
              [123.33599, 56.428959],
              [123.284629, 56.430454],
              [123.232583, 56.443518],
              [123.194051, 56.440672],
              [123.17423, 56.446347],
              [123.166458, 56.460264],
              [123.108703, 56.457535],
              [123.100708, 56.478222],
              [123.058842, 56.487461],
              [123.025769, 56.504112],
              [123.020298, 56.521465],
              [122.99672, 56.525293],
              [122.96832, 56.538372],
              [122.90191, 56.556641],
              [122.894241, 56.566618],
              [122.903993, 56.583978],
              [122.884679, 56.596382],
              [122.834532, 56.5845],
              [122.794468, 56.585153],
              [122.787082, 56.577118],
              [122.800418, 56.559491],
              [122.78839, 56.5357],
              [122.799947, 56.526449],
              [122.790152, 56.508723],
              [122.761357, 56.490427],
              [122.715024, 56.494906],
              [122.689181, 56.502034],
              [122.653487, 56.492313],
              [122.640219, 56.475338],
              [122.610659, 56.488739],
              [122.571597, 56.485402],
              [122.558339, 56.507878],
              [122.544551, 56.510562],
              [122.550283, 56.525678],
              [122.506772, 56.564319],
              [122.46697, 56.571713],
              [122.46076, 56.599617],
              [122.435298, 56.616501],
              [122.386535, 56.625956],
              [122.378095, 56.634413],
              [122.295484, 56.640747],
              [122.278935, 56.660204],
              [122.24674, 56.678377],
              [122.233819, 56.69262],
              [122.179695, 56.70327],
              [122.203664, 56.724403],
              [122.163943, 56.74619],
              [122.134951, 56.746468],
              [122.111409, 56.754088],
              [122.048465, 56.753445],
              [121.989547, 56.762514],
              [121.957703, 56.744126],
              [121.91149, 56.748961],
              [121.885619, 56.73863],
              [121.858054, 56.745453],
              [121.835604, 56.740431],
              [121.761611, 56.754279],
              [121.750533, 56.749057],
              [121.7234, 56.758113],
              [121.683531, 56.7596],
              [121.64543, 56.741957],
              [121.61031, 56.738243],
              [121.60523, 56.761543],
              [121.634175, 56.789855],
              [121.616745, 56.804461],
              [121.609072, 56.821492],
              [121.585622, 56.848448],
              [121.534915, 56.849884],
              [121.511433, 56.870348],
              [121.506818, 56.901732],
              [121.518671, 56.920594],
              [121.486135, 56.953604],
              [121.459334, 56.970512],
              [121.40559, 56.985529],
              [121.345001, 56.984021],
              [121.300256, 56.990032],
              [121.271793, 56.98607],
              [121.233985, 56.995686],
              [121.18389, 56.99315],
              [121.13937, 57.001474],
              [121.095533, 56.981504],
              [121.049646, 57.004354],
              [121.009368, 57.043304],
              [120.965271, 57.042628],
              [120.927393, 57.057979],
              [120.9055, 57.060508],
              [120.897639, 57.048025],
              [120.879772, 57.05667],
              [120.842924, 57.046566],
              [120.806927, 57.042433],
              [120.797514, 57.023984],
              [120.777134, 57.029479],
              [120.738392, 57.026004],
              [120.717652, 57.034532],
              [120.696528, 57.03272],
              [120.666643, 57.044899],
              [120.646918, 57.035128],
              [120.60775, 57.027165],
              [120.564077, 57.035732],
              [120.543639, 57.044459],
              [120.51802, 57.037749],
              [120.498733, 57.041442],
              [120.470286, 57.032363],
              [120.4529, 57.035401],
              [120.425089, 57.028316],
              [120.391607, 57.030655],
              [120.329354, 57.021089],
              [120.291281, 57.026973],
              [120.274705, 57.020748],
              [120.267611, 57.005771],
              [120.243318, 56.999494],
              [120.24095, 56.991053],
              [120.175704, 56.961689],
              [120.125415, 56.952552],
              [120.121285, 56.944807],
              [120.081591, 56.926528],
              [120.051691, 56.926548],
              [120.035444, 56.909939],
              [120.002964, 56.916908],
              [119.907848, 56.908417],
              [119.85614, 56.896079],
              [119.834795, 56.902237],
              [119.805443, 56.932447],
              [119.804702, 56.948635],
              [119.791058, 56.980033],
              [119.767692, 56.989411],
              [119.709825, 56.990381],
              [119.69011, 56.985813],
              [119.645616, 56.997165],
              [119.621578, 57.017655],
              [119.597018, 57.028036],
              [119.592735, 57.057313],
              [119.572568, 57.076454],
              [119.576359, 57.091773],
              [119.592954, 57.09797],
              [119.60854, 57.136346],
              [119.638123, 57.151064],
              [119.659859, 57.168914],
              [119.691375, 57.177932],
              [119.661951, 57.19039],
              [119.626156, 57.22598],
              [119.602753, 57.236038],
              [119.590954, 57.250667],
              [119.60138, 57.275657],
              [119.579385, 57.285978],
              [119.586803, 57.304075],
              [119.5643, 57.318446],
              [119.538448, 57.351368],
              [119.509504, 57.370754],
              [119.477319, 57.370509],
              [119.44365, 57.380061],
              [119.419936, 57.395929],
              [119.421799, 57.418431],
              [119.400462, 57.432688],
              [119.41859, 57.463229],
              [119.392819, 57.485241],
              [119.420623, 57.51872],
              [119.445125, 57.5323],
              [119.467154, 57.533297],
              [119.486432, 57.549482],
              [119.502115, 57.599544],
              [119.480369, 57.608866],
              [119.438155, 57.603646],
              [119.420717, 57.620952],
              [119.33814, 57.619588],
              [119.270363, 57.595776],
              [119.27314, 57.579503],
              [119.246429, 57.541832],
              [119.223145, 57.524324],
              [119.222136, 57.505674],
              [119.174523, 57.519333],
              [119.142199, 57.557559],
              [119.164821, 57.570613],
              [119.179843, 57.589429],
              [119.164033, 57.612644],
              [119.168982, 57.62566],
              [119.141664, 57.649476],
              [119.168664, 57.658518],
              [119.174363, 57.686365],
              [119.166955, 57.718408],
              [119.09677, 57.732012],
              [119.058217, 57.726167],
              [119.043871, 57.747557],
              [119.04583, 57.764522],
              [119.062909, 57.781243],
              [119.08378, 57.815193],
              [119.103373, 57.832283],
              [119.087889, 57.859283],
              [119.091565, 57.913008],
              [119.116039, 57.924726],
              [119.120055, 57.962517],
              [119.110081, 57.969755],
              [119.135, 57.973142],
              [119.137163, 57.988796],
              [119.152364, 58.005861],
              [119.163985, 58.031456],
              [119.184517, 58.044775],
              [119.148284, 58.085329],
              [119.14373, 58.099193],
              [119.159515, 58.109624],
              [119.152198, 58.139107],
              [119.191361, 58.152822],
              [119.187902, 58.16239],
              [119.162911, 58.169353],
              [119.15313, 58.191746],
              [119.133544, 58.206694],
              [119.128751, 58.23007],
              [119.116842, 58.238128],
              [119.133159, 58.273635],
              [119.123641, 58.284831],
              [119.138674, 58.292535],
              [119.1178, 58.325076],
              [119.076844, 58.339019],
              [119.08043, 58.34898],
              [119.063283, 58.363496],
              [119.105559, 58.422259],
              [119.131251, 58.487889],
              [119.120441, 58.516282],
              [119.084596, 58.518191],
              [119.038428, 58.530943],
              [119.016512, 58.52748],
              [118.992228, 58.547655],
              [118.991886, 58.56155],
              [118.962044, 58.566428],
              [118.950581, 58.579385],
              [118.920096, 58.56848],
              [118.887996, 58.569793],
              [118.863517, 58.577428],
              [118.866504, 58.596555],
              [118.850878, 58.603875],
              [118.820862, 58.600121],
              [118.776802, 58.611387],
              [118.754963, 58.629187],
              [118.765925, 58.645577],
              [118.799202, 58.638691],
              [118.81002, 58.644614],
              [118.833945, 58.676304],
              [118.831637, 58.693784],
              [118.861725, 58.731207],
              [118.853443, 58.756212],
              [118.838993, 58.774902],
              [118.87273, 58.820487],
              [118.86805, 58.835519],
              [118.8448, 58.849208],
              [118.833914, 58.870459],
              [118.816317, 58.872379],
              [118.766741, 58.895001],
              [118.748988, 58.934514],
              [118.763634, 58.950214],
              [118.799529, 58.958486],
              [118.827697, 58.946925],
              [118.861275, 58.952058],
              [118.873087, 58.967517],
              [118.872731, 58.991417],
              [118.839269, 59.018397],
              [118.840954, 59.03677],
              [118.819188, 59.050707],
              [118.75967, 59.053004],
              [118.713828, 59.049824],
              [118.696272, 59.057558],
              [118.71524, 59.080634],
              [118.711372, 59.117735],
              [118.689244, 59.150897],
              [118.689436, 59.172683],
              [118.670814, 59.186608],
              [118.698895, 59.213715],
              [118.713848, 59.236206],
              [118.734139, 59.243543],
              [118.733112, 59.255702],
              [118.755055, 59.262437],
              [118.76521, 59.274538],
              [118.801678, 59.270481],
              [118.802725, 59.291277],
              [118.792598, 59.302133],
              [118.836142, 59.317371],
              [118.817384, 59.329585],
              [118.7488, 59.345128],
              [118.763448, 59.370909],
              [118.747832, 59.392291],
              [118.761284, 59.428266],
              [118.737944, 59.435893],
              [118.6773, 59.437983],
              [118.62646, 59.448701],
              [118.614056, 59.461164],
              [118.589584, 59.450808],
              [118.58667, 59.460755],
              [118.553514, 59.471432],
              [118.539956, 59.485821],
              [118.508431, 59.490409],
              [118.496482, 59.498592],
              [118.46811, 59.496393],
              [118.437345, 59.507868],
              [118.405667, 59.510095],
              [118.361812, 59.525624],
              [118.356952, 59.573275],
              [118.346144, 59.615041],
              [118.319667, 59.633078],
              [118.275653, 59.634492],
              [118.244631, 59.650811],
              [118.239862, 59.640404],
              [118.159317, 59.630118],
              [118.121665, 59.640941],
              [118.106235, 59.62572],
              [118.070093, 59.625059],
              [118.052115, 59.642408],
              [118.033995, 59.629318],
              [118.021936, 59.598405],
              [118.03129, 59.575435],
              [117.980631, 59.557683],
              [117.954052, 59.555488],
              [117.938673, 59.54007],
              [117.956534, 59.527564],
              [117.968651, 59.497344],
              [117.930158, 59.482032],
              [117.910902, 59.450702],
              [117.873709, 59.463277],
              [117.826314, 59.445463],
              [117.808439, 59.466853],
              [117.796426, 59.495233],
              [117.783308, 59.558303],
              [117.77047, 59.565891],
              [117.717561, 59.557717],
              [117.645175, 59.540614],
              [117.646238, 59.530348],
              [117.611619, 59.523644],
              [117.583062, 59.508223],
              [117.596393, 59.48191],
              [117.569694, 59.477549],
              [117.497941, 59.492639],
              [117.460532, 59.52151],
              [117.438608, 59.525192],
              [117.382657, 59.520427],
              [117.346171, 59.512934],
              [117.297984, 59.526496],
              [117.24934, 59.513227],
              [117.211949, 59.542428],
              [117.218229, 59.561117],
              [117.193248, 59.582705],
              [117.162368, 59.589584],
              [117.112064, 59.609812],
              [117.160091, 59.634432],
              [117.145768, 59.65392],
              [117.170165, 59.658293],
              [117.205544, 59.681404],
              [117.199169, 59.696965],
              [117.175226, 59.715202],
              [117.209486, 59.729696],
              [117.228182, 59.750281],
              [117.222096, 59.767258],
              [117.232207, 59.773634],
              [117.208511, 59.786204],
              [117.229302, 59.809118],
              [117.210268, 59.846182],
              [117.223793, 59.874169],
              [117.204236, 59.87589],
              [117.147443, 59.8671],
              [117.096402, 59.869285],
              [117.077015, 59.888991],
              [117.046649, 59.905568],
              [117.008568, 59.916842],
              [117.02769, 59.947088],
              [117.056816, 59.951718],
              [117.077473, 59.972628],
              [117.105949, 59.972504],
              [117.132947, 59.980982],
              [117.154029, 59.973846],
              [117.238857, 59.993891],
              [117.246186, 60.001987],
              [117.292275, 60.00819],
              [117.338736, 60.006315],
              [117.35563, 60.011623],
              [117.34719, 60.024205],
              [117.300025, 60.048145],
              [117.264106, 60.057423],
              [117.254433, 60.051843],
              [117.21826, 60.054401],
              [117.181297, 60.049392],
              [117.178079, 60.039178],
              [117.130037, 60.02756],
              [117.103804, 60.027719],
              [117.019565, 60.049938],
              [116.988643, 60.048908],
              [117.00022, 60.091751],
              [117.012285, 60.100972],
              [117.0036, 60.118831],
              [116.972894, 60.139372],
              [116.941196, 60.146179],
              [116.925493, 60.170317],
              [116.937166, 60.19646],
              [116.921234, 60.20398],
              [116.879451, 60.205201],
              [116.829859, 60.226992],
              [116.822579, 60.236478],
              [116.791288, 60.240082],
              [116.770875, 60.249365],
              [116.774809, 60.269127],
              [116.759277, 60.27959],
              [116.688805, 60.289319],
              [116.648943, 60.283744],
              [116.608546, 60.295133],
              [116.6284, 60.319366],
              [116.623588, 60.349739],
              [116.602968, 60.36447],
              [116.594034, 60.382068],
              [116.559706, 60.382033],
              [116.540974, 60.389983],
              [116.464281, 60.386435],
              [116.369779, 60.377006],
              [116.355831, 60.371132],
              [116.324623, 60.374726],
              [116.316204, 60.398011],
              [116.291066, 60.409652],
              [116.1839, 60.394096],
              [116.173857, 60.39729],
              [116.17791, 60.41734],
              [116.167005, 60.429603],
              [116.138555, 60.433993],
              [116.088981, 60.449995],
              [116.084679, 60.456175],
              [116.042146, 60.461727],
              [116.019852, 60.459397],
              [116.005434, 60.469058],
              [115.937639, 60.45355],
              [115.904058, 60.491589],
              [115.863649, 60.496064],
              [115.867206, 60.507642],
              [115.885956, 60.508793],
              [115.872447, 60.523097],
              [115.812823, 60.520504],
              [115.805734, 60.529058],
              [115.758977, 60.521309],
              [115.767473, 60.541896],
              [115.749481, 60.546659],
              [115.696606, 60.549035],
              [115.679674, 60.539498],
              [115.639685, 60.533377],
              [115.594673, 60.535918],
              [115.496886, 60.512871],
              [115.456402, 60.515425],
              [115.417318, 60.496469],
              [115.369457, 60.491911],
              [115.340499, 60.496998],
              [115.332884, 60.507298],
              [115.300825, 60.513756],
              [115.259912, 60.499393],
              [115.212785, 60.490171],
              [115.22385, 60.470391],
              [115.211848, 60.460481],
              [115.146563, 60.44456],
              [115.112914, 60.430798],
              [115.096025, 60.413232],
              [115.117581, 60.37775],
              [115.145207, 60.365985],
              [115.107652, 60.340613],
              [115.117234, 60.326021],
              [115.073852, 60.305342],
              [115.077809, 60.283546],
              [115.032908, 60.263513],
              [115.023475, 60.236097],
              [114.986751, 60.214531],
              [114.939869, 60.2048],
              [114.911595, 60.194489],
              [114.906236, 60.184778],
              [114.84814, 60.192142],
              [114.825752, 60.185261],
              [114.821046, 60.200335],
              [114.774705, 60.202535],
              [114.719238, 60.195522],
              [114.651151, 60.179064],
              [114.656844, 60.155313],
              [114.621711, 60.144433],
              [114.599837, 60.1443],
              [114.565087, 60.130926],
              [114.547111, 60.107172],
              [114.555543, 60.101175],
              [114.546082, 60.082723],
              [114.496649, 60.069566],
              [114.49177, 60.034572],
              [114.53565, 60.020618],
              [114.559409, 60.022594],
              [114.556467, 60.008393],
              [114.573376, 59.989086],
              [114.529526, 59.983615],
              [114.497457, 59.97219],
              [114.449414, 59.945349],
              [114.426677, 59.949203],
              [114.35555, 59.914127],
              [114.334394, 59.913428],
              [114.297153, 59.890731],
              [114.30032, 59.875797],
              [114.288854, 59.854491],
              [114.227656, 59.837069],
              [114.230564, 59.819407],
              [114.216725, 59.784636],
              [114.196052, 59.781074],
              [114.156466, 59.758813],
              [114.150321, 59.745556],
              [114.096977, 59.76525],
              [114.06274, 59.755741],
              [114.04531, 59.741771],
              [114.000474, 59.730389],
              [114.006912, 59.719191],
              [113.983193, 59.709355],
              [113.99071, 59.701724],
              [113.969705, 59.690922],
              [113.925864, 59.682163],
              [113.913864, 59.694279],
              [113.874841, 59.686248],
              [113.883414, 59.678075],
              [113.856518, 59.650689],
              [113.814375, 59.643095],
              [113.795431, 59.627648],
              [113.761156, 59.617057],
              [113.736447, 59.62762],
              [113.706531, 59.630044],
              [113.684235, 59.615525],
              [113.652561, 59.614768],
              [113.5913, 59.594758],
              [113.596278, 59.577466],
              [113.626506, 59.562263],
              [113.607031, 59.545576],
              [113.614501, 59.515156],
              [113.584552, 59.496414],
              [113.561228, 59.494312],
              [113.529518, 59.47675],
              [113.523024, 59.45717],
              [113.503324, 59.45906],
              [113.463385, 59.432292],
              [113.451648, 59.409828],
              [113.431376, 59.40231],
              [113.432036, 59.37874],
              [113.513904, 59.360903],
              [113.532065, 59.349913],
              [113.500317, 59.327844],
              [113.461359, 59.308064],
              [113.485608, 59.284619],
              [113.465212, 59.265593],
              [113.456647, 59.276604],
              [113.436636, 59.270746],
              [113.429053, 59.247773],
              [113.39303, 59.241639],
              [113.374692, 59.223066],
              [113.356255, 59.227663],
              [113.32757, 59.218052],
              [113.33897, 59.205674],
              [113.332701, 59.191033],
              [113.277556, 59.158104],
              [113.239238, 59.161153],
              [113.201358, 59.187265],
              [113.161002, 59.183913],
              [113.109606, 59.150801],
              [113.078061, 59.165254],
              [113.058007, 59.167939],
              [113.02739, 59.145079],
              [112.964304, 59.141975],
              [112.962675, 59.153892],
              [112.931538, 59.156883],
              [112.891904, 59.142783],
              [112.886195, 59.13066],
              [112.851254, 59.121095],
              [112.844225, 59.127371],
              [112.805138, 59.126996],
              [112.749709, 59.091973],
              [112.751869, 59.051929],
              [112.730105, 59.047867],
              [112.706568, 59.054407],
              [112.650495, 59.051722],
              [112.627223, 59.042901],
              [112.608183, 59.048381],
              [112.614909, 59.056752],
              [112.594487, 59.076389],
              [112.619391, 59.090908],
              [112.605654, 59.107824],
              [112.531626, 59.075065],
              [112.514623, 59.060429],
              [112.51428, 59.044106],
              [112.555207, 59.01189],
              [112.599206, 58.992301],
              [112.578243, 58.962816],
              [112.589706, 58.944663],
              [112.568271, 58.943242],
              [112.543017, 58.922834],
              [112.524731, 58.894082],
              [112.479134, 58.891077],
              [112.441539, 58.918129],
              [112.446791, 58.979136],
              [112.461634, 58.995335],
              [112.434783, 59.010194],
              [112.460588, 59.030472],
              [112.435639, 59.055174],
              [112.438764, 59.070083],
              [112.468122, 59.082522],
              [112.459172, 59.094074],
              [112.464359, 59.113039],
              [112.447849, 59.136239],
              [112.448434, 59.153263],
              [112.462859, 59.158156],
              [112.465957, 59.186606],
              [112.486702, 59.214334],
              [112.50087, 59.220552],
              [112.505704, 59.238225],
              [112.525441, 59.23851],
              [112.539127, 59.254534],
              [112.56316, 59.255757],
              [112.601928, 59.274901],
              [112.628966, 59.270745],
              [112.643807, 59.281264],
              [112.651461, 59.313623],
              [112.635479, 59.323992],
              [112.590147, 59.325879],
              [112.569536, 59.316706],
              [112.566509, 59.335847],
              [112.521975, 59.348222],
              [112.504409, 59.334328],
              [112.455825, 59.341127],
              [112.429447, 59.330534],
              [112.419849, 59.335736],
              [112.368609, 59.310569],
              [112.313654, 59.315351],
              [112.279031, 59.299172],
              [112.268992, 59.305639],
              [112.280752, 59.318039],
              [112.322275, 59.334186],
              [112.305718, 59.345079],
              [112.302841, 59.364742],
              [112.275442, 59.373139],
              [112.340591, 59.425588],
              [112.336859, 59.439098],
              [112.286398, 59.482966],
              [112.281919, 59.502896],
              [112.301088, 59.524185],
              [112.273681, 59.531504],
              [112.251292, 59.5284],
              [112.199298, 59.504334],
              [112.201259, 59.491584],
              [112.178247, 59.470555],
              [112.148501, 59.464562],
              [112.093749, 59.428747],
              [112.097631, 59.416611],
              [112.078895, 59.392533],
              [112.040207, 59.383011],
              [112.000682, 59.357486],
              [111.959563, 59.338655],
              [111.975872, 59.319657],
              [111.941094, 59.278543],
              [111.898915, 59.303685],
              [111.871352, 59.301691],
              [111.844647, 59.290646],
              [111.790191, 59.29798],
              [111.718216, 59.257567],
              [111.649821, 59.223848],
              [111.616265, 59.226461],
              [111.587306, 59.239021],
              [111.560631, 59.23519],
              [111.507296, 59.255457],
              [111.494803, 59.285527],
              [111.467346, 59.282609],
              [111.451149, 59.292353],
              [111.41472, 59.290645],
              [111.39968, 59.283761],
              [111.381514, 59.288708],
              [111.3344, 59.277732],
              [111.297237, 59.277575],
              [111.267917, 59.258086],
              [111.285046, 59.244389],
              [111.250744, 59.241878],
              [111.234806, 59.229778],
              [111.217729, 59.203733],
              [111.180048, 59.203451],
              [111.172184, 59.211331],
              [111.134678, 59.213351],
              [111.102422, 59.236257],
              [111.10927, 59.243321],
              [111.093641, 59.256028],
              [111.064697, 59.254169],
              [111.071206, 59.240463],
              [111.042328, 59.222514],
              [110.983064, 59.216713],
              [110.983503, 59.23045],
              [110.949402, 59.247444],
              [110.856772, 59.261129],
              [110.859243, 59.272094],
              [110.826164, 59.273226],
              [110.809481, 59.266828],
              [110.720542, 59.280902],
              [110.673439, 59.264988],
              [110.634577, 59.246616],
              [110.641581, 59.218745],
              [110.574622, 59.204138],
              [110.574234, 59.190401],
              [110.548603, 59.183345],
              [110.587617, 59.159461],
              [110.603614, 59.140373],
              [110.579649, 59.127],
              [110.545461, 59.127576],
              [110.526121, 59.106793],
              [110.541325, 59.092033],
              [110.498522, 59.058927],
              [110.474673, 59.046366],
              [110.399319, 59.057215],
              [110.348347, 59.041594],
              [110.317668, 59.025479],
              [110.309698, 59.009021],
              [110.263097, 58.991048],
              [110.239422, 58.999926],
              [110.158248, 58.980934],
              [110.141044, 58.995427],
              [110.108643, 58.990821],
              [110.086257, 59.002417],
              [110.023501, 59.001598],
              [110.002585, 59.018775],
              [109.982887, 59.024971],
              [109.910673, 59.019581],
              [109.851127, 59.003205],
              [109.847911, 58.991841],
              [109.821884, 58.988769],
              [109.811944, 59.004253],
              [109.757196, 59.037041],
              [109.752613, 59.058986],
              [109.727389, 59.090552],
              [109.699021, 59.091942],
              [109.6556, 59.072746],
              [109.642656, 59.060777],
              [109.617505, 59.083333],
              [109.613595, 59.100666],
              [109.634199, 59.122181],
              [109.621544, 59.14417],
              [109.630401, 59.16426],
              [109.600108, 59.172478],
              [109.579928, 59.170634],
              [109.580476, 59.184238],
              [109.509493, 59.192734],
              [109.523843, 59.225786],
              [109.536504, 59.235458],
              [109.524361, 59.247185],
              [109.479278, 59.252317],
              [109.491523, 59.268551],
              [109.48516, 59.293458],
              [109.506285, 59.308076],
              [109.4526, 59.313018],
              [109.400094, 59.304693],
              [109.379609, 59.315437],
              [109.329543, 59.316102],
              [109.313819, 59.322394],
              [109.291664, 59.316525],
              [109.245004, 59.333282],
              [109.274728, 59.351495],
              [109.239804, 59.381485],
              [109.240394, 59.390863],
              [109.282681, 59.387755],
              [109.282946, 59.398155],
              [109.342886, 59.417168],
              [109.351645, 59.42901],
              [109.342513, 59.44246],
              [109.299961, 59.442145],
              [109.252271, 59.466906],
              [109.301102, 59.500696],
              [109.33398, 59.510732],
              [109.327877, 59.531574],
              [109.339252, 59.546245],
              [109.362492, 59.548386],
              [109.400384, 59.567578],
              [109.401759, 59.584365],
              [109.469828, 59.624351],
              [109.488318, 59.629131],
              [109.543439, 59.667295],
              [109.541292, 59.677482],
              [109.508243, 59.689748],
              [109.488587, 59.6862],
              [109.490698, 59.704568],
              [109.475642, 59.721383],
              [109.473381, 59.742339],
              [109.484454, 59.751955],
              [109.474766, 59.767633],
              [109.489046, 59.772083],
              [109.508123, 59.801529],
              [109.544459, 59.816041],
              [109.567703, 59.834807],
              [109.563324, 59.844239],
              [109.625284, 59.853674],
              [109.635339, 59.865575],
              [109.662596, 59.876033],
              [109.644325, 59.896015],
              [109.649978, 59.905096],
              [109.621352, 59.918122],
              [109.621253, 59.937818],
              [109.666365, 59.939082],
              [109.67474, 59.986677],
              [109.699774, 59.994889],
              [109.755081, 60.026996],
              [109.752078, 60.05239],
              [109.705972, 60.052794],
              [109.713836, 60.07286],
              [109.692598, 60.076711],
              [109.699399, 60.107703],
              [109.714113, 60.123064],
              [109.692776, 60.125608],
              [109.694643, 60.161238],
              [109.714598, 60.170523],
              [109.718438, 60.183118],
              [109.707514, 60.206889],
              [109.733636, 60.215595],
              [109.722853, 60.232044],
              [109.740317, 60.248555],
              [109.765509, 60.247294],
              [109.791317, 60.254336],
              [109.815246, 60.273757],
              [109.813265, 60.306672],
              [109.790777, 60.318494],
              [109.804651, 60.336441],
              [109.833218, 60.339688],
              [109.881641, 60.358443],
              [109.870997, 60.370794],
              [109.847149, 60.372024],
              [109.849731, 60.390969],
              [109.894707, 60.389439],
              [109.92554, 60.398534],
              [109.927097, 60.409603],
              [109.948778, 60.41689],
              [109.953373, 60.439926],
              [109.907127, 60.461395],
              [109.9248, 60.473833],
              [109.949669, 60.504864],
              [110.022251, 60.533268],
              [110.057321, 60.569849],
              [110.040121, 60.581271],
              [110.073888, 60.589096],
              [110.098306, 60.585129],
              [110.138172, 60.59693],
              [110.122694, 60.62139],
              [110.134587, 60.632181],
              [110.126216, 60.648095],
              [110.094435, 60.654072],
              [110.089865, 60.666565],
              [110.104821, 60.672408],
              [110.056796, 60.683279],
              [110.099332, 60.694398],
              [110.153486, 60.687679],
              [110.189047, 60.695944],
              [110.233532, 60.681354],
              [110.261457, 60.686626],
              [110.288624, 60.701981],
              [110.298469, 60.725578],
              [110.285677, 60.734238],
              [110.286167, 60.761539],
              [110.274962, 60.768111],
              [110.299794, 60.78213],
              [110.28296, 60.804044],
              [110.257592, 60.821647],
              [110.261865, 60.844633],
              [110.282638, 60.866969],
              [110.306785, 60.87417],
              [110.32481, 60.890717],
              [110.337218, 60.913736],
              [110.367768, 60.937577],
              [110.41589, 60.955051],
              [110.405495, 60.963744],
              [110.418913, 60.975645],
              [110.457916, 60.981627],
              [110.483167, 61.000681],
              [110.500269, 61.031415],
              [110.484787, 61.049727],
              [110.50083, 61.069968],
              [110.542292, 61.087795],
              [110.545392, 61.11666],
              [110.522813, 61.137411],
              [110.543107, 61.148485],
              [110.541449, 61.16427],
              [110.523942, 61.179692],
              [110.479851, 61.184227],
              [110.480688, 61.191158],
              [110.433001, 61.198562],
              [110.426312, 61.208414],
              [110.381295, 61.201345],
              [110.345216, 61.177064],
              [110.281989, 61.170505],
              [110.222093, 61.168251],
              [110.18179, 61.18617],
              [110.182385, 61.202514],
              [110.141577, 61.22151],
              [110.14079, 61.237072],
              [110.124805, 61.245059],
              [110.111251, 61.277886],
              [110.017394, 61.3002],
              [109.997213, 61.314717],
              [109.962238, 61.308802],
              [109.951507, 61.318418],
              [109.881907, 61.322966],
              [109.850701, 61.313317],
              [109.820886, 61.32866],
              [109.791678, 61.326304],
              [109.761796, 61.3317],
              [109.778996, 61.353524],
              [109.813556, 61.371162],
              [109.801233, 61.386175],
              [109.839155, 61.425168],
              [109.843192, 61.439957],
              [109.859936, 61.448522],
              [109.841329, 61.452756],
              [109.808911, 61.477883],
              [109.859293, 61.513695],
              [109.852766, 61.538084],
              [109.87049, 61.549351],
              [109.819822, 61.590004],
              [109.81912, 61.600738],
              [109.791757, 61.611161],
              [109.791498, 61.627234],
              [109.744946, 61.645636],
              [109.750185, 61.654497],
              [109.698127, 61.65606],
              [109.692799, 61.664631],
              [109.725983, 61.683838],
              [109.731829, 61.698271],
              [109.686193, 61.698058],
              [109.661904, 61.707276],
              [109.64819, 61.733501],
              [109.621593, 61.734282],
              [109.600187, 61.745473],
              [109.596726, 61.767658],
              [109.619117, 61.799681],
              [109.595105, 61.808043],
              [109.574538, 61.831156],
              [109.587859, 61.851233],
              [109.610922, 61.856408],
              [109.598866, 61.875907],
              [109.579281, 61.887328],
              [109.575309, 61.914704],
              [109.642256, 61.927687],
              [109.684185, 61.968145],
              [109.725438, 61.976609],
              [109.713407, 61.988741],
              [109.724054, 62.001212],
              [109.776145, 62.010131],
              [109.759877, 62.023455],
              [109.788918, 62.017958],
              [109.815796, 62.022757],
              [109.825956, 62.043834],
              [109.847397, 62.045839],
              [109.8721, 62.066979],
              [109.872613, 62.089061],
              [109.888904, 62.097735],
              [109.860159, 62.1096],
              [109.913799, 62.135983],
              [109.955671, 62.161926],
              [109.983661, 62.16212],
              [110.00455, 62.186047],
              [109.986581, 62.196525],
              [109.973639, 62.216203],
              [109.959688, 62.21826],
              [109.953945, 62.238388],
              [109.928832, 62.247467],
              [109.918588, 62.26006],
              [109.942542, 62.275217],
              [109.940637, 62.283957],
              [109.96541, 62.287118],
              [109.99532, 62.309658],
              [109.976218, 62.328178],
              [109.940476, 62.336908],
              [109.952515, 62.360993],
              [109.918012, 62.373167],
              [109.889907, 62.37053],
              [109.886796, 62.388369],
              [109.901879, 62.390456],
              [109.903973, 62.407934],
              [109.927115, 62.41604],
              [109.91093, 62.437021],
              [109.826772, 62.44538],
              [109.766392, 62.439608],
              [109.708819, 62.44252],
              [109.667902, 62.435392],
              [109.63906, 62.443911],
              [109.597643, 62.445939],
              [109.553325, 62.455069],
              [109.511885, 62.440061],
              [109.510465, 62.43065],
              [109.445182, 62.424841],
              [109.446225, 62.439617],
              [109.429148, 62.449974],
              [109.430854, 62.473591],
              [109.396881, 62.487216],
              [109.364208, 62.485329],
              [109.337635, 62.493533],
              [109.331384, 62.504491],
              [109.298355, 62.521629],
              [109.315182, 62.534728],
              [109.269589, 62.556171],
              [109.283197, 62.570145],
              [109.251916, 62.57675],
              [109.254805, 62.598724],
              [109.321039, 62.607872],
              [109.378151, 62.607404],
              [109.371361, 62.618261],
              [109.384026, 62.634933],
              [109.379037, 62.661495],
              [109.387625, 62.671617],
              [109.441231, 62.675398],
              [109.472373, 62.68541],
              [109.485329, 62.704266],
              [109.466491, 62.711588],
              [109.485845, 62.734106],
              [109.465187, 62.746661],
              [109.490232, 62.760373],
              [109.507987, 62.795014],
              [109.557787, 62.798641],
              [109.566645, 62.807156],
              [109.637146, 62.822334],
              [109.63627, 62.829144],
              [109.675732, 62.829442],
              [109.681311, 62.839964],
              [109.669882, 62.860569],
              [109.645487, 62.863578],
              [109.626582, 62.886566],
              [109.63932, 62.898698],
              [109.595097, 62.906037],
              [109.601607, 62.913438],
              [109.571428, 62.921499],
              [109.526804, 62.918123],
              [109.528362, 62.936629],
              [109.494455, 62.95639],
              [109.483979, 62.970701],
              [109.493619, 62.983241],
              [109.522663, 62.992972],
              [109.514489, 63.006081],
              [109.534118, 63.013659],
              [109.523396, 63.021319],
              [109.500371, 63.012955],
              [109.469752, 63.024098],
              [109.515371, 63.026988],
              [109.500157, 63.039547],
              [109.511788, 63.050358],
              [109.4881, 63.059914],
              [109.499467, 63.083403],
              [109.484731, 63.095885],
              [109.515634, 63.105548],
              [109.458555, 63.109201],
              [109.496928, 63.140758],
              [109.461507, 63.148064],
              [109.460137, 63.158812],
              [109.485233, 63.169677],
              [109.454443, 63.172254],
              [109.443339, 63.184794],
              [109.445411, 63.225706],
              [109.476166, 63.228486],
              [109.474685, 63.250419],
              [109.449538, 63.268754],
              [109.452144, 63.276225],
              [109.41587, 63.279973],
              [109.42835, 63.313726],
              [109.405326, 63.332638],
              [109.417675, 63.345739],
              [109.411673, 63.367869],
              [109.387816, 63.377348],
              [109.361415, 63.367573],
              [109.314958, 63.363457],
              [109.285972, 63.372478],
              [109.294618, 63.391306],
              [109.319332, 63.393414],
              [109.280412, 63.430266],
              [109.257791, 63.438851],
              [109.257929, 63.462989],
              [109.225679, 63.467811],
              [109.205101, 63.477932],
              [109.191372, 63.497659],
              [109.177315, 63.496168],
              [109.188828, 63.530199],
              [109.17121, 63.542724],
              [109.152264, 63.541669],
              [109.141663, 63.557673],
              [109.101878, 63.563812],
              [109.081238, 63.577065],
              [109.024534, 63.578966],
              [109.000071, 63.585065],
              [108.948255, 63.581924],
              [108.925639, 63.575649],
              [108.85561, 63.589498],
              [108.827793, 63.590065],
              [108.809476, 63.601787],
              [108.776065, 63.602552],
              [108.726957, 63.620063],
              [108.680051, 63.611704],
              [108.628232, 63.615876],
              [108.612839, 63.63243],
              [108.581849, 63.626194],
              [108.565959, 63.633721],
              [108.495058, 63.636415],
              [108.452815, 63.628641],
              [108.436178, 63.632487],
              [108.398593, 63.626607],
              [108.370605, 63.614787],
              [108.324941, 63.609137],
              [108.26406, 63.612614],
              [108.224163, 63.603415],
              [108.168998, 63.600662],
              [108.144004, 63.603945],
              [108.131411, 63.618065],
              [108.141358, 63.627116],
              [108.121532, 63.644839],
              [108.124333, 63.658146],
              [108.175527, 63.678133],
              [108.227865, 63.670016],
              [108.238237, 63.688203],
              [108.27279, 63.692947],
              [108.299315, 63.716051],
              [108.290108, 63.735989],
              [108.304045, 63.74393],
              [108.293075, 63.759155],
              [108.288525, 63.801924],
              [108.299295, 63.821584],
              [108.287333, 63.836652],
              [108.306324, 63.847093],
              [108.334732, 63.848223],
              [108.360629, 63.860993],
              [108.397535, 63.860962],
              [108.39144, 63.849517],
              [108.417107, 63.839107],
              [108.45756, 63.835292],
              [108.496466, 63.82235],
              [108.519852, 63.836164],
              [108.571351, 63.835731],
              [108.57553, 63.845315],
              [108.617179, 63.847725],
              [108.64789, 63.821812],
              [108.681176, 63.822215],
              [108.731835, 63.842014],
              [108.755276, 63.866311],
              [108.802638, 63.870719],
              [108.798344, 63.890804],
              [108.750462, 63.913036],
              [108.726626, 63.913474],
              [108.73945, 63.930677],
              [108.710601, 63.932133],
              [108.734659, 63.949464],
              [108.726077, 63.962167],
              [108.75017, 63.971412],
              [108.758917, 63.989195],
              [108.742761, 63.993893],
              [108.762471, 64.01662],
              [108.738807, 64.036193],
              [108.660205, 64.055075],
              [108.64482, 64.065031],
              [108.613007, 64.068797],
              [108.59588, 64.087527],
              [108.609358, 64.106627],
              [108.575507, 64.11608],
              [108.561741, 64.129611],
              [108.522791, 64.1346],
              [108.524723, 64.148757],
              [108.563997, 64.165214],
              [108.565096, 64.175885],
              [108.596893, 64.194121],
              [108.519782, 64.211751],
              [108.520916, 64.232464],
              [108.564356, 64.243236],
              [108.583116, 64.255198],
              [108.515734, 64.267948],
              [108.528984, 64.280703],
              [108.502827, 64.306291],
              [108.460006, 64.305409],
              [108.42834, 64.321545],
              [108.394859, 64.311035],
              [108.408728, 64.290331],
              [108.398389, 64.279697],
              [108.360864, 64.26464],
              [108.325514, 64.274124],
              [108.285489, 64.2698],
              [108.263828, 64.277356],
              [108.236827, 64.272134],
              [108.189293, 64.284289],
              [108.177868, 64.271764],
              [108.119211, 64.260729],
              [108.085664, 64.246468],
              [108.053478, 64.213862],
              [108.05893, 64.187904],
              [107.971941, 64.182336],
              [107.964583, 64.203991],
              [107.923244, 64.201142],
              [107.92246, 64.191118],
              [107.892208, 64.196355],
              [107.887879, 64.209748],
              [107.860089, 64.231146],
              [107.821949, 64.229155],
              [107.751537, 64.255209],
              [107.707701, 64.258048],
              [107.724676, 64.272285],
              [107.698538, 64.295698],
              [107.641299, 64.3107],
              [107.560744, 64.312666],
              [107.551413, 64.306474],
              [107.495908, 64.300898],
              [107.459087, 64.279131],
              [107.401014, 64.274573],
              [107.369929, 64.261064],
              [107.325227, 64.270333],
              [107.295266, 64.284644],
              [107.254706, 64.320253],
              [107.268497, 64.328176],
              [107.25651, 64.345425],
              [107.209175, 64.34282],
              [107.176165, 64.353232],
              [107.133377, 64.35101],
              [107.092467, 64.358963],
              [107.099751, 64.371804],
              [107.042761, 64.379854],
              [106.959579, 64.403908],
              [106.950481, 64.420774],
              [106.915167, 64.43828],
              [106.885759, 64.440323],
              [106.827834, 64.420867],
              [106.786969, 64.426061],
              [106.741122, 64.422277],
              [106.723825, 64.435543],
              [106.704785, 64.465273],
              [106.708144, 64.479424],
              [106.665837, 64.498908],
              [106.652537, 64.529516],
              [106.578978, 64.542671],
              [106.55534, 64.534551],
              [106.554992, 64.516329],
              [106.587233, 64.478226],
              [106.554146, 64.460234],
              [106.537184, 64.46433],
              [106.500714, 64.456455],
              [106.460507, 64.4606],
              [106.441341, 64.454437],
              [106.365503, 64.455635],
              [106.311919, 64.439264],
              [106.256347, 64.447051],
              [106.234628, 64.434828],
              [106.179124, 64.415694],
              [106.166881, 64.421609],
              [106.138632, 64.415954],
              [106.092496, 64.426158],
              [106.075299, 64.446469],
              [106.117332, 64.45595],
              [106.133812, 64.485387],
              [106.116999, 64.495013],
              [106.076682, 64.499172],
              [106.061565, 64.524808],
              [106.023849, 64.540924],
              [105.98557, 64.534101],
              [105.918855, 64.501263],
              [105.902473, 64.486768],
              [105.866378, 64.502231],
              [105.807422, 64.497484],
              [105.765799, 64.48447],
              [105.749891, 64.503934],
              [105.792656, 64.527169],
              [105.785637, 64.550311],
              [105.762625, 64.562309],
              [105.752845, 64.581327],
              [105.714886, 64.580925],
              [105.709529, 64.602223],
              [105.750906, 64.640663],
              [105.719323, 64.650038],
              [105.687202, 64.682967],
              [105.735249, 64.690987],
              [105.782152, 64.668013],
              [105.823024, 64.66791],
              [105.869535, 64.675123],
              [105.876841, 64.686773],
              [105.859898, 64.703434],
              [105.815905, 64.72606],
              [105.832102, 64.733509],
              [105.838079, 64.751579],
              [105.818335, 64.760534],
              [105.826075, 64.786311],
              [105.846682, 64.791062],
              [105.862818, 64.821242],
              [105.852876, 64.832933],
              [105.895064, 64.843904],
              [105.924011, 64.82586],
              [105.976429, 64.822378],
              [105.986937, 64.83151],
              [105.960806, 64.853228],
              [105.941366, 64.851868],
              [105.941564, 64.873473],
              [105.914801, 64.903497],
              [105.954415, 64.912983],
              [105.96893, 64.908378],
              [106.03182, 64.9187],
              [106.046102, 64.911952],
              [106.03309, 64.88266],
              [106.060232, 64.883835],
              [106.062025, 64.869101],
              [106.100655, 64.878377],
              [106.190975, 64.864978],
              [106.207529, 64.873037],
              [106.157388, 64.895398],
              [106.11633, 64.9061],
              [106.101321, 64.935535],
              [106.067029, 64.9565],
              [106.070925, 64.980394],
              [106.041725, 64.982915],
              [106.01496, 64.994318],
              [105.982286, 64.998276],
              [105.952936, 65.014658],
              [105.950465, 65.039901],
              [106.008667, 65.055783],
              [106.025802, 65.066351],
              [106.099257, 65.067432],
              [106.154905, 65.089094],
              [106.189947, 65.133042],
              [106.252735, 65.137042],
              [106.26613, 65.145893],
              [106.312529, 65.145672],
              [106.314717, 65.167082],
              [106.346887, 65.180628],
              [106.351408, 65.191747],
              [106.419395, 65.228993],
              [106.477599, 65.209495],
              [106.526121, 65.219637],
              [106.46739, 65.231975],
              [106.429257, 65.24653],
              [106.427562, 65.293797],
              [106.439462, 65.319506],
              [106.474336, 65.323562],
              [106.516178, 65.342986],
              [106.544958, 65.380508],
              [106.605457, 65.382964],
              [106.615225, 65.39598],
              [106.666106, 65.387591],
              [106.694962, 65.393503],
              [106.710033, 65.386566],
              [106.776138, 65.387228],
              [106.780135, 65.374773],
              [106.817327, 65.377536],
              [106.831985, 65.399978],
              [106.872231, 65.422995],
              [106.862204, 65.442414],
              [106.915869, 65.469944],
              [106.938868, 65.471908],
              [106.967889, 65.508284],
              [106.936139, 65.514155],
              [106.904479, 65.511646],
              [106.887487, 65.518033],
              [106.821717, 65.524952],
              [106.79765, 65.52049],
              [106.777177, 65.532961],
              [106.703752, 65.539858],
              [106.693273, 65.557131],
              [106.720365, 65.587637],
              [106.698461, 65.603734],
              [106.607198, 65.611315],
              [106.651519, 65.619566],
              [106.590382, 65.625145],
              [106.59399, 65.639472],
              [106.563533, 65.632606],
              [106.551804, 65.645548],
              [106.51585, 65.647869],
              [106.467432, 65.661283],
              [106.42805, 65.664566],
              [106.415123, 65.671969],
              [106.434117, 65.687521],
              [106.400972, 65.697742],
              [106.424278, 65.702393],
              [106.412898, 65.713006],
              [106.449195, 65.712487],
              [106.436468, 65.724716],
              [106.459869, 65.733042],
              [106.435674, 65.741582],
              [106.454803, 65.745556],
              [106.42806, 65.763081],
              [106.443504, 65.786145],
              [106.491483, 65.798387],
              [106.458572, 65.812298],
              [106.448051, 65.825931],
              [106.469854, 65.83249],
              [106.484043, 65.855028],
              [106.473245, 65.879053],
              [106.493821, 65.883603],
              [106.467775, 65.907716],
              [106.464948, 65.931961],
              [106.493774, 65.95507],
              [106.489102, 65.979808],
              [106.514476, 65.995053],
              [106.478285, 66.016127],
              [106.482648, 66.025938],
              [106.44727, 66.042775],
              [106.450987, 66.054196],
              [106.413411, 66.061489],
              [106.429789, 66.082528],
              [106.37404, 66.092769],
              [106.379069, 66.101865],
              [106.353288, 66.11242],
              [106.367106, 66.118887],
              [106.338582, 66.152849],
              [106.32207, 66.15679],
              [106.288516, 66.148764],
              [106.252266, 66.153987],
              [106.205446, 66.147],
              [106.17052, 66.165311],
              [106.157688, 66.163805],
              [106.106042, 66.19724],
              [106.120406, 66.213102],
              [106.108133, 66.235398],
              [106.137001, 66.24871],
              [106.120464, 66.277377],
              [106.13221, 66.282989],
              [106.112368, 66.297614],
              [106.121396, 66.314879],
              [106.095545, 66.336202],
              [106.119552, 66.357872],
              [106.108699, 66.363172],
              [106.12695, 66.384347],
              [106.146585, 66.389783],
              [106.148658, 66.409175],
              [106.182526, 66.414263],
              [106.195564, 66.429772],
              [106.253068, 66.435256],
              [106.262919, 66.445326],
              [106.316003, 66.464533],
              [106.330961, 66.474971],
              [106.300794, 66.497481],
              [106.322893, 66.518899],
              [106.290229, 66.546116],
              [106.237461, 66.563338],
              [106.231644, 66.590328],
              [106.250231, 66.603831],
              [106.219282, 66.63636],
              [106.222287, 66.663933],
              [106.258277, 66.676895],
              [106.220205, 66.697373],
              [106.170333, 66.707544],
              [106.146376, 66.730805],
              [106.14689, 66.764451],
              [106.186863, 66.770683],
              [106.213301, 66.797782],
              [106.186698, 66.801545],
              [106.123156, 66.797558],
              [106.085487, 66.779365],
              [106.051322, 66.789151],
              [106.025462, 66.78582],
              [105.985634, 66.801503],
              [106.09415, 66.859184],
              [106.103131, 66.874373],
              [106.080664, 66.895417],
              [106.033915, 66.89739],
              [106.018052, 66.886514],
              [105.976463, 66.900774],
              [105.909857, 66.909716],
              [105.855024, 66.910733],
              [105.845134, 66.930725],
              [105.858441, 66.943471],
              [105.785105, 66.936374],
              [105.737398, 66.924972],
              [105.698989, 66.925205],
              [105.637632, 66.944452],
              [105.626986, 66.954285],
              [105.579588, 66.966926],
              [105.543609, 66.984024],
              [105.555029, 66.999472],
              [105.509063, 67.013985],
              [105.544952, 67.023716],
              [105.579905, 67.018431],
              [105.640971, 67.025436],
              [105.669799, 67.013449],
              [105.764727, 67.029696],
              [105.872565, 67.029438],
              [105.962214, 67.045478],
              [106.015868, 67.085736],
              [106.066743, 67.11733],
              [106.062034, 67.142048],
              [106.076491, 67.168536],
              [106.113717, 67.167391],
              [106.184439, 67.173352],
              [106.220558, 67.171419],
              [106.259281, 67.184353],
              [106.276234, 67.198461],
              [106.342143, 67.200771],
              [106.376191, 67.215615],
              [106.38696, 67.229825],
              [106.433573, 67.264014],
              [106.505324, 67.275583],
              [106.511791, 67.283238],
              [106.555429, 67.290893],
              [106.560112, 67.273161],
              [106.590562, 67.262535],
              [106.678428, 67.251076],
              [106.715601, 67.258458],
              [106.712254, 67.276062],
              [106.737461, 67.295615],
              [106.760047, 67.301958],
              [106.822039, 67.334093],
              [106.825863, 67.416493],
              [106.838335, 67.502118],
              [106.874489, 67.667541],
              [106.868015, 67.747208],
              [106.870384, 67.835794],
              [106.8865, 67.918525],
              [106.88762, 68.000818],
              [106.900779, 68.045418],
              [106.910232, 68.175051],
              [106.920682, 68.254164],
              [106.92067, 68.334174],
              [106.916037, 68.399264],
              [106.916949, 68.486389],
              [106.912523, 68.604069],
              [106.906583, 68.685558],
              [106.905551, 68.859042],
              [106.715348, 69.000868],
              [106.675069, 69.03248],
              [106.513032, 69.150287],
              [106.308897, 69.293111],
              [106.14286, 69.409405],
              [106.178337, 69.44168],
              [106.292662, 69.459201],
              [106.344196, 69.478153],
              [106.348603, 69.507902],
              [106.372145, 69.526467],
              [106.440504, 69.555865],
              [106.452173, 69.572737],
              [106.487412, 69.581163],
              [106.543921, 69.570706],
              [106.59088, 69.569514],
              [106.615727, 69.558962],
              [106.630677, 69.527968],
              [106.681711, 69.525942],
              [106.744472, 69.53482],
              [106.857426, 69.523271],
              [106.956394, 69.545334],
              [107.069603, 69.556196],
              [107.171761, 69.585819],
              [107.30918, 69.605071],
              [107.341379, 69.613131],
              [107.393786, 69.614381],
              [107.442207, 69.622379],
              [107.507636, 69.639916],
              [107.606481, 69.660499],
              [107.691555, 69.666576],
              [107.733843, 69.675481],
              [107.785075, 69.676754],
              [107.844267, 69.686374],
              [107.929119, 69.668473],
              [107.952931, 69.669024],
              [107.976489, 69.688458],
              [108.01942, 69.69844],
              [108.030772, 69.725203],
              [108.006886, 69.749899],
              [108.05221, 69.76343],
              [108.022611, 69.787929],
              [108.069768, 69.79335],
              [108.117876, 69.786449],
              [108.140692, 69.808194],
              [108.176466, 69.821985],
              [108.221155, 69.82848],
              [108.226446, 69.848397],
              [108.248733, 69.854403],
              [108.391078, 69.850246],
              [108.443592, 69.841628],
              [108.514629, 69.84314],
              [108.549133, 69.831224],
              [108.613956, 69.832761],
              [108.637042, 69.838822],
              [108.68523, 69.828793],
              [108.77494, 69.832721],
              [108.800199, 69.830515],
              [108.901959, 69.842557],
              [108.932733, 69.824702],
              [108.917026, 69.802293],
              [108.944624, 69.770599],
              [108.995823, 69.775269],
              [109.054495, 69.766856],
              [109.120873, 69.769119],
              [109.201107, 69.754623],
              [109.263026, 69.772274],
              [109.295729, 69.774273],
              [109.325564, 69.789058],
              [109.340219, 69.823474],
              [109.38587, 69.824367],
              [109.378434, 69.837451],
              [109.390523, 69.87716],
              [109.413702, 69.893764],
              [109.374558, 69.906566],
              [109.406992, 69.931682],
              [109.400095, 69.95813],
              [109.418592, 69.97182],
              [109.463896, 69.98656],
              [109.525572, 69.9916],
              [109.545155, 70.014676],
              [109.545224, 70.030906],
              [109.50404, 70.043963],
              [109.451802, 70.039807],
              [109.417194, 70.0481],
              [109.396613, 70.064363],
              [109.365572, 70.073329],
              [109.347926, 70.088919],
              [109.361616, 70.096789],
              [109.36197, 70.131616],
              [109.303296, 70.144451],
              [109.294619, 70.166653],
              [109.307008, 70.175688],
              [109.304333, 70.209872],
              [109.346962, 70.21568],
              [109.396676, 70.212626],
              [109.456463, 70.218879],
              [109.575575, 70.252447],
              [109.616694, 70.28517],
              [109.610124, 70.306863],
              [109.586493, 70.324948],
              [109.598842, 70.332779],
              [109.558341, 70.352237],
              [109.643766, 70.372773],
              [109.726074, 70.370697],
              [109.755786, 70.379016],
              [109.822516, 70.38355],
              [109.851532, 70.390921],
              [109.959248, 70.391803],
              [109.998206, 70.400426],
              [110.013418, 70.413271],
              [110.062605, 70.39786],
              [110.15902, 70.411911],
              [110.211261, 70.408151],
              [110.313335, 70.408571],
              [110.317634, 70.429094],
              [110.284181, 70.438346],
              [110.205965, 70.445671],
              [110.180309, 70.458605],
              [110.133608, 70.465111],
              [110.113966, 70.479588],
              [110.124565, 70.499696],
              [110.084571, 70.520573],
              [110.092425, 70.537606],
              [110.153717, 70.543554],
              [110.132049, 70.558029],
              [110.105702, 70.592266],
              [110.145001, 70.608832],
              [110.123517, 70.636983],
              [110.185375, 70.645292],
              [110.20139, 70.658641],
              [110.308611, 70.670126],
              [110.308159, 70.68335],
              [110.333428, 70.695886],
              [110.505705, 70.711318],
              [110.516327, 70.716534],
              [110.492218, 70.739581],
              [110.517212, 70.764128],
              [110.558766, 70.768113],
              [110.61927, 70.784785],
              [110.652496, 70.782753],
              [110.736653, 70.799434],
              [110.794247, 70.792803],
              [110.813493, 70.780047],
              [110.858396, 70.785825],
              [110.887823, 70.799118],
              [110.974464, 70.816452],
              [111.026053, 70.830673],
              [111.034216, 70.840829],
              [111.129412, 70.849538],
              [111.194968, 70.861285],
              [111.325938, 70.856235],
              [111.378156, 70.863313],
              [111.432135, 70.881283],
              [111.43374, 70.892373],
              [111.478798, 70.928825],
              [111.511454, 70.930001],
              [111.572607, 70.942261],
              [111.637736, 70.938977],
              [111.726046, 70.97001],
              [111.749636, 70.971118],
              [111.801059, 70.957544],
              [111.867671, 70.977268],
              [111.920763, 70.973797],
              [111.946322, 70.978864],
              [111.980531, 71.000007],
              [111.988164, 71.027639],
              [112.030505, 71.05306],
              [112.101396, 71.060682],
              [112.164337, 71.032363],
              [112.184129, 71.013275],
              [112.219628, 71.001151],
              [112.249204, 71.001368],
              [112.277215, 71.0285],
              [112.277194, 71.044259],
              [112.355982, 71.071176],
              [112.434492, 71.068225],
              [112.468867, 71.072039],
              [112.509301, 71.101637],
              [112.569875, 71.095356],
              [112.599576, 71.109193],
              [112.537486, 71.1374],
              [112.509573, 71.159787],
              [112.520897, 71.176169],
              [112.614316, 71.191786],
              [112.651449, 71.192058],
              [112.675735, 71.201036],
              [112.707824, 71.227251],
              [112.724997, 71.252169],
              [112.658249, 71.26465],
              [112.634871, 71.289676],
              [112.593699, 71.293798],
              [112.50756, 71.275652],
              [112.469258, 71.276587],
              [112.443992, 71.302192],
              [112.383887, 71.307076],
              [112.349044, 71.324085],
              [112.33522, 71.341799],
              [112.251531, 71.362361],
              [112.193474, 71.382586],
              [112.16385, 71.377365],
              [112.000514, 71.402308],
              [112.000574, 71.66755],
              [112.000186, 72.001037],
              [112.000695, 72.1244],
              [111.967885, 72.135161],
              [111.895486, 72.128646],
              [111.865668, 72.140526],
              [111.823227, 72.142014],
              [111.788606, 72.161392],
              [111.743839, 72.158337],
              [111.698145, 72.172726],
              [111.690315, 72.193588],
              [111.715856, 72.211131],
              [111.691752, 72.220915],
              [111.623857, 72.20773],
              [111.592957, 72.211758],
              [111.582311, 72.226299],
              [111.667015, 72.257532],
              [111.638422, 72.281022],
              [111.639687, 72.298523],
              [111.58684, 72.309008],
              [111.574805, 72.324402],
              [111.517859, 72.329466],
              [111.483435, 72.322515],
              [111.454667, 72.326818],
              [111.438796, 72.317262],
              [111.360258, 72.32087],
              [111.33524, 72.316017],
              [111.291282, 72.331478],
              [111.304495, 72.339997],
              [111.257798, 72.337789],
              [111.174025, 72.359852],
              [111.069036, 72.363761],
              [111.056463, 72.377908],
              [111.0102, 72.39022],
              [111.074827, 72.412194],
              [111.017054, 72.425097],
              [111.008817, 72.441847],
              [111.130945, 72.446986],
              [111.161391, 72.460998],
              [111.239722, 72.473805],
              [111.253708, 72.483913],
              [111.291058, 72.486607],
              [111.30088, 72.512994],
              [111.243823, 72.52391],
              [111.245519, 72.533927],
              [111.178717, 72.557758],
              [111.137315, 72.563326],
              [111.044843, 72.566625],
              [110.957672, 72.580563],
              [110.912348, 72.570951],
              [110.870467, 72.584455],
              [110.91465, 72.591788],
              [110.863022, 72.60242],
              [110.909361, 72.623391],
              [110.908832, 72.632042],
              [110.76136, 72.647711],
              [110.763707, 72.656219],
              [110.718647, 72.678114],
              [110.719794, 72.697254],
              [110.699496, 72.705094],
              [110.70336, 72.728384],
              [110.662144, 72.75878],
              [110.687768, 72.766692],
              [110.656684, 72.791381],
              [110.735058, 72.800483],
              [110.721088, 72.811418],
              [110.730591, 72.829548],
              [110.681791, 72.835747],
              [110.665502, 72.847813],
              [110.628665, 72.846816],
              [110.660238, 72.857189],
              [110.648535, 72.87484],
              [110.626252, 72.879091],
              [110.645702, 72.893732],
              [110.700887, 72.902967],
              [110.754645, 72.900855],
              [110.818026, 72.929062],
              [110.891484, 72.931056],
              [110.890874, 72.95037],
              [110.855904, 72.94596],
              [110.687951, 72.960546],
              [110.693503, 72.940148],
              [110.657588, 72.952045],
              [110.677426, 72.964412],
              [110.711362, 72.967572],
              [110.666563, 72.99109],
              [110.703361, 72.993518],
              [110.730581, 73.021727],
              [110.811154, 73.020106],
              [110.85986, 73.055281],
              [110.801936, 73.068365],
              [110.798904, 73.086851],
              [110.745516, 73.102943],
              [110.815013, 73.112359],
              [110.838421, 73.132597],
              [110.85016, 73.16067],
              [110.80879, 73.163509],
              [110.731348, 73.153652],
              [110.65296, 73.165108],
              [110.555196, 73.156875],
              [110.542413, 73.14966],
              [110.468167, 73.150456],
              [110.417873, 73.156964],
              [110.373798, 73.151871],
              [110.378839, 73.162808],
              [110.342491, 73.16679],
              [110.36746, 73.182387],
              [110.34862, 73.196564],
              [110.384068, 73.206652],
              [110.448026, 73.212295],
              [110.552858, 73.238491],
              [110.583074, 73.240059],
              [110.624424, 73.254833],
              [110.623502, 73.268432],
              [110.540183, 73.299727],
              [110.562694, 73.309125],
              [110.530644, 73.317006],
              [110.543538, 73.333151],
              [110.456924, 73.334729],
              [110.456182, 73.346712],
              [110.374631, 73.336874],
              [110.363407, 73.354325],
              [110.321045, 73.34964],
              [110.321897, 73.361044],
              [110.260113, 73.360056],
              [110.244841, 73.377281],
              [110.214125, 73.36996],
              [110.203449, 73.381293],
              [110.137264, 73.383828],
              [110.159445, 73.396667],
              [110.121518, 73.402718],
              [110.143616, 73.414549],
              [110.207085, 73.396613],
              [110.376366, 73.413324],
              [110.429838, 73.415689],
              [110.531354, 73.434333],
              [110.635663, 73.427318],
              [110.650285, 73.4324],
              [110.64354, 73.454194],
              [110.679469, 73.473523],
              [110.743396, 73.491785],
              [110.802711, 73.502298],
              [110.839942, 73.518475],
              [110.814072, 73.546944],
              [110.872517, 73.549427],
              [110.879011, 73.563837],
              [110.923171, 73.565576],
              [110.946944, 73.556625],
              [111.001102, 73.556337],
              [111.052562, 73.564928],
              [111.09316, 73.579424],
              [111.113794, 73.576502],
              [111.182717, 73.592124],
              [111.165888, 73.604296],
              [111.221677, 73.598841],
              [111.282728, 73.609245],
              [111.28636, 73.620639],
              [111.227305, 73.617445],
              [111.197218, 73.623456],
              [111.192979, 73.644019],
              [111.156113, 73.646706],
              [111.192815, 73.660171],
              [111.109177, 73.661831],
              [111.101221, 73.673578],
              [111.061709, 73.672093],
              [111.010336, 73.681184],
              [111.015577, 73.691043],
              [110.947657, 73.70273],
              [110.914279, 73.698611],
            ],
          ],
          [
            [
              [129.511395, 72.997609],
              [129.536086, 73.005466],
              [129.5723, 72.990805],
              [129.532976, 72.989378],
              [129.511395, 72.997609],
            ],
          ],
          [
            [
              [122.891958, 73.042996],
              [122.886671, 73.028649],
              [122.792009, 73.050468],
              [122.734221, 73.059279],
              [122.736096, 73.075646],
              [122.770439, 73.078273],
              [122.835144, 73.071566],
              [122.891958, 73.042996],
            ],
          ],
          [
            [
              [122.468945, 73.057815],
              [122.535447, 73.078854],
              [122.55285, 73.06992],
              [122.511266, 73.052576],
              [122.468945, 73.057815],
            ],
          ],
          [
            [
              [122.139032, 73.030468],
              [122.181609, 73.031958],
              [122.221062, 73.017111],
              [122.206898, 73.00491],
              [122.149382, 73.019441],
              [122.139032, 73.030468],
            ],
          ],
          [
            [
              [122.530943, 72.987158],
              [122.467097, 72.991699],
              [122.446474, 72.985967],
              [122.404808, 73.01695],
              [122.375253, 73.019268],
              [122.345965, 73.038241],
              [122.321207, 73.026194],
              [122.290485, 73.052246],
              [122.332185, 73.073599],
              [122.3739, 73.071869],
              [122.41249, 73.051621],
              [122.526446, 73.024212],
              [122.50365, 72.995077],
              [122.530943, 72.987158],
            ],
          ],
          [
            [
              [122.695797, 73.069587],
              [122.656731, 73.064513],
              [122.63168, 73.079568],
              [122.683312, 73.083914],
              [122.695797, 73.069587],
            ],
          ],
          [
            [
              [128.980886, 73.125],
              [128.947789, 73.12496],
              [128.956548, 73.13978],
              [129.006727, 73.13723],
              [128.980886, 73.125],
            ],
          ],
          [
            [
              [120.197231, 73.003574],
              [120.080897, 73.005998],
              [120.1181, 73.011062],
              [120.111721, 73.019472],
              [120.016364, 73.024863],
              [119.933361, 73.033742],
              [119.919571, 73.040332],
              [119.833917, 73.038976],
              [119.844945, 73.05289],
              [119.796851, 73.050993],
              [119.822656, 73.076263],
              [119.732136, 73.088434],
              [119.673194, 73.10225],
              [119.701486, 73.128731],
              [119.635634, 73.141602],
              [119.574112, 73.136754],
              [119.575504, 73.152078],
              [119.625358, 73.149404],
              [119.624629, 73.16207],
              [119.662491, 73.155832],
              [119.692765, 73.163367],
              [119.735188, 73.158206],
              [119.799203, 73.161311],
              [119.855943, 73.157593],
              [119.948982, 73.159992],
              [120.101365, 73.153537],
              [120.24096, 73.135786],
              [120.309269, 73.115001],
              [120.401346, 73.077168],
              [120.409922, 73.060395],
              [120.45403, 73.050446],
              [120.40941, 73.035329],
              [120.420729, 73.028232],
              [120.483611, 73.03165],
              [120.505169, 73.021175],
              [120.41836, 73.020596],
              [120.391338, 73.030256],
              [120.331562, 73.033795],
              [120.359671, 73.038709],
              [120.338346, 73.050954],
              [120.28881, 73.044079],
              [120.301192, 73.0261],
              [120.269876, 73.003833],
              [120.197231, 73.003574],
            ],
          ],
          [
            [
              [113.676407, 73.363699],
              [113.706586, 73.366992],
              [113.795274, 73.341769],
              [113.726972, 73.349149],
              [113.676407, 73.363699],
            ],
          ],
          [
            [
              [118.717078, 73.376198],
              [118.677542, 73.391853],
              [118.674178, 73.403125],
              [118.701257, 73.423608],
              [118.722727, 73.423432],
              [118.788838, 73.404987],
              [118.752735, 73.375646],
              [118.717078, 73.376198],
            ],
          ],
          [
            [
              [128.671875, 73.282338],
              [128.688352, 73.269462],
              [128.47388, 73.263537],
              [128.486647, 73.281452],
              [128.603636, 73.289118],
              [128.638449, 73.280495],
              [128.671875, 73.282338],
            ],
          ],
          [
            [
              [118.823876, 73.352035],
              [118.791883, 73.323176],
              [118.674404, 73.314186],
              [118.657215, 73.329658],
              [118.768312, 73.362225],
              [118.814769, 73.362849],
              [118.823876, 73.352035],
            ],
          ],
          [
            [
              [120.12274, 73.177643],
              [120.053192, 73.181644],
              [120.055633, 73.187057],
              [120.12274, 73.177643],
            ],
          ],
          [
            [
              [123.220284, 73.572425],
              [123.329984, 73.570948],
              [123.289916, 73.560853],
              [123.220284, 73.572425],
            ],
          ],
          [
            [
              [125.595999, 73.563501],
              [125.596081, 73.57655],
              [125.643175, 73.571677],
              [125.595999, 73.563501],
            ],
          ],
          [
            [
              [125.298334, 73.604035],
              [125.329817, 73.588462],
              [125.309705, 73.579026],
              [125.298334, 73.604035],
            ],
          ],
          [
            [
              [123.41294, 73.610039],
              [123.340253, 73.595804],
              [123.289841, 73.601938],
              [123.358914, 73.616299],
              [123.41294, 73.610039],
            ],
          ],
          [
            [
              [123.507147, 73.682924],
              [123.441802, 73.676963],
              [123.401316, 73.666636],
              [123.391534, 73.65178],
              [123.368578, 73.653758],
              [123.400782, 73.673586],
              [123.507147, 73.682924],
            ],
          ],
          [
            [
              [123.525728, 73.710607],
              [123.573591, 73.718246],
              [123.572766, 73.702217],
              [123.525728, 73.710607],
            ],
          ],
          [
            [
              [124.774939, 73.714041],
              [124.80819, 73.725198],
              [124.791335, 73.708733],
              [124.774939, 73.714041],
            ],
          ],
          [
            [
              [123.151393, 73.96209],
              [123.195724, 73.987259],
              [123.241004, 73.986464],
              [123.182306, 73.976349],
              [123.178808, 73.957195],
              [123.151393, 73.96209],
            ],
          ],
          [
            [
              [140.067896, 73.354443],
              [139.985523, 73.342225],
              [139.864969, 73.345207],
              [139.835783, 73.359531],
              [139.81624, 73.390319],
              [139.779693, 73.409244],
              [139.809134, 73.413301],
              [139.83426, 73.430898],
              [139.890095, 73.434878],
              [140.054069, 73.432086],
              [140.255005, 73.444778],
              [140.301121, 73.434717],
              [140.366627, 73.443356],
              [140.560189, 73.494948],
              [140.64552, 73.52535],
              [140.74407, 73.578792],
              [140.795544, 73.616657],
              [140.846059, 73.685932],
              [140.87053, 73.695145],
              [140.859518, 73.70689],
              [140.932942, 73.7805],
              [140.974956, 73.804012],
              [141.017987, 73.810399],
              [141.02808, 73.821638],
              [141.068893, 73.830315],
              [141.079496, 73.842318],
              [141.146604, 73.856844],
              [141.206244, 73.852707],
              [141.274756, 73.83181],
              [141.393229, 73.839673],
              [141.554847, 73.87176],
              [141.704358, 73.885884],
              [141.842219, 73.901227],
              [142.065523, 73.908157],
              [142.137983, 73.890956],
              [142.15079, 73.882422],
              [142.284928, 73.850488],
              [142.416553, 73.843526],
              [142.512233, 73.832557],
              [142.62419, 73.808064],
              [142.700243, 73.781405],
              [142.719692, 73.767176],
              [142.814753, 73.732773],
              [142.925227, 73.707466],
              [143.052449, 73.662874],
              [143.194809, 73.62928],
              [143.285683, 73.597711],
              [143.40973, 73.547171],
              [143.429055, 73.530691],
              [143.527974, 73.476107],
              [143.478736, 73.449593],
              [143.495438, 73.410532],
              [143.47812, 73.403038],
              [143.4706, 73.355057],
              [143.480557, 73.309816],
              [143.504033, 73.270263],
              [143.54316, 73.230857],
              [143.607925, 73.209693],
              [143.549341, 73.206084],
              [143.484528, 73.212512],
              [143.35226, 73.18977],
              [143.23629, 73.181086],
              [143.016072, 73.200734],
              [142.90204, 73.208714],
              [142.734227, 73.228206],
              [142.641415, 73.229602],
              [142.497961, 73.218805],
              [142.326748, 73.247059],
              [142.10664, 73.254993],
              [141.90884, 73.268985],
              [141.821498, 73.27893],
              [141.735563, 73.282573],
              [141.407962, 73.31822],
              [141.234928, 73.348889],
              [141.065755, 73.383756],
              [141.004146, 73.390153],
              [140.93978, 73.405728],
              [140.795647, 73.421604],
              [140.642504, 73.427147],
              [140.543081, 73.423985],
              [140.388501, 73.410583],
              [140.31248, 73.398571],
              [140.248758, 73.375786],
              [140.126205, 73.36383],
              [140.067896, 73.354443],
            ],
          ],
          [
            [
              [124.772536, 73.87505],
              [124.8317, 73.887091],
              [124.782606, 73.868079],
              [124.785815, 73.853675],
              [124.753276, 73.851368],
              [124.772536, 73.87505],
            ],
          ],
          [
            [
              [123.743159, 73.823798],
              [123.764768, 73.834933],
              [123.830473, 73.846587],
              [123.791305, 73.827665],
              [123.776988, 73.803964],
              [123.743159, 73.823798],
            ],
          ],
          [
            [
              [140.625, 73.89618],
              [140.526016, 73.899462],
              [140.386292, 73.929337],
              [140.337379, 73.952951],
              [140.2968, 73.994721],
              [140.21561, 74.027143],
              [140.173116, 74.078592],
              [140.174063, 74.115129],
              [140.190187, 74.14738],
              [140.20109, 74.201326],
              [140.238076, 74.222662],
              [140.286272, 74.237131],
              [140.419051, 74.256428],
              [140.53225, 74.264132],
              [140.69565, 74.270076],
              [140.871665, 74.268121],
              [140.981657, 74.254049],
              [141.072509, 74.224739],
              [141.104965, 74.191067],
              [141.099695, 74.162551],
              [141.071571, 74.132268],
              [141.03978, 74.1167],
              [141.062898, 74.099012],
              [141.073542, 74.064788],
              [141.04051, 74.007252],
              [140.98069, 73.980535],
              [140.841221, 73.97015],
              [140.753163, 73.945143],
              [140.672853, 73.944525],
              [140.64998, 73.934526],
              [140.528764, 73.914495],
              [140.515178, 73.906418],
              [140.564242, 73.897071],
              [140.625, 73.89618],
            ],
          ],
          [
            [
              [136.069398, 73.920178],
              [136.074986, 73.930988],
              [136.017129, 73.961133],
              [135.909383, 73.994112],
              [135.879616, 73.998273],
              [135.756777, 74.0336],
              [135.719883, 74.071268],
              [135.713175, 74.089092],
              [135.615071, 74.123995],
              [135.589078, 74.164658],
              [135.558054, 74.163743],
              [135.549669, 74.133855],
              [135.502294, 74.132136],
              [135.449469, 74.158708],
              [135.470012, 74.207274],
              [135.439826, 74.242607],
              [135.529545, 74.225292],
              [135.600817, 74.222898],
              [135.659931, 74.197915],
              [135.757196, 74.163972],
              [136.074986, 74.065974],
              [136.09972, 74.037867],
              [136.171412, 74.003473],
              [136.272032, 73.980003],
              [136.25358, 73.961943],
              [136.289221, 73.942589],
              [136.299283, 73.91485],
              [136.22591, 73.895324],
              [136.219626, 73.884389],
              [136.179378, 73.878103],
              [136.098463, 73.905439],
              [136.069398, 73.920178],
            ],
          ],
          [
            [
              [124.876171, 73.904721],
              [124.951846, 73.891569],
              [124.900694, 73.882149],
              [124.868691, 73.895872],
              [124.876171, 73.904721],
            ],
          ],
          [
            [
              [123.143233, 73.899429],
              [123.208137, 73.907056],
              [123.152361, 73.894032],
              [123.143233, 73.899429],
            ],
          ],
          [
            [
              [124.720572, 73.935219],
              [124.751331, 73.930708],
              [124.799167, 73.909884],
              [124.829864, 73.919956],
              [124.826553, 73.900177],
              [124.795082, 73.904171],
              [124.728114, 73.89363],
              [124.698886, 73.867695],
              [124.660699, 73.860522],
              [124.66653, 73.845275],
              [124.711363, 73.849764],
              [124.719555, 73.837637],
              [124.668634, 73.842501],
              [124.649985, 73.824196],
              [124.613816, 73.83242],
              [124.538449, 73.818694],
              [124.51486, 73.827805],
              [124.430016, 73.825494],
              [124.381562, 73.837773],
              [124.345028, 73.85978],
              [124.28271, 73.858671],
              [124.264236, 73.868511],
              [124.343644, 73.884343],
              [124.444574, 73.882926],
              [124.453384, 73.899507],
              [124.415685, 73.89896],
              [124.406818, 73.909273],
              [124.553628, 73.930037],
              [124.675774, 73.936754],
              [124.720572, 73.935219],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5542",
      properties: {
        name: "Республика Хакасия",
        density: 0,
        path: "/world/Russia/Республика Хакасия",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.375832, 52.177457],
            [88.410632, 52.163467],
            [88.441009, 52.163754],
            [88.475288, 52.170883],
            [88.477939, 52.180842],
            [88.523069, 52.193471],
            [88.541756, 52.216066],
            [88.563867, 52.211553],
            [88.584203, 52.21711],
            [88.629626, 52.241173],
            [88.634362, 52.24878],
            [88.666294, 52.252735],
            [88.684737, 52.240756],
            [88.707507, 52.255952],
            [88.700993, 52.270618],
            [88.716037, 52.298651],
            [88.708339, 52.325467],
            [88.676496, 52.33306],
            [88.694844, 52.349283],
            [88.699479, 52.363788],
            [88.721042, 52.36865],
            [88.762539, 52.38594],
            [88.773459, 52.406177],
            [88.801628, 52.412531],
            [88.805355, 52.420444],
            [88.857319, 52.432426],
            [88.881763, 52.44887],
            [88.889346, 52.46457],
            [88.928, 52.471142],
            [88.939137, 52.487435],
            [88.986759, 52.495625],
            [88.994094, 52.509122],
            [88.98807, 52.526828],
            [89.007008, 52.532849],
            [89.013374, 52.552605],
            [89.059463, 52.563767],
            [89.055466, 52.580549],
            [89.062215, 52.59536],
            [89.083305, 52.599332],
            [89.110396, 52.58785],
            [89.129269, 52.610529],
            [89.076756, 52.636382],
            [89.084592, 52.663425],
            [89.058029, 52.686697],
            [89.053054, 52.705816],
            [89.083779, 52.709502],
            [89.112102, 52.703886],
            [89.137409, 52.706822],
            [89.131145, 52.724956],
            [89.137817, 52.735184],
            [89.159296, 52.731643],
            [89.178573, 52.752761],
            [89.19543, 52.787778],
            [89.239749, 52.800688],
            [89.2194, 52.857751],
            [89.176158, 52.883115],
            [89.125252, 52.863459],
            [89.102454, 52.881767],
            [89.116679, 52.903341],
            [89.084353, 52.938883],
            [89.075433, 52.958189],
            [89.05162, 52.964847],
            [89.01922, 52.960763],
            [88.972378, 52.965951],
            [88.958572, 52.95036],
            [88.941186, 52.964502],
            [88.934819, 52.982923],
            [88.912551, 52.989647],
            [88.941928, 53.03097],
            [88.914554, 53.034328],
            [88.920496, 53.045948],
            [88.935776, 53.045581],
            [89.008181, 53.076792],
            [89.025342, 53.093153],
            [89.012572, 53.103182],
            [89.018389, 53.117654],
            [89.062157, 53.131653],
            [89.076221, 53.158765],
            [89.065468, 53.165612],
            [89.029165, 53.165939],
            [89.005738, 53.19619],
            [89.015519, 53.206254],
            [89.045678, 53.219715],
            [89.05536, 53.235665],
            [89.034636, 53.262663],
            [89.036455, 53.280057],
            [89.003842, 53.287091],
            [88.987426, 53.301163],
            [88.945876, 53.317429],
            [88.951507, 53.342117],
            [88.927784, 53.355883],
            [88.910521, 53.392139],
            [88.880464, 53.394102],
            [88.822868, 53.368653],
            [88.806406, 53.343808],
            [88.780016, 53.342421],
            [88.758809, 53.355877],
            [88.75786, 53.376288],
            [88.786875, 53.382874],
            [88.805646, 53.419817],
            [88.820263, 53.426434],
            [88.805481, 53.437065],
            [88.82119, 53.466738],
            [88.839869, 53.472338],
            [88.859936, 53.464],
            [88.876085, 53.498],
            [88.857273, 53.530762],
            [88.880931, 53.542106],
            [88.88733, 53.562521],
            [88.860797, 53.565613],
            [88.845743, 53.577739],
            [88.838829, 53.595685],
            [88.874174, 53.597039],
            [88.891366, 53.608186],
            [88.933916, 53.611549],
            [88.926461, 53.625703],
            [88.948332, 53.646638],
            [88.97477, 53.644837],
            [88.994553, 53.656878],
            [88.977776, 53.663476],
            [89.002941, 53.687142],
            [89.010713, 53.72828],
            [89.04309, 53.752827],
            [89.044724, 53.79211],
            [89.07645, 53.794474],
            [89.091021, 53.788571],
            [89.11074, 53.79328],
            [89.127828, 53.787626],
            [89.14591, 53.801516],
            [89.188202, 53.795978],
            [89.218642, 53.805365],
            [89.237828, 53.818508],
            [89.237377, 53.838319],
            [89.197677, 53.850114],
            [89.165088, 53.885376],
            [89.175002, 53.897683],
            [89.200816, 53.906073],
            [89.207976, 53.920221],
            [89.194915, 53.933714],
            [89.194799, 53.952626],
            [89.17002, 53.954979],
            [89.142063, 53.948524],
            [89.131373, 53.954363],
            [89.074112, 53.956496],
            [89.050639, 53.938406],
            [89.041741, 53.940037],
            [89.041655, 53.960798],
            [89.061692, 53.972464],
            [89.007465, 54.000501],
            [89.006933, 54.013103],
            [89.023913, 54.02596],
            [89.014226, 54.036588],
            [89.005812, 54.06533],
            [89.01232, 54.080741],
            [89.040398, 54.098582],
            [89.041004, 54.108859],
            [89.086131, 54.108739],
            [89.098869, 54.118507],
            [89.127271, 54.117711],
            [89.135651, 54.129098],
            [89.159697, 54.135923],
            [89.201, 54.1834],
            [89.194711, 54.206289],
            [89.211309, 54.213181],
            [89.20699, 54.229196],
            [89.21672, 54.24977],
            [89.238394, 54.265603],
            [89.209741, 54.26738],
            [89.191946, 54.290004],
            [89.202405, 54.299327],
            [89.16118, 54.310003],
            [89.15976, 54.341127],
            [89.125804, 54.345714],
            [89.079956, 54.338724],
            [89.075554, 54.330119],
            [89.029732, 54.331375],
            [89.028477, 54.314152],
            [88.999211, 54.287349],
            [88.974605, 54.288641],
            [88.987123, 54.299554],
            [88.972936, 54.317506],
            [88.938367, 54.320394],
            [88.953502, 54.349514],
            [88.944188, 54.367799],
            [88.927829, 54.370738],
            [88.927541, 54.38632],
            [88.906129, 54.403487],
            [88.867904, 54.401112],
            [88.859688, 54.417708],
            [88.83563, 54.420038],
            [88.827133, 54.403222],
            [88.80741, 54.385801],
            [88.788401, 54.389057],
            [88.75842, 54.374098],
            [88.740377, 54.359258],
            [88.696615, 54.347217],
            [88.69484, 54.336242],
            [88.663695, 54.328411],
            [88.66442, 54.316153],
            [88.651612, 54.293439],
            [88.630714, 54.281594],
            [88.621098, 54.290286],
            [88.594107, 54.286127],
            [88.571659, 54.297064],
            [88.5812, 54.323219],
            [88.558052, 54.337789],
            [88.516723, 54.335887],
            [88.498421, 54.348067],
            [88.45478, 54.356446],
            [88.435603, 54.349481],
            [88.394309, 54.353236],
            [88.367763, 54.372208],
            [88.363813, 54.382062],
            [88.380624, 54.397388],
            [88.421954, 54.393341],
            [88.465218, 54.409471],
            [88.503846, 54.416481],
            [88.524409, 54.415688],
            [88.545095, 54.434451],
            [88.530751, 54.438141],
            [88.518374, 54.45649],
            [88.542332, 54.458624],
            [88.537757, 54.467244],
            [88.568741, 54.47939],
            [88.563371, 54.485822],
            [88.574052, 54.506016],
            [88.56582, 54.517836],
            [88.592093, 54.54169],
            [88.574997, 54.575683],
            [88.588558, 54.590191],
            [88.578944, 54.60631],
            [88.608022, 54.642431],
            [88.606885, 54.660401],
            [88.6198, 54.67476],
            [88.596995, 54.671376],
            [88.595401, 54.68305],
            [88.625769, 54.700898],
            [88.648647, 54.725857],
            [88.635811, 54.756529],
            [88.659798, 54.76744],
            [88.718436, 54.765672],
            [88.732453, 54.768861],
            [88.754163, 54.790547],
            [88.747868, 54.840162],
            [88.758422, 54.848333],
            [88.738961, 54.867757],
            [88.747198, 54.879469],
            [88.732001, 54.892945],
            [88.688967, 54.882552],
            [88.687834, 54.875196],
            [88.646555, 54.896987],
            [88.662633, 54.924175],
            [88.676801, 54.929747],
            [88.665424, 54.944231],
            [88.673682, 54.949111],
            [88.65381, 54.965732],
            [88.628385, 54.957931],
            [88.606291, 54.963456],
            [88.599541, 55.001986],
            [88.550427, 55.001677],
            [88.556563, 55.016892],
            [88.539308, 55.035888],
            [88.550186, 55.051339],
            [88.560741, 55.119048],
            [88.544084, 55.135912],
            [88.518298, 55.143557],
            [88.525117, 55.179077],
            [88.501839, 55.191116],
            [88.51857, 55.202245],
            [88.51001, 55.213393],
            [88.48995, 55.212695],
            [88.488049, 55.239229],
            [88.446518, 55.262224],
            [88.432544, 55.281874],
            [88.446689, 55.289678],
            [88.442366, 55.304549],
            [88.472795, 55.315699],
            [88.490334, 55.329812],
            [88.535042, 55.342633],
            [88.542311, 55.365963],
            [88.569312, 55.375725],
            [88.573088, 55.397196],
            [88.611029, 55.41834],
            [88.601258, 55.427377],
            [88.621307, 55.434932],
            [88.657559, 55.431521],
            [88.668924, 55.437878],
            [88.70922, 55.429377],
            [88.742338, 55.431879],
            [88.750821, 55.392331],
            [88.792774, 55.408678],
            [88.796978, 55.398554],
            [88.84465, 55.391199],
            [88.874789, 55.391738],
            [88.869843, 55.367371],
            [88.900361, 55.340356],
            [88.968233, 55.343534],
            [88.970614, 55.330049],
            [88.99724, 55.320614],
            [89.011422, 55.323647],
            [89.009895, 55.233704],
            [89.086482, 55.189799],
            [89.115942, 55.180122],
            [89.092232, 55.166117],
            [89.061335, 55.128288],
            [89.064119, 55.120055],
            [89.124025, 55.120452],
            [89.170443, 55.124705],
            [89.24112, 55.111902],
            [89.27278, 55.094979],
            [89.270908, 55.059318],
            [89.278195, 55.042068],
            [89.329931, 55.040314],
            [89.371978, 55.04258],
            [89.38204, 55.022877],
            [89.423039, 55.011289],
            [89.453832, 55.012713],
            [89.437054, 55.02391],
            [89.438259, 55.044098],
            [89.43036, 55.062463],
            [89.540107, 55.068627],
            [89.562187, 55.088492],
            [89.580251, 55.087853],
            [89.597068, 55.067954],
            [89.627795, 55.0698],
            [89.646806, 55.079748],
            [89.661038, 55.073468],
            [89.63597, 55.070827],
            [89.624329, 55.059897],
            [89.627189, 55.047129],
            [89.607299, 55.028163],
            [89.664379, 55.000658],
            [89.683324, 55.006839],
            [89.730913, 55.04482],
            [89.724446, 55.068499],
            [89.83429, 55.085191],
            [89.882704, 55.082825],
            [89.891708, 55.102379],
            [89.90575, 55.097058],
            [89.92036, 55.108077],
            [89.995627, 55.124243],
            [90.015134, 55.118353],
            [90.036775, 55.123109],
            [90.04576, 55.116027],
            [90.076843, 55.119825],
            [90.091258, 55.110759],
            [90.120482, 55.109682],
            [90.132867, 55.131174],
            [90.126284, 55.153501],
            [90.216932, 55.153584],
            [90.216071, 55.131556],
            [90.249609, 55.112082],
            [90.24387, 55.078872],
            [90.271273, 55.076059],
            [90.295343, 55.066437],
            [90.36589, 55.066338],
            [90.367261, 55.076371],
            [90.397906, 55.073421],
            [90.439813, 55.060747],
            [90.463409, 55.072154],
            [90.499215, 55.060849],
            [90.526867, 55.023657],
            [90.52982, 54.989815],
            [90.507234, 54.904443],
            [90.637298, 54.904927],
            [90.678864, 54.915962],
            [90.741339, 54.915973],
            [90.779157, 54.912112],
            [90.780531, 54.871266],
            [90.945677, 54.848132],
            [90.940488, 54.836551],
            [90.944518, 54.779888],
            [90.914141, 54.722113],
            [90.918748, 54.653634],
            [90.942466, 54.637361],
            [91.010093, 54.616662],
            [91.030075, 54.605106],
            [91.09651, 54.586661],
            [91.170756, 54.54556],
            [91.217608, 54.52807],
            [91.260237, 54.506586],
            [91.268225, 54.461612],
            [91.249282, 54.438577],
            [91.250915, 54.414755],
            [91.276786, 54.380733],
            [91.311651, 54.351286],
            [91.310988, 54.33155],
            [91.358511, 54.318032],
            [91.416507, 54.279602],
            [91.434076, 54.259431],
            [91.458375, 54.215508],
            [91.52951, 54.124141],
            [91.573678, 54.091245],
            [91.580759, 54.063137],
            [91.564056, 54.019147],
            [91.521131, 53.964043],
            [91.480156, 53.945136],
            [91.427376, 53.861709],
            [91.44478, 53.803129],
            [91.461967, 53.770166],
            [91.487686, 53.737015],
            [91.505855, 53.735917],
            [91.524552, 53.720482],
            [91.567944, 53.708647],
            [91.577792, 53.687712],
            [91.571485, 53.659669],
            [91.538996, 53.641577],
            [91.557796, 53.630012],
            [91.567396, 53.596858],
            [91.59253, 53.584128],
            [91.623919, 53.576365],
            [91.631648, 53.564612],
            [91.625096, 53.533874],
            [91.657052, 53.519714],
            [91.761159, 53.492379],
            [91.842762, 53.457089],
            [91.863571, 53.456917],
            [91.864219, 53.436738],
            [91.879319, 53.42512],
            [91.903467, 53.426054],
            [91.906587, 53.400345],
            [91.918126, 53.390967],
            [91.905242, 53.377863],
            [91.919645, 53.367065],
            [91.923697, 53.349601],
            [91.907072, 53.334663],
            [91.864175, 53.313412],
            [91.840148, 53.292922],
            [91.807835, 53.243766],
            [91.774662, 53.228352],
            [91.738462, 53.177746],
            [91.676469, 53.166895],
            [91.629837, 53.145196],
            [91.575916, 53.139195],
            [91.527398, 53.157364],
            [91.489048, 53.159076],
            [91.449972, 53.135703],
            [91.439671, 53.111194],
            [91.419335, 53.089087],
            [91.433847, 53.073997],
            [91.444315, 53.038623],
            [91.459194, 53.022867],
            [91.496401, 53.004598],
            [91.505196, 52.993749],
            [91.493693, 52.957787],
            [91.475508, 52.943589],
            [91.465897, 52.923675],
            [91.475536, 52.894465],
            [91.458933, 52.886229],
            [91.430373, 52.885749],
            [91.42009, 52.878011],
            [91.427931, 52.854537],
            [91.419238, 52.841608],
            [91.387412, 52.83389],
            [91.34264, 52.805121],
            [91.332282, 52.787034],
            [91.314188, 52.776589],
            [91.321925, 52.733034],
            [91.287223, 52.716196],
            [91.270632, 52.715598],
            [91.258797, 52.697665],
            [91.263077, 52.673303],
            [91.256921, 52.664536],
            [91.257868, 52.636738],
            [91.233304, 52.638615],
            [91.228259, 52.615591],
            [91.190706, 52.632722],
            [91.185164, 52.625229],
            [91.118591, 52.633616],
            [91.073766, 52.623062],
            [91.011261, 52.615372],
            [90.956857, 52.599768],
            [90.894977, 52.600995],
            [90.885794, 52.589605],
            [90.831763, 52.555171],
            [90.822664, 52.552573],
            [90.803863, 52.526558],
            [90.758913, 52.498283],
            [90.73812, 52.477165],
            [90.720166, 52.470235],
            [90.693913, 52.43867],
            [90.705793, 52.430385],
            [90.706567, 52.408439],
            [90.684521, 52.377253],
            [90.688385, 52.369668],
            [90.66857, 52.350257],
            [90.654951, 52.345888],
            [90.638078, 52.327075],
            [90.589912, 52.311008],
            [90.591224, 52.303822],
            [90.568068, 52.298085],
            [90.558904, 52.261357],
            [90.531812, 52.233167],
            [90.509606, 52.227962],
            [90.512743, 52.211769],
            [90.496156, 52.209786],
            [90.486733, 52.195871],
            [90.43472, 52.179217],
            [90.396362, 52.185383],
            [90.372189, 52.20221],
            [90.34982, 52.201493],
            [90.320446, 52.17825],
            [90.297104, 52.170093],
            [90.285577, 52.177265],
            [90.253919, 52.175734],
            [90.239452, 52.158935],
            [90.193549, 52.155227],
            [90.179742, 52.14748],
            [90.164413, 52.153137],
            [90.143443, 52.148488],
            [90.14716, 52.134761],
            [90.140327, 52.111178],
            [90.148633, 52.104637],
            [90.158465, 52.060766],
            [90.137946, 52.046988],
            [90.13124, 52.033886],
            [90.111641, 52.033575],
            [90.097955, 52.017581],
            [90.075817, 52.008289],
            [90.068908, 51.989341],
            [90.045184, 51.973643],
            [90.01302, 51.967516],
            [90.011789, 51.958472],
            [90.030693, 51.936257],
            [90.060964, 51.936352],
            [90.059192, 51.906475],
            [90.067046, 51.861113],
            [90.083334, 51.832297],
            [90.081351, 51.819487],
            [90.122623, 51.78277],
            [90.136214, 51.764436],
            [90.126676, 51.754936],
            [90.091673, 51.744294],
            [90.079633, 51.730862],
            [90.048108, 51.745316],
            [90.030883, 51.746819],
            [90.034404, 51.756298],
            [90.010591, 51.762722],
            [89.952207, 51.755495],
            [89.926926, 51.757194],
            [89.923436, 51.740522],
            [89.903588, 51.729985],
            [89.89444, 51.703565],
            [89.854276, 51.681292],
            [89.845403, 51.667365],
            [89.810866, 51.649009],
            [89.806998, 51.620814],
            [89.739988, 51.584154],
            [89.709556, 51.562191],
            [89.663736, 51.547449],
            [89.658952, 51.539275],
            [89.632816, 51.54758],
            [89.605862, 51.532166],
            [89.573547, 51.559843],
            [89.53927, 51.567541],
            [89.508172, 51.586279],
            [89.463699, 51.590393],
            [89.439673, 51.606774],
            [89.371724, 51.605179],
            [89.337229, 51.594342],
            [89.296637, 51.571694],
            [89.281275, 51.549685],
            [89.24937, 51.543225],
            [89.236473, 51.554119],
            [89.232859, 51.58383],
            [89.243069, 51.594725],
            [89.242696, 51.615045],
            [89.25368, 51.618754],
            [89.263824, 51.641242],
            [89.250426, 51.653595],
            [89.20975, 51.658379],
            [89.167079, 51.636471],
            [89.133811, 51.635777],
            [89.122516, 51.641807],
            [89.086614, 51.623982],
            [89.08536, 51.604429],
            [89.073396, 51.588621],
            [89.017031, 51.584952],
            [89.002642, 51.571226],
            [88.979509, 51.573712],
            [88.950574, 51.562961],
            [88.925857, 51.571503],
            [88.871952, 51.562409],
            [88.852586, 51.570949],
            [88.832828, 51.561837],
            [88.791531, 51.553761],
            [88.772891, 51.55031],
            [88.763075, 51.561429],
            [88.750393, 51.545358],
            [88.732151, 51.545532],
            [88.705309, 51.534739],
            [88.67864, 51.537432],
            [88.658053, 51.52805],
            [88.672125, 51.499579],
            [88.685329, 51.492282],
            [88.694016, 51.468502],
            [88.680552, 51.44444],
            [88.693842, 51.440444],
            [88.692365, 51.422245],
            [88.702876, 51.393362],
            [88.687762, 51.385631],
            [88.674644, 51.391885],
            [88.65397, 51.382069],
            [88.636336, 51.386999],
            [88.619744, 51.37145],
            [88.621656, 51.353403],
            [88.595487, 51.336055],
            [88.59325, 51.325171],
            [88.572836, 51.319959],
            [88.554768, 51.325345],
            [88.510355, 51.300079],
            [88.469377, 51.306495],
            [88.445489, 51.285126],
            [88.424641, 51.286255],
            [88.401447, 51.308059],
            [88.373389, 51.310773],
            [88.348545, 51.322348],
            [88.320139, 51.328342],
            [88.315101, 51.344413],
            [88.302419, 51.340417],
            [88.258637, 51.353447],
            [88.187319, 51.359006],
            [88.167166, 51.345802],
            [88.156395, 51.348495],
            [88.147274, 51.371211],
            [88.133288, 51.374165],
            [88.143278, 51.386152],
            [88.125209, 51.3951],
            [88.141106, 51.428543],
            [88.119042, 51.430628],
            [88.097325, 51.447676],
            [88.066053, 51.462878],
            [88.022706, 51.467004],
            [87.983963, 51.466309],
            [87.941398, 51.478926],
            [87.951736, 51.485355],
            [87.934449, 51.526312],
            [87.900397, 51.551547],
            [87.946871, 51.550679],
            [87.959032, 51.576348],
            [88.000642, 51.58864],
            [88.004116, 51.641954],
            [88.028874, 51.667971],
            [88.054326, 51.666929],
            [88.076042, 51.674834],
            [88.077867, 51.691165],
            [88.097933, 51.696399],
            [88.106794, 51.70856],
            [88.10219, 51.722589],
            [88.110094, 51.740266],
            [88.084121, 51.758335],
            [88.03617, 51.751733],
            [87.988046, 51.75182],
            [87.963289, 51.767304],
            [87.948782, 51.768346],
            [87.932712, 51.784547],
            [87.884761, 51.780985],
            [87.89145, 51.812562],
            [87.934536, 51.848568],
            [87.945307, 51.885704],
            [87.969804, 51.890264],
            [87.989523, 51.902078],
            [88.003769, 51.92095],
            [88.001771, 51.941712],
            [87.989262, 51.940148],
            [87.97684, 51.952266],
            [87.993432, 51.956262],
            [88.000034, 51.968097],
            [88.028613, 51.964275],
            [88.034433, 51.975481],
            [88.087596, 52.000021],
            [88.068398, 52.017243],
            [88.057627, 52.016808],
            [88.057279, 52.03631],
            [88.066661, 52.049688],
            [88.084642, 52.05225],
            [88.126426, 52.068234],
            [88.151791, 52.095988],
            [88.195224, 52.058331],
            [88.25247, 52.0458],
            [88.252557, 52.08198],
            [88.289041, 52.101547],
            [88.286261, 52.111732],
            [88.306414, 52.109908],
            [88.348632, 52.118443],
            [88.366874, 52.115663],
            [88.378253, 52.12461],
            [88.361228, 52.132515],
            [88.360185, 52.146284],
            [88.374518, 52.154623],
            [88.363573, 52.169065],
            [88.375832, 52.177457],
          ],
          [
            [88.634506, 55.31622],
            [88.651407, 55.299072],
            [88.664144, 55.303534],
            [88.646141, 55.318868],
            [88.634506, 55.31622],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5494",
      properties: {
        name: "Ростовская область",
        density: 0,
        path: "/world/Russia/Ростовская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [40.135392, 49.617102],
            [40.16067, 49.643123],
            [40.212694, 49.630173],
            [40.277485, 49.628409],
            [40.374629, 49.587519],
            [40.461434, 49.579025],
            [40.50815, 49.585157],
            [40.535614, 49.577769],
            [40.549094, 49.580892],
            [40.560794, 49.604971],
            [40.575904, 49.622558],
            [40.59628, 49.635017],
            [40.639862, 49.637089],
            [40.642174, 49.644334],
            [40.615951, 49.643587],
            [40.636967, 49.672992],
            [40.720055, 49.666012],
            [40.778987, 49.667234],
            [40.779277, 49.685124],
            [40.795504, 49.685151],
            [40.795915, 49.701203],
            [40.921617, 49.700629],
            [40.961071, 49.736528],
            [41.004022, 49.782426],
            [41.012444, 49.79851],
            [41.040879, 49.79981],
            [41.047121, 49.809186],
            [41.041246, 49.866913],
            [41.082125, 49.943371],
            [41.108252, 49.961177],
            [41.192887, 50.011776],
            [41.297755, 50.021471],
            [41.306175, 50.042833],
            [41.324701, 50.047593],
            [41.338275, 50.068574],
            [41.38435, 50.08111],
            [41.38366, 50.113865],
            [41.372992, 50.127898],
            [41.34569, 50.145173],
            [41.288934, 50.16324],
            [41.338107, 50.208881],
            [41.350255, 50.214477],
            [41.372793, 50.201755],
            [41.400994, 50.202806],
            [41.456458, 50.131195],
            [41.484191, 50.10311],
            [41.492899, 50.082891],
            [41.574625, 50.061049],
            [41.610802, 50.041515],
            [41.592494, 50.02029],
            [41.576483, 49.992399],
            [41.617367, 49.959574],
            [41.722128, 49.943959],
            [41.751389, 49.918476],
            [41.813886, 49.912302],
            [41.83972, 49.918772],
            [41.87773, 49.907268],
            [41.920794, 49.850074],
            [41.984923, 49.835096],
            [41.998759, 49.825964],
            [42.064703, 49.770174],
            [42.136251, 49.732863],
            [42.167569, 49.704099],
            [42.184355, 49.675666],
            [42.130499, 49.595313],
            [42.124338, 49.592714],
            [42.118558, 49.553812],
            [42.103262, 49.516963],
            [42.081482, 49.51253],
            [42.071475, 49.488621],
            [42.079416, 49.481835],
            [42.063311, 49.471003],
            [42.059534, 49.43157],
            [42.039484, 49.405585],
            [42.045053, 49.386119],
            [42.027719, 49.358697],
            [42.029998, 49.342203],
            [42.078087, 49.340972],
            [42.071349, 49.311787],
            [42.101571, 49.317076],
            [42.075661, 49.286704],
            [42.105854, 49.272548],
            [42.085354, 49.257317],
            [42.068084, 49.267257],
            [42.047344, 49.20989],
            [42.098703, 49.195383],
            [42.081462, 49.170547],
            [42.032237, 49.147433],
            [42.021112, 49.133835],
            [42.064725, 49.126669],
            [42.069082, 49.130259],
            [42.189283, 49.122345],
            [42.339804, 49.139264],
            [42.359233, 49.128754],
            [42.364654, 49.104401],
            [42.381409, 49.109262],
            [42.387197, 49.062182],
            [42.361722, 49.061428],
            [42.350433, 49.050401],
            [42.38979, 49.013372],
            [42.41803, 49.003343],
            [42.467184, 49.009866],
            [42.473589, 49.003156],
            [42.500368, 49.010149],
            [42.554753, 48.9739],
            [42.610108, 48.962434],
            [42.637566, 48.9596],
            [42.682567, 48.935906],
            [42.68149, 48.875137],
            [42.73026, 48.844013],
            [42.747807, 48.822529],
            [42.745068, 48.774256],
            [42.665464, 48.724617],
            [42.657963, 48.700576],
            [42.672559, 48.671092],
            [42.670158, 48.660325],
            [42.708659, 48.613181],
            [42.702115, 48.613077],
            [42.704482, 48.586598],
            [42.673221, 48.584854],
            [42.66253, 48.590596],
            [42.646141, 48.577273],
            [42.645076, 48.557393],
            [42.628896, 48.55752],
            [42.62202, 48.542907],
            [42.606795, 48.546791],
            [42.57153, 48.536857],
            [42.559716, 48.541897],
            [42.527661, 48.521032],
            [42.504981, 48.524545],
            [42.489046, 48.515595],
            [42.437117, 48.503217],
            [42.430525, 48.513456],
            [42.402934, 48.504808],
            [42.411578, 48.513687],
            [42.39608, 48.518632],
            [42.362682, 48.497345],
            [42.349585, 48.512285],
            [42.317278, 48.51603],
            [42.277304, 48.506578],
            [42.220617, 48.481455],
            [42.212249, 48.466184],
            [42.15072, 48.439636],
            [42.085691, 48.415706],
            [42.1247, 48.368584],
            [42.181006, 48.276915],
            [42.062792, 48.265648],
            [42.063066, 48.234135],
            [42.088673, 48.233794],
            [42.12905, 48.140898],
            [42.080752, 48.13612],
            [42.074472, 48.120535],
            [42.03161, 48.10616],
            [42.020522, 48.091585],
            [42.030365, 48.073091],
            [42.029996, 48.055495],
            [42.017706, 48.047625],
            [42.038065, 48.034178],
            [42.020433, 48.023011],
            [42.046224, 48.027148],
            [42.052393, 48.013022],
            [42.068134, 48.021921],
            [42.09303, 48.018808],
            [42.095019, 48.027099],
            [42.120019, 48.017316],
            [42.129761, 48.024694],
            [42.172242, 48.007568],
            [42.216126, 48.032691],
            [42.338384, 48.027268],
            [42.352163, 48.04424],
            [42.364317, 48.040216],
            [42.372059, 48.022661],
            [42.383199, 48.02295],
            [42.39162, 48.00933],
            [42.446936, 48.023456],
            [42.525202, 48.018644],
            [42.547152, 47.971845],
            [42.56435, 47.961393],
            [42.62961, 47.908836],
            [42.720173, 47.87587],
            [42.75922, 47.760653],
            [42.792718, 47.712903],
            [42.803383, 47.709857],
            [42.846395, 47.671727],
            [42.843235, 47.644216],
            [42.868766, 47.63316],
            [42.925543, 47.519058],
            [42.92499, 47.507356],
            [42.986591, 47.505497],
            [43.011999, 47.517609],
            [43.027956, 47.534117],
            [43.052624, 47.543715],
            [43.049853, 47.553617],
            [43.164356, 47.553664],
            [43.172718, 47.544429],
            [43.145453, 47.528941],
            [43.157179, 47.517079],
            [43.1604, 47.499896],
            [43.150106, 47.476388],
            [43.155268, 47.466015],
            [43.194611, 47.456157],
            [43.251113, 47.447352],
            [43.314577, 47.447569],
            [43.402113, 47.470062],
            [43.436895, 47.471989],
            [43.587169, 47.485709],
            [43.647018, 47.456461],
            [43.656744, 47.443765],
            [43.662467, 47.453522],
            [43.686675, 47.450155],
            [43.711218, 47.474225],
            [43.786743, 47.354211],
            [43.793497, 47.26782],
            [43.939513, 47.25875],
            [43.925687, 47.328734],
            [43.964133, 47.40023],
            [44.109607, 47.414056],
            [44.132611, 47.413516],
            [44.13358, 47.544736],
            [44.185852, 47.536528],
            [44.186391, 47.550892],
            [44.230995, 47.550569],
            [44.230889, 47.478641],
            [44.310809, 47.478534],
            [44.310594, 47.413518],
            [44.317545, 47.413347],
            [44.319992, 47.359303],
            [44.318049, 47.305411],
            [44.319886, 47.264587],
            [44.319242, 47.140927],
            [44.285765, 47.047075],
            [44.264923, 46.979791],
            [44.256393, 46.899115],
            [44.151636, 46.82319],
            [44.086836, 46.835502],
            [44.048389, 46.800077],
            [44.033594, 46.764545],
            [43.915336, 46.71508],
            [43.903025, 46.685596],
            [43.911557, 46.677928],
            [43.880131, 46.625224],
            [43.82354, 46.598763],
            [43.808097, 46.574571],
            [43.872897, 46.551784],
            [43.930569, 46.535045],
            [43.930246, 46.494221],
            [43.913615, 46.446917],
            [43.91286, 46.419485],
            [43.93014, 46.419269],
            [43.922905, 46.388381],
            [43.975825, 46.360302],
            [43.957575, 46.319154],
            [43.894935, 46.300253],
            [43.860376, 46.271525],
            [43.846876, 46.29118],
            [43.885215, 46.315049],
            [43.847739, 46.332004],
            [43.824302, 46.35166],
            [43.815338, 46.384384],
            [43.806266, 46.383627],
            [43.733905, 46.410843],
            [43.72343, 46.394966],
            [43.745787, 46.363431],
            [43.72948, 46.335351],
            [43.687793, 46.288046],
            [43.623158, 46.177832],
            [43.593567, 46.17578],
            [43.533951, 46.160119],
            [43.424656, 46.162817],
            [43.339229, 46.167136],
            [43.333072, 46.169188],
            [43.212219, 46.252886],
            [43.126142, 46.313581],
            [43.038985, 46.372332],
            [42.93173, 46.446915],
            [42.781082, 46.402784],
            [42.79086, 46.382572],
            [42.769106, 46.368682],
            [42.692415, 46.367575],
            [42.653212, 46.372435],
            [42.598456, 46.383882],
            [42.560547, 46.399325],
            [42.551475, 46.413797],
            [42.488942, 46.464124],
            [42.44369, 46.466932],
            [42.415718, 46.482267],
            [42.416798, 46.498683],
            [42.397084, 46.511122],
            [42.37403, 46.514126],
            [42.332557, 46.527086],
            [42.279098, 46.530757],
            [42.252638, 46.543177],
            [42.200366, 46.558404],
            [42.12185, 46.555379],
            [42.062775, 46.544362],
            [42.049948, 46.545913],
            [42.044824, 46.533957],
            [42.05589, 46.522281],
            [42.051149, 46.508358],
            [42.066559, 46.483079],
            [42.100883, 46.374208],
            [42.163927, 46.382901],
            [42.175251, 46.36082],
            [42.170595, 46.297562],
            [41.962308, 46.297794],
            [41.962993, 46.254877],
            [41.911855, 46.254929],
            [41.911261, 46.203514],
            [41.962394, 46.203273],
            [41.962656, 46.176349],
            [41.886388, 46.175591],
            [41.863031, 46.143873],
            [41.738463, 46.185582],
            [41.74397, 46.193781],
            [41.698772, 46.216062],
            [41.679027, 46.199885],
            [41.688451, 46.186322],
            [41.656206, 46.179837],
            [41.653301, 46.188841],
            [41.635671, 46.186534],
            [41.637826, 46.176103],
            [41.654367, 46.167058],
            [41.640864, 46.154467],
            [41.648689, 46.138508],
            [41.701913, 46.052122],
            [41.71441, 45.982428],
            [41.690649, 45.982404],
            [41.666927, 45.974902],
            [41.541262, 45.985923],
            [41.495071, 45.995079],
            [41.496249, 46.006201],
            [41.471448, 46.005994],
            [41.47142, 45.997886],
            [41.394203, 45.997123],
            [41.393082, 45.952307],
            [41.160898, 45.951688],
            [41.16065, 45.978968],
            [41.135053, 45.979184],
            [41.134752, 46.005693],
            [41.121792, 46.005679],
            [41.121842, 46.059611],
            [41.10844, 46.059741],
            [41.108224, 46.077619],
            [41.069777, 46.077571],
            [41.069513, 46.11333],
            [41.044878, 46.1225],
            [40.978788, 46.113405],
            [40.978863, 46.187751],
            [40.967431, 46.189339],
            [40.97192, 46.207312],
            [40.991235, 46.20741],
            [40.990422, 46.298363],
            [40.951891, 46.291879],
            [40.925535, 46.295277],
            [40.925714, 46.229994],
            [40.74449, 46.229063],
            [40.743525, 46.285549],
            [40.566432, 46.286279],
            [40.537268, 46.272668],
            [40.490197, 46.274116],
            [40.408093, 46.272132],
            [40.324128, 46.28296],
            [40.324277, 46.31434],
            [40.272275, 46.314493],
            [40.272239, 46.344489],
            [40.247306, 46.345153],
            [40.247545, 46.36771],
            [40.260582, 46.376221],
            [40.28613, 46.376346],
            [40.285994, 46.448445],
            [40.238122, 46.448403],
            [40.238018, 46.496791],
            [40.221043, 46.502402],
            [40.221454, 46.537824],
            [40.180034, 46.533212],
            [40.135707, 46.541362],
            [40.064291, 46.534309],
            [40.064406, 46.628049],
            [40.067814, 46.635723],
            [40.1036, 46.631452],
            [40.103439, 46.645922],
            [40.116658, 46.646098],
            [40.11629, 46.718187],
            [40.141978, 46.736044],
            [40.105537, 46.735972],
            [40.104755, 46.730213],
            [40.078948, 46.729629],
            [40.079678, 46.741853],
            [40.063257, 46.741989],
            [40.063222, 46.771941],
            [39.841119, 46.771467],
            [39.840838, 46.789107],
            [39.758186, 46.788926],
            [39.733501, 46.793417],
            [39.722643, 46.787433],
            [39.690484, 46.788808],
            [39.563772, 46.787894],
            [39.565225, 46.805597],
            [39.486642, 46.805214],
            [39.486776, 46.817798],
            [39.4461, 46.818085],
            [39.445993, 46.805679],
            [39.418574, 46.796536],
            [39.38329, 46.801202],
            [39.340945, 46.799494],
            [39.340976, 46.794535],
            [39.262562, 46.794294],
            [39.261845, 46.708199],
            [39.22789, 46.708289],
            [39.227224, 46.68196],
            [39.209323, 46.682072],
            [39.209095, 46.620574],
            [39.20242, 46.60978],
            [39.169423, 46.622915],
            [39.15199, 46.610593],
            [39.1403, 46.621152],
            [39.131449, 46.604947],
            [39.0688, 46.600413],
            [39.056754, 46.620234],
            [39.013991, 46.633196],
            [39.003552, 46.62163],
            [38.985364, 46.622987],
            [38.962894, 46.636245],
            [38.930587, 46.625247],
            [38.90208, 46.634822],
            [38.902433, 46.560099],
            [38.867766, 46.569768],
            [38.869649, 46.584606],
            [38.869483, 46.731926],
            [38.895954, 46.731792],
            [38.895873, 46.826389],
            [38.93327, 46.826404],
            [38.933296, 46.841291],
            [38.869563, 46.841224],
            [38.869561, 46.826414],
            [38.685737, 46.826137],
            [38.685837, 46.870703],
            [38.711228, 46.871549],
            [38.747756, 46.884087],
            [38.762477, 46.885021],
            [38.818551, 46.905881],
            [38.851377, 46.913136],
            [38.869901, 46.945797],
            [38.904244, 46.968229],
            [38.927377, 46.956657],
            [38.985033, 46.978733],
            [39.026944, 47.005185],
            [39.106956, 47.041564],
            [39.120555, 47.02632],
            [39.191412, 47.03433],
            [39.239576, 47.01872],
            [39.295127, 47.021758],
            [39.306328, 47.054286],
            [39.27506, 47.062666],
            [39.264885, 47.075253],
            [39.231458, 47.088796],
            [39.248331, 47.091601],
            [39.258874, 47.112988],
            [39.236938, 47.124212],
            [39.229886, 47.137349],
            [39.243218, 47.160053],
            [39.213179, 47.19065],
            [39.222468, 47.208072],
            [39.226168, 47.2373],
            [39.241203, 47.250562],
            [39.196595, 47.284157],
            [39.120846, 47.285801],
            [39.097484, 47.288388],
            [39.066193, 47.282512],
            [39.040176, 47.263931],
            [38.988561, 47.272529],
            [38.954653, 47.268802],
            [38.938659, 47.251869],
            [38.936331, 47.224034],
            [38.956867, 47.204524],
            [38.94697, 47.197071],
            [38.92943, 47.199712],
            [38.883489, 47.196578],
            [38.865411, 47.173844],
            [38.807674, 47.175684],
            [38.773855, 47.168442],
            [38.757679, 47.169563],
            [38.731781, 47.159631],
            [38.684811, 47.154639],
            [38.636784, 47.14052],
            [38.57963, 47.14353],
            [38.558098, 47.133576],
            [38.562191, 47.115767],
            [38.514379, 47.126731],
            [38.454392, 47.150183],
            [38.429602, 47.148267],
            [38.396661, 47.135917],
            [38.308685, 47.138368],
            [38.281775, 47.127175],
            [38.231281, 47.119614],
            [38.236151, 47.139409],
            [38.23594, 47.20112],
            [38.24221, 47.21838],
            [38.23397, 47.2316],
            [38.26383, 47.23915],
            [38.28873, 47.23932],
            [38.2889, 47.25571],
            [38.32332, 47.25606],
            [38.33353, 47.26919],
            [38.32529, 47.28327],
            [38.33473, 47.3061],
            [38.22075, 47.30541],
            [38.22092, 47.32687],
            [38.24435, 47.34884],
            [38.24564, 47.36378],
            [38.26152, 47.37399],
            [38.28701, 47.37416],
            [38.28701, 47.39141],
            [38.30332, 47.39176],
            [38.30272, 47.47372],
            [38.28821, 47.47742],
            [38.2937, 47.50677],
            [38.28564, 47.50694],
            [38.28512, 47.54419],
            [38.30546, 47.54428],
            [38.31121, 47.57569],
            [38.35113, 47.57603],
            [38.35095, 47.6162],
            [38.45721, 47.61715],
            [38.45704, 47.64427],
            [38.615914, 47.643657],
            [38.63016, 47.6538],
            [38.63033, 47.6683],
            [38.66698, 47.66865],
            [38.663457, 47.699723],
            [38.735099, 47.699581],
            [38.74509, 47.68555],
            [38.77324, 47.68461],
            [38.77067, 47.72693],
            [38.78998, 47.73697],
            [38.78981, 47.76752],
            [38.79658, 47.77413],
            [38.78268, 47.78924],
            [38.79058, 47.79645],
            [38.79006, 47.81516],
            [38.82877, 47.81559],
            [38.82362, 47.83559],
            [38.83504, 47.86194],
            [38.87564, 47.86692],
            [38.87692, 47.87593],
            [38.95494, 47.86966],
            [39.07356, 47.86949],
            [39.08317, 47.85147],
            [39.10995, 47.85026],
            [39.10995, 47.84237],
            [39.14944, 47.84202],
            [39.14926, 47.85112],
            [39.17175, 47.84666],
            [39.17948, 47.85267],
            [39.2363, 47.85318],
            [39.24162, 47.86709],
            [39.351607, 47.866926],
            [39.34839, 47.87138],
            [39.38736, 47.87104],
            [39.51731, 47.82898],
            [39.53516, 47.83293],
            [39.54288, 47.82503],
            [39.56589, 47.83653],
            [39.63455, 47.83859],
            [39.65618, 47.83275],
            [39.73755, 47.82778],
            [39.76364, 47.84666],
            [39.76364, 47.87121],
            [39.7954, 47.87069],
            [39.79334, 47.91567],
            [39.81171, 47.95034],
            [39.82492, 47.95532],
            [39.816, 47.96802],
            [39.81514, 48.0015],
            [39.79265, 48.0118],
            [39.77651, 48.03944],
            [39.883388, 48.041607],
            [39.883511, 48.048988],
            [39.847692, 48.059076],
            [39.83419, 48.06707],
            [39.86938, 48.07909],
            [39.87608, 48.11806],
            [39.89668, 48.13351],
            [39.90698, 48.14861],
            [39.9099, 48.17968],
            [39.93685, 48.18071],
            [39.942, 48.22861],
            [40.00963, 48.22483],
            [40.00637, 48.24217],
            [40.02062, 48.2535],
            [39.99264, 48.30053],
            [39.98663, 48.31701],
            [39.9523, 48.30345],
            [39.925, 48.27152],
            [39.91093, 48.27135],
            [39.91024, 48.29109],
            [39.88741, 48.30414],
            [39.84106, 48.30963],
            [39.84037, 48.3292],
            [39.84964, 48.33383],
            [39.88603, 48.33521],
            [39.942518, 48.346588],
            [39.93238, 48.36302],
            [39.943076, 48.384509],
            [39.91951, 48.37709],
            [39.90698, 48.3898],
            [39.9111, 48.42138],
            [39.89496, 48.43065],
            [39.89736, 48.44816],
            [39.85273, 48.46636],
            [39.84638, 48.47374],
            [39.8469, 48.49811],
            [39.86681, 48.50927],
            [39.85496, 48.5376],
            [39.84844, 48.56953],
            [39.79162, 48.59253],
            [39.75265, 48.58189],
            [39.75025, 48.58927],
            [39.72038, 48.58463],
            [39.677837, 48.590139],
            [39.660537, 48.607719],
            [39.667432, 48.620749],
            [39.694416, 48.637954],
            [39.691352, 48.646167],
            [39.70652, 48.656911],
            [39.703001, 48.667147],
            [39.723273, 48.683864],
            [39.720099, 48.697092],
            [39.704507, 48.707186],
            [39.704308, 48.720521],
            [39.725196, 48.740441],
            [39.739613, 48.744512],
            [39.725551, 48.752372],
            [39.750954, 48.772735],
            [39.762577, 48.772386],
            [39.78424, 48.79166],
            [39.780131, 48.812147],
            [39.801117, 48.842855],
            [39.88243, 48.82204],
            [39.94818, 48.79578],
            [39.97599, 48.79286],
            [40.079895, 48.870249],
            [40.07246, 48.8744],
            [40.06096, 48.9089],
            [40.02972, 48.91474],
            [40.01839, 48.90238],
            [40.00414, 48.90118],
            [39.99332, 48.86804],
            [39.94749, 48.87714],
            [39.92449, 48.89603],
            [39.85239, 48.89053],
            [39.84501, 48.90255],
            [39.82715, 48.88624],
            [39.82441, 48.90238],
            [39.80673, 48.91422],
            [39.78269, 48.91577],
            [39.77428, 48.92469],
            [39.78115, 48.93688],
            [39.76501, 48.95782],
            [39.75883, 48.97568],
            [39.745732, 48.986883],
            [39.72845, 48.9755],
            [39.70081, 49.00022],
            [39.67644, 48.98975],
            [39.66322, 49.00108],
            [39.68502, 49.03576],
            [39.67232, 49.04022],
            [39.69326, 49.05052],
            [39.76742, 49.04022],
            [39.79797, 49.05619],
            [39.87127, 49.06288],
            [39.93805, 49.05619],
            [39.93805, 49.08228],
            [39.97272, 49.12519],
            [40.03092, 49.17944],
            [40.07812, 49.18768],
            [40.10851, 49.21154],
            [40.12567, 49.23214],
            [40.15537, 49.23849],
            [40.19623, 49.23969],
            [40.22198, 49.25102],
            [40.22713, 49.26046],
            [40.18593, 49.28123],
            [40.18404, 49.30252],
            [40.20035, 49.34595],
            [40.16945, 49.35642],
            [40.14336, 49.37839],
            [40.11417, 49.38578],
            [40.0783, 49.40981],
            [40.04602, 49.44586],
            [40.02954, 49.45358],
            [40.0419, 49.49856],
            [40.03899, 49.52053],
            [40.16962, 49.56877],
            [40.13941, 49.59589],
            [40.135392, 49.617102],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5524",
      properties: {
        name: "Рязанская область",
        density: 0,
        path: "/world/Russia/Рязанская область",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [38.665982, 54.256714],
              [38.703614, 54.261294],
              [38.719932, 54.284554],
              [38.704244, 54.289497],
              [38.704104, 54.316263],
              [38.712983, 54.348532],
              [38.778134, 54.363574],
              [38.773931, 54.374373],
              [38.819578, 54.374625],
              [38.836482, 54.393119],
              [38.85463, 54.386906],
              [38.857918, 54.370132],
              [38.877143, 54.371187],
              [38.869913, 54.392079],
              [38.889513, 54.402096],
              [38.940648, 54.407989],
              [38.930667, 54.43928],
              [38.880352, 54.441288],
              [38.853546, 54.457659],
              [38.800949, 54.453427],
              [38.799514, 54.47006],
              [38.779489, 54.47923],
              [38.789806, 54.494802],
              [38.802991, 54.497461],
              [38.80134, 54.514148],
              [38.815089, 54.527942],
              [38.838244, 54.52585],
              [38.851213, 54.540496],
              [38.807775, 54.538247],
              [38.79701, 54.551414],
              [38.834331, 54.56961],
              [38.823007, 54.578467],
              [38.836843, 54.58675],
              [38.826756, 54.60643],
              [38.849023, 54.62562],
              [38.864631, 54.615156],
              [38.946505, 54.634241],
              [38.952496, 54.609956],
              [38.971764, 54.600977],
              [38.993967, 54.615927],
              [38.992897, 54.628108],
              [39.02361, 54.64112],
              [39.044773, 54.635404],
              [39.082056, 54.63447],
              [39.099955, 54.646041],
              [39.111228, 54.63285],
              [39.13195, 54.634659],
              [39.127352, 54.645934],
              [39.150494, 54.643067],
              [39.162813, 54.660841],
              [39.182282, 54.638054],
              [39.193928, 54.64193],
              [39.226063, 54.635703],
              [39.231811, 54.64658],
              [39.256482, 54.659497],
              [39.256321, 54.677558],
              [39.232416, 54.684127],
              [39.240709, 54.695916],
              [39.232055, 54.718611],
              [39.245611, 54.731869],
              [39.300491, 54.73527],
              [39.304687, 54.724338],
              [39.32745, 54.725509],
              [39.370879, 54.747372],
              [39.361288, 54.755646],
              [39.321955, 54.75371],
              [39.337742, 54.774516],
              [39.362821, 54.782729],
              [39.361286, 54.799827],
              [39.387404, 54.824719],
              [39.415177, 54.839913],
              [39.390504, 54.853952],
              [39.398601, 54.866246],
              [39.435377, 54.877063],
              [39.429527, 54.886132],
              [39.466207, 54.892387],
              [39.461383, 54.901958],
              [39.486499, 54.901426],
              [39.473672, 54.909501],
              [39.46555, 54.931445],
              [39.475166, 54.945866],
              [39.520583, 54.969754],
              [39.517518, 54.983877],
              [39.549097, 54.988852],
              [39.602885, 54.988013],
              [39.617584, 55.000383],
              [39.625368, 55.02508],
              [39.669568, 55.025425],
              [39.669218, 55.013973],
              [39.701278, 55.005641],
              [39.769973, 55.003971],
              [39.773741, 55.035157],
              [39.795772, 55.030511],
              [39.791136, 55.021561],
              [39.804256, 55.005216],
              [39.862565, 55.111142],
              [39.834256, 55.115608],
              [39.823104, 55.136054],
              [39.799059, 55.137608],
              [39.775137, 55.116365],
              [39.755965, 55.11947],
              [39.705639, 55.117414],
              [39.715144, 55.130186],
              [39.753887, 55.153743],
              [39.784906, 55.178792],
              [39.837854, 55.204526],
              [39.882855, 55.203867],
              [39.922507, 55.215311],
              [39.955369, 55.207433],
              [39.987484, 55.213318],
              [39.993914, 55.199897],
              [40.004695, 55.224077],
              [40.034136, 55.237863],
              [40.056419, 55.239258],
              [40.063663, 55.248067],
              [40.12684, 55.264792],
              [40.154644, 55.264389],
              [40.179502, 55.312063],
              [40.205688, 55.315762],
              [40.178899, 55.334834],
              [40.202142, 55.348554],
              [40.190923, 55.365007],
              [40.225471, 55.360106],
              [40.207282, 55.351532],
              [40.246238, 55.35095],
              [40.277959, 55.360578],
              [40.27841, 55.346734],
              [40.295659, 55.335212],
              [40.330047, 55.348483],
              [40.363867, 55.349775],
              [40.326954, 55.321948],
              [40.325729, 55.308121],
              [40.34344, 55.304882],
              [40.358068, 55.289607],
              [40.381273, 55.289731],
              [40.394356, 55.304296],
              [40.450293, 55.301197],
              [40.517742, 55.350894],
              [40.567896, 55.356139],
              [40.586905, 55.348487],
              [40.559167, 55.323846],
              [40.545434, 55.3238],
              [40.527195, 55.302347],
              [40.540526, 55.290245],
              [40.568267, 55.280419],
              [40.555434, 55.272083],
              [40.562593, 55.257518],
              [40.588267, 55.253939],
              [40.612955, 55.217774],
              [40.600251, 55.200483],
              [40.650602, 55.206049],
              [40.667758, 55.219874],
              [40.707159, 55.226553],
              [40.702936, 55.207654],
              [40.725997, 55.208214],
              [40.761564, 55.232588],
              [40.786003, 55.229009],
              [40.827599, 55.238884],
              [40.847472, 55.218889],
              [40.870059, 55.233454],
              [40.903138, 55.237651],
              [40.905729, 55.275297],
              [41.002091, 55.271881],
              [40.993449, 55.257039],
              [41.005841, 55.244519],
              [41.027679, 55.239628],
              [41.042615, 55.221977],
              [41.059337, 55.222439],
              [41.072116, 55.209141],
              [41.059526, 55.206549],
              [41.06434, 55.187911],
              [41.090419, 55.175732],
              [41.073351, 55.190874],
              [41.078432, 55.212305],
              [41.099888, 55.206303],
              [41.143458, 55.217535],
              [41.149382, 55.229879],
              [41.180486, 55.227904],
              [41.294505, 55.233472],
              [41.301449, 55.213465],
              [41.321256, 55.196107],
              [41.361505, 55.175076],
              [41.394764, 55.166069],
              [41.406255, 55.157349],
              [41.443436, 55.145457],
              [41.484622, 55.159405],
              [41.491972, 55.168022],
              [41.543868, 55.173107],
              [41.572258, 55.146816],
              [41.605165, 55.15645],
              [41.628133, 55.1524],
              [41.641855, 55.132145],
              [41.631239, 55.120261],
              [41.681372, 55.112134],
              [41.717817, 55.130018],
              [41.772956, 55.132378],
              [41.78246, 55.128799],
              [41.795935, 55.103672],
              [41.775525, 55.100507],
              [41.803794, 55.070811],
              [41.836876, 55.074788],
              [41.85803, 55.058076],
              [41.87701, 55.059816],
              [41.927704, 55.077833],
              [41.961904, 55.095416],
              [41.997626, 55.099703],
              [41.997907, 55.075101],
              [41.980948, 55.055406],
              [42.002568, 55.038757],
              [41.989585, 55.016764],
              [41.999029, 54.9962],
              [42.060815, 54.978184],
              [42.113063, 54.978372],
              [42.226476, 54.958679],
              [42.254307, 54.97856],
              [42.30028, 54.992104],
              [42.321528, 54.988004],
              [42.366817, 55.00714],
              [42.404528, 55.005028],
              [42.42944, 55.013105],
              [42.444723, 55.025903],
              [42.485478, 55.009005],
              [42.485231, 54.990865],
              [42.445968, 54.967753],
              [42.462432, 54.943462],
              [42.493807, 54.91886],
              [42.494678, 54.907429],
              [42.468336, 54.895252],
              [42.458956, 54.878477],
              [42.445182, 54.88449],
              [42.425751, 54.861484],
              [42.44429, 54.855454],
              [42.442662, 54.825224],
              [42.407356, 54.842369],
              [42.3891, 54.830207],
              [42.393684, 54.812263],
              [42.42683, 54.814853],
              [42.421806, 54.798948],
              [42.389103, 54.789667],
              [42.388067, 54.780686],
              [42.411087, 54.765364],
              [42.395078, 54.751232],
              [42.42891, 54.739147],
              [42.428558, 54.722557],
              [42.412899, 54.707919],
              [42.439807, 54.709716],
              [42.4784, 54.690565],
              [42.500038, 54.704323],
              [42.540755, 54.684936],
              [42.541867, 54.675006],
              [42.573411, 54.683965],
              [42.588876, 54.704496],
              [42.602024, 54.699853],
              [42.643047, 54.671403],
              [42.620803, 54.663243],
              [42.597332, 54.635541],
              [42.608495, 54.627427],
              [42.631418, 54.62953],
              [42.620953, 54.611446],
              [42.648882, 54.587932],
              [42.688461, 54.580993],
              [42.684363, 54.570882],
              [42.634424, 54.569587],
              [42.607795, 54.560514],
              [42.62881, 54.554653],
              [42.599169, 54.546773],
              [42.61237, 54.538467],
              [42.606069, 54.525409],
              [42.624967, 54.522011],
              [42.632614, 54.493776],
              [42.611254, 54.489156],
              [42.605587, 54.510034],
              [42.591946, 54.501813],
              [42.562258, 54.505203],
              [42.556252, 54.486663],
              [42.565607, 54.477741],
              [42.552738, 54.457249],
              [42.530773, 54.440734],
              [42.551791, 54.428209],
              [42.567065, 54.442481],
              [42.583538, 54.444723],
              [42.584744, 54.425562],
              [42.620432, 54.401595],
              [42.628586, 54.375868],
              [42.577963, 54.365521],
              [42.543555, 54.372819],
              [42.529911, 54.370217],
              [42.49645, 54.342668],
              [42.487528, 54.328464],
              [42.493016, 54.310795],
              [42.477745, 54.299079],
              [42.404984, 54.284658],
              [42.398206, 54.263011],
              [42.386967, 54.253944],
              [42.327165, 54.24928],
              [42.321926, 54.234788],
              [42.299961, 54.226212],
              [42.255259, 54.239456],
              [42.240932, 54.23895],
              [42.207214, 54.253839],
              [42.163367, 54.258106],
              [42.196399, 54.231181],
              [42.226176, 54.224009],
              [42.207643, 54.218189],
              [42.215877, 54.203837],
              [42.202148, 54.188527],
              [42.236297, 54.182254],
              [42.222057, 54.17467],
              [42.288121, 54.153619],
              [42.316267, 54.150705],
              [42.349386, 54.161961],
              [42.353847, 54.175573],
              [42.339089, 54.190387],
              [42.392369, 54.209407],
              [42.365086, 54.218391],
              [42.391345, 54.240759],
              [42.395634, 54.254498],
              [42.433386, 54.25705],
              [42.489413, 54.214926],
              [42.505115, 54.214275],
              [42.546042, 54.192495],
              [42.583538, 54.177787],
              [42.598899, 54.184863],
              [42.632701, 54.165027],
              [42.649948, 54.166381],
              [42.667966, 54.156738],
              [42.689115, 54.129681],
              [42.675398, 54.127981],
              [42.669366, 54.140924],
              [42.607048, 54.152968],
              [42.579333, 54.165228],
              [42.545616, 54.143215],
              [42.528799, 54.148698],
              [42.520645, 54.132308],
              [42.53566, 54.117077],
              [42.550934, 54.1211],
              [42.572044, 54.083319],
              [42.49808, 54.070025],
              [42.45947, 54.08503],
              [42.449604, 54.103745],
              [42.451234, 54.122406],
              [42.433812, 54.120392],
              [42.417168, 54.103094],
              [42.376241, 54.098562],
              [42.379416, 54.090964],
              [42.328622, 54.087344],
              [42.312322, 54.082463],
              [42.303136, 54.09982],
              [42.326046, 54.106364],
              [42.325445, 54.122607],
              [42.283234, 54.104851],
              [42.288809, 54.096248],
              [42.261952, 54.087393],
              [42.292582, 54.062424],
              [42.325191, 54.051592],
              [42.363887, 54.018338],
              [42.416742, 54.010119],
              [42.444625, 53.995998],
              [42.439305, 53.958004],
              [42.444366, 53.943007],
              [42.40464, 53.938362],
              [42.383019, 53.923458],
              [42.356509, 53.887619],
              [42.35023, 53.853669],
              [42.258911, 53.831881],
              [42.236265, 53.836824],
              [42.200068, 53.824626],
              [42.205091, 53.818646],
              [42.136515, 53.82247],
              [42.144531, 53.802341],
              [42.058812, 53.803613],
              [42.028784, 53.821407],
              [41.980193, 53.802414],
              [41.951619, 53.810455],
              [41.916202, 53.811652],
              [41.868209, 53.821062],
              [41.839294, 53.819351],
              [41.819789, 53.810453],
              [41.754858, 53.805662],
              [41.752292, 53.811992],
              [41.686248, 53.811649],
              [41.673759, 53.799159],
              [41.648413, 53.800717],
              [41.65665, 53.783931],
              [41.638685, 53.775034],
              [41.607374, 53.776402],
              [41.604293, 53.800527],
              [41.580853, 53.799157],
              [41.548889, 53.811862],
              [41.523964, 53.784956],
              [41.498471, 53.772807],
              [41.454157, 53.780677],
              [41.429262, 53.775159],
              [41.405822, 53.78474],
              [41.396412, 53.76917],
              [41.363562, 53.765576],
              [41.369722, 53.744189],
              [41.364936, 53.635586],
              [41.358093, 53.595592],
              [41.353684, 53.525571],
              [41.320626, 53.524928],
              [41.280676, 53.511325],
              [41.245906, 53.515181],
              [41.261171, 53.494215],
              [41.255853, 53.472636],
              [41.211383, 53.469577],
              [41.185548, 53.472143],
              [41.187517, 53.449127],
              [41.150731, 53.427229],
              [41.144602, 53.417168],
              [41.11771, 53.424833],
              [41.039905, 53.429787],
              [41.051665, 53.452208],
              [41.042144, 53.476481],
              [41.006922, 53.491987],
              [40.956619, 53.500541],
              [40.895789, 53.504954],
              [40.852594, 53.462214],
              [40.833516, 53.461358],
              [40.814524, 53.483601],
              [40.801861, 53.518504],
              [40.77147, 53.523935],
              [40.77996, 53.547762],
              [40.756348, 53.553407],
              [40.700873, 53.555919],
              [40.679184, 53.53835],
              [40.667036, 53.542285],
              [40.641372, 53.525346],
              [40.645821, 53.511829],
              [40.635556, 53.502419],
              [40.59894, 53.507496],
              [40.584569, 53.494548],
              [40.555996, 53.492494],
              [40.541368, 53.477566],
              [40.545988, 53.466787],
              [40.504241, 53.45806],
              [40.471904, 53.444714],
              [40.474128, 53.464048],
              [40.462321, 53.483382],
              [40.421771, 53.496213],
              [40.428102, 53.475169],
              [40.409111, 53.46148],
              [40.424585, 53.387493],
              [40.402955, 53.388422],
              [40.387214, 53.369858],
              [40.381569, 53.350524],
              [40.368908, 53.341455],
              [40.335715, 53.344877],
              [40.302054, 53.361934],
              [40.300297, 53.381491],
              [40.271895, 53.376016],
              [40.267961, 53.346416],
              [40.245291, 53.336962],
              [40.249226, 53.350821],
              [40.232801, 53.341582],
              [40.212613, 53.309929],
              [40.169999, 53.312082],
              [40.17937, 53.332623],
              [40.173669, 53.353893],
              [40.155853, 53.370092],
              [40.13824, 53.354215],
              [40.121879, 53.374685],
              [40.142638, 53.381264],
              [40.127373, 53.392541],
              [40.111803, 53.380863],
              [40.061825, 53.38538],
              [40.038051, 53.403314],
              [39.993147, 53.399559],
              [39.982441, 53.425695],
              [39.989114, 53.438347],
              [39.923704, 53.435357],
              [39.923286, 53.445088],
              [39.954148, 53.467193],
              [39.942678, 53.480192],
              [39.943271, 53.49666],
              [39.932111, 53.513974],
              [39.890821, 53.524539],
              [39.894192, 53.541048],
              [39.885294, 53.549667],
              [39.860687, 53.547582],
              [39.837296, 53.566454],
              [39.808553, 53.566349],
              [39.816269, 53.556409],
              [39.784642, 53.554393],
              [39.773415, 53.571805],
              [39.750893, 53.578547],
              [39.753604, 53.585429],
              [39.681487, 53.558006],
              [39.663588, 53.545806],
              [39.612394, 53.532564],
              [39.59453, 53.523596],
              [39.555012, 53.529122],
              [39.54827, 53.524812],
              [39.568019, 53.506398],
              [39.555188, 53.48738],
              [39.559951, 53.442997],
              [39.53677, 53.418147],
              [39.520852, 53.432674],
              [39.47282, 53.43323],
              [39.468789, 53.413489],
              [39.457146, 53.400803],
              [39.438864, 53.409769],
              [39.385237, 53.396874],
              [39.364939, 53.402226],
              [39.357224, 53.393885],
              [39.32723, 53.390583],
              [39.328203, 53.402817],
              [39.312215, 53.412409],
              [39.288825, 53.402225],
              [39.265399, 53.405075],
              [39.235545, 53.394821],
              [39.207253, 53.403232],
              [39.207671, 53.389399],
              [39.189494, 53.392562],
              [39.17434, 53.408271],
              [39.17771, 53.423042],
              [39.163113, 53.426378],
              [39.170063, 53.44035],
              [39.138957, 53.445945],
              [39.152511, 53.458597],
              [39.130579, 53.483516],
              [39.136001, 53.493178],
              [39.121785, 53.508227],
              [39.101349, 53.509131],
              [39.087757, 53.554487],
              [39.054738, 53.578467],
              [39.011885, 53.564808],
              [38.999304, 53.570438],
              [38.960552, 53.561922],
              [38.937824, 53.574779],
              [38.934075, 53.591316],
              [38.944195, 53.598494],
              [38.937605, 53.615674],
              [38.879414, 53.61991],
              [38.873412, 53.636502],
              [38.915539, 53.657448],
              [38.909831, 53.679689],
              [38.921245, 53.680924],
              [38.918302, 53.70293],
              [38.932187, 53.719522],
              [38.950779, 53.72517],
              [38.944571, 53.743881],
              [38.922183, 53.764826],
              [38.925595, 53.771475],
              [38.867082, 53.76306],
              [38.85508, 53.756294],
              [38.816158, 53.781305],
              [38.838163, 53.786595],
              [38.849165, 53.798245],
              [38.848399, 53.814778],
              [38.824835, 53.819367],
              [38.800065, 53.814836],
              [38.793945, 53.829663],
              [38.816215, 53.828428],
              [38.826834, 53.842784],
              [38.855252, 53.847962],
              [38.831364, 53.866613],
              [38.795296, 53.879733],
              [38.794824, 53.909446],
              [38.844865, 53.898974],
              [38.858426, 53.918155],
              [38.887845, 53.919273],
              [38.874829, 53.946696],
              [38.853306, 53.947985],
              [38.820476, 53.938806],
              [38.779377, 53.968812],
              [38.778787, 53.992347],
              [38.799939, 54.004703],
              [38.800115, 54.013705],
              [38.777785, 54.033004],
              [38.759722, 54.028826],
              [38.756134, 54.015529],
              [38.769137, 54.011057],
              [38.750133, 53.998289],
              [38.721745, 53.992699],
              [38.724862, 54.007291],
              [38.753368, 54.03065],
              [38.748425, 54.050772],
              [38.728626, 54.065834],
              [38.710386, 54.059656],
              [38.682614, 54.088839],
              [38.684832, 54.098183],
              [38.718004, 54.102136],
              [38.747539, 54.12526],
              [38.772957, 54.127261],
              [38.781663, 54.156209],
              [38.750978, 54.192216],
              [38.739799, 54.18192],
              [38.712028, 54.180625],
              [38.687227, 54.186234],
              [38.711233, 54.195275],
              [38.720647, 54.209161],
              [38.711526, 54.222458],
              [38.724765, 54.225577],
              [38.711702, 54.244404],
              [38.671163, 54.247051],
              [38.665982, 54.256714],
            ],
          ],
          [
            [
              [42.433575, 54.793561],
              [42.444321, 54.798354],
              [42.457904, 54.801894],
              [42.503668, 54.770663],
              [42.509725, 54.750742],
              [42.454866, 54.750434],
              [42.433575, 54.793561],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5489",
      properties: {
        name: "Самарская область",
        density: 0,
        path: "/world/Russia/Самарская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [50.092459, 54.5066],
            [50.195225, 54.501175],
            [50.223149, 54.492175],
            [50.235873, 54.446815],
            [50.273491, 54.44163],
            [50.307483, 54.442655],
            [50.302523, 54.45653],
            [50.343475, 54.462622],
            [50.333797, 54.471307],
            [50.341713, 54.486676],
            [50.374246, 54.493499],
            [50.386738, 54.48487],
            [50.430944, 54.420996],
            [50.462754, 54.428452],
            [50.473904, 54.408931],
            [50.477722, 54.383468],
            [50.48703, 54.366588],
            [50.506056, 54.350623],
            [50.483048, 54.345894],
            [50.493517, 54.332796],
            [50.521263, 54.324949],
            [50.564253, 54.34851],
            [50.575846, 54.343998],
            [50.621465, 54.370689],
            [50.636842, 54.371611],
            [50.648443, 54.38512],
            [50.676899, 54.395755],
            [50.736443, 54.40472],
            [50.750244, 54.375901],
            [50.77627, 54.376239],
            [50.821745, 54.363758],
            [50.847164, 54.367571],
            [50.87544, 54.384392],
            [50.889471, 54.371788],
            [50.907298, 54.37034],
            [50.899561, 54.343385],
            [50.95126, 54.342639],
            [50.954356, 54.392084],
            [50.982312, 54.396867],
            [50.991676, 54.405911],
            [50.984444, 54.437996],
            [51.020896, 54.46965],
            [51.000898, 54.482101],
            [51.008365, 54.549436],
            [51.07442, 54.540209],
            [51.099982, 54.571067],
            [51.100573, 54.586824],
            [51.14874, 54.613979],
            [51.150032, 54.639612],
            [51.190287, 54.659565],
            [51.225568, 54.647674],
            [51.228203, 54.631567],
            [51.279109, 54.641699],
            [51.288439, 54.652738],
            [51.312781, 54.646819],
            [51.331128, 54.654979],
            [51.355951, 54.652574],
            [51.381069, 54.664561],
            [51.389252, 54.677006],
            [51.40396, 54.668167],
            [51.39253, 54.647401],
            [51.396017, 54.637022],
            [51.381283, 54.612093],
            [51.381752, 54.588079],
            [51.500054, 54.596834],
            [51.513411, 54.595142],
            [51.518946, 54.65061],
            [51.538825, 54.649721],
            [51.584902, 54.606412],
            [51.633644, 54.565003],
            [51.663209, 54.569098],
            [51.690692, 54.555788],
            [51.726749, 54.552565],
            [51.777251, 54.556853],
            [51.789261, 54.536625],
            [51.823001, 54.539151],
            [51.86003, 54.53476],
            [51.875862, 54.527592],
            [51.906705, 54.538079],
            [51.928783, 54.535055],
            [51.920907, 54.524169],
            [51.937886, 54.516537],
            [51.940416, 54.483639],
            [51.951537, 54.473445],
            [51.942378, 54.442035],
            [51.928434, 54.445692],
            [51.914558, 54.436567],
            [51.904274, 54.412542],
            [51.951046, 54.414138],
            [51.954145, 54.426878],
            [51.975662, 54.428329],
            [51.965953, 54.419115],
            [51.978997, 54.411283],
            [52.01623, 54.414002],
            [51.985562, 54.416436],
            [51.991543, 54.427085],
            [52.026872, 54.426152],
            [52.034886, 54.41429],
            [52.065442, 54.418411],
            [52.046007, 54.387357],
            [52.058747, 54.373961],
            [52.036276, 54.355165],
            [52.054754, 54.342527],
            [52.092545, 54.328304],
            [52.107242, 54.339381],
            [52.116466, 54.361239],
            [52.101115, 54.364172],
            [52.104233, 54.381598],
            [52.09553, 54.391193],
            [52.11787, 54.391593],
            [52.126066, 54.373401],
            [52.147482, 54.363056],
            [52.177387, 54.375267],
            [52.182523, 54.391691],
            [52.198538, 54.406983],
            [52.188959, 54.41785],
            [52.242889, 54.439682],
            [52.293818, 54.434834],
            [52.298205, 54.447029],
            [52.343376, 54.439081],
            [52.367222, 54.398661],
            [52.397094, 54.412728],
            [52.388318, 54.446251],
            [52.431969, 54.474165],
            [52.43596, 54.464314],
            [52.486742, 54.4666],
            [52.502117, 54.459062],
            [52.484837, 54.436125],
            [52.504034, 54.437537],
            [52.501824, 54.391239],
            [52.489178, 54.39007],
            [52.506097, 54.373703],
            [52.568276, 54.340128],
            [52.528209, 54.303314],
            [52.460976, 54.297895],
            [52.429383, 54.310897],
            [52.393343, 54.304966],
            [52.389865, 54.293732],
            [52.345441, 54.294073],
            [52.330786, 54.279075],
            [52.373215, 54.255467],
            [52.349779, 54.211728],
            [52.362553, 54.191199],
            [52.343222, 54.169358],
            [52.310888, 54.1766],
            [52.294238, 54.149512],
            [52.307525, 54.129953],
            [52.347672, 54.125733],
            [52.372192, 54.148658],
            [52.381203, 54.133318],
            [52.379037, 54.097905],
            [52.389188, 54.089693],
            [52.381433, 54.078402],
            [52.478891, 54.070534],
            [52.488814, 54.054396],
            [52.458306, 54.033923],
            [52.426314, 54.026623],
            [52.401508, 54.014134],
            [52.405443, 54.008546],
            [52.371914, 53.971878],
            [52.395523, 53.962469],
            [52.384232, 53.95055],
            [52.407727, 53.950095],
            [52.416508, 53.960074],
            [52.408185, 53.893012],
            [52.381041, 53.889932],
            [52.3673, 53.830625],
            [52.357549, 53.80867],
            [52.342609, 53.801028],
            [52.339587, 53.787285],
            [52.279768, 53.744116],
            [52.278743, 53.711782],
            [52.261065, 53.694617],
            [52.26426, 53.661428],
            [52.234282, 53.651838],
            [52.226679, 53.62877],
            [52.2143, 53.615726],
            [52.188837, 53.55588],
            [52.168448, 53.544269],
            [52.138162, 53.550667],
            [52.122063, 53.545953],
            [52.097669, 53.558273],
            [52.078361, 53.498788],
            [52.101297, 53.499607],
            [52.116364, 53.491766],
            [52.091388, 53.470991],
            [52.129202, 53.450931],
            [52.122757, 53.441169],
            [52.145093, 53.444658],
            [52.159431, 53.42911],
            [52.145715, 53.415515],
            [52.152981, 53.386786],
            [52.162968, 53.390435],
            [52.211862, 53.380847],
            [52.176114, 53.324583],
            [52.188006, 53.321999],
            [52.173312, 53.297383],
            [52.142478, 53.301449],
            [52.160008, 53.282933],
            [52.166878, 53.235245],
            [52.126918, 53.233201],
            [52.136642, 53.166116],
            [52.106906, 53.168183],
            [52.068654, 53.15238],
            [52.066289, 53.129354],
            [52.085232, 53.123064],
            [52.157784, 53.125495],
            [52.1652, 53.120075],
            [52.162267, 53.087759],
            [52.050012, 53.090567],
            [52.045723, 53.032983],
            [52.061883, 53.032584],
            [52.054539, 52.964347],
            [51.9899, 52.966225],
            [51.981933, 52.970703],
            [51.913636, 52.971276],
            [51.912995, 52.949556],
            [51.88712, 52.946433],
            [51.866216, 52.930061],
            [51.848625, 52.901669],
            [51.836879, 52.864887],
            [51.758924, 52.874694],
            [51.715014, 52.876347],
            [51.725738, 52.804381],
            [51.766511, 52.800333],
            [51.720098, 52.651894],
            [51.616767, 52.645848],
            [51.611405, 52.681831],
            [51.523228, 52.676763],
            [51.530492, 52.631999],
            [51.471709, 52.628534],
            [51.475359, 52.604744],
            [51.507069, 52.59464],
            [51.510147, 52.564558],
            [51.477907, 52.570427],
            [51.451672, 52.568762],
            [51.461006, 52.510296],
            [51.490212, 52.512063],
            [51.493254, 52.494044],
            [51.514243, 52.495419],
            [51.532381, 52.475262],
            [51.531445, 52.463032],
            [51.548183, 52.46205],
            [51.548065, 52.428867],
            [51.473152, 52.428663],
            [51.462051, 52.406721],
            [51.461028, 52.365624],
            [51.37108, 52.365158],
            [51.384492, 52.357747],
            [51.399877, 52.322495],
            [51.399226, 52.272212],
            [51.409321, 52.22836],
            [51.462982, 52.226136],
            [51.447017, 52.183538],
            [51.432304, 52.174414],
            [51.43624, 52.150178],
            [51.406759, 52.106553],
            [51.39142, 52.069258],
            [51.298469, 52.031791],
            [51.262259, 52.019003],
            [51.205405, 51.986511],
            [51.14376, 51.988392],
            [51.153398, 51.956344],
            [51.126882, 51.92612],
            [51.088503, 51.932962],
            [51.033474, 51.923495],
            [50.999658, 51.891218],
            [50.909958, 51.860651],
            [50.768482, 51.773511],
            [50.719096, 51.788057],
            [50.717552, 51.819543],
            [50.701438, 51.819657],
            [50.70172, 51.932973],
            [50.649376, 51.933258],
            [50.649261, 51.958687],
            [50.597203, 51.963487],
            [50.521259, 51.963828],
            [50.520058, 51.993772],
            [50.406228, 51.99297],
            [50.3964, 51.980227],
            [50.352399, 51.981541],
            [50.352111, 52.044856],
            [50.308682, 52.044798],
            [50.30634, 52.014855],
            [50.281083, 52.01314],
            [50.215367, 52.036168],
            [50.183595, 52.030853],
            [50.181938, 52.01811],
            [50.156395, 52.009824],
            [50.160395, 52.02011],
            [50.159593, 52.090682],
            [50.224736, 52.090626],
            [50.223421, 52.129312],
            [50.163706, 52.130569],
            [50.163705, 52.139997],
            [50.045132, 52.139653],
            [50.039759, 52.190111],
            [49.984901, 52.18851],
            [49.985816, 52.171653],
            [49.798156, 52.172336],
            [49.750267, 52.239022],
            [49.734438, 52.251936],
            [49.733409, 52.272851],
            [49.721009, 52.262793],
            [49.71598, 52.275308],
            [49.70198, 52.265764],
            [49.703409, 52.24645],
            [49.680266, 52.251707],
            [49.669295, 52.244964],
            [49.676038, 52.228792],
            [49.659866, 52.230621],
            [49.624494, 52.24782],
            [49.627293, 52.267421],
            [49.65935, 52.282221],
            [49.601005, 52.321707],
            [49.637919, 52.34005],
            [49.573917, 52.389079],
            [49.570031, 52.395936],
            [49.547402, 52.388107],
            [49.520202, 52.395764],
            [49.52066, 52.364506],
            [49.507461, 52.322905],
            [49.450203, 52.320162],
            [49.449288, 52.355191],
            [49.428773, 52.362048],
            [49.434658, 52.39862],
            [49.391685, 52.403762],
            [49.385914, 52.395705],
            [49.345742, 52.397876],
            [49.329, 52.371589],
            [49.286427, 52.385932],
            [49.310256, 52.400847],
            [49.325569, 52.435076],
            [49.320768, 52.45359],
            [49.347969, 52.453534],
            [49.35214, 52.464734],
            [49.279167, 52.479133],
            [49.258082, 52.496366],
            [49.17145, 52.49976],
            [49.169306, 52.472409],
            [49.152278, 52.479347],
            [49.118715, 52.480262],
            [49.108274, 52.494801],
            [49.037628, 52.480623],
            [48.960312, 52.492513],
            [48.956569, 52.471993],
            [48.91233, 52.468457],
            [48.923149, 52.407633],
            [48.882121, 52.40757],
            [48.87722, 52.431127],
            [48.834615, 52.541915],
            [48.818029, 52.544167],
            [48.7272, 52.542621],
            [48.717113, 52.566309],
            [48.693213, 52.639141],
            [48.635552, 52.640738],
            [48.635226, 52.630664],
            [48.574395, 52.627327],
            [48.562456, 52.654564],
            [48.551074, 52.656716],
            [48.533475, 52.674737],
            [48.522155, 52.665387],
            [48.515914, 52.67827],
            [48.480283, 52.670513],
            [48.463958, 52.680546],
            [48.434238, 52.6673],
            [48.349461, 52.662993],
            [48.348317, 52.673311],
            [48.309744, 52.683711],
            [48.349543, 52.705685],
            [48.362917, 52.727508],
            [48.375575, 52.768162],
            [48.428632, 52.814407],
            [48.453573, 52.825236],
            [48.498977, 52.831464],
            [48.552252, 52.850666],
            [48.579817, 52.86443],
            [48.592476, 52.884687],
            [48.588749, 52.895802],
            [48.522826, 52.949445],
            [48.499657, 52.940533],
            [48.449417, 52.952128],
            [48.439125, 52.972621],
            [48.359846, 52.957338],
            [48.355737, 52.975756],
            [48.370665, 52.99223],
            [48.344165, 52.990773],
            [48.325615, 52.996912],
            [48.363287, 53.048013],
            [48.357766, 53.0572],
            [48.320401, 53.061704],
            [48.327158, 53.068815],
            [48.249203, 53.065634],
            [48.22456, 53.004761],
            [48.195896, 53.008791],
            [48.204507, 53.030565],
            [48.185427, 53.041872],
            [48.176285, 53.029107],
            [48.136843, 53.050528],
            [48.124697, 53.051808],
            [48.152256, 53.076101],
            [48.166345, 53.098228],
            [48.209495, 53.123537],
            [48.212984, 53.137229],
            [48.162103, 53.151052],
            [48.169611, 53.159974],
            [48.147129, 53.17181],
            [48.109719, 53.178832],
            [48.111043, 53.203787],
            [48.15998, 53.229979],
            [48.142666, 53.246232],
            [48.100044, 53.255153],
            [48.090503, 53.271715],
            [48.077298, 53.265974],
            [48.05411, 53.270478],
            [48.02381, 53.289426],
            [48.040461, 53.29636],
            [48.009234, 53.311509],
            [47.944573, 53.333415],
            [47.960517, 53.347681],
            [47.936798, 53.362035],
            [47.973191, 53.384472],
            [47.980434, 53.396751],
            [47.998454, 53.40236],
            [47.996113, 53.417819],
            [48.016252, 53.434647],
            [48.026014, 53.421352],
            [48.048759, 53.442553],
            [48.026587, 53.4456],
            [48.023186, 53.456995],
            [48.035817, 53.471305],
            [48.077555, 53.47453],
            [48.090981, 53.48354],
            [48.117393, 53.476872],
            [48.123709, 53.484645],
            [48.146102, 53.483939],
            [48.142127, 53.46636],
            [48.173044, 53.470159],
            [48.174015, 53.478197],
            [48.206213, 53.490962],
            [48.230902, 53.490874],
            [48.244771, 53.477359],
            [48.285185, 53.457617],
            [48.301702, 53.477448],
            [48.31694, 53.469322],
            [48.32648, 53.476521],
            [48.347327, 53.468306],
            [48.369543, 53.474137],
            [48.369939, 53.527844],
            [48.382128, 53.545555],
            [48.395025, 53.542993],
            [48.406508, 53.563752],
            [48.455268, 53.548692],
            [48.465294, 53.556244],
            [48.455665, 53.569583],
            [48.393876, 53.56702],
            [48.393875, 53.583097],
            [48.345203, 53.593476],
            [48.339107, 53.606549],
            [48.348159, 53.652527],
            [48.367726, 53.641132],
            [48.388484, 53.648464],
            [48.392504, 53.635479],
            [48.418519, 53.623863],
            [48.417369, 53.657209],
            [48.460874, 53.652838],
            [48.474433, 53.646168],
            [48.501419, 53.649835],
            [48.533131, 53.666707],
            [48.511312, 53.670019],
            [48.516037, 53.683667],
            [48.496603, 53.694885],
            [48.513607, 53.716571],
            [48.515505, 53.734547],
            [48.488828, 53.740554],
            [48.467042, 53.759266],
            [48.4851, 53.76967],
            [48.534452, 53.754511],
            [48.534054, 53.767805],
            [48.568594, 53.732384],
            [48.606888, 53.720327],
            [48.640411, 53.716264],
            [48.648183, 53.73],
            [48.669517, 53.705311],
            [48.686212, 53.7088],
            [48.686609, 53.727659],
            [48.782186, 53.73168],
            [48.825294, 53.714279],
            [48.843844, 53.716841],
            [48.851574, 53.70602],
            [48.994808, 53.706596],
            [48.994669, 53.872317],
            [49.07863, 53.873328],
            [49.212987, 53.871563],
            [49.249735, 53.836495],
            [49.300351, 53.831284],
            [49.311524, 53.872624],
            [49.418364, 53.86207],
            [49.434, 53.855754],
            [49.594302, 53.839897],
            [49.585494, 53.821659],
            [49.596448, 53.819937],
            [49.589646, 53.805273],
            [49.60166, 53.802623],
            [49.636021, 53.834159],
            [49.66283, 53.835484],
            [49.703278, 53.830193],
            [49.724178, 53.842066],
            [49.737869, 53.859689],
            [49.727755, 53.866888],
            [49.74127, 53.874131],
            [49.816593, 53.889757],
            [49.837582, 53.861436],
            [49.828413, 53.840984],
            [49.850446, 53.831996],
            [50.022697, 53.818294],
            [50.035857, 53.819146],
            [50.04009, 53.861213],
            [50.037275, 53.880285],
            [49.92765, 53.889398],
            [49.931196, 53.905269],
            [49.97804, 53.978772],
            [50.018069, 53.970232],
            [50.014957, 53.96229],
            [50.060934, 53.95845],
            [50.055435, 53.977369],
            [50.081107, 54.007111],
            [50.111715, 54.003341],
            [50.113999, 54.019708],
            [50.167685, 54.027643],
            [50.182345, 54.023657],
            [50.223575, 54.069714],
            [50.202101, 54.094133],
            [50.209123, 54.108576],
            [50.197683, 54.114804],
            [50.201921, 54.168069],
            [50.217998, 54.180127],
            [50.225549, 54.206009],
            [50.242641, 54.217581],
            [50.208851, 54.276234],
            [50.241888, 54.283699],
            [50.238884, 54.295933],
            [50.188619, 54.368322],
            [50.175723, 54.362624],
            [50.150591, 54.375477],
            [50.155801, 54.421101],
            [50.103772, 54.423706],
            [50.111543, 54.493446],
            [50.093655, 54.493844],
            [50.092459, 54.5066],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5497",
      properties: {
        name: "Санкт-Петербург",
        density: 0,
        path: "/world/Russia/Санкт-Петербург",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.647559, 59.929828],
            [29.669902, 59.936709],
            [29.687127, 59.990726],
            [29.69848, 60.00075],
            [29.671394, 60.024205],
            [29.634058, 60.03146],
            [29.73825, 60.02106],
            [29.757302, 60.030453],
            [29.763041, 60.008205],
            [29.793566, 59.992907],
            [29.813863, 59.989638],
            [29.784445, 59.970225],
            [29.765904, 59.968877],
            [29.777948, 59.975457],
            [29.744308, 59.978907],
            [29.750825, 59.987961],
            [29.700798, 59.988343],
            [29.68507, 59.96818],
            [29.673032, 59.937475],
            [29.683596, 59.932642],
            [29.73418, 59.93461],
            [29.779377, 59.930606],
            [29.780162, 59.921109],
            [29.806366, 59.909565],
            [29.932843, 59.88525],
            [29.952333, 59.887527],
            [29.99474, 59.87029],
            [30.031479, 59.861721],
            [30.10114, 59.86424],
            [30.134219, 59.86208],
            [30.164353, 59.877003],
            [30.15376, 59.891865],
            [30.190738, 59.888675],
            [30.211141, 59.901221],
            [30.218879, 59.929249],
            [30.189538, 59.932955],
            [30.190439, 59.953547],
            [30.212048, 59.959672],
            [30.207782, 59.97941],
            [30.18196, 59.98511],
            [30.061114, 59.992511],
            [30.040063, 59.997656],
            [30.01349, 59.992591],
            [29.99802, 60.00274],
            [29.962784, 60.00953],
            [29.94982, 60.03558],
            [29.89682, 60.02664],
            [29.86793, 60.02984],
            [29.94998, 60.03737],
            [29.96286, 60.04118],
            [29.960007, 60.061411],
            [29.93238, 60.06977],
            [29.94013, 60.07625],
            [29.919119, 60.088842],
            [29.94581, 60.10839],
            [29.92224, 60.1463],
            [29.87115, 60.16112],
            [29.84908, 60.16134],
            [29.772912, 60.179969],
            [29.72168, 60.1863],
            [29.57289, 60.19682],
            [29.54586, 60.19325],
            [29.52654, 60.18261],
            [29.47675, 60.17676],
            [29.457377, 60.169779],
            [29.4389, 60.176428],
            [29.469222, 60.181487],
            [29.42981, 60.189538],
            [29.490349, 60.224964],
            [29.521152, 60.233286],
            [29.521235, 60.225715],
            [29.579326, 60.219809],
            [29.643965, 60.242192],
            [29.69096, 60.237576],
            [29.719902, 60.225924],
            [29.713353, 60.241376],
            [29.758015, 60.242701],
            [29.785676, 60.222078],
            [29.801803, 60.221276],
            [29.849118, 60.197295],
            [30.007516, 60.165109],
            [30.023036, 60.143029],
            [30.068275, 60.135832],
            [30.192502, 60.125877],
            [30.189739, 60.117365],
            [30.232764, 60.114415],
            [30.259144, 60.125758],
            [30.275745, 60.102217],
            [30.302941, 60.091104],
            [30.37004, 60.088377],
            [30.392749, 60.055272],
            [30.468412, 60.033393],
            [30.46788, 60.022636],
            [30.489542, 60.022454],
            [30.490446, 60.008026],
            [30.513354, 59.983375],
            [30.534726, 59.992412],
            [30.544485, 59.976283],
            [30.512311, 59.967529],
            [30.495419, 59.891479],
            [30.521344, 59.861529],
            [30.493672, 59.856844],
            [30.493413, 59.847083],
            [30.540985, 59.833595],
            [30.558082, 59.836605],
            [30.563241, 59.825223],
            [30.589738, 59.816848],
            [30.625462, 59.79543],
            [30.697214, 59.781414],
            [30.736193, 59.779121],
            [30.727032, 59.770433],
            [30.757012, 59.743751],
            [30.703055, 59.745284],
            [30.682984, 59.724918],
            [30.648782, 59.721816],
            [30.63112, 59.735854],
            [30.576483, 59.73],
            [30.564495, 59.704998],
            [30.530688, 59.707325],
            [30.520541, 59.690944],
            [30.48795, 59.688416],
            [30.47712, 59.6807],
            [30.501904, 59.670247],
            [30.505564, 59.646024],
            [30.467429, 59.639224],
            [30.437203, 59.662958],
            [30.40637, 59.64945],
            [30.366668, 59.650194],
            [30.351922, 59.658231],
            [30.306506, 59.662096],
            [30.254172, 59.633783],
            [30.242922, 59.63863],
            [30.261542, 59.648656],
            [30.23863, 59.661783],
            [30.249575, 59.671021],
            [30.218223, 59.673069],
            [30.231665, 59.689384],
            [30.255618, 59.701724],
            [30.300721, 59.747387],
            [30.250585, 59.765601],
            [30.28985, 59.790402],
            [30.211009, 59.797537],
            [30.17948, 59.790583],
            [30.162925, 59.796426],
            [30.141685, 59.76117],
            [30.117987, 59.761779],
            [30.107026, 59.724548],
            [30.133743, 59.694757],
            [30.100898, 59.699199],
            [30.080821, 59.715371],
            [30.040947, 59.719632],
            [30.077229, 59.747534],
            [30.105084, 59.802945],
            [30.12769, 59.809289],
            [30.106357, 59.81671],
            [30.053039, 59.816193],
            [30.038641, 59.821716],
            [30.044272, 59.837316],
            [30.005135, 59.83638],
            [29.944059, 59.828085],
            [29.945457, 59.844794],
            [29.892708, 59.853855],
            [29.886267, 59.842746],
            [29.872016, 59.856211],
            [29.821382, 59.868244],
            [29.798885, 59.858308],
            [29.771356, 59.87222],
            [29.7245, 59.871103],
            [29.714945, 59.889277],
            [29.72208, 59.9015],
            [29.656531, 59.915543],
            [29.647559, 59.929828],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5533",
      properties: {
        name: "Саратовская область",
        density: 0,
        path: "/world/Russia/Саратовская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.039706, 52.370635],
            [43.087174, 52.372175],
            [43.097226, 52.382538],
            [43.117153, 52.37877],
            [43.142162, 52.438962],
            [43.157808, 52.437517],
            [43.178883, 52.452091],
            [43.240315, 52.436983],
            [43.245243, 52.426943],
            [43.283355, 52.440201],
            [43.322433, 52.420056],
            [43.321052, 52.403211],
            [43.336238, 52.373748],
            [43.366971, 52.353769],
            [43.374095, 52.371165],
            [43.414062, 52.382003],
            [43.461067, 52.375678],
            [43.488266, 52.379427],
            [43.510974, 52.401945],
            [43.543858, 52.398231],
            [43.56725, 52.407008],
            [43.584709, 52.397404],
            [43.597952, 52.400061],
            [43.669829, 52.393654],
            [43.709458, 52.418702],
            [43.747019, 52.432056],
            [43.755197, 52.425884],
            [43.797414, 52.418835],
            [43.864351, 52.380937],
            [43.891909, 52.405085],
            [43.938736, 52.380685],
            [43.965897, 52.360544],
            [43.997444, 52.34338],
            [44.001124, 52.350691],
            [44.028928, 52.330323],
            [44.064811, 52.352987],
            [44.095208, 52.32651],
            [44.114037, 52.330932],
            [44.156717, 52.306612],
            [44.177899, 52.33266],
            [44.196805, 52.347351],
            [44.218318, 52.321992],
            [44.258845, 52.314328],
            [44.321377, 52.278928],
            [44.332756, 52.294122],
            [44.347376, 52.29285],
            [44.334083, 52.308371],
            [44.314552, 52.306192],
            [44.3389, 52.337068],
            [44.356178, 52.335326],
            [44.35696, 52.3482],
            [44.372671, 52.366274],
            [44.374613, 52.396503],
            [44.381569, 52.413844],
            [44.39803, 52.413792],
            [44.401212, 52.429344],
            [44.425629, 52.42633],
            [44.448574, 52.431995],
            [44.466022, 52.428167],
            [44.461003, 52.413593],
            [44.47943, 52.429486],
            [44.497208, 52.425548],
            [44.506151, 52.440331],
            [44.535785, 52.450395],
            [44.520031, 52.481625],
            [44.506254, 52.485864],
            [44.520512, 52.502196],
            [44.503006, 52.505867],
            [44.507483, 52.523551],
            [44.532591, 52.532595],
            [44.570131, 52.505648],
            [44.591168, 52.509917],
            [44.620977, 52.489249],
            [44.613324, 52.480727],
            [44.665932, 52.460764],
            [44.682972, 52.463814],
            [44.783179, 52.45117],
            [44.79549, 52.438479],
            [44.853116, 52.447721],
            [44.8751, 52.424649],
            [44.8917, 52.416733],
            [44.892952, 52.356528],
            [44.952157, 52.356257],
            [44.95333, 52.365614],
            [44.98313, 52.365641],
            [44.983033, 52.33875],
            [45.027721, 52.316085],
            [45.059969, 52.306116],
            [45.088581, 52.313289],
            [45.085446, 52.338755],
            [45.061078, 52.36153],
            [45.068893, 52.38028],
            [45.088551, 52.379492],
            [45.093912, 52.400002],
            [45.148294, 52.418508],
            [45.174283, 52.418244],
            [45.17267, 52.409204],
            [45.19833, 52.404832],
            [45.207339, 52.384094],
            [45.233534, 52.375677],
            [45.243229, 52.385016],
            [45.267626, 52.390862],
            [45.295126, 52.376046],
            [45.299771, 52.391998],
            [45.317327, 52.396867],
            [45.352976, 52.396469],
            [45.359899, 52.386716],
            [45.384038, 52.410578],
            [45.409938, 52.402415],
            [45.429318, 52.38629],
            [45.442501, 52.400184],
            [45.448791, 52.424964],
            [45.468635, 52.426798],
            [45.489449, 52.413239],
            [45.4927, 52.426717],
            [45.513653, 52.425543],
            [45.524668, 52.459737],
            [45.524166, 52.47653],
            [45.552133, 52.465001],
            [45.576436, 52.464282],
            [45.583588, 52.453142],
            [45.600943, 52.452551],
            [45.589871, 52.469449],
            [45.62145, 52.477667],
            [45.640552, 52.507726],
            [45.652083, 52.508327],
            [45.654974, 52.485144],
            [45.667805, 52.474003],
            [45.700307, 52.471891],
            [45.698533, 52.440915],
            [45.732676, 52.45693],
            [45.770688, 52.457491],
            [45.766139, 52.427567],
            [45.794148, 52.41629],
            [45.823238, 52.420537],
            [45.835325, 52.431397],
            [45.850129, 52.412961],
            [45.908432, 52.415284],
            [45.914225, 52.405492],
            [45.961039, 52.396793],
            [45.960923, 52.418503],
            [45.977432, 52.423126],
            [45.996931, 52.447368],
            [46.012701, 52.454175],
            [45.983535, 52.474385],
            [46.004107, 52.510991],
            [46.028676, 52.514732],
            [46.054819, 52.533722],
            [46.065976, 52.528585],
            [46.081744, 52.538846],
            [46.084433, 52.555158],
            [46.122478, 52.544849],
            [46.119078, 52.523341],
            [46.136282, 52.526805],
            [46.125701, 52.546721],
            [46.140302, 52.549581],
            [46.140672, 52.587105],
            [46.166902, 52.607934],
            [46.178785, 52.599827],
            [46.198966, 52.601671],
            [46.210562, 52.614092],
            [46.268342, 52.610239],
            [46.261215, 52.626334],
            [46.261472, 52.650081],
            [46.289592, 52.668342],
            [46.325899, 52.680843],
            [46.37749, 52.675505],
            [46.412709, 52.675961],
            [46.417894, 52.664397],
            [46.436128, 52.669456],
            [46.432712, 52.684914],
            [46.45509, 52.678501],
            [46.489002, 52.681361],
            [46.522894, 52.694115],
            [46.539042, 52.688495],
            [46.571619, 52.702207],
            [46.577626, 52.725591],
            [46.593834, 52.721665],
            [46.597012, 52.694029],
            [46.609288, 52.662354],
            [46.659886, 52.664758],
            [46.680429, 52.64265],
            [46.702319, 52.635175],
            [46.743722, 52.611189],
            [46.801859, 52.621141],
            [46.802044, 52.613041],
            [46.831303, 52.598039],
            [46.83619, 52.61765],
            [46.865045, 52.614356],
            [46.932318, 52.620723],
            [46.954585, 52.633864],
            [46.980435, 52.638251],
            [46.990669, 52.623156],
            [46.993996, 52.601513],
            [46.986556, 52.585408],
            [47.006543, 52.574224],
            [47.036927, 52.583528],
            [47.050478, 52.573086],
            [47.04217, 52.53953],
            [47.062545, 52.537086],
            [47.067637, 52.560035],
            [47.063957, 52.577196],
            [47.148724, 52.5824],
            [47.163278, 52.595878],
            [47.166024, 52.586922],
            [47.156274, 52.558028],
            [47.20611, 52.547227],
            [47.230865, 52.570448],
            [47.245741, 52.556083],
            [47.380715, 52.57369],
            [47.424835, 52.561866],
            [47.442631, 52.563272],
            [47.511653, 52.590091],
            [47.490948, 52.603563],
            [47.495989, 52.621203],
            [47.540305, 52.610497],
            [47.645691, 52.604255],
            [47.648563, 52.59098],
            [47.679419, 52.597673],
            [47.722415, 52.570621],
            [47.738159, 52.581028],
            [47.7591, 52.580584],
            [47.756634, 52.589217],
            [47.772851, 52.601747],
            [47.752544, 52.619705],
            [47.769558, 52.648758],
            [47.763173, 52.677789],
            [47.786076, 52.675448],
            [47.828939, 52.703276],
            [47.802695, 52.700814],
            [47.812411, 52.736339],
            [47.833887, 52.736098],
            [47.897102, 52.724596],
            [47.8957, 52.707995],
            [47.907311, 52.706928],
            [47.906548, 52.678278],
            [47.933741, 52.646579],
            [47.951717, 52.655768],
            [47.974351, 52.656869],
            [47.977949, 52.665549],
            [47.999054, 52.659682],
            [48.042756, 52.678249],
            [48.063442, 52.676318],
            [48.064964, 52.704312],
            [48.092152, 52.732734],
            [47.991287, 52.731816],
            [47.99333, 52.744964],
            [48.017933, 52.765502],
            [48.041486, 52.769734],
            [48.070866, 52.765292],
            [48.067795, 52.774197],
            [48.088773, 52.793553],
            [48.115787, 52.794915],
            [48.19549, 52.769032],
            [48.230527, 52.813229],
            [48.268826, 52.810423],
            [48.280956, 52.795645],
            [48.30678, 52.779779],
            [48.34481, 52.78718],
            [48.383436, 52.814898],
            [48.428632, 52.814407],
            [48.375575, 52.768162],
            [48.362917, 52.727508],
            [48.349543, 52.705685],
            [48.309744, 52.683711],
            [48.348317, 52.673311],
            [48.349461, 52.662993],
            [48.434238, 52.6673],
            [48.463958, 52.680546],
            [48.480283, 52.670513],
            [48.515914, 52.67827],
            [48.522155, 52.665387],
            [48.533475, 52.674737],
            [48.551074, 52.656716],
            [48.562456, 52.654564],
            [48.574395, 52.627327],
            [48.635226, 52.630664],
            [48.635552, 52.640738],
            [48.693213, 52.639141],
            [48.717113, 52.566309],
            [48.7272, 52.542621],
            [48.818029, 52.544167],
            [48.834615, 52.541915],
            [48.87722, 52.431127],
            [48.882121, 52.40757],
            [48.923149, 52.407633],
            [48.91233, 52.468457],
            [48.956569, 52.471993],
            [48.960312, 52.492513],
            [49.037628, 52.480623],
            [49.108274, 52.494801],
            [49.118715, 52.480262],
            [49.152278, 52.479347],
            [49.169306, 52.472409],
            [49.17145, 52.49976],
            [49.258082, 52.496366],
            [49.279167, 52.479133],
            [49.35214, 52.464734],
            [49.347969, 52.453534],
            [49.320768, 52.45359],
            [49.325569, 52.435076],
            [49.310256, 52.400847],
            [49.286427, 52.385932],
            [49.329, 52.371589],
            [49.345742, 52.397876],
            [49.385914, 52.395705],
            [49.391685, 52.403762],
            [49.434658, 52.39862],
            [49.428773, 52.362048],
            [49.449288, 52.355191],
            [49.450203, 52.320162],
            [49.507461, 52.322905],
            [49.52066, 52.364506],
            [49.520202, 52.395764],
            [49.547402, 52.388107],
            [49.570031, 52.395936],
            [49.573917, 52.389079],
            [49.637919, 52.34005],
            [49.601005, 52.321707],
            [49.65935, 52.282221],
            [49.627293, 52.267421],
            [49.624494, 52.24782],
            [49.659866, 52.230621],
            [49.676038, 52.228792],
            [49.669295, 52.244964],
            [49.680266, 52.251707],
            [49.703409, 52.24645],
            [49.70198, 52.265764],
            [49.71598, 52.275308],
            [49.721009, 52.262793],
            [49.733409, 52.272851],
            [49.734438, 52.251936],
            [49.750267, 52.239022],
            [49.798156, 52.172336],
            [49.985816, 52.171653],
            [49.984901, 52.18851],
            [50.039759, 52.190111],
            [50.045132, 52.139653],
            [50.163705, 52.139997],
            [50.163706, 52.130569],
            [50.223421, 52.129312],
            [50.224736, 52.090626],
            [50.159593, 52.090682],
            [50.160395, 52.02011],
            [50.156395, 52.009824],
            [50.181938, 52.01811],
            [50.183595, 52.030853],
            [50.215367, 52.036168],
            [50.281083, 52.01314],
            [50.30634, 52.014855],
            [50.308682, 52.044798],
            [50.352111, 52.044856],
            [50.352399, 51.981541],
            [50.3964, 51.980227],
            [50.406228, 51.99297],
            [50.520058, 51.993772],
            [50.521259, 51.963828],
            [50.597203, 51.963487],
            [50.649261, 51.958687],
            [50.649376, 51.933258],
            [50.70172, 51.932973],
            [50.701438, 51.819657],
            [50.717552, 51.819543],
            [50.719096, 51.788057],
            [50.768482, 51.773511],
            [50.763, 51.7287],
            [50.7805, 51.6963],
            [50.84, 51.6488],
            [50.8169, 51.647],
            [50.8157, 51.5885],
            [50.7695, 51.5865],
            [50.7667, 51.5768],
            [50.683, 51.5748],
            [50.6786, 51.5873],
            [50.7261, 51.609],
            [50.7289, 51.6306],
            [50.6439, 51.6322],
            [50.6305, 51.6465],
            [50.5886, 51.6447],
            [50.5877, 51.5933],
            [50.569, 51.587],
            [50.5516, 51.5949],
            [50.5401, 51.5896],
            [50.5307, 51.5248],
            [50.5498, 51.4782],
            [50.537, 51.4682],
            [50.5053, 51.4681],
            [50.4971, 51.4312],
            [50.3749, 51.4288],
            [50.4025, 51.4047],
            [50.3382, 51.3819],
            [50.3718, 51.3407],
            [50.3604, 51.3318],
            [50.2507, 51.2859],
            [50.085, 51.2597],
            [50.0478, 51.2558],
            [49.9894, 51.2401],
            [49.9072, 51.2034],
            [49.8989, 51.1925],
            [49.844, 51.1569],
            [49.7809, 51.1113],
            [49.6866, 51.1111],
            [49.5937, 51.1145],
            [49.5523, 51.1141],
            [49.4855, 51.1026],
            [49.4576, 51.1282],
            [49.4423, 51.1156],
            [49.4287, 51.0937],
            [49.3888, 51.094],
            [49.3918, 51.0482],
            [49.3382, 50.9908],
            [49.3826, 50.9833],
            [49.3972, 50.9728],
            [49.438, 50.9032],
            [49.4399, 50.8888],
            [49.4198, 50.8517],
            [49.2716, 50.8289],
            [49.2032, 50.7973],
            [49.1213, 50.788],
            [49.1092, 50.7723],
            [49.0094, 50.6863],
            [48.9949, 50.6816],
            [48.9501, 50.6504],
            [48.8765, 50.6192],
            [48.8295, 50.5968],
            [48.7893, 50.6113],
            [48.7552, 50.6152],
            [48.7202, 50.6071],
            [48.6857, 50.6192],
            [48.6433, 50.6489],
            [48.6245, 50.6575],
            [48.602, 50.6544],
            [48.5693, 50.6294],
            [48.5968, 50.6165],
            [48.651, 50.6068],
            [48.6444, 50.5987],
            [48.6594, 50.581],
            [48.6457, 50.5438],
            [48.644, 50.5221],
            [48.6668, 50.5172],
            [48.6726, 50.4569],
            [48.7053, 50.3624],
            [48.7315, 50.2542],
            [48.7913, 50.2059],
            [48.8084, 50.1684],
            [48.7598, 50.1009],
            [48.7904, 50.1042],
            [48.827, 50.0988],
            [48.8441, 50.0918],
            [48.8616, 50.075],
            [48.8682, 50.0508],
            [48.8801, 50.0349],
            [48.9016, 50.0228],
            [48.7782, 49.944],
            [48.7485, 49.9227],
            [48.699, 49.91],
            [48.5262, 49.859],
            [48.451, 49.8349],
            [48.4443, 49.8031],
            [48.4259, 49.809],
            [48.3798, 49.8394],
            [48.3566, 49.8308],
            [48.3445, 49.8485],
            [48.3304, 49.8527],
            [48.3098, 49.8738],
            [48.2895, 49.8625],
            [48.2278, 49.8708],
            [48.2319, 49.8971],
            [48.2107, 49.9263],
            [48.1698, 49.9586],
            [48.1339, 50],
            [48.1227, 50.024],
            [48.1185, 50.0483],
            [48.0958, 50.099],
            [48.0731, 50.1045],
            [48.05, 50.1174],
            [48.0477, 50.1282],
            [48.0102, 50.1498],
            [48.0063, 50.1676],
            [47.9843, 50.1869],
            [47.9679, 50.1937],
            [47.9503, 50.2253],
            [47.9184, 50.2541],
            [47.8843, 50.2711],
            [47.8701, 50.2904],
            [47.8486, 50.307],
            [47.821, 50.3159],
            [47.8303, 50.33],
            [47.7871, 50.3354],
            [47.7665, 50.3442],
            [47.7424, 50.3638],
            [47.7474, 50.3779],
            [47.7214, 50.3911],
            [47.6934, 50.3934],
            [47.662, 50.4097],
            [47.6725, 50.422],
            [47.6514, 50.4222],
            [47.6248, 50.4491],
            [47.6335, 50.4541],
            [47.582, 50.466],
            [47.5337, 50.4575],
            [47.5059, 50.438],
            [47.4682, 50.4174],
            [47.4416, 50.3928],
            [47.430817, 50.376814],
            [47.272176, 50.379065],
            [47.272091, 50.371398],
            [47.235211, 50.371022],
            [47.23683, 50.429536],
            [47.204637, 50.430211],
            [47.205309, 50.509006],
            [47.038152, 50.509679],
            [47.038641, 50.489146],
            [46.911452, 50.489874],
            [46.915051, 50.522865],
            [46.870769, 50.523747],
            [46.870767, 50.577215],
            [46.803181, 50.575686],
            [46.814034, 50.591789],
            [46.812983, 50.60653],
            [46.853431, 50.626405],
            [46.856582, 50.641039],
            [46.775424, 50.642726],
            [46.742104, 50.65871],
            [46.727447, 50.703916],
            [46.686947, 50.697544],
            [46.679968, 50.689326],
            [46.646424, 50.682572],
            [46.611867, 50.686174],
            [46.583388, 50.712288],
            [46.56808, 50.690451],
            [46.56871, 50.61463],
            [46.553923, 50.622115],
            [46.548105, 50.613879],
            [46.538285, 50.644435],
            [46.519471, 50.641973],
            [46.527074, 50.623485],
            [46.49785, 50.625068],
            [46.492468, 50.634456],
            [46.45836, 50.617155],
            [46.460578, 50.597562],
            [46.442723, 50.585304],
            [46.454607, 50.556361],
            [46.495777, 50.553459],
            [46.465426, 50.545692],
            [46.414542, 50.549634],
            [46.387419, 50.555709],
            [46.345208, 50.555483],
            [46.344307, 50.573606],
            [46.275643, 50.57293],
            [46.278413, 50.527697],
            [46.06146, 50.526166],
            [46.06719, 50.531507],
            [46.065053, 50.607239],
            [46.091149, 50.617278],
            [46.091598, 50.665005],
            [46.034078, 50.665117],
            [46.03554, 50.687179],
            [45.987475, 50.688529],
            [45.983085, 50.699448],
            [45.966426, 50.700573],
            [45.952805, 50.713068],
            [45.893032, 50.749988],
            [45.842266, 50.756178],
            [45.744223, 50.757302],
            [45.715632, 50.737716],
            [45.685917, 50.691564],
            [45.678601, 50.667025],
            [45.702129, 50.605341],
            [45.705741, 50.582496],
            [45.684345, 50.575624],
            [45.633353, 50.578887],
            [45.606113, 50.590594],
            [45.553433, 50.586653],
            [45.559737, 50.571457],
            [45.552646, 50.556486],
            [45.533826, 50.551134],
            [45.496701, 50.585302],
            [45.489946, 50.603874],
            [45.427136, 50.60635],
            [45.402934, 50.613329],
            [45.385037, 50.589915],
            [45.192553, 50.585635],
            [45.200994, 50.634601],
            [45.218553, 50.659027],
            [45.206509, 50.662741],
            [45.234198, 50.698987],
            [45.202444, 50.69696],
            [45.18518, 50.710334],
            [45.194154, 50.715512],
            [45.209948, 50.701555],
            [45.27866, 50.718574],
            [45.285525, 50.769115],
            [45.312539, 50.808626],
            [45.326157, 50.860856],
            [45.321317, 50.877965],
            [45.30173, 50.905768],
            [45.304881, 50.913985],
            [45.267397, 50.925692],
            [45.24646, 50.917924],
            [45.22541, 50.936047],
            [45.250625, 50.933458],
            [45.24736, 50.942688],
            [45.217385, 50.94954],
            [45.203797, 50.967902],
            [45.218205, 50.980397],
            [45.201207, 50.997394],
            [45.202219, 51.020469],
            [45.220229, 51.039943],
            [45.188598, 51.044445],
            [45.1483, 51.064931],
            [45.094824, 51.077452],
            [45.074757, 51.087625],
            [45.077528, 51.099738],
            [45.06849, 51.119186],
            [45.044514, 51.132693],
            [45.03292, 51.127966],
            [44.993972, 51.146763],
            [44.981703, 51.120536],
            [44.953562, 51.122449],
            [44.939155, 51.106577],
            [44.894917, 51.118058],
            [44.896617, 51.162866],
            [44.863315, 51.170724],
            [44.834476, 51.15899],
            [44.809757, 51.160282],
            [44.789676, 51.148431],
            [44.741829, 51.152275],
            [44.643897, 51.178614],
            [44.615644, 51.166682],
            [44.627127, 51.126046],
            [44.593444, 51.11936],
            [44.571332, 51.123383],
            [44.548107, 51.153061],
            [44.516588, 51.179513],
            [44.499365, 51.205177],
            [44.455127, 51.210805],
            [44.450963, 51.191444],
            [44.423273, 51.179174],
            [44.401547, 51.188516],
            [44.374645, 51.189079],
            [44.350331, 51.175571],
            [44.314986, 51.177034],
            [44.300465, 51.185813],
            [44.282793, 51.1848],
            [44.282567, 51.195606],
            [44.228199, 51.198982],
            [44.210865, 51.18176],
            [44.215368, 51.16465],
            [44.208052, 51.150017],
            [44.215256, 51.12863],
            [44.184978, 51.093284],
            [44.129821, 51.106453],
            [44.140289, 51.119061],
            [44.116988, 51.124914],
            [44.098979, 51.099924],
            [44.058569, 51.102738],
            [44.050803, 51.072233],
            [44.018499, 51.072218],
            [44.019169, 51.159132],
            [43.975157, 51.155304],
            [43.958723, 51.147087],
            [43.916286, 51.160594],
            [43.882294, 51.110953],
            [43.848525, 51.099921],
            [43.805638, 51.117818],
            [43.753295, 51.121307],
            [43.737424, 51.113652],
            [43.694087, 51.108361],
            [43.673713, 51.121531],
            [43.661218, 51.114101],
            [43.679904, 51.109824],
            [43.674501, 51.098005],
            [43.687672, 51.084948],
            [43.681932, 51.064236],
            [43.652666, 51.053429],
            [43.561489, 51.060632],
            [43.527157, 51.048362],
            [43.511849, 51.050501],
            [43.433055, 51.027537],
            [43.421123, 51.034178],
            [43.380939, 51.005699],
            [43.357075, 50.998832],
            [43.324206, 51.013014],
            [43.262745, 51.061529],
            [43.182935, 51.113982],
            [43.13836, 51.122649],
            [43.11589, 51.133724],
            [42.949355, 51.201463],
            [42.880894, 51.233025],
            [42.941504, 51.256251],
            [42.947337, 51.26722],
            [42.904514, 51.286697],
            [42.858106, 51.316673],
            [42.843463, 51.338709],
            [42.853608, 51.358419],
            [42.85907, 51.397253],
            [42.849233, 51.401642],
            [42.834455, 51.391738],
            [42.767992, 51.472282],
            [42.752124, 51.494893],
            [42.630981, 51.53074],
            [42.568272, 51.538178],
            [42.546505, 51.578481],
            [42.534912, 51.577671],
            [42.520795, 51.596072],
            [42.517694, 51.625964],
            [42.50393, 51.659493],
            [42.515436, 51.712054],
            [42.523268, 51.721259],
            [42.649988, 51.790984],
            [42.665625, 51.809889],
            [42.682054, 51.84889],
            [42.712606, 51.88562],
            [42.708677, 51.948355],
            [42.724336, 51.965108],
            [42.715504, 51.987425],
            [42.727406, 51.992303],
            [42.72749, 52.006383],
            [42.762415, 52.017934],
            [42.810973, 52.014429],
            [42.820193, 52.022105],
            [42.797904, 52.024283],
            [42.791586, 52.039823],
            [42.77699, 52.041668],
            [42.77705, 52.062591],
            [42.785914, 52.071813],
            [42.761829, 52.082103],
            [42.760329, 52.111908],
            [42.767203, 52.155121],
            [42.775005, 52.168904],
            [42.771364, 52.183776],
            [42.790335, 52.183798],
            [42.823214, 52.200806],
            [42.84362, 52.200376],
            [42.85259, 52.210761],
            [42.888997, 52.213045],
            [42.887111, 52.239204],
            [42.89502, 52.259393],
            [42.92259, 52.290899],
            [42.941326, 52.3358],
            [42.918202, 52.360338],
            [42.988102, 52.348922],
            [43.032022, 52.358105],
            [43.039706, 52.370635],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5468",
      properties: {
        name: "Сахалинская область",
        density: 0,
        path: "/world/Russia/Сахалинская область",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [145.987479, 43.369275],
              [146.016252, 43.37376],
              [146.005913, 43.360583],
              [145.987479, 43.369275],
            ],
          ],
          [
            [
              [146.178942, 43.505024],
              [146.19582, 43.495136],
              [146.187868, 43.483532],
              [146.1461, 43.471423],
              [146.116688, 43.458625],
              [146.106873, 43.49114],
              [146.090346, 43.500248],
              [146.078803, 43.517477],
              [146.060372, 43.523615],
              [146.08139, 43.532895],
              [146.119375, 43.540379],
              [146.133017, 43.548439],
              [146.17416, 43.532192],
              [146.204119, 43.534201],
              [146.178942, 43.505024],
            ],
          ],
          [
            [
              [146.10087, 43.432454],
              [146.097534, 43.419982],
              [146.08109, 43.413179],
              [146.06098, 43.416095],
              [146.045354, 43.402365],
              [146.02555, 43.408356],
              [146.027839, 43.419154],
              [146.048083, 43.430359],
              [146.071289, 43.432603],
              [146.081256, 43.425498],
              [146.084204, 43.440149],
              [146.097826, 43.4477],
              [146.10087, 43.432454],
            ],
          ],
          [
            [
              [145.898437, 43.418565],
              [145.888668, 43.425072],
              [145.900937, 43.437765],
              [145.88466, 43.451465],
              [145.863431, 43.456645],
              [145.888701, 43.461769],
              [145.898813, 43.448137],
              [145.93772, 43.448891],
              [145.939864, 43.437106],
              [145.955216, 43.424929],
              [145.91224, 43.42594],
              [145.898437, 43.418565],
            ],
          ],
          [
            [
              [146.33299, 43.618811],
              [146.270005, 43.628049],
              [146.292258, 43.628317],
              [146.310499, 43.639592],
              [146.311781, 43.651147],
              [146.33312, 43.638956],
              [146.350786, 43.639058],
              [146.357193, 43.618052],
              [146.33299, 43.618811],
            ],
          ],
          [
            [
              [146.862947, 43.874848],
              [146.883916, 43.854086],
              [146.903082, 43.84384],
              [146.914778, 43.846672],
              [146.915169, 43.820755],
              [146.89836, 43.805345],
              [146.87798, 43.808917],
              [146.8507, 43.806575],
              [146.843278, 43.799894],
              [146.853753, 43.790156],
              [146.833767, 43.780121],
              [146.81639, 43.788306],
              [146.793083, 43.766968],
              [146.805508, 43.756982],
              [146.789621, 43.744251],
              [146.778599, 43.757703],
              [146.734404, 43.744265],
              [146.73249, 43.737075],
              [146.711806, 43.732662],
              [146.687274, 43.720281],
              [146.676199, 43.707419],
              [146.64333, 43.702189],
              [146.64219, 43.715786],
              [146.620478, 43.736501],
              [146.594278, 43.737951],
              [146.586781, 43.747419],
              [146.60434, 43.769293],
              [146.586554, 43.805925],
              [146.613566, 43.820646],
              [146.635903, 43.81918],
              [146.662436, 43.827473],
              [146.716296, 43.830955],
              [146.72155, 43.845338],
              [146.792339, 43.879361],
              [146.813633, 43.876831],
              [146.824592, 43.889679],
              [146.843402, 43.888167],
              [146.848399, 43.87637],
              [146.862947, 43.874848],
            ],
          ],
          [
            [
              [145.546875, 43.654267],
              [145.535456, 43.668214],
              [145.54759, 43.729442],
              [145.521278, 43.734494],
              [145.491943, 43.732955],
              [145.450018, 43.725722],
              [145.433907, 43.719344],
              [145.421563, 43.738036],
              [145.41958, 43.752891],
              [145.426319, 43.779415],
              [145.409121, 43.820233],
              [145.399009, 43.827277],
              [145.404508, 43.840441],
              [145.441826, 43.85045],
              [145.472167, 43.898075],
              [145.534214, 43.921982],
              [145.534872, 43.931355],
              [145.59065, 43.952457],
              [145.593342, 43.966347],
              [145.630504, 43.987967],
              [145.64114, 43.989249],
              [145.654302, 44.004288],
              [145.677233, 44.012253],
              [145.682966, 44.026304],
              [145.732712, 44.044957],
              [145.747266, 44.063336],
              [145.744258, 44.104614],
              [145.752028, 44.10765],
              [145.787827, 44.150758],
              [145.818177, 44.165702],
              [145.8558, 44.201751],
              [145.862711, 44.222604],
              [145.88718, 44.24953],
              [145.931659, 44.277153],
              [145.946276, 44.278572],
              [145.952829, 44.291718],
              [145.99142, 44.322599],
              [146.000472, 44.336534],
              [146.009672, 44.374075],
              [146.005573, 44.38379],
              [146.023344, 44.390871],
              [146.04906, 44.41572],
              [146.074712, 44.457753],
              [146.0788, 44.471128],
              [146.108787, 44.490353],
              [146.12718, 44.506916],
              [146.172427, 44.514437],
              [146.194051, 44.493552],
              [146.226306, 44.480433],
              [146.240798, 44.454616],
              [146.259908, 44.444735],
              [146.27963, 44.442551],
              [146.28368, 44.433853],
              [146.350118, 44.416674],
              [146.395888, 44.421801],
              [146.463933, 44.419574],
              [146.540785, 44.434791],
              [146.55073, 44.445479],
              [146.569677, 44.450427],
              [146.55157, 44.428805],
              [146.53412, 44.394096],
              [146.533341, 44.380238],
              [146.484904, 44.355141],
              [146.450718, 44.377945],
              [146.423923, 44.374221],
              [146.390785, 44.361606],
              [146.367762, 44.340697],
              [146.36549, 44.319259],
              [146.349394, 44.320452],
              [146.342367, 44.309635],
              [146.30422, 44.286958],
              [146.295959, 44.275874],
              [146.221346, 44.273721],
              [146.19712, 44.269448],
              [146.16496, 44.271271],
              [146.142911, 44.264693],
              [146.125743, 44.266615],
              [146.100882, 44.257827],
              [146.068402, 44.234601],
              [146.047726, 44.19992],
              [146.050374, 44.179415],
              [146.034944, 44.185444],
              [146.024736, 44.177403],
              [145.981355, 44.170609],
              [145.965979, 44.152895],
              [145.941585, 44.151773],
              [145.907379, 44.122456],
              [145.900633, 44.111615],
              [145.902913, 44.094316],
              [145.883467, 44.087072],
              [145.864326, 44.054809],
              [145.864382, 44.033255],
              [145.877599, 44.020813],
              [145.853951, 44.020834],
              [145.84806, 44.031771],
              [145.831537, 44.030486],
              [145.814904, 44.017511],
              [145.79592, 43.988933],
              [145.78819, 43.967297],
              [145.789108, 43.948318],
              [145.776654, 43.932147],
              [145.765294, 43.932025],
              [145.715485, 43.911846],
              [145.673919, 43.905598],
              [145.652182, 43.909449],
              [145.637617, 43.90222],
              [145.624732, 43.883847],
              [145.60452, 43.868206],
              [145.590294, 43.869827],
              [145.575504, 43.842957],
              [145.569537, 43.795924],
              [145.572266, 43.770554],
              [145.56161, 43.729228],
              [145.55351, 43.667161],
              [145.546875, 43.654267],
            ],
          ],
          [
            [
              [146.953325, 44.427237],
              [146.90749, 44.429262],
              [146.872377, 44.439491],
              [146.858556, 44.452946],
              [146.875295, 44.498637],
              [146.896947, 44.519954],
              [146.92312, 44.524978],
              [146.950717, 44.54693],
              [146.954118, 44.556169],
              [146.93202, 44.580019],
              [146.933068, 44.606703],
              [146.94849, 44.631086],
              [146.959295, 44.594143],
              [146.976897, 44.577984],
              [147.00418, 44.581065],
              [147.026265, 44.599023],
              [147.03239, 44.639046],
              [147.004364, 44.654702],
              [147.012094, 44.660986],
              [147.0599, 44.655199],
              [147.080364, 44.636841],
              [147.121351, 44.647197],
              [147.162781, 44.682295],
              [147.178981, 44.687916],
              [147.191272, 44.706882],
              [147.200221, 44.746121],
              [147.191819, 44.763582],
              [147.157264, 44.76992],
              [147.128811, 44.769956],
              [147.11316, 44.779851],
              [147.101132, 44.8009],
              [147.102242, 44.817319],
              [147.13039, 44.829862],
              [147.169652, 44.818522],
              [147.19265, 44.796934],
              [147.208507, 44.797547],
              [147.245675, 44.810174],
              [147.263223, 44.864526],
              [147.297954, 44.883682],
              [147.332265, 44.88859],
              [147.383667, 44.911648],
              [147.42034, 44.939997],
              [147.456544, 44.97348],
              [147.47961, 44.986457],
              [147.50205, 44.989696],
              [147.516246, 45.000408],
              [147.51105, 45.026086],
              [147.523815, 45.037317],
              [147.524203, 45.060148],
              [147.53266, 45.07422],
              [147.527453, 45.098616],
              [147.498014, 45.107984],
              [147.513271, 45.112534],
              [147.566462, 45.105613],
              [147.584001, 45.084744],
              [147.624157, 45.08094],
              [147.635683, 45.07536],
              [147.692061, 45.096582],
              [147.696337, 45.120544],
              [147.692974, 45.142115],
              [147.699887, 45.144125],
              [147.705256, 45.167951],
              [147.727471, 45.175251],
              [147.729484, 45.190888],
              [147.767694, 45.19936],
              [147.77323, 45.206249],
              [147.802309, 45.209512],
              [147.82746, 45.217414],
              [147.846594, 45.21009],
              [147.871711, 45.226834],
              [147.868388, 45.235068],
              [147.8829, 45.257533],
              [147.866998, 45.263042],
              [147.871488, 45.295332],
              [147.862214, 45.306947],
              [147.84871, 45.364257],
              [147.87214, 45.39743],
              [147.899014, 45.42698],
              [147.925431, 45.438313],
              [147.963754, 45.420552],
              [147.964168, 45.402078],
              [147.97558, 45.387871],
              [147.970881, 45.379445],
              [147.977652, 45.360404],
              [148.004113, 45.334007],
              [148.008971, 45.290096],
              [148.027179, 45.281981],
              [148.036727, 45.267528],
              [148.073262, 45.259109],
              [148.128792, 45.256778],
              [148.223399, 45.264526],
              [148.294143, 45.276317],
              [148.342805, 45.290507],
              [148.352666, 45.307835],
              [148.394465, 45.322212],
              [148.443194, 45.350852],
              [148.460585, 45.376285],
              [148.478218, 45.382471],
              [148.470819, 45.398985],
              [148.486484, 45.420075],
              [148.510435, 45.430279],
              [148.5218, 45.446417],
              [148.557858, 45.458887],
              [148.578094, 45.491702],
              [148.60616, 45.491054],
              [148.639115, 45.50803],
              [148.654604, 45.538591],
              [148.6999, 45.539592],
              [148.74438, 45.54944],
              [148.751156, 45.556134],
              [148.784726, 45.555358],
              [148.830126, 45.547841],
              [148.867673, 45.535048],
              [148.893356, 45.511794],
              [148.890466, 45.485419],
              [148.875164, 45.474896],
              [148.833881, 45.461201],
              [148.827694, 45.433338],
              [148.855084, 45.41853],
              [148.873473, 45.401811],
              [148.87667, 45.388419],
              [148.853452, 45.3575],
              [148.843139, 45.336887],
              [148.809506, 45.337296],
              [148.777035, 45.322543],
              [148.753046, 45.334714],
              [148.637355, 45.333867],
              [148.60755, 45.321719],
              [148.577956, 45.316745],
              [148.51475, 45.278221],
              [148.492322, 45.254274],
              [148.475063, 45.261039],
              [148.458779, 45.24932],
              [148.438149, 45.252147],
              [148.403863, 45.231537],
              [148.31465, 45.220412],
              [148.28159, 45.202959],
              [148.267039, 45.184906],
              [148.249672, 45.187021],
              [148.231006, 45.1794],
              [148.19374, 45.149394],
              [148.165753, 45.145835],
              [148.15999, 45.138839],
              [148.095462, 45.109973],
              [148.064519, 45.082558],
              [148.05371, 45.065983],
              [148.041752, 45.065194],
              [148.018206, 45.043985],
              [147.98386, 45.003622],
              [147.951533, 44.995045],
              [147.92507, 44.979612],
              [147.902546, 44.960935],
              [147.847314, 44.964694],
              [147.813162, 44.951857],
              [147.787811, 44.947417],
              [147.755816, 44.959564],
              [147.730378, 44.976662],
              [147.728531, 45.002073],
              [147.70037, 45.005969],
              [147.673926, 45.002023],
              [147.639198, 44.984649],
              [147.6143, 44.955025],
              [147.61106, 44.938879],
              [147.633321, 44.926956],
              [147.648119, 44.926104],
              [147.59817, 44.863006],
              [147.597351, 44.853774],
              [147.543056, 44.814149],
              [147.517815, 44.812984],
              [147.498245, 44.800628],
              [147.495061, 44.786531],
              [147.443628, 44.774042],
              [147.384545, 44.745393],
              [147.349799, 44.714716],
              [147.329782, 44.706309],
              [147.303131, 44.666615],
              [147.282776, 44.657815],
              [147.26052, 44.624642],
              [147.25215, 44.600287],
              [147.23327, 44.593399],
              [147.209121, 44.567917],
              [147.187952, 44.570391],
              [147.144494, 44.559422],
              [147.108985, 44.569082],
              [147.089953, 44.563711],
              [147.062661, 44.54795],
              [147.02515, 44.517689],
              [146.987512, 44.458421],
              [146.982858, 44.445335],
              [146.953325, 44.427237],
            ],
          ],
          [
            [
              [154.6875, 49.267986],
              [154.674504, 49.263578],
              [154.62166, 49.282791],
              [154.614898, 49.330376],
              [154.620516, 49.34553],
              [154.612851, 49.359879],
              [154.63331, 49.367637],
              [154.653206, 49.415499],
              [154.662319, 49.41714],
              [154.704127, 49.457991],
              [154.70224, 49.465966],
              [154.760353, 49.546336],
              [154.76596, 49.56961],
              [154.777443, 49.58738],
              [154.811277, 49.601212],
              [154.814231, 49.632955],
              [154.854043, 49.631555],
              [154.859847, 49.618131],
              [154.893423, 49.60887],
              [154.922689, 49.610695],
              [154.901261, 49.592334],
              [154.902758, 49.570005],
              [154.892917, 49.560168],
              [154.88765, 49.530771],
              [154.867832, 49.527631],
              [154.836693, 49.505954],
              [154.828976, 49.483772],
              [154.813774, 49.471705],
              [154.807708, 49.440523],
              [154.816356, 49.403212],
              [154.828087, 49.401746],
              [154.83498, 49.375999],
              [154.82579, 49.371529],
              [154.849131, 49.350135],
              [154.840752, 49.341167],
              [154.842841, 49.323572],
              [154.824873, 49.304372],
              [154.827771, 49.297917],
              [154.79226, 49.280783],
              [154.771985, 49.28366],
              [154.739068, 49.272623],
              [154.71191, 49.257774],
              [154.6875, 49.267986],
            ],
          ],
          [
            [
              [154.468131, 49.759376],
              [154.452464, 49.731631],
              [154.44125, 49.727028],
              [154.407557, 49.737825],
              [154.385837, 49.752955],
              [154.395345, 49.760276],
              [154.38722, 49.773703],
              [154.39876, 49.803302],
              [154.436399, 49.817068],
              [154.462491, 49.815204],
              [154.479702, 49.807936],
              [154.49281, 49.783414],
              [154.473054, 49.771619],
              [154.468131, 49.759376],
            ],
          ],
          [
            [
              [144.67425, 48.64275],
              [144.677, 48.678],
              [144.66925, 48.694],
              [144.65425, 48.70475],
              [144.66175, 48.73975],
              [144.652, 48.78025],
              [144.60975, 48.829],
              [144.5885, 48.84875],
              [144.57925, 48.87125],
              [144.56325, 48.891],
              [144.54375, 48.90375],
              [144.52175, 48.9275],
              [144.45975, 48.96975],
              [144.4535, 48.983],
              [144.4005, 49.0165],
              [144.33925, 49.0335],
              [144.32325, 49.06],
              [144.306, 49.06575],
              [144.276, 49.08575],
              [144.25075, 49.11625],
              [144.23875, 49.1385],
              [144.17825, 49.17375],
              [144.15525, 49.19925],
              [144.1545, 49.20875],
              [144.1375, 49.219],
              [144.0585, 49.25],
              [144.0025, 49.265],
              [143.9285, 49.27875],
              [143.84025, 49.2925],
              [143.65, 49.314],
              [143.618, 49.31675],
              [143.51925, 49.316],
              [143.41975, 49.308],
              [143.34125, 49.29675],
              [143.2925, 49.287],
              [143.2275, 49.2685],
              [143.14825, 49.239],
              [143.092, 49.2115],
              [143.05875, 49.19125],
              [143.02825, 49.168],
              [142.97175, 49.11625],
              [142.96875, 49.09975],
              [142.947, 49.0575],
              [142.9455, 49.019],
              [142.95375, 48.993],
              [142.97975, 48.97225],
              [142.98025, 48.945],
              [142.98675, 48.93325],
              [142.97875, 48.917],
              [142.9795, 48.89825],
              [142.93575, 48.84425],
              [142.91075, 48.81775],
              [142.902, 48.8015],
              [142.861, 48.7525],
              [142.828, 48.7025],
              [142.795, 48.64325],
              [142.791604, 48.626018],
              [142.7515, 48.557],
              [142.717, 48.4685],
              [142.71275, 48.44],
              [142.66975, 48.35375],
              [142.66075, 48.318],
              [142.64625, 48.30775],
              [142.6355, 48.2785],
              [142.62925, 48.27675],
              [142.62375, 48.24875],
              [142.605, 48.20375],
              [142.58975, 48.1915],
              [142.59725, 48.1835],
              [142.57725, 48.1335],
              [142.559, 48.11225],
              [142.55875, 48.09925],
              [142.5455, 48.076],
              [142.5505, 48.0375],
              [142.54875, 48.018],
              [142.5345, 48.01225],
              [142.52775, 47.99],
              [142.53125, 47.96375],
              [142.52525, 47.92825],
              [142.50975, 47.89575],
              [142.51225, 47.87475],
              [142.53075, 47.85875],
              [142.5215, 47.851],
              [142.51925, 47.8235],
              [142.526, 47.77775],
              [142.535, 47.7465],
              [142.555, 47.6945],
              [142.59075, 47.6255],
              [142.636, 47.56075],
              [142.691, 47.49925],
              [142.7255, 47.46675],
              [142.75225, 47.446],
              [142.79775, 47.41975],
              [142.84775, 47.40875],
              [142.87525, 47.38275],
              [142.907, 47.37375],
              [142.9365, 47.35725],
              [142.975, 47.2845],
              [142.9815, 47.2835],
              [143.017, 47.25075],
              [143.0095, 47.245],
              [143.01675, 47.21825],
              [143.02975, 47.20125],
              [143.031, 47.1675],
              [143.043, 47.1465],
              [143.04025, 47.11625],
              [143.02, 47.09675],
              [143.019, 47.07725],
              [143.05075, 47.033],
              [143.05425, 47.016],
              [143.072, 46.983],
              [143.08675, 46.971],
              [143.0865, 46.95525],
              [143.10125, 46.93725],
              [143.091, 46.919],
              [143.1, 46.90675],
              [143.13375, 46.87975],
              [143.15325, 46.8775],
              [143.16925, 46.85275],
              [143.22464, 46.82376],
              [143.30025, 46.79875],
              [143.33375, 46.79375],
              [143.3725, 46.79275],
              [143.4045, 46.797],
              [143.42525, 46.805],
              [143.431, 46.81975],
              [143.42325, 46.83175],
              [143.435, 46.8485],
              [143.45525, 46.84275],
              [143.47625, 46.82625],
              [143.4865, 46.787],
              [143.4855, 46.76775],
              [143.49625, 46.73625],
              [143.51025, 46.71525],
              [143.497, 46.69175],
              [143.49975, 46.65975],
              [143.521, 46.62575],
              [143.50925, 46.5995],
              [143.5315, 46.55825],
              [143.53175, 46.51925],
              [143.54425, 46.494],
              [143.541, 46.47975],
              [143.557, 46.46225],
              [143.557, 46.44925],
              [143.5695, 46.4345],
              [143.56825, 46.41025],
              [143.58825, 46.38925],
              [143.615, 46.384],
              [143.6165, 46.37175],
              [143.592, 46.359],
              [143.578, 46.35825],
              [143.56375, 46.34525],
              [143.56075, 46.31725],
              [143.5685, 46.2975],
              [143.55225, 46.3005],
              [143.53325, 46.29475],
              [143.518, 46.28025],
              [143.506, 46.22325],
              [143.47875, 46.2045],
              [143.45975, 46.18125],
              [143.45675, 46.13925],
              [143.467, 46.10125],
              [143.4595, 46.08875],
              [143.43375, 46.06725],
              [143.42525, 46.0545],
              [143.42225, 46.032],
              [143.41075, 46.025],
              [143.40325, 46.06925],
              [143.413, 46.07575],
              [143.397, 46.099],
              [143.4095, 46.1115],
              [143.413, 46.131],
              [143.40725, 46.154],
              [143.42225, 46.17425],
              [143.422, 46.21325],
              [143.39225, 46.28775],
              [143.37325, 46.32075],
              [143.36, 46.3555],
              [143.34075, 46.38125],
              [143.33025, 46.426],
              [143.34075, 46.44225],
              [143.33575, 46.47125],
              [143.29825, 46.51325],
              [143.25225, 46.553],
              [143.19175, 46.58925],
              [143.15, 46.60625],
              [143.122521, 46.608385],
              [143.10925, 46.59725],
              [143.055, 46.597],
              [143.00925, 46.6105],
              [142.974, 46.607],
              [142.934, 46.62],
              [142.8807, 46.626774],
              [142.8365, 46.61325],
              [142.78825, 46.60475],
              [142.77025, 46.608],
              [142.7565, 46.62775],
              [142.758, 46.645],
              [142.739303, 46.685577],
              [142.726098, 46.736511],
              [142.707672, 46.749138],
              [142.68431, 46.731864],
              [142.608342, 46.726468],
              [142.553831, 46.711804],
              [142.477829, 46.670297],
              [142.454036, 46.65065],
              [142.405543, 46.586474],
              [142.388246, 46.542539],
              [142.35075, 46.456],
              [142.30875, 46.3945],
              [142.28925, 46.35475],
              [142.28625, 46.3385],
              [142.27375, 46.32175],
              [142.249, 46.273],
              [142.24975, 46.252],
              [142.2425, 46.231],
              [142.24775, 46.20425],
              [142.23925, 46.18925],
              [142.2375, 46.16325],
              [142.21675, 46.13075],
              [142.21775, 46.11875],
              [142.2015, 46.1035],
              [142.18825, 46.07825],
              [142.1925, 46.06875],
              [142.17175, 46.016],
              [142.115, 46.00025],
              [142.09675, 45.98475],
              [142.085, 45.95525],
              [142.08875, 45.941],
              [142.077, 45.901],
              [142.02675, 45.93175],
              [142.01525, 45.9545],
              [141.99025, 45.9785],
              [141.987, 45.9915],
              [141.969, 46.01225],
              [141.922651, 46.04352],
              [141.931804, 46.06426],
              [141.933, 46.086],
              [141.92575, 46.122],
              [141.906145, 46.182047],
              [141.93334, 46.190605],
              [141.949523, 46.205696],
              [141.940757, 46.221093],
              [141.90325, 46.2205],
              [141.89175, 46.26975],
              [141.89275, 46.295],
              [141.86975, 46.3605],
              [141.8595, 46.39825],
              [141.84525, 46.43325],
              [141.83575, 46.445],
              [141.81775, 46.50375],
              [141.81, 46.5475],
              [141.8215, 46.558],
              [141.80975, 46.60025],
              [141.83575, 46.61275],
              [141.851, 46.6305],
              [141.85275, 46.676],
              [141.8665, 46.70625],
              [141.89875, 46.75475],
              [141.897, 46.7635],
              [141.91925, 46.8085],
              [141.94125, 46.84425],
              [141.961, 46.8675],
              [141.974, 46.897],
              [141.98825, 46.90375],
              [142.00675, 46.948],
              [142.007946, 46.972557],
              [142.016152, 46.978745],
              [142.02868, 47.019688],
              [142.02999, 47.037542],
              [142.057288, 47.076953],
              [142.059774, 47.108242],
              [142.053295, 47.116178],
              [142.05891, 47.138216],
              [142.054807, 47.169349],
              [142.04637, 47.181781],
              [142.050488, 47.195401],
              [142.031096, 47.233326],
              [142.018393, 47.249525],
              [141.976679, 47.285053],
              [141.96475, 47.30075],
              [141.97575, 47.33125],
              [141.98725, 47.34075],
              [141.965, 47.35775],
              [141.958, 47.40175],
              [141.97025, 47.409],
              [141.961, 47.41875],
              [141.97393, 47.424895],
              [141.97075, 47.43775],
              [141.955, 47.44175],
              [141.967, 47.4595],
              [141.961, 47.49175],
              [141.96175, 47.513],
              [141.95425, 47.5265],
              [141.96325, 47.54925],
              [141.9585, 47.57225],
              [141.96575, 47.60575],
              [141.96175, 47.62],
              [141.97925, 47.632],
              [141.98725, 47.65175],
              [142.0035, 47.6715],
              [142.0025, 47.69525],
              [142.0285, 47.72775],
              [142.04525, 47.75975],
              [142.064, 47.7725],
              [142.06025, 47.7795],
              [142.07775, 47.803],
              [142.086, 47.827],
              [142.08675, 47.8605],
              [142.1745, 47.93275],
              [142.19225, 47.95375],
              [142.20175, 47.984],
              [142.1865, 48.004],
              [142.17925, 48.03975],
              [142.18225, 48.06525],
              [142.172, 48.09475],
              [142.17575, 48.11225],
              [142.16625, 48.1365],
              [142.173, 48.14725],
              [142.165, 48.18275],
              [142.16775, 48.21125],
              [142.15075, 48.2435],
              [142.1545, 48.26225],
              [142.15175, 48.3],
              [142.14325, 48.325],
              [142.11425, 48.37075],
              [142.06875, 48.434],
              [141.96375, 48.54725],
              [141.92075, 48.6035],
              [141.872, 48.676],
              [141.87225, 48.6935],
              [141.86325, 48.71175],
              [141.86, 48.73675],
              [141.84775, 48.7665],
              [141.8575, 48.793],
              [141.88375, 48.81925],
              [141.917, 48.843],
              [141.94925, 48.852],
              [141.97675, 48.87325],
              [141.972, 48.89425],
              [141.95825, 48.913],
              [142.0045, 48.94625],
              [142.00625, 49.00175],
              [142.0145, 49.03225],
              [142.02725, 49.04275],
              [142.0285, 49.07],
              [142.04, 49.088],
              [142.02975, 49.117],
              [142.0305, 49.14625],
              [142.06175, 49.1655],
              [142.0665, 49.193],
              [142.08325, 49.206],
              [142.092, 49.22875],
              [142.07125, 49.25975],
              [142.075, 49.27175],
              [142.07075, 49.3025],
              [142.078, 49.33275],
              [142.06925, 49.33925],
              [142.095, 49.404],
              [142.099, 49.42275],
              [142.1295, 49.45425],
              [142.13, 49.47875],
              [142.14375, 49.51675],
              [142.14175, 49.5495],
              [142.1475, 49.5825],
              [142.1445, 49.618],
              [142.15475, 49.64125],
              [142.15825, 49.7195],
              [142.15325, 49.72575],
              [142.157, 49.771],
              [142.167, 49.8045],
              [142.15125, 49.83175],
              [142.152, 49.846],
              [142.1375, 49.89325],
              [142.14625, 49.90625],
              [142.138, 49.9475],
              [142.15725, 49.98225],
              [142.1575, 50.00575],
              [142.14, 50.0185],
              [142.172, 50.04525],
              [142.16825, 50.08325],
              [142.172, 50.09375],
              [142.17125, 50.1375],
              [142.16575, 50.17975],
              [142.153, 50.2165],
              [142.1615, 50.235],
              [142.155, 50.2625],
              [142.14525, 50.26825],
              [142.12925, 50.299],
              [142.142, 50.29775],
              [142.157, 50.31675],
              [142.1505, 50.3425],
              [142.14975, 50.37325],
              [142.1245, 50.40375],
              [142.0985, 50.428],
              [142.07675, 50.47175],
              [142.0585, 50.4895],
              [142.06325, 50.4965],
              [142.04325, 50.53625],
              [142.05025, 50.56275],
              [142.0495, 50.584],
              [142.057, 50.63675],
              [142.0655, 50.65475],
              [142.076, 50.701],
              [142.0905, 50.716],
              [142.09275, 50.773],
              [142.06, 50.77625],
              [142.03475, 50.79875],
              [142.07875, 50.78575],
              [142.095, 50.78975],
              [142.08675, 50.821],
              [142.105, 50.8365],
              [142.117, 50.87375],
              [142.116, 50.8925],
              [142.138, 50.89775],
              [142.18125, 50.93475],
              [142.22675, 50.9905],
              [142.24575, 51.0285],
              [142.25575, 51.06625],
              [142.25475, 51.11175],
              [142.24925, 51.155],
              [142.23875, 51.1755],
              [142.21925, 51.19625],
              [142.1955, 51.20975],
              [142.171, 51.255],
              [142.16475, 51.27375],
              [142.1795, 51.29225],
              [142.16925, 51.318],
              [142.138, 51.339],
              [142.08275, 51.39675],
              [142.08625, 51.4095],
              [142.0685, 51.42925],
              [142.09075, 51.4415],
              [142.08675, 51.45975],
              [142.074, 51.47725],
              [142.0515, 51.49425],
              [141.98425, 51.53175],
              [141.92375, 51.5685],
              [141.88775, 51.59675],
              [141.8785, 51.5995],
              [141.803, 51.67875],
              [141.75525, 51.705],
              [141.70425, 51.723],
              [141.67525, 51.73875],
              [141.67025, 51.7485],
              [141.69525, 51.735],
              [141.728, 51.72775],
              [141.75825, 51.7135],
              [141.77775, 51.7165],
              [141.81225, 51.74725],
              [141.827, 51.7695],
              [141.825, 51.786],
              [141.79125, 51.80675],
              [141.787268, 51.817952],
              [141.76025, 51.84175],
              [141.73225, 51.85475],
              [141.6865, 51.86],
              [141.67175, 51.86875],
              [141.63775, 51.8765],
              [141.62625, 51.88775],
              [141.64675, 51.89625],
              [141.6605, 51.93525],
              [141.66525, 51.9895],
              [141.648, 52.03425],
              [141.64875, 52.05275],
              [141.63, 52.07325],
              [141.63275, 52.10125],
              [141.6775, 52.1285],
              [141.692, 52.15225],
              [141.69175, 52.17],
              [141.68125, 52.18775],
              [141.6425, 52.226],
              [141.66275, 52.24975],
              [141.668, 52.2665],
              [141.65925, 52.289],
              [141.6345, 52.31],
              [141.62925, 52.3265],
              [141.63925, 52.3325],
              [141.65225, 52.39175],
              [141.6745, 52.408],
              [141.6915, 52.405],
              [141.767, 52.46275],
              [141.8245, 52.54925],
              [141.8345, 52.582],
              [141.82675, 52.59275],
              [141.8035, 52.5895],
              [141.83175, 52.6315],
              [141.835, 52.6525],
              [141.811, 52.655],
              [141.827, 52.67275],
              [141.842, 52.711],
              [141.81975, 52.722],
              [141.84875, 52.76425],
              [141.855, 52.7915],
              [141.84775, 52.8215],
              [141.857, 52.88375],
              [141.8745, 52.89925],
              [141.8895, 52.923],
              [141.89025, 52.96425],
              [141.91575, 53.004],
              [141.921, 53.03525],
              [141.91025, 53.0435],
              [141.92, 53.05775],
              [141.88875, 53.09],
              [141.8725, 53.1145],
              [141.85375, 53.1305],
              [141.82925, 53.16225],
              [141.83225, 53.1905],
              [141.825, 53.208],
              [141.8035, 53.23275],
              [141.79, 53.263],
              [141.789, 53.2855],
              [141.802, 53.32725],
              [141.7905, 53.34025],
              [141.7795, 53.338],
              [141.75725, 53.36975],
              [141.80725, 53.39725],
              [141.80625, 53.405],
              [141.778, 53.40525],
              [141.715, 53.3885],
              [141.7175, 53.375],
              [141.6925, 53.38975],
              [141.702, 53.40025],
              [141.77375, 53.4195],
              [141.84075, 53.421],
              [141.8595, 53.431],
              [142.1585, 53.5235],
              [142.2045, 53.534],
              [142.251307, 53.539026],
              [142.270075, 53.531939],
              [142.212888, 53.527477],
              [142.2455, 53.4985],
              [142.24175, 53.47725],
              [142.23175, 53.47075],
              [142.241, 53.44325],
              [142.22675, 53.42075],
              [142.245, 53.38575],
              [142.26975, 53.36775],
              [142.32, 53.36625],
              [142.3395, 53.3845],
              [142.35725, 53.3875],
              [142.375, 53.381],
              [142.4155, 53.37575],
              [142.4385, 53.383],
              [142.4915, 53.41925],
              [142.56625, 53.50875],
              [142.605, 53.52975],
              [142.64275, 53.51475],
              [142.6635, 53.519],
              [142.664, 53.545],
              [142.63925, 53.54175],
              [142.5845, 53.5705],
              [142.56225, 53.597],
              [142.54275, 53.59825],
              [142.52775, 53.57675],
              [142.5365, 53.56275],
              [142.51425, 53.5465],
              [142.49925, 53.5615],
              [142.50175, 53.58225],
              [142.4945, 53.6005],
              [142.492, 53.63175],
              [142.476, 53.66025],
              [142.5395, 53.6775],
              [142.57625, 53.6705],
              [142.60175, 53.6795],
              [142.57975, 53.70625],
              [142.585, 53.71775],
              [142.64925, 53.7895],
              [142.67875, 53.83425],
              [142.6935, 53.86925],
              [142.69925, 53.91175],
              [142.69325, 53.94625],
              [142.66425, 53.963],
              [142.57525, 54.031],
              [142.5685, 54.041],
              [142.543, 54.05325],
              [142.50175, 54.094],
              [142.441, 54.12775],
              [142.41275, 54.15775],
              [142.40075, 54.20225],
              [142.3715, 54.23825],
              [142.3555, 54.24375],
              [142.33125, 54.26725],
              [142.30325, 54.27775],
              [142.258208, 54.308126],
              [142.272826, 54.313422],
              [142.31689, 54.293264],
              [142.37387, 54.279621],
              [142.428956, 54.280402],
              [142.525015, 54.299631],
              [142.587055, 54.319047],
              [142.64872, 54.345777],
              [142.674938, 54.367852],
              [142.673058, 54.384559],
              [142.661482, 54.392429],
              [142.66289, 54.411497],
              [142.686261, 54.424368],
              [142.72589, 54.420721],
              [142.73173, 54.39103],
              [142.767209, 54.360852],
              [142.78109, 54.335536],
              [142.7875, 54.3085],
              [142.81725, 54.27625],
              [142.88075, 54.22525],
              [142.91775, 54.18525],
              [142.97225, 54.141],
              [142.9875, 54.11475],
              [142.99675, 54.08025],
              [142.977662, 54.035142],
              [142.910471, 53.994275],
              [142.89219, 53.956312],
              [142.888222, 53.902869],
              [142.89843, 53.846649],
              [142.923955, 53.781554],
              [143.0115, 53.63675],
              [143.0515, 53.57675],
              [143.10075, 53.49525],
              [143.14675, 53.426],
              [143.20825, 53.31275],
              [143.24675, 53.21875],
              [143.26275, 53.165],
              [143.2775, 53.1005],
              [143.292, 53.01925],
              [143.31875, 52.908],
              [143.3195, 52.886],
              [143.329, 52.8465],
              [143.33675, 52.832],
              [143.32325, 52.8065],
              [143.32375, 52.73675],
              [143.3165, 52.58225],
              [143.3015, 52.51375],
              [143.28325, 52.45275],
              [143.248, 52.39375],
              [143.21725, 52.36375],
              [143.17825, 52.317],
              [143.1595, 52.28375],
              [143.143, 52.222],
              [143.14025, 52.13175],
              [143.1545, 52.052],
              [143.16325, 52.01925],
              [143.179, 51.9865],
              [143.151, 51.975],
              [143.129, 51.9535],
              [143.10775, 51.941],
              [143.1105, 51.92575],
              [143.1525, 51.9105],
              [143.148, 51.8785],
              [143.176, 51.8705],
              [143.17825, 51.84925],
              [143.1965, 51.8475],
              [143.20275, 51.86325],
              [143.22775, 51.8665],
              [143.22275, 51.88525],
              [143.20325, 51.9245],
              [143.1905, 51.9355],
              [143.1745, 51.97025],
              [143.1875, 51.96125],
              [143.2505, 51.83925],
              [143.277, 51.79925],
              [143.309, 51.7435],
              [143.31875, 51.7375],
              [143.341, 51.70625],
              [143.43675, 51.49525],
              [143.473, 51.36525],
              [143.48125, 51.32225],
              [143.4895, 51.30075],
              [143.53425, 51.2045],
              [143.62025, 50.94875],
              [143.63575, 50.886],
              [143.656, 50.82125],
              [143.6705, 50.80325],
              [143.66525, 50.79225],
              [143.667, 50.765],
              [143.6775, 50.714],
              [143.6945, 50.675],
              [143.69775, 50.6455],
              [143.68975, 50.64125],
              [143.6995, 50.60425],
              [143.699, 50.5805],
              [143.71675, 50.5265],
              [143.7235, 50.4945],
              [143.7565, 50.402],
              [143.78525, 50.33325],
              [143.8335, 50.238],
              [143.88175, 50.1635],
              [143.94975, 50.0785],
              [143.9805, 50.0365],
              [143.99775, 50.01875],
              [143.9875, 49.98575],
              [144.00225, 49.94575],
              [144.0305, 49.892],
              [144.0715, 49.8195],
              [144.0935, 49.7875],
              [144.121, 49.75475],
              [144.12525, 49.72525],
              [144.11425, 49.69975],
              [144.11625, 49.67675],
              [144.1335, 49.617],
              [144.142, 49.60225],
              [144.18975, 49.548],
              [144.24, 49.50025],
              [144.21475, 49.48075],
              [144.22425, 49.4425],
              [144.22225, 49.41975],
              [144.247, 49.37675],
              [144.24075, 49.3735],
              [144.25475, 49.3305],
              [144.27, 49.30425],
              [144.27275, 49.27175],
              [144.281, 49.24475],
              [144.31325, 49.20325],
              [144.3405, 49.15925],
              [144.3595, 49.11175],
              [144.3955, 49.0595],
              [144.387, 49.04425],
              [144.42525, 49.0115],
              [144.59325, 48.92275],
              [144.61575, 48.90725],
              [144.66575, 48.8815],
              [144.6905, 48.857],
              [144.6795, 48.85],
              [144.6725, 48.82775],
              [144.689, 48.78975],
              [144.70475, 48.778],
              [144.67675, 48.76275],
              [144.67325, 48.72525],
              [144.68125, 48.706],
              [144.708, 48.6725],
              [144.72975, 48.6535],
              [144.74675, 48.64775],
              [144.74025, 48.641],
              [144.71525, 48.65425],
              [144.67425, 48.64275],
            ],
          ],
          [
            [
              [154.494572, 49.084273],
              [154.476951, 49.095524],
              [154.470558, 49.130218],
              [154.457536, 49.1391],
              [154.45178, 49.156093],
              [154.45967, 49.165224],
              [154.480101, 49.168553],
              [154.486109, 49.160271],
              [154.517136, 49.164996],
              [154.539995, 49.158283],
              [154.597724, 49.130066],
              [154.604001, 49.106648],
              [154.591039, 49.092085],
              [154.55391, 49.084515],
              [154.518127, 49.069479],
              [154.494572, 49.084273],
            ],
          ],
          [
            [
              [154.04324, 48.733117],
              [154.018576, 48.720774],
              [153.999169, 48.719149],
              [153.974179, 48.739362],
              [153.994465, 48.77534],
              [154.033125, 48.787819],
              [154.045401, 48.781042],
              [154.064496, 48.785098],
              [154.08834, 48.811155],
              [154.094008, 48.830844],
              [154.088196, 48.840385],
              [154.100455, 48.859241],
              [154.103615, 48.879209],
              [154.09544, 48.887748],
              [154.142366, 48.899471],
              [154.1763, 48.904001],
              [154.19648, 48.901871],
              [154.22375, 48.887996],
              [154.227235, 48.879498],
              [154.211187, 48.866848],
              [154.202638, 48.842581],
              [154.168406, 48.815371],
              [154.125722, 48.799002],
              [154.10711, 48.799567],
              [154.072105, 48.777977],
              [154.064134, 48.749591],
              [154.04324, 48.733117],
            ],
          ],
          [
            [
              [154.009946, 48.937754],
              [154.012357, 48.931836],
              [153.972466, 48.921539],
              [153.916491, 48.924177],
              [153.916313, 48.935716],
              [153.901466, 48.943268],
              [153.906332, 48.964766],
              [153.928633, 48.970485],
              [153.960911, 48.96817],
              [153.98523, 48.957325],
              [154.00905, 48.960837],
              [154.009946, 48.937754],
            ],
          ],
          [
            [
              [153.503757, 48.988506],
              [153.50105, 48.970955],
              [153.48465, 48.966411],
              [153.467419, 48.976866],
              [153.47437, 48.991194],
              [153.503757, 48.988506],
            ],
          ],
          [
            [
              [152.78825, 47.519421],
              [152.818519, 47.524088],
              [152.836251, 47.543861],
              [152.85008, 47.541059],
              [152.827251, 47.52936],
              [152.828268, 47.512831],
              [152.819735, 47.500754],
              [152.801188, 47.501749],
              [152.78825, 47.519421],
            ],
          ],
          [
            [
              [153.032848, 47.710934],
              [153.004042, 47.695532],
              [152.974936, 47.694041],
              [152.962919, 47.713057],
              [152.975098, 47.721797],
              [152.9683, 47.75143],
              [153.0109, 47.797848],
              [153.0444, 47.808097],
              [153.049712, 47.790401],
              [153.062065, 47.779369],
              [153.061467, 47.755008],
              [153.049269, 47.723986],
              [153.032848, 47.710934],
            ],
          ],
          [
            [
              [153.2608, 48.300515],
              [153.269974, 48.291136],
              [153.247263, 48.282469],
              [153.23347, 48.29019],
              [153.240051, 48.300946],
              [153.2608, 48.300515],
            ],
          ],
          [
            [
              [153.281483, 48.077773],
              [153.299826, 48.073624],
              [153.293281, 48.066462],
              [153.281483, 48.077773],
            ],
          ],
          [
            [
              [153.28125, 48.042957],
              [153.230638, 48.038352],
              [153.171308, 48.07862],
              [153.161022, 48.100057],
              [153.16489, 48.11236],
              [153.187903, 48.12335],
              [153.214661, 48.111252],
              [153.225772, 48.112769],
              [153.249036, 48.093617],
              [153.273103, 48.084973],
              [153.265036, 48.078966],
              [153.268732, 48.053957],
              [153.28125, 48.042957],
            ],
          ],
          [
            [
              [154.98402, 50.185641],
              [154.963265, 50.186056],
              [154.953057, 50.20007],
              [154.98195, 50.213039],
              [155.013368, 50.200194],
              [154.98402, 50.185641],
            ],
          ],
          [
            [
              [156.410086, 50.643935],
              [156.39927, 50.631646],
              [156.36557, 50.636143],
              [156.333453, 50.626696],
              [156.296247, 50.635964],
              [156.273718, 50.635183],
              [156.256012, 50.651943],
              [156.22881, 50.665398],
              [156.193071, 50.676206],
              [156.182927, 50.683909],
              [156.17341, 50.707731],
              [156.190789, 50.718682],
              [156.185705, 50.727444],
              [156.200109, 50.746391],
              [156.201804, 50.772297],
              [156.254901, 50.773012],
              [156.316604, 50.806165],
              [156.36087, 50.835421],
              [156.385251, 50.846],
              [156.44995, 50.856686],
              [156.482115, 50.868536],
              [156.486749, 50.850179],
              [156.5076, 50.838835],
              [156.498032, 50.836067],
              [156.495449, 50.816273],
              [156.504708, 50.771611],
              [156.513569, 50.764623],
              [156.48598, 50.740191],
              [156.483628, 50.723734],
              [156.465649, 50.716423],
              [156.435691, 50.67347],
              [156.404905, 50.653818],
              [156.410086, 50.643935],
            ],
          ],
          [
            [
              [155.661599, 50.811512],
              [155.625357, 50.803746],
              [155.527693, 50.808083],
              [155.497104, 50.830019],
              [155.48373, 50.833508],
              [155.476627, 50.856089],
              [155.457438, 50.880079],
              [155.48917, 50.912858],
              [155.530618, 50.921964],
              [155.570656, 50.919935],
              [155.613336, 50.907195],
              [155.63006, 50.908998],
              [155.6587, 50.899249],
              [155.668271, 50.861091],
              [155.661999, 50.857646],
              [155.672992, 50.819711],
              [155.661599, 50.811512],
            ],
          ],
          [
            [
              [155.41502, 50.015386],
              [155.408142, 50.005147],
              [155.3878, 50.004029],
              [155.39644, 50.021797],
              [155.390814, 50.03268],
              [155.34731, 50.054719],
              [155.299362, 50.059524],
              [155.23978, 50.054281],
              [155.225955, 50.04801],
              [155.211771, 50.072892],
              [155.231627, 50.093021],
              [155.261264, 50.159613],
              [155.25412, 50.185901],
              [155.26154, 50.207803],
              [155.253955, 50.220512],
              [155.218424, 50.238372],
              [155.1997, 50.274361],
              [155.20887, 50.291107],
              [155.237833, 50.302857],
              [155.275889, 50.303528],
              [155.315447, 50.293903],
              [155.342872, 50.281944],
              [155.364417, 50.288815],
              [155.387853, 50.309357],
              [155.39433, 50.32281],
              [155.388168, 50.3396],
              [155.411147, 50.350406],
              [155.450365, 50.357276],
              [155.465723, 50.352081],
              [155.518023, 50.363649],
              [155.54722, 50.3625],
              [155.583869, 50.387968],
              [155.616074, 50.368405],
              [155.649464, 50.377775],
              [155.686115, 50.402058],
              [155.721481, 50.431231],
              [155.756087, 50.453488],
              [155.784247, 50.505629],
              [155.777251, 50.516564],
              [155.818698, 50.540691],
              [155.835005, 50.56106],
              [155.830891, 50.56962],
              [155.849256, 50.607114],
              [155.858441, 50.652266],
              [155.897843, 50.680473],
              [155.901795, 50.689448],
              [155.960128, 50.731819],
              [156.015558, 50.763504],
              [156.050389, 50.766914],
              [156.072786, 50.755559],
              [156.148689, 50.747269],
              [156.152961, 50.725608],
              [156.147087, 50.702649],
              [156.1297, 50.686583],
              [156.14661, 50.673924],
              [156.124607, 50.651079],
              [156.136261, 50.62855],
              [156.151848, 50.620729],
              [156.154911, 50.606704],
              [156.17052, 50.6017],
              [156.153177, 50.575385],
              [156.14902, 50.534264],
              [156.169304, 50.507841],
              [156.150838, 50.500725],
              [156.13724, 50.48629],
              [156.10505, 50.493569],
              [156.06254, 50.479172],
              [156.042454, 50.466229],
              [156.022816, 50.436757],
              [156.026434, 50.40147],
              [156.013134, 50.386515],
              [155.986504, 50.375757],
              [155.950409, 50.323865],
              [155.927647, 50.311878],
              [155.922542, 50.287394],
              [155.90172, 50.267406],
              [155.88825, 50.246462],
              [155.8572, 50.245307],
              [155.831974, 50.237978],
              [155.807792, 50.221004],
              [155.79407, 50.191338],
              [155.755137, 50.180988],
              [155.735351, 50.19107],
              [155.70014, 50.189843],
              [155.659526, 50.177578],
              [155.616009, 50.184848],
              [155.59936, 50.17971],
              [155.581584, 50.164725],
              [155.550999, 50.148737],
              [155.522469, 50.144873],
              [155.482628, 50.118942],
              [155.438007, 50.068615],
              [155.423921, 50.030199],
              [155.42661, 50.016426],
              [155.41502, 50.015386],
            ],
          ],
          [
            [
              [150.779088, 46.468344],
              [150.804961, 46.485003],
              [150.832384, 46.477062],
              [150.826742, 46.464899],
              [150.843672, 46.445084],
              [150.800878, 46.448116],
              [150.780195, 46.459388],
              [150.779088, 46.468344],
            ],
          ],
          [
            [
              [150.85276, 46.497068],
              [150.830296, 46.51088],
              [150.85416, 46.536979],
              [150.87209, 46.54424],
              [150.908132, 46.544648],
              [150.8957, 46.53929],
              [150.903412, 46.512757],
              [150.875676, 46.499933],
              [150.85276, 46.497068],
            ],
          ],
          [
            [
              [150.74906, 46.727841],
              [150.740972, 46.702673],
              [150.709026, 46.721916],
              [150.72117, 46.739087],
              [150.74906, 46.727841],
            ],
          ],
          [
            [
              [151.814252, 46.773976],
              [151.799438, 46.768692],
              [151.758761, 46.776338],
              [151.735225, 46.793007],
              [151.725269, 46.811363],
              [151.72655, 46.828305],
              [151.705576, 46.838072],
              [151.725703, 46.856251],
              [151.788114, 46.857442],
              [151.79646, 46.852016],
              [151.817287, 46.856978],
              [151.844742, 46.849436],
              [151.87733, 46.864251],
              [151.874119, 46.874735],
              [151.91405, 46.916156],
              [151.915616, 46.931303],
              [151.931447, 46.944788],
              [151.972063, 46.955419],
              [152.004188, 46.980324],
              [152.041505, 46.989106],
              [152.060236, 47.000289],
              [152.066363, 47.025217],
              [152.11454, 47.060478],
              [152.122767, 47.084906],
              [152.138557, 47.095284],
              [152.179611, 47.148673],
              [152.20603, 47.166555],
              [152.22783, 47.167601],
              [152.285214, 47.150919],
              [152.28661, 47.130668],
              [152.276487, 47.115217],
              [152.208851, 47.078096],
              [152.19684, 47.07814],
              [152.164921, 47.054445],
              [152.15743, 47.041824],
              [152.162776, 47.023654],
              [152.152702, 46.996651],
              [152.141253, 46.985922],
              [152.087513, 46.972555],
              [152.080634, 46.958522],
              [152.065423, 46.950064],
              [152.024412, 46.898556],
              [152.006551, 46.893145],
              [151.941723, 46.860376],
              [151.90747, 46.853129],
              [151.875948, 46.831922],
              [151.872245, 46.82137],
              [151.8503, 46.799351],
              [151.814252, 46.773976],
            ],
          ],
          [
            [
              [152.512811, 47.298164],
              [152.491029, 47.291836],
              [152.476605, 47.2998],
              [152.429763, 47.300535],
              [152.421399, 47.31505],
              [152.4162, 47.360332],
              [152.446762, 47.377877],
              [152.512508, 47.37215],
              [152.537599, 47.351994],
              [152.543613, 47.332784],
              [152.533819, 47.309921],
              [152.512811, 47.298164],
            ],
          ],
          [
            [
              [149.5274, 45.573551],
              [149.445771, 45.579775],
              [149.421044, 45.573284],
              [149.396952, 45.577967],
              [149.408477, 45.591107],
              [149.43049, 45.593206],
              [149.43225, 45.600095],
              [149.460507, 45.619499],
              [149.456162, 45.628828],
              [149.4781, 45.657855],
              [149.469496, 45.665994],
              [149.51255, 45.679676],
              [149.539575, 45.702679],
              [149.562136, 45.731676],
              [149.588527, 45.779564],
              [149.613555, 45.789512],
              [149.627118, 45.812801],
              [149.638129, 45.812931],
              [149.641903, 45.830673],
              [149.658386, 45.83773],
              [149.667834, 45.851105],
              [149.70974, 45.852298],
              [149.73393, 45.844026],
              [149.77705, 45.854914],
              [149.797357, 45.871862],
              [149.824924, 45.886832],
              [149.845258, 45.90377],
              [149.855499, 45.935067],
              [149.871647, 45.945179],
              [149.89261, 45.980563],
              [149.939082, 46.010416],
              [149.963627, 46.01504],
              [149.981648, 46.02496],
              [149.99184, 46.050089],
              [150.051622, 46.09021],
              [150.068709, 46.094755],
              [150.087028, 46.089964],
              [150.108845, 46.095778],
              [150.13502, 46.088742],
              [150.147484, 46.097262],
              [150.186735, 46.108496],
              [150.189353, 46.140369],
              [150.174509, 46.15642],
              [150.208719, 46.177013],
              [150.217778, 46.187751],
              [150.242448, 46.189246],
              [150.26562, 46.198653],
              [150.27579, 46.194663],
              [150.298525, 46.205592],
              [150.311022, 46.222047],
              [150.321788, 46.21345],
              [150.336136, 46.222379],
              [150.363232, 46.227625],
              [150.418877, 46.223253],
              [150.444367, 46.211513],
              [150.453188, 46.200551],
              [150.506137, 46.214161],
              [150.514841, 46.209604],
              [150.5706, 46.227092],
              [150.527425, 46.200899],
              [150.501553, 46.193027],
              [150.464491, 46.171689],
              [150.456825, 46.161771],
              [150.412917, 46.137699],
              [150.383552, 46.126641],
              [150.329524, 46.091916],
              [150.30489, 46.071586],
              [150.299873, 46.057359],
              [150.289392, 46.060702],
              [150.258045, 46.038919],
              [150.221496, 46.01854],
              [150.18819, 45.985531],
              [150.174656, 45.958648],
              [150.179674, 45.943124],
              [150.192013, 45.939067],
              [150.13941, 45.918137],
              [150.142422, 45.902437],
              [150.121907, 45.887043],
              [150.104017, 45.884923],
              [150.055973, 45.859196],
              [150.038098, 45.839751],
              [150.026668, 45.840736],
              [150.018252, 45.826536],
              [150.002398, 45.821119],
              [149.967879, 45.819074],
              [149.951344, 45.82553],
              [149.928908, 45.817515],
              [149.906254, 45.801676],
              [149.912023, 45.789216],
              [149.88032, 45.785915],
              [149.864936, 45.77912],
              [149.859933, 45.767316],
              [149.83661, 45.755457],
              [149.827174, 45.742864],
              [149.798212, 45.731521],
              [149.76927, 45.709139],
              [149.757595, 45.691465],
              [149.736286, 45.682357],
              [149.66221, 45.621387],
              [149.626351, 45.609909],
              [149.574234, 45.602086],
              [149.571143, 45.587302],
              [149.539429, 45.588813],
              [149.5274, 45.573551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5481",
      properties: {
        name: "Свердловская область",
        density: 0,
        path: "/world/Russia/Свердловская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [59.348463, 61.66377],
            [59.360094, 61.672462],
            [59.345208, 61.689847],
            [59.371342, 61.700983],
            [59.369296, 61.713822],
            [59.399747, 61.727572],
            [59.418551, 61.753706],
            [59.391053, 61.765182],
            [59.359748, 61.769613],
            [59.342248, 61.785179],
            [59.327818, 61.783702],
            [59.315659, 61.802337],
            [59.33486, 61.830175],
            [59.327587, 61.842219],
            [59.364515, 61.873581],
            [59.403716, 61.883808],
            [59.402123, 61.908124],
            [59.486699, 61.946069],
            [59.507153, 61.936895],
            [59.538704, 61.935916],
            [59.584842, 61.944895],
            [59.629509, 61.91755],
            [59.663648, 61.918803],
            [59.718022, 61.930487],
            [59.822208, 61.906972],
            [59.884984, 61.904986],
            [59.926947, 61.899152],
            [59.972501, 61.882798],
            [59.97986, 61.8707],
            [59.971141, 61.844326],
            [59.945924, 61.834415],
            [59.940581, 61.794511],
            [59.958736, 61.775966],
            [59.944242, 61.756864],
            [59.950974, 61.745443],
            [59.993321, 61.718358],
            [60.199939, 61.724307],
            [60.51461, 61.7357],
            [60.710683, 61.717059],
            [60.838598, 61.698466],
            [60.997419, 61.694801],
            [61.121502, 61.676645],
            [61.194279, 61.663824],
            [61.229475, 61.654498],
            [61.461353, 61.582788],
            [61.676383, 61.510342],
            [61.88651, 61.436579],
            [61.890521, 61.406809],
            [62.200317, 61.333722],
            [62.249531, 61.318353],
            [62.483555, 61.260973],
            [62.551285, 61.221793],
            [62.646304, 61.170899],
            [62.709984, 61.107263],
            [62.825031, 61.001313],
            [62.760156, 60.853321],
            [62.814434, 60.756567],
            [62.867383, 60.653825],
            [62.889166, 60.638306],
            [63.041349, 60.483632],
            [63.158033, 60.362424],
            [63.22573, 60.294206],
            [63.18084, 60.224474],
            [63.247729, 60.20471],
            [63.444274, 60.150103],
            [63.487508, 60.148936],
            [63.535925, 60.130734],
            [63.60257, 60.138054],
            [63.618227, 60.115541],
            [63.605936, 60.101704],
            [63.677619, 59.998091],
            [63.72959, 59.913307],
            [63.795033, 59.816742],
            [63.824946, 59.744732],
            [63.838449, 59.65249],
            [63.848733, 59.566734],
            [63.874313, 59.461301],
            [63.999311, 59.390422],
            [64.020466, 59.381857],
            [64.486645, 59.376781],
            [64.627883, 59.360475],
            [64.90735, 59.333998],
            [65.045995, 59.317525],
            [65.074329, 59.310298],
            [65.079853, 59.286357],
            [65.093449, 59.264396],
            [65.095714, 59.242003],
            [65.121705, 59.233108],
            [65.122358, 59.216259],
            [65.136479, 59.209666],
            [65.217514, 59.215583],
            [65.238066, 59.204537],
            [65.302823, 59.081759],
            [65.329935, 59.033705],
            [65.371533, 58.900124],
            [65.619596, 58.69031],
            [65.655676, 58.658463],
            [65.95016, 58.614779],
            [65.983403, 58.393562],
            [65.993941, 58.33466],
            [66.000058, 58.248693],
            [66.154875, 58.048428],
            [66.184857, 58.01097],
            [66.142993, 58.007877],
            [66.104302, 57.996146],
            [66.073006, 57.993859],
            [66.076838, 58.00148],
            [66.041549, 57.992909],
            [66.036734, 57.984586],
            [66.006182, 57.977917],
            [65.980787, 57.965104],
            [65.984069, 57.944862],
            [65.874673, 57.942074],
            [65.824986, 57.932635],
            [65.808367, 57.908832],
            [65.794769, 57.874798],
            [65.759888, 57.879992],
            [65.748751, 57.894306],
            [65.702491, 57.888426],
            [65.668128, 57.898469],
            [65.655795, 57.875543],
            [65.635426, 57.872906],
            [65.634185, 57.859273],
            [65.684633, 57.838084],
            [65.655565, 57.824863],
            [65.627039, 57.796776],
            [65.597687, 57.796947],
            [65.606836, 57.78677],
            [65.53412, 57.762144],
            [65.519744, 57.741236],
            [65.503956, 57.741941],
            [65.498663, 57.762677],
            [65.462756, 57.762534],
            [65.45231, 57.78824],
            [65.426235, 57.808643],
            [65.417822, 57.837011],
            [65.406755, 57.825821],
            [65.243327, 57.776706],
            [65.149519, 57.747197],
            [64.946655, 57.685385],
            [64.833088, 57.64825],
            [64.857362, 57.64008],
            [64.863317, 57.621185],
            [64.899133, 57.623583],
            [64.9057, 57.592512],
            [64.966058, 57.60507],
            [64.990901, 57.56174],
            [64.960745, 57.562163],
            [64.937297, 57.555393],
            [64.928946, 57.541842],
            [64.933775, 57.526673],
            [64.926832, 57.500057],
            [64.905756, 57.482204],
            [64.898944, 57.45407],
            [64.849863, 57.451283],
            [64.869937, 57.38826],
            [64.859285, 57.363699],
            [64.897929, 57.332372],
            [64.843277, 57.339535],
            [64.827192, 57.29312],
            [64.860162, 57.290242],
            [64.8834, 57.280614],
            [64.908676, 57.293456],
            [64.931415, 57.291914],
            [64.924382, 57.263209],
            [64.95345, 57.246238],
            [65.005086, 57.233812],
            [64.962519, 57.156659],
            [64.981954, 57.118295],
            [64.995855, 57.107912],
            [64.980703, 57.103267],
            [64.982491, 57.067818],
            [64.988948, 57.055128],
            [65.012879, 57.043072],
            [65.000523, 57.020817],
            [65.034586, 57.005936],
            [65.009481, 56.966165],
            [65.043994, 56.968472],
            [65.038627, 56.945173],
            [65.074041, 56.946594],
            [65.056195, 56.903916],
            [65.111006, 56.886083],
            [65.119392, 56.903335],
            [65.21865, 56.920309],
            [65.253148, 56.920506],
            [65.217211, 56.844796],
            [65.18937, 56.84848],
            [65.169683, 56.764035],
            [65.053912, 56.771696],
            [65.012052, 56.769871],
            [64.927055, 56.7724],
            [64.912255, 56.78772],
            [64.865125, 56.793932],
            [64.883325, 56.807528],
            [64.886807, 56.824077],
            [64.830508, 56.842297],
            [64.813299, 56.822364],
            [64.789286, 56.824958],
            [64.764311, 56.812473],
            [64.73172, 56.815342],
            [64.680239, 56.813917],
            [64.633952, 56.803772],
            [64.606224, 56.793384],
            [64.594202, 56.799497],
            [64.549117, 56.803542],
            [64.545599, 56.788758],
            [64.517225, 56.761477],
            [64.493862, 56.745584],
            [64.327899, 56.756542],
            [64.292639, 56.737618],
            [64.291601, 56.728016],
            [64.321689, 56.715784],
            [64.295714, 56.699422],
            [64.350427, 56.68256],
            [64.343056, 56.644276],
            [64.2981, 56.646379],
            [64.298524, 56.630755],
            [64.31121, 56.619347],
            [64.287097, 56.605852],
            [64.211091, 56.597568],
            [64.183935, 56.58758],
            [64.168653, 56.559312],
            [64.221468, 56.537953],
            [64.214483, 56.491548],
            [64.178836, 56.460685],
            [64.061912, 56.472767],
            [64.041584, 56.476578],
            [64.05516, 56.487172],
            [64.019699, 56.489201],
            [63.996761, 56.468701],
            [63.985993, 56.475053],
            [63.915512, 56.474196],
            [63.890534, 56.482327],
            [63.829419, 56.481103],
            [63.773648, 56.510216],
            [63.742126, 56.514347],
            [63.74679, 56.537487],
            [63.725886, 56.538971],
            [63.701056, 56.524505],
            [63.686506, 56.533072],
            [63.595406, 56.558355],
            [63.599114, 56.587807],
            [63.568155, 56.591747],
            [63.560809, 56.59973],
            [63.520765, 56.609643],
            [63.473944, 56.611631],
            [63.469777, 56.59504],
            [63.430653, 56.598565],
            [63.417439, 56.610044],
            [63.400279, 56.610414],
            [63.398544, 56.581199],
            [63.368715, 56.549709],
            [63.377526, 56.497103],
            [63.358256, 56.49102],
            [63.360522, 56.512345],
            [63.318129, 56.524622],
            [63.311569, 56.54951],
            [63.31507, 56.567783],
            [63.290639, 56.57034],
            [63.262299, 56.564874],
            [63.236565, 56.570337],
            [63.231373, 56.560724],
            [63.189185, 56.568746],
            [63.201517, 56.58195],
            [63.18414, 56.586739],
            [63.154567, 56.566747],
            [63.145443, 56.585314],
            [63.124405, 56.564124],
            [62.985872, 56.597938],
            [62.976832, 56.586357],
            [62.922006, 56.592433],
            [62.890995, 56.590835],
            [62.871181, 56.546933],
            [62.83173, 56.548307],
            [62.742916, 56.558361],
            [62.724762, 56.507773],
            [62.688123, 56.502965],
            [62.653279, 56.514784],
            [62.651286, 56.48652],
            [62.623809, 56.496864],
            [62.602932, 56.490658],
            [62.583358, 56.532031],
            [62.604782, 56.548317],
            [62.569212, 56.565813],
            [62.518812, 56.563641],
            [62.428448, 56.526957],
            [62.435904, 56.520722],
            [62.399577, 56.508835],
            [62.401213, 56.487838],
            [62.378312, 56.487955],
            [62.369919, 56.457488],
            [62.336378, 56.467094],
            [62.309866, 56.431601],
            [62.271055, 56.419793],
            [62.254465, 56.399082],
            [62.256548, 56.34077],
            [62.267765, 56.339687],
            [62.212465, 56.319319],
            [62.196859, 56.304905],
            [62.17877, 56.303582],
            [62.171373, 56.285933],
            [62.15402, 56.274073],
            [62.11954, 56.286588],
            [62.07111, 56.297018],
            [62.061513, 56.28285],
            [62.053632, 56.245319],
            [62.076479, 56.233558],
            [62.077829, 56.218268],
            [62.066803, 56.203021],
            [62.078054, 56.198672],
            [62.061058, 56.130821],
            [61.936046, 56.153162],
            [61.90458, 56.164831],
            [61.851164, 56.177883],
            [61.836979, 56.177833],
            [61.870968, 56.198586],
            [61.870744, 56.222679],
            [61.830382, 56.241274],
            [61.80518, 56.228559],
            [61.773073, 56.226355],
            [61.76923, 56.237808],
            [61.74992, 56.2445],
            [61.746067, 56.269379],
            [61.723891, 56.272197],
            [61.73458, 56.286702],
            [61.698559, 56.300068],
            [61.668357, 56.317422],
            [61.618051, 56.320227],
            [61.604536, 56.302224],
            [61.591907, 56.304267],
            [61.581456, 56.287675],
            [61.560983, 56.309548],
            [61.54124, 56.303571],
            [61.504934, 56.330304],
            [61.471576, 56.330009],
            [61.471602, 56.345944],
            [61.444061, 56.353236],
            [61.420876, 56.335846],
            [61.409282, 56.345597],
            [61.382899, 56.33787],
            [61.337796, 56.314242],
            [61.313447, 56.313413],
            [61.319722, 56.328535],
            [61.310379, 56.340261],
            [61.259559, 56.332062],
            [61.254729, 56.339044],
            [61.219909, 56.349651],
            [61.18012, 56.353453],
            [61.161025, 56.366287],
            [61.154796, 56.357224],
            [61.119931, 56.352921],
            [61.093303, 56.339288],
            [61.091777, 56.319243],
            [61.145639, 56.280185],
            [61.160415, 56.239439],
            [61.174526, 56.229529],
            [61.185875, 56.208264],
            [61.103532, 56.181438],
            [61.07922, 56.183873],
            [61.045964, 56.19983],
            [61.01414, 56.185094],
            [60.976089, 56.200604],
            [60.963869, 56.190952],
            [60.874457, 56.21478],
            [60.879065, 56.22041],
            [60.846361, 56.224448],
            [60.808397, 56.217737],
            [60.768357, 56.218778],
            [60.77049, 56.237147],
            [60.705005, 56.242695],
            [60.65546, 56.214968],
            [60.679792, 56.310093],
            [60.576364, 56.318084],
            [60.553838, 56.221309],
            [60.502621, 56.236836],
            [60.465217, 56.221059],
            [60.363341, 56.233296],
            [60.290445, 56.228516],
            [60.261563, 56.213531],
            [60.258947, 56.199749],
            [60.200595, 56.202664],
            [60.170603, 56.217078],
            [60.140545, 56.213888],
            [60.102612, 56.218838],
            [60.050958, 56.213551],
            [60.01838, 56.225017],
            [60.004012, 56.220641],
            [59.977186, 56.238502],
            [59.961201, 56.239258],
            [59.91537, 56.257484],
            [59.863296, 56.260554],
            [59.832897, 56.245543],
            [59.757861, 56.223543],
            [59.634706, 56.248048],
            [59.714008, 56.323517],
            [59.572353, 56.320981],
            [59.571764, 56.337873],
            [59.554775, 56.338906],
            [59.412691, 56.336071],
            [59.420376, 56.320775],
            [59.28884, 56.327483],
            [59.2844, 56.344473],
            [59.262379, 56.343599],
            [59.222805, 56.355335],
            [59.159478, 56.34547],
            [59.164404, 56.326346],
            [59.141941, 56.330622],
            [59.138971, 56.322006],
            [59.162618, 56.314876],
            [59.164644, 56.304995],
            [59.186199, 56.2965],
            [59.198336, 56.271061],
            [59.16889, 56.230173],
            [59.168521, 56.198713],
            [59.133113, 56.202521],
            [59.120907, 56.190415],
            [59.109779, 56.197194],
            [59.067135, 56.199447],
            [59.018223, 56.197682],
            [59.030051, 56.173308],
            [59.04205, 56.168357],
            [59.038559, 56.12776],
            [58.984164, 56.080198],
            [58.96063, 56.065224],
            [58.900497, 56.053224],
            [58.880303, 56.073573],
            [58.832811, 56.068922],
            [58.832106, 56.08386],
            [58.750933, 56.086396],
            [58.732754, 56.080653],
            [58.71401, 56.094745],
            [58.713868, 56.116201],
            [58.643547, 56.117327],
            [58.628467, 56.135542],
            [58.597604, 56.137514],
            [58.582806, 56.16517],
            [58.559694, 56.172639],
            [58.55448, 56.166297],
            [58.511075, 56.170666],
            [58.49346, 56.157101],
            [58.482469, 56.132545],
            [58.469786, 56.121835],
            [58.42145, 56.12165],
            [58.410598, 56.101083],
            [58.384528, 56.081776],
            [58.345632, 56.084453],
            [58.359301, 56.114083],
            [58.349088, 56.121563],
            [58.316178, 56.11676],
            [58.306031, 56.10464],
            [58.25713, 56.10478],
            [58.242853, 56.118187],
            [58.185399, 56.126059],
            [58.164541, 56.140715],
            [58.129592, 56.133809],
            [58.103662, 56.139587],
            [58.071391, 56.116052],
            [57.996703, 56.109851],
            [57.955409, 56.112387],
            [57.912709, 56.106608],
            [57.894107, 56.121581],
            [57.908903, 56.131023],
            [57.893824, 56.141311],
            [57.861552, 56.148356],
            [57.8018, 56.141133],
            [57.774601, 56.144656],
            [57.751631, 56.133382],
            [57.721896, 56.12704],
            [57.686242, 56.136622],
            [57.657071, 56.113933],
            [57.645798, 56.093041],
            [57.61113, 56.095296],
            [57.598601, 56.085196],
            [57.521365, 56.096742],
            [57.51834, 56.105684],
            [57.480225, 56.122618],
            [57.473738, 56.13444],
            [57.493474, 56.164205],
            [57.49019, 56.173165],
            [57.50828, 56.19462],
            [57.50354, 56.203585],
            [57.468344, 56.213471],
            [57.452536, 56.240399],
            [57.420497, 56.249445],
            [57.4065, 56.27247],
            [57.412841, 56.293926],
            [57.432504, 56.313092],
            [57.406237, 56.34266],
            [57.410728, 56.346032],
            [57.388736, 56.372496],
            [57.371424, 56.379994],
            [57.32809, 56.374039],
            [57.312763, 56.390579],
            [57.323348, 56.394217],
            [57.357308, 56.440859],
            [57.35058, 56.467764],
            [57.370646, 56.527306],
            [57.388288, 56.531496],
            [57.408575, 56.581666],
            [57.418168, 56.580454],
            [57.418167, 56.609232],
            [57.387514, 56.598095],
            [57.371084, 56.606255],
            [57.338556, 56.609783],
            [57.346936, 56.621581],
            [57.39909, 56.640547],
            [57.438894, 56.677265],
            [57.395008, 56.683991],
            [57.367994, 56.680462],
            [57.335024, 56.687188],
            [57.335244, 56.703617],
            [57.317492, 56.705601],
            [57.306575, 56.718061],
            [57.347261, 56.748935],
            [57.323223, 56.772421],
            [57.305029, 56.775177],
            [57.270625, 56.802522],
            [57.2768, 56.817298],
            [57.271947, 56.838248],
            [57.254415, 56.840563],
            [57.229384, 56.856],
            [57.248349, 56.884779],
            [57.265219, 56.889741],
            [57.268085, 56.901759],
            [57.297195, 56.91036],
            [57.315829, 56.928444],
            [57.359934, 56.937376],
            [57.371511, 56.955349],
            [57.389154, 56.937376],
            [57.430833, 56.955129],
            [57.466781, 56.912899],
            [57.572525, 56.894596],
            [57.578258, 56.910474],
            [57.598657, 56.916208],
            [57.636698, 56.917311],
            [57.640337, 56.908711],
            [57.665146, 56.910806],
            [57.676062, 56.920509],
            [57.661506, 56.944216],
            [57.706604, 56.957668],
            [57.751041, 56.944878],
            [57.774857, 56.962852],
            [57.780921, 56.990197],
            [57.80562, 56.986779],
            [57.816977, 56.977517],
            [57.80959, 56.966932],
            [57.826571, 56.962411],
            [57.839912, 56.984905],
            [57.823702, 57.007068],
            [57.839691, 57.005965],
            [57.848842, 57.017874],
            [57.843328, 57.032208],
            [57.872988, 57.055695],
            [57.89449, 57.052608],
            [57.909045, 57.061429],
            [57.945763, 57.056247],
            [57.943116, 57.072786],
            [57.965941, 57.070912],
            [57.980275, 57.0783],
            [58.021624, 57.078962],
            [58.016111, 57.057461],
            [57.970683, 57.052278],
            [58.026036, 57.03629],
            [58.037282, 57.05294],
            [58.059225, 57.057241],
            [58.069369, 57.049412],
            [58.09072, 57.100279],
            [58.039704, 57.114243],
            [58.061359, 57.125685],
            [58.103573, 57.128724],
            [58.117442, 57.12396],
            [58.124395, 57.142605],
            [58.032166, 57.150674],
            [58.047652, 57.193216],
            [58.035169, 57.197225],
            [58.025352, 57.18495],
            [57.998718, 57.19],
            [57.98641, 57.21175],
            [57.99185, 57.22149],
            [58.017442, 57.213096],
            [58.028881, 57.237622],
            [58.047489, 57.248701],
            [58.016394, 57.25284],
            [58.010854, 57.265621],
            [58.02596, 57.274177],
            [57.98373, 57.293403],
            [57.955704, 57.29033],
            [57.940085, 57.296469],
            [57.979685, 57.312939],
            [57.940486, 57.31643],
            [58.012026, 57.484537],
            [57.968976, 57.489028],
            [57.985624, 57.535398],
            [57.985679, 57.554646],
            [58.028868, 57.566948],
            [58.030354, 57.611408],
            [58.063776, 57.611709],
            [58.064248, 57.595706],
            [58.088728, 57.588975],
            [58.119009, 57.602701],
            [58.092918, 57.619945],
            [58.109025, 57.630595],
            [58.107606, 57.651305],
            [58.094477, 57.658863],
            [58.154722, 57.695192],
            [58.15942, 57.679759],
            [58.238149, 57.682415],
            [58.250693, 57.673932],
            [58.276121, 57.677705],
            [58.387239, 57.673989],
            [58.446871, 57.673931],
            [58.452214, 57.664705],
            [58.428456, 57.660667],
            [58.449041, 57.650015],
            [58.465331, 57.620189],
            [58.460452, 57.582358],
            [58.469498, 57.576165],
            [58.510638, 57.574219],
            [58.517058, 57.562257],
            [58.537085, 57.564072],
            [58.594911, 57.597965],
            [58.679547, 57.655736],
            [58.744342, 57.690972],
            [58.805427, 57.729091],
            [58.847335, 57.725834],
            [58.83132, 57.740102],
            [58.863791, 57.838097],
            [58.728266, 57.852053],
            [58.744246, 57.892697],
            [58.702668, 57.885296],
            [58.686545, 57.899422],
            [58.686603, 57.913182],
            [58.668554, 57.933306],
            [58.649257, 57.943832],
            [58.662396, 57.94694],
            [58.642168, 57.975416],
            [58.638569, 58.004797],
            [58.597439, 58.021005],
            [58.586743, 58.041514],
            [58.625445, 58.058715],
            [58.650475, 58.050005],
            [58.676717, 58.069522],
            [58.663264, 58.105247],
            [58.688293, 58.115502],
            [58.738242, 58.144281],
            [58.762831, 58.141415],
            [58.778046, 58.179125],
            [58.805502, 58.176148],
            [58.816306, 58.214851],
            [58.861294, 58.21011],
            [58.874304, 58.245395],
            [58.908816, 58.241315],
            [58.924693, 58.28101],
            [59.137063, 58.259842],
            [59.105526, 58.288069],
            [59.18624, 58.278588],
            [59.200353, 58.310233],
            [59.201675, 58.327104],
            [59.218876, 58.324788],
            [59.264636, 58.336697],
            [59.255373, 58.35059],
            [59.277755, 58.377164],
            [59.27577, 58.399548],
            [59.287457, 58.419285],
            [59.319323, 58.426342],
            [59.38493, 58.429651],
            [59.453623, 58.478057],
            [59.39606, 58.585343],
            [59.415573, 58.662859],
            [59.398591, 58.708066],
            [59.350846, 58.706853],
            [59.3439, 58.696929],
            [59.317767, 58.694393],
            [59.286231, 58.700304],
            [59.267596, 58.712696],
            [59.228562, 58.719862],
            [59.153582, 58.717546],
            [59.145751, 58.774993],
            [59.105835, 58.77378],
            [59.081684, 58.843576],
            [59.087528, 58.843907],
            [59.080028, 58.906096],
            [59.1689, 58.910287],
            [59.20694, 58.953952],
            [59.092924, 59.000481],
            [59.196787, 59.147133],
            [59.151908, 59.162459],
            [59.106479, 59.17139],
            [58.994559, 59.208547],
            [58.989779, 59.22277],
            [58.95987, 59.234125],
            [58.789187, 59.239542],
            [58.681216, 59.26849],
            [58.624727, 59.297966],
            [58.598702, 59.352767],
            [58.54037, 59.407126],
            [58.524931, 59.435023],
            [58.459103, 59.455641],
            [58.458441, 59.463359],
            [58.416209, 59.459389],
            [58.362935, 59.46464],
            [58.479048, 59.711673],
            [58.505842, 59.71013],
            [58.602325, 59.693481],
            [58.693449, 59.85661],
            [58.800246, 59.843002],
            [58.839456, 59.931982],
            [58.988792, 59.907617],
            [58.978314, 59.955802],
            [58.980078, 59.967711],
            [58.963427, 59.977965],
            [58.984157, 59.985133],
            [59.029805, 60.015235],
            [59.07567, 60.102344],
            [59.068392, 60.119214],
            [59.089342, 60.1298],
            [59.0889, 60.148985],
            [59.103343, 60.17997],
            [59.137965, 60.205882],
            [59.155605, 60.249436],
            [59.14965, 60.257375],
            [59.160896, 60.278877],
            [59.156264, 60.290344],
            [59.179309, 60.315153],
            [59.143803, 60.326179],
            [59.149756, 60.341837],
            [59.175558, 60.349776],
            [59.21073, 60.39741],
            [59.207201, 60.406783],
            [59.225504, 60.429387],
            [59.218446, 60.44328],
            [59.243806, 60.470295],
            [59.284051, 60.499405],
            [59.355832, 60.521459],
            [59.361234, 60.543732],
            [59.394421, 60.596879],
            [59.405998, 60.601951],
            [59.409195, 60.622681],
            [59.423639, 60.635472],
            [59.417351, 60.685752],
            [59.432566, 60.721808],
            [59.444805, 60.733386],
            [59.425948, 60.750256],
            [59.450206, 60.772971],
            [59.482731, 60.831521],
            [59.469608, 60.847729],
            [59.476994, 60.88974],
            [59.475006, 60.939248],
            [59.437075, 60.946415],
            [59.395064, 60.942114],
            [59.369371, 60.968356],
            [59.374001, 60.991071],
            [59.362421, 61.043556],
            [59.3418, 61.058221],
            [59.358671, 61.06627],
            [59.340035, 61.078068],
            [59.344114, 61.104862],
            [59.315994, 61.151944],
            [59.292176, 61.161206],
            [59.284013, 61.228136],
            [59.271884, 61.228908],
            [59.252806, 61.260553],
            [59.261516, 61.275659],
            [59.257545, 61.313038],
            [59.28467, 61.316236],
            [59.320615, 61.333548],
            [59.312123, 61.354388],
            [59.369239, 61.392871],
            [59.425473, 61.39673],
            [59.44521, 61.415145],
            [59.42933, 61.441939],
            [59.401653, 61.46366],
            [59.439031, 61.486375],
            [59.446197, 61.510964],
            [59.415102, 61.521549],
            [59.402862, 61.532575],
            [59.413336, 61.55143],
            [59.402087, 61.58936],
            [59.413554, 61.5973],
            [59.406496, 61.616265],
            [59.357648, 61.634347],
            [59.348463, 61.66377],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5512",
      properties: {
        name: "Северная Осетия - Алания",
        density: 0,
        path: "/world/Russia/Северная Осетия - Алания",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.398487, 42.896923],
            [43.414416, 42.922296],
            [43.426533, 42.929998],
            [43.4291, 42.955619],
            [43.442963, 42.96938],
            [43.465041, 42.961473],
            [43.510379, 42.962501],
            [43.53523, 42.944838],
            [43.572302, 42.943093],
            [43.57949, 42.961167],
            [43.593147, 42.966635],
            [43.595201, 42.978136],
            [43.610399, 42.989227],
            [43.622618, 43.01069],
            [43.609884, 43.030791],
            [43.612041, 43.041266],
            [43.632681, 43.047427],
            [43.647571, 43.069403],
            [43.652037, 43.101212],
            [43.697015, 43.123085],
            [43.703382, 43.131198],
            [43.742198, 43.133458],
            [43.75068, 43.15681],
            [43.726281, 43.16619],
            [43.744354, 43.190015],
            [43.750206, 43.221695],
            [43.763042, 43.234018],
            [43.806581, 43.300433],
            [43.829874, 43.323265],
            [43.859672, 43.298817],
            [43.861798, 43.264182],
            [43.910864, 43.253402],
            [43.919593, 43.242004],
            [43.9237, 43.262029],
            [43.940236, 43.286538],
            [44.002068, 43.272692],
            [43.984904, 43.249244],
            [43.974178, 43.246343],
            [43.979462, 43.234457],
            [44.003052, 43.212394],
            [44.028856, 43.22655],
            [44.056685, 43.252326],
            [44.082973, 43.254996],
            [44.098232, 43.26719],
            [44.087696, 43.292119],
            [44.037769, 43.352206],
            [44.076552, 43.392756],
            [44.103816, 43.416144],
            [44.105683, 43.431065],
            [44.146346, 43.437676],
            [44.156342, 43.41635],
            [44.170308, 43.401152],
            [44.17493, 43.3486],
            [44.190334, 43.356713],
            [44.282959, 43.420408],
            [44.320372, 43.449644],
            [44.333482, 43.444053],
            [44.34406, 43.417867],
            [44.355767, 43.408522],
            [44.355254, 43.3924],
            [44.381491, 43.396508],
            [44.384058, 43.402977],
            [44.442879, 43.427804],
            [44.412997, 43.452857],
            [44.434323, 43.488288],
            [44.44798, 43.497633],
            [44.440587, 43.507799],
            [44.443975, 43.541841],
            [44.474986, 43.58225],
            [44.43545, 43.605894],
            [44.440071, 43.617909],
            [44.426206, 43.659165],
            [44.429341, 43.685154],
            [44.410751, 43.683965],
            [44.401714, 43.690947],
            [44.370496, 43.691461],
            [44.358584, 43.680473],
            [44.333271, 43.676673],
            [44.302567, 43.688174],
            [44.282233, 43.710046],
            [44.267364, 43.746333],
            [44.269395, 43.812249],
            [44.291438, 43.810387],
            [44.313177, 43.805896],
            [44.353767, 43.807348],
            [44.378436, 43.814519],
            [44.378351, 43.802056],
            [44.471309, 43.801203],
            [44.472248, 43.807349],
            [44.507929, 43.807947],
            [44.507929, 43.81435],
            [44.554536, 43.813838],
            [44.596832, 43.829566],
            [44.60443, 43.82453],
            [44.666445, 43.82658],
            [44.666359, 43.835116],
            [44.703064, 43.834604],
            [44.702296, 43.817425],
            [44.789621, 43.817085],
            [44.802938, 43.804942],
            [44.80294, 43.71941],
            [44.823683, 43.70285],
            [44.845535, 43.712411],
            [44.847499, 43.704728],
            [44.83243, 43.681956],
            [44.847463, 43.578175],
            [44.847608, 43.56497],
            [44.828589, 43.551795],
            [44.789316, 43.554499],
            [44.799898, 43.56955],
            [44.797311, 43.594948],
            [44.696069, 43.594712],
            [44.707358, 43.550382],
            [44.677256, 43.550264],
            [44.653151, 43.54121],
            [44.622436, 43.548247],
            [44.63363, 43.614966],
            [44.503346, 43.587919],
            [44.501935, 43.557876],
            [44.508168, 43.543765],
            [44.502877, 43.52942],
            [44.479066, 43.513722],
            [44.488473, 43.498318],
            [44.493177, 43.472684],
            [44.480948, 43.473625],
            [44.480478, 43.451754],
            [44.486887, 43.434263],
            [44.511816, 43.40569],
            [44.533922, 43.417331],
            [44.577958, 43.416361],
            [44.573778, 43.309153],
            [44.568979, 43.234828],
            [44.620764, 43.220757],
            [44.653688, 43.219464],
            [44.679901, 43.210889],
            [44.680493, 43.233137],
            [44.667974, 43.234162],
            [44.669267, 43.278728],
            [44.697017, 43.270732],
            [44.773101, 43.1577],
            [44.796956, 43.166151],
            [44.798251, 43.146351],
            [44.831421, 43.13986],
            [44.846707, 43.119635],
            [44.834126, 43.118695],
            [44.855233, 43.107465],
            [44.903326, 43.096648],
            [44.915555, 43.07948],
            [44.959944, 43.045205],
            [44.956652, 43.031329],
            [44.933135, 43.019688],
            [44.934194, 43.001492],
            [44.943837, 42.981502],
            [44.954067, 42.977151],
            [44.953009, 42.946579],
            [44.927611, 42.931116],
            [44.907974, 42.93629],
            [44.911149, 42.921003],
            [44.882929, 42.912537],
            [44.87752, 42.883375],
            [44.862234, 42.881905],
            [44.869995, 42.860622],
            [44.856826, 42.853802],
            [44.853651, 42.835576],
            [44.830134, 42.840044],
            [44.795798, 42.860386],
            [44.777044, 42.859798],
            [44.75094, 42.843453],
            [44.73389, 42.852037],
            [44.700495, 42.858856],
            [44.701413, 42.884269],
            [44.673793, 42.890841],
            [44.633879, 42.913062],
            [44.630115, 42.883143],
            [44.645292, 42.85913],
            [44.646008, 42.843065],
            [44.664741, 42.831885],
            [44.658577, 42.811263],
            [44.666338, 42.798681],
            [44.665868, 42.774223],
            [44.678461, 42.756177],
            [44.665638, 42.749013],
            [44.637881, 42.745517],
            [44.597123, 42.748679],
            [44.580175, 42.744751],
            [44.553093, 42.766372],
            [44.53174, 42.752752],
            [44.514598, 42.754273],
            [44.5001, 42.733924],
            [44.520555, 42.702166],
            [44.517974, 42.697038],
            [44.416457, 42.719994],
            [44.412223, 42.711101],
            [44.392851, 42.704944],
            [44.384269, 42.715134],
            [44.349761, 42.711304],
            [44.319256, 42.7225],
            [44.302919, 42.707503],
            [44.248892, 42.679922],
            [44.240589, 42.652913],
            [44.232088, 42.642507],
            [44.233345, 42.627957],
            [44.1914, 42.632357],
            [44.162211, 42.606269],
            [44.15055, 42.604787],
            [44.115379, 42.6173],
            [44.089904, 42.604973],
            [44.021778, 42.597196],
            [44.003283, 42.58514],
            [43.991396, 42.5673],
            [43.973845, 42.559139],
            [43.965006, 42.546942],
            [43.94607, 42.559658],
            [43.912435, 42.568671],
            [43.909639, 42.57518],
            [43.874628, 42.579863],
            [43.865799, 42.589984],
            [43.836416, 42.598723],
            [43.825977, 42.593475],
            [43.810165, 42.599114],
            [43.789374, 42.597354],
            [43.770458, 42.58878],
            [43.752034, 42.600588],
            [43.754203, 42.616475],
            [43.743225, 42.618485],
            [43.715011, 42.636577],
            [43.759004, 42.655092],
            [43.777776, 42.677031],
            [43.786968, 42.698261],
            [43.807887, 42.721072],
            [43.807656, 42.738327],
            [43.778776, 42.758044],
            [43.711088, 42.775845],
            [43.690019, 42.786989],
            [43.687783, 42.800806],
            [43.652795, 42.795479],
            [43.631469, 42.812925],
            [43.60891, 42.813681],
            [43.610651, 42.833934],
            [43.560661, 42.868327],
            [43.548055, 42.86523],
            [43.507662, 42.867795],
            [43.489896, 42.875787],
            [43.478122, 42.889693],
            [43.460511, 42.887035],
            [43.442468, 42.893693],
            [43.413658, 42.891609],
            [43.398487, 42.896923],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5508",
      properties: {
        name: "Смоленская область",
        density: 0,
        path: "/world/Russia/Смоленская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.931557, 55.623159],
            [30.927856, 55.640456],
            [30.951751, 55.646108],
            [30.949725, 55.659075],
            [30.965018, 55.668984],
            [31.037975, 55.698582],
            [31.064901, 55.694619],
            [31.062574, 55.704743],
            [31.027914, 55.723052],
            [31.027568, 55.749676],
            [31.038165, 55.758896],
            [31.139234, 55.756226],
            [31.135098, 55.752004],
            [31.176026, 55.735117],
            [31.176284, 55.744595],
            [31.206873, 55.719694],
            [31.235047, 55.730293],
            [31.239269, 55.738478],
            [31.280799, 55.737704],
            [31.293336, 55.72633],
            [31.309965, 55.725986],
            [31.310137, 55.74072],
            [31.350289, 55.732966],
            [31.395136, 55.732277],
            [31.417582, 55.72099],
            [31.436192, 55.739602],
            [31.453855, 55.73874],
            [31.465358, 55.727712],
            [31.418229, 55.712288],
            [31.440717, 55.702724],
            [31.490476, 55.705396],
            [31.483134, 55.718642],
            [31.503719, 55.728809],
            [31.525872, 55.725464],
            [31.54374, 55.733623],
            [31.563526, 55.73034],
            [31.604352, 55.748729],
            [31.596847, 55.729167],
            [31.653002, 55.734798],
            [31.659856, 55.726231],
            [31.685721, 55.729495],
            [31.693758, 55.720846],
            [31.735422, 55.716451],
            [31.726835, 55.70513],
            [31.735729, 55.694217],
            [31.777728, 55.696992],
            [31.785397, 55.716493],
            [31.770709, 55.74454],
            [31.791514, 55.762368],
            [31.80616, 55.760247],
            [31.841611, 55.774343],
            [31.875492, 55.771651],
            [31.865975, 55.78406],
            [31.887769, 55.787895],
            [31.893545, 55.771386],
            [32.035329, 55.76618],
            [32.055159, 55.752174],
            [32.047308, 55.722168],
            [32.056038, 55.730572],
            [32.070643, 55.720047],
            [32.114438, 55.721271],
            [32.094857, 55.689348],
            [32.070053, 55.685186],
            [32.055244, 55.691958],
            [32.035581, 55.676557],
            [32.02726, 55.658301],
            [32.057776, 55.649347],
            [32.057041, 55.658159],
            [32.095164, 55.670643],
            [32.088636, 55.681903],
            [32.103322, 55.688675],
            [32.127188, 55.685963],
            [32.126944, 55.671929],
            [32.156885, 55.669124],
            [32.169414, 55.691712],
            [32.159067, 55.697235],
            [32.195311, 55.699001],
            [32.19665, 55.683968],
            [32.234919, 55.672161],
            [32.259051, 55.670092],
            [32.272698, 55.694742],
            [32.288111, 55.698333],
            [32.308897, 55.68963],
            [32.324979, 55.694073],
            [32.315606, 55.70763],
            [32.341305, 55.708772],
            [32.382798, 55.72166],
            [32.380866, 55.70334],
            [32.403401, 55.692705],
            [32.416394, 55.715285],
            [32.440816, 55.71066],
            [32.430653, 55.683226],
            [32.452336, 55.672605],
            [32.464068, 55.684666],
            [32.458314, 55.709686],
            [32.481214, 55.709078],
            [32.481974, 55.723974],
            [32.497858, 55.740422],
            [32.512982, 55.716408],
            [32.542422, 55.707758],
            [32.543719, 55.687542],
            [32.522283, 55.664957],
            [32.522683, 55.643046],
            [32.538137, 55.655909],
            [32.563089, 55.660396],
            [32.554114, 55.679241],
            [32.58475, 55.688689],
            [32.631863, 55.684802],
            [32.642656, 55.690086],
            [32.679574, 55.686896],
            [32.709038, 55.692979],
            [32.715021, 55.684703],
            [32.76505, 55.678622],
            [32.796584, 55.669848],
            [32.816152, 55.675133],
            [32.822334, 55.660675],
            [32.8428, 55.653421],
            [32.880948, 55.656709],
            [32.900732, 55.634004],
            [32.923365, 55.662421],
            [32.958513, 55.658932],
            [32.97479, 55.668903],
            [33.002914, 55.656835],
            [33.0061, 55.647522],
            [33.045708, 55.654385],
            [33.037743, 55.661859],
            [33.070244, 55.668966],
            [33.074564, 55.654875],
            [33.094047, 55.65414],
            [33.117237, 55.636496],
            [33.134176, 55.63907],
            [33.170343, 55.659309],
            [33.168024, 55.671174],
            [33.194398, 55.687348],
            [33.196879, 55.701868],
            [33.246289, 55.715102],
            [33.266047, 55.71596],
            [33.257991, 55.700766],
            [33.304829, 55.697949],
            [33.30397, 55.71106],
            [33.323943, 55.714491],
            [33.321459, 55.726907],
            [33.345303, 55.729468],
            [33.421077, 55.727272],
            [33.390499, 55.733733],
            [33.391578, 55.74779],
            [33.454698, 55.754458],
            [33.457056, 55.761535],
            [33.498257, 55.76031],
            [33.510928, 55.772825],
            [33.53347, 55.77377],
            [33.537327, 55.813758],
            [33.569512, 55.812935],
            [33.575761, 55.848673],
            [33.648069, 55.844485],
            [33.659802, 55.901784],
            [33.672962, 55.929815],
            [33.646005, 55.930182],
            [33.650924, 55.956405],
            [33.696272, 55.953249],
            [33.714437, 55.959621],
            [33.722402, 55.952392],
            [33.756037, 55.950432],
            [33.779839, 55.942468],
            [33.777266, 55.930092],
            [33.802508, 55.932911],
            [33.821652, 55.962074],
            [33.796042, 55.9682],
            [33.811726, 55.974204],
            [33.803148, 55.982996],
            [33.811741, 56.006062],
            [33.798613, 56.014915],
            [33.863861, 56.014426],
            [33.863613, 56.059824],
            [33.87697, 56.054433],
            [33.915505, 56.067054],
            [33.929271, 56.054082],
            [33.963248, 56.051871],
            [33.999875, 56.040078],
            [34.013315, 56.048368],
            [34.059593, 56.037374],
            [34.085427, 56.026866],
            [34.11903, 56.051618],
            [34.15825, 56.063054],
            [34.208982, 56.052008],
            [34.204527, 56.039979],
            [34.291102, 56.016923],
            [34.322138, 55.994503],
            [34.336202, 56.012169],
            [34.367868, 56.015445],
            [34.381548, 56.022678],
            [34.41274, 55.99679],
            [34.448061, 55.979146],
            [34.460681, 55.981475],
            [34.470544, 55.963779],
            [34.492387, 55.96573],
            [34.517901, 55.954821],
            [34.515294, 55.9451],
            [34.539419, 55.931408],
            [34.573988, 55.934587],
            [34.596972, 55.943086],
            [34.619023, 55.926875],
            [34.605539, 55.911879],
            [34.657699, 55.919585],
            [34.682742, 55.916385],
            [34.696167, 55.926224],
            [34.684549, 55.935589],
            [34.71875, 55.956455],
            [34.745045, 55.977857],
            [34.767381, 55.976044],
            [34.784719, 55.961345],
            [34.827068, 55.97972],
            [34.813673, 55.966206],
            [34.852824, 55.955537],
            [34.8593, 55.972885],
            [34.893425, 55.974387],
            [34.910051, 55.964666],
            [34.925254, 55.984819],
            [34.957055, 55.96929],
            [35.003851, 55.977718],
            [35.045767, 55.980396],
            [35.065608, 55.989845],
            [35.077805, 55.968639],
            [35.104944, 55.952786],
            [35.148495, 55.957501],
            [35.15835, 55.946356],
            [35.204486, 55.919457],
            [35.189813, 55.907153],
            [35.199279, 55.889126],
            [35.220673, 55.908027],
            [35.223436, 55.880218],
            [35.249547, 55.860075],
            [35.251602, 55.845286],
            [35.269949, 55.836746],
            [35.288269, 55.816958],
            [35.283169, 55.79983],
            [35.330782, 55.792565],
            [35.327064, 55.758336],
            [35.342574, 55.741519],
            [35.315471, 55.744795],
            [35.30433, 55.734254],
            [35.3165, 55.708153],
            [35.33759, 55.700594],
            [35.32325, 55.665981],
            [35.337077, 55.665767],
            [35.351132, 55.643017],
            [35.38264, 55.63067],
            [35.391128, 55.60589],
            [35.386857, 55.594644],
            [35.364621, 55.587866],
            [35.372138, 55.575828],
            [35.348577, 55.572621],
            [35.341906, 55.582126],
            [35.300606, 55.581443],
            [35.294308, 55.555006],
            [35.309436, 55.544395],
            [35.338802, 55.538373],
            [35.344685, 55.527586],
            [35.31357, 55.514627],
            [35.325435, 55.501841],
            [35.324762, 55.480205],
            [35.289005, 55.472355],
            [35.309344, 55.457494],
            [35.303344, 55.434904],
            [35.329487, 55.429493],
            [35.297793, 55.412433],
            [35.300277, 55.391562],
            [35.286192, 55.389008],
            [35.290961, 55.351858],
            [35.274107, 55.331069],
            [35.258645, 55.329882],
            [35.299363, 55.304631],
            [35.293924, 55.291095],
            [35.309887, 55.291157],
            [35.334374, 55.27902],
            [35.334221, 55.271752],
            [35.320893, 55.25409],
            [35.297346, 55.253492],
            [35.308391, 55.239139],
            [35.323486, 55.242744],
            [35.342516, 55.233722],
            [35.330108, 55.213828],
            [35.345212, 55.20588],
            [35.321577, 55.201011],
            [35.303937, 55.18591],
            [35.280461, 55.179638],
            [35.288992, 55.163844],
            [35.274322, 55.111455],
            [35.25643, 55.111428],
            [35.231645, 55.092252],
            [35.208929, 55.062557],
            [35.183581, 55.059315],
            [35.187268, 55.049483],
            [35.167349, 55.052029],
            [35.149835, 55.03605],
            [35.136925, 55.043155],
            [35.114697, 55.037647],
            [35.117465, 55.016978],
            [35.100232, 55.012822],
            [35.126778, 55.004857],
            [35.105972, 54.998502],
            [35.122086, 54.991965],
            [35.124703, 54.976239],
            [35.092722, 54.975884],
            [35.051015, 54.969835],
            [35.05219, 54.960383],
            [35.030264, 54.95778],
            [35.043514, 54.945521],
            [35.006546, 54.94268],
            [35.021277, 54.933515],
            [35.000037, 54.915235],
            [34.976256, 54.914239],
            [34.990916, 54.897844],
            [34.953037, 54.895229],
            [34.944143, 54.919747],
            [34.916352, 54.934925],
            [34.906171, 54.930071],
            [34.920623, 54.910488],
            [34.906713, 54.90171],
            [34.871971, 54.903384],
            [34.88038, 54.875307],
            [34.870656, 54.866799],
            [34.876871, 54.825271],
            [34.894292, 54.823077],
            [34.888351, 54.809167],
            [34.905368, 54.787525],
            [34.928462, 54.78371],
            [34.90348, 54.778007],
            [34.893736, 54.757531],
            [34.87143, 54.753879],
            [34.867382, 54.736435],
            [34.83238, 54.721782],
            [34.83396, 54.708904],
            [34.814159, 54.69443],
            [34.789711, 54.707565],
            [34.758679, 54.705064],
            [34.706239, 54.71152],
            [34.702386, 54.682468],
            [34.684616, 54.674576],
            [34.66963, 54.679804],
            [34.620999, 54.661333],
            [34.586254, 54.683524],
            [34.575505, 54.6761],
            [34.542704, 54.681139],
            [34.534722, 54.672009],
            [34.51163, 54.677671],
            [34.499166, 54.691335],
            [34.475936, 54.691312],
            [34.441275, 54.679738],
            [34.434798, 54.688873],
            [34.406122, 54.688705],
            [34.402897, 54.680678],
            [34.448093, 54.678798],
            [34.464755, 54.682214],
            [34.481244, 54.658595],
            [34.51787, 54.660966],
            [34.520689, 54.598645],
            [34.488192, 54.597902],
            [34.48935, 54.578494],
            [34.458313, 54.577815],
            [34.466009, 54.562432],
            [34.435076, 54.545138],
            [34.444682, 54.516637],
            [34.418526, 54.513669],
            [34.301979, 54.515637],
            [34.252479, 54.521315],
            [34.242752, 54.508157],
            [34.222496, 54.50422],
            [34.181584, 54.520074],
            [34.171655, 54.518257],
            [34.152746, 54.553944],
            [34.135054, 54.564376],
            [34.119524, 54.562485],
            [34.084276, 54.54611],
            [34.062532, 54.566536],
            [34.029024, 54.555896],
            [33.987039, 54.57977],
            [33.963541, 54.574772],
            [33.950846, 54.582335],
            [33.913437, 54.576257],
            [33.89021, 54.554784],
            [33.864415, 54.559645],
            [33.855906, 54.571935],
            [33.822819, 54.565587],
            [33.819038, 54.556505],
            [33.792028, 54.555289],
            [33.771905, 54.573655],
            [33.760696, 54.564877],
            [33.709243, 54.545834],
            [33.694388, 54.533915],
            [33.693039, 54.514299],
            [33.649148, 54.507816],
            [33.659547, 54.492927],
            [33.633653, 54.476861],
            [33.670623, 54.469699],
            [33.686423, 54.475101],
            [33.702224, 54.465884],
            [33.660765, 54.448698],
            [33.627139, 54.419459],
            [33.637403, 54.410681],
            [33.625789, 54.397277],
            [33.572328, 54.393617],
            [33.614987, 54.373981],
            [33.642808, 54.340489],
            [33.689941, 54.329382],
            [33.671662, 54.305132],
            [33.687777, 54.295332],
            [33.655371, 54.276509],
            [33.65186, 54.268643],
            [33.621204, 54.268507],
            [33.630288, 54.25767],
            [33.603378, 54.258884],
            [33.604189, 54.242408],
            [33.625257, 54.236838],
            [33.611371, 54.223628],
            [33.628634, 54.207768],
            [33.629445, 54.187207],
            [33.564217, 54.175693],
            [33.568944, 54.164214],
            [33.559086, 54.147163],
            [33.572863, 54.123901],
            [33.536399, 54.120356],
            [33.527039, 54.10274],
            [33.56368, 54.106581],
            [33.541262, 54.093987],
            [33.493185, 54.088044],
            [33.484811, 54.098848],
            [33.452535, 54.082574],
            [33.451051, 54.055699],
            [33.465289, 54.049088],
            [33.48981, 54.058097],
            [33.501965, 54.03892],
            [33.473335, 54.035273],
            [33.510339, 54.023592],
            [33.504592, 54.016586],
            [33.493218, 54.007648],
            [33.432129, 54.000274],
            [33.431079, 53.989021],
            [33.449879, 53.978516],
            [33.430065, 53.973646],
            [33.408709, 53.946289],
            [33.344637, 53.92781],
            [33.340607, 53.944605],
            [33.317999, 53.948447],
            [33.280324, 53.938608],
            [33.276488, 53.926855],
            [33.303602, 53.911971],
            [33.286896, 53.896653],
            [33.251289, 53.891812],
            [33.245515, 53.870104],
            [33.271448, 53.864216],
            [33.257628, 53.855857],
            [33.263226, 53.843395],
            [33.289447, 53.844058],
            [33.28815, 53.832353],
            [33.303076, 53.826629],
            [33.298769, 53.816648],
            [33.265723, 53.801929],
            [33.233138, 53.795954],
            [33.223439, 53.804994],
            [33.19828, 53.807679],
            [33.197146, 53.796706],
            [33.218366, 53.792899],
            [33.211167, 53.780062],
            [33.221863, 53.767047],
            [33.224771, 53.743836],
            [33.235166, 53.738353],
            [33.184011, 53.726155],
            [33.140932, 53.725766],
            [33.0942, 53.694211],
            [33.067492, 53.689285],
            [33.069399, 53.661908],
            [33.035083, 53.662154],
            [33.044006, 53.654223],
            [32.99404, 53.619188],
            [32.975812, 53.619917],
            [32.951575, 53.606479],
            [32.951453, 53.58819],
            [32.91822, 53.58584],
            [32.894224, 53.558003],
            [32.911742, 53.544085],
            [32.895864, 53.525265],
            [32.879157, 53.527706],
            [32.862313, 53.511242],
            [32.862973, 53.494233],
            [32.891104, 53.499283],
            [32.87167, 53.473052],
            [32.900947, 53.465133],
            [32.880551, 53.434176],
            [32.857514, 53.427463],
            [32.804324, 53.438501],
            [32.788549, 53.438106],
            [32.79848, 53.425536],
            [32.769289, 53.413006],
            [32.762935, 53.424048],
            [32.74879, 53.44522],
            [32.75711, 53.45818],
            [32.67514, 53.46368],
            [32.65634, 53.46969],
            [32.65317, 53.49457],
            [32.63471, 53.49106],
            [32.6251, 53.475],
            [32.6051, 53.47878],
            [32.59961, 53.51295],
            [32.57163, 53.52007],
            [32.544728, 53.51826],
            [32.55086, 53.52796],
            [32.51842, 53.53955],
            [32.479968, 53.547706],
            [32.44263, 53.59363],
            [32.4258, 53.60744],
            [32.44511, 53.63748],
            [32.41619, 53.63483],
            [32.4131, 53.66521],
            [32.4337, 53.67328],
            [32.4525, 53.6689],
            [32.49996, 53.69053],
            [32.49704, 53.70589],
            [32.48116, 53.71902],
            [32.46254, 53.721],
            [32.460683, 53.737652],
            [32.41421, 53.72829],
            [32.39432, 53.730365],
            [32.36967, 53.71834],
            [32.35534, 53.72177],
            [32.343873, 53.750575],
            [32.322727, 53.761792],
            [32.27234, 53.76572],
            [32.285036, 53.776296],
            [32.25637, 53.7664],
            [32.23114, 53.7664],
            [32.21638, 53.77327],
            [32.223659, 53.78256],
            [32.208049, 53.799566],
            [32.198105, 53.794841],
            [32.14093, 53.80537],
            [32.125411, 53.818869],
            [32.099028, 53.815602],
            [32.09235, 53.8058],
            [32.05364, 53.80606],
            [32.04068, 53.79928],
            [31.987248, 53.803446],
            [31.952758, 53.783739],
            [31.927402, 53.78937],
            [31.901463, 53.781467],
            [31.86387, 53.78305],
            [31.812393, 53.806836],
            [31.778312, 53.804795],
            [31.756881, 53.832367],
            [31.794943, 53.838244],
            [31.781956, 53.844712],
            [31.7904, 53.85541],
            [31.81169, 53.854553],
            [31.83726, 53.866471],
            [31.819724, 53.865064],
            [31.800461, 53.877328],
            [31.8298, 53.882078],
            [31.82633, 53.897563],
            [31.857017, 53.917842],
            [31.848255, 53.94302],
            [31.8558, 53.95883],
            [31.891554, 53.966518],
            [31.890943, 53.993992],
            [31.85383, 54.00441],
            [31.8861, 54.03213],
            [31.892902, 54.053335],
            [31.871689, 54.079334],
            [31.806738, 54.092399],
            [31.79049, 54.07891],
            [31.7553, 54.07814],
            [31.768, 54.09016],
            [31.77298, 54.11316],
            [31.72731, 54.10397],
            [31.7256, 54.10887],
            [31.67264, 54.10638],
            [31.67461, 54.11839],
            [31.64586, 54.13942],
            [31.63024, 54.13376],
            [31.59419, 54.1329],
            [31.55866, 54.13968],
            [31.55325, 54.15084],
            [31.5293, 54.15144],
            [31.49248, 54.16148],
            [31.49943, 54.17831],
            [31.47952, 54.18062],
            [31.43223, 54.20826],
            [31.37901, 54.21306],
            [31.38288, 54.23246],
            [31.33644, 54.24877],
            [31.31352, 54.24851],
            [31.31044, 54.2825],
            [31.32039, 54.29589],
            [31.31258, 54.31623],
            [31.2858, 54.31967],
            [31.3185, 54.33761],
            [31.30117, 54.36816],
            [31.24924, 54.37803],
            [31.26872, 54.40052],
            [31.25095, 54.42618],
            [31.23542, 54.43434],
            [31.23259, 54.46627],
            [31.20495, 54.46678],
            [31.18847, 54.47983],
            [31.16186, 54.47571],
            [31.13766, 54.48901],
            [31.10873, 54.49167],
            [31.0847, 54.51012],
            [31.09706, 54.51588],
            [31.09835, 54.53218],
            [31.12968, 54.54523],
            [31.13182, 54.56248],
            [31.14504, 54.58102],
            [31.1695, 54.59029],
            [31.20624, 54.62634],
            [31.2004, 54.65003],
            [31.1768, 54.66814],
            [31.133082, 54.664729],
            [31.1059, 54.66762],
            [31.09895, 54.66144],
            [31.06093, 54.66162],
            [31.02762, 54.67526],
            [30.991364, 54.672783],
            [30.98634, 54.68822],
            [31.00299, 54.70178],
            [31.01852, 54.70384],
            [31.00668, 54.72118],
            [30.97612, 54.71397],
            [30.95364, 54.73586],
            [30.91218, 54.75603],
            [30.88274, 54.75912],
            [30.8321, 54.78873],
            [30.75451, 54.80504],
            [30.75837, 54.82323],
            [30.79193, 54.87456],
            [30.8218, 54.8798],
            [30.81416, 54.89387],
            [30.83476, 54.90752],
            [30.81871, 54.94022],
            [30.83236, 54.94589],
            [30.938604, 54.967566],
            [30.957004, 54.990446],
            [30.94302, 55.019777],
            [30.993879, 55.030681],
            [31.010015, 55.057728],
            [30.988901, 55.080138],
            [30.986841, 55.109115],
            [30.969465, 55.1776],
            [30.958731, 55.194167],
            [30.923523, 55.20066],
            [30.918634, 55.228739],
            [30.905285, 55.240351],
            [30.90856, 55.251811],
            [30.881212, 55.265163],
            [30.87562, 55.28054],
            [30.82309, 55.279],
            [30.818925, 55.316217],
            [30.83059, 55.334176],
            [30.874169, 55.350933],
            [30.900157, 55.374874],
            [30.92471, 55.37933],
            [30.93467, 55.39985],
            [30.90454, 55.44465],
            [30.91192, 55.45975],
            [30.89244, 55.47684],
            [30.91819, 55.47684],
            [30.95904, 55.50104],
            [30.91536, 55.51992],
            [30.91364, 55.53194],
            [30.95501, 55.54241],
            [30.94866, 55.56816],
            [30.93527, 55.58687],
            [30.931557, 55.623159],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5476",
      properties: {
        name: "Ставропольский край",
        density: 0,
        path: "/world/Russia/Ставропольский край",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.160898, 45.951688],
            [41.393082, 45.952307],
            [41.394203, 45.997123],
            [41.47142, 45.997886],
            [41.471448, 46.005994],
            [41.496249, 46.006201],
            [41.495071, 45.995079],
            [41.541262, 45.985923],
            [41.666927, 45.974902],
            [41.690649, 45.982404],
            [41.71441, 45.982428],
            [41.804885, 45.978374],
            [41.902802, 45.967967],
            [41.924894, 45.974231],
            [42.083464, 45.96571],
            [42.08057, 45.930811],
            [42.151814, 45.941748],
            [42.163068, 46.039045],
            [42.176577, 46.039367],
            [42.180275, 46.071371],
            [42.167571, 46.069923],
            [42.170947, 46.111897],
            [42.31472, 46.13554],
            [42.329676, 46.135219],
            [42.319547, 46.04162],
            [42.319871, 45.968447],
            [42.485676, 45.996271],
            [42.554668, 46.006404],
            [42.6042, 46.039855],
            [42.667402, 46.086494],
            [42.737359, 46.09132],
            [42.751671, 46.099683],
            [42.862475, 46.176396],
            [42.84784, 46.187975],
            [42.870676, 46.207113],
            [42.880004, 46.20647],
            [42.913775, 46.236222],
            [42.931787, 46.236062],
            [42.961862, 46.1965],
            [43.001746, 46.169322],
            [43.033267, 46.163693],
            [43.069935, 46.151471],
            [43.164337, 46.128797],
            [43.218374, 46.112233],
            [43.257453, 46.110464],
            [43.281737, 46.104032],
            [43.310364, 46.08988],
            [43.359737, 46.03311],
            [43.404768, 46.017029],
            [43.46974, 45.988886],
            [43.511875, 45.989208],
            [43.570093, 45.979077],
            [43.598558, 45.986797],
            [43.603865, 45.978917],
            [43.619304, 45.990496],
            [43.638442, 45.980847],
            [43.677199, 45.98326],
            [43.691834, 45.979239],
            [43.723194, 45.982617],
            [43.772567, 45.973772],
            [43.810038, 45.956404],
            [43.847027, 45.95737],
            [43.893183, 45.944183],
            [43.91811, 45.944987],
            [43.934515, 45.897545],
            [43.982762, 45.890148],
            [44.016856, 45.87085],
            [44.035512, 45.849461],
            [44.042107, 45.819548],
            [44.059315, 45.804431],
            [44.091158, 45.796391],
            [44.088585, 45.78642],
            [44.105955, 45.738173],
            [44.121555, 45.731097],
            [44.123003, 45.705848],
            [44.13812, 45.682208],
            [44.181222, 45.635409],
            [44.234615, 45.599225],
            [44.274499, 45.589255],
            [44.278037, 45.574942],
            [44.297336, 45.569313],
            [44.319047, 45.551302],
            [44.364238, 45.551945],
            [44.365846, 45.544708],
            [44.451726, 45.507077],
            [44.497721, 45.501771],
            [44.514607, 45.510455],
            [44.534066, 45.499037],
            [44.577006, 45.505149],
            [44.598395, 45.478292],
            [44.632811, 45.46414],
            [44.723676, 45.445164],
            [44.742814, 45.434872],
            [44.793312, 45.429244],
            [44.829657, 45.437607],
            [44.837377, 45.42828],
            [44.893182, 45.403514],
            [44.895113, 45.387432],
            [45.056417, 45.33388],
            [45.136346, 45.306059],
            [45.19376, 45.283866],
            [45.463781, 45.174672],
            [45.590027, 45.125784],
            [45.586328, 45.12112],
            [45.659504, 45.049073],
            [45.715953, 44.995841],
            [45.705116, 44.977363],
            [45.613474, 44.873985],
            [45.655536, 44.803692],
            [45.521916, 44.679398],
            [45.497763, 44.662426],
            [45.41233, 44.594161],
            [45.384315, 44.606814],
            [45.347507, 44.602258],
            [45.313644, 44.591911],
            [45.26072, 44.589344],
            [45.239472, 44.556773],
            [45.268123, 44.517956],
            [45.264861, 44.504327],
            [45.231117, 44.506794],
            [45.22674, 44.467518],
            [45.358414, 44.464655],
            [45.386667, 44.452081],
            [45.39773, 44.436283],
            [45.429086, 44.428803],
            [45.425904, 44.394064],
            [45.37151, 44.290144],
            [45.217672, 44.270863],
            [45.216877, 44.263939],
            [45.136672, 44.253797],
            [45.103629, 44.191674],
            [45.123923, 44.193187],
            [45.288784, 44.183778],
            [45.310073, 44.184255],
            [45.398412, 44.177014],
            [45.503624, 44.175802],
            [45.504341, 44.128966],
            [45.490175, 44.128568],
            [45.491051, 44.090725],
            [45.487471, 44.010095],
            [45.441289, 44.009733],
            [45.438681, 43.983904],
            [45.437874, 43.875638],
            [45.200171, 43.876309],
            [45.201686, 43.956407],
            [45.054674, 43.958372],
            [45.053366, 43.889072],
            [45.06581, 43.888166],
            [45.063143, 43.718963],
            [45.042455, 43.708681],
            [45.040634, 43.720418],
            [45.014124, 43.726894],
            [44.979317, 43.711311],
            [44.978306, 43.700181],
            [44.962319, 43.697145],
            [44.95645, 43.681361],
            [44.936821, 43.672456],
            [44.908895, 43.680348],
            [44.90849, 43.668206],
            [44.883397, 43.65829],
            [44.858, 43.664563],
            [44.851929, 43.673265],
            [44.861237, 43.692085],
            [44.853345, 43.695525],
            [44.83243, 43.681956],
            [44.847499, 43.704728],
            [44.845535, 43.712411],
            [44.823683, 43.70285],
            [44.80294, 43.71941],
            [44.802938, 43.804942],
            [44.789621, 43.817085],
            [44.702296, 43.817425],
            [44.703064, 43.834604],
            [44.666359, 43.835116],
            [44.666445, 43.82658],
            [44.60443, 43.82453],
            [44.596832, 43.829566],
            [44.554536, 43.813838],
            [44.507929, 43.81435],
            [44.507929, 43.807947],
            [44.472248, 43.807349],
            [44.471309, 43.801203],
            [44.378351, 43.802056],
            [44.378436, 43.814519],
            [44.353767, 43.807348],
            [44.313177, 43.805896],
            [44.291438, 43.810387],
            [44.29324, 43.89123],
            [44.324573, 43.894892],
            [44.343645, 43.90386],
            [44.344666, 43.945155],
            [44.418912, 43.945497],
            [44.419364, 44.016451],
            [44.400065, 44.008503],
            [44.369867, 44.004757],
            [44.367597, 43.973282],
            [44.319519, 43.973054],
            [44.319519, 43.96295],
            [44.269698, 43.962351],
            [44.270705, 43.917312],
            [44.226032, 43.918106],
            [44.225805, 43.926961],
            [44.14435, 43.928323],
            [44.143736, 43.970477],
            [43.9874, 43.969701],
            [43.986946, 43.985822],
            [43.945282, 43.985367],
            [43.943919, 44.008867],
            [43.920306, 44.018403],
            [43.862919, 43.987353],
            [43.857925, 43.96022],
            [43.820802, 43.958971],
            [43.784531, 43.939501],
            [43.770114, 43.926558],
            [43.770228, 43.874819],
            [43.743437, 43.821234],
            [43.731517, 43.819418],
            [43.712047, 43.830316],
            [43.655058, 43.832018],
            [43.575533, 43.849614],
            [43.545505, 43.843143],
            [43.504522, 43.860284],
            [43.47387, 43.86562],
            [43.411715, 43.858126],
            [43.400249, 43.845071],
            [43.374139, 43.838486],
            [43.349277, 43.816121],
            [43.317604, 43.799546],
            [43.275257, 43.867434],
            [43.224112, 43.958254],
            [43.189032, 43.955869],
            [43.147482, 43.958934],
            [43.144871, 43.948035],
            [43.155429, 43.938386],
            [43.140104, 43.922776],
            [43.147711, 43.895076],
            [43.101846, 43.894053],
            [43.060069, 43.899275],
            [42.970441, 43.897344],
            [42.918503, 43.865272],
            [42.865714, 43.867429],
            [42.871845, 43.84776],
            [42.854362, 43.840494],
            [42.854704, 43.801896],
            [42.824279, 43.800647],
            [42.801233, 43.807117],
            [42.763202, 43.810295],
            [42.732152, 43.819264],
            [42.678289, 43.809695],
            [42.646519, 43.827188],
            [42.639535, 43.846094],
            [42.655145, 43.856186],
            [42.628037, 43.890869],
            [42.639193, 43.89239],
            [42.640156, 43.917482],
            [42.647174, 43.916275],
            [42.660201, 43.937436],
            [42.61901, 43.945347],
            [42.627183, 43.951902],
            [42.624916, 43.973578],
            [42.579146, 43.973354],
            [42.577584, 43.997102],
            [42.584567, 44.026377],
            [42.502637, 44.026397],
            [42.48049, 44.014419],
            [42.481334, 43.980395],
            [42.495719, 43.978314],
            [42.480224, 43.966421],
            [42.424913, 43.973513],
            [42.412466, 43.978947],
            [42.376245, 43.972467],
            [42.369612, 43.989705],
            [42.379982, 43.997747],
            [42.370382, 44.004071],
            [42.358284, 44.037804],
            [42.343488, 44.02388],
            [42.303408, 44.029137],
            [42.293279, 44.038985],
            [42.298903, 44.053928],
            [42.320219, 44.064938],
            [42.324978, 44.097371],
            [42.351602, 44.114092],
            [42.335165, 44.132512],
            [42.378687, 44.153491],
            [42.378722, 44.168116],
            [42.44137, 44.168431],
            [42.440252, 44.202141],
            [42.469826, 44.19472],
            [42.481053, 44.215861],
            [42.500786, 44.214943],
            [42.50173, 44.224918],
            [42.552023, 44.223795],
            [42.553135, 44.269106],
            [42.609648, 44.269443],
            [42.608568, 44.31185],
            [42.580704, 44.320388],
            [42.581737, 44.343145],
            [42.553608, 44.352438],
            [42.553418, 44.368318],
            [42.510115, 44.368334],
            [42.494614, 44.364247],
            [42.495734, 44.350016],
            [42.478497, 44.324628],
            [42.408795, 44.325217],
            [42.408013, 44.307995],
            [42.341392, 44.310887],
            [42.298839, 44.328137],
            [42.285639, 44.326657],
            [42.237575, 44.336656],
            [42.217269, 44.347838],
            [42.120022, 44.348989],
            [42.119405, 44.352845],
            [42.029404, 44.35275],
            [42.018255, 44.37163],
            [41.977978, 44.387714],
            [41.951627, 44.393526],
            [41.9218, 44.40993],
            [41.927834, 44.438727],
            [41.92203, 44.456144],
            [41.930363, 44.469026],
            [41.926061, 44.482305],
            [41.933232, 44.496139],
            [41.86469, 44.496795],
            [41.865302, 44.486981],
            [41.807331, 44.484452],
            [41.792645, 44.470522],
            [41.736821, 44.473134],
            [41.736411, 44.483439],
            [41.703536, 44.489524],
            [41.699787, 44.499517],
            [41.651129, 44.490341],
            [41.638336, 44.525138],
            [41.589707, 44.515929],
            [41.551966, 44.618152],
            [41.527452, 44.61394],
            [41.531649, 44.602582],
            [41.494933, 44.595691],
            [41.476111, 44.647504],
            [41.456889, 44.650612],
            [41.444169, 44.66287],
            [41.427077, 44.708474],
            [41.523479, 44.727036],
            [41.519405, 44.730715],
            [41.559862, 44.738386],
            [41.555153, 44.751345],
            [41.563826, 44.76161],
            [41.578485, 44.757225],
            [41.58627, 44.772358],
            [41.594201, 44.768236],
            [41.584635, 44.794232],
            [41.615002, 44.799952],
            [41.605375, 44.825898],
            [41.620672, 44.848579],
            [41.629383, 44.847359],
            [41.638753, 44.864101],
            [41.63964, 44.905394],
            [41.652237, 44.905315],
            [41.654051, 44.986251],
            [41.456479, 44.988263],
            [41.420713, 45.030103],
            [41.40635, 45.064124],
            [41.387, 45.078511],
            [41.351444, 45.09139],
            [41.353706, 45.223072],
            [41.29749, 45.223569],
            [41.278997, 45.263847],
            [41.258531, 45.250751],
            [41.258261, 45.232849],
            [41.232885, 45.233001],
            [41.232713, 45.223985],
            [41.036139, 45.225292],
            [41.027456, 45.239273],
            [41.038357, 45.255708],
            [41.016884, 45.2634],
            [41.033672, 45.276623],
            [41.019894, 45.290748],
            [41.016011, 45.316449],
            [40.993477, 45.322803],
            [41.003106, 45.333545],
            [40.994456, 45.338962],
            [41.007196, 45.349503],
            [40.991039, 45.377283],
            [40.99532, 45.393013],
            [40.974033, 45.392539],
            [40.981617, 45.402008],
            [40.973484, 45.411534],
            [40.980407, 45.422455],
            [40.940638, 45.435855],
            [40.92249, 45.447101],
            [40.923175, 45.516862],
            [40.882291, 45.514764],
            [40.846043, 45.51692],
            [40.847968, 45.694229],
            [40.963034, 45.693733],
            [40.963457, 45.705951],
            [40.979072, 45.713609],
            [41.029051, 45.700812],
            [41.02911, 45.693199],
            [41.053256, 45.693132],
            [41.053134, 45.675002],
            [41.167237, 45.674439],
            [41.169374, 45.759651],
            [41.272136, 45.758834],
            [41.272798, 45.79481],
            [41.260045, 45.794947],
            [41.257537, 45.817223],
            [41.233466, 45.831393],
            [41.240907, 45.847836],
            [41.262804, 45.857267],
            [41.24901, 45.880051],
            [41.161177, 45.879841],
            [41.160898, 45.951688],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5480",
      properties: {
        name: "Тамбовская область",
        density: 0,
        path: "/world/Russia/Тамбовская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [40.169999, 53.312082],
            [40.212613, 53.309929],
            [40.232801, 53.341582],
            [40.249226, 53.350821],
            [40.245291, 53.336962],
            [40.267961, 53.346416],
            [40.271895, 53.376016],
            [40.300297, 53.381491],
            [40.302054, 53.361934],
            [40.335715, 53.344877],
            [40.368908, 53.341455],
            [40.381569, 53.350524],
            [40.387214, 53.369858],
            [40.402955, 53.388422],
            [40.424585, 53.387493],
            [40.409111, 53.46148],
            [40.428102, 53.475169],
            [40.421771, 53.496213],
            [40.462321, 53.483382],
            [40.474128, 53.464048],
            [40.471904, 53.444714],
            [40.504241, 53.45806],
            [40.545988, 53.466787],
            [40.541368, 53.477566],
            [40.555996, 53.492494],
            [40.584569, 53.494548],
            [40.59894, 53.507496],
            [40.635556, 53.502419],
            [40.645821, 53.511829],
            [40.641372, 53.525346],
            [40.667036, 53.542285],
            [40.679184, 53.53835],
            [40.700873, 53.555919],
            [40.756348, 53.553407],
            [40.77996, 53.547762],
            [40.77147, 53.523935],
            [40.801861, 53.518504],
            [40.814524, 53.483601],
            [40.833516, 53.461358],
            [40.852594, 53.462214],
            [40.895789, 53.504954],
            [40.956619, 53.500541],
            [41.006922, 53.491987],
            [41.042144, 53.476481],
            [41.051665, 53.452208],
            [41.039905, 53.429787],
            [41.11771, 53.424833],
            [41.144602, 53.417168],
            [41.150731, 53.427229],
            [41.187517, 53.449127],
            [41.185548, 53.472143],
            [41.211383, 53.469577],
            [41.255853, 53.472636],
            [41.261171, 53.494215],
            [41.245906, 53.515181],
            [41.280676, 53.511325],
            [41.320626, 53.524928],
            [41.353684, 53.525571],
            [41.358093, 53.595592],
            [41.364936, 53.635586],
            [41.369722, 53.744189],
            [41.363562, 53.765576],
            [41.396412, 53.76917],
            [41.405822, 53.78474],
            [41.429262, 53.775159],
            [41.454157, 53.780677],
            [41.498471, 53.772807],
            [41.523964, 53.784956],
            [41.548889, 53.811862],
            [41.580853, 53.799157],
            [41.604293, 53.800527],
            [41.607374, 53.776402],
            [41.638685, 53.775034],
            [41.65665, 53.783931],
            [41.648413, 53.800717],
            [41.673759, 53.799159],
            [41.686248, 53.811649],
            [41.752292, 53.811992],
            [41.754858, 53.805662],
            [41.819789, 53.810453],
            [41.839294, 53.819351],
            [41.868209, 53.821062],
            [41.916202, 53.811652],
            [41.951619, 53.810455],
            [41.980193, 53.802414],
            [42.028784, 53.821407],
            [42.058812, 53.803613],
            [42.144531, 53.802341],
            [42.154048, 53.77081],
            [42.106717, 53.768021],
            [42.110404, 53.75708],
            [42.081739, 53.748005],
            [42.089521, 53.708587],
            [42.143951, 53.712079],
            [42.125589, 53.69293],
            [42.117835, 53.671193],
            [42.127316, 53.652501],
            [42.121927, 53.569092],
            [42.139582, 53.567753],
            [42.109533, 53.515339],
            [42.171746, 53.532993],
            [42.158169, 53.507449],
            [42.141635, 53.499847],
            [42.15583, 53.49358],
            [42.197589, 53.496055],
            [42.225196, 53.489027],
            [42.227419, 53.479705],
            [42.255598, 53.484075],
            [42.25675, 53.470193],
            [42.271082, 53.45824],
            [42.295901, 53.452083],
            [42.299252, 53.432534],
            [42.285082, 53.428585],
            [42.273684, 53.412219],
            [42.259801, 53.414553],
            [42.259682, 53.3982],
            [42.246815, 53.392787],
            [42.240536, 53.357158],
            [42.272662, 53.373222],
            [42.28219, 53.364336],
            [42.291964, 53.372906],
            [42.323499, 53.347962],
            [42.379592, 53.327366],
            [42.412166, 53.33802],
            [42.419006, 53.287923],
            [42.43218, 53.283097],
            [42.420968, 53.271701],
            [42.494068, 53.241357],
            [42.557809, 53.195599],
            [42.571364, 53.179832],
            [42.509028, 53.17019],
            [42.526065, 53.144476],
            [42.544187, 53.135306],
            [42.547553, 53.122359],
            [42.627608, 53.128283],
            [42.64225, 53.111768],
            [42.708381, 53.060523],
            [42.754868, 53.018262],
            [42.810405, 52.98805],
            [42.93265, 52.942773],
            [42.957555, 52.930946],
            [42.954222, 52.911944],
            [42.980534, 52.89314],
            [42.990725, 52.89326],
            [43.009742, 52.870166],
            [43.10569, 52.803579],
            [43.11827, 52.803298],
            [43.193604, 52.717261],
            [43.248087, 52.63634],
            [43.220343, 52.636794],
            [43.11228, 52.570038],
            [43.08806, 52.574568],
            [43.083488, 52.566516],
            [43.058242, 52.559391],
            [43.04917, 52.530761],
            [43.061915, 52.517591],
            [43.121771, 52.510893],
            [43.057376, 52.49191],
            [43.024048, 52.486763],
            [43.048908, 52.440327],
            [43.06728, 52.43095],
            [43.081329, 52.404693],
            [43.066872, 52.402483],
            [43.072117, 52.391566],
            [43.038936, 52.3879],
            [43.039706, 52.370635],
            [43.032022, 52.358105],
            [42.988102, 52.348922],
            [42.918202, 52.360338],
            [42.941326, 52.3358],
            [42.92259, 52.290899],
            [42.89502, 52.259393],
            [42.887111, 52.239204],
            [42.888997, 52.213045],
            [42.85259, 52.210761],
            [42.84362, 52.200376],
            [42.823214, 52.200806],
            [42.790335, 52.183798],
            [42.771364, 52.183776],
            [42.775005, 52.168904],
            [42.767203, 52.155121],
            [42.760329, 52.111908],
            [42.761829, 52.082103],
            [42.785914, 52.071813],
            [42.77705, 52.062591],
            [42.77699, 52.041668],
            [42.791586, 52.039823],
            [42.797904, 52.024283],
            [42.820193, 52.022105],
            [42.810973, 52.014429],
            [42.762415, 52.017934],
            [42.72749, 52.006383],
            [42.727406, 51.992303],
            [42.715504, 51.987425],
            [42.724336, 51.965108],
            [42.708677, 51.948355],
            [42.712606, 51.88562],
            [42.682054, 51.84889],
            [42.665625, 51.809889],
            [42.649988, 51.790984],
            [42.523268, 51.721259],
            [42.515436, 51.712054],
            [42.50393, 51.659493],
            [42.517694, 51.625964],
            [42.520795, 51.596072],
            [42.498911, 51.593099],
            [42.482818, 51.631369],
            [42.425604, 51.623934],
            [42.379097, 51.594961],
            [42.343861, 51.587718],
            [42.298351, 51.619261],
            [42.287685, 51.606183],
            [42.264695, 51.612489],
            [42.280951, 51.639731],
            [42.268707, 51.663671],
            [42.295654, 51.666029],
            [42.306212, 51.689461],
            [42.295798, 51.684837],
            [42.239473, 51.702839],
            [42.24725, 51.709222],
            [42.227836, 51.718875],
            [42.18929, 51.723581],
            [42.194129, 51.707263],
            [42.16895, 51.709637],
            [42.118229, 51.730848],
            [42.105897, 51.717496],
            [42.125145, 51.702332],
            [42.107484, 51.692872],
            [42.121703, 51.676284],
            [42.136364, 51.631887],
            [42.089973, 51.629231],
            [42.069582, 51.657448],
            [42.017594, 51.668344],
            [42.010072, 51.654292],
            [41.959807, 51.656305],
            [41.931929, 51.663495],
            [41.893655, 51.680347],
            [41.839034, 51.674523],
            [41.842268, 51.69425],
            [41.855538, 51.693661],
            [41.856193, 51.711119],
            [41.812842, 51.712249],
            [41.79207, 51.708261],
            [41.783517, 51.718146],
            [41.733194, 51.70581],
            [41.722839, 51.736204],
            [41.682758, 51.732533],
            [41.679894, 51.740783],
            [41.635909, 51.732996],
            [41.617788, 51.755218],
            [41.585224, 51.769251],
            [41.546007, 51.770497],
            [41.51318, 51.777484],
            [41.507537, 51.799297],
            [41.487444, 51.797682],
            [41.462463, 51.805777],
            [41.472619, 51.789384],
            [41.460271, 51.769938],
            [41.428666, 51.772346],
            [41.345818, 51.788681],
            [41.349144, 51.799556],
            [41.307116, 51.801948],
            [41.3029, 51.788803],
            [41.280708, 51.791969],
            [41.284019, 51.756992],
            [41.296199, 51.738301],
            [41.249755, 51.737803],
            [41.116109, 51.730632],
            [41.119121, 51.719484],
            [41.063106, 51.720509],
            [41.055265, 51.736374],
            [41.026164, 51.725908],
            [41.033768, 51.721225],
            [41.002491, 51.716182],
            [40.969403, 51.746758],
            [40.927168, 51.74022],
            [40.882178, 51.738862],
            [40.897881, 51.770844],
            [40.928766, 51.817667],
            [40.917884, 51.828317],
            [40.948615, 51.860852],
            [40.945671, 51.891089],
            [40.925488, 51.887958],
            [40.876566, 51.912412],
            [40.860278, 51.909668],
            [40.845697, 51.921982],
            [40.814335, 51.909495],
            [40.803869, 51.894734],
            [40.752964, 51.937303],
            [40.716701, 51.938909],
            [40.711674, 51.933371],
            [40.658441, 51.942379],
            [40.61489, 51.944898],
            [40.608231, 51.938629],
            [40.549422, 51.951287],
            [40.53414, 51.945692],
            [40.540536, 51.964565],
            [40.531723, 51.967316],
            [40.545245, 51.997171],
            [40.536676, 52.008933],
            [40.594375, 52.024003],
            [40.606981, 52.033557],
            [40.641422, 52.036222],
            [40.644101, 52.053124],
            [40.614188, 52.052097],
            [40.626478, 52.088901],
            [40.590303, 52.091979],
            [40.611258, 52.103932],
            [40.625935, 52.144561],
            [40.647262, 52.135595],
            [40.642537, 52.158497],
            [40.655454, 52.164534],
            [40.637032, 52.174902],
            [40.66195, 52.190266],
            [40.701571, 52.188767],
            [40.684615, 52.202932],
            [40.696304, 52.229732],
            [40.709958, 52.223105],
            [40.744072, 52.19361],
            [40.764775, 52.201607],
            [40.733335, 52.224173],
            [40.741357, 52.22519],
            [40.733435, 52.251247],
            [40.660279, 52.285884],
            [40.611198, 52.281068],
            [40.601703, 52.262345],
            [40.590336, 52.257397],
            [40.572754, 52.276909],
            [40.573082, 52.314101],
            [40.563586, 52.332022],
            [40.529884, 52.336903],
            [40.505614, 52.328857],
            [40.503468, 52.346002],
            [40.512791, 52.354903],
            [40.399375, 52.367148],
            [40.376023, 52.360889],
            [40.354691, 52.366161],
            [40.355599, 52.383839],
            [40.308935, 52.38608],
            [40.293363, 52.391964],
            [40.281917, 52.415982],
            [40.294096, 52.425315],
            [40.296317, 52.441296],
            [40.330291, 52.44204],
            [40.325873, 52.458656],
            [40.272276, 52.453063],
            [40.2507, 52.464872],
            [40.214266, 52.467058],
            [40.226615, 52.500961],
            [40.173294, 52.502199],
            [40.155118, 52.517492],
            [40.145986, 52.534698],
            [40.124275, 52.551432],
            [40.114642, 52.540056],
            [40.063483, 52.562392],
            [40.046364, 52.545786],
            [40.019627, 52.564738],
            [40.043619, 52.589945],
            [39.976678, 52.596802],
            [39.971531, 52.624179],
            [39.949503, 52.63353],
            [39.955811, 52.650025],
            [39.945499, 52.657239],
            [39.960238, 52.668856],
            [39.982138, 52.655944],
            [39.987347, 52.677187],
            [39.931449, 52.699224],
            [39.938758, 52.708237],
            [39.915694, 52.708851],
            [39.940488, 52.734094],
            [39.960368, 52.727158],
            [39.97205, 52.732356],
            [39.960682, 52.7468],
            [39.97932, 52.753208],
            [39.990104, 52.739311],
            [40.02394, 52.737974],
            [40.050286, 52.751214],
            [40.023805, 52.772478],
            [40.051221, 52.770874],
            [40.058042, 52.752782],
            [40.083271, 52.754594],
            [40.064398, 52.784916],
            [40.090443, 52.783203],
            [40.094343, 52.814679],
            [40.092676, 52.858606],
            [40.095751, 52.879469],
            [40.037174, 52.894046],
            [40.037574, 52.912903],
            [40.072345, 52.924672],
            [40.078155, 52.946872],
            [40.122762, 52.939562],
            [40.124769, 52.957037],
            [40.114337, 52.959979],
            [40.12303, 52.977098],
            [40.116075, 52.989535],
            [40.074883, 53.004513],
            [40.125435, 53.022167],
            [40.155331, 53.046237],
            [40.133309, 53.111383],
            [40.139899, 53.137839],
            [40.120603, 53.144519],
            [40.125995, 53.173678],
            [40.108309, 53.211004],
            [40.087045, 53.213545],
            [40.103159, 53.221962],
            [40.095531, 53.275049],
            [40.113255, 53.275599],
            [40.123552, 53.294858],
            [40.152171, 53.295393],
            [40.157788, 53.312512],
            [40.169999, 53.312082],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5500",
      properties: {
        name: "Татарстан",
        density: 0,
        path: "/world/Russia/Татарстан",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [47.294091, 54.847865],
              [47.325065, 54.852519],
              [47.322108, 54.842708],
              [47.294091, 54.847865],
            ],
          ],
          [
            [
              [48.406167, 55.825699],
              [48.459321, 55.825284],
              [48.452569, 55.834095],
              [48.425891, 55.834241],
              [48.401626, 55.845173],
              [48.40565, 55.856962],
              [48.383295, 55.867481],
              [48.415802, 55.91449],
              [48.474072, 55.93618],
              [48.521926, 55.931532],
              [48.577594, 55.945224],
              [48.626829, 55.94475],
              [48.666195, 55.938391],
              [48.668108, 55.946123],
              [48.695051, 55.954844],
              [48.703817, 55.994769],
              [48.726219, 56.013638],
              [48.712245, 56.013791],
              [48.677612, 56.032797],
              [48.711319, 56.040409],
              [48.714258, 56.048323],
              [48.691852, 56.059126],
              [48.717585, 56.0711],
              [48.716533, 56.059498],
              [48.738772, 56.052527],
              [48.744356, 56.039351],
              [48.777082, 56.057728],
              [48.829928, 56.050319],
              [48.835911, 56.066487],
              [48.829949, 56.080957],
              [48.845125, 56.094245],
              [48.848632, 56.115987],
              [48.894516, 56.133043],
              [48.929196, 56.13202],
              [48.9477, 56.139046],
              [48.953969, 56.160633],
              [48.980718, 56.162363],
              [48.984553, 56.200543],
              [49.009971, 56.204535],
              [49.043896, 56.188095],
              [49.069652, 56.206555],
              [49.04553, 56.215833],
              [49.02417, 56.235341],
              [49.009691, 56.222237],
              [48.937537, 56.225379],
              [48.922421, 56.256181],
              [48.973401, 56.275231],
              [49.031806, 56.284657],
              [49.03177, 56.308431],
              [49.055362, 56.321641],
              [49.041116, 56.325022],
              [49.051564, 56.34479],
              [49.079259, 56.345993],
              [49.080153, 56.334948],
              [49.10932, 56.346635],
              [49.114449, 56.369224],
              [49.133862, 56.356452],
              [49.191993, 56.365539],
              [49.170712, 56.369392],
              [49.167041, 56.390303],
              [49.21585, 56.390075],
              [49.251641, 56.361108],
              [49.252924, 56.35005],
              [49.28468, 56.346146],
              [49.312777, 56.332456],
              [49.334157, 56.329954],
              [49.34248, 56.343216],
              [49.331366, 56.354366],
              [49.339344, 56.366506],
              [49.359371, 56.363939],
              [49.381475, 56.349293],
              [49.394666, 56.3561],
              [49.409294, 56.397521],
              [49.400395, 56.410163],
              [49.409455, 56.450295],
              [49.435759, 56.447522],
              [49.441279, 56.468774],
              [49.42199, 56.462872],
              [49.417629, 56.473483],
              [49.435871, 56.476933],
              [49.451117, 56.467959],
              [49.439946, 56.440824],
              [49.455574, 56.425532],
              [49.506447, 56.421256],
              [49.549388, 56.43168],
              [49.565345, 56.459197],
              [49.581551, 56.45921],
              [49.578909, 56.478829],
              [49.567596, 56.487324],
              [49.569368, 56.502881],
              [49.611684, 56.502179],
              [49.618808, 56.515194],
              [49.664696, 56.527425],
              [49.684931, 56.51541],
              [49.707403, 56.517412],
              [49.73262, 56.531461],
              [49.756333, 56.537227],
              [49.777231, 56.531537],
              [49.813884, 56.501165],
              [49.806064, 56.489335],
              [49.750548, 56.46291],
              [49.753835, 56.454004],
              [49.78581, 56.448073],
              [49.803606, 56.437279],
              [49.86106, 56.425313],
              [49.885751, 56.447704],
              [49.89751, 56.447866],
              [49.919405, 56.467339],
              [49.903985, 56.476443],
              [49.933392, 56.496858],
              [49.987235, 56.494623],
              [49.977481, 56.469913],
              [49.967272, 56.463642],
              [49.928538, 56.458441],
              [49.946482, 56.428925],
              [49.970484, 56.431381],
              [50.037929, 56.458014],
              [50.039934, 56.485033],
              [50.05647, 56.497223],
              [50.08303, 56.505663],
              [50.089445, 56.516607],
              [50.119262, 56.517108],
              [50.160043, 56.530607],
              [50.201345, 56.557124],
              [50.192858, 56.572799],
              [50.202985, 56.583254],
              [50.169562, 56.592931],
              [50.053581, 56.599679],
              [50.061857, 56.61314],
              [50.081423, 56.611177],
              [50.149412, 56.604106],
              [50.158947, 56.631894],
              [50.174968, 56.634704],
              [50.172819, 56.650296],
              [50.223631, 56.652051],
              [50.241929, 56.628118],
              [50.257675, 56.63273],
              [50.299205, 56.632284],
              [50.314647, 56.641663],
              [50.318158, 56.658599],
              [50.30213, 56.666005],
              [50.30761, 56.675063],
              [50.345692, 56.674661],
              [50.370963, 56.656227],
              [50.384279, 56.635619],
              [50.430506, 56.608573],
              [50.416298, 56.602811],
              [50.410307, 56.575783],
              [50.448577, 56.588409],
              [50.452537, 56.611329],
              [50.491241, 56.60972],
              [50.473809, 56.583356],
              [50.453373, 56.572091],
              [50.438251, 56.550561],
              [50.464257, 56.544342],
              [50.534738, 56.506487],
              [50.501001, 56.496648],
              [50.489004, 56.480112],
              [50.499089, 56.476043],
              [50.547752, 56.408043],
              [50.571828, 56.393444],
              [50.594796, 56.392785],
              [50.625159, 56.409722],
              [50.639824, 56.404603],
              [50.617509, 56.377046],
              [50.577631, 56.358683],
              [50.579791, 56.349373],
              [50.615689, 56.354768],
              [50.63102, 56.338337],
              [50.747378, 56.318536],
              [50.742046, 56.312816],
              [50.801149, 56.340792],
              [50.827078, 56.347222],
              [50.741588, 56.409196],
              [50.756164, 56.410003],
              [50.82413, 56.397554],
              [50.883545, 56.371771],
              [50.85389, 56.355091],
              [50.888398, 56.307768],
              [50.913143, 56.30763],
              [50.917326, 56.278355],
              [50.895228, 56.275833],
              [50.871451, 56.254609],
              [50.832155, 56.240606],
              [50.846866, 56.227419],
              [50.868764, 56.223568],
              [50.875488, 56.196212],
              [50.9341, 56.192794],
              [51.002717, 56.203645],
              [50.984416, 56.183285],
              [50.984957, 56.153971],
              [51.032482, 56.133667],
              [51.066751, 56.131697],
              [51.058532, 56.112816],
              [51.091675, 56.116499],
              [51.091951, 56.134043],
              [51.119748, 56.130601],
              [51.129677, 56.136729],
              [51.145957, 56.124348],
              [51.207984, 56.123117],
              [51.218075, 56.084197],
              [51.254669, 56.079697],
              [51.28663, 56.083867],
              [51.308844, 56.078253],
              [51.320319, 56.060762],
              [51.345599, 56.068375],
              [51.346405, 56.086189],
              [51.40249, 56.11608],
              [51.444008, 56.127376],
              [51.509884, 56.121719],
              [51.53238, 56.122968],
              [51.550112, 56.138897],
              [51.574024, 56.140989],
              [51.598465, 56.126804],
              [51.64003, 56.135744],
              [51.645638, 56.110942],
              [51.635028, 56.090631],
              [51.55981, 56.081583],
              [51.546785, 56.082977],
              [51.521653, 56.069175],
              [51.489833, 56.068076],
              [51.474682, 56.047718],
              [51.43802, 56.034941],
              [51.417707, 56.042377],
              [51.397933, 56.029644],
              [51.413689, 55.991091],
              [51.409258, 55.974761],
              [51.417425, 55.960242],
              [51.456185, 55.922552],
              [51.470138, 55.954518],
              [51.490616, 55.96021],
              [51.525675, 55.953806],
              [51.556888, 55.962509],
              [51.556819, 55.97704],
              [51.589638, 55.954596],
              [51.60178, 55.95274],
              [51.619682, 55.965075],
              [51.647675, 55.971199],
              [51.675836, 55.952894],
              [51.665684, 55.944045],
              [51.708453, 55.94135],
              [51.711647, 55.973889],
              [51.742772, 55.981557],
              [51.761958, 55.968354],
              [51.757614, 55.954091],
              [51.737503, 55.961703],
              [51.711387, 55.941683],
              [51.720575, 55.932587],
              [51.748067, 55.941209],
              [51.781854, 55.93915],
              [51.76259, 55.922068],
              [51.76996, 55.890978],
              [51.78097, 55.886564],
              [51.812961, 55.927019],
              [51.842545, 55.936216],
              [51.854731, 55.946404],
              [51.884322, 55.93559],
              [51.929855, 55.951743],
              [51.941709, 55.961396],
              [51.952489, 55.989862],
              [51.979359, 55.988097],
              [51.979726, 55.976516],
              [52.023252, 55.969476],
              [52.080038, 55.925803],
              [52.055813, 55.918285],
              [52.056168, 55.907914],
              [52.088767, 55.900923],
              [52.123099, 55.905977],
              [52.156222, 55.904398],
              [52.158165, 55.921706],
              [52.188899, 55.923855],
              [52.198673, 55.936234],
              [52.179353, 55.950341],
              [52.234172, 55.947442],
              [52.24645, 55.967358],
              [52.237303, 55.978344],
              [52.209809, 55.980011],
              [52.206527, 56.020273],
              [52.21898, 56.02117],
              [52.233787, 56.040538],
              [52.258377, 56.034022],
              [52.254252, 56.049764],
              [52.236012, 56.064693],
              [52.224067, 56.086477],
              [52.211522, 56.091916],
              [52.242984, 56.098086],
              [52.267872, 56.069554],
              [52.290685, 56.059605],
              [52.319793, 56.072618],
              [52.34035, 56.060377],
              [52.353467, 56.034136],
              [52.379718, 56.019136],
              [52.415712, 56.036019],
              [52.436406, 56.027056],
              [52.447253, 56.008992],
              [52.499403, 56.032898],
              [52.427004, 56.058084],
              [52.456877, 56.07668],
              [52.45639, 56.082527],
              [52.499964, 56.056767],
              [52.531095, 56.060764],
              [52.558678, 56.035469],
              [52.61411, 56.032784],
              [52.628875, 56.015861],
              [52.668531, 56.007757],
              [52.68173, 55.993474],
              [52.699262, 55.999127],
              [52.723577, 55.988757],
              [52.756727, 55.989558],
              [52.735892, 56.006141],
              [52.747544, 56.037911],
              [52.745429, 56.057345],
              [52.756607, 56.06278],
              [52.728629, 56.08449],
              [52.744853, 56.0958],
              [52.71344, 56.102675],
              [52.724457, 56.113917],
              [52.712548, 56.130197],
              [52.747125, 56.125027],
              [52.745329, 56.10867],
              [52.763591, 56.110176],
              [52.787868, 56.100431],
              [52.811105, 56.082712],
              [52.845043, 56.080128],
              [52.869305, 56.095332],
              [52.880856, 56.118692],
              [52.87093, 56.115299],
              [52.821581, 56.118469],
              [52.837809, 56.137656],
              [52.874704, 56.150318],
              [52.882179, 56.159847],
              [52.835969, 56.167341],
              [52.827525, 56.177577],
              [52.84695, 56.187453],
              [52.75734, 56.200357],
              [52.737629, 56.221247],
              [52.665335, 56.221115],
              [52.652181, 56.229228],
              [52.626649, 56.223272],
              [52.584101, 56.222258],
              [52.55531, 56.242863],
              [52.602532, 56.279317],
              [52.620556, 56.282559],
              [52.639879, 56.270332],
              [52.658589, 56.281619],
              [52.646999, 56.293285],
              [52.677948, 56.310971],
              [52.697375, 56.331041],
              [52.722382, 56.325878],
              [52.733103, 56.361814],
              [52.762962, 56.359201],
              [52.766035, 56.374185],
              [52.748253, 56.382961],
              [52.781037, 56.379893],
              [52.777968, 56.397833],
              [52.812366, 56.397297],
              [52.8294, 56.378934],
              [52.870576, 56.389022],
              [52.872482, 56.399519],
              [52.924081, 56.400796],
              [52.938057, 56.416641],
              [52.943366, 56.436432],
              [52.963986, 56.42843],
              [52.969235, 56.459967],
              [52.952423, 56.45699],
              [52.931649, 56.464146],
              [52.935532, 56.484669],
              [52.896021, 56.483823],
              [52.889844, 56.500476],
              [52.920685, 56.500049],
              [52.935059, 56.490724],
              [52.967203, 56.520349],
              [52.945549, 56.538455],
              [53.025507, 56.531099],
              [53.033488, 56.543349],
              [53.05412, 56.540045],
              [53.036356, 56.53203],
              [53.069569, 56.533292],
              [53.093198, 56.52073],
              [53.10649, 56.528384],
              [53.144828, 56.524698],
              [53.130582, 56.517565],
              [53.129737, 56.50367],
              [53.087018, 56.511086],
              [53.098825, 56.500869],
              [53.098269, 56.476767],
              [53.087366, 56.453921],
              [53.024051, 56.446744],
              [53.006075, 56.43261],
              [53.000433, 56.392734],
              [52.98174, 56.388401],
              [52.98911, 56.36614],
              [52.963672, 56.355202],
              [52.973163, 56.344621],
              [52.95852, 56.336327],
              [52.986722, 56.326079],
              [52.945273, 56.282013],
              [52.990802, 56.28578],
              [52.99797, 56.258809],
              [52.980404, 56.281354],
              [52.96874, 56.279893],
              [52.965534, 56.256086],
              [52.974289, 56.223665],
              [52.987318, 56.210257],
              [53.009818, 56.215781],
              [53.021486, 56.202806],
              [53.003819, 56.200097],
              [52.999291, 56.177529],
              [53.042938, 56.170669],
              [53.02636, 56.196433],
              [53.050152, 56.208909],
              [53.025171, 56.217672],
              [53.041467, 56.224384],
              [53.065107, 56.21165],
              [53.048155, 56.197086],
              [53.045933, 56.183214],
              [53.06336, 56.169992],
              [53.066614, 56.177938],
              [53.08886, 56.174949],
              [53.059643, 56.149255],
              [53.064148, 56.129808],
              [53.101508, 56.114682],
              [53.10837, 56.122272],
              [53.088002, 56.14118],
              [53.1122, 56.145573],
              [53.141962, 56.161147],
              [53.155232, 56.158943],
              [53.193653, 56.103465],
              [53.22271, 56.090752],
              [53.263174, 56.107618],
              [53.263666, 56.09115],
              [53.288165, 56.072028],
              [53.313467, 56.071621],
              [53.33283, 56.062106],
              [53.366844, 56.071078],
              [53.364057, 56.089622],
              [53.383501, 56.090312],
              [53.370341, 56.102424],
              [53.34711, 56.109331],
              [53.363895, 56.129358],
              [53.350944, 56.165816],
              [53.315115, 56.216211],
              [53.289595, 56.230456],
              [53.233604, 56.239378],
              [53.273262, 56.253513],
              [53.306748, 56.250358],
              [53.385672, 56.265059],
              [53.400229, 56.263044],
              [53.426542, 56.275065],
              [53.447116, 56.271196],
              [53.454996, 56.257194],
              [53.48834, 56.234566],
              [53.531333, 56.238731],
              [53.575332, 56.236981],
              [53.559882, 56.215635],
              [53.552748, 56.191886],
              [53.574829, 56.180838],
              [53.580648, 56.146272],
              [53.56423, 56.138877],
              [53.568052, 56.125731],
              [53.551724, 56.118149],
              [53.523714, 56.117237],
              [53.500013, 56.133511],
              [53.480002, 56.101205],
              [53.440649, 56.064614],
              [53.441672, 56.049658],
              [53.475272, 56.039445],
              [53.451607, 56.018596],
              [53.403354, 56.007025],
              [53.365395, 56.01673],
              [53.359959, 56.005378],
              [53.392794, 56.000337],
              [53.387464, 55.991433],
              [53.340592, 55.978752],
              [53.326742, 55.987505],
              [53.295859, 55.947775],
              [53.257555, 55.93497],
              [53.219134, 55.940775],
              [53.200002, 55.926013],
              [53.22217, 55.895562],
              [53.250417, 55.896559],
              [53.25308, 55.884487],
              [53.240618, 55.872167],
              [53.271711, 55.859569],
              [53.348052, 55.855303],
              [53.413967, 55.858484],
              [53.436986, 55.875152],
              [53.478997, 55.87671],
              [53.507646, 55.88672],
              [53.532241, 55.906764],
              [53.557521, 55.912137],
              [53.579757, 55.90215],
              [53.623473, 55.908909],
              [53.624322, 55.914551],
              [53.682536, 55.916414],
              [53.697341, 55.925082],
              [53.719374, 55.924025],
              [53.708493, 55.908907],
              [53.710224, 55.893027],
              [53.72827, 55.885983],
              [53.741982, 55.903634],
              [53.760793, 55.908521],
              [53.784106, 55.895104],
              [53.808252, 55.897436],
              [53.825437, 55.891485],
              [53.838584, 55.870704],
              [53.854127, 55.869071],
              [53.856336, 55.882445],
              [53.879525, 55.886982],
              [53.893719, 55.874328],
              [53.88881, 55.859657],
              [53.856133, 55.849799],
              [53.938073, 55.846524],
              [53.99175, 55.847449],
              [53.94242, 55.813033],
              [53.952013, 55.790528],
              [53.9707, 55.7848],
              [54, 55.7972],
              [54.0237, 55.7819],
              [54.106772, 55.774039],
              [54.129927, 55.780451],
              [54.116207, 55.763783],
              [54.127829, 55.749207],
              [54.162807, 55.739211],
              [54.171663, 55.716081],
              [54.208897, 55.702633],
              [54.234018, 55.706363],
              [54.255689, 55.720834],
              [54.265888, 55.704039],
              [54.240793, 55.676896],
              [54.198257, 55.660818],
              [54.203851, 55.642312],
              [54.175246, 55.634957],
              [54.182916, 55.622447],
              [54.141629, 55.609011],
              [54.107653, 55.583319],
              [54.07906, 55.586582],
              [54.071294, 55.572625],
              [54.031063, 55.559244],
              [54.042572, 55.546077],
              [54.023434, 55.538432],
              [54.004173, 55.550763],
              [53.982886, 55.52979],
              [53.966076, 55.529181],
              [53.978448, 55.482801],
              [53.958814, 55.482991],
              [53.978624, 55.46587],
              [53.926926, 55.455099],
              [53.927291, 55.444216],
              [53.902602, 55.42843],
              [53.900854, 55.40744],
              [53.8752, 55.3978],
              [53.8874, 55.3912],
              [53.8827, 55.3707],
              [53.8541, 55.3668],
              [53.786, 55.3415],
              [53.7771, 55.3497],
              [53.74, 55.3371],
              [53.717, 55.3232],
              [53.7001, 55.3308],
              [53.685995, 55.318991],
              [53.686497, 55.301295],
              [53.673076, 55.288411],
              [53.602269, 55.262642],
              [53.627864, 55.227383],
              [53.616626, 55.214323],
              [53.516353, 55.213979],
              [53.482911, 55.216589],
              [53.466611, 55.228466],
              [53.426972, 55.230037],
              [53.362239, 55.202065],
              [53.398238, 55.191883],
              [53.378185, 55.17562],
              [53.368441, 55.179223],
              [53.318673, 55.16222],
              [53.283468, 55.171541],
              [53.241394, 55.17459],
              [53.207989, 55.158837],
              [53.158294, 55.165716],
              [53.161102, 55.114022],
              [53.170297, 55.1052],
              [53.250295, 55.10447],
              [53.264145, 55.099769],
              [53.269498, 55.078052],
              [53.294045, 55.070675],
              [53.269505, 55.048659],
              [53.276383, 55.040976],
              [53.298459, 55.046311],
              [53.319335, 55.043372],
              [53.321526, 55.021277],
              [53.371849, 55.025013],
              [53.431194, 54.993997],
              [53.44267, 54.994621],
              [53.47511, 54.978258],
              [53.482486, 54.961686],
              [53.476099, 54.950344],
              [53.51806, 54.924777],
              [53.507682, 54.914218],
              [53.5535, 54.8881],
              [53.553362, 54.878503],
              [53.588445, 54.885896],
              [53.617124, 54.906188],
              [53.648184, 54.908455],
              [53.636504, 54.899498],
              [53.637366, 54.880269],
              [53.622774, 54.878513],
              [53.593066, 54.809682],
              [53.610457, 54.810367],
              [53.6063, 54.788],
              [53.6192, 54.7474],
              [53.6125, 54.7395],
              [53.6149, 54.7044],
              [53.6097, 54.6955],
              [53.5693, 54.6705],
              [53.571059, 54.640495],
              [53.546874, 54.631265],
              [53.531842, 54.616781],
              [53.511976, 54.617107],
              [53.490357, 54.60187],
              [53.489398, 54.592603],
              [53.466894, 54.588953],
              [53.47038, 54.571764],
              [53.459133, 54.565256],
              [53.437896, 54.571828],
              [53.422711, 54.564303],
              [53.434379, 54.550383],
              [53.426929, 54.507159],
              [53.437452, 54.496616],
              [53.434387, 54.479517],
              [53.415256, 54.470263],
              [53.424189, 54.455364],
              [53.404029, 54.449074],
              [53.417046, 54.435317],
              [53.386261, 54.41684],
              [53.404462, 54.397053],
              [53.398792, 54.382388],
              [53.381851, 54.375212],
              [53.419355, 54.360619],
              [53.399845, 54.350121],
              [53.432359, 54.327856],
              [53.419313, 54.30742],
              [53.4408, 54.2601],
              [53.4192, 54.2581],
              [53.4367, 54.2416],
              [53.4239, 54.2336],
              [53.4356, 54.2235],
              [53.4366, 54.1875],
              [53.4571, 54.1718],
              [53.4545, 54.1539],
              [53.4706, 54.1158],
              [53.4722, 54.0917],
              [53.500606, 54.056129],
              [53.445952, 54.043073],
              [53.414488, 54.030531],
              [53.404099, 53.997322],
              [53.36886, 53.974244],
              [53.346783, 54.001296],
              [53.321745, 54.017714],
              [53.30719, 54.016889],
              [53.282151, 54.037567],
              [53.290863, 54.076162],
              [53.287181, 54.087859],
              [53.225771, 54.087275],
              [53.228191, 54.096755],
              [53.254303, 54.098034],
              [53.236603, 54.113463],
              [53.199975, 54.111576],
              [53.198409, 54.096781],
              [53.171209, 54.084727],
              [53.133062, 54.076905],
              [53.092525, 54.079139],
              [53.062389, 54.065377],
              [53.058766, 54.082654],
              [53.083714, 54.092348],
              [53.096854, 54.125289],
              [53.044023, 54.131547],
              [53.046302, 54.138575],
              [53.025076, 54.16898],
              [52.974662, 54.179371],
              [52.962821, 54.191088],
              [52.970985, 54.205598],
              [52.950733, 54.212322],
              [52.954906, 54.235049],
              [53.004273, 54.237965],
              [53.027078, 54.220662],
              [53.074925, 54.221105],
              [53.044416, 54.245803],
              [53.097554, 54.23443],
              [53.130439, 54.24234],
              [53.143489, 54.251756],
              [53.116426, 54.267219],
              [53.15518, 54.288275],
              [53.124611, 54.301575],
              [53.115194, 54.311969],
              [53.081858, 54.317485],
              [53.056297, 54.352738],
              [53.011303, 54.333979],
              [53.016833, 54.322738],
              [52.990646, 54.318612],
              [53.025673, 54.303075],
              [53.029466, 54.291641],
              [52.978842, 54.286455],
              [52.98129, 54.268161],
              [52.945415, 54.268671],
              [52.935902, 54.277177],
              [52.922449, 54.268675],
              [52.890558, 54.276983],
              [52.904269, 54.290593],
              [52.927788, 54.292028],
              [52.952239, 54.306951],
              [52.934188, 54.308426],
              [52.917656, 54.320039],
              [52.897121, 54.317069],
              [52.877998, 54.325628],
              [52.84457, 54.313596],
              [52.8053, 54.335002],
              [52.755502, 54.349043],
              [52.696425, 54.360757],
              [52.678207, 54.357831],
              [52.638066, 54.338272],
              [52.622826, 54.356098],
              [52.602655, 54.365607],
              [52.573179, 54.349483],
              [52.568276, 54.340128],
              [52.506097, 54.373703],
              [52.489178, 54.39007],
              [52.501824, 54.391239],
              [52.504034, 54.437537],
              [52.484837, 54.436125],
              [52.502117, 54.459062],
              [52.486742, 54.4666],
              [52.43596, 54.464314],
              [52.431969, 54.474165],
              [52.388318, 54.446251],
              [52.397094, 54.412728],
              [52.367222, 54.398661],
              [52.343376, 54.439081],
              [52.298205, 54.447029],
              [52.293818, 54.434834],
              [52.242889, 54.439682],
              [52.188959, 54.41785],
              [52.198538, 54.406983],
              [52.182523, 54.391691],
              [52.177387, 54.375267],
              [52.147482, 54.363056],
              [52.126066, 54.373401],
              [52.11787, 54.391593],
              [52.09553, 54.391193],
              [52.104233, 54.381598],
              [52.101115, 54.364172],
              [52.116466, 54.361239],
              [52.107242, 54.339381],
              [52.092545, 54.328304],
              [52.054754, 54.342527],
              [52.036276, 54.355165],
              [52.058747, 54.373961],
              [52.046007, 54.387357],
              [52.065442, 54.418411],
              [52.034886, 54.41429],
              [52.026872, 54.426152],
              [51.991543, 54.427085],
              [51.985562, 54.416436],
              [52.01623, 54.414002],
              [51.978997, 54.411283],
              [51.965953, 54.419115],
              [51.975662, 54.428329],
              [51.954145, 54.426878],
              [51.951046, 54.414138],
              [51.904274, 54.412542],
              [51.914558, 54.436567],
              [51.928434, 54.445692],
              [51.942378, 54.442035],
              [51.951537, 54.473445],
              [51.940416, 54.483639],
              [51.937886, 54.516537],
              [51.920907, 54.524169],
              [51.928783, 54.535055],
              [51.906705, 54.538079],
              [51.875862, 54.527592],
              [51.86003, 54.53476],
              [51.823001, 54.539151],
              [51.789261, 54.536625],
              [51.777251, 54.556853],
              [51.726749, 54.552565],
              [51.690692, 54.555788],
              [51.663209, 54.569098],
              [51.633644, 54.565003],
              [51.584902, 54.606412],
              [51.538825, 54.649721],
              [51.518946, 54.65061],
              [51.513411, 54.595142],
              [51.500054, 54.596834],
              [51.381752, 54.588079],
              [51.381283, 54.612093],
              [51.396017, 54.637022],
              [51.39253, 54.647401],
              [51.40396, 54.668167],
              [51.389252, 54.677006],
              [51.381069, 54.664561],
              [51.355951, 54.652574],
              [51.331128, 54.654979],
              [51.312781, 54.646819],
              [51.288439, 54.652738],
              [51.279109, 54.641699],
              [51.228203, 54.631567],
              [51.225568, 54.647674],
              [51.190287, 54.659565],
              [51.150032, 54.639612],
              [51.14874, 54.613979],
              [51.100573, 54.586824],
              [51.099982, 54.571067],
              [51.07442, 54.540209],
              [51.008365, 54.549436],
              [51.000898, 54.482101],
              [51.020896, 54.46965],
              [50.984444, 54.437996],
              [50.991676, 54.405911],
              [50.982312, 54.396867],
              [50.954356, 54.392084],
              [50.95126, 54.342639],
              [50.899561, 54.343385],
              [50.907298, 54.37034],
              [50.889471, 54.371788],
              [50.87544, 54.384392],
              [50.847164, 54.367571],
              [50.821745, 54.363758],
              [50.77627, 54.376239],
              [50.750244, 54.375901],
              [50.736443, 54.40472],
              [50.676899, 54.395755],
              [50.648443, 54.38512],
              [50.636842, 54.371611],
              [50.621465, 54.370689],
              [50.575846, 54.343998],
              [50.564253, 54.34851],
              [50.521263, 54.324949],
              [50.493517, 54.332796],
              [50.483048, 54.345894],
              [50.506056, 54.350623],
              [50.48703, 54.366588],
              [50.477722, 54.383468],
              [50.473904, 54.408931],
              [50.462754, 54.428452],
              [50.430944, 54.420996],
              [50.386738, 54.48487],
              [50.374246, 54.493499],
              [50.341713, 54.486676],
              [50.333797, 54.471307],
              [50.343475, 54.462622],
              [50.302523, 54.45653],
              [50.307483, 54.442655],
              [50.273491, 54.44163],
              [50.235873, 54.446815],
              [50.223149, 54.492175],
              [50.195225, 54.501175],
              [50.092459, 54.5066],
              [50.053813, 54.509024],
              [50.015666, 54.50665],
              [49.98946, 54.517008],
              [49.967143, 54.500718],
              [49.952806, 54.508811],
              [49.95358, 54.522507],
              [49.941565, 54.535254],
              [49.848147, 54.537221],
              [49.804095, 54.564632],
              [49.7859, 54.560068],
              [49.76736, 54.571041],
              [49.734363, 54.576718],
              [49.684911, 54.577329],
              [49.620253, 54.555861],
              [49.560315, 54.56201],
              [49.551415, 54.576555],
              [49.584151, 54.597493],
              [49.581222, 54.615054],
              [49.533726, 54.621959],
              [49.536091, 54.649841],
              [49.551035, 54.668061],
              [49.515484, 54.695095],
              [49.479729, 54.688562],
              [49.489771, 54.709728],
              [49.525816, 54.723598],
              [49.485784, 54.732251],
              [49.490032, 54.748164],
              [49.476072, 54.778521],
              [49.436885, 54.803223],
              [49.377965, 54.827704],
              [49.407582, 54.830578],
              [49.405554, 54.839052],
              [49.357766, 54.840107],
              [49.326852, 54.868201],
              [49.288299, 54.891446],
              [49.239584, 54.880309],
              [49.271525, 54.861326],
              [49.238971, 54.841559],
              [49.180497, 54.852594],
              [49.148209, 54.841825],
              [49.125633, 54.802022],
              [49.09039, 54.788549],
              [49.045095, 54.803485],
              [49.031862, 54.814931],
              [49.012552, 54.796073],
              [48.88178, 54.79602],
              [48.881741, 54.625206],
              [48.465153, 54.625211],
              [48.417203, 54.654331],
              [48.351709, 54.670966],
              [48.361461, 54.693248],
              [48.334246, 54.703423],
              [48.280743, 54.663243],
              [48.240329, 54.691676],
              [48.244404, 54.702361],
              [48.265509, 54.711788],
              [48.277705, 54.730936],
              [48.24946, 54.717956],
              [48.211251, 54.72357],
              [48.214507, 54.736024],
              [48.152644, 54.723838],
              [48.115128, 54.739055],
              [48.098001, 54.738563],
              [48.103415, 54.766523],
              [48.069622, 54.763731],
              [48.07147, 54.735065],
              [48.08097, 54.713872],
              [48.038917, 54.703998],
              [48.021554, 54.727712],
              [47.956142, 54.711976],
              [47.945605, 54.726098],
              [47.920709, 54.719614],
              [47.928122, 54.699064],
              [47.903466, 54.696397],
              [47.90709, 54.666716],
              [47.925297, 54.667446],
              [47.928628, 54.64042],
              [47.909532, 54.647899],
              [47.816443, 54.641706],
              [47.770618, 54.682118],
              [47.789423, 54.6901],
              [47.835538, 54.689849],
              [47.822985, 54.728389],
              [47.717752, 54.735028],
              [47.699687, 54.716239],
              [47.711574, 54.708718],
              [47.703858, 54.695479],
              [47.670676, 54.673729],
              [47.693945, 54.658187],
              [47.651186, 54.625333],
              [47.612967, 54.612949],
              [47.634017, 54.590038],
              [47.616532, 54.55753],
              [47.578564, 54.532811],
              [47.539343, 54.530415],
              [47.506725, 54.551483],
              [47.484604, 54.545734],
              [47.485973, 54.557025],
              [47.4533, 54.567582],
              [47.438299, 54.563704],
              [47.435585, 54.544217],
              [47.403792, 54.527081],
              [47.424562, 54.498545],
              [47.396855, 54.496409],
              [47.387961, 54.523418],
              [47.34828, 54.598786],
              [47.344219, 54.621302],
              [47.355589, 54.627395],
              [47.405286, 54.638393],
              [47.391561, 54.653039],
              [47.373521, 54.655339],
              [47.365012, 54.672528],
              [47.370814, 54.697904],
              [47.290809, 54.674267],
              [47.267867, 54.673486],
              [47.258597, 54.677532],
              [47.283934, 54.712965],
              [47.31728, 54.730097],
              [47.387674, 54.752798],
              [47.358841, 54.773965],
              [47.370767, 54.781276],
              [47.369252, 54.828718],
              [47.387243, 54.843009],
              [47.426415, 54.83644],
              [47.409066, 54.880576],
              [47.453174, 54.872501],
              [47.450518, 54.861459],
              [47.560501, 54.844651],
              [47.576949, 54.828649],
              [47.603164, 54.832356],
              [47.640404, 54.864751],
              [47.658999, 54.859546],
              [47.684068, 54.839777],
              [47.709967, 54.843014],
              [47.744786, 54.812796],
              [47.754646, 54.81556],
              [47.724882, 54.846553],
              [47.722629, 54.858919],
              [47.738558, 54.896982],
              [47.714598, 54.90134],
              [47.735823, 54.908911],
              [47.745542, 54.897391],
              [47.787322, 54.823933],
              [47.795337, 54.80506],
              [47.823539, 54.803411],
              [47.844877, 54.819951],
              [47.801671, 54.826487],
              [47.782619, 54.869541],
              [47.778167, 54.901485],
              [47.769989, 54.915319],
              [47.763722, 54.954319],
              [47.808262, 54.964803],
              [47.823814, 54.959623],
              [47.852162, 54.961876],
              [47.895728, 54.941669],
              [47.930318, 54.950062],
              [47.941161, 54.945159],
              [47.976187, 54.952781],
              [48.011604, 54.98553],
              [48.028034, 55.039695],
              [48.01335, 55.036869],
              [47.99686, 55.053445],
              [47.975521, 55.040936],
              [47.972746, 55.064679],
              [47.945375, 55.061714],
              [47.936155, 55.049648],
              [47.95135, 55.037358],
              [47.930529, 55.026238],
              [47.918852, 55.045647],
              [47.900018, 55.052815],
              [47.906185, 55.072764],
              [47.901614, 55.091834],
              [47.943197, 55.090897],
              [47.959439, 55.098456],
              [47.982929, 55.097506],
              [48.014628, 55.105008],
              [48.017526, 55.115682],
              [48.035255, 55.118429],
              [48.03788, 55.108639],
              [48.063163, 55.083669],
              [48.086793, 55.093943],
              [48.061378, 55.12863],
              [48.102603, 55.135901],
              [48.107665, 55.145905],
              [48.074998, 55.175321],
              [48.064316, 55.229142],
              [48.021094, 55.232698],
              [48.012912, 55.238265],
              [48.004708, 55.266106],
              [48.016183, 55.28475],
              [47.976536, 55.303477],
              [47.96023, 55.304534],
              [47.911613, 55.322252],
              [47.89441, 55.315475],
              [47.872431, 55.321496],
              [47.873809, 55.304521],
              [47.864214, 55.284068],
              [47.901726, 55.262231],
              [47.866865, 55.254228],
              [47.865833, 55.268256],
              [47.822929, 55.279783],
              [47.804031, 55.302955],
              [47.788535, 55.29777],
              [47.80032, 55.281833],
              [47.789585, 55.271656],
              [47.783703, 55.23867],
              [47.737754, 55.238215],
              [47.709326, 55.230822],
              [47.703214, 55.215842],
              [47.669943, 55.214213],
              [47.66852, 55.230648],
              [47.687218, 55.233977],
              [47.699566, 55.285032],
              [47.684911, 55.30616],
              [47.683704, 55.323971],
              [47.662802, 55.33527],
              [47.645109, 55.33553],
              [47.690989, 55.361437],
              [47.70341, 55.349249],
              [47.741199, 55.352127],
              [47.75588, 55.337979],
              [47.780374, 55.333057],
              [47.808679, 55.3511],
              [47.802951, 55.366395],
              [47.838792, 55.373605],
              [47.860835, 55.391617],
              [47.876666, 55.392661],
              [47.872987, 55.412889],
              [47.89266, 55.414043],
              [47.897568, 55.429401],
              [47.912739, 55.429404],
              [47.928423, 55.475212],
              [47.976795, 55.491518],
              [47.969208, 55.51546],
              [48.039149, 55.524643],
              [48.05649, 55.513454],
              [48.07074, 55.528646],
              [48.080575, 55.521233],
              [48.107967, 55.524123],
              [48.108465, 55.546373],
              [48.147589, 55.565033],
              [48.136495, 55.573593],
              [48.103694, 55.568879],
              [48.09899, 55.5774],
              [48.071819, 55.585401],
              [48.067124, 55.595799],
              [48.082042, 55.616307],
              [48.05501, 55.607523],
              [48.028512, 55.606866],
              [48.013511, 55.599305],
              [48.010683, 55.629275],
              [48.034203, 55.637255],
              [48.033466, 55.650973],
              [48.086901, 55.650081],
              [48.083966, 55.694768],
              [48.108215, 55.690318],
              [48.110249, 55.651781],
              [48.129499, 55.646347],
              [48.135665, 55.673297],
              [48.128601, 55.688965],
              [48.145872, 55.693047],
              [48.222262, 55.687399],
              [48.23507, 55.689446],
              [48.241025, 55.706249],
              [48.232289, 55.712948],
              [48.251925, 55.735469],
              [48.237683, 55.748679],
              [48.28179, 55.761588],
              [48.266329, 55.78055],
              [48.309992, 55.781634],
              [48.337433, 55.769164],
              [48.346176, 55.777868],
              [48.361696, 55.77266],
              [48.412517, 55.783752],
              [48.420602, 55.793605],
              [48.395095, 55.808846],
              [48.395434, 55.825896],
              [48.406167, 55.825699],
            ],
            [
              [48.050632, 54.989088],
              [48.102709, 54.991061],
              [48.099905, 54.998922],
              [48.119588, 55.013018],
              [48.113203, 55.024608],
              [48.05238, 55.00272],
              [48.050632, 54.989088],
            ],
            [
              [47.819619, 54.903472],
              [47.842827, 54.909117],
              [47.822454, 54.913742],
              [47.819619, 54.903472],
            ],
            [
              [47.8373, 54.768253],
              [47.85386, 54.741635],
              [47.850659, 54.773181],
              [47.8373, 54.768253],
            ],
          ],
          [
            [
              [47.698839, 55.388287],
              [47.703566, 55.396145],
              [47.738953, 55.375665],
              [47.706103, 55.381188],
              [47.698839, 55.388287],
            ],
          ],
          [
            [
              [52.198188, 56.063163],
              [52.222115, 56.073613],
              [52.22231, 56.056262],
              [52.198188, 56.063163],
            ],
          ],
          [
            [
              [49.524869, 56.485832],
              [49.534847, 56.5034],
              [49.551464, 56.495247],
              [49.546705, 56.483279],
              [49.524869, 56.485832],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5503",
      properties: {
        name: "Тверская область",
        density: 0,
        path: "/world/Russia/Тверская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.978757, 56.969897],
            [31.006758, 56.977791],
            [31.004929, 56.986641],
            [31.030901, 56.98758],
            [31.054287, 56.954979],
            [31.069051, 56.956625],
            [31.069698, 56.943224],
            [31.097912, 56.938615],
            [31.100948, 56.930977],
            [31.152641, 56.9384],
            [31.20466, 56.942175],
            [31.225126, 56.936859],
            [31.241306, 56.951509],
            [31.275091, 56.948262],
            [31.298762, 56.955736],
            [31.308221, 56.937681],
            [31.334984, 56.939236],
            [31.343863, 56.928755],
            [31.368725, 56.927522],
            [31.396194, 56.909396],
            [31.434381, 56.924694],
            [31.441735, 56.954436],
            [31.531003, 56.946578],
            [31.563951, 56.977634],
            [31.613833, 56.990603],
            [31.615116, 56.984392],
            [31.646749, 57.003313],
            [31.702837, 56.99325],
            [31.679213, 57.012223],
            [31.65357, 57.013395],
            [31.647391, 57.026185],
            [31.671558, 57.043636],
            [31.66991, 57.056472],
            [31.68957, 57.071595],
            [31.711352, 57.073049],
            [31.725252, 57.092029],
            [31.743553, 57.092969],
            [31.755728, 57.114047],
            [31.779036, 57.09861],
            [31.818398, 57.104165],
            [31.873182, 57.12099],
            [31.880087, 57.130835],
            [31.898327, 57.125242],
            [31.896333, 57.10219],
            [31.882155, 57.094541],
            [31.913784, 57.07957],
            [31.928792, 57.08353],
            [31.922915, 57.095235],
            [31.969155, 57.109182],
            [31.967902, 57.114984],
            [32.001669, 57.113838],
            [32.015875, 57.128794],
            [32.020452, 57.149556],
            [32.08108, 57.159026],
            [32.083323, 57.176584],
            [32.106282, 57.185621],
            [32.155924, 57.184418],
            [32.181588, 57.178602],
            [32.191207, 57.19078],
            [32.210399, 57.186733],
            [32.250349, 57.202555],
            [32.24883, 57.225543],
            [32.231125, 57.229682],
            [32.236373, 57.255114],
            [32.276174, 57.259781],
            [32.292204, 57.240814],
            [32.327271, 57.225718],
            [32.384981, 57.230085],
            [32.391948, 57.260135],
            [32.434068, 57.269259],
            [32.436438, 57.276537],
            [32.477148, 57.285729],
            [32.547621, 57.324454],
            [32.545901, 57.34028],
            [32.582649, 57.320788],
            [32.593406, 57.328621],
            [32.631678, 57.334859],
            [32.626831, 57.34468],
            [32.670973, 57.375154],
            [32.662259, 57.390892],
            [32.681974, 57.410075],
            [32.706845, 57.406561],
            [32.706919, 57.434025],
            [32.749844, 57.443715],
            [32.76576, 57.440478],
            [32.781273, 57.449085],
            [32.78858, 57.467192],
            [32.826286, 57.455627],
            [32.859809, 57.460455],
            [32.871533, 57.451668],
            [32.915921, 57.445144],
            [32.937279, 57.465572],
            [32.962562, 57.446606],
            [33.004129, 57.44903],
            [33.018319, 57.435847],
            [33.050486, 57.429899],
            [33.073025, 57.442789],
            [33.07835, 57.466113],
            [33.127341, 57.465221],
            [33.154916, 57.455583],
            [33.184909, 57.453961],
            [33.185483, 57.444382],
            [33.160375, 57.430798],
            [33.196153, 57.423895],
            [33.213418, 57.411762],
            [33.240044, 57.411426],
            [33.264033, 57.42192],
            [33.266631, 57.463398],
            [33.232229, 57.477529],
            [33.233437, 57.545719],
            [33.253062, 57.544852],
            [33.268076, 57.553838],
            [33.293068, 57.54945],
            [33.304749, 57.565236],
            [33.294918, 57.582941],
            [33.318586, 57.583298],
            [33.32445, 57.614812],
            [33.30101, 57.629189],
            [33.306631, 57.648529],
            [33.426627, 57.700311],
            [33.45587, 57.692623],
            [33.476542, 57.679353],
            [33.492127, 57.699274],
            [33.48021, 57.714533],
            [33.507938, 57.717502],
            [33.509664, 57.728809],
            [33.535533, 57.710684],
            [33.569241, 57.713418],
            [33.593119, 57.727617],
            [33.586473, 57.739469],
            [33.566229, 57.74634],
            [33.567818, 57.756921],
            [33.551329, 57.767487],
            [33.549292, 57.794531],
            [33.564736, 57.795364],
            [33.544991, 57.836258],
            [33.567594, 57.835301],
            [33.575312, 57.843942],
            [33.547352, 57.851164],
            [33.597558, 57.874356],
            [33.605802, 57.869105],
            [33.647059, 57.873226],
            [33.684006, 57.905601],
            [33.668348, 57.961045],
            [33.69115, 57.968512],
            [33.677965, 57.981365],
            [33.692728, 57.990429],
            [33.689505, 58.002835],
            [33.64015, 57.997918],
            [33.625161, 58.005129],
            [33.62454, 58.038732],
            [33.569504, 58.023685],
            [33.544086, 58.034797],
            [33.554386, 58.042703],
            [33.53089, 58.051676],
            [33.529306, 58.061171],
            [33.551355, 58.067343],
            [33.602981, 58.100814],
            [33.575189, 58.108559],
            [33.585955, 58.120475],
            [33.617137, 58.122078],
            [33.635316, 58.107335],
            [33.637842, 58.128632],
            [33.669436, 58.140084],
            [33.665874, 58.148524],
            [33.696798, 58.152536],
            [33.705874, 58.170305],
            [33.74091, 58.16248],
            [33.780013, 58.169795],
            [33.802965, 58.137807],
            [33.928132, 58.163292],
            [33.95975, 58.180582],
            [33.968536, 58.195951],
            [33.998275, 58.213395],
            [34.018985, 58.205009],
            [34.026013, 58.18429],
            [34.047469, 58.195008],
            [34.063235, 58.191565],
            [34.110204, 58.203651],
            [34.133311, 58.214555],
            [34.149264, 58.200866],
            [34.170765, 58.212845],
            [34.204372, 58.205885],
            [34.190048, 58.200448],
            [34.223395, 58.187271],
            [34.276928, 58.187918],
            [34.27885, 58.177382],
            [34.349473, 58.164745],
            [34.357481, 58.176971],
            [34.379375, 58.171867],
            [34.370037, 58.161658],
            [34.425484, 58.149261],
            [34.465114, 58.155906],
            [34.498089, 58.139293],
            [34.484339, 58.127741],
            [34.504716, 58.114822],
            [34.527531, 58.117053],
            [34.551974, 58.104723],
            [34.560291, 58.115115],
            [34.596931, 58.118418],
            [34.59814, 58.105445],
            [34.617502, 58.10639],
            [34.615104, 58.094454],
            [34.645992, 58.078059],
            [34.657612, 58.083881],
            [34.671645, 58.07347],
            [34.721495, 58.090302],
            [34.731345, 58.142323],
            [34.711954, 58.152155],
            [34.736603, 58.172142],
            [34.716572, 58.185445],
            [34.724218, 58.202071],
            [34.689496, 58.200132],
            [34.749008, 58.223637],
            [34.793484, 58.245972],
            [34.821427, 58.231177],
            [34.898552, 58.224394],
            [34.928811, 58.231576],
            [34.954828, 58.229452],
            [34.963552, 58.218088],
            [34.974672, 58.225534],
            [35.004235, 58.207862],
            [35.050888, 58.194615],
            [35.077818, 58.213585],
            [35.11844, 58.212464],
            [35.142443, 58.23915],
            [35.195361, 58.246905],
            [35.206943, 58.25826],
            [35.231997, 58.26181],
            [35.257167, 58.274199],
            [35.264654, 58.297481],
            [35.255755, 58.317227],
            [35.276753, 58.345296],
            [35.283327, 58.36566],
            [35.247637, 58.381558],
            [35.254972, 58.390497],
            [35.304681, 58.396007],
            [35.304491, 58.408005],
            [35.331258, 58.412427],
            [35.376726, 58.406808],
            [35.470656, 58.409995],
            [35.460123, 58.428659],
            [35.497564, 58.428603],
            [35.516529, 58.447671],
            [35.536775, 58.445934],
            [35.551328, 58.456874],
            [35.622531, 58.450428],
            [35.644882, 58.46017],
            [35.656716, 58.450978],
            [35.690179, 58.448823],
            [35.688914, 58.441939],
            [35.732741, 58.434081],
            [35.754942, 58.45011],
            [35.777631, 58.45289],
            [35.768285, 58.432275],
            [35.836414, 58.426799],
            [35.835318, 58.41584],
            [35.888604, 58.422124],
            [35.904824, 58.434683],
            [35.916309, 58.42043],
            [35.939633, 58.423027],
            [35.941912, 58.438396],
            [35.918009, 58.439865],
            [35.92145, 58.451908],
            [35.958054, 58.466351],
            [36.0197, 58.459165],
            [36.015296, 58.472598],
            [36.043097, 58.473604],
            [36.102447, 58.504954],
            [36.124582, 58.497919],
            [36.181682, 58.51165],
            [36.231289, 58.505763],
            [36.243809, 58.521414],
            [36.287792, 58.515481],
            [36.327619, 58.484121],
            [36.359827, 58.47943],
            [36.352442, 58.490262],
            [36.394136, 58.52219],
            [36.434238, 58.51164],
            [36.455388, 58.527473],
            [36.503051, 58.515478],
            [36.520438, 58.525277],
            [36.522387, 58.543852],
            [36.537297, 58.55169],
            [36.543732, 58.569482],
            [36.506743, 58.576879],
            [36.539134, 58.603816],
            [36.579527, 58.599135],
            [36.594984, 58.614127],
            [36.587092, 58.626687],
            [36.630504, 58.619255],
            [36.638635, 58.642348],
            [36.629739, 58.650605],
            [36.66368, 58.701748],
            [36.687498, 58.694438],
            [36.685747, 58.709504],
            [36.721537, 58.715532],
            [36.763185, 58.713163],
            [36.786967, 58.723219],
            [36.810425, 58.723997],
            [36.808341, 58.735456],
            [36.829193, 58.742107],
            [36.821566, 58.760337],
            [36.779681, 58.774926],
            [36.765447, 58.800944],
            [36.800934, 58.808617],
            [36.847863, 58.836168],
            [36.883815, 58.835535],
            [36.959319, 58.828296],
            [36.997589, 58.838681],
            [37.031405, 58.836855],
            [37.032297, 58.846149],
            [37.070959, 58.863139],
            [37.121601, 58.853441],
            [37.137009, 58.834349],
            [37.18322, 58.826327],
            [37.195804, 58.814279],
            [37.188408, 58.792383],
            [37.202669, 58.747582],
            [37.234456, 58.739163],
            [37.23392, 58.714688],
            [37.27266, 58.697968],
            [37.276161, 58.68144],
            [37.304705, 58.696169],
            [37.306579, 58.671568],
            [37.285897, 58.663322],
            [37.292556, 58.645339],
            [37.340476, 58.652521],
            [37.364895, 58.646456],
            [37.392495, 58.600564],
            [37.413973, 58.583469],
            [37.476755, 58.559603],
            [37.512288, 58.553923],
            [37.539429, 58.521115],
            [37.571111, 58.517203],
            [37.596134, 58.507638],
            [37.628222, 58.482944],
            [37.665122, 58.450785],
            [37.630006, 58.454713],
            [37.624237, 58.420457],
            [37.582859, 58.420579],
            [37.560513, 58.409282],
            [37.543691, 58.415421],
            [37.535957, 58.390864],
            [37.508577, 58.380796],
            [37.476897, 58.401668],
            [37.457252, 58.39295],
            [37.437975, 58.397616],
            [37.418051, 58.39073],
            [37.43331, 58.380917],
            [37.411333, 58.356974],
            [37.381726, 58.36244],
            [37.368606, 58.338433],
            [37.392918, 58.323208],
            [37.39255, 58.313631],
            [37.417966, 58.313386],
            [37.43135, 58.305405],
            [37.435102, 58.276002],
            [37.370881, 58.268446],
            [37.385862, 58.25052],
            [37.369164, 58.241556],
            [37.327909, 58.236644],
            [37.335031, 58.225226],
            [37.375664, 58.233954],
            [37.388565, 58.222893],
            [37.38433, 58.202757],
            [37.413552, 58.203494],
            [37.437619, 58.187778],
            [37.476741, 58.179526],
            [37.505249, 58.188843],
            [37.530199, 58.167275],
            [37.582835, 58.161892],
            [37.583428, 58.136211],
            [37.614983, 58.133756],
            [37.612406, 58.119267],
            [37.63463, 58.115953],
            [37.643839, 58.105884],
            [37.6897, 58.085749],
            [37.706521, 58.097501],
            [37.715821, 58.090502],
            [37.699814, 58.081922],
            [37.714982, 58.077988],
            [37.694612, 58.070523],
            [37.669687, 58.073347],
            [37.652989, 58.064384],
            [37.650042, 58.074329],
            [37.605225, 58.07703],
            [37.603016, 58.063646],
            [37.586993, 58.064137],
            [37.569804, 58.050999],
            [37.51363, 58.041175],
            [37.504423, 58.01625],
            [37.538654, 57.996168],
            [37.536962, 57.986414],
            [37.499022, 57.987764],
            [37.484043, 57.977082],
            [37.499023, 57.96861],
            [37.478641, 57.964312],
            [37.443279, 57.982115],
            [37.400243, 57.985552],
            [37.387842, 57.979413],
            [37.36847, 57.992894],
            [37.354008, 57.990045],
            [37.349411, 57.973396],
            [37.366724, 57.96738],
            [37.359112, 57.95154],
            [37.407551, 57.939816],
            [37.403126, 57.925392],
            [37.444756, 57.907217],
            [37.402077, 57.911011],
            [37.395932, 57.897463],
            [37.358501, 57.905005],
            [37.349122, 57.891089],
            [37.33033, 57.88546],
            [37.334165, 57.8762],
            [37.366476, 57.87705],
            [37.381321, 57.884145],
            [37.411852, 57.880327],
            [37.433831, 57.869276],
            [37.401137, 57.85622],
            [37.379967, 57.837475],
            [37.380219, 57.820446],
            [37.437394, 57.821514],
            [37.46588, 57.811937],
            [37.488926, 57.811498],
            [37.484053, 57.796466],
            [37.499402, 57.781119],
            [37.533844, 57.768718],
            [37.559702, 57.766523],
            [37.551034, 57.75202],
            [37.561096, 57.739119],
            [37.649168, 57.703003],
            [37.630062, 57.66343],
            [37.675847, 57.655053],
            [37.685784, 57.657872],
            [37.724596, 57.635746],
            [37.736112, 57.621813],
            [37.772938, 57.615546],
            [37.78477, 57.623401],
            [37.800665, 57.617059],
            [37.814211, 57.625256],
            [37.823309, 57.596104],
            [37.85767, 57.5797],
            [37.838068, 57.573691],
            [37.84253, 57.562026],
            [37.823272, 57.551579],
            [37.83395, 57.530238],
            [37.817442, 57.523956],
            [37.821449, 57.512971],
            [37.800582, 57.501326],
            [37.828171, 57.492949],
            [37.852115, 57.470603],
            [37.843889, 57.461517],
            [37.804677, 57.447853],
            [37.806651, 57.440711],
            [37.842037, 57.439236],
            [37.882599, 57.39494],
            [37.944939, 57.373601],
            [37.959613, 57.354739],
            [37.974866, 57.35552],
            [38.016234, 57.336215],
            [38.040685, 57.351757],
            [38.089051, 57.331697],
            [38.116254, 57.331692],
            [38.158886, 57.339853],
            [38.165496, 57.354626],
            [38.284207, 57.365971],
            [38.3032, 57.358673],
            [38.31164, 57.343205],
            [38.32641, 57.282261],
            [38.308181, 57.265846],
            [38.313427, 57.259914],
            [38.260576, 57.255774],
            [38.245701, 57.239084],
            [38.200133, 57.227711],
            [38.168959, 57.229711],
            [38.148415, 57.224503],
            [38.161622, 57.170992],
            [38.204331, 57.159296],
            [38.208324, 57.120411],
            [38.222377, 57.112686],
            [38.242694, 57.117297],
            [38.252071, 57.092308],
            [38.230483, 57.092699],
            [38.205393, 57.073215],
            [38.238699, 57.081416],
            [38.253904, 57.072613],
            [38.251866, 57.028605],
            [38.226918, 57.018425],
            [38.237408, 56.999302],
            [38.220679, 56.98469],
            [38.154234, 56.985256],
            [38.151982, 56.968297],
            [38.136667, 56.958762],
            [38.180953, 56.957967],
            [38.197671, 56.947407],
            [38.180685, 56.933619],
            [38.194199, 56.902533],
            [38.179427, 56.89264],
            [38.108268, 56.87657],
            [38.100071, 56.85531],
            [38.124453, 56.858526],
            [38.152172, 56.845819],
            [38.110887, 56.827278],
            [38.119697, 56.818499],
            [38.155243, 56.821692],
            [38.172463, 56.831637],
            [38.190881, 56.817825],
            [38.228698, 56.824517],
            [38.244299, 56.793463],
            [38.250716, 56.765376],
            [38.241535, 56.760653],
            [38.217557, 56.771895],
            [38.164173, 56.767839],
            [38.128958, 56.770051],
            [38.103836, 56.759725],
            [38.067499, 56.761446],
            [38.050131, 56.753236],
            [38.051567, 56.767872],
            [38.009817, 56.771481],
            [37.99271, 56.767322],
            [37.994002, 56.822902],
            [37.983326, 56.825293],
            [37.977721, 56.849466],
            [37.984001, 56.865396],
            [37.962562, 56.870963],
            [37.942993, 56.88793],
            [37.91016, 56.888007],
            [37.910586, 56.878547],
            [37.887726, 56.878111],
            [37.882701, 56.893781],
            [37.857343, 56.906122],
            [37.82892, 56.90489],
            [37.825187, 56.91144],
            [37.798462, 56.908819],
            [37.785248, 56.929675],
            [37.762928, 56.925655],
            [37.747218, 56.935464],
            [37.758185, 56.949991],
            [37.698307, 56.958512],
            [37.678639, 56.940173],
            [37.649372, 56.94607],
            [37.645471, 56.935101],
            [37.591068, 56.929787],
            [37.585203, 56.914738],
            [37.548218, 56.918446],
            [37.531958, 56.909493],
            [37.524739, 56.892526],
            [37.537589, 56.881876],
            [37.574944, 56.873177],
            [37.564686, 56.858637],
            [37.54583, 56.866268],
            [37.54146, 56.852674],
            [37.552028, 56.839534],
            [37.515798, 56.821924],
            [37.483008, 56.787671],
            [37.463114, 56.776999],
            [37.455039, 56.794323],
            [37.442927, 56.796534],
            [37.350858, 56.779353],
            [37.342833, 56.763786],
            [37.280951, 56.77543],
            [37.274683, 56.759685],
            [37.249196, 56.745381],
            [37.228643, 56.758945],
            [37.24286, 56.785421],
            [37.215044, 56.783441],
            [37.205593, 56.769717],
            [37.178298, 56.769323],
            [37.167297, 56.779564],
            [37.100301, 56.769769],
            [37.105904, 56.761241],
            [37.066831, 56.755621],
            [37.123451, 56.726781],
            [37.13194, 56.714913],
            [37.17425, 56.696425],
            [37.154296, 56.690563],
            [37.158811, 56.681931],
            [37.129726, 56.668146],
            [37.159729, 56.661342],
            [37.157952, 56.651802],
            [37.115391, 56.640885],
            [37.13401, 56.618559],
            [37.126291, 56.601612],
            [37.148042, 56.587675],
            [37.122725, 56.568503],
            [37.098352, 56.540526],
            [37.071835, 56.545359],
            [37.055548, 56.526247],
            [37.037426, 56.533722],
            [36.969187, 56.547287],
            [36.970927, 56.564529],
            [36.873056, 56.57975],
            [36.803018, 56.597475],
            [36.813078, 56.579103],
            [36.740807, 56.546422],
            [36.677301, 56.532703],
            [36.65287, 56.523223],
            [36.681721, 56.498062],
            [36.649127, 56.503291],
            [36.614859, 56.517958],
            [36.58957, 56.499836],
            [36.540478, 56.496709],
            [36.54243, 56.473843],
            [36.425619, 56.473675],
            [36.392351, 56.466933],
            [36.375977, 56.451898],
            [36.305615, 56.441491],
            [36.273977, 56.417564],
            [36.126592, 56.399513],
            [36.124693, 56.386915],
            [36.107304, 56.388135],
            [36.092087, 56.367206],
            [36.088667, 56.335662],
            [36.06473, 56.335649],
            [36.062368, 56.353946],
            [36.073525, 56.363181],
            [36.049949, 56.373256],
            [36.0389, 56.365898],
            [36.012155, 56.37822],
            [35.958513, 56.378032],
            [35.953103, 56.370394],
            [35.871625, 56.37212],
            [35.862528, 56.386641],
            [35.843538, 56.380809],
            [35.839276, 56.397202],
            [35.801089, 56.406391],
            [35.769391, 56.389768],
            [35.735272, 56.389721],
            [35.72898, 56.41383],
            [35.71819, 56.411086],
            [35.681303, 56.429147],
            [35.686079, 56.445234],
            [35.664086, 56.447809],
            [35.658578, 56.460063],
            [35.630245, 56.454778],
            [35.62024, 56.477052],
            [35.599685, 56.477777],
            [35.518457, 56.430333],
            [35.473405, 56.437806],
            [35.477568, 56.45257],
            [35.434271, 56.458656],
            [35.424872, 56.443439],
            [35.456791, 56.432005],
            [35.430117, 56.42123],
            [35.419005, 56.396036],
            [35.42172, 56.38132],
            [35.438798, 56.385666],
            [35.461629, 56.37887],
            [35.482958, 56.383923],
            [35.497957, 56.399551],
            [35.520323, 56.39541],
            [35.505408, 56.367575],
            [35.482453, 56.37352],
            [35.470512, 56.361216],
            [35.480977, 56.346185],
            [35.465698, 56.330621],
            [35.480621, 56.32731],
            [35.467814, 56.307892],
            [35.491342, 56.286963],
            [35.474962, 56.276813],
            [35.50231, 56.259486],
            [35.486339, 56.247309],
            [35.442007, 56.239189],
            [35.4078, 56.247951],
            [35.392907, 56.235105],
            [35.363012, 56.237404],
            [35.315791, 56.227991],
            [35.320227, 56.209248],
            [35.338269, 56.199408],
            [35.36386, 56.196974],
            [35.348939, 56.183957],
            [35.363129, 56.178871],
            [35.320517, 56.153519],
            [35.304932, 56.154468],
            [35.291909, 56.140434],
            [35.308985, 56.130453],
            [35.28455, 56.124996],
            [35.287279, 56.103389],
            [35.308981, 56.106635],
            [35.338992, 56.094214],
            [35.317324, 56.083591],
            [35.323369, 56.077495],
            [35.304736, 56.058311],
            [35.287845, 56.062588],
            [35.266758, 56.039058],
            [35.23879, 56.046528],
            [35.239819, 56.031182],
            [35.215562, 56.010689],
            [35.196821, 56.012079],
            [35.17658, 55.996691],
            [35.175032, 55.984885],
            [35.153871, 55.977983],
            [35.148495, 55.957501],
            [35.104944, 55.952786],
            [35.077805, 55.968639],
            [35.065608, 55.989845],
            [35.045767, 55.980396],
            [35.003851, 55.977718],
            [34.957055, 55.96929],
            [34.925254, 55.984819],
            [34.910051, 55.964666],
            [34.893425, 55.974387],
            [34.8593, 55.972885],
            [34.852824, 55.955537],
            [34.813673, 55.966206],
            [34.827068, 55.97972],
            [34.784719, 55.961345],
            [34.767381, 55.976044],
            [34.745045, 55.977857],
            [34.71875, 55.956455],
            [34.684549, 55.935589],
            [34.696167, 55.926224],
            [34.682742, 55.916385],
            [34.657699, 55.919585],
            [34.605539, 55.911879],
            [34.619023, 55.926875],
            [34.596972, 55.943086],
            [34.573988, 55.934587],
            [34.539419, 55.931408],
            [34.515294, 55.9451],
            [34.517901, 55.954821],
            [34.492387, 55.96573],
            [34.470544, 55.963779],
            [34.460681, 55.981475],
            [34.448061, 55.979146],
            [34.41274, 55.99679],
            [34.381548, 56.022678],
            [34.367868, 56.015445],
            [34.336202, 56.012169],
            [34.322138, 55.994503],
            [34.291102, 56.016923],
            [34.204527, 56.039979],
            [34.208982, 56.052008],
            [34.15825, 56.063054],
            [34.11903, 56.051618],
            [34.085427, 56.026866],
            [34.059593, 56.037374],
            [34.013315, 56.048368],
            [33.999875, 56.040078],
            [33.963248, 56.051871],
            [33.929271, 56.054082],
            [33.915505, 56.067054],
            [33.87697, 56.054433],
            [33.863613, 56.059824],
            [33.863861, 56.014426],
            [33.798613, 56.014915],
            [33.811741, 56.006062],
            [33.803148, 55.982996],
            [33.811726, 55.974204],
            [33.796042, 55.9682],
            [33.821652, 55.962074],
            [33.802508, 55.932911],
            [33.777266, 55.930092],
            [33.779839, 55.942468],
            [33.756037, 55.950432],
            [33.722402, 55.952392],
            [33.714437, 55.959621],
            [33.696272, 55.953249],
            [33.650924, 55.956405],
            [33.646005, 55.930182],
            [33.672962, 55.929815],
            [33.659802, 55.901784],
            [33.648069, 55.844485],
            [33.575761, 55.848673],
            [33.569512, 55.812935],
            [33.537327, 55.813758],
            [33.53347, 55.77377],
            [33.510928, 55.772825],
            [33.498257, 55.76031],
            [33.457056, 55.761535],
            [33.454698, 55.754458],
            [33.391578, 55.74779],
            [33.390499, 55.733733],
            [33.421077, 55.727272],
            [33.345303, 55.729468],
            [33.321459, 55.726907],
            [33.323943, 55.714491],
            [33.30397, 55.71106],
            [33.304829, 55.697949],
            [33.257991, 55.700766],
            [33.266047, 55.71596],
            [33.246289, 55.715102],
            [33.196879, 55.701868],
            [33.194398, 55.687348],
            [33.168024, 55.671174],
            [33.170343, 55.659309],
            [33.134176, 55.63907],
            [33.117237, 55.636496],
            [33.094047, 55.65414],
            [33.074564, 55.654875],
            [33.070244, 55.668966],
            [33.037743, 55.661859],
            [33.045708, 55.654385],
            [33.0061, 55.647522],
            [33.002914, 55.656835],
            [32.97479, 55.668903],
            [32.958513, 55.658932],
            [32.923365, 55.662421],
            [32.900732, 55.634004],
            [32.880948, 55.656709],
            [32.8428, 55.653421],
            [32.822334, 55.660675],
            [32.816152, 55.675133],
            [32.796584, 55.669848],
            [32.76505, 55.678622],
            [32.715021, 55.684703],
            [32.709038, 55.692979],
            [32.679574, 55.686896],
            [32.642656, 55.690086],
            [32.631863, 55.684802],
            [32.58475, 55.688689],
            [32.554114, 55.679241],
            [32.563089, 55.660396],
            [32.538137, 55.655909],
            [32.522683, 55.643046],
            [32.522283, 55.664957],
            [32.543719, 55.687542],
            [32.542422, 55.707758],
            [32.512982, 55.716408],
            [32.497858, 55.740422],
            [32.481974, 55.723974],
            [32.481214, 55.709078],
            [32.458314, 55.709686],
            [32.464068, 55.684666],
            [32.452336, 55.672605],
            [32.430653, 55.683226],
            [32.440816, 55.71066],
            [32.416394, 55.715285],
            [32.403401, 55.692705],
            [32.380866, 55.70334],
            [32.382798, 55.72166],
            [32.341305, 55.708772],
            [32.315606, 55.70763],
            [32.324979, 55.694073],
            [32.308897, 55.68963],
            [32.288111, 55.698333],
            [32.272698, 55.694742],
            [32.259051, 55.670092],
            [32.234919, 55.672161],
            [32.19665, 55.683968],
            [32.195311, 55.699001],
            [32.159067, 55.697235],
            [32.169414, 55.691712],
            [32.156885, 55.669124],
            [32.126944, 55.671929],
            [32.127188, 55.685963],
            [32.103322, 55.688675],
            [32.088636, 55.681903],
            [32.095164, 55.670643],
            [32.057041, 55.658159],
            [32.057776, 55.649347],
            [32.02726, 55.658301],
            [32.035581, 55.676557],
            [32.055244, 55.691958],
            [32.070053, 55.685186],
            [32.094857, 55.689348],
            [32.114438, 55.721271],
            [32.070643, 55.720047],
            [32.056038, 55.730572],
            [32.047308, 55.722168],
            [32.055159, 55.752174],
            [32.035329, 55.76618],
            [31.893545, 55.771386],
            [31.887769, 55.787895],
            [31.865975, 55.78406],
            [31.875492, 55.771651],
            [31.841611, 55.774343],
            [31.80616, 55.760247],
            [31.791514, 55.762368],
            [31.770709, 55.74454],
            [31.785397, 55.716493],
            [31.777728, 55.696992],
            [31.735729, 55.694217],
            [31.726835, 55.70513],
            [31.735422, 55.716451],
            [31.693758, 55.720846],
            [31.685721, 55.729495],
            [31.659856, 55.726231],
            [31.653002, 55.734798],
            [31.596847, 55.729167],
            [31.604352, 55.748729],
            [31.563526, 55.73034],
            [31.54374, 55.733623],
            [31.525872, 55.725464],
            [31.503719, 55.728809],
            [31.483134, 55.718642],
            [31.47165, 55.734699],
            [31.447358, 55.743567],
            [31.434825, 55.781354],
            [31.406805, 55.805773],
            [31.402499, 55.817726],
            [31.413166, 55.832249],
            [31.394014, 55.866437],
            [31.408023, 55.87312],
            [31.414319, 55.890728],
            [31.394205, 55.903452],
            [31.398702, 55.916433],
            [31.38662, 55.927101],
            [31.347419, 55.942652],
            [31.363869, 55.968229],
            [31.411744, 55.983909],
            [31.42331, 55.999204],
            [31.481145, 56.030051],
            [31.501066, 56.034807],
            [31.503635, 56.067195],
            [31.467647, 56.087888],
            [31.451065, 56.139812],
            [31.482938, 56.145339],
            [31.507486, 56.161019],
            [31.501187, 56.184025],
            [31.464171, 56.19315],
            [31.46057, 56.242247],
            [31.487751, 56.286974],
            [31.514484, 56.293786],
            [31.509342, 56.307795],
            [31.433705, 56.308951],
            [31.399967, 56.314541],
            [31.387661, 56.305351],
            [31.367932, 56.307279],
            [31.357352, 56.324257],
            [31.301677, 56.322058],
            [31.229027, 56.338444],
            [31.224044, 56.39114],
            [31.229698, 56.408426],
            [31.206595, 56.414145],
            [31.211736, 56.427769],
            [31.184488, 56.435737],
            [31.153513, 56.437536],
            [31.042594, 56.468188],
            [30.987103, 56.485795],
            [31.005867, 56.489972],
            [30.998732, 56.525702],
            [31.021737, 56.540033],
            [31.04246, 56.574992],
            [31.040178, 56.586045],
            [31.054444, 56.602111],
            [31.046925, 56.606674],
            [31.079151, 56.625632],
            [31.085384, 56.640155],
            [31.065591, 56.638806],
            [31.04342, 56.651786],
            [31.021925, 56.644396],
            [30.946962, 56.647993],
            [30.936938, 56.636554],
            [30.881864, 56.643172],
            [30.857316, 56.655125],
            [30.849732, 56.666435],
            [30.814386, 56.694774],
            [30.824538, 56.713925],
            [30.811943, 56.713475],
            [30.799539, 56.730633],
            [30.774251, 56.731075],
            [30.790863, 56.738922],
            [30.834819, 56.739309],
            [30.82248, 56.750426],
            [30.791055, 56.754281],
            [30.772483, 56.764499],
            [30.81785, 56.811154],
            [30.867493, 56.809484],
            [30.870126, 56.828185],
            [30.88497, 56.838853],
            [30.886512, 56.859545],
            [30.874752, 56.853954],
            [30.879827, 56.885764],
            [30.900005, 56.890327],
            [30.89615, 56.871177],
            [30.927479, 56.863016],
            [30.962436, 56.889493],
            [30.941615, 56.895983],
            [30.975962, 56.911792],
            [30.967542, 56.946301],
            [30.985984, 56.960182],
            [30.978757, 56.969897],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5470",
      properties: {
        name: "Томская область",
        density: 0,
        path: "/world/Russia/Томская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [75.108237, 58.576245],
            [75.199295, 58.618682],
            [75.131297, 58.663889],
            [75.375765, 58.785863],
            [75.520414, 58.858804],
            [75.635376, 58.912346],
            [75.701148, 58.968584],
            [75.72395, 59.001001],
            [75.727608, 59.015372],
            [75.679617, 59.120133],
            [75.639673, 59.211579],
            [75.841198, 59.280213],
            [75.898666, 59.399905],
            [75.992856, 59.380677],
            [76.003504, 59.393173],
            [76.177166, 59.530001],
            [76.415635, 59.544651],
            [76.502463, 59.543769],
            [76.664888, 59.571174],
            [76.674988, 59.588059],
            [76.652304, 59.606796],
            [76.667053, 59.683865],
            [76.740251, 59.71086],
            [76.772889, 59.991184],
            [76.75775, 60.001698],
            [76.772433, 60.065674],
            [76.713994, 60.115036],
            [76.749723, 60.126812],
            [76.769302, 60.142511],
            [76.790061, 60.148209],
            [76.806478, 60.170538],
            [76.827144, 60.179173],
            [76.831701, 60.219247],
            [76.85708, 60.233379],
            [76.850307, 60.264683],
            [76.866349, 60.271042],
            [76.837463, 60.292637],
            [76.834355, 60.303913],
            [76.79954, 60.325239],
            [76.772165, 60.325936],
            [76.763255, 60.376239],
            [76.776925, 60.388346],
            [76.776916, 60.406918],
            [76.812711, 60.420958],
            [76.803234, 60.440637],
            [76.819377, 60.446376],
            [76.799347, 60.468751],
            [76.829158, 60.466948],
            [76.883037, 60.480854],
            [76.908805, 60.481291],
            [76.923395, 60.490034],
            [76.962345, 60.495359],
            [76.99012, 60.510802],
            [77.004468, 60.50554],
            [77.047763, 60.50892],
            [77.072209, 60.533701],
            [77.058118, 60.546489],
            [77.063081, 60.576115],
            [77.042664, 60.584034],
            [77.061724, 60.59976],
            [77.048133, 60.62515],
            [77.039846, 60.615672],
            [76.99538, 60.618996],
            [76.965589, 60.63119],
            [76.963016, 60.651213],
            [77.001348, 60.651969],
            [77.004717, 60.667158],
            [77.026476, 60.680886],
            [77.012741, 60.692579],
            [77.030456, 60.692589],
            [77.097546, 60.70529],
            [77.112207, 60.746307],
            [77.078079, 60.771349],
            [77.067248, 60.794377],
            [77.090354, 60.804548],
            [77.09656, 60.82307],
            [77.072066, 60.841782],
            [77.107935, 60.858414],
            [77.195721, 60.856371],
            [77.259611, 60.838249],
            [77.307203, 60.839951],
            [77.397008, 60.813889],
            [77.449238, 60.810023],
            [77.511705, 60.817487],
            [77.582634, 60.821176],
            [77.704323, 60.81999],
            [77.776443, 60.805875],
            [77.889358, 60.778865],
            [77.91946, 60.761766],
            [77.969963, 60.740696],
            [77.996985, 60.751659],
            [78.088345, 60.796784],
            [78.195398, 60.802477],
            [78.335904, 60.789241],
            [78.486753, 60.764639],
            [78.643277, 60.816575],
            [78.699992, 60.825356],
            [78.716328, 60.812821],
            [78.718459, 60.797904],
            [78.75491, 60.786997],
            [78.776975, 60.767922],
            [78.761713, 60.75473],
            [78.795277, 60.750058],
            [78.819789, 60.767594],
            [78.847018, 60.770127],
            [78.859229, 60.762831],
            [78.918998, 60.794736],
            [78.95341, 60.80266],
            [78.98489, 60.820193],
            [78.999552, 60.803684],
            [79.009519, 60.810334],
            [79.038341, 60.803207],
            [79.042641, 60.819355],
            [79.063027, 60.80688],
            [79.098451, 60.80536],
            [79.102804, 60.819846],
            [79.126536, 60.809552],
            [79.154585, 60.813621],
            [79.165905, 60.826351],
            [79.188381, 60.828644],
            [79.264254, 60.80492],
            [79.280243, 60.807383],
            [79.308211, 60.794421],
            [79.316109, 60.773877],
            [79.281332, 60.766679],
            [79.282397, 60.756617],
            [79.306394, 60.747471],
            [79.292835, 60.732147],
            [79.296047, 60.707553],
            [79.310196, 60.691681],
            [79.333194, 60.692619],
            [79.347647, 60.667564],
            [79.39887, 60.63584],
            [79.466066, 60.651819],
            [79.511089, 60.66799],
            [79.552842, 60.666662],
            [79.586964, 60.676276],
            [79.616099, 60.672919],
            [79.653362, 60.686547],
            [79.674619, 60.679882],
            [79.948533, 60.670061],
            [79.99238, 60.673476],
            [80.200997, 60.675322],
            [80.404972, 60.750197],
            [80.639058, 60.770738],
            [80.704837, 60.802779],
            [80.98408, 60.774523],
            [81.052447, 60.755725],
            [81.066821, 60.698241],
            [81.082297, 60.66997],
            [81.098435, 60.663329],
            [81.10419, 60.642048],
            [81.290592, 60.624811],
            [81.458741, 60.607139],
            [81.584263, 60.637983],
            [81.879534, 60.639791],
            [82.167305, 60.51634],
            [82.383422, 60.600347],
            [82.409601, 60.608155],
            [82.395229, 60.642404],
            [82.376451, 60.706879],
            [82.454093, 60.739884],
            [82.666543, 60.826982],
            [82.810137, 60.887063],
            [83.117912, 61.0124],
            [83.156683, 61.026795],
            [83.476909, 61.035672],
            [83.949694, 60.836217],
            [84.037853, 60.842435],
            [84.26311, 60.862518],
            [84.268217, 60.836548],
            [84.287673, 60.827181],
            [84.293921, 60.808916],
            [84.351305, 60.796932],
            [84.483434, 60.6648],
            [84.551635, 60.600516],
            [84.659268, 60.488676],
            [84.747627, 60.40053],
            [84.791487, 60.352366],
            [84.774695, 60.327504],
            [84.752084, 60.328742],
            [84.746543, 60.312453],
            [84.720161, 60.309016],
            [84.724749, 60.291218],
            [84.744393, 60.271115],
            [84.738281, 60.251798],
            [84.695195, 60.232382],
            [84.644489, 60.183854],
            [84.616018, 60.168548],
            [84.623418, 60.142768],
            [84.633451, 60.029796],
            [84.596798, 60.016354],
            [84.575203, 59.983973],
            [84.581069, 59.962095],
            [84.681663, 59.89955],
            [84.70755, 59.89207],
            [84.875863, 59.890405],
            [85.02993, 59.890304],
            [85.499551, 59.883032],
            [85.810271, 59.922616],
            [85.998473, 59.947149],
            [86.018937, 59.948568],
            [86.187707, 59.949625],
            [86.498852, 59.948596],
            [86.636499, 59.948654],
            [87.078047, 59.879074],
            [87.100398, 59.826583],
            [87.141509, 59.742885],
            [87.174086, 59.682528],
            [87.289785, 59.675226],
            [87.514225, 59.66441],
            [87.610136, 59.563763],
            [87.737545, 59.424316],
            [87.819717, 59.339384],
            [87.902226, 59.256238],
            [88.499308, 59.286529],
            [88.626467, 59.295126],
            [88.575826, 59.212216],
            [88.82866, 59.022604],
            [88.758918, 59.000665],
            [88.484038, 58.925682],
            [88.439381, 58.914215],
            [88.43196, 58.906989],
            [88.382657, 58.893119],
            [88.336587, 58.854956],
            [88.259621, 58.795813],
            [88.214673, 58.754299],
            [87.924013, 58.511345],
            [88.016988, 58.214258],
            [88.069853, 58.220064],
            [88.106659, 58.112772],
            [88.316451, 58.084336],
            [88.499757, 58.06183],
            [88.687253, 58.036199],
            [88.858954, 57.955186],
            [89.046761, 57.953132],
            [89.29641, 57.948954],
            [89.301527, 57.932874],
            [89.353245, 57.912996],
            [89.350168, 57.906931],
            [89.372526, 57.866694],
            [89.359155, 57.85666],
            [89.370576, 57.846373],
            [89.342479, 57.825677],
            [89.362799, 57.801674],
            [89.353726, 57.7863],
            [89.3549, 57.691536],
            [89.369805, 57.679281],
            [89.369804, 57.64867],
            [89.355248, 57.615657],
            [89.2115, 57.594065],
            [89.130337, 57.615341],
            [89.098764, 57.517209],
            [89.160477, 57.492393],
            [89.137288, 57.482803],
            [89.08392, 57.489343],
            [89.090307, 57.50261],
            [89.065828, 57.481676],
            [89.063639, 57.457552],
            [89.030142, 57.46398],
            [89.024766, 57.486414],
            [89.042849, 57.50095],
            [89.023645, 57.503043],
            [88.970841, 57.498411],
            [88.974953, 57.484618],
            [88.944947, 57.438103],
            [88.90098, 57.4361],
            [88.892182, 57.459946],
            [88.869036, 57.476367],
            [88.86505, 57.44362],
            [88.789771, 57.311617],
            [88.734155, 57.208035],
            [88.622505, 57.209349],
            [88.570971, 57.140888],
            [88.556312, 57.140655],
            [88.549875, 57.121331],
            [88.506549, 57.125246],
            [88.520819, 57.098836],
            [88.516982, 57.090443],
            [88.649939, 57.079165],
            [88.694879, 57.08222],
            [88.714554, 57.092294],
            [88.697349, 57.047894],
            [88.597557, 56.802198],
            [88.523953, 56.768973],
            [88.424416, 56.83442],
            [88.400726, 56.821552],
            [88.362446, 56.827376],
            [88.327599, 56.791855],
            [88.303566, 56.78264],
            [88.142033, 56.709407],
            [88.057575, 56.688106],
            [88.052597, 56.669813],
            [87.998176, 56.656265],
            [87.931175, 56.631567],
            [87.701479, 56.551007],
            [87.698943, 56.560436],
            [87.654309, 56.543607],
            [87.642603, 56.561621],
            [87.633813, 56.59324],
            [87.653999, 56.600611],
            [87.596665, 56.663656],
            [87.444656, 56.612752],
            [87.36458, 56.592957],
            [87.347585, 56.61096],
            [87.403373, 56.62706],
            [87.383977, 56.645611],
            [87.349474, 56.635181],
            [87.262701, 56.63858],
            [87.242786, 56.630271],
            [87.195664, 56.638864],
            [87.172663, 56.664294],
            [87.128434, 56.651985],
            [87.15384, 56.649154],
            [87.16002, 56.63273],
            [87.149549, 56.622909],
            [87.164827, 56.606379],
            [87.179933, 56.612898],
            [87.181649, 56.595607],
            [87.158864, 56.588387],
            [87.183538, 56.576228],
            [87.193837, 56.5871],
            [87.225251, 56.574715],
            [87.199818, 56.561724],
            [87.137734, 56.548739],
            [87.118012, 56.535604],
            [87.085961, 56.535332],
            [87.000494, 56.54191],
            [86.969635, 56.578898],
            [86.963234, 56.564119],
            [86.925984, 56.551537],
            [86.909466, 56.540903],
            [86.848797, 56.555901],
            [86.838795, 56.586899],
            [86.852139, 56.589539],
            [86.821676, 56.617442],
            [86.804658, 56.626212],
            [86.78771, 56.615559],
            [86.746245, 56.603176],
            [86.67819, 56.643265],
            [86.633685, 56.618225],
            [86.6222, 56.588123],
            [86.597467, 56.593799],
            [86.591258, 56.613592],
            [86.532459, 56.577249],
            [86.488621, 56.569464],
            [86.440552, 56.549862],
            [86.382675, 56.548036],
            [86.364136, 56.594563],
            [86.334438, 56.592011],
            [86.338887, 56.636074],
            [86.216043, 56.628059],
            [86.189443, 56.615721],
            [86.207586, 56.604779],
            [86.191003, 56.603197],
            [86.195642, 56.578759],
            [86.129704, 56.54279],
            [86.105171, 56.537152],
            [86.11507, 56.499507],
            [86.089079, 56.486996],
            [86.052945, 56.477857],
            [86.002583, 56.480861],
            [85.992051, 56.466586],
            [85.976283, 56.463972],
            [85.984631, 56.453579],
            [85.979234, 56.436525],
            [85.886397, 56.426638],
            [85.882771, 56.430095],
            [85.804184, 56.411439],
            [85.797523, 56.386523],
            [85.780658, 56.387092],
            [85.81228, 56.339641],
            [85.790525, 56.333211],
            [85.77307, 56.340125],
            [85.724164, 56.338692],
            [85.692291, 56.332515],
            [85.690436, 56.324757],
            [85.655611, 56.317569],
            [85.628966, 56.3174],
            [85.562492, 56.280707],
            [85.571628, 56.266407],
            [85.674163, 56.234134],
            [85.671139, 56.211846],
            [85.452274, 56.211113],
            [85.453073, 56.194966],
            [85.413864, 56.206097],
            [85.388146, 56.202471],
            [85.386544, 56.210818],
            [85.356947, 56.213516],
            [85.290164, 56.228356],
            [85.231645, 56.225658],
            [85.20188, 56.216867],
            [85.208036, 56.197642],
            [85.11621, 56.195871],
            [85.106345, 56.172957],
            [85.035431, 56.131092],
            [85.043273, 56.125611],
            [84.986862, 56.107777],
            [84.960975, 56.135624],
            [84.926825, 56.154132],
            [84.897481, 56.162817],
            [84.832554, 56.139017],
            [84.757087, 56.127254],
            [84.676729, 56.102758],
            [84.638531, 56.099638],
            [84.591818, 56.0825],
            [84.556656, 56.081066],
            [84.522506, 56.062979],
            [84.468541, 56.052924],
            [84.45382, 56.043149],
            [84.406505, 56.030504],
            [84.385253, 56.001404],
            [84.336326, 55.981167],
            [84.294221, 56.001946],
            [84.250683, 56.034349],
            [84.240179, 56.03059],
            [84.202577, 56.059193],
            [84.074814, 56.017847],
            [84.062283, 56.026322],
            [83.952621, 56.015014],
            [83.919492, 55.973405],
            [83.881595, 55.954169],
            [83.879665, 55.932936],
            [83.851874, 55.93256],
            [83.856092, 55.909589],
            [83.816779, 55.903748],
            [83.775403, 55.857456],
            [83.785981, 55.851842],
            [83.768354, 55.835543],
            [83.738456, 55.835183],
            [83.727803, 55.81655],
            [83.746832, 55.804119],
            [83.732489, 55.784882],
            [83.694061, 55.780928],
            [83.664797, 55.765441],
            [83.668674, 55.750947],
            [83.649676, 55.742896],
            [83.667647, 55.739603],
            [83.643134, 55.720537],
            [83.615655, 55.66466],
            [83.540484, 55.679731],
            [83.501749, 55.706691],
            [83.472582, 55.698702],
            [83.443539, 55.670225],
            [83.422492, 55.676653],
            [83.444034, 55.693661],
            [83.445489, 55.723099],
            [83.415712, 55.726359],
            [83.392158, 55.713955],
            [83.377923, 55.717162],
            [83.33679, 55.710601],
            [83.326275, 55.690585],
            [83.308655, 55.691513],
            [83.315157, 55.703312],
            [83.257878, 55.712177],
            [83.210779, 55.730319],
            [83.218095, 55.755319],
            [83.270875, 55.784504],
            [83.289214, 55.81163],
            [83.293241, 55.827598],
            [83.316456, 55.855875],
            [83.352127, 55.848539],
            [83.382134, 55.85268],
            [83.389744, 55.864454],
            [83.375685, 55.896018],
            [83.400827, 55.895182],
            [83.392628, 55.911335],
            [83.377024, 55.917896],
            [83.31371, 55.961171],
            [83.340379, 55.979045],
            [83.306484, 55.994485],
            [83.229571, 56.012005],
            [83.245368, 56.024073],
            [83.233748, 56.056269],
            [83.302317, 56.095736],
            [83.274158, 56.113939],
            [83.220981, 56.085828],
            [83.17846, 56.113378],
            [83.135373, 56.119503],
            [83.141896, 56.138733],
            [83.166959, 56.148201],
            [83.183429, 56.166086],
            [83.160426, 56.17268],
            [83.177249, 56.184911],
            [83.207977, 56.177363],
            [83.217942, 56.187673],
            [83.212234, 56.208177],
            [83.195788, 56.217952],
            [83.159234, 56.227521],
            [83.120501, 56.20929],
            [83.088379, 56.216049],
            [83.06484, 56.230477],
            [83.148702, 56.288899],
            [83.136898, 56.296951],
            [83.192988, 56.327988],
            [83.157761, 56.362743],
            [83.144675, 56.360275],
            [83.143915, 56.37556],
            [83.26076, 56.407621],
            [83.288813, 56.443041],
            [83.109831, 56.549519],
            [83.091384, 56.549778],
            [83.059284, 56.536245],
            [83.049671, 56.520813],
            [83.028213, 56.516457],
            [83.015853, 56.524032],
            [82.986327, 56.516457],
            [82.936202, 56.533405],
            [82.899124, 56.530281],
            [82.867538, 56.533216],
            [82.807971, 56.516173],
            [82.798015, 56.486802],
            [82.805911, 56.470402],
            [82.797157, 56.461108],
            [82.825137, 56.436062],
            [82.809345, 56.420209],
            [82.766773, 56.406629],
            [82.746517, 56.4053],
            [82.689596, 56.425517],
            [82.641116, 56.417645],
            [82.63116, 56.410238],
            [82.603523, 56.410713],
            [82.557689, 56.397795],
            [82.525417, 56.407769],
            [82.495033, 56.39903],
            [82.454521, 56.397035],
            [82.351524, 56.365386],
            [82.340366, 56.357303],
            [82.297622, 56.357874],
            [82.225696, 56.341989],
            [82.19514, 56.341513],
            [82.159901, 56.33486],
            [81.991904, 56.333547],
            [81.573403, 56.241888],
            [81.475127, 56.331022],
            [81.220274, 56.537593],
            [81.005222, 56.511755],
            [80.543437, 56.456212],
            [80.263517, 56.422445],
            [79.95075, 56.655303],
            [79.75726, 56.798643],
            [79.588576, 56.92316],
            [79.198214, 57.001034],
            [78.999552, 57.028944],
            [78.727446, 57.070035],
            [78.440323, 57.113345],
            [78.463438, 57.16501],
            [78.063128, 57.166244],
            [77.630444, 57.167579],
            [77.25041, 57.168751],
            [76.945459, 57.169691],
            [76.916861, 57.160251],
            [76.697895, 57.215498],
            [76.386868, 57.225836],
            [76.095015, 57.235533],
            [76.008875, 57.342677],
            [75.965411, 57.393694],
            [75.848267, 57.3858],
            [75.780091, 57.495967],
            [75.704124, 57.624997],
            [75.550039, 57.625218],
            [75.576082, 57.878807],
            [75.565762, 57.934585],
            [75.498835, 57.960411],
            [75.384577, 58.000182],
            [75.302963, 58.026804],
            [75.189059, 58.069921],
            [75.071473, 58.116793],
            [75.242489, 58.202615],
            [75.120436, 58.293624],
            [75.056664, 58.339153],
            [75.254965, 58.417351],
            [75.376835, 58.454101],
            [75.265048, 58.50625],
            [75.108237, 58.576245],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5504",
      properties: {
        name: "Тульская область",
        density: 0,
        path: "/world/Russia/Тульская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.272035, 54.839777],
            [37.270913, 54.830663],
            [37.294629, 54.826633],
            [37.303605, 54.850802],
            [37.326773, 54.839153],
            [37.334343, 54.846043],
            [37.358095, 54.834569],
            [37.359138, 54.848037],
            [37.375017, 54.840172],
            [37.391636, 54.843917],
            [37.446294, 54.841587],
            [37.503987, 54.807357],
            [37.497114, 54.793084],
            [37.526134, 54.77949],
            [37.544787, 54.780428],
            [37.556287, 54.760067],
            [37.547996, 54.737604],
            [37.563038, 54.742341],
            [37.626172, 54.743296],
            [37.629987, 54.737922],
            [37.682832, 54.717463],
            [37.727261, 54.724849],
            [37.758426, 54.743],
            [37.763837, 54.771646],
            [37.775636, 54.771395],
            [37.769412, 54.798489],
            [37.779168, 54.815614],
            [37.74816, 54.83237],
            [37.792103, 54.834333],
            [37.869544, 54.843173],
            [37.897804, 54.849918],
            [37.938469, 54.849673],
            [37.97445, 54.839807],
            [37.989826, 54.825024],
            [37.982201, 54.798755],
            [37.955153, 54.782063],
            [37.983619, 54.774917],
            [37.998749, 54.783971],
            [38.039657, 54.764673],
            [38.04482, 54.734262],
            [38.031018, 54.736143],
            [38.027778, 54.718491],
            [38.050275, 54.720807],
            [38.072864, 54.707359],
            [38.119556, 54.714024],
            [38.134454, 54.686644],
            [38.177162, 54.697053],
            [38.187138, 54.692211],
            [38.216253, 54.696624],
            [38.218403, 54.677807],
            [38.255999, 54.662748],
            [38.291259, 54.677533],
            [38.314649, 54.661571],
            [38.313244, 54.647208],
            [38.331786, 54.65502],
            [38.362991, 54.653668],
            [38.391978, 54.63557],
            [38.422812, 54.651189],
            [38.456644, 54.653588],
            [38.483182, 54.630129],
            [38.516588, 54.59149],
            [38.471292, 54.571361],
            [38.446135, 54.57254],
            [38.457998, 54.549169],
            [38.438378, 54.553412],
            [38.423841, 54.541588],
            [38.377215, 54.526831],
            [38.379174, 54.516165],
            [38.398851, 54.5073],
            [38.39772, 54.475176],
            [38.386291, 54.466623],
            [38.420126, 54.456837],
            [38.433834, 54.441871],
            [38.450583, 54.43995],
            [38.470833, 54.422766],
            [38.453655, 54.416074],
            [38.484496, 54.397746],
            [38.527101, 54.395264],
            [38.528351, 54.380648],
            [38.50432, 54.347055],
            [38.519661, 54.320857],
            [38.510521, 54.296849],
            [38.528507, 54.289467],
            [38.570939, 54.290465],
            [38.608143, 54.276476],
            [38.632173, 54.280508],
            [38.639191, 54.264471],
            [38.665982, 54.256714],
            [38.671163, 54.247051],
            [38.711702, 54.244404],
            [38.724765, 54.225577],
            [38.711526, 54.222458],
            [38.720647, 54.209161],
            [38.711233, 54.195275],
            [38.687227, 54.186234],
            [38.712028, 54.180625],
            [38.739799, 54.18192],
            [38.750978, 54.192216],
            [38.781663, 54.156209],
            [38.772957, 54.127261],
            [38.747539, 54.12526],
            [38.718004, 54.102136],
            [38.684832, 54.098183],
            [38.682614, 54.088839],
            [38.710386, 54.059656],
            [38.728626, 54.065834],
            [38.748425, 54.050772],
            [38.753368, 54.03065],
            [38.724862, 54.007291],
            [38.721745, 53.992699],
            [38.750133, 53.998289],
            [38.769137, 54.011057],
            [38.756134, 54.015529],
            [38.759722, 54.028826],
            [38.777785, 54.033004],
            [38.800115, 54.013705],
            [38.799939, 54.004703],
            [38.778787, 53.992347],
            [38.779377, 53.968812],
            [38.820476, 53.938806],
            [38.853306, 53.947985],
            [38.874829, 53.946696],
            [38.887845, 53.919273],
            [38.858426, 53.918155],
            [38.844865, 53.898974],
            [38.794824, 53.909446],
            [38.795296, 53.879733],
            [38.831364, 53.866613],
            [38.855252, 53.847962],
            [38.826834, 53.842784],
            [38.816215, 53.828428],
            [38.793945, 53.829663],
            [38.800065, 53.814836],
            [38.824835, 53.819367],
            [38.848399, 53.814778],
            [38.849165, 53.798245],
            [38.838163, 53.786595],
            [38.816158, 53.781305],
            [38.85508, 53.756294],
            [38.867082, 53.76306],
            [38.925595, 53.771475],
            [38.922183, 53.764826],
            [38.944571, 53.743881],
            [38.950779, 53.72517],
            [38.932187, 53.719522],
            [38.918302, 53.70293],
            [38.921245, 53.680924],
            [38.909831, 53.679689],
            [38.915539, 53.657448],
            [38.873412, 53.636502],
            [38.879414, 53.61991],
            [38.937605, 53.615674],
            [38.944195, 53.598494],
            [38.934075, 53.591316],
            [38.937824, 53.574779],
            [38.906875, 53.5758],
            [38.900358, 53.535917],
            [38.885779, 53.530029],
            [38.866923, 53.554702],
            [38.810569, 53.549374],
            [38.784634, 53.550074],
            [38.769635, 53.541593],
            [38.722323, 53.528485],
            [38.721903, 53.497293],
            [38.757371, 53.479631],
            [38.745666, 53.467224],
            [38.725655, 53.463999],
            [38.725936, 53.443112],
            [38.738273, 53.424677],
            [38.753059, 53.417301],
            [38.765961, 53.398393],
            [38.77178, 53.364328],
            [38.763755, 53.357529],
            [38.689001, 53.358859],
            [38.661069, 53.342387],
            [38.641408, 53.356055],
            [38.624095, 53.353601],
            [38.580113, 53.333204],
            [38.547905, 53.327596],
            [38.528911, 53.302222],
            [38.567743, 53.293881],
            [38.55001, 53.279652],
            [38.564519, 53.278811],
            [38.569707, 53.255891],
            [38.582534, 53.263321],
            [38.604789, 53.251055],
            [38.625536, 53.249583],
            [38.611308, 53.233041],
            [38.643444, 53.215751],
            [38.626449, 53.210962],
            [38.624066, 53.198836],
            [38.608331, 53.189934],
            [38.57756, 53.188462],
            [38.583309, 53.160284],
            [38.613098, 53.163789],
            [38.627397, 53.159514],
            [38.639594, 53.139047],
            [38.655015, 53.142342],
            [38.671049, 53.129568],
            [38.639947, 53.078276],
            [38.616309, 53.0692],
            [38.636444, 53.051641],
            [38.633851, 53.040707],
            [38.602554, 53.043019],
            [38.585733, 53.026898],
            [38.556188, 53.034327],
            [38.565863, 52.99108],
            [38.52952, 52.992201],
            [38.534846, 53.004607],
            [38.519951, 53.019677],
            [38.505372, 53.015121],
            [38.492193, 53.036499],
            [38.467696, 53.028438],
            [38.464473, 53.010354],
            [38.414743, 52.991218],
            [38.370654, 52.989955],
            [38.361859, 52.958133],
            [38.326953, 52.946357],
            [38.320013, 52.976286],
            [38.307922, 52.978249],
            [38.307781, 53.002641],
            [38.274206, 53.012033],
            [38.266145, 53.019743],
            [38.230784, 52.994509],
            [38.193108, 53.021284],
            [38.167629, 53.027732],
            [38.147231, 53.04112],
            [38.132302, 53.027872],
            [38.122278, 53.054437],
            [38.096484, 53.048689],
            [38.042653, 53.043572],
            [38.004592, 53.044903],
            [37.983985, 53.05072],
            [37.925037, 53.055766],
            [37.91186, 53.032074],
            [37.889671, 53.013689],
            [37.866469, 53.012217],
            [37.845237, 53.015455],
            [37.819999, 53.068098],
            [37.824341, 53.086377],
            [37.760832, 53.099325],
            [37.754454, 53.113845],
            [37.730973, 53.127954],
            [37.679015, 53.107583],
            [37.652725, 53.105177],
            [37.648876, 53.096258],
            [37.609804, 53.098542],
            [37.609433, 53.12268],
            [37.582669, 53.157975],
            [37.572872, 53.188019],
            [37.579434, 53.241544],
            [37.575899, 53.248715],
            [37.590463, 53.278939],
            [37.534694, 53.270528],
            [37.535098, 53.26568],
            [37.475918, 53.264215],
            [37.453094, 53.273506],
            [37.465515, 53.287039],
            [37.439863, 53.29431],
            [37.364222, 53.301176],
            [37.365434, 53.291279],
            [37.292722, 53.278553],
            [37.261921, 53.259566],
            [37.206376, 53.25593],
            [37.158125, 53.258045],
            [37.139004, 53.267278],
            [37.127045, 53.262907],
            [37.11498, 53.279156],
            [37.094112, 53.286224],
            [37.071526, 53.279115],
            [37.029775, 53.273852],
            [37.007767, 53.267031],
            [37.012474, 53.296323],
            [36.99619, 53.290203],
            [36.914919, 53.279456],
            [36.89876, 53.272993],
            [36.910777, 53.294302],
            [36.823219, 53.288948],
            [36.813423, 53.295815],
            [36.784136, 53.295815],
            [36.773935, 53.315608],
            [36.748183, 53.305913],
            [36.760457, 53.323755],
            [36.739093, 53.325606],
            [36.731215, 53.343734],
            [36.67567, 53.363628],
            [36.695868, 53.370495],
            [36.699603, 53.395036],
            [36.635626, 53.420219],
            [36.622851, 53.399125],
            [36.5793, 53.384788],
            [36.573274, 53.377057],
            [36.531698, 53.378138],
            [36.511078, 53.389478],
            [36.494591, 53.388288],
            [36.487625, 53.407303],
            [36.452931, 53.431575],
            [36.426208, 53.421834],
            [36.425529, 53.43309],
            [36.383065, 53.449602],
            [36.371735, 53.467763],
            [36.32032, 53.473698],
            [36.323766, 53.499218],
            [36.2879, 53.517555],
            [36.29754, 53.529813],
            [36.296466, 53.549973],
            [36.264378, 53.55474],
            [36.24326, 53.544746],
            [36.228511, 53.548008],
            [36.233427, 53.562824],
            [36.207978, 53.549897],
            [36.173945, 53.539393],
            [36.13163, 53.546765],
            [36.081235, 53.569588],
            [36.060734, 53.573425],
            [36.048918, 53.561104],
            [36.030538, 53.560296],
            [36.015793, 53.573071],
            [36.030437, 53.579939],
            [36.021448, 53.597309],
            [36.037, 53.610236],
            [35.991189, 53.626273],
            [36.003268, 53.659763],
            [36.028584, 53.673355],
            [36.074004, 53.681861],
            [36.070743, 53.699502],
            [36.053512, 53.715563],
            [36.030412, 53.711979],
            [36.028752, 53.726162],
            [35.999686, 53.720999],
            [35.974575, 53.723784],
            [35.963249, 53.715868],
            [35.954414, 53.733176],
            [35.958421, 53.751863],
            [35.93345, 53.752785],
            [35.917532, 53.777077],
            [35.941222, 53.790475],
            [35.949265, 53.783711],
            [35.973197, 53.800197],
            [35.96144, 53.807307],
            [35.965145, 53.824244],
            [35.938151, 53.827419],
            [35.918672, 53.840016],
            [35.900676, 53.840863],
            [35.90491, 53.854942],
            [35.92153, 53.851237],
            [35.975517, 53.856161],
            [36.001558, 53.873151],
            [35.981867, 53.892205],
            [35.981549, 53.908719],
            [36.02008, 53.924704],
            [36.025796, 53.933385],
            [36.055859, 53.946353],
            [36.047179, 53.95588],
            [36.066021, 53.961279],
            [36.110799, 53.95387],
            [36.114505, 53.943178],
            [36.141287, 53.940373],
            [36.156774, 53.956237],
            [36.128371, 53.964773],
            [36.120219, 53.984145],
            [36.093861, 53.98658],
            [36.09868, 54.006953],
            [36.124674, 54.011091],
            [36.12818, 54.031463],
            [36.154173, 54.054264],
            [36.176839, 54.052511],
            [36.211557, 54.06245],
            [36.207958, 54.092492],
            [36.176208, 54.089973],
            [36.176657, 54.102655],
            [36.140949, 54.100316],
            [36.12614, 54.091224],
            [36.105151, 54.106206],
            [36.070163, 54.107465],
            [36.069084, 54.11565],
            [36.103622, 54.118439],
            [36.115405, 54.108635],
            [36.142837, 54.13418],
            [36.130604, 54.143983],
            [36.109827, 54.143263],
            [36.07367, 54.127703],
            [36.087341, 54.144747],
            [36.019792, 54.175642],
            [35.988941, 54.165118],
            [35.971622, 54.167903],
            [35.969872, 54.181488],
            [35.983749, 54.182827],
            [35.987861, 54.195609],
            [36.035892, 54.173484],
            [36.060266, 54.18023],
            [36.141666, 54.176813],
            [36.148502, 54.168538],
            [36.174225, 54.173306],
            [36.205167, 54.155902],
            [36.234578, 54.171553],
            [36.234487, 54.186214],
            [36.247708, 54.200875],
            [36.240872, 54.215805],
            [36.267737, 54.221463],
            [36.309218, 54.220033],
            [36.315943, 54.232107],
            [36.337222, 54.211903],
            [36.362531, 54.211163],
            [36.377139, 54.202666],
            [36.390178, 54.223858],
            [36.435511, 54.217023],
            [36.429394, 54.226107],
            [36.453499, 54.233033],
            [36.446033, 54.238699],
            [36.483, 54.24666],
            [36.490824, 54.256869],
            [36.471035, 54.282593],
            [36.500357, 54.290598],
            [36.500537, 54.282863],
            [36.545239, 54.277377],
            [36.550636, 54.286192],
            [36.587333, 54.278997],
            [36.64058, 54.285608],
            [36.647955, 54.277873],
            [36.680245, 54.289297],
            [36.67044, 54.30027],
            [36.689024, 54.306458],
            [36.697801, 54.294038],
            [36.730522, 54.285922],
            [36.790644, 54.276346],
            [36.798817, 54.267073],
            [36.840507, 54.286994],
            [36.884255, 54.279213],
            [36.891825, 54.286082],
            [36.926247, 54.291733],
            [36.966846, 54.291658],
            [36.974841, 54.299628],
            [36.964199, 54.31973],
            [36.969779, 54.331732],
            [36.931142, 54.342983],
            [36.959388, 54.363503],
            [36.983973, 54.388175],
            [36.957966, 54.419652],
            [36.972763, 54.429393],
            [36.942246, 54.435037],
            [36.941106, 54.446766],
            [36.907202, 54.438313],
            [36.891884, 54.427655],
            [36.875826, 54.446976],
            [36.890308, 54.460459],
            [36.854939, 54.478772],
            [36.881819, 54.485188],
            [36.875511, 54.501732],
            [36.90514, 54.510962],
            [36.941926, 54.50975],
            [36.942882, 54.528016],
            [36.914239, 54.547388],
            [36.922083, 54.550073],
            [36.894968, 54.563494],
            [36.920734, 54.589374],
            [36.942129, 54.592265],
            [36.970507, 54.604839],
            [36.990644, 54.602404],
            [37.004506, 54.591329],
            [37.027094, 54.593696],
            [37.031293, 54.584843],
            [37.059952, 54.581004],
            [37.055458, 54.561195],
            [37.077294, 54.54509],
            [37.121643, 54.551012],
            [37.142571, 54.569363],
            [37.147374, 54.606117],
            [37.162569, 54.62163],
            [37.192101, 54.634859],
            [37.210464, 54.676738],
            [37.245222, 54.707979],
            [37.231864, 54.718342],
            [37.20708, 54.722359],
            [37.196827, 54.735545],
            [37.234961, 54.752882],
            [37.243372, 54.765528],
            [37.229558, 54.805061],
            [37.26874, 54.842015],
            [37.272035, 54.839777],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5479",
      properties: { name: "Тыва", density: 0, path: "/world/Russia/Тыва" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.791531, 51.553761],
            [88.832828, 51.561837],
            [88.852586, 51.570949],
            [88.871952, 51.562409],
            [88.925857, 51.571503],
            [88.950574, 51.562961],
            [88.979509, 51.573712],
            [89.002642, 51.571226],
            [89.017031, 51.584952],
            [89.073396, 51.588621],
            [89.08536, 51.604429],
            [89.086614, 51.623982],
            [89.122516, 51.641807],
            [89.133811, 51.635777],
            [89.167079, 51.636471],
            [89.20975, 51.658379],
            [89.250426, 51.653595],
            [89.263824, 51.641242],
            [89.25368, 51.618754],
            [89.242696, 51.615045],
            [89.243069, 51.594725],
            [89.232859, 51.58383],
            [89.236473, 51.554119],
            [89.24937, 51.543225],
            [89.281275, 51.549685],
            [89.296637, 51.571694],
            [89.337229, 51.594342],
            [89.371724, 51.605179],
            [89.439673, 51.606774],
            [89.463699, 51.590393],
            [89.508172, 51.586279],
            [89.53927, 51.567541],
            [89.573547, 51.559843],
            [89.605862, 51.532166],
            [89.632816, 51.54758],
            [89.658952, 51.539275],
            [89.663736, 51.547449],
            [89.709556, 51.562191],
            [89.739988, 51.584154],
            [89.806998, 51.620814],
            [89.810866, 51.649009],
            [89.845403, 51.667365],
            [89.854276, 51.681292],
            [89.89444, 51.703565],
            [89.903588, 51.729985],
            [89.923436, 51.740522],
            [89.926926, 51.757194],
            [89.952207, 51.755495],
            [90.010591, 51.762722],
            [90.034404, 51.756298],
            [90.030883, 51.746819],
            [90.048108, 51.745316],
            [90.079633, 51.730862],
            [90.091673, 51.744294],
            [90.126676, 51.754936],
            [90.136214, 51.764436],
            [90.122623, 51.78277],
            [90.081351, 51.819487],
            [90.083334, 51.832297],
            [90.067046, 51.861113],
            [90.059192, 51.906475],
            [90.060964, 51.936352],
            [90.030693, 51.936257],
            [90.011789, 51.958472],
            [90.01302, 51.967516],
            [90.045184, 51.973643],
            [90.068908, 51.989341],
            [90.075817, 52.008289],
            [90.097955, 52.017581],
            [90.111641, 52.033575],
            [90.13124, 52.033886],
            [90.137946, 52.046988],
            [90.158465, 52.060766],
            [90.148633, 52.104637],
            [90.140327, 52.111178],
            [90.14716, 52.134761],
            [90.143443, 52.148488],
            [90.164413, 52.153137],
            [90.179742, 52.14748],
            [90.193549, 52.155227],
            [90.239452, 52.158935],
            [90.253919, 52.175734],
            [90.285577, 52.177265],
            [90.297104, 52.170093],
            [90.320446, 52.17825],
            [90.34982, 52.201493],
            [90.372189, 52.20221],
            [90.396362, 52.185383],
            [90.43472, 52.179217],
            [90.486733, 52.195871],
            [90.51651, 52.174938],
            [90.531362, 52.145915],
            [90.558348, 52.12907],
            [90.59153, 52.120923],
            [90.633029, 52.093725],
            [90.666295, 52.096992],
            [90.714413, 52.090288],
            [90.751753, 52.070303],
            [90.76584, 52.047985],
            [90.76007, 52.013615],
            [90.818201, 51.968044],
            [90.83509, 51.921582],
            [90.849343, 51.900384],
            [90.913079, 51.92748],
            [90.930221, 51.925359],
            [90.959499, 51.936094],
            [90.995736, 51.935373],
            [91.017631, 51.944793],
            [91.059129, 51.944793],
            [91.072364, 51.95189],
            [91.105294, 51.939871],
            [91.128547, 51.924214],
            [91.154855, 51.914794],
            [91.164869, 51.899264],
            [91.179211, 51.902404],
            [91.232675, 51.89447],
            [91.259746, 51.876564],
            [91.266281, 51.894045],
            [91.292334, 51.896167],
            [91.355302, 51.891457],
            [91.371087, 51.897652],
            [91.428539, 51.898671],
            [91.485652, 51.894428],
            [91.509499, 51.906988],
            [91.547942, 51.908897],
            [91.585282, 51.901514],
            [91.59275, 51.891585],
            [91.635097, 51.87945],
            [91.729635, 51.872492],
            [91.739224, 51.865703],
            [91.781656, 51.876141],
            [91.804399, 51.886579],
            [91.81942, 51.869267],
            [91.839313, 51.85918],
            [91.87784, 51.867513],
            [91.897103, 51.859396],
            [91.918315, 51.835155],
            [91.914744, 51.826876],
            [91.940068, 51.80902],
            [91.95695, 51.811942],
            [91.995586, 51.794843],
            [92.026645, 51.794302],
            [92.07567, 51.773091],
            [92.130538, 51.789757],
            [92.1919, 51.798415],
            [92.230102, 51.809778],
            [92.25521, 51.822656],
            [92.284321, 51.809129],
            [92.307481, 51.814648],
            [92.364189, 51.818328],
            [92.395465, 51.805882],
            [92.438321, 51.7969],
            [92.461589, 51.802961],
            [92.480961, 51.790732],
            [92.512129, 51.799065],
            [92.529552, 51.791057],
            [92.5565, 51.798199],
            [92.568837, 51.807939],
            [92.593295, 51.813458],
            [92.625653, 51.808156],
            [92.6447, 51.81216],
            [92.662665, 51.833047],
            [92.693833, 51.850903],
            [92.719373, 51.846196],
            [92.763095, 51.848577],
            [92.769047, 51.855503],
            [92.79502, 51.846629],
            [92.81753, 51.848685],
            [92.843287, 51.835049],
            [92.873698, 51.836023],
            [92.890612, 51.84355],
            [92.898372, 51.868922],
            [92.91006, 51.884723],
            [92.947937, 51.904636],
            [92.957677, 51.915782],
            [93.01168, 51.914159],
            [93.075964, 51.935858],
            [93.091872, 51.956311],
            [93.119036, 51.966484],
            [93.146524, 52.015617],
            [93.188514, 52.035421],
            [93.204206, 52.032283],
            [93.237214, 52.037694],
            [93.24717, 52.063234],
            [93.266325, 52.084229],
            [93.309181, 52.104683],
            [93.337751, 52.128059],
            [93.363725, 52.124487],
            [93.394784, 52.127193],
            [93.420216, 52.135418],
            [93.431796, 52.149487],
            [93.472163, 52.171023],
            [93.498893, 52.169832],
            [93.546404, 52.176488],
            [93.576273, 52.166857],
            [93.59348, 52.189637],
            [93.622917, 52.196239],
            [93.642072, 52.192992],
            [93.676812, 52.212364],
            [93.656899, 52.237039],
            [93.66653, 52.257547],
            [93.678543, 52.263932],
            [93.687092, 52.316365],
            [93.716096, 52.326538],
            [93.725403, 52.344395],
            [93.752567, 52.362035],
            [93.765445, 52.381136],
            [93.754082, 52.427726],
            [93.765878, 52.434219],
            [93.767515, 52.451383],
            [93.807977, 52.450886],
            [93.832435, 52.478482],
            [93.829404, 52.495689],
            [93.860248, 52.512193],
            [93.859707, 52.519877],
            [93.88752, 52.525992],
            [93.912627, 52.543253],
            [93.919445, 52.558404],
            [93.943363, 52.566196],
            [93.965223, 52.601747],
            [93.941523, 52.613327],
            [93.97702, 52.628153],
            [93.999205, 52.649311],
            [94.030265, 52.659159],
            [94.058078, 52.660891],
            [94.093142, 52.676258],
            [94.102883, 52.68589],
            [94.09087, 52.714461],
            [94.093575, 52.73854],
            [94.13957, 52.754449],
            [94.140002, 52.77539],
            [94.131886, 52.782208],
            [94.159374, 52.813159],
            [94.141842, 52.831178],
            [94.157642, 52.830745],
            [94.191083, 52.845788],
            [94.209265, 52.859532],
            [94.244221, 52.864402],
            [94.261753, 52.883774],
            [94.279934, 52.886263],
            [94.296925, 52.898276],
            [94.331448, 52.893298],
            [94.354283, 52.895679],
            [94.378525, 52.911966],
            [94.399196, 52.914888],
            [94.449952, 52.893027],
            [94.493231, 52.891667],
            [94.51402, 52.909477],
            [94.525925, 52.910019],
            [94.51283, 52.935072],
            [94.525383, 52.958123],
            [94.542374, 52.975222],
            [94.555577, 52.977278],
            [94.583282, 52.996542],
            [94.570187, 53.02073],
            [94.581226, 53.04021],
            [94.600489, 53.046595],
            [94.575706, 53.07457],
            [94.585879, 53.090911],
            [94.590208, 53.119969],
            [94.620943, 53.126354],
            [94.607848, 53.135228],
            [94.616614, 53.159578],
            [94.602437, 53.167803],
            [94.62538, 53.183712],
            [94.657089, 53.187175],
            [94.662609, 53.200919],
            [94.65341, 53.214285],
            [94.698322, 53.21515],
            [94.716503, 53.240799],
            [94.68685, 53.257573],
            [94.677002, 53.28728],
            [94.631764, 53.286198],
            [94.606332, 53.291501],
            [94.600813, 53.305732],
            [94.618345, 53.33111],
            [94.657413, 53.338577],
            [94.677218, 53.357516],
            [94.698538, 53.358923],
            [94.72029, 53.376293],
            [94.744424, 53.377808],
            [94.771588, 53.387223],
            [94.782735, 53.381866],
            [94.812821, 53.392797],
            [94.884681, 53.393176],
            [94.925697, 53.400427],
            [94.942147, 53.388631],
            [94.954593, 53.404864],
            [94.991064, 53.400319],
            [94.995177, 53.376835],
            [95.035869, 53.366121],
            [95.061517, 53.394529],
            [95.094634, 53.401563],
            [95.112707, 53.397992],
            [95.146148, 53.4185],
            [95.152966, 53.434192],
            [95.212813, 53.468174],
            [95.248418, 53.459841],
            [95.267249, 53.448045],
            [95.304586, 53.444907],
            [95.309673, 53.421747],
            [95.284132, 53.410059],
            [95.30253, 53.391283],
            [95.336404, 53.376456],
            [95.375472, 53.381435],
            [95.405126, 53.365634],
            [95.428394, 53.375374],
            [95.423848, 53.395071],
            [95.47309, 53.400698],
            [95.495275, 53.395017],
            [95.490838, 53.415687],
            [95.531313, 53.438468],
            [95.539214, 53.431866],
            [95.577308, 53.438143],
            [95.594624, 53.447342],
            [95.65209, 53.455621],
            [95.66064, 53.47521],
            [95.656852, 53.486356],
            [95.685206, 53.506377],
            [95.708041, 53.506702],
            [95.732224, 53.52522],
            [95.757066, 53.505025],
            [95.807498, 53.491281],
            [95.860419, 53.493229],
            [95.885743, 53.501454],
            [95.93163, 53.488467],
            [95.967127, 53.491281],
            [95.976218, 53.479323],
            [95.993534, 53.48008],
            [96.033252, 53.457029],
            [96.075567, 53.451077],
            [96.110523, 53.451726],
            [96.112795, 53.485816],
            [96.103488, 53.504484],
            [96.135739, 53.511844],
            [96.187253, 53.51509],
            [96.193097, 53.509679],
            [96.232057, 53.517146],
            [96.240715, 53.557946],
            [96.222208, 53.568877],
            [96.225455, 53.597772],
            [96.240931, 53.618767],
            [96.277077, 53.608161],
            [96.272532, 53.590846],
            [96.310085, 53.587816],
            [96.333353, 53.59788],
            [96.374695, 53.593768],
            [96.35121, 53.571366],
            [96.387249, 53.566712],
            [96.384543, 53.557946],
            [96.423395, 53.539441],
            [96.440278, 53.535761],
            [96.439737, 53.513467],
            [96.46084, 53.516822],
            [96.483567, 53.530999],
            [96.482269, 53.541713],
            [96.52361, 53.5468],
            [96.556185, 53.545068],
            [96.559323, 53.551778],
            [96.589409, 53.552427],
            [96.621443, 53.56655],
            [96.62772, 53.556919],
            [96.658672, 53.56417],
            [96.659105, 53.573477],
            [96.686377, 53.590738],
            [96.674148, 53.604158],
            [96.705965, 53.623475],
            [96.687892, 53.632349],
            [96.689007, 53.645835],
            [96.700177, 53.668117],
            [96.710717, 53.673035],
            [96.707983, 53.709238],
            [96.734819, 53.724722],
            [96.760208, 53.718908],
            [96.79882, 53.728141],
            [96.843435, 53.720378],
            [96.88758, 53.73011],
            [96.918321, 53.702283],
            [96.972082, 53.688428],
            [96.976025, 53.671487],
            [97.000997, 53.663362],
            [97.022052, 53.648817],
            [97.058447, 53.646366],
            [97.077679, 53.657607],
            [97.085388, 53.639584],
            [97.13848, 53.610564],
            [97.177222, 53.612917],
            [97.183225, 53.62365],
            [97.203084, 53.619032],
            [97.215899, 53.593799],
            [97.274457, 53.58628],
            [97.297731, 53.57986],
            [97.329068, 53.588836],
            [97.332363, 53.569261],
            [97.347, 53.55703],
            [97.37321, 53.55438],
            [97.389376, 53.544146],
            [97.381697, 53.524872],
            [97.426427, 53.50809],
            [97.422881, 53.499088],
            [97.440588, 53.482447],
            [97.435152, 53.470489],
            [97.456561, 53.455465],
            [97.481233, 53.456097],
            [97.516061, 53.447402],
            [97.536574, 53.451613],
            [97.587548, 53.436353],
            [97.602759, 53.425895],
            [97.606885, 53.394515],
            [97.58953, 53.376688],
            [97.6379, 53.359463],
            [97.67274, 53.363521],
            [97.684698, 53.370649],
            [97.71465, 53.368025],
            [97.729229, 53.376118],
            [97.736898, 53.368394],
            [97.787516, 53.362296],
            [97.798959, 53.373758],
            [97.866066, 53.346704],
            [97.883108, 53.351283],
            [97.902536, 53.34485],
            [97.939596, 53.349517],
            [97.959474, 53.342008],
            [97.9825, 53.348161],
            [97.989152, 53.328596],
            [97.96027, 53.300753],
            [98.002554, 53.275606],
            [98.002828, 53.249523],
            [98.057371, 53.246837],
            [98.079918, 53.25081],
            [98.092163, 53.241691],
            [98.142315, 53.23677],
            [98.150268, 53.244357],
            [98.192373, 53.246006],
            [98.229772, 53.225184],
            [98.236532, 53.208673],
            [98.273667, 53.217505],
            [98.295093, 53.227152],
            [98.319987, 53.212176],
            [98.305157, 53.204468],
            [98.31543, 53.186354],
            [98.298281, 53.173243],
            [98.318025, 53.159717],
            [98.291325, 53.148271],
            [98.296149, 53.121197],
            [98.31211, 53.094827],
            [98.329347, 53.09956],
            [98.355017, 53.086194],
            [98.380716, 53.099745],
            [98.468117, 53.095779],
            [98.506846, 53.107271],
            [98.559658, 53.099931],
            [98.583248, 53.103138],
            [98.637103, 53.101222],
            [98.667312, 53.140974],
            [98.665839, 53.153727],
            [98.724091, 53.15345],
            [98.753547, 53.147359],
            [98.768621, 53.131901],
            [98.798824, 53.134195],
            [98.823519, 53.123815],
            [98.851137, 53.121403],
            [98.879942, 53.125744],
            [98.899073, 53.120402],
            [98.88603, 53.137591],
            [98.891476, 53.14788],
            [98.927803, 53.153476],
            [98.957722, 53.137793],
            [98.961474, 53.127061],
            [99.014152, 53.102184],
            [99.054015, 53.109028],
            [99.0636, 53.092067],
            [99.027788, 53.079106],
            [99.023128, 53.065844],
            [99.002957, 53.059427],
            [98.993968, 53.045745],
            [98.999776, 53.027406],
            [99.078919, 53.003334],
            [99.114848, 52.995082],
            [99.128644, 52.984975],
            [99.165545, 52.98797],
            [99.180928, 52.976117],
            [99.203971, 52.97685],
            [99.230567, 52.963186],
            [99.25523, 52.950453],
            [99.273846, 52.931484],
            [99.258823, 52.93377],
            [99.223225, 52.911944],
            [99.231227, 52.868699],
            [99.204011, 52.87436],
            [99.170972, 52.864426],
            [99.120079, 52.873489],
            [99.103097, 52.882524],
            [99.021124, 52.905575],
            [98.973497, 52.934314],
            [98.96272, 52.930178],
            [98.937518, 52.937471],
            [98.909813, 52.900949],
            [98.893212, 52.896349],
            [98.901703, 52.872509],
            [98.872365, 52.86478],
            [98.860934, 52.839878],
            [98.850266, 52.832802],
            [98.86948, 52.813806],
            [98.850865, 52.801178],
            [98.869807, 52.798456],
            [98.926469, 52.801695],
            [98.950745, 52.786999],
            [98.933981, 52.771922],
            [98.936049, 52.745904],
            [98.956624, 52.730609],
            [98.96174, 52.715559],
            [98.940893, 52.710742],
            [98.932185, 52.691991],
            [98.941329, 52.671743],
            [98.931259, 52.657455],
            [98.908562, 52.649699],
            [98.866106, 52.655904],
            [98.867739, 52.633125],
            [98.856308, 52.62311],
            [98.807157, 52.618837],
            [98.785657, 52.604331],
            [98.798557, 52.57053],
            [98.78495, 52.560052],
            [98.81837, 52.548486],
            [98.830236, 52.534661],
            [98.805797, 52.532783],
            [98.808518, 52.51678],
            [98.794366, 52.506194],
            [98.770199, 52.509133],
            [98.759313, 52.49694],
            [98.744617, 52.500397],
            [98.724369, 52.477618],
            [98.712938, 52.4774],
            [98.712557, 52.458622],
            [98.67021, 52.42746],
            [98.639783, 52.426235],
            [98.63053, 52.415023],
            [98.637497, 52.391563],
            [98.652085, 52.392761],
            [98.667217, 52.36707],
            [98.685505, 52.355912],
            [98.668632, 52.340671],
            [98.654698, 52.306516],
            [98.661991, 52.304557],
            [98.64871, 52.278185],
            [98.657076, 52.263283],
            [98.67745, 52.265013],
            [98.690187, 52.283084],
            [98.710054, 52.290487],
            [98.744508, 52.290051],
            [98.758824, 52.27326],
            [98.785114, 52.268687],
            [98.812003, 52.276961],
            [98.832896, 52.273913],
            [98.825393, 52.253066],
            [98.839218, 52.229117],
            [98.821147, 52.196187],
            [98.833557, 52.186498],
            [98.89501, 52.20729],
            [98.912972, 52.189111],
            [98.930988, 52.187777],
            [98.959619, 52.172265],
            [98.988249, 52.16508],
            [98.969217, 52.1434],
            [98.9111, 52.1496],
            [98.8834, 52.1361],
            [98.8615, 52.1361],
            [98.8572, 52.1136],
            [98.8397, 52.0983],
            [98.8413, 52.0588],
            [98.8268, 52.039],
            [98.8065, 52.0255],
            [98.8196, 52.0147],
            [98.8071, 52],
            [98.809, 51.9581],
            [98.7799, 51.9418],
            [98.7699, 51.9104],
            [98.7643, 51.8699],
            [98.7122, 51.8437],
            [98.6805, 51.8203],
            [98.6645, 51.8258],
            [98.65, 51.8157],
            [98.6124, 51.8063],
            [98.5981, 51.792],
            [98.5546, 51.7902],
            [98.5328, 51.7981],
            [98.5214, 51.7784],
            [98.4523, 51.7366],
            [98.3785, 51.7398],
            [98.354, 51.7315],
            [98.3559, 51.7011],
            [98.3284, 51.6973],
            [98.2918, 51.637],
            [98.2748, 51.6161],
            [98.2477, 51.5971],
            [98.226, 51.5978],
            [98.2204, 51.587],
            [98.245, 51.58],
            [98.2341, 51.5476],
            [98.2544, 51.5351],
            [98.2563, 51.5117],
            [98.2241, 51.4567],
            [98.1866, 51.4618],
            [98.1809, 51.4546],
            [98.126, 51.4713],
            [98.0501, 51.4502],
            [98.049, 51.4365],
            [98.0307, 51.4148],
            [98.0169, 51.3895],
            [98.0041, 51.3849],
            [97.9873, 51.365],
            [97.9772, 51.3649],
            [97.9676, 51.3451],
            [97.9535, 51.3342],
            [97.9645, 51.292],
            [97.962, 51.2758],
            [97.9408, 51.2632],
            [97.9458, 51.2324],
            [97.9347, 51.2179],
            [97.9393, 51.202],
            [97.9186, 51.1702],
            [97.9175, 51.155],
            [97.8991, 51.1494],
            [97.8883, 51.1241],
            [97.896, 51.0983],
            [97.8834, 51.0874],
            [97.8839, 51.0666],
            [97.8541, 51.0636],
            [97.8573, 51.0465],
            [97.8321, 51.0292],
            [97.834, 51.0105],
            [97.8557, 50.9962],
            [97.869, 50.9773],
            [97.868, 50.9605],
            [97.8859, 50.9235],
            [97.916, 50.9149],
            [97.9344, 50.9171],
            [97.9661, 50.8983],
            [97.9903, 50.8894],
            [97.995, 50.8767],
            [98.0309, 50.861],
            [98.0087, 50.8365],
            [97.9889, 50.8327],
            [97.9849, 50.8212],
            [97.9497, 50.8081],
            [97.9786, 50.7841],
            [97.9708, 50.7499],
            [97.991, 50.733],
            [97.9974, 50.6989],
            [98.0245, 50.6885],
            [98.008, 50.6692],
            [98.0567, 50.6364],
            [98.0913, 50.5999],
            [98.1196, 50.6001],
            [98.1453, 50.5832],
            [98.1527, 50.568],
            [98.1811, 50.5538],
            [98.2123, 50.5459],
            [98.2234, 50.5559],
            [98.2773, 50.5427],
            [98.2647, 50.5311],
            [98.2906, 50.4988],
            [98.3315, 50.4981],
            [98.3249, 50.4656],
            [98.29, 50.4421],
            [98.2665, 50.4185],
            [98.2654, 50.4005],
            [98.2795, 50.3961],
            [98.3054, 50.3559],
            [98.2875, 50.3305],
            [98.3072, 50.3261],
            [98.2641, 50.298],
            [98.2477, 50.2734],
            [98.2379, 50.2708],
            [98.2214, 50.2501],
            [98.2051, 50.2158],
            [98.1774, 50.2005],
            [98.1692, 50.1895],
            [98.1849, 50.1725],
            [98.1823, 50.1581],
            [98.1547, 50.1363],
            [98.1296, 50.1325],
            [98.1127, 50.1387],
            [98.1204, 50.1002],
            [98.1027, 50.0775],
            [98.0637, 50.07],
            [98.0699, 50.0359],
            [98.0306, 50.0346],
            [97.9821, 50.0189],
            [97.9783, 50.0018],
            [97.9342, 49.9781],
            [97.8978, 49.984],
            [97.8674, 49.972],
            [97.8678, 49.9539],
            [97.8949, 49.929],
            [97.8617, 49.9168],
            [97.8296, 49.923],
            [97.815, 49.9507],
            [97.7925, 49.9587],
            [97.7809, 49.9755],
            [97.7804, 49.9936],
            [97.7621, 50.0005],
            [97.7472, 49.9834],
            [97.7098, 49.9747],
            [97.7004, 49.9611],
            [97.6781, 49.9618],
            [97.6174, 49.9413],
            [97.5797, 49.9426],
            [97.5731, 49.9318],
            [97.5507, 49.9261],
            [97.5792, 49.9139],
            [97.5683, 49.9049],
            [97.5842, 49.8861],
            [97.5807, 49.8599],
            [97.5589, 49.8453],
            [97.5423, 49.8442],
            [97.5002, 49.8104],
            [97.4629, 49.8036],
            [97.4177, 49.7841],
            [97.3953, 49.7892],
            [97.3581, 49.7806],
            [97.3312, 49.7559],
            [97.2647, 49.7522],
            [97.2287, 49.7445],
            [97.1747, 49.7787],
            [97.1297, 49.7924],
            [97.1291, 49.8086],
            [97.1176, 49.8181],
            [97.08, 49.8213],
            [97.0489, 49.8342],
            [97.0128, 49.8317],
            [97.0106, 49.8525],
            [96.9996, 49.8828],
            [96.9796, 49.8951],
            [96.9547, 49.8919],
            [96.8946, 49.8963],
            [96.8501, 49.8963],
            [96.8174, 49.911],
            [96.7517, 49.9179],
            [96.7289, 49.9273],
            [96.7069, 49.9224],
            [96.6894, 49.9087],
            [96.6768, 49.9101],
            [96.6468, 49.8978],
            [96.6473, 49.887],
            [96.6119, 49.8719],
            [96.589, 49.884],
            [96.5871, 49.8957],
            [96.5527, 49.9408],
            [96.5219, 49.9446],
            [96.502, 49.9262],
            [96.4613, 49.9048],
            [96.4538, 49.8892],
            [96.4351, 49.8744],
            [96.4184, 49.8731],
            [96.3968, 49.8879],
            [96.3554, 49.9068],
            [96.341, 49.9399],
            [96.3239, 49.9465],
            [96.3103, 49.9408],
            [96.2956, 49.955],
            [96.2743, 49.9581],
            [96.2648, 49.9769],
            [96.2049, 49.9807],
            [96.1991, 49.9842],
            [96.1417, 49.9846],
            [96.1077, 49.9936],
            [96.0751, 50.0099],
            [96.0334, 50.0062],
            [96, 49.9871],
            [95.9806, 49.9683],
            [95.9264, 49.9931],
            [95.9097, 49.9944],
            [95.9074, 50.025],
            [95.8528, 50.0461],
            [95.8244, 50.0378],
            [95.7824, 50.0362],
            [95.76232, 50.004611],
            [95.777792, 49.984812],
            [95.778164, 49.973699],
            [95.754742, 49.972658],
            [95.727312, 49.989386],
            [95.720391, 49.974051],
            [95.656474, 49.964735],
            [95.65243, 49.95663],
            [95.629082, 49.956414],
            [95.592991, 49.948966],
            [95.565497, 49.948202],
            [95.5568, 49.9155],
            [95.5336, 49.8971],
            [95.5048, 49.9067],
            [95.4683, 49.9283],
            [95.4364, 49.9578],
            [95.318, 49.9592],
            [95.1998, 49.966],
            [95.0826, 49.9635],
            [95.0111, 49.9854],
            [94.9952, 50.0407],
            [94.9775, 50.05],
            [94.9564, 50.0476],
            [94.9036, 50.0539],
            [94.8493, 50.0566],
            [94.7627, 50.057],
            [94.6896, 50.0428],
            [94.6223, 50.0357],
            [94.607, 50.037],
            [94.5954, 50.0668],
            [94.5726, 50.093],
            [94.5397, 50.1152],
            [94.5067, 50.1748],
            [94.4655, 50.2014],
            [94.4151, 50.2049],
            [94.3988, 50.2202],
            [94.388264, 50.219791],
            [94.383767, 50.239549],
            [94.382595, 50.281148],
            [94.376576, 50.300364],
            [94.341509, 50.501224],
            [94.332049, 50.518559],
            [94.336288, 50.533013],
            [94.3093, 50.535],
            [94.2824, 50.5812],
            [94.2221, 50.5989],
            [94.0343, 50.6007],
            [93.9977, 50.6046],
            [93.9408, 50.5844],
            [93.8874, 50.5965],
            [93.7645, 50.5947],
            [93.7092, 50.5852],
            [93.6754, 50.589],
            [93.6347, 50.6045],
            [93.5683, 50.6048],
            [93.5399, 50.5942],
            [93.5173, 50.5979],
            [93.5218, 50.6133],
            [93.4865, 50.6194],
            [93.4499, 50.6323],
            [93.4202, 50.6333],
            [93.4173, 50.6243],
            [93.3508, 50.6111],
            [93.3424, 50.6219],
            [93.3141, 50.6119],
            [93.2717, 50.6102],
            [93.2533, 50.605],
            [93.249, 50.5941],
            [93.2066, 50.5908],
            [93.1629, 50.6024],
            [93.136, 50.6007],
            [93.136, 50.5856],
            [93.1219, 50.5862],
            [93.057, 50.6188],
            [93.0103, 50.6062],
            [92.9806, 50.6422],
            [92.9594, 50.6619],
            [92.9608, 50.6709],
            [93.0203, 50.7132],
            [93.0274, 50.7337],
            [93.0231, 50.7779],
            [93.0089, 50.7932],
            [92.9423, 50.7959],
            [92.9196, 50.7905],
            [92.8997, 50.8021],
            [92.8557, 50.8084],
            [92.8132, 50.8092],
            [92.7877, 50.8056],
            [92.7679, 50.7928],
            [92.7566, 50.7759],
            [92.7624, 50.7444],
            [92.754, 50.7275],
            [92.7272, 50.7165],
            [92.6465, 50.7055],
            [92.6139, 50.7108],
            [92.5883, 50.7259],
            [92.5925, 50.7403],
            [92.6066, 50.7458],
            [92.6135, 50.762],
            [92.5793, 50.7906],
            [92.5423, 50.7959],
            [92.497, 50.7867],
            [92.4587, 50.79],
            [92.4273, 50.8062],
            [92.4201, 50.8205],
            [92.4029, 50.8312],
            [92.404, 50.8562],
            [92.3867, 50.8743],
            [92.3581, 50.8851],
            [92.3057, 50.8756],
            [92.3232, 50.8434],
            [92.3234, 50.8335],
            [92.2955, 50.7974],
            [92.306, 50.7616],
            [92.2696, 50.7343],
            [92.1723, 50.7085],
            [92.089, 50.697],
            [92.0592, 50.6977],
            [91.9553, 50.7238],
            [91.8559, 50.7354],
            [91.8403, 50.7353],
            [91.7784, 50.7194],
            [91.7155, 50.6899],
            [91.6489, 50.6424],
            [91.6491, 50.5885],
            [91.6337, 50.582],
            [91.604, 50.5844],
            [91.5292, 50.5834],
            [91.5017, 50.5597],
            [91.4582, 50.552],
            [91.4528, 50.5456],
            [91.4539, 50.5106],
            [91.4028, 50.4803],
            [91.415, 50.4507],
            [91.32, 50.4709],
            [91.2933, 50.4696],
            [91.2085, 50.4396],
            [91.16, 50.4201],
            [91.0932, 50.436],
            [91.0765, 50.4311],
            [91.0383, 50.4351],
            [91.0118, 50.4301],
            [90.9696, 50.4294],
            [90.9208, 50.4185],
            [90.903, 50.4084],
            [90.9023, 50.3922],
            [90.9116, 50.3726],
            [90.911, 50.3528],
            [90.8986, 50.3483],
            [90.8828, 50.3559],
            [90.8594, 50.3456],
            [90.8196, 50.3547],
            [90.7956, 50.3264],
            [90.7782, 50.3379],
            [90.7064, 50.3129],
            [90.7308, 50.2434],
            [90.6691, 50.2132],
            [90.6489, 50.2255],
            [90.6182, 50.224],
            [90.5807, 50.2439],
            [90.5387, 50.2421],
            [90.4834, 50.2282],
            [90.4815, 50.2103],
            [90.4528, 50.1971],
            [90.4121, 50.1991],
            [90.388888, 50.192641],
            [90.358675, 50.163399],
            [90.328978, 50.152181],
            [90.285204, 50.110916],
            [90.247782, 50.10431],
            [90.208815, 50.102659],
            [90.165213, 50.108164],
            [90.0673, 50.0873],
            [90.0023, 50.0542],
            [90.0101, 50.0183],
            [90.02523, 50.000288],
            [90.017299, 49.990466],
            [89.97483, 49.971259],
            [89.9587, 49.9717],
            [89.9005, 49.9542],
            [89.877352, 49.952354],
            [89.865945, 49.9585],
            [89.8193, 49.95],
            [89.799152, 49.951763],
            [89.7523, 49.9461],
            [89.721269, 49.949145],
            [89.689512, 49.931021],
            [89.670286, 49.935397],
            [89.632503, 49.926435],
            [89.5915, 49.9111],
            [89.614714, 49.939182],
            [89.606268, 49.94422],
            [89.610565, 49.965447],
            [89.62479, 49.970855],
            [89.605823, 49.978005],
            [89.590116, 50.01716],
            [89.562777, 50.019401],
            [89.543959, 50.01503],
            [89.524695, 50.03935],
            [89.536031, 50.053908],
            [89.532919, 50.064373],
            [89.585893, 50.082043],
            [89.58241, 50.106974],
            [89.511507, 50.119309],
            [89.510025, 50.128571],
            [89.532474, 50.147871],
            [89.525583, 50.183729],
            [89.496244, 50.199066],
            [89.440603, 50.203696],
            [89.416969, 50.192212],
            [89.403485, 50.177932],
            [89.393409, 50.185489],
            [89.367255, 50.181951],
            [89.351252, 50.193231],
            [89.308428, 50.207937],
            [89.333026, 50.21668],
            [89.331247, 50.240869],
            [89.343768, 50.264244],
            [89.390296, 50.28636],
            [89.393333, 50.304771],
            [89.406595, 50.314032],
            [89.379479, 50.314921],
            [89.381479, 50.338889],
            [89.409559, 50.336444],
            [89.496835, 50.363912],
            [89.501577, 50.385732],
            [89.48135, 50.399919],
            [89.490463, 50.442131],
            [89.509059, 50.448392],
            [89.50291, 50.459801],
            [89.545363, 50.445354],
            [89.569294, 50.431555],
            [89.58989, 50.428129],
            [89.613229, 50.374267],
            [89.62901, 50.378045],
            [89.655163, 50.369729],
            [89.664721, 50.38501],
            [89.707322, 50.384121],
            [89.723103, 50.403106],
            [89.786671, 50.419795],
            [89.8189, 50.411201],
            [89.829272, 50.435168],
            [89.847942, 50.441558],
            [89.864909, 50.469675],
            [89.861649, 50.482529],
            [89.842163, 50.499884],
            [89.848757, 50.526519],
            [89.797857, 50.537762],
            [89.786818, 50.550431],
            [89.773334, 50.546134],
            [89.748588, 50.552061],
            [89.735548, 50.566416],
            [89.700208, 50.569675],
            [89.695762, 50.575602],
            [89.664497, 50.57012],
            [89.644048, 50.581307],
            [89.648271, 50.599774],
            [89.631971, 50.617147],
            [89.646048, 50.634447],
            [89.633897, 50.646597],
            [89.578923, 50.648542],
            [89.566921, 50.661896],
            [89.570625, 50.674825],
            [89.54825, 50.687642],
            [89.525727, 50.725798],
            [89.485793, 50.737652],
            [89.471419, 50.755451],
            [89.466529, 50.780475],
            [89.430892, 50.808887],
            [89.414148, 50.801479],
            [89.392218, 50.808221],
            [89.375918, 50.826409],
            [89.398663, 50.850525],
            [89.379178, 50.860694],
            [89.348579, 50.859675],
            [89.346949, 50.881253],
            [89.321907, 50.879642],
            [89.297605, 50.885198],
            [89.282417, 50.876381],
            [89.267747, 50.880456],
            [89.268043, 50.915982],
            [89.250188, 50.915315],
            [89.238778, 50.933429],
            [89.209587, 50.952655],
            [89.193732, 50.981809],
            [89.16906, 50.996071],
            [89.123199, 51.007425],
            [89.11653, 51.02704],
            [89.055555, 51.046395],
            [89.054147, 51.082309],
            [89.033402, 51.099072],
            [89.028882, 51.112445],
            [89.048664, 51.119576],
            [89.037699, 51.133986],
            [89.038365, 51.15036],
            [89.049849, 51.159806],
            [89.033771, 51.185015],
            [89.01562, 51.183977],
            [88.988207, 51.19861],
            [88.967313, 51.202925],
            [88.960719, 51.2283],
            [88.9765, 51.239932],
            [88.960349, 51.243526],
            [88.974277, 51.259751],
            [88.967016, 51.265048],
            [88.977981, 51.28157],
            [88.981463, 51.303982],
            [88.991243, 51.30839],
            [88.99754, 51.335377],
            [88.987538, 51.345064],
            [88.986501, 51.364623],
            [89.003097, 51.368402],
            [89.008876, 51.394259],
            [88.970868, 51.429117],
            [88.932045, 51.447158],
            [88.927007, 51.458437],
            [88.891295, 51.472329],
            [88.863883, 51.467884],
            [88.863067, 51.485813],
            [88.846101, 51.516319],
            [88.824541, 51.516782],
            [88.793794, 51.539712],
            [88.791531, 51.553761],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5484",
      properties: {
        name: "Тюменская область",
        density: 0,
        path: "/world/Russia/Тюменская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [65.95016, 58.614779],
            [66.189208, 58.600152],
            [66.291235, 58.594888],
            [66.315182, 58.584184],
            [66.365657, 58.579901],
            [66.455931, 58.607422],
            [66.619572, 58.63255],
            [66.785238, 58.656545],
            [66.778473, 58.753556],
            [66.772472, 58.923282],
            [66.969014, 58.972043],
            [66.985405, 58.971118],
            [67.052156, 58.932059],
            [67.303189, 59.023444],
            [67.508355, 59.074203],
            [67.612735, 59.104868],
            [67.658222, 59.168561],
            [67.731584, 59.19886],
            [67.793838, 59.2101],
            [68.104095, 59.210562],
            [68.269337, 59.24606],
            [68.402848, 59.306112],
            [68.422361, 59.335435],
            [68.495627, 59.42603],
            [68.573023, 59.588331],
            [68.728619, 59.568394],
            [68.997635, 59.666607],
            [69.044445, 59.686838],
            [69.308508, 59.769741],
            [69.318743, 59.779386],
            [69.312403, 59.793005],
            [69.146265, 59.88369],
            [69.072155, 59.904664],
            [69.134553, 59.946945],
            [69.168493, 59.9663],
            [69.267802, 59.989634],
            [69.391674, 59.97988],
            [69.516071, 59.972482],
            [69.623534, 59.967746],
            [69.651104, 59.909822],
            [69.793495, 59.914664],
            [69.829981, 59.909717],
            [69.884539, 59.923922],
            [69.936402, 59.921026],
            [69.991051, 59.926652],
            [70.03141, 59.941626],
            [70.051135, 59.938116],
            [70.091057, 59.948564],
            [70.134467, 59.949768],
            [70.174414, 59.937187],
            [70.207407, 59.935348],
            [70.221474, 59.923675],
            [70.2614, 59.91089],
            [70.278264, 59.886986],
            [70.308199, 59.870832],
            [70.357046, 59.866397],
            [70.395593, 59.839298],
            [70.497362, 59.851992],
            [70.615243, 59.86386],
            [70.686242, 59.872753],
            [70.743119, 59.884664],
            [70.869606, 59.855939],
            [70.941546, 59.83398],
            [70.999745, 59.827671],
            [71.123609, 59.886132],
            [71.179328, 59.907066],
            [71.226977, 59.830528],
            [71.257995, 59.78872],
            [71.499573, 59.742673],
            [71.544795, 59.685164],
            [71.658937, 59.656328],
            [71.731083, 59.648695],
            [71.792031, 59.635935],
            [71.825303, 59.610622],
            [71.832924, 59.578094],
            [71.863609, 59.559875],
            [71.889945, 59.555058],
            [72.025135, 59.551735],
            [72.086949, 59.530923],
            [72.117824, 59.506947],
            [72.185415, 59.46628],
            [72.231743, 59.413327],
            [72.285589, 59.377837],
            [72.355696, 59.350186],
            [72.442125, 59.300034],
            [72.433427, 59.236061],
            [72.418788, 59.209837],
            [72.452342, 59.192796],
            [72.500308, 59.176215],
            [72.527045, 59.148466],
            [72.553381, 59.138758],
            [72.744002, 59.136852],
            [72.792828, 59.146781],
            [72.867915, 59.155276],
            [72.911212, 59.154002],
            [72.975619, 59.126382],
            [72.956655, 59.086441],
            [72.928297, 59.053141],
            [73.029659, 58.995894],
            [73.099956, 58.951689],
            [73.080952, 58.930454],
            [73.000423, 58.889287],
            [73.02481, 58.874928],
            [73.134862, 58.859054],
            [73.252259, 58.845531],
            [73.500146, 58.824826],
            [73.618932, 58.84385],
            [73.718792, 58.856215],
            [73.787376, 58.838031],
            [73.859082, 58.806781],
            [73.991066, 58.810845],
            [74.084608, 58.749219],
            [74.144895, 58.730214],
            [74.175346, 58.726107],
            [74.509618, 58.721926],
            [74.584939, 58.729831],
            [74.792717, 58.72888],
            [74.838868, 58.705862],
            [74.89456, 58.68515],
            [74.997691, 58.700044],
            [75.131297, 58.663889],
            [75.199295, 58.618682],
            [75.108237, 58.576245],
            [74.951106, 58.493586],
            [74.950558, 58.472194],
            [74.914149, 58.459021],
            [74.913113, 58.430885],
            [74.806145, 58.410053],
            [74.688812, 58.359603],
            [74.661444, 58.345004],
            [74.663241, 58.331007],
            [74.645196, 58.321179],
            [74.642353, 58.302844],
            [74.628585, 58.288288],
            [74.563786, 58.274036],
            [74.455494, 58.242033],
            [74.219631, 58.136298],
            [73.791707, 58.139185],
            [73.47897, 58.143947],
            [73.261116, 58.149947],
            [73.128287, 58.155304],
            [72.947761, 58.069781],
            [72.92548, 58.044752],
            [72.867559, 58.001417],
            [72.835228, 58.003391],
            [72.811536, 57.996748],
            [72.746413, 58.00074],
            [72.326371, 58.008055],
            [72.167156, 58.014698],
            [72.105428, 58.052778],
            [72.089325, 58.086513],
            [72.071223, 58.095156],
            [71.990196, 58.115843],
            [71.541423, 58.0748],
            [71.490403, 58.074241],
            [71.466598, 58.064595],
            [71.402441, 58.064489],
            [71.390028, 58.077982],
            [71.338558, 58.07458],
            [71.326795, 58.077779],
            [71.310618, 58.065751],
            [71.271379, 58.053899],
            [71.214219, 58.054221],
            [71.208449, 58.078466],
            [71.233165, 58.09608],
            [71.20606, 58.111172],
            [71.237838, 58.327859],
            [71.293407, 58.351995],
            [71.291074, 58.361039],
            [71.311412, 58.383055],
            [71.293769, 58.389985],
            [71.276216, 58.408023],
            [71.23364, 58.412253],
            [71.220251, 58.4244],
            [71.194513, 58.419858],
            [71.172763, 58.427704],
            [71.165371, 58.441161],
            [71.140297, 58.442415],
            [71.111075, 58.434179],
            [71.099613, 58.450936],
            [71.122573, 58.454975],
            [71.095853, 58.477055],
            [71.079972, 58.480399],
            [71.084247, 58.496674],
            [71.053455, 58.511108],
            [71.003296, 58.502508],
            [70.961167, 58.505102],
            [70.963236, 58.514521],
            [70.939455, 58.514799],
            [70.943145, 58.528651],
            [70.901177, 58.526307],
            [70.887287, 58.531734],
            [70.852774, 58.529271],
            [70.758948, 58.403319],
            [70.669649, 58.277618],
            [70.540962, 58.10462],
            [70.430413, 57.952164],
            [70.440861, 57.943851],
            [70.376636, 57.940205],
            [70.367825, 57.920026],
            [70.379803, 57.915815],
            [70.358304, 57.900312],
            [70.382948, 57.887385],
            [70.405108, 57.90292],
            [70.426527, 57.893057],
            [70.403375, 57.878436],
            [70.425981, 57.870982],
            [70.420397, 57.84778],
            [70.429808, 57.841476],
            [70.392336, 57.782465],
            [70.47546, 57.768888],
            [70.478006, 57.735192],
            [70.557686, 57.718741],
            [70.598864, 57.577222],
            [70.627927, 57.589325],
            [70.657721, 57.584911],
            [70.681918, 57.574278],
            [70.690201, 57.553787],
            [70.685693, 57.515541],
            [70.632848, 57.501159],
            [70.587554, 57.476986],
            [70.57835, 57.458047],
            [70.418236, 57.415114],
            [70.369213, 57.372048],
            [70.372349, 57.329437],
            [70.45883, 57.282399],
            [70.520669, 57.286626],
            [70.530717, 57.275258],
            [70.569395, 57.272277],
            [70.606542, 57.280058],
            [70.629275, 57.234288],
            [70.587531, 57.223846],
            [70.581932, 57.205669],
            [70.639746, 57.20537],
            [70.701809, 57.201325],
            [70.708643, 57.21229],
            [70.733612, 57.212013],
            [70.71734, 57.232867],
            [70.753752, 57.250143],
            [70.780822, 57.275989],
            [70.734903, 57.286699],
            [70.712722, 57.299281],
            [70.733074, 57.307597],
            [70.73594, 57.322343],
            [70.758672, 57.317596],
            [70.776198, 57.326788],
            [70.763869, 57.341794],
            [70.786795, 57.364979],
            [70.81839, 57.366709],
            [70.829772, 57.378681],
            [70.845196, 57.364726],
            [70.92378, 57.354927],
            [70.948648, 57.383686],
            [70.979721, 57.379774],
            [70.983217, 57.387105],
            [71.006614, 57.376629],
            [70.981098, 57.367342],
            [70.974634, 57.351552],
            [71.01329, 57.345591],
            [71.018686, 57.356162],
            [71.065009, 57.350892],
            [71.052905, 57.293575],
            [71.09501, 57.28738],
            [71.08953, 57.257903],
            [71.070508, 57.258979],
            [71.081857, 57.234356],
            [71.105784, 57.217462],
            [71.115604, 57.202398],
            [71.18601, 57.195199],
            [71.215273, 57.171141],
            [71.22941, 57.174657],
            [71.297296, 57.085244],
            [71.516805, 56.975491],
            [71.604991, 56.932929],
            [71.638385, 56.88099],
            [71.677329, 56.765223],
            [71.630517, 56.755332],
            [71.598779, 56.757744],
            [71.565588, 56.752901],
            [71.581371, 56.743492],
            [71.576622, 56.711275],
            [71.553729, 56.67029],
            [71.449009, 56.676412],
            [71.434151, 56.682177],
            [71.381898, 56.678879],
            [71.392227, 56.647584],
            [71.369887, 56.631768],
            [71.295691, 56.638108],
            [71.263843, 56.656553],
            [71.225917, 56.644557],
            [71.120526, 56.652955],
            [71.075766, 56.691085],
            [71.020382, 56.682954],
            [71.012849, 56.645153],
            [71.113689, 56.624043],
            [71.084598, 56.524166],
            [71.012583, 56.488987],
            [70.984133, 56.462236],
            [70.926303, 56.454477],
            [70.894654, 56.461593],
            [70.800116, 56.449525],
            [70.736817, 56.424495],
            [70.7909, 56.416275],
            [70.811821, 56.442475],
            [70.840635, 56.418571],
            [70.841063, 56.409388],
            [70.871007, 56.403317],
            [70.877066, 56.387356],
            [70.927466, 56.379234],
            [70.911848, 56.356672],
            [70.928243, 56.317054],
            [70.948417, 56.304836],
            [70.851894, 56.301797],
            [70.788846, 56.271192],
            [70.732229, 56.253127],
            [70.773726, 56.212417],
            [70.800242, 56.211516],
            [70.814554, 56.218311],
            [70.823622, 56.195442],
            [70.806176, 56.186314],
            [70.861489, 56.140403],
            [70.879129, 56.146069],
            [70.902366, 56.112283],
            [70.901599, 56.084211],
            [70.913828, 56.085713],
            [70.898741, 56.067523],
            [70.867412, 55.962189],
            [70.80854, 55.902622],
            [70.579544, 55.910326],
            [70.582157, 55.905118],
            [70.480612, 55.905967],
            [70.481471, 55.869793],
            [70.47174, 55.851273],
            [70.524765, 55.841796],
            [70.585787, 55.822901],
            [70.602565, 55.837173],
            [70.6784, 55.842326],
            [70.757141, 55.855256],
            [70.828713, 55.834617],
            [70.778235, 55.824685],
            [70.748688, 55.825476],
            [70.698063, 55.800149],
            [70.647249, 55.799736],
            [70.646012, 55.811069],
            [70.561112, 55.80224],
            [70.550487, 55.791749],
            [70.589364, 55.772024],
            [70.587837, 55.764592],
            [70.54187, 55.736888],
            [70.531725, 55.694373],
            [70.563457, 55.650732],
            [70.64543, 55.624773],
            [70.710092, 55.625407],
            [70.753997, 55.609054],
            [70.756972, 55.584722],
            [70.719824, 55.559855],
            [70.673372, 55.490529],
            [70.636919, 55.49852],
            [70.56696, 55.493869],
            [70.536611, 55.478153],
            [70.439596, 55.407525],
            [70.4565, 55.392328],
            [70.498739, 55.330814],
            [70.486614, 55.297933],
            [70.460501, 55.279672],
            [70.4118, 55.2511],
            [70.4058, 55.2143],
            [70.3046, 55.1948],
            [70.2294, 55.1471],
            [70.2011, 55.1474],
            [70.1795, 55.1583],
            [70.1063, 55.1761],
            [70.0631, 55.207],
            [69.9517, 55.2097],
            [69.9378, 55.2206],
            [69.8671, 55.2957],
            [69.7937, 55.323],
            [69.7829, 55.3358],
            [69.738048, 55.33838],
            [69.747474, 55.344894],
            [69.710368, 55.3517],
            [69.683825, 55.350708],
            [69.676348, 55.4804],
            [69.66902, 55.448425],
            [69.656085, 55.435421],
            [69.584804, 55.434295],
            [69.58182, 55.416656],
            [69.621449, 55.39583],
            [69.636919, 55.381075],
            [69.634523, 55.366757],
            [69.660586, 55.359937],
            [69.659829, 55.349568],
            [69.549925, 55.351067],
            [69.538625, 55.336368],
            [69.4945, 55.3364],
            [69.4552, 55.3421],
            [69.4506, 55.351],
            [69.416, 55.3556],
            [69.4225, 55.3807],
            [69.3895, 55.3889],
            [69.3627, 55.389],
            [69.3479, 55.3823],
            [69.3467, 55.3666],
            [69.334, 55.353],
            [69.3086, 55.3424],
            [69.2061, 55.3301],
            [69.1733, 55.3822],
            [69.1087, 55.4074],
            [69.0471, 55.4236],
            [68.9855, 55.4227],
            [68.9855, 55.4416],
            [68.935, 55.4398],
            [68.9193, 55.3832],
            [68.9225, 55.3645],
            [68.9824, 55.3644],
            [68.9871, 55.3444],
            [69.0029, 55.3257],
            [69.0092, 55.2853],
            [68.9887, 55.2837],
            [68.9619, 55.3257],
            [68.9399, 55.3185],
            [68.85, 55.3436],
            [68.8153, 55.3597],
            [68.7521, 55.3768],
            [68.714266, 55.347519],
            [68.674592, 55.360946],
            [68.682148, 55.387897],
            [68.601612, 55.410634],
            [68.599243, 55.430458],
            [68.555691, 55.430851],
            [68.486273, 55.417287],
            [68.4739, 55.433147],
            [68.447332, 55.448561],
            [68.432854, 55.476641],
            [68.372716, 55.489629],
            [68.321853, 55.507337],
            [68.313915, 55.531461],
            [68.317003, 55.5694],
            [68.292587, 55.576648],
            [68.230727, 55.575236],
            [68.188755, 55.589124],
            [68.17737, 55.618867],
            [68.179072, 55.657672],
            [68.192357, 55.676914],
            [68.174467, 55.684289],
            [68.177142, 55.72036],
            [68.138859, 55.728834],
            [68.09471, 55.71917],
            [68.09223, 55.710829],
            [68.041776, 55.696473],
            [68.011171, 55.693712],
            [67.972331, 55.664005],
            [67.920944, 55.654964],
            [67.894692, 55.673417],
            [67.853535, 55.678494],
            [67.844043, 55.6854],
            [67.848303, 55.714853],
            [67.822177, 55.729012],
            [67.79429, 55.728749],
            [67.74655, 55.719513],
            [67.731729, 55.70951],
            [67.719124, 55.714405],
            [67.633784, 55.714146],
            [67.614331, 55.721569],
            [67.591939, 55.693749],
            [67.574727, 55.68669],
            [67.565377, 55.710337],
            [67.554481, 55.71839],
            [67.462485, 55.718991],
            [67.449763, 55.735538],
            [67.40392, 55.727855],
            [67.340969, 55.82508],
            [67.318994, 55.855776],
            [67.333167, 55.869666],
            [67.299739, 55.888765],
            [67.283794, 55.914767],
            [67.278328, 55.938899],
            [67.303046, 55.9629],
            [67.184004, 55.962381],
            [67.099887, 55.958139],
            [67.099064, 55.969277],
            [66.983715, 55.971465],
            [66.989894, 55.992353],
            [66.92495, 55.992638],
            [66.924861, 55.973432],
            [66.76387, 55.975632],
            [66.763542, 56.009534],
            [66.722086, 56.026798],
            [66.723401, 56.042495],
            [66.669975, 56.040107],
            [66.635863, 56.006067],
            [66.586198, 55.998765],
            [66.518225, 55.973076],
            [66.4811, 55.966471],
            [66.473329, 55.956088],
            [66.451777, 55.959582],
            [66.422473, 55.993962],
            [66.391701, 56.000042],
            [66.35573, 56.013219],
            [66.30855, 56.049543],
            [66.292425, 56.057455],
            [66.258997, 56.062678],
            [66.161296, 56.068576],
            [66.122045, 56.036595],
            [66.071999, 56.011522],
            [66.020971, 55.993602],
            [65.996445, 56.001231],
            [65.903161, 56.040181],
            [65.84187, 56.068988],
            [65.777827, 56.131316],
            [65.79186, 56.137115],
            [65.778002, 56.157672],
            [65.73308, 56.188621],
            [65.707607, 56.189252],
            [65.688219, 56.19797],
            [65.689082, 56.214087],
            [65.673665, 56.213742],
            [65.683123, 56.269664],
            [65.454226, 56.285428],
            [65.35355, 56.285693],
            [65.234286, 56.276729],
            [65.207996, 56.288089],
            [65.180078, 56.28877],
            [65.122002, 56.307573],
            [65.094151, 56.334932],
            [65.125872, 56.345926],
            [65.207091, 56.338509],
            [65.219061, 56.344992],
            [65.213432, 56.364235],
            [65.160137, 56.364429],
            [65.136775, 56.372141],
            [65.12467, 56.410616],
            [65.105062, 56.40784],
            [65.083579, 56.434922],
            [65.093433, 56.441139],
            [65.031883, 56.466854],
            [64.996718, 56.504587],
            [64.989483, 56.518366],
            [65.015652, 56.541928],
            [64.99417, 56.547821],
            [64.998529, 56.571001],
            [65.012426, 56.576128],
            [65.003239, 56.598938],
            [64.985705, 56.609939],
            [65.004166, 56.610595],
            [65.013137, 56.621989],
            [65.034667, 56.614714],
            [65.028727, 56.641531],
            [64.979605, 56.636822],
            [64.972266, 56.643221],
            [64.991497, 56.66181],
            [65.000835, 56.68159],
            [64.978742, 56.686504],
            [64.991291, 56.717528],
            [64.945, 56.736348],
            [64.94638, 56.761552],
            [64.927055, 56.7724],
            [65.012052, 56.769871],
            [65.053912, 56.771696],
            [65.169683, 56.764035],
            [65.18937, 56.84848],
            [65.217211, 56.844796],
            [65.253148, 56.920506],
            [65.21865, 56.920309],
            [65.119392, 56.903335],
            [65.111006, 56.886083],
            [65.056195, 56.903916],
            [65.074041, 56.946594],
            [65.038627, 56.945173],
            [65.043994, 56.968472],
            [65.009481, 56.966165],
            [65.034586, 57.005936],
            [65.000523, 57.020817],
            [65.012879, 57.043072],
            [64.988948, 57.055128],
            [64.982491, 57.067818],
            [64.980703, 57.103267],
            [64.995855, 57.107912],
            [64.981954, 57.118295],
            [64.962519, 57.156659],
            [65.005086, 57.233812],
            [64.95345, 57.246238],
            [64.924382, 57.263209],
            [64.931415, 57.291914],
            [64.908676, 57.293456],
            [64.8834, 57.280614],
            [64.860162, 57.290242],
            [64.827192, 57.29312],
            [64.843277, 57.339535],
            [64.897929, 57.332372],
            [64.859285, 57.363699],
            [64.869937, 57.38826],
            [64.849863, 57.451283],
            [64.898944, 57.45407],
            [64.905756, 57.482204],
            [64.926832, 57.500057],
            [64.933775, 57.526673],
            [64.928946, 57.541842],
            [64.937297, 57.555393],
            [64.960745, 57.562163],
            [64.990901, 57.56174],
            [64.966058, 57.60507],
            [64.9057, 57.592512],
            [64.899133, 57.623583],
            [64.863317, 57.621185],
            [64.857362, 57.64008],
            [64.833088, 57.64825],
            [64.946655, 57.685385],
            [65.149519, 57.747197],
            [65.243327, 57.776706],
            [65.406755, 57.825821],
            [65.417822, 57.837011],
            [65.426235, 57.808643],
            [65.45231, 57.78824],
            [65.462756, 57.762534],
            [65.498663, 57.762677],
            [65.503956, 57.741941],
            [65.519744, 57.741236],
            [65.53412, 57.762144],
            [65.606836, 57.78677],
            [65.597687, 57.796947],
            [65.627039, 57.796776],
            [65.655565, 57.824863],
            [65.684633, 57.838084],
            [65.634185, 57.859273],
            [65.635426, 57.872906],
            [65.655795, 57.875543],
            [65.668128, 57.898469],
            [65.702491, 57.888426],
            [65.748751, 57.894306],
            [65.759888, 57.879992],
            [65.794769, 57.874798],
            [65.808367, 57.908832],
            [65.824986, 57.932635],
            [65.874673, 57.942074],
            [65.984069, 57.944862],
            [65.980787, 57.965104],
            [66.006182, 57.977917],
            [66.036734, 57.984586],
            [66.041549, 57.992909],
            [66.076838, 58.00148],
            [66.073006, 57.993859],
            [66.104302, 57.996146],
            [66.142993, 58.007877],
            [66.184857, 58.01097],
            [66.154875, 58.048428],
            [66.000058, 58.248693],
            [65.993941, 58.33466],
            [65.983403, 58.393562],
            [65.95016, 58.614779],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5544",
      properties: {
        name: "Удмуртская республика",
        density: 0,
        path: "/world/Russia/Удмуртская республика",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [53.842667, 56.617525],
              [53.882409, 56.625908],
              [53.889345, 56.645707],
              [53.923308, 56.649609],
              [53.945975, 56.646311],
              [53.962474, 56.628799],
              [53.981117, 56.633568],
              [53.987186, 56.64831],
              [54.013632, 56.674432],
              [54.022272, 56.656415],
              [54.095682, 56.654968],
              [54.076377, 56.640808],
              [54.044127, 56.642096],
              [53.992823, 56.616226],
              [53.957127, 56.602785],
              [53.853217, 56.599894],
              [53.842667, 56.617525],
            ],
          ],
          [
            [
              [53.785599, 58.455538],
              [53.772575, 58.420644],
              [53.791731, 58.401598],
              [53.812801, 58.391611],
              [53.811236, 58.377084],
              [53.840281, 58.365872],
              [53.845873, 58.354893],
              [53.828815, 58.350435],
              [53.854932, 58.342762],
              [53.88668, 58.31436],
              [53.865735, 58.284107],
              [53.833044, 58.270021],
              [53.831076, 58.262961],
              [53.785463, 58.236607],
              [53.82639, 58.210146],
              [53.881299, 58.200116],
              [53.908204, 58.179682],
              [53.885234, 58.164273],
              [53.925447, 58.156092],
              [53.945871, 58.146597],
              [53.93725, 58.134961],
              [53.948822, 58.123932],
              [53.93644, 58.106267],
              [53.94708, 58.084837],
              [53.989937, 58.069713],
              [54.071602, 58.009748],
              [54.07895, 57.991935],
              [54.136468, 57.957979],
              [54.118932, 57.947192],
              [54.102384, 57.925599],
              [54.085662, 57.918409],
              [54.092953, 57.90138],
              [54.130595, 57.875985],
              [54.141852, 57.855673],
              [54.132491, 57.842951],
              [54.137142, 57.815237],
              [54.119574, 57.809495],
              [54.155539, 57.774661],
              [54.138683, 57.756679],
              [54.135365, 57.739321],
              [54.111653, 57.74115],
              [54.155006, 57.71436],
              [54.153117, 57.701943],
              [54.12001, 57.706049],
              [54.088111, 57.686243],
              [54.056055, 57.680584],
              [54.063679, 57.645237],
              [54.050386, 57.643421],
              [54.098266, 57.635629],
              [54.126741, 57.615644],
              [54.103149, 57.606745],
              [54.088364, 57.612969],
              [54.051878, 57.6058],
              [54.059279, 57.594556],
              [54.040194, 57.586715],
              [54.01381, 57.596813],
              [53.992334, 57.588211],
              [53.991837, 57.559337],
              [54.02448, 57.556596],
              [54.043913, 57.570364],
              [54.058109, 57.544873],
              [54.044726, 57.544242],
              [54.051644, 57.528055],
              [54.031841, 57.52388],
              [54.059918, 57.514508],
              [54.063941, 57.529366],
              [54.107028, 57.528856],
              [54.113674, 57.535628],
              [54.142203, 57.532375],
              [54.173095, 57.521944],
              [54.17044, 57.50163],
              [54.143876, 57.483947],
              [54.174891, 57.479743],
              [54.18859, 57.468123],
              [54.273825, 57.461266],
              [54.273253, 57.440659],
              [54.292475, 57.432529],
              [54.218424, 57.410733],
              [54.206791, 57.399698],
              [54.154916, 57.401067],
              [54.142765, 57.38683],
              [54.119877, 57.377806],
              [54.149179, 57.371825],
              [54.124469, 57.346259],
              [54.141776, 57.315905],
              [54.185449, 57.315996],
              [54.205214, 57.29933],
              [54.217127, 57.306307],
              [54.311665, 57.305716],
              [54.310318, 57.283404],
              [54.328177, 57.267346],
              [54.29802, 57.245973],
              [54.299241, 57.239152],
              [54.340702, 57.223288],
              [54.327436, 57.21328],
              [54.319205, 57.18663],
              [54.340671, 57.175154],
              [54.330755, 57.165285],
              [54.344605, 57.158853],
              [54.322196, 57.140156],
              [54.331194, 57.130563],
              [54.319761, 57.110327],
              [54.289141, 57.114539],
              [54.314321, 57.091715],
              [54.311305, 57.0841],
              [54.347069, 57.07743],
              [54.393693, 57.058227],
              [54.393782, 57.041128],
              [54.434826, 57.035921],
              [54.36676, 56.981221],
              [54.303163, 56.97305],
              [54.257175, 56.980451],
              [54.225297, 56.992335],
              [54.153452, 56.980931],
              [54.148268, 56.94274],
              [54.150754, 56.922388],
              [54.124079, 56.891253],
              [54.101643, 56.889308],
              [54.107012, 56.854994],
              [54.097687, 56.843158],
              [54.08791, 56.81075],
              [54.05943, 56.789674],
              [54.061375, 56.778247],
              [54.03253, 56.757927],
              [54.014841, 56.752071],
              [53.978163, 56.752412],
              [53.943293, 56.761489],
              [53.905032, 56.782888],
              [53.873961, 56.815064],
              [53.827534, 56.820257],
              [53.803202, 56.817448],
              [53.796359, 56.800024],
              [53.831831, 56.797308],
              [53.806785, 56.779609],
              [53.82716, 56.759525],
              [53.854054, 56.758561],
              [53.849607, 56.718192],
              [53.885947, 56.717607],
              [53.884949, 56.678908],
              [53.901705, 56.679068],
              [53.884968, 56.662533],
              [53.851004, 56.654217],
              [53.84524, 56.643374],
              [53.865246, 56.620372],
              [53.838854, 56.625688],
              [53.84102, 56.59672],
              [53.864883, 56.588813],
              [53.876681, 56.597965],
              [53.962696, 56.601512],
              [54.050387, 56.638054],
              [54.082409, 56.635401],
              [54.064054, 56.624547],
              [54.033573, 56.589073],
              [54.041618, 56.579077],
              [54.054993, 56.592409],
              [54.070281, 56.577721],
              [54.103281, 56.579168],
              [54.10796, 56.541972],
              [54.141266, 56.532146],
              [54.191302, 56.534463],
              [54.190389, 56.506259],
              [54.205898, 56.498706],
              [54.218731, 56.469356],
              [54.244086, 56.458165],
              [54.328795, 56.482895],
              [54.329599, 56.464091],
              [54.344629, 56.456246],
              [54.338456, 56.430565],
              [54.3468, 56.401529],
              [54.377579, 56.378702],
              [54.374587, 56.364619],
              [54.358961, 56.35295],
              [54.362895, 56.311971],
              [54.416149, 56.311239],
              [54.392625, 56.291526],
              [54.392322, 56.258322],
              [54.337126, 56.24267],
              [54.334995, 56.235009],
              [54.29654, 56.227602],
              [54.270565, 56.21469],
              [54.246823, 56.21398],
              [54.249867, 56.202387],
              [54.195177, 56.198227],
              [54.172247, 56.166315],
              [54.147795, 56.144855],
              [54.118574, 56.131969],
              [54.087819, 56.108145],
              [54.081744, 56.082758],
              [54.035782, 56.022081],
              [54.014881, 56.009474],
              [53.974165, 56.005389],
              [53.953994, 56.0145],
              [53.895782, 56.029451],
              [53.868356, 56.042278],
              [53.84536, 56.044852],
              [53.841463, 56.017905],
              [53.807139, 56.002246],
              [53.80173, 55.988299],
              [53.825844, 55.978912],
              [53.797727, 55.96779],
              [53.791142, 55.972595],
              [53.708927, 55.969034],
              [53.705725, 55.933043],
              [53.697341, 55.925082],
              [53.682536, 55.916414],
              [53.624322, 55.914551],
              [53.623473, 55.908909],
              [53.579757, 55.90215],
              [53.557521, 55.912137],
              [53.532241, 55.906764],
              [53.507646, 55.88672],
              [53.478997, 55.87671],
              [53.436986, 55.875152],
              [53.413967, 55.858484],
              [53.348052, 55.855303],
              [53.271711, 55.859569],
              [53.240618, 55.872167],
              [53.25308, 55.884487],
              [53.250417, 55.896559],
              [53.22217, 55.895562],
              [53.200002, 55.926013],
              [53.219134, 55.940775],
              [53.257555, 55.93497],
              [53.295859, 55.947775],
              [53.326742, 55.987505],
              [53.340592, 55.978752],
              [53.387464, 55.991433],
              [53.392794, 56.000337],
              [53.359959, 56.005378],
              [53.365395, 56.01673],
              [53.403354, 56.007025],
              [53.451607, 56.018596],
              [53.475272, 56.039445],
              [53.441672, 56.049658],
              [53.440649, 56.064614],
              [53.480002, 56.101205],
              [53.500013, 56.133511],
              [53.523714, 56.117237],
              [53.551724, 56.118149],
              [53.568052, 56.125731],
              [53.56423, 56.138877],
              [53.580648, 56.146272],
              [53.574829, 56.180838],
              [53.552748, 56.191886],
              [53.559882, 56.215635],
              [53.575332, 56.236981],
              [53.531333, 56.238731],
              [53.48834, 56.234566],
              [53.454996, 56.257194],
              [53.447116, 56.271196],
              [53.426542, 56.275065],
              [53.400229, 56.263044],
              [53.385672, 56.265059],
              [53.306748, 56.250358],
              [53.273262, 56.253513],
              [53.233604, 56.239378],
              [53.289595, 56.230456],
              [53.315115, 56.216211],
              [53.350944, 56.165816],
              [53.363895, 56.129358],
              [53.34711, 56.109331],
              [53.370341, 56.102424],
              [53.383501, 56.090312],
              [53.364057, 56.089622],
              [53.366844, 56.071078],
              [53.33283, 56.062106],
              [53.313467, 56.071621],
              [53.288165, 56.072028],
              [53.263666, 56.09115],
              [53.263174, 56.107618],
              [53.22271, 56.090752],
              [53.193653, 56.103465],
              [53.155232, 56.158943],
              [53.141962, 56.161147],
              [53.1122, 56.145573],
              [53.088002, 56.14118],
              [53.10837, 56.122272],
              [53.101508, 56.114682],
              [53.064148, 56.129808],
              [53.059643, 56.149255],
              [53.08886, 56.174949],
              [53.066614, 56.177938],
              [53.06336, 56.169992],
              [53.045933, 56.183214],
              [53.048155, 56.197086],
              [53.065107, 56.21165],
              [53.041467, 56.224384],
              [53.025171, 56.217672],
              [53.050152, 56.208909],
              [53.02636, 56.196433],
              [53.042938, 56.170669],
              [52.999291, 56.177529],
              [53.003819, 56.200097],
              [53.021486, 56.202806],
              [53.009818, 56.215781],
              [52.987318, 56.210257],
              [52.974289, 56.223665],
              [52.965534, 56.256086],
              [52.96874, 56.279893],
              [52.980404, 56.281354],
              [52.99797, 56.258809],
              [52.990802, 56.28578],
              [52.945273, 56.282013],
              [52.986722, 56.326079],
              [52.95852, 56.336327],
              [52.973163, 56.344621],
              [52.963672, 56.355202],
              [52.98911, 56.36614],
              [52.98174, 56.388401],
              [53.000433, 56.392734],
              [53.006075, 56.43261],
              [53.024051, 56.446744],
              [53.087366, 56.453921],
              [53.098269, 56.476767],
              [53.098825, 56.500869],
              [53.087018, 56.511086],
              [53.129737, 56.50367],
              [53.130582, 56.517565],
              [53.144828, 56.524698],
              [53.10649, 56.528384],
              [53.093198, 56.52073],
              [53.069569, 56.533292],
              [53.036356, 56.53203],
              [53.05412, 56.540045],
              [53.033488, 56.543349],
              [53.025507, 56.531099],
              [52.945549, 56.538455],
              [52.967203, 56.520349],
              [52.935059, 56.490724],
              [52.920685, 56.500049],
              [52.889844, 56.500476],
              [52.896021, 56.483823],
              [52.935532, 56.484669],
              [52.931649, 56.464146],
              [52.952423, 56.45699],
              [52.969235, 56.459967],
              [52.963986, 56.42843],
              [52.943366, 56.436432],
              [52.938057, 56.416641],
              [52.924081, 56.400796],
              [52.872482, 56.399519],
              [52.870576, 56.389022],
              [52.8294, 56.378934],
              [52.812366, 56.397297],
              [52.777968, 56.397833],
              [52.781037, 56.379893],
              [52.748253, 56.382961],
              [52.766035, 56.374185],
              [52.762962, 56.359201],
              [52.733103, 56.361814],
              [52.722382, 56.325878],
              [52.697375, 56.331041],
              [52.677948, 56.310971],
              [52.646999, 56.293285],
              [52.658589, 56.281619],
              [52.639879, 56.270332],
              [52.620556, 56.282559],
              [52.602532, 56.279317],
              [52.55531, 56.242863],
              [52.584101, 56.222258],
              [52.626649, 56.223272],
              [52.652181, 56.229228],
              [52.665335, 56.221115],
              [52.737629, 56.221247],
              [52.75734, 56.200357],
              [52.84695, 56.187453],
              [52.827525, 56.177577],
              [52.835969, 56.167341],
              [52.882179, 56.159847],
              [52.874704, 56.150318],
              [52.837809, 56.137656],
              [52.821581, 56.118469],
              [52.87093, 56.115299],
              [52.880856, 56.118692],
              [52.869305, 56.095332],
              [52.845043, 56.080128],
              [52.811105, 56.082712],
              [52.787868, 56.100431],
              [52.763591, 56.110176],
              [52.745329, 56.10867],
              [52.747125, 56.125027],
              [52.712548, 56.130197],
              [52.724457, 56.113917],
              [52.71344, 56.102675],
              [52.744853, 56.0958],
              [52.728629, 56.08449],
              [52.756607, 56.06278],
              [52.745429, 56.057345],
              [52.747544, 56.037911],
              [52.735892, 56.006141],
              [52.756727, 55.989558],
              [52.723577, 55.988757],
              [52.699262, 55.999127],
              [52.68173, 55.993474],
              [52.668531, 56.007757],
              [52.628875, 56.015861],
              [52.61411, 56.032784],
              [52.558678, 56.035469],
              [52.531095, 56.060764],
              [52.499964, 56.056767],
              [52.45639, 56.082527],
              [52.456877, 56.07668],
              [52.427004, 56.058084],
              [52.499403, 56.032898],
              [52.447253, 56.008992],
              [52.436406, 56.027056],
              [52.415712, 56.036019],
              [52.379718, 56.019136],
              [52.353467, 56.034136],
              [52.34035, 56.060377],
              [52.319793, 56.072618],
              [52.290685, 56.059605],
              [52.267872, 56.069554],
              [52.242984, 56.098086],
              [52.211522, 56.091916],
              [52.224067, 56.086477],
              [52.236012, 56.064693],
              [52.254252, 56.049764],
              [52.258377, 56.034022],
              [52.233787, 56.040538],
              [52.21898, 56.02117],
              [52.206527, 56.020273],
              [52.209809, 55.980011],
              [52.237303, 55.978344],
              [52.24645, 55.967358],
              [52.234172, 55.947442],
              [52.179353, 55.950341],
              [52.198673, 55.936234],
              [52.188899, 55.923855],
              [52.158165, 55.921706],
              [52.156222, 55.904398],
              [52.123099, 55.905977],
              [52.088767, 55.900923],
              [52.056168, 55.907914],
              [52.055813, 55.918285],
              [52.080038, 55.925803],
              [52.023252, 55.969476],
              [51.979726, 55.976516],
              [51.979359, 55.988097],
              [51.952489, 55.989862],
              [51.941709, 55.961396],
              [51.929855, 55.951743],
              [51.884322, 55.93559],
              [51.854731, 55.946404],
              [51.842545, 55.936216],
              [51.812961, 55.927019],
              [51.78097, 55.886564],
              [51.76996, 55.890978],
              [51.76259, 55.922068],
              [51.781854, 55.93915],
              [51.748067, 55.941209],
              [51.720575, 55.932587],
              [51.711387, 55.941683],
              [51.737503, 55.961703],
              [51.757614, 55.954091],
              [51.761958, 55.968354],
              [51.742772, 55.981557],
              [51.711647, 55.973889],
              [51.708453, 55.94135],
              [51.665684, 55.944045],
              [51.675836, 55.952894],
              [51.647675, 55.971199],
              [51.619682, 55.965075],
              [51.60178, 55.95274],
              [51.589638, 55.954596],
              [51.556819, 55.97704],
              [51.556888, 55.962509],
              [51.525675, 55.953806],
              [51.490616, 55.96021],
              [51.470138, 55.954518],
              [51.456185, 55.922552],
              [51.417425, 55.960242],
              [51.409258, 55.974761],
              [51.413689, 55.991091],
              [51.397933, 56.029644],
              [51.417707, 56.042377],
              [51.43802, 56.034941],
              [51.474682, 56.047718],
              [51.489833, 56.068076],
              [51.521653, 56.069175],
              [51.546785, 56.082977],
              [51.55981, 56.081583],
              [51.635028, 56.090631],
              [51.645638, 56.110942],
              [51.64003, 56.135744],
              [51.598465, 56.126804],
              [51.574024, 56.140989],
              [51.550112, 56.138897],
              [51.53238, 56.122968],
              [51.509884, 56.121719],
              [51.444008, 56.127376],
              [51.433732, 56.146024],
              [51.440862, 56.16153],
              [51.427512, 56.163769],
              [51.446672, 56.195386],
              [51.49131, 56.19885],
              [51.498864, 56.205728],
              [51.485928, 56.219162],
              [51.468763, 56.215098],
              [51.44455, 56.225712],
              [51.406074, 56.213522],
              [51.392599, 56.216507],
              [51.378545, 56.199425],
              [51.357483, 56.198388],
              [51.391023, 56.232478],
              [51.403791, 56.286327],
              [51.377919, 56.287902],
              [51.381235, 56.303906],
              [51.344584, 56.309378],
              [51.3458, 56.320586],
              [51.286024, 56.323433],
              [51.303618, 56.351336],
              [51.278161, 56.349677],
              [51.296321, 56.367671],
              [51.265141, 56.379529],
              [51.270282, 56.392382],
              [51.299262, 56.421902],
              [51.289808, 56.427292],
              [51.19735, 56.433924],
              [51.156551, 56.475758],
              [51.137893, 56.477996],
              [51.146122, 56.510477],
              [51.127693, 56.511828],
              [51.137559, 56.529242],
              [51.159119, 56.534383],
              [51.164757, 56.545826],
              [51.147675, 56.559343],
              [51.180423, 56.684637],
              [51.27396, 56.673527],
              [51.393202, 56.666895],
              [51.40514, 56.719467],
              [51.391374, 56.748987],
              [51.391373, 56.766567],
              [51.438058, 56.773035],
              [51.483415, 56.799073],
              [51.481506, 56.822042],
              [51.493861, 56.841612],
              [51.532916, 56.855875],
              [51.513512, 56.863586],
              [51.513325, 56.879499],
              [51.536232, 56.885229],
              [51.495807, 56.887882],
              [51.476817, 56.914997],
              [51.461892, 56.898744],
              [51.427397, 56.895925],
              [51.392154, 56.908362],
              [51.420099, 56.913836],
              [51.430318, 56.93549],
              [51.422802, 56.942899],
              [51.382734, 56.937734],
              [51.343605, 56.942575],
              [51.348599, 56.965055],
              [51.306492, 56.996175],
              [51.31213, 57.019393],
              [51.27896, 57.021549],
              [51.283106, 57.04087],
              [51.216643, 57.044933],
              [51.245831, 57.069146],
              [51.219129, 57.086311],
              [51.225015, 57.111685],
              [51.243175, 57.121802],
              [51.231483, 57.128436],
              [51.240272, 57.139464],
              [51.19425, 57.140956],
              [51.208393, 57.200064],
              [51.170697, 57.202567],
              [51.179236, 57.241956],
              [51.124165, 57.243277],
              [51.134083, 57.276492],
              [51.154146, 57.375419],
              [51.165254, 57.440762],
              [51.190214, 57.444079],
              [51.216748, 57.459005],
              [51.230846, 57.449552],
              [51.400422, 57.468212],
              [51.530113, 57.458263],
              [51.590067, 57.460752],
              [51.608343, 57.476234],
              [51.62481, 57.478995],
              [51.626881, 57.531568],
              [51.651094, 57.532066],
              [51.674811, 57.515896],
              [51.705575, 57.517389],
              [51.75566, 57.538203],
              [51.767267, 57.575104],
              [51.832307, 57.571415],
              [51.846748, 57.596499],
              [51.82445, 57.598026],
              [51.824069, 57.586714],
              [51.799648, 57.588206],
              [51.815527, 57.608315],
              [51.8511, 57.619178],
              [51.847699, 57.635348],
              [51.868429, 57.65583],
              [51.860468, 57.668932],
              [51.86992, 57.690243],
              [51.883104, 57.69754],
              [51.866105, 57.712964],
              [51.884223, 57.72051],
              [51.864777, 57.729299],
              [51.901262, 57.742319],
              [51.926222, 57.741821],
              [51.919919, 57.753762],
              [51.954249, 57.766864],
              [51.923483, 57.798955],
              [51.932853, 57.803018],
              [51.927628, 57.828973],
              [51.872235, 57.828392],
              [51.866679, 57.842571],
              [51.840144, 57.835937],
              [51.820076, 57.843814],
              [51.847274, 57.857165],
              [51.834053, 57.870933],
              [51.851087, 57.886603],
              [51.824634, 57.913884],
              [51.857554, 57.922591],
              [51.88243, 57.935859],
              [51.891882, 57.948546],
              [51.897187, 57.993573],
              [51.924883, 58.000705],
              [51.897684, 58.012314],
              [51.872226, 58.01306],
              [51.864597, 58.024669],
              [51.824628, 58.017371],
              [51.806302, 58.040008],
              [51.841791, 58.061735],
              [51.830761, 58.10021],
              [51.802152, 58.119448],
              [51.790292, 58.146398],
              [51.767157, 58.151704],
              [51.747671, 58.133378],
              [51.709028, 58.137358],
              [51.685395, 58.148137],
              [51.693395, 58.180975],
              [51.719557, 58.194616],
              [51.744018, 58.222437],
              [51.72312, 58.249386],
              [51.75733, 58.261811],
              [51.77836, 58.277338],
              [51.820346, 58.285681],
              [51.820168, 58.30829],
              [51.809821, 58.321335],
              [51.825249, 58.328881],
              [51.794519, 58.339565],
              [51.803654, 58.355204],
              [51.786873, 58.36115],
              [51.817521, 58.373978],
              [51.852763, 58.373647],
              [51.875401, 58.387247],
              [51.913875, 58.425225],
              [51.964705, 58.453088],
              [52.046715, 58.472991],
              [52.08884, 58.465777],
              [52.173006, 58.489411],
              [52.218863, 58.47432],
              [52.270856, 58.471916],
              [52.296977, 58.462546],
              [52.319755, 58.431612],
              [52.327908, 58.436177],
              [52.627675, 58.411304],
              [52.662835, 58.3987],
              [52.650824, 58.408919],
              [52.843074, 58.392879],
              [52.850254, 58.396989],
              [52.892691, 58.533618],
              [52.910892, 58.536977],
              [53.03419, 58.528306],
              [53.090581, 58.522298],
              [53.098981, 58.545842],
              [53.160052, 58.542172],
              [53.146429, 58.507052],
              [53.179509, 58.500687],
              [53.160667, 58.450482],
              [53.24802, 58.443735],
              [53.213692, 58.401361],
              [53.373651, 58.384282],
              [53.383518, 58.395144],
              [53.415194, 58.399954],
              [53.434929, 58.412891],
              [53.423485, 58.429475],
              [53.488413, 58.440007],
              [53.504666, 58.454353],
              [53.529045, 58.454187],
              [53.534021, 58.441583],
              [53.596213, 58.443574],
              [53.592068, 58.418863],
              [53.620923, 58.474256],
              [53.635767, 58.456179],
              [53.636346, 58.472183],
              [53.785599, 58.455538],
            ],
            [
              [52.198188, 56.063163],
              [52.22231, 56.056262],
              [52.222115, 56.073613],
              [52.198188, 56.063163],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5506",
      properties: {
        name: "Ульяновская область",
        density: 0,
        path: "/world/Russia/Ульяновская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.441731, 54.761445],
            [46.493309, 54.757752],
            [46.492495, 54.746029],
            [46.514475, 54.745215],
            [46.542561, 54.734633],
            [46.550376, 54.719491],
            [46.603291, 54.708584],
            [46.622341, 54.691651],
            [46.658486, 54.674231],
            [46.737531, 54.685629],
            [46.766024, 54.677977],
            [46.776443, 54.684164],
            [46.799972, 54.639554],
            [46.826185, 54.632065],
            [46.875435, 54.645905],
            [46.885611, 54.657953],
            [46.892286, 54.644277],
            [46.952689, 54.680259],
            [47.010243, 54.685144],
            [47.029454, 54.69068],
            [47.034094, 54.705496],
            [47.079356, 54.705171],
            [47.078868, 54.696379],
            [47.108826, 54.675703],
            [47.147414, 54.662841],
            [47.155554, 54.676029],
            [47.169719, 54.652421],
            [47.183396, 54.651607],
            [47.176394, 54.666098],
            [47.207492, 54.657143],
            [47.241031, 54.653562],
            [47.217749, 54.667401],
            [47.261545, 54.668053],
            [47.267867, 54.673486],
            [47.290809, 54.674267],
            [47.334299, 54.667393],
            [47.330579, 54.656757],
            [47.355589, 54.627395],
            [47.344219, 54.621302],
            [47.34828, 54.598786],
            [47.387961, 54.523418],
            [47.396855, 54.496409],
            [47.424562, 54.498545],
            [47.403792, 54.527081],
            [47.435585, 54.544217],
            [47.438299, 54.563704],
            [47.4533, 54.567582],
            [47.485973, 54.557025],
            [47.484604, 54.545734],
            [47.506725, 54.551483],
            [47.539343, 54.530415],
            [47.578564, 54.532811],
            [47.616532, 54.55753],
            [47.634017, 54.590038],
            [47.612967, 54.612949],
            [47.651186, 54.625333],
            [47.693945, 54.658187],
            [47.670676, 54.673729],
            [47.703858, 54.695479],
            [47.711574, 54.708718],
            [47.699687, 54.716239],
            [47.717752, 54.735028],
            [47.822985, 54.728389],
            [47.835538, 54.689849],
            [47.789423, 54.6901],
            [47.770618, 54.682118],
            [47.816443, 54.641706],
            [47.909532, 54.647899],
            [47.928628, 54.64042],
            [47.925297, 54.667446],
            [47.90709, 54.666716],
            [47.903466, 54.696397],
            [47.928122, 54.699064],
            [47.920709, 54.719614],
            [47.945605, 54.726098],
            [47.956142, 54.711976],
            [48.021554, 54.727712],
            [48.038917, 54.703998],
            [48.08097, 54.713872],
            [48.07147, 54.735065],
            [48.069622, 54.763731],
            [48.103415, 54.766523],
            [48.098001, 54.738563],
            [48.115128, 54.739055],
            [48.152644, 54.723838],
            [48.214507, 54.736024],
            [48.211251, 54.72357],
            [48.24946, 54.717956],
            [48.277705, 54.730936],
            [48.265509, 54.711788],
            [48.244404, 54.702361],
            [48.240329, 54.691676],
            [48.280743, 54.663243],
            [48.334246, 54.703423],
            [48.361461, 54.693248],
            [48.351709, 54.670966],
            [48.417203, 54.654331],
            [48.465153, 54.625211],
            [48.881741, 54.625206],
            [48.88178, 54.79602],
            [49.012552, 54.796073],
            [49.031862, 54.814931],
            [49.045095, 54.803485],
            [49.09039, 54.788549],
            [49.125633, 54.802022],
            [49.148209, 54.841825],
            [49.180497, 54.852594],
            [49.238971, 54.841559],
            [49.271525, 54.861326],
            [49.239584, 54.880309],
            [49.288299, 54.891446],
            [49.326852, 54.868201],
            [49.357766, 54.840107],
            [49.405554, 54.839052],
            [49.407582, 54.830578],
            [49.377965, 54.827704],
            [49.436885, 54.803223],
            [49.476072, 54.778521],
            [49.490032, 54.748164],
            [49.485784, 54.732251],
            [49.525816, 54.723598],
            [49.489771, 54.709728],
            [49.479729, 54.688562],
            [49.515484, 54.695095],
            [49.551035, 54.668061],
            [49.536091, 54.649841],
            [49.533726, 54.621959],
            [49.581222, 54.615054],
            [49.584151, 54.597493],
            [49.551415, 54.576555],
            [49.560315, 54.56201],
            [49.620253, 54.555861],
            [49.684911, 54.577329],
            [49.734363, 54.576718],
            [49.76736, 54.571041],
            [49.7859, 54.560068],
            [49.804095, 54.564632],
            [49.848147, 54.537221],
            [49.941565, 54.535254],
            [49.95358, 54.522507],
            [49.952806, 54.508811],
            [49.967143, 54.500718],
            [49.98946, 54.517008],
            [50.015666, 54.50665],
            [50.053813, 54.509024],
            [50.092459, 54.5066],
            [50.093655, 54.493844],
            [50.111543, 54.493446],
            [50.103772, 54.423706],
            [50.155801, 54.421101],
            [50.150591, 54.375477],
            [50.175723, 54.362624],
            [50.188619, 54.368322],
            [50.238884, 54.295933],
            [50.241888, 54.283699],
            [50.208851, 54.276234],
            [50.242641, 54.217581],
            [50.225549, 54.206009],
            [50.217998, 54.180127],
            [50.201921, 54.168069],
            [50.197683, 54.114804],
            [50.209123, 54.108576],
            [50.202101, 54.094133],
            [50.223575, 54.069714],
            [50.182345, 54.023657],
            [50.167685, 54.027643],
            [50.113999, 54.019708],
            [50.111715, 54.003341],
            [50.081107, 54.007111],
            [50.055435, 53.977369],
            [50.060934, 53.95845],
            [50.014957, 53.96229],
            [50.018069, 53.970232],
            [49.97804, 53.978772],
            [49.931196, 53.905269],
            [49.92765, 53.889398],
            [50.037275, 53.880285],
            [50.04009, 53.861213],
            [50.035857, 53.819146],
            [50.022697, 53.818294],
            [49.850446, 53.831996],
            [49.828413, 53.840984],
            [49.837582, 53.861436],
            [49.816593, 53.889757],
            [49.74127, 53.874131],
            [49.727755, 53.866888],
            [49.737869, 53.859689],
            [49.724178, 53.842066],
            [49.703278, 53.830193],
            [49.66283, 53.835484],
            [49.636021, 53.834159],
            [49.60166, 53.802623],
            [49.589646, 53.805273],
            [49.596448, 53.819937],
            [49.585494, 53.821659],
            [49.594302, 53.839897],
            [49.434, 53.855754],
            [49.418364, 53.86207],
            [49.311524, 53.872624],
            [49.300351, 53.831284],
            [49.249735, 53.836495],
            [49.212987, 53.871563],
            [49.07863, 53.873328],
            [48.994669, 53.872317],
            [48.994808, 53.706596],
            [48.851574, 53.70602],
            [48.843844, 53.716841],
            [48.825294, 53.714279],
            [48.782186, 53.73168],
            [48.686609, 53.727659],
            [48.686212, 53.7088],
            [48.669517, 53.705311],
            [48.648183, 53.73],
            [48.640411, 53.716264],
            [48.606888, 53.720327],
            [48.568594, 53.732384],
            [48.534054, 53.767805],
            [48.534452, 53.754511],
            [48.4851, 53.76967],
            [48.467042, 53.759266],
            [48.488828, 53.740554],
            [48.515505, 53.734547],
            [48.513607, 53.716571],
            [48.496603, 53.694885],
            [48.516037, 53.683667],
            [48.511312, 53.670019],
            [48.533131, 53.666707],
            [48.501419, 53.649835],
            [48.474433, 53.646168],
            [48.460874, 53.652838],
            [48.417369, 53.657209],
            [48.418519, 53.623863],
            [48.392504, 53.635479],
            [48.388484, 53.648464],
            [48.367726, 53.641132],
            [48.348159, 53.652527],
            [48.339107, 53.606549],
            [48.345203, 53.593476],
            [48.393875, 53.583097],
            [48.393876, 53.56702],
            [48.455665, 53.569583],
            [48.465294, 53.556244],
            [48.455268, 53.548692],
            [48.406508, 53.563752],
            [48.395025, 53.542993],
            [48.382128, 53.545555],
            [48.369939, 53.527844],
            [48.369543, 53.474137],
            [48.347327, 53.468306],
            [48.32648, 53.476521],
            [48.31694, 53.469322],
            [48.301702, 53.477448],
            [48.285185, 53.457617],
            [48.244771, 53.477359],
            [48.230902, 53.490874],
            [48.206213, 53.490962],
            [48.174015, 53.478197],
            [48.173044, 53.470159],
            [48.142127, 53.46636],
            [48.146102, 53.483939],
            [48.123709, 53.484645],
            [48.117393, 53.476872],
            [48.090981, 53.48354],
            [48.077555, 53.47453],
            [48.035817, 53.471305],
            [48.023186, 53.456995],
            [48.026587, 53.4456],
            [48.048759, 53.442553],
            [48.026014, 53.421352],
            [48.016252, 53.434647],
            [47.996113, 53.417819],
            [47.998454, 53.40236],
            [47.980434, 53.396751],
            [47.973191, 53.384472],
            [47.936798, 53.362035],
            [47.960517, 53.347681],
            [47.944573, 53.333415],
            [48.009234, 53.311509],
            [48.040461, 53.29636],
            [48.02381, 53.289426],
            [48.05411, 53.270478],
            [48.077298, 53.265974],
            [48.090503, 53.271715],
            [48.100044, 53.255153],
            [48.142666, 53.246232],
            [48.15998, 53.229979],
            [48.111043, 53.203787],
            [48.109719, 53.178832],
            [48.147129, 53.17181],
            [48.169611, 53.159974],
            [48.162103, 53.151052],
            [48.212984, 53.137229],
            [48.209495, 53.123537],
            [48.166345, 53.098228],
            [48.152256, 53.076101],
            [48.124697, 53.051808],
            [48.136843, 53.050528],
            [48.176285, 53.029107],
            [48.185427, 53.041872],
            [48.204507, 53.030565],
            [48.195896, 53.008791],
            [48.22456, 53.004761],
            [48.249203, 53.065634],
            [48.327158, 53.068815],
            [48.320401, 53.061704],
            [48.357766, 53.0572],
            [48.363287, 53.048013],
            [48.325615, 52.996912],
            [48.344165, 52.990773],
            [48.370665, 52.99223],
            [48.355737, 52.975756],
            [48.359846, 52.957338],
            [48.439125, 52.972621],
            [48.449417, 52.952128],
            [48.499657, 52.940533],
            [48.522826, 52.949445],
            [48.588749, 52.895802],
            [48.592476, 52.884687],
            [48.579817, 52.86443],
            [48.552252, 52.850666],
            [48.498977, 52.831464],
            [48.453573, 52.825236],
            [48.428632, 52.814407],
            [48.383436, 52.814898],
            [48.34481, 52.78718],
            [48.30678, 52.779779],
            [48.280956, 52.795645],
            [48.268826, 52.810423],
            [48.230527, 52.813229],
            [48.19549, 52.769032],
            [48.115787, 52.794915],
            [48.088773, 52.793553],
            [48.067795, 52.774197],
            [48.070866, 52.765292],
            [48.041486, 52.769734],
            [48.017933, 52.765502],
            [47.99333, 52.744964],
            [47.991287, 52.731816],
            [48.092152, 52.732734],
            [48.064964, 52.704312],
            [48.063442, 52.676318],
            [48.042756, 52.678249],
            [47.999054, 52.659682],
            [47.977949, 52.665549],
            [47.974351, 52.656869],
            [47.951717, 52.655768],
            [47.933741, 52.646579],
            [47.906548, 52.678278],
            [47.907311, 52.706928],
            [47.8957, 52.707995],
            [47.897102, 52.724596],
            [47.833887, 52.736098],
            [47.812411, 52.736339],
            [47.802695, 52.700814],
            [47.828939, 52.703276],
            [47.786076, 52.675448],
            [47.763173, 52.677789],
            [47.769558, 52.648758],
            [47.752544, 52.619705],
            [47.772851, 52.601747],
            [47.756634, 52.589217],
            [47.7591, 52.580584],
            [47.738159, 52.581028],
            [47.722415, 52.570621],
            [47.679419, 52.597673],
            [47.648563, 52.59098],
            [47.645691, 52.604255],
            [47.540305, 52.610497],
            [47.495989, 52.621203],
            [47.490948, 52.603563],
            [47.511653, 52.590091],
            [47.442631, 52.563272],
            [47.424835, 52.561866],
            [47.380715, 52.57369],
            [47.245741, 52.556083],
            [47.230865, 52.570448],
            [47.20611, 52.547227],
            [47.156274, 52.558028],
            [47.166024, 52.586922],
            [47.163278, 52.595878],
            [47.148724, 52.5824],
            [47.063957, 52.577196],
            [47.067637, 52.560035],
            [47.062545, 52.537086],
            [47.04217, 52.53953],
            [47.050478, 52.573086],
            [47.036927, 52.583528],
            [47.006543, 52.574224],
            [46.986556, 52.585408],
            [46.993996, 52.601513],
            [46.990669, 52.623156],
            [46.980435, 52.638251],
            [46.954585, 52.633864],
            [46.932318, 52.620723],
            [46.865045, 52.614356],
            [46.83619, 52.61765],
            [46.831303, 52.598039],
            [46.802044, 52.613041],
            [46.801859, 52.621141],
            [46.798117, 52.644268],
            [46.805864, 52.662709],
            [46.799415, 52.67002],
            [46.814921, 52.680551],
            [46.817569, 52.697949],
            [46.845057, 52.691247],
            [46.857917, 52.710086],
            [46.940143, 52.721964],
            [46.937198, 52.738486],
            [46.926254, 52.739942],
            [46.945772, 52.752972],
            [46.969109, 52.759405],
            [46.966177, 52.78689],
            [46.97028, 52.80898],
            [46.934715, 52.828303],
            [46.925652, 52.854482],
            [46.909558, 52.875679],
            [46.896265, 52.875997],
            [46.892765, 52.893382],
            [46.927109, 52.918984],
            [46.917765, 52.919133],
            [46.926232, 52.966235],
            [46.911345, 52.967432],
            [46.916406, 52.996374],
            [46.923738, 52.996389],
            [46.933116, 53.071272],
            [46.938684, 53.08047],
            [46.910137, 53.082676],
            [46.911449, 53.090428],
            [46.956722, 53.097728],
            [46.946652, 53.112531],
            [46.97298, 53.120431],
            [46.969899, 53.129485],
            [46.947901, 53.128992],
            [46.930099, 53.171332],
            [46.876104, 53.165773],
            [46.863107, 53.177165],
            [46.862212, 53.209943],
            [46.874847, 53.215738],
            [46.871097, 53.238552],
            [46.903859, 53.255807],
            [46.942159, 53.250974],
            [46.937642, 53.275552],
            [46.954466, 53.284601],
            [46.898875, 53.278757],
            [46.908509, 53.36347],
            [46.817088, 53.366982],
            [46.791834, 53.372678],
            [46.768237, 53.395788],
            [46.758187, 53.390948],
            [46.746153, 53.41597],
            [46.720084, 53.41511],
            [46.703585, 53.434995],
            [46.678696, 53.428115],
            [46.659541, 53.441857],
            [46.681045, 53.475366],
            [46.669933, 53.482395],
            [46.649753, 53.468775],
            [46.620054, 53.467599],
            [46.607771, 53.455752],
            [46.589637, 53.467599],
            [46.568175, 53.462485],
            [46.552762, 53.477997],
            [46.555964, 53.486761],
            [46.532285, 53.48297],
            [46.521321, 53.46439],
            [46.505246, 53.481495],
            [46.455797, 53.512958],
            [46.467044, 53.52291],
            [46.450697, 53.539561],
            [46.449633, 53.581845],
            [46.444955, 53.603505],
            [46.471303, 53.609069],
            [46.479751, 53.617991],
            [46.438391, 53.648993],
            [46.376481, 53.670359],
            [46.328083, 53.725346],
            [46.331165, 53.741447],
            [46.303347, 53.758559],
            [46.276153, 53.740858],
            [46.246873, 53.754909],
            [46.256721, 53.771453],
            [46.287422, 53.779221],
            [46.279374, 53.790302],
            [46.250099, 53.794973],
            [46.216315, 53.793677],
            [46.201384, 53.800393],
            [46.17272, 53.796735],
            [46.16317, 53.807983],
            [46.173285, 53.818819],
            [46.174146, 53.857007],
            [46.192932, 53.881457],
            [46.217747, 53.885096],
            [46.186894, 53.890782],
            [46.177834, 53.901473],
            [46.185294, 53.911999],
            [46.150167, 53.926036],
            [46.148233, 53.935542],
            [46.124903, 53.934978],
            [46.124426, 53.921518],
            [46.100432, 53.932647],
            [46.107735, 53.947141],
            [46.078067, 53.966138],
            [46.030515, 53.97682],
            [46.030821, 53.984404],
            [46.003857, 53.980768],
            [45.976949, 53.98513],
            [45.983434, 53.995544],
            [45.95926, 53.995986],
            [45.973759, 54.003427],
            [45.97641, 54.019802],
            [45.947012, 54.027264],
            [45.924477, 53.985742],
            [45.928127, 54.005476],
            [45.892731, 54.007081],
            [45.905733, 53.984423],
            [45.87269, 53.981194],
            [45.857451, 53.994651],
            [45.850307, 53.979291],
            [45.830398, 53.966588],
            [45.813633, 53.975716],
            [45.79665, 53.985759],
            [45.815348, 53.992367],
            [45.845556, 53.993172],
            [45.870607, 54.000688],
            [45.89412, 54.019242],
            [45.905014, 54.047918],
            [45.889915, 54.061819],
            [45.929382, 54.076922],
            [45.944915, 54.093633],
            [45.957786, 54.095239],
            [45.96928, 54.127835],
            [45.955466, 54.147289],
            [45.967396, 54.154323],
            [45.969883, 54.138093],
            [45.988159, 54.152414],
            [46.005831, 54.148848],
            [46.003261, 54.135731],
            [46.016733, 54.129446],
            [46.038522, 54.139646],
            [46.062373, 54.170452],
            [46.083575, 54.176427],
            [46.086833, 54.165879],
            [46.103995, 54.163974],
            [46.12235, 54.176982],
            [46.1269, 54.165576],
            [46.155379, 54.154425],
            [46.186101, 54.167235],
            [46.192366, 54.179893],
            [46.228312, 54.186469],
            [46.254055, 54.172458],
            [46.264694, 54.183154],
            [46.283062, 54.183403],
            [46.281083, 54.192846],
            [46.304076, 54.197965],
            [46.3239, 54.190137],
            [46.361742, 54.200121],
            [46.369033, 54.210062],
            [46.397946, 54.210359],
            [46.404468, 54.217685],
            [46.385849, 54.233583],
            [46.420681, 54.24848],
            [46.44085, 54.270431],
            [46.460664, 54.261509],
            [46.47371, 54.268577],
            [46.511379, 54.264668],
            [46.541404, 54.273034],
            [46.540123, 54.283504],
            [46.577444, 54.28541],
            [46.616823, 54.297128],
            [46.622231, 54.290621],
            [46.663933, 54.295173],
            [46.655862, 54.300884],
            [46.684518, 54.304336],
            [46.705543, 54.326711],
            [46.697828, 54.337313],
            [46.705291, 54.35897],
            [46.723995, 54.373468],
            [46.71121, 54.378615],
            [46.729482, 54.401948],
            [46.708287, 54.39845],
            [46.654581, 54.39825],
            [46.609701, 54.406492],
            [46.575211, 54.398001],
            [46.562776, 54.404692],
            [46.534027, 54.398745],
            [46.528276, 54.41288],
            [46.500991, 54.428505],
            [46.490875, 54.443429],
            [46.506572, 54.457296],
            [46.492495, 54.462183],
            [46.506919, 54.470363],
            [46.526566, 54.463931],
            [46.544414, 54.46807],
            [46.534713, 54.477093],
            [46.584736, 54.486064],
            [46.571612, 54.502561],
            [46.557627, 54.488458],
            [46.51378, 54.485615],
            [46.492495, 54.474255],
            [46.459293, 54.48158],
            [46.485121, 54.490401],
            [46.485206, 54.504254],
            [46.528966, 54.518056],
            [46.539864, 54.559021],
            [46.509573, 54.552451],
            [46.496099, 54.560662],
            [46.536418, 54.5731],
            [46.521321, 54.587969],
            [46.477726, 54.57688],
            [46.455693, 54.579617],
            [46.41915, 54.561215],
            [46.403024, 54.574192],
            [46.409115, 54.583342],
            [46.463864, 54.605165],
            [46.500659, 54.626381],
            [46.535834, 54.631893],
            [46.525617, 54.654832],
            [46.421202, 54.688222],
            [46.440595, 54.698917],
            [46.433729, 54.707395],
            [46.454323, 54.719589],
            [46.409037, 54.731975],
            [46.408442, 54.740002],
            [46.441731, 54.761445],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5540",
      properties: {
        name: "Хабаровский край",
        density: 0,
        path: "/world/Russia/Хабаровский край",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [136.44575, 54.6285],
              [136.3845, 54.63325],
              [136.334, 54.65],
              [136.28575, 54.652],
              [136.273, 54.67775],
              [136.2575, 54.68225],
              [136.27725, 54.7015],
              [136.305, 54.693],
              [136.33025, 54.697],
              [136.34925, 54.67725],
              [136.32975, 54.67425],
              [136.332, 54.66175],
              [136.36275, 54.64525],
              [136.421, 54.63475],
              [136.46675, 54.641],
              [136.45575, 54.62425],
              [136.44575, 54.6285],
            ],
          ],
          [
            [
              [138.21825, 54.7045],
              [138.203, 54.699],
              [138.18275, 54.716],
              [138.2265, 54.72325],
              [138.247, 54.711],
              [138.21825, 54.7045],
            ],
          ],
          [
            [
              [137.68875, 54.6425],
              [137.6665, 54.64225],
              [137.6415, 54.69],
              [137.6285, 54.69725],
              [137.6185, 54.73375],
              [137.59675, 54.738],
              [137.58325, 54.7695],
              [137.559, 54.76975],
              [137.552, 54.793],
              [137.53225, 54.8215],
              [137.48525, 54.868],
              [137.44225, 54.85675],
              [137.4235, 54.85625],
              [137.39775, 54.84375],
              [137.37575, 54.8125],
              [137.34925, 54.81],
              [137.33, 54.79625],
              [137.30625, 54.7915],
              [137.3055, 54.777],
              [137.27875, 54.766],
              [137.236, 54.76175],
              [137.26325, 54.8145],
              [137.26525, 54.837],
              [137.3025, 54.90575],
              [137.3425, 54.94075],
              [137.3885, 54.96725],
              [137.391, 54.984],
              [137.40725, 54.998],
              [137.42775, 55.00175],
              [137.479, 55.032],
              [137.49075, 55.0455],
              [137.48775, 55.0575],
              [137.5065, 55.07075],
              [137.502, 55.0825],
              [137.51925, 55.10175],
              [137.502, 55.1115],
              [137.5515, 55.153],
              [137.5535, 55.17925],
              [137.6145, 55.18675],
              [137.63175, 55.1965],
              [137.652, 55.18425],
              [137.692, 55.174],
              [137.73325, 55.149],
              [137.77175, 55.14175],
              [137.78825, 55.144],
              [137.835, 55.13175],
              [137.8905, 55.128],
              [137.9125, 55.1415],
              [137.93075, 55.14075],
              [137.95625, 55.10425],
              [137.99275, 55.083],
              [138.05475, 55.05425],
              [138.0745, 55.048],
              [138.107, 55.0515],
              [138.168, 55.037],
              [138.21675, 55.03425],
              [138.222, 55.02425],
              [138.20625, 55.01925],
              [138.1925, 55.0015],
              [138.1755, 54.99425],
              [138.1375, 54.99875],
              [138.091, 54.97525],
              [138.07125, 54.95425],
              [138.07175, 54.93925],
              [138.0215, 54.91775],
              [138.00225, 54.90075],
              [138.0035, 54.88075],
              [137.99525, 54.8635],
              [138.01075, 54.82475],
              [138.0005, 54.786],
              [137.98675, 54.77175],
              [137.9595, 54.76625],
              [137.9525, 54.753],
              [137.91775, 54.763],
              [137.891, 54.75875],
              [137.86225, 54.73325],
              [137.85925, 54.71675],
              [137.83575, 54.701],
              [137.81925, 54.702],
              [137.80375, 54.687],
              [137.77925, 54.68475],
              [137.76725, 54.6685],
              [137.7475, 54.6625],
              [137.731, 54.64525],
              [137.7255, 54.62375],
              [137.70075, 54.61475],
              [137.68875, 54.6425],
            ],
          ],
          [
            [
              [138.4135, 55.04675],
              [138.36675, 55.05075],
              [138.355, 55.057],
              [138.3245, 55.05475],
              [138.32475, 55.07775],
              [138.33525, 55.09],
              [138.36875, 55.096],
              [138.4115, 55.09225],
              [138.43, 55.078],
              [138.4135, 55.04675],
            ],
          ],
          [
            [
              [141.41111, 53.321302],
              [141.38368, 53.322198],
              [141.361999, 53.336536],
              [141.354451, 53.328662],
              [141.327279, 53.329435],
              [141.319344, 53.348164],
              [141.358079, 53.352149],
              [141.40208, 53.343842],
              [141.470423, 53.30907],
              [141.476143, 53.297047],
              [141.447406, 53.304058],
              [141.432062, 53.297761],
              [141.435391, 53.311618],
              [141.41111, 53.321302],
            ],
          ],
          [
            [
              [139.78775, 54.3415],
              [139.756, 54.3395],
              [139.75825, 54.35225],
              [139.78075, 54.35825],
              [139.80875, 54.35325],
              [139.78775, 54.3415],
            ],
          ],
          [
            [
              [137.7875, 54.37175],
              [137.76375, 54.37275],
              [137.7335, 54.3575],
              [137.7155, 54.3555],
              [137.708, 54.37225],
              [137.73675, 54.38225],
              [137.745, 54.39125],
              [137.77175, 54.3955],
              [137.77925, 54.40625],
              [137.82775, 54.43475],
              [137.82125, 54.448],
              [137.843, 54.48475],
              [137.867, 54.492],
              [137.8945, 54.4895],
              [137.91675, 54.49475],
              [137.955, 54.461],
              [137.916, 54.4515],
              [137.8785, 54.4365],
              [137.834, 54.39175],
              [137.81225, 54.3755],
              [137.7875, 54.37175],
            ],
          ],
          [
            [
              [137.59175, 54.38325],
              [137.5745, 54.383],
              [137.54, 54.40375],
              [137.5605, 54.41425],
              [137.59225, 54.452],
              [137.59175, 54.462],
              [137.5685, 54.46625],
              [137.5615, 54.486],
              [137.56925, 54.4985],
              [137.5555, 54.50825],
              [137.609, 54.55425],
              [137.62175, 54.552],
              [137.6355, 54.535],
              [137.635, 54.51475],
              [137.654, 54.50625],
              [137.66225, 54.4885],
              [137.64475, 54.4795],
              [137.6465, 54.46575],
              [137.63125, 54.462],
              [137.60575, 54.434],
              [137.618, 54.41875],
              [137.64625, 54.40225],
              [137.65775, 54.37925],
              [137.6385, 54.3945],
              [137.59175, 54.38325],
            ],
          ],
          [
            [
              [130.952768, 55.712176],
              [130.9625, 55.724033],
              [130.943834, 55.736267],
              [130.943203, 55.753498],
              [130.95825, 55.775232],
              [130.953358, 55.78679],
              [130.964553, 55.800008],
              [130.957815, 55.816086],
              [130.94079, 55.819272],
              [130.931467, 55.831635],
              [130.891943, 55.842574],
              [130.89742, 55.853822],
              [130.885771, 55.881929],
              [130.88634, 55.90152],
              [130.86022, 55.915099],
              [130.896825, 55.948569],
              [130.91676, 55.951553],
              [130.93553, 55.963596],
              [130.919627, 55.972647],
              [130.92738, 55.982601],
              [130.900451, 56.000446],
              [130.896693, 56.018642],
              [130.934076, 56.04176],
              [130.94703, 56.069564],
              [130.971521, 56.080218],
              [130.98006, 56.107148],
              [130.971793, 56.137579],
              [130.991287, 56.167585],
              [131.02717, 56.188264],
              [131.0431, 56.191435],
              [131.073342, 56.209382],
              [131.120136, 56.248448],
              [131.140401, 56.290778],
              [131.124973, 56.314347],
              [131.137156, 56.329351],
              [131.119877, 56.335738],
              [131.114135, 56.370057],
              [131.100653, 56.379269],
              [131.103629, 56.409936],
              [131.134938, 56.41859],
              [131.167571, 56.435544],
              [131.168599, 56.450922],
              [131.187366, 56.458697],
              [131.191349, 56.480053],
              [131.22548, 56.495728],
              [131.2156, 56.506312],
              [131.222116, 56.520844],
              [131.273297, 56.515731],
              [131.288813, 56.522084],
              [131.332727, 56.51304],
              [131.339013, 56.528154],
              [131.350384, 56.516237],
              [131.389722, 56.495145],
              [131.374186, 56.478812],
              [131.387256, 56.476993],
              [131.383884, 56.46048],
              [131.403556, 56.461469],
              [131.422284, 56.45309],
              [131.450872, 56.464378],
              [131.437601, 56.487905],
              [131.41754, 56.490896],
              [131.408278, 56.509243],
              [131.437323, 56.521797],
              [131.435655, 56.538401],
              [131.45285, 56.546743],
              [131.48688, 56.552468],
              [131.501157, 56.564587],
              [131.541156, 56.548214],
              [131.564234, 56.551999],
              [131.56642, 56.532595],
              [131.591261, 56.527464],
              [131.62874, 56.529793],
              [131.63506, 56.523971],
              [131.617775, 56.476977],
              [131.635363, 56.472114],
              [131.648413, 56.455436],
              [131.66254, 56.459318],
              [131.665751, 56.478928],
              [131.682475, 56.49617],
              [131.695851, 56.489168],
              [131.695326, 56.473014],
              [131.725008, 56.470715],
              [131.751685, 56.492245],
              [131.710913, 56.501021],
              [131.69251, 56.526286],
              [131.646368, 56.536917],
              [131.69962, 56.565521],
              [131.709222, 56.603398],
              [131.69494, 56.617854],
              [131.667007, 56.617286],
              [131.690424, 56.627643],
              [131.671966, 56.647742],
              [131.71905, 56.658784],
              [131.720395, 56.665584],
              [131.681289, 56.672586],
              [131.68148, 56.683889],
              [131.700781, 56.690527],
              [131.722274, 56.686933],
              [131.736786, 56.700277],
              [131.76587, 56.711561],
              [131.75214, 56.72274],
              [131.784409, 56.714717],
              [131.802934, 56.723221],
              [131.774384, 56.740859],
              [131.776829, 56.74895],
              [131.804928, 56.73796],
              [131.831681, 56.742746],
              [131.835872, 56.771393],
              [131.809684, 56.77245],
              [131.79451, 56.813208],
              [131.811644, 56.819534],
              [131.773785, 56.834853],
              [131.777026, 56.85771],
              [131.759408, 56.868136],
              [131.734877, 56.85935],
              [131.720687, 56.862724],
              [131.707992, 56.895986],
              [131.679719, 56.892562],
              [131.66088, 56.907639],
              [131.661892, 56.930381],
              [131.634783, 56.940357],
              [131.638126, 56.952756],
              [131.607381, 56.949785],
              [131.614759, 56.966813],
              [131.608717, 56.978186],
              [131.587089, 56.977716],
              [131.53427, 57.026356],
              [131.510433, 57.018914],
              [131.4989, 57.030247],
              [131.507657, 57.04964],
              [131.473271, 57.060153],
              [131.469062, 57.083762],
              [131.487935, 57.095532],
              [131.506517, 57.08963],
              [131.494191, 57.111407],
              [131.465111, 57.111482],
              [131.4323, 57.137788],
              [131.406821, 57.134384],
              [131.389647, 57.148995],
              [131.350222, 57.15047],
              [131.332037, 57.157313],
              [131.333482, 57.186913],
              [131.313257, 57.192029],
              [131.248833, 57.193211],
              [131.23455, 57.179639],
              [131.232651, 57.163881],
              [131.19229, 57.167836],
              [131.18971, 57.184351],
              [131.202333, 57.20329],
              [131.193769, 57.213022],
              [131.143447, 57.232004],
              [131.12295, 57.251713],
              [131.141993, 57.270024],
              [131.200708, 57.259563],
              [131.232327, 57.276633],
              [131.255328, 57.29879],
              [131.279232, 57.336195],
              [131.292271, 57.334498],
              [131.305023, 57.30609],
              [131.319267, 57.302107],
              [131.325463, 57.285161],
              [131.370607, 57.275056],
              [131.420455, 57.27952],
              [131.465957, 57.276765],
              [131.487912, 57.26412],
              [131.508821, 57.268152],
              [131.51019, 57.294236],
              [131.557366, 57.315993],
              [131.588436, 57.30685],
              [131.608751, 57.309362],
              [131.6117, 57.329838],
              [131.62714, 57.340519],
              [131.605674, 57.356235],
              [131.602788, 57.380298],
              [131.60628, 57.4282],
              [131.625617, 57.44091],
              [131.637851, 57.461394],
              [131.659488, 57.475764],
              [131.68317, 57.479958],
              [131.703289, 57.496777],
              [131.729471, 57.501621],
              [131.749743, 57.529496],
              [131.780773, 57.538616],
              [131.72291, 57.56858],
              [131.724049, 57.578063],
              [131.760622, 57.580018],
              [131.782979, 57.600091],
              [131.849116, 57.622865],
              [131.942162, 57.616709],
              [131.96994, 57.63569],
              [131.990664, 57.665197],
              [132.028038, 57.666687],
              [132.068427, 57.68172],
              [132.061857, 57.70392],
              [132.047152, 57.711306],
              [132.050971, 57.731251],
              [132.023597, 57.751953],
              [132.010458, 57.754854],
              [131.958236, 57.789994],
              [131.916428, 57.797247],
              [131.902646, 57.811239],
              [131.879332, 57.815483],
              [131.877758, 57.847845],
              [131.8415, 57.848018],
              [131.815722, 57.868479],
              [131.760145, 57.871115],
              [131.747755, 57.888456],
              [131.796466, 57.901824],
              [131.790738, 57.910954],
              [131.799213, 57.932452],
              [131.777842, 57.940176],
              [131.70772, 57.950209],
              [131.69544, 57.97183],
              [131.65645, 57.992809],
              [131.641358, 57.993719],
              [131.61859, 58.011091],
              [131.584748, 58.019402],
              [131.569036, 58.031347],
              [131.580677, 58.038557],
              [131.632881, 58.046134],
              [131.644071, 58.079844],
              [131.62013, 58.088486],
              [131.583217, 58.087085],
              [131.565695, 58.110516],
              [131.528286, 58.110512],
              [131.52336, 58.1322],
              [131.495204, 58.170494],
              [131.460153, 58.166493],
              [131.444274, 58.182403],
              [131.453301, 58.196866],
              [131.477286, 58.203714],
              [131.50224, 58.198514],
              [131.556726, 58.211532],
              [131.593139, 58.198359],
              [131.66815, 58.209021],
              [131.674061, 58.201267],
              [131.70549, 58.201191],
              [131.740003, 58.180971],
              [131.750023, 58.158754],
              [131.769774, 58.151824],
              [131.798024, 58.162822],
              [131.828724, 58.16674],
              [131.863, 58.149277],
              [131.876158, 58.135154],
              [131.899933, 58.129314],
              [131.886851, 58.119543],
              [131.979986, 58.079754],
              [131.984823, 58.05169],
              [132.021543, 58.057305],
              [132.066274, 58.049308],
              [132.090796, 58.069012],
              [132.137239, 58.08023],
              [132.144236, 58.102204],
              [132.1228, 58.102078],
              [132.104785, 58.122059],
              [132.029399, 58.146302],
              [131.99847, 58.150393],
              [131.985865, 58.158537],
              [132.005107, 58.178489],
              [132.034414, 58.186011],
              [132.014457, 58.20118],
              [132.047651, 58.229951],
              [132.073062, 58.245695],
              [132.111436, 58.27843],
              [132.131227, 58.279292],
              [132.198077, 58.254443],
              [132.196821, 58.274626],
              [132.210888, 58.288011],
              [132.205794, 58.306802],
              [132.22858, 58.331669],
              [132.229369, 58.342844],
              [132.212643, 58.362869],
              [132.226305, 58.386228],
              [132.196828, 58.396821],
              [132.170937, 58.430252],
              [132.186351, 58.443014],
              [132.185, 58.469852],
              [132.175477, 58.489555],
              [132.139929, 58.514862],
              [132.18708, 58.528982],
              [132.20998, 58.519674],
              [132.243122, 58.527345],
              [132.282621, 58.527949],
              [132.30206, 58.53674],
              [132.319044, 58.516768],
              [132.36222, 58.519684],
              [132.430917, 58.510854],
              [132.427658, 58.538308],
              [132.387727, 58.544871],
              [132.386507, 58.561251],
              [132.430068, 58.578355],
              [132.447914, 58.591133],
              [132.443672, 58.60246],
              [132.505111, 58.6309],
              [132.511525, 58.641663],
              [132.546511, 58.659689],
              [132.504695, 58.676655],
              [132.518315, 58.704971],
              [132.544862, 58.730203],
              [132.527413, 58.752215],
              [132.53667, 58.781395],
              [132.48851, 58.795264],
              [132.520134, 58.807822],
              [132.534671, 58.820878],
              [132.52548, 58.834074],
              [132.554992, 58.846344],
              [132.558718, 58.871613],
              [132.588076, 58.891657],
              [132.635521, 58.891286],
              [132.668123, 58.910123],
              [132.703116, 58.886562],
              [132.74635, 58.880192],
              [132.777245, 58.887027],
              [132.789074, 58.928292],
              [132.820145, 58.964118],
              [132.843873, 58.976578],
              [132.844908, 58.993174],
              [132.80827, 58.997135],
              [132.765002, 59.007366],
              [132.756228, 59.025339],
              [132.79922, 59.041526],
              [132.81975, 59.04324],
              [132.822336, 59.060754],
              [132.83699, 59.06807],
              [132.816881, 59.092156],
              [132.829636, 59.118754],
              [132.8647, 59.117909],
              [132.896474, 59.101137],
              [132.929453, 59.103984],
              [132.959967, 59.121165],
              [132.961538, 59.13753],
              [132.988955, 59.158992],
              [133.017638, 59.165723],
              [133.041852, 59.1794],
              [133.051638, 59.19458],
              [133.081235, 59.197575],
              [133.10398, 59.215674],
              [133.159038, 59.204642],
              [133.172857, 59.225692],
              [133.170973, 59.24141],
              [133.209436, 59.244381],
              [133.218423, 59.229948],
              [133.23506, 59.233383],
              [133.271917, 59.226689],
              [133.305743, 59.234144],
              [133.337031, 59.25356],
              [133.3689, 59.249389],
              [133.376802, 59.27349],
              [133.413022, 59.275897],
              [133.465973, 59.296137],
              [133.464108, 59.304017],
              [133.51856, 59.312262],
              [133.573378, 59.293565],
              [133.572488, 59.251018],
              [133.626456, 59.241618],
              [133.629472, 59.232917],
              [133.67724, 59.229846],
              [133.718346, 59.234119],
              [133.747541, 59.228344],
              [133.78608, 59.242967],
              [133.813366, 59.245417],
              [133.869044, 59.259041],
              [133.938569, 59.250537],
              [133.957765, 59.256217],
              [133.993286, 59.255428],
              [134.00117, 59.26333],
              [134.044984, 59.256496],
              [134.077362, 59.256017],
              [134.102972, 59.234929],
              [134.122666, 59.239994],
              [134.153547, 59.22903],
              [134.184975, 59.22834],
              [134.183703, 59.21853],
              [134.213563, 59.220021],
              [134.241695, 59.210743],
              [134.303705, 59.203486],
              [134.365691, 59.202258],
              [134.38773, 59.193869],
              [134.377232, 59.185384],
              [134.38892, 59.172668],
              [134.43971, 59.166065],
              [134.483379, 59.139514],
              [134.499409, 59.142191],
              [134.499186, 59.157457],
              [134.527989, 59.186748],
              [134.562509, 59.203086],
              [134.597844, 59.202131],
              [134.619074, 59.209323],
              [134.65486, 59.198671],
              [134.66857, 59.205716],
              [134.722557, 59.218614],
              [134.716415, 59.2047],
              [134.736352, 59.187575],
              [134.7221, 59.175071],
              [134.733111, 59.157808],
              [134.79873, 59.152045],
              [134.80816, 59.140001],
              [134.842509, 59.136602],
              [134.890082, 59.152452],
              [134.92199, 59.156692],
              [134.932373, 59.169826],
              [134.988606, 59.164912],
              [135.02601, 59.145711],
              [135.052726, 59.151643],
              [135.070293, 59.146526],
              [135.10225, 59.122982],
              [135.129173, 59.123017],
              [135.14393, 59.114982],
              [135.193251, 59.114383],
              [135.203196, 59.135634],
              [135.228102, 59.14379],
              [135.24807, 59.139061],
              [135.25053, 59.159491],
              [135.2383, 59.180245],
              [135.28806, 59.193331],
              [135.30238, 59.202744],
              [135.264922, 59.225636],
              [135.26093, 59.23396],
              [135.29629, 59.24826],
              [135.305126, 59.276336],
              [135.327001, 59.294163],
              [135.362486, 59.311354],
              [135.349139, 59.335389],
              [135.374108, 59.343398],
              [135.420443, 59.366186],
              [135.43587, 59.380391],
              [135.437829, 59.396957],
              [135.476391, 59.414049],
              [135.50804, 59.439284],
              [135.551849, 59.450927],
              [135.594593, 59.466778],
              [135.613066, 59.487839],
              [135.66232, 59.498231],
              [135.689987, 59.508543],
              [135.701028, 59.49993],
              [135.73732, 59.503295],
              [135.769162, 59.521347],
              [135.826448, 59.526107],
              [135.890556, 59.514043],
              [135.889517, 59.496764],
              [135.909163, 59.480115],
              [135.947567, 59.458813],
              [135.965809, 59.456007],
              [135.971292, 59.472243],
              [135.98686, 59.473552],
              [136.022941, 59.453181],
              [136.059743, 59.447244],
              [136.070977, 59.429925],
              [136.08988, 59.419551],
              [136.111533, 59.425725],
              [136.12014, 59.414602],
              [136.15826, 59.421636],
              [136.21046, 59.420925],
              [136.23986, 59.412094],
              [136.26194, 59.425962],
              [136.2745, 59.419008],
              [136.317351, 59.42381],
              [136.33782, 59.413681],
              [136.346825, 59.393925],
              [136.399989, 59.3838],
              [136.444841, 59.39246],
              [136.49855, 59.384844],
              [136.520344, 59.373094],
              [136.55692, 59.368504],
              [136.586397, 59.381576],
              [136.612259, 59.379357],
              [136.654064, 59.350732],
              [136.6729, 59.345857],
              [136.72633, 59.351102],
              [136.768574, 59.337881],
              [136.795411, 59.35718],
              [136.801104, 59.372371],
              [136.821174, 59.388431],
              [136.861098, 59.398562],
              [136.90915, 59.405651],
              [136.959364, 59.425584],
              [137.00541, 59.435164],
              [137.026074, 59.425489],
              [137.064595, 59.431897],
              [137.083397, 59.445562],
              [137.117116, 59.451139],
              [137.154117, 59.436571],
              [137.178614, 59.440205],
              [137.218749, 59.4794],
              [137.258171, 59.490787],
              [137.294906, 59.494901],
              [137.326398, 59.509319],
              [137.332057, 59.530313],
              [137.359772, 59.541879],
              [137.412171, 59.536585],
              [137.445322, 59.537099],
              [137.464461, 59.550823],
              [137.501406, 59.558085],
              [137.484382, 59.586042],
              [137.448996, 59.592532],
              [137.463238, 59.599753],
              [137.449793, 59.613138],
              [137.457479, 59.638071],
              [137.449811, 59.645583],
              [137.48843, 59.66298],
              [137.491184, 59.680838],
              [137.5266, 59.686103],
              [137.555677, 59.703664],
              [137.557797, 59.729889],
              [137.631978, 59.751303],
              [137.650391, 59.747367],
              [137.721515, 59.754589],
              [137.727178, 59.746032],
              [137.818526, 59.758248],
              [137.860995, 59.7562],
              [137.89877, 59.740811],
              [137.920366, 59.76161],
              [137.95199, 59.762151],
              [137.97434, 59.772345],
              [137.968194, 59.792038],
              [137.988064, 59.81245],
              [138.031175, 59.824763],
              [138.046915, 59.803682],
              [138.034008, 59.782405],
              [138.061788, 59.770348],
              [138.080477, 59.74679],
              [138.050514, 59.724308],
              [138.05174, 59.709361],
              [138.028721, 59.699374],
              [138.022098, 59.667668],
              [138.047467, 59.645564],
              [138.072804, 59.640158],
              [138.105984, 59.646032],
              [138.12539, 59.638795],
              [138.144453, 59.644283],
              [138.16908, 59.635249],
              [138.21759, 59.653979],
              [138.210209, 59.683585],
              [138.232169, 59.688217],
              [138.272096, 59.705773],
              [138.267109, 59.722185],
              [138.22002, 59.730602],
              [138.232334, 59.748429],
              [138.21974, 59.814601],
              [138.17691, 59.822122],
              [138.17898, 59.838894],
              [138.19466, 59.866927],
              [138.186291, 59.883179],
              [138.194355, 59.91419],
              [138.21251, 59.91464],
              [138.231852, 59.93093],
              [138.262393, 59.930771],
              [138.268721, 59.945713],
              [138.246993, 59.970681],
              [138.22523, 59.972463],
              [138.233283, 59.984511],
              [138.230908, 60.015369],
              [138.236042, 60.036286],
              [138.26242, 60.068805],
              [138.241172, 60.092213],
              [138.211654, 60.101969],
              [138.227166, 60.149738],
              [138.21689, 60.156824],
              [138.239475, 60.173035],
              [138.210739, 60.195193],
              [138.183612, 60.196145],
              [138.178408, 60.20899],
              [138.218639, 60.256284],
              [138.21322, 60.277813],
              [138.243668, 60.281947],
              [138.24477, 60.295472],
              [138.306788, 60.313082],
              [138.322441, 60.333853],
              [138.308435, 60.370427],
              [138.313925, 60.387825],
              [138.33563, 60.401549],
              [138.30414, 60.412142],
              [138.316753, 60.426214],
              [138.268057, 60.482459],
              [138.316538, 60.491307],
              [138.331848, 60.512503],
              [138.338562, 60.541402],
              [138.35997, 60.562798],
              [138.3283, 60.571854],
              [138.342413, 60.585708],
              [138.331997, 60.604217],
              [138.346549, 60.61164],
              [138.383449, 60.611781],
              [138.396839, 60.620654],
              [138.404109, 60.652866],
              [138.391856, 60.670421],
              [138.409833, 60.693742],
              [138.428956, 60.703656],
              [138.397671, 60.712894],
              [138.397526, 60.723003],
              [138.352726, 60.735604],
              [138.314729, 60.736787],
              [138.316269, 60.759931],
              [138.305303, 60.776131],
              [138.271806, 60.793298],
              [138.27044, 60.808488],
              [138.283249, 60.81715],
              [138.270196, 60.827573],
              [138.23419, 60.826663],
              [138.206922, 60.857035],
              [138.234533, 60.885437],
              [138.22958, 60.895601],
              [138.26168, 60.92074],
              [138.249141, 60.927106],
              [138.269092, 60.954915],
              [138.319109, 60.965698],
              [138.34398, 60.977802],
              [138.359881, 61.005269],
              [138.34714, 61.022645],
              [138.36053, 61.044959],
              [138.326384, 61.057158],
              [138.325686, 61.080891],
              [138.336459, 61.107432],
              [138.397131, 61.099381],
              [138.45702, 61.119319],
              [138.481323, 61.141343],
              [138.521881, 61.142254],
              [138.555849, 61.151232],
              [138.574067, 61.163295],
              [138.659701, 61.20452],
              [138.702808, 61.20852],
              [138.671659, 61.224647],
              [138.651092, 61.226987],
              [138.657732, 61.2491],
              [138.63744, 61.263558],
              [138.636763, 61.282242],
              [138.649138, 61.297307],
              [138.688467, 61.307003],
              [138.734927, 61.336884],
              [138.770495, 61.313665],
              [138.809022, 61.319307],
              [138.831063, 61.330637],
              [138.902048, 61.3323],
              [138.913598, 61.318768],
              [138.941172, 61.327542],
              [138.97238, 61.316048],
              [138.982414, 61.326873],
              [139.039548, 61.345647],
              [139.039404, 61.364679],
              [139.060415, 61.363339],
              [139.076006, 61.393615],
              [139.058469, 61.405616],
              [139.055351, 61.431278],
              [139.091365, 61.435135],
              [139.126827, 61.432874],
              [139.13744, 61.425831],
              [139.199463, 61.421789],
              [139.22169, 61.434719],
              [139.280331, 61.427652],
              [139.316815, 61.435597],
              [139.325365, 61.449778],
              [139.342592, 61.448575],
              [139.381519, 61.470046],
              [139.396751, 61.465213],
              [139.389311, 61.486507],
              [139.421749, 61.48629],
              [139.450048, 61.494528],
              [139.452056, 61.485956],
              [139.489168, 61.4776],
              [139.49708, 61.465204],
              [139.55376, 61.473609],
              [139.565293, 61.493745],
              [139.58998, 61.507548],
              [139.60984, 61.533169],
              [139.64859, 61.562251],
              [139.664966, 61.605594],
              [139.672261, 61.639014],
              [139.692293, 61.662242],
              [139.732754, 61.65924],
              [139.731738, 61.634646],
              [139.775756, 61.639539],
              [139.769855, 61.656403],
              [139.787808, 61.683865],
              [139.780897, 61.694221],
              [139.802106, 61.716978],
              [139.824067, 61.720311],
              [139.8521, 61.735086],
              [139.882064, 61.776085],
              [139.927104, 61.795297],
              [139.977237, 61.860247],
              [139.980066, 61.876091],
              [139.958918, 61.901133],
              [139.967351, 61.916537],
              [139.952546, 61.936529],
              [139.980514, 61.957772],
              [139.99022, 61.977711],
              [140.030227, 61.987143],
              [140.062635, 61.977386],
              [140.096414, 61.977448],
              [140.116334, 61.968794],
              [140.175297, 62.015144],
              [140.193451, 62.012437],
              [140.237099, 62.018818],
              [140.275988, 62.037808],
              [140.265695, 62.063973],
              [140.24132, 62.068294],
              [140.256402, 62.090872],
              [140.313853, 62.109324],
              [140.311191, 62.123116],
              [140.32718, 62.151843],
              [140.320568, 62.171382],
              [140.333334, 62.187437],
              [140.312693, 62.196089],
              [140.31687, 62.214022],
              [140.33532, 62.219561],
              [140.289673, 62.234111],
              [140.28359, 62.244521],
              [140.291707, 62.268197],
              [140.318535, 62.284846],
              [140.30686, 62.29327],
              [140.319696, 62.320276],
              [140.312081, 62.341879],
              [140.337705, 62.353838],
              [140.359918, 62.378813],
              [140.391467, 62.378302],
              [140.395049, 62.404049],
              [140.422095, 62.416213],
              [140.46521, 62.409171],
              [140.48202, 62.416702],
              [140.535839, 62.414131],
              [140.54565, 62.408606],
              [140.579632, 62.415275],
              [140.610694, 62.413204],
              [140.628272, 62.403972],
              [140.666065, 62.416365],
              [140.69751, 62.43857],
              [140.693738, 62.448448],
              [140.72409, 62.453594],
              [140.73082, 62.467138],
              [140.705446, 62.483149],
              [140.737663, 62.489801],
              [140.74471, 62.513493],
              [140.790861, 62.526497],
              [140.81124, 62.522479],
              [140.845978, 62.53015],
              [140.879461, 62.516447],
              [140.889114, 62.496533],
              [140.95661, 62.500818],
              [140.973947, 62.483573],
              [140.994842, 62.475898],
              [141.03792, 62.49252],
              [141.061325, 62.489834],
              [141.07842, 62.465083],
              [141.068185, 62.460944],
              [141.08102, 62.44442],
              [141.07591, 62.433207],
              [141.128164, 62.404761],
              [141.12933, 62.396668],
              [141.161606, 62.403162],
              [141.17455, 62.416705],
              [141.21822, 62.399386],
              [141.279787, 62.400903],
              [141.314379, 62.416314],
              [141.327896, 62.397646],
              [141.322277, 62.387475],
              [141.363378, 62.345377],
              [141.391133, 62.340882],
              [141.391841, 62.323614],
              [141.420903, 62.318754],
              [141.421017, 62.302541],
              [141.456588, 62.285214],
              [141.492869, 62.286735],
              [141.50437, 62.26778],
              [141.5269, 62.255515],
              [141.53986, 62.235773],
              [141.54525, 62.18069],
              [141.559283, 62.155529],
              [141.572829, 62.149282],
              [141.607506, 62.151737],
              [141.632858, 62.141878],
              [141.626925, 62.131674],
              [141.647994, 62.115758],
              [141.708222, 62.103302],
              [141.729374, 62.078307],
              [141.778878, 62.076295],
              [141.823296, 62.083877],
              [141.85493, 62.082662],
              [141.852298, 62.060914],
              [141.83174, 62.054842],
              [141.84241, 62.041912],
              [141.833325, 62.017922],
              [141.84925, 62.0154],
              [141.897412, 62.021338],
              [141.903894, 62.035321],
              [141.957493, 62.039296],
              [142.00171, 62.020176],
              [142.022489, 62.037788],
              [142.045886, 62.043576],
              [142.061563, 62.033485],
              [142.081733, 62.053158],
              [142.108654, 62.067137],
              [142.14532, 62.07551],
              [142.181286, 62.069774],
              [142.16952, 62.060032],
              [142.181468, 62.044696],
              [142.266965, 62.017924],
              [142.255451, 62.005627],
              [142.28246, 61.990797],
              [142.265991, 61.9665],
              [142.292738, 61.968122],
              [142.318117, 61.956153],
              [142.329519, 61.967712],
              [142.375686, 61.954798],
              [142.397509, 61.958573],
              [142.41709, 61.943794],
              [142.424474, 61.920605],
              [142.393293, 61.90529],
              [142.42107, 61.895734],
              [142.421049, 61.88267],
              [142.44244, 61.873455],
              [142.471657, 61.884928],
              [142.467313, 61.891966],
              [142.518457, 61.907066],
              [142.555523, 61.912663],
              [142.569167, 61.927841],
              [142.617375, 61.939848],
              [142.673781, 61.947024],
              [142.68618, 61.965485],
              [142.723366, 61.970616],
              [142.742518, 61.959688],
              [142.767657, 61.965786],
              [142.793588, 61.952197],
              [142.78729, 61.944438],
              [142.844346, 61.94751],
              [142.866266, 61.926435],
              [142.883119, 61.92451],
              [142.896399, 61.905418],
              [142.926701, 61.913874],
              [142.940479, 61.898748],
              [142.957602, 61.89841],
              [142.973968, 61.911128],
              [143.006472, 61.914842],
              [143.000232, 61.932521],
              [143.02309, 61.945692],
              [143.014314, 61.973431],
              [143.052154, 61.975846],
              [143.055557, 61.98723],
              [143.07884, 61.992666],
              [143.086812, 62.011602],
              [143.104672, 62.014728],
              [143.126801, 62.048889],
              [143.153417, 62.059373],
              [143.115075, 62.071557],
              [143.135909, 62.104004],
              [143.197541, 62.103805],
              [143.229693, 62.117471],
              [143.276342, 62.090898],
              [143.296127, 62.087315],
              [143.27977, 62.075521],
              [143.285479, 62.049911],
              [143.334967, 62.033713],
              [143.37231, 62.034006],
              [143.413333, 62.021878],
              [143.441032, 62.045505],
              [143.47987, 62.052848],
              [143.547992, 62.049755],
              [143.56373, 62.03252],
              [143.609126, 62.036011],
              [143.60635, 62.023577],
              [143.645788, 62.018704],
              [143.611058, 62.00392],
              [143.617208, 61.984889],
              [143.63625, 61.977713],
              [143.63134, 61.965832],
              [143.602775, 61.953289],
              [143.6179, 61.940864],
              [143.615074, 61.927776],
              [143.639553, 61.903285],
              [143.623527, 61.882964],
              [143.649507, 61.878154],
              [143.635303, 61.870926],
              [143.642355, 61.85787],
              [143.681857, 61.859308],
              [143.697407, 61.871227],
              [143.687047, 61.877504],
              [143.71445, 61.885679],
              [143.698927, 61.904],
              [143.731818, 61.90734],
              [143.753372, 61.902973],
              [143.78401, 61.920851],
              [143.768335, 61.945167],
              [143.777093, 61.954826],
              [143.823748, 61.956367],
              [143.85607, 61.962707],
              [143.86515, 61.980292],
              [143.901706, 61.997886],
              [143.922257, 61.994276],
              [143.919316, 61.982571],
              [143.93577, 61.973307],
              [143.920001, 61.949944],
              [143.944928, 61.948375],
              [143.959103, 61.92975],
              [143.941437, 61.917156],
              [143.964942, 61.902701],
              [143.983579, 61.903569],
              [143.998537, 61.891583],
              [143.97975, 61.875601],
              [143.983995, 61.859956],
              [143.951274, 61.839507],
              [143.94774, 61.823597],
              [143.959431, 61.812187],
              [143.933097, 61.785698],
              [143.949971, 61.777356],
              [143.944683, 61.754986],
              [143.98853, 61.756103],
              [144.001115, 61.745286],
              [144.060494, 61.750378],
              [144.122208, 61.735251],
              [144.13549, 61.756068],
              [144.187931, 61.747094],
              [144.18888, 61.732616],
              [144.209109, 61.737826],
              [144.223743, 61.727803],
              [144.25561, 61.746034],
              [144.24661, 61.766743],
              [144.297148, 61.776817],
              [144.336371, 61.792736],
              [144.354194, 61.784095],
              [144.372251, 61.791467],
              [144.371148, 61.806798],
              [144.411954, 61.8333],
              [144.454055, 61.820931],
              [144.48298, 61.832981],
              [144.500138, 61.832384],
              [144.515905, 61.845166],
              [144.549473, 61.848024],
              [144.537783, 61.862845],
              [144.548564, 61.870947],
              [144.52626, 61.875799],
              [144.547548, 61.892323],
              [144.584004, 61.904548],
              [144.617804, 61.907496],
              [144.587926, 61.91696],
              [144.609365, 61.935741],
              [144.586321, 61.94963],
              [144.582979, 61.973206],
              [144.61343, 61.978189],
              [144.633074, 61.991529],
              [144.655509, 61.990844],
              [144.672131, 62.019022],
              [144.697123, 62.019886],
              [144.719519, 62.044336],
              [144.73642, 62.045483],
              [144.772073, 62.036407],
              [144.83113, 62.047998],
              [144.85569, 62.059788],
              [144.92795, 62.054622],
              [144.956246, 62.066851],
              [144.987821, 62.051919],
              [144.992525, 62.026461],
              [145.05248, 61.996843],
              [145.144669, 62.002154],
              [145.148477, 61.980585],
              [145.17445, 61.979742],
              [145.210803, 61.964145],
              [145.234294, 61.972938],
              [145.256372, 61.990301],
              [145.290614, 62.00536],
              [145.32584, 61.994086],
              [145.361146, 61.994983],
              [145.398708, 61.976244],
              [145.440354, 61.991315],
              [145.461918, 61.988265],
              [145.450543, 61.949333],
              [145.510858, 61.96523],
              [145.527861, 61.949691],
              [145.55416, 61.956611],
              [145.554616, 61.975966],
              [145.57723, 61.974745],
              [145.597797, 61.984507],
              [145.620791, 61.974948],
              [145.65273, 61.993842],
              [145.643044, 62.00764],
              [145.659291, 62.018113],
              [145.663188, 62.03401],
              [145.634243, 62.05328],
              [145.664655, 62.056279],
              [145.66786, 62.04619],
              [145.704365, 62.035487],
              [145.753758, 62.03661],
              [145.743016, 62.030019],
              [145.779807, 62.029094],
              [145.818152, 62.001761],
              [145.843618, 62.002971],
              [145.861912, 62.013644],
              [145.926066, 62.000266],
              [145.965418, 62.004494],
              [145.988451, 61.988797],
              [145.98079, 61.981906],
              [146.022548, 61.947958],
              [146.060986, 61.950385],
              [146.087183, 61.95767],
              [146.1081, 61.944711],
              [146.100616, 61.936406],
              [146.135168, 61.929078],
              [146.157411, 61.933111],
              [146.140271, 61.906977],
              [146.176039, 61.906438],
              [146.186024, 61.914923],
              [146.238214, 61.899087],
              [146.226035, 61.871423],
              [146.25249, 61.872252],
              [146.242142, 61.857972],
              [146.277914, 61.851388],
              [146.308021, 61.857929],
              [146.347697, 61.841445],
              [146.3571, 61.81383],
              [146.388651, 61.806001],
              [146.362923, 61.781153],
              [146.379613, 61.771473],
              [146.389128, 61.743088],
              [146.427952, 61.738466],
              [146.42961, 61.719534],
              [146.455265, 61.7188],
              [146.467905, 61.707189],
              [146.456955, 61.689024],
              [146.479016, 61.689127],
              [146.52068, 61.666036],
              [146.5248, 61.657271],
              [146.572679, 61.650276],
              [146.603855, 61.615183],
              [146.58641, 61.601855],
              [146.606449, 61.575197],
              [146.591986, 61.565858],
              [146.59698, 61.552083],
              [146.580909, 61.531723],
              [146.66786, 61.496357],
              [146.673275, 61.488312],
              [146.708261, 61.490057],
              [146.739752, 61.468605],
              [146.716501, 61.444985],
              [146.706629, 61.416811],
              [146.68859, 61.408637],
              [146.714902, 61.396332],
              [146.729782, 61.403394],
              [146.747149, 61.38622],
              [146.728651, 61.352298],
              [146.712803, 61.34519],
              [146.698508, 61.322757],
              [146.698001, 61.306826],
              [146.733556, 61.264154],
              [146.730453, 61.252452],
              [146.74663, 61.236461],
              [146.757482, 61.209857],
              [146.742649, 61.1938],
              [146.743798, 61.178721],
              [146.728865, 61.164504],
              [146.757117, 61.100403],
              [146.756434, 61.093282],
              [146.725255, 61.09785],
              [146.689305, 61.093608],
              [146.670831, 61.083864],
              [146.682238, 61.076655],
              [146.664852, 61.067675],
              [146.667757, 61.054407],
              [146.712682, 61.040488],
              [146.7202, 61.013136],
              [146.75447, 61.003652],
              [146.721791, 60.992906],
              [146.70866, 60.975015],
              [146.71989, 60.965353],
              [146.705414, 60.949364],
              [146.66211, 60.950901],
              [146.652, 60.945716],
              [146.568905, 60.954392],
              [146.499321, 60.941147],
              [146.511681, 60.932212],
              [146.479014, 60.91716],
              [146.504548, 60.904813],
              [146.52209, 60.867943],
              [146.503261, 60.867388],
              [146.49871, 60.85376],
              [146.534597, 60.842448],
              [146.50785, 60.832275],
              [146.480923, 60.809335],
              [146.501062, 60.802687],
              [146.4872, 60.777343],
              [146.455747, 60.785163],
              [146.465473, 60.769191],
              [146.446264, 60.755915],
              [146.469313, 60.736664],
              [146.454625, 60.72933],
              [146.45999, 60.704862],
              [146.431612, 60.688452],
              [146.444025, 60.671125],
              [146.34966, 60.659897],
              [146.336103, 60.652664],
              [146.297767, 60.669397],
              [146.280041, 60.667611],
              [146.28128, 60.685494],
              [146.242154, 60.698121],
              [146.199886, 60.701338],
              [146.173735, 60.732191],
              [146.151136, 60.744506],
              [146.1338, 60.73676],
              [146.085257, 60.73658],
              [146.061358, 60.725503],
              [146.063997, 60.708828],
              [145.994941, 60.675609],
              [145.982425, 60.658375],
              [145.920307, 60.655805],
              [145.910596, 60.643125],
              [145.930939, 60.619104],
              [145.847676, 60.610482],
              [145.830282, 60.601436],
              [145.792376, 60.614177],
              [145.748897, 60.599828],
              [145.713608, 60.594978],
              [145.714854, 60.578764],
              [145.645282, 60.556981],
              [145.639837, 60.545287],
              [145.65509, 60.528835],
              [145.640721, 60.517542],
              [145.626598, 60.52481],
              [145.587037, 60.515271],
              [145.566349, 60.515785],
              [145.563685, 60.504242],
              [145.528851, 60.494829],
              [145.535392, 60.462866],
              [145.525005, 60.45554],
              [145.538663, 60.444499],
              [145.53005, 60.422181],
              [145.58146, 60.411589],
              [145.637327, 60.414645],
              [145.657322, 60.38814],
              [145.659894, 60.361861],
              [145.615686, 60.337572],
              [145.57439, 60.335558],
              [145.555693, 60.322623],
              [145.561934, 60.311641],
              [145.600369, 60.310756],
              [145.617888, 60.292888],
              [145.611438, 60.284773],
              [145.636964, 60.264846],
              [145.677225, 60.252353],
              [145.698828, 60.237327],
              [145.737718, 60.226411],
              [145.770586, 60.204123],
              [145.79459, 60.211519],
              [145.857411, 60.170272],
              [145.882826, 60.171169],
              [145.915409, 60.180972],
              [145.920186, 60.197625],
              [145.978902, 60.202491],
              [146.049952, 60.195748],
              [146.090755, 60.206653],
              [146.132452, 60.200305],
              [146.21731, 60.208566],
              [146.217277, 60.217982],
              [146.265748, 60.23566],
              [146.313531, 60.244643],
              [146.340462, 60.243678],
              [146.372387, 60.250587],
              [146.408265, 60.241535],
              [146.41052, 60.217274],
              [146.432792, 60.215523],
              [146.453189, 60.202174],
              [146.484631, 60.192764],
              [146.511635, 60.160368],
              [146.499899, 60.148409],
              [146.500442, 60.12335],
              [146.533677, 60.124984],
              [146.53747, 60.097224],
              [146.579596, 60.092908],
              [146.608579, 60.115646],
              [146.664963, 60.101209],
              [146.689112, 60.087294],
              [146.690388, 60.064478],
              [146.672289, 60.038349],
              [146.69053, 60.027667],
              [146.683728, 60.004762],
              [146.705747, 59.988169],
              [146.70214, 59.971554],
              [146.722682, 59.962885],
              [146.747207, 59.962624],
              [146.758139, 59.94688],
              [146.77842, 59.9426],
              [146.77542, 59.92131],
              [146.80077, 59.905055],
              [146.88295, 59.924335],
              [146.904867, 59.940006],
              [146.9332, 59.942806],
              [146.942227, 59.951859],
              [147.001935, 59.953285],
              [147.03927, 59.947898],
              [147.061496, 59.929358],
              [147.028112, 59.914996],
              [147.04359, 59.903588],
              [147.11826, 59.886198],
              [147.118901, 59.866597],
              [147.139833, 59.844838],
              [147.166118, 59.837383],
              [147.142002, 59.827433],
              [147.156659, 59.808469],
              [147.146468, 59.796661],
              [147.201996, 59.75988],
              [147.21618, 59.73206],
              [147.198585, 59.720802],
              [147.17234, 59.716919],
              [147.12605, 59.696004],
              [147.145368, 59.679472],
              [147.13966, 59.665316],
              [147.15554, 59.647538],
              [147.168798, 59.646334],
              [147.164454, 59.621808],
              [147.143398, 59.606033],
              [147.15439, 59.600556],
              [147.152583, 59.563969],
              [147.136835, 59.548483],
              [147.142621, 59.53333],
              [147.137041, 59.512844],
              [147.148458, 59.504515],
              [147.102551, 59.470346],
              [147.093636, 59.45473],
              [147.110148, 59.43761],
              [147.108655, 59.408506],
              [147.00181, 59.401602],
              [146.97398, 59.389481],
              [146.937766, 59.38745],
              [146.94151, 59.368693],
              [146.897225, 59.368353],
              [146.850337, 59.359945],
              [146.809842, 59.373077],
              [146.782003, 59.37212],
              [146.760224, 59.383031],
              [146.70101, 59.389714],
              [146.691814, 59.394451],
              [146.696052, 59.42139],
              [146.678007, 59.433249],
              [146.616964, 59.447925],
              [146.574406, 59.44827],
              [146.571891, 59.456589],
              [146.511008, 59.465413],
              [146.44101, 59.46173],
              [146.388777, 59.44299],
              [146.315966, 59.405741],
              [146.303374, 59.389637],
              [146.323473, 59.3437],
              [146.323497, 59.302837],
              [146.30183, 59.278511],
              [146.317832, 59.259417],
              [146.31814, 59.235006],
              [146.35263, 59.223986],
              [146.352857, 59.206626],
              [146.309784, 59.18447],
              [146.288658, 59.193183],
              [146.225548, 59.203915],
              [146.193114, 59.204926],
              [146.152344, 59.192701],
              [146.139098, 59.166486],
              [146.115671, 59.172216],
              [146.06682, 59.156203],
              [146.047259, 59.141124],
              [145.980455, 59.144318],
              [145.990097, 59.181886],
              [145.915427, 59.218427],
              [145.892129, 59.212731],
              [145.82965, 59.241875],
              [145.830529, 59.253376],
              [145.849825, 59.275717],
              [145.85124, 59.302082],
              [145.880854, 59.307881],
              [145.902548, 59.318958],
              [145.90369, 59.329898],
              [145.948081, 59.348323],
              [145.943419, 59.364035],
              [145.967574, 59.391187],
              [145.96803, 59.406859],
              [145.918554, 59.422212],
              [145.876954, 59.398847],
              [145.818553, 59.410373],
              [145.81529, 59.371812],
              [145.802559, 59.365585],
              [145.777061, 59.372],
              [145.75522, 59.390901],
              [145.715169, 59.401866],
              [145.708675, 59.422568],
              [145.68671, 59.425311],
              [145.680973, 59.410291],
              [145.572079, 59.420626],
              [145.46943, 59.410767],
              [145.398905, 59.398617],
              [145.357798, 59.388133],
              [145.317656, 59.385932],
              [145.330263, 59.39943],
              [145.299997, 59.415093],
              [145.267007, 59.417065],
              [145.205335, 59.409008],
              [145.05233, 59.377261],
              [144.980273, 59.367333],
              [144.873639, 59.366372],
              [144.819821, 59.368553],
              [144.77269, 59.383379],
              [144.6293, 59.380501],
              [144.618022, 59.372922],
              [144.548633, 59.369648],
              [144.39043, 59.369909],
              [144.286155, 59.377339],
              [144.133788, 59.396339],
              [144.026918, 59.402363],
              [143.936433, 59.403429],
              [143.87686, 59.40137],
              [143.807921, 59.394874],
              [143.76254, 59.387623],
              [143.68012, 59.368813],
              [143.5551, 59.335127],
              [143.482087, 59.319404],
              [143.456311, 59.327003],
              [143.38166, 59.325887],
              [143.321659, 59.360046],
              [143.26981, 59.362378],
              [143.212751, 59.356477],
              [143.073407, 59.328911],
              [143.036212, 59.324721],
              [142.96056, 59.306284],
              [142.853156, 59.274845],
              [142.676807, 59.247745],
              [142.57169, 59.228567],
              [142.474945, 59.205005],
              [142.399831, 59.180626],
              [142.258984, 59.122449],
              [142.148201, 59.071223],
              [142.079535, 59.033055],
              [141.984867, 58.965703],
              [141.917608, 58.905056],
              [141.85244, 58.828903],
              [141.827167, 58.812465],
              [141.781003, 58.773113],
              [141.77143, 58.760516],
              [141.723058, 58.72315],
              [141.676047, 58.68179],
              [141.66871, 58.68179],
              [141.611042, 58.640792],
              [141.597765, 58.636224],
              [141.54203, 58.601575],
              [141.480929, 58.577318],
              [141.425575, 58.560938],
              [141.413049, 58.551326],
              [141.355032, 58.527829],
              [141.314581, 58.506772],
              [141.281816, 58.503088],
              [141.219772, 58.483264],
              [141.193322, 58.469371],
              [141.168676, 58.465339],
              [141.136199, 58.468406],
              [141.080844, 58.450337],
              [141.03836, 58.442325],
              [141.01217, 58.43139],
              [140.875892, 58.358727],
              [140.861839, 58.347279],
              [140.777209, 58.303902],
              [140.739567, 58.270696],
              [140.689589, 58.218575],
              [140.658167, 58.178085],
              [140.64169, 58.14447],
              [140.629477, 58.107229],
              [140.60464, 58.072272],
              [140.602526, 58.059533],
              [140.57763, 58.037802],
              [140.554419, 58.005589],
              [140.489559, 57.883339],
              [140.49523, 57.850251],
              [140.48858, 57.843047],
              [140.42742, 57.827115],
              [140.407742, 57.809225],
              [140.380482, 57.796108],
              [140.351661, 57.790911],
              [140.32271, 57.768404],
              [140.308841, 57.747988],
              [140.293066, 57.763064],
              [140.272122, 57.767276],
              [140.24072, 57.764567],
              [140.19409, 57.747086],
              [140.114753, 57.743132],
              [140.056717, 57.726694],
              [139.99351, 57.698496],
              [139.92938, 57.652525],
              [139.87577, 57.599917],
              [139.843604, 57.556808],
              [139.821262, 57.513279],
              [139.78781, 57.484173],
              [139.73742, 57.493048],
              [139.705579, 57.490302],
              [139.645728, 57.46902],
              [139.60188, 57.460137],
              [139.563746, 57.424998],
              [139.516078, 57.353007],
              [139.491622, 57.335923],
              [139.463784, 57.33005],
              [139.415408, 57.300607],
              [139.388338, 57.310163],
              [139.356019, 57.301702],
              [139.31516, 57.280006],
              [139.227879, 57.277456],
              [139.182962, 57.2719],
              [139.117446, 57.239271],
              [139.05841, 57.198975],
              [139.032199, 57.162301],
              [139.026614, 57.137658],
              [139.004743, 57.154309],
              [138.971938, 57.153953],
              [138.952977, 57.144278],
              [138.926132, 57.142852],
              [138.931668, 57.116689],
              [138.90973, 57.101741],
              [138.930232, 57.090158],
              [138.9286, 57.064433],
              [138.909234, 57.047088],
              [138.90467, 57.030911],
              [138.86988, 57.013284],
              [138.825423, 57.015757],
              [138.822917, 57.027441],
              [138.8045, 57.031941],
              [138.782115, 57.020031],
              [138.79844, 57.012301],
              [138.79218, 57.003955],
              [138.761568, 57.009686],
              [138.708112, 56.977723],
              [138.685476, 56.986916],
              [138.663259, 56.982024],
              [138.610632, 56.955256],
              [138.581219, 56.905758],
              [138.564299, 56.852407],
              [138.57098, 56.841127],
              [138.556004, 56.817893],
              [138.57331, 56.810652],
              [138.579231, 56.785165],
              [138.562733, 56.778966],
              [138.547459, 56.793742],
              [138.553137, 56.843601],
              [138.548442, 56.858721],
              [138.533334, 56.85098],
              [138.4884, 56.850649],
              [138.44925, 56.844677],
              [138.45028, 56.8328],
              [138.468636, 56.813905],
              [138.419213, 56.784193],
              [138.422857, 56.772629],
              [138.401823, 56.757775],
              [138.393389, 56.733007],
              [138.35399, 56.712686],
              [138.33613, 56.718087],
              [138.2863, 56.694723],
              [138.281315, 56.670207],
              [138.259906, 56.660424],
              [138.253336, 56.648789],
              [138.260734, 56.617261],
              [138.248236, 56.627249],
              [138.227307, 56.627494],
              [138.21296, 56.607148],
              [138.202644, 56.61018],
              [138.136084, 56.60137],
              [138.112203, 56.578467],
              [138.10775, 56.538868],
              [138.133464, 56.494414],
              [138.178419, 56.47075],
              [138.233691, 56.464338],
              [138.254262, 56.468996],
              [138.25285, 56.457963],
              [138.230934, 56.452046],
              [138.228562, 56.423235],
              [138.186531, 56.427931],
              [138.176651, 56.447507],
              [138.119079, 56.437617],
              [138.098998, 56.441425],
              [138.062948, 56.43865],
              [138.055151, 56.430884],
              [138.06791, 56.399057],
              [138.04158, 56.37899],
              [138.019983, 56.383549],
              [137.947612, 56.366879],
              [137.925061, 56.367983],
              [137.892861, 56.337298],
              [137.867787, 56.294418],
              [137.86824, 56.281207],
              [137.845846, 56.266394],
              [137.834425, 56.229675],
              [137.795647, 56.226605],
              [137.77853, 56.216979],
              [137.751701, 56.177019],
              [137.7318, 56.17107],
              [137.718291, 56.155034],
              [137.725108, 56.137901],
              [137.696155, 56.131657],
              [137.687927, 56.123446],
              [137.667201, 56.129299],
              [137.643616, 56.11945],
              [137.626494, 56.121525],
              [137.55053, 56.105803],
              [137.486368, 56.061251],
              [137.462363, 56.056096],
              [137.42619, 56.034862],
              [137.413961, 56.006877],
              [137.41502, 55.970183],
              [137.40425, 55.970776],
              [137.39517, 55.947084],
              [137.37025, 55.949571],
              [137.342707, 55.969536],
              [137.314866, 55.96929],
              [137.291537, 55.961837],
              [137.245352, 55.919676],
              [137.195288, 55.903628],
              [137.183493, 55.893304],
              [137.163143, 55.894364],
              [137.126901, 55.868025],
              [137.099131, 55.860952],
              [137.064172, 55.827769],
              [137.05039, 55.787478],
              [137.028348, 55.786593],
              [137.004347, 55.796727],
              [136.980635, 55.795824],
              [136.926753, 55.780107],
              [136.90317, 55.769083],
              [136.8917, 55.744947],
              [136.851419, 55.738667],
              [136.842587, 55.7251],
              [136.799623, 55.695228],
              [136.797264, 55.663941],
              [136.754457, 55.651313],
              [136.71004, 55.66636],
              [136.697553, 55.657078],
              [136.651717, 55.638984],
              [136.6231, 55.621917],
              [136.594016, 55.615091],
              [136.556421, 55.623856],
              [136.54217, 55.584674],
              [136.519654, 55.578921],
              [136.48177, 55.586343],
              [136.454304, 55.582812],
              [136.415586, 55.566107],
              [136.396057, 55.543896],
              [136.35042, 55.527549],
              [136.311092, 55.4636],
              [136.29695, 55.454289],
              [136.301858, 55.425425],
              [136.258597, 55.413557],
              [136.247839, 55.385105],
              [136.203885, 55.350262],
              [136.173381, 55.344957],
              [136.16869, 55.317409],
              [136.143236, 55.311196],
              [136.133397, 55.289082],
              [136.062792, 55.286997],
              [136.049285, 55.278873],
              [136.033626, 55.256236],
              [135.963775, 55.242213],
              [135.954944, 55.224075],
              [135.91812, 55.212485],
              [135.899578, 55.188699],
              [135.852467, 55.17349],
              [135.82291, 55.180528],
              [135.805612, 55.16651],
              [135.698169, 55.114035],
              [135.645047, 55.118332],
              [135.58825, 55.0995],
              [135.53725, 55.08925],
              [135.50625, 55.0695],
              [135.463, 55.05425],
              [135.45575, 55.02825],
              [135.42625, 55.02075],
              [135.38275, 55.00075],
              [135.36, 54.9945],
              [135.33825, 54.9775],
              [135.29675, 54.95825],
              [135.28, 54.93175],
              [135.25875, 54.92775],
              [135.25825, 54.91625],
              [135.23175, 54.91325],
              [135.22125, 54.894],
              [135.2045, 54.89075],
              [135.2065, 54.8665],
              [135.18225, 54.8625],
              [135.1745, 54.825],
              [135.19625, 54.80325],
              [135.22175, 54.7875],
              [135.27025, 54.748],
              [135.26575, 54.72425],
              [135.2435, 54.712],
              [135.22675, 54.71625],
              [135.209, 54.70575],
              [135.21225, 54.69725],
              [135.239, 54.70275],
              [135.271, 54.6985],
              [135.2925, 54.704],
              [135.30575, 54.72425],
              [135.3845, 54.70175],
              [135.453, 54.68725],
              [135.5, 54.6815],
              [135.565, 54.6645],
              [135.58475, 54.66575],
              [135.60475, 54.65675],
              [135.63825, 54.66475],
              [135.68875, 54.64575],
              [135.687, 54.62],
              [135.703, 54.6075],
              [135.6965, 54.598],
              [135.71825, 54.5735],
              [135.7445, 54.56575],
              [135.76875, 54.569],
              [135.81975, 54.56225],
              [135.84825, 54.562],
              [135.9595, 54.56725],
              [136.01825, 54.5765],
              [136.04825, 54.5885],
              [136.0875, 54.592],
              [136.1015, 54.603],
              [136.137, 54.59925],
              [136.17275, 54.6025],
              [136.22125, 54.614],
              [136.22825, 54.6045],
              [136.25525, 54.5945],
              [136.286, 54.5985],
              [136.3035, 54.6065],
              [136.33875, 54.59],
              [136.3745, 54.59975],
              [136.387, 54.588],
              [136.41275, 54.5925],
              [136.48, 54.585],
              [136.51475, 54.5875],
              [136.57, 54.60275],
              [136.6105, 54.622],
              [136.61875, 54.63925],
              [136.648, 54.65925],
              [136.668, 54.683],
              [136.669, 54.6645],
              [136.643, 54.6235],
              [136.66025, 54.61225],
              [136.693, 54.60275],
              [136.734, 54.61],
              [136.783001, 54.642383],
              [136.829083, 54.643901],
              [136.81625, 54.6285],
              [136.82625, 54.59625],
              [136.856822, 54.590986],
              [136.876309, 54.568419],
              [136.854646, 54.560769],
              [136.848871, 54.546382],
              [136.831269, 54.546489],
              [136.824986, 54.52853],
              [136.803549, 54.510108],
              [136.813713, 54.498922],
              [136.849241, 54.488403],
              [136.826279, 54.485237],
              [136.82187, 54.467913],
              [136.800445, 54.461148],
              [136.8315, 54.429],
              [136.7945, 54.4],
              [136.79075, 54.42375],
              [136.74975, 54.417],
              [136.7615, 54.392],
              [136.753, 54.3585],
              [136.76825, 54.34025],
              [136.7875, 54.329],
              [136.799, 54.33225],
              [136.818, 54.32225],
              [136.804, 54.2945],
              [136.7875, 54.298],
              [136.75675, 54.2925],
              [136.7515, 54.282],
              [136.77925, 54.255],
              [136.79525, 54.26125],
              [136.80875, 54.23525],
              [136.78675, 54.22],
              [136.7585, 54.21125],
              [136.76625, 54.1945],
              [136.78925, 54.19],
              [136.814, 54.1685],
              [136.809, 54.153],
              [136.815, 54.132],
              [136.79325, 54.125],
              [136.80125, 54.1105],
              [136.7745, 54.08575],
              [136.786, 54.0625],
              [136.7695, 54.04725],
              [136.75, 54.0465],
              [136.722, 54.033],
              [136.70125, 54.013],
              [136.70025, 53.9825],
              [136.679, 53.9515],
              [136.6845, 53.9355],
              [136.678, 53.92175],
              [136.69175, 53.91275],
              [136.69075, 53.8815],
              [136.70575, 53.869],
              [136.69425, 53.84875],
              [136.707, 53.82375],
              [136.72425, 53.80875],
              [136.7685, 53.79325],
              [136.783, 53.7935],
              [136.793, 53.779],
              [136.8475, 53.806],
              [136.85675, 53.83],
              [136.91775, 53.8355],
              [136.94475, 53.85325],
              [136.96, 53.84725],
              [136.988, 53.84975],
              [136.99775, 53.84275],
              [137.062, 53.84725],
              [137.07875, 53.82775],
              [137.11975, 53.82775],
              [137.172, 53.84425],
              [137.18875, 53.8615],
              [137.212, 53.9145],
              [137.2495, 53.96025],
              [137.26925, 54.00625],
              [137.290165, 54.038169],
              [137.28413, 54.047556],
              [137.25997, 54.054646],
              [137.238833, 54.053708],
              [137.19425, 54.038482],
              [137.16228, 54.038065],
              [137.159, 54.05875],
              [137.11825, 54.0905],
              [137.11425, 54.11725],
              [137.0845, 54.12475],
              [137.09475, 54.147],
              [137.12825, 54.173],
              [137.15275, 54.17475],
              [137.1885, 54.189],
              [137.1895, 54.21],
              [137.21, 54.22025],
              [137.251, 54.209],
              [137.28425, 54.22425],
              [137.28325, 54.2415],
              [137.26775, 54.248],
              [137.32275, 54.27075],
              [137.349, 54.26325],
              [137.3905, 54.28225],
              [137.38, 54.29375],
              [137.4005, 54.306],
              [137.43025, 54.3],
              [137.45175, 54.314],
              [137.47325, 54.3055],
              [137.499, 54.30925],
              [137.514, 54.295],
              [137.57375, 54.292],
              [137.61475, 54.30275],
              [137.62925, 54.293],
              [137.6525, 54.29875],
              [137.67225, 54.31475],
              [137.69825, 54.319],
              [137.718, 54.31325],
              [137.72475, 54.32075],
              [137.752, 54.32375],
              [137.74425, 54.3065],
              [137.68675, 54.2775],
              [137.6655, 54.255],
              [137.62925, 54.24125],
              [137.62175, 54.22125],
              [137.59225, 54.205],
              [137.58725, 54.19425],
              [137.55275, 54.17575],
              [137.52925, 54.1575],
              [137.52175, 54.14225],
              [137.49275, 54.13375],
              [137.47675, 54.1105],
              [137.477, 54.09375],
              [137.4525, 54.09025],
              [137.458, 54.1115],
              [137.44725, 54.13275],
              [137.41025, 54.126],
              [137.3705, 54.127],
              [137.329, 54.10925],
              [137.3175, 54.088],
              [137.315, 54.0665],
              [137.33375, 54.05125],
              [137.36475, 54.05425],
              [137.3735, 54.0635],
              [137.40275, 54.03375],
              [137.43075, 54.03875],
              [137.47125, 54.0125],
              [137.567, 53.96225],
              [137.605, 53.95225],
              [137.636, 53.96275],
              [137.73325, 53.9565],
              [137.77475, 53.94975],
              [137.82425, 53.956],
              [137.84175, 53.96175],
              [137.82, 53.9395],
              [137.819, 53.92025],
              [137.80525, 53.90475],
              [137.74025, 53.89275],
              [137.73275, 53.884],
              [137.699, 53.87975],
              [137.68875, 53.867],
              [137.66575, 53.8705],
              [137.6485, 53.8495],
              [137.606, 53.83075],
              [137.6295, 53.81625],
              [137.6065, 53.8105],
              [137.59575, 53.7875],
              [137.599, 53.77275],
              [137.571, 53.77325],
              [137.566, 53.74325],
              [137.556, 53.72825],
              [137.52725, 53.7125],
              [137.49775, 53.687],
              [137.49925, 53.656],
              [137.47925, 53.661],
              [137.3975, 53.65625],
              [137.363, 53.65],
              [137.3095, 53.6305],
              [137.25325, 53.595],
              [137.25825, 53.58075],
              [137.24025, 53.57225],
              [137.241374, 53.561895],
              [137.293068, 53.540978],
              [137.320833, 53.533869],
              [137.3595, 53.54175],
              [137.40325, 53.53925],
              [137.4585, 53.54875],
              [137.616, 53.55075],
              [137.702, 53.549],
              [137.7625, 53.56075],
              [137.86375, 53.564],
              [137.87275, 53.56925],
              [137.91225, 53.5725],
              [137.95025, 53.581],
              [138.01275, 53.626],
              [138.06625, 53.635],
              [138.10075, 53.64475],
              [138.11375, 53.65625],
              [138.1815, 53.67325],
              [138.21225, 53.69975],
              [138.28225, 53.7295],
              [138.32225, 53.75525],
              [138.33775, 53.79],
              [138.3355, 53.8275],
              [138.35025, 53.8485],
              [138.35875, 53.88],
              [138.37075, 53.902],
              [138.46, 53.939],
              [138.49525, 53.95],
              [138.5145, 53.9705],
              [138.58225, 53.9945],
              [138.592, 53.986],
              [138.6005, 53.95325],
              [138.5795, 53.942],
              [138.57875, 53.8915],
              [138.59375, 53.8685],
              [138.592, 53.83425],
              [138.58175, 53.8245],
              [138.586, 53.806],
              [138.5765, 53.794],
              [138.55075, 53.78475],
              [138.52175, 53.75875],
              [138.496, 53.74725],
              [138.387, 53.667],
              [138.3525, 53.6275],
              [138.36625, 53.605],
              [138.34675, 53.58975],
              [138.30375, 53.5655],
              [138.274, 53.554],
              [138.27875, 53.514],
              [138.31325, 53.52325],
              [138.36525, 53.5135],
              [138.40925, 53.5185],
              [138.4895, 53.54275],
              [138.55475, 53.58725],
              [138.5885, 53.61675],
              [138.6595, 53.707],
              [138.68075, 53.79175],
              [138.70075, 53.82875],
              [138.6925, 53.884],
              [138.694, 53.9065],
              [138.7305, 53.93],
              [138.75475, 53.952],
              [138.76875, 53.97825],
              [138.769, 54.00875],
              [138.7605, 54.03825],
              [138.76825, 54.0695],
              [138.762, 54.077],
              [138.738, 54.07575],
              [138.705, 54.05875],
              [138.7115, 54.04875],
              [138.66625, 54.05625],
              [138.663, 54.0635],
              [138.6825, 54.079],
              [138.718, 54.123],
              [138.73275, 54.15325],
              [138.73225, 54.17],
              [138.721, 54.1955],
              [138.7365, 54.22425],
              [138.74, 54.247],
              [138.728, 54.263],
              [138.6945, 54.26525],
              [138.66, 54.286],
              [138.692, 54.28675],
              [138.6975, 54.2985],
              [138.72425, 54.3035],
              [138.7535, 54.302],
              [138.77325, 54.287],
              [138.833833, 54.27407],
              [138.8475, 54.26575],
              [138.922942, 54.240477],
              [139.04875, 54.21],
              [139.13925, 54.19275],
              [139.18975, 54.185],
              [139.31825, 54.1745],
              [139.35825, 54.186],
              [139.432, 54.19225],
              [139.475, 54.2015],
              [139.5035, 54.21525],
              [139.54225, 54.2255],
              [139.56925, 54.2275],
              [139.638, 54.247],
              [139.68175, 54.27575],
              [139.69675, 54.277],
              [139.7335, 54.2955],
              [139.74675, 54.29075],
              [139.7855, 54.2915],
              [139.7945, 54.269],
              [139.784, 54.2265],
              [139.77725, 54.21775],
              [139.79175, 54.203],
              [139.8335, 54.18475],
              [139.8595, 54.18125],
              [139.879, 54.1985],
              [139.89075, 54.19175],
              [139.8745, 54.1795],
              [139.8885, 54.16675],
              [139.956, 54.1235],
              [140.038, 54.09525],
              [140.08975, 54.082708],
              [140.117522, 54.081133],
              [140.158481, 54.064495],
              [140.196114, 54.055626],
              [140.206791, 54.047097],
              [140.215601, 54.021671],
              [140.207608, 53.999317],
              [140.260236, 53.974994],
              [140.279256, 53.972832],
              [140.328675, 53.9544],
              [140.308371, 53.928436],
              [140.284683, 53.923902],
              [140.256023, 53.893002],
              [140.25628, 53.870787],
              [140.310494, 53.805452],
              [140.34666, 53.780199],
              [140.419712, 53.750079],
              [140.482228, 53.730111],
              [140.519533, 53.712208],
              [140.50593, 53.689033],
              [140.522223, 53.66461],
              [140.56972, 53.634777],
              [140.635083, 53.605331],
              [140.671672, 53.593405],
              [140.73231, 53.563011],
              [140.791317, 53.545714],
              [140.826102, 53.53178],
              [140.878209, 53.51903],
              [140.94576, 53.499171],
              [141.087524, 53.466571],
              [141.120744, 53.455826],
              [141.140218, 53.441521],
              [141.18489, 53.435339],
              [141.207762, 53.424074],
              [141.286484, 53.395478],
              [141.326136, 53.375137],
              [141.306102, 53.374132],
              [141.28128, 53.382443],
              [141.239518, 53.408183],
              [141.186089, 53.423906],
              [141.166118, 53.434304],
              [141.120988, 53.442713],
              [141.112353, 53.453761],
              [141.038073, 53.469895],
              [141.024116, 53.479833],
              [140.95686, 53.494786],
              [140.931162, 53.488549],
              [140.907691, 53.459936],
              [140.908802, 53.427545],
              [140.94677, 53.426842],
              [140.975154, 53.443993],
              [140.996074, 53.445215],
              [140.989936, 53.417362],
              [141.013562, 53.406888],
              [141.111978, 53.394921],
              [141.088378, 53.377255],
              [141.095853, 53.368253],
              [141.135289, 53.3554],
              [141.189817, 53.317769],
              [141.245026, 53.304674],
              [141.282642, 53.314824],
              [141.320409, 53.300686],
              [141.362189, 53.305734],
              [141.373279, 53.299343],
              [141.401559, 53.304929],
              [141.417165, 53.293804],
              [141.39324, 53.275997],
              [141.38006, 53.253006],
              [141.342343, 53.23352],
              [141.34129, 53.224546],
              [141.365163, 53.222857],
              [141.40467, 53.22787],
              [141.410189, 53.192373],
              [141.443017, 53.165211],
              [141.408974, 53.144402],
              [141.38804, 53.14559],
              [141.358807, 53.136709],
              [141.365974, 53.121036],
              [141.344096, 53.107904],
              [141.344379, 53.099865],
              [141.368536, 53.083689],
              [141.348148, 53.077224],
              [141.308394, 53.085351],
              [141.243731, 53.080829],
              [141.224, 53.071482],
              [141.223828, 53.059714],
              [141.241339, 53.056743],
              [141.255976, 53.04214],
              [141.237162, 53.028592],
              [141.208156, 53.019304],
              [141.199451, 53.00115],
              [141.1487, 52.992064],
              [141.142646, 52.94766],
              [141.140312, 52.868607],
              [141.176425, 52.855645],
              [141.236419, 52.867667],
              [141.235788, 52.860197],
              [141.197973, 52.840654],
              [141.185773, 52.819788],
              [141.167426, 52.80682],
              [141.16353, 52.790359],
              [141.193373, 52.774183],
              [141.229642, 52.745831],
              [141.246597, 52.73716],
              [141.250041, 52.686473],
              [141.28402, 52.657952],
              [141.256909, 52.648598],
              [141.25931, 52.601004],
              [141.2663, 52.593915],
              [141.247155, 52.574215],
              [141.241204, 52.551627],
              [141.211035, 52.508679],
              [141.148575, 52.508408],
              [141.108822, 52.481543],
              [141.107232, 52.471615],
              [141.115573, 52.411484],
              [141.13593, 52.385788],
              [141.166452, 52.362886],
              [141.21061, 52.349426],
              [141.228975, 52.320187],
              [141.25099, 52.321222],
              [141.24297, 52.305945],
              [141.259498, 52.293596],
              [141.29188, 52.285406],
              [141.316658, 52.291174],
              [141.3426, 52.250341],
              [141.39092, 52.220949],
              [141.438789, 52.217378],
              [141.452389, 52.225811],
              [141.480623, 52.216568],
              [141.505012, 52.221075],
              [141.498585, 52.240717],
              [141.509614, 52.244793],
              [141.529221, 52.234937],
              [141.513814, 52.221332],
              [141.538119, 52.205713],
              [141.524789, 52.182372],
              [141.545942, 52.152291],
              [141.499347, 52.16177],
              [141.461436, 52.159927],
              [141.41809, 52.14342],
              [141.363724, 52.109752],
              [141.348889, 52.097003],
              [141.343241, 52.050592],
              [141.33741, 52.03569],
              [141.349577, 51.989963],
              [141.362771, 51.988109],
              [141.39609, 51.967132],
              [141.41319, 51.970209],
              [141.421821, 51.956796],
              [141.39696, 51.940773],
              [141.389018, 51.914925],
              [141.353635, 51.919043],
              [141.303241, 51.898325],
              [141.301908, 51.89202],
              [141.249132, 51.880921],
              [141.23865, 51.865194],
              [141.249175, 51.859222],
              [141.193961, 51.849468],
              [141.16827, 51.828469],
              [141.162434, 51.796456],
              [141.17977, 51.787254],
              [141.163979, 51.778724],
              [141.139261, 51.745081],
              [141.106819, 51.724139],
              [141.102013, 51.711024],
              [141.119807, 51.702762],
              [141.104702, 51.682262],
              [141.066882, 51.686767],
              [141.030176, 51.683713],
              [140.969237, 51.66861],
              [140.94973, 51.651428],
              [140.904607, 51.629628],
              [140.884544, 51.628206],
              [140.872881, 51.612215],
              [140.88186, 51.571264],
              [140.888895, 51.56442],
              [140.888659, 51.540871],
              [140.898573, 51.524877],
              [140.886187, 51.511703],
              [140.888017, 51.496752],
              [140.856559, 51.486218],
              [140.842939, 51.491751],
              [140.828232, 51.484514],
              [140.797823, 51.486717],
              [140.785594, 51.465842],
              [140.765186, 51.467502],
              [140.760241, 51.459496],
              [140.784066, 51.455726],
              [140.782743, 51.435072],
              [140.79439, 51.422294],
              [140.80933, 51.426596],
              [140.844969, 51.419978],
              [140.862715, 51.423429],
              [140.868741, 51.43745],
              [140.876071, 51.427469],
              [140.855397, 51.416043],
              [140.863683, 51.393024],
              [140.858235, 51.370762],
              [140.832627, 51.365385],
              [140.81833, 51.348211],
              [140.77784, 51.334384],
              [140.758264, 51.345407],
              [140.709206, 51.337064],
              [140.669759, 51.322536],
              [140.677729, 51.304506],
              [140.672505, 51.291305],
              [140.690097, 51.273202],
              [140.701131, 51.249517],
              [140.688138, 51.251733],
              [140.636007, 51.236677],
              [140.659335, 51.220022],
              [140.657518, 51.194287],
              [140.667578, 51.171868],
              [140.658985, 51.140102],
              [140.647602, 51.118886],
              [140.666637, 51.101861],
              [140.681833, 51.053201],
              [140.67947, 51.035714],
              [140.645968, 51.022938],
              [140.633595, 50.994488],
              [140.649474, 50.977856],
              [140.650853, 50.961034],
              [140.640816, 50.952009],
              [140.60018, 50.94411],
              [140.566645, 50.930107],
              [140.524, 50.899062],
              [140.53049, 50.889827],
              [140.501573, 50.871861],
              [140.503302, 50.849126],
              [140.518575, 50.833399],
              [140.487586, 50.779855],
              [140.449943, 50.755566],
              [140.45083, 50.733551],
              [140.430234, 50.718748],
              [140.467515, 50.66044],
              [140.475417, 50.637624],
              [140.491874, 50.628926],
              [140.45567, 50.609741],
              [140.436026, 50.574869],
              [140.413083, 50.543969],
              [140.424596, 50.506045],
              [140.483422, 50.459065],
              [140.47763, 50.442299],
              [140.48529, 50.432045],
              [140.495517, 50.39558],
              [140.498499, 50.368355],
              [140.530499, 50.338531],
              [140.509961, 50.327243],
              [140.492783, 50.30241],
              [140.492426, 50.266279],
              [140.504967, 50.247674],
              [140.50465, 50.223153],
              [140.493229, 50.20893],
              [140.501575, 50.168154],
              [140.517062, 50.140902],
              [140.538045, 50.121622],
              [140.568166, 50.111549],
              [140.648559, 50.094794],
              [140.676659, 50.099049],
              [140.686872, 50.089626],
              [140.668562, 50.082864],
              [140.657046, 50.060619],
              [140.634209, 50.047622],
              [140.53296, 50.018802],
              [140.484874, 49.996886],
              [140.47122, 49.982795],
              [140.473176, 49.970496],
              [140.456887, 49.941009],
              [140.434174, 49.920076],
              [140.43381, 49.896611],
              [140.40247, 49.88749],
              [140.398466, 49.873065],
              [140.415392, 49.849764],
              [140.431335, 49.837746],
              [140.452278, 49.805509],
              [140.495531, 49.788119],
              [140.522792, 49.789673],
              [140.52839, 49.783247],
              [140.518325, 49.763371],
              [140.523761, 49.746639],
              [140.498844, 49.71541],
              [140.499399, 49.679187],
              [140.51303, 49.655555],
              [140.512791, 49.6438],
              [140.532373, 49.621357],
              [140.54682, 49.564583],
              [140.52182, 49.534779],
              [140.50506, 49.52749],
              [140.511211, 49.489123],
              [140.487283, 49.478931],
              [140.423093, 49.414201],
              [140.416948, 49.393832],
              [140.430959, 49.384392],
              [140.411821, 49.3211],
              [140.42697, 49.30659],
              [140.423857, 49.295325],
              [140.405751, 49.298438],
              [140.374952, 49.282488],
              [140.3871, 49.266497],
              [140.360423, 49.249267],
              [140.35279, 49.254288],
              [140.334201, 49.241471],
              [140.3247, 49.219641],
              [140.310474, 49.215233],
              [140.31237, 49.19993],
              [140.333954, 49.19665],
              [140.344996, 49.204376],
              [140.34374, 49.18249],
              [140.334533, 49.170158],
              [140.361397, 49.150011],
              [140.351767, 49.133338],
              [140.33441, 49.127922],
              [140.330789, 49.112011],
              [140.351561, 49.103647],
              [140.344761, 49.094043],
              [140.319858, 49.09493],
              [140.294874, 49.080693],
              [140.32517, 49.067934],
              [140.311563, 49.055216],
              [140.333278, 49.054105],
              [140.331745, 49.044352],
              [140.302602, 49.025052],
              [140.287273, 49.026264],
              [140.282636, 49.012342],
              [140.2618, 49.001764],
              [140.258108, 48.990037],
              [140.229773, 48.979117],
              [140.235751, 48.969866],
              [140.272883, 48.973828],
              [140.284252, 48.99553],
              [140.30167, 48.999689],
              [140.332353, 49.023391],
              [140.350021, 49.02539],
              [140.360138, 48.99208],
              [140.378975, 48.988359],
              [140.39057, 48.976819],
              [140.372677, 48.96928],
              [140.364731, 48.953044],
              [140.360874, 48.920799],
              [140.32215, 48.896662],
              [140.304367, 48.879087],
              [140.26196, 48.861091],
              [140.236706, 48.845166],
              [140.230918, 48.830276],
              [140.233549, 48.809352],
              [140.21212, 48.788251],
              [140.190119, 48.748743],
              [140.19934, 48.73897],
              [140.18305, 48.716929],
              [140.17524, 48.674262],
              [140.187194, 48.637138],
              [140.17733, 48.601228],
              [140.193579, 48.595824],
              [140.161244, 48.545554],
              [140.174515, 48.536347],
              [140.182446, 48.519364],
              [140.177839, 48.486906],
              [140.168416, 48.469021],
              [140.174354, 48.45036],
              [140.165189, 48.441711],
              [140.10839, 48.419852],
              [140.082658, 48.402841],
              [140.058562, 48.380084],
              [140.032756, 48.330069],
              [140.004273, 48.327416],
              [139.952204, 48.313421],
              [139.91739, 48.297556],
              [139.907911, 48.282841],
              [139.87929, 48.270184],
              [139.8669, 48.246601],
              [139.844114, 48.238306],
              [139.812581, 48.212518],
              [139.760448, 48.181815],
              [139.721755, 48.162857],
              [139.70889, 48.149877],
              [139.70548, 48.133797],
              [139.6878, 48.124752],
              [139.690654, 48.11607],
              [139.6692, 48.101502],
              [139.64252, 48.060291],
              [139.628543, 48.05826],
              [139.60442, 48.038027],
              [139.53878, 47.990909],
              [139.538947, 47.970673],
              [139.473564, 47.94964],
              [139.443119, 47.936413],
              [139.409333, 47.927385],
              [139.377972, 47.896002],
              [139.322563, 47.853001],
              [139.30511, 47.830241],
              [139.284442, 47.821573],
              [139.263663, 47.802],
              [139.244935, 47.753781],
              [139.216558, 47.728384],
              [139.19717, 47.687922],
              [139.197912, 47.656691],
              [139.167105, 47.640044],
              [139.165736, 47.629368],
              [139.143014, 47.600514],
              [139.11739, 47.577032],
              [139.095186, 47.52675],
              [139.071055, 47.509506],
              [139.051505, 47.469094],
              [139.058405, 47.451841],
              [139.05009, 47.426294],
              [139.052567, 47.407979],
              [139.032543, 47.396181],
              [139.013509, 47.402268],
              [138.995884, 47.390739],
              [138.977729, 47.401937],
              [138.96971, 47.419297],
              [138.93327, 47.445338],
              [138.890797, 47.448982],
              [138.862704, 47.469589],
              [138.801082, 47.465747],
              [138.795781, 47.454085],
              [138.764175, 47.456206],
              [138.725347, 47.440569],
              [138.721902, 47.455278],
              [138.692747, 47.443683],
              [138.642722, 47.453357],
              [138.621717, 47.444744],
              [138.596274, 47.463429],
              [138.605087, 47.482247],
              [138.571228, 47.48238],
              [138.541411, 47.476284],
              [138.53684, 47.481983],
              [138.559169, 47.505173],
              [138.5976, 47.521009],
              [138.58415, 47.535056],
              [138.591969, 47.544597],
              [138.590776, 47.562355],
              [138.617016, 47.583491],
              [138.6304, 47.60423],
              [138.615028, 47.612579],
              [138.600253, 47.630337],
              [138.580375, 47.637294],
              [138.575141, 47.64836],
              [138.547908, 47.647763],
              [138.528428, 47.6689],
              [138.506695, 47.67705],
              [138.532073, 47.692687],
              [138.549764, 47.697325],
              [138.554138, 47.717137],
              [138.57713, 47.712631],
              [138.594888, 47.73019],
              [138.623446, 47.729792],
              [138.637294, 47.738869],
              [138.657901, 47.735159],
              [138.664328, 47.74417],
              [138.737744, 47.769878],
              [138.76266, 47.763384],
              [138.775313, 47.771865],
              [138.762327, 47.776305],
              [138.758219, 47.796249],
              [138.77876, 47.809699],
              [138.79751, 47.809566],
              [138.806986, 47.819174],
              [138.814806, 47.847798],
              [138.831172, 47.858399],
              [138.831636, 47.868139],
              [138.772864, 47.882783],
              [138.748746, 47.891795],
              [138.75471, 47.913859],
              [138.734037, 47.928701],
              [138.71628, 47.930888],
              [138.69627, 47.96256],
              [138.671886, 47.968391],
              [138.662478, 47.982041],
              [138.630342, 48.003509],
              [138.598008, 48.005299],
              [138.577998, 48.029218],
              [138.574884, 48.047638],
              [138.594166, 48.060095],
              [138.597479, 48.074407],
              [138.613183, 48.083352],
              [138.615701, 48.101242],
              [138.636706, 48.12218],
              [138.658703, 48.10694],
              [138.685207, 48.109391],
              [138.701507, 48.117938],
              [138.697399, 48.129733],
              [138.723572, 48.14093],
              [138.726952, 48.154513],
              [138.70946, 48.179295],
              [138.716815, 48.193607],
              [138.703497, 48.210105],
              [138.688854, 48.213816],
              [138.679246, 48.203082],
              [138.647839, 48.192017],
              [138.635548, 48.179],
              [138.601987, 48.180091],
              [138.582705, 48.189831],
              [138.550106, 48.196523],
              [138.537517, 48.211763],
              [138.52233, 48.21394],
              [138.52725, 48.233364],
              [138.547456, 48.245688],
              [138.538843, 48.26815],
              [138.523405, 48.270403],
              [138.48577, 48.290944],
              [138.481198, 48.312345],
              [138.462646, 48.318773],
              [138.468278, 48.343753],
              [138.495843, 48.359522],
              [138.500415, 48.380261],
              [138.469207, 48.389206],
              [138.474111, 48.414451],
              [138.459401, 48.410078],
              [138.408448, 48.43042],
              [138.396588, 48.443937],
              [138.364319, 48.442281],
              [138.358024, 48.433336],
              [138.33748, 48.44142],
              [138.310583, 48.464346],
              [138.286001, 48.458383],
              [138.270297, 48.447782],
              [138.236041, 48.443342],
              [138.211259, 48.424989],
              [138.19317, 48.419026],
              [138.189658, 48.401798],
              [138.1776, 48.402991],
              [138.171966, 48.385896],
              [138.182634, 48.374897],
              [138.18045, 48.358929],
              [138.189326, 48.347267],
              [138.17634, 48.335274],
              [138.14447, 48.333883],
              [138.123198, 48.326661],
              [138.099146, 48.327854],
              [138.07701, 48.309235],
              [138.065419, 48.311024],
              [138.0523, 48.298567],
              [138.012346, 48.309699],
              [137.997966, 48.272528],
              [137.965234, 48.270474],
              [137.946615, 48.259873],
              [137.950325, 48.249934],
              [137.935748, 48.243507],
              [137.928393, 48.228069],
              [137.895396, 48.226545],
              [137.88281, 48.210842],
              [137.860941, 48.219853],
              [137.83457, 48.209318],
              [137.797199, 48.221974],
              [137.789682, 48.232001],
              [137.704501, 48.211307],
              [137.678197, 48.24828],
              [137.647188, 48.252455],
              [137.620551, 48.2343],
              [137.591927, 48.245299],
              [137.56734, 48.238077],
              [137.560188, 48.213694],
              [137.534943, 48.205146],
              [137.491411, 48.204948],
              [137.484586, 48.197991],
              [137.451324, 48.202961],
              [137.409314, 48.16294],
              [137.395399, 48.153929],
              [137.366841, 48.152737],
              [137.351734, 48.118945],
              [137.33709, 48.109205],
              [137.308267, 48.104368],
              [137.321254, 48.097808],
              [137.322777, 48.065076],
              [137.35001, 48.05633],
              [137.372074, 48.041288],
              [137.390096, 48.040427],
              [137.418498, 48.021587],
              [137.42164, 47.993049],
              [137.427795, 47.987256],
              [137.47464, 47.979889],
              [137.494025, 47.983393],
              [137.515768, 47.976169],
              [137.560255, 47.937547],
              [137.605242, 47.924028],
              [137.60055, 47.911268],
              [137.61103, 47.897851],
              [137.594513, 47.883475],
              [137.572269, 47.87618],
              [137.53758, 47.881545],
              [137.5119, 47.874249],
              [137.482436, 47.856941],
              [137.46384, 47.83856],
              [137.444028, 47.833983],
              [137.442454, 47.823612],
              [137.413487, 47.805803],
              [137.453039, 47.784418],
              [137.48623, 47.793286],
              [137.50947, 47.788923],
              [137.527351, 47.798578],
              [137.557534, 47.787635],
              [137.595012, 47.805015],
              [137.632847, 47.784559],
              [137.654232, 47.781269],
              [137.66932, 47.768609],
              [137.719103, 47.759311],
              [137.730618, 47.740357],
              [137.776607, 47.720545],
              [137.776893, 47.707599],
              [137.792699, 47.706169],
              [137.800424, 47.72455],
              [137.8482, 47.729198],
              [137.87853, 47.751656],
              [137.958418, 47.736922],
              [137.992177, 47.722331],
              [137.992176, 47.690146],
              [138.036091, 47.687141],
              [138.05247, 47.696654],
              [138.060695, 47.691433],
              [138.049394, 47.679631],
              [138.054115, 47.671263],
              [137.972363, 47.616192],
              [138.000829, 47.596451],
              [138.010627, 47.601815],
              [138.034873, 47.587153],
              [138.051181, 47.601529],
              [138.070063, 47.586509],
              [138.067702, 47.575065],
              [138.086441, 47.566339],
              [138.055471, 47.553966],
              [138.079073, 47.533153],
              [138.077857, 47.511982],
              [138.03845, 47.508692],
              [138.019351, 47.478081],
              [138.004259, 47.463419],
              [137.964492, 47.455766],
              [137.961774, 47.448543],
              [137.937385, 47.447613],
              [137.922437, 47.455552],
              [137.906058, 47.441534],
              [137.912423, 47.426156],
              [137.905199, 47.418289],
              [137.917787, 47.406988],
              [137.898761, 47.390395],
              [137.885029, 47.390467],
              [137.82638, 47.362287],
              [137.7879, 47.353419],
              [137.746703, 47.330604],
              [137.717522, 47.356281],
              [137.705435, 47.355351],
              [137.687983, 47.339544],
              [137.652507, 47.336827],
              [137.64743, 47.327887],
              [137.628046, 47.324311],
              [137.60294, 47.31065],
              [137.59321, 47.313797],
              [137.552999, 47.263551],
              [137.50195, 47.233263],
              [137.48396, 47.234108],
              [137.469049, 47.225182],
              [137.46519, 47.209447],
              [137.41905, 47.214239],
              [137.392805, 47.201723],
              [137.335587, 47.212309],
              [137.33144, 47.229903],
              [137.30555, 47.242277],
              [137.305834, 47.250145],
              [137.284521, 47.259943],
              [137.281589, 47.279755],
              [137.257056, 47.279755],
              [137.247544, 47.287408],
              [137.223226, 47.278039],
              [137.226516, 47.262376],
              [137.18618, 47.261232],
              [137.152204, 47.289626],
              [137.110006, 47.294919],
              [137.106287, 47.302286],
              [137.081325, 47.304003],
              [137.037267, 47.284049],
              [137.036837, 47.265596],
              [137.015308, 47.248645],
              [137.003006, 47.227618],
              [136.998428, 47.199509],
              [136.981477, 47.195146],
              [136.972608, 47.182344],
              [136.948004, 47.166323],
              [136.952867, 47.125627],
              [136.93777, 47.116972],
              [136.94743, 47.100093],
              [136.926116, 47.080067],
              [136.926188, 47.068695],
              [136.949003, 47.049455],
              [136.974751, 47.04216],
              [136.978112, 47.024922],
              [136.94607, 47.007185],
              [136.939346, 46.992309],
              [136.904515, 46.988733],
              [136.892785, 46.974214],
              [136.894715, 46.956691],
              [136.88556, 46.944317],
              [136.871971, 46.949682],
              [136.822763, 46.95333],
              [136.794725, 46.901476],
              [136.762111, 46.881235],
              [136.73372, 46.885026],
              [136.73815, 46.857132],
              [136.724632, 46.858134],
              [136.70389, 46.843043],
              [136.683363, 46.839324],
              [136.677785, 46.846118],
              [136.634012, 46.825806],
              [136.63823, 46.814434],
              [136.624928, 46.79827],
              [136.60247, 46.79684],
              [136.570142, 46.786613],
              [136.553191, 46.810001],
              [136.521936, 46.809643],
              [136.504198, 46.801633],
              [136.497332, 46.81136],
              [136.466148, 46.809501],
              [136.459711, 46.826237],
              [136.419372, 46.824735],
              [136.383611, 46.81365],
              [136.3894, 46.787401],
              [136.372667, 46.780106],
              [136.360079, 46.763369],
              [136.33941, 46.774742],
              [136.311658, 46.776315],
              [136.258373, 46.787259],
              [136.234199, 46.775458],
              [136.220323, 46.789262],
              [136.199725, 46.787545],
              [136.14701, 46.807],
              [136.127845, 46.82717],
              [136.116545, 46.851344],
              [136.087006, 46.868224],
              [136.054106, 46.867438],
              [136.045379, 46.843406],
              [136.027099, 46.8347],
              [135.986826, 46.796438],
              [135.9689, 46.802306],
              [135.949447, 46.797242],
              [135.89776, 46.821438],
              [135.859336, 46.828673],
              [135.836668, 46.828593],
              [135.81127, 46.84885],
              [135.80435, 46.866052],
              [135.807087, 46.884219],
              [135.789322, 46.898045],
              [135.740047, 46.916695],
              [135.704517, 46.917338],
              [135.70902, 46.931164],
              [135.737394, 46.943543],
              [135.752426, 46.942177],
              [135.752588, 46.964765],
              [135.709903, 46.963238],
              [135.690852, 46.966775],
              [135.650981, 46.952869],
              [135.633457, 46.958496],
              [135.608779, 46.949573],
              [135.590853, 46.960827],
              [135.58378, 46.977145],
              [135.564005, 46.977708],
              [135.550098, 46.96766],
              [135.521, 46.965249],
              [135.47815, 46.945475],
              [135.442302, 46.950218],
              [135.427672, 46.943626],
              [135.427752, 46.926585],
              [135.387801, 46.928595],
              [135.361515, 46.920717],
              [135.344393, 46.929801],
              [135.35203, 46.949656],
              [135.350503, 46.969029],
              [135.360953, 46.97988],
              [135.398252, 46.972967],
              [135.402914, 46.987436],
              [135.391982, 47.000539],
              [135.392063, 47.017098],
              [135.42285, 47.031406],
              [135.430889, 47.04274],
              [135.415134, 47.059782],
              [135.3991, 47.066448],
              [135.370601, 47.063882],
              [135.360312, 47.071599],
              [135.338608, 47.104557],
              [135.317467, 47.109782],
              [135.294236, 47.122724],
              [135.2907, 47.137193],
              [135.303642, 47.151502],
              [135.29866, 47.160907],
              [135.25517, 47.162836],
              [135.239415, 47.182048],
              [135.230572, 47.164605],
              [135.216505, 47.161309],
              [135.195766, 47.16975],
              [135.191908, 47.181406],
              [135.137414, 47.204149],
              [135.10461, 47.205602],
              [135.085318, 47.2134],
              [135.070045, 47.194831],
              [135.045125, 47.180362],
              [135.016749, 47.172002],
              [135.022858, 47.156006],
              [135.009433, 47.133498],
              [134.979932, 47.129479],
              [134.975752, 47.138],
              [134.92173, 47.130525],
              [134.913936, 47.140734],
              [134.895207, 47.139046],
              [134.840304, 47.168467],
              [134.820128, 47.147969],
              [134.807346, 47.149818],
              [134.777604, 47.124979],
              [134.767957, 47.125462],
              [134.759196, 47.145799],
              [134.687251, 47.124417],
              [134.66213, 47.124291],
              [134.643843, 47.100624],
              [134.634036, 47.096283],
              [134.646334, 47.07064],
              [134.626801, 47.061477],
              [134.591271, 47.062844],
              [134.566672, 47.040818],
              [134.565547, 47.021044],
              [134.575916, 47.012764],
              [134.576479, 46.992909],
              [134.550996, 46.973296],
              [134.512733, 46.964373],
              [134.485884, 46.967348],
              [134.464663, 46.983264],
              [134.455419, 46.972171],
              [134.43814, 46.970805],
              [134.432345, 46.959034],
              [134.448827, 46.941303],
              [134.441672, 46.933426],
              [134.452845, 46.920243],
              [134.433874, 46.89677],
              [134.436205, 46.890742],
              [134.41305, 46.878121],
              [134.407427, 46.860999],
              [134.450593, 46.825951],
              [134.45003, 46.807945],
              [134.463052, 46.798058],
              [134.462329, 46.785116],
              [134.4731, 46.771852],
              [134.450351, 46.749586],
              [134.45204, 46.72105],
              [134.422778, 46.711886],
              [134.432745, 46.697497],
              [134.38773, 46.675392],
              [134.3612, 46.67491],
              [134.333711, 46.68536],
              [134.317554, 46.674669],
              [134.253648, 46.675955],
              [134.248664, 46.66639],
              [134.208552, 46.652323],
              [134.178086, 46.654333],
              [134.140707, 46.650474],
              [134.111206, 46.659719],
              [134.0682, 46.657629],
              [134.02085, 46.634961],
              [134.005313, 46.655228],
              [134.022808, 46.696552],
              [134.015255, 46.724331],
              [134.025554, 46.754918],
              [134.042034, 46.768089],
              [134.04444, 46.780786],
              [134.015255, 46.811106],
              [134.017315, 46.832484],
              [134.033794, 46.845166],
              [134.034481, 46.885776],
              [134.067097, 46.933153],
              [134.06641, 46.955889],
              [134.05474, 46.975336],
              [134.095592, 47.009058],
              [134.096279, 47.019124],
              [134.113102, 47.042759],
              [134.112415, 47.06194],
              [134.13885, 47.092803],
              [134.192066, 47.097711],
              [134.21678, 47.107993],
              [134.223308, 47.134158],
              [134.218802, 47.187199],
              [134.20342, 47.209144],
              [134.159528, 47.234782],
              [134.147018, 47.253035],
              [134.168348, 47.318667],
              [134.19337, 47.342458],
              [134.2348, 47.352303],
              [134.253464, 47.369736],
              [134.254899, 47.38717],
              [134.30474, 47.431061],
              [134.337145, 47.438641],
              [134.398866, 47.437768],
              [134.446175, 47.446939],
              [134.48038, 47.443445],
              [134.49494, 47.446502],
              [134.518085, 47.466154],
              [134.558262, 47.47547],
              [134.571945, 47.5024],
              [134.569179, 47.518267],
              [134.61969, 47.545488],
              [134.653026, 47.579405],
              [134.670057, 47.585955],
              [134.67424, 47.603939],
              [134.67218, 47.635413],
              [134.712353, 47.659699],
              [134.722653, 47.675884],
              [134.738446, 47.685593],
              [134.76522, 47.717017],
              [134.76934, 47.734801],
              [134.761792, 47.760426],
              [134.718533, 47.784193],
              [134.692327, 47.807607],
              [134.669095, 47.815097],
              [134.652615, 47.868098],
              [134.657078, 47.882837],
              [134.639569, 47.902405],
              [134.602954, 47.901651],
              [134.573771, 47.920752],
              [134.586646, 47.946974],
              [134.558201, 47.974858],
              [134.575024, 48.002892],
              [134.576423, 48.023339],
              [134.567668, 48.04446],
              [134.57938, 48.064906],
              [134.619719, 48.093349],
              [134.634825, 48.122463],
              [134.662291, 48.140909],
              [134.669329, 48.159348],
              [134.661948, 48.196664],
              [134.662915, 48.23965],
              [134.668408, 48.25154],
              [134.718028, 48.281236],
              [134.76806, 48.35759],
              [134.765455, 48.375203],
              [134.797868, 48.3772],
              [134.844333, 48.392645],
              [134.896898, 48.443262],
              [134.901014, 48.461912],
              [134.936202, 48.513926],
              [134.953331, 48.51935],
              [134.966287, 48.513675],
              [134.995785, 48.532681],
              [134.979706, 48.563532],
              [134.976974, 48.602619],
              [134.96632, 48.604424],
              [134.950617, 48.626557],
              [134.924001, 48.617156],
              [134.906315, 48.627179],
              [134.865839, 48.635209],
              [134.85976, 48.614619],
              [134.801276, 48.591748],
              [134.778207, 48.590172],
              [134.772053, 48.575596],
              [134.73236, 48.570618],
              [134.68265, 48.59929],
              [134.67592, 48.620047],
              [134.658138, 48.624854],
              [134.651434, 48.610716],
              [134.61214, 48.612643],
              [134.55478, 48.599774],
              [134.557148, 48.613116],
              [134.579396, 48.621861],
              [134.568489, 48.633703],
              [134.543621, 48.63711],
              [134.53961, 48.648855],
              [134.514478, 48.661437],
              [134.50332, 48.648804],
              [134.49272, 48.65461],
              [134.503197, 48.666612],
              [134.492386, 48.674632],
              [134.465815, 48.67584],
              [134.463124, 48.694962],
              [134.448697, 48.707913],
              [134.458388, 48.717634],
              [134.447548, 48.728866],
              [134.419568, 48.715602],
              [134.408964, 48.721579],
              [134.416051, 48.741429],
              [134.399664, 48.746428],
              [134.371803, 48.736779],
              [134.36483, 48.728769],
              [134.347005, 48.730434],
              [134.309419, 48.722234],
              [134.302859, 48.736826],
              [134.289842, 48.740582],
              [134.282982, 48.723274],
              [134.25613, 48.718611],
              [134.26195, 48.699412],
              [134.25495, 48.691814],
              [134.273305, 48.676445],
              [134.265952, 48.666304],
              [134.24324, 48.683001],
              [134.21574, 48.674429],
              [134.22614, 48.659429],
              [134.196799, 48.66338],
              [134.171668, 48.656715],
              [134.15435, 48.662256],
              [134.128004, 48.655934],
              [134.1393, 48.647749],
              [134.123085, 48.644663],
              [134.100942, 48.650471],
              [134.090931, 48.637638],
              [134.072179, 48.648589],
              [134.046514, 48.63543],
              [134.020498, 48.649311],
              [134.003048, 48.640963],
              [133.94099, 48.654206],
              [133.934109, 48.639607],
              [133.902577, 48.652437],
              [133.902691, 48.66129],
              [133.873747, 48.670191],
              [133.87184, 48.659732],
              [133.853577, 48.665552],
              [133.85713, 48.679158],
              [133.833011, 48.680125],
              [133.820529, 48.698068],
              [133.759577, 48.748359],
              [133.755086, 48.734887],
              [133.738935, 48.734169],
              [133.726693, 48.757533],
              [133.70826, 48.753292],
              [133.717763, 48.73494],
              [133.703452, 48.719495],
              [133.683841, 48.723314],
              [133.703995, 48.740502],
              [133.680013, 48.740046],
              [133.658952, 48.746097],
              [133.644678, 48.739797],
              [133.625282, 48.743646],
              [133.621995, 48.733932],
              [133.605928, 48.736556],
              [133.589277, 48.729502],
              [133.577718, 48.7455],
              [133.565438, 48.742921],
              [133.556654, 48.759103],
              [133.522162, 48.765858],
              [133.528844, 48.784348],
              [133.50623, 48.783504],
              [133.482393, 48.820146],
              [133.463447, 48.829097],
              [133.404043, 48.833351],
              [133.281351, 48.848458],
              [133.27972, 48.878996],
              [133.253603, 48.911513],
              [133.224739, 48.958946],
              [133.250817, 48.978582],
              [133.275213, 48.986787],
              [133.272472, 49.034188],
              [133.26, 49.056674],
              [133.218857, 49.088427],
              [133.156535, 49.120216],
              [133.164188, 49.143333],
              [133.152341, 49.155724],
              [133.101844, 49.173901],
              [133.079814, 49.172064],
              [133.05855, 49.198409],
              [133.053719, 49.214828],
              [133.003141, 49.221952],
              [132.983739, 49.229577],
              [132.956274, 49.223678],
              [132.916965, 49.233353],
              [132.91115, 49.254084],
              [132.898506, 49.256298],
              [132.869524, 49.24037],
              [132.833625, 49.242819],
              [132.809333, 49.23666],
              [132.802498, 49.271883],
              [132.780452, 49.286892],
              [132.783534, 49.329466],
              [132.771334, 49.33546],
              [132.75379, 49.32633],
              [132.732994, 49.330695],
              [132.705897, 49.319129],
              [132.713422, 49.300556],
              [132.694596, 49.306814],
              [132.672947, 49.300899],
              [132.674014, 49.291997],
              [132.65773, 49.282217],
              [132.632373, 49.286292],
              [132.59698, 49.310076],
              [132.529707, 49.293406],
              [132.529497, 49.281569],
              [132.508815, 49.27601],
              [132.50725, 49.266773],
              [132.48581, 49.264958],
              [132.46673, 49.283328],
              [132.44621, 49.282574],
              [132.3995, 49.301873],
              [132.385134, 49.29676],
              [132.371428, 49.307934],
              [132.366774, 49.334435],
              [132.375081, 49.342703],
              [132.400927, 49.347657],
              [132.404875, 49.379738],
              [132.384763, 49.41336],
              [132.353117, 49.410772],
              [132.304147, 49.421765],
              [132.270948, 49.442468],
              [132.273653, 49.463876],
              [132.25957, 49.481473],
              [132.241219, 49.489845],
              [132.222839, 49.474291],
              [132.19089, 49.474523],
              [132.167162, 49.488923],
              [132.111332, 49.494486],
              [132.082172, 49.478248],
              [132.06406, 49.457305],
              [132.074193, 49.441266],
              [132.062335, 49.428191],
              [132.062771, 49.411754],
              [132.093754, 49.389564],
              [132.023787, 49.374039],
              [132.009733, 49.381191],
              [131.993382, 49.377629],
              [131.99841, 49.358336],
              [132.022254, 49.33867],
              [132.022414, 49.329382],
              [131.999851, 49.326977],
              [131.974828, 49.309116],
              [131.979242, 49.290257],
              [132.001485, 49.284979],
              [132.00432, 49.268777],
              [131.986279, 49.256153],
              [131.93838, 49.276366],
              [131.918911, 49.271623],
              [131.902858, 49.278574],
              [131.88416, 49.272973],
              [131.872833, 49.289383],
              [131.852134, 49.30275],
              [131.833711, 49.322664],
              [131.803901, 49.337971],
              [131.776878, 49.315773],
              [131.742878, 49.308373],
              [131.735133, 49.313139],
              [131.701932, 49.303566],
              [131.665912, 49.312318],
              [131.638842, 49.292885],
              [131.618328, 49.288651],
              [131.6177, 49.276764],
              [131.6011, 49.265535],
              [131.569326, 49.266544],
              [131.573254, 49.256399],
              [131.556983, 49.247656],
              [131.514038, 49.248659],
              [131.508001, 49.231771],
              [131.491973, 49.223846],
              [131.490045, 49.210796],
              [131.47328, 49.209787],
              [131.459003, 49.242346],
              [131.419462, 49.255472],
              [131.443382, 49.295138],
              [131.436865, 49.301345],
              [131.454765, 49.313641],
              [131.458464, 49.333081],
              [131.470993, 49.345072],
              [131.490818, 49.380529],
              [131.488001, 49.387909],
              [131.527884, 49.427865],
              [131.504, 49.458674],
              [131.504095, 49.474858],
              [131.518879, 49.512025],
              [131.508782, 49.521885],
              [131.505567, 49.548375],
              [131.524086, 49.57231],
              [131.512089, 49.597028],
              [131.51035, 49.614858],
              [131.493883, 49.62777],
              [131.45865, 49.639294],
              [131.462144, 49.648681],
              [131.44605, 49.656699],
              [131.44572, 49.670272],
              [131.37786, 49.660619],
              [131.363408, 49.670134],
              [131.352414, 49.698801],
              [131.369935, 49.719508],
              [131.416832, 49.721335],
              [131.446738, 49.735642],
              [131.465893, 49.734997],
              [131.470943, 49.724179],
              [131.491368, 49.72511],
              [131.520415, 49.757803],
              [131.503085, 49.774424],
              [131.478651, 49.779948],
              [131.48612, 49.789427],
              [131.465802, 49.803785],
              [131.485209, 49.807536],
              [131.503541, 49.833144],
              [131.50248, 49.869642],
              [131.520539, 49.903465],
              [131.491197, 49.922248],
              [131.479136, 49.938632],
              [131.44806, 49.938578],
              [131.387403, 49.975901],
              [131.424054, 49.991028],
              [131.427719, 50.019816],
              [131.421724, 50.036483],
              [131.42796, 50.063009],
              [131.39736, 50.062931],
              [131.402469, 50.081925],
              [131.382361, 50.088528],
              [131.375446, 50.100153],
              [131.395645, 50.132344],
              [131.38882, 50.158784],
              [131.418819, 50.175179],
              [131.458305, 50.177325],
              [131.443446, 50.200249],
              [131.41283, 50.21624],
              [131.427671, 50.240087],
              [131.421513, 50.257708],
              [131.393811, 50.27161],
              [131.384124, 50.265384],
              [131.36101, 50.276625],
              [131.344135, 50.275182],
              [131.323935, 50.289332],
              [131.331605, 50.303755],
              [131.310048, 50.322809],
              [131.321137, 50.363463],
              [131.30484, 50.37646],
              [131.286303, 50.362487],
              [131.24035, 50.348448],
              [131.195364, 50.359004],
              [131.171355, 50.356659],
              [131.169975, 50.371261],
              [131.141332, 50.396245],
              [131.13055, 50.431843],
              [131.106012, 50.422533],
              [131.072722, 50.419383],
              [131.047069, 50.433462],
              [131.023652, 50.434764],
              [131.022008, 50.415873],
              [131.005998, 50.398362],
              [130.963698, 50.375777],
              [130.949018, 50.376262],
              [130.942123, 50.362337],
              [130.926832, 50.405011],
              [130.931014, 50.418565],
              [130.913263, 50.440217],
              [130.89911, 50.438069],
              [130.875941, 50.450522],
              [130.883912, 50.470562],
              [130.87875, 50.479153],
              [130.893439, 50.489683],
              [130.933201, 50.495098],
              [130.93973, 50.50659],
              [130.971882, 50.528062],
              [130.9843, 50.55266],
              [131.026452, 50.577456],
              [131.02039, 50.59303],
              [131.000921, 50.596698],
              [130.999334, 50.616596],
              [130.984524, 50.637969],
              [130.956589, 50.652363],
              [130.907408, 50.669539],
              [130.869414, 50.665157],
              [130.852487, 50.653648],
              [130.816808, 50.650581],
              [130.791448, 50.660374],
              [130.763969, 50.656391],
              [130.742225, 50.627686],
              [130.743995, 50.609813],
              [130.723473, 50.605954],
              [130.644752, 50.662002],
              [130.646734, 50.679891],
              [130.6682, 50.691126],
              [130.688779, 50.687855],
              [130.704787, 50.698033],
              [130.703071, 50.728219],
              [130.682264, 50.728224],
              [130.672734, 50.742222],
              [130.684318, 50.754132],
              [130.716806, 50.773171],
              [130.717272, 50.791011],
              [130.74043, 50.795426],
              [130.740586, 50.807203],
              [130.775663, 50.828507],
              [130.787387, 50.864914],
              [130.808334, 50.874228],
              [130.831922, 50.86916],
              [130.845384, 50.879121],
              [130.86778, 50.879538],
              [130.882629, 50.893339],
              [130.842658, 50.891062],
              [130.831865, 50.92282],
              [130.80632, 50.934105],
              [130.80491, 50.946084],
              [130.756983, 50.956274],
              [130.767952, 50.972289],
              [130.765432, 50.992791],
              [130.78464, 51.004151],
              [130.795499, 51.024091],
              [130.806835, 51.026951],
              [130.836887, 51.018808],
              [130.883413, 51.031154],
              [130.927046, 51.027766],
              [130.948992, 51.04269],
              [130.945529, 51.050124],
              [130.959951, 51.069812],
              [130.95761, 51.092635],
              [130.96767, 51.098296],
              [130.958451, 51.11255],
              [130.97155, 51.142668],
              [131.001368, 51.149954],
              [131.000293, 51.171032],
              [131.026889, 51.17331],
              [131.036567, 51.20824],
              [131.045429, 51.220161],
              [131.042859, 51.239123],
              [131.080034, 51.260716],
              [131.110102, 51.267198],
              [131.161662, 51.250492],
              [131.176885, 51.257705],
              [131.199184, 51.285902],
              [131.224404, 51.287567],
              [131.220332, 51.319173],
              [131.228657, 51.332281],
              [131.243608, 51.336116],
              [131.255845, 51.353251],
              [131.253109, 51.363298],
              [131.321644, 51.384386],
              [131.36059, 51.389378],
              [131.38112, 51.363501],
              [131.394161, 51.365734],
              [131.408862, 51.345265],
              [131.434723, 51.338543],
              [131.490443, 51.346711],
              [131.495169, 51.391704],
              [131.47947, 51.410896],
              [131.46825, 51.442722],
              [131.467645, 51.486221],
              [131.447753, 51.502641],
              [131.433077, 51.506278],
              [131.423209, 51.526887],
              [131.430423, 51.531616],
              [131.409658, 51.570057],
              [131.39014, 51.594668],
              [131.386529, 51.625973],
              [131.398703, 51.641131],
              [131.391433, 51.650143],
              [131.41532, 51.649714],
              [131.421571, 51.661185],
              [131.440254, 51.668888],
              [131.433176, 51.677839],
              [131.400911, 51.691948],
              [131.46287, 51.688939],
              [131.500998, 51.679689],
              [131.54037, 51.680509],
              [131.544393, 51.6737],
              [131.604323, 51.66596],
              [131.6221, 51.683887],
              [131.680291, 51.682335],
              [131.698378, 51.693581],
              [131.73953, 51.68876],
              [131.751697, 51.696745],
              [131.779877, 51.700424],
              [131.809449, 51.729585],
              [131.836909, 51.736658],
              [131.85849, 51.758266],
              [131.92357, 51.743218],
              [131.95629, 51.745314],
              [131.97801, 51.754939],
              [131.977779, 51.76601],
              [132.001154, 51.779271],
              [132.03374, 51.786049],
              [132.065705, 51.800509],
              [132.08751, 51.78679],
              [132.09759, 51.770163],
              [132.117926, 51.778549],
              [132.116661, 51.789411],
              [132.130038, 51.810771],
              [132.163514, 51.811837],
              [132.218875, 51.839784],
              [132.245708, 51.83494],
              [132.264218, 51.800098],
              [132.29565, 51.807431],
              [132.316459, 51.830644],
              [132.342698, 51.847809],
              [132.385661, 51.846817],
              [132.419441, 51.861546],
              [132.4506, 51.885684],
              [132.442925, 51.895965],
              [132.419028, 51.905468],
              [132.42751, 51.914503],
              [132.417808, 51.947628],
              [132.38938, 51.971535],
              [132.412036, 51.985695],
              [132.443897, 51.983993],
              [132.482025, 51.976147],
              [132.503228, 51.954039],
              [132.525827, 51.962229],
              [132.53395, 51.984595],
              [132.525685, 51.98984],
              [132.528301, 52.01193],
              [132.521422, 52.01642],
              [132.535806, 52.033719],
              [132.540104, 52.050249],
              [132.56446, 52.058023],
              [132.572847, 52.080795],
              [132.62138, 52.079073],
              [132.639298, 52.084471],
              [132.621792, 52.094888],
              [132.645882, 52.114255],
              [132.682486, 52.113949],
              [132.702485, 52.12784],
              [132.736646, 52.116705],
              [132.759327, 52.122053],
              [132.772167, 52.134435],
              [132.818207, 52.162822],
              [132.808303, 52.177125],
              [132.81813, 52.183412],
              [132.854423, 52.18104],
              [132.863415, 52.167536],
              [132.90419, 52.15911],
              [132.913665, 52.152429],
              [132.950453, 52.149475],
              [132.95915, 52.165725],
              [132.992416, 52.169779],
              [133.01188, 52.15918],
              [133.05783, 52.175041],
              [133.0927, 52.153126],
              [133.14464, 52.17904],
              [133.147698, 52.168221],
              [133.17135, 52.162446],
              [133.207603, 52.189721],
              [133.232201, 52.176521],
              [133.286498, 52.184496],
              [133.32591, 52.193529],
              [133.326695, 52.208556],
              [133.34746, 52.21285],
              [133.377463, 52.233223],
              [133.391638, 52.225614],
              [133.42045, 52.233701],
              [133.431421, 52.258165],
              [133.402246, 52.282767],
              [133.400865, 52.300137],
              [133.367865, 52.307077],
              [133.337664, 52.32188],
              [133.326259, 52.34619],
              [133.306984, 52.361111],
              [133.313884, 52.400007],
              [133.290196, 52.423524],
              [133.26228, 52.424155],
              [133.260191, 52.433429],
              [133.233804, 52.447453],
              [133.23898, 52.467639],
              [133.287217, 52.490071],
              [133.277035, 52.500626],
              [133.29596, 52.509441],
              [133.300754, 52.529183],
              [133.284918, 52.547014],
              [133.254747, 52.550917],
              [133.245318, 52.557869],
              [133.251439, 52.583722],
              [133.229284, 52.616671],
              [133.23657, 52.639062],
              [133.26003, 52.681092],
              [133.278986, 52.684361],
              [133.28809, 52.676429],
              [133.31719, 52.681072],
              [133.353601, 52.67244],
              [133.363152, 52.66289],
              [133.379752, 52.667598],
              [133.411119, 52.64758],
              [133.425492, 52.652516],
              [133.457519, 52.640519],
              [133.471832, 52.64606],
              [133.501103, 52.641776],
              [133.512813, 52.619666],
              [133.588637, 52.614426],
              [133.645455, 52.581317],
              [133.671725, 52.584865],
              [133.708484, 52.558646],
              [133.736235, 52.56694],
              [133.765215, 52.568453],
              [133.786517, 52.555093],
              [133.819143, 52.566742],
              [133.85485, 52.543435],
              [133.869705, 52.514491],
              [133.901827, 52.519947],
              [133.941008, 52.5126],
              [133.952294, 52.529627],
              [133.99851, 52.540358],
              [134.009128, 52.529018],
              [134.040591, 52.515714],
              [134.053796, 52.498184],
              [134.080272, 52.501095],
              [134.09752, 52.4816],
              [134.125336, 52.494384],
              [134.144583, 52.495511],
              [134.167189, 52.513573],
              [134.228935, 52.495376],
              [134.26529, 52.47072],
              [134.284073, 52.472083],
              [134.30573, 52.457656],
              [134.336086, 52.458847],
              [134.34411, 52.471997],
              [134.385555, 52.472851],
              [134.399058, 52.466102],
              [134.43832, 52.46668],
              [134.485739, 52.460792],
              [134.500889, 52.448655],
              [134.504718, 52.43252],
              [134.522152, 52.424738],
              [134.603193, 52.427106],
              [134.6024, 52.436194],
              [134.623599, 52.438936],
              [134.622947, 52.449089],
              [134.643588, 52.45882],
              [134.633543, 52.487702],
              [134.635746, 52.49879],
              [134.59916, 52.501069],
              [134.613257, 52.530467],
              [134.597839, 52.53894],
              [134.621069, 52.551054],
              [134.62184, 52.573821],
              [134.656032, 52.58356],
              [134.654692, 52.593627],
              [134.67279, 52.627651],
              [134.697576, 52.629375],
              [134.701996, 52.621243],
              [134.741746, 52.625926],
              [134.751015, 52.643576],
              [134.742256, 52.653649],
              [134.752147, 52.679678],
              [134.74872, 52.692349],
              [134.733308, 52.6981],
              [134.74054, 52.711325],
              [134.710397, 52.71999],
              [134.687954, 52.711069],
              [134.653166, 52.716489],
              [134.638526, 52.747317],
              [134.663443, 52.761607],
              [134.664882, 52.783211],
              [134.637874, 52.788491],
              [134.637034, 52.802464],
              [134.611919, 52.801217],
              [134.601656, 52.807717],
              [134.60967, 52.827164],
              [134.593882, 52.857205],
              [134.631408, 52.868942],
              [134.640802, 52.890465],
              [134.605953, 52.894264],
              [134.5946, 52.900874],
              [134.611179, 52.917118],
              [134.659969, 52.946711],
              [134.661177, 52.959693],
              [134.694515, 52.97697],
              [134.7112, 53.007921],
              [134.73499, 53.011596],
              [134.731573, 53.031946],
              [134.748694, 53.049219],
              [134.762687, 53.053363],
              [134.77177, 53.070249],
              [134.833555, 53.070203],
              [134.833215, 53.080819],
              [134.86279, 53.099726],
              [134.809652, 53.148667],
              [134.813961, 53.157805],
              [134.847603, 53.173481],
              [134.8249, 53.193831],
              [134.850161, 53.208905],
              [134.8741, 53.200518],
              [134.907069, 53.230265],
              [134.908935, 53.252384],
              [134.920913, 53.26072],
              [134.86819, 53.273816],
              [134.85899, 53.292194],
              [134.878661, 53.308016],
              [134.847573, 53.324912],
              [134.799868, 53.330286],
              [134.783393, 53.338948],
              [134.79414, 53.359445],
              [134.78237, 53.382705],
              [134.794439, 53.398195],
              [134.838494, 53.396875],
              [134.862144, 53.401488],
              [134.85908, 53.391516],
              [134.87697, 53.384798],
              [134.915095, 53.401786],
              [134.911818, 53.412608],
              [134.889364, 53.423904],
              [134.890271, 53.435472],
              [134.850818, 53.441526],
              [134.836357, 53.453133],
              [134.801164, 53.456937],
              [134.822358, 53.479781],
              [134.80415, 53.490435],
              [134.74069, 53.48708],
              [134.729792, 53.491356],
              [134.72309, 53.528952],
              [134.704406, 53.541578],
              [134.66539, 53.544332],
              [134.648819, 53.559468],
              [134.625657, 53.562766],
              [134.623293, 53.590088],
              [134.595256, 53.596765],
              [134.570762, 53.609105],
              [134.565446, 53.619653],
              [134.531237, 53.621482],
              [134.514668, 53.611175],
              [134.456307, 53.608036],
              [134.445909, 53.587972],
              [134.455941, 53.579655],
              [134.445506, 53.55846],
              [134.430421, 53.557005],
              [134.408431, 53.581282],
              [134.360207, 53.55917],
              [134.319684, 53.55259],
              [134.324308, 53.544049],
              [134.302176, 53.534141],
              [134.28513, 53.548054],
              [134.259308, 53.542171],
              [134.233432, 53.519617],
              [134.205977, 53.521355],
              [134.214065, 53.505107],
              [134.195285, 53.482235],
              [134.160244, 53.491972],
              [134.150833, 53.485176],
              [134.137971, 53.496803],
              [134.110475, 53.498104],
              [134.112319, 53.48856],
              [134.096616, 53.455898],
              [134.06947, 53.46158],
              [134.047457, 53.4568],
              [134.04631, 53.447146],
              [134.018992, 53.447169],
              [134.000946, 53.454522],
              [133.989876, 53.447725],
              [133.94648, 53.438939],
              [133.906421, 53.458927],
              [133.892631, 53.459554],
              [133.868107, 53.474426],
              [133.842905, 53.475631],
              [133.817318, 53.462919],
              [133.796591, 53.471016],
              [133.794385, 53.491222],
              [133.77668, 53.491754],
              [133.761248, 53.479634],
              [133.706805, 53.467333],
              [133.69985, 53.481118],
              [133.683571, 53.484555],
              [133.668403, 53.509015],
              [133.644344, 53.51906],
              [133.628511, 53.535923],
              [133.59901, 53.551287],
              [133.557104, 53.547241],
              [133.536722, 53.534211],
              [133.531686, 53.522619],
              [133.51444, 53.521964],
              [133.491894, 53.507104],
              [133.467075, 53.500835],
              [133.445296, 53.513967],
              [133.42068, 53.518214],
              [133.39362, 53.48934],
              [133.373872, 53.487448],
              [133.36111, 53.472979],
              [133.296106, 53.465341],
              [133.27748, 53.455657],
              [133.26115, 53.457848],
              [133.246626, 53.440217],
              [133.198365, 53.43187],
              [133.193738, 53.424357],
              [133.159513, 53.405444],
              [133.153467, 53.377153],
              [133.162718, 53.360824],
              [133.143899, 53.357552],
              [133.121995, 53.338795],
              [133.144167, 53.332671],
              [133.139159, 53.307675],
              [133.100051, 53.299746],
              [133.08556, 53.308158],
              [133.058669, 53.301164],
              [133.027988, 53.306549],
              [133.022199, 53.293595],
              [133.002908, 53.296506],
              [132.967227, 53.283613],
              [132.953823, 53.297565],
              [132.896266, 53.300235],
              [132.87905, 53.285625],
              [132.900522, 53.268502],
              [132.898012, 53.252937],
              [132.871653, 53.247788],
              [132.861201, 53.235276],
              [132.808388, 53.237989],
              [132.793237, 53.25055],
              [132.770547, 53.249724],
              [132.75054, 53.241075],
              [132.74952, 53.231647],
              [132.711906, 53.23009],
              [132.66594, 53.236333],
              [132.662759, 53.245534],
              [132.60807, 53.238042],
              [132.602166, 53.227928],
              [132.56206, 53.229671],
              [132.506664, 53.215431],
              [132.491155, 53.227295],
              [132.475178, 53.218542],
              [132.43982, 53.220104],
              [132.412575, 53.226481],
              [132.399571, 53.217513],
              [132.392853, 53.232815],
              [132.36829, 53.229348],
              [132.344917, 53.247437],
              [132.33194, 53.238699],
              [132.300443, 53.23916],
              [132.29973, 53.250482],
              [132.279676, 53.255438],
              [132.248983, 53.22612],
              [132.16666, 53.2281],
              [132.141151, 53.220924],
              [132.14055, 53.22897],
              [132.105603, 53.240534],
              [132.095639, 53.230265],
              [132.062511, 53.220296],
              [132.0611, 53.200524],
              [132.041637, 53.195669],
              [132.048158, 53.177301],
              [132.038072, 53.15787],
              [132.013113, 53.139232],
              [131.978849, 53.139492],
              [131.962098, 53.160551],
              [131.896795, 53.135808],
              [131.87864, 53.157186],
              [131.828641, 53.162787],
              [131.82651, 53.17402],
              [131.842509, 53.194397],
              [131.834435, 53.20262],
              [131.854688, 53.231578],
              [131.838361, 53.24101],
              [131.817631, 53.237873],
              [131.80643, 53.257528],
              [131.777038, 53.252507],
              [131.774508, 53.24406],
              [131.722268, 53.23337],
              [131.690212, 53.239095],
              [131.58572, 53.221008],
              [131.525645, 53.220692],
              [131.503296, 53.207901],
              [131.49127, 53.219156],
              [131.441549, 53.221315],
              [131.446764, 53.23804],
              [131.487196, 53.266821],
              [131.506918, 53.268192],
              [131.513563, 53.296897],
              [131.533441, 53.33366],
              [131.511095, 53.354116],
              [131.52249, 53.375287],
              [131.504116, 53.382573],
              [131.495549, 53.399345],
              [131.507735, 53.410271],
              [131.504989, 53.448214],
              [131.491511, 53.447722],
              [131.477329, 53.474483],
              [131.485045, 53.481995],
              [131.45127, 53.51011],
              [131.476201, 53.528686],
              [131.466018, 53.538948],
              [131.480471, 53.567552],
              [131.455222, 53.576637],
              [131.467661, 53.59119],
              [131.49141, 53.602354],
              [131.468975, 53.615427],
              [131.472705, 53.627898],
              [131.44147, 53.639661],
              [131.418264, 53.68338],
              [131.442634, 53.704592],
              [131.435092, 53.712833],
              [131.444139, 53.739173],
              [131.414561, 53.7569],
              [131.387734, 53.758092],
              [131.374364, 53.770133],
              [131.345268, 53.783016],
              [131.316504, 53.780364],
              [131.312503, 53.793405],
              [131.289005, 53.795343],
              [131.25227, 53.786405],
              [131.231088, 53.79037],
              [131.203124, 53.808668],
              [131.162727, 53.800721],
              [131.107558, 53.822338],
              [131.084135, 53.810082],
              [131.044671, 53.816708],
              [131.031688, 53.824599],
              [130.99277, 53.818009],
              [130.985051, 53.80785],
              [130.96423, 53.807301],
              [130.93774, 53.797147],
              [130.931853, 53.787074],
              [130.906202, 53.774997],
              [130.89644, 53.783358],
              [130.848504, 53.798378],
              [130.84646, 53.82719],
              [130.851848, 53.849167],
              [130.817254, 53.856118],
              [130.766391, 53.844867],
              [130.719581, 53.858272],
              [130.711175, 53.888895],
              [130.673976, 53.902561],
              [130.65933, 53.896283],
              [130.627878, 53.895227],
              [130.618758, 53.88328],
              [130.587779, 53.878462],
              [130.550986, 53.893035],
              [130.548238, 53.905854],
              [130.517043, 53.909166],
              [130.474359, 53.889541],
              [130.441599, 53.888155],
              [130.427177, 53.905036],
              [130.391022, 53.919968],
              [130.386742, 53.927568],
              [130.405986, 53.93662],
              [130.405295, 53.949055],
              [130.430772, 53.965997],
              [130.455883, 53.966052],
              [130.496274, 53.987317],
              [130.50262, 54.003311],
              [130.503197, 54.08369],
              [130.511178, 54.122416],
              [130.527269, 54.13886],
              [130.553757, 54.151245],
              [130.570601, 54.168091],
              [130.59956, 54.180671],
              [130.634532, 54.18585],
              [130.628232, 54.198362],
              [130.659953, 54.215587],
              [130.672055, 54.236408],
              [130.668607, 54.256612],
              [130.723532, 54.268444],
              [130.716215, 54.285494],
              [130.735983, 54.298827],
              [130.777795, 54.299585],
              [130.785615, 54.290015],
              [130.814952, 54.284583],
              [130.84387, 54.294906],
              [130.843275, 54.306688],
              [130.871, 54.321661],
              [130.89305, 54.323398],
              [130.939163, 54.339254],
              [130.972921, 54.325982],
              [130.973721, 54.304445],
              [131.037037, 54.291559],
              [131.052681, 54.299559],
              [131.083896, 54.329824],
              [131.11795, 54.333683],
              [131.106822, 54.354418],
              [131.13416, 54.367465],
              [131.136027, 54.390733],
              [131.156753, 54.428685],
              [131.17511, 54.439123],
              [131.165617, 54.453097],
              [131.16702, 54.470686],
              [131.188497, 54.49117],
              [131.196313, 54.511348],
              [131.21143, 54.5066],
              [131.208436, 54.521452],
              [131.21793, 54.544248],
              [131.231634, 54.558683],
              [131.20158, 54.570333],
              [131.170883, 54.606114],
              [131.177642, 54.61157],
              [131.169472, 54.635017],
              [131.209921, 54.643727],
              [131.240757, 54.655639],
              [131.253537, 54.650819],
              [131.28665, 54.658811],
              [131.321799, 54.699002],
              [131.349703, 54.70509],
              [131.36025, 54.724156],
              [131.39489, 54.73728],
              [131.41561, 54.731071],
              [131.449395, 54.734368],
              [131.467086, 54.745992],
              [131.494919, 54.748459],
              [131.539983, 54.741169],
              [131.552355, 54.743406],
              [131.559365, 54.77305],
              [131.618409, 54.793791],
              [131.629764, 54.789561],
              [131.634512, 54.772392],
              [131.671538, 54.775446],
              [131.675642, 54.800213],
              [131.701373, 54.798631],
              [131.736533, 54.813245],
              [131.757913, 54.827805],
              [131.754974, 54.840598],
              [131.771662, 54.843548],
              [131.810106, 54.861455],
              [131.809849, 54.884415],
              [131.875817, 54.876507],
              [131.886745, 54.892304],
              [131.929887, 54.893947],
              [131.974684, 54.917954],
              [132.01127, 54.933345],
              [132.011689, 54.959621],
              [132.028325, 54.970566],
              [132.061187, 54.970397],
              [132.061569, 54.987634],
              [132.088864, 55.000978],
              [132.073837, 55.004689],
              [132.039048, 54.998904],
              [131.979317, 55.013703],
              [131.972319, 55.019822],
              [132.01722, 55.026447],
              [132.044232, 55.041354],
              [132.06845, 55.046574],
              [132.086281, 55.061585],
              [132.127398, 55.066661],
              [132.171233, 55.065347],
              [132.193126, 55.060493],
              [132.294485, 55.062012],
              [132.360338, 55.077458],
              [132.415874, 55.081978],
              [132.435135, 55.091369],
              [132.415469, 55.102747],
              [132.437862, 55.111038],
              [132.43708, 55.120041],
              [132.39962, 55.14084],
              [132.411029, 55.149204],
              [132.381305, 55.147714],
              [132.375761, 55.159525],
              [132.401197, 55.16423],
              [132.382877, 55.174851],
              [132.35024, 55.17724],
              [132.363678, 55.193641],
              [132.36702, 55.183974],
              [132.39203, 55.185897],
              [132.377434, 55.20016],
              [132.352911, 55.206547],
              [132.36501, 55.218049],
              [132.33884, 55.221029],
              [132.336473, 55.230074],
              [132.372203, 55.247707],
              [132.425783, 55.243655],
              [132.45272, 55.24581],
              [132.481387, 55.234289],
              [132.512173, 55.245663],
              [132.546045, 55.24724],
              [132.611489, 55.235266],
              [132.631969, 55.263506],
              [132.650688, 55.280196],
              [132.692648, 55.30112],
              [132.67767, 55.355463],
              [132.69543, 55.360101],
              [132.739113, 55.346101],
              [132.77362, 55.347835],
              [132.786114, 55.362795],
              [132.832366, 55.382111],
              [132.84476, 55.379521],
              [132.88873, 55.396391],
              [132.917857, 55.384454],
              [132.930493, 55.392793],
              [132.972059, 55.401858],
              [132.965341, 55.42034],
              [132.935755, 55.429922],
              [132.902121, 55.427253],
              [132.888819, 55.441954],
              [132.844827, 55.435078],
              [132.817532, 55.438432],
              [132.799444, 55.454878],
              [132.763257, 55.456295],
              [132.745636, 55.469149],
              [132.708463, 55.511444],
              [132.690269, 55.510805],
              [132.66118, 55.521493],
              [132.639372, 55.556642],
              [132.647594, 55.564962],
              [132.686741, 55.582823],
              [132.658108, 55.604619],
              [132.661506, 55.626406],
              [132.645141, 55.632425],
              [132.591499, 55.666979],
              [132.570898, 55.669543],
              [132.559816, 55.690028],
              [132.558152, 55.709942],
              [132.541717, 55.7169],
              [132.501438, 55.710682],
              [132.49409, 55.719277],
              [132.470163, 55.721503],
              [132.441057, 55.742781],
              [132.415113, 55.746301],
              [132.386614, 55.738646],
              [132.320573, 55.741759],
              [132.306221, 55.732869],
              [132.271881, 55.73813],
              [132.234955, 55.728821],
              [132.220982, 55.707601],
              [132.197944, 55.710523],
              [132.174118, 55.705076],
              [132.155122, 55.688915],
              [132.156486, 55.679722],
              [132.10528, 55.689652],
              [132.083294, 55.701862],
              [132.044689, 55.701042],
              [132.038567, 55.695426],
              [131.974634, 55.714083],
              [131.941487, 55.704714],
              [131.932155, 55.675741],
              [131.940055, 55.659021],
              [131.897156, 55.669199],
              [131.867794, 55.664775],
              [131.827437, 55.647814],
              [131.804212, 55.653878],
              [131.798981, 55.676354],
              [131.773743, 55.694409],
              [131.744197, 55.691922],
              [131.666167, 55.700777],
              [131.636182, 55.708917],
              [131.61599, 55.701441],
              [131.59433, 55.68442],
              [131.594161, 55.670749],
              [131.579067, 55.655608],
              [131.564349, 55.655679],
              [131.524138, 55.676312],
              [131.525618, 55.686211],
              [131.493274, 55.697194],
              [131.44345, 55.686602],
              [131.455822, 55.674108],
              [131.424769, 55.661943],
              [131.386553, 55.657876],
              [131.36989, 55.665869],
              [131.363908, 55.657304],
              [131.33524, 55.651016],
              [131.298941, 55.673818],
              [131.267424, 55.674583],
              [131.255811, 55.686688],
              [131.21572, 55.694136],
              [131.187228, 55.664624],
              [131.147458, 55.649038],
              [131.09436, 55.654831],
              [131.070853, 55.663672],
              [131.05933, 55.656553],
              [131.014843, 55.687076],
              [130.971775, 55.69907],
              [130.952768, 55.712176],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5482",
      properties: {
        name: "Ханты-Мансийский автономный округ - Югра",
        density: 0,
        path: "/world/Russia/Ханты-Мансийский автономный округ - Югра",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [62.000444, 65.717736],
            [62.057542, 65.706138],
            [62.082812, 65.687458],
            [62.155129, 65.658611],
            [62.162845, 65.641382],
            [62.265281, 65.632344],
            [62.306307, 65.586818],
            [62.31297, 65.535157],
            [62.425405, 65.503702],
            [62.366543, 65.43211],
            [62.454152, 65.339291],
            [62.872114, 65.290589],
            [62.817293, 65.19469],
            [62.823741, 65.054607],
            [62.784569, 65.007945],
            [62.750244, 64.935124],
            [62.721849, 64.930727],
            [62.695222, 64.872818],
            [62.807012, 64.840715],
            [62.822519, 64.789863],
            [62.802971, 64.775991],
            [62.811534, 64.736448],
            [62.731729, 64.682102],
            [62.589629, 64.640373],
            [62.598902, 64.622727],
            [62.557643, 64.558547],
            [62.714807, 64.496628],
            [62.834749, 64.505259],
            [62.913436, 64.467695],
            [62.984144, 64.49865],
            [63.052354, 64.515668],
            [63.247328, 64.520418],
            [63.393418, 64.464475],
            [63.477518, 64.456145],
            [63.516973, 64.363156],
            [63.410065, 64.333669],
            [63.50434, 64.291577],
            [63.59398, 64.294836],
            [63.61126, 64.270514],
            [63.913423, 64.287707],
            [63.948638, 64.327428],
            [63.974429, 64.340842],
            [64.043311, 64.359353],
            [64.482332, 64.371083],
            [64.603817, 64.442677],
            [64.724249, 64.430431],
            [64.983107, 64.470057],
            [65.178586, 64.468122],
            [65.260676, 64.506807],
            [65.376371, 64.555241],
            [65.633317, 64.558346],
            [65.933079, 64.555248],
            [65.997001, 64.582256],
            [66.197823, 64.59119],
            [66.45493, 64.527194],
            [66.527405, 64.53995],
            [66.579397, 64.544917],
            [66.639818, 64.545674],
            [66.675258, 64.55365],
            [66.708921, 64.554487],
            [66.743926, 64.545029],
            [66.805601, 64.544364],
            [66.903612, 64.515114],
            [66.933793, 64.520322],
            [66.970269, 64.508071],
            [66.978998, 64.478729],
            [66.943863, 64.47042],
            [66.982689, 64.462163],
            [66.989855, 64.452413],
            [66.977605, 64.423956],
            [66.937285, 64.405903],
            [66.914885, 64.374168],
            [66.847343, 64.340373],
            [66.855727, 64.331243],
            [66.828735, 64.291745],
            [66.844506, 64.290075],
            [66.835255, 64.264268],
            [66.842265, 64.228073],
            [66.837673, 64.212394],
            [66.866675, 64.203068],
            [66.904247, 64.206104],
            [66.926256, 64.201495],
            [66.941885, 64.185803],
            [66.988902, 64.183432],
            [67.010781, 64.189062],
            [67.064809, 64.176893],
            [67.102171, 64.175816],
            [67.129693, 64.159425],
            [67.173981, 64.155999],
            [67.199669, 64.159666],
            [67.175596, 64.137904],
            [67.173003, 64.121351],
            [67.256246, 64.119734],
            [67.285666, 64.109779],
            [67.258515, 64.082051],
            [67.284903, 64.073888],
            [67.379995, 64.094677],
            [67.410795, 64.092342],
            [67.446589, 64.079032],
            [67.468898, 64.077359],
            [67.530714, 64.088488],
            [67.584153, 64.068562],
            [67.607542, 64.074084],
            [67.637824, 64.070199],
            [67.677623, 64.076902],
            [67.708647, 64.05776],
            [67.759279, 64.039665],
            [67.783301, 64.019195],
            [67.81876, 64.025309],
            [67.836193, 64.047912],
            [67.900104, 64.080737],
            [67.94158, 64.090174],
            [67.945155, 64.111922],
            [68.003685, 64.115227],
            [68.043567, 64.126016],
            [68.051928, 64.135809],
            [68.119994, 64.148625],
            [68.17795, 64.168602],
            [68.192319, 64.189135],
            [68.22016, 64.205661],
            [68.269044, 64.219631],
            [68.361269, 64.256915],
            [68.448825, 64.287694],
            [68.479372, 64.290085],
            [68.554679, 64.286463],
            [68.659141, 64.255333],
            [68.789036, 64.224683],
            [68.88206, 64.221717],
            [68.914756, 64.225913],
            [68.922826, 64.239546],
            [68.913064, 64.25951],
            [68.92206, 64.271613],
            [68.972276, 64.292765],
            [68.99078, 64.305535],
            [69.043761, 64.329374],
            [69.031082, 64.342582],
            [68.997339, 64.33934],
            [68.972196, 64.347165],
            [68.92665, 64.381815],
            [68.910423, 64.401757],
            [68.858472, 64.438539],
            [68.989973, 64.446344],
            [69.048018, 64.456006],
            [69.139597, 64.454516],
            [69.252204, 64.480255],
            [69.279718, 64.475579],
            [69.396756, 64.386862],
            [69.50955, 64.385918],
            [69.724948, 64.395908],
            [69.782913, 64.371672],
            [69.827757, 64.357466],
            [69.832335, 64.329812],
            [69.888528, 64.321694],
            [70.086485, 64.31878],
            [70.170085, 64.341234],
            [70.293517, 64.343852],
            [70.316141, 64.328251],
            [70.451002, 64.2487],
            [70.663629, 64.238863],
            [70.770934, 64.191113],
            [70.766496, 64.112367],
            [70.666297, 64.069209],
            [70.558903, 64.015282],
            [70.608592, 63.972069],
            [70.688871, 63.94098],
            [70.706206, 63.886808],
            [70.825653, 63.821911],
            [70.876593, 63.756679],
            [70.922692, 63.702792],
            [71.049697, 63.701495],
            [71.109507, 63.70311],
            [71.247148, 63.700463],
            [71.455856, 63.683308],
            [71.461273, 63.662355],
            [71.490714, 63.64528],
            [71.563007, 63.571948],
            [71.565238, 63.556634],
            [71.536975, 63.472687],
            [71.544898, 63.448901],
            [71.600598, 63.318983],
            [71.645484, 63.192752],
            [71.785132, 63.226995],
            [71.842448, 63.248284],
            [71.881758, 63.255933],
            [71.956087, 63.257632],
            [72.033459, 63.255622],
            [72.100081, 63.276521],
            [72.128306, 63.296786],
            [72.406384, 63.299387],
            [72.66308, 63.281521],
            [72.804964, 63.335946],
            [72.872556, 63.355793],
            [72.901202, 63.3974],
            [73.054297, 63.426791],
            [73.125184, 63.397768],
            [73.106153, 63.378196],
            [73.263269, 63.334776],
            [73.363796, 63.232242],
            [73.498183, 63.187955],
            [73.865504, 63.17119],
            [74.003924, 63.16813],
            [74.253194, 63.173027],
            [74.389952, 63.11449],
            [74.457345, 63.067122],
            [74.524322, 63.036364],
            [74.787514, 63.028981],
            [74.941549, 63.025322],
            [75.019551, 63.04672],
            [75.080264, 63.068454],
            [75.177443, 63.060908],
            [75.253558, 63.072457],
            [75.312551, 63.09707],
            [75.428676, 63.09805],
            [75.604962, 63.088084],
            [75.778408, 63.117148],
            [75.893379, 63.106482],
            [75.969968, 63.070608],
            [76.034384, 63.066713],
            [76.266714, 63.013198],
            [76.34005, 62.951344],
            [76.516902, 62.999357],
            [76.666942, 63.03481],
            [76.851631, 62.988709],
            [76.891352, 62.990745],
            [77.025323, 62.97192],
            [77.076727, 62.90101],
            [77.136819, 62.811422],
            [77.378102, 62.707897],
            [77.736092, 62.613238],
            [77.870423, 62.575948],
            [77.997859, 62.513573],
            [78.092906, 62.464098],
            [78.134596, 62.492828],
            [78.405963, 62.560355],
            [78.519467, 62.561092],
            [78.739462, 62.602213],
            [78.958785, 62.604858],
            [79.033506, 62.604514],
            [79.206321, 62.607419],
            [79.251138, 62.606674],
            [79.338717, 62.610661],
            [79.436125, 62.617529],
            [79.485684, 62.613982],
            [79.549697, 62.604292],
            [79.84344, 62.608557],
            [79.888616, 62.689899],
            [79.90381, 62.73209],
            [79.891493, 62.760082],
            [79.908321, 62.785033],
            [79.971252, 62.812941],
            [80.033394, 62.80776],
            [80.093017, 62.817933],
            [80.127285, 62.83207],
            [80.186641, 62.864102],
            [80.283708, 62.893737],
            [80.304757, 62.912402],
            [80.381905, 62.932537],
            [80.463206, 62.967335],
            [80.481344, 62.988404],
            [80.550595, 62.989025],
            [80.588787, 63.010208],
            [80.588381, 63.078139],
            [80.680919, 63.115941],
            [80.921948, 63.156636],
            [81.037018, 63.147459],
            [81.04349, 63.064524],
            [81.09632, 63.011204],
            [81.146951, 62.985953],
            [81.1503, 62.968745],
            [81.221253, 62.933246],
            [81.319859, 62.909914],
            [81.291249, 62.858547],
            [81.391306, 62.816941],
            [81.638742, 62.779396],
            [81.665461, 62.730553],
            [81.741175, 62.705005],
            [81.841287, 62.695857],
            [81.945271, 62.746047],
            [82.014015, 62.760161],
            [82.057963, 62.772393],
            [82.159325, 62.813008],
            [82.28936, 62.766298],
            [82.385112, 62.761329],
            [82.460168, 62.775159],
            [82.522789, 62.768131],
            [82.761864, 62.749446],
            [82.767236, 62.667415],
            [82.758996, 62.659955],
            [82.913857, 62.626211],
            [83.049317, 62.589347],
            [83.023482, 62.526334],
            [83.324248, 62.458029],
            [83.553267, 62.527374],
            [83.56373, 62.498239],
            [83.622688, 62.503169],
            [83.814028, 62.569876],
            [83.960389, 62.490159],
            [83.999492, 62.414854],
            [84.029004, 62.400176],
            [84.102061, 62.377306],
            [84.178694, 62.380209],
            [84.216697, 62.385866],
            [84.23908, 62.381468],
            [84.260146, 62.353253],
            [84.293099, 62.334189],
            [84.305637, 62.308297],
            [84.295021, 62.282115],
            [84.298348, 62.269345],
            [84.324728, 62.260122],
            [84.386936, 62.25079],
            [84.42533, 62.230166],
            [84.440859, 62.195657],
            [84.448072, 62.165132],
            [84.491986, 62.098471],
            [84.49506, 62.065138],
            [84.490848, 62.031781],
            [84.523857, 61.990612],
            [84.514918, 61.969531],
            [84.519392, 61.927839],
            [84.554217, 61.898145],
            [84.61101, 61.875949],
            [84.630174, 61.860226],
            [84.63959, 61.831949],
            [84.677915, 61.80813],
            [84.735405, 61.792696],
            [84.840892, 61.773046],
            [84.889225, 61.780066],
            [84.91898, 61.789921],
            [84.992454, 61.769161],
            [85.096852, 61.751986],
            [85.174212, 61.708649],
            [85.229997, 61.698402],
            [85.277798, 61.673722],
            [85.340882, 61.686691],
            [85.406556, 61.687147],
            [85.492853, 61.617215],
            [85.712839, 61.572615],
            [85.842513, 61.593921],
            [85.961137, 61.54512],
            [85.976673, 61.48261],
            [85.965282, 61.459195],
            [85.798192, 61.454098],
            [85.640731, 61.36709],
            [85.700535, 61.316369],
            [85.696583, 61.283815],
            [85.619246, 61.262323],
            [85.2797, 61.17077],
            [85.251538, 61.161459],
            [85.000187, 61.091738],
            [84.735082, 61.014263],
            [84.65392, 61.003498],
            [84.641651, 60.997945],
            [84.26311, 60.862518],
            [84.037853, 60.842435],
            [83.949694, 60.836217],
            [83.476909, 61.035672],
            [83.156683, 61.026795],
            [83.117912, 61.0124],
            [82.810137, 60.887063],
            [82.666543, 60.826982],
            [82.454093, 60.739884],
            [82.376451, 60.706879],
            [82.395229, 60.642404],
            [82.409601, 60.608155],
            [82.383422, 60.600347],
            [82.167305, 60.51634],
            [81.879534, 60.639791],
            [81.584263, 60.637983],
            [81.458741, 60.607139],
            [81.290592, 60.624811],
            [81.10419, 60.642048],
            [81.098435, 60.663329],
            [81.082297, 60.66997],
            [81.066821, 60.698241],
            [81.052447, 60.755725],
            [80.98408, 60.774523],
            [80.704837, 60.802779],
            [80.639058, 60.770738],
            [80.404972, 60.750197],
            [80.200997, 60.675322],
            [79.99238, 60.673476],
            [79.948533, 60.670061],
            [79.674619, 60.679882],
            [79.653362, 60.686547],
            [79.616099, 60.672919],
            [79.586964, 60.676276],
            [79.552842, 60.666662],
            [79.511089, 60.66799],
            [79.466066, 60.651819],
            [79.39887, 60.63584],
            [79.347647, 60.667564],
            [79.333194, 60.692619],
            [79.310196, 60.691681],
            [79.296047, 60.707553],
            [79.292835, 60.732147],
            [79.306394, 60.747471],
            [79.282397, 60.756617],
            [79.281332, 60.766679],
            [79.316109, 60.773877],
            [79.308211, 60.794421],
            [79.280243, 60.807383],
            [79.264254, 60.80492],
            [79.188381, 60.828644],
            [79.165905, 60.826351],
            [79.154585, 60.813621],
            [79.126536, 60.809552],
            [79.102804, 60.819846],
            [79.098451, 60.80536],
            [79.063027, 60.80688],
            [79.042641, 60.819355],
            [79.038341, 60.803207],
            [79.009519, 60.810334],
            [78.999552, 60.803684],
            [78.98489, 60.820193],
            [78.95341, 60.80266],
            [78.918998, 60.794736],
            [78.859229, 60.762831],
            [78.847018, 60.770127],
            [78.819789, 60.767594],
            [78.795277, 60.750058],
            [78.761713, 60.75473],
            [78.776975, 60.767922],
            [78.75491, 60.786997],
            [78.718459, 60.797904],
            [78.716328, 60.812821],
            [78.699992, 60.825356],
            [78.643277, 60.816575],
            [78.486753, 60.764639],
            [78.335904, 60.789241],
            [78.195398, 60.802477],
            [78.088345, 60.796784],
            [77.996985, 60.751659],
            [77.969963, 60.740696],
            [77.91946, 60.761766],
            [77.889358, 60.778865],
            [77.776443, 60.805875],
            [77.704323, 60.81999],
            [77.582634, 60.821176],
            [77.511705, 60.817487],
            [77.449238, 60.810023],
            [77.397008, 60.813889],
            [77.307203, 60.839951],
            [77.259611, 60.838249],
            [77.195721, 60.856371],
            [77.107935, 60.858414],
            [77.072066, 60.841782],
            [77.09656, 60.82307],
            [77.090354, 60.804548],
            [77.067248, 60.794377],
            [77.078079, 60.771349],
            [77.112207, 60.746307],
            [77.097546, 60.70529],
            [77.030456, 60.692589],
            [77.012741, 60.692579],
            [77.026476, 60.680886],
            [77.004717, 60.667158],
            [77.001348, 60.651969],
            [76.963016, 60.651213],
            [76.965589, 60.63119],
            [76.99538, 60.618996],
            [77.039846, 60.615672],
            [77.048133, 60.62515],
            [77.061724, 60.59976],
            [77.042664, 60.584034],
            [77.063081, 60.576115],
            [77.058118, 60.546489],
            [77.072209, 60.533701],
            [77.047763, 60.50892],
            [77.004468, 60.50554],
            [76.99012, 60.510802],
            [76.962345, 60.495359],
            [76.923395, 60.490034],
            [76.908805, 60.481291],
            [76.883037, 60.480854],
            [76.829158, 60.466948],
            [76.799347, 60.468751],
            [76.819377, 60.446376],
            [76.803234, 60.440637],
            [76.812711, 60.420958],
            [76.776916, 60.406918],
            [76.776925, 60.388346],
            [76.763255, 60.376239],
            [76.772165, 60.325936],
            [76.79954, 60.325239],
            [76.834355, 60.303913],
            [76.837463, 60.292637],
            [76.866349, 60.271042],
            [76.850307, 60.264683],
            [76.85708, 60.233379],
            [76.831701, 60.219247],
            [76.827144, 60.179173],
            [76.806478, 60.170538],
            [76.790061, 60.148209],
            [76.769302, 60.142511],
            [76.749723, 60.126812],
            [76.713994, 60.115036],
            [76.772433, 60.065674],
            [76.75775, 60.001698],
            [76.772889, 59.991184],
            [76.740251, 59.71086],
            [76.667053, 59.683865],
            [76.652304, 59.606796],
            [76.674988, 59.588059],
            [76.664888, 59.571174],
            [76.502463, 59.543769],
            [76.415635, 59.544651],
            [76.177166, 59.530001],
            [76.003504, 59.393173],
            [75.992856, 59.380677],
            [75.898666, 59.399905],
            [75.841198, 59.280213],
            [75.639673, 59.211579],
            [75.679617, 59.120133],
            [75.727608, 59.015372],
            [75.72395, 59.001001],
            [75.701148, 58.968584],
            [75.635376, 58.912346],
            [75.520414, 58.858804],
            [75.375765, 58.785863],
            [75.131297, 58.663889],
            [74.997691, 58.700044],
            [74.89456, 58.68515],
            [74.838868, 58.705862],
            [74.792717, 58.72888],
            [74.584939, 58.729831],
            [74.509618, 58.721926],
            [74.175346, 58.726107],
            [74.144895, 58.730214],
            [74.084608, 58.749219],
            [73.991066, 58.810845],
            [73.859082, 58.806781],
            [73.787376, 58.838031],
            [73.718792, 58.856215],
            [73.618932, 58.84385],
            [73.500146, 58.824826],
            [73.252259, 58.845531],
            [73.134862, 58.859054],
            [73.02481, 58.874928],
            [73.000423, 58.889287],
            [73.080952, 58.930454],
            [73.099956, 58.951689],
            [73.029659, 58.995894],
            [72.928297, 59.053141],
            [72.956655, 59.086441],
            [72.975619, 59.126382],
            [72.911212, 59.154002],
            [72.867915, 59.155276],
            [72.792828, 59.146781],
            [72.744002, 59.136852],
            [72.553381, 59.138758],
            [72.527045, 59.148466],
            [72.500308, 59.176215],
            [72.452342, 59.192796],
            [72.418788, 59.209837],
            [72.433427, 59.236061],
            [72.442125, 59.300034],
            [72.355696, 59.350186],
            [72.285589, 59.377837],
            [72.231743, 59.413327],
            [72.185415, 59.46628],
            [72.117824, 59.506947],
            [72.086949, 59.530923],
            [72.025135, 59.551735],
            [71.889945, 59.555058],
            [71.863609, 59.559875],
            [71.832924, 59.578094],
            [71.825303, 59.610622],
            [71.792031, 59.635935],
            [71.731083, 59.648695],
            [71.658937, 59.656328],
            [71.544795, 59.685164],
            [71.499573, 59.742673],
            [71.257995, 59.78872],
            [71.226977, 59.830528],
            [71.179328, 59.907066],
            [71.123609, 59.886132],
            [70.999745, 59.827671],
            [70.941546, 59.83398],
            [70.869606, 59.855939],
            [70.743119, 59.884664],
            [70.686242, 59.872753],
            [70.615243, 59.86386],
            [70.497362, 59.851992],
            [70.395593, 59.839298],
            [70.357046, 59.866397],
            [70.308199, 59.870832],
            [70.278264, 59.886986],
            [70.2614, 59.91089],
            [70.221474, 59.923675],
            [70.207407, 59.935348],
            [70.174414, 59.937187],
            [70.134467, 59.949768],
            [70.091057, 59.948564],
            [70.051135, 59.938116],
            [70.03141, 59.941626],
            [69.991051, 59.926652],
            [69.936402, 59.921026],
            [69.884539, 59.923922],
            [69.829981, 59.909717],
            [69.793495, 59.914664],
            [69.651104, 59.909822],
            [69.623534, 59.967746],
            [69.516071, 59.972482],
            [69.391674, 59.97988],
            [69.267802, 59.989634],
            [69.168493, 59.9663],
            [69.134553, 59.946945],
            [69.072155, 59.904664],
            [69.146265, 59.88369],
            [69.312403, 59.793005],
            [69.318743, 59.779386],
            [69.308508, 59.769741],
            [69.044445, 59.686838],
            [68.997635, 59.666607],
            [68.728619, 59.568394],
            [68.573023, 59.588331],
            [68.495627, 59.42603],
            [68.422361, 59.335435],
            [68.402848, 59.306112],
            [68.269337, 59.24606],
            [68.104095, 59.210562],
            [67.793838, 59.2101],
            [67.731584, 59.19886],
            [67.658222, 59.168561],
            [67.612735, 59.104868],
            [67.508355, 59.074203],
            [67.303189, 59.023444],
            [67.052156, 58.932059],
            [66.985405, 58.971118],
            [66.969014, 58.972043],
            [66.772472, 58.923282],
            [66.778473, 58.753556],
            [66.785238, 58.656545],
            [66.619572, 58.63255],
            [66.455931, 58.607422],
            [66.365657, 58.579901],
            [66.315182, 58.584184],
            [66.291235, 58.594888],
            [66.189208, 58.600152],
            [65.95016, 58.614779],
            [65.655676, 58.658463],
            [65.619596, 58.69031],
            [65.371533, 58.900124],
            [65.329935, 59.033705],
            [65.302823, 59.081759],
            [65.238066, 59.204537],
            [65.217514, 59.215583],
            [65.136479, 59.209666],
            [65.122358, 59.216259],
            [65.121705, 59.233108],
            [65.095714, 59.242003],
            [65.093449, 59.264396],
            [65.079853, 59.286357],
            [65.074329, 59.310298],
            [65.045995, 59.317525],
            [64.90735, 59.333998],
            [64.627883, 59.360475],
            [64.486645, 59.376781],
            [64.020466, 59.381857],
            [63.999311, 59.390422],
            [63.874313, 59.461301],
            [63.848733, 59.566734],
            [63.838449, 59.65249],
            [63.824946, 59.744732],
            [63.795033, 59.816742],
            [63.72959, 59.913307],
            [63.677619, 59.998091],
            [63.605936, 60.101704],
            [63.618227, 60.115541],
            [63.60257, 60.138054],
            [63.535925, 60.130734],
            [63.487508, 60.148936],
            [63.444274, 60.150103],
            [63.247729, 60.20471],
            [63.18084, 60.224474],
            [63.22573, 60.294206],
            [63.158033, 60.362424],
            [63.041349, 60.483632],
            [62.889166, 60.638306],
            [62.867383, 60.653825],
            [62.814434, 60.756567],
            [62.760156, 60.853321],
            [62.825031, 61.001313],
            [62.709984, 61.107263],
            [62.646304, 61.170899],
            [62.551285, 61.221793],
            [62.483555, 61.260973],
            [62.249531, 61.318353],
            [62.200317, 61.333722],
            [61.890521, 61.406809],
            [61.88651, 61.436579],
            [61.676383, 61.510342],
            [61.461353, 61.582788],
            [61.229475, 61.654498],
            [61.194279, 61.663824],
            [61.121502, 61.676645],
            [60.997419, 61.694801],
            [60.838598, 61.698466],
            [60.710683, 61.717059],
            [60.51461, 61.7357],
            [60.199939, 61.724307],
            [59.993321, 61.718358],
            [59.950974, 61.745443],
            [59.944242, 61.756864],
            [59.958736, 61.775966],
            [59.940581, 61.794511],
            [59.945924, 61.834415],
            [59.971141, 61.844326],
            [59.97986, 61.8707],
            [59.972501, 61.882798],
            [59.926947, 61.899152],
            [59.884984, 61.904986],
            [59.822208, 61.906972],
            [59.718022, 61.930487],
            [59.663648, 61.918803],
            [59.629509, 61.91755],
            [59.584842, 61.944895],
            [59.538704, 61.935916],
            [59.507153, 61.936895],
            [59.486699, 61.946069],
            [59.477331, 61.979461],
            [59.465313, 61.995063],
            [59.438397, 61.999515],
            [59.442736, 62.015486],
            [59.425375, 62.050411],
            [59.436656, 62.055547],
            [59.430881, 62.094864],
            [59.401369, 62.104504],
            [59.404955, 62.136903],
            [59.400125, 62.157112],
            [59.432765, 62.174576],
            [59.435856, 62.193728],
            [59.456211, 62.205783],
            [59.453562, 62.234336],
            [59.491126, 62.246724],
            [59.511709, 62.278955],
            [59.494088, 62.299098],
            [59.526464, 62.327885],
            [59.550246, 62.325899],
            [59.577386, 62.335849],
            [59.578084, 62.35775],
            [59.612653, 62.366442],
            [59.61999, 62.405604],
            [59.582734, 62.421282],
            [59.603141, 62.439639],
            [59.60924, 62.477312],
            [59.621651, 62.494288],
            [59.619192, 62.513674],
            [59.629845, 62.522176],
            [59.635447, 62.548454],
            [59.625152, 62.54457],
            [59.551061, 62.545566],
            [59.5085, 62.562821],
            [59.467715, 62.560321],
            [59.442619, 62.565955],
            [59.469099, 62.592541],
            [59.471717, 62.604623],
            [59.437217, 62.621091],
            [59.426075, 62.632817],
            [59.431883, 62.652543],
            [59.402618, 62.674158],
            [59.410605, 62.705249],
            [59.395962, 62.721136],
            [59.37116, 62.724874],
            [59.391679, 62.73744],
            [59.360391, 62.752291],
            [59.414437, 62.759843],
            [59.434096, 62.779641],
            [59.444318, 62.829926],
            [59.443141, 62.876208],
            [59.464346, 62.893049],
            [59.448193, 62.918013],
            [59.377201, 62.941711],
            [59.325204, 62.942026],
            [59.273633, 62.982215],
            [59.22443, 62.982778],
            [59.203794, 63.027597],
            [59.233202, 63.055274],
            [59.243106, 63.080074],
            [59.225312, 63.089303],
            [59.254634, 63.104314],
            [59.28941, 63.104993],
            [59.322027, 63.123271],
            [59.285487, 63.17032],
            [59.288405, 63.184708],
            [59.320395, 63.19704],
            [59.304599, 63.203292],
            [59.331562, 63.229375],
            [59.336674, 63.249565],
            [59.365877, 63.251827],
            [59.366262, 63.266174],
            [59.339221, 63.281587],
            [59.33497, 63.308197],
            [59.32203, 63.314058],
            [59.326557, 63.348915],
            [59.364137, 63.359209],
            [59.373036, 63.370917],
            [59.313, 63.390018],
            [59.353451, 63.414044],
            [59.377966, 63.422055],
            [59.405744, 63.46646],
            [59.404394, 63.479046],
            [59.442454, 63.500528],
            [59.449857, 63.516444],
            [59.436928, 63.530527],
            [59.467974, 63.548],
            [59.478645, 63.572225],
            [59.47992, 63.614184],
            [59.473707, 63.638534],
            [59.496804, 63.653542],
            [59.500314, 63.687782],
            [59.533165, 63.714067],
            [59.521964, 63.724038],
            [59.533813, 63.745989],
            [59.527208, 63.771404],
            [59.541601, 63.820747],
            [59.571, 63.841078],
            [59.58006, 63.862522],
            [59.559964, 63.884864],
            [59.561302, 63.908559],
            [59.593018, 63.924348],
            [59.634136, 63.931134],
            [59.688065, 63.94595],
            [59.72279, 63.962117],
            [59.723352, 63.974075],
            [59.756326, 63.985473],
            [59.768687, 63.999949],
            [59.75742, 64.007112],
            [59.769766, 64.042346],
            [59.811302, 64.063284],
            [59.810533, 64.075785],
            [59.836947, 64.087144],
            [59.822345, 64.095163],
            [59.815235, 64.115073],
            [59.828359, 64.134407],
            [59.768183, 64.142667],
            [59.744113, 64.136433],
            [59.679501, 64.149016],
            [59.677796, 64.164328],
            [59.620796, 64.197696],
            [59.633988, 64.214047],
            [59.585183, 64.224432],
            [59.55983, 64.247737],
            [59.574095, 64.25314],
            [59.571647, 64.274308],
            [59.587271, 64.302809],
            [59.601092, 64.311108],
            [59.600077, 64.333729],
            [59.625999, 64.340828],
            [59.653717, 64.376185],
            [59.62263, 64.387376],
            [59.616166, 64.408627],
            [59.651175, 64.433148],
            [59.629247, 64.461526],
            [59.600329, 64.465922],
            [59.590241, 64.475978],
            [59.598435, 64.497467],
            [59.561088, 64.498961],
            [59.507163, 64.490439],
            [59.464282, 64.495798],
            [59.466195, 64.505842],
            [59.494704, 64.519313],
            [59.526315, 64.522399],
            [59.536323, 64.540316],
            [59.556749, 64.544007],
            [59.552113, 64.556071],
            [59.573207, 64.576099],
            [59.570746, 64.589729],
            [59.618408, 64.617777],
            [59.631457, 64.6379],
            [59.615089, 64.64667],
            [59.655024, 64.65706],
            [59.7251, 64.652498],
            [59.745304, 64.657241],
            [59.746635, 64.679672],
            [59.72439, 64.692999],
            [59.661117, 64.702024],
            [59.660252, 64.711075],
            [59.626092, 64.724716],
            [59.638389, 64.741983],
            [59.63034, 64.763135],
            [59.648094, 64.775226],
            [59.624966, 64.78496],
            [59.626861, 64.803015],
            [59.675885, 64.801337],
            [59.706685, 64.823285],
            [59.723883, 64.827827],
            [59.707821, 64.837712],
            [59.73503, 64.846043],
            [59.726773, 64.853714],
            [59.812292, 64.910195],
            [59.867154, 64.89549],
            [59.888279, 64.902543],
            [59.879619, 64.920824],
            [59.890691, 64.930419],
            [59.950259, 64.943434],
            [60.001153, 64.939918],
            [60.010842, 64.955815],
            [60.038073, 64.96082],
            [60.031191, 64.974592],
            [60.049971, 64.979083],
            [60.056269, 64.996032],
            [60.036927, 65.006768],
            [60.033904, 65.028322],
            [60.101346, 65.038348],
            [60.130546, 65.055739],
            [60.177349, 65.052986],
            [60.203559, 65.076943],
            [60.289914, 65.076326],
            [60.35556, 65.060169],
            [60.377801, 65.072337],
            [60.417476, 65.066288],
            [60.447877, 65.036957],
            [60.428405, 65.023475],
            [60.434454, 65.00674],
            [60.48843, 64.989316],
            [60.516354, 64.962713],
            [60.592993, 64.942128],
            [60.609563, 64.920501],
            [60.644805, 64.925762],
            [60.72944, 64.925505],
            [60.753755, 64.939346],
            [60.753212, 64.960118],
            [60.765388, 64.967777],
            [60.753815, 64.980045],
            [60.794563, 65.004511],
            [60.807062, 65.024702],
            [60.84733, 65.027714],
            [60.948215, 65.014577],
            [60.955745, 65.027903],
            [60.988548, 65.04727],
            [60.996897, 65.067247],
            [61.041285, 65.071714],
            [61.076999, 65.082361],
            [61.091081, 65.106465],
            [61.081824, 65.127035],
            [61.097518, 65.138433],
            [61.093093, 65.151899],
            [61.137374, 65.174495],
            [61.166163, 65.167684],
            [61.193366, 65.176767],
            [61.27206, 65.16841],
            [61.295941, 65.183228],
            [61.297693, 65.205582],
            [61.281404, 65.211969],
            [61.323386, 65.219914],
            [61.364851, 65.2496],
            [61.351023, 65.256621],
            [61.359639, 65.28197],
            [61.295947, 65.276648],
            [61.285108, 65.31284],
            [61.298457, 65.327348],
            [61.335774, 65.345616],
            [61.347973, 65.358507],
            [61.38901, 65.370602],
            [61.417349, 65.386495],
            [61.434698, 65.411656],
            [61.47539, 65.454127],
            [61.491333, 65.455144],
            [61.524417, 65.494941],
            [61.564165, 65.514705],
            [61.571082, 65.528201],
            [61.598434, 65.539773],
            [61.626756, 65.561903],
            [61.669803, 65.558652],
            [61.69067, 65.575222],
            [61.716791, 65.577346],
            [61.742868, 65.589061],
            [61.703051, 65.602312],
            [61.750465, 65.622093],
            [61.78545, 65.624826],
            [61.830104, 65.644434],
            [61.814891, 65.652638],
            [61.831175, 65.663645],
            [61.855128, 65.660797],
            [61.878652, 65.669296],
            [61.857863, 65.686456],
            [61.955117, 65.69501],
            [61.961463, 65.7052],
            [62.000444, 65.717736],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5547",
      properties: {
        name: "Челябинская область",
        density: 0,
        path: "/world/Russia/Челябинская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [59.04205, 56.168357],
            [59.030051, 56.173308],
            [59.018223, 56.197682],
            [59.067135, 56.199447],
            [59.109779, 56.197194],
            [59.120907, 56.190415],
            [59.133113, 56.202521],
            [59.168521, 56.198713],
            [59.16889, 56.230173],
            [59.198336, 56.271061],
            [59.186199, 56.2965],
            [59.164644, 56.304995],
            [59.162618, 56.314876],
            [59.138971, 56.322006],
            [59.141941, 56.330622],
            [59.164404, 56.326346],
            [59.159478, 56.34547],
            [59.222805, 56.355335],
            [59.262379, 56.343599],
            [59.2844, 56.344473],
            [59.28884, 56.327483],
            [59.420376, 56.320775],
            [59.412691, 56.336071],
            [59.554775, 56.338906],
            [59.571764, 56.337873],
            [59.572353, 56.320981],
            [59.714008, 56.323517],
            [59.634706, 56.248048],
            [59.757861, 56.223543],
            [59.832897, 56.245543],
            [59.863296, 56.260554],
            [59.91537, 56.257484],
            [59.961201, 56.239258],
            [59.977186, 56.238502],
            [60.004012, 56.220641],
            [60.01838, 56.225017],
            [60.050958, 56.213551],
            [60.102612, 56.218838],
            [60.140545, 56.213888],
            [60.170603, 56.217078],
            [60.200595, 56.202664],
            [60.258947, 56.199749],
            [60.261563, 56.213531],
            [60.290445, 56.228516],
            [60.363341, 56.233296],
            [60.465217, 56.221059],
            [60.502621, 56.236836],
            [60.553838, 56.221309],
            [60.576364, 56.318084],
            [60.679792, 56.310093],
            [60.65546, 56.214968],
            [60.705005, 56.242695],
            [60.77049, 56.237147],
            [60.768357, 56.218778],
            [60.808397, 56.217737],
            [60.846361, 56.224448],
            [60.879065, 56.22041],
            [60.874457, 56.21478],
            [60.963869, 56.190952],
            [60.976089, 56.200604],
            [61.01414, 56.185094],
            [61.045964, 56.19983],
            [61.07922, 56.183873],
            [61.103532, 56.181438],
            [61.185875, 56.208264],
            [61.174526, 56.229529],
            [61.160415, 56.239439],
            [61.145639, 56.280185],
            [61.091777, 56.319243],
            [61.093303, 56.339288],
            [61.119931, 56.352921],
            [61.154796, 56.357224],
            [61.161025, 56.366287],
            [61.18012, 56.353453],
            [61.219909, 56.349651],
            [61.254729, 56.339044],
            [61.259559, 56.332062],
            [61.310379, 56.340261],
            [61.319722, 56.328535],
            [61.313447, 56.313413],
            [61.337796, 56.314242],
            [61.382899, 56.33787],
            [61.409282, 56.345597],
            [61.420876, 56.335846],
            [61.444061, 56.353236],
            [61.471602, 56.345944],
            [61.471576, 56.330009],
            [61.504934, 56.330304],
            [61.54124, 56.303571],
            [61.560983, 56.309548],
            [61.581456, 56.287675],
            [61.591907, 56.304267],
            [61.604536, 56.302224],
            [61.618051, 56.320227],
            [61.668357, 56.317422],
            [61.698559, 56.300068],
            [61.73458, 56.286702],
            [61.723891, 56.272197],
            [61.746067, 56.269379],
            [61.74992, 56.2445],
            [61.76923, 56.237808],
            [61.773073, 56.226355],
            [61.80518, 56.228559],
            [61.830382, 56.241274],
            [61.870744, 56.222679],
            [61.870968, 56.198586],
            [61.836979, 56.177833],
            [61.851164, 56.177883],
            [61.90458, 56.164831],
            [61.936046, 56.153162],
            [62.061058, 56.130821],
            [62.148541, 56.116316],
            [62.151776, 56.111757],
            [62.113867, 56.090665],
            [62.124984, 56.081645],
            [62.230419, 56.027999],
            [62.218997, 55.996339],
            [62.230334, 55.939632],
            [62.238595, 55.933735],
            [62.31766, 55.947603],
            [62.364329, 55.933387],
            [62.369971, 55.923328],
            [62.344562, 55.901515],
            [62.313568, 55.900655],
            [62.310055, 55.86677],
            [62.29181, 55.866571],
            [62.304293, 55.837975],
            [62.360737, 55.805429],
            [62.390936, 55.813193],
            [62.41601, 55.834826],
            [62.440321, 55.827572],
            [62.420078, 55.806317],
            [62.469247, 55.800751],
            [62.498942, 55.780707],
            [62.525893, 55.769909],
            [62.515934, 55.72578],
            [62.543848, 55.709547],
            [62.517429, 55.679204],
            [62.536746, 55.664477],
            [62.551791, 55.662811],
            [62.554016, 55.646069],
            [62.546249, 55.624153],
            [62.524592, 55.625249],
            [62.523405, 55.590302],
            [62.472397, 55.585984],
            [62.468967, 55.563626],
            [62.534669, 55.574883],
            [62.55157, 55.567405],
            [62.597863, 55.531209],
            [62.602374, 55.513439],
            [62.62009, 55.490569],
            [62.585075, 55.48449],
            [62.541959, 55.483939],
            [62.499635, 55.472144],
            [62.454923, 55.467246],
            [62.47628, 55.433965],
            [62.452115, 55.4276],
            [62.383235, 55.427026],
            [62.37801, 55.417973],
            [62.319534, 55.413737],
            [62.299507, 55.403819],
            [62.288312, 55.427604],
            [62.263046, 55.438024],
            [62.20855, 55.446311],
            [62.18825, 55.445441],
            [62.166861, 55.409406],
            [62.180311, 55.397237],
            [62.241682, 55.359085],
            [62.244451, 55.337179],
            [62.171268, 55.348693],
            [62.161149, 55.312684],
            [62.106083, 55.304462],
            [62.103194, 55.297734],
            [62.066351, 55.303627],
            [62.043156, 55.260574],
            [62.071679, 55.259332],
            [62.160815, 55.231132],
            [62.174374, 55.202504],
            [62.225938, 55.203206],
            [62.201699, 55.170596],
            [62.208643, 55.150343],
            [62.224007, 55.150734],
            [62.237112, 55.108568],
            [62.274191, 55.113405],
            [62.283391, 55.107393],
            [62.263906, 55.084684],
            [62.28477, 55.078212],
            [62.25832, 55.073978],
            [62.25291, 55.064172],
            [62.223947, 55.065911],
            [62.209049, 55.007085],
            [62.193825, 54.99019],
            [62.147238, 55.03827],
            [62.081408, 55.032419],
            [62.069132, 55.021576],
            [62.046347, 55.021295],
            [62.014551, 55.030216],
            [61.966185, 55.030311],
            [61.995558, 54.980732],
            [61.98787, 54.970678],
            [62.007965, 54.943448],
            [62.000864, 54.931751],
            [62.010334, 54.906065],
            [62.035037, 54.907666],
            [62.054764, 54.900122],
            [62.073992, 54.867154],
            [62.108793, 54.839655],
            [62.118788, 54.814148],
            [62.111899, 54.798037],
            [62.074371, 54.787675],
            [62.037149, 54.764265],
            [62.016963, 54.723884],
            [62.054358, 54.727045],
            [62.084809, 54.719894],
            [62.077854, 54.675609],
            [62.118048, 54.668887],
            [62.155404, 54.668387],
            [62.155938, 54.679579],
            [62.188443, 54.676673],
            [62.202633, 54.68929],
            [62.237823, 54.69127],
            [62.239155, 54.672924],
            [62.276022, 54.669316],
            [62.319144, 54.670853],
            [62.395527, 54.685839],
            [62.405792, 54.722977],
            [62.441153, 54.715725],
            [62.513741, 54.718592],
            [62.550583, 54.729143],
            [62.578253, 54.714924],
            [62.537999, 54.70167],
            [62.519936, 54.688386],
            [62.524142, 54.678356],
            [62.549045, 54.677384],
            [62.550252, 54.652031],
            [62.600672, 54.634595],
            [62.621821, 54.635613],
            [62.631824, 54.670165],
            [62.659602, 54.663626],
            [62.671724, 54.624012],
            [62.698632, 54.62956],
            [62.749789, 54.646767],
            [62.827097, 54.661022],
            [62.86964, 54.675336],
            [62.900567, 54.692772],
            [62.978433, 54.70545],
            [63.014372, 54.692893],
            [63.061435, 54.700512],
            [63.110931, 54.697375],
            [63.199821, 54.699085],
            [63.237101, 54.646927],
            [63.226293, 54.609063],
            [63.243144, 54.581245],
            [63.2851, 54.581361],
            [63.272589, 54.547238],
            [63.294737, 54.543791],
            [63.280173, 54.529435],
            [63.244031, 54.52262],
            [63.234235, 54.487245],
            [63.207921, 54.483939],
            [63.218199, 54.471431],
            [63.195799, 54.465319],
            [63.167028, 54.472849],
            [63.168383, 54.490695],
            [63.153453, 54.493809],
            [63.12989, 54.468126],
            [63.144085, 54.438556],
            [63.181712, 54.431861],
            [63.181211, 54.394922],
            [63.148314, 54.396429],
            [63.138918, 54.403277],
            [63.125166, 54.380913],
            [63.142525, 54.355651],
            [63.154131, 54.35508],
            [63.165626, 54.335988],
            [63.097533, 54.333485],
            [63.117456, 54.328276],
            [63.127624, 54.314759],
            [63.155219, 54.320983],
            [63.171626, 54.308113],
            [63.213937, 54.31519],
            [63.215469, 54.294293],
            [63.263381, 54.293759],
            [63.251585, 54.285381],
            [63.274897, 54.237351],
            [63.309289, 54.261899],
            [63.344, 54.251514],
            [63.352015, 54.241017],
            [63.338985, 54.231347],
            [63.3541, 54.1904],
            [63.3327, 54.1994],
            [63.2545, 54.1897],
            [63.2285, 54.1943],
            [63.2039, 54.1835],
            [63.1672, 54.189],
            [63.1534, 54.1845],
            [63.1472, 54.1594],
            [63.164, 54.1549],
            [63.1532, 54.1357],
            [63.1547, 54.1204],
            [63.0996, 54.1106],
            [63.0843, 54.1016],
            [63.0583, 54.098],
            [62.9925, 54.1062],
            [62.9849, 54.1141],
            [62.9635, 54.1079],
            [62.9253, 54.1169],
            [62.8993, 54.1088],
            [62.8886, 54.1167],
            [62.8748, 54.1097],
            [62.8519, 54.1132],
            [62.8213, 54.1087],
            [62.8, 54.097],
            [62.771, 54.0898],
            [62.7129, 54.0923],
            [62.6411, 54.0803],
            [62.6168, 54.0705],
            [62.5924, 54.0694],
            [62.5743, 54.0461],
            [62.582, 54.0353],
            [62.5807, 54.0111],
            [62.5945, 54.0021],
            [62.5993, 53.9831],
            [62.596308, 53.953068],
            [62.5543, 53.9149],
            [62.5469, 53.8978],
            [62.5163, 53.9057],
            [62.478, 53.9279],
            [62.4445, 53.9286],
            [62.3963, 54.0004],
            [62.4039, 54.0049],
            [62.4663, 54.0151],
            [62.4935, 54.0332],
            [62.414421, 54.047681],
            [62.391305, 54.046834],
            [62.335692, 54.03178],
            [62.227144, 54.033978],
            [62.174127, 54.028653],
            [62.130557, 54.041062],
            [62.090031, 54.043071],
            [62.076807, 54.05013],
            [62.068386, 54.039836],
            [62.037144, 54.034389],
            [62.013418, 54.039685],
            [62.006791, 54.030387],
            [62.038502, 53.96404],
            [62.037044, 53.947999],
            [61.93106, 53.94957],
            [61.930444, 53.954302],
            [61.857614, 53.962046],
            [61.871663, 53.973265],
            [61.893938, 54.00885],
            [61.876895, 54.015846],
            [61.846502, 54.009199],
            [61.850881, 54.031646],
            [61.825192, 54.024348],
            [61.782076, 53.984424],
            [61.747516, 53.998426],
            [61.729144, 54.030036],
            [61.711263, 54.021378],
            [61.66681, 54.016941],
            [61.660373, 54.029983],
            [61.614028, 53.989676],
            [61.612965, 53.968713],
            [61.571867, 53.968503],
            [61.569781, 54.007875],
            [61.4894, 54.0275],
            [61.4886, 54.0517],
            [61.474209, 54.082722],
            [61.416701, 54.082943],
            [61.406802, 54.086849],
            [61.361, 54.0725],
            [61.3334, 54.0756],
            [61.294, 54.0671],
            [61.2751, 54.0444],
            [61.246328, 54.037192],
            [61.2433, 54.02837],
            [61.2773, 53.9914],
            [61.266, 53.9697],
            [61.2807, 53.9456],
            [61.2799, 53.9269],
            [61.2164, 53.9168],
            [61.2072, 53.9185],
            [61.1757, 53.9439],
            [61.1866, 53.9721],
            [61.099897, 53.974818],
            [61.080669, 53.970525],
            [61.088105, 53.956543],
            [61.079991, 53.94386],
            [61.065579, 53.943466],
            [61.055394, 53.957887],
            [61.037785, 53.958659],
            [60.998162, 53.936906],
            [60.9992, 53.9072],
            [61.0342, 53.9077],
            [61.0697, 53.8957],
            [61.137479, 53.904418],
            [61.180103, 53.852556],
            [61.207869, 53.847119],
            [61.211452, 53.837801],
            [61.237328, 53.841701],
            [61.22659, 53.794853],
            [61.1769, 53.7742],
            [61.1434, 53.7439],
            [61.1336, 53.7259],
            [61.1156, 53.7203],
            [61.0626, 53.7212],
            [61.0553, 53.6825],
            [61.0444, 53.6553],
            [61.0022, 53.6519],
            [60.9982, 53.6734],
            [60.9001, 53.6682],
            [60.9037, 53.6249],
            [60.9349, 53.6211],
            [61.034, 53.62],
            [61.1249, 53.6153],
            [61.132, 53.5893],
            [61.1812, 53.5709],
            [61.2223, 53.5627],
            [61.2871, 53.5645],
            [61.3402, 53.5572],
            [61.3627, 53.5619],
            [61.3958, 53.6039],
            [61.4806, 53.5977],
            [61.5066, 53.5901],
            [61.5579, 53.5907],
            [61.5687, 53.5404],
            [61.5868, 53.5387],
            [61.5963, 53.5254],
            [61.5667, 53.5089],
            [61.5701, 53.4965],
            [61.5397, 53.5059],
            [61.522, 53.494],
            [61.5214, 53.469],
            [61.4956, 53.4748],
            [61.4733, 53.4655],
            [61.4566, 53.4689],
            [61.4239, 53.4586],
            [61.3952, 53.46],
            [61.3692, 53.4713],
            [61.3546, 53.4972],
            [61.3195, 53.5093],
            [61.3251, 53.5184],
            [61.2593, 53.5057],
            [61.2272, 53.481],
            [61.2228, 53.4423],
            [61.2157, 53.4314],
            [61.1582, 53.4036],
            [61.1724, 53.3535],
            [61.1861, 53.3492],
            [61.1791, 53.3374],
            [61.1852, 53.2997],
            [61.2083, 53.2857],
            [61.2325, 53.2807],
            [61.2804, 53.2823],
            [61.3704, 53.2808],
            [61.4022, 53.2723],
            [61.4544, 53.2397],
            [61.5241, 53.2156],
            [61.5391, 53.2147],
            [61.6332, 53.2221],
            [61.6428, 53.251],
            [61.6619, 53.262],
            [61.6829, 53.2624],
            [61.7058, 53.2481],
            [61.7284, 53.2439],
            [61.7443, 53.2126],
            [61.787, 53.1825],
            [61.8173, 53.1709],
            [61.8609, 53.1616],
            [61.9257, 53.1407],
            [62.0243, 53.1415],
            [62.1115, 53.1161],
            [62.1251, 53.1073],
            [62.147035, 53.0683],
            [62.132, 52.9887],
            [62.0331, 52.9511],
            [61.961261, 52.949689],
            [61.8517, 53.0007],
            [61.8386, 52.9896],
            [61.8116, 52.9951],
            [61.7715, 52.9943],
            [61.7329, 52.9878],
            [61.7001, 52.991],
            [61.6784, 52.9683],
            [61.6342, 52.9535],
            [61.6036, 52.9801],
            [61.5655, 53.0066],
            [61.5221, 53.0132],
            [61.4993, 53.0255],
            [61.4662, 53.035],
            [61.3962, 52.9918],
            [61.3844, 52.9899],
            [61.3157, 52.9952],
            [61.2591, 53.0312],
            [61.2148, 53.0199],
            [61.196, 53.0078],
            [61.1444, 52.9934],
            [61.1212, 52.9797],
            [61.113, 52.9636],
            [61.0618, 52.9768],
            [61.0231, 52.9754],
            [61.0378, 52.946],
            [61.0712, 52.9331],
            [61.0234, 52.9035],
            [60.9826, 52.8867],
            [60.9542, 52.8574],
            [60.8899, 52.8374],
            [60.8784, 52.8282],
            [60.8835, 52.8148],
            [60.8365, 52.7771],
            [60.7513, 52.7619],
            [60.7143, 52.7605],
            [60.7159, 52.6733],
            [60.7195, 52.6606],
            [60.7558, 52.6455],
            [60.8305, 52.6287],
            [60.8436, 52.6317],
            [60.841464, 52.521976],
            [60.980854, 52.50754],
            [60.990466, 52.424097],
            [61.0658, 52.3495],
            [61.0432, 52.3294],
            [61.0128, 52.3217],
            [60.9737, 52.2779],
            [60.9393, 52.2602],
            [60.8541, 52.2362],
            [60.8399, 52.2261],
            [60.802, 52.2236],
            [60.7857, 52.227],
            [60.7554, 52.2189],
            [60.7383, 52.2089],
            [60.6109, 52.156],
            [60.5303, 52.1571],
            [60.5015, 52.1485],
            [60.2714, 52.0165],
            [60.2527, 51.997],
            [60.2011, 51.9831],
            [60.1618, 51.9824],
            [60.1427, 51.9898],
            [60.0988, 51.9888],
            [60.011895, 51.995809],
            [60.0096, 51.9938],
            [59.999883, 51.996788],
            [59.975567, 52.033569],
            [59.907917, 52.040168],
            [59.919466, 52.064259],
            [59.905441, 52.082243],
            [59.933078, 52.084966],
            [59.968139, 52.151874],
            [60.016071, 52.175552],
            [60.056826, 52.176543],
            [60.055011, 52.182318],
            [60.082565, 52.216143],
            [60.122908, 52.221671],
            [60.144357, 52.244771],
            [60.173892, 52.260282],
            [60.160155, 52.282928],
            [60.162998, 52.400119],
            [60.076207, 52.411503],
            [60.037555, 52.422929],
            [60.021138, 52.413442],
            [60.003771, 52.41967],
            [59.918259, 52.42202],
            [59.860137, 52.464631],
            [59.824166, 52.480058],
            [59.733458, 52.434063],
            [59.734036, 52.422596],
            [59.677565, 52.408694],
            [59.669479, 52.423874],
            [59.672531, 52.438972],
            [59.658279, 52.441261],
            [59.547646, 52.442167],
            [59.516668, 52.404753],
            [59.501736, 52.398978],
            [59.489195, 52.42744],
            [59.443282, 52.457428],
            [59.414531, 52.460852],
            [59.412303, 52.484694],
            [59.391513, 52.469184],
            [59.370021, 52.472443],
            [59.312395, 52.471081],
            [59.296142, 52.479826],
            [59.249241, 52.476566],
            [59.244538, 52.463201],
            [59.250108, 52.43643],
            [59.227421, 52.42488],
            [59.230269, 52.352898],
            [59.217112, 52.304759],
            [59.187577, 52.284794],
            [59.165674, 52.276502],
            [59.076471, 52.33243],
            [59.057062, 52.346978],
            [59.029422, 52.348482],
            [59.001908, 52.361888],
            [59.007538, 52.385834],
            [58.989139, 52.408274],
            [59.001885, 52.42465],
            [58.955313, 52.433518],
            [58.953251, 52.440118],
            [58.901069, 52.447419],
            [58.898511, 52.443294],
            [58.859035, 52.4468],
            [58.854927, 52.425806],
            [58.796148, 52.431792],
            [58.79785, 52.468617],
            [58.770178, 52.471171],
            [58.781245, 52.520394],
            [58.838291, 52.520821],
            [58.838928, 52.566],
            [58.824027, 52.593299],
            [58.781235, 52.592084],
            [58.781029, 52.610593],
            [58.751016, 52.611018],
            [58.751015, 52.6579],
            [58.776771, 52.661519],
            [58.78571, 52.680091],
            [58.776557, 52.690308],
            [58.804657, 52.695086],
            [58.779534, 52.787318],
            [58.762717, 52.810626],
            [58.7608, 52.849632],
            [58.802945, 52.896994],
            [58.812309, 52.94031],
            [58.861054, 52.944409],
            [58.839127, 53.024017],
            [58.989132, 53.019652],
            [58.991746, 53.041952],
            [58.975995, 53.048551],
            [58.913414, 53.056053],
            [58.911284, 53.091654],
            [58.911068, 53.191218],
            [58.906597, 53.21144],
            [58.872275, 53.201817],
            [58.875305, 53.27668],
            [58.90021, 53.276521],
            [58.897223, 53.49353],
            [58.907014, 53.493796],
            [58.906374, 53.547702],
            [58.81378, 53.548606],
            [58.813353, 53.591603],
            [58.822129, 53.608293],
            [58.839787, 53.611418],
            [58.906865, 53.642514],
            [58.912543, 53.678185],
            [58.891255, 53.719639],
            [58.885933, 53.748587],
            [58.904026, 53.751993],
            [58.925523, 53.784827],
            [58.907744, 53.862662],
            [58.939994, 53.89679],
            [58.964898, 53.907859],
            [58.954468, 53.923664],
            [58.934033, 53.931539],
            [58.910619, 53.928772],
            [58.939566, 53.963096],
            [58.952125, 53.967353],
            [59.000232, 53.951602],
            [59.051317, 53.97076],
            [59.090483, 53.967355],
            [59.10879, 53.956286],
            [59.140931, 53.972677],
            [59.188185, 54.003595],
            [59.192228, 54.024508],
            [59.224794, 54.065218],
            [59.236501, 54.069688],
            [59.284818, 54.149883],
            [59.309296, 54.162442],
            [59.325654, 54.187793],
            [59.3491, 54.177395],
            [59.378901, 54.172926],
            [59.399122, 54.183569],
            [59.470643, 54.187614],
            [59.525348, 54.186125],
            [59.600488, 54.159731],
            [59.655832, 54.150366],
            [59.661792, 54.14249],
            [59.707982, 54.141639],
            [59.705427, 54.179954],
            [59.715218, 54.19512],
            [59.776307, 54.226464],
            [59.789291, 54.244344],
            [59.755019, 54.314268],
            [59.67179, 54.33965],
            [59.725855, 54.384883],
            [59.736071, 54.400688],
            [59.739901, 54.455659],
            [59.670295, 54.469334],
            [59.646489, 54.484027],
            [59.666889, 54.490673],
            [59.680299, 54.504296],
            [59.675402, 54.518558],
            [59.689025, 54.521804],
            [59.726913, 54.551658],
            [59.737343, 54.549316],
            [59.79226, 54.572306],
            [59.853775, 54.609024],
            [59.897196, 54.684643],
            [59.897621, 54.704917],
            [59.947215, 54.770372],
            [59.929122, 54.78889],
            [59.938274, 54.817892],
            [59.99319, 54.853281],
            [60.001809, 54.864135],
            [59.915283, 54.869936],
            [59.875479, 54.838432],
            [59.857599, 54.851842],
            [59.842486, 54.841838],
            [59.776712, 54.860409],
            [59.76394, 54.87318],
            [59.736906, 54.885739],
            [59.697314, 54.885951],
            [59.659425, 54.90282],
            [59.660276, 54.907928],
            [59.617976, 54.906111],
            [59.60089, 54.867006],
            [59.53682, 54.841409],
            [59.520005, 54.795591],
            [59.522773, 54.779786],
            [59.486588, 54.762544],
            [59.44572, 54.736947],
            [59.441463, 54.720717],
            [59.381011, 54.702623],
            [59.288845, 54.658241],
            [59.291613, 54.636689],
            [59.263303, 54.616893],
            [59.235206, 54.623013],
            [59.150062, 54.62812],
            [59.065343, 54.649671],
            [59.001486, 54.618327],
            [58.953697, 54.584371],
            [58.919749, 54.583151],
            [58.908468, 54.57187],
            [58.839076, 54.566973],
            [58.805871, 54.550583],
            [58.77458, 54.552498],
            [58.739246, 54.536906],
            [58.714982, 54.47789],
            [58.617068, 54.462723],
            [58.587267, 54.464],
            [58.498716, 54.513701],
            [58.436134, 54.543926],
            [58.421872, 54.558826],
            [58.38526, 54.560103],
            [58.257121, 54.514497],
            [58.163463, 54.510238],
            [58.16921, 54.502576],
            [58.118338, 54.46011],
            [58.012124, 54.396783],
            [57.983601, 54.396357],
            [57.901312, 54.420698],
            [57.896966, 54.429615],
            [57.856522, 54.456967],
            [57.826507, 54.48549],
            [57.753361, 54.501668],
            [57.747535, 54.54328],
            [57.762647, 54.563129],
            [57.738999, 54.574982],
            [57.746043, 54.58186],
            [57.627054, 54.617672],
            [57.603152, 54.67859],
            [57.592355, 54.679347],
            [57.520623, 54.65375],
            [57.484862, 54.665244],
            [57.504232, 54.675462],
            [57.516151, 54.692278],
            [57.454209, 54.71617],
            [57.395813, 54.71987],
            [57.384603, 54.735167],
            [57.244966, 54.772469],
            [57.201329, 54.80445],
            [57.162799, 54.848777],
            [57.143324, 54.851586],
            [57.156626, 54.870329],
            [57.188767, 54.887624],
            [57.259434, 54.950259],
            [57.259646, 54.960529],
            [57.214094, 54.972236],
            [57.204515, 54.982666],
            [57.187699, 54.977131],
            [57.192594, 55.006506],
            [57.180035, 55.014222],
            [57.184717, 55.031889],
            [57.168965, 55.040456],
            [57.164282, 55.058177],
            [57.131288, 55.085049],
            [57.156404, 55.127249],
            [57.210682, 55.135125],
            [57.241546, 55.1496],
            [57.259851, 55.179028],
            [57.233031, 55.179719],
            [57.212596, 55.200845],
            [57.211956, 55.23022],
            [57.22047, 55.236606],
            [57.239841, 55.214894],
            [57.263255, 55.219152],
            [57.232603, 55.246397],
            [57.246225, 55.266033],
            [57.292415, 55.26646],
            [57.30391, 55.25752],
            [57.326047, 55.259649],
            [57.339883, 55.279711],
            [57.380751, 55.294611],
            [57.393947, 55.317228],
            [57.420129, 55.316164],
            [57.457592, 55.326381],
            [57.507614, 55.327446],
            [57.530101, 55.337019],
            [57.568066, 55.319358],
            [57.578071, 55.319571],
            [57.641291, 55.301479],
            [57.721326, 55.304247],
            [57.780501, 55.298075],
            [57.882886, 55.302333],
            [57.955258, 55.291691],
            [57.969733, 55.28041],
            [58.008686, 55.282699],
            [58.023161, 55.276526],
            [58.043919, 55.238855],
            [58.078719, 55.221556],
            [58.126613, 55.217725],
            [58.136618, 55.211127],
            [58.131935, 55.194045],
            [58.117674, 55.191703],
            [58.080424, 55.170789],
            [58.041259, 55.153973],
            [58.005072, 55.163764],
            [57.987618, 55.149662],
            [58.005712, 55.142425],
            [57.98891, 55.112652],
            [58.007058, 55.102993],
            [57.991026, 55.091233],
            [58.04041, 55.068191],
            [58.029441, 55.059835],
            [58.023383, 55.038976],
            [58.032749, 55.027908],
            [58.008779, 54.99885],
            [58.027003, 54.991296],
            [57.999332, 54.977886],
            [57.983014, 54.984433],
            [57.958889, 54.975916],
            [57.952929, 54.960112],
            [57.932921, 54.948617],
            [57.963786, 54.921106],
            [57.982943, 54.927917],
            [58.005081, 54.906153],
            [58.058722, 54.919989],
            [58.080007, 54.929994],
            [58.082774, 54.946511],
            [58.117319, 54.938628],
            [58.131944, 54.952238],
            [58.125557, 54.975493],
            [58.101504, 54.98422],
            [58.130665, 54.999971],
            [58.087454, 55.012955],
            [58.128323, 55.017213],
            [58.158761, 55.031315],
            [58.179834, 55.031315],
            [58.176428, 55.040681],
            [58.153014, 55.037062],
            [58.166211, 55.050047],
            [58.15131, 55.059412],
            [58.193243, 55.070535],
            [58.195584, 55.096184],
            [58.163868, 55.094694],
            [58.153651, 55.083997],
            [58.137047, 55.097407],
            [58.158333, 55.10805],
            [58.128532, 55.127048],
            [58.167698, 55.131518],
            [58.190899, 55.149824],
            [58.210057, 55.142374],
            [58.231129, 55.151368],
            [58.245816, 55.170525],
            [58.271146, 55.171377],
            [58.305843, 55.162437],
            [58.362464, 55.140886],
            [58.370979, 55.119813],
            [58.346926, 55.103849],
            [58.373321, 55.09592],
            [58.400355, 55.07953],
            [58.379285, 55.065779],
            [58.42973, 55.06628],
            [58.439522, 55.048134],
            [58.422069, 55.017908],
            [58.453279, 54.99672],
            [58.475284, 55.001678],
            [58.501678, 55.020623],
            [58.531053, 55.027435],
            [58.548933, 55.023604],
            [58.525519, 54.994601],
            [58.493543, 54.98441],
            [58.529352, 54.972677],
            [58.566836, 54.970946],
            [58.59768, 54.956288],
            [58.621954, 54.964708],
            [58.652717, 54.958664],
            [58.694956, 54.967996],
            [58.727088, 54.967864],
            [58.730947, 54.996176],
            [58.806493, 55.028715],
            [58.823733, 55.053886],
            [58.798403, 55.055589],
            [58.771552, 55.077121],
            [58.761151, 55.094807],
            [58.731442, 55.10189],
            [58.733479, 55.12019],
            [58.641309, 55.147062],
            [58.624068, 55.140464],
            [58.587514, 55.146702],
            [58.597163, 55.161379],
            [58.57545, 55.162091],
            [58.582985, 55.177767],
            [58.608447, 55.181493],
            [58.615316, 55.194679],
            [58.652377, 55.192295],
            [58.644906, 55.172941],
            [58.657486, 55.161164],
            [58.679197, 55.173298],
            [58.692395, 55.171808],
            [58.693034, 55.150735],
            [58.723899, 55.138602],
            [58.726648, 55.152116],
            [58.755401, 55.16372],
            [58.773067, 55.212571],
            [58.693031, 55.23194],
            [58.70942, 55.279674],
            [58.725384, 55.289678],
            [58.83075, 55.27776],
            [58.931858, 55.272014],
            [58.933335, 55.294488],
            [58.865658, 55.290372],
            [58.837773, 55.293352],
            [58.846925, 55.326824],
            [58.911422, 55.326399],
            [58.930579, 55.317033],
            [58.965275, 55.315969],
            [58.966394, 55.262515],
            [59.006998, 55.265256],
            [59.014873, 55.283349],
            [59.034243, 55.277177],
            [59.05638, 55.297452],
            [59.074686, 55.291918],
            [59.086392, 55.325815],
            [59.104485, 55.32241],
            [59.101292, 55.33284],
            [59.124706, 55.342632],
            [59.162596, 55.333266],
            [59.160041, 55.342419],
            [59.176856, 55.363545],
            [59.184305, 55.38696],
            [59.144287, 55.412556],
            [59.150033, 55.425753],
            [59.170255, 55.423837],
            [59.183026, 55.441984],
            [59.183238, 55.468431],
            [59.200693, 55.465452],
            [59.281366, 55.463963],
            [59.361614, 55.484025],
            [59.372895, 55.478065],
            [59.380345, 55.498074],
            [59.433693, 55.493994],
            [59.431006, 55.477002],
            [59.464074, 55.473619],
            [59.483794, 55.499565],
            [59.517, 55.509357],
            [59.578942, 55.516595],
            [59.587243, 55.54432],
            [59.642798, 55.557571],
            [59.65493, 55.591895],
            [59.601502, 55.60573],
            [59.564465, 55.594022],
            [59.468494, 55.594828],
            [59.461653, 55.602322],
            [59.471444, 55.627067],
            [59.464419, 55.643457],
            [59.390982, 55.644734],
            [59.382895, 55.620681],
            [59.335427, 55.609558],
            [59.275828, 55.579757],
            [59.249646, 55.58146],
            [59.250497, 55.607003],
            [59.276465, 55.606152],
            [59.290939, 55.621957],
            [59.335809, 55.63157],
            [59.316268, 55.67714],
            [59.288809, 55.677991],
            [59.298611, 55.701453],
            [59.27561, 55.709122],
            [59.320522, 55.761379],
            [59.303705, 55.775694],
            [59.27135, 55.788039],
            [59.201532, 55.787825],
            [59.187909, 55.793359],
            [59.135546, 55.789102],
            [59.133416, 55.820019],
            [59.193902, 55.820163],
            [59.192378, 55.828321],
            [59.221148, 55.85022],
            [59.206638, 55.864774],
            [59.203019, 55.885474],
            [59.233031, 55.902982],
            [59.246653, 55.924321],
            [59.24644, 55.9488],
            [59.216213, 55.951833],
            [59.221959, 55.98871],
            [59.192583, 56.025162],
            [59.286454, 56.034742],
            [59.308165, 56.04629],
            [59.315827, 56.069917],
            [59.33541, 56.085935],
            [59.314337, 56.085509],
            [59.334771, 56.102165],
            [59.319231, 56.125633],
            [59.335833, 56.149686],
            [59.330086, 56.156337],
            [59.281128, 56.155698],
            [59.265164, 56.141809],
            [59.267293, 56.129038],
            [59.239408, 56.129463],
            [59.189173, 56.138615],
            [59.162992, 56.130314],
            [59.152774, 56.145639],
            [59.097005, 56.149044],
            [59.061669, 56.165487],
            [59.04205, 56.168357],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5531",
      properties: {
        name: "Чеченская республика",
        density: 0,
        path: "/world/Russia/Чеченская республика",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.83243, 43.681956],
            [44.853345, 43.695525],
            [44.861237, 43.692085],
            [44.851929, 43.673265],
            [44.858, 43.664563],
            [44.883397, 43.65829],
            [44.90849, 43.668206],
            [44.908895, 43.680348],
            [44.936821, 43.672456],
            [44.95645, 43.681361],
            [44.962319, 43.697145],
            [44.978306, 43.700181],
            [44.979317, 43.711311],
            [45.014124, 43.726894],
            [45.040634, 43.720418],
            [45.042455, 43.708681],
            [45.063143, 43.718963],
            [45.06581, 43.888166],
            [45.053366, 43.889072],
            [45.054674, 43.958372],
            [45.201686, 43.956407],
            [45.200171, 43.876309],
            [45.437874, 43.875638],
            [45.438681, 43.983904],
            [45.441289, 44.009733],
            [45.487471, 44.010095],
            [45.522159, 44.007663],
            [45.712798, 43.96295],
            [45.718551, 43.953318],
            [45.795809, 43.952382],
            [45.795809, 43.970576],
            [45.858819, 43.97138],
            [45.886111, 43.951179],
            [45.99628, 43.940043],
            [46.071198, 43.930546],
            [46.058021, 43.885395],
            [46.034743, 43.887936],
            [46.002636, 43.880979],
            [46.044043, 43.819843],
            [46.078291, 43.817167],
            [46.079093, 43.841114],
            [46.095147, 43.840211],
            [46.094878, 43.861081],
            [46.215883, 43.859209],
            [46.250131, 43.881952],
            [46.252002, 43.944929],
            [46.358893, 43.94386],
            [46.342571, 43.971017],
            [46.343106, 43.988944],
            [46.325313, 43.998442],
            [46.320363, 44.009813],
            [46.437555, 44.002992],
            [46.427789, 43.985734],
            [46.442305, 43.968276],
            [46.432004, 43.954898],
            [46.445115, 43.952089],
            [46.461035, 43.931487],
            [46.448326, 43.919313],
            [46.445518, 43.878376],
            [46.452608, 43.872891],
            [46.491538, 43.878811],
            [46.493679, 43.859413],
            [46.535553, 43.858745],
            [46.553345, 43.86811],
            [46.58572, 43.872391],
            [46.603514, 43.850184],
            [46.616156, 43.849883],
            [46.618966, 43.829147],
            [46.649831, 43.829716],
            [46.647426, 43.814857],
            [46.655132, 43.793539],
            [46.614151, 43.765869],
            [46.590271, 43.76821],
            [46.548666, 43.75443],
            [46.537429, 43.733092],
            [46.513616, 43.70473],
            [46.478307, 43.683427],
            [46.474696, 43.674092],
            [46.455128, 43.663141],
            [46.446434, 43.642157],
            [46.453846, 43.631252],
            [46.440974, 43.618552],
            [46.426559, 43.616077],
            [46.426526, 43.601097],
            [46.41331, 43.590019],
            [46.430784, 43.574892],
            [46.424921, 43.551785],
            [46.433226, 43.536246],
            [46.413585, 43.530146],
            [46.411179, 43.509929],
            [46.40079, 43.498612],
            [46.38127, 43.49494],
            [46.386722, 43.479869],
            [46.355026, 43.470087],
            [46.325255, 43.451031],
            [46.333018, 43.405239],
            [46.351941, 43.383744],
            [46.367483, 43.341315],
            [46.390278, 43.337503],
            [46.408573, 43.323941],
            [46.413791, 43.304008],
            [46.422286, 43.298322],
            [46.421217, 43.27379],
            [46.407505, 43.255797],
            [46.408475, 43.233205],
            [46.390215, 43.202435],
            [46.423927, 43.201048],
            [46.421185, 43.19088],
            [46.435968, 43.186131],
            [46.407273, 43.159726],
            [46.385467, 43.148154],
            [46.395969, 43.129157],
            [46.419648, 43.122619],
            [46.419113, 43.106432],
            [46.434699, 43.084893],
            [46.488178, 43.088222],
            [46.505235, 43.085379],
            [46.499884, 43.073406],
            [46.517276, 43.065446],
            [46.524032, 43.041767],
            [46.496908, 43.037302],
            [46.519986, 43.029744],
            [46.504802, 43.016767],
            [46.469618, 43.015362],
            [46.478715, 42.994241],
            [46.494435, 42.975512],
            [46.491927, 42.950445],
            [46.531259, 42.923288],
            [46.524838, 42.917134],
            [46.490389, 42.912268],
            [46.466309, 42.897485],
            [46.435239, 42.88628],
            [46.446209, 42.878588],
            [46.429152, 42.859725],
            [46.391426, 42.854105],
            [46.366744, 42.841864],
            [46.367747, 42.863403],
            [46.334936, 42.894072],
            [46.306909, 42.90959],
            [46.308849, 42.886045],
            [46.300488, 42.86489],
            [46.272494, 42.884606],
            [46.25497, 42.865943],
            [46.238816, 42.859789],
            [46.219484, 42.869689],
            [46.207711, 42.889889],
            [46.184333, 42.886812],
            [46.186273, 42.873501],
            [46.120655, 42.81539],
            [46.114769, 42.803082],
            [46.147579, 42.789253],
            [46.16477, 42.76738],
            [46.160657, 42.747664],
            [46.177647, 42.742848],
            [46.170155, 42.731878],
            [46.133533, 42.722095],
            [46.108984, 42.72069],
            [46.093867, 42.712563],
            [46.073131, 42.717445],
            [46.084436, 42.694235],
            [46.072731, 42.682194],
            [46.042128, 42.682495],
            [46.013165, 42.676742],
            [46.004771, 42.640973],
            [46.035407, 42.617963],
            [46.010925, 42.612344],
            [46.005374, 42.598648],
            [45.96136, 42.579049],
            [45.936978, 42.599383],
            [45.924804, 42.603263],
            [45.911226, 42.588613],
            [45.894971, 42.583262],
            [45.906076, 42.554633],
            [45.879855, 42.536104],
            [45.86039, 42.534566],
            [45.841594, 42.515836],
            [45.80835, 42.498779],
            [45.772822, 42.489153],
            [45.721932, 42.475502],
            [45.702033, 42.482222],
            [45.687512, 42.503237],
            [45.666025, 42.506208],
            [45.650735, 42.499358],
            [45.63302, 42.510646],
            [45.613604, 42.505861],
            [45.59742, 42.517434],
            [45.596781, 42.531085],
            [45.571877, 42.548841],
            [45.542558, 42.538065],
            [45.522161, 42.545098],
            [45.493319, 42.534989],
            [45.452873, 42.545734],
            [45.435922, 42.541136],
            [45.417998, 42.551894],
            [45.404444, 42.549213],
            [45.396215, 42.537072],
            [45.353145, 42.522086],
            [45.333492, 42.527377],
            [45.325301, 42.549067],
            [45.325539, 42.565217],
            [45.308634, 42.569358],
            [45.28157, 42.590412],
            [45.285032, 42.606938],
            [45.257467, 42.605752],
            [45.250607, 42.622713],
            [45.216703, 42.660485],
            [45.194156, 42.66944],
            [45.16478, 42.692687],
            [45.158036, 42.708165],
            [45.132131, 42.70979],
            [45.110223, 42.704922],
            [45.086794, 42.712221],
            [45.089499, 42.727476],
            [45.117422, 42.752656],
            [45.141653, 42.764649],
            [45.149575, 42.776272],
            [45.176431, 42.780111],
            [45.183847, 42.795998],
            [45.197038, 42.804507],
            [45.199209, 42.824666],
            [45.169194, 42.82898],
            [45.206439, 42.85827],
            [45.216134, 42.876305],
            [45.242798, 42.890254],
            [45.2493, 42.909473],
            [45.246863, 42.93158],
            [45.214988, 42.94261],
            [45.214626, 42.970617],
            [45.193972, 42.977908],
            [45.183265, 43.008968],
            [45.193695, 43.036113],
            [45.179733, 43.059249],
            [45.195959, 43.097012],
            [45.180624, 43.095714],
            [45.192105, 43.116251],
            [45.189526, 43.137981],
            [45.17308, 43.150286],
            [45.149602, 43.149519],
            [45.149952, 43.17048],
            [45.131029, 43.197564],
            [45.133132, 43.227191],
            [45.114909, 43.262417],
            [45.112456, 43.295072],
            [45.104046, 43.322612],
            [45.124021, 43.324906],
            [45.124371, 43.342239],
            [45.135585, 43.346571],
            [45.122969, 43.360585],
            [45.14014, 43.400318],
            [45.158521, 43.411035],
            [45.131748, 43.418404],
            [45.100526, 43.450972],
            [45.067942, 43.451871],
            [45.059517, 43.477679],
            [45.089891, 43.482623],
            [45.078126, 43.48685],
            [45.066798, 43.508619],
            [45.023689, 43.512516],
            [45.016275, 43.522807],
            [44.975471, 43.529216],
            [44.955539, 43.519089],
            [44.909396, 43.527833],
            [44.901526, 43.519392],
            [44.874142, 43.534042],
            [44.867364, 43.558494],
            [44.847608, 43.56497],
            [44.847463, 43.578175],
            [44.83243, 43.681956],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5511",
      properties: {
        name: "Чувашия",
        density: 0,
        path: "/world/Russia/Чувашия",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [48.050632, 54.989088],
              [48.05238, 55.00272],
              [48.113203, 55.024608],
              [48.119588, 55.013018],
              [48.099905, 54.998922],
              [48.102709, 54.991061],
              [48.050632, 54.989088],
            ],
          ],
          [
            [
              [47.290809, 54.674267],
              [47.370814, 54.697904],
              [47.365012, 54.672528],
              [47.373521, 54.655339],
              [47.391561, 54.653039],
              [47.405286, 54.638393],
              [47.355589, 54.627395],
              [47.330579, 54.656757],
              [47.334299, 54.667393],
              [47.290809, 54.674267],
            ],
          ],
          [
            [
              [47.8373, 54.768253],
              [47.850659, 54.773181],
              [47.85386, 54.741635],
              [47.8373, 54.768253],
            ],
          ],
          [
            [
              [47.819619, 54.903472],
              [47.822454, 54.913742],
              [47.842827, 54.909117],
              [47.819619, 54.903472],
            ],
          ],
          [
            [
              [46.185308, 56.031569],
              [46.202034, 56.032768],
              [46.200673, 56.045446],
              [46.238717, 56.06547],
              [46.30614, 56.072167],
              [46.321702, 56.086495],
              [46.38668, 56.108821],
              [46.414245, 56.129863],
              [46.44351, 56.112973],
              [46.48709, 56.124691],
              [46.516744, 56.122578],
              [46.516558, 56.113211],
              [46.586121, 56.132553],
              [46.619727, 56.127174],
              [46.655615, 56.099032],
              [46.706778, 56.123864],
              [46.734628, 56.129107],
              [46.760435, 56.155916],
              [46.754427, 56.163369],
              [46.796975, 56.190563],
              [46.787242, 56.203416],
              [46.76911, 56.199189],
              [46.753424, 56.206976],
              [46.775227, 56.210869],
              [46.822506, 56.193294],
              [46.918395, 56.213096],
              [46.934079, 56.225889],
              [46.985472, 56.243911],
              [46.984582, 56.252477],
              [47.042649, 56.270276],
              [47.091483, 56.280066],
              [47.146882, 56.277397],
              [47.150551, 56.314996],
              [47.247665, 56.311549],
              [47.251891, 56.329126],
              [47.392278, 56.324122],
              [47.380268, 56.227231],
              [47.43233, 56.225229],
              [47.425881, 56.156816],
              [47.480167, 56.150921],
              [47.50742, 56.159486],
              [47.51899, 56.144692],
              [47.562597, 56.14091],
              [47.602978, 56.143135],
              [47.653148, 56.131233],
              [47.672726, 56.141913],
              [47.742475, 56.130456],
              [47.759383, 56.135685],
              [47.844038, 56.126453],
              [47.872628, 56.11811],
              [47.878968, 56.125786],
              [47.909226, 56.115329],
              [47.933033, 56.092303],
              [47.918461, 56.079065],
              [47.931256, 56.03468],
              [47.926942, 56.01401],
              [47.952679, 56.01956],
              [47.995555, 55.998416],
              [48.005901, 55.98885],
              [47.983319, 55.976168],
              [48.047062, 55.945689],
              [48.053736, 55.953809],
              [48.096342, 55.939571],
              [48.169206, 55.935567],
              [48.234728, 55.909427],
              [48.222827, 55.876277],
              [48.25709, 55.85859],
              [48.285568, 55.854808],
              [48.322389, 55.835341],
              [48.347642, 55.830113],
              [48.406167, 55.825699],
              [48.395434, 55.825896],
              [48.395095, 55.808846],
              [48.420602, 55.793605],
              [48.412517, 55.783752],
              [48.361696, 55.77266],
              [48.346176, 55.777868],
              [48.337433, 55.769164],
              [48.309992, 55.781634],
              [48.266329, 55.78055],
              [48.28179, 55.761588],
              [48.237683, 55.748679],
              [48.251925, 55.735469],
              [48.232289, 55.712948],
              [48.241025, 55.706249],
              [48.23507, 55.689446],
              [48.222262, 55.687399],
              [48.145872, 55.693047],
              [48.128601, 55.688965],
              [48.135665, 55.673297],
              [48.129499, 55.646347],
              [48.110249, 55.651781],
              [48.108215, 55.690318],
              [48.083966, 55.694768],
              [48.086901, 55.650081],
              [48.033466, 55.650973],
              [48.034203, 55.637255],
              [48.010683, 55.629275],
              [48.013511, 55.599305],
              [48.028512, 55.606866],
              [48.05501, 55.607523],
              [48.082042, 55.616307],
              [48.067124, 55.595799],
              [48.071819, 55.585401],
              [48.09899, 55.5774],
              [48.103694, 55.568879],
              [48.136495, 55.573593],
              [48.147589, 55.565033],
              [48.108465, 55.546373],
              [48.107967, 55.524123],
              [48.080575, 55.521233],
              [48.07074, 55.528646],
              [48.05649, 55.513454],
              [48.039149, 55.524643],
              [47.969208, 55.51546],
              [47.976795, 55.491518],
              [47.928423, 55.475212],
              [47.912739, 55.429404],
              [47.897568, 55.429401],
              [47.89266, 55.414043],
              [47.872987, 55.412889],
              [47.876666, 55.392661],
              [47.860835, 55.391617],
              [47.838792, 55.373605],
              [47.802951, 55.366395],
              [47.808679, 55.3511],
              [47.780374, 55.333057],
              [47.75588, 55.337979],
              [47.741199, 55.352127],
              [47.70341, 55.349249],
              [47.690989, 55.361437],
              [47.645109, 55.33553],
              [47.662802, 55.33527],
              [47.683704, 55.323971],
              [47.684911, 55.30616],
              [47.699566, 55.285032],
              [47.687218, 55.233977],
              [47.66852, 55.230648],
              [47.669943, 55.214213],
              [47.703214, 55.215842],
              [47.709326, 55.230822],
              [47.737754, 55.238215],
              [47.783703, 55.23867],
              [47.789585, 55.271656],
              [47.80032, 55.281833],
              [47.788535, 55.29777],
              [47.804031, 55.302955],
              [47.822929, 55.279783],
              [47.865833, 55.268256],
              [47.866865, 55.254228],
              [47.901726, 55.262231],
              [47.864214, 55.284068],
              [47.873809, 55.304521],
              [47.872431, 55.321496],
              [47.89441, 55.315475],
              [47.911613, 55.322252],
              [47.96023, 55.304534],
              [47.976536, 55.303477],
              [48.016183, 55.28475],
              [48.004708, 55.266106],
              [48.012912, 55.238265],
              [48.021094, 55.232698],
              [48.064316, 55.229142],
              [48.074998, 55.175321],
              [48.107665, 55.145905],
              [48.102603, 55.135901],
              [48.061378, 55.12863],
              [48.086793, 55.093943],
              [48.063163, 55.083669],
              [48.03788, 55.108639],
              [48.035255, 55.118429],
              [48.017526, 55.115682],
              [48.014628, 55.105008],
              [47.982929, 55.097506],
              [47.959439, 55.098456],
              [47.943197, 55.090897],
              [47.901614, 55.091834],
              [47.906185, 55.072764],
              [47.900018, 55.052815],
              [47.918852, 55.045647],
              [47.930529, 55.026238],
              [47.95135, 55.037358],
              [47.936155, 55.049648],
              [47.945375, 55.061714],
              [47.972746, 55.064679],
              [47.975521, 55.040936],
              [47.99686, 55.053445],
              [48.01335, 55.036869],
              [48.028034, 55.039695],
              [48.011604, 54.98553],
              [47.976187, 54.952781],
              [47.941161, 54.945159],
              [47.930318, 54.950062],
              [47.895728, 54.941669],
              [47.852162, 54.961876],
              [47.823814, 54.959623],
              [47.808262, 54.964803],
              [47.763722, 54.954319],
              [47.769989, 54.915319],
              [47.778167, 54.901485],
              [47.782619, 54.869541],
              [47.801671, 54.826487],
              [47.844877, 54.819951],
              [47.823539, 54.803411],
              [47.795337, 54.80506],
              [47.787322, 54.823933],
              [47.745542, 54.897391],
              [47.735823, 54.908911],
              [47.714598, 54.90134],
              [47.738558, 54.896982],
              [47.722629, 54.858919],
              [47.724882, 54.846553],
              [47.754646, 54.81556],
              [47.744786, 54.812796],
              [47.709967, 54.843014],
              [47.684068, 54.839777],
              [47.658999, 54.859546],
              [47.640404, 54.864751],
              [47.603164, 54.832356],
              [47.576949, 54.828649],
              [47.560501, 54.844651],
              [47.450518, 54.861459],
              [47.453174, 54.872501],
              [47.409066, 54.880576],
              [47.426415, 54.83644],
              [47.387243, 54.843009],
              [47.369252, 54.828718],
              [47.370767, 54.781276],
              [47.358841, 54.773965],
              [47.387674, 54.752798],
              [47.31728, 54.730097],
              [47.283934, 54.712965],
              [47.258597, 54.677532],
              [47.267867, 54.673486],
              [47.261545, 54.668053],
              [47.217749, 54.667401],
              [47.241031, 54.653562],
              [47.207492, 54.657143],
              [47.176394, 54.666098],
              [47.183396, 54.651607],
              [47.169719, 54.652421],
              [47.155554, 54.676029],
              [47.147414, 54.662841],
              [47.108826, 54.675703],
              [47.078868, 54.696379],
              [47.079356, 54.705171],
              [47.034094, 54.705496],
              [47.029454, 54.69068],
              [47.010243, 54.685144],
              [46.952689, 54.680259],
              [46.892286, 54.644277],
              [46.885611, 54.657953],
              [46.875435, 54.645905],
              [46.826185, 54.632065],
              [46.799972, 54.639554],
              [46.776443, 54.684164],
              [46.766024, 54.677977],
              [46.737531, 54.685629],
              [46.658486, 54.674231],
              [46.622341, 54.691651],
              [46.603291, 54.708584],
              [46.550376, 54.719491],
              [46.542561, 54.734633],
              [46.514475, 54.745215],
              [46.492495, 54.746029],
              [46.493309, 54.757752],
              [46.441731, 54.761445],
              [46.405606, 54.77753],
              [46.449103, 54.785992],
              [46.426299, 54.790789],
              [46.399953, 54.806872],
              [46.382018, 54.830457],
              [46.400897, 54.86005],
              [46.390421, 54.865328],
              [46.410591, 54.874072],
              [46.373013, 54.893616],
              [46.370261, 54.90649],
              [46.339282, 54.91256],
              [46.326326, 54.920945],
              [46.32684, 54.93677],
              [46.310885, 54.928735],
              [46.29612, 54.943225],
              [46.282571, 54.938099],
              [46.27133, 54.962842],
              [46.243187, 54.977962],
              [46.287887, 54.992389],
              [46.275798, 55.00056],
              [46.293037, 55.028402],
              [46.279319, 55.034057],
              [46.226845, 55.083002],
              [46.182139, 55.068561],
              [46.202126, 55.049692],
              [46.207103, 55.032733],
              [46.1799, 55.026287],
              [46.184022, 55.019353],
              [46.154598, 54.973984],
              [46.13015, 54.996485],
              [46.143967, 55.005783],
              [46.137705, 55.022711],
              [46.061001, 55.047639],
              [46.040972, 55.053873],
              [46.067971, 55.064899],
              [46.077993, 55.082189],
              [46.032038, 55.094169],
              [46.043663, 55.114262],
              [46.028377, 55.149342],
              [46.013467, 55.149717],
              [46.011838, 55.163123],
              [46.044538, 55.166256],
              [46.056564, 55.189685],
              [46.074605, 55.200585],
              [46.079679, 55.220505],
              [46.105613, 55.230529],
              [46.12497, 55.223764],
              [46.173581, 55.236669],
              [46.163557, 55.24619],
              [46.182287, 55.263606],
              [46.221752, 55.271374],
              [46.229394, 55.287286],
              [46.199877, 55.337738],
              [46.236098, 55.347988],
              [46.214155, 55.371729],
              [46.245024, 55.386035],
              [46.256641, 55.399179],
              [46.332228, 55.413427],
              [46.378577, 55.417811],
              [46.38922, 55.427651],
              [46.384585, 55.451703],
              [46.394891, 55.462952],
              [46.345658, 55.484469],
              [46.32416, 55.477979],
              [46.308803, 55.500014],
              [46.291894, 55.508812],
              [46.28005, 55.499139],
              [46.250696, 55.502979],
              [46.237134, 55.521447],
              [46.214334, 55.520674],
              [46.228756, 55.532221],
              [46.206392, 55.543998],
              [46.18572, 55.542244],
              [46.19825, 55.521697],
              [46.151812, 55.526688],
              [46.133022, 55.51547],
              [46.107867, 55.530672],
              [46.10856, 55.541752],
              [46.092166, 55.549764],
              [46.11675, 55.555302],
              [46.082124, 55.57282],
              [46.098174, 55.575732],
              [46.080751, 55.595669],
              [46.062848, 55.600327],
              [46.06508, 55.614096],
              [46.041562, 55.629508],
              [46.014439, 55.633772],
              [45.960194, 55.630865],
              [45.952126, 55.653825],
              [45.93913, 55.665878],
              [45.916555, 55.668798],
              [45.914065, 55.695883],
              [45.953328, 55.712665],
              [45.933783, 55.72984],
              [45.952936, 55.75773],
              [45.992459, 55.749566],
              [46.032636, 55.785416],
              [46.067654, 55.78252],
              [46.100442, 55.798637],
              [46.111353, 55.831387],
              [46.073492, 55.867697],
              [46.12001, 55.878293],
              [46.142623, 55.889017],
              [46.168537, 55.886183],
              [46.150682, 55.900137],
              [46.125517, 55.901897],
              [46.145996, 55.913638],
              [46.134376, 55.92199],
              [46.102214, 55.926968],
              [46.080268, 55.948898],
              [46.180124, 56.010566],
              [46.185308, 56.031569],
            ],
            [
              [47.698839, 55.388287],
              [47.706103, 55.381188],
              [47.738953, 55.375665],
              [47.703566, 55.396145],
              [47.698839, 55.388287],
            ],
            [
              [47.294091, 54.847865],
              [47.322108, 54.842708],
              [47.325065, 54.852519],
              [47.294091, 54.847865],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5483",
      properties: {
        name: "Чукотский автономный округ",
        density: 0,
        path: "/world/Russia/Чукотский автономный округ",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [187.34198, 64.59757],
              [187.32095, 64.60053],
              [187.31741, 64.61917],
              [187.26856, 64.61886],
              [187.28486, 64.64386],
              [187.31774, 64.65243],
              [187.3395, 64.63809],
              [187.37872, 64.6407],
              [187.41316, 64.63315],
              [187.48693, 64.64696],
              [187.52483, 64.64134],
              [187.55333, 64.62141],
              [187.55334, 64.6099],
              [187.50673, 64.59064],
              [187.46293, 64.59671],
              [187.37789, 64.59452],
              [187.34198, 64.59757],
            ],
          ],
          [
            [
              [187.482664, 64.6875],
              [187.41527, 64.6787],
              [187.41067, 64.68594],
              [187.36446, 64.68927],
              [187.36248, 64.7023],
              [187.39876, 64.72572],
              [187.41122, 64.74785],
              [187.4555, 64.78004],
              [187.48249, 64.79055],
              [187.49459, 64.81011],
              [187.54346, 64.81674],
              [187.5418, 64.82516],
              [187.60664, 64.83716],
              [187.67988, 64.82607],
              [187.7231, 64.82657],
              [187.77019, 64.80804],
              [187.88969, 64.77714],
              [187.90593, 64.77586],
              [187.93611, 64.75523],
              [187.86822, 64.7459],
              [187.84901, 64.74716],
              [187.76437, 64.73855],
              [187.69794, 64.7133],
              [187.65358, 64.70344],
              [187.55914, 64.70294],
              [187.50979, 64.69797],
              [187.482664, 64.6875],
            ],
          ],
          [
            [
              [190.94514, 65.74933],
              [190.91187, 65.74527],
              [190.88245, 65.7597],
              [190.90372, 65.80344],
              [190.91799, 65.81925],
              [190.99287, 65.81151],
              [191.00221, 65.79937],
              [190.97222, 65.778],
              [190.94514, 65.74933],
            ],
          ],
          [
            [
              [185.28135, 67.15423],
              [185.2987, 67.11418],
              [185.29155, 67.101364],
              [185.33021, 67.060954],
              [185.33067, 67.0248],
              [185.30598, 67.01488],
              [185.277551, 67.01418],
              [185.27065, 67.0316],
              [185.30322, 67.05233],
              [185.27869, 67.07326],
              [185.25344, 67.06882],
              [185.27288, 67.09822],
              [185.29615, 67.11361],
              [185.28135, 67.15423],
            ],
          ],
          [
            [
              [180, 65.037387],
              [180, 65.03847],
              [180, 65.311729],
              [180, 65.312032],
              [180, 65.322175],
              [180, 65.33153],
              [180.005226, 65.323803],
              [180.055916, 65.304103],
              [180.128486, 65.296853],
              [180.153526, 65.319493],
              [180.139966, 65.331553],
              [180.104336, 65.330753],
              [180.079676, 65.355573],
              [180.028846, 65.366713],
              [180, 65.360292],
              [180, 65.361337],
              [180.000001, 65.361337],
              [180.000001, 66.09375],
              [180.000001, 67.13704],
              [180.000001, 67.650692],
              [180, 68.977636],
              [180.08441, 68.95001],
              [180.17055, 68.9254],
              [180.26796, 68.90616],
              [180.32231, 68.89909],
              [180.4158, 68.89738],
              [180.48784, 68.90832],
              [180.51572, 68.92272],
              [180.56913, 68.9188],
              [180.55998, 68.91054],
              [180.6391, 68.87739],
              [180.71781, 68.85162],
              [180.80148, 68.83108],
              [181.00624, 68.78809],
              [181.11878, 68.7658],
              [181.16197, 68.75117],
              [181.28486, 68.69636],
              [181.42646, 68.62892],
              [181.54926, 68.57646],
              [181.58698, 68.56902],
              [182.017, 68.4424],
              [182.17407, 68.38864],
              [182.30153, 68.33887],
              [182.46073, 68.28794],
              [182.54197, 68.26601],
              [182.48172, 68.27442],
              [182.4218, 68.29896],
              [182.38197, 68.29461],
              [182.39024, 68.26665],
              [182.344876, 68.264698],
              [182.3214, 68.24887],
              [182.36278, 68.24113],
              [182.33869, 68.22677],
              [182.44374, 68.214],
              [182.46201, 68.20378],
              [182.49715, 68.20069],
              [182.53516, 68.21802],
              [182.57638, 68.22279],
              [182.59662, 68.23211],
              [182.55967, 68.23926],
              [182.56403, 68.2486],
              [182.624, 68.25068],
              [182.69131, 68.23491],
              [182.90219, 68.16287],
              [182.92998, 68.15842],
              [183.21756, 68.07774],
              [183.60481, 67.96428],
              [183.79858, 67.91139],
              [183.90811, 67.88495],
              [184.03599, 67.85688],
              [184.12911, 67.84063],
              [184.16176, 67.84935],
              [184.167954, 67.816979],
              [184.21351, 67.80542],
              [184.28581, 67.78009],
              [184.40631, 67.74455],
              [184.5109, 67.7175],
              [184.679273, 67.670583],
              [184.718637, 67.669211],
              [184.742572, 67.654758],
              [184.687465, 67.639924],
              [184.70356, 67.60696],
              [184.75689, 67.57842],
              [184.748587, 67.564846],
              [184.76338, 67.55315],
              [184.856818, 67.509554],
              [184.924593, 67.482752],
              [184.93375, 67.472388],
              [184.99433, 67.45588],
              [185.09285, 67.42295],
              [185.16995, 67.391],
              [185.21281, 67.36411],
              [185.21771, 67.34579],
              [185.25092, 67.3097],
              [185.203138, 67.305171],
              [185.157305, 67.293796],
              [185.134727, 67.268466],
              [185.15145, 67.25409],
              [185.126003, 67.247194],
              [185.132624, 67.235162],
              [185.112168, 67.2259],
              [185.12319, 67.19963],
              [185.110632, 67.191951],
              [185.122002, 67.178212],
              [185.109204, 67.1636],
              [185.11638, 67.14364],
              [185.0893, 67.08716],
              [185.10924, 67.05308],
              [185.09912, 67.0344],
              [185.14827, 67.00768],
              [185.12992, 66.99751],
              [185.14667, 66.97845],
              [185.21371, 66.94704],
              [185.24277, 66.92801],
              [185.308259, 66.915062],
              [185.27985, 66.91596],
              [185.23728, 66.90432],
              [185.243797, 66.886693],
              [185.26401, 66.87449],
              [185.27056, 66.84008],
              [185.29928, 66.770752],
              [185.29248, 66.74893],
              [185.30087, 66.730742],
              [185.29166, 66.70923],
              [185.26532, 66.71654],
              [185.29218, 66.72512],
              [185.28589, 66.7475],
              [185.25415, 66.75174],
              [185.180529, 66.734395],
              [185.143363, 66.706001],
              [185.11266, 66.70448],
              [185.07873, 66.68742],
              [185.09624, 66.68094],
              [185.0793, 66.64518],
              [185.1364, 66.62253],
              [185.13939, 66.60749],
              [185.19039, 66.59005],
              [185.23341, 66.59083],
              [185.26734, 66.6006],
              [185.32753, 66.59434],
              [185.329704, 66.587026],
              [185.408075, 66.570672],
              [185.467731, 66.549012],
              [185.53686, 66.540067],
              [185.555441, 66.532771],
              [185.56439, 66.49937],
              [185.59796, 66.47194],
              [185.57598, 66.47338],
              [185.5414, 66.46404],
              [185.52429, 66.39147],
              [185.5617, 66.36082],
              [185.59761, 66.357],
              [185.61868, 66.33788],
              [185.68217, 66.34922],
              [185.73638, 66.37098],
              [185.75508, 66.39159],
              [185.83571, 66.40528],
              [185.85172, 66.42019],
              [185.83644, 66.44752],
              [185.80496, 66.45097],
              [185.78882, 66.46702],
              [185.8229, 66.45582],
              [185.97531, 66.471],
              [186.05704, 66.44582],
              [186.02539, 66.43767],
              [186.0167, 66.41934],
              [186.02225, 66.39692],
              [186.06997, 66.36558],
              [186.06785, 66.33458],
              [186.03884, 66.3403],
              [185.98243, 66.31937],
              [185.96471, 66.25787],
              [185.98128, 66.25205],
              [186.00942, 66.26642],
              [186.02456, 66.2511],
              [185.98903, 66.22968],
              [186.00381, 66.21205],
              [186.02954, 66.21152],
              [186.02399, 66.23445],
              [186.04657, 66.24205],
              [186.06239, 66.25873],
              [186.11319, 66.28092],
              [186.15625, 66.28786],
              [186.19469, 66.30224],
              [186.16519, 66.31847],
              [186.13057, 66.31796],
              [186.10559, 66.33042],
              [186.13087, 66.34317],
              [186.16601, 66.33665],
              [186.26142, 66.34936],
              [186.29482, 66.3676],
              [186.2879, 66.4051],
              [186.3119, 66.42828],
              [186.28243, 66.45541],
              [186.2279, 66.46827],
              [186.18988, 66.4704],
              [186.19086, 66.48449],
              [186.1668, 66.51016],
              [186.101116, 66.520193],
              [186.03582, 66.51683],
              [186.00978, 66.53065],
              [185.930169, 66.531551],
              [185.897535, 66.547115],
              [185.849189, 66.547752],
              [185.83849, 66.534106],
              [185.821, 66.54115],
              [185.83695, 66.55707],
              [185.79677, 66.55472],
              [185.783591, 66.571691],
              [185.73628, 66.569307],
              [185.78223, 66.57771],
              [185.802294, 66.593669],
              [185.94874, 66.62532],
              [186.05771, 66.6599],
              [186.07766, 66.67946],
              [186.06749, 66.70256],
              [186.062057, 66.779339],
              [186.03121, 66.856025],
              [185.97597, 66.90069],
              [185.98536, 66.92493],
              [185.96288, 66.96672],
              [185.948795, 66.974049],
              [185.83032, 66.97616],
              [185.794872, 66.968276],
              [185.73176, 66.976566],
              [185.76805, 66.98234],
              [185.79872, 66.99779],
              [185.808046, 67.014637],
              [185.79432, 67.0311],
              [185.70419, 67.03861],
              [185.643725, 67.035926],
              [185.53428, 67.02578],
              [185.46428, 67.02515],
              [185.38055, 67.04181],
              [185.38435, 67.06033],
              [185.40495, 67.06746],
              [185.48198, 67.07481],
              [185.79493, 67.08115],
              [185.98332, 67.08125],
              [186.1734, 67.07432],
              [186.24933, 67.07403],
              [186.30201, 67.07826],
              [186.33722, 67.08627],
              [186.34687, 67.11201],
              [186.44852, 67.09525],
              [186.53888, 67.0849],
              [186.55147, 67.07717],
              [186.58802, 67.07913],
              [186.84873, 67.05434],
              [187.08026, 67.03556],
              [187.14735, 67.03162],
              [187.21272, 67.03313],
              [187.34214, 67.01702],
              [187.32344, 67.00352],
              [187.39204, 66.99464],
              [187.40935, 66.9998],
              [187.37304, 67.01478],
              [187.72445, 66.97515],
              [187.8136, 66.96378],
              [187.942698, 66.951038],
              [188.000453, 66.949484],
              [188.03322, 66.96186],
              [188.06188, 66.96318],
              [188.07374, 66.97744],
              [188.109802, 66.95997],
              [188.19289, 66.94907],
              [188.20769, 66.95541],
              [188.28351, 66.95091],
              [188.3584, 66.93104],
              [188.36932, 66.9119],
              [188.303946, 66.883541],
              [188.31999, 66.85903],
              [188.39237, 66.82328],
              [188.48443, 66.79624],
              [188.59247, 66.77048],
              [188.63846, 66.76932],
              [188.66223, 66.75914],
              [188.66597, 66.74637],
              [188.63247, 66.73683],
              [188.61237, 66.71965],
              [188.62144, 66.69718],
              [188.64503, 66.67269],
              [188.68202, 66.65513],
              [188.90021, 66.57053],
              [188.99977, 66.54474],
              [189.02607, 66.53247],
              [189.11893, 66.50635],
              [189.14126, 66.48844],
              [189.26756, 66.44582],
              [189.33462, 66.41859],
              [189.35007, 66.40424],
              [189.41642, 66.36547],
              [189.42446, 66.35139],
              [189.51447, 66.3313],
              [189.73865, 66.296],
              [189.79162, 66.28334],
              [189.82992, 66.26027],
              [189.83239, 66.24874],
              [189.87346, 66.22601],
              [189.887928, 66.206647],
              [189.93101, 66.1957],
              [190.01078, 66.182792],
              [190.20922, 66.15855],
              [190.27339, 66.15265],
              [190.29072, 66.13948],
              [190.32599, 66.13151],
              [190.34811, 66.07863],
              [190.32961, 66.0499],
              [190.29612, 66.0287],
              [190.28048, 66.00531],
              [190.23883, 66.00142],
              [190.17819, 66.0074],
              [190.14974, 66.01733],
              [190.12793, 66.0359],
              [190.10063, 66.04454],
              [190.01752, 66.04268],
              [189.84238, 66.00628],
              [189.82109, 65.99845],
              [189.80669, 65.97987],
              [189.7725, 65.97033],
              [189.73746, 65.95107],
              [189.60914, 65.93143],
              [189.58303, 65.91287],
              [189.53699, 65.8959],
              [189.49832, 65.8697],
              [189.46443, 65.86034],
              [189.46614, 65.8519],
              [189.50683, 65.84103],
              [189.52445, 65.80261],
              [189.49558, 65.79146],
              [189.47056, 65.76904],
              [189.48683, 65.75697],
              [189.47007, 65.7427],
              [189.49618, 65.72574],
              [189.49622, 65.69557],
              [189.48631, 65.67975],
              [189.50702, 65.67661],
              [189.4988, 65.65234],
              [189.4664, 65.64222],
              [189.46397, 65.6274],
              [189.41968, 65.60553],
              [189.34604, 65.60188],
              [189.254459, 65.609006],
              [189.21077, 65.62003],
              [189.15756, 65.6206],
              [189.15334, 65.63954],
              [189.11624, 65.65363],
              [188.99233, 65.67566],
              [188.98738, 65.67873],
              [188.87443, 65.69429],
              [188.82099, 65.70496],
              [188.83463, 65.70856],
              [188.81772, 65.72874],
              [188.79904, 65.7346],
              [188.78394, 65.75657],
              [188.74585, 65.77161],
              [188.71968, 65.77411],
              [188.6652, 65.79918],
              [188.65087, 65.79902],
              [188.56864, 65.83576],
              [188.53562, 65.83324],
              [188.55149, 65.81743],
              [188.58335, 65.81343],
              [188.57378, 65.78911],
              [188.6073, 65.75545],
              [188.65416, 65.73996],
              [188.74044, 65.7207],
              [188.69659, 65.7265],
              [188.67553, 65.72109],
              [188.69624, 65.70272],
              [188.68951, 65.69452],
              [188.72372, 65.68718],
              [188.76218, 65.65323],
              [188.85113, 65.6467],
              [188.95708, 65.62401],
              [188.98556, 65.58834],
              [189.02048, 65.58769],
              [189.02909, 65.55318],
              [189.00933, 65.51534],
              [189.01271, 65.50025],
              [188.98255, 65.47621],
              [188.95426, 65.46623],
              [188.92104, 65.46902],
              [188.87975, 65.4846],
              [188.83973, 65.48585],
              [188.800111, 65.50825],
              [188.76503, 65.5103],
              [188.75259, 65.52255],
              [188.71555, 65.52684],
              [188.59112, 65.52003],
              [188.54437, 65.51427],
              [188.37563, 65.50945],
              [188.19772, 65.48445],
              [188.07446, 65.47096],
              [187.96162, 65.47713],
              [187.90938, 65.47536],
              [187.82877, 65.39958],
              [187.78828, 65.33275],
              [187.79606, 65.30315],
              [187.8235, 65.2911],
              [187.80401, 65.2559],
              [187.79405, 65.21336],
              [187.80839, 65.17895],
              [187.83578, 65.15154],
              [187.91189, 65.09611],
              [187.91435, 65.08052],
              [187.90153, 65.06075],
              [187.79509, 65.00953],
              [187.74407, 64.96947],
              [187.72756, 64.96114],
              [187.67826, 64.951],
              [187.62545, 64.92776],
              [187.59274, 64.91901],
              [187.47256, 64.90248],
              [187.41677, 64.87811],
              [187.35666, 64.8671],
              [187.32824, 64.86947],
              [187.31052, 64.85469],
              [187.24037, 64.83315],
              [187.20351, 64.8455],
              [187.15055, 64.84639],
              [187.0917, 64.86253],
              [187.0405, 64.86352],
              [187.00371, 64.84858],
              [186.98033, 64.84775],
              [186.94493, 64.8309],
              [186.91613, 64.83049],
              [186.94205, 64.79785],
              [186.90658, 64.78329],
              [186.86649, 64.78207],
              [186.84261, 64.77316],
              [186.81796, 64.77408],
              [186.8262, 64.7536],
              [186.8817, 64.764],
              [186.89474, 64.75816],
              [186.94647, 64.77143],
              [187.00048, 64.803],
              [187.05287, 64.82135],
              [187.05729, 64.83087],
              [187.08991, 64.83692],
              [187.10964, 64.82119],
              [187.12767, 64.82077],
              [187.19423, 64.78681],
              [187.25343, 64.77089],
              [187.25911, 64.76175],
              [187.22274, 64.72704],
              [187.17773, 64.72892],
              [187.164093, 64.723139],
              [187.16054, 64.69123],
              [187.1383, 64.6843],
              [187.10322, 64.69038],
              [187.02501, 64.68104],
              [186.94196, 64.6574],
              [186.93462, 64.64399],
              [186.95088, 64.63808],
              [186.983, 64.64338],
              [187.03897, 64.66153],
              [187.0626, 64.65672],
              [187.08083, 64.66898],
              [187.10226, 64.67003],
              [187.13545, 64.65625],
              [187.17009, 64.65592],
              [187.12721, 64.62865],
              [187.12417, 64.61224],
              [187.10602, 64.6161],
              [187.08052, 64.58891],
              [187.03337, 64.57396],
              [187.05803, 64.56188],
              [187.11237, 64.56744],
              [187.13938, 64.59888],
              [187.17781, 64.61201],
              [187.22733, 64.61121],
              [187.28499, 64.60396],
              [187.28264, 64.58935],
              [187.30068, 64.58163],
              [187.34762, 64.58306],
              [187.39562, 64.57655],
              [187.42088, 64.5515],
              [187.48348, 64.54845],
              [187.51848, 64.53883],
              [187.55184, 64.5375],
              [187.57409, 64.54372],
              [187.58755, 64.53631],
              [187.58511, 64.52017],
              [187.60115, 64.50267],
              [187.67005, 64.45267],
              [187.76567, 64.40772],
              [187.75882, 64.40215],
              [187.63922, 64.40545],
              [187.55369, 64.4013],
              [187.51251, 64.38644],
              [187.43959, 64.38018],
              [187.40697, 64.37203],
              [187.33403, 64.38657],
              [187.32719, 64.39212],
              [187.27538, 64.39817],
              [187.28578, 64.42106],
              [187.28054, 64.44326],
              [187.24163, 64.46352],
              [187.24057, 64.50776],
              [187.21445, 64.5077],
              [187.20329, 64.49694],
              [187.06063, 64.49181],
              [187.0363, 64.48778],
              [187.02446, 64.47509],
              [186.97156, 64.45671],
              [186.97609, 64.44757],
              [187.00675, 64.44133],
              [187.06046, 64.44397],
              [187.0885, 64.45802],
              [187.10963, 64.45253],
              [187.14688, 64.46235],
              [187.17877, 64.45508],
              [187.19712, 64.42844],
              [187.20908, 64.42654],
              [187.18391, 64.40884],
              [187.17145, 64.37389],
              [187.19366, 64.35958],
              [187.16664, 64.3219],
              [187.1631, 64.29985],
              [187.10999, 64.29252],
              [187.07615, 64.27929],
              [187.0645, 64.26071],
              [186.9728, 64.2596],
              [186.89298, 64.24146],
              [186.82654, 64.24893],
              [186.76835, 64.27031],
              [186.6731, 64.28994],
              [186.6335, 64.29148],
              [186.62814, 64.31954],
              [186.64714, 64.3526],
              [186.63893, 64.36219],
              [186.6644, 64.3649],
              [186.7238, 64.39296],
              [186.73907, 64.40509],
              [186.76767, 64.39516],
              [186.79462, 64.40568],
              [186.83259, 64.43426],
              [186.82185, 64.4428],
              [186.77792, 64.42193],
              [186.74636, 64.4171],
              [186.7519, 64.45429],
              [186.77866, 64.48233],
              [186.76541, 64.50311],
              [186.78145, 64.51742],
              [186.76444, 64.52203],
              [186.73615, 64.51213],
              [186.72304, 64.51986],
              [186.72215, 64.56974],
              [186.67389, 64.57384],
              [186.65895, 64.58845],
              [186.64289, 64.58819],
              [186.65662, 64.56015],
              [186.67487, 64.54135],
              [186.68306, 64.50797],
              [186.71973, 64.47759],
              [186.69437, 64.44777],
              [186.70036, 64.43635],
              [186.65574, 64.41798],
              [186.61054, 64.38655],
              [186.54087, 64.35904],
              [186.5094, 64.35442],
              [186.46258, 64.33058],
              [186.4119, 64.33622],
              [186.3793, 64.32528],
              [186.30709, 64.33983],
              [186.29188, 64.3503],
              [186.21693, 64.36169],
              [186.17395, 64.37751],
              [186.10537, 64.38897],
              [186.00781, 64.41593],
              [186.01722, 64.42457],
              [185.98485, 64.44695],
              [185.95494, 64.4814],
              [185.96664, 64.51236],
              [185.96053, 64.52989],
              [185.83912, 64.57469],
              [185.77446, 64.59275],
              [185.76006, 64.60856],
              [185.64211, 64.63179],
              [185.56771, 64.65849],
              [185.36429, 64.70509],
              [185.33234, 64.72889],
              [185.23685, 64.74774],
              [185.2178, 64.76391],
              [185.07343, 64.78717],
              [185.04227, 64.78673],
              [185.02202, 64.77338],
              [184.95178, 64.76148],
              [184.89957, 64.7694],
              [184.82795, 64.77457],
              [184.81055, 64.76678],
              [184.76601, 64.77525],
              [184.70453, 64.78052],
              [184.66563, 64.77594],
              [184.64583, 64.79072],
              [184.61682, 64.79179],
              [184.58296, 64.77476],
              [184.54661, 64.80033],
              [184.58404, 64.80478],
              [184.59845, 64.81598],
              [184.64579, 64.82633],
              [184.63381, 64.84264],
              [184.60306, 64.85031],
              [184.58464, 64.83964],
              [184.52765, 64.83666],
              [184.51812, 64.87762],
              [184.46718, 64.87761],
              [184.45525, 64.89467],
              [184.41307, 64.9085],
              [184.35701, 64.91855],
              [184.31886, 64.93298],
              [184.2152, 64.94506],
              [184.22921, 64.96654],
              [184.16655, 64.98681],
              [184.10789, 65.0013],
              [184.14087, 65.01481],
              [184.1692, 65.01561],
              [184.20544, 65.02984],
              [184.21665, 65.05019],
              [184.20411, 65.08106],
              [184.23433, 65.11586],
              [184.26702, 65.13381],
              [184.25761, 65.15581],
              [184.20506, 65.19027],
              [184.10857, 65.25841],
              [184.11256, 65.27649],
              [184.100268, 65.293476],
              [184.04008, 65.34281],
              [183.96425, 65.38993],
              [183.95632, 65.39954],
              [184.02152, 65.37757],
              [184.02898, 65.35116],
              [184.06925, 65.32634],
              [184.11106, 65.35386],
              [184.10169, 65.3843],
              [184.08085, 65.41182],
              [183.99986, 65.43774],
              [183.70704, 65.48021],
              [183.54561, 65.51427],
              [183.41077, 65.5447],
              [183.32728, 65.56054],
              [183.22543, 65.57516],
              [183.12662, 65.59523],
              [183.05407, 65.60341],
              [182.95649, 65.59739],
              [182.853672, 65.582041],
              [182.82312, 65.57256],
              [182.77043, 65.54738],
              [182.77192, 65.54013],
              [182.83173, 65.52809],
              [182.74188, 65.49381],
              [182.6403, 65.46686],
              [182.57892, 65.45682],
              [182.43075, 65.44428],
              [182.36628, 65.44248],
              [182.00799, 65.44428],
              [181.902401, 65.450527],
              [181.84662, 65.4512],
              [181.67545, 65.44494],
              [181.5974, 65.44592],
              [181.74679, 65.44771],
              [181.70719, 65.46048],
              [181.66905, 65.45743],
              [181.56899, 65.46664],
              [181.53131, 65.48751],
              [181.48339, 65.49585],
              [181.46665, 65.49175],
              [181.45825, 65.50941],
              [181.47852, 65.53212],
              [181.5158, 65.54229],
              [181.52943, 65.57443],
              [181.51014, 65.59166],
              [181.5433, 65.60605],
              [181.55899, 65.66527],
              [181.58742, 65.6803],
              [181.59525, 65.70159],
              [181.55063, 65.74137],
              [181.52085, 65.75097],
              [181.4647, 65.74698],
              [181.45933, 65.73752],
              [181.42013, 65.73406],
              [181.36806, 65.75388],
              [181.34713, 65.78317],
              [181.3161, 65.78969],
              [181.29862, 65.80273],
              [181.29685, 65.83327],
              [181.24568, 65.86211],
              [181.17903, 65.88315],
              [181.11855, 65.9081],
              [181.15707, 65.90604],
              [181.17375, 65.91224],
              [181.17922, 65.94001],
              [181.15265, 65.96379],
              [181.14604, 65.98497],
              [181.12085, 65.9964],
              [181.07553, 66.04142],
              [181.11166, 66.0174],
              [181.12468, 66.00066],
              [181.21778, 65.97777],
              [181.24666, 65.97761],
              [181.28326, 65.99709],
              [181.31664, 66.00486],
              [181.343, 66.02102],
              [181.34604, 66.03888],
              [181.3307, 66.04315],
              [181.35155, 66.06741],
              [181.32243, 66.08719],
              [181.38024, 66.09658],
              [181.42738, 66.09164],
              [181.44056, 66.10023],
              [181.494, 66.11482],
              [181.52439, 66.14161],
              [181.49598, 66.16165],
              [181.47466, 66.16099],
              [181.53091, 66.21162],
              [181.55588, 66.22165],
              [181.50652, 66.24207],
              [181.50659, 66.25666],
              [181.54379, 66.26556],
              [181.55502, 66.28189],
              [181.53851, 66.32081],
              [181.5548, 66.3334],
              [181.55596, 66.35207],
              [181.5304, 66.37126],
              [181.4996, 66.38113],
              [181.4607, 66.36713],
              [181.46296, 66.34759],
              [181.42396, 66.34346],
              [181.39953, 66.35145],
              [181.37674, 66.33416],
              [181.39279, 66.32756],
              [181.37288, 66.3084],
              [181.3792, 66.2972],
              [181.35886, 66.27716],
              [181.29977, 66.25331],
              [181.26658, 66.2112],
              [181.27141, 66.18724],
              [181.21998, 66.18096],
              [181.15641, 66.15656],
              [181.11019, 66.15254],
              [181.04104, 66.15935],
              [180.98772, 66.18252],
              [181.00177, 66.18523],
              [180.98753, 66.20532],
              [180.95274, 66.22413],
              [180.90541, 66.24019],
              [180.89094, 66.25336],
              [180.91735, 66.24962],
              [180.94921, 66.26443],
              [180.94589, 66.29192],
              [180.94892, 66.34562],
              [180.94398, 66.3629],
              [180.92687, 66.36564],
              [180.88346, 66.34701],
              [180.889058, 66.318998],
              [180.86728, 66.2875],
              [180.87885, 66.27389],
              [180.83914, 66.26362],
              [180.78448, 66.28044],
              [180.72323, 66.28333],
              [180.69096, 66.29742],
              [180.67612, 66.31339],
              [180.61844, 66.31961],
              [180.60697, 66.29734],
              [180.61851, 66.28248],
              [180.68882, 66.26111],
              [180.74075, 66.22146],
              [180.75479, 66.21779],
              [180.77062, 66.19001],
              [180.80394, 66.19457],
              [180.77585, 66.16351],
              [180.71496, 66.13246],
              [180.64035, 66.11466],
              [180.58982, 66.1102],
              [180.5339, 66.11226],
              [180.36638, 66.11089],
              [180.34496, 66.11839],
              [180.39701, 66.11329],
              [180.46554, 66.12034],
              [180.43833, 66.13861],
              [180.4425, 66.15772],
              [180.38858, 66.17312],
              [180.32079, 66.16781],
              [180.31759, 66.15303],
              [180.34302, 66.15402],
              [180.30958, 66.13042],
              [180.25033, 66.09712],
              [180.23616, 66.07259],
              [180.2487, 66.03751],
              [180.2352, 66.02039],
              [180.20985, 65.96896],
              [180.20494, 65.93989],
              [180.22321, 65.88575],
              [180.261191, 65.83684],
              [180.34457, 65.7581],
              [180.41548, 65.7198],
              [180.50355, 65.68592],
              [180.58143, 65.66069],
              [180.6558, 65.64253],
              [180.72564, 65.63008],
              [180.668115, 65.584743],
              [180.661764, 65.567211],
              [180.683221, 65.548459],
              [180.722703, 65.538154],
              [180.72717, 65.526352],
              [180.646486, 65.479445],
              [180.512246, 65.410605],
              [180.496969, 65.394099],
              [180.521344, 65.372791],
              [180.477743, 65.327113],
              [180.468301, 65.293914],
              [180.472936, 65.280063],
              [180.452029, 65.249341],
              [180.462672, 65.239421],
              [180.402933, 65.198121],
              [180.344569, 65.164111],
              [180.315214, 65.135471],
              [180.294272, 65.142976],
              [180.249468, 65.138069],
              [180.217882, 65.127674],
              [180.15528, 65.08933],
              [180.1237, 65.08922],
              [180.08804, 65.06437],
              [180, 65.037387],
            ],
          ],
          [
            [
              [185.28135, 67.15423],
              [185.29223, 67.16063],
              [185.30052, 67.22687],
              [185.31894, 67.2145],
              [185.335407, 67.181759],
              [185.336707, 67.161011],
              [185.28135, 67.15423],
            ],
          ],
          [
            [
              [185.3635, 67.47075],
              [185.35037, 67.481743],
              [185.41289, 67.460382],
              [185.38425, 67.4485],
              [185.3635, 67.47075],
            ],
          ],
          [
            [
              [180, 71.531777],
              [180, 70.97585],
              [179.897492, 70.964266],
              [179.832074, 70.934326],
              [179.771151, 70.912739],
              [179.672809, 70.884772],
              [179.583651, 70.865324],
              [179.491611, 70.852523],
              [179.450897, 70.850894],
              [179.391668, 70.857169],
              [179.363619, 70.870316],
              [179.314206, 70.874521],
              [179.07847, 70.849964],
              [178.980619, 70.829308],
              [178.78898, 70.800333],
              [178.7868, 70.831118],
              [178.759612, 70.884377],
              [178.730781, 70.917458],
              [178.66339, 70.966388],
              [178.635444, 71.00728],
              [178.627837, 71.072018],
              [178.6903, 71.102392],
              [178.7573, 71.124014],
              [178.787172, 71.144181],
              [178.826559, 71.151214],
              [178.869981, 71.175327],
              [178.906028, 71.220213],
              [178.934883, 71.223113],
              [179.046853, 71.246342],
              [179.142807, 71.258951],
              [179.197027, 71.274305],
              [179.268092, 71.317185],
              [179.334823, 71.334781],
              [179.478059, 71.405834],
              [179.554796, 71.420361],
              [179.686977, 71.455668],
              [179.71103, 71.438191],
              [179.77067, 71.439758],
              [179.801819, 71.460657],
              [179.760152, 71.472777],
              [179.83053, 71.491819],
              [179.855707, 71.504103],
              [180, 71.531777],
            ],
          ],
          [
            [
              [180.44406, 71.58955],
              [180.41918, 71.5795],
              [180.41797, 71.56596],
              [180.45145, 71.55435],
              [180.49829, 71.56297],
              [180.52881, 71.54436],
              [180.57399, 71.55696],
              [180.61689, 71.54873],
              [180.65917, 71.55432],
              [180.70873, 71.53058],
              [180.78701, 71.5467],
              [180.81305, 71.53952],
              [180.85162, 71.55052],
              [180.88449, 71.57302],
              [180.94318, 71.5594],
              [180.97826, 71.57634],
              [180.93662, 71.58642],
              [180.92338, 71.60077],
              [180.80866, 71.60258],
              [180.75297, 71.58321],
              [180.71379, 71.59408],
              [180.67967, 71.58515],
              [180.65251, 71.59975],
              [180.71002, 71.59966],
              [180.82234, 71.60615],
              [181.12645, 71.59149],
              [181.26516, 71.58127],
              [181.37115, 71.56658],
              [181.41928, 71.5647],
              [181.66241, 71.53277],
              [181.74513, 71.52726],
              [181.84925, 71.50132],
              [181.97723, 71.46263],
              [182.05917, 71.43124],
              [182.160296, 71.368393],
              [182.20332, 71.34863],
              [182.2934, 71.29151],
              [182.319, 71.28873],
              [182.37063, 71.26752],
              [182.42011, 71.26186],
              [182.47701, 71.27124],
              [182.4946, 71.2629],
              [182.49135, 71.24299],
              [182.54912, 71.23873],
              [182.56738, 71.22459],
              [182.53458, 71.20171],
              [182.52352, 71.15433],
              [182.49855, 71.15302],
              [182.45772, 71.13755],
              [182.41633, 71.1026],
              [182.38538, 71.09939],
              [182.320498, 71.076586],
              [182.25984, 71.06195],
              [182.227431, 71.061765],
              [182.09448, 71.02401],
              [181.88358, 71.00891],
              [181.82991, 71.00827],
              [181.63665, 70.97604],
              [181.53897, 70.96796],
              [181.4714, 70.97114],
              [181.37437, 70.96193],
              [181.28932, 70.960336],
              [181.17802, 70.94239],
              [181.05406, 70.93029],
              [180.8749, 70.89906],
              [180.76496, 70.88406],
              [180.80166, 70.89213],
              [180.7819, 70.90328],
              [180.61064, 70.89442],
              [180.61859, 70.88659],
              [180.53921, 70.87942],
              [180.53705, 70.88484],
              [180.44789, 70.91109],
              [180.39436, 70.93178],
              [180.3122, 70.95054],
              [180.21328, 70.95894],
              [180.06121, 70.9789],
              [180, 70.97585],
              [180.00018, 71.290432],
              [180, 71.527409],
              [180, 71.531777],
              [180.07142, 71.53784],
              [180.10585, 71.52116],
              [180.13872, 71.52484],
              [180.15625, 71.55282],
              [180.1937, 71.5449],
              [180.24663, 71.54746],
              [180.26856, 71.56705],
              [180.32243, 71.57456],
              [180.30268, 71.55392],
              [180.3318, 71.54484],
              [180.34978, 71.55988],
              [180.39495, 71.56381],
              [180.40852, 71.58097],
              [180.44406, 71.58955],
            ],
          ],
          [
            [
              [162.469443, 69.654961],
              [162.518554, 69.65642],
              [162.587457, 69.671064],
              [162.631558, 69.664759],
              [162.694829, 69.644119],
              [162.764219, 69.633893],
              [162.784097, 69.640411],
              [162.877028, 69.636446],
              [162.911383, 69.652968],
              [162.949463, 69.654311],
              [163.040635, 69.669671],
              [163.0809, 69.67165],
              [163.200633, 69.699251],
              [163.315796, 69.704737],
              [163.365586, 69.698944],
              [163.41061, 69.687597],
              [163.490306, 69.674111],
              [163.564793, 69.6709],
              [163.660388, 69.670633],
              [163.78426, 69.677157],
              [163.870157, 69.693706],
              [163.912844, 69.708034],
              [163.970828, 69.742378],
              [164.016757, 69.754014],
              [164.127726, 69.747569],
              [164.160709, 69.734439],
              [164.183918, 69.706177],
              [164.265494, 69.668198],
              [164.325727, 69.646203],
              [164.441631, 69.611551],
              [164.555382, 69.58355],
              [164.686606, 69.570927],
              [164.868122, 69.55889],
              [164.955863, 69.560468],
              [165.16955, 69.580455],
              [165.260464, 69.593122],
              [165.258184, 69.586225],
              [165.307216, 69.584739],
              [165.457726, 69.570609],
              [165.533784, 69.569371],
              [165.64653, 69.572058],
              [165.80671, 69.570371],
              [165.883878, 69.561052],
              [166.075096, 69.524068],
              [166.164223, 69.51161],
              [166.237607, 69.506121],
              [166.4646, 69.49695],
              [166.575616, 69.499178],
              [166.703674, 69.51357],
              [166.727369, 69.499659],
              [166.761394, 69.496507],
              [166.788812, 69.484211],
              [166.848993, 69.488953],
              [166.902624, 69.485308],
              [166.929598, 69.477212],
              [166.979466, 69.480617],
              [167.062738, 69.505222],
              [167.06418, 69.51262],
              [167.11663, 69.518896],
              [167.213962, 69.552723],
              [167.392085, 69.621473],
              [167.453719, 69.650033],
              [167.608207, 69.711237],
              [167.705917, 69.760663],
              [167.769118, 69.79754],
              [167.846014, 69.860014],
              [167.945302, 69.919531],
              [168.104975, 69.982051],
              [168.180181, 70.000168],
              [168.362347, 70.018372],
              [168.401108, 70.016299],
              [168.518425, 70.019777],
              [168.556558, 70.01518],
              [168.644317, 69.994976],
              [168.807009, 69.97156],
              [168.894138, 69.963439],
              [168.906482, 69.949598],
              [168.886734, 69.935695],
              [168.924824, 69.924103],
              [168.968965, 69.93285],
              [169.044938, 69.928573],
              [169.058471, 69.915716],
              [169.092932, 69.910954],
              [169.128262, 69.897459],
              [169.230258, 69.893771],
              [169.265808, 69.881905],
              [169.399074, 69.864554],
              [169.430218, 69.857116],
              [169.453077, 69.839586],
              [169.451999, 69.79509],
              [169.408442, 69.772875],
              [169.39825, 69.754175],
              [169.282421, 69.759208],
              [169.280397, 69.743763],
              [169.313786, 69.728092],
              [169.274027, 69.719587],
              [169.266307, 69.708761],
              [169.294676, 69.692191],
              [169.301126, 69.649516],
              [169.289256, 69.632647],
              [169.237536, 69.583453],
              [169.18327, 69.553644],
              [169.154022, 69.557672],
              [169.023508, 69.564824],
              [168.933055, 69.555106],
              [168.738879, 69.575371],
              [168.609907, 69.592799],
              [168.582278, 69.602965],
              [168.530046, 69.606586],
              [168.421976, 69.632789],
              [168.348468, 69.641886],
              [168.30355, 69.655533],
              [168.215278, 69.674828],
              [168.1681, 69.679268],
              [168.114068, 69.709915],
              [168.114843, 69.735231],
              [168.099376, 69.748041],
              [168.037854, 69.761905],
              [167.97197, 69.771599],
              [167.913915, 69.784847],
              [167.894515, 69.783632],
              [167.804544, 69.802352],
              [167.7319, 69.773052],
              [167.747316, 69.766545],
              [167.792541, 69.767625],
              [167.855172, 69.760738],
              [167.903636, 69.763912],
              [167.903144, 69.771083],
              [167.944829, 69.767833],
              [167.965125, 69.754446],
              [168.055284, 69.744462],
              [168.075117, 69.72851],
              [168.062815, 69.71235],
              [168.101889, 69.674565],
              [168.03871, 69.68309],
              [167.966209, 69.641093],
              [168.002757, 69.621538],
              [168.087437, 69.590387],
              [168.161894, 69.568459],
              [168.25359, 69.555006],
              [168.284776, 69.541244],
              [168.268956, 69.509554],
              [168.275965, 69.486136],
              [168.24215, 69.433799],
              [168.238056, 69.409054],
              [168.21869, 69.379219],
              [168.221011, 69.341305],
              [168.232434, 69.296567],
              [168.26592, 69.24527],
              [168.263921, 69.239166],
              [168.306418, 69.215069],
              [168.34817, 69.205434],
              [168.480826, 69.200205],
              [168.634356, 69.202449],
              [168.71576, 69.198281],
              [168.851955, 69.166101],
              [168.889377, 69.162186],
              [168.95053, 69.138516],
              [169.009273, 69.125888],
              [169.067001, 69.108142],
              [169.09504, 69.089293],
              [169.23388, 69.073241],
              [169.331869, 69.079628],
              [169.340543, 69.069163],
              [169.390374, 69.053292],
              [169.407445, 69.038508],
              [169.424859, 69.006038],
              [169.44514, 68.933272],
              [169.409147, 68.885024],
              [169.411239, 68.865246],
              [169.455759, 68.814899],
              [169.503042, 68.796612],
              [169.540087, 68.790885],
              [169.581914, 68.77413],
              [169.627667, 68.766351],
              [169.743483, 68.765834],
              [169.903717, 68.777539],
              [170.014191, 68.790579],
              [170.126132, 68.794019],
              [170.239296, 68.805425],
              [170.27063, 68.820991],
              [170.284937, 68.812597],
              [170.338253, 68.813233],
              [170.364698, 68.822911],
              [170.409179, 68.81047],
              [170.43047, 68.784527],
              [170.527488, 68.79774],
              [170.500516, 68.815735],
              [170.422058, 68.825445],
              [170.431346, 68.85464],
              [170.481684, 68.857124],
              [170.526555, 68.877469],
              [170.62296, 68.901365],
              [170.646602, 68.89917],
              [170.641918, 68.880856],
              [170.655002, 68.865286],
              [170.67885, 68.866798],
              [170.67977, 68.884212],
              [170.702561, 68.898071],
              [170.676385, 68.912544],
              [170.683196, 68.925143],
              [170.734083, 68.937159],
              [170.7985, 68.946137],
              [170.947683, 69.006939],
              [170.978134, 69.034618],
              [171.020737, 69.04621],
              [171.01469, 69.064645],
              [171.03292, 69.094287],
              [170.984908, 69.140628],
              [170.88874, 69.16577],
              [170.920072, 69.200104],
              [170.933697, 69.231455],
              [170.929464, 69.272788],
              [170.900551, 69.29957],
              [170.890012, 69.319432],
              [170.827198, 69.36236],
              [170.762103, 69.369159],
              [170.76997, 69.420209],
              [170.756116, 69.451456],
              [170.693751, 69.466853],
              [170.699496, 69.500784],
              [170.681046, 69.540124],
              [170.64499, 69.567333],
              [170.591958, 69.590459],
              [170.539283, 69.598421],
              [170.462126, 69.598543],
              [170.382188, 69.59346],
              [170.320476, 69.58176],
              [170.21223, 69.580538],
              [170.155679, 69.5926],
              [170.167381, 69.630196],
              [170.193846, 69.648128],
              [170.214574, 69.679617],
              [170.249748, 69.681643],
              [170.276748, 69.69673],
              [170.248167, 69.705023],
              [170.301492, 69.705599],
              [170.39103, 69.72397],
              [170.466685, 69.722015],
              [170.507849, 69.726756],
              [170.58573, 69.749945],
              [170.605002, 69.763953],
              [170.585104, 69.795109],
              [170.56686, 69.807486],
              [170.574315, 69.837004],
              [170.573128, 69.880132],
              [170.55964, 69.893895],
              [170.523513, 69.908108],
              [170.54943, 69.961759],
              [170.540671, 69.974257],
              [170.557491, 69.986812],
              [170.552786, 70.002862],
              [170.583078, 70.034744],
              [170.586017, 70.055428],
              [170.574691, 70.077585],
              [170.547, 70.086529],
              [170.433962, 70.09732],
              [170.43505, 70.11293],
              [170.45609, 70.118443],
              [170.562214, 70.123045],
              [170.6077, 70.108193],
              [170.682297, 70.100918],
              [170.773269, 70.081556],
              [170.85448, 70.080025],
              [170.94541, 70.087407],
              [171.029516, 70.084066],
              [171.060382, 70.076345],
              [171.237864, 70.055836],
              [171.333565, 70.056433],
              [171.424054, 70.052288],
              [171.588096, 70.024064],
              [171.678932, 70.016815],
              [171.8459, 69.992335],
              [172.04458, 69.975798],
              [172.190451, 69.972378],
              [172.260379, 69.96734],
              [172.389956, 69.945774],
              [172.463188, 69.938567],
              [172.526184, 69.937093],
              [172.606334, 69.942684],
              [172.656982, 69.95392],
              [172.709493, 69.956351],
              [172.75257, 69.964814],
              [172.804936, 69.938401],
              [172.791223, 69.917588],
              [172.85635, 69.893515],
              [172.910257, 69.88427],
              [173.032788, 69.846092],
              [173.110785, 69.833872],
              [173.219083, 69.832],
              [173.25333, 69.806363],
              [173.242764, 69.788731],
              [173.19621, 69.784149],
              [173.198827, 69.769613],
              [173.225758, 69.763792],
              [173.30182, 69.764382],
              [173.362, 69.775617],
              [173.42967, 69.768876],
              [173.472679, 69.777355],
              [173.511673, 69.796558],
              [173.478544, 69.808697],
              [173.490237, 69.823006],
              [173.454196, 69.830092],
              [173.413563, 69.8509],
              [173.37184, 69.864639],
              [173.30973, 69.877689],
              [173.29472, 69.897469],
              [173.275102, 69.898978],
              [173.223322, 69.884826],
              [173.206767, 69.852984],
              [173.184851, 69.848928],
              [173.197064, 69.877863],
              [173.246846, 69.902989],
              [173.311124, 69.918417],
              [173.467191, 69.933753],
              [173.489013, 69.932523],
              [173.569816, 69.898786],
              [173.659226, 69.874649],
              [173.710189, 69.867159],
              [173.670533, 69.854902],
              [173.68937, 69.834272],
              [173.738707, 69.812671],
              [173.764044, 69.826187],
              [173.742228, 69.844978],
              [173.772063, 69.865739],
              [173.8261, 69.847271],
              [173.887949, 69.845556],
              [173.97035, 69.860674],
              [174.021569, 69.866021],
              [174.08638, 69.864541],
              [174.06795, 69.874214],
              [174.119734, 69.875832],
              [174.28286, 69.861016],
              [174.305651, 69.868527],
              [174.346657, 69.867227],
              [174.431378, 69.848399],
              [174.481742, 69.842857],
              [174.50852, 69.84848],
              [174.581843, 69.842975],
              [174.786193, 69.838875],
              [174.79832, 69.836341],
              [174.92428, 69.836841],
              [175.011843, 69.8436],
              [175.042744, 69.838192],
              [175.113992, 69.837176],
              [175.224592, 69.843945],
              [175.43175, 69.859452],
              [175.506921, 69.866492],
              [175.672753, 69.87267],
              [175.72962, 69.877432],
              [175.796438, 69.877387],
              [175.931377, 69.866239],
              [176.091892, 69.877614],
              [176.135229, 69.870802],
              [176.234002, 69.817839],
              [176.355945, 69.76363],
              [176.452764, 69.731243],
              [176.50302, 69.725769],
              [176.565301, 69.707532],
              [176.620638, 69.701271],
              [176.723273, 69.677182],
              [176.90357, 69.642104],
              [177.048876, 69.623211],
              [177.144846, 69.607568],
              [177.195369, 69.602535],
              [177.336785, 69.602831],
              [177.415854, 69.599467],
              [177.504945, 69.587348],
              [177.59144, 69.564202],
              [177.620571, 69.560012],
              [177.690819, 69.53879],
              [177.811659, 69.50811],
              [177.962662, 69.475392],
              [178.095743, 69.456713],
              [178.202474, 69.452046],
              [178.338954, 69.458464],
              [178.44577, 69.455565],
              [178.605044, 69.434357],
              [178.76119, 69.405234],
              [178.93105, 69.363797],
              [179.080177, 69.323972],
              [179.388116, 69.235601],
              [179.534753, 69.176472],
              [179.651803, 69.122798],
              [179.796525, 69.059347],
              [180, 68.977636],
              [180, 68.969673],
              [180, 68.90625],
              [180, 67.5],
              [180, 66.09375],
              [180, 65.361337],
              [180, 65.360292],
              [179.97331, 65.360695],
              [179.915189, 65.348314],
              [179.920943, 65.329575],
              [179.978671, 65.305065],
              [180, 65.312032],
              [180, 65.311729],
              [180, 65.03847],
              [180, 65.037387],
              [179.95249, 65.023084],
              [179.916388, 65.01957],
              [179.85315, 64.999933],
              [179.775471, 64.956612],
              [179.726238, 64.917854],
              [179.666244, 64.890534],
              [179.55939, 64.830996],
              [179.511675, 64.796242],
              [179.467448, 64.798263],
              [179.28455, 64.785966],
              [179.209313, 64.768172],
              [179.146494, 64.762187],
              [179.028142, 64.726373],
              [178.899668, 64.669492],
              [178.79191, 64.629394],
              [178.709019, 64.608785],
              [178.662479, 64.589722],
              [178.556046, 64.560954],
              [178.522803, 64.56744],
              [178.529133, 64.579525],
              [178.566555, 64.588597],
              [178.6132, 64.581955],
              [178.649922, 64.58884],
              [178.689484, 64.602718],
              [178.68381, 64.616979],
              [178.705521, 64.611487],
              [178.736738, 64.624425],
              [178.78792, 64.632128],
              [178.81724, 64.646992],
              [178.796493, 64.662595],
              [178.759149, 64.675962],
              [178.669914, 64.672135],
              [178.593791, 64.657244],
              [178.580848, 64.65022],
              [178.538542, 64.651306],
              [178.431895, 64.643168],
              [178.392242, 64.637664],
              [178.30111, 64.635119],
              [178.318039, 64.638729],
              [178.305837, 64.651626],
              [178.179955, 64.665856],
              [178.137582, 64.666187],
              [178.094317, 64.659225],
              [178.022489, 64.637871],
              [178.03381, 64.661195],
              [177.997155, 64.678838],
              [177.929815, 64.68324],
              [177.82573, 64.678487],
              [177.705591, 64.689733],
              [177.679356, 64.696168],
              [177.63212, 64.697805],
              [177.66768, 64.711517],
              [177.642763, 64.728382],
              [177.637113, 64.744363],
              [177.61204, 64.758265],
              [177.53496, 64.759217],
              [177.568949, 64.784825],
              [177.546461, 64.799958],
              [177.516077, 64.803759],
              [177.45445, 64.794768],
              [177.40553, 64.81488],
              [177.438026, 64.819774],
              [177.483627, 64.818355],
              [177.528415, 64.836999],
              [177.49149, 64.854772],
              [177.501663, 64.868292],
              [177.50638, 64.911111],
              [177.482996, 64.917198],
              [177.33292, 64.913518],
              [177.290783, 64.916163],
              [177.271598, 64.895741],
              [177.262338, 64.87223],
              [177.273058, 64.861726],
              [177.262101, 64.847936],
              [177.279901, 64.825911],
              [177.317973, 64.820975],
              [177.34333, 64.808388],
              [177.317037, 64.792335],
              [177.28287, 64.794066],
              [177.248618, 64.786839],
              [177.224833, 64.774858],
              [177.172328, 64.764826],
              [177.151997, 64.771626],
              [177.104625, 64.763739],
              [177.08183, 64.754036],
              [177.0645, 64.768365],
              [177.030359, 64.78129],
              [176.970413, 64.779373],
              [176.969175, 64.813896],
              [176.961962, 64.821311],
              [176.90788, 64.837142],
              [176.830479, 64.821986],
              [176.792494, 64.842908],
              [176.758202, 64.847842],
              [176.72338, 64.844457],
              [176.6752, 64.853566],
              [176.623836, 64.830046],
              [176.60286, 64.826923],
              [176.552885, 64.798905],
              [176.483876, 64.800088],
              [176.447981, 64.795228],
              [176.462353, 64.811392],
              [176.45978, 64.834911],
              [176.442733, 64.8461],
              [176.275803, 64.885916],
              [176.222127, 64.87938],
              [176.239014, 64.856706],
              [176.241566, 64.830122],
              [176.28544, 64.806299],
              [176.30558, 64.769823],
              [176.337533, 64.749765],
              [176.376916, 64.71669],
              [176.41031, 64.707619],
              [176.437047, 64.686753],
              [176.414274, 64.662368],
              [176.355709, 64.640258],
              [176.26105, 64.634189],
              [176.215849, 64.619762],
              [176.154125, 64.563667],
              [176.10971, 64.53962],
              [176.098003, 64.525226],
              [176.137394, 64.519068],
              [176.13841, 64.533908],
              [176.180806, 64.566375],
              [176.24272, 64.607115],
              [176.287083, 64.596338],
              [176.360938, 64.595398],
              [176.435496, 64.617696],
              [176.45189, 64.632079],
              [176.468008, 64.661549],
              [176.520984, 64.669658],
              [176.556277, 64.66542],
              [176.646785, 64.636765],
              [176.688741, 64.617935],
              [176.724736, 64.593967],
              [176.762423, 64.53932],
              [176.790098, 64.541801],
              [176.831546, 64.554743],
              [176.865622, 64.57626],
              [176.863456, 64.593137],
              [176.92231, 64.62259],
              [176.936587, 64.634105],
              [176.988449, 64.654832],
              [177.043991, 64.686026],
              [177.092446, 64.706079],
              [177.12768, 64.699147],
              [177.185646, 64.697552],
              [177.246118, 64.709867],
              [177.29479, 64.731772],
              [177.35907, 64.751194],
              [177.43134, 64.739237],
              [177.511573, 64.7409],
              [177.539547, 64.732873],
              [177.490835, 64.67013],
              [177.480875, 64.647146],
              [177.499361, 64.630462],
              [177.459662, 64.60962],
              [177.442651, 64.589212],
              [177.422945, 64.546816],
              [177.412616, 64.51232],
              [177.424846, 64.478015],
              [177.465077, 64.438252],
              [177.48646, 64.409028],
              [177.546863, 64.383496],
              [177.61623, 64.341788],
              [177.661426, 64.332128],
              [177.730191, 64.30111],
              [177.78283, 64.267078],
              [177.85534, 64.233678],
              [177.934706, 64.217334],
              [177.979993, 64.202585],
              [178.019495, 64.207303],
              [178.02397, 64.217247],
              [178.068387, 64.211557],
              [178.066134, 64.198785],
              [178.093378, 64.190143],
              [178.145349, 64.184117],
              [178.16313, 64.195486],
              [178.14756, 64.216328],
              [178.122271, 64.222663],
              [178.127458, 64.234645],
              [178.16566, 64.248669],
              [178.247153, 64.269483],
              [178.309312, 64.302193],
              [178.33307, 64.324488],
              [178.32886, 64.341153],
              [178.308965, 64.350796],
              [178.297902, 64.379031],
              [178.265346, 64.403359],
              [178.24736, 64.397886],
              [178.23383, 64.41258],
              [178.233294, 64.433558],
              [178.28036, 64.40195],
              [178.307452, 64.374854],
              [178.463178, 64.198415],
              [178.511457, 64.147273],
              [178.604968, 64.05541],
              [178.63448, 64.032361],
              [178.655676, 64.025726],
              [178.685079, 63.974212],
              [178.734473, 63.86184],
              [178.784984, 63.734584],
              [178.803307, 63.609758],
              [178.81353, 63.594278],
              [178.825278, 63.535539],
              [178.84655, 63.492026],
              [178.870417, 63.458711],
              [178.918966, 63.406639],
              [179.00716, 63.329176],
              [179.046399, 63.299251],
              [179.101681, 63.266913],
              [179.185234, 63.229957],
              [179.244051, 63.227505],
              [179.331578, 63.194236],
              [179.4033, 63.143392],
              [179.452484, 63.11619],
              [179.457165, 63.081302],
              [179.466599, 63.059384],
              [179.433336, 63.049466],
              [179.402802, 63.062144],
              [179.364596, 63.064067],
              [179.32346, 63.046962],
              [179.28779, 63.009156],
              [179.282488, 62.962921],
              [179.29358, 62.939452],
              [179.321573, 62.916986],
              [179.321223, 62.905475],
              [179.366919, 62.877203],
              [179.408565, 62.879185],
              [179.424305, 62.8729],
              [179.512989, 62.867763],
              [179.565406, 62.839048],
              [179.581399, 62.820327],
              [179.57833, 62.778919],
              [179.61432, 62.756241],
              [179.626222, 62.72684],
              [179.617489, 62.71011],
              [179.632208, 62.687565],
              [179.622624, 62.661125],
              [179.598409, 62.645194],
              [179.597354, 62.626785],
              [179.565836, 62.612648],
              [179.512535, 62.613103],
              [179.477466, 62.599011],
              [179.44601, 62.577809],
              [179.45686, 62.554584],
              [179.446749, 62.529423],
              [179.425556, 62.517507],
              [179.416683, 62.500256],
              [179.36393, 62.477101],
              [179.330166, 62.476773],
              [179.302015, 62.484662],
              [179.278141, 62.483518],
              [179.212943, 62.491989],
              [179.146985, 62.463461],
              [179.122696, 62.440545],
              [179.119429, 62.417818],
              [179.187873, 62.391423],
              [179.166936, 62.376395],
              [179.14873, 62.349294],
              [179.160339, 62.330688],
              [179.183327, 62.321371],
              [179.154202, 62.287276],
              [179.100799, 62.274018],
              [179.087047, 62.289581],
              [179.065046, 62.297331],
              [179.048044, 62.32215],
              [179.026579, 62.338331],
              [178.94259, 62.350405],
              [178.83421, 62.369917],
              [178.731368, 62.392344],
              [178.674265, 62.396625],
              [178.622052, 62.410037],
              [178.556242, 62.417568],
              [178.526858, 62.425697],
              [178.42986, 62.440286],
              [178.387869, 62.451712],
              [178.340025, 62.456529],
              [178.232768, 62.481568],
              [178.165766, 62.493273],
              [178.099009, 62.528097],
              [178.062093, 62.533371],
              [177.89454, 62.542475],
              [177.763521, 62.55374],
              [177.618986, 62.559571],
              [177.368384, 62.556106],
              [177.152154, 62.542695],
              [177.054629, 62.532279],
              [176.949431, 62.516548],
              [176.774821, 62.47634],
              [176.538083, 62.414233],
              [176.449352, 62.37963],
              [176.392054, 62.352019],
              [176.324763, 62.308729],
              [176.275706, 62.291341],
              [176.209516, 62.280363],
              [176.13171, 62.273887],
              [176.02681, 62.257712],
              [175.87966, 62.229867],
              [175.75791, 62.203756],
              [175.575513, 62.156202],
              [175.474245, 62.124929],
              [175.394343, 62.096134],
              [175.284102, 62.046958],
              [175.280708, 62.039495],
              [175.210688, 62.006873],
              [175.136976, 61.992591],
              [175.07926, 61.975932],
              [174.972561, 61.960969],
              [174.886613, 61.938179],
              [174.814882, 61.915689],
              [174.742955, 61.882536],
              [174.726921, 61.857445],
              [174.644899, 61.838915],
              [174.579181, 61.820751],
              [174.521632, 61.816269],
              [174.506022, 61.808563],
              [174.475429, 61.808114],
              [174.445181, 61.824373],
              [174.440917, 61.848414],
              [174.473132, 61.849604],
              [174.496964, 61.83798],
              [174.49364, 61.85402],
              [174.51887, 61.870161],
              [174.502612, 61.898324],
              [174.470355, 61.909201],
              [174.43822, 61.909319],
              [174.437647, 61.923686],
              [174.462568, 61.933237],
              [174.469231, 61.957837],
              [174.49087, 61.973976],
              [174.450382, 61.984105],
              [174.442449, 62.011431],
              [174.451898, 62.023903],
              [174.386407, 62.036997],
              [174.354987, 62.024397],
              [174.341654, 62.040955],
              [174.353816, 62.078618],
              [174.30487, 62.093357],
              [174.251102, 62.089446],
              [174.239136, 62.076077],
              [174.192821, 62.072767],
              [174.158877, 62.084907],
              [174.15971, 62.109879],
              [174.120364, 62.122197],
              [174.127968, 62.134357],
              [174.108163, 62.156999],
              [174.110153, 62.174747],
              [174.149516, 62.18192],
              [174.137134, 62.203946],
              [174.11431, 62.20579],
              [174.113683, 62.219027],
              [174.082716, 62.24179],
              [174.063652, 62.243942],
              [174.073904, 62.256459],
              [174.058785, 62.266418],
              [174.02701, 62.261523],
              [174.00031, 62.281329],
              [174.011992, 62.292158],
              [174.054646, 62.2879],
              [174.074323, 62.312558],
              [174.069572, 62.32856],
              [174.088572, 62.324979],
              [174.151094, 62.33786],
              [174.144737, 62.383242],
              [174.160191, 62.402512],
              [174.158895, 62.42429],
              [174.13243, 62.447693],
              [174.08989, 62.45911],
              [174.082054, 62.467087],
              [174.042267, 62.47225],
              [174.037455, 62.462522],
              [174.002261, 62.459279],
              [173.987901, 62.443877],
              [173.955109, 62.437163],
              [173.894196, 62.437186],
              [173.9079, 62.447295],
              [173.860485, 62.468816],
              [173.865629, 62.488304],
              [173.84286, 62.507995],
              [173.799952, 62.513792],
              [173.772736, 62.522911],
              [173.723504, 62.51915],
              [173.732033, 62.534029],
              [173.67219, 62.546819],
              [173.63745, 62.511339],
              [173.612841, 62.499333],
              [173.592502, 62.501869],
              [173.574556, 62.515991],
              [173.540705, 62.514027],
              [173.52692, 62.523075],
              [173.500988, 62.523735],
              [173.485693, 62.506174],
              [173.462527, 62.505291],
              [173.4301, 62.484078],
              [173.382692, 62.479825],
              [173.362203, 62.467701],
              [173.332214, 62.465132],
              [173.329736, 62.44872],
              [173.269609, 62.452354],
              [173.251238, 62.44021],
              [173.216713, 62.449442],
              [173.20337, 62.444382],
              [173.16152, 62.447456],
              [173.180178, 62.42199],
              [173.134771, 62.425077],
              [173.094508, 62.417176],
              [173.09818, 62.409669],
              [173.07239, 62.402047],
              [173.070009, 62.383073],
              [173.010951, 62.381273],
              [172.976564, 62.398321],
              [172.95373, 62.402296],
              [172.920784, 62.397648],
              [172.883218, 62.377276],
              [172.890025, 62.35845],
              [172.854532, 62.347644],
              [172.793113, 62.344965],
              [172.767013, 62.350416],
              [172.741966, 62.383547],
              [172.768112, 62.40159],
              [172.756171, 62.412584],
              [172.703012, 62.41131],
              [172.704597, 62.421706],
              [172.644272, 62.421972],
              [172.62867, 62.436529],
              [172.584804, 62.429435],
              [172.561024, 62.445272],
              [172.499434, 62.444187],
              [172.452212, 62.438965],
              [172.419743, 62.418806],
              [172.406617, 62.403206],
              [172.374137, 62.395169],
              [172.33575, 62.399657],
              [172.320421, 62.374693],
              [172.28989, 62.371698],
              [172.268631, 62.37978],
              [172.271449, 62.396831],
              [172.242769, 62.397399],
              [172.238774, 62.407197],
              [172.2591, 62.424608],
              [172.20788, 62.464736],
              [172.182644, 62.457083],
              [172.162107, 62.461725],
              [172.100192, 62.460809],
              [172.07791, 62.465021],
              [171.995413, 62.454633],
              [171.978614, 62.416243],
              [171.960248, 62.415579],
              [171.920741, 62.401883],
              [171.875575, 62.402724],
              [171.852391, 62.412222],
              [171.841757, 62.392785],
              [171.891578, 62.377368],
              [171.895871, 62.360447],
              [171.805032, 62.356125],
              [171.768937, 62.361852],
              [171.749989, 62.349353],
              [171.714933, 62.348439],
              [171.676266, 62.354163],
              [171.63248, 62.339739],
              [171.621632, 62.3217],
              [171.590686, 62.324425],
              [171.52466, 62.311059],
              [171.4849, 62.294897],
              [171.471852, 62.303962],
              [171.435475, 62.306317],
              [171.402679, 62.301781],
              [171.40592, 62.332453],
              [171.38045, 62.340593],
              [171.382744, 62.354319],
              [171.346724, 62.347617],
              [171.339915, 62.331442],
              [171.315538, 62.319549],
              [171.268018, 62.314377],
              [171.248154, 62.319866],
              [171.244232, 62.340306],
              [171.266862, 62.354581],
              [171.314958, 62.372576],
              [171.309611, 62.377775],
              [171.25856, 62.383176],
              [171.192094, 62.34629],
              [171.148135, 62.335212],
              [171.107763, 62.34708],
              [171.085581, 62.322492],
              [171.10279, 62.310481],
              [171.086826, 62.289552],
              [171.05716, 62.277264],
              [170.997912, 62.281038],
              [170.981732, 62.275592],
              [170.96152, 62.28576],
              [170.921012, 62.270597],
              [170.8817, 62.280754],
              [170.844375, 62.269792],
              [170.850672, 62.25602],
              [170.83724, 62.250558],
              [170.804925, 62.261125],
              [170.78221, 62.252599],
              [170.767489, 62.26707],
              [170.73612, 62.277845],
              [170.700483, 62.263975],
              [170.662579, 62.270172],
              [170.641231, 62.267511],
              [170.632825, 62.284062],
              [170.601768, 62.290618],
              [170.584259, 62.279596],
              [170.573444, 62.298796],
              [170.552443, 62.290251],
              [170.544238, 62.303805],
              [170.51566, 62.300487],
              [170.490754, 62.285621],
              [170.44925, 62.304989],
              [170.416784, 62.30471],
              [170.408735, 62.312558],
              [170.370708, 62.30936],
              [170.35214, 62.292368],
              [170.298668, 62.275848],
              [170.291897, 62.294027],
              [170.245718, 62.281324],
              [170.235389, 62.292237],
              [170.194294, 62.295329],
              [170.184889, 62.306044],
              [170.213749, 62.328554],
              [170.20513, 62.344565],
              [170.183503, 62.357869],
              [170.170794, 62.353965],
              [170.140918, 62.366201],
              [170.131001, 62.386457],
              [170.148339, 62.392621],
              [170.143278, 62.411865],
              [170.120286, 62.431296],
              [170.08217, 62.4359],
              [170.06948, 62.455599],
              [170.10331, 62.459071],
              [170.11876, 62.49084],
              [170.069288, 62.507878],
              [170.05726, 62.524033],
              [169.99225, 62.5228],
              [169.99005, 62.539206],
              [170.011058, 62.558569],
              [170.042353, 62.573314],
              [170.041986, 62.597444],
              [170.026816, 62.605272],
              [170.028008, 62.623279],
              [170.0626, 62.636989],
              [170.09316, 62.636479],
              [170.094389, 62.648404],
              [170.070611, 62.656407],
              [170.048049, 62.674604],
              [170.006875, 62.676483],
              [169.947973, 62.655859],
              [169.932571, 62.667126],
              [169.900712, 62.668014],
              [169.85202, 62.656463],
              [169.811904, 62.676887],
              [169.772189, 62.681158],
              [169.739229, 62.694837],
              [169.742747, 62.707964],
              [169.7279, 62.715483],
              [169.679408, 62.704598],
              [169.622239, 62.746631],
              [169.579482, 62.750573],
              [169.601438, 62.762595],
              [169.58508, 62.788218],
              [169.501229, 62.821101],
              [169.473761, 62.823923],
              [169.445654, 62.845384],
              [169.42298, 62.844696],
              [169.401349, 62.857608],
              [169.36773, 62.866284],
              [169.400384, 62.881426],
              [169.386914, 62.891975],
              [169.339824, 62.891499],
              [169.3053, 62.906858],
              [169.248048, 62.919404],
              [169.214155, 62.941882],
              [169.186518, 62.939391],
              [169.185099, 62.952982],
              [169.158696, 62.966315],
              [169.109186, 62.980079],
              [169.06428, 62.97343],
              [169.048976, 62.953577],
              [169.014616, 62.963151],
              [168.922245, 62.943581],
              [168.912004, 62.952128],
              [168.825232, 62.967347],
              [168.754875, 62.999789],
              [168.671594, 63.026717],
              [168.645973, 63.023882],
              [168.591948, 63.036016],
              [168.578645, 63.032889],
              [168.52178, 63.056359],
              [168.522746, 63.067891],
              [168.50285, 63.065197],
              [168.46637, 63.083485],
              [168.493876, 63.11007],
              [168.521346, 63.114476],
              [168.529978, 63.126887],
              [168.55773, 63.128134],
              [168.627151, 63.160955],
              [168.61987, 63.178267],
              [168.651524, 63.182653],
              [168.7101, 63.183597],
              [168.744975, 63.180026],
              [168.773463, 63.202955],
              [168.738855, 63.211508],
              [168.733173, 63.222525],
              [168.709444, 63.22675],
              [168.754997, 63.24662],
              [168.796898, 63.235857],
              [168.831871, 63.266134],
              [168.824273, 63.272325],
              [168.827449, 63.304941],
              [168.897121, 63.324772],
              [168.90946, 63.321058],
              [168.948797, 63.3399],
              [168.946179, 63.355338],
              [169.010377, 63.378201],
              [169.05527, 63.403411],
              [169.094153, 63.414],
              [169.113003, 63.436899],
              [169.133743, 63.440496],
              [169.15488, 63.473665],
              [169.220475, 63.49595],
              [169.232317, 63.511282],
              [169.254119, 63.513185],
              [169.331603, 63.538088],
              [169.36931, 63.530681],
              [169.429222, 63.546706],
              [169.47952, 63.565419],
              [169.527422, 63.564474],
              [169.54593, 63.583164],
              [169.527684, 63.599037],
              [169.564733, 63.624837],
              [169.55444, 63.63182],
              [169.57933, 63.643317],
              [169.602365, 63.640453],
              [169.641674, 63.654413],
              [169.60865, 63.662615],
              [169.614284, 63.683735],
              [169.585314, 63.686057],
              [169.568073, 63.698947],
              [169.581741, 63.716004],
              [169.568975, 63.721548],
              [169.592006, 63.731213],
              [169.575561, 63.758346],
              [169.583028, 63.76989],
              [169.571695, 63.807671],
              [169.539125, 63.81744],
              [169.50109, 63.836943],
              [169.493011, 63.848934],
              [169.444418, 63.847105],
              [169.415505, 63.852506],
              [169.416511, 63.865271],
              [169.386581, 63.862377],
              [169.37658, 63.880708],
              [169.400577, 63.899524],
              [169.368591, 63.907502],
              [169.387495, 63.940075],
              [169.332526, 63.935192],
              [169.310539, 63.947033],
              [169.211128, 63.94283],
              [169.19246, 63.937282],
              [169.102014, 63.940831],
              [169.090249, 63.935409],
              [169.052757, 63.961709],
              [169.068102, 63.971273],
              [169.04243, 63.979293],
              [169.00286, 63.976777],
              [168.99219, 63.990697],
              [168.922598, 63.979769],
              [168.876823, 63.984723],
              [168.882406, 64.001941],
              [168.869441, 64.013695],
              [168.887334, 64.034473],
              [168.882987, 64.06051],
              [168.839008, 64.07231],
              [168.838594, 64.09409],
              [168.808886, 64.110033],
              [168.823667, 64.137213],
              [168.800179, 64.183616],
              [168.71649, 64.184893],
              [168.68417, 64.196187],
              [168.652026, 64.193683],
              [168.634006, 64.212841],
              [168.64551, 64.227367],
              [168.608112, 64.234771],
              [168.60619, 64.250496],
              [168.577783, 64.262058],
              [168.58188, 64.274248],
              [168.5625, 64.288961],
              [168.480252, 64.317216],
              [168.415652, 64.309292],
              [168.361042, 64.298165],
              [168.302123, 64.31462],
              [168.279529, 64.330188],
              [168.246553, 64.331137],
              [168.195512, 64.307663],
              [168.199244, 64.290906],
              [168.182753, 64.27669],
              [168.14621, 64.273928],
              [168.113724, 64.282469],
              [168.038053, 64.265495],
              [167.981494, 64.28257],
              [167.93587, 64.2823],
              [167.9015, 64.289603],
              [167.882369, 64.300728],
              [167.847339, 64.294216],
              [167.836957, 64.302597],
              [167.837622, 64.324898],
              [167.809568, 64.345986],
              [167.786889, 64.333],
              [167.746542, 64.329898],
              [167.69068, 64.318488],
              [167.66455, 64.353802],
              [167.669343, 64.362011],
              [167.641128, 64.366123],
              [167.603069, 64.388751],
              [167.611898, 64.406083],
              [167.589071, 64.415265],
              [167.58747, 64.428691],
              [167.54647, 64.434657],
              [167.482496, 64.453544],
              [167.49116, 64.473569],
              [167.476589, 64.482259],
              [167.413633, 64.476157],
              [167.383833, 64.479166],
              [167.339302, 64.501677],
              [167.328537, 64.52055],
              [167.333357, 64.540155],
              [167.290625, 64.557627],
              [167.292798, 64.574843],
              [167.273418, 64.593443],
              [167.247613, 64.603106],
              [167.250571, 64.615764],
              [167.231553, 64.623183],
              [167.19936, 64.607669],
              [167.136568, 64.622144],
              [167.12462, 64.618162],
              [167.135402, 64.592524],
              [167.106174, 64.599957],
              [167.041733, 64.575325],
              [167.026525, 64.556488],
              [166.999067, 64.538417],
              [166.91515, 64.541273],
              [166.901218, 64.534195],
              [166.867415, 64.536707],
              [166.859232, 64.544411],
              [166.808377, 64.532234],
              [166.787804, 64.518863],
              [166.759556, 64.542366],
              [166.729994, 64.549762],
              [166.685874, 64.540472],
              [166.682196, 64.532955],
              [166.635199, 64.521465],
              [166.588154, 64.52357],
              [166.582167, 64.537298],
              [166.547253, 64.544697],
              [166.537825, 64.55392],
              [166.47368, 64.558544],
              [166.457794, 64.570296],
              [166.427855, 64.57689],
              [166.407941, 64.590268],
              [166.350502, 64.589989],
              [166.311455, 64.594397],
              [166.310734, 64.58354],
              [166.19155, 64.600692],
              [166.185739, 64.620014],
              [166.134676, 64.606856],
              [166.11977, 64.58527],
              [166.06826, 64.574812],
              [166.040728, 64.549084],
              [166.002052, 64.554937],
              [165.961869, 64.549075],
              [165.942191, 64.55485],
              [165.915267, 64.547998],
              [165.879624, 64.551828],
              [165.845867, 64.548706],
              [165.846329, 64.571211],
              [165.820972, 64.586512],
              [165.850725, 64.597382],
              [165.871587, 64.596993],
              [165.901555, 64.608768],
              [165.822032, 64.624686],
              [165.813857, 64.640283],
              [165.780178, 64.644428],
              [165.732861, 64.659654],
              [165.721248, 64.671251],
              [165.758397, 64.714798],
              [165.717353, 64.718489],
              [165.684222, 64.737327],
              [165.685052, 64.750314],
              [165.615246, 64.74965],
              [165.567374, 64.736539],
              [165.539672, 64.739783],
              [165.525146, 64.752954],
              [165.496323, 64.755802],
              [165.445495, 64.733335],
              [165.42676, 64.744423],
              [165.40296, 64.743801],
              [165.387318, 64.764213],
              [165.336542, 64.754339],
              [165.323488, 64.762923],
              [165.348309, 64.783188],
              [165.320862, 64.796582],
              [165.331882, 64.804919],
              [165.302253, 64.811641],
              [165.286615, 64.827598],
              [165.258326, 64.826718],
              [165.186025, 64.846663],
              [165.171698, 64.838828],
              [165.137212, 64.854638],
              [165.121024, 64.845924],
              [165.081143, 64.846238],
              [165.024031, 64.838332],
              [165.00608, 64.786955],
              [165.010583, 64.766615],
              [165.038547, 64.748619],
              [165.02892, 64.728929],
              [165.007484, 64.725244],
              [165.012585, 64.675938],
              [164.986672, 64.673019],
              [164.971809, 64.68263],
              [164.932037, 64.682897],
              [164.922629, 64.674772],
              [164.864242, 64.681064],
              [164.856123, 64.703272],
              [164.869243, 64.71436],
              [164.845487, 64.731896],
              [164.805576, 64.749227],
              [164.797048, 64.767849],
              [164.733979, 64.77058],
              [164.713618, 64.77616],
              [164.67068, 64.805139],
              [164.628222, 64.810713],
              [164.623763, 64.794789],
              [164.607591, 64.795528],
              [164.585967, 64.817618],
              [164.516425, 64.825019],
              [164.521065, 64.844342],
              [164.476799, 64.862202],
              [164.475409, 64.872934],
              [164.362849, 64.886237],
              [164.33411, 64.903294],
              [164.275386, 64.911113],
              [164.270007, 64.926992],
              [164.232118, 64.925386],
              [164.218241, 64.936724],
              [164.197135, 64.921309],
              [164.167119, 64.921942],
              [164.151001, 64.893137],
              [164.061393, 64.896892],
              [164.05137, 64.870961],
              [164.003172, 64.866564],
              [163.965453, 64.855842],
              [163.930515, 64.86051],
              [163.907742, 64.848028],
              [163.828923, 64.861595],
              [163.825759, 64.873931],
              [163.800962, 64.881779],
              [163.810464, 64.890064],
              [163.776489, 64.886393],
              [163.721588, 64.889874],
              [163.680697, 64.885551],
              [163.643666, 64.892442],
              [163.643064, 64.91021],
              [163.60947, 64.922849],
              [163.563752, 64.913944],
              [163.544892, 64.928587],
              [163.503285, 64.916112],
              [163.490903, 64.899435],
              [163.488276, 64.86873],
              [163.468452, 64.868587],
              [163.41337, 64.854646],
              [163.405424, 64.832808],
              [163.35488, 64.815209],
              [163.357314, 64.80002],
              [163.317217, 64.805994],
              [163.266608, 64.79718],
              [163.207603, 64.813802],
              [163.125913, 64.793097],
              [163.135555, 64.767094],
              [163.170196, 64.762891],
              [163.169334, 64.740367],
              [163.21138, 64.734079],
              [163.212468, 64.722993],
              [163.262112, 64.715055],
              [163.264824, 64.693339],
              [163.230641, 64.696866],
              [163.148477, 64.683835],
              [163.108883, 64.681358],
              [163.071021, 64.662974],
              [163.001644, 64.663668],
              [162.9619, 64.656831],
              [162.922009, 64.66258],
              [162.86829, 64.657724],
              [162.75248, 64.662186],
              [162.711153, 64.684437],
              [162.675771, 64.685577],
              [162.676368, 64.708471],
              [162.650107, 64.74098],
              [162.578103, 64.74217],
              [162.534199, 64.758425],
              [162.48791, 64.753074],
              [162.422304, 64.762094],
              [162.318979, 64.743661],
              [162.307186, 64.754514],
              [162.264322, 64.763434],
              [162.250547, 64.777211],
              [162.224432, 64.785933],
              [162.141922, 64.782168],
              [162.088255, 64.795053],
              [162.020563, 64.79882],
              [161.956043, 64.812598],
              [161.911048, 64.81577],
              [161.88924, 64.827366],
              [161.848113, 64.824641],
              [161.798907, 64.84045],
              [161.82403, 64.854969],
              [161.814817, 64.877566],
              [161.754363, 64.910868],
              [161.758229, 64.922365],
              [161.74044, 64.938916],
              [161.753822, 64.958788],
              [161.744508, 64.982971],
              [161.718085, 65.003001],
              [161.619584, 65.038623],
              [161.589752, 65.036839],
              [161.547829, 65.050071],
              [161.518394, 65.050072],
              [161.4732, 65.064245],
              [161.406353, 65.09492],
              [161.418347, 65.104782],
              [161.406157, 65.118955],
              [161.381132, 65.121879],
              [161.361311, 65.13511],
              [161.283016, 65.153645],
              [161.21562, 65.147204],
              [161.144857, 65.165639],
              [161.114876, 65.158504],
              [161.037076, 65.177931],
              [161.01879, 65.178427],
              [160.952335, 65.161381],
              [160.943811, 65.164156],
              [160.868287, 65.143344],
              [160.760952, 65.155834],
              [160.736273, 65.148896],
              [160.64299, 65.152034],
              [160.599403, 65.167333],
              [160.569471, 65.162774],
              [160.53399, 65.176452],
              [160.501978, 65.178038],
              [160.512288, 65.20341],
              [160.475023, 65.210547],
              [160.461942, 65.22244],
              [160.439345, 65.224423],
              [160.456492, 65.238001],
              [160.434887, 65.250092],
              [160.41596, 65.27596],
              [160.391776, 65.274969],
              [160.38484, 65.290827],
              [160.326762, 65.2939],
              [160.32815, 65.321453],
              [160.299608, 65.325418],
              [160.284149, 65.343654],
              [160.233801, 65.351485],
              [160.19515, 65.377452],
              [160.212198, 65.382605],
              [160.204271, 65.404806],
              [160.243718, 65.417293],
              [160.240945, 65.432953],
              [160.189607, 65.448712],
              [160.194366, 65.46556],
              [160.158291, 65.477652],
              [160.12717, 65.478644],
              [160.092682, 65.502233],
              [160.04947, 65.510658],
              [160.038371, 65.520172],
              [159.999718, 65.519182],
              [159.940448, 65.506298],
              [159.91646, 65.522949],
              [159.854916, 65.51185],
              [159.811506, 65.511256],
              [159.805562, 65.53643],
              [159.752242, 65.556748],
              [159.728953, 65.575282],
              [159.645702, 65.594907],
              [159.581082, 65.597683],
              [159.587426, 65.613937],
              [159.566812, 65.620281],
              [159.51706, 65.619687],
              [159.48624, 65.637725],
              [159.450557, 65.638717],
              [159.448378, 65.648628],
              [159.41686, 65.644664],
              [159.284153, 65.665677],
              [159.227461, 65.659137],
              [159.208236, 65.680941],
              [159.142625, 65.687286],
              [159.100207, 65.704729],
              [159.068095, 65.703937],
              [159.026768, 65.726832],
              [158.998422, 65.723661],
              [158.951841, 65.72723],
              [158.909324, 65.744476],
              [158.9345, 65.763504],
              [158.900411, 65.81742],
              [158.950265, 65.831295],
              [158.924102, 65.851117],
              [158.923706, 65.862217],
              [158.960973, 65.871137],
              [158.931439, 65.882535],
              [158.93302, 65.892842],
              [158.976833, 65.901563],
              [158.973861, 65.91306],
              [159.02491, 65.947549],
              [159.024808, 65.961127],
              [159.048993, 65.978372],
              [159.02818, 65.987788],
              [159.054151, 66.019304],
              [159.10886, 66.024259],
              [159.097365, 66.045072],
              [159.132452, 66.061722],
              [159.16506, 66.069056],
              [159.187858, 66.09304],
              [159.18766, 66.114051],
              [159.217297, 66.125844],
              [159.209964, 66.138134],
              [159.247032, 66.142494],
              [159.260315, 66.1641],
              [159.239304, 66.173615],
              [159.246046, 66.192049],
              [159.215522, 66.216233],
              [159.229895, 66.236302],
              [159.115768, 66.226393],
              [159.06012, 66.231845],
              [159.039207, 66.247554],
              [158.981326, 66.239923],
              [158.945251, 66.252313],
              [158.92082, 66.253602],
              [158.90159, 66.265099],
              [158.783008, 66.265943],
              [158.780927, 66.275854],
              [158.74961, 66.288937],
              [158.7172, 66.294388],
              [158.7231, 66.315994],
              [158.688511, 66.319563],
              [158.644111, 66.336957],
              [158.601593, 66.339138],
              [158.543444, 66.335235],
              [158.551015, 66.342661],
              [158.507455, 66.356178],
              [158.498739, 66.369821],
              [158.465944, 66.375204],
              [158.466687, 66.386071],
              [158.40425, 66.415158],
              [158.390142, 66.404874],
              [158.370252, 66.411157],
              [158.368873, 66.430309],
              [158.397622, 66.434672],
              [158.417477, 66.44803],
              [158.504391, 66.4629],
              [158.490742, 66.502967],
              [158.544228, 66.530127],
              [158.535021, 66.548431],
              [158.57364, 66.560691],
              [158.606415, 66.558864],
              [158.63624, 66.566424],
              [158.644722, 66.592493],
              [158.669226, 66.601431],
              [158.666922, 66.615566],
              [158.72259, 66.641765],
              [158.713382, 66.659665],
              [158.723779, 66.673912],
              [158.71122, 66.689077],
              [158.77551, 66.726084],
              [158.811654, 66.71975],
              [158.841305, 66.731155],
              [158.843127, 66.741986],
              [158.88028, 66.751201],
              [158.9202, 66.751728],
              [158.960036, 66.760587],
              [158.934868, 66.768745],
              [158.893509, 66.803769],
              [158.85824, 66.817353],
              [158.847127, 66.837239],
              [158.88054, 66.844751],
              [158.860867, 66.854178],
              [158.8632, 66.869575],
              [158.841209, 66.871343],
              [158.787335, 66.89387],
              [158.809047, 66.908021],
              [158.785948, 66.916132],
              [158.783817, 66.930799],
              [158.815415, 66.94042],
              [158.811792, 66.962764],
              [158.782125, 66.979273],
              [158.781459, 66.989897],
              [158.740084, 67.002832],
              [158.746824, 67.021642],
              [158.71176, 67.038389],
              [158.663363, 67.049827],
              [158.643645, 67.062734],
              [158.618001, 67.05745],
              [158.574056, 67.064713],
              [158.540513, 67.080172],
              [158.479141, 67.08831],
              [158.43693, 67.103686],
              [158.378919, 67.101907],
              [158.311666, 67.139337],
              [158.277883, 67.150695],
              [158.242582, 67.144659],
              [158.238178, 67.167357],
              [158.199036, 67.171969],
              [158.184807, 67.185583],
              [158.160574, 67.189819],
              [158.171108, 67.19888],
              [158.110039, 67.213935],
              [158.093155, 67.227682],
              [158.051201, 67.227525],
              [158.016554, 67.233673],
              [158.002632, 67.247197],
              [157.924251, 67.259511],
              [157.879196, 67.284386],
              [157.839066, 67.286079],
              [157.811372, 67.308325],
              [157.84261, 67.333261],
              [157.807727, 67.351499],
              [157.849176, 67.364113],
              [157.86616, 67.381504],
              [157.89918, 67.399359],
              [157.907708, 67.420455],
              [157.793054, 67.449008],
              [157.788038, 67.476399],
              [157.79945, 67.489086],
              [157.762218, 67.500002],
              [157.730565, 67.518002],
              [157.74368, 67.523743],
              [157.744088, 67.557574],
              [157.78304, 67.574687],
              [157.758624, 67.594201],
              [157.773519, 67.602328],
              [157.777557, 67.622253],
              [157.809631, 67.6448],
              [157.84812, 67.659477],
              [157.810091, 67.674568],
              [157.942039, 67.72397],
              [157.991944, 67.711679],
              [158.0833, 67.718131],
              [158.115615, 67.710335],
              [158.1168, 67.697089],
              [158.15075, 67.701194],
              [158.169867, 67.694815],
              [158.18225, 67.714712],
              [158.239228, 67.708721],
              [158.316615, 67.723052],
              [158.362234, 67.723638],
              [158.36058, 67.734226],
              [158.314675, 67.758049],
              [158.318766, 67.77327],
              [158.293781, 67.785093],
              [158.285982, 67.805521],
              [158.249488, 67.824492],
              [158.212334, 67.830204],
              [158.181783, 67.819633],
              [158.148686, 67.825202],
              [158.106031, 67.821721],
              [158.081936, 67.836157],
              [158.079696, 67.875019],
              [158.09895, 67.889414],
              [158.103566, 67.914021],
              [158.150225, 67.916965],
              [158.215908, 67.934821],
              [158.235019, 67.964085],
              [158.253323, 67.966535],
              [158.276, 67.984673],
              [158.273534, 67.998201],
              [158.298679, 68.002909],
              [158.30916, 68.018818],
              [158.291787, 68.034494],
              [158.300103, 68.047854],
              [158.32669, 68.050031],
              [158.325145, 68.060556],
              [158.373762, 68.063619],
              [158.372391, 68.076162],
              [158.52692, 68.096885],
              [158.646984, 68.127407],
              [158.705533, 68.113723],
              [158.726653, 68.116504],
              [158.770846, 68.108175],
              [158.806252, 68.121998],
              [158.880165, 68.108243],
              [158.893296, 68.10158],
              [158.937036, 68.120201],
              [158.980483, 68.123932],
              [159.032074, 68.149087],
              [159.050112, 68.148367],
              [159.149323, 68.169485],
              [159.211853, 68.168098],
              [159.251936, 68.190598],
              [159.41762, 68.186571],
              [159.421084, 68.196032],
              [159.479905, 68.2022],
              [159.519636, 68.211605],
              [159.562946, 68.214363],
              [159.571052, 68.20796],
              [159.610554, 68.210857],
              [159.656572, 68.222842],
              [159.684328, 68.203597],
              [159.71826, 68.197332],
              [159.723907, 68.182196],
              [159.743854, 68.17864],
              [159.80139, 68.186179],
              [159.790401, 68.203685],
              [159.811305, 68.204376],
              [159.791751, 68.221162],
              [159.827464, 68.248196],
              [159.944142, 68.259624],
              [159.996778, 68.25186],
              [160.026359, 68.263318],
              [160.072613, 68.267002],
              [160.107415, 68.259679],
              [160.18506, 68.264599],
              [160.22938, 68.262074],
              [160.315998, 68.248802],
              [160.339679, 68.251563],
              [160.397983, 68.23932],
              [160.424518, 68.248848],
              [160.492392, 68.24924],
              [160.521456, 68.244884],
              [160.687007, 68.25067],
              [160.73171, 68.247989],
              [160.753468, 68.257634],
              [160.862201, 68.270017],
              [160.955187, 68.272423],
              [161.028757, 68.284424],
              [161.068762, 68.302327],
              [161.136985, 68.314666],
              [161.199905, 68.341693],
              [161.314502, 68.36157],
              [161.327292, 68.368581],
              [161.44974, 68.377936],
              [161.53387, 68.373988],
              [161.604907, 68.377016],
              [161.648387, 68.374933],
              [161.680545, 68.365356],
              [161.710646, 68.345468],
              [161.74553, 68.351468],
              [161.795702, 68.347973],
              [161.832294, 68.354943],
              [161.893656, 68.340347],
              [161.952261, 68.356581],
              [161.984553, 68.359083],
              [162.054609, 68.338086],
              [162.194525, 68.33462],
              [162.206016, 68.315894],
              [162.256169, 68.305486],
              [162.293248, 68.305501],
              [162.312949, 68.293189],
              [162.35531, 68.28684],
              [162.39375, 68.274591],
              [162.447526, 68.284103],
              [162.464571, 68.306676],
              [162.509277, 68.316693],
              [162.54795, 68.333041],
              [162.532366, 68.355276],
              [162.5452, 68.364126],
              [162.556008, 68.39305],
              [162.59491, 68.416396],
              [162.616563, 68.43732],
              [162.600113, 68.463629],
              [162.563824, 68.483344],
              [162.590326, 68.499859],
              [162.64623, 68.509754],
              [162.664538, 68.535849],
              [162.722343, 68.561084],
              [162.723938, 68.573763],
              [162.751685, 68.583955],
              [162.727942, 68.598712],
              [162.709587, 68.658241],
              [162.652767, 68.666866],
              [162.603191, 68.68753],
              [162.591219, 68.713933],
              [162.600451, 68.72875],
              [162.588524, 68.741601],
              [162.591608, 68.777632],
              [162.66112, 68.770112],
              [162.733122, 68.769073],
              [162.770738, 68.794234],
              [162.772709, 68.806893],
              [162.79937, 68.822854],
              [162.855209, 68.831229],
              [162.846724, 68.843782],
              [162.802471, 68.85326],
              [162.761457, 68.882807],
              [162.773897, 68.903257],
              [162.726529, 68.928539],
              [162.66426, 68.941105],
              [162.616054, 68.957342],
              [162.57955, 68.961211],
              [162.539667, 68.982584],
              [162.558471, 69.004701],
              [162.551378, 69.021705],
              [162.577096, 69.035302],
              [162.560909, 69.045326],
              [162.529824, 69.046234],
              [162.510844, 69.067557],
              [162.512406, 69.084911],
              [162.531907, 69.105638],
              [162.62488, 69.124027],
              [162.685317, 69.123839],
              [162.686757, 69.145103],
              [162.714982, 69.153991],
              [162.764771, 69.154624],
              [162.82646, 69.183649],
              [162.772473, 69.181351],
              [162.72572, 69.208189],
              [162.724771, 69.223296],
              [162.69896, 69.244011],
              [162.636688, 69.26048],
              [162.584566, 69.285107],
              [162.562997, 69.28877],
              [162.513718, 69.327776],
              [162.545741, 69.355219],
              [162.543629, 69.372961],
              [162.489371, 69.383634],
              [162.460298, 69.3802],
              [162.3495, 69.406387],
              [162.359094, 69.417791],
              [162.406185, 69.424936],
              [162.43208, 69.454206],
              [162.417086, 69.470847],
              [162.357457, 69.497936],
              [162.32461, 69.52943],
              [162.342853, 69.531982],
              [162.342288, 69.54897],
              [162.412578, 69.549421],
              [162.394562, 69.571793],
              [162.36618, 69.584414],
              [162.32411, 69.590994],
              [162.348009, 69.59612],
              [162.378298, 69.616001],
              [162.488798, 69.632718],
              [162.469443, 69.654961],
            ],
          ],
          [
            [
              [168.219265, 69.638036],
              [168.227148, 69.66092],
              [168.249991, 69.653974],
              [168.257485, 69.638098],
              [168.219265, 69.638036],
            ],
          ],
          [
            [
              [170.048762, 69.687613],
              [170.038365, 69.69586],
              [170.06429, 69.703871],
              [170.091969, 69.697125],
              [170.07752, 69.684191],
              [170.048762, 69.687613],
            ],
          ],
          [
            [
              [170.141127, 69.710184],
              [170.121123, 69.702309],
              [170.107886, 69.71782],
              [170.046935, 69.728681],
              [169.994845, 69.727223],
              [169.971814, 69.733002],
              [170.08614, 69.765618],
              [170.128289, 69.771444],
              [170.24972, 69.774145],
              [170.141127, 69.710184],
            ],
          ],
          [
            [
              [169.385596, 69.892552],
              [169.329817, 69.91083],
              [169.278559, 69.918862],
              [169.223826, 69.920943],
              [169.174461, 69.938758],
              [169.101192, 69.937907],
              [169.090489, 69.948013],
              [169.220003, 69.935248],
              [169.314715, 69.917089],
              [169.385596, 69.892552],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5525",
      properties: {
        name: "Ямало-Ненецкий автономный округ",
        density: 0,
        path: "/world/Russia/Ямало-Ненецкий автономный округ",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [71.636, 66.90468],
              [71.65268, 66.89621],
              [71.6097, 66.90243],
              [71.636, 66.90468],
            ],
          ],
          [
            [
              [71.69966, 66.88519],
              [71.7048, 66.87576],
              [71.66399, 66.87747],
              [71.69966, 66.88519],
            ],
          ],
          [
            [
              [67.40769, 68.76258],
              [67.39271, 68.75528],
              [67.34051, 68.76514],
              [67.32904, 68.75825],
              [67.28078, 68.77144],
              [67.25413, 68.78741],
              [67.21366, 68.799],
              [67.17754, 68.81728],
              [67.25313, 68.81808],
              [67.3043, 68.81464],
              [67.34739, 68.80547],
              [67.39879, 68.78347],
              [67.40769, 68.76258],
            ],
          ],
          [
            [
              [66.4817, 68.94856],
              [66.43553, 68.96629],
              [66.41551, 68.9821],
              [66.3622, 69.00188],
              [66.29722, 69.01404],
              [66.16061, 69.06606],
              [66.06321, 69.07222],
              [65.99226, 69.07291],
              [65.97304, 69.08767],
              [65.98801, 69.09343],
              [66.05417, 69.09151],
              [66.17305, 69.07509],
              [66.21873, 69.06471],
              [66.3157, 69.02635],
              [66.39588, 69.00355],
              [66.46464, 68.96391],
              [66.54696, 68.92138],
              [66.51547, 68.92348],
              [66.4817, 68.94856],
            ],
          ],
          [
            [
              [67.32353, 69.48098],
              [67.32096, 69.44874],
              [67.28665, 69.43639],
              [67.22153, 69.43229],
              [67.13636, 69.44175],
              [67.06226, 69.45667],
              [67.03032, 69.47152],
              [67.06343, 69.50028],
              [67.1011, 69.51143],
              [67.12059, 69.53056],
              [67.1611, 69.55221],
              [67.21376, 69.56963],
              [67.33205, 69.5823],
              [67.38131, 69.52678],
              [67.3666, 69.49929],
              [67.32353, 69.48098],
            ],
          ],
          [
            [
              [66.796875, 70.41961],
              [66.89912, 70.38488],
              [66.87163, 70.38584],
              [66.80754, 70.40144],
              [66.69925, 70.44305],
              [66.73187, 70.44142],
              [66.76453, 70.42546],
              [66.796875, 70.41961],
            ],
          ],
          [
            [
              [72.18441, 66.35113],
              [72.2238, 66.3649],
              [72.24729, 66.35186],
              [72.22859, 66.33792],
              [72.1825, 66.33861],
              [72.18441, 66.35113],
            ],
          ],
          [
            [
              [71.015625, 66.38083],
              [71.01063, 66.37326],
              [70.9697, 66.38785],
              [71.00381, 66.40761],
              [71.02859, 66.40615],
              [71.00739, 66.38839],
              [71.015625, 66.38083],
            ],
          ],
          [
            [
              [70.87059, 66.44022],
              [70.93261, 66.44458],
              [70.8924, 66.43702],
              [70.87059, 66.44022],
            ],
          ],
          [
            [
              [70.08664, 66.43857],
              [70.11342, 66.42303],
              [70.07342, 66.4283],
              [70.06254, 66.41252],
              [70.03242, 66.40991],
              [70.013, 66.41899],
              [70.02774, 66.43602],
              [70.05871, 66.4431],
              [70.08664, 66.43857],
            ],
          ],
          [
            [
              [71.13266, 66.52615],
              [71.17653, 66.5364],
              [71.17634, 66.52324],
              [71.13266, 66.52615],
            ],
          ],
          [
            [
              [69.33048, 66.61266],
              [69.35618, 66.60979],
              [69.34961, 66.60009],
              [69.33048, 66.61266],
            ],
          ],
          [
            [
              [76.699742, 73.125],
              [76.6846, 73.11735],
              [76.5218, 73.12321],
              [76.44734, 73.12133],
              [76.34642, 73.12611],
              [76.25527, 73.13684],
              [76.16079, 73.1559],
              [76.11251, 73.18603],
              [76.14146, 73.19645],
              [76.20687, 73.19695],
              [76.31104, 73.18846],
              [76.60098, 73.1691],
              [76.60344, 73.17684],
              [76.68491, 73.1445],
              [76.699742, 73.125],
            ],
          ],
          [
            [
              [75.60835, 73.4604],
              [75.60272, 73.4682],
              [75.65863, 73.51373],
              [75.79217, 73.51992],
              [75.79466, 73.49716],
              [75.82747, 73.48721],
              [75.87697, 73.44869],
              [75.91677, 73.43736],
              [75.90447, 73.43193],
              [75.74419, 73.42143],
              [75.65841, 73.4258],
              [75.67303, 73.4336],
              [75.60825, 73.44937],
              [75.60835, 73.4604],
            ],
          ],
          [
            [
              [70.104362, 73.004628],
              [69.959042, 73.014428],
              [69.937942, 73.052858],
              [70.025447, 73.179154],
              [70.04653, 73.25032],
              [70.04106, 73.32854],
              [70.01199, 73.37902],
              [70.19548, 73.41126],
              [70.29484, 73.43571],
              [70.34373, 73.44231],
              [70.63667, 73.45965],
              [70.74569, 73.47283],
              [70.83509, 73.47756],
              [70.94557, 73.46966],
              [71.04108, 73.45751],
              [71.15511, 73.43681],
              [71.26194, 73.41114],
              [71.23029, 73.40176],
              [71.20712, 73.41632],
              [71.17216, 73.42296],
              [71.10349, 73.42519],
              [71.09864, 73.40032],
              [71.13439, 73.36442],
              [71.10048, 73.35352],
              [71.09586, 73.33031],
              [71.12896, 73.31361],
              [71.16023, 73.31815],
              [71.15793, 73.35294],
              [71.22506, 73.36169],
              [71.26573, 73.38579],
              [71.30616, 73.38739],
              [71.31195, 73.37736],
              [71.36685, 73.3545],
              [71.33554, 73.35383],
              [71.33731, 73.33413],
              [71.36749, 73.31397],
              [71.33443, 73.31295],
              [71.31503, 73.29782],
              [71.24766, 73.2891],
              [71.25843, 73.26956],
              [71.18786, 73.27299],
              [71.11307, 73.26393],
              [71.07665, 73.27094],
              [71.00006, 73.26863],
              [71.00953, 73.25473],
              [71.08391, 73.25758],
              [71.19844, 73.25154],
              [71.23146, 73.25323],
              [71.26748, 73.24057],
              [71.33537, 73.27406],
              [71.40486, 73.28849],
              [71.38088, 73.29504],
              [71.42545, 73.32109],
              [71.47654, 73.30491],
              [71.56545, 73.26292],
              [71.63133, 73.21787],
              [71.67921, 73.15562],
              [71.58317, 73.16208],
              [71.54826, 73.15868],
              [71.36688, 73.15458],
              [71.308, 73.12942],
              [71.19218, 73.10461],
              [71.08213, 73.09591],
              [71.00411, 73.09745],
              [70.91267, 73.10484],
              [70.83711, 73.09684],
              [70.68502, 73.0685],
              [70.60165, 73.05843],
              [70.44906, 73.01522],
              [70.37273, 73.01192],
              [70.3122, 73.02075],
              [70.307422, 73.027838],
              [70.207152, 73.026028],
              [70.183152, 73.033198],
              [70.171672, 73.070698],
              [70.100542, 73.082838],
              [70.023372, 73.053478],
              [70.070902, 73.028828],
              [70.135372, 73.011488],
              [70.104362, 73.004628],
            ],
          ],
          [
            [
              [62.000444, 65.717736],
              [62.026009, 65.725843],
              [62.066415, 65.728674],
              [62.133162, 65.75227],
              [62.17072, 65.751395],
              [62.229096, 65.757906],
              [62.270043, 65.783781],
              [62.308086, 65.784366],
              [62.353647, 65.806328],
              [62.406354, 65.809564],
              [62.468907, 65.823102],
              [62.496709, 65.847722],
              [62.554679, 65.838638],
              [62.563529, 65.851148],
              [62.618258, 65.848709],
              [62.642781, 65.855652],
              [62.687134, 65.845543],
              [62.698536, 65.858617],
              [62.748267, 65.856293],
              [62.751791, 65.866437],
              [62.799675, 65.857698],
              [62.84062, 65.878156],
              [62.833679, 65.888324],
              [62.775354, 65.902371],
              [62.822601, 65.942017],
              [62.846927, 65.953058],
              [62.904052, 65.965107],
              [62.884004, 65.979192],
              [62.831275, 65.997422],
              [62.792307, 66.000908],
              [62.851145, 66.023981],
              [62.869189, 66.064291],
              [62.925695, 66.084021],
              [62.95548, 66.100588],
              [62.933659, 66.110951],
              [62.968793, 66.114505],
              [62.957961, 66.128016],
              [62.994195, 66.158134],
              [62.986638, 66.172368],
              [63.031789, 66.185552],
              [63.028633, 66.200695],
              [63.058787, 66.206278],
              [63.089206, 66.220227],
              [63.107787, 66.247136],
              [63.138085, 66.256855],
              [63.178859, 66.24684],
              [63.202564, 66.233634],
              [63.219912, 66.237197],
              [63.250304, 66.222002],
              [63.278412, 66.219457],
              [63.310583, 66.248885],
              [63.270776, 66.265564],
              [63.262144, 66.283247],
              [63.234032, 66.30107],
              [63.243029, 66.315291],
              [63.232851, 66.332681],
              [63.292663, 66.339047],
              [63.28396, 66.350307],
              [63.302604, 66.358524],
              [63.308081, 66.391106],
              [63.331256, 66.413166],
              [63.364195, 66.426125],
              [63.382189, 66.443279],
              [63.416008, 66.451572],
              [63.418482, 66.466617],
              [63.447751, 66.468432],
              [63.510363, 66.457538],
              [63.554035, 66.474148],
              [63.585116, 66.498071],
              [63.626707, 66.510935],
              [63.633352, 66.519951],
              [63.66223, 66.515292],
              [63.7477, 66.524034],
              [63.767974, 66.533668],
              [63.802101, 66.531855],
              [63.838289, 66.546448],
              [63.851482, 66.575783],
              [63.881065, 66.577164],
              [63.931975, 66.594275],
              [63.993179, 66.639145],
              [64.038427, 66.634757],
              [64.125606, 66.647817],
              [64.159809, 66.642068],
              [64.174878, 66.651075],
              [64.261545, 66.645425],
              [64.269698, 66.659842],
              [64.301372, 66.659457],
              [64.325124, 66.667262],
              [64.337775, 66.699157],
              [64.395068, 66.72215],
              [64.443604, 66.720463],
              [64.458896, 66.71398],
              [64.496341, 66.719664],
              [64.53158, 66.71483],
              [64.544723, 66.728846],
              [64.505894, 66.742547],
              [64.506595, 66.752184],
              [64.555757, 66.769045],
              [64.553329, 66.796648],
              [64.611075, 66.797046],
              [64.630696, 66.808178],
              [64.658993, 66.800424],
              [64.714178, 66.810624],
              [64.708453, 66.831029],
              [64.768064, 66.827809],
              [64.778325, 66.835303],
              [64.814796, 66.836576],
              [64.834147, 66.828869],
              [64.877128, 66.834849],
              [64.921903, 66.849913],
              [64.939573, 66.863404],
              [64.979456, 66.860543],
              [65.05176, 66.876503],
              [65.054753, 66.884621],
              [65.139159, 66.907727],
              [65.145955, 66.935037],
              [65.140295, 66.955128],
              [65.117673, 66.960843],
              [65.111176, 67.006182],
              [65.081882, 67.039277],
              [65.127976, 67.061901],
              [65.205406, 67.067292],
              [65.244684, 67.09368],
              [65.205183, 67.110883],
              [65.214029, 67.13368],
              [65.18091, 67.142545],
              [65.223308, 67.161529],
              [65.262585, 67.163616],
              [65.263249, 67.171558],
              [65.302119, 67.185523],
              [65.318911, 67.203139],
              [65.36408, 67.215417],
              [65.405533, 67.219455],
              [65.426237, 67.209387],
              [65.458811, 67.221479],
              [65.50968, 67.249252],
              [65.556663, 67.256931],
              [65.570202, 67.279413],
              [65.619661, 67.303601],
              [65.658006, 67.294547],
              [65.668852, 67.299021],
              [65.665897, 67.32427],
              [65.746773, 67.333938],
              [65.74717, 67.34372],
              [65.69548, 67.36228],
              [65.698554, 67.380796],
              [65.795274, 67.391766],
              [65.827343, 67.387172],
              [65.85407, 67.396179],
              [65.922929, 67.404582],
              [65.992447, 67.403085],
              [66.010179, 67.409108],
              [66.040582, 67.443929],
              [66.06951, 67.464988],
              [66.093311, 67.495058],
              [66.091752, 67.514165],
              [66.060745, 67.540621],
              [66.059843, 67.56531],
              [66.048175, 67.580506],
              [65.971078, 67.560416],
              [65.893079, 67.552989],
              [65.876801, 67.542269],
              [65.822687, 67.538601],
              [65.788083, 67.568425],
              [65.806473, 67.588875],
              [65.836791, 67.601611],
              [65.813708, 67.626718],
              [65.78579, 67.642054],
              [65.845715, 67.667578],
              [65.904437, 67.666742],
              [65.960725, 67.653882],
              [65.976326, 67.672615],
              [65.996714, 67.67737],
              [66.044432, 67.674547],
              [66.117604, 67.647701],
              [66.161257, 67.649424],
              [66.220695, 67.684884],
              [66.224767, 67.718531],
              [66.25068, 67.733047],
              [66.226747, 67.753885],
              [66.185166, 67.758957],
              [66.163149, 67.772819],
              [66.110666, 67.782191],
              [66.055807, 67.783174],
              [66.065215, 67.798077],
              [66.021731, 67.809888],
              [66.074298, 67.831083],
              [66.080581, 67.849507],
              [66.047795, 67.866182],
              [66.057176, 67.879331],
              [66.119031, 67.88728],
              [66.133509, 67.914682],
              [66.07661, 67.938814],
              [66.020693, 67.932718],
              [65.987643, 67.944247],
              [65.945242, 67.950062],
              [65.920661, 67.96083],
              [65.866145, 67.956043],
              [65.766259, 67.921182],
              [65.750547, 67.91861],
              [65.690547, 67.929199],
              [65.5432, 67.932502],
              [65.4894, 67.920222],
              [65.453463, 67.918514],
              [65.432183, 67.90966],
              [65.385561, 67.928762],
              [65.382919, 67.944782],
              [65.357862, 67.950353],
              [65.319394, 68.016118],
              [65.331834, 68.05422],
              [65.348994, 68.063172],
              [65.336759, 68.08832],
              [65.352139, 68.107793],
              [65.322088, 68.110485],
              [65.322634, 68.119883],
              [65.288958, 68.153982],
              [65.310891, 68.173499],
              [65.311004, 68.190353],
              [65.280376, 68.198504],
              [65.284121, 68.227142],
              [65.274676, 68.234566],
              [65.324039, 68.268639],
              [65.364489, 68.282158],
              [65.373345, 68.313321],
              [65.405622, 68.33647],
              [65.411423, 68.361759],
              [65.436016, 68.363875],
              [65.428235, 68.378696],
              [65.44049, 68.396327],
              [65.472578, 68.417891],
              [65.51067, 68.435016],
              [65.515125, 68.464719],
              [65.54192, 68.469838],
              [65.550141, 68.484215],
              [65.609221, 68.489609],
              [65.593241, 68.515511],
              [65.628645, 68.534101],
              [65.659721, 68.540906],
              [65.670532, 68.563628],
              [65.658298, 68.581045],
              [65.603516, 68.581606],
              [65.56128, 68.590539],
              [65.561211, 68.608162],
              [65.517197, 68.618311],
              [65.497763, 68.636243],
              [65.436069, 68.646778],
              [65.425481, 68.661362],
              [65.437586, 68.673458],
              [65.403742, 68.683976],
              [65.426367, 68.697146],
              [65.463755, 68.695572],
              [65.468167, 68.710267],
              [65.448928, 68.73369],
              [65.384136, 68.719959],
              [65.35088, 68.729614],
              [65.353261, 68.743744],
              [65.398303, 68.778002],
              [65.396731, 68.793298],
              [65.347424, 68.788339],
              [65.346071, 68.8123],
              [65.305789, 68.803284],
              [65.253883, 68.809826],
              [65.229804, 68.804397],
              [65.22558, 68.820371],
              [65.186805, 68.810914],
              [65.1469, 68.813516],
              [65.139807, 68.803589],
              [65.085294, 68.810969],
              [65.029112, 68.831514],
              [65.02436, 68.844186],
              [64.963436, 68.843816],
              [64.911532, 68.863604],
              [64.919129, 68.876499],
              [64.865264, 68.862601],
              [64.854588, 68.875255],
              [64.809513, 68.877657],
              [64.784693, 68.892985],
              [64.742446, 68.872436],
              [64.756903, 68.855895],
              [64.687459, 68.859042],
              [64.654625, 68.866444],
              [64.590996, 68.86092],
              [64.557261, 68.867615],
              [64.575929, 68.896119],
              [64.551408, 68.910664],
              [64.624637, 68.932736],
              [64.568502, 68.961855],
              [64.557475, 68.972606],
              [64.518135, 68.978933],
              [64.53918, 69.008155],
              [64.538948, 69.028795],
              [64.613376, 69.039797],
              [64.647438, 69.063295],
              [64.711538, 69.079576],
              [64.720055, 69.112431],
              [64.735405, 69.120174],
              [64.760528, 69.126732],
              [64.801416, 69.136606],
              [64.829245, 69.155683],
              [64.844651, 69.154939],
              [64.896678, 69.177919],
              [65.025041, 69.193918],
              [65.033576, 69.225393],
              [65.077134, 69.236169],
              [65.059762, 69.246286],
              [65.083685, 69.260027],
              [65.09096, 69.26492],
              [65.14536, 69.25155],
              [65.19101, 69.23004],
              [65.20195, 69.21492],
              [65.2937, 69.19042],
              [65.44605, 69.16125],
              [65.66321, 69.13259],
              [65.81355, 69.11561],
              [65.9362, 69.09507],
              [65.93261, 69.0849],
              [65.95818, 69.06579],
              [65.87497, 69.08233],
              [65.8452, 69.07769],
              [65.83597, 69.0921],
              [65.81756, 69.08805],
              [65.73365, 69.10797],
              [65.7163, 69.09459],
              [65.71577, 69.07423],
              [65.75812, 69.06864],
              [65.80028, 69.04948],
              [65.88539, 69.05936],
              [65.95197, 69.03843],
              [66.04011, 69.02603],
              [66.06685, 69.02892],
              [66.16151, 69.00391],
              [66.2191, 68.98286],
              [66.28098, 68.98961],
              [66.30605, 68.9799],
              [66.29509, 68.96182],
              [66.33846, 68.93871],
              [66.41651, 68.92815],
              [66.63833, 68.88098],
              [66.75678, 68.86685],
              [66.92335, 68.85145],
              [67.03168, 68.83573],
              [67.14946, 68.81494],
              [67.12722, 68.81047],
              [67.09689, 68.81794],
              [67.06133, 68.81216],
              [67.04373, 68.79534],
              [67.06629, 68.78767],
              [67.06871, 68.75983],
              [67.12499, 68.75304],
              [67.15949, 68.7322],
              [67.17572, 68.70169],
              [67.228, 68.68102],
              [67.2681, 68.67134],
              [67.32775, 68.67194],
              [67.38275, 68.65931],
              [67.40195, 68.64594],
              [67.54947, 68.58873],
              [67.6128, 68.57162],
              [67.68924, 68.53746],
              [67.70152, 68.51225],
              [67.81366, 68.48802],
              [67.84624, 68.47061],
              [67.74926, 68.49382],
              [67.71819, 68.48886],
              [67.78761, 68.45435],
              [67.84465, 68.45411],
              [67.8964, 68.44831],
              [67.98686, 68.43126],
              [68.06405, 68.4124],
              [68.15496, 68.39474],
              [68.20076, 68.37426],
              [68.20494, 68.36056],
              [68.24347, 68.34046],
              [68.26366, 68.31537],
              [68.23223, 68.30436],
              [68.23187, 68.29465],
              [68.18886, 68.28051],
              [68.17126, 68.24246],
              [68.17418, 68.22842],
              [68.19658, 68.21817],
              [68.23437, 68.21988],
              [68.26984, 68.20867],
              [68.2925, 68.18755],
              [68.29716, 68.17019],
              [68.34051, 68.17931],
              [68.34056, 68.18954],
              [68.37319, 68.20321],
              [68.43476, 68.21647],
              [68.51323, 68.24816],
              [68.54614, 68.25669],
              [68.54711, 68.27689],
              [68.58396, 68.29973],
              [68.62673, 68.3029],
              [68.59918, 68.31792],
              [68.59191, 68.35701],
              [68.66783, 68.42185],
              [68.8333, 68.53482],
              [68.8931, 68.58216],
              [68.96432, 68.64941],
              [68.99166, 68.70565],
              [69.04702, 68.77823],
              [69.07107, 68.79791],
              [69.13578, 68.81474],
              [69.10658, 68.84109],
              [69.10663, 68.85182],
              [69.05571, 68.86871],
              [69.02383, 68.86899],
              [68.97281, 68.88176],
              [69.02173, 68.89594],
              [69.08059, 68.89324],
              [69.1438, 68.90751],
              [69.16805, 68.92538],
              [69.15784, 68.93664],
              [69.07857, 68.92404],
              [69.04663, 68.9238],
              [69.06157, 68.9399],
              [68.98624, 68.93697],
              [68.91168, 68.92493],
              [68.8663, 68.9134],
              [68.85006, 68.90099],
              [68.793404, 68.90625],
              [68.77129, 68.90308],
              [68.60909, 68.92809],
              [68.52404, 68.95242],
              [68.49101, 68.96611],
              [68.43467, 68.99917],
              [68.38197, 69.06135],
              [68.32871, 69.09694],
              [68.23494, 69.14818],
              [68.11943, 69.2192],
              [68.07435, 69.26369],
              [68.07277, 69.27519],
              [68.03907, 69.31922],
              [68.03543, 69.344],
              [68.07349, 69.35136],
              [68.11278, 69.37329],
              [68.10199, 69.38014],
              [68.14301, 69.46112],
              [68.12631, 69.48685],
              [68.13329, 69.51245],
              [68.10277, 69.53568],
              [68.02608, 69.51929],
              [68.00821, 69.50335],
              [68.05262, 69.48033],
              [67.98598, 69.47216],
              [67.89668, 69.49488],
              [67.86617, 69.49276],
              [67.81247, 69.50135],
              [67.79328, 69.51835],
              [67.71909, 69.53827],
              [67.69205, 69.55034],
              [67.7048, 69.57523],
              [67.68347, 69.58287],
              [67.62784, 69.58257],
              [67.57913, 69.58845],
              [67.51174, 69.61486],
              [67.43607, 69.61177],
              [67.38384, 69.60427],
              [67.33123, 69.62091],
              [67.24579, 69.63848],
              [67.20639, 69.65725],
              [67.13482, 69.6606],
              [67.06516, 69.69104],
              [67.02748, 69.69255],
              [66.97211, 69.67107],
              [67.02153, 69.65373],
              [67.02601, 69.63716],
              [66.87523, 69.61034],
              [66.87347, 69.58755],
              [66.89535, 69.56993],
              [66.92976, 69.55859],
              [66.97484, 69.50362],
              [66.95362, 69.51181],
              [66.91776, 69.50525],
              [66.88066, 69.52628],
              [66.859167, 69.52602],
              [66.93406, 69.45737],
              [66.96504, 69.45313],
              [66.98103, 69.46124],
              [67.03204, 69.43689],
              [67.14341, 69.42751],
              [67.19052, 69.41624],
              [67.18592, 69.39318],
              [67.16718, 69.36179],
              [67.08125, 69.36636],
              [66.99978, 69.39267],
              [66.93068, 69.4471],
              [66.92749, 69.46509],
              [66.89263, 69.48986],
              [66.85036, 69.52974],
              [66.827, 69.56909],
              [66.80769, 69.7036],
              [66.81091, 69.7438],
              [66.84777, 69.8964],
              [66.89425, 69.96293],
              [66.94968, 70.01727],
              [67.00799, 70.04862],
              [66.98454, 70.0279],
              [67.0207, 70.00674],
              [66.96086, 70.00232],
              [66.95383, 69.99022],
              [66.9726, 69.95847],
              [67.03936, 69.94605],
              [67.09136, 69.95594],
              [67.10326, 69.9644],
              [67.1731, 69.96729],
              [67.20339, 69.97936],
              [67.26401, 69.97715],
              [67.33798, 69.99447],
              [67.34217, 70.01701],
              [67.37115, 70.02878],
              [67.38291, 70.06519],
              [67.37064, 70.107],
              [67.30889, 70.12765],
              [67.31036, 70.14658],
              [67.29138, 70.17606],
              [67.26859, 70.18274],
              [67.24991, 70.16313],
              [67.22129, 70.16336],
              [67.15958, 70.17578],
              [67.12798, 70.21687],
              [67.20575, 70.312],
              [67.21214, 70.36038],
              [67.22372, 70.37148],
              [67.28781, 70.40073],
              [67.27941, 70.41906],
              [67.32063, 70.42303],
              [67.33358, 70.44181],
              [67.29732, 70.48301],
              [67.25164, 70.5029],
              [67.23196, 70.5224],
              [67.28006, 70.56044],
              [67.27837, 70.60286],
              [67.326, 70.63602],
              [67.36797, 70.65046],
              [67.35559, 70.68869],
              [67.376762, 70.72966],
              [67.363452, 70.74117],
              [67.398612, 70.73714],
              [67.39104, 70.75726],
              [67.35424, 70.77125],
              [67.35645, 70.782],
              [67.30949, 70.79589],
              [67.29465, 70.81058],
              [67.21847, 70.83249],
              [67.099, 70.82886],
              [67.07245, 70.82182],
              [67.06187, 70.80265],
              [67.02266, 70.79841],
              [66.90917, 70.77696],
              [66.76191, 70.76845],
              [66.69215, 70.74777],
              [66.71276, 70.76746],
              [66.7244, 70.80494],
              [66.69596, 70.82913],
              [66.63775, 70.84425],
              [66.64391, 70.86785],
              [66.70398, 70.88779],
              [66.77519, 70.92088],
              [66.78515, 70.94017],
              [66.83877, 70.96151],
              [66.84957, 70.99897],
              [66.89227, 71.00329],
              [66.90297, 71.01339],
              [66.87052, 71.04114],
              [66.91154, 71.05848],
              [66.92797, 71.07439],
              [66.86442, 71.07929],
              [66.81568, 71.08883],
              [66.7936, 71.08192],
              [66.80907, 71.06345],
              [66.75605, 71.04491],
              [66.81653, 71.01035],
              [66.80488, 70.99154],
              [66.76037, 70.98973],
              [66.73266, 70.9715],
              [66.67014, 70.96331],
              [66.61406, 70.96388],
              [66.61178, 70.95362],
              [66.64293, 70.9287],
              [66.56683, 70.91161],
              [66.46526, 70.82278],
              [66.45661, 70.83264],
              [66.48093, 70.85266],
              [66.57864, 70.96521],
              [66.70104, 71.0647],
              [66.72644, 71.09365],
              [66.74119, 71.09561],
              [66.81573, 71.14508],
              [66.87654, 71.20037],
              [66.93259, 71.26989],
              [67.0544, 71.285],
              [67.17345, 71.30587],
              [67.28927, 71.33009],
              [67.41688, 71.36022],
              [67.51203, 71.39064],
              [67.59961, 71.43016],
              [67.75937, 71.46501],
              [67.96034, 71.52218],
              [68.00633, 71.537515],
              [67.95033, 71.49887],
              [68.02516, 71.49927],
              [68.0044, 71.51168],
              [68.05594, 71.52804],
              [68.05385, 71.53774],
              [68.09611, 71.54791],
              [68.13251, 71.5813],
              [68.15253, 71.58548],
              [68.22357, 71.62769],
              [68.26053, 71.63576],
              [68.30521, 71.65535],
              [68.35965, 71.70128],
              [68.35821, 71.70741],
              [68.43027, 71.75976],
              [68.47883, 71.80359],
              [68.49999, 71.80723],
              [68.50399, 71.82615],
              [68.5313, 71.84717],
              [68.62938, 72.00657],
              [68.657917, 72.046765],
              [68.68998, 72.11501],
              [68.731484, 72.16343],
              [68.78551, 72.2664],
              [68.84123, 72.41439],
              [68.86798, 72.4558],
              [68.94692, 72.545],
              [68.970475, 72.593147],
              [68.98727, 72.65819],
              [69.01728, 72.68834],
              [69.21692, 72.76998],
              [69.2478, 72.78962],
              [69.28224, 72.83079],
              [69.37946, 72.93377],
              [69.39927, 72.94369],
              [69.61722, 72.95273],
              [69.79722, 72.94585],
              [69.83769, 72.91809],
              [69.88037, 72.90232],
              [69.78904, 72.91892],
              [69.74509, 72.90747],
              [69.68949, 72.90753],
              [69.60491, 72.89848],
              [69.58203, 72.88935],
              [69.57531, 72.85641],
              [69.67349, 72.85254],
              [69.7568, 72.85645],
              [69.81002, 72.86455],
              [69.91425, 72.86896],
              [70.11633, 72.87012],
              [70.27669, 72.88145],
              [70.36428, 72.88101],
              [70.45921, 72.87473],
              [70.549, 72.87994],
              [70.63963, 72.87476],
              [70.8083, 72.87001],
              [70.93517, 72.87229],
              [70.95341, 72.88029],
              [71.12184, 72.88805],
              [71.32272, 72.87053],
              [71.39652, 72.87095],
              [71.49885, 72.87675],
              [71.62484, 72.88936],
              [71.75529, 72.86257],
              [71.95179, 72.82899],
              [72.02077, 72.81966],
              [71.99401, 72.80216],
              [71.93402, 72.80534],
              [71.99207, 72.78044],
              [72.04113, 72.77946],
              [72.06582, 72.79494],
              [72.05291, 72.80281],
              [72.095305, 72.81823],
              [72.25965, 72.7891],
              [72.65054, 72.7315],
              [72.84788, 72.69174],
              [72.85328, 72.68088],
              [72.91625, 72.68197],
              [72.96412, 72.65953],
              [72.94966, 72.64649],
              [72.91146, 72.63855],
              [72.9079, 72.61046],
              [72.96289, 72.57072],
              [72.86796, 72.59997],
              [72.8243, 72.60977],
              [72.79342, 72.60536],
              [72.75935, 72.61431],
              [72.69291, 72.59099],
              [72.72128, 72.55463],
              [72.71489, 72.53607],
              [72.76389, 72.49727],
              [72.81974, 72.49595],
              [72.79208, 72.48573],
              [72.82649, 72.4756],
              [72.7849, 72.46564],
              [72.77608, 72.4438],
              [72.78095, 72.38177],
              [72.79139, 72.36493],
              [72.79, 72.33194],
              [72.82637, 72.29438],
              [72.83379, 72.25353],
              [72.77646, 72.24415],
              [72.71107, 72.20495],
              [72.6927, 72.18431],
              [72.69667, 72.16248],
              [72.67939, 72.14283],
              [72.70218, 72.11298],
              [72.59109, 72.07487],
              [72.56651, 72.05076],
              [72.53291, 71.99852],
              [72.51188, 71.93531],
              [72.45069, 71.88088],
              [72.38876, 71.86125],
              [72.32668, 71.85159],
              [72.30497, 71.82151],
              [72.3189, 71.70306],
              [72.16906, 71.66901],
              [72.1524, 71.64703],
              [72.16472, 71.64299],
              [72.14527, 71.59905],
              [72.02678, 71.57718],
              [72.0134, 71.56153],
              [71.96162, 71.54814],
              [71.92053, 71.54878],
              [71.84938, 71.52389],
              [71.82634, 71.5071],
              [71.81935, 71.47881],
              [71.8287, 71.45489],
              [71.88982, 71.40434],
              [71.98729, 71.35832],
              [72.00957, 71.3242],
              [72.04738, 71.28548],
              [72.0855, 71.27462],
              [72.11407, 71.2542],
              [72.26012, 71.20004],
              [72.30235, 71.18702],
              [72.38263, 71.16985],
              [72.53754, 71.14587],
              [72.66759, 71.05863],
              [72.67459, 71.04558],
              [72.64135, 71.05737],
              [72.61901, 71.05371],
              [72.58139, 71.02014],
              [72.58242, 70.9848],
              [72.68223, 70.92014],
              [72.73387, 70.90554],
              [72.787, 70.87681],
              [72.80283, 70.84321],
              [72.77911, 70.79839],
              [72.76416, 70.79127],
              [72.76157, 70.7322],
              [72.74812, 70.70176],
              [72.74681, 70.66364],
              [72.72533, 70.65614],
              [72.68553, 70.62649],
              [72.67896, 70.58004],
              [72.70689, 70.53519],
              [72.74359, 70.5178],
              [72.74987, 70.48337],
              [72.73886, 70.39657],
              [72.71983, 70.37584],
              [72.70537, 70.39265],
              [72.6522, 70.38515],
              [72.57964, 70.36242],
              [72.5561, 70.34522],
              [72.48276, 70.32081],
              [72.41989, 70.28311],
              [72.40858, 70.24008],
              [72.43972, 70.21824],
              [72.50851, 70.19184],
              [72.54534, 70.18215],
              [72.58836, 70.13241],
              [72.58445, 70.11201],
              [72.54504, 70.07722],
              [72.54437, 70.06009],
              [72.49406, 70.03293],
              [72.48399, 70.01086],
              [72.49846, 69.98113],
              [72.5299, 69.95057],
              [72.58731, 69.90694],
              [72.63217, 69.88017],
              [72.66699, 69.82416],
              [72.67425, 69.78075],
              [72.66915, 69.75782],
              [72.63558, 69.72601],
              [72.57425, 69.69937],
              [72.54653, 69.67689],
              [72.53906, 69.65862],
              [72.50081, 69.63635],
              [72.55163, 69.59079],
              [72.56518, 69.5644],
              [72.59902, 69.52839],
              [72.63573, 69.50127],
              [72.63602, 69.45135],
              [72.61431, 69.42416],
              [72.56657, 69.39072],
              [72.51412, 69.41213],
              [72.48401, 69.39687],
              [72.51666, 69.39212],
              [72.55964, 69.36641],
              [72.57837, 69.33712],
              [72.60949, 69.30651],
              [72.6092, 69.26787],
              [72.51824, 69.22598],
              [72.48788, 69.19409],
              [72.47922, 69.15792],
              [72.47871, 69.1029],
              [72.4853, 69.07461],
              [72.52101, 69.00043],
              [72.55244, 68.94986],
              [72.60677, 68.89676],
              [72.68436, 68.85081],
              [72.75731, 68.81696],
              [72.92425, 68.76427],
              [73.05913, 68.72537],
              [73.11012, 68.71505],
              [73.16349, 68.68762],
              [73.22488, 68.66227],
              [73.42645, 68.5969],
              [73.48337, 68.57285],
              [73.54789, 68.53858],
              [73.58605, 68.50782],
              [73.60397, 68.4553],
              [73.59077, 68.42288],
              [73.56724, 68.41328],
              [73.58893, 68.46879],
              [73.55351, 68.47643],
              [73.50509, 68.46454],
              [73.45157, 68.43177],
              [73.42788, 68.40858],
              [73.41978, 68.36883],
              [73.35746, 68.33896],
              [73.25961, 68.30512],
              [73.21571, 68.28114],
              [73.19158, 68.25896],
              [73.12447, 68.24117],
              [73.07717, 68.20623],
              [73.09284, 68.19175],
              [73.08627, 68.1781],
              [73.10232, 68.15235],
              [73.03867, 68.09732],
              [73.0331, 68.07337],
              [73.04281, 68.05061],
              [73.09173, 68.00996],
              [73.16791, 67.95683],
              [73.19077, 67.93242],
              [73.19284, 67.87039],
              [73.173563, 67.83498],
              [73.09801, 67.82362],
              [73.054628, 67.794653],
              [73.05802, 67.780259],
              [73.10583, 67.72355],
              [73.10571, 67.70742],
              [73.0849, 67.722613],
              [73.060205, 67.714781],
              [72.970364, 67.702823],
              [72.966167, 67.69107],
              [72.921938, 67.694465],
              [72.885158, 67.685518],
              [72.85694, 67.6628],
              [72.8708, 67.64685],
              [72.86042, 67.63276],
              [72.80977, 67.6209],
              [72.72186, 67.60873],
              [72.69929, 67.60182],
              [72.64808, 67.6049],
              [72.56299, 67.60372],
              [72.52068, 67.58109],
              [72.55721, 67.56314],
              [72.55073, 67.51772],
              [72.5141, 67.49394],
              [72.45434, 67.48269],
              [72.41187, 67.46363],
              [72.40514, 67.44227],
              [72.42005, 67.40919],
              [72.44354, 67.39435],
              [72.4273, 67.37627],
              [72.41796, 67.33193],
              [72.38918, 67.31142],
              [72.32853, 67.31384],
              [72.24802, 67.30193],
              [72.20978, 67.32803],
              [72.15136, 67.31255],
              [72.12465, 67.30093],
              [72.08366, 67.3067],
              [72.04297, 67.29417],
              [72.03418, 67.26131],
              [72.0663, 67.24869],
              [72.13802, 67.24103],
              [72.15949, 67.24355],
              [72.1628, 67.25858],
              [72.18845, 67.24758],
              [72.22953, 67.19173],
              [72.22913, 67.17639],
              [72.20784, 67.14022],
              [72.18247, 67.14252],
              [72.19431, 67.15738],
              [72.17797, 67.16334],
              [72.15257, 67.1504],
              [72.11839, 67.15594],
              [72.06151, 67.15396],
              [72.02602, 67.14402],
              [72.00618, 67.13006],
              [71.97308, 67.12032],
              [71.97595, 67.10888],
              [72.02565, 67.0885],
              [71.99211, 67.07479],
              [71.98934, 67.06564],
              [72.02352, 67.0609],
              [72.02571, 67.03642],
              [71.95805, 67.035],
              [71.95858, 67.01785],
              [71.92226, 67.018],
              [71.89, 67.00273],
              [71.85228, 66.97117],
              [71.7964, 66.95681],
              [71.7461, 66.90447],
              [71.71054, 66.90406],
              [71.69764, 66.92884],
              [71.66948, 66.93674],
              [71.59598, 66.93885],
              [71.5658, 66.92489],
              [71.57156, 66.91456],
              [71.52212, 66.90602],
              [71.46192, 66.93907],
              [71.44561, 66.95531],
              [71.39009, 66.96831],
              [71.34689, 66.95719],
              [71.31886, 66.94046],
              [71.34698, 66.93442],
              [71.39969, 66.93311],
              [71.41131, 66.91862],
              [71.43505, 66.91493],
              [71.47689, 66.89688],
              [71.46804, 66.89306],
              [71.4075, 66.89987],
              [71.35657, 66.89859],
              [71.36423, 66.88351],
              [71.39548, 66.8902],
              [71.47706, 66.87923],
              [71.55434, 66.86521],
              [71.51931, 66.85349],
              [71.49208, 66.83499],
              [71.48719, 66.82201],
              [71.54912, 66.7462],
              [71.58871, 66.74722],
              [71.64365, 66.72226],
              [71.64909, 66.70067],
              [71.62252, 66.69383],
              [71.61584, 66.67962],
              [71.58935, 66.67941],
              [71.57104, 66.66461],
              [71.59371, 66.65938],
              [71.59052, 66.64127],
              [71.51509, 66.62842],
              [71.44007, 66.62551],
              [71.43609, 66.61125],
              [71.38204, 66.60479],
              [71.32235, 66.61348],
              [71.32434, 66.62777],
              [71.27209, 66.63417],
              [71.239, 66.61009],
              [71.26599, 66.60254],
              [71.33839, 66.59918],
              [71.3851, 66.58312],
              [71.30324, 66.58102],
              [71.30846, 66.59565],
              [71.24918, 66.59818],
              [71.23422, 66.60709],
              [71.17752, 66.59893],
              [71.16864, 66.58575],
              [71.18813, 66.57704],
              [71.13063, 66.5699],
              [71.13148, 66.55736],
              [71.08294, 66.54521],
              [71.07905, 66.53709],
              [71.02661, 66.53931],
              [71.00353, 66.54766],
              [70.98344, 66.52912],
              [70.89004, 66.52414],
              [70.86461, 66.51856],
              [70.77744, 66.51011],
              [70.75658, 66.49602],
              [70.71968, 66.49042],
              [70.67708, 66.4951],
              [70.64002, 66.50624],
              [70.56931, 66.51285],
              [70.53245, 66.52843],
              [70.46642, 66.53993],
              [70.40776, 66.55786],
              [70.37689, 66.55711],
              [70.38468, 66.54706],
              [70.31478, 66.57247],
              [70.26817, 66.57822],
              [70.25935, 66.60694],
              [70.28978, 66.61692],
              [70.29809, 66.63424],
              [70.33265, 66.64213],
              [70.41677, 66.6483],
              [70.42992, 66.67516],
              [70.36551, 66.6655],
              [70.35201, 66.6545],
              [70.31024, 66.65689],
              [70.34739, 66.66425],
              [70.34265, 66.67209],
              [70.25659, 66.67945],
              [70.19274, 66.69337],
              [70.15618, 66.71231],
              [70.18795, 66.72819],
              [70.182456, 66.734513],
              [70.10109, 66.73751],
              [70.07822, 66.74687],
              [70.06508, 66.76486],
              [69.98972, 66.79859],
              [69.99214, 66.81853],
              [69.95225, 66.85226],
              [69.89251, 66.85338],
              [69.86597, 66.81926],
              [69.90738, 66.81622],
              [69.90893, 66.80547],
              [69.878338, 66.80239],
              [69.85267, 66.81],
              [69.71957, 66.78356],
              [69.68598, 66.76415],
              [69.65549, 66.76147],
              [69.64346, 66.74694],
              [69.76126, 66.74885],
              [69.80164, 66.73701],
              [69.82758, 66.72001],
              [69.8681, 66.71442],
              [69.87453, 66.72282],
              [69.79329, 66.75879],
              [69.78706, 66.76649],
              [69.83581, 66.77238],
              [69.93147, 66.75011],
              [70.02794, 66.73597],
              [70.03534, 66.72722],
              [70.07932, 66.71286],
              [70.04923, 66.70436],
              [69.97724, 66.71314],
              [69.96271, 66.72154],
              [69.91326, 66.73002],
              [69.89465, 66.72501],
              [69.9147, 66.71646],
              [69.88541, 66.71061],
              [69.97972, 66.69408],
              [69.9998, 66.69587],
              [70.10119, 66.68177],
              [70.11304, 66.67273],
              [70.16221, 66.66215],
              [70.1641, 66.64731],
              [70.12532, 66.64477],
              [70.1235, 66.61896],
              [70.08853, 66.6141],
              [70.06378, 66.62219],
              [70.02866, 66.61347],
              [70.0547, 66.59029],
              [70.102, 66.56951],
              [70.10473, 66.55888],
              [70.14017, 66.56144],
              [70.16496, 66.54246],
              [70.12591, 66.53021],
              [70.14733, 66.52188],
              [70.12127, 66.51261],
              [70.07063, 66.50504],
              [70.08585, 66.49406],
              [70.07124, 66.48201],
              [70.03997, 66.48402],
              [70.01711, 66.51229],
              [69.98435, 66.51864],
              [70.000199, 66.49059],
              [69.98103, 66.49948],
              [69.94358, 66.49153],
              [69.94184, 66.47927],
              [69.86031, 66.47207],
              [69.8436, 66.4889],
              [69.76825, 66.51627],
              [69.71848, 66.52405],
              [69.66597, 66.55023],
              [69.6145, 66.5709],
              [69.57687, 66.59686],
              [69.60206, 66.58808],
              [69.61987, 66.59338],
              [69.62191, 66.61075],
              [69.54403, 66.66755],
              [69.54097, 66.69006],
              [69.51265, 66.70984],
              [69.4992, 66.73136],
              [69.45041, 66.7324],
              [69.45202, 66.71488],
              [69.43817, 66.69881],
              [69.44823, 66.68396],
              [69.41209, 66.68329],
              [69.38413, 66.66968],
              [69.35639, 66.66911],
              [69.40963, 66.65134],
              [69.39137, 66.63809],
              [69.40827, 66.62782],
              [69.33138, 66.63183],
              [69.29024, 66.66515],
              [69.28502, 66.6874],
              [69.30062, 66.69708],
              [69.32608, 66.69346],
              [69.33888, 66.70877],
              [69.28612, 66.72703],
              [69.23339, 66.75626],
              [69.21076, 66.75834],
              [69.15853, 66.78832],
              [69.22342, 66.79834],
              [69.26878, 66.7966],
              [69.28928, 66.77599],
              [69.37677, 66.77925],
              [69.45873, 66.79732],
              [69.52721, 66.78662],
              [69.54834, 66.78974],
              [69.52568, 66.80606],
              [69.44668, 66.81244],
              [69.3993, 66.81065],
              [69.39772, 66.79301],
              [69.34652, 66.79338],
              [69.28515, 66.81064],
              [69.12756, 66.81404],
              [69.13595, 66.80572],
              [69.12431, 66.81315],
              [69.06302, 66.81497],
              [68.99329, 66.79338],
              [68.95376, 66.78621],
              [68.85976, 66.79511],
              [68.81441, 66.79263],
              [68.73935, 66.78089],
              [68.7039, 66.76587],
              [68.64054, 66.75973],
              [68.60194, 66.76539],
              [68.58911, 66.7582],
              [68.61811, 66.74524],
              [68.53005, 66.74998],
              [68.4338, 66.74302],
              [68.42262, 66.72342],
              [68.44853, 66.72096],
              [68.44137, 66.70866],
              [68.40427, 66.70698],
              [68.372841, 66.692373],
              [68.41826, 66.68889],
              [68.42554, 66.68137],
              [68.48913, 66.68436],
              [68.43412, 66.67297],
              [68.47427, 66.66531],
              [68.61759, 66.69564],
              [68.54456, 66.66502],
              [68.54152, 66.65504],
              [68.48365, 66.61753],
              [68.3993, 66.59268],
              [68.34343, 66.56574],
              [68.29324, 66.54673],
              [68.23137, 66.53442],
              [68.20475, 66.52444],
              [68.17638, 66.52915],
              [68.08495, 66.52174],
              [68.00812, 66.5191],
              [68.00185, 66.53952],
              [67.98031, 66.55626],
              [67.96517, 66.54976],
              [67.98398, 66.52969],
              [67.95712, 66.51225],
              [68.01491, 66.50956],
              [68.05264, 66.50301],
              [68.04946, 66.49405],
              [68.07719, 66.48884],
              [68.1013, 66.49499],
              [68.18639, 66.4975],
              [68.262, 66.5182],
              [68.27896, 66.5303],
              [68.36242, 66.5515],
              [68.41896, 66.57665],
              [68.46694, 66.58627],
              [68.48981, 66.59888],
              [68.5359, 66.61104],
              [68.5559, 66.64383],
              [68.64182, 66.67217],
              [68.68667, 66.69553],
              [68.70194, 66.71423],
              [68.74506, 66.72977],
              [68.78687, 66.75425],
              [68.89099, 66.76842],
              [68.96351, 66.76896],
              [68.98294, 66.76487],
              [69.04443, 66.76551],
              [69.11179, 66.77084],
              [69.16484, 66.763],
              [69.18305, 66.73281],
              [69.14077, 66.68607],
              [69.13128, 66.66],
              [69.1275, 66.615],
              [69.15862, 66.60168],
              [69.18931, 66.5794],
              [69.34973, 66.51751],
              [69.44851, 66.48555],
              [69.49143, 66.48747],
              [69.55857, 66.48353],
              [69.76726, 66.46335],
              [69.84278, 66.44557],
              [69.87042, 66.41346],
              [69.90376, 66.39971],
              [70.03023, 66.38768],
              [70.09618, 66.3793],
              [70.17491, 66.35878],
              [70.25117, 66.35525],
              [70.39359, 66.3277],
              [70.57214, 66.33405],
              [70.61972, 66.33178],
              [70.80138, 66.34461],
              [70.86494, 66.35154],
              [70.93044, 66.35023],
              [71.00856, 66.35538],
              [71.06078, 66.34741],
              [71.17965, 66.35205],
              [71.3123, 66.34669],
              [71.4785, 66.33326],
              [71.56216, 66.31706],
              [71.61697, 66.31794],
              [71.7012, 66.30243],
              [71.74719, 66.28089],
              [71.76674, 66.25341],
              [71.80074, 66.23669],
              [71.89803, 66.23874],
              [71.96901, 66.24892],
              [72.00666, 66.234852],
              [72.03948, 66.233442],
              [72.06351, 66.240643],
              [72.06941, 66.218772],
              [72.11402, 66.24095],
              [72.21017, 66.261],
              [72.25152, 66.28369],
              [72.29737, 66.29399],
              [72.26119, 66.30678],
              [72.24143, 66.29414],
              [72.22594, 66.29984],
              [72.2294, 66.32434],
              [72.28906, 66.34715],
              [72.31094, 66.34873],
              [72.33416, 66.33646],
              [72.36373, 66.35259],
              [72.40248, 66.35162],
              [72.39485, 66.36356],
              [72.40842, 66.37658],
              [72.39881, 66.40699],
              [72.35997, 66.44598],
              [72.359, 66.45777],
              [72.33266, 66.46422],
              [72.30574, 66.48578],
              [72.3188, 66.50444],
              [72.38426, 66.51892],
              [72.42087, 66.53783],
              [72.47168, 66.58893],
              [72.59668, 66.60762],
              [72.71266, 66.62067],
              [72.82667, 66.62433],
              [72.91481, 66.64354],
              [72.92611, 66.65375],
              [72.9735, 66.66946],
              [72.98627, 66.68451],
              [72.97758, 66.69676],
              [72.9898, 66.71233],
              [73.03057, 66.7282],
              [73.14498, 66.75007],
              [73.28191, 66.78073],
              [73.36762, 66.79554],
              [73.48189, 66.82014],
              [73.52382, 66.83419],
              [73.5609, 66.85336],
              [73.56725, 66.87341],
              [73.61048, 66.90394],
              [73.62445, 66.9312],
              [73.68273, 66.93872],
              [73.80058, 66.96799],
              [73.876, 66.99978],
              [73.88444, 67.01246],
              [73.87382, 67.04444],
              [73.92062, 67.06479],
              [73.92463, 67.08286],
              [73.90907, 67.11308],
              [73.93173, 67.158],
              [73.9516, 67.18404],
              [73.95397, 67.20678],
              [73.9415, 67.22973],
              [73.91378, 67.25429],
              [73.92174, 67.2898],
              [74.002699, 67.326315],
              [74.07416, 67.39874],
              [74.13708, 67.44327],
              [74.30653, 67.5083],
              [74.54937, 67.59427],
              [74.66382, 67.6411],
              [74.72454, 67.67462],
              [74.75314, 67.69773],
              [74.78664, 67.75273],
              [74.79282, 67.77355],
              [74.78831, 67.80732],
              [74.80768, 67.86872],
              [74.82029, 67.95166],
              [74.80835, 68.0034],
              [74.79182, 68.02887],
              [74.73079, 68.07],
              [74.72877, 68.09496],
              [74.6512, 68.18178],
              [74.61105, 68.22188],
              [74.58031, 68.24459],
              [74.5257, 68.27168],
              [74.44273, 68.29956],
              [74.42515, 68.31449],
              [74.31523, 68.36035],
              [74.32049, 68.37685],
              [74.4538, 68.48154],
              [74.47055, 68.50305],
              [74.47464, 68.53138],
              [74.44643, 68.56231],
              [74.44446, 68.65828],
              [74.45452, 68.68207],
              [74.49683, 68.71224],
              [74.54938, 68.73335],
              [74.66327, 68.76163],
              [74.77306, 68.78383],
              [74.91981, 68.80813],
              [75.03, 68.82208],
              [75.14311, 68.84086],
              [75.23642, 68.86184],
              [75.3366, 68.88123],
              [75.42897, 68.89278],
              [75.4996, 68.89792],
              [75.63106, 68.89656],
              [75.67059, 68.90233],
              [75.75345, 68.92372],
              [75.9375, 68.937431],
              [75.99999, 68.94522],
              [76.09295, 68.96313],
              [76.20645, 68.97584],
              [76.37009, 68.97818],
              [76.43139, 68.9751],
              [76.48807, 68.97699],
              [76.56315, 68.97268],
              [76.60538, 68.95943],
              [76.60878, 68.93518],
              [76.64086, 68.91884],
              [76.69997, 68.84735],
              [76.66579, 68.81728],
              [76.64318, 68.8062],
              [76.62033, 68.77667],
              [76.66462, 68.7399],
              [76.74097, 68.71341],
              [76.83268, 68.69034],
              [76.9003, 68.66331],
              [76.94004, 68.65352],
              [76.9966, 68.63207],
              [76.98967, 68.62961],
              [77.03802, 68.60233],
              [77.05998, 68.58313],
              [77.17807, 68.56845],
              [77.21182, 68.55718],
              [77.25081, 68.51427],
              [77.28734, 68.49384],
              [77.30753, 68.49198],
              [77.33124, 68.47484],
              [77.29713, 68.47883],
              [77.24465, 68.47151],
              [77.22637, 68.45792],
              [77.2382, 68.42029],
              [77.25931, 68.39773],
              [77.28487, 68.38806],
              [77.29157, 68.3662],
              [77.23957, 68.34506],
              [77.2334, 68.32367],
              [77.15863, 68.31121],
              [77.15691, 68.29068],
              [77.12627, 68.27564],
              [77.12818, 68.26442],
              [77.165, 68.2467],
              [77.23337, 68.23803],
              [77.31415, 68.23767],
              [77.33699, 68.2264],
              [77.32234, 68.20175],
              [77.2866, 68.17587],
              [77.28906, 68.14571],
              [77.20643, 68.10518],
              [77.1997, 68.07737],
              [77.22774, 68.03577],
              [77.20385, 68.00015],
              [77.18079, 67.98886],
              [77.20502, 67.97636],
              [77.19193, 67.9502],
              [77.23092, 67.91987],
              [77.26144, 67.91416],
              [77.30184, 67.88897],
              [77.2567, 67.85491],
              [77.22993, 67.82742],
              [77.17929, 67.81382],
              [77.12498, 67.8329],
              [77.09967, 67.82411],
              [77.06759, 67.79927],
              [77.08317, 67.78505],
              [77.07518, 67.77231],
              [77.11548, 67.75356],
              [77.15887, 67.74024],
              [77.2701, 67.71594],
              [77.35642, 67.68564],
              [77.43485, 67.67035],
              [77.53799, 67.65556],
              [77.57215, 67.64272],
              [77.61389, 67.61186],
              [77.65729, 67.61121],
              [77.68226, 67.58639],
              [77.70125, 67.59446],
              [77.75205, 67.57629],
              [77.77325, 67.55598],
              [77.81744, 67.56351],
              [77.82488, 67.55802],
              [77.88389, 67.5625],
              [77.9405, 67.5495],
              [77.93754, 67.53614],
              [77.96836, 67.53058],
              [77.99312, 67.53577],
              [78.02327, 67.53006],
              [78.20249, 67.55859],
              [78.24304, 67.56107],
              [78.26557, 67.53996],
              [78.30822, 67.52967],
              [78.36781, 67.52899],
              [78.46429, 67.53347],
              [78.46983, 67.55044],
              [78.50617, 67.56124],
              [78.57158, 67.56325],
              [78.59817, 67.55853],
              [78.63566, 67.56522],
              [78.67256, 67.56447],
              [78.73188, 67.57427],
              [78.74964, 67.58475],
              [78.7913, 67.58353],
              [78.77457, 67.59763],
              [78.82321, 67.59355],
              [78.82521, 67.60829],
              [78.78221, 67.61615],
              [78.84053, 67.62422],
              [78.82131, 67.63611],
              [78.78113, 67.63481],
              [78.71096, 67.64509],
              [78.66537, 67.63063],
              [78.66825, 67.5946],
              [78.65694, 67.58702],
              [78.61493, 67.58487],
              [78.64948, 67.60124],
              [78.66216, 67.63263],
              [78.64425, 67.6605],
              [78.60989, 67.66793],
              [78.58972, 67.69731],
              [78.54518, 67.71442],
              [78.51707, 67.71259],
              [78.50815, 67.72678],
              [78.48875, 67.71906],
              [78.43558, 67.72437],
              [78.43083, 67.73402],
              [78.38859, 67.72874],
              [78.34972, 67.74449],
              [78.40601, 67.76637],
              [78.36968, 67.77705],
              [78.35365, 67.7993],
              [78.32413, 67.81494],
              [78.26114, 67.8153],
              [78.24379, 67.79595],
              [78.29082, 67.78113],
              [78.24854, 67.75561],
              [78.18134, 67.75293],
              [78.19288, 67.74391],
              [78.19156, 67.71831],
              [78.2315, 67.71694],
              [78.2051, 67.70651],
              [78.15708, 67.7108],
              [78.15845, 67.68831],
              [78.14722, 67.66712],
              [78.11301, 67.66627],
              [78.10749, 67.67564],
              [78.07358, 67.67504],
              [78.04671, 67.68542],
              [77.99869, 67.68186],
              [77.92619, 67.69573],
              [77.85457, 67.66711],
              [77.81791, 67.65962],
              [77.81057, 67.66907],
              [77.89066, 67.68674],
              [77.9164, 67.70361],
              [77.95326, 67.69995],
              [77.95895, 67.70799],
              [77.92849, 67.7142],
              [77.85006, 67.72089],
              [77.79581, 67.71817],
              [77.76451, 67.74468],
              [77.73677, 67.74029],
              [77.73939, 67.73113],
              [77.68735, 67.72701],
              [77.67802, 67.71185],
              [77.63828, 67.70694],
              [77.61865, 67.7215],
              [77.54577, 67.72177],
              [77.46639, 67.75616],
              [77.45928, 67.76957],
              [77.48617, 67.80215],
              [77.46893, 67.81995],
              [77.46632, 67.88415],
              [77.48644, 67.88817],
              [77.52064, 67.91385],
              [77.55207, 67.91746],
              [77.58629, 67.91139],
              [77.60449, 67.93109],
              [77.58083, 67.9393],
              [77.56265, 67.92868],
              [77.50039, 67.93991],
              [77.47646, 67.97421],
              [77.48317, 67.99074],
              [77.5204, 68.01764],
              [77.5173, 68.04726],
              [77.49, 68.07382],
              [77.52489, 68.1173],
              [77.55432, 68.13609],
              [77.61713, 68.16196],
              [77.67138, 68.1907],
              [77.73817, 68.20982],
              [77.80807, 68.21731],
              [77.96549, 68.21988],
              [78.11661, 68.23435],
              [78.164, 68.24693],
              [78.15808, 68.26397],
              [78.1024, 68.29218],
              [78.04762, 68.3122],
              [78.08146, 68.33428],
              [78.03926, 68.35797],
              [77.95463, 68.37398],
              [77.92117, 68.40074],
              [77.91975, 68.43168],
              [77.86885, 68.45018],
              [77.87729, 68.46723],
              [77.9257, 68.46467],
              [77.94799, 68.47493],
              [77.95268, 68.49702],
              [77.92701, 68.51139],
              [77.88751, 68.51949],
              [77.83164, 68.5196],
              [77.79051, 68.50935],
              [77.77328, 68.52488],
              [77.77795, 68.55568],
              [77.70544, 68.60673],
              [77.69701, 68.63063],
              [77.73187, 68.63951],
              [77.74881, 68.66056],
              [77.74445, 68.70986],
              [77.70901, 68.76881],
              [77.68306, 68.78647],
              [77.6884, 68.86923],
              [77.66849, 68.88368],
              [77.61898, 68.89882],
              [77.5513, 68.90925],
              [77.45736, 68.91449],
              [77.37048, 68.91112],
              [77.36432, 68.92046],
              [77.30725, 68.93639],
              [77.28306, 68.95327],
              [77.22227, 68.97549],
              [77.12938, 68.9807],
              [77.01597, 68.99256],
              [76.94047, 69.00333],
              [76.8248, 69.03896],
              [76.81553, 69.0838],
              [76.73014, 69.1173],
              [76.72797, 69.12659],
              [76.66631, 69.13696],
              [76.60763, 69.12855],
              [76.62608, 69.1189],
              [76.51838, 69.12586],
              [76.47363, 69.11994],
              [76.42624, 69.1233],
              [76.37934, 69.15484],
              [76.26845, 69.17847],
              [76.22565, 69.19625],
              [76.06383, 69.23576],
              [76.01662, 69.24004],
              [75.9724, 69.23575],
              [75.91434, 69.23989],
              [75.82513, 69.23266],
              [75.71673, 69.23796],
              [75.61324, 69.25238],
              [75.55398, 69.25285],
              [75.46309, 69.23959],
              [75.42251, 69.21993],
              [75.35282, 69.21159],
              [75.31814, 69.19927],
              [75.29379, 69.17886],
              [75.18523, 69.16124],
              [75.1286, 69.13683],
              [75.1216, 69.1145],
              [75.14594, 69.10644],
              [75.12764, 69.10016],
              [75.06027, 69.09833],
              [74.93355, 69.08955],
              [74.88925, 69.07979],
              [74.79056, 69.07315],
              [74.73717, 69.07482],
              [74.65159, 69.10743],
              [74.57754, 69.1214],
              [74.44543, 69.13079],
              [74.29082, 69.13087],
              [74.21051, 69.12282],
              [74.10456, 69.09088],
              [73.98841, 69.06932],
              [73.92473, 69.06988],
              [73.873, 69.07738],
              [73.82237, 69.12905],
              [73.78766, 69.15757],
              [73.77206, 69.18158],
              [73.803384, 69.23123],
              [73.86056, 69.29711],
              [73.90817, 69.36163],
              [73.91124, 69.38154],
              [73.89566, 69.4217],
              [73.85198, 69.48957],
              [73.78465, 69.54626],
              [73.7125, 69.58116],
              [73.65328, 69.60234],
              [73.599, 69.65194],
              [73.5816, 69.69237],
              [73.53464, 69.74175],
              [73.57455, 69.79631],
              [73.64089, 69.81481],
              [73.66831, 69.83306],
              [73.68006, 69.8558],
              [73.68145, 69.89368],
              [73.7384, 69.9347],
              [73.75142, 69.96408],
              [73.72722, 69.98753],
              [73.7657, 70.02494],
              [73.7848, 70.06722],
              [73.77527, 70.08618],
              [73.73192, 70.10373],
              [73.67825, 70.11258],
              [73.79638, 70.19683],
              [73.89133, 70.24654],
              [74.0203, 70.30296],
              [74.09907, 70.35227],
              [74.11971, 70.37835],
              [74.20976, 70.4173],
              [74.25565, 70.45289],
              [74.30406, 70.52533],
              [74.30479, 70.57351],
              [74.28876, 70.62397],
              [74.25377, 70.67037],
              [74.20673, 70.7053],
              [74.15729, 70.73156],
              [73.98737, 70.80649],
              [73.92004, 70.83983],
              [73.87532, 70.88971],
              [73.91443, 70.94018],
              [73.8919, 70.96795],
              [73.82795, 70.99705],
              [73.74301, 71.02639],
              [73.69986, 71.06003],
              [73.66098, 71.10559],
              [73.64108, 71.15502],
              [73.57614, 71.20427],
              [73.5349, 71.22679],
              [73.37587, 71.29588],
              [73.30973, 71.31789],
              [73.24421, 71.33423],
              [73.11434, 71.35889],
              [73.04331, 71.37526],
              [73.00405, 71.39837],
              [73.0214, 71.42002],
              [73.06014, 71.44378],
              [73.15166, 71.48404],
              [73.24755, 71.51675],
              [73.31532, 71.54877],
              [73.40404, 71.58285],
              [73.47529, 71.63268],
              [73.5138, 71.66994],
              [73.52777, 71.71136],
              [73.51275, 71.73388],
              [73.46042, 71.76648],
              [73.49756, 71.79353],
              [73.58176, 71.82306],
              [73.80331, 71.87096],
              [73.95463, 71.89853],
              [74.11698, 71.92237],
              [74.2614, 71.9485],
              [74.32456, 71.96408],
              [74.45035, 71.98379],
              [74.58164, 72.00982],
              [74.70941, 72.04202],
              [74.78763, 72.06613],
              [74.90566, 72.11366],
              [74.97109, 72.14938],
              [75.02766, 72.1918],
              [75.07337, 72.26021],
              [75.08337, 72.37489],
              [75.07302, 72.44015],
              [75.05868, 72.47601],
              [75.02339, 72.53996],
              [75.01466, 72.57898],
              [74.99135, 72.62204],
              [74.92317, 72.69873],
              [74.8348, 72.76059],
              [74.74302, 72.80711],
              [74.77424, 72.82699],
              [74.80296, 72.82719],
              [74.92947, 72.86766],
              [75.00077, 72.86993],
              [75.06371, 72.85291],
              [75.12547, 72.82782],
              [75.19165, 72.8208],
              [75.31439, 72.78747],
              [75.3769, 72.76599],
              [75.43746, 72.75147],
              [75.47385, 72.73259],
              [75.46131, 72.71835],
              [75.40832, 72.70438],
              [75.38368, 72.7175],
              [75.34614, 72.72125],
              [75.31233, 72.70511],
              [75.30726, 72.68961],
              [75.34934, 72.68186],
              [75.46344, 72.61375],
              [75.50555, 72.59492],
              [75.56271, 72.57996],
              [75.63558, 72.54286],
              [75.64951, 72.5239],
              [75.56673, 72.53011],
              [75.51136, 72.52128],
              [75.49063, 72.49344],
              [75.51984, 72.44214],
              [75.55729, 72.41753],
              [75.55434, 72.3742],
              [75.57796, 72.35519],
              [75.61937, 72.33633],
              [75.64594, 72.30718],
              [75.68181, 72.29903],
              [75.70589, 72.27611],
              [75.70147, 72.25031],
              [75.68644, 72.2426],
              [75.71569, 72.23287],
              [75.7305, 72.20795],
              [75.70675, 72.20157],
              [75.66955, 72.21363],
              [75.68244, 72.22707],
              [75.6323, 72.23725],
              [75.56034, 72.21963],
              [75.52689, 72.19301],
              [75.52244, 72.17106],
              [75.49914, 72.16208],
              [75.46438, 72.07283],
              [75.38769, 72.01501],
              [75.41887, 71.98984],
              [75.37314, 71.94487],
              [75.27045, 71.93744],
              [75.26301, 71.94975],
              [75.24112, 71.93017],
              [75.2411, 71.91528],
              [75.26723, 71.89466],
              [75.22161, 71.85687],
              [75.21438, 71.83758],
              [75.22198, 71.81089],
              [75.21927, 71.7717],
              [75.24494, 71.76008],
              [75.25714, 71.73144],
              [75.28174, 71.71806],
              [75.47658, 71.65172],
              [75.46468, 71.62284],
              [75.42644, 71.60128],
              [75.41697, 71.57566],
              [75.50121, 71.53582],
              [75.49846, 71.51588],
              [75.4399, 71.50426],
              [75.40897, 71.48322],
              [75.41865, 71.46389],
              [75.41054, 71.44208],
              [75.36887, 71.43681],
              [75.35244, 71.45046],
              [75.2847, 71.44682],
              [75.2353, 71.43254],
              [75.21718, 71.41517],
              [75.24159, 71.40359],
              [75.23697, 71.38935],
              [75.29854, 71.33185],
              [75.41104, 71.29646],
              [75.44649, 71.28998],
              [75.57407, 71.27926],
              [75.62224, 71.26343],
              [75.69637, 71.25129],
              [75.70824, 71.24406],
              [75.77922, 71.23238],
              [75.8119, 71.23454],
              [75.84838, 71.22253],
              [76.04979, 71.20885],
              [76.07298, 71.19895],
              [76.08035, 71.21068],
              [76.13018, 71.21172],
              [76.21043, 71.20546],
              [76.27148, 71.19625],
              [76.325289, 71.199055],
              [76.56434, 71.19023],
              [76.62876, 71.19674],
              [76.73093, 71.18707],
              [76.85971, 71.18598],
              [76.92034, 71.17681],
              [76.93216, 71.16196],
              [76.97134, 71.17178],
              [77.02574, 71.17717],
              [77.12102, 71.17251],
              [77.16746, 71.16207],
              [77.26873, 71.15861],
              [77.3341, 71.16477],
              [77.43789, 71.16473],
              [77.71024, 71.13721],
              [77.79423, 71.12652],
              [77.84688, 71.10388],
              [77.88944, 71.06699],
              [77.91435, 71.02877],
              [77.91267, 70.98935],
              [77.93584, 70.96901],
              [78.00265, 70.96269],
              [78.02538, 70.9523],
              [78.09839, 70.94578],
              [78.1699, 70.94945],
              [78.23755, 70.93039],
              [78.35034, 70.92347],
              [78.40386, 70.89098],
              [78.473062, 70.892432],
              [78.41893, 70.90116],
              [78.42309, 70.93831],
              [78.46613, 70.97496],
              [78.46699, 71.02023],
              [78.5108, 71.02799],
              [78.46749, 71.03469],
              [78.43248, 71.02577],
              [78.40392, 71.0278],
              [78.37218, 71.04181],
              [78.28377, 71.06676],
              [78.26763, 71.07803],
              [78.26698, 71.10539],
              [78.25245, 71.13305],
              [78.28007, 71.15315],
              [78.29625, 71.18791],
              [78.29934, 71.21992],
              [78.31165, 71.22854],
              [78.26667, 71.24912],
              [78.14118, 71.25083],
              [78.04626, 71.26842],
              [78, 71.26766],
              [77.97377, 71.24834],
              [77.93492, 71.2325],
              [77.91381, 71.24033],
              [77.90142, 71.26571],
              [77.90166, 71.29411],
              [77.94913, 71.32176],
              [77.9255, 71.33735],
              [77.88613, 71.33875],
              [77.85974, 71.32412],
              [77.89469, 71.32521],
              [77.84547, 71.30878],
              [77.74397, 71.30987],
              [77.7096, 71.30083],
              [77.68084, 71.2801],
              [77.71492, 71.26472],
              [77.62247, 71.27216],
              [77.53799, 71.28816],
              [77.4675, 71.29005],
              [77.38806, 71.30133],
              [77.2605, 71.32982],
              [77.19834, 71.34931],
              [77.15238, 71.37321],
              [77.13486, 71.3987],
              [77.07023, 71.41478],
              [76.98061, 71.41149],
              [76.91658, 71.41818],
              [76.8089, 71.44067],
              [76.71929, 71.45313],
              [76.65104, 71.46669],
              [76.62013, 71.47866],
              [76.61337, 71.49233],
              [76.58327, 71.50572],
              [76.50457, 71.52129],
              [76.47977, 71.53057],
              [76.4035, 71.54193],
              [76.3581, 71.55569],
              [76.30443, 71.56334],
              [76.25589, 71.59083],
              [76.2581, 71.61779],
              [76.28624, 71.64179],
              [76.27448, 71.6597],
              [76.23879, 71.68188],
              [76.16821, 71.70625],
              [76.13819, 71.73419],
              [76.11973, 71.77396],
              [76.1267, 71.80873],
              [76.07875, 71.87593],
              [76.05534, 71.882],
              [76.00018, 71.88032],
              [75.99798, 71.89473],
              [76.16926, 71.92716],
              [76.17085, 71.93694],
              [76.25121, 71.96839],
              [76.35554, 71.99782],
              [76.59509, 72.03208],
              [76.72894, 72.0453],
              [76.89165, 72.05277],
              [76.96824, 72.04041],
              [76.95589, 72.01711],
              [77.00423, 72.00429],
              [77.11659, 71.98173],
              [77.1662, 71.96173],
              [77.32029, 71.91761],
              [77.34458, 71.90297],
              [77.38898, 71.89049],
              [77.4198, 71.86702],
              [77.49143, 71.83992],
              [77.55956, 71.82631],
              [77.6503, 71.81396],
              [77.73675, 71.81621],
              [77.77854, 71.81141],
              [77.92349, 71.83654],
              [77.99667, 71.84563],
              [78.08964, 71.86508],
              [78.18066, 71.89878],
              [78.22574, 71.93038],
              [78.24013, 71.9669],
              [78.1885, 72.0191],
              [78.08036, 72.07762],
              [78.04178, 72.09034],
              [77.97689, 72.10086],
              [77.90575, 72.10346],
              [77.73113, 72.09345],
              [77.56858, 72.06418],
              [77.54147, 72.05373],
              [77.49041, 72.05601],
              [77.42793, 72.06575],
              [77.39654, 72.09691],
              [77.43163, 72.12417],
              [77.52173, 72.17441],
              [77.51353, 72.19346],
              [77.46973, 72.19728],
              [77.46313, 72.20765],
              [77.54435, 72.21191],
              [77.60822, 72.18782],
              [77.66487, 72.18662],
              [77.74152, 72.20684],
              [77.77321, 72.24318],
              [77.84076, 72.26474],
              [77.86627, 72.27998],
              [77.87961, 72.3027],
              [77.97543, 72.3157],
              [77.98054, 72.32806],
              [78.03813, 72.33123],
              [78.03416, 72.34524],
              [78.06565, 72.33292],
              [78.09193, 72.33715],
              [78.09745, 72.35539],
              [78.12318, 72.34732],
              [78.21427, 72.34146],
              [78.24811, 72.35166],
              [78.28495, 72.34769],
              [78.33453, 72.36588],
              [78.34797, 72.37795],
              [78.41276, 72.37944],
              [78.499157, 72.366584],
              [78.48371, 72.353842],
              [78.424233, 72.334442],
              [78.485855, 72.301407],
              [78.520242, 72.29383],
              [78.570749, 72.295288],
              [78.613903, 72.287387],
              [78.602525, 72.258172],
              [78.667055, 72.243517],
              [78.603167, 72.233909],
              [78.603707, 72.226794],
              [78.653866, 72.223482],
              [78.614171, 72.203066],
              [78.552386, 72.200763],
              [78.580285, 72.192207],
              [78.630646, 72.195669],
              [78.625833, 72.17134],
              [78.660146, 72.16653],
              [78.685192, 72.176796],
              [78.71507, 72.163972],
              [78.769734, 72.163414],
              [78.72248, 72.151004],
              [78.774602, 72.155162],
              [78.783209, 72.138289],
              [78.882471, 72.137547],
              [78.9155, 72.125797],
              [78.88643, 72.109703],
              [78.926717, 72.108473],
              [78.953751, 72.120564],
              [78.992399, 72.109691],
              [78.997891, 72.089835],
              [79.04323, 72.103561],
              [79.053889, 72.093937],
              [79.016746, 72.091428],
              [79.059655, 72.083078],
              [79.070631, 72.059099],
              [79.092652, 72.073317],
              [79.128228, 72.072],
              [79.128127, 72.061557],
              [79.206873, 72.075347],
              [79.233532, 72.06242],
              [79.286178, 72.063482],
              [79.275364, 72.055556],
              [79.319376, 72.04977],
              [79.359508, 72.057111],
              [79.372573, 72.03978],
              [79.410236, 72.048989],
              [79.393911, 72.028686],
              [79.435364, 72.02926],
              [79.486142, 72.040398],
              [79.645341, 72.040144],
              [79.639712, 72.048765],
              [79.720558, 72.050556],
              [79.73776, 72.040823],
              [79.839756, 72.026746],
              [79.831558, 72.019639],
              [79.881514, 72.019102],
              [79.895474, 72.003879],
              [79.878516, 71.992395],
              [79.907604, 71.973699],
              [79.91305, 71.991997],
              [79.975134, 71.978693],
              [80.00038, 71.986653],
              [80.020164, 71.974892],
              [80.062768, 71.980913],
              [80.065868, 71.964915],
              [80.089689, 71.971078],
              [80.114021, 71.950669],
              [80.089477, 71.944032],
              [80.151362, 71.945617],
              [80.17003, 71.93004],
              [80.220522, 71.938078],
              [80.225997, 71.925883],
              [80.251741, 71.931796],
              [80.277673, 71.923354],
              [80.251176, 71.907715],
              [80.330231, 71.885112],
              [80.379051, 71.878692],
              [80.378147, 71.871454],
              [80.331172, 71.864062],
              [80.322325, 71.877079],
              [80.297444, 71.869873],
              [80.317788, 71.855119],
              [80.253045, 71.846459],
              [80.251155, 71.855703],
              [80.194109, 71.854525],
              [80.163743, 71.835537],
              [80.131336, 71.83741],
              [80.074416, 71.823327],
              [80.053254, 71.798315],
              [80.101847, 71.805901],
              [80.100051, 71.783889],
              [80.120764, 71.78695],
              [80.117063, 71.768785],
              [80.148266, 71.757419],
              [80.128742, 71.745589],
              [80.072496, 71.750427],
              [80.101663, 71.734077],
              [80.142773, 71.732426],
              [80.109841, 71.725041],
              [80.152478, 71.709932],
              [80.135581, 71.687023],
              [80.174307, 71.681381],
              [80.130702, 71.678223],
              [80.138899, 71.663788],
              [80.030842, 71.639904],
              [79.980895, 71.623868],
              [79.980134, 71.609985],
              [79.915592, 71.608444],
              [79.905025, 71.596165],
              [79.841094, 71.582324],
              [79.784924, 71.590181],
              [79.724504, 71.592669],
              [79.705195, 71.601186],
              [79.651636, 71.605087],
              [79.572985, 71.600888],
              [79.542758, 71.606218],
              [79.520297, 71.595289],
              [79.394241, 71.612286],
              [79.312296, 71.586591],
              [79.314737, 71.567241],
              [79.352386, 71.548112],
              [79.371675, 71.528345],
              [79.362777, 71.505488],
              [79.313637, 71.454336],
              [79.249602, 71.434949],
              [79.213298, 71.416812],
              [79.161258, 71.404567],
              [79.12869, 71.387636],
              [79.143139, 71.38226],
              [79.175292, 71.332855],
              [79.220234, 71.307417],
              [79.433874, 71.296142],
              [79.996963, 71.116462],
              [80.367468, 71.075853],
              [80.583982, 71.043189],
              [80.608037, 70.943661],
              [80.638733, 70.833051],
              [80.715196, 70.809468],
              [80.701131, 70.777713],
              [80.674105, 70.760624],
              [80.641232, 70.706509],
              [80.647689, 70.691794],
              [80.684978, 70.676792],
              [80.736152, 70.673077],
              [80.794794, 70.654248],
              [80.800471, 70.635958],
              [80.82179, 70.631349],
              [80.778763, 70.602112],
              [80.793954, 70.590818],
              [80.764365, 70.583266],
              [80.77313, 70.574926],
              [80.715888, 70.565926],
              [80.742221, 70.557082],
              [80.722395, 70.531962],
              [80.723535, 70.506797],
              [80.648834, 70.501354],
              [80.646883, 70.482826],
              [80.701756, 70.480456],
              [80.702053, 70.460186],
              [80.720114, 70.441577],
              [80.7449, 70.434705],
              [80.823019, 70.438821],
              [80.812044, 70.424993],
              [80.834476, 70.423041],
              [80.849976, 70.439681],
              [80.889185, 70.437963],
              [80.849402, 70.409958],
              [80.793724, 70.411725],
              [80.791129, 70.400031],
              [80.822814, 70.397179],
              [80.824533, 70.38139],
              [80.790826, 70.385454],
              [80.749746, 70.381302],
              [80.730004, 70.387803],
              [80.644504, 70.398094],
              [80.641438, 70.405301],
              [80.685447, 70.411911],
              [80.674829, 70.432125],
              [80.61768, 70.453917],
              [80.497134, 70.459621],
              [80.414205, 70.460549],
              [80.358166, 70.446542],
              [80.341113, 70.427121],
              [80.311517, 70.425037],
              [80.222912, 70.435726],
              [80.208279, 70.427463],
              [80.224153, 70.397162],
              [80.177161, 70.376417],
              [80.124854, 70.389838],
              [80.0936, 70.375866],
              [80.136716, 70.365902],
              [80.124283, 70.342495],
              [80.023538, 70.331577],
              [79.945168, 70.290666],
              [79.919093, 70.268871],
              [79.878573, 70.261865],
              [79.837473, 70.281499],
              [79.811261, 70.283682],
              [79.755067, 70.27571],
              [79.737334, 70.255122],
              [79.782522, 70.246777],
              [79.785765, 70.233662],
              [79.70702, 70.240707],
              [79.666234, 70.232815],
              [79.651349, 70.18942],
              [79.570074, 70.139317],
              [79.552797, 70.118067],
              [79.507395, 70.111808],
              [79.414425, 70.122856],
              [79.397415, 70.132122],
              [79.442857, 70.139509],
              [79.441932, 70.148842],
              [79.403641, 70.155644],
              [79.348985, 70.152833],
              [79.322573, 70.142259],
              [79.325458, 70.128691],
              [79.358067, 70.110197],
              [79.32421, 70.090289],
              [79.364617, 70.068145],
              [79.369504, 70.045558],
              [79.342589, 70.04288],
              [79.324006, 70.063819],
              [79.283963, 70.052806],
              [79.220603, 70.060161],
              [79.200147, 70.032955],
              [79.242506, 70.020846],
              [79.209804, 70.00852],
              [79.182317, 70.023356],
              [79.165125, 70.021813],
              [79.116594, 69.997191],
              [79.166111, 70.006822],
              [79.176512, 69.998754],
              [79.14276, 69.989871],
              [79.197571, 69.976687],
              [79.159844, 69.974706],
              [79.14093, 69.959356],
              [79.158702, 69.951187],
              [79.109672, 69.932747],
              [79.056374, 69.930272],
              [79.045156, 69.907096],
              [78.962518, 69.878783],
              [78.892444, 69.886699],
              [78.880529, 69.8801],
              [78.913266, 69.868182],
              [78.909773, 69.856747],
              [78.948831, 69.854013],
              [78.987285, 69.840212],
              [79.039316, 69.837227],
              [79.046211, 69.824618],
              [79.010212, 69.82133],
              [78.995, 69.806612],
              [79.016828, 69.798959],
              [79.11753, 69.788667],
              [79.125156, 69.779482],
              [79.087384, 69.763771],
              [79.093897, 69.747511],
              [79.146281, 69.752703],
              [79.166983, 69.737767],
              [79.144295, 69.726591],
              [79.099668, 69.723533],
              [79.044522, 69.704091],
              [79.062402, 69.693997],
              [79.095395, 69.70651],
              [79.132922, 69.685417],
              [79.180069, 69.688798],
              [79.223269, 69.682024],
              [79.270381, 69.687241],
              [79.274866, 69.677791],
              [79.324142, 69.657002],
              [79.371382, 69.661547],
              [79.400052, 69.649052],
              [79.416822, 69.661203],
              [79.469401, 69.65567],
              [79.458558, 69.633626],
              [79.495568, 69.637517],
              [79.510766, 69.629141],
              [79.481391, 69.613092],
              [79.448908, 69.610453],
              [79.511352, 69.594249],
              [79.55472, 69.593397],
              [79.544678, 69.584276],
              [79.559154, 69.561575],
              [79.587099, 69.546462],
              [79.569015, 69.532759],
              [79.600986, 69.525044],
              [79.626555, 69.505197],
              [79.585271, 69.488729],
              [79.641837, 69.481205],
              [79.647238, 69.471071],
              [79.61801, 69.459564],
              [79.695007, 69.445197],
              [79.661787, 69.428579],
              [79.734247, 69.419249],
              [79.733248, 69.428886],
              [79.79105, 69.426335],
              [79.771019, 69.410323],
              [79.766587, 69.391648],
              [79.809118, 69.390502],
              [79.869868, 69.366531],
              [79.843914, 69.358653],
              [79.877087, 69.35401],
              [79.908253, 69.363246],
              [79.917844, 69.352295],
              [79.954077, 69.341658],
              [79.964082, 69.357285],
              [80.020968, 69.365285],
              [80.086493, 69.353341],
              [80.100091, 69.367839],
              [80.139804, 69.365459],
              [80.115068, 69.3544],
              [80.236667, 69.365988],
              [80.269265, 69.347486],
              [80.313646, 69.345314],
              [80.309274, 69.334058],
              [80.35852, 69.322592],
              [80.432638, 69.331127],
              [80.443944, 69.317724],
              [80.496754, 69.32377],
              [80.542682, 69.305977],
              [80.550795, 69.318527],
              [80.593458, 69.311571],
              [80.702343, 69.318664],
              [80.71105, 69.310098],
              [80.762196, 69.30638],
              [80.795927, 69.269559],
              [80.821636, 69.270802],
              [80.835565, 69.256959],
              [80.874165, 69.249398],
              [80.872294, 69.23983],
              [80.927304, 69.238647],
              [80.919105, 69.227823],
              [80.929878, 69.203682],
              [80.949757, 69.200576],
              [81.03203, 69.213412],
              [81.133316, 69.241534],
              [81.167313, 69.23672],
              [81.244246, 69.265178],
              [81.275437, 69.266156],
              [81.335078, 69.284426],
              [81.373666, 69.270983],
              [81.385886, 69.259035],
              [81.437938, 69.248205],
              [81.528168, 69.264923],
              [81.585507, 69.269522],
              [81.67646, 69.31661],
              [81.673828, 69.321784],
              [81.61129, 69.332608],
              [81.639582, 69.344352],
              [81.653093, 69.367954],
              [81.651931, 69.400761],
              [81.640992, 69.413596],
              [81.687942, 69.448003],
              [81.708273, 69.455059],
              [81.731076, 69.445398],
              [81.821998, 69.427072],
              [81.853177, 69.404787],
              [81.89647, 69.387787],
              [81.90612, 69.373803],
              [81.961172, 69.352549],
              [81.95246, 69.323323],
              [81.896496, 69.302854],
              [81.805257, 69.253679],
              [81.797426, 69.238756],
              [81.828985, 69.216017],
              [81.844422, 69.195059],
              [81.917518, 69.188421],
              [82.001487, 69.194029],
              [82.090297, 69.1802],
              [82.138464, 69.162346],
              [82.189619, 69.165569],
              [82.244619, 69.157358],
              [82.276559, 69.160162],
              [82.323671, 69.175565],
              [82.39592, 69.173855],
              [82.442518, 69.162003],
              [82.468054, 69.14523],
              [82.453385, 69.118583],
              [82.504474, 69.112295],
              [82.461028, 69.095961],
              [82.460954, 69.077317],
              [82.370327, 69.075634],
              [82.315583, 69.053849],
              [82.345381, 69.027492],
              [82.4287, 69.012804],
              [82.493701, 68.971615],
              [82.518253, 68.938132],
              [82.546685, 68.882811],
              [82.551408, 68.8405],
              [82.566188, 68.81906],
              [82.594519, 68.807023],
              [82.68717, 68.788657],
              [82.800677, 68.759195],
              [82.836311, 68.735302],
              [82.858743, 68.710673],
              [82.872731, 68.674322],
              [82.864283, 68.656829],
              [82.824517, 68.637134],
              [82.727938, 68.627516],
              [82.648785, 68.608826],
              [82.625036, 68.582547],
              [82.62097, 68.54776],
              [82.667387, 68.503439],
              [82.691186, 68.471412],
              [82.704353, 68.412865],
              [82.714025, 68.405172],
              [82.686904, 68.333877],
              [82.649253, 68.286303],
              [82.629405, 68.275401],
              [82.557966, 68.26484],
              [82.535222, 68.232502],
              [82.531906, 68.200874],
              [82.552208, 68.167067],
              [82.478772, 68.158007],
              [82.347783, 68.123965],
              [82.337415, 68.113932],
              [82.34465, 68.089396],
              [82.367328, 68.080665],
              [82.381642, 68.05583],
              [82.364764, 68.023897],
              [82.374997, 67.992154],
              [82.396129, 67.967409],
              [82.362037, 67.939894],
              [82.335616, 67.933346],
              [82.242385, 67.938319],
              [82.203654, 67.937124],
              [82.062589, 67.942873],
              [81.973863, 67.959532],
              [81.925067, 67.955957],
              [81.860003, 67.945482],
              [81.819986, 67.929903],
              [81.767561, 67.947541],
              [81.747112, 67.932713],
              [81.724489, 67.892795],
              [81.779235, 67.883733],
              [81.773772, 67.861449],
              [81.80235, 67.848585],
              [81.81928, 67.829277],
              [81.858145, 67.816113],
              [81.872129, 67.784859],
              [81.91561, 67.786164],
              [81.90804, 67.77695],
              [81.937585, 67.775003],
              [81.963442, 67.753996],
              [82.069957, 67.732534],
              [82.061455, 67.72096],
              [82.098844, 67.719674],
              [82.079755, 67.690459],
              [82.101719, 67.675952],
              [82.079389, 67.675686],
              [82.065729, 67.653168],
              [82.042417, 67.661923],
              [82.027625, 67.644631],
              [82.055803, 67.640415],
              [82.078833, 67.617567],
              [82.115164, 67.615483],
              [82.113949, 67.596324],
              [82.167622, 67.593395],
              [82.167995, 67.574196],
              [82.183963, 67.564111],
              [82.208187, 67.570045],
              [82.240198, 67.565679],
              [82.231579, 67.558268],
              [82.260205, 67.544636],
              [82.300135, 67.553875],
              [82.286725, 67.534251],
              [82.307857, 67.534853],
              [82.323576, 67.549621],
              [82.376661, 67.552146],
              [82.351871, 67.531287],
              [82.397382, 67.521762],
              [82.396098, 67.50127],
              [82.341602, 67.490989],
              [82.376629, 67.483455],
              [82.386048, 67.465779],
              [82.358301, 67.475628],
              [82.33056, 67.47218],
              [82.344465, 67.458119],
              [82.299863, 67.456941],
              [82.317267, 67.44591],
              [82.271942, 67.433633],
              [82.290645, 67.423842],
              [82.259384, 67.422546],
              [82.231825, 67.409169],
              [82.250594, 67.405108],
              [82.285948, 67.362518],
              [82.345855, 67.354136],
              [82.288914, 67.348964],
              [82.303994, 67.333324],
              [82.366948, 67.323128],
              [82.361536, 67.31718],
              [82.27037, 67.304129],
              [82.253418, 67.294351],
              [82.2293, 67.300235],
              [82.225925, 67.285306],
              [82.278312, 67.283058],
              [82.271919, 67.27394],
              [82.227294, 67.271557],
              [82.200453, 67.284044],
              [82.196173, 67.272089],
              [82.21349, 67.25647],
              [82.197843, 67.24558],
              [82.136118, 67.246504],
              [82.117143, 67.233997],
              [82.133208, 67.2189],
              [82.488674, 67.091716],
              [82.520082, 67.078868],
              [82.730564, 67.003301],
              [83.026209, 66.882688],
              [83.046354, 66.867114],
              [83.035754, 66.861255],
              [83.071408, 66.845654],
              [83.113129, 66.843262],
              [83.144944, 66.830278],
              [83.187981, 66.782996],
              [83.201161, 66.761555],
              [83.185789, 66.754953],
              [83.20839, 66.736153],
              [83.24019, 66.72671],
              [83.22996, 66.71815],
              [83.250573, 66.698628],
              [83.26775, 66.69833],
              [83.285059, 66.67638],
              [83.277275, 66.666761],
              [83.32771, 66.658633],
              [83.323616, 66.644533],
              [83.276548, 66.645163],
              [83.253065, 66.62303],
              [83.223195, 66.613303],
              [83.202174, 66.615499],
              [83.190509, 66.603551],
              [83.162402, 66.599785],
              [83.122368, 66.603983],
              [83.09052, 66.594674],
              [83.082868, 66.576432],
              [83.076214, 66.462893],
              [83.094962, 66.425271],
              [83.119947, 66.402522],
              [83.15444, 66.400506],
              [83.238121, 66.382911],
              [83.270533, 66.366471],
              [83.307718, 66.337277],
              [83.355001, 66.315935],
              [83.484163, 66.217641],
              [83.539154, 66.187446],
              [83.545444, 66.173299],
              [83.522981, 66.167431],
              [83.513789, 66.153953],
              [83.379892, 66.122494],
              [83.351897, 66.119241],
              [83.364974, 66.081315],
              [83.327144, 66.081155],
              [83.269447, 66.049229],
              [83.294883, 66.035719],
              [83.311026, 65.986285],
              [83.328522, 65.989962],
              [83.367649, 65.95742],
              [83.395265, 65.957719],
              [83.454818, 65.925961],
              [83.502832, 65.924771],
              [83.508987, 65.916357],
              [83.551045, 65.902985],
              [83.504384, 65.881159],
              [83.502296, 65.862725],
              [83.529222, 65.855543],
              [83.515845, 65.835187],
              [83.473505, 65.814529],
              [83.563395, 65.761979],
              [83.762194, 65.789367],
              [83.966508, 65.792251],
              [83.999658, 65.787181],
              [84.018518, 65.776739],
              [84.05659, 65.741084],
              [84.117299, 65.718203],
              [84.151455, 65.694778],
              [84.2391, 65.683762],
              [84.29285, 65.687125],
              [84.292248, 65.652353],
              [84.271657, 65.637469],
              [84.282737, 65.617595],
              [84.232378, 65.603347],
              [84.175039, 65.595555],
              [84.175125, 65.584504],
              [84.251996, 65.576431],
              [84.322646, 65.558741],
              [84.333665, 65.549023],
              [84.406546, 65.549365],
              [84.438607, 65.533728],
              [84.433876, 65.514673],
              [84.473298, 65.49993],
              [84.509418, 65.477438],
              [84.527697, 65.455532],
              [84.520354, 65.428043],
              [84.485458, 65.408738],
              [84.493406, 65.390253],
              [84.514388, 65.379842],
              [84.551225, 65.349357],
              [84.549785, 65.332901],
              [84.567119, 65.317042],
              [84.554657, 65.269471],
              [84.542418, 65.244356],
              [84.504262, 65.229712],
              [84.426055, 65.221949],
              [84.38348, 65.201851],
              [84.357794, 65.172809],
              [84.288352, 65.104061],
              [84.284425, 65.086531],
              [84.300765, 65.058692],
              [84.343988, 65.073417],
              [84.335926, 65.088867],
              [84.357616, 65.078756],
              [84.350452, 65.050932],
              [84.376725, 65.061749],
              [84.393556, 65.054653],
              [84.383055, 65.03567],
              [84.362265, 65.024672],
              [84.374455, 65.011592],
              [84.358867, 64.993762],
              [84.325554, 64.995697],
              [84.354288, 64.977971],
              [84.316776, 64.974176],
              [84.318959, 64.957283],
              [84.284623, 64.94824],
              [84.27232, 64.935291],
              [84.300791, 64.932167],
              [84.321848, 64.920113],
              [84.317647, 64.910492],
              [84.35453, 64.917806],
              [84.371262, 64.901682],
              [84.401873, 64.911383],
              [84.414101, 64.926077],
              [84.430095, 64.905507],
              [84.44585, 64.917591],
              [84.490015, 64.91163],
              [84.502268, 64.925069],
              [84.518227, 64.913284],
              [84.54777, 64.916504],
              [84.549276, 64.927878],
              [84.587194, 64.90822],
              [84.618237, 64.930824],
              [84.628287, 64.921249],
              [84.659474, 64.921971],
              [84.661303, 64.931257],
              [84.686435, 64.923],
              [84.701519, 64.933858],
              [84.721354, 64.929382],
              [84.755948, 64.943312],
              [84.821049, 64.94228],
              [84.862233, 64.950847],
              [84.908119, 64.935235],
              [84.89691, 64.952588],
              [84.921258, 64.954149],
              [84.942834, 64.942583],
              [84.976894, 64.940666],
              [85.025955, 64.924338],
              [85.032215, 64.910079],
              [85.002434, 64.915996],
              [84.996115, 64.900383],
              [85.012963, 64.895114],
              [84.981293, 64.878786],
              [84.959678, 64.885843],
              [84.94803, 64.870846],
              [84.923987, 64.864172],
              [84.946845, 64.834707],
              [85.100654, 64.820103],
              [85.103347, 64.829987],
              [85.133607, 64.826115],
              [85.10929, 64.800335],
              [85.131131, 64.796339],
              [85.133283, 64.763997],
              [85.167076, 64.7772],
              [85.182537, 64.772662],
              [85.204421, 64.786133],
              [85.2545, 64.791215],
              [85.25597, 64.796108],
              [85.340311, 64.791581],
              [85.364297, 64.795039],
              [85.390201, 64.811935],
              [85.416623, 64.812221],
              [85.44143, 64.832739],
              [85.455371, 64.830894],
              [85.512885, 64.843637],
              [85.527884, 64.85222],
              [85.569651, 64.853289],
              [85.596899, 64.84424],
              [85.665141, 64.838452],
              [85.694898, 64.828829],
              [85.735818, 64.823632],
              [85.833713, 64.784334],
              [85.819617, 64.705883],
              [85.819372, 64.679925],
              [85.905753, 64.598818],
              [85.907316, 64.574106],
              [85.804287, 64.496899],
              [85.865029, 64.45754],
              [85.921623, 64.431646],
              [85.931565, 64.372174],
              [86.000129, 64.277536],
              [86.007285, 64.272084],
              [86.00086, 64.2242],
              [85.978354, 64.205842],
              [85.982966, 64.178761],
              [85.933575, 64.132014],
              [85.9492, 64.110808],
              [85.967046, 64.048501],
              [85.902069, 64.043453],
              [85.75205, 64.001927],
              [85.629777, 63.950658],
              [85.585829, 63.943315],
              [85.558226, 63.932411],
              [85.506213, 63.928271],
              [85.482025, 63.914251],
              [85.476851, 63.89602],
              [85.491013, 63.876445],
              [85.462111, 63.863603],
              [85.418798, 63.867247],
              [85.402454, 63.854804],
              [85.375304, 63.859079],
              [85.366722, 63.844078],
              [85.387177, 63.834426],
              [85.346891, 63.817754],
              [85.329072, 63.796407],
              [85.37013, 63.797836],
              [85.349183, 63.783469],
              [85.378336, 63.766248],
              [85.348794, 63.762973],
              [85.330488, 63.747194],
              [85.340582, 63.733815],
              [85.319814, 63.727772],
              [85.336979, 63.710665],
              [85.322846, 63.710094],
              [85.315816, 63.667152],
              [85.299763, 63.665512],
              [85.302541, 63.647649],
              [85.242732, 63.624336],
              [85.257042, 63.62082],
              [85.229085, 63.607644],
              [85.193304, 63.613213],
              [85.154238, 63.581321],
              [85.145257, 63.562733],
              [85.114893, 63.571338],
              [85.096347, 63.584295],
              [85.04993, 63.595275],
              [85.023957, 63.580608],
              [85.085848, 63.565094],
              [85.078386, 63.533944],
              [85.123356, 63.525224],
              [85.210887, 63.523121],
              [85.231655, 63.495216],
              [85.272764, 63.510287],
              [85.28704, 63.52422],
              [85.295183, 63.511072],
              [85.317586, 63.507781],
              [85.353103, 63.491005],
              [85.360194, 63.473395],
              [85.380802, 63.475976],
              [85.397096, 63.461738],
              [85.373757, 63.452969],
              [85.417437, 63.437233],
              [85.404698, 63.418745],
              [85.387895, 63.41736],
              [85.374222, 63.393427],
              [85.411977, 63.388874],
              [85.419417, 63.365302],
              [85.455583, 63.361023],
              [85.463305, 63.351474],
              [85.496847, 63.366953],
              [85.577508, 63.379641],
              [85.645105, 63.377929],
              [85.688428, 63.372495],
              [85.704665, 63.345816],
              [85.695214, 63.332953],
              [85.652741, 63.326496],
              [85.61974, 63.298574],
              [85.598154, 63.299987],
              [85.56673, 63.26897],
              [85.576221, 63.257632],
              [85.554254, 63.252271],
              [85.546841, 63.235379],
              [85.572605, 63.225243],
              [85.564761, 63.202299],
              [85.502517, 63.177791],
              [85.517061, 63.167664],
              [85.498995, 63.153013],
              [85.502887, 63.135314],
              [85.532168, 63.119987],
              [85.522945, 63.11475],
              [85.541052, 63.09826],
              [85.559121, 63.101308],
              [85.569114, 63.087632],
              [85.595826, 63.075644],
              [85.597853, 63.059846],
              [85.572212, 63.061867],
              [85.619849, 63.034473],
              [85.59704, 63.01196],
              [85.57119, 63.014049],
              [85.536314, 62.988855],
              [85.517406, 62.990056],
              [85.502784, 62.971997],
              [85.53983, 62.938304],
              [85.522644, 62.935229],
              [85.512826, 62.919396],
              [85.523438, 62.904483],
              [85.499268, 62.898239],
              [85.471088, 62.881443],
              [85.369446, 62.879135],
              [85.343377, 62.861001],
              [85.301374, 62.843559],
              [85.28791, 62.824852],
              [85.214249, 62.798508],
              [85.20172, 62.777625],
              [85.18572, 62.770076],
              [85.170114, 62.734982],
              [85.149451, 62.717994],
              [85.153164, 62.707092],
              [85.116793, 62.701625],
              [85.12292, 62.68661],
              [85.099052, 62.683386],
              [85.064501, 62.667328],
              [85.099163, 62.641226],
              [85.090824, 62.627616],
              [85.059571, 62.630429],
              [85.011468, 62.602773],
              [84.984253, 62.592255],
              [84.962338, 62.596207],
              [84.940968, 62.587774],
              [84.946259, 62.579064],
              [84.918939, 62.558609],
              [84.89644, 62.550588],
              [84.909911, 62.526669],
              [84.94153, 62.516178],
              [84.935707, 62.482305],
              [84.848321, 62.441852],
              [84.785774, 62.416548],
              [84.722828, 62.403652],
              [84.698142, 62.390519],
              [84.573038, 62.300468],
              [84.491794, 62.215966],
              [84.440859, 62.195657],
              [84.42533, 62.230166],
              [84.386936, 62.25079],
              [84.324728, 62.260122],
              [84.298348, 62.269345],
              [84.295021, 62.282115],
              [84.305637, 62.308297],
              [84.293099, 62.334189],
              [84.260146, 62.353253],
              [84.23908, 62.381468],
              [84.216697, 62.385866],
              [84.178694, 62.380209],
              [84.102061, 62.377306],
              [84.029004, 62.400176],
              [83.999492, 62.414854],
              [83.960389, 62.490159],
              [83.814028, 62.569876],
              [83.622688, 62.503169],
              [83.56373, 62.498239],
              [83.553267, 62.527374],
              [83.324248, 62.458029],
              [83.023482, 62.526334],
              [83.049317, 62.589347],
              [82.913857, 62.626211],
              [82.758996, 62.659955],
              [82.767236, 62.667415],
              [82.761864, 62.749446],
              [82.522789, 62.768131],
              [82.460168, 62.775159],
              [82.385112, 62.761329],
              [82.28936, 62.766298],
              [82.159325, 62.813008],
              [82.057963, 62.772393],
              [82.014015, 62.760161],
              [81.945271, 62.746047],
              [81.841287, 62.695857],
              [81.741175, 62.705005],
              [81.665461, 62.730553],
              [81.638742, 62.779396],
              [81.391306, 62.816941],
              [81.291249, 62.858547],
              [81.319859, 62.909914],
              [81.221253, 62.933246],
              [81.1503, 62.968745],
              [81.146951, 62.985953],
              [81.09632, 63.011204],
              [81.04349, 63.064524],
              [81.037018, 63.147459],
              [80.921948, 63.156636],
              [80.680919, 63.115941],
              [80.588381, 63.078139],
              [80.588787, 63.010208],
              [80.550595, 62.989025],
              [80.481344, 62.988404],
              [80.463206, 62.967335],
              [80.381905, 62.932537],
              [80.304757, 62.912402],
              [80.283708, 62.893737],
              [80.186641, 62.864102],
              [80.127285, 62.83207],
              [80.093017, 62.817933],
              [80.033394, 62.80776],
              [79.971252, 62.812941],
              [79.908321, 62.785033],
              [79.891493, 62.760082],
              [79.90381, 62.73209],
              [79.888616, 62.689899],
              [79.84344, 62.608557],
              [79.549697, 62.604292],
              [79.485684, 62.613982],
              [79.436125, 62.617529],
              [79.338717, 62.610661],
              [79.251138, 62.606674],
              [79.206321, 62.607419],
              [79.033506, 62.604514],
              [78.958785, 62.604858],
              [78.739462, 62.602213],
              [78.519467, 62.561092],
              [78.405963, 62.560355],
              [78.134596, 62.492828],
              [78.092906, 62.464098],
              [77.997859, 62.513573],
              [77.870423, 62.575948],
              [77.736092, 62.613238],
              [77.378102, 62.707897],
              [77.136819, 62.811422],
              [77.076727, 62.90101],
              [77.025323, 62.97192],
              [76.891352, 62.990745],
              [76.851631, 62.988709],
              [76.666942, 63.03481],
              [76.516902, 62.999357],
              [76.34005, 62.951344],
              [76.266714, 63.013198],
              [76.034384, 63.066713],
              [75.969968, 63.070608],
              [75.893379, 63.106482],
              [75.778408, 63.117148],
              [75.604962, 63.088084],
              [75.428676, 63.09805],
              [75.312551, 63.09707],
              [75.253558, 63.072457],
              [75.177443, 63.060908],
              [75.080264, 63.068454],
              [75.019551, 63.04672],
              [74.941549, 63.025322],
              [74.787514, 63.028981],
              [74.524322, 63.036364],
              [74.457345, 63.067122],
              [74.389952, 63.11449],
              [74.253194, 63.173027],
              [74.003924, 63.16813],
              [73.865504, 63.17119],
              [73.498183, 63.187955],
              [73.363796, 63.232242],
              [73.263269, 63.334776],
              [73.106153, 63.378196],
              [73.125184, 63.397768],
              [73.054297, 63.426791],
              [72.901202, 63.3974],
              [72.872556, 63.355793],
              [72.804964, 63.335946],
              [72.66308, 63.281521],
              [72.406384, 63.299387],
              [72.128306, 63.296786],
              [72.100081, 63.276521],
              [72.033459, 63.255622],
              [71.956087, 63.257632],
              [71.881758, 63.255933],
              [71.842448, 63.248284],
              [71.785132, 63.226995],
              [71.645484, 63.192752],
              [71.600598, 63.318983],
              [71.544898, 63.448901],
              [71.536975, 63.472687],
              [71.565238, 63.556634],
              [71.563007, 63.571948],
              [71.490714, 63.64528],
              [71.461273, 63.662355],
              [71.455856, 63.683308],
              [71.247148, 63.700463],
              [71.109507, 63.70311],
              [71.049697, 63.701495],
              [70.922692, 63.702792],
              [70.876593, 63.756679],
              [70.825653, 63.821911],
              [70.706206, 63.886808],
              [70.688871, 63.94098],
              [70.608592, 63.972069],
              [70.558903, 64.015282],
              [70.666297, 64.069209],
              [70.766496, 64.112367],
              [70.770934, 64.191113],
              [70.663629, 64.238863],
              [70.451002, 64.2487],
              [70.316141, 64.328251],
              [70.293517, 64.343852],
              [70.170085, 64.341234],
              [70.086485, 64.31878],
              [69.888528, 64.321694],
              [69.832335, 64.329812],
              [69.827757, 64.357466],
              [69.782913, 64.371672],
              [69.724948, 64.395908],
              [69.50955, 64.385918],
              [69.396756, 64.386862],
              [69.279718, 64.475579],
              [69.252204, 64.480255],
              [69.139597, 64.454516],
              [69.048018, 64.456006],
              [68.989973, 64.446344],
              [68.858472, 64.438539],
              [68.910423, 64.401757],
              [68.92665, 64.381815],
              [68.972196, 64.347165],
              [68.997339, 64.33934],
              [69.031082, 64.342582],
              [69.043761, 64.329374],
              [68.99078, 64.305535],
              [68.972276, 64.292765],
              [68.92206, 64.271613],
              [68.913064, 64.25951],
              [68.922826, 64.239546],
              [68.914756, 64.225913],
              [68.88206, 64.221717],
              [68.789036, 64.224683],
              [68.659141, 64.255333],
              [68.554679, 64.286463],
              [68.479372, 64.290085],
              [68.448825, 64.287694],
              [68.361269, 64.256915],
              [68.269044, 64.219631],
              [68.22016, 64.205661],
              [68.192319, 64.189135],
              [68.17795, 64.168602],
              [68.119994, 64.148625],
              [68.051928, 64.135809],
              [68.043567, 64.126016],
              [68.003685, 64.115227],
              [67.945155, 64.111922],
              [67.94158, 64.090174],
              [67.900104, 64.080737],
              [67.836193, 64.047912],
              [67.81876, 64.025309],
              [67.783301, 64.019195],
              [67.759279, 64.039665],
              [67.708647, 64.05776],
              [67.677623, 64.076902],
              [67.637824, 64.070199],
              [67.607542, 64.074084],
              [67.584153, 64.068562],
              [67.530714, 64.088488],
              [67.468898, 64.077359],
              [67.446589, 64.079032],
              [67.410795, 64.092342],
              [67.379995, 64.094677],
              [67.284903, 64.073888],
              [67.258515, 64.082051],
              [67.285666, 64.109779],
              [67.256246, 64.119734],
              [67.173003, 64.121351],
              [67.175596, 64.137904],
              [67.199669, 64.159666],
              [67.173981, 64.155999],
              [67.129693, 64.159425],
              [67.102171, 64.175816],
              [67.064809, 64.176893],
              [67.010781, 64.189062],
              [66.988902, 64.183432],
              [66.941885, 64.185803],
              [66.926256, 64.201495],
              [66.904247, 64.206104],
              [66.866675, 64.203068],
              [66.837673, 64.212394],
              [66.842265, 64.228073],
              [66.835255, 64.264268],
              [66.844506, 64.290075],
              [66.828735, 64.291745],
              [66.855727, 64.331243],
              [66.847343, 64.340373],
              [66.914885, 64.374168],
              [66.937285, 64.405903],
              [66.977605, 64.423956],
              [66.989855, 64.452413],
              [66.982689, 64.462163],
              [66.943863, 64.47042],
              [66.978998, 64.478729],
              [66.970269, 64.508071],
              [66.933793, 64.520322],
              [66.903612, 64.515114],
              [66.805601, 64.544364],
              [66.743926, 64.545029],
              [66.708921, 64.554487],
              [66.675258, 64.55365],
              [66.639818, 64.545674],
              [66.579397, 64.544917],
              [66.527405, 64.53995],
              [66.45493, 64.527194],
              [66.197823, 64.59119],
              [65.997001, 64.582256],
              [65.933079, 64.555248],
              [65.633317, 64.558346],
              [65.376371, 64.555241],
              [65.260676, 64.506807],
              [65.178586, 64.468122],
              [64.983107, 64.470057],
              [64.724249, 64.430431],
              [64.603817, 64.442677],
              [64.482332, 64.371083],
              [64.043311, 64.359353],
              [63.974429, 64.340842],
              [63.948638, 64.327428],
              [63.913423, 64.287707],
              [63.61126, 64.270514],
              [63.59398, 64.294836],
              [63.50434, 64.291577],
              [63.410065, 64.333669],
              [63.516973, 64.363156],
              [63.477518, 64.456145],
              [63.393418, 64.464475],
              [63.247328, 64.520418],
              [63.052354, 64.515668],
              [62.984144, 64.49865],
              [62.913436, 64.467695],
              [62.834749, 64.505259],
              [62.714807, 64.496628],
              [62.557643, 64.558547],
              [62.598902, 64.622727],
              [62.589629, 64.640373],
              [62.731729, 64.682102],
              [62.811534, 64.736448],
              [62.802971, 64.775991],
              [62.822519, 64.789863],
              [62.807012, 64.840715],
              [62.695222, 64.872818],
              [62.721849, 64.930727],
              [62.750244, 64.935124],
              [62.784569, 65.007945],
              [62.823741, 65.054607],
              [62.817293, 65.19469],
              [62.872114, 65.290589],
              [62.454152, 65.339291],
              [62.366543, 65.43211],
              [62.425405, 65.503702],
              [62.31297, 65.535157],
              [62.306307, 65.586818],
              [62.265281, 65.632344],
              [62.162845, 65.641382],
              [62.155129, 65.658611],
              [62.082812, 65.687458],
              [62.057542, 65.706138],
              [62.000444, 65.717736],
            ],
          ],
          [
            [
              [66.60435, 70.4743],
              [66.61786, 70.48624],
              [66.65673, 70.4576],
              [66.60435, 70.4743],
            ],
          ],
          [
            [
              [66.5976, 70.49582],
              [66.5661, 70.5032],
              [66.50798, 70.54001],
              [66.4498, 70.59393],
              [66.42858, 70.64274],
              [66.41831, 70.70474],
              [66.4316, 70.74892],
              [66.45661, 70.80527],
              [66.476, 70.80247],
              [66.44551, 70.74323],
              [66.44678, 70.721],
              [66.4654, 70.71461],
              [66.43359, 70.69115],
              [66.4495, 70.66324],
              [66.43731, 70.65591],
              [66.46604, 70.61462],
              [66.4682, 70.59139],
              [66.52503, 70.6035],
              [66.54225, 70.59265],
              [66.49383, 70.59237],
              [66.49006, 70.5794],
              [66.53728, 70.55141],
              [66.55181, 70.52961],
              [66.59192, 70.51147],
              [66.61886, 70.5103],
              [66.5976, 70.49582],
            ],
          ],
          [
            [
              [76.88236, 72.21042],
              [76.92541, 72.21644],
              [76.9424, 72.22678],
              [76.96344, 72.20161],
              [76.91158, 72.18824],
              [76.92458, 72.16547],
              [76.88751, 72.19491],
              [76.88236, 72.21042],
            ],
          ],
          [
            [
              [77.01131, 72.24137],
              [77.04381, 72.22679],
              [76.99708, 72.22201],
              [76.97866, 72.22957],
              [77.01131, 72.24137],
            ],
          ],
          [
            [
              [77.27911, 72.23599],
              [77.3068, 72.2296],
              [77.27729, 72.22431],
              [77.19813, 72.22625],
              [77.27911, 72.23599],
            ],
          ],
          [
            [
              [77.06936, 72.27966],
              [76.96539, 72.27474],
              [77.02596, 72.26663],
              [76.99458, 72.26036],
              [76.94355, 72.27084],
              [76.86583, 72.3043],
              [76.9025, 72.33784],
              [76.96631, 72.36577],
              [77.12641, 72.40711],
              [77.2062, 72.42222],
              [77.22786, 72.4321],
              [77.31702, 72.5062],
              [77.37883, 72.53318],
              [77.43135, 72.54334],
              [77.5177, 72.57736],
              [77.61406, 72.60076],
              [77.77031, 72.59733],
              [78.13099, 72.56534],
              [78.20463, 72.55257],
              [78.28341, 72.52758],
              [78.32475, 72.50716],
              [78.35449, 72.47534],
              [78.35327, 72.4518],
              [78.31139, 72.45418],
              [78.29404, 72.44538],
              [78.23892, 72.44564],
              [78.20283, 72.41598],
              [78.15514, 72.40711],
              [78.1123, 72.38937],
              [78.04964, 72.39023],
              [78.00581, 72.38446],
              [77.95938, 72.3615],
              [77.8977, 72.35466],
              [77.87965, 72.3467],
              [77.88764, 72.32713],
              [77.81782, 72.30735],
              [77.80325, 72.28727],
              [77.67138, 72.2961],
              [77.62714, 72.28525],
              [77.54503, 72.28395],
              [77.4507, 72.28931],
              [77.34285, 72.2821],
              [77.27505, 72.28902],
              [77.19746, 72.28203],
              [77.13767, 72.28254],
              [77.10159, 72.27044],
              [77.06936, 72.27966],
            ],
          ],
          [
            [
              [74.69984, 72.86014],
              [74.72595, 72.8535],
              [74.70557, 72.84023],
              [74.6517, 72.83481],
              [74.53929, 72.8669],
              [74.32764, 72.91499],
              [74.24191, 72.94043],
              [74.12702, 72.9836],
              [74.10929, 72.99635],
              [74.15274, 73.05013],
              [74.22804, 73.08436],
              [74.29543, 73.10032],
              [74.38552, 73.10939],
              [74.4934, 73.10743],
              [74.67969, 73.08902],
              [74.75862, 73.0745],
              [74.7205, 73.06839],
              [74.71238, 73.0504],
              [74.68263, 73.03737],
              [74.70509, 73.02007],
              [74.68893, 72.98666],
              [74.64712, 72.96166],
              [74.63134, 72.93978],
              [74.65835, 72.92466],
              [74.6491, 72.91081],
              [74.68963, 72.88377],
              [74.67171, 72.86196],
              [74.69984, 72.86014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5543",
      properties: {
        name: "Ярославская область",
        density: 0,
        path: "/world/Russia/Ярославская область",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.628222, 58.482944],
            [37.640894, 58.484668],
            [37.694258, 58.523322],
            [37.720287, 58.561851],
            [37.697463, 58.571405],
            [37.712146, 58.625677],
            [37.747035, 58.628428],
            [37.76473, 58.623164],
            [37.833259, 58.630544],
            [37.86559, 58.610613],
            [37.896818, 58.611319],
            [37.927378, 58.647451],
            [37.966591, 58.66312],
            [37.95983, 58.679983],
            [38.014158, 58.682653],
            [38.022971, 58.689446],
            [37.983481, 58.707115],
            [38.018375, 58.713666],
            [38.064554, 58.730111],
            [38.094185, 58.72865],
            [38.284253, 58.728478],
            [38.412961, 58.726816],
            [38.482592, 58.699892],
            [38.539141, 58.726815],
            [38.547214, 58.748055],
            [38.565221, 58.751712],
            [38.557291, 58.772827],
            [38.635604, 58.761153],
            [38.649014, 58.750949],
            [38.662891, 58.757303],
            [38.676623, 58.729964],
            [38.701999, 58.732646],
            [38.690658, 58.747472],
            [38.719124, 58.753602],
            [38.728614, 58.745622],
            [38.842299, 58.746996],
            [38.847842, 58.734774],
            [38.923263, 58.733331],
            [38.921668, 58.770098],
            [38.915831, 58.779062],
            [38.938282, 58.795379],
            [38.920087, 58.807158],
            [38.941013, 58.805571],
            [39.005826, 58.832894],
            [39.000323, 58.837786],
            [39.036062, 58.855655],
            [39.001896, 58.85837],
            [38.957458, 58.891373],
            [38.926217, 58.896967],
            [38.944178, 58.907422],
            [38.948761, 58.943357],
            [38.989808, 58.956012],
            [39.023818, 58.928068],
            [39.10996, 58.937947],
            [39.124124, 58.95099],
            [39.175345, 58.930193],
            [39.210407, 58.926676],
            [39.234881, 58.915334],
            [39.244836, 58.898895],
            [39.267398, 58.905783],
            [39.257841, 58.91538],
            [39.290723, 58.924279],
            [39.334266, 58.926163],
            [39.332204, 58.915924],
            [39.368656, 58.909982],
            [39.363122, 58.897599],
            [39.377833, 58.883279],
            [39.392464, 58.891436],
            [39.437477, 58.89839],
            [39.457371, 58.894029],
            [39.454599, 58.882638],
            [39.500675, 58.883894],
            [39.571404, 58.875857],
            [39.604119, 58.904925],
            [39.712795, 58.837611],
            [39.71973, 58.820438],
            [39.73947, 58.834313],
            [39.793261, 58.837057],
            [39.812688, 58.809344],
            [39.853778, 58.77945],
            [39.870957, 58.752406],
            [39.887569, 58.748857],
            [39.914668, 58.758523],
            [39.954285, 58.75844],
            [39.979688, 58.741128],
            [40.020075, 58.741223],
            [40.014392, 58.721089],
            [40.035743, 58.677308],
            [40.053786, 58.676136],
            [40.048492, 58.656204],
            [40.069843, 58.650933],
            [40.104903, 58.658993],
            [40.125689, 58.649295],
            [40.135936, 58.626926],
            [40.123609, 58.620308],
            [40.09108, 58.623194],
            [40.076797, 58.608179],
            [40.088303, 58.595198],
            [40.130086, 58.578874],
            [40.162469, 58.578585],
            [40.185873, 58.555134],
            [40.229404, 58.547819],
            [40.264165, 58.55235],
            [40.302323, 58.535242],
            [40.327573, 58.536621],
            [40.343029, 58.554866],
            [40.368509, 58.552355],
            [40.392445, 58.54185],
            [40.457932, 58.547559],
            [40.512763, 58.546907],
            [40.53503, 58.567128],
            [40.569449, 58.56794],
            [40.608548, 58.585411],
            [40.69444, 58.594323],
            [40.711103, 58.584275],
            [40.72971, 58.58953],
            [40.763943, 58.587026],
            [40.782557, 58.594103],
            [40.813721, 58.590818],
            [40.834739, 58.574619],
            [40.856679, 58.576996],
            [40.890291, 58.591265],
            [40.889446, 58.577944],
            [40.930072, 58.576336],
            [40.938014, 58.562968],
            [40.968159, 58.558155],
            [41.019201, 58.541739],
            [41.023638, 58.527758],
            [41.052517, 58.506986],
            [41.041655, 58.484808],
            [41.070885, 58.486135],
            [41.078738, 58.468862],
            [41.103809, 58.463148],
            [41.048341, 58.441757],
            [41.067594, 58.426305],
            [41.082033, 58.426558],
            [41.136245, 58.394641],
            [41.150684, 58.396225],
            [41.170697, 58.382546],
            [41.150939, 58.370386],
            [41.189698, 58.350375],
            [41.190459, 58.333909],
            [41.161074, 58.332135],
            [41.138783, 58.306043],
            [41.088059, 58.285663],
            [41.0762, 58.292068],
            [41.037442, 58.283714],
            [40.98382, 58.242712],
            [40.985087, 58.231566],
            [40.960769, 58.219533],
            [40.96305, 58.195119],
            [40.921253, 58.191319],
            [40.906813, 58.199678],
            [40.882116, 58.178652],
            [40.892504, 58.138565],
            [40.893266, 58.107787],
            [40.875533, 58.115513],
            [40.850582, 58.110446],
            [40.85109, 58.085621],
            [40.837791, 58.082993],
            [40.839311, 58.068554],
            [40.820946, 58.064373],
            [40.774589, 58.066146],
            [40.76965, 58.041827],
            [40.803394, 58.019806],
            [40.809087, 58.006626],
            [40.790008, 58.003541],
            [40.777746, 57.989048],
            [40.766502, 57.996925],
            [40.739258, 57.987963],
            [40.715185, 57.991237],
            [40.680084, 57.981601],
            [40.673455, 57.958976],
            [40.63961, 57.94144],
            [40.646417, 57.935326],
            [40.626687, 57.914659],
            [40.597672, 57.897109],
            [40.614604, 57.878272],
            [40.638259, 57.864494],
            [40.622468, 57.84794],
            [40.650823, 57.819228],
            [40.651189, 57.799045],
            [40.630668, 57.79832],
            [40.635677, 57.785577],
            [40.614466, 57.782011],
            [40.624526, 57.7685],
            [40.657468, 57.759314],
            [40.647084, 57.727518],
            [40.675894, 57.729532],
            [40.655755, 57.697005],
            [40.673414, 57.697475],
            [40.718094, 57.662102],
            [40.708052, 57.652632],
            [40.707318, 57.625776],
            [40.692973, 57.618057],
            [40.673456, 57.593508],
            [40.650352, 57.585331],
            [40.631984, 57.56882],
            [40.598718, 57.572212],
            [40.615347, 57.593291],
            [40.591824, 57.59752],
            [40.548976, 57.582613],
            [40.524801, 57.59297],
            [40.498313, 57.581876],
            [40.507242, 57.568075],
            [40.490195, 57.559519],
            [40.465, 57.565859],
            [40.440885, 57.542821],
            [40.479221, 57.526576],
            [40.467212, 57.52039],
            [40.50458, 57.517849],
            [40.502487, 57.504063],
            [40.453246, 57.510666],
            [40.451868, 57.501794],
            [40.428242, 57.512229],
            [40.40563, 57.493452],
            [40.431873, 57.471207],
            [40.415532, 57.466428],
            [40.439927, 57.449526],
            [40.433576, 57.435372],
            [40.443977, 57.417478],
            [40.393326, 57.406933],
            [40.390103, 57.398728],
            [40.413778, 57.382872],
            [40.446901, 57.378803],
            [40.437419, 57.369118],
            [40.434653, 57.337582],
            [40.42469, 57.337995],
            [40.437478, 57.312456],
            [40.423103, 57.312432],
            [40.430464, 57.285829],
            [40.404191, 57.266173],
            [40.366886, 57.275179],
            [40.366431, 57.282945],
            [40.334816, 57.281308],
            [40.29796, 57.287611],
            [40.285098, 57.277301],
            [40.262508, 57.286251],
            [40.262173, 57.275349],
            [40.213795, 57.265247],
            [40.182932, 57.263417],
            [40.176423, 57.25601],
            [40.20697, 57.251954],
            [40.232559, 57.257483],
            [40.24353, 57.246199],
            [40.228748, 57.243559],
            [40.209622, 57.225632],
            [40.187722, 57.234192],
            [40.175128, 57.225073],
            [40.193111, 57.203873],
            [40.150221, 57.19083],
            [40.140513, 57.201084],
            [40.052497, 57.198999],
            [40.031508, 57.208731],
            [40.021669, 57.194743],
            [39.991697, 57.182867],
            [39.96247, 57.179829],
            [39.947938, 57.170057],
            [39.904223, 57.169569],
            [39.853334, 57.17419],
            [39.848047, 57.148263],
            [39.824462, 57.145667],
            [39.824378, 57.128063],
            [39.837557, 57.116277],
            [39.858897, 57.11754],
            [39.857583, 57.084842],
            [39.8097, 57.096119],
            [39.795387, 57.090914],
            [39.77375, 57.105298],
            [39.7572, 57.096218],
            [39.765078, 57.07658],
            [39.738382, 57.083506],
            [39.723018, 57.074697],
            [39.666814, 57.071994],
            [39.654902, 57.077999],
            [39.621221, 57.060488],
            [39.620565, 57.038587],
            [39.60581, 57.029405],
            [39.562769, 57.03501],
            [39.524104, 57.018293],
            [39.533913, 57.002793],
            [39.55533, 56.998764],
            [39.54414, 56.989185],
            [39.553421, 56.959924],
            [39.531866, 56.962599],
            [39.501841, 56.935981],
            [39.469288, 56.925113],
            [39.437352, 56.901626],
            [39.445761, 56.890415],
            [39.472727, 56.886691],
            [39.484551, 56.874],
            [39.458825, 56.857984],
            [39.44436, 56.869595],
            [39.400397, 56.86469],
            [39.413145, 56.851281],
            [39.396005, 56.847538],
            [39.379646, 56.833224],
            [39.377242, 56.811235],
            [39.397218, 56.803031],
            [39.378491, 56.80001],
            [39.379041, 56.777268],
            [39.402019, 56.771048],
            [39.367433, 56.765873],
            [39.347053, 56.756873],
            [39.336539, 56.739753],
            [39.353268, 56.719589],
            [39.342299, 56.705503],
            [39.366865, 56.692375],
            [39.323758, 56.682234],
            [39.312824, 56.660539],
            [39.335408, 56.627096],
            [39.332853, 56.595585],
            [39.323495, 56.578197],
            [39.294309, 56.585936],
            [39.297201, 56.567423],
            [39.289999, 56.5468],
            [39.273024, 56.536674],
            [39.187783, 56.554174],
            [39.174764, 56.579128],
            [39.148715, 56.572985],
            [39.134397, 56.578266],
            [39.106371, 56.571705],
            [39.09392, 56.554077],
            [39.078172, 56.563361],
            [39.062255, 56.559381],
            [39.03063, 56.538867],
            [38.989735, 56.540805],
            [38.972406, 56.535227],
            [38.927564, 56.548754],
            [38.884323, 56.568165],
            [38.855249, 56.567531],
            [38.868652, 56.576842],
            [38.852815, 56.584942],
            [38.854648, 56.601373],
            [38.841929, 56.605497],
            [38.809905, 56.595302],
            [38.785987, 56.595635],
            [38.769532, 56.610467],
            [38.755733, 56.609031],
            [38.766493, 56.594293],
            [38.765064, 56.572109],
            [38.73978, 56.588744],
            [38.691166, 56.581297],
            [38.648652, 56.584663],
            [38.620283, 56.576062],
            [38.607576, 56.551159],
            [38.592943, 56.539092],
            [38.55302, 56.541787],
            [38.568167, 56.56284],
            [38.531709, 56.590182],
            [38.562259, 56.613802],
            [38.5204, 56.621666],
            [38.521052, 56.627793],
            [38.492388, 56.648677],
            [38.456231, 56.647092],
            [38.428497, 56.659627],
            [38.410372, 56.693653],
            [38.422891, 56.70089],
            [38.389041, 56.702346],
            [38.398327, 56.68225],
            [38.357297, 56.673408],
            [38.318131, 56.658578],
            [38.301448, 56.69824],
            [38.314092, 56.703681],
            [38.319536, 56.721839],
            [38.303159, 56.73902],
            [38.304354, 56.759976],
            [38.271916, 56.773607],
            [38.244299, 56.793463],
            [38.228698, 56.824517],
            [38.190881, 56.817825],
            [38.172463, 56.831637],
            [38.155243, 56.821692],
            [38.119697, 56.818499],
            [38.110887, 56.827278],
            [38.152172, 56.845819],
            [38.124453, 56.858526],
            [38.100071, 56.85531],
            [38.108268, 56.87657],
            [38.179427, 56.89264],
            [38.194199, 56.902533],
            [38.180685, 56.933619],
            [38.197671, 56.947407],
            [38.180953, 56.957967],
            [38.136667, 56.958762],
            [38.151982, 56.968297],
            [38.154234, 56.985256],
            [38.220679, 56.98469],
            [38.237408, 56.999302],
            [38.226918, 57.018425],
            [38.251866, 57.028605],
            [38.253904, 57.072613],
            [38.238699, 57.081416],
            [38.205393, 57.073215],
            [38.230483, 57.092699],
            [38.252071, 57.092308],
            [38.242694, 57.117297],
            [38.222377, 57.112686],
            [38.208324, 57.120411],
            [38.204331, 57.159296],
            [38.161622, 57.170992],
            [38.148415, 57.224503],
            [38.168959, 57.229711],
            [38.200133, 57.227711],
            [38.245701, 57.239084],
            [38.260576, 57.255774],
            [38.313427, 57.259914],
            [38.308181, 57.265846],
            [38.32641, 57.282261],
            [38.31164, 57.343205],
            [38.3032, 57.358673],
            [38.284207, 57.365971],
            [38.165496, 57.354626],
            [38.158886, 57.339853],
            [38.116254, 57.331692],
            [38.089051, 57.331697],
            [38.040685, 57.351757],
            [38.016234, 57.336215],
            [37.974866, 57.35552],
            [37.959613, 57.354739],
            [37.944939, 57.373601],
            [37.882599, 57.39494],
            [37.842037, 57.439236],
            [37.806651, 57.440711],
            [37.804677, 57.447853],
            [37.843889, 57.461517],
            [37.852115, 57.470603],
            [37.828171, 57.492949],
            [37.800582, 57.501326],
            [37.821449, 57.512971],
            [37.817442, 57.523956],
            [37.83395, 57.530238],
            [37.823272, 57.551579],
            [37.84253, 57.562026],
            [37.838068, 57.573691],
            [37.85767, 57.5797],
            [37.823309, 57.596104],
            [37.814211, 57.625256],
            [37.800665, 57.617059],
            [37.78477, 57.623401],
            [37.772938, 57.615546],
            [37.736112, 57.621813],
            [37.724596, 57.635746],
            [37.685784, 57.657872],
            [37.675847, 57.655053],
            [37.630062, 57.66343],
            [37.649168, 57.703003],
            [37.561096, 57.739119],
            [37.551034, 57.75202],
            [37.559702, 57.766523],
            [37.533844, 57.768718],
            [37.499402, 57.781119],
            [37.484053, 57.796466],
            [37.488926, 57.811498],
            [37.46588, 57.811937],
            [37.437394, 57.821514],
            [37.380219, 57.820446],
            [37.379967, 57.837475],
            [37.401137, 57.85622],
            [37.433831, 57.869276],
            [37.411852, 57.880327],
            [37.381321, 57.884145],
            [37.366476, 57.87705],
            [37.334165, 57.8762],
            [37.33033, 57.88546],
            [37.349122, 57.891089],
            [37.358501, 57.905005],
            [37.395932, 57.897463],
            [37.402077, 57.911011],
            [37.444756, 57.907217],
            [37.403126, 57.925392],
            [37.407551, 57.939816],
            [37.359112, 57.95154],
            [37.366724, 57.96738],
            [37.349411, 57.973396],
            [37.354008, 57.990045],
            [37.36847, 57.992894],
            [37.387842, 57.979413],
            [37.400243, 57.985552],
            [37.443279, 57.982115],
            [37.478641, 57.964312],
            [37.499023, 57.96861],
            [37.484043, 57.977082],
            [37.499022, 57.987764],
            [37.536962, 57.986414],
            [37.538654, 57.996168],
            [37.504423, 58.01625],
            [37.51363, 58.041175],
            [37.569804, 58.050999],
            [37.586993, 58.064137],
            [37.603016, 58.063646],
            [37.605225, 58.07703],
            [37.650042, 58.074329],
            [37.652989, 58.064384],
            [37.669687, 58.073347],
            [37.694612, 58.070523],
            [37.714982, 58.077988],
            [37.699814, 58.081922],
            [37.715821, 58.090502],
            [37.706521, 58.097501],
            [37.6897, 58.085749],
            [37.643839, 58.105884],
            [37.63463, 58.115953],
            [37.612406, 58.119267],
            [37.614983, 58.133756],
            [37.583428, 58.136211],
            [37.582835, 58.161892],
            [37.530199, 58.167275],
            [37.505249, 58.188843],
            [37.476741, 58.179526],
            [37.437619, 58.187778],
            [37.413552, 58.203494],
            [37.38433, 58.202757],
            [37.388565, 58.222893],
            [37.375664, 58.233954],
            [37.335031, 58.225226],
            [37.327909, 58.236644],
            [37.369164, 58.241556],
            [37.385862, 58.25052],
            [37.370881, 58.268446],
            [37.435102, 58.276002],
            [37.43135, 58.305405],
            [37.417966, 58.313386],
            [37.39255, 58.313631],
            [37.392918, 58.323208],
            [37.368606, 58.338433],
            [37.381726, 58.36244],
            [37.411333, 58.356974],
            [37.43331, 58.380917],
            [37.418051, 58.39073],
            [37.437975, 58.397616],
            [37.457252, 58.39295],
            [37.476897, 58.401668],
            [37.508577, 58.380796],
            [37.535957, 58.390864],
            [37.543691, 58.415421],
            [37.560513, 58.409282],
            [37.582859, 58.420579],
            [37.624237, 58.420457],
            [37.630006, 58.454713],
            [37.665122, 58.450785],
            [37.628222, 58.482944],
          ],
        ],
      },
    },
  ],
  style: {
    stroke: "#000000",
    fill: "#3498DB",
    "stroke-width": 1,
    "fill-opacity": 0.8,
  },
};
