import React from 'react'

const Footer = () => {

  return (
    <>
      <footer className="bg-dark text-light card-footer" >
        <div className="container py-2">
          <div className="d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap text-center">
             
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer
