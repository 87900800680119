import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { createRoot } from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";

import msalInstance from "./component/msalConfig";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <App />
          <Toaster position="top-center" reverseOrder={false} />
        </PersistGate>
      </Provider>
    </MsalProvider>
  </BrowserRouter>
);
