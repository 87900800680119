import React, {
    useState,
    useRef,
    useImperativeHandle,
    forwardRef,
  } from "react";
  import html2canvas from "html2canvas";
  import { AgCharts } from "ag-charts-react";
  import "ag-charts-enterprise";
  
  import { Specimens_data, ILI_data, Sari_data } from "./who_data";
  import { topology } from "./topology.";
  
  export const AgMapChartWrapper = forwardRef((props, ref) => {
    const agMapChartRef = useRef(null);
  
    useImperativeHandle(ref, () => ({
      getAgMapChartImage: async () => {
        if (!agMapChartRef.current) return null;
  
        const AgchartNode = agMapChartRef.current;
        const canvas = await html2canvas(AgchartNode, {
          useCORS: true,
          logging: true,
        });
  
        return canvas.toDataURL("image/jpeg", 0.9);
      },
    }));
  
    const [options, setOptions] = useState({
      data: props.dataType === 'ILI' ? ILI_data : props.dataType === 'Sari' ? Sari_data : Specimens_data,
      topology,
      series: [
        {
          type: "map-shape-background",
        },
        {
          type: "map-shape",
          title: props.title || "Data Visualization",
          idKey: "name",
          colorKey: "value",
          colorName: props.colorName || "Value",
        },
      ],
      gradientLegend: {
        enabled: true,
        position: "right",
        gradient: {
          preferredLength: 200,
          thickness: 2,
        },
        scale: {
          label: {
            fontSize: 10,
            formatter: (p) => p.value + (props.unit || ""),
          },
        },
      },
      legend: {
        enabled: true,
      },
    });
  
    return (
      <div ref={agMapChartRef} style={{ height: "400px", width: "100%" }}>
        <AgCharts options={options} />
      </div>
    );
  });