export const chinaTopology = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [116.129623, 29.82485],
            [116.059059, 30.209255],
            [115.760956, 30.674276],
            [116.001167, 30.949516],
            [115.875023, 31.144224],
            [115.557411, 31.1597],
            [115.364372, 31.405039],
            [115.491806, 31.675667],
            [115.904358, 31.820019],
            [115.930763, 32.166019],
            [115.829315, 32.52697],
            [115.615974, 32.410995],
            [115.191528, 32.590481],
            [115.133911, 32.899525],
            [115.28569, 33.135986],
            [115.407524, 33.559216],
            [115.63195, 33.586838],
            [115.549286, 33.891666],
            [115.722046, 34.080299],
            [116.149857, 33.711246],
            [116.633545, 33.988464],
            [116.510933, 34.297325],
            [116.173653, 34.431614],
            [116.185913, 34.579468],
            [116.368607, 34.640869],
            [116.823044, 34.390011],
            [117.18663, 34.070179],
            [117.509041, 34.062248],
            [117.752937, 33.887058],
            [117.7258, 33.728981],
            [118.161636, 33.713795],
            [117.966393, 33.283028],
            [118.208374, 33.193462],
            [118.240677, 32.84985],
            [118.36602, 32.721146],
            [118.706917, 32.721516],
            [118.845215, 32.959549],
            [119.178001, 32.832832],
            [119.129013, 32.493427],
            [118.716782, 32.610863],
            [118.656952, 32.220146],
            [118.364098, 31.937202],
            [118.488136, 31.785788],
            [118.859612, 31.624331],
            [118.786819, 31.232365],
            [119.159317, 31.300915],
            [119.632111, 31.139343],
            [119.570267, 30.838839],
            [119.209274, 30.295321],
            [118.879913, 30.320143],
            [118.891502, 29.951298],
            [118.472031, 29.506912],
            [118.189453, 29.395222],
            [118.008888, 29.579794],
            [117.533562, 29.60195],
            [117.33313, 29.8503],
            [116.835899, 29.573484],
            [116.651825, 29.688986],
            [116.89521, 29.952171],
            [116.129623, 29.82485],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.1_1",
        NAME_1: "Anhui",
        VARNAME_1: "Ānhuī",
        NL_NAME_1: "安徽|安徽",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.AH",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [117.379738, 40.226871],
            [117.193985, 40.082783],
            [116.834869, 40.043587],
            [116.894295, 39.696308],
            [116.799385, 39.610008],
            [116.344795, 39.448544],
            [116.134758, 39.564999],
            [115.681664, 39.599796],
            [115.440819, 40.026234],
            [115.955132, 40.261818],
            [115.729225, 40.501873],
            [116.108749, 40.622406],
            [116.450974, 40.978912],
            [116.666885, 40.976711],
            [117.195091, 40.496902],
            [117.379738, 40.226871],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.2_1",
        NAME_1: "Beijing",
        VARNAME_1: "Běijīng",
        NL_NAME_1: "北京|北京",
        TYPE_1: "Zhíxiáshì",
        ENGTYPE_1: "Municipality",
        CC_1: "",
        HASC_1: "CN.BJ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [109.270203, 28.503477],
            [108.990822, 28.164127],
            [108.732483, 28.235146],
            [108.379951, 28.774687],
            [108.29673, 29.045576],
            [107.403137, 29.156733],
            [107.384537, 28.856974],
            [107.010567, 28.887796],
            [106.722252, 28.533972],
            [106.373581, 28.530485],
            [106.247749, 28.799732],
            [105.988113, 28.980568],
            [105.799912, 28.941227],
            [105.696213, 29.272169],
            [105.449142, 29.29394],
            [105.286743, 29.545477],
            [105.724586, 29.856422],
            [105.558777, 30.184237],
            [105.790787, 30.423487],
            [106.202103, 30.21261],
            [106.560379, 30.306889],
            [106.725014, 30.037191],
            [107.028023, 30.03611],
            [107.481644, 30.606314],
            [107.510681, 30.844881],
            [107.869003, 30.808849],
            [108.230232, 31.513105],
            [108.534233, 31.677107],
            [108.286552, 31.929058],
            [108.514549, 32.212044],
            [109.272758, 31.801455],
            [109.576607, 31.733168],
            [110.138222, 31.395578],
            [110.19043, 31.1506],
            [110.075706, 30.804096],
            [109.893662, 30.891636],
            [109.446747, 30.617092],
            [108.687111, 30.589031],
            [108.403938, 30.377985],
            [108.569382, 30.253855],
            [108.516533, 29.870522],
            [108.868134, 29.639494],
            [108.86644, 29.449812],
            [109.2286, 29.122456],
            [109.270203, 28.503477],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.3_1",
        NAME_1: "Chongqing",
        VARNAME_1: "Chóngqìng",
        NL_NAME_1: "重慶|重庆",
        TYPE_1: "Zhíxiáshì",
        ENGTYPE_1: "Municipality",
        CC_1: "",
        HASC_1: "CN.CQ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [118.175133, 24.66486],
            [118.134583, 24.27486],
            [117.706253, 23.952084],
            [117.270416, 23.737923],
            [117.18708, 23.618193],
            [117.046173, 23.703323],
            [116.906769, 24.365011],
            [116.721962, 24.665703],
            [116.509689, 24.654196],
            [116.211891, 24.852707],
            [115.877266, 24.935835],
            [115.851509, 25.213085],
            [116.132462, 25.87007],
            [116.420357, 26.076983],
            [116.383743, 26.241091],
            [116.59362, 26.48925],
            [116.510406, 26.724424],
            [116.674622, 26.981924],
            [116.942047, 27.041473],
            [117.164558, 27.298212],
            [117.020317, 27.663923],
            [117.471825, 27.938742],
            [117.784164, 27.899172],
            [118.336784, 28.096085],
            [118.424271, 28.292282],
            [118.803703, 28.229174],
            [118.868881, 27.537115],
            [119.239708, 27.42469],
            [119.700478, 27.51911],
            [119.871284, 27.305782],
            [120.243645, 27.434687],
            [120.434593, 27.171734],
            [120.109024, 26.924583],
            [120.101242, 26.695421],
            [119.867363, 26.349861],
            [119.515686, 26.08292],
            [119.616249, 25.887362],
            [119.581253, 25.585701],
            [119.150139, 25.399311],
            [119.268753, 25.17597],
            [118.940697, 25.006561],
            [118.628197, 24.54542],
            [118.175133, 24.66486],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.4_1",
        NAME_1: "Fujian",
        VARNAME_1: "Fújiàn",
        NL_NAME_1: "福建",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.FJ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [101.774231, 33.548042],
            [101.54747, 33.694004],
            [101.240608, 33.68232],
            [100.813576, 34.150478],
            [100.906868, 34.381611],
            [101.650803, 34.121708],
            [101.899109, 34.132668],
            [102.250908, 34.358482],
            [101.914337, 34.742989],
            [102.004646, 34.950432],
            [102.279129, 35.058537],
            [102.683922, 35.761406],
            [102.953995, 35.865234],
            [102.991547, 36.265728],
            [102.826828, 36.355118],
            [102.723656, 36.766659],
            [102.471382, 36.957989],
            [102.593399, 37.152779],
            [102.288422, 37.367676],
            [101.542519, 37.656311],
            [101.110832, 37.935677],
            [100.634659, 38.101261],
            [100.589073, 38.249485],
            [100.081184, 38.409706],
            [99.658302, 38.448959],
            [98.818108, 39.083874],
            [98.583908, 38.934086],
            [98.383118, 39.02866],
            [98.098335, 38.815048],
            [97.400383, 39.145912],
            [96.990173, 39.171623],
            [96.959702, 38.338337],
            [96.515732, 38.464706],
            [96.315063, 38.637993],
            [95.690765, 38.879173],
            [95.264748, 39.191685],
            [94.630127, 39.30706],
            [93.608086, 39.263451],
            [92.950562, 39.171055],
            [92.94313, 39.458271],
            [92.76416, 39.879799],
            [92.917992, 40.103733],
            [92.913811, 40.498299],
            [93.353943, 40.472462],
            [93.646751, 40.61911],
            [94.077011, 41.158325],
            [94.67984, 41.622211],
            [95.046371, 41.771229],
            [95.98967, 41.894283],
            [96.096642, 42.599667],
            [96.365166, 42.717075],
            [97.184723, 42.77169],
            [97.84021, 41.655746],
            [97.625511, 41.496742],
            [97.966667, 41.102913],
            [98.33477, 40.913048],
            [98.257401, 40.52898],
            [98.636818, 40.539402],
            [98.846207, 40.736595],
            [99.179375, 40.864712],
            [100.015846, 40.906425],
            [100.241798, 40.605705],
            [99.906899, 40.212147],
            [99.619377, 40.06403],
            [99.764938, 39.885975],
            [100.250633, 39.687466],
            [100.499611, 39.398361],
            [100.837646, 39.401348],
            [100.862274, 39.105923],
            [101.187523, 38.967781],
            [101.327522, 38.782753],
            [101.773056, 38.665138],
            [102.08049, 38.8969],
            [101.815231, 39.102142],
            [102.455795, 39.25404],
            [103.012505, 39.105091],
            [103.361351, 39.346069],
            [103.837456, 39.458847],
            [104.205711, 39.092949],
            [103.876305, 38.645123],
            [103.417717, 38.412624],
            [103.544449, 38.155285],
            [103.406967, 37.851868],
            [103.668976, 37.785751],
            [104.178154, 37.408188],
            [104.279076, 37.428288],
            [104.673393, 37.408348],
            [104.910599, 37.099552],
            [105.169609, 36.985584],
            [105.198998, 36.708305],
            [105.504639, 36.143032],
            [105.32534, 36.018593],
            [105.489105, 35.724865],
            [105.663872, 35.747375],
            [106.08786, 35.414886],
            [106.507553, 35.438972],
            [106.43911, 35.69413],
            [106.749596, 35.687607],
            [106.923347, 35.807312],
            [106.860443, 36.200603],
            [106.468536, 36.295067],
            [106.446449, 36.56189],
            [106.652557, 36.818123],
            [106.60286, 37.108383],
            [107.271393, 37.082253],
            [107.294968, 36.941544],
            [107.895432, 36.760159],
            [107.95443, 36.655251],
            [108.614517, 36.434116],
            [108.707626, 36.138363],
            [108.501007, 35.895126],
            [108.611229, 35.56321],
            [108.575089, 35.308823],
            [107.697678, 35.193741],
            [107.801704, 34.956863],
            [107.196236, 34.882576],
            [106.916161, 35.095222],
            [106.545708, 35.087738],
            [106.548737, 34.757553],
            [106.318947, 34.591091],
            [106.713997, 34.374432],
            [106.497681, 34.106415],
            [106.442642, 33.614723],
            [105.955429, 33.610134],
            [105.728973, 33.392807],
            [105.929459, 33.194454],
            [105.919235, 33.008152],
            [105.495354, 32.91074],
            [105.449127, 32.736294],
            [105.141594, 32.598896],
            [104.643738, 32.661419],
            [104.300194, 32.831444],
            [104.380806, 32.996159],
            [104.264328, 33.393478],
            [104.04966, 33.686806],
            [103.766243, 33.660385],
            [103.150146, 33.811813],
            [103.167442, 34.093655],
            [102.92524, 34.309345],
            [102.734848, 34.268593],
            [102.193352, 33.918644],
            [102.439133, 33.581356],
            [102.206024, 33.234425],
            [101.840828, 33.184826],
            [101.938866, 33.445759],
            [101.774231, 33.548042],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.5_1",
        NAME_1: "Gansu",
        VARNAME_1: "Gānsù",
        NL_NAME_1: "甘肅|甘肃",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.GS",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [109.751442, 21.565697],
            [109.935417, 21.827412],
            [110.284843, 21.91131],
            [110.434181, 22.20224],
            [110.634918, 22.150309],
            [110.760239, 22.577166],
            [111.051468, 22.654041],
            [111.424797, 23.038248],
            [111.351143, 23.287716],
            [111.641632, 23.820309],
            [111.923042, 23.968458],
            [111.871994, 24.228792],
            [112.0569, 24.36512],
            [111.920906, 24.631266],
            [112.015915, 24.739962],
            [112.164093, 24.911377],
            [112.178192, 25.177279],
            [112.654007, 25.128872],
            [112.773949, 24.896973],
            [113.000854, 24.930334],
            [112.962799, 25.160791],
            [113.154137, 25.487932],
            [113.583992, 25.323074],
            [113.959213, 25.457321],
            [114.118126, 25.312197],
            [114.529747, 25.41853],
            [114.732002, 25.236313],
            [114.39032, 24.952095],
            [114.276329, 24.709246],
            [114.426155, 24.487041],
            [115.410835, 24.780825],
            [115.721596, 24.537247],
            [115.877266, 24.935835],
            [116.211891, 24.852707],
            [116.509689, 24.654196],
            [116.721962, 24.665703],
            [116.906769, 24.365011],
            [117.046173, 23.703323],
            [117.18708, 23.618193],
            [116.895981, 23.54347],
            [116.772362, 23.25708],
            [116.455696, 22.94931],
            [115.875694, 22.797083],
            [115.662918, 22.877361],
            [115.391533, 22.691528],
            [115.230698, 22.83153],
            [114.94236, 22.768751],
            [114.72097, 22.795973],
            [114.426529, 22.601805],
            [114.041992, 22.505676],
            [113.644028, 22.640141],
            [113.599861, 22.328194],
            [113.400139, 22.009861],
            [112.768471, 21.937361],
            [112.56208, 21.762081],
            [112.26236, 21.70236],
            [112.120407, 21.80014],
            [111.623192, 21.543194],
            [110.743187, 21.38236],
            [110.415413, 21.192921],
            [110.347923, 20.99542],
            [110.530983, 20.481251],
            [110.282639, 20.25403],
            [109.949585, 20.255972],
            [109.676529, 20.863468],
            [109.77153, 21.32431],
            [109.751442, 21.565697],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.6_1",
        NAME_1: "Guangdong",
        VARNAME_1: "Guǎngdōng",
        NL_NAME_1: "廣東|广东",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.GD",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [105.541382, 23.193666],
            [105.810516, 23.504873],
            [106.059418, 23.490929],
            [106.18795, 23.838175],
            [105.99865, 24.132044],
            [105.492531, 24.028812],
            [105.039909, 24.444689],
            [104.609138, 24.380915],
            [104.530022, 24.734367],
            [104.713661, 24.623714],
            [105.208107, 24.998549],
            [105.506683, 24.810123],
            [105.995171, 24.648066],
            [106.145874, 24.949848],
            [106.894691, 25.197292],
            [107.058739, 25.561949],
            [107.327446, 25.502134],
            [107.502464, 25.21908],
            [107.810226, 25.133516],
            [108.129501, 25.237112],
            [108.306854, 25.530018],
            [108.934181, 25.558533],
            [109.100922, 25.807705],
            [109.307625, 25.770906],
            [109.47802, 26.032774],
            [109.821472, 25.914623],
            [110.330612, 25.995586],
            [110.607796, 26.337229],
            [110.987595, 26.333954],
            [111.257164, 26.212008],
            [111.215401, 25.897919],
            [111.28569, 25.436989],
            [111.091988, 25.281908],
            [111.431229, 25.101143],
            [111.474075, 24.800383],
            [112.015915, 24.739962],
            [111.920906, 24.631266],
            [112.0569, 24.36512],
            [111.871994, 24.228792],
            [111.923042, 23.968458],
            [111.641632, 23.820309],
            [111.351143, 23.287716],
            [111.424797, 23.038248],
            [111.051468, 22.654041],
            [110.760239, 22.577166],
            [110.634918, 22.150309],
            [110.434181, 22.20224],
            [110.284843, 21.91131],
            [109.935417, 21.827412],
            [109.751442, 21.565697],
            [109.157082, 21.402639],
            [109.125137, 21.575417],
            [108.573471, 21.684303],
            [108.393753, 21.552917],
            [107.952393, 21.5389],
            [107.871445, 21.647375],
            [107.363297, 21.599018],
            [107.024216, 21.944687],
            [106.708694, 22.025627],
            [106.558952, 22.465418],
            [106.835876, 22.802742],
            [106.601524, 22.929129],
            [106.345848, 22.855692],
            [106.20192, 22.984022],
            [105.872772, 22.932711],
            [105.541382, 23.193666],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.7_1",
        NAME_1: "Guangxi",
        VARNAME_1: "Guǎngxī Zhuàngzú",
        NL_NAME_1: "廣西壯族自治區|广西壮族自治区",
        TYPE_1: "Zìzhìqu",
        ENGTYPE_1: "Autonomous Region",
        CC_1: "",
        HASC_1: "CN.GX",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [104.530022, 24.734367],
            [104.752113, 25.217247],
            [104.307976, 25.65877],
            [104.538147, 26.252668],
            [104.67794, 26.376707],
            [104.417824, 26.711546],
            [103.811325, 26.539188],
            [103.634811, 27.119492],
            [103.927177, 27.441984],
            [104.178993, 27.273363],
            [104.340111, 27.461428],
            [104.8638, 27.293619],
            [105.232712, 27.431635],
            [105.300247, 27.709112],
            [105.620857, 27.667246],
            [106.186783, 27.758265],
            [106.317276, 27.976343],
            [105.973793, 28.116268],
            [105.63369, 28.524948],
            [106.103439, 28.634981],
            [106.373581, 28.530485],
            [106.722252, 28.533972],
            [107.010567, 28.887796],
            [107.384537, 28.856974],
            [107.403137, 29.156733],
            [108.29673, 29.045576],
            [108.379951, 28.774687],
            [108.732483, 28.235146],
            [108.990822, 28.164127],
            [109.270203, 28.503477],
            [109.377861, 28.266525],
            [109.299347, 28.076447],
            [109.454132, 27.580151],
            [108.900322, 27.217134],
            [109.389275, 27.165655],
            [109.550529, 26.950481],
            [109.350311, 26.696564],
            [109.47802, 26.032774],
            [109.307625, 25.770906],
            [109.100922, 25.807705],
            [108.934181, 25.558533],
            [108.306854, 25.530018],
            [108.129501, 25.237112],
            [107.810226, 25.133516],
            [107.502464, 25.21908],
            [107.327446, 25.502134],
            [107.058739, 25.561949],
            [106.894691, 25.197292],
            [106.145874, 24.949848],
            [105.995171, 24.648066],
            [105.506683, 24.810123],
            [105.208107, 24.998549],
            [104.713661, 24.623714],
            [104.530022, 24.734367],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.8_1",
        NAME_1: "Guizhou",
        VARNAME_1: "Gùizhōu",
        NL_NAME_1: "貴州|贵州",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.GZ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [109.728195, 18.314306],
            [109.155151, 18.293751],
            [108.686249, 18.506811],
            [108.634583, 19.291531],
            [109.181252, 19.653471],
            [109.258186, 19.864033],
            [109.576813, 19.97764],
            [109.989296, 19.93375],
            [110.57486, 20.095421],
            [110.939026, 20.004301],
            [110.982636, 19.643194],
            [110.671249, 19.382084],
            [110.513191, 18.969864],
            [110.531517, 18.79458],
            [110.098473, 18.525421],
            [110.050697, 18.38681],
            [109.728195, 18.314306],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.9_1",
        NAME_1: "Hainan",
        VARNAME_1: "Hǎinán",
        NL_NAME_1: "海南",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.HA",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [116.894295, 39.696308],
              [116.834869, 40.043587],
              [117.193985, 40.082783],
              [117.15242, 39.626743],
              [116.894295, 39.696308],
            ],
          ],
          [
            [
              [113.724342, 36.360001],
              [113.492821, 36.74033],
              [113.786469, 36.894398],
              [113.751709, 37.075169],
              [114.1119, 37.589737],
              [113.877113, 38.053986],
              [113.518791, 38.382465],
              [113.760887, 38.958836],
              [114.044205, 39.134827],
              [114.357864, 39.103451],
              [114.545372, 39.547451],
              [114.386375, 39.867168],
              [114.051682, 39.928169],
              [114.057838, 40.163113],
              [114.307922, 40.368305],
              [114.127861, 40.73576],
              [113.861801, 41.067337],
              [114.013466, 41.517654],
              [114.205093, 41.79763],
              [114.768799, 42.10902],
              [114.939491, 41.820648],
              [114.889351, 41.634548],
              [115.215187, 41.578056],
              [115.910172, 41.943233],
              [116.189796, 41.862461],
              [116.87339, 42.016464],
              [116.898109, 42.388458],
              [117.403946, 42.474705],
              [117.77314, 42.616489],
              [118.017708, 42.382694],
              [118.090958, 42.102806],
              [118.300232, 41.977608],
              [118.123947, 41.739986],
              [118.342651, 41.340641],
              [119.233109, 41.312511],
              [118.835594, 40.803291],
              [119.295921, 40.528835],
              [119.53701, 40.549286],
              [119.807213, 39.979584],
              [119.361809, 39.745419],
              [119.305687, 39.420971],
              [119.003197, 39.197083],
              [118.631531, 39.167919],
              [118.335976, 39.024582],
              [118.012688, 39.218193],
              [118.010475, 39.361912],
              [117.693596, 39.580555],
              [117.513191, 39.885006],
              [117.640846, 40.09824],
              [117.379738, 40.226871],
              [117.195091, 40.496902],
              [116.666885, 40.976711],
              [116.450974, 40.978912],
              [116.108749, 40.622406],
              [115.729225, 40.501873],
              [115.955132, 40.261818],
              [115.440819, 40.026234],
              [115.681664, 39.599796],
              [116.134758, 39.564999],
              [116.344795, 39.448544],
              [116.799385, 39.610008],
              [116.901459, 39.064217],
              [116.750755, 38.740154],
              [117.269318, 38.569546],
              [117.564308, 38.655693],
              [117.857544, 38.267361],
              [117.871262, 38.26144],
              [117.871117, 38.255974],
              [117.405815, 37.84362],
              [116.794418, 37.831295],
              [116.431961, 37.469872],
              [115.983894, 37.336353],
              [115.683945, 36.819458],
              [115.496025, 36.768993],
              [115.278908, 36.494328],
              [115.47287, 36.169361],
              [115.120499, 36.211357],
              [114.578712, 36.125465],
              [114.320442, 36.245853],
              [113.724342, 36.360001],
            ],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.10_1",
        NAME_1: "Hebei",
        VARNAME_1: "Héběi",
        NL_NAME_1: "河北",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.HB",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [123.171043, 46.246681],
            [123.003723, 46.573723],
            [123.588898, 46.686657],
            [123.624474, 46.803616],
            [122.889008, 46.815731],
            [122.841965, 47.052826],
            [122.387917, 47.344147],
            [122.549217, 47.520741],
            [123.574219, 48.041721],
            [124.0718, 48.429424],
            [124.570755, 48.257568],
            [124.512802, 48.548328],
            [124.882324, 49.177158],
            [125.266159, 49.358547],
            [125.193123, 49.736938],
            [125.271927, 50.12553],
            [125.819138, 50.546844],
            [125.788727, 50.735584],
            [126.064072, 50.959114],
            [125.343536, 51.622177],
            [125.127304, 51.632412],
            [124.857208, 51.378189],
            [123.635208, 51.318493],
            [123.282829, 51.249447],
            [122.966988, 51.333584],
            [122.742737, 51.742352],
            [122.579117, 52.265209],
            [122.126373, 52.474529],
            [121.706871, 52.316814],
            [121.192841, 52.602341],
            [121.809166, 53.064106],
            [121.69239, 53.388512],
            [122.833542, 53.452904],
            [123.257019, 53.56086],
            [123.844421, 53.48872],
            [124.375488, 53.246181],
            [124.833046, 53.13269],
            [125.18821, 53.191589],
            [125.579659, 53.08073],
            [126.343681, 52.39595],
            [126.549957, 52.130169],
            [126.469208, 51.93531],
            [126.979866, 51.30777],
            [126.922852, 51.057999],
            [127.359352, 50.583279],
            [127.57148, 50.24276],
            [127.515129, 49.834641],
            [127.802422, 49.591248],
            [128.260895, 49.49995],
            [128.708252, 49.563721],
            [129.118134, 49.348709],
            [129.520966, 49.411892],
            [129.906418, 49.053997],
            [130.257477, 48.861847],
            [130.825531, 48.300091],
            [130.652054, 48.109341],
            [130.954193, 47.720272],
            [131.803726, 47.669712],
            [132.504822, 47.71125],
            [132.691483, 47.953609],
            [133.160294, 48.105701],
            [133.468155, 48.0676],
            [134.057693, 48.335384],
            [134.575409, 48.363216],
            [134.550552, 48.024399],
            [134.771561, 47.75346],
            [134.49353, 47.44381],
            [134.149094, 47.25951],
            [134.014709, 46.654362],
            [133.850861, 46.460682],
            [133.47612, 45.654419],
            [133.215195, 45.509113],
            [133.135559, 45.12711],
            [132.851974, 45.055691],
            [132.560577, 44.555042],
            [132.111618, 44.740685],
            [132.183594, 44.956852],
            [132.058594, 45.235588],
            [131.826782, 45.308731],
            [131.469849, 44.95948],
            [131.137955, 44.937042],
            [131.102661, 44.691559],
            [131.293106, 44.0797],
            [131.203522, 43.82357],
            [131.275955, 43.478718],
            [130.515808, 43.628323],
            [130.330063, 43.948021],
            [129.230469, 43.806732],
            [129.202118, 43.574463],
            [128.849609, 43.559551],
            [128.462769, 44.157154],
            [128.46698, 44.374275],
            [128.210281, 44.429901],
            [128.047211, 44.108421],
            [127.620811, 44.183495],
            [127.448669, 44.6078],
            [127.024315, 44.590538],
            [126.963257, 45.0723],
            [126.60437, 45.233635],
            [126.237755, 45.137497],
            [125.901443, 45.190578],
            [125.679993, 45.508011],
            [125.068245, 45.38916],
            [124.539505, 45.407074],
            [124.127708, 45.630177],
            [123.950424, 45.944031],
            [123.885109, 46.301369],
            [123.171043, 46.246681],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.11_1",
        NAME_1: "Heilongjiang",
        VARNAME_1: "Hēilóngjiāng",
        NL_NAME_1: "黑龙江省|黑龍江省",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.HL",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [115.364372, 31.405039],
            [115.229965, 31.553312],
            [114.830032, 31.462206],
            [114.540588, 31.616879],
            [114.58033, 31.756344],
            [113.980438, 31.756737],
            [113.841637, 31.849066],
            [113.6604, 32.421482],
            [113.417198, 32.275146],
            [113.216057, 32.424839],
            [112.15136, 32.385262],
            [111.715263, 32.60651],
            [111.571899, 32.595814],
            [111.232399, 33.042702],
            [110.977661, 33.259674],
            [110.997704, 33.583656],
            [110.827217, 33.674751],
            [110.355522, 34.523582],
            [110.380234, 34.604488],
            [110.682404, 34.594139],
            [111.150177, 34.808304],
            [111.567627, 34.849541],
            [112.071861, 35.22913],
            [112.769073, 35.208904],
            [113.490547, 35.530548],
            [113.724342, 36.360001],
            [114.320442, 36.245853],
            [114.578712, 36.125465],
            [115.120499, 36.211357],
            [115.47287, 36.169361],
            [115.331924, 35.804111],
            [115.348778, 35.506802],
            [115.019257, 35.374371],
            [114.842934, 35.177551],
            [115.424973, 34.79631],
            [115.531532, 34.579025],
            [116.185913, 34.579468],
            [116.173653, 34.431614],
            [116.510933, 34.297325],
            [116.633545, 33.988464],
            [116.149857, 33.711246],
            [115.722046, 34.080299],
            [115.549286, 33.891666],
            [115.63195, 33.586838],
            [115.407524, 33.559216],
            [115.28569, 33.135986],
            [115.133911, 32.899525],
            [115.191528, 32.590481],
            [115.615974, 32.410995],
            [115.829315, 32.52697],
            [115.930763, 32.166019],
            [115.904358, 31.820019],
            [115.491806, 31.675667],
            [115.364372, 31.405039],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.12_1",
        NAME_1: "Henan",
        VARNAME_1: "Hénán",
        NL_NAME_1: "河南",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.HE",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [109.2286, 29.122456],
            [108.86644, 29.449812],
            [108.868134, 29.639494],
            [108.516533, 29.870522],
            [108.569382, 30.253855],
            [108.403938, 30.377985],
            [108.687111, 30.589031],
            [109.446747, 30.617092],
            [109.893662, 30.891636],
            [110.075706, 30.804096],
            [110.19043, 31.1506],
            [110.138222, 31.395578],
            [109.576607, 31.733168],
            [109.616821, 32.109123],
            [109.490417, 32.302586],
            [109.626961, 32.602417],
            [110.023064, 32.550529],
            [110.136909, 32.804752],
            [109.758919, 32.909706],
            [109.840263, 33.248894],
            [110.213142, 33.16502],
            [110.80571, 33.148438],
            [110.977661, 33.259674],
            [111.232399, 33.042702],
            [111.571899, 32.595814],
            [111.715263, 32.60651],
            [112.15136, 32.385262],
            [113.216057, 32.424839],
            [113.417198, 32.275146],
            [113.6604, 32.421482],
            [113.841637, 31.849066],
            [113.980438, 31.756737],
            [114.58033, 31.756344],
            [114.540588, 31.616879],
            [114.830032, 31.462206],
            [115.229965, 31.553312],
            [115.364372, 31.405039],
            [115.557411, 31.1597],
            [115.875023, 31.144224],
            [116.001167, 30.949516],
            [115.760956, 30.674276],
            [116.059059, 30.209255],
            [116.129623, 29.82485],
            [115.877625, 29.735132],
            [115.505165, 29.841829],
            [114.513412, 29.327091],
            [114.287842, 29.366165],
            [113.940132, 29.048281],
            [113.599609, 29.255363],
            [113.731743, 29.578884],
            [113.437157, 29.763933],
            [113.143463, 29.448658],
            [112.678169, 29.596909],
            [112.362373, 29.539343],
            [111.784897, 29.911354],
            [111.385941, 29.918917],
            [111.230049, 30.045486],
            [110.820267, 30.128004],
            [110.522758, 30.062428],
            [110.633217, 29.763695],
            [110.374863, 29.640249],
            [110.108452, 29.792105],
            [109.518318, 29.623173],
            [109.2286, 29.122456],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.13_1",
        NAME_1: "Hubei",
        VARNAME_1: "Húběi",
        NL_NAME_1: "湖北",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.HU",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [112.015915, 24.739962],
            [111.474075, 24.800383],
            [111.431229, 25.101143],
            [111.091988, 25.281908],
            [111.28569, 25.436989],
            [111.215401, 25.897919],
            [111.257164, 26.212008],
            [110.987595, 26.333954],
            [110.607796, 26.337229],
            [110.330612, 25.995586],
            [109.821472, 25.914623],
            [109.47802, 26.032774],
            [109.350311, 26.696564],
            [109.550529, 26.950481],
            [109.389275, 27.165655],
            [108.900322, 27.217134],
            [109.454132, 27.580151],
            [109.299347, 28.076447],
            [109.377861, 28.266525],
            [109.270203, 28.503477],
            [109.2286, 29.122456],
            [109.518318, 29.623173],
            [110.108452, 29.792105],
            [110.374863, 29.640249],
            [110.633217, 29.763695],
            [110.522758, 30.062428],
            [110.820267, 30.128004],
            [111.230049, 30.045486],
            [111.385941, 29.918917],
            [111.784897, 29.911354],
            [112.362373, 29.539343],
            [112.678169, 29.596909],
            [113.143463, 29.448658],
            [113.437157, 29.763933],
            [113.731743, 29.578884],
            [113.599609, 29.255363],
            [113.940132, 29.048281],
            [114.145554, 28.820494],
            [114.117592, 28.598354],
            [114.249229, 28.332539],
            [113.60186, 27.628822],
            [113.61129, 27.348873],
            [113.916237, 26.962132],
            [113.901413, 26.62179],
            [114.116974, 26.592796],
            [113.965073, 26.243938],
            [114.025711, 25.926094],
            [113.910439, 25.72934],
            [113.959213, 25.457321],
            [113.583992, 25.323074],
            [113.154137, 25.487932],
            [112.962799, 25.160791],
            [113.000854, 24.930334],
            [112.773949, 24.896973],
            [112.654007, 25.128872],
            [112.178192, 25.177279],
            [112.164093, 24.911377],
            [112.015915, 24.739962],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.14_1",
        NAME_1: "Hunan",
        VARNAME_1: "Húnán",
        NL_NAME_1: "湖南",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.HN",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [119.632111, 31.139343],
            [119.159317, 31.300915],
            [118.786819, 31.232365],
            [118.859612, 31.624331],
            [118.488136, 31.785788],
            [118.364098, 31.937202],
            [118.656952, 32.220146],
            [118.716782, 32.610863],
            [119.129013, 32.493427],
            [119.178001, 32.832832],
            [118.845215, 32.959549],
            [118.706917, 32.721516],
            [118.36602, 32.721146],
            [118.240677, 32.84985],
            [118.208374, 33.193462],
            [117.966393, 33.283028],
            [118.161636, 33.713795],
            [117.7258, 33.728981],
            [117.752937, 33.887058],
            [117.509041, 34.062248],
            [117.18663, 34.070179],
            [116.823044, 34.390011],
            [116.368607, 34.640869],
            [116.459694, 34.89851],
            [116.961479, 34.876541],
            [117.167686, 34.48243],
            [117.556503, 34.467175],
            [117.965431, 34.67564],
            [118.162666, 34.39336],
            [118.399651, 34.438042],
            [118.460823, 34.677834],
            [118.71508, 34.760876],
            [118.920563, 35.061733],
            [119.284378, 35.089863],
            [119.171806, 34.857361],
            [119.782082, 34.46986],
            [120.26403, 34.309029],
            [120.895973, 33.004311],
            [120.855972, 32.765419],
            [120.985138, 32.553749],
            [121.393471, 32.37764],
            [121.391808, 32.180969],
            [121.850418, 31.915703],
            [121.662361, 31.72736],
            [121.329033, 31.87875],
            [121.096802, 31.78347],
            [121.325974, 31.505417],
            [121.207253, 31.47485],
            [120.895119, 31.011978],
            [120.507065, 30.767185],
            [120.361671, 30.951988],
            [120.150826, 30.936314],
            [119.908951, 31.175247],
            [119.632111, 31.139343],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.15_1",
        NAME_1: "Jiangsu",
        VARNAME_1: "Jiāngsū",
        NL_NAME_1: "江蘇|江苏",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.JS",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [113.959213, 25.457321],
            [113.910439, 25.72934],
            [114.025711, 25.926094],
            [113.965073, 26.243938],
            [114.116974, 26.592796],
            [113.901413, 26.62179],
            [113.916237, 26.962132],
            [113.61129, 27.348873],
            [113.60186, 27.628822],
            [114.249229, 28.332539],
            [114.117592, 28.598354],
            [114.145554, 28.820494],
            [113.940132, 29.048281],
            [114.287842, 29.366165],
            [114.513412, 29.327091],
            [115.505165, 29.841829],
            [115.877625, 29.735132],
            [116.129623, 29.82485],
            [116.89521, 29.952171],
            [116.651825, 29.688986],
            [116.835899, 29.573484],
            [117.33313, 29.8503],
            [117.533562, 29.60195],
            [118.008888, 29.579794],
            [118.189453, 29.395222],
            [118.034599, 29.210161],
            [118.096474, 28.99362],
            [118.360336, 28.811964],
            [118.466103, 28.475739],
            [118.424271, 28.292282],
            [118.336784, 28.096085],
            [117.784164, 27.899172],
            [117.471825, 27.938742],
            [117.020317, 27.663923],
            [117.164558, 27.298212],
            [116.942047, 27.041473],
            [116.674622, 26.981924],
            [116.510406, 26.724424],
            [116.59362, 26.48925],
            [116.383743, 26.241091],
            [116.420357, 26.076983],
            [116.132462, 25.87007],
            [115.851509, 25.213085],
            [115.877266, 24.935835],
            [115.721596, 24.537247],
            [115.410835, 24.780825],
            [114.426155, 24.487041],
            [114.276329, 24.709246],
            [114.39032, 24.952095],
            [114.732002, 25.236313],
            [114.529747, 25.41853],
            [114.118126, 25.312197],
            [113.959213, 25.457321],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.16_1",
        NAME_1: "Jiangxi",
        VARNAME_1: "Jiāngxī",
        NL_NAME_1: "江西",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.JX",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [123.696838, 43.370869],
            [123.472496, 43.857594],
            [123.025818, 44.503048],
            [122.430008, 44.224422],
            [122.093391, 44.636604],
            [122.161675, 45.430267],
            [121.946159, 45.706295],
            [121.669983, 45.717613],
            [121.825348, 46.021149],
            [122.246132, 45.803062],
            [122.71769, 45.713173],
            [122.779938, 46.067497],
            [123.171043, 46.246681],
            [123.885109, 46.301369],
            [123.950424, 45.944031],
            [124.127708, 45.630177],
            [124.539505, 45.407074],
            [125.068245, 45.38916],
            [125.679993, 45.508011],
            [125.901443, 45.190578],
            [126.237755, 45.137497],
            [126.60437, 45.233635],
            [126.963257, 45.0723],
            [127.024315, 44.590538],
            [127.448669, 44.6078],
            [127.620811, 44.183495],
            [128.047211, 44.108421],
            [128.210281, 44.429901],
            [128.46698, 44.374275],
            [128.462769, 44.157154],
            [128.849609, 43.559551],
            [129.202118, 43.574463],
            [129.230469, 43.806732],
            [130.330063, 43.948021],
            [130.515808, 43.628323],
            [131.275955, 43.478718],
            [131.029602, 42.863281],
            [130.544342, 42.80769],
            [129.89653, 43.001999],
            [129.740585, 42.464314],
            [129.234985, 42.374161],
            [129.140915, 42.143284],
            [128.572586, 41.99794],
            [128.064026, 41.96542],
            [128.313812, 41.583031],
            [128.202026, 41.409599],
            [127.616989, 41.424992],
            [127.159363, 41.539051],
            [126.943901, 41.7882],
            [126.610672, 41.642891],
            [126.44696, 41.354382],
            [125.912514, 40.893143],
            [125.786407, 41.160286],
            [125.315598, 41.684704],
            [125.333809, 42.147251],
            [125.154037, 42.34771],
            [124.983673, 42.770332],
            [124.516357, 42.865658],
            [124.27726, 43.214859],
            [123.696838, 43.370869],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.17_1",
        NAME_1: "Jilin",
        VARNAME_1: "Jílín",
        NL_NAME_1: "吉林",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.JL",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [121.717918, 39.440964],
            [121.435692, 39.512924],
            [121.554031, 39.857643],
            [121.793472, 39.956253],
            [122.301247, 40.47403],
            [121.940407, 40.8032],
            [121.156807, 40.909863],
            [120.674309, 40.537922],
            [120.442642, 40.195694],
            [119.807213, 39.979584],
            [119.53701, 40.549286],
            [119.295921, 40.528835],
            [118.835594, 40.803291],
            [119.233109, 41.312511],
            [119.396881, 41.472874],
            [119.281708, 41.784237],
            [119.537132, 42.355705],
            [120.121155, 41.770279],
            [120.461098, 42.103096],
            [121.024757, 42.24691],
            [121.400246, 42.482876],
            [121.65519, 42.436234],
            [121.930985, 42.684536],
            [122.331162, 42.67255],
            [122.618301, 42.773296],
            [123.061386, 42.773373],
            [123.249794, 42.993176],
            [123.623756, 43.077591],
            [123.696838, 43.370869],
            [124.27726, 43.214859],
            [124.516357, 42.865658],
            [124.983673, 42.770332],
            [125.154037, 42.34771],
            [125.333809, 42.147251],
            [125.315598, 41.684704],
            [125.786407, 41.160286],
            [125.912514, 40.893143],
            [125.454468, 40.668152],
            [124.887543, 40.47089],
            [124.385841, 40.123089],
            [124.200966, 39.859585],
            [123.303192, 39.796261],
            [122.248192, 39.342365],
            [121.96347, 39.033478],
            [121.685692, 39.020145],
            [121.472076, 38.817921],
            [121.133751, 38.725418],
            [121.094307, 38.928749],
            [121.657639, 39.078476],
            [121.717918, 39.440964],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.18_1",
        NAME_1: "Liaoning",
        VARNAME_1: "Liáoníng",
        NL_NAME_1: "遼寧|辽宁",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.LN",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [119.233109, 41.312511],
            [118.342651, 41.340641],
            [118.123947, 41.739986],
            [118.300232, 41.977608],
            [118.090958, 42.102806],
            [118.017708, 42.382694],
            [117.77314, 42.616489],
            [117.403946, 42.474705],
            [116.898109, 42.388458],
            [116.87339, 42.016464],
            [116.189796, 41.862461],
            [115.910172, 41.943233],
            [115.215187, 41.578056],
            [114.889351, 41.634548],
            [114.939491, 41.820648],
            [114.768799, 42.10902],
            [114.205093, 41.79763],
            [114.013466, 41.517654],
            [113.861801, 41.067337],
            [114.127861, 40.73576],
            [114.055099, 40.52739],
            [113.553078, 40.347542],
            [113.2491, 40.410275],
            [112.892044, 40.3283],
            [112.721825, 40.167042],
            [112.302727, 40.251465],
            [111.970085, 39.796257],
            [111.927124, 39.619183],
            [111.48661, 39.658478],
            [111.133766, 39.367733],
            [110.862755, 39.489971],
            [110.010643, 39.213139],
            [109.7136, 39.065636],
            [108.969666, 38.338245],
            [109.054482, 38.081856],
            [108.777962, 37.949837],
            [108.778648, 37.684029],
            [108.01075, 37.670696],
            [107.651749, 37.874371],
            [107.325378, 38.092823],
            [106.996323, 38.121887],
            [106.541161, 38.272808],
            [106.676971, 38.671124],
            [106.964355, 38.957741],
            [106.788727, 39.358307],
            [106.275475, 39.261284],
            [105.969002, 38.910896],
            [105.847427, 38.617783],
            [105.835793, 38.007515],
            [105.595055, 37.699032],
            [105.310829, 37.701458],
            [104.98526, 37.544407],
            [104.409447, 37.515587],
            [104.279076, 37.428288],
            [104.178154, 37.408188],
            [103.668976, 37.785751],
            [103.406967, 37.851868],
            [103.544449, 38.155285],
            [103.417717, 38.412624],
            [103.876305, 38.645123],
            [104.205711, 39.092949],
            [103.837456, 39.458847],
            [103.361351, 39.346069],
            [103.012505, 39.105091],
            [102.455795, 39.25404],
            [101.815231, 39.102142],
            [102.08049, 38.8969],
            [101.773056, 38.665138],
            [101.327522, 38.782753],
            [101.187523, 38.967781],
            [100.862274, 39.105923],
            [100.837646, 39.401348],
            [100.499611, 39.398361],
            [100.250633, 39.687466],
            [99.764938, 39.885975],
            [99.619377, 40.06403],
            [99.906899, 40.212147],
            [100.241798, 40.605705],
            [100.015846, 40.906425],
            [99.179375, 40.864712],
            [98.846207, 40.736595],
            [98.636818, 40.539402],
            [98.257401, 40.52898],
            [98.33477, 40.913048],
            [97.966667, 41.102913],
            [97.625511, 41.496742],
            [97.84021, 41.655746],
            [97.184723, 42.77169],
            [98.247879, 42.637859],
            [99.496277, 42.570084],
            [100.326462, 42.683331],
            [100.911583, 42.64526],
            [101.816818, 42.47361],
            [102.063293, 42.218689],
            [102.739037, 42.134159],
            [103.322083, 41.907471],
            [103.862396, 41.807941],
            [104.543747, 41.883793],
            [104.534012, 41.662621],
            [105.034485, 41.567688],
            [105.801895, 41.957645],
            [106.683632, 42.259392],
            [107.266472, 42.356659],
            [107.452797, 42.461887],
            [107.912422, 42.41201],
            [108.238548, 42.461952],
            [108.782349, 42.409714],
            [109.547478, 42.471664],
            [109.834717, 42.624962],
            [110.416504, 42.768921],
            [111.047142, 43.343456],
            [111.574509, 43.489532],
            [112.015686, 43.791321],
            [111.393951, 44.321613],
            [111.559875, 44.723084],
            [111.83313, 45.048523],
            [112.415527, 45.061523],
            [112.758087, 44.87048],
            [113.62381, 44.743958],
            [114.379272, 45.165283],
            [114.548706, 45.398499],
            [115.000122, 45.365173],
            [115.721153, 45.438438],
            [116.273499, 45.761108],
            [116.26207, 45.950333],
            [116.605286, 46.297119],
            [117.371468, 46.354771],
            [118.27005, 46.787563],
            [118.935913, 46.819527],
            [119.403702, 46.633621],
            [119.920723, 46.7435],
            [119.850708, 47.101685],
            [119.187851, 47.518459],
            [119.124084, 47.703308],
            [118.478699, 48.000122],
            [117.759697, 47.990189],
            [117.374512, 47.638058],
            [116.826782, 47.898399],
            [116.256287, 47.876709],
            [115.949371, 47.679123],
            [115.55378, 47.948326],
            [115.514893, 48.183899],
            [115.8125, 48.261475],
            [115.821495, 48.531666],
            [116.700272, 49.839855],
            [117.274269, 49.63155],
            [117.842033, 49.506931],
            [118.548988, 49.914852],
            [119.197487, 50.012089],
            [119.273491, 50.601753],
            [119.987373, 51.45269],
            [120.086403, 51.66909],
            [120.652031, 51.934109],
            [120.71965, 52.52879],
            [120.280434, 52.866299],
            [120.876717, 53.287033],
            [121.69239, 53.388512],
            [121.809166, 53.064106],
            [121.192841, 52.602341],
            [121.706871, 52.316814],
            [122.126373, 52.474529],
            [122.579117, 52.265209],
            [122.742737, 51.742352],
            [122.966988, 51.333584],
            [123.282829, 51.249447],
            [123.635208, 51.318493],
            [124.857208, 51.378189],
            [125.127304, 51.632412],
            [125.343536, 51.622177],
            [126.064072, 50.959114],
            [125.788727, 50.735584],
            [125.819138, 50.546844],
            [125.271927, 50.12553],
            [125.193123, 49.736938],
            [125.266159, 49.358547],
            [124.882324, 49.177158],
            [124.512802, 48.548328],
            [124.570755, 48.257568],
            [124.0718, 48.429424],
            [123.574219, 48.041721],
            [122.549217, 47.520741],
            [122.387917, 47.344147],
            [122.841965, 47.052826],
            [122.889008, 46.815731],
            [123.624474, 46.803616],
            [123.588898, 46.686657],
            [123.003723, 46.573723],
            [123.171043, 46.246681],
            [122.779938, 46.067497],
            [122.71769, 45.713173],
            [122.246132, 45.803062],
            [121.825348, 46.021149],
            [121.669983, 45.717613],
            [121.946159, 45.706295],
            [122.161675, 45.430267],
            [122.093391, 44.636604],
            [122.430008, 44.224422],
            [123.025818, 44.503048],
            [123.472496, 43.857594],
            [123.696838, 43.370869],
            [123.623756, 43.077591],
            [123.249794, 42.993176],
            [123.061386, 42.773373],
            [122.618301, 42.773296],
            [122.331162, 42.67255],
            [121.930985, 42.684536],
            [121.65519, 42.436234],
            [121.400246, 42.482876],
            [121.024757, 42.24691],
            [120.461098, 42.103096],
            [120.121155, 41.770279],
            [119.537132, 42.355705],
            [119.281708, 41.784237],
            [119.396881, 41.472874],
            [119.233109, 41.312511],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.19_1",
        NAME_1: "Nei Mongol",
        VARNAME_1: "Inner Mongol|Nèiměnggǔ",
        NL_NAME_1: "內蒙古自治區|内蒙古自治区",
        TYPE_1: "Zìzhìqu",
        ENGTYPE_1: "Autonomous Region",
        CC_1: "",
        HASC_1: "CN.NM",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [104.279076, 37.428288],
            [104.409447, 37.515587],
            [104.98526, 37.544407],
            [105.310829, 37.701458],
            [105.595055, 37.699032],
            [105.835793, 38.007515],
            [105.847427, 38.617783],
            [105.969002, 38.910896],
            [106.275475, 39.261284],
            [106.788727, 39.358307],
            [106.964355, 38.957741],
            [106.676971, 38.671124],
            [106.541161, 38.272808],
            [106.996323, 38.121887],
            [107.325378, 38.092823],
            [107.651749, 37.874371],
            [107.310516, 37.593761],
            [107.271393, 37.082253],
            [106.60286, 37.108383],
            [106.652557, 36.818123],
            [106.446449, 36.56189],
            [106.468536, 36.295067],
            [106.860443, 36.200603],
            [106.923347, 35.807312],
            [106.749596, 35.687607],
            [106.43911, 35.69413],
            [106.507553, 35.438972],
            [106.08786, 35.414886],
            [105.663872, 35.747375],
            [105.489105, 35.724865],
            [105.32534, 36.018593],
            [105.504639, 36.143032],
            [105.198998, 36.708305],
            [105.169609, 36.985584],
            [104.910599, 37.099552],
            [104.673393, 37.408348],
            [104.279076, 37.428288],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.20_1",
        NAME_1: "Ningxia Hui",
        VARNAME_1: "Níngxià Húizú",
        NL_NAME_1: "寧夏回族自治區|宁夏回族自治区",
        TYPE_1: "Zìzhìqu",
        ENGTYPE_1: "Autonomous Region",
        CC_1: "",
        HASC_1: "CN.NX",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [97.729965, 32.529659],
            [97.397713, 32.520443],
            [97.301926, 32.082607],
            [96.736305, 31.983194],
            [96.736137, 31.67774],
            [96.250084, 31.932867],
            [96.060516, 31.72341],
            [95.521004, 31.751423],
            [95.371979, 31.964407],
            [95.433388, 32.160007],
            [95.080849, 32.250435],
            [94.622375, 32.670227],
            [94.13578, 32.435955],
            [93.654045, 32.574978],
            [93.515419, 32.480309],
            [93.246407, 32.662952],
            [91.967201, 32.820004],
            [91.378151, 33.23875],
            [90.70668, 33.139141],
            [90.264809, 33.36758],
            [89.739548, 33.917171],
            [89.814171, 34.540432],
            [89.591995, 34.894688],
            [89.497055, 35.365894],
            [89.688042, 35.421749],
            [89.785599, 35.861809],
            [89.731247, 36.084744],
            [89.988831, 36.155739],
            [91.098862, 36.094807],
            [91.027786, 36.537014],
            [90.730186, 36.591377],
            [90.847298, 36.919029],
            [91.304131, 37.018635],
            [91.090782, 37.463398],
            [90.522377, 37.73019],
            [90.480614, 37.958195],
            [90.14225, 38.40213],
            [90.630623, 38.675484],
            [91.296257, 38.753319],
            [92.500031, 39.109432],
            [92.950562, 39.171055],
            [93.608086, 39.263451],
            [94.630127, 39.30706],
            [95.264748, 39.191685],
            [95.690765, 38.879173],
            [96.315063, 38.637993],
            [96.515732, 38.464706],
            [96.959702, 38.338337],
            [96.990173, 39.171623],
            [97.400383, 39.145912],
            [98.098335, 38.815048],
            [98.383118, 39.02866],
            [98.583908, 38.934086],
            [98.818108, 39.083874],
            [99.658302, 38.448959],
            [100.081184, 38.409706],
            [100.589073, 38.249485],
            [100.634659, 38.101261],
            [101.110832, 37.935677],
            [101.542519, 37.656311],
            [102.288422, 37.367676],
            [102.593399, 37.152779],
            [102.471382, 36.957989],
            [102.723656, 36.766659],
            [102.826828, 36.355118],
            [102.991547, 36.265728],
            [102.953995, 35.865234],
            [102.683922, 35.761406],
            [102.279129, 35.058537],
            [102.004646, 34.950432],
            [101.914337, 34.742989],
            [102.250908, 34.358482],
            [101.899109, 34.132668],
            [101.650803, 34.121708],
            [100.906868, 34.381611],
            [100.813576, 34.150478],
            [101.240608, 33.68232],
            [101.54747, 33.694004],
            [101.774231, 33.548042],
            [101.734337, 33.278027],
            [101.164749, 33.161854],
            [101.216904, 32.716076],
            [100.933083, 32.604904],
            [99.768662, 32.952419],
            [99.742401, 32.746674],
            [98.852394, 33.159336],
            [98.662704, 33.652294],
            [98.411163, 33.872028],
            [97.660103, 33.96233],
            [97.388329, 33.888744],
            [97.61969, 33.337727],
            [97.362961, 32.950981],
            [97.487526, 32.653938],
            [97.729965, 32.529659],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.21_1",
        NAME_1: "Qinghai",
        VARNAME_1: "Qīnghǎi",
        NL_NAME_1: "青海",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.QH",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [105.495354, 32.91074],
            [105.919235, 33.008152],
            [105.929459, 33.194454],
            [105.728973, 33.392807],
            [105.955429, 33.610134],
            [106.442642, 33.614723],
            [106.497681, 34.106415],
            [106.713997, 34.374432],
            [106.318947, 34.591091],
            [106.548737, 34.757553],
            [106.545708, 35.087738],
            [106.916161, 35.095222],
            [107.196236, 34.882576],
            [107.801704, 34.956863],
            [107.697678, 35.193741],
            [108.575089, 35.308823],
            [108.611229, 35.56321],
            [108.501007, 35.895126],
            [108.707626, 36.138363],
            [108.614517, 36.434116],
            [107.95443, 36.655251],
            [107.895432, 36.760159],
            [107.294968, 36.941544],
            [107.271393, 37.082253],
            [107.310516, 37.593761],
            [107.651749, 37.874371],
            [108.01075, 37.670696],
            [108.778648, 37.684029],
            [108.777962, 37.949837],
            [109.054482, 38.081856],
            [108.969666, 38.338245],
            [109.7136, 39.065636],
            [110.010643, 39.213139],
            [110.862755, 39.489971],
            [111.133766, 39.367733],
            [111.207245, 39.232056],
            [110.768753, 38.434734],
            [110.505722, 38.192474],
            [110.49894, 38.021873],
            [110.785767, 37.551132],
            [110.408333, 36.994373],
            [110.500084, 36.581142],
            [110.444641, 36.16486],
            [110.601044, 35.605801],
            [110.270561, 34.968773],
            [110.380234, 34.604488],
            [110.355522, 34.523582],
            [110.827217, 33.674751],
            [110.997704, 33.583656],
            [110.977661, 33.259674],
            [110.80571, 33.148438],
            [110.213142, 33.16502],
            [109.840263, 33.248894],
            [109.758919, 32.909706],
            [110.136909, 32.804752],
            [110.023064, 32.550529],
            [109.626961, 32.602417],
            [109.490417, 32.302586],
            [109.616821, 32.109123],
            [109.576607, 31.733168],
            [109.272758, 31.801455],
            [108.514549, 32.212044],
            [107.846054, 32.20866],
            [107.669182, 32.393536],
            [107.259354, 32.40551],
            [107.054932, 32.711998],
            [106.728409, 32.731453],
            [106.430122, 32.619446],
            [105.955116, 32.847637],
            [105.618591, 32.699814],
            [105.495354, 32.91074],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.22_1",
        NAME_1: "Shaanxi",
        VARNAME_1: "Shǎnxī",
        NL_NAME_1: "陝西|陕西",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.SA",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [116.368607, 34.640869],
            [116.185913, 34.579468],
            [115.531532, 34.579025],
            [115.424973, 34.79631],
            [114.842934, 35.177551],
            [115.019257, 35.374371],
            [115.348778, 35.506802],
            [115.331924, 35.804111],
            [115.47287, 36.169361],
            [115.278908, 36.494328],
            [115.496025, 36.768993],
            [115.683945, 36.819458],
            [115.983894, 37.336353],
            [116.431961, 37.469872],
            [116.794418, 37.831295],
            [117.405815, 37.84362],
            [117.871117, 38.255974],
            [118.111252, 38.144032],
            [118.846252, 38.144585],
            [119.098473, 37.834305],
            [118.93042, 37.528751],
            [118.966797, 37.276249],
            [119.244026, 37.134861],
            [119.749031, 37.122921],
            [119.845139, 37.375973],
            [120.084862, 37.444031],
            [120.371246, 37.696804],
            [120.877357, 37.832359],
            [121.141525, 37.598473],
            [121.543747, 37.435421],
            [122.157639, 37.537922],
            [122.571251, 37.361809],
            [122.424026, 36.866531],
            [122.009583, 36.967922],
            [121.185966, 36.685425],
            [120.680138, 36.380421],
            [120.665703, 36.13681],
            [120.285698, 36.060421],
            [119.913193, 35.635975],
            [119.635406, 35.586533],
            [119.284378, 35.089863],
            [118.920563, 35.061733],
            [118.71508, 34.760876],
            [118.460823, 34.677834],
            [118.399651, 34.438042],
            [118.162666, 34.39336],
            [117.965431, 34.67564],
            [117.556503, 34.467175],
            [117.167686, 34.48243],
            [116.961479, 34.876541],
            [116.459694, 34.89851],
            [116.368607, 34.640869],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.23_1",
        NAME_1: "Shandong",
        VARNAME_1: "Shāndōng",
        NL_NAME_1: "山東|山东",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.SD",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [121.2649, 30.689583],
            [120.895119, 31.011978],
            [121.207253, 31.47485],
            [121.325974, 31.505417],
            [121.666809, 31.328194],
            [121.862358, 31.11347],
            [121.890419, 30.847918],
            [121.526802, 30.821529],
            [121.2649, 30.689583],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.24_1",
        NAME_1: "Shanghai",
        VARNAME_1: "Shànghǎi",
        NL_NAME_1: "上海|上海",
        TYPE_1: "Zhíxiáshì",
        ENGTYPE_1: "Municipality",
        CC_1: "",
        HASC_1: "CN.SH",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [110.380234, 34.604488],
            [110.270561, 34.968773],
            [110.601044, 35.605801],
            [110.444641, 36.16486],
            [110.500084, 36.581142],
            [110.408333, 36.994373],
            [110.785767, 37.551132],
            [110.49894, 38.021873],
            [110.505722, 38.192474],
            [110.768753, 38.434734],
            [111.207245, 39.232056],
            [111.133766, 39.367733],
            [111.48661, 39.658478],
            [111.927124, 39.619183],
            [111.970085, 39.796257],
            [112.302727, 40.251465],
            [112.721825, 40.167042],
            [112.892044, 40.3283],
            [113.2491, 40.410275],
            [113.553078, 40.347542],
            [114.055099, 40.52739],
            [114.127861, 40.73576],
            [114.307922, 40.368305],
            [114.057838, 40.163113],
            [114.051682, 39.928169],
            [114.386375, 39.867168],
            [114.545372, 39.547451],
            [114.357864, 39.103451],
            [114.044205, 39.134827],
            [113.760887, 38.958836],
            [113.518791, 38.382465],
            [113.877113, 38.053986],
            [114.1119, 37.589737],
            [113.751709, 37.075169],
            [113.786469, 36.894398],
            [113.492821, 36.74033],
            [113.724342, 36.360001],
            [113.490547, 35.530548],
            [112.769073, 35.208904],
            [112.071861, 35.22913],
            [111.567627, 34.849541],
            [111.150177, 34.808304],
            [110.682404, 34.594139],
            [110.380234, 34.604488],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.25_1",
        NAME_1: "Shanxi",
        VARNAME_1: "Shānxī",
        NL_NAME_1: "山西",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.SX",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [106.373581, 28.530485],
            [106.103439, 28.634981],
            [105.63369, 28.524948],
            [105.973793, 28.116268],
            [106.317276, 27.976343],
            [106.186783, 27.758265],
            [105.620857, 27.667246],
            [105.300247, 27.709112],
            [105.175957, 28.068842],
            [104.561378, 27.852774],
            [104.310707, 28.035183],
            [104.456146, 28.174364],
            [104.253342, 28.397078],
            [104.26107, 28.64563],
            [103.87841, 28.625391],
            [103.864098, 28.306711],
            [103.563652, 28.228449],
            [103.502441, 27.840231],
            [103.13662, 27.425537],
            [102.88176, 27.303371],
            [102.867393, 27.032141],
            [102.979553, 26.79908],
            [102.989281, 26.364401],
            [102.400536, 26.306],
            [102.129906, 26.09491],
            [101.816795, 26.090187],
            [101.639503, 26.388838],
            [101.394959, 26.559118],
            [101.366577, 26.887445],
            [101.139648, 27.033499],
            [100.829651, 27.684649],
            [100.448006, 27.872025],
            [100.325462, 27.721991],
            [100.027054, 28.174341],
            [99.956444, 28.571981],
            [99.621864, 28.812735],
            [99.519066, 28.58551],
            [99.192223, 28.496876],
            [99.108978, 28.856487],
            [99.112312, 29.25112],
            [98.993912, 29.672953],
            [99.071129, 29.931366],
            [98.988098, 30.152859],
            [98.96225, 30.733437],
            [98.602341, 31.238855],
            [98.881332, 31.369423],
            [98.565819, 31.648495],
            [98.212364, 32.345001],
            [97.729965, 32.529659],
            [97.487526, 32.653938],
            [97.362961, 32.950981],
            [97.61969, 33.337727],
            [97.388329, 33.888744],
            [97.660103, 33.96233],
            [98.411163, 33.872028],
            [98.662704, 33.652294],
            [98.852394, 33.159336],
            [99.742401, 32.746674],
            [99.768662, 32.952419],
            [100.933083, 32.604904],
            [101.216904, 32.716076],
            [101.164749, 33.161854],
            [101.734337, 33.278027],
            [101.774231, 33.548042],
            [101.938866, 33.445759],
            [101.840828, 33.184826],
            [102.206024, 33.234425],
            [102.439133, 33.581356],
            [102.193352, 33.918644],
            [102.734848, 34.268593],
            [102.92524, 34.309345],
            [103.167442, 34.093655],
            [103.150146, 33.811813],
            [103.766243, 33.660385],
            [104.04966, 33.686806],
            [104.264328, 33.393478],
            [104.380806, 32.996159],
            [104.300194, 32.831444],
            [104.643738, 32.661419],
            [105.141594, 32.598896],
            [105.449127, 32.736294],
            [105.495354, 32.91074],
            [105.618591, 32.699814],
            [105.955116, 32.847637],
            [106.430122, 32.619446],
            [106.728409, 32.731453],
            [107.054932, 32.711998],
            [107.259354, 32.40551],
            [107.669182, 32.393536],
            [107.846054, 32.20866],
            [108.514549, 32.212044],
            [108.286552, 31.929058],
            [108.534233, 31.677107],
            [108.230232, 31.513105],
            [107.869003, 30.808849],
            [107.510681, 30.844881],
            [107.481644, 30.606314],
            [107.028023, 30.03611],
            [106.725014, 30.037191],
            [106.560379, 30.306889],
            [106.202103, 30.21261],
            [105.790787, 30.423487],
            [105.558777, 30.184237],
            [105.724586, 29.856422],
            [105.286743, 29.545477],
            [105.449142, 29.29394],
            [105.696213, 29.272169],
            [105.799912, 28.941227],
            [105.988113, 28.980568],
            [106.247749, 28.799732],
            [106.373581, 28.530485],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.26_1",
        NAME_1: "Sichuan",
        VARNAME_1: "Sìchuān",
        NL_NAME_1: "四川",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.SC",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [116.799385, 39.610008],
            [116.894295, 39.696308],
            [117.15242, 39.626743],
            [117.193985, 40.082783],
            [117.379738, 40.226871],
            [117.640846, 40.09824],
            [117.513191, 39.885006],
            [117.693596, 39.580555],
            [118.010475, 39.361912],
            [118.012688, 39.218193],
            [117.823471, 39.170139],
            [117.564308, 38.655693],
            [117.269318, 38.569546],
            [116.750755, 38.740154],
            [116.901459, 39.064217],
            [116.799385, 39.610008],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.27_1",
        NAME_1: "Tianjin",
        VARNAME_1: "Tiānjīn",
        NL_NAME_1: "天津|天津",
        TYPE_1: "Zhíxiáshì",
        ENGTYPE_1: "Municipality",
        CC_1: "",
        HASC_1: "CN.TJ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [92.950562, 39.171055],
            [92.500031, 39.109432],
            [91.296257, 38.753319],
            [90.630623, 38.675484],
            [90.14225, 38.40213],
            [90.480614, 37.958195],
            [90.522377, 37.73019],
            [91.090782, 37.463398],
            [91.304131, 37.018635],
            [90.847298, 36.919029],
            [90.730186, 36.591377],
            [91.027786, 36.537014],
            [91.098862, 36.094807],
            [89.988831, 36.155739],
            [89.731247, 36.084744],
            [89.234177, 36.295219],
            [88.765656, 36.301781],
            [88.468002, 36.482487],
            [87.551468, 36.342541],
            [86.743629, 36.292454],
            [86.195747, 36.129486],
            [86.052307, 35.846107],
            [85.612045, 35.656296],
            [85.266304, 35.780487],
            [84.445625, 35.543003],
            [84.093803, 35.36454],
            [83.539001, 35.342594],
            [82.996239, 35.485298],
            [82.964722, 35.668182],
            [82.405899, 35.699997],
            [81.851219, 35.259785],
            [81.4459, 35.354332],
            [80.687126, 35.33997],
            [80.521103, 35.455967],
            [80.0504, 35.060013],
            [79.799149, 34.480713],
            [78.927437, 34.371635],
            [78.569336, 34.61195],
            [78.284363, 34.667362],
            [77.990288, 35.379032],
            [77.81575, 35.51786],
            [77.427277, 35.46904],
            [76.763771, 35.669647],
            [76.590515, 35.893116],
            [76.221825, 35.850933],
            [75.933067, 36.125309],
            [76.053017, 36.23732],
            [75.918869, 36.62048],
            [75.454498, 36.721615],
            [75.372353, 36.943619],
            [74.433098, 37.115761],
            [74.50798, 37.239189],
            [74.892906, 37.221943],
            [75.133827, 37.437439],
            [74.928017, 37.55624],
            [74.995079, 37.762791],
            [74.785797, 38.185539],
            [74.867256, 38.498417],
            [74.382217, 38.65303],
            [73.806709, 38.633499],
            [73.651535, 39.453953],
            [73.941368, 39.602909],
            [73.842293, 39.838753],
            [74.023911, 40.098305],
            [74.340439, 40.083851],
            [74.812004, 40.453865],
            [75.193939, 40.438751],
            [75.593109, 40.658714],
            [75.722404, 40.288818],
            [76.521324, 40.462624],
            [76.633987, 40.757339],
            [76.864044, 41.020943],
            [77.669754, 41.001057],
            [78.190033, 41.079029],
            [78.405037, 41.41349],
            [79.452019, 41.849201],
            [79.670494, 41.790096],
            [79.881523, 42.03801],
            [80.280937, 42.072792],
            [80.167328, 42.636589],
            [80.409706, 43.057289],
            [80.799446, 43.175419],
            [80.745956, 43.448502],
            [80.519737, 43.834209],
            [80.342827, 44.481297],
            [80.489738, 44.71143],
            [80.246674, 44.839584],
            [80.402779, 45.05283],
            [81.452766, 45.2687],
            [81.827843, 45.199989],
            [82.240822, 45.235359],
            [82.486206, 45.12067],
            [82.590141, 45.445621],
            [82.275719, 45.545399],
            [82.50444, 45.983379],
            [83.030876, 47.21212],
            [84.038216, 46.965351],
            [84.704735, 46.996235],
            [84.826683, 46.832348],
            [85.219414, 47.051399],
            [85.529724, 47.059399],
            [85.698303, 47.397781],
            [85.541595, 47.939438],
            [85.735451, 48.371002],
            [86.584747, 48.541519],
            [86.81044, 48.85033],
            [86.855202, 49.107521],
            [87.438103, 49.084671],
            [88.073326, 48.691681],
            [88.004257, 48.56599],
            [88.601074, 48.344116],
            [88.67424, 48.174561],
            [89.193039, 47.950161],
            [89.565918, 48.048706],
            [89.796417, 47.81815],
            [90.323456, 47.666992],
            [90.517845, 47.244156],
            [90.881157, 46.976364],
            [91.077637, 46.563965],
            [90.934769, 46.205639],
            [91.011498, 46.000534],
            [90.699852, 45.690681],
            [90.663696, 45.516907],
            [90.938293, 45.182442],
            [91.652023, 45.056709],
            [93.50827, 44.965343],
            [94.197418, 44.662502],
            [94.334007, 44.52306],
            [94.990509, 44.254551],
            [95.42366, 44.289268],
            [95.881104, 43.4021],
            [95.916451, 43.233505],
            [96.324448, 42.882431],
            [96.365166, 42.717075],
            [96.096642, 42.599667],
            [95.98967, 41.894283],
            [95.046371, 41.771229],
            [94.67984, 41.622211],
            [94.077011, 41.158325],
            [93.646751, 40.61911],
            [93.353943, 40.472462],
            [92.913811, 40.498299],
            [92.917992, 40.103733],
            [92.76416, 39.879799],
            [92.94313, 39.458271],
            [92.950562, 39.171055],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.28_1",
        NAME_1: "Xinjiang Uygur",
        VARNAME_1: "Xīnjiāng Wéiwúěr",
        NL_NAME_1: "新疆維吾爾自治區|新疆维吾尔自治区",
        TYPE_1: "Zìzhìqu",
        ENGTYPE_1: "Autonomous Region",
        CC_1: "",
        HASC_1: "CN.XJ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [98.143211, 28.048653],
            [97.717979, 28.50386],
            [97.460861, 28.268101],
            [96.996498, 28.316231],
            [96.400772, 28.56237],
            [96.585938, 28.721588],
            [96.521217, 28.96521],
            [96.150391, 29.053141],
            [96.234169, 29.24346],
            [96.092819, 29.45643],
            [95.545029, 29.21559],
            [95.454338, 29.036991],
            [94.871803, 29.18429],
            [94.628159, 29.348591],
            [94.214691, 29.084089],
            [93.84404, 28.70739],
            [93.332306, 28.63368],
            [93.193756, 28.318041],
            [92.783028, 28.183741],
            [92.560692, 27.821041],
            [92.255157, 27.862169],
            [91.878647, 27.72184],
            [91.467331, 28.005781],
            [91.208481, 27.988226],
            [90.68882, 28.077557],
            [90.381935, 28.06144],
            [89.860268, 28.230169],
            [89.613876, 28.170416],
            [89.033951, 27.579596],
            [88.767067, 27.56496],
            [88.880188, 27.895241],
            [88.751083, 28.073313],
            [88.388802, 27.97784],
            [87.581314, 27.861755],
            [87.128838, 27.83392],
            [87.041618, 27.950115],
            [86.578804, 28.112665],
            [86.444832, 27.9055],
            [85.898033, 28.066933],
            [85.664314, 28.304716],
            [85.110382, 28.346796],
            [84.951073, 28.580038],
            [84.493988, 28.734653],
            [84.222603, 28.9156],
            [84.166031, 29.19397],
            [83.964813, 29.331316],
            [83.635506, 29.158428],
            [83.198822, 29.611118],
            [82.764816, 29.731115],
            [82.69873, 29.85523],
            [82.172546, 30.067627],
            [82.000053, 30.320765],
            [81.635818, 30.445116],
            [81.134369, 30.016541],
            [81.010979, 30.262791],
            [80.21096, 30.5823],
            [80.15937, 30.81209],
            [79.74559, 31.006151],
            [79.584816, 30.943991],
            [79.299622, 31.14924],
            [79.056778, 31.47226],
            [78.72126, 31.540951],
            [78.737068, 32.004082],
            [78.464317, 32.277779],
            [78.632339, 32.611061],
            [78.970963, 32.34428],
            [79.202957, 32.51083],
            [79.545357, 32.607361],
            [79.365036, 32.956921],
            [79.409836, 33.178772],
            [79.141441, 33.2001],
            [78.709297, 33.679611],
            [78.729233, 34.095699],
            [78.927437, 34.371635],
            [79.799149, 34.480713],
            [80.0504, 35.060013],
            [80.521103, 35.455967],
            [80.687126, 35.33997],
            [81.4459, 35.354332],
            [81.851219, 35.259785],
            [82.405899, 35.699997],
            [82.964722, 35.668182],
            [82.996239, 35.485298],
            [83.539001, 35.342594],
            [84.093803, 35.36454],
            [84.445625, 35.543003],
            [85.266304, 35.780487],
            [85.612045, 35.656296],
            [86.052307, 35.846107],
            [86.195747, 36.129486],
            [86.743629, 36.292454],
            [87.551468, 36.342541],
            [88.468002, 36.482487],
            [88.765656, 36.301781],
            [89.234177, 36.295219],
            [89.731247, 36.084744],
            [89.785599, 35.861809],
            [89.688042, 35.421749],
            [89.497055, 35.365894],
            [89.591995, 34.894688],
            [89.814171, 34.540432],
            [89.739548, 33.917171],
            [90.264809, 33.36758],
            [90.70668, 33.139141],
            [91.378151, 33.23875],
            [91.967201, 32.820004],
            [93.246407, 32.662952],
            [93.515419, 32.480309],
            [93.654045, 32.574978],
            [94.13578, 32.435955],
            [94.622375, 32.670227],
            [95.080849, 32.250435],
            [95.433388, 32.160007],
            [95.371979, 31.964407],
            [95.521004, 31.751423],
            [96.060516, 31.72341],
            [96.250084, 31.932867],
            [96.736137, 31.67774],
            [96.736305, 31.983194],
            [97.301926, 32.082607],
            [97.397713, 32.520443],
            [97.729965, 32.529659],
            [98.212364, 32.345001],
            [98.565819, 31.648495],
            [98.881332, 31.369423],
            [98.602341, 31.238855],
            [98.96225, 30.733437],
            [98.988098, 30.152859],
            [99.071129, 29.931366],
            [98.993912, 29.672953],
            [99.112312, 29.25112],
            [98.920929, 28.978325],
            [98.655914, 28.977781],
            [98.628037, 28.493793],
            [98.712135, 28.239544],
            [98.143211, 28.048653],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.29_1",
        NAME_1: "Xizang",
        VARNAME_1: "Tibet|Xīzàng",
        NL_NAME_1: "西藏自治區|西藏自治区",
        TYPE_1: "Zìzhìqu",
        ENGTYPE_1: "Autonomous Region",
        CC_1: "",
        HASC_1: "CN.XZ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [98.143211, 28.048653],
            [98.712135, 28.239544],
            [98.628037, 28.493793],
            [98.655914, 28.977781],
            [98.920929, 28.978325],
            [99.112312, 29.25112],
            [99.108978, 28.856487],
            [99.192223, 28.496876],
            [99.519066, 28.58551],
            [99.621864, 28.812735],
            [99.956444, 28.571981],
            [100.027054, 28.174341],
            [100.325462, 27.721991],
            [100.448006, 27.872025],
            [100.829651, 27.684649],
            [101.139648, 27.033499],
            [101.366577, 26.887445],
            [101.394959, 26.559118],
            [101.639503, 26.388838],
            [101.816795, 26.090187],
            [102.129906, 26.09491],
            [102.400536, 26.306],
            [102.989281, 26.364401],
            [102.979553, 26.79908],
            [102.867393, 27.032141],
            [102.88176, 27.303371],
            [103.13662, 27.425537],
            [103.502441, 27.840231],
            [103.563652, 28.228449],
            [103.864098, 28.306711],
            [103.87841, 28.625391],
            [104.26107, 28.64563],
            [104.253342, 28.397078],
            [104.456146, 28.174364],
            [104.310707, 28.035183],
            [104.561378, 27.852774],
            [105.175957, 28.068842],
            [105.300247, 27.709112],
            [105.232712, 27.431635],
            [104.8638, 27.293619],
            [104.340111, 27.461428],
            [104.178993, 27.273363],
            [103.927177, 27.441984],
            [103.634811, 27.119492],
            [103.811325, 26.539188],
            [104.417824, 26.711546],
            [104.67794, 26.376707],
            [104.538147, 26.252668],
            [104.307976, 25.65877],
            [104.752113, 25.217247],
            [104.530022, 24.734367],
            [104.609138, 24.380915],
            [105.039909, 24.444689],
            [105.492531, 24.028812],
            [105.99865, 24.132044],
            [106.18795, 23.838175],
            [106.059418, 23.490929],
            [105.810516, 23.504873],
            [105.541382, 23.193666],
            [105.212326, 23.267252],
            [104.801163, 23.115057],
            [104.838371, 22.92222],
            [104.352448, 22.692413],
            [104.114952, 22.811686],
            [103.961411, 22.506477],
            [103.647263, 22.794525],
            [103.409218, 22.778502],
            [103.030815, 22.442457],
            [102.688477, 22.704491],
            [102.466263, 22.769526],
            [102.251678, 22.463634],
            [101.863609, 22.39016],
            [101.695297, 22.47427],
            [101.582001, 22.10231],
            [101.781456, 21.832491],
            [101.826706, 21.60298],
            [101.740303, 21.314831],
            [101.238037, 21.250731],
            [101.213623, 21.56126],
            [101.018021, 21.712151],
            [100.577789, 21.450251],
            [100.202583, 21.43935],
            [99.986153, 21.71866],
            [100, 21.977118],
            [99.652611, 22.104872],
            [99.218941, 22.11875],
            [99.389519, 22.49942],
            [99.325462, 22.75107],
            [99.511269, 23.083738],
            [99.109818, 23.09371],
            [98.89743, 23.219681],
            [98.92305, 23.423113],
            [98.69281, 23.920351],
            [98.846466, 24.134291],
            [98.230927, 24.116899],
            [97.885262, 24.01022],
            [97.557579, 24.495993],
            [97.575897, 24.76333],
            [97.845032, 25.267893],
            [98.369156, 25.573],
            [98.475769, 25.797703],
            [98.712662, 25.898083],
            [98.572861, 26.119082],
            [98.781059, 26.619881],
            [98.686852, 27.21373],
            [98.692917, 27.58967],
            [98.467583, 27.690163],
            [98.318817, 27.54277],
            [98.143211, 28.048653],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.30_1",
        NAME_1: "Yunnan",
        VARNAME_1: "Yúnnán",
        NL_NAME_1: "雲南|云南",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.YN",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [118.424271, 28.292282],
            [118.466103, 28.475739],
            [118.360336, 28.811964],
            [118.096474, 28.99362],
            [118.034599, 29.210161],
            [118.189453, 29.395222],
            [118.472031, 29.506912],
            [118.891502, 29.951298],
            [118.879913, 30.320143],
            [119.209274, 30.295321],
            [119.570267, 30.838839],
            [119.632111, 31.139343],
            [119.908951, 31.175247],
            [120.150826, 30.936314],
            [120.361671, 30.951988],
            [120.507065, 30.767185],
            [120.895119, 31.011978],
            [121.2649, 30.689583],
            [121.008186, 30.571251],
            [120.907913, 30.37236],
            [121.350693, 30.325142],
            [121.685974, 29.985971],
            [121.914017, 29.922918],
            [121.829865, 29.544584],
            [121.950417, 29.51236],
            [121.935417, 29.194311],
            [121.634026, 29.093748],
            [121.70903, 28.934584],
            [121.510139, 28.664862],
            [121.358467, 28.13905],
            [121.133194, 28.24625],
            [120.683746, 27.698475],
            [120.681526, 27.472361],
            [120.434593, 27.171734],
            [120.243645, 27.434687],
            [119.871284, 27.305782],
            [119.700478, 27.51911],
            [119.239708, 27.42469],
            [118.868881, 27.537115],
            [118.803703, 28.229174],
            [118.424271, 28.292282],
          ],
        ],
      },
      properties: {
        GID_0: "CHN",
        NAME_0: "China",
        GID_1: "CHN.31_1",
        NAME_1: "Zhejiang",
        VARNAME_1: "Zhèjiāng",
        NL_NAME_1: "浙江",
        TYPE_1: "Shěng",
        ENGTYPE_1: "Province",
        CC_1: "",
        HASC_1: "CN.ZJ",
      },
    },
  ],
  style: {
    stroke: "#000000",
    fill: "#3498DB",
    "stroke-width": 1,
    "fill-opacity": 0.8,
  },
};
