// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 480px;
}
.loaderContainer > svg {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/UI/Loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,wBAAwB;EACxB,qCAAqC;AACvC;;AAEA;EACE,UAAU;EACV,0BAA0B;EAC1B,eAAe;EACf,wBAAwB;EACxB,oBAAoB;EACpB,qBAAqB;EACrB,0CAA0C;AAC5C;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,wBAAwB;IACxB,oBAAoB;EACtB;;EAEA;IACE,yBAAyB;IACzB,wBAAwB;EAC1B;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loaderContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  min-height: 480px;\n}\n.loaderContainer > svg {\n  width: 3.25em;\n  transform-origin: center;\n  animation: rotate4 2s linear infinite;\n}\n\ncircle {\n  fill: none;\n  stroke: hsl(214, 97%, 59%);\n  stroke-width: 2;\n  stroke-dasharray: 1, 200;\n  stroke-dashoffset: 0;\n  stroke-linecap: round;\n  animation: dash4 1.5s ease-in-out infinite;\n}\n\n@keyframes rotate4 {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes dash4 {\n  0% {\n    stroke-dasharray: 1, 200;\n    stroke-dashoffset: 0;\n  }\n\n  50% {\n    stroke-dasharray: 90, 200;\n    stroke-dashoffset: -35px;\n  }\n\n  100% {\n    stroke-dashoffset: -125px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
