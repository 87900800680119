import React, { forwardRef, useImperativeHandle, useRef } from "react";
import MapChart from "./MapChart";

export const MapChartWrapper = forwardRef((props, ref) => {
  const mapChartRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getMapChartImage: async () => {
      return await mapChartRef.current.getChartImage();
    },
  }));

  return (
    <div className="chart-item mb-4 border">
      <div className="d-flex justify-content-center">
        <p className="mb-3 mt-2 report-header">{props.title}</p>
      </div>
      <MapChart ref={mapChartRef} props={props} />
    </div>
  );
});
