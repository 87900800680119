/**
 * Adds the China section to the PDF document.
 * @param {Object} doc - PDF document instance
 * @param {Object} chinaReport - China report data
 * @returns {Promise<void>}
 */
export const addChinaSection = async (doc, chinaReport) => {
  const margin = 20;
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  const contentWidth = pageWidth - margin * 2;
  const lineSpacing = 8;
  const sectionSpacing = 10;
  const fontSize = 11;
  const titleFontSize = 16;

  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!chinaReport) {
      console.warn("China report data is missing");
      return;
    }

    const { week_number, year, summary, report_url } = chinaReport;

    // Start with a new page
    doc.addPage();

    // Title Section
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("China", margin, 30);

    // Metadata
    doc.setFontSize(14);
    doc.text(`Report for Week ${week_number}, ${year}`, margin, 45);
    doc.setFontSize(10);
    doc.setTextColor(0, 102, 204);
    doc.textWithLink("View Full Report Online", margin, 55, {
      url: report_url,
    });

    let currentY = 70;

    // Function to handle text wrapping and pagination
    const addTextWithPagination = (text, y) => {
      const lines = doc.splitTextToSize(text, contentWidth);
      lines.forEach((line) => {
        if (y > pageHeight - margin) {
          doc.addPage();
          y = margin;
        }
        doc.text(line, margin, y);
        y += lineSpacing;
      });
      return y;
    };

    // Detection Information
    if (summary.detection_info) {
      doc.setFontSize(titleFontSize);
      doc.setTextColor(41, 128, 185);
      doc.text("Detection Information", margin, currentY);
      currentY += sectionSpacing;

      doc.setFontSize(fontSize);
      doc.setTextColor(0, 0, 0);
      currentY = addTextWithPagination(
        summary.detection_info.raw_text,
        currentY
      );
    }

    // Outbreak Information
    if (summary.outbreak_info) {
      doc.setFontSize(titleFontSize);
      doc.setTextColor(41, 128, 185);
      doc.text("Outbreak Information", margin, currentY);
      currentY += sectionSpacing;

      doc.setFontSize(fontSize);
      doc.setTextColor(0, 0, 0);
      currentY = addTextWithPagination(
        `Number of outbreaks reported: ${summary.outbreak_info.count}`,
        currentY
      );
    }

    // Characterization Information
    if (summary.characterization_info) {
      doc.setFontSize(titleFontSize);
      doc.setTextColor(41, 128, 185);
      doc.text("Characterization Information", margin, currentY);
      currentY += sectionSpacing;

      doc.setFontSize(fontSize);
      doc.setTextColor(0, 0, 0);
      currentY = addTextWithPagination(
        summary.characterization_info.raw_text,
        currentY
      );
    }

    // Resistance Information
    if (summary.resistance_info) {
      doc.setFontSize(titleFontSize);
      doc.setTextColor(41, 128, 185);
      doc.text("Resistance Information", margin, currentY);
      currentY += sectionSpacing;

      doc.setFontSize(fontSize);
      doc.setTextColor(0, 0, 0);
      currentY = addTextWithPagination(
        summary.resistance_info.raw_text,
        currentY
      );
    }

    // Add Images
    if (chinaReport.images && chinaReport.images.length > 0) {
      for (const image of chinaReport.images) {
        const imageData = `data:image/png;base64,${image.image_data}`;
        const imgWidth = contentWidth;
        const imgHeight = (image.height / image.width) * imgWidth;

        if (currentY + imgHeight + sectionSpacing > pageHeight - margin) {
          doc.addPage();
          currentY = margin;
        }

        doc.addImage(imageData, "PNG", margin, currentY, imgWidth, imgHeight);
        currentY += imgHeight + sectionSpacing;
      }
    }

    // Add Comment Section on a New Page
    doc.addPage();
    currentY = margin;

    // Comments Label
    doc.setFontSize(titleFontSize);
    doc.setTextColor(41, 128, 185);
    doc.text("Comments:", margin, currentY);
    currentY += sectionSpacing;

    // Calculate Comment Box Height (1/3rd of total page height)
    const commentBoxHeight = (pageHeight - margin * 2) / 3;

    // Draw Comment Box with Gray Border
    doc.setDrawColor(150, 150, 150); // Gray border
    doc.setLineWidth(0.1); // Thin border
    doc.rect(margin, currentY, contentWidth, commentBoxHeight);

    // Source Citation
    doc.setFontSize(9);
    doc.setTextColor(128, 128, 128);
    doc.text("Source: China CDC", margin, pageHeight - 15);
  } catch (error) {
    console.error("Error generating China PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating China report section. Please try again later.",
      margin,
      40
    );
  }
};
