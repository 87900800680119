import { useRef } from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoute from "../component/routes/PrivateRoute";
import Error from "../component/Error";

import Whoo from "../component/pages/Whoo";
import Russia from "../component/pages/Russia";
import Spain from "../component/pages/Spain";
import Italy from "../component/pages/Italy";
import China from "../component/pages/China";
import PDF from "../component/pages/PDF";
import Chatwithdb from "../component/chatandreport/Chatwithdb";
import Register from "../component/register_login/Register";
import Login from "../component/register_login/Login";
import Resetpassword from "../component/register_login/Resetpassword";
import Newpassword from "../component/register_login/Newpassword";
import Brazil from "../component/pages/Brazil";
import Indonesia from "../component/pages/Indonesia";
import GlobalNews from "../component/pages/GlobalNews";
import Profile from "../component/Profile";
import France from "../component/pages/France";

export const AllRoutes = ({ isLoggedIn }) => {
  const whoRef = useRef(null);
  const russiaRef = useRef(null);
  const spainRef = useRef(null);
  const italyRef = useRef(null);
  const chinaRef = useRef(null);

  return (
    <div className={isLoggedIn ? "tab-content" : ""}>
      <Routes>
        <Route
          path="/who"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Whoo ref={whoRef} key={"whoo-component"} />
            </PrivateRoute>
          }
        />
        <Route
          path="/russia"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Russia ref={russiaRef} key={"russia-component"} />
            </PrivateRoute>
          }
        />
        <Route
          path="/spain"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Spain ref={spainRef} key={"spain-component"} />
            </PrivateRoute>
          }
        />
        <Route
          path="/italy"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Italy ref={italyRef} key={"italy-component"} />
            </PrivateRoute>
          }
        />
        <Route
          path="/china"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <China ref={chinaRef} key={"china-component"} />
            </PrivateRoute>
          }
        />
        <Route
          path="/pdf"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <PDF key={"pdf-component"} />
            </PrivateRoute>
          }
        />
        <Route
          path="/chatwithdb"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Chatwithdb />
            </PrivateRoute>
          }
        />
        <Route
          path="/brazil"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Brazil />
            </PrivateRoute>
          }
        />
        <Route
          path="/indonesia"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Indonesia />
            </PrivateRoute>
          }
        />
        <Route
          path="/france"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <France />
            </PrivateRoute>
          }
        />
        <Route
          path="/global-news"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <GlobalNews />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Login />} />
        <Route path="/resertpassword" element={<Resetpassword />} />
        <Route path="/newpassword" element={<Newpassword />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
};
