import { format } from "date-fns";

// Constants for PDF styling
const STYLES = {
  MARGINS: {
    LEFT: 20,
    RIGHT: 20,
    TOP: 20,
    BOTTOM: 20,
  },
  COLORS: {
    PRIMARY: [41, 128, 185], // Blue for headers
    SECONDARY: [52, 73, 94], // Dark gray for metadata
    TEXT: [0, 0, 0], // Black for body text
    BORDER: [150, 150, 150], // Light gray for comment box
  },
  FONTS: {
    HEADER: "helvetica",
    BODY: "helvetica",
  },
  FONT_SIZES: {
    TITLE: 18,
    HEADER: 14,
    SUBHEADER: 12,
    BODY: 11,
    FOOTER: 9,
  },
  LINE_HEIGHTS: {
    BODY: 6,
    HEADER: 10,
  },
};

const addSectionTitle = (doc, title, currentY, maxWidth) => {
  if (
    currentY + STYLES.LINE_HEIGHTS.HEADER >=
    doc.internal.pageSize.height - STYLES.MARGINS.BOTTOM
  ) {
    doc.addPage();
    currentY = STYLES.MARGINS.TOP;
  }
  doc.setFont(STYLES.FONTS.HEADER, "bold");
  doc.setFontSize(STYLES.FONT_SIZES.HEADER);
  doc.setTextColor(...STYLES.COLORS.PRIMARY);
  doc.text(title, STYLES.MARGINS.LEFT, currentY, { maxWidth });
  return currentY + STYLES.LINE_HEIGHTS.HEADER;
};

const addWrappedText = (doc, text, currentY, maxWidth) => {
  if (!text) return currentY;
  doc.setFont(STYLES.FONTS.BODY, "normal");
  doc.setFontSize(STYLES.FONT_SIZES.BODY);
  doc.setTextColor(...STYLES.COLORS.TEXT);

  const lines = doc.splitTextToSize(text, maxWidth);
  for (const line of lines) {
    if (
      currentY + STYLES.LINE_HEIGHTS.BODY >=
      doc.internal.pageSize.height - STYLES.MARGINS.BOTTOM
    ) {
      doc.addPage();
      currentY = STYLES.MARGINS.TOP;
    }
    doc.text(line, STYLES.MARGINS.LEFT, currentY);
    currentY += STYLES.LINE_HEIGHTS.BODY;
  }
  return currentY + STYLES.LINE_HEIGHTS.BODY;
};

export const addWhoSection = async (doc, whoReport) => {
  if (!whoReport) return;

  let currentY = STYLES.MARGINS.TOP;
  const pageWidth = doc.internal.pageSize.width;
  const maxWidth = pageWidth - STYLES.MARGINS.LEFT - STYLES.MARGINS.RIGHT;

  currentY = addSectionTitle(doc, "WHO", currentY, maxWidth);

  // Add report metadata
  const reportDate = new Date(whoReport.report_date);
  const metadataText = `Report #${whoReport.report_number} | Date: ${format(
    reportDate,
    "MMMM d, yyyy"
  )} | Week ${whoReport.week_number}, ${whoReport.year}`;
  doc.setFont(STYLES.FONTS.BODY, "normal");
  doc.setFontSize(STYLES.FONT_SIZES.BODY);
  doc.setTextColor(...STYLES.COLORS.SECONDARY);
  currentY = addWrappedText(doc, metadataText, currentY, maxWidth);

  // Sections
  if (whoReport.summary?.summary_text) {
    currentY = addSectionTitle(doc, "Summary", currentY, maxWidth);
    currentY = addWrappedText(
      doc,
      whoReport.summary.summary_text,
      currentY,
      maxWidth
    );
  }

  if (whoReport.summary?.influenza_info) {
    currentY = addSectionTitle(doc, "Influenza Activity", currentY, maxWidth);
    if (whoReport.summary.influenza_info.northern_hemisphere) {
      currentY = addWrappedText(
        doc,
        `Northern Hemisphere: ${whoReport.summary.influenza_info.northern_hemisphere}`,
        currentY,
        maxWidth
      );
    }
    if (whoReport.summary.influenza_info.southern_hemisphere) {
      currentY = addWrappedText(
        doc,
        `Southern Hemisphere: ${whoReport.summary.influenza_info.southern_hemisphere}`,
        currentY,
        maxWidth
      );
    }
  }

  if (whoReport.summary?.sars_cov2_info?.activity_info) {
    currentY = addSectionTitle(doc, "SARS-CoV-2 Activity", currentY, maxWidth);
    currentY = addWrappedText(
      doc,
      whoReport.summary.sars_cov2_info.activity_info,
      currentY,
      maxWidth
    );
  }

  if (whoReport.summary?.raw_text) {
    currentY = addSectionTitle(
      doc,
      "Additional Information",
      currentY,
      maxWidth
    );
    currentY = addWrappedText(
      doc,
      whoReport.summary.raw_text,
      currentY,
      maxWidth
    );
  }

  // Add Images
  if (whoReport.images?.length) {
    for (const image of whoReport.images) {
      if (
        currentY + 100 >=
        doc.internal.pageSize.height - STYLES.MARGINS.BOTTOM
      ) {
        doc.addPage();
        currentY = STYLES.MARGINS.TOP;
      }
      try {
        const imgWidth = maxWidth;
        const imgHeight = (imgWidth * image.height) / image.width;
        doc.addImage(
          `data:image/${image.content_type};base64,${image.image_data}`,
          image.content_type.toUpperCase(),
          STYLES.MARGINS.LEFT,
          currentY,
          imgWidth,
          imgHeight
        );
        currentY += imgHeight + STYLES.LINE_HEIGHTS.HEADER;
      } catch (error) {
        console.error("Error adding image to PDF:", error);
      }
    }
  }

  // Add Comment Section on a New Page
  doc.addPage();
  currentY = STYLES.MARGINS.TOP;

  // Comments Label
  doc.setFontSize(STYLES.FONT_SIZES.HEADER);
  doc.setTextColor(...STYLES.COLORS.PRIMARY);
  doc.text("Comments:", STYLES.MARGINS.LEFT, currentY);
  currentY += STYLES.LINE_HEIGHTS.HEADER;

  // Calculate Comment Box Height (1/3rd of total page height)
  const commentBoxHeight =
    (doc.internal.pageSize.height -
      STYLES.MARGINS.TOP -
      STYLES.MARGINS.BOTTOM) /
    3;

  // Draw Comment Box with Gray Border
  doc.setLineWidth(0.1); // Thin border
  doc.setDrawColor(...STYLES.COLORS.BORDER); // Gray border
  doc.rect(STYLES.MARGINS.LEFT, currentY, maxWidth, commentBoxHeight);
};
