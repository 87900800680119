import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  report: null,
  loading: true,
  error: null,
};

const chinaSlice = createSlice({
  name: "china",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChinaReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChinaReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
      })
      .addCase(fetchChinaReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const fetchChinaReport = createAsyncThunk(
  "china/fetchChinaReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/dashboard/china-who-summary`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch China report");
      }
      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.toString());
    }
  }
);

export default chinaSlice.reducer;
