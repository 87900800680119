import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchItalyReport } from "../../redux/slices/italy.slice";
import { Loader } from "../UI/Loader/Loader";
import { ErrorBoundary } from "./Whoo";

const Italy = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.italy);

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      return {
        influenzaAgMapChart: null,
      };
    },
  }));

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const renderSummaryPoints = (data) => {
    if (!data?.summary_points || !Array.isArray(data.summary_points)) {
      return null;
    }

    return (
      <div className="mb-4">
        <h5 className="text-primary mb-3">Weekly Summary</h5>
        <ul className="list-unstyled">
          {data.summary_points.map((point, index) => (
            <li key={index} className="mb-3 d-flex align-items-start">
              <i className="fa fa-circle-check text-success mt-1 me-2"></i>
              <span>{point}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // const renderVirologyData = (data) => {
  //   if (!data?.virology_data) {
  //     return null;
  //   }

  //   return (
  //     <div className="mb-4">
  //       <h5 className="text-primary mb-3">Virology Data</h5>
  //       <div className="card bg-light">
  //         <div className="card-body">
  //           <div className="row">
  //             <div className="col-md-6">
  //               <h6 className="mb-3">Week {data.week_id}</h6>
  //               <p className="mb-2">
  //                 <strong>Date Range:</strong> {data.date_range?.start} -{" "}
  //                 {data.date_range?.end}
  //               </p>
  //               <p className="mb-2">
  //                 <strong>Year:</strong> {data.year}
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderItalyReport = () => {
    if (!report?.data) return <div>No report data available</div>;

    const reportData = report.data;

    return (
      <div className="report-container">
        <div className="alert alert-info mb-4">
          <i className="fa fa-info-circle me-2"></i>
          Last updated: {formatDate(reportData.created_at)}
        </div>

        {renderSummaryPoints(reportData)}
        {/* {renderVirologyData(reportData)} */}

        {/* <div className="mt-4">
          <p className="text-muted">
            <i className="fa fa-link me-2"></i>
            Source: {reportData.source_url}
          </p>
        </div> */}
      </div>
    );
  };

  useEffect(() => {
    if (!report) {
      dispatch(fetchItalyReport());
    }
  }, [dispatch, report]);

  if (loading) return <Loader />;
  if (error) return <div className="alert alert-danger">Error: {error}</div>;
  if (!report)
    return (
      <div className="alert alert-warning">No report available for Italy.</div>
    );

  return (
    <div className="italy-report">
      <h2 className="country-name mb-4">Italy</h2>
      <ErrorBoundary>
        <div className="card">
          <div className="card-body">{renderItalyReport()}</div>
        </div>
      </ErrorBoundary>
    </div>
  );
});

export default Italy;
