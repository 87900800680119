import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchItalyReport } from "../../redux/slices/italy.slice";
import { Loader } from "../UI/Loader/Loader";
import { ErrorBoundary } from "./Whoo";
import { Button } from "react-bootstrap";

export const renderGoToSourceButton = (url) => {
  return (
    <Button
      style={{ marginRight: "1rem" }}
      onClick={() => {
        window.open(url, "_blank");
      }}
    >
      <i className="fa fa-link me-2"></i>
      Go to source
    </Button>
  );
};

const Italy = () => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.italy);

  const renderReportWeekYear = () => {
    if (!report?.week_id || !report?.year) return null;

    const week = parseInt(report.week_id.slice(5), 10);
    return (
      <div className="alert alert-info mb-4">
        <i className="fa fa-info-circle me-2"></i>
        Report for Year {report.year}, Week {week}
      </div>
    );
  };

  const renderSummaryPoints = (data) => {
    if (!data?.summary_points || !Array.isArray(data.summary_points)) {
      return null;
    }

    return (
      <div className="mb-4">
        <h5 className="text-primary mb-3">Weekly Summary</h5>
        <ul className="list-unstyled">
          {data.summary_points.map((point, index) => (
            <li key={index} className="mb-3 d-flex align-items-start">
              <i className="fa fa-circle-check text-success mt-1 me-2"></i>
              <span>{point}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderItalyReport = () => {
    if (!report) return <div>No report data available</div>;

    return (
      <div className="report-container">
        {renderReportWeekYear()}
        {renderSummaryPoints(report)}
      </div>
    );
  };

  useEffect(() => {
    if (!report) {
      dispatch(fetchItalyReport());
    }
  }, [dispatch, report]);

  if (loading) return <Loader />;
  if (error) return <div className="alert alert-danger">Error: {error}</div>;
  if (!report)
    return (
      <div className="alert alert-warning">No report available for Italy.</div>
    );

  return (
    <div className="container-fluid">
      <div className="italy-report">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="country-name mb-4">Italy</h2>
          {renderGoToSourceButton(
            "https://respivirnet.iss.it/pagine/rapportoInflunet.aspx"
          )}
        </div>
        <ErrorBoundary>
          <div className="card">
            <div className="card-body">
              {renderItalyReport()}

              <div className="mt-4 p-4">
                <p className="d-flex align-items-center text-muted">
                  <i className="fa fa-link me-2"></i>
                  <span>
                    Source:{" "}
                    <a href={report?.source_url}>{report?.source_url}</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Italy;
