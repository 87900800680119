import React, { useRef, useImperativeHandle, forwardRef } from "react";
import { Chart } from "react-google-charts";
import html2canvas from "html2canvas";

const MapChart = forwardRef(({props}, ref) => {
  const chartRef = useRef(null);

  const options = {
    colorAxis: { colors: props.color.split(" ") },
    datalessRegionColor: "#a9a5a5",
    animation: {
      duration: 1000,
      easing: "out",
    },
    magnifyingGlass: { enable: true, zoomFactor: 7.5 },
  };

  const data = [
    ["Country", "Cases"],
    ["Germany", 200],
    ["United States", 300],
    ["Brazil", 400],
    ["Canada", 500],
    ["France", 600],
    ["RU", 700],
  ];

  useImperativeHandle(ref, () => ({
    getChartImage: async () => {
      if (!chartRef.current) return null;

      const chartNode = chartRef.current;
      const canvas = await html2canvas(chartNode, {
        useCORS: true,
        logging: true,
      });

      return canvas.toDataURL("image/jpeg", 0.9);
    },
  }));

  return (
    <div ref={chartRef}>
      <Chart
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              if (selection.length === 0) return;
              const region = data[selection[0].row + 1];
            },
          },
        ]}
        chartType="GeoChart"
        width="100%"
        height="100%"
        data={data}
        options={options}
      />
    </div>
  );
});

export default MapChart;
