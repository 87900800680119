import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchGlobalNews = createAsyncThunk(
  "globalNews/fetchGlobalNews",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/global-news`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalNewsSlice = createSlice({
  name: "globalNews",
  initialState: {
    news: {},
    loading: false,
    error: null,
    lastUpdated: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGlobalNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload;
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(fetchGlobalNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch news";
      });
  },
});

export const { clearError } = globalNewsSlice.actions;
export default globalNewsSlice.reducer;
