/**
 * Adds Spain section to the PDF document with proper margin control and formatting
 * @param {Object} doc - PDF document instance
 * @param {Object} reportSpain - Spain report data
 * @param {Object} spainRef - Reference to Spain component
 * @returns {Promise<void>}
 */
export const addSpainSection = async (doc, reportSpain, spainRef) => {
  const margin = 20;
  const lineHeight = 6;
  const sectionSpacing = 10;
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  const contentWidth = pageWidth - margin * 2;

  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!reportSpain || reportSpain.status !== "success") {
      console.warn("Spain report data is missing or invalid");
      return;
    }

    doc.addPage();

    // Title
    let yPosition = margin + 10;
    doc.setFontSize(22);
    doc.setTextColor(41, 128, 185);
    doc.text("Spain", margin, yPosition);

    yPosition += 15;
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    const reportTimestamp = new Date(reportSpain.timestamp).toLocaleDateString(
      "en-GB",
      {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
    );
    doc.text(`Report generated on: ${reportTimestamp}`, margin, yPosition);

    yPosition += 20;
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text("Summary", margin, yPosition);

    yPosition += 10;
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);

    const summaryData = reportSpain.data.summary;
    if (summaryData && summaryData.length > 0) {
      summaryData.forEach((section) => {
        section.paragraphs.forEach((paragraph) => {
          const lines = doc.splitTextToSize(paragraph, contentWidth);
          const textHeight = lines.length * lineHeight;

          if (yPosition + textHeight > pageHeight - margin - 40) {
            // Ensure space for comments section
            doc.addPage();
            yPosition = margin;
          }

          doc.text(lines, margin, yPosition);
          yPosition += textHeight + sectionSpacing;
        });
      });
    } else {
      doc.text("No summary data available.", margin, yPosition);
      yPosition += 10;
    }

    // Add graphs if available
    if (reportSpain.data.graphs) {
      doc.addPage();
      yPosition = margin + 10;
      const chartWidth = contentWidth * 0.8;
      const chartX = (pageWidth - chartWidth) / 2;

      for (const [name, imageData] of Object.entries(reportSpain.data.graphs)) {
        if (imageData) {
          try {
            const img = new Image();
            img.src = `data:image/png;base64,${imageData}`;

            await new Promise((resolve) => {
              img.onload = () => {
                const aspectRatio = img.width / img.height;
                const chartHeight = chartWidth / aspectRatio;

                if (yPosition + chartHeight > pageHeight - margin - 40) {
                  // Ensure space for comments section
                  doc.addPage();
                  yPosition = margin + 10;
                }

                doc.addImage(
                  imageData,
                  "PNG",
                  chartX,
                  yPosition,
                  chartWidth,
                  chartHeight
                );
                yPosition += chartHeight + 20;
                resolve();
              };
            });
          } catch (error) {
            console.error(`Error adding chart ${name} to PDF:`, error);
          }
        }
      }
    }

    // Add Comments Section on a New Page
    doc.addPage();
    let commentsYPosition = margin;

    // "Comments" label
    doc.setFontSize(12);
    doc.setTextColor(41, 128, 185);
    doc.text("Comments:", margin, commentsYPosition);
    commentsYPosition += 10;

    // Calculate Comment Box Height (1/3rd of total page height)
    const commentBoxHeight = (pageHeight - margin * 2) / 3;

    // Draw an empty rectangle for the comments section with a gray thin border
    doc.setDrawColor(150, 150, 150); // Light gray border
    doc.setLineWidth(0.1); // Thin border
    doc.rect(margin, commentsYPosition, contentWidth, commentBoxHeight);
  } catch (error) {
    console.error("Error generating Spain PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating Spain report section. Please try again later.",
      margin,
      40
    );
  }
};
