export const france_topology = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "5393",
      properties: {
        name: "Auvergne-Rhône-Alpes",
        density: 0,
        path: "/world/France/Auvergne-Rhône-Alpes",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.361348, 44.971408],
            [3.354532, 44.95482],
            [3.337942, 44.955907],
            [3.297306, 44.935605],
            [3.286018, 44.926382],
            [3.271337, 44.9276],
            [3.261522, 44.932068],
            [3.25954, 44.942429],
            [3.245168, 44.934803],
            [3.249689, 44.916235],
            [3.227814, 44.909928],
            [3.226984, 44.897602],
            [3.232343, 44.891622],
            [3.225294, 44.882045],
            [3.215962, 44.875041],
            [3.19918, 44.874642],
            [3.182317, 44.863735],
            [3.169021, 44.874091],
            [3.153173, 44.893017],
            [3.141098, 44.903315],
            [3.12838, 44.903578],
            [3.122913, 44.892733],
            [3.105495, 44.886775],
            [3.105037, 44.866395],
            [3.095325, 44.852084],
            [3.100121, 44.844374],
            [3.099481, 44.833585],
            [3.071475, 44.834125],
            [3.076859, 44.82441],
            [3.061522, 44.817172],
            [3.045749, 44.799052],
            [3.050392, 44.779809],
            [3.042243, 44.759381],
            [3.031073, 44.749414],
            [3.028417, 44.732557],
            [3.035354, 44.727207],
            [3.039124, 44.714996],
            [3.025141, 44.716097],
            [3.016012, 44.713692],
            [2.998574, 44.674443],
            [2.98176, 44.651995],
            [2.981676, 44.644673],
            [2.972997, 44.645719],
            [2.963758, 44.654317],
            [2.948727, 44.672978],
            [2.939287, 44.677756],
            [2.940897, 44.683819],
            [2.933873, 44.697402],
            [2.93509, 44.705005],
            [2.923267, 44.728643],
            [2.932201, 44.738183],
            [2.933526, 44.750102],
            [2.928456, 44.755161],
            [2.91725, 44.756714],
            [2.920801, 44.76573],
            [2.934822, 44.766338],
            [2.934518, 44.779202],
            [2.92019, 44.794309],
            [2.904073, 44.783703],
            [2.889706, 44.788417],
            [2.879575, 44.803006],
            [2.870508, 44.827398],
            [2.855965, 44.85518],
            [2.859674, 44.874465],
            [2.849652, 44.87149],
            [2.822033, 44.87157],
            [2.801958, 44.873626],
            [2.772707, 44.855546],
            [2.768411, 44.863859],
            [2.770491, 44.875538],
            [2.778338, 44.887718],
            [2.771859, 44.902271],
            [2.776088, 44.908964],
            [2.77354, 44.915091],
            [2.760393, 44.92751],
            [2.738258, 44.941219],
            [2.731007, 44.936748],
            [2.724021, 44.924576],
            [2.713593, 44.92713],
            [2.706434, 44.907222],
            [2.693891, 44.902938],
            [2.681298, 44.907393],
            [2.658106, 44.882979],
            [2.656227, 44.872416],
            [2.649065, 44.869728],
            [2.636608, 44.872551],
            [2.62393, 44.866918],
            [2.618053, 44.854604],
            [2.602682, 44.843163],
            [2.611739, 44.831312],
            [2.607471, 44.824097],
            [2.597136, 44.819511],
            [2.601388, 44.807574],
            [2.599361, 44.792806],
            [2.586565, 44.78399],
            [2.573548, 44.784963],
            [2.562713, 44.775757],
            [2.558589, 44.757925],
            [2.551639, 44.75072],
            [2.554491, 44.739644],
            [2.551941, 44.732805],
            [2.556123, 44.721284],
            [2.518459, 44.703481],
            [2.518886, 44.699028],
            [2.498915, 44.687021],
            [2.487578, 44.666984],
            [2.490134, 44.656773],
            [2.483187, 44.650343],
            [2.468999, 44.649642],
            [2.468039, 44.642888],
            [2.452164, 44.648221],
            [2.435001, 44.638875],
            [2.405522, 44.64718],
            [2.397446, 44.646186],
            [2.378223, 44.650162],
            [2.365998, 44.641342],
            [2.348539, 44.64148],
            [2.332779, 44.650618],
            [2.336558, 44.661276],
            [2.326791, 44.669693],
            [2.304587, 44.662449],
            [2.291037, 44.666582],
            [2.27093, 44.660401],
            [2.264931, 44.660758],
            [2.250894, 44.651888],
            [2.228625, 44.655107],
            [2.208415, 44.643842],
            [2.214542, 44.627081],
            [2.219871, 44.623646],
            [2.207473, 44.615529],
            [2.190806, 44.628252],
            [2.169416, 44.63807],
            [2.16876, 44.647387],
            [2.174044, 44.653645],
            [2.165566, 44.661375],
            [2.179152, 44.674446],
            [2.163032, 44.690215],
            [2.155296, 44.700239],
            [2.138664, 44.692881],
            [2.130132, 44.698486],
            [2.133685, 44.709565],
            [2.147968, 44.723036],
            [2.152224, 44.736723],
            [2.153496, 44.753104],
            [2.1494, 44.769791],
            [2.166702, 44.772652],
            [2.171637, 44.790027],
            [2.165293, 44.79956],
            [2.165821, 44.812904],
            [2.139803, 44.823821],
            [2.122777, 44.847633],
            [2.11657, 44.850184],
            [2.09421, 44.872012],
            [2.083736, 44.887424],
            [2.08649, 44.90108],
            [2.108105, 44.910639],
            [2.089228, 44.92856],
            [2.078624, 44.932494],
            [2.07632, 44.940562],
            [2.080694, 44.953286],
            [2.062908, 44.976505],
            [2.076617, 44.978786],
            [2.090574, 44.984665],
            [2.100371, 44.983427],
            [2.132982, 44.985516],
            [2.138151, 44.992812],
            [2.140641, 45.005858],
            [2.116807, 45.021114],
            [2.10941, 45.041522],
            [2.099318, 45.047623],
            [2.095157, 45.056033],
            [2.103746, 45.065812],
            [2.118365, 45.070379],
            [2.129819, 45.078932],
            [2.145654, 45.085592],
            [2.171759, 45.081497],
            [2.180362, 45.097341],
            [2.179439, 45.109177],
            [2.185269, 45.113998],
            [2.178791, 45.136298],
            [2.194837, 45.136028],
            [2.211565, 45.148448],
            [2.213701, 45.16019],
            [2.225398, 45.160337],
            [2.233586, 45.167182],
            [2.219587, 45.172115],
            [2.201188, 45.181494],
            [2.198548, 45.194409],
            [2.190513, 45.202134],
            [2.195364, 45.220851],
            [2.224929, 45.241855],
            [2.241341, 45.249426],
            [2.238775, 45.260354],
            [2.245005, 45.267107],
            [2.258568, 45.27022],
            [2.261735, 45.283221],
            [2.271411, 45.290155],
            [2.28008, 45.287326],
            [2.29207, 45.29057],
            [2.307351, 45.31266],
            [2.31774, 45.322964],
            [2.350481, 45.327561],
            [2.359379, 45.33579],
            [2.351346, 45.348611],
            [2.364779, 45.35792],
            [2.36297, 45.378412],
            [2.368273, 45.388621],
            [2.364446, 45.395235],
            [2.354553, 45.401399],
            [2.350084, 45.409676],
            [2.355131, 45.415028],
            [2.37825, 45.414302],
            [2.395487, 45.407322],
            [2.398288, 45.40017],
            [2.412905, 45.396651],
            [2.422674, 45.39723],
            [2.441866, 45.384259],
            [2.458081, 45.384827],
            [2.473456, 45.381526],
            [2.477832, 45.370411],
            [2.485768, 45.378947],
            [2.522732, 45.382085],
            [2.526235, 45.389344],
            [2.522034, 45.402238],
            [2.515971, 45.403237],
            [2.492758, 45.413842],
            [2.487472, 45.418842],
            [2.495595, 45.445504],
            [2.50677, 45.464065],
            [2.508413, 45.478502],
            [2.513891, 45.492348],
            [2.508991, 45.500226],
            [2.51152, 45.511153],
            [2.520381, 45.520451],
            [2.516521, 45.524033],
            [2.516327, 45.553428],
            [2.506468, 45.553444],
            [2.490929, 45.560394],
            [2.487431, 45.569384],
            [2.472537, 45.58093],
            [2.464625, 45.594411],
            [2.465345, 45.60082],
            [2.478434, 45.607912],
            [2.479082, 45.622462],
            [2.483388, 45.639304],
            [2.489745, 45.642354],
            [2.50115, 45.638739],
            [2.514298, 45.639528],
            [2.524938, 45.657234],
            [2.51513, 45.664284],
            [2.512923, 45.671334],
            [2.52836, 45.681924],
            [2.52687, 45.694919],
            [2.519874, 45.697053],
            [2.518322, 45.7128],
            [2.492129, 45.73767],
            [2.484641, 45.748521],
            [2.475153, 45.747689],
            [2.454791, 45.761151],
            [2.441426, 45.762379],
            [2.434112, 45.769853],
            [2.43298, 45.783889],
            [2.427404, 45.794285],
            [2.418599, 45.798475],
            [2.400677, 45.81729],
            [2.388014, 45.827373],
            [2.401345, 45.837577],
            [2.426536, 45.834771],
            [2.436437, 45.846999],
            [2.447828, 45.845819],
            [2.442583, 45.863008],
            [2.470614, 45.872335],
            [2.479738, 45.864541],
            [2.492228, 45.86403],
            [2.509961, 45.886666],
            [2.526209, 45.896727],
            [2.537691, 45.900846],
            [2.555006, 45.912509],
            [2.541506, 45.921532],
            [2.550954, 45.935762],
            [2.551638, 45.941261],
            [2.565717, 45.946857],
            [2.565178, 45.95662],
            [2.589188, 45.960438],
            [2.610785, 45.971228],
            [2.60747, 45.979109],
            [2.59442, 45.989441],
            [2.602506, 46.033462],
            [2.571699, 46.04869],
            [2.563183, 46.06461],
            [2.557084, 46.0693],
            [2.551872, 46.083457],
            [2.551822, 46.103971],
            [2.549049, 46.114075],
            [2.558236, 46.130702],
            [2.558622, 46.138326],
            [2.565373, 46.143036],
            [2.565482, 46.153967],
            [2.560764, 46.155596],
            [2.5598, 46.173367],
            [2.543167, 46.175603],
            [2.528629, 46.184895],
            [2.528019, 46.195467],
            [2.521695, 46.203035],
            [2.521527, 46.211334],
            [2.515428, 46.228012],
            [2.516309, 46.239437],
            [2.489106, 46.248969],
            [2.477097, 46.269362],
            [2.478945, 46.281146],
            [2.443026, 46.294996],
            [2.430951, 46.29152],
            [2.421336, 46.284622],
            [2.416102, 46.300056],
            [2.420466, 46.31013],
            [2.411318, 46.314094],
            [2.391858, 46.329985],
            [2.384064, 46.328909],
            [2.370483, 46.312629],
            [2.354906, 46.325678],
            [2.334582, 46.32532],
            [2.323023, 46.329277],
            [2.309379, 46.342006],
            [2.302746, 46.35442],
            [2.313389, 46.356936],
            [2.323338, 46.366525],
            [2.337115, 46.366796],
            [2.33159, 46.378177],
            [2.315472, 46.375206],
            [2.285044, 46.385858],
            [2.280403, 46.410236],
            [2.281044, 46.420404],
            [2.28576, 46.453515],
            [2.305469, 46.475429],
            [2.316671, 46.468549],
            [2.324288, 46.470979],
            [2.329822, 46.479617],
            [2.324179, 46.490368],
            [2.352004, 46.512207],
            [2.3683, 46.518434],
            [2.388465, 46.518246],
            [2.413164, 46.520302],
            [2.426687, 46.526122],
            [2.450281, 46.521936],
            [2.468571, 46.52601],
            [2.482928, 46.532695],
            [2.496943, 46.533666],
            [2.499123, 46.521292],
            [2.512426, 46.523881],
            [2.520139, 46.530864],
            [2.527531, 46.52903],
            [2.536655, 46.519703],
            [2.554028, 46.529388],
            [2.572492, 46.53385],
            [2.583505, 46.542742],
            [2.614961, 46.553276],
            [2.606219, 46.565758],
            [2.609378, 46.571329],
            [2.604519, 46.579002],
            [2.60232, 46.595164],
            [2.58156, 46.593164],
            [2.577952, 46.603788],
            [2.569101, 46.609519],
            [2.585963, 46.614297],
            [2.585277, 46.622739],
            [2.596648, 46.637215],
            [2.58958, 46.648027],
            [2.56739, 46.652347],
            [2.572189, 46.659445],
            [2.584975, 46.664166],
            [2.605048, 46.668158],
            [2.609598, 46.66284],
            [2.624641, 46.657301],
            [2.630861, 46.672294],
            [2.621553, 46.678653],
            [2.623404, 46.688703],
            [2.631358, 46.690887],
            [2.647886, 46.688908],
            [2.654729, 46.696537],
            [2.677793, 46.704612],
            [2.688165, 46.72091],
            [2.700921, 46.72096],
            [2.70497, 46.73939],
            [2.728721, 46.748309],
            [2.73729, 46.74315],
            [2.74357, 46.730083],
            [2.756744, 46.724772],
            [2.758521, 46.717749],
            [2.774489, 46.718903],
            [2.787291, 46.728614],
            [2.801072, 46.733722],
            [2.827618, 46.735286],
            [2.844812, 46.726844],
            [2.84542, 46.742856],
            [2.861233, 46.75403],
            [2.877016, 46.761469],
            [2.876169, 46.768446],
            [2.909805, 46.779348],
            [2.908127, 46.787904],
            [2.924375, 46.794523],
            [2.937816, 46.795714],
            [2.952959, 46.790999],
            [2.959919, 46.803872],
            [2.977802, 46.803773],
            [2.98954, 46.799298],
            [3.003355, 46.798123],
            [3.017518, 46.799901],
            [3.032063, 46.794909],
            [3.036839, 46.784399],
            [3.036145, 46.776351],
            [3.049067, 46.758081],
            [3.061078, 46.74985],
            [3.083934, 46.737632],
            [3.12978, 46.727202],
            [3.163152, 46.693541],
            [3.19726, 46.679928],
            [3.215545, 46.682893],
            [3.233036, 46.697018],
            [3.255164, 46.706289],
            [3.269796, 46.716742],
            [3.298833, 46.71359],
            [3.313965, 46.688752],
            [3.346704, 46.684418],
            [3.366445, 46.691259],
            [3.375427, 46.699411],
            [3.378731, 46.711348],
            [3.387758, 46.714818],
            [3.407411, 46.711511],
            [3.43414, 46.711908],
            [3.429567, 46.702505],
            [3.432978, 46.69334],
            [3.451312, 46.689901],
            [3.453596, 46.681988],
            [3.447339, 46.663554],
            [3.457289, 46.65176],
            [3.486535, 46.653809],
            [3.487153, 46.658256],
            [3.517493, 46.683185],
            [3.530036, 46.688813],
            [3.546473, 46.678293],
            [3.554396, 46.684732],
            [3.555559, 46.695741],
            [3.548208, 46.706167],
            [3.550387, 46.715861],
            [3.577314, 46.714853],
            [3.598001, 46.723983],
            [3.597188, 46.728402],
            [3.584552, 46.739383],
            [3.580671, 46.752741],
            [3.591053, 46.762426],
            [3.597749, 46.762029],
            [3.60204, 46.751247],
            [3.620107, 46.75406],
            [3.629422, 46.749456],
            [3.62259, 46.740131],
            [3.635334, 46.728515],
            [3.638139, 46.722724],
            [3.637895, 46.707205],
            [3.651393, 46.702822],
            [3.655472, 46.687748],
            [3.6801, 46.668521],
            [3.696958, 46.660583],
            [3.69968, 46.651867],
            [3.712152, 46.633632],
            [3.722709, 46.62761],
            [3.723025, 46.622073],
            [3.713874, 46.613995],
            [3.717647, 46.605806],
            [3.732378, 46.604907],
            [3.743289, 46.567565],
            [3.740655, 46.559049],
            [3.731541, 46.549578],
            [3.741844, 46.539508],
            [3.754663, 46.53602],
            [3.765601, 46.537908],
            [3.787896, 46.528043],
            [3.801756, 46.519902],
            [3.811344, 46.520138],
            [3.817676, 46.525229],
            [3.834003, 46.531135],
            [3.846463, 46.524356],
            [3.839756, 46.517562],
            [3.849471, 46.513024],
            [3.860025, 46.515222],
            [3.864627, 46.509717],
            [3.860387, 46.495601],
            [3.864913, 46.489736],
            [3.890467, 46.481246],
            [3.896986, 46.481472],
            [3.901353, 46.490636],
            [3.91897, 46.496061],
            [3.937576, 46.490762],
            [3.949613, 46.492479],
            [3.957938, 46.489754],
            [3.952087, 46.481423],
            [3.956593, 46.476882],
            [3.973056, 46.477457],
            [3.99804, 46.465464],
            [4.001542, 46.458618],
            [3.997444, 46.450265],
            [4.00557, 46.443531],
            [3.988142, 46.435462],
            [3.995615, 46.428694],
            [3.984448, 46.416663],
            [3.988697, 46.408772],
            [3.977221, 46.39922],
            [3.984262, 46.378286],
            [3.991604, 46.36963],
            [3.988664, 46.360561],
            [3.984741, 46.32973],
            [3.997405, 46.323291],
            [3.986627, 46.319196],
            [3.948129, 46.319792],
            [3.947719, 46.303445],
            [3.942782, 46.298926],
            [3.930098, 46.29582],
            [3.913571, 46.296681],
            [3.897421, 46.291461],
            [3.891239, 46.285246],
            [3.899539, 46.275908],
            [3.905513, 46.271597],
            [3.908822, 46.260347],
            [3.907658, 46.242456],
            [3.896611, 46.237119],
            [3.898192, 46.226659],
            [3.890131, 46.214487],
            [3.913569, 46.206919],
            [3.917989, 46.202853],
            [3.933515, 46.206414],
            [3.972547, 46.202708],
            [3.972833, 46.193863],
            [3.981782, 46.176341],
            [3.988788, 46.169805],
            [4.030884, 46.171883],
            [4.05196, 46.18169],
            [4.061405, 46.18879],
            [4.071667, 46.188071],
            [4.090884, 46.192837],
            [4.104087, 46.198391],
            [4.132416, 46.177826],
            [4.166089, 46.172928],
            [4.188444, 46.175129],
            [4.185455, 46.190162],
            [4.207903, 46.194833],
            [4.224687, 46.177995],
            [4.233529, 46.180027],
            [4.242358, 46.18883],
            [4.2525, 46.187922],
            [4.261025, 46.178754],
            [4.251899, 46.167098],
            [4.25203, 46.157917],
            [4.282494, 46.156946],
            [4.295283, 46.17225],
            [4.303581, 46.174267],
            [4.315742, 46.17203],
            [4.327089, 46.18479],
            [4.335712, 46.181398],
            [4.345068, 46.187442],
            [4.364848, 46.183579],
            [4.370687, 46.19162],
            [4.363178, 46.200537],
            [4.377474, 46.210202],
            [4.389398, 46.213601],
            [4.388074, 46.21979],
            [4.388291, 46.247956],
            [4.392047, 46.263027],
            [4.405814, 46.296058],
            [4.421872, 46.294954],
            [4.427234, 46.302731],
            [4.439721, 46.293185],
            [4.45837, 46.296977],
            [4.47657, 46.284238],
            [4.488466, 46.287994],
            [4.503994, 46.267132],
            [4.537264, 46.269911],
            [4.546448, 46.273918],
            [4.548076, 46.282865],
            [4.557796, 46.294552],
            [4.569849, 46.293352],
            [4.572669, 46.277139],
            [4.586507, 46.268695],
            [4.618558, 46.264794],
            [4.622024, 46.270079],
            [4.618322, 46.28244],
            [4.631452, 46.289015],
            [4.635261, 46.29929],
            [4.654659, 46.303485],
            [4.669382, 46.29791],
            [4.679434, 46.303994],
            [4.69311, 46.302197],
            [4.694516, 46.292673],
            [4.707541, 46.28466],
            [4.707687, 46.269648],
            [4.687881, 46.265312],
            [4.67955, 46.258667],
            [4.688362, 46.250114],
            [4.702829, 46.25133],
            [4.735767, 46.234245],
            [4.732661, 46.22702],
            [4.720624, 46.222391],
            [4.735693, 46.211924],
            [4.722665, 46.20232],
            [4.724371, 46.184305],
            [4.730526, 46.178369],
            [4.75976, 46.172841],
            [4.760805, 46.175969],
            [4.780208, 46.176676],
            [4.780243, 46.189052],
            [4.79339, 46.2047],
            [4.794586, 46.218312],
            [4.807751, 46.236972],
            [4.811555, 46.249933],
            [4.810998, 46.259923],
            [4.825952, 46.274786],
            [4.833214, 46.300145],
            [4.853421, 46.329901],
            [4.851454, 46.356271],
            [4.858529, 46.368018],
            [4.873839, 46.384668],
            [4.88821, 46.402977],
            [4.891815, 46.439918],
            [4.899296, 46.450123],
            [4.911117, 46.457733],
            [4.915781, 46.465413],
            [4.915551, 46.488939],
            [4.925719, 46.497442],
            [4.931421, 46.509211],
            [4.940022, 46.517199],
            [4.949102, 46.501381],
            [4.964599, 46.513176],
            [4.98355, 46.515393],
            [5.011008, 46.510295],
            [5.014179, 46.500588],
            [5.052372, 46.484874],
            [5.070006, 46.485667],
            [5.098939, 46.497167],
            [5.107372, 46.491919],
            [5.115205, 46.494069],
            [5.14195, 46.508357],
            [5.16645, 46.505591],
            [5.166792, 46.514675],
            [5.181597, 46.509759],
            [5.20114, 46.508211],
            [5.206635, 46.486398],
            [5.213043, 46.481262],
            [5.215063, 46.468359],
            [5.225548, 46.468274],
            [5.235029, 46.457949],
            [5.246832, 46.459404],
            [5.254594, 46.454118],
            [5.275925, 46.448121],
            [5.310563, 46.44677],
            [5.319488, 46.430803],
            [5.308979, 46.424532],
            [5.307687, 46.416819],
            [5.31477, 46.409476],
            [5.331272, 46.399499],
            [5.352508, 46.397586],
            [5.355222, 46.393755],
            [5.377043, 46.381355],
            [5.362997, 46.370927],
            [5.377054, 46.364113],
            [5.373465, 46.352234],
            [5.381917, 46.345256],
            [5.399276, 46.339521],
            [5.404293, 46.332813],
            [5.404651, 46.310338],
            [5.410234, 46.309107],
            [5.417086, 46.339534],
            [5.427898, 46.342211],
            [5.437795, 46.315109],
            [5.466918, 46.323267],
            [5.475301, 46.315383],
            [5.467404, 46.295566],
            [5.459513, 46.290472],
            [5.456843, 46.274474],
            [5.473052, 46.265067],
            [5.499589, 46.2682],
            [5.512943, 46.26454],
            [5.542034, 46.270204],
            [5.558127, 46.282174],
            [5.566364, 46.29405],
            [5.585501, 46.292558],
            [5.598389, 46.298446],
            [5.610461, 46.324211],
            [5.617644, 46.329092],
            [5.630515, 46.330205],
            [5.641958, 46.342028],
            [5.649345, 46.339495],
            [5.652905, 46.323215],
            [5.668442, 46.324369],
            [5.683347, 46.316277],
            [5.684577, 46.310928],
            [5.714722, 46.308772],
            [5.719294, 46.293462],
            [5.716049, 46.279916],
            [5.720112, 46.26587],
            [5.725182, 46.260732],
            [5.745918, 46.266369],
            [5.765647, 46.268295],
            [5.821284, 46.262094],
            [5.849848, 46.262067],
            [5.864333, 46.271209],
            [5.870249, 46.265306],
            [5.879121, 46.269936],
            [5.894625, 46.286608],
            [5.908936, 46.283951],
            [5.918518, 46.3072],
            [5.925904, 46.313766],
            [5.941409, 46.309447],
            [5.95356, 46.326531],
            [5.972917, 46.346856],
            [5.986846, 46.364869],
            [6.010657, 46.374509],
            [6.029514, 46.386816],
            [6.048075, 46.405125],
            [6.055182, 46.414801],
            [6.064006, 46.416223],
            [6.098198, 46.40879],
            [6.103688, 46.401582],
            [6.118595, 46.396899],
            [6.136111, 46.387666],
            [6.147585, 46.379107],
            [6.161361, 46.375594],
            [6.169736, 46.367935],
            [6.159309, 46.357523],
            [6.148758, 46.342475],
            [6.139081, 46.339163],
            [6.13789, 46.333067],
            [6.119587, 46.308278],
            [6.119374, 46.294909],
            [6.102644, 46.285063],
            [6.103953, 46.27957],
            [6.115112, 46.266273],
            [6.120137, 46.264754],
            [6.124246, 46.251016],
            [6.10925, 46.239762],
            [6.101759, 46.237489],
            [6.088187, 46.246811],
            [6.069679, 46.241028],
            [6.06344, 46.245647],
            [6.045095, 46.231704],
            [6.033126, 46.237994],
            [6.017064, 46.231762],
            [6.00729, 46.22537],
            [5.971781, 46.211519],
            [5.963678, 46.196964],
            [5.992163, 46.186617],
            [5.995292, 46.182994],
            [5.983253, 46.171487],
            [5.956067, 46.13209],
            [5.97384, 46.131739],
            [5.985317, 46.143309],
            [6.015464, 46.142634],
            [6.032542, 46.138442],
            [6.045523, 46.139908],
            [6.052041, 46.151403],
            [6.074931, 46.14889],
            [6.092462, 46.151763],
            [6.099275, 46.144012],
            [6.121201, 46.142667],
            [6.126621, 46.14046],
            [6.144919, 46.144833],
            [6.152115, 46.151724],
            [6.175073, 46.158153],
            [6.188204, 46.168463],
            [6.185972, 46.178178],
            [6.214247, 46.193787],
            [6.232074, 46.20547],
            [6.251778, 46.207041],
            [6.277374, 46.215612],
            [6.295651, 46.226055],
            [6.310111, 46.2437],
            [6.30854, 46.255106],
            [6.284617, 46.255154],
            [6.26763, 46.24784],
            [6.248922, 46.263081],
            [6.237799, 46.277337],
            [6.241629, 46.284547],
            [6.251076, 46.287644],
            [6.247933, 46.306039],
            [6.257924, 46.321778],
            [6.279914, 46.351093],
            [6.294184, 46.360851],
            [6.3152, 46.368778],
            [6.326998, 46.371129],
            [6.344681, 46.370015],
            [6.362284, 46.347951],
            [6.390033, 46.340163],
            [6.398336, 46.349292],
            [6.411588, 46.358395],
            [6.428583, 46.359525],
            [6.469064, 46.372615],
            [6.480391, 46.379314],
            [6.482851, 46.391927],
            [6.492397, 46.398425],
            [6.50904, 46.404032],
            [6.520579, 46.404193],
            [6.545176, 46.394725],
            [6.56356, 46.398366],
            [6.635058, 46.405772],
            [6.657399, 46.404992],
            [6.692686, 46.408082],
            [6.722865, 46.40755],
            [6.757505, 46.402602],
            [6.760254, 46.400377],
            [6.795671, 46.393089],
            [6.804829, 46.393585],
            [6.806216, 46.380373],
            [6.793958, 46.368623],
            [6.772599, 46.361732],
            [6.770609, 46.354894],
            [6.782641, 46.336879],
            [6.798121, 46.330138],
            [6.79993, 46.320469],
            [6.806644, 46.320655],
            [6.825606, 46.310849],
            [6.837308, 46.296687],
            [6.849897, 46.290148],
            [6.858905, 46.2906],
            [6.864511, 46.282986],
            [6.853898, 46.253761],
            [6.843413, 46.248726],
            [6.833746, 46.237036],
            [6.821677, 46.227692],
            [6.803663, 46.204343],
            [6.811228, 46.18503],
            [6.804202, 46.172106],
            [6.79198, 46.162225],
            [6.790394, 46.154718],
            [6.798533, 46.136103],
            [6.81473, 46.129696],
            [6.835508, 46.13244],
            [6.851787, 46.126417],
            [6.889762, 46.124848],
            [6.898384, 46.122562],
            [6.892715, 46.104317],
            [6.883844, 46.095797],
            [6.891451, 46.084495],
            [6.890069, 46.076513],
            [6.879895, 46.067794],
            [6.872633, 46.052045],
            [6.888327, 46.043121],
            [6.90853, 46.050234],
            [6.924511, 46.065195],
            [6.937369, 46.064563],
            [6.935798, 46.055119],
            [6.95151, 46.049953],
            [6.962908, 46.030532],
            [6.978608, 46.021145],
            [6.984677, 46.006542],
            [6.994725, 46.000742],
            [7.003632, 46.000554],
            [7.018252, 45.984185],
            [7.018242, 45.975568],
            [7.009409, 45.96976],
            [7.017975, 45.960347],
            [7.037615, 45.954301],
            [7.037102, 45.941929],
            [7.043891, 45.922087],
            [7.021811, 45.916452],
            [7.003148, 45.897828],
            [7.003319, 45.882409],
            [6.991725, 45.868199],
            [6.971695, 45.867044],
            [6.951036, 45.859535],
            [6.939609, 45.846733],
            [6.923084, 45.847274],
            [6.909212, 45.842522],
            [6.895099, 45.8426],
            [6.882197, 45.84952],
            [6.873611, 45.845497],
            [6.869844, 45.825643],
            [6.853839, 45.837147],
            [6.837965, 45.839496],
            [6.818078, 45.834974],
            [6.814256, 45.826836],
            [6.803985, 45.815846],
            [6.812722, 45.808019],
            [6.811583, 45.798399],
            [6.803984, 45.789308],
            [6.802515, 45.778372],
            [6.80562, 45.748466],
            [6.816346, 45.739523],
            [6.811185, 45.73065],
            [6.829113, 45.702831],
            [6.841692, 45.699758],
            [6.847752, 45.689376],
            [6.868596, 45.680965],
            [6.890295, 45.677517],
            [6.902535, 45.680939],
            [6.906505, 45.674559],
            [6.902137, 45.663743],
            [6.916286, 45.660011],
            [6.917185, 45.650737],
            [6.934614, 45.647094],
            [6.96668, 45.654063],
            [6.978157, 45.645388],
            [7.000692, 45.6399],
            [7.001046, 45.634973],
            [6.986008, 45.62249],
            [6.985542, 45.611105],
            [6.977847, 45.589885],
            [6.980697, 45.583324],
            [6.995384, 45.575637],
            [6.990248, 45.561161],
            [6.994509, 45.546677],
            [6.99151, 45.531279],
            [7.003844, 45.52094],
            [7.000332, 45.504414],
            [7.022477, 45.496548],
            [7.052054, 45.49625],
            [7.056357, 45.489624],
            [7.04585, 45.478351],
            [7.054227, 45.472184],
            [7.069423, 45.473884],
            [7.099784, 45.469438],
            [7.101483, 45.453864],
            [7.114616, 45.44172],
            [7.113517, 45.434196],
            [7.133848, 45.426565],
            [7.147513, 45.423993],
            [7.156887, 45.417008],
            [7.184271, 45.407484],
            [7.1776, 45.389026],
            [7.163181, 45.38136],
            [7.1589, 45.370752],
            [7.16166, 45.362454],
            [7.15288, 45.353816],
            [7.137739, 45.350806],
            [7.132113, 45.341125],
            [7.134713, 45.331243],
            [7.126344, 45.326945],
            [7.110693, 45.326509],
            [7.112373, 45.315171],
            [7.119179, 45.306693],
            [7.122988, 45.294328],
            [7.136423, 45.280798],
            [7.132084, 45.266961],
            [7.137593, 45.255693],
            [7.125585, 45.244603],
            [7.110604, 45.246347],
            [7.106512, 45.239259],
            [7.085436, 45.225882],
            [7.079601, 45.214244],
            [7.067337, 45.210085],
            [7.05118, 45.225335],
            [7.042761, 45.225314],
            [7.030359, 45.217845],
            [7.019993, 45.215877],
            [7.000375, 45.217603],
            [6.989138, 45.21026],
            [6.968762, 45.208058],
            [6.953836, 45.184629],
            [6.954196, 45.179612],
            [6.942903, 45.176189],
            [6.943064, 45.170911],
            [6.930267, 45.170963],
            [6.90696, 45.166614],
            [6.891601, 45.167033],
            [6.885824, 45.154195],
            [6.897563, 45.143009],
            [6.894377, 45.137373],
            [6.874763, 45.135636],
            [6.849855, 45.127165],
            [6.842286, 45.135528],
            [6.812271, 45.148357],
            [6.801396, 45.150083],
            [6.779648, 45.158029],
            [6.767941, 45.15974],
            [6.749894, 45.142719],
            [6.739263, 45.136772],
            [6.727039, 45.138491],
            [6.712108, 45.144649],
            [6.686214, 45.139756],
            [6.680069, 45.140123],
            [6.673982, 45.129496],
            [6.66571, 45.122606],
            [6.629992, 45.109325],
            [6.615546, 45.121478],
            [6.590685, 45.119011],
            [6.576529, 45.123093],
            [6.563079, 45.113039],
            [6.556731, 45.104079],
            [6.529711, 45.098569],
            [6.510424, 45.108998],
            [6.499528, 45.101598],
            [6.481256, 45.094139],
            [6.489338, 45.068354],
            [6.486236, 45.056076],
            [6.472888, 45.056012],
            [6.45353, 45.051837],
            [6.443399, 45.055077],
            [6.43859, 45.062587],
            [6.400243, 45.063261],
            [6.393911, 45.061818],
            [6.365073, 45.07129],
            [6.373531, 45.084152],
            [6.362927, 45.104492],
            [6.334484, 45.122836],
            [6.331295, 45.118124],
            [6.301916, 45.108954],
            [6.286281, 45.110161],
            [6.275503, 45.115299],
            [6.26057, 45.126844],
            [6.254494, 45.12079],
            [6.243831, 45.117298],
            [6.229392, 45.10875],
            [6.229672, 45.100589],
            [6.235832, 45.087233],
            [6.240121, 45.067718],
            [6.22008, 45.06537],
            [6.206328, 45.026783],
            [6.203923, 45.012471],
            [6.251761, 44.9967],
            [6.269699, 44.998351],
            [6.297062, 45.003365],
            [6.318202, 45.003859],
            [6.319657, 44.994553],
            [6.31481, 44.980186],
            [6.328501, 44.969714],
            [6.322918, 44.95302],
            [6.329005, 44.947315],
            [6.358842, 44.941281],
            [6.354614, 44.923593],
            [6.35817, 44.893779],
            [6.350778, 44.881204],
            [6.355363, 44.854775],
            [6.336317, 44.84837],
            [6.31923, 44.856846],
            [6.30285, 44.873258],
            [6.288213, 44.874031],
            [6.267756, 44.869501],
            [6.258171, 44.862484],
            [6.250543, 44.852651],
            [6.224375, 44.852494],
            [6.196376, 44.858978],
            [6.185219, 44.853992],
            [6.168451, 44.852243],
            [6.149019, 44.85817],
            [6.136227, 44.864072],
            [6.128356, 44.861902],
            [6.116673, 44.849313],
            [6.100706, 44.842578],
            [6.096516, 44.837489],
            [6.065315, 44.822685],
            [6.05634, 44.815907],
            [6.040207, 44.827867],
            [6.030216, 44.838098],
            [6.015933, 44.835486],
            [6.00486, 44.820439],
            [5.996166, 44.817874],
            [5.978199, 44.818036],
            [5.949522, 44.804527],
            [5.953764, 44.799526],
            [5.977779, 44.790984],
            [5.980149, 44.781182],
            [5.955515, 44.772449],
            [5.952472, 44.762141],
            [5.937984, 44.763046],
            [5.926812, 44.757136],
            [5.915234, 44.754703],
            [5.90015, 44.758315],
            [5.888831, 44.748804],
            [5.879495, 44.747015],
            [5.865226, 44.751552],
            [5.850394, 44.750747],
            [5.837158, 44.757677],
            [5.827098, 44.759688],
            [5.827771, 44.740086],
            [5.817772, 44.730405],
            [5.808795, 44.712102],
            [5.80147, 44.706778],
            [5.82711, 44.700288],
            [5.829472, 44.692425],
            [5.824635, 44.685278],
            [5.80648, 44.677182],
            [5.790624, 44.653293],
            [5.766631, 44.655267],
            [5.753943, 44.662711],
            [5.748257, 44.655091],
            [5.751105, 44.648612],
            [5.736013, 44.64951],
            [5.735803, 44.640416],
            [5.726033, 44.639394],
            [5.685362, 44.649596],
            [5.65936, 44.650663],
            [5.654457, 44.655393],
            [5.641718, 44.651077],
            [5.640373, 44.633825],
            [5.649631, 44.617885],
            [5.647516, 44.612808],
            [5.627479, 44.594966],
            [5.625799, 44.586276],
            [5.607019, 44.568348],
            [5.599798, 44.553941],
            [5.597253, 44.543274],
            [5.614993, 44.532818],
            [5.630802, 44.531597],
            [5.653143, 44.514831],
            [5.664504, 44.501895],
            [5.652656, 44.499963],
            [5.629788, 44.501187],
            [5.618123, 44.474813],
            [5.603645, 44.465542],
            [5.579201, 44.471686],
            [5.570304, 44.476755],
            [5.562401, 44.474854],
            [5.544701, 44.482538],
            [5.513342, 44.491126],
            [5.479877, 44.491243],
            [5.473596, 44.498569],
            [5.465389, 44.500459],
            [5.456931, 44.496236],
            [5.463576, 44.480513],
            [5.458731, 44.466143],
            [5.464049, 44.45736],
            [5.464428, 44.44789],
            [5.496804, 44.438494],
            [5.493615, 44.428218],
            [5.486567, 44.429311],
            [5.476485, 44.419722],
            [5.450759, 44.430874],
            [5.433857, 44.433224],
            [5.418533, 44.424945],
            [5.422756, 44.416771],
            [5.43431, 44.409479],
            [5.44302, 44.391234],
            [5.442361, 44.38166],
            [5.435114, 44.377077],
            [5.434415, 44.369626],
            [5.462966, 44.367052],
            [5.467949, 44.352677],
            [5.482472, 44.349606],
            [5.49307, 44.337174],
            [5.513545, 44.347486],
            [5.539863, 44.342637],
            [5.537366, 44.333521],
            [5.549111, 44.330396],
            [5.57386, 44.33394],
            [5.586892, 44.332226],
            [5.617139, 44.332479],
            [5.626915, 44.334765],
            [5.631598, 44.328306],
            [5.613832, 44.316139],
            [5.607906, 44.30667],
            [5.637753, 44.299689],
            [5.633266, 44.28212],
            [5.646781, 44.267089],
            [5.675291, 44.275852],
            [5.686562, 44.266921],
            [5.675344, 44.258111],
            [5.672644, 44.245665],
            [5.681315, 44.232891],
            [5.676021, 44.212147],
            [5.686443, 44.197158],
            [5.676036, 44.191429],
            [5.651586, 44.189573],
            [5.652293, 44.185411],
            [5.643691, 44.172641],
            [5.646959, 44.166287],
            [5.662633, 44.166864],
            [5.682709, 44.163217],
            [5.678609, 44.146091],
            [5.66795, 44.148874],
            [5.657708, 44.147529],
            [5.63114, 44.150577],
            [5.639595, 44.167582],
            [5.616216, 44.181069],
            [5.602056, 44.191496],
            [5.596206, 44.187649],
            [5.576192, 44.188037],
            [5.564371, 44.170902],
            [5.582975, 44.157626],
            [5.569507, 44.1481],
            [5.551331, 44.149792],
            [5.543985, 44.13639],
            [5.532959, 44.130053],
            [5.519757, 44.126616],
            [5.504824, 44.11627],
            [5.498786, 44.115717],
            [5.454715, 44.119226],
            [5.449251, 44.124592],
            [5.447281, 44.135995],
            [5.436946, 44.142813],
            [5.435751, 44.15225],
            [5.390523, 44.153425],
            [5.383237, 44.155285],
            [5.386477, 44.176907],
            [5.383042, 44.198796],
            [5.384527, 44.201049],
            [5.354042, 44.213432],
            [5.336784, 44.203893],
            [5.318311, 44.209869],
            [5.30371, 44.206011],
            [5.291191, 44.214934],
            [5.256505, 44.230056],
            [5.240451, 44.230826],
            [5.238146, 44.213233],
            [5.204614, 44.215117],
            [5.173507, 44.221966],
            [5.1549, 44.230941],
            [5.151659, 44.237729],
            [5.16155, 44.245589],
            [5.157136, 44.267225],
            [5.147472, 44.269525],
            [5.149731, 44.282086],
            [5.167205, 44.29205],
            [5.17269, 44.309391],
            [5.166435, 44.314853],
            [5.152417, 44.307684],
            [5.154676, 44.301845],
            [5.121466, 44.28748],
            [5.107852, 44.280398],
            [5.076514, 44.284084],
            [5.060561, 44.308137],
            [5.038495, 44.299638],
            [5.021847, 44.295587],
            [5.006912, 44.287554],
            [4.981453, 44.284833],
            [4.959892, 44.274193],
            [4.935493, 44.264315],
            [4.917533, 44.260349],
            [4.906063, 44.260294],
            [4.896148, 44.264431],
            [4.873756, 44.259258],
            [4.845369, 44.241471],
            [4.82665, 44.228322],
            [4.81409, 44.232315],
            [4.812662, 44.257716],
            [4.805294, 44.268723],
            [4.80288, 44.296594],
            [4.7991, 44.303525],
            [4.782547, 44.315583],
            [4.762255, 44.325382],
            [4.720127, 44.326711],
            [4.713017, 44.320649],
            [4.679025, 44.32049],
            [4.650611, 44.329803],
            [4.653662, 44.29728],
            [4.649227, 44.27036],
            [4.641963, 44.273967],
            [4.63806, 44.282965],
            [4.628823, 44.284004],
            [4.618146, 44.278542],
            [4.606818, 44.290472],
            [4.586981, 44.294596],
            [4.574187, 44.300751],
            [4.558696, 44.302443],
            [4.544468, 44.320767],
            [4.532621, 44.322525],
            [4.517864, 44.329927],
            [4.503539, 44.340188],
            [4.484186, 44.337582],
            [4.476116, 44.340745],
            [4.457616, 44.341636],
            [4.450886, 44.332617],
            [4.449272, 44.304915],
            [4.45069, 44.297287],
            [4.440502, 44.284053],
            [4.421891, 44.287324],
            [4.398978, 44.288944],
            [4.393407, 44.293647],
            [4.390781, 44.303051],
            [4.403209, 44.333896],
            [4.394199, 44.345265],
            [4.386526, 44.346614],
            [4.366778, 44.339498],
            [4.336071, 44.339519],
            [4.321724, 44.323989],
            [4.304428, 44.315234],
            [4.288423, 44.314658],
            [4.28941, 44.293215],
            [4.276618, 44.274253],
            [4.25885, 44.264784],
            [4.241324, 44.270096],
            [4.216443, 44.288853],
            [4.186707, 44.299685],
            [4.177745, 44.317879],
            [4.154887, 44.312622],
            [4.142871, 44.313352],
            [4.140304, 44.327884],
            [4.126746, 44.337731],
            [4.112513, 44.32949],
            [4.103991, 44.3339],
            [4.071627, 44.327299],
            [4.051457, 44.317322],
            [4.036867, 44.330771],
            [4.045004, 44.333082],
            [4.053154, 44.340969],
            [4.055527, 44.365626],
            [4.052985, 44.378694],
            [4.043744, 44.38455],
            [4.042469, 44.394644],
            [4.057213, 44.393348],
            [4.068445, 44.405112],
            [4.056104, 44.414336],
            [4.035482, 44.420091],
            [4.046458, 44.432711],
            [4.037599, 44.44572],
            [4.023206, 44.445573],
            [4.014984, 44.454514],
            [3.998162, 44.459798],
            [3.987544, 44.473361],
            [3.985747, 44.487924],
            [3.987332, 44.499163],
            [3.983704, 44.510168],
            [3.976654, 44.51669],
            [3.975366, 44.523651],
            [3.965745, 44.537497],
            [3.960875, 44.554804],
            [3.945151, 44.57379],
            [3.928483, 44.569999],
            [3.920737, 44.575746],
            [3.919167, 44.585245],
            [3.905171, 44.592709],
            [3.908304, 44.606616],
            [3.898532, 44.613412],
            [3.892615, 44.62303],
            [3.894742, 44.651129],
            [3.882909, 44.660089],
            [3.87327, 44.678202],
            [3.884548, 44.697348],
            [3.869643, 44.696963],
            [3.871891, 44.705397],
            [3.861513, 44.712017],
            [3.867792, 44.728143],
            [3.876299, 44.7318],
            [3.875462, 44.740627],
            [3.862531, 44.743866],
            [3.836559, 44.747848],
            [3.830456, 44.756162],
            [3.842872, 44.76766],
            [3.841603, 44.77213],
            [3.830146, 44.775711],
            [3.819884, 44.772685],
            [3.812132, 44.766033],
            [3.803331, 44.770414],
            [3.803231, 44.781579],
            [3.779487, 44.797311],
            [3.758761, 44.805577],
            [3.762429, 44.810666],
            [3.752681, 44.820979],
            [3.740649, 44.838697],
            [3.722884, 44.830987],
            [3.700386, 44.836813],
            [3.695439, 44.830959],
            [3.666362, 44.828776],
            [3.657421, 44.836124],
            [3.671817, 44.843382],
            [3.670479, 44.850864],
            [3.659522, 44.869941],
            [3.642346, 44.878382],
            [3.626452, 44.880254],
            [3.61475, 44.874174],
            [3.608276, 44.879331],
            [3.594334, 44.875433],
            [3.598035, 44.859505],
            [3.592066, 44.847965],
            [3.589355, 44.832194],
            [3.578465, 44.825697],
            [3.574542, 44.83461],
            [3.563821, 44.832832],
            [3.561576, 44.824977],
            [3.539752, 44.828176],
            [3.505646, 44.823847],
            [3.494866, 44.815406],
            [3.490996, 44.808474],
            [3.47881, 44.809447],
            [3.475771, 44.815371],
            [3.457062, 44.833778],
            [3.454199, 44.844564],
            [3.441674, 44.85445],
            [3.438625, 44.860822],
            [3.435705, 44.880245],
            [3.417977, 44.900767],
            [3.419259, 44.908428],
            [3.413833, 44.91986],
            [3.414953, 44.932632],
            [3.412832, 44.944842],
            [3.404553, 44.9564],
            [3.396993, 44.958578],
            [3.386368, 44.952742],
            [3.371355, 44.96025],
            [3.370319, 44.969984],
            [3.361348, 44.971408],
          ],
          [
            [4.888121, 44.331685],
            [4.881634, 44.324867],
            [4.889804, 44.314637],
            [4.88953, 44.304153],
            [4.922154, 44.308786],
            [4.945117, 44.30493],
            [4.959214, 44.300383],
            [4.978583, 44.297461],
            [4.98729, 44.293202],
            [4.99313, 44.314276],
            [5.013377, 44.326137],
            [5.009002, 44.333764],
            [5.023285, 44.345948],
            [5.021705, 44.356748],
            [5.027011, 44.362835],
            [5.05201, 44.364657],
            [5.071558, 44.377496],
            [5.070883, 44.383226],
            [5.045088, 44.38222],
            [5.033569, 44.391089],
            [5.015755, 44.392767],
            [5.013302, 44.405343],
            [5.018754, 44.415979],
            [5.001069, 44.412605],
            [4.988612, 44.4232],
            [4.978694, 44.423476],
            [4.970438, 44.431367],
            [4.960462, 44.420048],
            [4.918514, 44.407787],
            [4.91238, 44.399636],
            [4.911766, 44.387045],
            [4.906621, 44.374101],
            [4.893169, 44.367875],
            [4.890682, 44.359782],
            [4.875396, 44.351439],
            [4.879647, 44.345347],
            [4.895327, 44.338062],
            [4.888121, 44.331685],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5380",
      properties: {
        name: "Bourgogne-Franche-Comté",
        density: 0,
        path: "/world/France/Bourgogne-Franche-Comté",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.940542, 47.433372],
            [6.941679, 47.415802],
            [6.938378, 47.406022],
            [6.917566, 47.405541],
            [6.911281, 47.385651],
            [6.885396, 47.3746],
            [6.879373, 47.358393],
            [6.884185, 47.352617],
            [6.904131, 47.359489],
            [6.917306, 47.356174],
            [6.951822, 47.359329],
            [6.965172, 47.359201],
            [6.983254, 47.363544],
            [6.994219, 47.363057],
            [7.016175, 47.371835],
            [7.049715, 47.360716],
            [7.052294, 47.351142],
            [7.062201, 47.34416],
            [7.056751, 47.334567],
            [7.046206, 47.326978],
            [7.030571, 47.327028],
            [7.009728, 47.324359],
            [7.016141, 47.313601],
            [7.009688, 47.303186],
            [6.993121, 47.295544],
            [6.981266, 47.295771],
            [6.966531, 47.29233],
            [6.953559, 47.292422],
            [6.942892, 47.287757],
            [6.951476, 47.262184],
            [6.949414, 47.256679],
            [6.954977, 47.24294],
            [6.926273, 47.224556],
            [6.882438, 47.201615],
            [6.863915, 47.180099],
            [6.84263, 47.172148],
            [6.844854, 47.166385],
            [6.858344, 47.164385],
            [6.839787, 47.150905],
            [6.828573, 47.146342],
            [6.806549, 47.13089],
            [6.76378, 47.119795],
            [6.739914, 47.108052],
            [6.745676, 47.100308],
            [6.741092, 47.091068],
            [6.71783, 47.088938],
            [6.707258, 47.083034],
            [6.70601, 47.074995],
            [6.69162, 47.066689],
            [6.711051, 47.054328],
            [6.714055, 47.049123],
            [6.699656, 47.039039],
            [6.678472, 47.035382],
            [6.661621, 47.028224],
            [6.653591, 47.021769],
            [6.640342, 47.002761],
            [6.618679, 46.992039],
            [6.59318, 46.991771],
            [6.566562, 46.980637],
            [6.540208, 46.974399],
            [6.518807, 46.970856],
            [6.505219, 46.965849],
            [6.496686, 46.974183],
            [6.475125, 46.959379],
            [6.455344, 46.940429],
            [6.432675, 46.928606],
            [6.457903, 46.900856],
            [6.464578, 46.890211],
            [6.459879, 46.851147],
            [6.442342, 46.831237],
            [6.440168, 46.819063],
            [6.431002, 46.81241],
            [6.438293, 46.799582],
            [6.458652, 46.78876],
            [6.438105, 46.761751],
            [6.425911, 46.754801],
            [6.395075, 46.748243],
            [6.389643, 46.735238],
            [6.371629, 46.724751],
            [6.360238, 46.72308],
            [6.344802, 46.711868],
            [6.315492, 46.702443],
            [6.299357, 46.694896],
            [6.285326, 46.691242],
            [6.270488, 46.683194],
            [6.269172, 46.678151],
            [6.241611, 46.660315],
            [6.208777, 46.636618],
            [6.184917, 46.622901],
            [6.175535, 46.614143],
            [6.164465, 46.610012],
            [6.127333, 46.59031],
            [6.110744, 46.576314],
            [6.138106, 46.55766],
            [6.156442, 46.545472],
            [6.152848, 46.536135],
            [6.144602, 46.528394],
            [6.137859, 46.530871],
            [6.112694, 46.509641],
            [6.096828, 46.481209],
            [6.07393, 46.46395],
            [6.07446, 46.453664],
            [6.084391, 46.447437],
            [6.085835, 46.440994],
            [6.074768, 46.431631],
            [6.064006, 46.416223],
            [6.055182, 46.414801],
            [6.048075, 46.405125],
            [6.029514, 46.386816],
            [6.010657, 46.374509],
            [5.986846, 46.364869],
            [5.972917, 46.346856],
            [5.95356, 46.326531],
            [5.941409, 46.309447],
            [5.925904, 46.313766],
            [5.918518, 46.3072],
            [5.908936, 46.283951],
            [5.894625, 46.286608],
            [5.879121, 46.269936],
            [5.870249, 46.265306],
            [5.864333, 46.271209],
            [5.849848, 46.262067],
            [5.821284, 46.262094],
            [5.765647, 46.268295],
            [5.745918, 46.266369],
            [5.725182, 46.260732],
            [5.720112, 46.26587],
            [5.716049, 46.279916],
            [5.719294, 46.293462],
            [5.714722, 46.308772],
            [5.684577, 46.310928],
            [5.683347, 46.316277],
            [5.668442, 46.324369],
            [5.652905, 46.323215],
            [5.649345, 46.339495],
            [5.641958, 46.342028],
            [5.630515, 46.330205],
            [5.617644, 46.329092],
            [5.610461, 46.324211],
            [5.598389, 46.298446],
            [5.585501, 46.292558],
            [5.566364, 46.29405],
            [5.558127, 46.282174],
            [5.542034, 46.270204],
            [5.512943, 46.26454],
            [5.499589, 46.2682],
            [5.473052, 46.265067],
            [5.456843, 46.274474],
            [5.459513, 46.290472],
            [5.467404, 46.295566],
            [5.475301, 46.315383],
            [5.466918, 46.323267],
            [5.437795, 46.315109],
            [5.427898, 46.342211],
            [5.417086, 46.339534],
            [5.410234, 46.309107],
            [5.404651, 46.310338],
            [5.404293, 46.332813],
            [5.399276, 46.339521],
            [5.381917, 46.345256],
            [5.373465, 46.352234],
            [5.377054, 46.364113],
            [5.362997, 46.370927],
            [5.377043, 46.381355],
            [5.355222, 46.393755],
            [5.352508, 46.397586],
            [5.331272, 46.399499],
            [5.31477, 46.409476],
            [5.307687, 46.416819],
            [5.308979, 46.424532],
            [5.319488, 46.430803],
            [5.310563, 46.44677],
            [5.275925, 46.448121],
            [5.254594, 46.454118],
            [5.246832, 46.459404],
            [5.235029, 46.457949],
            [5.225548, 46.468274],
            [5.215063, 46.468359],
            [5.213043, 46.481262],
            [5.206635, 46.486398],
            [5.20114, 46.508211],
            [5.181597, 46.509759],
            [5.166792, 46.514675],
            [5.16645, 46.505591],
            [5.14195, 46.508357],
            [5.115205, 46.494069],
            [5.107372, 46.491919],
            [5.098939, 46.497167],
            [5.070006, 46.485667],
            [5.052372, 46.484874],
            [5.014179, 46.500588],
            [5.011008, 46.510295],
            [4.98355, 46.515393],
            [4.964599, 46.513176],
            [4.949102, 46.501381],
            [4.940022, 46.517199],
            [4.931421, 46.509211],
            [4.925719, 46.497442],
            [4.915551, 46.488939],
            [4.915781, 46.465413],
            [4.911117, 46.457733],
            [4.899296, 46.450123],
            [4.891815, 46.439918],
            [4.88821, 46.402977],
            [4.873839, 46.384668],
            [4.858529, 46.368018],
            [4.851454, 46.356271],
            [4.853421, 46.329901],
            [4.833214, 46.300145],
            [4.825952, 46.274786],
            [4.810998, 46.259923],
            [4.811555, 46.249933],
            [4.807751, 46.236972],
            [4.794586, 46.218312],
            [4.79339, 46.2047],
            [4.780243, 46.189052],
            [4.780208, 46.176676],
            [4.760805, 46.175969],
            [4.75976, 46.172841],
            [4.730526, 46.178369],
            [4.724371, 46.184305],
            [4.722665, 46.20232],
            [4.735693, 46.211924],
            [4.720624, 46.222391],
            [4.732661, 46.22702],
            [4.735767, 46.234245],
            [4.702829, 46.25133],
            [4.688362, 46.250114],
            [4.67955, 46.258667],
            [4.687881, 46.265312],
            [4.707687, 46.269648],
            [4.707541, 46.28466],
            [4.694516, 46.292673],
            [4.69311, 46.302197],
            [4.679434, 46.303994],
            [4.669382, 46.29791],
            [4.654659, 46.303485],
            [4.635261, 46.29929],
            [4.631452, 46.289015],
            [4.618322, 46.28244],
            [4.622024, 46.270079],
            [4.618558, 46.264794],
            [4.586507, 46.268695],
            [4.572669, 46.277139],
            [4.569849, 46.293352],
            [4.557796, 46.294552],
            [4.548076, 46.282865],
            [4.546448, 46.273918],
            [4.537264, 46.269911],
            [4.503994, 46.267132],
            [4.488466, 46.287994],
            [4.47657, 46.284238],
            [4.45837, 46.296977],
            [4.439721, 46.293185],
            [4.427234, 46.302731],
            [4.421872, 46.294954],
            [4.405814, 46.296058],
            [4.392047, 46.263027],
            [4.388291, 46.247956],
            [4.388074, 46.21979],
            [4.389398, 46.213601],
            [4.377474, 46.210202],
            [4.363178, 46.200537],
            [4.370687, 46.19162],
            [4.364848, 46.183579],
            [4.345068, 46.187442],
            [4.335712, 46.181398],
            [4.327089, 46.18479],
            [4.315742, 46.17203],
            [4.303581, 46.174267],
            [4.295283, 46.17225],
            [4.282494, 46.156946],
            [4.25203, 46.157917],
            [4.251899, 46.167098],
            [4.261025, 46.178754],
            [4.2525, 46.187922],
            [4.242358, 46.18883],
            [4.233529, 46.180027],
            [4.224687, 46.177995],
            [4.207903, 46.194833],
            [4.185455, 46.190162],
            [4.188444, 46.175129],
            [4.166089, 46.172928],
            [4.132416, 46.177826],
            [4.104087, 46.198391],
            [4.090884, 46.192837],
            [4.071667, 46.188071],
            [4.061405, 46.18879],
            [4.05196, 46.18169],
            [4.030884, 46.171883],
            [3.988788, 46.169805],
            [3.981782, 46.176341],
            [3.972833, 46.193863],
            [3.972547, 46.202708],
            [3.933515, 46.206414],
            [3.917989, 46.202853],
            [3.913569, 46.206919],
            [3.890131, 46.214487],
            [3.898192, 46.226659],
            [3.896611, 46.237119],
            [3.907658, 46.242456],
            [3.908822, 46.260347],
            [3.905513, 46.271597],
            [3.899539, 46.275908],
            [3.891239, 46.285246],
            [3.897421, 46.291461],
            [3.913571, 46.296681],
            [3.930098, 46.29582],
            [3.942782, 46.298926],
            [3.947719, 46.303445],
            [3.948129, 46.319792],
            [3.986627, 46.319196],
            [3.997405, 46.323291],
            [3.984741, 46.32973],
            [3.988664, 46.360561],
            [3.991604, 46.36963],
            [3.984262, 46.378286],
            [3.977221, 46.39922],
            [3.988697, 46.408772],
            [3.984448, 46.416663],
            [3.995615, 46.428694],
            [3.988142, 46.435462],
            [4.00557, 46.443531],
            [3.997444, 46.450265],
            [4.001542, 46.458618],
            [3.99804, 46.465464],
            [3.973056, 46.477457],
            [3.956593, 46.476882],
            [3.952087, 46.481423],
            [3.957938, 46.489754],
            [3.949613, 46.492479],
            [3.937576, 46.490762],
            [3.91897, 46.496061],
            [3.901353, 46.490636],
            [3.896986, 46.481472],
            [3.890467, 46.481246],
            [3.864913, 46.489736],
            [3.860387, 46.495601],
            [3.864627, 46.509717],
            [3.860025, 46.515222],
            [3.849471, 46.513024],
            [3.839756, 46.517562],
            [3.846463, 46.524356],
            [3.834003, 46.531135],
            [3.817676, 46.525229],
            [3.811344, 46.520138],
            [3.801756, 46.519902],
            [3.787896, 46.528043],
            [3.765601, 46.537908],
            [3.754663, 46.53602],
            [3.741844, 46.539508],
            [3.731541, 46.549578],
            [3.740655, 46.559049],
            [3.743289, 46.567565],
            [3.732378, 46.604907],
            [3.717647, 46.605806],
            [3.713874, 46.613995],
            [3.723025, 46.622073],
            [3.722709, 46.62761],
            [3.712152, 46.633632],
            [3.69968, 46.651867],
            [3.696958, 46.660583],
            [3.6801, 46.668521],
            [3.655472, 46.687748],
            [3.651393, 46.702822],
            [3.637895, 46.707205],
            [3.638139, 46.722724],
            [3.635334, 46.728515],
            [3.62259, 46.740131],
            [3.629422, 46.749456],
            [3.620107, 46.75406],
            [3.60204, 46.751247],
            [3.597749, 46.762029],
            [3.591053, 46.762426],
            [3.580671, 46.752741],
            [3.584552, 46.739383],
            [3.597188, 46.728402],
            [3.598001, 46.723983],
            [3.577314, 46.714853],
            [3.550387, 46.715861],
            [3.548208, 46.706167],
            [3.555559, 46.695741],
            [3.554396, 46.684732],
            [3.546473, 46.678293],
            [3.530036, 46.688813],
            [3.517493, 46.683185],
            [3.487153, 46.658256],
            [3.486535, 46.653809],
            [3.457289, 46.65176],
            [3.447339, 46.663554],
            [3.453596, 46.681988],
            [3.451312, 46.689901],
            [3.432978, 46.69334],
            [3.429567, 46.702505],
            [3.43414, 46.711908],
            [3.407411, 46.711511],
            [3.387758, 46.714818],
            [3.378731, 46.711348],
            [3.375427, 46.699411],
            [3.366445, 46.691259],
            [3.346704, 46.684418],
            [3.313965, 46.688752],
            [3.298833, 46.71359],
            [3.269796, 46.716742],
            [3.255164, 46.706289],
            [3.233036, 46.697018],
            [3.215545, 46.682893],
            [3.19726, 46.679928],
            [3.163152, 46.693541],
            [3.12978, 46.727202],
            [3.083934, 46.737632],
            [3.061078, 46.74985],
            [3.049067, 46.758081],
            [3.036145, 46.776351],
            [3.036839, 46.784399],
            [3.032063, 46.794909],
            [3.037471, 46.807787],
            [3.059351, 46.827264],
            [3.054589, 46.838765],
            [3.067102, 46.847818],
            [3.068096, 46.87768],
            [3.060959, 46.898149],
            [3.051402, 46.904956],
            [3.050295, 46.910631],
            [3.062211, 46.92774],
            [3.064898, 46.937079],
            [3.079332, 46.955115],
            [3.071706, 46.964341],
            [3.06293, 46.981085],
            [3.065582, 46.997352],
            [3.075744, 47.019148],
            [3.074838, 47.029815],
            [3.062745, 47.045276],
            [3.034525, 47.056291],
            [3.023219, 47.062612],
            [3.018716, 47.070781],
            [3.02118, 47.078232],
            [3.031374, 47.092476],
            [3.028091, 47.129065],
            [3.021198, 47.136438],
            [3.015948, 47.159094],
            [3.009285, 47.179915],
            [2.997283, 47.201664],
            [2.981689, 47.221993],
            [2.977955, 47.235553],
            [2.9834, 47.259766],
            [2.973939, 47.269805],
            [2.937862, 47.287719],
            [2.926481, 47.298618],
            [2.907768, 47.310991],
            [2.884876, 47.316364],
            [2.876542, 47.321934],
            [2.869948, 47.338248],
            [2.873492, 47.348397],
            [2.895249, 47.372177],
            [2.900816, 47.385193],
            [2.918455, 47.405525],
            [2.919886, 47.42135],
            [2.930733, 47.431324],
            [2.928897, 47.444565],
            [2.920988, 47.455436],
            [2.914061, 47.470897],
            [2.898018, 47.485252],
            [2.888459, 47.509431],
            [2.874631, 47.520425],
            [2.848901, 47.537541],
            [2.845187, 47.544933],
            [2.857486, 47.552839],
            [2.866125, 47.546368],
            [2.873895, 47.55281],
            [2.890771, 47.553102],
            [2.909372, 47.559161],
            [2.914296, 47.565972],
            [2.958652, 47.557378],
            [2.964314, 47.564408],
            [2.976535, 47.569424],
            [2.962268, 47.576787],
            [2.964452, 47.581632],
            [2.954983, 47.590409],
            [2.948069, 47.590535],
            [2.937994, 47.598886],
            [2.945217, 47.608449],
            [2.935769, 47.619832],
            [2.936291, 47.636649],
            [2.954229, 47.645774],
            [2.936944, 47.659132],
            [2.926238, 47.660086],
            [2.918156, 47.669795],
            [2.923115, 47.680559],
            [2.903712, 47.695096],
            [2.882941, 47.702615],
            [2.884193, 47.714212],
            [2.87658, 47.719082],
            [2.858517, 47.711991],
            [2.848969, 47.716845],
            [2.84879, 47.725885],
            [2.858146, 47.74565],
            [2.85667, 47.760929],
            [2.870177, 47.764868],
            [2.893987, 47.764757],
            [2.909376, 47.769314],
            [2.935056, 47.76325],
            [2.988227, 47.786034],
            [3.023799, 47.78655],
            [3.02812, 47.800645],
            [3.022937, 47.812772],
            [3.01547, 47.813536],
            [3.012531, 47.834373],
            [3.031078, 47.837953],
            [3.033828, 47.843872],
            [3.024157, 47.860433],
            [3.005359, 47.864049],
            [3.002016, 47.869105],
            [3.011313, 47.874942],
            [3.007226, 47.895291],
            [3.010289, 47.904717],
            [3.025143, 47.905395],
            [3.036909, 47.910047],
            [3.050103, 47.91145],
            [3.049852, 47.917612],
            [3.064613, 47.930517],
            [3.078476, 47.931419],
            [3.081389, 47.938303],
            [3.095956, 47.946483],
            [3.105265, 47.946939],
            [3.11778, 47.964978],
            [3.128449, 47.970977],
            [3.121509, 47.995081],
            [3.124552, 48.006031],
            [3.115427, 48.012966],
            [3.104091, 48.013542],
            [3.10311, 48.024051],
            [3.120445, 48.027237],
            [3.124257, 48.031123],
            [3.097336, 48.039372],
            [3.088316, 48.053932],
            [3.066843, 48.062701],
            [3.050471, 48.072334],
            [3.049889, 48.088839],
            [3.038314, 48.101543],
            [3.035285, 48.115994],
            [3.015877, 48.115703],
            [3.029468, 48.133204],
            [3.006785, 48.14525],
            [2.993984, 48.142266],
            [2.990885, 48.152489],
            [2.963612, 48.152953],
            [2.953271, 48.165012],
            [2.936316, 48.163392],
            [2.934745, 48.178821],
            [2.950743, 48.190203],
            [2.969547, 48.193835],
            [2.97447, 48.20555],
            [2.988966, 48.209015],
            [3.00516, 48.207666],
            [3.018924, 48.231775],
            [3.018566, 48.235193],
            [3.031462, 48.248879],
            [3.047566, 48.249696],
            [3.043633, 48.272021],
            [3.024964, 48.276009],
            [3.029771, 48.285841],
            [3.020415, 48.29344],
            [3.027256, 48.300368],
            [3.015898, 48.307897],
            [3.036574, 48.326028],
            [3.03749, 48.343151],
            [3.049523, 48.360117],
            [3.060548, 48.357442],
            [3.087841, 48.358744],
            [3.103415, 48.349511],
            [3.118256, 48.366788],
            [3.139865, 48.3726],
            [3.167734, 48.371858],
            [3.179671, 48.375721],
            [3.184947, 48.36813],
            [3.201851, 48.364015],
            [3.232849, 48.370333],
            [3.254386, 48.36502],
            [3.264777, 48.374621],
            [3.282392, 48.37752],
            [3.305159, 48.372877],
            [3.309322, 48.37698],
            [3.33011, 48.372121],
            [3.365161, 48.372282],
            [3.362785, 48.382577],
            [3.367213, 48.394317],
            [3.383317, 48.399704],
            [3.402033, 48.389706],
            [3.414789, 48.390269],
            [3.413273, 48.376301],
            [3.421681, 48.371728],
            [3.427509, 48.359976],
            [3.443249, 48.367383],
            [3.452589, 48.374388],
            [3.470476, 48.374668],
            [3.474881, 48.369267],
            [3.498154, 48.369098],
            [3.512139, 48.360812],
            [3.530465, 48.342613],
            [3.545102, 48.334543],
            [3.544073, 48.319672],
            [3.563407, 48.321441],
            [3.566569, 48.307426],
            [3.587964, 48.300806],
            [3.585741, 48.290084],
            [3.577796, 48.284593],
            [3.588198, 48.280069],
            [3.611771, 48.274411],
            [3.616802, 48.271344],
            [3.624323, 48.254527],
            [3.613853, 48.249277],
            [3.60008, 48.237038],
            [3.604687, 48.229885],
            [3.613627, 48.232261],
            [3.621611, 48.225744],
            [3.614071, 48.220116],
            [3.611152, 48.21193],
            [3.600997, 48.203801],
            [3.575182, 48.188742],
            [3.594177, 48.178873],
            [3.619545, 48.190785],
            [3.631885, 48.18939],
            [3.6415, 48.183933],
            [3.650654, 48.168228],
            [3.65958, 48.159652],
            [3.667867, 48.139211],
            [3.688755, 48.144279],
            [3.693622, 48.148],
            [3.705016, 48.144315],
            [3.722345, 48.156838],
            [3.714171, 48.170603],
            [3.718464, 48.175364],
            [3.740297, 48.16971],
            [3.7519, 48.161296],
            [3.754829, 48.150245],
            [3.739804, 48.138688],
            [3.739713, 48.132754],
            [3.75691, 48.125307],
            [3.768021, 48.133761],
            [3.776883, 48.124997],
            [3.80497, 48.102547],
            [3.799973, 48.096696],
            [3.798581, 48.086367],
            [3.806798, 48.083861],
            [3.820161, 48.06746],
            [3.826359, 48.063604],
            [3.827059, 48.047441],
            [3.821978, 48.043917],
            [3.832155, 48.036233],
            [3.842547, 48.03619],
            [3.850086, 48.027863],
            [3.870614, 48.015634],
            [3.870001, 48.0026],
            [3.861971, 48.004326],
            [3.839815, 48.00389],
            [3.850028, 47.983792],
            [3.864309, 47.984236],
            [3.861806, 47.976443],
            [3.878302, 47.97942],
            [3.885414, 48.000328],
            [3.900185, 47.997906],
            [3.914696, 47.975697],
            [3.905615, 47.956391],
            [3.902092, 47.939168],
            [3.894036, 47.929291],
            [3.912468, 47.930259],
            [3.92645, 47.934567],
            [3.940327, 47.933141],
            [3.956598, 47.934513],
            [3.985957, 47.93064],
            [4.00559, 47.94201],
            [4.012202, 47.9331],
            [4.025649, 47.928322],
            [4.031546, 47.933181],
            [4.043508, 47.928108],
            [4.054046, 47.92948],
            [4.056312, 47.9415],
            [4.061263, 47.945564],
            [4.077909, 47.942844],
            [4.092975, 47.942979],
            [4.091393, 47.929645],
            [4.111782, 47.926998],
            [4.129355, 47.93604],
            [4.141839, 47.937107],
            [4.145993, 47.94795],
            [4.16632, 47.9598],
            [4.181494, 47.957134],
            [4.185175, 47.953253],
            [4.185389, 47.94025],
            [4.201887, 47.941158],
            [4.207836, 47.946595],
            [4.199154, 47.969941],
            [4.212781, 47.971726],
            [4.228462, 47.969156],
            [4.22214, 47.949693],
            [4.236286, 47.940458],
            [4.248113, 47.929257],
            [4.265593, 47.924113],
            [4.293421, 47.925674],
            [4.301942, 47.939722],
            [4.298969, 47.948925],
            [4.309001, 47.961171],
            [4.351283, 47.956582],
            [4.398491, 47.963429],
            [4.41435, 47.968208],
            [4.427588, 47.965925],
            [4.439404, 47.958224],
            [4.449004, 47.957152],
            [4.482135, 47.963341],
            [4.490801, 47.967275],
            [4.515003, 47.966198],
            [4.531567, 47.969936],
            [4.553309, 47.967682],
            [4.559964, 47.971424],
            [4.555208, 47.985774],
            [4.545599, 47.988294],
            [4.535817, 48.001208],
            [4.549426, 48.00446],
            [4.554851, 48.012319],
            [4.567323, 48.018739],
            [4.571885, 48.025129],
            [4.582673, 48.029463],
            [4.612405, 48.030242],
            [4.624063, 48.025381],
            [4.639625, 48.025482],
            [4.663323, 48.02002],
            [4.673227, 48.015055],
            [4.698553, 48.023678],
            [4.704233, 48.020235],
            [4.719946, 48.008896],
            [4.749297, 48.004247],
            [4.789078, 48.007829],
            [4.793264, 47.996944],
            [4.80919, 47.990088],
            [4.794634, 47.983195],
            [4.786515, 47.964201],
            [4.819424, 47.960359],
            [4.841009, 47.960753],
            [4.852047, 47.956242],
            [4.866094, 47.940509],
            [4.850172, 47.929538],
            [4.84672, 47.924484],
            [4.828942, 47.915124],
            [4.834034, 47.906782],
            [4.856999, 47.895851],
            [4.86934, 47.91745],
            [4.894496, 47.92284],
            [4.901851, 47.921285],
            [4.919092, 47.89472],
            [4.928173, 47.886872],
            [4.928236, 47.871124],
            [4.9541, 47.866767],
            [4.960386, 47.857358],
            [4.96225, 47.839702],
            [4.968346, 47.831939],
            [4.984696, 47.828869],
            [4.994127, 47.819746],
            [4.99062, 47.807093],
            [4.982401, 47.800351],
            [4.963576, 47.795107],
            [4.956449, 47.790048],
            [4.918305, 47.777312],
            [4.917785, 47.766615],
            [4.93074, 47.761333],
            [4.949605, 47.765006],
            [4.958992, 47.76187],
            [4.959893, 47.754471],
            [4.970703, 47.727636],
            [4.957064, 47.709406],
            [4.954139, 47.701463],
            [4.97911, 47.687764],
            [4.992274, 47.688315],
            [5.00436, 47.700727],
            [5.026267, 47.709493],
            [5.032762, 47.704076],
            [5.032795, 47.692333],
            [5.060645, 47.69479],
            [5.043583, 47.676514],
            [5.057887, 47.668306],
            [5.070365, 47.666754],
            [5.084917, 47.657253],
            [5.101726, 47.659488],
            [5.108874, 47.649688],
            [5.13342, 47.650731],
            [5.156277, 47.668257],
            [5.161341, 47.679936],
            [5.179319, 47.679307],
            [5.173773, 47.661555],
            [5.173596, 47.652564],
            [5.188582, 47.64956],
            [5.215302, 47.638777],
            [5.227566, 47.630462],
            [5.239232, 47.616131],
            [5.250544, 47.622148],
            [5.258602, 47.622216],
            [5.243335, 47.603257],
            [5.239582, 47.595736],
            [5.248822, 47.588305],
            [5.252922, 47.576951],
            [5.277906, 47.581361],
            [5.278798, 47.590249],
            [5.294862, 47.599202],
            [5.299839, 47.604926],
            [5.322349, 47.61236],
            [5.34, 47.60907],
            [5.342166, 47.597943],
            [5.354765, 47.59136],
            [5.370635, 47.604808],
            [5.37408, 47.604538],
            [5.372758, 47.61866],
            [5.386847, 47.635662],
            [5.405592, 47.647771],
            [5.398475, 47.649083],
            [5.40634, 47.673403],
            [5.426521, 47.675524],
            [5.435713, 47.670914],
            [5.446034, 47.670774],
            [5.47257, 47.676723],
            [5.482566, 47.684598],
            [5.516771, 47.673666],
            [5.529779, 47.672826],
            [5.542963, 47.685876],
            [5.567204, 47.705233],
            [5.580076, 47.703119],
            [5.59668, 47.67169],
            [5.606093, 47.675207],
            [5.616917, 47.673507],
            [5.635043, 47.67673],
            [5.653411, 47.677378],
            [5.660201, 47.684701],
            [5.690072, 47.684834],
            [5.694632, 47.691589],
            [5.693492, 47.7037],
            [5.684117, 47.711966],
            [5.684835, 47.722252],
            [5.693339, 47.737536],
            [5.709216, 47.744819],
            [5.706155, 47.75229],
            [5.709218, 47.763724],
            [5.705088, 47.769109],
            [5.679972, 47.769967],
            [5.676613, 47.779156],
            [5.681691, 47.801919],
            [5.690285, 47.818602],
            [5.703816, 47.822616],
            [5.732632, 47.817595],
            [5.746051, 47.823594],
            [5.744124, 47.848676],
            [5.753419, 47.85178],
            [5.761197, 47.859338],
            [5.798194, 47.852396],
            [5.805354, 47.847367],
            [5.827986, 47.851868],
            [5.821437, 47.868178],
            [5.836683, 47.885276],
            [5.848394, 47.904329],
            [5.869969, 47.900702],
            [5.886498, 47.902632],
            [5.890864, 47.910826],
            [5.884724, 47.926046],
            [5.896713, 47.932028],
            [5.892904, 47.937141],
            [5.900117, 47.944753],
            [5.918046, 47.947355],
            [5.921714, 47.970784],
            [5.936745, 47.978874],
            [5.947528, 47.979712],
            [5.959513, 47.965839],
            [5.93772, 47.950937],
            [5.929652, 47.938718],
            [5.953718, 47.93703],
            [5.968901, 47.947408],
            [5.970782, 47.957207],
            [5.996511, 47.957642],
            [6.002266, 47.956089],
            [6.009978, 47.968676],
            [6.023513, 47.978198],
            [6.018167, 47.984218],
            [6.02692, 47.990054],
            [6.041177, 48.004423],
            [6.072559, 48.015669],
            [6.080935, 48.0127],
            [6.097739, 48.015043],
            [6.109229, 48.012467],
            [6.116534, 48.01939],
            [6.131708, 48.023715],
            [6.156058, 48.006943],
            [6.152341, 47.994505],
            [6.164889, 47.976134],
            [6.150943, 47.970557],
            [6.160692, 47.964476],
            [6.167989, 47.952353],
            [6.178444, 47.954216],
            [6.197367, 47.951792],
            [6.207961, 47.942753],
            [6.205224, 47.932045],
            [6.219173, 47.935763],
            [6.237983, 47.93284],
            [6.277332, 47.953806],
            [6.296416, 47.955367],
            [6.30976, 47.949875],
            [6.324506, 47.94928],
            [6.338561, 47.954979],
            [6.366153, 47.961944],
            [6.394006, 47.956963],
            [6.405358, 47.953112],
            [6.408887, 47.943264],
            [6.431714, 47.943818],
            [6.436306, 47.936498],
            [6.460436, 47.913451],
            [6.456018, 47.906627],
            [6.478606, 47.885522],
            [6.503214, 47.896219],
            [6.542157, 47.902612],
            [6.568284, 47.934495],
            [6.601785, 47.9444],
            [6.630246, 47.925593],
            [6.645537, 47.904023],
            [6.669663, 47.8932],
            [6.703676, 47.881418],
            [6.710238, 47.875768],
            [6.730438, 47.867533],
            [6.737772, 47.861561],
            [6.76385, 47.857308],
            [6.764465, 47.853457],
            [6.784819, 47.84962],
            [6.792547, 47.840497],
            [6.788428, 47.834484],
            [6.823533, 47.813051],
            [6.83931, 47.824784],
            [6.846176, 47.822943],
            [6.842829, 47.812907],
            [6.863473, 47.785157],
            [6.902086, 47.776444],
            [6.909337, 47.777601],
            [6.923998, 47.77047],
            [6.942564, 47.768619],
            [6.969541, 47.75388],
            [6.98818, 47.747811],
            [7.015962, 47.74323],
            [7.019465, 47.736016],
            [7.037422, 47.721642],
            [7.026486, 47.701001],
            [7.037635, 47.694694],
            [7.0388, 47.687529],
            [7.048046, 47.682334],
            [7.038947, 47.677982],
            [7.046317, 47.671502],
            [7.034431, 47.661171],
            [7.039192, 47.650571],
            [7.018866, 47.650671],
            [7.018036, 47.642567],
            [7.004979, 47.619379],
            [7.010507, 47.60502],
            [7.005955, 47.602446],
            [7.025421, 47.59268],
            [7.040519, 47.60096],
            [7.056507, 47.598941],
            [7.064716, 47.601059],
            [7.077239, 47.598071],
            [7.086272, 47.592565],
            [7.086336, 47.585556],
            [7.094166, 47.573634],
            [7.100244, 47.572349],
            [7.108418, 47.563027],
            [7.106217, 47.551378],
            [7.119203, 47.547271],
            [7.13278, 47.53965],
            [7.139802, 47.526067],
            [7.137873, 47.510602],
            [7.130341, 47.503029],
            [7.111061, 47.494904],
            [7.091709, 47.494844],
            [7.079277, 47.488865],
            [7.061586, 47.494747],
            [7.036566, 47.497499],
            [7.024394, 47.50421],
            [7.000203, 47.499397],
            [6.983116, 47.493302],
            [6.988686, 47.486681],
            [6.988083, 47.474451],
            [6.991767, 47.466419],
            [7.0003, 47.462967],
            [6.998968, 47.452298],
            [6.989063, 47.44765],
            [6.970002, 47.446884],
            [6.966367, 47.437928],
            [6.957359, 47.433603],
            [6.940542, 47.433372],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5385",
      properties: {
        name: "Bretagne",
        density: 0,
        path: "/world/France/Bretagne",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.123706, 48.604406],
              [-2.134482, 48.611643],
              [-2.138709, 48.623233],
              [-2.150325, 48.627053],
              [-2.133496, 48.637088],
              [-2.126512, 48.63493],
              [-2.115146, 48.640575],
              [-2.09479, 48.6336],
              [-2.081216, 48.636114],
              [-2.078079, 48.640607],
              [-2.048692, 48.636979],
              [-2.055098, 48.627308],
              [-2.047901, 48.624253],
              [-2.030266, 48.62304],
              [-2.033553, 48.609451],
              [-2.01749, 48.594981],
              [-2.013514, 48.587519],
              [-2.005239, 48.588372],
              [-1.994669, 48.578424],
              [-2.011766, 48.571459],
              [-2.006897, 48.56611],
              [-1.99371, 48.559544],
              [-1.988186, 48.545116],
              [-1.97545, 48.543657],
              [-1.972351, 48.534638],
              [-1.987034, 48.525657],
              [-1.985759, 48.510759],
              [-1.969496, 48.517141],
              [-1.969331, 48.523179],
              [-1.9548, 48.522834],
              [-1.948034, 48.538808],
              [-1.95914, 48.536362],
              [-1.968941, 48.539138],
              [-1.961156, 48.550288],
              [-1.963072, 48.555935],
              [-1.974208, 48.557793],
              [-1.972575, 48.565573],
              [-1.981996, 48.573999],
              [-1.981283, 48.586179],
              [-1.959251, 48.576213],
              [-1.954186, 48.578971],
              [-1.967811, 48.592143],
              [-1.991948, 48.594107],
              [-2.011001, 48.604726],
              [-2.002394, 48.613056],
              [-2.015142, 48.613982],
              [-2.018312, 48.620077],
              [-2.013624, 48.62701],
              [-2.025986, 48.634438],
              [-2.020258, 48.63991],
              [-2.028956, 48.646918],
              [-1.995235, 48.660138],
              [-1.982338, 48.672879],
              [-1.984967, 48.681291],
              [-1.969831, 48.686324],
              [-1.956293, 48.680757],
              [-1.942551, 48.68572],
              [-1.938075, 48.694459],
              [-1.905838, 48.690485],
              [-1.890508, 48.698944],
              [-1.874583, 48.694894],
              [-1.856132, 48.704855],
              [-1.837138, 48.67916],
              [-1.862725, 48.667275],
              [-1.863355, 48.65942],
              [-1.871663, 48.647406],
              [-1.866622, 48.635297],
              [-1.856958, 48.624577],
              [-1.845069, 48.616365],
              [-1.822119, 48.610008],
              [-1.76415, 48.603559],
              [-1.701773, 48.607233],
              [-1.675154, 48.611423],
              [-1.661108, 48.610746],
              [-1.571089, 48.626441],
              [-1.565122, 48.614398],
              [-1.548486, 48.607366],
              [-1.539652, 48.59992],
              [-1.544011, 48.587389],
              [-1.542993, 48.580447],
              [-1.528642, 48.57918],
              [-1.518922, 48.56659],
              [-1.529171, 48.560523],
              [-1.533286, 48.548004],
              [-1.519564, 48.540018],
              [-1.512278, 48.524834],
              [-1.495641, 48.508899],
              [-1.489942, 48.489372],
              [-1.473186, 48.485386],
              [-1.467086, 48.488067],
              [-1.44926, 48.486102],
              [-1.436253, 48.478368],
              [-1.440353, 48.471141],
              [-1.429136, 48.462553],
              [-1.406484, 48.460604],
              [-1.396676, 48.462053],
              [-1.377246, 48.458283],
              [-1.346351, 48.471671],
              [-1.34504, 48.484912],
              [-1.339369, 48.49094],
              [-1.330285, 48.489792],
              [-1.326337, 48.498742],
              [-1.302566, 48.498857],
              [-1.288249, 48.50699],
              [-1.279203, 48.509166],
              [-1.269574, 48.524058],
              [-1.272248, 48.53392],
              [-1.254274, 48.543268],
              [-1.236321, 48.53808],
              [-1.215822, 48.538229],
              [-1.206552, 48.542206],
              [-1.188929, 48.528893],
              [-1.169907, 48.531104],
              [-1.156893, 48.521286],
              [-1.147077, 48.517499],
              [-1.130543, 48.521703],
              [-1.11609, 48.521233],
              [-1.096904, 48.512092],
              [-1.070164, 48.508492],
              [-1.075717, 48.499393],
              [-1.078296, 48.481155],
              [-1.074106, 48.473897],
              [-1.063965, 48.466954],
              [-1.065433, 48.451696],
              [-1.079364, 48.443272],
              [-1.082793, 48.433057],
              [-1.07792, 48.421477],
              [-1.078368, 48.413231],
              [-1.068121, 48.404717],
              [-1.053937, 48.383987],
              [-1.064629, 48.368281],
              [-1.059003, 48.358994],
              [-1.059892, 48.350533],
              [-1.055818, 48.340683],
              [-1.04502, 48.327729],
              [-1.059214, 48.312094],
              [-1.082092, 48.298392],
              [-1.093041, 48.281856],
              [-1.100055, 48.259278],
              [-1.086599, 48.22753],
              [-1.080604, 48.219498],
              [-1.087311, 48.209807],
              [-1.074774, 48.198201],
              [-1.079606, 48.183481],
              [-1.073888, 48.159662],
              [-1.060288, 48.150111],
              [-1.059142, 48.125078],
              [-1.052756, 48.107352],
              [-1.049611, 48.089801],
              [-1.040604, 48.07818],
              [-1.02329, 48.068912],
              [-1.033831, 48.052093],
              [-1.027797, 48.044864],
              [-1.033063, 48.031189],
              [-1.018208, 48.012308],
              [-1.016889, 48.003728],
              [-1.02126, 47.994939],
              [-1.045096, 47.987097],
              [-1.070966, 47.981801],
              [-1.09081, 47.987743],
              [-1.102678, 47.989064],
              [-1.122802, 47.986671],
              [-1.126075, 47.973308],
              [-1.134383, 47.969309],
              [-1.15399, 47.965817],
              [-1.161381, 47.95231],
              [-1.159515, 47.93922],
              [-1.16712, 47.934716],
              [-1.166314, 47.923578],
              [-1.175481, 47.910386],
              [-1.176224, 47.8974],
              [-1.196631, 47.889268],
              [-1.196966, 47.878939],
              [-1.189187, 47.867977],
              [-1.203023, 47.856844],
              [-1.216492, 47.857201],
              [-1.222737, 47.8526],
              [-1.213954, 47.844315],
              [-1.220636, 47.82039],
              [-1.232724, 47.820245],
              [-1.238248, 47.809993],
              [-1.245885, 47.776717],
              [-1.318487, 47.792334],
              [-1.352865, 47.797689],
              [-1.363166, 47.801684],
              [-1.377293, 47.812713],
              [-1.381345, 47.822669],
              [-1.390429, 47.828276],
              [-1.41785, 47.827487],
              [-1.424917, 47.832841],
              [-1.435427, 47.831152],
              [-1.462897, 47.833558],
              [-1.481813, 47.831894],
              [-1.466918, 47.80978],
              [-1.468444, 47.805903],
              [-1.492897, 47.79844],
              [-1.504239, 47.800948],
              [-1.520276, 47.79362],
              [-1.528061, 47.785843],
              [-1.551909, 47.784015],
              [-1.593405, 47.776049],
              [-1.598115, 47.766615],
              [-1.616354, 47.764155],
              [-1.626081, 47.756572],
              [-1.635568, 47.742658],
              [-1.639068, 47.73123],
              [-1.638181, 47.722311],
              [-1.645476, 47.721464],
              [-1.654817, 47.712589],
              [-1.664116, 47.711145],
              [-1.686342, 47.713034],
              [-1.705141, 47.709322],
              [-1.713383, 47.699308],
              [-1.72924, 47.69907],
              [-1.735492, 47.704031],
              [-1.754669, 47.706172],
              [-1.772418, 47.698454],
              [-1.80286, 47.702303],
              [-1.825053, 47.708269],
              [-1.841547, 47.705494],
              [-1.864016, 47.706981],
              [-1.880073, 47.695309],
              [-1.891836, 47.696328],
              [-1.936304, 47.686649],
              [-1.953816, 47.672268],
              [-1.969196, 47.677526],
              [-1.969059, 47.688369],
              [-1.974381, 47.693937],
              [-1.985223, 47.683233],
              [-2.009356, 47.671376],
              [-2.013316, 47.666003],
              [-2.035728, 47.668541],
              [-2.043095, 47.665697],
              [-2.050625, 47.651134],
              [-2.05791, 47.649484],
              [-2.074733, 47.651663],
              [-2.089884, 47.64271],
              [-2.097034, 47.631356],
              [-2.084995, 47.621229],
              [-2.086924, 47.602779],
              [-2.099269, 47.597488],
              [-2.103723, 47.589435],
              [-2.096507, 47.572369],
              [-2.103887, 47.54997],
              [-2.09683, 47.54033],
              [-2.098559, 47.533957],
              [-2.107329, 47.531054],
              [-2.156018, 47.522028],
              [-2.152957, 47.510963],
              [-2.154186, 47.496365],
              [-2.163676, 47.490501],
              [-2.183302, 47.491697],
              [-2.192164, 47.496644],
              [-2.183761, 47.501792],
              [-2.184623, 47.511939],
              [-2.206668, 47.510041],
              [-2.21997, 47.50542],
              [-2.244283, 47.493603],
              [-2.265638, 47.50178],
              [-2.25852, 47.504528],
              [-2.262969, 47.512911],
              [-2.280834, 47.509587],
              [-2.296514, 47.516059],
              [-2.299123, 47.500478],
              [-2.303671, 47.492411],
              [-2.313141, 47.485905],
              [-2.312864, 47.464471],
              [-2.32394, 47.4597],
              [-2.346365, 47.45785],
              [-2.354044, 47.45452],
              [-2.370969, 47.463384],
              [-2.382296, 47.462248],
              [-2.39039, 47.456872],
              [-2.399943, 47.455985],
              [-2.416826, 47.462052],
              [-2.423023, 47.477116],
              [-2.440082, 47.465781],
              [-2.453437, 47.462075],
              [-2.458493, 47.448123],
              [-2.465819, 47.449802],
              [-2.480425, 47.441963],
              [-2.490557, 47.446452],
              [-2.499136, 47.45754],
              [-2.488992, 47.468034],
              [-2.489561, 47.474318],
              [-2.501402, 47.490519],
              [-2.49372, 47.496719],
              [-2.481226, 47.495302],
              [-2.46745, 47.482627],
              [-2.456632, 47.486499],
              [-2.44122, 47.496238],
              [-2.454484, 47.506954],
              [-2.46615, 47.511698],
              [-2.500133, 47.517804],
              [-2.505162, 47.523097],
              [-2.517734, 47.526379],
              [-2.537128, 47.525677],
              [-2.544757, 47.518192],
              [-2.556172, 47.512845],
              [-2.57317, 47.516857],
              [-2.594865, 47.517693],
              [-2.605812, 47.515244],
              [-2.609494, 47.50977],
              [-2.621918, 47.504643],
              [-2.623885, 47.513043],
              [-2.611572, 47.514954],
              [-2.602231, 47.523493],
              [-2.586718, 47.52597],
              [-2.573593, 47.545948],
              [-2.559928, 47.551149],
              [-2.586852, 47.563523],
              [-2.58301, 47.544093],
              [-2.5951, 47.53386],
              [-2.604352, 47.531033],
              [-2.617457, 47.544578],
              [-2.619761, 47.55312],
              [-2.629361, 47.549477],
              [-2.620896, 47.54314],
              [-2.626011, 47.530694],
              [-2.650779, 47.537968],
              [-2.649045, 47.528714],
              [-2.632397, 47.529969],
              [-2.636296, 47.518311],
              [-2.664333, 47.518637],
              [-2.666363, 47.509807],
              [-2.681273, 47.495824],
              [-2.71551, 47.505557],
              [-2.738372, 47.503972],
              [-2.746459, 47.499179],
              [-2.768681, 47.497547],
              [-2.783917, 47.494171],
              [-2.800208, 47.487275],
              [-2.82025, 47.488552],
              [-2.849463, 47.498714],
              [-2.847322, 47.511543],
              [-2.871562, 47.53218],
              [-2.886752, 47.537761],
              [-2.905315, 47.538366],
              [-2.912751, 47.542221],
              [-2.910006, 47.552848],
              [-2.878525, 47.563823],
              [-2.875193, 47.554282],
              [-2.85846, 47.550661],
              [-2.83888, 47.54374],
              [-2.820997, 47.542598],
              [-2.813615, 47.552977],
              [-2.805323, 47.554652],
              [-2.798329, 47.547729],
              [-2.776366, 47.546378],
              [-2.780078, 47.539995],
              [-2.765683, 47.53635],
              [-2.730534, 47.542486],
              [-2.734209, 47.55206],
              [-2.72712, 47.565349],
              [-2.718402, 47.567486],
              [-2.713808, 47.579403],
              [-2.701951, 47.589923],
              [-2.69, 47.58963],
              [-2.689368, 47.605475],
              [-2.685818, 47.617255],
              [-2.692395, 47.626672],
              [-2.710187, 47.639251],
              [-2.715763, 47.632178],
              [-2.708783, 47.628877],
              [-2.706858, 47.619857],
              [-2.695619, 47.610972],
              [-2.713401, 47.594743],
              [-2.721059, 47.603107],
              [-2.739922, 47.603861],
              [-2.778576, 47.619154],
              [-2.775156, 47.625163],
              [-2.750712, 47.62191],
              [-2.757748, 47.632012],
              [-2.780577, 47.626366],
              [-2.78328, 47.62093],
              [-2.798025, 47.619354],
              [-2.811945, 47.622321],
              [-2.831264, 47.613981],
              [-2.851152, 47.619021],
              [-2.868376, 47.60705],
              [-2.865556, 47.597307],
              [-2.885191, 47.603862],
              [-2.891299, 47.600251],
              [-2.890208, 47.583759],
              [-2.895448, 47.581495],
              [-2.905587, 47.590412],
              [-2.914715, 47.591081],
              [-2.932096, 47.600389],
              [-2.939128, 47.59403],
              [-2.946865, 47.607516],
              [-2.949205, 47.6203],
              [-2.947218, 47.629098],
              [-2.958846, 47.627387],
              [-2.957893, 47.637888],
              [-2.976638, 47.658577],
              [-2.9821, 47.655172],
              [-2.971681, 47.645056],
              [-2.974453, 47.639664],
              [-2.963271, 47.631814],
              [-2.960707, 47.614638],
              [-2.965335, 47.60158],
              [-2.955176, 47.598618],
              [-2.964982, 47.588705],
              [-2.955952, 47.579652],
              [-2.947861, 47.579345],
              [-2.942811, 47.568757],
              [-2.928836, 47.554744],
              [-2.939091, 47.554097],
              [-2.951487, 47.558896],
              [-2.968429, 47.557176],
              [-2.975179, 47.576069],
              [-2.986272, 47.577093],
              [-3.004687, 47.566465],
              [-3.012322, 47.580153],
              [-3.022879, 47.578112],
              [-3.051973, 47.577936],
              [-3.061881, 47.572517],
              [-3.073862, 47.571102],
              [-3.094887, 47.564688],
              [-3.106577, 47.57444],
              [-3.103581, 47.583876],
              [-3.130412, 47.595994],
              [-3.129482, 47.574986],
              [-3.123256, 47.569508],
              [-3.128326, 47.561156],
              [-3.133359, 47.544058],
              [-3.13298, 47.532941],
              [-3.126568, 47.515572],
              [-3.120269, 47.510194],
              [-3.118788, 47.498762],
              [-3.109918, 47.490096],
              [-3.094502, 47.482412],
              [-3.099624, 47.472659],
              [-3.117805, 47.478792],
              [-3.131077, 47.473342],
              [-3.14381, 47.483494],
              [-3.15178, 47.503876],
              [-3.151083, 47.51324],
              [-3.157309, 47.527777],
              [-3.142442, 47.53335],
              [-3.134582, 47.548824],
              [-3.138394, 47.559328],
              [-3.139751, 47.579596],
              [-3.149441, 47.596336],
              [-3.158374, 47.606981],
              [-3.192656, 47.621875],
              [-3.20976, 47.640692],
              [-3.204835, 47.652288],
              [-3.208134, 47.663424],
              [-3.190509, 47.682544],
              [-3.162288, 47.680141],
              [-3.164454, 47.694638],
              [-3.143993, 47.706565],
              [-3.128061, 47.705557],
              [-3.131417, 47.712837],
              [-3.125967, 47.723461],
              [-3.149357, 47.721594],
              [-3.14935, 47.73734],
              [-3.161008, 47.735379],
              [-3.160076, 47.728977],
              [-3.174636, 47.723148],
              [-3.168781, 47.704128],
              [-3.194634, 47.703536],
              [-3.201003, 47.697372],
              [-3.190377, 47.69089],
              [-3.206528, 47.672945],
              [-3.218924, 47.666402],
              [-3.21093, 47.655063],
              [-3.214971, 47.645223],
              [-3.243257, 47.662982],
              [-3.270899, 47.678873],
              [-3.287863, 47.700956],
              [-3.295557, 47.702973],
              [-3.3111, 47.701543],
              [-3.317558, 47.705106],
              [-3.344224, 47.70836],
              [-3.354032, 47.703281],
              [-3.363938, 47.709063],
              [-3.342734, 47.719388],
              [-3.349079, 47.729313],
              [-3.336148, 47.728998],
              [-3.32158, 47.735253],
              [-3.313554, 47.746463],
              [-3.305731, 47.74833],
              [-3.297856, 47.768549],
              [-3.316764, 47.756496],
              [-3.323831, 47.747889],
              [-3.333042, 47.743689],
              [-3.351455, 47.744279],
              [-3.355826, 47.73241],
              [-3.367163, 47.725361],
              [-3.367567, 47.717947],
              [-3.38711, 47.701882],
              [-3.430439, 47.703212],
              [-3.452758, 47.695339],
              [-3.463858, 47.705833],
              [-3.479494, 47.714386],
              [-3.50022, 47.731474],
              [-3.505131, 47.745703],
              [-3.520965, 47.757668],
              [-3.530021, 47.774211],
              [-3.529934, 47.782468],
              [-3.517378, 47.805038],
              [-3.535914, 47.816437],
              [-3.538292, 47.836023],
              [-3.524008, 47.844903],
              [-3.523006, 47.849147],
              [-3.53965, 47.83618],
              [-3.541574, 47.82278],
              [-3.521246, 47.798868],
              [-3.530517, 47.791502],
              [-3.532306, 47.768279],
              [-3.538637, 47.762639],
              [-3.56253, 47.768194],
              [-3.579117, 47.767577],
              [-3.617328, 47.769507],
              [-3.643052, 47.774569],
              [-3.646812, 47.778818],
              [-3.6701, 47.782985],
              [-3.680838, 47.776793],
              [-3.717823, 47.796524],
              [-3.721803, 47.803803],
              [-3.732548, 47.802927],
              [-3.761373, 47.790506],
              [-3.788241, 47.791328],
              [-3.800595, 47.787634],
              [-3.820699, 47.797035],
              [-3.837052, 47.797207],
              [-3.845726, 47.792372],
              [-3.853685, 47.80318],
              [-3.865372, 47.809482],
              [-3.892344, 47.831814],
              [-3.902479, 47.835941],
              [-3.895333, 47.851457],
              [-3.905929, 47.856054],
              [-3.911501, 47.87028],
              [-3.929185, 47.870729],
              [-3.931815, 47.87993],
              [-3.947495, 47.887111],
              [-3.952255, 47.894974],
              [-3.972707, 47.897113],
              [-3.990027, 47.882735],
              [-3.983508, 47.860854],
              [-3.977108, 47.853797],
              [-4.025559, 47.850395],
              [-4.041584, 47.846],
              [-4.055218, 47.85829],
              [-4.077723, 47.866529],
              [-4.097342, 47.861927],
              [-4.103336, 47.870935],
              [-4.117513, 47.876648],
              [-4.115344, 47.884957],
              [-4.124092, 47.887654],
              [-4.135014, 47.89932],
              [-4.141318, 47.900933],
              [-4.144304, 47.91076],
              [-4.139164, 47.922461],
              [-4.126548, 47.930695],
              [-4.118171, 47.930256],
              [-4.109787, 47.936359],
              [-4.10951, 47.949458],
              [-4.100324, 47.951229],
              [-4.094295, 47.962344],
              [-4.101636, 47.971599],
              [-4.115459, 47.951972],
              [-4.110804, 47.936438],
              [-4.11694, 47.931723],
              [-4.130849, 47.931269],
              [-4.138727, 47.924044],
              [-4.148591, 47.911785],
              [-4.145611, 47.897378],
              [-4.137658, 47.894583],
              [-4.12144, 47.882431],
              [-4.122041, 47.877481],
              [-4.112806, 47.864208],
              [-4.126045, 47.864174],
              [-4.138451, 47.860372],
              [-4.163556, 47.849183],
              [-4.175105, 47.875971],
              [-4.195648, 47.87613],
              [-4.191726, 47.867927],
              [-4.198584, 47.859767],
              [-4.189368, 47.851395],
              [-4.177964, 47.834481],
              [-4.169637, 47.835984],
              [-4.159408, 47.831818],
              [-4.166527, 47.815627],
              [-4.174796, 47.80772],
              [-4.192305, 47.797376],
              [-4.210283, 47.801668],
              [-4.216594, 47.793145],
              [-4.232466, 47.793694],
              [-4.269227, 47.790981],
              [-4.278552, 47.793791],
              [-4.290869, 47.792683],
              [-4.298542, 47.800459],
              [-4.312195, 47.800898],
              [-4.3236, 47.797855],
              [-4.342249, 47.799674],
              [-4.362414, 47.795739],
              [-4.374048, 47.798476],
              [-4.371611, 47.807779],
              [-4.379256, 47.822051],
              [-4.349804, 47.830787],
              [-4.346161, 47.839074],
              [-4.347687, 47.849005],
              [-4.360058, 47.879146],
              [-4.362572, 47.889267],
              [-4.379033, 47.914085],
              [-4.395188, 47.93441],
              [-4.409852, 47.946651],
              [-4.42461, 47.963759],
              [-4.453577, 47.981778],
              [-4.474679, 47.985625],
              [-4.482665, 47.993824],
              [-4.498078, 48.001183],
              [-4.514854, 48.006068],
              [-4.525615, 48.006068],
              [-4.53688, 48.012492],
              [-4.550096, 48.011713],
              [-4.56475, 47.999836],
              [-4.590735, 48.006794],
              [-4.597265, 48.014882],
              [-4.617148, 48.016679],
              [-4.632959, 48.029369],
              [-4.646082, 48.023304],
              [-4.699789, 48.027892],
              [-4.710595, 48.0332],
              [-4.723034, 48.0336],
              [-4.731958, 48.040893],
              [-4.712464, 48.042115],
              [-4.706855, 48.049993],
              [-4.71634, 48.062467],
              [-4.673763, 48.06083],
              [-4.668228, 48.070201],
              [-4.632986, 48.067657],
              [-4.621255, 48.06857],
              [-4.605557, 48.075145],
              [-4.590653, 48.077103],
              [-4.580147, 48.081908],
              [-4.553622, 48.077133],
              [-4.545646, 48.07846],
              [-4.54004, 48.087861],
              [-4.514476, 48.088899],
              [-4.488598, 48.086473],
              [-4.466698, 48.099144],
              [-4.456986, 48.096459],
              [-4.435068, 48.097323],
              [-4.41025, 48.106022],
              [-4.393609, 48.104453],
              [-4.374396, 48.110159],
              [-4.36356, 48.1092],
              [-4.354948, 48.103716],
              [-4.306993, 48.089186],
              [-4.296934, 48.092193],
              [-4.284297, 48.107889],
              [-4.281539, 48.115547],
              [-4.283729, 48.126894],
              [-4.271733, 48.133054],
              [-4.27308, 48.154184],
              [-4.292838, 48.157399],
              [-4.296245, 48.165147],
              [-4.292063, 48.176348],
              [-4.301125, 48.191447],
              [-4.315401, 48.20159],
              [-4.332025, 48.206252],
              [-4.367427, 48.205181],
              [-4.376415, 48.217121],
              [-4.411237, 48.222749],
              [-4.425516, 48.229011],
              [-4.431744, 48.228336],
              [-4.448357, 48.235634],
              [-4.489029, 48.236209],
              [-4.501648, 48.23042],
              [-4.497301, 48.216774],
              [-4.510751, 48.211454],
              [-4.519341, 48.192342],
              [-4.540171, 48.18069],
              [-4.539679, 48.170249],
              [-4.55413, 48.167776],
              [-4.553916, 48.178981],
              [-4.549647, 48.19426],
              [-4.553452, 48.211909],
              [-4.564029, 48.232228],
              [-4.554237, 48.239437],
              [-4.544509, 48.241191],
              [-4.543845, 48.248685],
              [-4.558183, 48.259044],
              [-4.563762, 48.255323],
              [-4.583579, 48.252477],
              [-4.619782, 48.263922],
              [-4.615175, 48.272609],
              [-4.619191, 48.277687],
              [-4.601035, 48.283329],
              [-4.592026, 48.274942],
              [-4.58051, 48.276822],
              [-4.566609, 48.28634],
              [-4.572232, 48.298195],
              [-4.571003, 48.305488],
              [-4.579354, 48.317985],
              [-4.571137, 48.330049],
              [-4.55541, 48.338273],
              [-4.534438, 48.341856],
              [-4.532399, 48.339322],
              [-4.545328, 48.324531],
              [-4.548356, 48.309092],
              [-4.556272, 48.299756],
              [-4.55269, 48.294333],
              [-4.540964, 48.290219],
              [-4.53577, 48.283943],
              [-4.521795, 48.291114],
              [-4.507696, 48.310709],
              [-4.501046, 48.306106],
              [-4.512999, 48.29707],
              [-4.508766, 48.284365],
              [-4.502602, 48.280861],
              [-4.483559, 48.284045],
              [-4.455052, 48.292772],
              [-4.423777, 48.29167],
              [-4.412122, 48.277432],
              [-4.40685, 48.275442],
              [-4.384494, 48.275154],
              [-4.366084, 48.278011],
              [-4.353667, 48.285793],
              [-4.338012, 48.285592],
              [-4.308034, 48.297163],
              [-4.271675, 48.29592],
              [-4.273935, 48.288971],
              [-4.284541, 48.28765],
              [-4.284857, 48.275914],
              [-4.259585, 48.266202],
              [-4.245929, 48.25078],
              [-4.231754, 48.250321],
              [-4.217293, 48.257615],
              [-4.20802, 48.242451],
              [-4.186661, 48.246536],
              [-4.197034, 48.251108],
              [-4.207983, 48.251025],
              [-4.216717, 48.260996],
              [-4.240657, 48.254406],
              [-4.248442, 48.264632],
              [-4.279434, 48.277816],
              [-4.258664, 48.280338],
              [-4.233983, 48.291663],
              [-4.225592, 48.287776],
              [-4.194078, 48.293672],
              [-4.192791, 48.297567],
              [-4.223091, 48.296296],
              [-4.260759, 48.308809],
              [-4.271777, 48.308501],
              [-4.281731, 48.314149],
              [-4.293198, 48.311356],
              [-4.306532, 48.314942],
              [-4.331179, 48.31462],
              [-4.309804, 48.32739],
              [-4.305472, 48.333013],
              [-4.290948, 48.341953],
              [-4.278, 48.344875],
              [-4.297674, 48.355911],
              [-4.305074, 48.354482],
              [-4.302157, 48.343775],
              [-4.329398, 48.340226],
              [-4.335349, 48.351019],
              [-4.344618, 48.343247],
              [-4.366486, 48.3448],
              [-4.367844, 48.334697],
              [-4.375252, 48.326306],
              [-4.404674, 48.323603],
              [-4.416317, 48.324328],
              [-4.421281, 48.332856],
              [-4.447459, 48.325681],
              [-4.449627, 48.337161],
              [-4.439548, 48.34128],
              [-4.441757, 48.353615],
              [-4.429027, 48.36562],
              [-4.418609, 48.368727],
              [-4.407525, 48.380534],
              [-4.39613, 48.386502],
              [-4.368041, 48.394899],
              [-4.359324, 48.402145],
              [-4.342846, 48.403365],
              [-4.30935, 48.419404],
              [-4.281973, 48.434393],
              [-4.287872, 48.436421],
              [-4.309469, 48.423597],
              [-4.338182, 48.414949],
              [-4.345948, 48.409663],
              [-4.368601, 48.406643],
              [-4.36886, 48.401485],
              [-4.386795, 48.397755],
              [-4.402944, 48.390019],
              [-4.424947, 48.3976],
              [-4.433354, 48.396594],
              [-4.435286, 48.383389],
              [-4.442753, 48.381948],
              [-4.456356, 48.387016],
              [-4.467286, 48.38101],
              [-4.481871, 48.382521],
              [-4.507028, 48.375177],
              [-4.538017, 48.357125],
              [-4.549669, 48.362001],
              [-4.556902, 48.356968],
              [-4.594938, 48.343982],
              [-4.608724, 48.337888],
              [-4.628101, 48.337509],
              [-4.63925, 48.344933],
              [-4.654239, 48.346681],
              [-4.667175, 48.352722],
              [-4.680557, 48.355611],
              [-4.69947, 48.350825],
              [-4.703186, 48.345439],
              [-4.69842, 48.339009],
              [-4.707608, 48.332259],
              [-4.726828, 48.330102],
              [-4.741265, 48.331137],
              [-4.755152, 48.328315],
              [-4.772305, 48.329179],
              [-4.771335, 48.348321],
              [-4.780755, 48.358364],
              [-4.760916, 48.372753],
              [-4.774454, 48.389385],
              [-4.773684, 48.404923],
              [-4.785613, 48.409184],
              [-4.793343, 48.416236],
              [-4.782969, 48.43755],
              [-4.776289, 48.44323],
              [-4.778303, 48.449337],
              [-4.769182, 48.462065],
              [-4.759058, 48.470398],
              [-4.775478, 48.491001],
              [-4.776998, 48.503117],
              [-4.766953, 48.50895],
              [-4.767751, 48.515491],
              [-4.75799, 48.520935],
              [-4.763583, 48.53106],
              [-4.750431, 48.531788],
              [-4.750572, 48.543396],
              [-4.73694, 48.54967],
              [-4.730581, 48.556346],
              [-4.707325, 48.554495],
              [-4.70061, 48.565257],
              [-4.689733, 48.571618],
              [-4.675161, 48.56864],
              [-4.646423, 48.572119],
              [-4.630607, 48.577693],
              [-4.596081, 48.570438],
              [-4.592029, 48.556967],
              [-4.580183, 48.562246],
              [-4.594088, 48.57467],
              [-4.609161, 48.575418],
              [-4.60367, 48.582979],
              [-4.60766, 48.596462],
              [-4.598316, 48.608011],
              [-4.58562, 48.601473],
              [-4.590777, 48.594024],
              [-4.562772, 48.59817],
              [-4.556517, 48.594799],
              [-4.545504, 48.597833],
              [-4.550782, 48.604094],
              [-4.56087, 48.603771],
              [-4.568699, 48.611036],
              [-4.561479, 48.623801],
              [-4.550343, 48.625019],
              [-4.539549, 48.631188],
              [-4.526159, 48.629376],
              [-4.521797, 48.634384],
              [-4.501804, 48.621186],
              [-4.493119, 48.625415],
              [-4.478229, 48.623558],
              [-4.462769, 48.627101],
              [-4.446944, 48.638394],
              [-4.415, 48.637926],
              [-4.427103, 48.652261],
              [-4.398044, 48.655615],
              [-4.380143, 48.661667],
              [-4.351403, 48.676113],
              [-4.330327, 48.675462],
              [-4.329112, 48.667908],
              [-4.310239, 48.667932],
              [-4.295404, 48.660447],
              [-4.316069, 48.640189],
              [-4.311589, 48.635173],
              [-4.298511, 48.632641],
              [-4.272766, 48.64958],
              [-4.246494, 48.64971],
              [-4.233386, 48.652098],
              [-4.223644, 48.648214],
              [-4.194501, 48.652176],
              [-4.198786, 48.655718],
              [-4.220228, 48.656147],
              [-4.209607, 48.670448],
              [-4.196816, 48.677628],
              [-4.186934, 48.686462],
              [-4.174061, 48.686025],
              [-4.162419, 48.689758],
              [-4.127037, 48.694927],
              [-4.083798, 48.692973],
              [-4.070902, 48.684671],
              [-4.057588, 48.689259],
              [-4.056795, 48.700628],
              [-4.042916, 48.702653],
              [-4.035389, 48.713929],
              [-4.024955, 48.71063],
              [-4.012482, 48.714491],
              [-4.000536, 48.711632],
              [-3.983437, 48.726305],
              [-3.966808, 48.719625],
              [-3.973524, 48.710025],
              [-3.975743, 48.699032],
              [-3.9682, 48.689974],
              [-3.969158, 48.676108],
              [-3.953138, 48.672137],
              [-3.949466, 48.652861],
              [-3.930067, 48.657839],
              [-3.921181, 48.674928],
              [-3.911435, 48.66996],
              [-3.891111, 48.670896],
              [-3.892573, 48.666175],
              [-3.906197, 48.660823],
              [-3.89771, 48.646356],
              [-3.889568, 48.641662],
              [-3.860796, 48.632587],
              [-3.856665, 48.62063],
              [-3.845545, 48.626965],
              [-3.856272, 48.650756],
              [-3.856433, 48.660929],
              [-3.864415, 48.670331],
              [-3.84664, 48.673109],
              [-3.854474, 48.686374],
              [-3.848261, 48.695947],
              [-3.832374, 48.711936],
              [-3.820772, 48.701352],
              [-3.818937, 48.71517],
              [-3.805611, 48.71102],
              [-3.787493, 48.701796],
              [-3.776109, 48.702862],
              [-3.765708, 48.708879],
              [-3.733247, 48.707418],
              [-3.716983, 48.702516],
              [-3.702428, 48.690385],
              [-3.68847, 48.691672],
              [-3.678008, 48.686813],
              [-3.660939, 48.694072],
              [-3.645168, 48.690711],
              [-3.654788, 48.6824],
              [-3.642795, 48.672116],
              [-3.655458, 48.664724],
              [-3.659151, 48.659209],
              [-3.642087, 48.669628],
              [-3.636403, 48.681877],
              [-3.622975, 48.685744],
              [-3.616067, 48.682135],
              [-3.613437, 48.672125],
              [-3.601347, 48.668882],
              [-3.581252, 48.670015],
              [-3.567389, 48.682876],
              [-3.582129, 48.688829],
              [-3.580618, 48.699099],
              [-3.584814, 48.717143],
              [-3.577734, 48.722923],
              [-3.553507, 48.7296],
              [-3.549404, 48.745686],
              [-3.566024, 48.760471],
              [-3.578071, 48.763053],
              [-3.585013, 48.775497],
              [-3.567049, 48.796072],
              [-3.554436, 48.791566],
              [-3.535039, 48.802711],
              [-3.54523, 48.811548],
              [-3.535353, 48.824209],
              [-3.513204, 48.834999],
              [-3.510219, 48.827666],
              [-3.494729, 48.82891],
              [-3.487057, 48.835211],
              [-3.476763, 48.836553],
              [-3.468294, 48.82372],
              [-3.453425, 48.81573],
              [-3.438779, 48.820131],
              [-3.428248, 48.816468],
              [-3.442888, 48.80323],
              [-3.430731, 48.797224],
              [-3.397552, 48.80075],
              [-3.387219, 48.805277],
              [-3.386091, 48.815073],
              [-3.357023, 48.819691],
              [-3.33539, 48.828434],
              [-3.319428, 48.838251],
              [-3.291876, 48.831923],
              [-3.280881, 48.842071],
              [-3.264335, 48.834201],
              [-3.2566, 48.846756],
              [-3.24603, 48.856724],
              [-3.234798, 48.86198],
              [-3.231756, 48.867412],
              [-3.219726, 48.86646],
              [-3.210974, 48.85476],
              [-3.213491, 48.839397],
              [-3.202827, 48.834507],
              [-3.208228, 48.813122],
              [-3.196739, 48.818393],
              [-3.198161, 48.826345],
              [-3.182762, 48.842558],
              [-3.173803, 48.841865],
              [-3.167944, 48.853146],
              [-3.158146, 48.851636],
              [-3.134488, 48.858232],
              [-3.129837, 48.864364],
              [-3.113974, 48.86674],
              [-3.084001, 48.865696],
              [-3.093491, 48.858795],
              [-3.085149, 48.849385],
              [-3.089146, 48.832183],
              [-3.079151, 48.830044],
              [-3.092103, 48.80898],
              [-3.098384, 48.804283],
              [-3.097356, 48.794284],
              [-3.10178, 48.783225],
              [-3.119793, 48.773596],
              [-3.121398, 48.76527],
              [-3.099637, 48.768296],
              [-3.093496, 48.791936],
              [-3.08547, 48.810359],
              [-3.070432, 48.8209],
              [-3.062064, 48.822425],
              [-3.052357, 48.815397],
              [-3.038639, 48.819469],
              [-3.01363, 48.822138],
              [-3.007949, 48.818123],
              [-3.016782, 48.812113],
              [-3.00614, 48.800293],
              [-3.020841, 48.797138],
              [-3.024997, 48.791446],
              [-3.041653, 48.789014],
              [-3.043641, 48.783351],
              [-3.023993, 48.783128],
              [-3.029395, 48.775914],
              [-3.015917, 48.767132],
              [-2.989506, 48.762639],
              [-2.964541, 48.762495],
              [-2.955426, 48.76919],
              [-2.943655, 48.758338],
              [-2.928635, 48.754427],
              [-2.941645, 48.745309],
              [-2.934163, 48.737092],
              [-2.947908, 48.727211],
              [-2.941742, 48.719207],
              [-2.932092, 48.717492],
              [-2.92476, 48.707466],
              [-2.889615, 48.696373],
              [-2.880089, 48.674271],
              [-2.858244, 48.672308],
              [-2.841321, 48.663352],
              [-2.837764, 48.65602],
              [-2.82693, 48.650565],
              [-2.824158, 48.634957],
              [-2.815384, 48.6099],
              [-2.825156, 48.600304],
              [-2.819603, 48.593528],
              [-2.806596, 48.591905],
              [-2.797963, 48.586577],
              [-2.779312, 48.58498],
              [-2.772682, 48.570547],
              [-2.749549, 48.562166],
              [-2.717974, 48.555376],
              [-2.724792, 48.546914],
              [-2.714565, 48.529029],
              [-2.698437, 48.505684],
              [-2.684782, 48.498049],
              [-2.67887, 48.50394],
              [-2.676522, 48.519146],
              [-2.68156, 48.531129],
              [-2.668467, 48.534819],
              [-2.657986, 48.526362],
              [-2.642926, 48.523602],
              [-2.630281, 48.526089],
              [-2.631266, 48.538126],
              [-2.605558, 48.551823],
              [-2.568675, 48.57856],
              [-2.550274, 48.598731],
              [-2.531518, 48.597243],
              [-2.496651, 48.608236],
              [-2.473431, 48.62252],
              [-2.46801, 48.635312],
              [-2.480218, 48.63714],
              [-2.468006, 48.649584],
              [-2.453352, 48.647604],
              [-2.43563, 48.652183],
              [-2.412173, 48.641491],
              [-2.396321, 48.642746],
              [-2.385197, 48.652775],
              [-2.356643, 48.658128],
              [-2.329818, 48.672434],
              [-2.326835, 48.68269],
              [-2.312861, 48.680925],
              [-2.309555, 48.670249],
              [-2.287309, 48.668049],
              [-2.29863, 48.650777],
              [-2.308235, 48.645116],
              [-2.311635, 48.634573],
              [-2.337838, 48.620071],
              [-2.309375, 48.615046],
              [-2.299891, 48.624057],
              [-2.287345, 48.627321],
              [-2.284515, 48.632393],
              [-2.259073, 48.644952],
              [-2.255747, 48.636213],
              [-2.243355, 48.623384],
              [-2.24575, 48.615404],
              [-2.228603, 48.607719],
              [-2.223953, 48.595382],
              [-2.212802, 48.591598],
              [-2.201779, 48.583791],
              [-2.191553, 48.592304],
              [-2.194715, 48.599921],
              [-2.182119, 48.599175],
              [-2.187757, 48.587191],
              [-2.186746, 48.581332],
              [-2.173476, 48.577917],
              [-2.164265, 48.578893],
              [-2.156707, 48.587698],
              [-2.169568, 48.596655],
              [-2.165804, 48.604235],
              [-2.14286, 48.611174],
              [-2.123706, 48.604406],
            ],
          ],
          [
            [
              [-3.002321, 48.836321],
              [-3.011495, 48.841507],
              [-3.013089, 48.85642],
              [-2.994139, 48.864343],
              [-2.994471, 48.847149],
              [-3.002321, 48.836321],
            ],
          ],
          [
            [
              [-3.583877, 48.794095],
              [-3.583649, 48.80475],
              [-3.565774, 48.807749],
              [-3.57013, 48.797239],
              [-3.583877, 48.794095],
            ],
          ],
          [
            [
              [-5.072523, 48.48273],
              [-5.056629, 48.476825],
              [-5.056973, 48.468162],
              [-5.040348, 48.465114],
              [-5.058232, 48.450957],
              [-5.082401, 48.448345],
              [-5.092587, 48.439891],
              [-5.105912, 48.43635],
              [-5.109192, 48.443357],
              [-5.098058, 48.446084],
              [-5.092475, 48.451963],
              [-5.101687, 48.455914],
              [-5.138001, 48.448414],
              [-5.133165, 48.457206],
              [-5.11136, 48.465175],
              [-5.103596, 48.472329],
              [-5.084028, 48.472703],
              [-5.071921, 48.478208],
              [-5.072523, 48.48273],
            ],
          ],
          [
            [
              [-3.991811, 48.7364],
              [-4.009012, 48.742839],
              [-4.022169, 48.738492],
              [-4.039777, 48.746527],
              [-4.02185, 48.752309],
              [-4.006229, 48.752082],
              [-3.98991, 48.742602],
              [-3.991811, 48.7364],
            ],
          ],
          [
            [
              [-2.882836, 47.342634],
              [-2.868824, 47.347187],
              [-2.864023, 47.337397],
              [-2.885198, 47.332205],
              [-2.882836, 47.342634],
            ],
          ],
          [
            [
              [-3.421793, 47.619998],
              [-3.440678, 47.627447],
              [-3.445627, 47.623593],
              [-3.461914, 47.620346],
              [-3.474247, 47.626988],
              [-3.485205, 47.628379],
              [-3.507632, 47.640599],
              [-3.513272, 47.650913],
              [-3.496941, 47.653808],
              [-3.444295, 47.6435],
              [-3.429485, 47.642297],
              [-3.416339, 47.630594],
              [-3.421793, 47.619998],
            ],
          ],
          [
            [
              [-2.948149, 47.373337],
              [-2.961233, 47.384143],
              [-2.973497, 47.385337],
              [-2.970456, 47.393457],
              [-2.949747, 47.391475],
              [-2.95395, 47.383664],
              [-2.948149, 47.373337],
            ],
          ],
          [
            [
              [-3.23544, 47.323814],
              [-3.248053, 47.330283],
              [-3.263557, 47.352257],
              [-3.259214, 47.357022],
              [-3.261822, 47.371275],
              [-3.248771, 47.379576],
              [-3.244701, 47.387618],
              [-3.234076, 47.379338],
              [-3.217584, 47.378419],
              [-3.216353, 47.373609],
              [-3.201275, 47.372214],
              [-3.193519, 47.365912],
              [-3.173008, 47.361685],
              [-3.15586, 47.361081],
              [-3.153488, 47.348344],
              [-3.14517, 47.342292],
              [-3.139647, 47.32984],
              [-3.115051, 47.324061],
              [-3.111024, 47.319022],
              [-3.093125, 47.315131],
              [-3.074124, 47.317262],
              [-3.06335, 47.315039],
              [-3.067862, 47.29675],
              [-3.075923, 47.286048],
              [-3.086552, 47.283069],
              [-3.105896, 47.284999],
              [-3.122895, 47.290553],
              [-3.136216, 47.287983],
              [-3.151259, 47.293806],
              [-3.176273, 47.300368],
              [-3.185663, 47.295185],
              [-3.202141, 47.296876],
              [-3.217195, 47.29347],
              [-3.228449, 47.301291],
              [-3.238701, 47.303637],
              [-3.24909, 47.31612],
              [-3.23544, 47.323814],
            ],
          ],
          [
            [
              [-2.859562, 47.559896],
              [-2.861581, 47.568279],
              [-2.853851, 47.594135],
              [-2.84475, 47.601282],
              [-2.837912, 47.591917],
              [-2.850183, 47.586592],
              [-2.85426, 47.575142],
              [-2.853354, 47.567483],
              [-2.859562, 47.559896],
            ],
          ],
          [
            [
              [-2.809605, 47.578258],
              [-2.809095, 47.592252],
              [-2.79399, 47.601732],
              [-2.786811, 47.595356],
              [-2.799409, 47.588535],
              [-2.809605, 47.578258],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5379",
      properties: {
        name: "Centre-Val de Loire",
        density: 0,
        path: "/world/France/Centre-Val de Loire",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.814825, 48.670164],
            [0.827671, 48.68072],
            [0.850363, 48.682948],
            [0.861498, 48.691008],
            [0.876711, 48.715496],
            [0.888368, 48.718921],
            [0.9024, 48.710649],
            [0.921199, 48.709175],
            [0.955242, 48.716786],
            [0.961444, 48.725722],
            [0.977294, 48.730139],
            [0.984415, 48.725995],
            [1.014064, 48.7281],
            [1.020608, 48.734789],
            [1.03165, 48.730582],
            [1.039803, 48.742155],
            [1.059071, 48.756765],
            [1.068665, 48.756914],
            [1.075781, 48.749438],
            [1.086, 48.756926],
            [1.095726, 48.748713],
            [1.112658, 48.751864],
            [1.120984, 48.76725],
            [1.118984, 48.782375],
            [1.121396, 48.789196],
            [1.152115, 48.78556],
            [1.153304, 48.774016],
            [1.161543, 48.769293],
            [1.184736, 48.77263],
            [1.198145, 48.769569],
            [1.223527, 48.758057],
            [1.222616, 48.767292],
            [1.246024, 48.769676],
            [1.256264, 48.765104],
            [1.254547, 48.758629],
            [1.270213, 48.757483],
            [1.279985, 48.762674],
            [1.300297, 48.767537],
            [1.319544, 48.760961],
            [1.332905, 48.762612],
            [1.342232, 48.772542],
            [1.362664, 48.781664],
            [1.374426, 48.782614],
            [1.376963, 48.791766],
            [1.359248, 48.813609],
            [1.362182, 48.83418],
            [1.39056, 48.846606],
            [1.396857, 48.855384],
            [1.409366, 48.861153],
            [1.423893, 48.861268],
            [1.434311, 48.86614],
            [1.454994, 48.870326],
            [1.465363, 48.877601],
            [1.468355, 48.892216],
            [1.46067, 48.900495],
            [1.459326, 48.914649],
            [1.447959, 48.92464],
            [1.459411, 48.928927],
            [1.461311, 48.937555],
            [1.480648, 48.94049],
            [1.501527, 48.941052],
            [1.509014, 48.936627],
            [1.512895, 48.922585],
            [1.524102, 48.924752],
            [1.538618, 48.921795],
            [1.541797, 48.91548],
            [1.538524, 48.906686],
            [1.557253, 48.891576],
            [1.559508, 48.882055],
            [1.546233, 48.872432],
            [1.555213, 48.865076],
            [1.56664, 48.866184],
            [1.578039, 48.860864],
            [1.581597, 48.855],
            [1.577273, 48.844358],
            [1.595192, 48.839707],
            [1.594182, 48.834219],
            [1.583728, 48.832131],
            [1.591206, 48.814867],
            [1.583424, 48.807169],
            [1.575674, 48.790504],
            [1.580399, 48.777559],
            [1.587597, 48.773606],
            [1.584905, 48.763297],
            [1.602688, 48.760487],
            [1.626404, 48.748092],
            [1.617805, 48.735942],
            [1.589538, 48.712468],
            [1.594881, 48.709311],
            [1.57954, 48.701812],
            [1.582315, 48.696368],
            [1.611775, 48.689474],
            [1.605863, 48.678763],
            [1.602492, 48.663765],
            [1.615616, 48.652789],
            [1.629848, 48.648875],
            [1.643191, 48.651291],
            [1.64099, 48.64339],
            [1.651572, 48.638125],
            [1.649561, 48.632271],
            [1.658233, 48.627646],
            [1.656339, 48.622053],
            [1.666223, 48.613704],
            [1.679359, 48.618489],
            [1.689422, 48.611536],
            [1.714898, 48.614392],
            [1.71786, 48.606851],
            [1.701984, 48.584999],
            [1.709249, 48.578026],
            [1.73683, 48.572281],
            [1.745863, 48.576137],
            [1.765323, 48.569375],
            [1.76741, 48.559406],
            [1.787242, 48.553746],
            [1.775754, 48.527703],
            [1.778617, 48.508132],
            [1.790635, 48.497377],
            [1.785709, 48.489937],
            [1.796331, 48.484188],
            [1.801451, 48.466086],
            [1.836384, 48.466478],
            [1.844863, 48.449361],
            [1.877489, 48.440614],
            [1.906408, 48.440147],
            [1.906555, 48.445672],
            [1.920738, 48.44775],
            [1.922146, 48.457599],
            [1.930423, 48.453403],
            [1.933125, 48.442264],
            [1.942897, 48.441084],
            [1.937807, 48.42639],
            [1.925889, 48.412742],
            [1.930561, 48.404127],
            [1.94277, 48.405651],
            [1.961954, 48.404321],
            [1.976571, 48.399385],
            [1.966382, 48.381588],
            [1.976311, 48.380271],
            [1.986824, 48.362141],
            [1.973395, 48.352975],
            [1.974947, 48.335258],
            [1.982343, 48.328313],
            [1.974492, 48.323164],
            [1.969441, 48.314232],
            [1.959226, 48.308685],
            [1.96661, 48.295582],
            [1.975292, 48.287202],
            [1.99409, 48.286584],
            [2.007238, 48.284689],
            [2.022845, 48.28808],
            [2.049377, 48.289753],
            [2.052714, 48.29547],
            [2.081505, 48.293604],
            [2.106168, 48.307636],
            [2.113717, 48.307245],
            [2.110594, 48.296944],
            [2.13612, 48.299248],
            [2.163748, 48.298437],
            [2.155708, 48.304494],
            [2.15439, 48.315911],
            [2.169025, 48.312696],
            [2.181355, 48.313746],
            [2.185025, 48.326429],
            [2.19821, 48.337063],
            [2.202252, 48.344599],
            [2.223914, 48.336416],
            [2.229592, 48.329229],
            [2.24631, 48.329962],
            [2.238043, 48.316371],
            [2.249436, 48.314581],
            [2.245152, 48.298394],
            [2.25357, 48.300243],
            [2.266892, 48.314682],
            [2.295419, 48.308222],
            [2.312594, 48.330804],
            [2.327695, 48.333053],
            [2.340155, 48.32023],
            [2.369816, 48.308674],
            [2.396853, 48.314755],
            [2.402663, 48.320718],
            [2.404812, 48.314593],
            [2.42076, 48.299253],
            [2.42317, 48.289316],
            [2.417831, 48.278395],
            [2.423762, 48.260296],
            [2.431932, 48.255142],
            [2.450164, 48.250037],
            [2.469093, 48.255276],
            [2.486653, 48.240104],
            [2.506186, 48.238532],
            [2.514113, 48.214378],
            [2.522944, 48.200517],
            [2.512965, 48.192979],
            [2.509755, 48.177878],
            [2.516843, 48.166808],
            [2.506324, 48.156438],
            [2.483232, 48.164516],
            [2.478373, 48.156744],
            [2.456083, 48.135818],
            [2.444253, 48.131468],
            [2.451087, 48.123459],
            [2.464866, 48.129109],
            [2.490313, 48.126606],
            [2.521512, 48.127298],
            [2.538115, 48.140651],
            [2.57056, 48.140816],
            [2.577572, 48.132078],
            [2.60269, 48.131484],
            [2.639738, 48.138859],
            [2.646371, 48.136008],
            [2.663414, 48.122204],
            [2.672223, 48.124202],
            [2.706543, 48.124819],
            [2.729149, 48.13909],
            [2.755194, 48.145653],
            [2.753574, 48.153211],
            [2.741463, 48.159773],
            [2.746265, 48.163803],
            [2.768611, 48.163611],
            [2.780168, 48.167547],
            [2.798946, 48.168273],
            [2.809946, 48.1613],
            [2.798633, 48.150165],
            [2.800902, 48.133252],
            [2.820884, 48.129664],
            [2.825253, 48.133557],
            [2.841195, 48.137851],
            [2.859006, 48.14735],
            [2.868033, 48.156437],
            [2.936316, 48.163392],
            [2.953271, 48.165012],
            [2.963612, 48.152953],
            [2.990885, 48.152489],
            [2.993984, 48.142266],
            [3.006785, 48.14525],
            [3.029468, 48.133204],
            [3.015877, 48.115703],
            [3.035285, 48.115994],
            [3.038314, 48.101543],
            [3.049889, 48.088839],
            [3.050471, 48.072334],
            [3.066843, 48.062701],
            [3.088316, 48.053932],
            [3.097336, 48.039372],
            [3.124257, 48.031123],
            [3.120445, 48.027237],
            [3.10311, 48.024051],
            [3.104091, 48.013542],
            [3.115427, 48.012966],
            [3.124552, 48.006031],
            [3.121509, 47.995081],
            [3.128449, 47.970977],
            [3.11778, 47.964978],
            [3.105265, 47.946939],
            [3.095956, 47.946483],
            [3.081389, 47.938303],
            [3.078476, 47.931419],
            [3.064613, 47.930517],
            [3.049852, 47.917612],
            [3.050103, 47.91145],
            [3.036909, 47.910047],
            [3.025143, 47.905395],
            [3.010289, 47.904717],
            [3.007226, 47.895291],
            [3.011313, 47.874942],
            [3.002016, 47.869105],
            [3.005359, 47.864049],
            [3.024157, 47.860433],
            [3.033828, 47.843872],
            [3.031078, 47.837953],
            [3.012531, 47.834373],
            [3.01547, 47.813536],
            [3.022937, 47.812772],
            [3.02812, 47.800645],
            [3.023799, 47.78655],
            [2.988227, 47.786034],
            [2.935056, 47.76325],
            [2.909376, 47.769314],
            [2.893987, 47.764757],
            [2.870177, 47.764868],
            [2.85667, 47.760929],
            [2.858146, 47.74565],
            [2.84879, 47.725885],
            [2.848969, 47.716845],
            [2.858517, 47.711991],
            [2.87658, 47.719082],
            [2.884193, 47.714212],
            [2.882941, 47.702615],
            [2.903712, 47.695096],
            [2.923115, 47.680559],
            [2.918156, 47.669795],
            [2.926238, 47.660086],
            [2.936944, 47.659132],
            [2.954229, 47.645774],
            [2.936291, 47.636649],
            [2.935769, 47.619832],
            [2.945217, 47.608449],
            [2.937994, 47.598886],
            [2.948069, 47.590535],
            [2.954983, 47.590409],
            [2.964452, 47.581632],
            [2.962268, 47.576787],
            [2.976535, 47.569424],
            [2.964314, 47.564408],
            [2.958652, 47.557378],
            [2.914296, 47.565972],
            [2.909372, 47.559161],
            [2.890771, 47.553102],
            [2.873895, 47.55281],
            [2.866125, 47.546368],
            [2.857486, 47.552839],
            [2.845187, 47.544933],
            [2.848901, 47.537541],
            [2.874631, 47.520425],
            [2.888459, 47.509431],
            [2.898018, 47.485252],
            [2.914061, 47.470897],
            [2.920988, 47.455436],
            [2.928897, 47.444565],
            [2.930733, 47.431324],
            [2.919886, 47.42135],
            [2.918455, 47.405525],
            [2.900816, 47.385193],
            [2.895249, 47.372177],
            [2.873492, 47.348397],
            [2.869948, 47.338248],
            [2.876542, 47.321934],
            [2.884876, 47.316364],
            [2.907768, 47.310991],
            [2.926481, 47.298618],
            [2.937862, 47.287719],
            [2.973939, 47.269805],
            [2.9834, 47.259766],
            [2.977955, 47.235553],
            [2.981689, 47.221993],
            [2.997283, 47.201664],
            [3.009285, 47.179915],
            [3.015948, 47.159094],
            [3.021198, 47.136438],
            [3.028091, 47.129065],
            [3.031374, 47.092476],
            [3.02118, 47.078232],
            [3.018716, 47.070781],
            [3.023219, 47.062612],
            [3.034525, 47.056291],
            [3.062745, 47.045276],
            [3.074838, 47.029815],
            [3.075744, 47.019148],
            [3.065582, 46.997352],
            [3.06293, 46.981085],
            [3.071706, 46.964341],
            [3.079332, 46.955115],
            [3.064898, 46.937079],
            [3.062211, 46.92774],
            [3.050295, 46.910631],
            [3.051402, 46.904956],
            [3.060959, 46.898149],
            [3.068096, 46.87768],
            [3.067102, 46.847818],
            [3.054589, 46.838765],
            [3.059351, 46.827264],
            [3.037471, 46.807787],
            [3.032063, 46.794909],
            [3.017518, 46.799901],
            [3.003355, 46.798123],
            [2.98954, 46.799298],
            [2.977802, 46.803773],
            [2.959919, 46.803872],
            [2.952959, 46.790999],
            [2.937816, 46.795714],
            [2.924375, 46.794523],
            [2.908127, 46.787904],
            [2.909805, 46.779348],
            [2.876169, 46.768446],
            [2.877016, 46.761469],
            [2.861233, 46.75403],
            [2.84542, 46.742856],
            [2.844812, 46.726844],
            [2.827618, 46.735286],
            [2.801072, 46.733722],
            [2.787291, 46.728614],
            [2.774489, 46.718903],
            [2.758521, 46.717749],
            [2.756744, 46.724772],
            [2.74357, 46.730083],
            [2.73729, 46.74315],
            [2.728721, 46.748309],
            [2.70497, 46.73939],
            [2.700921, 46.72096],
            [2.688165, 46.72091],
            [2.677793, 46.704612],
            [2.654729, 46.696537],
            [2.647886, 46.688908],
            [2.631358, 46.690887],
            [2.623404, 46.688703],
            [2.621553, 46.678653],
            [2.630861, 46.672294],
            [2.624641, 46.657301],
            [2.609598, 46.66284],
            [2.605048, 46.668158],
            [2.584975, 46.664166],
            [2.572189, 46.659445],
            [2.56739, 46.652347],
            [2.58958, 46.648027],
            [2.596648, 46.637215],
            [2.585277, 46.622739],
            [2.585963, 46.614297],
            [2.569101, 46.609519],
            [2.577952, 46.603788],
            [2.58156, 46.593164],
            [2.60232, 46.595164],
            [2.604519, 46.579002],
            [2.609378, 46.571329],
            [2.606219, 46.565758],
            [2.614961, 46.553276],
            [2.583505, 46.542742],
            [2.572492, 46.53385],
            [2.554028, 46.529388],
            [2.536655, 46.519703],
            [2.527531, 46.52903],
            [2.520139, 46.530864],
            [2.512426, 46.523881],
            [2.499123, 46.521292],
            [2.496943, 46.533666],
            [2.482928, 46.532695],
            [2.468571, 46.52601],
            [2.450281, 46.521936],
            [2.426687, 46.526122],
            [2.413164, 46.520302],
            [2.388465, 46.518246],
            [2.3683, 46.518434],
            [2.352004, 46.512207],
            [2.324179, 46.490368],
            [2.329822, 46.479617],
            [2.324288, 46.470979],
            [2.316671, 46.468549],
            [2.305469, 46.475429],
            [2.28576, 46.453515],
            [2.281044, 46.420404],
            [2.249877, 46.426367],
            [2.233415, 46.423384],
            [2.22064, 46.423664],
            [2.197568, 46.428294],
            [2.185156, 46.423284],
            [2.167784, 46.424069],
            [2.129681, 46.419868],
            [2.112635, 46.420825],
            [2.108372, 46.413529],
            [2.088946, 46.4089],
            [2.080347, 46.411894],
            [2.074196, 46.419843],
            [2.029417, 46.424589],
            [2.020346, 46.429422],
            [1.993077, 46.430917],
            [1.978104, 46.439773],
            [1.954251, 46.438126],
            [1.94387, 46.433997],
            [1.924302, 46.431903],
            [1.919553, 46.440208],
            [1.90918, 46.443498],
            [1.902458, 46.437805],
            [1.890716, 46.441493],
            [1.883437, 46.432557],
            [1.858038, 46.433477],
            [1.838365, 46.427285],
            [1.819504, 46.430034],
            [1.816812, 46.439525],
            [1.803139, 46.446918],
            [1.798375, 46.454813],
            [1.754857, 46.452215],
            [1.747592, 46.450018],
            [1.756666, 46.441292],
            [1.757376, 46.423616],
            [1.749316, 46.410934],
            [1.750545, 46.405586],
            [1.739212, 46.401254],
            [1.72771, 46.388956],
            [1.709131, 46.393354],
            [1.697467, 46.406605],
            [1.683606, 46.418177],
            [1.661184, 46.403538],
            [1.644846, 46.386817],
            [1.628837, 46.388247],
            [1.614306, 46.405296],
            [1.622655, 46.41826],
            [1.609255, 46.423123],
            [1.592039, 46.407312],
            [1.569691, 46.405498],
            [1.552492, 46.394134],
            [1.546195, 46.39346],
            [1.543968, 46.416883],
            [1.535052, 46.424456],
            [1.522307, 46.426528],
            [1.511205, 46.419595],
            [1.50599, 46.409909],
            [1.492935, 46.398341],
            [1.477596, 46.393727],
            [1.472559, 46.383432],
            [1.462937, 46.37535],
            [1.45435, 46.376049],
            [1.435374, 46.363842],
            [1.438395, 46.357973],
            [1.415185, 46.347215],
            [1.404845, 46.364057],
            [1.396578, 46.371445],
            [1.38352, 46.374755],
            [1.377528, 46.382803],
            [1.356024, 46.40012],
            [1.344293, 46.401547],
            [1.330922, 46.396706],
            [1.32234, 46.389656],
            [1.320474, 46.381632],
            [1.30936, 46.378135],
            [1.303059, 46.370992],
            [1.279555, 46.376489],
            [1.260399, 46.378783],
            [1.245105, 46.373239],
            [1.240865, 46.367574],
            [1.216424, 46.367785],
            [1.204766, 46.387689],
            [1.191538, 46.376759],
            [1.18415, 46.377291],
            [1.177279, 46.383948],
            [1.195087, 46.402754],
            [1.194473, 46.410352],
            [1.212688, 46.432218],
            [1.201134, 46.432466],
            [1.183406, 46.429225],
            [1.186018, 46.441084],
            [1.168919, 46.446309],
            [1.151607, 46.449234],
            [1.135508, 46.470884],
            [1.152979, 46.472958],
            [1.140527, 46.485635],
            [1.134968, 46.495263],
            [1.149149, 46.502205],
            [1.145929, 46.506401],
            [1.108286, 46.53151],
            [1.087597, 46.538168],
            [1.072298, 46.537235],
            [1.0206, 46.537099],
            [1.021958, 46.553717],
            [1.014769, 46.56776],
            [0.987233, 46.56556],
            [0.982094, 46.57264],
            [0.962668, 46.574298],
            [0.942022, 46.580795],
            [0.93719, 46.586045],
            [0.937756, 46.59442],
            [0.915865, 46.596628],
            [0.909864, 46.603447],
            [0.906937, 46.615213],
            [0.894303, 46.625732],
            [0.896383, 46.633452],
            [0.906521, 46.647752],
            [0.917429, 46.650363],
            [0.906703, 46.665571],
            [0.910711, 46.677184],
            [0.902159, 46.679191],
            [0.924749, 46.69279],
            [0.924953, 46.699991],
            [0.914552, 46.71012],
            [0.901042, 46.736091],
            [0.887775, 46.737903],
            [0.867469, 46.748216],
            [0.854449, 46.759971],
            [0.842938, 46.764275],
            [0.830648, 46.775361],
            [0.829436, 46.783579],
            [0.816284, 46.787769],
            [0.811902, 46.794504],
            [0.81532, 46.805708],
            [0.810329, 46.813065],
            [0.80932, 46.827854],
            [0.795093, 46.832522],
            [0.786802, 46.840463],
            [0.796459, 46.848962],
            [0.790329, 46.852389],
            [0.768799, 46.850746],
            [0.771988, 46.860643],
            [0.750882, 46.863525],
            [0.747331, 46.869392],
            [0.733685, 46.87604],
            [0.726737, 46.886741],
            [0.704318, 46.903296],
            [0.703572, 46.930056],
            [0.706248, 46.937157],
            [0.696189, 46.95681],
            [0.692567, 46.974304],
            [0.682162, 46.97708],
            [0.661621, 46.978087],
            [0.656462, 46.985435],
            [0.647695, 46.988275],
            [0.636211, 46.985451],
            [0.621244, 46.997106],
            [0.618874, 47.007465],
            [0.590551, 47.006723],
            [0.566953, 47.002267],
            [0.573685, 46.995531],
            [0.573822, 46.98334],
            [0.593534, 46.977613],
            [0.601173, 46.973094],
            [0.601558, 46.959107],
            [0.598348, 46.956764],
            [0.570842, 46.955939],
            [0.539289, 46.960219],
            [0.528129, 46.955972],
            [0.506135, 46.959245],
            [0.476624, 46.949862],
            [0.444801, 46.941152],
            [0.438705, 46.929578],
            [0.418525, 46.937387],
            [0.406661, 46.936648],
            [0.388146, 46.943625],
            [0.366511, 46.949554],
            [0.355244, 46.944186],
            [0.347738, 46.936586],
            [0.339526, 46.936623],
            [0.324837, 46.930652],
            [0.311232, 46.937837],
            [0.305215, 46.952805],
            [0.305075, 46.965176],
            [0.300739, 46.97383],
            [0.308399, 46.97815],
            [0.301785, 46.984422],
            [0.308846, 46.999441],
            [0.305431, 47.012362],
            [0.298677, 47.019599],
            [0.309699, 47.025644],
            [0.306985, 47.048723],
            [0.29823, 47.053922],
            [0.267707, 47.043883],
            [0.262016, 47.057459],
            [0.267619, 47.067504],
            [0.26166, 47.070051],
            [0.242451, 47.071188],
            [0.231751, 47.064055],
            [0.207994, 47.053231],
            [0.191672, 47.064672],
            [0.179973, 47.059188],
            [0.174218, 47.071274],
            [0.200953, 47.091258],
            [0.188118, 47.100828],
            [0.181457, 47.11439],
            [0.165984, 47.107162],
            [0.161267, 47.100796],
            [0.134717, 47.107873],
            [0.136131, 47.121579],
            [0.127167, 47.119967],
            [0.123724, 47.128316],
            [0.111119, 47.129423],
            [0.104719, 47.120794],
            [0.084648, 47.118378],
            [0.076371, 47.123931],
            [0.080871, 47.137653],
            [0.078395, 47.146335],
            [0.066259, 47.143216],
            [0.05383, 47.163734],
            [0.063053, 47.175282],
            [0.066597, 47.189796],
            [0.053278, 47.197182],
            [0.072493, 47.22051],
            [0.074829, 47.248048],
            [0.082849, 47.274169],
            [0.078979, 47.282822],
            [0.099013, 47.30814],
            [0.109245, 47.313422],
            [0.117869, 47.325601],
            [0.117457, 47.332343],
            [0.131254, 47.334092],
            [0.138988, 47.338243],
            [0.147969, 47.348448],
            [0.141654, 47.361961],
            [0.158458, 47.366157],
            [0.182962, 47.38033],
            [0.167988, 47.386934],
            [0.16949, 47.395646],
            [0.153858, 47.398727],
            [0.181382, 47.417825],
            [0.185279, 47.424736],
            [0.180938, 47.453405],
            [0.189798, 47.460723],
            [0.200718, 47.484545],
            [0.220107, 47.501952],
            [0.220089, 47.51149],
            [0.224915, 47.5271],
            [0.20807, 47.526432],
            [0.203491, 47.533331],
            [0.193346, 47.539118],
            [0.201502, 47.544324],
            [0.215103, 47.569976],
            [0.23453, 47.577977],
            [0.23, 47.608397],
            [0.237683, 47.610966],
            [0.259253, 47.612254],
            [0.267842, 47.608672],
            [0.277992, 47.597381],
            [0.290017, 47.597729],
            [0.323255, 47.592888],
            [0.338446, 47.58503],
            [0.339586, 47.579472],
            [0.366736, 47.573458],
            [0.378954, 47.569105],
            [0.402166, 47.579002],
            [0.394423, 47.594393],
            [0.379056, 47.61078],
            [0.364804, 47.620165],
            [0.364654, 47.626011],
            [0.381075, 47.639065],
            [0.397023, 47.638927],
            [0.423905, 47.617824],
            [0.449933, 47.61933],
            [0.455182, 47.627017],
            [0.456628, 47.638826],
            [0.476076, 47.648012],
            [0.479767, 47.643292],
            [0.499667, 47.645272],
            [0.513252, 47.652864],
            [0.542897, 47.656204],
            [0.559479, 47.665995],
            [0.587727, 47.669617],
            [0.604637, 47.679968],
            [0.614804, 47.682751],
            [0.614432, 47.694215],
            [0.604187, 47.685607],
            [0.595571, 47.688313],
            [0.59297, 47.703591],
            [0.58052, 47.712331],
            [0.594095, 47.723106],
            [0.611597, 47.728134],
            [0.61067, 47.732034],
            [0.626833, 47.751793],
            [0.639379, 47.751572],
            [0.67565, 47.768962],
            [0.69688, 47.764225],
            [0.703442, 47.76994],
            [0.689318, 47.779997],
            [0.697985, 47.788889],
            [0.712632, 47.790039],
            [0.724844, 47.798889],
            [0.739721, 47.814679],
            [0.7454, 47.825663],
            [0.758857, 47.833536],
            [0.768414, 47.831101],
            [0.774574, 47.839684],
            [0.774019, 47.851208],
            [0.759161, 47.859222],
            [0.764689, 47.866583],
            [0.757338, 47.884474],
            [0.759869, 47.898225],
            [0.770325, 47.902009],
            [0.780512, 47.910375],
            [0.790024, 47.91221],
            [0.797991, 47.898194],
            [0.810187, 47.890394],
            [0.817218, 47.892419],
            [0.809134, 47.910662],
            [0.812118, 47.928939],
            [0.817146, 47.934468],
            [0.837277, 47.937247],
            [0.845794, 47.941403],
            [0.845292, 47.954439],
            [0.824281, 47.982142],
            [0.826223, 47.991476],
            [0.832375, 47.996592],
            [0.831702, 48.006117],
            [0.840527, 48.021049],
            [0.841555, 48.029674],
            [0.836723, 48.034559],
            [0.825203, 48.03006],
            [0.80877, 48.031994],
            [0.797478, 48.037557],
            [0.796533, 48.052678],
            [0.801183, 48.071514],
            [0.834603, 48.070148],
            [0.84302, 48.072638],
            [0.844838, 48.086647],
            [0.839203, 48.091252],
            [0.815168, 48.093731],
            [0.814351, 48.098802],
            [0.832688, 48.098454],
            [0.841217, 48.10306],
            [0.85537, 48.122621],
            [0.852575, 48.133602],
            [0.872936, 48.133409],
            [0.89397, 48.135535],
            [0.913798, 48.135125],
            [0.911612, 48.148859],
            [0.882496, 48.161766],
            [0.861989, 48.166817],
            [0.836835, 48.167352],
            [0.82689, 48.175387],
            [0.808359, 48.186119],
            [0.795627, 48.188043],
            [0.797658, 48.194455],
            [0.806269, 48.203016],
            [0.830484, 48.210709],
            [0.82589, 48.220722],
            [0.806087, 48.239891],
            [0.802415, 48.248072],
            [0.78759, 48.261314],
            [0.785871, 48.271138],
            [0.794261, 48.284951],
            [0.793418, 48.293009],
            [0.777582, 48.302841],
            [0.760232, 48.298172],
            [0.762194, 48.306877],
            [0.773138, 48.325511],
            [0.784185, 48.334479],
            [0.785425, 48.340431],
            [0.818401, 48.34942],
            [0.827856, 48.342447],
            [0.841595, 48.35023],
            [0.861813, 48.350898],
            [0.870157, 48.35834],
            [0.883373, 48.356669],
            [0.901843, 48.37345],
            [0.914919, 48.374324],
            [0.931178, 48.391266],
            [0.942246, 48.399005],
            [0.948269, 48.417832],
            [0.976271, 48.439082],
            [0.958409, 48.442597],
            [0.942268, 48.457734],
            [0.935713, 48.475554],
            [0.956293, 48.482279],
            [0.954369, 48.490574],
            [0.941559, 48.498695],
            [0.954004, 48.50384],
            [0.966551, 48.522742],
            [0.953418, 48.526244],
            [0.922603, 48.537716],
            [0.938763, 48.550559],
            [0.92639, 48.559394],
            [0.891266, 48.572177],
            [0.867676, 48.573488],
            [0.850635, 48.582626],
            [0.846587, 48.604783],
            [0.833047, 48.61077],
            [0.819636, 48.609871],
            [0.817754, 48.61632],
            [0.831406, 48.633774],
            [0.822682, 48.640054],
            [0.823921, 48.650042],
            [0.812949, 48.66051],
            [0.814825, 48.670164],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5395",
      properties: { name: "Corse", density: 0, path: "/world/France/Corse" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [9.271032, 41.364959],
              [9.265699, 41.362165],
              [9.255883, 41.368794],
              [9.260245, 41.373651],
              [9.273659, 41.369222],
              [9.271032, 41.364959],
            ],
          ],
          [
            [
              [9.402271, 41.858702],
              [9.402053, 41.845844],
              [9.406274, 41.822364],
              [9.394134, 41.798942],
              [9.39919, 41.790143],
              [9.398145, 41.778459],
              [9.407322, 41.767635],
              [9.405272, 41.754576],
              [9.406219, 41.739201],
              [9.401342, 41.71696],
              [9.403533, 41.71084],
              [9.399819, 41.692799],
              [9.381088, 41.688001],
              [9.372301, 41.67883],
              [9.375738, 41.67062],
              [9.387491, 41.657359],
              [9.385056, 41.65297],
              [9.364413, 41.636179],
              [9.354739, 41.64071],
              [9.347601, 41.636632],
              [9.346393, 41.630023],
              [9.353827, 41.626633],
              [9.346355, 41.617509],
              [9.336742, 41.621539],
              [9.327205, 41.616357],
              [9.308237, 41.628488],
              [9.306019, 41.61747],
              [9.297002, 41.61093],
              [9.288341, 41.609068],
              [9.282534, 41.596416],
              [9.286354, 41.589453],
              [9.295679, 41.583322],
              [9.308381, 41.593973],
              [9.313081, 41.601132],
              [9.321517, 41.604837],
              [9.335099, 41.602028],
              [9.347068, 41.594391],
              [9.368574, 41.596897],
              [9.353383, 41.577938],
              [9.348174, 41.575389],
              [9.350172, 41.566187],
              [9.3409, 41.559242],
              [9.323992, 41.557236],
              [9.302322, 41.545198],
              [9.287557, 41.530229],
              [9.273074, 41.530193],
              [9.27368, 41.522518],
              [9.283918, 41.52045],
              [9.277308, 41.506125],
              [9.268897, 41.501625],
              [9.275931, 41.496437],
              [9.287652, 41.494831],
              [9.284002, 41.477572],
              [9.277272, 41.475358],
              [9.277716, 41.464955],
              [9.265882, 41.465995],
              [9.242945, 41.446706],
              [9.223954, 41.442489],
              [9.223272, 41.436026],
              [9.228897, 41.425302],
              [9.228119, 41.409111],
              [9.244236, 41.421181],
              [9.265102, 41.428283],
              [9.259167, 41.413375],
              [9.250725, 41.409606],
              [9.236164, 41.393559],
              [9.233123, 41.38656],
              [9.221488, 41.376329],
              [9.219679, 41.368212],
              [9.198806, 41.365886],
              [9.180369, 41.366438],
              [9.174371, 41.379329],
              [9.167244, 41.385593],
              [9.144615, 41.387184],
              [9.120863, 41.396443],
              [9.09384, 41.393839],
              [9.091172, 41.401806],
              [9.095532, 41.412432],
              [9.108922, 41.41994],
              [9.104347, 41.425503],
              [9.120688, 41.437616],
              [9.119219, 41.440772],
              [9.09945, 41.442768],
              [9.093583, 41.449719],
              [9.082201, 41.441974],
              [9.074313, 41.442921],
              [9.06643, 41.451024],
              [9.070432, 41.466941],
              [9.070287, 41.475671],
              [9.050836, 41.459642],
              [9.038261, 41.459645],
              [9.018027, 41.467748],
              [9.012884, 41.476122],
              [9.00217, 41.475774],
              [8.992846, 41.485396],
              [8.980864, 41.47933],
              [8.959459, 41.49213],
              [8.938403, 41.489668],
              [8.931788, 41.495378],
              [8.921013, 41.497499],
              [8.914747, 41.507208],
              [8.900184, 41.508774],
              [8.884615, 41.505072],
              [8.882038, 41.509239],
              [8.888261, 41.51708],
              [8.87781, 41.523794],
              [8.86537, 41.520128],
              [8.845074, 41.517958],
              [8.841405, 41.523767],
              [8.851924, 41.533234],
              [8.851188, 41.540861],
              [8.841681, 41.546553],
              [8.822918, 41.546156],
              [8.81489, 41.553906],
              [8.788535, 41.557736],
              [8.785493, 41.563659],
              [8.801965, 41.572525],
              [8.782789, 41.585681],
              [8.777193, 41.592764],
              [8.783902, 41.5964],
              [8.790947, 41.608501],
              [8.793077, 41.629554],
              [8.802475, 41.633221],
              [8.82178, 41.63009],
              [8.826517, 41.635952],
              [8.854076, 41.647017],
              [8.869501, 41.646091],
              [8.877264, 41.652455],
              [8.880913, 41.668095],
              [8.892893, 41.6741],
              [8.908018, 41.677008],
              [8.915328, 41.681283],
              [8.914508, 41.689724],
              [8.885656, 41.69085],
              [8.878288, 41.695973],
              [8.841281, 41.697552],
              [8.830294, 41.706263],
              [8.812712, 41.71413],
              [8.793768, 41.707893],
              [8.772973, 41.711919],
              [8.784545, 41.731665],
              [8.780024, 41.739414],
              [8.772455, 41.741517],
              [8.760082, 41.73838],
              [8.736647, 41.729425],
              [8.725485, 41.727546],
              [8.717242, 41.722775],
              [8.703033, 41.726006],
              [8.707608, 41.736186],
              [8.697638, 41.741319],
              [8.68201, 41.739918],
              [8.67177, 41.743688],
              [8.658564, 41.741805],
              [8.659749, 41.749464],
              [8.66828, 41.752895],
              [8.684517, 41.747002],
              [8.704409, 41.757891],
              [8.713143, 41.760834],
              [8.730088, 41.775912],
              [8.727168, 41.787089],
              [8.719572, 41.788894],
              [8.72125, 41.797809],
              [8.740941, 41.798688],
              [8.748257, 41.810824],
              [8.757546, 41.80895],
              [8.771296, 41.811239],
              [8.774795, 41.82504],
              [8.782065, 41.833703],
              [8.770809, 41.836461],
              [8.767795, 41.845931],
              [8.7841, 41.84817],
              [8.790617, 41.855521],
              [8.790275, 41.866417],
              [8.781212, 41.880669],
              [8.803133, 41.891381],
              [8.795662, 41.908866],
              [8.785817, 41.920266],
              [8.772639, 41.927376],
              [8.760896, 41.924461],
              [8.754908, 41.932566],
              [8.745111, 41.933164],
              [8.739948, 41.928307],
              [8.741064, 41.917548],
              [8.731037, 41.9159],
              [8.72143, 41.909256],
              [8.703285, 41.90763],
              [8.686855, 41.908594],
              [8.670458, 41.905061],
              [8.66431, 41.907385],
              [8.64145, 41.909889],
              [8.614932, 41.902347],
              [8.610306, 41.913051],
              [8.621888, 41.927827],
              [8.620776, 41.939542],
              [8.609, 41.940156],
              [8.605693, 41.951055],
              [8.5977, 41.953238],
              [8.595884, 41.966097],
              [8.61484, 41.971331],
              [8.639021, 41.967471],
              [8.646066, 41.968131],
              [8.668878, 41.98243],
              [8.668459, 41.991611],
              [8.657566, 41.993437],
              [8.656425, 42.009346],
              [8.66801, 42.017457],
              [8.690325, 42.027593],
              [8.702012, 42.026639],
              [8.725346, 42.034357],
              [8.722682, 42.04254],
              [8.741329, 42.040912],
              [8.746987, 42.050211],
              [8.739161, 42.062699],
              [8.719856, 42.063511],
              [8.720335, 42.069442],
              [8.714767, 42.081539],
              [8.704737, 42.08794],
              [8.710258, 42.095822],
              [8.700249, 42.106044],
              [8.699986, 42.112516],
              [8.689155, 42.115105],
              [8.680728, 42.105997],
              [8.67126, 42.107107],
              [8.660503, 42.103588],
              [8.647009, 42.121132],
              [8.637475, 42.119767],
              [8.625634, 42.122671],
              [8.609954, 42.134545],
              [8.595886, 42.130259],
              [8.582518, 42.129323],
              [8.584983, 42.135867],
              [8.593957, 42.143185],
              [8.589746, 42.149152],
              [8.577473, 42.156555],
              [8.590174, 42.163885],
              [8.588488, 42.171421],
              [8.568219, 42.169017],
              [8.565439, 42.177332],
              [8.582879, 42.178547],
              [8.578217, 42.188552],
              [8.581386, 42.20594],
              [8.569675, 42.208002],
              [8.575321, 42.216374],
              [8.567136, 42.219577],
              [8.573542, 42.22584],
              [8.570341, 42.230301],
              [8.548191, 42.228906],
              [8.540103, 42.234991],
              [8.549244, 42.239977],
              [8.556112, 42.236039],
              [8.573117, 42.238745],
              [8.578263, 42.244253],
              [8.589324, 42.244504],
              [8.616717, 42.255458],
              [8.626742, 42.251755],
              [8.641842, 42.256428],
              [8.665588, 42.25932],
              [8.671571, 42.262059],
              [8.689105, 42.263528],
              [8.690123, 42.278207],
              [8.675978, 42.283616],
              [8.673955, 42.294337],
              [8.660571, 42.302119],
              [8.648652, 42.302733],
              [8.639933, 42.299416],
              [8.625515, 42.312262],
              [8.610357, 42.308395],
              [8.597433, 42.316055],
              [8.604629, 42.322652],
              [8.621555, 42.32751],
              [8.627996, 42.332296],
              [8.626444, 42.340242],
              [8.618283, 42.340702],
              [8.600244, 42.353],
              [8.591544, 42.352772],
              [8.581559, 42.347468],
              [8.57385, 42.338413],
              [8.564882, 42.333215],
              [8.553308, 42.333031],
              [8.558803, 42.343785],
              [8.551794, 42.347376],
              [8.555885, 42.36475],
              [8.543205, 42.367017],
              [8.547948, 42.379355],
              [8.557571, 42.370756],
              [8.566691, 42.370693],
              [8.578306, 42.375542],
              [8.573409, 42.381405],
              [8.585126, 42.385325],
              [8.596226, 42.382399],
              [8.608844, 42.38638],
              [8.602287, 42.400053],
              [8.611679, 42.403198],
              [8.604043, 42.409513],
              [8.608417, 42.416822],
              [8.623438, 42.421018],
              [8.639396, 42.41852],
              [8.648108, 42.413119],
              [8.65523, 42.415734],
              [8.66222, 42.426292],
              [8.660411, 42.435283],
              [8.648238, 42.442411],
              [8.667321, 42.444884],
              [8.668144, 42.458013],
              [8.67983, 42.468114],
              [8.674792, 42.476243],
              [8.649236, 42.47431],
              [8.64993, 42.47824],
              [8.66455, 42.492994],
              [8.666509, 42.515224],
              [8.674842, 42.518622],
              [8.683761, 42.516459],
              [8.696843, 42.525584],
              [8.710448, 42.523704],
              [8.718616, 42.528245],
              [8.710258, 42.535724],
              [8.718952, 42.540795],
              [8.716631, 42.549268],
              [8.72282, 42.556778],
              [8.715075, 42.564028],
              [8.71099, 42.576606],
              [8.724319, 42.584094],
              [8.723842, 42.574014],
              [8.727044, 42.561604],
              [8.738112, 42.567803],
              [8.748283, 42.568188],
              [8.760442, 42.55857],
              [8.768643, 42.555811],
              [8.787805, 42.558171],
              [8.804099, 42.569856],
              [8.800159, 42.582399],
              [8.81108, 42.589685],
              [8.808932, 42.59906],
              [8.823535, 42.606187],
              [8.834319, 42.601744],
              [8.850941, 42.610584],
              [8.864968, 42.608],
              [8.875042, 42.613165],
              [8.874094, 42.61818],
              [8.886527, 42.628966],
              [8.902416, 42.627137],
              [8.911157, 42.628895],
              [8.918135, 42.636906],
              [8.934261, 42.638772],
              [8.941783, 42.634096],
              [8.959373, 42.635082],
              [8.975174, 42.640044],
              [9.000633, 42.64191],
              [9.010378, 42.640352],
              [9.020694, 42.644273],
              [9.027514, 42.652054],
              [9.046004, 42.655043],
              [9.059321, 42.660065],
              [9.061825, 42.665093],
              [9.053804, 42.679958],
              [9.059094, 42.694609],
              [9.071703, 42.693409],
              [9.079701, 42.700849],
              [9.085764, 42.714609],
              [9.09988, 42.715246],
              [9.105977, 42.724098],
              [9.118007, 42.726543],
              [9.124552, 42.731631],
              [9.136056, 42.730227],
              [9.166886, 42.736564],
              [9.188155, 42.727336],
              [9.203845, 42.726315],
              [9.217627, 42.734222],
              [9.224553, 42.732],
              [9.234005, 42.71991],
              [9.254448, 42.718515],
              [9.259325, 42.700693],
              [9.267944, 42.699477],
              [9.278857, 42.689592],
              [9.284427, 42.676336],
              [9.293322, 42.674311],
              [9.303158, 42.684062],
              [9.314177, 42.688992],
              [9.322598, 42.698103],
              [9.321293, 42.7134],
              [9.339577, 42.728992],
              [9.344478, 42.73781],
              [9.339181, 42.757928],
              [9.33966, 42.776012],
              [9.342563, 42.794802],
              [9.337775, 42.80408],
              [9.32513, 42.813333],
              [9.320544, 42.823408],
              [9.311015, 42.834679],
              [9.332178, 42.858473],
              [9.340154, 42.865486],
              [9.329853, 42.872396],
              [9.326269, 42.890316],
              [9.322223, 42.899633],
              [9.353819, 42.916235],
              [9.35604, 42.927003],
              [9.348894, 42.92931],
              [9.358604, 42.946339],
              [9.348145, 42.951797],
              [9.345758, 42.957907],
              [9.350545, 42.96827],
              [9.342142, 42.978546],
              [9.344368, 42.985533],
              [9.340873, 42.994465],
              [9.359042, 43.006725],
              [9.372862, 43.004764],
              [9.379939, 43.008193],
              [9.404476, 43.005824],
              [9.41367, 43.006532],
              [9.421758, 43.011724],
              [9.430837, 43.002724],
              [9.438513, 43.000491],
              [9.451265, 42.990315],
              [9.463558, 42.986401],
              [9.454097, 42.973079],
              [9.451876, 42.96436],
              [9.457635, 42.949057],
              [9.469321, 42.936145],
              [9.467674, 42.926807],
              [9.470792, 42.918531],
              [9.473869, 42.891155],
              [9.477528, 42.881443],
              [9.473067, 42.874129],
              [9.482315, 42.864255],
              [9.484277, 42.850189],
              [9.480743, 42.840776],
              [9.485812, 42.832427],
              [9.492385, 42.8051],
              [9.491075, 42.79497],
              [9.472079, 42.770534],
              [9.467114, 42.76086],
              [9.46856, 42.753049],
              [9.461391, 42.74066],
              [9.462471, 42.735628],
              [9.456195, 42.718039],
              [9.452397, 42.693496],
              [9.446387, 42.686125],
              [9.446189, 42.673577],
              [9.449194, 42.66224],
              [9.462197, 42.640115],
              [9.47488, 42.624836],
              [9.516215, 42.583013],
              [9.525678, 42.569008],
              [9.533196, 42.545947],
              [9.534492, 42.523983],
              [9.530026, 42.503291],
              [9.531229, 42.484416],
              [9.540463, 42.461067],
              [9.540835, 42.440809],
              [9.543395, 42.428491],
              [9.538632, 42.41402],
              [9.532673, 42.379968],
              [9.53337, 42.36628],
              [9.537243, 42.34521],
              [9.542502, 42.328804],
              [9.550457, 42.313215],
              [9.558829, 42.285265],
              [9.559226, 42.271734],
              [9.553213, 42.241434],
              [9.55286, 42.221258],
              [9.557439, 42.203166],
              [9.554409, 42.157011],
              [9.556602, 42.142143],
              [9.54998, 42.104166],
              [9.531344, 42.087489],
              [9.502931, 42.055616],
              [9.464786, 42.010954],
              [9.441548, 41.989749],
              [9.424255, 41.971536],
              [9.412569, 41.952476],
              [9.412053, 41.939727],
              [9.414998, 41.925108],
              [9.411248, 41.911001],
              [9.402064, 41.897155],
              [9.397019, 41.874613],
              [9.402271, 41.858702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5383",
      properties: {
        name: "Grand Est",
        density: 0,
        path: "/world/France/Grand Est",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.414789, 48.390269],
            [3.422079, 48.413341],
            [3.413623, 48.41434],
            [3.411836, 48.421316],
            [3.391959, 48.424318],
            [3.396989, 48.434568],
            [3.403567, 48.438739],
            [3.406484, 48.452452],
            [3.396439, 48.463729],
            [3.388358, 48.480409],
            [3.420355, 48.491547],
            [3.434639, 48.490252],
            [3.423914, 48.51436],
            [3.405397, 48.528015],
            [3.414239, 48.533468],
            [3.423448, 48.533559],
            [3.438228, 48.528334],
            [3.459185, 48.53074],
            [3.479601, 48.544709],
            [3.47977, 48.550623],
            [3.472296, 48.564913],
            [3.465522, 48.570485],
            [3.473383, 48.576874],
            [3.485275, 48.580427],
            [3.497506, 48.589955],
            [3.515864, 48.589782],
            [3.503855, 48.604807],
            [3.534618, 48.610353],
            [3.555614, 48.620286],
            [3.545903, 48.628065],
            [3.519064, 48.63347],
            [3.517498, 48.643365],
            [3.503184, 48.645683],
            [3.488194, 48.644796],
            [3.476573, 48.637329],
            [3.453116, 48.633956],
            [3.460432, 48.653009],
            [3.440849, 48.663073],
            [3.442691, 48.672503],
            [3.454556, 48.682119],
            [3.465832, 48.686193],
            [3.476969, 48.699355],
            [3.464308, 48.707378],
            [3.467467, 48.735224],
            [3.440606, 48.738702],
            [3.43581, 48.753572],
            [3.42567, 48.75569],
            [3.412961, 48.753902],
            [3.398658, 48.755599],
            [3.400841, 48.767256],
            [3.409423, 48.783836],
            [3.428211, 48.779485],
            [3.442161, 48.784354],
            [3.44129, 48.800919],
            [3.410478, 48.803943],
            [3.404165, 48.809276],
            [3.416618, 48.817829],
            [3.432166, 48.812258],
            [3.454412, 48.81384],
            [3.470382, 48.820898],
            [3.480929, 48.812186],
            [3.487202, 48.81519],
            [3.484968, 48.82501],
            [3.489574, 48.839868],
            [3.485183, 48.85191],
            [3.482405, 48.864937],
            [3.50215, 48.871151],
            [3.513061, 48.894728],
            [3.528571, 48.912137],
            [3.560022, 48.917013],
            [3.569814, 48.914706],
            [3.573848, 48.921481],
            [3.574445, 48.939019],
            [3.588888, 48.943911],
            [3.601596, 48.944074],
            [3.599065, 48.953592],
            [3.591553, 48.960375],
            [3.599176, 48.964609],
            [3.620654, 48.965944],
            [3.624537, 48.983752],
            [3.639893, 49.004086],
            [3.665164, 49.005632],
            [3.674966, 49.011723],
            [3.677482, 49.019165],
            [3.663955, 49.037308],
            [3.646312, 49.04059],
            [3.616841, 49.036484],
            [3.615019, 49.033501],
            [3.5867, 49.035796],
            [3.587708, 49.059395],
            [3.612154, 49.07356],
            [3.623795, 49.070865],
            [3.632872, 49.07279],
            [3.639051, 49.081304],
            [3.632341, 49.086509],
            [3.620076, 49.107194],
            [3.600011, 49.12069],
            [3.610395, 49.127544],
            [3.622252, 49.151105],
            [3.646874, 49.149123],
            [3.671804, 49.150635],
            [3.683838, 49.154491],
            [3.698032, 49.149828],
            [3.701621, 49.143083],
            [3.717013, 49.14704],
            [3.73957, 49.156933],
            [3.748768, 49.157085],
            [3.751142, 49.177699],
            [3.737224, 49.177961],
            [3.725439, 49.173516],
            [3.704389, 49.181369],
            [3.700549, 49.187713],
            [3.704875, 49.19859],
            [3.697103, 49.205473],
            [3.683908, 49.197534],
            [3.681794, 49.20534],
            [3.658406, 49.211001],
            [3.651609, 49.221427],
            [3.665022, 49.223221],
            [3.676373, 49.238361],
            [3.670779, 49.2396],
            [3.663796, 49.253525],
            [3.655249, 49.262848],
            [3.665132, 49.269658],
            [3.651609, 49.278361],
            [3.657318, 49.291022],
            [3.642802, 49.296041],
            [3.638334, 49.301355],
            [3.646957, 49.315153],
            [3.660162, 49.31837],
            [3.670333, 49.325093],
            [3.6891, 49.326851],
            [3.701293, 49.332508],
            [3.721617, 49.335726],
            [3.742221, 49.336511],
            [3.741081, 49.347586],
            [3.757818, 49.347604],
            [3.774462, 49.35464],
            [3.782444, 49.352774],
            [3.801516, 49.358931],
            [3.823758, 49.356987],
            [3.832779, 49.350262],
            [3.852156, 49.344655],
            [3.858709, 49.353737],
            [3.847476, 49.364589],
            [3.857347, 49.369946],
            [3.859145, 49.381352],
            [3.878782, 49.385857],
            [3.915074, 49.399385],
            [3.924439, 49.406179],
            [3.931831, 49.403085],
            [3.942414, 49.391461],
            [3.96131, 49.377347],
            [3.991365, 49.378222],
            [4.005109, 49.369209],
            [4.012733, 49.358303],
            [4.035498, 49.359906],
            [4.039906, 49.397393],
            [4.047974, 49.405644],
            [4.050275, 49.412261],
            [4.042296, 49.416651],
            [4.043145, 49.429326],
            [4.037669, 49.438263],
            [4.050024, 49.445792],
            [4.060247, 49.447151],
            [4.052914, 49.460257],
            [4.042375, 49.468691],
            [4.040706, 49.508532],
            [4.053159, 49.508645],
            [4.061858, 49.512563],
            [4.056624, 49.521047],
            [4.065098, 49.523284],
            [4.075835, 49.518345],
            [4.076749, 49.531315],
            [4.073801, 49.541618],
            [4.048768, 49.545273],
            [4.058291, 49.552548],
            [4.064794, 49.562212],
            [4.076572, 49.571126],
            [4.062782, 49.573557],
            [4.059561, 49.57871],
            [4.06511, 49.594909],
            [4.054191, 49.595289],
            [4.031396, 49.614287],
            [4.02529, 49.62253],
            [4.037911, 49.622919],
            [4.04325, 49.636146],
            [4.051111, 49.634644],
            [4.069556, 49.636752],
            [4.099313, 49.62848],
            [4.114791, 49.635305],
            [4.125466, 49.650608],
            [4.122332, 49.659521],
            [4.127027, 49.677918],
            [4.147538, 49.67802],
            [4.144187, 49.688544],
            [4.178301, 49.698036],
            [4.192036, 49.710906],
            [4.192629, 49.715801],
            [4.206463, 49.723538],
            [4.224707, 49.727199],
            [4.22902, 49.745745],
            [4.250094, 49.757099],
            [4.238219, 49.767643],
            [4.226619, 49.773088],
            [4.214264, 49.771268],
            [4.209588, 49.781777],
            [4.222431, 49.786967],
            [4.227046, 49.793183],
            [4.214772, 49.80611],
            [4.219892, 49.815134],
            [4.223488, 49.833765],
            [4.236622, 49.849699],
            [4.248506, 49.856518],
            [4.253053, 49.872272],
            [4.254064, 49.904005],
            [4.233245, 49.904305],
            [4.222139, 49.910422],
            [4.218709, 49.920686],
            [4.221602, 49.937902],
            [4.230396, 49.947205],
            [4.233063, 49.957825],
            [4.254384, 49.963632],
            [4.291294, 49.964059],
            [4.301483, 49.966176],
            [4.333652, 49.963561],
            [4.349712, 49.952178],
            [4.376835, 49.952427],
            [4.389355, 49.948472],
            [4.404077, 49.949066],
            [4.438432, 49.940983],
            [4.446938, 49.937508],
            [4.481608, 49.94785],
            [4.510409, 49.946683],
            [4.526888, 49.955655],
            [4.53796, 49.966579],
            [4.56276, 49.969878],
            [4.573704, 49.980303],
            [4.593058, 49.985505],
            [4.623491, 49.986514],
            [4.671873, 49.997042],
            [4.688873, 49.9955],
            [4.685174, 50.008023],
            [4.685568, 50.02763],
            [4.696497, 50.048066],
            [4.6959, 50.059079],
            [4.683185, 50.064919],
            [4.693026, 50.084819],
            [4.702083, 50.095528],
            [4.751637, 50.112096],
            [4.762161, 50.136395],
            [4.779757, 50.141423],
            [4.80603, 50.152929],
            [4.822582, 50.168317],
            [4.824291, 50.16073],
            [4.831994, 50.153954],
            [4.879559, 50.152031],
            [4.882345, 50.14108],
            [4.89497, 50.140359],
            [4.894887, 50.134603],
            [4.877726, 50.129652],
            [4.870636, 50.124646],
            [4.868474, 50.116645],
            [4.873212, 50.106964],
            [4.868526, 50.097623],
            [4.851233, 50.101366],
            [4.843554, 50.093373],
            [4.842871, 50.077478],
            [4.837935, 50.067107],
            [4.819311, 50.065877],
            [4.826993, 50.052898],
            [4.840818, 50.045906],
            [4.840393, 50.037589],
            [4.821988, 50.027413],
            [4.815942, 49.997897],
            [4.811003, 49.987972],
            [4.794699, 49.978299],
            [4.790108, 49.970019],
            [4.79091, 49.958398],
            [4.814965, 49.954066],
            [4.829415, 49.949217],
            [4.839332, 49.950756],
            [4.850187, 49.946472],
            [4.858128, 49.932897],
            [4.881367, 49.921508],
            [4.887667, 49.906258],
            [4.883308, 49.896181],
            [4.869223, 49.883674],
            [4.862135, 49.869184],
            [4.854189, 49.864789],
            [4.851721, 49.856207],
            [4.85546, 49.848134],
            [4.867577, 49.840076],
            [4.873879, 49.820796],
            [4.867089, 49.814194],
            [4.855377, 49.792362],
            [4.862298, 49.789221],
            [4.880951, 49.792326],
            [4.887543, 49.788172],
            [4.910293, 49.786101],
            [4.930928, 49.786852],
            [4.956128, 49.801228],
            [4.998835, 49.799311],
            [5.00893, 49.781937],
            [5.038248, 49.771526],
            [5.056801, 49.767535],
            [5.063178, 49.761924],
            [5.0935, 49.763727],
            [5.115075, 49.741436],
            [5.12469, 49.727396],
            [5.12307, 49.717306],
            [5.143639, 49.7093],
            [5.153313, 49.718376],
            [5.161569, 49.714805],
            [5.165855, 49.707139],
            [5.166201, 49.692915],
            [5.206604, 49.693601],
            [5.224567, 49.689429],
            [5.232445, 49.690738],
            [5.247413, 49.68699],
            [5.258046, 49.694261],
            [5.269185, 49.696072],
            [5.280761, 49.689032],
            [5.286563, 49.681818],
            [5.30874, 49.671224],
            [5.332764, 49.652788],
            [5.316973, 49.643831],
            [5.304674, 49.62943],
            [5.311732, 49.613547],
            [5.339767, 49.616561],
            [5.342988, 49.62674],
            [5.350336, 49.630863],
            [5.36992, 49.622571],
            [5.393539, 49.617087],
            [5.426913, 49.597345],
            [5.437971, 49.569737],
            [5.453553, 49.564921],
            [5.441564, 49.552225],
            [5.465062, 49.538863],
            [5.466328, 49.52843],
            [5.447579, 49.517373],
            [5.464732, 49.50582],
            [5.470905, 49.497209],
            [5.480126, 49.504205],
            [5.505774, 49.507561],
            [5.532728, 49.51298],
            [5.55605, 49.529251],
            [5.593451, 49.52191],
            [5.60028, 49.517481],
            [5.603625, 49.508662],
            [5.611504, 49.506452],
            [5.621359, 49.519544],
            [5.615922, 49.527126],
            [5.620343, 49.533164],
            [5.636801, 49.545045],
            [5.659774, 49.552869],
            [5.694071, 49.544035],
            [5.70126, 49.53952],
            [5.715283, 49.539335],
            [5.73252, 49.544461],
            [5.738864, 49.53932],
            [5.757375, 49.542824],
            [5.756152, 49.557012],
            [5.775218, 49.562644],
            [5.793935, 49.55124],
            [5.817599, 49.546479],
            [5.81923, 49.537378],
            [5.836959, 49.542475],
            [5.845881, 49.529954],
            [5.834415, 49.526303],
            [5.843715, 49.516116],
            [5.862451, 49.501642],
            [5.869087, 49.498778],
            [5.893404, 49.496912],
            [5.913123, 49.50193],
            [5.927578, 49.498222],
            [5.93994, 49.500974],
            [5.954355, 49.494029],
            [5.971777, 49.49132],
            [5.97199, 49.472772],
            [5.97866, 49.461213],
            [5.987658, 49.462416],
            [5.988982, 49.45333],
            [6.002013, 49.455793],
            [6.009164, 49.452599],
            [6.027645, 49.455537],
            [6.027878, 49.448439],
            [6.042372, 49.447931],
            [6.055622, 49.465362],
            [6.076705, 49.463659],
            [6.081299, 49.458562],
            [6.100816, 49.452882],
            [6.097871, 49.464052],
            [6.102078, 49.470225],
            [6.123492, 49.473754],
            [6.127657, 49.492886],
            [6.143804, 49.487269],
            [6.156906, 49.497456],
            [6.156886, 49.504391],
            [6.180792, 49.50774],
            [6.197268, 49.505967],
            [6.25641, 49.510019],
            [6.262412, 49.504724],
            [6.279098, 49.503353],
            [6.280107, 49.494399],
            [6.296575, 49.480107],
            [6.312493, 49.48032],
            [6.332823, 49.469494],
            [6.34247, 49.469156],
            [6.364397, 49.459485],
            [6.366666, 49.466919],
            [6.39196, 49.46446],
            [6.407618, 49.467512],
            [6.419086, 49.47494],
            [6.43012, 49.4764],
            [6.43959, 49.467282],
            [6.470084, 49.462767],
            [6.484898, 49.452471],
            [6.497663, 49.450632],
            [6.521101, 49.437324],
            [6.532962, 49.435379],
            [6.552235, 49.423348],
            [6.554362, 49.418384],
            [6.538798, 49.412379],
            [6.540524, 49.401146],
            [6.563422, 49.388146],
            [6.573588, 49.389263],
            [6.58708, 49.384937],
            [6.58388, 49.368623],
            [6.594395, 49.371472],
            [6.601856, 49.366781],
            [6.590128, 49.354745],
            [6.57463, 49.358198],
            [6.56477, 49.353135],
            [6.565843, 49.347353],
            [6.577597, 49.335792],
            [6.585476, 49.336045],
            [6.595586, 49.33027],
            [6.589078, 49.321999],
            [6.617539, 49.302171],
            [6.625392, 49.30219],
            [6.651402, 49.285835],
            [6.653228, 49.281117],
            [6.669186, 49.280602],
            [6.667873, 49.27134],
            [6.660328, 49.26097],
            [6.664444, 49.254561],
            [6.678068, 49.255061],
            [6.690544, 49.247634],
            [6.685096, 49.24374],
            [6.689579, 49.235334],
            [6.688135, 49.224446],
            [6.694803, 49.215907],
            [6.719651, 49.221375],
            [6.731497, 49.206089],
            [6.711363, 49.188538],
            [6.720968, 49.174995],
            [6.738508, 49.163661],
            [6.753883, 49.166177],
            [6.784556, 49.168087],
            [6.834543, 49.151467],
            [6.843855, 49.155653],
            [6.847269, 49.173488],
            [6.860947, 49.178714],
            [6.85865, 49.185918],
            [6.850117, 49.193288],
            [6.85218, 49.198655],
            [6.835384, 49.211325],
            [6.857443, 49.222278],
            [6.871324, 49.215473],
            [6.894156, 49.210197],
            [6.927413, 49.222526],
            [6.935419, 49.222156],
            [6.951723, 49.207277],
            [6.959281, 49.203005],
            [6.974633, 49.209818],
            [6.99151, 49.201388],
            [7.010708, 49.18812],
            [7.021392, 49.193184],
            [7.034325, 49.189681],
            [7.02765, 49.170086],
            [7.031678, 49.158253],
            [7.046141, 49.138567],
            [7.044622, 49.121956],
            [7.049759, 49.114534],
            [7.066095, 49.114582],
            [7.071938, 49.124748],
            [7.088875, 49.130224],
            [7.080281, 49.148776],
            [7.097848, 49.155037],
            [7.113266, 49.151695],
            [7.10406, 49.146084],
            [7.104855, 49.138662],
            [7.126446, 49.141725],
            [7.131589, 49.131823],
            [7.156725, 49.121307],
            [7.16322, 49.126663],
            [7.183939, 49.130727],
            [7.201239, 49.116722],
            [7.206637, 49.12348],
            [7.215896, 49.125515],
            [7.247792, 49.127982],
            [7.266344, 49.122592],
            [7.280887, 49.124111],
            [7.286267, 49.117591],
            [7.298647, 49.117456],
            [7.314128, 49.137566],
            [7.329841, 49.144942],
            [7.345333, 49.143232],
            [7.362847, 49.145216],
            [7.365076, 49.171836],
            [7.381368, 49.175274],
            [7.445065, 49.184305],
            [7.43995, 49.180796],
            [7.440672, 49.170483],
            [7.455226, 49.166323],
            [7.491193, 49.169804],
            [7.495443, 49.156592],
            [7.50617, 49.154031],
            [7.49215, 49.141863],
            [7.5047, 49.124892],
            [7.515439, 49.121169],
            [7.524164, 49.105491],
            [7.539006, 49.092068],
            [7.563184, 49.080458],
            [7.57039, 49.079864],
            [7.599984, 49.083328],
            [7.631191, 49.069382],
            [7.635282, 49.054164],
            [7.674489, 49.045042],
            [7.707317, 49.054054],
            [7.723517, 49.052567],
            [7.732224, 49.044356],
            [7.74644, 49.047184],
            [7.756497, 49.045598],
            [7.769941, 49.048753],
            [7.776114, 49.056209],
            [7.79303, 49.063495],
            [7.800442, 49.064115],
            [7.820356, 49.053762],
            [7.844521, 49.044969],
            [7.855493, 49.035416],
            [7.868791, 49.034159],
            [7.881686, 49.045192],
            [7.891618, 49.047595],
            [7.916698, 49.040966],
            [7.934623, 49.057811],
            [7.945066, 49.055272],
            [7.947399, 49.04918],
            [7.96739, 49.038892],
            [7.974852, 49.027746],
            [7.9819, 49.025893],
            [7.999126, 49.02813],
            [8.022704, 49.018325],
            [8.048084, 49.014173],
            [8.067169, 48.999498],
            [8.09138, 48.98926],
            [8.121379, 48.98536],
            [8.141494, 48.978018],
            [8.170148, 48.979348],
            [8.194402, 48.97743],
            [8.199089, 48.967224],
            [8.214342, 48.975096],
            [8.226822, 48.974093],
            [8.230399, 48.965608],
            [8.203035, 48.959937],
            [8.195801, 48.956211],
            [8.176781, 48.932012],
            [8.147939, 48.903811],
            [8.124576, 48.870688],
            [8.10877, 48.836986],
            [8.106061, 48.82369],
            [8.096323, 48.810384],
            [8.077106, 48.795299],
            [8.058945, 48.788813],
            [8.043267, 48.79073],
            [8.029135, 48.786479],
            [8.022825, 48.768353],
            [8.014705, 48.761181],
            [8.004955, 48.758781],
            [7.981756, 48.761014],
            [7.971752, 48.757632],
            [7.967223, 48.745411],
            [7.967257, 48.727018],
            [7.963144, 48.721356],
            [7.890888, 48.663099],
            [7.839822, 48.641377],
            [7.828716, 48.617708],
            [7.804168, 48.592334],
            [7.800237, 48.578684],
            [7.80523, 48.562215],
            [7.803269, 48.533821],
            [7.806955, 48.522278],
            [7.805201, 48.513573],
            [7.794708, 48.502091],
            [7.768193, 48.489661],
            [7.764884, 48.456688],
            [7.761888, 48.448985],
            [7.735972, 48.404162],
            [7.731846, 48.392906],
            [7.732002, 48.375407],
            [7.74503, 48.335662],
            [7.739875, 48.321749],
            [7.733239, 48.317792],
            [7.703147, 48.308813],
            [7.696009, 48.30401],
            [7.689333, 48.293284],
            [7.688976, 48.279873],
            [7.667808, 48.223906],
            [7.664559, 48.219142],
            [7.644069, 48.207228],
            [7.63115, 48.185415],
            [7.601148, 48.158378],
            [7.600604, 48.138846],
            [7.597071, 48.133147],
            [7.582881, 48.126528],
            [7.577316, 48.120371],
            [7.579959, 48.106021],
            [7.577408, 48.095951],
            [7.569234, 48.081402],
            [7.573806, 48.053034],
            [7.56849, 48.035382],
            [7.57162, 48.030376],
            [7.605436, 48.003758],
            [7.61862, 47.985904],
            [7.622086, 47.972272],
            [7.608835, 47.953177],
            [7.593668, 47.941694],
            [7.583294, 47.931119],
            [7.580511, 47.924033],
            [7.583505, 47.903575],
            [7.576943, 47.891476],
            [7.556707, 47.879941],
            [7.557189, 47.863835],
            [7.563552, 47.852137],
            [7.561466, 47.838678],
            [7.549702, 47.822575],
            [7.54531, 47.81128],
            [7.531261, 47.78676],
            [7.531056, 47.770365],
            [7.54808, 47.739589],
            [7.543473, 47.721949],
            [7.534727, 47.714499],
            [7.513752, 47.702815],
            [7.512573, 47.694845],
            [7.519993, 47.682663],
            [7.519603, 47.667762],
            [7.52234, 47.662322],
            [7.538426, 47.648803],
            [7.566473, 47.632896],
            [7.573605, 47.616665],
            [7.592796, 47.60178],
            [7.584707, 47.577266],
            [7.566418, 47.577551],
            [7.55867, 47.573258],
            [7.557195, 47.565031],
            [7.503411, 47.541976],
            [7.499041, 47.535364],
            [7.501392, 47.528725],
            [7.511072, 47.529059],
            [7.519261, 47.534553],
            [7.531426, 47.528284],
            [7.527709, 47.521555],
            [7.505452, 47.51437],
            [7.510509, 47.504115],
            [7.506757, 47.495629],
            [7.486858, 47.481732],
            [7.470907, 47.480413],
            [7.461366, 47.489038],
            [7.450258, 47.490386],
            [7.434557, 47.497928],
            [7.422689, 47.484388],
            [7.455633, 47.472977],
            [7.445013, 47.461705],
            [7.43006, 47.459277],
            [7.422592, 47.447888],
            [7.40341, 47.435524],
            [7.380945, 47.431864],
            [7.355961, 47.433852],
            [7.342478, 47.438086],
            [7.325923, 47.439342],
            [7.301736, 47.437814],
            [7.277786, 47.431674],
            [7.268821, 47.426648],
            [7.246298, 47.422198],
            [7.235768, 47.43693],
            [7.218475, 47.438111],
            [7.206684, 47.434823],
            [7.181191, 47.441481],
            [7.173074, 47.447182],
            [7.178221, 47.458502],
            [7.179241, 47.469724],
            [7.196696, 47.493483],
            [7.167538, 47.489409],
            [7.158952, 47.490758],
            [7.149134, 47.497609],
            [7.130341, 47.503029],
            [7.137873, 47.510602],
            [7.139802, 47.526067],
            [7.13278, 47.53965],
            [7.119203, 47.547271],
            [7.106217, 47.551378],
            [7.108418, 47.563027],
            [7.100244, 47.572349],
            [7.094166, 47.573634],
            [7.086336, 47.585556],
            [7.086272, 47.592565],
            [7.077239, 47.598071],
            [7.064716, 47.601059],
            [7.056507, 47.598941],
            [7.040519, 47.60096],
            [7.025421, 47.59268],
            [7.005955, 47.602446],
            [7.010507, 47.60502],
            [7.004979, 47.619379],
            [7.018036, 47.642567],
            [7.018866, 47.650671],
            [7.039192, 47.650571],
            [7.034431, 47.661171],
            [7.046317, 47.671502],
            [7.038947, 47.677982],
            [7.048046, 47.682334],
            [7.0388, 47.687529],
            [7.037635, 47.694694],
            [7.026486, 47.701001],
            [7.037422, 47.721642],
            [7.019465, 47.736016],
            [7.015962, 47.74323],
            [6.98818, 47.747811],
            [6.969541, 47.75388],
            [6.942564, 47.768619],
            [6.923998, 47.77047],
            [6.909337, 47.777601],
            [6.902086, 47.776444],
            [6.863473, 47.785157],
            [6.842829, 47.812907],
            [6.846176, 47.822943],
            [6.83931, 47.824784],
            [6.823533, 47.813051],
            [6.788428, 47.834484],
            [6.792547, 47.840497],
            [6.784819, 47.84962],
            [6.764465, 47.853457],
            [6.76385, 47.857308],
            [6.737772, 47.861561],
            [6.730438, 47.867533],
            [6.710238, 47.875768],
            [6.703676, 47.881418],
            [6.669663, 47.8932],
            [6.645537, 47.904023],
            [6.630246, 47.925593],
            [6.601785, 47.9444],
            [6.568284, 47.934495],
            [6.542157, 47.902612],
            [6.503214, 47.896219],
            [6.478606, 47.885522],
            [6.456018, 47.906627],
            [6.460436, 47.913451],
            [6.436306, 47.936498],
            [6.431714, 47.943818],
            [6.408887, 47.943264],
            [6.405358, 47.953112],
            [6.394006, 47.956963],
            [6.366153, 47.961944],
            [6.338561, 47.954979],
            [6.324506, 47.94928],
            [6.30976, 47.949875],
            [6.296416, 47.955367],
            [6.277332, 47.953806],
            [6.237983, 47.93284],
            [6.219173, 47.935763],
            [6.205224, 47.932045],
            [6.207961, 47.942753],
            [6.197367, 47.951792],
            [6.178444, 47.954216],
            [6.167989, 47.952353],
            [6.160692, 47.964476],
            [6.150943, 47.970557],
            [6.164889, 47.976134],
            [6.152341, 47.994505],
            [6.156058, 48.006943],
            [6.131708, 48.023715],
            [6.116534, 48.01939],
            [6.109229, 48.012467],
            [6.097739, 48.015043],
            [6.080935, 48.0127],
            [6.072559, 48.015669],
            [6.041177, 48.004423],
            [6.02692, 47.990054],
            [6.018167, 47.984218],
            [6.023513, 47.978198],
            [6.009978, 47.968676],
            [6.002266, 47.956089],
            [5.996511, 47.957642],
            [5.970782, 47.957207],
            [5.968901, 47.947408],
            [5.953718, 47.93703],
            [5.929652, 47.938718],
            [5.93772, 47.950937],
            [5.959513, 47.965839],
            [5.947528, 47.979712],
            [5.936745, 47.978874],
            [5.921714, 47.970784],
            [5.918046, 47.947355],
            [5.900117, 47.944753],
            [5.892904, 47.937141],
            [5.896713, 47.932028],
            [5.884724, 47.926046],
            [5.890864, 47.910826],
            [5.886498, 47.902632],
            [5.869969, 47.900702],
            [5.848394, 47.904329],
            [5.836683, 47.885276],
            [5.821437, 47.868178],
            [5.827986, 47.851868],
            [5.805354, 47.847367],
            [5.798194, 47.852396],
            [5.761197, 47.859338],
            [5.753419, 47.85178],
            [5.744124, 47.848676],
            [5.746051, 47.823594],
            [5.732632, 47.817595],
            [5.703816, 47.822616],
            [5.690285, 47.818602],
            [5.681691, 47.801919],
            [5.676613, 47.779156],
            [5.679972, 47.769967],
            [5.705088, 47.769109],
            [5.709218, 47.763724],
            [5.706155, 47.75229],
            [5.709216, 47.744819],
            [5.693339, 47.737536],
            [5.684835, 47.722252],
            [5.684117, 47.711966],
            [5.693492, 47.7037],
            [5.694632, 47.691589],
            [5.690072, 47.684834],
            [5.660201, 47.684701],
            [5.653411, 47.677378],
            [5.635043, 47.67673],
            [5.616917, 47.673507],
            [5.606093, 47.675207],
            [5.59668, 47.67169],
            [5.580076, 47.703119],
            [5.567204, 47.705233],
            [5.542963, 47.685876],
            [5.529779, 47.672826],
            [5.516771, 47.673666],
            [5.482566, 47.684598],
            [5.47257, 47.676723],
            [5.446034, 47.670774],
            [5.435713, 47.670914],
            [5.426521, 47.675524],
            [5.40634, 47.673403],
            [5.398475, 47.649083],
            [5.405592, 47.647771],
            [5.386847, 47.635662],
            [5.372758, 47.61866],
            [5.37408, 47.604538],
            [5.370635, 47.604808],
            [5.354765, 47.59136],
            [5.342166, 47.597943],
            [5.34, 47.60907],
            [5.322349, 47.61236],
            [5.299839, 47.604926],
            [5.294862, 47.599202],
            [5.278798, 47.590249],
            [5.277906, 47.581361],
            [5.252922, 47.576951],
            [5.248822, 47.588305],
            [5.239582, 47.595736],
            [5.243335, 47.603257],
            [5.258602, 47.622216],
            [5.250544, 47.622148],
            [5.239232, 47.616131],
            [5.227566, 47.630462],
            [5.215302, 47.638777],
            [5.188582, 47.64956],
            [5.173596, 47.652564],
            [5.173773, 47.661555],
            [5.179319, 47.679307],
            [5.161341, 47.679936],
            [5.156277, 47.668257],
            [5.13342, 47.650731],
            [5.108874, 47.649688],
            [5.101726, 47.659488],
            [5.084917, 47.657253],
            [5.070365, 47.666754],
            [5.057887, 47.668306],
            [5.043583, 47.676514],
            [5.060645, 47.69479],
            [5.032795, 47.692333],
            [5.032762, 47.704076],
            [5.026267, 47.709493],
            [5.00436, 47.700727],
            [4.992274, 47.688315],
            [4.97911, 47.687764],
            [4.954139, 47.701463],
            [4.957064, 47.709406],
            [4.970703, 47.727636],
            [4.959893, 47.754471],
            [4.958992, 47.76187],
            [4.949605, 47.765006],
            [4.93074, 47.761333],
            [4.917785, 47.766615],
            [4.918305, 47.777312],
            [4.956449, 47.790048],
            [4.963576, 47.795107],
            [4.982401, 47.800351],
            [4.99062, 47.807093],
            [4.994127, 47.819746],
            [4.984696, 47.828869],
            [4.968346, 47.831939],
            [4.96225, 47.839702],
            [4.960386, 47.857358],
            [4.9541, 47.866767],
            [4.928236, 47.871124],
            [4.928173, 47.886872],
            [4.919092, 47.89472],
            [4.901851, 47.921285],
            [4.894496, 47.92284],
            [4.86934, 47.91745],
            [4.856999, 47.895851],
            [4.834034, 47.906782],
            [4.828942, 47.915124],
            [4.84672, 47.924484],
            [4.850172, 47.929538],
            [4.866094, 47.940509],
            [4.852047, 47.956242],
            [4.841009, 47.960753],
            [4.819424, 47.960359],
            [4.786515, 47.964201],
            [4.794634, 47.983195],
            [4.80919, 47.990088],
            [4.793264, 47.996944],
            [4.789078, 48.007829],
            [4.749297, 48.004247],
            [4.719946, 48.008896],
            [4.704233, 48.020235],
            [4.698553, 48.023678],
            [4.673227, 48.015055],
            [4.663323, 48.02002],
            [4.639625, 48.025482],
            [4.624063, 48.025381],
            [4.612405, 48.030242],
            [4.582673, 48.029463],
            [4.571885, 48.025129],
            [4.567323, 48.018739],
            [4.554851, 48.012319],
            [4.549426, 48.00446],
            [4.535817, 48.001208],
            [4.545599, 47.988294],
            [4.555208, 47.985774],
            [4.559964, 47.971424],
            [4.553309, 47.967682],
            [4.531567, 47.969936],
            [4.515003, 47.966198],
            [4.490801, 47.967275],
            [4.482135, 47.963341],
            [4.449004, 47.957152],
            [4.439404, 47.958224],
            [4.427588, 47.965925],
            [4.41435, 47.968208],
            [4.398491, 47.963429],
            [4.351283, 47.956582],
            [4.309001, 47.961171],
            [4.298969, 47.948925],
            [4.301942, 47.939722],
            [4.293421, 47.925674],
            [4.265593, 47.924113],
            [4.248113, 47.929257],
            [4.236286, 47.940458],
            [4.22214, 47.949693],
            [4.228462, 47.969156],
            [4.212781, 47.971726],
            [4.199154, 47.969941],
            [4.207836, 47.946595],
            [4.201887, 47.941158],
            [4.185389, 47.94025],
            [4.185175, 47.953253],
            [4.181494, 47.957134],
            [4.16632, 47.9598],
            [4.145993, 47.94795],
            [4.141839, 47.937107],
            [4.129355, 47.93604],
            [4.111782, 47.926998],
            [4.091393, 47.929645],
            [4.092975, 47.942979],
            [4.077909, 47.942844],
            [4.061263, 47.945564],
            [4.056312, 47.9415],
            [4.054046, 47.92948],
            [4.043508, 47.928108],
            [4.031546, 47.933181],
            [4.025649, 47.928322],
            [4.012202, 47.9331],
            [4.00559, 47.94201],
            [3.985957, 47.93064],
            [3.956598, 47.934513],
            [3.940327, 47.933141],
            [3.92645, 47.934567],
            [3.912468, 47.930259],
            [3.894036, 47.929291],
            [3.902092, 47.939168],
            [3.905615, 47.956391],
            [3.914696, 47.975697],
            [3.900185, 47.997906],
            [3.885414, 48.000328],
            [3.878302, 47.97942],
            [3.861806, 47.976443],
            [3.864309, 47.984236],
            [3.850028, 47.983792],
            [3.839815, 48.00389],
            [3.861971, 48.004326],
            [3.870001, 48.0026],
            [3.870614, 48.015634],
            [3.850086, 48.027863],
            [3.842547, 48.03619],
            [3.832155, 48.036233],
            [3.821978, 48.043917],
            [3.827059, 48.047441],
            [3.826359, 48.063604],
            [3.820161, 48.06746],
            [3.806798, 48.083861],
            [3.798581, 48.086367],
            [3.799973, 48.096696],
            [3.80497, 48.102547],
            [3.776883, 48.124997],
            [3.768021, 48.133761],
            [3.75691, 48.125307],
            [3.739713, 48.132754],
            [3.739804, 48.138688],
            [3.754829, 48.150245],
            [3.7519, 48.161296],
            [3.740297, 48.16971],
            [3.718464, 48.175364],
            [3.714171, 48.170603],
            [3.722345, 48.156838],
            [3.705016, 48.144315],
            [3.693622, 48.148],
            [3.688755, 48.144279],
            [3.667867, 48.139211],
            [3.65958, 48.159652],
            [3.650654, 48.168228],
            [3.6415, 48.183933],
            [3.631885, 48.18939],
            [3.619545, 48.190785],
            [3.594177, 48.178873],
            [3.575182, 48.188742],
            [3.600997, 48.203801],
            [3.611152, 48.21193],
            [3.614071, 48.220116],
            [3.621611, 48.225744],
            [3.613627, 48.232261],
            [3.604687, 48.229885],
            [3.60008, 48.237038],
            [3.613853, 48.249277],
            [3.624323, 48.254527],
            [3.616802, 48.271344],
            [3.611771, 48.274411],
            [3.588198, 48.280069],
            [3.577796, 48.284593],
            [3.585741, 48.290084],
            [3.587964, 48.300806],
            [3.566569, 48.307426],
            [3.563407, 48.321441],
            [3.544073, 48.319672],
            [3.545102, 48.334543],
            [3.530465, 48.342613],
            [3.512139, 48.360812],
            [3.498154, 48.369098],
            [3.474881, 48.369267],
            [3.470476, 48.374668],
            [3.452589, 48.374388],
            [3.443249, 48.367383],
            [3.427509, 48.359976],
            [3.421681, 48.371728],
            [3.413273, 48.376301],
            [3.414789, 48.390269],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5387",
      properties: {
        name: "Guadeloupe",
        density: 0,
        path: "/world/France/Guadeloupe",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-61.633562, 15.839768],
              [-61.647985, 15.847135],
              [-61.646886, 15.857929],
              [-61.632811, 15.868008],
              [-61.616955, 15.858508],
              [-61.633562, 15.839768],
            ],
          ],
          [
            [
              [-61.593278, 15.852526],
              [-61.605014, 15.85685],
              [-61.59909, 15.862822],
              [-61.584325, 15.864353],
              [-61.583844, 15.876528],
              [-61.568352, 15.871659],
              [-61.576559, 15.866561],
              [-61.578756, 15.859571],
              [-61.586962, 15.857704],
              [-61.593278, 15.852526],
            ],
          ],
          [
            [
              [-61.119932, 16.167153],
              [-61.128888, 16.169446],
              [-61.128429, 16.173508],
              [-61.107911, 16.172739],
              [-61.108228, 16.168902],
              [-61.119932, 16.167153],
            ],
          ],
          [
            [
              [-61.009732, 16.349752],
              [-61.002076, 16.333962],
              [-61.013135, 16.327651],
              [-61.019408, 16.327258],
              [-61.034892, 16.318141],
              [-61.047375, 16.308283],
              [-61.075287, 16.301796],
              [-61.083853, 16.296543],
              [-61.098492, 16.294316],
              [-61.096468, 16.303379],
              [-61.088289, 16.311809],
              [-61.078645, 16.312444],
              [-61.073986, 16.320037],
              [-61.062767, 16.321449],
              [-61.041743, 16.334921],
              [-61.009732, 16.349752],
            ],
          ],
          [
            [
              [-61.587244, 16.344938],
              [-61.586117, 16.352773],
              [-61.579279, 16.346501],
              [-61.587244, 16.344938],
            ],
          ],
          [
            [
              [-61.323119, 15.94961],
              [-61.319301, 15.953828],
              [-61.318359, 15.974425],
              [-61.306997, 15.978583],
              [-61.303421, 15.987163],
              [-61.290717, 16.003397],
              [-61.27601, 16.004687],
              [-61.261828, 16.000183],
              [-61.251664, 15.992645],
              [-61.243967, 15.991362],
              [-61.23207, 15.981799],
              [-61.230908, 15.973182],
              [-61.217655, 15.9585],
              [-61.210098, 15.9544],
              [-61.201119, 15.945734],
              [-61.196153, 15.931799],
              [-61.197228, 15.916016],
              [-61.202131, 15.905462],
              [-61.20802, 15.899614],
              [-61.217817, 15.895177],
              [-61.223252, 15.887676],
              [-61.233121, 15.880256],
              [-61.249483, 15.872324],
              [-61.260137, 15.871122],
              [-61.266137, 15.868277],
              [-61.278683, 15.866954],
              [-61.315008, 15.880547],
              [-61.323359, 15.886983],
              [-61.329503, 15.896539],
              [-61.330917, 15.910314],
              [-61.337223, 15.938423],
              [-61.334518, 15.943644],
              [-61.323119, 15.94961],
            ],
          ],
          [
            [
              [-61.782311, 16.187699],
              [-61.787182, 16.194189],
              [-61.782434, 16.213369],
              [-61.786881, 16.228082],
              [-61.801477, 16.24344],
              [-61.805917, 16.259644],
              [-61.805955, 16.274467],
              [-61.803077, 16.280462],
              [-61.803087, 16.2942],
              [-61.799713, 16.310451],
              [-61.800519, 16.316331],
              [-61.792265, 16.319312],
              [-61.7868, 16.325155],
              [-61.788028, 16.330992],
              [-61.773403, 16.349527],
              [-61.76045, 16.355009],
              [-61.748233, 16.355163],
              [-61.744143, 16.361533],
              [-61.730811, 16.356618],
              [-61.720528, 16.348454],
              [-61.712963, 16.337326],
              [-61.695242, 16.334732],
              [-61.685802, 16.32742],
              [-61.673031, 16.325013],
              [-61.667669, 16.318517],
              [-61.650129, 16.320755],
              [-61.645414, 16.318102],
              [-61.641367, 16.308679],
              [-61.625741, 16.301573],
              [-61.608828, 16.3044],
              [-61.603538, 16.298129],
              [-61.611159, 16.292395],
              [-61.616001, 16.282485],
              [-61.625966, 16.28255],
              [-61.624285, 16.275399],
              [-61.611858, 16.279475],
              [-61.613532, 16.267646],
              [-61.607586, 16.268195],
              [-61.602074, 16.273672],
              [-61.598569, 16.266463],
              [-61.585026, 16.269627],
              [-61.579159, 16.278851],
              [-61.564833, 16.286617],
              [-61.555115, 16.284744],
              [-61.550019, 16.277772],
              [-61.547231, 16.256131],
              [-61.55308, 16.249573],
              [-61.548832, 16.241907],
              [-61.547714, 16.223289],
              [-61.551692, 16.224182],
              [-61.555916, 16.232647],
              [-61.560762, 16.2353],
              [-61.577416, 16.234961],
              [-61.583789, 16.233422],
              [-61.586922, 16.22375],
              [-61.587548, 16.211414],
              [-61.584403, 16.206312],
              [-61.589955, 16.195142],
              [-61.589151, 16.186551],
              [-61.582957, 16.17964],
              [-61.58378, 16.162519],
              [-61.577321, 16.153709],
              [-61.574138, 16.137781],
              [-61.563082, 16.134317],
              [-61.561115, 16.128294],
              [-61.569956, 16.121078],
              [-61.570381, 16.113941],
              [-61.564202, 16.106784],
              [-61.559273, 16.092085],
              [-61.560836, 16.063174],
              [-61.555488, 16.053016],
              [-61.562888, 16.044344],
              [-61.566151, 16.032344],
              [-61.57579, 16.020636],
              [-61.610753, 15.991876],
              [-61.619204, 15.975526],
              [-61.633373, 15.966578],
              [-61.648534, 15.969747],
              [-61.665156, 15.963885],
              [-61.671772, 15.959407],
              [-61.675881, 15.952562],
              [-61.699977, 15.946608],
              [-61.705776, 15.949083],
              [-61.709496, 15.955387],
              [-61.708059, 15.961028],
              [-61.711082, 15.974061],
              [-61.716241, 15.982716],
              [-61.722806, 15.985385],
              [-61.746384, 16.01029],
              [-61.747337, 16.022316],
              [-61.751046, 16.034495],
              [-61.765058, 16.050868],
              [-61.770365, 16.062039],
              [-61.766361, 16.089809],
              [-61.772977, 16.093417],
              [-61.772144, 16.099649],
              [-61.774153, 16.115026],
              [-61.773578, 16.122926],
              [-61.769864, 16.128243],
              [-61.771038, 16.134984],
              [-61.778538, 16.139501],
              [-61.775114, 16.148926],
              [-61.775913, 16.170941],
              [-61.784106, 16.177089],
              [-61.782311, 16.187699],
            ],
          ],
          [
            [
              [-61.493485, 16.353632],
              [-61.497398, 16.358023],
              [-61.495443, 16.375946],
              [-61.500178, 16.384136],
              [-61.507507, 16.389254],
              [-61.511153, 16.387558],
              [-61.525751, 16.394665],
              [-61.534552, 16.41967],
              [-61.538774, 16.437459],
              [-61.535458, 16.454969],
              [-61.523224, 16.462836],
              [-61.518153, 16.470708],
              [-61.507303, 16.474834],
              [-61.504289, 16.483172],
              [-61.494798, 16.48708],
              [-61.485524, 16.493158],
              [-61.479497, 16.500367],
              [-61.474007, 16.510562],
              [-61.465321, 16.513164],
              [-61.465108, 16.507356],
              [-61.460459, 16.500115],
              [-61.453213, 16.498412],
              [-61.444923, 16.500268],
              [-61.442244, 16.489305],
              [-61.435127, 16.487681],
              [-61.422445, 16.474934],
              [-61.411393, 16.469202],
              [-61.407028, 16.447435],
              [-61.401917, 16.442674],
              [-61.398317, 16.430469],
              [-61.398421, 16.417818],
              [-61.40553, 16.395596],
              [-61.404666, 16.383712],
              [-61.401491, 16.374373],
              [-61.381039, 16.34575],
              [-61.375881, 16.341236],
              [-61.351142, 16.333771],
              [-61.332141, 16.333983],
              [-61.321454, 16.330645],
              [-61.314573, 16.33077],
              [-61.287886, 16.321835],
              [-61.269308, 16.311337],
              [-61.265288, 16.305611],
              [-61.256778, 16.299802],
              [-61.245276, 16.276257],
              [-61.232932, 16.266891],
              [-61.220328, 16.263985],
              [-61.2169, 16.260341],
              [-61.202502, 16.25421],
              [-61.196038, 16.25524],
              [-61.191174, 16.250048],
              [-61.203805, 16.249703],
              [-61.21851, 16.25299],
              [-61.23151, 16.253324],
              [-61.254264, 16.256134],
              [-61.269471, 16.250159],
              [-61.276763, 16.250715],
              [-61.288646, 16.244938],
              [-61.317824, 16.240382],
              [-61.322942, 16.24363],
              [-61.327638, 16.241232],
              [-61.339284, 16.241432],
              [-61.347649, 16.23673],
              [-61.370463, 16.232167],
              [-61.379668, 16.222021],
              [-61.39126, 16.222922],
              [-61.405012, 16.216925],
              [-61.412585, 16.211514],
              [-61.423017, 16.20889],
              [-61.436533, 16.208981],
              [-61.45074, 16.200409],
              [-61.4703, 16.198879],
              [-61.490222, 16.204896],
              [-61.504618, 16.206094],
              [-61.50848, 16.214921],
              [-61.512023, 16.216392],
              [-61.524295, 16.213992],
              [-61.529473, 16.215881],
              [-61.530405, 16.224633],
              [-61.539654, 16.236964],
              [-61.54124, 16.243843],
              [-61.549698, 16.251448],
              [-61.546544, 16.254138],
              [-61.548844, 16.272184],
              [-61.548685, 16.281152],
              [-61.551398, 16.28752],
              [-61.547244, 16.298925],
              [-61.538691, 16.305676],
              [-61.534417, 16.312393],
              [-61.533803, 16.325697],
              [-61.535585, 16.331471],
              [-61.528928, 16.339299],
              [-61.519444, 16.343333],
              [-61.507937, 16.342574],
              [-61.506989, 16.350339],
              [-61.493485, 16.353632],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5389",
      properties: { name: "Guyane", density: 0, path: "/world/France/Guyane" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-53.870434, 5.74491],
              [-53.877886, 5.736851],
              [-53.90057, 5.741509],
              [-53.899036, 5.747756],
              [-53.870434, 5.74491],
            ],
          ],
          [
            [
              [-54.262866, 5.276379],
              [-54.240223, 5.286368],
              [-54.227818, 5.298812],
              [-54.208482, 5.303238],
              [-54.196831, 5.318785],
              [-54.184147, 5.323344],
              [-54.174192, 5.341761],
              [-54.147937, 5.362617],
              [-54.132774, 5.384663],
              [-54.117478, 5.417367],
              [-54.092861, 5.440506],
              [-54.063725, 5.480657],
              [-54.046956, 5.491838],
              [-54.037465, 5.510101],
              [-54.018812, 5.521439],
              [-54.009474, 5.545327],
              [-54.003654, 5.573065],
              [-54.006598, 5.589261],
              [-54.004908, 5.611846],
              [-54.014856, 5.640812],
              [-54.011948, 5.663468],
              [-53.9715, 5.734175],
              [-53.968341, 5.745159],
              [-53.93442, 5.746531],
              [-53.895425, 5.733054],
              [-53.836713, 5.732306],
              [-53.799234, 5.723882],
              [-53.786976, 5.718671],
              [-53.733987, 5.688784],
              [-53.712716, 5.680496],
              [-53.700811, 5.670501],
              [-53.647029, 5.640303],
              [-53.630887, 5.63343],
              [-53.592551, 5.608264],
              [-53.584394, 5.609462],
              [-53.508863, 5.578089],
              [-53.469175, 5.566235],
              [-53.453569, 5.568393],
              [-53.403015, 5.563666],
              [-53.365564, 5.551661],
              [-53.308912, 5.538447],
              [-53.300513, 5.552869],
              [-53.271974, 5.561159],
              [-53.269338, 5.552225],
              [-53.234119, 5.542507],
              [-53.187967, 5.525151],
              [-53.175295, 5.523542],
              [-53.13078, 5.506785],
              [-53.12205, 5.497005],
              [-53.074735, 5.470192],
              [-53.060604, 5.465059],
              [-53.01432, 5.455308],
              [-53.00316, 5.438686],
              [-52.996894, 5.459693],
              [-52.981186, 5.454166],
              [-52.956354, 5.455543],
              [-52.930926, 5.443189],
              [-52.922859, 5.429703],
              [-52.875645, 5.395135],
              [-52.867821, 5.382153],
              [-52.855604, 5.377847],
              [-52.834405, 5.347385],
              [-52.831606, 5.333247],
              [-52.811922, 5.32399],
              [-52.788929, 5.307997],
              [-52.769338, 5.278892],
              [-52.722797, 5.236839],
              [-52.683839, 5.209935],
              [-52.666326, 5.200233],
              [-52.6562, 5.185295],
              [-52.637381, 5.166153],
              [-52.628094, 5.162124],
              [-52.627649, 5.146288],
              [-52.606599, 5.134602],
              [-52.577982, 5.108575],
              [-52.549456, 5.100639],
              [-52.490118, 5.040589],
              [-52.468171, 5.022474],
              [-52.460314, 5.012121],
              [-52.420187, 4.98223],
              [-52.402043, 4.964433],
              [-52.390401, 4.945302],
              [-52.364676, 4.920717],
              [-52.355881, 4.917512],
              [-52.334264, 4.93133],
              [-52.335865, 4.941816],
              [-52.304339, 4.950836],
              [-52.283496, 4.934731],
              [-52.263041, 4.914153],
              [-52.26273, 4.899856],
              [-52.249875, 4.889244],
              [-52.229082, 4.859602],
              [-52.210715, 4.858688],
              [-52.200387, 4.8503],
              [-52.182576, 4.820505],
              [-52.159943, 4.808691],
              [-52.145697, 4.790875],
              [-52.139367, 4.796898],
              [-52.114832, 4.786167],
              [-52.100239, 4.771718],
              [-52.089912, 4.768384],
              [-52.069755, 4.751439],
              [-52.017814, 4.718336],
              [-52.006179, 4.701788],
              [-51.916024, 4.668816],
              [-51.89652, 4.676768],
              [-51.86239, 4.6708],
              [-51.832636, 4.65075],
              [-51.823262, 4.635641],
              [-51.811981, 4.629365],
              [-51.799483, 4.614649],
              [-51.784568, 4.574521],
              [-51.781614, 4.544169],
              [-51.77502, 4.520575],
              [-51.776549, 4.490203],
              [-51.768387, 4.468269],
              [-51.748665, 4.447455],
              [-51.752328, 4.418213],
              [-51.737147, 4.404126],
              [-51.711835, 4.388025],
              [-51.692206, 4.395052],
              [-51.692394, 4.384414],
              [-51.707651, 4.375566],
              [-51.718109, 4.357144],
              [-51.718383, 4.340413],
              [-51.711081, 4.314008],
              [-51.701558, 4.294543],
              [-51.683399, 4.271067],
              [-51.675882, 4.253744],
              [-51.661023, 4.243844],
              [-51.656193, 4.226409],
              [-51.626282, 4.195066],
              [-51.634914, 4.176313],
              [-51.637502, 4.133228],
              [-51.647652, 4.079464],
              [-51.656015, 4.053869],
              [-51.67567, 4.036018],
              [-51.702135, 4.024327],
              [-51.708686, 4.017988],
              [-51.745686, 4.001453],
              [-51.775718, 3.976435],
              [-51.782283, 3.964055],
              [-51.780294, 3.940994],
              [-51.78253, 3.920715],
              [-51.798591, 3.886918],
              [-51.821959, 3.868275],
              [-51.823398, 3.858536],
              [-51.837778, 3.849998],
              [-51.85635, 3.832631],
              [-51.873946, 3.803132],
              [-51.89109, 3.796127],
              [-51.896427, 3.785034],
              [-51.920567, 3.780059],
              [-51.923543, 3.764159],
              [-51.918834, 3.74923],
              [-51.923476, 3.722635],
              [-51.955728, 3.720407],
              [-51.972555, 3.705431],
              [-51.978748, 3.6824],
              [-51.986389, 3.67254],
              [-51.991313, 3.640732],
              [-51.988222, 3.627688],
              [-52.019722, 3.593022],
              [-52.060359, 3.519645],
              [-52.069909, 3.518007],
              [-52.073508, 3.499743],
              [-52.086775, 3.48954],
              [-52.095176, 3.461725],
              [-52.108617, 3.451017],
              [-52.138319, 3.393712],
              [-52.150063, 3.375606],
              [-52.173681, 3.331998],
              [-52.187904, 3.317454],
              [-52.189029, 3.302016],
              [-52.210453, 3.277365],
              [-52.233552, 3.24065],
              [-52.242643, 3.237336],
              [-52.249063, 3.250251],
              [-52.25971, 3.253062],
              [-52.274475, 3.235813],
              [-52.292463, 3.226385],
              [-52.302819, 3.199014],
              [-52.299308, 3.178183],
              [-52.330478, 3.167787],
              [-52.339416, 3.143939],
              [-52.349078, 3.135411],
              [-52.351623, 3.123442],
              [-52.338514, 3.116756],
              [-52.34515, 3.108033],
              [-52.343075, 3.093242],
              [-52.325894, 3.077773],
              [-52.345489, 3.04238],
              [-52.353803, 3.021431],
              [-52.362058, 3.017089],
              [-52.370552, 2.975919],
              [-52.39508, 2.927788],
              [-52.379258, 2.912948],
              [-52.390093, 2.89817],
              [-52.405543, 2.900855],
              [-52.419837, 2.893131],
              [-52.444916, 2.850026],
              [-52.455711, 2.822421],
              [-52.473404, 2.793852],
              [-52.478088, 2.776625],
              [-52.478969, 2.753106],
              [-52.491028, 2.729162],
              [-52.499657, 2.721949],
              [-52.510636, 2.693279],
              [-52.529338, 2.654181],
              [-52.541997, 2.653931],
              [-52.555185, 2.640275],
              [-52.542947, 2.623948],
              [-52.548786, 2.610016],
              [-52.531807, 2.598886],
              [-52.527622, 2.581016],
              [-52.537979, 2.566479],
              [-52.561157, 2.550252],
              [-52.551654, 2.520823],
              [-52.565162, 2.516236],
              [-52.584752, 2.496334],
              [-52.593736, 2.477892],
              [-52.616762, 2.475639],
              [-52.621527, 2.462019],
              [-52.611242, 2.455451],
              [-52.616346, 2.445562],
              [-52.637196, 2.442986],
              [-52.646318, 2.432087],
              [-52.644683, 2.418283],
              [-52.655706, 2.405971],
              [-52.654099, 2.395661],
              [-52.660531, 2.373938],
              [-52.680151, 2.370589],
              [-52.710418, 2.358588],
              [-52.71947, 2.343765],
              [-52.769908, 2.320925],
              [-52.793673, 2.304435],
              [-52.819174, 2.29466],
              [-52.839446, 2.291035],
              [-52.838177, 2.281261],
              [-52.856578, 2.280026],
              [-52.853984, 2.263643],
              [-52.869842, 2.260046],
              [-52.877221, 2.238388],
              [-52.886505, 2.234688],
              [-52.886153, 2.219245],
              [-52.901038, 2.198555],
              [-52.913967, 2.192271],
              [-52.93903, 2.189894],
              [-52.939825, 2.179797],
              [-52.952691, 2.174534],
              [-52.9857, 2.168572],
              [-53.001454, 2.177595],
              [-53.041584, 2.187348],
              [-53.060001, 2.196557],
              [-53.062342, 2.204184],
              [-53.080262, 2.222531],
              [-53.094493, 2.221578],
              [-53.133851, 2.225347],
              [-53.18127, 2.21193],
              [-53.209697, 2.210538],
              [-53.226086, 2.202468],
              [-53.244697, 2.211093],
              [-53.263031, 2.189149],
              [-53.275238, 2.20095],
              [-53.272039, 2.222059],
              [-53.260456, 2.223459],
              [-53.258702, 2.236974],
              [-53.237001, 2.252089],
              [-53.226655, 2.26359],
              [-53.237554, 2.269972],
              [-53.256818, 2.270413],
              [-53.260168, 2.285813],
              [-53.280764, 2.301424],
              [-53.300865, 2.307028],
              [-53.314118, 2.323386],
              [-53.317147, 2.340083],
              [-53.33186, 2.334062],
              [-53.333058, 2.345946],
              [-53.356332, 2.347855],
              [-53.358656, 2.327852],
              [-53.37209, 2.312999],
              [-53.388015, 2.303865],
              [-53.399175, 2.304972],
              [-53.406286, 2.289836],
              [-53.433121, 2.283086],
              [-53.444765, 2.272898],
              [-53.451706, 2.259558],
              [-53.46481, 2.252157],
              [-53.498114, 2.259346],
              [-53.525831, 2.252915],
              [-53.54481, 2.253072],
              [-53.56746, 2.267115],
              [-53.581738, 2.268065],
              [-53.604412, 2.280698],
              [-53.619368, 2.273344],
              [-53.626012, 2.279114],
              [-53.657026, 2.281266],
              [-53.653727, 2.297414],
              [-53.669144, 2.303691],
              [-53.689067, 2.295013],
              [-53.698903, 2.311139],
              [-53.728946, 2.310873],
              [-53.739586, 2.305772],
              [-53.747656, 2.319799],
              [-53.725996, 2.338825],
              [-53.741548, 2.373647],
              [-53.751121, 2.36971],
              [-53.765989, 2.375533],
              [-53.783265, 2.361141],
              [-53.796861, 2.361459],
              [-53.810525, 2.351245],
              [-53.809578, 2.341039],
              [-53.834587, 2.33229],
              [-53.827893, 2.322901],
              [-53.815155, 2.322249],
              [-53.813216, 2.31139],
              [-53.835641, 2.303954],
              [-53.851764, 2.303079],
              [-53.882721, 2.3088],
              [-53.885191, 2.27509],
              [-53.896454, 2.272939],
              [-53.905403, 2.260651],
              [-53.915103, 2.262234],
              [-53.915051, 2.278146],
              [-53.933611, 2.269374],
              [-53.94135, 2.259275],
              [-53.936594, 2.250232],
              [-53.940896, 2.219838],
              [-53.964388, 2.218521],
              [-53.973451, 2.206182],
              [-53.990362, 2.209098],
              [-54.017342, 2.182083],
              [-54.029947, 2.187171],
              [-54.045805, 2.18639],
              [-54.064369, 2.196431],
              [-54.0723, 2.178679],
              [-54.082832, 2.172497],
              [-54.085086, 2.148023],
              [-54.095351, 2.132035],
              [-54.112088, 2.117567],
              [-54.12783, 2.122967],
              [-54.159342, 2.12347],
              [-54.174076, 2.136652],
              [-54.190364, 2.171081],
              [-54.224091, 2.149798],
              [-54.254257, 2.15577],
              [-54.267553, 2.144267],
              [-54.288233, 2.147639],
              [-54.319253, 2.159554],
              [-54.341832, 2.151801],
              [-54.339087, 2.168554],
              [-54.360668, 2.1749],
              [-54.364835, 2.184923],
              [-54.354424, 2.202926],
              [-54.363781, 2.210338],
              [-54.397792, 2.196881],
              [-54.421014, 2.20006],
              [-54.42067, 2.213622],
              [-54.433197, 2.213118],
              [-54.441672, 2.20515],
              [-54.460827, 2.207467],
              [-54.478121, 2.21619],
              [-54.476245, 2.225468],
              [-54.503744, 2.254422],
              [-54.523241, 2.252726],
              [-54.535483, 2.260464],
              [-54.538792, 2.272458],
              [-54.520241, 2.276846],
              [-54.519344, 2.289274],
              [-54.533659, 2.292498],
              [-54.538017, 2.304493],
              [-54.532681, 2.316888],
              [-54.543056, 2.32682],
              [-54.591822, 2.325003],
              [-54.601501, 2.336617],
              [-54.590443, 2.351926],
              [-54.558308, 2.351649],
              [-54.522197, 2.336926],
              [-54.509975, 2.336648],
              [-54.510252, 2.371371],
              [-54.504975, 2.391093],
              [-54.496086, 2.405537],
              [-54.471751, 2.43548],
              [-54.43861, 2.432222],
              [-54.419512, 2.436842],
              [-54.390838, 2.468449],
              [-54.389073, 2.480696],
              [-54.37273, 2.491228],
              [-54.369995, 2.508917],
              [-54.352913, 2.518567],
              [-54.3441, 2.539711],
              [-54.34592, 2.561743],
              [-54.334016, 2.566411],
              [-54.322812, 2.592822],
              [-54.321642, 2.609648],
              [-54.312766, 2.613328],
              [-54.317197, 2.626818],
              [-54.29091, 2.660631],
              [-54.280006, 2.666587],
              [-54.269421, 2.685136],
              [-54.273007, 2.693229],
              [-54.264722, 2.723147],
              [-54.235164, 2.747832],
              [-54.208634, 2.776619],
              [-54.208134, 2.793732],
              [-54.20037, 2.801247],
              [-54.206202, 2.828572],
              [-54.185912, 2.837817],
              [-54.179399, 2.858516],
              [-54.189408, 2.867849],
              [-54.193859, 2.887232],
              [-54.185709, 2.905382],
              [-54.18529, 2.916809],
              [-54.172831, 2.938588],
              [-54.175904, 2.957699],
              [-54.164824, 2.973457],
              [-54.181399, 2.983711],
              [-54.181478, 3.001358],
              [-54.169657, 3.007511],
              [-54.170525, 3.020009],
              [-54.190672, 3.069601],
              [-54.174268, 3.072532],
              [-54.181095, 3.090799],
              [-54.173672, 3.105595],
              [-54.18619, 3.135114],
              [-54.198472, 3.123772],
              [-54.21384, 3.150182],
              [-54.203116, 3.157576],
              [-54.204121, 3.167395],
              [-54.194007, 3.197058],
              [-54.178169, 3.206879],
              [-54.152321, 3.241078],
              [-54.136293, 3.266009],
              [-54.121829, 3.271863],
              [-54.117969, 3.284662],
              [-54.100466, 3.300372],
              [-54.081625, 3.293489],
              [-54.064242, 3.312653],
              [-54.067158, 3.324329],
              [-54.062182, 3.352029],
              [-54.053489, 3.363411],
              [-54.059101, 3.378381],
              [-54.034185, 3.404824],
              [-54.020673, 3.411966],
              [-54.012134, 3.427029],
              [-54.019084, 3.436138],
              [-54.016986, 3.456988],
              [-54.008052, 3.46806],
              [-54.008321, 3.506671],
              [-53.999991, 3.524178],
              [-54.009265, 3.537821],
              [-54.004485, 3.560594],
              [-53.98704, 3.580347],
              [-53.980581, 3.608325],
              [-53.989316, 3.61493],
              [-54.003189, 3.645087],
              [-54.022618, 3.646746],
              [-54.029408, 3.631213],
              [-54.050963, 3.635024],
              [-54.05034, 3.652715],
              [-54.056886, 3.660049],
              [-54.082843, 3.674407],
              [-54.086596, 3.687171],
              [-54.078989, 3.706451],
              [-54.084754, 3.723848],
              [-54.095625, 3.733332],
              [-54.100738, 3.751498],
              [-54.11308, 3.768422],
              [-54.121892, 3.792671],
              [-54.130377, 3.799965],
              [-54.166238, 3.805156],
              [-54.186355, 3.800222],
              [-54.197704, 3.805479],
              [-54.201757, 3.823248],
              [-54.189386, 3.841828],
              [-54.196496, 3.851397],
              [-54.236888, 3.860465],
              [-54.250278, 3.886786],
              [-54.24919, 3.908843],
              [-54.2781, 3.920587],
              [-54.289966, 3.935765],
              [-54.299581, 3.967764],
              [-54.318054, 4.013145],
              [-54.336241, 4.0371],
              [-54.357569, 4.050145],
              [-54.344076, 4.095937],
              [-54.344323, 4.11344],
              [-54.323302, 4.1347],
              [-54.325437, 4.150105],
              [-54.340806, 4.155617],
              [-54.366322, 4.176284],
              [-54.387231, 4.179371],
              [-54.395737, 4.202149],
              [-54.388371, 4.245777],
              [-54.382324, 4.263273],
              [-54.390476, 4.280075],
              [-54.39444, 4.306529],
              [-54.391376, 4.33321],
              [-54.385864, 4.349217],
              [-54.394501, 4.364979],
              [-54.411516, 4.373616],
              [-54.435994, 4.364584],
              [-54.440743, 4.372742],
              [-54.432201, 4.415072],
              [-54.441036, 4.438913],
              [-54.432421, 4.467948],
              [-54.437897, 4.490814],
              [-54.450026, 4.525878],
              [-54.425132, 4.556206],
              [-54.417918, 4.578525],
              [-54.415242, 4.60647],
              [-54.436351, 4.636304],
              [-54.435682, 4.653226],
              [-54.423668, 4.665446],
              [-54.426256, 4.683175],
              [-54.433423, 4.697332],
              [-54.427245, 4.714949],
              [-54.435414, 4.724776],
              [-54.458544, 4.72576],
              [-54.466259, 4.739842],
              [-54.459971, 4.777768],
              [-54.467828, 4.814929],
              [-54.465563, 4.824043],
              [-54.468907, 4.863389],
              [-54.468973, 4.890363],
              [-54.478689, 4.903525],
              [-54.44612, 4.93497],
              [-54.439851, 4.952564],
              [-54.443267, 4.986546],
              [-54.436274, 4.993249],
              [-54.437853, 5.017301],
              [-54.425503, 5.03402],
              [-54.416731, 5.060492],
              [-54.4155, 5.084679],
              [-54.375341, 5.107864],
              [-54.365923, 5.135097],
              [-54.350554, 5.143864],
              [-54.346324, 5.161636],
              [-54.333885, 5.176273],
              [-54.31267, 5.212822],
              [-54.308111, 5.225272],
              [-54.285919, 5.249182],
              [-54.278956, 5.262482],
              [-54.262866, 5.276379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5382",
      properties: {
        name: "Hauts-de-France",
        density: 0,
        path: "/world/France/Hauts-de-France",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.140894, 49.978756],
            [4.153979, 49.974841],
            [4.172391, 49.977081],
            [4.197114, 49.967724],
            [4.197361, 49.954735],
            [4.225981, 49.959065],
            [4.233063, 49.957825],
            [4.230396, 49.947205],
            [4.221602, 49.937902],
            [4.218709, 49.920686],
            [4.222139, 49.910422],
            [4.233245, 49.904305],
            [4.254064, 49.904005],
            [4.253053, 49.872272],
            [4.248506, 49.856518],
            [4.236622, 49.849699],
            [4.223488, 49.833765],
            [4.219892, 49.815134],
            [4.214772, 49.80611],
            [4.227046, 49.793183],
            [4.222431, 49.786967],
            [4.209588, 49.781777],
            [4.214264, 49.771268],
            [4.226619, 49.773088],
            [4.238219, 49.767643],
            [4.250094, 49.757099],
            [4.22902, 49.745745],
            [4.224707, 49.727199],
            [4.206463, 49.723538],
            [4.192629, 49.715801],
            [4.192036, 49.710906],
            [4.178301, 49.698036],
            [4.144187, 49.688544],
            [4.147538, 49.67802],
            [4.127027, 49.677918],
            [4.122332, 49.659521],
            [4.125466, 49.650608],
            [4.114791, 49.635305],
            [4.099313, 49.62848],
            [4.069556, 49.636752],
            [4.051111, 49.634644],
            [4.04325, 49.636146],
            [4.037911, 49.622919],
            [4.02529, 49.62253],
            [4.031396, 49.614287],
            [4.054191, 49.595289],
            [4.06511, 49.594909],
            [4.059561, 49.57871],
            [4.062782, 49.573557],
            [4.076572, 49.571126],
            [4.064794, 49.562212],
            [4.058291, 49.552548],
            [4.048768, 49.545273],
            [4.073801, 49.541618],
            [4.076749, 49.531315],
            [4.075835, 49.518345],
            [4.065098, 49.523284],
            [4.056624, 49.521047],
            [4.061858, 49.512563],
            [4.053159, 49.508645],
            [4.040706, 49.508532],
            [4.042375, 49.468691],
            [4.052914, 49.460257],
            [4.060247, 49.447151],
            [4.050024, 49.445792],
            [4.037669, 49.438263],
            [4.043145, 49.429326],
            [4.042296, 49.416651],
            [4.050275, 49.412261],
            [4.047974, 49.405644],
            [4.039906, 49.397393],
            [4.035498, 49.359906],
            [4.012733, 49.358303],
            [4.005109, 49.369209],
            [3.991365, 49.378222],
            [3.96131, 49.377347],
            [3.942414, 49.391461],
            [3.931831, 49.403085],
            [3.924439, 49.406179],
            [3.915074, 49.399385],
            [3.878782, 49.385857],
            [3.859145, 49.381352],
            [3.857347, 49.369946],
            [3.847476, 49.364589],
            [3.858709, 49.353737],
            [3.852156, 49.344655],
            [3.832779, 49.350262],
            [3.823758, 49.356987],
            [3.801516, 49.358931],
            [3.782444, 49.352774],
            [3.774462, 49.35464],
            [3.757818, 49.347604],
            [3.741081, 49.347586],
            [3.742221, 49.336511],
            [3.721617, 49.335726],
            [3.701293, 49.332508],
            [3.6891, 49.326851],
            [3.670333, 49.325093],
            [3.660162, 49.31837],
            [3.646957, 49.315153],
            [3.638334, 49.301355],
            [3.642802, 49.296041],
            [3.657318, 49.291022],
            [3.651609, 49.278361],
            [3.665132, 49.269658],
            [3.655249, 49.262848],
            [3.663796, 49.253525],
            [3.670779, 49.2396],
            [3.676373, 49.238361],
            [3.665022, 49.223221],
            [3.651609, 49.221427],
            [3.658406, 49.211001],
            [3.681794, 49.20534],
            [3.683908, 49.197534],
            [3.697103, 49.205473],
            [3.704875, 49.19859],
            [3.700549, 49.187713],
            [3.704389, 49.181369],
            [3.725439, 49.173516],
            [3.737224, 49.177961],
            [3.751142, 49.177699],
            [3.748768, 49.157085],
            [3.73957, 49.156933],
            [3.717013, 49.14704],
            [3.701621, 49.143083],
            [3.698032, 49.149828],
            [3.683838, 49.154491],
            [3.671804, 49.150635],
            [3.646874, 49.149123],
            [3.622252, 49.151105],
            [3.610395, 49.127544],
            [3.600011, 49.12069],
            [3.620076, 49.107194],
            [3.632341, 49.086509],
            [3.639051, 49.081304],
            [3.632872, 49.07279],
            [3.623795, 49.070865],
            [3.612154, 49.07356],
            [3.587708, 49.059395],
            [3.5867, 49.035796],
            [3.615019, 49.033501],
            [3.616841, 49.036484],
            [3.646312, 49.04059],
            [3.663955, 49.037308],
            [3.677482, 49.019165],
            [3.674966, 49.011723],
            [3.665164, 49.005632],
            [3.639893, 49.004086],
            [3.624537, 48.983752],
            [3.620654, 48.965944],
            [3.599176, 48.964609],
            [3.591553, 48.960375],
            [3.599065, 48.953592],
            [3.601596, 48.944074],
            [3.588888, 48.943911],
            [3.574445, 48.939019],
            [3.573848, 48.921481],
            [3.569814, 48.914706],
            [3.560022, 48.917013],
            [3.528571, 48.912137],
            [3.513061, 48.894728],
            [3.50215, 48.871151],
            [3.482405, 48.864937],
            [3.485183, 48.85191],
            [3.470467, 48.851],
            [3.461906, 48.837795],
            [3.445362, 48.843227],
            [3.452235, 48.856295],
            [3.420974, 48.86395],
            [3.403221, 48.86469],
            [3.405849, 48.875883],
            [3.389742, 48.871208],
            [3.380479, 48.874761],
            [3.382812, 48.888744],
            [3.369008, 48.893888],
            [3.375505, 48.907224],
            [3.372206, 48.921685],
            [3.366346, 48.922834],
            [3.353572, 48.915243],
            [3.344122, 48.915424],
            [3.330294, 48.908707],
            [3.313077, 48.921215],
            [3.312875, 48.93362],
            [3.304754, 48.948767],
            [3.284527, 48.940531],
            [3.267847, 48.93833],
            [3.257143, 48.957063],
            [3.251832, 48.972364],
            [3.244197, 48.976835],
            [3.231213, 48.976753],
            [3.229174, 48.988415],
            [3.209314, 48.993784],
            [3.207165, 49.000687],
            [3.183652, 49.011251],
            [3.171679, 49.014126],
            [3.161029, 49.024224],
            [3.176533, 49.030276],
            [3.181201, 49.042313],
            [3.190203, 49.046494],
            [3.182165, 49.052357],
            [3.181845, 49.062074],
            [3.16867, 49.076194],
            [3.158617, 49.080496],
            [3.156869, 49.086717],
            [3.16523, 49.099654],
            [3.149529, 49.100548],
            [3.128898, 49.106713],
            [3.101945, 49.108666],
            [3.08243, 49.112379],
            [3.07188, 49.117553],
            [3.056317, 49.101914],
            [3.057355, 49.09399],
            [3.048356, 49.086316],
            [3.032782, 49.089068],
            [3.029525, 49.085932],
            [3.008516, 49.091507],
            [2.991237, 49.084011],
            [2.987996, 49.072372],
            [2.974745, 49.074788],
            [2.970474, 49.088974],
            [2.944584, 49.081605],
            [2.917451, 49.079816],
            [2.901508, 49.085373],
            [2.894949, 49.077063],
            [2.883075, 49.076071],
            [2.866268, 49.070694],
            [2.855742, 49.07026],
            [2.845148, 49.084656],
            [2.836089, 49.082987],
            [2.81985, 49.087367],
            [2.809053, 49.097538],
            [2.79187, 49.090226],
            [2.787074, 49.075274],
            [2.760424, 49.062962],
            [2.73501, 49.060453],
            [2.732417, 49.069856],
            [2.720486, 49.074887],
            [2.706179, 49.065307],
            [2.690995, 49.066576],
            [2.692195, 49.072099],
            [2.684801, 49.08107],
            [2.667693, 49.092495],
            [2.633277, 49.108373],
            [2.620531, 49.095141],
            [2.610335, 49.09494],
            [2.607212, 49.089062],
            [2.590528, 49.079654],
            [2.583304, 49.080706],
            [2.578686, 49.091953],
            [2.558176, 49.098382],
            [2.552015, 49.113947],
            [2.556155, 49.118628],
            [2.540809, 49.122242],
            [2.532994, 49.119245],
            [2.531059, 49.099597],
            [2.48993, 49.106359],
            [2.502096, 49.118887],
            [2.499232, 49.12227],
            [2.481707, 49.126919],
            [2.461507, 49.135937],
            [2.435402, 49.133939],
            [2.440904, 49.145804],
            [2.415354, 49.151762],
            [2.391285, 49.14935],
            [2.383003, 49.155913],
            [2.370938, 49.159174],
            [2.3593, 49.147344],
            [2.346263, 49.161816],
            [2.322263, 49.18086],
            [2.31093, 49.186405],
            [2.3012, 49.183887],
            [2.299423, 49.175835],
            [2.288806, 49.17071],
            [2.286268, 49.160278],
            [2.269545, 49.156307],
            [2.262209, 49.158297],
            [2.252481, 49.152881],
            [2.223005, 49.151869],
            [2.216355, 49.154378],
            [2.23541, 49.167036],
            [2.218646, 49.180685],
            [2.204959, 49.174316],
            [2.181953, 49.173676],
            [2.164274, 49.166005],
            [2.157743, 49.172599],
            [2.164571, 49.179671],
            [2.13274, 49.191379],
            [2.113337, 49.186544],
            [2.09688, 49.189757],
            [2.09154, 49.20436],
            [2.080885, 49.209774],
            [2.066842, 49.202861],
            [2.038092, 49.192136],
            [2.021786, 49.188672],
            [2.004502, 49.17761],
            [1.996824, 49.176453],
            [1.973443, 49.183894],
            [1.960717, 49.173517],
            [1.948017, 49.170749],
            [1.931476, 49.174168],
            [1.894536, 49.166041],
            [1.885235, 49.162636],
            [1.877042, 49.171449],
            [1.845543, 49.169897],
            [1.837082, 49.171699],
            [1.826576, 49.179517],
            [1.814398, 49.176162],
            [1.795474, 49.185263],
            [1.790979, 49.179854],
            [1.777555, 49.184105],
            [1.755171, 49.174546],
            [1.742142, 49.180152],
            [1.737436, 49.194603],
            [1.725825, 49.195713],
            [1.715576, 49.203118],
            [1.723039, 49.209679],
            [1.733682, 49.210959],
            [1.734109, 49.221278],
            [1.729662, 49.229197],
            [1.704359, 49.232197],
            [1.699043, 49.234864],
            [1.701418, 49.25246],
            [1.710482, 49.264466],
            [1.754721, 49.270008],
            [1.764355, 49.263082],
            [1.766894, 49.252034],
            [1.789534, 49.247871],
            [1.791806, 49.256428],
            [1.802674, 49.271948],
            [1.79323, 49.274331],
            [1.796488, 49.284112],
            [1.775625, 49.299694],
            [1.7676, 49.31589],
            [1.77277, 49.333136],
            [1.757698, 49.356711],
            [1.759413, 49.368145],
            [1.747581, 49.37312],
            [1.741214, 49.381721],
            [1.720313, 49.39483],
            [1.740106, 49.405315],
            [1.713931, 49.409225],
            [1.720755, 49.421791],
            [1.722799, 49.433058],
            [1.732506, 49.440347],
            [1.737574, 49.448744],
            [1.751256, 49.452316],
            [1.747714, 49.459538],
            [1.766341, 49.466149],
            [1.775816, 49.475733],
            [1.774103, 49.48411],
            [1.78748, 49.493411],
            [1.790225, 49.503468],
            [1.771862, 49.512848],
            [1.757959, 49.508956],
            [1.743704, 49.495261],
            [1.738743, 49.499647],
            [1.725453, 49.499782],
            [1.719234, 49.508225],
            [1.730942, 49.516127],
            [1.745129, 49.531736],
            [1.74461, 49.539601],
            [1.727182, 49.541626],
            [1.729622, 49.561515],
            [1.714777, 49.576403],
            [1.721617, 49.588806],
            [1.709401, 49.586714],
            [1.707903, 49.595121],
            [1.695188, 49.599725],
            [1.704583, 49.605397],
            [1.721603, 49.621992],
            [1.717252, 49.633072],
            [1.704629, 49.639706],
            [1.703454, 49.645178],
            [1.722566, 49.661514],
            [1.725199, 49.672637],
            [1.752185, 49.680961],
            [1.750639, 49.693805],
            [1.737534, 49.70035],
            [1.721428, 49.691451],
            [1.717707, 49.684373],
            [1.704518, 49.680928],
            [1.689574, 49.694787],
            [1.711986, 49.707714],
            [1.715188, 49.71282],
            [1.712169, 49.731959],
            [1.723234, 49.730085],
            [1.74095, 49.738588],
            [1.741629, 49.751631],
            [1.747145, 49.757054],
            [1.783834, 49.758309],
            [1.784472, 49.763593],
            [1.772804, 49.776869],
            [1.757928, 49.780795],
            [1.754475, 49.79063],
            [1.737387, 49.808209],
            [1.727053, 49.828882],
            [1.724683, 49.845733],
            [1.719514, 49.854067],
            [1.718733, 49.865708],
            [1.711744, 49.873718],
            [1.712456, 49.886444],
            [1.693295, 49.895602],
            [1.678451, 49.918131],
            [1.618211, 49.937822],
            [1.594009, 49.949051],
            [1.57389, 49.973922],
            [1.558258, 49.979389],
            [1.54656, 49.987582],
            [1.527019, 49.996531],
            [1.518015, 50.009102],
            [1.501039, 50.018916],
            [1.493311, 50.01774],
            [1.473493, 50.0336],
            [1.456457, 50.037832],
            [1.455286, 50.056299],
            [1.459152, 50.062495],
            [1.446453, 50.069324],
            [1.423594, 50.070852],
            [1.408835, 50.057247],
            [1.39244, 50.060056],
            [1.379698, 50.065012],
            [1.392695, 50.075508],
            [1.432645, 50.094349],
            [1.453883, 50.110331],
            [1.468046, 50.139077],
            [1.47871, 50.164914],
            [1.491677, 50.184159],
            [1.512444, 50.201706],
            [1.548436, 50.215217],
            [1.559653, 50.210793],
            [1.57424, 50.196012],
            [1.596192, 50.185502],
            [1.611574, 50.191033],
            [1.627414, 50.190005],
            [1.673346, 50.174681],
            [1.683794, 50.183026],
            [1.670837, 50.19426],
            [1.669635, 50.205896],
            [1.662666, 50.213594],
            [1.639201, 50.217277],
            [1.622501, 50.215152],
            [1.592062, 50.248546],
            [1.590273, 50.255949],
            [1.562293, 50.255766],
            [1.549313, 50.260404],
            [1.540336, 50.273871],
            [1.537942, 50.282668],
            [1.550936, 50.350319],
            [1.55583, 50.361316],
            [1.571331, 50.358452],
            [1.612966, 50.360293],
            [1.624258, 50.366291],
            [1.633978, 50.355381],
            [1.64154, 50.352149],
            [1.631387, 50.360086],
            [1.625231, 50.371833],
            [1.609294, 50.370744],
            [1.600128, 50.37772],
            [1.584684, 50.376383],
            [1.5703, 50.392158],
            [1.563295, 50.39495],
            [1.557795, 50.404714],
            [1.563411, 50.418461],
            [1.569107, 50.440883],
            [1.577004, 50.493788],
            [1.57742, 50.516534],
            [1.585409, 50.537352],
            [1.626756, 50.521386],
            [1.619295, 50.535275],
            [1.608428, 50.54011],
            [1.600097, 50.549489],
            [1.581268, 50.56277],
            [1.576604, 50.572223],
            [1.577686, 50.579873],
            [1.578417, 50.640285],
            [1.572097, 50.664196],
            [1.562465, 50.687458],
            [1.560707, 50.699673],
            [1.573604, 50.716166],
            [1.562701, 50.724987],
            [1.584127, 50.731155],
            [1.590522, 50.729796],
            [1.596001, 50.746196],
            [1.604393, 50.76283],
            [1.604209, 50.791871],
            [1.610641, 50.803762],
            [1.599684, 50.80585],
            [1.590154, 50.825395],
            [1.583314, 50.845703],
            [1.577942, 50.853334],
            [1.580633, 50.867344],
            [1.58873, 50.87167],
            [1.601662, 50.871164],
            [1.635804, 50.876985],
            [1.6641, 50.890134],
            [1.692537, 50.915373],
            [1.712261, 50.929409],
            [1.728856, 50.937893],
            [1.780717, 50.954866],
            [1.851151, 50.965665],
            [1.852701, 50.970956],
            [1.871166, 50.974936],
            [1.889694, 50.972315],
            [1.915779, 50.984087],
            [1.939241, 50.987859],
            [1.959845, 50.987203],
            [1.992737, 50.993729],
            [2.039567, 50.997311],
            [2.047718, 51.002861],
            [2.067705, 51.006502],
            [2.084504, 51.008607],
            [2.109705, 51.003828],
            [2.123516, 51.014168],
            [2.141505, 51.022059],
            [2.167346, 51.020738],
            [2.179423, 51.015103],
            [2.192561, 51.027437],
            [2.191829, 51.034715],
            [2.214001, 51.031682],
            [2.259022, 51.04349],
            [2.331858, 51.056056],
            [2.347833, 51.05983],
            [2.366647, 51.05192],
            [2.378265, 51.049622],
            [2.399586, 51.050957],
            [2.424754, 51.055622],
            [2.493467, 51.073071],
            [2.543035, 51.088544],
            [2.546321, 51.088403],
            [2.559926, 51.068919],
            [2.57598, 51.013756],
            [2.573998, 51.003522],
            [2.582804, 50.997122],
            [2.601285, 50.991284],
            [2.610592, 50.976188],
            [2.63268, 50.946009],
            [2.618277, 50.938762],
            [2.590222, 50.919275],
            [2.594097, 50.914372],
            [2.607532, 50.912407],
            [2.606319, 50.899326],
            [2.607163, 50.873044],
            [2.610326, 50.862062],
            [2.59959, 50.853333],
            [2.625615, 50.83656],
            [2.627263, 50.827651],
            [2.634976, 50.812756],
            [2.658897, 50.814992],
            [2.669026, 50.822462],
            [2.686235, 50.81343],
            [2.717475, 50.813607],
            [2.72564, 50.808632],
            [2.721927, 50.801594],
            [2.726703, 50.792383],
            [2.737512, 50.782667],
            [2.762152, 50.770757],
            [2.75843, 50.762931],
            [2.766042, 50.754519],
            [2.781946, 50.751091],
            [2.786739, 50.733817],
            [2.795656, 50.724609],
            [2.813274, 50.716946],
            [2.848386, 50.722915],
            [2.85473, 50.714709],
            [2.870172, 50.702911],
            [2.88565, 50.706611],
            [2.898379, 50.694239],
            [2.910907, 50.694496],
            [2.911594, 50.703839],
            [2.92233, 50.702801],
            [2.93078, 50.716243],
            [2.929869, 50.722759],
            [2.940837, 50.733175],
            [2.938069, 50.744344],
            [2.95824, 50.753446],
            [2.969659, 50.749613],
            [2.981088, 50.756658],
            [3.012093, 50.769112],
            [3.035275, 50.770239],
            [3.040882, 50.775688],
            [3.061377, 50.780502],
            [3.079896, 50.773021],
            [3.088699, 50.773371],
            [3.106796, 50.783861],
            [3.114767, 50.794188],
            [3.124988, 50.786438],
            [3.150783, 50.790127],
            [3.152001, 50.782343],
            [3.192753, 50.738373],
            [3.199134, 50.734732],
            [3.191227, 50.724409],
            [3.204072, 50.719907],
            [3.215507, 50.712361],
            [3.241487, 50.712571],
            [3.258377, 50.700647],
            [3.260121, 50.693081],
            [3.254802, 50.686811],
            [3.262427, 50.678483],
            [3.244587, 50.670121],
            [3.241102, 50.657785],
            [3.248504, 50.638041],
            [3.259021, 50.630051],
            [3.261268, 50.618848],
            [3.270297, 50.610813],
            [3.277504, 50.59296],
            [3.276165, 50.581685],
            [3.28172, 50.577327],
            [3.275963, 50.558538],
            [3.280173, 50.54165],
            [3.286524, 50.527578],
            [3.307629, 50.519352],
            [3.323616, 50.515712],
            [3.328858, 50.508124],
            [3.337322, 50.508504],
            [3.362664, 50.503102],
            [3.373896, 50.491828],
            [3.389297, 50.496497],
            [3.407611, 50.49903],
            [3.43225, 50.507122],
            [3.450081, 50.507358],
            [3.453738, 50.51924],
            [3.473964, 50.533564],
            [3.487975, 50.529512],
            [3.503771, 50.529588],
            [3.519405, 50.522872],
            [3.515053, 50.51194],
            [3.496327, 50.498685],
            [3.500774, 50.487265],
            [3.526069, 50.494642],
            [3.567768, 50.500612],
            [3.585343, 50.490538],
            [3.599871, 50.493844],
            [3.613017, 50.492471],
            [3.632185, 50.476505],
            [3.64365, 50.463211],
            [3.655997, 50.461231],
            [3.664218, 50.453174],
            [3.660107, 50.444371],
            [3.668846, 50.436855],
            [3.673709, 50.404245],
            [3.672374, 50.387652],
            [3.658267, 50.371344],
            [3.667279, 50.360268],
            [3.667372, 50.350557],
            [3.673843, 50.342706],
            [3.673676, 50.334926],
            [3.693622, 50.32215],
            [3.694433, 50.315828],
            [3.710431, 50.30317],
            [3.715609, 50.312634],
            [3.731734, 50.311905],
            [3.730033, 50.323304],
            [3.736223, 50.343257],
            [3.747405, 50.350928],
            [3.7637, 50.348299],
            [3.768117, 50.352409],
            [3.798785, 50.351333],
            [3.813665, 50.353165],
            [3.821386, 50.34574],
            [3.829755, 50.352692],
            [3.852756, 50.351594],
            [3.859478, 50.342899],
            [3.872286, 50.337942],
            [3.88845, 50.338223],
            [3.889794, 50.329976],
            [3.900403, 50.327566],
            [3.91447, 50.33037],
            [3.967899, 50.350369],
            [3.98447, 50.341953],
            [3.993904, 50.348611],
            [4.015164, 50.351814],
            [4.027377, 50.357489],
            [4.037617, 50.342951],
            [4.048809, 50.3396],
            [4.07819, 50.320552],
            [4.078887, 50.309753],
            [4.097168, 50.313208],
            [4.112909, 50.30207],
            [4.11996, 50.300056],
            [4.125941, 50.286248],
            [4.124358, 50.273612],
            [4.136652, 50.274296],
            [4.136279, 50.256815],
            [4.156126, 50.256602],
            [4.167608, 50.259699],
            [4.168472, 50.266122],
            [4.151174, 50.278121],
            [4.162674, 50.28892],
            [4.176676, 50.284428],
            [4.181283, 50.274458],
            [4.203249, 50.27452],
            [4.221852, 50.256954],
            [4.220693, 50.252205],
            [4.204294, 50.240014],
            [4.183902, 50.232827],
            [4.171949, 50.218985],
            [4.150765, 50.213106],
            [4.160119, 50.202535],
            [4.153907, 50.181619],
            [4.149394, 50.174783],
            [4.155121, 50.16677],
            [4.148285, 50.158155],
            [4.137753, 50.151612],
            [4.126921, 50.135009],
            [4.143707, 50.129043],
            [4.152447, 50.12895],
            [4.157517, 50.135527],
            [4.179837, 50.133558],
            [4.191691, 50.134591],
            [4.199887, 50.131081],
            [4.203171, 50.114887],
            [4.197502, 50.109174],
            [4.201408, 50.10154],
            [4.216445, 50.091114],
            [4.22754, 50.079679],
            [4.227996, 50.066369],
            [4.191096, 50.049492],
            [4.177412, 50.045997],
            [4.162224, 50.048982],
            [4.136374, 50.020461],
            [4.135483, 50.015042],
            [4.145677, 50.003909],
            [4.161743, 50.000913],
            [4.157708, 49.988249],
            [4.140894, 49.978756],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5378",
      properties: {
        name: "Île-de-France",
        density: 0,
        path: "/world/France/Île-de-France",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.922146, 48.457599],
            [1.920738, 48.44775],
            [1.906555, 48.445672],
            [1.906408, 48.440147],
            [1.877489, 48.440614],
            [1.844863, 48.449361],
            [1.836384, 48.466478],
            [1.801451, 48.466086],
            [1.796331, 48.484188],
            [1.785709, 48.489937],
            [1.790635, 48.497377],
            [1.778617, 48.508132],
            [1.775754, 48.527703],
            [1.787242, 48.553746],
            [1.76741, 48.559406],
            [1.765323, 48.569375],
            [1.745863, 48.576137],
            [1.73683, 48.572281],
            [1.709249, 48.578026],
            [1.701984, 48.584999],
            [1.71786, 48.606851],
            [1.714898, 48.614392],
            [1.689422, 48.611536],
            [1.679359, 48.618489],
            [1.666223, 48.613704],
            [1.656339, 48.622053],
            [1.658233, 48.627646],
            [1.649561, 48.632271],
            [1.651572, 48.638125],
            [1.64099, 48.64339],
            [1.643191, 48.651291],
            [1.629848, 48.648875],
            [1.615616, 48.652789],
            [1.602492, 48.663765],
            [1.605863, 48.678763],
            [1.611775, 48.689474],
            [1.582315, 48.696368],
            [1.57954, 48.701812],
            [1.594881, 48.709311],
            [1.589538, 48.712468],
            [1.617805, 48.735942],
            [1.626404, 48.748092],
            [1.602688, 48.760487],
            [1.584905, 48.763297],
            [1.587597, 48.773606],
            [1.580399, 48.777559],
            [1.575674, 48.790504],
            [1.583424, 48.807169],
            [1.591206, 48.814867],
            [1.583728, 48.832131],
            [1.594182, 48.834219],
            [1.595192, 48.839707],
            [1.577273, 48.844358],
            [1.581597, 48.855],
            [1.578039, 48.860864],
            [1.56664, 48.866184],
            [1.555213, 48.865076],
            [1.546233, 48.872432],
            [1.559508, 48.882055],
            [1.557253, 48.891576],
            [1.538524, 48.906686],
            [1.541797, 48.91548],
            [1.538618, 48.921795],
            [1.524102, 48.924752],
            [1.512895, 48.922585],
            [1.509014, 48.936627],
            [1.501527, 48.941052],
            [1.501059, 48.952678],
            [1.496606, 48.969345],
            [1.515549, 48.976406],
            [1.507909, 48.983767],
            [1.497416, 48.979551],
            [1.477093, 48.978791],
            [1.47061, 48.975306],
            [1.46092, 48.986434],
            [1.478751, 48.998885],
            [1.472182, 49.018491],
            [1.457757, 49.026295],
            [1.457953, 49.034833],
            [1.447429, 49.045189],
            [1.447285, 49.053509],
            [1.460801, 49.06274],
            [1.484877, 49.051545],
            [1.503294, 49.059098],
            [1.511601, 49.074136],
            [1.521772, 49.068547],
            [1.549473, 49.072558],
            [1.557074, 49.069621],
            [1.574738, 49.078438],
            [1.604487, 49.083037],
            [1.608799, 49.077894],
            [1.623323, 49.086078],
            [1.617534, 49.093997],
            [1.647091, 49.124714],
            [1.655782, 49.130164],
            [1.653923, 49.145441],
            [1.664379, 49.153368],
            [1.667017, 49.178614],
            [1.676955, 49.202543],
            [1.675742, 49.211867],
            [1.704064, 49.22932],
            [1.704359, 49.232197],
            [1.729662, 49.229197],
            [1.734109, 49.221278],
            [1.733682, 49.210959],
            [1.723039, 49.209679],
            [1.715576, 49.203118],
            [1.725825, 49.195713],
            [1.737436, 49.194603],
            [1.742142, 49.180152],
            [1.755171, 49.174546],
            [1.777555, 49.184105],
            [1.790979, 49.179854],
            [1.795474, 49.185263],
            [1.814398, 49.176162],
            [1.826576, 49.179517],
            [1.837082, 49.171699],
            [1.845543, 49.169897],
            [1.877042, 49.171449],
            [1.885235, 49.162636],
            [1.894536, 49.166041],
            [1.931476, 49.174168],
            [1.948017, 49.170749],
            [1.960717, 49.173517],
            [1.973443, 49.183894],
            [1.996824, 49.176453],
            [2.004502, 49.17761],
            [2.021786, 49.188672],
            [2.038092, 49.192136],
            [2.066842, 49.202861],
            [2.080885, 49.209774],
            [2.09154, 49.20436],
            [2.09688, 49.189757],
            [2.113337, 49.186544],
            [2.13274, 49.191379],
            [2.164571, 49.179671],
            [2.157743, 49.172599],
            [2.164274, 49.166005],
            [2.181953, 49.173676],
            [2.204959, 49.174316],
            [2.218646, 49.180685],
            [2.23541, 49.167036],
            [2.216355, 49.154378],
            [2.223005, 49.151869],
            [2.252481, 49.152881],
            [2.262209, 49.158297],
            [2.269545, 49.156307],
            [2.286268, 49.160278],
            [2.288806, 49.17071],
            [2.299423, 49.175835],
            [2.3012, 49.183887],
            [2.31093, 49.186405],
            [2.322263, 49.18086],
            [2.346263, 49.161816],
            [2.3593, 49.147344],
            [2.370938, 49.159174],
            [2.383003, 49.155913],
            [2.391285, 49.14935],
            [2.415354, 49.151762],
            [2.440904, 49.145804],
            [2.435402, 49.133939],
            [2.461507, 49.135937],
            [2.481707, 49.126919],
            [2.499232, 49.12227],
            [2.502096, 49.118887],
            [2.48993, 49.106359],
            [2.531059, 49.099597],
            [2.532994, 49.119245],
            [2.540809, 49.122242],
            [2.556155, 49.118628],
            [2.552015, 49.113947],
            [2.558176, 49.098382],
            [2.578686, 49.091953],
            [2.583304, 49.080706],
            [2.590528, 49.079654],
            [2.607212, 49.089062],
            [2.610335, 49.09494],
            [2.620531, 49.095141],
            [2.633277, 49.108373],
            [2.667693, 49.092495],
            [2.684801, 49.08107],
            [2.692195, 49.072099],
            [2.690995, 49.066576],
            [2.706179, 49.065307],
            [2.720486, 49.074887],
            [2.732417, 49.069856],
            [2.73501, 49.060453],
            [2.760424, 49.062962],
            [2.787074, 49.075274],
            [2.79187, 49.090226],
            [2.809053, 49.097538],
            [2.81985, 49.087367],
            [2.836089, 49.082987],
            [2.845148, 49.084656],
            [2.855742, 49.07026],
            [2.866268, 49.070694],
            [2.883075, 49.076071],
            [2.894949, 49.077063],
            [2.901508, 49.085373],
            [2.917451, 49.079816],
            [2.944584, 49.081605],
            [2.970474, 49.088974],
            [2.974745, 49.074788],
            [2.987996, 49.072372],
            [2.991237, 49.084011],
            [3.008516, 49.091507],
            [3.029525, 49.085932],
            [3.032782, 49.089068],
            [3.048356, 49.086316],
            [3.057355, 49.09399],
            [3.056317, 49.101914],
            [3.07188, 49.117553],
            [3.08243, 49.112379],
            [3.101945, 49.108666],
            [3.128898, 49.106713],
            [3.149529, 49.100548],
            [3.16523, 49.099654],
            [3.156869, 49.086717],
            [3.158617, 49.080496],
            [3.16867, 49.076194],
            [3.181845, 49.062074],
            [3.182165, 49.052357],
            [3.190203, 49.046494],
            [3.181201, 49.042313],
            [3.176533, 49.030276],
            [3.161029, 49.024224],
            [3.171679, 49.014126],
            [3.183652, 49.011251],
            [3.207165, 49.000687],
            [3.209314, 48.993784],
            [3.229174, 48.988415],
            [3.231213, 48.976753],
            [3.244197, 48.976835],
            [3.251832, 48.972364],
            [3.257143, 48.957063],
            [3.267847, 48.93833],
            [3.284527, 48.940531],
            [3.304754, 48.948767],
            [3.312875, 48.93362],
            [3.313077, 48.921215],
            [3.330294, 48.908707],
            [3.344122, 48.915424],
            [3.353572, 48.915243],
            [3.366346, 48.922834],
            [3.372206, 48.921685],
            [3.375505, 48.907224],
            [3.369008, 48.893888],
            [3.382812, 48.888744],
            [3.380479, 48.874761],
            [3.389742, 48.871208],
            [3.405849, 48.875883],
            [3.403221, 48.86469],
            [3.420974, 48.86395],
            [3.452235, 48.856295],
            [3.445362, 48.843227],
            [3.461906, 48.837795],
            [3.470467, 48.851],
            [3.485183, 48.85191],
            [3.489574, 48.839868],
            [3.484968, 48.82501],
            [3.487202, 48.81519],
            [3.480929, 48.812186],
            [3.470382, 48.820898],
            [3.454412, 48.81384],
            [3.432166, 48.812258],
            [3.416618, 48.817829],
            [3.404165, 48.809276],
            [3.410478, 48.803943],
            [3.44129, 48.800919],
            [3.442161, 48.784354],
            [3.428211, 48.779485],
            [3.409423, 48.783836],
            [3.400841, 48.767256],
            [3.398658, 48.755599],
            [3.412961, 48.753902],
            [3.42567, 48.75569],
            [3.43581, 48.753572],
            [3.440606, 48.738702],
            [3.467467, 48.735224],
            [3.464308, 48.707378],
            [3.476969, 48.699355],
            [3.465832, 48.686193],
            [3.454556, 48.682119],
            [3.442691, 48.672503],
            [3.440849, 48.663073],
            [3.460432, 48.653009],
            [3.453116, 48.633956],
            [3.476573, 48.637329],
            [3.488194, 48.644796],
            [3.503184, 48.645683],
            [3.517498, 48.643365],
            [3.519064, 48.63347],
            [3.545903, 48.628065],
            [3.555614, 48.620286],
            [3.534618, 48.610353],
            [3.503855, 48.604807],
            [3.515864, 48.589782],
            [3.497506, 48.589955],
            [3.485275, 48.580427],
            [3.473383, 48.576874],
            [3.465522, 48.570485],
            [3.472296, 48.564913],
            [3.47977, 48.550623],
            [3.479601, 48.544709],
            [3.459185, 48.53074],
            [3.438228, 48.528334],
            [3.423448, 48.533559],
            [3.414239, 48.533468],
            [3.405397, 48.528015],
            [3.423914, 48.51436],
            [3.434639, 48.490252],
            [3.420355, 48.491547],
            [3.388358, 48.480409],
            [3.396439, 48.463729],
            [3.406484, 48.452452],
            [3.403567, 48.438739],
            [3.396989, 48.434568],
            [3.391959, 48.424318],
            [3.411836, 48.421316],
            [3.413623, 48.41434],
            [3.422079, 48.413341],
            [3.414789, 48.390269],
            [3.402033, 48.389706],
            [3.383317, 48.399704],
            [3.367213, 48.394317],
            [3.362785, 48.382577],
            [3.365161, 48.372282],
            [3.33011, 48.372121],
            [3.309322, 48.37698],
            [3.305159, 48.372877],
            [3.282392, 48.37752],
            [3.264777, 48.374621],
            [3.254386, 48.36502],
            [3.232849, 48.370333],
            [3.201851, 48.364015],
            [3.184947, 48.36813],
            [3.179671, 48.375721],
            [3.167734, 48.371858],
            [3.139865, 48.3726],
            [3.118256, 48.366788],
            [3.103415, 48.349511],
            [3.087841, 48.358744],
            [3.060548, 48.357442],
            [3.049523, 48.360117],
            [3.03749, 48.343151],
            [3.036574, 48.326028],
            [3.015898, 48.307897],
            [3.027256, 48.300368],
            [3.020415, 48.29344],
            [3.029771, 48.285841],
            [3.024964, 48.276009],
            [3.043633, 48.272021],
            [3.047566, 48.249696],
            [3.031462, 48.248879],
            [3.018566, 48.235193],
            [3.018924, 48.231775],
            [3.00516, 48.207666],
            [2.988966, 48.209015],
            [2.97447, 48.20555],
            [2.969547, 48.193835],
            [2.950743, 48.190203],
            [2.934745, 48.178821],
            [2.936316, 48.163392],
            [2.868033, 48.156437],
            [2.859006, 48.14735],
            [2.841195, 48.137851],
            [2.825253, 48.133557],
            [2.820884, 48.129664],
            [2.800902, 48.133252],
            [2.798633, 48.150165],
            [2.809946, 48.1613],
            [2.798946, 48.168273],
            [2.780168, 48.167547],
            [2.768611, 48.163611],
            [2.746265, 48.163803],
            [2.741463, 48.159773],
            [2.753574, 48.153211],
            [2.755194, 48.145653],
            [2.729149, 48.13909],
            [2.706543, 48.124819],
            [2.672223, 48.124202],
            [2.663414, 48.122204],
            [2.646371, 48.136008],
            [2.639738, 48.138859],
            [2.60269, 48.131484],
            [2.577572, 48.132078],
            [2.57056, 48.140816],
            [2.538115, 48.140651],
            [2.521512, 48.127298],
            [2.490313, 48.126606],
            [2.464866, 48.129109],
            [2.451087, 48.123459],
            [2.444253, 48.131468],
            [2.456083, 48.135818],
            [2.478373, 48.156744],
            [2.483232, 48.164516],
            [2.506324, 48.156438],
            [2.516843, 48.166808],
            [2.509755, 48.177878],
            [2.512965, 48.192979],
            [2.522944, 48.200517],
            [2.514113, 48.214378],
            [2.506186, 48.238532],
            [2.486653, 48.240104],
            [2.469093, 48.255276],
            [2.450164, 48.250037],
            [2.431932, 48.255142],
            [2.423762, 48.260296],
            [2.417831, 48.278395],
            [2.42317, 48.289316],
            [2.42076, 48.299253],
            [2.404812, 48.314593],
            [2.402663, 48.320718],
            [2.396853, 48.314755],
            [2.369816, 48.308674],
            [2.340155, 48.32023],
            [2.327695, 48.333053],
            [2.312594, 48.330804],
            [2.295419, 48.308222],
            [2.266892, 48.314682],
            [2.25357, 48.300243],
            [2.245152, 48.298394],
            [2.249436, 48.314581],
            [2.238043, 48.316371],
            [2.24631, 48.329962],
            [2.229592, 48.329229],
            [2.223914, 48.336416],
            [2.202252, 48.344599],
            [2.19821, 48.337063],
            [2.185025, 48.326429],
            [2.181355, 48.313746],
            [2.169025, 48.312696],
            [2.15439, 48.315911],
            [2.155708, 48.304494],
            [2.163748, 48.298437],
            [2.13612, 48.299248],
            [2.110594, 48.296944],
            [2.113717, 48.307245],
            [2.106168, 48.307636],
            [2.081505, 48.293604],
            [2.052714, 48.29547],
            [2.049377, 48.289753],
            [2.022845, 48.28808],
            [2.007238, 48.284689],
            [1.99409, 48.286584],
            [1.975292, 48.287202],
            [1.96661, 48.295582],
            [1.959226, 48.308685],
            [1.969441, 48.314232],
            [1.974492, 48.323164],
            [1.982343, 48.328313],
            [1.974947, 48.335258],
            [1.973395, 48.352975],
            [1.986824, 48.362141],
            [1.976311, 48.380271],
            [1.966382, 48.381588],
            [1.976571, 48.399385],
            [1.961954, 48.404321],
            [1.94277, 48.405651],
            [1.930561, 48.404127],
            [1.925889, 48.412742],
            [1.937807, 48.42639],
            [1.942897, 48.441084],
            [1.933125, 48.442264],
            [1.930423, 48.453403],
            [1.922146, 48.457599],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5390",
      properties: {
        name: "La Réunion",
        density: 0,
        path: "/world/France/La Réunion",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [55.254154, -21.109738],
            [55.230936, -21.090935],
            [55.221239, -21.078095],
            [55.222673, -21.055648],
            [55.217135, -21.03647],
            [55.233692, -21.01928],
            [55.244908, -21.019876],
            [55.256553, -21.016234],
            [55.274829, -21.003237],
            [55.279329, -20.995807],
            [55.282766, -20.978605],
            [55.278748, -20.963382],
            [55.278231, -20.952052],
            [55.288113, -20.937199],
            [55.289292, -20.923219],
            [55.304332, -20.929152],
            [55.325053, -20.929558],
            [55.343017, -20.922804],
            [55.35514, -20.91008],
            [55.386297, -20.889371],
            [55.394037, -20.882986],
            [55.421142, -20.87444],
            [55.437381, -20.876568],
            [55.45665, -20.876527],
            [55.471366, -20.884562],
            [55.485094, -20.885877],
            [55.502874, -20.882269],
            [55.54156, -20.894673],
            [55.551075, -20.895348],
            [55.557176, -20.891552],
            [55.58731, -20.896549],
            [55.592452, -20.895958],
            [55.617891, -20.909357],
            [55.640381, -20.913263],
            [55.664378, -20.925161],
            [55.680839, -20.942755],
            [55.694616, -20.96306],
            [55.700684, -20.978612],
            [55.700687, -21.005727],
            [55.702241, -21.016338],
            [55.707789, -21.028026],
            [55.713564, -21.029916],
            [55.72809, -21.046589],
            [55.732198, -21.061972],
            [55.738734, -21.071506],
            [55.752531, -21.085301],
            [55.762611, -21.100605],
            [55.771434, -21.106971],
            [55.780073, -21.12189],
            [55.800058, -21.127272],
            [55.808258, -21.136109],
            [55.826737, -21.145764],
            [55.834386, -21.16025],
            [55.832909, -21.166582],
            [55.836628, -21.183406],
            [55.825382, -21.187946],
            [55.824708, -21.203252],
            [55.814059, -21.214294],
            [55.808521, -21.22753],
            [55.804226, -21.252892],
            [55.802021, -21.257819],
            [55.803217, -21.276421],
            [55.808281, -21.287306],
            [55.805404, -21.296579],
            [55.804504, -21.312483],
            [55.807296, -21.336761],
            [55.79985, -21.346519],
            [55.779849, -21.362096],
            [55.773607, -21.365232],
            [55.760772, -21.36357],
            [55.74131, -21.369205],
            [55.727081, -21.369854],
            [55.719399, -21.373939],
            [55.702545, -21.376893],
            [55.681553, -21.374734],
            [55.670862, -21.383822],
            [55.659193, -21.382841],
            [55.652833, -21.385828],
            [55.638028, -21.382997],
            [55.625637, -21.382827],
            [55.609007, -21.386905],
            [55.584505, -21.37238],
            [55.571433, -21.375965],
            [55.547527, -21.369147],
            [55.536725, -21.363099],
            [55.527169, -21.361949],
            [55.501003, -21.350598],
            [55.488681, -21.350477],
            [55.459523, -21.341144],
            [55.454781, -21.331585],
            [55.448947, -21.328377],
            [55.429384, -21.326205],
            [55.417434, -21.320149],
            [55.407899, -21.306824],
            [55.390688, -21.29216],
            [55.367872, -21.284875],
            [55.340821, -21.280815],
            [55.33223, -21.27304],
            [55.330559, -21.258678],
            [55.30982, -21.239376],
            [55.294129, -21.230373],
            [55.283074, -21.202892],
            [55.286773, -21.185049],
            [55.286733, -21.160641],
            [55.274414, -21.150557],
            [55.271388, -21.135425],
            [55.256063, -21.11671],
            [55.254154, -21.109738],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5388",
      properties: {
        name: "Martinique",
        density: 0,
        path: "/world/France/Martinique",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.204046, 14.86191],
            [-61.197936, 14.863721],
            [-61.182379, 14.87175],
            [-61.1797, 14.874987],
            [-61.17659, 14.874151],
            [-61.165398, 14.877462],
            [-61.159765, 14.878099],
            [-61.143622, 14.877549],
            [-61.126961, 14.874513],
            [-61.122403, 14.871857],
            [-61.11273, 14.869344],
            [-61.104559, 14.865304],
            [-61.086511, 14.853139],
            [-61.084326, 14.853122],
            [-61.072086, 14.844078],
            [-61.06265, 14.838715],
            [-61.056208, 14.834031],
            [-61.05206, 14.832575],
            [-61.046553, 14.834748],
            [-61.04307, 14.83435],
            [-61.038637, 14.830934],
            [-61.029652, 14.827292],
            [-61.020962, 14.817006],
            [-61.020722, 14.812889],
            [-61.018108, 14.809025],
            [-61.010639, 14.807475],
            [-61.005854, 14.808424],
            [-61.001947, 14.807135],
            [-61.005114, 14.801825],
            [-61.004038, 14.797656],
            [-60.998851, 14.790742],
            [-60.989653, 14.780496],
            [-60.981617, 14.777411],
            [-60.980401, 14.770928],
            [-60.973916, 14.763914],
            [-60.968368, 14.760495],
            [-60.966271, 14.756619],
            [-60.967643, 14.754141],
            [-60.963387, 14.747235],
            [-60.961805, 14.738044],
            [-60.956756, 14.736376],
            [-60.952713, 14.742395],
            [-60.952421, 14.748496],
            [-60.942115, 14.756822],
            [-60.93218, 14.762253],
            [-60.92478, 14.761157],
            [-60.920487, 14.758318],
            [-60.912172, 14.762455],
            [-60.909527, 14.766683],
            [-60.902412, 14.76703],
            [-60.901688, 14.769692],
            [-60.897454, 14.77011],
            [-60.892068, 14.774133],
            [-60.888428, 14.780161],
            [-60.885594, 14.780047],
            [-60.878114, 14.775101],
            [-60.875693, 14.775398],
            [-60.873213, 14.768007],
            [-60.875445, 14.763233],
            [-60.871877, 14.759903],
            [-60.87866, 14.757925],
            [-60.881832, 14.761343],
            [-60.882292, 14.76641],
            [-60.893504, 14.76542],
            [-60.89701, 14.758667],
            [-60.890507, 14.75599],
            [-60.888468, 14.753261],
            [-60.89094, 14.747766],
            [-60.893433, 14.749368],
            [-60.898114, 14.746062],
            [-60.898061, 14.742082],
            [-60.892246, 14.741302],
            [-60.893047, 14.737023],
            [-60.900342, 14.734449],
            [-60.904339, 14.737117],
            [-60.908172, 14.734888],
            [-60.910946, 14.736265],
            [-60.911107, 14.741038],
            [-60.912953, 14.744738],
            [-60.91944, 14.74669],
            [-60.926279, 14.750725],
            [-60.930942, 14.749226],
            [-60.937489, 14.749913],
            [-60.938693, 14.745627],
            [-60.94313, 14.743358],
            [-60.944436, 14.733466],
            [-60.946209, 14.728054],
            [-60.944357, 14.722417],
            [-60.940187, 14.721207],
            [-60.937124, 14.716074],
            [-60.934155, 14.715507],
            [-60.930607, 14.721763],
            [-60.928675, 14.715191],
            [-60.933817, 14.712067],
            [-60.933117, 14.707676],
            [-60.924562, 14.708195],
            [-60.920777, 14.710198],
            [-60.918689, 14.714567],
            [-60.913567, 14.715632],
            [-60.915295, 14.709996],
            [-60.914206, 14.702865],
            [-60.908757, 14.69952],
            [-60.902504, 14.702],
            [-60.901404, 14.695842],
            [-60.906841, 14.695886],
            [-60.910535, 14.688864],
            [-60.913016, 14.691733],
            [-60.923411, 14.688246],
            [-60.926306, 14.68886],
            [-60.929215, 14.685945],
            [-60.926578, 14.680272],
            [-60.93017, 14.678764],
            [-60.93302, 14.681862],
            [-60.937431, 14.682214],
            [-60.93819, 14.675891],
            [-60.943012, 14.671996],
            [-60.942037, 14.667241],
            [-60.935603, 14.664792],
            [-60.93502, 14.66143],
            [-60.929955, 14.653352],
            [-60.925495, 14.653339],
            [-60.920326, 14.655124],
            [-60.916246, 14.663233],
            [-60.910554, 14.665629],
            [-60.905987, 14.66238],
            [-60.897037, 14.665245],
            [-60.897682, 14.661181],
            [-60.891434, 14.658869],
            [-60.891186, 14.665015],
            [-60.886851, 14.666426],
            [-60.882958, 14.66549],
            [-60.883165, 14.658801],
            [-60.886523, 14.652815],
            [-60.890501, 14.650045],
            [-60.897189, 14.649704],
            [-60.90024, 14.651662],
            [-60.906035, 14.648183],
            [-60.905313, 14.646007],
            [-60.898824, 14.644598],
            [-60.896207, 14.641457],
            [-60.894218, 14.635946],
            [-60.891096, 14.632235],
            [-60.897509, 14.627406],
            [-60.897769, 14.620038],
            [-60.891316, 14.619623],
            [-60.888173, 14.620849],
            [-60.884493, 14.619047],
            [-60.875284, 14.620303],
            [-60.874773, 14.615733],
            [-60.877966, 14.612322],
            [-60.869217, 14.604745],
            [-60.872098, 14.600067],
            [-60.87246, 14.596312],
            [-60.867125, 14.591675],
            [-60.858555, 14.589322],
            [-60.852333, 14.593746],
            [-60.850467, 14.585502],
            [-60.851621, 14.579982],
            [-60.847496, 14.576208],
            [-60.853588, 14.575447],
            [-60.85263, 14.570021],
            [-60.846031, 14.564984],
            [-60.842483, 14.566897],
            [-60.84065, 14.564485],
            [-60.841631, 14.559384],
            [-60.830243, 14.569597],
            [-60.830105, 14.560283],
            [-60.834551, 14.556929],
            [-60.838568, 14.550001],
            [-60.836099, 14.546227],
            [-60.831704, 14.544743],
            [-60.826991, 14.537922],
            [-60.831977, 14.531634],
            [-60.825277, 14.533114],
            [-60.824198, 14.529534],
            [-60.825859, 14.521546],
            [-60.830149, 14.524204],
            [-60.834715, 14.52266],
            [-60.832888, 14.519774],
            [-60.836428, 14.513993],
            [-60.834705, 14.50991],
            [-60.82997, 14.514527],
            [-60.824906, 14.514891],
            [-60.822674, 14.510666],
            [-60.823767, 14.503691],
            [-60.822794, 14.494437],
            [-60.817886, 14.493175],
            [-60.814401, 14.490258],
            [-60.814161, 14.485186],
            [-60.81104, 14.478867],
            [-60.813412, 14.474533],
            [-60.819316, 14.47135],
            [-60.814865, 14.461682],
            [-60.82364, 14.452443],
            [-60.822625, 14.447236],
            [-60.824508, 14.440062],
            [-60.827157, 14.435157],
            [-60.829195, 14.435717],
            [-60.834379, 14.432278],
            [-60.832686, 14.430004],
            [-60.835513, 14.425823],
            [-60.83466, 14.422876],
            [-60.840513, 14.422338],
            [-60.843385, 14.427472],
            [-60.848641, 14.427425],
            [-60.847225, 14.419936],
            [-60.845293, 14.416021],
            [-60.837656, 14.414012],
            [-60.840523, 14.410465],
            [-60.844529, 14.413529],
            [-60.847789, 14.412335],
            [-60.85034, 14.407835],
            [-60.852856, 14.406816],
            [-60.858073, 14.39793],
            [-60.862945, 14.395642],
            [-60.869264, 14.394744],
            [-60.876608, 14.398196],
            [-60.878928, 14.402871],
            [-60.885051, 14.405408],
            [-60.885439, 14.413603],
            [-60.893181, 14.419183],
            [-60.885859, 14.427442],
            [-60.879903, 14.438426],
            [-60.881202, 14.443092],
            [-60.87972, 14.447375],
            [-60.872628, 14.44881],
            [-60.873868, 14.452481],
            [-60.866932, 14.459207],
            [-60.862964, 14.461435],
            [-60.866076, 14.470458],
            [-60.87559, 14.470237],
            [-60.882969, 14.466212],
            [-60.888823, 14.459692],
            [-60.894299, 14.4517],
            [-60.897691, 14.448879],
            [-60.900164, 14.449917],
            [-60.909584, 14.459602],
            [-60.910096, 14.468919],
            [-60.916982, 14.468857],
            [-60.928755, 14.464277],
            [-60.938368, 14.463969],
            [-60.943589, 14.46625],
            [-60.950921, 14.466578],
            [-60.955112, 14.465076],
            [-60.961881, 14.470825],
            [-60.965767, 14.471339],
            [-60.973092, 14.468292],
            [-60.978402, 14.471227],
            [-60.975945, 14.475729],
            [-60.981361, 14.482282],
            [-60.985409, 14.47603],
            [-60.989984, 14.472917],
            [-60.992687, 14.466924],
            [-60.99669, 14.468199],
            [-61.000438, 14.472003],
            [-61.003854, 14.478813],
            [-61.007938, 14.474051],
            [-61.010635, 14.473563],
            [-61.017352, 14.480047],
            [-61.020923, 14.480525],
            [-61.032513, 14.477087],
            [-61.04067, 14.473531],
            [-61.046572, 14.467833],
            [-61.047429, 14.460469],
            [-61.050106, 14.457053],
            [-61.054726, 14.45433],
            [-61.058628, 14.454132],
            [-61.065234, 14.457662],
            [-61.067298, 14.460347],
            [-61.067668, 14.465504],
            [-61.072093, 14.46911],
            [-61.076749, 14.467516],
            [-61.081693, 14.470219],
            [-61.082964, 14.473395],
            [-61.079315, 14.481462],
            [-61.079894, 14.489379],
            [-61.090818, 14.492942],
            [-61.089718, 14.496393],
            [-61.084641, 14.498277],
            [-61.084059, 14.501031],
            [-61.086565, 14.50643],
            [-61.089827, 14.507562],
            [-61.095526, 14.506405],
            [-61.10044, 14.50721],
            [-61.094625, 14.523809],
            [-61.091362, 14.525232],
            [-61.084494, 14.535039],
            [-61.07501, 14.541254],
            [-61.068569, 14.539802],
            [-61.06451, 14.541944],
            [-61.061206, 14.550306],
            [-61.055325, 14.550919],
            [-61.05311, 14.554473],
            [-61.054196, 14.557647],
            [-61.049855, 14.559875],
            [-61.05057, 14.551297],
            [-61.044231, 14.552937],
            [-61.040414, 14.556413],
            [-61.037361, 14.555032],
            [-61.037782, 14.549792],
            [-61.04413, 14.546222],
            [-61.040691, 14.541447],
            [-61.035029, 14.541178],
            [-61.030681, 14.539336],
            [-61.026585, 14.535417],
            [-61.023016, 14.539776],
            [-61.017132, 14.538283],
            [-61.01382, 14.535048],
            [-61.01, 14.541213],
            [-61.005418, 14.53973],
            [-61.000688, 14.543899],
            [-60.99421, 14.546335],
            [-60.996092, 14.549679],
            [-60.991082, 14.553047],
            [-60.996857, 14.561007],
            [-61.001423, 14.55955],
            [-61.010575, 14.564803],
            [-61.014139, 14.570566],
            [-61.010523, 14.574789],
            [-61.010672, 14.578769],
            [-61.014437, 14.579612],
            [-61.016032, 14.582868],
            [-61.023258, 14.583521],
            [-61.02426, 14.585608],
            [-61.023039, 14.591884],
            [-61.016737, 14.595273],
            [-61.014811, 14.602491],
            [-61.020175, 14.602646],
            [-61.022123, 14.605351],
            [-61.019049, 14.611297],
            [-61.024188, 14.613009],
            [-61.0272, 14.608872],
            [-61.040305, 14.603916],
            [-61.037773, 14.59776],
            [-61.037893, 14.593543],
            [-61.043405, 14.595212],
            [-61.04816, 14.598324],
            [-61.052479, 14.598356],
            [-61.055948, 14.594991],
            [-61.060946, 14.596974],
            [-61.057705, 14.603369],
            [-61.067501, 14.601387],
            [-61.07209, 14.602347],
            [-61.083307, 14.600057],
            [-61.090646, 14.599998],
            [-61.096409, 14.606491],
            [-61.099081, 14.612315],
            [-61.110996, 14.625268],
            [-61.130022, 14.632754],
            [-61.133322, 14.632641],
            [-61.137283, 14.635304],
            [-61.139874, 14.642954],
            [-61.149357, 14.649285],
            [-61.153006, 14.655258],
            [-61.158414, 14.661152],
            [-61.162147, 14.668353],
            [-61.166963, 14.674281],
            [-61.175727, 14.687118],
            [-61.178814, 14.692837],
            [-61.18416, 14.705944],
            [-61.184328, 14.713361],
            [-61.176554, 14.741206],
            [-61.177254, 14.746184],
            [-61.184652, 14.755915],
            [-61.199273, 14.769086],
            [-61.202705, 14.775396],
            [-61.209676, 14.781051],
            [-61.215105, 14.78231],
            [-61.220773, 14.79352],
            [-61.227146, 14.804145],
            [-61.22894, 14.811754],
            [-61.228608, 14.823283],
            [-61.223236, 14.837038],
            [-61.21899, 14.843859],
            [-61.215471, 14.84752],
            [-61.210497, 14.857932],
            [-61.204046, 14.86191],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5391",
      properties: {
        name: "Mayotte",
        density: 0,
        path: "/world/France/Mayotte",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [45.041009, -12.646861],
              [45.033919, -12.654206],
              [45.029934, -12.653141],
              [45.03023, -12.650971],
              [45.02782, -12.64862],
              [45.02048, -12.645615],
              [45.0204, -12.643017],
              [45.027012, -12.636672],
              [45.033231, -12.639976],
              [45.035738, -12.642859],
              [45.041009, -12.646861],
            ],
          ],
          [
            [
              [45.271347, -12.793306],
              [45.263687, -12.787485],
              [45.258224, -12.783995],
              [45.254251, -12.78278],
              [45.256559, -12.780407],
              [45.258573, -12.784062],
              [45.265897, -12.788803],
              [45.270645, -12.786009],
              [45.271204, -12.783346],
              [45.276064, -12.774297],
              [45.27539, -12.771258],
              [45.2803, -12.765824],
              [45.284846, -12.758997],
              [45.287309, -12.763046],
              [45.292622, -12.767828],
              [45.293809, -12.771584],
              [45.293943, -12.77574],
              [45.296926, -12.777399],
              [45.295512, -12.780959],
              [45.297066, -12.783155],
              [45.294177, -12.787646],
              [45.296547, -12.795716],
              [45.292119, -12.799547],
              [45.286803, -12.799966],
              [45.28447, -12.804915],
              [45.28488, -12.815501],
              [45.279182, -12.811172],
              [45.278673, -12.805292],
              [45.276653, -12.801412],
              [45.274354, -12.799834],
              [45.271347, -12.793306],
            ],
          ],
          [
            [
              [45.05158, -12.701684],
              [45.054745, -12.70096],
              [45.061199, -12.701571],
              [45.065508, -12.700233],
              [45.0685, -12.697535],
              [45.072638, -12.691879],
              [45.07352, -12.687563],
              [45.072444, -12.683316],
              [45.07705, -12.683635],
              [45.082064, -12.680642],
              [45.083278, -12.678411],
              [45.082367, -12.672072],
              [45.08547, -12.668003],
              [45.092309, -12.664211],
              [45.095632, -12.65888],
              [45.103556, -12.654644],
              [45.102129, -12.659473],
              [45.104328, -12.661511],
              [45.089875, -12.66751],
              [45.088781, -12.671284],
              [45.091548, -12.672476],
              [45.097412, -12.673132],
              [45.101734, -12.676099],
              [45.10142, -12.679915],
              [45.10493, -12.683341],
              [45.108303, -12.684068],
              [45.111981, -12.688494],
              [45.111226, -12.691235],
              [45.114562, -12.695769],
              [45.126816, -12.701424],
              [45.12327, -12.705308],
              [45.124263, -12.711543],
              [45.129563, -12.715724],
              [45.130022, -12.720934],
              [45.125761, -12.724348],
              [45.125444, -12.72734],
              [45.133195, -12.730489],
              [45.14042, -12.734331],
              [45.144206, -12.731443],
              [45.143531, -12.72936],
              [45.146345, -12.727989],
              [45.150352, -12.729096],
              [45.15056, -12.734363],
              [45.153746, -12.736704],
              [45.157606, -12.735324],
              [45.160547, -12.732392],
              [45.163935, -12.726776],
              [45.165542, -12.721407],
              [45.167255, -12.718965],
              [45.17244, -12.727381],
              [45.177429, -12.730132],
              [45.18751, -12.728458],
              [45.195849, -12.731097],
              [45.204855, -12.730813],
              [45.21177, -12.738263],
              [45.213283, -12.740865],
              [45.219661, -12.743706],
              [45.226239, -12.745057],
              [45.228629, -12.750002],
              [45.232068, -12.75252],
              [45.235936, -12.752704],
              [45.236328, -12.759026],
              [45.238539, -12.761658],
              [45.235915, -12.765886],
              [45.233589, -12.766874],
              [45.231745, -12.763238],
              [45.229408, -12.769586],
              [45.22628, -12.774182],
              [45.232303, -12.776008],
              [45.23263, -12.778211],
              [45.235982, -12.7814],
              [45.225036, -12.788711],
              [45.223226, -12.790811],
              [45.216912, -12.794788],
              [45.215182, -12.801791],
              [45.208856, -12.806484],
              [45.203351, -12.809766],
              [45.204159, -12.813695],
              [45.20395, -12.819256],
              [45.1957, -12.823139],
              [45.192059, -12.829661],
              [45.192118, -12.831367],
              [45.196879, -12.830516],
              [45.196037, -12.833325],
              [45.191319, -12.83701],
              [45.187549, -12.838455],
              [45.185744, -12.841818],
              [45.192877, -12.843047],
              [45.194751, -12.849441],
              [45.196807, -12.853294],
              [45.200744, -12.854545],
              [45.202121, -12.857256],
              [45.203441, -12.863184],
              [45.211624, -12.865038],
              [45.210017, -12.868879],
              [45.213908, -12.873492],
              [45.220133, -12.871015],
              [45.221784, -12.873372],
              [45.217302, -12.877717],
              [45.215996, -12.881332],
              [45.216911, -12.884299],
              [45.215296, -12.886666],
              [45.209024, -12.891978],
              [45.198897, -12.896018],
              [45.197274, -12.89792],
              [45.194326, -12.90494],
              [45.195004, -12.909986],
              [45.199264, -12.915118],
              [45.204143, -12.919816],
              [45.200808, -12.920167],
              [45.19508, -12.917545],
              [45.187696, -12.916421],
              [45.185035, -12.918653],
              [45.183236, -12.923721],
              [45.178651, -12.92725],
              [45.176908, -12.929951],
              [45.175739, -12.935037],
              [45.179663, -12.939387],
              [45.178859, -12.943336],
              [45.172618, -12.941723],
              [45.170165, -12.942948],
              [45.168848, -12.949614],
              [45.170407, -12.952755],
              [45.174691, -12.955949],
              [45.176205, -12.958714],
              [45.176382, -12.964048],
              [45.185127, -12.97014],
              [45.189285, -12.970478],
              [45.191851, -12.973682],
              [45.196609, -12.97595],
              [45.198774, -12.97439],
              [45.203116, -12.97425],
              [45.203649, -12.975677],
              [45.200749, -12.979319],
              [45.194008, -12.985575],
              [45.189396, -12.985637],
              [45.185516, -12.983983],
              [45.180473, -12.984962],
              [45.175417, -12.994399],
              [45.17379, -12.983636],
              [45.170519, -12.977224],
              [45.16651, -12.974945],
              [45.160831, -12.97431],
              [45.156725, -12.979481],
              [45.157674, -12.989039],
              [45.151161, -12.998576],
              [45.14814, -13.000103],
              [45.142878, -12.999464],
              [45.140964, -12.998182],
              [45.139436, -12.993897],
              [45.135607, -12.989984],
              [45.133169, -12.990159],
              [45.130426, -12.99422],
              [45.126219, -12.99454],
              [45.125067, -12.99073],
              [45.126372, -12.987213],
              [45.124434, -12.98135],
              [45.117434, -12.980628],
              [45.113692, -12.982326],
              [45.110904, -12.98632],
              [45.108703, -12.993295],
              [45.106038, -12.989269],
              [45.105599, -12.986867],
              [45.108223, -12.981446],
              [45.10667, -12.974175],
              [45.110587, -12.967862],
              [45.110932, -12.961564],
              [45.108277, -12.957248],
              [45.104505, -12.957645],
              [45.099102, -12.964341],
              [45.092587, -12.966511],
              [45.082931, -12.961254],
              [45.085778, -12.957478],
              [45.093775, -12.954346],
              [45.100554, -12.946496],
              [45.101632, -12.939291],
              [45.104275, -12.93315],
              [45.102516, -12.928511],
              [45.09792, -12.924905],
              [45.095248, -12.925675],
              [45.089347, -12.929304],
              [45.089101, -12.925634],
              [45.086947, -12.922368],
              [45.082771, -12.920244],
              [45.081479, -12.917672],
              [45.077327, -12.905377],
              [45.075614, -12.903207],
              [45.071791, -12.900957],
              [45.072985, -12.899165],
              [45.080333, -12.898945],
              [45.084319, -12.898102],
              [45.092294, -12.897894],
              [45.099363, -12.899341],
              [45.102297, -12.903135],
              [45.102765, -12.905805],
              [45.101593, -12.909946],
              [45.102458, -12.917715],
              [45.103403, -12.920124],
              [45.107171, -12.924124],
              [45.114044, -12.92588],
              [45.12083, -12.929073],
              [45.124509, -12.929813],
              [45.124057, -12.932424],
              [45.12902, -12.934942],
              [45.136718, -12.93671],
              [45.145042, -12.935849],
              [45.151428, -12.930353],
              [45.155021, -12.922368],
              [45.153724, -12.918473],
              [45.147028, -12.907571],
              [45.144057, -12.904583],
              [45.145115, -12.900223],
              [45.142293, -12.896402],
              [45.140608, -12.896078],
              [45.138168, -12.890983],
              [45.131993, -12.888647],
              [45.12954, -12.884235],
              [45.12329, -12.881147],
              [45.118141, -12.875107],
              [45.113532, -12.866288],
              [45.111203, -12.863639],
              [45.104046, -12.86263],
              [45.099925, -12.859464],
              [45.095223, -12.853261],
              [45.099001, -12.849194],
              [45.10184, -12.844935],
              [45.109262, -12.844005],
              [45.114785, -12.844012],
              [45.121297, -12.839621],
              [45.116854, -12.839027],
              [45.108892, -12.83648],
              [45.102578, -12.835326],
              [45.100787, -12.833818],
              [45.103596, -12.825044],
              [45.106274, -12.819533],
              [45.105703, -12.814096],
              [45.100101, -12.807675],
              [45.100156, -12.798143],
              [45.101972, -12.796654],
              [45.106496, -12.796033],
              [45.111965, -12.796442],
              [45.112352, -12.793443],
              [45.108277, -12.790987],
              [45.103821, -12.792776],
              [45.097562, -12.792292],
              [45.096194, -12.786614],
              [45.094598, -12.784477],
              [45.100943, -12.781011],
              [45.103062, -12.773416],
              [45.102279, -12.76667],
              [45.09911, -12.765149],
              [45.090469, -12.768678],
              [45.085665, -12.76516],
              [45.082303, -12.764278],
              [45.079472, -12.766345],
              [45.073835, -12.764016],
              [45.07027, -12.760078],
              [45.06455, -12.75516],
              [45.062452, -12.752215],
              [45.059699, -12.751014],
              [45.054308, -12.752459],
              [45.050984, -12.755046],
              [45.043277, -12.748305],
              [45.042556, -12.744254],
              [45.046015, -12.740747],
              [45.050963, -12.73833],
              [45.054267, -12.734441],
              [45.057984, -12.728054],
              [45.055667, -12.723807],
              [45.043114, -12.721512],
              [45.047871, -12.71546],
              [45.051176, -12.709229],
              [45.050552, -12.705493],
              [45.05158, -12.701684],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5381",
      properties: {
        name: "Normandie",
        density: 0,
        path: "/world/France/Normandie",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.11962, 49.355569],
            [-1.115035, 49.362396],
            [-1.092158, 49.379887],
            [-1.078216, 49.388494],
            [-1.058104, 49.390753],
            [-1.030231, 49.389585],
            [-1.021776, 49.392986],
            [-0.989403, 49.397436],
            [-0.939382, 49.395041],
            [-0.919393, 49.390028],
            [-0.907405, 49.381676],
            [-0.881377, 49.371558],
            [-0.85116, 49.362546],
            [-0.82688, 49.3579],
            [-0.758795, 49.349989],
            [-0.722245, 49.347004],
            [-0.695504, 49.347282],
            [-0.642019, 49.345054],
            [-0.624187, 49.340514],
            [-0.594179, 49.340746],
            [-0.553248, 49.346097],
            [-0.51531, 49.345015],
            [-0.451958, 49.335499],
            [-0.41485, 49.335581],
            [-0.396611, 49.333003],
            [-0.361039, 49.322881],
            [-0.302571, 49.298438],
            [-0.282146, 49.293321],
            [-0.246996, 49.288222],
            [-0.225695, 49.28182],
            [-0.212072, 49.286032],
            [-0.169661, 49.286246],
            [-0.141893, 49.289141],
            [-0.0996, 49.297601],
            [-0.087163, 49.297907],
            [-0.077762, 49.304038],
            [-0.01337, 49.321204],
            [0.004021, 49.32778],
            [0.049806, 49.350859],
            [0.075463, 49.366164],
            [0.087043, 49.376676],
            [0.110385, 49.394266],
            [0.129747, 49.402984],
            [0.159409, 49.410112],
            [0.175786, 49.412357],
            [0.219941, 49.426761],
            [0.297225, 49.42986],
            [0.340593, 49.434319],
            [0.338979, 49.440929],
            [0.339315, 49.449872],
            [0.273136, 49.453416],
            [0.271421, 49.447329],
            [0.256797, 49.446324],
            [0.238256, 49.45116],
            [0.217691, 49.452963],
            [0.192462, 49.45146],
            [0.17389, 49.456501],
            [0.12124, 49.463194],
            [0.116632, 49.468669],
            [0.090697, 49.482115],
            [0.093998, 49.493022],
            [0.08691, 49.501427],
            [0.069553, 49.506395],
            [0.065609, 49.512575],
            [0.074936, 49.536328],
            [0.096662, 49.566866],
            [0.106732, 49.583499],
            [0.136914, 49.620054],
            [0.15454, 49.648639],
            [0.150419, 49.654089],
            [0.157453, 49.662409],
            [0.164358, 49.683595],
            [0.169025, 49.690718],
            [0.189585, 49.704796],
            [0.211669, 49.714464],
            [0.237346, 49.719026],
            [0.283256, 49.736576],
            [0.30543, 49.741648],
            [0.320556, 49.74155],
            [0.354921, 49.755007],
            [0.364473, 49.76432],
            [0.374272, 49.768688],
            [0.402918, 49.776954],
            [0.428464, 49.786277],
            [0.483542, 49.808257],
            [0.506251, 49.81973],
            [0.522751, 49.824531],
            [0.534434, 49.833749],
            [0.572939, 49.849717],
            [0.634556, 49.861652],
            [0.642042, 49.864191],
            [0.674491, 49.86736],
            [0.701967, 49.871881],
            [0.733559, 49.870615],
            [0.769727, 49.872092],
            [0.790972, 49.875749],
            [0.836672, 49.889522],
            [0.884349, 49.896715],
            [0.929793, 49.906737],
            [0.954672, 49.918318],
            [0.963183, 49.91986],
            [1.020049, 49.916107],
            [1.065898, 49.925609],
            [1.080906, 49.932293],
            [1.11097, 49.939082],
            [1.162921, 49.957022],
            [1.184587, 49.96636],
            [1.194376, 49.968033],
            [1.208096, 49.979831],
            [1.233292, 49.988728],
            [1.299785, 50.029579],
            [1.317017, 50.036251],
            [1.332427, 50.047125],
            [1.367203, 50.062608],
            [1.379698, 50.065012],
            [1.39244, 50.060056],
            [1.408835, 50.057247],
            [1.423594, 50.070852],
            [1.446453, 50.069324],
            [1.459152, 50.062495],
            [1.455286, 50.056299],
            [1.456457, 50.037832],
            [1.473493, 50.0336],
            [1.493311, 50.01774],
            [1.501039, 50.018916],
            [1.518015, 50.009102],
            [1.527019, 49.996531],
            [1.54656, 49.987582],
            [1.558258, 49.979389],
            [1.57389, 49.973922],
            [1.594009, 49.949051],
            [1.618211, 49.937822],
            [1.678451, 49.918131],
            [1.693295, 49.895602],
            [1.712456, 49.886444],
            [1.711744, 49.873718],
            [1.718733, 49.865708],
            [1.719514, 49.854067],
            [1.724683, 49.845733],
            [1.727053, 49.828882],
            [1.737387, 49.808209],
            [1.754475, 49.79063],
            [1.757928, 49.780795],
            [1.772804, 49.776869],
            [1.784472, 49.763593],
            [1.783834, 49.758309],
            [1.747145, 49.757054],
            [1.741629, 49.751631],
            [1.74095, 49.738588],
            [1.723234, 49.730085],
            [1.712169, 49.731959],
            [1.715188, 49.71282],
            [1.711986, 49.707714],
            [1.689574, 49.694787],
            [1.704518, 49.680928],
            [1.717707, 49.684373],
            [1.721428, 49.691451],
            [1.737534, 49.70035],
            [1.750639, 49.693805],
            [1.752185, 49.680961],
            [1.725199, 49.672637],
            [1.722566, 49.661514],
            [1.703454, 49.645178],
            [1.704629, 49.639706],
            [1.717252, 49.633072],
            [1.721603, 49.621992],
            [1.704583, 49.605397],
            [1.695188, 49.599725],
            [1.707903, 49.595121],
            [1.709401, 49.586714],
            [1.721617, 49.588806],
            [1.714777, 49.576403],
            [1.729622, 49.561515],
            [1.727182, 49.541626],
            [1.74461, 49.539601],
            [1.745129, 49.531736],
            [1.730942, 49.516127],
            [1.719234, 49.508225],
            [1.725453, 49.499782],
            [1.738743, 49.499647],
            [1.743704, 49.495261],
            [1.757959, 49.508956],
            [1.771862, 49.512848],
            [1.790225, 49.503468],
            [1.78748, 49.493411],
            [1.774103, 49.48411],
            [1.775816, 49.475733],
            [1.766341, 49.466149],
            [1.747714, 49.459538],
            [1.751256, 49.452316],
            [1.737574, 49.448744],
            [1.732506, 49.440347],
            [1.722799, 49.433058],
            [1.720755, 49.421791],
            [1.713931, 49.409225],
            [1.740106, 49.405315],
            [1.720313, 49.39483],
            [1.741214, 49.381721],
            [1.747581, 49.37312],
            [1.759413, 49.368145],
            [1.757698, 49.356711],
            [1.77277, 49.333136],
            [1.7676, 49.31589],
            [1.775625, 49.299694],
            [1.796488, 49.284112],
            [1.79323, 49.274331],
            [1.802674, 49.271948],
            [1.791806, 49.256428],
            [1.789534, 49.247871],
            [1.766894, 49.252034],
            [1.764355, 49.263082],
            [1.754721, 49.270008],
            [1.710482, 49.264466],
            [1.701418, 49.25246],
            [1.699043, 49.234864],
            [1.704359, 49.232197],
            [1.704064, 49.22932],
            [1.675742, 49.211867],
            [1.676955, 49.202543],
            [1.667017, 49.178614],
            [1.664379, 49.153368],
            [1.653923, 49.145441],
            [1.655782, 49.130164],
            [1.647091, 49.124714],
            [1.617534, 49.093997],
            [1.623323, 49.086078],
            [1.608799, 49.077894],
            [1.604487, 49.083037],
            [1.574738, 49.078438],
            [1.557074, 49.069621],
            [1.549473, 49.072558],
            [1.521772, 49.068547],
            [1.511601, 49.074136],
            [1.503294, 49.059098],
            [1.484877, 49.051545],
            [1.460801, 49.06274],
            [1.447285, 49.053509],
            [1.447429, 49.045189],
            [1.457953, 49.034833],
            [1.457757, 49.026295],
            [1.472182, 49.018491],
            [1.478751, 48.998885],
            [1.46092, 48.986434],
            [1.47061, 48.975306],
            [1.477093, 48.978791],
            [1.497416, 48.979551],
            [1.507909, 48.983767],
            [1.515549, 48.976406],
            [1.496606, 48.969345],
            [1.501059, 48.952678],
            [1.501527, 48.941052],
            [1.480648, 48.94049],
            [1.461311, 48.937555],
            [1.459411, 48.928927],
            [1.447959, 48.92464],
            [1.459326, 48.914649],
            [1.46067, 48.900495],
            [1.468355, 48.892216],
            [1.465363, 48.877601],
            [1.454994, 48.870326],
            [1.434311, 48.86614],
            [1.423893, 48.861268],
            [1.409366, 48.861153],
            [1.396857, 48.855384],
            [1.39056, 48.846606],
            [1.362182, 48.83418],
            [1.359248, 48.813609],
            [1.376963, 48.791766],
            [1.374426, 48.782614],
            [1.362664, 48.781664],
            [1.342232, 48.772542],
            [1.332905, 48.762612],
            [1.319544, 48.760961],
            [1.300297, 48.767537],
            [1.279985, 48.762674],
            [1.270213, 48.757483],
            [1.254547, 48.758629],
            [1.256264, 48.765104],
            [1.246024, 48.769676],
            [1.222616, 48.767292],
            [1.223527, 48.758057],
            [1.198145, 48.769569],
            [1.184736, 48.77263],
            [1.161543, 48.769293],
            [1.153304, 48.774016],
            [1.152115, 48.78556],
            [1.121396, 48.789196],
            [1.118984, 48.782375],
            [1.120984, 48.76725],
            [1.112658, 48.751864],
            [1.095726, 48.748713],
            [1.086, 48.756926],
            [1.075781, 48.749438],
            [1.068665, 48.756914],
            [1.059071, 48.756765],
            [1.039803, 48.742155],
            [1.03165, 48.730582],
            [1.020608, 48.734789],
            [1.014064, 48.7281],
            [0.984415, 48.725995],
            [0.977294, 48.730139],
            [0.961444, 48.725722],
            [0.955242, 48.716786],
            [0.921199, 48.709175],
            [0.9024, 48.710649],
            [0.888368, 48.718921],
            [0.876711, 48.715496],
            [0.861498, 48.691008],
            [0.850363, 48.682948],
            [0.827671, 48.68072],
            [0.814825, 48.670164],
            [0.812949, 48.66051],
            [0.823921, 48.650042],
            [0.822682, 48.640054],
            [0.831406, 48.633774],
            [0.817754, 48.61632],
            [0.819636, 48.609871],
            [0.833047, 48.61077],
            [0.846587, 48.604783],
            [0.850635, 48.582626],
            [0.867676, 48.573488],
            [0.891266, 48.572177],
            [0.92639, 48.559394],
            [0.938763, 48.550559],
            [0.922603, 48.537716],
            [0.953418, 48.526244],
            [0.966551, 48.522742],
            [0.954004, 48.50384],
            [0.941559, 48.498695],
            [0.954369, 48.490574],
            [0.956293, 48.482279],
            [0.935713, 48.475554],
            [0.942268, 48.457734],
            [0.958409, 48.442597],
            [0.976271, 48.439082],
            [0.948269, 48.417832],
            [0.942246, 48.399005],
            [0.931178, 48.391266],
            [0.914919, 48.374324],
            [0.901843, 48.37345],
            [0.883373, 48.356669],
            [0.870157, 48.35834],
            [0.861813, 48.350898],
            [0.841595, 48.35023],
            [0.827856, 48.342447],
            [0.818401, 48.34942],
            [0.785425, 48.340431],
            [0.784185, 48.334479],
            [0.773138, 48.325511],
            [0.762194, 48.306877],
            [0.760232, 48.298172],
            [0.777582, 48.302841],
            [0.793418, 48.293009],
            [0.794261, 48.284951],
            [0.785871, 48.271138],
            [0.78759, 48.261314],
            [0.802415, 48.248072],
            [0.806087, 48.239891],
            [0.82589, 48.220722],
            [0.830484, 48.210709],
            [0.806269, 48.203016],
            [0.797658, 48.194455],
            [0.764079, 48.1816],
            [0.755663, 48.181982],
            [0.73783, 48.18907],
            [0.73015, 48.200522],
            [0.72363, 48.19814],
            [0.716576, 48.212095],
            [0.68322, 48.248588],
            [0.675471, 48.254741],
            [0.653151, 48.263703],
            [0.640705, 48.261222],
            [0.631904, 48.254755],
            [0.63316, 48.245554],
            [0.579191, 48.244364],
            [0.560994, 48.245949],
            [0.550138, 48.249396],
            [0.53597, 48.249845],
            [0.538486, 48.256988],
            [0.5303, 48.265497],
            [0.51293, 48.266874],
            [0.494556, 48.286816],
            [0.50703, 48.295833],
            [0.487577, 48.307796],
            [0.480501, 48.298592],
            [0.463612, 48.305016],
            [0.442799, 48.304629],
            [0.431334, 48.306639],
            [0.426889, 48.315425],
            [0.415996, 48.321625],
            [0.40622, 48.314621],
            [0.395403, 48.32055],
            [0.38261, 48.333828],
            [0.380478, 48.341798],
            [0.388285, 48.349122],
            [0.382552, 48.359499],
            [0.378659, 48.383228],
            [0.373861, 48.38697],
            [0.375372, 48.39574],
            [0.371724, 48.410452],
            [0.381508, 48.417548],
            [0.38066, 48.425412],
            [0.367718, 48.438273],
            [0.363956, 48.451632],
            [0.355785, 48.458217],
            [0.338741, 48.4616],
            [0.327276, 48.471072],
            [0.317897, 48.471938],
            [0.295856, 48.480175],
            [0.275934, 48.479055],
            [0.262862, 48.482955],
            [0.258578, 48.47671],
            [0.229393, 48.472578],
            [0.218232, 48.473791],
            [0.189813, 48.461891],
            [0.181255, 48.464965],
            [0.169724, 48.461777],
            [0.169657, 48.449364],
            [0.156101, 48.454795],
            [0.158118, 48.444016],
            [0.151319, 48.437227],
            [0.116248, 48.435556],
            [0.09917, 48.41035],
            [0.08358, 48.411138],
            [0.067827, 48.406115],
            [0.056685, 48.393974],
            [0.06249, 48.382214],
            [0.020993, 48.380201],
            [0.006586, 48.388521],
            [-0.002564, 48.397312],
            [-0.020364, 48.393656],
            [-0.022055, 48.38806],
            [-0.035753, 48.384875],
            [-0.050693, 48.375201],
            [-0.054527, 48.382004],
            [-0.052691, 48.392979],
            [-0.05669, 48.398916],
            [-0.053013, 48.412716],
            [-0.057356, 48.428503],
            [-0.04991, 48.447628],
            [-0.051891, 48.453255],
            [-0.072708, 48.456928],
            [-0.073007, 48.450527],
            [-0.106412, 48.44752],
            [-0.124542, 48.44924],
            [-0.148718, 48.458069],
            [-0.153366, 48.476725],
            [-0.149589, 48.479782],
            [-0.15856, 48.496817],
            [-0.172091, 48.502135],
            [-0.16634, 48.515584],
            [-0.155684, 48.520497],
            [-0.145012, 48.521],
            [-0.144603, 48.527754],
            [-0.169379, 48.536973],
            [-0.189959, 48.548884],
            [-0.193986, 48.554824],
            [-0.206942, 48.562946],
            [-0.221074, 48.560317],
            [-0.234352, 48.562336],
            [-0.24264, 48.567994],
            [-0.261804, 48.547895],
            [-0.246357, 48.542621],
            [-0.241768, 48.536389],
            [-0.253955, 48.525986],
            [-0.265801, 48.522782],
            [-0.271554, 48.507448],
            [-0.27823, 48.506986],
            [-0.302809, 48.517341],
            [-0.320233, 48.522924],
            [-0.343378, 48.50085],
            [-0.355821, 48.495674],
            [-0.353496, 48.483897],
            [-0.36724, 48.487749],
            [-0.367623, 48.492944],
            [-0.393457, 48.501835],
            [-0.399186, 48.510159],
            [-0.412734, 48.506498],
            [-0.424972, 48.507283],
            [-0.430757, 48.511816],
            [-0.462263, 48.512709],
            [-0.470603, 48.509717],
            [-0.478205, 48.501566],
            [-0.488495, 48.501618],
            [-0.505062, 48.505799],
            [-0.530442, 48.495165],
            [-0.545103, 48.482691],
            [-0.551718, 48.47312],
            [-0.57152, 48.469153],
            [-0.595337, 48.47263],
            [-0.61757, 48.45896],
            [-0.654, 48.444278],
            [-0.653631, 48.459546],
            [-0.663718, 48.484472],
            [-0.668957, 48.486138],
            [-0.685853, 48.475468],
            [-0.687992, 48.469431],
            [-0.702069, 48.467208],
            [-0.711217, 48.470742],
            [-0.730342, 48.472703],
            [-0.735858, 48.461125],
            [-0.71977, 48.454579],
            [-0.715099, 48.44895],
            [-0.735278, 48.445049],
            [-0.757277, 48.436552],
            [-0.774539, 48.443279],
            [-0.778586, 48.453255],
            [-0.777873, 48.465414],
            [-0.797563, 48.46528],
            [-0.799184, 48.458939],
            [-0.813224, 48.455083],
            [-0.818459, 48.474292],
            [-0.82729, 48.476292],
            [-0.837789, 48.485179],
            [-0.846107, 48.498284],
            [-0.86036, 48.501459],
            [-0.877464, 48.49962],
            [-0.896249, 48.495084],
            [-0.918471, 48.500394],
            [-0.92236, 48.512389],
            [-0.933711, 48.515027],
            [-0.956374, 48.51662],
            [-0.964254, 48.510812],
            [-0.962354, 48.503667],
            [-0.972258, 48.4946],
            [-1.003996, 48.489172],
            [-1.051445, 48.509309],
            [-1.06055, 48.515346],
            [-1.070164, 48.508492],
            [-1.096904, 48.512092],
            [-1.11609, 48.521233],
            [-1.130543, 48.521703],
            [-1.147077, 48.517499],
            [-1.156893, 48.521286],
            [-1.169907, 48.531104],
            [-1.188929, 48.528893],
            [-1.206552, 48.542206],
            [-1.215822, 48.538229],
            [-1.236321, 48.53808],
            [-1.254274, 48.543268],
            [-1.272248, 48.53392],
            [-1.269574, 48.524058],
            [-1.279203, 48.509166],
            [-1.288249, 48.50699],
            [-1.302566, 48.498857],
            [-1.326337, 48.498742],
            [-1.330285, 48.489792],
            [-1.339369, 48.49094],
            [-1.34504, 48.484912],
            [-1.346351, 48.471671],
            [-1.377246, 48.458283],
            [-1.396676, 48.462053],
            [-1.406484, 48.460604],
            [-1.429136, 48.462553],
            [-1.440353, 48.471141],
            [-1.436253, 48.478368],
            [-1.44926, 48.486102],
            [-1.467086, 48.488067],
            [-1.473186, 48.485386],
            [-1.489942, 48.489372],
            [-1.495641, 48.508899],
            [-1.512278, 48.524834],
            [-1.519564, 48.540018],
            [-1.533286, 48.548004],
            [-1.529171, 48.560523],
            [-1.518922, 48.56659],
            [-1.528642, 48.57918],
            [-1.542993, 48.580447],
            [-1.544011, 48.587389],
            [-1.539652, 48.59992],
            [-1.548486, 48.607366],
            [-1.565122, 48.614398],
            [-1.571089, 48.626441],
            [-1.543545, 48.631216],
            [-1.518648, 48.627241],
            [-1.515648, 48.618249],
            [-1.487514, 48.619683],
            [-1.477189, 48.618738],
            [-1.453119, 48.624676],
            [-1.449159, 48.623417],
            [-1.425842, 48.637559],
            [-1.404583, 48.643394],
            [-1.378893, 48.641984],
            [-1.357423, 48.635483],
            [-1.36679, 48.646817],
            [-1.377796, 48.650981],
            [-1.393192, 48.650524],
            [-1.400884, 48.658912],
            [-1.386808, 48.67423],
            [-1.388502, 48.680611],
            [-1.410933, 48.6715],
            [-1.432437, 48.666381],
            [-1.438583, 48.656898],
            [-1.445306, 48.655011],
            [-1.449289, 48.670002],
            [-1.481254, 48.684374],
            [-1.496968, 48.68367],
            [-1.504951, 48.687407],
            [-1.51847, 48.716306],
            [-1.531928, 48.731023],
            [-1.560175, 48.735463],
            [-1.570154, 48.742947],
            [-1.574403, 48.751851],
            [-1.570144, 48.772302],
            [-1.569891, 48.800225],
            [-1.574438, 48.821682],
            [-1.59415, 48.834715],
            [-1.60132, 48.836516],
            [-1.587174, 48.846459],
            [-1.576382, 48.866233],
            [-1.564346, 48.92158],
            [-1.554118, 48.90014],
            [-1.543426, 48.931027],
            [-1.549536, 48.940185],
            [-1.562111, 48.941017],
            [-1.56203, 48.981626],
            [-1.558987, 48.991686],
            [-1.56071, 49.001481],
            [-1.550793, 49.003262],
            [-1.557066, 49.01156],
            [-1.556148, 49.02469],
            [-1.539805, 49.034165],
            [-1.523862, 49.027357],
            [-1.509383, 49.026943],
            [-1.541795, 49.041162],
            [-1.547101, 49.037293],
            [-1.559232, 49.038274],
            [-1.568874, 49.032048],
            [-1.578577, 49.015283],
            [-1.574585, 49.002854],
            [-1.581617, 49.002704],
            [-1.593789, 49.022503],
            [-1.60295, 49.055779],
            [-1.598096, 49.058557],
            [-1.598422, 49.074513],
            [-1.609415, 49.079282],
            [-1.610432, 49.092933],
            [-1.606931, 49.110034],
            [-1.599986, 49.118513],
            [-1.594746, 49.131928],
            [-1.581072, 49.131675],
            [-1.595825, 49.142832],
            [-1.598847, 49.1692],
            [-1.607322, 49.196647],
            [-1.605148, 49.203745],
            [-1.610749, 49.214491],
            [-1.57177, 49.223972],
            [-1.589294, 49.233427],
            [-1.616995, 49.23161],
            [-1.625064, 49.225075],
            [-1.631685, 49.214222],
            [-1.642501, 49.22393],
            [-1.65416, 49.241445],
            [-1.660905, 49.259266],
            [-1.678218, 49.280143],
            [-1.665772, 49.281881],
            [-1.665208, 49.286812],
            [-1.683108, 49.287625],
            [-1.696863, 49.302727],
            [-1.711172, 49.325095],
            [-1.701294, 49.323916],
            [-1.696829, 49.33172],
            [-1.717616, 49.326352],
            [-1.72479, 49.32745],
            [-1.741159, 49.344038],
            [-1.764727, 49.363552],
            [-1.778796, 49.371673],
            [-1.807045, 49.371887],
            [-1.819827, 49.390482],
            [-1.823363, 49.405355],
            [-1.823433, 49.438807],
            [-1.826773, 49.452913],
            [-1.83657, 49.468324],
            [-1.84341, 49.470777],
            [-1.846419, 49.498629],
            [-1.852602, 49.510386],
            [-1.868679, 49.512372],
            [-1.881933, 49.519312],
            [-1.88653, 49.527615],
            [-1.885691, 49.54039],
            [-1.859927, 49.550652],
            [-1.841026, 49.571733],
            [-1.842366, 49.603652],
            [-1.845138, 49.619088],
            [-1.855007, 49.642822],
            [-1.860296, 49.650194],
            [-1.873484, 49.657595],
            [-1.898398, 49.665828],
            [-1.917822, 49.665793],
            [-1.929107, 49.671817],
            [-1.946184, 49.674344],
            [-1.936276, 49.687906],
            [-1.936433, 49.693756],
            [-1.947273, 49.704929],
            [-1.942247, 49.725558],
            [-1.916518, 49.724881],
            [-1.897781, 49.717102],
            [-1.890118, 49.707153],
            [-1.876535, 49.706746],
            [-1.852483, 49.716941],
            [-1.839531, 49.711553],
            [-1.827633, 49.693428],
            [-1.815242, 49.688872],
            [-1.790312, 49.687063],
            [-1.762679, 49.678676],
            [-1.735379, 49.677476],
            [-1.722617, 49.679235],
            [-1.704436, 49.670861],
            [-1.686747, 49.673066],
            [-1.67839, 49.661233],
            [-1.650144, 49.656799],
            [-1.632852, 49.660642],
            [-1.624015, 49.644053],
            [-1.615161, 49.643461],
            [-1.595585, 49.654476],
            [-1.579067, 49.654109],
            [-1.565722, 49.656432],
            [-1.541576, 49.654112],
            [-1.514267, 49.659906],
            [-1.495199, 49.668112],
            [-1.485803, 49.669315],
            [-1.484871, 49.67776],
            [-1.475394, 49.681674],
            [-1.475988, 49.692013],
            [-1.471084, 49.696671],
            [-1.455113, 49.691212],
            [-1.421165, 49.703706],
            [-1.411249, 49.701954],
            [-1.385821, 49.706236],
            [-1.369567, 49.706888],
            [-1.349838, 49.702068],
            [-1.287844, 49.692596],
            [-1.267929, 49.695329],
            [-1.271509, 49.683653],
            [-1.253886, 49.663806],
            [-1.23918, 49.652403],
            [-1.243248, 49.6482],
            [-1.229597, 49.624654],
            [-1.228866, 49.607863],
            [-1.254285, 49.612658],
            [-1.266266, 49.592846],
            [-1.261238, 49.586657],
            [-1.267613, 49.581954],
            [-1.284057, 49.587252],
            [-1.296228, 49.583173],
            [-1.3042, 49.573677],
            [-1.309173, 49.55261],
            [-1.30636, 49.538928],
            [-1.289525, 49.517982],
            [-1.269926, 49.500542],
            [-1.258385, 49.48641],
            [-1.223338, 49.455809],
            [-1.182532, 49.424018],
            [-1.167998, 49.408032],
            [-1.161734, 49.39175],
            [-1.173275, 49.378438],
            [-1.17754, 49.364897],
            [-1.163947, 49.36666],
            [-1.138838, 49.358414],
            [-1.136049, 49.354043],
            [-1.11962, 49.355569],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5386",
      properties: {
        name: "Nouvelle-Aquitaine",
        density: 0,
        path: "/world/France/Nouvelle-Aquitaine",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.025739, 45.574691],
              [-0.993004, 45.577175],
              [-0.989152, 45.581341],
              [-0.994482, 45.598471],
              [-1.00911, 45.597495],
              [-1.014955, 45.602385],
              [-1.009635, 45.611437],
              [-1.020321, 45.621517],
              [-1.030734, 45.616566],
              [-1.048863, 45.620297],
              [-1.072856, 45.634225],
              [-1.115853, 45.646977],
              [-1.161563, 45.674286],
              [-1.176454, 45.679631],
              [-1.196049, 45.691216],
              [-1.209316, 45.696696],
              [-1.230334, 45.693221],
              [-1.237223, 45.705896],
              [-1.241445, 45.758156],
              [-1.243795, 45.77244],
              [-1.242569, 45.781575],
              [-1.231032, 45.788931],
              [-1.221018, 45.789635],
              [-1.208966, 45.795749],
              [-1.191997, 45.789371],
              [-1.17495, 45.793552],
              [-1.159551, 45.801417],
              [-1.14097, 45.798212],
              [-1.132892, 45.807423],
              [-1.137158, 45.819899],
              [-1.150209, 45.830706],
              [-1.154587, 45.840814],
              [-1.168072, 45.845145],
              [-1.15733, 45.85453],
              [-1.153187, 45.862359],
              [-1.121043, 45.856463],
              [-1.100891, 45.87221],
              [-1.095276, 45.885484],
              [-1.081212, 45.89879],
              [-1.073788, 45.914235],
              [-1.076865, 45.936699],
              [-1.095978, 45.942017],
              [-1.094399, 45.948774],
              [-1.064326, 45.949912],
              [-1.065084, 45.955877],
              [-1.076032, 45.958642],
              [-1.082493, 45.965886],
              [-1.083394, 45.978241],
              [-1.094287, 45.984079],
              [-1.098953, 45.990511],
              [-1.084291, 45.995596],
              [-1.063087, 45.994435],
              [-1.055639, 45.999343],
              [-1.05269, 46.011183],
              [-1.061154, 46.028849],
              [-1.056743, 46.035916],
              [-1.067003, 46.05015],
              [-1.088841, 46.053947],
              [-1.093849, 46.077074],
              [-1.100724, 46.093795],
              [-1.115048, 46.102319],
              [-1.130095, 46.101768],
              [-1.143168, 46.10884],
              [-1.125303, 46.110921],
              [-1.126407, 46.12426],
              [-1.148337, 46.132006],
              [-1.155422, 46.137461],
              [-1.172931, 46.139283],
              [-1.171226, 46.146519],
              [-1.161831, 46.148521],
              [-1.160723, 46.15549],
              [-1.189315, 46.150729],
              [-1.206939, 46.145764],
              [-1.228449, 46.149726],
              [-1.242043, 46.157447],
              [-1.236133, 46.166145],
              [-1.22387, 46.165946],
              [-1.218442, 46.180122],
              [-1.206615, 46.184681],
              [-1.19933, 46.194321],
              [-1.206533, 46.204362],
              [-1.199002, 46.213244],
              [-1.171351, 46.224056],
              [-1.148458, 46.238407],
              [-1.141132, 46.249709],
              [-1.118295, 46.261125],
              [-1.111166, 46.261343],
              [-1.121963, 46.290282],
              [-1.120713, 46.296691],
              [-1.129406, 46.310272],
              [-1.123642, 46.321796],
              [-1.113284, 46.316302],
              [-1.099736, 46.314472],
              [-1.078295, 46.316912],
              [-1.080724, 46.321431],
              [-1.064512, 46.335517],
              [-1.052533, 46.342541],
              [-1.029064, 46.348868],
              [-1.01381, 46.355623],
              [-0.995212, 46.350318],
              [-0.977445, 46.351109],
              [-0.964518, 46.365398],
              [-0.95084, 46.360618],
              [-0.94112, 46.367862],
              [-0.928576, 46.371051],
              [-0.937905, 46.353157],
              [-0.944155, 46.336048],
              [-0.961231, 46.323396],
              [-0.934894, 46.312858],
              [-0.904803, 46.313812],
              [-0.892701, 46.320101],
              [-0.887676, 46.326323],
              [-0.850942, 46.317186],
              [-0.844876, 46.32456],
              [-0.848655, 46.332513],
              [-0.839885, 46.340367],
              [-0.830528, 46.34153],
              [-0.824152, 46.335855],
              [-0.807321, 46.339294],
              [-0.802476, 46.325157],
              [-0.784243, 46.318862],
              [-0.775703, 46.318376],
              [-0.757949, 46.311254],
              [-0.750471, 46.304254],
              [-0.734595, 46.304956],
              [-0.721914, 46.302373],
              [-0.720149, 46.314891],
              [-0.707572, 46.317705],
              [-0.697327, 46.325093],
              [-0.672274, 46.316216],
              [-0.648253, 46.317144],
              [-0.639982, 46.322111],
              [-0.636604, 46.337595],
              [-0.618682, 46.339099],
              [-0.605471, 46.347208],
              [-0.603252, 46.361468],
              [-0.575677, 46.356508],
              [-0.557647, 46.363451],
              [-0.537795, 46.386464],
              [-0.55052, 46.393343],
              [-0.566055, 46.393082],
              [-0.572384, 46.400707],
              [-0.581898, 46.402174],
              [-0.594173, 46.410186],
              [-0.61018, 46.413733],
              [-0.620888, 46.390451],
              [-0.636587, 46.395578],
              [-0.63283, 46.403813],
              [-0.640647, 46.416225],
              [-0.63684, 46.432306],
              [-0.618771, 46.438855],
              [-0.619837, 46.452467],
              [-0.612427, 46.458737],
              [-0.624182, 46.486747],
              [-0.624838, 46.496363],
              [-0.636582, 46.506095],
              [-0.645194, 46.508571],
              [-0.633529, 46.526546],
              [-0.610025, 46.527291],
              [-0.602132, 46.53328],
              [-0.606709, 46.562332],
              [-0.617854, 46.562043],
              [-0.624567, 46.5774],
              [-0.611908, 46.588307],
              [-0.61671, 46.598537],
              [-0.627027, 46.605651],
              [-0.614114, 46.620391],
              [-0.626199, 46.633078],
              [-0.64406, 46.638024],
              [-0.657172, 46.634619],
              [-0.649248, 46.653284],
              [-0.637715, 46.663489],
              [-0.657873, 46.676827],
              [-0.680733, 46.686804],
              [-0.656193, 46.700775],
              [-0.668884, 46.717232],
              [-0.683976, 46.727864],
              [-0.700154, 46.735796],
              [-0.694133, 46.742731],
              [-0.70418, 46.749454],
              [-0.715343, 46.75179],
              [-0.720799, 46.762538],
              [-0.727312, 46.767631],
              [-0.717838, 46.800521],
              [-0.700858, 46.808626],
              [-0.708838, 46.821064],
              [-0.727527, 46.821938],
              [-0.744317, 46.830243],
              [-0.758157, 46.8314],
              [-0.781646, 46.842828],
              [-0.794776, 46.861062],
              [-0.808376, 46.869152],
              [-0.815275, 46.879362],
              [-0.832186, 46.884537],
              [-0.820204, 46.89962],
              [-0.819774, 46.909085],
              [-0.807393, 46.919825],
              [-0.822618, 46.919497],
              [-0.829149, 46.933362],
              [-0.840698, 46.933043],
              [-0.851946, 46.946506],
              [-0.872984, 46.944344],
              [-0.883797, 46.950389],
              [-0.877139, 46.955856],
              [-0.883595, 46.962369],
              [-0.877125, 46.968538],
              [-0.88901, 46.970996],
              [-0.891964, 46.97582],
              [-0.879729, 46.975804],
              [-0.857643, 46.969398],
              [-0.849156, 46.973776],
              [-0.855917, 46.97908],
              [-0.838536, 46.985504],
              [-0.82697, 46.992404],
              [-0.800441, 46.994429],
              [-0.787573, 47.005135],
              [-0.773878, 47.004248],
              [-0.761951, 46.992144],
              [-0.747602, 46.99145],
              [-0.743363, 47.000702],
              [-0.727903, 46.994993],
              [-0.713054, 46.986071],
              [-0.696372, 46.994705],
              [-0.680188, 46.987659],
              [-0.676051, 47.000124],
              [-0.644225, 46.995603],
              [-0.629714, 46.996851],
              [-0.619979, 46.993321],
              [-0.595496, 46.997955],
              [-0.586412, 47.00998],
              [-0.57653, 47.017027],
              [-0.56547, 47.019423],
              [-0.56226, 47.030666],
              [-0.545659, 47.029239],
              [-0.542222, 47.035132],
              [-0.555578, 47.043529],
              [-0.555184, 47.056996],
              [-0.559532, 47.061883],
              [-0.542712, 47.068832],
              [-0.495336, 47.082386],
              [-0.462696, 47.081926],
              [-0.464044, 47.074916],
              [-0.47635, 47.07214],
              [-0.485538, 47.065209],
              [-0.476341, 47.054362],
              [-0.464253, 47.067574],
              [-0.446138, 47.067565],
              [-0.425851, 47.072734],
              [-0.409315, 47.066289],
              [-0.400782, 47.070768],
              [-0.396311, 47.087753],
              [-0.383459, 47.087698],
              [-0.357419, 47.094026],
              [-0.344981, 47.091771],
              [-0.341465, 47.087333],
              [-0.314251, 47.091338],
              [-0.298957, 47.09925],
              [-0.287925, 47.101438],
              [-0.255375, 47.100286],
              [-0.241533, 47.105728],
              [-0.206075, 47.093285],
              [-0.186497, 47.101547],
              [-0.184838, 47.108333],
              [-0.157212, 47.10178],
              [-0.141255, 47.103745],
              [-0.14556, 47.091367],
              [-0.159474, 47.085935],
              [-0.178483, 47.06977],
              [-0.165991, 47.064597],
              [-0.147661, 47.069855],
              [-0.136783, 47.063924],
              [-0.137121, 47.058427],
              [-0.128379, 47.054429],
              [-0.102116, 47.0648],
              [-0.101504, 47.083264],
              [-0.098719, 47.090118],
              [-0.085909, 47.10101],
              [-0.060662, 47.095148],
              [-0.044169, 47.09324],
              [-0.035624, 47.086261],
              [-0.029235, 47.095258],
              [-0.026535, 47.105798],
              [-0.03929, 47.108056],
              [-0.040856, 47.112929],
              [-0.034012, 47.127335],
              [-0.010739, 47.157512],
              [0.019016, 47.175754],
              [0.036502, 47.160445],
              [0.04948, 47.168623],
              [0.05383, 47.163734],
              [0.066259, 47.143216],
              [0.078395, 47.146335],
              [0.080871, 47.137653],
              [0.076371, 47.123931],
              [0.084648, 47.118378],
              [0.104719, 47.120794],
              [0.111119, 47.129423],
              [0.123724, 47.128316],
              [0.127167, 47.119967],
              [0.136131, 47.121579],
              [0.134717, 47.107873],
              [0.161267, 47.100796],
              [0.165984, 47.107162],
              [0.181457, 47.11439],
              [0.188118, 47.100828],
              [0.200953, 47.091258],
              [0.174218, 47.071274],
              [0.179973, 47.059188],
              [0.191672, 47.064672],
              [0.207994, 47.053231],
              [0.231751, 47.064055],
              [0.242451, 47.071188],
              [0.26166, 47.070051],
              [0.267619, 47.067504],
              [0.262016, 47.057459],
              [0.267707, 47.043883],
              [0.29823, 47.053922],
              [0.306985, 47.048723],
              [0.309699, 47.025644],
              [0.298677, 47.019599],
              [0.305431, 47.012362],
              [0.308846, 46.999441],
              [0.301785, 46.984422],
              [0.308399, 46.97815],
              [0.300739, 46.97383],
              [0.305075, 46.965176],
              [0.305215, 46.952805],
              [0.311232, 46.937837],
              [0.324837, 46.930652],
              [0.339526, 46.936623],
              [0.347738, 46.936586],
              [0.355244, 46.944186],
              [0.366511, 46.949554],
              [0.388146, 46.943625],
              [0.406661, 46.936648],
              [0.418525, 46.937387],
              [0.438705, 46.929578],
              [0.444801, 46.941152],
              [0.476624, 46.949862],
              [0.506135, 46.959245],
              [0.528129, 46.955972],
              [0.539289, 46.960219],
              [0.570842, 46.955939],
              [0.598348, 46.956764],
              [0.601558, 46.959107],
              [0.601173, 46.973094],
              [0.593534, 46.977613],
              [0.573822, 46.98334],
              [0.573685, 46.995531],
              [0.566953, 47.002267],
              [0.590551, 47.006723],
              [0.618874, 47.007465],
              [0.621244, 46.997106],
              [0.636211, 46.985451],
              [0.647695, 46.988275],
              [0.656462, 46.985435],
              [0.661621, 46.978087],
              [0.682162, 46.97708],
              [0.692567, 46.974304],
              [0.696189, 46.95681],
              [0.706248, 46.937157],
              [0.703572, 46.930056],
              [0.704318, 46.903296],
              [0.726737, 46.886741],
              [0.733685, 46.87604],
              [0.747331, 46.869392],
              [0.750882, 46.863525],
              [0.771988, 46.860643],
              [0.768799, 46.850746],
              [0.790329, 46.852389],
              [0.796459, 46.848962],
              [0.786802, 46.840463],
              [0.795093, 46.832522],
              [0.80932, 46.827854],
              [0.810329, 46.813065],
              [0.81532, 46.805708],
              [0.811902, 46.794504],
              [0.816284, 46.787769],
              [0.829436, 46.783579],
              [0.830648, 46.775361],
              [0.842938, 46.764275],
              [0.854449, 46.759971],
              [0.867469, 46.748216],
              [0.887775, 46.737903],
              [0.901042, 46.736091],
              [0.914552, 46.71012],
              [0.924953, 46.699991],
              [0.924749, 46.69279],
              [0.902159, 46.679191],
              [0.910711, 46.677184],
              [0.906703, 46.665571],
              [0.917429, 46.650363],
              [0.906521, 46.647752],
              [0.896383, 46.633452],
              [0.894303, 46.625732],
              [0.906937, 46.615213],
              [0.909864, 46.603447],
              [0.915865, 46.596628],
              [0.937756, 46.59442],
              [0.93719, 46.586045],
              [0.942022, 46.580795],
              [0.962668, 46.574298],
              [0.982094, 46.57264],
              [0.987233, 46.56556],
              [1.014769, 46.56776],
              [1.021958, 46.553717],
              [1.0206, 46.537099],
              [1.072298, 46.537235],
              [1.087597, 46.538168],
              [1.108286, 46.53151],
              [1.145929, 46.506401],
              [1.149149, 46.502205],
              [1.134968, 46.495263],
              [1.140527, 46.485635],
              [1.152979, 46.472958],
              [1.135508, 46.470884],
              [1.151607, 46.449234],
              [1.168919, 46.446309],
              [1.186018, 46.441084],
              [1.183406, 46.429225],
              [1.201134, 46.432466],
              [1.212688, 46.432218],
              [1.194473, 46.410352],
              [1.195087, 46.402754],
              [1.177279, 46.383948],
              [1.18415, 46.377291],
              [1.191538, 46.376759],
              [1.204766, 46.387689],
              [1.216424, 46.367785],
              [1.240865, 46.367574],
              [1.245105, 46.373239],
              [1.260399, 46.378783],
              [1.279555, 46.376489],
              [1.303059, 46.370992],
              [1.30936, 46.378135],
              [1.320474, 46.381632],
              [1.32234, 46.389656],
              [1.330922, 46.396706],
              [1.344293, 46.401547],
              [1.356024, 46.40012],
              [1.377528, 46.382803],
              [1.38352, 46.374755],
              [1.396578, 46.371445],
              [1.404845, 46.364057],
              [1.415185, 46.347215],
              [1.438395, 46.357973],
              [1.435374, 46.363842],
              [1.45435, 46.376049],
              [1.462937, 46.37535],
              [1.472559, 46.383432],
              [1.477596, 46.393727],
              [1.492935, 46.398341],
              [1.50599, 46.409909],
              [1.511205, 46.419595],
              [1.522307, 46.426528],
              [1.535052, 46.424456],
              [1.543968, 46.416883],
              [1.546195, 46.39346],
              [1.552492, 46.394134],
              [1.569691, 46.405498],
              [1.592039, 46.407312],
              [1.609255, 46.423123],
              [1.622655, 46.41826],
              [1.614306, 46.405296],
              [1.628837, 46.388247],
              [1.644846, 46.386817],
              [1.661184, 46.403538],
              [1.683606, 46.418177],
              [1.697467, 46.406605],
              [1.709131, 46.393354],
              [1.72771, 46.388956],
              [1.739212, 46.401254],
              [1.750545, 46.405586],
              [1.749316, 46.410934],
              [1.757376, 46.423616],
              [1.756666, 46.441292],
              [1.747592, 46.450018],
              [1.754857, 46.452215],
              [1.798375, 46.454813],
              [1.803139, 46.446918],
              [1.816812, 46.439525],
              [1.819504, 46.430034],
              [1.838365, 46.427285],
              [1.858038, 46.433477],
              [1.883437, 46.432557],
              [1.890716, 46.441493],
              [1.902458, 46.437805],
              [1.90918, 46.443498],
              [1.919553, 46.440208],
              [1.924302, 46.431903],
              [1.94387, 46.433997],
              [1.954251, 46.438126],
              [1.978104, 46.439773],
              [1.993077, 46.430917],
              [2.020346, 46.429422],
              [2.029417, 46.424589],
              [2.074196, 46.419843],
              [2.080347, 46.411894],
              [2.088946, 46.4089],
              [2.108372, 46.413529],
              [2.112635, 46.420825],
              [2.129681, 46.419868],
              [2.167784, 46.424069],
              [2.185156, 46.423284],
              [2.197568, 46.428294],
              [2.22064, 46.423664],
              [2.233415, 46.423384],
              [2.249877, 46.426367],
              [2.281044, 46.420404],
              [2.280403, 46.410236],
              [2.285044, 46.385858],
              [2.315472, 46.375206],
              [2.33159, 46.378177],
              [2.337115, 46.366796],
              [2.323338, 46.366525],
              [2.313389, 46.356936],
              [2.302746, 46.35442],
              [2.309379, 46.342006],
              [2.323023, 46.329277],
              [2.334582, 46.32532],
              [2.354906, 46.325678],
              [2.370483, 46.312629],
              [2.384064, 46.328909],
              [2.391858, 46.329985],
              [2.411318, 46.314094],
              [2.420466, 46.31013],
              [2.416102, 46.300056],
              [2.421336, 46.284622],
              [2.430951, 46.29152],
              [2.443026, 46.294996],
              [2.478945, 46.281146],
              [2.477097, 46.269362],
              [2.489106, 46.248969],
              [2.516309, 46.239437],
              [2.515428, 46.228012],
              [2.521527, 46.211334],
              [2.521695, 46.203035],
              [2.528019, 46.195467],
              [2.528629, 46.184895],
              [2.543167, 46.175603],
              [2.5598, 46.173367],
              [2.560764, 46.155596],
              [2.565482, 46.153967],
              [2.565373, 46.143036],
              [2.558622, 46.138326],
              [2.558236, 46.130702],
              [2.549049, 46.114075],
              [2.551822, 46.103971],
              [2.551872, 46.083457],
              [2.557084, 46.0693],
              [2.563183, 46.06461],
              [2.571699, 46.04869],
              [2.602506, 46.033462],
              [2.59442, 45.989441],
              [2.60747, 45.979109],
              [2.610785, 45.971228],
              [2.589188, 45.960438],
              [2.565178, 45.95662],
              [2.565717, 45.946857],
              [2.551638, 45.941261],
              [2.550954, 45.935762],
              [2.541506, 45.921532],
              [2.555006, 45.912509],
              [2.537691, 45.900846],
              [2.526209, 45.896727],
              [2.509961, 45.886666],
              [2.492228, 45.86403],
              [2.479738, 45.864541],
              [2.470614, 45.872335],
              [2.442583, 45.863008],
              [2.447828, 45.845819],
              [2.436437, 45.846999],
              [2.426536, 45.834771],
              [2.401345, 45.837577],
              [2.388014, 45.827373],
              [2.400677, 45.81729],
              [2.418599, 45.798475],
              [2.427404, 45.794285],
              [2.43298, 45.783889],
              [2.434112, 45.769853],
              [2.441426, 45.762379],
              [2.454791, 45.761151],
              [2.475153, 45.747689],
              [2.484641, 45.748521],
              [2.492129, 45.73767],
              [2.518322, 45.7128],
              [2.519874, 45.697053],
              [2.52687, 45.694919],
              [2.52836, 45.681924],
              [2.512923, 45.671334],
              [2.51513, 45.664284],
              [2.524938, 45.657234],
              [2.514298, 45.639528],
              [2.50115, 45.638739],
              [2.489745, 45.642354],
              [2.483388, 45.639304],
              [2.479082, 45.622462],
              [2.478434, 45.607912],
              [2.465345, 45.60082],
              [2.464625, 45.594411],
              [2.472537, 45.58093],
              [2.487431, 45.569384],
              [2.490929, 45.560394],
              [2.506468, 45.553444],
              [2.516327, 45.553428],
              [2.516521, 45.524033],
              [2.520381, 45.520451],
              [2.51152, 45.511153],
              [2.508991, 45.500226],
              [2.513891, 45.492348],
              [2.508413, 45.478502],
              [2.50677, 45.464065],
              [2.495595, 45.445504],
              [2.487472, 45.418842],
              [2.492758, 45.413842],
              [2.515971, 45.403237],
              [2.522034, 45.402238],
              [2.526235, 45.389344],
              [2.522732, 45.382085],
              [2.485768, 45.378947],
              [2.477832, 45.370411],
              [2.473456, 45.381526],
              [2.458081, 45.384827],
              [2.441866, 45.384259],
              [2.422674, 45.39723],
              [2.412905, 45.396651],
              [2.398288, 45.40017],
              [2.395487, 45.407322],
              [2.37825, 45.414302],
              [2.355131, 45.415028],
              [2.350084, 45.409676],
              [2.354553, 45.401399],
              [2.364446, 45.395235],
              [2.368273, 45.388621],
              [2.36297, 45.378412],
              [2.364779, 45.35792],
              [2.351346, 45.348611],
              [2.359379, 45.33579],
              [2.350481, 45.327561],
              [2.31774, 45.322964],
              [2.307351, 45.31266],
              [2.29207, 45.29057],
              [2.28008, 45.287326],
              [2.271411, 45.290155],
              [2.261735, 45.283221],
              [2.258568, 45.27022],
              [2.245005, 45.267107],
              [2.238775, 45.260354],
              [2.241341, 45.249426],
              [2.224929, 45.241855],
              [2.195364, 45.220851],
              [2.190513, 45.202134],
              [2.198548, 45.194409],
              [2.201188, 45.181494],
              [2.219587, 45.172115],
              [2.233586, 45.167182],
              [2.225398, 45.160337],
              [2.213701, 45.16019],
              [2.211565, 45.148448],
              [2.194837, 45.136028],
              [2.178791, 45.136298],
              [2.185269, 45.113998],
              [2.179439, 45.109177],
              [2.180362, 45.097341],
              [2.171759, 45.081497],
              [2.145654, 45.085592],
              [2.129819, 45.078932],
              [2.118365, 45.070379],
              [2.103746, 45.065812],
              [2.095157, 45.056033],
              [2.099318, 45.047623],
              [2.10941, 45.041522],
              [2.116807, 45.021114],
              [2.140641, 45.005858],
              [2.138151, 44.992812],
              [2.132982, 44.985516],
              [2.100371, 44.983427],
              [2.090574, 44.984665],
              [2.076617, 44.978786],
              [2.062908, 44.976505],
              [2.052567, 44.976478],
              [2.045433, 44.983665],
              [2.006869, 44.97663],
              [1.985357, 44.97445],
              [1.974098, 44.966633],
              [1.95577, 44.958319],
              [1.950992, 44.953152],
              [1.940717, 44.95514],
              [1.939067, 44.973222],
              [1.928065, 44.978715],
              [1.908158, 44.978423],
              [1.892666, 44.964883],
              [1.88741, 44.956563],
              [1.867032, 44.952926],
              [1.851053, 44.946246],
              [1.844348, 44.93803],
              [1.836439, 44.937455],
              [1.823915, 44.927683],
              [1.808582, 44.92771],
              [1.800891, 44.92421],
              [1.784872, 44.937318],
              [1.782857, 44.929653],
              [1.774928, 44.923722],
              [1.768522, 44.931118],
              [1.75367, 44.940576],
              [1.750619, 44.954961],
              [1.74284, 44.959831],
              [1.721546, 44.968066],
              [1.711033, 44.967296],
              [1.702662, 44.987826],
              [1.687161, 44.99638],
              [1.684375, 45.002661],
              [1.67141, 45.004302],
              [1.654455, 45.017019],
              [1.650977, 45.025013],
              [1.629956, 45.033567],
              [1.61474, 45.03302],
              [1.58931, 45.036412],
              [1.57631, 45.040696],
              [1.569042, 45.038712],
              [1.552045, 45.028473],
              [1.543704, 45.030762],
              [1.541069, 45.042887],
              [1.535723, 45.046276],
              [1.51958, 45.04103],
              [1.502855, 45.038441],
              [1.480057, 45.026798],
              [1.473584, 45.017999],
              [1.461983, 45.013702],
              [1.44826, 45.019314],
              [1.428182, 45.00922],
              [1.409264, 45.006004],
              [1.413304, 44.999382],
              [1.414588, 44.977794],
              [1.420734, 44.955117],
              [1.436404, 44.940615],
              [1.436513, 44.932251],
              [1.44235, 44.916547],
              [1.424626, 44.919694],
              [1.413539, 44.911822],
              [1.421635, 44.896767],
              [1.439857, 44.888947],
              [1.441926, 44.877576],
              [1.431084, 44.871281],
              [1.41818, 44.870599],
              [1.40483, 44.862526],
              [1.401938, 44.84945],
              [1.386102, 44.847435],
              [1.377145, 44.841824],
              [1.369985, 44.846449],
              [1.361409, 44.840796],
              [1.36063, 44.826748],
              [1.364106, 44.811568],
              [1.336866, 44.806072],
              [1.328106, 44.806531],
              [1.29964, 44.796921],
              [1.304279, 44.788546],
              [1.296243, 44.777811],
              [1.313413, 44.76604],
              [1.322817, 44.765133],
              [1.316047, 44.74037],
              [1.300523, 44.743068],
              [1.299747, 44.733877],
              [1.287777, 44.714785],
              [1.270413, 44.722362],
              [1.263798, 44.710686],
              [1.248266, 44.707709],
              [1.243361, 44.703747],
              [1.240441, 44.692804],
              [1.224551, 44.684265],
              [1.19223, 44.682145],
              [1.181492, 44.683121],
              [1.169122, 44.680201],
              [1.163182, 44.674247],
              [1.146676, 44.670346],
              [1.146726, 44.651943],
              [1.153795, 44.639409],
              [1.137389, 44.623916],
              [1.107642, 44.604048],
              [1.095425, 44.590239],
              [1.102348, 44.583112],
              [1.103214, 44.571735],
              [1.091572, 44.571298],
              [1.075142, 44.577326],
              [1.071694, 44.567842],
              [1.046322, 44.562092],
              [1.034701, 44.555411],
              [1.013165, 44.53613],
              [1.010247, 44.545188],
              [0.993919, 44.549541],
              [0.981776, 44.54395],
              [0.99607, 44.526838],
              [1.016228, 44.505874],
              [1.016841, 44.492628],
              [1.00902, 44.480045],
              [1.023009, 44.475437],
              [1.02389, 44.464106],
              [1.020984, 44.456238],
              [1.024717, 44.442988],
              [1.033332, 44.432218],
              [1.045283, 44.434329],
              [1.057485, 44.427673],
              [1.060811, 44.416586],
              [1.061308, 44.401879],
              [1.051419, 44.392095],
              [1.060916, 44.388149],
              [1.064081, 44.378509],
              [1.059034, 44.369063],
              [1.049797, 44.362639],
              [1.004557, 44.365588],
              [0.997331, 44.368971],
              [0.980632, 44.358423],
              [0.971036, 44.36128],
              [0.95005, 44.359756],
              [0.941419, 44.345348],
              [0.937288, 44.368841],
              [0.925896, 44.375629],
              [0.919914, 44.38415],
              [0.898213, 44.381368],
              [0.887336, 44.366374],
              [0.893567, 44.358038],
              [0.896113, 44.346227],
              [0.873471, 44.323307],
              [0.869625, 44.309387],
              [0.882317, 44.308199],
              [0.894497, 44.29674],
              [0.916424, 44.302198],
              [0.923963, 44.288698],
              [0.949924, 44.276443],
              [0.940784, 44.263651],
              [0.927788, 44.267406],
              [0.933028, 44.253149],
              [0.919095, 44.238272],
              [0.929241, 44.230247],
              [0.924768, 44.226052],
              [0.91176, 44.20474],
              [0.906288, 44.190307],
              [0.863488, 44.193325],
              [0.856338, 44.189047],
              [0.85357, 44.174962],
              [0.86504, 44.173535],
              [0.872247, 44.167867],
              [0.883018, 44.175466],
              [0.890342, 44.169673],
              [0.886634, 44.162789],
              [0.888479, 44.148767],
              [0.881807, 44.140905],
              [0.879443, 44.129609],
              [0.868779, 44.126327],
              [0.835434, 44.136366],
              [0.82441, 44.14118],
              [0.796102, 44.145622],
              [0.788567, 44.144391],
              [0.797566, 44.130382],
              [0.79624, 44.115129],
              [0.786236, 44.111911],
              [0.771912, 44.11342],
              [0.752879, 44.102268],
              [0.73811, 44.073028],
              [0.741885, 44.065199],
              [0.736702, 44.061551],
              [0.717367, 44.057565],
              [0.707801, 44.058016],
              [0.694482, 44.045586],
              [0.687666, 44.045887],
              [0.679736, 44.037179],
              [0.679585, 44.029344],
              [0.666597, 44.025144],
              [0.655076, 44.031189],
              [0.6546, 44.041308],
              [0.631651, 44.049501],
              [0.627887, 44.060631],
              [0.604704, 44.071956],
              [0.597978, 44.078225],
              [0.575881, 44.07591],
              [0.565077, 44.059223],
              [0.539025, 44.053724],
              [0.522074, 44.056969],
              [0.512143, 44.063296],
              [0.506052, 44.056125],
              [0.485818, 44.058598],
              [0.478769, 44.054521],
              [0.459516, 44.055235],
              [0.448876, 44.042595],
              [0.442444, 44.028759],
              [0.417508, 44.02697],
              [0.394461, 44.019984],
              [0.381519, 44.006373],
              [0.371373, 44.008155],
              [0.364912, 44.015152],
              [0.357566, 44.016377],
              [0.329387, 44.008325],
              [0.316539, 44.010119],
              [0.317938, 43.994974],
              [0.304091, 43.993061],
              [0.27346, 43.998816],
              [0.265868, 44.003717],
              [0.247489, 44.004733],
              [0.235202, 44.008471],
              [0.22471, 44.019169],
              [0.205072, 44.019029],
              [0.20118, 44.013669],
              [0.189571, 44.014642],
              [0.179161, 44.000666],
              [0.166761, 43.996831],
              [0.166409, 43.984223],
              [0.159121, 43.973861],
              [0.138635, 43.974771],
              [0.140954, 43.994676],
              [0.126406, 44.000336],
              [0.114374, 43.988073],
              [0.076043, 43.983139],
              [0.067532, 43.974193],
              [0.067025, 43.967877],
              [0.055307, 43.957963],
              [0.057458, 43.94905],
              [0.068508, 43.93781],
              [0.076644, 43.91647],
              [0.059163, 43.897893],
              [0.045527, 43.902166],
              [0.032552, 43.900192],
              [0.01744, 43.910848],
              [-0.001843, 43.92142],
              [-0.013582, 43.92365],
              [-0.015361, 43.934152],
              [0.004209, 43.943424],
              [0.006735, 43.95374],
              [0.00144, 43.959887],
              [-0.021769, 43.973057],
              [-0.036165, 43.983736],
              [-0.040803, 43.968964],
              [-0.046527, 43.96104],
              [-0.059362, 43.960939],
              [-0.073597, 43.945046],
              [-0.087029, 43.947486],
              [-0.09833, 43.942428],
              [-0.094989, 43.933381],
              [-0.102257, 43.927416],
              [-0.125943, 43.944422],
              [-0.135137, 43.936298],
              [-0.153332, 43.939048],
              [-0.15392, 43.932325],
              [-0.165624, 43.927613],
              [-0.17911, 43.937915],
              [-0.184853, 43.92877],
              [-0.192024, 43.926039],
              [-0.199787, 43.91511],
              [-0.216273, 43.907357],
              [-0.227631, 43.910989],
              [-0.234437, 43.89901],
              [-0.233936, 43.891065],
              [-0.22062, 43.890378],
              [-0.201226, 43.885467],
              [-0.190992, 43.875182],
              [-0.198558, 43.862413],
              [-0.208908, 43.857673],
              [-0.195968, 43.845942],
              [-0.188129, 43.832561],
              [-0.197256, 43.830783],
              [-0.192462, 43.810178],
              [-0.217503, 43.809929],
              [-0.22714, 43.808271],
              [-0.218852, 43.796306],
              [-0.222126, 43.787092],
              [-0.213325, 43.779092],
              [-0.215467, 43.76954],
              [-0.207913, 43.760638],
              [-0.218947, 43.750868],
              [-0.206059, 43.750073],
              [-0.195569, 43.745768],
              [-0.194138, 43.737015],
              [-0.205797, 43.727838],
              [-0.232585, 43.714086],
              [-0.246652, 43.710666],
              [-0.247622, 43.705845],
              [-0.239101, 43.693947],
              [-0.255897, 43.679786],
              [-0.252056, 43.67217],
              [-0.239736, 43.671241],
              [-0.244286, 43.66312],
              [-0.243166, 43.654504],
              [-0.26184, 43.650334],
              [-0.263759, 43.635926],
              [-0.282116, 43.643074],
              [-0.279599, 43.618178],
              [-0.277715, 43.616201],
              [-0.247204, 43.615953],
              [-0.254644, 43.59708],
              [-0.248175, 43.596658],
              [-0.242833, 43.584979],
              [-0.235029, 43.583358],
              [-0.224236, 43.590403],
              [-0.210604, 43.593235],
              [-0.204743, 43.583598],
              [-0.184802, 43.591151],
              [-0.176417, 43.596401],
              [-0.160449, 43.580575],
              [-0.148097, 43.585796],
              [-0.128918, 43.581224],
              [-0.121605, 43.58624],
              [-0.108933, 43.582373],
              [-0.096788, 43.582405],
              [-0.093425, 43.56357],
              [-0.088934, 43.557204],
              [-0.094969, 43.548141],
              [-0.088671, 43.542057],
              [-0.078386, 43.546823],
              [-0.064395, 43.545147],
              [-0.056174, 43.533029],
              [-0.044655, 43.525299],
              [-0.040135, 43.512604],
              [-0.049321, 43.492468],
              [-0.034676, 43.487324],
              [-0.03396, 43.474881],
              [-0.017814, 43.472147],
              [-0.019322, 43.466531],
              [-0.065758, 43.463484],
              [-0.061667, 43.452453],
              [-0.069028, 43.437346],
              [-0.057819, 43.427454],
              [-0.053836, 43.418938],
              [-0.062025, 43.417738],
              [-0.066461, 43.411734],
              [-0.042752, 43.410435],
              [-0.034595, 43.429064],
              [-0.024647, 43.430442],
              [-0.016529, 43.443944],
              [-0.000976, 43.444399],
              [-0.004617, 43.43176],
              [0.009592, 43.422106],
              [-0.003811, 43.398395],
              [0.005178, 43.394194],
              [-0.005254, 43.373758],
              [0.005781, 43.363002],
              [0.023777, 43.349141],
              [0.026296, 43.341364],
              [0.010318, 43.325315],
              [-0.003254, 43.332106],
              [-0.024874, 43.329492],
              [-0.031831, 43.312151],
              [-0.046123, 43.300858],
              [-0.044392, 43.285272],
              [-0.023979, 43.280212],
              [-0.017009, 43.270449],
              [-0.025624, 43.260991],
              [-0.023687, 43.254986],
              [-0.037586, 43.242417],
              [-0.045868, 43.232135],
              [-0.049462, 43.217181],
              [-0.072527, 43.224387],
              [-0.067869, 43.177118],
              [-0.095496, 43.177197],
              [-0.097529, 43.166625],
              [-0.104482, 43.166691],
              [-0.111332, 43.179339],
              [-0.117701, 43.180323],
              [-0.126553, 43.160447],
              [-0.1386, 43.149104],
              [-0.140503, 43.136172],
              [-0.146136, 43.128233],
              [-0.166342, 43.121885],
              [-0.170869, 43.113421],
              [-0.190981, 43.111203],
              [-0.197682, 43.098173],
              [-0.186936, 43.091164],
              [-0.187768, 43.08333],
              [-0.197768, 43.071151],
              [-0.199151, 43.064413],
              [-0.189647, 43.052021],
              [-0.208601, 43.039724],
              [-0.223946, 43.033684],
              [-0.23942, 43.039677],
              [-0.259937, 43.038278],
              [-0.25607, 43.022727],
              [-0.26484, 43.009957],
              [-0.28772, 43.005372],
              [-0.291547, 42.987769],
              [-0.286474, 42.960054],
              [-0.279387, 42.94212],
              [-0.281592, 42.933564],
              [-0.290037, 42.929088],
              [-0.297779, 42.930973],
              [-0.308718, 42.924636],
              [-0.310746, 42.919005],
              [-0.327082, 42.915785],
              [-0.324591, 42.90526],
              [-0.318194, 42.897762],
              [-0.315668, 42.884802],
              [-0.307491, 42.867844],
              [-0.313444, 42.849375],
              [-0.323418, 42.835493],
              [-0.347544, 42.835914],
              [-0.355804, 42.83026],
              [-0.364267, 42.816952],
              [-0.382973, 42.807184],
              [-0.392597, 42.799559],
              [-0.40923, 42.807751],
              [-0.41892, 42.805668],
              [-0.439746, 42.796464],
              [-0.459553, 42.805069],
              [-0.472865, 42.808472],
              [-0.509651, 42.825391],
              [-0.524823, 42.811697],
              [-0.522878, 42.799049],
              [-0.529731, 42.791532],
              [-0.54381, 42.793157],
              [-0.551205, 42.777516],
              [-0.570593, 42.782912],
              [-0.564714, 42.798019],
              [-0.568866, 42.806822],
              [-0.577749, 42.807226],
              [-0.592731, 42.80323],
              [-0.60076, 42.806308],
              [-0.598694, 42.816584],
              [-0.603635, 42.832618],
              [-0.622186, 42.839974],
              [-0.636156, 42.853244],
              [-0.648864, 42.855199],
              [-0.661465, 42.863781],
              [-0.664469, 42.872434],
              [-0.676034, 42.882121],
              [-0.698993, 42.879656],
              [-0.720945, 42.888649],
              [-0.729757, 42.896558],
              [-0.734995, 42.912174],
              [-0.725066, 42.923031],
              [-0.732508, 42.928418],
              [-0.730954, 42.938774],
              [-0.739245, 42.947642],
              [-0.751634, 42.966937],
              [-0.756378, 42.968427],
              [-0.787808, 42.964224],
              [-0.800045, 42.958966],
              [-0.810014, 42.951436],
              [-0.838387, 42.953542],
              [-0.867851, 42.951831],
              [-0.883941, 42.955915],
              [-0.895552, 42.955177],
              [-0.899726, 42.961947],
              [-0.914287, 42.963569],
              [-0.921985, 42.955086],
              [-0.946455, 42.954058],
              [-0.96116, 42.960781],
              [-0.975191, 42.963791],
              [-0.980756, 42.970298],
              [-1.000506, 42.977947],
              [-1.006449, 42.988992],
              [-1.017554, 42.994381],
              [-1.030813, 42.993443],
              [-1.07026, 42.997697],
              [-1.083066, 43.001677],
              [-1.09188, 43.011282],
              [-1.110663, 43.020498],
              [-1.118918, 43.019962],
              [-1.133452, 43.010371],
              [-1.142568, 43.010206],
              [-1.148263, 43.026043],
              [-1.166903, 43.035573],
              [-1.180728, 43.03253],
              [-1.208627, 43.047643],
              [-1.21297, 43.051465],
              [-1.231147, 43.054495],
              [-1.247196, 43.042411],
              [-1.264286, 43.044563],
              [-1.269567, 43.052911],
              [-1.287037, 43.06301],
              [-1.308544, 43.068679],
              [-1.308596, 43.072598],
              [-1.298666, 43.09328],
              [-1.270184, 43.118569],
              [-1.320617, 43.112751],
              [-1.332009, 43.107918],
              [-1.343298, 43.09538],
              [-1.341816, 43.077659],
              [-1.345424, 43.049771],
              [-1.354752, 43.028468],
              [-1.435187, 43.045028],
              [-1.443586, 43.049156],
              [-1.471737, 43.081097],
              [-1.470868, 43.091714],
              [-1.464232, 43.097527],
              [-1.441119, 43.108355],
              [-1.424134, 43.124963],
              [-1.413947, 43.129178],
              [-1.412567, 43.13778],
              [-1.416025, 43.14974],
              [-1.407219, 43.155624],
              [-1.403378, 43.164178],
              [-1.402019, 43.17787],
              [-1.383825, 43.190817],
              [-1.385268, 43.19762],
              [-1.38336, 43.217727],
              [-1.378066, 43.229614],
              [-1.382786, 43.253297],
              [-1.40512, 43.270441],
              [-1.413206, 43.273415],
              [-1.427534, 43.266987],
              [-1.438853, 43.266517],
              [-1.466546, 43.272576],
              [-1.496216, 43.28346],
              [-1.505326, 43.292788],
              [-1.531891, 43.293508],
              [-1.55182, 43.291691],
              [-1.564716, 43.287913],
              [-1.558145, 43.276923],
              [-1.567966, 43.258219],
              [-1.575125, 43.249838],
              [-1.599409, 43.25453],
              [-1.608932, 43.252129],
              [-1.622411, 43.26395],
              [-1.630366, 43.284448],
              [-1.622603, 43.300623],
              [-1.623815, 43.305851],
              [-1.635425, 43.309047],
              [-1.64367, 43.306683],
              [-1.654864, 43.312638],
              [-1.665759, 43.314079],
              [-1.684102, 43.309624],
              [-1.703133, 43.309207],
              [-1.713521, 43.300323],
              [-1.729749, 43.295679],
              [-1.735545, 43.310342],
              [-1.741349, 43.316754],
              [-1.737352, 43.3297],
              [-1.746593, 43.331212],
              [-1.757713, 43.344008],
              [-1.775488, 43.343951],
              [-1.787732, 43.354202],
              [-1.784156, 43.362477],
              [-1.790887, 43.373441],
              [-1.774637, 43.372995],
              [-1.762022, 43.375887],
              [-1.75229, 43.386696],
              [-1.73789, 43.381658],
              [-1.686817, 43.396262],
              [-1.681464, 43.390043],
              [-1.669768, 43.388034],
              [-1.660741, 43.393409],
              [-1.663143, 43.399286],
              [-1.657708, 43.405453],
              [-1.639114, 43.408363],
              [-1.625505, 43.421748],
              [-1.609735, 43.426738],
              [-1.597388, 43.437493],
              [-1.594886, 43.448346],
              [-1.589537, 43.449747],
              [-1.577491, 43.46414],
              [-1.566718, 43.481676],
              [-1.548066, 43.495969],
              [-1.527301, 43.523224],
              [-1.52487, 43.529699],
              [-1.50703, 43.554965],
              [-1.491922, 43.572319],
              [-1.460015, 43.6203],
              [-1.448208, 43.642275],
              [-1.446479, 43.656202],
              [-1.441711, 43.669165],
              [-1.440428, 43.690015],
              [-1.436505, 43.710561],
              [-1.422121, 43.763113],
              [-1.410223, 43.803248],
              [-1.395396, 43.849122],
              [-1.39121, 43.85886],
              [-1.37499, 43.913787],
              [-1.337065, 44.0559],
              [-1.325999, 44.089757],
              [-1.325332, 44.096045],
              [-1.312326, 44.144798],
              [-1.306621, 44.171604],
              [-1.294685, 44.234574],
              [-1.28668, 44.27265],
              [-1.272103, 44.350133],
              [-1.26036, 44.421267],
              [-1.253891, 44.467602],
              [-1.251405, 44.488888],
              [-1.251731, 44.514868],
              [-1.260278, 44.539508],
              [-1.258614, 44.547133],
              [-1.246165, 44.555849],
              [-1.227397, 44.575753],
              [-1.212134, 44.59951],
              [-1.203505, 44.622709],
              [-1.20417, 44.639985],
              [-1.191538, 44.660726],
              [-1.181234, 44.663558],
              [-1.161759, 44.663377],
              [-1.145167, 44.65763],
              [-1.140763, 44.647227],
              [-1.12493, 44.647339],
              [-1.096426, 44.64211],
              [-1.080793, 44.640599],
              [-1.066018, 44.646891],
              [-1.051201, 44.64426],
              [-1.044604, 44.647779],
              [-1.005364, 44.648038],
              [-1.005304, 44.653801],
              [-1.018571, 44.663912],
              [-1.023305, 44.675026],
              [-1.031172, 44.679029],
              [-1.050028, 44.682043],
              [-1.058137, 44.689048],
              [-1.036362, 44.693562],
              [-1.106673, 44.742647],
              [-1.117241, 44.744],
              [-1.125367, 44.751001],
              [-1.145951, 44.761417],
              [-1.160528, 44.774667],
              [-1.168574, 44.771765],
              [-1.178112, 44.755804],
              [-1.1722, 44.750987],
              [-1.190069, 44.73702],
              [-1.190772, 44.73092],
              [-1.204487, 44.72154],
              [-1.204381, 44.717663],
              [-1.22296, 44.709573],
              [-1.222311, 44.706128],
              [-1.232624, 44.693019],
              [-1.237418, 44.682509],
              [-1.240108, 44.666522],
              [-1.238945, 44.657782],
              [-1.246202, 44.641863],
              [-1.243112, 44.628828],
              [-1.252148, 44.61773],
              [-1.260447, 44.625669],
              [-1.261733, 44.647703],
              [-1.251725, 44.712016],
              [-1.237023, 44.784936],
              [-1.222903, 44.864823],
              [-1.204123, 44.989781],
              [-1.198002, 45.042705],
              [-1.194206, 45.069163],
              [-1.190237, 45.107603],
              [-1.180886, 45.176064],
              [-1.162337, 45.297869],
              [-1.15936, 45.350688],
              [-1.160556, 45.410687],
              [-1.151504, 45.439472],
              [-1.151453, 45.451855],
              [-1.157037, 45.470366],
              [-1.154617, 45.480168],
              [-1.136425, 45.510992],
              [-1.122863, 45.520418],
              [-1.100294, 45.541646],
              [-1.096109, 45.556768],
              [-1.091205, 45.562411],
              [-1.060591, 45.571953],
              [-1.025739, 45.574691],
            ],
            [
              [-0.102217, 43.358515],
              [-0.090143, 43.358859],
              [-0.086787, 43.336872],
              [-0.096784, 43.334763],
              [-0.108479, 43.337802],
              [-0.115664, 43.330977],
              [-0.111395, 43.315809],
              [-0.104123, 43.312464],
              [-0.075041, 43.307136],
              [-0.070279, 43.317455],
              [-0.062503, 43.346712],
              [-0.065397, 43.355049],
              [-0.076423, 43.364562],
              [-0.090959, 43.373163],
              [-0.107283, 43.370735],
              [-0.102217, 43.358515],
            ],
            [
              [-0.103071, 43.242819],
              [-0.099321, 43.252127],
              [-0.092112, 43.252066],
              [-0.079841, 43.262366],
              [-0.079284, 43.271656],
              [-0.096174, 43.285481],
              [-0.092031, 43.300469],
              [-0.111854, 43.310395],
              [-0.119979, 43.30625],
              [-0.126165, 43.293877],
              [-0.136506, 43.284505],
              [-0.140615, 43.271941],
              [-0.133465, 43.258459],
              [-0.122151, 43.243894],
              [-0.103071, 43.242819],
            ],
          ],
          [
            [
              [-1.480904, 46.210027],
              [-1.467059, 46.217438],
              [-1.458199, 46.22867],
              [-1.412938, 46.230134],
              [-1.422042, 46.218168],
              [-1.44391, 46.213521],
              [-1.422099, 46.20488],
              [-1.404207, 46.203131],
              [-1.363897, 46.207475],
              [-1.347685, 46.202735],
              [-1.322213, 46.18753],
              [-1.307956, 46.190557],
              [-1.291069, 46.186236],
              [-1.274234, 46.160161],
              [-1.256792, 46.160987],
              [-1.276168, 46.147265],
              [-1.304772, 46.142879],
              [-1.321632, 46.145037],
              [-1.327418, 46.148227],
              [-1.354955, 46.155886],
              [-1.370902, 46.167389],
              [-1.389569, 46.175712],
              [-1.461605, 46.202119],
              [-1.474622, 46.202762],
              [-1.505925, 46.194091],
              [-1.535323, 46.204393],
              [-1.543813, 46.219776],
              [-1.561411, 46.237187],
              [-1.56148, 46.245427],
              [-1.541096, 46.243605],
              [-1.523557, 46.251305],
              [-1.514374, 46.257714],
              [-1.500577, 46.258182],
              [-1.482015, 46.24719],
              [-1.474548, 46.233255],
              [-1.482013, 46.228635],
              [-1.491522, 46.229124],
              [-1.496323, 46.234949],
              [-1.512718, 46.222325],
              [-1.506715, 46.214571],
              [-1.499534, 46.216647],
              [-1.487694, 46.206897],
              [-1.480904, 46.210027],
            ],
          ],
          [
            [
              [-1.25026, 45.845963],
              [-1.259216, 45.868299],
              [-1.266155, 45.878169],
              [-1.287574, 45.894605],
              [-1.338052, 45.91787],
              [-1.350955, 45.925037],
              [-1.383917, 45.951445],
              [-1.391717, 45.974043],
              [-1.387253, 45.993871],
              [-1.389081, 46.002682],
              [-1.400533, 46.016129],
              [-1.413435, 46.046865],
              [-1.407053, 46.048113],
              [-1.386106, 46.0416],
              [-1.371273, 46.039342],
              [-1.372079, 46.032155],
              [-1.365928, 46.027268],
              [-1.335813, 46.012041],
              [-1.31276, 45.992976],
              [-1.295763, 45.989021],
              [-1.281187, 45.988409],
              [-1.24792, 45.990418],
              [-1.236033, 45.98152],
              [-1.23546, 45.968794],
              [-1.242082, 45.957461],
              [-1.229793, 45.944251],
              [-1.22908, 45.933287],
              [-1.233773, 45.927059],
              [-1.223574, 45.913107],
              [-1.206434, 45.901445],
              [-1.188429, 45.886745],
              [-1.188389, 45.882604],
              [-1.200038, 45.867289],
              [-1.20771, 45.850341],
              [-1.195619, 45.829693],
              [-1.205209, 45.824573],
              [-1.21743, 45.813047],
              [-1.236424, 45.804532],
              [-1.245666, 45.821355],
              [-1.25026, 45.845963],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5392",
      properties: {
        name: "Occitanie",
        density: 0,
        path: "/world/France/Occitanie",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.78613, 42.573623],
              [1.78094, 42.582603],
              [1.773159, 42.580676],
              [1.753648, 42.582645],
              [1.741967, 42.587753],
              [1.725922, 42.590364],
              [1.726445, 42.599644],
              [1.737968, 42.611311],
              [1.734977, 42.61715],
              [1.717945, 42.614762],
              [1.700021, 42.621544],
              [1.687356, 42.623901],
              [1.662023, 42.619477],
              [1.653723, 42.626312],
              [1.641382, 42.628127],
              [1.619009, 42.626912],
              [1.601469, 42.62804],
              [1.584208, 42.634096],
              [1.56879, 42.648435],
              [1.549251, 42.655773],
              [1.52215, 42.649183],
              [1.518905, 42.645476],
              [1.501417, 42.645161],
              [1.493745, 42.653209],
              [1.480109, 42.651394],
              [1.477898, 42.643814],
              [1.468337, 42.630823],
              [1.477266, 42.614964],
              [1.46946, 42.60689],
              [1.458939, 42.603638],
              [1.440154, 42.603504],
              [1.435057, 42.606074],
              [1.420086, 42.627198],
              [1.41359, 42.651807],
              [1.394242, 42.668387],
              [1.387737, 42.670987],
              [1.3896, 42.685086],
              [1.378661, 42.693636],
              [1.354406, 42.699495],
              [1.352365, 42.70889],
              [1.356894, 42.719323],
              [1.342366, 42.719197],
              [1.335266, 42.724034],
              [1.325023, 42.723901],
              [1.310831, 42.718076],
              [1.298055, 42.718971],
              [1.277559, 42.71475],
              [1.251509, 42.717852],
              [1.247471, 42.72204],
              [1.230214, 42.727583],
              [1.217157, 42.720482],
              [1.184803, 42.715103],
              [1.172774, 42.708101],
              [1.161851, 42.711044],
              [1.133306, 42.729012],
              [1.130097, 42.752068],
              [1.122151, 42.760174],
              [1.10922, 42.76838],
              [1.075109, 42.785348],
              [1.05434, 42.78237],
              [1.035621, 42.786428],
              [1.012363, 42.788068],
              [1.005579, 42.7908],
              [0.996485, 42.787452],
              [0.983103, 42.787068],
              [0.973284, 42.797457],
              [0.959891, 42.805644],
              [0.939739, 42.796973],
              [0.933919, 42.789522],
              [0.927327, 42.789256],
              [0.911059, 42.796343],
              [0.883847, 42.813501],
              [0.870392, 42.816246],
              [0.858301, 42.825718],
              [0.831739, 42.829192],
              [0.808923, 42.839054],
              [0.801488, 42.840484],
              [0.786495, 42.835955],
              [0.76764, 42.838977],
              [0.74731, 42.846685],
              [0.73506, 42.854314],
              [0.708375, 42.861403],
              [0.692025, 42.855312],
              [0.67832, 42.855019],
              [0.678584, 42.847953],
              [0.659017, 42.838529],
              [0.661146, 42.830369],
              [0.668514, 42.82035],
              [0.665361, 42.812512],
              [0.669687, 42.802095],
              [0.658631, 42.797152],
              [0.65129, 42.786597],
              [0.644538, 42.783076],
              [0.664236, 42.771673],
              [0.650321, 42.764169],
              [0.647431, 42.751663],
              [0.659939, 42.752436],
              [0.669575, 42.73277],
              [0.679012, 42.722784],
              [0.673867, 42.716587],
              [0.682258, 42.708965],
              [0.673775, 42.699841],
              [0.670577, 42.689893],
              [0.637901, 42.693041],
              [0.607643, 42.699058],
              [0.59732, 42.705833],
              [0.586831, 42.694999],
              [0.530288, 42.702427],
              [0.512541, 42.692055],
              [0.491879, 42.695004],
              [0.477751, 42.699987],
              [0.45693, 42.692659],
              [0.429277, 42.690748],
              [0.417687, 42.694691],
              [0.402991, 42.696059],
              [0.395815, 42.701202],
              [0.392626, 42.713119],
              [0.376902, 42.714106],
              [0.359628, 42.723391],
              [0.347875, 42.714179],
              [0.326225, 42.705239],
              [0.323469, 42.687138],
              [0.300203, 42.676589],
              [0.292824, 42.674921],
              [0.270869, 42.690721],
              [0.259886, 42.715815],
              [0.245517, 42.718158],
              [0.226679, 42.717354],
              [0.20599, 42.729296],
              [0.175726, 42.73648],
              [0.162211, 42.725842],
              [0.136572, 42.722324],
              [0.125713, 42.713757],
              [0.111253, 42.710208],
              [0.090419, 42.717129],
              [0.074773, 42.712092],
              [0.07071, 42.703944],
              [0.059213, 42.698966],
              [0.045947, 42.696775],
              [0.025181, 42.701924],
              [0.016465, 42.701788],
              [0.015304, 42.695195],
              [0.002354, 42.686188],
              [-0.01064, 42.684384],
              [-0.047757, 42.693359],
              [-0.059811, 42.693424],
              [-0.064564, 42.699354],
              [-0.06401, 42.710085],
              [-0.068842, 42.717901],
              [-0.076501, 42.717198],
              [-0.090398, 42.721078],
              [-0.106113, 42.720827],
              [-0.110707, 42.725008],
              [-0.1108, 42.734997],
              [-0.136666, 42.764142],
              [-0.145997, 42.768469],
              [-0.154212, 42.78077],
              [-0.154581, 42.792741],
              [-0.159724, 42.797401],
              [-0.178493, 42.785359],
              [-0.189224, 42.787796],
              [-0.201566, 42.796278],
              [-0.213905, 42.796038],
              [-0.238446, 42.808173],
              [-0.238865, 42.818111],
              [-0.243362, 42.823143],
              [-0.258014, 42.821008],
              [-0.267338, 42.826009],
              [-0.276686, 42.835471],
              [-0.305474, 42.841233],
              [-0.313444, 42.849375],
              [-0.307491, 42.867844],
              [-0.315668, 42.884802],
              [-0.318194, 42.897762],
              [-0.324591, 42.90526],
              [-0.327082, 42.915785],
              [-0.310746, 42.919005],
              [-0.308718, 42.924636],
              [-0.297779, 42.930973],
              [-0.290037, 42.929088],
              [-0.281592, 42.933564],
              [-0.279387, 42.94212],
              [-0.286474, 42.960054],
              [-0.291547, 42.987769],
              [-0.28772, 43.005372],
              [-0.26484, 43.009957],
              [-0.25607, 43.022727],
              [-0.259937, 43.038278],
              [-0.23942, 43.039677],
              [-0.223946, 43.033684],
              [-0.208601, 43.039724],
              [-0.189647, 43.052021],
              [-0.199151, 43.064413],
              [-0.197768, 43.071151],
              [-0.187768, 43.08333],
              [-0.186936, 43.091164],
              [-0.197682, 43.098173],
              [-0.190981, 43.111203],
              [-0.170869, 43.113421],
              [-0.166342, 43.121885],
              [-0.146136, 43.128233],
              [-0.140503, 43.136172],
              [-0.1386, 43.149104],
              [-0.126553, 43.160447],
              [-0.117701, 43.180323],
              [-0.111332, 43.179339],
              [-0.104482, 43.166691],
              [-0.097529, 43.166625],
              [-0.095496, 43.177197],
              [-0.067869, 43.177118],
              [-0.072527, 43.224387],
              [-0.049462, 43.217181],
              [-0.045868, 43.232135],
              [-0.037586, 43.242417],
              [-0.023687, 43.254986],
              [-0.025624, 43.260991],
              [-0.017009, 43.270449],
              [-0.023979, 43.280212],
              [-0.044392, 43.285272],
              [-0.046123, 43.300858],
              [-0.031831, 43.312151],
              [-0.024874, 43.329492],
              [-0.003254, 43.332106],
              [0.010318, 43.325315],
              [0.026296, 43.341364],
              [0.023777, 43.349141],
              [0.005781, 43.363002],
              [-0.005254, 43.373758],
              [0.005178, 43.394194],
              [-0.003811, 43.398395],
              [0.009592, 43.422106],
              [-0.004617, 43.43176],
              [-0.000976, 43.444399],
              [-0.016529, 43.443944],
              [-0.024647, 43.430442],
              [-0.034595, 43.429064],
              [-0.042752, 43.410435],
              [-0.066461, 43.411734],
              [-0.062025, 43.417738],
              [-0.053836, 43.418938],
              [-0.057819, 43.427454],
              [-0.069028, 43.437346],
              [-0.061667, 43.452453],
              [-0.065758, 43.463484],
              [-0.019322, 43.466531],
              [-0.017814, 43.472147],
              [-0.03396, 43.474881],
              [-0.034676, 43.487324],
              [-0.049321, 43.492468],
              [-0.040135, 43.512604],
              [-0.044655, 43.525299],
              [-0.056174, 43.533029],
              [-0.064395, 43.545147],
              [-0.078386, 43.546823],
              [-0.088671, 43.542057],
              [-0.094969, 43.548141],
              [-0.088934, 43.557204],
              [-0.093425, 43.56357],
              [-0.096788, 43.582405],
              [-0.108933, 43.582373],
              [-0.121605, 43.58624],
              [-0.128918, 43.581224],
              [-0.148097, 43.585796],
              [-0.160449, 43.580575],
              [-0.176417, 43.596401],
              [-0.184802, 43.591151],
              [-0.204743, 43.583598],
              [-0.210604, 43.593235],
              [-0.224236, 43.590403],
              [-0.235029, 43.583358],
              [-0.242833, 43.584979],
              [-0.248175, 43.596658],
              [-0.254644, 43.59708],
              [-0.247204, 43.615953],
              [-0.277715, 43.616201],
              [-0.279599, 43.618178],
              [-0.282116, 43.643074],
              [-0.263759, 43.635926],
              [-0.26184, 43.650334],
              [-0.243166, 43.654504],
              [-0.244286, 43.66312],
              [-0.239736, 43.671241],
              [-0.252056, 43.67217],
              [-0.255897, 43.679786],
              [-0.239101, 43.693947],
              [-0.247622, 43.705845],
              [-0.246652, 43.710666],
              [-0.232585, 43.714086],
              [-0.205797, 43.727838],
              [-0.194138, 43.737015],
              [-0.195569, 43.745768],
              [-0.206059, 43.750073],
              [-0.218947, 43.750868],
              [-0.207913, 43.760638],
              [-0.215467, 43.76954],
              [-0.213325, 43.779092],
              [-0.222126, 43.787092],
              [-0.218852, 43.796306],
              [-0.22714, 43.808271],
              [-0.217503, 43.809929],
              [-0.192462, 43.810178],
              [-0.197256, 43.830783],
              [-0.188129, 43.832561],
              [-0.195968, 43.845942],
              [-0.208908, 43.857673],
              [-0.198558, 43.862413],
              [-0.190992, 43.875182],
              [-0.201226, 43.885467],
              [-0.22062, 43.890378],
              [-0.233936, 43.891065],
              [-0.234437, 43.89901],
              [-0.227631, 43.910989],
              [-0.216273, 43.907357],
              [-0.199787, 43.91511],
              [-0.192024, 43.926039],
              [-0.184853, 43.92877],
              [-0.17911, 43.937915],
              [-0.165624, 43.927613],
              [-0.15392, 43.932325],
              [-0.153332, 43.939048],
              [-0.135137, 43.936298],
              [-0.125943, 43.944422],
              [-0.102257, 43.927416],
              [-0.094989, 43.933381],
              [-0.09833, 43.942428],
              [-0.087029, 43.947486],
              [-0.073597, 43.945046],
              [-0.059362, 43.960939],
              [-0.046527, 43.96104],
              [-0.040803, 43.968964],
              [-0.036165, 43.983736],
              [-0.021769, 43.973057],
              [0.00144, 43.959887],
              [0.006735, 43.95374],
              [0.004209, 43.943424],
              [-0.015361, 43.934152],
              [-0.013582, 43.92365],
              [-0.001843, 43.92142],
              [0.01744, 43.910848],
              [0.032552, 43.900192],
              [0.045527, 43.902166],
              [0.059163, 43.897893],
              [0.076644, 43.91647],
              [0.068508, 43.93781],
              [0.057458, 43.94905],
              [0.055307, 43.957963],
              [0.067025, 43.967877],
              [0.067532, 43.974193],
              [0.076043, 43.983139],
              [0.114374, 43.988073],
              [0.126406, 44.000336],
              [0.140954, 43.994676],
              [0.138635, 43.974771],
              [0.159121, 43.973861],
              [0.166409, 43.984223],
              [0.166761, 43.996831],
              [0.179161, 44.000666],
              [0.189571, 44.014642],
              [0.20118, 44.013669],
              [0.205072, 44.019029],
              [0.22471, 44.019169],
              [0.235202, 44.008471],
              [0.247489, 44.004733],
              [0.265868, 44.003717],
              [0.27346, 43.998816],
              [0.304091, 43.993061],
              [0.317938, 43.994974],
              [0.316539, 44.010119],
              [0.329387, 44.008325],
              [0.357566, 44.016377],
              [0.364912, 44.015152],
              [0.371373, 44.008155],
              [0.381519, 44.006373],
              [0.394461, 44.019984],
              [0.417508, 44.02697],
              [0.442444, 44.028759],
              [0.448876, 44.042595],
              [0.459516, 44.055235],
              [0.478769, 44.054521],
              [0.485818, 44.058598],
              [0.506052, 44.056125],
              [0.512143, 44.063296],
              [0.522074, 44.056969],
              [0.539025, 44.053724],
              [0.565077, 44.059223],
              [0.575881, 44.07591],
              [0.597978, 44.078225],
              [0.604704, 44.071956],
              [0.627887, 44.060631],
              [0.631651, 44.049501],
              [0.6546, 44.041308],
              [0.655076, 44.031189],
              [0.666597, 44.025144],
              [0.679585, 44.029344],
              [0.679736, 44.037179],
              [0.687666, 44.045887],
              [0.694482, 44.045586],
              [0.707801, 44.058016],
              [0.717367, 44.057565],
              [0.736702, 44.061551],
              [0.741885, 44.065199],
              [0.73811, 44.073028],
              [0.752879, 44.102268],
              [0.771912, 44.11342],
              [0.786236, 44.111911],
              [0.79624, 44.115129],
              [0.797566, 44.130382],
              [0.788567, 44.144391],
              [0.796102, 44.145622],
              [0.82441, 44.14118],
              [0.835434, 44.136366],
              [0.868779, 44.126327],
              [0.879443, 44.129609],
              [0.881807, 44.140905],
              [0.888479, 44.148767],
              [0.886634, 44.162789],
              [0.890342, 44.169673],
              [0.883018, 44.175466],
              [0.872247, 44.167867],
              [0.86504, 44.173535],
              [0.85357, 44.174962],
              [0.856338, 44.189047],
              [0.863488, 44.193325],
              [0.906288, 44.190307],
              [0.91176, 44.20474],
              [0.924768, 44.226052],
              [0.929241, 44.230247],
              [0.919095, 44.238272],
              [0.933028, 44.253149],
              [0.927788, 44.267406],
              [0.940784, 44.263651],
              [0.949924, 44.276443],
              [0.923963, 44.288698],
              [0.916424, 44.302198],
              [0.894497, 44.29674],
              [0.882317, 44.308199],
              [0.869625, 44.309387],
              [0.873471, 44.323307],
              [0.896113, 44.346227],
              [0.893567, 44.358038],
              [0.887336, 44.366374],
              [0.898213, 44.381368],
              [0.919914, 44.38415],
              [0.925896, 44.375629],
              [0.937288, 44.368841],
              [0.941419, 44.345348],
              [0.95005, 44.359756],
              [0.971036, 44.36128],
              [0.980632, 44.358423],
              [0.997331, 44.368971],
              [1.004557, 44.365588],
              [1.049797, 44.362639],
              [1.059034, 44.369063],
              [1.064081, 44.378509],
              [1.060916, 44.388149],
              [1.051419, 44.392095],
              [1.061308, 44.401879],
              [1.060811, 44.416586],
              [1.057485, 44.427673],
              [1.045283, 44.434329],
              [1.033332, 44.432218],
              [1.024717, 44.442988],
              [1.020984, 44.456238],
              [1.02389, 44.464106],
              [1.023009, 44.475437],
              [1.00902, 44.480045],
              [1.016841, 44.492628],
              [1.016228, 44.505874],
              [0.99607, 44.526838],
              [0.981776, 44.54395],
              [0.993919, 44.549541],
              [1.010247, 44.545188],
              [1.013165, 44.53613],
              [1.034701, 44.555411],
              [1.046322, 44.562092],
              [1.071694, 44.567842],
              [1.075142, 44.577326],
              [1.091572, 44.571298],
              [1.103214, 44.571735],
              [1.102348, 44.583112],
              [1.095425, 44.590239],
              [1.107642, 44.604048],
              [1.137389, 44.623916],
              [1.153795, 44.639409],
              [1.146726, 44.651943],
              [1.146676, 44.670346],
              [1.163182, 44.674247],
              [1.169122, 44.680201],
              [1.181492, 44.683121],
              [1.19223, 44.682145],
              [1.224551, 44.684265],
              [1.240441, 44.692804],
              [1.243361, 44.703747],
              [1.248266, 44.707709],
              [1.263798, 44.710686],
              [1.270413, 44.722362],
              [1.287777, 44.714785],
              [1.299747, 44.733877],
              [1.300523, 44.743068],
              [1.316047, 44.74037],
              [1.322817, 44.765133],
              [1.313413, 44.76604],
              [1.296243, 44.777811],
              [1.304279, 44.788546],
              [1.29964, 44.796921],
              [1.328106, 44.806531],
              [1.336866, 44.806072],
              [1.364106, 44.811568],
              [1.36063, 44.826748],
              [1.361409, 44.840796],
              [1.369985, 44.846449],
              [1.377145, 44.841824],
              [1.386102, 44.847435],
              [1.401938, 44.84945],
              [1.40483, 44.862526],
              [1.41818, 44.870599],
              [1.431084, 44.871281],
              [1.441926, 44.877576],
              [1.439857, 44.888947],
              [1.421635, 44.896767],
              [1.413539, 44.911822],
              [1.424626, 44.919694],
              [1.44235, 44.916547],
              [1.436513, 44.932251],
              [1.436404, 44.940615],
              [1.420734, 44.955117],
              [1.414588, 44.977794],
              [1.413304, 44.999382],
              [1.409264, 45.006004],
              [1.428182, 45.00922],
              [1.44826, 45.019314],
              [1.461983, 45.013702],
              [1.473584, 45.017999],
              [1.480057, 45.026798],
              [1.502855, 45.038441],
              [1.51958, 45.04103],
              [1.535723, 45.046276],
              [1.541069, 45.042887],
              [1.543704, 45.030762],
              [1.552045, 45.028473],
              [1.569042, 45.038712],
              [1.57631, 45.040696],
              [1.58931, 45.036412],
              [1.61474, 45.03302],
              [1.629956, 45.033567],
              [1.650977, 45.025013],
              [1.654455, 45.017019],
              [1.67141, 45.004302],
              [1.684375, 45.002661],
              [1.687161, 44.99638],
              [1.702662, 44.987826],
              [1.711033, 44.967296],
              [1.721546, 44.968066],
              [1.74284, 44.959831],
              [1.750619, 44.954961],
              [1.75367, 44.940576],
              [1.768522, 44.931118],
              [1.774928, 44.923722],
              [1.782857, 44.929653],
              [1.784872, 44.937318],
              [1.800891, 44.92421],
              [1.808582, 44.92771],
              [1.823915, 44.927683],
              [1.836439, 44.937455],
              [1.844348, 44.93803],
              [1.851053, 44.946246],
              [1.867032, 44.952926],
              [1.88741, 44.956563],
              [1.892666, 44.964883],
              [1.908158, 44.978423],
              [1.928065, 44.978715],
              [1.939067, 44.973222],
              [1.940717, 44.95514],
              [1.950992, 44.953152],
              [1.95577, 44.958319],
              [1.974098, 44.966633],
              [1.985357, 44.97445],
              [2.006869, 44.97663],
              [2.045433, 44.983665],
              [2.052567, 44.976478],
              [2.062908, 44.976505],
              [2.080694, 44.953286],
              [2.07632, 44.940562],
              [2.078624, 44.932494],
              [2.089228, 44.92856],
              [2.108105, 44.910639],
              [2.08649, 44.90108],
              [2.083736, 44.887424],
              [2.09421, 44.872012],
              [2.11657, 44.850184],
              [2.122777, 44.847633],
              [2.139803, 44.823821],
              [2.165821, 44.812904],
              [2.165293, 44.79956],
              [2.171637, 44.790027],
              [2.166702, 44.772652],
              [2.1494, 44.769791],
              [2.153496, 44.753104],
              [2.152224, 44.736723],
              [2.147968, 44.723036],
              [2.133685, 44.709565],
              [2.130132, 44.698486],
              [2.138664, 44.692881],
              [2.155296, 44.700239],
              [2.163032, 44.690215],
              [2.179152, 44.674446],
              [2.165566, 44.661375],
              [2.174044, 44.653645],
              [2.16876, 44.647387],
              [2.169416, 44.63807],
              [2.190806, 44.628252],
              [2.207473, 44.615529],
              [2.219871, 44.623646],
              [2.214542, 44.627081],
              [2.208415, 44.643842],
              [2.228625, 44.655107],
              [2.250894, 44.651888],
              [2.264931, 44.660758],
              [2.27093, 44.660401],
              [2.291037, 44.666582],
              [2.304587, 44.662449],
              [2.326791, 44.669693],
              [2.336558, 44.661276],
              [2.332779, 44.650618],
              [2.348539, 44.64148],
              [2.365998, 44.641342],
              [2.378223, 44.650162],
              [2.397446, 44.646186],
              [2.405522, 44.64718],
              [2.435001, 44.638875],
              [2.452164, 44.648221],
              [2.468039, 44.642888],
              [2.468999, 44.649642],
              [2.483187, 44.650343],
              [2.490134, 44.656773],
              [2.487578, 44.666984],
              [2.498915, 44.687021],
              [2.518886, 44.699028],
              [2.518459, 44.703481],
              [2.556123, 44.721284],
              [2.551941, 44.732805],
              [2.554491, 44.739644],
              [2.551639, 44.75072],
              [2.558589, 44.757925],
              [2.562713, 44.775757],
              [2.573548, 44.784963],
              [2.586565, 44.78399],
              [2.599361, 44.792806],
              [2.601388, 44.807574],
              [2.597136, 44.819511],
              [2.607471, 44.824097],
              [2.611739, 44.831312],
              [2.602682, 44.843163],
              [2.618053, 44.854604],
              [2.62393, 44.866918],
              [2.636608, 44.872551],
              [2.649065, 44.869728],
              [2.656227, 44.872416],
              [2.658106, 44.882979],
              [2.681298, 44.907393],
              [2.693891, 44.902938],
              [2.706434, 44.907222],
              [2.713593, 44.92713],
              [2.724021, 44.924576],
              [2.731007, 44.936748],
              [2.738258, 44.941219],
              [2.760393, 44.92751],
              [2.77354, 44.915091],
              [2.776088, 44.908964],
              [2.771859, 44.902271],
              [2.778338, 44.887718],
              [2.770491, 44.875538],
              [2.768411, 44.863859],
              [2.772707, 44.855546],
              [2.801958, 44.873626],
              [2.822033, 44.87157],
              [2.849652, 44.87149],
              [2.859674, 44.874465],
              [2.855965, 44.85518],
              [2.870508, 44.827398],
              [2.879575, 44.803006],
              [2.889706, 44.788417],
              [2.904073, 44.783703],
              [2.92019, 44.794309],
              [2.934518, 44.779202],
              [2.934822, 44.766338],
              [2.920801, 44.76573],
              [2.91725, 44.756714],
              [2.928456, 44.755161],
              [2.933526, 44.750102],
              [2.932201, 44.738183],
              [2.923267, 44.728643],
              [2.93509, 44.705005],
              [2.933873, 44.697402],
              [2.940897, 44.683819],
              [2.939287, 44.677756],
              [2.948727, 44.672978],
              [2.963758, 44.654317],
              [2.972997, 44.645719],
              [2.981676, 44.644673],
              [2.98176, 44.651995],
              [2.998574, 44.674443],
              [3.016012, 44.713692],
              [3.025141, 44.716097],
              [3.039124, 44.714996],
              [3.035354, 44.727207],
              [3.028417, 44.732557],
              [3.031073, 44.749414],
              [3.042243, 44.759381],
              [3.050392, 44.779809],
              [3.045749, 44.799052],
              [3.061522, 44.817172],
              [3.076859, 44.82441],
              [3.071475, 44.834125],
              [3.099481, 44.833585],
              [3.100121, 44.844374],
              [3.095325, 44.852084],
              [3.105037, 44.866395],
              [3.105495, 44.886775],
              [3.122913, 44.892733],
              [3.12838, 44.903578],
              [3.141098, 44.903315],
              [3.153173, 44.893017],
              [3.169021, 44.874091],
              [3.182317, 44.863735],
              [3.19918, 44.874642],
              [3.215962, 44.875041],
              [3.225294, 44.882045],
              [3.232343, 44.891622],
              [3.226984, 44.897602],
              [3.227814, 44.909928],
              [3.249689, 44.916235],
              [3.245168, 44.934803],
              [3.25954, 44.942429],
              [3.261522, 44.932068],
              [3.271337, 44.9276],
              [3.286018, 44.926382],
              [3.297306, 44.935605],
              [3.337942, 44.955907],
              [3.354532, 44.95482],
              [3.361348, 44.971408],
              [3.370319, 44.969984],
              [3.371355, 44.96025],
              [3.386368, 44.952742],
              [3.396993, 44.958578],
              [3.404553, 44.9564],
              [3.412832, 44.944842],
              [3.414953, 44.932632],
              [3.413833, 44.91986],
              [3.419259, 44.908428],
              [3.417977, 44.900767],
              [3.435705, 44.880245],
              [3.438625, 44.860822],
              [3.441674, 44.85445],
              [3.454199, 44.844564],
              [3.457062, 44.833778],
              [3.475771, 44.815371],
              [3.47881, 44.809447],
              [3.490996, 44.808474],
              [3.494866, 44.815406],
              [3.505646, 44.823847],
              [3.539752, 44.828176],
              [3.561576, 44.824977],
              [3.563821, 44.832832],
              [3.574542, 44.83461],
              [3.578465, 44.825697],
              [3.589355, 44.832194],
              [3.592066, 44.847965],
              [3.598035, 44.859505],
              [3.594334, 44.875433],
              [3.608276, 44.879331],
              [3.61475, 44.874174],
              [3.626452, 44.880254],
              [3.642346, 44.878382],
              [3.659522, 44.869941],
              [3.670479, 44.850864],
              [3.671817, 44.843382],
              [3.657421, 44.836124],
              [3.666362, 44.828776],
              [3.695439, 44.830959],
              [3.700386, 44.836813],
              [3.722884, 44.830987],
              [3.740649, 44.838697],
              [3.752681, 44.820979],
              [3.762429, 44.810666],
              [3.758761, 44.805577],
              [3.779487, 44.797311],
              [3.803231, 44.781579],
              [3.803331, 44.770414],
              [3.812132, 44.766033],
              [3.819884, 44.772685],
              [3.830146, 44.775711],
              [3.841603, 44.77213],
              [3.842872, 44.76766],
              [3.830456, 44.756162],
              [3.836559, 44.747848],
              [3.862531, 44.743866],
              [3.875462, 44.740627],
              [3.876299, 44.7318],
              [3.867792, 44.728143],
              [3.861513, 44.712017],
              [3.871891, 44.705397],
              [3.869643, 44.696963],
              [3.884548, 44.697348],
              [3.87327, 44.678202],
              [3.882909, 44.660089],
              [3.894742, 44.651129],
              [3.892615, 44.62303],
              [3.898532, 44.613412],
              [3.908304, 44.606616],
              [3.905171, 44.592709],
              [3.919167, 44.585245],
              [3.920737, 44.575746],
              [3.928483, 44.569999],
              [3.945151, 44.57379],
              [3.960875, 44.554804],
              [3.965745, 44.537497],
              [3.975366, 44.523651],
              [3.976654, 44.51669],
              [3.983704, 44.510168],
              [3.987332, 44.499163],
              [3.985747, 44.487924],
              [3.987544, 44.473361],
              [3.998162, 44.459798],
              [4.014984, 44.454514],
              [4.023206, 44.445573],
              [4.037599, 44.44572],
              [4.046458, 44.432711],
              [4.035482, 44.420091],
              [4.056104, 44.414336],
              [4.068445, 44.405112],
              [4.057213, 44.393348],
              [4.042469, 44.394644],
              [4.043744, 44.38455],
              [4.052985, 44.378694],
              [4.055527, 44.365626],
              [4.053154, 44.340969],
              [4.045004, 44.333082],
              [4.036867, 44.330771],
              [4.051457, 44.317322],
              [4.071627, 44.327299],
              [4.103991, 44.3339],
              [4.112513, 44.32949],
              [4.126746, 44.337731],
              [4.140304, 44.327884],
              [4.142871, 44.313352],
              [4.154887, 44.312622],
              [4.177745, 44.317879],
              [4.186707, 44.299685],
              [4.216443, 44.288853],
              [4.241324, 44.270096],
              [4.25885, 44.264784],
              [4.276618, 44.274253],
              [4.28941, 44.293215],
              [4.288423, 44.314658],
              [4.304428, 44.315234],
              [4.321724, 44.323989],
              [4.336071, 44.339519],
              [4.366778, 44.339498],
              [4.386526, 44.346614],
              [4.394199, 44.345265],
              [4.403209, 44.333896],
              [4.390781, 44.303051],
              [4.393407, 44.293647],
              [4.398978, 44.288944],
              [4.421891, 44.287324],
              [4.440502, 44.284053],
              [4.45069, 44.297287],
              [4.449272, 44.304915],
              [4.450886, 44.332617],
              [4.457616, 44.341636],
              [4.476116, 44.340745],
              [4.484186, 44.337582],
              [4.503539, 44.340188],
              [4.517864, 44.329927],
              [4.532621, 44.322525],
              [4.544468, 44.320767],
              [4.558696, 44.302443],
              [4.574187, 44.300751],
              [4.586981, 44.294596],
              [4.606818, 44.290472],
              [4.618146, 44.278542],
              [4.628823, 44.284004],
              [4.63806, 44.282965],
              [4.641963, 44.273967],
              [4.649227, 44.27036],
              [4.654065, 44.254338],
              [4.674841, 44.238547],
              [4.678429, 44.22943],
              [4.672666, 44.216806],
              [4.683641, 44.212423],
              [4.701817, 44.216071],
              [4.709712, 44.206925],
              [4.703895, 44.197612],
              [4.722071, 44.187421],
              [4.716117, 44.165975],
              [4.718893, 44.144497],
              [4.714319, 44.137803],
              [4.707013, 44.113682],
              [4.70746, 44.10367],
              [4.719639, 44.086665],
              [4.730135, 44.079023],
              [4.754876, 44.088309],
              [4.760855, 44.086297],
              [4.757851, 44.077247],
              [4.787979, 44.065057],
              [4.789118, 44.052194],
              [4.808007, 44.039723],
              [4.816168, 44.032145],
              [4.821141, 44.016469],
              [4.837676, 44.01494],
              [4.843333, 44.009858],
              [4.84555, 43.996677],
              [4.8421, 43.986474],
              [4.831996, 43.985261],
              [4.815983, 43.9888],
              [4.810148, 43.977044],
              [4.815055, 43.967622],
              [4.808, 43.960029],
              [4.797153, 43.956719],
              [4.779021, 43.937894],
              [4.768779, 43.933801],
              [4.74999, 43.932182],
              [4.73906, 43.924062],
              [4.740099, 43.919679],
              [4.723368, 43.905956],
              [4.703798, 43.899341],
              [4.707494, 43.895551],
              [4.690546, 43.883899],
              [4.665438, 43.875147],
              [4.656648, 43.874779],
              [4.641915, 43.867477],
              [4.64801, 43.851229],
              [4.658666, 43.853224],
              [4.6663, 43.844777],
              [4.650152, 43.839124],
              [4.642419, 43.831437],
              [4.652277, 43.816838],
              [4.654826, 43.806405],
              [4.651244, 43.782275],
              [4.628819, 43.759862],
              [4.622717, 43.743237],
              [4.613635, 43.729822],
              [4.61304, 43.714297],
              [4.627662, 43.690543],
              [4.60583, 43.686077],
              [4.593035, 43.68746],
              [4.581885, 43.696369],
              [4.552779, 43.701311],
              [4.539847, 43.707276],
              [4.52439, 43.702131],
              [4.503672, 43.702259],
              [4.487234, 43.699241],
              [4.475662, 43.684464],
              [4.475495, 43.671096],
              [4.455948, 43.667416],
              [4.447579, 43.659307],
              [4.438216, 43.644375],
              [4.427034, 43.620675],
              [4.439748, 43.610673],
              [4.454526, 43.611392],
              [4.466807, 43.615206],
              [4.475235, 43.608296],
              [4.460681, 43.589261],
              [4.443312, 43.583145],
              [4.42554, 43.585225],
              [4.404417, 43.574366],
              [4.41532, 43.572538],
              [4.409353, 43.561127],
              [4.387087, 43.560477],
              [4.380677, 43.552542],
              [4.365136, 43.547157],
              [4.353644, 43.54739],
              [4.334133, 43.535451],
              [4.316431, 43.54433],
              [4.309523, 43.543279],
              [4.322709, 43.530447],
              [4.320492, 43.525482],
              [4.305132, 43.520795],
              [4.29769, 43.515329],
              [4.271417, 43.50807],
              [4.258642, 43.507539],
              [4.238103, 43.497625],
              [4.231171, 43.476759],
              [4.230281, 43.460184],
              [4.193863, 43.465779],
              [4.16562, 43.471915],
              [4.13767, 43.481949],
              [4.12314, 43.494201],
              [4.116589, 43.508711],
              [4.129875, 43.522766],
              [4.138258, 43.526105],
              [4.13912, 43.531898],
              [4.121628, 43.547075],
              [4.10104, 43.554371],
              [4.066349, 43.557386],
              [4.042252, 43.556807],
              [4.011184, 43.552447],
              [3.968572, 43.540018],
              [3.907138, 43.516846],
              [3.886401, 43.506871],
              [3.849623, 43.48536],
              [3.82882, 43.4692],
              [3.81853, 43.458313],
              [3.796669, 43.441039],
              [3.774568, 43.431421],
              [3.746282, 43.423624],
              [3.72492, 43.415793],
              [3.723035, 43.401095],
              [3.715556, 43.400688],
              [3.691903, 43.391883],
              [3.662162, 43.39228],
              [3.646079, 43.384774],
              [3.619675, 43.367836],
              [3.602623, 43.355428],
              [3.56612, 43.325718],
              [3.527301, 43.289811],
              [3.510959, 43.273329],
              [3.468335, 43.276598],
              [3.430332, 43.29021],
              [3.403483, 43.2879],
              [3.386905, 43.284136],
              [3.343858, 43.270394],
              [3.287114, 43.241993],
              [3.263451, 43.228865],
              [3.240562, 43.212804],
              [3.217871, 43.197502],
              [3.190198, 43.175846],
              [3.168348, 43.157328],
              [3.1408, 43.128769],
              [3.130924, 43.111758],
              [3.117227, 43.101836],
              [3.092882, 43.069146],
              [3.083849, 43.055303],
              [3.065587, 43.021675],
              [3.061508, 43.008829],
              [3.05251, 42.990159],
              [3.042844, 42.960156],
              [3.039317, 42.941683],
              [3.040467, 42.929701],
              [3.05255, 42.926207],
              [3.060589, 42.917063],
              [3.052131, 42.885348],
              [3.043505, 42.838148],
              [3.039383, 42.799198],
              [3.038315, 42.759309],
              [3.038817, 42.731854],
              [3.040634, 42.717464],
              [3.035424, 42.678252],
              [3.034845, 42.660161],
              [3.037421, 42.623835],
              [3.045187, 42.59458],
              [3.046196, 42.571621],
              [3.049602, 42.550137],
              [3.060253, 42.535571],
              [3.082058, 42.53156],
              [3.088412, 42.525558],
              [3.109134, 42.524893],
              [3.124505, 42.521009],
              [3.13358, 42.512938],
              [3.125267, 42.50238],
              [3.132957, 42.497276],
              [3.130192, 42.485333],
              [3.133114, 42.481257],
              [3.153008, 42.477773],
              [3.155635, 42.469619],
              [3.162445, 42.464978],
              [3.159895, 42.451899],
              [3.167388, 42.448419],
              [3.174789, 42.438691],
              [3.173219, 42.434878],
              [3.12546, 42.434687],
              [3.120765, 42.438009],
              [3.107277, 42.432658],
              [3.098303, 42.424929],
              [3.085441, 42.425513],
              [3.057324, 42.446987],
              [3.049039, 42.455546],
              [3.040758, 42.473142],
              [3.019801, 42.470625],
              [3.012573, 42.466522],
              [3.00092, 42.472989],
              [2.982239, 42.471614],
              [2.968992, 42.465794],
              [2.947028, 42.481797],
              [2.930214, 42.472079],
              [2.924485, 42.458391],
              [2.907095, 42.456951],
              [2.892009, 42.461789],
              [2.880945, 42.461173],
              [2.87279, 42.467059],
              [2.863518, 42.463685],
              [2.859677, 42.454573],
              [2.841416, 42.458522],
              [2.83317, 42.452805],
              [2.827103, 42.43924],
              [2.816295, 42.439738],
              [2.803149, 42.428224],
              [2.799243, 42.418571],
              [2.79095, 42.417858],
              [2.77697, 42.41195],
              [2.768537, 42.413431],
              [2.753199, 42.425361],
              [2.716672, 42.421224],
              [2.694549, 42.407888],
              [2.67504, 42.404971],
              [2.671415, 42.38753],
              [2.65564, 42.388431],
              [2.660742, 42.378154],
              [2.66111, 42.365962],
              [2.672719, 42.359466],
              [2.676075, 42.351639],
              [2.67177, 42.341177],
              [2.65594, 42.341429],
              [2.64953, 42.344118],
              [2.617806, 42.345509],
              [2.577826, 42.357932],
              [2.562717, 42.357068],
              [2.557338, 42.354086],
              [2.552172, 42.343319],
              [2.540038, 42.333755],
              [2.52576, 42.333647],
              [2.500306, 42.342908],
              [2.482957, 42.339647],
              [2.467535, 42.358924],
              [2.451468, 42.369204],
              [2.445404, 42.369569],
              [2.433467, 42.377095],
              [2.435485, 42.38888],
              [2.428821, 42.394336],
              [2.41679, 42.39236],
              [2.39549, 42.395122],
              [2.38997, 42.398051],
              [2.368835, 42.401166],
              [2.349177, 42.406745],
              [2.334118, 42.415652],
              [2.325309, 42.41741],
              [2.314134, 42.42785],
              [2.306959, 42.42879],
              [2.292385, 42.422977],
              [2.273858, 42.43269],
              [2.257804, 42.438354],
              [2.246673, 42.429497],
              [2.222233, 42.424593],
              [2.201057, 42.416291],
              [2.156338, 42.423424],
              [2.13135, 42.413484],
              [2.114897, 42.394507],
              [2.116769, 42.383218],
              [2.091668, 42.374167],
              [2.083593, 42.362697],
              [2.072351, 42.364577],
              [2.058002, 42.357888],
              [2.023495, 42.355223],
              [2.016923, 42.348686],
              [1.985908, 42.362045],
              [1.980585, 42.369406],
              [1.971695, 42.373783],
              [1.96206, 42.389876],
              [1.960776, 42.403677],
              [1.95586, 42.412476],
              [1.958281, 42.424048],
              [1.941725, 42.429661],
              [1.943021, 42.444112],
              [1.935306, 42.453582],
              [1.916639, 42.446309],
              [1.898268, 42.450059],
              [1.885448, 42.449295],
              [1.881427, 42.459711],
              [1.868372, 42.465059],
              [1.858545, 42.462681],
              [1.846994, 42.470705],
              [1.843361, 42.477147],
              [1.834624, 42.481784],
              [1.806421, 42.488452],
              [1.795003, 42.486845],
              [1.76326, 42.486844],
              [1.74616, 42.49532],
              [1.729889, 42.495327],
              [1.725829, 42.503605],
              [1.725647, 42.515034],
              [1.734375, 42.53565],
              [1.735946, 42.548521],
              [1.741115, 42.5571],
              [1.74988, 42.563588],
              [1.76996, 42.56482],
              [1.78613, 42.573623],
            ],
            [
              [1.95738, 42.461623],
              [1.9598, 42.45326],
              [1.981342, 42.447525],
              [2.000065, 42.446779],
              [2.012687, 42.448344],
              [2.010954, 42.454821],
              [1.996605, 42.461187],
              [1.987295, 42.469686],
              [1.98653, 42.475761],
              [1.996084, 42.484654],
              [1.994442, 42.48888],
              [1.97876, 42.49477],
              [1.976196, 42.485599],
              [1.961384, 42.472524],
              [1.95738, 42.461623],
            ],
          ],
          [
            [
              [-0.103071, 43.242819],
              [-0.122151, 43.243894],
              [-0.133465, 43.258459],
              [-0.140615, 43.271941],
              [-0.136506, 43.284505],
              [-0.126165, 43.293877],
              [-0.119979, 43.30625],
              [-0.111854, 43.310395],
              [-0.092031, 43.300469],
              [-0.096174, 43.285481],
              [-0.079284, 43.271656],
              [-0.079841, 43.262366],
              [-0.092112, 43.252066],
              [-0.099321, 43.252127],
              [-0.103071, 43.242819],
            ],
          ],
          [
            [
              [-0.102217, 43.358515],
              [-0.107283, 43.370735],
              [-0.090959, 43.373163],
              [-0.076423, 43.364562],
              [-0.065397, 43.355049],
              [-0.062503, 43.346712],
              [-0.070279, 43.317455],
              [-0.075041, 43.307136],
              [-0.104123, 43.312464],
              [-0.111395, 43.315809],
              [-0.115664, 43.330977],
              [-0.108479, 43.337802],
              [-0.096784, 43.334763],
              [-0.086787, 43.336872],
              [-0.090143, 43.358859],
              [-0.102217, 43.358515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5384",
      properties: {
        name: "Pays de la Loire",
        density: 0,
        path: "/world/France/Pays de la Loire",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.304788, 46.709424],
              [-2.28612, 46.695672],
              [-2.301825, 46.692466],
              [-2.314184, 46.695298],
              [-2.32201, 46.689371],
              [-2.333909, 46.686336],
              [-2.34084, 46.692271],
              [-2.362824, 46.694297],
              [-2.383825, 46.706769],
              [-2.398721, 46.717509],
              [-2.398761, 46.723952],
              [-2.382963, 46.732132],
              [-2.371518, 46.732424],
              [-2.344996, 46.726619],
              [-2.340309, 46.720187],
              [-2.319808, 46.719333],
              [-2.304788, 46.709424],
            ],
          ],
          [
            [
              [-0.891964, 46.97582],
              [-0.88901, 46.970996],
              [-0.877125, 46.968538],
              [-0.883595, 46.962369],
              [-0.877139, 46.955856],
              [-0.883797, 46.950389],
              [-0.872984, 46.944344],
              [-0.851946, 46.946506],
              [-0.840698, 46.933043],
              [-0.829149, 46.933362],
              [-0.822618, 46.919497],
              [-0.807393, 46.919825],
              [-0.819774, 46.909085],
              [-0.820204, 46.89962],
              [-0.832186, 46.884537],
              [-0.815275, 46.879362],
              [-0.808376, 46.869152],
              [-0.794776, 46.861062],
              [-0.781646, 46.842828],
              [-0.758157, 46.8314],
              [-0.744317, 46.830243],
              [-0.727527, 46.821938],
              [-0.708838, 46.821064],
              [-0.700858, 46.808626],
              [-0.717838, 46.800521],
              [-0.727312, 46.767631],
              [-0.720799, 46.762538],
              [-0.715343, 46.75179],
              [-0.70418, 46.749454],
              [-0.694133, 46.742731],
              [-0.700154, 46.735796],
              [-0.683976, 46.727864],
              [-0.668884, 46.717232],
              [-0.656193, 46.700775],
              [-0.680733, 46.686804],
              [-0.657873, 46.676827],
              [-0.637715, 46.663489],
              [-0.649248, 46.653284],
              [-0.657172, 46.634619],
              [-0.64406, 46.638024],
              [-0.626199, 46.633078],
              [-0.614114, 46.620391],
              [-0.627027, 46.605651],
              [-0.61671, 46.598537],
              [-0.611908, 46.588307],
              [-0.624567, 46.5774],
              [-0.617854, 46.562043],
              [-0.606709, 46.562332],
              [-0.602132, 46.53328],
              [-0.610025, 46.527291],
              [-0.633529, 46.526546],
              [-0.645194, 46.508571],
              [-0.636582, 46.506095],
              [-0.624838, 46.496363],
              [-0.624182, 46.486747],
              [-0.612427, 46.458737],
              [-0.619837, 46.452467],
              [-0.618771, 46.438855],
              [-0.63684, 46.432306],
              [-0.640647, 46.416225],
              [-0.63283, 46.403813],
              [-0.636587, 46.395578],
              [-0.620888, 46.390451],
              [-0.61018, 46.413733],
              [-0.594173, 46.410186],
              [-0.581898, 46.402174],
              [-0.572384, 46.400707],
              [-0.566055, 46.393082],
              [-0.55052, 46.393343],
              [-0.537795, 46.386464],
              [-0.557647, 46.363451],
              [-0.575677, 46.356508],
              [-0.603252, 46.361468],
              [-0.605471, 46.347208],
              [-0.618682, 46.339099],
              [-0.636604, 46.337595],
              [-0.639982, 46.322111],
              [-0.648253, 46.317144],
              [-0.672274, 46.316216],
              [-0.697327, 46.325093],
              [-0.707572, 46.317705],
              [-0.720149, 46.314891],
              [-0.721914, 46.302373],
              [-0.734595, 46.304956],
              [-0.750471, 46.304254],
              [-0.757949, 46.311254],
              [-0.775703, 46.318376],
              [-0.784243, 46.318862],
              [-0.802476, 46.325157],
              [-0.807321, 46.339294],
              [-0.824152, 46.335855],
              [-0.830528, 46.34153],
              [-0.839885, 46.340367],
              [-0.848655, 46.332513],
              [-0.844876, 46.32456],
              [-0.850942, 46.317186],
              [-0.887676, 46.326323],
              [-0.892701, 46.320101],
              [-0.904803, 46.313812],
              [-0.934894, 46.312858],
              [-0.961231, 46.323396],
              [-0.944155, 46.336048],
              [-0.937905, 46.353157],
              [-0.928576, 46.371051],
              [-0.94112, 46.367862],
              [-0.95084, 46.360618],
              [-0.964518, 46.365398],
              [-0.977445, 46.351109],
              [-0.995212, 46.350318],
              [-1.01381, 46.355623],
              [-1.029064, 46.348868],
              [-1.052533, 46.342541],
              [-1.064512, 46.335517],
              [-1.080724, 46.321431],
              [-1.078295, 46.316912],
              [-1.099736, 46.314472],
              [-1.113284, 46.316302],
              [-1.123642, 46.321796],
              [-1.129406, 46.310272],
              [-1.139604, 46.31122],
              [-1.163387, 46.324358],
              [-1.178982, 46.319237],
              [-1.201086, 46.316343],
              [-1.202948, 46.312998],
              [-1.195507, 46.300486],
              [-1.206379, 46.288845],
              [-1.207398, 46.266567],
              [-1.233814, 46.279787],
              [-1.244708, 46.287909],
              [-1.285084, 46.310944],
              [-1.295895, 46.323927],
              [-1.305978, 46.319191],
              [-1.295049, 46.304456],
              [-1.29865, 46.298035],
              [-1.308884, 46.303381],
              [-1.320921, 46.321997],
              [-1.320977, 46.325733],
              [-1.345675, 46.342002],
              [-1.366739, 46.348612],
              [-1.38171, 46.341186],
              [-1.400976, 46.340508],
              [-1.416443, 46.346032],
              [-1.42842, 46.347121],
              [-1.436973, 46.34105],
              [-1.465937, 46.342356],
              [-1.470943, 46.346062],
              [-1.477175, 46.363507],
              [-1.487802, 46.38139],
              [-1.50214, 46.397345],
              [-1.515067, 46.404294],
              [-1.537751, 46.409242],
              [-1.550501, 46.405428],
              [-1.584934, 46.408882],
              [-1.611309, 46.413418],
              [-1.627062, 46.414221],
              [-1.641722, 46.419203],
              [-1.656384, 46.431211],
              [-1.674603, 46.439924],
              [-1.703868, 46.448675],
              [-1.71912, 46.459168],
              [-1.752124, 46.474156],
              [-1.777138, 46.492759],
              [-1.791293, 46.494498],
              [-1.803395, 46.488967],
              [-1.812344, 46.493417],
              [-1.81865, 46.518683],
              [-1.841959, 46.573014],
              [-1.848442, 46.585885],
              [-1.856015, 46.608581],
              [-1.873382, 46.620915],
              [-1.882287, 46.630595],
              [-1.890673, 46.634691],
              [-1.903567, 46.647322],
              [-1.912634, 46.661558],
              [-1.942876, 46.692706],
              [-1.950836, 46.695651],
              [-1.966129, 46.692012],
              [-1.978445, 46.703097],
              [-1.982665, 46.720311],
              [-2.000266, 46.733925],
              [-2.071949, 46.782813],
              [-2.117198, 46.803361],
              [-2.140193, 46.817152],
              [-2.144506, 46.826399],
              [-2.145433, 46.84781],
              [-2.148918, 46.869931],
              [-2.155343, 46.883152],
              [-2.153824, 46.890149],
              [-2.118888, 46.893677],
              [-2.1178, 46.908317],
              [-2.103439, 46.920985],
              [-2.062735, 46.948064],
              [-2.044781, 46.969115],
              [-2.045362, 46.979481],
              [-2.027362, 47.009921],
              [-1.997515, 47.018755],
              [-1.980413, 47.028905],
              [-1.983624, 47.029505],
              [-1.999415, 47.055759],
              [-2.004721, 47.061461],
              [-2.032602, 47.073513],
              [-2.048556, 47.086376],
              [-2.053477, 47.094117],
              [-2.104856, 47.108516],
              [-2.155586, 47.112834],
              [-2.17768, 47.122062],
              [-2.215014, 47.12406],
              [-2.22682, 47.130936],
              [-2.247815, 47.134022],
              [-2.229532, 47.144165],
              [-2.226128, 47.152275],
              [-2.200956, 47.158406],
              [-2.180205, 47.155934],
              [-2.167063, 47.166181],
              [-2.158213, 47.196893],
              [-2.158374, 47.209051],
              [-2.173861, 47.226512],
              [-2.177861, 47.236097],
              [-2.170546, 47.239752],
              [-2.16999, 47.268472],
              [-2.187361, 47.280622],
              [-2.20556, 47.271131],
              [-2.224644, 47.264387],
              [-2.22786, 47.25633],
              [-2.246691, 47.255988],
              [-2.269895, 47.239565],
              [-2.30164, 47.2364],
              [-2.339602, 47.255188],
              [-2.342096, 47.261756],
              [-2.35529, 47.271809],
              [-2.369753, 47.277438],
              [-2.39838, 47.281449],
              [-2.420381, 47.276444],
              [-2.425388, 47.270965],
              [-2.416553, 47.258713],
              [-2.447535, 47.261758],
              [-2.455521, 47.26814],
              [-2.482315, 47.27298],
              [-2.499097, 47.280742],
              [-2.544252, 47.290108],
              [-2.54497, 47.297964],
              [-2.527213, 47.301552],
              [-2.513808, 47.298376],
              [-2.50499, 47.313671],
              [-2.502914, 47.328755],
              [-2.506778, 47.34139],
              [-2.521159, 47.358811],
              [-2.531175, 47.365375],
              [-2.541347, 47.366006],
              [-2.558945, 47.374566],
              [-2.545549, 47.381126],
              [-2.534106, 47.382961],
              [-2.522412, 47.39224],
              [-2.500025, 47.404399],
              [-2.491235, 47.404811],
              [-2.482683, 47.412264],
              [-2.472672, 47.416083],
              [-2.458309, 47.412128],
              [-2.434712, 47.41324],
              [-2.433036, 47.416778],
              [-2.450865, 47.425324],
              [-2.452391, 47.433822],
              [-2.448248, 47.441292],
              [-2.458493, 47.448123],
              [-2.453437, 47.462075],
              [-2.440082, 47.465781],
              [-2.423023, 47.477116],
              [-2.416826, 47.462052],
              [-2.399943, 47.455985],
              [-2.39039, 47.456872],
              [-2.382296, 47.462248],
              [-2.370969, 47.463384],
              [-2.354044, 47.45452],
              [-2.346365, 47.45785],
              [-2.32394, 47.4597],
              [-2.312864, 47.464471],
              [-2.313141, 47.485905],
              [-2.303671, 47.492411],
              [-2.299123, 47.500478],
              [-2.296514, 47.516059],
              [-2.280834, 47.509587],
              [-2.262969, 47.512911],
              [-2.25852, 47.504528],
              [-2.265638, 47.50178],
              [-2.244283, 47.493603],
              [-2.21997, 47.50542],
              [-2.206668, 47.510041],
              [-2.184623, 47.511939],
              [-2.183761, 47.501792],
              [-2.192164, 47.496644],
              [-2.183302, 47.491697],
              [-2.163676, 47.490501],
              [-2.154186, 47.496365],
              [-2.152957, 47.510963],
              [-2.156018, 47.522028],
              [-2.107329, 47.531054],
              [-2.098559, 47.533957],
              [-2.09683, 47.54033],
              [-2.103887, 47.54997],
              [-2.096507, 47.572369],
              [-2.103723, 47.589435],
              [-2.099269, 47.597488],
              [-2.086924, 47.602779],
              [-2.084995, 47.621229],
              [-2.097034, 47.631356],
              [-2.089884, 47.64271],
              [-2.074733, 47.651663],
              [-2.05791, 47.649484],
              [-2.050625, 47.651134],
              [-2.043095, 47.665697],
              [-2.035728, 47.668541],
              [-2.013316, 47.666003],
              [-2.009356, 47.671376],
              [-1.985223, 47.683233],
              [-1.974381, 47.693937],
              [-1.969059, 47.688369],
              [-1.969196, 47.677526],
              [-1.953816, 47.672268],
              [-1.936304, 47.686649],
              [-1.891836, 47.696328],
              [-1.880073, 47.695309],
              [-1.864016, 47.706981],
              [-1.841547, 47.705494],
              [-1.825053, 47.708269],
              [-1.80286, 47.702303],
              [-1.772418, 47.698454],
              [-1.754669, 47.706172],
              [-1.735492, 47.704031],
              [-1.72924, 47.69907],
              [-1.713383, 47.699308],
              [-1.705141, 47.709322],
              [-1.686342, 47.713034],
              [-1.664116, 47.711145],
              [-1.654817, 47.712589],
              [-1.645476, 47.721464],
              [-1.638181, 47.722311],
              [-1.639068, 47.73123],
              [-1.635568, 47.742658],
              [-1.626081, 47.756572],
              [-1.616354, 47.764155],
              [-1.598115, 47.766615],
              [-1.593405, 47.776049],
              [-1.551909, 47.784015],
              [-1.528061, 47.785843],
              [-1.520276, 47.79362],
              [-1.504239, 47.800948],
              [-1.492897, 47.79844],
              [-1.468444, 47.805903],
              [-1.466918, 47.80978],
              [-1.481813, 47.831894],
              [-1.462897, 47.833558],
              [-1.435427, 47.831152],
              [-1.424917, 47.832841],
              [-1.41785, 47.827487],
              [-1.390429, 47.828276],
              [-1.381345, 47.822669],
              [-1.377293, 47.812713],
              [-1.363166, 47.801684],
              [-1.352865, 47.797689],
              [-1.318487, 47.792334],
              [-1.245885, 47.776717],
              [-1.238248, 47.809993],
              [-1.232724, 47.820245],
              [-1.220636, 47.82039],
              [-1.213954, 47.844315],
              [-1.222737, 47.8526],
              [-1.216492, 47.857201],
              [-1.203023, 47.856844],
              [-1.189187, 47.867977],
              [-1.196966, 47.878939],
              [-1.196631, 47.889268],
              [-1.176224, 47.8974],
              [-1.175481, 47.910386],
              [-1.166314, 47.923578],
              [-1.16712, 47.934716],
              [-1.159515, 47.93922],
              [-1.161381, 47.95231],
              [-1.15399, 47.965817],
              [-1.134383, 47.969309],
              [-1.126075, 47.973308],
              [-1.122802, 47.986671],
              [-1.102678, 47.989064],
              [-1.09081, 47.987743],
              [-1.070966, 47.981801],
              [-1.045096, 47.987097],
              [-1.02126, 47.994939],
              [-1.016889, 48.003728],
              [-1.018208, 48.012308],
              [-1.033063, 48.031189],
              [-1.027797, 48.044864],
              [-1.033831, 48.052093],
              [-1.02329, 48.068912],
              [-1.040604, 48.07818],
              [-1.049611, 48.089801],
              [-1.052756, 48.107352],
              [-1.059142, 48.125078],
              [-1.060288, 48.150111],
              [-1.073888, 48.159662],
              [-1.079606, 48.183481],
              [-1.074774, 48.198201],
              [-1.087311, 48.209807],
              [-1.080604, 48.219498],
              [-1.086599, 48.22753],
              [-1.100055, 48.259278],
              [-1.093041, 48.281856],
              [-1.082092, 48.298392],
              [-1.059214, 48.312094],
              [-1.04502, 48.327729],
              [-1.055818, 48.340683],
              [-1.059892, 48.350533],
              [-1.059003, 48.358994],
              [-1.064629, 48.368281],
              [-1.053937, 48.383987],
              [-1.068121, 48.404717],
              [-1.078368, 48.413231],
              [-1.07792, 48.421477],
              [-1.082793, 48.433057],
              [-1.079364, 48.443272],
              [-1.065433, 48.451696],
              [-1.063965, 48.466954],
              [-1.074106, 48.473897],
              [-1.078296, 48.481155],
              [-1.075717, 48.499393],
              [-1.070164, 48.508492],
              [-1.06055, 48.515346],
              [-1.051445, 48.509309],
              [-1.003996, 48.489172],
              [-0.972258, 48.4946],
              [-0.962354, 48.503667],
              [-0.964254, 48.510812],
              [-0.956374, 48.51662],
              [-0.933711, 48.515027],
              [-0.92236, 48.512389],
              [-0.918471, 48.500394],
              [-0.896249, 48.495084],
              [-0.877464, 48.49962],
              [-0.86036, 48.501459],
              [-0.846107, 48.498284],
              [-0.837789, 48.485179],
              [-0.82729, 48.476292],
              [-0.818459, 48.474292],
              [-0.813224, 48.455083],
              [-0.799184, 48.458939],
              [-0.797563, 48.46528],
              [-0.777873, 48.465414],
              [-0.778586, 48.453255],
              [-0.774539, 48.443279],
              [-0.757277, 48.436552],
              [-0.735278, 48.445049],
              [-0.715099, 48.44895],
              [-0.71977, 48.454579],
              [-0.735858, 48.461125],
              [-0.730342, 48.472703],
              [-0.711217, 48.470742],
              [-0.702069, 48.467208],
              [-0.687992, 48.469431],
              [-0.685853, 48.475468],
              [-0.668957, 48.486138],
              [-0.663718, 48.484472],
              [-0.653631, 48.459546],
              [-0.654, 48.444278],
              [-0.61757, 48.45896],
              [-0.595337, 48.47263],
              [-0.57152, 48.469153],
              [-0.551718, 48.47312],
              [-0.545103, 48.482691],
              [-0.530442, 48.495165],
              [-0.505062, 48.505799],
              [-0.488495, 48.501618],
              [-0.478205, 48.501566],
              [-0.470603, 48.509717],
              [-0.462263, 48.512709],
              [-0.430757, 48.511816],
              [-0.424972, 48.507283],
              [-0.412734, 48.506498],
              [-0.399186, 48.510159],
              [-0.393457, 48.501835],
              [-0.367623, 48.492944],
              [-0.36724, 48.487749],
              [-0.353496, 48.483897],
              [-0.355821, 48.495674],
              [-0.343378, 48.50085],
              [-0.320233, 48.522924],
              [-0.302809, 48.517341],
              [-0.27823, 48.506986],
              [-0.271554, 48.507448],
              [-0.265801, 48.522782],
              [-0.253955, 48.525986],
              [-0.241768, 48.536389],
              [-0.246357, 48.542621],
              [-0.261804, 48.547895],
              [-0.24264, 48.567994],
              [-0.234352, 48.562336],
              [-0.221074, 48.560317],
              [-0.206942, 48.562946],
              [-0.193986, 48.554824],
              [-0.189959, 48.548884],
              [-0.169379, 48.536973],
              [-0.144603, 48.527754],
              [-0.145012, 48.521],
              [-0.155684, 48.520497],
              [-0.16634, 48.515584],
              [-0.172091, 48.502135],
              [-0.15856, 48.496817],
              [-0.149589, 48.479782],
              [-0.153366, 48.476725],
              [-0.148718, 48.458069],
              [-0.124542, 48.44924],
              [-0.106412, 48.44752],
              [-0.073007, 48.450527],
              [-0.072708, 48.456928],
              [-0.051891, 48.453255],
              [-0.04991, 48.447628],
              [-0.057356, 48.428503],
              [-0.053013, 48.412716],
              [-0.05669, 48.398916],
              [-0.052691, 48.392979],
              [-0.054527, 48.382004],
              [-0.050693, 48.375201],
              [-0.035753, 48.384875],
              [-0.022055, 48.38806],
              [-0.020364, 48.393656],
              [-0.002564, 48.397312],
              [0.006586, 48.388521],
              [0.020993, 48.380201],
              [0.06249, 48.382214],
              [0.056685, 48.393974],
              [0.067827, 48.406115],
              [0.08358, 48.411138],
              [0.09917, 48.41035],
              [0.116248, 48.435556],
              [0.151319, 48.437227],
              [0.158118, 48.444016],
              [0.156101, 48.454795],
              [0.169657, 48.449364],
              [0.169724, 48.461777],
              [0.181255, 48.464965],
              [0.189813, 48.461891],
              [0.218232, 48.473791],
              [0.229393, 48.472578],
              [0.258578, 48.47671],
              [0.262862, 48.482955],
              [0.275934, 48.479055],
              [0.295856, 48.480175],
              [0.317897, 48.471938],
              [0.327276, 48.471072],
              [0.338741, 48.4616],
              [0.355785, 48.458217],
              [0.363956, 48.451632],
              [0.367718, 48.438273],
              [0.38066, 48.425412],
              [0.381508, 48.417548],
              [0.371724, 48.410452],
              [0.375372, 48.39574],
              [0.373861, 48.38697],
              [0.378659, 48.383228],
              [0.382552, 48.359499],
              [0.388285, 48.349122],
              [0.380478, 48.341798],
              [0.38261, 48.333828],
              [0.395403, 48.32055],
              [0.40622, 48.314621],
              [0.415996, 48.321625],
              [0.426889, 48.315425],
              [0.431334, 48.306639],
              [0.442799, 48.304629],
              [0.463612, 48.305016],
              [0.480501, 48.298592],
              [0.487577, 48.307796],
              [0.50703, 48.295833],
              [0.494556, 48.286816],
              [0.51293, 48.266874],
              [0.5303, 48.265497],
              [0.538486, 48.256988],
              [0.53597, 48.249845],
              [0.550138, 48.249396],
              [0.560994, 48.245949],
              [0.579191, 48.244364],
              [0.63316, 48.245554],
              [0.631904, 48.254755],
              [0.640705, 48.261222],
              [0.653151, 48.263703],
              [0.675471, 48.254741],
              [0.68322, 48.248588],
              [0.716576, 48.212095],
              [0.72363, 48.19814],
              [0.73015, 48.200522],
              [0.73783, 48.18907],
              [0.755663, 48.181982],
              [0.764079, 48.1816],
              [0.797658, 48.194455],
              [0.795627, 48.188043],
              [0.808359, 48.186119],
              [0.82689, 48.175387],
              [0.836835, 48.167352],
              [0.861989, 48.166817],
              [0.882496, 48.161766],
              [0.911612, 48.148859],
              [0.913798, 48.135125],
              [0.89397, 48.135535],
              [0.872936, 48.133409],
              [0.852575, 48.133602],
              [0.85537, 48.122621],
              [0.841217, 48.10306],
              [0.832688, 48.098454],
              [0.814351, 48.098802],
              [0.815168, 48.093731],
              [0.839203, 48.091252],
              [0.844838, 48.086647],
              [0.84302, 48.072638],
              [0.834603, 48.070148],
              [0.801183, 48.071514],
              [0.796533, 48.052678],
              [0.797478, 48.037557],
              [0.80877, 48.031994],
              [0.825203, 48.03006],
              [0.836723, 48.034559],
              [0.841555, 48.029674],
              [0.840527, 48.021049],
              [0.831702, 48.006117],
              [0.832375, 47.996592],
              [0.826223, 47.991476],
              [0.824281, 47.982142],
              [0.845292, 47.954439],
              [0.845794, 47.941403],
              [0.837277, 47.937247],
              [0.817146, 47.934468],
              [0.812118, 47.928939],
              [0.809134, 47.910662],
              [0.817218, 47.892419],
              [0.810187, 47.890394],
              [0.797991, 47.898194],
              [0.790024, 47.91221],
              [0.780512, 47.910375],
              [0.770325, 47.902009],
              [0.759869, 47.898225],
              [0.757338, 47.884474],
              [0.764689, 47.866583],
              [0.759161, 47.859222],
              [0.774019, 47.851208],
              [0.774574, 47.839684],
              [0.768414, 47.831101],
              [0.758857, 47.833536],
              [0.7454, 47.825663],
              [0.739721, 47.814679],
              [0.724844, 47.798889],
              [0.712632, 47.790039],
              [0.697985, 47.788889],
              [0.689318, 47.779997],
              [0.703442, 47.76994],
              [0.69688, 47.764225],
              [0.67565, 47.768962],
              [0.639379, 47.751572],
              [0.626833, 47.751793],
              [0.61067, 47.732034],
              [0.611597, 47.728134],
              [0.594095, 47.723106],
              [0.58052, 47.712331],
              [0.59297, 47.703591],
              [0.595571, 47.688313],
              [0.604187, 47.685607],
              [0.614432, 47.694215],
              [0.614804, 47.682751],
              [0.604637, 47.679968],
              [0.587727, 47.669617],
              [0.559479, 47.665995],
              [0.542897, 47.656204],
              [0.513252, 47.652864],
              [0.499667, 47.645272],
              [0.479767, 47.643292],
              [0.476076, 47.648012],
              [0.456628, 47.638826],
              [0.455182, 47.627017],
              [0.449933, 47.61933],
              [0.423905, 47.617824],
              [0.397023, 47.638927],
              [0.381075, 47.639065],
              [0.364654, 47.626011],
              [0.364804, 47.620165],
              [0.379056, 47.61078],
              [0.394423, 47.594393],
              [0.402166, 47.579002],
              [0.378954, 47.569105],
              [0.366736, 47.573458],
              [0.339586, 47.579472],
              [0.338446, 47.58503],
              [0.323255, 47.592888],
              [0.290017, 47.597729],
              [0.277992, 47.597381],
              [0.267842, 47.608672],
              [0.259253, 47.612254],
              [0.237683, 47.610966],
              [0.23, 47.608397],
              [0.23453, 47.577977],
              [0.215103, 47.569976],
              [0.201502, 47.544324],
              [0.193346, 47.539118],
              [0.203491, 47.533331],
              [0.20807, 47.526432],
              [0.224915, 47.5271],
              [0.220089, 47.51149],
              [0.220107, 47.501952],
              [0.200718, 47.484545],
              [0.189798, 47.460723],
              [0.180938, 47.453405],
              [0.185279, 47.424736],
              [0.181382, 47.417825],
              [0.153858, 47.398727],
              [0.16949, 47.395646],
              [0.167988, 47.386934],
              [0.182962, 47.38033],
              [0.158458, 47.366157],
              [0.141654, 47.361961],
              [0.147969, 47.348448],
              [0.138988, 47.338243],
              [0.131254, 47.334092],
              [0.117457, 47.332343],
              [0.117869, 47.325601],
              [0.109245, 47.313422],
              [0.099013, 47.30814],
              [0.078979, 47.282822],
              [0.082849, 47.274169],
              [0.074829, 47.248048],
              [0.072493, 47.22051],
              [0.053278, 47.197182],
              [0.066597, 47.189796],
              [0.063053, 47.175282],
              [0.05383, 47.163734],
              [0.04948, 47.168623],
              [0.036502, 47.160445],
              [0.019016, 47.175754],
              [-0.010739, 47.157512],
              [-0.034012, 47.127335],
              [-0.040856, 47.112929],
              [-0.03929, 47.108056],
              [-0.026535, 47.105798],
              [-0.029235, 47.095258],
              [-0.035624, 47.086261],
              [-0.044169, 47.09324],
              [-0.060662, 47.095148],
              [-0.085909, 47.10101],
              [-0.098719, 47.090118],
              [-0.101504, 47.083264],
              [-0.102116, 47.0648],
              [-0.128379, 47.054429],
              [-0.137121, 47.058427],
              [-0.136783, 47.063924],
              [-0.147661, 47.069855],
              [-0.165991, 47.064597],
              [-0.178483, 47.06977],
              [-0.159474, 47.085935],
              [-0.14556, 47.091367],
              [-0.141255, 47.103745],
              [-0.157212, 47.10178],
              [-0.184838, 47.108333],
              [-0.186497, 47.101547],
              [-0.206075, 47.093285],
              [-0.241533, 47.105728],
              [-0.255375, 47.100286],
              [-0.287925, 47.101438],
              [-0.298957, 47.09925],
              [-0.314251, 47.091338],
              [-0.341465, 47.087333],
              [-0.344981, 47.091771],
              [-0.357419, 47.094026],
              [-0.383459, 47.087698],
              [-0.396311, 47.087753],
              [-0.400782, 47.070768],
              [-0.409315, 47.066289],
              [-0.425851, 47.072734],
              [-0.446138, 47.067565],
              [-0.464253, 47.067574],
              [-0.476341, 47.054362],
              [-0.485538, 47.065209],
              [-0.47635, 47.07214],
              [-0.464044, 47.074916],
              [-0.462696, 47.081926],
              [-0.495336, 47.082386],
              [-0.542712, 47.068832],
              [-0.559532, 47.061883],
              [-0.555184, 47.056996],
              [-0.555578, 47.043529],
              [-0.542222, 47.035132],
              [-0.545659, 47.029239],
              [-0.56226, 47.030666],
              [-0.56547, 47.019423],
              [-0.57653, 47.017027],
              [-0.586412, 47.00998],
              [-0.595496, 46.997955],
              [-0.619979, 46.993321],
              [-0.629714, 46.996851],
              [-0.644225, 46.995603],
              [-0.676051, 47.000124],
              [-0.680188, 46.987659],
              [-0.696372, 46.994705],
              [-0.713054, 46.986071],
              [-0.727903, 46.994993],
              [-0.743363, 47.000702],
              [-0.747602, 46.99145],
              [-0.761951, 46.992144],
              [-0.773878, 47.004248],
              [-0.787573, 47.005135],
              [-0.800441, 46.994429],
              [-0.82697, 46.992404],
              [-0.838536, 46.985504],
              [-0.855917, 46.97908],
              [-0.849156, 46.973776],
              [-0.857643, 46.969398],
              [-0.879729, 46.975804],
              [-0.891964, 46.97582],
            ],
          ],
          [
            [
              [-2.198072, 46.951171],
              [-2.223153, 46.965271],
              [-2.242445, 46.965359],
              [-2.254398, 46.958123],
              [-2.264375, 46.960877],
              [-2.286789, 46.983497],
              [-2.301095, 46.989048],
              [-2.302032, 46.997617],
              [-2.292519, 47.012555],
              [-2.304205, 47.025061],
              [-2.287233, 47.024618],
              [-2.277069, 47.029038],
              [-2.252069, 47.027966],
              [-2.240136, 47.017854],
              [-2.225623, 47.016855],
              [-2.218334, 47.007817],
              [-2.218812, 46.991713],
              [-2.231746, 46.989711],
              [-2.237473, 46.981958],
              [-2.230739, 46.973216],
              [-2.220245, 46.973677],
              [-2.157181, 46.950033],
              [-2.147438, 46.934194],
              [-2.148214, 46.913529],
              [-2.153252, 46.906933],
              [-2.153899, 46.895779],
              [-2.167939, 46.911178],
              [-2.173528, 46.925286],
              [-2.181421, 46.93705],
              [-2.198072, 46.951171],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "5394",
      properties: {
        name: "Provence-Alpes-Côte d'Azur",
        density: 0,
        path: "/world/France/Provence-Alpes-Côte d'Azur",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [6.948333, 44.654818],
              [6.954513, 44.636225],
              [6.96682, 44.625347],
              [6.950767, 44.619842],
              [6.944934, 44.60579],
              [6.938398, 44.603748],
              [6.932738, 44.592706],
              [6.933509, 44.575953],
              [6.921945, 44.570244],
              [6.913207, 44.558829],
              [6.87603, 44.550156],
              [6.869839, 44.536837],
              [6.858494, 44.534359],
              [6.854014, 44.529125],
              [6.861228, 44.503421],
              [6.881964, 44.477256],
              [6.906338, 44.466844],
              [6.911096, 44.452368],
              [6.937133, 44.438869],
              [6.944558, 44.432058],
              [6.923971, 44.422878],
              [6.917967, 44.427399],
              [6.902883, 44.420453],
              [6.892868, 44.42078],
              [6.895619, 44.408504],
              [6.894364, 44.397964],
              [6.896505, 44.374301],
              [6.887435, 44.361051],
              [6.903941, 44.358364],
              [6.922409, 44.34826],
              [6.926475, 44.334749],
              [6.952952, 44.317556],
              [6.960638, 44.308314],
              [6.956527, 44.298638],
              [6.974685, 44.284947],
              [6.981827, 44.28641],
              [6.993447, 44.28089],
              [6.996106, 44.274899],
              [6.997135, 44.25197],
              [7.008059, 44.236435],
              [7.023607, 44.234003],
              [7.030953, 44.227886],
              [7.042354, 44.225342],
              [7.071039, 44.232947],
              [7.08751, 44.228292],
              [7.095603, 44.220998],
              [7.112229, 44.217331],
              [7.141051, 44.200999],
              [7.159741, 44.206207],
              [7.188913, 44.197801],
              [7.193615, 44.187097],
              [7.207726, 44.17936],
              [7.218864, 44.168944],
              [7.233071, 44.171847],
              [7.24336, 44.168249],
              [7.248571, 44.158511],
              [7.262787, 44.148032],
              [7.281966, 44.143449],
              [7.309439, 44.145824],
              [7.319723, 44.142985],
              [7.3442, 44.14516],
              [7.348652, 44.132703],
              [7.356038, 44.120863],
              [7.367865, 44.116394],
              [7.386271, 44.123786],
              [7.402624, 44.120706],
              [7.426953, 44.112875],
              [7.429923, 44.1278],
              [7.435355, 44.129363],
              [7.457603, 44.126387],
              [7.487715, 44.135762],
              [7.50574, 44.143649],
              [7.520183, 44.137674],
              [7.53729, 44.14596],
              [7.556791, 44.146921],
              [7.573069, 44.152945],
              [7.61456, 44.149672],
              [7.62727, 44.15869],
              [7.630055, 44.170244],
              [7.63662, 44.177107],
              [7.64528, 44.177975],
              [7.684589, 44.174017],
              [7.68095, 44.164265],
              [7.670853, 44.153737],
              [7.678536, 44.14589],
              [7.666595, 44.130757],
              [7.674523, 44.117978],
              [7.687963, 44.110091],
              [7.69661, 44.097734],
              [7.711954, 44.088264],
              [7.716938, 44.081763],
              [7.714073, 44.065431],
              [7.704023, 44.052509],
              [7.700027, 44.040792],
              [7.689809, 44.039471],
              [7.678282, 44.033292],
              [7.664982, 44.030664],
              [7.661835, 44.017914],
              [7.670205, 43.998468],
              [7.648598, 43.97411],
              [7.640102, 43.971241],
              [7.615549, 43.957789],
              [7.598065, 43.957024],
              [7.583683, 43.952448],
              [7.56711, 43.943674],
              [7.570722, 43.937344],
              [7.568245, 43.923261],
              [7.559929, 43.914445],
              [7.562813, 43.901908],
              [7.559224, 43.897297],
              [7.536079, 43.892183],
              [7.510515, 43.881553],
              [7.501212, 43.874829],
              [7.495441, 43.864356],
              [7.498608, 43.846178],
              [7.507372, 43.84168],
              [7.510252, 43.8282],
              [7.518723, 43.802508],
              [7.528519, 43.790518],
              [7.524726, 43.78488],
              [7.511795, 43.781115],
              [7.511498, 43.776215],
              [7.490211, 43.767195],
              [7.475913, 43.750794],
              [7.459736, 43.76004],
              [7.446623, 43.751857],
              [7.437226, 43.751597],
              [7.412268, 43.734387],
              [7.415635, 43.725914],
              [7.406457, 43.719629],
              [7.39794, 43.718906],
              [7.383271, 43.722581],
              [7.369353, 43.719373],
              [7.358518, 43.721877],
              [7.351188, 43.71521],
              [7.336106, 43.710332],
              [7.329614, 43.70191],
              [7.333642, 43.693576],
              [7.335347, 43.678214],
              [7.326364, 43.674107],
              [7.320289, 43.691329],
              [7.325341, 43.700156],
              [7.314716, 43.706536],
              [7.306845, 43.697864],
              [7.308768, 43.691876],
              [7.297203, 43.686139],
              [7.285174, 43.694321],
              [7.262429, 43.694257],
              [7.241886, 43.688481],
              [7.230632, 43.678553],
              [7.225883, 43.662251],
              [7.206452, 43.645639],
              [7.19454, 43.657918],
              [7.179225, 43.655921],
              [7.167666, 43.657402],
              [7.151054, 43.649273],
              [7.137198, 43.634491],
              [7.126473, 43.605278],
              [7.125811, 43.594574],
              [7.129689, 43.582642],
              [7.125547, 43.575784],
              [7.139239, 43.564445],
              [7.134786, 43.555745],
              [7.144172, 43.55295],
              [7.138503, 43.545621],
              [7.119194, 43.544452],
              [7.121625, 43.554189],
              [7.114583, 43.565834],
              [7.101267, 43.570682],
              [7.084307, 43.569546],
              [7.067565, 43.561637],
              [7.055947, 43.547965],
              [7.040444, 43.541583],
              [7.026614, 43.548604],
              [7.014829, 43.550853],
              [6.987249, 43.548425],
              [6.971833, 43.545451],
              [6.952164, 43.534716],
              [6.938364, 43.516391],
              [6.938394, 43.508638],
              [6.954751, 43.504725],
              [6.948989, 43.493481],
              [6.94884, 43.485497],
              [6.932693, 43.484349],
              [6.933721, 43.480065],
              [6.923867, 43.470961],
              [6.92436, 43.464066],
              [6.917972, 43.447739],
              [6.905135, 43.445353],
              [6.893895, 43.436171],
              [6.891804, 43.42858],
              [6.874535, 43.426538],
              [6.865239, 43.433931],
              [6.857, 43.430478],
              [6.859473, 43.41816],
              [6.855048, 43.411429],
              [6.842509, 43.416314],
              [6.826761, 43.417372],
              [6.798854, 43.41372],
              [6.789695, 43.408267],
              [6.763412, 43.424489],
              [6.750658, 43.420845],
              [6.739809, 43.412882],
              [6.729509, 43.397246],
              [6.726313, 43.383104],
              [6.714331, 43.367903],
              [6.716546, 43.347491],
              [6.70996, 43.344598],
              [6.681619, 43.34096],
              [6.668991, 43.332928],
              [6.665956, 43.31822],
              [6.650447, 43.309304],
              [6.628623, 43.304543],
              [6.621297, 43.296034],
              [6.601704, 43.28583],
              [6.589981, 43.282439],
              [6.583874, 43.277308],
              [6.585991, 43.264474],
              [6.593463, 43.262266],
              [6.623488, 43.265351],
              [6.645798, 43.273835],
              [6.662121, 43.26491],
              [6.669531, 43.268463],
              [6.670414, 43.276401],
              [6.677307, 43.278795],
              [6.697255, 43.265497],
              [6.690423, 43.253768],
              [6.676366, 43.244474],
              [6.668565, 43.245554],
              [6.66358, 43.238043],
              [6.662666, 43.217572],
              [6.677034, 43.197187],
              [6.659327, 43.19593],
              [6.642023, 43.183734],
              [6.635535, 43.172509],
              [6.623662, 43.165301],
              [6.612902, 43.165892],
              [6.60047, 43.182582],
              [6.59193, 43.185477],
              [6.559638, 43.188633],
              [6.541396, 43.182331],
              [6.534084, 43.172783],
              [6.536319, 43.165107],
              [6.522806, 43.165324],
              [6.508769, 43.156649],
              [6.493496, 43.150224],
              [6.488374, 43.152806],
              [6.464148, 43.157528],
              [6.449215, 43.153388],
              [6.4443, 43.145456],
              [6.429071, 43.151423],
              [6.405159, 43.148995],
              [6.387568, 43.1449],
              [6.3803, 43.138559],
              [6.368722, 43.136975],
              [6.359763, 43.119904],
              [6.359881, 43.105075],
              [6.368364, 43.102269],
              [6.368257, 43.089915],
              [6.349288, 43.088191],
              [6.332514, 43.091094],
              [6.325362, 43.095399],
              [6.316667, 43.106166],
              [6.309241, 43.108321],
              [6.28822, 43.108702],
              [6.28816, 43.114201],
              [6.274941, 43.120741],
              [6.265297, 43.121041],
              [6.234337, 43.113014],
              [6.208085, 43.116444],
              [6.200785, 43.115791],
              [6.183993, 43.108536],
              [6.170456, 43.099756],
              [6.159837, 43.088452],
              [6.150271, 43.064454],
              [6.14996, 43.045856],
              [6.153157, 43.035246],
              [6.1479, 43.02696],
              [6.136899, 43.030617],
              [6.133995, 43.036214],
              [6.120936, 43.032186],
              [6.115005, 43.034957],
              [6.102591, 43.028783],
              [6.091831, 43.036774],
              [6.104719, 43.041957],
              [6.116714, 43.03924],
              [6.128192, 43.042671],
              [6.13233, 43.059514],
              [6.124052, 43.079307],
              [6.113993, 43.083655],
              [6.081298, 43.087226],
              [6.060871, 43.081748],
              [6.031435, 43.077475],
              [6.021534, 43.078257],
              [6.016824, 43.083635],
              [6.023465, 43.094812],
              [6.005016, 43.103799],
              [5.976227, 43.107577],
              [5.966273, 43.105639],
              [5.941249, 43.107661],
              [5.925787, 43.103971],
              [5.934336, 43.118604],
              [5.90748, 43.118882],
              [5.889011, 43.110051],
              [5.886836, 43.10345],
              [5.901009, 43.103997],
              [5.909107, 43.100461],
              [5.894771, 43.081712],
              [5.90957, 43.079394],
              [5.917086, 43.085825],
              [5.928953, 43.082919],
              [5.937658, 43.084579],
              [5.950958, 43.076765],
              [5.943881, 43.06641],
              [5.936646, 43.071726],
              [5.928598, 43.071716],
              [5.911289, 43.065784],
              [5.898466, 43.077587],
              [5.887133, 43.077226],
              [5.865754, 43.059882],
              [5.857455, 43.046619],
              [5.827638, 43.050349],
              [5.816712, 43.062315],
              [5.794106, 43.068774],
              [5.804564, 43.078283],
              [5.810513, 43.092326],
              [5.812732, 43.109367],
              [5.806951, 43.115679],
              [5.791018, 43.112425],
              [5.788666, 43.116377],
              [5.774495, 43.116904],
              [5.773688, 43.124358],
              [5.780694, 43.129637],
              [5.771254, 43.138932],
              [5.752295, 43.133161],
              [5.72585, 43.136527],
              [5.719015, 43.147274],
              [5.694875, 43.14359],
              [5.682675, 43.160132],
              [5.694761, 43.168022],
              [5.692439, 43.17487],
              [5.684595, 43.180062],
              [5.671879, 43.17927],
              [5.652559, 43.187314],
              [5.638324, 43.189174],
              [5.617217, 43.183715],
              [5.609839, 43.173684],
              [5.614645, 43.169198],
              [5.600895, 43.162546],
              [5.571498, 43.173556],
              [5.54796, 43.196024],
              [5.549909, 43.207368],
              [5.53693, 43.21449],
              [5.515764, 43.203024],
              [5.510061, 43.19769],
              [5.500289, 43.196791],
              [5.48657, 43.204154],
              [5.468037, 43.208724],
              [5.446188, 43.210231],
              [5.425649, 43.203784],
              [5.403381, 43.212824],
              [5.391057, 43.212123],
              [5.363649, 43.207122],
              [5.34561, 43.212498],
              [5.342561, 43.216258],
              [5.348354, 43.229824],
              [5.372525, 43.245078],
              [5.376047, 43.255157],
              [5.367373, 43.268908],
              [5.345684, 43.282366],
              [5.362986, 43.301953],
              [5.364415, 43.31214],
              [5.36205, 43.32196],
              [5.355632, 43.322292],
              [5.352524, 43.332823],
              [5.343179, 43.339096],
              [5.341021, 43.344783],
              [5.31731, 43.355087],
              [5.31103, 43.36088],
              [5.288515, 43.357327],
              [5.270398, 43.344652],
              [5.257642, 43.337857],
              [5.240591, 43.331713],
              [5.221334, 43.328359],
              [5.195439, 43.330003],
              [5.177474, 43.333617],
              [5.164477, 43.327323],
              [5.148211, 43.326418],
              [5.135188, 43.329222],
              [5.094781, 43.329271],
              [5.081588, 43.327877],
              [5.062798, 43.331088],
              [5.053915, 43.324272],
              [5.04104, 43.327285],
              [5.019048, 43.342937],
              [5.024297, 43.355559],
              [5.01711, 43.356943],
              [5.011398, 43.368028],
              [5.000198, 43.377116],
              [4.987638, 43.391777],
              [5.001068, 43.398216],
              [4.995504, 43.403479],
              [4.977642, 43.407406],
              [4.973049, 43.420934],
              [4.96771, 43.4261],
              [4.949433, 43.429224],
              [4.941216, 43.428254],
              [4.931377, 43.433157],
              [4.910606, 43.42728],
              [4.906353, 43.419737],
              [4.886588, 43.412881],
              [4.88396, 43.419515],
              [4.86741, 43.432331],
              [4.877303, 43.410513],
              [4.86685, 43.404678],
              [4.829121, 43.428265],
              [4.824433, 43.424394],
              [4.861468, 43.40077],
              [4.850014, 43.398836],
              [4.84161, 43.404034],
              [4.835856, 43.39804],
              [4.85511, 43.387878],
              [4.849484, 43.380014],
              [4.856933, 43.370915],
              [4.866732, 43.369607],
              [4.873702, 43.360848],
              [4.902234, 43.370982],
              [4.892522, 43.360162],
              [4.855045, 43.332619],
              [4.848141, 43.333671],
              [4.833034, 43.32987],
              [4.823184, 43.335957],
              [4.801964, 43.343147],
              [4.783357, 43.347189],
              [4.759595, 43.349936],
              [4.717742, 43.350306],
              [4.705708, 43.347799],
              [4.661784, 43.346411],
              [4.638089, 43.351002],
              [4.613103, 43.353767],
              [4.585802, 43.360072],
              [4.562798, 43.372135],
              [4.555611, 43.382823],
              [4.562713, 43.392244],
              [4.573592, 43.393577],
              [4.587698, 43.400712],
              [4.592951, 43.409939],
              [4.588099, 43.422705],
              [4.573169, 43.437346],
              [4.554917, 43.446213],
              [4.534717, 43.451637],
              [4.516361, 43.454715],
              [4.465266, 43.457151],
              [4.441709, 43.454884],
              [4.433437, 43.451163],
              [4.404587, 43.44812],
              [4.382042, 43.452272],
              [4.303722, 43.456772],
              [4.230281, 43.460184],
              [4.231171, 43.476759],
              [4.238103, 43.497625],
              [4.258642, 43.507539],
              [4.271417, 43.50807],
              [4.29769, 43.515329],
              [4.305132, 43.520795],
              [4.320492, 43.525482],
              [4.322709, 43.530447],
              [4.309523, 43.543279],
              [4.316431, 43.54433],
              [4.334133, 43.535451],
              [4.353644, 43.54739],
              [4.365136, 43.547157],
              [4.380677, 43.552542],
              [4.387087, 43.560477],
              [4.409353, 43.561127],
              [4.41532, 43.572538],
              [4.404417, 43.574366],
              [4.42554, 43.585225],
              [4.443312, 43.583145],
              [4.460681, 43.589261],
              [4.475235, 43.608296],
              [4.466807, 43.615206],
              [4.454526, 43.611392],
              [4.439748, 43.610673],
              [4.427034, 43.620675],
              [4.438216, 43.644375],
              [4.447579, 43.659307],
              [4.455948, 43.667416],
              [4.475495, 43.671096],
              [4.475662, 43.684464],
              [4.487234, 43.699241],
              [4.503672, 43.702259],
              [4.52439, 43.702131],
              [4.539847, 43.707276],
              [4.552779, 43.701311],
              [4.581885, 43.696369],
              [4.593035, 43.68746],
              [4.60583, 43.686077],
              [4.627662, 43.690543],
              [4.61304, 43.714297],
              [4.613635, 43.729822],
              [4.622717, 43.743237],
              [4.628819, 43.759862],
              [4.651244, 43.782275],
              [4.654826, 43.806405],
              [4.652277, 43.816838],
              [4.642419, 43.831437],
              [4.650152, 43.839124],
              [4.6663, 43.844777],
              [4.658666, 43.853224],
              [4.64801, 43.851229],
              [4.641915, 43.867477],
              [4.656648, 43.874779],
              [4.665438, 43.875147],
              [4.690546, 43.883899],
              [4.707494, 43.895551],
              [4.703798, 43.899341],
              [4.723368, 43.905956],
              [4.740099, 43.919679],
              [4.73906, 43.924062],
              [4.74999, 43.932182],
              [4.768779, 43.933801],
              [4.779021, 43.937894],
              [4.797153, 43.956719],
              [4.808, 43.960029],
              [4.815055, 43.967622],
              [4.810148, 43.977044],
              [4.815983, 43.9888],
              [4.831996, 43.985261],
              [4.8421, 43.986474],
              [4.84555, 43.996677],
              [4.843333, 44.009858],
              [4.837676, 44.01494],
              [4.821141, 44.016469],
              [4.816168, 44.032145],
              [4.808007, 44.039723],
              [4.789118, 44.052194],
              [4.787979, 44.065057],
              [4.757851, 44.077247],
              [4.760855, 44.086297],
              [4.754876, 44.088309],
              [4.730135, 44.079023],
              [4.719639, 44.086665],
              [4.70746, 44.10367],
              [4.707013, 44.113682],
              [4.714319, 44.137803],
              [4.718893, 44.144497],
              [4.716117, 44.165975],
              [4.722071, 44.187421],
              [4.703895, 44.197612],
              [4.709712, 44.206925],
              [4.701817, 44.216071],
              [4.683641, 44.212423],
              [4.672666, 44.216806],
              [4.678429, 44.22943],
              [4.674841, 44.238547],
              [4.654065, 44.254338],
              [4.649227, 44.27036],
              [4.653662, 44.29728],
              [4.650611, 44.329803],
              [4.679025, 44.32049],
              [4.713017, 44.320649],
              [4.720127, 44.326711],
              [4.762255, 44.325382],
              [4.782547, 44.315583],
              [4.7991, 44.303525],
              [4.80288, 44.296594],
              [4.805294, 44.268723],
              [4.812662, 44.257716],
              [4.81409, 44.232315],
              [4.82665, 44.228322],
              [4.845369, 44.241471],
              [4.873756, 44.259258],
              [4.896148, 44.264431],
              [4.906063, 44.260294],
              [4.917533, 44.260349],
              [4.935493, 44.264315],
              [4.959892, 44.274193],
              [4.981453, 44.284833],
              [5.006912, 44.287554],
              [5.021847, 44.295587],
              [5.038495, 44.299638],
              [5.060561, 44.308137],
              [5.076514, 44.284084],
              [5.107852, 44.280398],
              [5.121466, 44.28748],
              [5.154676, 44.301845],
              [5.152417, 44.307684],
              [5.166435, 44.314853],
              [5.17269, 44.309391],
              [5.167205, 44.29205],
              [5.149731, 44.282086],
              [5.147472, 44.269525],
              [5.157136, 44.267225],
              [5.16155, 44.245589],
              [5.151659, 44.237729],
              [5.1549, 44.230941],
              [5.173507, 44.221966],
              [5.204614, 44.215117],
              [5.238146, 44.213233],
              [5.240451, 44.230826],
              [5.256505, 44.230056],
              [5.291191, 44.214934],
              [5.30371, 44.206011],
              [5.318311, 44.209869],
              [5.336784, 44.203893],
              [5.354042, 44.213432],
              [5.384527, 44.201049],
              [5.383042, 44.198796],
              [5.386477, 44.176907],
              [5.383237, 44.155285],
              [5.390523, 44.153425],
              [5.435751, 44.15225],
              [5.436946, 44.142813],
              [5.447281, 44.135995],
              [5.449251, 44.124592],
              [5.454715, 44.119226],
              [5.498786, 44.115717],
              [5.504824, 44.11627],
              [5.519757, 44.126616],
              [5.532959, 44.130053],
              [5.543985, 44.13639],
              [5.551331, 44.149792],
              [5.569507, 44.1481],
              [5.582975, 44.157626],
              [5.564371, 44.170902],
              [5.576192, 44.188037],
              [5.596206, 44.187649],
              [5.602056, 44.191496],
              [5.616216, 44.181069],
              [5.639595, 44.167582],
              [5.63114, 44.150577],
              [5.657708, 44.147529],
              [5.66795, 44.148874],
              [5.678609, 44.146091],
              [5.682709, 44.163217],
              [5.662633, 44.166864],
              [5.646959, 44.166287],
              [5.643691, 44.172641],
              [5.652293, 44.185411],
              [5.651586, 44.189573],
              [5.676036, 44.191429],
              [5.686443, 44.197158],
              [5.676021, 44.212147],
              [5.681315, 44.232891],
              [5.672644, 44.245665],
              [5.675344, 44.258111],
              [5.686562, 44.266921],
              [5.675291, 44.275852],
              [5.646781, 44.267089],
              [5.633266, 44.28212],
              [5.637753, 44.299689],
              [5.607906, 44.30667],
              [5.613832, 44.316139],
              [5.631598, 44.328306],
              [5.626915, 44.334765],
              [5.617139, 44.332479],
              [5.586892, 44.332226],
              [5.57386, 44.33394],
              [5.549111, 44.330396],
              [5.537366, 44.333521],
              [5.539863, 44.342637],
              [5.513545, 44.347486],
              [5.49307, 44.337174],
              [5.482472, 44.349606],
              [5.467949, 44.352677],
              [5.462966, 44.367052],
              [5.434415, 44.369626],
              [5.435114, 44.377077],
              [5.442361, 44.38166],
              [5.44302, 44.391234],
              [5.43431, 44.409479],
              [5.422756, 44.416771],
              [5.418533, 44.424945],
              [5.433857, 44.433224],
              [5.450759, 44.430874],
              [5.476485, 44.419722],
              [5.486567, 44.429311],
              [5.493615, 44.428218],
              [5.496804, 44.438494],
              [5.464428, 44.44789],
              [5.464049, 44.45736],
              [5.458731, 44.466143],
              [5.463576, 44.480513],
              [5.456931, 44.496236],
              [5.465389, 44.500459],
              [5.473596, 44.498569],
              [5.479877, 44.491243],
              [5.513342, 44.491126],
              [5.544701, 44.482538],
              [5.562401, 44.474854],
              [5.570304, 44.476755],
              [5.579201, 44.471686],
              [5.603645, 44.465542],
              [5.618123, 44.474813],
              [5.629788, 44.501187],
              [5.652656, 44.499963],
              [5.664504, 44.501895],
              [5.653143, 44.514831],
              [5.630802, 44.531597],
              [5.614993, 44.532818],
              [5.597253, 44.543274],
              [5.599798, 44.553941],
              [5.607019, 44.568348],
              [5.625799, 44.586276],
              [5.627479, 44.594966],
              [5.647516, 44.612808],
              [5.649631, 44.617885],
              [5.640373, 44.633825],
              [5.641718, 44.651077],
              [5.654457, 44.655393],
              [5.65936, 44.650663],
              [5.685362, 44.649596],
              [5.726033, 44.639394],
              [5.735803, 44.640416],
              [5.736013, 44.64951],
              [5.751105, 44.648612],
              [5.748257, 44.655091],
              [5.753943, 44.662711],
              [5.766631, 44.655267],
              [5.790624, 44.653293],
              [5.80648, 44.677182],
              [5.824635, 44.685278],
              [5.829472, 44.692425],
              [5.82711, 44.700288],
              [5.80147, 44.706778],
              [5.808795, 44.712102],
              [5.817772, 44.730405],
              [5.827771, 44.740086],
              [5.827098, 44.759688],
              [5.837158, 44.757677],
              [5.850394, 44.750747],
              [5.865226, 44.751552],
              [5.879495, 44.747015],
              [5.888831, 44.748804],
              [5.90015, 44.758315],
              [5.915234, 44.754703],
              [5.926812, 44.757136],
              [5.937984, 44.763046],
              [5.952472, 44.762141],
              [5.955515, 44.772449],
              [5.980149, 44.781182],
              [5.977779, 44.790984],
              [5.953764, 44.799526],
              [5.949522, 44.804527],
              [5.978199, 44.818036],
              [5.996166, 44.817874],
              [6.00486, 44.820439],
              [6.015933, 44.835486],
              [6.030216, 44.838098],
              [6.040207, 44.827867],
              [6.05634, 44.815907],
              [6.065315, 44.822685],
              [6.096516, 44.837489],
              [6.100706, 44.842578],
              [6.116673, 44.849313],
              [6.128356, 44.861902],
              [6.136227, 44.864072],
              [6.149019, 44.85817],
              [6.168451, 44.852243],
              [6.185219, 44.853992],
              [6.196376, 44.858978],
              [6.224375, 44.852494],
              [6.250543, 44.852651],
              [6.258171, 44.862484],
              [6.267756, 44.869501],
              [6.288213, 44.874031],
              [6.30285, 44.873258],
              [6.31923, 44.856846],
              [6.336317, 44.84837],
              [6.355363, 44.854775],
              [6.350778, 44.881204],
              [6.35817, 44.893779],
              [6.354614, 44.923593],
              [6.358842, 44.941281],
              [6.329005, 44.947315],
              [6.322918, 44.95302],
              [6.328501, 44.969714],
              [6.31481, 44.980186],
              [6.319657, 44.994553],
              [6.318202, 45.003859],
              [6.297062, 45.003365],
              [6.269699, 44.998351],
              [6.251761, 44.9967],
              [6.203923, 45.012471],
              [6.206328, 45.026783],
              [6.22008, 45.06537],
              [6.240121, 45.067718],
              [6.235832, 45.087233],
              [6.229672, 45.100589],
              [6.229392, 45.10875],
              [6.243831, 45.117298],
              [6.254494, 45.12079],
              [6.26057, 45.126844],
              [6.275503, 45.115299],
              [6.286281, 45.110161],
              [6.301916, 45.108954],
              [6.331295, 45.118124],
              [6.334484, 45.122836],
              [6.362927, 45.104492],
              [6.373531, 45.084152],
              [6.365073, 45.07129],
              [6.393911, 45.061818],
              [6.400243, 45.063261],
              [6.43859, 45.062587],
              [6.443399, 45.055077],
              [6.45353, 45.051837],
              [6.472888, 45.056012],
              [6.486236, 45.056076],
              [6.489338, 45.068354],
              [6.481256, 45.094139],
              [6.499528, 45.101598],
              [6.510424, 45.108998],
              [6.529711, 45.098569],
              [6.556731, 45.104079],
              [6.563079, 45.113039],
              [6.576529, 45.123093],
              [6.590685, 45.119011],
              [6.615546, 45.121478],
              [6.629992, 45.109325],
              [6.627484, 45.101215],
              [6.645447, 45.085321],
              [6.645245, 45.075644],
              [6.66211, 45.071636],
              [6.661967, 45.054255],
              [6.669733, 45.041529],
              [6.664206, 45.033853],
              [6.673884, 45.019618],
              [6.720875, 45.021948],
              [6.740812, 45.016733],
              [6.745518, 45.012636],
              [6.751182, 44.997554],
              [6.737637, 44.99149],
              [6.748725, 44.985301],
              [6.750099, 44.979004],
              [6.763462, 44.971216],
              [6.764944, 44.95967],
              [6.753076, 44.942965],
              [6.760455, 44.933557],
              [6.750958, 44.924737],
              [6.749751, 44.907359],
              [6.770756, 44.903356],
              [6.778832, 44.895248],
              [6.799787, 44.885791],
              [6.804083, 44.877495],
              [6.828619, 44.867936],
              [6.836556, 44.862113],
              [6.859866, 44.852903],
              [6.883743, 44.847959],
              [6.913765, 44.84542],
              [6.927765, 44.859966],
              [6.933686, 44.862026],
              [6.953688, 44.855489],
              [6.97261, 44.846235],
              [6.983852, 44.84587],
              [7.006773, 44.839316],
              [7.009316, 44.826729],
              [7.021786, 44.821493],
              [7.018287, 44.812303],
              [6.99957, 44.789444],
              [7.014173, 44.780241],
              [7.024745, 44.762382],
              [7.021784, 44.753151],
              [7.023727, 44.741017],
              [7.032723, 44.727849],
              [7.04368, 44.718142],
              [7.065783, 44.713604],
              [7.066399, 44.707136],
              [7.074193, 44.692483],
              [7.077105, 44.680915],
              [7.059723, 44.67996],
              [7.037091, 44.689271],
              [7.023734, 44.6913],
              [7.006337, 44.68822],
              [6.987061, 44.690138],
              [6.976342, 44.681205],
              [6.962647, 44.677767],
              [6.948333, 44.654818],
            ],
            [
              [5.017834, 43.469321],
              [5.037985, 43.470644],
              [5.051885, 43.46356],
              [5.054969, 43.453888],
              [5.054122, 43.442022],
              [5.061108, 43.42314],
              [5.058021, 43.409552],
              [5.059568, 43.403975],
              [5.07039, 43.400203],
              [5.092706, 43.400605],
              [5.110708, 43.404127],
              [5.125777, 43.400016],
              [5.137266, 43.400185],
              [5.157535, 43.409584],
              [5.182863, 43.426607],
              [5.191266, 43.434901],
              [5.208447, 43.445434],
              [5.220348, 43.449162],
              [5.230138, 43.465078],
              [5.222552, 43.483465],
              [5.217517, 43.488096],
              [5.202792, 43.491173],
              [5.190843, 43.486476],
              [5.182461, 43.47935],
              [5.165105, 43.471767],
              [5.149688, 43.457027],
              [5.132115, 43.473849],
              [5.123114, 43.486154],
              [5.113569, 43.504661],
              [5.118209, 43.518012],
              [5.108662, 43.525497],
              [5.089314, 43.525516],
              [5.06433, 43.527831],
              [5.046435, 43.522139],
              [5.032251, 43.539769],
              [5.030952, 43.546825],
              [5.021994, 43.556156],
              [5.013284, 43.554449],
              [5.012205, 43.545527],
              [5.015464, 43.529719],
              [5.00245, 43.512784],
              [4.998549, 43.498106],
              [5.002904, 43.486741],
              [5.000858, 43.474094],
              [5.006005, 43.469665],
              [5.017834, 43.469321],
            ],
          ],
          [
            [
              [6.434149, 43.013347],
              [6.433941, 43.020254],
              [6.440263, 43.024843],
              [6.455203, 43.026815],
              [6.470216, 43.045168],
              [6.504181, 43.05334],
              [6.511289, 43.046822],
              [6.504712, 43.043075],
              [6.48959, 43.042642],
              [6.471021, 43.019212],
              [6.4617, 43.013939],
              [6.452803, 43.016844],
              [6.439101, 43.004472],
              [6.434149, 43.013347],
            ],
          ],
          [
            [
              [6.397091, 42.992802],
              [6.385004, 42.997875],
              [6.371412, 42.997142],
              [6.36972, 43.001244],
              [6.382411, 43.011038],
              [6.408582, 43.018604],
              [6.420666, 43.013709],
              [6.4069, 42.997224],
              [6.397091, 42.992802],
            ],
          ],
          [
            [
              [6.244074, 43.020035],
              [6.248487, 43.016627],
              [6.250453, 42.999408],
              [6.226523, 42.992424],
              [6.216436, 42.991108],
              [6.208671, 42.983381],
              [6.197898, 42.982043],
              [6.193858, 42.990203],
              [6.177388, 42.996128],
              [6.164537, 42.997829],
              [6.17127, 43.006205],
              [6.184005, 43.008257],
              [6.201681, 43.000829],
              [6.211735, 43.002562],
              [6.220415, 43.012437],
              [6.233991, 43.011656],
              [6.244074, 43.020035],
            ],
          ],
          [
            [
              [7.067118, 43.513649],
              [7.036133, 43.517461],
              [7.032208, 43.52344],
              [7.050097, 43.522515],
              [7.070877, 43.517365],
              [7.067118, 43.513649],
            ],
          ],
          [
            [
              [4.888121, 44.331685],
              [4.895327, 44.338062],
              [4.879647, 44.345347],
              [4.875396, 44.351439],
              [4.890682, 44.359782],
              [4.893169, 44.367875],
              [4.906621, 44.374101],
              [4.911766, 44.387045],
              [4.91238, 44.399636],
              [4.918514, 44.407787],
              [4.960462, 44.420048],
              [4.970438, 44.431367],
              [4.978694, 44.423476],
              [4.988612, 44.4232],
              [5.001069, 44.412605],
              [5.018754, 44.415979],
              [5.013302, 44.405343],
              [5.015755, 44.392767],
              [5.033569, 44.391089],
              [5.045088, 44.38222],
              [5.070883, 44.383226],
              [5.071558, 44.377496],
              [5.05201, 44.364657],
              [5.027011, 44.362835],
              [5.021705, 44.356748],
              [5.023285, 44.345948],
              [5.009002, 44.333764],
              [5.013377, 44.326137],
              [4.99313, 44.314276],
              [4.98729, 44.293202],
              [4.978583, 44.297461],
              [4.959214, 44.300383],
              [4.945117, 44.30493],
              [4.922154, 44.308786],
              [4.88953, 44.304153],
              [4.889804, 44.314637],
              [4.881634, 44.324867],
              [4.888121, 44.331685],
            ],
          ],
        ],
      },
    },
  ],
  style: {
    stroke: "#000000",
    fill: "#3498DB",
    "stroke-width": 1,
    "fill-opacity": 0.8,
  },
};
