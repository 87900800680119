import React, { useState, useEffect } from "react";
import CommonLoginRegisterComp from "./CommonLoginRegisterComp";
import { msalInstance } from "../msalConfig";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
      } catch (error) {
        console.error("Error initializing MSAL:", error);
        setError("Failed to initialize Microsoft authentication.");
      }
    };
    initializeMsal();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  

  const handleMicrosoftLogin = async () => {
    try {
      const loginResponse = await msalInstance.loginPopup({
        scopes: ["openid", "profile", "User.Read"],
      });
      console.log("Microsoft login successful, token received:", loginResponse.accessToken);
      
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/microsoft`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify({ id_token: loginResponse.accessToken }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Server response:", errorData);
        throw new Error(errorData.detail || "Failed to authenticate with the server");
      }
      
      const data = await response.json();
      console.log("Server authentication successful:", data);
      
      localStorage.setItem("user", JSON.stringify({
        email: data.email,
        full_name: data.full_name,
        status: "logged_in",
        id: data.user_id,
        token: data.access_token,
      }));
      
      navigate("/who");
    } catch (error) {
      console.error("Microsoft Login failed:", error);
      setError(`Microsoft Login failed: ${error.message}. Please try again.`);
    }
  };


  // const handleMicrosoftLogin = () => {
  //   navigate("/who");
  // };

  
  const handleMicrosoftLogout = async () => {
    try {
      console.log('Initiating Microsoft logout process...');

      // Clear the console logs
      console.clear();

      // Call the backend logout API
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/microsoft-logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Include any necessary data in the body, e.g., tokens
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Logout failed');
      }

      // Clear localStorage
      localStorage.removeItem('user');

      // Redirect to the login page (frontend route)
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      setError('Logout failed. Please try again.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
  
      // Ensure email is included in localStorage
      localStorage.setItem("user", JSON.stringify({
        email: data.email, // Ensure email is set
        full_name: data.full_name,
        status: "logged_in",
        id: data.id,
        token: data.access_token,
      }));
      
      navigate("/who");
    } catch (error) {
      console.error("Error:", error);
      setError("Login failed. Please check your credentials and try again.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <CommonLoginRegisterComp
      title1="Sign In"
      title2="to your account"
      isRegister={false}
      isReserpassword={false}
      newPassword={false}
      errorMessage={error}
      buttonText={loading ? "Logging in..." : "Login"}
      linkText1="Don't have an account?"
      linkText2="Sign Up"
      linkUrl="/register"
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleMicrosoftLogin={handleMicrosoftLogin}
      handleMicrosoftLogout={handleMicrosoftLogout}
      email={email}
      password={password}
    />
  );
};

export default Login;
