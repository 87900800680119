export const italy_topology = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.715897, 46.098965],
            [8.65937, 46.113487],
            [8.648775, 46.124089],
            [8.612798, 46.122744],
            [8.595965, 46.14316],
            [8.603998, 46.155122],
            [8.574142, 46.165941],
            [8.570512, 46.177942],
            [8.541774, 46.198593],
            [8.53367, 46.21898],
            [8.469846, 46.234407],
            [8.464799, 46.24566],
            [8.443586, 46.254558],
            [8.457338, 46.264434],
            [8.428755, 46.299321],
            [8.445099, 46.32056],
            [8.465534, 46.333904],
            [8.463109, 46.354124],
            [8.469646, 46.365791],
            [8.462062, 46.387453],
            [8.469623, 46.412543],
            [8.45876, 46.423273],
            [8.466976, 46.443821],
            [8.44659, 46.464621],
            [8.408455, 46.459525],
            [8.394646, 46.453199],
            [8.357275, 46.448378],
            [8.340503, 46.433731],
            [8.30141, 46.420269],
            [8.29079, 46.409504],
            [8.318, 46.398159],
            [8.313044, 46.377476],
            [8.286131, 46.366871],
            [8.264495, 46.364583],
            [8.262795, 46.346105],
            [8.2248, 46.33456],
            [8.212951, 46.313771],
            [8.199871, 46.303249],
            [8.162454, 46.297132],
            [8.138808, 46.302536],
            [8.082426, 46.259124],
            [8.111239, 46.250226],
            [8.126108, 46.230505],
            [8.140101, 46.227002],
            [8.154558, 46.192578],
            [8.165648, 46.184218],
            [8.150624, 46.16023],
            [8.155165, 46.148726],
            [8.144472, 46.137667],
            [8.116068, 46.131241],
            [8.109145, 46.112514],
            [8.033871, 46.099346],
            [8.023678, 46.069154],
            [8.035507, 46.046287],
            [8.013863, 46.032899],
            [8.013276, 46.012846],
            [7.99001, 45.99742],
            [7.909918, 45.99825],
            [7.878863, 45.973199],
            [7.877616, 45.949939],
            [7.8695, 45.938573],
            [7.878063, 45.927363],
            [7.865146, 45.917375],
            [7.869913, 45.868659],
            [7.877019, 45.862518],
            [7.864959, 45.840519],
            [7.875134, 45.826921],
            [7.863428, 45.82084],
            [7.864196, 45.791928],
            [7.901259, 45.750186],
            [7.930096, 45.743382],
            [7.937717, 45.725282],
            [7.906301, 45.682903],
            [7.924948, 45.657625],
            [7.940595, 45.644639],
            [7.916996, 45.634031],
            [7.897805, 45.612296],
            [7.89734, 45.600711],
            [7.863368, 45.597932],
            [7.849965, 45.603207],
            [7.79677, 45.587331],
            [7.786416, 45.575735],
            [7.733793, 45.551277],
            [7.71744, 45.559905],
            [7.677888, 45.55304],
            [7.647039, 45.569938],
            [7.610163, 45.563824],
            [7.600181, 45.579392],
            [7.566586, 45.592512],
            [7.549517, 45.589731],
            [7.533769, 45.578117],
            [7.50012, 45.584057],
            [7.471965, 45.578635],
            [7.449063, 45.556516],
            [7.423275, 45.546956],
            [7.376815, 45.517443],
            [7.365125, 45.526004],
            [7.324055, 45.515402],
            [7.2706, 45.515628],
            [7.264357, 45.49962],
            [7.248657, 45.495024],
            [7.231488, 45.476415],
            [7.21485, 45.472214],
            [7.200568, 45.482098],
            [7.158377, 45.487555],
            [7.142241, 45.481767],
            [7.142161, 45.511874],
            [7.122771, 45.507191],
            [7.114457, 45.47394],
            [7.105435, 45.467913],
            [7.102426, 45.455198],
            [7.115532, 45.434416],
            [7.153362, 45.423585],
            [7.183516, 45.401917],
            [7.164384, 45.382745],
            [7.160611, 45.360415],
            [7.139561, 45.352135],
            [7.127181, 45.328287],
            [7.110504, 45.32866],
            [7.12267, 45.299517],
            [7.136386, 45.283396],
            [7.138018, 45.256509],
            [7.125421, 45.244976],
            [7.108172, 45.244568],
            [7.068092, 45.211254],
            [7.052071, 45.226666],
            [7.020727, 45.215569],
            [6.96172, 45.203945],
            [6.968122, 45.196147],
            [6.93308, 45.17206],
            [6.894624, 45.169971],
            [6.885529, 45.158533],
            [6.899029, 45.14231],
            [6.851509, 45.128418],
            [6.814209, 45.149494],
            [6.770264, 45.160805],
            [6.741307, 45.137939],
            [6.713143, 45.145732],
            [6.681317, 45.141381],
            [6.674166, 45.125501],
            [6.634503, 45.115774],
            [6.628369, 45.102739],
            [6.662631, 45.072652],
            [6.666535, 45.032584],
            [6.676554, 45.020295],
            [6.727126, 45.022645],
            [6.744322, 45.016883],
            [6.751537, 44.97912],
            [6.766165, 44.960044],
            [6.747328, 44.939555],
            [6.751102, 44.908457],
            [6.772289, 44.90402],
            [6.810055, 44.87644],
            [6.864042, 44.851691],
            [6.913936, 44.845826],
            [6.932603, 44.864409],
            [6.970636, 44.847614],
            [7.007666, 44.840479],
            [7.024945, 44.824302],
            [7.001174, 44.790018],
            [7.020331, 44.77693],
            [7.024764, 44.740675],
            [7.042154, 44.720531],
            [7.066205, 44.714755],
            [7.077251, 44.684822],
            [7.063352, 44.680833],
            [7.031504, 44.692442],
            [7.004543, 44.687788],
            [6.988209, 44.690499],
            [6.948693, 44.653598],
            [6.956137, 44.622782],
            [6.934218, 44.593617],
            [6.934778, 44.575991],
            [6.913565, 44.569502],
            [6.915402, 44.559984],
            [6.8783, 44.553846],
            [6.854507, 44.528861],
            [6.857691, 44.514706],
            [6.876794, 44.483231],
            [6.907419, 44.468807],
            [6.913081, 44.45313],
            [6.935739, 44.430919],
            [6.9264, 44.423655],
            [6.894579, 44.421831],
            [6.898908, 44.406102],
            [6.897241, 44.372933],
            [6.889913, 44.362058],
            [6.923099, 44.35271],
            [6.927873, 44.33417],
            [6.961, 44.313098],
            [6.957344, 44.299222],
            [6.971072, 44.286314],
            [6.996051, 44.281696],
            [6.997798, 44.251694],
            [7.006337, 44.238411],
            [7.034471, 44.225341],
            [7.069622, 44.234673],
            [7.087513, 44.230293],
            [7.142731, 44.201954],
            [7.164653, 44.208835],
            [7.189758, 44.199897],
            [7.220745, 44.169337],
            [7.236929, 44.175989],
            [7.249496, 44.158951],
            [7.279882, 44.142262],
            [7.287821, 44.146567],
            [7.344343, 44.146275],
            [7.356551, 44.118129],
            [7.393727, 44.126544],
            [7.426924, 44.115212],
            [7.429244, 44.131395],
            [7.461032, 44.126841],
            [7.498277, 44.142839],
            [7.522477, 44.138779],
            [7.536024, 44.14842],
            [7.566094, 44.153326],
            [7.61845, 44.15082],
            [7.633629, 44.174577],
            [7.646059, 44.179105],
            [7.685811, 44.174901],
            [7.673402, 44.160628],
            [7.679817, 44.147612],
            [7.667958, 44.134582],
            [7.673002, 44.121433],
            [7.714313, 44.088811],
            [7.715396, 44.062541],
            [7.728839, 44.061262],
            [7.741147, 44.078306],
            [7.720363, 44.105462],
            [7.724191, 44.121798],
            [7.747091, 44.136337],
            [7.776209, 44.140952],
            [7.816376, 44.124224],
            [7.867184, 44.119135],
            [7.884992, 44.105652],
            [7.927035, 44.111276],
            [7.93826, 44.100727],
            [7.972593, 44.114074],
            [8.005694, 44.103305],
            [8.016658, 44.11168],
            [8.007695, 44.126681],
            [7.979993, 44.13452],
            [8.010507, 44.156586],
            [8.029849, 44.155069],
            [8.043975, 44.143809],
            [8.06837, 44.145666],
            [8.083763, 44.16981],
            [8.095254, 44.176384],
            [8.089023, 44.204136],
            [8.066425, 44.217912],
            [8.081681, 44.242685],
            [8.064159, 44.254049],
            [8.081729, 44.262568],
            [8.089661, 44.275272],
            [8.060803, 44.301677],
            [8.077085, 44.314931],
            [8.103019, 44.301904],
            [8.108032, 44.33379],
            [8.135346, 44.333072],
            [8.149699, 44.355782],
            [8.142545, 44.369043],
            [8.150582, 44.385763],
            [8.187568, 44.396272],
            [8.204561, 44.405863],
            [8.222898, 44.430525],
            [8.197498, 44.464573],
            [8.198391, 44.478524],
            [8.219542, 44.482793],
            [8.225226, 44.515142],
            [8.253862, 44.529725],
            [8.272375, 44.507764],
            [8.305615, 44.503614],
            [8.321864, 44.48937],
            [8.351113, 44.485777],
            [8.35955, 44.465902],
            [8.395507, 44.481196],
            [8.404959, 44.509943],
            [8.450557, 44.498353],
            [8.454069, 44.510776],
            [8.515326, 44.510845],
            [8.53584, 44.514315],
            [8.561115, 44.50381],
            [8.577217, 44.51019],
            [8.574812, 44.524288],
            [8.591216, 44.528579],
            [8.614508, 44.547565],
            [8.598872, 44.557522],
            [8.601943, 44.568786],
            [8.621225, 44.577691],
            [8.667811, 44.583418],
            [8.682474, 44.575844],
            [8.722052, 44.580826],
            [8.73382, 44.571393],
            [8.768616, 44.525926],
            [8.774291, 44.506402],
            [8.770013, 44.490621],
            [8.789086, 44.488982],
            [8.797704, 44.501071],
            [8.796158, 44.522818],
            [8.80744, 44.535558],
            [8.825448, 44.539602],
            [8.828893, 44.562409],
            [8.863955, 44.561571],
            [8.89599, 44.556329],
            [8.918552, 44.562014],
            [8.905405, 44.621784],
            [8.883745, 44.639978],
            [8.909312, 44.647781],
            [8.928935, 44.675224],
            [8.960573, 44.676977],
            [8.973565, 44.664306],
            [9.014202, 44.667747],
            [9.054728, 44.621841],
            [9.0769, 44.624655],
            [9.10553, 44.607569],
            [9.103201, 44.595071],
            [9.114014, 44.582066],
            [9.153599, 44.575178],
            [9.184564, 44.592597],
            [9.199998, 44.593732],
            [9.204017, 44.614449],
            [9.19972, 44.649713],
            [9.207479, 44.661159],
            [9.201365, 44.687358],
            [9.209489, 44.70387],
            [9.203876, 44.72301],
            [9.214596, 44.7533],
            [9.195205, 44.757791],
            [9.172773, 44.772635],
            [9.174552, 44.7971],
            [9.156168, 44.810359],
            [9.110041, 44.806389],
            [9.096195, 44.819977],
            [9.082666, 44.815414],
            [9.05243, 44.846876],
            [9.071156, 44.86699],
            [9.056743, 44.889997],
            [9.031416, 44.889449],
            [9.009101, 44.903932],
            [8.98926, 44.931802],
            [8.970645, 44.944441],
            [8.979179, 44.968155],
            [8.949648, 44.98942],
            [8.926039, 44.984339],
            [8.902331, 45.008711],
            [8.905714, 45.027568],
            [8.899125, 45.051606],
            [8.882117, 45.055028],
            [8.824952, 45.047537],
            [8.803373, 45.027024],
            [8.778759, 45.024683],
            [8.776293, 45.008898],
            [8.748787, 45.010244],
            [8.752021, 45.021334],
            [8.701876, 45.027468],
            [8.672223, 45.028124],
            [8.635802, 45.041913],
            [8.648015, 45.056491],
            [8.637961, 45.067477],
            [8.636552, 45.089985],
            [8.609541, 45.109294],
            [8.612045, 45.124578],
            [8.58934, 45.136636],
            [8.581068, 45.153647],
            [8.553581, 45.161064],
            [8.545018, 45.182237],
            [8.557945, 45.206121],
            [8.52674, 45.220238],
            [8.547284, 45.242753],
            [8.532011, 45.269108],
            [8.514162, 45.276214],
            [8.517023, 45.287212],
            [8.499409, 45.293172],
            [8.508782, 45.308696],
            [8.539438, 45.320298],
            [8.527442, 45.343119],
            [8.551447, 45.355747],
            [8.579883, 45.348178],
            [8.612849, 45.355369],
            [8.629904, 45.350799],
            [8.632736, 45.339324],
            [8.656779, 45.326623],
            [8.667655, 45.29315],
            [8.698306, 45.295666],
            [8.717188, 45.303824],
            [8.727607, 45.333832],
            [8.763741, 45.351172],
            [8.747765, 45.357232],
            [8.749357, 45.375318],
            [8.766499, 45.38988],
            [8.794448, 45.3799],
            [8.811602, 45.389732],
            [8.843962, 45.394777],
            [8.811137, 45.446917],
            [8.790416, 45.46292],
            [8.791872, 45.47968],
            [8.761421, 45.497784],
            [8.728963, 45.503337],
            [8.714268, 45.520869],
            [8.720475, 45.531992],
            [8.710177, 45.544839],
            [8.70593, 45.570872],
            [8.695491, 45.576938],
            [8.704221, 45.600992],
            [8.666273, 45.63248],
            [8.689628, 45.640809],
            [8.664756, 45.675481],
            [8.641875, 45.677742],
            [8.655434, 45.708816],
            [8.647037, 45.722694],
            [8.594828, 45.731345],
            [8.583989, 45.749604],
            [8.556745, 45.775923],
            [8.553355, 45.789037],
            [8.564591, 45.808176],
            [8.582727, 45.814589],
            [8.596281, 45.834545],
            [8.593392, 45.853376],
            [8.570528, 45.895058],
            [8.588969, 45.917856],
            [8.68338, 45.987813],
            [8.720402, 46.012071],
            [8.729844, 46.030156],
            [8.721331, 46.043123],
            [8.706755, 46.082317],
            [8.715897, 46.098965],
          ],
        ],
      },
      properties: {
        name: "Piemonte",
        cartodb_id: 1,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.454164, 46.531452],
            [10.420392, 46.551948],
            [10.398607, 46.544837],
            [10.364427, 46.556463],
            [10.338116, 46.544328],
            [10.326707, 46.552609],
            [10.296803, 46.551296],
            [10.28459, 46.572119],
            [10.270182, 46.578696],
            [10.245059, 46.579297],
            [10.243309, 46.593418],
            [10.258934, 46.611789],
            [10.24012, 46.636081],
            [10.212591, 46.61872],
            [10.191687, 46.62698],
            [10.131231, 46.606496],
            [10.102727, 46.611008],
            [10.102155, 46.583674],
            [10.086224, 46.56752],
            [10.045791, 46.537358],
            [10.054158, 46.532363],
            [10.044104, 46.480313],
            [10.053876, 46.46218],
            [10.04087, 46.446604],
            [10.05759, 46.441465],
            [10.063071, 46.428104],
            [10.08065, 46.422008],
            [10.102311, 46.423233],
            [10.13, 46.432794],
            [10.161647, 46.416714],
            [10.164996, 46.391797],
            [10.128777, 46.378521],
            [10.129694, 46.361764],
            [10.108824, 46.352285],
            [10.105371, 46.334445],
            [10.117461, 46.315644],
            [10.139981, 46.305604],
            [10.156981, 46.291501],
            [10.175582, 46.255574],
            [10.146728, 46.231289],
            [10.12434, 46.22468],
            [10.095571, 46.229459],
            [10.071891, 46.218047],
            [10.048827, 46.233075],
            [10.061585, 46.249048],
            [10.056511, 46.263828],
            [10.031163, 46.278027],
            [9.999327, 46.283835],
            [9.992837, 46.297517],
            [9.995737, 46.314951],
            [9.982488, 46.323589],
            [9.99741, 46.35184],
            [9.965549, 46.364317],
            [9.954, 46.380259],
            [9.925593, 46.367027],
            [9.907421, 46.381788],
            [9.870679, 46.363391],
            [9.8515, 46.365547],
            [9.77854, 46.335869],
            [9.744695, 46.352424],
            [9.724086, 46.341352],
            [9.726988, 46.314609],
            [9.715561, 46.293978],
            [9.68939, 46.294589],
            [9.675405, 46.303663],
            [9.636147, 46.287126],
            [9.610929, 46.295417],
            [9.583962, 46.295387],
            [9.538197, 46.31056],
            [9.514969, 46.334147],
            [9.49472, 46.366261],
            [9.465303, 46.375141],
            [9.469819, 46.389603],
            [9.454997, 46.419311],
            [9.460131, 46.443391],
            [9.460333, 46.485831],
            [9.450461, 46.500625],
            [9.425885, 46.490424],
            [9.413897, 46.467821],
            [9.390502, 46.474167],
            [9.369453, 46.494939],
            [9.365759, 46.508635],
            [9.310659, 46.505378],
            [9.283006, 46.497328],
            [9.275136, 46.484424],
            [9.272765, 46.455429],
            [9.247626, 46.447342],
            [9.25055, 46.432068],
            [9.281713, 46.413268],
            [9.276786, 46.39565],
            [9.282548, 46.359787],
            [9.296813, 46.356592],
            [9.293499, 46.33911],
            [9.301123, 46.327499],
            [9.282537, 46.310267],
            [9.275082, 46.29207],
            [9.252714, 46.266401],
            [9.247203, 46.233164],
            [9.222374, 46.230785],
            [9.219737, 46.216426],
            [9.2014, 46.208108],
            [9.194042, 46.18015],
            [9.180964, 46.170506],
            [9.161446, 46.171768],
            [9.136628, 46.154656],
            [9.121876, 46.13553],
            [9.072709, 46.120434],
            [9.090285, 46.090992],
            [9.074443, 46.06354],
            [9.05045, 46.063328],
            [9.017364, 46.049847],
            [9.010172, 46.028095],
            [9.023509, 46.017403],
            [9.023718, 45.993596],
            [8.989483, 45.973532],
            [9.015137, 45.96108],
            [9.022921, 45.938574],
            [9.050017, 45.921141],
            [9.077395, 45.912364],
            [9.078864, 45.886519],
            [9.055077, 45.873824],
            [9.031704, 45.821712],
            [8.994353, 45.823562],
            [8.987771, 45.839249],
            [8.97087, 45.833242],
            [8.950609, 45.844405],
            [8.920467, 45.834965],
            [8.916423, 45.84443],
            [8.935374, 45.862987],
            [8.925688, 45.906777],
            [8.89508, 45.930914],
            [8.89535, 45.95939],
            [8.86931, 45.961312],
            [8.832353, 45.988908],
            [8.796714, 45.992972],
            [8.793142, 46.005907],
            [8.80702, 46.022532],
            [8.829054, 46.03424],
            [8.855595, 46.06255],
            [8.853129, 46.076443],
            [8.807229, 46.101836],
            [8.784505, 46.094865],
            [8.757878, 46.104672],
            [8.74377, 46.123068],
            [8.715897, 46.098965],
            [8.706755, 46.082317],
            [8.721331, 46.043123],
            [8.729844, 46.030156],
            [8.720402, 46.012071],
            [8.68338, 45.987813],
            [8.588969, 45.917856],
            [8.570528, 45.895058],
            [8.593392, 45.853376],
            [8.596281, 45.834545],
            [8.582727, 45.814589],
            [8.564591, 45.808176],
            [8.553355, 45.789037],
            [8.556745, 45.775923],
            [8.583989, 45.749604],
            [8.594828, 45.731345],
            [8.647037, 45.722694],
            [8.655434, 45.708816],
            [8.641875, 45.677742],
            [8.664756, 45.675481],
            [8.689628, 45.640809],
            [8.666273, 45.63248],
            [8.704221, 45.600992],
            [8.695491, 45.576938],
            [8.70593, 45.570872],
            [8.710177, 45.544839],
            [8.720475, 45.531992],
            [8.714268, 45.520869],
            [8.728963, 45.503337],
            [8.761421, 45.497784],
            [8.791872, 45.47968],
            [8.790416, 45.46292],
            [8.811137, 45.446917],
            [8.843962, 45.394777],
            [8.811602, 45.389732],
            [8.794448, 45.3799],
            [8.766499, 45.38988],
            [8.749357, 45.375318],
            [8.747765, 45.357232],
            [8.763741, 45.351172],
            [8.727607, 45.333832],
            [8.717188, 45.303824],
            [8.698306, 45.295666],
            [8.667655, 45.29315],
            [8.656779, 45.326623],
            [8.632736, 45.339324],
            [8.629904, 45.350799],
            [8.612849, 45.355369],
            [8.579883, 45.348178],
            [8.551447, 45.355747],
            [8.527442, 45.343119],
            [8.539438, 45.320298],
            [8.508782, 45.308696],
            [8.499409, 45.293172],
            [8.517023, 45.287212],
            [8.514162, 45.276214],
            [8.532011, 45.269108],
            [8.547284, 45.242753],
            [8.52674, 45.220238],
            [8.557945, 45.206121],
            [8.545018, 45.182237],
            [8.553581, 45.161064],
            [8.581068, 45.153647],
            [8.58934, 45.136636],
            [8.612045, 45.124578],
            [8.609541, 45.109294],
            [8.636552, 45.089985],
            [8.637961, 45.067477],
            [8.648015, 45.056491],
            [8.635802, 45.041913],
            [8.672223, 45.028124],
            [8.701876, 45.027468],
            [8.752021, 45.021334],
            [8.748787, 45.010244],
            [8.776293, 45.008898],
            [8.778759, 45.024683],
            [8.803373, 45.027024],
            [8.824952, 45.047537],
            [8.882117, 45.055028],
            [8.899125, 45.051606],
            [8.905714, 45.027568],
            [8.902331, 45.008711],
            [8.926039, 44.984339],
            [8.949648, 44.98942],
            [8.979179, 44.968155],
            [8.970645, 44.944441],
            [8.98926, 44.931802],
            [9.009101, 44.903932],
            [9.031416, 44.889449],
            [9.056743, 44.889997],
            [9.071156, 44.86699],
            [9.05243, 44.846876],
            [9.082666, 44.815414],
            [9.096195, 44.819977],
            [9.110041, 44.806389],
            [9.156168, 44.810359],
            [9.174552, 44.7971],
            [9.172773, 44.772635],
            [9.195205, 44.757791],
            [9.214596, 44.7533],
            [9.203876, 44.72301],
            [9.209489, 44.70387],
            [9.201365, 44.687358],
            [9.241527, 44.68926],
            [9.258632, 44.682173],
            [9.284068, 44.686929],
            [9.299548, 44.681974],
            [9.32568, 44.690968],
            [9.309523, 44.706587],
            [9.333623, 44.735992],
            [9.286944, 44.760379],
            [9.288019, 44.773447],
            [9.317804, 44.7849],
            [9.330915, 44.80094],
            [9.359468, 44.815896],
            [9.34272, 44.87076],
            [9.29116, 44.883154],
            [9.28206, 44.896986],
            [9.295507, 44.923984],
            [9.315579, 44.937766],
            [9.34851, 44.998355],
            [9.367251, 45.004553],
            [9.377244, 45.042611],
            [9.371854, 45.049086],
            [9.390346, 45.064989],
            [9.435885, 45.082648],
            [9.440053, 45.094388],
            [9.466943, 45.095631],
            [9.502515, 45.104509],
            [9.533071, 45.079373],
            [9.551656, 45.096171],
            [9.538488, 45.115817],
            [9.549706, 45.133591],
            [9.569627, 45.108279],
            [9.602925, 45.10508],
            [9.614368, 45.114849],
            [9.619456, 45.133958],
            [9.63935, 45.125243],
            [9.632462, 45.088736],
            [9.66058, 45.083032],
            [9.713578, 45.059705],
            [9.731102, 45.064518],
            [9.754141, 45.087538],
            [9.746094, 45.106772],
            [9.767696, 45.108204],
            [9.780001, 45.090522],
            [9.811136, 45.080792],
            [9.817597, 45.055398],
            [9.836545, 45.072823],
            [9.832286, 45.083134],
            [9.845042, 45.097748],
            [9.864472, 45.093408],
            [9.88388, 45.075446],
            [9.897809, 45.081476],
            [9.901664, 45.096379],
            [9.880426, 45.112784],
            [9.882071, 45.127938],
            [9.916544, 45.139927],
            [9.927292, 45.1329],
            [9.916245, 45.101358],
            [9.937504, 45.105758],
            [9.979372, 45.134671],
            [9.993762, 45.130694],
            [10.030266, 45.095832],
            [10.02235, 45.078116],
            [10.044542, 45.064016],
            [10.058381, 45.042384],
            [10.083339, 45.047542],
            [10.085521, 45.033322],
            [10.107198, 45.023069],
            [10.148688, 45.036421],
            [10.158751, 45.045015],
            [10.194988, 45.029091],
            [10.212096, 45.034968],
            [10.261057, 45.015167],
            [10.275493, 44.998866],
            [10.305713, 44.999974],
            [10.309926, 44.988314],
            [10.348298, 44.970485],
            [10.366311, 44.967039],
            [10.384389, 44.980045],
            [10.406697, 44.98543],
            [10.417792, 44.978586],
            [10.432858, 44.948008],
            [10.50534, 44.923361],
            [10.520868, 44.913673],
            [10.567085, 44.909414],
            [10.599848, 44.915691],
            [10.634115, 44.933708],
            [10.65135, 44.949013],
            [10.664899, 44.969506],
            [10.687696, 44.987525],
            [10.703523, 44.966177],
            [10.712349, 44.983933],
            [10.739688, 44.986674],
            [10.74917, 44.963632],
            [10.744767, 44.949997],
            [10.823354, 44.937336],
            [10.877645, 44.917987],
            [10.917698, 44.92534],
            [10.942382, 44.922846],
            [10.951602, 44.934258],
            [10.968162, 44.935737],
            [10.995017, 44.954804],
            [11.060583, 44.95019],
            [11.074033, 44.963553],
            [11.125339, 44.952311],
            [11.149671, 44.935178],
            [11.242438, 44.946862],
            [11.267697, 44.944918],
            [11.302585, 44.963287],
            [11.327495, 44.963176],
            [11.358156, 44.951008],
            [11.379193, 44.958853],
            [11.427741, 44.951011],
            [11.426486, 44.961746],
            [11.332856, 44.993161],
            [11.314218, 45.010657],
            [11.2765, 45.018239],
            [11.264146, 45.034512],
            [11.271374, 45.044936],
            [11.257964, 45.054834],
            [11.236036, 45.045499],
            [11.202455, 45.06113],
            [11.175301, 45.062488],
            [11.188332, 45.083437],
            [11.178516, 45.098271],
            [11.190232, 45.110698],
            [11.139747, 45.124789],
            [11.128413, 45.104153],
            [11.10641, 45.10998],
            [11.094606, 45.096929],
            [11.073952, 45.099675],
            [11.043708, 45.112614],
            [11.029708, 45.123972],
            [11.044858, 45.135205],
            [11.029401, 45.160153],
            [11.011256, 45.149583],
            [10.99553, 45.151281],
            [10.999117, 45.185963],
            [10.9914, 45.197036],
            [10.947972, 45.206662],
            [10.931745, 45.218935],
            [10.937805, 45.233863],
            [10.904481, 45.238115],
            [10.900678, 45.247846],
            [10.847626, 45.257493],
            [10.81751, 45.295008],
            [10.783835, 45.316345],
            [10.738436, 45.28817],
            [10.729369, 45.292225],
            [10.732172, 45.316114],
            [10.713342, 45.32332],
            [10.715993, 45.335],
            [10.687786, 45.343099],
            [10.686567, 45.354608],
            [10.715064, 45.36808],
            [10.700791, 45.375854],
            [10.717865, 45.39649],
            [10.713539, 45.419472],
            [10.666258, 45.428638],
            [10.655651, 45.416745],
            [10.64404, 45.458233],
            [10.629497, 45.602974],
            [10.632072, 45.610426],
            [10.703035, 45.673651],
            [10.773014, 45.751692],
            [10.81581, 45.804955],
            [10.835578, 45.819103],
            [10.841174, 45.833668],
            [10.826414, 45.838655],
            [10.792244, 45.834368],
            [10.777973, 45.844022],
            [10.752328, 45.837475],
            [10.736584, 45.843633],
            [10.712044, 45.836974],
            [10.702875, 45.841946],
            [10.676751, 45.831373],
            [10.655673, 45.833539],
            [10.646359, 45.80504],
            [10.601519, 45.803733],
            [10.564503, 45.785026],
            [10.538832, 45.786548],
            [10.531916, 45.796775],
            [10.544302, 45.819277],
            [10.529914, 45.827466],
            [10.509233, 45.824651],
            [10.503316, 45.872058],
            [10.492028, 45.883229],
            [10.496116, 45.900728],
            [10.508728, 45.906254],
            [10.508399, 45.925883],
            [10.492285, 45.934152],
            [10.48406, 45.952838],
            [10.487644, 45.972067],
            [10.454446, 45.977463],
            [10.458133, 46.008956],
            [10.487267, 46.030459],
            [10.482411, 46.050817],
            [10.491959, 46.067717],
            [10.518226, 46.080983],
            [10.542621, 46.104175],
            [10.552205, 46.125956],
            [10.55031, 46.147332],
            [10.566284, 46.167968],
            [10.542445, 46.189517],
            [10.551845, 46.207706],
            [10.569103, 46.221087],
            [10.568691, 46.233171],
            [10.586735, 46.248842],
            [10.577133, 46.27551],
            [10.560766, 46.283952],
            [10.580618, 46.299726],
            [10.565904, 46.313914],
            [10.566664, 46.327246],
            [10.528336, 46.343141],
            [10.534856, 46.356328],
            [10.567735, 46.37867],
            [10.608424, 46.3802],
            [10.631506, 46.403448],
            [10.617256, 46.430157],
            [10.622494, 46.449258],
            [10.60155, 46.469563],
            [10.578118, 46.474249],
            [10.553, 46.492335],
            [10.500718, 46.497641],
            [10.485568, 46.494481],
            [10.45898, 46.511472],
            [10.454164, 46.531452],
          ],
        ],
      },
      properties: {
        name: "Lombardia",
        cartodb_id: 3,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.478505, 46.680727],
            [12.474567, 46.687738],
            [12.44391, 46.688987],
            [12.414874, 46.701513],
            [12.378926, 46.722791],
            [12.359934, 46.773834],
            [12.309673, 46.785678],
            [12.284543, 46.784128],
            [12.293569, 46.803354],
            [12.28344, 46.81585],
            [12.307186, 46.834799],
            [12.267503, 46.887998],
            [12.237212, 46.889373],
            [12.216041, 46.875054],
            [12.191175, 46.907093],
            [12.159918, 46.9103],
            [12.154513, 46.919025],
            [12.169217, 46.938746],
            [12.159224, 46.95152],
            [12.139086, 46.957541],
            [12.136009, 46.983734],
            [12.122042, 47.007638],
            [12.149163, 47.025225],
            [12.170933, 47.022841],
            [12.205803, 47.028746],
            [12.217751, 47.05936],
            [12.24077, 47.070609],
            [12.226903, 47.083449],
            [12.187693, 47.09264],
            [12.162025, 47.082443],
            [12.122551, 47.07545],
            [12.093997, 47.077139],
            [12.074676, 47.060594],
            [12.051052, 47.062415],
            [12.021075, 47.047618],
            [11.980919, 47.050739],
            [11.969416, 47.041465],
            [11.918388, 47.034182],
            [11.895845, 47.02113],
            [11.860783, 47.008754],
            [11.837226, 46.993755],
            [11.815749, 46.990561],
            [11.782799, 46.992917],
            [11.767798, 46.978437],
            [11.748171, 46.969762],
            [11.728259, 46.973797],
            [11.71225, 46.993883],
            [11.668175, 46.993421],
            [11.610475, 47.011646],
            [11.581797, 46.997975],
            [11.539203, 46.98497],
            [11.513619, 47.005525],
            [11.480879, 47.011857],
            [11.470082, 46.99839],
            [11.452767, 46.991448],
            [11.443112, 46.97736],
            [11.400651, 46.966956],
            [11.381787, 46.973894],
            [11.359309, 46.991223],
            [11.263793, 46.9812],
            [11.242417, 46.97048],
            [11.189933, 46.971017],
            [11.16566, 46.965104],
            [11.169359, 46.945518],
            [11.14239, 46.929795],
            [11.116037, 46.931866],
            [11.096007, 46.90834],
            [11.102576, 46.890734],
            [11.089772, 46.879503],
            [11.074306, 46.84979],
            [11.084563, 46.823728],
            [11.040823, 46.805946],
            [11.029765, 46.780466],
            [10.99446, 46.767951],
            [10.944998, 46.776004],
            [10.915976, 46.774621],
            [10.883234, 46.764057],
            [10.837813, 46.782804],
            [10.814769, 46.776408],
            [10.789622, 46.795707],
            [10.757051, 46.786011],
            [10.731522, 46.788769],
            [10.72709, 46.79861],
            [10.746841, 46.80524],
            [10.764231, 46.824348],
            [10.756723, 46.832906],
            [10.724353, 46.838249],
            [10.694807, 46.853223],
            [10.69797, 46.863543],
            [10.672475, 46.87156],
            [10.597458, 46.858161],
            [10.571537, 46.843309],
            [10.551754, 46.850761],
            [10.500007, 46.84837],
            [10.481128, 46.859576],
            [10.458336, 46.831055],
            [10.449306, 46.802235],
            [10.425705, 46.789654],
            [10.441566, 46.774616],
            [10.43635, 46.753254],
            [10.402078, 46.732954],
            [10.419365, 46.71877],
            [10.383837, 46.684349],
            [10.393271, 46.672146],
            [10.40162, 46.638603],
            [10.446984, 46.64197],
            [10.490159, 46.615892],
            [10.485108, 46.579402],
            [10.476071, 46.567618],
            [10.473602, 46.544423],
            [10.454164, 46.531452],
            [10.45898, 46.511472],
            [10.485568, 46.494481],
            [10.500718, 46.497641],
            [10.553, 46.492335],
            [10.578118, 46.474249],
            [10.60155, 46.469563],
            [10.622494, 46.449258],
            [10.617256, 46.430157],
            [10.631506, 46.403448],
            [10.608424, 46.3802],
            [10.567735, 46.37867],
            [10.534856, 46.356328],
            [10.528336, 46.343141],
            [10.566664, 46.327246],
            [10.565904, 46.313914],
            [10.580618, 46.299726],
            [10.560766, 46.283952],
            [10.577133, 46.27551],
            [10.586735, 46.248842],
            [10.568691, 46.233171],
            [10.569103, 46.221087],
            [10.551845, 46.207706],
            [10.542445, 46.189517],
            [10.566284, 46.167968],
            [10.55031, 46.147332],
            [10.552205, 46.125956],
            [10.542621, 46.104175],
            [10.518226, 46.080983],
            [10.491959, 46.067717],
            [10.482411, 46.050817],
            [10.487267, 46.030459],
            [10.458133, 46.008956],
            [10.454446, 45.977463],
            [10.487644, 45.972067],
            [10.48406, 45.952838],
            [10.492285, 45.934152],
            [10.508399, 45.925883],
            [10.508728, 45.906254],
            [10.496116, 45.900728],
            [10.492028, 45.883229],
            [10.503316, 45.872058],
            [10.509233, 45.824651],
            [10.529914, 45.827466],
            [10.544302, 45.819277],
            [10.531916, 45.796775],
            [10.538832, 45.786548],
            [10.564503, 45.785026],
            [10.601519, 45.803733],
            [10.646359, 45.80504],
            [10.655673, 45.833539],
            [10.676751, 45.831373],
            [10.702875, 45.841946],
            [10.712044, 45.836974],
            [10.736584, 45.843633],
            [10.752328, 45.837475],
            [10.777973, 45.844022],
            [10.792244, 45.834368],
            [10.826414, 45.838655],
            [10.841174, 45.833668],
            [10.884432, 45.817884],
            [10.873825, 45.789472],
            [10.885806, 45.77915],
            [10.86627, 45.762214],
            [10.84486, 45.719509],
            [10.890604, 45.715987],
            [10.919806, 45.701466],
            [10.913019, 45.689227],
            [10.938483, 45.674328],
            [10.952349, 45.685411],
            [10.969343, 45.681005],
            [10.998653, 45.697937],
            [11.008578, 45.711331],
            [11.042398, 45.70723],
            [11.058748, 45.718511],
            [11.09342, 45.696064],
            [11.139529, 45.697758],
            [11.139684, 45.710227],
            [11.175671, 45.734016],
            [11.181677, 45.763815],
            [11.174587, 45.787429],
            [11.194529, 45.794759],
            [11.189516, 45.812771],
            [11.219472, 45.844869],
            [11.240343, 45.856888],
            [11.255656, 45.892141],
            [11.262411, 45.917403],
            [11.276314, 45.920596],
            [11.325368, 45.918317],
            [11.359075, 45.926197],
            [11.359336, 45.944564],
            [11.381767, 45.943575],
            [11.373413, 45.974072],
            [11.379208, 45.985148],
            [11.401168, 45.981002],
            [11.447798, 45.981796],
            [11.469063, 45.998455],
            [11.492217, 46.009449],
            [11.539927, 46.014205],
            [11.578244, 46.007419],
            [11.588605, 45.969897],
            [11.631695, 45.97028],
            [11.62862, 45.961541],
            [11.674241, 45.96525],
            [11.691016, 45.989705],
            [11.672343, 46.015298],
            [11.667915, 46.038959],
            [11.707457, 46.046889],
            [11.687402, 46.067155],
            [11.683358, 46.090884],
            [11.70447, 46.091387],
            [11.717802, 46.103832],
            [11.751782, 46.106032],
            [11.816459, 46.105801],
            [11.894421, 46.122042],
            [11.903219, 46.138649],
            [11.922776, 46.15023],
            [11.929635, 46.176692],
            [11.963274, 46.18875],
            [11.963611, 46.20055],
            [11.940368, 46.199711],
            [11.920412, 46.221719],
            [11.925825, 46.243842],
            [11.914106, 46.253181],
            [11.887272, 46.25908],
            [11.886239, 46.279707],
            [11.863916, 46.272214],
            [11.838649, 46.271612],
            [11.827274, 46.285862],
            [11.837781, 46.303556],
            [11.832864, 46.326035],
            [11.803827, 46.334113],
            [11.801433, 46.34499],
            [11.775405, 46.359118],
            [11.805026, 46.365867],
            [11.833054, 46.388075],
            [11.851534, 46.435194],
            [11.870965, 46.434046],
            [11.889805, 46.442882],
            [11.887841, 46.465473],
            [11.875231, 46.473759],
            [11.814184, 46.479041],
            [11.816471, 46.501201],
            [11.854212, 46.519074],
            [11.890453, 46.523832],
            [11.912893, 46.53383],
            [11.9349, 46.528766],
            [11.967157, 46.545549],
            [11.988581, 46.546167],
            [11.999428, 46.533728],
            [12.050783, 46.594625],
            [12.046458, 46.608818],
            [12.065802, 46.624067],
            [12.069282, 46.675938],
            [12.107056, 46.65979],
            [12.146135, 46.634943],
            [12.172345, 46.634964],
            [12.195275, 46.620199],
            [12.194353, 46.603419],
            [12.242846, 46.617188],
            [12.262229, 46.629994],
            [12.284808, 46.618889],
            [12.306471, 46.620128],
            [12.316933, 46.630853],
            [12.340636, 46.632363],
            [12.361025, 46.620452],
            [12.390843, 46.628396],
            [12.380783, 46.643776],
            [12.406466, 46.643965],
            [12.435142, 46.669242],
            [12.478505, 46.680727],
          ],
        ],
      },
      properties: {
        name: "Trentino-Alto Adige/Sudtirol",
        cartodb_id: 4,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.732979, 46.634685],
            [12.715557, 46.64918],
            [12.691107, 46.656844],
            [12.658967, 46.657306],
            [12.643457, 46.652807],
            [12.622419, 46.661625],
            [12.571404, 46.651644],
            [12.547312, 46.660202],
            [12.515266, 46.678931],
            [12.478505, 46.680727],
            [12.435142, 46.669242],
            [12.406466, 46.643965],
            [12.380783, 46.643776],
            [12.390843, 46.628396],
            [12.361025, 46.620452],
            [12.340636, 46.632363],
            [12.316933, 46.630853],
            [12.306471, 46.620128],
            [12.284808, 46.618889],
            [12.262229, 46.629994],
            [12.242846, 46.617188],
            [12.194353, 46.603419],
            [12.195275, 46.620199],
            [12.172345, 46.634964],
            [12.146135, 46.634943],
            [12.107056, 46.65979],
            [12.069282, 46.675938],
            [12.065802, 46.624067],
            [12.046458, 46.608818],
            [12.050783, 46.594625],
            [11.999428, 46.533728],
            [11.988581, 46.546167],
            [11.967157, 46.545549],
            [11.9349, 46.528766],
            [11.912893, 46.53383],
            [11.890453, 46.523832],
            [11.854212, 46.519074],
            [11.816471, 46.501201],
            [11.814184, 46.479041],
            [11.875231, 46.473759],
            [11.887841, 46.465473],
            [11.889805, 46.442882],
            [11.870965, 46.434046],
            [11.851534, 46.435194],
            [11.833054, 46.388075],
            [11.805026, 46.365867],
            [11.775405, 46.359118],
            [11.801433, 46.34499],
            [11.803827, 46.334113],
            [11.832864, 46.326035],
            [11.837781, 46.303556],
            [11.827274, 46.285862],
            [11.838649, 46.271612],
            [11.863916, 46.272214],
            [11.886239, 46.279707],
            [11.887272, 46.25908],
            [11.914106, 46.253181],
            [11.925825, 46.243842],
            [11.920412, 46.221719],
            [11.940368, 46.199711],
            [11.963611, 46.20055],
            [11.963274, 46.18875],
            [11.929635, 46.176692],
            [11.922776, 46.15023],
            [11.903219, 46.138649],
            [11.894421, 46.122042],
            [11.816459, 46.105801],
            [11.751782, 46.106032],
            [11.717802, 46.103832],
            [11.70447, 46.091387],
            [11.683358, 46.090884],
            [11.687402, 46.067155],
            [11.707457, 46.046889],
            [11.667915, 46.038959],
            [11.672343, 46.015298],
            [11.691016, 45.989705],
            [11.674241, 45.96525],
            [11.62862, 45.961541],
            [11.631695, 45.97028],
            [11.588605, 45.969897],
            [11.578244, 46.007419],
            [11.539927, 46.014205],
            [11.492217, 46.009449],
            [11.469063, 45.998455],
            [11.447798, 45.981796],
            [11.401168, 45.981002],
            [11.379208, 45.985148],
            [11.373413, 45.974072],
            [11.381767, 45.943575],
            [11.359336, 45.944564],
            [11.359075, 45.926197],
            [11.325368, 45.918317],
            [11.276314, 45.920596],
            [11.262411, 45.917403],
            [11.255656, 45.892141],
            [11.240343, 45.856888],
            [11.219472, 45.844869],
            [11.189516, 45.812771],
            [11.194529, 45.794759],
            [11.174587, 45.787429],
            [11.181677, 45.763815],
            [11.175671, 45.734016],
            [11.139684, 45.710227],
            [11.139529, 45.697758],
            [11.09342, 45.696064],
            [11.058748, 45.718511],
            [11.042398, 45.70723],
            [11.008578, 45.711331],
            [10.998653, 45.697937],
            [10.969343, 45.681005],
            [10.952349, 45.685411],
            [10.938483, 45.674328],
            [10.913019, 45.689227],
            [10.919806, 45.701466],
            [10.890604, 45.715987],
            [10.84486, 45.719509],
            [10.86627, 45.762214],
            [10.885806, 45.77915],
            [10.873825, 45.789472],
            [10.884432, 45.817884],
            [10.841174, 45.833668],
            [10.835578, 45.819103],
            [10.81581, 45.804955],
            [10.773014, 45.751692],
            [10.703035, 45.673651],
            [10.632072, 45.610426],
            [10.629497, 45.602974],
            [10.64404, 45.458233],
            [10.655651, 45.416745],
            [10.666258, 45.428638],
            [10.713539, 45.419472],
            [10.717865, 45.39649],
            [10.700791, 45.375854],
            [10.715064, 45.36808],
            [10.686567, 45.354608],
            [10.687786, 45.343099],
            [10.715993, 45.335],
            [10.713342, 45.32332],
            [10.732172, 45.316114],
            [10.729369, 45.292225],
            [10.738436, 45.28817],
            [10.783835, 45.316345],
            [10.81751, 45.295008],
            [10.847626, 45.257493],
            [10.900678, 45.247846],
            [10.904481, 45.238115],
            [10.937805, 45.233863],
            [10.931745, 45.218935],
            [10.947972, 45.206662],
            [10.9914, 45.197036],
            [10.999117, 45.185963],
            [10.99553, 45.151281],
            [11.011256, 45.149583],
            [11.029401, 45.160153],
            [11.044858, 45.135205],
            [11.029708, 45.123972],
            [11.043708, 45.112614],
            [11.073952, 45.099675],
            [11.094606, 45.096929],
            [11.10641, 45.10998],
            [11.128413, 45.104153],
            [11.139747, 45.124789],
            [11.190232, 45.110698],
            [11.178516, 45.098271],
            [11.188332, 45.083437],
            [11.175301, 45.062488],
            [11.202455, 45.06113],
            [11.236036, 45.045499],
            [11.257964, 45.054834],
            [11.271374, 45.044936],
            [11.264146, 45.034512],
            [11.2765, 45.018239],
            [11.314218, 45.010657],
            [11.332856, 44.993161],
            [11.426486, 44.961746],
            [11.427741, 44.951011],
            [11.435075, 44.930323],
            [11.470599, 44.9373],
            [11.535127, 44.937172],
            [11.587424, 44.912266],
            [11.593078, 44.892966],
            [11.625802, 44.89015],
            [11.673837, 44.916092],
            [11.716524, 44.927263],
            [11.733924, 44.927534],
            [11.745738, 44.938052],
            [11.748028, 44.959021],
            [11.784817, 44.96511],
            [11.80571, 44.977884],
            [11.839308, 44.973712],
            [11.900548, 44.978727],
            [11.927365, 44.975715],
            [11.963666, 44.987749],
            [12.017894, 44.977385],
            [12.048472, 44.977885],
            [12.05785, 44.971533],
            [12.092547, 44.971128],
            [12.117166, 44.958594],
            [12.128945, 44.93809],
            [12.143406, 44.928825],
            [12.170126, 44.943011],
            [12.195154, 44.926272],
            [12.225952, 44.923902],
            [12.257162, 44.943449],
            [12.281848, 44.942687],
            [12.295725, 44.926291],
            [12.283164, 44.912431],
            [12.287628, 44.871227],
            [12.304812, 44.85631],
            [12.337294, 44.853159],
            [12.354257, 44.81795],
            [12.395284, 44.795363],
            [12.410436, 44.798504],
            [12.424517, 44.813936],
            [12.430013, 44.831851],
            [12.468019, 44.84821],
            [12.50585, 44.916912],
            [12.510819, 44.931598],
            [12.5528, 44.966051],
            [12.549302, 44.975152],
            [12.512122, 44.989826],
            [12.486112, 44.993871],
            [12.445121, 45.014126],
            [12.338214, 45.090438],
            [12.328243, 45.12356],
            [12.331886, 45.164622],
            [12.307236, 45.195298],
            [12.296882, 45.245376],
            [12.306996, 45.288757],
            [12.329938, 45.338439],
            [12.331665, 45.354455],
            [12.36112, 45.396996],
            [12.400555, 45.430702],
            [12.427884, 45.418876],
            [12.45378, 45.439569],
            [12.481249, 45.450296],
            [12.585277, 45.480516],
            [12.651151, 45.506355],
            [12.716434, 45.525307],
            [12.761747, 45.543255],
            [12.800145, 45.563032],
            [12.886793, 45.598218],
            [12.909338, 45.615333],
            [12.918816, 45.613532],
            [12.979116, 45.627393],
            [13.059794, 45.631668],
            [13.098651, 45.636833],
            [13.101762, 45.644287],
            [13.067881, 45.671757],
            [13.045343, 45.685205],
            [13.045939, 45.694722],
            [13.031136, 45.726159],
            [13.002166, 45.750181],
            [13.008567, 45.766523],
            [12.988929, 45.777054],
            [12.97521, 45.808486],
            [12.982294, 45.819563],
            [12.962408, 45.847763],
            [12.952351, 45.843894],
            [12.953043, 45.823097],
            [12.930474, 45.818243],
            [12.916, 45.825512],
            [12.89423, 45.820581],
            [12.870653, 45.834673],
            [12.875964, 45.851236],
            [12.837849, 45.848355],
            [12.802835, 45.823792],
            [12.805285, 45.845515],
            [12.776015, 45.853991],
            [12.743839, 45.827537],
            [12.730978, 45.838171],
            [12.679914, 45.79559],
            [12.651269, 45.804562],
            [12.648605, 45.817365],
            [12.612538, 45.832788],
            [12.59838, 45.823036],
            [12.560287, 45.82682],
            [12.559973, 45.847959],
            [12.538077, 45.869549],
            [12.533248, 45.882821],
            [12.501825, 45.926102],
            [12.464096, 45.935413],
            [12.454487, 45.948231],
            [12.43358, 45.946321],
            [12.423315, 45.956845],
            [12.429182, 45.996654],
            [12.406076, 46.043629],
            [12.42884, 46.07721],
            [12.485419, 46.105361],
            [12.49978, 46.13789],
            [12.492507, 46.157783],
            [12.457915, 46.171536],
            [12.431591, 46.210321],
            [12.408257, 46.20791],
            [12.402677, 46.22988],
            [12.377422, 46.223828],
            [12.338708, 46.240355],
            [12.321934, 46.269791],
            [12.33025, 46.284246],
            [12.351833, 46.294724],
            [12.355656, 46.320566],
            [12.380069, 46.332649],
            [12.412789, 46.332357],
            [12.429666, 46.352478],
            [12.463942, 46.370144],
            [12.461518, 46.381306],
            [12.482978, 46.404144],
            [12.499683, 46.41309],
            [12.493478, 46.42514],
            [12.506863, 46.44184],
            [12.570953, 46.476363],
            [12.607067, 46.465004],
            [12.621749, 46.476244],
            [12.66344, 46.470046],
            [12.65506, 46.485264],
            [12.631019, 46.501583],
            [12.631584, 46.513043],
            [12.676605, 46.526986],
            [12.699712, 46.541765],
            [12.717514, 46.546124],
            [12.747804, 46.54355],
            [12.733747, 46.563158],
            [12.74335, 46.57675],
            [12.736824, 46.605453],
            [12.729862, 46.61135],
            [12.732979, 46.634685],
          ],
        ],
      },
      properties: {
        name: "Veneto",
        cartodb_id: 5,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.531351, 45.788817],
            [13.58225, 45.781409],
            [13.60384, 45.772506],
            [13.632971, 45.769103],
            [13.640357, 45.760727],
            [13.692557, 45.726287],
            [13.712564, 45.704829],
            [13.720722, 45.70177],
            [13.754295, 45.681546],
            [13.77006, 45.653881],
            [13.75802, 45.647106],
            [13.753321, 45.650958],
            [13.745195, 45.640019],
            [13.766937, 45.631377],
            [13.774938, 45.613617],
            [13.787143, 45.613768],
            [13.789176, 45.613253],
            [13.814733, 45.61549],
            [13.806346, 45.608954],
            [13.787875, 45.601042],
            [13.749362, 45.609811],
            [13.720561, 45.606608],
            [13.723891, 45.595599],
            [13.748112, 45.598963],
            [13.787943, 45.583785],
            [13.81148, 45.581817],
            [13.852071, 45.585745],
            [13.880097, 45.612574],
            [13.919018, 45.632031],
            [13.90408, 45.643454],
            [13.859083, 45.666557],
            [13.832001, 45.693582],
            [13.828036, 45.715151],
            [13.812226, 45.723718],
            [13.800788, 45.740619],
            [13.784562, 45.749119],
            [13.74711, 45.756103],
            [13.70555, 45.77794],
            [13.670361, 45.800526],
            [13.628233, 45.79836],
            [13.597251, 45.808826],
            [13.588758, 45.835015],
            [13.575422, 45.843967],
            [13.578238, 45.858765],
            [13.594029, 45.876141],
            [13.591291, 45.889204],
            [13.610195, 45.900133],
            [13.61868, 45.916176],
            [13.638509, 45.936272],
            [13.634159, 45.951121],
            [13.641462, 45.970658],
            [13.63725, 45.988206],
            [13.620974, 45.984339],
            [13.591507, 45.990655],
            [13.569212, 45.968958],
            [13.527056, 45.973902],
            [13.502653, 45.981353],
            [13.477159, 46.007136],
            [13.495261, 46.01543],
            [13.510985, 46.047127],
            [13.497464, 46.059497],
            [13.530913, 46.068578],
            [13.545085, 46.083847],
            [13.574931, 46.091556],
            [13.584479, 46.101497],
            [13.647899, 46.141311],
            [13.66417, 46.180994],
            [13.633552, 46.191621],
            [13.615503, 46.185287],
            [13.586509, 46.19115],
            [13.56606, 46.18869],
            [13.562928, 46.204954],
            [13.546695, 46.212996],
            [13.503973, 46.216559],
            [13.496494, 46.223123],
            [13.458001, 46.227299],
            [13.447272, 46.21372],
            [13.424426, 46.207214],
            [13.411748, 46.213174],
            [13.423457, 46.235267],
            [13.39956, 46.254283],
            [13.392848, 46.280162],
            [13.376524, 46.299093],
            [13.403773, 46.300871],
            [13.426669, 46.323427],
            [13.448347, 46.337251],
            [13.437803, 46.35524],
            [13.448898, 46.363701],
            [13.469067, 46.361639],
            [13.482956, 46.36987],
            [13.517235, 46.377654],
            [13.539553, 46.387304],
            [13.57624, 46.409731],
            [13.579674, 46.427352],
            [13.593175, 46.437282],
            [13.64843, 46.44684],
            [13.685207, 46.438289],
            [13.699723, 46.457363],
            [13.696437, 46.469501],
            [13.707307, 46.484616],
            [13.704466, 46.502217],
            [13.717749, 46.508212],
            [13.707389, 46.52184],
            [13.677752, 46.52412],
            [13.62814, 46.54238],
            [13.592975, 46.547055],
            [13.570877, 46.539751],
            [13.564473, 46.552127],
            [13.545015, 46.554375],
            [13.520852, 46.547828],
            [13.505078, 46.566943],
            [13.474373, 46.558205],
            [13.428247, 46.5585],
            [13.409736, 46.571867],
            [13.373732, 46.580227],
            [13.364861, 46.570927],
            [13.345425, 46.570733],
            [13.321167, 46.553756],
            [13.302098, 46.559152],
            [13.253702, 46.561437],
            [13.238361, 46.554056],
            [13.21899, 46.571163],
            [13.179783, 46.579193],
            [13.169403, 46.589703],
            [13.126127, 46.592071],
            [13.084538, 46.602701],
            [13.046913, 46.596224],
            [13.022421, 46.603002],
            [12.988678, 46.600297],
            [12.929691, 46.611207],
            [12.852984, 46.60595],
            [12.834649, 46.611526],
            [12.838317, 46.628297],
            [12.803849, 46.634625],
            [12.79602, 46.643395],
            [12.757476, 46.648089],
            [12.732979, 46.634685],
            [12.729862, 46.61135],
            [12.736824, 46.605453],
            [12.74335, 46.57675],
            [12.733747, 46.563158],
            [12.747804, 46.54355],
            [12.717514, 46.546124],
            [12.699712, 46.541765],
            [12.676605, 46.526986],
            [12.631584, 46.513043],
            [12.631019, 46.501583],
            [12.65506, 46.485264],
            [12.66344, 46.470046],
            [12.621749, 46.476244],
            [12.607067, 46.465004],
            [12.570953, 46.476363],
            [12.506863, 46.44184],
            [12.493478, 46.42514],
            [12.499683, 46.41309],
            [12.482978, 46.404144],
            [12.461518, 46.381306],
            [12.463942, 46.370144],
            [12.429666, 46.352478],
            [12.412789, 46.332357],
            [12.380069, 46.332649],
            [12.355656, 46.320566],
            [12.351833, 46.294724],
            [12.33025, 46.284246],
            [12.321934, 46.269791],
            [12.338708, 46.240355],
            [12.377422, 46.223828],
            [12.402677, 46.22988],
            [12.408257, 46.20791],
            [12.431591, 46.210321],
            [12.457915, 46.171536],
            [12.492507, 46.157783],
            [12.49978, 46.13789],
            [12.485419, 46.105361],
            [12.42884, 46.07721],
            [12.406076, 46.043629],
            [12.429182, 45.996654],
            [12.423315, 45.956845],
            [12.43358, 45.946321],
            [12.454487, 45.948231],
            [12.464096, 45.935413],
            [12.501825, 45.926102],
            [12.533248, 45.882821],
            [12.538077, 45.869549],
            [12.559973, 45.847959],
            [12.560287, 45.82682],
            [12.59838, 45.823036],
            [12.612538, 45.832788],
            [12.648605, 45.817365],
            [12.651269, 45.804562],
            [12.679914, 45.79559],
            [12.730978, 45.838171],
            [12.743839, 45.827537],
            [12.776015, 45.853991],
            [12.805285, 45.845515],
            [12.802835, 45.823792],
            [12.837849, 45.848355],
            [12.875964, 45.851236],
            [12.870653, 45.834673],
            [12.89423, 45.820581],
            [12.916, 45.825512],
            [12.930474, 45.818243],
            [12.953043, 45.823097],
            [12.952351, 45.843894],
            [12.962408, 45.847763],
            [12.982294, 45.819563],
            [12.97521, 45.808486],
            [12.988929, 45.777054],
            [13.008567, 45.766523],
            [13.002166, 45.750181],
            [13.031136, 45.726159],
            [13.045939, 45.694722],
            [13.045343, 45.685205],
            [13.067881, 45.671757],
            [13.101762, 45.644287],
            [13.110281, 45.663777],
            [13.143948, 45.68656],
            [13.15771, 45.703554],
            [13.186759, 45.712047],
            [13.24326, 45.718023],
            [13.286704, 45.710602],
            [13.34615, 45.689696],
            [13.360383, 45.69337],
            [13.39037, 45.676143],
            [13.425234, 45.678128],
            [13.455116, 45.690803],
            [13.469428, 45.70447],
            [13.488376, 45.7056],
            [13.533019, 45.7246],
            [13.537596, 45.738554],
            [13.52173, 45.757871],
            [13.532059, 45.788276],
            [13.531351, 45.788817],
          ],
        ],
      },
      properties: {
        name: "Friuli Venezia Giulia",
        cartodb_id: 6,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.761786, 43.808497],
            [7.769315, 43.813428],
            [7.794961, 43.820674],
            [7.820645, 43.821137],
            [7.831441, 43.817181],
            [7.848126, 43.82978],
            [7.873174, 43.837052],
            [7.908885, 43.838245],
            [7.922053, 43.845384],
            [7.96667, 43.853077],
            [7.969796, 43.86091],
            [8.016071, 43.872998],
            [8.04163, 43.888424],
            [8.067486, 43.889848],
            [8.095257, 43.917931],
            [8.129344, 43.927889],
            [8.143108, 43.948592],
            [8.175017, 43.958923],
            [8.16244, 43.971118],
            [8.161275, 43.986352],
            [8.17987, 44.012263],
            [8.193715, 44.018245],
            [8.209397, 44.035726],
            [8.227108, 44.044988],
            [8.227822, 44.081337],
            [8.238548, 44.103159],
            [8.273634, 44.142902],
            [8.295731, 44.154422],
            [8.330125, 44.162231],
            [8.384492, 44.18159],
            [8.400126, 44.182086],
            [8.423303, 44.193543],
            [8.416903, 44.221877],
            [8.446098, 44.244487],
            [8.457383, 44.259372],
            [8.44113, 44.273384],
            [8.460431, 44.294658],
            [8.497931, 44.311111],
            [8.497093, 44.321676],
            [8.625402, 44.372826],
            [8.641254, 44.387485],
            [8.679305, 44.393616],
            [8.687362, 44.400576],
            [8.693978, 44.409312],
            [8.742371, 44.427542],
            [8.755849, 44.427984],
            [8.797334, 44.418226],
            [8.801226, 44.425398],
            [8.832047, 44.422899],
            [8.834061, 44.422659],
            [8.836163, 44.422928],
            [8.822899, 44.415884],
            [8.872419, 44.406193],
            [8.88805, 44.408338],
            [8.91752, 44.399544],
            [9.011751, 44.38587],
            [9.064235, 44.378868],
            [9.143866, 44.361485],
            [9.15613, 44.348676],
            [9.149864, 44.325595],
            [9.156567, 44.316728],
            [9.189323, 44.309854],
            [9.213432, 44.311522],
            [9.215205, 44.335664],
            [9.235095, 44.349224],
            [9.321436, 44.315427],
            [9.321593, 44.315387],
            [9.338405, 44.30908],
            [9.338889, 44.308864],
            [9.366028, 44.297921],
            [9.39164, 44.281694],
            [9.404176, 44.25624],
            [9.433754, 44.254466],
            [9.446205, 44.239761],
            [9.473432, 44.234408],
            [9.496562, 44.235652],
            [9.503655, 44.223006],
            [9.530331, 44.208432],
            [9.548935, 44.208157],
            [9.568325, 44.193223],
            [9.570012, 44.18317],
            [9.602781, 44.173201],
            [9.607616, 44.171877],
            [9.608582, 44.158499],
            [9.636513, 44.134494],
            [9.658524, 44.146342],
            [9.683375, 44.13706],
            [9.705897, 44.120545],
            [9.726667, 44.113457],
            [9.74015, 44.094364],
            [9.750743, 44.091823],
            [9.798315, 44.066142],
            [9.838362, 44.054108],
            [9.843228, 44.060324],
            [9.822506, 44.096362],
            [9.829133, 44.103145],
            [9.840675, 44.105019],
            [9.849364, 44.110971],
            [9.84955, 44.110958],
            [9.849896, 44.110933],
            [9.850385, 44.110898],
            [9.850565, 44.110883],
            [9.851053, 44.110395],
            [9.882189, 44.081943],
            [9.89824, 44.08415],
            [9.930025, 44.058966],
            [9.965259, 44.038228],
            [9.978603, 44.036449],
            [10.001653, 44.052138],
            [10.019783, 44.045428],
            [10.029702, 44.060128],
            [10.067236, 44.087126],
            [10.070278, 44.098959],
            [10.047157, 44.116432],
            [10.018902, 44.120241],
            [10.003144, 44.108612],
            [9.984615, 44.125717],
            [9.977366, 44.140508],
            [9.980031, 44.161987],
            [9.967484, 44.170392],
            [9.929142, 44.167159],
            [9.893386, 44.168981],
            [9.929142, 44.196568],
            [9.898169, 44.210877],
            [9.875429, 44.196261],
            [9.858572, 44.194467],
            [9.876378, 44.224901],
            [9.858049, 44.234988],
            [9.862178, 44.251721],
            [9.85255, 44.27182],
            [9.815808, 44.287172],
            [9.806739, 44.283761],
            [9.765204, 44.306473],
            [9.75934, 44.319309],
            [9.730482, 44.329288],
            [9.718487, 44.356291],
            [9.707329, 44.367146],
            [9.687729, 44.366897],
            [9.676008, 44.36567],
            [9.664031, 44.401616],
            [9.656319, 44.412616],
            [9.637068, 44.414859],
            [9.599202, 44.437696],
            [9.578847, 44.434551],
            [9.56021, 44.439092],
            [9.552261, 44.429039],
            [9.494936, 44.424854],
            [9.480068, 44.410216],
            [9.466806, 44.425423],
            [9.443812, 44.417545],
            [9.439838, 44.428825],
            [9.459233, 44.443161],
            [9.471271, 44.474225],
            [9.497838, 44.483686],
            [9.496567, 44.504087],
            [9.505643, 44.530915],
            [9.489216, 44.546524],
            [9.491808, 44.5599],
            [9.477572, 44.566149],
            [9.441741, 44.567672],
            [9.424114, 44.575567],
            [9.422344, 44.593558],
            [9.406897, 44.598593],
            [9.390407, 44.591174],
            [9.34119, 44.579624],
            [9.301451, 44.608732],
            [9.273272, 44.59687],
            [9.246545, 44.620603],
            [9.204017, 44.614449],
            [9.199998, 44.593732],
            [9.184564, 44.592597],
            [9.153599, 44.575178],
            [9.114014, 44.582066],
            [9.103201, 44.595071],
            [9.10553, 44.607569],
            [9.0769, 44.624655],
            [9.054728, 44.621841],
            [9.014202, 44.667747],
            [8.973565, 44.664306],
            [8.960573, 44.676977],
            [8.928935, 44.675224],
            [8.909312, 44.647781],
            [8.883745, 44.639978],
            [8.905405, 44.621784],
            [8.918552, 44.562014],
            [8.89599, 44.556329],
            [8.863955, 44.561571],
            [8.828893, 44.562409],
            [8.825448, 44.539602],
            [8.80744, 44.535558],
            [8.796158, 44.522818],
            [8.797704, 44.501071],
            [8.789086, 44.488982],
            [8.770013, 44.490621],
            [8.774291, 44.506402],
            [8.768616, 44.525926],
            [8.73382, 44.571393],
            [8.722052, 44.580826],
            [8.682474, 44.575844],
            [8.667811, 44.583418],
            [8.621225, 44.577691],
            [8.601943, 44.568786],
            [8.598872, 44.557522],
            [8.614508, 44.547565],
            [8.591216, 44.528579],
            [8.574812, 44.524288],
            [8.577217, 44.51019],
            [8.561115, 44.50381],
            [8.53584, 44.514315],
            [8.515326, 44.510845],
            [8.454069, 44.510776],
            [8.450557, 44.498353],
            [8.404959, 44.509943],
            [8.395507, 44.481196],
            [8.35955, 44.465902],
            [8.351113, 44.485777],
            [8.321864, 44.48937],
            [8.305615, 44.503614],
            [8.272375, 44.507764],
            [8.253862, 44.529725],
            [8.225226, 44.515142],
            [8.219542, 44.482793],
            [8.198391, 44.478524],
            [8.197498, 44.464573],
            [8.222898, 44.430525],
            [8.204561, 44.405863],
            [8.187568, 44.396272],
            [8.150582, 44.385763],
            [8.142545, 44.369043],
            [8.149699, 44.355782],
            [8.135346, 44.333072],
            [8.108032, 44.33379],
            [8.103019, 44.301904],
            [8.077085, 44.314931],
            [8.060803, 44.301677],
            [8.089661, 44.275272],
            [8.081729, 44.262568],
            [8.064159, 44.254049],
            [8.081681, 44.242685],
            [8.066425, 44.217912],
            [8.089023, 44.204136],
            [8.095254, 44.176384],
            [8.083763, 44.16981],
            [8.06837, 44.145666],
            [8.043975, 44.143809],
            [8.029849, 44.155069],
            [8.010507, 44.156586],
            [7.979993, 44.13452],
            [8.007695, 44.126681],
            [8.016658, 44.11168],
            [8.005694, 44.103305],
            [7.972593, 44.114074],
            [7.93826, 44.100727],
            [7.927035, 44.111276],
            [7.884992, 44.105652],
            [7.867184, 44.119135],
            [7.816376, 44.124224],
            [7.776209, 44.140952],
            [7.747091, 44.136337],
            [7.724191, 44.121798],
            [7.720363, 44.105462],
            [7.741147, 44.078306],
            [7.728839, 44.061262],
            [7.715396, 44.062541],
            [7.701756, 44.041704],
            [7.667479, 44.032239],
            [7.663784, 44.017359],
            [7.671411, 43.999212],
            [7.653162, 43.974505],
            [7.609944, 43.95703],
            [7.588863, 43.956402],
            [7.567274, 43.944677],
            [7.572917, 43.936393],
            [7.560776, 43.917541],
            [7.56371, 43.901646],
            [7.516238, 43.88482],
            [7.504544, 43.876173],
            [7.495858, 43.856945],
            [7.507844, 43.839208],
            [7.530666, 43.785245],
            [7.556806, 43.780772],
            [7.570401, 43.793222],
            [7.589403, 43.793015],
            [7.675668, 43.778323],
            [7.701059, 43.799639],
            [7.737533, 43.796463],
            [7.761786, 43.808497],
          ],
        ],
      },
      properties: {
        name: "Liguria",
        cartodb_id: 7,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.281759, 44.541182],
            [12.282799, 44.594818],
            [12.276105, 44.620657],
            [12.261408, 44.64123],
            [12.241219, 44.691931],
            [12.249284, 44.76369],
            [12.258073, 44.780805],
            [12.28695, 44.817687],
            [12.273178, 44.82157],
            [12.285754, 44.841653],
            [12.309578, 44.843232],
            [12.333982, 44.829696],
            [12.325538, 44.79756],
            [12.340984, 44.798153],
            [12.37364, 44.787749],
            [12.395284, 44.795363],
            [12.354257, 44.81795],
            [12.337294, 44.853159],
            [12.304812, 44.85631],
            [12.287628, 44.871227],
            [12.283164, 44.912431],
            [12.295725, 44.926291],
            [12.281848, 44.942687],
            [12.257162, 44.943449],
            [12.225952, 44.923902],
            [12.195154, 44.926272],
            [12.170126, 44.943011],
            [12.143406, 44.928825],
            [12.128945, 44.93809],
            [12.117166, 44.958594],
            [12.092547, 44.971128],
            [12.05785, 44.971533],
            [12.048472, 44.977885],
            [12.017894, 44.977385],
            [11.963666, 44.987749],
            [11.927365, 44.975715],
            [11.900548, 44.978727],
            [11.839308, 44.973712],
            [11.80571, 44.977884],
            [11.784817, 44.96511],
            [11.748028, 44.959021],
            [11.745738, 44.938052],
            [11.733924, 44.927534],
            [11.716524, 44.927263],
            [11.673837, 44.916092],
            [11.625802, 44.89015],
            [11.593078, 44.892966],
            [11.587424, 44.912266],
            [11.535127, 44.937172],
            [11.470599, 44.9373],
            [11.435075, 44.930323],
            [11.427741, 44.951011],
            [11.379193, 44.958853],
            [11.358156, 44.951008],
            [11.327495, 44.963176],
            [11.302585, 44.963287],
            [11.267697, 44.944918],
            [11.242438, 44.946862],
            [11.149671, 44.935178],
            [11.125339, 44.952311],
            [11.074033, 44.963553],
            [11.060583, 44.95019],
            [10.995017, 44.954804],
            [10.968162, 44.935737],
            [10.951602, 44.934258],
            [10.942382, 44.922846],
            [10.917698, 44.92534],
            [10.877645, 44.917987],
            [10.823354, 44.937336],
            [10.744767, 44.949997],
            [10.74917, 44.963632],
            [10.739688, 44.986674],
            [10.712349, 44.983933],
            [10.703523, 44.966177],
            [10.687696, 44.987525],
            [10.664899, 44.969506],
            [10.65135, 44.949013],
            [10.634115, 44.933708],
            [10.599848, 44.915691],
            [10.567085, 44.909414],
            [10.520868, 44.913673],
            [10.50534, 44.923361],
            [10.432858, 44.948008],
            [10.417792, 44.978586],
            [10.406697, 44.98543],
            [10.384389, 44.980045],
            [10.366311, 44.967039],
            [10.348298, 44.970485],
            [10.309926, 44.988314],
            [10.305713, 44.999974],
            [10.275493, 44.998866],
            [10.261057, 45.015167],
            [10.212096, 45.034968],
            [10.194988, 45.029091],
            [10.158751, 45.045015],
            [10.148688, 45.036421],
            [10.107198, 45.023069],
            [10.085521, 45.033322],
            [10.083339, 45.047542],
            [10.058381, 45.042384],
            [10.044542, 45.064016],
            [10.02235, 45.078116],
            [10.030266, 45.095832],
            [9.993762, 45.130694],
            [9.979372, 45.134671],
            [9.937504, 45.105758],
            [9.916245, 45.101358],
            [9.927292, 45.1329],
            [9.916544, 45.139927],
            [9.882071, 45.127938],
            [9.880426, 45.112784],
            [9.901664, 45.096379],
            [9.897809, 45.081476],
            [9.88388, 45.075446],
            [9.864472, 45.093408],
            [9.845042, 45.097748],
            [9.832286, 45.083134],
            [9.836545, 45.072823],
            [9.817597, 45.055398],
            [9.811136, 45.080792],
            [9.780001, 45.090522],
            [9.767696, 45.108204],
            [9.746094, 45.106772],
            [9.754141, 45.087538],
            [9.731102, 45.064518],
            [9.713578, 45.059705],
            [9.66058, 45.083032],
            [9.632462, 45.088736],
            [9.63935, 45.125243],
            [9.619456, 45.133958],
            [9.614368, 45.114849],
            [9.602925, 45.10508],
            [9.569627, 45.108279],
            [9.549706, 45.133591],
            [9.538488, 45.115817],
            [9.551656, 45.096171],
            [9.533071, 45.079373],
            [9.502515, 45.104509],
            [9.466943, 45.095631],
            [9.440053, 45.094388],
            [9.435885, 45.082648],
            [9.390346, 45.064989],
            [9.371854, 45.049086],
            [9.377244, 45.042611],
            [9.367251, 45.004553],
            [9.34851, 44.998355],
            [9.315579, 44.937766],
            [9.295507, 44.923984],
            [9.28206, 44.896986],
            [9.29116, 44.883154],
            [9.34272, 44.87076],
            [9.359468, 44.815896],
            [9.330915, 44.80094],
            [9.317804, 44.7849],
            [9.288019, 44.773447],
            [9.286944, 44.760379],
            [9.333623, 44.735992],
            [9.309523, 44.706587],
            [9.32568, 44.690968],
            [9.299548, 44.681974],
            [9.284068, 44.686929],
            [9.258632, 44.682173],
            [9.241527, 44.68926],
            [9.201365, 44.687358],
            [9.207479, 44.661159],
            [9.19972, 44.649713],
            [9.204017, 44.614449],
            [9.246545, 44.620603],
            [9.273272, 44.59687],
            [9.301451, 44.608732],
            [9.34119, 44.579624],
            [9.390407, 44.591174],
            [9.406897, 44.598593],
            [9.422344, 44.593558],
            [9.424114, 44.575567],
            [9.441741, 44.567672],
            [9.477572, 44.566149],
            [9.491808, 44.5599],
            [9.489216, 44.546524],
            [9.505643, 44.530915],
            [9.496567, 44.504087],
            [9.497838, 44.483686],
            [9.471271, 44.474225],
            [9.459233, 44.443161],
            [9.439838, 44.428825],
            [9.443812, 44.417545],
            [9.466806, 44.425423],
            [9.480068, 44.410216],
            [9.494936, 44.424854],
            [9.552261, 44.429039],
            [9.56021, 44.439092],
            [9.578847, 44.434551],
            [9.599202, 44.437696],
            [9.637068, 44.414859],
            [9.656319, 44.412616],
            [9.664031, 44.401616],
            [9.676008, 44.36567],
            [9.687729, 44.366897],
            [9.717927, 44.384131],
            [9.733102, 44.380334],
            [9.782189, 44.425895],
            [9.791507, 44.437885],
            [9.828744, 44.469229],
            [9.86343, 44.472235],
            [9.92308, 44.47324],
            [9.971736, 44.465392],
            [9.981689, 44.445996],
            [10.002041, 44.444199],
            [10.007959, 44.432687],
            [9.990307, 44.405285],
            [10.022718, 44.383888],
            [10.054198, 44.373987],
            [10.100004, 44.347121],
            [10.120811, 44.354339],
            [10.143053, 44.354821],
            [10.161995, 44.345078],
            [10.169539, 44.330345],
            [10.219159, 44.305642],
            [10.25487, 44.269535],
            [10.297789, 44.286926],
            [10.343767, 44.270585],
            [10.3726, 44.270332],
            [10.408408, 44.250261],
            [10.41504, 44.236797],
            [10.431273, 44.228389],
            [10.465945, 44.23209],
            [10.49391, 44.217953],
            [10.486222, 44.20545],
            [10.523945, 44.175793],
            [10.533262, 44.155997],
            [10.578513, 44.132855],
            [10.594107, 44.11637],
            [10.625059, 44.121321],
            [10.624756, 44.142159],
            [10.642213, 44.160789],
            [10.662622, 44.154969],
            [10.694988, 44.158604],
            [10.744934, 44.15719],
            [10.761508, 44.15116],
            [10.774259, 44.135613],
            [10.798267, 44.128035],
            [10.820147, 44.113401],
            [10.843802, 44.110582],
            [10.849664, 44.099008],
            [10.879704, 44.099572],
            [10.903798, 44.087041],
            [10.900135, 44.06743],
            [10.919246, 44.063964],
            [10.927878, 44.076432],
            [10.945138, 44.08218],
            [10.967944, 44.104211],
            [10.969487, 44.114768],
            [11.007386, 44.139784],
            [11.015923, 44.126165],
            [11.002993, 44.111866],
            [11.030101, 44.096763],
            [11.055274, 44.09245],
            [11.094213, 44.095409],
            [11.128648, 44.110629],
            [11.160193, 44.113372],
            [11.210794, 44.099815],
            [11.229343, 44.103355],
            [11.249025, 44.098522],
            [11.264652, 44.105211],
            [11.261215, 44.116791],
            [11.21935, 44.130353],
            [11.194834, 44.146559],
            [11.235629, 44.15936],
            [11.272485, 44.160151],
            [11.281702, 44.156287],
            [11.323609, 44.18319],
            [11.325852, 44.195982],
            [11.343798, 44.206765],
            [11.381532, 44.200947],
            [11.395574, 44.220716],
            [11.421429, 44.238229],
            [11.451915, 44.221064],
            [11.448613, 44.200431],
            [11.481147, 44.184732],
            [11.504355, 44.163243],
            [11.545802, 44.153554],
            [11.553429, 44.166285],
            [11.574818, 44.161506],
            [11.611795, 44.160927],
            [11.618815, 44.146659],
            [11.598271, 44.126098],
            [11.638579, 44.112444],
            [11.653533, 44.102104],
            [11.678995, 44.123125],
            [11.753616, 44.122335],
            [11.735599, 44.086406],
            [11.710406, 44.067543],
            [11.695886, 44.036287],
            [11.682866, 44.022152],
            [11.657351, 44.017893],
            [11.664505, 44.006041],
            [11.646617, 43.990328],
            [11.655908, 43.975645],
            [11.689764, 43.95997],
            [11.683356, 43.939216],
            [11.718006, 43.922768],
            [11.711599, 43.878378],
            [11.73345, 43.864118],
            [11.785433, 43.848529],
            [11.825517, 43.816439],
            [11.842644, 43.808973],
            [11.857031, 43.816852],
            [11.897394, 43.808732],
            [11.910713, 43.813669],
            [11.919756, 43.794639],
            [11.94803, 43.791824],
            [11.954348, 43.77704],
            [11.987476, 43.762884],
            [12.007358, 43.767473],
            [12.021319, 43.761513],
            [12.061361, 43.754577],
            [12.078029, 43.741608],
            [12.109517, 43.754014],
            [12.12454, 43.750101],
            [12.16501, 43.763211],
            [12.195593, 43.732875],
            [12.210057, 43.75811],
            [12.22905, 43.760546],
            [12.245072, 43.751961],
            [12.257581, 43.761643],
            [12.28474, 43.765866],
            [12.285814, 43.795743],
            [12.316846, 43.806585],
            [12.336398, 43.825066],
            [12.348277, 43.849845],
            [12.348331, 43.867966],
            [12.387189, 43.88293],
            [12.401567, 43.87372],
            [12.420969, 43.873176],
            [12.418646, 43.899993],
            [12.409234, 43.921467],
            [12.415683, 43.929692],
            [12.404565, 43.952027],
            [12.437826, 43.957566],
            [12.470843, 43.978661],
            [12.510429, 43.992145],
            [12.507514, 43.971875],
            [12.516622, 43.942476],
            [12.494902, 43.916508],
            [12.543552, 43.890026],
            [12.531257, 43.877733],
            [12.542647, 43.864972],
            [12.563407, 43.870761],
            [12.565675, 43.881597],
            [12.585355, 43.885047],
            [12.603515, 43.864379],
            [12.601954, 43.851479],
            [12.624227, 43.822096],
            [12.645416, 43.827837],
            [12.668542, 43.824471],
            [12.682268, 43.829015],
            [12.686378, 43.853155],
            [12.72335, 43.861878],
            [12.734943, 43.871202],
            [12.723604, 43.881509],
            [12.735782, 43.896967],
            [12.728859, 43.925124],
            [12.756592, 43.962895],
            [12.751573, 43.969485],
            [12.712953, 43.975944],
            [12.656652, 44.009109],
            [12.621494, 44.035905],
            [12.578262, 44.07769],
            [12.556304, 44.079543],
            [12.53014, 44.096286],
            [12.418616, 44.191026],
            [12.384488, 44.225431],
            [12.368839, 44.250346],
            [12.352369, 44.287365],
            [12.344089, 44.318709],
            [12.326119, 44.362479],
            [12.317512, 44.403823],
            [12.293092, 44.455207],
            [12.28314, 44.506619],
            [12.281759, 44.541182],
          ],
          [
            [12.225185, 43.810801],
            [12.238965, 43.801347],
            [12.238311, 43.78794],
            [12.187101, 43.77508],
            [12.1708, 43.805365],
            [12.225185, 43.810801],
          ],
        ],
      },
      properties: {
        name: "Emilia-Romagna",
        cartodb_id: 8,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [10.167211, 43.958259],
              [10.190367, 43.936502],
              [10.239133, 43.873906],
              [10.243267, 43.850043],
              [10.262632, 43.807456],
              [10.278604, 43.738483],
              [10.277559, 43.720154],
              [10.2836, 43.687388],
              [10.273563, 43.672865],
              [10.286601, 43.643155],
              [10.29665, 43.56967],
              [10.296579, 43.542337],
              [10.319925, 43.511283],
              [10.345282, 43.464868],
              [10.367108, 43.458921],
              [10.38319, 43.44679],
              [10.407471, 43.410447],
              [10.425987, 43.399352],
              [10.455781, 43.345616],
              [10.456181, 43.329602],
              [10.466065, 43.317708],
              [10.502508, 43.291817],
              [10.52924, 43.232603],
              [10.539567, 43.169274],
              [10.540444, 43.123543],
              [10.532606, 43.05351],
              [10.523108, 43.021727],
              [10.511987, 43.013746],
              [10.512707, 42.99421],
              [10.483991, 42.989374],
              [10.484852, 42.967466],
              [10.500047, 42.936938],
              [10.522169, 42.923553],
              [10.543999, 42.932693],
              [10.548114, 42.947254],
              [10.594846, 42.955259],
              [10.646065, 42.954176],
              [10.680601, 42.95012],
              [10.713272, 42.940991],
              [10.756606, 42.921924],
              [10.776432, 42.908327],
              [10.787611, 42.889209],
              [10.770406, 42.870477],
              [10.776996, 42.844696],
              [10.763805, 42.813849],
              [10.734254, 42.804476],
              [10.785573, 42.776644],
              [10.847191, 42.770056],
              [10.912786, 42.754425],
              [10.952516, 42.737876],
              [10.98365, 42.714083],
              [11.016312, 42.656439],
              [11.063559, 42.640012],
              [11.087191, 42.626808],
              [11.082692, 42.616507],
              [11.105773, 42.597823],
              [11.118151, 42.566816],
              [11.136679, 42.558898],
              [11.159138, 42.563038],
              [11.177021, 42.543161],
              [11.191388, 42.518369],
              [11.190618, 42.482399],
              [11.183431, 42.461069],
              [11.16709, 42.438625],
              [11.133079, 42.440027],
              [11.123319, 42.435696],
              [11.104632, 42.447568],
              [11.090693, 42.426805],
              [11.090706, 42.409276],
              [11.099033, 42.394243],
              [11.128168, 42.38687],
              [11.15785, 42.362653],
              [11.183616, 42.363539],
              [11.207252, 42.395897],
              [11.211896, 42.403304],
              [11.223503, 42.415072],
              [11.254227, 42.420577],
              [11.273908, 42.419607],
              [11.291805, 42.40595],
              [11.305057, 42.408485],
              [11.384013, 42.396052],
              [11.450808, 42.378528],
              [11.45304, 42.38328],
              [11.450737, 42.395027],
              [11.483047, 42.420878],
              [11.489845, 42.440357],
              [11.517531, 42.434542],
              [11.544474, 42.443108],
              [11.555462, 42.439756],
              [11.618661, 42.437025],
              [11.620856, 42.476756],
              [11.616917, 42.489624],
              [11.562192, 42.518276],
              [11.569499, 42.534452],
              [11.585307, 42.543738],
              [11.579514, 42.567194],
              [11.589561, 42.572052],
              [11.637007, 42.565908],
              [11.658123, 42.578143],
              [11.686873, 42.58582],
              [11.681005, 42.596295],
              [11.712571, 42.612009],
              [11.734083, 42.607042],
              [11.752784, 42.626575],
              [11.752335, 42.639859],
              [11.781839, 42.635662],
              [11.805729, 42.644978],
              [11.80936, 42.66073],
              [11.786493, 42.67153],
              [11.782097, 42.706304],
              [11.798035, 42.707491],
              [11.819612, 42.746749],
              [11.810081, 42.754162],
              [11.780374, 42.758994],
              [11.76298, 42.780574],
              [11.746974, 42.786736],
              [11.754304, 42.803212],
              [11.769522, 42.816385],
              [11.787351, 42.817265],
              [11.803956, 42.801946],
              [11.814653, 42.823647],
              [11.851548, 42.839657],
              [11.895922, 42.835645],
              [11.929972, 42.870263],
              [11.959608, 42.87203],
              [11.959765, 42.892619],
              [11.933674, 42.909471],
              [11.94352, 42.939247],
              [11.95238, 42.949044],
              [11.955249, 42.979714],
              [11.972891, 43.023268],
              [11.981775, 43.057337],
              [11.957544, 43.069645],
              [11.937336, 43.056599],
              [11.923461, 43.082479],
              [11.931354, 43.095262],
              [11.921878, 43.118552],
              [11.924987, 43.148745],
              [11.947449, 43.16283],
              [11.971126, 43.17014],
              [11.988434, 43.181484],
              [12.007381, 43.181491],
              [12.028446, 43.192582],
              [12.028175, 43.203402],
              [12.040609, 43.229957],
              [12.034919, 43.247666],
              [12.04987, 43.254062],
              [12.086877, 43.237297],
              [12.122779, 43.256157],
              [12.149537, 43.255579],
              [12.167385, 43.269686],
              [12.211016, 43.283688],
              [12.224661, 43.297223],
              [12.194774, 43.314034],
              [12.179126, 43.28404],
              [12.165639, 43.283239],
              [12.132799, 43.294937],
              [12.136403, 43.342689],
              [12.119726, 43.365318],
              [12.073984, 43.371374],
              [12.078791, 43.399646],
              [12.033895, 43.401185],
              [12.02617, 43.416533],
              [12.057838, 43.439213],
              [12.08125, 43.434803],
              [12.100851, 43.448748],
              [12.104984, 43.461407],
              [12.14219, 43.469886],
              [12.131919, 43.483595],
              [12.096054, 43.500122],
              [12.091852, 43.512186],
              [12.103687, 43.530889],
              [12.143058, 43.536723],
              [12.154196, 43.529668],
              [12.163621, 43.562321],
              [12.194431, 43.58892],
              [12.197835, 43.600556],
              [12.214771, 43.611826],
              [12.210869, 43.621979],
              [12.188178, 43.636992],
              [12.204651, 43.652696],
              [12.233609, 43.656553],
              [12.252038, 43.673522],
              [12.274437, 43.676915],
              [12.298169, 43.686901],
              [12.306724, 43.698889],
              [12.321726, 43.695617],
              [12.331074, 43.707994],
              [12.358281, 43.702975],
              [12.369443, 43.715804],
              [12.335532, 43.740873],
              [12.328032, 43.751959],
              [12.302674, 43.756692],
              [12.28474, 43.765866],
              [12.257581, 43.761643],
              [12.245072, 43.751961],
              [12.22905, 43.760546],
              [12.210057, 43.75811],
              [12.195593, 43.732875],
              [12.16501, 43.763211],
              [12.12454, 43.750101],
              [12.109517, 43.754014],
              [12.078029, 43.741608],
              [12.061361, 43.754577],
              [12.021319, 43.761513],
              [12.007358, 43.767473],
              [11.987476, 43.762884],
              [11.954348, 43.77704],
              [11.94803, 43.791824],
              [11.919756, 43.794639],
              [11.910713, 43.813669],
              [11.897394, 43.808732],
              [11.857031, 43.816852],
              [11.842644, 43.808973],
              [11.825517, 43.816439],
              [11.785433, 43.848529],
              [11.73345, 43.864118],
              [11.711599, 43.878378],
              [11.718006, 43.922768],
              [11.683356, 43.939216],
              [11.689764, 43.95997],
              [11.655908, 43.975645],
              [11.646617, 43.990328],
              [11.664505, 44.006041],
              [11.657351, 44.017893],
              [11.682866, 44.022152],
              [11.695886, 44.036287],
              [11.710406, 44.067543],
              [11.735599, 44.086406],
              [11.753616, 44.122335],
              [11.678995, 44.123125],
              [11.653533, 44.102104],
              [11.638579, 44.112444],
              [11.598271, 44.126098],
              [11.618815, 44.146659],
              [11.611795, 44.160927],
              [11.574818, 44.161506],
              [11.553429, 44.166285],
              [11.545802, 44.153554],
              [11.504355, 44.163243],
              [11.481147, 44.184732],
              [11.448613, 44.200431],
              [11.451915, 44.221064],
              [11.421429, 44.238229],
              [11.395574, 44.220716],
              [11.381532, 44.200947],
              [11.343798, 44.206765],
              [11.325852, 44.195982],
              [11.323609, 44.18319],
              [11.281702, 44.156287],
              [11.272485, 44.160151],
              [11.235629, 44.15936],
              [11.194834, 44.146559],
              [11.21935, 44.130353],
              [11.261215, 44.116791],
              [11.264652, 44.105211],
              [11.249025, 44.098522],
              [11.229343, 44.103355],
              [11.210794, 44.099815],
              [11.160193, 44.113372],
              [11.128648, 44.110629],
              [11.094213, 44.095409],
              [11.055274, 44.09245],
              [11.030101, 44.096763],
              [11.002993, 44.111866],
              [11.015923, 44.126165],
              [11.007386, 44.139784],
              [10.969487, 44.114768],
              [10.967944, 44.104211],
              [10.945138, 44.08218],
              [10.927878, 44.076432],
              [10.919246, 44.063964],
              [10.900135, 44.06743],
              [10.903798, 44.087041],
              [10.879704, 44.099572],
              [10.849664, 44.099008],
              [10.843802, 44.110582],
              [10.820147, 44.113401],
              [10.798267, 44.128035],
              [10.774259, 44.135613],
              [10.761508, 44.15116],
              [10.744934, 44.15719],
              [10.694988, 44.158604],
              [10.662622, 44.154969],
              [10.642213, 44.160789],
              [10.624756, 44.142159],
              [10.625059, 44.121321],
              [10.594107, 44.11637],
              [10.578513, 44.132855],
              [10.533262, 44.155997],
              [10.523945, 44.175793],
              [10.486222, 44.20545],
              [10.49391, 44.217953],
              [10.465945, 44.23209],
              [10.431273, 44.228389],
              [10.41504, 44.236797],
              [10.408408, 44.250261],
              [10.3726, 44.270332],
              [10.343767, 44.270585],
              [10.297789, 44.286926],
              [10.25487, 44.269535],
              [10.219159, 44.305642],
              [10.169539, 44.330345],
              [10.161995, 44.345078],
              [10.143053, 44.354821],
              [10.120811, 44.354339],
              [10.100004, 44.347121],
              [10.054198, 44.373987],
              [10.022718, 44.383888],
              [9.990307, 44.405285],
              [10.007959, 44.432687],
              [10.002041, 44.444199],
              [9.981689, 44.445996],
              [9.971736, 44.465392],
              [9.92308, 44.47324],
              [9.86343, 44.472235],
              [9.828744, 44.469229],
              [9.791507, 44.437885],
              [9.782189, 44.425895],
              [9.733102, 44.380334],
              [9.717927, 44.384131],
              [9.687729, 44.366897],
              [9.707329, 44.367146],
              [9.718487, 44.356291],
              [9.730482, 44.329288],
              [9.75934, 44.319309],
              [9.765204, 44.306473],
              [9.806739, 44.283761],
              [9.815808, 44.287172],
              [9.85255, 44.27182],
              [9.862178, 44.251721],
              [9.858049, 44.234988],
              [9.876378, 44.224901],
              [9.858572, 44.194467],
              [9.875429, 44.196261],
              [9.898169, 44.210877],
              [9.929142, 44.196568],
              [9.893386, 44.168981],
              [9.929142, 44.167159],
              [9.967484, 44.170392],
              [9.980031, 44.161987],
              [9.977366, 44.140508],
              [9.984615, 44.125717],
              [10.003144, 44.108612],
              [10.018902, 44.120241],
              [10.047157, 44.116432],
              [10.070278, 44.098959],
              [10.067236, 44.087126],
              [10.029702, 44.060128],
              [10.019783, 44.045428],
              [10.065145, 44.02906],
              [10.114049, 43.999149],
              [10.167211, 43.958259],
            ],
          ],
          [
            [
              [10.418679, 42.868351],
              [10.40513, 42.868455],
              [10.368354, 42.818325],
              [10.358413, 42.800318],
              [10.3407, 42.797763],
              [10.318361, 42.805631],
              [10.317157, 42.821052],
              [10.276199, 42.818495],
              [10.270395, 42.804444],
              [10.248561, 42.799962],
              [10.242092, 42.788323],
              [10.220819, 42.793946],
              [10.206534, 42.808226],
              [10.14091, 42.810295],
              [10.110379, 42.795579],
              [10.104681, 42.768606],
              [10.123232, 42.74277],
              [10.157889, 42.729808],
              [10.189129, 42.737239],
              [10.237693, 42.727038],
              [10.245875, 42.751064],
              [10.272935, 42.738781],
              [10.290976, 42.7397],
              [10.292944, 42.755045],
              [10.345598, 42.764938],
              [10.385686, 42.714978],
              [10.432647, 42.713589],
              [10.43375, 42.735883],
              [10.412, 42.74478],
              [10.402605, 42.758437],
              [10.421346, 42.773748],
              [10.434697, 42.776013],
              [10.429602, 42.817297],
              [10.444865, 42.84778],
              [10.418679, 42.868351],
            ],
          ],
          [
            [
              [10.887067, 42.390795],
              [10.883391, 42.352853],
              [10.900643, 42.331394],
              [10.918422, 42.320273],
              [10.930223, 42.326521],
              [10.922805, 42.360679],
              [10.905063, 42.384234],
              [10.887067, 42.390795],
            ],
          ],
          [
            [
              [9.83614, 43.051025],
              [9.837622, 43.066365],
              [9.826569, 43.073884],
              [9.803885, 43.051248],
              [9.797066, 43.026931],
              [9.80214, 43.007999],
              [9.842629, 43.027748],
              [9.848602, 43.046132],
              [9.83614, 43.051025],
            ],
          ],
          [
            [
              [12.225185, 43.810801],
              [12.1708, 43.805365],
              [12.187101, 43.77508],
              [12.238311, 43.78794],
              [12.238965, 43.801347],
              [12.225185, 43.810801],
            ],
          ],
          [
            [
              [10.316159, 42.35186],
              [10.295045, 42.348093],
              [10.290083, 42.327346],
              [10.298574, 42.31666],
              [10.317423, 42.314977],
              [10.333839, 42.325697],
              [10.330926, 42.341815],
              [10.316159, 42.35186],
            ],
          ],
          [
            [
              [10.094247, 42.593179],
              [10.087515, 42.604311],
              [10.053125, 42.574865],
              [10.082753, 42.572002],
              [10.101283, 42.575889],
              [10.094247, 42.593179],
            ],
          ],
        ],
      },
      properties: {
        name: "Toscana",
        cartodb_id: 9,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.190133, 42.734555],
            [13.200177, 42.749621],
            [13.194228, 42.759797],
            [13.221964, 42.773658],
            [13.246024, 42.773601],
            [13.264933, 42.809215],
            [13.248847, 42.850447],
            [13.231825, 42.854963],
            [13.212761, 42.842482],
            [13.188482, 42.835438],
            [13.15548, 42.835585],
            [13.113172, 42.890292],
            [13.081668, 42.900489],
            [13.053708, 42.920758],
            [13.025889, 42.90353],
            [13.01019, 42.904002],
            [12.991148, 42.872087],
            [12.977087, 42.870766],
            [12.969324, 42.901644],
            [12.977718, 42.926643],
            [12.950268, 42.930921],
            [12.948984, 42.942433],
            [12.930294, 42.953647],
            [12.896755, 42.965555],
            [12.9091, 42.985832],
            [12.88894, 43.010478],
            [12.907643, 43.036354],
            [12.890869, 43.059845],
            [12.898262, 43.094444],
            [12.885961, 43.100603],
            [12.876402, 43.124404],
            [12.84394, 43.123304],
            [12.833298, 43.148258],
            [12.852829, 43.154876],
            [12.863427, 43.166924],
            [12.869181, 43.19617],
            [12.863282, 43.212209],
            [12.837538, 43.218493],
            [12.82908, 43.23624],
            [12.825702, 43.267526],
            [12.806949, 43.271642],
            [12.790182, 43.285438],
            [12.800531, 43.291734],
            [12.803167, 43.308523],
            [12.79203, 43.338257],
            [12.760306, 43.365316],
            [12.747752, 43.391407],
            [12.77537, 43.40292],
            [12.780604, 43.415157],
            [12.766855, 43.426992],
            [12.768389, 43.4608],
            [12.736707, 43.464706],
            [12.71621, 43.447038],
            [12.710329, 43.426069],
            [12.664122, 43.437784],
            [12.637283, 43.431777],
            [12.626709, 43.421985],
            [12.605707, 43.438937],
            [12.565208, 43.460754],
            [12.500765, 43.522411],
            [12.481485, 43.528407],
            [12.458339, 43.518261],
            [12.434139, 43.53936],
            [12.418888, 43.535847],
            [12.399997, 43.514173],
            [12.36769, 43.53504],
            [12.351078, 43.52278],
            [12.334165, 43.524534],
            [12.313862, 43.544051],
            [12.321202, 43.554243],
            [12.345904, 43.554581],
            [12.374451, 43.58524],
            [12.362747, 43.596138],
            [12.37223, 43.612109],
            [12.352112, 43.612428],
            [12.314422, 43.603157],
            [12.306778, 43.590035],
            [12.288748, 43.59558],
            [12.26414, 43.590025],
            [12.226426, 43.594271],
            [12.214771, 43.611826],
            [12.197835, 43.600556],
            [12.194431, 43.58892],
            [12.163621, 43.562321],
            [12.154196, 43.529668],
            [12.143058, 43.536723],
            [12.103687, 43.530889],
            [12.091852, 43.512186],
            [12.096054, 43.500122],
            [12.131919, 43.483595],
            [12.14219, 43.469886],
            [12.104984, 43.461407],
            [12.100851, 43.448748],
            [12.08125, 43.434803],
            [12.057838, 43.439213],
            [12.02617, 43.416533],
            [12.033895, 43.401185],
            [12.078791, 43.399646],
            [12.073984, 43.371374],
            [12.119726, 43.365318],
            [12.136403, 43.342689],
            [12.132799, 43.294937],
            [12.165639, 43.283239],
            [12.179126, 43.28404],
            [12.194774, 43.314034],
            [12.224661, 43.297223],
            [12.211016, 43.283688],
            [12.167385, 43.269686],
            [12.149537, 43.255579],
            [12.122779, 43.256157],
            [12.086877, 43.237297],
            [12.04987, 43.254062],
            [12.034919, 43.247666],
            [12.040609, 43.229957],
            [12.028175, 43.203402],
            [12.028446, 43.192582],
            [12.007381, 43.181491],
            [11.988434, 43.181484],
            [11.971126, 43.17014],
            [11.947449, 43.16283],
            [11.924987, 43.148745],
            [11.921878, 43.118552],
            [11.931354, 43.095262],
            [11.923461, 43.082479],
            [11.937336, 43.056599],
            [11.957544, 43.069645],
            [11.981775, 43.057337],
            [11.972891, 43.023268],
            [11.955249, 42.979714],
            [11.95238, 42.949044],
            [11.94352, 42.939247],
            [11.933674, 42.909471],
            [11.959765, 42.892619],
            [11.959608, 42.87203],
            [11.929972, 42.870263],
            [11.895922, 42.835645],
            [11.905879, 42.828869],
            [11.93226, 42.779415],
            [11.980432, 42.76556],
            [11.969676, 42.735554],
            [11.950097, 42.72838],
            [11.927244, 42.705718],
            [11.949714, 42.696987],
            [11.94228, 42.684278],
            [11.978929, 42.676502],
            [12.025659, 42.652464],
            [12.040673, 42.64995],
            [12.074662, 42.657647],
            [12.095455, 42.656464],
            [12.10761, 42.665619],
            [12.12049, 42.6508],
            [12.14172, 42.657277],
            [12.161957, 42.677885],
            [12.198225, 42.664821],
            [12.244283, 42.629067],
            [12.230137, 42.611378],
            [12.246891, 42.600925],
            [12.236796, 42.584288],
            [12.239662, 42.572983],
            [12.262758, 42.560968],
            [12.275984, 42.560892],
            [12.279494, 42.542535],
            [12.26811, 42.533595],
            [12.280167, 42.50748],
            [12.290719, 42.511576],
            [12.317381, 42.489358],
            [12.32586, 42.495857],
            [12.345771, 42.485921],
            [12.352163, 42.474632],
            [12.375111, 42.482106],
            [12.391105, 42.499169],
            [12.40446, 42.500939],
            [12.428761, 42.487918],
            [12.413789, 42.479757],
            [12.418835, 42.459433],
            [12.412318, 42.446985],
            [12.423205, 42.422583],
            [12.439162, 42.427287],
            [12.467647, 42.420444],
            [12.45432, 42.396909],
            [12.482296, 42.405143],
            [12.492511, 42.398402],
            [12.512624, 42.40409],
            [12.519684, 42.368572],
            [12.532116, 42.365961],
            [12.549871, 42.383307],
            [12.568002, 42.384324],
            [12.615822, 42.415459],
            [12.612894, 42.437225],
            [12.622305, 42.469644],
            [12.636731, 42.469947],
            [12.650786, 42.444068],
            [12.675577, 42.443396],
            [12.703717, 42.461874],
            [12.725623, 42.461385],
            [12.742493, 42.471509],
            [12.717705, 42.490936],
            [12.712066, 42.501769],
            [12.728517, 42.510114],
            [12.774472, 42.514068],
            [12.774131, 42.532254],
            [12.822698, 42.537867],
            [12.848757, 42.553776],
            [12.893655, 42.564472],
            [12.89437, 42.580393],
            [12.881018, 42.603333],
            [12.897264, 42.617413],
            [12.932464, 42.604561],
            [12.938378, 42.616328],
            [12.954315, 42.619992],
            [13.005243, 42.616207],
            [13.016258, 42.632887],
            [13.059498, 42.623812],
            [13.098728, 42.637277],
            [13.131421, 42.660271],
            [13.145155, 42.647277],
            [13.175052, 42.667089],
            [13.174257, 42.688281],
            [13.191581, 42.713704],
            [13.190133, 42.734555],
          ],
        ],
      },
      properties: {
        name: "Umbria",
        cartodb_id: 10,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.500965, 43.624786],
            [13.482907, 43.607615],
            [13.458038, 43.610858],
            [13.414756, 43.624659],
            [13.376353, 43.644924],
            [13.356413, 43.649043],
            [13.308897, 43.669361],
            [13.269782, 43.68958],
            [13.124993, 43.780737],
            [13.068516, 43.822857],
            [13.022985, 43.850762],
            [13.010262, 43.853582],
            [12.963511, 43.882514],
            [12.90828, 43.923942],
            [12.893552, 43.924142],
            [12.798543, 43.966114],
            [12.751573, 43.969485],
            [12.756592, 43.962895],
            [12.728859, 43.925124],
            [12.735782, 43.896967],
            [12.723604, 43.881509],
            [12.734943, 43.871202],
            [12.72335, 43.861878],
            [12.686378, 43.853155],
            [12.682268, 43.829015],
            [12.668542, 43.824471],
            [12.645416, 43.827837],
            [12.624227, 43.822096],
            [12.601954, 43.851479],
            [12.603515, 43.864379],
            [12.585355, 43.885047],
            [12.565675, 43.881597],
            [12.563407, 43.870761],
            [12.542647, 43.864972],
            [12.531257, 43.877733],
            [12.543552, 43.890026],
            [12.494902, 43.916508],
            [12.488361, 43.897413],
            [12.452077, 43.896624],
            [12.444652, 43.905923],
            [12.418646, 43.899993],
            [12.420969, 43.873176],
            [12.401567, 43.87372],
            [12.387189, 43.88293],
            [12.348331, 43.867966],
            [12.348277, 43.849845],
            [12.336398, 43.825066],
            [12.316846, 43.806585],
            [12.285814, 43.795743],
            [12.28474, 43.765866],
            [12.302674, 43.756692],
            [12.328032, 43.751959],
            [12.335532, 43.740873],
            [12.369443, 43.715804],
            [12.358281, 43.702975],
            [12.331074, 43.707994],
            [12.321726, 43.695617],
            [12.306724, 43.698889],
            [12.298169, 43.686901],
            [12.274437, 43.676915],
            [12.252038, 43.673522],
            [12.233609, 43.656553],
            [12.204651, 43.652696],
            [12.188178, 43.636992],
            [12.210869, 43.621979],
            [12.214771, 43.611826],
            [12.226426, 43.594271],
            [12.26414, 43.590025],
            [12.288748, 43.59558],
            [12.306778, 43.590035],
            [12.314422, 43.603157],
            [12.352112, 43.612428],
            [12.37223, 43.612109],
            [12.362747, 43.596138],
            [12.374451, 43.58524],
            [12.345904, 43.554581],
            [12.321202, 43.554243],
            [12.313862, 43.544051],
            [12.334165, 43.524534],
            [12.351078, 43.52278],
            [12.36769, 43.53504],
            [12.399997, 43.514173],
            [12.418888, 43.535847],
            [12.434139, 43.53936],
            [12.458339, 43.518261],
            [12.481485, 43.528407],
            [12.500765, 43.522411],
            [12.565208, 43.460754],
            [12.605707, 43.438937],
            [12.626709, 43.421985],
            [12.637283, 43.431777],
            [12.664122, 43.437784],
            [12.710329, 43.426069],
            [12.71621, 43.447038],
            [12.736707, 43.464706],
            [12.768389, 43.4608],
            [12.766855, 43.426992],
            [12.780604, 43.415157],
            [12.77537, 43.40292],
            [12.747752, 43.391407],
            [12.760306, 43.365316],
            [12.79203, 43.338257],
            [12.803167, 43.308523],
            [12.800531, 43.291734],
            [12.790182, 43.285438],
            [12.806949, 43.271642],
            [12.825702, 43.267526],
            [12.82908, 43.23624],
            [12.837538, 43.218493],
            [12.863282, 43.212209],
            [12.869181, 43.19617],
            [12.863427, 43.166924],
            [12.852829, 43.154876],
            [12.833298, 43.148258],
            [12.84394, 43.123304],
            [12.876402, 43.124404],
            [12.885961, 43.100603],
            [12.898262, 43.094444],
            [12.890869, 43.059845],
            [12.907643, 43.036354],
            [12.88894, 43.010478],
            [12.9091, 42.985832],
            [12.896755, 42.965555],
            [12.930294, 42.953647],
            [12.948984, 42.942433],
            [12.950268, 42.930921],
            [12.977718, 42.926643],
            [12.969324, 42.901644],
            [12.977087, 42.870766],
            [12.991148, 42.872087],
            [13.01019, 42.904002],
            [13.025889, 42.90353],
            [13.053708, 42.920758],
            [13.081668, 42.900489],
            [13.113172, 42.890292],
            [13.15548, 42.835585],
            [13.188482, 42.835438],
            [13.212761, 42.842482],
            [13.231825, 42.854963],
            [13.248847, 42.850447],
            [13.264933, 42.809215],
            [13.246024, 42.773601],
            [13.221964, 42.773658],
            [13.194228, 42.759797],
            [13.200177, 42.749621],
            [13.190133, 42.734555],
            [13.255073, 42.722418],
            [13.26542, 42.74115],
            [13.288683, 42.740186],
            [13.29758, 42.730691],
            [13.31978, 42.723691],
            [13.342078, 42.702729],
            [13.358676, 42.695059],
            [13.389725, 42.688265],
            [13.428314, 42.70739],
            [13.453025, 42.734875],
            [13.489687, 42.734645],
            [13.50514, 42.748048],
            [13.503233, 42.771931],
            [13.53003, 42.796303],
            [13.535416, 42.817611],
            [13.572376, 42.801468],
            [13.602431, 42.807318],
            [13.625313, 42.817502],
            [13.63643, 42.804263],
            [13.664513, 42.808088],
            [13.700216, 42.823575],
            [13.711797, 42.844316],
            [13.725768, 42.856681],
            [13.771853, 42.859996],
            [13.800246, 42.86768],
            [13.817034, 42.878481],
            [13.905448, 42.895623],
            [13.917635, 42.895539],
            [13.8939, 42.937656],
            [13.873594, 42.988304],
            [13.85333, 43.055434],
            [13.84561, 43.097679],
            [13.804565, 43.173275],
            [13.778644, 43.23522],
            [13.736276, 43.309326],
            [13.730217, 43.314101],
            [13.708121, 43.342722],
            [13.671836, 43.424831],
            [13.645381, 43.471331],
            [13.627279, 43.497766],
            [13.621984, 43.52951],
            [13.626471, 43.551213],
            [13.5738, 43.572032],
            [13.566504, 43.590054],
            [13.527873, 43.622441],
            [13.500965, 43.624786],
          ],
        ],
      },
      properties: {
        name: "Marche",
        cartodb_id: 11,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [12.623322, 41.445588],
              [12.635505, 41.447801],
              [12.662615, 41.455503],
              [12.689642, 41.452842],
              [12.72847, 41.434106],
              [12.766407, 41.411133],
              [12.773067, 41.417116],
              [12.827995, 41.416675],
              [12.877584, 41.404627],
              [12.920986, 41.382582],
              [12.951009, 41.363339],
              [12.981157, 41.336713],
              [13.018429, 41.286752],
              [13.046451, 41.227786],
              [13.069165, 41.222722],
              [13.095424, 41.225433],
              [13.106298, 41.242218],
              [13.143904, 41.264281],
              [13.183138, 41.279232],
              [13.206689, 41.284043],
              [13.252427, 41.2842],
              [13.267296, 41.295884],
              [13.294499, 41.298416],
              [13.332802, 41.293014],
              [13.375392, 41.282301],
              [13.447395, 41.253189],
              [13.471053, 41.23845],
              [13.485802, 41.239057],
              [13.502108, 41.228685],
              [13.520091, 41.225826],
              [13.548519, 41.20849],
              [13.561058, 41.21186],
              [13.56926, 41.231912],
              [13.583554, 41.247223],
              [13.621928, 41.261716],
              [13.666002, 41.254391],
              [13.681361, 41.24535],
              [13.706935, 41.255058],
              [13.763001, 41.223592],
              [13.774653, 41.243657],
              [13.817753, 41.244678],
              [13.840695, 41.264375],
              [13.828459, 41.278449],
              [13.851947, 41.290564],
              [13.883224, 41.291935],
              [13.895624, 41.311529],
              [13.874567, 41.336616],
              [13.874506, 41.375707],
              [13.88671, 41.386446],
              [13.862688, 41.419077],
              [13.876028, 41.427406],
              [13.911747, 41.429675],
              [13.93212, 41.44114],
              [13.961673, 41.464587],
              [13.978791, 41.463475],
              [13.993663, 41.483069],
              [13.973794, 41.495024],
              [13.99592, 41.514072],
              [14.014992, 41.516895],
              [14.023046, 41.527999],
              [14.024547, 41.562018],
              [14.005386, 41.567366],
              [13.998702, 41.580415],
              [14.011062, 41.593546],
              [14.011878, 41.608523],
              [13.997555, 41.625124],
              [13.989183, 41.654816],
              [13.96825, 41.675014],
              [13.941906, 41.688956],
              [13.932428, 41.695354],
              [13.920593, 41.718226],
              [13.905347, 41.719174],
              [13.898535, 41.733115],
              [13.881478, 41.72928],
              [13.824379, 41.741866],
              [13.811031, 41.754852],
              [13.791053, 41.743956],
              [13.766808, 41.749686],
              [13.750353, 41.761569],
              [13.749546, 41.774572],
              [13.717393, 41.798381],
              [13.69361, 41.792505],
              [13.663786, 41.812252],
              [13.639362, 41.805302],
              [13.643793, 41.793855],
              [13.632167, 41.783629],
              [13.587003, 41.76742],
              [13.577126, 41.756002],
              [13.547496, 41.762592],
              [13.521337, 41.773953],
              [13.50531, 41.802528],
              [13.464659, 41.810434],
              [13.452462, 41.823805],
              [13.408368, 41.838449],
              [13.392419, 41.82077],
              [13.380426, 41.818442],
              [13.367737, 41.834055],
              [13.360742, 41.870361],
              [13.384902, 41.90517],
              [13.357454, 41.92672],
              [13.338442, 41.926963],
              [13.323527, 41.946142],
              [13.299159, 41.955249],
              [13.286944, 41.951352],
              [13.261362, 41.969746],
              [13.243752, 41.972515],
              [13.232452, 41.982671],
              [13.199994, 41.987479],
              [13.166103, 42.001116],
              [13.140861, 42.016329],
              [13.108655, 42.026087],
              [13.102238, 42.010731],
              [13.079698, 42.010113],
              [13.059037, 42.016537],
              [13.047394, 42.036299],
              [13.027538, 42.05101],
              [13.019405, 42.07592],
              [13.037925, 42.119979],
              [13.060739, 42.123935],
              [13.085756, 42.143178],
              [13.094399, 42.161629],
              [13.087975, 42.178847],
              [13.123229, 42.178098],
              [13.159224, 42.158474],
              [13.179058, 42.154349],
              [13.193625, 42.159236],
              [13.224265, 42.144317],
              [13.238137, 42.130111],
              [13.265914, 42.137767],
              [13.306202, 42.139235],
              [13.324106, 42.162388],
              [13.354875, 42.17592],
              [13.353144, 42.192256],
              [13.332802, 42.209157],
              [13.341981, 42.218371],
              [13.320848, 42.230614],
              [13.284242, 42.237698],
              [13.272157, 42.255139],
              [13.23955, 42.273801],
              [13.229847, 42.288151],
              [13.227379, 42.320305],
              [13.192268, 42.333309],
              [13.172567, 42.349704],
              [13.154267, 42.357969],
              [13.190316, 42.382085],
              [13.19012, 42.401193],
              [13.156084, 42.415188],
              [13.142897, 42.430005],
              [13.117091, 42.444881],
              [13.134296, 42.45933],
              [13.154975, 42.463303],
              [13.179341, 42.480312],
              [13.172455, 42.487053],
              [13.178692, 42.513606],
              [13.158275, 42.528688],
              [13.177709, 42.54953],
              [13.175195, 42.568484],
              [13.193192, 42.578936],
              [13.192285, 42.588484],
              [13.227708, 42.583806],
              [13.246855, 42.573674],
              [13.262465, 42.584069],
              [13.285216, 42.583241],
              [13.292176, 42.571505],
              [13.328029, 42.580904],
              [13.343742, 42.57587],
              [13.382695, 42.581982],
              [13.389921, 42.602614],
              [13.407337, 42.629764],
              [13.4092, 42.64383],
              [13.370836, 42.649934],
              [13.350601, 42.669683],
              [13.358676, 42.695059],
              [13.342078, 42.702729],
              [13.31978, 42.723691],
              [13.29758, 42.730691],
              [13.288683, 42.740186],
              [13.26542, 42.74115],
              [13.255073, 42.722418],
              [13.190133, 42.734555],
              [13.191581, 42.713704],
              [13.174257, 42.688281],
              [13.175052, 42.667089],
              [13.145155, 42.647277],
              [13.131421, 42.660271],
              [13.098728, 42.637277],
              [13.059498, 42.623812],
              [13.016258, 42.632887],
              [13.005243, 42.616207],
              [12.954315, 42.619992],
              [12.938378, 42.616328],
              [12.932464, 42.604561],
              [12.897264, 42.617413],
              [12.881018, 42.603333],
              [12.89437, 42.580393],
              [12.893655, 42.564472],
              [12.848757, 42.553776],
              [12.822698, 42.537867],
              [12.774131, 42.532254],
              [12.774472, 42.514068],
              [12.728517, 42.510114],
              [12.712066, 42.501769],
              [12.717705, 42.490936],
              [12.742493, 42.471509],
              [12.725623, 42.461385],
              [12.703717, 42.461874],
              [12.675577, 42.443396],
              [12.650786, 42.444068],
              [12.636731, 42.469947],
              [12.622305, 42.469644],
              [12.612894, 42.437225],
              [12.615822, 42.415459],
              [12.568002, 42.384324],
              [12.549871, 42.383307],
              [12.532116, 42.365961],
              [12.519684, 42.368572],
              [12.512624, 42.40409],
              [12.492511, 42.398402],
              [12.482296, 42.405143],
              [12.45432, 42.396909],
              [12.467647, 42.420444],
              [12.439162, 42.427287],
              [12.423205, 42.422583],
              [12.412318, 42.446985],
              [12.418835, 42.459433],
              [12.413789, 42.479757],
              [12.428761, 42.487918],
              [12.40446, 42.500939],
              [12.391105, 42.499169],
              [12.375111, 42.482106],
              [12.352163, 42.474632],
              [12.345771, 42.485921],
              [12.32586, 42.495857],
              [12.317381, 42.489358],
              [12.290719, 42.511576],
              [12.280167, 42.50748],
              [12.26811, 42.533595],
              [12.279494, 42.542535],
              [12.275984, 42.560892],
              [12.262758, 42.560968],
              [12.239662, 42.572983],
              [12.236796, 42.584288],
              [12.246891, 42.600925],
              [12.230137, 42.611378],
              [12.244283, 42.629067],
              [12.198225, 42.664821],
              [12.161957, 42.677885],
              [12.14172, 42.657277],
              [12.12049, 42.6508],
              [12.10761, 42.665619],
              [12.095455, 42.656464],
              [12.074662, 42.657647],
              [12.040673, 42.64995],
              [12.025659, 42.652464],
              [11.978929, 42.676502],
              [11.94228, 42.684278],
              [11.949714, 42.696987],
              [11.927244, 42.705718],
              [11.950097, 42.72838],
              [11.969676, 42.735554],
              [11.980432, 42.76556],
              [11.93226, 42.779415],
              [11.905879, 42.828869],
              [11.895922, 42.835645],
              [11.851548, 42.839657],
              [11.814653, 42.823647],
              [11.803956, 42.801946],
              [11.787351, 42.817265],
              [11.769522, 42.816385],
              [11.754304, 42.803212],
              [11.746974, 42.786736],
              [11.76298, 42.780574],
              [11.780374, 42.758994],
              [11.810081, 42.754162],
              [11.819612, 42.746749],
              [11.798035, 42.707491],
              [11.782097, 42.706304],
              [11.786493, 42.67153],
              [11.80936, 42.66073],
              [11.805729, 42.644978],
              [11.781839, 42.635662],
              [11.752335, 42.639859],
              [11.752784, 42.626575],
              [11.734083, 42.607042],
              [11.712571, 42.612009],
              [11.681005, 42.596295],
              [11.686873, 42.58582],
              [11.658123, 42.578143],
              [11.637007, 42.565908],
              [11.589561, 42.572052],
              [11.579514, 42.567194],
              [11.585307, 42.543738],
              [11.569499, 42.534452],
              [11.562192, 42.518276],
              [11.616917, 42.489624],
              [11.620856, 42.476756],
              [11.618661, 42.437025],
              [11.555462, 42.439756],
              [11.544474, 42.443108],
              [11.517531, 42.434542],
              [11.489845, 42.440357],
              [11.483047, 42.420878],
              [11.450737, 42.395027],
              [11.45304, 42.38328],
              [11.452087, 42.378235],
              [11.513307, 42.357548],
              [11.557373, 42.339378],
              [11.633426, 42.29652],
              [11.666304, 42.268579],
              [11.68133, 42.24719],
              [11.696, 42.236698],
              [11.721632, 42.191407],
              [11.740245, 42.172314],
              [11.734714, 42.159049],
              [11.755904, 42.128177],
              [11.769177, 42.120871],
              [11.770737, 42.108216],
              [11.80771, 42.081402],
              [11.821085, 42.050529],
              [11.845933, 42.03118],
              [11.880391, 42.039054],
              [11.916675, 42.040362],
              [11.964833, 42.015254],
              [11.980897, 41.99744],
              [12.00838, 41.992886],
              [12.024916, 41.984833],
              [12.050723, 41.957503],
              [12.078352, 41.947634],
              [12.091755, 41.937487],
              [12.125544, 41.927407],
              [12.141673, 41.917235],
              [12.178603, 41.874521],
              [12.204442, 41.830737],
              [12.225152, 41.78289],
              [12.220772, 41.77154],
              [12.230697, 41.753485],
              [12.226173, 41.745771],
              [12.308049, 41.718324],
              [12.353081, 41.697838],
              [12.389944, 41.676122],
              [12.438032, 41.64177],
              [12.468427, 41.61598],
              [12.534467, 41.554397],
              [12.576248, 41.509152],
              [12.607487, 41.469995],
              [12.623322, 41.445588],
            ],
          ],
          [
            [
              [12.968292, 40.92229],
              [12.949989, 40.918466],
              [12.943244, 40.901799],
              [12.959972, 40.897979],
              [12.968292, 40.92229],
            ],
          ],
        ],
      },
      properties: {
        name: "Lazio",
        cartodb_id: 12,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.780426, 42.070794],
            [14.752402, 42.081561],
            [14.718644, 42.108685],
            [14.718099, 42.171171],
            [14.621669, 42.200237],
            [14.563943, 42.2249],
            [14.509151, 42.253469],
            [14.503292, 42.272775],
            [14.44209, 42.313281],
            [14.411034, 42.348842],
            [14.410806, 42.359055],
            [14.391583, 42.37406],
            [14.37441, 42.379242],
            [14.318279, 42.407823],
            [14.261172, 42.441708],
            [14.229788, 42.467407],
            [14.206892, 42.479501],
            [14.124631, 42.550426],
            [14.076166, 42.602591],
            [14.03975, 42.65574],
            [14.026761, 42.668734],
            [13.996731, 42.709297],
            [13.975547, 42.752212],
            [13.961896, 42.77084],
            [13.941168, 42.815239],
            [13.917635, 42.895539],
            [13.905448, 42.895623],
            [13.817034, 42.878481],
            [13.800246, 42.86768],
            [13.771853, 42.859996],
            [13.725768, 42.856681],
            [13.711797, 42.844316],
            [13.700216, 42.823575],
            [13.664513, 42.808088],
            [13.63643, 42.804263],
            [13.625313, 42.817502],
            [13.602431, 42.807318],
            [13.572376, 42.801468],
            [13.535416, 42.817611],
            [13.53003, 42.796303],
            [13.503233, 42.771931],
            [13.50514, 42.748048],
            [13.489687, 42.734645],
            [13.453025, 42.734875],
            [13.428314, 42.70739],
            [13.389725, 42.688265],
            [13.358676, 42.695059],
            [13.350601, 42.669683],
            [13.370836, 42.649934],
            [13.4092, 42.64383],
            [13.407337, 42.629764],
            [13.389921, 42.602614],
            [13.382695, 42.581982],
            [13.343742, 42.57587],
            [13.328029, 42.580904],
            [13.292176, 42.571505],
            [13.285216, 42.583241],
            [13.262465, 42.584069],
            [13.246855, 42.573674],
            [13.227708, 42.583806],
            [13.192285, 42.588484],
            [13.193192, 42.578936],
            [13.175195, 42.568484],
            [13.177709, 42.54953],
            [13.158275, 42.528688],
            [13.178692, 42.513606],
            [13.172455, 42.487053],
            [13.179341, 42.480312],
            [13.154975, 42.463303],
            [13.134296, 42.45933],
            [13.117091, 42.444881],
            [13.142897, 42.430005],
            [13.156084, 42.415188],
            [13.19012, 42.401193],
            [13.190316, 42.382085],
            [13.154267, 42.357969],
            [13.172567, 42.349704],
            [13.192268, 42.333309],
            [13.227379, 42.320305],
            [13.229847, 42.288151],
            [13.23955, 42.273801],
            [13.272157, 42.255139],
            [13.284242, 42.237698],
            [13.320848, 42.230614],
            [13.341981, 42.218371],
            [13.332802, 42.209157],
            [13.353144, 42.192256],
            [13.354875, 42.17592],
            [13.324106, 42.162388],
            [13.306202, 42.139235],
            [13.265914, 42.137767],
            [13.238137, 42.130111],
            [13.224265, 42.144317],
            [13.193625, 42.159236],
            [13.179058, 42.154349],
            [13.159224, 42.158474],
            [13.123229, 42.178098],
            [13.087975, 42.178847],
            [13.094399, 42.161629],
            [13.085756, 42.143178],
            [13.060739, 42.123935],
            [13.037925, 42.119979],
            [13.019405, 42.07592],
            [13.027538, 42.05101],
            [13.047394, 42.036299],
            [13.059037, 42.016537],
            [13.079698, 42.010113],
            [13.102238, 42.010731],
            [13.108655, 42.026087],
            [13.140861, 42.016329],
            [13.166103, 42.001116],
            [13.199994, 41.987479],
            [13.232452, 41.982671],
            [13.243752, 41.972515],
            [13.261362, 41.969746],
            [13.286944, 41.951352],
            [13.299159, 41.955249],
            [13.323527, 41.946142],
            [13.338442, 41.926963],
            [13.357454, 41.92672],
            [13.384902, 41.90517],
            [13.360742, 41.870361],
            [13.367737, 41.834055],
            [13.380426, 41.818442],
            [13.392419, 41.82077],
            [13.408368, 41.838449],
            [13.452462, 41.823805],
            [13.464659, 41.810434],
            [13.50531, 41.802528],
            [13.521337, 41.773953],
            [13.547496, 41.762592],
            [13.577126, 41.756002],
            [13.587003, 41.76742],
            [13.632167, 41.783629],
            [13.643793, 41.793855],
            [13.639362, 41.805302],
            [13.663786, 41.812252],
            [13.69361, 41.792505],
            [13.717393, 41.798381],
            [13.749546, 41.774572],
            [13.750353, 41.761569],
            [13.766808, 41.749686],
            [13.791053, 41.743956],
            [13.811031, 41.754852],
            [13.824379, 41.741866],
            [13.881478, 41.72928],
            [13.898535, 41.733115],
            [13.905347, 41.719174],
            [13.920593, 41.718226],
            [13.932428, 41.695354],
            [13.941906, 41.688956],
            [13.957329, 41.696652],
            [13.995297, 41.694676],
            [14.020069, 41.683344],
            [14.027812, 41.703684],
            [14.054135, 41.702681],
            [14.054366, 41.720594],
            [14.070459, 41.738207],
            [14.089129, 41.739901],
            [14.104278, 41.719368],
            [14.124303, 41.727522],
            [14.116153, 41.737915],
            [14.159564, 41.748005],
            [14.194849, 41.749004],
            [14.202805, 41.767976],
            [14.171578, 41.780688],
            [14.165537, 41.810448],
            [14.148052, 41.828274],
            [14.156511, 41.840591],
            [14.217751, 41.854747],
            [14.22776, 41.875775],
            [14.267253, 41.896773],
            [14.280187, 41.910494],
            [14.303625, 41.895198],
            [14.325382, 41.896632],
            [14.344375, 41.869928],
            [14.376774, 41.879448],
            [14.426303, 41.854075],
            [14.446578, 41.838799],
            [14.455625, 41.796785],
            [14.46602, 41.778153],
            [14.455403, 41.765988],
            [14.490424, 41.761947],
            [14.499974, 41.773755],
            [14.534224, 41.792562],
            [14.55165, 41.817563],
            [14.568857, 41.819095],
            [14.584544, 41.841759],
            [14.59952, 41.85444],
            [14.615123, 41.890162],
            [14.6422, 41.900316],
            [14.664641, 41.917262],
            [14.671122, 41.949175],
            [14.728038, 42.004216],
            [14.766695, 42.019058],
            [14.783123, 42.036074],
            [14.762586, 42.042656],
            [14.780426, 42.070794],
          ],
        ],
      },
      properties: {
        name: "Abruzzo",
        cartodb_id: 13,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.138912, 41.927614],
            [15.09027, 41.939418],
            [15.022295, 41.985075],
            [15.00501, 41.992015],
            [14.994462, 42.005755],
            [14.916576, 42.018715],
            [14.860032, 42.035477],
            [14.780426, 42.070794],
            [14.762586, 42.042656],
            [14.783123, 42.036074],
            [14.766695, 42.019058],
            [14.728038, 42.004216],
            [14.671122, 41.949175],
            [14.664641, 41.917262],
            [14.6422, 41.900316],
            [14.615123, 41.890162],
            [14.59952, 41.85444],
            [14.584544, 41.841759],
            [14.568857, 41.819095],
            [14.55165, 41.817563],
            [14.534224, 41.792562],
            [14.499974, 41.773755],
            [14.490424, 41.761947],
            [14.455403, 41.765988],
            [14.46602, 41.778153],
            [14.455625, 41.796785],
            [14.446578, 41.838799],
            [14.426303, 41.854075],
            [14.376774, 41.879448],
            [14.344375, 41.869928],
            [14.325382, 41.896632],
            [14.303625, 41.895198],
            [14.280187, 41.910494],
            [14.267253, 41.896773],
            [14.22776, 41.875775],
            [14.217751, 41.854747],
            [14.156511, 41.840591],
            [14.148052, 41.828274],
            [14.165537, 41.810448],
            [14.171578, 41.780688],
            [14.202805, 41.767976],
            [14.194849, 41.749004],
            [14.159564, 41.748005],
            [14.116153, 41.737915],
            [14.124303, 41.727522],
            [14.104278, 41.719368],
            [14.089129, 41.739901],
            [14.070459, 41.738207],
            [14.054366, 41.720594],
            [14.054135, 41.702681],
            [14.027812, 41.703684],
            [14.020069, 41.683344],
            [13.995297, 41.694676],
            [13.957329, 41.696652],
            [13.941906, 41.688956],
            [13.96825, 41.675014],
            [13.989183, 41.654816],
            [13.997555, 41.625124],
            [14.011878, 41.608523],
            [14.011062, 41.593546],
            [13.998702, 41.580415],
            [14.005386, 41.567366],
            [14.024547, 41.562018],
            [14.023046, 41.527999],
            [14.014992, 41.516895],
            [13.99592, 41.514072],
            [13.973794, 41.495024],
            [13.993663, 41.483069],
            [13.978791, 41.463475],
            [14.006668, 41.453823],
            [14.005906, 41.425992],
            [14.01858, 41.424407],
            [14.043273, 41.393769],
            [14.085653, 41.401023],
            [14.096986, 41.391184],
            [14.108373, 41.417421],
            [14.079254, 41.448072],
            [14.126154, 41.508356],
            [14.152526, 41.483642],
            [14.168185, 41.495402],
            [14.205735, 41.50092],
            [14.242507, 41.497647],
            [14.250303, 41.485537],
            [14.278435, 41.486834],
            [14.328041, 41.454423],
            [14.343057, 41.455066],
            [14.401817, 41.438185],
            [14.413388, 41.442211],
            [14.428489, 41.430093],
            [14.456171, 41.428819],
            [14.478733, 41.411096],
            [14.48948, 41.391694],
            [14.511202, 41.382821],
            [14.523173, 41.386206],
            [14.552396, 41.380371],
            [14.567198, 41.393116],
            [14.60391, 41.364948],
            [14.629329, 41.379646],
            [14.63465, 41.388418],
            [14.672314, 41.407625],
            [14.700493, 41.411827],
            [14.718148, 41.401812],
            [14.765569, 41.418351],
            [14.774829, 41.446509],
            [14.79105, 41.453534],
            [14.806485, 41.448627],
            [14.843982, 41.427589],
            [14.868561, 41.428805],
            [14.885286, 41.445761],
            [14.911702, 41.452863],
            [14.947759, 41.457065],
            [14.983131, 41.470046],
            [15.008485, 41.487367],
            [14.967259, 41.519983],
            [14.937896, 41.528835],
            [14.939194, 41.551712],
            [14.949404, 41.57473],
            [14.947889, 41.610661],
            [14.935743, 41.622596],
            [14.956763, 41.644378],
            [14.989569, 41.638782],
            [14.992318, 41.630052],
            [15.023553, 41.622828],
            [15.026326, 41.635077],
            [15.045563, 41.641322],
            [15.049177, 41.656496],
            [15.070426, 41.661042],
            [15.106938, 41.686585],
            [15.134463, 41.698948],
            [15.144252, 41.712258],
            [15.122008, 41.721209],
            [15.103708, 41.742249],
            [15.110907, 41.754754],
            [15.098239, 41.766906],
            [15.101872, 41.782982],
            [15.116001, 41.790531],
            [15.117309, 41.815507],
            [15.101134, 41.83476],
            [15.104529, 41.847711],
            [15.12426, 41.860496],
            [15.139665, 41.880251],
            [15.132301, 41.90399],
            [15.138912, 41.927614],
          ],
        ],
      },
      properties: {
        name: "Molise",
        cartodb_id: 14,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [14.040466, 40.799319],
              [14.044743, 40.791904],
              [14.086887, 40.788185],
              [14.085412, 40.788932],
              [14.08269, 40.813225],
              [14.075989, 40.822034],
              [14.090276, 40.831771],
              [14.108566, 40.832581],
              [14.124105, 40.821798],
              [14.156668, 40.819159],
              [14.160058, 40.809028],
              [14.169256, 40.799409],
              [14.193407, 40.796321],
              [14.208485, 40.802066],
              [14.20812, 40.803161],
              [14.214755, 40.82037],
              [14.217648, 40.822828],
              [14.22156, 40.827167],
              [14.249225, 40.830054],
              [14.265317, 40.845641],
              [14.307255, 40.832232],
              [14.333005, 40.815043],
              [14.35211, 40.795172],
              [14.396375, 40.760387],
              [14.414238, 40.753494],
              [14.448004, 40.755675],
              [14.466097, 40.737325],
              [14.481235, 40.709824],
              [14.478787, 40.69672],
              [14.474116, 40.692905],
              [14.439874, 40.682839],
              [14.430486, 40.666654],
              [14.409082, 40.660217],
              [14.406455, 40.644606],
              [14.378453, 40.62994],
              [14.352659, 40.635504],
              [14.32131, 40.588811],
              [14.325388, 40.570046],
              [14.353177, 40.583085],
              [14.408898, 40.60058],
              [14.419773, 40.611294],
              [14.452549, 40.620299],
              [14.467639, 40.619831],
              [14.496288, 40.628042],
              [14.530453, 40.60822],
              [14.540133, 40.614311],
              [14.577776, 40.619267],
              [14.620756, 40.647067],
              [14.642966, 40.648268],
              [14.679387, 40.633942],
              [14.692525, 40.635303],
              [14.714697, 40.663551],
              [14.748233, 40.678727],
              [14.789806, 40.666296],
              [14.822538, 40.642641],
              [14.859321, 40.608772],
              [14.883703, 40.581043],
              [14.907607, 40.547897],
              [14.940113, 40.487389],
              [14.981046, 40.430276],
              [14.995937, 40.401657],
              [15.002186, 40.378626],
              [14.994405, 40.354611],
              [14.970522, 40.342103],
              [14.946489, 40.337889],
              [14.940162, 40.320331],
              [14.94784, 40.29626],
              [14.943732, 40.275858],
              [14.907329, 40.254962],
              [14.909871, 40.244298],
              [14.936107, 40.2296],
              [14.959515, 40.231376],
              [14.969546, 40.223044],
              [14.991742, 40.219953],
              [15.017263, 40.1977],
              [15.02594, 40.180135],
              [15.066691, 40.165403],
              [15.086222, 40.17467],
              [15.118736, 40.178262],
              [15.147169, 40.161017],
              [15.179684, 40.12829],
              [15.209978, 40.122177],
              [15.240191, 40.094324],
              [15.263694, 40.083713],
              [15.277012, 40.067778],
              [15.292528, 40.024543],
              [15.315698, 40.03289],
              [15.342933, 40.006222],
              [15.357693, 40.000125],
              [15.381336, 40.001834],
              [15.419407, 39.991608],
              [15.460932, 40.034706],
              [15.485242, 40.039372],
              [15.504129, 40.065058],
              [15.531506, 40.07484],
              [15.572599, 40.079337],
              [15.633359, 40.068417],
              [15.627669, 40.057256],
              [15.646172, 40.043969],
              [15.676995, 40.055904],
              [15.669422, 40.074256],
              [15.70666, 40.118736],
              [15.707745, 40.147498],
              [15.702569, 40.162472],
              [15.713358, 40.179447],
              [15.730584, 40.187328],
              [15.735307, 40.202678],
              [15.752038, 40.202801],
              [15.774915, 40.218345],
              [15.798014, 40.225848],
              [15.788184, 40.237635],
              [15.806202, 40.25241],
              [15.806596, 40.275527],
              [15.793117, 40.290779],
              [15.749993, 40.299736],
              [15.727712, 40.316446],
              [15.705352, 40.345017],
              [15.710956, 40.378298],
              [15.65128, 40.409249],
              [15.627159, 40.427221],
              [15.595602, 40.44184],
              [15.578763, 40.470473],
              [15.539692, 40.489048],
              [15.537029, 40.501847],
              [15.551313, 40.510242],
              [15.536146, 40.523258],
              [15.536897, 40.547479],
              [15.510969, 40.586743],
              [15.476195, 40.593449],
              [15.450314, 40.610095],
              [15.468613, 40.64023],
              [15.49294, 40.648489],
              [15.504433, 40.663935],
              [15.488037, 40.673835],
              [15.470844, 40.674165],
              [15.431011, 40.693077],
              [15.403427, 40.717328],
              [15.382321, 40.725144],
              [15.379903, 40.751923],
              [15.38987, 40.768618],
              [15.381661, 40.779645],
              [15.386405, 40.792522],
              [15.366958, 40.801478],
              [15.367304, 40.81313],
              [15.335819, 40.835871],
              [15.37918, 40.841741],
              [15.379204, 40.868659],
              [15.355743, 40.872156],
              [15.355022, 40.883644],
              [15.374276, 40.886852],
              [15.384797, 40.877317],
              [15.403581, 40.882603],
              [15.445575, 40.87781],
              [15.461412, 40.868105],
              [15.473987, 40.875929],
              [15.468477, 40.89059],
              [15.527578, 40.908575],
              [15.54313, 40.947384],
              [15.567462, 40.969767],
              [15.57288, 40.999863],
              [15.543717, 41.056839],
              [15.490335, 41.071016],
              [15.479199, 41.079647],
              [15.446945, 41.079002],
              [15.428339, 41.093807],
              [15.397223, 41.107799],
              [15.368304, 41.085865],
              [15.352467, 41.095751],
              [15.315209, 41.104871],
              [15.298763, 41.094297],
              [15.268041, 41.107626],
              [15.236517, 41.140868],
              [15.211643, 41.149656],
              [15.2102, 41.168672],
              [15.228355, 41.185914],
              [15.258236, 41.198383],
              [15.24703, 41.237095],
              [15.280698, 41.241778],
              [15.276513, 41.250519],
              [15.247902, 41.272346],
              [15.217139, 41.276219],
              [15.20186, 41.287255],
              [15.151057, 41.28372],
              [15.137152, 41.287863],
              [15.127629, 41.303935],
              [15.131419, 41.318168],
              [15.100791, 41.328687],
              [15.073051, 41.333447],
              [15.059332, 41.373117],
              [15.082412, 41.388715],
              [15.099641, 41.434749],
              [15.077112, 41.433758],
              [15.033306, 41.453665],
              [15.024753, 41.477928],
              [15.008485, 41.487367],
              [14.983131, 41.470046],
              [14.947759, 41.457065],
              [14.911702, 41.452863],
              [14.885286, 41.445761],
              [14.868561, 41.428805],
              [14.843982, 41.427589],
              [14.806485, 41.448627],
              [14.79105, 41.453534],
              [14.774829, 41.446509],
              [14.765569, 41.418351],
              [14.718148, 41.401812],
              [14.700493, 41.411827],
              [14.672314, 41.407625],
              [14.63465, 41.388418],
              [14.629329, 41.379646],
              [14.60391, 41.364948],
              [14.567198, 41.393116],
              [14.552396, 41.380371],
              [14.523173, 41.386206],
              [14.511202, 41.382821],
              [14.48948, 41.391694],
              [14.478733, 41.411096],
              [14.456171, 41.428819],
              [14.428489, 41.430093],
              [14.413388, 41.442211],
              [14.401817, 41.438185],
              [14.343057, 41.455066],
              [14.328041, 41.454423],
              [14.278435, 41.486834],
              [14.250303, 41.485537],
              [14.242507, 41.497647],
              [14.205735, 41.50092],
              [14.168185, 41.495402],
              [14.152526, 41.483642],
              [14.126154, 41.508356],
              [14.079254, 41.448072],
              [14.108373, 41.417421],
              [14.096986, 41.391184],
              [14.085653, 41.401023],
              [14.043273, 41.393769],
              [14.01858, 41.424407],
              [14.005906, 41.425992],
              [14.006668, 41.453823],
              [13.978791, 41.463475],
              [13.961673, 41.464587],
              [13.93212, 41.44114],
              [13.911747, 41.429675],
              [13.876028, 41.427406],
              [13.862688, 41.419077],
              [13.88671, 41.386446],
              [13.874506, 41.375707],
              [13.874567, 41.336616],
              [13.895624, 41.311529],
              [13.883224, 41.291935],
              [13.851947, 41.290564],
              [13.828459, 41.278449],
              [13.840695, 41.264375],
              [13.817753, 41.244678],
              [13.774653, 41.243657],
              [13.763001, 41.223592],
              [13.814768, 41.18549],
              [13.889291, 41.100374],
              [13.903182, 41.077273],
              [13.923357, 41.027534],
              [13.978918, 40.9776],
              [13.997625, 40.957267],
              [14.033448, 40.899985],
              [14.045699, 40.86584],
              [14.048879, 40.836639],
              [14.040466, 40.799319],
            ],
          ],
          [
            [
              [13.872763, 40.761839],
              [13.859448, 40.739065],
              [13.862087, 40.718406],
              [13.852139, 40.711533],
              [13.874541, 40.697915],
              [13.906084, 40.702791],
              [13.92435, 40.698402],
              [13.966166, 40.713701],
              [13.95595, 40.741449],
              [13.925008, 40.750936],
              [13.908688, 40.749881],
              [13.872763, 40.761839],
            ],
          ],
          [
            [
              [14.261111, 40.562461],
              [14.241347, 40.556751],
              [14.213941, 40.562999],
              [14.199042, 40.560373],
              [14.198814, 40.537813],
              [14.253328, 40.54276],
              [14.261111, 40.562461],
            ],
          ],
        ],
      },
      properties: {
        name: "Campania",
        cartodb_id: 15,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.651347, 41.192783],
            [16.626966, 41.195806],
            [16.582908, 41.209198],
            [16.572156, 41.219623],
            [16.54532, 41.229283],
            [16.532659, 41.23972],
            [16.454004, 41.26076],
            [16.422597, 41.281179],
            [16.387953, 41.29121],
            [16.359887, 41.305685],
            [16.267366, 41.327894],
            [16.246191, 41.337121],
            [16.207868, 41.360399],
            [16.16952, 41.370377],
            [16.042165, 41.42234],
            [16.006387, 41.434392],
            [15.981407, 41.44671],
            [15.946145, 41.472289],
            [15.919091, 41.506501],
            [15.905405, 41.533434],
            [15.895074, 41.576532],
            [15.904136, 41.61916],
            [15.944363, 41.643449],
            [16.020357, 41.673087],
            [16.066222, 41.696675],
            [16.074998, 41.712033],
            [16.09078, 41.716145],
            [16.105754, 41.730707],
            [16.140878, 41.741877],
            [16.164108, 41.755577],
            [16.170029, 41.768214],
            [16.187443, 41.773087],
            [16.19857, 41.804557],
            [16.195941, 41.823121],
            [16.177527, 41.856077],
            [16.177304, 41.885786],
            [16.162825, 41.891381],
            [16.154122, 41.909488],
            [16.118987, 41.917528],
            [16.090596, 41.939632],
            [16.063025, 41.94613],
            [16.024869, 41.946667],
            [16.012995, 41.951404],
            [15.988616, 41.944706],
            [15.925413, 41.934101],
            [15.750821, 41.917484],
            [15.687385, 41.915393],
            [15.643194, 41.9276],
            [15.623903, 41.9284],
            [15.525687, 41.912855],
            [15.459127, 41.904662],
            [15.392573, 41.901593],
            [15.364254, 41.904176],
            [15.336783, 41.917697],
            [15.294141, 41.921296],
            [15.20867, 41.920174],
            [15.138912, 41.927614],
            [15.132301, 41.90399],
            [15.139665, 41.880251],
            [15.12426, 41.860496],
            [15.104529, 41.847711],
            [15.101134, 41.83476],
            [15.117309, 41.815507],
            [15.116001, 41.790531],
            [15.101872, 41.782982],
            [15.098239, 41.766906],
            [15.110907, 41.754754],
            [15.103708, 41.742249],
            [15.122008, 41.721209],
            [15.144252, 41.712258],
            [15.134463, 41.698948],
            [15.106938, 41.686585],
            [15.070426, 41.661042],
            [15.049177, 41.656496],
            [15.045563, 41.641322],
            [15.026326, 41.635077],
            [15.023553, 41.622828],
            [14.992318, 41.630052],
            [14.989569, 41.638782],
            [14.956763, 41.644378],
            [14.935743, 41.622596],
            [14.947889, 41.610661],
            [14.949404, 41.57473],
            [14.939194, 41.551712],
            [14.937896, 41.528835],
            [14.967259, 41.519983],
            [15.008485, 41.487367],
            [15.024753, 41.477928],
            [15.033306, 41.453665],
            [15.077112, 41.433758],
            [15.099641, 41.434749],
            [15.082412, 41.388715],
            [15.059332, 41.373117],
            [15.073051, 41.333447],
            [15.100791, 41.328687],
            [15.131419, 41.318168],
            [15.127629, 41.303935],
            [15.137152, 41.287863],
            [15.151057, 41.28372],
            [15.20186, 41.287255],
            [15.217139, 41.276219],
            [15.247902, 41.272346],
            [15.276513, 41.250519],
            [15.280698, 41.241778],
            [15.24703, 41.237095],
            [15.258236, 41.198383],
            [15.228355, 41.185914],
            [15.2102, 41.168672],
            [15.211643, 41.149656],
            [15.236517, 41.140868],
            [15.268041, 41.107626],
            [15.298763, 41.094297],
            [15.315209, 41.104871],
            [15.352467, 41.095751],
            [15.368304, 41.085865],
            [15.397223, 41.107799],
            [15.428339, 41.093807],
            [15.446945, 41.079002],
            [15.479199, 41.079647],
            [15.490335, 41.071016],
            [15.543717, 41.056839],
            [15.543846, 41.067945],
            [15.559756, 41.088371],
            [15.588398, 41.09936],
            [15.61826, 41.100242],
            [15.647399, 41.090606],
            [15.680257, 41.087782],
            [15.717932, 41.090663],
            [15.731227, 41.100013],
            [15.753439, 41.09832],
            [15.769242, 41.084173],
            [15.799574, 41.092966],
            [15.806212, 41.111871],
            [15.881501, 41.137817],
            [15.921807, 41.112132],
            [15.954222, 41.11179],
            [15.97709, 41.095148],
            [15.999694, 41.054972],
            [16.008085, 41.060088],
            [16.041373, 41.037347],
            [16.028769, 41.025063],
            [16.032818, 40.999367],
            [16.003689, 40.971193],
            [15.972646, 40.973115],
            [15.977463, 40.959437],
            [16.046553, 40.938013],
            [16.059811, 40.929882],
            [16.094469, 40.921252],
            [16.122128, 40.900223],
            [16.138898, 40.919337],
            [16.163801, 40.929563],
            [16.203293, 40.918515],
            [16.224633, 40.887569],
            [16.232026, 40.855871],
            [16.25659, 40.824564],
            [16.279056, 40.802591],
            [16.317051, 40.784091],
            [16.350899, 40.749883],
            [16.381212, 40.737384],
            [16.400974, 40.708392],
            [16.43133, 40.705741],
            [16.480499, 40.737784],
            [16.496897, 40.75927],
            [16.52337, 40.747434],
            [16.537686, 40.753885],
            [16.567784, 40.755444],
            [16.575426, 40.764031],
            [16.608755, 40.752815],
            [16.633637, 40.754554],
            [16.678325, 40.737128],
            [16.725723, 40.714817],
            [16.697442, 40.703068],
            [16.726471, 40.678586],
            [16.710572, 40.633819],
            [16.713434, 40.616619],
            [16.724776, 40.603445],
            [16.7085, 40.564363],
            [16.706501, 40.551178],
            [16.725191, 40.53628],
            [16.716633, 40.525236],
            [16.737884, 40.504816],
            [16.720401, 40.4824],
            [16.736404, 40.468913],
            [16.760635, 40.463744],
            [16.793416, 40.462941],
            [16.810503, 40.447927],
            [16.810191, 40.434063],
            [16.851485, 40.402229],
            [16.867328, 40.399067],
            [16.902432, 40.436476],
            [16.959199, 40.476292],
            [16.996071, 40.495924],
            [17.045802, 40.513359],
            [17.085855, 40.520738],
            [17.129483, 40.518319],
            [17.163442, 40.507096],
            [17.182633, 40.482427],
            [17.220967, 40.480326],
            [17.247532, 40.463121],
            [17.256466, 40.443718],
            [17.230347, 40.424793],
            [17.212222, 40.423728],
            [17.232513, 40.402575],
            [17.268463, 40.391589],
            [17.280065, 40.380129],
            [17.294772, 40.379445],
            [17.312092, 40.367323],
            [17.340819, 40.362886],
            [17.37593, 40.349569],
            [17.402922, 40.333295],
            [17.427986, 40.333466],
            [17.452237, 40.326998],
            [17.458827, 40.317965],
            [17.495557, 40.310944],
            [17.509336, 40.295721],
            [17.63825, 40.304137],
            [17.679282, 40.305701],
            [17.757732, 40.298569],
            [17.817148, 40.290578],
            [17.840167, 40.28156],
            [17.856663, 40.286603],
            [17.9053, 40.257189],
            [17.908264, 40.24268],
            [17.924986, 40.218519],
            [17.920591, 40.193122],
            [17.964365, 40.148689],
            [17.974886, 40.148096],
            [18.011655, 40.107835],
            [18.011787, 40.087047],
            [17.998439, 40.051649],
            [18.019925, 40.028111],
            [18.018417, 40.008007],
            [17.995622, 39.996211],
            [18.048338, 39.929777],
            [18.069059, 39.919765],
            [18.07777, 39.908025],
            [18.12152, 39.885666],
            [18.151991, 39.862211],
            [18.205513, 39.839538],
            [18.27685, 39.831862],
            [18.327842, 39.808363],
            [18.346935, 39.792456],
            [18.369758, 39.794756],
            [18.390111, 39.816636],
            [18.386682, 39.832114],
            [18.3998, 39.890072],
            [18.390943, 39.906839],
            [18.409041, 39.978828],
            [18.417438, 39.994972],
            [18.43243, 40.001586],
            [18.436373, 40.025571],
            [18.47521, 40.044671],
            [18.479892, 40.062785],
            [18.485446, 40.078455],
            [18.520959, 40.108748],
            [18.507573, 40.125698],
            [18.517643, 40.136513],
            [18.504008, 40.148023],
            [18.490347, 40.148234],
            [18.479119, 40.180173],
            [18.464421, 40.193904],
            [18.459194, 40.212781],
            [18.461946, 40.233324],
            [18.445078, 40.257413],
            [18.431186, 40.276151],
            [18.428043, 40.289277],
            [18.421327, 40.296409],
            [18.42091, 40.29682],
            [18.419817, 40.29662],
            [18.410072, 40.299342],
            [18.38083, 40.323287],
            [18.35946, 40.346607],
            [18.318026, 40.372989],
            [18.306303, 40.393014],
            [18.279736, 40.408294],
            [18.249883, 40.438367],
            [18.203791, 40.468069],
            [18.18659, 40.470283],
            [18.170371, 40.481652],
            [18.12745, 40.49412],
            [18.109954, 40.510793],
            [18.079531, 40.528696],
            [18.075515, 40.540512],
            [18.043054, 40.559542],
            [18.036032, 40.575917],
            [18.045921, 40.598154],
            [18.018925, 40.622953],
            [18.015797, 40.638697],
            [17.999398, 40.650009],
            [17.980097, 40.647549],
            [17.968544, 40.657963],
            [17.967804, 40.656696],
            [17.953775, 40.665104],
            [17.942738, 40.68298],
            [17.92599, 40.680487],
            [17.874861, 40.685826],
            [17.828858, 40.696063],
            [17.747054, 40.734827],
            [17.723494, 40.751969],
            [17.682324, 40.768197],
            [17.624782, 40.784288],
            [17.597432, 40.788976],
            [17.551343, 40.802619],
            [17.473611, 40.833191],
            [17.469206, 40.843836],
            [17.415633, 40.871654],
            [17.386035, 40.895476],
            [17.350917, 40.906713],
            [17.327614, 40.937385],
            [17.279923, 40.972626],
            [17.237064, 40.994767],
            [17.211859, 41.000747],
            [17.176217, 41.030075],
            [17.143216, 41.037911],
            [17.08884, 41.065858],
            [17.047067, 41.081339],
            [17.008832, 41.086759],
            [16.964634, 41.097812],
            [16.891291, 41.119748],
            [16.84527, 41.140875],
            [16.829059, 41.13779],
            [16.793522, 41.148881],
            [16.780135, 41.160588],
            [16.699914, 41.177778],
            [16.676713, 41.188481],
            [16.651347, 41.192783],
          ],
        ],
      },
      properties: {
        name: "Puglia",
        cartodb_id: 16,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.724363, 39.969296],
            [15.732756, 39.965087],
            [15.743427, 39.935385],
            [15.756717, 39.924553],
            [15.76668, 39.931611],
            [15.772018, 39.956607],
            [15.795944, 39.972662],
            [15.820876, 40.002874],
            [15.851059, 40.007038],
            [15.868486, 39.991132],
            [15.891137, 39.995517],
            [15.89884, 39.982688],
            [15.928843, 40.002076],
            [15.969339, 39.992739],
            [15.980026, 39.983405],
            [16.031335, 39.994447],
            [16.007699, 39.957941],
            [16.017478, 39.928108],
            [16.038495, 39.917173],
            [16.054318, 39.899147],
            [16.083865, 39.89762],
            [16.104137, 39.907927],
            [16.13879, 39.902418],
            [16.159773, 39.920408],
            [16.215507, 39.896984],
            [16.21735, 39.928831],
            [16.238815, 39.926473],
            [16.269246, 39.936966],
            [16.28323, 39.9319],
            [16.314748, 39.932046],
            [16.344041, 39.903437],
            [16.35597, 39.90211],
            [16.360476, 39.920607],
            [16.338616, 39.937178],
            [16.3589, 39.955102],
            [16.361086, 39.969043],
            [16.391826, 40.004308],
            [16.399244, 40.019968],
            [16.395201, 40.050942],
            [16.415054, 40.081684],
            [16.414622, 40.110744],
            [16.409763, 40.121956],
            [16.452798, 40.135801],
            [16.471586, 40.135078],
            [16.495483, 40.126076],
            [16.518786, 40.129775],
            [16.530822, 40.119092],
            [16.56267, 40.130877],
            [16.588026, 40.134116],
            [16.628579, 40.130725],
            [16.644681, 40.120036],
            [16.684889, 40.145212],
            [16.732082, 40.203706],
            [16.750289, 40.246788],
            [16.787172, 40.30373],
            [16.850692, 40.374541],
            [16.867328, 40.399067],
            [16.851485, 40.402229],
            [16.810191, 40.434063],
            [16.810503, 40.447927],
            [16.793416, 40.462941],
            [16.760635, 40.463744],
            [16.736404, 40.468913],
            [16.720401, 40.4824],
            [16.737884, 40.504816],
            [16.716633, 40.525236],
            [16.725191, 40.53628],
            [16.706501, 40.551178],
            [16.7085, 40.564363],
            [16.724776, 40.603445],
            [16.713434, 40.616619],
            [16.710572, 40.633819],
            [16.726471, 40.678586],
            [16.697442, 40.703068],
            [16.725723, 40.714817],
            [16.678325, 40.737128],
            [16.633637, 40.754554],
            [16.608755, 40.752815],
            [16.575426, 40.764031],
            [16.567784, 40.755444],
            [16.537686, 40.753885],
            [16.52337, 40.747434],
            [16.496897, 40.75927],
            [16.480499, 40.737784],
            [16.43133, 40.705741],
            [16.400974, 40.708392],
            [16.381212, 40.737384],
            [16.350899, 40.749883],
            [16.317051, 40.784091],
            [16.279056, 40.802591],
            [16.25659, 40.824564],
            [16.232026, 40.855871],
            [16.224633, 40.887569],
            [16.203293, 40.918515],
            [16.163801, 40.929563],
            [16.138898, 40.919337],
            [16.122128, 40.900223],
            [16.094469, 40.921252],
            [16.059811, 40.929882],
            [16.046553, 40.938013],
            [15.977463, 40.959437],
            [15.972646, 40.973115],
            [16.003689, 40.971193],
            [16.032818, 40.999367],
            [16.028769, 41.025063],
            [16.041373, 41.037347],
            [16.008085, 41.060088],
            [15.999694, 41.054972],
            [15.97709, 41.095148],
            [15.954222, 41.11179],
            [15.921807, 41.112132],
            [15.881501, 41.137817],
            [15.806212, 41.111871],
            [15.799574, 41.092966],
            [15.769242, 41.084173],
            [15.753439, 41.09832],
            [15.731227, 41.100013],
            [15.717932, 41.090663],
            [15.680257, 41.087782],
            [15.647399, 41.090606],
            [15.61826, 41.100242],
            [15.588398, 41.09936],
            [15.559756, 41.088371],
            [15.543846, 41.067945],
            [15.543717, 41.056839],
            [15.57288, 40.999863],
            [15.567462, 40.969767],
            [15.54313, 40.947384],
            [15.527578, 40.908575],
            [15.468477, 40.89059],
            [15.473987, 40.875929],
            [15.461412, 40.868105],
            [15.445575, 40.87781],
            [15.403581, 40.882603],
            [15.384797, 40.877317],
            [15.374276, 40.886852],
            [15.355022, 40.883644],
            [15.355743, 40.872156],
            [15.379204, 40.868659],
            [15.37918, 40.841741],
            [15.335819, 40.835871],
            [15.367304, 40.81313],
            [15.366958, 40.801478],
            [15.386405, 40.792522],
            [15.381661, 40.779645],
            [15.38987, 40.768618],
            [15.379903, 40.751923],
            [15.382321, 40.725144],
            [15.403427, 40.717328],
            [15.431011, 40.693077],
            [15.470844, 40.674165],
            [15.488037, 40.673835],
            [15.504433, 40.663935],
            [15.49294, 40.648489],
            [15.468613, 40.64023],
            [15.450314, 40.610095],
            [15.476195, 40.593449],
            [15.510969, 40.586743],
            [15.536897, 40.547479],
            [15.536146, 40.523258],
            [15.551313, 40.510242],
            [15.537029, 40.501847],
            [15.539692, 40.489048],
            [15.578763, 40.470473],
            [15.595602, 40.44184],
            [15.627159, 40.427221],
            [15.65128, 40.409249],
            [15.710956, 40.378298],
            [15.705352, 40.345017],
            [15.727712, 40.316446],
            [15.749993, 40.299736],
            [15.793117, 40.290779],
            [15.806596, 40.275527],
            [15.806202, 40.25241],
            [15.788184, 40.237635],
            [15.798014, 40.225848],
            [15.774915, 40.218345],
            [15.752038, 40.202801],
            [15.735307, 40.202678],
            [15.730584, 40.187328],
            [15.713358, 40.179447],
            [15.702569, 40.162472],
            [15.707745, 40.147498],
            [15.70666, 40.118736],
            [15.669422, 40.074256],
            [15.676995, 40.055904],
            [15.646172, 40.043969],
            [15.665292, 40.040584],
            [15.677561, 40.026655],
            [15.681962, 40.00717],
            [15.700062, 39.997096],
            [15.724363, 39.969296],
          ],
        ],
      },
      properties: {
        name: "Basilicata",
        cartodb_id: 17,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.816827, 39.677978],
            [15.832661, 39.668783],
            [15.84511, 39.628665],
            [15.852167, 39.619633],
            [15.874773, 39.554916],
            [15.882526, 39.544872],
            [15.926014, 39.527546],
            [15.946041, 39.503783],
            [15.95944, 39.479339],
            [15.994356, 39.446389],
            [16.0375, 39.349418],
            [16.055893, 39.271827],
            [16.06398, 39.159758],
            [16.067677, 39.13449],
            [16.077125, 39.113933],
            [16.095712, 39.042913],
            [16.10265, 39.026277],
            [16.132391, 38.999495],
            [16.154473, 38.955313],
            [16.219134, 38.921373],
            [16.221914, 38.867348],
            [16.220228, 38.836248],
            [16.21446, 38.812644],
            [16.199152, 38.776664],
            [16.183012, 38.748704],
            [16.160721, 38.738492],
            [16.147674, 38.725284],
            [16.105238, 38.712835],
            [16.032392, 38.728911],
            [15.986831, 38.72405],
            [15.948379, 38.691979],
            [15.903166, 38.681048],
            [15.872881, 38.67426],
            [15.848971, 38.660238],
            [15.837442, 38.64722],
            [15.827523, 38.624003],
            [15.845893, 38.614198],
            [15.867321, 38.593671],
            [15.89356, 38.578853],
            [15.901971, 38.565465],
            [15.929077, 38.551374],
            [15.931457, 38.538325],
            [15.9116, 38.479376],
            [15.899176, 38.452538],
            [15.866038, 38.40306],
            [15.858935, 38.381248],
            [15.837008, 38.364851],
            [15.830431, 38.325852],
            [15.817893, 38.301758],
            [15.795025, 38.281307],
            [15.749998, 38.258153],
            [15.714091, 38.255472],
            [15.679865, 38.248206],
            [15.668722, 38.240246],
            [15.637312, 38.233226],
            [15.633924, 38.223859],
            [15.634817, 38.186883],
            [15.64142, 38.169798],
            [15.655871, 38.153529],
            [15.656067, 38.134884],
            [15.648769, 38.115347],
            [15.631424, 38.102966],
            [15.635472, 38.076825],
            [15.650208, 38.069264],
            [15.648691, 38.05574],
            [15.65777, 38.040325],
            [15.652045, 38.02909],
            [15.634366, 38.019449],
            [15.646733, 37.992429],
            [15.678501, 37.955407],
            [15.697811, 37.949926],
            [15.728619, 37.929662],
            [15.765053, 37.917112],
            [15.804223, 37.920738],
            [15.840603, 37.920221],
            [15.876018, 37.927238],
            [15.920957, 37.930742],
            [15.958193, 37.920396],
            [15.998805, 37.916829],
            [16.019864, 37.923546],
            [16.065253, 37.926679],
            [16.097743, 37.957633],
            [16.117027, 37.98277],
            [16.14499, 38.03229],
            [16.143913, 38.044689],
            [16.167125, 38.13835],
            [16.193295, 38.15894],
            [16.330419, 38.298361],
            [16.422438, 38.326823],
            [16.438202, 38.329684],
            [16.474109, 38.345092],
            [16.503745, 38.365858],
            [16.556954, 38.412416],
            [16.57157, 38.427765],
            [16.582685, 38.453003],
            [16.580114, 38.503875],
            [16.572672, 38.536048],
            [16.573907, 38.564951],
            [16.568066, 38.606075],
            [16.561239, 38.622457],
            [16.557231, 38.656021],
            [16.559426, 38.680558],
            [16.536505, 38.704738],
            [16.543358, 38.728019],
            [16.568601, 38.754814],
            [16.567501, 38.766208],
            [16.603833, 38.810083],
            [16.625899, 38.826796],
            [16.638386, 38.827698],
            [16.702123, 38.861066],
            [16.771073, 38.894148],
            [16.837223, 38.916345],
            [16.877201, 38.926092],
            [16.954511, 38.939093],
            [16.978672, 38.939476],
            [16.998327, 38.93106],
            [17.011443, 38.912375],
            [17.04817, 38.920309],
            [17.082731, 38.914294],
            [17.094221, 38.906608],
            [17.130677, 38.926982],
            [17.170914, 38.957823],
            [17.160288, 38.982194],
            [17.17192, 39.005015],
            [17.196719, 39.027955],
            [17.173895, 39.034087],
            [17.147535, 39.047495],
            [17.135794, 39.059668],
            [17.131205, 39.076329],
            [17.110584, 39.099423],
            [17.11108, 39.125248],
            [17.12372, 39.158636],
            [17.149209, 39.20711],
            [17.11151, 39.246306],
            [17.105593, 39.267378],
            [17.112149, 39.289923],
            [17.112022, 39.316703],
            [17.133104, 39.359539],
            [17.135657, 39.373377],
            [17.154006, 39.394984],
            [17.154098, 39.402899],
            [17.121639, 39.40611],
            [17.081683, 39.422848],
            [17.047585, 39.444923],
            [17.025289, 39.484855],
            [16.980709, 39.492099],
            [16.94457, 39.502851],
            [16.90261, 39.525931],
            [16.867392, 39.539626],
            [16.827484, 39.569182],
            [16.81845, 39.589092],
            [16.771205, 39.621799],
            [16.756607, 39.625432],
            [16.736422, 39.6174],
            [16.689357, 39.622537],
            [16.631125, 39.621312],
            [16.607973, 39.62561],
            [16.563962, 39.643778],
            [16.530036, 39.667496],
            [16.526396, 39.680906],
            [16.530207, 39.725442],
            [16.500545, 39.742497],
            [16.488087, 39.768257],
            [16.486986, 39.783493],
            [16.497556, 39.818781],
            [16.539064, 39.871758],
            [16.5833, 39.911123],
            [16.62571, 39.95529],
            [16.629692, 39.968379],
            [16.619275, 39.977432],
            [16.606964, 40.027214],
            [16.599799, 40.038393],
            [16.604402, 40.074813],
            [16.609879, 40.085912],
            [16.644681, 40.120036],
            [16.628579, 40.130725],
            [16.588026, 40.134116],
            [16.56267, 40.130877],
            [16.530822, 40.119092],
            [16.518786, 40.129775],
            [16.495483, 40.126076],
            [16.471586, 40.135078],
            [16.452798, 40.135801],
            [16.409763, 40.121956],
            [16.414622, 40.110744],
            [16.415054, 40.081684],
            [16.395201, 40.050942],
            [16.399244, 40.019968],
            [16.391826, 40.004308],
            [16.361086, 39.969043],
            [16.3589, 39.955102],
            [16.338616, 39.937178],
            [16.360476, 39.920607],
            [16.35597, 39.90211],
            [16.344041, 39.903437],
            [16.314748, 39.932046],
            [16.28323, 39.9319],
            [16.269246, 39.936966],
            [16.238815, 39.926473],
            [16.21735, 39.928831],
            [16.215507, 39.896984],
            [16.159773, 39.920408],
            [16.13879, 39.902418],
            [16.104137, 39.907927],
            [16.083865, 39.89762],
            [16.054318, 39.899147],
            [16.038495, 39.917173],
            [16.017478, 39.928108],
            [16.007699, 39.957941],
            [16.031335, 39.994447],
            [15.980026, 39.983405],
            [15.969339, 39.992739],
            [15.928843, 40.002076],
            [15.89884, 39.982688],
            [15.891137, 39.995517],
            [15.868486, 39.991132],
            [15.851059, 40.007038],
            [15.820876, 40.002874],
            [15.795944, 39.972662],
            [15.772018, 39.956607],
            [15.76668, 39.931611],
            [15.756717, 39.924553],
            [15.779805, 39.89468],
            [15.791894, 39.865812],
            [15.780303, 39.848296],
            [15.777716, 39.821843],
            [15.786349, 39.817933],
            [15.795661, 39.774486],
            [15.809191, 39.731581],
            [15.816827, 39.677978],
          ],
        ],
      },
      properties: {
        name: "Calabria",
        cartodb_id: 18,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [12.444029, 37.789301],
              [12.470803, 37.748174],
              [12.472491, 37.704487],
              [12.494687, 37.688048],
              [12.502029, 37.675771],
              [12.534338, 37.659826],
              [12.554876, 37.664487],
              [12.591931, 37.650981],
              [12.614278, 37.635298],
              [12.62322, 37.612788],
              [12.645666, 37.586283],
              [12.673573, 37.561087],
              [12.726441, 37.570133],
              [12.76554, 37.579979],
              [12.819364, 37.583651],
              [12.85897, 37.583767],
              [12.905976, 37.574932],
              [12.918444, 37.578235],
              [12.956596, 37.568274],
              [12.989488, 37.542075],
              [13.012724, 37.513805],
              [13.014088, 37.500326],
              [13.039278, 37.496872],
              [13.055512, 37.505323],
              [13.078938, 37.506855],
              [13.131341, 37.493614],
              [13.145011, 37.498371],
              [13.16963, 37.493122],
              [13.188458, 37.48017],
              [13.196639, 37.46642],
              [13.217937, 37.460364],
              [13.240168, 37.434451],
              [13.254566, 37.42568],
              [13.272332, 37.392825],
              [13.298523, 37.388296],
              [13.323768, 37.364404],
              [13.353644, 37.359325],
              [13.402566, 37.334181],
              [13.427223, 37.310875],
              [13.453789, 37.295643],
              [13.483073, 37.289321],
              [13.512529, 37.292641],
              [13.538319, 37.283379],
              [13.551987, 37.288105],
              [13.614835, 37.243066],
              [13.639841, 37.230592],
              [13.65668, 37.212701],
              [13.664144, 37.194517],
              [13.692041, 37.185243],
              [13.7088, 37.174168],
              [13.726858, 37.171386],
              [13.752095, 37.150393],
              [13.767646, 37.147425],
              [13.824783, 37.145624],
              [13.83952, 37.138462],
              [13.859507, 37.113536],
              [13.883757, 37.102418],
              [13.932376, 37.095995],
              [13.976024, 37.109759],
              [14.037006, 37.107222],
              [14.079604, 37.110781],
              [14.157502, 37.096217],
              [14.198102, 37.08277],
              [14.270149, 37.054246],
              [14.301135, 37.034691],
              [14.340609, 37.00121],
              [14.383729, 36.957277],
              [14.436554, 36.886038],
              [14.456635, 36.841819],
              [14.460719, 36.815818],
              [14.479356, 36.806062],
              [14.494025, 36.787652],
              [14.517101, 36.788676],
              [14.532019, 36.781618],
              [14.556948, 36.783509],
              [14.5882, 36.778269],
              [14.607568, 36.769506],
              [14.639803, 36.762195],
              [14.6714, 36.743661],
              [14.690514, 36.722374],
              [14.743992, 36.721728],
              [14.758168, 36.711536],
              [14.783764, 36.704743],
              [14.811835, 36.716706],
              [14.862854, 36.731354],
              [14.895796, 36.73054],
              [14.919223, 36.720957],
              [14.957287, 36.695222],
              [14.973333, 36.693713],
              [14.996811, 36.703208],
              [15.021386, 36.702381],
              [15.042961, 36.690061],
              [15.057272, 36.662953],
              [15.082337, 36.650189],
              [15.113062, 36.674286],
              [15.133408, 36.668729],
              [15.140916, 36.686174],
              [15.126532, 36.699607],
              [15.122171, 36.732617],
              [15.095615, 36.777685],
              [15.094842, 36.800464],
              [15.110516, 36.81806],
              [15.105756, 36.835304],
              [15.111454, 36.849452],
              [15.13778, 36.881271],
              [15.154099, 36.915591],
              [15.168867, 36.923056],
              [15.187083, 36.946831],
              [15.230466, 36.971411],
              [15.259794, 36.974403],
              [15.268446, 37.003647],
              [15.30462, 37.015794],
              [15.315655, 37.004838],
              [15.337408, 37.008696],
              [15.315616, 37.038224],
              [15.303942, 37.044059],
              [15.280055, 37.035979],
              [15.271629, 37.052491],
              [15.278711, 37.066378],
              [15.291669, 37.063104],
              [15.301353, 37.094095],
              [15.296415, 37.107872],
              [15.263821, 37.107185],
              [15.238086, 37.117433],
              [15.221405, 37.13053],
              [15.220786, 37.144495],
              [15.200052, 37.164772],
              [15.201988, 37.179363],
              [15.187533, 37.191958],
              [15.183509, 37.210339],
              [15.195479, 37.225315],
              [15.190957, 37.233509],
              [15.217041, 37.246405],
              [15.240114, 37.236921],
              [15.242486, 37.235667],
              [15.254617, 37.234843],
              [15.23957, 37.263431],
              [15.216814, 37.279003],
              [15.204176, 37.295046],
              [15.186111, 37.288124],
              [15.186038, 37.288209],
              [15.175284, 37.292615],
              [15.162308, 37.290414],
              [15.139955, 37.307938],
              [15.117464, 37.311474],
              [15.098563, 37.322269],
              [15.091215, 37.363803],
              [15.092366, 37.406331],
              [15.086011, 37.480904],
              [15.092753, 37.49602],
              [15.117909, 37.524585],
              [15.116829, 37.532692],
              [15.143669, 37.54468],
              [15.177908, 37.577686],
              [15.173717, 37.618955],
              [15.17796, 37.638124],
              [15.193893, 37.647786],
              [15.199853, 37.677795],
              [15.219021, 37.709488],
              [15.205488, 37.740415],
              [15.21246, 37.754656],
              [15.249161, 37.800864],
              [15.26621, 37.816218],
              [15.269818, 37.831796],
              [15.303678, 37.858068],
              [15.306964, 37.879336],
              [15.346391, 37.915603],
              [15.350026, 37.924515],
              [15.405254, 37.984452],
              [15.439647, 38.01805],
              [15.442414, 38.025712],
              [15.477882, 38.055699],
              [15.494445, 38.075966],
              [15.522463, 38.129364],
              [15.563092, 38.184029],
              [15.558265, 38.191487],
              [15.568204, 38.225557],
              [15.584295, 38.247805],
              [15.613677, 38.25787],
              [15.651228, 38.265789],
              [15.649724, 38.271835],
              [15.609999, 38.275509],
              [15.583497, 38.283793],
              [15.557509, 38.298648],
              [15.540991, 38.302351],
              [15.518087, 38.297213],
              [15.450336, 38.252498],
              [15.401006, 38.232837],
              [15.354241, 38.218871],
              [15.293506, 38.207482],
              [15.26858, 38.207778],
              [15.241579, 38.219412],
              [15.250241, 38.233696],
              [15.25097, 38.256917],
              [15.236018, 38.253673],
              [15.239632, 38.237367],
              [15.226023, 38.205858],
              [15.212827, 38.187529],
              [15.171312, 38.15281],
              [15.147258, 38.142623],
              [15.146581, 38.142413],
              [15.126784, 38.135387],
              [15.089522, 38.121084],
              [15.056868, 38.132598],
              [15.047113, 38.152329],
              [14.982645, 38.151197],
              [14.967538, 38.155194],
              [14.940448, 38.180425],
              [14.916974, 38.193366],
              [14.891399, 38.173539],
              [14.785081, 38.152743],
              [14.74494, 38.164045],
              [14.69012, 38.110252],
              [14.671838, 38.095112],
              [14.630577, 38.071373],
              [14.617095, 38.072705],
              [14.581885, 38.059691],
              [14.557114, 38.06123],
              [14.532028, 38.04521],
              [14.476916, 38.034476],
              [14.446744, 38.035523],
              [14.424805, 38.041187],
              [14.398365, 38.025278],
              [14.373525, 38.016957],
              [14.339222, 38.019777],
              [14.3128, 38.009413],
              [14.286128, 38.008422],
              [14.228891, 38.011316],
              [14.146655, 38.027105],
              [14.126754, 38.017919],
              [14.107127, 38.015732],
              [14.063139, 38.020173],
              [14.024124, 38.042206],
              [14.006784, 38.033551],
              [13.946528, 38.029314],
              [13.896678, 38.005251],
              [13.853502, 37.989162],
              [13.798125, 37.976203],
              [13.745667, 37.971262],
              [13.711384, 37.978633],
              [13.704502, 37.991885],
              [13.664153, 37.99552],
              [13.637712, 38.009034],
              [13.619898, 38.012974],
              [13.600844, 38.027269],
              [13.568494, 38.039033],
              [13.541305, 38.059406],
              [13.538108, 38.071813],
              [13.5407, 38.073526],
              [13.536571, 38.108315],
              [13.509308, 38.120721],
              [13.505797, 38.109445],
              [13.473751, 38.099051],
              [13.443438, 38.095925],
              [13.404523, 38.101498],
              [13.384981, 38.109776],
              [13.365686, 38.126709],
              [13.375665, 38.155068],
              [13.366293, 38.183641],
              [13.326749, 38.204813],
              [13.318937, 38.22519],
              [13.296985, 38.218587],
              [13.277419, 38.203376],
              [13.248685, 38.200069],
              [13.238251, 38.187446],
              [13.195236, 38.171075],
              [13.162532, 38.173821],
              [13.156701, 38.187827],
              [13.138833, 38.184649],
              [13.122761, 38.192588],
              [13.106356, 38.19206],
              [13.07745, 38.1722],
              [13.084099, 38.158812],
              [13.07098, 38.149366],
              [13.053696, 38.140387],
              [13.068987, 38.121678],
              [13.078965, 38.089423],
              [13.053347, 38.073607],
              [13.040286, 38.07256],
              [12.997875, 38.048903],
              [12.93831, 38.03042],
              [12.893705, 38.025398],
              [12.859721, 38.049578],
              [12.827552, 38.067521],
              [12.790925, 38.113158],
              [12.791661, 38.136261],
              [12.766741, 38.181365],
              [12.73932, 38.17702],
              [12.732641, 38.182981],
              [12.719688, 38.168076],
              [12.738385, 38.142169],
              [12.710473, 38.110059],
              [12.688407, 38.107105],
              [12.673026, 38.115761],
              [12.65766, 38.108416],
              [12.661267, 38.092594],
              [12.646653, 38.080869],
              [12.611313, 38.066871],
              [12.578245, 38.071782],
              [12.560003, 38.06463],
              [12.555465, 38.052832],
              [12.519407, 38.022513],
              [12.519965, 38.010221],
              [12.496168, 37.986097],
              [12.499265, 37.967205],
              [12.49238, 37.952205],
              [12.45991, 37.909493],
              [12.467492, 37.892912],
              [12.486514, 37.874656],
              [12.467664, 37.841516],
              [12.459943, 37.816646],
              [12.426203, 37.804253],
              [12.444029, 37.789301],
            ],
          ],
          [
            [
              [11.97338, 36.835857],
              [11.955286, 36.840565],
              [11.935962, 36.832968],
              [11.927089, 36.820445],
              [11.927331, 36.803423],
              [11.944865, 36.791567],
              [11.957586, 36.769296],
              [11.973314, 36.76414],
              [11.982835, 36.745606],
              [11.993664, 36.738545],
              [12.029092, 36.736975],
              [12.04977, 36.751372],
              [12.056552, 36.776436],
              [12.049519, 36.800925],
              [12.02811, 36.812639],
              [12.014088, 36.826318],
              [12.002077, 36.820814],
              [11.97338, 36.835857],
            ],
          ],
          [
            [
              [14.961175, 38.524486],
              [14.926878, 38.5241],
              [14.904708, 38.510215],
              [14.898354, 38.487477],
              [14.91317, 38.46628],
              [14.932774, 38.462426],
              [14.953746, 38.440344],
              [14.963259, 38.45286],
              [14.957752, 38.478096],
              [14.964289, 38.490571],
              [14.961175, 38.524486],
            ],
          ],
          [
            [
              [14.865405, 38.584127],
              [14.845536, 38.580597],
              [14.808049, 38.584174],
              [14.797334, 38.561905],
              [14.857149, 38.535292],
              [14.871687, 38.537724],
              [14.874338, 38.56227],
              [14.865405, 38.584127],
            ],
          ],
          [
            [
              [14.963177, 38.42094],
              [14.939617, 38.410566],
              [14.949482, 38.388771],
              [14.977106, 38.37012],
              [14.993015, 38.367815],
              [15.006185, 38.376899],
              [14.997824, 38.397348],
              [14.964416, 38.413212],
              [14.963177, 38.42094],
            ],
          ],
          [
            [
              [12.531594, 35.53032],
              [12.518596, 35.522815],
              [12.5695, 35.512612],
              [12.59997, 35.496709],
              [12.633407, 35.494864],
              [12.626026, 35.511422],
              [12.6326, 35.519456],
              [12.605374, 35.52713],
              [12.594721, 35.521925],
              [12.531594, 35.53032],
            ],
          ],
          [
            [
              [12.277537, 37.935774],
              [12.283864, 37.925679],
              [12.287191, 37.917023],
              [12.317556, 37.922204],
              [12.33801, 37.920927],
              [12.351452, 37.908754],
              [12.366558, 37.907293],
              [12.371322, 37.919599],
              [12.339556, 37.937679],
              [12.316526, 37.939843],
              [12.308768, 37.954373],
              [12.277537, 37.935774],
            ],
          ],
          [
            [
              [15.215771, 38.772583],
              [15.227559, 38.778318],
              [15.24295, 38.806061],
              [15.213532, 38.811322],
              [15.18922, 38.788903],
              [15.215771, 38.772583],
            ],
          ],
          [
            [
              [12.064641, 37.991175],
              [12.034394, 37.989812],
              [12.05704, 37.953139],
              [12.085066, 37.946062],
              [12.081479, 37.959064],
              [12.065537, 37.978777],
              [12.064641, 37.991175],
            ],
          ],
          [
            [
              [14.586679, 38.559954],
              [14.57639, 38.582375],
              [14.546111, 38.586353],
              [14.540049, 38.578651],
              [14.556467, 38.558866],
              [14.586679, 38.559954],
            ],
          ],
          [
            [
              [13.182863, 38.720571],
              [13.160059, 38.713023],
              [13.15352, 38.694755],
              [13.174, 38.691806],
              [13.200308, 38.709694],
              [13.182863, 38.720571],
            ],
          ],
          [
            [
              [12.351364, 37.989483],
              [12.336286, 38.021697],
              [12.320628, 38.002158],
              [12.330549, 37.988184],
              [12.351364, 37.989483],
            ],
          ],
          [
            [
              [12.450721, 37.897335],
              [12.432619, 37.897183],
              [12.445203, 37.849196],
              [12.452547, 37.854042],
              [12.450721, 37.897335],
            ],
          ],
          [
            [
              [12.876144, 35.876542],
              [12.849198, 35.869482],
              [12.860222, 35.856195],
              [12.881448, 35.855769],
              [12.876144, 35.876542],
            ],
          ],
          [
            [
              [14.352496, 38.531741],
              [14.367195, 38.550724],
              [14.349728, 38.554994],
              [14.342179, 38.535811],
              [14.352496, 38.531741],
            ],
          ],
        ],
      },
      properties: {
        name: "Sicilia",
        cartodb_id: 19,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.204561, 40.582196],
              [8.19919, 40.572179],
              [8.244458, 40.571504],
              [8.246688, 40.581902],
              [8.288118, 40.593303],
              [8.299984, 40.592292],
              [8.318867, 40.572314],
              [8.310155, 40.561663],
              [8.32053, 40.551227],
              [8.340251, 40.510156],
              [8.374972, 40.491813],
              [8.387317, 40.453907],
              [8.399435, 40.430611],
              [8.400811, 40.404474],
              [8.382781, 40.352831],
              [8.396393, 40.340957],
              [8.420905, 40.338199],
              [8.445543, 40.319816],
              [8.464838, 40.316285],
              [8.465355, 40.301395],
              [8.483997, 40.286345],
              [8.473953, 40.243463],
              [8.463132, 40.215033],
              [8.468687, 40.200482],
              [8.456791, 40.177669],
              [8.461086, 40.151374],
              [8.485928, 40.118774],
              [8.490226, 40.08062],
              [8.454727, 40.0518],
              [8.434079, 40.051111],
              [8.42257, 40.043587],
              [8.386785, 40.044869],
              [8.379283, 40.029929],
              [8.407016, 40.030776],
              [8.414898, 40.019129],
              [8.410125, 39.997221],
              [8.393741, 39.980236],
              [8.403033, 39.945801],
              [8.399935, 39.918954],
              [8.412181, 39.901692],
              [8.432085, 39.889204],
              [8.439594, 39.876294],
              [8.469134, 39.905169],
              [8.507832, 39.909039],
              [8.532753, 39.89842],
              [8.545564, 39.885641],
              [8.540839, 39.870792],
              [8.553638, 39.864594],
              [8.55578, 39.839758],
              [8.546387, 39.796187],
              [8.53368, 39.771394],
              [8.513441, 39.753832],
              [8.522109, 39.74716],
              [8.499415, 39.719338],
              [8.459468, 39.770425],
              [8.444994, 39.759103],
              [8.450184, 39.741087],
              [8.448273, 39.716034],
              [8.456365, 39.712116],
              [8.445395, 39.679343],
              [8.454901, 39.660843],
              [8.452976, 39.62419],
              [8.466029, 39.617807],
              [8.471875, 39.602626],
              [8.452137, 39.546651],
              [8.436178, 39.523795],
              [8.405116, 39.487703],
              [8.391154, 39.479333],
              [8.384278, 39.458379],
              [8.393684, 39.44781],
              [8.411918, 39.442191],
              [8.411757, 39.419035],
              [8.387039, 39.394638],
              [8.374002, 39.375785],
              [8.386538, 39.366553],
              [8.397712, 39.338689],
              [8.416907, 39.337137],
              [8.418842, 39.32482],
              [8.432726, 39.312738],
              [8.438006, 39.293008],
              [8.426327, 39.270523],
              [8.367662, 39.229185],
              [8.373264, 39.210559],
              [8.397347, 39.20031],
              [8.407803, 39.159709],
              [8.419355, 39.172192],
              [8.438414, 39.156857],
              [8.434547, 39.130862],
              [8.443689, 39.117529],
              [8.462043, 39.122224],
              [8.47266, 39.113182],
              [8.489428, 39.085573],
              [8.467035, 39.058161],
              [8.457502, 39.084756],
              [8.432558, 39.096916],
              [8.426755, 39.109937],
              [8.387432, 39.100248],
              [8.374157, 39.113232],
              [8.361345, 39.110961],
              [8.359429, 39.088333],
              [8.351272, 39.0768],
              [8.362753, 39.043176],
              [8.376388, 39.013081],
              [8.384858, 39.007119],
              [8.40422, 38.963328],
              [8.41485, 38.956326],
              [8.445766, 38.970936],
              [8.452428, 38.990466],
              [8.45369, 39.025522],
              [8.469471, 39.050197],
              [8.467392, 39.056902],
              [8.499677, 39.064399],
              [8.528179, 39.060159],
              [8.544587, 39.047735],
              [8.566035, 39.046398],
              [8.575937, 39.033071],
              [8.575878, 39.018062],
              [8.566018, 39.000778],
              [8.58982, 38.965485],
              [8.613572, 38.958938],
              [8.624597, 38.938577],
              [8.619342, 38.916912],
              [8.607665, 38.893851],
              [8.622425, 38.891333],
              [8.650675, 38.895909],
              [8.715648, 38.92602],
              [8.721216, 38.937685],
              [8.763026, 38.917989],
              [8.799925, 38.895737],
              [8.820742, 38.89553],
              [8.833537, 38.878435],
              [8.8556, 38.879565],
              [8.907366, 38.908965],
              [8.925907, 38.929102],
              [9.012828, 38.988155],
              [9.025185, 39.004768],
              [9.025373, 39.02118],
              [9.042664, 39.044185],
              [9.046067, 39.059472],
              [9.017258, 39.088466],
              [9.009921, 39.122286],
              [9.02024, 39.150185],
              [9.062234, 39.19442],
              [9.096924, 39.21512],
              [9.124443, 39.207029],
              [9.147007, 39.184955],
              [9.165783, 39.20536],
              [9.19313, 39.222193],
              [9.230859, 39.229081],
              [9.248377, 39.21744],
              [9.29848, 39.214255],
              [9.310118, 39.20631],
              [9.324054, 39.204243],
              [9.346824, 39.182143],
              [9.371865, 39.174894],
              [9.40888, 39.14487],
              [9.431861, 39.134353],
              [9.435027, 39.126524],
              [9.485404, 39.136674],
              [9.503524, 39.1323],
              [9.519753, 39.114296],
              [9.540166, 39.134075],
              [9.564951, 39.142623],
              [9.571003, 39.171222],
              [9.578846, 39.185401],
              [9.565432, 39.189972],
              [9.571171, 39.240292],
              [9.58929, 39.275667],
              [9.601453, 39.290033],
              [9.629438, 39.30734],
              [9.605883, 39.318983],
              [9.59841, 39.346755],
              [9.60899, 39.389431],
              [9.646314, 39.461952],
              [9.643899, 39.494407],
              [9.633585, 39.497789],
              [9.640058, 39.540611],
              [9.652353, 39.550241],
              [9.657686, 39.606745],
              [9.648186, 39.652691],
              [9.664865, 39.684019],
              [9.660414, 39.693167],
              [9.676947, 39.711875],
              [9.675596, 39.75149],
              [9.66943, 39.777736],
              [9.67488, 39.809752],
              [9.686392, 39.84311],
              [9.697727, 39.854422],
              [9.68325, 39.872335],
              [9.684177, 39.906516],
              [9.706464, 39.925572],
              [9.687845, 39.949406],
              [9.687636, 39.985157],
              [9.70098, 40.001717],
              [9.706736, 40.034918],
              [9.721111, 40.04992],
              [9.734874, 40.087087],
              [9.69057, 40.108937],
              [9.658217, 40.143236],
              [9.644762, 40.174491],
              [9.635119, 40.180769],
              [9.62671, 40.208915],
              [9.624925, 40.253763],
              [9.632629, 40.277505],
              [9.655362, 40.301964],
              [9.666665, 40.320737],
              [9.718502, 40.367501],
              [9.742738, 40.38237],
              [9.755101, 40.382054],
              [9.773905, 40.407751],
              [9.776599, 40.428774],
              [9.793597, 40.444038],
              [9.826979, 40.513119],
              [9.82783, 40.531455],
              [9.799836, 40.543345],
              [9.789365, 40.573758],
              [9.770216, 40.584148],
              [9.752761, 40.601839],
              [9.754223, 40.616206],
              [9.743986, 40.626903],
              [9.743221, 40.646411],
              [9.752742, 40.674879],
              [9.74386, 40.692206],
              [9.715976, 40.710853],
              [9.712549, 40.741251],
              [9.715968, 40.754098],
              [9.687701, 40.767467],
              [9.6724, 40.785634],
              [9.676848, 40.807856],
              [9.694535, 40.847337],
              [9.685774, 40.860551],
              [9.668692, 40.854029],
              [9.653368, 40.862304],
              [9.636705, 40.885417],
              [9.616993, 40.890538],
              [9.646214, 40.918608],
              [9.618123, 40.918655],
              [9.6183, 40.918642],
              [9.618123, 40.918655],
              [9.605418, 40.910911],
              [9.590777, 40.900748],
              [9.575867, 40.912317],
              [9.547366, 40.920358],
              [9.516038, 40.923906],
              [9.521667, 40.932455],
              [9.527449, 40.933845],
              [9.551483, 40.928735],
              [9.551762, 40.928861],
              [9.552684, 40.929244],
              [9.575848, 40.928951],
              [9.569363, 40.943753],
              [9.594894, 40.96944],
              [9.583803, 40.977162],
              [9.609087, 41.001896],
              [9.620093, 41.003549],
              [9.637566, 40.98875],
              [9.653664, 40.985291],
              [9.665017, 40.99506],
              [9.657005, 41.005477],
              [9.632068, 41.003049],
              [9.597365, 41.022042],
              [9.577855, 41.00382],
              [9.551948, 41.007683],
              [9.560782, 41.022],
              [9.562231, 41.038677],
              [9.527665, 41.024595],
              [9.52455, 41.054395],
              [9.54135, 41.090761],
              [9.552703, 41.08024],
              [9.571671, 41.101461],
              [9.567911, 41.116827],
              [9.539899, 41.123654],
              [9.54681, 41.134648],
              [9.51576, 41.157193],
              [9.511176, 41.141693],
              [9.486702, 41.147393],
              [9.471166, 41.142746],
              [9.450762, 41.103653],
              [9.441596, 41.104301],
              [9.443187, 41.131949],
              [9.429881, 41.146733],
              [9.420214, 41.179965],
              [9.403812, 41.176601],
              [9.381878, 41.18866],
              [9.364338, 41.208539],
              [9.339227, 41.205652],
              [9.331342, 41.195955],
              [9.302245, 41.191676],
              [9.301874, 41.203558],
              [9.281552, 41.228804],
              [9.261563, 41.242587],
              [9.241229, 41.246217],
              [9.226267, 41.260197],
              [9.199612, 41.245594],
              [9.181981, 41.248987],
              [9.166402, 41.237324],
              [9.159246, 41.184522],
              [9.172089, 41.178],
              [9.153517, 41.156385],
              [9.119842, 41.152872],
              [9.111207, 41.135896],
              [9.087249, 41.128958],
              [9.059053, 41.129102],
              [9.042715, 41.138297],
              [9.014283, 41.126387],
              [8.977103, 41.084909],
              [8.951184, 41.059891],
              [8.916975, 41.036766],
              [8.880914, 41.029631],
              [8.874034, 40.996435],
              [8.83758, 40.958186],
              [8.790934, 40.923142],
              [8.769481, 40.916858],
              [8.712757, 40.91974],
              [8.684394, 40.903986],
              [8.658694, 40.895747],
              [8.633404, 40.882307],
              [8.626357, 40.86586],
              [8.608706, 40.852219],
              [8.572783, 40.835856],
              [8.540832, 40.827322],
              [8.487383, 40.820731],
              [8.450351, 40.822237],
              [8.414695, 40.839979],
              [8.373985, 40.838377],
              [8.316595, 40.845284],
              [8.291057, 40.859041],
              [8.249806, 40.892507],
              [8.233748, 40.911813],
              [8.229121, 40.931162],
              [8.238796, 40.951857],
              [8.227523, 40.954114],
              [8.206538, 40.971541],
              [8.190752, 40.958604],
              [8.177837, 40.933606],
              [8.193379, 40.913823],
              [8.220362, 40.901067],
              [8.224843, 40.88736],
              [8.219308, 40.869478],
              [8.176808, 40.801946],
              [8.161211, 40.791875],
              [8.167518, 40.768432],
              [8.152737, 40.749839],
              [8.136233, 40.738655],
              [8.170044, 40.710122],
              [8.175895, 40.695638],
              [8.204186, 40.689441],
              [8.205149, 40.680577],
              [8.190419, 40.658255],
              [8.187773, 40.640176],
              [8.147502, 40.62706],
              [8.151924, 40.580417],
              [8.162452, 40.578343],
              [8.190164, 40.616761],
              [8.210796, 40.615748],
              [8.214359, 40.594606],
              [8.204561, 40.582196],
            ],
          ],
          [
            [
              [8.225367, 41.036993],
              [8.230331, 41.024179],
              [8.213656, 41.008278],
              [8.209699, 40.991157],
              [8.255763, 40.986342],
              [8.264632, 41.003647],
              [8.25005, 41.018694],
              [8.251998, 41.034257],
              [8.284419, 41.065416],
              [8.322994, 41.056824],
              [8.341643, 41.072253],
              [8.350539, 41.091139],
              [8.331798, 41.100625],
              [8.321144, 41.121055],
              [8.301865, 41.105915],
              [8.272856, 41.100475],
              [8.286337, 41.077713],
              [8.264584, 41.069542],
              [8.25863, 41.058017],
              [8.225367, 41.036993],
            ],
          ],
          [
            [
              [8.309227, 39.181197],
              [8.272703, 39.180812],
              [8.257394, 39.16992],
              [8.231536, 39.165373],
              [8.220629, 39.148804],
              [8.251338, 39.134417],
              [8.248926, 39.109491],
              [8.282304, 39.09724],
              [8.305868, 39.102804],
              [8.310069, 39.115549],
              [8.309227, 39.181197],
            ],
          ],
          [
            [
              [9.404657, 41.212228],
              [9.438206, 41.214615],
              [9.436758, 41.24997],
              [9.428377, 41.259197],
              [9.415381, 41.269008],
              [9.401568, 41.243868],
              [9.379078, 41.235616],
              [9.382384, 41.211287],
              [9.404657, 41.212228],
            ],
          ],
          [
            [
              [9.470094, 41.244254],
              [9.44583, 41.203768],
              [9.448478, 41.191843],
              [9.465791, 41.190427],
              [9.479849, 41.200485],
              [9.482689, 41.237075],
              [9.470094, 41.244254],
            ],
          ],
          [
            [
              [9.735138, 40.92358],
              [9.674552, 40.892736],
              [9.693793, 40.888812],
              [9.71271, 40.901361],
              [9.743761, 40.913267],
              [9.735138, 40.92358],
            ],
          ],
          [
            [
              [9.344742, 41.251943],
              [9.333888, 41.236594],
              [9.346692, 41.229888],
              [9.361654, 41.243455],
              [9.344742, 41.251943],
            ],
          ],
          [
            [
              [9.41106, 41.187388],
              [9.425692, 41.19801],
              [9.415412, 41.208589],
              [9.399106, 41.198677],
              [9.41106, 41.187388],
            ],
          ],
        ],
      },
      properties: {
        name: "Sardegna",
        cartodb_id: 20,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:01:56+0100",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.865146, 45.917375],
            [7.820674, 45.927621],
            [7.802254, 45.918186],
            [7.769719, 45.938046],
            [7.748407, 45.938697],
            [7.735656, 45.924588],
            [7.708759, 45.935096],
            [7.711095, 45.948425],
            [7.681221, 45.958974],
            [7.65899, 45.977562],
            [7.640323, 45.971257],
            [7.585227, 45.9731],
            [7.5792, 45.987191],
            [7.551455, 45.98714],
            [7.543143, 45.978682],
            [7.539235, 45.955969],
            [7.503443, 45.961599],
            [7.479233, 45.953567],
            [7.475815, 45.93706],
            [7.445386, 45.932419],
            [7.432778, 45.919359],
            [7.382905, 45.897846],
            [7.344965, 45.916194],
            [7.33078, 45.911497],
            [7.291821, 45.921397],
            [7.278078, 45.902101],
            [7.260818, 45.891309],
            [7.217524, 45.889697],
            [7.191373, 45.85954],
            [7.176444, 45.863857],
            [7.154363, 45.880198],
            [7.119385, 45.860483],
            [7.102759, 45.859983],
            [7.096192, 45.876867],
            [7.066357, 45.900793],
            [7.065608, 45.910456],
            [7.046866, 45.923196],
            [7.019523, 45.915642],
            [7.005396, 45.900762],
            [7.000374, 45.875104],
            [6.951857, 45.85978],
            [6.940746, 45.847781],
            [6.897746, 45.843613],
            [6.88421, 45.850438],
            [6.866996, 45.83479],
            [6.843624, 45.843171],
            [6.819765, 45.837156],
            [6.810507, 45.821723],
            [6.813353, 45.797701],
            [6.802445, 45.782209],
            [6.806662, 45.74915],
            [6.818395, 45.740698],
            [6.810304, 45.72571],
            [6.828962, 45.70487],
            [6.870699, 45.681507],
            [6.89464, 45.67691],
            [6.931229, 45.648084],
            [6.961971, 45.656017],
            [7.001093, 45.641552],
            [6.985658, 45.626483],
            [6.978968, 45.589894],
            [6.996143, 45.5771],
            [6.990738, 45.564062],
            [6.995162, 45.529756],
            [7.004746, 45.521608],
            [7.000797, 45.505526],
            [7.02178, 45.498078],
            [7.056129, 45.49406],
            [7.048996, 45.473434],
            [7.07666, 45.475173],
            [7.105435, 45.467913],
            [7.114457, 45.47394],
            [7.122771, 45.507191],
            [7.142161, 45.511874],
            [7.142241, 45.481767],
            [7.158377, 45.487555],
            [7.200568, 45.482098],
            [7.21485, 45.472214],
            [7.231488, 45.476415],
            [7.248657, 45.495024],
            [7.264357, 45.49962],
            [7.2706, 45.515628],
            [7.324055, 45.515402],
            [7.365125, 45.526004],
            [7.376815, 45.517443],
            [7.423275, 45.546956],
            [7.449063, 45.556516],
            [7.471965, 45.578635],
            [7.50012, 45.584057],
            [7.533769, 45.578117],
            [7.549517, 45.589731],
            [7.566586, 45.592512],
            [7.600181, 45.579392],
            [7.610163, 45.563824],
            [7.647039, 45.569938],
            [7.677888, 45.55304],
            [7.71744, 45.559905],
            [7.733793, 45.551277],
            [7.786416, 45.575735],
            [7.79677, 45.587331],
            [7.849965, 45.603207],
            [7.863368, 45.597932],
            [7.89734, 45.600711],
            [7.897805, 45.612296],
            [7.916996, 45.634031],
            [7.940595, 45.644639],
            [7.924948, 45.657625],
            [7.906301, 45.682903],
            [7.937717, 45.725282],
            [7.930096, 45.743382],
            [7.901259, 45.750186],
            [7.864196, 45.791928],
            [7.863428, 45.82084],
            [7.875134, 45.826921],
            [7.864959, 45.840519],
            [7.877019, 45.862518],
            [7.869913, 45.868659],
            [7.865146, 45.917375],
          ],
        ],
      },
      properties: {
        name: "Valle D'Aosta/Vallée D'Aoste",
        cartodb_id: 2,
        created_at: "2013-11-28T03:00:02+0100",
        updated_at: "2013-11-28T03:02:18+0100",
      },
    },
  ],
  style: {
    stroke: "#000000",
    fill: "#3498DB",
    "stroke-width": 1,
    "fill-opacity": 0.8,
  },
};
