import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../UI/Loader/Loader";
import { fetchRussiaReport } from "../../redux/slices/russia.slice";
import PDFViewer from "../PDFViewer/PDFViewer";
import { renderGoToSourceButton } from "./Italy";

const Russia = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.russia);

  useEffect(() => {
    if (report) return;
    dispatch(fetchRussiaReport());
  }, [dispatch, report]);

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      if (!report?.content_sections?.[0]?.graphs) return [];
      return report.content_sections[0].graphs.map((chart) => ({
        title: chart.title,
        image: `https://www.influenza.spb.ru${chart.image_url}`,
      }));
    },
  }));

  if (loading) return <Loader />;
  if (error) return <p className="error-message">Error: {error}</p>;
  if (!report) return <p className="info-message">No report available.</p>;

  const contentSection = report.content_sections?.[0];
  if (!contentSection)
    return <p className="info-message">No content available.</p>;

  const renderImages = (report) => {
    return (
      <div>
        {report?.images?.map((base64) => {
          return (
            <div className="d-flex align-items-center justify-content-center p-2 m-auto" style={{width: "80%"}}>
              {
                <img
                width={"100%"}
                  alt=""
                  src={`data:image/png;base64,${base64?.base64_data}`}
                  className="border p-2 rounded shadow-md"
                ></img>
              }
            </div>
          );
        })}
      </div>
    );
  };
  const renderContent = (report) => {
    return (
      <div>
        {report?.content?.map((content) => {
          return (
            <div>
              <p>{content}</p>
            </div>
          );
        })}
      </div>
    );
  };
  const renderReportSection = () => {
    if (!report?.content_sections) {
      return (
        <div className="p-2 d-flex align-items-center justify-content-center">
          No report available
        </div>
      );
    }
    return (
      <div className="p-3">
        {report?.content_sections.map((report, index) => {
          if (index === 0) return <></>;
          return (
            <div>
              {renderContent(report)}
              {renderImages(report)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="country-name mb-4">Russia</h2>
        {renderGoToSourceButton(
          "https://www.influenza.spb.ru/en/surveillance/flu-bulletin/"
        )}
      </div>

      <div className="card mb-4">
        <div className="card-body">
          <div className="alert alert-info mb-4">
            <i className="fa fa-info-circle me-2"></i>
            Report for Week {report.week} of {report.year}
          </div>

          {report.pdf_url && (
            <div className="d-flex justify-content-end">
              <PDFViewer
                pdfUrl={`https://www.influenza.spb.ru${report.pdf_url}`}
              />
            </div>
          )}
        </div>
        {renderReportSection()}

        <div className="card-footer text-muted">
          <p className="mb-0">
            <i className="fa fa-clock-o me-2"></i>
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
  );
});

export default Russia;
