import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../UI/Loader/Loader";
import { fetchRussiaReport } from "../../redux/slices/russia.slice";
import { getBase64Image } from "../../utils/cfunctions";

const Russia = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.russia);

  useEffect(() => {
    if (report) return;
    dispatch(fetchRussiaReport());
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      return report?.charts_data.map(async (chart) => {
        await getBase64Image(
          `https://www.influenza.spb.ru${chart.image_url}`
        ).then((res) => {
          return {
            title: chart.title,
            image: res,
          };
        });
      });
    },
  }));

  if (loading) return <Loader />;
  if (error) return <p className="error-message">Error: {error}</p>;
  if (!report) return <p className="info-message">No report available.</p>;

  const renderStatistics = () => {
    return (
      <div>
        <h4>Statistics</h4>
        <div className="p-2 card">
          <h6>Statistics</h6>
          <ul>
            <li>Morbidity rate: {report.statistics.morbidity_rate}</li>
            <li>Total samples: {report.statistics.total_samples}</li>
          </ul>

          <h6>Positive Cases</h6>
          <ul>
            <li>Influenza A: {report.statistics.positive_cases.influenza_a}</li>
            <li>Influenza B: {report.statistics.positive_cases.influenza_b}</li>
            <li>Other ARVI: {report.statistics.positive_cases.other_arvi}</li>
          </ul>

          <h6>Covid Stats</h6>
          <ul>
            <li>Total Cases: {report.statistics.covid_stats.total_cases}</li>
            <li>Total Deaths: {report.statistics.covid_stats.total_deaths}</li>
            <li>Weekly cases: {report.statistics.covid_stats.weekly_cases}</li>
            <li>
              Weekly Deaths: {report.statistics.covid_stats.weekly_deaths}
            </li>
            <li>
              Positive Rate: {report.statistics.covid_stats.positive_rate}
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderChartImages = () => {
    return (
      <div className="container-fluid">
        <h4>Charts</h4>
        <div className="row justify-content-center">
          {report.charts_data.map((chart) => (
            <div key={chart.title} className="col-12 col-md-6 col-lg-4 mb-4">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title text-center">{chart.title}</h5>
                  <img
                    src={`https://www.influenza.spb.ru${chart.image_url}`}
                    alt={chart.title}
                    className="img-fluid mb-3"
                  />
                  <div className="d-flex flex-column gap-2">
                    {chart.legend_items.map((item) => (
                      <div
                        key={item.description}
                        className="d-flex gap-2 align-items-center"
                      >
                        <img
                          src={`https://www.influenza.spb.ru${item.image_url}`}
                          alt="Legend"
                          className="img-fluid"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <span>{item.description}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderHighlights = () => {
    return (
      <div>
        <h4>Highlights</h4>
        <ul className="list-unstyled">
          {report.highlights?.map((highlight, index) => (
            <div className="d-flex align-items-start gap-2">
              <i className="fa fa-check-circle icon mt-1"></i>
              <li key={index}>{highlight}</li>
            </div>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <h2 className="country-name mb-4">Russia</h2>
      <div className="card">
        <div className="card-body">
          <div className="alert alert-info mb-4">
            <i className="fa fa-info-circle me-2"></i>
            Report for Year {report.year}. Week {report.week}
          </div>
          <div className="highlights">{renderHighlights()}</div>
          <div className="statistics">{renderStatistics()}</div>
          <div className="chartImages mt-4">{renderChartImages()}</div>
        </div>
      </div>
    </>
  );
});

export default Russia;
