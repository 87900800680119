// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newspaper-section {
  page-break-inside: avoid;
  break-inside: avoid;
  padding: 1rem;
}

.newspaper-article {
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: 2rem;
}

.featured-article {
  font-size: 1.1em;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eaeaea;
}

.article-content {
  column-count: 1;
  column-gap: 2rem;
}

@media (min-width: 768px) {
  .featured-article .article-content {
    column-count: 2;
  }
}

/* Print styles */
@media print {
  .newspaper-content {
    background: white;
    padding: 0;
    margin: 0;
  }

  .form-select,
  .btn {
    display: none;
  }

  .container {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
}

/* Custom scrollbar for Webkit browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.functions-tab-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.functions-tab-btn-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/component/pages/styles/globalNews.css"],"names":[],"mappings":"AAAA;EACE,wBAAmB;EAAnB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,wBAAmB;EAAnB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA,iBAAiB;AACjB;EACE;IACE,iBAAiB;IACjB,UAAU;IACV,SAAS;EACX;;EAEA;;IAEE,aAAa;EACf;;EAEA;IACE,eAAe;IACf,UAAU;IACV,SAAS;EACX;AACF;;AAEA,yCAAyC;AACzC;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".newspaper-section {\n  break-inside: avoid;\n  padding: 1rem;\n}\n\n.newspaper-article {\n  break-inside: avoid;\n  margin-bottom: 2rem;\n}\n\n.featured-article {\n  font-size: 1.1em;\n  padding-bottom: 2rem;\n  margin-bottom: 2rem;\n  border-bottom: 1px solid #eaeaea;\n}\n\n.article-content {\n  column-count: 1;\n  column-gap: 2rem;\n}\n\n@media (min-width: 768px) {\n  .featured-article .article-content {\n    column-count: 2;\n  }\n}\n\n/* Print styles */\n@media print {\n  .newspaper-content {\n    background: white;\n    padding: 0;\n    margin: 0;\n  }\n\n  .form-select,\n  .btn {\n    display: none;\n  }\n\n  .container {\n    max-width: 100%;\n    padding: 0;\n    margin: 0;\n  }\n}\n\n/* Custom scrollbar for Webkit browsers */\n::-webkit-scrollbar {\n  width: 8px;\n}\n\n::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 4px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n\n.functions-tab-container {\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n}\n\n.functions-tab-btn-container {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
