import { format } from "date-fns";
import { jsPDF } from "jspdf";

const generateGlobalNewsPDF = (news, whoOutbreaks, lastUpdated) => {
  const pdf = new jsPDF({
    orientation: "p",
    unit: "mm",
    format: "a4",
  });

  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const margin = 20;
  const contentWidth = pageWidth - margin * 2;

  const addWrappedText = (text, x, y, maxWidth, lineHeight = 7) => {
    if (!text) return y;
    const splitText = pdf.splitTextToSize(text, maxWidth);
    pdf.text(splitText, x, y);
    return y + splitText.length * lineHeight;
  };

  // Title and Date
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(24);
  pdf.text("Global Health Monitor", pageWidth / 2, 20, { align: "center" });

  pdf.setFontSize(12);
  pdf.setFont("helvetica", "normal");
  pdf.text(
    format(new Date(lastUpdated), "EEEE, MMMM dd, yyyy"),
    pageWidth / 2,
    30,
    { align: "center" }
  );

  let currentY = 40;

  // WHO Outbreaks Section
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(18);
  pdf.setTextColor(220, 38, 38); // Red color for alert section
  currentY += 10;
  pdf.text("WHO Disease Outbreak Alerts", margin, currentY);

  currentY += 2;
  pdf.setLineWidth(0.5);
  pdf.line(margin, currentY, pageWidth - margin, currentY);
  pdf.setTextColor(0);
  currentY += 8;

  whoOutbreaks.forEach((outbreak) => {
    if (currentY > pageHeight - 60) {
      pdf.addPage();
      currentY = 20;
    }

    // Outbreak Title
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(14);
    currentY = addWrappedText(outbreak.title, margin, currentY, contentWidth);

    // Date
    pdf.setFont("helvetica", "italic");
    pdf.setFontSize(10);
    currentY += 5;
    pdf.text(outbreak.date, margin, currentY);
    currentY += 6;

    // Summary
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    currentY = addWrappedText(
      outbreak.situation_summary,
      margin,
      currentY,
      contentWidth
    );

    currentY += 10;
  });

  // Section Divider
  if (currentY > pageHeight - 40) {
    pdf.addPage();
    currentY = 20;
  }

  currentY += 10;
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(18);
  pdf.text("Country Updates", margin, currentY);
  currentY += 2;
  pdf.setLineWidth(0.5);
  pdf.line(margin, currentY, pageWidth - margin, currentY);
  currentY += 8;

  // Country News Section
  Object.entries(news).forEach(([country, articles]) => {
    if (currentY > pageHeight - 40) {
      pdf.addPage();
      currentY = 20;
    }

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);
    pdf.setTextColor(49, 135, 174); // #3187ae
    currentY += 10;
    pdf.text(country, margin, currentY);

    currentY += 2;
    pdf.setLineWidth(0.5);
    pdf.line(margin, currentY, pageWidth - margin, currentY);

    pdf.setTextColor(0);
    currentY += 8;

    articles.forEach((article) => {
      if (currentY > pageHeight - 60) {
        pdf.addPage();
        currentY = 20;
      }

      // Article Title
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(14);
      currentY = addWrappedText(
        article.title.replace(/[\[\]]/g, ""),
        margin,
        currentY,
        contentWidth
      );

      // Date and Source
      pdf.setFont("helvetica", "italic");
      pdf.setFontSize(10);
      currentY += 5;
      const dateText = format(new Date(article.timestamp), "MMMM dd, yyyy");
      pdf.text(dateText, margin, currentY);

      if (article.source) {
        pdf.text(`Source: ${article.source}`, pageWidth - margin, currentY, {
          align: "right",
        });
      }
      currentY += 6;

      // Summary
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(11);
      currentY = addWrappedText(
        article.summary.replace(/[\[\]]/g, ""),
        margin,
        currentY,
        contentWidth
      );

      // Topics
      if (article.topics && article.topics.length > 0) {
        currentY += 5;
        pdf.setFont("helvetica", "italic");
        pdf.setFontSize(9);
        pdf.text(`Topics: ${article.topics.join(", ")}`, margin, currentY);
      }

      currentY += 10;
    });

    currentY += 5;
  });

  // Add page numbers and footer to all pages
  const totalPages = pdf.internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i++) {
    pdf.setPage(i);

    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    const footerText = `© ${new Date().getFullYear()} AI-Flu Monitor`;
    pdf.text(footerText, pageWidth / 2, pageHeight - 10, { align: "center" });

    pdf.text(
      `Page ${i} of ${totalPages}`,
      pageWidth - margin,
      pageHeight - 10,
      { align: "right" }
    );
  }

  return pdf;
};

export const handleDownloadPdf = (news, whoOutbreaks, lastUpdated) => {
  try {
    const pdf = generateGlobalNewsPDF(news, whoOutbreaks, lastUpdated);
    pdf.save("Global_Health_Monitor.pdf");
  } catch (error) {
    console.error("PDF generation error:", error);
    alert("An error occurred while generating the PDF. Please try again.");
  }
};
