import html2pdf from "html2pdf.js";
import toast from "react-hot-toast";

export const getCurrentWeekNumber = () => {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const pastDaysOfYear = (now - startOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
};

export const generateComprehensivePDF = async (
  whoRef,
  russiaRef,
  italyRef,
  spainRef,
  chinaRef,
  indonesiaRef,
  brazilRef,
  franceRef,
  reportWHO,
  reportRussia,
  reportItaly,
  reportSpain,
  reportChina,
  reportIndonesia,
  reportBrazil,
  reportFrance,
  setLoading
) => {
  setLoading(true);
  try {
    const whoCharts = await whoRef.current?.getChartImages();
    const russiaCharts = await russiaRef.current?.getChartImages();
    const italyCharts = await italyRef.current?.getChartImages();
    const spainCharts = await spainRef.current?.getChartImages();
    const chinaCharts = await chinaRef.current?.getChartImages();
    const indonesiaCharts = await indonesiaRef.current?.getChartImages();
    const brazilCharts = await brazilRef.current?.getChartImages();
    const franceCharts = await franceRef.current?.getChartImages();

    if (
      !reportChina ||
      !reportRussia ||
      !reportItaly ||
      !reportWHO ||
      !reportSpain ||
      !reportChina ||
      !reportIndonesia ||
      !reportBrazil
    ) {
      toast.error("Some data isn't available yet.");
      setLoading(false);
      return;
    }

    const content = document.createElement("div");
    content.style.padding = "20px";
    content.style.fontFamily = "Arial, sans-serif";
    content.style.color = "#333";

    const header = document.createElement("h1");
    header.textContent = "Global Influenza Report";
    header.style.textAlign = "center";
    header.style.marginBottom = "40px";
    content.appendChild(header);

    const addReport = (title, report, charts, isItaly = false) => {
      console.log('title: ', title);
      console.log('report: ', report);
      if (!report) return;

      const reportSection = document.createElement("section");
      reportSection.style.marginBottom = "30px";

      const reportTitle = document.createElement("h2");
      reportTitle.textContent = title;
      reportTitle.style.marginBottom = "15px";
      reportSection.appendChild(reportTitle);

      const reportContent = document.createElement("div");
      reportContent.style.lineHeight = "1.6";

      if (!isItaly) {
        if (report.title) {
          const reportHeader = document.createElement("h3");
          reportHeader.textContent = report.title;
          reportHeader.style.marginBottom = "10px";
          reportContent.appendChild(reportHeader);
        }

        if (report.specimen_summary) {
          const specimenSummary = document.createElement("p");
          specimenSummary.textContent = report.specimen_summary;
          reportContent.appendChild(specimenSummary);
        }

        if (report.subtype_analysis && Array.isArray(report.subtype_analysis)) {
          const subtypeHeader = document.createElement("h4");
          subtypeHeader.textContent = "Subtype Analysis";
          subtypeHeader.style.marginTop = "15px";
          reportContent.appendChild(subtypeHeader);

          const subtypeList = document.createElement("ul");
          report.subtype_analysis.forEach((subtype, index) => {
            const listItem = document.createElement("li");
            listItem.innerHTML = `<i class="fa fa-check-circle icon" aria-label="Subtype"></i> ${subtype}`;
            subtypeList.appendChild(listItem);
          });
          reportContent.appendChild(subtypeList);
        }

        if (report.sari_surveillance) {
          const sari = document.createElement("p");
          sari.innerHTML = `<i class="fa fa-heartbeat icon" aria-label="SARI Surveillance"></i> ${report.sari_surveillance}`;
          reportContent.appendChild(sari);
        }

        if (report.ili_surveillance) {
          const ili = document.createElement("p");
          ili.innerHTML = `<i class="fa fa-stethoscope icon" aria-label="ILI Surveillance"></i> ${report.ili_surveillance}`;
          reportContent.appendChild(ili);
        }

        if (report.total_burden) {
          const totalBurden = document.createElement("p");
          totalBurden.innerHTML = `<i class="fa fa-chart-line icon" aria-label="Total Burden"></i> ${report.total_burden}`;
          reportContent.appendChild(totalBurden);
        }
      } else {
        console.log("report of italy...");
        console.log('report: ', report);
        if (report.heading) {
          const heading = document.createElement("p");
          heading.textContent = report.heading;
          heading.style.fontWeight = "bold";
          reportContent.appendChild(heading);
        }
        if (report.data1) {
          const data1 = document.createElement("p");
          data1.textContent = report.data1;
          reportContent.appendChild(data1);
        }
        if (report.data2) {
          const data2 = document.createElement("p");
          data2.textContent = report.data2;
          reportContent.appendChild(data2);
        }
        if (report.data3) {
          const data3 = document.createElement("p");
          data3.textContent = report.data3;
          reportContent.appendChild(data3);
        }
      }

      reportSection.appendChild(reportContent);
      content.appendChild(reportSection);

      addChartsGrid(charts);
    };

    const addChartsGrid = (charts) => {
      if (!charts || charts.length === 0) return;

      const gridContainer = document.createElement("div");
      gridContainer.style.display = "grid";
      gridContainer.style.gridTemplateColumns =
        "repeat(auto-fill, minmax(300px, 1fr))";
      gridContainer.style.gap = "20px";
      gridContainer.style.marginBottom = "30px";

      charts.forEach((chart) => {
        if (!chart.image) return;

        const chartSection = document.createElement("div");
        chartSection.style.textAlign = "center";
        chartSection.style.breakInside = "avoid";

        const chartImage = document.createElement("img");
        chartImage.src = chart.image;
        chartImage.style.width = "100%";
        chartImage.style.height = "auto";
        chartImage.style.maxHeight = "300px";
        chartImage.style.objectFit = "contain";
        chartImage.style.marginBottom = "10px";
        chartImage.style.border = "1px solid #ddd";
        chartImage.style.padding = "5px";
        chartImage.style.borderRadius = "4px";

        const chartTitle = document.createElement("p");
        chartTitle.textContent = chart.title;
        chartTitle.style.marginTop = "5px";
        chartTitle.style.fontSize = "14px";
        chartTitle.style.fontWeight = "bold";

        chartSection.appendChild(chartImage);
        chartSection.appendChild(chartTitle);

        gridContainer.appendChild(chartSection);
      });

      content.appendChild(gridContainer);
    };

    const addFranceSection = () => {
      const franceSection = document.createElement("section");
      franceSection.style.marginBottom = "30px";

      const franceTitle = document.createElement("h2");
      franceTitle.textContent = "France Report";
      franceSection.appendChild(franceTitle);

      const weekSummary = document.createElement("p");
      weekSummary.textContent = formatWeekIdToDateRange(
        reportFrance?.data?.requested_week_report?.week_id
      );
      weekSummary.style.fontStyle = "italic";
      franceSection.appendChild(weekSummary);

      const summaryText = document.createElement("p");
      summaryText.textContent =
        reportFrance?.data?.requested_week_report?.summary_text;
      franceSection.appendChild(summaryText);

      const table = document.createElement("table");
      table.style.width = "100%";
      table.style.borderCollapse = "collapse";
      table.style.marginTop = "15px";

      const tableHeader = document.createElement("tr");
      ["Region", "Incidence", "Incidence Rate"].forEach((headerText) => {
        const th = document.createElement("th");
        th.textContent = headerText;
        th.style.border = "1px solid #ddd";
        th.style.padding = "8px";
        th.style.backgroundColor = "#4CAF50";
        th.style.color = "#fff";
        tableHeader.appendChild(th);
      });
      table.appendChild(tableHeader);

      reportFrance?.data?.requested_week_report?.table_data.forEach((row) => {
        const tableRow = document.createElement("tr");
        ["region", "incidence", "incidence_rate"].forEach((key) => {
          const td = document.createElement("td");
          td.textContent = row[key];
          td.style.border = "1px solid #ddd";
          td.style.padding = "8px";
          td.style.backgroundColor = "#FAFAFA";
          tableRow.appendChild(td);
        });
        table.appendChild(tableRow);
      });

      franceSection.appendChild(table);

      content.appendChild(franceSection);

      addChartsGrid([
        { title: "ARI Chart", image: franceCharts?.influenzaAgMapChart },
      ]);
    };

    const formatWeekIdToDateRange = (weekId) => {
      const year = parseInt(weekId.slice(0, 4), 10);
      const week = parseInt(weekId.slice(4), 10);
      const startDate = new Date(year, 0, 1 + (week - 1) * 7);
      startDate.setDate(startDate.getDate() + (1 - startDate.getDay()));
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      return `Observed situation in France for the week ${week} of the year ${year}, from ${startDate.toLocaleDateString(
        "en-US"
      )} to ${endDate.toLocaleDateString("en-US")}`;
    };

    addReport(
      "WHO Report",
      reportWHO,
      [
        { title: "Influenza Chart", image: whoCharts?.influenzaAgMapChart },
        { title: "ILI Cases Chart", image: whoCharts?.iliAgMapChart },
        { title: "SARI Cases Chart", image: whoCharts?.sariAgMapChart },
      ],
      false
    );

    addReport(
      "Russia Report",
      reportRussia,
      // [{ title: "Influenza Chart", image: russiaCharts?.influenzaAgMapChart }],
      [],
      false
    );

    addReport(
      "Spain Report",
      reportSpain,
      // [{ title: "Influenza Chart", image: spainCharts?.influenzaAgMapChart }],
      [],
      false
    );

    addReport(
      "China Report",
      reportChina,
      // [{ title: "Influenza Chart", image: chinaCharts?.influenzaAgMapChart }],
      [],
      false
    );

    addReport(
      "Indonesia Report",
      reportIndonesia,
      // [
      //   {
      //     title: "Influenza Chart",
      //     image: indonesiaCharts?.influenzaAgMapChart,
      //   },
      // ],
      [],
      false
    );

    addReport(
      "Brazil Report",
      reportBrazil,
      // [
      //   {
      //     title: "Influenza Chart",
      //     image: indonesiaCharts?.influenzaAgMapChart,
      //   },
      // ],
      [],
      false
    );

    addReport(
      "Italy Report",
      reportItaly,
      // [{ title: "Influenza Chart", image: italyCharts?.influenzaAgMapChart }],
      [],
      false
    );

    addFranceSection();

    const options = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: "global_influenza_report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: false, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"], after: "section" },
    };

    await html2pdf().from(content).set(options).save();
    toast.success("PDF downloaded successfully!");
  } catch (err) {
    console.error("PDF Generation Error:", err);
    toast.error("An error occurred while generating the PDF.");
  } finally {
    setLoading(false);
  }
};
