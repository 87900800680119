// https://ec.europa.eu/eurostat/web/gisco/geodata/reference-data/administrative-units-statistical-units/countries
export const topology = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [3.0871, -54.4014],
              [3.407, -54.3486],
              [3.5662, -54.3224],
              [3.5714, -54.5157],
              [3.407, -54.5279],
              [3.2236, -54.5414],
              [3.0871, -54.4014],
            ],
          ],
        },
        properties: { name: "Bouvet Island" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [25.2703, -17.7989],
              [26.1873, -19.5021],
              [27.6166, -20.5719],
              [27.7788, -21.1231],
              [28.123, -21.5265],
              [28.9194, -21.7879],
              [29.3608, -22.1966],
              [28.2714, -22.6602],
              [27.0526, -23.648],
              [26.7949, -24.2778],
              [26.0025, -24.7193],
              [25.5853, -25.5825],
              [25.3317, -25.7424],
              [24.4722, -25.7662],
              [23.4396, -25.3063],
              [23.0327, -25.3248],
              [22.5598, -26.1926],
              [21.661, -26.8299],
              [21.2827, -26.8663],
              [20.7004, -26.8181],
              [20.8245, -26.0714],
              [20.6983, -25.63],
              [20.4129, -25.096],
              [19.9995, -24.752],
              [20.0143, -22.0939],
              [20.9363, -21.9709],
              [20.9991, -21.6023],
              [20.9992, -21.4074],
              [20.9993, -20.8507],
              [20.9994, -20.5666],
              [20.9995, -20.1422],
              [20.9999, -18.3196],
              [21.463, -18.3043],
              [21.7196, -18.256],
              [23.1849, -18.0102],
              [23.668, -18.4216],
              [24.3074, -18.0188],
              [25.2628, -17.7914],
              [25.2703, -17.7989],
            ],
          ],
        },
        properties: { name: "Botswana" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [31.7869, 52.1115],
              [30.9112, 52.0252],
              [30.5778, 51.675],
              [30.5213, 51.3278],
              [30.1126, 51.4902],
              [29.3584, 51.4228],
              [29.0542, 51.6149],
              [28.7544, 51.4177],
              [28.2365, 51.6329],
              [27.7579, 51.5175],
              [27.1051, 51.7564],
              [25.3462, 51.9465],
              [24.4673, 51.8703],
              [23.6174, 51.5095],
              [23.5477, 51.6914],
              [23.6455, 52.0553],
              [23.1783, 52.2831],
              [23.8616, 52.7073],
              [23.9162, 52.9048],
              [23.8116, 53.255],
              [23.5887, 53.6959],
              [23.5147, 53.9566],
              [24.4754, 53.9309],
              [24.8359, 54.149],
              [25.6697, 54.195],
              [25.761, 54.5791],
              [25.7708, 54.7943],
              [25.9578, 54.9506],
              [26.1932, 55.0034],
              [26.3111, 55.1325],
              [26.6447, 55.1608],
              [26.7404, 55.2639],
              [26.6304, 55.6807],
              [27.6312, 55.8737],
              [28.1546, 56.1698],
              [30.7426, 55.6294],
              [30.9746, 54.6727],
              [31.8888, 53.8522],
              [32.2832, 53.7654],
              [32.5875, 53.4898],
              [32.526, 53.3094],
              [31.3967, 53.0369],
              [31.7869, 52.1115],
            ],
          ],
        },
        properties: { name: "Belarus" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-87.6804, 17.3598],
                [-87.8544, 17.1216],
                [-88.0451, 17.259],
                [-87.8529, 17.5203],
                [-87.6804, 17.3598],
              ],
            ],
            [
              [
                [-88.9128, 15.9001],
                [-89.1806, 15.9683],
                [-89.1972, 16.2896],
                [-89.147, 17.8095],
                [-88.3168, 18.4888],
                [-88.1381, 18.1966],
                [-88.2692, 16.8235],
                [-88.9128, 15.9001],
              ],
            ],
          ],
        },
        properties: { name: "Belize" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [44.812, 39.6317],
              [45.6126, 38.9511],
              [46.1407, 38.8449],
              [46.5458, 38.8781],
              [47.9849, 39.664],
              [48.2959, 39.3917],
              [48.1125, 38.8555],
              [48.5934, 38.4644],
              [48.8878, 38.4412],
              [48.9806, 37.8463],
              [49.3825, 37.5063],
              [50.087, 37.3942],
              [51.0059, 36.7758],
              [51.8799, 36.5928],
              [53.8846, 36.9251],
              [53.9068, 37.3399],
              [54.2432, 37.3273],
              [54.7706, 37.5063],
              [55.3309, 38.0319],
              [56.7141, 38.2659],
              [57.2392, 38.2153],
              [57.5183, 37.9459],
              [58.2749, 37.6987],
              [59.3708, 37.5063],
              [59.5306, 37.1849],
              [60.3349, 36.6789],
              [61.0913, 36.5821],
              [61.2741, 35.6051],
              [60.8534, 34.3412],
              [60.5205, 34.0684],
              [60.799, 33.4155],
              [60.6222, 33.069],
              [60.8565, 32.1149],
              [60.8832, 31.5377],
              [61.6443, 31.3638],
              [61.8061, 31.0542],
              [61.6934, 30.7331],
              [60.873, 29.8585],
              [61.8091, 28.6605],
              [62.7458, 28.2253],
              [62.8319, 27.3052],
              [63.1741, 27.1781],
              [63.1929, 26.8423],
              [63.0911, 26.6676],
              [62.4742, 26.5693],
              [61.8842, 26.2036],
              [61.6105, 25.195],
              [57.5287, 25.767],
              [56.9532, 26.942],
              [56.4839, 27.1427],
              [56.0946, 27.0537],
              [55.9736, 26.7682],
              [54.8176, 26.5647],
              [53.6711, 26.7478],
              [52.3164, 27.6817],
              [51.4145, 27.9721],
              [50.0673, 30.0967],
              [49.5687, 30.0989],
              [49.0414, 30.3363],
              [48.4844, 29.9791],
              [47.7508, 31.0984],
              [47.7857, 31.7734],
              [47.4435, 32.3096],
              [46.2417, 32.9842],
              [45.5675, 33.8898],
              [45.5289, 34.4408],
              [46.0875, 35.1508],
              [46.0584, 35.6666],
              [45.1379, 36.4692],
              [44.7931, 37.1763],
              [44.6485, 37.4271],
              [44.4629, 37.8096],
              [44.2847, 37.9314],
              [44.4142, 38.2972],
              [44.05, 39.3628],
              [44.3729, 39.452],
              [44.5021, 39.717],
              [44.812, 39.6317],
            ],
          ],
        },
        properties: { name: "Iran" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [13.7142, 46.5227],
                [13.684, 46.4375],
                [13.511, 46.3484],
                [13.4969, 46.0513],
                [13.5971, 45.8195],
                [13.5962, 45.8079],
                [13.7847, 45.7428],
                [13.9032, 45.6358],
                [13.836, 45.5875],
                [13.7221, 45.595],
                [13.5798, 45.787],
                [13.4503, 45.704],
                [13.3525, 45.7354],
                [13.0988, 45.6445],
                [12.2277, 45.3255],
                [12.3306, 45.1606],
                [12.3991, 44.7926],
                [12.2699, 44.63],
                [12.3843, 44.2247],
                [12.4503, 44.1622],
                [12.7507, 43.9697],
                [13.1726, 43.7503],
                [13.6421, 43.4741],
                [13.743, 43.2941],
                [13.7462, 43.2888],
                [13.7521, 43.2777],
                [13.7575, 43.2673],
                [13.7642, 43.2555],
                [13.7675, 43.2502],
                [13.7742, 43.2399],
                [13.7777, 43.2352],
                [13.7907, 43.1989],
                [13.8039, 43.1717],
                [13.8108, 43.1631],
                [13.8179, 43.1451],
                [13.8291, 43.1268],
                [13.8418, 43.1032],
                [13.8455, 43.0944],
                [13.8467, 43.0801],
                [13.8495, 43.0667],
                [13.9157, 42.8946],
                [14.0154, 42.6893],
                [14.1469, 42.5306],
                [14.254, 42.4448],
                [14.7796, 42.07],
                [15.1382, 41.927],
                [16.0491, 41.9056],
                [16.0404, 41.7331],
                [16.0247, 41.4256],
                [16.5423, 41.2294],
                [17.389, 40.8919],
                [17.9006, 40.6819],
                [18.0975, 40.5154],
                [18.4343, 40.1839],
                [18.3281, 39.8804],
                [18.102, 39.937],
                [17.7635, 40.2955],
                [16.8673, 40.3978],
                [16.6443, 40.1191],
                [16.5399, 39.7215],
                [17.0251, 39.4836],
                [17.11, 39.0493],
                [16.8907, 38.9271],
                [16.7476, 38.8823],
                [16.5724, 38.7103],
                [16.5821, 38.4698],
                [15.8327, 37.931],
                [15.6528, 38.1424],
                [15.919, 38.5068],
                [16.2138, 38.8104],
                [16.0932, 39.0487],
                [15.756, 39.9235],
                [15.6449, 40.0428],
                [15.094, 40.1788],
                [14.7762, 40.5698],
                [14.4813, 40.6426],
                [14.0321, 40.8988],
                [13.7608, 41.2232],
                [12.7733, 41.4163],
                [12.3087, 41.7331],
                [11.7338, 42.1581],
                [11.4499, 42.3777],
                [10.7057, 42.9419],
                [10.2998, 43.5819],
                [10.2581, 43.8151],
                [10.1435, 43.9754],
                [10.0188, 44.0445],
                [9.5113, 44.2167],
                [8.633, 44.3798],
                [8.1354, 43.9389],
                [7.5298, 43.784],
                [7.7142, 44.0615],
                [6.8874, 44.3613],
                [6.9484, 44.6547],
                [7.0658, 44.7135],
                [6.6301, 45.1099],
                [7.1311, 45.2671],
                [7.1047, 45.4685],
                [6.8024, 45.7786],
                [7.0449, 45.9224],
                [7.8641, 45.9168],
                [7.8771, 45.927],
                [8.3847, 46.4522],
                [8.7139, 46.0973],
                [8.9121, 45.8304],
                [9.1594, 46.1696],
                [9.2485, 46.2338],
                [9.3475, 46.472],
                [10.0507, 46.335],
                [10.1739, 46.5798],
                [10.4528, 46.5307],
                [10.4697, 46.8549],
                [10.9817, 46.8074],
                [11.1643, 46.9657],
                [11.6272, 47.0133],
                [11.8572, 47.0038],
                [12.136, 47.0807],
                [12.2407, 47.0692],
                [12.2117, 46.8968],
                [12.4779, 46.6798],
                [12.6906, 46.657],
                [12.7314, 46.6343],
                [13.5042, 46.5663],
                [13.7142, 46.5227],
              ],
              [
                [12.3624, 41.8843],
                [12.3911, 41.869],
                [12.5186, 41.8784],
                [12.4897, 41.9547],
                [12.4327, 41.9402],
                [12.3624, 41.8843],
              ],
              [
                [12.453, 43.8945],
                [12.4624, 43.8938],
                [12.4688, 43.8966],
                [12.4874, 43.8965],
                [12.4931, 43.9069],
                [12.47, 43.973],
                [12.4504, 43.8972],
                [12.453, 43.8945],
              ],
            ],
            [
              [
                [13.2265, 38.1933],
                [14.1835, 38.0194],
                [15.3432, 38.1648],
                [15.2581, 37.8072],
                [15.0915, 37.3577],
                [15.2479, 37.0747],
                [15.0004, 36.7028],
                [14.5732, 36.7696],
                [14.3376, 37.002],
                [14.0362, 37.106],
                [12.8965, 37.5771],
                [12.6447, 37.6388],
                [12.5503, 37.9303],
                [12.9773, 38.0402],
                [13.2265, 38.1933],
              ],
            ],
            [
              [
                [12.1937, 36.8246],
                [11.9944, 36.6104],
                [11.76, 36.8816],
                [11.856, 36.9636],
                [12.1937, 36.8246],
              ],
            ],
            [
              [
                [9.5207, 39.1815],
                [8.6124, 38.9578],
                [8.3658, 39.0693],
                [8.393, 39.4465],
                [8.5023, 39.713],
                [8.4666, 40.1984],
                [8.3998, 40.4076],
                [8.2316, 40.795],
                [8.8027, 40.931],
                [9.3804, 41.196],
                [9.7494, 40.6581],
                [9.628, 40.1984],
                [9.6511, 39.5493],
                [9.5207, 39.1815],
              ],
            ],
          ],
        },
        properties: { name: "Italy" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-2.3643, 49.2882],
              [-2.014, 49.2781],
              [-1.9469, 49.1354],
              [-2.2715, 49.1496],
              [-2.3643, 49.2882],
            ],
          ],
        },
        properties: { name: "Jersey" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-78.2914, 18.2884],
              [-78.0766, 18.4752],
              [-77.4957, 18.4829],
              [-76.915, 18.3837],
              [-76.3799, 18.0417],
              [-76.4044, 17.9423],
              [-77.4957, 17.8484],
              [-78.2914, 18.2884],
            ],
          ],
        },
        properties: { name: "Jamaica" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [39.2009, 32.1543],
              [37.0883, 31.5014],
              [37.9246, 30.5466],
              [37.4562, 30.0187],
              [36.7808, 29.8457],
              [36.081, 29.223],
              [34.957, 29.3567],
              [34.9601, 29.359],
              [34.9666, 29.3638],
              [34.9608, 29.3683],
              [34.9642, 29.3734],
              [34.9622, 29.3799],
              [34.9662, 29.3879],
              [34.9604, 29.3984],
              [34.9681, 29.4029],
              [34.9749, 29.4098],
              [34.9708, 29.4219],
              [34.9731, 29.4313],
              [34.9668, 29.4462],
              [34.9764, 29.4623],
              [34.9751, 29.4701],
              [34.9771, 29.4738],
              [34.9915, 29.5015],
              [34.9938, 29.5101],
              [35, 29.5161],
              [35, 29.524],
              [34.9982, 29.5287],
              [34.9911, 29.5349],
              [34.9782, 29.5418],
              [35.476, 31.4905],
              [35.5551, 32.389],
              [35.6716, 32.6911],
              [36.8551, 32.3662],
              [38.7925, 33.3747],
              [39.2009, 32.1543],
            ],
          ],
        },
        properties: { name: "Jordan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [143.7459, 42.7022],
                [143.2036, 42.1068],
                [142.9945, 42.1068],
                [141.8174, 42.5459],
                [141.0993, 42.4226],
                [140.6396, 42.4965],
                [140.4363, 42.2295],
                [140.9257, 41.8218],
                [140.1806, 41.5037],
                [139.8677, 42.3012],
                [140.4815, 43.2037],
                [141.3431, 43.391],
                [141.7074, 44.4718],
                [141.6927, 45.172],
                [141.922, 45.3698],
                [143.6403, 44.2562],
                [145.1581, 43.9917],
                [145.2182, 43.1959],
                [143.7459, 42.7022],
              ],
            ],
            [
              [
                [142.3139, 27.64],
                [142.2605, 27.3207],
                [141.974, 27.5119],
                [142.1367, 27.7013],
                [142.3139, 27.64],
              ],
            ],
            [
              [
                [141.3843, 41.3148],
                [141.4656, 40.6779],
                [141.9617, 39.9522],
                [141.9127, 39.3419],
                [141.4592, 38.4797],
                [140.9518, 38.091],
                [141.0202, 37.2545],
                [140.6044, 36.3526],
                [140.7576, 35.7614],
                [140.3355, 35.2033],
                [139.8772, 34.9981],
                [139.847, 35.2989],
                [140.0474, 35.5553],
                [139.8713, 35.5787],
                [139.2019, 35.1931],
                [138.9179, 34.7682],
                [138.8207, 34.7851],
                [138.7345, 35.13],
                [138.1493, 34.6372],
                [137.4608, 34.6762],
                [136.735, 34.9873],
                [136.654, 34.5805],
                [136.8518, 34.5186],
                [136.8251, 34.3817],
                [136.373, 34.171],
                [135.7332, 33.502],
                [135.1309, 33.9027],
                [135.1434, 34.3064],
                [135.4375, 34.5805],
                [135.2971, 34.6852],
                [134.9658, 34.5805],
                [134.7432, 34.7231],
                [134.2243, 34.6772],
                [134.2243, 34.3503],
                [134.491, 34.2284],
                [134.8237, 34.4083],
                [134.9082, 34.3112],
                [134.6652, 34.1592],
                [134.65, 33.8435],
                [134.2243, 33.4048],
                [134.1674, 33.2633],
                [133.7255, 33.5211],
                [133.362, 33.4095],
                [132.8394, 32.79],
                [132.396, 33.0202],
                [132.4776, 33.2633],
                [132.3856, 33.4772],
                [132.7772, 33.9829],
                [132.7523, 34.1208],
                [132.4453, 34.2682],
                [132.2094, 33.9829],
                [132.2703, 33.8237],
                [132.1182, 33.7695],
                [131.8767, 33.9829],
                [130.9728, 33.9651],
                [131.1571, 33.6347],
                [131.6728, 33.6428],
                [131.6913, 33.2633],
                [131.9458, 32.8316],
                [131.5257, 32.0747],
                [131.3905, 31.502],
                [130.8656, 31.1442],
                [130.6665, 31.4888],
                [130.5626, 31.2684],
                [130.2842, 31.2779],
                [130.1614, 32.1463],
                [129.9792, 32.252],
                [130.0512, 32.4653],
                [130.1883, 32.5441],
                [130.2633, 32.3751],
                [130.4852, 32.3881],
                [130.4599, 32.5245],
                [130.6204, 32.581],
                [130.4164, 33.0226],
                [130.1883, 33.1315],
                [130.1883, 32.7764],
                [129.78, 32.7169],
                [129.5281, 33.3202],
                [129.8479, 33.5638],
                [130.145, 33.5906],
                [130.4633, 33.8723],
                [130.8524, 33.9651],
                [130.9359, 34.3818],
                [131.5257, 34.5557],
                [131.8033, 34.6874],
                [132.902, 35.5294],
                [134.2243, 35.5441],
                [135.162, 35.7225],
                [135.4529, 35.4853],
                [135.9832, 35.6504],
                [136.0372, 36.0428],
                [136.6944, 36.7816],
                [136.7192, 37.2545],
                [136.8244, 37.3689],
                [137.2308, 37.4549],
                [137.2348, 37.3314],
                [136.9482, 37.1061],
                [137.2679, 36.803],
                [138.3599, 37.2545],
                [139.3692, 38.1345],
                [139.7816, 38.867],
                [140.0439, 39.668],
                [139.7196, 39.9522],
                [139.9682, 40.1985],
                [139.9278, 40.6275],
                [140.2332, 40.8438],
                [140.3716, 41.1996],
                [140.7139, 40.9245],
                [141.1719, 40.9888],
                [141.1952, 41.212],
                [140.8212, 41.207],
                [140.9694, 41.4952],
                [141.3843, 41.3148],
              ],
            ],
            [
              [
                [141.4516, 45.128],
                [141.2428, 45.0076],
                [141.0125, 45.1926],
                [141.1655, 45.3497],
                [141.4516, 45.128],
              ],
            ],
            [
              [
                [138.5999, 38.058],
                [138.2952, 37.8091],
                [138.2018, 38.0174],
                [138.4719, 38.2694],
                [138.5999, 38.058],
              ],
            ],
            [
              [
                [133.4804, 36.2723],
                [133.3682, 36.0854],
                [133.2079, 36.1234],
                [133.0802, 35.9376],
                [132.8788, 36.0841],
                [133.2778, 36.4247],
                [133.4804, 36.2723],
              ],
            ],
            [
              [
                [131.3757, 25.7243],
                [131.066, 25.7087],
                [131.1244, 25.9636],
                [131.3461, 26.0201],
                [131.3757, 25.7243],
              ],
            ],
            [
              [
                [131.1503, 30.505],
                [130.9761, 30.4341],
                [130.8236, 30.6856],
                [131.0942, 30.8134],
                [131.1503, 30.505],
              ],
            ],
            [
              [
                [130.6362, 30.224],
                [130.4351, 30.1966],
                [130.3349, 30.3737],
                [130.4617, 30.4917],
                [130.7217, 30.3928],
                [130.6362, 30.224],
              ],
            ],
            [
              [
                [129.34, 34.4432],
                [129.3736, 34.0703],
                [129.0985, 34.1138],
                [129.2238, 34.6098],
                [129.5626, 34.7815],
                [129.5177, 34.5074],
                [129.34, 34.4432],
              ],
            ],
            [
              [
                [129.5144, 28.4103],
                [129.4426, 28.0497],
                [129.2902, 28.0849],
                [129.1927, 28.335],
                [129.5144, 28.4103],
              ],
            ],
            [
              [
                [129.0434, 32.7774],
                [128.7058, 32.5712],
                [128.5822, 32.6539],
                [129.0065, 32.9667],
                [129.0434, 32.7774],
              ],
            ],
            [
              [
                [128.0224, 26.4734],
                [127.8872, 26.2837],
                [127.735, 26.3854],
                [127.883, 26.6917],
                [128.0096, 26.6917],
                [128.0224, 26.4734],
              ],
            ],
            [
              [
                [124.1338, 24.3741],
                [123.9283, 24.1489],
                [123.6603, 24.4046],
                [123.7167, 24.4693],
                [124.1338, 24.3741],
              ],
            ],
            [
              [
                [123.2932, 24.4998],
                [123.1436, 24.3506],
                [122.6928, 24.4117],
                [122.8343, 24.5471],
                [123.2932, 24.4998],
              ],
            ],
          ],
        },
        properties: { name: "Japan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [35.9477, 4.62],
              [36.9621, 4.3833],
              [38.1026, 3.6459],
              [38.9731, 3.5158],
              [39.5667, 3.5224],
              [39.9305, 3.8858],
              [40.708, 4.2326],
              [41.2328, 3.9616],
              [41.9101, 3.9823],
              [41.0171, 2.6734],
              [41.0554, -0.8786],
              [41.5645, -1.6558],
              [41.4558, -1.766],
              [40.8487, -2.2733],
              [40.2335, -2.7873],
              [39.6021, -4.2684],
              [39.2042, -4.6703],
              [37.7537, -3.6303],
              [37.5178, -2.9658],
              [36.2553, -2.2733],
              [35.6903, -1.9633],
              [35.4478, -1.8303],
              [35.3306, -1.766],
              [33.934, -0.9999],
              [33.9422, -0.8786],
              [34.0186, 0.252],
              [34.5428, 1.1019],
              [34.9796, 1.7951],
              [34.8669, 2.5352],
              [34.5428, 3.1339],
              [34.4095, 3.6353],
              [33.9945, 4.2144],
              [34.3785, 4.62],
              [35.5088, 4.62],
              [35.9477, 4.62],
            ],
          ],
        },
        properties: { name: "Kenya" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [80.2456, 42.2209],
              [80.0734, 42.1059],
              [79.6793, 41.8428],
              [79.2841, 41.7331],
              [78.9227, 41.6328],
              [78.124, 41.0801],
              [76.8949, 40.9933],
              [76.3508, 40.3902],
              [75.7474, 40.329],
              [75.5366, 40.5992],
              [74.004, 40.0502],
              [73.8806, 39.5608],
              [73.6582, 39.4651],
              [73.4844, 39.4679],
              [72.2515, 39.2305],
              [71.4569, 39.5692],
              [70.8614, 39.4289],
              [70.4856, 39.5952],
              [69.5209, 39.5656],
              [69.3322, 39.7854],
              [69.5137, 40.0502],
              [69.6253, 40.1138],
              [70.0058, 40.2165],
              [70.5257, 40.0502],
              [70.6555, 40.0502],
              [70.9676, 40.2285],
              [71.7869, 40.2283],
              [72.7923, 40.7655],
              [71.8407, 41.3602],
              [71.2273, 41.179],
              [70.6296, 41.3903],
              [70.4493, 41.5111],
              [70.4682, 41.7331],
              [71.0708, 42.1059],
              [71.177, 42.1716],
              [70.9402, 42.2592],
              [71.0385, 42.3929],
              [71.555, 42.7757],
              [73.3125, 42.5357],
              [73.7513, 43.0076],
              [74.2231, 43.1398],
              [75.4801, 42.8656],
              [77.0929, 42.9409],
              [78.8408, 42.7334],
              [79.8198, 42.3929],
              [80.2456, 42.2209],
            ],
          ],
        },
        properties: { name: "Kyrgyzstan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [104.4386, 10.4245],
              [103.6272, 10.5957],
              [103.5771, 11.0007],
              [103.1292, 10.9936],
              [102.9109, 11.6472],
              [102.9011, 11.7642],
              [102.7276, 12.1572],
              [102.7756, 12.435],
              [102.524, 12.6873],
              [102.5082, 13.006],
              [102.3986, 13.1639],
              [102.4114, 13.5271],
              [103.1615, 14.3049],
              [105.2001, 14.3427],
              [106.0246, 13.9409],
              [106.0779, 14.3576],
              [106.6894, 14.4307],
              [106.9803, 14.3702],
              [107.5561, 14.6866],
              [107.407, 14.1765],
              [107.5795, 13.4943],
              [107.4792, 12.4166],
              [105.9956, 11.599],
              [106.102, 10.9073],
              [105.2582, 10.9203],
              [104.4386, 10.4245],
            ],
          ],
        },
        properties: { name: "Cambodia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [159.2966, -31.6438],
                [159.1312, -31.6991],
                [158.8469, -31.3638],
                [159.0007, -31.2949],
                [159.2966, -31.6438],
              ],
            ],
            [
              [
                [155.9704, -17.2969],
                [155.973, -17.556],
                [155.7584, -17.5196],
                [155.6617, -17.345],
                [155.8034, -17.2219],
                [155.9704, -17.2969],
              ],
            ],
            [
              [
                [143.3715, -12.6082],
                [143.8063, -14.3006],
                [144.5405, -14.2989],
                [145.232, -14.9531],
                [145.5013, -16.4924],
                [145.9725, -17.1891],
                [146.4035, -18.9198],
                [149.0378, -20.302],
                [149.0683, -20.4459],
                [148.7901, -20.6295],
                [149.249, -21.1971],
                [149.7084, -22.3402],
                [150.0021, -22.1271],
                [150.7057, -22.5271],
                [150.9186, -23.4939],
                [152.6114, -25.0964],
                [152.8872, -25.2023],
                [153.285, -25.029],
                [153.0982, -26.3843],
                [153.1753, -27.2224],
                [153.4694, -27.6758],
                [153.5647, -28.7456],
                [152.8692, -31.5895],
                [152.4464, -32.4572],
                [151.5009, -33.2363],
                [150.2193, -35.8375],
                [149.8727, -37.5021],
                [149.3792, -37.7606],
                [147.7855, -37.9714],
                [146.3968, -38.788],
                [146.2835, -39.0539],
                [145.0217, -38.4085],
                [144.887, -37.9609],
                [144.6176, -38.2848],
                [143.432, -38.777],
                [142.3993, -38.3878],
                [141.4856, -38.3568],
                [140.4176, -37.883],
                [139.8416, -37.2501],
                [139.7534, -36.5051],
                [139.3294, -35.8924],
                [138.8109, -35.5927],
                [138.2753, -35.5625],
                [138.4692, -34.8268],
                [138.128, -34.2953],
                [137.5221, -35.1263],
                [136.9587, -35.1346],
                [137.0529, -34.9514],
                [137.4216, -34.7907],
                [137.5221, -34.1005],
                [137.8794, -33.508],
                [137.9169, -32.8395],
                [137.5221, -33.0944],
                [136.0338, -34.4602],
                [135.9271, -34.8001],
                [135.6967, -34.8212],
                [135.4824, -34.6888],
                [134.8275, -33.4176],
                [134.3206, -33.0516],
                [134.1826, -32.6088],
                [133.6319, -32.2527],
                [132.2258, -31.9878],
                [131.2217, -31.5341],
                [129.2891, -31.663],
                [127.2572, -32.2475],
                [125.8716, -32.3456],
                [124.3256, -33.0179],
                [123.8423, -33.6636],
                [123.286, -33.9514],
                [120.033, -33.9575],
                [119.378, -34.4489],
                [117.8687, -35.073],
                [116.654, -35.0256],
                [116.0491, -34.81],
                [115.0278, -34.0106],
                [115.0605, -33.7253],
                [115.6402, -33.1935],
                [115.7013, -31.8384],
                [115.0463, -30.3686],
                [114.8743, -29.2035],
                [114.2243, -28.1324],
                [113.7431, -26.8072],
                [113.8982, -26.2244],
                [114.1168, -26.3553],
                [114.1982, -26.0276],
                [113.4607, -24.3896],
                [113.9093, -22.1271],
                [114.0134, -21.9227],
                [114.2925, -22.4072],
                [114.5846, -21.9122],
                [115.2139, -21.5393],
                [115.4685, -21.5224],
                [115.8755, -21.1998],
                [116.6373, -20.5215],
                [117.5124, -20.6781],
                [119.1302, -20.0296],
                [119.7266, -19.9948],
                [121.0812, -19.5399],
                [122.2487, -18.1397],
                [122.1926, -17.3851],
                [122.3787, -17.012],
                [122.8903, -16.6285],
                [123.426, -17.1848],
                [123.6661, -17.1235],
                [123.7459, -16.9045],
                [123.4248, -16.524],
                [123.42, -16.11],
                [124.3935, -16.25],
                [124.5049, -15.3163],
                [124.9746, -15.1558],
                [125.2466, -14.4512],
                [125.6992, -14.472],
                [125.9939, -14.2521],
                [126.0438, -13.9079],
                [126.5201, -14.1004],
                [126.8112, -13.8389],
                [127.3487, -13.9719],
                [128.0612, -14.6176],
                [128.2075, -15.0149],
                [128.5841, -14.8256],
                [129.536, -14.8953],
                [129.4737, -14.2464],
                [130.1384, -13.3701],
                [130.4102, -12.6682],
                [131.0988, -12.2102],
                [132.0646, -12.2717],
                [132.5565, -12.0334],
                [132.5268, -11.6283],
                [132.0463, -11.4709],
                [131.9684, -11.2603],
                [135.123, -12.2306],
                [135.8323, -12.1924],
                [136.496, -11.9267],
                [136.813, -12.4018],
                [136.5071, -13.0754],
                [135.9222, -13.4949],
                [136.167, -13.8234],
                [135.5535, -14.5414],
                [135.4682, -14.8617],
                [136.3046, -15.5358],
                [136.835, -15.5487],
                [136.881, -15.6719],
                [136.6733, -15.7347],
                [136.7543, -15.8652],
                [137.5221, -16.1578],
                [139.9767, -17.6244],
                [140.7841, -17.4143],
                [141.3251, -16.2907],
                [141.5836, -15.2458],
                [141.5086, -13.89],
                [141.7907, -12.7449],
                [141.7342, -12.2479],
                [142.1218, -11.1308],
                [142.4452, -10.8017],
                [142.7381, -11.1191],
                [142.901, -11.8067],
                [143.3715, -12.6082],
              ],
            ],
            [
              [
                [149.3052, -15.9431],
                [149.1402, -16.2252],
                [148.9648, -16.1924],
                [149.1548, -15.7879],
                [149.3052, -15.9431],
              ],
            ],
            [
              [
                [149.2777, -17.0875],
                [149.0543, -17.1564],
                [148.9714, -16.9853],
                [149.0282, -16.8454],
                [149.2765, -16.8752],
                [149.2777, -17.0875],
              ],
            ],
            [
              [
                [148.3998, -40.4443],
                [148.1487, -40.5268],
                [147.8945, -40.1393],
                [147.8945, -39.7542],
                [148.2461, -39.9791],
                [148.3998, -40.4443],
              ],
            ],
            [
              [
                [145.5098, -40.871],
                [146.175, -41.1528],
                [146.8238, -41.129],
                [146.9568, -41.0118],
                [148.1511, -40.8069],
                [148.278, -41.0944],
                [148.2549, -41.9098],
                [147.8895, -42.7506],
                [147.9785, -42.9314],
                [147.8895, -43.1614],
                [147.5227, -43.046],
                [147.4265, -43.2354],
                [146.8859, -43.5748],
                [146.0882, -43.5296],
                [145.4941, -42.9411],
                [145.2782, -42.5438],
                [145.3213, -42.1548],
                [144.8812, -41.6283],
                [144.6774, -41.129],
                [144.7947, -40.6765],
                [145.5098, -40.871],
              ],
            ],
            [
              [
                [144.1221, -40.0012],
                [143.9274, -40.1309],
                [143.847, -39.92],
                [143.963, -39.5844],
                [144.1125, -39.6906],
                [144.1221, -40.0012],
              ],
            ],
            [
              [
                [143.618, -9.687],
                [143.3843, -9.8851],
                [143.1337, -9.7773],
                [143.373, -9.5873],
                [143.618, -9.687],
              ],
            ],
            [
              [
                [142.3386, -10.7332],
                [142.1641, -10.8484],
                [142.0643, -10.5364],
                [141.9054, -10.4878],
                [141.9453, -10.2793],
                [142.2907, -10.0598],
                [142.4221, -10.159],
                [142.1947, -10.3637],
                [142.3386, -10.7332],
              ],
            ],
            [
              [
                [139.4754, -16.6758],
                [139.2364, -16.7256],
                [139.1597, -16.6319],
                [139.3119, -16.4637],
                [139.544, -16.3972],
                [139.6519, -16.4731],
                [139.4754, -16.6758],
              ],
            ],
            [
              [
                [138.2414, -35.8657],
                [138.0753, -35.9737],
                [137.7635, -35.804],
                [138.0132, -35.6618],
                [138.2414, -35.8657],
              ],
            ],
            [
              [
                [137.7266, -35.8128],
                [137.5253, -36.022],
                [136.6355, -35.9795],
                [136.6141, -35.7793],
                [137.3114, -35.5824],
                [137.5997, -35.5867],
                [137.7266, -35.8128],
              ],
            ],
            [
              [
                [136.8058, -13.8258],
                [136.8024, -14.2601],
                [136.4606, -14.2217],
                [136.3797, -13.6939],
                [136.6855, -13.6875],
                [136.8058, -13.8258],
              ],
            ],
            [
              [
                [130.4534, -11.2221],
                [130.6917, -11.3805],
                [131.1161, -11.2597],
                [131.417, -11.2588],
                [131.4688, -11.6059],
                [130.943, -11.9338],
                [130.6937, -11.7998],
                [130.2008, -11.7619],
                [130.1512, -11.7057],
                [130.2545, -11.3476],
                [130.4534, -11.2221],
              ],
            ],
            [
              [
                [123.7093, -12.6268],
                [123.6241, -12.7447],
                [123.4844, -12.6569],
                [123.4103, -12.4125],
                [123.5695, -12.3123],
                [123.7093, -12.6268],
              ],
            ],
            [
              [
                [122.3117, -16.9294],
                [122.074, -16.9798],
                [121.8675, -16.856],
                [121.9528, -16.7655],
                [122.2908, -16.7555],
                [122.3117, -16.9294],
              ],
            ],
            [
              [
                [113.7008, -26.0876],
                [113.5801, -26.0937],
                [113.4135, -25.6794],
                [113.4904, -25.5869],
                [113.7618, -25.8839],
                [113.7008, -26.0876],
              ],
            ],
            [
              [
                [113.5749, -26.4047],
                [113.2979, -26.4047],
                [113.1899, -26.1588],
                [113.3362, -26.0519],
                [113.5749, -26.4047],
              ],
            ],
          ],
        },
        properties: { name: "Australia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-70.1872, 12.5799],
              [-70.173, 12.7716],
              [-69.7737, 12.4417],
              [-69.7655, 12.2842],
              [-70.1872, 12.5799],
            ],
          ],
        },
        properties: { name: "Aruba" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [48.5735, 41.8254],
                [48.6559, 41.7331],
                [49.5501, 40.732],
                [50.1459, 40.4007],
                [49.5004, 40.0607],
                [49.2098, 39.2264],
                [48.9454, 38.9508],
                [48.8878, 38.4412],
                [48.5934, 38.4644],
                [48.1125, 38.8555],
                [48.2959, 39.3917],
                [47.9849, 39.664],
                [46.5458, 38.8781],
                [46.4532, 39.4681],
                [45.8952, 39.8651],
                [45.9149, 40.1148],
                [45.9168, 40.2364],
                [45.6126, 40.4017],
                [45.4264, 40.634],
                [45.4846, 40.9218],
                [45.0131, 41.2966],
                [46.5337, 41.1855],
                [46.5821, 41.3432],
                [46.2829, 41.7331],
                [46.4205, 41.9043],
                [46.7291, 41.7331],
                [47.6587, 41.2176],
                [48.5735, 41.8254],
              ],
            ],
            [
              [
                [46.1407, 38.8449],
                [45.6126, 38.9511],
                [44.812, 39.6317],
                [44.7683, 39.7141],
                [45.6126, 39.5652],
                [45.9781, 39.2712],
                [46.1407, 38.8449],
              ],
            ],
          ],
        },
        properties: { name: "Azerbaijan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [19.0221, 44.8554],
              [19.3512, 44.8891],
              [19.1422, 44.422],
              [19.4971, 44.0991],
              [19.4764, 43.6372],
              [19.2241, 43.5275],
              [18.7067, 43.1554],
              [18.4381, 42.5557],
              [18.1539, 42.6893],
              [17.4508, 43.1769],
              [16.5443, 43.9742],
              [16.4384, 44.0425],
              [16.1724, 44.2092],
              [16.1322, 44.4545],
              [15.7367, 44.9356],
              [15.84, 45.2245],
              [16.2537, 45.0676],
              [17.1412, 45.1633],
              [17.4433, 45.1597],
              [17.8339, 45.0615],
              [18.019, 45.1294],
              [18.5297, 45.0875],
              [18.7408, 45.0207],
              [18.8337, 44.8701],
              [19.0221, 44.8554],
            ],
          ],
        },
        properties: { name: "Bosnia and Herzegovina" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-59.7048, 13.2272],
              [-59.5985, 13.3256],
              [-59.3407, 13.1483],
              [-59.4786, 12.9895],
              [-59.6562, 13.0331],
              [-59.7048, 13.2272],
            ],
          ],
        },
        properties: { name: "Barbados" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [92.6068, 21.9712],
              [92.6238, 21.4616],
              [92.3081, 21.3804],
              [92.2021, 21.1507],
              [91.7732, 22.2937],
              [91.4733, 22.6557],
              [90.6837, 22.0496],
              [89.4416, 21.7456],
              [89.0628, 21.9257],
              [88.9238, 23.0935],
              [88.6343, 23.6942],
              [88.6844, 24.2282],
              [88.1499, 24.6608],
              [88.8173, 25.3069],
              [88.2105, 25.8776],
              [88.5924, 26.3771],
              [89.1233, 26.2142],
              [89.7217, 26.0307],
              [90.0071, 25.3633],
              [92.2478, 25.0052],
              [92.1082, 24.5094],
              [91.453, 24.0284],
              [91.2736, 23.6319],
              [91.6475, 23.1403],
              [92.0094, 23.5264],
              [92.1892, 23.5198],
              [92.6068, 21.9712],
            ],
          ],
        },
        properties: { name: "Bangladesh" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [6.021, 50.7543],
              [6.3156, 50.497],
              [6.405, 50.3233],
              [6.1377, 50.13],
              [6.0249, 50.1828],
              [5.7463, 49.8536],
              [5.8181, 49.5463],
              [5.7346, 49.5457],
              [5.4709, 49.4972],
              [5.3935, 49.6171],
              [5.1537, 49.7179],
              [4.9694, 49.8018],
              [4.7928, 50.147],
              [4.4325, 49.9416],
              [4.2331, 49.9578],
              [4.1409, 49.9788],
              [4.0278, 50.3583],
              [3.7407, 50.358],
              [3.6555, 50.4617],
              [3.6151, 50.4904],
              [3.3897, 50.5453],
              [3.2453, 50.713],
              [3.0985, 50.779],
              [3.0187, 50.7735],
              [3.0149, 50.7736],
              [3.0112, 50.7702],
              [3.0059, 50.7661],
              [2.9833, 50.7572],
              [2.9722, 50.7502],
              [2.9642, 50.75],
              [2.9566, 50.7529],
              [2.9401, 50.7453],
              [2.938, 50.7402],
              [2.9442, 50.732],
              [2.9372, 50.73],
              [2.9306, 50.7262],
              [2.93, 50.7117],
              [2.9223, 50.7028],
              [2.9116, 50.7031],
              [2.9092, 50.6945],
              [2.9004, 50.6933],
              [2.8864, 50.7057],
              [2.8701, 50.7029],
              [2.8633, 50.7083],
              [2.607, 50.9127],
              [2.546, 51.0894],
              [2.7491, 51.1618],
              [3.1107, 51.3123],
              [3.3658, 51.3698],
              [3.3807, 51.2743],
              [3.8563, 51.2111],
              [3.9777, 51.2251],
              [4.2348, 51.3483],
              [4.242, 51.354],
              [4.2437, 51.3747],
              [4.2796, 51.376],
              [4.6695, 51.4264],
              [4.7599, 51.5025],
              [5.1022, 51.429],
              [5.2377, 51.2616],
              [5.5663, 51.2208],
              [5.7983, 51.0599],
              [5.7661, 51.0087],
              [5.6876, 50.8119],
              [5.8191, 50.7146],
              [6.021, 50.7543],
            ],
          ],
        },
        properties: { name: "Belgium" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [0.2293, 14.9897],
              [0.1942, 14.5692],
              [0.4331, 14.031],
              [0.7453, 13.7626],
              [0.9463, 13.5898],
              [1.0371, 13.0366],
              [1.5993, 12.6512],
              [2.1167, 12.6689],
              [2.1412, 12.269],
              [2.4051, 11.8998],
              [2.0416, 11.5059],
              [1.4594, 11.3946],
              [0.9122, 10.9965],
              [0.7453, 11.019],
              [-0.142, 11.139],
              [-0.6868, 10.9912],
              [-1.8933, 10.9871],
              [-2.7279, 10.9884],
              [-2.7863, 10.8872],
              [-2.9125, 10.6685],
              [-2.7863, 10.0094],
              [-2.6869, 9.4907],
              [-2.7863, 9.5629],
              [-3.198, 9.8618],
              [-3.3739, 9.8912],
              [-3.6098, 9.9307],
              [-4.3699, 9.6182],
              [-4.812, 9.777],
              [-5.117, 10.2222],
              [-5.5189, 10.4333],
              [-5.2335, 11.6898],
              [-4.5928, 12.2275],
              [-4.1467, 13.1869],
              [-3.4208, 13.2926],
              [-2.6484, 14.1311],
              [-2.1123, 14.2218],
              [-1.8933, 14.4694],
              [-1.7859, 14.5221],
              [-0.721, 15.0452],
              [0.2293, 14.9897],
            ],
          ],
        },
        properties: { name: "Burkina Faso" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [28.5789, 43.7387],
              [28.498, 43.4341],
              [28.0602, 43.3164],
              [27.8826, 42.8384],
              [27.7205, 42.3929],
              [27.9411, 42.1059],
              [28.0355, 41.9831],
              [27.0597, 42.0883],
              [26.9492, 42.0002],
              [26.5615, 41.9263],
              [26.3579, 41.7111],
              [25.9486, 41.3203],
              [25.9064, 41.3076],
              [25.2247, 41.2646],
              [25.1794, 41.3102],
              [24.784, 41.3602],
              [24.4917, 41.5436],
              [24.0597, 41.5221],
              [23.6242, 41.3757],
              [23.4604, 41.4025],
              [22.9276, 41.3385],
              [22.9683, 41.5198],
              [22.9254, 41.7331],
              [22.8672, 42.0222],
              [22.6425, 42.1059],
              [22.5104, 42.1552],
              [22.3602, 42.3112],
              [22.4615, 42.6485],
              [22.4428, 42.8255],
              [22.5155, 42.8685],
              [22.7484, 42.8898],
              [23.0062, 43.1929],
              [22.7475, 43.3866],
              [22.667, 43.4027],
              [22.4069, 43.7517],
              [22.5361, 44.0455],
              [22.6752, 44.2157],
              [22.9664, 44.0983],
              [22.9972, 43.8079],
              [23.1554, 43.8123],
              [23.6304, 43.791],
              [24.112, 43.6999],
              [24.3235, 43.6977],
              [24.6548, 43.7236],
              [25.2964, 43.6554],
              [25.5445, 43.6428],
              [25.6722, 43.6881],
              [26.3582, 44.0372],
              [26.3818, 44.0386],
              [27.2713, 44.1263],
              [27.6954, 43.9873],
              [28.5789, 43.7387],
            ],
          ],
        },
        properties: { name: "Bulgaria" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [50.3613, 26.1572],
              [50.4618, 26.2513],
              [50.6533, 26.1748],
              [50.616, 25.8703],
              [50.4541, 25.9299],
              [50.3613, 26.1572],
            ],
          ],
        },
        properties: { name: "Bahrain" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [29.4233, -4.4475],
              [29.0406, -2.7438],
              [29.1824, -2.638],
              [29.7073, -2.7799],
              [30.0189, -2.4386],
              [30.5433, -2.4132],
              [30.472, -2.8532],
              [30.806, -3.0324],
              [30.8015, -3.2214],
              [30.0119, -4.2813],
              [29.4233, -4.4475],
            ],
          ],
        },
        properties: { name: "Burundi" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [3.5631, 11.7331],
              [3.7869, 10.624],
              [3.5493, 9.9526],
              [3.407, 9.7552],
              [2.7788, 8.8841],
              [2.7094, 6.3757],
              [1.6287, 6.2345],
              [1.7479, 6.3304],
              [1.5719, 6.8296],
              [1.6158, 8.974],
              [1.3304, 9.9647],
              [0.9463, 10.2593],
              [0.7968, 10.3989],
              [0.8005, 10.6685],
              [0.9122, 10.9965],
              [1.4594, 11.3946],
              [2.0416, 11.5059],
              [2.4051, 11.8998],
              [2.4326, 12.2297],
              [2.8313, 12.3625],
              [3.407, 11.8673],
              [3.5631, 11.7331],
            ],
          ],
        },
        properties: { name: "Benin" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-62.979, 17.9891],
              [-62.8672, 17.912],
              [-62.7774, 17.9639],
              [-62.6999, 17.8746],
              [-62.866, 17.8245],
              [-62.979, 17.9891],
            ],
          ],
        },
        properties: { name: "Saint Barthélemy" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-65.2669, 32.2352],
              [-65.1432, 32.1305],
              [-64.5569, 32.4167],
              [-64.5745, 32.5468],
              [-64.3621, 32.4789],
              [-64.8349, 32.1658],
              [-65.1885, 32.0922],
              [-65.2669, 32.2352],
            ],
          ],
        },
        properties: { name: "Bermuda" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [115.1546, 4.9128],
              [115.1809, 4.6253],
              [114.8561, 4.647],
              [114.6759, 4.1459],
              [114.0715, 4.5889],
              [114.9395, 4.9924],
              [115.1546, 4.9128],
            ],
            [
              [115.0116, 4.8917],
              [115.0194, 4.8894],
              [115.0208, 4.8858],
              [115.0139, 4.8753],
              [115.0228, 4.8731],
              [115.0228, 4.8704],
              [115.0148, 4.8563],
              [115.0184, 4.8524],
              [115.0227, 4.8354],
              [115.0173, 4.8184],
              [115.0173, 4.8131],
              [115.0317, 4.7987],
              [115.0341, 4.7979],
              [115.0359, 4.8044],
              [115.0306, 4.8176],
              [115.0314, 4.8232],
              [115.0448, 4.8388],
              [115.0531, 4.86],
              [115.0293, 4.8796],
              [115.0284, 4.8951],
              [115.0116, 4.8917],
            ],
          ],
        },
        properties: { name: "Brunei" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-58.1635, -20.1625],
              [-58.2561, -19.7822],
              [-59.1211, -19.2995],
              [-59.9788, -19.3035],
              [-61.658, -19.6476],
              [-62.2348, -20.5542],
              [-62.6104, -22.1424],
              [-62.6452, -22.2505],
              [-63.1001, -22.0135],
              [-63.774, -22.0199],
              [-64.3371, -22.6392],
              [-64.7318, -22.1889],
              [-66.1231, -21.8884],
              [-67.1775, -22.8047],
              [-67.8122, -22.812],
              [-67.9519, -22.1424],
              [-68.1699, -21.5829],
              [-68.186, -21.287],
              [-68.3472, -21.0169],
              [-68.7187, -20.3202],
              [-68.4825, -19.3753],
              [-68.8976, -18.9789],
              [-69.0894, -18.1169],
              [-69.4726, -17.499],
              [-69.5634, -17.2192],
              [-68.8869, -16.3343],
              [-69.2396, -16.0733],
              [-69.3798, -15.6492],
              [-69.1901, -15.2484],
              [-69.3052, -14.785],
              [-68.8868, -14.1931],
              [-68.9523, -12.9637],
              [-68.7259, -12.4796],
              [-69.5103, -10.9543],
              [-68.5679, -11.0587],
              [-66.6161, -9.9352],
              [-65.4168, -9.8233],
              [-65.2426, -11.4858],
              [-64.4633, -12.3126],
              [-63.4294, -12.6018],
              [-61.7725, -13.4912],
              [-61.0701, -13.5338],
              [-60.5969, -13.7761],
              [-60.1059, -16.1795],
              [-58.4129, -16.4288],
              [-58.3443, -17.1387],
              [-57.8067, -17.6314],
              [-57.6125, -18.2358],
              [-58.0283, -19.5601],
              [-57.9822, -19.9775],
              [-58.1635, -20.1625],
            ],
          ],
        },
        properties: { name: "Bolivia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-67.1775, -22.8047],
                [-67.0288, -23.0701],
                [-67.3388, -23.9728],
                [-68.1852, -24.386],
                [-68.5056, -24.7511],
                [-68.4182, -25.0728],
                [-68.5762, -25.3721],
                [-68.4207, -26.0951],
                [-68.5625, -26.4666],
                [-68.3337, -26.93],
                [-68.7444, -27.191],
                [-69.0586, -27.6623],
                [-69.6223, -28.4279],
                [-69.9632, -29.3913],
                [-69.8858, -30.2257],
                [-70.5245, -31.3244],
                [-70.0282, -32.9751],
                [-69.7883, -33.4437],
                [-69.8573, -34.177],
                [-70.4916, -35.194],
                [-70.4275, -36.05],
                [-71.0288, -36.5279],
                [-71.1446, -36.85],
                [-71.0074, -38.3952],
                [-70.8969, -38.6298],
                [-71.3805, -38.9559],
                [-71.6473, -39.7234],
                [-71.8698, -41.0051],
                [-71.7579, -42.0332],
                [-72.1151, -42.3196],
                [-72.0854, -42.897],
                [-71.774, -43.2167],
                [-71.8492, -43.481],
                [-71.5891, -43.6776],
                [-71.6484, -43.8615],
                [-71.7271, -44.2778],
                [-71.2402, -44.6116],
                [-71.7838, -44.8667],
                [-71.4171, -45.2791],
                [-71.7062, -45.6759],
                [-71.8582, -47.009],
                [-72.4113, -47.8215],
                [-72.3592, -48.2851],
                [-72.5453, -48.6615],
                [-73.3904, -49.2987],
                [-73.4824, -49.619],
                [-73.5128, -50.1948],
                [-73.3262, -50.5264],
                [-73.1348, -50.6998],
                [-72.7285, -50.6302],
                [-72.3501, -50.7088],
                [-72.3194, -51.5264],
                [-71.8645, -51.952],
                [-69.9918, -52.0205],
                [-68.7018, -52.3124],
                [-69.2271, -52.2196],
                [-69.4436, -52.3513],
                [-69.1363, -52.6596],
                [-68.7018, -52.5998],
                [-68.6086, -52.6495],
                [-68.6082, -53.5411],
                [-68.6075, -54.8934],
                [-68.6158, -54.9019],
                [-68.399, -54.9469],
                [-67.754, -54.9019],
                [-67.6519, -54.9064],
                [-67.3051, -55.1588],
                [-68.3668, -55.5442],
                [-69.4001, -55.5008],
                [-70.7663, -54.871],
                [-71.2784, -54.946],
                [-73.2332, -54.0189],
                [-73.8055, -53.2904],
                [-73.346, -53.2318],
                [-73.3566, -52.9965],
                [-74.8772, -52.1327],
                [-74.954, -51.4749],
                [-74.7321, -51.1646],
                [-74.9112, -50.7134],
                [-74.5482, -50.1266],
                [-74.8052, -50.1022],
                [-75.2986, -50.3995],
                [-75.5487, -49.0989],
                [-75.3736, -48.8505],
                [-75.4213, -48.3697],
                [-74.5858, -48.1136],
                [-74.2009, -47.5096],
                [-74.4279, -46.8667],
                [-75.3599, -46.8124],
                [-75.4953, -46.6339],
                [-74.4965, -45.4889],
                [-74.2857, -44.2389],
                [-73.7298, -43.9727],
                [-73.6399, -44.5323],
                [-73.4196, -44.6435],
                [-72.6044, -42.2863],
                [-72.8183, -41.733],
                [-73.1808, -41.8254],
                [-73.2645, -42.3159],
                [-73.624, -42.6409],
                [-73.6999, -43.1792],
                [-73.9552, -43.3108],
                [-74.1693, -43.1982],
                [-74.1247, -42.4395],
                [-73.7858, -41.7002],
                [-73.8086, -40.574],
                [-73.3248, -39.3449],
                [-73.5163, -37.3961],
                [-72.2518, -35.0886],
                [-71.5136, -32.6493],
                [-71.6509, -30.8281],
                [-71.3907, -29.8674],
                [-71.4575, -28.943],
                [-70.6623, -26.2968],
                [-70.4799, -25.0536],
                [-70.5185, -23.2234],
                [-70.2163, -21.8582],
                [-70.1569, -20.0275],
                [-70.3762, -18.3536],
                [-69.9206, -18.183],
                [-69.7443, -17.665],
                [-69.4726, -17.499],
                [-69.0894, -18.1169],
                [-68.8976, -18.9789],
                [-68.4825, -19.3753],
                [-68.7187, -20.3202],
                [-68.3472, -21.0169],
                [-68.186, -21.287],
                [-68.1699, -21.5829],
                [-67.9519, -22.1424],
                [-67.8122, -22.812],
                [-67.1775, -22.8047],
              ],
            ],
            [
              [
                [-67.4254, -55.5888],
                [-67.3754, -55.983],
                [-67.5914, -55.8994],
                [-67.7233, -55.7422],
                [-67.7233, -55.3884],
                [-67.4254, -55.5888],
              ],
            ],
            [
              [
                [-68.5111, -56.3814],
                [-68.5101, -56.5589],
                [-69.0158, -56.5093],
                [-68.9102, -56.3725],
                [-68.5111, -56.3814],
              ],
            ],
            [
              [
                [-69.0718, -55.7921],
                [-69.1137, -55.9825],
                [-69.5704, -55.7994],
                [-69.4497, -55.6961],
                [-69.0718, -55.7921],
              ],
            ],
            [
              [
                [-72.9266, -42.8634],
                [-73.1143, -42.885],
                [-73.1932, -42.7366],
                [-73.0779, -42.4871],
                [-72.9238, -42.4746],
                [-72.9906, -42.7002],
                [-72.9266, -42.8634],
              ],
            ],
            [
              [
                [-74.736, -47.9138],
                [-75.2478, -48.0023],
                [-75.2288, -47.8864],
                [-74.8877, -47.7275],
                [-74.736, -47.9138],
              ],
            ],
            [
              [
                [-78.9289, -33.5366],
                [-78.7724, -33.8838],
                [-79.0279, -33.823],
                [-79.1771, -33.5871],
                [-78.9289, -33.5366],
              ],
            ],
            [
              [
                [-79.6161, -26.3664],
                [-79.8105, -26.4758],
                [-80.1288, -26.3703],
                [-79.9074, -26.1932],
                [-79.6161, -26.3664],
              ],
            ],
            [
              [
                [-105.1993, -26.4128],
                [-105.2813, -26.6869],
                [-105.5616, -26.448],
                [-105.4088, -26.2983],
                [-105.1993, -26.4128],
              ],
            ],
          ],
        },
        properties: { name: "Chile" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [15.5075, 7.5275],
              [14.5004, 6.0684],
              [14.5544, 5.3255],
              [15.1777, 3.8331],
              [16.0122, 2.9309],
              [16.192, 2.2205],
              [16.0227, 1.7813],
              [14.5151, 2.1889],
              [13.299, 2.1706],
              [11.8459, 2.2795],
              [11.3346, 2.1739],
              [10.1506, 2.1693],
              [9.8215, 2.3478],
              [9.9418, 3.1147],
              [9.5717, 3.8991],
              [9.0721, 4.0643],
              [8.5392, 4.7005],
              [8.5638, 4.8182],
              [8.9762, 5.9087],
              [10.0174, 6.8872],
              [10.6677, 6.9687],
              [11.3091, 6.5958],
              [11.7815, 7.0341],
              [12.2559, 8.3308],
              [12.7578, 8.8108],
              [13.6915, 10.8135],
              [14.5709, 11.6606],
              [14.6064, 12.1066],
              [14.2342, 12.4916],
              [14.0854, 13.0781],
              [14.4197, 13.0406],
              [14.8196, 12.5718],
              [15.11, 11.7138],
              [15.1229, 10.7159],
              [15.5186, 10.0058],
              [14.3073, 9.9856],
              [14.0726, 9.6656],
              [14.6321, 8.9454],
              [15.2028, 8.553],
              [15.5075, 7.5275],
            ],
          ],
        },
        properties: { name: "Cameroon" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [130.6238, 42.45],
                [130.0276, 42.8517],
                [129.4314, 42.3929],
                [128.2927, 41.948],
                [128.0305, 41.4865],
                [126.716, 41.6181],
                [126.0071, 40.978],
                [124.3583, 40],
                [124.171, 39.8251],
                [123.3093, 39.7567],
                [121.8493, 39.1658],
                [121.5895, 39.6445],
                [122.1101, 40.5153],
                [121.2806, 40.8325],
                [119.5759, 39.8584],
                [118.9333, 39.2514],
                [118.0196, 39.1777],
                [117.6997, 38.9261],
                [117.719, 38.4562],
                [118.7738, 38.0871],
                [119.0286, 37.4139],
                [119.2678, 37.2265],
                [119.682, 37.2329],
                [120.8565, 37.7417],
                [122.3952, 37.3245],
                [122.2813, 36.9096],
                [121.3184, 36.6931],
                [120.1931, 36.0618],
                [119.3091, 34.9226],
                [119.4466, 34.6765],
                [120.2355, 34.2007],
                [120.8933, 32.697],
                [121.7869, 31.7912],
                [121.7599, 30.9648],
                [120.8367, 30.2761],
                [121.7266, 30.0425],
                [122.094, 30.1116],
                [122.1758, 29.9153],
                [121.6241, 29.0011],
                [121.4865, 28.3922],
                [120.857, 27.8813],
                [120.5222, 27.256],
                [119.8793, 26.5848],
                [119.6043, 25.7465],
                [118.91, 24.8786],
                [116.4097, 23.009],
                [114.2348, 22.5558],
                [114.0376, 22.5037],
                [113.626, 22.7011],
                [113.5509, 22.2145],
                [113.5331, 22.1856],
                [113.2517, 21.9123],
                [113.0244, 22.068],
                [112.6755, 21.7496],
                [110.6701, 21.3622],
                [110.339, 20.9293],
                [110.4282, 20.4521],
                [110.1936, 20.3096],
                [109.9482, 20.392],
                [109.7699, 20.728],
                [109.8039, 21.4003],
                [108.7886, 21.7088],
                [108.0236, 21.5489],
                [106.7942, 22.0576],
                [106.6447, 22.8138],
                [105.3207, 23.2985],
                [104.0941, 22.6352],
                [103.37, 22.7152],
                [102.9794, 22.5457],
                [102.5296, 22.6804],
                [102.1439, 22.4007],
                [101.6416, 22.2745],
                [101.7015, 21.2366],
                [101.2992, 21.2567],
                [101.1432, 21.5641],
                [100.9638, 21.6867],
                [100.2985, 21.5455],
                [99.8935, 22.0023],
                [99.3116, 22.1851],
                [99.4242, 22.9783],
                [98.9544, 23.2538],
                [98.7357, 24.0311],
                [97.6624, 23.9718],
                [97.6323, 24.7947],
                [98.6174, 25.8964],
                [98.7586, 26.6967],
                [98.6505, 27.4662],
                [98.3001, 27.67],
                [98.0277, 28.1945],
                [97.6607, 28.4374],
                [97.3471, 28.2081],
                [96.4079, 28.4508],
                [96.5583, 28.8405],
                [96.2042, 29.0341],
                [96.0434, 29.4013],
                [95.4413, 29.1482],
                [94.4925, 29.2273],
                [93.3732, 28.6225],
                [93.2491, 28.5107],
                [92.697, 28.0134],
                [92.5723, 27.901],
                [91.6421, 27.7585],
                [91.3732, 27.9617],
                [90.0071, 28.1425],
                [89.5442, 28.0925],
                [89.1233, 27.5369],
                [88.9233, 27.2728],
                [88.7725, 28.0233],
                [88.1351, 27.8819],
                [87.2191, 27.8565],
                [87.0575, 27.9138],
                [86.6441, 28.0606],
                [86.0082, 27.9649],
                [85.6902, 28.2767],
                [85.1957, 28.3587],
                [85.1125, 28.6226],
                [84.5559, 28.7241],
                [84.0841, 29.255],
                [83.6232, 29.2272],
                [83.2769, 29.5084],
                [81.7779, 30.3959],
                [81.4846, 30.4064],
                [81.1786, 30.0816],
                [81.0369, 30.2028],
                [80.2176, 30.583],
                [80.2241, 30.6046],
                [80.2058, 30.6217],
                [80.2061, 30.6279],
                [80.2201, 30.6353],
                [80.2218, 30.6396],
                [80.2064, 30.6634],
                [80.1928, 30.6743],
                [80.2044, 30.6848],
                [80.2244, 30.6883],
                [80.2383, 30.7087],
                [80.2545, 30.7176],
                [80.2554, 30.7378],
                [80.2475, 30.7563],
                [80.2385, 30.7621],
                [80.2269, 30.7622],
                [80.2208, 30.7651],
                [80.2144, 30.7763],
                [80.2025, 30.7824],
                [80.1945, 30.7983],
                [80.18, 30.8069],
                [80.165, 30.804],
                [80.1519, 30.7916],
                [80.139, 30.7844],
                [80.0915, 30.7919],
                [80.0753, 30.8041],
                [80.0453, 30.813],
                [80.0258, 30.8423],
                [80.0006, 30.867],
                [79.992, 30.8796],
                [79.9765, 30.8862],
                [79.9683, 30.8862],
                [79.9526, 30.8744],
                [79.4224, 31.064],
                [79.1149, 31.3794],
                [78.8882, 31.2723],
                [78.769, 31.9377],
                [78.6791, 32.0931],
                [78.4736, 32.2395],
                [78.398, 32.5232],
                [78.7005, 32.6471],
                [78.9227, 32.3637],
                [79.2537, 32.5233],
                [79.5132, 32.7273],
                [79.3416, 33.1356],
                [79.1467, 33.1883],
                [78.9227, 33.3924],
                [78.9826, 33.9808],
                [79.4735, 34.1377],
                [80.0029, 34.6687],
                [80.2549, 35.3651],
                [79.3089, 35.9267],
                [77.9244, 35.4936],
                [77.318, 35.4964],
                [76.1908, 35.877],
                [75.8285, 36.6678],
                [75.3164, 36.9453],
                [74.5646, 37.0309],
                [74.5722, 37.2118],
                [74.8899, 37.2341],
                [75.096, 37.4764],
                [74.7852, 38.5064],
                [73.7953, 38.664],
                [73.6582, 39.4651],
                [73.8806, 39.5608],
                [74.004, 40.0502],
                [75.5366, 40.5992],
                [75.7474, 40.329],
                [76.3508, 40.3902],
                [76.8949, 40.9933],
                [78.124, 41.0801],
                [78.9227, 41.6328],
                [79.2841, 41.7331],
                [79.6793, 41.8428],
                [80.0734, 42.1059],
                [80.2456, 42.2209],
                [80.2371, 42.3929],
                [80.6646, 43.3477],
                [80.2882, 44.9512],
                [81.5111, 45.2789],
                [82.375, 45.2653],
                [82.4415, 45.832],
                [83.0421, 47.0395],
                [84.825, 46.9384],
                [85.3679, 47.0945],
                [85.7846, 48.2473],
                [87.3126, 49.0997],
                [87.825, 49.1833],
                [88.164, 48.5182],
                [88.8093, 48.1353],
                [89.1233, 48.0475],
                [90.0071, 47.8003],
                [90.8926, 46.7843],
                [90.9625, 46.1517],
                [90.7868, 45.5843],
                [90.9154, 45.324],
                [92.697, 45.042],
                [93.6971, 44.8837],
                [95.0889, 44.3062],
                [96.5054, 42.8216],
                [99.3602, 42.5821],
                [100.937, 42.6407],
                [101.5726, 42.45],
                [101.7629, 42.3929],
                [103.412, 41.8982],
                [104.7319, 41.7331],
                [104.9939, 41.6306],
                [105.3061, 41.7331],
                [106.4412, 42.1059],
                [107.315, 42.3929],
                [109.382, 42.4713],
                [110.2689, 42.7433],
                [111.7456, 43.6738],
                [111.5444, 44.3827],
                [111.8923, 44.9111],
                [113.6553, 44.836],
                [114.6043, 45.3288],
                [115.8268, 45.5901],
                [116.9594, 46.3931],
                [118.2867, 46.7064],
                [119.7421, 46.8031],
                [119.5494, 47.2549],
                [118.4115, 47.9874],
                [117.8818, 47.99],
                [117.3447, 47.7443],
                [115.7462, 47.8633],
                [115.726, 48.3132],
                [116.7209, 49.8498],
                [117.8074, 49.5794],
                [119.1095, 50.0654],
                [119.9519, 51.4479],
                [120.6644, 52.0663],
                [120.6094, 52.4875],
                [120.1502, 52.6928],
                [120.208, 52.7974],
                [120.9957, 53.2739],
                [123.3487, 53.5256],
                [125.4695, 53.0542],
                [126.48, 52.1446],
                [127.6584, 49.7757],
                [129.5609, 49.3475],
                [130.3126, 48.931],
                [131.1145, 47.7422],
                [131.9987, 47.7036],
                [134.6067, 48.3298],
                [134.6406, 47.6454],
                [134.1834, 47.2206],
                [133.8238, 46.2524],
                [133.0211, 45.139],
                [131.8756, 45.2894],
                [131.2039, 44.8571],
                [131.1583, 43.1226],
                [130.46, 42.6893],
                [130.5711, 42.6363],
                [130.6238, 42.45],
              ],
            ],
            [
              [
                [121.6525, 39.0179],
                [121.5156, 38.7935],
                [121.1792, 38.8744],
                [121.3766, 39.0768],
                [121.6525, 39.0179],
              ],
            ],
            [
              [
                [116.8984, 20.7791],
                [116.9517, 20.5385],
                [116.6036, 20.6042],
                [116.4792, 20.751],
                [116.8984, 20.7791],
              ],
            ],
            [
              [
                [110.6812, 20.139],
                [110.9933, 19.6819],
                [110.6583, 19.3126],
                [110.4176, 18.6723],
                [110.3237, 18.6723],
                [109.5218, 18.21],
                [108.8271, 18.4595],
                [108.7016, 18.6723],
                [108.6957, 19.3258],
                [109.4167, 19.8652],
                [109.6572, 19.9902],
                [110.037, 19.9477],
                [110.1603, 20.0432],
                [110.5712, 20.0432],
                [110.6812, 20.139],
              ],
            ],
            [
              [
                [109.2882, 21.0897],
                [109.1969, 20.9009],
                [108.9371, 20.9479],
                [108.9618, 21.1177],
                [109.1447, 21.2118],
                [109.2882, 21.0897],
              ],
            ],
          ],
        },
        properties: { name: "China" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [121.6339, 24.001],
                [120.7859, 21.9547],
                [120.0743, 23.0877],
                [120.3462, 24.001],
                [120.7267, 24.6108],
                [121.2063, 25.1118],
                [121.526, 25.2491],
                [121.7688, 25.1615],
                [121.928, 24.9948],
                [121.6339, 24.001],
              ],
            ],
          ],
        },
        properties: { name: "Taiwan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-66.8698, 1.2256],
                [-67.053, 1.2347],
                [-67.1246, 1.7233],
                [-67.4033, 2.0748],
                [-67.9063, 1.7868],
                [-68.1691, 1.952],
                [-68.4389, 1.7354],
                [-69.7411, 1.7222],
                [-69.8199, 1.1472],
                [-69.2979, 1.0299],
                [-69.1631, 0.7249],
                [-70.0018, 0.5188],
                [-70.0201, 0.252],
                [-70.0325, 0.0707],
                [-70.0455, -0.1179],
                [-69.6569, -0.5135],
                [-69.5206, -0.8786],
                [-69.4207, -1.1466],
                [-69.517, -1.766],
                [-69.6069, -2.2733],
                [-69.9534, -4.2305],
                [-70.5831, -3.7195],
                [-70.1573, -2.707],
                [-70.8922, -2.3015],
                [-72.9094, -2.42],
                [-74.2103, -0.8786],
                [-74.5531, -0.4725],
                [-75.2882, -0.0937],
                [-76.1433, 0.3503],
                [-77.1784, 0.3755],
                [-78.7509, 1.3888],
                [-78.8299, 1.46],
                [-78.9409, 1.6692],
                [-78.649, 1.8523],
                [-78.5197, 2.4711],
                [-77.8031, 2.6963],
                [-77.1966, 3.6179],
                [-77.1966, 3.8358],
                [-77.4736, 4.1128],
                [-77.3908, 5.1931],
                [-77.3768, 6.5361],
                [-77.893, 7.2218],
                [-77.2491, 7.9096],
                [-77.3675, 8.6728],
                [-76.8545, 8.1711],
                [-76.7509, 8.6538],
                [-75.6626, 9.543],
                [-75.4211, 10.6021],
                [-74.9272, 10.994],
                [-73.2096, 11.361],
                [-71.6858, 12.3922],
                [-71.2438, 12.2088],
                [-71.3241, 11.8457],
                [-71.9211, 11.6612],
                [-72.7638, 10.6322],
                [-73.3126, 9.2426],
                [-72.8017, 9.0532],
                [-72.4152, 8.3431],
                [-72.3943, 7.4355],
                [-71.9631, 7.0539],
                [-70.0816, 6.9418],
                [-69.3548, 6.1185],
                [-67.5876, 6.2176],
                [-67.4644, 5.9946],
                [-67.8207, 5.1931],
                [-67.8354, 4.4178],
                [-67.3557, 3.4424],
                [-67.721, 2.8964],
                [-67.2208, 2.3424],
                [-66.8698, 1.2256],
              ],
            ],
            [
              [
                [-81.6496, 12.1222],
                [-81.7201, 12.0434],
                [-82.0257, 12.1255],
                [-82.0559, 12.2338],
                [-81.8332, 12.3566],
                [-81.6496, 12.1222],
              ],
            ],
          ],
        },
        properties: { name: "Colombia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-109.4225, 10.3676],
              [-109.3337, 10.4888],
              [-109.0251, 10.3615],
              [-109.2315, 10.1217],
              [-109.4225, 10.3676],
            ],
          ],
        },
        properties: { name: "Clipperton Island" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-83.6929, 10.9389],
                [-83.2913, 10.2475],
                [-82.5646, 9.572],
                [-82.9148, 9.3744],
                [-82.7781, 8.9311],
                [-82.9936, 8.3047],
                [-82.9004, 8.0398],
                [-83.2399, 8.5852],
                [-83.5973, 8.5528],
                [-83.7582, 9.0982],
                [-84.72, 9.8332],
                [-85.1356, 9.6863],
                [-85.6179, 9.9526],
                [-85.788, 10.3238],
                [-85.6948, 11.0803],
                [-84.6235, 11.0333],
                [-83.9461, 10.7432],
                [-83.6929, 10.9389],
              ],
            ],
            [
              [
                [-86.8714, 5.5178],
                [-87.139, 5.3445],
                [-87.2699, 5.4705],
                [-87.1732, 5.6042],
                [-86.8876, 5.6556],
                [-86.8714, 5.5178],
              ],
            ],
          ],
        },
        properties: { name: "Costa Rica" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-80.7927, 23.2314],
                [-80.3015, 22.9185],
                [-80.1475, 23.0896],
                [-80.0102, 22.9185],
                [-79.6882, 22.8847],
                [-79.6882, 22.7549],
                [-79.237, 22.4071],
                [-78.1189, 22.1349],
                [-77.9062, 22.2326],
                [-77.6506, 21.7738],
                [-77.3719, 21.7581],
                [-76.9956, 21.52],
                [-75.7122, 21.0869],
                [-75.5304, 20.8008],
                [-74.7817, 20.6089],
                [-74.2065, 20.1628],
                [-75.141, 19.9082],
                [-77.6514, 19.8409],
                [-77.4936, 20.1126],
                [-77.1257, 20.4183],
                [-77.2683, 20.6525],
                [-77.4936, 20.6717],
                [-78.2602, 20.8656],
                [-78.6391, 21.52],
                [-78.8347, 21.6068],
                [-79.2225, 21.555],
                [-79.6882, 21.682],
                [-80.5515, 22.0569],
                [-81.8095, 22.1806],
                [-82.0464, 22.3689],
                [-81.6881, 22.4685],
                [-81.8095, 22.6605],
                [-82.8384, 22.5965],
                [-83.0706, 22.4898],
                [-83.3656, 22.2078],
                [-83.8897, 22.1742],
                [-84.0947, 21.9151],
                [-84.4389, 21.8999],
                [-84.3845, 22.4228],
                [-83.8897, 22.7202],
                [-83.3556, 22.9185],
                [-82.3231, 23.1525],
                [-81.8095, 23.149],
                [-81.1668, 23.0847],
                [-80.7927, 23.2314],
              ],
            ],
            [
              [
                [-78.1494, 22.4968],
                [-78.069, 22.3098],
                [-78.3505, 22.3118],
                [-78.5818, 22.5031],
                [-78.4575, 22.6604],
                [-78.1494, 22.4968],
              ],
            ],
            [
              [
                [-82.6183, 21.5187],
                [-82.8466, 21.4411],
                [-83.1218, 21.5187],
                [-83.0307, 21.8254],
                [-82.8353, 21.9283],
                [-82.496, 21.733],
                [-82.6183, 21.5187],
              ],
            ],
          ],
        },
        properties: { name: "Cuba" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-22.6286, 16.0611],
                [-22.8344, 15.9359],
                [-23.0065, 16.0611],
                [-22.8765, 16.2542],
                [-22.7028, 16.2196],
                [-22.6286, 16.0611],
              ],
            ],
            [
              [
                [-23.0363, 15.1297],
                [-23.157, 15.0276],
                [-23.319, 15.1183],
                [-23.2308, 15.4072],
                [-23.0734, 15.3733],
                [-23.0363, 15.1297],
              ],
            ],
            [
              [
                [-23.4074, 14.9603],
                [-23.5034, 14.8595],
                [-23.8304, 15.0653],
                [-23.6342, 15.2625],
                [-23.4074, 14.9603],
              ],
            ],
            [
              [
                [-24.2242, 14.8452],
                [-24.4445, 14.7451],
                [-24.5924, 14.9295],
                [-24.3733, 15.1233],
                [-24.2242, 14.8452],
              ],
            ],
            [
              [
                [-24.9271, 17.1146],
                [-25.2871, 16.8756],
                [-25.3939, 17.0384],
                [-25.0606, 17.2238],
                [-24.9271, 17.1146],
              ],
            ],
          ],
        },
        properties: { name: "Cape Verde" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [1.7258, 42.5044],
              [1.4956, 42.4736],
              [1.4426, 42.6037],
              [1.7861, 42.5737],
              [1.7258, 42.5044],
            ],
          ],
        },
        properties: { name: "Andorra" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [56.2658, 25.6247],
              [56.3647, 25.5033],
              [56.3742, 24.9804],
              [56.1415, 24.8043],
              [55.851, 24.8578],
              [55.8942, 24.1886],
              [55.5272, 23.8382],
              [55.2083, 22.7083],
              [52.6453, 22.9764],
              [51.5906, 24.243],
              [52.1447, 24.0061],
              [54.08, 24.2199],
              [56.0846, 26.0505],
              [56.0871, 26.0513],
              [56.2658, 25.6247],
            ],
          ],
        },
        properties: { name: "United Arab Emirates" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [74.8899, 37.2341],
              [74.5722, 37.2118],
              [74.5646, 37.0309],
              [73.6393, 36.9034],
              [73.2944, 36.8773],
              [72.3359, 36.7654],
              [71.2997, 36.1094],
              [71.6228, 35.1948],
              [71.2242, 34.7534],
              [70.9817, 34.0291],
              [69.9165, 33.9708],
              [70.2571, 33.3559],
              [69.5333, 33.0026],
              [69.2828, 32.1149],
              [68.9944, 31.6561],
              [68.2247, 31.7982],
              [67.8555, 31.6214],
              [67.5736, 31.2768],
              [66.9026, 31.2606],
              [66.4208, 30.9063],
              [66.2579, 29.8854],
              [65.2412, 29.5782],
              [62.4953, 29.403],
              [60.873, 29.8585],
              [61.6934, 30.7331],
              [61.8061, 31.0542],
              [61.6443, 31.3638],
              [60.8832, 31.5377],
              [60.8565, 32.1149],
              [60.6222, 33.069],
              [60.799, 33.4155],
              [60.5205, 34.0684],
              [60.8534, 34.3412],
              [61.2741, 35.6051],
              [62.2905, 35.2271],
              [62.7395, 35.2921],
              [63.2646, 35.8341],
              [64.4207, 36.2878],
              [64.8799, 37.0996],
              [65.7703, 37.4986],
              [66.5537, 37.3548],
              [67.7787, 37.1796],
              [67.8555, 37.0629],
              [68.0528, 36.9765],
              [68.9042, 37.2831],
              [69.2866, 37.1431],
              [69.4312, 37.4764],
              [70.1791, 37.5831],
              [70.2538, 37.9541],
              [70.8413, 38.4213],
              [71.3126, 38.2515],
              [71.3153, 37.9763],
              [71.5601, 37.8039],
              [71.5235, 37.4764],
              [71.4851, 36.9595],
              [71.8083, 36.7149],
              [73.2944, 37.4584],
              [73.8567, 37.2769],
              [74.5767, 37.4012],
              [74.8899, 37.2341],
            ],
          ],
        },
        properties: { name: "Afghanistan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-61.999, 17.1139],
              [-61.6956, 17.2266],
              [-61.5387, 17.0178],
              [-61.9731, 16.969],
              [-61.999, 17.1139],
            ],
          ],
        },
        properties: { name: "Antigua and Barbuda" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-63.2563, 18.1237],
              [-63.128, 18.1817],
              [-63.0849, 18.248],
              [-62.9099, 18.3163],
              [-62.9461, 18.2326],
              [-63.1074, 18.1429],
              [-63.2563, 18.1237],
            ],
          ],
        },
        properties: { name: "Anguilla" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [20.0763, 42.5558],
              [20.2649, 42.3929],
              [20.4701, 42.2156],
              [20.5181, 42.1059],
              [20.5943, 41.8773],
              [20.5629, 41.8461],
              [20.5607, 41.7331],
              [20.5578, 41.5819],
              [20.5017, 41.3172],
              [20.5977, 41.092],
              [20.8378, 40.9277],
              [20.9802, 40.8557],
              [21.0561, 40.6167],
              [20.7785, 40.3488],
              [20.6129, 40.0797],
              [20.3833, 39.9827],
              [20.3913, 39.7885],
              [20.3023, 39.8127],
              [20.01, 39.6918],
              [19.9824, 39.7035],
              [19.9957, 39.8519],
              [19.885, 40.0039],
              [19.5715, 40.1747],
              [19.3188, 40.645],
              [19.4425, 41.0005],
              [19.5178, 41.2687],
              [19.5573, 41.5777],
              [19.5353, 41.7331],
              [19.5201, 41.8403],
              [19.3725, 41.8441],
              [19.3841, 42.1059],
              [19.3924, 42.2956],
              [19.488, 42.3929],
              [19.6623, 42.5469],
              [19.831, 42.4664],
              [20.0763, 42.5558],
            ],
          ],
        },
        properties: { name: "Albania" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [46.5458, 38.8781],
              [46.1407, 38.8449],
              [45.9781, 39.2712],
              [45.6126, 39.5652],
              [44.7683, 39.7141],
              [44.3752, 40.001],
              [43.6539, 40.1304],
              [43.7018, 40.7773],
              [43.4696, 41.0573],
              [43.4738, 41.1233],
              [45.0131, 41.2966],
              [45.4846, 40.9218],
              [45.4264, 40.634],
              [45.6126, 40.4017],
              [45.9168, 40.2364],
              [45.9149, 40.1148],
              [45.8952, 39.8651],
              [46.4532, 39.4681],
              [46.5458, 38.8781],
            ],
          ],
        },
        properties: { name: "Armenia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [23.983, -10.9113],
                [24.0347, -12.3467],
                [23.8836, -12.9513],
                [22.0281, -13.0808],
                [22.1075, -16.3334],
                [23.4299, -17.6345],
                [21.7196, -17.9666],
                [19.0299, -17.8279],
                [18.2636, -17.3914],
                [14.0264, -17.3778],
                [13.2413, -16.9985],
                [11.7509, -17.2525],
                [11.7666, -15.9191],
                [12.2657, -14.7705],
                [12.5454, -13.4814],
                [13.6519, -12.2049],
                [13.8308, -10.9113],
                [13.0383, -9.1111],
                [13.368, -8.6957],
                [13.3455, -8.3207],
                [12.4007, -6.2987],
                [12.8501, -5.9411],
                [16.4629, -5.9179],
                [17.0219, -7.2667],
                [17.7714, -8.0899],
                [19.2527, -7.9545],
                [19.8263, -6.9975],
                [20.4216, -6.9498],
                [20.7264, -7.2513],
                [21.6619, -7.309],
                [21.9191, -8.4515],
                [21.8433, -9.4449],
                [22.2739, -10.3412],
                [22.2761, -11.0428],
                [23.0649, -11.0941],
                [23.983, -10.9113],
              ],
            ],
            [
              [
                [12.1955, -5.7639],
                [11.9892, -4.9993],
                [12.7888, -4.4786],
                [13.1018, -4.6851],
                [12.5746, -5.0613],
                [12.4994, -5.6868],
                [12.1955, -5.7639],
              ],
            ],
          ],
        },
        properties: { name: "Angola" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-62.6452, -22.2505],
                [-61.9084, -23.0815],
                [-60.9507, -23.795],
                [-59.9462, -24.0628],
                [-57.6904, -25.2889],
                [-57.6289, -25.5065],
                [-58.5741, -27.253],
                [-56.4174, -27.5161],
                [-56.1084, -27.3439],
                [-55.7448, -27.4423],
                [-55.4625, -27.0428],
                [-54.7923, -26.5932],
                [-54.5941, -25.5923],
                [-53.8961, -25.7766],
                [-53.6653, -26.3689],
                [-53.8273, -27.051],
                [-55.6513, -28.1901],
                [-57.619, -30.1759],
                [-58.1316, -31.8015],
                [-58.1303, -33.0265],
                [-58.3743, -33.136],
                [-58.4914, -33.4943],
                [-58.4834, -33.702],
                [-58.4566, -34.4633],
                [-57.2109, -35.3391],
                [-57.3462, -35.9249],
                [-56.7541, -36.4367],
                [-56.7878, -37.0294],
                [-57.6193, -38.1231],
                [-57.9886, -38.3253],
                [-60.6751, -38.9478],
                [-61.8092, -38.9887],
                [-61.9758, -39.1231],
                [-62.1663, -39.0175],
                [-62.1826, -39.0117],
                [-62.1998, -39.0113],
                [-62.2164, -39.0164],
                [-62.2304, -39.0265],
                [-62.2405, -39.0406],
                [-62.2456, -39.0571],
                [-62.2454, -39.0745],
                [-62.1117, -39.7951],
                [-62.3525, -40.3306],
                [-62.1751, -40.5378],
                [-62.7461, -41.0215],
                [-63.6197, -41.1504],
                [-65.0042, -40.8392],
                [-65.1398, -41.0948],
                [-65.0425, -41.8371],
                [-64.6583, -42.3566],
                [-64.8868, -42.7454],
                [-64.5576, -43.016],
                [-65.2511, -43.6178],
                [-65.3166, -43.8615],
                [-65.2571, -44.331],
                [-65.647, -44.7515],
                [-65.5962, -45.0064],
                [-66.9428, -45.263],
                [-67.5674, -46.0023],
                [-67.4974, -46.4579],
                [-67.392, -46.5923],
                [-67.1426, -46.7074],
                [-66.9428, -46.8651],
                [-65.9098, -47.116],
                [-65.7435, -47.3694],
                [-65.9201, -48.0645],
                [-66.9428, -48.6064],
                [-67.5227, -49.016],
                [-67.6201, -49.3262],
                [-67.8641, -49.94],
                [-68.8488, -50.3421],
                [-69.0991, -50.67],
                [-68.9347, -51.6166],
                [-68.5314, -52.1567],
                [-68.399, -52.2936],
                [-68.4322, -52.3975],
                [-68.7018, -52.3124],
                [-69.9918, -52.0205],
                [-71.8645, -51.952],
                [-72.3194, -51.5264],
                [-72.3501, -50.7088],
                [-72.7285, -50.6302],
                [-73.1348, -50.6998],
                [-73.3262, -50.5264],
                [-73.5128, -50.1948],
                [-73.4824, -49.619],
                [-73.3904, -49.2987],
                [-72.5453, -48.6615],
                [-72.3592, -48.2851],
                [-72.4113, -47.8215],
                [-71.8582, -47.009],
                [-71.7062, -45.6759],
                [-71.4171, -45.2791],
                [-71.7838, -44.8667],
                [-71.2402, -44.6116],
                [-71.7271, -44.2778],
                [-71.6484, -43.8615],
                [-71.5891, -43.6776],
                [-71.8492, -43.481],
                [-71.774, -43.2167],
                [-72.0854, -42.897],
                [-72.1151, -42.3196],
                [-71.7579, -42.0332],
                [-71.8698, -41.0051],
                [-71.6473, -39.7234],
                [-71.3805, -38.9559],
                [-70.8969, -38.6298],
                [-71.0074, -38.3952],
                [-71.1446, -36.85],
                [-71.0288, -36.5279],
                [-70.4275, -36.05],
                [-70.4916, -35.194],
                [-69.8573, -34.177],
                [-69.7883, -33.4437],
                [-70.0282, -32.9751],
                [-70.5245, -31.3244],
                [-69.8858, -30.2257],
                [-69.9632, -29.3913],
                [-69.6223, -28.4279],
                [-69.0586, -27.6623],
                [-68.7444, -27.191],
                [-68.3337, -26.93],
                [-68.5625, -26.4666],
                [-68.4207, -26.0951],
                [-68.5762, -25.3721],
                [-68.4182, -25.0728],
                [-68.5056, -24.7511],
                [-68.1852, -24.386],
                [-67.3388, -23.9728],
                [-67.0288, -23.0701],
                [-67.1775, -22.8047],
                [-66.1231, -21.8884],
                [-64.7318, -22.1889],
                [-64.3371, -22.6392],
                [-63.774, -22.0199],
                [-63.1001, -22.0135],
                [-62.6452, -22.2505],
              ],
            ],
            [
              [
                [-63.622, -42.7699],
                [-64.1122, -42.8904],
                [-64.2681, -42.7552],
                [-64.2271, -42.627],
                [-64.4189, -42.4845],
                [-64.0614, -42.3769],
                [-64.082, -42.1686],
                [-63.7576, -42.0628],
                [-63.6078, -42.2787],
                [-63.622, -42.7699],
              ],
            ],
            [
              [
                [-63.999, -54.7849],
                [-64.2264, -54.9118],
                [-64.4464, -54.7703],
                [-64.1295, -54.5903],
                [-63.999, -54.7849],
              ],
            ],
            [
              [
                [-68.6075, -54.8934],
                [-68.6082, -53.5411],
                [-68.6086, -52.6495],
                [-68.3954, -52.9204],
                [-68.4324, -53.2536],
                [-67.4603, -53.9586],
                [-66.2523, -54.5256],
                [-65.3835, -54.6442],
                [-65.3179, -54.7799],
                [-65.3917, -54.898],
                [-66.4723, -55.0265],
                [-68.0502, -54.846],
                [-68.6075, -54.8934],
              ],
            ],
          ],
        },
        properties: { name: "Argentina" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-171.0195, -14.3773],
              [-170.6403, -14.1571],
              [-170.5857, -14.2729],
              [-170.7849, -14.5267],
              [-171.0195, -14.3773],
            ],
          ],
        },
        properties: { name: "American Samoa" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [16.9403, 48.6172],
              [16.9498, 48.5358],
              [16.8511, 48.4386],
              [16.9762, 48.1722],
              [17.0667, 48.1187],
              [17.1608, 48.0067],
              [16.9914, 47.72],
              [16.4215, 47.6653],
              [16.6462, 47.4466],
              [16.4338, 47.3529],
              [16.4594, 47.0441],
              [16.1138, 46.8691],
              [15.9962, 46.8354],
              [15.7864, 46.7075],
              [15.65, 46.7058],
              [15.402, 46.6535],
              [15.0651, 46.6521],
              [14.6746, 46.4507],
              [14.5652, 46.3725],
              [14.4345, 46.4429],
              [13.7142, 46.5227],
              [13.5042, 46.5663],
              [12.7314, 46.6343],
              [12.6906, 46.657],
              [12.4779, 46.6798],
              [12.2117, 46.8968],
              [12.2407, 47.0692],
              [12.136, 47.0807],
              [11.8572, 47.0038],
              [11.6272, 47.0133],
              [11.1643, 46.9657],
              [10.9817, 46.8074],
              [10.4697, 46.8549],
              [10.145, 46.851],
              [9.6071, 47.0608],
              [9.6206, 47.1516],
              [9.5307, 47.2706],
              [9.5587, 47.5419],
              [9.9678, 47.5462],
              [9.9995, 47.483],
              [10.1784, 47.2701],
              [10.419, 47.3881],
              [10.4544, 47.5558],
              [10.8862, 47.5368],
              [10.9912, 47.3961],
              [11.0165, 47.3964],
              [11.4214, 47.4449],
              [11.4102, 47.4953],
              [11.6329, 47.5924],
              [11.8572, 47.602],
              [12.0607, 47.6187],
              [12.338, 47.6971],
              [12.575, 47.6323],
              [12.6958, 47.6822],
              [13.0461, 47.5205],
              [12.9593, 47.8754],
              [12.8758, 47.9626],
              [12.8602, 47.9966],
              [12.7516, 48.1128],
              [12.9447, 48.2067],
              [13.177, 48.2944],
              [13.4564, 48.5588],
              [13.4633, 48.5544],
              [13.4751, 48.556],
              [13.4765, 48.5633],
              [13.4855, 48.5624],
              [13.4916, 48.5648],
              [13.4938, 48.5692],
              [13.5042, 48.573],
              [13.5063, 48.5765],
              [13.5031, 48.5837],
              [13.5066, 48.5896],
              [13.5097, 48.59],
              [13.7271, 48.513],
              [13.7961, 48.7136],
              [13.8395, 48.7716],
              [14.691, 48.5843],
              [15.0403, 48.9668],
              [15.5424, 48.9077],
              [15.7536, 48.8522],
              [16.9403, 48.6172],
            ],
          ],
        },
        properties: { name: "Austria" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-68.5308, 12.3126],
              [-68.4701, 12.411],
              [-68.1175, 12.2437],
              [-68.111, 12.0789],
              [-68.2476, 12.0769],
              [-68.5308, 12.3126],
            ],
          ],
        },
        properties: { name: "Bonaire, Sint Eustatius and Saba" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-29.149, -20.4968],
                [-29.4064, -20.6874],
                [-29.5118, -20.4398],
                [-29.4459, -20.2808],
                [-29.149, -20.4968],
              ],
            ],
            [
              [
                [-29.1999, 0.9741],
                [-29.2323, 0.683],
                [-29.5286, 0.9462],
                [-29.464, 1.1052],
                [-29.1999, 0.9741],
              ],
            ],
            [
              [
                [-56.4774, 1.9459],
                [-56.0897, 1.9331],
                [-55.8909, 2.4178],
                [-55.0333, 2.5492],
                [-54.5879, 2.3221],
                [-54.1804, 2.1379],
                [-53.7392, 2.2956],
                [-52.966, 2.2123],
                [-52.5292, 2.588],
                [-51.6555, 4.056],
                [-51.4718, 4.3036],
                [-51.1503, 3.9792],
                [-50.7314, 2.3536],
                [-49.9735, 1.5367],
                [-50.1581, 0.5286],
                [-49.671, 0.252],
                [-49.5423, 0.0707],
                [-48.4877, -0.3838],
                [-48.6017, -0.8786],
                [-48.7243, -1.4109],
                [-48.4925, -1.3663],
                [-48.0778, -0.8786],
                [-47.896, -0.6648],
                [-47.2356, -0.6835],
                [-46.6886, -0.8786],
                [-45.6863, -1.2363],
                [-45.4124, -1.4762],
                [-44.7663, -1.5308],
                [-44.6547, -1.766],
                [-44.5033, -2.2733],
                [-44.3967, -2.6305],
                [-43.4509, -2.4341],
                [-41.5256, -2.8667],
                [-40.024, -2.8996],
                [-38.5537, -3.7185],
                [-37.1805, -4.8144],
                [-35.5716, -5.2793],
                [-35.2181, -5.7609],
                [-34.9246, -6.7583],
                [-34.9096, -8.0417],
                [-35.4473, -9.2894],
                [-37.0799, -11.0563],
                [-38.088, -12.5827],
                [-38.8672, -13.2038],
                [-39.0183, -14.6246],
                [-38.9357, -15.9485],
                [-39.1746, -17.4734],
                [-39.6543, -18.5131],
                [-39.8568, -19.5645],
                [-40.7067, -20.8939],
                [-41.1183, -21.9585],
                [-42.2084, -22.8766],
                [-44.4428, -23.1122],
                [-45.3959, -23.8004],
                [-46.33, -24.0058],
                [-48.1332, -25.2447],
                [-48.5494, -26.0651],
                [-48.4988, -27.6248],
                [-48.8159, -28.4568],
                [-49.817, -29.5314],
                [-50.7132, -30.9112],
                [-50.8901, -30.9417],
                [-50.8869, -30.5116],
                [-51.3091, -30.7064],
                [-51.9917, -31.5832],
                [-52.6172, -32.9915],
                [-53.378, -33.7504],
                [-53.5095, -33.3089],
                [-53.3047, -32.9751],
                [-53.1514, -32.7404],
                [-53.83, -32.0405],
                [-55.5446, -30.9301],
                [-55.7448, -30.9943],
                [-55.9687, -31.002],
                [-56.1653, -30.6435],
                [-56.8071, -30.1365],
                [-57.3474, -30.2723],
                [-57.619, -30.1759],
                [-55.6513, -28.1901],
                [-53.8273, -27.051],
                [-53.6653, -26.3689],
                [-53.8961, -25.7766],
                [-54.5941, -25.5923],
                [-54.3851, -24.0606],
                [-54.6633, -23.8829],
                [-55.3922, -23.8396],
                [-55.8971, -22.3237],
                [-56.4163, -22.1247],
                [-57.0908, -22.2421],
                [-57.8684, -22.0614],
                [-57.863, -20.9629],
                [-58.1635, -20.1625],
                [-57.9822, -19.9775],
                [-58.0283, -19.5601],
                [-57.6125, -18.2358],
                [-57.8067, -17.6314],
                [-58.3443, -17.1387],
                [-58.4129, -16.4288],
                [-60.1059, -16.1795],
                [-60.5969, -13.7761],
                [-61.0701, -13.5338],
                [-61.7725, -13.4912],
                [-63.4294, -12.6018],
                [-64.4633, -12.3126],
                [-65.2426, -11.4858],
                [-65.4168, -9.8233],
                [-66.6161, -9.9352],
                [-68.5679, -11.0587],
                [-69.5103, -10.9543],
                [-70.5742, -10.9321],
                [-70.631, -9.6918],
                [-71.3377, -9.9639],
                [-72.0116, -9.9595],
                [-72.3919, -9.5555],
                [-72.9782, -9.3412],
                [-73.0739, -8.8492],
                [-73.8818, -7.5555],
                [-73.712, -6.95],
                [-73.1725, -6.3431],
                [-72.6878, -5.0578],
                [-71.899, -4.564],
                [-70.8737, -4.2367],
                [-69.9534, -4.2305],
                [-69.6069, -2.2733],
                [-69.517, -1.766],
                [-69.4207, -1.1466],
                [-69.5206, -0.8786],
                [-69.6569, -0.5135],
                [-70.0455, -0.1179],
                [-70.0325, 0.0707],
                [-70.0201, 0.252],
                [-70.0018, 0.5188],
                [-69.1631, 0.7249],
                [-69.2979, 1.0299],
                [-69.8199, 1.1472],
                [-69.7411, 1.7222],
                [-68.4389, 1.7354],
                [-68.1691, 1.952],
                [-67.9063, 1.7868],
                [-67.4033, 2.0748],
                [-67.1246, 1.7233],
                [-67.053, 1.2347],
                [-66.8698, 1.2256],
                [-66.2879, 0.8221],
                [-65.6926, 0.9257],
                [-65.4471, 0.8038],
                [-64.2898, 1.4973],
                [-63.4774, 2.2297],
                [-63.5034, 2.3999],
                [-64.0031, 2.6686],
                [-64.5692, 4.1305],
                [-62.9241, 3.7234],
                [-62.4283, 4.0929],
                [-61.0145, 4.5424],
                [-60.7041, 4.8701],
                [-60.7318, 5.1961],
                [-60.1578, 5.1926],
                [-60.0054, 4.5779],
                [-59.642, 4.1358],
                [-59.9399, 2.6147],
                [-59.511, 1.7084],
                [-58.8112, 1.2685],
                [-57.2818, 1.9505],
                [-56.4774, 1.9459],
              ],
            ],
            [
              [
                [-51.2522, -31.6331],
                [-51.4852, -31.7964],
                [-51.6325, -31.7099],
                [-51.4244, -31.4022],
                [-51.2522, -31.6331],
              ],
            ],
          ],
        },
        properties: { name: "Brazil" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-73.1493, 20.9629],
                [-73.6893, 20.9268],
                [-73.7181, 21.0135],
                [-73.3883, 21.2411],
                [-73.0939, 21.1267],
                [-73.1493, 20.9629],
              ],
            ],
            [
              [
                [-74.0223, 22.1938],
                [-74.0715, 22.0152],
                [-74.5221, 22.1416],
                [-74.3805, 22.2736],
                [-74.0223, 22.1938],
              ],
            ],
            [
              [
                [-74.9795, 23.2998],
                [-75.0108, 23.0301],
                [-75.3351, 23.169],
                [-75.2003, 23.3688],
                [-74.9795, 23.2998],
              ],
            ],
            [
              [
                [-76.5124, 25.3883],
                [-76.8707, 25.3176],
                [-76.8752, 25.5264],
                [-76.5767, 25.6062],
                [-76.5124, 25.3883],
              ],
            ],
            [
              [
                [-77.0504, 26.3037],
                [-77.1951, 26.2581],
                [-77.2769, 26.4217],
                [-77.4308, 26.6343],
                [-77.4133, 26.8136],
                [-77.022, 26.582],
                [-77.0395, 26.4217],
                [-77.0504, 26.3037],
              ],
            ],
            [
              [
                [-77.0938, 25.9844],
                [-77.2876, 25.8603],
                [-77.428, 26.0231],
                [-77.3605, 26.1749],
                [-77.1485, 26.1845],
                [-77.0938, 25.9844],
              ],
            ],
            [
              [
                [-77.5251, 23.9231],
                [-77.6502, 23.7113],
                [-77.8477, 23.8001],
                [-77.864, 24.0619],
                [-77.6502, 24.2187],
                [-77.5251, 23.9231],
              ],
            ],
            [
              [
                [-77.6868, 24.3623],
                [-77.891, 24.1782],
                [-78.4268, 24.6332],
                [-78.1335, 25.1679],
                [-77.9749, 25.0498],
                [-77.6868, 24.3623],
              ],
            ],
            [
              [
                [-77.6902, 25.8231],
                [-77.8002, 25.7253],
                [-78.0892, 25.7283],
                [-78.1598, 25.7985],
                [-78.1081, 25.8748],
                [-77.7586, 25.945],
                [-77.6902, 25.8231],
              ],
            ],
            [
              [
                [-78.4492, 26.6183],
                [-78.696, 26.4226],
                [-78.9533, 26.5993],
                [-78.5452, 26.7947],
                [-78.4492, 26.6183],
              ],
            ],
          ],
        },
        properties: { name: "Bahamas" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [91.6421, 27.7585],
              [91.6596, 27.5403],
              [92.0573, 27.3683],
              [92.1079, 26.8583],
              [90.0071, 26.7529],
              [89.7443, 26.7397],
              [89.1233, 26.8977],
              [89.0273, 26.9609],
              [88.9233, 27.2728],
              [89.1233, 27.5369],
              [89.5442, 28.0925],
              [90.0071, 28.1425],
              [91.3732, 27.9617],
              [91.6421, 27.7585],
            ],
          ],
        },
        properties: { name: "Bhutan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [44.6336, -12.299],
                [44.5193, -12.4303],
                [44.2599, -12.2072],
                [44.4497, -12.0245],
                [44.6336, -12.299],
              ],
            ],
            [
              [
                [43.8492, -12.2743],
                [43.7563, -12.504],
                [43.5732, -12.476],
                [43.5401, -12.2329],
                [43.6108, -12.1556],
                [43.8492, -12.2743],
              ],
            ],
            [
              [
                [43.3976, -11.3608],
                [43.4694, -11.8271],
                [43.2213, -11.7449],
                [43.2747, -11.3832],
                [43.3976, -11.3608],
              ],
            ],
          ],
        },
        properties: { name: "Comoros" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-62.9797, 17.4048],
              [-62.846, 17.5142],
              [-62.6684, 17.3285],
              [-62.6529, 17.2963],
              [-62.6847, 17.2366],
              [-62.8002, 17.2452],
              [-62.9797, 17.4048],
            ],
          ],
        },
        properties: { name: "Saint Kitts and Nevis" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [130.6238, 42.45],
              [130.6749, 42.2836],
              [129.8444, 41.7331],
              [129.5878, 40.8241],
              [127.6243, 39.7112],
              [127.5348, 39.3078],
              [128.3613, 38.6149],
              [128.0712, 38.3378],
              [127.159, 38.287],
              [126.6658, 37.833],
              [126.6505, 37.8017],
              [126.6544, 37.7888],
              [126.6201, 37.788],
              [126.6084, 37.7824],
              [126.5949, 37.7701],
              [126.578, 37.7691],
              [126.5577, 37.7866],
              [126.5283, 37.803],
              [126.5163, 37.8069],
              [126.5062, 37.8141],
              [126.4733, 37.8168],
              [126.4582, 37.8393],
              [126.423, 37.8529],
              [126.4055, 37.8779],
              [126.4164, 37.9],
              [126.4147, 37.9058],
              [126.4109, 37.9084],
              [126.3926, 37.8852],
              [126.3372, 37.8757],
              [126.2816, 37.8473],
              [126.2724, 37.8469],
              [126.2639, 37.8432],
              [126.2106, 37.8635],
              [126.2114, 37.8545],
              [126.2085, 37.8498],
              [126.1937, 37.8455],
              [126.1567, 37.8347],
              [126.1518, 37.8219],
              [126.1444, 37.8082],
              [126.1333, 37.7972],
              [126.1196, 37.7899],
              [126.1043, 37.7868],
              [126.096, 37.7869],
              [125.7654, 37.9466],
              [125.3655, 37.8399],
              [124.9484, 38.1557],
              [124.9571, 38.5202],
              [125.2639, 38.7013],
              [125.3446, 39.432],
              [125.0612, 39.5911],
              [124.6655, 39.4497],
              [124.3583, 40],
              [126.0071, 40.978],
              [126.716, 41.6181],
              [128.0305, 41.4865],
              [128.2927, 41.948],
              [129.4314, 42.3929],
              [130.0276, 42.8517],
              [130.6238, 42.45],
            ],
          ],
        },
        properties: { name: "North Korea" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-68.7404, 12.0681],
                [-68.8129, 12.0275],
                [-68.9567, 12.072],
                [-69.0009, 12.1451],
                [-68.9327, 12.1936],
                [-68.7926, 12.1652],
                [-68.7404, 12.0681],
              ],
            ],
            [
              [
                [-68.9581, 12.2085],
                [-69.0259, 12.1305],
                [-69.2531, 12.282],
                [-69.3065, 12.5395],
                [-69.0997, 12.4728],
                [-68.9581, 12.2085],
              ],
            ],
          ],
        },
        properties: { name: "Curaçao" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [105.425, -10.5147],
              [105.7299, -10.3025],
              [105.8258, -10.3326],
              [105.6829, -10.6863],
              [105.425, -10.5147],
            ],
          ],
        },
        properties: { name: "Christmas Island" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [33.6251, 34.8511],
              [32.9417, 34.6418],
              [32.559, 34.6875],
              [32.3694, 34.8511],
              [32.4435, 35.0778],
              [33.0172, 35.3338],
              [34.0072, 35.4317],
              [34.1708, 35.5577],
              [34.543, 35.6809],
              [34.5581, 35.641],
              [34.0609, 35.3269],
              [33.9628, 35.3196],
              [33.9299, 35.06],
              [33.6251, 34.8511],
            ],
          ],
        },
        properties: { name: "Cyprus" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [14.8234, 50.8706],
              [15.1816, 50.9574],
              [15.5353, 50.7794],
              [16.1073, 50.6621],
              [16.3325, 50.5925],
              [16.5803, 50.1428],
              [16.8633, 50.1981],
              [16.9079, 50.4495],
              [17.4296, 50.2545],
              [18.0351, 50.0658],
              [18.5757, 49.9104],
              [18.8516, 49.5172],
              [18.4036, 49.3967],
              [18.3224, 49.3151],
              [17.6469, 48.8543],
              [17.3967, 48.8133],
              [16.9403, 48.6172],
              [15.7536, 48.8522],
              [15.5424, 48.9077],
              [15.0403, 48.9668],
              [14.691, 48.5843],
              [13.8395, 48.7716],
              [13.5516, 48.9678],
              [13.4167, 48.98],
              [13.1709, 49.1736],
              [12.6338, 49.4762],
              [12.5938, 49.5422],
              [12.4015, 49.7584],
              [12.551, 49.9051],
              [12.2608, 50.0582],
              [12.1607, 50.2198],
              [12.1009, 50.318],
              [12.5838, 50.4071],
              [12.9481, 50.4043],
              [13.5018, 50.6336],
              [13.6522, 50.7304],
              [14.1512, 50.8516],
              [14.3179, 51.0547],
              [14.4912, 51.0435],
              [14.6188, 50.8578],
              [14.8234, 50.8706],
            ],
          ],
        },
        properties: { name: "Czechia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [9.4222, 54.832],
                [9.4685, 54.8172],
                [9.4919, 54.8226],
                [9.7781, 54.7277],
                [9.7944, 54.5625],
                [10.1685, 54.4328],
                [10.1742, 54.3457],
                [10.7138, 54.3051],
                [11.0064, 54.3232],
                [10.8413, 53.9919],
                [10.9037, 53.9568],
                [11.5618, 54.0281],
                [11.9985, 54.175],
                [12.2011, 54.2447],
                [12.2855, 54.275],
                [13.2531, 54.5684],
                [13.548, 54.4363],
                [13.3194, 54.1935],
                [14.2263, 53.9287],
                [14.2131, 53.8665],
                [14.0982, 53.7654],
                [14.2675, 53.6978],
                [14.4122, 53.3296],
                [14.1437, 52.9614],
                [14.1567, 52.8956],
                [14.4364, 52.6799],
                [14.5651, 52.6245],
                [14.5344, 52.395],
                [14.6009, 52.2721],
                [14.7552, 52.07],
                [14.7167, 52.0012],
                [14.7299, 51.5818],
                [14.9742, 51.3639],
                [14.8234, 50.8706],
                [14.6188, 50.8578],
                [14.4912, 51.0435],
                [14.3179, 51.0547],
                [14.1512, 50.8516],
                [13.6522, 50.7304],
                [13.5018, 50.6336],
                [12.9481, 50.4043],
                [12.5838, 50.4071],
                [12.1009, 50.318],
                [12.1607, 50.2198],
                [12.2608, 50.0582],
                [12.551, 49.9051],
                [12.4015, 49.7584],
                [12.5938, 49.5422],
                [12.6338, 49.4762],
                [13.1709, 49.1736],
                [13.4167, 48.98],
                [13.5516, 48.9678],
                [13.8395, 48.7716],
                [13.7961, 48.7136],
                [13.7271, 48.513],
                [13.5097, 48.59],
                [13.5066, 48.5896],
                [13.5031, 48.5837],
                [13.5063, 48.5765],
                [13.5042, 48.573],
                [13.4938, 48.5692],
                [13.4916, 48.5648],
                [13.4855, 48.5624],
                [13.4765, 48.5633],
                [13.4751, 48.556],
                [13.4633, 48.5544],
                [13.4564, 48.5588],
                [13.177, 48.2944],
                [12.9447, 48.2067],
                [12.7516, 48.1128],
                [12.8602, 47.9966],
                [12.8758, 47.9626],
                [12.9593, 47.8754],
                [13.0461, 47.5205],
                [12.6958, 47.6822],
                [12.575, 47.6323],
                [12.338, 47.6971],
                [12.0607, 47.6187],
                [11.8572, 47.602],
                [11.6329, 47.5924],
                [11.4102, 47.4953],
                [11.4214, 47.4449],
                [11.0165, 47.3964],
                [10.9912, 47.3961],
                [10.8862, 47.5368],
                [10.4544, 47.5558],
                [10.419, 47.3881],
                [10.1784, 47.2701],
                [9.9995, 47.483],
                [9.9678, 47.5462],
                [9.5587, 47.5419],
                [9.4956, 47.5515],
                [9.1822, 47.6559],
                [8.6705, 47.6849],
                [8.6634, 47.6859],
                [8.6138, 47.8011],
                [8.5101, 47.7762],
                [8.5149, 47.6272],
                [8.4264, 47.5675],
                [7.8941, 47.5864],
                [7.7138, 47.5394],
                [7.6341, 47.5611],
                [7.589, 47.5899],
                [7.546, 47.7436],
                [7.5814, 47.9145],
                [7.5773, 48.1157],
                [7.5779, 48.1214],
                [7.6807, 48.2573],
                [7.9596, 48.7186],
                [8.2326, 48.9666],
                [8.0684, 48.9993],
                [7.9107, 49.0452],
                [7.6357, 49.054],
                [7.3688, 49.1615],
                [7.2643, 49.1338],
                [7.1011, 49.156],
                [6.7235, 49.2188],
                [6.557, 49.4192],
                [6.3671, 49.4695],
                [6.3801, 49.5511],
                [6.475, 49.8213],
                [6.1377, 50.13],
                [6.405, 50.3233],
                [6.3156, 50.497],
                [6.021, 50.7543],
                [6.0869, 50.9131],
                [5.8771, 51.0321],
                [6.1748, 51.1845],
                [6.0727, 51.2426],
                [6.2244, 51.365],
                [5.9532, 51.7478],
                [6.1678, 51.9008],
                [6.4078, 51.8281],
                [6.6817, 51.9324],
                [6.7605, 52.1186],
                [7.0657, 52.2414],
                [7.0041, 52.3996],
                [6.6979, 52.4863],
                [6.7097, 52.6278],
                [7.0062, 52.6388],
                [7.0927, 52.8382],
                [7.2028, 53.1133],
                [7.2089, 53.2431],
                [7.2643, 53.3255],
                [7.0927, 53.3725],
                [7.4917, 53.68],
                [7.8099, 53.7077],
                [8.0913, 53.6381],
                [8.1422, 53.555],
                [8.0613, 53.506],
                [8.1956, 53.4092],
                [8.5549, 53.5251],
                [8.5204, 53.6062],
                [8.6133, 53.7654],
                [8.6872, 53.8919],
                [8.8326, 54.0239],
                [8.8448, 54.2663],
                [8.6359, 54.9117],
                [8.889, 54.8915],
                [9.1131, 54.8736],
                [9.2736, 54.8117],
                [9.4222, 54.832],
              ],
            ],
            [
              [
                [8.7077, 53.9405],
                [8.4781, 53.8105],
                [8.3246, 53.8376],
                [8.4763, 54.0527],
                [8.7077, 53.9405],
              ],
            ],
            [
              [
                [8.0547, 54.2508],
                [7.9827, 53.9925],
                [7.6358, 54.2677],
                [7.8982, 54.3426],
                [8.0547, 54.2508],
              ],
            ],
          ],
        },
        properties: { name: "Germany" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [43.2603, 11.4606],
              [42.9681, 10.9974],
              [41.8348, 11.0448],
              [41.816, 11.6292],
              [42.4043, 12.4686],
              [42.6867, 12.4073],
              [43.1261, 12.7101],
              [43.3732, 12.1093],
              [42.7821, 11.6427],
              [43.2603, 11.4606],
            ],
          ],
        },
        properties: { name: "Djibouti" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [15.1629, 55.0937],
                [15.094, 54.9962],
                [14.948, 55.0011],
                [14.6968, 55.1306],
                [14.774, 55.2754],
                [14.948, 55.2179],
                [15.1629, 55.0937],
              ],
            ],
            [
              [
                [12.525, 55.9115],
                [12.5828, 55.808],
                [12.5841, 55.722],
                [12.7503, 55.6491],
                [12.6192, 55.5715],
                [12.5018, 55.6274],
                [12.3635, 55.5938],
                [12.2196, 55.4251],
                [12.2912, 55.207],
                [11.9573, 54.6835],
                [11.3756, 54.8133],
                [11.4797, 55.0525],
                [11.2262, 55.3129],
                [11.2326, 55.6431],
                [11.5259, 55.8584],
                [11.9424, 55.8223],
                [12.1495, 56.095],
                [12.5176, 56.0562],
                [12.525, 55.9115],
              ],
            ],
            [
              [
                [10.8837, 55.0372],
                [10.8037, 54.8744],
                [10.4282, 54.8835],
                [10.3072, 54.8835],
                [9.7656, 55.369],
                [10.0455, 55.5688],
                [10.6948, 55.5438],
                [10.8837, 55.0372],
              ],
            ],
            [
              [
                [9.4222, 54.832],
                [9.2736, 54.8117],
                [9.1131, 54.8736],
                [8.889, 54.8915],
                [8.6359, 54.9117],
                [8.1897, 55.5629],
                [8.1837, 55.8223],
                [8.1907, 56.5531],
                [8.6728, 56.527],
                [8.889, 56.7146],
                [9.3048, 56.693],
                [9.0659, 56.9285],
                [8.889, 56.9243],
                [8.7173, 56.7262],
                [8.3249, 56.8385],
                [8.6317, 57.1018],
                [8.889, 57.1206],
                [10.283, 57.5981],
                [10.4957, 57.3511],
                [10.199, 56.6836],
                [10.8182, 56.4896],
                [10.8509, 56.3228],
                [9.9457, 55.7305],
                [9.6721, 55.7121],
                [9.8384, 54.999],
                [9.6229, 54.8818],
                [9.4222, 54.832],
              ],
            ],
          ],
        },
        properties: { name: "Denmark" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-61.5123, 15.5686],
              [-61.2352, 15.5425],
              [-61.2355, 15.2169],
              [-61.3652, 15.1829],
              [-61.5123, 15.5686],
            ],
          ],
        },
        properties: { name: "Dominica" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-71.7536, 18.0307],
              [-71.8766, 18.5572],
              [-71.6752, 19.1658],
              [-71.7579, 19.702],
              [-71.0165, 19.8737],
              [-70.1124, 19.6603],
              [-69.6952, 19.3275],
              [-69.285, 19.281],
              [-69.3763, 19.0976],
              [-68.7534, 18.908],
              [-68.4468, 18.57],
              [-68.6771, 18.2116],
              [-69.7448, 18.4306],
              [-70.3492, 18.2502],
              [-70.8751, 18.3096],
              [-71.4373, 17.7791],
              [-71.7536, 18.0307],
            ],
          ],
        },
        properties: { name: "Dominican Republic" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [8.6421, 36.9412],
              [8.3435, 36.5284],
              [8.4128, 35.1988],
              [8.2407, 34.6847],
              [7.5356, 33.8878],
              [7.7925, 33.2741],
              [8.6877, 32.3104],
              [9.0952, 31.9812],
              [9.5516, 30.2464],
              [9.4464, 30.0952],
              [9.8055, 29.2946],
              [9.9363, 27.8899],
              [9.8837, 26.6543],
              [9.4591, 26.143],
              [9.9784, 25.3828],
              [10.1392, 24.8009],
              [10.4325, 24.5274],
              [11.6027, 24.2015],
              [11.9985, 23.5127],
              [8.7142, 21.6026],
              [7.4448, 20.8222],
              [5.8191, 19.4689],
              [4.243, 19.1389],
              [3.5631, 19.0665],
              [3.407, 19.0499],
              [3.2945, 19.0379],
              [3.182, 19.8421],
              [1.8141, 20.3039],
              [1.235, 20.7351],
              [0.9463, 21.2628],
              [0.7453, 21.3959],
              [0.5578, 21.5201],
              [-1.7859, 23.0319],
              [-1.8933, 23.1012],
              [-2.7863, 23.6772],
              [-3.3739, 24.0562],
              [-4.8333, 24.9976],
              [-8.6667, 27.3127],
              [-8.6666, 27.662],
              [-8.6296, 28.5136],
              [-8.3109, 28.8968],
              [-7.1492, 29.458],
              [-5.5291, 29.6274],
              [-4.9056, 30.1258],
              [-3.8126, 30.6884],
              [-3.5844, 31.5104],
              [-3.0082, 31.7988],
              [-2.7778, 32.0677],
              [-1.2332, 32.2639],
              [-1.1187, 32.5223],
              [-1.5962, 33.2215],
              [-1.8091, 34.6952],
              [-2.2097, 35.0846],
              [-1.8933, 35.1581],
              [-1.7859, 35.183],
              [-1.5608, 35.2352],
              [-0.8764, 35.7128],
              [-0.0547, 35.8673],
              [0.7453, 36.3212],
              [0.9549, 36.4402],
              [2.5173, 36.6076],
              [2.9693, 36.7924],
              [3.407, 36.8057],
              [3.5631, 36.8104],
              [3.8798, 36.9112],
              [4.7588, 36.8859],
              [5.3146, 36.6516],
              [6.1198, 36.8823],
              [6.4162, 37.063],
              [6.9707, 36.8953],
              [7.353, 37.0632],
              [7.8867, 36.8665],
              [8.6421, 36.9412],
            ],
          ],
        },
        properties: { name: "Algeria" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-80.2733, -3.4262],
                [-80.2896, -3.4106],
                [-80.0893, -2.9775],
                [-80.7308, -2.2506],
                [-80.8365, -1.3353],
                [-80.6015, -0.8786],
                [-80.0197, 0.252],
                [-80.009, 0.7931],
                [-78.7509, 1.3888],
                [-77.1784, 0.3755],
                [-76.1433, 0.3503],
                [-75.2882, -0.0937],
                [-75.511, -0.2279],
                [-75.2664, -0.8786],
                [-75.6084, -1.582],
                [-75.7923, -1.766],
                [-76.3212, -2.2733],
                [-76.5151, -2.4592],
                [-78.0291, -3.2721],
                [-79.0222, -4.799],
                [-80.2973, -4.3463],
                [-80.2733, -3.4262],
              ],
            ],
            [
              [
                [-89.3141, -0.8786],
                [-89.3676, -0.9753],
                [-89.6306, -0.9132],
                [-89.5928, -0.8786],
                [-89.2874, -0.599],
                [-89.189, -0.6522],
                [-89.3141, -0.8786],
              ],
            ],
            [
              [
                [-90.2343, -0.7503],
                [-90.3987, -0.7812],
                [-90.5575, -0.6282],
                [-90.2875, -0.3823],
                [-90.162, -0.5731],
                [-90.2343, -0.7503],
              ],
            ],
            [
              [
                [-90.2194, -1.247],
                [-90.4877, -1.4671],
                [-90.6339, -1.3532],
                [-90.5386, -1.1297],
                [-90.2194, -1.247],
              ],
            ],
            [
              [
                [-90.5618, -0.1994],
                [-90.6005, -0.4734],
                [-90.9233, -0.2625],
                [-90.8506, -0.1152],
                [-90.5618, -0.1994],
              ],
            ],
            [
              [
                [-91.0745, -0.8786],
                [-91.1901, -0.9842],
                [-91.3584, -0.8786],
                [-91.2651, -0.5463],
                [-91.5239, -0.3004],
                [-91.3957, 0.0588],
                [-90.9264, -0.6562],
                [-91.0745, -0.8786],
              ],
            ],
          ],
        },
        properties: { name: "Ecuador" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [27.3516, 57.5182],
                [26.4549, 57.5549],
                [25.0463, 58.0401],
                [24.8341, 57.9728],
                [24.3528, 57.8766],
                [24.5109, 58.2945],
                [23.9606, 58.2911],
                [23.5211, 58.5818],
                [23.5183, 59.1406],
                [23.7306, 59.2373],
                [25.8302, 59.5641],
                [26.0153, 59.6195],
                [26.7594, 59.4994],
                [28.0419, 59.4701],
                [28.1202, 59.3526],
                [27.357, 58.7871],
                [27.6449, 57.981],
                [27.3516, 57.5182],
              ],
            ],
            [
              [
                [23.0127, 58.8772],
                [22.8917, 58.6388],
                [23.1995, 58.6473],
                [23.16, 58.4714],
                [22.4883, 58.1535],
                [22.1153, 58.1784],
                [21.9044, 58.4282],
                [22.4604, 58.6315],
                [22.6103, 59.053],
                [23.0127, 58.8772],
              ],
            ],
          ],
        },
        properties: { name: "Estonia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [34.2198, 31.3217],
              [34.2688, 31.2205],
              [34.5937, 30.3802],
              [34.9051, 29.4932],
              [34.3905, 28.0194],
              [34.1419, 27.8534],
              [33.2866, 28.5757],
              [32.6275, 29.7499],
              [32.4577, 29.6796],
              [32.7278, 28.8485],
              [33.572, 27.8321],
              [34.0863, 26.4969],
              [35.3875, 24.2632],
              [35.6218, 23.1455],
              [34.9524, 22.7943],
              [34.5937, 22.2811],
              [34.2134, 22.2],
              [34.0865, 22.0025],
              [33.1826, 22.0017],
              [25, 22],
              [25, 24.9875],
              [25, 25.5268],
              [25, 26.8805],
              [24.9948, 29.1507],
              [24.7452, 30.1337],
              [24.9888, 30.7293],
              [24.892, 31.3739],
              [25.1472, 31.6497],
              [27.1871, 31.3899],
              [29.0734, 30.8521],
              [31.0638, 31.5733],
              [31.9143, 31.4752],
              [32.7141, 31.0714],
              [34.2198, 31.3217],
            ],
          ],
        },
        properties: { name: "Egypt" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-8.6666, 27.662],
              [-8.6667, 27.3127],
              [-8.8245, 26.0382],
              [-11.9441, 25.882],
              [-12.022, 23.6542],
              [-13.0409, 22.8389],
              [-13.0576, 21.3647],
              [-16.825, 21.3191],
              [-17.0513, 20.7716],
              [-16.9587, 21.7111],
              [-15.7411, 23.8954],
              [-14.9633, 24.6788],
              [-14.4726, 26.0925],
              [-13.6084, 26.7669],
              [-13.173, 27.667],
              [-8.6666, 27.662],
            ],
          ],
        },
        properties: { name: "Western Sahara" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [28.0777, 36.1182],
                [27.8606, 35.9226],
                [27.7226, 35.9288],
                [27.6996, 36.1182],
                [27.7048, 36.2643],
                [28.0929, 36.3913],
                [28.0777, 36.1182],
              ],
            ],
            [
              [
                [27.0088, 37.7299],
                [26.6951, 37.45],
                [26.2824, 37.492],
                [26.2357, 37.6322],
                [26.6264, 37.6111],
                [26.5842, 37.7779],
                [26.8644, 37.8529],
                [27.0088, 37.7299],
              ],
            ],
            [
              [
                [26.0328, 40.7303],
                [25.6324, 40.8628],
                [25.1395, 40.9907],
                [24.8135, 40.8581],
                [24.6926, 40.6531],
                [24.5139, 40.9053],
                [23.8677, 40.7787],
                [23.7651, 40.7579],
                [23.7569, 40.6346],
                [23.6512, 40.3129],
                [23.2995, 40.2157],
                [23.0042, 40.3503],
                [22.6824, 40.529],
                [22.664, 40.4916],
                [22.5589, 40.1747],
                [22.6646, 39.9748],
                [22.9159, 39.6029],
                [23.0946, 39.3836],
                [22.9625, 38.9755],
                [22.7999, 38.8142],
                [23.3778, 38.5258],
                [23.4604, 38.4988],
                [23.4977, 38.7546],
                [24.0531, 38.4939],
                [23.9884, 38.3775],
                [23.6556, 38.3521],
                [23.6904, 38.3403],
                [23.9905, 38.2351],
                [23.9836, 38.0911],
                [24.0433, 37.7052],
                [23.7436, 37.8538],
                [23.6722, 37.9418],
                [23.597, 38.0152],
                [23.4604, 38.024],
                [23.1797, 37.9516],
                [23.12, 37.73],
                [23.1935, 37.5912],
                [23.3192, 37.6097],
                [23.313, 37.7614],
                [23.4336, 37.7616],
                [23.4162, 37.4558],
                [22.8171, 37.4458],
                [22.9808, 37.0479],
                [22.9735, 36.7312],
                [22.3987, 36.7163],
                [22.0867, 36.9279],
                [21.813, 36.8056],
                [21.6802, 37.3774],
                [21.4883, 37.5912],
                [21.2096, 37.8627],
                [21.3511, 38.1021],
                [21.8645, 38.2852],
                [22.373, 38.1422],
                [23.1175, 38.0606],
                [23.1262, 38.1684],
                [22.8945, 38.2198],
                [22.6083, 38.3587],
                [22.0551, 38.3922],
                [21.8517, 38.3745],
                [21.2055, 38.3544],
                [20.768, 38.8622],
                [21.1086, 39.0461],
                [20.7033, 39.0501],
                [20.4778, 39.2748],
                [20.01, 39.6918],
                [20.3023, 39.8127],
                [20.3913, 39.7885],
                [20.3833, 39.9827],
                [20.6129, 40.0797],
                [20.7785, 40.3488],
                [21.0561, 40.6167],
                [20.9802, 40.8557],
                [21.7874, 40.9311],
                [21.9294, 41.1004],
                [22.2162, 41.1705],
                [22.3321, 41.1203],
                [22.8792, 41.3407],
                [22.9276, 41.3385],
                [23.4604, 41.4025],
                [23.6242, 41.3757],
                [24.0597, 41.5221],
                [24.4917, 41.5436],
                [24.784, 41.3602],
                [25.1794, 41.3102],
                [25.2247, 41.2646],
                [25.9064, 41.3076],
                [25.9486, 41.3203],
                [26.3579, 41.7111],
                [26.5923, 41.4201],
                [26.0328, 40.7303],
              ],
            ],
            [
              [
                [26.3896, 39.3419],
                [26.4805, 39.1632],
                [26.4268, 38.9834],
                [26.1649, 39.0105],
                [26.1649, 39.1914],
                [26.0217, 39.0983],
                [25.8524, 39.205],
                [25.9117, 39.2784],
                [26.3896, 39.3419],
              ],
            ],
            [
              [
                [24.0973, 35.5568],
                [24.3174, 35.3538],
                [24.928, 35.4069],
                [25.4907, 35.2983],
                [26.2086, 35.1708],
                [26.1133, 35.0322],
                [25.5506, 34.9908],
                [24.875, 34.9554],
                [24.7225, 35.0925],
                [24.2875, 35.176],
                [23.6393, 35.2486],
                [23.5541, 35.3592],
                [23.6489, 35.491],
                [24.0973, 35.5568],
              ],
            ],
            [
              [
                [26.1554, 38.3107],
                [26.0119, 38.1529],
                [25.9174, 38.2494],
                [25.9902, 38.3889],
                [25.8343, 38.5419],
                [26.0035, 38.6018],
                [26.1625, 38.5512],
                [26.1554, 38.3107],
              ],
            ],
            [
              [
                [25.7442, 40.3674],
                [25.5042, 40.2894],
                [25.4107, 40.4392],
                [25.5042, 40.602],
                [25.7231, 40.5587],
                [25.7442, 40.3674],
              ],
            ],
            [
              [
                [25.6114, 37.1296],
                [25.6419, 36.8851],
                [25.4413, 36.8597],
                [25.3632, 37.0238],
                [25.1663, 36.9122],
                [25.0262, 36.9996],
                [25.1428, 37.1707],
                [25.6114, 37.1296],
              ],
            ],
            [
              [
                [25.6088, 36.4028],
                [25.4913, 36.2191],
                [25.1951, 36.4505],
                [25.4428, 36.5758],
                [25.6088, 36.4028],
              ],
            ],
            [
              [
                [25.2632, 40.0608],
                [25.289, 39.7529],
                [24.9851, 39.8112],
                [24.978, 39.996],
                [25.2632, 40.0608],
              ],
            ],
            [
              [
                [25.0724, 37.9127],
                [24.9876, 37.6671],
                [24.6661, 37.8695],
                [24.869, 38.012],
                [25.0724, 37.9127],
              ],
            ],
            [
              [
                [24.5977, 38.1182],
                [24.4072, 37.9119],
                [24.0932, 38.0951],
                [24.2194, 38.1912],
                [24.5977, 38.1182],
              ],
            ],
            [
              [
                [24.5907, 40.0422],
                [24.3493, 39.977],
                [24.1553, 40.2273],
                [23.8854, 40.2974],
                [23.8604, 40.5728],
                [24.2109, 40.3959],
                [24.5907, 40.0422],
              ],
            ],
            [
              [
                [24.0937, 38.9901],
                [23.8864, 38.8942],
                [23.784, 39.104],
                [24.1162, 39.2788],
                [24.0937, 38.9901],
              ],
            ],
            [
              [
                [24.0296, 40.1756],
                [24.0599, 39.9279],
                [23.7817, 39.9659],
                [23.6895, 40.2006],
                [24.0296, 40.1756],
              ],
            ],
            [
              [
                [23.1388, 36.3676],
                [23.0534, 36.0275],
                [22.8546, 36.1415],
                [22.8384, 36.3676],
                [23.1388, 36.3676],
              ],
            ],
            [
              [
                [21.2434, 37.1953],
                [20.9354, 37.1004],
                [20.7617, 37.2426],
                [21.1453, 37.3813],
                [21.2434, 37.1953],
              ],
            ],
            [
              [
                [20.9506, 37.7489],
                [20.8642, 37.6089],
                [20.5468, 37.8293],
                [20.6797, 38.0053],
                [20.9506, 37.7489],
              ],
            ],
            [
              [
                [20.8474, 38.6571],
                [20.4881, 38.5244],
                [20.5649, 38.8161],
                [20.7219, 38.9069],
                [20.8474, 38.6571],
              ],
            ],
            [
              [
                [20.709, 38.4519],
                [20.709, 38.0598],
                [20.3827, 38.2101],
                [20.525, 38.4293],
                [20.709, 38.4519],
              ],
            ],
            [
              [
                [19.8831, 39.5638],
                [19.7308, 39.56],
                [19.5448, 39.7784],
                [19.7873, 39.8571],
                [19.9233, 39.7814],
                [19.8831, 39.5638],
              ],
            ],
          ],
        },
        properties: { name: "Greece" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [43.1261, 12.7101],
                [42.6867, 12.4073],
                [42.4043, 12.4686],
                [41.6307, 13.3913],
                [40.8941, 14.0891],
                [40.0851, 14.5456],
                [39.0217, 14.5899],
                [38.5391, 14.4413],
                [37.9201, 14.8128],
                [37.5268, 14.2588],
                [37.2972, 14.3876],
                [36.5603, 14.2577],
                [36.4673, 15.1054],
                [36.8653, 16.1186],
                [37.0295, 17.0223],
                [38.2556, 17.6008],
                [38.5696, 18.005],
                [39.6434, 15.3839],
                [39.9153, 15.3649],
                [41.2338, 14.532],
                [43.1261, 12.7101],
              ],
            ],
            [
              [
                [40.8196, 15.8159],
                [40.8516, 15.5192],
                [40.5282, 15.5556],
                [40.5469, 15.7454],
                [40.8196, 15.8159],
              ],
            ],
            [
              [
                [40.3757, 16.5021],
                [40.0675, 16.2685],
                [40.0397, 16.615],
                [40.3431, 16.6081],
                [40.3757, 16.5021],
              ],
            ],
            [
              [
                [40.2398, 15.9624],
                [40.2691, 15.6519],
                [39.9571, 15.6541],
                [39.8498, 15.974],
                [40.0853, 16.0916],
                [40.2398, 15.9624],
              ],
            ],
          ],
        },
        properties: { name: "Eritrea" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [4.391, 39.8617],
                [4.1907, 39.7981],
                [3.9068, 39.9986],
                [4.1586, 40.1071],
                [4.391, 39.8617],
              ],
            ],
            [
              [
                [3.0272, 39.9286],
                [3.1413, 39.771],
                [3.3402, 39.7851],
                [3.4469, 39.6566],
                [3.2245, 39.3643],
                [3.0486, 39.2718],
                [2.7901, 39.3675],
                [2.6354, 39.5678],
                [2.5259, 39.4609],
                [2.4094, 39.591],
                [2.7816, 39.8511],
                [3.0272, 39.9286],
              ],
            ],
            [
              [
                [-1.786, 43.3505],
                [-1.7694, 43.3347],
                [-1.7289, 43.2961],
                [-1.4389, 43.2579],
                [-0.7245, 42.9202],
                [-0.3133, 42.8494],
                [0.4778, 42.7],
                [0.6601, 42.691],
                [0.7453, 42.7489],
                [0.8582, 42.8257],
                [1.3298, 42.6999],
                [1.4426, 42.6037],
                [1.4956, 42.4736],
                [1.7258, 42.5044],
                [1.731, 42.4924],
                [3.1748, 42.4354],
                [3.0758, 41.9496],
                [2.7785, 41.6488],
                [1.6453, 41.1956],
                [0.93, 40.9773],
                [0.7453, 40.775],
                [0.5152, 40.5229],
                [-0.1885, 39.722],
                [-0.296, 39.3774],
                [-0.0376, 38.8864],
                [0.1442, 38.7061],
                [-0.4319, 38.3781],
                [-0.7621, 37.847],
                [-1.63, 37.3752],
                [-1.7859, 37.1805],
                [-1.8933, 37.0464],
                [-2.0979, 36.7908],
                [-2.7863, 36.7642],
                [-3.1287, 36.7509],
                [-3.3739, 36.746],
                [-3.7775, 36.7379],
                [-4.3798, 36.6987],
                [-5.2524, 36.3113],
                [-5.3392, 36.152],
                [-5.3515, 36.1526],
                [-5.3694, 36.1554],
                [-5.4235, 36.1675],
                [-5.4456, 36.0714],
                [-5.5951, 36.0149],
                [-5.7926, 36.0817],
                [-5.9068, 36.176],
                [-6.0355, 36.192],
                [-6.4087, 36.6705],
                [-6.43, 36.7375],
                [-6.3456, 36.7988],
                [-6.9451, 37.1593],
                [-7.4019, 37.1748],
                [-7.5127, 37.5263],
                [-7.4457, 37.694],
                [-6.9317, 38.2084],
                [-7.108, 38.1881],
                [-7.2758, 38.4539],
                [-7.2031, 38.751],
                [-7.0209, 39.0127],
                [-7.2315, 39.2784],
                [-7.5429, 39.6628],
                [-7.0106, 39.7363],
                [-6.9513, 40.2574],
                [-6.8651, 40.2707],
                [-6.8022, 40.5288],
                [-6.9299, 41.0295],
                [-6.6898, 41.2052],
                [-6.4797, 41.2944],
                [-6.3008, 41.5924],
                [-6.6375, 41.8984],
                [-6.9858, 41.9714],
                [-7.2005, 41.8797],
                [-8.0519, 41.8206],
                [-8.1651, 41.8183],
                [-8.199, 42.1544],
                [-8.8632, 41.8721],
                [-8.786, 42.334],
                [-8.7267, 42.6883],
                [-8.9621, 42.6303],
                [-9.2093, 42.979],
                [-9.1336, 43.0807],
                [-8.9905, 43.2731],
                [-8.6289, 43.3347],
                [-8.3239, 43.3866],
                [-7.6997, 43.7351],
                [-7.6014, 43.6731],
                [-7.0318, 43.5445],
                [-5.8535, 43.612],
                [-4.5123, 43.3932],
                [-3.3739, 43.4082],
                [-3.1533, 43.3532],
                [-2.7863, 43.4305],
                [-2.4592, 43.3347],
                [-2.123, 43.3347],
                [-1.8933, 43.3454],
                [-1.786, 43.3505],
              ],
            ],
            [
              [
                [1.5747, 39.0405],
                [1.3846, 38.8022],
                [1.1328, 38.8746],
                [1.2463, 39.0789],
                [1.5747, 39.0405],
              ],
            ],
            [
              [
                [-2.9323, 35.2776],
                [-2.9698, 35.2933],
                [-2.9525, 35.3198],
                [-2.9459, 35.3141],
                [-2.9457, 35.3077],
                [-2.932, 35.2934],
                [-2.9359, 35.2914],
                [-2.937, 35.2826],
                [-2.9323, 35.2776],
              ],
            ],
            [
              [
                [-5.3434, 35.8709],
                [-5.3819, 35.913],
                [-5.3777, 35.916],
                [-5.365, 35.9167],
                [-5.3489, 35.9078],
                [-5.3363, 35.8952],
                [-5.3252, 35.8999],
                [-5.3146, 35.8899],
                [-5.3019, 35.8937],
                [-5.2978, 35.901],
                [-5.2879, 35.9059],
                [-5.2792, 35.8999],
                [-5.2801, 35.8955],
                [-5.288, 35.891],
                [-5.3062, 35.8849],
                [-5.3196, 35.8864],
                [-5.3281, 35.8793],
                [-5.3378, 35.8781],
                [-5.3434, 35.8709],
              ],
            ],
            [
              [
                [-13.4371, 29.0022],
                [-13.7995, 28.8585],
                [-13.877, 28.9395],
                [-13.8555, 29.0358],
                [-13.4611, 29.1565],
                [-13.4371, 29.0022],
              ],
            ],
            [
              [
                [-13.9045, 28.2315],
                [-14.2422, 28.2011],
                [-14.0392, 28.615],
                [-13.874, 28.7975],
                [-13.8004, 28.6101],
                [-13.9045, 28.2315],
              ],
            ],
            [
              [
                [-15.373, 27.8509],
                [-15.6011, 27.7205],
                [-15.85, 27.9717],
                [-15.7041, 28.1628],
                [-15.4172, 28.1572],
                [-15.373, 27.8509],
              ],
            ],
            [
              [
                [-16.4257, 28.1504],
                [-16.7084, 28.0082],
                [-16.9265, 28.3522],
                [-16.5229, 28.4185],
                [-16.3182, 28.5798],
                [-16.2224, 28.5306],
                [-16.4257, 28.1504],
              ],
            ],
            [
              [
                [-17.004, 28.08],
                [-17.2554, 27.9517],
                [-17.4329, 28.0885],
                [-17.2817, 28.2909],
                [-17.004, 28.08],
              ],
            ],
            [
              [
                [-17.7361, 28.5452],
                [-17.8792, 28.4927],
                [-18.0407, 28.7934],
                [-17.759, 28.8716],
                [-17.7361, 28.5452],
              ],
            ],
            [
              [
                [-17.8024, 27.8558],
                [-17.9781, 27.5665],
                [-18.2623, 27.7101],
                [-17.8673, 27.9174],
                [-17.8024, 27.8558],
              ],
            ],
          ],
        },
        properties: { name: "Spain" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [42.4043, 12.4686],
              [41.816, 11.6292],
              [41.8348, 11.0448],
              [42.9681, 10.9974],
              [42.7628, 10.7159],
              [42.9804, 10.0644],
              [43.9589, 9.0545],
              [45.6126, 8.4674],
              [46.9411, 8.0224],
              [47.8524, 7.9151],
              [45.6126, 5.5657],
              [45.4747, 5.4241],
              [45.2923, 5.2367],
              [44.9162, 4.9368],
              [43.4741, 4.7993],
              [42.8138, 4.301],
              [41.9101, 3.9823],
              [41.2328, 3.9616],
              [40.708, 4.2326],
              [39.9305, 3.8858],
              [39.5667, 3.5224],
              [38.9731, 3.5158],
              [38.1026, 3.6459],
              [36.9621, 4.3833],
              [35.9477, 4.62],
              [35.8294, 5.2367],
              [35.3235, 5.413],
              [35.0325, 5.8494],
              [34.9588, 6.4537],
              [34.5428, 6.7418],
              [33.7557, 7.6074],
              [33.0448, 7.899],
              [33.2485, 8.381],
              [33.8204, 8.4168],
              [34.0937, 8.6026],
              [34.1132, 9.4986],
              [34.4181, 10.6735],
              [34.8021, 10.8052],
              [35.2153, 11.9187],
              [35.6227, 12.5064],
              [36.1342, 12.8315],
              [36.5603, 14.2577],
              [37.2972, 14.3876],
              [37.5268, 14.2588],
              [37.9201, 14.8128],
              [38.5391, 14.4413],
              [39.0217, 14.5899],
              [40.0851, 14.5456],
              [40.8941, 14.0891],
              [41.6307, 13.3913],
              [42.4043, 12.4686],
            ],
          ],
        },
        properties: { name: "Ethiopia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [28.8967, 69.0426],
                [28.4782, 68.5109],
                [28.6388, 68.2375],
                [28.8967, 68.1515],
                [29.956, 67.6452],
                [29.1273, 66.9908],
                [29.4643, 66.5492],
                [29.5729, 66.4329],
                [30.0785, 65.7704],
                [29.7547, 65.4974],
                [29.7009, 64.9085],
                [30.0443, 64.7388],
                [30.0598, 64.547],
                [30.0686, 64.4375],
                [30.4884, 64.1574],
                [30.2805, 63.8509],
                [29.9719, 63.7572],
                [31.128, 63.2578],
                [31.5218, 62.8942],
                [31.1863, 62.4872],
                [30.144, 61.8522],
                [27.9913, 60.669],
                [27.8036, 60.5486],
                [27.4542, 60.4236],
                [26.7304, 60.4075],
                [26.4539, 60.4877],
                [23.4699, 59.8757],
                [23.0034, 59.8867],
                [22.9896, 60.0826],
                [22.526, 59.9512],
                [22.2141, 60.2235],
                [21.3727, 60.1112],
                [21.1787, 60.7855],
                [21.4207, 61.0455],
                [21.5937, 61.5386],
                [21.3296, 61.9539],
                [21.116, 62.7127],
                [21.1799, 63.0766],
                [23.0234, 63.8509],
                [23.6173, 64.0488],
                [24.2982, 64.547],
                [24.562, 64.777],
                [24.5412, 65.0792],
                [24.8163, 65.0981],
                [24.9085, 64.8645],
                [25.2608, 64.9432],
                [25.0846, 65.5889],
                [24.6602, 65.6676],
                [24.1551, 65.816],
                [23.7656, 66.2739],
                [23.8453, 66.837],
                [23.4078, 68.023],
                [20.5486, 69.06],
                [21.0332, 69.2348],
                [21.3103, 69.3065],
                [21.5888, 69.2786],
                [21.6919, 69.2348],
                [21.9836, 69.0729],
                [23.0757, 68.6756],
                [24.0289, 68.7802],
                [25.012, 68.6429],
                [25.638, 68.9632],
                [26.0763, 69.7256],
                [27.7472, 70.0602],
                [29.1537, 69.588],
                [28.8967, 69.0426],
              ],
            ],
            [
              [
                [22.1562, 59.7949],
                [21.9437, 59.6886],
                [21.847, 59.8419],
                [21.7649, 59.9724],
                [22.0745, 60.0004],
                [22.1562, 59.7949],
              ],
            ],
            [
              [
                [21.1062, 60.4652],
                [21.0595, 60.2693],
                [20.7092, 60.2983],
                [20.8791, 60.5594],
                [21.1062, 60.4652],
              ],
            ],
            [
              [
                [20.55, 60.0961],
                [20.1365, 60.0355],
                [19.548, 60.2237],
                [19.6324, 60.32],
                [20.1365, 60.3373],
                [20.4009, 60.3136],
                [20.55, 60.0961],
              ],
            ],
          ],
        },
        properties: { name: "Finland" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [179.6738, -16.4902],
                [179.9173, -16.6526],
                [179.7354, -16.757],
                [179.6784, -16.5883],
                [179.4587, -16.7284],
                [178.7776, -16.9537],
                [178.6168, -16.8573],
                [178.5993, -16.6408],
                [179.6622, -16.219],
                [179.836, -16.3106],
                [179.6738, -16.4902],
              ],
            ],
            [
              [
                [178.501, -18.1185],
                [177.9862, -18.2601],
                [177.3939, -18.1281],
                [177.2656, -17.9593],
                [177.4317, -17.6272],
                [177.6413, -17.4673],
                [178.1928, -17.3103],
                [178.5482, -17.6272],
                [178.501, -18.1185],
              ],
            ],
            [
              [
                [178.2849, -19.1472],
                [178.1542, -19.3847],
                [177.988, -19.2959],
                [177.965, -18.9902],
                [178.2849, -19.1472],
              ],
            ],
            [
              [
                [-179.9307, -16.8438],
                [-180, -16.9728],
                [-180, -16.7973],
                [-179.9488, -16.8052],
                [-179.9307, -16.8438],
              ],
            ],
          ],
        },
        properties: { name: "Fiji" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-58.3365, -51.3236],
                [-58.3004, -51.3984],
                [-57.9228, -51.3727],
                [-57.7233, -51.6659],
                [-58.0596, -51.7907],
                [-58.3365, -51.8246],
                [-58.4347, -52.0524],
                [-58.6392, -52.0524],
                [-59.7554, -52.3821],
                [-59.6972, -52.1115],
                [-59.8029, -51.8657],
                [-60.282, -52.0524],
                [-60.4624, -52.2166],
                [-60.7826, -52.166],
                [-60.8703, -52.0468],
                [-60.5288, -51.9344],
                [-60.5288, -51.6823],
                [-60.4058, -51.6823],
                [-60.2337, -51.3236],
                [-59.4376, -51.3236],
                [-59.2842, -51.4358],
                [-59.5801, -51.6909],
                [-59.3968, -51.8047],
                [-59.0744, -51.694],
                [-59.0744, -51.5646],
                [-58.8831, -51.3053],
                [-58.3365, -51.3236],
              ],
            ],
            [
              [
                [-60.6288, -51.6623],
                [-61.0179, -51.6852],
                [-60.9604, -51.4587],
                [-60.6836, -51.4524],
                [-60.6288, -51.6623],
              ],
            ],
          ],
        },
        properties: { name: "Falkland Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [162.441, 11.7853],
                [162.4662, 11.4213],
                [162.1497, 11.5683],
                [162.1379, 11.6753],
                [162.441, 11.7853],
              ],
            ],
            [
              [
                [158.3919, 6.8645],
                [158.3248, 6.7299],
                [158.1289, 6.7633],
                [158.0496, 6.9413],
                [158.2678, 7.0729],
                [158.3919, 6.8645],
              ],
            ],
            [
              [
                [155.0335, 1.0626],
                [154.9879, 0.9026],
                [154.8092, 1.0508],
                [154.5622, 1.124],
                [154.5513, 1.2456],
                [154.8189, 1.2144],
                [155.0335, 1.0626],
              ],
            ],
            [
              [
                [152.0497, 7.5599],
                [152.0173, 7.1788],
                [151.7651, 7.3273],
                [151.9196, 7.6092],
                [152.0497, 7.5599],
              ],
            ],
            [
              [
                [139.7787, 10.0227],
                [139.8461, 9.6924],
                [139.6418, 9.7071],
                [139.5233, 10.0093],
                [139.7787, 10.0227],
              ],
            ],
          ],
        },
        properties: { name: "Micronesia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-7.1612, 62.127],
              [-7.1326, 62.2843],
              [-6.5477, 62.3301],
              [-6.6617, 62.127],
              [-6.8239, 61.9419],
              [-7.1612, 62.127],
            ],
          ],
        },
        properties: { name: "Faroes" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [55.8498, -21.1858],
                [55.7858, -21.3757],
                [55.3304, -21.2876],
                [55.202, -21.035],
                [55.4471, -20.8597],
                [55.6529, -20.9056],
                [55.8498, -21.1858],
              ],
            ],
            [
              [
                [45.3392, -12.7143],
                [45.2392, -13.0535],
                [45.0223, -12.9229],
                [45.1091, -12.6808],
                [45.3392, -12.7143],
              ],
            ],
            [
              [
                [9.3497, 41.7331],
                [9.241, 41.4735],
                [8.9872, 41.5136],
                [8.8934, 41.7331],
                [8.7341, 42.1059],
                [8.62, 42.3732],
                [9.4119, 42.797],
                [9.4022, 41.8587],
                [9.3497, 41.7331],
              ],
            ],
            [
              [
                [5.8181, 49.5463],
                [5.8934, 49.4969],
                [6.3671, 49.4695],
                [6.557, 49.4192],
                [6.7235, 49.2188],
                [7.1011, 49.156],
                [7.2643, 49.1338],
                [7.3688, 49.1615],
                [7.6357, 49.054],
                [7.9107, 49.0452],
                [8.0684, 48.9993],
                [8.2326, 48.9666],
                [7.9596, 48.7186],
                [7.6807, 48.2573],
                [7.5779, 48.1214],
                [7.5773, 48.1157],
                [7.5814, 47.9145],
                [7.546, 47.7436],
                [7.589, 47.5899],
                [7.5552, 47.5646],
                [7.5109, 47.5026],
                [7.3809, 47.4319],
                [7.1304, 47.503],
                [6.9392, 47.4337],
                [6.8584, 47.1672],
                [6.8602, 47.1655],
                [6.463, 46.8511],
                [6.1381, 46.5577],
                [6.064, 46.4162],
                [6.1256, 46.3172],
                [5.9561, 46.1321],
                [6.1776, 46.1816],
                [6.2414, 46.3436],
                [6.463, 46.432],
                [6.8211, 46.4272],
                [7.0449, 45.9224],
                [6.8024, 45.7786],
                [7.1047, 45.4685],
                [7.1311, 45.2671],
                [6.6301, 45.1099],
                [7.0658, 44.7135],
                [6.9484, 44.6547],
                [6.8874, 44.3613],
                [7.7142, 44.0615],
                [7.5298, 43.784],
                [7.4392, 43.749],
                [7.4374, 43.7516],
                [7.4129, 43.7328],
                [7.4103, 43.7293],
                [7.4147, 43.7266],
                [6.9313, 43.4803],
                [6.463, 43.1567],
                [5.6719, 43.1793],
                [4.2303, 43.4602],
                [4.1011, 43.5547],
                [3.5631, 43.341],
                [3.407, 43.279],
                [3.2405, 43.2129],
                [3.0434, 42.8382],
                [3.0523, 42.6893],
                [3.1748, 42.4354],
                [1.731, 42.4924],
                [1.7258, 42.5044],
                [1.7861, 42.5737],
                [1.4426, 42.6037],
                [1.3298, 42.6999],
                [0.8582, 42.8257],
                [0.7453, 42.7489],
                [0.6601, 42.691],
                [0.4778, 42.7],
                [-0.3133, 42.8494],
                [-0.7245, 42.9202],
                [-1.4389, 43.2579],
                [-1.7289, 43.2961],
                [-1.7694, 43.3347],
                [-1.786, 43.3505],
                [-1.5248, 43.5296],
                [-1.2541, 44.4676],
                [-1.1393, 45.4131],
                [-0.7148, 45.3284],
                [-1.1438, 45.7955],
                [-1.1625, 46.3242],
                [-1.7859, 46.5279],
                [-1.8933, 46.6465],
                [-2.0158, 46.7818],
                [-1.9805, 47.0287],
                [-2.4585, 47.448],
                [-2.7863, 47.5636],
                [-3.0916, 47.4437],
                [-3.3739, 47.7091],
                [-3.523, 47.8491],
                [-4.3251, 47.8813],
                [-4.3643, 48.158],
                [-4.6593, 48.3933],
                [-4.4342, 48.6204],
                [-3.6592, 48.6594],
                [-3.3739, 48.7307],
                [-3.1221, 48.7936],
                [-2.7863, 48.5849],
                [-1.9481, 48.5389],
                [-1.5711, 48.6265],
                [-1.7613, 49.516],
                [-1.4101, 49.6094],
                [-1.1196, 49.3223],
                [0.2972, 49.4299],
                [0.6443, 49.4431],
                [0.6911, 49.5073],
                [0.7453, 49.5183],
                [0.7453, 49.5209],
                [0.7453, 49.5234],
                [0.1167, 49.5391],
                [0.2717, 49.7307],
                [0.7453, 49.8709],
                [1.3796, 50.0651],
                [1.6447, 50.3504],
                [1.5785, 50.5895],
                [1.6472, 50.8631],
                [2.0677, 51.0066],
                [2.546, 51.0894],
                [2.607, 50.9127],
                [2.8633, 50.7083],
                [2.8701, 50.7029],
                [2.8864, 50.7057],
                [2.9004, 50.6933],
                [2.9092, 50.6945],
                [2.9116, 50.7031],
                [2.9223, 50.7028],
                [2.93, 50.7117],
                [2.9306, 50.7262],
                [2.9372, 50.73],
                [2.9442, 50.732],
                [2.938, 50.7402],
                [2.9401, 50.7453],
                [2.9566, 50.7529],
                [2.9642, 50.75],
                [2.9722, 50.7502],
                [2.9833, 50.7572],
                [3.0059, 50.7661],
                [3.0112, 50.7702],
                [3.0149, 50.7736],
                [3.0187, 50.7735],
                [3.0985, 50.779],
                [3.2453, 50.713],
                [3.3897, 50.5453],
                [3.6151, 50.4904],
                [3.6555, 50.4617],
                [3.7407, 50.358],
                [4.0278, 50.3583],
                [4.1409, 49.9788],
                [4.2331, 49.9578],
                [4.4325, 49.9416],
                [4.7928, 50.147],
                [4.9694, 49.8018],
                [5.1537, 49.7179],
                [5.3935, 49.6171],
                [5.4709, 49.4972],
                [5.7346, 49.5457],
                [5.8181, 49.5463],
              ],
            ],
            [
              [
                [-54.5879, 2.3221],
                [-54.326, 2.588],
                [-54.0163, 3.5593],
                [-54.4353, 4.386],
                [-54.298, 5.2363],
                [-54.0056, 5.6025],
                [-53.8517, 5.6949],
                [-53.066, 5.4745],
                [-52.7219, 5.2363],
                [-51.8475, 4.6165],
                [-51.6555, 4.056],
                [-52.5292, 2.588],
                [-52.966, 2.2123],
                [-53.7392, 2.2956],
                [-54.1804, 2.1379],
                [-54.5879, 2.3221],
              ],
            ],
            [
              [
                [-60.7977, 14.5053],
                [-60.9485, 14.4332],
                [-61.1957, 14.7745],
                [-60.8696, 14.7984],
                [-60.7977, 14.5053],
              ],
            ],
            [
              [
                [-61.0749, 15.9501],
                [-61.2968, 15.7521],
                [-61.4556, 15.9501],
                [-61.289, 16.1336],
                [-61.0749, 15.9501],
              ],
            ],
            [
              [
                [-61.5638, 16.1518],
                [-61.7221, 15.9609],
                [-61.8407, 16.2787],
                [-61.5435, 16.3898],
                [-61.4564, 16.5499],
                [-61.2889, 16.2942],
                [-61.5638, 16.1518],
              ],
            ],
          ],
        },
        properties: { name: "France" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [13.299, 2.1706],
              [13.3162, 1.4466],
              [14.0479, 1.3393],
              [14.3513, 1.0236],
              [14.008, 0.0173],
              [14.4299, -0.8786],
              [14.3759, -1.7141],
              [14.1596, -2.16],
              [13.3829, -2.3436],
              [12.6158, -2.0511],
              [12.3425, -2.3577],
              [11.8464, -2.5027],
              [11.7476, -2.7148],
              [11.9433, -3.1022],
              [11.8945, -3.4408],
              [11.201, -3.9916],
              [9.7678, -2.5299],
              [8.9799, -1.2056],
              [8.9194, -0.8786],
              [9.5261, 0.1929],
              [9.4768, 0.5178],
              [9.662, 1.0598],
              [11.2847, 1.0376],
              [11.3346, 2.1739],
              [11.8459, 2.2795],
              [13.299, 2.1706],
            ],
          ],
        },
        properties: { name: "Gabon" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-61.8872, 11.9187],
              [-61.7536, 12.2389],
              [-61.5437, 12.3092],
              [-61.6175, 11.9707],
              [-61.8872, 11.9187],
            ],
          ],
        },
        properties: { name: "Grenada" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [46.4205, 41.9043],
              [46.2829, 41.7331],
              [46.5821, 41.3432],
              [46.5337, 41.1855],
              [45.0131, 41.2966],
              [43.4738, 41.1233],
              [42.815, 41.5555],
              [42.5152, 41.4383],
              [41.5471, 41.5204],
              [41.6572, 41.7331],
              [41.73, 41.8738],
              [41.6548, 42.1059],
              [41.536, 42.3929],
              [41.2964, 42.7667],
              [40.0103, 43.3853],
              [41.0039, 43.4488],
              [42.4524, 43.1829],
              [43.9013, 42.6535],
              [45.0431, 42.6553],
              [45.6621, 42.3929],
              [45.9086, 42.1059],
              [46.4205, 41.9043],
            ],
          ],
        },
        properties: { name: "Georgia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-2.7205, 49.4438],
              [-2.4703, 49.6425],
              [-2.3118, 49.638],
              [-2.4045, 49.3183],
              [-2.7205, 49.4438],
            ],
          ],
        },
        properties: { name: "Guernsey" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [1.1996, 6.1124],
              [1.0504, 6.0026],
              [0.9463, 5.8001],
              [0.7453, 5.7835],
              [0.2362, 5.7413],
              [-1.7859, 4.8801],
              [-1.8933, 4.8344],
              [-2.0007, 4.7886],
              [-2.7863, 5.0035],
              [-3.1058, 5.0909],
              [-2.8322, 5.299],
              [-3.1813, 6.7737],
              [-2.5205, 8.2869],
              [-2.6869, 9.4907],
              [-2.7863, 10.0094],
              [-2.9125, 10.6685],
              [-2.7863, 10.8872],
              [-2.7279, 10.9884],
              [-1.8933, 10.9871],
              [-0.6868, 10.9912],
              [-0.142, 11.139],
              [-0.0713, 10.6685],
              [0.3087, 10.2935],
              [0.6206, 8.2206],
              [0.57, 6.8716],
              [0.7453, 6.6207],
              [0.9463, 6.333],
              [1.1996, 6.1124],
            ],
          ],
        },
        properties: { name: "Ghana" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-5.3392, 36.152],
              [-5.3413, 36.1247],
              [-5.3481, 36.1135],
              [-5.3559, 36.1252],
              [-5.3595, 36.1458],
              [-5.3588, 36.1497],
              [-5.3515, 36.1526],
              [-5.3392, 36.152],
            ],
          ],
        },
        properties: { name: "Gibraltar" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-24.5304, 82.9324],
                [-24.4093, 82.8762],
                [-23.2855, 82.9788],
                [-20.7029, 82.6728],
                [-20.7038, 82.5656],
                [-24.4093, 82.1877],
                [-29.6414, 82.1427],
                [-31.3607, 81.9014],
                [-31.3505, 81.7942],
                [-26.7037, 82.0293],
                [-25.0188, 81.9299],
                [-24.999, 81.8272],
                [-25.3418, 81.6662],
                [-25.3144, 81.5637],
                [-24.4093, 81.6406],
                [-22.7312, 82.0151],
                [-21.4404, 82.0356],
                [-21.1128, 81.8798],
                [-21.2646, 81.5342],
                [-22.182, 81.1618],
                [-22.149, 81.0507],
                [-20.9853, 81.223],
                [-19.8059, 81.6237],
                [-17.4748, 81.3867],
                [-16.1693, 81.7027],
                [-13.5572, 81.7367],
                [-11.8645, 81.4732],
                [-11.8412, 81.3761],
                [-12.0501, 81.2256],
                [-16.15, 80.6122],
                [-16.4702, 80.2369],
                [-18.4958, 80.1732],
                [-18.4906, 80.0514],
                [-17.6459, 80.0598],
                [-17.5098, 79.9343],
                [-17.9872, 79.7213],
                [-19.2888, 79.6632],
                [-19.5438, 79.3166],
                [-19.2253, 79.0851],
                [-19.4133, 78.7149],
                [-20.8328, 78.7492],
                [-21.202, 78.2926],
                [-20.9822, 77.9536],
                [-20.6203, 77.8806],
                [-20.3063, 78.068],
                [-20.2393, 77.8355],
                [-19.4048, 77.886],
                [-19.2569, 77.6422],
                [-20.4562, 77.6491],
                [-20.5382, 77.5168],
                [-18.3317, 77.2026],
                [-18.249, 76.8768],
                [-18.509, 76.7507],
                [-20.403, 76.8639],
                [-20.8252, 76.7415],
                [-21.1093, 76.9169],
                [-21.2599, 76.8153],
                [-21.1539, 76.5862],
                [-21.3334, 76.491],
                [-22.1374, 76.8207],
                [-22.2859, 76.6469],
                [-21.4751, 76.3065],
                [-20.8002, 76.5533],
                [-20.3676, 76.4907],
                [-19.5648, 75.7519],
                [-19.4036, 75.4898],
                [-19.487, 75.2248],
                [-20.453, 75.2262],
                [-20.5773, 75.0378],
                [-20.0158, 74.8814],
                [-19.9334, 74.6328],
                [-19.2983, 74.6009],
                [-19.2908, 74.369],
                [-21.6066, 74.1776],
                [-21.6143, 74.0713],
                [-20.5412, 73.825],
                [-20.6637, 73.5018],
                [-22.3417, 73.2677],
                [-23.6477, 73.5041],
                [-24.1112, 73.7424],
                [-24.4093, 73.6884],
                [-24.5881, 73.5084],
                [-24.4093, 73.2662],
                [-23.6044, 73.3098],
                [-22.1746, 72.8266],
                [-22.4045, 72.1564],
                [-24.4093, 72.6197],
                [-24.7163, 73.0444],
                [-24.8871, 72.8232],
                [-24.7126, 72.5973],
                [-24.4093, 72.4509],
                [-22.7891, 71.9446],
                [-22.4061, 71.4146],
                [-21.7873, 71.4592],
                [-21.7142, 71.235],
                [-21.8837, 70.973],
                [-21.6908, 70.5033],
                [-22.3012, 70.4915],
                [-22.5288, 70.7284],
                [-22.7192, 70.4715],
                [-23.4574, 70.4829],
                [-24.0236, 70.6969],
                [-24.4093, 71.0672],
                [-24.7349, 71.2767],
                [-25.4402, 71.3729],
                [-25.4072, 70.6816],
                [-26.4459, 70.4185],
                [-26.2876, 70.2382],
                [-25.4435, 70.3427],
                [-24.4093, 70.2232],
                [-22.8339, 70.0895],
                [-22.8237, 69.984],
                [-23.3521, 69.8338],
                [-23.8153, 69.5235],
                [-26.5918, 68.7098],
                [-29.3278, 68.301],
                [-29.9246, 68.3444],
                [-30.3883, 68.1358],
                [-31.4976, 68.1459],
                [-32.1596, 68.3845],
                [-32.1755, 67.9331],
                [-33.2563, 67.6329],
                [-33.5693, 67.1124],
                [-34.381, 66.5626],
                [-35.6327, 66.2369],
                [-36.0778, 65.9268],
                [-36.5346, 66.0181],
                [-36.7984, 65.586],
                [-37.0909, 65.7371],
                [-37.7907, 65.6315],
                [-37.6365, 66.1909],
                [-37.829, 66.2034],
                [-38.245, 65.692],
                [-39.6725, 65.5714],
                [-39.8643, 65.0151],
                [-41.0277, 65.0016],
                [-40.9698, 64.8671],
                [-40.5484, 64.8728],
                [-40.4842, 64.6577],
                [-40.5522, 64.3781],
                [-40.9836, 64.2925],
                [-40.6582, 63.9687],
                [-40.8335, 63.5446],
                [-41.4075, 63.4999],
                [-41.2675, 63.1684],
                [-42.2538, 62.6127],
                [-42.3548, 61.4764],
                [-42.7287, 61.1172],
                [-42.8919, 60.6246],
                [-43.3139, 60.4483],
                [-43.1456, 60.0556],
                [-43.6036, 59.8993],
                [-43.7145, 60.0889],
                [-44.0578, 60.1286],
                [-44.1567, 59.879],
                [-45.0497, 59.9181],
                [-45.3466, 60.0704],
                [-45.6719, 60.7496],
                [-45.8693, 60.5369],
                [-46.0752, 60.6825],
                [-45.7814, 60.9722],
                [-45.7946, 61.096],
                [-46.2184, 61.0055],
                [-46.3362, 60.7582],
                [-46.4446, 60.9548],
                [-46.7499, 60.7631],
                [-47.0282, 60.6894],
                [-48.0324, 60.7278],
                [-48.5494, 61.4588],
                [-49.2875, 61.562],
                [-49.3335, 61.7886],
                [-49.1724, 61.9553],
                [-49.3602, 62.1364],
                [-49.5243, 62.0358],
                [-49.4176, 61.8013],
                [-49.5672, 61.8106],
                [-50.5142, 62.8611],
                [-51.0991, 63.2159],
                [-51.3711, 63.7806],
                [-51.7672, 63.8783],
                [-52.1646, 64.6577],
                [-52.4448, 65.3604],
                [-53.1565, 65.4725],
                [-53.6664, 66.1379],
                [-53.6594, 66.4304],
                [-53.3269, 66.8604],
                [-53.5897, 66.8855],
                [-53.7896, 67.2589],
                [-53.7983, 67.9389],
                [-53.6315, 68.2638],
                [-52.9788, 68.7395],
                [-51.6436, 68.5612],
                [-51.2829, 68.6939],
                [-50.8333, 69.5984],
                [-50.9065, 69.7122],
                [-51.2139, 69.6055],
                [-51.6107, 69.9891],
                [-52.3858, 70.0919],
                [-54.0515, 70.4609],
                [-54.3088, 70.6749],
                [-53.9298, 70.7999],
                [-51.203, 70.4182],
                [-50.9137, 70.558],
                [-50.9248, 70.7947],
                [-51.3603, 71.0978],
                [-51.8913, 70.9061],
                [-52.1743, 71.1477],
                [-52.975, 71.1948],
                [-53.3814, 71.8271],
                [-54.123, 71.4392],
                [-55.3981, 71.5146],
                [-55.5916, 71.7935],
                [-55.1204, 72.2678],
                [-55.4181, 72.3016],
                [-55.3, 72.5594],
                [-55.6037, 72.6139],
                [-55.9824, 73.0312],
                [-56.2152, 73.4147],
                [-55.8732, 73.5217],
                [-55.6982, 73.0166],
                [-55.1977, 72.8887],
                [-55.1442, 72.9769],
                [-56.0253, 73.9643],
                [-56.1978, 74.4686],
                [-56.9687, 74.8562],
                [-57.5503, 74.887],
                [-58.8398, 75.5776],
                [-58.8044, 75.6888],
                [-59.3677, 75.8821],
                [-63.2778, 76.3524],
                [-63.9139, 76.1182],
                [-64.332, 76.3118],
                [-65.5026, 76.0263],
                [-66.0459, 76.2401],
                [-66.8312, 76.2289],
                [-67.2235, 76.0072],
                [-68.3758, 76.0815],
                [-69.2535, 76.3222],
                [-68.9588, 76.6632],
                [-70.5125, 76.8242],
                [-70.731, 76.929],
                [-70.7152, 77.031],
                [-68.3758, 77.3623],
                [-66.514, 77.4848],
                [-66.502, 77.5903],
                [-66.8193, 77.6859],
                [-68.3758, 77.535],
                [-69.1155, 77.4873],
                [-71.674, 77.8686],
                [-72.5651, 78.2338],
                [-72.2831, 78.5038],
                [-68.3758, 79.027],
                [-66.0848, 79.1524],
                [-64.7909, 79.5435],
                [-64.513, 80.0252],
                [-66.9557, 80.0786],
                [-67.2451, 80.2154],
                [-67.1247, 80.3518],
                [-63.5449, 81.1763],
                [-62.0161, 81.092],
                [-61.5368, 81.4102],
                [-61.597, 81.7588],
                [-59.3692, 82.0882],
                [-55.173, 82.3007],
                [-54.5609, 82.0524],
                [-54.4875, 81.6684],
                [-54.0033, 81.817],
                [-54.0841, 82.1849],
                [-53.8333, 82.2551],
                [-51.7017, 81.7286],
                [-51.2207, 81.8837],
                [-51.5735, 82.4264],
                [-50.4164, 82.4312],
                [-49.5024, 82.2718],
                [-49.032, 82.3538],
                [-48.4243, 82.0743],
                [-45.823, 81.6455],
                [-45.5096, 82.0038],
                [-45.6244, 82.1269],
                [-45.825, 81.9411],
                [-46.5168, 82.1052],
                [-47.6208, 82.2747],
                [-47.7632, 82.478],
                [-46.77, 82.5647],
                [-46.1292, 82.5893],
                [-46.199, 82.761],
                [-47.1275, 82.7809],
                [-47.1715, 82.9864],
                [-46.77, 82.997],
                [-45.6356, 82.8842],
                [-43.5391, 83.2165],
                [-39.6446, 82.9078],
                [-38.9224, 83.012],
                [-38.9153, 83.1172],
                [-39.3401, 83.2382],
                [-39.3386, 83.4149],
                [-32.5912, 83.6315],
                [-26.8793, 83.5214],
                [-26.147, 83.423],
                [-26.0334, 83.1866],
                [-24.4093, 83.1561],
                [-24.5304, 82.9324],
              ],
            ],
            [
              [
                [-17.2649, 75.0442],
                [-17.6003, 74.9329],
                [-17.6953, 75.0381],
                [-17.6481, 75.1756],
                [-17.3607, 75.1828],
                [-17.2649, 75.0442],
              ],
            ],
            [
              [
                [-17.3861, 81.5452],
                [-17.6235, 81.5387],
                [-17.9342, 81.6715],
                [-17.8555, 81.7566],
                [-17.411, 81.6739],
                [-17.3861, 81.5452],
              ],
            ],
            [
              [
                [-17.5816, 77.65],
                [-18.0207, 77.5849],
                [-18.0786, 77.6494],
                [-17.8382, 77.8448],
                [-17.5374, 77.8124],
                [-17.5816, 77.65],
              ],
            ],
            [
              [
                [-17.5804, 79.0358],
                [-18.0817, 78.9271],
                [-18.0431, 79.0874],
                [-17.7734, 79.2066],
                [-17.5804, 79.0358],
              ],
            ],
            [
              [
                [-18.5653, 75.3622],
                [-18.3484, 75.3142],
                [-17.9481, 75.3339],
                [-17.9885, 75.0448],
                [-18.1892, 75.0098],
                [-18.8317, 75.0354],
                [-18.8272, 75.247],
                [-18.5653, 75.3622],
              ],
            ],
            [
              [
                [-18.3165, 78.6781],
                [-18.3217, 78.4218],
                [-18.624, 78.482],
                [-18.6947, 78.5795],
                [-18.6527, 78.6529],
                [-18.3165, 78.6781],
              ],
            ],
            [
              [
                [-18.4531, 76.0546],
                [-18.5643, 75.9973],
                [-19.0874, 76.4571],
                [-19.0731, 76.6711],
                [-18.8351, 76.6039],
                [-18.4531, 76.0546],
              ],
            ],
            [
              [
                [-18.719, 78.1402],
                [-18.6553, 77.9265],
                [-19.2828, 77.9391],
                [-19.3204, 78.1402],
                [-18.8862, 78.0305],
                [-18.719, 78.1402],
              ],
            ],
            [
              [
                [-18.7015, 74.6539],
                [-18.9369, 74.4328],
                [-19.1717, 74.573],
                [-19.0131, 74.7286],
                [-18.7015, 74.6539],
              ],
            ],
            [
              [
                [-18.7265, 81.8228],
                [-19.0254, 81.8053],
                [-19.4416, 82.0286],
                [-19.207, 82.1289],
                [-18.7085, 81.9408],
                [-18.7265, 81.8228],
              ],
            ],
            [
              [
                [-22.0096, 71.5912],
                [-22.3027, 71.5214],
                [-22.4128, 71.745],
                [-22.2005, 71.8426],
                [-22.0096, 71.5912],
              ],
            ],
            [
              [
                [-39.8943, 83.4248],
                [-40.0202, 83.221],
                [-40.4045, 83.3422],
                [-40.3036, 83.4598],
                [-39.8943, 83.4248],
              ],
            ],
            [
              [
                [-52.269, 69.4511],
                [-53.9908, 69.3202],
                [-54.0375, 69.5312],
                [-54.7382, 69.6289],
                [-54.7804, 70.0919],
                [-54.6738, 70.185],
                [-54.2786, 70.3043],
                [-53.6205, 70.248],
                [-53.0825, 70.0919],
                [-52.269, 69.8418],
                [-52.0279, 69.8041],
                [-51.9308, 69.741],
                [-51.9982, 69.572],
                [-52.269, 69.4511],
              ],
            ],
            [
              [
                [-53.4106, 71.0613],
                [-53.6342, 71.0077],
                [-53.9188, 71.1585],
                [-53.6118, 71.3306],
                [-53.4106, 71.0613],
              ],
            ],
            [
              [
                [-56.7563, 73.8478],
                [-56.9074, 73.8149],
                [-56.9682, 73.9902],
                [-56.8828, 74.2048],
                [-56.5285, 74.1965],
                [-56.7563, 73.8478],
              ],
            ],
            [
              [
                [-57.0764, 74.4304],
                [-57.2939, 74.3446],
                [-57.4436, 74.4668],
                [-57.0764, 74.7186],
                [-57.0764, 74.4304],
              ],
            ],
          ],
        },
        properties: { name: "Greenland" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-16.747, 13.0637],
              [-16.8106, 13.3348],
              [-16.5467, 13.5909],
              [-16.5068, 13.5909],
              [-16.1395, 13.5914],
              [-15.0767, 13.7903],
              [-13.9619, 13.512],
              [-13.8396, 13.3348],
              [-14.0997, 13.2862],
              [-14.2079, 13.2305],
              [-14.383, 13.2445],
              [-14.5538, 13.3348],
              [-15.102, 13.5695],
              [-15.3101, 13.3781],
              [-15.8026, 13.3348],
              [-15.8577, 13.1872],
              [-16.1395, 13.1667],
              [-16.747, 13.0637],
            ],
          ],
        },
        properties: { name: "Gambia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-11.3795, 12.4136],
              [-11.3138, 12.1429],
              [-10.6468, 11.9712],
              [-9.0923, 12.3591],
              [-8.5611, 11.3615],
              [-8.5566, 11.0662],
              [-7.9785, 10.1751],
              [-8.1218, 9.6107],
              [-7.8783, 9.2737],
              [-7.9101, 8.8593],
              [-7.7092, 8.5226],
              [-8.1489, 8.4531],
              [-8.2025, 8.2206],
              [-8.0418, 8.107],
              [-8.1719, 7.6791],
              [-8.4722, 7.5546],
              [-9.1699, 7.3537],
              [-9.5993, 8.3707],
              [-10.282, 8.488],
              [-10.5938, 8.3973],
              [-10.5221, 8.6984],
              [-10.7248, 9.3546],
              [-11.3001, 9.9887],
              [-12.4355, 9.8266],
              [-13.0138, 9.1406],
              [-13.3027, 9.0404],
              [-13.481, 9.5749],
              [-14.3944, 10.2521],
              [-14.9028, 11.0929],
              [-14.6662, 11.4317],
              [-13.7975, 11.7717],
              [-13.7083, 12.6762],
              [-13.2641, 12.649],
              [-12.4334, 12.3458],
              [-11.3795, 12.4136],
            ],
          ],
        },
        properties: { name: "Guinea" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [9.8215, 2.3478],
                [10.1506, 2.1693],
                [11.3346, 2.1739],
                [11.2847, 1.0376],
                [9.662, 1.0598],
                [9.4026, 1.2325],
                [9.7791, 1.8945],
                [9.8215, 2.3478],
              ],
            ],
            [
              [
                [8.9477, 3.6456],
                [8.6776, 3.1916],
                [8.3963, 3.3507],
                [8.5671, 3.4704],
                [8.6916, 3.7949],
                [8.9007, 3.7856],
                [8.9477, 3.6456],
              ],
            ],
            [
              [
                [5.7677, -1.4352],
                [5.7396, -1.6057],
                [5.533, -1.591],
                [5.5092, -1.2886],
                [5.6073, -1.2091],
                [5.7677, -1.4352],
              ],
            ],
          ],
        },
        properties: { name: "Equatorial Guinea" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-36.1482, -54.3785],
                [-36.0495, -54.572],
                [-35.8595, -54.5547],
                [-35.8709, -54.7898],
                [-36.2473, -54.8133],
                [-36.4526, -54.5331],
                [-36.8273, -54.4593],
                [-37.2127, -54.2092],
                [-36.9226, -54.0603],
                [-36.6745, -54.2796],
                [-36.1482, -54.3785],
              ],
            ],
            [
              [
                [-37.3814, -54.1483],
                [-37.6379, -54.2553],
                [-37.8265, -54.128],
                [-37.4428, -53.9379],
                [-37.3814, -54.1483],
              ],
            ],
            [
              [
                [-41.4845, -53.6988],
                [-41.5768, -53.7952],
                [-41.9803, -53.6333],
                [-41.7669, -53.4771],
                [-41.4845, -53.6988],
              ],
            ],
          ],
        },
        properties: { name: "South Georgia and The South Sandwich Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-88.9128, 15.9001],
              [-88.2213, 15.7205],
              [-89.0755, 15.0705],
              [-89.3532, 14.4185],
              [-89.435, 14.3466],
              [-89.6593, 14.1495],
              [-90.0133, 13.8384],
              [-90.1243, 13.7409],
              [-90.1338, 13.7454],
              [-90.7911, 13.8738],
              [-91.0362, 13.9216],
              [-92.2313, 14.5333],
              [-92.1126, 15.2942],
              [-91.8002, 15.8454],
              [-90.4981, 16.2084],
              [-91.1617, 17.0796],
              [-90.9613, 17.6841],
              [-90.0133, 17.7907],
              [-89.6593, 17.7984],
              [-89.435, 17.8032],
              [-89.147, 17.8095],
              [-89.1972, 16.2896],
              [-89.1806, 15.9683],
              [-88.9128, 15.9001],
            ],
          ],
        },
        properties: { name: "Guatemala" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [144.5399, 13.4638],
              [144.7864, 13.5479],
              [144.866, 13.7133],
              [145.0169, 13.6423],
              [144.6352, 13.1516],
              [144.5399, 13.4638],
            ],
          ],
        },
        properties: { name: "Guam" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-14.9028, 11.0929],
                [-14.9581, 10.9988],
                [-15.2684, 11.2026],
                [-15.2842, 11.6135],
                [-15.6256, 11.6966],
                [-15.9355, 11.4707],
                [-16.0111, 11.7422],
                [-16.7074, 12.3386],
                [-15.1085, 12.6633],
                [-13.7083, 12.6762],
                [-13.7975, 11.7717],
                [-14.6662, 11.4317],
                [-14.9028, 11.0929],
              ],
            ],
            [
              [
                [-15.6251, 10.7377],
                [-15.8016, 10.6407],
                [-15.9372, 10.8898],
                [-15.5471, 10.9821],
                [-15.6251, 10.7377],
              ],
            ],
            [
              [
                [-15.8081, 11.0723],
                [-16.0685, 10.9738],
                [-16.2077, 11.0476],
                [-16.2077, 11.2233],
                [-15.9304, 11.2239],
                [-15.8081, 11.0723],
              ],
            ],
          ],
        },
        properties: { name: "Guinea-Bissau" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-57.134, 5.8374],
              [-57.2815, 5.2363],
              [-57.3549, 5.0511],
              [-57.8536, 4.8346],
              [-58.0157, 4.0688],
              [-57.786, 3.6417],
              [-57.3197, 3.3177],
              [-57.0218, 2.6002],
              [-56.4774, 1.9459],
              [-57.2818, 1.9505],
              [-58.8112, 1.2685],
              [-59.511, 1.7084],
              [-59.9399, 2.6147],
              [-59.642, 4.1358],
              [-60.0054, 4.5779],
              [-60.1578, 5.1926],
              [-60.7318, 5.1961],
              [-61.2634, 5.8625],
              [-61.2089, 6.4133],
              [-60.4378, 6.9909],
              [-60.6142, 7.5487],
              [-59.9947, 8.1428],
              [-59.994, 8.5334],
              [-59.037, 7.9339],
              [-58.3467, 6.9418],
              [-57.2986, 6.2066],
              [-57.134, 5.8374],
            ],
          ],
        },
        properties: { name: "Guyana" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [114.0376, 22.5037],
              [114.2348, 22.5558],
              [114.2598, 22.252],
              [113.9355, 22.1785],
              [113.8834, 22.3116],
              [114.0376, 22.5037],
            ],
          ],
        },
        properties: { name: "Hong Kong" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [73.3253, -53.1447],
              [73.4256, -52.988],
              [73.7196, -53.023],
              [73.7196, -53.1581],
              [73.4531, -53.2566],
              [73.3253, -53.1447],
            ],
          ],
        },
        properties: { name: "Heard Island and Mcdonald Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-83.145, 14.9981],
                [-84.4054, 14.6387],
                [-84.9132, 14.7538],
                [-85.3235, 14.1899],
                [-86.6936, 13.744],
                [-86.7646, 13.3165],
                [-87.3121, 12.9829],
                [-87.5178, 13.2785],
                [-87.8057, 13.3959],
                [-87.8678, 13.8204],
                [-88.5397, 13.9656],
                [-89.3532, 14.4185],
                [-89.0755, 15.0705],
                [-88.2213, 15.7205],
                [-87.7568, 15.8825],
                [-86.7792, 15.7641],
                [-85.9388, 15.9862],
                [-84.9024, 15.9532],
                [-84.3175, 15.798],
                [-83.8765, 15.3067],
                [-83.4621, 15.2635],
                [-83.145, 14.9981],
              ],
            ],
            [
              [
                [-86.0956, 16.2984],
                [-86.3235, 16.2529],
                [-86.3901, 16.503],
                [-86.149, 16.6249],
                [-86.0956, 16.2984],
              ],
            ],
          ],
        },
        properties: { name: "Honduras" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [18.8897, 45.9212],
                [19.0339, 45.4868],
                [19.0053, 45.4346],
                [19.0771, 45.3405],
                [19.4252, 45.1677],
                [19.1373, 45.1187],
                [19.0221, 44.8554],
                [18.8337, 44.8701],
                [18.7408, 45.0207],
                [18.5297, 45.0875],
                [18.019, 45.1294],
                [17.8339, 45.0615],
                [17.4433, 45.1597],
                [17.1412, 45.1633],
                [16.2537, 45.0676],
                [15.84, 45.2245],
                [15.7367, 44.9356],
                [16.1322, 44.4545],
                [16.1724, 44.2092],
                [16.4384, 44.0425],
                [16.5443, 43.9742],
                [17.4508, 43.1769],
                [18.1539, 42.6893],
                [18.4381, 42.5557],
                [18.5252, 42.4205],
                [17.9364, 42.6878],
                [17.6492, 42.8889],
                [17.5813, 42.9384],
                [17.3561, 43.0842],
                [16.9389, 43.3334],
                [16.0165, 43.5019],
                [15.5392, 43.8578],
                [15.3244, 43.9473],
                [15.3244, 43.9136],
                [15.3244, 43.9094],
                [15.3244, 43.8762],
                [15.3244, 43.872],
                [15.3244, 43.8665],
                [15.3244, 43.8488],
                [15.3244, 43.8185],
                [15.3244, 43.7913],
                [15.2224, 43.8714],
                [15.0388, 44.1357],
                [15.2786, 44.3661],
                [14.954, 44.5495],
                [14.9025, 44.6902],
                [14.8813, 45.0311],
                [14.5261, 44.9265],
                [14.2264, 45.1538],
                [13.8453, 44.8883],
                [13.543, 45.3405],
                [13.6513, 45.4541],
                [14.1113, 45.4823],
                [14.1182, 45.4812],
                [14.3909, 45.5034],
                [14.57, 45.6729],
                [15.2264, 45.4271],
                [15.3313, 45.7629],
                [15.4044, 45.7927],
                [15.7064, 45.9753],
                [15.6273, 46.086],
                [15.7915, 46.2593],
                [15.8735, 46.2793],
                [16.3015, 46.3783],
                [16.2421, 46.4901],
                [16.5968, 46.4759],
                [16.8548, 46.3504],
                [16.876, 46.3206],
                [17.2943, 45.9885],
                [17.4433, 45.9496],
                [17.6515, 45.8478],
                [17.9121, 45.7908],
                [18.8213, 45.9144],
                [18.8897, 45.9212],
              ],
            ],
            [
              [
                [17.3294, 42.9672],
                [17.2813, 42.8579],
                [16.9818, 42.8645],
                [16.9248, 42.9563],
                [17.0344, 43.0844],
                [17.2145, 43.0935],
                [17.3294, 42.9672],
              ],
            ],
            [
              [
                [16.6308, 42.2935],
                [16.3305, 42.2716],
                [16.1233, 42.3939],
                [16.2575, 42.4915],
                [16.5125, 42.4661],
                [16.6308, 42.2935],
              ],
            ],
            [
              [
                [15.8854, 42.9677],
                [15.6582, 42.908],
                [15.4565, 42.992],
                [15.5523, 43.0512],
                [15.8748, 43.15],
                [16.0236, 43.0999],
                [15.8854, 42.9677],
              ],
            ],
          ],
        },
        properties: { name: "Croatia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-71.7536, 18.0307],
                [-72.1734, 18.2281],
                [-73.8928, 18.1341],
                [-74.4327, 18.4518],
                [-74.2391, 18.6521],
                [-72.8756, 18.4506],
                [-72.3981, 18.5879],
                [-72.8389, 19.4889],
                [-73.3781, 19.6946],
                [-73.2461, 19.873],
                [-72.7983, 19.9331],
                [-71.7579, 19.702],
                [-71.6752, 19.1658],
                [-71.8766, 18.5572],
                [-71.7536, 18.0307],
              ],
            ],
            [
              [
                [-72.8109, 18.8339],
                [-72.7474, 18.7171],
                [-73.034, 18.743],
                [-73.3489, 18.9417],
                [-73.2274, 18.9926],
                [-72.8109, 18.8339],
              ],
            ],
          ],
        },
        properties: { name: "Haiti" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [22.1553, 48.4034],
              [22.8963, 47.9541],
              [22.1808, 47.6001],
              [22.1283, 47.5981],
              [21.659, 47.0221],
              [21.4414, 46.6515],
              [21.1475, 46.3359],
              [20.7756, 46.2759],
              [20.7053, 46.1609],
              [20.2643, 46.1264],
              [20.1365, 46.1341],
              [19.8654, 46.1503],
              [19.6981, 46.1879],
              [19.3027, 45.9916],
              [18.8897, 45.9212],
              [18.8213, 45.9144],
              [17.9121, 45.7908],
              [17.6515, 45.8478],
              [17.4433, 45.9496],
              [17.2943, 45.9885],
              [16.876, 46.3206],
              [16.8548, 46.3504],
              [16.5968, 46.4759],
              [16.3708, 46.7222],
              [16.1138, 46.8691],
              [16.4594, 47.0441],
              [16.4338, 47.3529],
              [16.6462, 47.4466],
              [16.4215, 47.6653],
              [16.9914, 47.72],
              [17.1608, 48.0067],
              [17.2474, 48.012],
              [17.7054, 47.759],
              [17.8939, 47.7395],
              [18.8485, 47.8182],
              [18.7602, 47.9145],
              [18.9284, 48.0568],
              [19.0143, 48.0777],
              [20.0519, 48.1677],
              [20.4639, 48.464],
              [21.4106, 48.5497],
              [22.1211, 48.3783],
              [22.1553, 48.4034],
            ],
          ],
        },
        properties: { name: "Hungary" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-6.268, 54.1023],
              [-6.2149, 53.6334],
              [-6.0843, 53.4842],
              [-6.1028, 53.2108],
              [-6.1445, 52.7377],
              [-6.4842, 52.2218],
              [-7.8422, 51.9542],
              [-9.6116, 51.5096],
              [-10.2193, 51.7677],
              [-10.1497, 52.2477],
              [-9.3755, 52.5878],
              [-9.4044, 52.9687],
              [-9.0093, 53.1408],
              [-9.8734, 53.3329],
              [-10.1572, 53.5751],
              [-10.0368, 53.7783],
              [-10.1697, 54.0152],
              [-9.9073, 54.2643],
              [-9.1323, 54.1624],
              [-8.4004, 54.4797],
              [-8.6423, 54.6824],
              [-8.2513, 55.1317],
              [-7.3138, 55.311],
              [-7.2561, 55.067],
              [-7.6551, 54.7729],
              [-7.5702, 54.6426],
              [-7.8411, 54.3349],
              [-7.4291, 54.1829],
              [-7.1885, 54.3377],
              [-6.8642, 54.3302],
              [-6.6439, 54.18],
              [-6.595, 54.0447],
              [-6.268, 54.1023],
            ],
          ],
        },
        properties: { name: "Ireland" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [35.6716, 32.6911],
              [35.5551, 32.389],
              [35.2216, 32.5167],
              [35.0135, 32.3104],
              [35.0826, 31.7397],
              [34.9515, 31.4243],
              [35.476, 31.4905],
              [34.9782, 29.5418],
              [34.9051, 29.4932],
              [34.5937, 30.3802],
              [34.2688, 31.2205],
              [34.4994, 31.4912],
              [34.4909, 31.5959],
              [34.5937, 31.7385],
              [34.7067, 31.9416],
              [34.8436, 32.3104],
              [35.1034, 33.0936],
              [35.6244, 33.2423],
              [35.6716, 32.6911],
            ],
          ],
        },
        properties: { name: "Israel" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-4.7696, 54.0657],
              [-4.7212, 54.2029],
              [-4.5499, 54.3537],
              [-4.3866, 54.3997],
              [-4.3376, 54.2928],
              [-4.5499, 54.1006],
              [-4.7696, 54.0657],
            ],
          ],
        },
        properties: { name: "Isle of Man" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [78.398, 32.5232],
                [78.4736, 32.2395],
                [78.5766, 32.0593],
                [78.769, 31.9377],
                [78.8882, 31.2723],
                [79.138, 31.003],
                [79.4224, 31.064],
                [79.9526, 30.8744],
                [80.2176, 30.583],
                [81.0369, 30.2028],
                [80.4172, 29.7013],
                [80.1928, 28.9158],
                [80.3067, 28.7183],
                [83.0809, 27.4663],
                [84.2444, 27.4339],
                [85.2178, 26.8066],
                [87.0575, 26.4379],
                [87.4011, 26.3831],
                [88.0627, 26.484],
                [88.1704, 26.852],
                [88.0432, 27.206],
                [88.1351, 27.8819],
                [88.7725, 28.0233],
                [88.9233, 27.2728],
                [89.0273, 26.9609],
                [89.1233, 26.8977],
                [89.7443, 26.7397],
                [90.0071, 26.7529],
                [92.1079, 26.8583],
                [92.697, 26.9058],
                [93.2491, 26.9504],
                [93.6616, 26.9837],
                [94.2529, 27.5398],
                [95.7527, 28.2572],
                [96.3086, 28.1895],
                [97.0575, 27.7441],
                [96.8989, 27.2897],
                [96.1868, 27.1873],
                [95.203, 26.5525],
                [94.2381, 24.0841],
                [94.0361, 23.9215],
                [93.3872, 23.9016],
                [93.1232, 22.3714],
                [92.6068, 21.9712],
                [92.1892, 23.5198],
                [92.0094, 23.5264],
                [91.6475, 23.1403],
                [91.2736, 23.6319],
                [91.453, 24.0284],
                [92.1082, 24.5094],
                [92.2478, 25.0052],
                [90.0071, 25.3633],
                [89.7217, 26.0307],
                [89.1233, 26.2142],
                [88.5924, 26.3771],
                [88.2105, 25.8776],
                [88.8173, 25.3069],
                [88.1499, 24.6608],
                [88.6844, 24.2282],
                [88.6343, 23.6942],
                [88.9238, 23.0935],
                [89.0628, 21.9257],
                [89.0238, 21.887],
                [88.6191, 21.6875],
                [87.8076, 21.7084],
                [87.3346, 21.4676],
                [86.4061, 20.0929],
                [84.9085, 19.1741],
                [82.6146, 17.2281],
                [82.0003, 16.4865],
                [80.3399, 15.5112],
                [80.1667, 14.9797],
                [80.2595, 13.2612],
                [79.8627, 11.8084],
                [79.7489, 10.5292],
                [78.7661, 9.2097],
                [77.9593, 8.4451],
                [77.5097, 8.2855],
                [76.494, 9.1984],
                [73.438, 16.3077],
                [72.792, 19.4375],
                [72.8081, 20.7982],
                [72.5294, 21.9031],
                [72.3579, 21.9557],
                [72.1176, 21.4636],
                [71.6404, 21.0458],
                [70.9636, 20.8337],
                [70.3921, 21.0003],
                [69.2859, 22.2151],
                [70.0091, 22.6016],
                [70.1621, 22.8467],
                [69.485, 22.8793],
                [68.7003, 23.3122],
                [68.6735, 23.6224],
                [69.218, 23.9453],
                [68.9389, 24.2488],
                [70.0829, 24.2255],
                [69.9677, 23.8801],
                [70.1285, 23.6507],
                [70.316, 23.881],
                [70.9228, 23.9726],
                [70.8701, 24.2488],
                [71.0157, 24.7573],
                [70.716, 25.4417],
                [70.2013, 25.93],
                [70.084, 26.4979],
                [69.5759, 26.9485],
                [70.3421, 27.909],
                [70.9183, 27.7815],
                [71.9298, 28.0722],
                [74.4044, 30.8691],
                [74.65, 31.8079],
                [75.3339, 32.3266],
                [76.5852, 33.1567],
                [77.3152, 32.8606],
                [77.7621, 32.9237],
                [78.398, 32.5232],
              ],
            ],
            [
              [
                [93.9514, 7.0194],
                [93.8073, 6.808],
                [93.6389, 7.1267],
                [93.8473, 7.2598],
                [93.9514, 7.0194],
              ],
            ],
            [
              [
                [92.7744, 11.8045],
                [92.6556, 11.6296],
                [92.5401, 11.8045],
                [92.8167, 13.1674],
                [92.945, 13.6002],
                [93.087, 13.4621],
                [92.9348, 13.0071],
                [92.9758, 12.5434],
                [92.9348, 12.1948],
                [92.7584, 12.0256],
                [92.7744, 11.8045],
              ],
            ],
            [
              [
                [93.0147, 8.8774],
                [92.8391, 8.5952],
                [92.7008, 8.6844],
                [92.8953, 9.0924],
                [93.0147, 8.8774],
              ],
            ],
            [
              [
                [92.61, 10.6965],
                [92.5338, 10.497],
                [92.3721, 10.523],
                [92.3637, 10.7851],
                [92.523, 10.9194],
                [92.61, 10.6965],
              ],
            ],
            [
              [
                [73.25, 8.2245],
                [73.1115, 8.034],
                [72.8237, 8.2237],
                [73.1579, 8.3749],
                [73.25, 8.2245],
              ],
            ],
            [
              [
                [68.9485, 23.9873],
                [68.7147, 23.8086],
                [68.5248, 23.9208],
                [68.7308, 24.154],
                [68.9485, 23.9873],
              ],
            ],
          ],
        },
        properties: { name: "India" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [72.2496, -7.2082],
              [72.4022, -7.1035],
              [72.5587, -7.2509],
              [72.5618, -7.4562],
              [72.4008, -7.582],
              [72.3982, -7.3365],
              [72.2496, -7.2082],
            ],
          ],
        },
        properties: { name: "British Indian Ocean Territory" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [44.7931, 37.1763],
              [45.1379, 36.4692],
              [46.0584, 35.6666],
              [46.0875, 35.1508],
              [45.5289, 34.4408],
              [45.5675, 33.8898],
              [46.2417, 32.9842],
              [47.4435, 32.3096],
              [47.7857, 31.7734],
              [47.7508, 31.0984],
              [48.4844, 29.9791],
              [47.9591, 30.0038],
              [47.2144, 30.0133],
              [46.553, 29.1012],
              [46.4258, 29.0612],
              [46.1115, 29.0876],
              [45.6126, 29.1294],
              [44.663, 29.2518],
              [42.1579, 31.0566],
              [40.4951, 31.898],
              [39.2009, 32.1543],
              [38.7925, 33.3747],
              [41.0138, 34.4548],
              [41.3561, 35.6375],
              [41.3313, 36.384],
              [42.3557, 37.108],
              [42.8718, 37.37],
              [43.4246, 37.2753],
              [44.1298, 37.279],
              [44.3175, 37.0426],
              [44.7931, 37.1763],
            ],
          ],
        },
        properties: { name: "Iraq" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [179.0015, -49.6501],
                [178.7724, -49.8507],
                [178.5452, -49.6947],
                [178.909, -49.5191],
                [179.0015, -49.6501],
              ],
            ],
            [
              [
                [175.211, -36.9434],
                [175.4301, -37.208],
                [175.5268, -37.1542],
                [175.4246, -36.7147],
                [175.4907, -36.6256],
                [175.8471, -36.9434],
                [176.1621, -37.6155],
                [177.2769, -37.9942],
                [177.9092, -37.5919],
                [178.3213, -37.5744],
                [178.4965, -37.7213],
                [178.3276, -38.3317],
                [177.8392, -39.0056],
                [177.0026, -39.2801],
                [176.9183, -40.0124],
                [176.066, -41.1167],
                [175.3236, -41.5785],
                [174.715, -41.3285],
                [175.1401, -40.6613],
                [175.1781, -40.2119],
                [173.7984, -39.3176],
                [173.9188, -39.1348],
                [174.533, -38.8729],
                [174.6003, -38.7145],
                [174.8117, -37.7731],
                [174.3377, -36.4518],
                [174.0344, -36.3225],
                [173.3415, -35.512],
                [173.1236, -35.1608],
                [173.2284, -34.8726],
                [173.904, -35.0379],
                [174.4677, -35.512],
                [174.4877, -35.9133],
                [174.5887, -36.0626],
                [174.8279, -36.712],
                [175.211, -36.9434],
              ],
            ],
            [
              [
                [172.7362, -40.69],
                [173.1993, -41.2224],
                [173.9697, -40.9516],
                [174.1467, -41.8038],
                [172.8252, -43.2176],
                [172.9688, -43.7881],
                [171.8361, -44.0752],
                [171.3433, -44.3576],
                [171.1099, -44.7518],
                [170.5556, -45.8222],
                [169.6687, -46.4979],
                [168.9475, -46.6627],
                [168.3749, -46.5934],
                [167.5117, -46.1908],
                [166.7007, -46.1853],
                [166.4494, -45.8621],
                [166.8476, -45.2798],
                [167.4731, -44.7518],
                [168.1666, -44.1848],
                [170.8669, -42.7873],
                [172.4063, -40.7032],
                [172.7362, -40.69],
              ],
            ],
            [
              [
                [173.148, -34.7698],
                [173.012, -34.8214],
                [172.7207, -34.4944],
                [173.0069, -34.3878],
                [172.9857, -34.5219],
                [173.148, -34.7698],
              ],
            ],
            [
              [
                [168.2008, -47.0612],
                [167.6336, -47.2661],
                [167.5502, -47.1551],
                [167.7658, -46.8901],
                [167.7198, -46.705],
                [167.8617, -46.677],
                [168.1705, -46.8607],
                [168.2008, -47.0612],
              ],
            ],
            [
              [
                [166.3205, -50.841],
                [166.1375, -50.9601],
                [165.8918, -50.8077],
                [166.1559, -50.5356],
                [166.3205, -50.841],
              ],
            ],
            [
              [
                [-176.3517, -44.0765],
                [-176.5996, -44.2034],
                [-176.7921, -43.9919],
                [-176.3561, -43.9203],
                [-176.3517, -44.0765],
              ],
            ],
            [
              [
                [-176.6359, -43.5673],
                [-176.6389, -43.7018],
                [-176.9625, -43.6642],
                [-177.0155, -43.5437],
                [-176.7712, -43.4085],
                [-176.6359, -43.5673],
              ],
            ],
            [
              [
                [-178.4544, -30.4002],
                [-178.4299, -30.7471],
                [-178.7271, -30.6285],
                [-178.697, -30.4381],
                [-178.4544, -30.4002],
              ],
            ],
          ],
        },
        properties: { name: "New Zealand" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [56.3742, 24.9804],
                [56.8749, 24.2008],
                [57.2627, 23.9326],
                [58.6784, 23.5339],
                [59.305, 22.7683],
                [59.7246, 22.502],
                [59.749, 22.1614],
                [59.4198, 21.5894],
                [58.5057, 20.4815],
                [58.1107, 20.4832],
                [57.878, 20.2393],
                [57.7116, 19.7373],
                [57.7294, 18.9955],
                [56.8314, 18.7324],
                [56.3286, 17.9621],
                [55.4639, 17.7892],
                [54.987, 17.055],
                [54.0762, 16.9726],
                [53.107, 16.6544],
                [52, 19],
                [54.9485, 20.0059],
                [55.5332, 21.6026],
                [55.623, 21.8699],
                [55.6667, 22],
                [55.2083, 22.7083],
                [55.5272, 23.8382],
                [55.8942, 24.1886],
                [55.851, 24.8578],
                [56.1415, 24.8043],
                [56.3742, 24.9804],
              ],
            ],
            [
              [
                [58.7788, 20.2184],
                [58.5562, 20.2053],
                [58.8901, 20.6868],
                [59.0327, 20.5538],
                [58.8419, 20.4561],
                [58.7788, 20.2184],
              ],
            ],
            [
              [
                [56.2658, 25.6247],
                [56.0871, 26.0513],
                [56.364, 26.1347],
                [56.2658, 25.6247],
              ],
            ],
          ],
        },
        properties: { name: "Oman" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-77.3675, 8.6728],
                [-77.2491, 7.9096],
                [-77.893, 7.2218],
                [-78.3561, 7.898],
                [-78.3616, 8.3659],
                [-79.2671, 8.954],
                [-80.3163, 8.2753],
                [-80.1249, 7.5415],
                [-80.6084, 7.2851],
                [-81.9829, 8.1676],
                [-82.7153, 8.2748],
                [-82.9004, 8.0398],
                [-82.9936, 8.3047],
                [-82.7781, 8.9311],
                [-82.9148, 9.3744],
                [-82.5646, 9.572],
                [-82.1142, 9.2781],
                [-82.1606, 9.0042],
                [-81.2964, 8.7963],
                [-79.5888, 9.5775],
                [-78.9287, 9.5371],
                [-78.1197, 9.2829],
                [-77.3675, 8.6728],
              ],
            ],
            [
              [
                [-78.781, 8.4146],
                [-78.9039, 8.1216],
                [-79.1048, 8.3141],
                [-78.9004, 8.5713],
                [-78.781, 8.4146],
              ],
            ],
            [
              [
                [-81.6151, 7.4985],
                [-81.7789, 7.3184],
                [-81.9587, 7.5187],
                [-81.7272, 7.7381],
                [-81.6151, 7.4985],
              ],
            ],
          ],
        },
        properties: { name: "Panama" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-69.9534, -4.2305],
              [-70.8737, -4.2367],
              [-71.899, -4.564],
              [-72.6878, -5.0578],
              [-73.1725, -6.3431],
              [-73.712, -6.95],
              [-73.8818, -7.5555],
              [-73.0739, -8.8492],
              [-72.9782, -9.3412],
              [-72.3919, -9.5555],
              [-72.0116, -9.9595],
              [-71.3377, -9.9639],
              [-70.631, -9.6918],
              [-70.5742, -10.9321],
              [-69.5103, -10.9543],
              [-68.7259, -12.4796],
              [-68.9523, -12.9637],
              [-68.8868, -14.1931],
              [-69.3052, -14.785],
              [-69.1901, -15.2484],
              [-69.3798, -15.6492],
              [-69.2396, -16.0733],
              [-68.8869, -16.3343],
              [-69.5634, -17.2192],
              [-69.4726, -17.499],
              [-69.7443, -17.665],
              [-69.9206, -18.183],
              [-70.3762, -18.3536],
              [-71.2706, -17.7324],
              [-71.4978, -17.3049],
              [-75.1079, -15.4055],
              [-76.235, -14.2095],
              [-76.2379, -13.3923],
              [-77.1075, -12.0879],
              [-77.2567, -11.6027],
              [-77.5984, -11.2957],
              [-78.9821, -8.2756],
              [-79.9678, -6.8066],
              [-81.0363, -6.0325],
              [-80.9295, -5.5289],
              [-81.1436, -5.149],
              [-81.2324, -4.3904],
              [-80.2733, -3.4262],
              [-80.2973, -4.3463],
              [-79.0222, -4.799],
              [-78.0291, -3.2721],
              [-76.5151, -2.4592],
              [-76.3212, -2.2733],
              [-75.7923, -1.766],
              [-75.6084, -1.582],
              [-75.2664, -0.8786],
              [-75.511, -0.2279],
              [-75.2882, -0.0937],
              [-74.5531, -0.4725],
              [-74.2103, -0.8786],
              [-72.9094, -2.42],
              [-70.8922, -2.3015],
              [-70.1573, -2.707],
              [-70.5831, -3.7195],
              [-69.9534, -4.2305],
            ],
          ],
        },
        properties: { name: "Peru" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [155.9192, -6.7298],
                [155.7032, -6.8882],
                [155.2445, -6.602],
                [154.6647, -5.5612],
                [155.0348, -5.5601],
                [155.4671, -6.1312],
                [155.9192, -6.5093],
                [155.9192, -6.7298],
              ],
            ],
            [
              [
                [154.7075, -5.4061],
                [154.5641, -5.4238],
                [154.4816, -5.2075],
                [154.6129, -4.9528],
                [154.731, -5.2106],
                [154.7075, -5.4061],
              ],
            ],
            [
              [
                [154.1991, -11.4815],
                [153.9769, -11.4966],
                [153.8434, -11.3865],
                [153.9506, -11.2186],
                [154.2051, -11.2912],
                [154.1991, -11.4815],
              ],
            ],
            [
              [
                [153.8364, -9.277],
                [153.6307, -9.5031],
                [153.5542, -9.3382],
                [153.5685, -9.133],
                [153.8052, -9.122],
                [153.8364, -9.277],
              ],
            ],
            [
              [
                [153.713, -11.5138],
                [153.6874, -11.6582],
                [153.3321, -11.5633],
                [153.2803, -11.4371],
                [153.3863, -11.3614],
                [153.713, -11.5138],
              ],
            ],
            [
              [
                [153.0721, -9.1649],
                [152.827, -9.3065],
                [152.6431, -9.1305],
                [152.7584, -8.9624],
                [153.0721, -9.1649],
              ],
            ],
            [
              [
                [152.654, -3.8377],
                [153.0189, -4.1505],
                [153.0268, -4.5844],
                [152.8729, -4.793],
                [152.6887, -4.5631],
                [152.6512, -4.1505],
                [152.5602, -3.9215],
                [152.4332, -3.9308],
                [152.2907, -3.7164],
                [152.4929, -3.5236],
                [152.654, -3.8377],
              ],
            ],
            [
              [
                [152.7791, -3.1485],
                [152.5692, -3.2793],
                [152.3707, -3.0695],
                [152.7167, -2.9522],
                [152.7791, -3.1485],
              ],
            ],
            [
              [
                [151.4771, -5.5212],
                [151.1822, -5.9341],
                [149.9341, -6.3042],
                [148.9713, -6.1273],
                [148.4082, -5.571],
                [148.9495, -5.4603],
                [149.1987, -5.578],
                [149.9043, -5.4776],
                [150.0993, -5.5212],
                [150.466, -5.4361],
                [150.714, -5.5212],
                [151.6195, -4.866],
                [151.5873, -4.2316],
                [152.1767, -4.1451],
                [152.3445, -4.7203],
                [151.9945, -5.0447],
                [152.0686, -5.3754],
                [151.9402, -5.5212],
                [151.4771, -5.5212],
              ],
            ],
            [
              [
                [152.0773, -3.2554],
                [152.2006, -3.4598],
                [151.9254, -3.4501],
                [151.2746, -2.9349],
                [151.3856, -2.8803],
                [151.8075, -3.1893],
                [152.0773, -3.2554],
              ],
            ],
            [
              [
                [151.3415, -9.9479],
                [151.2365, -10.2085],
                [150.9062, -10.1224],
                [150.8864, -10.0152],
                [151.3415, -9.9479],
              ],
            ],
            [
              [
                [151.023, -2.8145],
                [150.6524, -2.8372],
                [150.8226, -2.5295],
                [151.0564, -2.687],
                [151.023, -2.8145],
              ],
            ],
            [
              [
                [150.2231, -9.2357],
                [150.5593, -9.3687],
                [150.7054, -9.276],
                [150.8846, -9.5559],
                [150.8088, -9.6712],
                [150.2297, -9.4642],
                [150.1334, -9.294],
                [150.2231, -9.2357],
              ],
            ],
            [
              [
                [141.0194, -9.1296],
                [141.0191, -8.5941],
                [141.019, -8.2445],
                [140.8902, -6.6579],
                [140.9999, -5.5208],
                [141, -2.7849],
                [141, -2.6087],
                [144.5226, -3.895],
                [145.6132, -4.7063],
                [145.8885, -5.4302],
                [147.645, -6.2159],
                [147.7216, -6.6203],
                [147.0257, -6.8644],
                [147.1783, -7.3019],
                [148.1154, -8.1191],
                [148.5796, -8.9479],
                [149.2016, -9.0947],
                [149.4396, -9.528],
                [149.8839, -9.6781],
                [149.9092, -9.97],
                [150.5112, -10.2194],
                [150.6114, -10.5194],
                [150.2823, -10.6789],
                [149.6128, -10.3643],
                [147.8877, -10.0593],
                [147.0302, -9.4153],
                [146.1837, -8.2648],
                [144.7153, -7.7091],
                [144.0224, -7.8248],
                [143.4401, -8.2673],
                [143.3445, -8.955],
                [142.7941, -9.234],
                [141.0194, -9.1296],
              ],
            ],
            [
              [
                [150.5565, -2.669],
                [150.1644, -2.6868],
                [150.0071, -2.4738],
                [150.1888, -2.3563],
                [150.5155, -2.4882],
                [150.5565, -2.669],
              ],
            ],
            [
              [
                [150.3047, -5.1354],
                [149.9896, -5.2158],
                [149.8801, -5.0624],
                [150.1617, -4.9033],
                [150.3047, -5.1354],
              ],
            ],
            [
              [
                [149.8273, -1.5788],
                [149.6128, -1.6318],
                [149.4282, -1.4102],
                [149.5519, -1.2421],
                [149.8273, -1.5788],
              ],
            ],
            [
              [
                [148.1247, -5.6672],
                [147.918, -5.8171],
                [147.692, -5.4815],
                [147.918, -5.4815],
                [148.1247, -5.6672],
              ],
            ],
            [
              [
                [147.1952, -2.2054],
                [146.9684, -2.2163],
                [146.6056, -2.1052],
                [146.5614, -2.0248],
                [146.6361, -1.9726],
                [147.3562, -1.9772],
                [147.1952, -2.2054],
              ],
            ],
            [
              [
                [147.2773, -5.4451],
                [147.1237, -5.4997],
                [146.9265, -5.2551],
                [147.0787, -5.1438],
                [147.2334, -5.2326],
                [147.2773, -5.4451],
              ],
            ],
            [
              [
                [146.0852, -4.7733],
                [145.8994, -4.8281],
                [145.8177, -4.6723],
                [146.004, -4.4377],
                [146.1186, -4.5388],
                [146.0852, -4.7733],
              ],
            ],
          ],
        },
        properties: { name: "Papua New Guinea" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [141, -2.6087],
                [141, -2.7849],
                [140.9999, -5.5208],
                [140.8902, -6.6579],
                [141.019, -8.2445],
                [141.0191, -8.5941],
                [141.0194, -9.1296],
                [139.6527, -8.2242],
                [138.0183, -8.2334],
                [138.0749, -7.7967],
                [138.5287, -7.0576],
                [137.9026, -5.4377],
                [137.3299, -5.0153],
                [135.4366, -4.4563],
                [134.0279, -3.828],
                [132.9843, -3.7966],
                [132.4483, -2.92],
                [133.2113, -2.3929],
                [132.431, -2.1467],
                [131.9076, -1.5438],
                [131.1531, -1.4128],
                [130.7478, -0.9912],
                [131.0971, -0.8786],
                [132.5168, -0.4211],
                [133.762, -0.7198],
                [133.857, -0.8786],
                [134.1699, -1.4015],
                [134.3555, -2.3404],
                [135.1215, -3.1887],
                [137.8487, -1.5936],
                [141, -2.6087],
              ],
            ],
            [
              [
                [136.7028, -1.8337],
                [136.3807, -1.9596],
                [136.0103, -1.7117],
                [136.1796, -1.6502],
                [136.6722, -1.7337],
                [136.7028, -1.8337],
              ],
            ],
            [
              [
                [136.4118, -1.1122],
                [136.3297, -1.288],
                [135.8664, -1.1994],
                [135.7721, -0.8786],
                [135.7097, -0.7855],
                [135.8485, -0.7237],
                [136.0431, -0.8786],
                [136.22, -1.0698],
                [136.4118, -1.1122],
              ],
            ],
            [
              [
                [135.0428, -0.9106],
                [134.9622, -1.1615],
                [134.7692, -1.1553],
                [134.7059, -0.9938],
                [134.8537, -0.8786],
                [134.9871, -0.8786],
                [135.0428, -0.9106],
              ],
            ],
            [
              [
                [134.7918, -6.5361],
                [134.1571, -6.8062],
                [134.1282, -6.235],
                [134.2335, -5.6965],
                [134.5107, -5.4001],
                [134.671, -5.4598],
                [134.7918, -5.7306],
                [134.7342, -5.9834],
                [134.7918, -6.5361],
              ],
            ],
            [
              [
                [132.878, -5.9113],
                [132.7926, -5.9679],
                [132.6177, -5.7992],
                [132.7274, -5.4757],
                [132.8091, -5.5499],
                [132.878, -5.9113],
              ],
            ],
            [
              [
                [131.6413, -7.1302],
                [131.6172, -7.6325],
                [131.2814, -8.045],
                [131.0996, -8.0712],
                [131.0773, -7.7427],
                [131.1634, -7.7105],
                [131.2636, -7.3135],
                [131.3535, -7.344],
                [131.4364, -7.1152],
                [131.6413, -7.1302],
              ],
            ],
            [
              [
                [131.1805, -0.1179],
                [131.2427, -0.3525],
                [130.4911, -0.526],
                [130.4911, -0.3392],
                [130.2408, -0.3015],
                [130.2438, -0.1179],
                [130.5256, -0.0633],
                [130.7124, -0.1179],
                [130.873, 0.0234],
                [131.1805, -0.1179],
              ],
            ],
            [
              [
                [129.5424, -2.8],
                [130.4791, -3.0764],
                [130.8132, -3.5021],
                [130.8177, -3.7784],
                [129.8004, -3.3317],
                [129.1405, -3.3606],
                [128.8638, -3.2681],
                [128.5035, -3.4098],
                [128.1367, -3.1482],
                [128.0364, -3.3124],
                [127.8956, -3.2257],
                [128.1986, -2.8853],
                [129.0463, -2.8],
                [129.1405, -2.9566],
                [129.3707, -2.8],
                [129.5424, -2.8],
              ],
            ],
            [
              [
                [130.4641, -1.9867],
                [130.1849, -2.0825],
                [129.8044, -1.9045],
                [130.0452, -1.7297],
                [130.3317, -1.6684],
                [130.4641, -1.9867],
              ],
            ],
            [
              [
                [130.1121, -4.4137],
                [130.1308, -4.858],
                [130.0002, -4.7958],
                [129.8955, -4.5682],
                [130.0107, -4.3802],
                [130.1121, -4.4137],
              ],
            ],
            [
              [
                [129.9435, -1.2418],
                [129.8027, -1.3592],
                [129.5463, -1.3466],
                [129.5437, -1.2152],
                [129.8395, -1.0825],
                [129.9435, -1.2418],
              ],
            ],
            [
              [
                [129.9033, -7.8182],
                [129.8011, -8.1062],
                [129.5254, -7.8828],
                [129.6895, -7.741],
                [129.9033, -7.8182],
              ],
            ],
            [
              [
                [129.6687, -6.5658],
                [129.5318, -6.9486],
                [129.3441, -6.837],
                [129.3694, -6.6367],
                [129.6687, -6.5658],
              ],
            ],
            [
              [
                [128.1375, -0.5962],
                [128.0744, -0.6888],
                [127.6611, 0.0707],
                [127.6542, 0.252],
                [127.6356, 0.737],
                [128.3419, 1.4333],
                [128.6653, 1.5285],
                [128.6909, 1.2071],
                [128.3963, 0.7703],
                [128.5436, 0.4354],
                [128.0541, 0.3986],
                [128.0121, 0.252],
                [127.9602, 0.0707],
                [128.1375, -0.5962],
              ],
            ],
            [
              [
                [128.5643, 2.1554],
                [128.3598, 2.0596],
                [128.2212, 2.2568],
                [128.3573, 2.5017],
                [128.5774, 2.6362],
                [128.6812, 2.5017],
                [128.5643, 2.1554],
              ],
            ],
            [
              [
                [128.5983, -1.1836],
                [128.5427, -1.2665],
                [128.2216, -1.1392],
                [128.1674, -0.9419],
                [128.2852, -0.9159],
                [128.5983, -1.1836],
              ],
            ],
            [
              [
                [128.3978, -3.4399],
                [128.4126, -3.7222],
                [128.0286, -3.6294],
                [128.2244, -3.4254],
                [128.3978, -3.4399],
              ],
            ],
            [
              [
                [128.0776, -1.6743],
                [127.5978, -1.7293],
                [127.4354, -1.6659],
                [127.4054, -1.4761],
                [127.634, -1.3388],
                [127.9563, -1.4761],
                [128.0776, -1.6743],
              ],
            ],
            [
              [
                [127.9018, 1.1698],
                [127.5574, 0.8969],
                [127.423, 1.0583],
                [127.4152, 1.1698],
                [127.5525, 1.7282],
                [127.9256, 2.0919],
                [128.0291, 1.6494],
                [127.9018, 1.1698],
              ],
            ],
            [
              [
                [127.7813, -5.2153],
                [127.9353, -5.5008],
                [127.588, -5.4971],
                [127.5657, -5.2881],
                [127.7813, -5.2153],
              ],
            ],
            [
              [
                [127.5969, -0.6143],
                [127.8898, -0.7863],
                [127.7346, -0.8786],
                [127.7274, -0.8786],
                [127.6211, -0.7545],
                [127.4573, -0.816],
                [127.4715, -0.6446],
                [127.3218, -0.6749],
                [127.103, -0.4346],
                [127.1078, -0.2914],
                [127.5259, -0.3165],
                [127.688, -0.4747],
                [127.5969, -0.6143],
              ],
            ],
            [
              [
                [127.24, -3.4701],
                [126.821, -3.7999],
                [126.352, -3.7],
                [126.0326, -3.3329],
                [126.099, -3.1559],
                [126.352, -3.0964],
                [126.8752, -3.1076],
                [127.24, -3.4701],
              ],
            ],
            [
              [
                [126.9145, 4.02],
                [126.7869, 3.9151],
                [126.5624, 3.9606],
                [126.7362, 4.2006],
                [126.656, 4.3353],
                [126.7295, 4.5964],
                [126.8741, 4.5233],
                [126.9503, 4.244],
                [126.9145, 4.02],
              ],
            ],
            [
              [
                [126.8214, -6.7672],
                [126.5579, -6.7672],
                [126.4506, -6.5777],
                [126.794, -6.4962],
                [126.8214, -6.7672],
              ],
            ],
            [
              [
                [126.6625, 5.4401],
                [126.5285, 5.3064],
                [126.4378, 5.4526],
                [126.523, 5.7022],
                [126.6993, 5.775],
                [126.6625, 5.4401],
              ],
            ],
            [
              [
                [126.688, -7.7041],
                [126.4723, -7.9296],
                [126.352, -7.9226],
                [125.848, -7.8527],
                [125.9512, -7.7023],
                [126.352, -7.6753],
                [126.5769, -7.5815],
                [126.688, -7.7041],
              ],
            ],
            [
              [
                [126.048, -1.7592],
                [126.0326, -2.0564],
                [125.6222, -1.8309],
                [125.6782, -1.7749],
                [126.048, -1.7592],
              ],
            ],
            [
              [
                [125.6825, 3.4811],
                [125.522, 3.402],
                [125.4681, 3.4853],
                [125.5218, 3.6003],
                [125.6825, 3.4811],
              ],
            ],
            [
              [
                [125.5586, 3.6723],
                [125.5197, 3.6049],
                [125.4259, 3.6215],
                [125.3794, 3.6826],
                [125.4052, 3.7481],
                [125.5141, 3.7392],
                [125.5586, 3.6723],
              ],
            ],
            [
              [
                [125.1281, -1.8957],
                [124.4547, -1.9904],
                [124.3065, -1.766],
                [124.6221, -1.6523],
                [125.1635, -1.7209],
                [125.2646, -1.766],
                [125.1281, -1.8957],
              ],
            ],
            [
              [
                [124.9795, 1.1237],
                [124.2471, 0.4228],
                [123.6163, 0.3077],
                [120.6846, 0.4843],
                [120.2126, 0.252],
                [120.1255, 0.0707],
                [120.1728, -0.7484],
                [120.3237, -0.8786],
                [120.8602, -1.3421],
                [121.5654, -0.9473],
                [121.8579, -0.8786],
                [122.9771, -0.6159],
                [123.3408, -0.726],
                [123.3089, -0.8786],
                [122.7457, -1.0869],
                [121.7172, -2.0886],
                [122.4409, -3.6697],
                [123.0816, -4.1828],
                [122.9862, -5.3197],
                [122.7302, -5.5155],
                [122.4999, -5.3696],
                [122.4154, -4.9715],
                [122.6224, -4.5947],
                [121.9035, -4.7502],
                [121.6749, -4.6471],
                [120.7748, -2.7805],
                [120.3042, -3.2254],
                [120.2894, -5.2903],
                [119.7709, -5.5801],
                [119.5409, -5.4177],
                [119.5018, -3.8519],
                [118.9632, -3.3302],
                [118.8499, -2.9709],
                [119.563, -0.8786],
                [119.7402, -0.5736],
                [119.7744, 0.0707],
                [119.8606, 0.252],
                [120.0635, 0.6216],
                [120.5987, 0.8708],
                [120.9464, 1.2721],
                [123.7339, 0.8724],
                [125.0099, 1.7289],
                [125.1568, 1.5864],
                [124.9795, 1.1237],
              ],
            ],
            [
              [
                [125.1339, -8.2599],
                [124.5099, -8.4419],
                [124.3425, -8.3184],
                [124.6738, -8.1665],
                [125.0074, -8.1496],
                [125.1339, -8.2599],
              ],
            ],
            [
              [
                [125.0819, -9.4462],
                [124.4302, -10.1008],
                [123.7921, -10.3282],
                [123.4692, -10.2739],
                [123.7337, -9.6205],
                [124.045, -9.343],
                [124.3535, -9.3971],
                [124.4772, -9.175],
                [124.95, -8.9581],
                [125.1277, -9.0687],
                [125.0819, -9.4462],
              ],
            ],
            [
              [
                [124.2976, -8.475],
                [123.9794, -8.6549],
                [123.9515, -8.4237],
                [124.1625, -8.2623],
                [124.2976, -8.475],
              ],
            ],
            [
              [
                [123.9468, -2.1083],
                [123.6464, -2.2176],
                [123.6522, -1.9584],
                [123.8887, -1.8478],
                [123.9468, -2.1083],
              ],
            ],
            [
              [
                [123.6426, -1.7702],
                [123.5094, -1.7702],
                [123.3394, -1.6479],
                [123.5086, -1.4815],
                [123.7497, -1.5692],
                [123.6426, -1.7702],
              ],
            ],
            [
              [
                [123.7389, -8.3574],
                [123.4021, -8.5397],
                [123.0603, -8.3984],
                [123.0889, -8.2549],
                [123.713, -8.2549],
                [123.7389, -8.3574],
              ],
            ],
            [
              [
                [123.4912, -1.2722],
                [123.5135, -1.471],
                [123.0685, -1.3948],
                [122.8795, -1.6235],
                [122.7693, -1.471],
                [123.1149, -1.1623],
                [123.4912, -1.2722],
              ],
            ],
            [
              [
                [123.3186, -10.7526],
                [122.9218, -10.9127],
                [122.8445, -10.8665],
                [122.9062, -10.7337],
                [123.2907, -10.6091],
                [123.3186, -10.7526],
              ],
            ],
            [
              [
                [120.6213, -8.2574],
                [121.4625, -8.5841],
                [122.3064, -8.6088],
                [122.6035, -8.5187],
                [122.5602, -8.6907],
                [120.9999, -8.9618],
                [120.5599, -8.8153],
                [119.9016, -8.8297],
                [119.6931, -8.6439],
                [119.4114, -8.646],
                [119.4001, -8.5008],
                [119.7537, -8.5045],
                [120.413, -8.2574],
                [120.6213, -8.2574],
              ],
            ],
            [
              [
                [122.1654, -0.2968],
                [122.1299, -0.4312],
                [121.9389, -0.355],
                [122.08, -0.2127],
                [122.1654, -0.2968],
              ],
            ],
            [
              [
                [122.0723, -10.524],
                [121.8009, -10.7016],
                [121.6997, -10.5776],
                [121.8244, -10.3975],
                [122.0739, -10.3908],
                [122.0723, -10.524],
              ],
            ],
            [
              [
                [122.067, -5.1713],
                [122.0544, -5.454],
                [121.735, -5.3514],
                [121.8387, -5.1335],
                [122.067, -5.1713],
              ],
            ],
            [
              [
                [121.9493, -0.4103],
                [121.8823, -0.5427],
                [121.7719, -0.4362],
                [121.8934, -0.3195],
                [121.9493, -0.4103],
              ],
            ],
            [
              [
                [120.6492, -10.2134],
                [120.2576, -10.2626],
                [119.711, -9.8291],
                [118.9571, -9.5824],
                [119.1419, -9.4038],
                [119.5751, -9.3551],
                [119.978, -9.3527],
                [120.2429, -9.5824],
                [120.7457, -9.9622],
                [120.6492, -10.2134],
              ],
            ],
            [
              [
                [120.6771, -6.0935],
                [120.4509, -6.2451],
                [120.3384, -6.0379],
                [120.5818, -5.8378],
                [120.6771, -6.0935],
              ],
            ],
            [
              [
                [118.0921, -8.1333],
                [118.3212, -8.3434],
                [118.5009, -8.2603],
                [118.9646, -8.3627],
                [119.1702, -8.6774],
                [118.8012, -8.855],
                [118.195, -8.8447],
                [116.9965, -9.0773],
                [116.783, -8.9597],
                [116.6906, -8.6065],
                [116.4604, -8.8606],
                [116.0999, -8.8871],
                [116.0517, -8.445],
                [116.3764, -8.2133],
                [116.5477, -8.2603],
                [116.8056, -8.4685],
                [117.521, -8.4166],
                [118.109, -8.6384],
                [118.1576, -8.543],
                [117.7282, -8.2603],
                [117.8888, -8.0895],
                [118.0921, -8.1333],
              ],
            ],
            [
              [
                [109.643, 2.076],
                [109.7189, 1.6007],
                [110.4947, 0.905],
                [111.7875, 0.9963],
                [112.1786, 1.2951],
                [112.5607, 1.5663],
                [113.6416, 1.2735],
                [114.5414, 1.4939],
                [114.8213, 1.9271],
                [114.8113, 2.2131],
                [115.1387, 2.4688],
                [115.1395, 2.8234],
                [115.4801, 3.1051],
                [115.8436, 4.3193],
                [117.0734, 4.3762],
                [117.7554, 4.1668],
                [117.8959, 4.1667],
                [117.5481, 3.513],
                [117.5543, 3.1743],
                [118.0559, 1.7823],
                [118.7863, 1.0052],
                [117.774, 0.6638],
                [117.5885, 0.252],
                [117.5129, 0.0841],
                [117.4502, -0.7924],
                [117.365, -0.8786],
                [116.4885, -1.766],
                [116.5064, -2.2733],
                [116.5119, -2.4285],
                [116.2024, -3.7304],
                [114.8134, -4.0478],
                [114.3456, -3.5118],
                [113.1529, -3.12],
                [112.115, -3.4116],
                [111.6289, -3.0637],
                [110.4658, -2.9451],
                [110.1816, -2.2733],
                [109.967, -1.766],
                [109.9643, -1.3814],
                [109.4272, -0.9967],
                [109.3906, -0.8786],
                [109.0401, 0.252],
                [108.8866, 0.7473],
                [109.042, 1.3967],
                [109.643, 2.076],
              ],
            ],
            [
              [
                [118.7224, 2.3269],
                [118.76, 2.0697],
                [118.5013, 2.125],
                [118.5106, 2.4988],
                [118.7224, 2.3269],
              ],
            ],
            [
              [
                [107.0461, -5.9273],
                [108.3472, -6.322],
                [108.7056, -6.7783],
                [109.8693, -6.9108],
                [110.155, -6.8716],
                [110.4804, -6.9262],
                [110.6755, -6.5445],
                [110.9164, -6.4525],
                [111.2071, -6.6551],
                [112.5963, -6.9262],
                [112.7182, -7.0419],
                [113.002, -6.8819],
                [113.911, -6.8646],
                [113.9825, -6.9847],
                [113.5864, -7.2048],
                [112.8594, -7.2388],
                [112.7916, -7.506],
                [113.2628, -7.7514],
                [114.1108, -7.6683],
                [114.6297, -8.1202],
                [115.4692, -8.1764],
                [115.6441, -8.4174],
                [115.4692, -8.5567],
                [115.1635, -8.665],
                [114.4693, -8.2485],
                [114.3798, -8.2485],
                [114.482, -8.7189],
                [113.2125, -8.3188],
                [112.627, -8.4254],
                [111.1012, -8.2485],
                [110.6176, -8.1643],
                [109.8693, -7.8384],
                [108.2266, -7.784],
                [107.0461, -7.4491],
                [106.4949, -7.3635],
                [106.4498, -6.9944],
                [105.5433, -6.7661],
                [105.9583, -5.9881],
                [106.8785, -6.0856],
                [107.0461, -5.9273],
              ],
            ],
            [
              [
                [108.292, 3.6701],
                [108.132, 3.6365],
                [107.9074, 3.9065],
                [108.2152, 4.2172],
                [108.3878, 3.9614],
                [108.292, 3.6701],
              ],
            ],
            [
              [
                [108.2617, -2.7948],
                [108.1126, -3.1765],
                [107.612, -3.1134],
                [107.6006, -2.7948],
                [107.6648, -2.5979],
                [107.8068, -2.549],
                [108.2617, -2.7948],
              ],
            ],
            [
              [
                [107.648, 0.9447],
                [107.411, 0.8335],
                [107.2667, 1.0292],
                [107.375, 1.1505],
                [107.6441, 1.0482],
                [107.648, 0.9447],
              ],
            ],
            [
              [
                [106.3714, -2.2733],
                [106.582, -2.9165],
                [106.0495, -2.7466],
                [105.7373, -2.2733],
                [105.3193, -1.9868],
                [105.414, -1.766],
                [105.6093, -1.5481],
                [105.9736, -1.6191],
                [106.0339, -1.766],
                [106.3714, -2.2733],
              ],
            ],
            [
              [
                [106.5122, 3.1842],
                [106.4163, 2.9895],
                [106.1573, 3.3387],
                [106.3426, 3.4309],
                [106.5122, 3.1842],
              ],
            ],
            [
              [
                [105.9748, -3.1274],
                [105.6646, -5.7527],
                [104.7093, -5.5988],
                [104.383, -5.7113],
                [102.3591, -3.991],
                [101.0404, -2.4721],
                [100.9427, -2.2733],
                [100.6934, -1.766],
                [100.2783, -0.8786],
                [99.1631, 0.4309],
                [98.6906, 1.6615],
                [97.8741, 2.2957],
                [96.9479, 3.5275],
                [95.5685, 4.7281],
                [95.3465, 5.1664],
                [95.4219, 5.428],
                [97.5344, 5.0736],
                [98.4114, 4.0921],
                [99.6667, 3.1949],
                [100.4713, 2.3804],
                [101.5518, 2.0194],
                [102.6702, 1.1256],
                [103.4741, 0.8441],
                [103.7046, 0.0964],
                [103.5984, -0.6624],
                [103.8633, -0.8786],
                [104.3262, -1.1385],
                [104.6592, -1.766],
                [104.9251, -2.213],
                [105.0451, -2.2733],
                [105.5831, -2.5431],
                [105.9748, -3.1274],
              ],
            ],
            [
              [
                [104.7347, -0.1246],
                [104.4905, -0.5724],
                [104.2953, -0.5163],
                [104.4937, -0.1329],
                [104.3405, 0.1632],
                [104.4524, 0.4518],
                [104.7347, -0.1246],
              ],
            ],
            [
              [
                [104.6311, 1.1455],
                [104.6594, 0.8854],
                [104.4013, 0.9806],
                [104.1714, 0.8854],
                [103.7964, 0.8393],
                [103.7358, 0.8982],
                [103.8862, 1.0958],
                [104.6311, 1.1455],
              ],
            ],
            [
              [
                [102.4806, -5.3315],
                [102.4298, -5.5778],
                [102.2056, -5.5632],
                [102.0971, -5.3299],
                [102.4806, -5.3315],
              ],
            ],
            [
              [
                [100.5247, -3.1488],
                [100.4948, -3.3303],
                [100.3367, -3.2822],
                [100.1827, -2.8695],
                [99.9877, -2.755],
                [100.1404, -2.6254],
                [100.5247, -3.1488],
              ],
            ],
            [
              [
                [99.8006, -2.3453],
                [99.6017, -2.3663],
                [99.4535, -2.1449],
                [99.6201, -1.9584],
                [99.8006, -2.3453],
              ],
            ],
            [
              [
                [99.2935, -1.7403],
                [99.0749, -1.7677],
                [98.7771, -1.5369],
                [98.6554, -1.0321],
                [98.8921, -0.9809],
                [99.2935, -1.7403],
              ],
            ],
            [
              [
                [98.5559, -0.3306],
                [98.5307, -0.6294],
                [98.2976, -0.5521],
                [98.4386, -0.1462],
                [98.5559, -0.3306],
              ],
            ],
            [
              [
                [97.8792, 0.9705],
                [97.7805, 0.6049],
                [97.2774, 1.202],
                [97.2224, 1.3677],
                [97.3613, 1.4891],
                [97.6662, 1.202],
                [97.8792, 0.9705],
              ],
            ],
            [
              [
                [96.4661, 2.4402],
                [96.2482, 2.3423],
                [96.0973, 2.5642],
                [95.7378, 2.6674],
                [95.695, 2.8271],
                [96.0973, 2.7676],
                [96.4661, 2.4402],
              ],
            ],
          ],
        },
        properties: { name: "Indonesia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-13.9795, 65.5766],
              [-13.7583, 65.2412],
              [-13.955, 64.8599],
              [-14.991, 64.2925],
              [-18.2325, 63.4908],
              [-20.1327, 63.5482],
              [-21.9677, 63.883],
              [-22.1255, 64.0406],
              [-21.7569, 64.3256],
              [-21.37, 64.3803],
              [-22.1039, 64.4324],
              [-22.9771, 64.8024],
              [-22.5479, 65.4471],
              [-23.6334, 65.4704],
              [-23.9554, 65.6835],
              [-22.7011, 66.2838],
              [-21.8994, 66.1389],
              [-20.973, 65.5252],
              [-20.0898, 65.9505],
              [-17.4718, 66.0604],
              [-16.0154, 66.4212],
              [-14.8549, 66.0685],
              [-13.9795, 65.5766],
            ],
          ],
        },
        properties: { name: "Iceland" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [96.722, -12.1294],
              [96.7655, -11.9425],
              [96.91, -11.9769],
              [97.0362, -12.2452],
              [96.7881, -12.3034],
              [96.722, -12.1294],
            ],
          ],
        },
        properties: { name: "Cocos (Keeling) Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-56.5765, 50.0328],
                [-56.514, 49.9506],
                [-56.1747, 50.0772],
                [-55.486, 49.4964],
                [-54.8391, 49.5075],
                [-54.3228, 49.7405],
                [-53.5861, 49.2583],
                [-53.7108, 48.9673],
                [-53.6122, 48.6333],
                [-53.2101, 48.4958],
                [-53.6108, 47.8203],
                [-53.548, 47.7414],
                [-53.2551, 47.8352],
                [-52.7355, 47.5814],
                [-53.1183, 46.7567],
                [-54.0635, 46.893],
                [-54.027, 47.3325],
                [-54.1678, 47.4615],
                [-54.5468, 47.4398],
                [-55.5303, 46.9241],
                [-55.7128, 47.0319],
                [-55.2853, 47.3492],
                [-55.2401, 47.5781],
                [-56.0516, 47.709],
                [-58.841, 47.6074],
                [-59.2959, 47.7606],
                [-58.67, 48.4096],
                [-57.1875, 50.841],
                [-56.4434, 51.4306],
                [-55.8965, 51.5913],
                [-55.514, 51.4954],
                [-56.5765, 50.0328],
              ],
            ],
            [
              [
                [-67.2702, 45.1886],
                [-67.7647, 45.7142],
                [-67.7815, 46.0459],
                [-67.8174, 47.0362],
                [-68.3747, 47.349],
                [-68.8921, 47.206],
                [-68.9977, 47.3552],
                [-69.2777, 47.3844],
                [-69.8084, 46.8614],
                [-70.3752, 45.8014],
                [-70.8839, 45.369],
                [-71.7863, 45.0078],
                [-74.9771, 44.9407],
                [-76.011, 44.2472],
                [-76.8745, 43.668],
                [-78.7616, 43.5853],
                [-79.0575, 43.4429],
                [-79.0567, 43.3347],
                [-79.0466, 42.8794],
                [-79.241, 42.7271],
                [-82.7186, 41.7827],
                [-82.9607, 42.0964],
                [-82.3027, 43.3347],
                [-82.1811, 43.6656],
                [-82.3354, 44.2472],
                [-82.6251, 45.3393],
                [-85.0831, 46.9699],
                [-88.0434, 48.1758],
                [-89.435, 47.9857],
                [-89.6593, 48.0179],
                [-90.0133, 48.0688],
                [-90.7911, 48.1805],
                [-91.0362, 48.1534],
                [-91.5593, 48.1211],
                [-92.9202, 48.5702],
                [-94.2745, 48.6951],
                [-95.0687, 49.2416],
                [-95.824, 49.0008],
                [-122.8092, 49.0215],
                [-123.1007, 49.0215],
                [-123.4626, 49.4002],
                [-124.6564, 49.7725],
                [-124.5411, 49.4832],
                [-123.4779, 48.757],
                [-123.6216, 48.4456],
                [-124.1405, 48.4645],
                [-125.2315, 48.8222],
                [-127.8586, 50.2265],
                [-128.2026, 50.678],
                [-127.7437, 50.9841],
                [-127.8236, 51.3989],
                [-128.4085, 52.2116],
                [-129.2663, 52.7032],
                [-129.4957, 53.1624],
                [-130.4093, 53.5743],
                [-130.534, 54.8067],
                [-130.4276, 54.8695],
                [-130.4192, 54.8695],
                [-130.2438, 54.8695],
                [-130.1553, 54.8695],
                [-130.1037, 54.8695],
                [-130.0749, 54.8695],
                [-130.0719, 54.874],
                [-130.0594, 54.887],
                [-130.0521, 54.9002],
                [-130.0493, 54.903],
                [-130.0412, 54.9186],
                [-130.0387, 54.9364],
                [-130.0422, 54.9541],
                [-130.0514, 54.9696],
                [-130.0652, 54.9812],
                [-130.082, 54.9877],
                [-130.1001, 54.9883],
                [-130.1071, 54.9873],
                [-130.1148, 54.9937],
                [-130.1311, 55.0209],
                [-130.1376, 55.0491],
                [-130.1517, 55.0596],
                [-130.1617, 55.0721],
                [-130.164, 55.0812],
                [-130.1602, 55.0912],
                [-130.1386, 55.1183],
                [-130.1266, 55.1493],
                [-130.1075, 55.1667],
                [-130.0873, 55.1911],
                [-130.034, 55.2271],
                [-130.0081, 55.2493],
                [-129.9558, 55.2807],
                [-129.9469, 55.2902],
                [-129.9492, 55.2946],
                [-129.9605, 55.302],
                [-129.9714, 55.3182],
                [-129.9943, 55.3306],
                [-130.0015, 55.3372],
                [-130.0049, 55.3616],
                [-130.0151, 55.3836],
                [-130.0163, 55.4122],
                [-130.0118, 55.424],
                [-130.0193, 55.4354],
                [-130.0244, 55.4579],
                [-130.0678, 55.4961],
                [-130.0747, 55.5061],
                [-130.0787, 55.5232],
                [-130.0889, 55.5331],
                [-130.0984, 55.5548],
                [-130.1088, 55.5654],
                [-130.1095, 55.5806],
                [-130.1042, 55.6162],
                [-130.0946, 55.6285],
                [-130.0896, 55.6448],
                [-130.0912, 55.6664],
                [-130.0875, 55.6831],
                [-130.1046, 55.6967],
                [-130.1184, 55.703],
                [-130.1287, 55.714],
                [-130.1366, 55.7477],
                [-130.1308, 55.7673],
                [-130.1131, 55.7864],
                [-130.1072, 55.7963],
                [-130.0699, 55.8189],
                [-130.032, 55.8624],
                [-130.0185, 55.8734],
                [-130.0138, 55.8806],
                [-130.0036, 55.887],
                [-129.9901, 55.9158],
                [-129.994, 55.9205],
                [-130.0002, 55.9222],
                [-130.0061, 55.9213],
                [-130.0102, 55.9171],
                [-131.7547, 56.6577],
                [-133.4872, 58.471],
                [-134.5386, 59.1177],
                [-135.0304, 59.5094],
                [-135.4947, 59.7832],
                [-136.1363, 59.635],
                [-136.2508, 59.5094],
                [-136.5724, 59.1992],
                [-137.0381, 59.0793],
                [-137.4221, 58.9656],
                [-138.0988, 59.5094],
                [-139.0965, 60.0754],
                [-139.1645, 60.3358],
                [-139.9046, 60.2219],
                [-140.8352, 60.2915],
                [-141, 60.5156],
                [-140.999, 64.4949],
                [-140.999, 64.7661],
                [-140.9942, 68.2307],
                [-140.9923, 69.6172],
                [-139.3778, 69.5422],
                [-137.9611, 69.1467],
                [-136.6084, 68.9884],
                [-134.9684, 69.4984],
                [-133.1407, 69.4792],
                [-130.3626, 70.0533],
                [-129.6589, 69.7592],
                [-129.2014, 69.7561],
                [-127.811, 70.2888],
                [-125.7819, 69.508],
                [-124.7253, 69.8874],
                [-123.9277, 69.4961],
                [-122.7378, 69.7326],
                [-121.5173, 69.7247],
                [-117.5206, 69.0146],
                [-114.948, 68.755],
                [-114.4288, 68.48],
                [-114.5186, 68.0542],
                [-114.095, 67.8625],
                [-111.8357, 67.7432],
                [-109.4388, 67.9373],
                [-108.6718, 67.6161],
                [-108.1858, 67.871],
                [-108.3965, 68.2454],
                [-108.2937, 68.4449],
                [-106.4051, 68.8372],
                [-105.5947, 68.7064],
                [-103.9784, 68.0309],
                [-102.4075, 67.8603],
                [-99.1508, 67.9024],
                [-98.5005, 68.5568],
                [-98.7368, 69.1683],
                [-97.5263, 69.7122],
                [-96.4408, 69.4904],
                [-95.9272, 69.1323],
                [-96.4467, 68.355],
                [-95.696, 67.5406],
                [-95.2905, 67.9147],
                [-94.4406, 68.2865],
                [-94.0012, 68.7589],
                [-94.2568, 69.3459],
                [-95.5422, 69.5189],
                [-96.2832, 70.3142],
                [-96.2689, 71.0031],
                [-95.6691, 71.4902],
                [-95.3822, 71.9751],
                [-95.398, 73.4821],
                [-93.2258, 74.1045],
                [-91.1484, 73.954],
                [-90.7357, 73.7537],
                [-92.0829, 72.8431],
                [-93.5219, 72.6496],
                [-94.0476, 71.8608],
                [-93.129, 71.3532],
                [-92.1471, 70.5143],
                [-91.9321, 69.6981],
                [-90.0133, 69.1615],
                [-88.4412, 68.8645],
                [-88.138, 68.5301],
                [-87.9399, 67.755],
                [-87.0639, 67.4663],
                [-86.4941, 68.0551],
                [-85.3995, 68.7259],
                [-85.1384, 69.521],
                [-84.7889, 69.8181],
                [-86.4354, 70.2708],
                [-88.1811, 70.4404],
                [-88.6872, 70.6769],
                [-88.8827, 71.2027],
                [-89.7216, 71.6464],
                [-89.7758, 72.1788],
                [-89.5814, 72.4993],
                [-87.9973, 73.5359],
                [-86.7641, 73.7605],
                [-85.7874, 73.6732],
                [-85.7165, 73.5005],
                [-86.1608, 73.0173],
                [-86.3245, 72.4901],
                [-86.0552, 72.0023],
                [-85.5669, 71.7875],
                [-85.3559, 72.7713],
                [-84.4327, 73.3551],
                [-82.9486, 73.6203],
                [-81.7693, 73.5421],
                [-80.831, 73.0294],
                [-80.2391, 72.4191],
                [-79.8277, 72.342],
                [-78.864, 72.4324],
                [-78.6286, 72.5918],
                [-79.9532, 73.0126],
                [-80.3313, 73.2731],
                [-80.3662, 73.5016],
                [-78.1027, 73.5641],
                [-76.8959, 73.2283],
                [-76.1224, 72.6543],
                [-74.8499, 72.1739],
                [-74.4059, 71.626],
                [-71.9445, 71.4616],
                [-70.4937, 70.8428],
                [-69.008, 70.5967],
                [-67.9682, 70.2006],
                [-67.5952, 69.9388],
                [-67.467, 69.5059],
                [-67.8496, 68.9237],
                [-67.7593, 68.7263],
                [-65.9779, 68.069],
                [-64.7092, 67.8345],
                [-63.1422, 67.1183],
                [-62.0934, 66.8906],
                [-61.8229, 66.6387],
                [-63.8759, 65.2494],
                [-64.7196, 65.3706],
                [-66.2115, 66.069],
                [-66.9466, 65.9731],
                [-67.0573, 65.662],
                [-66.8539, 65.2661],
                [-65.5592, 64.582],
                [-64.5783, 63.6743],
                [-65.0837, 62.7371],
                [-67.3908, 63.1229],
                [-67.2886, 62.8874],
                [-66.354, 62.2975],
                [-66.2703, 61.9785],
                [-70.4062, 62.6748],
                [-72.0739, 63.574],
                [-74.0855, 64.2396],
                [-76.5583, 64.2738],
                [-77.6771, 64.5845],
                [-77.6977, 64.9509],
                [-77.2058, 65.27],
                [-75.4539, 65.3396],
                [-74.3871, 65.6222],
                [-73.8732, 66.3804],
                [-72.7415, 67.4922],
                [-73.4276, 68.2274],
                [-73.7263, 68.2726],
                [-74.3337, 68.0068],
                [-75.1629, 68.6727],
                [-76.1914, 68.9253],
                [-76.802, 69.5982],
                [-77.9055, 70.1187],
                [-78.6401, 70.2417],
                [-79.8116, 69.731],
                [-80.7852, 69.9102],
                [-82.0054, 69.6635],
                [-81.5547, 68.9269],
                [-81.8718, 68.2421],
                [-81.6273, 67.3838],
                [-81.9579, 66.9476],
                [-83.5704, 66.4754],
                [-84.0305, 66.0376],
                [-85.2198, 66.3715],
                [-85.8537, 66.353],
                [-88.0746, 64.3328],
                [-90.0133, 63.8897],
                [-90.6491, 63.5232],
                [-91.0362, 63.0553],
                [-92.0439, 62.7129],
                [-92.6448, 62.2673],
                [-92.8154, 61.8699],
                [-93.8632, 61.4412],
                [-94.526, 60.5553],
                [-94.7573, 59.8097],
                [-94.5633, 58.9588],
                [-93.2035, 58.5386],
                [-92.3943, 57.2082],
                [-91.0362, 57.2002],
                [-90.0133, 56.9973],
                [-88.9249, 56.7678],
                [-87.2257, 55.9936],
                [-85.4401, 55.4453],
                [-82.7271, 54.9859],
                [-81.9936, 53.1694],
                [-81.1298, 53.1518],
                [-80.7269, 52.7814],
                [-81.7804, 52.9563],
                [-81.8014, 52.7503],
                [-80.6083, 51.6691],
                [-79.7291, 51.4105],
                [-79.0711, 51.6588],
                [-78.8132, 52.1842],
                [-79.3486, 54.6638],
                [-77.8159, 55.3825],
                [-76.7132, 56.3715],
                [-77.1515, 57.7435],
                [-78.3083, 58.7537],
                [-77.6812, 59.8697],
                [-78.0104, 61.2482],
                [-77.9943, 61.9607],
                [-77.7448, 62.3235],
                [-76.977, 62.4467],
                [-75.481, 62.2637],
                [-73.6147, 62.3018],
                [-72.2227, 61.8358],
                [-71.162, 61.2419],
                [-69.8858, 60.9316],
                [-69.5644, 60.5604],
                [-69.2923, 59.4739],
                [-67.7417, 58.598],
                [-66.6124, 58.7119],
                [-65.2886, 59.7983],
                [-64.3842, 59.9948],
                [-61.9721, 57.7991],
                [-60.4872, 55.8211],
                [-59.4916, 55.1841],
                [-57.7432, 54.6536],
                [-57.1405, 54.0534],
                [-56.191, 53.481],
                [-55.9106, 52.4896],
                [-56.8548, 51.6009],
                [-58.3277, 51.1443],
                [-60.1202, 50.3193],
                [-66.2184, 50.11],
                [-68.3068, 49.082],
                [-68.9977, 48.2561],
                [-66.6534, 49.0046],
                [-65.1852, 49.0974],
                [-64.7853, 48.9734],
                [-64.6137, 48.5881],
                [-65.3673, 47.8865],
                [-64.9618, 47.5552],
                [-64.6095, 46.8288],
                [-64.4036, 46.6956],
                [-64.0601, 46.9239],
                [-63.4098, 46.4767],
                [-62.2794, 46.4139],
                [-62.4591, 46.0575],
                [-62.7009, 45.9843],
                [-64.3195, 46.5303],
                [-64.0986, 46.183],
                [-63.4742, 45.9214],
                [-62.0915, 45.8108],
                [-60.7162, 46.547],
                [-60.3791, 46.2387],
                [-60.4891, 45.9772],
                [-61.4726, 45.2013],
                [-65.4186, 43.69],
                [-65.8272, 43.8294],
                [-65.891, 44.2139],
                [-65.0052, 45.3319],
                [-67.2702, 45.1886],
              ],
            ],
            [
              [
                [-58.863, 48.5019],
                [-59.0496, 48.4247],
                [-59.2231, 48.5084],
                [-59.1352, 48.6998],
                [-58.904, 48.7033],
                [-58.863, 48.5019],
              ],
            ],
            [
              [
                [-60.1621, 56.3627],
                [-60.3062, 56.1331],
                [-60.6857, 56.2554],
                [-60.6032, 56.3497],
                [-60.1621, 56.3627],
              ],
            ],
            [
              [
                [-61.7247, 49.1508],
                [-62.2186, 49.0682],
                [-63.3233, 49.3232],
                [-64.2525, 49.8069],
                [-64.2813, 49.849],
                [-64.1519, 49.9417],
                [-63.3233, 49.8129],
                [-61.9415, 49.3513],
                [-61.7247, 49.1508],
              ],
            ],
            [
              [
                [-64.634, 82.8393],
                [-62.8285, 82.2455],
                [-63.5304, 81.8894],
                [-65.809, 81.6127],
                [-66.3801, 81.1774],
                [-71.1978, 79.8647],
                [-76.8768, 79.3447],
                [-76.969, 79.2079],
                [-76.7239, 79.0734],
                [-75.4125, 79.0071],
                [-75.2548, 78.6978],
                [-76.0256, 78.1013],
                [-78.0005, 77.7695],
                [-78.7169, 77.2111],
                [-78.5193, 76.7064],
                [-79.9227, 76.3434],
                [-82.0678, 76.5072],
                [-85.4058, 76.3658],
                [-88.8467, 76.573],
                [-89.0176, 76.6748],
                [-88.9425, 76.8133],
                [-87.8415, 77.2064],
                [-87.4276, 77.7323],
                [-86.6853, 77.768],
                [-85.3855, 77.5307],
                [-85.2001, 77.821],
                [-86.7618, 78.2927],
                [-87.3589, 78.6476],
                [-88.8949, 78.3188],
                [-90.0133, 78.2807],
                [-91.0362, 78.2563],
                [-92.2878, 78.3216],
                [-93.4558, 78.8088],
                [-92.9526, 79.2081],
                [-92.9775, 79.3699],
                [-95.1843, 79.3743],
                [-96.0661, 79.946],
                [-95.6138, 80.6963],
                [-93.6758, 81.2499],
                [-92.3157, 81.214],
                [-91.0362, 80.8467],
                [-90.0133, 80.5777],
                [-88.8305, 80.2772],
                [-87.794, 80.2427],
                [-86.8415, 79.865],
                [-85.5152, 80.2641],
                [-82.3146, 79.9506],
                [-81.3021, 80.5144],
                [-85.3214, 80.5719],
                [-86.4412, 80.9213],
                [-87.9007, 80.7733],
                [-89.247, 80.9846],
                [-90.0133, 81.441],
                [-90.4005, 81.687],
                [-90.0133, 81.8375],
                [-87.2029, 82.0392],
                [-85.4819, 82.401],
                [-83.2212, 82.3593],
                [-81.2588, 82.7922],
                [-75.554, 83.0968],
                [-64.634, 82.8393],
              ],
            ],
            [
              [
                [-64.4904, 60.5822],
                [-64.6757, 60.4546],
                [-64.9297, 60.4606],
                [-64.7762, 60.6936],
                [-64.5538, 60.7051],
                [-64.4904, 60.5822],
              ],
            ],
            [
              [
                [-64.6824, 61.4667],
                [-64.8241, 61.3185],
                [-65.4341, 61.6079],
                [-65.2985, 61.8063],
                [-65.096, 61.9197],
                [-64.7749, 61.8063],
                [-64.6824, 61.4667],
              ],
            ],
            [
              [
                [-68.0029, 60.2987],
                [-68.3889, 60.2024],
                [-68.3889, 60.3594],
                [-68.1652, 60.5739],
                [-67.8776, 60.4617],
                [-68.0029, 60.2987],
              ],
            ],
            [
              [
                [-75.8326, 67.2588],
                [-76.7658, 67.2192],
                [-77.1596, 67.3849],
                [-77.2674, 67.7328],
                [-77.1596, 67.8934],
                [-76.7331, 68.2183],
                [-76.3889, 68.3091],
                [-75.5942, 68.3091],
                [-75.0948, 68.1834],
                [-75.0406, 67.6097],
                [-75.8326, 67.2588],
              ],
            ],
            [
              [
                [-76.638, 63.4113],
                [-76.9357, 63.3801],
                [-77.251, 63.5979],
                [-77.1901, 63.6594],
                [-76.7518, 63.6104],
                [-76.638, 63.4113],
              ],
            ],
            [
              [
                [-76.9385, 69.174],
                [-77.1576, 69.1314],
                [-77.3486, 69.2686],
                [-77.3131, 69.4079],
                [-77.1576, 69.4467],
                [-76.7579, 69.3554],
                [-76.9385, 69.174],
              ],
            ],
            [
              [
                [-77.4909, 56.4498],
                [-77.6975, 56.1912],
                [-77.9406, 56.3449],
                [-77.7018, 56.5131],
                [-77.4909, 56.4498],
              ],
            ],
            [
              [
                [-77.5025, 64.042],
                [-77.5602, 63.8396],
                [-77.913, 63.9513],
                [-77.6702, 64.1692],
                [-77.5025, 64.042],
              ],
            ],
            [
              [
                [-77.5392, 63.2428],
                [-77.915, 63.1115],
                [-78.3512, 63.3036],
                [-78.4372, 63.3997],
                [-78.3072, 63.4675],
                [-77.7613, 63.4387],
                [-77.5392, 63.2428],
              ],
            ],
            [
              [
                [-78.0398, 69.6028],
                [-78.5557, 69.4952],
                [-78.646, 69.5597],
                [-78.3636, 69.699],
                [-77.997, 69.7162],
                [-78.0398, 69.6028],
              ],
            ],
            [
              [
                [-78.8433, 68.9103],
                [-79.2693, 68.837],
                [-79.3917, 68.8927],
                [-79.3095, 68.9986],
                [-78.5376, 69.3941],
                [-78.3191, 69.2421],
                [-78.8433, 68.9103],
              ],
            ],
            [
              [
                [-78.5227, 68.6605],
                [-78.6028, 68.4607],
                [-78.936, 68.4827],
                [-78.8512, 68.7053],
                [-78.5227, 68.6605],
              ],
            ],
            [
              [
                [-78.6262, 56.8871],
                [-78.7017, 56.6116],
                [-78.9507, 56.7162],
                [-78.9553, 56.9256],
                [-78.6262, 56.8871],
              ],
            ],
            [
              [
                [-79.0735, 56.4461],
                [-78.708, 56.3891],
                [-78.664, 56.1747],
                [-78.7927, 56.0237],
                [-79.0959, 56.0516],
                [-79.2156, 56.1747],
                [-79.3997, 55.9255],
                [-79.6946, 55.8329],
                [-80.0602, 55.8365],
                [-80.0824, 56.1832],
                [-79.8088, 56.0661],
                [-79.8088, 56.3689],
                [-79.5522, 56.5141],
                [-79.3449, 56.4011],
                [-79.2039, 56.6387],
                [-79.0735, 56.4461],
              ],
            ],
            [
              [
                [-78.8122, 68.2803],
                [-79.0089, 68.1435],
                [-79.2415, 68.227],
                [-79.1799, 68.3905],
                [-78.9156, 68.4049],
                [-78.8122, 68.2803],
              ],
            ],
            [
              [
                [-78.9366, 75.9665],
                [-79.2229, 75.8124],
                [-79.3987, 75.9092],
                [-79.1078, 76.141],
                [-78.9366, 75.9665],
              ],
            ],
            [
              [
                [-79.134, 51.9211],
                [-79.4743, 51.8714],
                [-79.5693, 51.9558],
                [-79.1708, 52.181],
                [-79.134, 51.9211],
              ],
            ],
            [
              [
                [-79.4762, 61.8566],
                [-79.6792, 61.6126],
                [-80.1522, 61.8203],
                [-80.1993, 62.152],
                [-79.9465, 62.3472],
                [-79.6762, 62.3946],
                [-79.4022, 62.3282],
                [-79.2964, 62.152],
                [-79.3329, 62.0291],
                [-79.4269, 61.9648],
                [-79.4762, 61.8566],
              ],
            ],
            [
              [
                [-94.833, 76.9745],
                [-90.7953, 76.5602],
                [-90.0133, 76.6288],
                [-89.7865, 76.3309],
                [-90.0133, 76.0853],
                [-90.0133, 75.9908],
                [-89.6593, 75.8131],
                [-89.435, 75.7129],
                [-88.9084, 75.4624],
                [-88.6472, 75.6199],
                [-86.4242, 75.4013],
                [-84.0155, 75.7922],
                [-82.6153, 75.7929],
                [-79.8986, 75.5356],
                [-79.6258, 75.3944],
                [-79.4489, 74.9901],
                [-80.602, 74.5878],
                [-82.5764, 74.5454],
                [-83.2128, 74.7429],
                [-84.7803, 74.5058],
                [-89.435, 74.6262],
                [-89.6593, 74.6402],
                [-90.0133, 74.6656],
                [-91.8478, 74.9562],
                [-92.883, 76.0794],
                [-95.0143, 76.3256],
                [-95.6133, 76.7282],
                [-94.833, 76.9745],
              ],
            ],
            [
              [
                [-79.8685, 53.2163],
                [-80.1555, 53.1964],
                [-80.1734, 53.3762],
                [-80.0095, 53.4618],
                [-79.8052, 53.3969],
                [-79.8685, 53.2163],
              ],
            ],
            [
              [
                [-79.8494, 59.9737],
                [-79.8394, 59.7016],
                [-80.1243, 59.7139],
                [-80.3602, 59.5323],
                [-80.4539, 59.6548],
                [-80.3618, 59.8907],
                [-80.1771, 59.8368],
                [-79.9893, 60.0204],
                [-79.8494, 59.9737],
              ],
            ],
            [
              [
                [-85.1864, 65.5481],
                [-84.9418, 65.3192],
                [-84.4343, 65.3741],
                [-82.246, 64.6579],
                [-81.8456, 64.5007],
                [-81.5201, 64.1043],
                [-80.8286, 64.0271],
                [-80.5021, 63.7557],
                [-81.1424, 63.4783],
                [-83.344, 64.0817],
                [-84.8175, 63.2434],
                [-85.3688, 63.141],
                [-85.7767, 63.6411],
                [-86.7482, 63.6854],
                [-86.7996, 63.7393],
                [-86.3666, 64.0987],
                [-86.3026, 64.6579],
                [-85.9173, 65.7099],
                [-84.9339, 66.033],
                [-84.6282, 65.6982],
                [-85.1864, 65.5481],
              ],
            ],
            [
              [
                [-83.0197, 62.2169],
                [-83.733, 62.1515],
                [-83.8854, 62.452],
                [-83.4624, 62.8497],
                [-82.1604, 62.9751],
                [-81.9062, 62.8897],
                [-82.0289, 62.6718],
                [-83.0197, 62.2169],
              ],
            ],
            [
              [
                [-86.5326, 70.092],
                [-86.5528, 69.9828],
                [-87.1228, 70.0126],
                [-87.1745, 70.0681],
                [-87.0819, 70.1622],
                [-86.5326, 70.092],
              ],
            ],
            [
              [
                [-90.0133, 77.3929],
                [-91.0092, 77.1827],
                [-91.1136, 77.2544],
                [-91.1656, 77.4586],
                [-91.1013, 77.5717],
                [-90.97, 77.6054],
                [-90.2109, 77.5592],
                [-90.0133, 77.4185],
                [-90.0133, 77.3929],
              ],
            ],
            [
              [
                [-95.4147, 77.8092],
                [-93.9653, 77.7465],
                [-93.5232, 77.7717],
                [-93.3416, 77.6694],
                [-93.5198, 77.4673],
                [-93.9653, 77.4561],
                [-95.9977, 77.4895],
                [-96.2635, 77.6327],
                [-96.055, 77.7372],
                [-95.4147, 77.7652],
                [-95.4147, 77.8092],
              ],
            ],
            [
              [
                [-93.5013, 74.7845],
                [-93.9823, 74.6497],
                [-96.2486, 74.9573],
                [-96.3837, 75.032],
                [-96.274, 75.2432],
                [-95.681, 75.5192],
                [-94.7543, 75.6115],
                [-93.5674, 75.2432],
                [-93.5013, 74.7845],
              ],
            ],
            [
              [
                [-94.4815, 76.0104],
                [-94.3575, 75.7355],
                [-94.8515, 75.7996],
                [-94.8178, 75.942],
                [-94.4815, 76.0104],
              ],
            ],
            [
              [
                [-94.8963, 78.1037],
                [-95.1153, 77.9672],
                [-95.4119, 77.9688],
                [-96.8501, 77.7885],
                [-97.2136, 77.9492],
                [-97.2436, 78.1407],
                [-97.9712, 78.2509],
                [-98.2853, 78.6715],
                [-97.9674, 78.8153],
                [-96.8779, 78.697],
                [-95.4174, 78.4959],
                [-95.0803, 78.4207],
                [-94.8963, 78.1037],
              ],
            ],
            [
              [
                [-95.4626, 74.6956],
                [-95.3838, 74.4766],
                [-95.7246, 74.4989],
                [-95.8748, 74.6624],
                [-95.4626, 74.6956],
              ],
            ],
            [
              [
                [-96.3969, 75.4318],
                [-96.6837, 75.3288],
                [-96.8415, 75.4426],
                [-96.2946, 75.6848],
                [-95.9934, 75.6268],
                [-95.9554, 75.5039],
                [-96.3969, 75.4318],
              ],
            ],
            [
              [
                [-97.88, 73.0734],
                [-97.0126, 72.777],
                [-97.0524, 73.0718],
                [-96.823, 73.1717],
                [-96.4015, 72.4342],
                [-96.8809, 71.8208],
                [-98.6701, 71.3129],
                [-101.8311, 72.4226],
                [-102.6382, 72.759],
                [-102.4485, 72.9756],
                [-101.8311, 72.9935],
                [-101.2478, 72.7399],
                [-100.4041, 72.8561],
                [-100.5413, 73.1934],
                [-101.3264, 73.4662],
                [-100.7734, 73.8151],
                [-97.7582, 74.082],
                [-97.6879, 73.8986],
                [-97.0759, 73.6786],
                [-97.2122, 73.4228],
                [-97.8059, 73.2384],
                [-97.88, 73.0734],
              ],
            ],
            [
              [
                [-96.9622, 76.4161],
                [-97.2393, 76.3408],
                [-97.3729, 76.5176],
                [-97.1817, 76.6802],
                [-96.9622, 76.4161],
              ],
            ],
            [
              [
                [-97.2386, 74.6784],
                [-97.2929, 74.424],
                [-97.6589, 74.5584],
                [-97.4391, 74.7278],
                [-97.2386, 74.6784],
              ],
            ],
            [
              [
                [-97.9147, 76.4941],
                [-97.4178, 75.5273],
                [-97.8048, 75.482],
                [-97.8886, 75.1289],
                [-98.0308, 75.0461],
                [-99.6238, 74.979],
                [-100.2619, 75.0499],
                [-100.4783, 75.586],
                [-102.4592, 75.5089],
                [-102.704, 75.7702],
                [-103.7575, 75.8914],
                [-104.3683, 76.1469],
                [-104.4543, 76.5494],
                [-103.9215, 76.635],
                [-101.9721, 75.9686],
                [-101.7795, 76.4066],
                [-100.6133, 76.2143],
                [-100.5612, 76.2998],
                [-100.7677, 76.5772],
                [-100.7212, 76.6632],
                [-97.9147, 76.4941],
              ],
            ],
            [
              [
                [-98.6659, 79.8217],
                [-98.909, 79.6889],
                [-99.8492, 79.9269],
                [-99.8898, 79.9752],
                [-99.8407, 80.0331],
                [-98.9605, 80.1096],
                [-98.6659, 79.8217],
              ],
            ],
            [
              [
                [-102.9396, 79.2494],
                [-102.5686, 78.8768],
                [-101.5083, 79.0614],
                [-99.7049, 78.5941],
                [-99.7049, 78.3019],
                [-99.0691, 78.1025],
                [-99.2325, 77.8615],
                [-99.7049, 77.8185],
                [-100.4781, 77.8729],
                [-100.8606, 78.1061],
                [-101.5999, 78.2548],
                [-102.5686, 78.2389],
                [-103.2252, 78.3494],
                [-104.0423, 78.2396],
                [-104.7025, 78.3333],
                [-104.8906, 78.4569],
                [-104.6674, 78.5809],
                [-104.0423, 78.5203],
                [-103.5632, 78.5978],
                [-103.7027, 78.8029],
                [-104.0423, 78.8029],
                [-104.0423, 78.9523],
                [-104.7528, 78.8698],
                [-104.9512, 79.0377],
                [-105.5071, 79.0197],
                [-105.6443, 79.1494],
                [-105.5071, 79.253],
                [-104.0423, 79.3838],
                [-102.9396, 79.2494],
              ],
            ],
            [
              [
                [-100.4044, 68.7105],
                [-100.6374, 68.4301],
                [-100.8119, 68.4579],
                [-100.7931, 68.6218],
                [-100.626, 68.6835],
                [-100.7474, 68.804],
                [-100.5105, 69.0421],
                [-100.2316, 69.0421],
                [-100.1209, 69.1493],
                [-99.994, 69.0421],
                [-100.0428, 68.7873],
                [-100.4044, 68.7105],
              ],
            ],
            [
              [
                [-100.1155, 70.5892],
                [-100.1401, 70.4241],
                [-100.6654, 70.5828],
                [-100.7013, 70.6967],
                [-100.5447, 70.819],
                [-100.3902, 70.6967],
                [-100.1155, 70.5892],
              ],
            ],
            [
              [
                [-105.2221, 72.6833],
                [-104.3428, 71.4584],
                [-104.3357, 71.0246],
                [-103.8412, 70.7572],
                [-101.8355, 70.3488],
                [-101.0933, 70.2146],
                [-100.9141, 70.043],
                [-100.9171, 69.9116],
                [-101.1428, 69.712],
                [-100.8891, 69.4933],
                [-101.1049, 69.3543],
                [-101.3283, 69.4937],
                [-101.3179, 69.7102],
                [-101.8355, 69.7051],
                [-102.3125, 69.8289],
                [-102.7389, 69.5621],
                [-102.7088, 69.4625],
                [-102.2548, 69.4756],
                [-101.7501, 69.2359],
                [-101.8355, 69.1418],
                [-102.7899, 68.8376],
                [-104.7223, 68.8869],
                [-106.1736, 69.1765],
                [-106.6447, 69.4203],
                [-107.472, 69.0244],
                [-109.1225, 68.7213],
                [-112.6339, 68.4935],
                [-113.2259, 68.5488],
                [-113.8908, 69.2008],
                [-116.0305, 69.3347],
                [-117.0197, 69.651],
                [-117.3066, 69.9628],
                [-116.4708, 70.1785],
                [-114.5359, 70.312],
                [-112.6339, 70.2603],
                [-112.4132, 70.2756],
                [-112.3107, 70.4562],
                [-113.9367, 70.7106],
                [-117.1482, 70.5476],
                [-117.9915, 70.9246],
                [-117.9834, 71.0259],
                [-116.8858, 71.3185],
                [-116.9014, 71.4244],
                [-118.0035, 71.3903],
                [-118.9192, 71.8132],
                [-118.2328, 72.239],
                [-118.3273, 72.5325],
                [-117.2304, 72.9289],
                [-114.3876, 73.3235],
                [-114.0156, 73.0806],
                [-114.2225, 72.6195],
                [-113.503, 72.7266],
                [-113.1294, 72.959],
                [-112.6339, 72.94],
                [-111.3353, 72.7281],
                [-111.1915, 72.6046],
                [-111.1886, 72.4153],
                [-110.4109, 72.6557],
                [-110.2948, 72.9462],
                [-109.8232, 72.9279],
                [-108.5943, 72.5739],
                [-108.4309, 72.1653],
                [-107.8917, 71.7223],
                [-107.4532, 71.9007],
                [-108.0046, 72.7207],
                [-108.0476, 73.2378],
                [-106.5375, 73.2711],
                [-106.6812, 73.5292],
                [-106.54, 73.6625],
                [-105.3363, 73.7339],
                [-104.5731, 73.4787],
                [-105.2221, 72.6833],
              ],
            ],
            [
              [
                [-101.2059, 77.7343],
                [-102.0822, 77.6812],
                [-102.432, 77.7519],
                [-102.488, 77.8373],
                [-102.4295, 77.8894],
                [-101.758, 77.9116],
                [-101.2025, 77.8425],
                [-101.2059, 77.7343],
              ],
            ],
            [
              [
                [-101.6833, 68.712],
                [-101.8306, 68.5891],
                [-102.1469, 68.6128],
                [-102.2525, 68.6979],
                [-101.8306, 68.804],
                [-101.6833, 68.712],
              ],
            ],
            [
              [
                [-103.7767, 75.2433],
                [-104.1951, 75.0408],
                [-104.6732, 75.121],
                [-104.7081, 75.2433],
                [-104.3159, 75.4419],
                [-104.0786, 75.4379],
                [-103.7767, 75.2433],
              ],
            ],
            [
              [
                [-104.2454, 68.4797],
                [-104.511, 68.3328],
                [-104.7591, 68.4994],
                [-104.5718, 68.6179],
                [-104.2454, 68.4797],
              ],
            ],
            [
              [
                [-104.5417, 77.17],
                [-104.917, 77.1132],
                [-105.5118, 77.2857],
                [-105.8716, 77.6607],
                [-105.5118, 77.7265],
                [-104.5268, 77.2763],
                [-104.5417, 77.17],
              ],
            ],
            [
              [
                [-108.3209, 76.0307],
                [-107.3727, 75.9059],
                [-106.3451, 76.0395],
                [-105.546, 75.8839],
                [-105.4376, 75.659],
                [-105.858, 75.2433],
                [-106.1079, 75.0561],
                [-108.4827, 74.9374],
                [-108.93, 75.0174],
                [-111.414, 74.568],
                [-112.6341, 74.4562],
                [-113.2271, 74.4295],
                [-114.0031, 74.6144],
                [-113.9991, 74.7201],
                [-112.0824, 75.0361],
                [-112.0807, 75.1419],
                [-112.6341, 75.1917],
                [-113.6837, 75.0803],
                [-114.009, 75.4392],
                [-114.375, 75.1365],
                [-115.0009, 74.9954],
                [-117.3462, 75.2316],
                [-117.3941, 75.2935],
                [-116.6303, 76.0423],
                [-115.6926, 76.3877],
                [-114.3342, 76.4672],
                [-113.7928, 76.2331],
                [-112.6341, 76.1889],
                [-111.0779, 75.5522],
                [-109.0441, 75.5375],
                [-108.9996, 75.6525],
                [-109.5307, 75.85],
                [-109.5904, 76.1549],
                [-110.1009, 76.3663],
                [-109.3993, 76.744],
                [-108.8421, 76.8094],
                [-108.2831, 76.2969],
                [-108.3209, 76.0307],
              ],
            ],
            [
              [
                [-109.7381, 78.412],
                [-112.6657, 78.3158],
                [-112.8388, 78.4256],
                [-112.7913, 78.4726],
                [-110.4607, 78.7363],
                [-109.7243, 78.5202],
                [-109.7381, 78.412],
              ],
            ],
            [
              [
                [-110.3821, 77.5079],
                [-112.1137, 77.3568],
                [-113.1834, 77.594],
                [-113.2337, 77.85],
                [-112.6441, 77.9594],
                [-110.0182, 78.0399],
                [-110.3821, 77.5079],
              ],
            ],
            [
              [
                [-111.5035, 68.171],
                [-111.8802, 68.1509],
                [-111.9607, 68.2251],
                [-111.6133, 68.437],
                [-111.5035, 68.171],
              ],
            ],
            [
              [
                [-113.6985, 76.892],
                [-113.6178, 76.7117],
                [-114.6547, 76.7444],
                [-114.7274, 76.8039],
                [-114.6606, 76.8648],
                [-113.6985, 76.892],
              ],
            ],
            [
              [
                [-113.7993, 77.7334],
                [-114.3405, 77.7072],
                [-114.7195, 77.9061],
                [-114.3405, 78.0948],
                [-114.3405, 78.0127],
                [-113.6523, 77.848],
                [-113.7993, 77.7334],
              ],
            ],
            [
              [
                [-121.3047, 74.5393],
                [-118.9321, 74.0881],
                [-118.1611, 74.2722],
                [-117.1603, 74.1672],
                [-116.9752, 74.1142],
                [-115.5888, 73.5649],
                [-115.5964, 73.4622],
                [-118.7569, 72.7354],
                [-119.4055, 72.3395],
                [-119.9617, 72.232],
                [-120.3803, 71.9613],
                [-120.6059, 71.5197],
                [-121.9312, 71.3785],
                [-122.0624, 71.2928],
                [-122.5414, 71.1983],
                [-122.8087, 71.084],
                [-123.2288, 71.1276],
                [-123.5077, 71.3567],
                [-124.2544, 71.7416],
                [-125.6619, 71.963],
                [-125.7307, 72.0537],
                [-125.6619, 72.2569],
                [-125.5253, 72.2802],
                [-125.1229, 72.6513],
                [-124.964, 72.6845],
                [-124.4509, 73.3708],
                [-123.896, 73.736],
                [-124.5878, 74.2423],
                [-124.5575, 74.3379],
                [-122.8087, 74.4603],
                [-121.3047, 74.5393],
              ],
            ],
            [
              [
                [-115.9759, 76.929],
                [-116.1233, 76.6379],
                [-117.0356, 76.5275],
                [-116.9858, 76.3726],
                [-117.1527, 76.2903],
                [-117.7573, 76.3284],
                [-117.9772, 76.5058],
                [-117.9589, 76.7079],
                [-118.1453, 76.7722],
                [-118.5494, 76.5058],
                [-118.642, 76.3771],
                [-119.0739, 76.1429],
                [-119.5321, 76.2913],
                [-119.5562, 75.9818],
                [-119.9432, 75.8513],
                [-120.4099, 75.8741],
                [-120.6347, 76.0108],
                [-121.0154, 75.7094],
                [-121.1406, 75.9524],
                [-122.5985, 76.0193],
                [-122.6834, 76.2114],
                [-122.408, 76.3952],
                [-121.3904, 76.5058],
                [-121.1092, 76.733],
                [-119.9432, 77.0218],
                [-119.0408, 77.3289],
                [-117.1527, 77.3603],
                [-116.4539, 77.5506],
                [-115.7531, 77.3612],
                [-115.7516, 77.2575],
                [-116.1792, 77.1285],
                [-115.9759, 76.929],
              ],
            ],
            [
              [
                [-118.3616, 75.5448],
                [-118.5534, 75.4962],
                [-119.145, 75.5576],
                [-119.1932, 75.6193],
                [-118.6909, 75.8966],
                [-118.1422, 75.9845],
                [-117.7591, 76.13],
                [-117.4868, 76.082],
                [-117.767, 75.8388],
                [-118.3616, 75.5448],
              ],
            ],
            [
              [
                [-124.8749, 70.2862],
                [-124.9891, 69.9567],
                [-125.2347, 70.0163],
                [-125.2088, 70.1956],
                [-124.8749, 70.2862],
              ],
            ],
            [
              [
                [-130.6891, 54.4046],
                [-130.8338, 54.2143],
                [-130.9982, 54.5676],
                [-130.7585, 54.6445],
                [-130.6891, 54.4046],
              ],
            ],
            [
              [
                [-131.0971, 52.2697],
                [-131.2355, 52.1354],
                [-131.5019, 52.4746],
                [-131.2355, 52.4942],
                [-131.0971, 52.2697],
              ],
            ],
            [
              [
                [-131.5903, 52.9486],
                [-132.1017, 52.7488],
                [-132.2233, 52.8573],
                [-132.4797, 53.3343],
                [-132.9286, 53.5556],
                [-133.1223, 53.8956],
                [-133.085, 54.2386],
                [-132.9016, 54.1635],
                [-131.7587, 54.0151],
                [-131.9297, 53.5556],
                [-131.9061, 53.2954],
                [-131.5903, 52.9486],
              ],
            ],
          ],
        },
        properties: { name: "Canada" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [27.4436, 5.0194],
              [28.3376, 4.3157],
              [29.7103, 4.5969],
              [30.8545, 3.4875],
              [30.7765, 2.5135],
              [31.2496, 2.0752],
              [30.0157, 0.8003],
              [29.8398, 0.252],
              [29.7816, 0.0707],
              [29.721, -0.1179],
              [29.6422, -0.8786],
              [29.5895, -1.3866],
              [29.2083, -1.766],
              [28.9949, -2.2733],
              [28.9465, -2.3881],
              [29.0406, -2.7438],
              [29.4233, -4.4475],
              [29.3874, -5.0153],
              [29.5996, -5.587],
              [29.5926, -6.3587],
              [30.2771, -7.1145],
              [30.7712, -8.1922],
              [29.016, -8.5161],
              [28.454, -9.2529],
              [28.6626, -9.794],
              [28.5408, -10.9113],
              [28.4861, -11.8258],
              [29.1044, -12.3704],
              [29.7932, -12.2818],
              [29.7527, -13.4086],
              [29.5569, -13.2535],
              [29.0399, -13.3796],
              [28.4077, -12.5205],
              [27.6036, -12.2134],
              [27.1699, -11.6261],
              [26.7612, -11.9646],
              [26.0386, -11.9177],
              [25.4764, -11.7233],
              [25.2205, -11.26],
              [24.396, -11.3999],
              [24.1243, -10.9113],
              [23.983, -10.9113],
              [23.0649, -11.0941],
              [22.2761, -11.0428],
              [22.2739, -10.3412],
              [21.8433, -9.4449],
              [21.9191, -8.4515],
              [21.6619, -7.309],
              [20.7264, -7.2513],
              [20.4216, -6.9498],
              [19.8263, -6.9975],
              [19.2527, -7.9545],
              [17.7714, -8.0899],
              [17.0219, -7.2667],
              [16.4629, -5.9179],
              [12.8501, -5.9411],
              [12.7227, -5.9948],
              [12.4187, -5.982],
              [12.1955, -5.7639],
              [12.4994, -5.6868],
              [12.5746, -5.0613],
              [13.1018, -4.6851],
              [13.4772, -4.7956],
              [13.7912, -4.4961],
              [14.2824, -4.3716],
              [14.4064, -4.4542],
              [14.4575, -4.7733],
              [14.6688, -4.8486],
              [15.1942, -4.3563],
              [15.86, -3.9206],
              [16.1709, -3.3396],
              [16.2257, -2.2733],
              [16.8321, -1.3121],
              [17.3029, -0.8786],
              [17.6376, -0.5705],
              [17.7769, 0.252],
              [18.1044, 2.1845],
              [18.6251, 3.4716],
              [18.6554, 4.3279],
              [19.4799, 5.075],
              [19.8888, 5.0123],
              [20.6682, 4.4533],
              [22.1795, 4.175],
              [22.9998, 4.6994],
              [23.5054, 4.6884],
              [24.443, 5.0286],
              [24.9587, 4.9801],
              [25.5177, 5.3238],
              [27.4436, 5.0194],
            ],
          ],
        },
        properties: { name: "Democratic Republic of The Congo" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [22.8755, 10.9314],
              [23.608, 9.9187],
              [23.5994, 8.8147],
              [24.2234, 8.6422],
              [24.321, 8.3317],
              [24.9801, 8.016],
              [25.4062, 7.3523],
              [26.29, 6.6847],
              [26.6049, 6.0962],
              [27.1693, 5.6776],
              [27.4436, 5.0194],
              [25.5177, 5.3238],
              [24.9587, 4.9801],
              [24.443, 5.0286],
              [23.5054, 4.6884],
              [22.9998, 4.6994],
              [22.1795, 4.175],
              [20.6682, 4.4533],
              [19.8888, 5.0123],
              [19.4799, 5.075],
              [18.6554, 4.3279],
              [18.6251, 3.4716],
              [18.392, 3.6187],
              [16.6837, 3.5022],
              [16.192, 2.2205],
              [16.0122, 2.9309],
              [15.1777, 3.8331],
              [14.5544, 5.3255],
              [14.5004, 6.0684],
              [15.5075, 7.5275],
              [15.8854, 7.4815],
              [16.5651, 7.8021],
              [16.883, 7.591],
              [17.6801, 7.9654],
              [18.5893, 8.0831],
              [19.0457, 8.6058],
              [18.9757, 8.9098],
              [20.3063, 9.1517],
              [20.7327, 9.3974],
              [21.6697, 10.3052],
              [21.7863, 10.7159],
              [22.4744, 10.9768],
              [22.8755, 10.9314],
            ],
          ],
        },
        properties: { name: "Central African Republic" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [18.6251, 3.4716],
              [18.1044, 2.1845],
              [17.7769, 0.252],
              [17.6376, -0.5705],
              [17.3029, -0.8786],
              [16.8321, -1.3121],
              [16.2257, -2.2733],
              [16.1709, -3.3396],
              [15.86, -3.9206],
              [15.1942, -4.3563],
              [14.6688, -4.8486],
              [14.4575, -4.7733],
              [14.4064, -4.4542],
              [14.2824, -4.3716],
              [13.7912, -4.4961],
              [13.4772, -4.7956],
              [13.1018, -4.6851],
              [12.7888, -4.4786],
              [11.9892, -4.9993],
              [11.201, -3.9916],
              [11.8945, -3.4408],
              [11.9433, -3.1022],
              [11.7476, -2.7148],
              [11.8464, -2.5027],
              [12.3425, -2.3577],
              [12.6158, -2.0511],
              [13.3829, -2.3436],
              [14.1596, -2.16],
              [14.3759, -1.7141],
              [14.4299, -0.8786],
              [14.008, 0.0173],
              [14.3513, 1.0236],
              [14.0479, 1.3393],
              [13.3162, 1.4466],
              [13.299, 2.1706],
              [14.5151, 2.1889],
              [16.0227, 1.7813],
              [16.192, 2.2205],
              [16.6837, 3.5022],
              [18.392, 3.6187],
              [18.6251, 3.4716],
            ],
          ],
        },
        properties: { name: "Congo" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [9.5587, 47.5419],
              [9.5307, 47.2706],
              [9.476, 47.0518],
              [9.6071, 47.0608],
              [10.145, 46.851],
              [10.4697, 46.8549],
              [10.4528, 46.5307],
              [10.1739, 46.5798],
              [10.0507, 46.335],
              [9.3475, 46.472],
              [9.2485, 46.2338],
              [9.1594, 46.1696],
              [8.9121, 45.8304],
              [8.7139, 46.0973],
              [8.3847, 46.4522],
              [7.8771, 45.927],
              [7.8641, 45.9168],
              [7.0449, 45.9224],
              [6.8211, 46.4272],
              [6.463, 46.432],
              [6.2414, 46.3436],
              [6.1776, 46.1816],
              [5.9561, 46.1321],
              [6.1256, 46.3172],
              [6.064, 46.4162],
              [6.1381, 46.5577],
              [6.463, 46.8511],
              [6.8602, 47.1655],
              [6.8584, 47.1672],
              [6.9392, 47.4337],
              [7.1304, 47.503],
              [7.3809, 47.4319],
              [7.5109, 47.5026],
              [7.5552, 47.5646],
              [7.589, 47.5899],
              [7.6341, 47.5611],
              [7.7138, 47.5394],
              [7.8941, 47.5864],
              [8.4264, 47.5675],
              [8.5149, 47.6272],
              [8.5101, 47.7762],
              [8.6138, 47.8011],
              [8.6634, 47.6859],
              [8.6705, 47.6849],
              [9.1822, 47.6559],
              [9.4956, 47.5515],
              [9.5587, 47.5419],
            ],
          ],
        },
        properties: { name: "Switzerland" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-5.5189, 10.4333],
              [-5.117, 10.2222],
              [-4.812, 9.777],
              [-4.3699, 9.6182],
              [-3.6098, 9.9307],
              [-3.3739, 9.8912],
              [-3.198, 9.8618],
              [-2.7863, 9.5629],
              [-2.6869, 9.4907],
              [-2.5205, 8.2869],
              [-3.1813, 6.7737],
              [-2.8322, 5.299],
              [-3.1058, 5.0909],
              [-3.3739, 5.1348],
              [-4.0246, 5.2415],
              [-5.595, 5.0728],
              [-7.5231, 4.3617],
              [-7.4568, 5.7608],
              [-8.4496, 6.4988],
              [-8.3267, 7.0841],
              [-8.4722, 7.5546],
              [-8.1719, 7.6791],
              [-8.0418, 8.107],
              [-8.2025, 8.2206],
              [-8.1489, 8.4531],
              [-7.7092, 8.5226],
              [-7.9101, 8.8593],
              [-7.8783, 9.2737],
              [-8.1218, 9.6107],
              [-7.9785, 10.1751],
              [-7.5603, 10.415],
              [-6.9796, 10.2777],
              [-6.3759, 10.6631],
              [-6.0283, 10.3127],
              [-5.5189, 10.4333],
            ],
          ],
        },
        properties: { name: "Ivory Coast" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-157.7861, -9.075],
                [-157.9637, -9.1776],
                [-158.1467, -8.9599],
                [-158.1209, -8.8361],
                [-157.8847, -8.8845],
                [-157.7861, -9.075],
              ],
            ],
            [
              [
                [-159.5685, -21.2758],
                [-159.6923, -21.3824],
                [-159.9369, -21.3179],
                [-159.9866, -21.1757],
                [-159.6782, -21.1139],
                [-159.5685, -21.2758],
              ],
            ],
            [
              [
                [-162.9359, -13.4211],
                [-163.1309, -13.4225],
                [-163.415, -13.085],
                [-163.242, -13.0414],
                [-162.9359, -13.4211],
              ],
            ],
          ],
        },
        properties: { name: "Cook Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [46.553, 29.1012],
              [47.4177, 28.9797],
              [47.7643, 28.5452],
              [48.4333, 28.5314],
              [48.9148, 27.6301],
              [49.9616, 26.8423],
              [50.2035, 26.3608],
              [50.1167, 25.8365],
              [50.815, 24.7395],
              [51.3072, 24.5064],
              [51.3465, 24.3093],
              [51.5906, 24.243],
              [52.6453, 22.9764],
              [55.2083, 22.7083],
              [55.6667, 22],
              [55.623, 21.8699],
              [55.5332, 21.6026],
              [54.9485, 20.0059],
              [52, 19],
              [49.0135, 18.5638],
              [48.1849, 18.1433],
              [47.1932, 16.9779],
              [46.7083, 17.2521],
              [45.6126, 17.308],
              [43.3633, 17.4977],
              [43.183, 16.6552],
              [42.7639, 16.4046],
              [42.2907, 17.3713],
              [41.6108, 18.1055],
              [40.533, 19.886],
              [39.3742, 20.8823],
              [38.9569, 22.7932],
              [38.4486, 23.6967],
              [37.45, 24.498],
              [37.1665, 25.218],
              [36.6707, 25.724],
              [35.3225, 27.8117],
              [34.6586, 28.1161],
              [34.957, 29.3567],
              [36.081, 29.223],
              [36.7808, 29.8457],
              [37.4562, 30.0187],
              [37.9246, 30.5466],
              [37.0883, 31.5014],
              [39.2009, 32.1543],
              [40.4951, 31.898],
              [42.1579, 31.0566],
              [44.663, 29.2518],
              [45.6126, 29.1294],
              [46.1115, 29.0876],
              [46.4258, 29.0612],
              [46.553, 29.1012],
            ],
          ],
        },
        properties: { name: "Saudi Arabia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [179.9962, 70.9763],
                [179.583, 70.8674],
                [179.2345, 70.8757],
                [178.7808, 70.8069],
                [178.6083, 71.0683],
                [178.8815, 71.2158],
                [179.4381, 71.4027],
                [179.9973, 71.5246],
                [179.9962, 70.9763],
              ],
            ],
            [
              [
                [130.6749, 42.2836],
                [130.6238, 42.45],
                [130.5711, 42.6363],
                [130.46, 42.6893],
                [131.1583, 43.1226],
                [131.2039, 44.8571],
                [131.8756, 45.2894],
                [133.0211, 45.139],
                [133.8238, 46.2524],
                [134.1834, 47.2206],
                [134.6406, 47.6454],
                [134.6067, 48.3298],
                [131.9987, 47.7036],
                [131.1145, 47.7422],
                [130.3126, 48.931],
                [129.5609, 49.3475],
                [127.6584, 49.7757],
                [126.48, 52.1446],
                [125.4695, 53.0542],
                [123.3487, 53.5256],
                [120.9957, 53.2739],
                [120.208, 52.7974],
                [120.1502, 52.6928],
                [120.6094, 52.4875],
                [120.6644, 52.0663],
                [119.9519, 51.4479],
                [119.1095, 50.0654],
                [117.8074, 49.5794],
                [116.7209, 49.8498],
                [116.2426, 49.9903],
                [115.4261, 49.9355],
                [114.3649, 50.236],
                [112.887, 49.5712],
                [110.9163, 49.2007],
                [108.8074, 49.3345],
                [107.8553, 49.9138],
                [106.5203, 50.3292],
                [105.4152, 50.4519],
                [103.5057, 50.2012],
                [102.4373, 50.5965],
                [101.9481, 51.3734],
                [98.9786, 52.0482],
                [97.9549, 51.1391],
                [98.1961, 50.2828],
                [97.3304, 49.8163],
                [94.7987, 50.0463],
                [94.1016, 50.5591],
                [92.697, 50.77],
                [92.4372, 50.809],
                [90.0071, 50.0358],
                [89.5277, 49.6654],
                [89.1233, 49.6048],
                [88.2913, 49.48],
                [87.825, 49.1833],
                [87.3126, 49.0997],
                [86.6632, 49.68],
                [86.0644, 49.5438],
                [85.3276, 49.658],
                [83.538, 50.9008],
                [81.6483, 50.8179],
                [80.754, 51.2158],
                [79.9616, 51.0371],
                [78.2111, 52.9706],
                [76.7984, 53.8832],
                [76.5796, 54.2755],
                [75.5425, 54.0876],
                [74.3649, 53.5615],
                [73.6202, 53.5211],
                [73.1022, 54.0044],
                [71.2665, 54.2568],
                [70.7176, 55.2085],
                [69.013, 55.3543],
                [67.9852, 54.952],
                [65.6722, 54.6387],
                [64.8622, 54.3622],
                [61.3583, 53.9303],
                [61.2404, 53.6425],
                [61.6281, 53.0971],
                [60.9869, 52.8069],
                [60.8386, 52.2997],
                [60.2825, 51.9603],
                [61.3899, 51.3192],
                [61.3457, 50.9419],
                [61.1066, 50.7718],
                [59.4875, 50.6292],
                [58.0642, 51.0787],
                [56.5075, 50.972],
                [55.6774, 50.6643],
                [54.9145, 50.8104],
                [54.5327, 50.6975],
                [53.2814, 51.483],
                [52.3307, 51.6643],
                [51.5214, 51.544],
                [50.8212, 51.6542],
                [48.9207, 50.6761],
                [48.573, 49.9575],
                [47.446, 50.2984],
                [46.9351, 49.7296],
                [46.6827, 48.4677],
                [47.2665, 47.8148],
                [48.0285, 47.6876],
                [48.4852, 47.4156],
                [48.8096, 46.9554],
                [48.7942, 46.599],
                [49.2136, 46.3332],
                [48.3519, 45.8178],
                [47.604, 45.6259],
                [46.863, 44.6345],
                [47.3607, 43.8888],
                [47.5658, 42.9899],
                [48.0231, 42.3929],
                [48.3039, 42.1059],
                [48.5218, 41.8833],
                [48.5735, 41.8254],
                [47.6587, 41.2176],
                [46.7291, 41.7331],
                [46.4205, 41.9043],
                [45.9086, 42.1059],
                [45.6621, 42.3929],
                [45.0431, 42.6553],
                [43.9013, 42.6535],
                [42.4524, 43.1829],
                [41.0039, 43.4488],
                [40.0103, 43.3853],
                [38.7996, 44.2241],
                [36.9458, 45.1824],
                [37.9777, 45.9863],
                [38.0723, 46.5853],
                [38.5103, 46.9405],
                [38.231, 47.1187],
                [38.5666, 47.6752],
                [39.756, 47.9207],
                [39.8787, 48.3158],
                [39.7817, 48.848],
                [40.0937, 49.271],
                [39.9709, 49.5281],
                [39.1438, 49.8497],
                [38.0083, 49.9928],
                [37.3983, 50.3916],
                [36.5636, 50.2607],
                [35.6852, 50.4062],
                [35.1589, 51.1293],
                [34.307, 51.4173],
                [34.3442, 51.8126],
                [33.7694, 52.2996],
                [31.7869, 52.1115],
                [31.3967, 53.0369],
                [32.526, 53.3094],
                [32.5875, 53.4898],
                [32.2832, 53.7654],
                [31.8888, 53.8522],
                [30.9746, 54.6727],
                [30.7426, 55.6294],
                [28.1546, 56.1698],
                [27.6908, 57.3706],
                [27.3516, 57.5182],
                [27.6449, 57.981],
                [27.357, 58.7871],
                [28.1202, 59.3526],
                [28.0419, 59.4701],
                [29.3172, 60.1181],
                [28.5672, 60.5],
                [27.7476, 60.4578],
                [27.8036, 60.5486],
                [27.9913, 60.669],
                [30.144, 61.8522],
                [31.1863, 62.4872],
                [31.5218, 62.8942],
                [31.128, 63.2578],
                [29.9719, 63.7572],
                [30.2805, 63.8509],
                [30.4884, 64.1574],
                [30.0686, 64.4375],
                [30.0598, 64.547],
                [30.0443, 64.7388],
                [29.7009, 64.9085],
                [29.7547, 65.4974],
                [30.0785, 65.7704],
                [29.5729, 66.4329],
                [29.4643, 66.5492],
                [29.1273, 66.9908],
                [29.956, 67.6452],
                [28.8967, 68.1515],
                [28.6388, 68.2375],
                [28.4782, 68.5109],
                [28.8967, 69.0426],
                [29.6453, 69.3474],
                [30.0753, 69.4717],
                [30.2617, 69.6417],
                [30.8426, 69.5422],
                [30.9297, 69.6195],
                [30.8654, 69.7596],
                [32.2282, 69.7951],
                [33.6469, 69.3004],
                [36.3959, 69.0059],
                [40.877, 67.4097],
                [40.9794, 67.0664],
                [40.6709, 66.6558],
                [38.9814, 66.192],
                [34.184, 66.4059],
                [35.1704, 64.5412],
                [36.3256, 64.0662],
                [37.1374, 63.9503],
                [37.5223, 64.16],
                [37.0853, 64.6282],
                [37.1902, 64.9043],
                [39.6429, 64.7651],
                [39.9186, 64.9561],
                [40.1409, 65.4991],
                [40.6412, 65.835],
                [42.25, 66.3431],
                [43.9817, 66.4973],
                [44.1706, 66.8837],
                [44.0179, 68.1538],
                [44.4091, 68.4926],
                [45.9114, 68.2653],
                [46.0643, 67.9449],
                [45.4559, 67.4089],
                [47.0008, 66.9654],
                [49.3314, 67.9542],
                [53.298, 68.7358],
                [54.8435, 68.3345],
                [58.404, 68.7985],
                [59.6601, 68.5647],
                [60.4865, 68.9586],
                [60.442, 69.3314],
                [59.2881, 69.95],
                [59.3619, 70.1626],
                [60.7346, 69.7979],
                [64.6961, 69.3395],
                [68.4408, 68.5423],
                [68.6217, 68.7039],
                [68.5329, 68.9701],
                [67.2152, 69.7906],
                [67.2366, 71.1079],
                [69.3769, 72.6976],
                [71.7354, 72.7044],
                [72.4602, 72.3494],
                [72.2713, 71.4029],
                [72.5962, 70.6168],
                [72.6748, 69.2239],
                [73.1332, 68.1285],
                [72.6757, 67.5559],
                [71.4922, 66.8091],
                [70.2403, 66.5432],
                [70.5087, 66.3892],
                [71.798, 66.3446],
                [73.2985, 66.8302],
                [74.0269, 67.2874],
                [74.517, 67.8502],
                [74.7341, 68.4802],
                [75.014, 68.6814],
                [76.4, 68.7806],
                [77.3041, 68.1731],
                [77.5638, 68.2629],
                [77.4465, 68.7061],
                [76.9954, 68.9722],
                [73.9087, 69.6016],
                [73.9252, 70.6072],
                [73.5653, 71.4963],
                [74.9319, 72.4185],
                [75.2424, 72.3581],
                [75.5185, 71.6873],
                [76.1227, 71.2727],
                [77.16, 71.2259],
                [76.7108, 71.7767],
                [78.2618, 72.4203],
                [82.3964, 71.6931],
                [82.7167, 71.0218],
                [83.1657, 70.815],
                [83.1295, 71.6767],
                [81.2609, 72.455],
                [80.9349, 73.2703],
                [85.8455, 73.8889],
                [86.2336, 74.1623],
                [86.192, 74.6654],
                [86.6244, 74.8754],
                [95.1844, 76.1808],
                [96.8181, 75.9747],
                [98.5686, 76.151],
                [100.7834, 76.614],
                [102.6562, 77.4333],
                [104.5472, 77.6314],
                [105.6401, 77.4411],
                [107.3126, 76.6255],
                [111.2225, 76.7131],
                [113.0457, 76.3156],
                [113.5029, 75.9571],
                [113.4201, 75.4638],
                [112.8646, 75.0958],
                [107.4182, 73.633],
                [106.8982, 73.3679],
                [107.0256, 73.1779],
                [109.1608, 73.4013],
                [110.686, 73.9045],
                [112.7292, 73.7814],
                [113.8377, 73.4997],
                [115.6978, 73.6525],
                [117.636, 73.5748],
                [119.3563, 73.1501],
                [121.2509, 72.9724],
                [123.0428, 73.0515],
                [124.284, 73.6563],
                [127.1877, 73.4971],
                [129.0678, 72.9295],
                [129.2629, 72.5375],
                [129.1489, 71.9184],
                [129.2731, 71.6075],
                [129.9087, 71.2165],
                [131.049, 70.9136],
                [132.9632, 71.6747],
                [134.5583, 71.4507],
                [139.1177, 71.5684],
                [139.9969, 72.3703],
                [142.1795, 72.77],
                [148.4814, 72.251],
                [152.7627, 70.9087],
                [158.2213, 70.8941],
                [159.3347, 70.6216],
                [160.2129, 69.847],
                [161.3828, 69.5239],
                [164.1068, 69.6802],
                [166.6541, 69.5592],
                [168.3886, 69.8244],
                [168.6206, 69.7125],
                [168.8372, 69.238],
                [170.0048, 68.9403],
                [170.5578, 69.1405],
                [170.9095, 69.7566],
                [172.1652, 70.002],
                [176.7193, 69.7286],
                [178.6221, 69.3595],
                [179.7432, 68.6856],
                [179.9559, 67.3162],
                [179.8103, 65.4272],
                [179.1361, 64.8671],
                [178.1761, 64.3831],
                [179.1082, 63.207],
                [179.065, 62.5938],
                [176.7371, 62.3957],
                [174.2536, 61.8157],
                [170.5153, 60.2446],
                [168.4208, 60.4862],
                [163.8424, 59.7762],
                [162.4195, 58.2071],
                [162.968, 57.4438],
                [162.951, 56.3],
                [162.1297, 55.7562],
                [161.6682, 54.7698],
                [160.3802, 54.1602],
                [159.7945, 53.4023],
                [158.7737, 52.8946],
                [158.0959, 52.0229],
                [157.2446, 51.4472],
                [156.4905, 51.9416],
                [155.7786, 54.4608],
                [155.7272, 55.6758],
                [156.0364, 56.5102],
                [157.2578, 57.7648],
                [158.6874, 58.3422],
                [161.6927, 60.1765],
                [163.3851, 60.9563],
                [164.1342, 62.3923],
                [163.6561, 62.3609],
                [162.7223, 61.5942],
                [160.7523, 60.9377],
                [160.2363, 61.1361],
                [159.6791, 61.7447],
                [158.2131, 61.7836],
                [157.2974, 61.6169],
                [154.7298, 60.0415],
                [154.5438, 59.721],
                [154.6514, 59.3363],
                [154.3106, 59.1655],
                [151.9522, 58.9866],
                [151.344, 59.425],
                [149.8162, 59.614],
                [148.2633, 59.3343],
                [145.0309, 59.3263],
                [141.8573, 58.7491],
                [135.7775, 54.8981],
                [137.697, 53.7632],
                [138.2982, 53.7639],
                [139.4491, 54.1061],
                [141.0637, 53.2194],
                [141.1846, 51.9968],
                [140.6448, 50.8282],
                [140.4238, 49.5567],
                [139.9505, 48.4355],
                [137.7699, 45.9533],
                [135.0391, 43.5659],
                [133.3857, 42.8819],
                [131.7936, 43.0045],
                [130.6749, 42.2836],
              ],
              [
                [73.4786, 53.86],
                [73.723, 53.8039],
                [73.8138, 54.1065],
                [73.4388, 54.0281],
                [73.4786, 53.86],
              ],
            ],
            [
              [
                [166.6501, 54.8319],
                [166.5906, 54.7281],
                [166.1652, 54.9469],
                [165.8504, 55.299],
                [165.9027, 55.3759],
                [166.2665, 55.3136],
                [166.2171, 55.1826],
                [166.4334, 54.9488],
                [166.6501, 54.8319],
              ],
            ],
            [
              [
                [164.2051, 58.8131],
                [163.7877, 58.6996],
                [163.72, 58.7597],
                [163.8509, 59.0184],
                [164.3197, 59.1114],
                [164.6075, 59.2435],
                [164.7632, 59.0307],
                [164.6653, 58.8686],
                [164.2051, 58.8131],
              ],
            ],
            [
              [
                [158.3604, 76.6476],
                [157.9497, 76.6178],
                [157.889, 76.6755],
                [158.0828, 76.8249],
                [158.4223, 76.7663],
                [158.3604, 76.6476],
              ],
            ],
            [
              [
                [156.4938, 50.8867],
                [156.42, 50.6418],
                [156.1875, 50.6428],
                [155.8912, 50.2403],
                [155.6073, 50.1772],
                [155.4085, 50.0019],
                [155.1865, 50.061],
                [155.2288, 50.2336],
                [155.7289, 50.4393],
                [155.8524, 50.651],
                [156.4938, 50.8867],
              ],
            ],
            [
              [
                [155.7858, 50.8553],
                [155.4719, 50.7231],
                [155.2973, 50.9106],
                [155.6449, 51.0009],
                [155.7858, 50.8553],
              ],
            ],
            [
              [
                [154.9165, 49.3392],
                [154.706, 49.1914],
                [154.5409, 49.3045],
                [154.7255, 49.58],
                [154.9165, 49.3392],
              ],
            ],
            [
              [
                [146.4403, 75.5745],
                [146.9721, 75.34],
                [147.8902, 75.4213],
                [150.7066, 75.0449],
                [150.507, 74.8549],
                [149.114, 74.7376],
                [146.4403, 75.1545],
                [146.3229, 75.1949],
                [146.2658, 75.3731],
                [146.4403, 75.5745],
              ],
            ],
            [
              [
                [150.2144, 45.9421],
                [149.6699, 45.6062],
                [149.577, 45.6632],
                [150.0645, 46.0962],
                [150.3738, 46.2397],
                [150.4113, 46.1106],
                [150.2144, 45.9421],
              ],
            ],
            [
              [
                [149.2942, 76.7884],
                [149.316, 76.5856],
                [148.9788, 76.5878],
                [148.8857, 76.68],
                [148.9755, 76.7752],
                [149.2942, 76.7884],
              ],
            ],
            [
              [
                [139.1009, 76.1731],
                [140.7059, 75.7049],
                [141.0207, 76.0599],
                [141.7878, 76.0833],
                [144.9837, 75.5577],
                [145.0101, 75.4635],
                [144.6004, 75.1119],
                [143.3834, 74.8961],
                [142.532, 74.8204],
                [142.133, 74.9936],
                [140.7059, 74.8724],
                [139.7162, 74.9286],
                [138.9392, 74.6615],
                [138.2309, 74.7684],
                [137.045, 75.2611],
                [137.2451, 75.7087],
                [137.5548, 75.9213],
                [138.5049, 76.1401],
                [139.1009, 76.1731],
              ],
            ],
            [
              [
                [143.4824, 46.7446],
                [143.5727, 46.3982],
                [143.4439, 46.2889],
                [143.1663, 46.5671],
                [142.5957, 46.6828],
                [142.06, 45.9602],
                [141.8412, 46.5501],
                [142.0904, 47.8449],
                [142.1556, 48.1942],
                [141.9093, 48.7132],
                [142.1339, 49.6014],
                [142.0652, 50.6516],
                [142.206, 51.1814],
                [141.6632, 51.9611],
                [141.8786, 53.0959],
                [141.8595, 53.3932],
                [142.3801, 53.3971],
                [142.7286, 53.736],
                [142.4494, 54.1962],
                [142.6729, 54.3563],
                [142.9365, 54.1028],
                [143.1762, 53.295],
                [143.0919, 53.0959],
                [143.2945, 52.7832],
                [143.1524, 52.0745],
                [143.2807, 51.5226],
                [144.2659, 49.2706],
                [143.0677, 49.1501],
                [142.5374, 47.8449],
                [143.1224, 46.9378],
                [143.4824, 46.7446],
              ],
            ],
            [
              [
                [143.4715, 56.3041],
                [143.2011, 56.287],
                [143.1509, 56.3905],
                [143.4736, 56.5562],
                [143.6132, 56.4675],
                [143.4715, 56.3041],
              ],
            ],
            [
              [
                [143.5483, 73.4003],
                [143.2072, 73.1882],
                [140.7059, 73.4297],
                [140.7059, 73.5507],
                [141.1108, 73.8288],
                [142.1034, 73.9058],
                [143.1833, 73.6505],
                [143.5483, 73.4003],
              ],
            ],
            [
              [
                [140.7059, 73.9426],
                [140.4556, 73.9297],
                [140.2354, 74.0393],
                [140.2984, 74.2149],
                [140.7059, 74.2716],
                [141.05, 74.1907],
                [141.0734, 74.0393],
                [140.9748, 73.989],
                [140.7059, 73.9426],
              ],
            ],
            [
              [
                [137.8863, 54.7467],
                [137.6871, 54.676],
                [137.3793, 54.8843],
                [137.5562, 55.1282],
                [137.8863, 55.1266],
                [138.0625, 55.0042],
                [137.9914, 54.8],
                [137.8863, 54.7467],
              ],
            ],
            [
              [
                [137.1123, 55.1828],
                [137.1161, 54.8394],
                [136.9025, 54.8416],
                [136.7915, 55.0038],
                [137.1123, 55.1828],
              ],
            ],
            [
              [
                [136.1841, 74.0414],
                [136.179, 73.8826],
                [135.7278, 74.0414],
                [135.6923, 74.1284],
                [135.7665, 74.1826],
                [136.1841, 74.0414],
              ],
            ],
            [
              [
                [136.073, 75.4394],
                [135.6046, 75.3791],
                [135.5453, 75.4375],
                [135.658, 75.7493],
                [135.7989, 75.8089],
                [136.0893, 75.6475],
                [136.073, 75.4394],
              ],
            ],
            [
              [
                [123.4194, 73.3718],
                [123.1781, 73.3336],
                [123.0671, 73.5256],
                [123.2466, 73.716],
                [123.4194, 73.3718],
              ],
            ],
            [
              [
                [120.6889, 74.1524],
                [120.7267, 73.9112],
                [120.2539, 74.1349],
                [120.4311, 74.2332],
                [120.6889, 74.1524],
              ],
            ],
            [
              [
                [113.2672, 74.2988],
                [112.8544, 74.1066],
                [112.5141, 74.1074],
                [111.772, 74.2699],
                [111.7296, 74.316],
                [112.0715, 74.5334],
                [112.5141, 74.5258],
                [113.2532, 74.402],
                [113.2672, 74.2988],
              ],
            ],
            [
              [
                [107.8406, 78.1845],
                [107.5342, 77.9552],
                [107.4017, 78.1333],
                [107.5614, 78.2893],
                [107.8406, 78.1845],
              ],
            ],
            [
              [
                [107.7193, 77.2311],
                [107.3022, 77.1709],
                [107.2358, 77.2404],
                [107.5477, 77.4667],
                [107.7193, 77.2311],
              ],
            ],
            [
              [
                [102.9989, 79.3069],
                [102.9629, 79.0049],
                [103.4345, 79.1651],
                [104.1392, 78.9859],
                [104.6229, 78.7858],
                [105.0665, 78.8603],
                [105.3192, 78.5861],
                [104.8884, 78.3354],
                [104.1392, 78.2922],
                [101.2335, 78.1585],
                [100.0011, 77.9474],
                [99.6648, 78.0684],
                [99.6914, 78.1076],
                [101.2335, 78.9897],
                [101.2335, 79.0904],
                [101.6038, 79.2177],
                [101.6576, 79.3069],
                [102.2392, 79.2348],
                [102.2996, 79.3825],
                [102.4684, 79.4075],
                [102.9989, 79.3069],
              ],
            ],
            [
              [
                [97.3491, 80.3705],
                [98.037, 79.9604],
                [99.1615, 80.0135],
                [99.8236, 79.8713],
                [100.0212, 79.6344],
                [99.7718, 78.9424],
                [98.4008, 78.793],
                [94.9671, 79.0881],
                [94.1448, 79.543],
                [94.2977, 79.8455],
                [94.1995, 79.9542],
                [92.697, 79.7265],
                [92.0682, 79.8426],
                [91.9828, 79.9006],
                [92.0659, 79.9669],
                [92.697, 80.0335],
                [92.8065, 80.5418],
                [93.073, 80.8378],
                [95.8425, 81.2164],
                [97.4925, 80.8099],
                [97.3491, 80.3705],
              ],
            ],
            [
              [
                [96.5787, 77.1055],
                [96.1356, 76.9867],
                [96.0468, 77.0523],
                [96.4981, 77.2961],
                [96.5787, 77.1055],
              ],
            ],
            [
              [
                [95.7166, 76.5293],
                [95.3627, 76.4593],
                [95.3051, 76.7214],
                [95.6495, 76.6784],
                [95.7166, 76.5293],
              ],
            ],
            [
              [
                [95.2074, 76.7409],
                [95.2107, 76.6349],
                [94.9486, 76.5709],
                [94.748, 76.6664],
                [94.4417, 76.548],
                [94.3569, 76.6613],
                [94.446, 76.8473],
                [95.2074, 76.7409],
              ],
            ],
            [
              [
                [91.6152, 81.0744],
                [91.3624, 81.0341],
                [90.177, 81.0668],
                [90.122, 81.1305],
                [90.2109, 81.1856],
                [91.0617, 81.2148],
                [91.5945, 81.183],
                [91.6152, 81.0744],
              ],
            ],
            [
              [
                [89.7209, 77.1344],
                [89.3885, 77.0937],
                [89.2568, 77.2216],
                [89.7042, 77.3474],
                [89.7209, 77.1344],
              ],
            ],
            [
              [
                [88.9291, 77.2058],
                [89.0054, 76.8986],
                [88.6801, 76.9955],
                [88.5976, 77.1375],
                [88.9291, 77.2058],
              ],
            ],
            [
              [
                [86.4772, 75.1964],
                [86.178, 75.183],
                [86.0744, 75.2832],
                [86.2189, 75.3963],
                [86.5524, 75.3543],
                [86.4772, 75.1964],
              ],
            ],
            [
              [
                [85.79, 74.9463],
                [85.6884, 74.6624],
                [85.4114, 74.769],
                [85.4423, 74.9309],
                [85.79, 74.9463],
              ],
            ],
            [
              [
                [85.2905, 74.9815],
                [85.2707, 74.6556],
                [85.0063, 74.6946],
                [85.0166, 74.9102],
                [85.2905, 74.9815],
              ],
            ],
            [
              [
                [82.7731, 77.3925],
                [82.4151, 77.3925],
                [82.3052, 77.5101],
                [82.6754, 77.6375],
                [82.7731, 77.3925],
              ],
            ],
            [
              [
                [82.1842, 75.3675],
                [82.2128, 75.0836],
                [81.8092, 75.3043],
                [81.8714, 75.4135],
                [82.1842, 75.3675],
              ],
            ],
            [
              [
                [80.1964, 80.8439],
                [79.2602, 80.818],
                [79.2016, 80.8819],
                [79.3042, 80.9469],
                [80.1737, 80.9568],
                [80.1964, 80.8439],
              ],
            ],
            [
              [
                [79.5228, 72.7714],
                [79.0944, 72.7084],
                [78.7097, 72.8399],
                [79.0944, 73.0877],
                [79.3465, 73.0564],
                [79.5228, 72.7714],
              ],
            ],
            [
              [
                [77.0163, 79.6159],
                [77.0309, 79.4465],
                [76.6165, 79.456],
                [76.5982, 79.5591],
                [76.6892, 79.6457],
                [77.0163, 79.6159],
              ],
            ],
            [
              [
                [74.7877, 72.8876],
                [74.4961, 72.8503],
                [74.1352, 72.9955],
                [74.3164, 73.1244],
                [74.6343, 73.1095],
                [74.7877, 72.8876],
              ],
            ],
            [
              [
                [70.8998, 73.1102],
                [70.3531, 73.0088],
                [69.9009, 73.0347],
                [69.97, 73.3898],
                [70.7916, 73.4971],
                [71.2866, 73.4324],
                [71.4738, 73.2462],
                [71.44, 73.154],
                [70.8998, 73.1102],
              ],
            ],
            [
              [
                [60.6482, 75.0826],
                [60.5357, 74.9811],
                [57.9374, 74.0185],
                [57.6462, 73.6944],
                [56.0805, 73.0383],
                [56.0111, 72.8035],
                [55.5047, 72.4354],
                [55.4097, 72.0392],
                [55.5679, 71.7391],
                [56.2, 71.2126],
                [57.3108, 70.7434],
                [57.2587, 70.5796],
                [53.9065, 70.7669],
                [53.3653, 71.2894],
                [52.9667, 71.0505],
                [51.5364, 71.7143],
                [51.7052, 72.0557],
                [52.631, 72.3382],
                [52.802, 72.53],
                [52.7288, 72.7929],
                [54.0632, 73.4463],
                [54.1002, 73.8192],
                [55.755, 74.4466],
                [56.3957, 74.9097],
                [56.3563, 75.0068],
                [55.9842, 74.9811],
                [55.8588, 75.1311],
                [56.3525, 75.1799],
                [56.5068, 74.9907],
                [56.9112, 75.3038],
                [57.5774, 75.3618],
                [58.9175, 75.9046],
                [61.282, 76.272],
                [64.7371, 76.39],
                [66.3923, 76.818],
                [67.9314, 77.0007],
                [68.5623, 76.9228],
                [68.9223, 76.6995],
                [68.754, 76.4897],
                [67.9314, 76.2308],
                [62.1638, 75.4412],
                [60.6482, 75.0826],
              ],
            ],
            [
              [
                [64.7421, 80.7443],
                [63.2269, 80.6433],
                [63.0105, 80.7734],
                [63.0533, 80.8217],
                [64.7421, 81.1801],
                [65.256, 81.1284],
                [65.2985, 80.8656],
                [64.7421, 80.7443],
              ],
            ],
            [
              [
                [63.5888, 81.5908],
                [62.9418, 81.5651],
                [62.8257, 81.6486],
                [62.8818, 81.7034],
                [63.5866, 81.703],
                [63.5888, 81.5908],
              ],
            ],
            [
              [
                [61.9379, 80.6744],
                [61.0955, 80.383],
                [59.5099, 80.4518],
                [59.3552, 80.5111],
                [59.5099, 80.7063],
                [60.5084, 80.8078],
                [60.4005, 81.0167],
                [61.2035, 81.1108],
                [61.4511, 81.0533],
                [61.3115, 80.8546],
                [61.9268, 80.7792],
                [61.9379, 80.6744],
              ],
            ],
            [
              [
                [59.8616, 80.0555],
                [59.8687, 79.9288],
                [59.0246, 79.9528],
                [58.971, 80.0168],
                [59.1336, 80.122],
                [59.8616, 80.0555],
              ],
            ],
            [
              [
                [59.3789, 81.4929],
                [59.2856, 81.2131],
                [58.9052, 81.3833],
                [58.9567, 81.4437],
                [59.3789, 81.4929],
              ],
            ],
            [
              [
                [59.2392, 81.7672],
                [58.7185, 81.619],
                [58.6887, 81.7239],
                [58.1947, 81.6835],
                [58.0247, 81.7496],
                [58.0666, 81.8239],
                [59.1947, 81.8522],
                [59.2392, 81.7672],
              ],
            ],
            [
              [
                [57.887, 81.1628],
                [57.9152, 81.0628],
                [58.6549, 81.1059],
                [58.8513, 80.8092],
                [57.9537, 80.7118],
                [57.9441, 80.608],
                [58.6928, 80.3823],
                [58.693, 80.2805],
                [58.1437, 80.1125],
                [57.2006, 80.2366],
                [56.5837, 80.0651],
                [56.147, 80.0903],
                [55.9987, 80.3252],
                [56.5837, 80.3169],
                [57.2954, 80.5415],
                [57.1448, 80.6585],
                [56.5837, 80.6117],
                [54.8218, 80.7516],
                [54.6711, 81.0825],
                [55.2488, 81.0339],
                [55.3523, 80.8959],
                [56.1414, 81.0825],
                [56.5288, 81.1034],
                [56.4204, 81.3017],
                [56.5837, 81.3946],
                [57.887, 81.1628],
              ],
            ],
            [
              [
                [58.4195, 81.49],
                [58.3478, 81.3594],
                [57.8258, 81.3603],
                [57.6595, 81.4975],
                [57.719, 81.5521],
                [58.4195, 81.49],
              ],
            ],
            [
              [
                [56.0887, 81.2991],
                [56.074, 81.1771],
                [55.6238, 81.193],
                [55.5703, 81.2547],
                [55.6648, 81.3703],
                [56.0887, 81.2991],
              ],
            ],
            [
              [
                [55.3282, 80.4653],
                [55.4145, 80.2406],
                [55.0916, 80.2843],
                [55.0386, 80.3916],
                [54.467, 80.3788],
                [54.3835, 80.1556],
                [54.0764, 80.0907],
                [54.1666, 80.3402],
                [53.9738, 80.5357],
                [54.0094, 80.5914],
                [55.3282, 80.4653],
              ],
            ],
            [
              [
                [53.4413, 80.3596],
                [53.6411, 80.2738],
                [53.566, 80.1398],
                [52.9392, 80.1428],
                [52.9005, 80.1008],
                [52.1956, 80.2484],
                [52.2326, 80.3477],
                [52.6935, 80.3135],
                [53.1959, 80.3892],
                [53.2734, 80.668],
                [53.4595, 80.618],
                [53.4413, 80.3596],
              ],
            ],
            [
              [
                [50.9457, 80.8918],
                [50.9457, 80.7832],
                [51.3322, 80.7871],
                [51.3624, 80.6661],
                [50.9457, 80.5421],
                [48.8848, 80.3571],
                [48.2444, 80.1193],
                [47.9053, 80.1014],
                [47.634, 80.3309],
                [47.743, 80.4806],
                [48.2279, 80.5309],
                [48.1, 80.7261],
                [48.2559, 80.8315],
                [48.5067, 80.5576],
                [48.879, 80.5031],
                [49.323, 80.5893],
                [49.6696, 80.8746],
                [50.5486, 80.7725],
                [50.9457, 80.8918],
              ],
            ],
            [
              [
                [51.2966, 80.0142],
                [51.2119, 79.9097],
                [50.7875, 79.9172],
                [50.7326, 79.9781],
                [50.9434, 80.105],
                [51.2966, 80.0142],
              ],
            ],
            [
              [
                [50.7839, 81.0254],
                [50.5768, 80.9693],
                [50.408, 81.1223],
                [50.464, 81.2271],
                [50.7732, 81.2173],
                [50.7839, 81.0254],
              ],
            ],
            [
              [
                [50.2024, 80.0387],
                [49.8618, 80.031],
                [49.696, 80.1646],
                [50.1126, 80.2555],
                [50.2024, 80.0387],
              ],
            ],
            [
              [
                [50.0416, 69.0698],
                [49.0204, 68.6767],
                [48.3887, 68.7546],
                [48.2164, 69.084],
                [48.544, 69.3951],
                [49.1407, 69.4946],
                [50.0404, 69.171],
                [50.0416, 69.0698],
              ],
            ],
            [
              [
                [47.4145, 80.2039],
                [46.8957, 80.1688],
                [46.8642, 80.3009],
                [47.3693, 80.3755],
                [47.4145, 80.2039],
              ],
            ],
            [
              [
                [47.4016, 80.604],
                [46.3208, 80.4513],
                [45.4918, 80.5364],
                [45.4429, 80.592],
                [45.4946, 80.6449],
                [47.4042, 80.7419],
                [47.4016, 80.604],
              ],
            ],
            [
              [
                [36.0347, 65.0758],
                [35.7619, 64.9198],
                [35.5474, 65.0203],
                [35.7619, 65.2178],
                [36.0347, 65.0758],
              ],
            ],
            [
              [
                [22.7921, 54.3634],
                [21.5593, 54.3225],
                [20.3135, 54.4022],
                [20.1365, 54.4162],
                [19.8038, 54.4424],
                [20.1243, 54.5992],
                [20.0733, 54.9187],
                [21.0449, 54.9481],
                [21.2735, 55.2457],
                [21.3903, 55.2694],
                [21.6511, 55.18],
                [22.589, 55.0703],
                [22.8215, 54.8264],
                [22.7921, 54.3634],
              ],
            ],
            [
              [
                [-174.767, 66.6963],
                [-174.3756, 66.4825],
                [-174.1416, 66.5499],
                [-173.8895, 67.0173],
                [-173.3363, 67.0735],
                [-171.6973, 66.8848],
                [-170.0108, 66.0919],
                [-171.0349, 65.5597],
                [-172.1842, 65.474],
                [-172.3177, 64.8106],
                [-172.6108, 64.6866],
                [-172.6615, 64.4123],
                [-173.0582, 64.2992],
                [-175.4565, 64.8842],
                [-176.2657, 65.445],
                [-178.3359, 65.5331],
                [-178.868, 66.1801],
                [-179.5633, 66.018],
                [-179.4817, 65.5034],
                [-180, 65.0276],
                [-180, 65.213],
                [-180, 65.3972],
                [-180, 65.5801],
                [-180, 65.7618],
                [-180, 65.9421],
                [-180, 66.1212],
                [-180, 66.2991],
                [-180, 66.4757],
                [-180, 66.5336],
                [-180, 66.651],
                [-180, 66.8251],
                [-180, 66.998],
                [-180, 67.1697],
                [-180, 67.3401],
                [-180, 67.5094],
                [-180, 67.6774],
                [-180, 67.8443],
                [-180, 68.01],
                [-180, 68.1745],
                [-180, 68.2648],
                [-180, 68.3378],
                [-180, 68.5],
                [-180, 68.661],
                [-180, 68.8208],
                [-180, 68.9795],
                [-175.354, 67.6315],
                [-174.9293, 67.3214],
                [-174.767, 66.6963],
              ],
            ],
            [
              [
                [-178.2836, 71.2987],
                [-179.1037, 70.9697],
                [-180, 70.9748],
                [-179.9949, 71.5296],
                [-178.6318, 71.5065],
                [-178.2836, 71.2987],
              ],
            ],
          ],
        },
        properties: { name: "Russian Federation" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [169.0654, -12.2911],
                [168.6567, -12.4646],
                [168.582, -12.3865],
                [168.808, -12.1342],
                [169.0654, -12.2911],
              ],
            ],
            [
              [
                [166.0288, -10.6189],
                [165.8587, -10.9636],
                [165.6911, -10.7777],
                [165.7618, -10.579],
                [166.0288, -10.6189],
              ],
            ],
            [
              [
                [162.0684, -10.5134],
                [162.2098, -10.7511],
                [161.7851, -10.7345],
                [161.2908, -10.3389],
                [161.2908, -10.2071],
                [162.0684, -10.5134],
              ],
            ],
            [
              [
                [161.664, -9.605],
                [161.4358, -9.8238],
                [161.3176, -9.5469],
                [161.4384, -9.405],
                [161.664, -9.605],
              ],
            ],
            [
              [
                [161.3, -9.3743],
                [161.2026, -9.4169],
                [160.7174, -8.8216],
                [160.6496, -8.4101],
                [160.7314, -8.3273],
                [160.9764, -8.6162],
                [160.947, -8.8216],
                [161.1445, -8.9869],
                [161.3, -9.3743],
              ],
            ],
            [
              [
                [159.7096, -9.3089],
                [159.9623, -9.4305],
                [160.331, -9.4158],
                [160.71, -9.7403],
                [160.6676, -9.8332],
                [159.8791, -9.7864],
                [159.5777, -9.5018],
                [159.7096, -9.3089],
              ],
            ],
            [
              [
                [160.3505, -11.6083],
                [160.2583, -11.7928],
                [159.9092, -11.4825],
                [160.0953, -11.4172],
                [160.3505, -11.6083],
              ],
            ],
            [
              [
                [159.7778, -8.3268],
                [159.6281, -8.4461],
                [159.1383, -8.1378],
                [158.5854, -7.7388],
                [158.5043, -7.6262],
                [158.5855, -7.5797],
                [159.6142, -8.1378],
                [159.7778, -8.3268],
              ],
            ],
            [
              [
                [159.308, -8.9274],
                [159.3881, -9.2218],
                [159.0956, -9.2034],
                [159.0744, -8.9455],
                [159.308, -8.9274],
              ],
            ],
            [
              [
                [157.1557, -7.9031],
                [157.2373, -8.1869],
                [157.4836, -8.0224],
                [157.8821, -8.3807],
                [158.1822, -8.5675],
                [158.2467, -8.8039],
                [157.9055, -8.7165],
                [157.7783, -8.5675],
                [157.0243, -8.24],
                [156.9471, -7.974],
                [157.1557, -7.9031],
              ],
            ],
            [
              [
                [158.0137, -7.5081],
                [157.634, -7.6184],
                [157.5783, -7.4477],
                [157.8047, -7.3086],
                [158.0137, -7.5081],
              ],
            ],
            [
              [
                [157.528, -7.3447],
                [157.4848, -7.4465],
                [157.2472, -7.3671],
                [156.412, -6.7291],
                [156.5461, -6.6125],
                [157.095, -6.9582],
                [157.1494, -7.1226],
                [157.528, -7.3447],
              ],
            ],
            [
              [
                [156.7189, -7.9385],
                [156.5763, -7.9495],
                [156.4976, -7.6445],
                [156.6205, -7.5695],
                [156.7945, -7.7328],
                [156.7189, -7.9385],
              ],
            ],
            [
              [
                [155.9426, -7.1688],
                [155.8412, -7.4957],
                [155.5308, -7.3838],
                [155.435, -7.6161],
                [155.2505, -7.5775],
                [155.1611, -7.3125],
                [155.5058, -7.3295],
                [155.9426, -7.1688],
              ],
            ],
          ],
        },
        properties: { name: "Solomon Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [55.6578, -4.6765],
                [55.65, -4.8496],
                [55.1718, -4.5707],
                [55.3526, -4.4859],
                [55.6578, -4.6765],
              ],
            ],
            [
              [
                [53.149, -6.3584],
                [52.8456, -6.3803],
                [53.0169, -6.047],
                [53.2077, -6.1346],
                [53.149, -6.3584],
              ],
            ],
            [
              [
                [46.4807, -9.4728],
                [46.1499, -9.5654],
                [46.083, -9.3353],
                [46.4023, -9.2874],
                [46.4807, -9.4728],
              ],
            ],
            [
              [
                [-63.4882, 15.5918],
                [-63.5773, 15.4115],
                [-63.7901, 15.7552],
                [-63.5773, 15.8927],
                [-63.4882, 15.5918],
              ],
            ],
          ],
        },
        properties: { name: "Seychelles" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [38.5696, 18.005],
              [38.2556, 17.6008],
              [37.0295, 17.0223],
              [36.8653, 16.1186],
              [36.4673, 15.1054],
              [36.5603, 14.2577],
              [36.1342, 12.8315],
              [35.6227, 12.5064],
              [35.2153, 11.9187],
              [34.8021, 10.8052],
              [34.4181, 10.6735],
              [34.1132, 9.4986],
              [33.9183, 9.5986],
              [33.8946, 10.1882],
              [33.298, 10.7159],
              [33.1567, 11.5649],
              [33.2446, 12.1394],
              [32.9459, 12.2235],
              [32.3227, 11.8954],
              [32.4186, 11.0739],
              [32.1035, 10.7159],
              [31.2168, 9.7703],
              [30.8069, 9.7642],
              [29.9635, 10.2429],
              [29.6187, 10.097],
              [29.4717, 9.7791],
              [28.977, 9.6412],
              [28.9293, 10.1061],
              [27.9298, 10.1398],
              [27.8156, 10.0193],
              [27.9072, 9.6102],
              [26.5343, 9.5362],
              [25.8244, 10.3885],
              [25.2549, 10.3366],
              [24.7697, 9.7561],
              [24.5255, 8.9136],
              [24.2234, 8.6422],
              [23.5994, 8.8147],
              [23.608, 9.9187],
              [22.8755, 10.9314],
              [22.9543, 11.2968],
              [22.6063, 11.6278],
              [22.3889, 12.6326],
              [21.9575, 12.6808],
              [21.8593, 12.8394],
              [22.2317, 13.3473],
              [22.1293, 13.8043],
              [22.4859, 14.1342],
              [22.4535, 14.5476],
              [22.9335, 15.1702],
              [23.0384, 15.6361],
              [23.941, 15.7526],
              [23.9993, 16.1186],
              [23.9995, 17.1753],
              [24, 19.5],
              [24.0666, 19.9725],
              [24.9765, 20.0689],
              [25, 21.6026],
              [25, 22],
              [33.1826, 22.0017],
              [33.6406, 21.7398],
              [33.9661, 21.7899],
              [34.0865, 22.0025],
              [34.5937, 22.003],
              [36.8825, 22.0051],
              [36.9175, 21.6026],
              [37.1381, 21.2085],
              [37.4135, 18.905],
              [38.5696, 18.005],
            ],
          ],
        },
        properties: { name: "Sudan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [20.5486, 69.06],
                [23.4078, 68.023],
                [23.8453, 66.837],
                [23.7656, 66.2739],
                [24.1551, 65.816],
                [24.1623, 65.6931],
                [22.922, 65.6743],
                [22.3981, 65.3894],
                [22.0983, 65.442],
                [21.5436, 65.0631],
                [21.1527, 64.7698],
                [21.2899, 64.547],
                [21.2205, 64.2262],
                [20.1365, 63.6328],
                [19.2865, 63.4697],
                [18.4235, 63.0127],
                [18.3811, 62.8492],
                [17.4629, 62.3561],
                [17.4893, 62.137],
                [17.3451, 61.84],
                [17.4842, 61.6791],
                [17.1739, 61.1723],
                [17.3726, 60.6543],
                [18.6903, 60.2312],
                [19.0245, 59.7064],
                [18.5904, 59.049],
                [17.913, 58.8055],
                [17.5724, 58.9509],
                [17.3896, 58.7121],
                [16.756, 58.6265],
                [16.9569, 58.1695],
                [16.6667, 57.9961],
                [16.7278, 57.5476],
                [16.5007, 57.115],
                [16.7676, 56.7915],
                [16.5072, 56.3617],
                [16.2846, 56.5734],
                [16.0499, 56.3219],
                [15.7826, 56.1053],
                [14.5464, 56.0613],
                [14.1554, 55.4493],
                [13.1754, 55.3938],
                [12.6635, 56.0409],
                [12.8993, 56.449],
                [11.9229, 57.5622],
                [11.4977, 57.984],
                [11.195, 58.652],
                [11.2142, 58.9726],
                [11.4563, 58.99],
                [11.622, 58.9296],
                [11.7167, 59.049],
                [11.8262, 59.2378],
                [11.927, 59.7905],
                [11.8397, 59.8408],
                [12.1495, 59.8882],
                [12.4381, 60.0782],
                [12.5685, 60.4347],
                [12.295, 60.9869],
                [12.6702, 61.056],
                [12.6933, 61.4259],
                [12.1824, 61.7672],
                [12.2994, 62.2675],
                [12.2547, 62.331],
                [12.0525, 63.1834],
                [12.1495, 63.4182],
                [12.2057, 63.6188],
                [12.5221, 63.8509],
                [13.1489, 64.0748],
                [14.0136, 64.0734],
                [14.1092, 64.383],
                [13.8381, 64.547],
                [13.7303, 64.6122],
                [14.326, 65.1189],
                [14.6273, 66.0639],
                [15.454, 66.3452],
                [15.4153, 66.5045],
                [16.3425, 67.0642],
                [16.168, 67.4289],
                [16.7204, 67.8874],
                [17.2661, 68.0913],
                [17.6849, 68.0325],
                [18.0706, 68.1372],
                [18.1259, 68.5365],
                [19.9221, 68.3845],
                [20.3346, 69.0186],
                [20.5486, 69.06],
              ],
            ],
            [
              [
                [18.7588, 57.2794],
                [18.2025, 56.9712],
                [18.2209, 57.1417],
                [18.0973, 57.2638],
                [18.1911, 57.3863],
                [18.1109, 57.5336],
                [18.4614, 57.8163],
                [18.7354, 57.9249],
                [19.0979, 57.9367],
                [19.0105, 57.7422],
                [18.8075, 57.6524],
                [18.7588, 57.2794],
              ],
            ],
          ],
        },
        properties: { name: "Sweden" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [103.723, 1.3309],
              [103.8895, 1.394],
              [103.9327, 1.3452],
              [103.9091, 1.2386],
              [103.832, 1.2386],
              [103.723, 1.3309],
            ],
          ],
        },
        properties: { name: "Singapore" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-5.4914, -15.9213],
                [-5.8189, -16.1433],
                [-5.943, -16.0496],
                [-5.6955, -15.7865],
                [-5.4914, -15.9213],
              ],
            ],
            [
              [
                [-9.7092, -40.2449],
                [-9.7302, -40.4051],
                [-10.0662, -40.3971],
                [-10.0977, -40.2569],
                [-9.8982, -40.1569],
                [-9.7092, -40.2449],
              ],
            ],
            [
              [
                [-14.1234, -7.9509],
                [-14.4647, -8.1134],
                [-14.5561, -7.9362],
                [-14.3329, -7.7822],
                [-14.1234, -7.9509],
              ],
            ],
          ],
        },
        properties: { name: "Saint Helena, Ascension and Tristan Da Cunha" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [16.5968, 46.4759],
              [16.2421, 46.4901],
              [16.3015, 46.3783],
              [15.8735, 46.2793],
              [15.7915, 46.2593],
              [15.6273, 46.086],
              [15.7064, 45.9753],
              [15.4044, 45.7927],
              [15.3313, 45.7629],
              [15.2264, 45.4271],
              [14.57, 45.6729],
              [14.3909, 45.5034],
              [14.1182, 45.4812],
              [14.1113, 45.4823],
              [13.6513, 45.4541],
              [13.7221, 45.595],
              [13.836, 45.5875],
              [13.9032, 45.6358],
              [13.7847, 45.7428],
              [13.5962, 45.8079],
              [13.5971, 45.8195],
              [13.4969, 46.0513],
              [13.511, 46.3484],
              [13.684, 46.4375],
              [13.7142, 46.5227],
              [14.4345, 46.4429],
              [14.5652, 46.3725],
              [14.6746, 46.4507],
              [15.0651, 46.6521],
              [15.402, 46.6535],
              [15.65, 46.7058],
              [15.7864, 46.7075],
              [15.9962, 46.8354],
              [16.1138, 46.8691],
              [16.3708, 46.7222],
              [16.5968, 46.4759],
            ],
          ],
        },
        properties: { name: "Slovenia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [43.2137, -22.1543],
              [43.4715, -21.6025],
              [44.427, -19.9892],
              [44.2552, -18.8432],
              [44.0486, -18.3599],
              [43.9764, -17.4492],
              [44.3868, -16.7473],
              [44.4775, -16.2465],
              [44.9906, -16.1579],
              [46.1213, -15.7139],
              [47.4311, -14.7568],
              [47.5788, -14.8911],
              [47.9587, -13.6829],
              [48.2476, -13.7471],
              [48.841, -13.2709],
              [48.8575, -12.48],
              [49.009, -12.2361],
              [49.2934, -12.1746],
              [49.8209, -12.8145],
              [50.0402, -13.539],
              [50.4555, -15.3578],
              [50.1955, -15.928],
              [49.8548, -15.4876],
              [49.6896, -15.5414],
              [49.7518, -16.1579],
              [49.8396, -16.4548],
              [49.4612, -17.2579],
              [49.4847, -17.8103],
              [49.1547, -18.8432],
              [48.2422, -21.6025],
              [47.591, -23.7626],
              [47.0338, -24.9599],
              [45.2664, -25.5843],
              [44.2372, -25.1692],
              [43.7044, -24.3522],
              [43.7058, -23.435],
              [43.2137, -22.1543],
            ],
          ],
        },
        properties: { name: "Madagascar" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [171.7561, 6.895],
                [171.546, 6.8706],
                [171.3904, 7.1192],
                [171.6032, 7.2327],
                [171.7561, 6.895],
              ],
            ],
            [
              [
                [170.1342, 9.2736],
                [169.9945, 9.2386],
                [169.9067, 9.4378],
                [170.0462, 9.5249],
                [170.3432, 9.491],
                [170.1342, 9.2736],
              ],
            ],
            [
              [
                [169.7075, 5.813],
                [169.5108, 5.702],
                [169.4636, 5.8357],
                [169.672, 6.1153],
                [169.8185, 6.0168],
                [169.7075, 5.813],
              ],
            ],
            [
              [
                [168.9821, 7.4149],
                [168.8497, 7.3168],
                [168.6817, 7.4579],
                [168.9762, 7.7048],
                [169.057, 7.6792],
                [168.9821, 7.4149],
              ],
            ],
            [
              [
                [168.8662, 4.5601],
                [168.661, 4.4785],
                [168.5211, 4.6571],
                [168.587, 4.7577],
                [168.8511, 4.7716],
                [168.8662, 4.5601],
              ],
            ],
            [
              [
                [168.2683, 5.7334],
                [168.1779, 5.4402],
                [168.0267, 5.4711],
                [167.9382, 5.7812],
                [168.2683, 5.7334],
              ],
            ],
            [
              [
                [166.8366, 11.3453],
                [166.4795, 11.3043],
                [166.5503, 11.5014],
                [166.7992, 11.6374],
                [166.8366, 11.3453],
              ],
            ],
          ],
        },
        properties: { name: "Marshall Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [22.9276, 41.3385],
              [22.8792, 41.3407],
              [22.3321, 41.1203],
              [22.2162, 41.1705],
              [21.9294, 41.1004],
              [21.7874, 40.9311],
              [20.9802, 40.8557],
              [20.8378, 40.9277],
              [20.5977, 41.092],
              [20.5017, 41.3172],
              [20.5578, 41.5819],
              [20.5607, 41.7331],
              [20.5629, 41.8461],
              [20.5943, 41.8773],
              [20.9223, 42.1087],
              [21.2127, 42.1107],
              [21.443, 42.2349],
              [21.5869, 42.2628],
              [22.3602, 42.3112],
              [22.5104, 42.1552],
              [22.6425, 42.1059],
              [22.8672, 42.0222],
              [22.9254, 41.7331],
              [22.9683, 41.5198],
              [22.9276, 41.3385],
            ],
          ],
        },
        properties: { name: "North Macedonia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [4.243, 19.1389],
              [4.2092, 17.0347],
              [3.8653, 15.7915],
              [3.407, 15.3552],
              [1.4597, 15.2725],
              [0.9463, 14.9749],
              [0.7453, 14.9791],
              [0.2293, 14.9897],
              [-0.721, 15.0452],
              [-1.7859, 14.5221],
              [-1.8933, 14.4694],
              [-2.1123, 14.2218],
              [-2.6484, 14.1311],
              [-3.4208, 13.2926],
              [-4.1467, 13.1869],
              [-4.5928, 12.2275],
              [-5.2335, 11.6898],
              [-5.5189, 10.4333],
              [-6.0283, 10.3127],
              [-6.3759, 10.6631],
              [-6.9796, 10.2777],
              [-7.5603, 10.415],
              [-7.9785, 10.1751],
              [-8.5566, 11.0662],
              [-8.5611, 11.3615],
              [-9.0923, 12.3591],
              [-10.6468, 11.9712],
              [-11.3138, 12.1429],
              [-11.3795, 12.4136],
              [-11.4311, 13.0175],
              [-11.864, 13.4525],
              [-12.2392, 14.7594],
              [-11.8803, 14.8646],
              [-11.5868, 15.5771],
              [-10.9241, 15.1701],
              [-10.4675, 15.4386],
              [-5.6391, 15.5124],
              [-5.3816, 16.0758],
              [-5.6236, 16.7004],
              [-6.1671, 21.5201],
              [-6.5279, 24.9366],
              [-4.8333, 24.9976],
              [-3.3739, 24.0562],
              [-2.7863, 23.6772],
              [-1.8933, 23.1012],
              [-1.7859, 23.0319],
              [0.5578, 21.5201],
              [0.7453, 21.3959],
              [0.9463, 21.2628],
              [1.235, 20.7351],
              [1.8141, 20.3039],
              [3.182, 19.8421],
              [3.2945, 19.0379],
              [3.407, 19.0499],
              [3.5631, 19.0665],
              [4.243, 19.1389],
            ],
          ],
        },
        properties: { name: "Mali" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [101.1432, 21.5641],
              [100.9577, 21.3822],
              [100.0853, 20.3527],
              [99.5946, 20.2881],
              [98.8831, 19.8187],
              [98.0265, 19.6724],
              [97.8338, 19.3888],
              [97.7543, 18.6525],
              [97.5345, 18.396],
              [97.7527, 17.8227],
              [98.8009, 16.2881],
              [98.255, 14.9941],
              [99.0747, 13.843],
              [99.161, 13.1048],
              [99.6209, 11.8668],
              [98.7537, 10.3893],
              [98.5412, 10.3768],
              [98.6717, 11.3316],
              [98.5438, 12.0993],
              [98.128, 12.304],
              [98.5626, 12.6711],
              [98.5738, 13.0363],
              [98.1283, 13.8448],
              [97.6984, 15.8071],
              [97.1599, 16.8995],
              [96.9675, 16.9794],
              [95.4334, 15.8209],
              [94.4348, 15.964],
              [94.2838, 16.3627],
              [94.5297, 17.5142],
              [94.3263, 18.3094],
              [93.955, 18.8288],
              [93.715, 18.6498],
              [93.5355, 18.7713],
              [93.7593, 19.0141],
              [93.2491, 19.9619],
              [93.0232, 19.9866],
              [92.697, 20.4491],
              [92.2021, 21.1507],
              [92.3081, 21.3804],
              [92.6238, 21.4616],
              [92.6068, 21.9712],
              [93.1232, 22.3714],
              [93.3872, 23.9016],
              [94.0361, 23.9215],
              [94.2381, 24.0841],
              [95.203, 26.5525],
              [96.1868, 27.1873],
              [96.8989, 27.2897],
              [97.0575, 27.7441],
              [97.3443, 27.9963],
              [97.3471, 28.2081],
              [97.6607, 28.4374],
              [98.0277, 28.1945],
              [98.3001, 27.67],
              [98.6505, 27.4662],
              [98.7586, 26.6967],
              [98.6174, 25.8964],
              [97.6323, 24.7947],
              [97.6624, 23.9718],
              [98.7357, 24.0311],
              [98.9544, 23.2538],
              [99.4242, 22.9783],
              [99.3116, 22.1851],
              [99.8935, 22.0023],
              [100.2985, 21.5455],
              [100.9638, 21.6867],
              [101.1432, 21.5641],
            ],
          ],
        },
        properties: { name: "Myanmar/Burma" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [116.7209, 49.8498],
              [115.726, 48.3132],
              [115.7462, 47.8633],
              [117.3447, 47.7443],
              [117.8818, 47.99],
              [118.4115, 47.9874],
              [119.5494, 47.2549],
              [119.7421, 46.8031],
              [118.2867, 46.7064],
              [116.9594, 46.3931],
              [115.8268, 45.5901],
              [114.6043, 45.3288],
              [113.6553, 44.836],
              [111.8923, 44.9111],
              [111.5444, 44.3827],
              [111.7456, 43.6738],
              [110.2689, 42.7433],
              [109.382, 42.4713],
              [107.315, 42.3929],
              [106.4412, 42.1059],
              [105.3061, 41.7331],
              [104.9939, 41.6306],
              [104.7319, 41.7331],
              [103.412, 41.8982],
              [101.7629, 42.3929],
              [101.5726, 42.45],
              [100.937, 42.6407],
              [99.3602, 42.5821],
              [96.5054, 42.8216],
              [95.0889, 44.3062],
              [93.6971, 44.8837],
              [92.697, 45.042],
              [90.9154, 45.324],
              [90.7868, 45.5843],
              [90.9625, 46.1517],
              [90.8926, 46.7843],
              [90.0071, 47.8003],
              [89.1233, 48.0475],
              [88.8093, 48.1353],
              [88.164, 48.5182],
              [87.825, 49.1833],
              [88.2913, 49.48],
              [89.1233, 49.6048],
              [89.5277, 49.6654],
              [90.0071, 50.0358],
              [92.4372, 50.809],
              [92.697, 50.77],
              [94.1016, 50.5591],
              [94.7987, 50.0463],
              [97.3304, 49.8163],
              [98.1961, 50.2828],
              [97.9549, 51.1391],
              [98.9786, 52.0482],
              [101.9481, 51.3734],
              [102.4373, 50.5965],
              [103.5057, 50.2012],
              [105.4152, 50.4519],
              [106.5203, 50.3292],
              [107.8553, 49.9138],
              [108.8074, 49.3345],
              [110.9163, 49.2007],
              [112.887, 49.5712],
              [114.3649, 50.236],
              [115.4261, 49.9355],
              [116.2426, 49.9903],
              [116.7209, 49.8498],
            ],
          ],
        },
        properties: { name: "Mongolia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [113.5331, 22.1856],
              [113.5509, 22.2145],
              [113.5533, 22.2032],
              [113.5643, 22.1943],
              [113.5648, 22.1893],
              [113.559, 22.1867],
              [113.5504, 22.1841],
              [113.5331, 22.1856],
            ],
          ],
        },
        properties: { name: "Macau" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [145.5548, 14.967],
              [145.6383, 15.2891],
              [145.8721, 15.3231],
              [145.7153, 14.9178],
              [145.5548, 14.967],
            ],
          ],
        },
        properties: { name: "Northern Mariana Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-4.8333, 24.9976],
              [-6.5279, 24.9366],
              [-6.1671, 21.5201],
              [-5.6236, 16.7004],
              [-5.3816, 16.0758],
              [-5.6391, 15.5124],
              [-10.4675, 15.4386],
              [-10.9241, 15.1701],
              [-11.5868, 15.5771],
              [-11.8803, 14.8646],
              [-12.2392, 14.7594],
              [-13.3922, 16.0124],
              [-14.3668, 16.5898],
              [-16.0689, 16.4984],
              [-16.4217, 16.2162],
              [-16.4, 16.3836],
              [-16.4703, 16.1801],
              [-16.5064, 16.0758],
              [-16.0504, 17.922],
              [-16.1516, 18.7368],
              [-16.4264, 19.3425],
              [-16.2798, 20.1715],
              [-16.8251, 20.9275],
              [-17.0513, 20.7716],
              [-16.825, 21.3191],
              [-13.0576, 21.3647],
              [-13.0409, 22.8389],
              [-12.022, 23.6542],
              [-11.9441, 25.882],
              [-8.8245, 26.0382],
              [-8.6667, 27.3127],
              [-4.8333, 24.9976],
            ],
          ],
        },
        properties: { name: "Mauritania" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-62.2655, 16.7143],
              [-62.2094, 16.8665],
              [-62.1283, 16.7441],
              [-62.1209, 16.6755],
              [-62.1879, 16.6416],
              [-62.2655, 16.7143],
            ],
          ],
        },
        properties: { name: "Montserrat" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [14.6459, 35.9333],
                [14.4334, 35.717],
                [14.234, 35.8815],
                [14.4119, 36.0669],
                [14.6459, 35.9333],
              ],
            ],
            [
              [
                [14.3482, 36.0308],
                [14.2622, 36.0067],
                [14.1727, 36.0691],
                [14.2307, 36.0879],
                [14.3482, 36.0308],
              ],
            ],
          ],
        },
        properties: { name: "Malta" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [59.9207, -16.6311],
                [59.8305, -16.7145],
                [59.6371, -16.6882],
                [59.5469, -16.5925],
                [59.5469, -16.3777],
                [59.8079, -16.5168],
                [59.9207, -16.6311],
              ],
            ],
            [
              [
                [57.8201, -20.2272],
                [57.6806, -20.4906],
                [57.295, -20.474],
                [57.352, -20.2886],
                [57.6029, -19.9678],
                [57.8201, -20.2272],
              ],
            ],
            [
              [
                [56.6337, -10.3004],
                [56.4859, -10.3535],
                [56.2872, -10.0508],
                [56.5007, -9.9448],
                [56.6337, -10.3004],
              ],
            ],
          ],
        },
        properties: { name: "Mauritius" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [73.71, 2.9097],
                [73.5532, 2.8675],
                [73.532, 3.0986],
                [73.5768, 3.3129],
                [73.7724, 3.1829],
                [73.71, 2.9097],
              ],
            ],
            [
              [
                [73.6138, 1.915],
                [73.5132, 1.897],
                [73.4939, 1.951],
                [73.5489, 2.3858],
                [73.7555, 2.2028],
                [73.6138, 1.915],
              ],
            ],
            [
              [
                [73.5107, 5.8299],
                [73.2603, 5.6292],
                [73.129, 5.8511],
                [73.4245, 6.0007],
                [73.5107, 5.8299],
              ],
            ],
            [
              [
                [73.45, 0.5286],
                [73.2334, 0.3392],
                [73.0536, 0.5433],
                [73.1494, 0.6307],
                [73.4418, 0.6343],
                [73.45, 0.5286],
              ],
            ],
            [
              [
                [73.2809, -0.8992],
                [73.1341, -0.9461],
                [73.1042, -0.8786],
                [72.9709, -0.578],
                [73.2311, -0.578],
                [73.2778, -0.8786],
                [73.2809, -0.8992],
              ],
            ],
            [
              [
                [73.1135, 6.9997],
                [72.9024, 6.8634],
                [72.682, 7.1261],
                [72.8867, 7.2046],
                [73.1135, 6.9997],
              ],
            ],
            [
              [
                [73.0821, 5.4272],
                [72.9394, 5.228],
                [72.8077, 5.509],
                [73.1022, 5.709],
                [73.0821, 5.4272],
              ],
            ],
          ],
        },
        properties: { name: "Maldives" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [34.9596, -11.5733],
              [34.6477, -11.683],
              [34.4272, -12.1589],
              [34.59, -13.2477],
              [35.1871, -13.8221],
              [35.8884, -14.9206],
              [35.7988, -15.8783],
              [35.3313, -16.3299],
              [35.2071, -16.8302],
              [34.6506, -16.4046],
              [34.3592, -15.9208],
              [34.5628, -15.1596],
              [34.4393, -14.5468],
              [33.6496, -14.4564],
              [33.2422, -13.9985],
              [32.8162, -13.5758],
              [33.0256, -12.7461],
              [33.4181, -12.4294],
              [33.2902, -10.9113],
              [33.6324, -10.4637],
              [32.9535, -9.4014],
              [34.1574, -9.6216],
              [34.4913, -10.0245],
              [34.6491, -10.9113],
              [34.9596, -11.5733],
            ],
          ],
        },
        properties: { name: "Malawi" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-86.7243, 20.493],
                [-87.0304, 20.1789],
                [-87.0877, 20.3151],
                [-86.9277, 20.629],
                [-86.7243, 20.493],
              ],
            ],
            [
              [
                [-97.1461, 25.9561],
                [-97.6605, 24.9927],
                [-97.7995, 22.4507],
                [-97.1874, 20.7668],
                [-96.2089, 19.3064],
                [-95.5126, 18.7465],
                [-94.334, 18.242],
                [-92.3658, 18.6267],
                [-91.525, 18.57],
                [-91.0362, 19.0664],
                [-90.7911, 19.3481],
                [-90.1713, 21.0539],
                [-90.0133, 21.0954],
                [-89.6593, 21.1884],
                [-89.435, 21.2474],
                [-88.205, 21.5705],
                [-87.0275, 21.4897],
                [-86.8074, 21.1134],
                [-87.4043, 20.1524],
                [-87.7328, 18.6498],
                [-87.837, 18.4743],
                [-88.1012, 18.6276],
                [-88.2278, 18.4563],
                [-88.3168, 18.4888],
                [-89.147, 17.8095],
                [-89.435, 17.8032],
                [-89.6593, 17.7984],
                [-90.0133, 17.7907],
                [-90.9613, 17.6841],
                [-91.1617, 17.0796],
                [-90.4981, 16.2084],
                [-91.8002, 15.8454],
                [-92.1126, 15.2942],
                [-92.2313, 14.5333],
                [-93.9772, 15.9773],
                [-94.8804, 16.1769],
                [-96.7674, 15.7411],
                [-100.8916, 17.2207],
                [-101.9017, 17.8987],
                [-103.0395, 18.2055],
                [-104.8583, 19.2912],
                [-105.5735, 20.2491],
                [-105.2503, 21.2477],
                [-105.5393, 22.2618],
                [-108.3362, 25.2414],
                [-109.303, 25.748],
                [-109.4406, 26.6009],
                [-110.468, 27.2919],
                [-110.7126, 27.8157],
                [-112.0317, 28.8587],
                [-112.4559, 28.8865],
                [-112.4243, 29.4301],
                [-113.2073, 31.1889],
                [-113.8855, 31.5756],
                [-114.7204, 31.7052],
                [-114.8459, 31.1849],
                [-114.6152, 30.2489],
                [-112.9819, 28.4931],
                [-112.6547, 27.7506],
                [-111.6015, 26.6305],
                [-111.2092, 25.7112],
                [-110.6464, 24.9759],
                [-110.631, 24.3806],
                [-109.8852, 24.029],
                [-109.5568, 23.5705],
                [-109.5387, 23.2777],
                [-109.7247, 23.0591],
                [-110.026, 23.0347],
                [-110.4614, 23.6254],
                [-112.1424, 24.7711],
                [-112.0994, 25.6934],
                [-112.2911, 26.0556],
                [-113.1841, 26.7169],
                [-114.0845, 27.0565],
                [-114.735, 27.6012],
                [-114.1474, 27.8024],
                [-114.1633, 28.5421],
                [-115.6737, 29.8209],
                [-117.1236, 32.5346],
                [-115.002, 32.6693],
                [-110.9234, 31.3386],
                [-108.5185, 31.3352],
                [-107.9988, 31.7599],
                [-106.4688, 31.7164],
                [-104.9579, 30.5767],
                [-104.4209, 29.6204],
                [-103.4137, 29.0808],
                [-103.0936, 29.1084],
                [-102.5338, 29.7138],
                [-101.3814, 29.6713],
                [-100.7311, 29.1117],
                [-100.2721, 28.305],
                [-99.5889, 27.5721],
                [-99.0549, 26.4642],
                [-98.2152, 26.0797],
                [-97.1461, 25.9561],
              ],
            ],
            [
              [
                [-89.5916, 22.6071],
                [-89.7403, 22.3668],
                [-89.9653, 22.3878],
                [-89.835, 22.6266],
                [-89.6579, 22.7248],
                [-89.5916, 22.6071],
              ],
            ],
            [
              [
                [-113.1275, 29.2853],
                [-113.193, 29.0258],
                [-113.5937, 29.4303],
                [-113.4045, 29.5082],
                [-113.3401, 29.2956],
                [-113.1275, 29.2853],
              ],
            ],
            [
              [
                [-115.0844, 28.2245],
                [-115.138, 27.9482],
                [-115.4617, 28.034],
                [-115.1849, 28.3096],
                [-115.0844, 28.2245],
              ],
            ],
          ],
        },
        properties: { name: "Mexico" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [117.8959, 4.1667],
                [117.7554, 4.1668],
                [117.0734, 4.3762],
                [115.8436, 4.3193],
                [115.4801, 3.1051],
                [115.1395, 2.8234],
                [115.1387, 2.4688],
                [114.8113, 2.2131],
                [114.8213, 1.9271],
                [114.5414, 1.4939],
                [113.6416, 1.2735],
                [112.5607, 1.5663],
                [112.1786, 1.2951],
                [111.7875, 0.9963],
                [110.4947, 0.905],
                [109.7189, 1.6007],
                [109.643, 2.076],
                [109.8927, 1.7452],
                [110.931, 1.5752],
                [111.2899, 2.6103],
                [111.3616, 2.7531],
                [112.1786, 2.9376],
                [112.9749, 3.193],
                [114.0715, 4.5889],
                [114.6759, 4.1459],
                [114.8561, 4.647],
                [115.1809, 4.6253],
                [115.1546, 4.9128],
                [115.4643, 5.0522],
                [115.5133, 5.4198],
                [115.9276, 5.7279],
                [116.6864, 6.8434],
                [117.2094, 6.8787],
                [117.5639, 6.5633],
                [117.7781, 5.9958],
                [119.1523, 5.2991],
                [118.9874, 5.0505],
                [118.2168, 4.9102],
                [118.2273, 4.7465],
                [118.6761, 4.4497],
                [117.8959, 4.1667],
              ],
            ],
            [
              [
                [117.4104, 7.2793],
                [117.0352, 7.0492],
                [116.9808, 7.2621],
                [117.1402, 7.3915],
                [117.4104, 7.2793],
              ],
            ],
            [
              [
                [102.091, 6.2409],
                [103.2234, 5.1722],
                [103.4637, 4.5054],
                [103.4749, 2.9794],
                [103.8553, 2.5039],
                [104.241, 1.5689],
                [104.1831, 1.4124],
                [103.7262, 1.4425],
                [103.4985, 1.3149],
                [103.3195, 1.5544],
                [102.1602, 2.2308],
                [101.3433, 2.8563],
                [101.1733, 3.3788],
                [100.6182, 4.2201],
                [100.5967, 4.791],
                [100.4296, 5.2091],
                [100.2362, 5.3984],
                [100.2982, 5.9874],
                [100.1213, 6.4217],
                [100.2294, 6.6479],
                [100.7624, 6.4231],
                [101.0625, 6.1551],
                [101.1972, 5.6487],
                [101.4768, 5.8798],
                [101.8326, 5.8096],
                [102.091, 6.2409],
              ],
            ],
            [
              [
                [99.9522, 6.3149],
                [99.7176, 6.1649],
                [99.6681, 6.3568],
                [99.8637, 6.4716],
                [99.9522, 6.3149],
              ],
            ],
          ],
        },
        properties: { name: "Malaysia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [32.8915, -26.8583],
              [32.1337, -26.835],
              [31.9821, -25.952],
              [31.9765, -24.3034],
              [31.3113, -22.4177],
              [32.1429, -21.5651],
              [32.9546, -19.9683],
              [32.7663, -18.9699],
              [33.0077, -18.2738],
              [33.0194, -17.3795],
              [32.8225, -16.6907],
              [31.1972, -16.04],
              [30.5713, -15.9585],
              [30.4183, -15.6176],
              [30.3073, -14.9824],
              [31.8523, -14.4588],
              [33.2422, -13.9985],
              [33.6496, -14.4564],
              [34.4393, -14.5468],
              [34.5628, -15.1596],
              [34.3592, -15.9208],
              [34.6506, -16.4046],
              [35.2071, -16.8302],
              [35.3313, -16.3299],
              [35.7988, -15.8783],
              [35.8884, -14.9206],
              [35.1871, -13.8221],
              [34.59, -13.2477],
              [34.4272, -12.1589],
              [34.6477, -11.683],
              [34.9596, -11.5733],
              [35.8549, -11.4522],
              [36.4576, -11.7048],
              [37.4237, -11.6577],
              [37.9279, -11.3183],
              [38.5264, -11.3686],
              [39.7605, -10.9113],
              [40.3944, -10.5107],
              [40.5415, -10.5884],
              [40.5174, -10.9113],
              [40.3936, -11.4514],
              [40.6121, -12.3398],
              [40.5476, -13.5827],
              [40.7767, -14.9679],
              [39.9336, -16.3334],
              [39.032, -16.9959],
              [37.2608, -17.7392],
              [36.2902, -18.8289],
              [35.787, -19.0561],
              [34.8284, -19.8782],
              [34.729, -20.4597],
              [34.9977, -20.7831],
              [35.2559, -21.6023],
              [35.5255, -22.3972],
              [35.4795, -24.1018],
              [35.027, -24.6372],
              [33.7149, -25.1251],
              [32.9295, -25.5292],
              [32.6816, -25.8667],
              [32.648, -26.0849],
              [32.9086, -26.4004],
              [32.8915, -26.8583],
            ],
          ],
        },
        properties: { name: "Mozambique" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [23.4299, -17.6345],
              [24.5991, -17.4938],
              [25.2628, -17.7914],
              [24.3074, -18.0188],
              [23.668, -18.4216],
              [23.1849, -18.0102],
              [21.7196, -18.256],
              [21.463, -18.3043],
              [20.9999, -18.3196],
              [20.9995, -20.1422],
              [20.9994, -20.5666],
              [20.9993, -20.8507],
              [20.9992, -21.4074],
              [20.9991, -21.6023],
              [20.9363, -21.9709],
              [20.0143, -22.0939],
              [19.9995, -24.752],
              [19.9434, -28.4086],
              [19.162, -28.9253],
              [18.2081, -28.8908],
              [17.4762, -28.6959],
              [17.32, -28.29],
              [17.0144, -28.0716],
              [16.4556, -28.6347],
              [15.7299, -27.9936],
              [15.254, -27.1958],
              [14.8631, -25.7884],
              [14.8279, -25.004],
              [14.485, -24.1025],
              [14.4948, -22.5568],
              [13.8909, -21.6023],
              [12.5735, -19.1047],
              [11.9214, -18.2655],
              [11.7509, -17.2525],
              [13.2413, -16.9985],
              [14.0264, -17.3778],
              [18.2636, -17.3914],
              [19.0299, -17.8279],
              [21.7196, -17.9666],
              [23.4299, -17.6345],
            ],
          ],
        },
        properties: { name: "Namibia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [172.1671, -22.2252],
                [172.2382, -22.5566],
                [171.9814, -22.5296],
                [171.943, -22.2584],
                [172.1671, -22.2252],
              ],
            ],
            [
              [
                [168.1442, -21.6031],
                [167.9749, -21.6829],
                [167.8474, -21.5987],
                [167.7974, -21.368],
                [168.1463, -21.453],
                [168.1442, -21.6031],
              ],
            ],
            [
              [
                [167.4577, -21.0543],
                [167.3421, -21.1873],
                [167.0069, -20.9174],
                [167.1424, -20.7425],
                [167.303, -20.7355],
                [167.2589, -20.9079],
                [167.4577, -21.0543],
              ],
            ],
            [
              [
                [166.9325, -22.1296],
                [166.9472, -22.3244],
                [166.2347, -22.1604],
                [164.9671, -21.3204],
                [164.1152, -20.265],
                [164.1871, -20.2119],
                [164.5396, -20.3357],
                [166.9325, -22.1296],
              ],
            ],
            [
              [
                [163.6947, -19.9618],
                [163.5126, -19.9923],
                [163.5123, -19.8762],
                [163.7564, -19.5852],
                [163.8436, -19.7765],
                [163.7931, -19.8762],
                [163.6947, -19.9618],
              ],
            ],
            [
              [
                [163.2525, -18.483],
                [163.202, -18.694],
                [162.8579, -18.4238],
                [163.0713, -18.3063],
                [163.2525, -18.483],
              ],
            ],
            [
              [
                [158.6014, -19.9746],
                [158.2371, -20.043],
                [158.1715, -19.9178],
                [158.4364, -19.7314],
                [158.6014, -19.9746],
              ],
            ],
          ],
        },
        properties: { name: "New Caledonia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [14.9984, 22.9972],
              [15.1963, 21.6026],
              [15.9427, 20.3452],
              [15.6327, 19.025],
              [15.5004, 16.9853],
              [14.3535, 15.68],
              [13.5373, 14.4407],
              [13.6346, 13.7107],
              [12.398, 13.123],
              [10.7545, 13.3442],
              [9.3565, 12.8289],
              [7.9448, 13.2833],
              [6.9574, 13.0844],
              [6.3889, 13.5974],
              [5.7132, 13.8248],
              [4.8269, 13.7524],
              [4.184, 13.4108],
              [3.6932, 12.4497],
              [3.5631, 11.7331],
              [3.407, 11.8673],
              [2.8313, 12.3625],
              [2.4326, 12.2297],
              [2.4051, 11.8998],
              [2.1412, 12.269],
              [2.1167, 12.6689],
              [1.5993, 12.6512],
              [1.0371, 13.0366],
              [0.9463, 13.5898],
              [0.7453, 13.7626],
              [0.4331, 14.031],
              [0.1942, 14.5692],
              [0.2293, 14.9897],
              [0.7453, 14.9791],
              [0.9463, 14.9749],
              [1.4597, 15.2725],
              [3.407, 15.3552],
              [3.8653, 15.7915],
              [4.2092, 17.0347],
              [4.243, 19.1389],
              [5.8191, 19.4689],
              [7.4448, 20.8222],
              [8.7142, 21.6026],
              [11.9985, 23.5127],
              [13.4242, 23.1955],
              [14.1827, 22.692],
              [14.9984, 22.9972],
            ],
          ],
        },
        properties: { name: "Niger" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [167.7712, -28.9017],
              [168.1835, -29.0073],
              [168.0342, -29.1822],
              [167.7824, -29.1318],
              [167.7712, -28.9017],
            ],
          ],
        },
        properties: { name: "Norfolk Island" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [13.6346, 13.7107],
              [14.0854, 13.0781],
              [14.2342, 12.4916],
              [14.6064, 12.1066],
              [14.5709, 11.6606],
              [13.6915, 10.8135],
              [12.7578, 8.8108],
              [12.2559, 8.3308],
              [11.7815, 7.0341],
              [11.3091, 6.5958],
              [10.6677, 6.9687],
              [10.0174, 6.8872],
              [8.9762, 5.9087],
              [8.5638, 4.8182],
              [8.1918, 4.5512],
              [6.1424, 4.2937],
              [5.645, 4.6124],
              [5.3273, 5.3255],
              [4.5444, 6.2784],
              [3.8834, 6.425],
              [3.5631, 6.4115],
              [3.407, 6.405],
              [2.7094, 6.3757],
              [2.7788, 8.8841],
              [3.407, 9.7552],
              [3.5493, 9.9526],
              [3.7869, 10.624],
              [3.5631, 11.7331],
              [3.6932, 12.4497],
              [4.184, 13.4108],
              [4.8269, 13.7524],
              [5.7132, 13.8248],
              [6.3889, 13.5974],
              [6.9574, 13.0844],
              [7.9448, 13.2833],
              [9.3565, 12.8289],
              [10.7545, 13.3442],
              [12.398, 13.123],
              [13.6346, 13.7107],
            ],
          ],
        },
        properties: { name: "Nigeria" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-83.6929, 10.9389],
              [-83.9461, 10.7432],
              [-84.6235, 11.0333],
              [-85.6948, 11.0803],
              [-87.6309, 12.9115],
              [-87.3121, 12.9829],
              [-86.7646, 13.3165],
              [-86.6936, 13.744],
              [-85.3235, 14.1899],
              [-84.9132, 14.7538],
              [-84.4054, 14.6387],
              [-83.145, 14.9981],
              [-83.5184, 13.6278],
              [-83.5441, 12.4635],
              [-83.8427, 11.3267],
              [-83.6929, 10.9389],
            ],
          ],
        },
        properties: { name: "Nicaragua" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [6.021, 50.7543],
                [5.8191, 50.7146],
                [5.6876, 50.8119],
                [5.7661, 51.0087],
                [5.7983, 51.0599],
                [5.5663, 51.2208],
                [5.2377, 51.2616],
                [5.1022, 51.429],
                [4.7599, 51.5025],
                [4.6695, 51.4264],
                [4.2796, 51.376],
                [4.2437, 51.3747],
                [3.6874, 51.5167],
                [3.8391, 51.7583],
                [4.1278, 52.0005],
                [4.198, 52.0541],
                [4.3742, 52.1871],
                [4.4938, 52.3283],
                [4.5606, 52.4374],
                [4.6097, 52.5734],
                [4.6491, 52.7562],
                [4.7639, 53.2074],
                [5.1644, 53.0009],
                [5.4112, 53.1517],
                [5.5199, 53.255],
                [5.877, 53.3821],
                [6.1913, 53.4109],
                [6.4492, 53.4229],
                [6.8749, 53.408],
                [7.0981, 53.255],
                [7.2089, 53.2431],
                [7.2028, 53.1133],
                [7.0927, 52.8382],
                [7.0062, 52.6388],
                [6.7097, 52.6278],
                [6.6979, 52.4863],
                [7.0041, 52.3996],
                [7.0657, 52.2414],
                [6.7605, 52.1186],
                [6.6817, 51.9324],
                [6.4078, 51.8281],
                [6.1678, 51.9008],
                [5.9532, 51.7478],
                [6.2244, 51.365],
                [6.0727, 51.2426],
                [6.1748, 51.1845],
                [5.8771, 51.0321],
                [6.0869, 50.9131],
                [6.021, 50.7543],
              ],
            ],
            [
              [
                [3.3658, 51.3698],
                [4.2348, 51.3483],
                [3.9777, 51.2251],
                [3.8563, 51.2111],
                [3.3807, 51.2743],
                [3.3658, 51.3698],
              ],
            ],
          ],
        },
        properties: { name: "Netherlands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [30.8654, 69.7596],
                [30.9297, 69.6195],
                [30.8426, 69.5422],
                [30.2617, 69.6417],
                [30.0753, 69.4717],
                [29.6453, 69.3474],
                [28.8967, 69.0426],
                [29.1537, 69.588],
                [27.7472, 70.0602],
                [26.0763, 69.7256],
                [25.638, 68.9632],
                [25.012, 68.6429],
                [24.0289, 68.7802],
                [23.0757, 68.6756],
                [21.9836, 69.0729],
                [21.6919, 69.2348],
                [21.5888, 69.2786],
                [21.3103, 69.3065],
                [21.0332, 69.2348],
                [20.5486, 69.06],
                [20.3346, 69.0186],
                [19.9221, 68.3845],
                [18.1259, 68.5365],
                [18.0706, 68.1372],
                [17.6849, 68.0325],
                [17.2661, 68.0913],
                [16.7204, 67.8874],
                [16.168, 67.4289],
                [16.3425, 67.0642],
                [15.4153, 66.5045],
                [15.454, 66.3452],
                [14.6273, 66.0639],
                [14.326, 65.1189],
                [13.7303, 64.6122],
                [13.8381, 64.547],
                [14.1092, 64.383],
                [14.0136, 64.0734],
                [13.1489, 64.0748],
                [12.5221, 63.8509],
                [12.2057, 63.6188],
                [12.1495, 63.4182],
                [12.0525, 63.1834],
                [12.2547, 62.331],
                [12.2994, 62.2675],
                [12.1824, 61.7672],
                [12.6933, 61.4259],
                [12.6702, 61.056],
                [12.295, 60.9869],
                [12.5685, 60.4347],
                [12.4381, 60.0782],
                [12.1495, 59.8882],
                [11.8397, 59.8408],
                [11.927, 59.7905],
                [11.8262, 59.2378],
                [11.7167, 59.049],
                [11.622, 58.9296],
                [11.4563, 58.99],
                [11.4085, 59.049],
                [10.9111, 59.0499],
                [10.6697, 59.448],
                [10.502, 59.7816],
                [10.5301, 59.5794],
                [10.3241, 59.691],
                [10.447, 59.4335],
                [10.4489, 59.1292],
                [10.2051, 59.0038],
                [9.8392, 59.0442],
                [9.2921, 58.7823],
                [8.889, 58.4734],
                [8.1852, 58.1429],
                [7.2885, 57.9989],
                [6.4384, 58.2907],
                [5.5556, 58.7508],
                [5.5115, 59.0063],
                [5.7563, 59.2668],
                [5.6747, 59.574],
                [5.8523, 59.6292],
                [5.6053, 59.7698],
                [5.5356, 59.4677],
                [5.5429, 59.3359],
                [5.2682, 59.1639],
                [5.2432, 59.4677],
                [4.9588, 60.3744],
                [5.126, 60.8317],
                [4.6278, 60.9062],
                [4.8476, 61.2295],
                [4.8928, 61.7154],
                [5.1509, 61.8975],
                [5.1092, 62.1405],
                [5.4796, 61.9843],
                [5.4364, 62.1798],
                [5.7295, 62.1712],
                [6.2456, 62.6269],
                [7.004, 62.9534],
                [7.6824, 62.9924],
                [7.8809, 63.4079],
                [8.6235, 63.3851],
                [8.3296, 63.5338],
                [8.3801, 63.6655],
                [8.889, 63.6323],
                [9.0465, 63.5245],
                [9.7046, 63.6577],
                [9.9284, 64.0215],
                [10.7295, 64.4134],
                [11.0527, 64.6068],
                [11.0773, 64.9574],
                [12.1396, 65.0203],
                [12.0265, 65.6489],
                [12.3307, 66.1444],
                [12.8026, 66.4914],
                [13.0668, 66.4101],
                [13.2066, 66.746],
                [13.6302, 67.0023],
                [14.1927, 67.1709],
                [14.7901, 67.8194],
                [15.7675, 68.1432],
                [15.6182, 68.3187],
                [14.5495, 68.283],
                [14.6388, 68.713],
                [15.8247, 69.1515],
                [15.9768, 68.7925],
                [16.4767, 68.9513],
                [17.1721, 68.7446],
                [17.0086, 69.3203],
                [18.5131, 69.7278],
                [18.8896, 70.088],
                [19.7581, 70.0822],
                [19.8665, 69.8299],
                [20.4595, 69.7886],
                [20.6753, 70.0573],
                [21.1304, 69.9502],
                [21.489, 70.1871],
                [22.3418, 70.2529],
                [22.9628, 70.6847],
                [23.7409, 70.6295],
                [25.5574, 70.9647],
                [25.6335, 70.556],
                [27.8602, 70.9848],
                [28.4654, 70.7187],
                [29.2455, 70.7613],
                [30.3277, 70.5198],
                [30.4967, 70.2885],
                [30.1455, 69.9302],
                [30.8654, 69.7596],
              ],
            ],
            [
              [
                [24.3547, 70.8951],
                [24.0672, 70.8887],
                [24.018, 71.1137],
                [24.2857, 71.1782],
                [24.3547, 70.8951],
              ],
            ],
            [
              [
                [14.0206, 68.1948],
                [13.4925, 68.0436],
                [13.3644, 68.1188],
                [13.9204, 68.3658],
                [14.0206, 68.1948],
              ],
            ],
            [
              [
                [13.2331, 68.002],
                [13.0289, 67.7984],
                [12.8363, 67.8951],
                [13.0927, 68.1817],
                [13.2331, 68.002],
              ],
            ],
            [
              [
                [9.1976, 64.0278],
                [9.0578, 63.8197],
                [8.6588, 63.8945],
                [8.7361, 64.0097],
                [9.1976, 64.0278],
              ],
            ],
          ],
        },
        properties: { name: "Norway" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [88.1351, 27.8819],
              [88.0432, 27.206],
              [88.1704, 26.852],
              [88.0627, 26.484],
              [87.4011, 26.3831],
              [87.0575, 26.4379],
              [85.2178, 26.8066],
              [84.2444, 27.4339],
              [83.0809, 27.4663],
              [80.3067, 28.7183],
              [80.1928, 28.9158],
              [80.4172, 29.7013],
              [81.0369, 30.2028],
              [81.1786, 30.0816],
              [81.4846, 30.4064],
              [81.7779, 30.3959],
              [83.2769, 29.5084],
              [83.6232, 29.2272],
              [84.0841, 29.255],
              [84.5559, 28.7241],
              [85.1125, 28.6226],
              [85.1957, 28.3587],
              [85.6902, 28.2767],
              [86.0082, 27.9649],
              [86.6441, 28.0606],
              [87.0575, 27.9138],
              [87.2191, 27.8565],
              [88.1351, 27.8819],
            ],
          ],
        },
        properties: { name: "Nepal" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [166.7442, -0.5586],
              [166.9388, -0.3275],
              [167.1234, -0.4761],
              [166.8652, -0.7265],
              [166.7442, -0.5586],
            ],
          ],
        },
        properties: { name: "Nauru" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-170.0501, -19.0977],
              [-169.8976, -18.8573],
              [-169.6837, -19.0626],
              [-169.8271, -19.2469],
              [-170.0501, -19.0977],
            ],
          ],
        },
        properties: { name: "Niue" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [22.5668, 49.0884],
              [22.3828, 48.8623],
              [22.3336, 48.7016],
              [22.1758, 48.5744],
              [22.1553, 48.4034],
              [22.1211, 48.3783],
              [21.4106, 48.5497],
              [20.4639, 48.464],
              [20.0519, 48.1677],
              [19.0143, 48.0777],
              [18.9284, 48.0568],
              [18.7602, 47.9145],
              [18.8485, 47.8182],
              [17.8939, 47.7395],
              [17.7054, 47.759],
              [17.2474, 48.012],
              [17.1608, 48.0067],
              [17.0667, 48.1187],
              [16.9762, 48.1722],
              [16.8511, 48.4386],
              [16.9498, 48.5358],
              [16.9403, 48.6172],
              [17.3967, 48.8133],
              [17.6469, 48.8543],
              [18.3224, 49.3151],
              [18.4036, 49.3967],
              [18.8516, 49.5172],
              [19.1237, 49.4201],
              [19.4674, 49.6138],
              [19.8839, 49.2042],
              [20.1365, 49.278],
              [20.6149, 49.4178],
              [21.3977, 49.4338],
              [22.5668, 49.0884],
            ],
          ],
        },
        properties: { name: "Slovakia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-10.282, 8.488],
              [-10.6672, 7.7454],
              [-11.4975, 6.9234],
              [-12.7133, 7.5119],
              [-12.9538, 8.1236],
              [-13.2316, 8.3453],
              [-13.3027, 9.0404],
              [-13.0138, 9.1406],
              [-12.4355, 9.8266],
              [-11.3001, 9.9887],
              [-10.7248, 9.3546],
              [-10.5221, 8.6984],
              [-10.5938, 8.3973],
              [-10.282, 8.488],
            ],
          ],
        },
        properties: { name: "Sierra Leone" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [12.453, 43.8945],
              [12.4504, 43.8972],
              [12.47, 43.973],
              [12.4931, 43.9069],
              [12.4874, 43.8965],
              [12.4688, 43.8966],
              [12.4624, 43.8938],
              [12.453, 43.8945],
            ],
          ],
        },
        properties: { name: "San Marino" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-12.2392, 14.7594],
              [-11.864, 13.4525],
              [-11.4311, 13.0175],
              [-11.3795, 12.4136],
              [-12.4334, 12.3458],
              [-13.2641, 12.649],
              [-13.7083, 12.6762],
              [-15.1085, 12.6633],
              [-16.7074, 12.3386],
              [-16.747, 13.0637],
              [-16.1395, 13.1667],
              [-15.8577, 13.1872],
              [-15.8026, 13.3348],
              [-15.3101, 13.3781],
              [-15.102, 13.5695],
              [-14.5538, 13.3348],
              [-14.383, 13.2445],
              [-14.2079, 13.2305],
              [-14.0997, 13.2862],
              [-13.8396, 13.3348],
              [-13.9619, 13.512],
              [-15.0767, 13.7903],
              [-16.1395, 13.5914],
              [-16.5068, 13.5909],
              [-17.2351, 14.6308],
              [-17.4539, 14.7405],
              [-17, 15.1117],
              [-16.5087, 16.0573],
              [-16.5006, 16.0601],
              [-16.4217, 16.2162],
              [-16.0689, 16.4984],
              [-14.3668, 16.5898],
              [-13.3922, 16.0124],
              [-12.2392, 14.7594],
            ],
          ],
        },
        properties: { name: "Senegal" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [41.5645, -1.6558],
              [41.0554, -0.8786],
              [41.0171, 2.6734],
              [41.9101, 3.9823],
              [42.8138, 4.301],
              [43.4741, 4.7993],
              [44.9162, 4.9368],
              [45.2923, 5.2367],
              [45.4747, 5.4241],
              [45.6126, 5.5657],
              [47.8524, 7.9151],
              [46.9411, 8.0224],
              [45.6126, 8.4674],
              [43.9589, 9.0545],
              [42.9804, 10.0644],
              [42.7628, 10.7159],
              [42.9681, 10.9974],
              [43.2603, 11.4606],
              [44.0533, 10.616],
              [44.6392, 10.4017],
              [45.6371, 10.7459],
              [46.5238, 10.7437],
              [47.4038, 11.1335],
              [49.3662, 11.336],
              [50.8088, 11.9173],
              [51.2139, 11.6403],
              [51.1329, 10.6053],
              [50.7576, 9.3318],
              [48.4946, 5.297],
              [47.6689, 4.166],
              [45.7523, 2.3236],
              [44.4979, 1.493],
              [43.1674, 0.252],
              [42.9729, 0.0707],
              [42.1985, -0.8786],
              [41.5645, -1.6558],
            ],
          ],
        },
        properties: { name: "Somalia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-54.0056, 5.6025],
              [-54.298, 5.2363],
              [-54.4353, 4.386],
              [-54.0163, 3.5593],
              [-54.326, 2.588],
              [-54.5879, 2.3221],
              [-55.0333, 2.5492],
              [-55.8909, 2.4178],
              [-56.0897, 1.9331],
              [-56.4774, 1.9459],
              [-57.0218, 2.6002],
              [-57.3197, 3.3177],
              [-57.786, 3.6417],
              [-58.0157, 4.0688],
              [-57.8536, 4.8346],
              [-57.3549, 5.0511],
              [-57.2815, 5.2363],
              [-57.134, 5.8374],
              [-56.8909, 5.9852],
              [-56.0947, 5.8443],
              [-55.5568, 5.9977],
              [-54.2265, 5.8741],
              [-54.0056, 5.6025],
            ],
          ],
        },
        properties: { name: "Suriname" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [34.1132, 9.4986],
              [34.0937, 8.6026],
              [33.8204, 8.4168],
              [33.2485, 8.381],
              [33.0448, 7.899],
              [33.7557, 7.6074],
              [34.5428, 6.7418],
              [34.9588, 6.4537],
              [35.0325, 5.8494],
              [35.3235, 5.413],
              [35.8294, 5.2367],
              [35.9477, 4.62],
              [35.5088, 4.62],
              [35.2489, 4.9563],
              [34.5428, 4.7504],
              [34.3785, 4.62],
              [33.9945, 4.2144],
              [33.4753, 3.7622],
              [32.973, 3.8481],
              [32.3907, 3.7291],
              [32.0987, 3.5511],
              [31.8199, 3.7886],
              [31.5619, 3.6758],
              [31.2097, 3.7851],
              [30.9998, 3.6956],
              [30.8545, 3.4875],
              [29.7103, 4.5969],
              [28.3376, 4.3157],
              [27.4436, 5.0194],
              [27.1693, 5.6776],
              [26.6049, 6.0962],
              [26.29, 6.6847],
              [25.4062, 7.3523],
              [24.9801, 8.016],
              [24.321, 8.3317],
              [24.2234, 8.6422],
              [24.5255, 8.9136],
              [24.7697, 9.7561],
              [25.2549, 10.3366],
              [25.8244, 10.3885],
              [26.5343, 9.5362],
              [27.9072, 9.6102],
              [28.0981, 9.3663],
              [28.5512, 9.3479],
              [28.977, 9.6412],
              [29.4717, 9.7791],
              [29.6187, 10.097],
              [29.9635, 10.2429],
              [30.8069, 9.7642],
              [31.2168, 9.7703],
              [32.1035, 10.7159],
              [32.4186, 11.0739],
              [32.3227, 11.8954],
              [32.9459, 12.2235],
              [33.2446, 12.1394],
              [33.1567, 11.5649],
              [33.298, 10.7159],
              [33.8946, 10.1882],
              [33.9183, 9.5986],
              [34.1132, 9.4986],
            ],
          ],
        },
        properties: { name: "South Sudan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [6.4432, 0.2468],
              [6.6142, 0.4337],
              [6.7711, 0.2443],
              [6.5052, -0.0085],
              [6.4432, 0.2468],
            ],
          ],
        },
        properties: { name: "São Tomé and Príncipe" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-87.8057, 13.3959],
              [-87.8115, 13.3603],
              [-88.2278, 13.1624],
              [-89.435, 13.5306],
              [-89.6593, 13.5991],
              [-90.0133, 13.7071],
              [-90.1243, 13.7409],
              [-90.0133, 13.8384],
              [-89.6593, 14.1495],
              [-89.435, 14.3466],
              [-89.3532, 14.4185],
              [-88.5397, 13.9656],
              [-87.8678, 13.8204],
              [-87.8057, 13.3959],
            ],
          ],
        },
        properties: { name: "El Salvador" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-63.189, 18.0113],
              [-63.0744, 18.1233],
              [-62.997, 18.0851],
              [-62.9939, 18.0051],
              [-63.1006, 17.9691],
              [-63.189, 18.0113],
            ],
          ],
        },
        properties: { name: "Sint-Maarten" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [42.3557, 37.108],
              [41.3313, 36.384],
              [41.3561, 35.6375],
              [41.0138, 34.4548],
              [38.7925, 33.3747],
              [36.8551, 32.3662],
              [35.6716, 32.6911],
              [35.6244, 33.2423],
              [36.5688, 34.2017],
              [36.4058, 34.578],
              [35.9756, 34.6334],
              [35.7849, 35.5954],
              [35.9168, 35.9285],
              [36.2014, 35.8768],
              [36.6168, 36.2635],
              [36.66, 36.8332],
              [36.6788, 36.8327],
              [37.16, 36.6767],
              [37.5855, 36.7038],
              [38.0476, 36.8456],
              [39.2161, 36.6749],
              [40.226, 36.9014],
              [40.7628, 37.096],
              [41.6633, 37.1029],
              [42.1596, 37.2329],
              [42.3557, 37.108],
            ],
          ],
        },
        properties: { name: "Syria" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [31.9821, -25.952],
              [32.1337, -26.835],
              [31.94, -27.2766],
              [31.5496, -27.3065],
              [31.2284, -27.2042],
              [30.8609, -26.7714],
              [30.9531, -26.2532],
              [31.338, -25.8256],
              [31.9821, -25.952],
            ],
          ],
        },
        properties: { name: "Eswatini" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-71.487, 21.2881],
                [-71.6267, 21.0124],
                [-71.8048, 21.3111],
                [-71.6644, 21.4721],
                [-71.487, 21.2881],
              ],
            ],
            [
              [
                [-71.6517, 21.7242],
                [-71.7664, 21.6609],
                [-72.121, 21.9314],
                [-71.9244, 22.0401],
                [-71.6517, 21.7242],
              ],
            ],
          ],
        },
        properties: { name: "Turks and Caicos Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [24, 19.5],
              [23.9995, 17.1753],
              [23.9993, 16.1186],
              [23.941, 15.7526],
              [23.0384, 15.6361],
              [22.9335, 15.1702],
              [22.4535, 14.5476],
              [22.4859, 14.1342],
              [22.1293, 13.8043],
              [22.2317, 13.3473],
              [21.8593, 12.8394],
              [21.9575, 12.6808],
              [22.3889, 12.6326],
              [22.6063, 11.6278],
              [22.9543, 11.2968],
              [22.8755, 10.9314],
              [22.4744, 10.9768],
              [21.7863, 10.7159],
              [21.6697, 10.3052],
              [20.7327, 9.3974],
              [20.3063, 9.1517],
              [18.9757, 8.9098],
              [19.0457, 8.6058],
              [18.5893, 8.0831],
              [17.6801, 7.9654],
              [16.883, 7.591],
              [16.5651, 7.8021],
              [15.8854, 7.4815],
              [15.5075, 7.5275],
              [15.2028, 8.553],
              [14.6321, 8.9454],
              [14.0726, 9.6656],
              [14.3073, 9.9856],
              [15.5186, 10.0058],
              [15.1229, 10.7159],
              [15.11, 11.7138],
              [14.8196, 12.5718],
              [14.4197, 13.0406],
              [14.0854, 13.0781],
              [13.6346, 13.7107],
              [13.5373, 14.4407],
              [14.3535, 15.68],
              [15.5004, 16.9853],
              [15.6327, 19.025],
              [15.9427, 20.3452],
              [15.1963, 21.6026],
              [14.9984, 22.9972],
              [16.1947, 23.3281],
              [24, 19.5],
            ],
          ],
        },
        properties: { name: "Chad" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [77.6118, -38.6497],
                [77.7596, -38.8443],
                [77.4076, -38.8389],
                [77.3545, -38.6388],
                [77.4064, -38.5375],
                [77.6118, -38.6497],
              ],
            ],
            [
              [
                [69.1051, -48.7638],
                [69.4074, -48.9438],
                [69.4856, -48.8566],
                [69.7606, -49.173],
                [70.1501, -49.1668],
                [70.3549, -49.0702],
                [70.5111, -49.1506],
                [70.5015, -49.329],
                [70.1817, -49.4662],
                [70.2855, -49.622],
                [70.1501, -49.6958],
                [69.4856, -49.6459],
                [68.8324, -49.6715],
                [68.7849, -49.0072],
                [69.1051, -48.7638],
              ],
            ],
            [
              [
                [50.5593, -46.352],
                [50.6421, -46.5809],
                [50.2171, -46.4718],
                [50.1557, -46.3267],
                [50.5593, -46.352],
              ],
            ],
          ],
        },
        properties: { name: "French Southern and Antarctic Lands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [0.9122, 10.9965],
              [0.8005, 10.6685],
              [0.7968, 10.3989],
              [0.9463, 10.2593],
              [1.3304, 9.9647],
              [1.6158, 8.974],
              [1.5719, 6.8296],
              [1.7479, 6.3304],
              [1.6287, 6.2345],
              [1.1996, 6.1124],
              [0.9463, 6.333],
              [0.7453, 6.6207],
              [0.57, 6.8716],
              [0.6206, 8.2206],
              [0.3087, 10.2935],
              [-0.0713, 10.6685],
              [-0.142, 11.139],
              [0.7453, 11.019],
              [0.9122, 10.9965],
            ],
          ],
        },
        properties: { name: "Togo" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [105.2001, 14.3427],
                [103.1615, 14.3049],
                [102.4114, 13.5271],
                [102.3986, 13.1639],
                [102.5082, 13.006],
                [102.524, 12.6873],
                [102.7756, 12.435],
                [102.7276, 12.1572],
                [102.9011, 11.7642],
                [102.9109, 11.6472],
                [102.6947, 11.972],
                [102.3531, 11.8911],
                [102.3439, 12.2567],
                [101.9368, 12.5584],
                [100.9522, 12.7195],
                [100.8061, 13.4329],
                [100.1981, 13.4058],
                [99.9636, 12.155],
                [99.2679, 10.3895],
                [99.2889, 9.4351],
                [99.8671, 9.1319],
                [100.6558, 7.1775],
                [101.5666, 6.7747],
                [102.091, 6.2409],
                [101.8326, 5.8096],
                [101.4768, 5.8798],
                [101.1972, 5.6487],
                [101.0625, 6.1551],
                [100.7624, 6.4231],
                [100.2294, 6.6479],
                [100.1213, 6.4217],
                [98.8763, 8.0211],
                [98.5966, 8.1227],
                [98.289, 7.9949],
                [98.2418, 8.8636],
                [98.7537, 10.3893],
                [99.6209, 11.8668],
                [99.161, 13.1048],
                [99.0747, 13.843],
                [98.255, 14.9941],
                [98.8009, 16.2881],
                [97.7527, 17.8227],
                [97.5345, 18.396],
                [97.7543, 18.6525],
                [97.8338, 19.3888],
                [98.0265, 19.6724],
                [98.8831, 19.8187],
                [99.5946, 20.2881],
                [100.0853, 20.3527],
                [100.4885, 20.1911],
                [100.5125, 19.5883],
                [101.2249, 19.5387],
                [101.3116, 18.9891],
                [101.179, 18.5917],
                [100.9955, 17.6658],
                [101.1487, 17.5228],
                [101.3548, 17.6701],
                [102.0799, 18.1552],
                [102.7248, 17.8976],
                [103.3719, 18.4053],
                [103.9616, 18.2846],
                [104.7439, 17.4068],
                [104.7661, 16.548],
                [105.3708, 15.8632],
                [105.6101, 15.563],
                [105.5365, 14.6777],
                [105.2001, 14.3427],
              ],
            ],
            [
              [
                [100.1835, 9.6249],
                [100.0062, 9.3116],
                [99.8344, 9.4273],
                [99.8581, 9.6438],
                [100.1835, 9.6249],
              ],
            ],
          ],
        },
        properties: { name: "Thailand" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [70.9676, 40.2285],
              [70.6555, 40.0502],
              [70.5257, 40.0502],
              [70.0058, 40.2165],
              [69.6253, 40.1138],
              [69.5137, 40.0502],
              [69.3322, 39.7854],
              [69.5209, 39.5656],
              [70.4856, 39.5952],
              [70.8614, 39.4289],
              [71.4569, 39.5692],
              [72.2515, 39.2305],
              [73.4844, 39.4679],
              [73.6582, 39.4651],
              [73.7953, 38.664],
              [74.7852, 38.5064],
              [75.096, 37.4764],
              [74.8899, 37.2341],
              [74.5767, 37.4012],
              [73.8567, 37.2769],
              [73.2944, 37.4584],
              [71.8083, 36.7149],
              [71.4851, 36.9595],
              [71.5235, 37.4764],
              [71.5601, 37.8039],
              [71.3153, 37.9763],
              [71.3126, 38.2515],
              [70.8413, 38.4213],
              [70.2538, 37.9541],
              [70.1791, 37.5831],
              [69.4312, 37.4764],
              [69.2866, 37.1431],
              [68.9042, 37.2831],
              [68.0528, 36.9765],
              [67.8555, 37.0629],
              [67.7787, 37.1796],
              [68.3151, 38.2289],
              [68.0963, 38.8623],
              [67.5335, 39.2951],
              [67.5514, 39.5075],
              [68.456, 39.6133],
              [69.4317, 40.6763],
              [70.4136, 40.9551],
              [70.6152, 40.7776],
              [70.5038, 40.3699],
              [70.9676, 40.2285],
            ],
          ],
        },
        properties: { name: "Tajikistan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-172.4922, -8.5781],
                [-172.501, -8.5845],
                [-172.5028, -8.5827],
                [-172.4974, -8.5773],
                [-172.4922, -8.5781],
              ],
            ],
            [
              [
                [-172.4922, -8.5781],
                [-172.4886, -8.5744],
                [-172.4872, -8.5766],
                [-172.4922, -8.5781],
              ],
            ],
            [
              [
                [-172.4872, -8.5766],
                [-172.4824, -8.5736],
                [-172.4765, -8.5715],
                [-172.4748, -8.5696],
                [-172.469, -8.5728],
                [-172.4659, -8.572],
                [-172.4649, -8.5746],
                [-172.479, -8.576],
                [-172.4872, -8.5766],
              ],
            ],
            [
              [
                [-172.4943, -8.5341],
                [-172.4863, -8.5425],
                [-172.4782, -8.5496],
                [-172.4677, -8.557],
                [-172.4652, -8.5578],
                [-172.467, -8.5597],
                [-172.472, -8.5577],
                [-172.4816, -8.5513],
                [-172.4901, -8.5422],
                [-172.4943, -8.5341],
              ],
            ],
          ],
        },
        properties: { name: "Tokelau" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [125.0819, -9.4462],
                [125.1277, -9.0687],
                [124.95, -8.9581],
                [125.2317, -8.6243],
                [126.352, -8.4368],
                [127.0499, -8.3337],
                [127.2463, -8.4341],
                [126.352, -8.9637],
                [125.0819, -9.4462],
              ],
            ],
            [
              [
                [124.4772, -9.175],
                [124.3535, -9.3971],
                [124.045, -9.343],
                [124.3038, -9.2066],
                [124.4772, -9.175],
              ],
            ],
          ],
        },
        properties: { name: "Timor-Leste" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [66.5537, 37.3548],
              [65.7703, 37.4986],
              [64.8799, 37.0996],
              [64.4207, 36.2878],
              [63.2646, 35.8341],
              [62.7395, 35.2921],
              [62.2905, 35.2271],
              [61.2741, 35.6051],
              [61.0913, 36.5821],
              [60.3349, 36.6789],
              [59.5306, 37.1849],
              [59.3708, 37.5063],
              [58.2749, 37.6987],
              [57.5183, 37.9459],
              [57.2392, 38.2153],
              [56.7141, 38.2659],
              [55.3309, 38.0319],
              [54.7706, 37.5063],
              [54.2432, 37.3273],
              [53.9068, 37.3399],
              [53.8822, 37.5063],
              [53.923, 38.9001],
              [53.198, 39.3832],
              [53.4719, 39.5742],
              [53.4125, 39.8629],
              [52.7581, 40.1148],
              [52.9824, 40.878],
              [53.8494, 40.6472],
              [54.6972, 41.0293],
              [54.1055, 41.4865],
              [53.9561, 41.7331],
              [53.7339, 42.0997],
              [53.046, 42.0246],
              [52.9106, 41.7331],
              [52.7772, 41.4461],
              [52.4614, 41.7331],
              [52.4322, 41.7597],
              [53.0753, 42.1059],
              [53.2532, 42.2017],
              [54.1353, 42.3174],
              [54.6085, 42.1059],
              [54.8453, 42.0001],
              [55.0793, 41.7331],
              [55.4482, 41.3123],
              [56.0006, 41.318],
              [56.7141, 41.2841],
              [56.9976, 41.3132],
              [57.0023, 41.7331],
              [57.2518, 42.0348],
              [57.9738, 42.3929],
              [58.089, 42.45],
              [58.6272, 42.717],
              [59.2965, 42.45],
              [59.4397, 42.3929],
              [59.7804, 42.257],
              [60.1297, 41.7331],
              [60.3327, 41.3003],
              [61.8085, 41.1353],
              [62.6116, 39.8876],
              [65.419, 38.3671],
              [66.5616, 38.0044],
              [66.6138, 37.5063],
              [66.5537, 37.3548],
            ],
          ],
        },
        properties: { name: "Turkmenistan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [11.5643, 33.1651],
              [11.5395, 32.5327],
              [11.3188, 32.3104],
              [10.2329, 31.5973],
              [10.234, 30.7743],
              [9.5516, 30.2464],
              [9.0952, 31.9812],
              [8.6877, 32.3104],
              [7.7925, 33.2741],
              [7.5356, 33.8878],
              [8.2407, 34.6847],
              [8.4128, 35.1988],
              [8.3435, 36.5284],
              [8.6421, 36.9412],
              [9.7533, 37.2992],
              [10.1132, 37.1835],
              [10.4177, 36.8321],
              [10.9207, 36.8774],
              [10.5683, 36.1102],
              [10.9916, 35.571],
              [11.0506, 35.1702],
              [10.1311, 34.0606],
              [10.6992, 33.6176],
              [10.9345, 33.7902],
              [11.2219, 33.3328],
              [11.5643, 33.1651],
            ],
          ],
        },
        properties: { name: "Tunisia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-173.5961, -15.9317],
                [-173.8066, -16.1695],
                [-174.0395, -15.9118],
                [-173.6904, -15.8471],
                [-173.5961, -15.9317],
              ],
            ],
            [
              [
                [-173.8699, -18.7001],
                [-174.0728, -18.8811],
                [-174.2448, -18.8352],
                [-174.1979, -18.7018],
                [-173.8856, -18.5138],
                [-173.8699, -18.7001],
              ],
            ],
            [
              [
                [-174.9909, -21.1613],
                [-175.0842, -21.3447],
                [-175.4439, -21.0636],
                [-175.2814, -20.9815],
                [-174.9909, -21.1613],
              ],
            ],
            [
              [
                [-176.0368, -22.4012],
                [-176.2261, -22.5309],
                [-176.4144, -22.2532],
                [-176.1702, -22.1682],
                [-176.0368, -22.4012],
              ],
            ],
          ],
        },
        properties: { name: "Tonga" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [126.6544, 37.7888],
                [126.6641, 37.804],
                [126.6665, 37.8173],
                [126.667, 37.8226],
                [126.6663, 37.8283],
                [126.6658, 37.833],
                [127.159, 38.287],
                [128.0712, 38.3378],
                [128.3613, 38.6149],
                [128.8236, 37.8986],
                [129.0375, 37.6816],
                [129.2009, 37.4058],
                [129.4412, 36.9331],
                [129.5186, 35.8656],
                [129.2023, 35.1948],
                [128.8027, 35.0394],
                [128.5847, 34.7469],
                [127.8148, 34.69],
                [127.7971, 34.6856],
                [127.7819, 34.6755],
                [127.771, 34.661],
                [127.7655, 34.6436],
                [127.7661, 34.6254],
                [127.7727, 34.6083],
                [127.8149, 34.5388],
                [127.6834, 34.4943],
                [127.6286, 34.5807],
                [127.6431, 34.5948],
                [127.6528, 34.6075],
                [127.6584, 34.6224],
                [127.6594, 34.6383],
                [127.6557, 34.6538],
                [127.6476, 34.6675],
                [127.6359, 34.6782],
                [127.6215, 34.6851],
                [127.6057, 34.6875],
                [127.5551, 34.6875],
                [127.5317, 34.7142],
                [127.5178, 34.7255],
                [127.501, 34.7317],
                [127.483, 34.732],
                [127.466, 34.7263],
                [127.4518, 34.7154],
                [127.4419, 34.7004],
                [127.4384, 34.6874],
                [127.395, 34.5008],
                [127.2719, 34.5093],
                [127.2399, 34.6875],
                [127.1611, 34.6875],
                [127.1542, 34.6875],
                [127.1178, 34.6875],
                [127.028, 34.5084],
                [127.0994, 34.3339],
                [127.088, 34.3339],
                [127.0631, 34.3339],
                [127.0502, 34.3339],
                [127.0414, 34.3339],
                [127.0242, 34.3339],
                [126.8984, 34.3339],
                [126.8882, 34.3339],
                [126.878, 34.3339],
                [126.8162, 34.3339],
                [126.7935, 34.3339],
                [126.7802, 34.3339],
                [126.7328, 34.3339],
                [126.7026, 34.3339],
                [126.7026, 34.2869],
                [126.5757, 34.1451],
                [126.521, 34.3339],
                [126.5312, 34.6424],
                [126.5293, 34.6585],
                [126.5227, 34.6733],
                [126.512, 34.6854],
                [126.4982, 34.6938],
                [126.4825, 34.6978],
                [126.4664, 34.6969],
                [126.4512, 34.6912],
                [126.4457, 34.6882],
                [126.4248, 34.6875],
                [126.4171, 34.6875],
                [126.3924, 34.6875],
                [126.3732, 34.6875],
                [126.3459, 34.6875],
                [126.337, 34.6875],
                [126.3086, 34.4783],
                [126.0558, 34.4707],
                [126.0091, 34.6089],
                [125.9164, 34.6875],
                [126.0099, 34.6875],
                [126.0106, 34.7026],
                [126.0106, 34.8607],
                [126.0106, 34.897],
                [126.2126, 35.0549],
                [126.2089, 34.9862],
                [126.2106, 34.9701],
                [126.2169, 34.9552],
                [126.2273, 34.9428],
                [126.2409, 34.9341],
                [126.2564, 34.9299],
                [126.2726, 34.9304],
                [126.2878, 34.9357],
                [126.3008, 34.9452],
                [126.3104, 34.9582],
                [126.3157, 34.9735],
                [126.3846, 35.3457],
                [126.6634, 36.0243],
                [126.3626, 36.7223],
                [126.3601, 36.7229],
                [126.3456, 36.7324],
                [126.3354, 36.7451],
                [126.3294, 36.7603],
                [126.3283, 36.7766],
                [126.3322, 36.7925],
                [126.3406, 36.8065],
                [126.3528, 36.8173],
                [126.7291, 37.0602],
                [126.6911, 37.4068],
                [126.688, 37.4117],
                [126.6798, 37.4245],
                [126.6756, 37.4391],
                [126.6755, 37.4497],
                [126.6726, 37.4673],
                [126.6641, 37.4829],
                [126.651, 37.4949],
                [126.6348, 37.5019],
                [126.6234, 37.503],
                [126.6192, 37.5037],
                [126.615, 37.5033],
                [126.6103, 37.5062],
                [126.6103, 37.5109],
                [126.6105, 37.5227],
                [126.6051, 37.5397],
                [126.5945, 37.5539],
                [126.5798, 37.564],
                [126.5642, 37.5679],
                [126.5587, 37.5702],
                [126.5529, 37.5729],
                [126.5356, 37.5776],
                [126.5254, 37.5776],
                [126.5182, 37.5767],
                [126.503, 37.5736],
                [126.4847, 37.5726],
                [126.4671, 37.5778],
                [126.4523, 37.5886],
                [126.4419, 37.6038],
                [126.4379, 37.6125],
                [126.4287, 37.6226],
                [126.4175, 37.6349],
                [126.4106, 37.6503],
                [126.4011, 37.672],
                [126.3901, 37.6818],
                [126.3879, 37.6927],
                [126.3802, 37.6968],
                [126.3555, 37.7012],
                [126.3486, 37.7106],
                [126.355, 37.7214],
                [126.3556, 37.7477],
                [126.3661, 37.7556],
                [126.3598, 37.7633],
                [126.3568, 37.7803],
                [126.3694, 37.7951],
                [126.3802, 37.7993],
                [126.3802, 37.8019],
                [126.384, 37.8008],
                [126.3915, 37.8045],
                [126.3959, 37.8148],
                [126.4108, 37.8171],
                [126.4302, 37.8241],
                [126.4377, 37.8199],
                [126.442, 37.8096],
                [126.4617, 37.8008],
                [126.4789, 37.7866],
                [126.4895, 37.7835],
                [126.5028, 37.7843],
                [126.5056, 37.7812],
                [126.5075, 37.7794],
                [126.5202, 37.7706],
                [126.5339, 37.766],
                [126.5396, 37.7649],
                [126.5457, 37.765],
                [126.5754, 37.7521],
                [126.6058, 37.7526],
                [126.6229, 37.761],
                [126.6384, 37.7747],
                [126.6423, 37.7768],
                [126.6474, 37.7826],
                [126.6544, 37.7888],
              ],
            ],
            [
              [
                [126.6049, 33.2413],
                [126.2812, 33.2101],
                [126.1661, 33.2958],
                [126.3548, 33.4663],
                [126.7281, 33.5472],
                [126.8894, 33.4431],
                [126.7751, 33.2958],
                [126.6049, 33.2413],
              ],
            ],
            [
              [
                [126.4848, 37.2841],
                [126.3746, 37.0416],
                [125.871, 37.2254],
                [126.1092, 37.1514],
                [126.249, 37.3667],
                [126.4848, 37.2841],
              ],
            ],
            [
              [
                [125.7708, 37.6949],
                [125.8116, 37.5285],
                [125.7133, 37.564],
                [125.7059, 37.5666],
                [125.6918, 37.5765],
                [125.6815, 37.5903],
                [125.6762, 37.6067],
                [125.6763, 37.6239],
                [125.6818, 37.6403],
                [125.6922, 37.654],
                [125.7065, 37.6637],
                [125.7708, 37.6949],
              ],
            ],
          ],
        },
        properties: { name: "South Korea" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [47.9591, 30.0038],
              [48.3365, 29.7801],
              [47.882, 29.4296],
              [48.0921, 29.2944],
              [48.4333, 28.5314],
              [47.7643, 28.5452],
              [47.4177, 28.9797],
              [46.553, 29.1012],
              [47.2144, 30.0133],
              [47.9591, 30.0038],
            ],
          ],
        },
        properties: { name: "Kuwait" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-81.5321, 19.4131],
              [-80.982, 19.3761],
              [-81.0083, 19.2749],
              [-81.4641, 19.2261],
              [-81.5321, 19.4131],
            ],
          ],
        },
        properties: { name: "Cayman Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [87.3126, 49.0997],
                [85.7846, 48.2473],
                [85.3679, 47.0945],
                [84.825, 46.9384],
                [83.0421, 47.0395],
                [82.4415, 45.832],
                [82.375, 45.2653],
                [81.5111, 45.2789],
                [80.2882, 44.9512],
                [80.6646, 43.3477],
                [80.2371, 42.3929],
                [80.2456, 42.2209],
                [79.8198, 42.3929],
                [78.8408, 42.7334],
                [77.0929, 42.9409],
                [75.4801, 42.8656],
                [74.2231, 43.1398],
                [73.7513, 43.0076],
                [73.3125, 42.5357],
                [71.555, 42.7757],
                [71.0385, 42.3929],
                [70.9402, 42.2592],
                [70.6274, 42.1059],
                [69.8741, 41.7331],
                [69.1476, 41.3736],
                [68.4646, 40.6663],
                [67.8782, 41.1359],
                [66.6943, 41.3166],
                [66.5069, 41.7331],
                [66.4486, 41.8377],
                [66.0488, 42.1109],
                [66.0376, 42.3929],
                [65.9536, 42.7934],
                [65.0384, 43.6349],
                [62.0428, 43.6178],
                [60.8103, 44.4097],
                [58.5043, 45.4677],
                [56.1524, 44.8265],
                [56.0252, 42.3929],
                [56.0005, 41.7331],
                [56.0006, 41.318],
                [55.4482, 41.3123],
                [55.0793, 41.7331],
                [54.8453, 42.0001],
                [54.6085, 42.1059],
                [54.1353, 42.3174],
                [53.2532, 42.2017],
                [53.0753, 42.1059],
                [52.4322, 41.7597],
                [52.5391, 42.1059],
                [52.6277, 42.3929],
                [52.626, 42.7096],
                [51.3899, 43.2306],
                [50.465, 44.4641],
                [51.1379, 44.5785],
                [51.0993, 44.975],
                [51.5158, 45.3353],
                [52.7943, 45.464],
                [53.0812, 46.215],
                [52.9723, 46.7786],
                [51.1616, 47.0596],
                [49.2136, 46.3332],
                [48.7942, 46.599],
                [48.8096, 46.9554],
                [48.4852, 47.4156],
                [48.0285, 47.6876],
                [47.2665, 47.8148],
                [46.6827, 48.4677],
                [46.9351, 49.7296],
                [47.446, 50.2984],
                [48.573, 49.9575],
                [48.9207, 50.6761],
                [50.8212, 51.6542],
                [51.5214, 51.544],
                [52.3307, 51.6643],
                [53.2814, 51.483],
                [54.5327, 50.6975],
                [54.9145, 50.8104],
                [55.6774, 50.6643],
                [56.5075, 50.972],
                [58.0642, 51.0787],
                [59.4875, 50.6292],
                [61.1066, 50.7718],
                [61.3457, 50.9419],
                [61.3899, 51.3192],
                [60.2825, 51.9603],
                [60.8386, 52.2997],
                [60.9869, 52.8069],
                [61.6281, 53.0971],
                [61.2404, 53.6425],
                [61.3583, 53.9303],
                [64.8622, 54.3622],
                [65.6722, 54.6387],
                [67.9852, 54.952],
                [69.013, 55.3543],
                [70.7176, 55.2085],
                [71.2665, 54.2568],
                [73.1022, 54.0044],
                [73.6202, 53.5211],
                [74.3649, 53.5615],
                [75.5425, 54.0876],
                [76.5796, 54.2755],
                [76.7984, 53.8832],
                [78.2111, 52.9706],
                [79.9616, 51.0371],
                [80.754, 51.2158],
                [81.6483, 50.8179],
                [83.538, 50.9008],
                [85.3276, 49.658],
                [86.0644, 49.5438],
                [86.6632, 49.68],
                [87.3126, 49.0997],
              ],
            ],
            [
              [
                [73.4786, 53.86],
                [73.4388, 54.0281],
                [73.8138, 54.1065],
                [73.723, 53.8039],
                [73.4786, 53.86],
              ],
            ],
          ],
        },
        properties: { name: "Kazakhstan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [107.5561, 14.6866],
              [106.9803, 14.3702],
              [106.6894, 14.4307],
              [106.0779, 14.3576],
              [106.0246, 13.9409],
              [105.2001, 14.3427],
              [105.5365, 14.6777],
              [105.6101, 15.563],
              [105.3708, 15.8632],
              [104.7661, 16.548],
              [104.7439, 17.4068],
              [103.9616, 18.2846],
              [103.3719, 18.4053],
              [102.7248, 17.8976],
              [102.0799, 18.1552],
              [101.3548, 17.6701],
              [101.1487, 17.5228],
              [100.9955, 17.6658],
              [101.179, 18.5917],
              [101.3116, 18.9891],
              [101.2249, 19.5387],
              [100.5125, 19.5883],
              [100.4885, 20.1911],
              [100.0853, 20.3527],
              [100.9577, 21.3822],
              [101.1432, 21.5641],
              [101.2992, 21.2567],
              [101.7015, 21.2366],
              [101.6416, 22.2745],
              [102.1439, 22.4007],
              [102.9271, 21.7266],
              [102.897, 21.3822],
              [103.2157, 20.8666],
              [103.665, 20.7839],
              [104.1421, 20.9297],
              [104.4887, 20.719],
              [104.5298, 20.4333],
              [104.9197, 20.0654],
              [104.6562, 19.6942],
              [104.1919, 19.6463],
              [103.9581, 19.3062],
              [105.1551, 18.6727],
              [105.1906, 18.3486],
              [106.5116, 16.9689],
              [106.74, 16.4137],
              [107.3734, 16.1224],
              [107.2941, 15.8632],
              [107.5938, 15.3404],
              [107.5561, 14.6866],
            ],
          ],
        },
        properties: { name: "Laos" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [35.6244, 33.2423],
              [35.1034, 33.0936],
              [35.9756, 34.6334],
              [36.4058, 34.578],
              [36.5688, 34.2017],
              [35.6244, 33.2423],
            ],
          ],
        },
        properties: { name: "Lebanon" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-61.1329, 13.8767],
              [-61.0349, 14.0693],
              [-60.829, 14.0373],
              [-60.9347, 13.6443],
              [-61.1329, 13.8767],
            ],
          ],
        },
        properties: { name: "Saint Lucia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [9.6071, 47.0608],
              [9.476, 47.0518],
              [9.5307, 47.2706],
              [9.6206, 47.1516],
              [9.6071, 47.0608],
            ],
          ],
        },
        properties: { name: "Liechtenstein" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [79.7508, 7.9286],
              [79.8337, 8.0142],
              [79.7794, 8.5235],
              [79.9183, 8.5541],
              [79.9225, 8.9409],
              [80.0675, 9.0611],
              [80.0634, 9.3925],
              [80.2181, 9.558],
              [80.0272, 9.7745],
              [80.2852, 9.8441],
              [80.4738, 9.5725],
              [80.8076, 9.2901],
              [81.0332, 8.8855],
              [81.3498, 8.4939],
              [81.473, 8.0411],
              [81.7848, 7.5987],
              [81.8754, 7.0229],
              [81.697, 6.4947],
              [81.322, 6.2049],
              [80.7152, 5.9644],
              [80.2653, 6.0023],
              [80.0643, 6.1959],
              [79.8676, 6.8183],
              [79.8361, 7.322],
              [79.7508, 7.9286],
            ],
          ],
        },
        properties: { name: "Sri Lanka" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-8.4722, 7.5546],
              [-8.3267, 7.0841],
              [-8.4496, 6.4988],
              [-7.4568, 5.7608],
              [-7.5231, 4.3617],
              [-8.491, 4.718],
              [-9.4809, 5.3693],
              [-10.3175, 6.0775],
              [-10.7788, 6.3029],
              [-11.4975, 6.9234],
              [-10.6672, 7.7454],
              [-10.282, 8.488],
              [-9.5993, 8.3707],
              [-9.1699, 7.3537],
              [-8.4722, 7.5546],
            ],
          ],
        },
        properties: { name: "Liberia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [27.0327, -29.6171],
              [27.37, -29.4774],
              [27.7666, -28.8974],
              [28.0366, -28.8784],
              [28.1938, -28.6929],
              [28.6524, -28.5749],
              [28.9973, -28.9141],
              [29.3202, -29.0855],
              [29.4624, -29.352],
              [29.1825, -29.665],
              [29.172, -29.9121],
              [28.8475, -30.093],
              [28.4128, -30.1401],
              [28.1178, -30.6655],
              [27.7514, -30.5918],
              [27.3808, -30.2],
              [27.0327, -29.6171],
            ],
          ],
        },
        properties: { name: "Lesotho" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [26.6304, 55.6807],
              [26.7404, 55.2639],
              [26.6447, 55.1608],
              [26.3111, 55.1325],
              [26.1932, 55.0034],
              [25.9578, 54.9506],
              [25.7708, 54.7943],
              [25.761, 54.5791],
              [25.6697, 54.195],
              [24.8359, 54.149],
              [24.4754, 53.9309],
              [23.5147, 53.9566],
              [23.3215, 54.2533],
              [22.7921, 54.3634],
              [22.8215, 54.8264],
              [22.589, 55.0703],
              [21.6511, 55.18],
              [21.3903, 55.2694],
              [21.2735, 55.2457],
              [21.05, 55.9115],
              [21.0642, 56.0691],
              [21.9782, 56.3851],
              [22.6356, 56.3682],
              [22.9205, 56.3991],
              [23.1554, 56.3288],
              [24.1518, 56.2533],
              [24.8485, 56.3676],
              [25.8214, 56.0522],
              [26.0462, 55.9441],
              [26.6304, 55.6807],
            ],
          ],
        },
        properties: { name: "Lithuania" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [6.1377, 50.13],
              [6.475, 49.8213],
              [6.3801, 49.5511],
              [6.3671, 49.4695],
              [5.8934, 49.4969],
              [5.8181, 49.5463],
              [5.7463, 49.8536],
              [6.0249, 50.1828],
              [6.1377, 50.13],
            ],
          ],
        },
        properties: { name: "Luxembourg" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [28.1546, 56.1698],
              [27.6312, 55.8737],
              [26.6304, 55.6807],
              [26.0462, 55.9441],
              [25.8214, 56.0522],
              [24.8485, 56.3676],
              [24.1518, 56.2533],
              [23.1554, 56.3288],
              [22.9205, 56.3991],
              [22.6356, 56.3682],
              [21.9782, 56.3851],
              [21.0642, 56.0691],
              [21.073, 56.7843],
              [21.7132, 57.54],
              [22.4918, 57.7045],
              [23.1469, 57.3163],
              [23.477, 57.0452],
              [23.9345, 57.0064],
              [24.1192, 57.0863],
              [24.3819, 57.3167],
              [24.3528, 57.8766],
              [24.8341, 57.9728],
              [25.0463, 58.0401],
              [26.4549, 57.5549],
              [27.3516, 57.5182],
              [27.6908, 57.3706],
              [28.1546, 56.1698],
            ],
          ],
        },
        properties: { name: "Latvia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [25.1472, 31.6497],
              [24.892, 31.3739],
              [24.9888, 30.7293],
              [24.7452, 30.1337],
              [24.9948, 29.1507],
              [25, 26.8805],
              [25, 25.5268],
              [25, 24.9875],
              [25, 22],
              [25, 21.6026],
              [24.9765, 20.0689],
              [24.0666, 19.9725],
              [24, 19.5],
              [16.1947, 23.3281],
              [14.9984, 22.9972],
              [14.1827, 22.692],
              [13.4242, 23.1955],
              [11.9985, 23.5127],
              [11.6027, 24.2015],
              [10.4325, 24.5274],
              [10.1392, 24.8009],
              [9.9784, 25.3828],
              [9.4591, 26.143],
              [9.8837, 26.6543],
              [9.9363, 27.8899],
              [9.8055, 29.2946],
              [9.4464, 30.0952],
              [9.5516, 30.2464],
              [10.234, 30.7743],
              [10.2329, 31.5973],
              [11.3188, 32.3104],
              [11.5395, 32.5327],
              [11.5643, 33.1651],
              [12.3804, 32.8311],
              [13.3439, 32.8653],
              [15.1861, 32.3341],
              [15.4797, 31.7506],
              [15.8354, 31.3853],
              [17.4555, 31.0433],
              [19.1543, 30.3129],
              [19.6354, 30.4643],
              [20.0481, 30.8943],
              [19.9817, 31.8874],
              [20.2437, 32.2947],
              [20.9944, 32.7107],
              [21.7671, 32.9048],
              [22.9352, 32.6696],
              [23.1522, 32.325],
              [23.4122, 32.1894],
              [24.1654, 32.0109],
              [24.9115, 31.9798],
              [25.1472, 31.6497],
            ],
          ],
        },
        properties: { name: "Libya" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-5.3434, 35.8709],
              [-5.3282, 35.7175],
              [-5.2469, 35.5748],
              [-4.9374, 35.3311],
              [-4.6931, 35.2112],
              [-4.4183, 35.1574],
              [-4.3025, 35.1701],
              [-4.2979, 35.1704],
              [-4.297, 35.1728],
              [-3.3739, 35.2562],
              [-2.9698, 35.2933],
              [-2.9323, 35.2776],
              [-2.9247, 35.2784],
              [-2.7863, 35.1869],
              [-2.6593, 35.103],
              [-2.2097, 35.0846],
              [-1.8091, 34.6952],
              [-1.5962, 33.2215],
              [-1.1187, 32.5223],
              [-1.2332, 32.2639],
              [-2.7778, 32.0677],
              [-3.0082, 31.7988],
              [-3.5844, 31.5104],
              [-3.8126, 30.6884],
              [-4.9056, 30.1258],
              [-5.5291, 29.6274],
              [-7.1492, 29.458],
              [-8.3109, 28.8968],
              [-8.6296, 28.5136],
              [-8.6666, 27.662],
              [-13.173, 27.667],
              [-12.8629, 27.947],
              [-11.5272, 28.3192],
              [-10.2316, 29.3082],
              [-9.6511, 30.2728],
              [-9.8437, 30.6757],
              [-9.8045, 31.4344],
              [-9.2562, 32.1908],
              [-9.1851, 32.6104],
              [-8.0741, 33.4365],
              [-6.8681, 34.0174],
              [-5.9489, 35.701],
              [-5.3819, 35.913],
              [-5.3434, 35.8709],
            ],
          ],
        },
        properties: { name: "Morocco" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [7.4392, 43.749],
              [7.4375, 43.7471],
              [7.4345, 43.7442],
              [7.4272, 43.737],
              [7.4249, 43.7348],
              [7.4147, 43.7266],
              [7.4103, 43.7293],
              [7.4129, 43.7328],
              [7.4374, 43.7516],
              [7.4392, 43.749],
            ],
          ],
        },
        properties: { name: "Monaco" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [28.2114, 45.4673],
              [28.1158, 46.1078],
              [28.2283, 46.438],
              [28.1138, 46.8384],
              [27.3912, 47.5894],
              [27.1926, 47.9044],
              [26.6306, 48.2597],
              [27.6199, 48.4649],
              [28.7544, 48.135],
              [28.9245, 47.9859],
              [29.1968, 47.9044],
              [29.223, 47.477],
              [29.5435, 47.3214],
              [29.9594, 46.6108],
              [29.8609, 46.431],
              [29.0181, 46.4516],
              [29.0061, 46.1012],
              [28.7122, 45.8204],
              [28.2114, 45.4673],
            ],
          ],
        },
        properties: { name: "Moldova" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [20.0763, 42.5558],
              [19.831, 42.4664],
              [19.6623, 42.5469],
              [19.488, 42.3929],
              [19.3924, 42.2956],
              [19.3841, 42.1059],
              [19.3725, 41.8441],
              [18.5252, 42.4205],
              [18.4381, 42.5557],
              [18.7067, 43.1554],
              [19.2241, 43.5275],
              [20.0639, 43.0068],
              [20.3529, 42.8334],
              [20.0763, 42.5558],
            ],
          ],
        },
        properties: { name: "Montenegro" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [125.6861, 9.6278],
                [125.8361, 9.5882],
                [126.107, 9.9587],
                [126.1614, 9.7729],
                [125.9535, 9.5762],
                [125.9359, 9.3815],
                [126.1536, 9.2283],
                [126.4463, 7.8063],
                [126.5799, 7.2993],
                [126.1161, 6.6926],
                [125.8266, 7.2332],
                [125.4592, 6.8735],
                [125.6755, 6.0802],
                [125.3546, 5.6143],
                [125.1942, 6.0334],
                [124.8356, 5.9022],
                [124.1152, 6.3351],
                [124.0154, 6.9154],
                [124.1561, 7.4061],
                [123.6916, 7.8063],
                [123.431, 7.8063],
                [123.4058, 7.6304],
                [123.1658, 7.4765],
                [122.8249, 7.3662],
                [122.7577, 7.7004],
                [122.583, 7.7118],
                [122.047, 6.93],
                [121.9491, 7.1314],
                [122.1163, 7.7856],
                [122.8725, 8.1648],
                [123.1658, 8.5318],
                [123.6352, 8.5998],
                [123.8856, 8.2461],
                [124.125, 8.2219],
                [124.3902, 8.5589],
                [124.6849, 8.5461],
                [124.8246, 8.9461],
                [125.1071, 8.8939],
                [125.5349, 9.0955],
                [125.3984, 9.7538],
                [125.5163, 10.1026],
                [125.6199, 10.0975],
                [125.6861, 9.6278],
              ],
            ],
            [
              [
                [125.4721, 11.804],
                [125.5206, 11.1264],
                [125.0263, 11.1264],
                [125.2002, 10.2724],
                [124.9086, 10.1223],
                [124.7292, 10.4333],
                [124.7489, 10.8159],
                [124.6181, 10.9569],
                [124.4139, 10.915],
                [124.3469, 11.3798],
                [124.5098, 11.5885],
                [124.6933, 11.3616],
                [124.8277, 11.804],
                [124.692, 11.804],
                [124.2724, 12.3896],
                [124.425, 12.5825],
                [125.2575, 12.4981],
                [125.5018, 12.1218],
                [125.4721, 11.804],
              ],
            ],
            [
              [
                [124.554, 9.7499],
                [124.1137, 9.5874],
                [123.7753, 9.7499],
                [124.2086, 10.1875],
                [124.554, 10.048],
                [124.554, 9.7499],
              ],
            ],
            [
              [
                [124.4114, 13.6703],
                [124.2144, 13.529],
                [124.0284, 13.6647],
                [124.2093, 14.0943],
                [124.4189, 13.7977],
                [124.4114, 13.6703],
              ],
            ],
            [
              [
                [120.8602, 18.6314],
                [121.7969, 18.3347],
                [122.2742, 18.3771],
                [122.1613, 17.6999],
                [122.4768, 16.9427],
                [122.1039, 16.255],
                [121.6409, 15.9685],
                [121.415, 15.3049],
                [121.6132, 14.5872],
                [121.7686, 14.1867],
                [122.0776, 13.9676],
                [122.5338, 14.317],
                [123.0292, 14.1058],
                [123.1517, 13.7755],
                [123.5631, 13.9908],
                [123.8272, 13.9235],
                [123.5822, 13.615],
                [123.758, 13.1798],
                [124.1064, 12.9458],
                [124.0644, 12.5893],
                [123.3668, 13.0143],
                [123.1909, 13.409],
                [122.6648, 13.7578],
                [122.5816, 13.7003],
                [122.6966, 13.2475],
                [122.5944, 13.1618],
                [122.2393, 13.5122],
                [122.0655, 13.2257],
                [121.8268, 13.4128],
                [122.128, 13.6221],
                [121.8851, 13.8618],
                [121.1879, 13.6245],
                [120.6831, 13.9667],
                [120.6373, 14.2172],
                [120.967, 14.5872],
                [120.8672, 14.7358],
                [120.5553, 14.7259],
                [120.5733, 14.4607],
                [120.4062, 14.4696],
                [120.3647, 14.6193],
                [120.0985, 14.8397],
                [119.7507, 15.9685],
                [119.8185, 16.2568],
                [120.1641, 16.0803],
                [120.3786, 16.2105],
                [120.3844, 17.616],
                [120.5963, 18.4478],
                [120.8602, 18.6314],
              ],
            ],
            [
              [
                [124.0118, 10.4343],
                [123.4238, 9.5791],
                [123.3245, 9.6161],
                [123.3451, 9.751],
                [123.671, 10.4343],
                [123.9177, 11.0284],
                [124.0218, 11.0067],
                [124.0118, 10.4343],
              ],
            ],
            [
              [
                [123.8546, 12.2471],
                [124.0067, 11.9178],
                [123.9277, 11.8497],
                [123.4977, 12.1325],
                [123.2473, 12.099],
                [123.246, 12.4994],
                [123.4715, 12.4994],
                [123.8546, 12.2471],
              ],
            ],
            [
              [
                [123.1762, 11.5024],
                [123.1396, 11.1284],
                [123.0299, 11.1638],
                [122.762, 10.9007],
                [122.8632, 10.6977],
                [123.0299, 10.9174],
                [123.2333, 10.985],
                [123.5158, 10.8717],
                [123.5238, 10.6787],
                [123.3623, 10.4331],
                [123.1678, 9.9116],
                [123.1262, 9.6261],
                [123.29, 9.3411],
                [123.1678, 9.0711],
                [122.4225, 9.7112],
                [122.4695, 9.9183],
                [122.8223, 10.1131],
                [122.8339, 10.4331],
                [122.4146, 10.65],
                [121.9947, 10.5542],
                [122.0574, 11.6352],
                [121.9405, 11.8141],
                [122.0507, 11.8623],
                [123.0299, 11.5121],
                [123.1762, 11.5024],
              ],
            ],
            [
              [
                [122.7642, 12.3937],
                [122.6602, 12.1984],
                [122.3423, 12.4583],
                [122.661, 12.5425],
                [122.7642, 12.3937],
              ],
            ],
            [
              [
                [122.1357, 12.5344],
                [122.0532, 12.1977],
                [121.8693, 12.2828],
                [121.986, 12.5711],
                [121.9377, 12.8789],
                [122.1352, 12.9554],
                [122.111, 12.7116],
                [122.2425, 12.6743],
                [122.1357, 12.5344],
              ],
            ],
            [
              [
                [122.1953, 6.4803],
                [122.0017, 6.3728],
                [121.7696, 6.608],
                [122.0303, 6.7336],
                [122.1836, 6.6514],
                [122.1953, 6.4803],
              ],
            ],
            [
              [
                [122.14, 14.8988],
                [122.0808, 14.7915],
                [121.7002, 14.9294],
                [121.9476, 15.1311],
                [122.14, 14.8988],
              ],
            ],
            [
              [
                [121.6348, 19.2069],
                [121.3886, 19.1892],
                [121.303, 19.3081],
                [121.3427, 19.4221],
                [121.5952, 19.4847],
                [121.6348, 19.2069],
              ],
            ],
            [
              [
                [121.5068, 12.6039],
                [121.2253, 12.2764],
                [120.5282, 13.2468],
                [120.3679, 13.3761],
                [120.3679, 13.5128],
                [121.1294, 13.4286],
                [121.3763, 13.2468],
                [121.5068, 12.6039],
              ],
            ],
            [
              [
                [121.4029, 6.032],
                [121.2269, 5.7985],
                [120.9035, 5.9594],
                [120.9731, 6.0848],
                [121.4029, 6.032],
              ],
            ],
            [
              [
                [120.3661, 5.3313],
                [120.3567, 5.0974],
                [120.1416, 5.1384],
                [119.7954, 4.9493],
                [119.723, 5.0221],
                [120.0211, 5.3738],
                [120.2128, 5.4542],
                [120.3661, 5.3313],
              ],
            ],
            [
              [
                [120.304, 5.9371],
                [120.1589, 5.7836],
                [119.8749, 5.8961],
                [120.2134, 6.1273],
                [120.304, 5.9371],
              ],
            ],
            [
              [
                [120.2768, 12.0826],
                [120.0136, 11.794],
                [119.8994, 11.8578],
                [119.8289, 12.1349],
                [119.9905, 12.2288],
                [120.2768, 12.0826],
              ],
            ],
            [
              [
                [120.2696, 13.7973],
                [120.1287, 13.7074],
                [119.8008, 13.951],
                [120.0259, 14.0509],
                [120.2696, 13.7973],
              ],
            ],
            [
              [
                [120.0657, 10.621],
                [120.0883, 10.4319],
                [119.7052, 10.5025],
                [119.7696, 10.73],
                [120.0657, 10.621],
              ],
            ],
            [
              [
                [120.046, 11.6003],
                [119.9487, 11.4286],
                [119.6158, 11.4672],
                [119.803, 11.7212],
                [120.046, 11.6003],
              ],
            ],
            [
              [
                [117.7595, 8.6997],
                [117.4018, 8.4719],
                [117.2442, 8.4954],
                [117.7595, 9.1212],
                [119.0966, 10.4337],
                [119.1817, 10.4337],
                [119.2374, 11.1383],
                [119.4415, 11.3476],
                [119.6885, 11.3603],
                [119.5746, 11.1383],
                [119.6101, 10.4337],
                [118.8845, 9.9421],
                [118.5119, 9.3531],
                [117.7595, 8.6997],
              ],
            ],
            [
              [
                [118.653, 7.1332],
                [118.5864, 6.8393],
                [118.2745, 6.9411],
                [118.2451, 7.0498],
                [118.653, 7.1332],
              ],
            ],
            [
              [
                [117.113, 8.0559],
                [117.0351, 7.8539],
                [116.9193, 7.9417],
                [116.9852, 8.1737],
                [117.2447, 8.3998],
                [117.3449, 8.1861],
                [117.113, 8.0559],
              ],
            ],
          ],
        },
        properties: { name: "Philippines" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [75.3339, 32.3266],
              [74.65, 31.8079],
              [74.4044, 30.8691],
              [71.9298, 28.0722],
              [70.9183, 27.7815],
              [70.3421, 27.909],
              [69.5759, 26.9485],
              [70.084, 26.4979],
              [70.2013, 25.93],
              [70.716, 25.4417],
              [71.0157, 24.7573],
              [70.8701, 24.2488],
              [70.0829, 24.2255],
              [68.9389, 24.2488],
              [68.8662, 24.2488],
              [67.5192, 24.0224],
              [67.3698, 24.633],
              [66.7746, 24.8824],
              [66.35, 25.4884],
              [61.6105, 25.195],
              [61.8842, 26.2036],
              [62.4742, 26.5693],
              [63.0911, 26.6676],
              [63.1929, 26.8423],
              [63.1741, 27.1781],
              [62.8319, 27.3052],
              [62.7458, 28.2253],
              [61.8091, 28.6605],
              [60.873, 29.8585],
              [62.4953, 29.403],
              [65.2412, 29.5782],
              [66.2579, 29.8854],
              [66.4208, 30.9063],
              [66.9026, 31.2606],
              [67.5736, 31.2768],
              [67.8555, 31.6214],
              [68.2247, 31.7982],
              [68.9944, 31.6561],
              [69.2828, 32.1149],
              [69.5333, 33.0026],
              [70.2571, 33.3559],
              [69.9165, 33.9708],
              [70.9817, 34.0291],
              [71.2242, 34.7534],
              [71.6228, 35.1948],
              [71.2997, 36.1094],
              [72.3359, 36.7654],
              [73.2944, 36.8773],
              [73.6393, 36.9034],
              [73.6909, 36.7433],
              [73.2944, 36.7238],
              [72.5938, 36.2273],
              [72.5308, 36.0297],
              [72.6179, 35.8638],
              [73.049, 35.8283],
              [73.2944, 35.6086],
              [73.7126, 35.5074],
              [73.7903, 35.2386],
              [74.0657, 35.092],
              [73.7156, 34.7534],
              [73.4315, 34.3917],
              [73.6386, 33.1515],
              [75.3339, 32.3266],
            ],
          ],
        },
        properties: { name: "Pakistan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [19.8038, 54.4424],
              [20.1365, 54.4162],
              [20.3135, 54.4022],
              [21.5593, 54.3225],
              [22.7921, 54.3634],
              [23.3215, 54.2533],
              [23.5147, 53.9566],
              [23.5887, 53.6959],
              [23.8116, 53.255],
              [23.9162, 52.9048],
              [23.8616, 52.7073],
              [23.1783, 52.2831],
              [23.6455, 52.0553],
              [23.5477, 51.6914],
              [23.6174, 51.5095],
              [23.7092, 51.2776],
              [24.096, 50.6123],
              [23.5476, 50.2516],
              [22.6861, 49.5732],
              [22.7088, 49.2022],
              [22.5668, 49.0884],
              [21.3977, 49.4338],
              [20.6149, 49.4178],
              [20.1365, 49.278],
              [19.8839, 49.2042],
              [19.4674, 49.6138],
              [19.1237, 49.4201],
              [18.8516, 49.5172],
              [18.5757, 49.9104],
              [18.0351, 50.0658],
              [17.4296, 50.2545],
              [16.9079, 50.4495],
              [16.8633, 50.1981],
              [16.5803, 50.1428],
              [16.3325, 50.5925],
              [16.1073, 50.6621],
              [15.5353, 50.7794],
              [15.1816, 50.9574],
              [14.8234, 50.8706],
              [14.9742, 51.3639],
              [14.7299, 51.5818],
              [14.7167, 52.0012],
              [14.7552, 52.07],
              [14.6009, 52.2721],
              [14.5344, 52.395],
              [14.5651, 52.6245],
              [14.4364, 52.6799],
              [14.1567, 52.8956],
              [14.1437, 52.9614],
              [14.4122, 53.3296],
              [14.2675, 53.6978],
              [15.3881, 54.1589],
              [16.6991, 54.5692],
              [17.6666, 54.7832],
              [18.2896, 54.8068],
              [18.5417, 54.5845],
              [18.95, 54.3583],
              [19.6389, 54.4582],
              [19.6451, 54.4516],
              [19.3315, 54.3523],
              [19.257, 54.2785],
              [19.8038, 54.4424],
            ],
          ],
        },
        properties: { name: "Poland" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-56.1805, 47.1484],
              [-56.2609, 46.8912],
              [-55.9808, 46.7185],
              [-56.1685, 46.6411],
              [-56.5198, 46.8061],
              [-56.3798, 46.9702],
              [-56.3849, 47.2296],
              [-56.1805, 47.1484],
            ],
          ],
        },
        properties: { name: "Saint Pierre and Miquelon" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-128.4739, -24.3403],
              [-128.4006, -24.2028],
              [-128.2309, -24.1758],
              [-128.2155, -24.5814],
              [-128.385, -24.5115],
              [-128.4739, -24.3403],
            ],
          ],
        },
        properties: { name: "Pitcairn Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-67.2167, 18.3365],
              [-67.0683, 18.4872],
              [-66.4737, 18.4709],
              [-65.9003, 18.4404],
              [-65.626, 18.2782],
              [-65.95, 17.9884],
              [-66.4737, 17.9896],
              [-67.0637, 17.9836],
              [-67.2167, 18.3365],
            ],
          ],
        },
        properties: { name: "Puerto Rico" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [35.476, 31.4905],
                [34.9515, 31.4243],
                [35.0826, 31.7397],
                [35.0135, 32.3104],
                [35.2216, 32.5167],
                [35.5551, 32.389],
                [35.476, 31.4905],
              ],
            ],
            [
              [
                [34.2688, 31.2205],
                [34.2198, 31.3217],
                [34.234, 31.3334],
                [34.4729, 31.5726],
                [34.4909, 31.5959],
                [34.4994, 31.4912],
                [34.2688, 31.2205],
              ],
            ],
          ],
        },
        properties: { name: "Palestine" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-7.4019, 37.1748],
                [-8.0741, 37.053],
                [-8.8579, 37.1415],
                [-8.7963, 37.4429],
                [-8.7924, 37.694],
                [-8.7351, 38.5157],
                [-9.1747, 38.4665],
                [-9.4452, 38.7632],
                [-9.4165, 39.0547],
                [-9.0403, 39.7414],
                [-8.8949, 40.0455],
                [-8.7749, 40.5145],
                [-8.6531, 40.9648],
                [-8.7764, 41.472],
                [-8.8116, 41.6116],
                [-8.8632, 41.8721],
                [-8.199, 42.1544],
                [-8.1651, 41.8183],
                [-8.0519, 41.8206],
                [-7.2005, 41.8797],
                [-6.9858, 41.9714],
                [-6.6375, 41.8984],
                [-6.3008, 41.5924],
                [-6.4797, 41.2944],
                [-6.6898, 41.2052],
                [-6.9299, 41.0295],
                [-6.8022, 40.5288],
                [-6.8651, 40.2707],
                [-6.9513, 40.2574],
                [-7.0106, 39.7363],
                [-7.5429, 39.6628],
                [-7.2315, 39.2784],
                [-7.0209, 39.0127],
                [-7.2031, 38.751],
                [-7.2758, 38.4539],
                [-7.108, 38.1881],
                [-6.9317, 38.2084],
                [-7.4457, 37.694],
                [-7.5127, 37.5263],
                [-7.4019, 37.1748],
              ],
            ],
            [
              [
                [-15.8103, 29.9885],
                [-15.9142, 29.8996],
                [-16.1934, 29.9542],
                [-16.2041, 30.0776],
                [-16.0535, 30.2138],
                [-15.8103, 29.9885],
              ],
            ],
            [
              [
                [-16.6542, 32.7289],
                [-16.9703, 32.5887],
                [-17.1775, 32.7607],
                [-16.8521, 32.8591],
                [-16.6542, 32.7289],
              ],
            ],
            [
              [
                [-25.1259, 37.7314],
                [-25.5507, 37.6875],
                [-25.6229, 37.7689],
                [-25.5234, 37.8623],
                [-25.099, 37.8667],
                [-25.1259, 37.7314],
              ],
            ],
            [
              [
                [-25.6029, 37.8163],
                [-25.7462, 37.705],
                [-25.9489, 37.8788],
                [-25.8024, 37.9838],
                [-25.6029, 37.8163],
              ],
            ],
            [
              [
                [-27.0259, 38.8346],
                [-26.9863, 38.5909],
                [-27.3268, 38.6063],
                [-27.4303, 38.7367],
                [-27.0259, 38.8346],
              ],
            ],
            [
              [
                [-28.2995, 38.5007],
                [-28.3758, 38.4157],
                [-28.7859, 38.4612],
                [-28.9592, 38.6194],
                [-28.713, 38.7097],
                [-28.2995, 38.5007],
              ],
            ],
            [
              [
                [-31.025, 39.4895],
                [-31.1392, 39.2852],
                [-31.3079, 39.287],
                [-31.3542, 39.4642],
                [-31.2793, 39.625],
                [-31.025, 39.4895],
              ],
            ],
          ],
        },
        properties: { name: "Portugal" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [134.679, 7.7471],
                [134.6162, 7.2854],
                [134.403, 7.3024],
                [134.5227, 7.6612],
                [134.679, 7.7471],
              ],
            ],
            [
              [
                [134.2794, 6.8402],
                [133.9897, 6.7108],
                [134.0188, 7.0616],
                [134.1988, 7.0905],
                [134.2794, 6.8402],
              ],
            ],
            [
              [
                [131.2904, 3.0827],
                [131.1123, 2.7578],
                [130.9605, 3.0182],
                [131.2545, 3.2259],
                [131.2904, 3.0827],
              ],
            ],
          ],
        },
        properties: { name: "Palau" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-58.1635, -20.1625],
              [-57.863, -20.9629],
              [-57.8684, -22.0614],
              [-57.0908, -22.2421],
              [-56.4163, -22.1247],
              [-55.8971, -22.3237],
              [-55.3922, -23.8396],
              [-54.6633, -23.8829],
              [-54.3851, -24.0606],
              [-54.5941, -25.5923],
              [-54.7923, -26.5932],
              [-55.4625, -27.0428],
              [-55.7448, -27.4423],
              [-56.1084, -27.3439],
              [-56.4174, -27.5161],
              [-58.5741, -27.253],
              [-57.6289, -25.5065],
              [-57.6904, -25.2889],
              [-59.9462, -24.0628],
              [-60.9507, -23.795],
              [-61.9084, -23.0815],
              [-62.6452, -22.2505],
              [-62.6104, -22.1424],
              [-62.2348, -20.5542],
              [-61.658, -19.6476],
              [-59.9788, -19.3035],
              [-59.1211, -19.2995],
              [-58.2561, -19.7822],
              [-58.1635, -20.1625],
            ],
          ],
        },
        properties: { name: "Paraguay" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [51.3072, 24.5064],
              [50.815, 24.7395],
              [50.8625, 24.8402],
              [50.7772, 25.1654],
              [50.8085, 25.5978],
              [50.8094, 25.6029],
              [51.2627, 26.0579],
              [51.4887, 25.9122],
              [51.5749, 25.0543],
              [51.3072, 24.5064],
            ],
          ],
        },
        properties: { name: "Qatar" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [28.2114, 45.4673],
              [28.6519, 45.2511],
              [29.2976, 45.4112],
              [29.667, 45.3119],
              [29.6796, 45.2118],
              [29.5569, 44.874],
              [28.9941, 44.6796],
              [28.7122, 44.3774],
              [28.5789, 43.7387],
              [27.6954, 43.9873],
              [27.2713, 44.1263],
              [26.3818, 44.0386],
              [26.3582, 44.0372],
              [25.6722, 43.6881],
              [25.5445, 43.6428],
              [25.2964, 43.6554],
              [24.6548, 43.7236],
              [24.3235, 43.6977],
              [24.112, 43.6999],
              [23.6304, 43.791],
              [23.1554, 43.8123],
              [22.9972, 43.8079],
              [22.9664, 44.0983],
              [22.6752, 44.2157],
              [22.4997, 44.6117],
              [22.0161, 44.5992],
              [22.0124, 44.6023],
              [21.3585, 44.8216],
              [21.4792, 45.193],
              [21.0166, 45.3246],
              [20.6628, 45.7941],
              [20.2643, 46.1264],
              [20.7053, 46.1609],
              [20.7756, 46.2759],
              [21.1475, 46.3359],
              [21.4414, 46.6515],
              [21.659, 47.0221],
              [22.1283, 47.5981],
              [22.1808, 47.6001],
              [22.8963, 47.9541],
              [23.1554, 48.1127],
              [23.4936, 47.9678],
              [24.5608, 47.9339],
              [24.9471, 47.7291],
              [26.0988, 47.9788],
              [26.6306, 48.2597],
              [27.1926, 47.9044],
              [27.3912, 47.5894],
              [28.1138, 46.8384],
              [28.2283, 46.438],
              [28.1158, 46.1078],
              [28.2114, 45.4673],
            ],
          ],
        },
        properties: { name: "Romania" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [22.6752, 44.2157],
              [22.5361, 44.0455],
              [22.4069, 43.7517],
              [22.667, 43.4027],
              [22.7475, 43.3866],
              [23.0062, 43.1929],
              [22.7484, 42.8898],
              [22.5155, 42.8685],
              [22.4428, 42.8255],
              [22.4615, 42.6485],
              [22.3602, 42.3112],
              [21.5869, 42.2628],
              [21.443, 42.2349],
              [21.2127, 42.1107],
              [20.9223, 42.1087],
              [20.5943, 41.8773],
              [20.5181, 42.1059],
              [20.4701, 42.2156],
              [20.2649, 42.3929],
              [20.0763, 42.5558],
              [20.3529, 42.8334],
              [20.0639, 43.0068],
              [19.2241, 43.5275],
              [19.4764, 43.6372],
              [19.4971, 44.0991],
              [19.1422, 44.422],
              [19.3512, 44.8891],
              [19.0221, 44.8554],
              [19.1373, 45.1187],
              [19.4252, 45.1677],
              [19.0771, 45.3405],
              [19.0053, 45.4346],
              [19.0339, 45.4868],
              [18.8897, 45.9212],
              [19.3027, 45.9916],
              [19.6981, 46.1879],
              [19.8654, 46.1503],
              [20.1365, 46.1341],
              [20.2643, 46.1264],
              [20.6628, 45.7941],
              [21.0166, 45.3246],
              [21.4792, 45.193],
              [21.3585, 44.8216],
              [22.0124, 44.6023],
              [22.0161, 44.5992],
              [22.4997, 44.6117],
              [22.6752, 44.2157],
            ],
          ],
        },
        properties: { name: "Serbia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [30.5433, -2.4132],
              [30.0189, -2.4386],
              [29.7073, -2.7799],
              [29.1824, -2.638],
              [29.0406, -2.7438],
              [28.9465, -2.3881],
              [28.9949, -2.2733],
              [29.2083, -1.766],
              [29.5895, -1.3866],
              [30.0925, -1.3689],
              [30.4735, -1.0574],
              [30.7956, -1.5802],
              [30.8216, -1.766],
              [30.8289, -2.1109],
              [30.6755, -2.2733],
              [30.5433, -2.4132],
            ],
          ],
        },
        properties: { name: "Rwanda" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [148.9425, 45.5027],
                [148.882, 45.3125],
                [148.4784, 45.3935],
                [148.6407, 45.5687],
                [148.9425, 45.5027],
              ],
            ],
            [
              [
                [147.1653, 44.6366],
                [147.0266, 44.414],
                [146.8157, 44.5694],
                [146.9151, 44.7252],
                [147.1664, 44.7272],
                [147.2386, 44.8552],
                [147.8835, 45.2387],
                [147.9437, 45.4666],
                [148.2504, 45.1744],
                [147.9142, 44.96],
                [147.6584, 44.958],
                [147.5901, 44.8417],
                [147.1653, 44.6366],
              ],
            ],
            [
              [
                [146.3048, 44.3015],
                [145.9094, 44.0684],
                [145.8106, 44.133],
                [146.0545, 44.4502],
                [146.3048, 44.3015],
              ],
            ],
            [
              [
                [145.6386, 43.8878],
                [145.5989, 43.6582],
                [145.3799, 43.6597],
                [145.3254, 43.841],
                [145.5652, 43.9908],
                [145.6386, 43.8878],
              ],
            ],
          ],
        },
        properties: { name: "Kuril Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-75.2511, 18.5294],
              [-74.8958, 18.5166],
              [-74.8365, 18.2647],
              [-75.0892, 18.2897],
              [-75.2511, 18.5294],
            ],
          ],
        },
        properties: { name: "Navassa Island" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [117.6048, 15.2975],
              [117.6846, 15.3492],
              [117.811, 15.2409],
              [117.9928, 15.0852],
              [117.9236, 15.0038],
              [117.6374, 15.0731],
              [117.6048, 15.2975],
            ],
          ],
        },
        properties: { name: "Scarborough Reef" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [123.1791, 25.6798],
              [123.5462, 25.8885],
              [123.6814, 25.7112],
              [123.2995, 25.5839],
              [123.1791, 25.6798],
            ],
          ],
        },
        properties: { name: "Senkaku Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [54.7024, -15.8799],
                [54.6127, -16.1313],
                [54.3092, -15.7546],
                [54.4674, -15.7155],
                [54.7024, -15.8799],
              ],
            ],
            [
              [
                [47.5513, -11.4763],
                [47.4245, -11.715],
                [47.1644, -11.5048],
                [47.4023, -11.3411],
                [47.5513, -11.4763],
              ],
            ],
            [
              [
                [42.9694, -17.1823],
                [42.6813, -17.2191],
                [42.5171, -17.0453],
                [42.8528, -16.9499],
                [42.9694, -17.1823],
              ],
            ],
            [
              [
                [40.4369, -22.1561],
                [40.3489, -22.5928],
                [40.1548, -22.3855],
                [40.1858, -22.2105],
                [40.4369, -22.1561],
              ],
            ],
            [
              [
                [39.9182, -21.5501],
                [39.652, -21.6126],
                [39.549, -21.428],
                [39.8571, -21.2869],
                [39.9182, -21.5501],
              ],
            ],
          ],
        },
        properties: { name: "Bassas Da India" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [28.977, 9.6412],
              [28.5512, 9.3479],
              [28.0981, 9.3663],
              [27.9072, 9.6102],
              [27.8156, 10.0193],
              [27.9298, 10.1398],
              [28.9293, 10.1061],
              [28.977, 9.6412],
            ],
          ],
        },
        properties: { name: "Abyei" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [33.1826, 22.0017],
              [34.0865, 22.0025],
              [33.9661, 21.7899],
              [33.6406, 21.7398],
              [33.1826, 22.0017],
            ],
          ],
        },
        properties: { name: "Bir Tawil (Disputed Territory)" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [53.6461, 12.7028],
                [53.8461, 12.6042],
                [54.0848, 12.7012],
                [54.4634, 12.5445],
                [54.1329, 12.3503],
                [53.7284, 12.3021],
                [53.5462, 12.3426],
                [53.3068, 12.5372],
                [53.3993, 12.6551],
                [53.6461, 12.7028],
              ],
            ],
            [
              [
                [52, 19],
                [53.107, 16.6544],
                [52.4364, 16.3497],
                [52.1341, 15.654],
                [51.5872, 15.3288],
                [49.5638, 14.7052],
                [48.6192, 14.0676],
                [45.6726, 13.3012],
                [44.9607, 12.7815],
                [44.1175, 12.6413],
                [43.5481, 12.8001],
                [43.282, 13.3288],
                [42.8826, 15.0012],
                [42.6016, 15.3149],
                [42.7639, 16.4046],
                [43.183, 16.6552],
                [43.3633, 17.4977],
                [45.6126, 17.308],
                [46.7083, 17.2521],
                [47.1932, 16.9779],
                [48.1849, 18.1433],
                [49.0135, 18.5638],
                [52, 19],
              ],
            ],
            [
              [
                [42.5415, 16.2997],
                [42.358, 16.1296],
                [42.2473, 16.233],
                [42.2172, 16.3172],
                [42.2973, 16.4013],
                [42.5415, 16.2997],
              ],
            ],
          ],
        },
        properties: { name: "Yemen" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [38.0254, -46.8953],
                [37.8873, -47.0414],
                [37.5201, -46.9346],
                [37.5976, -46.7765],
                [38.0254, -46.8953],
              ],
            ],
            [
              [
                [31.9821, -25.952],
                [31.338, -25.8256],
                [30.9531, -26.2532],
                [30.8609, -26.7714],
                [31.2284, -27.2042],
                [31.5496, -27.3065],
                [31.94, -27.2766],
                [32.1337, -26.835],
                [32.8915, -26.8583],
                [32.7946, -27.2265],
                [32.3221, -28.5947],
                [31.3118, -29.4248],
                [30.0787, -31.214],
                [28.4291, -32.6388],
                [27.0545, -33.5418],
                [26.4843, -33.7479],
                [26.0025, -33.7064],
                [25.5483, -34.0063],
                [25.0772, -33.9884],
                [24.7605, -34.1775],
                [22.4286, -34.0265],
                [21.8498, -34.3301],
                [19.6936, -34.762],
                [18.7471, -34.1344],
                [18.3898, -34.0763],
                [18.4155, -33.6879],
                [17.8844, -32.9057],
                [18.2742, -32.6388],
                [18.2235, -31.8078],
                [17.3617, -30.4882],
                [16.8538, -29.1859],
                [16.4556, -28.6347],
                [17.0144, -28.0716],
                [17.32, -28.29],
                [17.4762, -28.6959],
                [18.2081, -28.8908],
                [19.162, -28.9253],
                [19.9434, -28.4086],
                [19.9995, -24.752],
                [20.4129, -25.096],
                [20.6983, -25.63],
                [20.8245, -26.0714],
                [20.7004, -26.8181],
                [21.2827, -26.8663],
                [21.661, -26.8299],
                [22.5598, -26.1926],
                [23.0327, -25.3248],
                [23.4396, -25.3063],
                [24.4722, -25.7662],
                [25.3317, -25.7424],
                [25.5853, -25.5825],
                [26.0025, -24.7193],
                [26.7949, -24.2778],
                [27.0526, -23.648],
                [28.2714, -22.6602],
                [29.3608, -22.1966],
                [31.3113, -22.4177],
                [31.9765, -24.3034],
                [31.9821, -25.952],
              ],
              [
                [27.0327, -29.6171],
                [27.3808, -30.2],
                [27.7514, -30.5918],
                [28.1178, -30.6655],
                [28.4128, -30.1401],
                [28.8475, -30.093],
                [29.172, -29.9121],
                [29.1825, -29.665],
                [29.4624, -29.352],
                [29.3202, -29.0855],
                [28.9973, -28.9141],
                [28.6524, -28.5749],
                [28.1938, -28.6929],
                [28.0366, -28.8784],
                [27.7666, -28.8974],
                [27.37, -29.4774],
                [27.0327, -29.6171],
              ],
            ],
          ],
        },
        properties: { name: "South Africa" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [32.9535, -9.4014],
              [33.6324, -10.4637],
              [33.2902, -10.9113],
              [33.4181, -12.4294],
              [33.0256, -12.7461],
              [32.8162, -13.5758],
              [33.2422, -13.9985],
              [31.8523, -14.4588],
              [30.3073, -14.9824],
              [30.4183, -15.6176],
              [29.2568, -15.7693],
              [28.8954, -16.0421],
              [28.8357, -16.3334],
              [27.8657, -16.9601],
              [26.8734, -18.0039],
              [25.2703, -17.7989],
              [25.2628, -17.7914],
              [24.5991, -17.4938],
              [23.4299, -17.6345],
              [22.1075, -16.3334],
              [22.0281, -13.0808],
              [23.8836, -12.9513],
              [24.0347, -12.3467],
              [23.983, -10.9113],
              [24.1243, -10.9113],
              [24.396, -11.3999],
              [25.2205, -11.26],
              [25.4764, -11.7233],
              [26.0386, -11.9177],
              [26.7612, -11.9646],
              [27.1699, -11.6261],
              [27.6036, -12.2134],
              [28.4077, -12.5205],
              [29.0399, -13.3796],
              [29.5569, -13.2535],
              [29.7527, -13.4086],
              [29.7932, -12.2818],
              [29.1044, -12.3704],
              [28.4861, -11.8258],
              [28.5408, -10.9113],
              [28.6626, -9.794],
              [28.454, -9.2529],
              [29.016, -8.5161],
              [30.7712, -8.1922],
              [31.0424, -8.5543],
              [31.8419, -8.8977],
              [32.9535, -9.4014],
            ],
          ],
        },
        properties: { name: "Zambia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [31.3113, -22.4177],
              [29.3608, -22.1966],
              [28.9194, -21.7879],
              [28.123, -21.5265],
              [27.7788, -21.1231],
              [27.6166, -20.5719],
              [26.1873, -19.5021],
              [25.2703, -17.7989],
              [26.8734, -18.0039],
              [27.8657, -16.9601],
              [28.8357, -16.3334],
              [28.8954, -16.0421],
              [29.2568, -15.7693],
              [30.4183, -15.6176],
              [30.5713, -15.9585],
              [31.1972, -16.04],
              [32.8225, -16.6907],
              [33.0194, -17.3795],
              [33.0077, -18.2738],
              [32.7663, -18.9699],
              [32.9546, -19.9683],
              [32.1429, -21.5651],
              [31.3113, -22.4177],
            ],
          ],
        },
        properties: { name: "Zimbabwe" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-134.7579, -23.1154],
                [-134.7909, -23.3127],
                [-135.1201, -23.1118],
                [-134.9228, -22.9104],
                [-134.7579, -23.1154],
              ],
            ],
            [
              [
                [-136.2456, -21.4833],
                [-136.3384, -21.6367],
                [-136.6038, -21.5361],
                [-136.4457, -21.247],
                [-136.2456, -21.4833],
              ],
            ],
            [
              [
                [-139.9806, -8.8384],
                [-140.2076, -9.0745],
                [-140.3476, -8.7899],
                [-140.1728, -8.6834],
                [-139.9806, -8.8384],
              ],
            ],
            [
              [
                [-140.1892, -19.6886],
                [-140.5305, -19.7723],
                [-140.611, -19.642],
                [-140.4799, -19.4334],
                [-140.1892, -19.6886],
              ],
            ],
            [
              [
                [-141.2121, -13.9895],
                [-141.2349, -14.3925],
                [-141.3503, -14.3622],
                [-141.4912, -14.0328],
                [-141.2121, -13.9895],
              ],
            ],
            [
              [
                [-142.6566, -16.7862],
                [-142.9553, -16.8839],
                [-142.9694, -16.5838],
                [-142.7705, -16.5391],
                [-142.6566, -16.7862],
              ],
            ],
            [
              [
                [-144.3074, -14.9291],
                [-144.5782, -15.0848],
                [-144.7973, -15.0784],
                [-144.6374, -14.8603],
                [-144.4682, -14.8155],
                [-144.3074, -14.9291],
              ],
            ],
            [
              [
                [-145.2975, -17.5118],
                [-145.41, -17.607],
                [-145.7677, -17.2639],
                [-145.5388, -17.2302],
                [-145.2975, -17.5118],
              ],
            ],
            [
              [
                [-146.2769, -15.6763],
                [-146.3912, -15.7489],
                [-146.5943, -15.4177],
                [-146.4065, -15.2788],
                [-146.2769, -15.6763],
              ],
            ],
            [
              [
                [-149.2971, -17.5072],
                [-149.2994, -17.7917],
                [-149.6171, -17.773],
                [-149.675, -17.5456],
                [-149.2971, -17.5072],
              ],
            ],
          ],
        },
        properties: { name: "French Polynesia" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [174.9552, -1.4438],
                [174.8784, -1.5532],
                [174.6115, -1.0983],
                [174.7712, -1.0228],
                [174.9552, -1.4438],
              ],
            ],
            [
              [
                [173.3124, 1.339],
                [172.885, 1.2419],
                [172.7632, 1.3067],
                [173.1087, 1.5196],
                [173.3124, 1.339],
              ],
            ],
            [
              [
                [-150.2038, -10.0616],
                [-150.2717, -10.2322],
                [-150.3905, -10.1317],
                [-150.3674, -9.9742],
                [-150.185, -9.8879],
                [-150.0565, -9.7159],
                [-149.9841, -9.8257],
                [-150.2038, -10.0616],
              ],
            ],
            [
              [
                [-151.6949, -11.5565],
                [-151.8044, -11.6107],
                [-151.9819, -11.333],
                [-151.9549, -11.106],
                [-151.825, -11.2001],
                [-151.6949, -11.5565],
              ],
            ],
            [
              [
                [-157.2719, 1.7957],
                [-157.4346, 1.7279],
                [-157.651, 1.8745],
                [-157.4777, 2.1201],
                [-157.2719, 1.7957],
              ],
            ],
            [
              [
                [-159.104, 3.8492],
                [-159.3473, 3.7047],
                [-159.5227, 3.9568],
                [-159.3991, 4.065],
                [-159.104, 3.8492],
              ],
            ],
            [
              [
                [-171.3732, -3.5481],
                [-171.4838, -3.8226],
                [-171.7045, -3.4779],
                [-171.565, -3.3669],
                [-171.3732, -3.5481],
              ],
            ],
          ],
        },
        properties: { name: "Kiribati" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [41.5471, 41.5204],
                [42.5152, 41.4383],
                [42.815, 41.5555],
                [43.4738, 41.1233],
                [43.4696, 41.0573],
                [43.7018, 40.7773],
                [43.6539, 40.1304],
                [44.3752, 40.001],
                [44.7683, 39.7141],
                [44.812, 39.6317],
                [44.5021, 39.717],
                [44.3729, 39.452],
                [44.05, 39.3628],
                [44.4142, 38.2972],
                [44.2847, 37.9314],
                [44.4629, 37.8096],
                [44.6485, 37.4271],
                [44.7931, 37.1763],
                [44.3175, 37.0426],
                [44.1298, 37.279],
                [43.4246, 37.2753],
                [42.8718, 37.37],
                [42.3557, 37.108],
                [42.1596, 37.2329],
                [41.6633, 37.1029],
                [40.7628, 37.096],
                [40.226, 36.9014],
                [39.2161, 36.6749],
                [38.0476, 36.8456],
                [37.5855, 36.7038],
                [37.16, 36.6767],
                [36.6788, 36.8327],
                [36.66, 36.8332],
                [36.6168, 36.2635],
                [36.2014, 35.8768],
                [35.9168, 35.9285],
                [35.8433, 36.3198],
                [36.178, 36.6918],
                [35.9631, 36.903],
                [35.4678, 36.5777],
                [34.8992, 36.7383],
                [34.3261, 36.6099],
                [33.6143, 36.1545],
                [32.5763, 36.0932],
                [31.8435, 36.5909],
                [30.8497, 36.8627],
                [30.6382, 36.8079],
                [30.3761, 36.3065],
                [29.7811, 36.1705],
                [29.2606, 36.3044],
                [28.5149, 36.8091],
                [27.9346, 36.6959],
                [27.9561, 36.9364],
                [27.4194, 37.0671],
                [27.4107, 37.4271],
                [27.25, 37.5028],
                [27.2646, 37.875],
                [26.4588, 38.2949],
                [26.8499, 38.7906],
                [26.7639, 39.1752],
                [26.7978, 39.505],
                [26.6187, 39.5479],
                [26.2563, 39.4912],
                [26.1284, 39.6273],
                [26.1063, 39.9289],
                [26.3733, 40.3468],
                [27.5063, 40.3047],
                [28.1648, 40.3956],
                [28.8484, 40.3813],
                [28.9912, 40.4663],
                [28.9906, 40.5902],
                [29.5409, 40.7025],
                [29.3425, 40.8077],
                [28.9343, 40.9418],
                [29.1373, 41.2447],
                [29.8656, 41.1434],
                [30.3547, 41.1842],
                [30.9821, 41.0726],
                [31.2957, 41.1163],
                [32.0626, 41.5803],
                [32.4456, 41.7331],
                [32.7361, 41.849],
                [33.3698, 42.0073],
                [34.2289, 41.955],
                [35.0028, 42.0121],
                [35.3818, 41.7331],
                [35.5137, 41.636],
                [35.9494, 41.6995],
                [36.3344, 41.3177],
                [36.7806, 41.3414],
                [37.1523, 41.1484],
                [38.11, 40.9585],
                [39.1793, 41.0741],
                [40.3283, 40.9879],
                [41.2519, 41.3299],
                [41.5471, 41.5204],
              ],
            ],
            [
              [
                [26.0328, 40.7303],
                [26.5923, 41.4201],
                [26.3579, 41.7111],
                [26.5615, 41.9263],
                [26.9492, 42.0002],
                [27.0597, 42.0883],
                [28.0355, 41.9831],
                [28.1082, 41.7331],
                [28.153, 41.579],
                [28.1817, 41.5641],
                [28.8484, 41.2849],
                [28.8484, 40.9701],
                [27.9911, 41.0183],
                [27.5657, 40.9609],
                [26.9687, 40.5534],
                [26.7349, 40.6429],
                [26.0328, 40.7303],
              ],
            ],
            [
              [
                [26.0736, 40.1324],
                [25.7962, 40.0214],
                [25.6179, 40.1527],
                [26.0253, 40.3299],
                [26.0736, 40.1324],
              ],
            ],
          ],
        },
        properties: { name: "Türkiye" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-61.633, 10.1375],
              [-61.4993, 10.6322],
              [-61.4387, 10.7532],
              [-60.9865, 10.8108],
              [-61.0316, 10.6322],
              [-61.0278, 10.1839],
              [-61.2816, 10.0874],
              [-61.633, 10.1375],
            ],
          ],
        },
        properties: { name: "Trinidad and Tobago" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [177.0395, -6.9988],
              [177.2368, -7.0769],
              [177.2696, -7.3884],
              [177.0657, -7.4184],
              [177.0395, -6.9988],
            ],
          ],
        },
        properties: { name: "Tuvalu" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [34.9596, -11.5733],
                [34.6491, -10.9113],
                [34.4913, -10.0245],
                [34.1574, -9.6216],
                [32.9535, -9.4014],
                [31.8419, -8.8977],
                [31.0424, -8.5543],
                [30.7712, -8.1922],
                [30.2771, -7.1145],
                [29.5926, -6.3587],
                [29.5996, -5.587],
                [29.3874, -5.0153],
                [29.4233, -4.4475],
                [30.0119, -4.2813],
                [30.8015, -3.2214],
                [30.806, -3.0324],
                [30.472, -2.8532],
                [30.5433, -2.4132],
                [30.6755, -2.2733],
                [30.8289, -2.1109],
                [30.8216, -1.766],
                [30.7956, -1.5802],
                [30.4735, -1.0574],
                [31.8419, -0.9999],
                [33.934, -0.9999],
                [35.3306, -1.766],
                [35.4478, -1.8303],
                [35.6903, -1.9633],
                [36.2553, -2.2733],
                [37.5178, -2.9658],
                [37.7537, -3.6303],
                [39.2042, -4.6703],
                [39.2074, -4.6813],
                [38.8561, -6.1048],
                [39.4608, -6.9615],
                [39.2874, -8.3571],
                [39.8111, -9.8797],
                [40.3944, -10.5107],
                [39.7605, -10.9113],
                [38.5264, -11.3686],
                [37.9279, -11.3183],
                [37.4237, -11.6577],
                [36.4576, -11.7048],
                [35.8549, -11.4522],
                [34.9596, -11.5733],
              ],
            ],
            [
              [
                [39.7978, -5.4211],
                [39.5471, -5.4242],
                [39.713, -4.8848],
                [39.8457, -4.9639],
                [39.7978, -5.4211],
              ],
            ],
            [
              [
                [39.5402, -6.3985],
                [39.3763, -6.4222],
                [39.1421, -6.1478],
                [39.2312, -5.8857],
                [39.4504, -6.0408],
                [39.5402, -6.3985],
              ],
            ],
          ],
        },
        properties: { name: "United Republic of Tanzania" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [38.231, 47.1187],
              [34.4589, 46.1535],
              [34.51, 45.8949],
              [35.228, 45.369],
              [36.2129, 45.4559],
              [36.4191, 45.2813],
              [36.2692, 45.0994],
              [35.4954, 45.0545],
              [34.2033, 44.527],
              [33.6689, 44.5026],
              [33.4795, 45.0505],
              [32.7281, 45.4251],
              [33.3489, 46.0144],
              [31.9721, 46.2089],
              [31.9859, 46.6013],
              [31.017, 46.551],
              [29.9149, 45.6926],
              [29.667, 45.3119],
              [29.2976, 45.4112],
              [28.6519, 45.2511],
              [28.2114, 45.4673],
              [28.7122, 45.8204],
              [29.0061, 46.1012],
              [29.0181, 46.4516],
              [29.8609, 46.431],
              [29.9594, 46.6108],
              [29.5435, 47.3214],
              [29.223, 47.477],
              [29.1968, 47.9044],
              [28.9245, 47.9859],
              [28.7544, 48.135],
              [27.6199, 48.4649],
              [26.6306, 48.2597],
              [26.0988, 47.9788],
              [24.9471, 47.7291],
              [24.5608, 47.9339],
              [23.4936, 47.9678],
              [23.1554, 48.1127],
              [22.8963, 47.9541],
              [22.1553, 48.4034],
              [22.1758, 48.5744],
              [22.3336, 48.7016],
              [22.3828, 48.8623],
              [22.5668, 49.0884],
              [22.7088, 49.2022],
              [22.6861, 49.5732],
              [23.5476, 50.2516],
              [24.096, 50.6123],
              [23.7092, 51.2776],
              [23.6174, 51.5095],
              [24.4673, 51.8703],
              [25.3462, 51.9465],
              [27.1051, 51.7564],
              [27.7579, 51.5175],
              [28.2365, 51.6329],
              [28.7544, 51.4177],
              [29.0542, 51.6149],
              [29.3584, 51.4228],
              [30.1126, 51.4902],
              [30.5213, 51.3278],
              [30.5778, 51.675],
              [30.9112, 52.0252],
              [31.7869, 52.1115],
              [33.7694, 52.2996],
              [34.3442, 51.8126],
              [34.307, 51.4173],
              [35.1589, 51.1293],
              [35.6852, 50.4062],
              [36.5636, 50.2607],
              [37.3983, 50.3916],
              [38.0083, 49.9928],
              [39.1438, 49.8497],
              [39.9709, 49.5281],
              [40.0937, 49.271],
              [39.7817, 48.848],
              [39.8787, 48.3158],
              [39.756, 47.9207],
              [38.5666, 47.6752],
              [38.231, 47.1187],
            ],
          ],
        },
        properties: { name: "Ukraine" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [33.934, -0.9999],
              [31.8419, -0.9999],
              [30.4735, -1.0574],
              [30.0925, -1.3689],
              [29.5895, -1.3866],
              [29.6422, -0.8786],
              [29.721, -0.1179],
              [29.7816, 0.0707],
              [29.8398, 0.252],
              [30.0157, 0.8003],
              [31.2496, 2.0752],
              [30.7765, 2.5135],
              [30.8545, 3.4875],
              [30.9998, 3.6956],
              [31.2097, 3.7851],
              [31.5619, 3.6758],
              [31.8199, 3.7886],
              [32.0987, 3.5511],
              [32.3907, 3.7291],
              [32.973, 3.8481],
              [33.4753, 3.7622],
              [33.9945, 4.2144],
              [34.4095, 3.6353],
              [34.5428, 3.1339],
              [34.8669, 2.5352],
              [34.9796, 1.7951],
              [34.5428, 1.1019],
              [34.0186, 0.252],
              [33.9422, -0.8786],
              [33.934, -0.9999],
            ],
          ],
        },
        properties: { name: "Uganda" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-0.1102, 51.5096],
                [-0.0247, 51.4856],
                [0.2174, 51.4801],
                [0.2309, 51.4732],
                [0.2398, 51.4687],
                [0.2441, 51.467],
                [0.2557, 51.4625],
                [0.2776, 51.4535],
                [0.2835, 51.4539],
                [0.2931, 51.4557],
                [0.3094, 51.4661],
                [0.3155, 51.465],
                [0.3291, 51.4508],
                [0.3397, 51.4475],
                [0.356, 51.4458],
                [0.3697, 51.4452],
                [0.4066, 51.4433],
                [0.4285, 51.4468],
                [0.4336, 51.4474],
                [0.4378, 51.4474],
                [0.4432, 51.45],
                [0.4589, 51.4549],
                [0.593, 51.4661],
                [0.6269, 51.3752],
                [0.7453, 51.3644],
                [0.9503, 51.3457],
                [1.2689, 51.3419],
                [1.3465, 51.2285],
                [0.964, 50.9267],
                [0.8548, 50.9237],
                [0.7453, 50.9085],
                [-0.0382, 50.7995],
                [-0.216, 50.8276],
                [-0.9413, 50.8252],
                [-1.0404, 50.8049],
                [-1.2098, 50.8064],
                [-1.3652, 50.8801],
                [-1.3983, 50.8896],
                [-1.692, 50.7366],
                [-1.7407, 50.7215],
                [-1.7859, 50.7203],
                [-1.8933, 50.7175],
                [-1.9642, 50.7156],
                [-2.7863, 50.7179],
                [-2.9478, 50.7183],
                [-3.3739, 50.615],
                [-3.509, 50.5165],
                [-3.4953, 50.4324],
                [-3.5603, 50.3856],
                [-3.7853, 50.258],
                [-4.0693, 50.3541],
                [-4.1622, 50.3762],
                [-4.1576, 50.4329],
                [-4.2186, 50.4752],
                [-5.1343, 50.0624],
                [-5.4569, 50.1431],
                [-4.546, 50.9284],
                [-3.7208, 51.2331],
                [-3.3739, 51.2083],
                [-3.2367, 51.1985],
                [-2.9929, 51.3203],
                [-2.7126, 51.5041],
                [-2.6738, 51.5444],
                [-2.5347, 51.6773],
                [-2.6661, 51.6394],
                [-2.7863, 51.5997],
                [-3.0827, 51.5019],
                [-3.3739, 51.3914],
                [-3.6379, 51.4698],
                [-3.8862, 51.6174],
                [-4.0525, 51.7033],
                [-4.9714, 51.6358],
                [-5.201, 51.7973],
                [-3.931, 52.5536],
                [-4.3644, 52.9835],
                [-4.0074, 53.2469],
                [-3.3739, 53.3392],
                [-3.0842, 53.2561],
                [-3.1107, 53.2963],
                [-3.0899, 53.4035],
                [-2.9286, 53.3083],
                [-2.7863, 53.3134],
                [-2.8267, 53.3317],
                [-2.8322, 53.336],
                [-3.0087, 53.4384],
                [-3.0672, 53.5672],
                [-2.9562, 53.6975],
                [-2.7863, 53.7265],
                [-2.9425, 53.8508],
                [-2.8754, 53.9739],
                [-2.8695, 54.1767],
                [-3.1453, 54.0955],
                [-3.5562, 54.5554],
                [-3.3788, 54.8232],
                [-3.1191, 54.9279],
                [-3.0574, 54.9869],
                [-3.3739, 54.9334],
                [-4.5498, 54.7347],
                [-5.0764, 54.8525],
                [-5.0412, 54.9989],
                [-4.8444, 55.3258],
                [-4.6583, 55.5703],
                [-4.8708, 55.6993],
                [-4.889, 55.8748],
                [-4.7892, 55.9521],
                [-4.5498, 55.9233],
                [-4.5498, 55.9356],
                [-4.6916, 56.0002],
                [-4.7487, 56.2033],
                [-5.2358, 55.5504],
                [-5.7501, 55.3713],
                [-5.665, 56.2692],
                [-6.1561, 56.5357],
                [-5.8807, 56.7063],
                [-5.7146, 57.2297],
                [-5.5044, 57.3669],
                [-5.5662, 57.7852],
                [-5.0712, 58.3285],
                [-3.8391, 58.3993],
                [-4.4321, 57.4944],
                [-3.3739, 57.6247],
                [-2.8015, 57.6952],
                [-1.9158, 57.4522],
                [-2.4253, 56.7552],
                [-2.5116, 56.6408],
                [-2.6789, 56.5182],
                [-3.0519, 56.4585],
                [-3.277, 56.3505],
                [-2.9528, 56.3097],
                [-2.9359, 56.3046],
                [-2.9215, 56.2944],
                [-2.9112, 56.2801],
                [-2.9061, 56.2633],
                [-2.9067, 56.2457],
                [-2.9129, 56.2292],
                [-2.9241, 56.2157],
                [-2.9392, 56.2063],
                [-3.3747, 56.0293],
                [-3.3854, 56.0257],
                [-3.3941, 56.0271],
                [-3.3999, 56.0248],
                [-3.3889, 56.0224],
                [-3.3904, 56.0172],
                [-3.3883, 56.0121],
                [-3.3909, 56.0066],
                [-3.3958, 56.0095],
                [-3.4036, 56.0101],
                [-3.4084, 56.0143],
                [-3.4141, 56.0171],
                [-3.4415, 56.0228],
                [-3.4466, 56.0211],
                [-3.4485, 56.0194],
                [-3.4544, 56.0217],
                [-3.4584, 56.0274],
                [-3.4861, 56.0344],
                [-3.5041, 56.0342],
                [-3.5186, 56.0414],
                [-3.5298, 56.0398],
                [-3.5456, 56.0407],
                [-3.5548, 56.0442],
                [-3.5602, 56.0504],
                [-3.5765, 56.0588],
                [-3.5896, 56.0592],
                [-3.5863, 56.0549],
                [-3.5871, 56.0503],
                [-3.5897, 56.0474],
                [-3.5948, 56.046],
                [-3.6074, 56.0466],
                [-3.6141, 56.0543],
                [-3.6181, 56.0557],
                [-3.6502, 56.0529],
                [-3.6701, 56.0466],
                [-3.6852, 56.0481],
                [-3.7068, 56.0557],
                [-3.7256, 56.0697],
                [-3.7396, 56.0781],
                [-3.7494, 56.0801],
                [-3.7576, 56.0826],
                [-3.7618, 56.0839],
                [-3.7759, 56.0937],
                [-3.7924, 56.1055],
                [-3.8041, 56.1104],
                [-3.8197, 56.1109],
                [-3.8294, 56.1028],
                [-3.8311, 56.1047],
                [-3.8328, 56.1066],
                [-3.8271, 56.1099],
                [-3.8277, 56.1143],
                [-3.835, 56.1181],
                [-3.8489, 56.1222],
                [-3.857, 56.1204],
                [-3.8582, 56.118],
                [-3.8588, 56.1101],
                [-3.864, 56.1072],
                [-3.8591, 56.1076],
                [-3.8566, 56.1088],
                [-3.8541, 56.1121],
                [-3.8557, 56.1182],
                [-3.8486, 56.1171],
                [-3.8382, 56.1149],
                [-3.8409, 56.107],
                [-3.8375, 56.1015],
                [-3.832, 56.0992],
                [-3.8202, 56.0991],
                [-3.6946, 56.0239],
                [-3.5157, 56.0023],
                [-3.4253, 55.994],
                [-3.0776, 55.9469],
                [-2.3666, 55.9461],
                [-2.0343, 55.8112],
                [-1.8933, 55.7117],
                [-1.7859, 55.636],
                [-1.6281, 55.5231],
                [-1.5573, 55.3074],
                [-1.4617, 55.0744],
                [-1.3639, 54.9442],
                [-1.3474, 54.8607],
                [-1.2422, 54.7226],
                [-1.2089, 54.5805],
                [-0.7909, 54.5595],
                [-0.2143, 54.154],
                [-0.1957, 53.9739],
                [-0.0491, 53.7654],
                [-0.2963, 53.7654],
                [-0.4191, 53.7196],
                [-0.6984, 53.6847],
                [-0.4014, 53.6921],
                [0.0174, 53.5254],
                [0.2763, 53.2308],
                [0.1167, 52.9094],
                [0.266, 52.8101],
                [0.7453, 52.918],
                [0.9463, 52.9633],
                [1.3301, 52.9157],
                [1.6755, 52.7427],
                [1.7405, 52.5321],
                [1.52, 52.0943],
                [1.0564, 51.9515],
                [0.9463, 51.7343],
                [0.7453, 51.6757],
                [0.5908, 51.6306],
                [0.6069, 51.5176],
                [0.5137, 51.5312],
                [0.3815, 51.4592],
                [0.2105, 51.49],
                [0.097, 51.5157],
                [0.0094, 51.5071],
                [-0.0794, 51.5078],
                [-0.1102, 51.5096],
              ],
              [
                [-0.1102, 51.5096],
                [-0.1126, 51.5104],
                [-0.1499, 51.4855],
                [-0.2445, 51.4887],
                [-0.2228, 51.4718],
                [-0.1284, 51.4851],
                [-0.1102, 51.5096],
              ],
            ],
            [
              [
                [0.6222, 51.5405],
                [0.636, 51.5764],
                [0.7453, 51.5596],
                [0.788, 51.553],
                [0.7902, 51.5223],
                [0.7453, 51.5272],
                [0.6222, 51.5405],
              ],
            ],
            [
              [
                [-1.4369, 60.4236],
                [-1.3351, 60.5431],
                [-1.1501, 60.4735],
                [-1.1498, 60.743],
                [-0.8706, 60.743],
                [-1.1669, 60.241],
                [-1.3213, 60.1145],
                [-1.4369, 60.4236],
              ],
            ],
            [
              [
                [-1.5322, 59.8857],
                [-1.4423, 60.0197],
                [-1.0717, 59.8842],
                [-1.2856, 59.7158],
                [-1.4423, 59.8015],
                [-1.5322, 59.8857],
              ],
            ],
            [
              [
                [-1.4814, 50.6951],
                [-1.3794, 50.805],
                [-1.1285, 50.7707],
                [-1.1278, 50.5491],
                [-1.375, 50.5496],
                [-1.4814, 50.6951],
              ],
            ],
            [
              [
                [-2.7829, 59.1653],
                [-3.1724, 58.8149],
                [-3.3753, 58.9168],
                [-3.3179, 59.0737],
                [-2.9554, 59.314],
                [-2.7829, 59.1653],
              ],
            ],
            [
              [
                [-2.7829, 59.1653],
                [-2.7888, 59.1806],
                [-2.6063, 59.3241],
                [-2.5143, 59.1109],
                [-2.7118, 59.0122],
                [-2.7829, 59.1653],
              ],
            ],
            [
              [
                [-3.0996, 53.7385],
                [-3.0952, 53.8286],
                [-3.0099, 53.936],
                [-2.944, 53.7567],
                [-3.0996, 53.7385],
              ],
            ],
            [
              [
                [-4.6228, 53.3398],
                [-4.2798, 53.4379],
                [-4.1506, 53.245],
                [-4.3542, 53.1021],
                [-4.6228, 53.3398],
              ],
            ],
            [
              [
                [-7.2561, 55.067],
                [-7.1491, 55.0434],
                [-6.9366, 55.1703],
                [-6.2092, 55.2732],
                [-5.9765, 55.0566],
                [-5.8683, 54.6888],
                [-5.9052, 54.6553],
                [-5.8135, 54.6553],
                [-5.5493, 54.6553],
                [-5.4625, 54.4169],
                [-5.519, 54.3612],
                [-5.6191, 54.4588],
                [-5.6101, 54.3089],
                [-5.9681, 54.0619],
                [-6.268, 54.1023],
                [-6.595, 54.0447],
                [-6.6439, 54.18],
                [-6.8642, 54.3302],
                [-7.1885, 54.3377],
                [-7.4291, 54.1829],
                [-7.8411, 54.3349],
                [-7.5702, 54.6426],
                [-7.6551, 54.7729],
                [-7.2561, 55.067],
              ],
            ],
            [
              [
                [-6.7615, 57.4256],
                [-6.4199, 57.5571],
                [-6.301, 57.7386],
                [-5.9506, 57.3175],
                [-5.9931, 57.0247],
                [-6.159, 57.0852],
                [-6.3201, 56.86],
                [-6.4761, 56.9838],
                [-6.2245, 57.2117],
                [-6.3554, 57.3175],
                [-6.7103, 57.3175],
                [-6.7615, 57.4256],
              ],
            ],
            [
              [
                [-6.0409, 55.6844],
                [-6.1667, 55.6337],
                [-6.4548, 55.7973],
                [-6.3518, 55.8832],
                [-5.987, 55.9516],
                [-5.987, 55.7947],
                [-6.0409, 55.6844],
              ],
            ],
            [
              [
                [-7.2113, 57.5922],
                [-7.1682, 57.4962],
                [-7.3508, 57.093],
                [-7.4517, 57.1198],
                [-7.3672, 57.4036],
                [-7.4517, 57.6711],
                [-7.0812, 57.8133],
                [-7.1589, 58.0163],
                [-6.9642, 58.2507],
                [-6.3092, 58.4676],
                [-6.2033, 58.295],
                [-6.3734, 58.0163],
                [-6.8742, 57.7982],
                [-7.2113, 57.5922],
              ],
            ],
            [
              [
                [-7.8718, 58.2784],
                [-7.3775, 58.4243],
                [-7.462, 58.1623],
                [-7.798, 58.1889],
                [-7.8718, 58.2784],
              ],
            ],
            [
              [
                [-8.8381, 57.9178],
                [-8.3377, 57.8201],
                [-8.4254, 57.6463],
                [-8.7676, 57.7509],
                [-8.8381, 57.9178],
              ],
            ],
          ],
        },
        properties: { name: "United Kingdom" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [166.6872, 19.471],
                [166.8216, 19.1497],
                [166.5113, 19.2327],
                [166.3937, 19.396],
                [166.6872, 19.471],
              ],
            ],
            [
              [
                [-159.8328, -0.262],
                [-159.8173, -0.4786],
                [-160.2398, -0.4453],
                [-160.0809, -0.2405],
                [-159.8328, -0.262],
              ],
            ],
            [
              [
                [-162.1623, 6.2639],
                [-162.2521, 6.1459],
                [-162.6343, 6.5266],
                [-163.1964, 6.812],
                [-162.8348, 6.6967],
                [-162.3237, 6.3678],
                [-162.1623, 6.2639],
              ],
            ],
            [
              [
                [-177.1042, 28.2133],
                [-177.5001, 28.0922],
                [-177.5719, 28.1537],
                [-177.4906, 28.318],
                [-177.2633, 28.3605],
                [-177.1042, 28.2133],
              ],
            ],
          ],
        },
        properties: { name: "United States Minor Outlying Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-53.378, -33.7504],
              [-53.8632, -34.4342],
              [-54.9524, -34.9739],
              [-55.4492, -34.758],
              [-55.8346, -34.8021],
              [-56.1532, -34.9257],
              [-56.3373, -34.8826],
              [-57.1761, -34.4798],
              [-57.8338, -34.4414],
              [-58.2546, -34.0846],
              [-58.417, -33.4995],
              [-58.1303, -33.0265],
              [-58.1316, -31.8015],
              [-57.619, -30.1759],
              [-57.3474, -30.2723],
              [-56.8071, -30.1365],
              [-56.1653, -30.6435],
              [-55.9687, -31.002],
              [-55.7448, -30.9943],
              [-55.5446, -30.9301],
              [-53.83, -32.0405],
              [-53.1514, -32.7404],
              [-53.3047, -32.9751],
              [-53.5095, -33.3089],
              [-53.378, -33.7504],
            ],
          ],
        },
        properties: { name: "Uruguay" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [70.9402, 42.2592],
              [71.177, 42.1716],
              [71.0708, 42.1059],
              [70.4682, 41.7331],
              [70.4493, 41.5111],
              [70.6296, 41.3903],
              [71.2273, 41.179],
              [71.8407, 41.3602],
              [72.7923, 40.7655],
              [71.7869, 40.2283],
              [70.9676, 40.2285],
              [70.5038, 40.3699],
              [70.6152, 40.7776],
              [70.4136, 40.9551],
              [69.4317, 40.6763],
              [68.456, 39.6133],
              [67.5514, 39.5075],
              [67.5335, 39.2951],
              [68.0963, 38.8623],
              [68.3151, 38.2289],
              [67.7787, 37.1796],
              [66.5537, 37.3548],
              [66.6138, 37.5063],
              [66.5616, 38.0044],
              [65.419, 38.3671],
              [62.6116, 39.8876],
              [61.8085, 41.1353],
              [60.3327, 41.3003],
              [60.1297, 41.7331],
              [59.7804, 42.257],
              [59.4397, 42.3929],
              [59.2965, 42.45],
              [58.6272, 42.717],
              [58.089, 42.45],
              [57.9738, 42.3929],
              [57.2518, 42.0348],
              [57.0023, 41.7331],
              [56.9976, 41.3132],
              [56.7141, 41.2841],
              [56.0006, 41.318],
              [56.0005, 41.7331],
              [56.0252, 42.3929],
              [56.1524, 44.8265],
              [58.5043, 45.4677],
              [60.8103, 44.4097],
              [62.0428, 43.6178],
              [65.0384, 43.6349],
              [65.9536, 42.7934],
              [66.0376, 42.3929],
              [66.0488, 42.1109],
              [66.4486, 41.8377],
              [66.5069, 41.7331],
              [66.6943, 41.3166],
              [67.8782, 41.1359],
              [68.4646, 40.6663],
              [69.1476, 41.3736],
              [69.8741, 41.7331],
              [70.6274, 42.1059],
              [70.9402, 42.2592],
            ],
          ],
        },
        properties: { name: "Uzbekistan" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [12.3624, 41.8843],
              [12.4327, 41.9402],
              [12.4897, 41.9547],
              [12.5186, 41.8784],
              [12.3911, 41.869],
              [12.3624, 41.8843],
            ],
          ],
        },
        properties: { name: "Vatican City" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-61.3573, 13.1668],
              [-61.2124, 13.473],
              [-61.0578, 13.3956],
              [-61.1054, 13.0698],
              [-61.3573, 13.1668],
            ],
          ],
        },
        properties: { name: "Saint Vincent and The Grenadines" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-59.994, 8.5334],
                [-59.9947, 8.1428],
                [-60.6142, 7.5487],
                [-60.4378, 6.9909],
                [-61.2089, 6.4133],
                [-61.2634, 5.8625],
                [-60.7318, 5.1961],
                [-60.7041, 4.8701],
                [-61.0145, 4.5424],
                [-62.4283, 4.0929],
                [-62.9241, 3.7234],
                [-64.5692, 4.1305],
                [-64.0031, 2.6686],
                [-63.5034, 2.3999],
                [-63.4774, 2.2297],
                [-64.2898, 1.4973],
                [-65.4471, 0.8038],
                [-65.6926, 0.9257],
                [-66.2879, 0.8221],
                [-66.8698, 1.2256],
                [-67.2208, 2.3424],
                [-67.721, 2.8964],
                [-67.3557, 3.4424],
                [-67.8354, 4.4178],
                [-67.8207, 5.1931],
                [-67.4644, 5.9946],
                [-67.5876, 6.2176],
                [-69.3548, 6.1185],
                [-70.0816, 6.9418],
                [-71.9631, 7.0539],
                [-72.3943, 7.4355],
                [-72.4152, 8.3431],
                [-72.8017, 9.0532],
                [-73.3126, 9.2426],
                [-72.7638, 10.6322],
                [-71.9211, 11.6612],
                [-71.3241, 11.8457],
                [-71.9114, 11.5009],
                [-71.5952, 10.8613],
                [-70.322, 11.3475],
                [-70.2244, 11.9498],
                [-70.0181, 12.1182],
                [-69.8586, 11.9569],
                [-69.8887, 11.5525],
                [-69.1734, 11.4875],
                [-68.549, 11.2224],
                [-68.2506, 10.6699],
                [-67.9129, 10.5131],
                [-66.2761, 10.6192],
                [-65.7125, 10.2685],
                [-65.0224, 10.1064],
                [-64.039, 10.4236],
                [-63.7676, 10.6644],
                [-62.4112, 10.6783],
                [-62.3189, 10.6204],
                [-62.6974, 10.4323],
                [-62.383, 9.9678],
                [-61.3003, 9.6877],
                [-60.858, 9.3429],
                [-60.6854, 8.6872],
                [-59.994, 8.5334],
              ],
            ],
            [
              [
                [-63.8668, 11.2606],
                [-63.7008, 10.9783],
                [-63.8663, 10.879],
                [-64.1632, 10.8692],
                [-64.4483, 11.0041],
                [-64.1757, 11.1584],
                [-64.0732, 11.0298],
                [-63.8668, 11.2606],
              ],
            ],
          ],
        },
        properties: { name: "Venezuela" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-64.8273, 18.4534],
              [-64.5684, 18.5125],
              [-64.44, 18.4159],
              [-64.6272, 18.2782],
              [-64.8273, 18.4534],
            ],
          ],
        },
        properties: { name: "British Virgin Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-65.077, 17.6065],
              [-65.0123, 17.8202],
              [-64.7111, 17.8491],
              [-64.6234, 17.6811],
              [-65.077, 17.6065],
            ],
          ],
        },
        properties: { name: "Us Virgin Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [108.0236, 21.5489],
                [107.7238, 21.3719],
                [107.4317, 20.8393],
                [106.8152, 20.8642],
                [106.4903, 20.2841],
                [105.8986, 19.7262],
                [105.711, 18.9371],
                [106.7883, 17.33],
                [108.38, 15.8786],
                [108.9017, 15.1994],
                [109.3789, 12.8643],
                [109.0658, 11.5804],
                [107.4511, 10.5171],
                [106.789, 10.3884],
                [106.4598, 9.6024],
                [104.991, 8.6881],
                [104.8492, 9.3839],
                [104.9907, 9.979],
                [104.4386, 10.4245],
                [105.2582, 10.9203],
                [106.102, 10.9073],
                [105.9956, 11.599],
                [107.4792, 12.4166],
                [107.5795, 13.4943],
                [107.407, 14.1765],
                [107.5561, 14.6866],
                [107.5938, 15.3404],
                [107.2941, 15.8632],
                [107.3734, 16.1224],
                [106.74, 16.4137],
                [106.5116, 16.9689],
                [105.1906, 18.3486],
                [105.1551, 18.6727],
                [103.9581, 19.3062],
                [104.1919, 19.6463],
                [104.6562, 19.6942],
                [104.9197, 20.0654],
                [104.5298, 20.4333],
                [104.4887, 20.719],
                [104.1421, 20.9297],
                [103.665, 20.7839],
                [103.2157, 20.8666],
                [102.897, 21.3822],
                [102.9271, 21.7266],
                [102.1439, 22.4007],
                [102.5296, 22.6804],
                [102.9794, 22.5457],
                [103.37, 22.7152],
                [104.0941, 22.6352],
                [105.3207, 23.2985],
                [106.6447, 22.8138],
                [106.7942, 22.0576],
                [108.0236, 21.5489],
              ],
            ],
            [
              [
                [104.0837, 10.3015],
                [104.0134, 10.1833],
                [103.9211, 10.2872],
                [103.9706, 10.4427],
                [104.0837, 10.3015],
              ],
            ],
          ],
        },
        properties: { name: "Vietnam" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [169.5451, -19.6006],
                [169.4505, -19.7089],
                [169.1717, -19.4341],
                [169.2756, -19.2419],
                [169.5451, -19.6006],
              ],
            ],
            [
              [
                [169.3591, -18.9369],
                [169.1993, -18.9746],
                [168.9984, -18.853],
                [169.0342, -18.6486],
                [169.1974, -18.6656],
                [169.3591, -18.9369],
              ],
            ],
            [
              [
                [168.5879, -17.6865],
                [168.3906, -17.8248],
                [168.1441, -17.6432],
                [168.3506, -17.4148],
                [168.5879, -17.6865],
              ],
            ],
            [
              [
                [168.406, -16.7999],
                [168.1437, -16.863],
                [168.0404, -16.6649],
                [168.1118, -16.551],
                [168.3718, -16.6504],
                [168.406, -16.7999],
              ],
            ],
            [
              [
                [168.3271, -16.3668],
                [168.1915, -16.4172],
                [167.941, -16.2296],
                [168.1646, -16.0315],
                [168.3271, -16.3668],
              ],
            ],
            [
              [
                [168.0146, -15.4002],
                [167.8673, -15.5386],
                [167.6182, -15.4341],
                [167.8009, -15.2574],
                [167.9876, -15.2345],
                [168.0146, -15.4002],
              ],
            ],
            [
              [
                [167.7941, -16.5119],
                [167.4671, -16.5344],
                [167.3834, -16.2251],
                [167.1851, -16.0832],
                [167.2145, -15.9171],
                [167.6049, -16.2251],
                [167.7941, -16.5119],
              ],
            ],
            [
              [
                [167.6703, -14.1518],
                [167.5679, -14.4519],
                [167.3281, -14.4111],
                [167.4253, -14.1354],
                [167.6703, -14.1518],
              ],
            ],
            [
              [
                [167.6132, -13.8992],
                [167.4784, -14.0795],
                [167.3037, -13.8348],
                [167.4843, -13.6113],
                [167.6132, -13.8992],
              ],
            ],
            [
              [
                [166.825, -15.1545],
                [166.9491, -15.1609],
                [166.9847, -14.9575],
                [167.1171, -14.9507],
                [167.2905, -15.5612],
                [167.2301, -15.735],
                [167.055, -15.6152],
                [166.778, -15.6407],
                [166.6537, -15.3919],
                [166.6773, -15.2426],
                [166.5505, -14.8535],
                [166.619, -14.6567],
                [166.761, -14.833],
                [166.825, -15.1545],
              ],
            ],
            [
              [
                [166.7624, -13.1032],
                [166.5578, -13.3178],
                [166.4214, -13.1446],
                [166.4616, -13.0012],
                [166.7322, -12.9822],
                [166.7624, -13.1032],
              ],
            ],
          ],
        },
        properties: { name: "Vanuatu" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-176.0257, -13.2825],
              [-176.2605, -13.5069],
              [-176.3346, -13.2899],
              [-176.2092, -13.0355],
              [-176.0257, -13.2825],
            ],
          ],
        },
        properties: { name: "Wallis and Futuna" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [179.8851, 51.9945],
                [179.6069, 51.8073],
                [179.3511, 51.9293],
                [179.6774, 52.088],
                [179.8851, 51.9945],
              ],
            ],
            [
              [
                [177.76, 51.9239],
                [177.4784, 51.8305],
                [177.3861, 51.9458],
                [177.5436, 52.2151],
                [177.76, 51.9239],
              ],
            ],
            [
              [
                [173.1771, 52.9586],
                [173.1834, 52.7922],
                [172.9249, 52.7443],
                [172.7832, 52.8909],
                [172.6093, 52.8666],
                [172.6692, 53.0006],
                [173.1771, 52.9586],
              ],
            ],
            [
              [
                [-67.2702, 45.1886],
                [-66.9835, 44.9097],
                [-68.2758, 44.2472],
                [-68.3846, 44.1914],
                [-69.9476, 43.7632],
                [-70.3129, 43.3347],
                [-70.6719, 42.9137],
                [-70.768, 42.4816],
                [-70.7354, 42.334],
                [-70.5614, 41.8794],
                [-70.7074, 41.5642],
                [-72.7517, 41.2539],
                [-72.904, 41.1372],
                [-72.8531, 40.845],
                [-74.0036, 40.5421],
                [-74.1687, 39.8466],
                [-74.5222, 39.4016],
                [-74.8005, 39.1919],
                [-75.2722, 39.1532],
                [-75.1845, 38.4685],
                [-75.6268, 37.6941],
                [-76.0962, 38.3308],
                [-76.2247, 39.0578],
                [-76.3737, 39.1491],
                [-76.3684, 37.301],
                [-75.9375, 36.544],
                [-75.9173, 35.6802],
                [-76.3858, 35.2758],
                [-76.6634, 34.7175],
                [-78.8994, 33.5851],
                [-80.6595, 32.163],
                [-81.0733, 31.5811],
                [-81.3883, 30.8222],
                [-81.3479, 30.2933],
                [-80.5855, 28.5131],
                [-80.1642, 26.9557],
                [-80.1978, 25.8115],
                [-80.549, 25.1714],
                [-81.1091, 25.3183],
                [-82.1535, 26.5466],
                [-82.739, 27.775],
                [-82.8471, 29.0099],
                [-83.8386, 29.9165],
                [-84.289, 29.9857],
                [-85.1412, 29.7554],
                [-86.1958, 30.2582],
                [-88.1274, 30.4787],
                [-89.5133, 30.1464],
                [-89.6035, 29.9927],
                [-89.3631, 29.8545],
                [-89.6524, 29.4756],
                [-90.0133, 29.4052],
                [-90.7805, 29.2555],
                [-91.0362, 29.3255],
                [-91.8794, 29.5444],
                [-92.0339, 29.7299],
                [-92.4404, 29.6131],
                [-93.3831, 29.7924],
                [-94.7819, 29.6223],
                [-95.2861, 29.0205],
                [-97.0261, 28.1015],
                [-97.3091, 27.599],
                [-97.3829, 26.9252],
                [-97.1461, 25.9561],
                [-98.2152, 26.0797],
                [-99.0549, 26.4642],
                [-99.5889, 27.5721],
                [-100.2721, 28.305],
                [-100.7311, 29.1117],
                [-101.3814, 29.6713],
                [-102.5338, 29.7138],
                [-103.0936, 29.1084],
                [-103.4137, 29.0808],
                [-104.4209, 29.6204],
                [-104.9579, 30.5767],
                [-106.4688, 31.7164],
                [-107.9988, 31.7599],
                [-108.5185, 31.3352],
                [-110.9234, 31.3386],
                [-115.002, 32.6693],
                [-117.1236, 32.5346],
                [-117.66, 33.4],
                [-119.5453, 34.366],
                [-120.5355, 34.5811],
                [-120.7439, 35.1518],
                [-121.8039, 36.2684],
                [-121.8909, 36.8265],
                [-122.3564, 37.2307],
                [-122.5427, 37.8584],
                [-123.6126, 38.8603],
                [-123.8577, 39.7452],
                [-124.3149, 40.3203],
                [-124.1112, 41.4593],
                [-124.3579, 42.334],
                [-124.4815, 42.8651],
                [-124.3638, 43.3347],
                [-124.1351, 44.2472],
                [-123.9899, 45.9257],
                [-124.5489, 48.0587],
                [-124.2765, 48.2617],
                [-122.933, 48.2411],
                [-122.8092, 49.0215],
                [-95.824, 49.0008],
                [-95.0687, 49.2416],
                [-94.2745, 48.6951],
                [-92.9202, 48.5702],
                [-91.5593, 48.1211],
                [-91.0362, 48.1534],
                [-90.7911, 48.1805],
                [-90.0133, 48.0688],
                [-89.6593, 48.0179],
                [-89.435, 47.9857],
                [-88.0434, 48.1758],
                [-85.0831, 46.9699],
                [-82.6251, 45.3393],
                [-82.3354, 44.2472],
                [-82.1811, 43.6656],
                [-82.3027, 43.3347],
                [-82.9607, 42.0964],
                [-82.7186, 41.7827],
                [-79.241, 42.7271],
                [-79.0466, 42.8794],
                [-79.0567, 43.3347],
                [-79.0575, 43.4429],
                [-78.7616, 43.5853],
                [-76.8745, 43.668],
                [-76.011, 44.2472],
                [-74.9771, 44.9407],
                [-71.7863, 45.0078],
                [-70.8839, 45.369],
                [-70.3752, 45.8014],
                [-69.8084, 46.8614],
                [-69.2777, 47.3844],
                [-68.9977, 47.3552],
                [-68.8921, 47.206],
                [-68.3747, 47.349],
                [-67.8174, 47.0362],
                [-67.7815, 46.0459],
                [-67.7647, 45.7142],
                [-67.2702, 45.1886],
              ],
            ],
            [
              [
                [-69.8183, 41.6633],
                [-69.9354, 41.4943],
                [-70.237, 41.7523],
                [-70.0203, 41.8592],
                [-69.8183, 41.6633],
              ],
            ],
            [
              [
                [-81.1897, 24.6405],
                [-81.4693, 24.5676],
                [-81.6764, 24.6585],
                [-81.437, 24.8648],
                [-81.1897, 24.6405],
              ],
            ],
            [
              [
                [-89.0385, 29.2483],
                [-89.2514, 29.03],
                [-89.4264, 29.2328],
                [-89.2795, 29.4033],
                [-89.0385, 29.2483],
              ],
            ],
            [
              [
                [-119.9832, 33.8457],
                [-120.1181, 33.7975],
                [-120.3402, 34.0121],
                [-119.9445, 34.1235],
                [-119.9832, 33.8457],
              ],
            ],
            [
              [
                [-140.9923, 69.6172],
                [-140.9942, 68.2307],
                [-140.999, 64.7661],
                [-140.999, 64.4949],
                [-141, 60.5156],
                [-140.8352, 60.2915],
                [-139.9046, 60.2219],
                [-139.1645, 60.3358],
                [-139.0965, 60.0754],
                [-138.0988, 59.5094],
                [-137.4221, 58.9656],
                [-137.0381, 59.0793],
                [-136.5724, 59.1992],
                [-136.2508, 59.5094],
                [-136.1363, 59.635],
                [-135.4947, 59.7832],
                [-135.0304, 59.5094],
                [-134.5386, 59.1177],
                [-133.4872, 58.471],
                [-131.7547, 56.6577],
                [-130.0102, 55.9171],
                [-130.0093, 55.9114],
                [-130.0119, 55.9078],
                [-130.0242, 55.9069],
                [-130.029, 55.9048],
                [-130.0361, 55.8913],
                [-130.0519, 55.8767],
                [-130.0603, 55.863],
                [-130.0891, 55.8321],
                [-130.112, 55.8225],
                [-130.1282, 55.8218],
                [-130.1397, 55.8162],
                [-130.1491, 55.8006],
                [-130.1693, 55.7792],
                [-130.1752, 55.7593],
                [-130.1663, 55.7451],
                [-130.1636, 55.7334],
                [-130.1689, 55.7143],
                [-130.1403, 55.6937],
                [-130.1269, 55.6753],
                [-130.1276, 55.6446],
                [-130.1377, 55.6287],
                [-130.1403, 55.6126],
                [-130.1493, 55.5965],
                [-130.14, 55.5711],
                [-130.139, 55.5535],
                [-130.1181, 55.5273],
                [-130.115, 55.5146],
                [-130.1061, 55.4953],
                [-130.0619, 55.2542],
                [-130.44, 54.8695],
                [-130.5183, 54.8695],
                [-130.534, 54.8695],
                [-130.534, 54.8628],
                [-130.5257, 54.8622],
                [-130.5164, 54.8536],
                [-130.5073, 54.8338],
                [-130.5216, 54.8246],
                [-130.5248, 54.8182],
                [-130.534, 54.8147],
                [-130.534, 54.8105],
                [-130.534, 54.8067],
                [-130.6423, 54.7507],
                [-131.4035, 55.0646],
                [-131.992, 55.6064],
                [-132.2095, 55.5914],
                [-132.1389, 55.1308],
                [-132.3992, 54.8623],
                [-132.878, 54.9285],
                [-133.5288, 55.4766],
                [-133.7269, 56.1541],
                [-134.067, 56.2619],
                [-134.1955, 56.5645],
                [-134.0599, 56.8656],
                [-133.6383, 57.1463],
                [-133.6492, 57.5041],
                [-133.8447, 57.6161],
                [-134.4763, 57.2893],
                [-134.7914, 58.2695],
                [-135.077, 58.6338],
                [-135.4452, 58.3491],
                [-136.5879, 58.3495],
                [-139.2481, 59.3321],
                [-139.9775, 59.7654],
                [-145.7361, 60.2847],
                [-146.8959, 60.8323],
                [-147.7155, 60.9004],
                [-148.2782, 60.7401],
                [-148.0373, 60.3987],
                [-148.2365, 60.041],
                [-151.4689, 59.3299],
                [-151.5905, 60.0891],
                [-151.2032, 60.6379],
                [-150.6095, 60.9727],
                [-150.7682, 61.1379],
                [-151.6115, 60.8802],
                [-153.8089, 59.4121],
                [-153.9219, 59.1562],
                [-153.6659, 58.7197],
                [-154.4768, 58.1019],
                [-156.0148, 57.5013],
                [-156.7197, 57.0129],
                [-158.2631, 56.4289],
                [-159.0484, 55.9308],
                [-160.3948, 55.5631],
                [-160.681, 55.2971],
                [-161.4111, 55.4277],
                [-161.9879, 55.1034],
                [-162.6444, 55.0464],
                [-162.7133, 55.3224],
                [-162.1446, 55.6651],
                [-160.7428, 55.9412],
                [-158.5296, 57.0208],
                [-157.8319, 57.6312],
                [-157.4129, 58.5755],
                [-158.4358, 58.762],
                [-159.0941, 58.5567],
                [-160.2873, 58.9094],
                [-160.9417, 58.6572],
                [-161.4338, 58.6784],
                [-161.7802, 58.9542],
                [-161.992, 59.6625],
                [-162.2754, 59.9612],
                [-164.0544, 59.9629],
                [-164.7925, 60.3086],
                [-165.7555, 61.5051],
                [-165.5572, 62.1509],
                [-164.3354, 63.0738],
                [-163.1606, 63.1419],
                [-161.1527, 63.6774],
                [-160.9492, 63.9765],
                [-161.0236, 64.5972],
                [-163.003, 64.419],
                [-163.6588, 64.5563],
                [-165.3792, 64.5116],
                [-166.209, 64.7067],
                [-167.1507, 65.6045],
                [-164.4554, 66.5191],
                [-163.2031, 66.1033],
                [-162.0556, 66.0908],
                [-161.4603, 66.3159],
                [-161.8333, 66.8187],
                [-163.3911, 67.0937],
                [-164.355, 67.6435],
                [-165.9368, 68.2247],
                [-166.1372, 68.5492],
                [-165.9563, 68.7483],
                [-163.7403, 69.1472],
                [-162.2064, 70.1213],
                [-159.8727, 70.7111],
                [-158.0854, 70.8749],
                [-156.5917, 71.2616],
                [-156.0296, 71.2004],
                [-155.5742, 70.9153],
                [-153.1711, 70.8697],
                [-151.3528, 70.4503],
                [-148.5162, 70.3723],
                [-145.0148, 70.0024],
                [-143.1852, 70.0715],
                [-140.9923, 69.6172],
              ],
            ],
            [
              [
                [-134.6337, 56.3747],
                [-134.915, 56.3747],
                [-135.405, 56.908],
                [-135.4345, 57.0361],
                [-135.8127, 57.0551],
                [-135.883, 57.425],
                [-136.5538, 57.9476],
                [-136.0531, 58.2318],
                [-135.6046, 58.209],
                [-134.9212, 57.9476],
                [-134.9026, 57.425],
                [-134.7098, 56.908],
                [-134.6337, 56.3747],
              ],
            ],
            [
              [
                [-146.9694, 60.204],
                [-147.2609, 60.0512],
                [-147.2609, 60.3555],
                [-146.9772, 60.4021],
                [-146.9694, 60.204],
              ],
            ],
            [
              [
                [-147.3685, 59.857],
                [-147.6864, 59.8094],
                [-147.8124, 59.8909],
                [-147.4183, 60.1161],
                [-147.3685, 59.857],
              ],
            ],
            [
              [
                [-152.5848, 58.0209],
                [-152.5625, 57.9173],
                [-152.3302, 57.9173],
                [-152.3188, 57.5046],
                [-153.7286, 57.1143],
                [-153.5949, 56.911],
                [-153.8361, 56.7244],
                [-154.0232, 56.7622],
                [-153.8072, 56.9858],
                [-153.8532, 57.0813],
                [-154.3715, 56.9442],
                [-154.505, 57.0061],
                [-154.7022, 57.4038],
                [-154.3715, 57.6186],
                [-153.7744, 57.5495],
                [-153.8399, 57.8139],
                [-153.418, 57.7503],
                [-153.2998, 57.8924],
                [-153.3698, 58.0166],
                [-152.8815, 58.3418],
                [-152.5363, 58.6297],
                [-152.5189, 58.4237],
                [-152.1675, 58.3129],
                [-152.1615, 58.2119],
                [-152.5848, 58.0209],
              ],
            ],
            [
              [
                [-154.0302, 56.5127],
                [-154.2296, 56.3788],
                [-154.4393, 56.5479],
                [-154.2099, 56.7322],
                [-154.0302, 56.5127],
              ],
            ],
            [
              [
                [-155.5039, 19.1318],
                [-155.6363, 18.934],
                [-155.8808, 19.03],
                [-155.8868, 19.3317],
                [-156.0645, 19.7297],
                [-155.8246, 20.0258],
                [-155.8395, 20.271],
                [-155.2752, 20.0197],
                [-155.0799, 19.8488],
                [-155.0864, 19.7327],
                [-154.8084, 19.5263],
                [-154.9698, 19.3473],
                [-155.5039, 19.1318],
              ],
            ],
            [
              [
                [-156.5749, 21.0277],
                [-156.4834, 20.8988],
                [-156.2319, 20.9447],
                [-155.9741, 20.7916],
                [-156.0237, 20.64],
                [-156.3758, 20.5574],
                [-156.4734, 20.78],
                [-156.7248, 20.937],
                [-156.5749, 21.0277],
              ],
            ],
            [
              [
                [-156.7592, 20.7216],
                [-157.0039, 20.6577],
                [-157.0916, 20.919],
                [-156.9886, 21.0559],
                [-157.3309, 21.1305],
                [-157.314, 21.2879],
                [-156.8217, 21.1585],
                [-156.8809, 21.0075],
                [-156.7592, 20.7216],
              ],
            ],
            [
              [
                [-157.6617, 21.3113],
                [-158.1021, 21.3025],
                [-158.2659, 21.5706],
                [-157.9689, 21.7011],
                [-157.6617, 21.3113],
              ],
            ],
            [
              [
                [-159.3151, 21.9515],
                [-159.438, 21.8529],
                [-159.8096, 22.017],
                [-159.5848, 22.2382],
                [-159.2953, 22.1938],
                [-159.3151, 21.9515],
              ],
            ],
            [
              [
                [-159.4398, 55.0989],
                [-159.6772, 54.9481],
                [-159.7703, 55.0969],
                [-159.7225, 55.2509],
                [-159.4637, 55.2712],
                [-159.4398, 55.0989],
              ],
            ],
            [
              [
                [-162.2022, 54.3381],
                [-162.6927, 54.297],
                [-162.6796, 54.4941],
                [-162.2558, 54.4585],
                [-162.2022, 54.3381],
              ],
            ],
            [
              [
                [-163.1577, 55.1258],
                [-163.1176, 54.9135],
                [-163.2888, 54.6982],
                [-163.5876, 54.6159],
                [-164.0979, 54.6215],
                [-164.3468, 54.4707],
                [-164.6443, 54.3934],
                [-164.8352, 54.4229],
                [-164.9374, 54.5787],
                [-164.6891, 54.6608],
                [-164.5457, 54.886],
                [-164.2187, 54.8949],
                [-163.8926, 55.033],
                [-163.5387, 55.0482],
                [-163.363, 54.9809],
                [-163.1577, 55.1258],
              ],
            ],
            [
              [
                [-165.638, 59.9732],
                [-166.2902, 59.7757],
                [-167.1355, 60.0781],
                [-167.1717, 60.1323],
                [-167.1303, 60.1826],
                [-166.3709, 60.3711],
                [-165.7389, 60.306],
                [-165.638, 59.9732],
              ],
            ],
            [
              [
                [-165.7769, 54.0967],
                [-165.8468, 53.9993],
                [-166.0799, 54.0133],
                [-166.1784, 54.1223],
                [-165.9863, 54.2616],
                [-165.8601, 54.2553],
                [-165.7769, 54.0967],
              ],
            ],
            [
              [
                [-165.9588, 61.5724],
                [-166.0564, 61.3855],
                [-166.1855, 61.4286],
                [-166.2785, 61.6215],
                [-166.2244, 61.7348],
                [-166.0387, 61.7],
                [-165.9588, 61.5724],
              ],
            ],
            [
              [
                [-166.3934, 53.6565],
                [-167.0445, 53.4444],
                [-167.1136, 53.489],
                [-167.0674, 53.8859],
                [-166.7916, 53.9907],
                [-166.3831, 53.7556],
                [-166.3934, 53.6565],
              ],
            ],
            [
              [
                [-167.4326, 53.2224],
                [-167.691, 53.1901],
                [-167.8322, 53.3117],
                [-167.7903, 53.4018],
                [-167.4068, 53.4039],
                [-167.4326, 53.2224],
              ],
            ],
            [
              [
                [-168.4378, 53.0095],
                [-168.7531, 52.9657],
                [-168.8105, 53.0663],
                [-168.1863, 53.532],
                [-168.0207, 53.4027],
                [-168.3378, 53.1728],
                [-168.4378, 53.0095],
              ],
            ],
            [
              [
                [-170.2934, 63.6864],
                [-170.0521, 63.4841],
                [-169.5379, 63.3711],
                [-168.8183, 63.3101],
                [-168.8585, 63.1479],
                [-169.3735, 63.1519],
                [-169.7555, 62.9609],
                [-169.8629, 63.0987],
                [-170.2866, 63.1895],
                [-170.6176, 63.3687],
                [-171.0831, 63.4236],
                [-171.3979, 63.323],
                [-171.7362, 63.3687],
                [-171.8437, 63.4841],
                [-171.7947, 63.6294],
                [-171.5342, 63.66],
                [-170.8959, 63.5729],
                [-170.6443, 63.6678],
                [-170.2934, 63.6864],
              ],
            ],
            [
              [
                [-170.141, 57.0375],
                [-170.3448, 56.9501],
                [-170.7134, 57.028],
                [-170.5861, 57.1467],
                [-170.2278, 57.1272],
                [-170.141, 57.0375],
              ],
            ],
            [
              [
                [-174.0122, 52.127],
                [-174.459, 52.127],
                [-174.5138, 52.1819],
                [-174.3614, 52.3199],
                [-174.0676, 52.3741],
                [-174.0122, 52.127],
              ],
            ],
            [
              [
                [-175.8817, 51.9613],
                [-176.2253, 51.8894],
                [-176.301, 52.0951],
                [-176.0227, 52.1711],
                [-175.8817, 51.9613],
              ],
            ],
            [
              [
                [-176.483, 51.7738],
                [-176.7545, 51.6424],
                [-176.9307, 51.8314],
                [-176.7189, 51.9623],
                [-176.483, 51.7738],
              ],
            ],
            [
              [
                [-177.7821, 51.7965],
                [-177.9155, 51.6188],
                [-178.1504, 51.6563],
                [-178.0961, 51.9444],
                [-177.7821, 51.7965],
              ],
            ],
          ],
        },
        properties: { name: "United States" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [-171.5311, -13.9149],
                [-171.5794, -14.0384],
                [-171.9186, -14.0083],
                [-172.0308, -13.8761],
                [-171.8332, -13.7882],
                [-171.5311, -13.9149],
              ],
            ],
            [
              [
                [-172.1378, -13.6745],
                [-172.5407, -13.8272],
                [-172.764, -13.5574],
                [-172.2869, -13.4376],
                [-172.1378, -13.6745],
              ],
            ],
          ],
        },
        properties: { name: "Samoa" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [112.5204, 16.7931],
                [112.3207, 16.6434],
                [112.1436, 16.916],
                [112.2708, 16.9745],
                [112.5187, 16.901],
                [112.5204, 16.7931],
              ],
            ],
            [
              [
                [111.4671, 15.9211],
                [111.235, 15.6204],
                [111.0326, 15.7651],
                [111.1423, 15.9214],
                [111.4671, 15.9211],
              ],
            ],
          ],
        },
        properties: { name: "Paracel Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [114.1161, 10.9954],
              [114.1834, 11.1959],
              [114.2851, 11.2327],
              [114.4818, 11.0087],
              [114.3172, 10.8856],
              [114.1161, 10.9954],
            ],
          ],
        },
        properties: { name: "Spratly Islands" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [78.9227, 33.3924],
              [78.7315, 33.6703],
              [78.7594, 34.0457],
              [78.9227, 34.1568],
              [78.9227, 34.3701],
              [78.6855, 34.5165],
              [78.3085, 34.6245],
              [78.2078, 34.7534],
              [77.9244, 35.4936],
              [79.3089, 35.9267],
              [80.2549, 35.3651],
              [80.0029, 34.6687],
              [79.4735, 34.1377],
              [78.9826, 33.9808],
              [78.9227, 33.3924],
            ],
          ],
        },
        properties: { name: "Aksai Chin" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [97.3471, 28.2081],
              [97.3443, 27.9963],
              [97.0575, 27.7441],
              [96.3086, 28.1895],
              [95.7527, 28.2572],
              [94.2529, 27.5398],
              [93.6616, 26.9837],
              [93.2491, 26.9504],
              [92.697, 26.9058],
              [92.1079, 26.8583],
              [92.0573, 27.3683],
              [91.6596, 27.5403],
              [91.6421, 27.7585],
              [92.5723, 27.901],
              [92.697, 28.0134],
              [93.2491, 28.5107],
              [93.3732, 28.6225],
              [94.4925, 29.2273],
              [95.4413, 29.1482],
              [96.0434, 29.4013],
              [96.2042, 29.0341],
              [96.5583, 28.8405],
              [96.4079, 28.4508],
              [97.3471, 28.2081],
            ],
          ],
        },
        properties: { name: "Arunachal Pradesh" },
      },
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [80.2176, 30.583],
                [79.9526, 30.8744],
                [79.9683, 30.8862],
                [79.9765, 30.8862],
                [79.992, 30.8796],
                [80.0006, 30.867],
                [80.0258, 30.8423],
                [80.0453, 30.813],
                [80.0753, 30.8041],
                [80.0915, 30.7919],
                [80.139, 30.7844],
                [80.1519, 30.7916],
                [80.165, 30.804],
                [80.18, 30.8069],
                [80.1945, 30.7983],
                [80.2025, 30.7824],
                [80.2144, 30.7763],
                [80.2208, 30.7651],
                [80.2269, 30.7622],
                [80.2385, 30.7621],
                [80.2475, 30.7563],
                [80.2554, 30.7378],
                [80.2545, 30.7176],
                [80.2383, 30.7087],
                [80.2244, 30.6883],
                [80.2044, 30.6848],
                [80.1928, 30.6743],
                [80.2064, 30.6634],
                [80.2218, 30.6396],
                [80.2201, 30.6353],
                [80.2061, 30.6279],
                [80.2058, 30.6217],
                [80.2241, 30.6046],
                [80.2176, 30.583],
              ],
            ],
            [
              [
                [79.2537, 32.5233],
                [79.1467, 33.1883],
                [79.3416, 33.1356],
                [79.5132, 32.7273],
                [79.2537, 32.5233],
              ],
            ],
            [
              [
                [79.4224, 31.064],
                [79.138, 31.003],
                [78.8882, 31.2723],
                [79.1149, 31.3794],
                [79.4224, 31.064],
              ],
            ],
            [
              [
                [78.769, 31.9377],
                [78.5766, 32.0593],
                [78.4736, 32.2395],
                [78.6791, 32.0931],
                [78.769, 31.9377],
              ],
            ],
          ],
        },
        properties: { name: "China/India" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [36.8825, 22.0051],
              [34.5937, 22.003],
              [34.0865, 22.0025],
              [34.2134, 22.2],
              [34.5937, 22.2811],
              [34.9524, 22.7943],
              [35.6218, 23.1455],
              [35.8194, 22.8034],
              [36.8825, 22.0051],
            ],
          ],
        },
        properties: { name: "Hala'Ib Triangle" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [35.5088, 4.62],
              [34.3785, 4.62],
              [34.5428, 4.7504],
              [35.2489, 4.9563],
              [35.5088, 4.62],
            ],
          ],
        },
        properties: { name: "Ilemi Triangle" },
      },
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [77.9244, 35.4936],
              [78.2078, 34.7534],
              [78.3085, 34.6245],
              [78.6855, 34.5165],
              [78.9227, 34.3701],
              [78.9227, 34.1568],
              [78.7594, 34.0457],
              [78.7315, 33.6703],
              [78.9227, 33.3924],
              [79.1467, 33.1883],
              [79.2537, 32.5233],
              [78.9227, 32.3637],
              [78.7005, 32.6471],
              [78.398, 32.5232],
              [77.7621, 32.9237],
              [77.3152, 32.8606],
              [76.5852, 33.1567],
              [75.3339, 32.3266],
              [73.6386, 33.1515],
              [73.4315, 34.3917],
              [73.7156, 34.7534],
              [74.0657, 35.092],
              [73.7903, 35.2386],
              [73.7126, 35.5074],
              [73.2944, 35.6086],
              [73.049, 35.8283],
              [72.6179, 35.8638],
              [72.5308, 36.0297],
              [72.5938, 36.2273],
              [73.2944, 36.7238],
              [73.6909, 36.7433],
              [73.6393, 36.9034],
              [74.5646, 37.0309],
              [75.3164, 36.9453],
              [75.8285, 36.6678],
              [76.1908, 35.877],
              [77.318, 35.4964],
              [77.9244, 35.4936],
            ],
          ],
        },
        properties: { name: "Jammu Kashmir" },
      },
    ],
  };
  