export const addItalySection = async (doc, italyReport, italyRef) => {
  // Constants for layout
  const margin = {
    top: 30,
    bottom: 30,
    left: 20,
    right: 20,
  };
  const lineHeight = 6;
  const sectionSpacing = 12;
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  const contentWidth = pageWidth - margin.left - margin.right;

  // Helper function to check if new page is needed
  const checkAndAddPage = (currentY, requiredSpace) => {
    if (currentY + requiredSpace > pageHeight - margin.bottom) {
      doc.addPage();
      return margin.top;
    }
    return currentY;
  };

  // Helper function to add section title
  const addSectionTitle = (title, yPosition) => {
    doc.setFontSize(14);
    doc.setTextColor(41, 128, 185);
    yPosition = checkAndAddPage(yPosition, lineHeight + 5);
    doc.text(title, margin.left, yPosition);
    return yPosition + lineHeight + 5;
  };

  // Helper function to add bordered section
  const addBorderedSection = (title, yPosition, height, isThinBorder = false) => {
    yPosition = checkAndAddPage(yPosition, height + 30);

    // Draw section title
    doc.setFontSize(14);
    doc.setTextColor(41, 128, 185);
    doc.text(title, margin.left, yPosition);
    yPosition += lineHeight + 5;

    // Draw bordered box
    doc.setDrawColor(200, 200, 200);
    doc.setLineWidth(isThinBorder ? 0.1 : 0.5);
    doc.rect(margin.left, yPosition, contentWidth, height);

    return yPosition + height + sectionSpacing;
  };

  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!italyReport) {
      console.warn("Italy report data is missing");
      return;
    }

    doc.addPage();
    let yPosition = margin.top;

    // Header
    doc.setFont("helvetica", "bold");
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("Italy", margin.left, yPosition);
    yPosition += lineHeight * 2;

    // Metadata
    const { week_id, year } = italyReport;
    const week = parseInt(week_id.slice(5), 10);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    const metadataText = [`Year: ${year || "N/A"}`, `Week: ${week || "N/A"}`];

    metadataText.forEach((text) => {
      doc.text(text, margin.left, yPosition);
      yPosition += lineHeight;
    });

    yPosition += sectionSpacing;

    // Weekly Summary
    if (italyReport.summary_points?.length > 0) {
      yPosition = addSectionTitle("Weekly Summary", yPosition);

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);

      for (const point of italyReport.summary_points) {
        const lines = doc.splitTextToSize(point, contentWidth);
        yPosition = checkAndAddPage(yPosition, lines.length * lineHeight + 2);
        doc.text(lines, margin.left, yPosition);
        yPosition += lines.length * lineHeight + 3;
      }

      yPosition += sectionSpacing;
    }

    // Add a new page for Graphs and Comments sections
    doc.addPage();
    yPosition = margin.top;

    // Graphs Section with 1/2 page height
    yPosition = addBorderedSection("Graphs", yPosition, pageHeight / 2, true);

    // Comments Section with 1/3 page height
    yPosition = addBorderedSection("Comments", yPosition, pageHeight / 3, true);

    // Source citation
    yPosition = checkAndAddPage(yPosition, 20);
    doc.setFontSize(10);
    doc.setTextColor(128, 128, 128);
    doc.text(
      `Source: ${italyReport.source_url || "Not available"}`,
      margin.left,
      pageHeight - margin.bottom
    );
  } catch (error) {
    console.error("Error generating Italy PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating Italy report section. Please try again later.",
      margin.left,
      margin.top
    );
  }
};
