/**
 * Adds France section to the PDF document with proper margin control
 * @param {Object} doc - PDF document instance
 * @param {Object} franceReport - France report data
 * @param {string} chartImage - Base64 encoded chart image
 * @returns {Promise<void>}
 */
export const addFranceSection = async (doc, franceReport, chartImage) => {
  const margin = 20;
  const pageWidth = doc.internal.pageSize.width;
  const contentWidth = pageWidth - margin * 2;
  const pageHeight = doc.internal.pageSize.height;

  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!franceReport) {
      console.warn("France report data is missing");
      return;
    }

    doc.addPage();

    let currentY = margin;

    // Title
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("France", margin, currentY);
    currentY += 15;

    // Report period
    const { requested_week_report } = franceReport.data;
    const weekId = requested_week_report.week_id;
    const year = weekId.slice(0, 4);
    const week = weekId.slice(4);

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(`Report for Year ${year}, Week ${week}`, margin, currentY);
    currentY += 15;

    // Summary Section
    doc.setFontSize(12);
    doc.setTextColor(41, 128, 185);
    doc.text("Summary of Acute Respiratory Infections (ARI)", margin, currentY);
    currentY += 10;

    doc.setTextColor(0, 0, 0);
    const summaryParagraphs = requested_week_report.summary_paragraphs || [];

    summaryParagraphs.forEach((paragraph) => {
      const plainText = paragraph.replace(/<\/?strong>/g, "");
      const lines = doc.splitTextToSize(plainText, contentWidth);

      if (currentY + lines.length * 7 > pageHeight - 150) {
        doc.addPage();
        currentY = margin;
      }

      doc.text(lines, margin, currentY);
      currentY += lines.length * 7;
    });

    // Add Chart Image (Ensure it fits properly)
    if (chartImage) {
      const maxChartWidth = contentWidth * 0.8;
      const maxChartHeight = 150;

      const img = new Image();
      img.src = chartImage;

      await new Promise((resolve) => {
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          let chartWidth = maxChartWidth;
          let chartHeight = chartWidth / aspectRatio;

          if (chartHeight > maxChartHeight) {
            chartHeight = maxChartHeight;
            chartWidth = chartHeight * aspectRatio;
          }

          // Ensure space before adding image
          if (currentY + chartHeight > pageHeight - 150) {
            doc.addPage();
            currentY = margin;
          }

          // Center the chart
          doc.addImage(
            chartImage,
            "PNG",
            margin + (contentWidth - chartWidth) / 2,
            currentY,
            chartWidth,
            chartHeight
          );

          currentY += chartHeight + 15;
          resolve();
        };
      });
    }

    // Source citation
    if (currentY + 60 > pageHeight) {
      doc.addPage();
      currentY = margin;
    }

    doc.setFontSize(9);
    doc.setTextColor(128, 128, 128);
    doc.text(
      "Source: Sentiweb France (https://www.sentiweb.fr/)",
      margin,
      currentY
    );
    currentY += 10;

    // Always Add Comments Section on a New Page
    doc.addPage();
    currentY = margin;

    // Label for Comments
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text("Comments", margin, currentY);
    currentY += 5;

    // Comment Box Height
    const commentBoxHeight = pageHeight / 3;

    // The comment box
    doc.setDrawColor(150, 150, 150);
    doc.setLineWidth(0.1);
    doc.rect(margin, currentY, contentWidth, commentBoxHeight);
  } catch (error) {
    console.error("Error generating France PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating France report section. Please try again later.",
      margin,
      40
    );
  }
};
